import React, { useContext, useEffect, useState } from 'react'

import MUICarousel, { CarouselProps as MUICarouselProps } from 'react-material-ui-carousel'

import { AppTheme, CreateCSSProperties, Loader, makeAppStyles, Typography, useMediaQuery } from '@percept/mui'

import { ChevronLeftThin, ChevronRightThin } from '@percept/mui/icons'

import { UserPrivilegeContext } from '@percept/app-components'

import { get } from 'lodash-es'

import { userHasGlobalPrivileges } from '@percept/utils'

import { GlobalPrivilege } from '@percept/types'


const { NODE_ENV } = process.env


type CarouselItemClassName = (
  'wrapper' | 'item' | 'background' | 'itemInner' | 'typography' | 'title' | 'description'
  | 'infoGraphic' | 'infoGraphicInactive' | 'itemCentered' | 'infoGraphicHeader' | 'itemFixedRight'
  | 'infoGraphicFixedRight' | 'footnote' | 'loader' | 'link'
)


type CarouselItemProps = {
  title: string | JSX.Element
  titleSize?: number
  description?: string | JSX.Element
  footnote?: string | JSX.Element
  backgroundImageSrc?: string
  backgroundOverride?: string
  textColor: string
  active: boolean
  infoGraphicSrc?: string
  infoGraphicHeader?: string
  infoGraphicHeaderStyle?: React.CSSProperties
  infoGraphicFixedRight?: boolean
  GraphicComponent?: () => JSX.Element
  infoGraphicContent?: JSX.Element
  href?: string | null
  loading?: boolean
  requiredGlobalPrivileges?: GlobalPrivilege[]
}

export type CarouselItem = Omit<CarouselItemProps, 'classes' | 'active'>


const CAROUSEL_HEIGHTS = {
  xl: 600,
  md: 500,
  sm: 500,
}

const useCarouselItemStyles = makeAppStyles<{ titleSize: number }, CarouselItemClassName>( theme => ({
  wrapper: {
    height: CAROUSEL_HEIGHTS.xl,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      height: CAROUSEL_HEIGHTS.md,
    },
    [theme.breakpoints.down('sm')]: {
      height: CAROUSEL_HEIGHTS.sm,
    },
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundPosition: '100% center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    zIndex: 0,
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  link: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  navButton: {
    top: 'calc(50% - 80px) !important',
    margin: 0,
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    '&:visited': {
      backgroundColor: 'transparent !important',
    },
    fontSize: 92,
    [theme.breakpoints.down('sm')]: {
      fontSize: 80,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 72,
    },
  },
  indicator: {
    color: '#ccc',
    padding: 10,
    margin: theme.spacing(2, 0),
  },
  activeIndicator: {
    color: theme.palette.primary.main,
    padding: 10,
    margin: theme.spacing(2, 0),
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
    zIndex: 0,
  },
  itemInner: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '50%',
    padding: theme.spacing(0, 12),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 8),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2),
    },
  },
  itemFixedRight: {
    width: '50%',
    height: '100%',
    position: 'relative',
    zIndex: 0,
    padding: '0 !important',
    textAlign: 'right',
  },
  itemCentered: {
    alignItems: 'center',
  },
  typography: {
    color: 'inherit',
  },
  title: ({ titleSize }): CreateCSSProperties => ({
    fontSize: Math.floor(56 * titleSize),
    lineHeight: 1.15,
    fontWeight: 700,
    padding: theme.spacing(0, 3),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      fontSize: Math.floor(48 * titleSize),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: Math.floor(32 * titleSize),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: Math.floor(28 * titleSize),
    },
  }),
  description: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.5,
    maxWidth: '45rem',
    padding: theme.spacing(0, 3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  footnote: {
    fontSize: 12,
    lineHeight: 1.5,
    fontWeight: 500,
    padding: theme.spacing(1, 3),
  },
  infoGraphic: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    opacity: 1,
    padding: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
    transitionTimingFunction: 'cubic-bezier(0.7, 0, 0.7, 1.5)',
    transitionDuration: '1s',
    transitionProperty: 'max-width, max-height, opacity'
  },
  infoGraphicFixedRight: {
    maxWidth: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  infoGraphicInactive: {
    maxWidth: '50%',
    maxHeight: '50%',
    opacity: 0.5,
  },
  infoGraphicHeader: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '24px',
    textAlign: 'center',
    marginBottom: theme.spacing(-4),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(-2),
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
}))


const CarouselItemComponent = ({
  title,
  titleSize = 1,
  description,
  footnote,
  textColor,
  infoGraphicSrc,
  infoGraphicHeader,
  infoGraphicHeaderStyle,
  infoGraphicFixedRight = false,
  backgroundImageSrc,
  backgroundOverride,
  active,
  GraphicComponent,
  infoGraphicContent,
  loading = false,
  href = null,
}: CarouselItemProps): JSX.Element => {
  const [showGraphic, setShowGraphic] = useState(false)
  const carouselItemClasses = useCarouselItemStyles({ titleSize })
  useEffect(() => {
    setShowGraphic(!!active)
  }, [active])
  const infoGraphicClassName = (
    infoGraphicFixedRight ?
      carouselItemClasses.infoGraphicFixedRight :
      showGraphic ?
        carouselItemClasses.infoGraphic :
        `${carouselItemClasses.infoGraphic} ${carouselItemClasses.infoGraphicInactive}`
  )

  const backgroundStyle = (
    backgroundOverride ? {
      background: backgroundOverride
    } : backgroundImageSrc ? {
      backgroundImage: `url(${backgroundImageSrc})`,
    } : {}
  )

  const infoGraphicRootClassName = (
    infoGraphicFixedRight ?
      carouselItemClasses.itemFixedRight :
      `${carouselItemClasses.itemInner} ${carouselItemClasses.itemCentered}`
  )

  return (
    <div className={carouselItemClasses.wrapper}>
      <div
        className={carouselItemClasses.background}
        style={backgroundStyle} />
      <div
        className={carouselItemClasses.item}
        style={{ color: textColor }}>
        <div
          className={carouselItemClasses.itemInner}>
          <Typography
            className={carouselItemClasses.title}
            variant='h3'
            color='inherit'>
            { title }
          </Typography>
          { description && (
            <Typography
              className={carouselItemClasses.description}
              variant='body1'
              color='inherit'>
              { description }
            </Typography>
          )}
          { footnote && (
            <Typography
              className={carouselItemClasses.footnote}
              variant='body1'
              color='inherit'>
              { footnote }
            </Typography>
          )}
        </div>
        <div className={infoGraphicRootClassName}>
          { infoGraphicHeader && (
            <span className={carouselItemClasses.infoGraphicHeader} style={infoGraphicHeaderStyle}>
              { infoGraphicHeader }
            </span>
          )}
          { infoGraphicSrc && (
            <img className={infoGraphicClassName} src={infoGraphicSrc} />
          )}
          { GraphicComponent && <GraphicComponent /> }
          { infoGraphicContent }
        </div>
      </div>
      { loading && (
        <Loader className={carouselItemClasses.loader} preset='centered' />
      )}
      { href && (
        <a
          className={carouselItemClasses.link}
          target='_blank'
          rel='noopener noreferrer'
          href={href} />
      )}
    </div>
  )
}


const useCarouselStyles = makeAppStyles( theme => ({
  root: {
    minHeight: CAROUSEL_HEIGHTS.xl,
    [theme.breakpoints.down('md')]: {
      minHeight: CAROUSEL_HEIGHTS.md,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: CAROUSEL_HEIGHTS.sm,
    },
  },
  navButton: {
    top: 'calc(50% - 80px) !important',
    margin: 0,
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    '&:visited': {
      backgroundColor: 'transparent !important',
    },
    fontSize: 92,
    [theme.breakpoints.down('sm')]: {
      fontSize: 80,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 72,
    },
  },
  indicator: {
    color: '#ccc',
    padding: 10,
    margin: theme.spacing(2, 0),
  },
  activeIndicator: {
    color: theme.palette.primary.main,
    padding: 10,
    margin: theme.spacing(2, 0),
  },
}))


const AUTOPLAY_ENABLED = NODE_ENV === 'production'  // disable auto-play in development


export type CarouselProps = {
  items: CarouselItem[]
} & Omit<MUICarouselProps, 'children'>


export const Carousel = ({
  items,
  ...muiCarouselProps
}: CarouselProps): JSX.Element => {
  const carouselClasses = useCarouselStyles()
  const isSmallScreen = useMediaQuery((theme: AppTheme) => theme.breakpoints.down('sm'))
  const [activeIndex, setActiveIndex] = useState(0)
  const privileges = useContext(UserPrivilegeContext)
  const filteredItems = items.filter( item => {
    if( item.requiredGlobalPrivileges ){
      return userHasGlobalPrivileges(item.requiredGlobalPrivileges, privileges.global_privileges)
    }
    return true
  })
  const navButtonColor = get(items[activeIndex], 'textColor') || 'white'
  return (
    <div className={carouselClasses.root}>
      <MUICarousel
        autoPlay={AUTOPLAY_ENABLED}
        interval={7000}
        timeout={350}
        activeIndicatorIconButtonProps={{
          className: carouselClasses.activeIndicator,
        }}
        indicatorIconButtonProps={{
          className: carouselClasses.indicator,
        }}
        navButtonsAlwaysVisible={!isSmallScreen}
        navButtonsProps={{
          className: carouselClasses.navButton,
          style: {
            color: navButtonColor,
          },
        }}
        fullHeightHover={false}
        onChange={setActiveIndex}
        NextIcon={<ChevronRightThin color='inherit' fontSize='inherit' />}
        PrevIcon={<ChevronLeftThin color='inherit' fontSize='inherit' />}
        animation='slide'
        {...muiCarouselProps}>
        { filteredItems.map( (props, i) => (
          <CarouselItemComponent
            key={`carousel-${i}`}
            active={i === activeIndex}
            {...props} />
        ))}
      </MUICarousel>
    </div>
  )
}
