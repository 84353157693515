import React, { useContext } from 'react'

import { Box } from '@percept/mui'

import { getDateOptions } from './utils'

import { OverviewContext } from './overviewContext'
import { DatePeriodSelect, GranularitySelect } from './overviewComponents'


export const OverviewDateControls = (): JSX.Element => {
  const { granularity, setGranularity, maxDate, referenceDate, setReferenceDate } = useContext(OverviewContext)

  const dateOptions = getDateOptions(maxDate, granularity)

  return (
    <Box display='flex' alignItems='center'>
      <GranularitySelect
        value={granularity}
        onChange={setGranularity} />

      <Box ml={2}>
        <DatePeriodSelect
          granularity={granularity}
          value={referenceDate}
          options={dateOptions}
          onChange={setReferenceDate} />
      </Box>
    </Box>
  )
}
