
import React, { useEffect, useRef } from 'react'

import { MappedTooltipDatum, Tooltip, TooltipData } from './Tooltip'

import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip'

import ResizeObserverPolyfill from 'resize-observer-polyfill'

import { localPoint } from '@visx/event'

import { find } from 'lodash-es'

import { getLabelFormatter, getValueFormatter } from './formatters'

import { getDatasetTotal } from './lib'

import { DatumType } from '@percept/types'

import { ChartEvent, DatumEventHandler, BaseChartProps, SVGDatumType, MultiDataset } from './typings'


export type ChartWithTooltipProps<
  T extends SVGDatumType,
  P extends object = {}
> = BaseChartProps<T> & P


const tooltipStyles: React.CSSProperties = {
  ...defaultStyles,
  zIndex: 9999,
  background: 'transparent',
  color: 'unset',
  margin: 0,
  padding: 0,
}


const divStyle: React.CSSProperties = {
  position: 'relative',
}


type WithTooltipProps<P extends object> = (
  P & {
    yTickFormatter?: (value: DatumType['value']) => string
    xTickFormatter?: (label: DatumType['label']) => string
    tooltipLabelFormatter?: (label: DatumType['label']) => string
  }
)


export function makeChartWithTooltip<
  T extends SVGDatumType,
  P extends (
    BaseChartProps<T> & Partial<{
      datasets: MultiDataset<T>[]
    }>
  )
>(
  Component: React.FC<P>
): React.FC<WithTooltipProps<P>> {

  const ComponentWithTooltip: React.FC<WithTooltipProps<P>> = (props): JSX.Element => {
    const {
      tooltipOpen,
      tooltipData,
      tooltipLeft,
      tooltipTop,
      showTooltip,
      hideTooltip,
    } = useTooltip<TooltipData<T>>()

    const { containerRef, TooltipInPortal, forceRefreshBounds } = useTooltipInPortal({
      detectBounds: true,
      scroll: true,
      polyfill: ResizeObserverPolyfill,
    })

    const forceRefreshBoundsRef = useRef(forceRefreshBounds)

    /*
    NOTE - we need to do this as some animations on ancestor components have been observed to
    interfere with bounds detection, e.g slide transitions making the tooltip relative to where
    the containing element _started_, and not where it is _now_. This 1 second delay has been
    picked as a kind of magic number which is longer than most of our animations but not so long
    that user interaction is likely to start before this fires.
    Not an exact science, so we may need to tweak this.
    */
    useEffect(() => {
      if( props.refreshTooltipBoundsOnMount ){
        setTimeout(forceRefreshBoundsRef.current, 1000)
      }
    }, [props.refreshTooltipBoundsOnMount])

    const labelFormatter = getLabelFormatter(props)

    const valueFormatter = getValueFormatter(props)

    const handleMouseOver: DatumEventHandler<T, ChartEvent> = (event, datum, data) => {
      const coords = localPoint(event.currentTarget.parentNode as Element, event)
      let datumArrayLabel: string | number | null = null
      const datumArray: MappedTooltipDatum<T>[] | undefined = props.datasets && props.datasets.reduce( (acc, { data: datasetData, label, color }) => {
        const match = find(datasetData, dt => dt.label === datum.label )
        if( match && match.value ){
          datumArrayLabel = match.label
          acc.push({
            ...match,
            label,
            color,
            total: getDatasetTotal(datasetData),
          })
        }
        return acc
      }, [] as MappedTooltipDatum<T>[])
      coords && showTooltip({
        tooltipLeft: coords.x + 5,
        tooltipTop: coords.y + 5,
        tooltipData: {
          ...!datumArray && {
            datum: {
              ...datum,
              label: labelFormatter(datum.label),
              total: getDatasetTotal(data),
            }
          },
          ...(datumArray && {
            data: datumArray,
            label: (props.xTickFormatter || valueFormatter)(datumArrayLabel as unknown as number),
          } || {}),
        }
      })
    }

    return (
      <div style={divStyle}>

        <Component
          {...props}
          containerRef={containerRef}
          onSegmentMouseOver={handleMouseOver}
          onSegmentMouseOut={hideTooltip} />

        { tooltipOpen && (
          <TooltipInPortal
            key={Math.random()}
            top={tooltipTop}
            left={tooltipLeft}
            style={tooltipStyles}>
            <Tooltip
              tooltipData={tooltipData}
              renderPercentageOfTotal={!!props.tooltipPercentage}
              valueFormatter={valueFormatter} />
          </TooltipInPortal>
        )}

      </div>
    )
  }

  ComponentWithTooltip.displayName = `${Component.displayName}WithTooltip`

  return ComponentWithTooltip
}
