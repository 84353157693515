
import { ReduxAction, Dictionary, AuditProvider } from '@percept/types'


export const STORE_DEEPLINK_PARAMETERS = 'STORE_DEEPLINK_PARAMETERS'


export const storeDeeplinkParameters = (
  { payload, provider }: { payload: Dictionary, provider: AuditProvider }
): ReduxAction => ({
  type: STORE_DEEPLINK_PARAMETERS,
  payload,
  meta: {
    provider
  }
})
