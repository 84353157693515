import { get, intersection } from 'lodash-es'

import { GlobalPrivilege, OrgPrivilege, OrgPrivileges, User } from '@percept/types'

type PrivilegeOperator = 'ALL' | 'ANY'

const defaultPrivilegeOperator: PrivilegeOperator = 'ANY'

const checkPrivileges = (
  requiredPrivileges: string[],
  assignedPrivileges: string[] | undefined | null,
  operator: PrivilegeOperator,
): boolean => {
  if( !assignedPrivileges ){
    return false
  }
  if( operator === 'ANY' ){
    return !!(
      intersection(assignedPrivileges, requiredPrivileges).length
    )
  }
  for( const requiredPrivilege of requiredPrivileges ){
    if( assignedPrivileges.includes(requiredPrivilege) ){
      return true
    }
  }
  return false
}


export const userHasOrgPrivileges = (
  requiredOrgPrivileges: OrgPrivilege[],
  orgUnitId: string | null,
  userOrgPrivileges: OrgPrivileges[] | undefined | null,
  operator: PrivilegeOperator = defaultPrivilegeOperator,
): boolean => (
  checkPrivileges(
    requiredOrgPrivileges,
    get((userOrgPrivileges || []).find( p => p.org_id === orgUnitId ), 'privileges', null),
    operator,
  )
)


export const userHasGlobalPrivileges = (
  requiredGlobalPrivileges: GlobalPrivilege[],
  userGlobalPrivileges: GlobalPrivilege[] | undefined | null,
  operator: PrivilegeOperator = defaultPrivilegeOperator,
): boolean => (
  checkPrivileges(
    requiredGlobalPrivileges,
    userGlobalPrivileges,
    operator,
  )
)


export const isAdminUser = (user: User): boolean => (
  (user && user.groups || []).includes('Admins')
)
