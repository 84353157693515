import React from 'react'

import { Card, CardContent, CardMedia, makeAppStyles } from '@percept/mui'


const useStyles = makeAppStyles( theme => ({
  card: {
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  cardMedia: {
    height: 260,
    objectPosition: 'top',
  },
  metadata: {
    fontSize: 12,
    lineHeight: '26px',
    display: 'flex',
    alignItems: 'center',
  },
  metadataSeparator: {
    margin: theme.spacing(0, 0.75),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 25,
    lineHeight: '28px',
    fontWeight: 700,
  },
  summary: {
    fontSize: 18,
    lineHeight: '26px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  summaryLink: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 'auto',
    marginBottom: theme.spacing(2),
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
}))


export type LinkCardProps = {
  title: React.ReactNode
  summaryElements: React.ReactNode[]
  imageUrl?: string
  externalUrl: string
  externalUrlLinkText: string
  metadataContent?: React.ReactNode | JSX.Element
}


export const LinkCard = ({
  title,
  summaryElements,
  imageUrl,
  externalUrl,
  externalUrlLinkText,
  metadataContent,
}: LinkCardProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Card
      elevation={4}
      className={classes.card}>
      { imageUrl && (
        <CardMedia
          component='img'
          className={classes.cardMedia}
          src={imageUrl} />
      )}
      <CardContent>
        { !!metadataContent && (
          <div className={classes.metadata}>
            { metadataContent }
          </div>
        )}
        <div className={classes.title}>
          { title }
        </div>
        { summaryElements.map( (summary, i) => (
          summary && (
            <div key={i} className={classes.summary}>
              { summary }
            </div>
          )
        ))}
        <div className={classes.summaryLink}>
          <a href={externalUrl} target='_blank' rel='noopener noreferrer'>
            <strong>{externalUrlLinkText}</strong>
          </a>
        </div>
      </CardContent>
    </Card>
  )
}
