
import React from 'react'

import { Box, BoxProps, Typography, TypographyProps } from '@material-ui/core'

import { makeAppStyles } from '../../themes'

import { Info, Check, WarningOutlined, ErrorOutlined } from '../../icons'


type AlertVariant = 'info' | 'success' | 'warning' | 'error'

const iconPresets: Record<AlertVariant, JSX.Element> = {
  info: <Info />,
  success: <Check />,
  warning: <WarningOutlined />,
  error: <ErrorOutlined />
}


const useStyles = makeAppStyles( theme => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
  },
  icon: {
    marginRight: theme.spacing(1),
    lineHeight: 1,
  },
  inlineIcon: {
    display: 'inline-flex',
    marginRight: theme.spacing(0.5),
    '&> .MuiSvgIcon-root': {
      fontSize: '1.2rem',
    },
  },
  error: {
    background: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
    '&> .MuiSvgIcon-root': {
      color: theme.palette.error.light,
    },
  },
  warning: {
    background: theme.palette.warning.dark,
    color: theme.palette.warning.contrastText,
    '&> .MuiSvgIcon-root': {
      color: theme.palette.warning.light,
    },
  },
  info: {
    background: theme.palette.info.dark,
    color: theme.palette.info.contrastText,
    '&> .MuiSvgIcon-root': {
      color: theme.palette.info.light,
    },
  },
  success: {
    background: theme.palette.success.dark,
    color: theme.palette.success.contrastText,
    '&> .MuiSvgIcon-root': {
      color: theme.palette.success.light,
    },
  },
  message: {
    fontWeight: theme.typography.fontWeightBold,
  },
  inlineIconWrap: {
    display: 'flex',
  },
}) )


export type AlertProps = React.PropsWithChildren<BoxProps & {
  variant?: AlertVariant
  icon?: JSX.Element
  header?: string | JSX.Element
  message?: string | JSX.Element
  TypographyProps?: TypographyProps
}>

export const Alert = (
  { variant = 'info', header, message, icon, children, className = '', TypographyProps = {}, ...boxProps }: AlertProps
): JSX.Element => {

  const classes = useStyles()

  icon = icon || iconPresets[variant]

  const messageContent = message && (
    <Typography
      className={classes.message}
      variant='body1'
      {...TypographyProps}>
      { message }
    </Typography>
  )

  return (
    <Box
      className={`${classes.root} ${classes[variant]} ${className}`}
      p={1}
      {...boxProps}>

      { !!header && (
        <div className={classes.header}>
          { icon && (
            <div className={classes.icon}>
              { icon }
            </div>
          )}
          <Typography variant='h5'>
            { header }
          </Typography>
        </div>
      )}

      { messageContent && (
        !header && icon ? (
          <div
            className={classes.inlineIconWrap}>
            <span className={classes.inlineIcon}>
              { icon }
            </span>
            { messageContent }
          </div>
        ) : (
          messageContent
        )
      )}

      { children }

    </Box>
  )
}
