import React, { useEffect, useState } from 'react'

import { Formik, FormikErrors } from 'formik'

import { useQueryClient } from 'react-query'

import { useDispatch } from 'react-redux'

import { notifications } from '@percept/redux/bundles'

import {
  Box,
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  MassiveRoundedPlainTextButton,
  TextField,
  formatMoney,
} from '@percept/mui'

import { Check, ErrorOutline } from '@percept/mui/icons'

import { FormSection, useFormStyles } from './formComponents'

import { MarketSelect } from 'components/MarketDisplay'

import { useAddSponsorshipsMarketingCPMs, useSponsorshipsMarkets } from './api'

import { marketingCPMMetrics } from './MarketingCPMs'

import { mapValues } from 'lodash-es'

import {
  CreateSponsorshipMarketingCPMsPayload,
  FormViewProps,
  SponsorshipsMarketingCPMMetric,
} from './typings'


type FormValues = {
  currency: string
} & Record<SponsorshipsMarketingCPMMetric, number | ''>

// const initialValues: FormValues = { 
//   currency: 'GBP',
//   net_website_logged_out_cpm: 2.0,
//   net_website_logged_in_cpm: 2.0,
//   net_app_logged_out_cpm: 2.0,
//   net_app_logged_in_cpm: 2.0,
//   social_cpm: 2.0,
//   ooh_cpm: 8.0,
//   ooh_big_screen_cpm: 20.0,
//   email_cpm: 3.0,
//   email_cpc: 0.25,
//   radio_listens_cpl: 1.0,
//   instore_promo_cpm: 5.0,
//   facebook_cpm: 2.0,
//   instagram_cpm: 2.0,
//   twitter_cpm: 3.0,
//   other_social_cpm: 3.0,
// }

const emptyFormValues: FormValues = {
  currency: '',
  net_website_logged_out_cpm: '',
  net_website_logged_in_cpm: '',
  net_app_logged_out_cpm: '',
  net_app_logged_in_cpm: '',
  social_cpm: '',
  ooh_cpm: '',
  ooh_big_screen_cpm: '',
  email_cpm: '',
  email_cpc: '',
  radio_listens_cpl: '',
  instore_promo_cpm: '',
  facebook_cpm: '',
  instagram_cpm: '',
  twitter_cpm: '',
  other_social_cpm: '',
}

export const MarketingCPMsForm = ({ onSubmit }: FormViewProps): JSX.Element => {

  const { data: markets } = useSponsorshipsMarkets()

  const [marketId, setMarketId] = useState<string | null>(null)

  useEffect(() => {
    if( !marketId && markets && markets.length ){
      setMarketId(markets[0].id)
    }
  }, [marketId, markets])

  const dispatch = useDispatch()

  const queryClient = useQueryClient()

  const submissionHook = useAddSponsorshipsMarketingCPMs(marketId, {
    onMutate: () => {
      dispatch(notifications.actions.addNotification({
        id: 'create-marketing-cpms',
        type: 'info',
        loading: true,
        ttl: 0,
        message: 'Saving your CPM values...',
      }))
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sponsorshipsMarketingCPMs'] })
      dispatch(notifications.actions.addNotification({
        id: 'create-marketing-cpms',
        type: 'success',
        message: 'Your CPM values have been saved',
      }))
      window.scrollTo({ top: 0 })
      onSubmit()
    },
    onError: (error) => {
      dispatch(notifications.actions.addNotification({
        id: 'create-marketing-cpms',
        type: 'error',
        ttl: 0,
        message: `Your CPM values could not be saved - ${error.message}`,
      }))
    },
  })

  const classes = useFormStyles()

  return (
    <>
      <Box mb={4} display='flex' justifyContent='flex-end'>
        <MarketSelect
          value={marketId}
          options={markets || []}
          onChange={(market): void => setMarketId(market.id)} />
      </Box>
      
      <Formik
        initialValues={emptyFormValues}
        validate={(values): FormikErrors<FormValues> => {
          const errors: FormikErrors<FormValues> = {}
          if( !values.currency || values.currency.length !== 3 ){
            errors.currency = 'Currency is required'
          }
          for( const cpmMetric of marketingCPMMetrics ){
            if( values[cpmMetric.metric] === '' ){
              errors[cpmMetric.metric] = `${cpmMetric.label} is required`
            }
          }
          return errors
        }}
        onSubmit={(values): void => {
          const { currency, ...cpm_values } = values
          const payload: CreateSponsorshipMarketingCPMsPayload = {
            currency,
            cpm_values: mapValues(cpm_values, v => Number(v || 0) )
          }
          submissionHook.mutate(payload)
        }}>
        { ({ values, isValid, handleBlur, handleChange, handleSubmit }): JSX.Element => (
          <form noValidate onSubmit={handleSubmit}>
            <Card elevation={2}>
              <FormSection title='Estimated Marketing CPMs' bgcolor='primary.main' color='white'>
                <Box p={3}>
                  <FormControl className={classes.inputField}>
                    <FormHelperText>Currency</FormHelperText>
                    <TextField
                      type='text'
                      variant='filled'
                      name='currency'
                      placeholder='For example EUR or GBP'
                      value={values.currency}
                      onChange={handleChange}
                      onBlur={handleBlur} />
                  </FormControl>
                  { marketingCPMMetrics.map( ({ metric, label }) => (
                    <FormControl key={metric} className={classes.inputField}>
                      <FormHelperText>
                        { label }
                      </FormHelperText>
                      <TextField
                        type='number'
                        variant='filled'
                        name={metric}
                        value={values[metric]}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              { formatMoney({ amount: values[metric] || null, currency: values.currency }) }
                            </InputAdornment>
                          )
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                    </FormControl>
                  ))}
                </Box>
              </FormSection>
            </Card>

            <Box mt={8} display='flex' justifyContent='center'>
              <MassiveRoundedPlainTextButton
                type='submit'
                size='large'
                color='primary'
                variant='contained'
                startIcon={
                  !isValid ?
                    <ErrorOutline /> :
                    submissionHook.isLoading ?
                      <CircularProgress size='1em' color='inherit' /> :
                      <Check />
                }
                disabled={!marketId || !isValid || submissionHook.isLoading}>
                { !isValid ? 'All Form Fields Are Required' : 'Submit Estimated Marketing CPMs' }
              </MassiveRoundedPlainTextButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  )
}
