import React from 'react'
import { Box, Card, Typography } from '@material-ui/core'
import { makeAppStyles } from '@percept/mui'

interface StepItemProps {
  title: string
  label: string
  isOnTrack: boolean
}

const useStyles = makeAppStyles((theme) => ({
  title: {
    fontSize: 20,
    lineHeight: '40px',
    fontWeight: 700,

    '&.isOnTrack': {
      color: theme.chart.healthColourScale(1),
    },
    '&.isNotOnTrack': {
      color: theme.chart.healthColourScale(0),
    },
  },
  message: {
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 400,
    marginTop: 12,
  },
}))

export function StepItem({
  title,
  label,
  isOnTrack,
}: StepItemProps): JSX.Element {
  const classes = useStyles()

  return (
    <Card>
      <Box p={3} className={classes.box}>
        <Typography
          variant='h4'
          className={`${classes.title} ${
            isOnTrack ? 'isOnTrack' : 'isNotOnTrack'
          }`}>
          {title}
        </Typography>
        <Typography className={classes.message}>{label}</Typography>
      </Box>
    </Card>
  )
}
