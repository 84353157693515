
import React from 'react'

import { ChannelThemeProvider, ChannelThemeProviderProps } from '@percept/mui'

import { DefaultLayout, DefaultLayoutProps } from './DefaultLayout'


export type ChannelLayoutProps = React.PropsWithChildren<
  Pick<ChannelThemeProviderProps, 'channel'> &
  DefaultLayoutProps
>


export const ChannelLayout = ({ channel, AppBarProps = {}, ...props }: ChannelLayoutProps): JSX.Element => {

  const mergedAppBarProps: Partial<DefaultLayoutProps['AppBarProps']> = {
    ...(AppBarProps),
    // color: channel ? 'secondary' : 'primary'
  }

  return (
    <ChannelThemeProvider channel={channel}>

      <DefaultLayout
        {...props}
        AppBarProps={mergedAppBarProps} />

    </ChannelThemeProvider>
  )
}
