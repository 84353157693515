import { Box, Button } from '@material-ui/core'
import React from 'react'
import { CampaignWithKey } from '../types'
import { useFormTableStyles } from './TableForm'
import { TableFormTotal } from './TableFormTotal'
import { TableInput, useTableInputStyles } from './TableInput'


const submissionEnabled = process.env.WORKLOAD_TYPE !== 'PROD'

type Props = {
  tableData: CampaignWithKey[]
  totalByMarket: string[]
  currency: string
  financialYear: string
  months: { name: string; id: string }[]
  submitButtonDisabled: boolean
  editingEnabled?: boolean
  handleInputChange: (e: any, month: string, id: number) => void
  handleSubmitButton: () => void
}

export const TableInputSide = ({
  tableData,
  totalByMarket,
  financialYear,
  months,
  currency,
  editingEnabled = true,
  submitButtonDisabled,
  handleInputChange,
  handleSubmitButton,
}: Props) => {
  const classes = useFormTableStyles()
  const tableInputClasses = useTableInputStyles()
  const restMonth = months.slice(0, -1)
  const currentMonth = months.slice(-1)[0]

  return (
    <Box display='flex'>
      <div>
        {tableData.map((el, id) => (
          <Box
            display='flex'
            className={classes.border}
            flexDirection='row'
            justifyContent='space-between'
            pl='16px'
            width='fit-content'
            key={el.key}>
            {restMonth.map((e, i) => (
              <TableInput
                key={i}
                classes={tableInputClasses}
                value={el.data.find((i) => i.month === e.name)?.amount || ''}
                editingEnabled={editingEnabled}
                handleInputChange={handleInputChange}
                month={e.name}
                id={id}
              />
            ))}
          </Box>
        ))}
      </div>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        width='156px'
        className={
          submitButtonDisabled
            ? classes.disabledSubmitBorder
            : classes.submitBorder
        }
        style={{
          borderWidth: '2px',
          borderStyle: 'solid',
          borderTopWidth: 0,
        }}>
        {tableData.map((el, id) => (
          <div key={el.key} className={classes.border}>
            <TableInput
              classes={tableInputClasses}
              value={
                el.data.find((i) => i.month === currentMonth.name)?.amount || ''
              }
              editingEnabled={editingEnabled}
              handleInputChange={handleInputChange}
              month={currentMonth.name}
              id={id}
            />
          </div>
        ))}
        { editingEnabled && submissionEnabled && !!tableData.length && (
          <Button
            onClick={handleSubmitButton}
            variant='contained'
            color='secondary'
            disabled={submitButtonDisabled}
            className={classes.submitButton}>
            Submit
          </Button>
        )}
      </Box>
      <TableFormTotal
        currency={currency}
        totalData={totalByMarket}
        financialYear={financialYear}
      />
    </Box>
  )
}
