import { sortBy, uniq } from 'lodash-es'

import { isPlatformUnitContainer, coerceReportProvider, isDoubleVerifyPlatformUnit } from '@percept/utils'

import { providerEnumMap } from '@percept/constants'

import {
  PlatformUnit,
  PlatformUnitContainer,
  PlatformUnitLeaf,
  PlatformUnitChildType,
  ReportProvider,
  ReportProviderEnum,
  DoubleVerifyProviderEnum,
  PlatformUnitProviderInfo,
  ReportProviderObject,
} from '@percept/types'


const withMappedProviderType = <
  T extends { provider: ReportProviderObject }
>(item: T): Omit<T, 'provider_type'> & { provider_type: ReportProvider } => {
  return {
    ...item,
    provider_type: coerceReportProvider(item.provider.slug)
  }
}


const deriveChildOrgType = (children: PlatformUnit[]): PlatformUnitChildType | null => {
  if( !children || !children.length ) return null
  const seenTypes = uniq(children.map( c => c.unit_type ))
  return seenTypes[0] || null
}


// const platformUnitReportSeries = (response: PlatformUnit['report_series']): PlatformUnit['report_series'] => {
//   return response && response.filter( s => !!s.enabled ).map(withMappedProviderType)
// }


export const platformUnit = (response: PlatformUnit): PlatformUnit => {

  let performance_reporting = response.performance_reporting && {
    ...response.performance_reporting,
    providers: (response.performance_reporting.providers || []).map(withMappedProviderType)
  }

  if( performance_reporting && isDoubleVerifyPlatformUnit(response) ){
    performance_reporting = {
      ...performance_reporting,
      providers: performance_reporting.providers.map( p => ({
        ...p,
        augmentations: {
          doubleverify: p.provider_type !== 'dv360',
        }
      }))
    }
  }

  if( isPlatformUnitContainer(response) ){
    return {
      ...response,
      unit_type: (
        deriveChildOrgType(response.children || []) || undefined
      ),
      children: sortBy(
        (response.children || []).map(platformUnit),
        'name',
      ),
      performance_reporting,
    } as PlatformUnitContainer
  }

  return {
    ...response,
    //report_series: platformUnitReportSeries(response.report_series),
    performance_reporting,
  } as PlatformUnitLeaf

}



export const platformUnitProviderInfo = (
  response: {
    provider_id: ReportProviderEnum | DoubleVerifyProviderEnum
    reference_date: string
  }[]
): PlatformUnitProviderInfo => {
  return response.reduce((acc, { provider_id, reference_date }) => {
    acc[providerEnumMap[provider_id]] = {
      reference_date: new Date(reference_date),
    }
    return acc
  }, {} as PlatformUnitProviderInfo)
}
