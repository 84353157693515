import { ButtonProps, ClassNameMap, InputProps } from '@percept/mui'

import { Dictionary, RequiresMFAResponse, SingleSignOnMetadata, User } from '@percept/types'
import { AuthInputProps } from './Inputs'
import { SSO_REDIRECT_URL_PATH } from './constants'


export type AuthActionType = (
  'SIGN_OUT' | 'SIGN_IN' | 'SIGN_IN_SUCCESS' |
  'USER_ONBOARDING' | 'ONBOARD_SIGN_IN' | 'STORE_ONBOARD_PARAMS' |
  'SET_ERROR' | 'USER_NOT_FOUND' |
  'CONFIRM_SIGN_IN_REQUIRED' | 'CONFIRM_SIGN_UP_REQUIRED' |
  'PASSWORD_RESET_REQUIRED' | 'NEW_PASSWORD_REQUIRED' | 'FORGOT_PASSWORD' |
  'CONTACT_VERIFICATION_REQUIRED' | 'SETUP_TOTP' |
  'UPDATE_INPUTS' | 'REQUIRE_MFA'
)

type ErrorType = Error | Dictionary | null

export type AuthAction = {
  type: AuthActionType
  payload?: any
  error?: ErrorType
}

export type AuthInputs = {
  username?: string
  name?: string
  password?: string
  email?: string
  code?: string
  series_group_name?: string
}

export type AuthComponentClasses = ClassNameMap<'heading'>

export type AuthComponentProps = {
  loading: boolean
  onboarding: boolean
  invitationExpired: boolean
  isUnknownLogin: boolean
  error: ErrorType
  user: UserIdent | null
  mfaChallenge: RequiresMFAResponse | null
  inputs: AuthInputs
  initialToken: string | null
  updateInputs: (update: Partial<AuthInputs>) => void
  attemptSignIn: () => void
  setInitialPassword: () => void
  dispatch: React.Dispatch<AuthAction>
  classes: AuthComponentClasses
  enableSingleSignOn?: boolean
  hideSignInHeader?: boolean
  AuthInputProps?: Partial<AuthInputProps>
  ButtonComponent: React.FC<ButtonProps>
  onSignUp?: (() => void) | null
}

export type UserIdent = (
  Pick<User, 'email'> &
  Partial<Omit<User, 'email'>>
)

export type AuthStatus = (
  'loading' | 'signedIn' | 'signIn' | 'onboard' |
  'confirmSignIn' | 'confirmSignUp' |
  'requireNewPassword' | 'forgotPassword' |
  'TOTPSetup' | 'verifyContact' | 'requireMFA'
)


export const getErrorMessage = (err: string | Error): string =>
  typeof err === 'string' ? err : (
    err.message || JSON.stringify(err)
  )


export const getSingleSignOnLink = ({
  domain,
  client_id,
  scopes,
  idp_name,
}: SingleSignOnMetadata): string => {
  const { protocol, host } = window.location
  const qsParams = {
    client_id,
    scope: scopes.join(' '),
    response_type: 'CODE',
    redirect_uri: `${protocol}//${host}${SSO_REDIRECT_URL_PATH}`,
    identity_provider: idp_name,
  }
  const queryStringFragments = Object.entries(qsParams).map(([key, value]) => {
    return `${key}=${value}`
  })
  const queryString = queryStringFragments.join('&')
  return (
    `https://${domain}/oauth2/authorize?${queryString}`
  )
}
