
import { AsyncReduxAction, ProviderParams } from '@percept/types'


export const LOAD_METRIC_METADATA = 'LOAD_METRIC_METADATA'

export const loadMetricMetadata = ({ provider = '', report_layout_id = null } = {}): AsyncReduxAction => ({
  type: LOAD_METRIC_METADATA,
  resource: (
    provider ? `/metadata/provider/${provider}` : 
      report_layout_id ? `metadata/layout/${report_layout_id}` :
        '/metadata'
  ),
  meta: {
    ...(provider && { provider } || {}),
    ...(report_layout_id && { report_layout_id } || {})
  }
})

export const loadFilteredMetricMetadata = ({ provider }: ProviderParams): AsyncReduxAction => ({
  type: LOAD_METRIC_METADATA,
  resource:  `/metadata/provider/${provider}`,
  meta: {
    provider,
  }
})
