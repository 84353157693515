
export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}


export const getRandomFloat = (min: number, max: number): number => {
  return (Math.random() * (max - min)) + min
}


export const makeNumberRandomiser = (min: number, max: number) => (
  (n: number, round = false): number => {
    const r = getRandomFloat(min, max) * n
    return round ? Math.round(r) : r
  }
)

export const randomNumberLike = makeNumberRandomiser(0.85, 1.15)
export const randomNumberLessThan = makeNumberRandomiser(0.65, 0.85)
export const randomNumberMoreThan = makeNumberRandomiser(1.15, 1.35)
