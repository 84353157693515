import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeAppStyles } from '@percept/mui'
import {
  COMPETITIVE_INVESTMENT_FORM_COLUMNS,
  COMPETITIVE_TV_SHARE_OF_VOICE_FORM_COLUMNS,
  MEDIA_INVESTMENT_FORM_COLUMNS,
} from 'constans'
import React, { useEffect, useState } from 'react'
import { ConflictResolution } from './ConflictResolution'
import { Close, Done } from '@material-ui/icons'
import { checkResolvedConflicts, combineAllConflicts } from './helpers'
import { Campaign, Conflicts } from '../types'
import { WebFormTypes } from 'enums/WebFormTypes'

export const useConflictStyles = makeAppStyles((theme) => ({
  propertyLabel: {
    textTransform: 'uppercase',
    flexGrow: 1,
  },
  propertyValue: {
    display: 'flex',
    alignItems: 'center',
  },
  propertyContainer: {
    minHeight: '40px',

    margin: '10px 0',
    backgroundColor: theme.palette.action.disabledBackground,
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
  },
  header: {
    height: '40px',
    borderTop: '1px solid',
    borderColor: theme.palette.action.disabled,
  },
  columnHeader: {
    fontSize: '12px',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
  },
  buttonsGroup: {
    display: 'flex',
    gap: '10px',
    alignSelf: 'flex-end',
    textTransform: 'none',
    marginTop: '20px',
    '& > *': {
      textTransform: 'none',
    },
  },
}))

export const ConflictHandling = ({
  data,
  updateConflicts,
  createConflicts,
  deleteConflicts,
  conflictTime,
  saveChanges,
  webformType,
}: {
  data: Campaign[]
  createConflicts: Conflicts['create_conflicts']
  updateConflicts: Conflicts['update_conflicts']
  deleteConflicts: { id: string; modified: string; amount: string }[]
  conflictTime: string
  webformType: WebFormTypes
  saveChanges: (resolvedConflicts: Campaign[]) => void
}) => {
  const classes = useConflictStyles()
  const [expandAll, setExpandAll] = useState(true)
  const [completed, setCompleted] = useState(false)

  const webformColumns =
    webformType === WebFormTypes.MediaInvestment
      ? MEDIA_INVESTMENT_FORM_COLUMNS
      : webformType === WebFormTypes.CompetitiveInvestment
        ? COMPETITIVE_INVESTMENT_FORM_COLUMNS
        : COMPETITIVE_TV_SHARE_OF_VOICE_FORM_COLUMNS
  const formattedCreateConflicts = createConflicts.map((el) =>
    el.equiv_spot_lengths
      ? {
        ...el,
        equiv_spot_lengths: {
          id: el.equiv_spot_lengths,
          name: el.equiv_spot_lengths,
        },
        data: el.data.map((e) => ({ ...e, new_amount: e.amount })),
      }
      : el.spending_type
        ? {
          ...el,
          spending_type: {
            id: el.spending_type,
            name: el.spending_type,
          },
          data: el.data.map((e) => ({ ...e, new_amount: e.amount })),
        }
        : {
          ...el,
          data: el.data.map((e) => ({ ...e, new_amount: e.amount })),
        }
  )

  const formattedDeleteConflicts =
    deleteConflicts.length > 0
      ? deleteConflicts.map((el) => ({ id: el.id, new_amount: el.amount }))
      : updateConflicts
  const conflicts = combineAllConflicts(
    data,
    formattedCreateConflicts,
    formattedDeleteConflicts
  )
  const [resolvedConflicts, setResolvedConflicts] = useState(conflicts)
  const [expanded, setExpanded] = useState<boolean[]>(
    new Array(resolvedConflicts.length).fill(false)
  )
  const handleExpandAll = (): void => {
    setExpanded(new Array(resolvedConflicts.length).fill(expandAll))
    setExpandAll((p) => !p)
  }

  const onClick = (idx: number): void => {
    setExpanded((p) => p.map((el, i) => (i === idx ? !el : el)))
  }

  const handleReject = (
    e: { stopPropagation: () => void },
    idx: string,
    newValue: string
  ): void => {
    e.stopPropagation()
    const reject = resolvedConflicts.map((el) => ({
      ...el,
      data: el.data.map((e) =>
        e.id === idx
          ? newValue === '0.00'
            ? {
              ...e,
              action: 'delete',
              amount: newValue,
              solved: 'rejected',
              modified: conflictTime,
            }
            : {
              ...e,
              amount: newValue,
              solved: 'rejected',
              modified: conflictTime,
            }
          : e
      ),
    }))
    setResolvedConflicts(reject)
  }

  const handleAccept = (
    e: { stopPropagation: () => void },
    idx: string,
    newValue: string
  ) => {
    e.stopPropagation()
    const accept = resolvedConflicts.map((el) => ({
      ...el,
      data: el.data.map((e) =>
        e.id === idx
          ? newValue === '0.00'
            ? {
              ...e,
              solved: 'accepted',
              modified: conflictTime,
              action: 'create',
            }
            : { ...e, solved: 'accepted', modified: conflictTime }
          : e
      ),
    }))
    setResolvedConflicts(accept)
  }

  const handleUndo = (e: { stopPropagation: () => void }, idx: string) => {
    e.stopPropagation()
    const initialCell = data
      .find((el) => el.data.find((e) => e.id === idx))
      ?.data.find((el): unknown => el.id === idx)
    const initialData = resolvedConflicts.map((el) => ({
      ...el,
      data: el.data.map((e) =>
        e.id === idx ? { ...e, amount: initialCell.amount, solved: '' } : e
      ),
    }))
    setResolvedConflicts(initialData)
  }

  useEffect(() => {
    setCompleted(checkResolvedConflicts(resolvedConflicts))
  }, [resolvedConflicts])

  const handleAcceptAll = () => {
    const allAccepted = conflicts.map((el) => ({
      ...el,
      data: el.data.map((e) =>
        formattedDeleteConflicts.find((conf) => conf.id === e.id)
          ?.new_amount === '0.00'
          ? {
            ...e,
            solved: 'accepted',
            action: 'create',
            modified: conflictTime,
          }
          : {
            ...e,
            solved: 'accepted',
            modified: conflictTime,
          }
      ),
    }))

    setResolvedConflicts(allAccepted)
  }

  const handleRejectAll = () => {
    const allRejected = resolvedConflicts.map((el) => ({
      ...el,
      data: el.data.map((e) => {
        const modifiedCell =
          formattedDeleteConflicts.find((conflict) => conflict.id === e.id) ||
          formattedCreateConflicts
            .find((conflict) =>
              conflict.data.some((spending) => spending.month === e.month)
            )
            ?.data.find((spending) => spending.id === e.id)
        return modifiedCell
          ? modifiedCell.new_amount === '0.00'
            ? {
              ...e,
              amount: modifiedCell.new_amount,
              action: 'delete',
              solved: 'rejected',
              modified: conflictTime,
            }
            : {
              ...e,
              amount: modifiedCell.new_amount,
              solved: 'rejected',
              modified: conflictTime,
            }
          : e
      }),
    }))
    setResolvedConflicts(allRejected)
  }

  return (
    <Box display='flex' flexDirection='column'>
      <Button
        variant='outlined'
        style={{ alignSelf: 'flex-end', marginBottom: '15px' }}
        onClick={handleExpandAll}>
        Expand All
      </Button>

      <Grid container justify='center' className={classes.header}>
        <Grid item xs={3} className={classes.columnHeader}>
          Last version
        </Grid>
        <Grid item xs={3} className={classes.columnHeader}>
          Your version
        </Grid>
      </Grid>
      {resolvedConflicts.map((conflict, idx) => {
        if (conflict.conflict) {
          return (
            <Accordion
              key={idx}
              expanded={expanded[idx]}
              onClick={(): void => onClick(idx)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                style={{
                  flexDirection: 'row-reverse',
                  display: 'flex',
                  gap: '10px',
                }}>
                <Typography>Row {idx + 1}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display='flex'
                  flexDirection='column'
                  style={{ width: '100%' }}>
                  {webformColumns.map((el, i) => (
                    <Grid
                      container
                      key={i}
                      className={classes.propertyContainer}>
                      <Grid item xs={3} className={classes.gridItem}>
                        <Typography className={classes.propertyLabel}>
                          {el.label}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} className={classes.gridItem}>
                        <Typography variant='body1'>
                          {conflict[el.name as keyof Campaign]?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} className={classes.gridItem}>
                        <Typography variant='body1'>
                          {conflict[el.name as keyof Campaign]?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} />
                    </Grid>
                  ))}
                  {conflict.data.map((monthCell) => {
                    if (!monthCell.id) {
                      return null
                    }

                    const modifiedCell =
                      formattedDeleteConflicts.find(
                        (el) => el.id === monthCell.id
                      ) ||
                      formattedCreateConflicts
                        .find((el) =>
                          el.data.some((e) => e.month === monthCell.month)
                        )
                        ?.data.find((el) => el.id === monthCell.id)
                    return modifiedCell ? (
                      <ConflictResolution
                        cell={monthCell}
                        prevCell={modifiedCell}
                        handleReject={handleReject}
                        handleAccept={handleAccept}
                        handleUndo={handleUndo}
                      />
                    ) : (
                      <Grid
                        container
                        key={monthCell.id}
                        xs={12}
                        className={classes.propertyContainer}>
                        <Grid item xs={3} className={classes.gridItem}>
                          <Typography className={classes.propertyLabel}>
                            {monthCell.month}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} className={classes.gridItem}>
                          <Typography variant='body1'>
                            {monthCell.amount}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} className={classes.gridItem}>
                          <Typography variant='body1'>
                            {monthCell.amount}
                          </Typography>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>
          )
        }
      })}
      <Box className={classes.buttonsGroup}>
        <Button
          variant='outlined'
          startIcon={<Close />}
          onClick={handleRejectAll}>
          Reject All
        </Button>
        <Button
          variant='outlined'
          startIcon={<Done />}
          onClick={handleAcceptAll}>
          Accept All
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={!completed}
          onClick={() => saveChanges(resolvedConflicts)}>
          Save changes
        </Button>
      </Box>
    </Box>
  )
}
