import { combineReducers } from 'redux'

import { createApiReducer, createResetReducer } from '../../reducers'

import { getFetchTypes } from '../../utils'

import {
  OAUTH_REFRESH,
  SIGN_IN,
  SIGN_OUT,
  USER_LOGIN,
  USER_LOGOUT,
  LOAD_MY_USER,
  LOAD_MY_USER_DETAIL,
  TOKEN_AUTOLOGIN,
  BEGIN_SOFTWARE_MFA_SETUP,
  COMPLETE_SOFTWARE_MFA_SETUP,
  RESPOND_TO_CODE_CHALLENGE,
  RESET_BEGIN_SOFTWARE_MFA_SETUP,
  RESET_COMPLETE_SOFTWARE_MFA_SETUP,
  TOGGLE_SOFTWARE_MFA_STATE,
  RESET_TOGGLE_SOFTWARE_MFA_STATE,
  RESET_MFA_SETUP,
  STORE_MFA_DELIVERY_MEDIUM,
  SET_INITIAL_PASSWORD,
  LOAD_SINGLE_SIGN_ON_METADATA,
  EXCHANGE_SINGLE_SIGN_ON_CODE,
  OAUTH_REFRESH_SSO,
  LOAD_MY_USER_PRIVILEGES,
} from './actions'

import { user, platformUnit } from '@percept/api/adapters'

import { Reducer } from 'redux'

import { get } from 'lodash-es'

import { MFADeliveryMedium, ReduxAction } from '@percept/types'

import { UserState } from './typings'


const loggedIn: Reducer<UserState['loggedIn'], ReduxAction> = (state = false, action) => (
  action.type === USER_LOGIN ?
    true :
    action.type === USER_LOGOUT ?
      false :
      state
)

const attributes: Reducer<UserState['attributes'], ReduxAction> = (state = null, action) => (
  action.type === USER_LOGIN ?
    user(action.payload || {}) :
    action.type === USER_LOGOUT ?
      null :
      state
)


const signInFetchTypes = getFetchTypes(SIGN_IN)

const oAuthRefreshFetchTypes = getFetchTypes(OAUTH_REFRESH)

const codeChallengeFetchTypes = getFetchTypes(RESPOND_TO_CODE_CHALLENGE)

const ssoExchangeFetchTypes = getFetchTypes(EXCHANGE_SINGLE_SIGN_ON_CODE)

const ssoRefreshFetchTypes = getFetchTypes(OAUTH_REFRESH_SSO)


const signInResponse: Reducer<UserState['signInResponse'], ReduxAction> = createApiReducer(
  SIGN_IN
)

const setInitialPasswordResponse: Reducer<UserState['setInitialPasswordResponse'], ReduxAction> = createApiReducer(
  SET_INITIAL_PASSWORD, {
    resetActionTypes: [signInFetchTypes.success],
  }
)

const refreshResponse: Reducer<UserState['refreshResponse'], ReduxAction> = createApiReducer(
  OAUTH_REFRESH
)

const singleSignOnRefreshResponse: Reducer<UserState['singleSignOnRefreshResponse'], ReduxAction> = createApiReducer(
  OAUTH_REFRESH_SSO
)

const singleSignOnMetadataResponse: Reducer<UserState['singleSignOnMetadataResponse'], ReduxAction> = createApiReducer(
  LOAD_SINGLE_SIGN_ON_METADATA
)

const singleSignOnCodeExchange: Reducer<UserState['singleSignOnCodeExchange'], ReduxAction> = createApiReducer(
  EXCHANGE_SINGLE_SIGN_ON_CODE
)


const refreshTokenSuccessTypes = [
  signInFetchTypes.success,
  codeChallengeFetchTypes.success,
  ssoExchangeFetchTypes.success,
  TOKEN_AUTOLOGIN,
]

const idTokenSuccessTypes = [
  signInFetchTypes.success,
  codeChallengeFetchTypes.success,
  ssoExchangeFetchTypes.success,
  oAuthRefreshFetchTypes.success,
  ssoRefreshFetchTypes.success,
]

const accessTokenSuccessTypes = [
  signInFetchTypes.success,
  oAuthRefreshFetchTypes.success,
  codeChallengeFetchTypes.success,
  ssoExchangeFetchTypes.success,
  ssoRefreshFetchTypes.success,
]


const refreshToken: Reducer<UserState['refreshToken'], ReduxAction> = (state = null, action) => (
  refreshTokenSuccessTypes.includes(action.type) ?
    action.payload.refresh_token :
    state
)

const idToken: Reducer<UserState['idToken'], ReduxAction> = (state = null, action) => (
  idTokenSuccessTypes.includes(action.type) ?
    action.payload.id_token :
    state
)

const accessToken: Reducer<UserState['accessToken'], ReduxAction> = (state = null, action) => (
  accessTokenSuccessTypes.includes(action.type) ?
    action.payload.access_token :
    state
)

const userId: Reducer<UserState['userId'], ReduxAction> = (state = null, action) => (
  refreshTokenSuccessTypes.includes(action.type) ?
    action.payload.user_id :
    state
)


const userPlatformUnitsFetchTypes = getFetchTypes(LOAD_MY_USER)

const defaultPlatformUnitId: Reducer<UserState['defaultPlatformUnitId'], ReduxAction> = (state = null, action) => (
  action.type === userPlatformUnitsFetchTypes.success ?
    (get(action.payload, 'default_org_unit_id') || null) :
    state
)

const me: Reducer<UserState['me'], ReduxAction> = createApiReducer(LOAD_MY_USER, {
  getPayloadFromAction: ({ payload }) => {
    return {
      ...payload,
      org_units: (payload.org_units || []).map(platformUnit),
    }
  }
})

const detail: Reducer<UserState['detail'], ReduxAction> = createApiReducer(LOAD_MY_USER_DETAIL, {
  getPayloadFromAction: ({ payload }) => {
    return {
      ...payload,
      org_units: (payload.org_units || []).map(platformUnit),
    }
  }
})

const privileges: Reducer<UserState['privileges'], ReduxAction> = createApiReducer(LOAD_MY_USER_PRIVILEGES)


const mfaToggleFetchTypes = getFetchTypes(TOGGLE_SOFTWARE_MFA_STATE)


const mfa: Reducer<UserState['mfa'], ReduxAction> = combineReducers({
  beginSoftwareMfaSetup: createApiReducer(BEGIN_SOFTWARE_MFA_SETUP, {
    resetActionTypes: [RESET_MFA_SETUP, RESET_BEGIN_SOFTWARE_MFA_SETUP],
  }),
  completeSoftwareMfaSetup: createApiReducer(COMPLETE_SOFTWARE_MFA_SETUP, {
    resetActionTypes: [RESET_MFA_SETUP, RESET_COMPLETE_SOFTWARE_MFA_SETUP],
  }),
  toggleSoftwareMfaState: createApiReducer(TOGGLE_SOFTWARE_MFA_STATE, {
    resetActionTypes: [RESET_MFA_SETUP, RESET_TOGGLE_SOFTWARE_MFA_STATE],
  }),
  codeChallenge: createApiReducer(RESPOND_TO_CODE_CHALLENGE),
  derivedActiveDeliveryMedium: (state = null, action): MFADeliveryMedium | null => {
    // No MFA if login via username and password is successful
    if( action.type === signInFetchTypes.success ){
      return null
    }
    if( action.type === STORE_MFA_DELIVERY_MEDIUM ){
      return action.payload
    }
    if( action.type === codeChallengeFetchTypes.success ){
      return 'SOFTWARE_TOKEN_MFA'
    }
    if( action.type === mfaToggleFetchTypes.success && action.meta ){
      const enabled = !!action.meta.enabled
      if( enabled ){
        return 'SOFTWARE_TOKEN_MFA'
      }else{
        return null
      }
    }
    return state
  }
})


const canManageMFA: Reducer<UserState['canManageMFA'], ReduxAction> = (state = null, action) => {
  if( action.type === TOKEN_AUTOLOGIN ){
    return action.payload && action.payload.source === 'LOGIN'
  }
  if( action.type === ssoExchangeFetchTypes.success ){
    return false
  }
  if( action.type === signInFetchTypes.success || action.type === codeChallengeFetchTypes.success ){
    return true
  }
  return state
}


const userStateReducer: Reducer<UserState, ReduxAction> = combineReducers({
  loggedIn,
  attributes,
  signInResponse,
  setInitialPasswordResponse,
  refreshResponse,
  idToken,
  refreshToken,
  accessToken,
  userId,
  defaultPlatformUnitId,
  me,
  detail,
  privileges,
  mfa,
  canManageMFA,
  singleSignOnMetadataResponse,
  singleSignOnCodeExchange,
  singleSignOnRefreshResponse,
})


const reducer: Reducer<UserState, ReduxAction> = createResetReducer(
  [SIGN_OUT], userStateReducer
)


export default reducer
