import { Location } from 'history'
import qs from 'query-string'
import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import PageNotFound from 'screens/PageNotFound'
import PerformanceReport from 'screens/PerformanceReport'
import Report from 'screens/Report'
import Series from 'screens/Series'
import SeriesAuthHandler from 'screens/SeriesAuthHandler'
import Channel from './screens/Channel'
import HealthVsPerformance from './screens/HealthVsPerformance'
import Insights from './screens/Insights'
import Organisation from './screens/Organisation'
import Provider from './screens/Provider'
import MediaQualityRate from 'screens/MediaQualityRate'
import DataWarehouse from 'screens/DataWarehouse'

export const isLoginLocation = ({ pathname }: Location): boolean => (
  pathname.includes('sign-in')
)

export const isAccountCreationLocation = ({ search }: Location): boolean => (
  search.includes('token') && search.includes('username')
)

export const getSignInRoute = ({ search }: Location): string => {
  const { initial_referrer, username, token } = qs.parse(search)
  if (username && token ){
    return `/sign-in?${qs.stringify({ username, token })}`
  }
  if (initial_referrer ){
    return `/sign-in?${qs.stringify({ initial_referrer })}`
  }
  return '/sign-in'
}

const HomeRedirect = (): JSX.Element => <Redirect to='/' />

const DashboardRoutes = (): JSX.Element => {
  
  const { path } = useRouteMatch()

  return <Switch>

    <Route exact path={`${path}`} component={Organisation} />
    
    <Route exact path={`${path}performance`} component={Organisation} />

    <Route exact path={`${path}(search|social|programmatic)`} component={Channel} />

    <Route exact path={`${path}(google-ads|facebook|adform|dv360|amazon-ads|tiktok)`} component={Provider} />

    <Route exact path={`${path}dashboards/:org_unit_id/performance`} component={Organisation} />

    <Route exact path={`${path}dashboards/:org_unit_id/(search|social|programmatic)`} component={Channel} />

    <Route exact path={`${path}dashboards/:org_unit_id/(google-ads|facebook|adform|dv360|amazon-ads|tiktok)`} component={Provider} />

    <Route exact path={`${path}dashboards/:org_unit_id/health-vs-performance`} component={HealthVsPerformance} />

    <Route exact path={`${path}dashboards/:org_unit_id/insights`} component={Insights} />

    <Route exact path={`${path}dashboards/:org_unit_id/performance-report`} component={PerformanceReport} />

    <Route exact path={`${path}dashboards/:org_unit_id/media-quality-rate`} component={MediaQualityRate} />

    <Route exact path={`${path}dashboards/:org_unit_id/data-warehouse`} component={DataWarehouse} />

    <Route exact path={`${path}dashboards/:org_unit_id`} component={Organisation} />


    <Route exact path={`${path}signup`}>
      { HomeRedirect }
    </Route>

    <Route exact path={`${path}adwords-oauth-redirect`} component={SeriesAuthHandler} />

    <Route exact path={`${path}series-groups/:series_group_id`}>
      { HomeRedirect }
    </Route>

    <Route exact path={`${path}sign-in`}>
      { HomeRedirect }
    </Route>

    { /* Reinstate legacy report URLs! */ }
    <Route exact path={`${path}series/:series_id/reports/:report_id`} component={Report} />

    {/* NOTE - deprecated */}
    {/* <Route exact path={`${path}series/:series_id/insights-reports/:insights_report_type`} component={InsightsReport} /> */}

    <Route exact path={`${path}series/:series_id`} component={Series} />

    <Route component={PageNotFound} />

  </Switch>
}

export default DashboardRoutes
