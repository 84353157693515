import { darken, lighten } from '@material-ui/core'
import { deepPurple, lightBlue, pink } from '@material-ui/core/colors'

import { merge } from 'lodash-es'

import { Percept } from '../components/Logos'

import { AppThemeOptions } from './themeAugmentation'

import { TypographyStyleOptions } from '@material-ui/core/styles/createTypography'


type PerceptPaletteKey = (
  | 'cream'
  | 'blue'
  | 'turquoise'
  | 'red'
  | 'grey'
  | 'textLight'
  | 'black'
  | 'yellow'
  | 'pink'
  | 'purple'
  | 'orange'
  | 'green'
)

type PerceptPalette = Record<PerceptPaletteKey, string>

const originalPerceptPalette: PerceptPalette = {
  blue: '#16D2E8',
  turquoise: '#00fec0',
  red: '#EA1600',
  grey: '#434343',
  textLight: '#FFFEF9',
  cream: '#FFFEF9',
  black: '#000',
  yellow: '#f9f36b',
  pink: '#E53F71',
  purple: '#5300D0',
  orange: '#ff6523',
  green: '#29F4B3',
}

const perceptPaletteLight: PerceptPalette = {
  ...originalPerceptPalette,
  yellow: '#F0DB5B',
  green: 'rgb(29, 183, 84)',
  red: 'rgb(236, 20, 20)',
}

const perceptPaletteDark: PerceptPalette = {
  ...originalPerceptPalette,
  red: 'rgb(255, 93, 76)',
  purple: deepPurple[400],
  pink: pink[400],
}

// Header font - Outfit
// Body font - Rubik

const primaryColor = perceptPaletteLight.purple

const primaryColorDarkTheme = perceptPaletteDark.turquoise


const genericChartColourKeys: PerceptPaletteKey[] = [
  'red',
  'turquoise',
  'purple',
  'green',
  'orange',
  'pink',
  'blue',
  'yellow',
]

const getGenericChartColours = (palette: PerceptPalette): string[] => [
  ...genericChartColourKeys.map( k => palette[k] ),
  ...genericChartColourKeys.map( k => darken(palette[k], 0.3) ),
  ...genericChartColourKeys.map( k => lighten(palette[k], 0.35) ),
]

const headerStyles: TypographyStyleOptions = {
  fontFamily: 'Outfit',
  fontWeight: 500,
}

export const baseLightPerceptThemeOptions: AppThemeOptions = {
  branding: {
    label: 'Percept',
    LogoComponent: Percept,
  },
  palette: {
    type: 'light',
    neutral: {
      main: perceptPaletteLight.grey,
    },
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: perceptPaletteLight.pink,
    },
    action: {
      active: '#000',
      disabled: 'rgb(150, 150, 150)',
      disabledBackground: 'rgb(240, 240, 240)',
    },
    background: {
      default: '#f8f6ed',
      paper: perceptPaletteLight.cream,
    },
    text: {
      primary: '#000',
      secondary: 'rgba(0, 0, 0, 0.65)',
      hint: '#CCC',
    },
    error: {
      light: lighten(perceptPaletteLight.red, 0.1),
      main: perceptPaletteLight.red,
      dark: darken(perceptPaletteLight.red, 0.1),
    },
    warning: {
      light: lighten(perceptPaletteLight.yellow, 0.1),
      main: perceptPaletteLight.yellow,
      dark: darken(perceptPaletteLight.yellow, 0.1),
    },
    success: {
      light: lighten(perceptPaletteLight.green, 0.1),
      main: perceptPaletteLight.green,
      dark: darken(perceptPaletteLight.green, 0.1),
    },
    info: {
      light: lighten(perceptPaletteDark.blue, 0.1),
      main: perceptPaletteDark.blue,
      dark: darken(perceptPaletteDark.blue, 0.1),
      contrastText: perceptPaletteDark.grey,
    },
    channel: {
      search: {
        light: lightBlue['500'],
        main: lightBlue['700'],
        dark: lightBlue['800'],
      },
      social: {
        light: lighten(perceptPaletteLight.purple, 0.1),
        main: perceptPaletteLight.purple,
        dark: darken(perceptPaletteLight.purple, 0.1),
      },
      programmatic: {
        light: lighten(perceptPaletteLight.orange, 0.1),
        main: perceptPaletteLight.orange,
        dark: darken(perceptPaletteLight.orange, 0.1),
        contrastText: '#FFF',
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
  chart: {
    healthColourStops: [
      perceptPaletteLight.red,
      perceptPaletteLight.yellow,
      perceptPaletteLight.green,
    ],
    informationalColourStops: [
      perceptPaletteLight.blue,
      perceptPaletteLight.purple,
    ],
    genericColourStops: getGenericChartColours(perceptPaletteLight),
    grid: {
      stroke: 'rgb(220, 220, 220)',
    },
    axis: {
      stroke: 'rgb(200, 200, 200)',
    },
    tick: {
      stroke: 'rgb(180, 180, 180)',
    },
    tooltip: {
      valueColor: '#000',
      valueBackgroundColor: 'rgb(240, 240, 240)',
      secondaryContentColor: 'rgba(0, 0, 0, 0.6)',
    },
  },
  typography: {
    fontFamily: 'Rubik, Helvetica Neue, Arial, Helvetica, sans-serif',
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    fontWeightBold: 500,
    fontSize: 14,
    button: {
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 500,
    },
    body1: {
      fontSize: 14,
      lineHeight: '22px',
    },
    body2: {
      fontSize: 12,
      lineHeight: '20px',
    },
    caption: {
      ...headerStyles,
      fontSize: 10,
      lineHeight: '16px',
    },
    h1: {
      ...headerStyles,
      fontWeight: 500,
      fontSize: 40,
      lineHeight: '48px',
    },
    h2: {
      ...headerStyles,
      fontWeight: 500,
      fontSize: 32,
      lineHeight: '40px',
    },
    h3: {
      ...headerStyles,
      fontWeight: 500,
      fontSize: 26,
      lineHeight: '34px',
    },
    h4: {
      ...headerStyles,
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '28px',
    },
    h5: {
      ...headerStyles,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
    },
    h6: {
      ...headerStyles,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  appComponents: {
    appBarTabIndicator: {
      colorPrimary: {
        backgroundColor: '#FFF',
      },
      colorSecondary: {
        backgroundColor: '#FFF',
      }
    },
    authenticator: {
      root: {
        // backgroundColor: 'rgba(255, 255, 255, 0.75)',
      },
      heading: {
        ...headerStyles,
        fontSize: 24,
        fontWeight: 500,
      },
      input: {
        color: '#000',
        backgroundColor: 'transparent',
        borderRadius: 6,
        fontWeight: 500,
      }
    },
    contrastPanel: {
      root: {
        color: '#FFF',
        backgroundColor: perceptPaletteLight.grey,
        borderRadius: 6,
      },
    },
    performanceTable: {
      cellCompact: {
        fontWeight: 500,
        fontSize: 14,
        padding: '12px 8px',
        '@media (min-width: 1500px)': {
          fontSize: 14,
        },
        '@media (max-width: 600px)': {
          fontSize: 13,
        },
        '@media (min-width: 1920px)': {
          fontSize: 15,
        },
      },
    },
    reportDashboard: {
      secondaryHealth: {
        backgroundColor: '#FFF',
      },
    },
    table: {
      footer: {
        color: '#FFF',
        backgroundColor: perceptPaletteLight.grey,
        borderColor: '#4a4949',
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '@font-face': 'Outfit',
        },
      },
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiPaper: {
      elevation: 0,
    },
    MuiCard: {
      elevation: 0,
    },
  },
  overrides: {
    MuiCard: {
      root: {
        background: perceptPaletteLight.cream,
      },
    },
    MuiChip: {
      root: {
        fontWeight: 500,
      },
      outlined: {
        color: '#000',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
    },
    MuiIconButton: {
      root: {
        padding: '4px',
      },
    },
    MuiInputBase: {
      root: {
        fontWeight: 500,
      },
      input: {
        padding: '6px 4px 7px',
      },
    },
    /* @material-ui/pickers */
    
    MuiPickersToolbar: {
      toolbar: {
        color: '#FFF',
        backgroundColor: perceptPaletteLight.yellow,
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: 'rgba(0,0,0,0.85)',
        fontWeight: 500,
        fontFamily: 'Rubik',
      },
      switchHeader: {
        color: '#000',
      },
      iconButton: {
        color: 'rgba(0,0,0,0.85)',
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: primaryColor,
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: primaryColor,
      },
    },
    MuiPickersClockPointer:{
      pointer: {
        backgroundColor: primaryColor,
      },
      noPoint: {
        backgroundColor: primaryColor,
      },
      thumb: {
        backgroundColor: primaryColor,
        border: `14px solid ${primaryColor}`,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: primaryColor,
        '&:hover': {
          backgroundColor: primaryColor,
        },
        '@media (hover: none)': {
          '&:hover': {
            backgroundColor: `${primaryColor} !important`,
          },
        },
      },
      dayDisabled: {
        color: 'rgba(0,0,0,0.35)',
      },
    },
    MuiPickersYear: {
      yearDisabled: {
        color: 'rgba(0,0,0,0.35)',
      },
    },
    MuiTableCell: {
      root: {
        color: '#000',
        fontSize: 13,
      },
    },
  },
}


export const baseDarkPerceptThemeOptions: AppThemeOptions = merge(
  {},
  baseLightPerceptThemeOptions,
  {
    palette: {
      type: 'dark',
      neutral: {
        main: lighten(perceptPaletteDark.grey, 0.2),
      },
      primary: {
        main: primaryColorDarkTheme,
        contrastText: perceptPaletteDark.grey,
      },
      secondary: {
        main: perceptPaletteDark.pink,
      },
      action: {
        active: '#FFF',
        disabled: 'rgb(200, 200, 200)',
        disabledBackground: 'rgb(75, 75, 75)',
      },
      background: {
        default: perceptPaletteDark.grey,
        paper: darken(perceptPaletteDark.grey, 0.15),
      },
      text: {
        primary: perceptPaletteDark.textLight,
        secondary: 'rgba(255, 255, 255, 0.65)',
        hint: darken(perceptPaletteDark.textLight, 0.2),
      },
      error: {
        light: lighten(perceptPaletteDark.red, 0.1),
        main: perceptPaletteDark.red,
        dark: darken(perceptPaletteDark.red, 0.1),
      },
      warning: {
        light: lighten(perceptPaletteDark.yellow, 0.1),
        main: perceptPaletteDark.yellow,
        dark: darken(perceptPaletteDark.yellow, 0.15),
      },
      success: {
        light: perceptPaletteDark.green,
        main: darken(perceptPaletteDark.green, 0.1),
        dark: darken(perceptPaletteDark.green, 0.2),
      },
      info: {
        light: lighten(perceptPaletteDark.blue, 0.1),
        main: perceptPaletteDark.blue,
        dark: darken(perceptPaletteDark.blue, 0.1),
        contrastText: perceptPaletteDark.grey,
      },
      channel: {
        search: {
          light: lightBlue['500'],
          main: lightBlue['600'],
          dark: lightBlue['800'],
        },
        social: {
          light: lighten(perceptPaletteDark.purple, 0.25),
          main: lighten(perceptPaletteDark.purple, 0.15),
          dark: lighten(perceptPaletteDark.purple, 0.05),
        },
        programmatic: {
          light: lighten(perceptPaletteDark.orange, 0.1),
          main: perceptPaletteDark.orange,
          dark: darken(perceptPaletteDark.orange, 0.1),
          contrastText: '#FFF',
        },
      },
    },
    chart: {
      healthColourStops: [
        perceptPaletteDark.red,
        perceptPaletteDark.yellow,
        perceptPaletteDark.green,
      ],
      informationalColourStops: [
        perceptPaletteDark.blue,
        perceptPaletteDark.purple,
      ],
      genericColourStops: getGenericChartColours(perceptPaletteDark),
      grid: {
        stroke: 'rgb(80, 80, 80)',
      },
      axis: {
        stroke: 'rgb(100, 100, 100)',
      },
      tick: {
        stroke: 'rgb(90, 90, 90)',
      },
      tooltip: {
        valueColor: '#FFF',
        valueBackgroundColor: 'rgb(40, 40, 40)',
        secondaryContentColor: 'rgba(255, 255, 255, 0.6)',
      },
    },
    appComponents: {
      authenticator: {
        input: {
          color: '#FFF',
        },
      },
    },
    overrides: {
      MuiCard: {
        root: {
          background: darken(perceptPaletteDark.grey, 0.15),
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.25)',
        },
      },

      /* material-ui-pickers */
      MuiPickersCalendarHeader: {
        dayLabel: {
          color: 'rgba(255,255,255,0.85)',
          fontWeight: 500,
        },
        switchHeader: {
          color: '#FFF',
        },
        iconButton: {
          color: 'rgba(255,255,255,0.85)',
        },
      },
      MuiPickerDTTabs: {
        tabs: {
          backgroundColor: primaryColorDarkTheme,
        },
      },
      MuiPickersClock: {
        pin: {
          backgroundColor: primaryColorDarkTheme,
        },
      },
      MuiPickersClockPointer:{
        pointer: {
          backgroundColor: primaryColorDarkTheme,
        },
        noPoint: {
          backgroundColor: primaryColorDarkTheme,
        },
        thumb: {
          backgroundColor: primaryColorDarkTheme,
          border: `14px solid ${primaryColorDarkTheme}`,
        },
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: primaryColorDarkTheme,
          '&:hover': {
            backgroundColor: primaryColorDarkTheme,
          },
          '@media (hover: none)': {
            '&:hover': {
              backgroundColor: `${primaryColorDarkTheme} !important`,
            },
          },
        },
        dayDisabled: {
          color: 'rgba(255,255,255,0.35)',
        },
      },
      MuiPickersYear: {
        yearDisabled: {
          color: 'rgba(255,255,255,0.35)',
        },
      },
      MuiTableCell: {
        root: {
          color: '#FFF',
          fontSize: 13,
        },
      },
    },
  }
)
