
import { combineReducers } from '@percept/redux/reducers'

import byId, { raw as rawById } from './byId'
import activeProvider from './activeProvider'
import timeSeries from './timeSeries'
import settings from './settings'

import { Reducer } from '@percept/types'


export const makeReducer = ({ raw = false } = {}): Reducer =>
  combineReducers({
    byId: raw ? rawById : byId,
    activeProvider,
    timeSeries,
    settings,
  })


export default makeReducer()