import React from 'react'

import { MultiOrgStructuralSummary } from './MultiOrgStructuralSummary'

import { SingleOrgStructuralSummary } from './SingleOrgStructuralSummary'

import { MultiOrgStructuralVariance } from './MultiOrgStructuralVariance'

import { isPlatformUnitContainer } from '@percept/utils'

import { PlatformUnit, ReportProvider } from '@percept/types'


export type QualityAnalysisProps = {
  platformUnit: PlatformUnit
  provider: ReportProvider | null
  viewType?: 'DETAIL' | 'SUMMARY'
}


export const QualityAnalysis = ({
  platformUnit,
  provider,
  viewType,
}: QualityAnalysisProps): JSX.Element => {

  if( isPlatformUnitContainer(platformUnit) && provider ){
    if( viewType === 'DETAIL' ){
      return (
        <MultiOrgStructuralVariance
          platformUnit={platformUnit}
          provider={provider} />
      )
    }
    return (
      <MultiOrgStructuralSummary
        platformUnit={platformUnit}
        provider={provider} />
    )
  }

  return (
    <SingleOrgStructuralSummary
      platformUnit={platformUnit} />
  )
}
