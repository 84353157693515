import React from 'react'

import { makeAppStyles } from '../../themes'

import { CopyToClipboard } from '../CopyToClipboard'


const useStyles = makeAppStyles( theme => ({
  copyToClipboard: {
    color: theme.palette.text.hint,
    marginTop: theme.spacing(1),
  }
}) )


export type MetricDebugProps = {
  id: string
}

export const MetricDebug = ({ id }: MetricDebugProps): JSX.Element => {

  const classes = useStyles()

  return (
    <CopyToClipboard
      className={classes.copyToClipboard}
      value={id} />
  )
}

export default MetricDebug
