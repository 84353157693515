
import React from 'react'

import { ButtonProps } from '@material-ui/core'

import { MenuAbstractionProps, MenuOptionsProps, PlainTextButtonMenu } from '../../Menus'

import { useDimensionOptions } from './lib'


const defaultOptions = ['count', 'cost', 'impressions', 'clicks', 'conversions']


export type DimensionSelectProps = (
  Omit<
    MenuOptionsProps<string>,
    'options' | 'children'
  > & {
    activePerformanceTail?: string | null
    options?: string[]
    iconOnly?: boolean
  }
)


export const DimensionSelect = ({
  activePerformanceTail = null,
  options = defaultOptions,
  value,
  ...props
}: DimensionSelectProps): JSX.Element => {

  if( !activePerformanceTail || activePerformanceTail === 'audit_period' ){
    activePerformanceTail = ''
  }

  if( !value || value === 'audit_period' ){
    value = ''
  }

  const {
    activeDimension,
    options: filteredOptions,
    IconComponent,
  } = useDimensionOptions({ value, options, activePerformanceTail })

  return (
    <PlainTextButtonMenu
      {...props}
      TriggerProps={
        IconComponent && {
          startIcon: <IconComponent />
        }
      }
      label={activeDimension && activeDimension.label || 'Select Dimension'}
      value={value}
      options={filteredOptions} />
  )

}
