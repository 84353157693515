
import { createKeyedReducer } from '../../../reducers'

import {
  FILTER_CLIENT_REPORT_ENTITIES,
  CLEAR_CLIENT_REPORT_ENTITY_FILTER,
} from '../actions'

import { getSeriesKeyFromAction } from '@percept/redux/utils'

import { Reducer, ReportProvider } from '@percept/types'

import { ClientReportState } from '../typings'


const filters: Reducer<ClientReportState['filters']> = createKeyedReducer(
  (state = {}, action) => {
  
    const { payload } = action
    
    const provider = (payload && payload.provider) as ReportProvider | undefined | null

    if( !provider ){
      return state
    }

    switch(action.type){
      
      case FILTER_CLIENT_REPORT_ENTITIES:

        return {
          ...state,
          [provider]: {
            type: payload.type,
            id: payload.id
          }
        }

      case CLEAR_CLIENT_REPORT_ENTITY_FILTER:

        return {
          ...state, 
          [provider]: {
            type: null,
            id: null
          }
        }
      

      default:
        return state
    }

  },
  getSeriesKeyFromAction
)

export default filters
