import React from 'react'

import { Notifications, NotificationsProps, Toasts, ToastsProps } from '@percept/mui'

import { useNotifications, useToasts } from '@percept/hooks'

import { find } from 'lodash-es'


export type NotificationHubProps = (
  Omit<
    NotificationsProps, 'notifications' | 'onClose' | 'onCloseAll'
  >
)


export const NotificationHub = (props: NotificationHubProps): JSX.Element => {

  const [
    notifications,
    /* we don't need `add()` */,
    remove,
    onCloseAll,
  ] = useNotifications()

  return (
    <Notifications
      marginTop={4}
      zIndex={3000}
      {...props}
      notifications={notifications}
      onCloseAll={onCloseAll}
      onClose={(id): void => {
        if( id && find(notifications, n => n.id === id ) ){
          remove({ id })
        }
      }} />
  )
}


export type ToastHubProps = (
  Omit<
    ToastsProps, 'toasts' | 'onClose' | 'onCloseAll'
  >
)


export const ToastHub = (props: NotificationHubProps): JSX.Element => {

  const [
    toasts,
    /* we don't need `add()` */,
    remove,
    onCloseAll,
  ] = useToasts()

  return (
    <Toasts
      marginTop={4}
      zIndex={3000}
      {...props}
      toasts={toasts}
      onCloseAll={onCloseAll}
      onClose={(id): void => {
        if( id && find(toasts, n => n.id === id ) ){
          remove({ id })
        }
      }} />
  )
}

