import React from 'react'

import { Button, ButtonClassKey, ButtonProps } from '@material-ui/core'

import { Styles } from '@material-ui/styles'

import { AppTheme, withAppStyles } from '../../themes'


export const PlainTextButton: React.FC<ButtonProps> = withAppStyles({
  root: {
    textTransform: 'none'
  }
} as Styles<AppTheme, ButtonProps, ButtonClassKey>)(Button) as React.FC<ButtonProps>


export const RoundedPlainTextButton: React.FC<ButtonProps> = withAppStyles<ButtonClassKey>({
  root: {
    textTransform: 'none',
    borderRadius: '2rem',
  }
} as Styles<AppTheme, ButtonProps, ButtonClassKey>)(Button) as React.FC<ButtonProps>


export const MassiveRoundedPlainTextButton: React.FC<ButtonProps> = withAppStyles<ButtonClassKey>({
  root: {
    textTransform: 'none',
    borderRadius: '4rem',
    fontSize: 22,
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
  },
} as Styles<AppTheme, ButtonProps, ButtonClassKey>)(
  props => <Button size='large' {...props} />
) as React.FC<ButtonProps>
