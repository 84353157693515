import { Box, Typography } from '@material-ui/core'
import { makeAppStyles } from '@percept/mui'
import React from 'react'
import { TABLE_FORM_ROW_HEIGHT } from './constants'

type Props = {
  errrRow: boolean
  selected: string
}

const useStyles = makeAppStyles(() => ({
  container: {
    minWidth: '190px',
    padding: '10px 0',
    marginRight: '16px',
    height: TABLE_FORM_ROW_HEIGHT,
  },
  errorBox: {
    padding: '0 14px',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  error: {
    border: '1px solid #BD6069',
  },
}))

export const TableOption = ({ selected, errrRow }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={`${classes.errorBox} ${errrRow ? classes.error : null}`}>
        <Typography>{selected}</Typography>
      </Box>
    </Box>
  )
}
