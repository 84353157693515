/* eslint-disable no-console */

import {
  isObject,
  isArray,
  getPath,
  findNext,
  sum,
  uniq,
  range,
  intersection,
} from '@percept/utils'

import {
  Dictionary,
  MetricSegmentType,
  MetricDataType,
  MetricMetadataEntryType,
  DatumType,
  MetricType,
  MetricMetadataType,
  MetricMetadataSegmentType,
  HealthType,
  MetricExamplesType,
  MetricsPayload,
  MetricDimensions,
} from '@percept/types'


// const { PERCEPT_ENV, APP } = process.env

const debug = false  // PERCEPT_ENV === 'dev' || APP === 'admin'

const logKeys = (keys: string): string[] => [
  `%c${keys}`,
  'background: #5c748c; color: #ebeff3; padding: 2px 5px; font-weight: bold; border-radius: 2px',
]



const toUpper = (s: string): string => s.toUpperCase()


const toObject = (data: MetricDataType, uppercase = false): Dictionary<MetricSegmentType> => (
  isArray(data) ?
    data.reduce( (acc: Dictionary<MetricSegmentType>, d) => {
      const label = uppercase ? toUpper(d.label) : d.label
      acc[label] = d
      return acc
    }, {}) :
    isObject(data) ?
      Object.entries(data).reduce( (acc: Dictionary<MetricSegmentType>, [k, v]) => {
        const effectiveLabel = v && v.label || k
        const label = uppercase ? toUpper(effectiveLabel) : effectiveLabel
        acc[label] = v
        return acc
      }, {}) :
      {}
)


export const getMetricMetadataById = (
  metricMetadata: Dictionary<MetricMetadataEntryType> | MetricMetadataEntryType[],
  id: string
): MetricMetadataEntryType | undefined => (
  isArray(metricMetadata) ?
    metricMetadata.filter( m => m.metric_id === id )[0] :
    isObject(metricMetadata) ?
      metricMetadata[id] :
      undefined
)


const getValue = (d: DatumType): number => d.value || 0


const isSegmentDictionary = (d: Dictionary | null | undefined): d is Dictionary<MetricSegmentType> => (
  !!(d && d.segments)
)

const getMetricTotal = (data: MetricDataType): number => (
  isArray(data) ?
    sum(data.map(getValue)) :
    isSegmentDictionary(data) ?
      sum(Object.values(data.segments).filter(Boolean).map(Number)) :
      data.value || 0
)


export const applySegments = (
  { data, metadata }:
  { data: MetricDataType | null, metadata: MetricMetadataEntryType | null | undefined }
): MetricDataType | null => {

  let mappedData = data

  const segments: MetricMetadataSegmentType[] = getPath(metadata, ['display_options', 'segments'])

  const isDynamic = getPath(metadata, ['display_options', 'dynamic']) === true

  const mixedDynamic = getPath(metadata, ['display_options', 'mixed_dynamic']) === true

  if( metadata && data && (isDynamic || segments && segments.length) ){
    
    const dataObj = toObject(data, true)

    let mappableSegments = segments

    if( isDynamic ){
      const keys = Object.keys(dataObj)
      const prefix = getPath(metadata, ['display_options', 'segment_prefix'])

      mappableSegments = range(keys.length).map( i => ({
        keys: [keys[i]],
        label: String(prefix ? `${prefix} ${i + 1}` : i + 1),
        health: null,
      }) )

      if( mixedDynamic ){
        mappableSegments = segments.concat(
          mappableSegments.filter( m => {
            return !findNext(segments, s => (
              intersection(
                [...s.keys, s.label].map( x => x.toUpperCase()),
                m.keys.map( x => x.toUpperCase() )
              ).length > 0
            ))
          })
        )
      }
    }

    mappedData = mappableSegments.map( ({ keys, label, ...segment }) => {

      let value = 0,
          health: HealthType = segment.health,
          examples: MetricExamplesType | null = null

      const matchingKeys = uniq(keys.concat([label]).map(toUpper))

      const matchedKey = findNext(matchingKeys, k => typeof dataObj[k] !== 'undefined' )

      if( matchedKey ){
        value = dataObj[matchedKey].value || 0
        health = dataObj[matchedKey].health || segment.health
        examples = dataObj[matchedKey].examples || null
      }else{
        if( debug ){
          console.groupCollapsed(`Missing segment key: ${metadata.metric_id}`)
          console.log(...logKeys(matchingKeys.join(' | ')))
          console.log(`Label: ${label}`)
          console.log('Data:', dataObj)
          console.groupEnd()
        }
      }

      return { label, value, health, examples }
    })

    if( debug ){
      if( getMetricTotal(data) === 0 ){
        console.groupCollapsed(`All values empty: ${metadata.metric_id}`)
        console.log('Original data', data)
        console.groupEnd()
      }
    }
  }

  return mappedData

}

export const applyAllSegments = (
  { metrics, metadata }:
  { metrics: MetricsPayload | null, metadata: MetricMetadataType | null }
): MetricsPayload => {

  if( !metrics || !metadata ){
    return {}
  }

  return Object.keys(metrics).reduce( (acc: MetricsPayload, metricId) => {

    const metricMetadata = getMetricMetadataById(metadata, metricId)

    const metricDimensions = metrics[metricId]

    acc[metricId] = Object.keys(metricDimensions).reduce( (entries: MetricDimensions, dimension) => {

      entries[dimension] = {
        ...(metrics[metricId][dimension] as MetricType),
        data: applySegments({
          data: getPath(metrics[metricId][dimension], 'data'),
          metadata: metricMetadata || null
        })
      }
      return entries
    }, {} as MetricDimensions)

    return acc
  }, {})

}
