import {
  Box,
  Button,
  Card,
  CircularProgress,
  LinearProgress,
  Menu,
  MenuItem,
  Modal,
  Snackbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Loader, Money, makeAppStyles } from '@percept/mui'
import { SortControl } from '../ReportTable/SortControl'
import { SortConfig } from '@percept/types'
import React, { Fragment, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { get, isEmpty, orderBy, pick, omit, some } from 'lodash-es'
import { TableSelect } from './TableSelect'
import { addKeyToCampaign, fillDataWithEmptyMonth, filterFutureMonths, findDuplicates, findEmptyRow } from './helpers'
import { TableInputSide } from './TableInputSide'
import { useLocation } from 'react-router'
import { ConfirmModal } from '../Modal'
import { TableOption } from './TableOption'
import { InfoOutlined, CheckCircle, ChevronRight } from '@material-ui/icons'
import { ConflictHandling } from '../ConflictHandling'
import { FormUpdateInformation } from '../FormUpdateInformation'
import { FormLayout } from '../FormLayout'
import Api from '../../../api/services/Api'
import { Campaign, CampaignWithKey, SpendingCell, Conflicts, StatusConflict } from '../types'
import { FormColumns } from 'enums/FormColumns'
import { WebFormTypes } from 'enums/WebFormTypes'
import { ALL_REPORTING_FEATURES_ENABLED } from 'constans'
import { filterOptions, isDisabledColumn } from './filterOptions'
import { OptionType } from '../Filters'
import { TABLE_FORM_ROW_HEIGHT } from './constants'
import { sumByMarket, sumByMonth } from './calculateTotal'
import { useActivePlatformUnit } from 'components/Organisation'
import { useColumnOptions } from './useColumnOptions'


const FORM_EDIT_ENABLED = false

const DIVIDER_WIDTH = 8

export const useFormTableStyles = makeAppStyles((theme) => ({
  card: {
    maxHeight: 'calc(100vh - 272px)',
    maxWidth: '100%',
    position: 'relative',
    overflow: 'auto',
  },
  fetchIndicator: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 2000,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  headerContainer: {
    position: 'sticky',
    top: 0,
    left: 0,
    width: '100%',
    height: 108,
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    zIndex: 200,
  },
  titleSeparator: {
    margin: theme.spacing(0, 0.5),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainPanelContainer: {
    display: 'flex',
    position: 'relative',
  },
  scroll: {
    '&::-webkit-scrollbar': {
      height: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#C6CBD0',
      borderRadius: '20px',
    },
  },
  leftPanel: {
    overflow: 'auto',
    minWidth: '200px',
    boxShadow: '6px 0px 12px rgba(0, 0, 0, 0.5)',
  },
  rightPanel: {
    overflow: 'auto',
    minWidth: '200px',
  },
  leftHeaderPanel: {
    overflow: 'hidden',
    minWidth: '200px',
    boxShadow: '6px 0px 12px rgba(0, 0, 0, 0.5)',
  },
  rightHeaderPanel: {
    overflow: 'hidden',
    minWidth: '200px',
  },
  drag: {
    cursor: 'w-resize',
    background: theme.palette.primary.main,
    position: 'absolute',
    height: '100%',
    width: DIVIDER_WIDTH,
    top: 0,
    left: '50%',
    zIndex: 400,
  },
  border: {
    borderTop: `1px solid ${theme.appComponents.table.footer.borderColor}`,
  },
  rowButton: {
    height: TABLE_FORM_ROW_HEIGHT + 1,
  },
  totalCellBackground: {
    ...theme.appComponents.table.footer,
  },
  columnMonth: {
    textTransform: 'uppercase',
    height: '54px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '120px',
    fontSize: '13px',
    fontWeight: 700,
  },
  currentMonth: {
    color: theme.palette.info.main,
  },
  tableTotalCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '54px',
    width: '120px',
    minWidth: '120px',
    fontSize: 13,
    fontWeight: 700,
    paddingRight: theme.spacing(3),
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
  addButton: {
    alignSelf: 'flex-start',
    textTransform: 'none',
    marginTop: '10px',
  },
  buttonSave: {
    alignSelf: 'flex-end',
    textTransform: 'none',
    marginTop: '20px',
  },
  submitButton: {
    width: '100%',
    boxShadow: 'none',
    borderRadius: 0,
  },
  submitBorder: {
    borderColor: theme.palette.secondary.main,
  },
  disabledSubmitBorder: {
    borderColor: theme.palette.action.disabledBackground,
  },
  conflictBanner: {
    display: 'flex',
    flexDirection: 'row',
    width: '320px',
    backgroundColor: theme.palette.type === 'dark' ? '#fff' : '#000',
    color: theme.palette.type === 'dark' ? '#000' : '#fff',
    gap: '10px',
    padding: '10px',
    marginTop: '100px',
    marginRight: '104px',
  },
  successBanner: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.type === 'dark' ? '#fff' : '#000',
    color: theme.palette.type === 'dark' ? '#000' : '#fff',
    padding: '10px',
    width: '50vw',
    marginTop: '50px',
    gap: '10px',
  },
}))

type ColumnType = FormColumns

const hasAnyConflict = (data?: Conflicts) => {
  const conflicts = data
    ? [...(data.create_conflicts ?? []), ...(data.update_conflicts ?? [])]
    : []
  return conflicts.length > 0
}

export type SortConfiguration = SortConfig<ColumnType>

type Props = {
  tableColumnsWithOptions: {
    name: string
    label: string
    description: string
  }[]
  value: WebFormTypes
  title: string
}

export const TableForm = ({
  tableColumnsWithOptions,
  title,
  value,
}: Props): JSX.Element => {
  const defaultSort: SortConfiguration = {
    order: 'ASC',
    key:
      value === WebFormTypes.MediaInvestment
        ? FormColumns.secondBrand
        : FormColumns.agency,
  }
  const [tableData, setTableData] = useState<CampaignWithKey[]>([])
  const [isTableEdited, setIsTableEdited] = useState<boolean>(false)
  const [tableMonths, setTableMonths] = useState<
    { id: string; name: string }[]
  >([])
  const [sortBy, setSortBy] = useState<SortConfiguration | undefined>(
    defaultSort
  )
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedRow, setSelectedRow] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [openModalStatusConflict, setOpenModalStatusConflict] = useState(false)
  const [openBanner, setOpenBanner] = useState(false)
  const [duplicates, setDuplicates] = useState<boolean[]>([])
  const [empty, setEmpty] = useState<boolean[]>([])
  const classes = useFormTableStyles()
  const { pathname } = useLocation()
  const lastPartUrl = pathname.split('/').pop()
  const orgId = pathname.split('/')[2]

  const activePlatformUnit = useActivePlatformUnit()

  const titleStartElement = (
    activePlatformUnit ?
      <Fragment>
        {activePlatformUnit.name}
        <ChevronRight className={classes.titleSeparator}  />
      </Fragment> :
      null
  )

  const { data: submissionTracker } = Api.useSubmissionTrackerForWebformType(value)
  const { data: markets } = Api.useMarkets(true)
  const orgName = markets?.find((el) => el.value === orgId)?.name
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>()

  useEffect(() => {
    const submitedMarket =
      submissionTracker &&
      submissionTracker.find((el) => el.market_name === orgName)
    setSubmitButtonDisabled(
      submissionTracker &&
        submitedMarket &&
        (submitedMarket.status === 'submitted' ||
          submitedMarket.status === 'updated')
    )
  }, [orgName, submissionTracker])

  const {
    deleteRow,
    error: deleteError,
    reset: resetDeleteMutation,
    isLoading: isLoadingDelete,
    isSuccess: isSuccessDelete,
  } = Api.useDeleteRow(value)
  const {
    updateForm,
    error: updateError,
    conflictTime,
    reset: resetUpdateMutation,
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
  } = Api.useUpdateFormData(value)
  const { data: yearsListResponse } = Api.useFinancialYears(true)
  const yearsList = useMemo(() => {
    if (!yearsListResponse) {
      return yearsListResponse
    }
    // NOTE - currently hiding financial years before 22/23 due to nullable columns in data
    const validYears = ['FY2223', 'FY2324']
    return yearsListResponse.filter((y) => validYears.includes(y.name))
  }, [yearsListResponse])
  const {
    submitForm,
    error: submitError,
    conflictTime: submitConflictTime,
    reset: resetSubmitMutation,
    isSuccess: isSuccessSubmit,
    isLoading: isLoadingSubmit,
  } = Api.useSubmitFormDataFY(value)

  const [selectedYear, setSelectedYears] = useState<string>()

  const isLoadingSave = some([isLoadingDelete, isLoadingSubmit, isLoadingUpdate])
  const isSuccessSave = some([isSuccessDelete, isSuccessSubmit, isSuccessUpdate])

  // const renderUpdateInformation = ALL_REPORTING_FEATURES_ENABLED ? (
  //   <FormUpdateInformation formType={title} />
  // ) : null

  const conflictDataUpdate = omit(get(updateError, 'response.data'), [
    'status_conflicts',
  ]) as Conflicts
  const conflictDataSubmit = omit(get(submitError, 'response.data'), [
    'status_conflicts',
  ]) as Conflicts

  const conflictStatusUpdate = pick(get(updateError, 'response.data'), [
    'status_conflicts',
  ]) as { status_conflicts: StatusConflict[] }

  const conflictStatusSubmit = pick(get(submitError, 'response.data'), [
    'status_conflicts',
  ]) as { status_conflicts: StatusConflict[] }

  const isConflictDataUpdate =
    !isEmpty(conflictDataUpdate) && hasAnyConflict(conflictDataUpdate)

  const isConflictStatusUpdate =
    !isEmpty(conflictStatusUpdate) &&
    conflictStatusUpdate.status_conflicts.length > 0

  const isConflictStatusSubmit =
    !isEmpty(conflictStatusSubmit) &&
    conflictStatusSubmit.status_conflicts.length > 0

  const conflictsData = isConflictDataUpdate
    ? conflictDataUpdate
    : conflictDataSubmit

  const conflictsStatus = isConflictStatusUpdate
    ? conflictStatusUpdate
    : conflictStatusSubmit

  const errorConflictsData = get(deleteError, 'response.data')

  const hasConflictRepsonse = updateError
    ? get(updateError, 'response.status') === 409
    : submitError
      ? get(submitError, 'response.status') === 409
      : false

  const hasErrorConflictRepsonse = deleteError
    ? get(deleteError, 'response.status') === 409
    : false

  const hasConflicts =
    (hasConflictRepsonse && hasAnyConflict(conflictsData)) ||
    hasErrorConflictRepsonse

  const hasStatusConflicts =
    hasConflictRepsonse && (isConflictStatusUpdate || isConflictStatusSubmit)

  useEffect(() => {
    if (!submitButtonDisabled && isSuccessSubmit) setSubmitButtonDisabled(true)
  }, [isSuccessSubmit, submitButtonDisabled])

  useEffect(() => {
    if (hasStatusConflicts) setOpenModalStatusConflict(true)
  }, [hasStatusConflicts])

  useEffect(() => {
    if (yearsList && yearsList.length)
      setSelectedYears(yearsList.slice(-1)[0].id)
  }, [yearsList])
  const {
    data: { data, time: dataRenderTime } = { time: '' },
    isLoading: formIsLoading,
    isRefetching: formIsRefetching,
  } =
    Api.useFormDataFY(
      orgId,
      selectedYear ? selectedYear : undefined,
      value,
      !!selectedYear
    )
  // const results = useDropdownOptions(tableColumnsWithOptions)

  // console.log('WTF?', results)

  const selectOptions = useColumnOptions({
    org_unit_id: orgId,
    columns: tableColumnsWithOptions,
  })

  // const selectOptions = tableColumnsWithOptions.map((el, idx) => ({
  //   ...el,
  //   options: results[idx].data,
  // }))

  // console.log('RIGHT', {
  //   columnOptions,
  //   selectOptions,
  // })

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    function handleResize(e: any): void {
      setWindowWidth(e.target.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const [leftPanelEl, setLeftPanelEl] = useState<HTMLDivElement | null>(null)
  const [leftHeaderEl, setLeftHeaderEl] = useState<HTMLDivElement | null>(null)
  const [rightPanelEl, setRightPanelEl] = useState<HTMLDivElement | null>(null)
  const [rightHeaderEl, setRightHeaderEl] = useState<HTMLDivElement | null>(null)
  const [dividerEl, setDividerEl] = useState<HTMLDivElement | null>(null)
  const [headerDividerEl, setHeaderDividerEl] = useState<HTMLDivElement | null>(null)
  const rowContainerRef = useRef<HTMLDivElement | null>(null)

  const hasData = !!(tableData && tableData.length)

  useLayoutEffect(() => {
    if (
      leftPanelEl &&
      rightPanelEl &&
      leftHeaderEl &&
      rightHeaderEl &&
      dividerEl &&
      headerDividerEl &&
      windowWidth / 2 - 64 > 200
    ) {
      const padding = 32
      const halfWindowWidth = windowWidth / 2
      const leftWidth = halfWindowWidth - (hasData ? padding + 64 : padding)
      const rightWidth = halfWindowWidth - padding

      leftPanelEl.style.width = `${leftWidth}px`
      leftHeaderEl.style.width = `${leftWidth}px`
      rightPanelEl.style.width = `${rightWidth}px`
      rightHeaderEl.style.width = `${rightWidth}px`

      dividerEl.style.left = '50%'
      headerDividerEl.style.left = '50%'
      // Ensure latest month is scrolled to
      rightPanelEl.scrollLeft = rightPanelEl.scrollWidth
      rightHeaderEl.scrollLeft = rightHeaderEl.scrollWidth

      // Ensure last row is scrolled to - need a delay on this for rows to render
      // before triggering the scroll to get the correct height.
      setTimeout(() => {
        if( rowContainerRef.current ){
          rowContainerRef.current.scrollTop = rowContainerRef.current.scrollHeight
        }
      }, 50)
    }
  }, [selectedYear, hasData, dividerEl, headerDividerEl, leftPanelEl, rightPanelEl, leftHeaderEl, rightHeaderEl, windowWidth])

  const [isResizing, setIsResizing] = useState(false)

  useEffect(() => {

    const els = [
      leftPanelEl,
      leftHeaderEl,
      rightPanelEl,
      rightHeaderEl,
    ].filter( e => e !== null) as HTMLElement[]

    function handleMouseDown(): void {
      setIsResizing(true)
      for( const el of els ){
        el.style['user-select' as unknown as number] = 'none'
      }
    }

    function handleMouseUp(): void {
      setIsResizing(false)
      for( const el of els ){
        el.style['user-select' as unknown as number] = 'auto'
      }
    }

    function handleMouseMove(e: { clientX: number }): void {
      if (!isResizing) {
        return
      }

      const { clientX } = e

      if (
        clientX - 128 > 200 &&
        clientX + (320 - 128) < windowWidth &&
        leftPanelEl &&
        rightPanelEl &&
        leftHeaderEl &&
        rightHeaderEl &&
        headerDividerEl &&
        dividerEl
      ) {
        const padding = 32
        const leftWidth = clientX - (hasData ? (padding + 64) : padding)
        const rightWidth = windowWidth - clientX - (hasData ? padding : 0)
        const dividerLeft = clientX - padding

        headerDividerEl.style.left = `${dividerLeft}px`
        dividerEl.style.left = `${dividerLeft}px` 

        leftPanelEl.style.width = `${leftWidth}px`
        leftHeaderEl.style.width = `${leftWidth}px`
        rightPanelEl.style.width = `${rightWidth}px`
        rightHeaderEl.style.width = `${rightWidth}px`
      }
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
    dividerEl && dividerEl.addEventListener('mousedown', handleMouseDown)
    headerDividerEl && headerDividerEl.addEventListener('mousedown', handleMouseDown)

    return (): void => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
      dividerEl && dividerEl.removeEventListener('mousedown', handleMouseDown)
      headerDividerEl && headerDividerEl.removeEventListener('mousedown', handleMouseDown)
    }
  }, [hasData, isResizing, leftPanelEl, windowWidth, dividerEl, headerDividerEl, rightPanelEl, leftHeaderEl, rightHeaderEl])

  useEffect(() => {
    if (!data || !dataRenderTime) {
      return
    }

    const filteredMonths = filterFutureMonths(data.results.months)

    const months = filteredMonths.map((el) => el.name) ?? []

    const mediaInvestmentData = data.results.investments.map((el) =>
      el.equiv_spot_lengths
        ? {
          ...el,
          equiv_spot_lengths: {
            id: el.equiv_spot_lengths,
            name: el.equiv_spot_lengths,
          },
          data: fillDataWithEmptyMonth(el.data, months, dataRenderTime),
        }
        : el.spending_type
          ? {
            ...el,
            spending_type: {
              id: el.spending_type,
              name: el.spending_type,
            },
            data: fillDataWithEmptyMonth(el.data, months, dataRenderTime),
          }
          : {
            ...el,
            data: fillDataWithEmptyMonth(el.data, months, dataRenderTime),
          }
    )
    const sortedTableData = sortBy
      ? orderBy(
        mediaInvestmentData,
        `${sortBy.key}.name`,
          sortBy.order.toLocaleLowerCase() as 'asc' | 'desc'
      )
      : mediaInvestmentData

    const tableDataWithKey = sortedTableData.map( d => (
      addKeyToCampaign(d as unknown as Campaign)
    ))

    setTableData(tableDataWithKey)
    setTableMonths(filteredMonths)
  }, [data, dataRenderTime, lastPartUrl, sortBy])

  const { totalByMonth, totalByMarket } = useMemo(() => {
    return {
      totalByMarket: sumByMarket(tableData),
      totalByMonth: sumByMonth(tableData),
    }
  }, [tableData])

  const restMonth = tableMonths.slice(0, -1)
  const currentMonth = tableMonths[tableMonths.length - 1]

  const financialYear = data ? data.results.fin_year : ''

  const currency = (
    data && value !== WebFormTypes.CompetitiveTVSOV
      ? data.results.market.currency
      : ''
  )

  /* Event handlers */

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    idx: number
  ): void => {
    setAnchorEl(event.currentTarget)
    setSelectedRow(idx)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    name: string,
    idx: number
  ): void => {
    const optionName = selectOptions
      ?.find((el) => el.name === name)
      ?.options.find((el) => el.value === event.target.value)?.name

    setTableData(
      tableData.map((el, i) =>
        i === idx
          ? addKeyToCampaign({ ...el, [name]: { id: event.target.value, name: optionName } })
          : el
      )
    )
    setIsTableEdited(true)
  }

  const handleInputChange = (
    event: { target: { value: string } },
    month: string,
    idx: number
  ): void => {
    const amount = event.target.value
      .replace('/[^\\d\\.]*/g', '')
      .replace(',', '.')
    const regex = /^(\d{1,8}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/
    if (amount === '' || regex.test(amount)) {
      setTableData((p) =>
        p.map((el, i) =>
          i === idx
            ? {
              ...el,
              data: el.data.map((e: SpendingCell) =>
                e.month === month
                  ? { ...e, amount, modified: dataRenderTime }
                  : e
              ),
            }
            : el
        )
      )
      setIsTableEdited(true)
    }
  }

  const handleSortChange = ({
    order,
    key,
  }: Omit<SortConfiguration, 'order'> & { order?: 'ASC' | 'DESC' }): void => {
    if (key != sortBy?.key) {
      return setSortBy({
        key,
        order: 'ASC',
      })
    }
    setSortBy(
      order
        ? {
          order,
          key,
        }
        : undefined
    )
  }

  const handleDuplicate = (): void => {
    const copiedObj = { ...tableData[selectedRow] }
    const cell = copiedObj.data.map((el) => ({
      ...el,
      id: '',
      amount: '',
    }))
    tableData.splice(selectedRow + 1, 0, {
      ...copiedObj,
      data: cell,

      editable: true,
    })
    setTableData([...tableData])
    setIsTableEdited(true)
    setAnchorEl(null)
  }

  const handleDelete = (): void => {
    const row = tableData.find((_, i) => i === selectedRow)

    if (!row) {
      return
    }
    if (row.editable) {
      setTableData((p) => p.filter((_, i) => i !== selectedRow))
      setIsTableEdited(true)
      setAnchorEl(null)
      return
    }
    setOpenModal(true)
  }
  const handleSubmitDelete = (): void => {
    const row = tableData.find((_, i) => i === selectedRow)

    if (!row) {
      return
    }
    const modifiedMonths = row.data
      .filter((el) => el.amount !== '')
      .map((el) => ({
        id: el.id || '',
        updated_at: dataRenderTime,
      }))
    setTableData((p) =>
      p.map((el, i) =>
        i === selectedRow
          ? { ...el, data: el.data.map((e) => ({ ...e, amount: '' })) }
          : el
      )
    )

    setAnchorEl(null)
    setOpenModal(false)
    resetUpdateMutation()
    resetSubmitMutation()
    deleteRow(modifiedMonths)
  }

  const handleAddNewRow = (): void => {
    const newRow = tableColumnsWithOptions.reduce(
      (result, el) => {
        return { ...result, [el.name]: { id: '', name: '' } }
      },
      {
        data: tableMonths.map((el) => ({ month: el.name, amount: '' })),
        editable: true,
      }
    )
    setTableData((p) => [...p, addKeyToCampaign(newRow as unknown as Campaign)])
    setIsTableEdited(true)
    const rowContainer = rowContainerRef.current
    if( rowContainer ){
      rowContainer.scrollTop = rowContainer.scrollHeight + (TABLE_FORM_ROW_HEIGHT / 2)
    }
  }

  const handleSubmitButton = async (): Promise<void> => {
    const duplicatedRows = findDuplicates(tableData)
    const emptyRows = findEmptyRow(tableData)
    setDuplicates(duplicatedRows)
    setEmpty(emptyRows)

    if (
      duplicatedRows.every((el) => el === false) &&
      emptyRows.every((el) => el === false)
    ) {
      const notEmptyCells = tableData.map((el) => ({
        ...el,
        data: el.data.filter((e) => e.amount !== '' || e.id),
      }))
      const modifiedCells = notEmptyCells.map((el) =>
        el.spending_type
          ? {
            ...el,
            spending_type: el.spending_type.name,
          }
          : el.equiv_spot_lengths
            ? {
              ...el,
              equiv_spot_lengths: el.equiv_spot_lengths.name,
            }
            : el
      )
      resetUpdateMutation()
      resetDeleteMutation()
      selectedYear &&
        submitForm({
          fyId: selectedYear,
          orgId: orgId,
          investments: modifiedCells,
          webformType: value,
        })

      setOpenBanner(true)
      setIsTableEdited(false)
    }
  }

  const handleSaveChanges = async (): Promise<void> => {
    const duplicatedRows = findDuplicates(tableData)
    const emptyRows = findEmptyRow(tableData)
    setDuplicates(duplicatedRows)
    setEmpty(emptyRows)

    if (
      duplicatedRows.every((el) => el === false) &&
      emptyRows.every((el) => el === false)
    ) {
      const notEmptyCells = tableData.map((el) =>
        el.spending_type
          ? {
            ...el,
            spending_type: el.spending_type.name,
            data: el.data.filter((e) => e.amount !== '' || e.id),
          }
          : el.equiv_spot_lengths
            ? {
              ...el,
              equiv_spot_lengths: el.equiv_spot_lengths.name,
              data: el.data.filter((e) => e.amount !== '' || e.id),
            }
            : {
              ...el,
              data: el.data.filter((e) => e.amount !== '' || e.id),
            }
      )

      resetSubmitMutation()
      resetDeleteMutation()
      selectedYear &&
        updateForm({
          fyId: selectedYear,
          orgId: orgId,
          investments: notEmptyCells,
          webformType: value,
        })

      setOpenBanner(true)
      setIsTableEdited(false)
      if (openModalStatusConflict) setOpenModalStatusConflict(false)
    }else{
      console.warn('Duplicated or empty rows', {
        duplicatedRows,
        emptyRows,
      })
    }
  }
  const handleSaveConflictsChanges = (resolvedConflicts: Campaign[]): void => {
    const resolvedData = resolvedConflicts.map((el) => ({
      ...el,
      data: el.data.filter((e) => e.solved),
    }))
    const notEmptyCells = resolvedData.map((el) => ({
      ...el,
      data: el.data
        .filter((e) => (e.amount !== '' || e.id) && e.action !== 'delete')
        .map((spending) =>
          spending.action === 'create'
            ? {
              amount: spending.amount,
              modified: spending.modified,
              month: spending.month,
            }
            : spending
        ),
    }))
    const modifCells = notEmptyCells.map((el) =>
      el.spending_type
        ? {
          ...el,
          spending_type: el.spending_type.name,
        }
        : el.equiv_spot_lengths
          ? {
            ...el,
            equiv_spot_lengths: el.equiv_spot_lengths.name,
          }
          : el
    )

    resetDeleteMutation()
    resetSubmitMutation()
    selectedYear &&
      updateForm({
        fyId: selectedYear,
        orgId: orgId,
        investments: modifCells,
        webformType: value,
      })
    setOpenBanner(false)
  }

  const handleCloseModal = (): void => {
    setOpenModal(false)
    setAnchorEl(null)
  }

  const handleCloseModalStatusConflict = (): void => {
    setOpenModalStatusConflict(false)
  }

  if (!tableData || tableMonths.length === 0) {
    if (tableData && tableMonths.length === 0) {
      console.warn('Table data loaded but no `tableMonths` are present')
    }
    return (
      <Loader preset='fullscreen'>
        <Typography variant='h4' style={{ marginTop: 12 }}>
          Loading data management form...
        </Typography>
      </Loader>
    )
  }

  if (hasConflicts && !hasStatusConflicts) {
    return (
      <FormLayout
        isConflictScreen
        yearsList={yearsList}
        selectedYear={selectedYear}
        changeYear={(value): void => setSelectedYears(value)}
        title={
          <Fragment>
            {titleStartElement}
            Data Conflicts
          </Fragment>
        }
        modalCancelText='Stay in Change Form'
        modalTitle='Form changes'
        modalSubmitText='Leave Change Form'
        modalText='Not all changes have been verified by you and if you do not do it and close the window,
        these data will be rejected. Are you sure you want to close the form?'>
        <Typography variant='h4' style={{ paddingBottom: '30px' }}>
          {title}
        </Typography>
        <ConflictHandling
          updateConflicts={
            hasErrorConflictRepsonse ? [] : conflictsData.update_conflicts
          }
          createConflicts={
            hasErrorConflictRepsonse ? [] : conflictsData.create_conflicts
          }
          deleteConflicts={
            hasErrorConflictRepsonse ? errorConflictsData.conflicts : []
          }
          data={tableData}
          conflictTime={
            isConflictDataUpdate ? conflictTime : submitConflictTime
          }
          saveChanges={handleSaveConflictsChanges}
          webformType={value}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openBanner}
          style={{ position: 'absolute' }}>
          <Box className={classes.conflictBanner}>
            <InfoOutlined fontSize='small' />
            <Typography>
              While you were filling out the form, another user entered new
              data. Some of them conflict and are listed below and require
              verification.
            </Typography>
          </Box>
        </Snackbar>
      </FormLayout>
    )
  }

  return (
    <FormLayout
      yearsList={yearsList}
      selectedYear={selectedYear}
      changeYear={(value): void => setSelectedYears(value)}
      modalDisabled={!isTableEdited}
      title={
        <Fragment>
          {titleStartElement}
          Data Management
        </Fragment>
      }
      isConflictScreen={false}
      modalCancelText='Stay'
      modalSubmitText='Leave'
      modalText='Are you sure you want to leave the form?  If you do, 
      the changes you made to the file will not be saved.'
      modalTitle='Do you want to leave the form?'>

      <Box display='flex' flexDirection='column' position='relative'>
        { (formIsLoading || formIsRefetching) && (
          <LinearProgress variant='indeterminate' className={classes.fetchIndicator} />
        )}
        <Card ref={rowContainerRef} className={classes.card}>
          <div
            className={classes.headerContainer}
            style={{
              paddingLeft: hasData ? 64 : 0
            }}
          >
            <div
              id='left_header'
              className={classes.leftHeaderPanel}
              ref={setLeftHeaderEl}>
              <Box
                className={`${classes.tableCell} ${classes.border}`}
                height='54px'>
                <Button size='small'>Row</Button>
                {tableColumnsWithOptions.map((e, idx) => (
                  <Box
                    key={idx}
                    style={{
                      minWidth: '190px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      marginRight: '16px',
                    }}>
                    <SortControl
                      label={e.label}
                      orderBy={sortBy && sortBy.order}
                      isActiveKey={!!sortBy && sortBy.key === e.name}
                      onSort={(order): void => {
                        handleSortChange({
                          order,
                          key: e.name as ColumnType,
                        })
                      }}
                    />
                    <Tooltip placement='top' title={e.description} arrow>
                      <InfoOutlined color='disabled' />
                    </Tooltip>
                  </Box>
                ))}
              </Box>
            </div>

            <div
              id='drag_header'
              className={classes.drag}
              ref={setHeaderDividerEl} />

            <div
              id='right_header'
              className={classes.rightHeaderPanel}
              ref={setRightHeaderEl}>
              <Box display='flex' flexWrap='wrap' flexDirection='column'>
                <Box display='flex' pl='16px' style={{ gap: '16px' }}>
                  {restMonth.map((el, idx) => (
                    <Typography key={idx} className={classes.columnMonth}>
                      {el.name}
                    </Typography>
                  ))}
                  <Typography
                    className={classes.columnMonth + ' ' + classes.currentMonth}
                    style={{ height: '52px' }}>
                    {currentMonth.name}
                  </Typography>
                </Box>
                <Box
                  display='flex'
                  gridGap='16px'
                  pl='16px'
                  className={classes.totalCellBackground}>
                  {restMonth.map((el, idx) => (
                    <Box key={idx} pr={2} className={classes.tableTotalCell}>
                      {tableData.length > 0
                        ? (
                          <Money
                            amount={Number(totalByMonth[el.name])}
                            currency={currency} />
                        ) : ''}
                    </Box>
                  ))}
                  <Box
                    className={`${classes.tableTotalCell} ${classes.totalCellBackground}`}
                    px={4}
                    minWidth='100%'>
                    {tableData.length > 0
                      ? (
                        <Money
                          amount={Number(totalByMonth[currentMonth.name])}
                          currency={currency} />
                      ) : ''}
                  </Box>
                  <Box
                    className={`${classes.tableTotalCell}`}>
                    TOTAL {financialYear.slice(0, 4) + '/' + financialYear.slice(-2)}
                  </Box>
                </Box>
                
              </Box>
            </div>
          </div>

          <div
            id='container'
            className={classes.mainPanelContainer}>
            <div className={classes.container}>
              {tableData.map((e, idx) => (
                <Button
                  key={e.key}
                  onClick={(e): void => handleClick(e, idx)}
                  disabled={!FORM_EDIT_ENABLED}
                  className={classes.rowButton + ' ' + classes.border}>
                  ...
                </Button>
              ))}
            </div>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
              <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
            <div
              id='left_panel'
              onScroll={() => {
                if( leftPanelEl && leftHeaderEl ){
                  leftHeaderEl.scrollLeft = leftPanelEl.scrollLeft
                }
              }}
              className={`${classes.leftPanel} ${classes.scroll}`}
              ref={setLeftPanelEl}>
              {tableData.map((campaign, idx) => (
                <Box key={campaign.key} display='flex'>
                  <Box
                    key={campaign.key}
                    className={`${classes.tableCell} ${classes.border}`}>
                    <Typography
                      variant='body1'
                      align='center'
                      style={{ minWidth: '64px' }}>
                      {empty[idx] ? (
                        <Tooltip title='Empty campaign' arrow>
                          <span
                            style={{ color: '#BD6069', width: '10px' }}>{`! ${
                              idx + 1
                            }`}</span>
                        </Tooltip>
                      ) : duplicates[idx] ? (
                        <Tooltip title='Duplicated campaigns' arrow>
                          <span
                            style={{ color: '#BD6069', width: '10px' }}>{`! ${
                              idx + 1
                            }`}</span>
                        </Tooltip>
                      ) : (
                        <>{idx + 1}</>
                      )}
                    </Typography>

                    {selectOptions.map((el, i) => {
                      return campaign.editable ? (
                        <TableSelect
                          key={i}
                          errrRow={duplicates[idx] || empty[idx]}
                          disabled={
                            isDisabledColumn(
                              tableData[idx],
                              FormColumns.competitor,
                              FormColumns.competitveSecondBrand,
                              el.name
                            ) ||
                            isDisabledColumn(
                              tableData[idx],
                              FormColumns.campaignPillar,
                              FormColumns.campaignSubPillar,
                              el.name
                            ) ||
                            isDisabledColumn(
                              tableData[idx],
                              FormColumns.mediaChannel,
                              FormColumns.mediaSubChannel,
                              el.name
                            )
                          }
                          options={
                            //to be remove when permissions will be introduced
                            el.name === FormColumns.dataSource &&
                            value === WebFormTypes.CompetitiveInvestment
                              ? (el.options as OptionType[]).filter(
                                (e) => e.name !== 'AGB / Nielsen'
                              )
                              : el.name === FormColumns.competitor &&
                                value === WebFormTypes.CompetitiveInvestment
                                ? (el.options as OptionType[]).filter(
                                  (e) =>
                                    e.name !== 'Wind (Wind + Q Telecom)' &&
                                    e.name !== 'Cyta' &&
                                    e.name !== 'Forthnet' &&
                                    e.name !==
                                      'Deutsche Telekom (Cosmote+OTE+Germanos)'
                                )
                                : filterOptions({
                                  column: el,
                                  selectOptions: tableData[idx],
                                  optionsList: selectOptions,
                                  orgId,
                                })
                          }
                          selected={tableData[idx][el.name as ColumnType].id}
                          handleChange={(e): void =>
                            handleChange(e, el.name, idx)
                          }
                        />
                      ) : (
                        <TableOption
                          key={i}
                          errrRow={duplicates[idx] || empty[idx]}
                          selected={
                            tableData[idx][el.name as ColumnType].name ||
                            tableData[idx][el.name as ColumnType]
                          }
                        />
                      )
                    })}
                  </Box>
                </Box>
              ))}
            </div>

            <div
              id='drag'
              className={classes.drag}
              ref={setDividerEl} />
            
            <div
              ref={setRightPanelEl}
              id='right_panel'
              onScroll={() => {
                if( rightPanelEl && rightHeaderEl ){
                  rightHeaderEl.scrollLeft = rightPanelEl.scrollLeft
                }
              }}
              className={`${classes.rightPanel} ${classes.scroll}`}>
              <TableInputSide
                financialYear={data ? data.results.fin_year : ''}
                months={tableMonths}
                currency={
                  data && value !== WebFormTypes.CompetitiveTVSOV
                    ? data.results.market.currency
                    : ''
                }
                editingEnabled={FORM_EDIT_ENABLED}
                submitButtonDisabled={!!submitButtonDisabled || !FORM_EDIT_ENABLED}
                tableData={tableData}
                totalByMarket={totalByMarket}
                handleInputChange={handleInputChange}
                handleSubmitButton={handleSubmitButton}
              />
            </div>

          </div>
        </Card>
        
        { FORM_EDIT_ENABLED && (
          <>
            <Button className={classes.addButton} onClick={handleAddNewRow}>
              + Add new row
            </Button>
            <Button
              className={classes.buttonSave}
              variant='contained'
              color='primary'
              disabled={formIsLoading || formIsRefetching}
              onClick={handleSaveChanges}>
              Save changes
            </Button>
          </>
        )}
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>
        <ConfirmModal
          handleSubmit={handleSubmitDelete}
          handleCancel={handleCloseModal}
          title='Delete Row'
          text='  Are you sure you want to delete the row? 
           If you do this, you will lose your data forever. All unsaved changes will be lost as well.'
          submitText='Delete Row'
          cancelText='Cancel'
        />
      </Modal>
      <Modal
        open={openModalStatusConflict}
        onClose={handleCloseModalStatusConflict}>
        <ConfirmModal
          handleSubmit={handleSaveChanges}
          handleCancel={handleCloseModalStatusConflict}
          title={
            hasStatusConflicts
              ? conflictsStatus.status_conflicts[0].message
              : ''
          }
          text='  Do you want to update your changes?'
          submitText='Save changes'
          cancelText='Cancel'
        />
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isLoadingSave || isSuccessSave}
        autoHideDuration={isSuccessSave ? 3000 : null}
        onClose={(): void => {
          resetSubmitMutation()
          resetUpdateMutation()
          resetDeleteMutation()
        }}>
        <Box className={classes.successBanner}>
          { isLoadingSave ? (
            <Fragment>
              <CircularProgress size='1.25rem' color='inherit' />
              <Typography>Saving your changes...</Typography>
            </Fragment>
          ) : (
            <Fragment>
              <CheckCircle fontSize='small' />
              <Typography>Your changes have been saved</Typography>
            </Fragment>
          )}
        </Box>
      </Snackbar>
    </FormLayout>
  )
}
