
import { SORT_AUDIT_ENTITIES } from '../actions'


const initialState = {
  key: 'name',
  order: 'ASC'
}

export default (state = initialState, action) => {
  
  switch(action.type){
    
    case SORT_AUDIT_ENTITIES:
      return { ...state, ...action.payload }
    
    default:
      return state
  }

}