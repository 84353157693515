import React from 'react'

import { Box, Column, PaginatedTable, ReportRange, SimpleTable, SimpleTableProps } from '@percept/mui'

import { defaultRenderers } from './CellRenderers'

import { useCompactTableCellClasses } from './styles'

import { dmyFormatter } from '@percept/mui/charts'

import { subDays } from 'date-fns'

import { PerformanceReportingDatum } from 'types'

import { PerformanceComparisonRange } from '@percept/types'


export type PerformanceTableProps<T extends PerformanceReportingDatum> = (
  Pick<
    SimpleTableProps<T>, 'rows'
  > &
  Partial<
    Omit<
      SimpleTableProps<T>, 'rows'
    >
  > & {
    nowLabel?: React.ReactNode
    showNowColumn?: boolean
    referenceDate?: Date | null
    performanceComparisonRange?: PerformanceComparisonRange
    showQuarterlyFigure?: boolean
    paginate?: number
  }
)


const deriveComparisonLabel = (
  referenceDate: Date | number | string,
  dayDelta: number,
): React.ReactNode => {

  const referenceEnd = new Date(referenceDate)
  const referenceStart = subDays(referenceEnd, dayDelta - 1)

  const benchmarkEnd = subDays(referenceStart, 1)
  const benchmarkStart = subDays(benchmarkEnd, dayDelta - 1)

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <ReportRange
        start={referenceStart}
        end={referenceEnd} />
      <Box my={0.25}>
        vs.
      </Box>
      <ReportRange
        start={benchmarkStart}
        end={benchmarkEnd} />
    </Box>
  )

}


export function PerformanceTable<T extends PerformanceReportingDatum>({
  columns,
  rows,
  renderers = {},
  classes = {},
  paginate,
  nowLabel,
  referenceDate,
  performanceComparisonRange,
  showNowColumn = true,
  showQuarterlyFigure = false,
  // initialSortConfig = {
  //   key: 'week' as Extract<keyof T, string>,
  //   order: 'DESC',
  // },
  ...props
}: PerformanceTableProps<T>): JSX.Element {

  const defaultClasses = useCompactTableCellClasses()

  const date = referenceDate || subDays(new Date(), 1)

  const dateLabel = nowLabel || dmyFormatter(date)

  const mergedProps: SimpleTableProps<T> = {
    ...props,
    // initialSortConfig,
    classes: {
      ...defaultClasses,
      ...classes,
    },
    columns: (
      columns || [
        {
          key: 'label',
          label: '',
        },
        ...(showNowColumn === false ? [] : [
          {
            key: 'now',
            label: dateLabel,
            align: 'right',
          }
        ]),
        (!performanceComparisonRange || performanceComparisonRange === 'd7') && {
          key: 'week',
          label: 'Week',
          align: 'right',
          tooltip: deriveComparisonLabel(date, 7),
        },
        (!performanceComparisonRange || performanceComparisonRange === 'd28') && {
          key: 'month',
          label: 'Month',
          align: 'right',
          tooltip: deriveComparisonLabel(date, 28),
        },
        (!performanceComparisonRange && showQuarterlyFigure) && {
          key: 'quarter',
          label: 'Quarter',
          align: 'right',
          tooltip: deriveComparisonLabel(date, 28 * 3),
        },
        (!performanceComparisonRange || performanceComparisonRange === 'd365') && {
          key: 'year',
          label: 'Year',
          align: 'right',
          tooltip: deriveComparisonLabel(date, 365),
        },
      ].filter(Boolean) as Column<T>[]
    ),
    rows,
    renderers: {
      ...defaultRenderers,
      ...renderers,
    },
  }

  if (paginate) {
    return <PaginatedTable paginate={paginate} {...mergedProps} />
  }

  return <SimpleTable {...mergedProps} />
}
