import React, { useEffect, useMemo, useState } from 'react'

import {
  Box,
  Loader,
  MenuOption,
  RoundedPlainTextButtonMenu,
  makeAppStyles,
  useAppTheme,
} from '@percept/mui'

import { ArrowDropDown } from '@percept/mui/icons'

import { MarketDisplayLabel } from 'components/MarketDisplay'

import { GlobalOverview } from './GlobalOverview'

import { MarketOverview } from './MarketOverview'

import { useCompactTableCellClasses } from 'dashboard/src/components/Tables'

import { getTableRenderers } from './lib'

import {
  areDatesEqual,
  getComparisonDateRanges,
  getDateOptions,
  getPreviousPotentialEfficiencyReferenceDate,
  isoDate,
} from './utils'

import { sortBy } from 'lodash-es'

import { VODAFONE_GLOBAL_ID } from 'vodafoneMarkets'

import {
  useCreativeXReferenceDate,
  useNestedOrgUnitPotentialEfficiencyScores,
} from '@percept/hooks'

import { useOverviewUserMarketOptions } from './userHooks'

import {
  useBrandInvestmentShareBreakdownByMarket,
  useCompetitiveSOSByMarket,
  useMediaInvestmentReportsByMarket,
} from './globalDataHooks'

import { differenceInDays } from 'date-fns'

import { OverviewContext } from './overviewContext'

import { vodafoneMarketsByIsoCode } from './fixtureData'

import {
  BaseOverviewComponentProps,
  OverviewGranularity,
  OverviewEntity,
  OverviewClassNames,
} from './typings'

import { ComparisonMethod } from '@percept/utils'

const useStyles = makeAppStyles<{}, OverviewClassNames>( theme => ({
  fullHeightCard: {
    height: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    marginLeft: theme.spacing(1),
  },
  tableCell: {
    padding: theme.spacing(1.5, 2),
  },
}))


export const Overview = (): JSX.Element => {

  const [org, setOrg] = useState<OverviewEntity>('Global')

  const appTheme = useAppTheme()

  const [comparisonMethod, setComparisonMethod] = useState<ComparisonMethod>('ABSOLUTE')

  const cellRenderers = useMemo(() => {
    return getTableRenderers(appTheme, comparisonMethod)
  }, [appTheme, comparisonMethod])

  const classes = useStyles()

  const compactTableClasses = useCompactTableCellClasses()

  const tableClasses = {
    ...compactTableClasses,
    tableCell: compactTableClasses.tableCell + ' ' + classes.tableCell,
  }

  const [granularity, setGranularity] = useState<OverviewGranularity>('quarter')

  const marketOptions = useOverviewUserMarketOptions()

  const { data: creativeXReferenceDate, isLoading } = useCreativeXReferenceDate()
  
  const maxDate: Date | null = (
    creativeXReferenceDate && creativeXReferenceDate.reference_date ?
      new Date(creativeXReferenceDate.reference_date) :
      null
  )
  const maxDateTime: number | null = maxDate ? maxDate.getTime() : null
    
  const [referenceDate, setReferenceDate] = useState<Date | null>(null)

  const dateRanges = useMemo(() => {
    return getComparisonDateRanges({
      primaryGranularity: granularity,
      secondaryGranularity: granularity,
      referenceDate,
    })
  }, [granularity, referenceDate])

  useEffect(() => {
    if( !referenceDate && maxDateTime ){
      setReferenceDate(new Date(maxDateTime))
    }
  }, [referenceDate, maxDateTime])

  useEffect(() => {
    if( referenceDate && maxDateTime ){
      const dateOptions = getDateOptions(new Date(maxDateTime), granularity)
      for( const option of dateOptions ){
        if( areDatesEqual(option, referenceDate) ){
          return
        }
      }
      // Find nearest date range
      const datesWithDelta: [Date, number][] = dateOptions.map( date => ([
        date, differenceInDays(date, referenceDate)
      ]))
      const sortedDatesWithDelta = sortBy(
        datesWithDelta.filter( d => d[1] > 0),
        delta => delta[1]
      )
      const nearestDate: Date = (
        sortedDatesWithDelta.length ?
          sortedDatesWithDelta[0][0] :
          dateOptions[dateOptions.length - 1]
      )
      setReferenceDate(nearestDate)
    }
  }, [granularity, referenceDate, maxDateTime])

  // NOTE - we kick these off here as they take the longest by far, and don't rely on
  // other reference date endpoints
  useNestedOrgUnitPotentialEfficiencyScores({
    org_unit_id: VODAFONE_GLOBAL_ID,
  })
  useNestedOrgUnitPotentialEfficiencyScores({
    org_unit_id: VODAFONE_GLOBAL_ID,
    reference_date: isoDate(getPreviousPotentialEfficiencyReferenceDate()),
  })
  useMediaInvestmentReportsByMarket({
    referenceDate,
  })
  useBrandInvestmentShareBreakdownByMarket({
    referenceDate,
  })
  useCompetitiveSOSByMarket({
    referenceDate,
  })

  const baseOverviewComponentProps: BaseOverviewComponentProps = {
    tableClasses,
    cellRenderers,
    classes,
  }

  let orgLabel: string | JSX.Element = 'Global'
  if( org !== 'Global' ){
    const market = vodafoneMarketsByIsoCode[org]
    orgLabel = (
      <>
        <MarketDisplayLabel
          iso_code={org}
          name={market.name}
          FlagProps={{
            size: 18,
          }} />
      </>
    )
  }

  return (
    <Box p={3}>
      <Box display='flex' justifyContent='space-between' alignItems='center' mb={3}>
        <RoundedPlainTextButtonMenu
          value={org}
          label={orgLabel}
          TriggerProps={{
            variant: 'contained',
            size: 'large',
            endIcon: <ArrowDropDown />,
          }}
          options={[
            { value: 'Global', label: 'Global' },
            ...marketOptions.map( market => ({
              value: market.iso_code,
              label: (
                <MarketDisplayLabel
                  iso_code={market.iso_code}
                  name={market.name} />
              )
            })),
          ] as MenuOption<OverviewEntity>[]}
          onChange={(e, value): void => {
            setOrg(value)
          }} />
      </Box>

      { !referenceDate || !maxDate || isLoading ? (
        <Loader preset='fullscreen' />
      ) : (
        <OverviewContext.Provider
          value={{
            referenceDate,
            setReferenceDate,
            maxDate,
            granularity,
            setGranularity,
            dateRanges,
            comparisonMethod,
            setComparisonMethod,
          }}>
          { org === 'Global' ? (
            <GlobalOverview
              {...baseOverviewComponentProps} />
          ) : (
            <MarketOverview
              market={org}
              {...baseOverviewComponentProps} />
          )}
        </OverviewContext.Provider>
      )}
    </Box>
  )
}
