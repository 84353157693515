import React from 'react'

import { ButtonProps, MenuAbstractionProps, MenuOption, RoundedPlainTextButtonMenu } from '@percept/mui'

import { ArrowDropDown, dimensionIcons } from '@percept/mui/icons'

import { intersection, uniq } from 'lodash-es'

import {
  dimensionMap,
  performanceReportingDimensionOrder,
  doubleVerifyAdformReportingDimensionOrder,
  doubleVerifyAdwordsReportingDimensionOrder,
  doubleVerifyFacebookReportingDimensionOrder,
} from '@percept/constants'

import { AnyPerformanceDimension } from '@percept/types'


export const allDimensionsOrder = uniq([
  ...performanceReportingDimensionOrder,
  ...doubleVerifyAdformReportingDimensionOrder,
  ...doubleVerifyAdwordsReportingDimensionOrder,
  ...doubleVerifyFacebookReportingDimensionOrder,
])


export const getPerformanceDimensionOptions = (
  reportingDimensions: AnyPerformanceDimension[]
): MenuOption<AnyPerformanceDimension>[] => (
  intersection(allDimensionsOrder, reportingDimensions).map( value => ({
    value,
    label: dimensionMap[value].text,
  }))
)


export type PerformanceDimensionSelectProps = (
  Omit<
    MenuAbstractionProps<
      ButtonProps, AnyPerformanceDimension
    >,
    'children' | 'options'
  > & {
    availableDimensions?: AnyPerformanceDimension[]
  }
)

export const PerformanceDimensionSelect = ({
  value,
  TriggerProps = {},
  availableDimensions = performanceReportingDimensionOrder,
  ...props
}: PerformanceDimensionSelectProps): JSX.Element => {

  const TriggerIconComponent = value && dimensionIcons[value]

  const startIcon = TriggerIconComponent && (
    <TriggerIconComponent />
  )

  const options = getPerformanceDimensionOptions(availableDimensions)

  return (
    <RoundedPlainTextButtonMenu
      value={value}
      label={value && dimensionMap[value].text}
      TriggerProps={{
        startIcon,
        endIcon: <ArrowDropDown />,
        variant: 'contained',
        ...TriggerProps,
      }}
      options={options}
      {...props} />
  )
}
