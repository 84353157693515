import { capitalize, get } from 'lodash-es'

import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query'

import qs from 'query-string'

import { DateType, ReportProviderV2 } from '@percept/types'

import { PerformanceValue } from 'types'

import { apiClients, makeClientQueryFn } from '@percept/hooks'

import { format } from 'date-fns'

import {
  adformMediaTypeDisplayLabelMapping,
  adformMediaTypeEnumMapping,
  subNetworkLabelMap,
} from '@percept/constants'

import { facebookSubNetworkEnumMapping } from '@percept/api/adapters/performanceReporting'

import { AxiosError } from 'axios'


export type MediaQualityRateProvider = ReportProviderV2 | 'AMAZON_ADS'

export type MediaQualityRateGroupingType = 'PLACEMENT' | 'PROVIDER'

export type MediaQualityRateHookProps = {
  platformUnitId: string | null
  start: DateType | null
  end: DateType | null
  groupBy?: MediaQualityRateGroupingType | null
  providers?: MediaQualityRateProvider[] | null
} & Partial<UseQueryOptions<DerivedMediaQualityRateRow[], AxiosError>>


export type MediaQualityRateResponseDatum = {
  source_provider: MediaQualityRateProvider | null
  placement: string | null
  total_impressions: PerformanceValue
  verifiable_impressions: PerformanceValue
  monitored_impressions: PerformanceValue
  blocked_impressions: PerformanceValue
  authentic_impressions: PerformanceValue
  doubleverify_tracking_usage_rate: PerformanceValue
  media_quality_rate: PerformanceValue
}

export type MediaQualityRateResponse = MediaQualityRateResponseDatum[]


export type MediaQualityRateRow = {
  provider_name: MediaQualityRateProvider | null
  placement_category: string
  placement: string | null
  total_campaign_impressions: number | null
  verifiable_impressions: number | null
  dv_matched_impressions: number | null
  dv_monitored_impressions: number | null
  dv_blocked_impressions: number | null
  dv_authentic_impressions: number | null
  tracking_usage_rate: number | null
  media_quality_rate: number | null
  authentic_media_rate: number | null
}

export type MediaQualityRateApiResponse = {
  rates: MediaQualityRateRow[]
}

export type DerivedMediaQualityRateRow = (
  Omit<MediaQualityRateResponseDatum, 'placement'>
  & {
    placement: string | null
  }
)

const placementEnumMappingsByProvider: Partial<Record<MediaQualityRateProvider, Record<number, string>>> = {
  FACEBOOK: facebookSubNetworkEnumMapping,
  ADFORM: adformMediaTypeEnumMapping,
}

const combinedEnumLabelMapping: Record<string, string> = {
  ...subNetworkLabelMap,
  ...adformMediaTypeDisplayLabelMapping,
}


const getProviderPlacementValue = ({
  provider,
  value,
}: {
  provider: MediaQualityRateProvider
  value: number | string | null
}): string | null => {
  // NOTE - handle API change from integer values to display labels
  if( typeof value === 'string' ){
    return value
  }
  const valueMapping = placementEnumMappingsByProvider[provider]
  if( value && valueMapping ){
    return get(combinedEnumLabelMapping, valueMapping[value], null)
  }
  return null
}

const getDisplayPlacementCategory = (placementCategory: string | null): string | null => {
  if( placementCategory ){
    return placementCategory === 'YOUTUBE' ? 'YouTube' : capitalize(placementCategory)
  }
  return null
}

const mediaQualityRateRowAdapter = (
  row: MediaQualityRateRow
): DerivedMediaQualityRateRow => {
  return {
    ...row,
    source_provider: row.provider_name,
    placement: (
      row.provider_name && row.placement ? (
        getProviderPlacementValue({ provider: row.provider_name, value: row.placement })
      ) : row.placement_category ?
        getDisplayPlacementCategory(row.placement_category) : null
    ),
    total_impressions: row.total_campaign_impressions,
    monitored_impressions: row.dv_monitored_impressions,
    blocked_impressions: row.dv_blocked_impressions,
    authentic_impressions: row.dv_authentic_impressions,
    doubleverify_tracking_usage_rate: row.tracking_usage_rate,
  }
}

export const useMediaQualityRate = ({
  platformUnitId,
  start,
  end,
  groupBy = null,
  providers,
  enabled = true,
  ...options
}: MediaQualityRateHookProps): UseQueryResult<DerivedMediaQualityRateRow[], AxiosError> => {
  const start_date = start && format(new Date(start), 'yyyy-MM-dd')
  const end_date = end && format(new Date(end), 'yyyy-MM-dd')
  return useQuery({
    queryKey: ['mediaQualityRate', platformUnitId, start_date, end_date, providers && providers.sort(), groupBy],
    queryFn: makeClientQueryFn<MediaQualityRateApiResponse, DerivedMediaQualityRateRow[]>(
      apiClients.performance,
      {
        url: `/doubleverify/unit/${platformUnitId}/tracking-rates`,
        params: {
          start_date,
          end_date,
          ...(groupBy && {
            group_by: groupBy,
          }),
          ...(providers && {
            source_providers: providers,
          }),
        },
        paramsSerializer: qs.stringify,
      },
      response => (
        response.data.rates.map(mediaQualityRateRowAdapter)
      ),
    ),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 1000 * 60 * 5,
    ...options,
    enabled: !!(enabled && platformUnitId && start && end),
  })
}
