
import React from 'react'

import { Button, ButtonProps, CircularProgress } from '@percept/mui'

import { ArrowBack, Check, SvgIconComponent } from '@percept/mui/icons'


type AuthButtonProps = ButtonProps & {
  ButtonComponent: React.FC<ButtonProps>
  loading?: boolean
  IconComponent?: SvgIconComponent | null
}

type AuthButton = (props: AuthButtonProps) => JSX.Element


const getIcon = (
  Icon?: SvgIconComponent | null,
  loading?: boolean
): JSX.Element | null => (
  loading ? (
    <CircularProgress size='1em' color='inherit' />
  ) : (
    Icon && <Icon /> || null
  )
)


export const BackToSignIn: AuthButton = ({
  children = 'Back To Sign In',
  loading = false,
  IconComponent = ArrowBack,
  ButtonComponent = Button,
  ...props
}) => (
  <ButtonComponent
    size='small'
    startIcon={getIcon(IconComponent, loading)}
    {...props}>
    { children }
  </ButtonComponent>
)


export const Confirm: AuthButton = ({
  children = 'Confirm',
  loading = false,
  IconComponent = Check,
  ButtonComponent = Button,
  ...props }) => (
  <ButtonComponent
    fullWidth
    variant='contained'
    color='primary'
    size='large'
    startIcon={getIcon(IconComponent, loading)}
    {...props}>
    { children }
  </ButtonComponent>
)
