import { coerceReportProvider } from '@percept/utils'

import { providerURLMap } from '@percept/constants'

import {
  AsyncReduxAction,
  ReportProviderV2
} from '@percept/types'


const { CORE_API_ROOT } = process.env


const createAsyncJsonSchemaAction = (action: AsyncReduxAction): AsyncReduxAction => ({
  ...action,
  options: {
    baseURL: `${CORE_API_ROOT}/v1`,
    ...action.options || {},
  }
})


export const LOAD_REPORT_SERIES_CONFIG_SCHEMA = 'LOAD_REPORT_SERIES_CONFIG_SCHEMA'

export const loadReportSeriesConfigSchema = (
  { provider }: { provider: ReportProviderV2 }
): AsyncReduxAction => createAsyncJsonSchemaAction({
  type: LOAD_REPORT_SERIES_CONFIG_SCHEMA,
  resource: `/json-schemas/report-series-config/${providerURLMap[coerceReportProvider(provider)]}`,
  meta: { provider },
})
