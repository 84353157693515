import { createStore, applyMiddleware, compose, Store } from 'redux'

import { createMiddleware, CreateMiddlewareProps } from './middleware'

import { createRootReducer } from './reducers'

import { Dictionary } from '@percept/types'

export type CreateStoreProps = CreateMiddlewareProps & {
  initialState?: Dictionary
}

export default ({
  api,
  history,
  persistence,
  initialState = {},
}: CreateStoreProps): Store => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(
      applyMiddleware(...createMiddleware({ history, api, persistence }))
    )
  )

  /* Hot Module Replacement */
  if (module.hot) {
    module.hot.accept('./reducers', () =>
      store.replaceReducer(createRootReducer(history))
    )
  }

  return store
}
