
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'

import { apiInitialState } from '../../constants'

import { apiResponseOf, identity } from '@percept/utils'

import { isEqual } from 'lodash-es'

import { ApiResponse, MetricMetadataType, MetricMetadataEntryType, ProviderParams } from '@percept/types'

import { MetricMetadataState } from './typings'


type StoreState = {
  metricMetadata: MetricMetadataState
}

const createShallowEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual
)


export const getFilteredMetricMetadata = createShallowEqualSelector(
  (state: StoreState, { provider }: ProviderParams): ApiResponse<MetricMetadataType> => {

    const response = state.metricMetadata.responsesByFilter[provider]

    if( response && response.error ) return {
      ...response,
      data: null,
    }

    if( !response || !response.data ) return apiInitialState

    return apiResponseOf(state.metricMetadata.byId)

  },

  identity

)


export const getMetricMetadata = createShallowEqualSelector(
  (state: StoreState,
    { provider }: Partial<ProviderParams>
  ): ApiResponse<MetricMetadataEntryType[]> | ApiResponse<MetricMetadataType> => {

    if( typeof provider === 'undefined' ){
      return state.metricMetadata.overview
    }

    const response = state.metricMetadata.responsesByFilter[provider || '']

    if( response && response.error ) return {
      ...response,
      data: null,
    }

    if( !response || !response.data ) return apiInitialState

    return apiResponseOf(state.metricMetadata.byId)

  },

  identity

)


export const getMetricMetadataById = createSelector(
  (state: StoreState) => state.metricMetadata,
  metricMetadata => apiResponseOf(metricMetadata.byId)
)
