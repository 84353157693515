
import React, { useMemo } from 'react'

import { MetricVisualisationWrap, MetricCard } from '../Cards'

import { TextMetricVisualisation } from '../Metric'

import { getPath, parseDimension } from '@percept/utils'

import { DimensionType, ReportEntity } from '@percept/types'


export type EntitySummaryProps = ReportEntity & {
  dimensionOptions?: DimensionType[]
}

export const EntitySummary = ({
  dimensionOptions = [],
  performance,
  ...entity
}: EntitySummaryProps): JSX.Element => {

  const parsedDimensions = useMemo(() => {
    return dimensionOptions.filter( d => d !== 'count' ).map(parseDimension)
  }, [dimensionOptions])

  return (
    <MetricCard
      health={entity.count_health}
      title={entity.name}>

      { parsedDimensions.map( ({ raw, dimension, label }) => (
        <MetricVisualisationWrap
          key={dimension}
          label={label}>
          <TextMetricVisualisation
            metric_type='value'
            dimension={dimension}
            format={dimension === 'cost' ? 'currency' : 'number'}
            currency={entity.currency_code}
            value={getPath(performance, raw, 0)}
            total={getPath(performance, raw, 0)} />
        </MetricVisualisationWrap>
      )) }

    </MetricCard>
  )
}
