import React from 'react'

import { MetricVisualisationWrap, MetricCard, MetricCardProps } from './MetricCard'

import { GridProps } from '@material-ui/core'

import { DatumType } from '@percept/types'


export type StatCardDatum = (
  Omit<
    DatumType,
    'label'
  > & {
    label: React.ReactNode | JSX.Element
  }
)

export type StatCardProps<T extends StatCardDatum> = {
  data: T[]
  VisualisationComponent: (props: T) => JSX.Element
  GridItemProps?: Partial<GridProps>
} & MetricCardProps


export function StatCard<T extends StatCardDatum>({
  data,
  VisualisationComponent,
  GridItemProps,
  ...props
}: StatCardProps<T>): JSX.Element {

  return (
    <MetricCard
      {...props}>

      { data.map( (d, i) => (
        <MetricVisualisationWrap
          key={i}
          button={false}
          label={d.label}
          GridProps={GridItemProps}>

          <VisualisationComponent {...d} />

        </MetricVisualisationWrap>
      ))}

    </MetricCard>
  )
}
