
import { createApiReducer, createKeyedReducer } from '../../../reducers'

import { getReportKeyFromAction, getMetricKeyFromAction } from '../../../utils'

import {
  LOAD_CLIENT_REPORT_METRIC,
} from '../actions'  

import { Reducer } from '@percept/types'

import { ClientReportState } from '../typings'

import { pick } from 'lodash-es'


const metrics: Reducer<ClientReportState['metrics']> = createKeyedReducer(
  createKeyedReducer(
    createApiReducer(LOAD_CLIENT_REPORT_METRIC, {
      /**
       * The api returns scant information here, most relevant properties
       * are actually encapsulated within the meta params as they all form
       * part of the request URL. A simple merge with the payload as last write
       * is enough to populate these as defaults.
       */
      getPayloadFromAction: ({ payload, meta }) => ({
        ...pick(meta, ['metric_id', 'report_id', 'entity_id', 'entity_type', 'dimension']),
        ...payload,
      })
    }),
    getMetricKeyFromAction
  ),
  getReportKeyFromAction
)

export default metrics
