
import React from 'react'

import { ChannelThemeProvider } from '@percept/mui'

import { ReportDashboardDriver } from './ReportDashboardDriver'

import {
  useFilteredMetricMetadata,
  useDocumentTitle,
  useReportSeries,
  useReportSeriesReports,
  useReportSeriesReport,
  useReportSeriesReportEntity,
  useReportSeriesReportMetric,
  useReportSeriesReportPayload,
  useReportSeriesMetricTimeseries,
  useReportLayout,
} from '@percept/hooks'

import { get } from 'lodash-es'

import { providerChannelMap } from '@percept/constants'

import { coerceReportProvider } from '@percept/utils'

import { ReportDashboardAppProps } from './typings'


const defaultPageConfig = {
  site: 'Percept',
  paths: ['Report Dashboard'],
}


export const ReportDashboardApp = ({
  series_id = null,
  report_id,
  result_id,
  setDefaultResultId = false,
  report_layout_id,
  useSeries = useReportSeries,
  useSeriesReports = useReportSeriesReports,
  useReport = useReportSeriesReport,
  usePayload = useReportSeriesReportPayload,
  useEntity = useReportSeriesReportEntity,
  useMetric = useReportSeriesReportMetric,
  useSeriesMetric = useReportSeriesMetricTimeseries,
  useLayout = useReportLayout,
  useProviderMetricMetadata = useFilteredMetricMetadata,
  basePageConfig = defaultPageConfig,
  ...props
}: ReportDashboardAppProps): JSX.Element => {

  const [report] = useReport({ report_id, series_id })

  series_id = series_id || get(report.data, 'series_id', null)

  const [series] = useSeries({ series_id })

  const [reportListing] = useSeriesReports({ series_id })

  const provider = series.data && coerceReportProvider(series.data.provider.slug)

  const channel = provider && providerChannelMap[provider]

  // Allow consumers to override layout
  report_layout_id = report_layout_id || get(series.data, 'layout_id', null)

  if( setDefaultResultId ){
    result_id = result_id || get(report.data, 'final_task_id')
  }

  const [payload] = usePayload({
    report_id,
    series_id,
    ...(result_id && {
      result_id
    }),
  })

  const [metadata] = useProviderMetricMetadata({ provider })

  const [layout] = useLayout({ report_layout_id })

  return (
    <ChannelThemeProvider
      channel={channel}>

      <ReportDashboardDriver
        channel={channel}
        provider={provider}
        series={series}
        reportListing={reportListing}
        series_id={series_id}
        report_id={report_id}
        result_id={result_id}
        report={report}
        payload={payload}
        metadata={metadata}
        layout={layout}
        useEntity={useEntity}
        useMetric={useMetric}
        useSeriesMetric={useSeriesMetric}
        usePageTitle={useDocumentTitle}
        basePageConfig={basePageConfig}
        {...props} />

    </ChannelThemeProvider>
  )
}
