
import {
  getHealthFrom,
  collectMetricIdsFromLayout,
  getPath,
} from '@percept/utils'

import {
  MetricMetadataType,
  LayoutType,
  ReportMetricsPayload,
  LayoutMetricType,
  LayoutNodeType,
  ReportEntityPayload,
} from '@percept/types'

import { memoize } from 'lodash-es'

import { MetricSearchResult, MetricSearchConfig } from './typings'


const EMPTY_QUERY_RESULT: MetricSearchResult[] = []

type LayoutQueryResultProps = {
  entity: ReportEntityPayload | null
  metrics: ReportMetricsPayload | null
  metadata: MetricMetadataType | null
  searchConfig: MetricSearchConfig | null
  layout: LayoutType | null
  healthDimension?: string
}

export const getMetricSearchResults = ({
  entity,
  metrics,
  metadata,
  searchConfig,
  layout,
  healthDimension = 'count',
}: LayoutQueryResultProps): MetricSearchResult[] => {

  if( !metadata || !searchConfig || !metrics || !layout ){
    return EMPTY_QUERY_RESULT
  }

  let ids: string[] = []

  const {
    query,
    tabs,
    searchDescriptions,
    searchIds = false,
    sortBy: sortMetricsBy = 'A_TO_Z',
    allowPayloadSearch = false,
    searchLayout = true,
  } = searchConfig

  const tabById: Record<string, string> = {}

  if( allowPayloadSearch && !searchLayout ){
    ids = [
      ...Object.keys(metrics),
      ...(Object.keys(getPath(entity, ['derived', 'attributes']) || {})),
      ...(Object.keys(getPath(entity, ['derived', 'performance']) || {})),
    ]
  }else if( tabs && tabs.length ){
    const members = layout.members.filter( m => {
      return m.type === 'tab' && tabs.indexOf(m.key || '') !== -1
    })
    if( !members.length ){
      console.warn(`tabs not found: '${tabs.join('", "')}'`)
      ids = []
    }else{
      ids = collectMetricIdsFromLayout({ members, type: 'main', name: '' }, metrics)
    }
  }else{
    ids = collectMetricIdsFromLayout(layout, metrics)
  }

  if( ids.length === 0 ){
    return EMPTY_QUERY_RESULT
  }

  const sortValueById: Record<string, number | string> = {}

  if( query || sortMetricsBy === 'A_TO_Z' ){

    const matcher = (query || '').toLowerCase()

    const getQueryIndex = (q?: string): number => q ? q.toLowerCase().indexOf(matcher) : -1

    const titleMatches: string[] = []

    ids.forEach( id => {
      if( metadata[id] ){

        if( sortMetricsBy === 'A_TO_Z' ){
          sortValueById[id] = metadata[id].title.toLowerCase()
        }

        if( query ){

          let descriptionIndex = -1

          const titleIndex = getQueryIndex(metadata[id].title)

          if( searchDescriptions ) descriptionIndex = getQueryIndex(metadata[id].descriptions.s)

          if( titleIndex > -1 || (searchDescriptions && descriptionIndex > -1) ){

            titleMatches.push(id)

            if( sortMetricsBy === 'RANK' ){
              sortValueById[id] = titleIndex > -1 ? titleIndex : descriptionIndex
            }

          }else if( allowPayloadSearch && searchIds && id.indexOf(matcher) > -1 ){
            titleMatches.push(id)
            sortValueById[id] = id
          }

        }
      }
    })

    if( query ){
      ids = titleMatches
    }

  }

  const {
    minHealth = 0,
    maxHealth = 100,
    healthOnly = false,
  } = searchConfig

  const healthMatches: string[] = []

  ids.forEach( id => {
    const health = getHealthFrom(metrics[id] && metrics[id].dimensions[healthDimension])

    if( typeof health === 'number' ){

      if( sortMetricsBy === 'HEALTH_ASC' ){
        sortValueById[id] = health
      }else if( sortMetricsBy === 'HEALTH_DESC' ){
        sortValueById[id] = 1 - health
      }

      if( !healthOnly || (health >= minHealth && health <= maxHealth) ){
        healthMatches.push(id)
      }

    }else if( !healthOnly ){

      healthMatches.push(id)

      if( sortMetricsBy === 'HEALTH_ASC' || sortMetricsBy === 'HEALTH_DESC' ){
        sortValueById[id] = 2
      }

    }
  })

  ids = healthMatches

  const members = ids.sort((a, b): number => (
    sortValueById[a] > sortValueById[b] ?
      1 :
      sortValueById[a] < sortValueById[b] ?
        -1 :
        0
  )).map( id => ({
    id,
    tab: tabById[id] || null,
  }))

  return members
}


export const filterLayoutWithMatches = ({
  layout,
  matches,
}: {
  layout: LayoutType | null
  matches: MetricSearchResult[]
}): LayoutType | null => {

  let resultsLayout = layout

  if( layout ){

    const ids = matches.map( result => result.id )

    const transform = (node: LayoutType | LayoutNodeType): LayoutType => {
      return {
        ...node,
        ...(!!node.members && {
          members: node.members.filter(
            (n: LayoutMetricType) => !n.id || ids.includes(n.id)
          ).map(transform).filter( (n: LayoutType) => !n.members || !!n.members.length ),
        } || {}),
      }
    }
    resultsLayout = transform(layout)

  }

  return resultsLayout
}


function visitNode(node: LayoutType, path: number[]): LayoutType & { nodePath: number[] } {
  return {
    nodePath: path,
    ...node,
    ...(node.members && {
      members: node.members.map( (n, i) => visitNode(n, [...path, i]) )
    } || {}),
  }
}

export const withLayoutItemPaths = (layout: LayoutType): LayoutType => (
  visitNode(layout, [])
)


export const makeLayout = memoize(
  (
    members: LayoutMetricType[] = [],
    seedLayout: LayoutType | null
  ): LayoutType & { metric_count: number } => ({
    type: 'main',
    name: '',
    features: seedLayout && seedLayout.features || {},
    members: [
      {
        type: 'tab',
        key: 'metric-search',
        name: '',
        features: {
          icon: 'search',
        },
        members: [
          {
            type: 'section',
            name: `${members.length || 'No'} match${members.length === 1 ? '' : 'es'}`,
            members: [
              { type: 'optional', metrics: [], operator: 'any' },
              ...members,
            ]
          },
        ]
      },
      ...(seedLayout && seedLayout.members || []),
    ],
    metric_count: members.length,
  })
)
