
import { SORT_CLIENT_REPORT_ENTITIES } from '../actions'

import { Reducer } from '@percept/types'

import { ClientReportState } from '../typings'


const initialState: ClientReportState['entitySort'] = {
  key: 'name',
  order: 'ASC'
}

const entitySort: Reducer<ClientReportState['entitySort']> = (state = initialState, action) => {
  
  switch(action.type){
    
    case SORT_CLIENT_REPORT_ENTITIES:
      return { ...state, ...action.payload }
    
    default:
      return state
  }

}

export default entitySort
