import React, { Fragment } from 'react'

import { Link, useLocation } from 'react-router-dom'

import {
  ButtonProps,
  makeAppStyles,
  RoundedPlainTextButton,
  RoundedPlainTextButtonMenu,
} from '@percept/mui'

import { ChevronRight, ArrowDropDown } from '@percept/mui/icons'

import { ActiveOrgEnvironment } from '../Organisation'

import { useNavigation } from '@percept/hooks'

import { PlatformUnit } from '@percept/types'

export type PlatformUnitCrumbsProps = Pick<
  ActiveOrgEnvironment,
  | 'hierarchy'
  | 'activePlatformUnit'
  | 'platformUnitOptions'
  | 'childPlatformUnitLabel'
  | 'loading'
> & {
  showActiveOnly?: boolean
  ButtonProps?: Partial<ButtonProps>
}

const areOrgsEqual = (
  a: Pick<PlatformUnit, 'id'> | null,
  b: Pick<PlatformUnit, 'id'> | null
): boolean => {
  return Boolean(a && b && a.id === b.id)
}

const useStyles = makeAppStyles((theme) => ({
  menu: {
    transition: theme.transitions.create('color'),
  },
  menuSelected: {
    transition: theme.transitions.create('color'),
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.dark,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  orgLink: {
    color: theme.palette.primary.main,
  },
  orgLinkSeparator: {
    fontSize: 20,
    margin: theme.spacing(0, 0.5),
  },
}))

export const PlatformUnitCrumbs = ({
  activePlatformUnit,
  platformUnitOptions,
  childPlatformUnitLabel,
  hierarchy,
  loading,
  showActiveOnly,
  ButtonProps = {},
}: PlatformUnitCrumbsProps): JSX.Element => {
  const lastCrumb = hierarchy[hierarchy.length - 1]

  const crumbs = showActiveOnly && lastCrumb ? [lastCrumb] : hierarchy

  const classes = useStyles()

  const navigate = useNavigation()
  const location = useLocation()

  const pathnameFragments = location.pathname.split('/')
  const topLevelRoute = pathnameFragments[1] !== 'reporting' ? 'dashboards' : 'reporting'
  const activeReportingRoute = pathnameFragments[3] || 'media-investment'
  const routeSuffix = topLevelRoute === 'reporting' ? `/${activeReportingRoute}/` : ''

  const triggerProps: Partial<ButtonProps> = {
    disabled: loading,
    variant: 'contained',
    endIcon: <ArrowDropDown />,
    className: classes.menu,
    ...ButtonProps,
  }

  return (
    <Fragment>
      {crumbs.map(({ id, name, options }, i, all) => {
        const isNotLast = i < all.length - 1
        const isCrumb = isNotLast
        const isActive = areOrgsEqual({ id }, activePlatformUnit)
        const className = isActive ? classes.menuSelected : classes.menu
        return (
          <Fragment key={id}>
            {options && options.length > 1 ? (
              <RoundedPlainTextButtonMenu
                value={{ id }}
                label={name}
                TriggerProps={{
                  ...triggerProps,
                  className,
                }}
                isEqual={areOrgsEqual}
                options={options}
                onChange={(e, platformUnit): void => {
                  const typedPlatformUnit = platformUnit as PlatformUnit | null
                  if (typedPlatformUnit) {
                    navigate(
                      `/${topLevelRoute}/${typedPlatformUnit.id}${routeSuffix}`
                    )
                  }
                }}
              />
            ) : (
              <Link
                className={isCrumb ? classes.orgLink : undefined}
                to={`/${topLevelRoute}/${id}${routeSuffix}`}>
                <RoundedPlainTextButton
                  {...triggerProps}
                  endIcon={null}
                  className={className}>
                  {name}
                </RoundedPlainTextButton>
              </Link>
            )}
            {isNotLast && <ChevronRight className={classes.orgLinkSeparator} />}
          </Fragment>
        )
      })}
      {platformUnitOptions.length > 0 && (
        <Fragment>
          <ChevronRight className={classes.orgLinkSeparator} />
          <RoundedPlainTextButtonMenu
            label={`Select ${childPlatformUnitLabel || 'Activity'}`}
            value={null}
            TriggerProps={{
              ...triggerProps,
              color: 'default',
            }}
            isEqual={areOrgsEqual}
            options={platformUnitOptions}
            onChange={(e, platformUnit): void => {
              const typedPlatformUnit = platformUnit as PlatformUnit | null
              if (typedPlatformUnit) {
                navigate(
                  `/${topLevelRoute}/${typedPlatformUnit.id}${routeSuffix}`
                )
              }
            }}
          />
        </Fragment>
      )}
    </Fragment>
  )
}
