import React from 'react'
import { LinkCard } from './LinkCard'

import caseStudiesImg from '../../images/misc/case-studies.png'


const caseStudiesExternalUrl = (
  'https://author-vodafone-prd1.adobecqms.net/assets.html/content/dam/vodafonedam/vodafone-group/shareable-assets'
  + '/vodafone/sponsorship---partnerships/case-studies'
)


export const CaseStudy = (): JSX.Element => {
  return (
    <LinkCard
      title='Explore Partnerships Success Stories Across Markets!'
      summaryElements={[
        'Discover case studies that will not only inform but inspire your work strategy across various markets'
      ]}
      imageUrl={caseStudiesImg}
      externalUrl={caseStudiesExternalUrl}
      externalUrlLinkText='Link to case studies' />
  )
}
