import React, { Fragment } from 'react'

import { formatMoney, MoneyProps } from './formatMoney'


export const Money = (props: MoneyProps): JSX.Element => {
  return (
    <Fragment>
      { formatMoney(props) }
    </Fragment>
  )
}
