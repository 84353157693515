
import { darken, lighten } from 'polished'


const { BRANDING = 'PERCEPT' } = process.env


export type PaletteColour = (
  'black' | 'midBlack' | 'lightBlack' | 'darkGrey' | 'midGrey' | 'grey' | 'lightGrey' |
  'white' | 'teal' | 'cyan' | 'blue' | 'darkBlue' | 'red' | 'orange' | 'yellow' | 'amber' |
  'green' | 'purple'
)

export type PaletteType = {
  [P in PaletteColour]: string
}


const nord: PaletteType = {
  black: '#2E3440',
  midBlack: '#3B4252',
  lightBlack: '#434C5E',
  darkGrey: '#4C566A',
  midGrey: '#99A3B6',
  grey: '#D8DEE9',
  lightGrey: '#E5E9F0',
  white: '#ECEFF4',
  teal: '#8FBCBB',
  cyan: '#88C0D0',
  blue: '#81A1C1',
  darkBlue: '#5E81AC',
  red: '#BF616A',
  orange: '#D08770',
  yellow: '#EBCB8B',
  amber: '#EBCB8B',
  green: '#A3BE8C',
  purple: '#B48EAD'
}


const nordDark: PaletteType = {
  ...nord,
  blue: '#5C748C',
  teal: '#76A3A2',
  cyan: '#6FA7B7',
  purple: '#9B7594',
}

/* Ebiquity Colour Palette */

const ebiquityLightBlue = '#00addb'
const ebiquityDarkBlue = '#001c54'


const ebiquity: PaletteType = {
  black: darken(10, ebiquityDarkBlue),
  midBlack: ebiquityDarkBlue,
  lightBlack: lighten(10, ebiquityDarkBlue),
  darkGrey: '#273f6d',
  midGrey: '#99A3B6',
  grey: '#D8DEE9',
  lightGrey: '#E5E9F0',
  white: '#FFF',
  teal: '#176694',
  cyan: '#6FA7B7',
  blue: ebiquityLightBlue,
  darkBlue: ebiquityDarkBlue,
  // red: '#c33f46', // '#ce464d', //'#ed212b',
  // orange: '#D08770',
  // yellow: '#efa05d', // '#d88946', // '#eb8024',
  // amber: '#d88946', // '#eb8024',
  // green: '#60a773', // '#24b24a',

  purple: '#bd3f90',
  orange: '#D08770',

  /* Percept RAG */
  // red: '#BF616A',
  // yellow: '#EBCB8B',
  // amber: '#EBCB8B',
  // green: '#A3BE8C',

  /* Ebq site-derived */
  // red: '#ed212b',
  // yellow: '#eb8024',
  // amber: '#eb8024',
  // green: '#24b24a',

  red: '#ce464d',
  yellow: '#d88946',
  amber: '#d88946',
  green: '#60a773',
}

export default BRANDING === 'PERCEPT' ? nordDark : ebiquity