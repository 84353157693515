import {
  FormControl,
  MenuItem,
  Select as SelectComponent,
  SelectProps,
} from '@material-ui/core'
import React from 'react'

interface Props {
  value: string
  renderValue: (value: unknown) => string
  changeHandle: SelectProps['onChange']
  optionList: { value: string; label: string }[]
}

export const Select = ({
  value,
  renderValue,
  changeHandle,
  optionList,
}: Props): JSX.Element => {
  return (
    <FormControl size='small' variant='outlined' style={{ minWidth: '200px' }}>
      <SelectComponent
        value={value}
        renderValue={renderValue}
        onChange={changeHandle}>
        {optionList.map((option, idx) => (
          <MenuItem key={idx} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </SelectComponent>
    </FormControl>
  )
}
