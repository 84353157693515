import { makeAuthorizedApiClient } from '@percept/hooks'

import { PartnershipsArticleCreationParams, PartnershipsArticleMutationParams, PartnershipsEvent } from './types'


const { VODAFONE_CUSTOM_API_ROOT } = process.env


export const partnershipsClient = makeAuthorizedApiClient({
  baseURL: `${ VODAFONE_CUSTOM_API_ROOT }/partnerships/v1`,
})

export const partnershipsAdminClient = makeAuthorizedApiClient({
  baseURL: `${ VODAFONE_CUSTOM_API_ROOT }/partnerships/v1/admin`,
})


export const postScorecard = async (file: File, type: string) => {
  const formData = new FormData()
  formData.append('upload_file', file)
  return await partnershipsAdminClient.post(`/${type}`, formData)
    .then(res => res.data)
}

export const getArticleFormData = (article: PartnershipsArticleCreationParams): FormData => {
  const formData = new FormData()
  formData.append('url', article.external_url)
  if( article.image_url ){
    formData.append('image_url', article.image_url)
  }
  if( article.image_upload ){
    formData.append('image_upload', article.image_upload)
  }
  formData.append('local_timezone_info', article.local_timezone_info)
  if( article.partner_category ){
    formData.append('partner_category', article.partner_category)
  }
  formData.append('publish', String(article.publish))
  formData.append('published_date', article.publish_date)
  formData.append('summary', article.summary)
  formData.append('title', article.title)
  return formData
}

export const createArticle = async (article: PartnershipsArticleCreationParams) => {
  const formData = getArticleFormData(article)
  const res = await partnershipsAdminClient.post('/articles', formData)
  return res.data
}

export const updateArticle = async (article: PartnershipsArticleMutationParams) => {
  const formData = getArticleFormData(article)
  const res = await partnershipsAdminClient.put(`/articles/${article.id}`, formData)
  return res.data
}

export const deleteArticle = async (id: string) => {
  const res = await partnershipsAdminClient.delete(`/articles/${id}`)
  return res.data
}

export const getOgData = async (article_url: string) => {
  const res = await partnershipsAdminClient.get('/opengraph_article', { params: { article_url} })
  return res.data
}

export const createEvent = async (body: any) => {
  delete body.id
  const res = await partnershipsAdminClient.post('/events', body)
  return res.data
}

export const updateEvent = async (body: PartnershipsEvent) => {
  const res = await partnershipsAdminClient.put(`/events/${body.id}`, body)
  return res.data
}

export const deleteEvent = async (id: string) => {
  const res = await partnershipsAdminClient.delete(`/events/${id}`)
  return res.data
}
