import React, { Fragment, useState } from 'react'

import {
  Card,
  CardContent,
  Typography,
  makeAppStyles,
  List,
  ChannelThemeProvider,
} from '@percept/mui'

import { StructuralInsight } from './StructuralInsight'

import { InsightDialog } from './InsightDialog'

import { sortBy } from 'lodash-es'

import { providerChannelMap } from '@percept/constants'

import { Insight, ReportProvider } from '@percept/types'


export type KeyTasksProps = {
  provider: ReportProvider
  insights?: Insight[]
}


const useStyles = makeAppStyles( theme => ({
  insight: {
    margin: theme.spacing(3, 0),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  insightsHeader: {
    marginBottom: theme.spacing(4),
  },
}))



export const KeyTasks = (props: KeyTasksProps): JSX.Element => {
  return (
    <ChannelThemeProvider
      channel={providerChannelMap[props.provider]}>
      <KeyTasksContent {...props} />
    </ChannelThemeProvider>
  )
}


function KeyTasksContent({ provider, insights }: KeyTasksProps): JSX.Element {

  const classes = useStyles()

  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const [activeInsight, setActiveInsight] = useState<Insight | null>(null)
    
  const sortedInsights = sortBy(insights, 'severity').reverse()

  return (
    <Fragment>
      <Card>
        <CardContent>

          <Typography
            variant='h5'
            className={classes.insightsHeader}>
            Key Tasks
          </Typography>

          { insights && (
            <List disablePadding>
              { sortedInsights.map( (insight, i) => {
                return (
                  <StructuralInsight
                    key={i}
                    className={classes.insight}
                    {...insight}
                    collapsed={i !== activeIndex}
                    onToggleCollapse={(): void => {
                      setActiveIndex( prev => prev === i ? null : i)
                    }}
                    toggleDetail={(e): void => {
                      e.preventDefault()
                      e.stopPropagation()
                      setActiveInsight(insight)
                    }} />
                )
              })}
            </List>
          )}

        </CardContent>
      </Card>


      { activeInsight && (
        <InsightDialog
          fullScreen
          onClose={(): void => {
            setActiveInsight(null)
          }}
          insight={activeInsight}
          provider={provider} />
      )}

    </Fragment>
  )
}
