import React from 'react'

export const VodafoneLogoShort = (props: React.SVGAttributes<SVGElement>): JSX.Element => {
  return (
    <svg viewBox='0 0 512 512' width='512' height='512' stroke='none' fillRule='evenodd' {...props}>
      {/* <circle cx='254.5' cy='254.5' r='255' /> */}
      <path d='M-.5 255.5c0-141.824 114.176-256 256-256s256 114.176 256 256-114.176 256-256 256-256-114.176-256-256zm395.1547 6.0035c-.2172-64.6043-35.3746-111.4242-112.8572-130.2784-.2048-1.3768-.3159-2.7522-.3072-4.1416-.1569-48.889 36.6854-91.9763 82.9919-101.5808-4.3805-1.4778-11.5043-2.0125-18.3069-2.0125-52.9534.1831-111.2646 22.8259-153.9516 58.1066-43.693 36.1241-77.869 97.1675-77.6772 160.5401.3202 95.8144 73.0554 155.4562 142.9504 155.2384 86.3106-.3072 137.3764-71.2574 137.1578-135.8718z' />
    </svg>
  )
}

