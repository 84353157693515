import React, { FC, useEffect, useState } from 'react'
import { Box, CircularProgress, Collapse, Divider, Grid, Loader, ProviderLogo, RoundedPlainTextButton, makeAppStyles } from '@percept/mui'
import { Add, ArrowDropDown } from '@percept/mui/icons'
import { CategorySelect, isAdvertisingPartner } from './CategorySelect'
import { TabSectionHeader } from './PartnershipTabs'
import { LinkCard } from './LinkCard'
import { useInfiniteQuery, useQueryClient } from 'react-query'
import { format } from 'date-fns'
import { partnershipsClient } from './partnershipsClient'
import { capitalize, uniq } from 'lodash-es'
import { InfinitePaginationWrapper, PartnerCategory, PartnershipsEvent } from './types'


const PartnershipsEventSummary = ({
  start_datetime,
  end_datetime,
  partner_category,
  title,
  summary,
  external_url,
  additional_info,
}: PartnershipsEvent): JSX.Element => {
  // Dedupe dates based on date format
  // TODO - show times and probably make more of this metadata as it's crucial for events
  const formattedDates = uniq(
    [start_datetime, end_datetime].map( d => (
      format(new Date(d), 'd MMMM yyyy')
    ))
  )

  return (
    <LinkCard
      title={title}
      summaryElements={[summary, additional_info]}
      metadataContent={
        <>
          { formattedDates.join(' - ')}
          { partner_category && (
            <>
              <span style={{margin: '0 6px'}}>|</span>
              {
                isAdvertisingPartner(partner_category) ?
                  <ProviderLogo provider={partner_category} size={1.25} /> :
                  <strong>{capitalize(partner_category)}</strong>
              }
            </>
          )}
          </>
      }
      externalUrl={external_url}
      externalUrlLinkText='Link to the event' />
  )
}


export const getEventsPaginated = async (
  page: number, category: PartnerCategory | null, upcoming?: boolean, previous?: boolean
): Promise<InfinitePaginationWrapper<PartnershipsEvent>> => {
  const orderKey = upcoming ? 'order_by_asc' : 'order_by_desc'
  const res = await partnershipsClient.get(`/events`, {
    params: {
      limit: 5,
      page_number: page,
      upcoming,
      previous,
      partner_category: category,
      [orderKey]: 'start_datetime'
    },
  })
  const hasNext = page * res.data.size < res.data.total
  return {
    nextPage: hasNext ? page + 1 : undefined,
    previousPage: page > 1 ? page - 1 : undefined,
    items: res.data.items,
  }
}

export const Events = (): JSX.Element => {
  return (
    <>
      <EventsPreview key="upcoming" upcoming={true} title="Upcoming Events" />
      <Box my={5}>
        <Divider />
      </Box>
      <EventsPreview key="past" upcoming={false} title="Past Events" />
    </>
  )
}

export type EventsPreviewProps = {
  upcoming: boolean;
  title: string;
}

const usePreviewStyles = makeAppStyles( theme => ({
  menu: {
    marginRight: theme.spacing(2),
  }
}))


const EventsPreview: FC<EventsPreviewProps> = ({ upcoming, title }) => {
  const [category, setCategory] = useState<PartnerCategory | null>(null)
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)

  const queryClient = useQueryClient()
  const {isLoading, isError, data, isFetchingNextPage, hasNextPage, fetchNextPage, refetch} = useInfiniteQuery({
    queryKey: ['events', upcoming ? 'upcoming' : 'past', category, 'infinite'],
    getNextPageParam: (prevData: InfinitePaginationWrapper<PartnershipsEvent>) => prevData.nextPage,
    queryFn: ({ pageParam = 1 }) => getEventsPaginated(pageParam, category, upcoming, !upcoming)
  })

  useEffect(() => {
    refetch()
    queryClient.invalidateQueries(['events', upcoming ? 'upcoming' : 'past', category, 'infinite'])
  }, [category, refetch, upcoming, queryClient])

  const classes = usePreviewStyles()

  return (
    <>
      <TabSectionHeader title={title} isCollapsed={isCollapsed} handleCollapse={setIsCollapsed}>
        <CategorySelect
          TriggerProps={{
            variant: 'contained',
            color: 'primary',
            className: classes.menu,
            endIcon: <ArrowDropDown />,
          }}
          value={category || undefined}
          emptySelectLabel='View All'
          onChange={(_e, value): void => {
            setCategory(value || null)
          }} />
      </TabSectionHeader> 
      <Collapse in={!isCollapsed}>
        <Box my={3}>
          {isLoading && <Loader preset='centered' minHeight='16rem' />}
          {isError && (<Box width="100%" textAlign="center">Error occurred while fetching data.</Box>)}
          {data && (
          <>
            <Grid container spacing={3}>
              {(data.pages.flatMap( data  => data.items ) ?? [])
                .filter( event => event.partner_category === category || category === null)
                .map( event => (
                  <Grid item xs={12} md={6} lg={4} xl={3} key={event.title}>
                    <PartnershipsEventSummary
                      id={event.id}
                      start_datetime={event.start_datetime as string}
                      end_datetime={event.end_datetime as string}
                      title={event.title}
                      summary={event.summary}
                      additional_info={event.additional_info}
                      external_url={event.external_url}
                      partner_category={event.partner_category}
                      local_timezone_info={event.local_timezone_info}
                    />
                  </Grid>
                ))}
            </Grid>
            {hasNextPage && (
              <Box marginTop={6} textAlign="center">
                <RoundedPlainTextButton
                  color='primary'
                  variant='contained'
                  size='large'
                  disabled={isFetchingNextPage}
                  startIcon={
                    isFetchingNextPage ?
                      <CircularProgress size='1em' color='inherit' /> :
                      <Add />
                  }
                  onClick={(): void => {
                    fetchNextPage()
                  }}>
                  Load More
                </RoundedPlainTextButton>
              </Box>
            )}
          </>
          )}
        </Box>
      </Collapse>
    </>
  )
}
