
import { Dictionary } from '@percept/types'


type ErrorName = (
  'UserNotFoundException' |
  'NewPasswordMissing' |
  'InvalidPasswordException' |
  'NotAuthorizedException' |
  'CodeMismatchException' |
  'ExpiredCodeException' |
  'UsernameExistsException'
)

interface ErrorMapping extends Dictionary<string>{
  __default: string
}


const mappings = {
  UserNotFoundException: 'We couldn\'t find a user by that name. Please double-check your username below.',
  NewPasswordMissing: 'Please enter a new password.',
  InvalidPasswordException: 'The password provided does not meet our security requirements, please check below',
  NotAuthorizedException: {
    'username': 'Incorrect username or password provided. Please double-check and try again',
    'password': 'Incorrect username or password provided. Please double-check and try again',
    'session': 'Your session has expired. Please try signing in again to refresh',
    'expired': 'Your account invitation has expired. Please contact the Percept team at support@perceptaudit.com to receive a new invitation',
    '__default': 'An error occurred during the authorization request. Please refresh the page and try again, and don\'t hesitate to contact the Percept team if the problem persists.'
  },
  CodeMismatchException: 'Invalid verification code provided. Please double-check and try again.',
  ExpiredCodeException: 'The verification code has expired.',
  UsernameExistsException: 'An existing user has registered with this email address.'
} as Record<ErrorName, string | ErrorMapping>


const missingFields = (fieldnames: string[]): string => {
  const { length } = fieldnames
  const displayStr = (
    length === 1 ? fieldnames[0]
      : length === 2 ? fieldnames.join(' and ')
        : `${fieldnames.slice(0, length - 2).join(', ')} and ${fieldnames.slice(length - 1)}`
  ) 
  return `Please enter your ${displayStr}.`
}


export default (response: ErrorName | Dictionary | null, defaultMessage = 'An error occurred.'): string => {

  if( !response ){
    return defaultMessage
  }

  if( typeof response === 'string' ){
    const value = mappings[response]
    return (
      typeof value === 'string' ?
        value :
        value && value.__default || defaultMessage
    )
  }

  if( response.MissingFields ){
    return missingFields(response.MissingFields)
  }

  const mapping = mappings[response && response.code]
  
  if( typeof mapping === 'string' ){
    return mapping
  }
  
  if( !mapping || !response.message ){
    return defaultMessage
  }
  
  const matcher = response.message.toLowerCase()

  const foundMessage = Object.keys(mapping).reduce((found, key) => (
    matcher.indexOf(key) > -1 ? mapping[key] : found
  ), '')

  return foundMessage || mapping['__default'] || response.message || defaultMessage

}
