
import { apiInitialState, apiInitialStateWithProcessing, makeNestedSelector } from '@percept/redux'

import { getTimeSeriesKey } from '@percept/redux/utils'

import {
  getPath,
  uniqBy,
  // mapValues,
} from '@percept/utils'

import {
  createSelector,
  // createSelectorCreator,
  // defaultMemoize
} from 'reselect'

import {
  Selector,
  StoreState,
  SeriesWrapper,
  ApiResponse,
  Series,
  SeriesAudit,
  Report,
  ApiStatusResponse,
  AdwordsAccountHierarchy,
  EntityType,
  DimensionType,
  StackedChartType,
  Dictionary,
  SeriesMetric,
  AuditProvider,
  HydratedSeries,
} from '@percept/types'

import { getSeriesGroups } from '../seriesGroups/selectors'


// import { isEqual } from 'lodash-es'


// const createShallowEqualSelector = createSelectorCreator(
//   defaultMemoize,
//   isEqual
// )


type SeriesSelectors = {
  getSeriesWrappers: Selector<Dictionary<SeriesWrapper>>
  getSeries: Selector<ApiResponse<Series>>
  getSeriesAudits: Selector<ApiResponse<SeriesAudit[]>>

  // TODO: This is horrible! Need to fulfill admin app separately
  // due to issue with endpoint and pagination...
  getOriginalSeriesAudits: Selector<ApiResponse<SeriesAudit[]>>

  getCreateReport: Selector<ApiStatusResponse<Report>>
  getSeriesMutation: Selector<ApiStatusResponse<Series>>
  getSeriesRefreshToken: Selector<ApiStatusResponse<string>>
  getSeriesAccounts: Selector<ApiResponse<AdwordsAccountHierarchy[]>>
  getSeriesOAuthUrl: Selector<ApiResponse<string>>
  getSeriesMetrics: Selector<Dictionary<ApiResponse<SeriesMetric[]>>>
  getSeriesMetric: Selector<ApiResponse<SeriesMetric[]>>
  getActiveSeriesMetric: Selector<Dictionary | null>
  getActiveSeriesProvider: Selector<AuditProvider | null>
  getActiveAuditOverviews: Selector<SeriesAudit[]>
  getFilteredAuditOverviews: Selector<SeriesAudit[]>
  getSeriesProviderOptions: Selector<AuditProvider[]>
  // Export selector generators
  makeGetActiveAuditOverviews: () => Selector<SeriesAudit[]>
  makeGetSeriesProviderOptions: () => Selector<AuditProvider[]>
  makeGetFilteredAuditOverviews: () => Selector<SeriesAudit[]>
  makeGetActiveSeriesProvider: () => Selector<AuditProvider>
  getSeriesChartType: Selector<StackedChartType>
  getSeriesPerformanceTail: Selector<string | null>
  getSeriesHealthDimension: Selector<DimensionType>
}




type SeriesSelectorArgs = [string | null | undefined]


const uniqByProvider = uniqBy('provider')


export const makeSelectors = ({ raw = false } = {}): SeriesSelectors => {

  const statePath = raw ? ['series', 'raw'] : ['series']

  const getSeriesWrappers = (state: StoreState): Dictionary<SeriesWrapper> => (
    getPath(state, [...statePath, 'byId']) || {}
  )

  const getSeriesWrapperById = (state: StoreState, series_id: string | null): SeriesWrapper => getPath(
    getSeriesWrappers(state),
    series_id,
    {
      series: apiInitialState,
      audits: apiInitialState,
      accounts: apiInitialState,
      oAuthUrl: apiInitialStateWithProcessing,
      mutation: apiInitialStateWithProcessing,
      refreshToken: apiInitialStateWithProcessing,
      createReport: apiInitialStateWithProcessing,
    }
  )

  const getSeries = makeNestedSelector<ApiResponse<Series>, SeriesSelectorArgs>(
    getSeriesWrapperById, 'series'
  )

  const getOriginalSeriesAudits = makeNestedSelector<ApiResponse<SeriesAudit[]>, SeriesSelectorArgs>(
    getSeriesWrapperById, 'audits'
  )

  // TODO: Temporary patch for using series groups endpoint to populate series audits listing
  const getSeriesAudits = createSelector(
    getSeriesGroups,
    (state: StoreState, series_id: string) => (series_id),
    (seriesGroups, series_id): ApiResponse<SeriesAudit[]> => {

      if( !seriesGroups.data ){
        return {
          ...seriesGroups,
          data: null,
        }
      }

      let matchedSeries: HydratedSeries | null = null
      for( const group of seriesGroups.data ){
        for( const series of (group.series || []) ){
          if( series.series_id === series_id ){
            matchedSeries = series
            break
          }
        }
      }

      return {
        ...seriesGroups,
        data: matchedSeries ? matchedSeries.reports : [],
      }
    }
  )

  const getSeriesMutation = makeNestedSelector<ApiStatusResponse, SeriesSelectorArgs>(
    getSeriesWrapperById, 'mutation'
  )

  const getSeriesOAuthUrl = makeNestedSelector<ApiResponse<string>, SeriesSelectorArgs>(
    getSeriesWrapperById, 'oAuthUrl'
  )

  const getSeriesRefreshToken = makeNestedSelector<ApiStatusResponse, SeriesSelectorArgs>(
    getSeriesWrapperById, 'refreshToken'
  )

  const getSeriesAccounts = makeNestedSelector<ApiResponse<AdwordsAccountHierarchy[]>, SeriesSelectorArgs>(
    getSeriesWrapperById, 'accounts'
  )

  const getCreateReport = makeNestedSelector<ApiStatusResponse<Report>, SeriesSelectorArgs>(
    getSeriesWrapperById, 'createReport'
  )

  type SeriesMetricProps = {
    series_id: string | null,
    entity_type: EntityType | null,
    entity_id: string | number | null,
    metric_id: string | null,
    dimension: DimensionType | null,
    cursor?: string,
    limit?: number
  }
  
  const getSeriesMetric = (state: StoreState, {
    series_id,
    entity_id,
    metric_id,
    entity_type,
    dimension,
    cursor = '',
    limit = 0,
  }: SeriesMetricProps): ApiResponse<SeriesMetric[]> => (
    getPath(
      state,
      [
        ...statePath,
        'timeSeries',
        'byId',
        getTimeSeriesKey({ series_id, entity_type, entity_id, metric_id, dimension, cursor, limit }),
      ],
      apiInitialState
    )
  )

  const getSeriesMetrics = (state: StoreState): Dictionary<ApiResponse<SeriesMetric[]>> => (
    getPath(
      state,
      [
        ...statePath,
        'timeSeries',
        'byId'
      ],
      {}
    )
  )

  // const getSeriesMetricsById = createShallowEqualSelector(
  //   (state, params) => {
  //     const timeSeriesById = getPath(state, [...statePath, 'timeSeries', 'byId']) || {}
  //     return Object.keys(timeSeriesById).reduce()
  //   }
  // )
  
  const getActiveSeriesMetric = (state: StoreState) => state.series.timeSeries.active
  
  const getProviderBySeriesId = (state: StoreState, series_id: string) => (
    state.series.activeProvider[series_id]
  )

  const makeGetSeriesProviderOptions = () =>
    createSelector(
      getSeriesAudits,
      audits =>
        uniqByProvider(
          (audits.data || []).filter( o => o.status !== 'SCHEDULED' )
        ).map( o => o.provider )
    )

  const getSeriesProviderOptions = makeGetSeriesProviderOptions()


  const makeGetActiveSeriesProvider = () =>
    createSelector(
      [getProviderBySeriesId, getSeriesProviderOptions],
      (provider, options) =>
        provider ? provider : options.length === 1 ? options[0] : 'adwords'
    )

  const getActiveSeriesProvider = makeGetActiveSeriesProvider()


  const makeGetFilteredAuditOverviews = () =>
    createSelector(
      getSeriesAudits,
      seriesAudits => (seriesAudits.data || []).filter( o => o.status !== 'SCHEDULED' )
    )
        


  const getFilteredAuditOverviews = makeGetFilteredAuditOverviews()


  const makeGetActiveAuditOverviews = () =>
    createSelector(
      getFilteredAuditOverviews,
      overviews => overviews.filter( o => o.status === 'COMPLETED' )
    )

  const getActiveAuditOverviews = makeGetActiveAuditOverviews()

  const getSeriesChartType = (state: StoreState): StackedChartType => (
    state.series.settings.chartType
  )

  const getSeriesPerformanceTail = (state: StoreState): string | null => (
    state.series.settings.performanceTail
  )

  const getSeriesHealthDimension = (state: StoreState): DimensionType => (
    state.series.settings.healthDimension || 'count'
  )

  return {
    getSeriesWrappers,
    getSeries,
    getSeriesAudits,

    // TODO: This is horrible! Need to fulfill admin app separately
    // due to issue with endpoint and pagination...
    getOriginalSeriesAudits,

    getCreateReport,
    getSeriesMutation,
    getSeriesRefreshToken,
    getSeriesAccounts,
    getSeriesOAuthUrl,
    getSeriesMetrics,
    getSeriesMetric,
    getActiveSeriesMetric,
    getActiveSeriesProvider,
    getActiveAuditOverviews,
    getFilteredAuditOverviews,
    getSeriesProviderOptions,
    // Export selector generators
    makeGetActiveAuditOverviews,
    makeGetSeriesProviderOptions,
    makeGetFilteredAuditOverviews,
    makeGetActiveSeriesProvider,
    getSeriesChartType,
    getSeriesPerformanceTail,
    getSeriesHealthDimension,
  }

}


export default makeSelectors()
