
import {
  SET_DISPLAY_TYPE,
  SET_METRIC_ORDER,
} from '../actions'


const initialState = {
  displayType: 'SUMMARY',
  metricOrder: 'default',
}


export default (state = initialState, action) => {
  switch(action.type){

    case SET_DISPLAY_TYPE:
      return { ...state, displayType: action.payload }

    case SET_METRIC_ORDER:
      return { ...state, metricOrder: action.payload }

    default:
      return state

  }
}