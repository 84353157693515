import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from '@material-ui/core'
import { ExpandMore, CheckCircle, Cancel } from '@material-ui/icons'
import { FieldsetAnchorLink, FieldsetApplicationLink, FieldsetAssetLink, SegmentFieldset as ISegmentFieldset, SegmentFieldsetLink } from '../../../api/report-messages'
import { Step } from './Step'
import { makeAppStyles } from '@percept/mui'
import { Link } from 'react-router-dom'
import { MarketSegment } from 'components/BrandGrowthPlanner/api/markets'
import { AssetLink } from 'components/AssetLink'

const useStyles = makeAppStyles((theme) => ({
  legend: {
    fontSize: 25,
    fontWeight: 700,
    lineHeight: '40px',
    color: '#ffffff',
  },
  fieldset: {
    display: 'grid',
    gap: '2rem',
  },
  steps: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  generalDetail: {
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 400,
  },
  linksLabel: {
    fontSize: 16,
    fontWeight: 700,
  },
  link: {
    fontSize: 16,
    fontWeight: 400,
    display: 'block',
    marginBottom: theme.spacing(1.5),
    '&:last-of-type': {
      marginBottom: 0
    },
    textDecoration: 'underline',
    textUnderlineOffset: '4px',
    transition: theme.transitions.create('color'),
    '&[href]:hover': {
      color: theme.palette.primary.main,
    },
  },
  accordion: {
    '& .MuiAccordionDetails-root': {
      padding: '24px 16px',
    },
    '& .MuiAccordionSummary-root': {
      backgroundColor: theme.palette.grey[100],
    },
    '& .MuiAccordionSummary-expandIcon': {
      color: theme.palette.grey[800],
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: '6px',
      padding: '8px',
      margin: '0px',
    },
    '& .legend': {
      display: 'inline-flex',
      alignItems: 'center',

      '& svg': {
        width: '32px',
        height: '32px',
        verticalAlign: 'middle',
        marginRight: '12px',
      },
    },
    '&.isOnTrack': {
      '& .legend': {
        color: theme.chart.healthColourScale(1),
      },
    },
    '&.isNotOnTrack': {
      '& .legend': {
        color: theme.chart.healthColourScale(0),
      },
    },
  },
}))


type PartialAnchorProps = Partial<React.AnchorHTMLAttributes<HTMLAnchorElement>>

const FieldsetApplicationLinkComponent = ({
  link_type,
  application,
  label,
  ...props
}: FieldsetApplicationLink & PartialAnchorProps): JSX.Element | null => {
  if( application === 'PATHWAYS' ){
    return (
      <a
        href={'/wizard/strategy/pathways'}
        {...props}>
        { label }
      </a>
    )
  }
  return null
}


const FieldsetAssetLinkComponent = ({
  link_type,
  asset,
  label,
  marketSegment,
  ...props
}: FieldsetAssetLink & PartialAnchorProps & {
  marketSegment: MarketSegment
}): JSX.Element | null => {
  const assetIdentifier = [
    'bgp',
    asset === 'GUIDANCE_TABLE' ?
      'cut-back' :
      'invest-for-impact'
    ,
    marketSegment.toLowerCase()
  ].join('-')
  return (
    <AssetLink
      assetIdentifier={assetIdentifier}
      linkContent={label}
      showIcon={false}
      {...props} />
  )
}


const FieldsetAnchorLinkComponent = ({
  link_type,
  label,
  link,
  ...props
}: FieldsetAnchorLink & PartialAnchorProps): JSX.Element | null => {
  return (
    <a
      href={link || undefined}
      {...props}>
      { label }
    </a>
  )
}


const FieldsetLinkComponent = ({
  marketSegment,
  ...props
}: SegmentFieldsetLink & PartialAnchorProps & { marketSegment: MarketSegment }): JSX.Element | null => {
  switch(props.link_type){
    case 'ASSET': return <FieldsetAssetLinkComponent {...props} marketSegment={marketSegment} />
    case 'INTERNAL_APPLICATION': return <FieldsetApplicationLinkComponent {...props} />
    case 'ANCHOR':  // Fallthrough to catch legacy reports
    case undefined: return <FieldsetAnchorLinkComponent {...props} />
    default: return null
  }
}


interface SegmentFieldsetProps {
  fieldset: ISegmentFieldset
  marketSegment: MarketSegment
}

export function SegmentFieldset({
  fieldset: { legend, steps, details, image, links },
  marketSegment,
}: SegmentFieldsetProps): JSX.Element {
  const classes = useStyles()

  const hasDetails = details && details.length > 0
  const hasLinks = links && links.length > 0

  const allStepsOnTrack = steps.every((step) => step.is_on_track)

  return (
    <Accordion
      defaultExpanded
      className={`${classes.accordion} ${
        allStepsOnTrack ? 'isOnTrack' : 'isNotOnTrack'
      }`}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant='h3' className={`${classes.legend} legend`}>
          {allStepsOnTrack ? <CheckCircle /> : <Cancel />} {legend}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={classes.fieldset}>
          {hasDetails && (
            <div>
              {details.map((detail, index) => (
                <Typography key={index} className={classes.generalDetail}>
                  {detail}
                </Typography>
              ))}
            </div>
          )}

          <Box className={classes.steps}>
            {steps.map((step, id) => (
              <Step key={id} step={step} />
            ))}
          </Box>

          {image && <img src={image} alt='' />}

          {hasLinks && (
            <Box>
              <Typography className={classes.linksLabel}>Links:</Typography>

              <Box mt={1}>
                {links.map((link, index) => (
                  <FieldsetLinkComponent
                    key={index}
                    {...link}
                    marketSegment={marketSegment}
                    className={classes.link}
                    target='_blank'
                    rel='noopener noreferrer' />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
