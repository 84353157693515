
import { useMemo } from 'react'

import {
  getPath,
  any,
  isNumber,
  avg,
  sum,
  weightedScore,
  collectMetricIdsFromLayout,
  intersection,
} from '@percept/utils'

import {
  Dictionary,
  LayoutType,
  LayoutNodeType,
  HealthType,
  MetricsPayload,
  MetricMetadataType,
  ReportMetricsPayload,
} from '@percept/types'

const { WORKLOAD_TYPE } = process.env


const DEBUG = WORKLOAD_TYPE === 'DEV'


export const useLayoutMetricIds = (layout: LayoutType | null, payload?: MetricsPayload | ReportMetricsPayload | null): string[] => (
  useMemo(() => (
    layout && payload ? collectMetricIdsFromLayout(layout, payload) : []
  ), [layout, payload])
)


const getHealthFromLayoutNode = (
  layoutNode: LayoutNodeType,
  metrics: MetricsPayload | ReportMetricsPayload | null,
  options?: SectionScoreOptions
): HealthType => {

  const {
    healthDimension = 'count',
    impactWeighted = false,
    metricMetadata = null,
    parentLayout = null,
  } = options || {}

  const metricIds = collectMetricIdsFromLayout(layoutNode, metrics)
  
  const health: number[] = metricIds.map(
    id => {
      const unmappedHealth = getPath<number | null>(
        metrics,
        [id, 'dimensions', healthDimension, 'health'],
        -1
      )
      if( unmappedHealth === -1 ){
        return getPath<number | null>(metrics, [id, healthDimension, 'health'], null)
      }
      return unmappedHealth
    }
  ).filter(isNumber)

  if( !health.length ){
    return null
  }

  if( impactWeighted && metricMetadata && parentLayout ){

    DEBUG && console.log('Section scores are weighted averages')

    const allMetricIds = collectMetricIdsFromLayout(parentLayout, metrics)

    const impactMap = allMetricIds.reduce((acc, id) => {
      const impact = getPath(metricMetadata, [id, 'display_options', 'priority', 'impact'], null)
      if( isNumber(impact) ){
        acc[id] = impact
      }
      return acc
    }, {} as Record<string, number>)

    const total = sum(Object.values(impactMap))
    const normalizer = total === 0 ? 0 : 1 / total

    const subset = intersection(metricIds, Object.keys(impactMap)).filter( id =>
      isNumber(getPath(metrics, [id, healthDimension, 'health']))
    )

    if( !subset.length ){
      return null
    }

    return weightedScore(
      subset.map( id => getPath(metrics, [id, healthDimension, 'health']) as number ),
      subset.map( id => impactMap[id] * normalizer ),
    )
  }

  DEBUG && console.log('Section scores are straight averages')

  return avg(health)
}


type SectionScore = Dictionary & {
  key: string,
  name: string,
  health: HealthType,
}

type SectionScoreOptions = {
  healthDimension?: string
  impactWeighted?: boolean
  metricMetadata?: MetricMetadataType | null
  parentLayout?: LayoutType | null
}

export const useSectionScores = (
  layout: LayoutType | null,
  metrics: MetricsPayload | ReportMetricsPayload | null,
  options?: SectionScoreOptions
): SectionScore[] => {

  const { healthDimension = 'count', metricMetadata = null } = options || {}

  return useMemo(() => {
    const members: LayoutNodeType[] = getPath(layout, 'members') || []

    if( !members.length || any(members, m => m.type !== 'tab' ) ){
      return [] as SectionScore[]
    }

    return members.map( member => ({
      key: member.key || member.title || member.name,
      ...member,
      name: member.title || member.name,
      health: getHealthFromLayoutNode(
        member,
        metrics,
        {
          healthDimension,
          metricMetadata,
          parentLayout: layout,
        })
    } as SectionScore) )

  }, [layout, metrics, healthDimension, metricMetadata])
}


export const useSectionScoresObject = (
  layout: LayoutType | null,
  metrics: MetricsPayload | ReportMetricsPayload | null,
  options?: SectionScoreOptions
): Dictionary<HealthType> => (
  useSectionScores(layout, metrics, options).reduce((acc, section) => {
    acc[section.key] = section.health
    return acc
  }, {} as Dictionary<HealthType>)
)
