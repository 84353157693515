import React from 'react'

import { animated } from 'react-spring'

import { Box, BoxProps, Button, Fade } from '@material-ui/core'

import { Toast } from './Toast'

import { useCollapsingItemsTransition } from './lib'

import { Cancel } from '../../icons'

import { Toast as ToastType } from '@percept/types'


export type ToastsProps = {
  toasts: ToastType[]
  onClose: (id: string | number) => void
  onCloseAll?: () => void
} & Omit<BoxProps, 'id'>


export const Toasts = ({ toasts, onClose, onCloseAll, ...props }: ToastsProps): JSX.Element => {

  const { transitions, makeRef } = useCollapsingItemsTransition(toasts.slice().reverse())

  return (
    <Box
      position='fixed'
      bottom='0.75em'
      display='flex'
      flexDirection='column'
      flexWrap='wrap'
      alignContent='center'
      width='100%'
      {...props}>

      { !!onCloseAll && (
        <Fade in={toasts.length > 1}>
          <Box
            position='absolute'
            left='calc(50vw + 15.5rem)'
            bottom='1rem'
            boxShadow={12}>
            <Button
              variant='contained'
              startIcon={
                <Cancel />
              }
              onClick={(): void => onCloseAll()}>
              Dismiss All
            </Button>
          </Box>
        </Fade>
      )}

      { transitions.map( ({ item, props, key }) => (
        <animated.div
          key={key}
          style={props}>
          <div
            style={{paddingBottom: '16px'}}
            ref={makeRef(item)}>
            <Toast
              {...item}
              onClose={onClose} />
          </div>
        </animated.div>
      ))}

    </Box>
  )
}
