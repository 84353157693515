import React, { Fragment } from 'react'

import { ClassNameMap, CopyToClipboard, Typography } from '@percept/mui'

import { LegendItem } from '@percept/mui/charts'

import { find } from 'lodash-es'

import { MUIDataTableColumnOptions } from 'mui-datatables'

import { MetricSegmentType } from '@percept/types'

import { DataFrame, ExampleColumn } from './lib'

import { DataTableClassName } from './styles'


export type ColumnOptionsMap = Partial<
  Record<
    ExampleColumn, MUIDataTableColumnOptions
  >
>


const hide: MUIDataTableColumnOptions = {
  display: false,
  filter: false,
}


export const getColumnOptionsMap = ({
  classes,
  activeSegments,
  fieldnames,
  rows,
}: {
  classes: ClassNameMap<DataTableClassName>
  activeSegments?: MetricSegmentType[]
} & DataFrame): ColumnOptionsMap => {

  const hasSegments = !!(activeSegments && activeSegments.length)

  return {
    account_id: hide,
    campaign_id: hide,
    adgroup_id: hide,
    adset_id: hide,
    ad_id: hide,
    insertionorder_id: hide,
    lineitem_id: hide,
    id: hide,
    currency: {
      ...hide,
      filter: false,
      searchable: false,
    },
    __SEGMENT__: {
      filter: false,
      display: hasSegments,
      searchable: hasSegments,
      // eslint-disable-next-line react/display-name
      customBodyRenderLite: (rowIndex): JSX.Element | string => {
        const value = rows[rowIndex][fieldnames.indexOf('__SEGMENT__')]
        const match = find(activeSegments, s => s.label === value )
        if( match ){
          return (
            <LegendItem
              datum={{
                label: String(value),
                fill: match.color,
              }} />
          )
        }
        return String(value)
      }, 
    },
    name: {
      setCellProps: (): React.HTMLAttributes<HTMLTableCellElement> => ({
        className: classes.fixedColumnCell,
      }),
      setCellHeaderProps: (): React.HTMLAttributes<HTMLTableCellElement> => ({
        className: classes.fixedColumnHeaderCell,
      }),
      // eslint-disable-next-line react/display-name
      customBodyRenderLite: (rowIndex): JSX.Element | string => {
        let value = rows[rowIndex][fieldnames.indexOf('name')]
        if( value === null || typeof value === 'undefined' ){
          value = ''
        }
        const content = (
          value && typeof value === 'string' && value.includes('\n') ? (
            <Fragment>
              { value.split('\n').map( (para, i) => (
                <Typography key={i} className={classes.fixedColumnCellTypography} variant='subtitle1' noWrap>
                  { para }
                </Typography>
              ))}
            </Fragment>
          ) : (
            <Typography className={`${classes.fixedColumnCellTypography} ${classes.inline}`} variant='subtitle1' noWrap>
              { value }
            </Typography>
          )
        )
        return (
          <CopyToClipboard
            value={value}
            content={content} />
        )
      }, 
    }
  }
}
