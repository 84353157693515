import React, { useContext, useEffect, useMemo, useState } from 'react'

import {
  Box,
  Card,
  CellRenderers,
  CircularProgress,
  Column,
  Divider,
  LinearProgress,
  Loader,
  MenuOption,
  RoundedPlainTextButton,
  RoundedPlainTextButtonMenu,
  SimpleTable,
  TablePagination,
  Typography,
  makeAppStyles,
} from '@percept/mui'

import { ArrowDropDown, Block, CheckCircle, Help, OpenInNew, PersonAdd } from '@percept/mui/icons'

import { UserManagementDialog } from './UserManagementDialog'

import { VodafoneLogoShort } from './VodafoneLogoShort'

import { TablePaginationActions } from './tableComponents'

import { getMarketLabel } from './attributeComponents'

import {
  useTenantUserManagementEstablishedUsers,
  useTenantUserManagementMetadata,
  useTenantUserManagementPendingSSOUsers,
  useTimeago,
} from '@percept/hooks'

import { UserPrivilegeContext } from '@percept/app-components'

import { isAdminUser, userHasOrgPrivileges } from '@percept/utils'

import { DerivedListingUser, ListingViewType, generateDerivedListingUser } from './lib'

import { VODAFONE_GLOBAL_ID } from 'vodafoneMarkets'

import { DateType } from '@percept/types'


const columns: Column<DerivedListingUser>[] = [
  {
    key: 'user_type',
    label: '',
    align: 'center',
    style: {
      width: 20,
    }
  },
  {
    key: 'name',
    label: 'Name',
    align: 'left',
  },
  {
    key: 'email',
    label: 'Email',
    align: 'left',
  },
  {
    key: 'user_status',
    label: 'User Status',
    align: 'left'
  },
  {
    key: 'market',
    label: 'Market',
    align: 'left',
  },
  {
    key: 'last_login',
    label: 'Last Login',
    align: 'left',
  },
  {
    key: 'activated_at',
    label: 'Activated',
    align: 'left',
  },
  {
    key: 'modified',
    label: 'Last Edited',
    align: 'left',
  }
]


const useStyles = makeAppStyles( theme => ({
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  spacedLeft: {
    marginLeft: theme.spacing(2),
  },
  card: {
    position: 'relative',
    paddingTop: theme.spacing(0.5),
  },
  enabledIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(0.75),
  },
  disabledIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(0.75),
  },
  neutralIcon: {
    color: 'inherit',
    marginRight: theme.spacing(0.75),
  },
  loadingIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 4,
    width: '100%',
  },
  tableCell: {
    fontSize: 13,
    fontWeight: 500,
    padding: theme.spacing(1, 2),
  },
  tableRowClickable: {
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '& .MuiTableCell-body': {
        color: theme.palette.primary.contrastText,
      },
      '& $tableVodafoneIcon': {
        fill: 'currentColor',
      },
      '& $enabledIcon': {
        color: 'inherit',
      },
      '& $disabledIcon': {
        color: 'inherit',
      },
    },
  },
  tableVodafoneIcon: {
    width: 18,
    height: 18,
    transition: theme.transitions.create('fill'),
    fill: theme.palette.primary.main,
  },
  tableExternalIcon: {
    fontSize: 18,
  },
  logo: {
    color: theme.palette.primary.main,
    margin: theme.spacing(0, 1),
  },
}))


export const UserManagement = (): JSX.Element => {

  const [timeago] = useTimeago()

  const [open, setOpen] = useState(false)

  const [page, setPage] = useState(1)

  const [editUserId, setEditUserId] = useState<string | null>(null)

  const [listingViewType, setListingViewType] = useState<ListingViewType>('ACTIVE_USERS')

  useEffect(() => {
    setPage(1)
  }, [listingViewType])

  const privileges = useContext(UserPrivilegeContext)

  const tenantId = privileges.user.default_org_unit_id || VODAFONE_GLOBAL_ID

  const canAddUsers = !!(
    isAdminUser(privileges.user) || userHasOrgPrivileges(
      ['tenant.admin.createUser'],
      tenantId,
      privileges.org_privileges
    )
  )

  const userManagementMetadata = useTenantUserManagementMetadata(tenantId)

  const activeUsers = useTenantUserManagementEstablishedUsers(
    tenantId, page, { enabled: listingViewType === 'ACTIVE_USERS' }
  )

  const pendingSSOUsers = useTenantUserManagementPendingSSOUsers(
    tenantId, page, { enabled: listingViewType === 'PENDING_SSO_USERS' }
  )

  const editUser = (
    editUserId && (
      listingViewType === 'ACTIVE_USERS' ?
        (activeUsers.data && activeUsers.data.items || []).find( u => u.user_id === editUserId ) :
        (pendingSSOUsers.data && pendingSSOUsers.data.items || []).find( u => u.user_id === editUserId )
    ) || null
  )

  const userListingHook = listingViewType === 'ACTIVE_USERS' ? activeUsers : pendingSSOUsers

  const classes = useStyles()

  const renderNA = <>N / A</>

  const listingUsers: DerivedListingUser[] = useMemo(() => {
    if( !userListingHook.data ){
      return []
    }
    return userListingHook.data.items.map(generateDerivedListingUser)
  }, [userListingHook.data])

  const renderers: CellRenderers<DerivedListingUser> = {
    /* eslint-disable react/display-name, react/prop-types */
    user_type: ({ user_type }) => (
      <span style={{position: 'relative', top: 2}}>
        {user_type === 'Vodafone User' ?
          <VodafoneLogoShort className={classes.tableVodafoneIcon} width={18} height={18} /> :
          <OpenInNew className={classes.tableExternalIcon} />
        }
      </span>
    ),
    name: ({ name }) => (
      !name ?
        renderNA :
        <>{name}</>
    ),
    enabled: ({ enabled }) => {
      const IconComponent = enabled ? CheckCircle : Block
      const className = enabled ? classes.enabledIcon : classes.disabledIcon
      const text = enabled ? 'Enabled' : 'Disabled'
      return (
        <span className={classes.flexCenter}>
          <IconComponent className={className} />
          { text }
        </span>
      )
    },
    market: ({ market }) => (
      !market ?
        renderNA :
        getMarketLabel(market)
    ),
    user_status: ({ enabled, user_status }) => {
      if( !enabled ){
        return (
          <span className={classes.flexCenter}>
            <Block className={classes.disabledIcon} />
            Disabled
          </span>
        )
      }
      if( !user_status ){
        return renderNA
      }
      const icon = (
        user_status === 'Active User' ?
          <CheckCircle className={classes.enabledIcon} /> :
          user_status === 'Unknown SSO User' ?
            <Help className={classes.neutralIcon} /> :
            null
      )
      return (
        <span className={classes.flexCenter}>
          {icon}
          {user_status}
        </span>
      )
    },
    ...(['last_login', 'activated_at', 'modified'] as (keyof DerivedListingUser)[]).reduce( (acc, key) => {
      acc[key] = (props): JSX.Element => <>{props[key] ? timeago(props[key] as DateType) : renderNA}</>
      return acc
    }, {} as CellRenderers<DerivedListingUser>),
    /* eslint-enable react/display-name, react/prop-types */
  }
  
  return (
    <Box p={4}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography className={classes.flexCenter} variant='h2'>
          User Management

          <RoundedPlainTextButtonMenu
            TriggerProps={{
              className: classes.spacedLeft,
              size: 'small',
              variant: 'contained',
              color: 'secondary',
              endIcon: <ArrowDropDown />,
            }}
            label={listingViewType === 'ACTIVE_USERS' ? 'Active Users' : 'Pending SSO Users'}
            value={listingViewType}
            options={[
              {
                value: 'ACTIVE_USERS',
                label: 'Active Users',
              },
              {
                value: 'PENDING_SSO_USERS',
                label: 'Pending SSO Users',
              }
            ] as MenuOption<ListingViewType>[]}
            onChange={(e, value): void => {
              setListingViewType(value)
            }} />
        </Typography>
        { canAddUsers && (
          <RoundedPlainTextButton
            color='primary'
            variant='contained'
            startIcon={
              userManagementMetadata.data ?
                <PersonAdd /> :
                <CircularProgress size='1em' color='inherit' />
            }
            disabled={!userManagementMetadata.data}
            onClick={(): void => {
              setEditUserId(null)
              setOpen(true)
            }}>
            Add User
          </RoundedPlainTextButton>
        )}
      </Box>

      <Box mt={4}>
        <Card elevation={2} className={classes.card}>
          { userListingHook.isRefetching && (
            <LinearProgress variant='indeterminate' className={classes.loadingIndicator} />
          )}
          { userListingHook.data ? (
            <>
              <SimpleTable
                classes={{
                  tableRowClickable: classes.tableRowClickable,
                  tableCell: classes.tableCell,
                }}
                columns={columns}
                renderers={renderers}
                rows={listingUsers}
                onRowClick={(_, user): void => {
                  setEditUserId(user.user_id)
                  setOpen(true)
                }} />
              <Divider />
              <TablePagination
                component={'div'}
                ActionsComponent={TablePaginationActions}
                page={page - 1}
                rowsPerPage={userListingHook.data.size}
                rowsPerPageOptions={[userListingHook.data.size]}
                labelDisplayedRows={(pageInfo): JSX.Element => (
                  <Typography variant='h5'>
                    {`${pageInfo.from} - ${pageInfo.to} of ${pageInfo.count}`}
                  </Typography>
                )}
                count={userListingHook.data.total}
                onChangePage={(e, page): void => {
                  setPage(page + 1)
                }} />
            </>
          ) : (
            <Loader preset='centered' minHeight='20rem' />
          )}
        </Card>
      </Box>

      { userManagementMetadata.data && (
        <UserManagementDialog
          tenantId={tenantId}
          userManagementMetadata={userManagementMetadata.data}
          open={open}
          editUser={editUser}
          editUserContext={listingViewType}
          setListingViewType={setListingViewType}
          onClose={(): void => {
            setOpen(false)
            setTimeout(() => {
              setEditUserId(null)
            }, 200)
          }} />
      )}
    </Box>
  )
}
