/* eslint-disable semi */ // My linter keeps adding semi colons at the end for some reason
import { Box, Typography, BoxProps, IconButton } from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import React, { FC, PropsWithChildren } from 'react';

export type TabType = {
  value: string;
  label: string;
}

export type TabPanelProps = {
  value: string;
  tab: TabType;
}

export const TabPanel: FC<PropsWithChildren<TabPanelProps>> = ({value, tab, children, ...props}) => {
  return <Box {...props}>
    {value === tab.value && (<Box> {children} </Box>) }
  </Box>
}

export type TabSectionHeaderProps = {
  title: React.ReactNode | JSX.Element
  collapsible?: boolean
  isCollapsed?: boolean
  handleCollapse?: (val: boolean) => void
} & Omit<BoxProps, 'title'>

export const TabSectionHeader: FC<PropsWithChildren<TabSectionHeaderProps>> = ({
  title,
  collapsible = true,
  isCollapsed,
  handleCollapse,
  children,
  ...props
}) => {
  const IconComponent = isCollapsed ? ArrowDownward : ArrowUpward
  return <Box mt={2} display="flex" justifyContent="space-between" alignItems="center" {...props}>
    <Typography variant="h3">{title}</Typography>
    <Box display="flex"  alignItems="center">
      { children }
      { collapsible && handleCollapse && (
        <IconButton color='inherit' onClick={(): void => handleCollapse(!isCollapsed)}>
          <IconComponent color='inherit' />
        </IconButton>
      )}
    </Box>
  </Box>
}
