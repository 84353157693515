
import React from 'react'

import {
  PercentageBlob,
  PercentageBlobProps,
  PercentageDonut,
  PercentageDonutProps,
} from '@percept/mui'

import {
  percentageDeltaFormatter
} from '@percept/mui/charts'


export type PercentageVisualisationProps<P extends object> = {
  value: number | null
} & P


export function PercentageVisualisation({
  value,
  ...props
}: PercentageVisualisationProps<Partial<PercentageDonutProps>>): JSX.Element {
  return (
    <PercentageDonut
      value={value}
      isHealthPercentage={true}
      width={60}
      height={60}
      fontSize={14}
      donutThickness={0.2}
      {...props} />
  )
}


export function PercentageBlobVisualisation({
  value,
  ...props
}: PercentageVisualisationProps<PercentageBlobProps>): JSX.Element {
  return (
    <PercentageBlob
      value={value}
      formatter={percentageDeltaFormatter}
      fontSize={14}
      {...props} />
  )
}
