
import { DatumType } from '@percept/types'

import { isNumber } from '@percept/utils'
import { AppTheme } from '../../themes'


export type GaugeProps<T extends DatumType> = {
  data: (T & { key?: string | number})[]
  width?: string
  height?: string
  orientation?: 'horizontal' | 'vertical'
  className?: string
  animate?: boolean
}


export type GaugeDatum = DatumType & {
  weight: number
}


export const mapGaugeData = <T extends DatumType>(data: T[]): GaugeDatum[] => {
  
  const max = Math.max(
    ...(data || []).map( d => isNumber(d.value) ? d.value : 0),
    0
  )
  
  return data.map( d => ({
    ...d,
    weight: !d.value || isNaN(d.value) ? 0 : d.value / max
  }) )

}


export type PercentageFormatter = (percentage: number) => React.ReactNode


export type PercentageGaugeTypeProps = {
  value: number | null
  isHealthPercentage?: boolean
  color?: string
  trackColor?: string
  invert?: boolean
  formatter?: PercentageFormatter
}


export type PercentageDatasetConfig = (
  Omit<
    PercentageGaugeTypeProps,
    'precision'
  > & {
    appTheme: AppTheme
  }
)


type PercentageDataset<T> = {
  data: T[]
  color: string
}


export const getPercentageColor = ({
  value, isHealthPercentage, appTheme
}: (
    Pick<
      PercentageDatasetConfig,
      'appTheme' | 'isHealthPercentage'
    > & {
      value: number
    }
  )
): string => {

  if( isHealthPercentage ){
    return appTheme.chart.healthColourScale(value / 100)
  }

  return appTheme.chart.getInformationalColourScale([0, 100])(value)
}


export const getPercentageDataset = <T extends DatumType = DatumType>(
  { value, color, trackColor, invert, isHealthPercentage, appTheme }: PercentageDatasetConfig
): PercentageDataset<T> => {

  const animatableValue = invert ? (100 - (value || 0)) : (value || 0)

  color = color || getPercentageColor({
    value: animatableValue,
    isHealthPercentage,
    appTheme
  })

  const data = [
    { label: 'enumerator', value: animatableValue, color },
    { label: 'delta', value: 100 - animatableValue, color: trackColor }
  ] as T[]

  return { data, color }
}
