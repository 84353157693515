import React, { useContext } from 'react'

import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom'

import { UserPrivilegeContext } from '../../contexts'

import { every, get } from 'lodash-es'

import { isAdminUser, userHasGlobalPrivileges, userHasOrgPrivileges } from '@percept/utils'

import { GlobalPrivilege, OrgPrivilege, WorkloadContext, WorkloadType } from '@percept/types'

import { Location } from 'history'


const { WORKLOAD_CONTEXT, WORKLOAD_TYPE } = process.env


export type UserIdRestrictions = Partial<
  Record<
    WorkloadContext, Partial<
      Record<WorkloadType, string[] | null>
    >
  >
>

export const getActiveUserIdRestrictions = (
  userIdRestrictions: UserIdRestrictions | null | undefined
): string[] | null => (
  get(userIdRestrictions, [WORKLOAD_CONTEXT, WORKLOAD_TYPE]) || null
)


export type RestrictedRouteProps = (
  RouteProps & {
    requiredGlobalPrivileges?: GlobalPrivilege[]
    orgUnitId?: string | null | ((location: Location) => string)
    requiredOrgPrivileges?: OrgPrivilege[]
    userIdRestrictions?: UserIdRestrictions | null
    allowAdmin?: boolean
    fallbackRedirect?: string | ((location: Location) => string)
    fallbackContent?: JSX.Element
  }
)


export const RestrictedRoute = ({
  fallbackContent,
  fallbackRedirect,
  requiredGlobalPrivileges,
  requiredOrgPrivileges,
  allowAdmin = false,
  orgUnitId = null,
  userIdRestrictions = null,
  ...props
}: RestrictedRouteProps): JSX.Element | null => {

  const { global_privileges, org_privileges, user } = useContext(UserPrivilegeContext)

  const location = useLocation()

  const requiredFlags: boolean[] = []

  if( requiredGlobalPrivileges ){
    requiredFlags.push(
      userHasGlobalPrivileges(requiredGlobalPrivileges, global_privileges)
    )
  }

  if( requiredOrgPrivileges ){
    const orgUnitIdValue = typeof orgUnitId === 'function' ? orgUnitId(location) : orgUnitId
    requiredFlags.push(
      userHasOrgPrivileges(requiredOrgPrivileges, orgUnitIdValue, org_privileges)
    )
  }

  const activeUserRestrictions = getActiveUserIdRestrictions(userIdRestrictions)

  if( activeUserRestrictions && activeUserRestrictions.length ){
    requiredFlags.push(
      activeUserRestrictions.includes(user.cognito_id)
    )
  }

  const hasRequiredPrivileges = (
    requiredFlags.length === 0 || every(requiredFlags)
  )

  if( !hasRequiredPrivileges ){
    const isAdmin = isAdminUser(user)
    if( !allowAdmin || !isAdmin ){
      if( fallbackRedirect ){
        const redirectPath = (
          typeof fallbackRedirect === 'function' ?
            fallbackRedirect(location) :
            fallbackRedirect
        )
        return <Redirect to={redirectPath} />
      }
      return fallbackContent || null
    }
  }

  return <Route {...props} />
}
