import { Box, Card, LinearProgress } from '@material-ui/core'
import { makeAppStyles } from '@percept/mui'
import { SortConfig } from '@percept/types'
import React, { useEffect, useState, useMemo } from 'react'
import { TableCells } from './TableCells'
import { TableLeftSide } from './TableLeftSide'
import { TableTotalColumn } from './TableTotalColumn'
import { SortControl } from './SortControl'
import { sortTable } from './sortTable'
import { ListItem, NestedItem } from '../types'
import { reorderColumns } from '../reorderColumns'
import {IncompleteData, IncompleteMarkets} from 'api/services/Api'
import { Summary } from '../DashboardLayout'

export type SortConfiguration = SortConfig<keyof ListItem | keyof NestedItem>

export type ReportTableProps = {
  data: ListItem[]
  incompleteData: IncompleteData[] | undefined
  isError: boolean
  incompleteMarkets: IncompleteMarkets[] | undefined
  isErrorMarkets: boolean
  dataFormat: string
  showFetchIndicator: boolean
  differenceVariant?: boolean
  exportType: string | null
  automaticSort?: boolean
  incompleteDataMatchFunction?: (datum: { org_unit_name: string }, row: ListItem) => boolean
}

const useStyles = makeAppStyles((theme) => ({
  card: {
    position: 'relative',
  },
  tableRowNames: {
    boxShadow: '3px 0px 12px rgba(0, 0, 0, 0.3)',
    zIndex: 100,
  },
  tableTotalColumn: {
    boxShadow: '-3px 0px 12px rgba(0, 0, 0, 0.3)',
  },
  tableHeader: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  tableData: {
    overflow: 'auto',
    flex: 1,
    flexWrap: 'nowrap',
    flexDirection: 'column',
    '&::-webkit-scrollbar': {
      height: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#C6CBD0',
      borderRadius: '20px',
    },
  },
  fetchIndicator: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
  },
  error:{
    fontSize: 25,
  }
}))


export type IncompleteDataMatchFunction = (datum: { org_unit_name: string }, row: ListItem) => boolean


const defaultIncompleteDataMatcher: IncompleteDataMatchFunction = (datum, row) => (
  datum.org_unit_name === row.row_group
)


const getIncompleteDataAndDimensions = (
  data: ListItem[],
  incompleteData: IncompleteData[] | undefined, 
  incompleteMarkets: IncompleteMarkets[] | undefined,
  exportType: string | null,
  incompleteDataMatchFunction: IncompleteDataMatchFunction = defaultIncompleteDataMatcher,
) => {
  if(exportType != 'product' && exportType != 'funding_source' && exportType != 'message'){
    const dataWithUpdatedCells = data.map((row) => {
      const newCosts = row.costs.map((cell) => {
        if( !incompleteData ){
          return cell
        }
        const hasIncompleteData = incompleteData.some( element => (
          element.period_display_name === cell.type_value
          && incompleteDataMatchFunction(element, row)
        ))
        return { ...cell, hasIncompleteData }
      })
      const totalHasIncompleteData = newCosts.some( c => c.hasIncompleteData )
      return { ...row, costs: newCosts, totalHasIncompleteData }
    })
    data = dataWithUpdatedCells
  }
  else {
    const dataWithUpdatedTotalCells = data.map((row) => {
      if( !incompleteMarkets ){
        return row
      }
      const totalHasIncompleteData = incompleteMarkets.some( element => (
        incompleteDataMatchFunction(element, row)
      ))
      return { ...row, totalHasIncompleteData }
    })
    data = dataWithUpdatedTotalCells
  }
  
  const dataWithUpdatedColumns = data.map((row) => {
    if( row.row_group !== Summary.AllTotal || row.totalHasIncompleteData ){
      return row
    }
    const newCosts = row.costs.map((cell) => {
      const hasIncompleteData = data.some( u => (
        u.costs.some( c => c.type_value === cell.type_value && c.hasIncompleteData )
      ))
      return { ...cell, hasIncompleteData }
    })
    const totalHasIncompleteData = newCosts.some( c => c.hasIncompleteData )
    return { ...row, costs: newCosts, totalHasIncompleteData }
  })

  const dimensions = reorderColumns(
    dataWithUpdatedColumns.reduce((arr: string[], el: ListItem) => {
      const newArr = el.costs
        .filter(
          (item: { type_value: string }) =>
            !arr.find((el) => el === item.type_value)
        )
        .map((el) => el.type_value)
      return arr.concat(newArr)
    }, [])
  )
  return { updatedData: dataWithUpdatedColumns, dimensions }
}

export const ReportTable = ({
  data,
  incompleteData,
  isError,
  incompleteMarkets,
  isErrorMarkets,
  dataFormat,
  showFetchIndicator,
  differenceVariant,
  exportType,
  automaticSort = true,
  incompleteDataMatchFunction,
}: ReportTableProps): JSX.Element => {
  const [isOpened, setIsOpened] = useState<string[]>([])
  const [sortBy, setSortBy] = useState<SortConfiguration>()
  const classes = useStyles()
  const {updatedData, dimensions} = useMemo(() => {
    return getIncompleteDataAndDimensions(
      data, incompleteData, incompleteMarkets, exportType, incompleteDataMatchFunction
    )
  }, [data, incompleteData, incompleteMarkets, exportType, incompleteDataMatchFunction])

  useEffect(() => {
    if( isOpened.length > 0 && automaticSort ){
      setSortBy({ key: 'data_type', order: 'ASC' })
    }
  }, [isOpened, automaticSort])

  const sortedData = sortBy
    ? sortTable(updatedData, sortBy.key, sortBy.order, dimensions)
    : updatedData

  const handleClick = (key: string): void => {
    isOpened.includes(key)
      ? setIsOpened(isOpened.filter((e) => e != key))
      : setIsOpened([...isOpened, key])
  }

  const handleSortChange = ({
    order,
    key,
  }: Omit<SortConfiguration, 'order'> & { order?: 'ASC' | 'DESC' }): void => {
    if (differenceVariant) {
      return
    }
    if (key != sortBy?.key) {
      return setSortBy({
        key,
        order: 'ASC',
      })
    }
    setSortBy(
      order
        ? {
          order,
          key,
        }
        : undefined
    )
  }

  const isGrouped = !!(sortedData[0] && sortedData[0].data)
  return (
    (isError === true && isErrorMarkets === true) ? 
      <Box textAlign='center' className={classes.error}>Error when trying to retrieve data</Box>  :
      <Card className={classes.card}>
        {showFetchIndicator && <LinearProgress className={classes.fetchIndicator} />}
        <Box display='flex' flexDirection='row'>
          <Box className={classes.tableRowNames}>
            <Box
              display='flex'
              justifyContent='space-around'
              className={classes.tableHeader}>
              <SortControl
                label={differenceVariant ? '' : 'Market'}
                orderBy={sortBy && sortBy.order}
                isActiveKey={!!sortBy && sortBy.key === 'row_group'}
                onSort={(order): void => {
                  handleSortChange({
                    order,
                    key: 'row_group',
                  })
                }}
                justifyContent='flex-start'
              />
              {isOpened.length > 0 && sortedData[0].data && (
                <SortControl
                  label='Data'
                  orderBy={sortBy && sortBy.order}
                  isActiveKey={!!sortBy && sortBy.key === 'data_type'}
                  onSort={(order): void => {
                    handleSortChange({
                      order,
                      key: 'data_type',
                    })
                  }}
                  justifyContent='flex-start'
                />
              )}
            </Box>
            <Box>
              {sortedData.map((row) => (
                <TableLeftSide
                  key={row.row_group}
                  row={row}
                  onClick={handleClick}
                  isOpen={isOpened}
                />
              ))}
            </Box>
          </Box>
          <Box
            display='flex'
            className={classes.tableData}
            style={{
              overflow: 'auto',
              flex: 1,
              flexWrap: 'nowrap',
              flexDirection: 'column',
            }}>
            <Box flex='1' display='flex'>
              {dimensions.map((dimension) => (
                <Box
                  className={classes.tableHeader}
                  style={{ padding: '0 15px', flex: 1, minWidth: '140px' }}
                  key={dimension}>
                  <SortControl
                    orderBy={sortBy && sortBy.order}
                    isActiveKey={!!sortBy && sortBy.key === dimension}
                    label={dimension}
                    justifyContent='flex-end'
                    onSort={(order): void => {
                      handleSortChange({
                        order,
                        key: dimension as SortConfiguration['key'],
                      })
                    }}
                  />
                </Box>
              ))}
            </Box>
            {sortedData.map((row) => (
              <TableCells
                key={row.row_group}
                incompleteData={incompleteData}
                row={row}
                dataFormat={dataFormat}
                isOpen={isOpened}
                dimensions={dimensions}
              />
            ))}
          </Box>
          {!differenceVariant && (
            <Box className={classes.tableTotalColumn}>
              <Box className={classes.tableHeader}>
                <SortControl
                  isActiveKey={!!sortBy && sortBy.key === 'total'}
                  label={
                    sortedData[0].data && sortedData[0].data[0].average
                      ? 'AVERAGE YOY'
                      : dataFormat === 'percentage' && isGrouped
                        ? 'AVG. PERCENT'
                        : 'TOTAL'
                  }
                  orderBy={sortBy && sortBy.order}
                  onSort={(order): void => {
                    handleSortChange({
                      order,
                      key: 'total',
                    })
                  }}
                  justifyContent='flex-end'
                  mx={2}
                />
              </Box>
              {sortedData.map((row) => (
                <TableTotalColumn
                  dataFormat={dataFormat}
                  key={row.row_group}
                  row={row}
                  opend={isOpened}
                  dimensions={dimensions}
                />
              ))}
            </Box>
          )}
        </Box>
      </Card>
  )
}
