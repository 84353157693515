import { useMemo } from 'react'

import { AppTheme } from '@percept/mui'

import { StructuralReport } from '@percept/types'

import { ChartData } from '@percept/mui/charts'

import { getReportResultHealth } from '@percept/utils'

import { TimelineDatum } from 'types'


export const useSeriesHealth = ({
  reports,
  appTheme,
}: {
  reports: StructuralReport[]
  appTheme: AppTheme
}): ChartData<TimelineDatum> => {
  return useMemo(() => {
    return reports.map( report => {
      const health = getReportResultHealth(report)
      return {
        label: new Date(report.end).getTime(),
        start: new Date(report.start).getTime(),
        end: new Date(report.end).getTime(),
        value: health,
        color: appTheme.chart.healthColourScale(health || 0) 
      }
    })
  }, [reports, appTheme])
}
