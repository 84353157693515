import { triggerDownload } from './utils'

export type CSVData = {
  columns: string[];
  rows: string[][];
}

export const downloadCSV = ({
  filename,
  data,
}: {
  filename: string;
  data: CSVData;
}): void => {
  const csvString = [
    data.columns.join(', '),
    ...data.rows.map( row => (
      row.join(', ')
    )),
  ].join('\n')

  triggerDownload({
    filename,
    contents: csvString,
    mimeType: 'text/csv',
  })
}


export const humanReadableFilesize = (bytes: number, precision = 1): string => {
  const thresh = 1000

  if (Math.abs(bytes) < thresh) {
    return bytes + 'B'
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let u = -1
  const r = 10**precision

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return bytes.toFixed(precision) + ' ' + units[u]
}
