import qs from 'query-string'

import { addNotification } from '../notifications/actions'

import { providerURLMap, reportProviderV2Map } from '@percept/constants'

import {
  AddReportWorkflowParams,
  AdminParams,
  AsyncReduxAction,
  CreateMetricMetadataParams,
  CreateReportLayoutParams,
  CreateReportSeriesTaskParams,
  CurrencyParams,
  EditMetricMetadataParams,
  EditReportLayoutParams,
  EditReportSeriesParams,
  EntityParams, 
  InsightsReportLoadParams, 
  InsightsReportTypeParams,
  MetricMetadataParams,
  PaginatedParams,
  PlatformUnitParams,
  ProviderParams,
  ReduxAction,
  ReportLayoutParams,
  ReportMetricParams,
  ReportProviderParams,
  ReportResultParams,
  ReportWorkflowProcessParams,
  RerunReportSeriesParams,
  RerunReportSeriesResponse,
  RerunReportWorkflowCompletionHandlerParams,
  RerunReportWorkflowParams,
  SeriesParams,
  SeriesReportMetricParams,
  StructuralReportParams,
  WorkflowExecutionParams,
} from '@percept/types'


const { CORE_API_ROOT } = process.env


const createAsyncStructuralReportingAction = (action: AsyncReduxAction): AsyncReduxAction => ({
  ...action,
  options: {
    baseURL: `${CORE_API_ROOT}/v1`,
    paramsSerializer: (params): string => {
      return (
        qs.stringify(params, {
          arrayFormat: 'none',
        })
      )
    },
    ...action.options || {},
  }
})


/* Metadata */

export const LOAD_METRIC_METADATA_BY_PROVIDER = 'LOAD_METRIC_METADATA_BY_PROVIDER'

export const loadMetricMetadataByProvider = (
  { provider }: ProviderParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_METRIC_METADATA_BY_PROVIDER,
  resource: `/metric-metadata/${providerURLMap[provider]}`,
  meta: {
    provider,
  }
})


export const LOAD_ALL_METRIC_METADATA = 'LOAD_ALL_METRIC_METADATA'

export const loadAllMetricMetadata = (params?: PaginatedParams): AsyncReduxAction => (
  createAsyncStructuralReportingAction({
    type: LOAD_ALL_METRIC_METADATA,
    resource: '/metric-metadata/',
    options: {
      params,
    },
    meta: params,
  })
)


export const ADD_METRIC_METADATA = 'ADD_METRIC_METADATA'

export const addMetricMetadata = ({
  provider,
  ...data
}: CreateMetricMetadataParams): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: ADD_METRIC_METADATA,
  resource: '/metric-metadata/',
  options: {
    method: 'POST',
    data: {
      provider: reportProviderV2Map[provider],
      ...data,
    },
  },
})

export const RESET_ADD_METRIC_METADATA = 'RESET_ADD_METRIC_METADATA'

export const resetAddMetricMetadata = (): ReduxAction => ({
  type: RESET_ADD_METRIC_METADATA,
})


export const EDIT_METRIC_METADATA = 'EDIT_METRIC_METADATA'

export const editMetricMetadata = (
  { metric_id, ...data }: MetricMetadataParams & Partial<EditMetricMetadataParams>
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: EDIT_METRIC_METADATA,
  resource: `/metric-metadata/${metric_id}`,
  options: {
    method: 'PATCH',
    data,
  },
  meta: {
    metric_id
  },
})

export const RESET_EDIT_METRIC_METADATA = 'RESET_EDIT_METRIC_METADATA'

export const resetEditMetricMetadata = (): ReduxAction => ({
  type: RESET_EDIT_METRIC_METADATA,
})


/* Layouts */

export const LOAD_REPORT_LAYOUTS = 'LOAD_REPORT_LAYOUTS'

export const loadReportLayouts = (params?: PaginatedParams): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_REPORT_LAYOUTS,
  resource: '/report-layouts/',
  options: {
    params,
  },
  meta: params,
})


export const LOAD_REPORT_LAYOUT = 'LOAD_REPORT_LAYOUT'

export const loadReportLayout = (
  { report_layout_id }: ReportLayoutParams  
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_REPORT_LAYOUT,
  resource: `/report-layouts/${report_layout_id}`,
  meta: {
    report_layout_id,
  }
})


export const ADD_REPORT_LAYOUT = 'ADD_REPORT_LAYOUT'

export const addReportLayout = (
  data: CreateReportLayoutParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: ADD_REPORT_LAYOUT,
  resource: '/report-layouts/',
  options: {
    method: 'POST',
    data,
  },
})

export const RESET_ADD_REPORT_LAYOUT = 'RESET_ADD_REPORT_LAYOUT'

export const resetAddReportLayout = (): ReduxAction => ({
  type: RESET_ADD_REPORT_LAYOUT,
})


export const EDIT_REPORT_LAYOUT = 'EDIT_REPORT_LAYOUT'

export const editReportLayout = (
  { report_layout_id, ...data }: ReportLayoutParams & Partial<EditReportLayoutParams> 
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: EDIT_REPORT_LAYOUT,
  resource: `/report-layouts/${report_layout_id}`,
  options: {
    method: 'PATCH',
    data,
  },
  meta: {
    report_layout_id,
  },
  successActions: [
    loadReportLayouts(),
    loadReportLayout({ report_layout_id }),
  ],
})

export const RESET_EDIT_REPORT_LAYOUT = 'RESET_EDIT_REPORT_LAYOUT'

export const resetEditReportLayout = (): ReduxAction => ({
  type: RESET_EDIT_REPORT_LAYOUT,
})


/* Series */


export const LOAD_REPORT_SERIES = 'LOAD_REPORT_SERIES'

export const loadReportSeries = (
  { series_id }: SeriesParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_REPORT_SERIES,
  resource: `/report-series/${series_id}`,
  meta: {
    series_id,
  }
})


export const LOAD_REPORT_SERIES_DETAIL = 'LOAD_REPORT_SERIES_DETAIL'

export const loadReportSeriesDetail = (
  { series_id }: SeriesParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_REPORT_SERIES_DETAIL,
  resource: `/report-series/${series_id}/detail`,
  meta: {
    series_id,
  }
})


export const EDIT_REPORT_SERIES = 'EDIT_REPORT_SERIES'

export const editReportSeries = (
  { series_id, ...data }: SeriesParams & Partial<EditReportSeriesParams>
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: EDIT_REPORT_SERIES,
  resource: `/report-series/${series_id}`,
  options: {
    method: 'PATCH',
    data,
  },
  meta: {
    series_id,
  },
  successActions: [
    loadReportSeriesDetail({ series_id }),
  ],
})

export const RESET_EDIT_REPORT_SERIES = 'RESET_EDIT_REPORT_SERIES'

export const resetEditReportSeries = (): ReduxAction => ({
  type: RESET_EDIT_REPORT_SERIES,
})


export const LOAD_PLATFORM_UNIT_REPORT_SERIES_LEGACY_SERIES = 'LOAD_PLATFORM_UNIT_REPORT_SERIES_LEGACY_SERIES'

export const loadReportSeriesLegacySeries = (
  { series_id }: SeriesParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_PLATFORM_UNIT_REPORT_SERIES_LEGACY_SERIES,
  resource: `/report-series/${series_id}/legacy-series`,
  meta: {
    series_id,
  }
})


export const LOAD_REPORT_SERIES_REPORTS = 'LOAD_REPORT_SERIES_REPORTS'

export const loadReportSeriesReports = (
  { series_id }: SeriesParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_REPORT_SERIES_REPORTS,
  resource: `/report-series/${series_id}/reports`,
  meta: {
    series_id,
  }
})

/* Reports */


export const CREATE_REPORT_TASK = 'CREATE_REPORT_TASK'

export const createReportTask = (
  { series_id, ...data }: SeriesParams & CreateReportSeriesTaskParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: CREATE_REPORT_TASK,
  resource: `/report-series/${series_id}/reports/create-task`,
  options: {
    method: 'POST',
    data,
  },
  meta: {
    series_id,
  },
  successActions: [
    addNotification({
      type: 'success',
      message: 'New report workflow successfully created',
    }),
    loadReportSeriesReports({ series_id }),
  ],
})

export const RESET_CREATE_REPORT_TASK = 'RESET_CREATE_REPORT_TASK'

export const resetCreateReportTask = ({ series_id }: SeriesParams): ReduxAction => ({
  type: RESET_CREATE_REPORT_TASK,
  meta: { series_id },
})


export const LOAD_REPORT_SERIES_REPORT_WORKFLOWS = 'LOAD_REPORT_SERIES_REPORT_WORKFLOWS'

export const loadReportSeriesReportWorkflows = (
  { series_id, report_id }: SeriesParams & StructuralReportParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_REPORT_SERIES_REPORT_WORKFLOWS,
  resource: `/report-series/${series_id}/reports/${report_id}/workflow-executions`,
  meta: {
    series_id,
    report_id,
  }
})


export const ADD_REPORT_SERIES_REPORT_WORKFLOW = 'ADD_REPORT_SERIES_REPORT_WORKFLOW'

export const addReportSeriesReportWorkflow = (
  { series_id, report_id, ...data }: StructuralReportParams & AddReportWorkflowParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: ADD_REPORT_SERIES_REPORT_WORKFLOW,
  resource: `/report-series/${series_id}/reports/${report_id}/workflow-executions`,
  options: {
    method: 'POST',
    data,
  },
  successActions: [
    loadReportSeriesReportWorkflows({
      series_id,
      report_id,
    }),
  ],
  meta: {
    series_id,
    report_id,
  },
})

export const RESET_ADD_REPORT_SERIES_REPORT_WORKFLOW = 'RESET_ADD_REPORT_SERIES_REPORT_WORKFLOW'

export const resetAddReportSeriesReportWorkflow = (): ReduxAction => ({
  type: RESET_ADD_REPORT_SERIES_REPORT_WORKFLOW,
})


export const ADD_REPORT_SERIES_REPORT_WORKFLOW_FROM_LEGACY_TASK = 'ADD_REPORT_SERIES_REPORT_WORKFLOW_FROM_LEGACY_TASK'

export const addReportSeriesReportWorkflowFromLegacyTask = (
  { series_id, report_id, ...data }: StructuralReportParams & ReportWorkflowProcessParams & { legacy_download_task_id: string }
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: ADD_REPORT_SERIES_REPORT_WORKFLOW_FROM_LEGACY_TASK,
  resource: `/report-series/${series_id}/reports/${report_id}/create-task-from-legacy-execution`,
  options: {
    method: 'POST',
    data,
  },
  successActions: [
    loadReportSeriesReportWorkflows({
      series_id,
      report_id,
    }),
  ],
  meta: {
    series_id,
    report_id,
  },
})

export const RESET_ADD_REPORT_SERIES_REPORT_WORKFLOW_FROM_LEGACY_TASK = 'RESET_ADD_REPORT_SERIES_REPORT_WORKFLOW_FROM_LEGACY_TASK'

export const resetAddReportSeriesReportWorkflowFromLegacyTask = (): ReduxAction => ({
  type: RESET_ADD_REPORT_SERIES_REPORT_WORKFLOW_FROM_LEGACY_TASK,
})


export const LOAD_WORKFLOW_EXECUTION_HISTORY = 'LOAD_WORKFLOW_EXECUTION_HISTORY'

export const loadWorkflowExecutionHistory = (
  { workflow_execution_id }: WorkflowExecutionParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_WORKFLOW_EXECUTION_HISTORY,
  resource: `/workflow-executions/${workflow_execution_id}/execution-history`,
  meta: {
    workflow_execution_id,
  },
  notifyError: false,
})


export const RERUN_REPORT_WORKFLOW = 'RERUN_REPORT_WORKFLOW'

export const rerunReportWorkflow = (
  { series_id, report_id, ...data }: StructuralReportParams & RerunReportWorkflowParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: RERUN_REPORT_WORKFLOW,
  resource: `/report-series/${series_id}/reports/${report_id}/create-task-from-execution`,
  options: {
    method: 'POST',
    data,
  },
  successActions: [
    addNotification({
      type: 'success',
      message: 'Report workflow re-run successfully created',
    }),
    loadReportSeriesReportWorkflows({ series_id, report_id }),
  ],
})

export const RESET_RERUN_REPORT_WORKFLOW = 'RESET_RERUN_REPORT_WORKFLOW'

export const resetRerunReportWorkflow = (): ReduxAction => ({
  type: RESET_RERUN_REPORT_WORKFLOW,
})


export const RERUN_REPORT_WORKFLOW_COMPLETION_HANDLERS = 'RERUN_REPORT_WORKFLOW_COMPLETION_HANDLERS'

export const rerunReportWorkflowCompletionHandlers = (
  { series_id, report_id, ...data }: StructuralReportParams & RerunReportWorkflowCompletionHandlerParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: RERUN_REPORT_WORKFLOW_COMPLETION_HANDLERS,
  resource: `/report-series/${series_id}/reports/${report_id}/run-completion-handlers`,
  options: {
    method: 'POST',
    data,
  },
  successActions: [
    addNotification({
      type: 'success',
      message: `Workflow completion handlers successfully re-run for execution ${data.workflow_execution_id}`,
    }),
  ],
})

export const RESET_RERUN_REPORT_WORKFLOW_COMPLETION_HANDLERS = 'RESET_RERUN_REPORT_WORKFLOW_COMPLETION_HANDLERS'

export const resetRerunReportWorkflowCompletionHandlers = (): ReduxAction => ({
  type: RESET_RERUN_REPORT_WORKFLOW_COMPLETION_HANDLERS,
})


export const RERUN_REPORT_SERIES = 'RERUN_REPORT_SERIES'

export const rerunReportSeries = (
  { series_id, ...data }: SeriesParams & RerunReportSeriesParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: RERUN_REPORT_SERIES,
  resource: `/report-series/${series_id}/re-run`,
  options: {
    method: 'POST',
    data,
  },
  meta: { series_id },
  notifyError: false,
  getSuccessActions: (payload: RerunReportSeriesResponse) => {
    if( payload.dry_run ){
      return []
    }
    return [
      addNotification({
        id: `rerun-${series_id}`,
        type: payload.dry_run ? 'info' : 'success',
        loading: false,
        message: (
          `${payload.report_ids.length} reports affected by series re-run `
          + `spanning ${payload.start} to ${payload.end}`
        ),
      })
    ]
  },
  getErrorActions: (error) => [
    addNotification({
      id: `rerun-${series_id}`,
      type: 'error',
      loading: false,
      message: error.message,
      ttl: 0,
    })
  ],
})

export const RESET_RERUN_REPORT_SERIES = 'RESET_RERUN_REPORT_SERIES'

export const resetRerunReportSeries = (): ReduxAction => ({
  type: RESET_RERUN_REPORT_SERIES,
})


export const LOAD_REPORT_SERIES_REPORT = 'LOAD_REPORT_SERIES_REPORT'

export const loadReportSeriesReport = (
  { series_id, report_id, admin }: SeriesParams & StructuralReportParams & AdminParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_REPORT_SERIES_REPORT,
  resource: `/report-series/${series_id}/reports/${report_id}`,
  options: {
    params: {
      admin,
    },
  },
  meta: {
    series_id,
    report_id,
  }
})


export const SET_ACTIVE_REPORT_WORKFLOW_RESULTS = 'SET_ACTIVE_REPORT_WORKFLOW_RESULTS'

export const setActiveReportWorkflowResults = (
  { series_id, report_id, ...data }: StructuralReportParams & WorkflowExecutionParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: SET_ACTIVE_REPORT_WORKFLOW_RESULTS,
  resource: `/report-series/${series_id}/reports/${report_id}/set-active-results-from-execution`,
  options: {
    method: 'POST',
    data,
  },
  pendingActions: [
    addNotification({
      id: `set-active-report-results-${report_id}`,
      loading: true,
      message: `Setting active results for report ${report_id}...`,
    }),
  ],
  successActions: [
    addNotification({
      id: `set-active-report-results-${report_id}`,
      loading: false,
      type: 'success',
      message: `Active results updated for report ${report_id}`,
    }),
    loadReportSeriesReport({ series_id, report_id, admin: true }),
    loadReportSeriesReports({ series_id }),
    loadReportSeriesReportWorkflows({ series_id, report_id }),
  ],
  getErrorActions: error => [
    addNotification({
      id: `set-active-report-results-${report_id}`,
      type: 'error',
      loading: false,
      message: `Active results could not be updated for report ${report_id}: ${error.message}`,
      ttl: 0,
    })
  ],
  notifyError: false,
})

export const RESET_SET_ACTIVE_REPORT_WORKFLOW_RESULTS = 'RESET_SET_ACTIVE_REPORT_WORKFLOW_RESULTS'

export const resetSetActiveReportWorkflowResults = (): ReduxAction => ({
  type: RESET_SET_ACTIVE_REPORT_WORKFLOW_RESULTS,
})


/* Legacy tasks */

export const LOAD_REPORT_SERIES_REPORT_LEGACY_TASKS = 'LOAD_REPORT_SERIES_REPORT_LEGACY_TASKS'

export const loadReportSeriesReportLegacyTasks = (
  { series_id, report_id }: SeriesParams & StructuralReportParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_REPORT_SERIES_REPORT_LEGACY_TASKS,
  resource: `/report-series/${series_id}/reports/${report_id}/legacy-tasks`,
  meta: {
    series_id,
    report_id,
  },
  notifyError: false,
})


/* Report output payloads */

export const LOAD_REPORT_SERIES_REPORT_STRUCTURE = 'LOAD_REPORT_SERIES_REPORT_STRUCTURE'

export const loadReportSeriesReportStructure = (
  { series_id, report_id }: SeriesParams & StructuralReportParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_REPORT_SERIES_REPORT_STRUCTURE,
  resource: `/report-series/${series_id}/reports/${report_id}/structure`,
  meta: {
    series_id,
    report_id,
  }
})


export const LOAD_REPORT_SERIES_REPORT_PAYLOAD = 'LOAD_REPORT_SERIES_REPORT_PAYLOAD'

export const loadReportSeriesReportPayload = (
  { series_id, report_id, result_id }: SeriesParams & StructuralReportParams & Partial<ReportResultParams>
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_REPORT_SERIES_REPORT_PAYLOAD,
  resource: `/report-series/${series_id}/reports/${report_id}/payload`,
  options:{
    params: {
      result_id,
    },
  },
  meta: {
    series_id,
    report_id,
    result_id,
  }
})


export const LOAD_REPORT_SERIES_REPORT_ENTITY = 'LOAD_REPORT_SERIES_REPORT_ENTITY'

export const loadReportSeriesReportEntity = (
  { series_id, report_id, entity_type, entity_id, result_id }: (
   SeriesParams & StructuralReportParams & EntityParams & Partial<ReportResultParams>
  )
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_REPORT_SERIES_REPORT_ENTITY,
  resource: `/report-series/${series_id}/reports/${report_id}/entity`,
  options: {
    params: {
      entity_type,
      entity_id,
      result_id,
    },
  },
  meta: {
    series_id,
    report_id,
    entity_type,
    entity_id,
    result_id,
  }
})


/* Metrics */

export const LOAD_REPORT_SERIES_REPORT_METRIC = 'LOAD_REPORT_SERIES_REPORT_METRIC'

export const loadReportSeriesReportMetric = (
  { series_id, report_id, entity_type, entity_id, metric_id, dimension, result_id }: (
   SeriesParams & ReportMetricParams & Partial<ReportResultParams>
  )
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_REPORT_SERIES_REPORT_METRIC,
  resource: `/report-series/${series_id}/reports/${report_id}/metric`,
  options: {
    params: {
      entity_type,
      entity_id,
      metric_id,
      dimension,
      result_id,
    },
  },
  meta: {
    series_id,
    report_id,
    entity_type,
    entity_id,
    metric_id,
    dimension,
    result_id,
  }
})

export const LOAD_REPORT_SERIES_METRIC_TIMESERIES = 'LOAD_REPORT_SERIES_METRIC_TIMESERIES'

export const loadReportSeriesMetricTimeseries = (
  { series_id, entity_type, entity_id, metric_id, dimension }: (
   SeriesReportMetricParams
  )
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_REPORT_SERIES_METRIC_TIMESERIES,
  resource: `/report-series/${series_id}/metric-timeseries`,
  options: {
    params: {
      entity_type,
      entity_id,
      metric_id,
      dimension,
    },
  },
  meta: {
    series_id,
    entity_type,
    entity_id,
    metric_id,
    dimension,
  }
})


/* Insights */

export const LOAD_REPORT_SERIES_INSIGHTS = 'LOAD_REPORT_SERIES_INSIGHTS'

export const loadReportSeriesInsights = (
  { series_id }: SeriesParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_REPORT_SERIES_INSIGHTS,
  resource: `/report-series/${series_id}/recommendations/latest`,
  meta: {
    series_id,
  }
})


export const LOAD_REPORT_SERIES_INSIGHTS_REPORT = 'LOAD_REPORT_SERIES_INSIGHTS_REPORT'

export const loadReportSeriesLatestInsightReport = (
  { series_id, insights_report_type }: SeriesParams & InsightsReportTypeParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_REPORT_SERIES_INSIGHTS_REPORT,
  resource: `/report-series/${series_id}/insight-reports/${insights_report_type}/latest`,
  meta: {
    series_id,
    insights_report_type,
  }
})


export const LOAD_REPORT_SERIES_REPORT_INSIGHTS_REPORT = 'LOAD_REPORT_SERIES_REPORT_INSIGHTS_REPORT'

export const loadReportSeriesReportInsightsReport = (
  { series_id, report_id, insights_report_type, result_id }: InsightsReportLoadParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_REPORT_SERIES_REPORT_INSIGHTS_REPORT,
  resource: `/report-series/${series_id}/reports/${report_id}/insight-reports/${insights_report_type}`,
  options: {
    params: result_id && { result_id } || undefined,
  },
  meta: {
    series_id,
    report_id,
    result_id,
    insights_report_type,
  }
})

/* Pillar Scores */

export const LOAD_PLATFORM_UNIT_PROVIDER_PILLAR_SCORES = 'LOAD_PLATFORM_UNIT_PROVIDER_PILLAR_SCORES'

export const loadPlatformUnitProviderPillarScores = (
  { org_unit_id }: PlatformUnitParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_PLATFORM_UNIT_PROVIDER_PILLAR_SCORES,
  resource: `org-units/${org_unit_id}/report-series/provider-scores/`,
  meta: {
    org_unit_id,
  },
})


export const LOAD_PLATFORM_UNIT_CHANNEL_PILLAR_SCORES = 'LOAD_PLATFORM_UNIT_CHANNEL_PILLAR_SCORES'

export const loadPlatformUnitChannelPillarScores = (
  { org_unit_id }: PlatformUnitParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_PLATFORM_UNIT_CHANNEL_PILLAR_SCORES,
  resource: `org-units/${org_unit_id}/report-series/channel-scores/`,
  meta: {
    org_unit_id,
  },
})


export const LOAD_PLATFORM_UNIT_CHILDREN_PROVIDER_PILLAR_SCORES = 'LOAD_PLATFORM_UNIT_CHILDREN_PROVIDER_PILLAR_SCORES'

export const loadPlatformUnitChildrenProviderPillarScores = (
  { org_unit_id }: PlatformUnitParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_PLATFORM_UNIT_CHILDREN_PROVIDER_PILLAR_SCORES,
  resource: `org-units/${org_unit_id}/children/report-series/provider-scores/`,
  meta: {
    org_unit_id,
  },
})


export const LOAD_PLATFORM_UNIT_CHILDREN_CHANNEL_PILLAR_SCORES = 'LOAD_PLATFORM_UNIT_CHILDREN_CHANNEL_PILLAR_SCORES'

export const loadPlatformUnitChildrenChannelPillarScores = (
  { org_unit_id }: PlatformUnitParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_PLATFORM_UNIT_CHILDREN_CHANNEL_PILLAR_SCORES,
  resource: `org-units/${org_unit_id}/children/report-series/channel-scores/`,
  meta: {
    org_unit_id,
  },
})


export const LOAD_REPORT_SERIES_PILLAR_SCORES = 'LOAD_REPORT_SERIES_PILLAR_SCORES'

export const loadReportSeriesPillarScores = (
  { series_id }: SeriesParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_REPORT_SERIES_PILLAR_SCORES,
  resource: `report-series/${series_id}/report-scores`,
  meta: {
    series_id,
  },
})


/* Potential Efficiencies */


export const LOAD_REPORT_SERIES_POTENTIAL_EFFICIENCIES = 'LOAD_REPORT_SERIES_POTENTIAL_EFFICIENCIES'

export const loadReportSeriesPotentialEfficiencies = (
  { series_id }: SeriesParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_REPORT_SERIES_POTENTIAL_EFFICIENCIES,
  resource: `report-series/${series_id}/potential-efficiencies`,
  meta: {
    series_id,
  },
})

export const LOAD_PLATFORM_UNIT_POTENTIAL_EFFICIENCIES = 'LOAD_PLATFORM_UNIT_POTENTIAL_EFFICIENCIES'

export const loadPlatformUnitPotentialEfficiencies = (
  { org_unit_id, target_currency }: PlatformUnitParams & CurrencyParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_PLATFORM_UNIT_POTENTIAL_EFFICIENCIES,
  resource: `org-units/${org_unit_id}/report-series/potential-efficiency-scores/`,
  options: {
    params: {
      target_currency
    },
  },
  meta: {
    org_unit_id,
    ...(target_currency && {
      target_currency
    }),
  },
})


export const LOAD_POTENTIAL_EFFICIENCY_CONFIGURATION = 'LOAD_POTENTIAL_EFFICIENCY_CONFIGURATION'

export const loadPotentialEfficiencyConfiguration = (
  { provider }: ReportProviderParams
): AsyncReduxAction => createAsyncStructuralReportingAction({
  type: LOAD_POTENTIAL_EFFICIENCY_CONFIGURATION,
  resource: `meta/potential-efficiencies/${providerURLMap[provider]}/config`,
  meta: { provider },
  notifyError: false,
})
