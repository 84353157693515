
import audit_payload from './audit_payload'
import audit_payload_root from './audit_payload_root'
import metric, { singleMetric } from './metric'
import reportPayload from './reportPayload'

import {
  performanceTimeseries,
  performanceTimeseriesByProvider,
  channelPerformanceTimeseries,
  adNetworkPerformanceTimeseries,
  providerPerformanceTimeseries,
  performanceTotals,
  doubleVerifyPerformanceTimeseries,
  doubleVerifyProviderPerformanceTimeseries,
  performanceComparisons,
  providerReferenceDates,
} from './performanceReporting'

import {
  insightsReport,
  nestedPlatformUnitPillarScores,
  nestedPlatformUnitChannelPillarScores,
  potentialEfficiencies,
  platformUnitPillarScores,
  platformUnitChannelPillarScores,
  reportLayout,
  reportSeriesPillarScores,
} from './structuralReporting'

import {
  platformUnit,
} from './platformUnit'

import { getPath } from '@percept/utils'


export { default as metricFixes } from './metric-fixes'


const adaptersBySchemaKey = {
  audit_payload,
  audit_payload_root,
  report_layout: reportLayout,
}

const getAdapter = (props: any) => {
  const schema_key = getPath(props, 'schema_key'),
        schema_version = getPath(props, 'schema_version')

  const adapters = getPath(adaptersBySchemaKey, schema_key)

  if( !adapters ){
    throw new Error(
      `Unrecognised schema_key '${schema_key}'`
    )
  }

  const adapter = adapters[schema_version] || adapters.__default

  if( typeof adapter !== 'function' ){
    throw new Error(
      `Unrecognised schema_key '${schema_key}' or unrecognized schema_version ${schema_version}`
    )
  }

  return adapter(props || {})
}

export const auditPayload = getAdapter

export {
  metric,
  reportPayload,
  reportLayout,
  singleMetric,
  performanceTimeseries,
  performanceTimeseriesByProvider,
  performanceTotals,
  channelPerformanceTimeseries,
  adNetworkPerformanceTimeseries,
  providerPerformanceTimeseries,
  doubleVerifyPerformanceTimeseries,
  doubleVerifyProviderPerformanceTimeseries,
  performanceComparisons,
  platformUnit,
  platformUnitPillarScores,
  platformUnitChannelPillarScores,
  nestedPlatformUnitPillarScores,
  nestedPlatformUnitChannelPillarScores,
  providerReferenceDates,
  reportSeriesPillarScores,
  insightsReport,
  potentialEfficiencies,
}

import { metricSeries, annotation } from './non-schema-adapters'

export default {
  audit_payload,
  audit_payload_root,
  report_layout: reportLayout,
  reportLayout,
  reportPayload,
  metric,
  singleMetric,
  metricSeries,
  annotation,
  performanceTimeseriesByProvider,
  performanceTotals,
  channelPerformanceTimeseries,
  adNetworkPerformanceTimeseries,
  providerPerformanceTimeseries,
  doubleVerifyPerformanceTimeseries,
  doubleVerifyProviderPerformanceTimeseries,
  performanceComparisons,
  performanceTimeseries,
  platformUnit,
  platformUnitPillarScores,
  platformUnitChannelPillarScores,
  nestedPlatformUnitPillarScores,
  nestedPlatformUnitChannelPillarScores,
  providerReferenceDates,
  insightsReport,
  potentialEfficiencies,
}

export * from './non-schema-adapters'

export * from './performanceReporting'
