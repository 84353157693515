import {
  Box,
  darken,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
} from '@material-ui/core'
import { useNavigation } from '@percept/hooks'
import { makeAppStyles } from '@percept/mui'

import React from 'react'
import { useLocation } from 'react-router'

const WIDTH = 240

const useSidebarClasses = makeAppStyles((theme) => ({
  drawer: {
    width: WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: WIDTH,
    backgroundColor: theme.appComponents.contrastPanel.root.backgroundColor,
    border: 'none',
  },
  drawerToolbar: {
    minHeight: 96,
  },
  drawerContentContainer: {
    overflow: 'auto',
  },
  drawerListItem: {
    paddingLeft: theme.spacing(3),
    color: '#C6CBD0',
    '& .MuiTypography-root': {
      fontWeight: 500,
      padding: 3,
      lineHeight: 1.75,
    },
  },
  drawerActiveButton: {
    borderLeft: '4px solid #E0E0E0',
    backgroundColor: darken(theme.palette.primary.main, 0.275),
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.35),
    },
    '& .MuiTypography-root': {
      fontWeight: 700,
    },
    color: '#ffffff',
  },
  contentContainer: {
    flexGrow: 1,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(16),
  },
}))

type TabProps = {
  title: string
  value: string
}

export const Sidebar = ({ tabs }: { tabs: TabProps[] }): JSX.Element => {
  const sidebarClasses = useSidebarClasses()
  const { pathname } = useLocation()
  const navigate = useNavigation()

  const mainUrlPart = pathname.substring(0, pathname.lastIndexOf('/'))

  const lastUrlPart = pathname.substring(
    pathname.lastIndexOf('/') + 1,
    pathname.length
  )

  return (
    <Drawer
      variant='permanent'
      className={sidebarClasses.drawer}
      classes={{ paper: sidebarClasses.drawerPaper }}>
      <Toolbar className={sidebarClasses.drawerToolbar} />
      <Box className={sidebarClasses.drawerContentContainer}>
        <List>
          {tabs.map((tab, index) => (
            <ListItem
              button
              className={`${sidebarClasses.drawerListItem} ${
                `/${lastUrlPart}` === tab.value
                  ? sidebarClasses.drawerActiveButton
                  : undefined
              }`}
              onClick={(): void => {
                navigate(`${mainUrlPart}${tab.value}`)
              }}
              key={index}>
              <ListItemText
                primary={tab.title}
                primaryTypographyProps={{
                  variant: 'subtitle1',
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  )
}
