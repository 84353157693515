import logger from 'redux-logger'

import {
  asyncMiddlewareCreator,
  AsyncMiddlewareProps,
  errorMiddleware,
  mfaMiddleware,
  tokenMiddleware,
} from '@percept/redux' 

import { routerMiddleware } from 'connected-react-router'

import analyticsMiddleware from './analytics'

import { sentryMiddlewareCreator } from './sentry'

import { PersistenceMiddlewareConfig, persistenceMiddlewareCreator } from './persistenceMiddleware'

import ignoreMiddleware from './ignore'

import { TRACK } from 'redux/actions'

import { Middleware } from 'redux'

import { History } from 'history'
import thunkMiddleware from 'redux-thunk'


const {
  WORKLOAD_TYPE,
  NODE_ENV,
  SENTRY_DSN,
  GA_TRACKING_ID,
  GTAG_ID,
} = process.env


const isDevMode = NODE_ENV === 'development',
      isTesting = WORKLOAD_TYPE !== 'PROD' || NODE_ENV === 'development'


const middlewareDebug = {
  analytics: false,
  sentry: false,
}


const ignore = {
  dev: middlewareDebug.analytics ? [] : [TRACK]
}


export type CreateMiddlewareProps = (
  AsyncMiddlewareProps &
  PersistenceMiddlewareConfig & {
    history: History
  }
)


export const createMiddleware = ({
  history,
  api,
  persistence,
}: CreateMiddlewareProps): Middleware[] => [
  
  thunkMiddleware,

  ...(
    isDevMode && [
      ignoreMiddleware({ env: WORKLOAD_TYPE, ignore }),
      errorMiddleware,
    ] || []
  ) as Middleware[],

  ...(
    !!( ( !isDevMode || middlewareDebug.sentry ) && SENTRY_DSN ) && [
      sentryMiddlewareCreator({ dsn: SENTRY_DSN, env: WORKLOAD_TYPE }),
    ] || []
  ),

  tokenMiddleware,

  mfaMiddleware,

  routerMiddleware(history),
  
  asyncMiddlewareCreator({ api }),

  persistenceMiddlewareCreator({ persistence }),

  ...(
    !!( GA_TRACKING_ID || GTAG_ID || middlewareDebug.analytics ) && [
      analyticsMiddleware({ GTAG_ID, GA_TRACKING_ID, debug: middlewareDebug.analytics }),
    ] || []
  ),

  ...(
    isTesting && [
      logger
    ] || []
  )

]
