
import { combineReducers } from 'redux'

import {
  createKeyedReducer,
  createApiReducer,
} from '../../reducers'

import {
  getFetchTypes,
  getProviderKeyFromAction,
} from '../../utils'


import { apiInitialState } from '../../constants'

import { LOAD_METRIC_METADATA } from './actions'

import {
  ReduxAction,
  ApiResponse,
  MetricMetadataEntryType,
} from '@percept/types'

import { MetricMetadataState } from './typings'


const fetchTypes = getFetchTypes(LOAD_METRIC_METADATA)


const byId = (state = {}, action: ReduxAction): Record<string, MetricMetadataEntryType> =>
  action.type === fetchTypes.success ? (
    {
      ...state,
      ...(action.payload || []).reduce( (obj: Record<string, MetricMetadataEntryType>, metric: MetricMetadataEntryType) => {
        obj[metric.metric_id] = metric
        return obj
      }, {})
    }
  ) : (
    state
  )


const defaultOverviewReducer = createApiReducer<MetricMetadataEntryType[]>(LOAD_METRIC_METADATA)


const allFetchTypes = Object.values(fetchTypes)

const overview = (state = apiInitialState, action: ReduxAction): ApiResponse<MetricMetadataEntryType[]> => {
  if(
    allFetchTypes.indexOf(action.type) !== -1 && (
      !action.meta || typeof action.meta.provider === 'undefined'
    )
  ){
    return defaultOverviewReducer(state, action)
  }
  return state
}


const responsesByFilter = createKeyedReducer<ApiResponse<true>>(
  createApiReducer(LOAD_METRIC_METADATA, {
    getPayloadFromAction: () => true
  }),
  getProviderKeyFromAction
)


export default combineReducers<MetricMetadataState>({
  byId,
  overview,
  responsesByFilter,
})
