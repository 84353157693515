
import React from 'react'

import DashboardRoot from './Main'

import { useSectionScoresObject } from '@percept/hooks'

import { ReportDashboardProps } from './typings'


export const ReportDashboard = ({
  metrics,
  previousMetrics,
  metadata,
  impactWeighted,
  layout,
  healthDimension = 'count',
  activePerformanceTail = '',
  displayType = 'SUMMARY',
  ...props
}: ReportDashboardProps): JSX.Element | null => {

  const sectionScores = useSectionScoresObject(
    layout,
    metrics,
    {
      healthDimension,
      impactWeighted,
      metricMetadata: metadata,
    }
  )

  const { name, members, features = {} } = layout || {}

  if( !members || !members.length ){
    console.warn('No layout provided!')
    return null
  }

  return (
    <DashboardRoot
      type='main'
      name={name}
      members={members}
      autoExpand={!!features.auto_expand}
      sectionScores={sectionScores}
      metadata={metadata}
      performanceTail={activePerformanceTail}
      displayType={displayType}
      filterLegend
      metrics={metrics}
      previous={previousMetrics}
      onSegmentClick={props.setActiveMetric}
      healthDimension={healthDimension}
      {...props} />
  )

}
