import { Box } from '@material-ui/core'
import { makeAppStyles, Money } from '@percept/mui'
import { spacedPercentageFormatter } from '@percept/mui/charts'
import React from 'react'
import { Summary } from '../DashboardLayout'
import { ListItem } from '../types'
import { IncompleteData } from 'api/services/Api'
import { AsteriskIcon } from '@percept/mui/icons'

export const useStyles = makeAppStyles((theme) => ({
  groupItem: {
    backgroundColor: theme.palette.action.disabledBackground,
    borderBottom: `1px solid ${theme.appComponents.table.footer.borderColor}`,
  },
  totalItem: {
    ...theme.appComponents.table.footer,
    // borderBottom: `1px solid ${theme.appComponents.table.footer.borderColor}`,
    // borderBottom: `1px solid ${theme.appComponents.table.footer.color}`,
    borderBottom: '0.5px solid rgba(255, 255, 255, 0.35)',
  },
  item: {
    borderBottom: `1px solid ${theme.appComponents.table.footer.borderColor}`,
  },
  cell: {
    flex: 1,
    minWidth: 140,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 15px',
    fontWeight: 500,
    fontSize: 13,
  },
  bold: {
    fontWeight: 700
  },
  noWrap: {
    whitespace: 'nowrap',
  },
  incomplete: {
    color: theme.palette.error.main,
    fontSize: 10,
    margin: theme.spacing(0, 0.5),
  },
}))

export const TableCells = ({
  row,
  incompleteData,
  depth = 0,
  isOpen,
  dataFormat,
  dimensions,
}: {
  row: ListItem
  incompleteData: IncompleteData[] | undefined
  depth?: number
  isOpen: string[]
  dimensions: string[]
  dataFormat: string
}): JSX.Element => {
  const classes = useStyles()

  return (
    <>
      <Box flex='1' display='flex'>
        {dimensions.map((dimension) => {
          const cell = row.costs.find((el) => el.type_value === dimension)
          const isTotal = row.row_group === Summary.AllTotal || row.isTotalRow
          const className = [
            classes.cell,
            (
              isTotal
                ? classes.totalItem
                : row.data
                  ? classes.groupItem
                  : classes.item
            ),
            (row.data || isTotal) && classes.bold,
          ].filter(Boolean).join(' ')
          return (
            <div
              key={dimension}
              className={className}>
              {cell && cell.hasIncompleteData ? <AsteriskIcon className={classes.incomplete} fontSize='inherit' /> : null}
              {!cell || cell.spend === '' || cell.spend === null || cell.spend === undefined ? (
                '-'
              ) : row.row_group === 'Difference' ? (
                spacedPercentageFormatter(cell.percent)
              ) : dataFormat === 'currency' ? (
                <Money
                  amount={Number(cell.spend)}
                  currency={row.total_currency}
                />
              ) : (
                spacedPercentageFormatter(cell.percent)
              )}
            </div>
          )
        })}
      </Box>
      {isOpen.includes(row.row_group) &&
        (row.data ?? []).map((member) => (
          <TableCells
            incompleteData={incompleteData}
            dataFormat={dataFormat}
            key={member.data_type}
            row={{
              ...member,
              total_currency: row.total_currency,
              row_group:
                row.row_group === Summary.AllTotal
                  ? Summary.AllTotal
                  : row.isTotalRow
                    ? row.row_group
                    : member.data_type,
            }}
            depth={depth + 1}
            isOpen={[]}
            dimensions={dimensions}
          />
        ))}
    </>
  )
}
