
import { SET_ACTIVE_SERIES_PROVIDER } from '../actions'

import {
  Reducer,
} from '@percept/types'

import { SeriesState } from '../typings'


const initialState: SeriesState['activeProvider'] = {}


const activeProvider: Reducer<SeriesState['activeProvider']> = (state = initialState, action) => {

  switch(action.type){

    case SET_ACTIVE_SERIES_PROVIDER:
      return {
        ...state,
        [action.payload.series_id]: action.payload.provider
      }

    default:
      return state

  }

}

export default activeProvider
