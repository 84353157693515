import {
  useMyUser,
  useMyUserDetail,
  useMyUserPrivileges,
} from '@percept/hooks'


export const UserEnvironmentLoader = (): null => {
  useMyUser()
  useMyUserDetail()
  useMyUserPrivileges()
  // We want to ensure we kick off the loaders, nothing more
  return null
}
