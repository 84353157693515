import React from 'react'

import { Link } from 'react-router-dom'

import { Alert, Box, Button, Divider, Typography } from '@percept/mui'

import { ArrowBack } from '@percept/mui/icons'

import { DefaultLayout } from './DefaultLayout'


export const PageNotFound = (): JSX.Element => {
  return (
    <DefaultLayout>
      <Box py={4} px={3}>

        <Typography
          variant='h3'>
          Page Not Found
        </Typography>

        <Box my={4}>
          <Divider />
        </Box>

        <Box mt={5} display='flex' justifyContent='center'>

          <Alert
            p={4}
            maxWidth='50rem'>
            <Box mb={5} fontSize={24}>
              Sorry, we couldn&apos;t find that page.
            </Box>

            <Box mb={6} fontSize={18}>
              Please try returning to the homepage, or selecting a dashboard from the menu.
            </Box>

            <Box display='flex' justifyContent='center'>
              <Link to='/'>
                <Button
                  size='large'
                  variant='contained'
                  startIcon={<ArrowBack />}>
                  Back To Homepage
                </Button>
              </Link>
            </Box>

          </Alert>

        </Box>

      </Box>
    </DefaultLayout>
  )
}

export default PageNotFound
