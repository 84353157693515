import { getFinancialYearStart } from '@percept/utils'

import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns'

export type DateRangePreset = (
  | 'current-financial-year'
  | 'current-year'
  | 'last-year'
  | 'last-financial-year'
  | 'last-quarter'
  | 'last-month'
  | 'last-week'
  | 'last-7-days'
  | 'last-30-days'
  | 'yesterday'
  | 'today'
)

export const dateRangeCalculators: Record<DateRangePreset, (referenceDate: Date) => [Date, Date]> = {
  'current-financial-year': refDate => [
    getFinancialYearStart(refDate), refDate
  ],
  'current-year': refDate => [
    startOfYear(refDate), refDate,
  ],
  'last-financial-year': refDate => [
    getFinancialYearStart(subYears(refDate, 1)), subDays(getFinancialYearStart(refDate), 1)
  ],
  'last-year': refDate => [
    startOfYear(subYears(refDate, 1)), endOfYear(subYears(refDate, 1))
  ],
  'last-quarter': refDate => [
    startOfQuarter(subQuarters(refDate, 1)), endOfQuarter(subQuarters(refDate, 1))
  ],
  'last-month': refDate => [
    startOfMonth(subMonths(refDate, 1)), endOfMonth(subMonths(refDate, 1))
  ],
  'last-week': refDate => [
    startOfWeek(subWeeks(refDate, 1), { weekStartsOn: 1 }),
    endOfWeek(subWeeks(refDate, 1), { weekStartsOn: 1 })
  ],
  'today': () => {
    const today = new Date()
    return [today, today] 
  },
  'yesterday': () => {
    const yesterday = subDays(new Date(), 1)
    return [yesterday, yesterday]
  },
  'last-7-days': refDate => [
    subDays(refDate, 6), refDate,
  ],
  'last-30-days': refDate => [
    subDays(refDate, 29), refDate,
  ]
}
