import { Box, IconButton, Tooltip } from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import { makeAppStyles, PlainTextButton } from '@percept/mui'
import React from 'react'
import { Summary } from '../DashboardLayout'
import { ListItem } from '../types'

const useStyles = makeAppStyles((theme) => ({
  groupItem: {
    padding: 0,
    backgroundColor: theme.palette.action.disabledBackground,
    borderBottom: `1px solid ${theme.appComponents.table.footer.borderColor}`,
    fontWeight: 700,
    paddingRight: '5px',
  },
  totalItem: {
    ...theme.appComponents.table.footer,
    // borderBottom: `0.5px solid ${theme.appComponents.table.footer.color}`,
    borderBottom: '0.5px solid rgba(255, 255, 255, 0.35)',
    // m
    fontWeight: 700,
  },
  item: {
    borderBottom: `1px solid ${theme.appComponents.table.footer.borderColor}`,
    fontWeight: 500,
  },
  groupButton: {
    fontWeight: 700,
    justifyContent: 'start',
    whiteSpace: 'nowrap',
  },
  itemButton: {
    fontWeight: 500,
    paddingLeft: '5px',
    justifyContent: 'start',
    whiteSpace: 'nowrap',
  },
}))

export const TableLeftSide = ({
  row,
  depth = 0,
  onClick,
  isOpen,
}: {
  row: ListItem
  depth?: number
  isOpen: string[]
  onClick: (e: string) => void
}): JSX.Element => {
  const isGroupType = row.data && row.data.length > 0
  const isTotalRow = row.row_group === Summary.AllTotal || row.isTotalRow
  const minHeight = isTotalRow ? '31px' : '32px'
  const classes = useStyles()

  return (
    <Box
      flexDirection='row'
      display='flex'
      alignItems='flex-start'
      className={
        isTotalRow ? classes.totalItem : undefined
      }>
      <Box
        display='flex'
        flex={1}
        pr={2}
        width={150}
        minHeight={minHeight}
        className={
          isTotalRow
            ? classes.totalItem
            : (depth === 0 && row.data) || row.row_group === 'TOTAL'
              ? classes.groupItem
              : classes.item
        }>
        {isGroupType && (
          <IconButton
            size='small'
            color='inherit'
            onClick={(): void => {
              onClick(row.row_group)
            }}>
            {isOpen.includes(row.row_group) ? (
              <ArrowDropUp />
            ) : (
              <ArrowDropDown />
            )}
          </IconButton>
        )}
        <PlainTextButton
          size='small'
          color='inherit'
          className={depth === 0 ? classes.groupButton : classes.itemButton}>
          {row.row_group.length > 18 ? (
            <Tooltip title={row.row_group} placement='left'>
              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {row.row_group}
              </span>
            </Tooltip>
          ) : (
            row.row_group
          )}
        </PlainTextButton>
      </Box>
      {isOpen.length > 0 && row.data && (
        <Box style={{ width: '150px' }}>
          <TableLeftSide
            key={'Total'}
            row={{
              row_group:
                row.row_group === Summary.AllTotal ? 'GRAND TOTAL' : row.isTotalRow ? row.row_group : 'TOTAL',
              total_percentage: row.total_percentage,
              costs: [],
              total: row.total,
              total_currency: row.total_currency,
              isTotalRow,
            }}
            depth={depth}
            onClick={onClick}
            isOpen={isOpen}
          />
          {isOpen.includes(row.row_group) &&
            (row.data ?? []).map((member) => (
              <TableLeftSide
                key={member.data_type}
                row={{
                  ...member,
                  total_currency: row.total_currency,
                  row_group: member.data_type,
                }}
                depth={depth + 1}
                onClick={onClick}
                isOpen={isOpen}
              />
            ))}
        </Box>
      )}
    </Box>
  )
}
