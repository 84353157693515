import React from 'react'

import { ProviderLogo } from '../Logos'

import { MultiSelectBase, MultiSelectBaseProps } from './MultiSelectBase'

import { identity } from 'lodash-es'

import { PerformanceProvider, ReportProviderV2 } from '@percept/types'


type ProviderSelectOption = PerformanceProvider | ReportProviderV2

export type MultiProviderSelectProps = Omit<
  MultiSelectBaseProps<ProviderSelectOption>, 'itemLabel' | 'selectPrompt' | 'getSortValue'
> & {
  providerLabel?: string
}

const renderProvider = (value: ProviderSelectOption): React.ReactNode => (
  <ProviderLogo provider={value} size={1.65} style={{position: 'relative', top: 3}} />
)

export const MultiProviderSelect = ({
  providerLabel = 'Provider',
  ...props
}: MultiProviderSelectProps
): JSX.Element => {
  return (
    <MultiSelectBase
      itemLabel={providerLabel}
      selectPrompt={`Select a ${providerLabel.toLowerCase()}`}
      getSortValue={identity}
      renderItem={renderProvider}
      {...props} />
  )
}
