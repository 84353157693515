import { SingleMarketOverviewDatum } from './typings'

export const singleYearlyMarketValues: SingleMarketOverviewDatum[] = [
  {
    market_name: 'DE',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'year',
        segments: [
          {
            segment: 'Vodafone',
            current: 12,
            previous: 19, //  YoY Change CSV value = -7, for now we have to do like this cause of speed current - previous = 12 - 9 = -7
            target: 29,
          },
          {
            segment: 'DTAG',
            current: 45,
            previous: 29, // YoY Change CSV value = 16, for now we have to do like this cause of speed current - previous = 45 - 29 = 16
            target: null,
          },
          {
            segment: 'O2',
            current: 22,
            previous: 27, //  YoY Change CSV value = -5, for now we have to do like this cause of speed current - previous = 22 - 27 = -5
            target: null,
          },
          {
            segment: '1+1',
            current: 21,
            previous: 19, //  YoY Change CSV value = 2, for now we have to do like this cause of speed current - previous = 21 - 19 = 2
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'year',
        segments: [
          {
            segment: 'All Media',
            current: 40,
            previous: 37, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 55, 
            previous: 100, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 20,
            previous: 21,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'year',
        current: 24,
        previous: null,
        change: null,
        value: null,
        target: 20,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'year',
        current: 4.01,
        previous: null,
        change: -15.08,
        value: 631833.43,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'year',
        current: 3.61,
        previous: null,
        change: -41.09,
        value: 903472.09,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'year',
        current: 9.35,
        previous: null,
        change: 15.36,
        value: 1535306,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'year',
        current: 4.41,
        previous: null,
        change: -68.68,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'year',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'UK',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'year',
        segments: [
          {
            segment: 'Vodafone',
            current: 12,
            previous: 19, //  YoY Change CSV value = -7, for now we have to do like this cause of speed current - previous = 12 - 9 = -7
            target: 29,
          },
          {
            segment: 'DTAG',
            current: 45,
            previous: 29, // YoY Change CSV value = 16, for now we have to do like this cause of speed current - previous = 45 - 29 = 16
            target: null,
          },
          {
            segment: 'O2',
            current: 22,
            previous: 27, //  YoY Change CSV value = -5, for now we have to do like this cause of speed current - previous = 22 - 27 = -5
            target: null,
          },
          {
            segment: '1+1',
            current: 21,
            previous: 19, //  YoY Change CSV value = 2, for now we have to do like this cause of speed current - previous = 21 - 19 = 2
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'year',
        segments: [
          {
            segment: 'All Media',
            current: 40,
            previous: 37, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 55, 
            previous: 100, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 20,
            previous: 21,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'year',
        current: 24,
        previous: null,
        change: null,
        value: null,
        target: 20,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'year',
        current: 4.39,
        previous: null,
        change: -34.70,
        value: 339868.58,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'year',
        current: 38.73,
        previous: null,
        change: -29.02,
        value: 896050.33,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'year',
        current: 27.68,
        previous: null,
        change: 34.17,
        value: 1235919,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'year',
        current: 43.19,
        previous: null,
        change: 122.63,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'year',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'IT',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'year',
        segments: [
          {
            segment: 'Vodafone',
            current: 12,
            previous: 19, //  YoY Change CSV value = -7, for now we have to do like this cause of speed current - previous = 12 - 9 = -7
            target: 29,
          },
          {
            segment: 'DTAG',
            current: 45,
            previous: 29, // YoY Change CSV value = 16, for now we have to do like this cause of speed current - previous = 45 - 29 = 16
            target: null,
          },
          {
            segment: 'O2',
            current: 22,
            previous: 27, //  YoY Change CSV value = -5, for now we have to do like this cause of speed current - previous = 22 - 27 = -5
            target: null,
          },
          {
            segment: '1+1',
            current: 21,
            previous: 19, //  YoY Change CSV value = 2, for now we have to do like this cause of speed current - previous = 21 - 19 = 2
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'year',
        segments: [
          {
            segment: 'All Media',
            current: 40,
            previous: 37, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 55, 
            previous: 100, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 20,
            previous: 21,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'year',
        current: 24,
        previous: null,
        change: null,
        value: null,
        target: 20,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'year',
        current: 10.52,
        previous: null,
        change: 12.38,
        value: 409141.23,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'year',
        current: 57.12,
        previous: null,
        change: -20.78,
        value: 378912.98,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'year',
        current: 9.43,
        previous: null,
        change: -26.18,
        value: 788054,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'year',
        current: 6,
        previous: null,
        change: 1339.2,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'year',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'ES',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'year',
        segments: [
          {
            segment: 'Vodafone',
            current: 12,
            previous: 19, //  YoY Change CSV value = -7, for now we have to do like this cause of speed current - previous = 12 - 9 = -7
            target: 29,
          },
          {
            segment: 'DTAG',
            current: 45,
            previous: 29, // YoY Change CSV value = 16, for now we have to do like this cause of speed current - previous = 45 - 29 = 16
            target: null,
          },
          {
            segment: 'O2',
            current: 22,
            previous: 27, //  YoY Change CSV value = -5, for now we have to do like this cause of speed current - previous = 22 - 27 = -5
            target: null,
          },
          {
            segment: '1+1',
            current: 21,
            previous: 19, //  YoY Change CSV value = 2, for now we have to do like this cause of speed current - previous = 21 - 19 = 2
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'year',
        segments: [
          {
            segment: 'All Media',
            current: 40,
            previous: 37, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 55, 
            previous: 100, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 20,
            previous: 21,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'year',
        current: 24,
        previous: null,
        change: null,
        value: null,
        target: 20,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'year',
        current: 4.88,
        previous: null,
        change: -39.71,
        value: 154853.16,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'year',
        current: 13.05,
        previous: null,
        change: -83.05,
        value: 60376.91,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'year',
        current: 4.16,
        previous: null,
        change: -70.62,
        value: 215230,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'year',
        current: 65.19,
        previous: null,
        change: 5936.11,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'year',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'ZA',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'year',
        segments: [
          {
            segment: 'Vodafone',
            current: 12,
            previous: 19, //  YoY Change CSV value = -7, for now we have to do like this cause of speed current - previous = 12 - 9 = -7
            target: 29,
          },
          {
            segment: 'DTAG',
            current: 45,
            previous: 29, // YoY Change CSV value = 16, for now we have to do like this cause of speed current - previous = 45 - 29 = 16
            target: null,
          },
          {
            segment: 'O2',
            current: 22,
            previous: 27, //  YoY Change CSV value = -5, for now we have to do like this cause of speed current - previous = 22 - 27 = -5
            target: null,
          },
          {
            segment: '1+1',
            current: 21,
            previous: 19, //  YoY Change CSV value = 2, for now we have to do like this cause of speed current - previous = 21 - 19 = 2
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'year',
        segments: [
          {
            segment: 'All Media',
            current: 40,
            previous: 37, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 55, 
            previous: 100, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 20,
            previous: 21,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'year',
        current: 24,
        previous: null,
        change: null,
        value: null,
        target: 20,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'year',
        current: 6.61,
        previous: null,
        change: -25.26,
        value: 122425.66,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'year',
        current: 62.44,
        previous: null,
        change: -2.08,
        value: 365762.94,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'year',
        current: 24.18,
        previous: null,
        change: -36.35,
        value: 488189,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'year',
        current: 13.91,
        previous: null,
        change: 1.31,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'year',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'TR',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'year',
        segments: [
          {
            segment: 'Vodafone',
            current: 12,
            previous: 19, //  YoY Change CSV value = -7, for now we have to do like this cause of speed current - previous = 12 - 9 = -7
            target: 29,
          },
          {
            segment: 'DTAG',
            current: 45,
            previous: 29, // YoY Change CSV value = 16, for now we have to do like this cause of speed current - previous = 45 - 29 = 16
            target: null,
          },
          {
            segment: 'O2',
            current: 22,
            previous: 27, //  YoY Change CSV value = -5, for now we have to do like this cause of speed current - previous = 22 - 27 = -5
            target: null,
          },
          {
            segment: '1+1',
            current: 21,
            previous: 19, //  YoY Change CSV value = 2, for now we have to do like this cause of speed current - previous = 21 - 19 = 2
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'year',
        segments: [
          {
            segment: 'All Media',
            current: 40,
            previous: 37, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 55, 
            previous: 100, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 20,
            previous: 21,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'year',
        current: 24,
        previous: null,
        change: null,
        value: null,
        target: 20,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'year',
        current: 5.51,
        previous: null,
        change: -11.26,
        value: 110985.4,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'year',
        current: 68.40,
        previous: null,
        change: -8.65,
        value: 111916.3,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'year',
        current: 10.99,
        previous: null,
        change: 21.30,
        value: 222902,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'year',
        current: 16.01,
        previous: null,
        change: 558.98,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'year',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'RO',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'year',
        segments: [
          {
            segment: 'Vodafone',
            current: 12,
            previous: 19, //  YoY Change CSV value = -7, for now we have to do like this cause of speed current - previous = 12 - 9 = -7
            target: 29,
          },
          {
            segment: 'DTAG',
            current: 45,
            previous: 29, // YoY Change CSV value = 16, for now we have to do like this cause of speed current - previous = 45 - 29 = 16
            target: null,
          },
          {
            segment: 'O2',
            current: 22,
            previous: 27, //  YoY Change CSV value = -5, for now we have to do like this cause of speed current - previous = 22 - 27 = -5
            target: null,
          },
          {
            segment: '1+1',
            current: 21,
            previous: 19, //  YoY Change CSV value = 2, for now we have to do like this cause of speed current - previous = 21 - 19 = 2
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'year',
        segments: [
          {
            segment: 'All Media',
            current: 40,
            previous: 37, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 55, 
            previous: 100, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 20,
            previous: 21,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'year',
        current: 24,
        previous: null,
        change: null,
        value: null,
        target: 20,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'year',
        current: 4.71,
        previous: null,
        change: -40.06,
        value: 48056.14,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'year',
        current: 61.11,
        previous: null,
        change: -15.32,
        value: 270793.21,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'year',
        current: 31.12,
        previous: null,
        change: -43.67,
        value: 318849,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'year',
        current: 16.79,
        previous: null,
        change: 226.59,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'year',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'GR',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'year',
        segments: [
          {
            segment: 'Vodafone',
            current: 12,
            previous: 19, //  YoY Change CSV value = -7, for now we have to do like this cause of speed current - previous = 12 - 9 = -7
            target: 29,
          },
          {
            segment: 'DTAG',
            current: 45,
            previous: 29, // YoY Change CSV value = 16, for now we have to do like this cause of speed current - previous = 45 - 29 = 16
            target: null,
          },
          {
            segment: 'O2',
            current: 22,
            previous: 27, //  YoY Change CSV value = -5, for now we have to do like this cause of speed current - previous = 22 - 27 = -5
            target: null,
          },
          {
            segment: '1+1',
            current: 21,
            previous: 19, //  YoY Change CSV value = 2, for now we have to do like this cause of speed current - previous = 21 - 19 = 2
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'year',
        segments: [
          {
            segment: 'All Media',
            current: 40,
            previous: 37, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 55, 
            previous: 100, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 20,
            previous: 21,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'year',
        current: 24,
        previous: null,
        change: null,
        value: null,
        target: 20,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'year',
        current: 5.87,
        previous: null,
        change: -15.21,
        value: 52067.23,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'year',
        current: 34.79,
        previous: null,
        change: -37.08,
        value: 114971.82,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'year',
        current: 20.42,
        previous: null,
        change: -18.70,
        value: 167039,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'year',
        current: 35.92,
        previous: null,
        change: 157.39,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'year',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'PT',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'year',
        segments: [
          {
            segment: 'Vodafone',
            current: 12,
            previous: 19, //  YoY Change CSV value = -7, for now we have to do like this cause of speed current - previous = 12 - 9 = -7
            target: 29,
          },
          {
            segment: 'DTAG',
            current: 45,
            previous: 29, // YoY Change CSV value = 16, for now we have to do like this cause of speed current - previous = 45 - 29 = 16
            target: null,
          },
          {
            segment: 'O2',
            current: 22,
            previous: 27, //  YoY Change CSV value = -5, for now we have to do like this cause of speed current - previous = 22 - 27 = -5
            target: null,
          },
          {
            segment: '1+1',
            current: 21,
            previous: 19, //  YoY Change CSV value = 2, for now we have to do like this cause of speed current - previous = 21 - 19 = 2
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'year',
        segments: [
          {
            segment: 'All Media',
            current: 40,
            previous: 37, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 55, 
            previous: 100, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 20,
            previous: 21,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'year',
        current: 24,
        previous: null,
        change: null,
        value: null,
        target: 20,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'year',
        current: 4.53,
        previous: null,
        change: -51.34,
        value: 40061.34,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'year',
        current: 34.86,
        previous: null,
        change: 55.22,
        value: 105317.29,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'year',
        current: 17.26,
        previous: null,
        change: -38.83,
        value: 145379,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'year',
        current: 39.81,
        previous: null,
        change: 129.94,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'year',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'IE',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'year',
        segments: [
          {
            segment: 'Vodafone',
            current: 12,
            previous: 19, //  YoY Change CSV value = -7, for now we have to do like this cause of speed current - previous = 12 - 9 = -7
            target: 29,
          },
          {
            segment: 'DTAG',
            current: 45,
            previous: 29, // YoY Change CSV value = 16, for now we have to do like this cause of speed current - previous = 45 - 29 = 16
            target: null,
          },
          {
            segment: 'o2',
            current: 22,
            previous: 27, //  YoY Change CSV value = -5, for now we have to do like this cause of speed current - previous = 22 - 27 = -5
            target: null,
          },
          {
            segment: '1+1',
            current: 21,
            previous: 19, //  YoY Change CSV value = 2, for now we have to do like this cause of speed current - previous = 21 - 19 = 2
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'year',
        segments: [
          {
            segment: 'All Media',
            current: 40,
            previous: 37, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 55, 
            previous: 100, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 20,
            previous: 21,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'year',
        current: 24,
        previous: null,
        change: null,
        value: null,
        target: 20,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'year',
        current: 2.68,
        previous: null,
        change: -46.95,
        value: 15035.03,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'year',
        current: 37.13,
        previous: null,
        change: -46.14,
        value: 6706.06,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'year',
        current: 3.57,
        previous: null,
        change: -26.71,
        value: 21741,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'year',
        current: 45.38,
        previous: null,
        change: 353.31,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'year',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'CZ',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'year',
        segments: [
          {
            segment: 'Vodafone',
            current: 12,
            previous: 19, //  YoY Change CSV value = -7, for now we have to do like this cause of speed current - previous = 12 - 9 = -7
            target: 29,
          },
          {
            segment: 'DTAG',
            current: 45,
            previous: 29, // YoY Change CSV value = 16, for now we have to do like this cause of speed current - previous = 45 - 29 = 16
            target: null,
          },
          {
            segment: 'O2',
            current: 22,
            previous: 27, //  YoY Change CSV value = -5, for now we have to do like this cause of speed current - previous = 22 - 27 = -5
            target: null,
          },
          {
            segment: '1+1',
            current: 21,
            previous: 19, //  YoY Change CSV value = 2, for now we have to do like this cause of speed current - previous = 21 - 19 = 2
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'year',
        segments: [
          {
            segment: 'All Media',
            current: 40,
            previous: 37, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 55, 
            previous: 100, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 20,
            previous: 21,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'year',
        current: 24,
        previous: null,
        change: null,
        value: null,
        target: 20,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'year',
        current: 3.98,
        previous: null,
        change: -29.98,
        value: 35695.81,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'year',
        current: 42.76,
        previous: null,
        change: 218.51,
        value: 167752.8,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'year',
        current: 27.03,
        previous: null,
        change: 24.64,
        value: 203449,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'year',
        current: 23.46,
        previous: null,
        change: -27.96,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'year',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'AL',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'year',
        segments: [
          {
            segment: 'Vodafone',
            current: 12,
            previous: 19, //  YoY Change CSV value = -7, for now we have to do like this cause of speed current - previous = 12 - 9 = -7
            target: 29,
          },
          {
            segment: 'DTAG',
            current: 45,
            previous: 29, // YoY Change CSV value = 16, for now we have to do like this cause of speed current - previous = 45 - 29 = 16
            target: null,
          },
          {
            segment: 'O2',
            current: 22,
            previous: 27, //  YoY Change CSV value = -5, for now we have to do like this cause of speed current - previous = 22 - 27 = -5
            target: null,
          },
          {
            segment: '1+1',
            current: 21,
            previous: 19, //  YoY Change CSV value = 2, for now we have to do like this cause of speed current - previous = 21 - 19 = 2
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'year',
        segments: [
          {
            segment: 'All Media',
            current: 40,
            previous: 37, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 55, 
            previous: 100, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 20,
            previous: 21,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'year',
        current: 24,
        previous: null,
        change: null,
        value: null,
        target: 20,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'year',
        current: 5.84,
        previous: null,
        change: -35.15,
        value: 3103.47,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'year',
        current: 76.37,
        previous: null,
        change: 1871.01,
        value: 18171.71,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'year',
        current: 12.53,
        previous: null,
        change: -37.02,
        value: 21275,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'year',
        current: 19.50,
        previous: null,
        change: 24.81,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'year',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'EG',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'year',
        segments: [
          {
            segment: 'Vodafone',
            current: 12,
            previous: 19, //  YoY Change CSV value = -7, for now we have to do like this cause of speed current - previous = 12 - 9 = -7
            target: 29,
          },
          {
            segment: 'DTAG',
            current: 45,
            previous: 29, // YoY Change CSV value = 16, for now we have to do like this cause of speed current - previous = 45 - 29 = 16
            target: null,
          },
          {
            segment: 'O2',
            current: 22,
            previous: 27, //  YoY Change CSV value = -5, for now we have to do like this cause of speed current - previous = 22 - 27 = -5
            target: null,
          },
          {
            segment: '1+1',
            current: 21,
            previous: 19, //  YoY Change CSV value = 2, for now we have to do like this cause of speed current - previous = 21 - 19 = 2
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'year',
        segments: [
          {
            segment: 'All Media',
            current: 40,
            previous: 37, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 55, 
            previous: 100, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 20,
            previous: 21,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'year',
        current: 24,
        previous: null,
        change: null,
        value: null,
        target: 20,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'year',
        current: 5.09,
        previous: null,
        change: -16.60,
        value: 30483.85,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'year',
        current: 71.01,
        previous: null,
        change: -17.07,
        value: 268976.67,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'year',
        current: 22.78,
        previous: null,
        change: -8.61,
        value: 299461,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'year',
        current: 4.95,
        previous: null,
        change: -59.25,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'year',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
]