import { CSSProperties, useState } from 'react'

import {
  AnimatedValue,
  ForwardedProps,
  useSpring,
  useTransition,
  UseTransitionResult,
} from 'react-spring'

import { makeAppStyles } from '../../themes'

import { usePrevious } from '@percept/hooks'

import { NotificationItemBase } from '@percept/types'


export type LifeIndicatorProps = {
  id: string | number
  ttl: number | null | undefined
  onRest: (id: string | number) => void
}


export const useLifeIndicatorSpring = ({
  id,
  ttl,
  onRest,
}: LifeIndicatorProps): AnimatedValue<ForwardedProps<{ width: string | number | undefined }>> => {

  ttl = Number(ttl)

  const previousTtl = usePrevious(ttl)
  
  return useSpring({
    width: ttl ? '100%' : '0%',
    from: {
      width: '0%',
    },
    reset: ttl !== previousTtl,
    config: {
      duration: ttl,
    },
    onRest: () => {
      if( ttl && id ){
        onRest(id)
      }
    },
  })
}


export const useLifeIndicatorStyles = makeAppStyles( theme => ({
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
  },
  indicator: {
    height: theme.spacing(0.75),
    position: 'absolute',
    left: 0,
    bottom: 0,
    borderBottomRightRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    backgroundImage: 'linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))'
  },
}))


export type CollapsingItemsHookValue<T extends NotificationItemBase, E extends HTMLElement> = {
  transitions: UseTransitionResult<T, ForwardedProps<CSSProperties>>[]
  itemRef: (item: T, element: E | null) => void
  makeRef: (item: T) => (element: E | null) => void
}


export const useCollapsingItemsTransition = <T extends NotificationItemBase, E extends HTMLElement>(
  items: T[]
): CollapsingItemsHookValue<T, E> => {
  const [refMap] = useState(() => new WeakMap<T, E>())

  const itemRef = (item: T, element: E | null): void => {
    element && refMap.set(item, element)
  }

  const makeRef = (item: T) => (element: E | null): void => {
    element && refMap.set(item, element)
  }

  const transitions = useTransition(
    items,
    item => item.id,
    {
      from: { opacity: 0, height: 0 },
      enter: (
        (item: T) =>
          async (next: (properties: React.CSSProperties) => Promise<void>): Promise<void> =>
            await next({ opacity: 1, height: (refMap.get(item) as E).offsetHeight })
      ) as ((item: T) => Partial<ForwardedProps<React.CSSProperties>>),
      leave: (
        () =>
          async (next: (properties: React.CSSProperties) => Promise<void>): Promise<void> => {
            await next({ opacity: 0 })
            await next({ height: 0 })
          }
      ) as ((item: T) => Partial<ForwardedProps<React.CSSProperties>>),
      unique: true,
    }
  )

  return {
    transitions,
    itemRef,
    makeRef,
  }

}
