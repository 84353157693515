import React, { useContext } from 'react'

import { Box, Typography, makeAppStyles } from '@percept/mui'

import { GlobalView } from './GlobalView'

import { MarketView } from './MarketView'

import { UserPrivilegeContext } from '@percept/app-components'

import { isVodafoneGlobalUser } from 'vodafoneMarkets'


const typography = {
  title: 'Welcome to the Sponsorship Calculator',
  description: (<>
  <strong>
  The Sponsorship calculator provides support in estimating the size of the potential available 
  inventory across Vodafone touchpoints (digital and physical) and ultimately demonstrating their value in Sponsorship deals discussions. 
  </strong>
  <br />
  <br /> 
  How does it work? The template will include the baseline data for your market’s digital and physical marketing assets. 
  To get calculations specific to your deal, simply adjust the values in the form below and you will see the value estimation on screen.
  {/* <i>*For demonstration purposes, the baseline data has been populated with some of the UK data.</i>
  Your market data will be requested following the Media Summit. The ability to save/edit/retrieve previous calculations will also be added later in 2023. */}
  </>)
}

const useStyles = makeAppStyles( theme => ({
  pageContainer: {
    padding: theme.spacing(6, 6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 6),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    fontSize: 38,
    lineHeight: 1.125,
    fontWeight: 700,
    marginBottom: theme.spacing(4),
  },
  text: {
    fontSize: 18,
  },
}))

export const Sponsorships = (): JSX.Element => {
  const classes = useStyles()

  const { user: { default_org_unit_id } } = useContext(UserPrivilegeContext)

  const isGlobalUser = isVodafoneGlobalUser(default_org_unit_id)

  const View = isGlobalUser ? GlobalView : MarketView

  return (
    <Box className={classes.pageContainer}>
      <Typography variant='h3' className={classes.title}>
        {typography.title}
      </Typography>
      <Typography className={classes.text}>{typography.description}</Typography>
      <View />
    </Box>
  )
}
