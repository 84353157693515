import { ParentSize } from '@visx/responsive'
import React, { useLayoutEffect, useRef } from 'react'

import * as d3 from 'd3'

import { circleAnimationDefElements } from './lib'

import { AnimationSurfaceProps } from './typings'


type Point = {
  x: number | string
  y: number | string
}

const makeCircle = (
  surface: d3.Selection<SVGSVGElement | SVGGElement, unknown, null, undefined>,
  coordinates: Point,
  radius: number,
  attrs: Record<string, string | number>
): d3.Selection<SVGCircleElement, unknown, null, undefined> => {
  const el = surface.append('circle')
    .attr('cx', coordinates.x)
    .attr('cy', coordinates.y)
    .attr('r', radius)
  Object.keys(attrs).forEach( attr => {
    el.attr(attr, attrs[attr])
  })
  return el
}


const AnimationSurface = ({
  width,
  height
}: AnimationSurfaceProps): JSX.Element => {
  const svgRef = useRef<SVGSVGElement | null>(null)

  useLayoutEffect(() => {
    const rootEl = svgRef.current
    if( !rootEl ){
      console.warn('No root element for animation')
      return
    }

    const MID_X = width / 2
    const MID_Y = height / 2

    const circleSize = Math.min(width / 5, height / 4)
    const fontSize = circleSize / 6
    const bevelWidth = 12

    const innerCircleMargin = 8
    
    const startRadius = 0
    const endRadius = circleSize / 2

    const circleDuration = 1000
    const lineDuration = 500

    const midpointTop = {
      x: MID_X,
      y: MID_Y - (MID_Y / 2)
    }
    const midpointLeft = {
      x: MID_X - (MID_X / 2),
      y: MID_Y,
    }
    const midpointRight = {
      x: MID_X + (MID_X / 2),
      y: MID_Y,
    }
    const midpointBottom = {
      x: MID_X,
      y: MID_Y + (MID_Y / 2),
    }

    const svg = d3.select(rootEl)
    // Clear surfaces
    svg.selectAll('g').remove()
    const background = svg.append('g')
    const surface = svg.append('g')

    // Begin Media Wizard Circle
    const homeCircleWrap = surface.append('g')
      .attr('filter', 'url(#innerShadow)')
    const subWrap = homeCircleWrap.append('g')
      .attr('filter', 'url(#dropShadow)')

    const homeCircle = makeCircle(
      subWrap,
      midpointTop,
      startRadius,
      { fill:  'url(#radialGradientRed)' }
    )
    const homeCircleBevel = makeCircle(
      subWrap,
      midpointTop,
      startRadius,
      {
        fill: 'none',
        stroke: '#DCDCDC',
        'stroke-width': 0,
        filter:  'url(#innerShadowPath)',
      }
    )
    const whiteCircle = makeCircle(
      subWrap,
      midpointTop,
      startRadius - innerCircleMargin,
      {
        fill:  'none',
        stroke: 'white',
        'stroke-width': 2,
      }
    )

    whiteCircle.transition()
      .duration(circleDuration)
      .attr('r', endRadius - innerCircleMargin)
    homeCircleBevel.transition()
      .duration(circleDuration)
      .attr('r', endRadius + (bevelWidth / 2))
      .attr('stroke-width', bevelWidth)

    homeCircle.transition()
      .duration(circleDuration)
      .attr('r', endRadius)
      .on('end', () => {
        surface.append('text')
          .attr('x', midpointTop.x)
          .attr('y', midpointTop.y - (fontSize / 1.5))
          .text('Media')
          .attr('fill', 'white')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'central')
          .attr('font-size', fontSize * 1.2)
          .attr('font-weight', 700)
        
        surface.append('text')
          .attr('x', midpointTop.x)
          .attr('y', midpointTop.y + (fontSize / 1.5))
          .text('Wizard')
          .attr('fill', 'white')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'central')
          .attr('font-size', fontSize * 1.2)
          .attr('font-weight', 700)
        
        const line = background.append('line')
          .attr('stroke', '#DCDCDC')
          .attr('stroke-width', bevelWidth)
          .attr('stroke-linecap', 'round')
          .attr('filter', 'url(#innerShadowPath)')
          .attr('x1', midpointTop.x)
          .attr('y1', midpointTop.y)
          .attr('x2', midpointTop.x)
          .attr('y2', midpointTop.y)

        line.transition()
          .duration(lineDuration)
          .attr('x2', midpointLeft.x)
          .attr('y2', midpointLeft.y)
          .on('end', () => {
            // Start 'Strategy'
            // Begin Strategy Circle
            const strategyCircleWrap = surface.append('g')
              .attr('filter', 'url(#innerShadow)')
            const subWrapStrategy = strategyCircleWrap.append('g')
              .attr('filter', 'url(#dropShadow)')

            const strategyCircle = makeCircle(
              subWrapStrategy,
              midpointLeft,
              startRadius,
              { fill:  'url(#radialGradientWhite)' }
            )
            const strategyCircleBevel = makeCircle(
              subWrapStrategy,
              midpointLeft,
              startRadius,
              {
                fill: 'none',
                stroke: '#DCDCDC',
                'stroke-width': 0,
                filter:  'url(#innerShadowPath)',
              }
            )

            strategyCircleBevel.transition()
              .duration(circleDuration)
              .attr('r', endRadius + (bevelWidth / 2))
              .attr('stroke-width', bevelWidth)
            strategyCircle.transition()
              .duration(circleDuration)
              .attr('r', endRadius)
              .on('end', () => {
                surface.append('text')
                  .attr('x', midpointLeft.x)
                  .attr('y', midpointLeft.y)
                  .text('Strategy')
                  .attr('fill', 'black')
                  .attr('text-anchor', 'middle')
                  .attr('dominant-baseline', 'central')
                  .attr('font-size', fontSize)
                  .attr('font-weight', 500)
                
                const line = background.append('line')
                  .attr('stroke', '#DCDCDC')
                  .attr('stroke-width', bevelWidth)
                  .attr('stroke-linecap', 'round')
                  .attr('filter', 'url(#innerShadowPath)')
                  .attr('x1', midpointTop.x)
                  .attr('y1', midpointTop.y)
                  .attr('x2', midpointTop.x)
                  .attr('y2', midpointTop.y)

                line.transition()
                  .duration(lineDuration)
                  .attr('x2', midpointBottom.x)
                  .attr('y2', midpointBottom.y)
                  .on('end', () => {
                    // Start 'Optimisation' circle
                    const optimisationCircleWrap = surface.append('g')
                      .attr('filter', 'url(#innerShadow)')
                    const subWrapOptimisation = optimisationCircleWrap.append('g')
                      .attr('filter', 'url(#dropShadow)')

                    const optimisationCircle = makeCircle(
                      subWrapOptimisation,
                      midpointBottom,
                      startRadius,
                      { fill:  'url(#radialGradientWhite)' }
                    )
                    const optimisationCircleBevel = makeCircle(
                      subWrapOptimisation,
                      midpointBottom,
                      startRadius,
                      {
                        fill: 'none',
                        stroke: '#DCDCDC',
                        'stroke-width': 0,
                        filter:  'url(#innerShadowPath)',
                      }
                    )

                    optimisationCircleBevel.transition()
                      .duration(circleDuration)
                      .attr('r', endRadius + (bevelWidth / 2))
                      .attr('stroke-width', bevelWidth)

                    optimisationCircle.transition()
                      .duration(circleDuration)
                      .attr('r', endRadius)
                      .on('end', () => {
                        surface.append('text')
                          .attr('x', midpointBottom.x)
                          .attr('y', midpointBottom.y)
                          .text('Optimisation')
                          .attr('fill', 'black')
                          .attr('text-anchor', 'middle')
                          .attr('dominant-baseline', 'central')
                          .attr('font-size', fontSize)
                          .attr('font-weight', 500)
                        
                        const line = background.append('line')
                          .attr('stroke', '#DCDCDC')
                          .attr('stroke-width', bevelWidth)
                          .attr('stroke-linecap', 'round')
                          .attr('filter', 'url(#innerShadowPath)')
                          .attr('x1', midpointTop.x)
                          .attr('y1', midpointTop.y)
                          .attr('x2', midpointTop.x)
                          .attr('y2', midpointTop.y)
      
                        line.transition()
                          .duration(lineDuration)
                          .attr('x2', midpointRight.x)
                          .attr('y2', midpointRight.y)
                          .on('end', () => {
                            // Start 'Reporting' circle
                            const reportingCircleWrap = surface.append('g')
                              .attr('filter', 'url(#innerShadow)')
                            const subWrapReporting = reportingCircleWrap.append('g')
                              .attr('filter', 'url(#dropShadow)')
      
                            const reportingCircle = makeCircle(
                              subWrapReporting,
                              midpointRight,
                              startRadius,
                              { fill:  'url(#radialGradientWhite)' }
                            )
                            const reportingCircleBevel = makeCircle(
                              subWrapReporting,
                              midpointRight,
                              startRadius,
                              {
                                fill: 'none',
                                stroke: '#DCDCDC',
                                'stroke-width': 0,
                                filter:  'url(#innerShadowPath)',
                              }
                            )
      
                            reportingCircleBevel.transition()
                              .duration(circleDuration)
                              .attr('r', endRadius + (bevelWidth / 2))
                              .attr('stroke-width', bevelWidth)

                            reportingCircle.transition()
                              .duration(circleDuration)
                              .attr('r', endRadius)
                              .on('end', () => {
                                surface.append('text')
                                  .attr('x', midpointRight.x)
                                  .attr('y', midpointRight.y)
                                  .text('Reporting')
                                  .attr('fill', 'black')
                                  .attr('text-anchor', 'middle')
                                  .attr('dominant-baseline', 'central')
                                  .attr('font-size', fontSize)
                                  .attr('font-weight', 500)
                              })
                          })
                      })
                  })
              })
          })
      })
    
  }, [width, height])

  return (
    <svg
      id='animation-media-wizard'
      ref={svgRef}
      width={width}
      height={height}>
      <defs>
        { circleAnimationDefElements }
      </defs>
    </svg>
  )

}


export const AnimationMediaWizard = (): JSX.Element => {
  return (
    <ParentSize
      debounceTime={100}>
      { AnimationSurface }
    </ParentSize>
  )
}
