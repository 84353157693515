
export const marketPathwaysAssetMap: Record<string, string> = {
  DE: 'pathways-de-2023',
  EG: 'pathways-eg-2023',
  ES: 'pathways-es-2023',
  GR: 'pathways-gr-2023',
  IE: 'pathways-ie-2023',
  IT: 'pathways-it-2023',
  PT: 'pathways-pt-2023',
  TR: 'pathways-tr-2023',
  UK: 'pathways-uk-2023',
}
