
import {
  ApiResponse,
  ApiStatusResponse,
  FetchTypes,
} from '@percept/types'


export const fetchTypes: FetchTypes = {
  start: 'PENDING',
  progress: 'PROGRESS',
  success: 'FULFILLED',
  error: 'REJECTED'
}


export const apiInitialState: ApiResponse = {
  data: null,
  loading: false,
  error: null,
  lastFetched: null
}


export const apiInitialStateWithProcessing: ApiStatusResponse = {
  ...apiInitialState,
  processing: false,
  processed: false
}