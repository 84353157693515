import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import {
  Box,
  Card,
  Container,
  Tab,
  Tabs,
  Loader,
  makeAppStyles,
} from '@percept/mui'
import { Hero } from './Hero'
import {
  LatestReportPage,
  SubmitAnalysisPage,
  AllMarketsPage,
  AboutBGPPage,
} from './pages'
import { getBGPRoute, isVodafoneGlobalOrgUnit } from './utils'
import { useNavigation } from '@percept/hooks'
import { UserPrivilegeContext } from '@percept/app-components'
import {
  BrandGrowthPlannerContext,
  BrandGrowthPlannerTabContext,
  FormSubmissionContext,
} from './contexts'

import {
  BGPTab,
  defaultTab,
  TAB_SLUG_SEARCH_PARAM,
  globalUserTabs,
  userTabs,
  slugTabMap,
  tabLabels,
} from './tabs'


const tabComponentMap: Record<BGPTab, () => JSX.Element | null> = {
  REPORTS: LatestReportPage,
  SUBMIT: SubmitAnalysisPage,
  MARKETS: AllMarketsPage,
  ABOUT: AboutBGPPage,
}


const useTabsStyles = makeAppStyles(() => ({
  tabs: {
    borderBottom: '1px solid #BEBFBE',
  },
  tab: {
    textTransform: 'none',
  },
}))


export const BrandGrowthPlanner = (): JSX.Element | null => {
  const { user } = useContext(UserPrivilegeContext)

  if (!user.default_org_unit_id) return <Loader preset='fullscreen' />

  const isGlobalUser = isVodafoneGlobalOrgUnit(user.default_org_unit_id)

  return (
    <BrandGrowthPlannerContent
      marketId={user.default_org_unit_id}
      isGlobalUser={isGlobalUser}
    />
  )
}

export const BrandGrowthPlannerContent = ({
  marketId,
  isGlobalUser,
}: {
  marketId: string
  isGlobalUser: boolean
}): JSX.Element | null => {
  const location = useLocation()
  const classes = useTabsStyles()


  const tabs = isGlobalUser ? globalUserTabs : userTabs

  const searchParams = new URLSearchParams(location.search)

  const tabSlug = searchParams.get(TAB_SLUG_SEARCH_PARAM)

  const activeTab = (tabSlug && slugTabMap[tabSlug] || tabs[0])

  const navigate = useNavigation()

  const navigateToTab = (tab: BGPTab): void => {
    navigate(getBGPRoute(tab))
  }

  useEffect(() => {
    if( !tabs.includes(activeTab) ){
      const initialTab = tabs.includes(defaultTab) ? defaultTab : tabs[0]
      navigate(getBGPRoute(initialTab))
    }
  }, [tabs, activeTab, navigate])

  const ActiveTabComponent = tabComponentMap[activeTab]

  return (
    <BrandGrowthPlannerContext.Provider value={{ isGlobalUser, marketId }}>
      <Box p={3}>
        <Container>
          <Hero />
        </Container>

        <Box mt={6}>
          <Tabs
            value={activeTab}
            onChange={(_, tab: BGPTab): void => {
              navigateToTab(tab)
            }}
            className={classes.tabs}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'>
            {tabs.map((value) => (
              <Tab
                key={value}
                className={classes.tab}
                label={tabLabels[value]}
                value={value} />
            ))}
          </Tabs>
        </Box>

        <Container>
          <Box mt={3}>
            <Card raised>
              <Box p={4}>
                <BrandGrowthPlannerTabContext.Provider
                  value={{ navigateToTab }}>
                  <FormSubmissionContext.Provider
                    value={{ onSubmit: (): void => navigateToTab('REPORTS') }}>
                    <ActiveTabComponent />
                  </FormSubmissionContext.Provider>
                </BrandGrowthPlannerTabContext.Provider>
              </Box>
            </Card>
          </Box>
        </Container>
      </Box>
    </BrandGrowthPlannerContext.Provider>
  )
}
