
import React, { Fragment } from 'react'

import { makeAppStyles } from '../themes'

import { AnimatedAxis } from '@visx/react-spring'

import { Axis } from '@visx/axis'

import { ScaleRequirements, SizeRequirements, TickConfiguration } from './typings'

import { TickLabelProps } from '@visx/axis'


export type AxesProps = (
  ScaleRequirements &
  TickConfiguration & {
    arbitraryTimeline?: boolean
    animate?: boolean
    hideText?: boolean
    left?: number
    stroke?: string
    tickStroke?: string
    xTickValues?: any[]
    yTickValues?: any[]
    getXTickLabelProps?: TickLabelProps<string | number>
    getYTickLabelProps?: TickLabelProps<string | number>
  }
)


// Idempotent empty typed arrays
const EMPTY_TICKS: string[] = []
const EMPTY_VALUES: number[] = []


const useStyles = makeAppStyles({
  axisLine: {
    pointerEvents: 'none',
  },
})


export const Axes = ({
  animate = true,
  xScale,
  yScale,
  // arbitraryTimeline = false,
  left,
  hideText,
  stroke,
  tickStroke,
  xTickValues,
  numXTicks,
  hideXTickLines = true,
  yTickValues,
  numYTicks,
  hideYTickLines = true,
  getXTickLabelProps,
  getYTickLabelProps,
  xTickFormatter,
  yTickFormatter,
}: AxesProps): JSX.Element => {

  const AxisComponent = animate ? AnimatedAxis : Axis

  const classes = useStyles()

  return (
    <Fragment>
  
      <AxisComponent
        axisClassName={classes.axisLine}
        orientation='left'
        animationTrajectory='outside'
        scale={yScale}
        hideTicks={hideYTickLines}
        tickValues={
          hideText || numYTicks === 0 ?
            EMPTY_VALUES :
            yTickValues
        }
        numTicks={numYTicks}
        left={left}
        tickLabelProps={getYTickLabelProps}
        tickFormat={yTickFormatter}
        tickStroke={tickStroke}
        tickLength={0}
        stroke={stroke} />
  
      <AxisComponent
        axisClassName={classes.axisLine}
        animationTrajectory='outside'
        orientation='bottom'
        scale={xScale}
        hideTicks
        tickValues={EMPTY_TICKS}
        top={Number(yScale(0))}
        stroke={stroke} />
  
      <AxisComponent
        axisClassName={classes.axisLine}
        animationTrajectory='outside'
        orientation='bottom'
        scale={xScale}
        hideTicks={hideXTickLines}
        tickValues={
          (hideText || numXTicks === 0) ?
            EMPTY_VALUES :
            xTickValues
        }
        numTicks={numXTicks}
        tickLabelProps={getXTickLabelProps}
        tickFormat={xTickFormatter}
        tickStroke={tickStroke}
        tickLength={0}
        top={Number(yScale(yScale.domain()[0]))}
        stroke='none' />
  
    </Fragment>
  
  )
}


export type SimpleAxesProps = (
  SizeRequirements &
  Partial<
    Omit<
      React.SVGAttributes<SVGLineElement>,
      'x1' | 'x2' | 'y1' | 'y2'
    >
  > & {
    top?: number
    left?: number
  }
)


export const SimpleAxes = ({
  width,
  height,
  left = 0,
  top = 0,
  ...props
}: SimpleAxesProps): JSX.Element => (
  <Fragment>

    <line
      x1={left}
      x2={left}
      y1={top}
      y2={height}
      pointerEvents='none'
      {...props} />

    <line
      x1={left}
      x2={width}
      y1={height}
      y2={height}
      pointerEvents='none'
      {...props} />

  </Fragment>
)