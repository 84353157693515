import React, { Fragment, useEffect, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import performanceReporting, { PerformanceReportingState } from '@percept/redux/bundles/performanceReporting'

import { apiInitialState, getNestedPerformanceTotalsKey, getPlatformUnitKey } from '@percept/redux'

import {
  Alert,
  Box,
  Card,
  CardContent,
  CardStrip,
  CellRenderer,
  CellRenderers,
  Column,
  Loader,
  ProviderLogo,
  SimpleTable,
  Typography,
} from '@percept/mui'

import { PotentialEfficiencyRenderer } from './PotentialEfficiencies'

import { PerformanceValueRenderer } from 'components/Tables'

import { usePerformanceTotalsTableStyles } from './styles'

import { format, subDays } from 'date-fns'

import { every, get, intersection, sortBy } from 'lodash-es'

import { apiResponseOf, shouldAttemptLoad } from '@percept/utils'

import { primaryPerformanceDataProviders, providerChannelMap } from '@percept/constants'

import { StoreState } from 'types'

import {
  AnyPerformanceDimension,
  ApiResponse,
  ChannelKey,
  PerformanceProviderParams,
  PerformanceTotals as PerformanceTotalsType,
  PlatformUnitParams,
  PlatformUnitProviderPerformanceTotalsParams,
  PrimaryPerformanceDataProvider,
} from '@percept/types'


const dayRanges = [
  1, 7, 30, 90, 180, 365,
]

const formatDateParam = (date: Date): string => (
  format(date, 'yyyy-MM-dd')
)


const resolveMergedDatasetValue = ({
  entries,
  provider,
  dimension,
  multiplier,
}: PerformanceProviderParams & {
  entries: Partial<ProviderTotals>
  dimension: AnyPerformanceDimension
  multiplier?: number | null
}): number | null => {
  const datasetValue: number | null = get(
    entries,
    [provider, 'dataset', dimension],
    null
  )
  if( typeof multiplier === 'undefined' ){
    return datasetValue
  }
  if( multiplier === null || datasetValue === null ){
    return null
  }
  return datasetValue * multiplier
}


type ProviderTotals = Record<PrimaryPerformanceDataProvider, PerformanceTotalsType>


type RangedProviderTotal = {
  days: number
  entries: Partial<ProviderTotals>
}


type RangedProviderPerformanceProps = (
  PlatformUnitParams & {
    currency: string
    dimension: AnyPerformanceDimension
    providers: PrimaryPerformanceDataProvider[]
    referenceDate: Date | null
    potentialEfficiencyLabel: string
    showPotentialEfficiency?: boolean
    providerMultipliers?: Partial<Record<PrimaryPerformanceDataProvider, number | null>> | null
  }
)


const useRangedProviderPerformanceTotals = ({
  org_unit_id,
  providers,
  referenceDate,
  currency,
}: RangedProviderPerformanceProps): ApiResponse<RangedProviderTotal[]> => {

  const dispatch = useDispatch()

  const allPerformanceTotals = useSelector<
    StoreState, PerformanceReportingState['totalsByProvider']
  >( state => (
    state.performanceReporting.totalsByProvider
  ))
  
  const requests = useMemo(() => {
    if( !referenceDate ){
      return []
    }
    const end_date = formatDateParam(referenceDate)

    return ( 
      dayRanges.map( days => ({
        days,
        entries: providers.reduce( (acc, provider) => {
          acc[provider] = {
            org_unit_id,
            provider,
            target_currency: currency,
            start_date: formatDateParam(subDays(referenceDate, days - 1)),
            end_date,
          }
          return acc
        }, {} as Record<PrimaryPerformanceDataProvider, PlatformUnitProviderPerformanceTotalsParams>)
      }))
    )
  }, [org_unit_id, providers, referenceDate, currency])

  const requestResponses = useMemo(() => {
    return requests.map( config => {
      return {
        days: config.days,
        entries: Object.values(config.entries).map( e => ({
          config: e,
          response: get(
            allPerformanceTotals,
            [getPlatformUnitKey(e) || '', getNestedPerformanceTotalsKey(e)],
            apiInitialState
          ) as ApiResponse<PerformanceTotalsType>
        }))
      }
    })
  }, [requests, allPerformanceTotals])

  useEffect(() => {
    requestResponses.forEach( ({ entries }) => {
      entries.forEach( entry => {
        if( shouldAttemptLoad(entry.response) ){
          dispatch(performanceReporting.actions.loadProviderPerformanceTotals(entry.config))
        }
      })
    })
  }, [dispatch, requestResponses])

  return useMemo(() => {
    if( every(requestResponses, r => every(r.entries, e => e.response.data || e.response.error)) ){
      return apiResponseOf(
        sortBy(
          requestResponses.map( ({ days, entries }) => ({
            days,
            entries: entries.reduce( (acc, e) => {
              const { data } = e.response
              if( data ){
                acc[e.config.provider] = data
              }
              return acc
            }, {} as Partial<ProviderTotals>),
          }))
        )
      )
    }
    return apiInitialState
  }, [requestResponses])
}


type PerformanceTotalsRow = Record<number, number | null> & {
  channel: ChannelKey
  provider: PrimaryPerformanceDataProvider
  potential_efficiency_ratio: number | null
  currency?: string | null
  dimension?: AnyPerformanceDimension
}


const performanceTotalCellRenderers: CellRenderers<PerformanceTotalsRow> = {
  /* eslint-disable react/prop-types, react/display-name */
  provider: (props): JSX.Element => {
    return <ProviderLogo provider={props.provider} size={1.5} />
  },
  potential_efficiency_ratio: (props): JSX.Element => {
    return (
      <span style={{display: 'inline-flex', position: 'relative', height: 18}}>
        <span style={{position: 'absolute', bottom: -14, right: 0}}>
          <PotentialEfficiencyRenderer
            ratio={props.potential_efficiency_ratio}
            channel={props.channel} />
        </span>
      </span>
    )
  },
  ...(dayRanges.reduce((acc, day) => {
    acc[day] = (props): JSX.Element => {
      return (
        <PerformanceValueRenderer
          value={props[day]}
          dimension={props.dimension}
          currency={props.currency} />
      )
    }
    return acc
  }, {} as Record<number, CellRenderer<PerformanceTotalsRow>>)),
  /* eslint-enable react/prop-types, react/display-name */
}


export const PerformanceTotals = (props: RangedProviderPerformanceProps): JSX.Element => {

  const rangedTotals = useRangedProviderPerformanceTotals(props)

  const { columns, rows } = useMemo(() => {
    if( !rangedTotals.data ){
      return {
        columns: null,
        rows: null,
      }
    }
    const byProvider: Partial<
      Record<PrimaryPerformanceDataProvider, Record<number, number | null>>
    > = {}
    const seenRanges = new Set<number>()
    const seenProviders = new Set<PrimaryPerformanceDataProvider>()
    rangedTotals.data.forEach(({ days, entries }) => {
      seenRanges.add(days)
      const providers = Object.keys(entries) as PrimaryPerformanceDataProvider[]
      providers.forEach( provider => {
        seenProviders.add(provider)
        const ref = byProvider[provider] = byProvider[provider] || {}
        ref[days] = resolveMergedDatasetValue({
          entries,
          provider,
          dimension: props.dimension,
          multiplier: get(props.providerMultipliers, provider),
        })
      })
    })

    const sortedDays = sortBy(
      Array.from(seenRanges),
      n => Number(n)
    )

    const columnKeys: ((keyof PerformanceTotalsRow) | number)[] = [
      'provider',
      ...(props.showPotentialEfficiency && [
        'potential_efficiency_ratio',
      ] || []) as (keyof PerformanceTotalsRow)[],
      ...sortedDays,
    ]

    const columns: Column<PerformanceTotalsRow>[] = columnKeys.map( (key, i) => ({
      key,
      align: i === 0 ? 'left' : 'right',
      label: (
        key === 'potential_efficiency_ratio' ?
          props.potentialEfficiencyLabel :
          key === 'provider' ?
            '' :
            `${key} Day${key === 1 ? '' : 's'}`
      ),
    })) as Column<PerformanceTotalsRow>[]

    const rows = intersection(primaryPerformanceDataProviders, Array.from(seenProviders)).map( provider => ({
      channel: providerChannelMap[provider],
      currency: props.currency,
      dimension: props.dimension,
      provider,
      potential_efficiency_ratio: get(props.providerMultipliers, provider, null),
      ...sortedDays.reduce( (acc, day) => {
        acc[day] = get(byProvider[provider], day, null)
        return acc
      }, {} as Record<number, number | null>),
    }) as PerformanceTotalsRow)

    return {
      columns,
      rows,
      sortedDays,
    }
  }, [
    rangedTotals.data,
    props.currency,
    props.dimension,
    props.showPotentialEfficiency,
    props.potentialEfficiencyLabel,
    props.providerMultipliers
  ])

  const tableClasses = usePerformanceTotalsTableStyles()

  return (
    <Fragment>
      <Box mb={3}>
        <Typography variant='h5'>
          Totals
        </Typography>
      </Box>
      { !rangedTotals.data && !rangedTotals.error && <Loader preset='fullsize' minHeight='16rem' /> }
      { rangedTotals.error && (
        <Alert variant='error' message={rangedTotals.error.message} />
      )}
      { rangedTotals.data && columns && rows && (
        <Card>
          {/* <CardStrip color='neutral' /> */}
          <CardContent>
            <SimpleTable
              classes={tableClasses}
              columns={columns}
              rows={rows}
              renderers={performanceTotalCellRenderers}
              sortable />
          </CardContent>
        </Card>
      )}
      {/* { rangedTotals.data && (
        <Grid container spacing={3}>
          { rangedTotals.data.map( ({ days, entries }) => (
            <Grid
              key={days}
              item
              xs={6}
              sm={3}
              md={2}>
              <Typography variant='subtitle1'>
                {days} day{days === 1 ? '' : 's'}
              </Typography>
              { (intersection(reportProviders, Object.keys(entries)) as ReportProvider[]).map( provider => {
                const multiplier = get(props.providerMultipliers, provider, null)
                return (
                  <Box key={provider} display='flex' alignItems='center' p={1}>
                    <ProviderLogo
                      provider={provider} />
                    <NowCellRenderer
                      key=''
                      label=''
                      potential_efficiency_ratio={multiplier}
                      channel={providerChannelMap[provider]}
                      currency={props.currency}
                      now={
                        resolveMergedDatasetValue({
                          entries,
                          provider,
                          dimension: props.dimension,
                          multiplier,
                        })
                      }
                      week={null}
                      month={null}
                      year={null} />
                  </Box>
                )
              })}
            </Grid>
          ))}
        </Grid>
      )} */}
    </Fragment>
  )
}
