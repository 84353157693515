import React from 'react'

import { ParentSize } from '@visx/responsive'

import { makeAppStyles } from '@percept/mui'

import { AnimationSurfaceProps } from './typings'


const useStyles = makeAppStyles({
  '@keyframes rotate': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  rotatingCog: {
    animation: '$rotate 5s linear infinite',
    transformBox: 'fill-box',
    transformOrigin: 'center',
  },
})


const viewBoxWidth = 828
const viewBoxHeight = 898
const ratio = viewBoxHeight / viewBoxWidth


type AnimationCogsLargeProps = {
  style?: React.CSSProperties
}

const AnimationSurface = ({
  height,
  style,
}: AnimationSurfaceProps & AnimationCogsLargeProps): JSX.Element => {

  const svgHeight = height
  const svgWidth = svgHeight * ratio

  const classes = useStyles()

  return (
    <svg
      x="0px"
      y="0px"
      width={svgWidth}
      height={svgHeight}
      viewBox="0 0 828 898"
      style={style}
      dangerouslySetInnerHTML={{
        __html: `
        <style type="text/css">
        .st0{opacity:0.4;fill:#CF372E;}
        .st1{enable-background:new    ;}
        .st2{clip-path:url(#SVGID_00000150807381046050269420000012324431413640586370_);enable-background:new    ;}
        .st3{fill:#6C1D18;}
        .st4{clip-path:url(#SVGID_00000165220992784795812660000003281869899933464250_);enable-background:new    ;}
        .st5{fill:#BA3229;}
        .st6{clip-path:url(#SVGID_00000124162089682115777000000015786288878560465847_);enable-background:new    ;}
        .st7{fill:#BE332A;}
        .st8{fill:#C3342B;}
        .st9{fill:#C7352C;}
        .st10{fill:#CB362D;}
        .st11{fill:#CF372E;}
        .st12{fill:#D54F47;}
        .st13{fill:#D75951;}
        .st14{fill:#D96159;}
        .st15{clip-path:url(#SVGID_00000111879335029053794560000000885361365428152219_);enable-background:new    ;}
        .st16{fill:#741F1A;}
        .st17{clip-path:url(#SVGID_00000135676998370912466490000000133139126544190889_);enable-background:new    ;}
        .st18{fill:#B63028;}
        .st19{clip-path:url(#SVGID_00000037683996911939836660000013485294349596785077_);enable-background:new    ;}
        .st20{fill:#B22F28;}
        .st21{fill:#AE2E27;}
        .st22{fill:#AA2D26;}
        .st23{fill:#A62C25;}
        .st24{fill:#A12B24;}
        .st25{fill:#9D2A23;}
        .st26{fill:#992922;}
        .st27{fill:#952821;}
        .st28{fill:#912720;}
        .st29{fill:#8D251F;}
        .st30{fill:#89241E;}
        .st31{fill:#84231D;}
        .st32{fill:#80221D;}
        .st33{fill:#7C211C;}
        .st34{fill:#78201B;}
        .st35{fill:#701E19;}
        .st36{clip-path:url(#SVGID_00000042719513604453800660000013879334184657505983_);enable-background:new    ;}
        .st37{clip-path:url(#SVGID_00000021806599308799487720000007528144971304074431_);enable-background:new    ;}
        .st38{clip-path:url(#SVGID_00000131336922972960488690000016813991408388984491_);enable-background:new    ;}
        .st39{clip-path:url(#SVGID_00000084522518562226710770000017562294522068596875_);enable-background:new    ;}
        .st40{clip-path:url(#SVGID_00000019648205800289281700000014567423939227173510_);enable-background:new    ;}
        .st41{clip-path:url(#SVGID_00000072975877849198408220000006839837645677397172_);enable-background:new    ;}
        .st42{clip-path:url(#SVGID_00000043418045621031365770000015882509209826410657_);enable-background:new    ;}
        .st43{clip-path:url(#SVGID_00000151520073095595094270000004226766513706538641_);enable-background:new    ;}
        .st44{clip-path:url(#SVGID_00000136407330060733822310000017098121666426351746_);enable-background:new    ;}
        .st45{clip-path:url(#SVGID_00000059289767928312768160000008693943187173214886_);enable-background:new    ;}
        .st46{clip-path:url(#SVGID_00000132796036881772831220000014501787674925777838_);enable-background:new    ;}
        .st47{clip-path:url(#SVGID_00000075853608417283315810000003702646186066827965_);enable-background:new    ;}
        .st48{clip-path:url(#SVGID_00000007391170846188381770000011746446351899530381_);enable-background:new    ;}
        .st49{clip-path:url(#SVGID_00000103951820944772965020000010659558437827801499_);enable-background:new    ;}
        .st50{clip-path:url(#SVGID_00000150785009973223012910000006487637739339437955_);enable-background:new    ;}
        .st51{fill:#DB6760;}
        .st52{fill:#DC6D66;}
        .st53{fill:#DD726B;}
        .st54{clip-path:url(#SVGID_00000075879594245374089900000001888179921030049936_);enable-background:new    ;}
        .st55{clip-path:url(#SVGID_00000047053523278357143180000005633069175452705198_);enable-background:new    ;}
        .st56{clip-path:url(#SVGID_00000051363745435071642760000006156113873794864021_);enable-background:new    ;}
        .st57{clip-path:url(#SVGID_00000053545036674368592550000012034796005608998833_);enable-background:new    ;}
        .st58{clip-path:url(#SVGID_00000040563329951261258330000004872530863942674093_);enable-background:new    ;}
        .st59{clip-path:url(#SVGID_00000021803980613849881800000014899263456844449444_);enable-background:new    ;}
        .st60{clip-path:url(#SVGID_00000085971963165127148590000013020043102079937710_);enable-background:new    ;}
        .st61{clip-path:url(#SVGID_00000124882704847638170780000006168552782466485909_);enable-background:new    ;}
        .st62{clip-path:url(#SVGID_00000088106037888598870360000011005151360383243681_);enable-background:new    ;}
        .st63{fill:#DE7670;}
        .st64{fill:#DF7B75;}
        .st65{fill:#E07F79;}
        .st66{fill:#E1837D;}
        .st67{fill:#E28781;}
        .st68{fill:#E38A85;}
        .st69{fill:#E48E88;}
        .st70{fill:#E5918C;}
        .st71{fill:#E5948F;}
        .st72{fill:#E69792;}
        .st73{fill:#E79A95;}
        .st74{fill:#E79D98;}
        .st75{fill:#E8A09B;}
        .st76{fill:#E9A29E;}
        .st77{fill:#E9A5A1;}
        .st78{fill:#EAA8A4;}
        .st79{clip-path:url(#SVGID_00000134948029204381302970000007471799861895902106_);enable-background:new    ;}
        .st80{clip-path:url(#SVGID_00000037690772590554051010000010124199103422302880_);enable-background:new    ;}
        .st81{clip-path:url(#SVGID_00000147184038978685491220000005995793355896979602_);enable-background:new    ;}
        .st82{clip-path:url(#SVGID_00000111152140992752319580000008919162814950877105_);enable-background:new    ;}
        .st83{clip-path:url(#SVGID_00000118358052730165513470000007700725785214129844_);enable-background:new    ;}
        .st84{clip-path:url(#SVGID_00000134956759524020614220000011864152209945061807_);enable-background:new    ;}
        .st85{clip-path:url(#SVGID_00000095327670586429945440000006412041591533608612_);enable-background:new    ;}
        .st86{clip-path:url(#SVGID_00000152234816228008481280000016604033964112107919_);enable-background:new    ;}
        .st87{clip-path:url(#SVGID_00000022529403024194356520000012795030446489346205_);enable-background:new    ;}
        .st88{clip-path:url(#SVGID_00000068679479595476788240000012474452702844950710_);enable-background:new    ;}
        .st89{clip-path:url(#SVGID_00000015337306277899544490000008649258162408132004_);enable-background:new    ;}
        .st90{clip-path:url(#SVGID_00000130638257675656517010000006392043637522117519_);enable-background:new    ;}
        .st91{clip-path:url(#SVGID_00000005257727607672284110000010018409788722025375_);enable-background:new    ;}
        .st92{clip-path:url(#SVGID_00000164505853876948794310000015384593713157252240_);enable-background:new    ;}
        .st93{clip-path:url(#SVGID_00000159438352992765319450000018049944286286818706_);enable-background:new    ;}
        .st94{clip-path:url(#SVGID_00000058580907337911063750000007014148989423412924_);enable-background:new    ;}
        .st95{clip-path:url(#SVGID_00000154388500014812824240000014331683367298377099_);enable-background:new    ;}
        .st96{clip-path:url(#SVGID_00000099637513847937350550000010259532465970119077_);enable-background:new    ;}
        .st97{clip-path:url(#SVGID_00000085212483671502798780000008225380052096374924_);enable-background:new    ;}
        .st98{clip-path:url(#SVGID_00000029028275206888542290000017687327524220499871_);enable-background:new    ;}
        .st99{clip-path:url(#SVGID_00000030462606477628079710000011155780756833060255_);enable-background:new    ;}
        .st100{clip-path:url(#SVGID_00000093858626606000329190000013082656319976726195_);enable-background:new    ;}
        .st101{clip-path:url(#SVGID_00000023262559617283289730000012409724644535807381_);enable-background:new    ;}
        .st102{clip-path:url(#SVGID_00000102506447558279260930000003528041166761249171_);enable-background:new    ;}
        .st103{clip-path:url(#SVGID_00000137844557892197240040000002919874552758474406_);enable-background:new    ;}
        .st104{clip-path:url(#SVGID_00000009565773868002888980000003902621416505363852_);enable-background:new    ;}
        .st105{clip-path:url(#SVGID_00000003098737544596904520000011883434140156225962_);enable-background:new    ;}
        .st106{clip-path:url(#SVGID_00000172431350293030937940000017216324585321707174_);enable-background:new    ;}
        .st107{clip-path:url(#SVGID_00000101075991490957222640000006844839351791113661_);enable-background:new    ;}
        .st108{clip-path:url(#SVGID_00000160893620461145181750000014467441770420666249_);enable-background:new    ;}
        .st109{clip-path:url(#SVGID_00000119101851299030387420000017551130506795070353_);enable-background:new    ;}
        .st110{clip-path:url(#SVGID_00000011013926315923607660000001215958709063990447_);enable-background:new    ;}
        .st111{clip-path:url(#SVGID_00000106851370271004583420000017355017766404041890_);enable-background:new    ;}
        .st112{clip-path:url(#SVGID_00000159434583713525598230000013759977176007502260_);enable-background:new    ;}
        .st113{clip-path:url(#SVGID_00000149345545776636755060000004228489327389035667_);enable-background:new    ;}
        .st114{clip-path:url(#SVGID_00000129196742385602490930000011598152421300013499_);enable-background:new    ;}
        .st115{clip-path:url(#SVGID_00000106869454760051825510000011289693798214979982_);enable-background:new    ;}
        .st116{clip-path:url(#SVGID_00000150811610638749166860000008754672389477017765_);enable-background:new    ;}
        .st117{clip-path:url(#SVGID_00000060016115108125141000000004876101787983651484_);enable-background:new    ;}
        .st118{clip-path:url(#SVGID_00000100362347357036934580000008337316415657372342_);enable-background:new    ;}
        .st119{clip-path:url(#SVGID_00000001662367568139432830000013456391288221305736_);enable-background:new    ;}
        .st120{clip-path:url(#SVGID_00000115500980078729541270000000435451642143882646_);enable-background:new    ;}
        .st121{clip-path:url(#SVGID_00000130618419456604295730000014162954824788205738_);enable-background:new    ;}
        .st122{clip-path:url(#SVGID_00000169531672761703122730000004977109085394543757_);enable-background:new    ;}
        .st123{clip-path:url(#SVGID_00000162314774529756243540000011953913744013905565_);enable-background:new    ;}
        .st124{clip-path:url(#SVGID_00000117665497614941336300000002446670293286862767_);enable-background:new    ;}
        .st125{clip-path:url(#SVGID_00000075866409470344118640000002694840880767335320_);enable-background:new    ;}
        .st126{clip-path:url(#SVGID_00000040568935518598111020000012812176329751899782_);enable-background:new    ;}
        .st127{clip-path:url(#SVGID_00000044880987805172368970000001790840733261043901_);enable-background:new    ;}
        .st128{clip-path:url(#SVGID_00000030489340180443213520000012628951984521377418_);enable-background:new    ;}
        .st129{clip-path:url(#SVGID_00000142881933770054400470000010211983210517285260_);enable-background:new    ;}
        .st130{clip-path:url(#SVGID_00000123415084606856199570000010616568217921187236_);enable-background:new    ;}
        .st131{clip-path:url(#SVGID_00000183215406159353096580000014658922489424305047_);enable-background:new    ;}
        .st132{clip-path:url(#SVGID_00000064320821194550099160000015257429412160908960_);enable-background:new    ;}
        .st133{clip-path:url(#SVGID_00000140708833842000184780000012913311041496826774_);enable-background:new    ;}
        .st134{clip-path:url(#SVGID_00000124840361623681682060000018016308051728167320_);enable-background:new    ;}
        .st135{clip-path:url(#SVGID_00000151543571021091533820000018323857502676744862_);enable-background:new    ;}
        .st136{clip-path:url(#SVGID_00000042728984414327284820000005710973632325370300_);enable-background:new    ;}
        .st137{clip-path:url(#SVGID_00000159444875101270279180000015002745493398922892_);enable-background:new    ;}
        .st138{clip-path:url(#SVGID_00000063627645947234865900000009700664628603313848_);enable-background:new    ;}
        .st139{clip-path:url(#SVGID_00000075853698599930001220000014442169100571993502_);enable-background:new    ;}
        .st140{clip-path:url(#SVGID_00000085970658560688072250000016599875175949823653_);enable-background:new    ;}
        .st141{clip-path:url(#SVGID_00000042013627844556155310000001259427149279134618_);enable-background:new    ;}
        .st142{clip-path:url(#SVGID_00000147200798698705599970000015378977693545296569_);enable-background:new    ;}
        .st143{clip-path:url(#SVGID_00000000921916265278620430000014197819417578317957_);enable-background:new    ;}
        .st144{clip-path:url(#SVGID_00000011005517232329712580000004034013842311599759_);enable-background:new    ;}
        .st145{clip-path:url(#SVGID_00000166664203650703735940000007022898713311194551_);enable-background:new    ;}
        .st146{clip-path:url(#SVGID_00000170998361481326729280000016263076284092128386_);enable-background:new    ;}
        .st147{fill:#B32F28;}
        .st148{clip-path:url(#SVGID_00000052824446303012163980000003194518824010235805_);enable-background:new    ;}
        .st149{clip-path:url(#SVGID_00000009558094260088391560000002678522600173365929_);enable-background:new    ;}
        .st150{clip-path:url(#SVGID_00000047037399923102137120000014770083172217478333_);enable-background:new    ;}
        .st151{clip-path:url(#SVGID_00000026865464304546585730000003661030195181928874_);enable-background:new    ;}
        .st152{clip-path:url(#SVGID_00000120528144051834679610000003289367559344299670_);enable-background:new    ;}
        .st153{clip-path:url(#SVGID_00000178902832333431426040000017538611145866890408_);enable-background:new    ;}
        .st154{clip-path:url(#SVGID_00000129171094876345113310000011621398109256720533_);enable-background:new    ;}
        .st155{clip-path:url(#SVGID_00000057838126474068189730000018150971862810182299_);enable-background:new    ;}
        .st156{clip-path:url(#SVGID_00000023961355723560248620000007535192773477366684_);enable-background:new    ;}
        .st157{clip-path:url(#SVGID_00000118378002605879694550000006975468516689991041_);enable-background:new    ;}
        .st158{clip-path:url(#SVGID_00000060709615324441602650000003588751423479177614_);enable-background:new    ;}
        .st159{clip-path:url(#SVGID_00000093178920885409186790000018278609141837216667_);enable-background:new    ;}
        .st160{clip-path:url(#SVGID_00000130646370438307949280000000807464017007978650_);enable-background:new    ;}
        .st161{clip-path:url(#SVGID_00000008148294827077815230000006948838359565908662_);enable-background:new    ;}
        .st162{clip-path:url(#SVGID_00000128487420531859381080000007102000155613877129_);enable-background:new    ;}
        .st163{clip-path:url(#SVGID_00000128470326400957843360000015528677229703740086_);enable-background:new    ;}
        .st164{clip-path:url(#SVGID_00000029764582524817006790000003710245621130461570_);enable-background:new    ;}
        .st165{clip-path:url(#SVGID_00000061439872267021009850000011226598340985447340_);enable-background:new    ;}
        .st166{clip-path:url(#SVGID_00000041279819911123975870000011416952855439825070_);enable-background:new    ;}
        .st167{clip-path:url(#SVGID_00000041275988860195724940000005819881773619820672_);enable-background:new    ;}
        .st168{clip-path:url(#SVGID_00000148661765144810366850000014022231217935995795_);enable-background:new    ;}
        .st169{clip-path:url(#SVGID_00000097495600260068330200000007420125528223964045_);enable-background:new    ;}
        .st170{clip-path:url(#SVGID_00000181056354516854874220000009391164922666628486_);enable-background:new    ;}
        .st171{clip-path:url(#SVGID_00000096748080409392608480000007052363040162721212_);enable-background:new    ;}
        .st172{clip-path:url(#SVGID_00000109733252406203949020000014297602219193558972_);enable-background:new    ;}
        .st173{clip-path:url(#SVGID_00000081621019297740960090000001372372673958934418_);enable-background:new    ;}
        .st174{clip-path:url(#SVGID_00000137822604592470238780000012193311814493891480_);enable-background:new    ;}
        .st175{clip-path:url(#SVGID_00000037693619719552457040000010378788826238279847_);enable-background:new    ;}
        .st176{clip-path:url(#SVGID_00000036949875070944583690000011471546481790811298_);enable-background:new    ;}
        .st177{clip-path:url(#SVGID_00000097469418561778064320000007162749644615799174_);enable-background:new    ;}
        .st178{clip-path:url(#SVGID_00000147942326642915644390000005158520283240001412_);enable-background:new    ;}
        .st179{clip-path:url(#SVGID_00000132068592744223074490000006416519235483378622_);enable-background:new    ;}
        .st180{clip-path:url(#SVGID_00000048478621440043164570000011509108956970682789_);enable-background:new    ;}
        .st181{clip-path:url(#SVGID_00000024680689012053785910000002539197303620398486_);enable-background:new    ;}
        .st182{clip-path:url(#SVGID_00000082370302160894363290000006091842330892527530_);enable-background:new    ;}
        .st183{clip-path:url(#SVGID_00000151515205078492006150000010615160224042861977_);enable-background:new    ;}
        .st184{clip-path:url(#SVGID_00000008140143824990573940000014328383544604715197_);enable-background:new    ;}
        .st185{clip-path:url(#SVGID_00000178179122636341642810000006182676218074972863_);enable-background:new    ;}
        .st186{clip-path:url(#SVGID_00000044892886869884540230000016321037603478696869_);enable-background:new    ;}
        .st187{clip-path:url(#SVGID_00000052078801301936713670000018013005338129068222_);enable-background:new    ;}
        .st188{clip-path:url(#SVGID_00000105400964233887876870000002461884078611063223_);enable-background:new    ;}
        .st189{clip-path:url(#SVGID_00000133504023263464650400000004821016097657057928_);enable-background:new    ;}
        .st190{clip-path:url(#SVGID_00000178899845268286052970000008776065354645713025_);enable-background:new    ;}
        .st191{clip-path:url(#SVGID_00000129180252422297848450000017940041624703173295_);enable-background:new    ;}
        .st192{clip-path:url(#SVGID_00000154394751847271938530000005352180352273000343_);enable-background:new    ;}
        .st193{clip-path:url(#SVGID_00000167354528671005974290000014427343176121810842_);enable-background:new    ;}
        .st194{clip-path:url(#SVGID_00000180346347302591701150000007390161214530838418_);enable-background:new    ;}
        .st195{clip-path:url(#SVGID_00000109021684863326125060000014102763933912202125_);enable-background:new    ;}
        .st196{clip-path:url(#SVGID_00000094599801608961969590000002141024015356255670_);enable-background:new    ;}
        .st197{clip-path:url(#SVGID_00000101083074495922676830000004217671195441812865_);enable-background:new    ;}
        .st198{clip-path:url(#SVGID_00000005259644373263881010000007452269903023596703_);enable-background:new    ;}
        .st199{clip-path:url(#SVGID_00000092452573098522142330000015059112890830658435_);enable-background:new    ;}
        .st200{clip-path:url(#SVGID_00000088849365521915774020000011085766576499975071_);enable-background:new    ;}
        .st201{clip-path:url(#SVGID_00000111893198852619351790000008899649006428525503_);enable-background:new    ;}
        .st202{clip-path:url(#SVGID_00000045597796942393668410000002379006105724153773_);enable-background:new    ;}
        .st203{clip-path:url(#SVGID_00000078744974072428024330000011288377570853241013_);enable-background:new    ;}
        .st204{clip-path:url(#SVGID_00000124876240375491234120000016378049876528284826_);enable-background:new    ;}
        .st205{clip-path:url(#SVGID_00000160885093109964253160000018297895868740418433_);enable-background:new    ;}
        .st206{clip-path:url(#SVGID_00000050630564963545530830000016598051723730840217_);enable-background:new    ;}
        .st207{clip-path:url(#SVGID_00000074429390341433108440000016032725842031683984_);enable-background:new    ;}
        .st208{clip-path:url(#SVGID_00000004517367415553538870000002497556002283721383_);enable-background:new    ;}
        .st209{clip-path:url(#SVGID_00000167387185663597433190000018357078798380693891_);enable-background:new    ;}
        .st210{clip-path:url(#SVGID_00000173144993428948338580000011761292418388749472_);enable-background:new    ;}
        .st211{clip-path:url(#SVGID_00000130642507426192454150000004940978004450945215_);enable-background:new    ;}
        .st212{clip-path:url(#SVGID_00000131335495630869751940000015670725416120327053_);enable-background:new    ;}
        .st213{clip-path:url(#SVGID_00000051373178855246088450000004856309529065782437_);enable-background:new    ;}
        .st214{clip-path:url(#SVGID_00000103960229005902952880000016192780855961791406_);enable-background:new    ;}
        .st215{clip-path:url(#SVGID_00000010991029447164385190000013440880051907338412_);enable-background:new    ;}
        .st216{clip-path:url(#SVGID_00000132803280476519509860000014137672913089981077_);enable-background:new    ;}
        .st217{clip-path:url(#SVGID_00000119111794445729927140000003044611410809884048_);enable-background:new    ;}
        .st218{clip-path:url(#SVGID_00000057140155583290033710000004337120460619538851_);enable-background:new    ;}
        .st219{clip-path:url(#SVGID_00000176743627282110895030000007131219202453260442_);enable-background:new    ;}
        .st220{clip-path:url(#SVGID_00000030446975954959221810000007564134994863352485_);enable-background:new    ;}
        .st221{clip-path:url(#SVGID_00000116922793723102868550000017264155279180553910_);enable-background:new    ;}
        .st222{clip-path:url(#SVGID_00000136371910693292943830000007605391382575820455_);enable-background:new    ;}
        .st223{clip-path:url(#SVGID_00000167393401938659005040000014699479230681190579_);enable-background:new    ;}
        .st224{clip-path:url(#SVGID_00000034086749372357462770000013614480169378916026_);enable-background:new    ;}
        .st225{clip-path:url(#SVGID_00000156586241317791231740000012832337056129418128_);enable-background:new    ;}
        .st226{clip-path:url(#SVGID_00000071553478750362013130000014263577367493696189_);enable-background:new    ;}
        .st227{clip-path:url(#SVGID_00000026867814352755923540000005123722625002193836_);enable-background:new    ;}
        .st228{clip-path:url(#SVGID_00000031928330022520222850000006857298104621725848_);enable-background:new    ;}
        .st229{clip-path:url(#SVGID_00000077310189010727713700000006793464892915673757_);enable-background:new    ;}
        .st230{clip-path:url(#SVGID_00000098921641121743200470000015379323745127095222_);enable-background:new    ;}
        .st231{clip-path:url(#SVGID_00000097464647574488715240000007276504709711041706_);enable-background:new    ;}
        .st232{clip-path:url(#SVGID_00000118359651155437190350000000409856541161310125_);enable-background:new    ;}
        .st233{clip-path:url(#SVGID_00000178887113470735821580000005406091901459038904_);enable-background:new    ;}
        .st234{clip-path:url(#SVGID_00000136383207938053623670000003728022460725975980_);enable-background:new    ;}
        .st235{clip-path:url(#SVGID_00000168798203404092523790000009779649517149151885_);enable-background:new    ;}
        .st236{clip-path:url(#SVGID_00000068646723015832801880000006196258589542581899_);enable-background:new    ;}
        .st237{clip-path:url(#SVGID_00000038400875246405450340000010361967427678102418_);enable-background:new    ;}
        .st238{clip-path:url(#SVGID_00000035511673843432508000000017565154166962767525_);enable-background:new    ;}
        .st239{clip-path:url(#SVGID_00000060715944822228678070000005745827846337372063_);enable-background:new    ;}
        .st240{clip-path:url(#SVGID_00000152255088257091383700000005126213127420522137_);enable-background:new    ;}
        .st241{clip-path:url(#SVGID_00000003790844697090363210000001153766812656028599_);enable-background:new    ;}
        .st242{clip-path:url(#SVGID_00000173126460028742545180000001634206647390393757_);enable-background:new    ;}
        .st243{clip-path:url(#SVGID_00000146461607002018575760000006793360028406704789_);enable-background:new    ;}
        .st244{clip-path:url(#SVGID_00000148642698009194414340000012521135158870725792_);enable-background:new    ;}
        .st245{clip-path:url(#SVGID_00000173163348989716787700000012028696407357580441_);enable-background:new    ;}
        .st246{clip-path:url(#SVGID_00000001634990137932071940000018367518614956515989_);enable-background:new    ;}
        .st247{clip-path:url(#SVGID_00000112633864175829666340000009162701392908789417_);enable-background:new    ;}
        .st248{clip-path:url(#SVGID_00000182487036254485136880000004089133909870269362_);enable-background:new    ;}
        .st249{clip-path:url(#SVGID_00000167361255436530647010000008750826735467936402_);enable-background:new    ;}
        .st250{clip-path:url(#SVGID_00000018210040035055089900000010802299452956608653_);enable-background:new    ;}
        .st251{clip-path:url(#SVGID_00000028295066233061926170000018038343131085872533_);enable-background:new    ;}
        .st252{clip-path:url(#SVGID_00000016048593478010957930000001646621315807893903_);enable-background:new    ;}
        .st253{clip-path:url(#SVGID_00000158707052897867190070000000846186838533888395_);enable-background:new    ;}
        .st254{clip-path:url(#SVGID_00000128464465178059071350000010365636460552607385_);enable-background:new    ;}
        .st255{clip-path:url(#SVGID_00000123441408377223352290000010978458475687117955_);enable-background:new    ;}
        .st256{clip-path:url(#SVGID_00000127044716567431397400000005428982742069029783_);enable-background:new    ;}
        .st257{clip-path:url(#SVGID_00000066516535022983746400000010600764453159508101_);enable-background:new    ;}
        .st258{clip-path:url(#SVGID_00000026844764964358114500000005065919039007133101_);enable-background:new    ;}
        .st259{clip-path:url(#SVGID_00000070810350405631824920000015986530148180594083_);enable-background:new    ;}
        .st260{clip-path:url(#SVGID_00000183248666041776616030000012776899712668598933_);enable-background:new    ;}
        .st261{clip-path:url(#SVGID_00000173124474096385840800000016542634069959903366_);enable-background:new    ;}
        .st262{clip-path:url(#SVGID_00000111154203571546701710000008628631136297405081_);enable-background:new    ;}
        .st263{clip-path:url(#SVGID_00000158003534193910273100000005548717195340638593_);enable-background:new    ;}
        .st264{clip-path:url(#SVGID_00000062872275412135649530000013537575159356733349_);enable-background:new    ;}
        .st265{clip-path:url(#SVGID_00000096022792589876185070000016296031310118690988_);enable-background:new    ;}
        .st266{clip-path:url(#SVGID_00000163039984299662436540000006302584375364614049_);enable-background:new    ;}
        .st267{clip-path:url(#SVGID_00000100344563394223944830000008248468364321926829_);enable-background:new    ;}
        .st268{clip-path:url(#SVGID_00000175316194902796263550000001970312415039960481_);enable-background:new    ;}
        .st269{clip-path:url(#SVGID_00000079450448281952251690000001330578805710268568_);enable-background:new    ;}
        .st270{clip-path:url(#SVGID_00000095321817229539628390000005290360212960121501_);enable-background:new    ;}
        .st271{clip-path:url(#SVGID_00000040566744324834571260000002341471580652099752_);enable-background:new    ;}
        .st272{clip-path:url(#SVGID_00000003802442323160841810000016607875999950281114_);enable-background:new    ;}
        .st273{clip-path:url(#SVGID_00000055670781302389628270000000615652040616707990_);enable-background:new    ;}
        .st274{clip-path:url(#SVGID_00000074427178948600934350000016527677732697177227_);enable-background:new    ;}
        .st275{clip-path:url(#SVGID_00000031914152375999441570000011199877730078816685_);enable-background:new    ;}
        .st276{clip-path:url(#SVGID_00000129169757331632570840000007431848097403877277_);enable-background:new    ;}
        .st277{clip-path:url(#SVGID_00000167383267184290861830000014821652259364304792_);enable-background:new    ;}
        .st278{clip-path:url(#SVGID_00000001659831502582534890000005468249446091446433_);enable-background:new    ;}
        .st279{clip-path:url(#SVGID_00000106867456764659154850000005644695803241387665_);enable-background:new    ;}
        .st280{clip-path:url(#SVGID_00000181079239337000233840000000531306119766467458_);enable-background:new    ;}
        .st281{clip-path:url(#SVGID_00000173140914352118815110000016928379425686165679_);enable-background:new    ;}
        .st282{clip-path:url(#SVGID_00000181769790663875902890000012899740888535799449_);enable-background:new    ;}
        .st283{clip-path:url(#SVGID_00000072259457454074383090000006641782698152490135_);enable-background:new    ;}
        .st284{clip-path:url(#SVGID_00000003798006527986208050000007315711692293142713_);enable-background:new    ;}
        .st285{clip-path:url(#SVGID_00000012438794087258581260000013576885329029922993_);enable-background:new    ;}
        .st286{clip-path:url(#SVGID_00000115479306216064600830000005546911694276069802_);enable-background:new    ;}
        .st287{clip-path:url(#SVGID_00000168082998505591017210000004072084126477959299_);enable-background:new    ;}
        .st288{clip-path:url(#SVGID_00000123410039480105366910000015524422525049001858_);enable-background:new    ;}
        .st289{clip-path:url(#SVGID_00000044162899869645450090000013426583882713981058_);enable-background:new    ;}
        .st290{clip-path:url(#SVGID_00000029039942003060349420000018142319370332944575_);enable-background:new    ;}
        .st291{clip-path:url(#SVGID_00000019651818205003038610000015734949041743046027_);enable-background:new    ;}
        .st292{clip-path:url(#SVGID_00000080918816680009006120000018199891342924140216_);enable-background:new    ;}
        .st293{clip-path:url(#SVGID_00000000920031302487326580000012358379409296588430_);enable-background:new    ;}
        .st294{clip-path:url(#SVGID_00000129890341205043304700000018258456908125895605_);enable-background:new    ;}
        .st295{clip-path:url(#SVGID_00000090987238276684935160000015649625113676376980_);enable-background:new    ;}
        .st296{clip-path:url(#SVGID_00000161615768521534940170000001243116236647740578_);enable-background:new    ;}
        .st297{clip-path:url(#SVGID_00000150094855353508690770000013961132081161721003_);enable-background:new    ;}
        .st298{clip-path:url(#SVGID_00000124147368498802340350000009858590861884469426_);enable-background:new    ;}
        .st299{clip-path:url(#SVGID_00000005264916380531645300000013755822186360594107_);enable-background:new    ;}
        .st300{clip-path:url(#SVGID_00000047051411926841846160000003924768644132078751_);enable-background:new    ;}
        .st301{clip-path:url(#SVGID_00000031925858691054367780000002296747874626135687_);enable-background:new    ;}
        .st302{clip-path:url(#SVGID_00000089558086563784653570000007006091071298894015_);enable-background:new    ;}
        .st303{clip-path:url(#SVGID_00000174590626754060584430000010520349989236809125_);enable-background:new    ;}
        .st304{clip-path:url(#SVGID_00000010290080414558417270000008943655022706445733_);enable-background:new    ;}
        .st305{clip-path:url(#SVGID_00000163039678694252083030000006478760596926559636_);enable-background:new    ;}
        .st306{clip-path:url(#SVGID_00000031895613306653309630000009582260480065928631_);enable-background:new    ;}
        .st307{clip-path:url(#SVGID_00000086693440724099490670000016538558266936856213_);enable-background:new    ;}
        .st308{clip-path:url(#SVGID_00000134241020846681282710000009135170828390461058_);enable-background:new    ;}
        .st309{clip-path:url(#SVGID_00000078751432842565764750000011203447801890611349_);enable-background:new    ;}
        .st310{clip-path:url(#SVGID_00000096034497190585531480000003245244820596161183_);enable-background:new    ;}
        .st311{clip-path:url(#SVGID_00000026142648197263687080000011920828392541449914_);enable-background:new    ;}
        .st312{clip-path:url(#SVGID_00000152955601211515781780000007866899593360253356_);enable-background:new    ;}
        .st313{clip-path:url(#SVGID_00000134931757224226058640000014355739079610524606_);enable-background:new    ;}
        .st314{clip-path:url(#SVGID_00000147907137035509316710000007753890689804069012_);enable-background:new    ;}
        .st315{clip-path:url(#SVGID_00000091017212994132322510000001491428362865852076_);enable-background:new    ;}
        .st316{clip-path:url(#SVGID_00000053527815582318916330000015374096141062330009_);enable-background:new    ;}
        .st317{clip-path:url(#SVGID_00000093856665317879185170000011718065358388188049_);enable-background:new    ;}
        .st318{clip-path:url(#SVGID_00000122001862520881122730000009806458508097114292_);enable-background:new    ;}
        .st319{clip-path:url(#SVGID_00000154422485560029238510000003306715267281609915_);enable-background:new    ;}
        .st320{clip-path:url(#SVGID_00000163055171540404335930000006622316571670834335_);enable-background:new    ;}
        .st321{clip-path:url(#SVGID_00000030475368253177909810000002989762690248231079_);enable-background:new    ;}
        .st322{clip-path:url(#SVGID_00000114069164276579434020000006274338844006074023_);enable-background:new    ;}
        .st323{clip-path:url(#SVGID_00000075855086024020824180000008495090887738342540_);enable-background:new    ;}
        .st324{clip-path:url(#SVGID_00000160172343717701147740000012100193203874953626_);enable-background:new    ;}
        .st325{clip-path:url(#SVGID_00000143616284372790228600000015041683027005219221_);enable-background:new    ;}
        .st326{clip-path:url(#SVGID_00000003077522003183565930000001590179100905204156_);enable-background:new    ;}
        .st327{clip-path:url(#SVGID_00000119114524625269211170000009451488169960597677_);enable-background:new    ;}
        .st328{clip-path:url(#SVGID_00000038408177664981173980000017994522187574589847_);enable-background:new    ;}
        .st329{clip-path:url(#SVGID_00000181782122648466218220000014168391824338919598_);enable-background:new    ;}
        .st330{clip-path:url(#SVGID_00000170981655442683021260000012988005393823378822_);enable-background:new    ;}
        .st331{clip-path:url(#SVGID_00000043429653058997741560000004230104809890419117_);enable-background:new    ;}
        .st332{clip-path:url(#SVGID_00000002374663920557725910000002071941452697679284_);enable-background:new    ;}
        .st333{clip-path:url(#SVGID_00000170985460187588628330000008828980047942934696_);enable-background:new    ;}
        .st334{clip-path:url(#SVGID_00000153665128220509372590000007501750354813320884_);enable-background:new    ;}
        .st335{clip-path:url(#SVGID_00000031899802825797450950000000568597406318785452_);enable-background:new    ;}
        .st336{clip-path:url(#SVGID_00000176008361353513504430000004150283942086866050_);enable-background:new    ;}
        .st337{clip-path:url(#SVGID_00000160170662871803723580000017343646439279417002_);enable-background:new    ;}
        .st338{clip-path:url(#SVGID_00000170975760751843332070000001312233617639681417_);enable-background:new    ;}
        .st339{clip-path:url(#SVGID_00000069386276310150520810000000493732599480355986_);enable-background:new    ;}
        .st340{clip-path:url(#SVGID_00000036241309514366030570000018150838728651656353_);enable-background:new    ;}
        .st341{clip-path:url(#SVGID_00000002382008565065308630000006828989714429769602_);enable-background:new    ;}
        .st342{clip-path:url(#SVGID_00000013881441935446736630000018426548913970156178_);enable-background:new    ;}
        .st343{clip-path:url(#SVGID_00000052106121294284986850000003410265713335254186_);enable-background:new    ;}
        .st344{clip-path:url(#SVGID_00000136392721778961038550000000318778803984675721_);enable-background:new    ;}
        .st345{clip-path:url(#SVGID_00000179615781596204864540000016662892580332169875_);enable-background:new    ;}
        .st346{clip-path:url(#SVGID_00000130644867665180373230000000794849310571833279_);enable-background:new    ;}
        .st347{clip-path:url(#SVGID_00000158736621177869814510000012460448602020812952_);enable-background:new    ;}
        .st348{clip-path:url(#SVGID_00000087395624801812421580000009066608731923600815_);enable-background:new    ;}
        .st349{clip-path:url(#SVGID_00000080929350956632729550000001233433061569950347_);enable-background:new    ;}
        .st350{clip-path:url(#SVGID_00000128462451895980067070000006034285186638643332_);enable-background:new    ;}
        .st351{clip-path:url(#SVGID_00000054237324934306866590000017867493137312601735_);enable-background:new    ;}
        .st352{clip-path:url(#SVGID_00000171000748437673848670000004647027907143860886_);enable-background:new    ;}
        .st353{clip-path:url(#SVGID_00000115496446787412995410000017964719275945547439_);enable-background:new    ;}
        .st354{clip-path:url(#SVGID_00000080912260325728167910000009752400593855510676_);enable-background:new    ;}
        .st355{clip-path:url(#SVGID_00000109733146581871837540000006556213057983525021_);enable-background:new    ;}
        .st356{clip-path:url(#SVGID_00000052785926587865882680000004347909547183288255_);enable-background:new    ;}
        .st357{clip-path:url(#SVGID_00000028295927013996122100000005283135407089482377_);enable-background:new    ;}
        .st358{clip-path:url(#SVGID_00000085959060968021602350000013281126757143279039_);enable-background:new    ;}
        .st359{clip-path:url(#SVGID_00000060744297922736028870000018123445580927218334_);enable-background:new    ;}
        .st360{clip-path:url(#SVGID_00000004513274685131981160000013610014566694462592_);enable-background:new    ;}
        .st361{clip-path:url(#SVGID_00000047746663027410076090000006765905713249768894_);enable-background:new    ;}
        .st362{clip-path:url(#SVGID_00000065044516038087051600000016842596317514312106_);enable-background:new    ;}
        .st363{clip-path:url(#SVGID_00000020368322999548756900000015571082342441823665_);enable-background:new    ;}
        .st364{clip-path:url(#SVGID_00000106144285793620933420000007749372978861272968_);enable-background:new    ;}
        .st365{clip-path:url(#SVGID_00000153695468414273766990000008421518743794963376_);enable-background:new    ;}
        .st366{clip-path:url(#SVGID_00000084520630330806499200000007152689763949405083_);enable-background:new    ;}
        .st367{clip-path:url(#SVGID_00000030465639839651385330000009229843283750059189_);enable-background:new    ;}
        .st368{clip-path:url(#SVGID_00000173851419833043942260000017429817514404817837_);enable-background:new    ;}
        .st369{clip-path:url(#SVGID_00000083779446747600143370000004978453038473334144_);enable-background:new    ;}
        .st370{clip-path:url(#SVGID_00000103256176092610107550000009548355941895954335_);enable-background:new    ;}
        .st371{clip-path:url(#SVGID_00000174568473258192890910000008575558963766796975_);enable-background:new    ;}
        .st372{clip-path:url(#SVGID_00000056391020137937605260000009993454218276350877_);enable-background:new    ;}
        .st373{clip-path:url(#SVGID_00000167386691534774183160000008670292333368173221_);enable-background:new    ;}
        .st374{clip-path:url(#SVGID_00000170243352144171928430000001348534650720571558_);enable-background:new    ;}
        .st375{clip-path:url(#SVGID_00000143592270200049507900000008312853728149132472_);enable-background:new    ;}
        .st376{clip-path:url(#SVGID_00000094611597310108257580000009021866039477760689_);enable-background:new    ;}
        .st377{clip-path:url(#SVGID_00000166672712845867053330000005799219138336876710_);enable-background:new    ;}
        .st378{clip-path:url(#SVGID_00000158006695212921107810000014504448035487448471_);enable-background:new    ;}
        .st379{clip-path:url(#SVGID_00000052797474450863264600000016885176628657973145_);enable-background:new    ;}
        .st380{clip-path:url(#SVGID_00000117640534219262733370000002121723816259504514_);enable-background:new    ;}
        .st381{clip-path:url(#SVGID_00000054981369601299806590000006330489153124110475_);enable-background:new    ;}
        .st382{clip-path:url(#SVGID_00000169535482055581469630000001600194221069485192_);enable-background:new    ;}
        .st383{clip-path:url(#SVGID_00000031927647308343688910000006051959946003165106_);enable-background:new    ;}
        .st384{clip-path:url(#SVGID_00000134247993654773542980000006994418190147130036_);enable-background:new    ;}
        .st385{clip-path:url(#SVGID_00000059295492296632946300000012437795335780584593_);enable-background:new    ;}
        .st386{clip-path:url(#SVGID_00000042013429508616757000000011880860647839014076_);enable-background:new    ;}
        .st387{clip-path:url(#SVGID_00000150085854571578239100000000179627136410087062_);enable-background:new    ;}
        .st388{clip-path:url(#SVGID_00000049221814161732744120000007829001674893625238_);enable-background:new    ;}
        .st389{clip-path:url(#SVGID_00000121995089402406753440000014211957396846415240_);enable-background:new    ;}
        .st390{clip-path:url(#SVGID_00000108292585789188193880000008213666223549656450_);enable-background:new    ;}
        .st391{clip-path:url(#SVGID_00000180350940703937909580000013573102780002649004_);enable-background:new    ;}
        .st392{clip-path:url(#SVGID_00000018926392202416559020000008715837656954815123_);enable-background:new    ;}
        .st393{clip-path:url(#SVGID_00000031181042338813818110000009330980975109431430_);enable-background:new    ;}
        .st394{clip-path:url(#SVGID_00000085956701533732916590000012891071557955279032_);enable-background:new    ;}
        .st395{clip-path:url(#SVGID_00000049915906776897084590000008667348559170566285_);enable-background:new    ;}
        .st396{clip-path:url(#SVGID_00000165211306946060471190000015918280646814923653_);enable-background:new    ;}
        .st397{clip-path:url(#SVGID_00000081630615696988768430000001267410505130998183_);enable-background:new    ;}
        .st398{clip-path:url(#SVGID_00000015326162903271505390000005188104785945509783_);enable-background:new    ;}
        .st399{clip-path:url(#SVGID_00000029750360055816555990000001382350348666172044_);enable-background:new    ;}
        .st400{clip-path:url(#SVGID_00000021830670442481464640000013903812711999488682_);enable-background:new    ;}
        .st401{clip-path:url(#SVGID_00000131358620879999125110000008827758987710113168_);enable-background:new    ;}
        .st402{clip-path:url(#SVGID_00000091705413528977989570000017085875919252577453_);enable-background:new    ;}
        .st403{clip-path:url(#SVGID_00000086683789509002144560000006728767063334497203_);enable-background:new    ;}
        .st404{clip-path:url(#SVGID_00000172417166232329440830000004074740115795789190_);enable-background:new    ;}
        .st405{clip-path:url(#SVGID_00000019654167048916132160000014728553923382588329_);enable-background:new    ;}
        .st406{clip-path:url(#SVGID_00000161615508867301482010000018324723378233450941_);enable-background:new    ;}
        .st407{clip-path:url(#SVGID_00000023282955020841371270000007409515501702179487_);enable-background:new    ;}
        .st408{clip-path:url(#SVGID_00000181071913487132291230000008724072878632439471_);enable-background:new    ;}
        .st409{clip-path:url(#SVGID_00000110463244102122445550000013160323081384522881_);enable-background:new    ;}
        .st410{clip-path:url(#SVGID_00000099656306838232958540000018417977519517461681_);enable-background:new    ;}
        .st411{clip-path:url(#SVGID_00000148636657700900605100000008405196806338848133_);enable-background:new    ;}
        .st412{clip-path:url(#SVGID_00000084496762825010631320000010845313053098917012_);enable-background:new    ;}
        .st413{clip-path:url(#SVGID_00000057118179656971052270000005237029425325821882_);enable-background:new    ;}
        .st414{clip-path:url(#SVGID_00000019666890488443706320000011306082854877338523_);enable-background:new    ;}
        .st415{clip-path:url(#SVGID_00000054977391819749058660000006554565796518367931_);enable-background:new    ;}
        .st416{clip-path:url(#SVGID_00000106851410140367868270000006327548526493536677_);enable-background:new    ;}
        .st417{clip-path:url(#SVGID_00000137847752742366622560000007237740125509131197_);enable-background:new    ;}
        .st418{clip-path:url(#SVGID_00000062162412948565437070000014077874715882901636_);enable-background:new    ;}
        .st419{clip-path:url(#SVGID_00000114774724899297498520000016875124707659991473_);enable-background:new    ;}
        .st420{clip-path:url(#SVGID_00000155853374415050380030000005362090701329662397_);enable-background:new    ;}
        .st421{clip-path:url(#SVGID_00000109717669781731682120000013475296675041257608_);enable-background:new    ;}
        .st422{clip-path:url(#SVGID_00000101062833747487711640000007189321771597655733_);enable-background:new    ;}
        .st423{clip-path:url(#SVGID_00000146482498602630350890000007596645875877481606_);enable-background:new    ;}
        .st424{clip-path:url(#SVGID_00000122717710668065218410000009914566039172512698_);enable-background:new    ;}
        .st425{clip-path:url(#SVGID_00000132062468395758157080000012803944778119363002_);enable-background:new    ;}
        .st426{clip-path:url(#SVGID_00000034770439113666026850000011743277527569781381_);enable-background:new    ;}
        .st427{clip-path:url(#SVGID_00000103963043972713446560000000135509590054720678_);enable-background:new    ;}
        .st428{clip-path:url(#SVGID_00000067227884625428669170000014536624632441840809_);enable-background:new    ;}
        .st429{clip-path:url(#SVGID_00000090995099293244973810000015688094965136174723_);enable-background:new    ;}
        .st430{clip-path:url(#SVGID_00000161630962340526464690000007799142507886038718_);enable-background:new    ;}
        .st431{clip-path:url(#SVGID_00000167359573917017133230000002439602453531675267_);enable-background:new    ;}
        .st432{clip-path:url(#SVGID_00000060746457482797998940000005876080903331993518_);enable-background:new    ;}
        .st433{clip-path:url(#SVGID_00000053533474921499485110000013895628320879401088_);enable-background:new    ;}
        .st434{clip-path:url(#SVGID_00000023962745582639805220000016142773348729476505_);enable-background:new    ;}
        .st435{clip-path:url(#SVGID_00000140730288263210481020000010361117441297329063_);enable-background:new    ;}
        .st436{clip-path:url(#SVGID_00000124129775859998239470000000795262076225342137_);enable-background:new    ;}
        .st437{clip-path:url(#SVGID_00000098928973768803107030000008721652342985031585_);enable-background:new    ;}
        .st438{clip-path:url(#SVGID_00000100372710568429764400000000998421735712593802_);enable-background:new    ;}
        .st439{clip-path:url(#SVGID_00000005959302183744384480000001454073354416374192_);enable-background:new    ;}
        .st440{clip-path:url(#SVGID_00000019656577809917817640000008405475929654944910_);enable-background:new    ;}
        .st441{clip-path:url(#SVGID_00000023269345819079950660000012659880721165079950_);enable-background:new    ;}
        .st442{opacity:0.4;fill:#333333;}
        .st443{clip-path:url(#SVGID_00000138548543099818761130000013346589078352610991_);enable-background:new    ;}
        .st444{fill:#747474;}
        .st445{fill:#6F6F6F;}
        .st446{fill:#6A6A6A;}
        .st447{fill:#646464;}
        .st448{fill:#5D5D5D;}
        .st449{fill:#565656;}
        .st450{fill:#4B4B4B;}
        .st451{fill:#333333;}
        .st452{fill:#323232;}
        .st453{fill:#313131;}
        .st454{fill:#303030;}
        .st455{fill:#2F2F2F;}
        .st456{fill:#2E2E2E;}
        .st457{fill:#2D2D2D;}
        .st458{fill:#2C2C2C;}
        .st459{fill:#2B2B2B;}
        .st460{fill:#2A2A2A;}
        .st461{fill:#292929;}
        .st462{fill:#282828;}
        .st463{fill:#272727;}
        .st464{fill:#262626;}
        .st465{fill:#252525;}
        .st466{fill:#242424;}
        .st467{fill:#232323;}
        .st468{fill:#222222;}
        .st469{fill:#212121;}
        .st470{fill:#202020;}
        .st471{fill:#1F1F1F;}
        .st472{fill:#1E1E1E;}
        .st473{fill:#1D1D1D;}
        .st474{fill:#1C1C1C;}
        .st475{fill:#1B1B1B;}
        .st476{clip-path:url(#SVGID_00000147198179911462765050000008611767903847884678_);enable-background:new    ;}
        .st477{clip-path:url(#SVGID_00000053505110703010061020000013786781724481849769_);enable-background:new    ;}
        .st478{clip-path:url(#SVGID_00000023971235339763868110000002473552881507760062_);enable-background:new    ;}
        .st479{clip-path:url(#SVGID_00000140692990940787448460000001258704560143465905_);enable-background:new    ;}
        .st480{clip-path:url(#SVGID_00000027596475553114618460000003415933012270981299_);enable-background:new    ;}
        .st481{clip-path:url(#SVGID_00000054956617093979063460000010917564461721061561_);enable-background:new    ;}
        .st482{clip-path:url(#SVGID_00000095306047913921171890000001742201695583153802_);enable-background:new    ;}
        .st483{clip-path:url(#SVGID_00000092448575450128841660000007785101072426106267_);enable-background:new    ;}
        .st484{clip-path:url(#SVGID_00000038388048615343748350000012659974202692404656_);enable-background:new    ;}
        .st485{clip-path:url(#SVGID_00000039828039680214117180000002452354795938077885_);enable-background:new    ;}
        .st486{clip-path:url(#SVGID_00000029045400182536407670000007587388097310609596_);enable-background:new    ;}
        .st487{clip-path:url(#SVGID_00000011013272709421196390000012131044374880469170_);enable-background:new    ;}
        .st488{clip-path:url(#SVGID_00000181067903371986121350000013076263482954625180_);enable-background:new    ;}
        .st489{clip-path:url(#SVGID_00000176730039107784816920000006903640538390537914_);enable-background:new    ;}
        .st490{clip-path:url(#SVGID_00000153684570184962276180000014675444172390622905_);enable-background:new    ;}
        .st491{clip-path:url(#SVGID_00000018942601028046982840000004816524519483294602_);enable-background:new    ;}
        .st492{clip-path:url(#SVGID_00000036954548342642621990000008263652847845354131_);enable-background:new    ;}
        .st493{clip-path:url(#SVGID_00000127012385814686732140000014518501464569115044_);enable-background:new    ;}
        .st494{clip-path:url(#SVGID_00000151501556996744191120000010221354713722247357_);enable-background:new    ;}
        .st495{clip-path:url(#SVGID_00000174598220763737272120000007112083151908325796_);enable-background:new    ;}
        .st496{clip-path:url(#SVGID_00000078726854071609158580000004875893521873501342_);enable-background:new    ;}
        .st497{fill:#787878;}
        .st498{fill:#7C7C7C;}
        .st499{fill:#808080;}
        .st500{fill:#848484;}
        .st501{fill:#888888;}
        .st502{fill:#8B8B8B;}
        .st503{fill:#8F8F8F;}
        .st504{fill:#929292;}
        .st505{fill:#959595;}
        .st506{fill:#989898;}
        .st507{fill:#9B9B9B;}
        .st508{fill:#9E9E9E;}
        .st509{fill:#A0A0A0;}
        .st510{clip-path:url(#SVGID_00000039096438830559959620000015088140835186355856_);enable-background:new    ;}
        .st511{clip-path:url(#SVGID_00000076597217921415901960000016228475211888437633_);enable-background:new    ;}
        .st512{clip-path:url(#SVGID_00000007404570644196944350000005607741608546635169_);enable-background:new    ;}
        .st513{clip-path:url(#SVGID_00000067225327943065513990000013080594702367596676_);enable-background:new    ;}
        .st514{clip-path:url(#SVGID_00000043425311394196718300000004141751913871497364_);enable-background:new    ;}
        .st515{clip-path:url(#SVGID_00000170262340854432364550000018024132133274450105_);enable-background:new    ;}
        .st516{clip-path:url(#SVGID_00000170268761783600177170000016973272382228625304_);enable-background:new    ;}
        .st517{clip-path:url(#SVGID_00000128446864380965072130000015225876513180753561_);enable-background:new    ;}
        .st518{clip-path:url(#SVGID_00000002366494506027074530000015626443193838736539_);enable-background:new    ;}
        .st519{clip-path:url(#SVGID_00000168091384946566318260000017253141184514452390_);enable-background:new    ;}
        .st520{clip-path:url(#SVGID_00000132797330812840028270000009181770681860966316_);enable-background:new    ;}
        .st521{clip-path:url(#SVGID_00000090285280529418442490000004453129795670634641_);enable-background:new    ;}
        .st522{clip-path:url(#SVGID_00000177482979878910556420000003854758086221488302_);enable-background:new    ;}
        .st523{clip-path:url(#SVGID_00000056399031611629677760000009981123935993332875_);enable-background:new    ;}
        .st524{clip-path:url(#SVGID_00000084510656170404441990000013299292352597928338_);enable-background:new    ;}
        .st525{clip-path:url(#SVGID_00000175307327696871508480000003479486798037547689_);enable-background:new    ;}
        .st526{clip-path:url(#SVGID_00000108300883593863239270000002547862174391979425_);enable-background:new    ;}
        .st527{clip-path:url(#SVGID_00000071532928810309005850000009216730593769008265_);enable-background:new    ;}
        .st528{clip-path:url(#SVGID_00000144321550074160366750000000411014183857142929_);enable-background:new    ;}
        .st529{clip-path:url(#SVGID_00000040561773219145656430000013801724707270188220_);enable-background:new    ;}
        .st530{clip-path:url(#SVGID_00000000937709749222588150000001334462482810721447_);enable-background:new    ;}
        .st531{clip-path:url(#SVGID_00000103944229619053696850000005236796126720057508_);enable-background:new    ;}
        .st532{clip-path:url(#SVGID_00000054978490173508688650000015845113496996092055_);enable-background:new    ;}
        .st533{clip-path:url(#SVGID_00000107551070005370374800000009377451767998160311_);enable-background:new    ;}
        .st534{clip-path:url(#SVGID_00000164478517841112365460000000848671065562661044_);enable-background:new    ;}
        .st535{clip-path:url(#SVGID_00000087411686281141022990000001342832568472555936_);enable-background:new    ;}
        .st536{clip-path:url(#SVGID_00000064354813355480051660000001092993364290258583_);enable-background:new    ;}
        .st537{clip-path:url(#SVGID_00000051364910196190410880000008375396653717725060_);enable-background:new    ;}
        .st538{clip-path:url(#SVGID_00000009559223571047238660000007644391061378433439_);enable-background:new    ;}
        .st539{clip-path:url(#SVGID_00000058557849275303705500000013298114397685220527_);enable-background:new    ;}
        .st540{clip-path:url(#SVGID_00000132804764230351216640000004849819166331717297_);enable-background:new    ;}
        .st541{clip-path:url(#SVGID_00000132065841526876277810000003751770015591849109_);enable-background:new    ;}
        .st542{clip-path:url(#SVGID_00000151540821866159257890000009865631137693348768_);enable-background:new    ;}
        .st543{clip-path:url(#SVGID_00000156564249513929301220000006810267026179339684_);enable-background:new    ;}
        .st544{clip-path:url(#SVGID_00000085939492723188395250000005190718135773031093_);enable-background:new    ;}
        .st545{clip-path:url(#SVGID_00000099661773490840714880000011197345900626606754_);enable-background:new    ;}
        .st546{clip-path:url(#SVGID_00000114042048731263773380000007406751843562037947_);enable-background:new    ;}
        .st547{clip-path:url(#SVGID_00000168795294414199024520000005265191077237819013_);enable-background:new    ;}
        .st548{clip-path:url(#SVGID_00000018203854440736648380000003930563733832853926_);enable-background:new    ;}
        .st549{clip-path:url(#SVGID_00000172436363723833122420000011768148152634085816_);enable-background:new    ;}
        .st550{clip-path:url(#SVGID_00000098910556340615087700000013571901691893095608_);enable-background:new    ;}
        .st551{clip-path:url(#SVGID_00000170981113651369750440000017317120825164254099_);enable-background:new    ;}
        .st552{clip-path:url(#SVGID_00000144321400555959313100000007284769860383244699_);enable-background:new    ;}
        .st553{clip-path:url(#SVGID_00000106845451834878590070000008832038055487497640_);enable-background:new    ;}
        .st554{clip-path:url(#SVGID_00000060021490213407976970000004636823203221422474_);enable-background:new    ;}
        .st555{clip-path:url(#SVGID_00000001646050154700193520000015583064446383421860_);enable-background:new    ;}
        .st556{clip-path:url(#SVGID_00000076600927515920733720000008230763960182915488_);enable-background:new    ;}
        .st557{clip-path:url(#SVGID_00000036217803401870941900000001878106477954018991_);enable-background:new    ;}
        .st558{clip-path:url(#SVGID_00000101811723868929708090000003381500379655319682_);enable-background:new    ;}
        .st559{clip-path:url(#SVGID_00000121252385472607942380000017165785986270404000_);enable-background:new    ;}
        .st560{clip-path:url(#SVGID_00000106113016381402482620000011885432453877357492_);enable-background:new    ;}
        .st561{clip-path:url(#SVGID_00000063608246651418382280000012813847169051612055_);enable-background:new    ;}
        .st562{clip-path:url(#SVGID_00000072245256188483549730000016231294054489508543_);enable-background:new    ;}
        .st563{clip-path:url(#SVGID_00000031164962297095059120000014958245938596246462_);enable-background:new    ;}
        .st564{clip-path:url(#SVGID_00000075856954401881418220000017585435054285367230_);enable-background:new    ;}
        .st565{clip-path:url(#SVGID_00000008847410930060846760000008827490786953160593_);enable-background:new    ;}
        .st566{clip-path:url(#SVGID_00000180339580323995683110000001779359006796376252_);enable-background:new    ;}
        .st567{clip-path:url(#SVGID_00000157268918298856396150000002796731660820614282_);enable-background:new    ;}
        .st568{clip-path:url(#SVGID_00000070826498319662271550000001887146845629286046_);enable-background:new    ;}
        .st569{clip-path:url(#SVGID_00000119810875286986518550000014864675539968194716_);enable-background:new    ;}
        .st570{clip-path:url(#SVGID_00000047057057863523415300000010739927839904716474_);enable-background:new    ;}
        .st571{clip-path:url(#SVGID_00000048490775559499286260000012033114231015532187_);enable-background:new    ;}
        .st572{clip-path:url(#SVGID_00000088855074017724309500000010334826182646677929_);enable-background:new    ;}
        .st573{clip-path:url(#SVGID_00000002346169931665360440000007177863907838443161_);enable-background:new    ;}
        .st574{clip-path:url(#SVGID_00000085218175164929640590000003989221597185472409_);enable-background:new    ;}
        .st575{clip-path:url(#SVGID_00000163061496288587884580000008464783724880956852_);enable-background:new    ;}
        .st576{clip-path:url(#SVGID_00000102531980029970180630000010589788783163591312_);enable-background:new    ;}
        .st577{clip-path:url(#SVGID_00000123414974897297832470000007405654650414730131_);enable-background:new    ;}
        .st578{clip-path:url(#SVGID_00000108305587156701262910000016511806311439588510_);enable-background:new    ;}
        .st579{clip-path:url(#SVGID_00000072267930230775694270000008423554905177931411_);enable-background:new    ;}
        .st580{clip-path:url(#SVGID_00000165928638179948989950000010708483028432895673_);enable-background:new    ;}
        .st581{clip-path:url(#SVGID_00000030464124851101376550000011645339659832854414_);enable-background:new    ;}
        .st582{clip-path:url(#SVGID_00000145743350543454926790000015260472083499534510_);enable-background:new    ;}
        .st583{clip-path:url(#SVGID_00000078765165009627400700000013749051108679579316_);enable-background:new    ;}
        .st584{clip-path:url(#SVGID_00000168105437493262998390000009572601028097104794_);enable-background:new    ;}
        .st585{clip-path:url(#SVGID_00000089545366073196129380000009383325260543323523_);enable-background:new    ;}
        .st586{clip-path:url(#SVGID_00000052809809170556495680000000992192392155488420_);enable-background:new    ;}
        .st587{clip-path:url(#SVGID_00000061457125281420420650000005508925966297673879_);enable-background:new    ;}
        .st588{clip-path:url(#SVGID_00000002356938920950812330000002103946834528251804_);enable-background:new    ;}
        .st589{clip-path:url(#SVGID_00000151536861293322501050000010854413793630239374_);enable-background:new    ;}
        .st590{clip-path:url(#SVGID_00000015315846246318068810000007007631280122470587_);enable-background:new    ;}
        .st591{clip-path:url(#SVGID_00000049926024318462031600000016028487592568389532_);enable-background:new    ;}
        .st592{clip-path:url(#SVGID_00000183214165162290877240000013801739136774435211_);enable-background:new    ;}
        .st593{clip-path:url(#SVGID_00000133521880403394730940000018321790871445703334_);enable-background:new    ;}
        .st594{clip-path:url(#SVGID_00000077317640420071506140000010384660744578664355_);enable-background:new    ;}
        .st595{clip-path:url(#SVGID_00000041980978521564814490000007021782465227905961_);enable-background:new    ;}
        .st596{clip-path:url(#SVGID_00000174593802347259291150000008545262054009917576_);enable-background:new    ;}
        .st597{clip-path:url(#SVGID_00000178186009567432214690000013491212500614505125_);enable-background:new    ;}
        .st598{clip-path:url(#SVGID_00000160179128900788913210000018066547329251938214_);enable-background:new    ;}
        .st599{clip-path:url(#SVGID_00000075149940932538675790000003235433410274337719_);enable-background:new    ;}
        .st600{clip-path:url(#SVGID_00000153687677179409442970000006780094145083242940_);enable-background:new    ;}
        .st601{clip-path:url(#SVGID_00000127012335683817828070000018398167831631732122_);enable-background:new    ;}
        .st602{clip-path:url(#SVGID_00000012455732555244516400000006985627910702300079_);enable-background:new    ;}
        .st603{clip-path:url(#SVGID_00000165935548061553891190000003115494186754597537_);enable-background:new    ;}
        .st604{clip-path:url(#SVGID_00000014596423371434917910000003268599863246305941_);enable-background:new    ;}
        .st605{clip-path:url(#SVGID_00000106147397250103108950000000227683140088779958_);enable-background:new    ;}
        .st606{clip-path:url(#SVGID_00000075848190536824809480000014117015090317801607_);enable-background:new    ;}
        .st607{clip-path:url(#SVGID_00000183942347490420027720000011180241245512252047_);enable-background:new    ;}
        .st608{clip-path:url(#SVGID_00000021096591042354477710000000279840983363598519_);enable-background:new    ;}
        .st609{clip-path:url(#SVGID_00000130623579210121526360000011269066554002903974_);enable-background:new    ;}
        .st610{clip-path:url(#SVGID_00000158007901781872929200000014575069917185142407_);enable-background:new    ;}
        .st611{clip-path:url(#SVGID_00000093895247967192893350000005050057550906794156_);enable-background:new    ;}
        .st612{clip-path:url(#SVGID_00000028311936297438059000000006427161438258421438_);enable-background:new    ;}
        .st613{clip-path:url(#SVGID_00000077319919654180044180000013943263207043646117_);enable-background:new    ;}
        .st614{clip-path:url(#SVGID_00000171690407311992544190000015677630018942720447_);enable-background:new    ;}
        .st615{clip-path:url(#SVGID_00000042725936076266132310000007192584783487926190_);enable-background:new    ;}
        .st616{clip-path:url(#SVGID_00000149361336337482254330000013303524262452726184_);enable-background:new    ;}
        .st617{clip-path:url(#SVGID_00000088116142374894209110000005602395101840864390_);enable-background:new    ;}
        .st618{clip-path:url(#SVGID_00000075135003165430051860000016949122095680751518_);enable-background:new    ;}
        .st619{clip-path:url(#SVGID_00000047757247838521708760000003513816832634524826_);enable-background:new    ;}
        .st620{clip-path:url(#SVGID_00000003064845717926216430000009586691626519054015_);enable-background:new    ;}
        .st621{clip-path:url(#SVGID_00000060722294300813979350000001435665112164800917_);enable-background:new    ;}
        .st622{clip-path:url(#SVGID_00000034769410105548235270000003796391667763138471_);enable-background:new    ;}
        .st623{clip-path:url(#SVGID_00000132785613323906448740000004207608763964368005_);enable-background:new    ;}
        .st624{clip-path:url(#SVGID_00000064328252728788722650000000440541195433727640_);enable-background:new    ;}
        .st625{clip-path:url(#SVGID_00000021084971102446644870000006963202190106629548_);enable-background:new    ;}
        .st626{clip-path:url(#SVGID_00000122707226490723772000000004849726566435499669_);enable-background:new    ;}
        .st627{clip-path:url(#SVGID_00000137121300299962687520000016433330714833966250_);enable-background:new    ;}
        .st628{clip-path:url(#SVGID_00000076600213748947660790000007818860038633403535_);enable-background:new    ;}
        .st629{clip-path:url(#SVGID_00000008843449913124210900000003239006463485072554_);enable-background:new    ;}
        .st630{clip-path:url(#SVGID_00000005984179047947221430000010323502945567443849_);enable-background:new    ;}
        .st631{clip-path:url(#SVGID_00000090997037598299562810000014639600841674636955_);enable-background:new    ;}
        .st632{clip-path:url(#SVGID_00000087395453293781920090000004518194779613366680_);enable-background:new    ;}
        .st633{clip-path:url(#SVGID_00000165953388474751829310000007478209686146709387_);enable-background:new    ;}
        .st634{clip-path:url(#SVGID_00000132067722265542840530000003381417079245241994_);enable-background:new    ;}
        .st635{clip-path:url(#SVGID_00000094613596389861003210000009468583977907883422_);enable-background:new    ;}
        .st636{clip-path:url(#SVGID_00000036248292757368543280000006149773537119752101_);enable-background:new    ;}
        .st637{clip-path:url(#SVGID_00000018234570409033715120000003589585171774128274_);enable-background:new    ;}
        .st638{clip-path:url(#SVGID_00000078034533344130119360000002663947614274261898_);enable-background:new    ;}
        .st639{clip-path:url(#SVGID_00000076578187492429166560000001990815375783071618_);enable-background:new    ;}
        .st640{clip-path:url(#SVGID_00000171699010286316743960000002185173142605543842_);enable-background:new    ;}
        .st641{clip-path:url(#SVGID_00000012458402697062192540000012211120056449763730_);enable-background:new    ;}
        .st642{clip-path:url(#SVGID_00000037673293423795381470000016592024309227525262_);enable-background:new    ;}
        .st643{clip-path:url(#SVGID_00000173842139522599657920000011938197720646548643_);enable-background:new    ;}
        .st644{clip-path:url(#SVGID_00000177454432952131508900000017936266650654045825_);enable-background:new    ;}
        .st645{clip-path:url(#SVGID_00000011709632500558250200000000791955263041160123_);enable-background:new    ;}
        .st646{clip-path:url(#SVGID_00000134961289380189238360000009878823549252105103_);enable-background:new    ;}
        .st647{clip-path:url(#SVGID_00000047034042086320985220000003170570954762183812_);enable-background:new    ;}
        .st648{clip-path:url(#SVGID_00000118380538075352195100000013855669736375432615_);enable-background:new    ;}
        .st649{clip-path:url(#SVGID_00000028327402171092354590000012670169764918304389_);enable-background:new    ;}
        .st650{clip-path:url(#SVGID_00000172427906607052487880000005128642412396040832_);enable-background:new    ;}
        .st651{clip-path:url(#SVGID_00000080172367901731420340000000934231409964359344_);enable-background:new    ;}
        .st652{clip-path:url(#SVGID_00000119119544558993217600000010953130486332582844_);enable-background:new    ;}
        .st653{clip-path:url(#SVGID_00000119803703338608156130000016929773206606950839_);enable-background:new    ;}
        .st654{clip-path:url(#SVGID_00000116218955570143916170000011093954492330498952_);enable-background:new    ;}
        .st655{clip-path:url(#SVGID_00000062156130335157853400000005236826046015797894_);enable-background:new    ;}
        .st656{clip-path:url(#SVGID_00000106111825548012543850000013338716914610929310_);enable-background:new    ;}
        .st657{clip-path:url(#SVGID_00000053525600954376162990000010249817388772594085_);enable-background:new    ;}
        .st658{clip-path:url(#SVGID_00000162329373923803793420000016466411365741152151_);enable-background:new    ;}
        .st659{clip-path:url(#SVGID_00000096763018092113695280000014095977700887750048_);enable-background:new    ;}
        .st660{clip-path:url(#SVGID_00000174573811259807927930000016558354483077568910_);enable-background:new    ;}
        .st661{clip-path:url(#SVGID_00000037689702472174974320000005898388652890322091_);enable-background:new    ;}
        .st662{clip-path:url(#SVGID_00000158743240439676330080000008074234769609320612_);enable-background:new    ;}
        .st663{clip-path:url(#SVGID_00000143616750710703723730000016029153765805152190_);enable-background:new    ;}
        .st664{clip-path:url(#SVGID_00000083056289056718444960000006205389804808949689_);enable-background:new    ;}
        .st665{clip-path:url(#SVGID_00000101815834690775876890000000796738910336689042_);enable-background:new    ;}
        .st666{clip-path:url(#SVGID_00000085242061183008908290000015080225085108197264_);enable-background:new    ;}
        .st667{clip-path:url(#SVGID_00000049929166138905186440000010394431050060226466_);enable-background:new    ;}
        .st668{clip-path:url(#SVGID_00000147183638506953186200000000399636479444075685_);enable-background:new    ;}
        .st669{clip-path:url(#SVGID_00000141422872339164904130000008841717876061784466_);enable-background:new    ;}
        .st670{clip-path:url(#SVGID_00000124158799817261154860000000815833143218888088_);enable-background:new    ;}
        .st671{clip-path:url(#SVGID_00000173852644399365238360000006321552336998275983_);enable-background:new    ;}
        .st672{clip-path:url(#SVGID_00000077281996559648759040000008615490637245968533_);enable-background:new    ;}
        .st673{clip-path:url(#SVGID_00000083798358829775157330000001385559074019960209_);enable-background:new    ;}
        .st674{clip-path:url(#SVGID_00000078012433134142705080000018147838396037946546_);enable-background:new    ;}
        .st675{clip-path:url(#SVGID_00000163753661950366259710000004709758996907970233_);enable-background:new    ;}
        .st676{clip-path:url(#SVGID_00000070083118287096369350000013690311020401173687_);enable-background:new    ;}
        .st677{clip-path:url(#SVGID_00000103953761591712285120000011217191219899185811_);enable-background:new    ;}
        .st678{clip-path:url(#SVGID_00000158721083530586098870000016332751500390833822_);enable-background:new    ;}
        .st679{clip-path:url(#SVGID_00000146486816988449925920000000136543104683401626_);enable-background:new    ;}
        .st680{clip-path:url(#SVGID_00000003809152500467810450000000709397003579185299_);enable-background:new    ;}
        .st681{clip-path:url(#SVGID_00000102519694967796079730000000149541660762220672_);enable-background:new    ;}
        .st682{clip-path:url(#SVGID_00000152248898531957928270000000875813961967077565_);enable-background:new    ;}
        .st683{clip-path:url(#SVGID_00000017497441419837367040000007964501098911911570_);enable-background:new    ;}
        .st684{clip-path:url(#SVGID_00000061461627943099737730000004255561354521522841_);enable-background:new    ;}
        .st685{clip-path:url(#SVGID_00000026162172984342437460000014304850483340283271_);enable-background:new    ;}
        .st686{clip-path:url(#SVGID_00000068669939900856843980000006234959061372801452_);enable-background:new    ;}
        .st687{clip-path:url(#SVGID_00000083807973567393876200000004819690201635963561_);enable-background:new    ;}
        .st688{clip-path:url(#SVGID_00000054951529126121612010000001445283458164647810_);enable-background:new    ;}
        .st689{clip-path:url(#SVGID_00000158706990127867595560000004342337123019235753_);enable-background:new    ;}
        .st690{clip-path:url(#SVGID_00000008841539033109200630000018233854972694291125_);enable-background:new    ;}
        .st691{clip-path:url(#SVGID_00000111148958292724713910000002504377915223884734_);enable-background:new    ;}
        .st692{clip-path:url(#SVGID_00000091003881892360750490000006418205391259845781_);enable-background:new    ;}
        .st693{clip-path:url(#SVGID_00000014635912165885140380000002539992487224827271_);enable-background:new    ;}
        .st694{clip-path:url(#SVGID_00000167366298711964866980000007481617812850785178_);enable-background:new    ;}
        .st695{clip-path:url(#SVGID_00000042726610755308340260000013799578934192832959_);enable-background:new    ;}
        .st696{clip-path:url(#SVGID_00000018917456830649606300000004038027094969858196_);enable-background:new    ;}
        .st697{clip-path:url(#SVGID_00000083797147957153610110000007551507172085737354_);enable-background:new    ;}
        .st698{clip-path:url(#SVGID_00000172402451514672913700000014806490221613950129_);enable-background:new    ;}
        .st699{clip-path:url(#SVGID_00000114058385999464305980000012044135601778163644_);enable-background:new    ;}
        .st700{clip-path:url(#SVGID_00000163044947818109927270000017272695799435102376_);enable-background:new    ;}
        .st701{clip-path:url(#SVGID_00000140717033718963759630000005005252415538664324_);enable-background:new    ;}
        .st702{clip-path:url(#SVGID_00000072975339066081641710000018248728050485198747_);enable-background:new    ;}
        .st703{clip-path:url(#SVGID_00000039814243744592111130000015186499925559551655_);enable-background:new    ;}
        .st704{clip-path:url(#SVGID_00000031918567294105818130000016759352152969675419_);enable-background:new    ;}
        .st705{clip-path:url(#SVGID_00000130621854690477458120000001027992813759039131_);enable-background:new    ;}
        .st706{clip-path:url(#SVGID_00000005976846093960529540000008993110855901773468_);enable-background:new    ;}
        .st707{clip-path:url(#SVGID_00000059310475586581546280000016922741878675980223_);enable-background:new    ;}
        .st708{clip-path:url(#SVGID_00000148661164065853762140000011872744743013888384_);enable-background:new    ;}
        .st709{clip-path:url(#SVGID_00000067921866083089280710000018095136727972370311_);enable-background:new    ;}
        .st710{clip-path:url(#SVGID_00000101083314723449188040000003006541634054980269_);enable-background:new    ;}
        .st711{clip-path:url(#SVGID_00000108310883401238543510000014786406510698827152_);enable-background:new    ;}
        .st712{clip-path:url(#SVGID_00000052820801573958271020000008274361099613414785_);enable-background:new    ;}
        .st713{clip-path:url(#SVGID_00000071526729169945612080000005566074760797754541_);enable-background:new    ;}
        .st714{clip-path:url(#SVGID_00000067206454129377842010000005693887429465928893_);enable-background:new    ;}
        .st715{clip-path:url(#SVGID_00000181057582745192997840000005055860866065026439_);enable-background:new    ;}
        .st716{clip-path:url(#SVGID_00000140708563101748035620000005677382476078402464_);enable-background:new    ;}
        .st717{clip-path:url(#SVGID_00000036249316697640292620000016135155332592656522_);enable-background:new    ;}
        .st718{clip-path:url(#SVGID_00000151516756317200374880000007734485288896106126_);enable-background:new    ;}
        .st719{clip-path:url(#SVGID_00000147937412670441560330000004026037200384550839_);enable-background:new    ;}
        .st720{clip-path:url(#SVGID_00000079471655752983427910000002460419374335304084_);enable-background:new    ;}
        .st721{clip-path:url(#SVGID_00000158743286896568392820000008269487926341375676_);enable-background:new    ;}
        .st722{clip-path:url(#SVGID_00000052791607949389919360000003794376363188970139_);enable-background:new    ;}
        .st723{clip-path:url(#SVGID_00000085942708562741313050000001599982951622721713_);enable-background:new    ;}
        .st724{clip-path:url(#SVGID_00000028308574683721504880000003059871973966255007_);enable-background:new    ;}
        .st725{fill:#A3A3A3;}
        .st726{fill:#A6A6A6;}
        .st727{clip-path:url(#SVGID_00000142893927087058804880000015450548936997528760_);enable-background:new    ;}
        .st728{clip-path:url(#SVGID_00000052072318941032944100000004479001546775395214_);enable-background:new    ;}
        .st729{clip-path:url(#SVGID_00000040555831411191873460000003384790000909034392_);enable-background:new    ;}
        .st730{clip-path:url(#SVGID_00000113314400396005457670000008123930504376054912_);enable-background:new    ;}
        .st731{clip-path:url(#SVGID_00000110465381093909032860000008220189734946362541_);enable-background:new    ;}
        .st732{clip-path:url(#SVGID_00000142164291450910631260000014661273008557841063_);enable-background:new    ;}
        .st733{clip-path:url(#SVGID_00000115491939581371562350000014294794118545289377_);enable-background:new    ;}
        .st734{clip-path:url(#SVGID_00000106859762569880762670000000692540979222982062_);enable-background:new    ;}
        .st735{clip-path:url(#SVGID_00000160882438264151955270000015538059478190467738_);enable-background:new    ;}
        .st736{clip-path:url(#SVGID_00000127753834699256865950000005437207392577040549_);enable-background:new    ;}
        .st737{clip-path:url(#SVGID_00000178165809052741768100000000041421523922226826_);enable-background:new    ;}
        .st738{clip-path:url(#SVGID_00000111896179063576201330000010262181499435683720_);enable-background:new    ;}
        .st739{clip-path:url(#SVGID_00000063598595826779903910000011213121375923326648_);enable-background:new    ;}
        .st740{clip-path:url(#SVGID_00000105405015750173527010000002576257020302396824_);enable-background:new    ;}
        .st741{clip-path:url(#SVGID_00000120518172469024577930000002125059129360972475_);enable-background:new    ;}
        .st742{clip-path:url(#SVGID_00000004544210633403952280000006237726265189002128_);enable-background:new    ;}
        .st743{clip-path:url(#SVGID_00000183227769069937282110000012247489497813095041_);enable-background:new    ;}
        .st744{clip-path:url(#SVGID_00000139254966396117768320000002478743658617949365_);enable-background:new    ;}
        .st745{clip-path:url(#SVGID_00000145040110605122580780000013193028441516787380_);enable-background:new    ;}
        .st746{clip-path:url(#SVGID_00000031167990996945844170000001175763035679483027_);enable-background:new    ;}
        .st747{clip-path:url(#SVGID_00000082359482026172077190000003996786338095883404_);enable-background:new    ;}
        .st748{clip-path:url(#SVGID_00000037670169690805875270000007613584336491150991_);enable-background:new    ;}
        .st749{clip-path:url(#SVGID_00000114756358642195210840000002248744262950666388_);enable-background:new    ;}
        .st750{clip-path:url(#SVGID_00000119796101137160252440000011313373605224078783_);enable-background:new    ;}
        .st751{clip-path:url(#SVGID_00000106125081509839527310000003742895311462060711_);enable-background:new    ;}
        .st752{clip-path:url(#SVGID_00000034812067546238304280000009100027574788794250_);enable-background:new    ;}
        .st753{clip-path:url(#SVGID_00000052079372552450518580000016583454642124954767_);enable-background:new    ;}
        .st754{clip-path:url(#SVGID_00000123418536953573961360000007993206673988131509_);enable-background:new    ;}
        .st755{clip-path:url(#SVGID_00000119820500888826184810000013816479305441754267_);enable-background:new    ;}
        .st756{clip-path:url(#SVGID_00000045577065825262135870000005095499059019845527_);enable-background:new    ;}
        .st757{clip-path:url(#SVGID_00000096738864810680009910000012367703533704921253_);enable-background:new    ;}
        .st758{clip-path:url(#SVGID_00000141429024718774304170000015473903635739783073_);enable-background:new    ;}
        .st759{clip-path:url(#SVGID_00000016061440607014776700000001069397833451018373_);enable-background:new    ;}
        .st760{clip-path:url(#SVGID_00000117634389973292754670000011416882203962977679_);enable-background:new    ;}
        .st761{clip-path:url(#SVGID_00000164496688362047681650000017705575392592463256_);enable-background:new    ;}
        .st762{clip-path:url(#SVGID_00000053544771800318216490000012693790647260269492_);enable-background:new    ;}
        .st763{clip-path:url(#SVGID_00000079468280431472395270000017318738621972479129_);enable-background:new    ;}
        .st764{clip-path:url(#SVGID_00000023979598474533264220000014871377155218213051_);enable-background:new    ;}
        .st765{clip-path:url(#SVGID_00000013155163126164581300000000321640708092113037_);enable-background:new    ;}
        .st766{clip-path:url(#SVGID_00000023975547382958840070000001102006049117971608_);enable-background:new    ;}
        .st767{clip-path:url(#SVGID_00000020388943791017927640000001031683420789842060_);enable-background:new    ;}
        .st768{clip-path:url(#SVGID_00000003790189513865509350000007986942560559200399_);enable-background:new    ;}
        .st769{clip-path:url(#SVGID_00000057860799095589531710000003337933093789449895_);enable-background:new    ;}
        .st770{clip-path:url(#SVGID_00000018229836745098303730000010610403974358621879_);enable-background:new    ;}
        .st771{clip-path:url(#SVGID_00000010994289017701350770000009471154984332605330_);enable-background:new    ;}
        .st772{clip-path:url(#SVGID_00000104683387887217249610000012023815524781396134_);enable-background:new    ;}
        .st773{clip-path:url(#SVGID_00000152943395263832207550000009430504454966189500_);enable-background:new    ;}
        .st774{clip-path:url(#SVGID_00000076572267606022533460000005250365978384100497_);enable-background:new    ;}
        .st775{clip-path:url(#SVGID_00000145046378361675823250000000913385309916400290_);enable-background:new    ;}
        .st776{clip-path:url(#SVGID_00000080183232324920666290000000498316209158053549_);enable-background:new    ;}
        .st777{clip-path:url(#SVGID_00000040552385638522000070000012755391131590261391_);enable-background:new    ;}
        .st778{clip-path:url(#SVGID_00000160907794730748664560000007782572707095801262_);enable-background:new    ;}
        .st779{clip-path:url(#SVGID_00000027575632185423092820000003692190757868296851_);enable-background:new    ;}
        .st780{clip-path:url(#SVGID_00000013194531820523512430000017666306879677147035_);enable-background:new    ;}
        .st781{clip-path:url(#SVGID_00000153667542274455484700000000765843013106132109_);enable-background:new    ;}
        .st782{clip-path:url(#SVGID_00000048489508431776270180000014342041647359850374_);enable-background:new    ;}
        .st783{clip-path:url(#SVGID_00000123432947459696348880000010571181412726064005_);enable-background:new    ;}
        .st784{clip-path:url(#SVGID_00000142888354298728383390000013780474289682807183_);enable-background:new    ;}
        .st785{clip-path:url(#SVGID_00000106142155459112022260000014902169775713382317_);enable-background:new    ;}
        .st786{clip-path:url(#SVGID_00000114062925599550893270000013146993362071492017_);enable-background:new    ;}
        .st787{clip-path:url(#SVGID_00000039842009428770554030000003028653733611416224_);enable-background:new    ;}
        .st788{clip-path:url(#SVGID_00000082364798909385087650000017316527995748279702_);enable-background:new    ;}
        .st789{clip-path:url(#SVGID_00000013892111410495030500000013789450740217309114_);enable-background:new    ;}
        .st790{clip-path:url(#SVGID_00000073679929092904755360000010523872412245602697_);enable-background:new    ;}
        .st791{clip-path:url(#SVGID_00000052823415108940750210000003358600929720594817_);enable-background:new    ;}
        .st792{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
      </style>
<g class="${classes.rotatingCog}">
	<path class="st0" d="M491.6,476l-0.4,0c-0.6-0.1-4-0.5-7.5-2.5c-5.5-3-8.6-8.3-8.6-14.5v-12.4l0,0c0-0.4-0.1-1.6-0.7-2.2
		c-4.9-1.6-9.8-3.5-14.4-5.9l-0.7-0.2c-0.5-0.2-2.4-0.7-4-0.7c-0.2,0-0.3,0-0.4,0l-8.6,8.6c-0.9,0.9-6,5.6-12.7,5.6
		c-3.8,0-7.2-1.5-10-4.2L402,426.1l-0.3-0.3c-0.4-0.5-2.5-3.2-3.6-7c-1.7-6-0.2-12,4.2-16.3l8.8-8.8l0,0c0.3-0.3,0.8-1,1-1.7
		c-2.7-5.2-5-10.7-6.9-16.2c-0.8-1.3-1.6-2.2-2.1-2.7H391c-8.4,0-17-5.2-17-15v-30.4l0-0.4c0.1-0.6,0.5-4,2.5-7.5
		c3-5.5,8.3-8.6,14.5-8.6h12.4l0,0c0.4,0,0.8-0.1,1.2-0.2c2-6.4,4.5-12.6,7.6-18.5c0.1-0.7,0.1-1.2,0-1.5l-8.6-8.6
		c-0.4-0.4-2.7-2.8-4.2-6.2c-2.6-5.9-1.5-12,2.8-16.4l21.5-21.5l0.3-0.3c0.9-0.7,5.7-4.3,11.9-4.3c4.3,0,8.3,1.7,11.5,4.9l8.8,8.8
		l0,0c0.1,0.1,0.3,0.2,0.5,0.4c6.3-3.4,13-6.1,19.9-8.2c0.1-0.1,0.1-0.2,0.2-0.2V227c0-8.4,5.2-17,15-17h30.4l0.4,0
		c0.6,0.1,4,0.5,7.5,2.5c5.5,3,8.6,8.3,8.6,14.5v12.4l0,0c0,0.1,0,0.2,0,0.3c7,2.2,13.7,5,20.1,8.6c0,0,0,0,0,0l8.6-8.6
		c0.9-0.9,6-5.6,12.7-5.6c3.8,0,7.2,1.5,10,4.2l21.5,21.5l0.3,0.3c0.4,0.5,2.5,3.2,3.6,7c1.7,6,0.2,12-4.2,16.3l-8.8,8.8l0,0
		c-0.1,0.1-0.2,0.3-0.4,0.5c3.3,6.3,5.9,12.9,7.8,19.6c0.2,0.2,0.4,0.4,0.5,0.5H623c8.4,0,17,5.2,17,15v30.4l0,0.4
		c-0.1,0.6-0.5,4-2.5,7.5c-3,5.5-8.3,8.6-14.5,8.6h-12.4l0,0c-0.3,0-0.8,0.1-1.2,0.2c-2,6.1-4.5,12.1-7.6,17.8
		c-0.2,1.1-0.2,1.8-0.2,2.3l8.6,8.6c0.4,0.4,2.7,2.8,4.2,6.2c2.6,5.9,1.5,12-2.8,16.4L590.1,448l-0.3,0.3c-0.9,0.7-5.7,4.3-11.9,4.3
		c-4.3,0-8.3-1.7-11.5-4.9l-8.8-8.8l0,0c-0.4-0.4-1.1-0.8-1.6-1c-5,2.6-10.3,4.8-15.7,6.5c-1.2,0.7-2.7,1.8-3.3,2.5V459
		c0,8.4-5.2,17-15,17H491.6z M508,273c-39.1,0-71,31.9-71,71s31.9,71,71,71s71-31.9,71-71S547.1,273,508,273z"/>
	<g>
		<g>
			<g class="st1">
				<g class="st1">
					<defs>
						<path id="SVGID_1_" class="st1" d="M430.7,245.4c-0.1-0.1-0.3-0.3-0.4-0.4L430.7,245.4C430.7,245.4,430.7,245.4,430.7,245.4z"
							/>
					</defs>
					<clipPath id="SVGID_00000056416369236093453260000012404897779065398918_">
						<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000056416369236093453260000012404897779065398918_);enable-background:new    ;">
						<polyline class="st3" points="430.2,245 430.7,245.4 430.7,245.4 430.2,245 430.2,245 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000011741699502065665620000015641626574140611235_" class="st1" d="M384,358h-2c0-7,0-28.8,0-30h2
							C384,329.2,384,351,384,358z"/>
					</defs>
					<clipPath id="SVGID_00000001659667388609689890000006772595156104491701_">
						<use xlink:href="#SVGID_00000011741699502065665620000015641626574140611235_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000001659667388609689890000006772595156104491701_);enable-background:new    ;">
						<path class="st5" d="M384,358h-2c0-7,0-28.8,0-30h2C384,329.2,384,351,384,358"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000121238969002048385790000018292566895032927395_" class="st1" d="M384,328h-2c0-0.5,1-9,9-9l0,2
							C384.9,320.9,384,327.9,384,328z"/>
					</defs>
					<clipPath id="SVGID_00000180364618293495722230000010475539636291267515_">
						<use xlink:href="#SVGID_00000121238969002048385790000018292566895032927395_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000180364618293495722230000010475539636291267515_);enable-background:new    ;">
						<path class="st7" d="M384,328h-2c0-0.2,0.2-1.7,0.9-3.5l1.8,0.8C384.2,326.8,384,327.9,384,328"/>
						<path class="st8" d="M384.8,325.4l-1.8-0.8c0.3-0.7,0.7-1.4,1.2-2.1l1.6,1.3C385.3,324.3,385,324.8,384.8,325.4"/>
						<path class="st9" d="M385.7,323.7l-1.6-1.3c0.3-0.5,0.7-0.9,1.1-1.3l1.3,1.5C386.2,323,385.9,323.3,385.7,323.7"/>
						<path class="st10" d="M386.5,322.7l-1.3-1.5c0.3-0.3,0.7-0.6,1.1-0.9l1.1,1.7C387.1,322.2,386.8,322.4,386.5,322.7"/>
						<path class="st11" d="M387.4,322l-1.1-1.7c0.3-0.2,0.7-0.4,1.1-0.6l0.8,1.8C387.9,321.7,387.7,321.8,387.4,322"/>
						<path class="st12" d="M388.2,321.5l-0.8-1.8c0.4-0.2,0.8-0.3,1.2-0.4l0.6,1.9C388.8,321.3,388.5,321.4,388.2,321.5"/>
						<path class="st13" d="M389.1,321.2l-0.6-1.9c0.4-0.1,0.9-0.2,1.4-0.2l0.2,2C389.8,321.1,389.5,321.1,389.1,321.2"/>
						<path class="st14" d="M390.2,321l-0.2-2c0.3,0,0.7-0.1,1-0.1l0,2C390.7,321,390.5,321,390.2,321"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000115474084254368406700000006041415303324641433_" class="st1" d="M403.1,319L403.1,319
							C403.1,319,403,319,403.1,319L403.1,319C403,319,403.1,319,403.1,319z"/>
					</defs>
					<clipPath id="SVGID_00000061464660621834767930000008414750887666736789_">
						<use xlink:href="#SVGID_00000115474084254368406700000006041415303324641433_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000061464660621834767930000008414750887666736789_);enable-background:new    ;">
						<path class="st16" d="M403,319L403,319C403,319,403.1,319,403,319L403,319C403.1,319,403,319,403,319"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000064354511587410237680000000058966575125730442_" class="st1" d="M404,363l0,2c-5,0-12.6,0-13,0
							c-0.4,0-9,0-9-7h2c-0.1,4.9,6.6,5,7,5C391.4,363,399,363,404,363z"/>
					</defs>
					<clipPath id="SVGID_00000084519426641105386370000014653061880326258580_">
						<use xlink:href="#SVGID_00000064354511587410237680000000058966575125730442_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000084519426641105386370000014653061880326258580_);enable-background:new    ;">
						<path class="st7" d="M404,363l0,2c-5,0-12.6,0-13,0c-0.1,0-0.7,0-1.5-0.1l0.3-2c0.6,0.1,1.1,0.1,1.1,0.1
							C391.4,363,399,363,404,363"/>
						<path class="st5" d="M389.9,362.9l-0.3,2c-1.5-0.2-3.9-0.7-5.6-2.2l1.5-1.4C386.8,362.4,388.7,362.8,389.9,362.9"/>
						<path class="st18" d="M385.5,361.3l-1.5,1.4c-0.5-0.5-1-1.1-1.4-1.8l1.8-0.8C384.7,360.5,385,360.9,385.5,361.3"/>
						<path class="st5" d="M384.4,360l-1.8,0.8c-0.4-0.8-0.6-1.7-0.6-2.8h2C384,358.8,384.1,359.4,384.4,360"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000101078522707644838540000008713025551658376579_" class="st1" d="M457.9,427.5c0,0.1,0.1,0.2,0.1,0.2
							c0.2,0.2,0.4,0.3,0.6,0.4c-1.9-1.4-4.4-3.4-6.2-4.8C456,426,457.6,427.1,457.9,427.5z"/>
					</defs>
					<clipPath id="SVGID_00000173121167253968775490000005221340573189099667_">
						<use xlink:href="#SVGID_00000101078522707644838540000008713025551658376579_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000173121167253968775490000005221340573189099667_);enable-background:new    ;">
						<path class="st9" d="M458.6,428.1l-6.2-4.8c0,0,0.1,0,0.1,0.1L458.6,428.1C458.6,428.1,458.6,428.1,458.6,428.1"/>
						<path class="st8" d="M458.6,428.1l-6.2-4.8c0.1,0.1,0.2,0.1,0.2,0.2L458.6,428.1C458.7,428.2,458.6,428.2,458.6,428.1"/>
						<path class="st7" d="M458.7,428.2l-6-4.7c0.1,0.1,0.2,0.1,0.2,0.2L458.7,428.2C458.8,428.2,458.7,428.2,458.7,428.2"/>
						<path class="st5" d="M458.8,428.3l-5.9-4.6c0.1,0.1,0.2,0.1,0.2,0.2L458.8,428.3C458.8,428.3,458.8,428.3,458.8,428.3"/>
						<path class="st18" d="M458.8,428.3l-5.7-4.4c0.1,0.1,0.1,0.1,0.2,0.2L458.8,428.3C458.9,428.4,458.9,428.3,458.8,428.3"/>
						<path class="st20" d="M458.9,428.4l-5.6-4.3c0,0,0.1,0.1,0.1,0.1L458.9,428.4C458.9,428.4,458.9,428.4,458.9,428.4"/>
						<path class="st21" d="M459,428.4l-5.5-4.3c0,0,0.1,0.1,0.1,0.1L459,428.4C459,428.4,459,428.4,459,428.4"/>
						<path class="st22" d="M459,428.4l-5.4-4.2c0,0,0.1,0,0.1,0.1L459,428.4C459,428.5,459,428.4,459,428.4"/>
						<path class="st23" d="M459,428.5l-5.3-4.2c0,0,0.1,0,0.1,0.1L459,428.5C459,428.5,459,428.5,459,428.5"/>
						<path class="st24" d="M459,428.5l-5.3-4.1c0,0,0.1,0,0.1,0.1L459,428.5C459.1,428.5,459.1,428.5,459,428.5"/>
						<path class="st25" d="M459.1,428.5l-5.2-4.1c0,0,0.1,0,0.1,0.1L459.1,428.5C459.1,428.5,459.1,428.5,459.1,428.5"/>
						<path class="st26" d="M459.1,428.5l-5.1-4c0,0,0.1,0,0.1,0.1L459.1,428.5C459.1,428.6,459.1,428.5,459.1,428.5"/>
						<path class="st27" d="M459.1,428.6l-5.1-4c0,0,0.1,0,0.1,0.1L459.1,428.6C459.2,428.6,459.1,428.6,459.1,428.6"/>
						<path class="st28" d="M459.2,428.6l-5-3.9c0,0,0.1,0,0.1,0.1L459.2,428.6C459.2,428.6,459.2,428.6,459.2,428.6"/>
						<path class="st29" d="M459.2,428.6l-4.9-3.9c0,0,0.1,0,0.1,0.1L459.2,428.6C459.2,428.6,459.2,428.6,459.2,428.6"/>
						<path class="st30" d="M459.2,428.6l-4.9-3.9c0,0,0.1,0,0.1,0.1L459.2,428.6C459.2,428.6,459.2,428.6,459.2,428.6"/>
						<path class="st31" d="M459.2,428.7l-4.8-3.8c0,0,0.1,0,0.1,0.1L459.2,428.7C459.3,428.7,459.3,428.7,459.2,428.7"/>
						<path class="st32" d="M459.3,428.7l-4.7-3.8c0,0,0.1,0,0.1,0.1L459.3,428.7C459.3,428.7,459.3,428.7,459.3,428.7"/>
						<path class="st33" d="M459.3,428.7l-4.6-3.7c0,0,0.1,0,0.1,0.1L459.3,428.7C459.3,428.7,459.3,428.7,459.3,428.7"/>
						<path class="st34" d="M459.3,428.7l-4.6-3.7c0,0,0.1,0,0.1,0.1L459.3,428.7C459.3,428.7,459.3,428.7,459.3,428.7"/>
						<path class="st16" d="M459.3,428.7l-4.5-3.6c0,0,0.1,0,0.1,0.1L459.3,428.7C459.4,428.8,459.3,428.8,459.3,428.7"/>
						<path class="st35" d="M459.4,428.8l-4.4-3.6c0,0,0.1,0,0.1,0.1L459.4,428.8C459.4,428.8,459.4,428.8,459.4,428.8"/>
						<path class="st3" d="M459.4,428.8l-4.3-3.5c1.8,1.3,2.7,1.9,2.9,2.2l1.3,1.8C459.7,429.3,459.8,429.2,459.4,428.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000085961238407787574820000011586785527600301214_" class="st1" d="M409.4,419.4l-1.4,1.4
							c-0.3-0.4-5.7-7.1,0-12.7l1.4,1.4C405,413.8,409.4,419.3,409.4,419.4z"/>
					</defs>
					<clipPath id="SVGID_00000116202391893343880580000000370930736070722717_">
						<use xlink:href="#SVGID_00000085961238407787574820000011586785527600301214_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000116202391893343880580000000370930736070722717_);enable-background:new    ;">
						<path class="st18" d="M409.4,419.4l-1.4,1.4c-0.1-0.1-1-1.3-1.7-3l1.9-0.7C408.7,418.4,409.4,419.3,409.4,419.4"/>
						<path class="st5" d="M408.1,417.1l-1.9,0.7c-0.5-1.3-1-2.9-0.8-4.7l2,0.3C407.4,414.8,407.7,416,408.1,417.1"/>
						<path class="st7" d="M407.5,413.4l-2-0.3c0.1-0.7,0.2-1.4,0.6-2.2l1.9,0.7C407.7,412.3,407.5,412.8,407.5,413.4"/>
						<path class="st8" d="M407.9,411.7l-1.9-0.7c0.2-0.6,0.5-1.1,0.9-1.6l1.6,1.1C408.3,410.8,408.1,411.3,407.9,411.7"/>
						<path class="st9" d="M408.6,410.4l-1.6-1.1c0.3-0.4,0.7-0.8,1.1-1.2l1.4,1.4C409.1,409.8,408.8,410.1,408.6,410.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000049917718015069632870000004339157585181912751_" class="st1" d="M391,321l0-2c8,0,11.8,0,12,0
							c1.5,0,5.7-0.2,8.6-3.3l1.8,1c-3.8,4-8.6,4.3-10.4,4.3C402.8,321,399,321,391,321z"/>
					</defs>
					<clipPath id="SVGID_00000049214955984385876420000017747959096594696099_">
						<use xlink:href="#SVGID_00000049917718015069632870000004339157585181912751_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000049214955984385876420000017747959096594696099_);enable-background:new    ;">
						<path class="st14" d="M391,321l0-2c8,0,11.8,0,12,0c0.4,0,0.9,0,1.5-0.1l0.2,2c-0.7,0.1-1.3,0.1-1.7,0.1
							C402.8,321,399,321,391,321"/>
						<path class="st13" d="M404.7,320.9l-0.2-2c0.6-0.1,1.3-0.2,2.1-0.4l0.6,1.9C406.2,320.7,405.4,320.8,404.7,320.9"/>
						<path class="st12" d="M407.1,320.5l-0.6-1.9c0.6-0.1,1.1-0.3,1.7-0.6l0.9,1.7C408.5,320,407.8,320.3,407.1,320.5"/>
						<path class="st11" d="M409.2,319.7l-0.9-1.7c0.5-0.2,1-0.5,1.4-0.8l1.3,1.5C410.4,319.1,409.8,319.4,409.2,319.7"/>
						<path class="st10" d="M411,318.7l-1.3-1.5c0.5-0.3,0.9-0.6,1.3-1l1.6,1.2C412.2,317.9,411.6,318.3,411,318.7"/>
						<path class="st9" d="M412.7,317.4l-1.6-1.2c0.2-0.2,0.3-0.3,0.5-0.5l1.8,1C413.2,316.9,413,317.2,412.7,317.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000043421564342755987660000017722599739621784743_" class="st1" d="M414.6,371.8l-1.9,0.6
							c-1.1-1.9-4.5-7.4-8.7-7.4l0-2C409.4,363.1,413.3,369.4,414.6,371.8z"/>
					</defs>
					<clipPath id="SVGID_00000136407197672402399420000002782355451275150208_">
						<use xlink:href="#SVGID_00000043421564342755987660000017722599739621784743_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000136407197672402399420000002782355451275150208_);enable-background:new    ;">
						<path class="st18" d="M414.6,371.8l-1.9,0.6c-0.8-1.3-2.7-4.5-5.3-6.2l0.9-1.8C411.5,366.4,413.6,370.1,414.6,371.8"/>
						<path class="st5" d="M408.3,364.4l-0.9,1.8c-0.9-0.6-1.9-1-2.9-1.1l0.1-2C406,363.2,407.2,363.7,408.3,364.4"/>
						<path class="st7" d="M404.7,363l-0.1,2c-0.2,0-0.3,0-0.5,0l0-2C404.2,363,404.4,363,404.7,363"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000091708192007707045770000008897206084702094504_" class="st1" d="M409.4,409.5l-1.4-1.4
							c5.7-5.7,8.3-8.3,8.5-8.5l1.4,1.4C417.8,401.1,415.1,403.8,409.4,409.5z"/>
					</defs>
					<clipPath id="SVGID_00000096025219918174025270000013458070370127665060_">
						<use xlink:href="#SVGID_00000091708192007707045770000008897206084702094504_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000096025219918174025270000013458070370127665060_);enable-background:new    ;">
						<path class="st9" d="M409.4,409.5l-1.4-1.4c5.7-5.7,8.3-8.3,8.5-8.5l1.4,1.4C417.8,401.1,415.1,403.8,409.4,409.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000099639985912503017050000009586541108687308694_" class="st1" d="M420,284.3l-1.4,1.4
							c-3.5-3.5-8.9-8.9-9.2-9.2c-0.3-0.3-6.4-6.4-1.4-11.3l1.4,1.4c-3.5,3.4,1.2,8.2,1.4,8.5C411.1,275.4,416.5,280.8,420,284.3z"
							/>
					</defs>
					<clipPath id="SVGID_00000181794072892387543140000006499448542603755445_">
						<use xlink:href="#SVGID_00000099639985912503017050000009586541108687308694_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000181794072892387543140000006499448542603755445_);enable-background:new    ;">
						<path class="st18" d="M420,284.3l-1.4,1.4c-3.5-3.5-8.9-8.9-9.2-9.2c-0.1-0.1-1.5-1.5-2.5-3.5l1.9-0.7c0.8,1.5,1.9,2.6,2,2.8
							C411.1,275.4,416.5,280.8,420,284.3"/>
						<path class="st5" d="M408.9,272.4l-1.9,0.7c-0.6-1.2-1-2.6-0.8-4l2,0.3C408.1,270.4,408.4,271.4,408.9,272.4"/>
						<path class="st7" d="M408.1,269.3l-2-0.3c0.1-0.5,0.2-1.1,0.4-1.6l1.9,0.8C408.2,268.5,408.1,268.9,408.1,269.3"/>
						<path class="st8" d="M408.4,268.1l-1.9-0.8c0.2-0.4,0.4-0.8,0.7-1.2l1.6,1.1C408.7,267.6,408.5,267.8,408.4,268.1"/>
						<path class="st9" d="M408.9,267.3l-1.6-1.1c0.2-0.3,0.5-0.6,0.8-0.9l1.4,1.4C409.2,266.8,409,267.1,408.9,267.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000148658832096255023610000017300256303272650422_" class="st1" d="M413.4,316.7l-1.8-1
							c2-7.3,4.9-14.3,8.4-20.9l1.8,1C418.3,302.2,415.6,309.3,413.4,316.7z"/>
					</defs>
					<clipPath id="SVGID_00000046339278396216845820000001995738267676151947_">
						<use xlink:href="#SVGID_00000148658832096255023610000017300256303272650422_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000046339278396216845820000001995738267676151947_);enable-background:new    ;">
						<path class="st7" d="M413.4,316.7l-1.8-1c0.9-3.4,2-6.7,3.3-9.9l1.9,0.9C415.5,309.9,414.4,313.3,413.4,316.7"/>
						<path class="st8" d="M416.8,306.7l-1.9-0.9c1.5-3.8,3.2-7.4,5.1-11l1.8,1C419.9,299.3,418.3,302.9,416.8,306.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000126320036694362525350000017530148372297776829_" class="st1" d="M422,389.3l-2,0.4
							c-3-5.5-5.5-11.3-7.4-17.3l1.9-0.6C416.5,377.5,418.9,383.4,422,389.3z"/>
					</defs>
					<clipPath id="SVGID_00000025431785312529515720000018143154435877948326_">
						<use xlink:href="#SVGID_00000126320036694362525350000017530148372297776829_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000025431785312529515720000018143154435877948326_);enable-background:new    ;">
						<path class="st18" d="M422,389.3l-2,0.4c-0.9-1.7-1.8-3.4-2.6-5.2l1.9-0.7C420.2,385.6,421.1,387.5,422,389.3"/>
						<path class="st5" d="M419.3,383.8l-1.9,0.7c-1.8-3.9-3.4-8-4.8-12.2l1.9-0.6C415.9,375.7,417.5,379.8,419.3,383.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000134959518997983547320000002297646427814508450_" class="st1" d="M421.8,295.8l-1.8-1
							c0.5-2.8,0.8-6.9-1.4-9.1l1.4-1.4C423,287.3,422.6,292.1,421.8,295.8z"/>
					</defs>
					<clipPath id="SVGID_00000173140776050730595460000000323894048015741315_">
						<use xlink:href="#SVGID_00000134959518997983547320000002297646427814508450_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000173140776050730595460000000323894048015741315_);enable-background:new    ;">
						<path class="st7" d="M421.8,295.8l-1.8-1c0.1-0.3,0.1-0.7,0.2-1l1.9,0.7C422,295,421.9,295.4,421.8,295.8"/>
						<path class="st5" d="M422.1,294.5l-1.9-0.7c0.3-2,0.3-4.3-0.4-6.2l1.8-0.8C422.6,289.2,422.5,292,422.1,294.5"/>
						<path class="st18" d="M421.6,286.8l-1.8,0.8c-0.3-0.7-0.7-1.4-1.2-1.9l1.4-1.4C420.7,285,421.3,285.9,421.6,286.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000038386609586538247850000015899847193812842156_" class="st1" d="M417.9,401l-1.4-1.4
							c0.2-0.2,4.7-4.2,3.6-9.9l2-0.4C423.4,396.2,418.1,400.8,417.9,401z"/>
					</defs>
					<clipPath id="SVGID_00000031917681477538111440000014844888631247810444_">
						<use xlink:href="#SVGID_00000038386609586538247850000015899847193812842156_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000031917681477538111440000014844888631247810444_);enable-background:new    ;">
						<path class="st10" d="M417.9,401l-1.4-1.4c0,0,0.1-0.1,0.1-0.1l1.4,1.4C418,400.9,417.9,401,417.9,401"/>
						<path class="st9" d="M418.1,400.8l-1.4-1.4c0.3-0.3,0.9-0.8,1.5-1.7l1.6,1.1C419,399.9,418.4,400.6,418.1,400.8"/>
						<path class="st8" d="M419.8,398.9l-1.6-1.1c0.5-0.6,0.9-1.4,1.3-2.3l1.9,0.7C420.9,397.2,420.3,398.1,419.8,398.9"/>
						<path class="st7" d="M421.3,396.1l-1.9-0.7c0.4-0.8,0.6-1.8,0.7-2.8l2,0.2C422.1,394,421.7,395.1,421.3,396.1"/>
						<path class="st5" d="M422.2,392.7l-2-0.2c0.1-0.9,0.1-1.8-0.1-2.8l2-0.4C422.3,390.5,422.3,391.7,422.2,392.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000060744646747516858640000014394226659276906390_" class="st1" d="M430.6,440.6l-1.4,1.4
							c-4.9-4.9-20.4-20.4-21.2-21.2l1.4-1.4C410.2,420.2,425.7,435.6,430.6,440.6z"/>
					</defs>
					<clipPath id="SVGID_00000041990370400408890650000011233454300500807050_">
						<use xlink:href="#SVGID_00000060744646747516858640000014394226659276906390_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000041990370400408890650000011233454300500807050_);enable-background:new    ;">
						<path class="st18" d="M430.6,440.6l-1.4,1.4c-4.9-4.9-20.4-20.4-21.2-21.2l1.4-1.4C410.2,420.2,425.7,435.6,430.6,440.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000150066474417833441500000000310914155814340500_" class="st1" d="M430.2,245l0.4,0.4c0,0,0,0,0,0
							C430.5,245.3,430.4,245.1,430.2,245z"/>
					</defs>
					<clipPath id="SVGID_00000016796464597320536040000005312518653367133087_">
						<use xlink:href="#SVGID_00000150066474417833441500000000310914155814340500_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000016796464597320536040000005312518653367133087_);enable-background:new    ;">
						<polyline class="st10" points="430.6,245.4 430.2,245 430.2,245 430.6,245.4 430.6,245.4 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000133491400223026653070000000429948559532450178_" class="st1" d="M409.4,266.6l-1.4-1.4
							c4.9-4.9,20.4-20.4,21.2-21.2l1.4,1.4C429.8,246.2,414.4,261.7,409.4,266.6z"/>
					</defs>
					<clipPath id="SVGID_00000166671663372789594890000008556687784268642484_">
						<use xlink:href="#SVGID_00000133491400223026653070000000429948559532450178_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000166671663372789594890000008556687784268642484_);enable-background:new    ;">
						<path class="st9" d="M409.4,266.6l-1.4-1.4c4.9-4.9,20.4-20.4,21.2-21.2l1.4,1.4C429.8,246.2,414.4,261.7,409.4,266.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000096762606909132431540000015308597969358219951_" class="st1" d="M440.5,440.6L440.5,440.6
							c-0.2,0.2-3.3,3.3-7,3.3h0C437.2,443.9,440.4,440.7,440.5,440.6z"/>
					</defs>
					<clipPath id="SVGID_00000021803277647147433910000012622601999088262552_">
						<use xlink:href="#SVGID_00000096762606909132431540000015308597969358219951_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000021803277647147433910000012622601999088262552_);enable-background:new    ;">
						<path class="st3" d="M440.5,440.6L440.5,440.6c-0.2,0.2-3.3,3.3-7,3.3h0C437.2,443.9,440.4,440.7,440.5,440.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000041977845029586492970000015704374368126432172_" class="st1" d="M436.1,241.5L436.1,241.5
							c1.9,0,3.9,0.6,5.8,2.5l0,0C440.1,242.1,438,241.5,436.1,241.5z"/>
					</defs>
					<clipPath id="SVGID_00000039855026787155317140000003378842501839513728_">
						<use xlink:href="#SVGID_00000041977845029586492970000015704374368126432172_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000039855026787155317140000003378842501839513728_);enable-background:new    ;">
						<path class="st16" d="M436.1,241.5L436.1,241.5c0.1,0,0.2,0,0.3,0h0C436.3,241.5,436.2,241.5,436.1,241.5"/>
						<path class="st34" d="M436.4,241.5L436.4,241.5c0.4,0,0.8,0.1,1.2,0.1h0C437.2,241.5,436.8,241.5,436.4,241.5"/>
						<path class="st33" d="M437.6,241.6L437.6,241.6c0.4,0.1,0.8,0.2,1.1,0.3h0C438.3,241.8,437.9,241.7,437.6,241.6"/>
						<path class="st32" d="M438.7,241.9L438.7,241.9c0.4,0.1,0.9,0.3,1.3,0.6h0C439.5,242.2,439.1,242.1,438.7,241.9"/>
						<path class="st31" d="M440,242.5L440,242.5c0.7,0.4,1.3,0.9,2,1.5h0C441.3,243.4,440.6,242.9,440,242.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000178892467750976912470000014627523174250078114_" class="st1" d="M430.6,245.4c-0.1-0.1-0.3-0.3-0.4-0.4
							l-1-1c0.2-0.1,3.1-2.5,6.9-2.5c1.9,0,3.9,0.6,5.8,2.5l-1.4,1.4c-1.5-1.5-3-1.9-4.4-1.9C433.1,243.5,430.6,245.4,430.6,245.4z"
							/>
					</defs>
					<clipPath id="SVGID_00000049908544688677435580000003744554453965532051_">
						<use xlink:href="#SVGID_00000178892467750976912470000014627523174250078114_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000049908544688677435580000003744554453965532051_);enable-background:new    ;">
						<path class="st10" d="M430.6,245.4l-1.4-1.4c0,0,0.3-0.2,0.7-0.5l1.2,1.6C430.8,245.3,430.6,245.4,430.6,245.4"/>
						<path class="st11" d="M431.1,245.1l-1.2-1.6c0.5-0.3,1.1-0.7,1.9-1.1l0.8,1.8C432,244.6,431.5,244.9,431.1,245.1"/>
						<path class="st12" d="M432.7,244.3l-0.8-1.8c0.5-0.2,1.1-0.5,1.8-0.6l0.5,1.9C433.6,243.9,433.1,244.1,432.7,244.3"/>
						<path class="st13" d="M434.1,243.8l-0.5-1.9c0.5-0.1,1-0.2,1.6-0.3l0.2,2C435,243.6,434.5,243.7,434.1,243.8"/>
						<path class="st14" d="M435.4,243.5l-0.2-2c0.3,0,0.6,0,0.9,0c0.2,0,0.4,0,0.6,0l-0.1,2c-0.1,0-0.3,0-0.4,0
							C435.9,243.5,435.6,243.5,435.4,243.5"/>
						<path class="st51" d="M436.6,243.5l0.1-2c0.4,0,0.9,0.1,1.4,0.2l-0.5,1.9C437.2,243.6,436.9,243.5,436.6,243.5"/>
						<path class="st52" d="M437.6,243.7l0.5-1.9c0.5,0.1,1,0.3,1.6,0.6l-0.9,1.8C438.4,243.9,438,243.8,437.6,243.7"/>
						<path class="st53" d="M438.8,244.1l0.9-1.8c0.8,0.4,1.6,0.9,2.3,1.7l-1.4,1.4C439.9,244.8,439.3,244.4,438.8,244.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000043453742507546953370000000939283057783688371_" class="st1" d="M449.7,431.4L449.7,431.4
							c-3.5,3.5-8.9,8.9-9.2,9.2h0C440.8,440.3,446.2,434.9,449.7,431.4z"/>
					</defs>
					<clipPath id="SVGID_00000118394716562716065990000005581000427130244485_">
						<use xlink:href="#SVGID_00000043453742507546953370000000939283057783688371_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000118394716562716065990000005581000427130244485_);enable-background:new    ;">
						<path class="st3" d="M449.7,431.4L449.7,431.4c-3.5,3.5-8.9,8.9-9.2,9.2h0C440.8,440.3,446.2,434.9,449.7,431.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000070811331800606831690000003826766236881262754_" class="st1" d="M442,244L442,244
							c5.7,5.7,8.4,8.3,8.5,8.5l0,0C450.3,252.4,447.6,249.7,442,244z"/>
					</defs>
					<clipPath id="SVGID_00000119817598781856015870000005258925017087855776_">
						<use xlink:href="#SVGID_00000070811331800606831690000003826766236881262754_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000119817598781856015870000005258925017087855776_);enable-background:new    ;">
						<path class="st31" d="M442,244L442,244c5.7,5.7,8.4,8.3,8.5,8.5h0C450.3,252.4,447.6,249.7,442,244"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000145039256351698664170000004562910386922919329_" class="st1" d="M455.2,429.6L455.2,429.6
							c-2,0-4.1,0.4-5.5,1.8h0C451.1,430,453.2,429.6,455.2,429.6z"/>
					</defs>
					<clipPath id="SVGID_00000066513142856679810120000009155216148025483410_">
						<use xlink:href="#SVGID_00000145039256351698664170000004562910386922919329_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000066513142856679810120000009155216148025483410_);enable-background:new    ;">
						<path class="st3" d="M455.2,429.6L455.2,429.6c-2,0-4.1,0.4-5.5,1.8h0C451.1,430,453.2,429.6,455.2,429.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000125597543494500367380000013833544708051222959_" class="st1" d="M448.3,430l1.4,1.4
							c-3.5,3.5-8.9,8.9-9.2,9.2c-0.2,0.2-3.3,3.3-7,3.3c-1.4,0-2.9-0.5-4.3-1.9l1.4-1.4c1,1,2,1.3,2.9,1.3c2.8,0,5.5-2.6,5.6-2.7
							C439.4,438.9,444.8,433.5,448.3,430z"/>
					</defs>
					<clipPath id="SVGID_00000030449575064239747030000005608382215698330546_">
						<use xlink:href="#SVGID_00000125597543494500367380000013833544708051222959_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000030449575064239747030000005608382215698330546_);enable-background:new    ;">
						<path class="st11" d="M448.3,430l1.4,1.4c-3.5,3.5-8.9,8.9-9.2,9.2c0,0,0,0-0.1,0.1l-1.4-1.4c0,0,0,0,0.1-0.1
							C439.4,438.9,444.8,433.5,448.3,430"/>
						<path class="st10" d="M439.1,439.2l1.4,1.4c-0.2,0.2-0.9,0.8-1.8,1.5l-1.1-1.7C438.4,439.9,438.9,439.4,439.1,439.2"/>
						<path class="st9" d="M437.6,440.4l1.1,1.7c-0.6,0.4-1.3,0.8-2,1.1l-0.7-1.9C436.6,441.1,437.1,440.7,437.6,440.4"/>
						<path class="st8" d="M436,441.3l0.7,1.9c-0.6,0.2-1.2,0.4-1.9,0.6l-0.3-2C435,441.7,435.5,441.5,436,441.3"/>
						<path class="st7" d="M434.5,441.8l0.3,2c-0.4,0.1-0.8,0.1-1.2,0.1c-0.2,0-0.4,0-0.7,0l0.2-2c0.1,0,0.3,0,0.4,0
							C433.9,441.9,434.2,441.9,434.5,441.8"/>
						<path class="st5" d="M433.1,441.9l-0.2,2c-0.9-0.1-1.8-0.4-2.7-1.1l1.1-1.6C431.9,441.6,432.5,441.8,433.1,441.9"/>
						<path class="st18" d="M431.3,441.1l-1.1,1.6c-0.3-0.2-0.6-0.5-0.9-0.8l1.4-1.4C430.9,440.8,431.1,441,431.3,441.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000055684247968887751920000009361232160075788961_" class="st1" d="M450.4,252.5L450.4,252.5
							c0.2,0.2,3,3.4,7.3,3.7h0C453.4,255.8,450.6,252.7,450.4,252.5z"/>
					</defs>
					<clipPath id="SVGID_00000061472102615668113090000017331141828286533009_">
						<use xlink:href="#SVGID_00000055684247968887751920000009361232160075788961_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000061472102615668113090000017331141828286533009_);enable-background:new    ;">
						<path class="st31" d="M450.4,252.5L450.4,252.5c0.1,0.1,1.2,1.3,3,2.4h0C451.6,253.8,450.5,252.6,450.4,252.5"/>
						<path class="st32" d="M453.4,254.9L453.4,254.9c0.6,0.3,1.2,0.6,1.9,0.8h0C454.6,255.5,454,255.2,453.4,254.9"/>
						<path class="st33" d="M455.3,255.7L455.3,255.7c0.5,0.2,1,0.3,1.5,0.4h0C456.3,256,455.8,255.9,455.3,255.7"/>
						<path class="st34" d="M456.8,256.1L456.8,256.1c0.3,0.1,0.6,0.1,0.9,0.1h0C457.4,256.2,457.1,256.1,456.8,256.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000144314071151851953290000006798339420163564680_" class="st1" d="M440.5,245.4l1.4-1.4
							c5.7,5.7,8.3,8.3,8.5,8.5l-1.4,1.4C448.9,253.8,446.2,251.1,440.5,245.4z"/>
					</defs>
					<clipPath id="SVGID_00000150104744790378244340000003660939654981668781_">
						<use xlink:href="#SVGID_00000144314071151851953290000006798339420163564680_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000150104744790378244340000003660939654981668781_);enable-background:new    ;">
						<path class="st53" d="M440.5,245.4l1.4-1.4c5.7,5.7,8.3,8.3,8.5,8.5l-1.4,1.4C448.9,253.8,446.2,251.1,440.5,245.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000070077060244775468160000010482180912926325393_" class="st1" d="M461.3,430.4L461.3,430.4
							C461.4,430.4,461.4,430.3,461.3,430.4L461.3,430.4C461.4,430.3,461.4,430.4,461.3,430.4z"/>
					</defs>
					<clipPath id="SVGID_00000065759156270847709080000016779069065586841743_">
						<use xlink:href="#SVGID_00000070077060244775468160000010482180912926325393_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000065759156270847709080000016779069065586841743_);enable-background:new    ;">
						<path class="st31" d="M461.4,430.3L461.4,430.3C461.4,430.3,461.4,430.3,461.4,430.3L461.4,430.3
							C461.4,430.3,461.4,430.3,461.4,430.3"/>
						<path class="st32" d="M461.4,430.3L461.4,430.3C461.4,430.3,461.4,430.3,461.4,430.3L461.4,430.3
							C461.4,430.3,461.4,430.3,461.4,430.3"/>
						<path class="st33" d="M461.4,430.3L461.4,430.3C461.4,430.3,461.4,430.3,461.4,430.3L461.4,430.3
							C461.4,430.3,461.4,430.3,461.4,430.3"/>
						<polyline class="st34" points="461.4,430.3 461.4,430.3 461.4,430.3 461.4,430.3 461.4,430.3 						"/>
						<polyline class="st16" points="461.4,430.3 461.4,430.3 461.4,430.3 461.4,430.3 461.4,430.3 						"/>
						<polyline class="st35" points="461.4,430.3 461.4,430.3 461.4,430.4 461.4,430.4 461.4,430.3 						"/>
						<path class="st3" d="M461.4,430.4L461.4,430.4C461.4,430.4,461.4,430.4,461.4,430.4L461.4,430.4
							C461.3,430.4,461.4,430.4,461.4,430.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000153678069488486391330000015578805659323545233_" class="st1" d="M478.8,435.3l-1,1.8
							c-5.7-1.8-11.2-4.1-16.5-6.8l-9.1-7C467.4,431.2,473,433.4,478.8,435.3z"/>
					</defs>
					<clipPath id="SVGID_00000108282613755633991370000016537557464237608626_">
						<use xlink:href="#SVGID_00000153678069488486391330000015578805659323545233_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000108282613755633991370000016537557464237608626_);enable-background:new    ;">
						<path class="st5" d="M478.8,435.3l-1,1.8c-5.7-1.8-11.2-4.1-16.5-6.8l-9.1-7C467.4,431.2,473,433.4,478.8,435.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000178206817233139129050000015959828129750918070_" class="st1" d="M457.9,427.5c0,0,0,0.1,0.1,0.1
							c0,0,0,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c1.1,0.8,2.1,1.6,3.2,2.4c0.4,0.4-2.8-0.7-6.1-0.7c-2.1,0-4.1,0.4-5.5,1.8l-1.4-1.4
							c1.9-1.9,4.5-2.4,6.9-2.4C457.1,427.6,458.2,427.9,457.9,427.5z"/>
					</defs>
					<clipPath id="SVGID_00000032609346157963267230000009050276648048277951_">
						<use xlink:href="#SVGID_00000178206817233139129050000015959828129750918070_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000032609346157963267230000009050276648048277951_);enable-background:new    ;">
						<path class="st3" d="M452.3,423.3l9.1,7c0,0,0,0,0,0.1l-7.9-6.1C453.2,423.9,452.8,423.6,452.3,423.3"/>
						<path class="st35" d="M453.5,424.2l7.9,6.1c0,0,0,0,0,0l-7.7-6C453.7,424.3,453.6,424.2,453.5,424.2"/>
						<path class="st16" d="M453.7,424.3l7.7,6c0,0,0,0,0,0l-7.5-5.9C453.9,424.4,453.8,424.4,453.7,424.3"/>
						<path class="st34" d="M453.9,424.5l7.5,5.9c0,0,0,0,0,0l-7.3-5.7C454.1,424.6,454,424.5,453.9,424.5"/>
						<path class="st33" d="M454.1,424.6l7.3,5.7c0,0,0,0,0,0l-7.1-5.6C454.2,424.7,454.2,424.7,454.1,424.6"/>
						<path class="st32" d="M454.3,424.7l7.1,5.6c0,0,0,0,0,0L454.3,424.7C454.3,424.8,454.3,424.8,454.3,424.7"/>
						<path class="st31" d="M454.4,424.8l7,5.6c0,0,0,0,0,0L454.4,424.8C454.4,424.8,454.4,424.8,454.4,424.8"/>
						<path class="st30" d="M454.4,424.8l6.9,5.5c0,0,0,0,0,0L454.4,424.8C454.5,424.9,454.5,424.9,454.4,424.8"/>
						<path class="st29" d="M454.5,424.9l6.9,5.5c0,0,0,0,0,0L454.5,424.9C454.5,424.9,454.5,424.9,454.5,424.9"/>
						<path class="st28" d="M454.6,424.9l6.8,5.4c0,0,0,0,0,0L454.6,424.9C454.6,425,454.6,424.9,454.6,424.9"/>
						<path class="st27" d="M454.6,425l6.7,5.4c0,0,0,0,0,0L454.6,425C454.7,425,454.6,425,454.6,425"/>
						<path class="st26" d="M454.7,425l6.7,5.3c0,0,0,0,0,0L454.7,425C454.7,425.1,454.7,425,454.7,425"/>
						<path class="st25" d="M454.7,425.1l6.6,5.3c0,0,0,0,0,0L454.7,425.1C454.8,425.1,454.8,425.1,454.7,425.1"/>
						<path class="st24" d="M454.8,425.1l6.5,5.3c0,0,0,0,0,0L454.8,425.1C454.9,425.1,454.8,425.1,454.8,425.1"/>
						<path class="st23" d="M454.9,425.2l6.5,5.2c0,0,0,0,0,0L454.9,425.2C454.9,425.2,454.9,425.2,454.9,425.2"/>
						<path class="st22" d="M454.9,425.2l6.4,5.2c0,0,0,0,0,0L454.9,425.2C455,425.2,455,425.2,454.9,425.2"/>
						<path class="st21" d="M455,425.2l6.3,5.1c0,0,0,0,0,0L455,425.2C455,425.3,455,425.3,455,425.2"/>
						<path class="st20" d="M455,425.3l6.3,5.1c0,0,0,0,0,0L455,425.3C455.1,425.3,455.1,425.3,455,425.3"/>
						<path class="st18" d="M455.1,425.3l6.2,5c0,0,0,0,0,0L455.1,425.3C455.1,425.4,455.1,425.3,455.1,425.3"/>
						<path class="st5" d="M455.2,425.4l6.1,5c0,0,0,0,0,0L455.2,425.4C455.2,425.4,455.2,425.4,455.2,425.4"/>
						<path class="st7" d="M455.2,425.4l6.1,5c0,0,0,0,0,0L455.2,425.4C455.3,425.4,455.2,425.4,455.2,425.4"/>
						<path class="st8" d="M455.3,425.5l6,4.9c0,0,0,0,0,0L455.3,425.5C455.3,425.5,455.3,425.5,455.3,425.5"/>
						<path class="st9" d="M455.3,425.5l5.9,4.9c0,0,0,0,0,0L455.3,425.5C455.4,425.5,455.4,425.5,455.3,425.5"/>
						<path class="st10" d="M455.4,425.5l5.9,4.8c0,0,0,0,0,0L455.4,425.5C455.4,425.6,455.4,425.6,455.4,425.5"/>
						<path class="st11" d="M455.5,425.6l5.8,4.8c0,0,0,0,0,0L455.5,425.6C455.5,425.6,455.5,425.6,455.5,425.6"/>
						<path class="st12" d="M455.5,425.6l5.7,4.7c0,0,0,0,0,0L455.5,425.6C455.6,425.7,455.5,425.6,455.5,425.6"/>
						<path class="st13" d="M455.6,425.7l5.7,4.7c0,0,0,0,0,0L455.6,425.7C455.6,425.7,455.6,425.7,455.6,425.7"/>
						<path class="st14" d="M455.6,425.7l5.6,4.7c0,0,0,0,0,0L455.6,425.7C455.7,425.7,455.7,425.7,455.6,425.7"/>
						<path class="st51" d="M455.7,425.7l5.5,4.6c0,0,0,0,0,0L455.7,425.7C455.7,425.8,455.7,425.8,455.7,425.7"/>
						<path class="st52" d="M455.7,425.8l5.5,4.6c0,0,0,0,0,0L455.7,425.8C455.8,425.8,455.8,425.8,455.7,425.8"/>
						<path class="st53" d="M455.8,425.8l5.4,4.5c0,0,0,0,0,0L455.8,425.8C455.8,425.8,455.8,425.8,455.8,425.8"/>
						<path class="st63" d="M455.8,425.9l5.4,4.5c0,0,0,0,0,0L455.8,425.9C455.9,425.9,455.9,425.9,455.8,425.9"/>
						<path class="st64" d="M455.9,425.9l5.3,4.5c0,0,0,0,0,0L455.9,425.9C455.9,425.9,455.9,425.9,455.9,425.9"/>
						<path class="st65" d="M455.9,425.9l5.3,4.5c0,0,0,0,0,0L455.9,425.9C455.9,425.9,455.9,425.9,455.9,425.9"/>
						<path class="st66" d="M456,425.9l5.2,4.4c0,0,0,0,0,0L456,425.9C456,426,456,425.9,456,425.9"/>
						<path class="st67" d="M456,426l5.2,4.4c0,0,0,0,0,0L456,426C456,426,456,426,456,426"/>
						<path class="st68" d="M456,426l5.1,4.4c0,0,0,0,0,0L456,426C456.1,426,456,426,456,426"/>
						<path class="st69" d="M456.1,426l5.1,4.3c0,0,0,0,0,0L456.1,426C456.1,426,456.1,426,456.1,426"/>
						<path class="st70" d="M456.1,426l5,4.3c0,0,0,0,0,0L456.1,426C456.1,426.1,456.1,426.1,456.1,426"/>
						<path class="st71" d="M456.1,426.1l5,4.3c0,0,0,0,0,0L456.1,426.1C456.2,426.1,456.1,426.1,456.1,426.1"/>
						<path class="st72" d="M456.2,426.1l4.9,4.3c0,0,0,0,0,0L456.2,426.1C456.2,426.1,456.2,426.1,456.2,426.1"/>
						<path class="st73" d="M456.2,426.1l4.9,4.2c0,0,0,0,0,0L456.2,426.1C456.2,426.1,456.2,426.1,456.2,426.1"/>
						<path class="st74" d="M456.2,426.1l4.8,4.2c0,0,0,0,0,0L456.2,426.1C456.3,426.2,456.3,426.2,456.2,426.1"/>
						<path class="st75" d="M456.3,426.2l4.8,4.2c0,0,0,0,0,0L456.3,426.2C456.3,426.2,456.3,426.2,456.3,426.2"/>
						<path class="st76" d="M456.3,426.2l4.8,4.1c0,0,0,0,0,0L456.3,426.2C456.3,426.2,456.3,426.2,456.3,426.2"/>
						<path class="st77" d="M456.3,426.2l4.7,4.1c0,0,0,0,0,0L456.3,426.2C456.4,426.2,456.4,426.2,456.3,426.2"/>
						<path class="st78" d="M456.4,426.2l4.7,4.1c0,0,0,0,0,0L456.4,426.2C456.4,426.3,456.4,426.3,456.4,426.2"/>
						<path class="st77" d="M456.4,426.3l4.6,4.1c0,0,0,0,0,0L456.4,426.3C456.5,426.3,456.5,426.3,456.4,426.3"/>
						<path class="st76" d="M456.5,426.3l4.5,4c0,0,0,0,0,0L456.5,426.3C456.6,426.4,456.5,426.4,456.5,426.3"/>
						<path class="st75" d="M456.6,426.4l4.4,3.9c0,0,0,0,0,0L456.6,426.4C456.6,426.4,456.6,426.4,456.6,426.4"/>
						<path class="st74" d="M456.6,426.4l4.3,3.9c0,0,0,0,0,0L456.6,426.4C456.7,426.5,456.7,426.5,456.6,426.4"/>
						<path class="st73" d="M456.7,426.5l4.2,3.8c0,0,0,0,0,0L456.7,426.5C456.8,426.5,456.7,426.5,456.7,426.5"/>
						<path class="st72" d="M456.8,426.5l4.1,3.8c0,0,0,0,0,0L456.8,426.5C456.8,426.6,456.8,426.6,456.8,426.5"/>
						<path class="st71" d="M456.8,426.6l4,3.7c0,0,0,0,0,0L456.8,426.6C456.9,426.6,456.9,426.6,456.8,426.6"/>
						<path class="st70" d="M456.9,426.6l3.9,3.7c0,0,0,0,0,0L456.9,426.6C456.9,426.7,456.9,426.6,456.9,426.6"/>
						<path class="st69" d="M457,426.7l3.9,3.6c0,0,0,0,0,0L457,426.7C457,426.7,457,426.7,457,426.7"/>
						<path class="st68" d="M457,426.7l3.8,3.6c0,0,0,0,0,0L457,426.7C457,426.7,457,426.7,457,426.7"/>
						<path class="st67" d="M457.1,426.7l3.7,3.6c0,0,0,0,0,0L457.1,426.7C457.1,426.8,457.1,426.8,457.1,426.7"/>
						<path class="st66" d="M457.1,426.8l3.6,3.5c0,0,0,0,0,0L457.1,426.8C457.1,426.8,457.1,426.8,457.1,426.8"/>
						<path class="st65" d="M457.1,426.8l3.5,3.5c0,0,0,0,0,0L457.1,426.8C457.2,426.8,457.2,426.8,457.1,426.8"/>
						<path class="st64" d="M457.2,426.9l3.5,3.4c0,0,0,0,0,0L457.2,426.9C457.2,426.9,457.2,426.9,457.2,426.9"/>
						<path class="st63" d="M457.2,426.9l3.4,3.4c0,0,0,0,0,0L457.2,426.9C457.3,426.9,457.2,426.9,457.2,426.9"/>
						<path class="st53" d="M457.3,426.9l3.3,3.4c0,0,0,0-0.1,0L457.3,426.9C457.3,427,457.3,426.9,457.3,426.9"/>
						<path class="st52" d="M457.3,427l3.2,3.3c0,0,0,0-0.1,0L457.3,427C457.4,427,457.4,427,457.3,427"/>
						<path class="st51" d="M457.4,427l3.1,3.2c0,0,0,0-0.1,0L457.4,427C457.4,427.1,457.4,427,457.4,427"/>
						<path class="st14" d="M457.5,427.1l3,3.2c0,0,0,0-0.1,0L457.5,427.1C457.5,427.1,457.5,427.1,457.5,427.1"/>
						<path class="st13" d="M457.5,427.1l2.9,3.1c0,0,0,0-0.1,0L457.5,427.1C457.6,427.2,457.5,427.1,457.5,427.1"/>
						<path class="st12" d="M457.6,427.2l2.7,3c0,0-0.1,0-0.1,0L457.6,427.2C457.6,427.2,457.6,427.2,457.6,427.2"/>
						<path class="st11" d="M457.7,427.2l2.5,2.9c0,0-0.1,0-0.1,0L457.7,427.2C457.7,427.3,457.7,427.3,457.7,427.2"/>
						<path class="st10" d="M457.7,427.3l2.3,2.9c-0.1,0-0.1,0-0.2,0L457.7,427.3C457.8,427.4,457.8,427.3,457.7,427.3"/>
						<path class="st9" d="M457.8,427.4l2.1,2.7c-0.1,0-0.2,0-0.2,0L457.8,427.4C457.9,427.5,457.9,427.4,457.8,427.4"/>
						<path class="st8" d="M457.9,427.5l1.8,2.6c-0.2,0-0.3-0.1-0.5-0.1l-1.3-2.4C457.9,427.6,457.9,427.5,457.9,427.5"/>
						<path class="st7" d="M457.9,427.6l1.3,2.4c-1.1-0.2-2.5-0.4-4-0.4c-0.7,0-1.4,0-2.1,0.2l-0.4-2c0.8-0.2,1.7-0.2,2.5-0.2
							C456.8,427.6,457.9,427.8,457.9,427.6"/>
						<path class="st8" d="M452.7,427.8l0.4,2c-0.6,0.1-1.1,0.3-1.7,0.5l-0.8-1.8C451.3,428.2,452,428,452.7,427.8"/>
						<path class="st9" d="M450.6,428.4l0.8,1.8c-0.4,0.1-0.7,0.3-1,0.5l-1.2-1.6C449.7,428.9,450.2,428.6,450.6,428.4"/>
						<path class="st10" d="M449.3,429.1l1.2,1.6c-0.2,0.2-0.5,0.3-0.7,0.6l-1.4-1.4C448.7,429.6,449,429.4,449.3,429.1"/>
						<path class="st11" d="M448.4,429.9l1.4,1.4c0,0,0,0,0,0L448.4,429.9C448.3,429.9,448.4,429.9,448.4,429.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000062184324033883523770000016721463437442202298_" class="st1" d="M449,253.9l1.4-1.4
							c0.1,0.2,3,3.4,7.3,3.7l0.5,2C452.4,257.8,449.1,254,449,253.9z"/>
					</defs>
					<clipPath id="SVGID_00000084517824390235033470000014466723886421273778_">
						<use xlink:href="#SVGID_00000062184324033883523770000016721463437442202298_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000084517824390235033470000014466723886421273778_);enable-background:new    ;">
						<path class="st53" d="M449,253.9l1.4-1.4c0.1,0.1,1.5,1.7,3.9,2.8l-0.6,1.9C450.8,255.9,449.1,254,449,253.9"/>
						<path class="st52" d="M453.8,257.2l0.6-1.9c0.6,0.3,1.3,0.5,2,0.7l0,2C455.4,257.8,454.6,257.5,453.8,257.2"/>
						<path class="st51" d="M456.4,258l0-2c0.4,0.1,0.9,0.2,1.4,0.2l0.5,2C457.6,258.2,457,258.1,456.4,258"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000119827328013151520430000015215233559341484173_" class="st1" d="M458.2,258.3l-0.5-2
							c7.1-4.1,14.8-7.4,23-9.6l0.5,2C473.5,250.8,465.7,253.9,458.2,258.3z"/>
					</defs>
					<clipPath id="SVGID_00000116224585502706385900000007761750688227909763_">
						<use xlink:href="#SVGID_00000119827328013151520430000015215233559341484173_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000116224585502706385900000007761750688227909763_);enable-background:new    ;">
						<path class="st11" d="M458.2,258.3l-0.5-2c3.1-1.8,6.3-3.4,9.6-4.8l0.8,1.9C464.8,254.7,461.5,256.4,458.2,258.3"/>
						<path class="st12" d="M468.1,253.3l-0.8-1.9c4.3-1.9,8.8-3.5,13.4-4.8l0.5,2C476.9,249.8,472.4,251.4,468.1,253.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000020378363525674476860000014034307791037273765_" class="st1" d="M485,459h-2c0-8,0-11.8,0-12h2
							C485,447.2,485,451,485,459z"/>
					</defs>
					<clipPath id="SVGID_00000068679158487569392760000012246919231047128745_">
						<use xlink:href="#SVGID_00000020378363525674476860000014034307791037273765_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000068679158487569392760000012246919231047128745_);enable-background:new    ;">
						<path class="st5" d="M485,459h-2c0-8,0-11.8,0-12h2C485,447.2,485,451,485,459"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000022518984243344173720000013002525588874163084_" class="st1" d="M485,447h-2c0-0.3,0.4-6.9-5.2-9.9
							l1-1.8C485.5,439,485,446.7,485,447z"/>
					</defs>
					<clipPath id="SVGID_00000133501988295251450930000016033068482286343592_">
						<use xlink:href="#SVGID_00000022518984243344173720000013002525588874163084_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000133501988295251450930000016033068482286343592_);enable-background:new    ;">
						<path class="st5" d="M485,447h-2c0-0.2,0.2-2.7-0.9-5.3l1.8-0.9C485.2,443.9,485,446.8,485,447"/>
						<path class="st18" d="M483.9,440.9l-1.8,0.9c-0.6-1.4-1.6-2.8-3.1-4l1.2-1.6C482,437.5,483.2,439.2,483.9,440.9"/>
						<path class="st5" d="M480.1,436.2l-1.2,1.6c-0.3-0.2-0.7-0.5-1.1-0.7l1-1.8C479.3,435.6,479.7,435.8,480.1,436.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000040557389107359507710000016629696950213231760_" class="st1" d="M481.3,248.6l-0.5-2
							c2.1-1.6,4.3-4,4.3-6.6h2C487,243.6,484.3,246.3,481.3,248.6z"/>
					</defs>
					<clipPath id="SVGID_00000155115301856270030610000004478884435292882052_">
						<use xlink:href="#SVGID_00000040557389107359507710000016629696950213231760_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000155115301856270030610000004478884435292882052_);enable-background:new    ;">
						<path class="st10" d="M481.3,248.6l-0.5-2c0.4-0.4,0.9-0.7,1.3-1.1l1.1,1.6C482.5,247.6,481.9,248.1,481.3,248.6"/>
						<path class="st9" d="M483.1,247l-1.1-1.6c0.6-0.5,1.1-1.1,1.6-1.8l1.6,1.1C484.6,245.6,483.9,246.3,483.1,247"/>
						<path class="st8" d="M485.2,244.8l-1.6-1.1c0.4-0.5,0.7-1,0.9-1.5l1.9,0.7C486.1,243.5,485.7,244.2,485.2,244.8"/>
						<path class="st7" d="M486.4,242.8l-1.9-0.7c0.2-0.5,0.4-1,0.4-1.6l2,0.2C486.9,241.4,486.7,242.1,486.4,242.8"/>
						<path class="st5" d="M487,240.7l-2-0.2c0-0.2,0-0.4,0-0.5h2C487,240.2,487,240.5,487,240.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000099621102651715629210000011887731237888682927_" class="st1" d="M528.8,437.8c1.7-0.1,3.7-0.3,7.7-0.8
							c-3,0.3-6.9,0.8-9.9,1.1C527.6,437.9,528.3,437.8,528.8,437.8z"/>
					</defs>
					<clipPath id="SVGID_00000077314329092655909270000000443120871233529243_">
						<use xlink:href="#SVGID_00000099621102651715629210000011887731237888682927_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000077314329092655909270000000443120871233529243_);enable-background:new    ;">
						<path class="st14" d="M536.6,437l-9.9,1.1c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st13" d="M536.6,437l-9.9,1.1c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st12" d="M536.6,437l-9.8,1.1c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st11" d="M536.6,437l-9.8,1.1c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st10" d="M536.6,437l-9.7,1c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st9" d="M536.6,437l-9.7,1c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st8" d="M536.6,437l-9.7,1c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st7" d="M536.6,437l-9.6,1c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st5" d="M536.6,437l-9.6,1c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st18" d="M536.6,437l-9.5,1c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st20" d="M536.6,437l-9.5,1c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st21" d="M536.6,437l-9.4,1c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st22" d="M536.6,437l-9.4,1c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st23" d="M536.6,437l-9.3,1c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st24" d="M536.6,437l-9.3,1c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st25" d="M536.6,437l-9.2,1c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st26" d="M536.6,437l-9.2,0.9c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st27" d="M536.6,437l-9.2,0.9c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st28" d="M536.6,437l-9.1,0.9c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st29" d="M536.6,437l-9.1,0.9c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st30" d="M536.6,437l-9,0.9c0,0,0,0,0,0L536.6,437C536.6,437,536.6,437,536.6,437"/>
						<path class="st31" d="M536.6,437l-9,0.9c0,0,0,0,0,0L536.6,437C536.6,437.1,536.6,437,536.6,437"/>
						<path class="st32" d="M536.5,437.1l-8.9,0.9c0,0,0,0,0,0L536.5,437.1C536.5,437.1,536.5,437.1,536.5,437.1"/>
						<path class="st33" d="M536.5,437.1l-8.9,0.9c0,0,0.1,0,0.1,0L536.5,437.1C536.5,437.1,536.5,437.1,536.5,437.1"/>
						<path class="st34" d="M536.5,437.1l-8.8,0.9c0.1,0,0.1,0,0.2,0L536.5,437.1C536.5,437.1,536.5,437.1,536.5,437.1"/>
						<path class="st16" d="M536.5,437.1l-8.5,0.8c0.1,0,0.1,0,0.2,0L536.5,437.1C536.5,437.1,536.5,437.1,536.5,437.1"/>
						<path class="st35" d="M536.5,437.1l-8.3,0.8c0.1,0,0.1,0,0.2,0L536.5,437.1C536.5,437.1,536.5,437.1,536.5,437.1"/>
						<path class="st3" d="M536.5,437.1l-8.1,0.7c0.2,0,0.3,0,0.5-0.1l7.5-0.6C536.4,437.2,536.4,437.1,536.5,437.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000104675980530069136520000009769814462715132835_" class="st1" d="M492,466l0,2c-0.4,0-9-1-9-9h2
							C484.9,465.1,491.9,466,492,466z"/>
					</defs>
					<clipPath id="SVGID_00000110453115043544830380000014413492449785783179_">
						<use xlink:href="#SVGID_00000104675980530069136520000009769814462715132835_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000110453115043544830380000014413492449785783179_);enable-background:new    ;">
						<path class="st5" d="M492,466l0,2c-0.3,0-3.4-0.4-5.9-2.4l1.3-1.5C489.4,465.7,491.9,466,492,466"/>
						<path class="st18" d="M487.4,464.1l-1.3,1.5c-0.9-0.7-1.7-1.6-2.3-2.9l1.8-0.9C486,462.8,486.7,463.6,487.4,464.1"/>
						<path class="st5" d="M485.6,461.9l-1.8,0.9c-0.5-1-0.8-2.3-0.8-3.8h2C485,460.1,485.2,461.1,485.6,461.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000057829621425186801070000013559200018900335509_" class="st1" d="M487,240h-2c0-5,0-12.6,0-13
							c0-0.4,0-9,7-9l0,2c-4.9-0.1-5,6.6-5,7C487,227.4,487,235,487,240z"/>
					</defs>
					<clipPath id="SVGID_00000060735979990097528130000013697291852038504835_">
						<use xlink:href="#SVGID_00000057829621425186801070000013559200018900335509_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000060735979990097528130000013697291852038504835_);enable-background:new    ;">
						<path class="st5" d="M487,240h-2c0-5,0-12.6,0-13c0-0.1,0-0.6,0.1-1.3l2,0.3c-0.1,0.5-0.1,0.9-0.1,1
							C487,227.4,487,235,487,240"/>
						<path class="st7" d="M487.1,226l-2-0.3c0.1-0.9,0.3-2.3,0.9-3.6l1.8,1C487.3,224.2,487.1,225.2,487.1,226"/>
						<path class="st8" d="M487.7,223.2l-1.8-1c0.2-0.6,0.5-1.2,0.9-1.7l1.5,1.4C488.1,222.3,487.9,222.7,487.7,223.2"/>
						<path class="st9" d="M488.4,221.9l-1.5-1.4c0.2-0.3,0.5-0.6,0.8-0.9l1.3,1.6C488.8,221.4,488.6,221.6,488.4,221.9"/>
						<path class="st10" d="M489,221.1l-1.3-1.6c0.2-0.2,0.5-0.4,0.8-0.6l1,1.7C489.3,220.8,489.2,221,489,221.1"/>
						<path class="st11" d="M489.6,220.7l-1-1.7c0.2-0.2,0.5-0.3,0.8-0.4l0.8,1.8C489.9,220.4,489.7,220.6,489.6,220.7"/>
						<path class="st12" d="M490.1,220.4l-0.8-1.8c0.3-0.1,0.6-0.2,0.9-0.3l0.5,1.9C490.5,220.2,490.3,220.3,490.1,220.4"/>
						<path class="st13" d="M490.7,220.1l-0.5-1.9c0.3-0.1,0.6-0.1,1-0.2l0.2,2C491.2,220,490.9,220.1,490.7,220.1"/>
						<path class="st14" d="M491.4,220l-0.2-2c0.3,0,0.5,0,0.8,0l0,2C491.8,220,491.6,220,491.4,220"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000150813373225914161570000011145885483570341030_" class="st1" d="M508,265L508,265c-43.6,0-79,35.4-79,79
							c0,43.6,35.4,79,79,79l0,0c-43.6,0-79-35.4-79-79C429,300.4,464.4,265,508,265z"/>
					</defs>
					<clipPath id="SVGID_00000013911892518540568770000006622878345595561365_">
						<use xlink:href="#SVGID_00000150813373225914161570000011145885483570341030_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000013911892518540568770000006622878345595561365_);enable-background:new    ;">
						<path class="st3" d="M508,265L508,265c-30,0-56,16.7-69.4,41.3h0C452,281.7,478,265,508,265"/>
						<path class="st35" d="M438.6,306.3L438.6,306.3c-2.2,4.1-4.1,8.4-5.6,12.9h0C434.5,314.7,436.3,310.4,438.6,306.3"/>
						<path class="st16" d="M433,319.1L433,319.1c-1.2,3.7-2.2,7.5-2.8,11.5h0C430.8,326.7,431.8,322.9,433,319.1"/>
						<path class="st34" d="M430.1,330.6L430.1,330.6c-0.6,3.7-1,7.6-1.1,11.4h0C429.1,338.2,429.5,334.3,430.1,330.6"/>
						<path class="st33" d="M429,342.1L429,342.1c0,0.6,0,1.3,0,1.9c0,3.5,0.2,6.9,0.7,10.2h0c-0.4-3.3-0.7-6.8-0.7-10.2
							C429,343.4,429,342.7,429,342.1"/>
						<path class="st32" d="M429.7,354.2L429.7,354.2c0.6,4.8,1.7,9.4,3.1,13.8h0C431.3,363.6,430.3,359,429.7,354.2"/>
						<path class="st31" d="M432.7,368.1L432.7,368.1c6.1,19.1,19.3,35.1,36.6,44.8h0C452.1,403.2,438.9,387.2,432.7,368.1"/>
						<path class="st32" d="M469.3,412.9L469.3,412.9c4.1,2.3,8.5,4.3,13,5.8h0C477.8,417.2,473.4,415.2,469.3,412.9"/>
						<path class="st33" d="M482.3,418.7L482.3,418.7c3.7,1.3,7.6,2.3,11.5,3h0C489.9,421,486,420,482.3,418.7"/>
						<path class="st34" d="M493.8,421.7L493.8,421.7c3.7,0.7,7.6,1.1,11.4,1.2h0C501.4,422.8,497.6,422.4,493.8,421.7"/>
						<path class="st16" d="M505.3,423L505.3,423c0.9,0,1.8,0,2.7,0h0C507.1,423,506.2,423,505.3,423"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000155105991896150235530000015705754743013416335_" class="st1" d="M529,459L529,459c0,0.5,0,9-7,9l0,0
							C529,468,529,459.4,529,459z"/>
					</defs>
					<clipPath id="SVGID_00000000211902207843711580000010485453975116019388_">
						<use xlink:href="#SVGID_00000155105991896150235530000015705754743013416335_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000000211902207843711580000010485453975116019388_);enable-background:new    ;">
						<path class="st33" d="M529,459L529,459c0,0,0,0.1,0,0.2h0C529,459.1,529,459,529,459"/>
						<path class="st34" d="M529,459.2L529,459.2c0,0.4,0,1.1-0.2,2h0C528.9,460.3,529,459.6,529,459.2"/>
						<path class="st16" d="M528.8,461.2L528.8,461.2c-0.1,0.6-0.2,1.2-0.4,1.9h0C528.6,462.4,528.7,461.8,528.8,461.2"/>
						<path class="st35" d="M528.3,463.1L528.3,463.1c-0.2,0.5-0.4,1.1-0.7,1.6h0C527.9,464.1,528.2,463.6,528.3,463.1"/>
						<path class="st3" d="M527.7,464.6L527.7,464.6c-1,1.8-2.7,3.4-5.7,3.4h0C525,468,526.7,466.5,527.7,464.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000039826268503514657050000016858964617848446632_" class="st1" d="M529,446L529,446c0,5,0,12.6,0,13l0,0
							C529,458.6,529,451,529,446z"/>
					</defs>
					<clipPath id="SVGID_00000171678330298127905070000017345132678012238487_">
						<use xlink:href="#SVGID_00000039826268503514657050000016858964617848446632_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000171678330298127905070000017345132678012238487_);enable-background:new    ;">
						<path class="st33" d="M529,446L529,446c0,5,0,12.6,0,13h0C529,458.6,529,451,529,446"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000178195596314736730600000002675210646946780057_" class="st1" d="M531,227L531,227c0,8,0,11.8,0,12l0,0
							C531,238.8,531,235,531,227z"/>
					</defs>
					<clipPath id="SVGID_00000009548934737836572690000002260560624825267611_">
						<use xlink:href="#SVGID_00000178195596314736730600000002675210646946780057_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000009548934737836572690000002260560624825267611_);enable-background:new    ;">
						<path class="st33" d="M531,227L531,227c0,8,0,11.8,0,12h0C531,238.8,531,235,531,227"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000132783031497394680180000000057143160459576463_" class="st1" d="M522,218L522,218c0.5,0.1,9,1,9,9h0
							C531,219,522.4,218,522,218z"/>
					</defs>
					<clipPath id="SVGID_00000174578218225423188300000000273922862815327930_">
						<use xlink:href="#SVGID_00000132783031497394680180000000057143160459576463_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000174578218225423188300000000273922862815327930_);enable-background:new    ;">
						<path class="st34" d="M522,218L522,218c0.1,0,0.3,0,0.7,0.1h0C522.3,218,522.1,218,522,218"/>
						<path class="st33" d="M522.6,218.1L522.6,218.1c0.5,0.1,1.2,0.2,2,0.5h0C523.8,218.3,523.1,218.2,522.6,218.1"/>
						<path class="st32" d="M524.6,218.6L524.6,218.6c0.6,0.2,1.3,0.5,2,0.9h0C525.9,219.1,525.3,218.8,524.6,218.6"/>
						<path class="st31" d="M526.6,219.5L526.6,219.5c1.6,0.9,3.2,2.4,4,4.6h0C529.8,221.8,528.2,220.4,526.6,219.5"/>
						<path class="st32" d="M530.6,224.1L530.6,224.1c0.2,0.5,0.3,1,0.4,1.5h0C530.8,225.1,530.7,224.6,530.6,224.1"/>
						<path class="st33" d="M530.9,225.7L530.9,225.7c0.1,0.4,0.1,0.9,0.1,1.3h0C531,226.5,531,226.1,530.9,225.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000160905350718866701540000006918046787468623278_" class="st1" d="M522,466l0,2c-7,0-28.9,0-30,0l0-2
							C493.1,466,515,466,522,466z"/>
					</defs>
					<clipPath id="SVGID_00000104682967132135783790000011396424828308461709_">
						<use xlink:href="#SVGID_00000160905350718866701540000006918046787468623278_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000104682967132135783790000011396424828308461709_);enable-background:new    ;">
						<path class="st7" d="M522,466l0,2c-7,0-28.9,0-30,0l0-2C493.1,466,515,466,522,466"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000150818953910278164310000016487123734830176414_" class="st1" d="M492,220l0-2c7,0,28.9,0,30,0l0,2
							C520.8,220,499,220,492,220z"/>
					</defs>
					<clipPath id="SVGID_00000145761306806417377910000012673033036946227368_">
						<use xlink:href="#SVGID_00000150818953910278164310000016487123734830176414_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000145761306806417377910000012673033036946227368_);enable-background:new    ;">
						<path class="st14" d="M492,220l0-2c7,0,28.9,0,30,0l0,2C520.8,220,499,220,492,220"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000018229974659633725570000002558789556699283076_" class="st1" d="M531,239L531,239c0,0.2-0.2,4.2,2.3,7.5
							l0,0C530.7,243.2,531,239.2,531,239z"/>
					</defs>
					<clipPath id="SVGID_00000001624685746255356870000000999521230034766999_">
						<use xlink:href="#SVGID_00000018229974659633725570000002558789556699283076_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000001624685746255356870000000999521230034766999_);enable-background:new    ;">
						<path class="st34" d="M531,239L531,239c0,0,0,0.1,0,0.2h0C531,239.1,531,239,531,239"/>
						<path class="st33" d="M531,239.2L531,239.2c0,0.3,0,0.9,0.1,1.7h0C531,240.2,531,239.5,531,239.2"/>
						<path class="st32" d="M531.1,241L531.1,241c0.1,0.6,0.2,1.4,0.5,2.2h0C531.3,242.4,531.2,241.6,531.1,241"/>
						<path class="st31" d="M531.6,243.2L531.6,243.2c0.3,1.1,0.9,2.2,1.7,3.3h0C532.4,245.4,531.9,244.2,531.6,243.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000076567153573492557680000011362932602907224467_" class="st1" d="M527,446h2c0,5,0,12.6,0,13
							c0,0.5,0,9-7,9l0-2c4.9,0.1,5-6.6,5-7C527,458.6,527,451,527,446z"/>
					</defs>
					<clipPath id="SVGID_00000059296608141088930840000000693248306240405663_">
						<use xlink:href="#SVGID_00000076567153573492557680000011362932602907224467_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000059296608141088930840000000693248306240405663_);enable-background:new    ;">
						<path class="st51" d="M527,446h2c0,5,0,12.6,0,13c0,0.1,0,1-0.2,2.1l-2-0.4c0.1-0.9,0.1-1.5,0.1-1.6
							C527,458.6,527,451,527,446"/>
						<path class="st14" d="M526.8,460.6l2,0.4c-0.1,0.6-0.3,1.4-0.5,2.1l-1.8-0.9C526.6,461.7,526.8,461.1,526.8,460.6"/>
						<path class="st13" d="M526.5,462.3l1.8,0.9c-0.2,0.5-0.4,1-0.6,1.4l-1.7-1.2C526.2,463,526.3,462.6,526.5,462.3"/>
						<path class="st12" d="M526,463.4l1.7,1.2c-0.2,0.4-0.4,0.7-0.7,1.1l-1.5-1.4C525.7,464,525.9,463.7,526,463.4"/>
						<path class="st11" d="M525.5,464.2l1.5,1.4c-0.2,0.3-0.4,0.5-0.7,0.8l-1.3-1.6C525.2,464.6,525.4,464.4,525.5,464.2"/>
						<path class="st10" d="M525,464.8l1.3,1.6c-0.2,0.2-0.5,0.4-0.8,0.6l-1.1-1.7C524.7,465.2,524.9,465,525,464.8"/>
						<path class="st9" d="M524.5,465.3l1.1,1.7c-0.3,0.2-0.6,0.4-0.9,0.5l-0.8-1.9C524,465.6,524.3,465.4,524.5,465.3"/>
						<path class="st8" d="M523.8,465.7l0.8,1.9c-0.4,0.2-0.8,0.3-1.3,0.4l-0.4-2C523.2,465.9,523.5,465.8,523.8,465.7"/>
						<path class="st7" d="M522.9,465.9l0.4,2c-0.4,0.1-0.9,0.1-1.3,0.1l0-2C522.3,466,522.6,466,522.9,465.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000072990877735735168190000005532449283711942290_" class="st1" d="M536.9,437L536.9,437c0.1,0-7.9,4-7.9,9
							h0C529,441,537,437,536.9,437z"/>
					</defs>
					<clipPath id="SVGID_00000108997742719514948460000007730069443547319176_">
						<use xlink:href="#SVGID_00000072990877735735168190000005532449283711942290_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000108997742719514948460000007730069443547319176_);enable-background:new    ;">
						<polyline class="st34" points="536.9,437 536.9,437 536.9,437 536.9,437 536.9,437 						"/>
						<polyline class="st16" points="536.9,437 536.9,437 536.9,437 536.9,437 536.9,437 						"/>
						<path class="st35" d="M536.9,437L536.9,437C536.9,437,536.9,437,536.9,437L536.9,437C536.9,437,536.9,437,536.9,437"/>
						<path class="st3" d="M536.9,437L536.9,437c-0.3,0.2-5.2,2.8-7.1,6.3h0C531.7,439.8,536.5,437.2,536.9,437"/>
						<path class="st35" d="M529.8,443.3L529.8,443.3c-0.2,0.4-0.3,0.7-0.5,1.1h0C529.4,444,529.6,443.6,529.8,443.3"/>
						<path class="st16" d="M529.3,444.4L529.3,444.4c-0.1,0.3-0.1,0.5-0.2,0.8h0C529.1,444.9,529.2,444.6,529.3,444.4"/>
						<path class="st34" d="M529.1,445.2L529.1,445.2c0,0.2-0.1,0.5-0.1,0.7h0C529,445.6,529,445.4,529.1,445.2"/>
						<path class="st33" d="M529,445.9L529,445.9c0,0,0,0.1,0,0.1h0C529,446,529,445.9,529,445.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000034772547157301161500000012635559376780625297_" class="st1" d="M529,227h2c0,8,0,11.8,0,12h-2
							C529,238.8,529,235,529,227z"/>
					</defs>
					<clipPath id="SVGID_00000173881914849495281670000018250332347319649699_">
						<use xlink:href="#SVGID_00000034772547157301161500000012635559376780625297_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000173881914849495281670000018250332347319649699_);enable-background:new    ;">
						<path class="st51" d="M529,227h2c0,8,0,11.8,0,12h-2C529,238.8,529,235,529,227"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000028322537913144749040000010202822795969284495_" class="st1" d="M522,220l0-2c0.4,0.1,9,1,9,9h-2
							C529.1,220.9,522.1,220,522,220z"/>
					</defs>
					<clipPath id="SVGID_00000029020316112669039150000009864189500483972737_">
						<use xlink:href="#SVGID_00000028322537913144749040000010202822795969284495_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000029020316112669039150000009864189500483972737_);enable-background:new    ;">
						<path class="st51" d="M522,220l0-2c0.1,0,0.6,0.1,1.4,0.2l-0.4,2C522.4,220.1,522,220,522,220"/>
						<path class="st52" d="M523,220.2l0.4-2c0.8,0.2,1.8,0.5,2.9,1.1l-1,1.7C524.4,220.6,523.6,220.3,523,220.2"/>
						<path class="st53" d="M525.2,221l1-1.7c1.9,1,3.8,2.6,4.5,5.4l-1.9,0.5C528.3,223.1,526.7,221.8,525.2,221"/>
						<path class="st52" d="M528.8,225.2l1.9-0.5c0.2,0.6,0.2,1.3,0.3,2l-2,0.1C529,226.2,528.9,225.7,528.8,225.2"/>
						<path class="st51" d="M529,226.8l2-0.1c0,0.1,0,0.2,0,0.3h-2C529,226.9,529,226.9,529,226.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000049921197190441426510000002845534863050921102_" class="st1" d="M583.4,440.6c0.1,0.1,0.3,0.3,0.4,0.4
							L583.4,440.6C583.4,440.6,583.4,440.6,583.4,440.6z"/>
					</defs>
					<clipPath id="SVGID_00000160888105650059843200000006077951975626143626_">
						<use xlink:href="#SVGID_00000049921197190441426510000002845534863050921102_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000160888105650059843200000006077951975626143626_);enable-background:new    ;">
						<polyline class="st3" points="583.8,441 583.4,440.6 583.4,440.6 583.8,441 583.8,441 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000173844370015932867340000010934962611038955407_" class="st1" d="M529,239h2c0,0.2-0.3,4.2,2.3,7.5
							l-1.2,1.8C528.7,243.8,529,239.2,529,239z"/>
					</defs>
					<clipPath id="SVGID_00000018221073117582109550000008875160263838897595_">
						<use xlink:href="#SVGID_00000173844370015932867340000010934962611038955407_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000018221073117582109550000008875160263838897595_);enable-background:new    ;">
						<path class="st51" d="M529,239h2c0,0,0,0.2,0,0.5l-2,0.1C529,239.2,529,239,529,239"/>
						<path class="st52" d="M529,239.6l2-0.1c0,0.5,0,1.3,0.2,2.2l-1.9,0.5C529.1,241.1,529,240.2,529,239.6"/>
						<path class="st53" d="M529.3,242.2l1.9-0.5c0.3,1.4,0.8,3.1,2,4.7l-1.2,1.8C530.5,246.1,529.7,243.9,529.3,242.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000109001924923236320570000009470066455674683041_" class="st1" d="M528.8,437.8c2.1-0.2,4.3-0.4,8.1-0.8
							c0.1,0-7.9,4-7.9,9h-2C527.1,440.4,532.5,437.5,528.8,437.8z"/>
					</defs>
					<clipPath id="SVGID_00000047057029101220395330000003024208576318779537_">
						<use xlink:href="#SVGID_00000109001924923236320570000009470066455674683041_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000047057029101220395330000003024208576318779537_);enable-background:new    ;">
						<path class="st3" d="M526.6,438.1l10.3-1.1c0,0,0,0,0,0l-9.2,0.9C527.4,438,527,438,526.6,438.1"/>
						<path class="st35" d="M527.7,437.9l9.2-0.9c0,0,0,0,0,0L527.7,437.9C527.7,437.9,527.7,437.9,527.7,437.9"/>
						<path class="st16" d="M527.7,437.9l9.1-0.9c0,0,0,0,0,0L527.7,437.9C527.7,437.9,527.7,437.9,527.7,437.9"/>
						<path class="st34" d="M527.7,437.9l9.1-0.9c0,0,0,0,0,0L527.7,437.9C527.7,437.9,527.7,437.9,527.7,437.9"/>
						<path class="st33" d="M527.8,437.9l9.1-0.9c0,0,0,0,0,0L527.8,437.9C527.8,437.9,527.8,437.9,527.8,437.9"/>
						<path class="st32" d="M527.8,437.9l9-0.9c0,0,0,0,0,0L527.8,437.9C527.8,437.9,527.8,437.9,527.8,437.9"/>
						<path class="st31" d="M527.8,437.9l9-0.9c0,0,0,0,0,0L527.8,437.9C527.8,437.9,527.8,437.9,527.8,437.9"/>
						<path class="st30" d="M527.8,437.9l9-0.9c0,0,0,0,0,0L527.8,437.9C527.9,437.9,527.9,437.9,527.8,437.9"/>
						<path class="st29" d="M527.9,437.9l8.9-0.9c0,0,0,0,0,0L527.9,437.9C527.9,437.9,527.9,437.9,527.9,437.9"/>
						<path class="st28" d="M527.9,437.9l8.9-0.9c0,0,0,0,0,0L527.9,437.9C527.9,437.9,527.9,437.9,527.9,437.9"/>
						<path class="st27" d="M527.9,437.9l8.9-0.8c0,0,0,0,0,0L527.9,437.9C528,437.9,527.9,437.9,527.9,437.9"/>
						<path class="st26" d="M528,437.9l8.8-0.8c0,0,0,0,0,0L528,437.9C528,437.9,528,437.9,528,437.9"/>
						<path class="st25" d="M528,437.9l8.8-0.8c0,0,0,0,0,0L528,437.9C528,437.9,528,437.9,528,437.9"/>
						<path class="st24" d="M528,437.9l8.8-0.8c0,0,0,0,0,0L528,437.9C528,437.9,528,437.9,528,437.9"/>
						<path class="st23" d="M528.1,437.9l8.7-0.8c0,0,0,0,0,0L528.1,437.9C528.1,437.9,528.1,437.9,528.1,437.9"/>
						<path class="st22" d="M528.1,437.9l8.7-0.8c0,0,0,0,0,0L528.1,437.9C528.1,437.9,528.1,437.9,528.1,437.9"/>
						<path class="st21" d="M528.1,437.9l8.7-0.8c0,0,0,0,0,0L528.1,437.9C528.1,437.9,528.1,437.9,528.1,437.9"/>
						<path class="st20" d="M528.1,437.9l8.6-0.8c0,0,0,0,0,0L528.1,437.9C528.2,437.9,528.1,437.9,528.1,437.9"/>
						<path class="st18" d="M528.2,437.9l8.6-0.8c0,0,0,0,0,0L528.2,437.9C528.2,437.9,528.2,437.9,528.2,437.9"/>
						<path class="st5" d="M528.2,437.9l8.6-0.8c0,0,0,0,0,0L528.2,437.9C528.2,437.9,528.2,437.9,528.2,437.9"/>
						<path class="st7" d="M528.2,437.9l8.5-0.8c0,0,0,0,0,0L528.2,437.9C528.2,437.8,528.2,437.9,528.2,437.9"/>
						<path class="st8" d="M528.2,437.8l8.5-0.8c0,0,0,0,0,0L528.2,437.8C528.3,437.8,528.3,437.8,528.2,437.8"/>
						<path class="st9" d="M528.3,437.8l8.5-0.8c0,0,0,0,0,0L528.3,437.8C528.3,437.8,528.3,437.8,528.3,437.8"/>
						<path class="st10" d="M528.3,437.8l8.4-0.8c0,0,0,0,0,0L528.3,437.8C528.3,437.8,528.3,437.8,528.3,437.8"/>
						<path class="st11" d="M528.3,437.8l8.4-0.7c0,0,0,0,0,0L528.3,437.8C528.3,437.8,528.3,437.8,528.3,437.8"/>
						<path class="st12" d="M528.3,437.8l8.4-0.7c0,0,0,0,0,0L528.3,437.8C528.4,437.8,528.4,437.8,528.3,437.8"/>
						<path class="st13" d="M528.4,437.8l8.3-0.7c0,0,0,0,0,0L528.4,437.8C528.4,437.8,528.4,437.8,528.4,437.8"/>
						<path class="st14" d="M528.4,437.8l8.3-0.7c0,0,0,0,0,0L528.4,437.8C528.4,437.8,528.4,437.8,528.4,437.8"/>
						<path class="st51" d="M528.4,437.8l8.3-0.7c0,0,0,0,0,0L528.4,437.8C528.4,437.8,528.4,437.8,528.4,437.8"/>
						<path class="st52" d="M528.4,437.8l8.2-0.7c0,0,0,0-0.1,0l-7.9,0.6C528.6,437.8,528.5,437.8,528.4,437.8"/>
						<path class="st51" d="M528.7,437.8l7.9-0.6c0,0-0.1,0-0.1,0l-7.6,0.6C528.8,437.8,528.8,437.8,528.7,437.8"/>
						<path class="st14" d="M528.9,437.8l7.6-0.6c0,0-0.1,0-0.1,0.1l-7.4,0.5C529,437.8,529,437.8,528.9,437.8"/>
						<path class="st13" d="M529.1,437.8l7.4-0.5c-0.1,0-0.1,0.1-0.2,0.1l-6.8,0.4C529.3,437.8,529.2,437.8,529.1,437.8"/>
						<path class="st12" d="M529.4,437.8l6.8-0.4c-0.1,0.1-0.3,0.2-0.5,0.3l-5.9,0.2C529.7,437.8,529.6,437.8,529.4,437.8"/>
						<path class="st11" d="M529.8,437.8l5.9-0.2c-1.2,0.8-3.3,2.1-4.8,3.9l-2.4-0.7C529.6,439.1,530.6,438.1,529.8,437.8"/>
						<path class="st12" d="M528.6,440.9l2.4,0.7c-0.4,0.5-0.8,1-1.1,1.5l-2.1-0.6C528,441.9,528.3,441.4,528.6,440.9"/>
						<path class="st13" d="M527.8,442.5l2.1,0.6c-0.2,0.4-0.4,0.7-0.5,1.1l-2-0.4C527.5,443.3,527.6,442.8,527.8,442.5"/>
						<path class="st14" d="M527.3,443.7l2,0.4c-0.1,0.3-0.2,0.7-0.3,1l-2-0.2C527.1,444.5,527.2,444.1,527.3,443.7"/>
						<path class="st51" d="M527.1,444.9l2,0.2c-0.1,0.3-0.1,0.6-0.1,0.9h-2C527,445.6,527,445.2,527.1,444.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000061447751463951630420000000397868481625925508_" class="st1" d="M553.8,429.9L553.8,429.9
							c-5.4,2.9-11,5.2-16.9,7.1h0C542.8,435.1,548.4,432.8,553.8,429.9z"/>
					</defs>
					<clipPath id="SVGID_00000062169250578358393490000003447121359919486606_">
						<use xlink:href="#SVGID_00000061447751463951630420000000397868481625925508_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000062169250578358393490000003447121359919486606_);enable-background:new    ;">
						<path class="st3" d="M553.8,429.9L553.8,429.9c-5.4,2.9-11,5.2-16.9,7.1h0C542.8,435.1,548.4,432.8,553.8,429.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000033350703023983084050000010329837573347019415_" class="st1" d="M555.5,429.8L555.5,429.8
							c-0.5,0-1.1,0-1.7,0.2h0C554.4,429.8,554.9,429.8,555.5,429.8z"/>
					</defs>
					<clipPath id="SVGID_00000147911028719751352010000004665917064280421006_">
						<use xlink:href="#SVGID_00000033350703023983084050000010329837573347019415_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000147911028719751352010000004665917064280421006_);enable-background:new    ;">
						<path class="st3" d="M555.5,429.8L555.5,429.8c-0.5,0-1.1,0-1.7,0.2h0C554.4,429.8,554.9,429.8,555.5,429.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000000213266417533273920000011447933692408752768_" class="st1" d="M533.3,246.5L533.3,246.5
							c8.4,2.3,16.3,5.6,23.6,9.8h0C549.5,252,541.6,248.7,533.3,246.5z"/>
					</defs>
					<clipPath id="SVGID_00000019669198594344328080000014372819254997937300_">
						<use xlink:href="#SVGID_00000000213266417533273920000011447933692408752768_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000019669198594344328080000014372819254997937300_);enable-background:new    ;">
						<path class="st33" d="M533.3,246.5L533.3,246.5c2.1,0.6,4.2,1.2,6.3,1.9h0C537.5,247.7,535.4,247,533.3,246.5"/>
						<path class="st32" d="M539.5,248.4L539.5,248.4c5.7,1.9,11.1,4.4,16.2,7.3h0C550.6,252.7,545.2,250.3,539.5,248.4"/>
						<path class="st31" d="M555.7,255.6L555.7,255.6c0.4,0.2,0.8,0.4,1.2,0.6h0C556.5,256.1,556.1,255.8,555.7,255.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000013900054720108839910000018282555688858744490_" class="st1" d="M556.9,256.3L556.9,256.3
							c0.6,0.1,1.3,0.1,1.9,0.1h0C558.1,256.4,557.5,256.3,556.9,256.3z"/>
					</defs>
					<clipPath id="SVGID_00000045597689779436506500000018282903120751291544_">
						<use xlink:href="#SVGID_00000013900054720108839910000018282555688858744490_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000045597689779436506500000018282903120751291544_);enable-background:new    ;">
						<path class="st34" d="M556.9,256.3L556.9,256.3c0.5,0,0.9,0.1,1.4,0.1h0C557.8,256.4,557.3,256.3,556.9,256.3"/>
						<path class="st16" d="M558.2,256.4L558.2,256.4c0.2,0,0.4,0,0.6,0h0C558.6,256.4,558.4,256.4,558.2,256.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000108286206573597201800000008203067658967675830_" class="st1" d="M553.4,427.9l0.4,2
							c-5.4,2.9-11,5.2-16.9,7.1l-10.3,1.1C541.9,433.2,547.6,430.9,553.4,427.9z"/>
					</defs>
					<clipPath id="SVGID_00000043417991315701217450000006899453531711713447_">
						<use xlink:href="#SVGID_00000108286206573597201800000008203067658967675830_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000043417991315701217450000006899453531711713447_);enable-background:new    ;">
						<path class="st9" d="M553.4,427.9l0.4,2c-3.1,1.7-6.3,3.1-9.6,4.5l-2.6-1.3C546.7,431.2,550.1,429.7,553.4,427.9"/>
						<path class="st8" d="M541.6,433.1l2.6,1.3c-2.4,1-4.8,1.8-7.3,2.6l-10.3,1.1C533.1,436,537.8,434.4,541.6,433.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000004538419067520339990000016123453426495174065_" class="st1" d="M532.1,248.2l1.2-1.8
							c8.4,2.3,16.3,5.6,23.6,9.8l-1,1.8C548.7,253.9,540.7,250.7,532.1,248.2z"/>
					</defs>
					<clipPath id="SVGID_00000023270482753325508890000015872770590729704582_">
						<use xlink:href="#SVGID_00000004538419067520339990000016123453426495174065_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000023270482753325508890000015872770590729704582_);enable-background:new    ;">
						<path class="st52" d="M532.1,248.2l1.2-1.8c7.5,2,14.7,4.9,21.4,8.6l-1,1.8C547.1,253.2,539.8,250.4,532.1,248.2"/>
						<path class="st53" d="M553.7,256.8l1-1.8c0.7,0.4,1.5,0.8,2.2,1.2l-1,1.8C555.1,257.6,554.4,257.2,553.7,256.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000035523121855886894320000016609913346335467648_" class="st1" d="M565,432.1l-1.4,1.4
							c-0.2-0.2-3.3-3.8-8.1-3.8c-0.5,0-1.1,0-1.7,0.2l-0.4-2c0.8-0.2,1.4-0.2,2.1-0.2C561.2,427.8,564.8,431.9,565,432.1z"/>
					</defs>
					<clipPath id="SVGID_00000170965219527133955120000003168818638808340117_">
						<use xlink:href="#SVGID_00000035523121855886894320000016609913346335467648_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000170965219527133955120000003168818638808340117_);enable-background:new    ;">
						<path class="st18" d="M565,432.1l-1.4,1.4c-0.1-0.1-0.7-0.8-1.9-1.7l1.1-1.6C564.1,431.2,564.9,432,565,432.1"/>
						<path class="st5" d="M562.8,430.2l-1.1,1.6c-1.2-0.9-3-1.8-5.1-2l0.2-2C559.3,428.1,561.4,429.2,562.8,430.2"/>
						<path class="st7" d="M556.7,427.8l-0.2,2c-0.3,0-0.7-0.1-1-0.1c-0.4,0-0.9,0-1.3,0.1l-0.2-2c0.6-0.1,1-0.1,1.6-0.1
							C555.9,427.8,556.3,427.8,556.7,427.8"/>
						<path class="st8" d="M553.9,427.8l0.2,2c-0.1,0-0.3,0-0.4,0.1l-0.4-2C553.6,427.9,553.8,427.8,553.9,427.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000067957692543963276210000005142882209840735629_" class="st1" d="M555.8,258.1l1-1.8
							c0.6,0.1,1.3,0.1,1.9,0.1c2.1,0,4.1-0.4,5.5-1.8l1.4,1.4c-1.9,1.9-4.5,2.4-6.9,2.4C558,258.5,557.3,258.3,555.8,258.1z"/>
					</defs>
					<clipPath id="SVGID_00000010295239227850264510000004252157749533558170_">
						<use xlink:href="#SVGID_00000067957692543963276210000005142882209840735629_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000010295239227850264510000004252157749533558170_);enable-background:new    ;">
						<path class="st51" d="M555.8,258.1l1-1.8c0.4,0,0.7,0.1,1.1,0.1l-0.2,2C557.3,258.3,556.7,258.2,555.8,258.1"/>
						<path class="st14" d="M557.8,258.4l0.2-2c0.3,0,0.5,0,0.8,0c0.4,0,0.8,0,1.3-0.1l0.2,2c-0.5,0-1,0.1-1.5,0.1
							C558.4,258.4,558.1,258.4,557.8,258.4"/>
						<path class="st13" d="M560.2,258.3l-0.2-2c0.5,0,1-0.1,1.5-0.3l0.6,1.9C561.5,258.2,560.9,258.3,560.2,258.3"/>
						<path class="st12" d="M562.1,258l-0.6-1.9c0.4-0.1,0.7-0.2,1.1-0.4l0.9,1.8C563,257.7,562.6,257.9,562.1,258"/>
						<path class="st11" d="M563.5,257.5l-0.9-1.8c0.3-0.1,0.6-0.3,0.8-0.4l1.1,1.7C564.2,257.1,563.8,257.3,563.5,257.5"/>
						<path class="st10" d="M564.6,256.9l-1.1-1.7c0.3-0.2,0.5-0.3,0.7-0.6l1.4,1.5C565.2,256.4,564.9,256.7,564.6,256.9"/>
						<path class="st9" d="M565.5,256.2l-1.4-1.5c0,0,0.1-0.1,0.1-0.1l1.4,1.4C565.6,256.1,565.6,256.1,565.5,256.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000015324939152520215540000006296803547262174630_" class="st1" d="M573.4,440.6L572,442
							c-5.7-5.7-8.3-8.3-8.5-8.5l1.4-1.4C565.1,432.2,567.8,434.9,573.4,440.6z"/>
					</defs>
					<clipPath id="SVGID_00000155838677577120927470000006094530097097374902_">
						<use xlink:href="#SVGID_00000015324939152520215540000006296803547262174630_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000155838677577120927470000006094530097097374902_);enable-background:new    ;">
						<path class="st18" d="M573.4,440.6L572,442c-5.7-5.7-8.3-8.3-8.5-8.5l1.4-1.4C565.1,432.2,567.8,434.9,573.4,440.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000018938991148247974390000008971364045622860946_" class="st1" d="M584.8,442L584.8,442
							c-0.1,0.1-3.1,2.5-6.9,2.5h0C581.6,444.5,584.6,442.1,584.8,442z"/>
					</defs>
					<clipPath id="SVGID_00000016044292041348459580000013374095198302893465_">
						<use xlink:href="#SVGID_00000018938991148247974390000008971364045622860946_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000016044292041348459580000013374095198302893465_);enable-background:new    ;">
						<path class="st3" d="M584.8,442L584.8,442c-0.1,0.1-3.1,2.5-6.9,2.5h0C581.6,444.5,584.6,442.1,584.8,442"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000073696351579519369240000013218625658753697451_" class="st1" d="M580.4,242.1L580.4,242.1
							c1.4,0,2.9,0.5,4.3,1.9l0,0C583.4,242.6,581.9,242.1,580.4,242.1z"/>
					</defs>
					<clipPath id="SVGID_00000120522265780342569060000016266858499066069939_">
						<use xlink:href="#SVGID_00000073696351579519369240000013218625658753697451_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000120522265780342569060000016266858499066069939_);enable-background:new    ;">
						<path class="st16" d="M580.4,242.1L580.4,242.1c0.1,0,0.2,0,0.2,0h0C580.6,242.1,580.5,242.1,580.4,242.1"/>
						<path class="st34" d="M580.7,242.1L580.7,242.1c0.3,0,0.6,0,0.9,0.1h0C581.2,242.2,580.9,242.1,580.7,242.1"/>
						<path class="st33" d="M581.5,242.2L581.5,242.2c0.3,0,0.6,0.1,0.8,0.2h0C582,242.3,581.8,242.3,581.5,242.2"/>
						<path class="st32" d="M582.3,242.4L582.3,242.4c0.3,0.1,0.6,0.3,1,0.4h0C583,242.7,582.6,242.5,582.3,242.4"/>
						<path class="st31" d="M583.3,242.9L583.3,242.9c0.5,0.3,1,0.7,1.5,1.2h0C584.3,243.5,583.8,243.1,583.3,242.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000098912180153776247220000007329575067458650303_" class="st1" d="M583.4,440.6c0.1,0.1,0.3,0.3,0.4,0.4
							l1,1c-0.2,0.1-3.1,2.5-6.9,2.5c-1.9,0-3.9-0.6-5.8-2.5l1.4-1.4c1.5,1.5,3,1.9,4.4,1.9C580.9,442.5,583.3,440.6,583.4,440.6z"
							/>
					</defs>
					<clipPath id="SVGID_00000083066205469811002860000007775502323179462289_">
						<use xlink:href="#SVGID_00000098912180153776247220000007329575067458650303_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000083066205469811002860000007775502323179462289_);enable-background:new    ;">
						<polyline class="st9" points="583.3,440.6 584.8,442 584.8,442 583.3,440.6 583.3,440.6 						"/>
						<path class="st10" d="M583.3,440.6l1.4,1.4c0,0-0.2,0.2-0.5,0.4l-1.2-1.6C583.3,440.7,583.4,440.6,583.3,440.6"/>
						<path class="st9" d="M583,440.8l1.2,1.6c-0.5,0.3-1.2,0.8-2.2,1.2l-0.8-1.8C582.1,441.4,582.7,441,583,440.8"/>
						<path class="st8" d="M581.3,441.8l0.8,1.8c-0.7,0.3-1.6,0.6-2.5,0.8l-0.3-2C580,442.2,580.7,442,581.3,441.8"/>
						<path class="st7" d="M579.2,442.4l0.3,2c-0.5,0.1-1.1,0.2-1.7,0.2c-0.3,0-0.6,0-0.9-0.1l0.2-2c0.2,0,0.5,0,0.7,0
							C578.3,442.5,578.8,442.5,579.2,442.4"/>
						<path class="st5" d="M577.2,442.5l-0.2,2c-1.2-0.1-2.5-0.6-3.7-1.5l1.1-1.6C575.3,442,576.2,442.4,577.2,442.5"/>
						<path class="st18" d="M574.3,441.4l-1.1,1.6c-0.4-0.3-0.8-0.6-1.2-1l1.4-1.4C573.7,440.9,574,441.1,574.3,441.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000163786318940492743050000008663679299794884238_" class="st1" d="M583.8,441l-0.4-0.4c0,0,0,0,0,0
							C583.5,440.7,583.6,440.9,583.8,441z"/>
					</defs>
					<clipPath id="SVGID_00000044894186691494108630000014535518448118731963_">
						<use xlink:href="#SVGID_00000163786318940492743050000008663679299794884238_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000044894186691494108630000014535518448118731963_);enable-background:new    ;">
						<polyline class="st9" points="583.3,440.6 583.8,441 583.8,441 583.3,440.6 583.3,440.6 						"/>
						<polyline class="st10" points="583.3,440.6 583.8,441 583.8,441 583.4,440.6 583.3,440.6 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000142136691662578175120000002416880693104275638_" class="st1" d="M565.7,256l-1.4-1.4
							c3.5-3.5,8.9-8.9,9.2-9.2c0.2-0.2,3.3-3.3,7-3.3c1.4,0,2.9,0.5,4.3,1.9l-1.4,1.4c-1-1-2-1.3-2.9-1.3c-2.8,0-5.5,2.6-5.6,2.7
							C574.6,247.1,569.2,252.5,565.7,256z"/>
					</defs>
					<clipPath id="SVGID_00000172409213975578546580000004920242116419320511_">
						<use xlink:href="#SVGID_00000142136691662578175120000002416880693104275638_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000172409213975578546580000004920242116419320511_);enable-background:new    ;">
						<path class="st9" d="M565.7,256l-1.4-1.4c3.5-3.5,8.9-8.9,9.2-9.2c0,0,0.1-0.1,0.2-0.2l1.4,1.4c-0.1,0.1-0.1,0.1-0.1,0.1
							C574.6,247.1,569.2,252.5,565.7,256"/>
						<path class="st10" d="M575,246.7l-1.4-1.4c0.3-0.3,1-0.9,1.9-1.5l1,1.7C575.8,246,575.3,246.5,575,246.7"/>
						<path class="st11" d="M576.6,245.5l-1-1.7c0.5-0.3,1.1-0.7,1.7-0.9l0.7,1.9C577.5,244.9,577,245.2,576.6,245.5"/>
						<path class="st12" d="M578,244.7l-0.7-1.9c0.4-0.2,0.9-0.3,1.4-0.5l0.4,1.9C578.7,244.4,578.3,244.5,578,244.7"/>
						<path class="st13" d="M579,244.3l-0.4-1.9c0.4-0.1,0.7-0.2,1.1-0.2l0.2,2C579.6,244.2,579.3,244.2,579,244.3"/>
						<path class="st14" d="M579.9,244.1l-0.2-2c0.2,0,0.5,0,0.7,0c0.1,0,0.3,0,0.4,0l-0.1,2c-0.1,0-0.2,0-0.3,0
							C580.3,244.1,580.1,244.1,579.9,244.1"/>
						<path class="st51" d="M580.7,244.1l0.1-2c0.3,0,0.7,0.1,1,0.2l-0.5,1.9C581.1,244.2,580.9,244.1,580.7,244.1"/>
						<path class="st52" d="M581.4,244.2l0.5-1.9c0.4,0.1,0.8,0.2,1.1,0.4l-0.9,1.8C581.9,244.4,581.6,244.3,581.4,244.2"/>
						<path class="st53" d="M582.1,244.5l0.9-1.8c0.6,0.3,1.2,0.7,1.8,1.3l-1.4,1.4C582.9,245,582.5,244.7,582.1,244.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000144339456736911397710000017459721955524784769_" class="st1" d="M594.1,390.2L594.1,390.2
							c-0.6,2.7-1.2,7.6,1.2,10.1h0C592.9,397.8,593.5,392.9,594.1,390.2z"/>
					</defs>
					<clipPath id="SVGID_00000044857065990773475140000001967852356415666364_">
						<use xlink:href="#SVGID_00000144339456736911397710000017459721955524784769_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000044857065990773475140000001967852356415666364_);enable-background:new    ;">
						<path class="st16" d="M594.1,390.2L594.1,390.2c-0.1,0.4-0.2,0.8-0.2,1.3h0C594,391,594.1,390.6,594.1,390.2"/>
						<path class="st34" d="M593.9,391.5L593.9,391.5c-0.1,0.9-0.3,1.9-0.3,2.9h0C593.6,393.4,593.7,392.4,593.9,391.5"/>
						<path class="st33" d="M593.6,394.4L593.6,394.4c0,0.7,0,1.4,0.1,2h0C593.6,395.7,593.6,395.1,593.6,394.4"/>
						<path class="st32" d="M593.7,396.4L593.7,396.4c0.1,0.5,0.2,1.1,0.4,1.6h0C593.9,397.5,593.8,396.9,593.7,396.4"/>
						<path class="st31" d="M594,398L594,398c0.3,0.9,0.7,1.7,1.4,2.3h0C594.7,399.6,594.3,398.8,594,398"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000083767031115926592670000012980026351341416124_" class="st1" d="M597.5,286.4L597.5,286.4
							c-0.2,0.1-3.3,2.9-3.7,7.2h0C594.2,289.4,597.3,286.6,597.5,286.4z"/>
					</defs>
					<clipPath id="SVGID_00000123410271956472376200000011076992561655689662_">
						<use xlink:href="#SVGID_00000083767031115926592670000012980026351341416124_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000123410271956472376200000011076992561655689662_);enable-background:new    ;">
						<path class="st3" d="M597.5,286.4L597.5,286.4c-0.1,0.1-1.4,1.2-2.4,3.2h0C596.1,287.7,597.4,286.5,597.5,286.4"/>
						<path class="st35" d="M595,289.6L595,289.6c-0.3,0.6-0.6,1.2-0.8,1.8h0C594.5,290.8,594.7,290.1,595,289.6"/>
						<path class="st16" d="M594.2,291.4L594.2,291.4c-0.1,0.5-0.3,1-0.4,1.5h0C594,292.4,594.1,291.9,594.2,291.4"/>
						<path class="st34" d="M593.9,292.9L593.9,292.9c0,0.2-0.1,0.5-0.1,0.7h0C593.8,293.4,593.8,293.2,593.9,292.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000079458590623738458090000003291379362460708791_" class="st1" d="M508,425l0-2c43.6,0,79-35.4,79-79
							c0-43.6-35.4-79-79-79c-43.6,0-79,35.4-79,79c0,43.6,35.4,79,79,79L508,425c-44.7,0-81-36.3-81-81c0-44.7,36.3-81,81-81
							c44.7,0,81,36.3,81,81C589,388.7,552.7,425,508,425z"/>
					</defs>
					<clipPath id="SVGID_00000163049129880653084950000005616492363327881103_">
						<use xlink:href="#SVGID_00000079458590623738458090000003291379362460708791_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000163049129880653084950000005616492363327881103_);enable-background:new    ;">
						<path class="st14" d="M508,425l0-2c2.7,0,5.3-0.1,7.9-0.4l0.2,2C513.4,424.9,510.7,425,508,425"/>
						<path class="st13" d="M516.1,424.6l-0.2-2c4.1-0.4,8.1-1.1,12-2.2l0.5,1.9C524.5,423.4,520.3,424.2,516.1,424.6"/>
						<path class="st12" d="M528.5,422.4l-0.5-1.9c4.1-1.1,8.1-2.5,11.9-4.1l0.8,1.8C536.7,419.9,532.7,421.3,528.5,422.4"/>
						<path class="st11" d="M540.7,418.1l-0.8-1.8c3.9-1.7,7.7-3.8,11.2-6.1l1.1,1.7C548.5,414.3,544.7,416.4,540.7,418.1"/>
						<path class="st10" d="M552.2,411.9l-1.1-1.7c3.9-2.5,7.5-5.4,10.9-8.6l1.4,1.5C559.9,406.4,556.2,409.3,552.2,411.9"/>
						<path class="st9" d="M563.4,403.1l-1.4-1.5c3.8-3.5,7.2-7.4,10.2-11.6l1.6,1.2C570.7,395.5,567.2,399.5,563.4,403.1"/>
						<path class="st8" d="M573.8,391.2l-1.6-1.2c3.4-4.8,6.3-9.9,8.6-15.4l1.8,0.8C580.3,381.1,577.3,386.4,573.8,391.2"/>
						<path class="st7" d="M582.6,375.5l-1.8-0.8c2.9-6.9,4.9-14.3,5.7-22l2,0.2C587.6,360.8,585.6,368.4,582.6,375.5"/>
						<path class="st5" d="M588.5,352.9l-2-0.2c0.3-2.8,0.5-5.7,0.5-8.7c0-10.9-2.2-21.3-6.2-30.8l1.8-0.8
							c4.1,9.7,6.4,20.4,6.4,31.6C589,347,588.8,350,588.5,352.9"/>
						<path class="st18" d="M582.6,312.4l-1.8,0.8c-5.7-13.5-15.1-25.1-26.9-33.5l1.2-1.6C567.1,286.7,576.7,298.6,582.6,312.4"/>
						<path class="st5" d="M555,278l-1.2,1.6c-10.7-7.6-23.3-12.6-37-14.2l0.2-2C531.1,265.1,544.1,270.2,555,278"/>
						<path class="st7" d="M517,263.5l-0.2,2c-2.9-0.3-5.8-0.5-8.8-0.5c-4.8,0-9.4,0.4-14,1.2l-0.4-2c4.7-0.8,9.4-1.3,14.3-1.3
							C511.1,263,514.1,263.2,517,263.5"/>
						<path class="st8" d="M493.6,264.3l0.4,2c-5.9,1-11.5,2.7-16.8,5l-0.8-1.8C481.9,267.1,487.6,265.3,493.6,264.3"/>
						<path class="st9" d="M476.4,269.4l0.8,1.8c-4.7,2-9.2,4.4-13.4,7.3l-1.1-1.7C467,274,471.6,271.4,476.4,269.4"/>
						<path class="st10" d="M462.7,276.9l1.1,1.7c-3.8,2.6-7.4,5.5-10.6,8.6l-1.4-1.4C455.1,282.5,458.8,279.5,462.7,276.9"/>
						<path class="st11" d="M451.8,285.7l1.4,1.4c-3.1,3-6,6.2-8.5,9.7l-1.6-1.2C445.6,292.1,448.6,288.8,451.8,285.7"/>
						<path class="st12" d="M443,295.7l1.6,1.2c-2.5,3.3-4.7,6.8-6.6,10.5l-1.8-0.9C438.2,302.7,440.5,299.1,443,295.7"/>
						<path class="st13" d="M436.2,306.4l1.8,0.9c-1.9,3.7-3.6,7.5-4.9,11.5l-1.9-0.6C432.6,314.2,434.2,310.2,436.2,306.4"/>
						<path class="st14" d="M431.2,318.2l1.9,0.6c-1.3,3.9-2.3,8-3,12.2l-2-0.3C428.8,326.4,429.8,322.3,431.2,318.2"/>
						<path class="st51" d="M428.1,330.7l2,0.3c-0.7,4.2-1.1,8.5-1.1,12.9c0,0.5,0,0.9,0,1.4l-2,0c0-0.5,0-1,0-1.4
							C427,339.5,427.4,335,428.1,330.7"/>
						<path class="st52" d="M427,345.4l2,0c0.1,6,0.9,11.8,2.2,17.4l-1.9,0.5C427.9,357.5,427.1,351.6,427,345.4"/>
						<path class="st53" d="M429.3,363.3l1.9-0.5c5.5,22.7,20.9,41.6,41.3,51.8l-0.9,1.8C450.7,405.9,435,386.5,429.3,363.3"/>
						<path class="st52" d="M471.6,416.4l0.9-1.8c5.1,2.6,10.5,4.6,16.1,6l-0.5,1.9C482.4,421.1,476.9,419,471.6,416.4"/>
						<path class="st51" d="M488.2,422.6l0.5-1.9c4.5,1.1,9.2,1.9,14,2.2l-0.1,2C497.6,424.5,492.8,423.7,488.2,422.6"/>
						<path class="st14" d="M502.5,424.8l0.1-2c1.8,0.1,3.5,0.2,5.3,0.2l0,2C506.2,425,504.3,424.9,502.5,424.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000066507728389229892800000015087358530247439277_" class="st1" d="M602.5,370.2L602.5,370.2
							c-2,7-4.9,13.7-8.3,20h0C597.6,383.9,600.4,377.2,602.5,370.2z"/>
					</defs>
					<clipPath id="SVGID_00000004531682308017261170000017703878023671945614_">
						<use xlink:href="#SVGID_00000066507728389229892800000015087358530247439277_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000004531682308017261170000017703878023671945614_);enable-background:new    ;">
						<path class="st16" d="M602.5,370.2L602.5,370.2c-0.3,1.1-0.6,2.2-1,3.2h0C601.8,372.4,602.2,371.3,602.5,370.2"/>
						<path class="st35" d="M601.5,373.4L601.5,373.4c-1.8,5.6-4.2,11-6.9,16.1h0C597.3,384.4,599.6,379,601.5,373.4"/>
						<path class="st3" d="M594.5,389.5L594.5,389.5c-0.1,0.2-0.2,0.5-0.4,0.7h0C594.3,390,594.4,389.7,594.5,389.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000164478636589261891060000001780637139945371270_" class="st1" d="M592.4,389.2l1.8,1
							c-0.6,2.7-1.3,7.6,1.2,10.1l-1.4,1.4C590.7,398.4,591.5,392.7,592.4,389.2z"/>
					</defs>
					<clipPath id="SVGID_00000105419159984530093630000008938756562875666604_">
						<use xlink:href="#SVGID_00000164478636589261891060000001780637139945371270_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000105419159984530093630000008938756562875666604_);enable-background:new    ;">
						<path class="st14" d="M592.4,389.2l1.8,1c-0.1,0.4-0.2,0.8-0.3,1.3l-1.9-0.6C592.1,390.3,592.3,389.7,592.4,389.2"/>
						<path class="st51" d="M592,390.8l1.9,0.6c-0.2,1-0.3,2.2-0.3,3.4l-2,0.1C591.6,393.5,591.8,392.1,592,390.8"/>
						<path class="st52" d="M591.6,394.9l2-0.1c0,0.8,0.1,1.6,0.2,2.4l-1.9,0.6C591.7,396.9,591.6,395.9,591.6,394.9"/>
						<path class="st53" d="M591.9,397.8l1.9-0.6c0.3,1.2,0.7,2.2,1.5,3l-1.4,1.4C592.9,400.6,592.3,399.3,591.9,397.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000049900540918177565340000009213698591125591988_" class="st1" d="M593.8,293.6L593.8,293.6
							c4,7,7.1,14.6,9.3,22.6h0C600.9,308.3,597.7,300.7,593.8,293.6z"/>
					</defs>
					<clipPath id="SVGID_00000094594602457888144220000011575534869093542316_">
						<use xlink:href="#SVGID_00000049900540918177565340000009213698591125591988_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000094594602457888144220000011575534869093542316_);enable-background:new    ;">
						<path class="st31" d="M593.8,293.6L593.8,293.6c3.3,5.8,6,12,8,18.4h0C599.7,305.6,597,299.5,593.8,293.6"/>
						<path class="st32" d="M601.8,312.1L601.8,312.1c0.5,1.4,0.9,2.8,1.2,4.2h0C602.7,314.9,602.2,313.5,601.8,312.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000157291926091118546070000018294946200993718411_" class="st1" d="M595.4,400.3L595.4,400.3
							c3.5,3.5,8.9,8.9,9.2,9.2h0C604.3,409.2,598.9,403.8,595.4,400.3z"/>
					</defs>
					<clipPath id="SVGID_00000051374415592864171870000016408482948015875732_">
						<use xlink:href="#SVGID_00000157291926091118546070000018294946200993718411_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000051374415592864171870000016408482948015875732_);enable-background:new    ;">
						<path class="st31" d="M595.4,400.3L595.4,400.3c3.5,3.5,8.9,8.9,9.2,9.2h0C604.3,409.2,598.9,403.8,595.4,400.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000072971636721934277530000017726733725475969205_" class="st1" d="M596.1,285l1.4,1.4
							c-0.2,0.1-3.3,2.9-3.7,7.2l-2.1,0.4C592.3,288.4,595.9,285.1,596.1,285z"/>
					</defs>
					<clipPath id="SVGID_00000016048490637110814070000016115642925901284250_">
						<use xlink:href="#SVGID_00000072971636721934277530000017726733725475969205_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000016048490637110814070000016115642925901284250_);enable-background:new    ;">
						<path class="st10" d="M596.1,285l1.4,1.4c0,0-0.1,0.1-0.2,0.2l-1.4-1.4C596,285.1,596.1,285,596.1,285"/>
						<path class="st11" d="M595.9,285.2l1.4,1.4c-0.3,0.2-0.7,0.7-1.2,1.4l-1.6-1.2C595.1,286,595.6,285.5,595.9,285.2"/>
						<path class="st12" d="M594.5,286.8l1.6,1.2c-0.3,0.4-0.7,1-1,1.6l-1.8-0.8C593.7,288,594.1,287.3,594.5,286.8"/>
						<path class="st13" d="M593.3,288.8l1.8,0.8c-0.3,0.5-0.5,1-0.7,1.6l-1.9-0.4C592.7,290,593,289.4,593.3,288.8"/>
						<path class="st14" d="M592.4,290.8l1.9,0.4c-0.2,0.5-0.4,1.1-0.5,1.7l-2,0.2C592,292.2,592.2,291.4,592.4,290.8"/>
						<path class="st51" d="M591.9,293l2-0.2c0,0.3-0.1,0.5-0.1,0.8l-2.1,0.4C591.8,293.7,591.8,293.3,591.9,293"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000114040972799877629020000006000349401813056165_" class="st1" d="M606,420.8L606,420.8
							c-4.9,4.9-20.4,20.4-21.2,21.2h0C585.6,441.2,601,425.7,606,420.8z"/>
					</defs>
					<clipPath id="SVGID_00000170274261610079780980000000520183249100946570_">
						<use xlink:href="#SVGID_00000114040972799877629020000006000349401813056165_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000170274261610079780980000000520183249100946570_);enable-background:new    ;">
						<path class="st3" d="M606,420.8L606,420.8c-4.9,4.9-20.4,20.4-21.2,21.2h0C585.6,441.2,601,425.7,606,420.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000036930816392926444060000009359395825674410943_" class="st1" d="M606,277.9L606,277.9
							c-5.6,5.7-8.3,8.3-8.5,8.5h0C597.6,286.3,600.3,283.6,606,277.9z"/>
					</defs>
					<clipPath id="SVGID_00000012437172848086404330000005935949898968333472_">
						<use xlink:href="#SVGID_00000036930816392926444060000009359395825674410943_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000012437172848086404330000005935949898968333472_);enable-background:new    ;">
						<path class="st3" d="M606,277.9L606,277.9c-5.6,5.7-8.3,8.3-8.5,8.5h0C597.6,286.3,600.3,283.6,606,277.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000110471128432199876950000008666970185757622153_" class="st1" d="M584.8,244L584.8,244
							c5,4.9,20.4,20.4,21.2,21.2h0C605.2,264.4,589.7,249,584.8,244z"/>
					</defs>
					<clipPath id="SVGID_00000108998096768593454130000012917198428253687188_">
						<use xlink:href="#SVGID_00000110471128432199876950000008666970185757622153_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000108998096768593454130000012917198428253687188_);enable-background:new    ;">
						<path class="st31" d="M584.8,244L584.8,244c5,4.9,20.4,20.4,21.2,21.2h0C605.2,264.4,589.7,249,584.8,244"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000053505872351308749470000006343546090070460312_" class="st1" d="M600.7,369.2l1.8,1
							c-2.1,7-4.9,13.7-8.3,20l-1.8-1C595.8,383.1,598.5,376.3,600.7,369.2z"/>
					</defs>
					<clipPath id="SVGID_00000130607474056427497340000003320515070052176284_">
						<use xlink:href="#SVGID_00000053505872351308749470000006343546090070460312_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000130607474056427497340000003320515070052176284_);enable-background:new    ;">
						<path class="st14" d="M600.7,369.2l1.8,1c-0.3,0.9-0.5,1.7-0.8,2.6l-1.8-1C600.1,370.9,600.4,370.1,600.7,369.2"/>
						<path class="st13" d="M599.9,371.8l1.8,1c-1.7,5.3-3.9,10.5-6.4,15.4l-1.8-0.9C596,382.4,598,377.2,599.9,371.8"/>
						<path class="st12" d="M593.4,387.2l1.8,0.9c-0.4,0.7-0.7,1.4-1.1,2l-1.8-1C592.7,388.6,593.1,387.9,593.4,387.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000022532328601775036240000004733207517437578173_" class="st1" d="M603,316.3L603,316.3
							c1.7,2.2,4.2,4.7,7,4.7h0C607.2,321,604.7,318.5,603,316.3z"/>
					</defs>
					<clipPath id="SVGID_00000143575583191502464990000006106898884296361092_">
						<use xlink:href="#SVGID_00000022532328601775036240000004733207517437578173_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000143575583191502464990000006106898884296361092_);enable-background:new    ;">
						<path class="st31" d="M603,316.3L603,316.3c1.1,1.4,2.5,3,4.2,3.9h0C605.5,319.3,604.1,317.7,603,316.3"/>
						<path class="st32" d="M607.2,320.2L607.2,320.2c0.4,0.2,0.8,0.4,1.1,0.5h0C607.9,320.6,607.5,320.4,607.2,320.2"/>
						<path class="st33" d="M608.3,320.7L608.3,320.7c0.3,0.1,0.5,0.2,0.8,0.2h0C608.8,320.9,608.6,320.8,608.3,320.7"/>
						<path class="st34" d="M609.1,320.9L609.1,320.9c0.2,0,0.5,0.1,0.7,0.1h0C609.6,321,609.3,321,609.1,320.9"/>
						<path class="st16" d="M609.8,321L609.8,321c0.1,0,0.1,0,0.2,0h0C609.9,321,609.9,321,609.8,321"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000088815728879895865230000012067921256437725860_" class="st1" d="M591.7,294.1l2.1-0.4
							c4,7,7.1,14.6,9.3,22.6l-2,0.5C598.9,309.2,595.9,301.5,591.7,294.1z"/>
					</defs>
					<clipPath id="SVGID_00000039841835668875512210000004566616124283932572_">
						<use xlink:href="#SVGID_00000088815728879895865230000012067921256437725860_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000039841835668875512210000004566616124283932572_);enable-background:new    ;">
						<path class="st53" d="M591.7,294.1l2.1-0.4c4,7,7.1,14.6,9.3,22.6l-2,0.5C598.9,309.2,595.9,301.5,591.7,294.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000076605328688287138920000012805930089214236079_" class="st1" d="M610.9,367L610.9,367
							c-1.6,0-5.6,0.3-8.4,3.2h0C605.3,367.3,609.3,367,610.9,367z"/>
					</defs>
					<clipPath id="SVGID_00000122710165862862471160000011805766392885739394_">
						<use xlink:href="#SVGID_00000076605328688287138920000012805930089214236079_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000122710165862862471160000011805766392885739394_);enable-background:new    ;">
						<path class="st3" d="M610.9,367L610.9,367c-1.6,0-5.6,0.3-8.4,3.2h0C605.3,367.3,609.3,367,610.9,367"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000058582324940314859160000016562099597079991231_" class="st1" d="M604.6,409.5L604.6,409.5
							c0.3,0.3,6.4,6.4,1.4,11.3h0C610.9,415.8,604.9,409.8,604.6,409.5z"/>
					</defs>
					<clipPath id="SVGID_00000011750031295157142710000018338803860552648121_">
						<use xlink:href="#SVGID_00000058582324940314859160000016562099597079991231_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000011750031295157142710000018338803860552648121_);enable-background:new    ;">
						<path class="st31" d="M604.6,409.5L604.6,409.5c0.2,0.2,2.2,2.2,3,4.8h0C606.7,411.6,604.7,409.6,604.6,409.5"/>
						<path class="st32" d="M607.6,414.2L607.6,414.2c0.2,0.4,0.3,0.9,0.3,1.3h0C607.8,415.1,607.7,414.7,607.6,414.2"/>
						<path class="st33" d="M607.9,415.6L607.9,415.6c0.1,0.3,0.1,0.6,0.1,1h0C607.9,416.2,607.9,415.9,607.9,415.6"/>
						<path class="st34" d="M607.9,416.5L607.9,416.5c0,0.3,0,0.6-0.1,0.8h0C607.9,417.1,607.9,416.8,607.9,416.5"/>
						<path class="st16" d="M607.8,417.4L607.8,417.4c0,0.3-0.1,0.6-0.2,0.8h0C607.7,417.9,607.8,417.7,607.8,417.4"/>
						<path class="st35" d="M607.6,418.2L607.6,418.2c-0.1,0.3-0.2,0.6-0.4,1h0C607.4,418.8,607.5,418.5,607.6,418.2"/>
						<path class="st3" d="M607.2,419.2L607.2,419.2c-0.3,0.5-0.7,1.1-1.2,1.6h0C606.5,420.3,606.9,419.7,607.2,419.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000020393608455129966730000009271797001456039598_" class="st1" d="M606,265.2L606,265.2
							c0.3,0.4,5.7,7.1,0,12.7h0C611.6,272.3,606.3,265.6,606,265.2z"/>
					</defs>
					<clipPath id="SVGID_00000101787180405358164390000002283675532142160058_">
						<use xlink:href="#SVGID_00000020393608455129966730000009271797001456039598_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000101787180405358164390000002283675532142160058_);enable-background:new    ;">
						<path class="st31" d="M606,265.2L606,265.2c0.1,0.2,1.4,1.7,2.1,3.9h0C607.4,267,606.1,265.4,606,265.2"/>
						<path class="st32" d="M608.1,269.2L608.1,269.2c0.2,0.6,0.3,1.2,0.4,1.8h0C608.4,270.3,608.3,269.7,608.1,269.2"/>
						<path class="st33" d="M608.5,271L608.5,271c0.1,0.4,0.1,0.9,0.1,1.3h0C608.6,271.8,608.5,271.4,608.5,271"/>
						<path class="st34" d="M608.6,272.3L608.6,272.3c0,0.4,0,0.8-0.1,1.1h0C608.5,273,608.5,272.6,608.6,272.3"/>
						<path class="st16" d="M608.4,273.4L608.4,273.4c-0.1,0.4-0.1,0.7-0.3,1.1h0C608.3,274.1,608.4,273.8,608.4,273.4"/>
						<path class="st35" d="M608.2,274.5L608.2,274.5c-0.1,0.4-0.3,0.9-0.6,1.3h0C607.8,275.4,608,275,608.2,274.5"/>
						<path class="st3" d="M607.6,275.8L607.6,275.8c-0.4,0.7-0.9,1.4-1.6,2.1h0C606.7,277.2,607.2,276.5,607.6,275.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000050625601612214830650000000156267625999134344_" class="st1" d="M583.4,245.4l1.4-1.4
							c4.9,4.9,20.4,20.4,21.2,21.2l-1.4,1.4C603.7,265.8,588.3,250.4,583.4,245.4z"/>
					</defs>
					<clipPath id="SVGID_00000077287416647616666720000005388097567697704578_">
						<use xlink:href="#SVGID_00000050625601612214830650000000156267625999134344_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000077287416647616666720000005388097567697704578_);enable-background:new    ;">
						<path class="st53" d="M583.4,245.4l1.4-1.4c4.9,4.9,20.4,20.4,21.2,21.2l-1.4,1.4C603.7,265.8,588.3,250.4,583.4,245.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000027562538788594896920000012487871451748344709_" class="st1" d="M604.6,419.4l1.4,1.4
							c-4.9,4.9-20.4,20.4-21.2,21.2l-1.4-1.4C584.2,439.8,599.6,424.3,604.6,419.4z"/>
					</defs>
					<clipPath id="SVGID_00000087389950162582549360000014078117738823877566_">
						<use xlink:href="#SVGID_00000027562538788594896920000012487871451748344709_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000087389950162582549360000014078117738823877566_);enable-background:new    ;">
						<path class="st11" d="M604.6,419.4l1.4,1.4c-4.9,4.9-20.4,20.4-21.2,21.2l-1.4-1.4C584.2,439.8,599.6,424.3,604.6,419.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000150097292834493858260000010010148707651373234_" class="st1" d="M604.6,276.5l1.4,1.4
							c-5.7,5.7-8.3,8.3-8.5,8.5l-1.4-1.4C596.2,284.9,598.9,282.2,604.6,276.5z"/>
					</defs>
					<clipPath id="SVGID_00000103946996490334094630000009562480279366773167_">
						<use xlink:href="#SVGID_00000150097292834493858260000010010148707651373234_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000103946996490334094630000009562480279366773167_);enable-background:new    ;">
						<path class="st11" d="M604.6,276.5l1.4,1.4c-5.7,5.7-8.3,8.3-8.5,8.5l-1.4-1.4C596.2,284.9,598.9,282.2,604.6,276.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000016757059630093692540000013036175935781596301_" class="st1" d="M594,401.7l1.4-1.4
							c3.5,3.5,8.9,8.9,9.2,9.2c0.3,0.3,6.4,6.4,1.4,11.3l-1.4-1.4c3.5-3.4-1.2-8.2-1.4-8.5C602.9,410.6,597.5,405.2,594,401.7z"/>
					</defs>
					<clipPath id="SVGID_00000178164366073891343680000012782157901048558993_">
						<use xlink:href="#SVGID_00000016757059630093692540000013036175935781596301_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000178164366073891343680000012782157901048558993_);enable-background:new    ;">
						<path class="st53" d="M594,401.7l1.4-1.4c3.5,3.5,8.9,8.9,9.2,9.2c0.2,0.2,2.4,2.4,3.1,5.2l-2,0.3c-0.6-2.1-2.4-3.9-2.5-4
							C602.9,410.6,597.5,405.2,594,401.7"/>
						<path class="st52" d="M605.6,414.9l2-0.3c0.1,0.5,0.2,1.1,0.2,1.6l-2-0.1C605.8,415.7,605.8,415.3,605.6,414.9"/>
						<path class="st51" d="M605.9,416.1l2,0.1c0,0.4,0,0.7-0.1,1.1l-2-0.4C605.9,416.7,605.9,416.4,605.9,416.1"/>
						<path class="st14" d="M605.8,416.9l2,0.4c-0.1,0.3-0.1,0.6-0.2,0.9l-1.9-0.7C605.8,417.4,605.8,417.2,605.8,416.9"/>
						<path class="st13" d="M605.7,417.6l1.9,0.7c-0.1,0.3-0.2,0.6-0.4,0.8l-1.8-0.9C605.6,418,605.6,417.8,605.7,417.6"/>
						<path class="st12" d="M605.5,418.2l1.8,0.9c-0.1,0.3-0.3,0.5-0.5,0.8l-1.6-1.1C605.2,418.6,605.4,418.4,605.5,418.2"/>
						<path class="st11" d="M605.1,418.8l1.6,1.1c-0.2,0.3-0.5,0.6-0.7,0.9l-1.4-1.4C604.8,419.2,604.9,419,605.1,418.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000090289969087131780930000004525723537764241302_" class="st1" d="M604.6,266.6l1.4-1.4
							c0.3,0.4,5.7,7.1,0,12.7l-1.4-1.4C608.9,272.2,604.6,266.7,604.6,266.6z"/>
					</defs>
					<clipPath id="SVGID_00000048494986122205807780000001883571878683882159_">
						<use xlink:href="#SVGID_00000090289969087131780930000004525723537764241302_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000048494986122205807780000001883571878683882159_);enable-background:new    ;">
						<path class="st53" d="M604.6,266.6l1.4-1.4c0.1,0.2,1.7,2.1,2.3,4.6l-2,0.4C605.8,268.2,604.6,266.7,604.6,266.6"/>
						<path class="st52" d="M606.3,270.2l2-0.4c0.2,0.6,0.3,1.3,0.3,2l-2-0.1C606.5,271.3,606.4,270.7,606.3,270.2"/>
						<path class="st51" d="M606.5,271.8l2,0.1c0,0.5,0,1-0.1,1.5l-2-0.4C606.5,272.6,606.5,272.2,606.5,271.8"/>
						<path class="st14" d="M606.5,273l2,0.4c-0.1,0.4-0.2,0.8-0.3,1.2l-1.9-0.6C606.3,273.6,606.4,273.3,606.5,273"/>
						<path class="st13" d="M606.2,273.9l1.9,0.6c-0.1,0.4-0.3,0.8-0.5,1.1l-1.8-0.9C606,274.5,606.1,274.2,606.2,273.9"/>
						<path class="st12" d="M605.9,274.8l1.8,0.9c-0.2,0.4-0.4,0.8-0.7,1.1l-1.6-1.1C605.5,275.4,605.7,275.1,605.9,274.8"/>
						<path class="st11" d="M605.3,275.7l1.6,1.1c-0.3,0.4-0.6,0.8-1,1.1l-1.4-1.4C604.8,276.2,605.1,276,605.3,275.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000175322506608049970860000007961903007311194257_" class="st1" d="M601.1,316.8l2-0.5
							c1.6,2.2,4.1,4.7,6.9,4.7l0,2C606.2,323,603.2,319.9,601.1,316.8z"/>
					</defs>
					<clipPath id="SVGID_00000065067138398943607720000010876630116247459255_">
						<use xlink:href="#SVGID_00000175322506608049970860000007961903007311194257_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000065067138398943607720000010876630116247459255_);enable-background:new    ;">
						<path class="st63" d="M601.1,316.8l2-0.5c0.6,0.8,1.3,1.6,2.1,2.4l-1.4,1.3C602.7,319,601.8,317.9,601.1,316.8"/>
						<path class="st53" d="M603.7,320l1.4-1.3c0.8,0.7,1.6,1.3,2.5,1.8l-0.8,1.8C605.7,321.7,604.6,320.9,603.7,320"/>
						<path class="st52" d="M606.8,322.2l0.8-1.8c0.4,0.2,0.8,0.3,1.2,0.4l-0.4,2C607.8,322.7,607.3,322.5,606.8,322.2"/>
						<path class="st51" d="M608.4,322.8l0.4-2c0.3,0.1,0.6,0.1,0.9,0.1l-0.1,2C609.2,323,608.8,322.9,608.4,322.8"/>
						<path class="st14" d="M609.6,323l0.1-2c0.1,0,0.2,0,0.3,0l0,2C609.8,323,609.7,323,609.6,323"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000105390411548194004690000001924206179268233150_" class="st1" d="M632,358L632,358c0,0.5-1,9-9,9h0
							C631,367,631.9,358.5,632,358z"/>
					</defs>
					<clipPath id="SVGID_00000132065684235927711300000003078739707471045000_">
						<use xlink:href="#SVGID_00000105390411548194004690000001924206179268233150_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000132065684235927711300000003078739707471045000_);enable-background:new    ;">
						<path class="st34" d="M632,358L632,358c0,0.1,0,0.3-0.1,0.5h0C631.9,358.3,632,358.1,632,358"/>
						<path class="st16" d="M631.9,358.5L631.9,358.5c-0.1,0.5-0.2,1.2-0.5,2h0C631.7,359.7,631.8,359,631.9,358.5"/>
						<path class="st35" d="M631.4,360.5L631.4,360.5c-0.2,0.6-0.5,1.3-0.8,2h0C630.9,361.8,631.2,361.1,631.4,360.5"/>
						<path class="st3" d="M630.6,362.5L630.6,362.5c-1.2,2.3-3.5,4.5-7.6,4.5h0C627.1,367,629.3,364.7,630.6,362.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000121241026381761138430000006367931036853429400_" class="st1" d="M632,328L632,328c0,7,0,28.8,0,30h0
							C632,356.8,632,335,632,328z"/>
					</defs>
					<clipPath id="SVGID_00000084502405302614570760000018442655975706215830_">
						<use xlink:href="#SVGID_00000121241026381761138430000006367931036853429400_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000084502405302614570760000018442655975706215830_);enable-background:new    ;">
						<path class="st33" d="M632,328L632,328c0,7,0,28.8,0,30h0C632,356.8,632,335,632,328"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000062157026751316838130000004651263178358416001_" class="st1" d="M623,321L623,321c0.5,0,9,0,9,7h0
							C632,321,623.4,321,623,321z"/>
					</defs>
					<clipPath id="SVGID_00000085958256386465142370000001995593917649148573_">
						<use xlink:href="#SVGID_00000062157026751316838130000004651263178358416001_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000085958256386465142370000001995593917649148573_);enable-background:new    ;">
						<path class="st16" d="M623,321L623,321c0.1,0,0.2,0,0.3,0h0C623.1,321,623,321,623,321"/>
						<path class="st34" d="M623.3,321L623.3,321c0.4,0,1.2,0.1,2,0.2h0C624.4,321.1,623.7,321,623.3,321"/>
						<path class="st33" d="M625.3,321.2L625.3,321.2c0.6,0.1,1.2,0.3,1.9,0.5h0C626.5,321.5,625.9,321.3,625.3,321.2"/>
						<path class="st32" d="M627.2,321.7L627.2,321.7c0.5,0.2,1.1,0.4,1.6,0.7h0C628.2,322.1,627.7,321.9,627.2,321.7"/>
						<path class="st31" d="M628.7,322.4L628.7,322.4c1.3,0.7,2.4,1.8,2.9,3.4h0C631.1,324.2,630,323.1,628.7,322.4"/>
						<path class="st32" d="M631.7,325.8L631.7,325.8c0.1,0.4,0.2,0.8,0.3,1.2h0C631.9,326.6,631.8,326.2,631.7,325.8"/>
						<path class="st33" d="M631.9,327L631.9,327c0.1,0.3,0.1,0.6,0.1,1h0C632,327.7,632,327.3,631.9,327"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000145020897821611127630000011544147801083702198_" class="st1" d="M623,365l0,2c-8,0-11.8,0-12,0
							c-1.5,0-5.6,0.2-8.5,3.2l-1.8-1c3.8-3.9,8.6-4.2,10.3-4.2C611.2,365,615,365,623,365z"/>
					</defs>
					<clipPath id="SVGID_00000041271214570013420030000015375573565980885894_">
						<use xlink:href="#SVGID_00000145020897821611127630000011544147801083702198_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000041271214570013420030000015375573565980885894_);enable-background:new    ;">
						<path class="st7" d="M623,365l0,2c-8,0-11.8,0-12,0c-0.6,0-1.5,0-2.6,0.2l-0.4-1.9c1.3-0.2,2.4-0.3,3-0.3
							C611.2,365,615,365,623,365"/>
						<path class="st8" d="M608,365.3l0.4,1.9c-0.8,0.1-1.8,0.4-2.7,0.8l-0.9-1.7C605.9,365.8,607,365.5,608,365.3"/>
						<path class="st9" d="M604.8,366.3l0.9,1.7c-0.6,0.2-1.1,0.5-1.7,0.9l-1.3-1.4C603.4,367,604.1,366.6,604.8,366.3"/>
						<path class="st10" d="M602.6,367.5l1.3,1.4c-0.4,0.3-0.9,0.6-1.3,1l-1.7-1.1C601.6,368.3,602.1,367.9,602.6,367.5"/>
						<path class="st11" d="M601,368.8l1.7,1.1c-0.1,0.1-0.2,0.2-0.3,0.3l-1.8-1C600.8,369,600.9,368.9,601,368.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000119091394220686036370000001034212059268110014_" class="st1" d="M610,323l0-2c5,0,12.6,0,13,0l0,2
							C622.6,323,615,323,610,323z"/>
					</defs>
					<clipPath id="SVGID_00000090975500348537803660000003135023094307530644_">
						<use xlink:href="#SVGID_00000119091394220686036370000001034212059268110014_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000090975500348537803660000003135023094307530644_);enable-background:new    ;">
						<path class="st14" d="M610,323l0-2c5,0,12.6,0,13,0l0,2C622.6,323,615,323,610,323"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000105393408124979024230000011256881585788184215_" class="st1" d="M630,358h2c0,0.5-1,9-9,9l0-2
							C629.1,365.1,629.9,358.1,630,358z"/>
					</defs>
					<clipPath id="SVGID_00000171696090389383220820000002910398814498757557_">
						<use xlink:href="#SVGID_00000105393408124979024230000011256881585788184215_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000171696090389383220820000002910398814498757557_);enable-background:new    ;">
						<path class="st51" d="M630,358h2c0,0.1,0,0.2-0.1,0.5l-2-0.2C630,358.1,630,358,630,358"/>
						<path class="st14" d="M629.9,358.3l2,0.2c-0.1,0.5-0.2,1.2-0.5,2.1l-1.9-0.6C629.7,359.2,629.9,358.6,629.9,358.3"/>
						<path class="st13" d="M629.5,359.9l1.9,0.6c-0.2,0.6-0.4,1.2-0.8,1.8l-1.7-1C629.2,360.8,629.4,360.3,629.5,359.9"/>
						<path class="st12" d="M628.9,361.3l1.7,1c-0.2,0.5-0.5,0.9-0.9,1.4l-1.5-1.3C628.5,362.1,628.7,361.7,628.9,361.3"/>
						<path class="st11" d="M628.2,362.4l1.5,1.3c-0.3,0.4-0.6,0.7-1,1.1l-1.3-1.5C627.8,363,628,362.7,628.2,362.4"/>
						<path class="st10" d="M627.5,363.3l1.3,1.5c-0.3,0.3-0.7,0.6-1.1,0.9l-1.1-1.7C627,363.8,627.2,363.5,627.5,363.3"/>
						<path class="st9" d="M626.6,364l1.1,1.7c-0.4,0.3-0.8,0.5-1.3,0.7l-0.8-1.8C626,364.3,626.3,364.2,626.6,364"/>
						<path class="st8" d="M625.7,364.5l0.8,1.8c-0.5,0.2-1.1,0.4-1.7,0.5l-0.4-2C624.8,364.8,625.3,364.7,625.7,364.5"/>
						<path class="st7" d="M624.3,364.9l0.4,2c-0.5,0.1-1.1,0.2-1.8,0.2l0-2C623.5,365,623.9,365,624.3,364.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000126310275301745140100000001801144879690743444_" class="st1" d="M630,328h2c0,7,0,28.8,0,30h-2
							C630,356.8,630,335,630,328z"/>
					</defs>
					<clipPath id="SVGID_00000150082813632778103340000006579706210461622430_">
						<use xlink:href="#SVGID_00000126310275301745140100000001801144879690743444_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000150082813632778103340000006579706210461622430_);enable-background:new    ;">
						<path class="st51" d="M630,328h2c0,7,0,28.8,0,30h-2C630,356.8,630,335,630,328"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000039832264172791878350000007867839171023962033_" class="st1" d="M623,323l0-2c0.4,0,9,0,9,7h-2
							C630.1,323.1,623.3,323,623,323z"/>
					</defs>
					<clipPath id="SVGID_00000083788065770434815330000001384463481580020906_">
						<use xlink:href="#SVGID_00000039832264172791878350000007867839171023962033_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000083788065770434815330000001384463481580020906_);enable-background:new    ;">
						<path class="st14" d="M623,323l0-2c0.1,0,0.3,0,0.7,0l-0.1,2C623.2,323,623,323,623,323"/>
						<path class="st51" d="M623.5,323l0.1-2c0.6,0,1.6,0.1,2.7,0.4l-0.7,1.9C624.8,323.1,624,323.1,623.5,323"/>
						<path class="st52" d="M625.7,323.4l0.7-1.9c0.8,0.2,1.6,0.5,2.3,0.9l-1.2,1.7C626.9,323.7,626.3,323.5,625.7,323.4"/>
						<path class="st53" d="M627.5,324l1.2-1.7c1.4,0.8,2.6,2,3.1,3.9l-1.9,0.5C629.5,325.5,628.6,324.6,627.5,324"/>
						<path class="st52" d="M629.8,326.8l1.9-0.5c0.1,0.5,0.2,1,0.2,1.5l-2,0.1C630,327.5,629.9,327.1,629.8,326.8"/>
						<path class="st51" d="M630,327.9l2-0.1c0,0.1,0,0.1,0,0.2h-2C630,328,630,327.9,630,327.9"/>
					</g>
				</g>
				<g>
					<path class="st147" d="M623,323c0.4,0,7.1,0.1,7,5c0,7,0,28.8,0,30c0,0.1-0.9,7.1-7,7c-8,0-11.8,0-12,0
						c-1.7,0-6.5,0.2-10.3,4.2c-2.2,7.2-4.9,13.9-8.3,20.1c-0.8,3.5-1.7,9.1,1.6,12.5c3.5,3.5,8.9,8.9,9.2,9.2
						c0.3,0.3,5,5.1,1.4,8.5c-4.9,4.9-20.4,20.4-21.2,21.2c0.1-0.1-2.5,1.9-5.5,1.9c-1.4,0-2.9-0.5-4.4-1.9
						c-5.6-5.7-8.3-8.3-8.5-8.5c-0.1-0.2-3.8-4.3-9.5-4.3c-0.7,0-1.2-0.1-2.1,0.2c-5.8,3-11.5,5.3-26.8,10.2
						c7.5-1.4,0.5,1.5,0.4,7.9c0,5,0,12.6,0,13c0,0.4-0.1,7.1-5,7c-7,0-28.9,0-30,0c-0.1,0-7.1-0.9-7-7c0-8,0-11.8,0-12
						c0-0.3,0.5-8-6.2-11.7c-5.8-1.9-11.4-4.1-26.5-12c7.4,5.4,6.5,4.3,2.9,4.3c-2.4,0-5,0.5-6.9,2.4c-3.5,3.5-8.9,8.9-9.2,9.2
						c-0.1,0.1-2.8,2.7-5.6,2.7c-0.9,0-1.9-0.3-2.9-1.3c-4.9-4.9-20.4-20.4-21.2-21.2c-0.1-0.1-4.4-5.6,0-9.9
						c5.7-5.7,8.3-8.3,8.5-8.5c0.2-0.2,5.5-4.8,4.1-11.7c-3.2-5.9-5.5-11.8-7.5-17.5c-1.3-2.4-5.1-8.7-10.6-8.8c-5,0-12.6,0-13,0
						c-0.4,0-7.1-0.1-7-5c0-7,0-28.8,0-30c0-0.1,0.9-7.1,7-7c8,0,11.8,0,12,0c1.8,0,6.6-0.2,10.4-4.3c2.2-7.4,4.9-14.5,8.4-20.9
						c0.8-3.7,1.1-8.5-1.8-11.5c-3.5-3.5-8.9-8.9-9.2-9.2c-0.3-0.3-5-5.1-1.4-8.5c4.9-4.9,20.4-20.4,21.2-21.2
						c-0.1,0.1,2.5-1.9,5.5-1.9c1.4,0,2.9,0.5,4.4,1.9c5.7,5.7,8.3,8.3,8.5,8.5c0.1,0.1,3.4,3.9,9.2,4.3c7.5-4.4,15.3-7.5,23.1-9.7
						c3-2.2,5.7-5,5.7-8.6c0-5,0-12.6,0-13c0-0.4,0.1-7.1,5-7c7,0,28.9,0,30,0c0.1,0,7.1,0.9,7,7c0,8,0,11.8,0,12
						c0,0.2-0.3,4.8,3.1,9.2c8.6,2.5,16.6,5.7,23.8,9.8c1.5,0.3,2.1,0.4,2.9,0.3c2.4,0,5-0.5,6.9-2.4c3.5-3.5,8.9-8.9,9.2-9.2
						c0.1-0.1,2.8-2.7,5.6-2.7c0.9,0,1.9,0.3,2.9,1.3c4.9,5,20.4,20.4,21.2,21.2c0.1,0.1,4.4,5.6,0,9.9c-5.7,5.7-8.3,8.3-8.5,8.5
						c-0.1,0.1-3.8,3.3-4.4,9.1c4.2,7.4,7.2,15.1,9.3,22.7c2.2,3.1,5.1,6.2,8.9,6.2C615,323,622.6,323,623,323z M508,425
						c44.7,0,81-36.3,81-81c0-44.7-36.3-81-81-81c-44.7,0-81,36.3-81,81C427,388.7,463.3,425,508,425"/>
				</g>
			</g>
		</g>
	</g>
</g>
<g class="${classes.rotatingCog}">
	<path class="st0" d="M117.6,476l-0.4,0c-0.6-0.1-4-0.5-7.5-2.5c-5.5-3-8.6-8.3-8.6-14.5v-12.4l0,0c0-0.4-0.1-1.6-0.7-2.2
		c-4.9-1.6-9.8-3.5-14.4-5.9l-0.7-0.2c-0.5-0.2-2.4-0.7-4-0.7c-0.2,0-0.3,0-0.4,0l-8.6,8.6c-0.9,0.9-6,5.6-12.7,5.6
		c-3.8,0-7.2-1.5-10-4.2L28,426.1l-0.3-0.3c-0.4-0.5-2.5-3.2-3.6-7c-1.7-6-0.2-12,4.2-16.3l8.8-8.8l0,0c0.3-0.3,0.8-1,1-1.7
		c-2.7-5.2-5-10.7-6.9-16.2c-0.8-1.3-1.6-2.2-2.1-2.7H17c-8.4,0-17-5.2-17-15v-30.4l0-0.4c0.1-0.6,0.5-4,2.5-7.5
		c3-5.5,8.3-8.6,14.5-8.6h12.4l0,0c0.4,0,0.8-0.1,1.2-0.2c2-6.4,4.5-12.6,7.6-18.5c0.1-0.7,0.1-1.2,0-1.5l-8.6-8.6
		c-0.4-0.4-2.7-2.8-4.2-6.2c-2.6-5.9-1.5-12,2.8-16.4L49.9,238l0.3-0.3c0.9-0.7,5.7-4.3,11.9-4.3c4.3,0,8.3,1.7,11.5,4.9l8.8,8.8
		l0,0c0.1,0.1,0.3,0.2,0.5,0.4c6.3-3.4,13-6.1,19.9-8.2c0.1-0.1,0.1-0.2,0.2-0.2V227c0-8.4,5.2-17,15-17h30.4l0.4,0
		c0.6,0.1,4,0.5,7.5,2.5c5.5,3,8.6,8.3,8.6,14.5v12.4l0,0c0,0.1,0,0.2,0,0.3c7,2.2,13.7,5,20.1,8.6c0,0,0,0,0,0l8.6-8.6
		c0.9-0.9,6-5.6,12.7-5.6c3.8,0,7.2,1.5,10,4.2l21.5,21.5l0.3,0.3c0.4,0.5,2.5,3.2,3.6,7c1.7,6,0.2,12-4.2,16.3l-8.8,8.8l0,0
		c-0.1,0.1-0.2,0.3-0.4,0.5c3.3,6.3,5.9,12.9,7.8,19.6c0.2,0.2,0.4,0.4,0.5,0.5H249c8.4,0,17,5.2,17,15v30.4l0,0.4
		c-0.1,0.6-0.5,4-2.5,7.5c-3,5.5-8.3,8.6-14.5,8.6h-12.4l0,0c-0.3,0-0.8,0.1-1.2,0.2c-2,6.1-4.5,12.1-7.6,17.8
		c-0.2,1.1-0.2,1.8-0.2,2.3l8.6,8.6c0.4,0.4,2.7,2.8,4.2,6.2c2.6,5.9,1.5,12-2.8,16.4L216.1,448l-0.3,0.3c-0.9,0.7-5.7,4.3-11.9,4.3
		c-4.3,0-8.3-1.7-11.5-4.9l-8.8-8.8l0,0c-0.4-0.4-1.1-0.8-1.6-1c-5,2.6-10.3,4.8-15.7,6.5c-1.2,0.7-2.7,1.8-3.3,2.5V459
		c0,8.4-5.2,17-15,17H117.6z M134,273c-39.1,0-71,31.9-71,71s31.9,71,71,71s71-31.9,71-71S173.1,273,134,273z"/>
	<g>
		<g>
			<g class="st1">
				<g class="st1">
					<defs>
						<path id="SVGID_00000047770100809824820010000009921693890274699951_" class="st1" d="M56.7,245.4c-0.1-0.1-0.3-0.3-0.4-0.4
							L56.7,245.4C56.7,245.4,56.7,245.4,56.7,245.4z"/>
					</defs>
					<clipPath id="SVGID_00000165933410789771421180000010075834425313187242_">
						<use xlink:href="#SVGID_00000047770100809824820010000009921693890274699951_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000165933410789771421180000010075834425313187242_);enable-background:new    ;">
						<polyline class="st3" points="56.2,245 56.7,245.4 56.7,245.4 56.2,245 56.2,245 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000098920745383119306800000000389635980195086781_" class="st1" d="M10,358H8c0-7,0-28.8,0-30h2
							C10,329.2,10,351,10,358z"/>
					</defs>
					<clipPath id="SVGID_00000022557614063951844970000012465160378151710898_">
						<use xlink:href="#SVGID_00000098920745383119306800000000389635980195086781_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000022557614063951844970000012465160378151710898_);enable-background:new    ;">
						<path class="st5" d="M10,358H8c0-7,0-28.8,0-30h2C10,329.2,10,351,10,358"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000118388024681261229960000017722421581885990079_" class="st1" d="M10,328H8c0-0.5,1-9,9-9l0,2
							C10.9,320.9,10,327.9,10,328z"/>
					</defs>
					<clipPath id="SVGID_00000026872690332118488340000011849616937870410141_">
						<use xlink:href="#SVGID_00000118388024681261229960000017722421581885990079_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000026872690332118488340000011849616937870410141_);enable-background:new    ;">
						<path class="st7" d="M10,328H8c0-0.2,0.2-1.7,0.9-3.5l1.8,0.8C10.2,326.8,10,327.9,10,328"/>
						<path class="st8" d="M10.8,325.4l-1.8-0.8c0.3-0.7,0.7-1.4,1.2-2.1l1.6,1.3C11.3,324.3,11,324.8,10.8,325.4"/>
						<path class="st9" d="M11.7,323.7l-1.6-1.3c0.3-0.5,0.7-0.9,1.1-1.3l1.3,1.5C12.2,323,11.9,323.3,11.7,323.7"/>
						<path class="st10" d="M12.5,322.7l-1.3-1.5c0.3-0.3,0.7-0.6,1.1-0.9l1.1,1.7C13.1,322.2,12.8,322.4,12.5,322.7"/>
						<path class="st11" d="M13.4,322l-1.1-1.7c0.3-0.2,0.7-0.4,1.1-0.6l0.8,1.8C13.9,321.7,13.7,321.8,13.4,322"/>
						<path class="st12" d="M14.2,321.5l-0.8-1.8c0.4-0.2,0.8-0.3,1.2-0.4l0.6,1.9C14.8,321.3,14.5,321.4,14.2,321.5"/>
						<path class="st13" d="M15.1,321.2l-0.6-1.9c0.4-0.1,0.9-0.2,1.4-0.2l0.2,2C15.8,321.1,15.5,321.1,15.1,321.2"/>
						<path class="st14" d="M16.2,321l-0.2-2c0.3,0,0.7-0.1,1-0.1l0,2C16.7,321,16.5,321,16.2,321"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000183953136658969003800000016773089637097131151_" class="st1" d="M29.1,319L29.1,319
							C29.1,319,29,319,29.1,319L29.1,319C29,319,29.1,319,29.1,319z"/>
					</defs>
					<clipPath id="SVGID_00000162311221714924113160000003837053318351220359_">
						<use xlink:href="#SVGID_00000183953136658969003800000016773089637097131151_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000162311221714924113160000003837053318351220359_);enable-background:new    ;">
						<path class="st16" d="M29,319L29,319C29,319,29.1,319,29,319L29,319C29.1,319,29,319,29,319"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000105402285504719129270000015870021022384266659_" class="st1" d="M30,363l0,2c-5,0-12.6,0-13,0
							c-0.4,0-9,0-9-7h2c-0.1,4.9,6.6,5,7,5C17.4,363,25,363,30,363z"/>
					</defs>
					<clipPath id="SVGID_00000039817709712489409160000004294625124705450889_">
						<use xlink:href="#SVGID_00000105402285504719129270000015870021022384266659_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000039817709712489409160000004294625124705450889_);enable-background:new    ;">
						<path class="st7" d="M30,363l0,2c-5,0-12.6,0-13,0c-0.1,0-0.7,0-1.5-0.1l0.3-2c0.6,0.1,1.1,0.1,1.1,0.1
							C17.4,363,25,363,30,363"/>
						<path class="st5" d="M15.9,362.9l-0.3,2c-1.5-0.2-3.9-0.7-5.6-2.2l1.5-1.4C12.8,362.4,14.7,362.8,15.9,362.9"/>
						<path class="st18" d="M11.5,361.3l-1.5,1.4c-0.5-0.5-1-1.1-1.4-1.8l1.8-0.8C10.7,360.5,11,360.9,11.5,361.3"/>
						<path class="st5" d="M10.4,360l-1.8,0.8C8.2,360.1,8,359.1,8,358h2C10,358.8,10.1,359.4,10.4,360"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000183214702640415004440000000233480316432639924_" class="st1" d="M83.9,427.5c0,0.1,0.1,0.2,0.1,0.2
							c0.2,0.2,0.4,0.3,0.6,0.4c-1.9-1.4-4.4-3.4-6.2-4.8C82,426,83.6,427.1,83.9,427.5z"/>
					</defs>
					<clipPath id="SVGID_00000015328111988832072060000016104033017769728184_">
						<use xlink:href="#SVGID_00000183214702640415004440000000233480316432639924_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000015328111988832072060000016104033017769728184_);enable-background:new    ;">
						<path class="st9" d="M84.6,428.1l-6.2-4.8c0,0,0.1,0,0.1,0.1L84.6,428.1C84.6,428.1,84.6,428.1,84.6,428.1"/>
						<path class="st8" d="M84.6,428.1l-6.2-4.8c0.1,0.1,0.2,0.1,0.2,0.2L84.6,428.1C84.7,428.2,84.6,428.2,84.6,428.1"/>
						<path class="st7" d="M84.7,428.2l-6-4.7c0.1,0.1,0.2,0.1,0.2,0.2L84.7,428.2C84.8,428.2,84.7,428.2,84.7,428.2"/>
						<path class="st5" d="M84.8,428.3l-5.9-4.6c0.1,0.1,0.2,0.1,0.2,0.2L84.8,428.3C84.8,428.3,84.8,428.3,84.8,428.3"/>
						<path class="st18" d="M84.8,428.3l-5.7-4.4c0.1,0.1,0.1,0.1,0.2,0.2L84.8,428.3C84.9,428.4,84.9,428.3,84.8,428.3"/>
						<path class="st20" d="M84.9,428.4l-5.6-4.3c0,0,0.1,0.1,0.1,0.1L84.9,428.4C84.9,428.4,84.9,428.4,84.9,428.4"/>
						<path class="st21" d="M85,428.4l-5.5-4.3c0,0,0.1,0.1,0.1,0.1L85,428.4C85,428.4,85,428.4,85,428.4"/>
						<path class="st22" d="M85,428.4l-5.4-4.2c0,0,0.1,0,0.1,0.1L85,428.4C85,428.5,85,428.4,85,428.4"/>
						<path class="st23" d="M85,428.5l-5.3-4.2c0,0,0.1,0,0.1,0.1L85,428.5C85,428.5,85,428.5,85,428.5"/>
						<path class="st24" d="M85,428.5l-5.3-4.1c0,0,0.1,0,0.1,0.1L85,428.5C85.1,428.5,85.1,428.5,85,428.5"/>
						<path class="st25" d="M85.1,428.5l-5.2-4.1c0,0,0.1,0,0.1,0.1L85.1,428.5C85.1,428.5,85.1,428.5,85.1,428.5"/>
						<path class="st26" d="M85.1,428.5l-5.1-4c0,0,0.1,0,0.1,0.1L85.1,428.5C85.1,428.6,85.1,428.5,85.1,428.5"/>
						<path class="st27" d="M85.1,428.6l-5.1-4c0,0,0.1,0,0.1,0.1L85.1,428.6C85.2,428.6,85.1,428.6,85.1,428.6"/>
						<path class="st28" d="M85.2,428.6l-5-3.9c0,0,0.1,0,0.1,0.1L85.2,428.6C85.2,428.6,85.2,428.6,85.2,428.6"/>
						<path class="st29" d="M85.2,428.6l-4.9-3.9c0,0,0.1,0,0.1,0.1L85.2,428.6C85.2,428.6,85.2,428.6,85.2,428.6"/>
						<path class="st30" d="M85.2,428.6l-4.9-3.9c0,0,0.1,0,0.1,0.1L85.2,428.6C85.2,428.6,85.2,428.6,85.2,428.6"/>
						<path class="st31" d="M85.2,428.7l-4.8-3.8c0,0,0.1,0,0.1,0.1L85.2,428.7C85.3,428.7,85.3,428.7,85.2,428.7"/>
						<path class="st32" d="M85.3,428.7l-4.7-3.8c0,0,0.1,0,0.1,0.1L85.3,428.7C85.3,428.7,85.3,428.7,85.3,428.7"/>
						<path class="st33" d="M85.3,428.7l-4.6-3.7c0,0,0.1,0,0.1,0.1L85.3,428.7C85.3,428.7,85.3,428.7,85.3,428.7"/>
						<path class="st34" d="M85.3,428.7l-4.6-3.7c0,0,0.1,0,0.1,0.1L85.3,428.7C85.3,428.7,85.3,428.7,85.3,428.7"/>
						<path class="st16" d="M85.3,428.7l-4.5-3.6c0,0,0.1,0,0.1,0.1L85.3,428.7C85.4,428.8,85.3,428.8,85.3,428.7"/>
						<path class="st35" d="M85.4,428.8l-4.4-3.6c0,0,0.1,0,0.1,0.1L85.4,428.8C85.4,428.8,85.4,428.8,85.4,428.8"/>
						<path class="st3" d="M85.4,428.8l-4.3-3.5c1.8,1.3,2.7,1.9,2.9,2.2l1.3,1.8C85.7,429.3,85.8,429.2,85.4,428.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000065032115461692561830000006188853492568607901_" class="st1" d="M35.4,419.4l-1.4,1.4
							c-0.3-0.4-5.7-7.1,0-12.7l1.4,1.4C31,413.8,35.4,419.3,35.4,419.4z"/>
					</defs>
					<clipPath id="SVGID_00000157274875774019146150000011127146408621518503_">
						<use xlink:href="#SVGID_00000065032115461692561830000006188853492568607901_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000157274875774019146150000011127146408621518503_);enable-background:new    ;">
						<path class="st18" d="M35.4,419.4l-1.4,1.4c-0.1-0.1-1-1.3-1.7-3l1.9-0.7C34.7,418.4,35.4,419.3,35.4,419.4"/>
						<path class="st5" d="M34.1,417.1l-1.9,0.7c-0.5-1.3-1-2.9-0.8-4.7l2,0.3C33.4,414.8,33.7,416,34.1,417.1"/>
						<path class="st7" d="M33.5,413.4l-2-0.3c0.1-0.7,0.2-1.4,0.6-2.2l1.9,0.7C33.7,412.3,33.5,412.8,33.5,413.4"/>
						<path class="st8" d="M33.9,411.7l-1.9-0.7c0.2-0.6,0.5-1.1,0.9-1.6l1.6,1.1C34.3,410.8,34.1,411.3,33.9,411.7"/>
						<path class="st9" d="M34.6,410.4l-1.6-1.1c0.3-0.4,0.7-0.8,1.1-1.2l1.4,1.4C35.1,409.8,34.8,410.1,34.6,410.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000018934143045407828060000013752335170990895747_" class="st1" d="M17,321l0-2c8,0,11.8,0,12,0
							c1.5,0,5.7-0.2,8.6-3.3l1.8,1c-3.8,4-8.6,4.3-10.4,4.3C28.8,321,25,321,17,321z"/>
					</defs>
					<clipPath id="SVGID_00000079455188073319407330000016800097316106571415_">
						<use xlink:href="#SVGID_00000018934143045407828060000013752335170990895747_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000079455188073319407330000016800097316106571415_);enable-background:new    ;">
						<path class="st14" d="M17,321l0-2c8,0,11.8,0,12,0c0.4,0,0.9,0,1.5-0.1l0.2,2C30,321,29.4,321,29,321C28.8,321,25,321,17,321"
							/>
						<path class="st13" d="M30.7,320.9l-0.2-2c0.6-0.1,1.3-0.2,2.1-0.4l0.6,1.9C32.2,320.7,31.4,320.8,30.7,320.9"/>
						<path class="st12" d="M33.1,320.5l-0.6-1.9c0.6-0.1,1.1-0.3,1.7-0.6l0.9,1.7C34.5,320,33.8,320.3,33.1,320.5"/>
						<path class="st11" d="M35.2,319.7l-0.9-1.7c0.5-0.2,1-0.5,1.4-0.8l1.3,1.5C36.4,319.1,35.8,319.4,35.2,319.7"/>
						<path class="st10" d="M37,318.7l-1.3-1.5c0.5-0.3,0.9-0.6,1.3-1l1.6,1.2C38.2,317.9,37.6,318.3,37,318.7"/>
						<path class="st9" d="M38.7,317.4l-1.6-1.2c0.2-0.2,0.3-0.3,0.5-0.5l1.8,1C39.2,316.9,39,317.2,38.7,317.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000113318548304222594310000001808110298735260606_" class="st1" d="M40.6,371.8l-1.9,0.6
							c-1.1-1.9-4.5-7.4-8.7-7.4l0-2C35.4,363.1,39.3,369.4,40.6,371.8z"/>
					</defs>
					<clipPath id="SVGID_00000154412228752545592970000013039122541340983205_">
						<use xlink:href="#SVGID_00000113318548304222594310000001808110298735260606_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000154412228752545592970000013039122541340983205_);enable-background:new    ;">
						<path class="st18" d="M40.6,371.8l-1.9,0.6c-0.8-1.3-2.7-4.5-5.3-6.2l0.9-1.8C37.5,366.4,39.6,370.1,40.6,371.8"/>
						<path class="st5" d="M34.3,364.4l-0.9,1.8c-0.9-0.6-1.9-1-2.9-1.1l0.1-2C32,363.2,33.2,363.7,34.3,364.4"/>
						<path class="st7" d="M30.7,363l-0.1,2c-0.2,0-0.3,0-0.5,0l0-2C30.2,363,30.4,363,30.7,363"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000055697715033435080700000015603467646978446270_" class="st1" d="M35.4,409.5l-1.4-1.4
							c5.7-5.7,8.3-8.3,8.5-8.5l1.4,1.4C43.8,401.1,41.1,403.8,35.4,409.5z"/>
					</defs>
					<clipPath id="SVGID_00000052104972094632665120000000956533392499903893_">
						<use xlink:href="#SVGID_00000055697715033435080700000015603467646978446270_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000052104972094632665120000000956533392499903893_);enable-background:new    ;">
						<path class="st9" d="M35.4,409.5l-1.4-1.4c5.7-5.7,8.3-8.3,8.5-8.5l1.4,1.4C43.8,401.1,41.1,403.8,35.4,409.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000158742278300685906480000008573441290208017796_" class="st1" d="M46,284.3l-1.4,1.4
							c-3.5-3.5-8.9-8.9-9.2-9.2c-0.3-0.3-6.4-6.4-1.4-11.3l1.4,1.4c-3.5,3.4,1.2,8.2,1.4,8.5C37.1,275.4,42.5,280.8,46,284.3z"/>
					</defs>
					<clipPath id="SVGID_00000170963163935772174430000003590898042702834878_">
						<use xlink:href="#SVGID_00000158742278300685906480000008573441290208017796_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000170963163935772174430000003590898042702834878_);enable-background:new    ;">
						<path class="st18" d="M46,284.3l-1.4,1.4c-3.5-3.5-8.9-8.9-9.2-9.2c-0.1-0.1-1.5-1.5-2.5-3.5l1.9-0.7c0.8,1.5,1.9,2.6,2,2.8
							C37.1,275.4,42.5,280.8,46,284.3"/>
						<path class="st5" d="M34.9,272.4l-1.9,0.7c-0.6-1.2-1-2.6-0.8-4l2,0.3C34.1,270.4,34.4,271.4,34.9,272.4"/>
						<path class="st7" d="M34.1,269.3l-2-0.3c0.1-0.5,0.2-1.1,0.4-1.6l1.9,0.8C34.2,268.5,34.1,268.9,34.1,269.3"/>
						<path class="st8" d="M34.4,268.1l-1.9-0.8c0.2-0.4,0.4-0.8,0.7-1.2l1.6,1.1C34.7,267.6,34.5,267.8,34.4,268.1"/>
						<path class="st9" d="M34.9,267.3l-1.6-1.1c0.2-0.3,0.5-0.6,0.8-0.9l1.4,1.4C35.2,266.8,35,267.1,34.9,267.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000126298878766765383430000011508871271302543258_" class="st1" d="M39.4,316.7l-1.8-1
							c2-7.3,4.9-14.3,8.4-20.9l1.8,1C44.3,302.2,41.6,309.3,39.4,316.7z"/>
					</defs>
					<clipPath id="SVGID_00000019645058708632882410000009586967659953683330_">
						<use xlink:href="#SVGID_00000126298878766765383430000011508871271302543258_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000019645058708632882410000009586967659953683330_);enable-background:new    ;">
						<path class="st7" d="M39.4,316.7l-1.8-1c0.9-3.4,2-6.7,3.3-9.9l1.9,0.9C41.5,309.9,40.4,313.3,39.4,316.7"/>
						<path class="st8" d="M42.8,306.7l-1.9-0.9c1.5-3.8,3.2-7.4,5.1-11l1.8,1C45.9,299.3,44.3,302.9,42.8,306.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000092453515447137317820000001701478302458579382_" class="st1" d="M48,389.3l-2,0.4
							c-3-5.5-5.5-11.3-7.4-17.3l1.9-0.6C42.5,377.5,44.9,383.4,48,389.3z"/>
					</defs>
					<clipPath id="SVGID_00000156561958383554113470000002779475126441775540_">
						<use xlink:href="#SVGID_00000092453515447137317820000001701478302458579382_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000156561958383554113470000002779475126441775540_);enable-background:new    ;">
						<path class="st18" d="M48,389.3l-2,0.4c-0.9-1.7-1.8-3.4-2.6-5.2l1.9-0.7C46.2,385.6,47.1,387.5,48,389.3"/>
						<path class="st5" d="M45.3,383.8l-1.9,0.7c-1.8-3.9-3.4-8-4.8-12.2l1.9-0.6C41.9,375.7,43.5,379.8,45.3,383.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000165234626845590528430000003531339958492843163_" class="st1" d="M47.8,295.8l-1.8-1
							c0.5-2.8,0.8-6.9-1.4-9.1l1.4-1.4C49,287.3,48.6,292.1,47.8,295.8z"/>
					</defs>
					<clipPath id="SVGID_00000002353629581473807730000010560327549449765768_">
						<use xlink:href="#SVGID_00000165234626845590528430000003531339958492843163_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000002353629581473807730000010560327549449765768_);enable-background:new    ;">
						<path class="st7" d="M47.8,295.8l-1.8-1c0.1-0.3,0.1-0.7,0.2-1l1.9,0.7C48,295,47.9,295.4,47.8,295.8"/>
						<path class="st5" d="M48.1,294.5l-1.9-0.7c0.3-2,0.3-4.3-0.4-6.2l1.8-0.8C48.6,289.2,48.5,292,48.1,294.5"/>
						<path class="st18" d="M47.6,286.8l-1.8,0.8c-0.3-0.7-0.7-1.4-1.2-1.9l1.4-1.4C46.7,285,47.3,285.9,47.6,286.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000146483452332835919830000010772263765643460736_" class="st1" d="M43.9,401l-1.4-1.4
							c0.2-0.2,4.7-4.2,3.6-9.9l2-0.4C49.4,396.2,44.1,400.8,43.9,401z"/>
					</defs>
					<clipPath id="SVGID_00000153695370368584035640000004862044524030440852_">
						<use xlink:href="#SVGID_00000146483452332835919830000010772263765643460736_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000153695370368584035640000004862044524030440852_);enable-background:new    ;">
						<path class="st10" d="M43.9,401l-1.4-1.4c0,0,0.1-0.1,0.1-0.1l1.4,1.4C44,400.9,43.9,401,43.9,401"/>
						<path class="st9" d="M44.1,400.8l-1.4-1.4c0.3-0.3,0.9-0.8,1.5-1.7l1.6,1.1C45,399.9,44.4,400.6,44.1,400.8"/>
						<path class="st8" d="M45.8,398.9l-1.6-1.1c0.5-0.6,0.9-1.4,1.3-2.3l1.9,0.7C46.9,397.2,46.3,398.1,45.8,398.9"/>
						<path class="st7" d="M47.3,396.1l-1.9-0.7c0.4-0.8,0.6-1.8,0.7-2.8l2,0.2C48.1,394,47.7,395.1,47.3,396.1"/>
						<path class="st5" d="M48.2,392.7l-2-0.2c0.1-0.9,0.1-1.8-0.1-2.8l2-0.4C48.3,390.5,48.3,391.7,48.2,392.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000042701054723109401830000015712667647653508539_" class="st1" d="M56.6,440.6l-1.4,1.4
							c-4.9-4.9-20.4-20.4-21.2-21.2l1.4-1.4C36.2,420.2,51.7,435.6,56.6,440.6z"/>
					</defs>
					<clipPath id="SVGID_00000098205344720288801260000017295852159872089261_">
						<use xlink:href="#SVGID_00000042701054723109401830000015712667647653508539_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000098205344720288801260000017295852159872089261_);enable-background:new    ;">
						<path class="st18" d="M56.6,440.6l-1.4,1.4c-4.9-4.9-20.4-20.4-21.2-21.2l1.4-1.4C36.2,420.2,51.7,435.6,56.6,440.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000165197868524308286780000017498710674926121105_" class="st1" d="M56.2,245l0.4,0.4c0,0,0,0,0,0
							C56.5,245.3,56.4,245.1,56.2,245z"/>
					</defs>
					<clipPath id="SVGID_00000010290707510968722740000005735018231736124289_">
						<use xlink:href="#SVGID_00000165197868524308286780000017498710674926121105_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000010290707510968722740000005735018231736124289_);enable-background:new    ;">
						<polyline class="st10" points="56.6,245.4 56.2,245 56.2,245 56.6,245.4 56.6,245.4 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000036218089228236373790000009336216915682908094_" class="st1" d="M35.4,266.6l-1.4-1.4
							c4.9-4.9,20.4-20.4,21.2-21.2l1.4,1.4C55.8,246.2,40.4,261.7,35.4,266.6z"/>
					</defs>
					<clipPath id="SVGID_00000060015846662000017660000016748585758003557506_">
						<use xlink:href="#SVGID_00000036218089228236373790000009336216915682908094_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000060015846662000017660000016748585758003557506_);enable-background:new    ;">
						<path class="st9" d="M35.4,266.6l-1.4-1.4c4.9-4.9,20.4-20.4,21.2-21.2l1.4,1.4C55.8,246.2,40.4,261.7,35.4,266.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000124125912081616637530000012590056111069118387_" class="st1" d="M66.5,440.6L66.5,440.6
							c-0.2,0.2-3.3,3.3-7,3.3h0C63.2,443.9,66.4,440.7,66.5,440.6z"/>
					</defs>
					<clipPath id="SVGID_00000098189094478025008910000003201240287744190117_">
						<use xlink:href="#SVGID_00000124125912081616637530000012590056111069118387_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000098189094478025008910000003201240287744190117_);enable-background:new    ;">
						<path class="st3" d="M66.5,440.6L66.5,440.6c-0.2,0.2-3.3,3.3-7,3.3h0C63.2,443.9,66.4,440.7,66.5,440.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000062171024239270103300000016637569843109943478_" class="st1" d="M62.1,241.5L62.1,241.5
							c1.9,0,3.9,0.6,5.8,2.5l0,0C66.1,242.1,64,241.5,62.1,241.5z"/>
					</defs>
					<clipPath id="SVGID_00000040549519678526959880000001231837636907759007_">
						<use xlink:href="#SVGID_00000062171024239270103300000016637569843109943478_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000040549519678526959880000001231837636907759007_);enable-background:new    ;">
						<path class="st16" d="M62.1,241.5L62.1,241.5c0.1,0,0.2,0,0.3,0h0C62.3,241.5,62.2,241.5,62.1,241.5"/>
						<path class="st34" d="M62.4,241.5L62.4,241.5c0.4,0,0.8,0.1,1.2,0.1h0C63.2,241.5,62.8,241.5,62.4,241.5"/>
						<path class="st33" d="M63.6,241.6L63.6,241.6c0.4,0.1,0.8,0.2,1.1,0.3h0C64.3,241.8,63.9,241.7,63.6,241.6"/>
						<path class="st32" d="M64.7,241.9L64.7,241.9c0.4,0.1,0.9,0.3,1.3,0.6h0C65.5,242.2,65.1,242.1,64.7,241.9"/>
						<path class="st31" d="M66,242.5L66,242.5c0.7,0.4,1.3,0.9,2,1.5h0C67.3,243.4,66.6,242.9,66,242.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000085929399801509188280000014712220020336402594_" class="st1" d="M56.6,245.4c-0.1-0.1-0.3-0.3-0.4-0.4
							l-1-1c0.2-0.1,3.1-2.5,6.9-2.5c1.9,0,3.9,0.6,5.8,2.5l-1.4,1.4c-1.5-1.5-3-1.9-4.4-1.9C59.1,243.5,56.6,245.4,56.6,245.4z"/>
					</defs>
					<clipPath id="SVGID_00000066496080741314727660000009388837912720799417_">
						<use xlink:href="#SVGID_00000085929399801509188280000014712220020336402594_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000066496080741314727660000009388837912720799417_);enable-background:new    ;">
						<path class="st10" d="M56.6,245.4l-1.4-1.4c0,0,0.3-0.2,0.7-0.5l1.2,1.6C56.8,245.3,56.6,245.4,56.6,245.4"/>
						<path class="st11" d="M57.1,245.1l-1.2-1.6c0.5-0.3,1.1-0.7,1.9-1.1l0.8,1.8C58,244.6,57.5,244.9,57.1,245.1"/>
						<path class="st12" d="M58.7,244.3l-0.8-1.8c0.5-0.2,1.1-0.5,1.8-0.6l0.5,1.9C59.6,243.9,59.1,244.1,58.7,244.3"/>
						<path class="st13" d="M60.1,243.8l-0.5-1.9c0.5-0.1,1-0.2,1.6-0.3l0.2,2C61,243.6,60.5,243.7,60.1,243.8"/>
						<path class="st14" d="M61.4,243.5l-0.2-2c0.3,0,0.6,0,0.9,0c0.2,0,0.4,0,0.6,0l-0.1,2c-0.1,0-0.3,0-0.4,0
							C61.9,243.5,61.6,243.5,61.4,243.5"/>
						<path class="st51" d="M62.6,243.5l0.1-2c0.4,0,0.9,0.1,1.4,0.2l-0.5,1.9C63.2,243.6,62.9,243.5,62.6,243.5"/>
						<path class="st52" d="M63.6,243.7l0.5-1.9c0.5,0.1,1,0.3,1.6,0.6l-0.9,1.8C64.4,243.9,64,243.8,63.6,243.7"/>
						<path class="st53" d="M64.8,244.1l0.9-1.8c0.8,0.4,1.6,0.9,2.3,1.7l-1.4,1.4C65.9,244.8,65.3,244.4,64.8,244.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000102517759267036341820000017553736368261624469_" class="st1" d="M75.7,431.4L75.7,431.4
							c-3.5,3.5-8.9,8.9-9.2,9.2h0C66.8,440.3,72.2,434.9,75.7,431.4z"/>
					</defs>
					<clipPath id="SVGID_00000070115341181010003930000005949829675999319720_">
						<use xlink:href="#SVGID_00000102517759267036341820000017553736368261624469_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000070115341181010003930000005949829675999319720_);enable-background:new    ;">
						<path class="st3" d="M75.7,431.4L75.7,431.4c-3.5,3.5-8.9,8.9-9.2,9.2h0C66.8,440.3,72.2,434.9,75.7,431.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000168795256520932900750000007469298588560580031_" class="st1" d="M68,244L68,244c5.7,5.7,8.4,8.3,8.5,8.5
							l0,0C76.3,252.4,73.6,249.7,68,244z"/>
					</defs>
					<clipPath id="SVGID_00000005260058915155599730000000279404778563322037_">
						<use xlink:href="#SVGID_00000168795256520932900750000007469298588560580031_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000005260058915155599730000000279404778563322037_);enable-background:new    ;">
						<path class="st31" d="M68,244L68,244c5.7,5.7,8.4,8.3,8.5,8.5h0C76.3,252.4,73.6,249.7,68,244"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000026860619009134684060000000838894824661152652_" class="st1" d="M81.2,429.6L81.2,429.6
							c-2,0-4.1,0.4-5.5,1.8h0C77.1,430,79.2,429.6,81.2,429.6z"/>
					</defs>
					<clipPath id="SVGID_00000169521551251872737000000009183602130189887909_">
						<use xlink:href="#SVGID_00000026860619009134684060000000838894824661152652_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000169521551251872737000000009183602130189887909_);enable-background:new    ;">
						<path class="st3" d="M81.2,429.6L81.2,429.6c-2,0-4.1,0.4-5.5,1.8h0C77.1,430,79.2,429.6,81.2,429.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000129916423126331615060000017622963662659602600_" class="st1" d="M74.3,430l1.4,1.4
							c-3.5,3.5-8.9,8.9-9.2,9.2c-0.2,0.2-3.3,3.3-7,3.3c-1.4,0-2.9-0.5-4.3-1.9l1.4-1.4c1,1,2,1.3,2.9,1.3c2.8,0,5.5-2.6,5.6-2.7
							C65.4,438.9,70.8,433.5,74.3,430z"/>
					</defs>
					<clipPath id="SVGID_00000034783795411734139070000010886417351739855748_">
						<use xlink:href="#SVGID_00000129916423126331615060000017622963662659602600_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000034783795411734139070000010886417351739855748_);enable-background:new    ;">
						<path class="st11" d="M74.3,430l1.4,1.4c-3.5,3.5-8.9,8.9-9.2,9.2c0,0,0,0-0.1,0.1l-1.4-1.4c0,0,0,0,0.1-0.1
							C65.4,438.9,70.8,433.5,74.3,430"/>
						<path class="st10" d="M65.1,439.2l1.4,1.4c-0.2,0.2-0.9,0.8-1.8,1.5l-1.1-1.7C64.4,439.9,64.9,439.4,65.1,439.2"/>
						<path class="st9" d="M63.6,440.4l1.1,1.7c-0.6,0.4-1.3,0.8-2,1.1l-0.7-1.9C62.6,441.1,63.1,440.7,63.6,440.4"/>
						<path class="st8" d="M62,441.3l0.7,1.9c-0.6,0.2-1.2,0.4-1.9,0.6l-0.3-2C61,441.7,61.5,441.5,62,441.3"/>
						<path class="st7" d="M60.5,441.8l0.3,2c-0.4,0.1-0.8,0.1-1.2,0.1c-0.2,0-0.4,0-0.7,0l0.2-2c0.1,0,0.3,0,0.4,0
							C59.9,441.9,60.2,441.9,60.5,441.8"/>
						<path class="st5" d="M59.1,441.9l-0.2,2c-0.9-0.1-1.8-0.4-2.7-1.1l1.1-1.6C57.9,441.6,58.5,441.8,59.1,441.9"/>
						<path class="st18" d="M57.3,441.1l-1.1,1.6c-0.3-0.2-0.6-0.5-0.9-0.8l1.4-1.4C56.9,440.8,57.1,441,57.3,441.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000045611244794661270510000014002086342700055947_" class="st1" d="M76.4,252.5L76.4,252.5
							c0.2,0.2,3,3.4,7.3,3.7h0C79.4,255.8,76.6,252.7,76.4,252.5z"/>
					</defs>
					<clipPath id="SVGID_00000019652573401377620990000017622691174469670562_">
						<use xlink:href="#SVGID_00000045611244794661270510000014002086342700055947_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000019652573401377620990000017622691174469670562_);enable-background:new    ;">
						<path class="st31" d="M76.4,252.5L76.4,252.5c0.1,0.1,1.2,1.3,3,2.4h0C77.6,253.8,76.5,252.6,76.4,252.5"/>
						<path class="st32" d="M79.4,254.9L79.4,254.9c0.6,0.3,1.2,0.6,1.9,0.8h0C80.6,255.5,80,255.2,79.4,254.9"/>
						<path class="st33" d="M81.3,255.7L81.3,255.7c0.5,0.2,1,0.3,1.5,0.4h0C82.3,256,81.8,255.9,81.3,255.7"/>
						<path class="st34" d="M82.8,256.1L82.8,256.1c0.3,0.1,0.6,0.1,0.9,0.1h0C83.4,256.2,83.1,256.1,82.8,256.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000150781110722488055360000006457447804948420783_" class="st1" d="M66.5,245.4L68,244
							c5.7,5.7,8.3,8.3,8.5,8.5l-1.4,1.4C74.9,253.8,72.2,251.1,66.5,245.4z"/>
					</defs>
					<clipPath id="SVGID_00000003788206930382098360000001278442654884481947_">
						<use xlink:href="#SVGID_00000150781110722488055360000006457447804948420783_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000003788206930382098360000001278442654884481947_);enable-background:new    ;">
						<path class="st53" d="M66.5,245.4L68,244c5.7,5.7,8.3,8.3,8.5,8.5l-1.4,1.4C74.9,253.8,72.2,251.1,66.5,245.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000173858826046530453400000011562913966287808958_" class="st1" d="M87.3,430.4L87.3,430.4
							C87.4,430.4,87.4,430.3,87.3,430.4L87.3,430.4C87.4,430.3,87.4,430.4,87.3,430.4z"/>
					</defs>
					<clipPath id="SVGID_00000090252873936343363900000017119430070514755215_">
						<use xlink:href="#SVGID_00000173858826046530453400000011562913966287808958_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000090252873936343363900000017119430070514755215_);enable-background:new    ;">
						<path class="st31" d="M87.4,430.3L87.4,430.3C87.4,430.3,87.4,430.3,87.4,430.3L87.4,430.3C87.4,430.3,87.4,430.3,87.4,430.3"
							/>
						<path class="st32" d="M87.4,430.3L87.4,430.3C87.4,430.3,87.4,430.3,87.4,430.3L87.4,430.3C87.4,430.3,87.4,430.3,87.4,430.3"
							/>
						<path class="st33" d="M87.4,430.3L87.4,430.3C87.4,430.3,87.4,430.3,87.4,430.3L87.4,430.3C87.4,430.3,87.4,430.3,87.4,430.3"
							/>
						<polyline class="st34" points="87.4,430.3 87.4,430.3 87.4,430.3 87.4,430.3 87.4,430.3 						"/>
						<polyline class="st16" points="87.4,430.3 87.4,430.3 87.4,430.3 87.4,430.3 87.4,430.3 						"/>
						<polyline class="st35" points="87.4,430.3 87.4,430.3 87.4,430.4 87.4,430.4 87.4,430.3 						"/>
						<path class="st3" d="M87.4,430.4L87.4,430.4C87.4,430.4,87.4,430.4,87.4,430.4L87.4,430.4C87.3,430.4,87.4,430.4,87.4,430.4"
							/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000107548494886574074670000011659681671349631152_" class="st1" d="M104.8,435.3l-1,1.8
							c-5.7-1.8-11.2-4.1-16.5-6.8l-9.1-7C93.4,431.2,99,433.4,104.8,435.3z"/>
					</defs>
					<clipPath id="SVGID_00000058548201686346081340000008553150912042215048_">
						<use xlink:href="#SVGID_00000107548494886574074670000011659681671349631152_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000058548201686346081340000008553150912042215048_);enable-background:new    ;">
						<path class="st5" d="M104.8,435.3l-1,1.8c-5.7-1.8-11.2-4.1-16.5-6.8l-9.1-7C93.4,431.2,99,433.4,104.8,435.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000020383927593585757470000001592811809927500214_" class="st1" d="M83.9,427.5c0,0,0,0.1,0.1,0.1
							c0,0,0,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c1.1,0.8,2.1,1.6,3.2,2.4c0.4,0.4-2.8-0.7-6.1-0.7c-2.1,0-4.1,0.4-5.5,1.8l-1.4-1.4
							c1.9-1.9,4.5-2.4,6.9-2.4C83.1,427.6,84.2,427.9,83.9,427.5z"/>
					</defs>
					<clipPath id="SVGID_00000025404365795608434430000005690112658691016357_">
						<use xlink:href="#SVGID_00000020383927593585757470000001592811809927500214_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000025404365795608434430000005690112658691016357_);enable-background:new    ;">
						<path class="st3" d="M78.3,423.3l9.1,7c0,0,0,0,0,0.1l-7.9-6.1C79.2,423.9,78.8,423.6,78.3,423.3"/>
						<path class="st35" d="M79.5,424.2l7.9,6.1c0,0,0,0,0,0l-7.7-6C79.7,424.3,79.6,424.2,79.5,424.2"/>
						<path class="st16" d="M79.7,424.3l7.7,6c0,0,0,0,0,0l-7.5-5.9C79.9,424.4,79.8,424.4,79.7,424.3"/>
						<path class="st34" d="M79.9,424.5l7.5,5.9c0,0,0,0,0,0l-7.3-5.7C80.1,424.6,80,424.5,79.9,424.5"/>
						<path class="st33" d="M80.1,424.6l7.3,5.7c0,0,0,0,0,0l-7.1-5.6C80.2,424.7,80.2,424.7,80.1,424.6"/>
						<path class="st32" d="M80.3,424.7l7.1,5.6c0,0,0,0,0,0L80.3,424.7C80.3,424.8,80.3,424.8,80.3,424.7"/>
						<path class="st31" d="M80.4,424.8l7,5.6c0,0,0,0,0,0L80.4,424.8C80.4,424.8,80.4,424.8,80.4,424.8"/>
						<path class="st30" d="M80.4,424.8l6.9,5.5c0,0,0,0,0,0L80.4,424.8C80.5,424.9,80.5,424.9,80.4,424.8"/>
						<path class="st29" d="M80.5,424.9l6.9,5.5c0,0,0,0,0,0L80.5,424.9C80.5,424.9,80.5,424.9,80.5,424.9"/>
						<path class="st28" d="M80.6,424.9l6.8,5.4c0,0,0,0,0,0L80.6,424.9C80.6,425,80.6,424.9,80.6,424.9"/>
						<path class="st27" d="M80.6,425l6.7,5.4c0,0,0,0,0,0L80.6,425C80.7,425,80.6,425,80.6,425"/>
						<path class="st26" d="M80.7,425l6.7,5.3c0,0,0,0,0,0L80.7,425C80.7,425.1,80.7,425,80.7,425"/>
						<path class="st25" d="M80.7,425.1l6.6,5.3c0,0,0,0,0,0L80.7,425.1C80.8,425.1,80.8,425.1,80.7,425.1"/>
						<path class="st24" d="M80.8,425.1l6.5,5.3c0,0,0,0,0,0L80.8,425.1C80.9,425.1,80.8,425.1,80.8,425.1"/>
						<path class="st23" d="M80.9,425.2l6.5,5.2c0,0,0,0,0,0L80.9,425.2C80.9,425.2,80.9,425.2,80.9,425.2"/>
						<path class="st22" d="M80.9,425.2l6.4,5.2c0,0,0,0,0,0L80.9,425.2C81,425.2,81,425.2,80.9,425.2"/>
						<path class="st21" d="M81,425.2l6.3,5.1c0,0,0,0,0,0L81,425.2C81,425.3,81,425.3,81,425.2"/>
						<path class="st20" d="M81,425.3l6.3,5.1c0,0,0,0,0,0L81,425.3C81.1,425.3,81.1,425.3,81,425.3"/>
						<path class="st18" d="M81.1,425.3l6.2,5c0,0,0,0,0,0L81.1,425.3C81.1,425.4,81.1,425.3,81.1,425.3"/>
						<path class="st5" d="M81.2,425.4l6.1,5c0,0,0,0,0,0L81.2,425.4C81.2,425.4,81.2,425.4,81.2,425.4"/>
						<path class="st7" d="M81.2,425.4l6.1,5c0,0,0,0,0,0L81.2,425.4C81.3,425.4,81.2,425.4,81.2,425.4"/>
						<path class="st8" d="M81.3,425.5l6,4.9c0,0,0,0,0,0L81.3,425.5C81.3,425.5,81.3,425.5,81.3,425.5"/>
						<path class="st9" d="M81.3,425.5l5.9,4.9c0,0,0,0,0,0L81.3,425.5C81.4,425.5,81.4,425.5,81.3,425.5"/>
						<path class="st10" d="M81.4,425.5l5.9,4.8c0,0,0,0,0,0L81.4,425.5C81.4,425.6,81.4,425.6,81.4,425.5"/>
						<path class="st11" d="M81.5,425.6l5.8,4.8c0,0,0,0,0,0L81.5,425.6C81.5,425.6,81.5,425.6,81.5,425.6"/>
						<path class="st12" d="M81.5,425.6l5.7,4.7c0,0,0,0,0,0L81.5,425.6C81.6,425.7,81.5,425.6,81.5,425.6"/>
						<path class="st13" d="M81.6,425.7l5.7,4.7c0,0,0,0,0,0L81.6,425.7C81.6,425.7,81.6,425.7,81.6,425.7"/>
						<path class="st14" d="M81.6,425.7l5.6,4.7c0,0,0,0,0,0L81.6,425.7C81.7,425.7,81.7,425.7,81.6,425.7"/>
						<path class="st51" d="M81.7,425.7l5.5,4.6c0,0,0,0,0,0L81.7,425.7C81.7,425.8,81.7,425.8,81.7,425.7"/>
						<path class="st52" d="M81.7,425.8l5.5,4.6c0,0,0,0,0,0L81.7,425.8C81.8,425.8,81.8,425.8,81.7,425.8"/>
						<path class="st53" d="M81.8,425.8l5.4,4.5c0,0,0,0,0,0L81.8,425.8C81.8,425.8,81.8,425.8,81.8,425.8"/>
						<path class="st63" d="M81.8,425.9l5.4,4.5c0,0,0,0,0,0L81.8,425.9C81.9,425.9,81.9,425.9,81.8,425.9"/>
						<path class="st64" d="M81.9,425.9l5.3,4.5c0,0,0,0,0,0L81.9,425.9C81.9,425.9,81.9,425.9,81.9,425.9"/>
						<path class="st65" d="M81.9,425.9l5.3,4.5c0,0,0,0,0,0L81.9,425.9C81.9,425.9,81.9,425.9,81.9,425.9"/>
						<path class="st66" d="M82,425.9l5.2,4.4c0,0,0,0,0,0L82,425.9C82,426,82,425.9,82,425.9"/>
						<path class="st67" d="M82,426l5.2,4.4c0,0,0,0,0,0L82,426C82,426,82,426,82,426"/>
						<path class="st68" d="M82,426l5.1,4.4c0,0,0,0,0,0L82,426C82.1,426,82,426,82,426"/>
						<path class="st69" d="M82.1,426l5.1,4.3c0,0,0,0,0,0L82.1,426C82.1,426,82.1,426,82.1,426"/>
						<path class="st70" d="M82.1,426l5,4.3c0,0,0,0,0,0L82.1,426C82.1,426.1,82.1,426.1,82.1,426"/>
						<path class="st71" d="M82.1,426.1l5,4.3c0,0,0,0,0,0L82.1,426.1C82.2,426.1,82.1,426.1,82.1,426.1"/>
						<path class="st72" d="M82.2,426.1l4.9,4.3c0,0,0,0,0,0L82.2,426.1C82.2,426.1,82.2,426.1,82.2,426.1"/>
						<path class="st73" d="M82.2,426.1l4.9,4.2c0,0,0,0,0,0L82.2,426.1C82.2,426.1,82.2,426.1,82.2,426.1"/>
						<path class="st74" d="M82.2,426.1l4.8,4.2c0,0,0,0,0,0L82.2,426.1C82.3,426.2,82.3,426.2,82.2,426.1"/>
						<path class="st75" d="M82.3,426.2l4.8,4.2c0,0,0,0,0,0L82.3,426.2C82.3,426.2,82.3,426.2,82.3,426.2"/>
						<path class="st76" d="M82.3,426.2l4.8,4.1c0,0,0,0,0,0L82.3,426.2C82.3,426.2,82.3,426.2,82.3,426.2"/>
						<path class="st77" d="M82.3,426.2l4.7,4.1c0,0,0,0,0,0L82.3,426.2C82.4,426.2,82.4,426.2,82.3,426.2"/>
						<path class="st78" d="M82.4,426.2l4.7,4.1c0,0,0,0,0,0L82.4,426.2C82.4,426.3,82.4,426.3,82.4,426.2"/>
						<path class="st77" d="M82.4,426.3l4.6,4.1c0,0,0,0,0,0L82.4,426.3C82.5,426.3,82.5,426.3,82.4,426.3"/>
						<path class="st76" d="M82.5,426.3l4.5,4c0,0,0,0,0,0L82.5,426.3C82.6,426.4,82.5,426.4,82.5,426.3"/>
						<path class="st75" d="M82.6,426.4l4.4,3.9c0,0,0,0,0,0L82.6,426.4C82.6,426.4,82.6,426.4,82.6,426.4"/>
						<path class="st74" d="M82.6,426.4l4.3,3.9c0,0,0,0,0,0L82.6,426.4C82.7,426.5,82.7,426.5,82.6,426.4"/>
						<path class="st73" d="M82.7,426.5l4.2,3.8c0,0,0,0,0,0L82.7,426.5C82.8,426.5,82.7,426.5,82.7,426.5"/>
						<path class="st72" d="M82.8,426.5l4.1,3.8c0,0,0,0,0,0L82.8,426.5C82.8,426.6,82.8,426.6,82.8,426.5"/>
						<path class="st71" d="M82.8,426.6l4,3.7c0,0,0,0,0,0L82.8,426.6C82.9,426.6,82.9,426.6,82.8,426.6"/>
						<path class="st70" d="M82.9,426.6l3.9,3.7c0,0,0,0,0,0L82.9,426.6C82.9,426.7,82.9,426.6,82.9,426.6"/>
						<path class="st69" d="M83,426.7l3.9,3.6c0,0,0,0,0,0L83,426.7C83,426.7,83,426.7,83,426.7"/>
						<path class="st68" d="M83,426.7l3.8,3.6c0,0,0,0,0,0L83,426.7C83,426.7,83,426.7,83,426.7"/>
						<path class="st67" d="M83.1,426.7l3.7,3.6c0,0,0,0,0,0L83.1,426.7C83.1,426.8,83.1,426.8,83.1,426.7"/>
						<path class="st66" d="M83.1,426.8l3.6,3.5c0,0,0,0,0,0L83.1,426.8C83.1,426.8,83.1,426.8,83.1,426.8"/>
						<path class="st65" d="M83.1,426.8l3.5,3.5c0,0,0,0,0,0L83.1,426.8C83.2,426.8,83.2,426.8,83.1,426.8"/>
						<path class="st64" d="M83.2,426.9l3.5,3.4c0,0,0,0,0,0L83.2,426.9C83.2,426.9,83.2,426.9,83.2,426.9"/>
						<path class="st63" d="M83.2,426.9l3.4,3.4c0,0,0,0,0,0L83.2,426.9C83.3,426.9,83.2,426.9,83.2,426.9"/>
						<path class="st53" d="M83.3,426.9l3.3,3.4c0,0,0,0-0.1,0L83.3,426.9C83.3,427,83.3,426.9,83.3,426.9"/>
						<path class="st52" d="M83.3,427l3.2,3.3c0,0,0,0-0.1,0L83.3,427C83.4,427,83.4,427,83.3,427"/>
						<path class="st51" d="M83.4,427l3.1,3.2c0,0,0,0-0.1,0L83.4,427C83.4,427.1,83.4,427,83.4,427"/>
						<path class="st14" d="M83.5,427.1l3,3.2c0,0,0,0-0.1,0L83.5,427.1C83.5,427.1,83.5,427.1,83.5,427.1"/>
						<path class="st13" d="M83.5,427.1l2.9,3.1c0,0,0,0-0.1,0L83.5,427.1C83.6,427.2,83.5,427.1,83.5,427.1"/>
						<path class="st12" d="M83.6,427.2l2.7,3c0,0-0.1,0-0.1,0L83.6,427.2C83.6,427.2,83.6,427.2,83.6,427.2"/>
						<path class="st11" d="M83.7,427.2l2.5,2.9c0,0-0.1,0-0.1,0L83.7,427.2C83.7,427.3,83.7,427.3,83.7,427.2"/>
						<path class="st10" d="M83.7,427.3l2.3,2.9c-0.1,0-0.1,0-0.2,0L83.7,427.3C83.8,427.4,83.8,427.3,83.7,427.3"/>
						<path class="st9" d="M83.8,427.4l2.1,2.7c-0.1,0-0.2,0-0.2,0L83.8,427.4C83.9,427.5,83.9,427.4,83.8,427.4"/>
						<path class="st8" d="M83.9,427.5l1.8,2.6c-0.2,0-0.3-0.1-0.5-0.1l-1.3-2.4C83.9,427.6,83.9,427.5,83.9,427.5"/>
						<path class="st7" d="M83.9,427.6l1.3,2.4c-1.1-0.2-2.5-0.4-4-0.4c-0.7,0-1.4,0-2.1,0.2l-0.4-2c0.8-0.2,1.7-0.2,2.5-0.2
							C82.8,427.6,83.9,427.8,83.9,427.6"/>
						<path class="st8" d="M78.7,427.8l0.4,2c-0.6,0.1-1.1,0.3-1.7,0.5l-0.8-1.8C77.3,428.2,78,428,78.7,427.8"/>
						<path class="st9" d="M76.6,428.4l0.8,1.8c-0.4,0.1-0.7,0.3-1,0.5l-1.2-1.6C75.7,428.9,76.2,428.6,76.6,428.4"/>
						<path class="st10" d="M75.3,429.1l1.2,1.6c-0.2,0.2-0.5,0.3-0.7,0.6l-1.4-1.4C74.7,429.6,75,429.4,75.3,429.1"/>
						<path class="st11" d="M74.4,429.9l1.4,1.4c0,0,0,0,0,0L74.4,429.9C74.3,429.9,74.4,429.9,74.4,429.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000084500227143409713990000007260143481397618569_" class="st1" d="M75,253.9l1.4-1.4
							c0.1,0.2,3,3.4,7.3,3.7l0.5,2C78.4,257.8,75.1,254,75,253.9z"/>
					</defs>
					<clipPath id="SVGID_00000173130107076041561230000001574326767893611182_">
						<use xlink:href="#SVGID_00000084500227143409713990000007260143481397618569_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000173130107076041561230000001574326767893611182_);enable-background:new    ;">
						<path class="st53" d="M75,253.9l1.4-1.4c0.1,0.1,1.5,1.7,3.9,2.8l-0.6,1.9C76.8,255.9,75.1,254,75,253.9"/>
						<path class="st52" d="M79.8,257.2l0.6-1.9c0.6,0.3,1.3,0.5,2,0.7l0,2C81.4,257.8,80.6,257.5,79.8,257.2"/>
						<path class="st51" d="M82.4,258l0-2c0.4,0.1,0.9,0.2,1.4,0.2l0.5,2C83.6,258.2,83,258.1,82.4,258"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000180334482453938738050000000426952987641727107_" class="st1" d="M84.2,258.3l-0.5-2
							c7.1-4.1,14.8-7.4,23-9.6l0.5,2C99.5,250.8,91.7,253.9,84.2,258.3z"/>
					</defs>
					<clipPath id="SVGID_00000183953315687702616700000005669450770786161302_">
						<use xlink:href="#SVGID_00000180334482453938738050000000426952987641727107_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000183953315687702616700000005669450770786161302_);enable-background:new    ;">
						<path class="st11" d="M84.2,258.3l-0.5-2c3.1-1.8,6.3-3.4,9.6-4.8l0.8,1.9C90.8,254.7,87.5,256.4,84.2,258.3"/>
						<path class="st12" d="M94.1,253.3l-0.8-1.9c4.3-1.9,8.8-3.5,13.4-4.8l0.5,2C102.9,249.8,98.4,251.4,94.1,253.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000060727609332341792480000016186900851270637955_" class="st1" d="M111,459h-2c0-8,0-11.8,0-12h2
							C111,447.2,111,451,111,459z"/>
					</defs>
					<clipPath id="SVGID_00000166641712711864189340000006679063589249874066_">
						<use xlink:href="#SVGID_00000060727609332341792480000016186900851270637955_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000166641712711864189340000006679063589249874066_);enable-background:new    ;">
						<path class="st5" d="M111,459h-2c0-8,0-11.8,0-12h2C111,447.2,111,451,111,459"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000154414594082685909070000004127053950020904585_" class="st1" d="M111,447h-2c0-0.3,0.4-6.9-5.2-9.9
							l1-1.8C111.5,439,111,446.7,111,447z"/>
					</defs>
					<clipPath id="SVGID_00000093862234760467325760000008032089594922901939_">
						<use xlink:href="#SVGID_00000154414594082685909070000004127053950020904585_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000093862234760467325760000008032089594922901939_);enable-background:new    ;">
						<path class="st5" d="M111,447h-2c0-0.2,0.2-2.7-0.9-5.3l1.8-0.9C111.2,443.9,111,446.8,111,447"/>
						<path class="st18" d="M109.9,440.9l-1.8,0.9c-0.6-1.4-1.6-2.8-3.1-4l1.2-1.6C108,437.5,109.2,439.2,109.9,440.9"/>
						<path class="st5" d="M106.1,436.2l-1.2,1.6c-0.3-0.2-0.7-0.5-1.1-0.7l1-1.8C105.3,435.6,105.7,435.8,106.1,436.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000004539289167995286680000011469609622148037253_" class="st1" d="M107.3,248.6l-0.5-2
							c2.1-1.6,4.3-4,4.3-6.6h2C113,243.6,110.3,246.3,107.3,248.6z"/>
					</defs>
					<clipPath id="SVGID_00000087405754667288588550000003417706699912964274_">
						<use xlink:href="#SVGID_00000004539289167995286680000011469609622148037253_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000087405754667288588550000003417706699912964274_);enable-background:new    ;">
						<path class="st10" d="M107.3,248.6l-0.5-2c0.4-0.4,0.9-0.7,1.3-1.1l1.1,1.6C108.5,247.6,107.9,248.1,107.3,248.6"/>
						<path class="st9" d="M109.1,247l-1.1-1.6c0.6-0.5,1.1-1.1,1.6-1.8l1.6,1.1C110.6,245.6,109.9,246.3,109.1,247"/>
						<path class="st8" d="M111.2,244.8l-1.6-1.1c0.4-0.5,0.7-1,0.9-1.5l1.9,0.7C112.1,243.5,111.7,244.2,111.2,244.8"/>
						<path class="st7" d="M112.4,242.8l-1.9-0.7c0.2-0.5,0.4-1,0.4-1.6l2,0.2C112.9,241.4,112.7,242.1,112.4,242.8"/>
						<path class="st5" d="M113,240.7l-2-0.2c0-0.2,0-0.4,0-0.5h2C113,240.2,113,240.5,113,240.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000005950539103663006540000007098937068327054212_" class="st1" d="M154.8,437.8c1.7-0.1,3.7-0.3,7.7-0.8
							c-3,0.3-6.9,0.8-9.9,1.1C153.6,437.9,154.3,437.8,154.8,437.8z"/>
					</defs>
					<clipPath id="SVGID_00000167387674127454071300000017685704013356418956_">
						<use xlink:href="#SVGID_00000005950539103663006540000007098937068327054212_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000167387674127454071300000017685704013356418956_);enable-background:new    ;">
						<path class="st14" d="M162.6,437l-9.9,1.1c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st13" d="M162.6,437l-9.9,1.1c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st12" d="M162.6,437l-9.8,1.1c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st11" d="M162.6,437l-9.8,1.1c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st10" d="M162.6,437l-9.7,1c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st9" d="M162.6,437l-9.7,1c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st8" d="M162.6,437l-9.7,1c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st7" d="M162.6,437l-9.6,1c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st5" d="M162.6,437l-9.6,1c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st18" d="M162.6,437l-9.5,1c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st20" d="M162.6,437l-9.5,1c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st21" d="M162.6,437l-9.4,1c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st22" d="M162.6,437l-9.4,1c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st23" d="M162.6,437l-9.3,1c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st24" d="M162.6,437l-9.3,1c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st25" d="M162.6,437l-9.2,1c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st26" d="M162.6,437l-9.2,0.9c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st27" d="M162.6,437l-9.2,0.9c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st28" d="M162.6,437l-9.1,0.9c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st29" d="M162.6,437l-9.1,0.9c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st30" d="M162.6,437l-9,0.9c0,0,0,0,0,0L162.6,437C162.6,437,162.6,437,162.6,437"/>
						<path class="st31" d="M162.6,437l-9,0.9c0,0,0,0,0,0L162.6,437C162.6,437.1,162.6,437,162.6,437"/>
						<path class="st32" d="M162.5,437.1l-8.9,0.9c0,0,0,0,0,0L162.5,437.1C162.5,437.1,162.5,437.1,162.5,437.1"/>
						<path class="st33" d="M162.5,437.1l-8.9,0.9c0,0,0.1,0,0.1,0L162.5,437.1C162.5,437.1,162.5,437.1,162.5,437.1"/>
						<path class="st34" d="M162.5,437.1l-8.8,0.9c0.1,0,0.1,0,0.2,0L162.5,437.1C162.5,437.1,162.5,437.1,162.5,437.1"/>
						<path class="st16" d="M162.5,437.1l-8.5,0.8c0.1,0,0.1,0,0.2,0L162.5,437.1C162.5,437.1,162.5,437.1,162.5,437.1"/>
						<path class="st35" d="M162.5,437.1l-8.3,0.8c0.1,0,0.1,0,0.2,0L162.5,437.1C162.5,437.1,162.5,437.1,162.5,437.1"/>
						<path class="st3" d="M162.5,437.1l-8.1,0.7c0.2,0,0.3,0,0.5-0.1l7.5-0.6C162.4,437.2,162.4,437.1,162.5,437.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000104708051203704206130000013301903831233098908_" class="st1" d="M118,466l0,2c-0.4,0-9-1-9-9h2
							C110.9,465.1,117.9,466,118,466z"/>
					</defs>
					<clipPath id="SVGID_00000048469281715976658710000015506576073300611768_">
						<use xlink:href="#SVGID_00000104708051203704206130000013301903831233098908_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000048469281715976658710000015506576073300611768_);enable-background:new    ;">
						<path class="st5" d="M118,466l0,2c-0.3,0-3.4-0.4-5.9-2.4l1.3-1.5C115.4,465.7,117.9,466,118,466"/>
						<path class="st18" d="M113.4,464.1l-1.3,1.5c-0.9-0.7-1.7-1.6-2.3-2.9l1.8-0.9C112,462.8,112.7,463.6,113.4,464.1"/>
						<path class="st5" d="M111.6,461.9l-1.8,0.9c-0.5-1-0.8-2.3-0.8-3.8h2C111,460.1,111.2,461.1,111.6,461.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000069393205812581832880000003797953054872095125_" class="st1" d="M113,240h-2c0-5,0-12.6,0-13
							c0-0.4,0-9,7-9l0,2c-4.9-0.1-5,6.6-5,7C113,227.4,113,235,113,240z"/>
					</defs>
					<clipPath id="SVGID_00000091006933183659835850000011559343114241929611_">
						<use xlink:href="#SVGID_00000069393205812581832880000003797953054872095125_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000091006933183659835850000011559343114241929611_);enable-background:new    ;">
						<path class="st5" d="M113,240h-2c0-5,0-12.6,0-13c0-0.1,0-0.6,0.1-1.3l2,0.3c-0.1,0.5-0.1,0.9-0.1,1
							C113,227.4,113,235,113,240"/>
						<path class="st7" d="M113.1,226l-2-0.3c0.1-0.9,0.3-2.3,0.9-3.6l1.8,1C113.3,224.2,113.1,225.2,113.1,226"/>
						<path class="st8" d="M113.7,223.2l-1.8-1c0.2-0.6,0.5-1.2,0.9-1.7l1.5,1.4C114.1,222.3,113.9,222.7,113.7,223.2"/>
						<path class="st9" d="M114.4,221.9l-1.5-1.4c0.2-0.3,0.5-0.6,0.8-0.9l1.3,1.6C114.8,221.4,114.6,221.6,114.4,221.9"/>
						<path class="st10" d="M115,221.1l-1.3-1.6c0.2-0.2,0.5-0.4,0.8-0.6l1,1.7C115.3,220.8,115.2,221,115,221.1"/>
						<path class="st11" d="M115.6,220.7l-1-1.7c0.2-0.2,0.5-0.3,0.8-0.4l0.8,1.8C115.9,220.4,115.7,220.6,115.6,220.7"/>
						<path class="st12" d="M116.1,220.4l-0.8-1.8c0.3-0.1,0.6-0.2,0.9-0.3l0.5,1.9C116.5,220.2,116.3,220.3,116.1,220.4"/>
						<path class="st13" d="M116.7,220.1l-0.5-1.9c0.3-0.1,0.6-0.1,1-0.2l0.2,2C117.2,220,116.9,220.1,116.7,220.1"/>
						<path class="st14" d="M117.4,220l-0.2-2c0.3,0,0.5,0,0.8,0l0,2C117.8,220,117.6,220,117.4,220"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000090989777202390556310000005904614188877159069_" class="st1" d="M134,265L134,265c-43.6,0-79,35.4-79,79
							c0,43.6,35.4,79,79,79l0,0c-43.6,0-79-35.4-79-79C55,300.4,90.4,265,134,265z"/>
					</defs>
					<clipPath id="SVGID_00000037662213745606893440000011243179917325448099_">
						<use xlink:href="#SVGID_00000090989777202390556310000005904614188877159069_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000037662213745606893440000011243179917325448099_);enable-background:new    ;">
						<path class="st3" d="M134,265L134,265c-30,0-56,16.7-69.4,41.3h0C78,281.7,104,265,134,265"/>
						<path class="st35" d="M64.6,306.3L64.6,306.3c-2.2,4.1-4.1,8.4-5.6,12.9h0C60.5,314.7,62.3,310.4,64.6,306.3"/>
						<path class="st16" d="M59,319.1L59,319.1c-1.2,3.7-2.2,7.5-2.8,11.5h0C56.8,326.7,57.8,322.9,59,319.1"/>
						<path class="st34" d="M56.1,330.6L56.1,330.6c-0.6,3.7-1,7.6-1.1,11.4h0C55.1,338.2,55.5,334.3,56.1,330.6"/>
						<path class="st33" d="M55,342.1L55,342.1c0,0.6,0,1.3,0,1.9c0,3.5,0.2,6.9,0.7,10.2h0c-0.4-3.3-0.7-6.8-0.7-10.2
							C55,343.4,55,342.7,55,342.1"/>
						<path class="st32" d="M55.7,354.2L55.7,354.2c0.6,4.8,1.7,9.4,3.1,13.8h0C57.3,363.6,56.3,359,55.7,354.2"/>
						<path class="st31" d="M58.7,368.1L58.7,368.1c6.1,19.1,19.3,35.1,36.6,44.8h0C78.1,403.2,64.9,387.2,58.7,368.1"/>
						<path class="st32" d="M95.3,412.9L95.3,412.9c4.1,2.3,8.5,4.3,13,5.8h0C103.8,417.2,99.4,415.2,95.3,412.9"/>
						<path class="st33" d="M108.3,418.7L108.3,418.7c3.7,1.3,7.6,2.3,11.5,3h0C115.9,421,112,420,108.3,418.7"/>
						<path class="st34" d="M119.8,421.7L119.8,421.7c3.7,0.7,7.6,1.1,11.4,1.2h0C127.4,422.8,123.6,422.4,119.8,421.7"/>
						<path class="st16" d="M131.3,423L131.3,423c0.9,0,1.8,0,2.7,0h0C133.1,423,132.2,423,131.3,423"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000091724124424218976290000011346395250431037338_" class="st1" d="M155,459L155,459c0,0.5,0,9-7,9l0,0
							C155,468,155,459.4,155,459z"/>
					</defs>
					<clipPath id="SVGID_00000166647944695958461910000016112029803516215429_">
						<use xlink:href="#SVGID_00000091724124424218976290000011346395250431037338_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000166647944695958461910000016112029803516215429_);enable-background:new    ;">
						<path class="st33" d="M155,459L155,459c0,0,0,0.1,0,0.2h0C155,459.1,155,459,155,459"/>
						<path class="st34" d="M155,459.2L155,459.2c0,0.4,0,1.1-0.2,2h0C154.9,460.3,155,459.6,155,459.2"/>
						<path class="st16" d="M154.8,461.2L154.8,461.2c-0.1,0.6-0.2,1.2-0.4,1.9h0C154.6,462.4,154.7,461.8,154.8,461.2"/>
						<path class="st35" d="M154.3,463.1L154.3,463.1c-0.2,0.5-0.4,1.1-0.7,1.6h0C153.9,464.1,154.2,463.6,154.3,463.1"/>
						<path class="st3" d="M153.7,464.6L153.7,464.6c-1,1.8-2.7,3.4-5.7,3.4h0C151,468,152.7,466.5,153.7,464.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000067930172591109834840000004814008399358256282_" class="st1" d="M155,446L155,446c0,5,0,12.6,0,13l0,0
							C155,458.6,155,451,155,446z"/>
					</defs>
					<clipPath id="SVGID_00000012474590431123060080000014648474012010839941_">
						<use xlink:href="#SVGID_00000067930172591109834840000004814008399358256282_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000012474590431123060080000014648474012010839941_);enable-background:new    ;">
						<path class="st33" d="M155,446L155,446c0,5,0,12.6,0,13h0C155,458.6,155,451,155,446"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000073702746594910929060000011200497340957919647_" class="st1" d="M157,227L157,227c0,8,0,11.8,0,12l0,0
							C157,238.8,157,235,157,227z"/>
					</defs>
					<clipPath id="SVGID_00000141442096842481344770000002670053329682526139_">
						<use xlink:href="#SVGID_00000073702746594910929060000011200497340957919647_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000141442096842481344770000002670053329682526139_);enable-background:new    ;">
						<path class="st33" d="M157,227L157,227c0,8,0,11.8,0,12h0C157,238.8,157,235,157,227"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000161602905722241108690000016649433940334958745_" class="st1" d="M148,218L148,218c0.5,0.1,9,1,9,9h0
							C157,219,148.4,218,148,218z"/>
					</defs>
					<clipPath id="SVGID_00000157266572517251309080000008539250859092230581_">
						<use xlink:href="#SVGID_00000161602905722241108690000016649433940334958745_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000157266572517251309080000008539250859092230581_);enable-background:new    ;">
						<path class="st34" d="M148,218L148,218c0.1,0,0.3,0,0.7,0.1h0C148.3,218,148.1,218,148,218"/>
						<path class="st33" d="M148.6,218.1L148.6,218.1c0.5,0.1,1.2,0.2,2,0.5h0C149.8,218.3,149.1,218.2,148.6,218.1"/>
						<path class="st32" d="M150.6,218.6L150.6,218.6c0.6,0.2,1.3,0.5,2,0.9h0C151.9,219.1,151.3,218.8,150.6,218.6"/>
						<path class="st31" d="M152.6,219.5L152.6,219.5c1.6,0.9,3.2,2.4,4,4.6h0C155.8,221.8,154.2,220.4,152.6,219.5"/>
						<path class="st32" d="M156.6,224.1L156.6,224.1c0.2,0.5,0.3,1,0.4,1.5h0C156.8,225.1,156.7,224.6,156.6,224.1"/>
						<path class="st33" d="M156.9,225.7L156.9,225.7c0.1,0.4,0.1,0.9,0.1,1.3h0C157,226.5,157,226.1,156.9,225.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000035518676352349870850000004136292104984363925_" class="st1" d="M148,466l0,2c-7,0-28.9,0-30,0l0-2
							C119.1,466,141,466,148,466z"/>
					</defs>
					<clipPath id="SVGID_00000018205766589681201040000002591448984629110692_">
						<use xlink:href="#SVGID_00000035518676352349870850000004136292104984363925_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000018205766589681201040000002591448984629110692_);enable-background:new    ;">
						<path class="st7" d="M148,466l0,2c-7,0-28.9,0-30,0l0-2C119.1,466,141,466,148,466"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000068657375365070190400000013169340158456642476_" class="st1" d="M118,220l0-2c7,0,28.9,0,30,0l0,2
							C146.8,220,125,220,118,220z"/>
					</defs>
					<clipPath id="SVGID_00000126306218382766192110000011187347419774424210_">
						<use xlink:href="#SVGID_00000068657375365070190400000013169340158456642476_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000126306218382766192110000011187347419774424210_);enable-background:new    ;">
						<path class="st14" d="M118,220l0-2c7,0,28.9,0,30,0l0,2C146.8,220,125,220,118,220"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000057142588296960537140000007982047945655174815_" class="st1" d="M157,239L157,239c0,0.2-0.2,4.2,2.3,7.5
							l0,0C156.7,243.2,157,239.2,157,239z"/>
					</defs>
					<clipPath id="SVGID_00000064330775017968838760000009235168295413751695_">
						<use xlink:href="#SVGID_00000057142588296960537140000007982047945655174815_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000064330775017968838760000009235168295413751695_);enable-background:new    ;">
						<path class="st34" d="M157,239L157,239c0,0,0,0.1,0,0.2h0C157,239.1,157,239,157,239"/>
						<path class="st33" d="M157,239.2L157,239.2c0,0.3,0,0.9,0.1,1.7h0C157,240.2,157,239.5,157,239.2"/>
						<path class="st32" d="M157.1,241L157.1,241c0.1,0.6,0.2,1.4,0.5,2.2h0C157.3,242.4,157.2,241.6,157.1,241"/>
						<path class="st31" d="M157.6,243.2L157.6,243.2c0.3,1.1,0.9,2.2,1.7,3.3h0C158.4,245.4,157.9,244.2,157.6,243.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000155115482836063000570000005612070318328206256_" class="st1" d="M153,446h2c0,5,0,12.6,0,13
							c0,0.5,0,9-7,9l0-2c4.9,0.1,5-6.6,5-7C153,458.6,153,451,153,446z"/>
					</defs>
					<clipPath id="SVGID_00000088104840640196363240000016686147538492766111_">
						<use xlink:href="#SVGID_00000155115482836063000570000005612070318328206256_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000088104840640196363240000016686147538492766111_);enable-background:new    ;">
						<path class="st51" d="M153,446h2c0,5,0,12.6,0,13c0,0.1,0,1-0.2,2.1l-2-0.4c0.1-0.9,0.1-1.5,0.1-1.6
							C153,458.6,153,451,153,446"/>
						<path class="st14" d="M152.8,460.6l2,0.4c-0.1,0.6-0.3,1.4-0.5,2.1l-1.8-0.9C152.6,461.7,152.8,461.1,152.8,460.6"/>
						<path class="st13" d="M152.5,462.3l1.8,0.9c-0.2,0.5-0.4,1-0.6,1.4l-1.7-1.2C152.2,463,152.3,462.6,152.5,462.3"/>
						<path class="st12" d="M152,463.4l1.7,1.2c-0.2,0.4-0.4,0.7-0.7,1.1l-1.5-1.4C151.7,464,151.9,463.7,152,463.4"/>
						<path class="st11" d="M151.5,464.2l1.5,1.4c-0.2,0.3-0.4,0.5-0.7,0.8l-1.3-1.6C151.2,464.6,151.4,464.4,151.5,464.2"/>
						<path class="st10" d="M151,464.8l1.3,1.6c-0.2,0.2-0.5,0.4-0.8,0.6l-1.1-1.7C150.7,465.2,150.9,465,151,464.8"/>
						<path class="st9" d="M150.5,465.3l1.1,1.7c-0.3,0.2-0.6,0.4-0.9,0.5l-0.8-1.9C150,465.6,150.3,465.4,150.5,465.3"/>
						<path class="st8" d="M149.8,465.7l0.8,1.9c-0.4,0.2-0.8,0.3-1.3,0.4l-0.4-2C149.2,465.9,149.5,465.8,149.8,465.7"/>
						<path class="st7" d="M148.9,465.9l0.4,2c-0.4,0.1-0.9,0.1-1.3,0.1l0-2C148.3,466,148.6,466,148.9,465.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000181767276934234611540000000961664427429063846_" class="st1" d="M162.9,437L162.9,437c0.1,0-7.9,4-7.9,9
							h0C155,441,163,437,162.9,437z"/>
					</defs>
					<clipPath id="SVGID_00000126313618791156922650000002841637025868079748_">
						<use xlink:href="#SVGID_00000181767276934234611540000000961664427429063846_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000126313618791156922650000002841637025868079748_);enable-background:new    ;">
						<polyline class="st34" points="162.9,437 162.9,437 162.9,437 162.9,437 162.9,437 						"/>
						<polyline class="st16" points="162.9,437 162.9,437 162.9,437 162.9,437 162.9,437 						"/>
						<path class="st35" d="M162.9,437L162.9,437C162.9,437,162.9,437,162.9,437L162.9,437C162.9,437,162.9,437,162.9,437"/>
						<path class="st3" d="M162.9,437L162.9,437c-0.3,0.2-5.2,2.8-7.1,6.3h0C157.7,439.8,162.5,437.2,162.9,437"/>
						<path class="st35" d="M155.8,443.3L155.8,443.3c-0.2,0.4-0.3,0.7-0.5,1.1h0C155.4,444,155.6,443.6,155.8,443.3"/>
						<path class="st16" d="M155.3,444.4L155.3,444.4c-0.1,0.3-0.1,0.5-0.2,0.8h0C155.1,444.9,155.2,444.6,155.3,444.4"/>
						<path class="st34" d="M155.1,445.2L155.1,445.2c0,0.2-0.1,0.5-0.1,0.7h0C155,445.6,155,445.4,155.1,445.2"/>
						<path class="st33" d="M155,445.9L155,445.9c0,0,0,0.1,0,0.1h0C155,446,155,445.9,155,445.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000164502702586574956930000016475158147912222628_" class="st1" d="M155,227h2c0,8,0,11.8,0,12h-2
							C155,238.8,155,235,155,227z"/>
					</defs>
					<clipPath id="SVGID_00000170960067274570042130000018263587017229304463_">
						<use xlink:href="#SVGID_00000164502702586574956930000016475158147912222628_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000170960067274570042130000018263587017229304463_);enable-background:new    ;">
						<path class="st51" d="M155,227h2c0,8,0,11.8,0,12h-2C155,238.8,155,235,155,227"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000153690784082176105930000017628247960711825332_" class="st1" d="M148,220l0-2c0.4,0.1,9,1,9,9h-2
							C155.1,220.9,148.1,220,148,220z"/>
					</defs>
					<clipPath id="SVGID_00000144316300402925679410000018215569776584182409_">
						<use xlink:href="#SVGID_00000153690784082176105930000017628247960711825332_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000144316300402925679410000018215569776584182409_);enable-background:new    ;">
						<path class="st51" d="M148,220l0-2c0.1,0,0.6,0.1,1.4,0.2l-0.4,2C148.4,220.1,148,220,148,220"/>
						<path class="st52" d="M149,220.2l0.4-2c0.8,0.2,1.8,0.5,2.9,1.1l-1,1.7C150.4,220.6,149.6,220.3,149,220.2"/>
						<path class="st53" d="M151.2,221l1-1.7c1.9,1,3.8,2.6,4.5,5.4l-1.9,0.5C154.3,223.1,152.7,221.8,151.2,221"/>
						<path class="st52" d="M154.8,225.2l1.9-0.5c0.2,0.6,0.2,1.3,0.3,2l-2,0.1C155,226.2,154.9,225.7,154.8,225.2"/>
						<path class="st51" d="M155,226.8l2-0.1c0,0.1,0,0.2,0,0.3h-2C155,226.9,155,226.9,155,226.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000134240237089871307180000008946755269856875187_" class="st1" d="M209.4,440.6c0.1,0.1,0.3,0.3,0.4,0.4
							L209.4,440.6C209.4,440.6,209.4,440.6,209.4,440.6z"/>
					</defs>
					<clipPath id="SVGID_00000027569582780631012100000000434850909708548227_">
						<use xlink:href="#SVGID_00000134240237089871307180000008946755269856875187_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000027569582780631012100000000434850909708548227_);enable-background:new    ;">
						<polyline class="st3" points="209.8,441 209.4,440.6 209.4,440.6 209.8,441 209.8,441 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000124122670224048785360000015734077078046020013_" class="st1" d="M155,239h2c0,0.2-0.3,4.2,2.3,7.5
							l-1.2,1.8C154.7,243.8,155,239.2,155,239z"/>
					</defs>
					<clipPath id="SVGID_00000134941675827489586440000000880839300611232171_">
						<use xlink:href="#SVGID_00000124122670224048785360000015734077078046020013_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000134941675827489586440000000880839300611232171_);enable-background:new    ;">
						<path class="st51" d="M155,239h2c0,0,0,0.2,0,0.5l-2,0.1C155,239.2,155,239,155,239"/>
						<path class="st52" d="M155,239.6l2-0.1c0,0.5,0,1.3,0.2,2.2l-1.9,0.5C155.1,241.1,155,240.2,155,239.6"/>
						<path class="st53" d="M155.3,242.2l1.9-0.5c0.3,1.4,0.8,3.1,2,4.7l-1.2,1.8C156.5,246.1,155.7,243.9,155.3,242.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000179627782051746086430000009639634966750800533_" class="st1" d="M154.8,437.8c2.1-0.2,4.3-0.4,8.1-0.8
							c0.1,0-7.9,4-7.9,9h-2C153.1,440.4,158.5,437.5,154.8,437.8z"/>
					</defs>
					<clipPath id="SVGID_00000075121307172338202300000014585840186604012726_">
						<use xlink:href="#SVGID_00000179627782051746086430000009639634966750800533_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000075121307172338202300000014585840186604012726_);enable-background:new    ;">
						<path class="st3" d="M152.6,438.1l10.3-1.1c0,0,0,0,0,0l-9.2,0.9C153.4,438,153,438,152.6,438.1"/>
						<path class="st35" d="M153.7,437.9l9.2-0.9c0,0,0,0,0,0L153.7,437.9C153.7,437.9,153.7,437.9,153.7,437.9"/>
						<path class="st16" d="M153.7,437.9l9.1-0.9c0,0,0,0,0,0L153.7,437.9C153.7,437.9,153.7,437.9,153.7,437.9"/>
						<path class="st34" d="M153.7,437.9l9.1-0.9c0,0,0,0,0,0L153.7,437.9C153.7,437.9,153.7,437.9,153.7,437.9"/>
						<path class="st33" d="M153.8,437.9l9.1-0.9c0,0,0,0,0,0L153.8,437.9C153.8,437.9,153.8,437.9,153.8,437.9"/>
						<path class="st32" d="M153.8,437.9l9-0.9c0,0,0,0,0,0L153.8,437.9C153.8,437.9,153.8,437.9,153.8,437.9"/>
						<path class="st31" d="M153.8,437.9l9-0.9c0,0,0,0,0,0L153.8,437.9C153.8,437.9,153.8,437.9,153.8,437.9"/>
						<path class="st30" d="M153.8,437.9l9-0.9c0,0,0,0,0,0L153.8,437.9C153.9,437.9,153.9,437.9,153.8,437.9"/>
						<path class="st29" d="M153.9,437.9l8.9-0.9c0,0,0,0,0,0L153.9,437.9C153.9,437.9,153.9,437.9,153.9,437.9"/>
						<path class="st28" d="M153.9,437.9l8.9-0.9c0,0,0,0,0,0L153.9,437.9C153.9,437.9,153.9,437.9,153.9,437.9"/>
						<path class="st27" d="M153.9,437.9l8.9-0.8c0,0,0,0,0,0L153.9,437.9C154,437.9,153.9,437.9,153.9,437.9"/>
						<path class="st26" d="M154,437.9l8.8-0.8c0,0,0,0,0,0L154,437.9C154,437.9,154,437.9,154,437.9"/>
						<path class="st25" d="M154,437.9l8.8-0.8c0,0,0,0,0,0L154,437.9C154,437.9,154,437.9,154,437.9"/>
						<path class="st24" d="M154,437.9l8.8-0.8c0,0,0,0,0,0L154,437.9C154,437.9,154,437.9,154,437.9"/>
						<path class="st23" d="M154.1,437.9l8.7-0.8c0,0,0,0,0,0L154.1,437.9C154.1,437.9,154.1,437.9,154.1,437.9"/>
						<path class="st22" d="M154.1,437.9l8.7-0.8c0,0,0,0,0,0L154.1,437.9C154.1,437.9,154.1,437.9,154.1,437.9"/>
						<path class="st21" d="M154.1,437.9l8.7-0.8c0,0,0,0,0,0L154.1,437.9C154.1,437.9,154.1,437.9,154.1,437.9"/>
						<path class="st20" d="M154.1,437.9l8.6-0.8c0,0,0,0,0,0L154.1,437.9C154.2,437.9,154.1,437.9,154.1,437.9"/>
						<path class="st18" d="M154.2,437.9l8.6-0.8c0,0,0,0,0,0L154.2,437.9C154.2,437.9,154.2,437.9,154.2,437.9"/>
						<path class="st5" d="M154.2,437.9l8.6-0.8c0,0,0,0,0,0L154.2,437.9C154.2,437.9,154.2,437.9,154.2,437.9"/>
						<path class="st7" d="M154.2,437.9l8.5-0.8c0,0,0,0,0,0L154.2,437.9C154.2,437.8,154.2,437.9,154.2,437.9"/>
						<path class="st8" d="M154.2,437.8l8.5-0.8c0,0,0,0,0,0L154.2,437.8C154.3,437.8,154.3,437.8,154.2,437.8"/>
						<path class="st9" d="M154.3,437.8l8.5-0.8c0,0,0,0,0,0L154.3,437.8C154.3,437.8,154.3,437.8,154.3,437.8"/>
						<path class="st10" d="M154.3,437.8l8.4-0.8c0,0,0,0,0,0L154.3,437.8C154.3,437.8,154.3,437.8,154.3,437.8"/>
						<path class="st11" d="M154.3,437.8l8.4-0.7c0,0,0,0,0,0L154.3,437.8C154.3,437.8,154.3,437.8,154.3,437.8"/>
						<path class="st12" d="M154.3,437.8l8.4-0.7c0,0,0,0,0,0L154.3,437.8C154.4,437.8,154.4,437.8,154.3,437.8"/>
						<path class="st13" d="M154.4,437.8l8.3-0.7c0,0,0,0,0,0L154.4,437.8C154.4,437.8,154.4,437.8,154.4,437.8"/>
						<path class="st14" d="M154.4,437.8l8.3-0.7c0,0,0,0,0,0L154.4,437.8C154.4,437.8,154.4,437.8,154.4,437.8"/>
						<path class="st51" d="M154.4,437.8l8.3-0.7c0,0,0,0,0,0L154.4,437.8C154.4,437.8,154.4,437.8,154.4,437.8"/>
						<path class="st52" d="M154.4,437.8l8.2-0.7c0,0,0,0-0.1,0l-7.9,0.6C154.6,437.8,154.5,437.8,154.4,437.8"/>
						<path class="st51" d="M154.7,437.8l7.9-0.6c0,0-0.1,0-0.1,0l-7.6,0.6C154.8,437.8,154.8,437.8,154.7,437.8"/>
						<path class="st14" d="M154.9,437.8l7.6-0.6c0,0-0.1,0-0.1,0.1l-7.4,0.5C155,437.8,155,437.8,154.9,437.8"/>
						<path class="st13" d="M155.1,437.8l7.4-0.5c-0.1,0-0.1,0.1-0.2,0.1l-6.8,0.4C155.3,437.8,155.2,437.8,155.1,437.8"/>
						<path class="st12" d="M155.4,437.8l6.8-0.4c-0.1,0.1-0.3,0.2-0.5,0.3l-5.9,0.2C155.7,437.8,155.6,437.8,155.4,437.8"/>
						<path class="st11" d="M155.8,437.8l5.9-0.2c-1.2,0.8-3.3,2.1-4.8,3.9l-2.4-0.7C155.6,439.1,156.6,438.1,155.8,437.8"/>
						<path class="st12" d="M154.6,440.9l2.4,0.7c-0.4,0.5-0.8,1-1.1,1.5l-2.1-0.6C154,441.9,154.3,441.4,154.6,440.9"/>
						<path class="st13" d="M153.8,442.5l2.1,0.6c-0.2,0.4-0.4,0.7-0.5,1.1l-2-0.4C153.5,443.3,153.6,442.8,153.8,442.5"/>
						<path class="st14" d="M153.3,443.7l2,0.4c-0.1,0.3-0.2,0.7-0.3,1l-2-0.2C153.1,444.5,153.2,444.1,153.3,443.7"/>
						<path class="st51" d="M153.1,444.9l2,0.2c-0.1,0.3-0.1,0.6-0.1,0.9h-2C153,445.6,153,445.2,153.1,444.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000069374842514356731390000001674661332039039924_" class="st1" d="M179.8,429.9L179.8,429.9
							c-5.4,2.9-11,5.2-16.9,7.1h0C168.8,435.1,174.4,432.8,179.8,429.9z"/>
					</defs>
					<clipPath id="SVGID_00000074416209812938996740000017041164251293348499_">
						<use xlink:href="#SVGID_00000069374842514356731390000001674661332039039924_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000074416209812938996740000017041164251293348499_);enable-background:new    ;">
						<path class="st3" d="M179.8,429.9L179.8,429.9c-5.4,2.9-11,5.2-16.9,7.1h0C168.8,435.1,174.4,432.8,179.8,429.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000078012226811565838550000010304081863164633754_" class="st1" d="M181.5,429.8L181.5,429.8
							c-0.5,0-1.1,0-1.7,0.2h0C180.4,429.8,180.9,429.8,181.5,429.8z"/>
					</defs>
					<clipPath id="SVGID_00000039127495899767701930000009987038208874635167_">
						<use xlink:href="#SVGID_00000078012226811565838550000010304081863164633754_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000039127495899767701930000009987038208874635167_);enable-background:new    ;">
						<path class="st3" d="M181.5,429.8L181.5,429.8c-0.5,0-1.1,0-1.7,0.2h0C180.4,429.8,180.9,429.8,181.5,429.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000158751547428113213140000018303936920716599213_" class="st1" d="M159.3,246.5L159.3,246.5
							c8.4,2.3,16.3,5.6,23.6,9.8h0C175.5,252,167.6,248.7,159.3,246.5z"/>
					</defs>
					<clipPath id="SVGID_00000163775376050253565090000006328685098542306449_">
						<use xlink:href="#SVGID_00000158751547428113213140000018303936920716599213_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000163775376050253565090000006328685098542306449_);enable-background:new    ;">
						<path class="st33" d="M159.3,246.5L159.3,246.5c2.1,0.6,4.2,1.2,6.3,1.9h0C163.5,247.7,161.4,247,159.3,246.5"/>
						<path class="st32" d="M165.5,248.4L165.5,248.4c5.7,1.9,11.1,4.4,16.2,7.3h0C176.6,252.7,171.2,250.3,165.5,248.4"/>
						<path class="st31" d="M181.7,255.6L181.7,255.6c0.4,0.2,0.8,0.4,1.2,0.6h0C182.5,256.1,182.1,255.8,181.7,255.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000030485682023573390570000016762545515873466007_" class="st1" d="M182.9,256.3L182.9,256.3
							c0.6,0.1,1.3,0.1,1.9,0.1h0C184.1,256.4,183.5,256.3,182.9,256.3z"/>
					</defs>
					<clipPath id="SVGID_00000102533955594373949950000009338652053542374326_">
						<use xlink:href="#SVGID_00000030485682023573390570000016762545515873466007_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000102533955594373949950000009338652053542374326_);enable-background:new    ;">
						<path class="st34" d="M182.9,256.3L182.9,256.3c0.5,0,0.9,0.1,1.4,0.1h0C183.8,256.4,183.3,256.3,182.9,256.3"/>
						<path class="st16" d="M184.2,256.4L184.2,256.4c0.2,0,0.4,0,0.6,0h0C184.6,256.4,184.4,256.4,184.2,256.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000089535825800098296230000000263666576580973491_" class="st1" d="M179.4,427.9l0.4,2
							c-5.4,2.9-11,5.2-16.9,7.1l-10.3,1.1C167.9,433.2,173.6,430.9,179.4,427.9z"/>
					</defs>
					<clipPath id="SVGID_00000073694787767966916630000003833188318884511909_">
						<use xlink:href="#SVGID_00000089535825800098296230000000263666576580973491_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000073694787767966916630000003833188318884511909_);enable-background:new    ;">
						<path class="st9" d="M179.4,427.9l0.4,2c-3.1,1.7-6.3,3.1-9.6,4.5l-2.6-1.3C172.7,431.2,176.1,429.7,179.4,427.9"/>
						<path class="st8" d="M167.6,433.1l2.6,1.3c-2.4,1-4.8,1.8-7.3,2.6l-10.3,1.1C159.1,436,163.8,434.4,167.6,433.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000043424594106242509990000016288919272548144056_" class="st1" d="M158.1,248.2l1.2-1.8
							c8.4,2.3,16.3,5.6,23.6,9.8l-1,1.8C174.7,253.9,166.7,250.7,158.1,248.2z"/>
					</defs>
					<clipPath id="SVGID_00000128455337056518316780000016750675817598836894_">
						<use xlink:href="#SVGID_00000043424594106242509990000016288919272548144056_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000128455337056518316780000016750675817598836894_);enable-background:new    ;">
						<path class="st52" d="M158.1,248.2l1.2-1.8c7.5,2,14.7,4.9,21.4,8.6l-1,1.8C173.1,253.2,165.8,250.4,158.1,248.2"/>
						<path class="st53" d="M179.7,256.8l1-1.8c0.7,0.4,1.5,0.8,2.2,1.2l-1,1.8C181.1,257.6,180.4,257.2,179.7,256.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000085232678215701256020000015865674840506429583_" class="st1" d="M191,432.1l-1.4,1.4
							c-0.2-0.2-3.3-3.8-8.1-3.8c-0.5,0-1.1,0-1.7,0.2l-0.4-2c0.8-0.2,1.4-0.2,2.1-0.2C187.2,427.8,190.8,431.9,191,432.1z"/>
					</defs>
					<clipPath id="SVGID_00000060006524774367084200000014264328492136345477_">
						<use xlink:href="#SVGID_00000085232678215701256020000015865674840506429583_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000060006524774367084200000014264328492136345477_);enable-background:new    ;">
						<path class="st18" d="M191,432.1l-1.4,1.4c-0.1-0.1-0.7-0.8-1.9-1.7l1.1-1.6C190.1,431.2,190.9,432,191,432.1"/>
						<path class="st5" d="M188.8,430.2l-1.1,1.6c-1.2-0.9-3-1.8-5.1-2l0.2-2C185.3,428.1,187.4,429.2,188.8,430.2"/>
						<path class="st7" d="M182.7,427.8l-0.2,2c-0.3,0-0.7-0.1-1-0.1c-0.4,0-0.9,0-1.3,0.1l-0.2-2c0.6-0.1,1-0.1,1.6-0.1
							C181.9,427.8,182.3,427.8,182.7,427.8"/>
						<path class="st8" d="M179.9,427.8l0.2,2c-0.1,0-0.3,0-0.4,0.1l-0.4-2C179.6,427.9,179.8,427.8,179.9,427.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000092420936655674307730000011621117623695286164_" class="st1" d="M181.8,258.1l1-1.8
							c0.6,0.1,1.3,0.1,1.9,0.1c2.1,0,4.1-0.4,5.5-1.8l1.4,1.4c-1.9,1.9-4.5,2.4-6.9,2.4C184,258.5,183.3,258.3,181.8,258.1z"/>
					</defs>
					<clipPath id="SVGID_00000113324080622469966460000011880996727026040193_">
						<use xlink:href="#SVGID_00000092420936655674307730000011621117623695286164_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000113324080622469966460000011880996727026040193_);enable-background:new    ;">
						<path class="st51" d="M181.8,258.1l1-1.8c0.4,0,0.7,0.1,1.1,0.1l-0.2,2C183.3,258.3,182.7,258.2,181.8,258.1"/>
						<path class="st14" d="M183.8,258.4l0.2-2c0.3,0,0.5,0,0.8,0c0.4,0,0.8,0,1.3-0.1l0.2,2c-0.5,0-1,0.1-1.5,0.1
							C184.4,258.4,184.1,258.4,183.8,258.4"/>
						<path class="st13" d="M186.2,258.3l-0.2-2c0.5,0,1-0.1,1.5-0.3l0.6,1.9C187.5,258.2,186.9,258.3,186.2,258.3"/>
						<path class="st12" d="M188.1,258l-0.6-1.9c0.4-0.1,0.7-0.2,1.1-0.4l0.9,1.8C189,257.7,188.6,257.9,188.1,258"/>
						<path class="st11" d="M189.5,257.5l-0.9-1.8c0.3-0.1,0.6-0.3,0.8-0.4l1.1,1.7C190.2,257.1,189.8,257.3,189.5,257.5"/>
						<path class="st10" d="M190.6,256.9l-1.1-1.7c0.3-0.2,0.5-0.3,0.7-0.6l1.4,1.5C191.2,256.4,190.9,256.7,190.6,256.9"/>
						<path class="st9" d="M191.5,256.2l-1.4-1.5c0,0,0.1-0.1,0.1-0.1l1.4,1.4C191.6,256.1,191.6,256.1,191.5,256.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000081645112785887384830000013464595461460171961_" class="st1" d="M199.4,440.6L198,442
							c-5.7-5.7-8.3-8.3-8.5-8.5l1.4-1.4C191.1,432.2,193.8,434.9,199.4,440.6z"/>
					</defs>
					<clipPath id="SVGID_00000015354974793356194960000014784458568422998974_">
						<use xlink:href="#SVGID_00000081645112785887384830000013464595461460171961_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000015354974793356194960000014784458568422998974_);enable-background:new    ;">
						<path class="st18" d="M199.4,440.6L198,442c-5.7-5.7-8.3-8.3-8.5-8.5l1.4-1.4C191.1,432.2,193.8,434.9,199.4,440.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000146468756172875254220000006187067335483151514_" class="st1" d="M210.8,442L210.8,442
							c-0.1,0.1-3.1,2.5-6.9,2.5h0C207.6,444.5,210.6,442.1,210.8,442z"/>
					</defs>
					<clipPath id="SVGID_00000039112258071577315270000005702914220541555592_">
						<use xlink:href="#SVGID_00000146468756172875254220000006187067335483151514_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000039112258071577315270000005702914220541555592_);enable-background:new    ;">
						<path class="st3" d="M210.8,442L210.8,442c-0.1,0.1-3.1,2.5-6.9,2.5h0C207.6,444.5,210.6,442.1,210.8,442"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000119110593677729155320000018123112069657855625_" class="st1" d="M206.4,242.1L206.4,242.1
							c1.4,0,2.9,0.5,4.3,1.9l0,0C209.4,242.6,207.9,242.1,206.4,242.1z"/>
					</defs>
					<clipPath id="SVGID_00000098213259009077382610000002289444594595495608_">
						<use xlink:href="#SVGID_00000119110593677729155320000018123112069657855625_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000098213259009077382610000002289444594595495608_);enable-background:new    ;">
						<path class="st16" d="M206.4,242.1L206.4,242.1c0.1,0,0.2,0,0.2,0h0C206.6,242.1,206.5,242.1,206.4,242.1"/>
						<path class="st34" d="M206.7,242.1L206.7,242.1c0.3,0,0.6,0,0.9,0.1h0C207.2,242.2,206.9,242.1,206.7,242.1"/>
						<path class="st33" d="M207.5,242.2L207.5,242.2c0.3,0,0.6,0.1,0.8,0.2h0C208,242.3,207.8,242.3,207.5,242.2"/>
						<path class="st32" d="M208.3,242.4L208.3,242.4c0.3,0.1,0.6,0.3,1,0.4h0C209,242.7,208.6,242.5,208.3,242.4"/>
						<path class="st31" d="M209.3,242.9L209.3,242.9c0.5,0.3,1,0.7,1.5,1.2h0C210.3,243.5,209.8,243.1,209.3,242.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000088849734274688414210000003127863145355592856_" class="st1" d="M209.4,440.6c0.1,0.1,0.3,0.3,0.4,0.4
							l1,1c-0.2,0.1-3.1,2.5-6.9,2.5c-1.9,0-3.9-0.6-5.8-2.5l1.4-1.4c1.5,1.5,3,1.9,4.4,1.9C206.9,442.5,209.3,440.6,209.4,440.6z"
							/>
					</defs>
					<clipPath id="SVGID_00000158024008756444672970000006854620225512055717_">
						<use xlink:href="#SVGID_00000088849734274688414210000003127863145355592856_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000158024008756444672970000006854620225512055717_);enable-background:new    ;">
						<polyline class="st9" points="209.3,440.6 210.8,442 210.8,442 209.3,440.6 209.3,440.6 						"/>
						<path class="st10" d="M209.3,440.6l1.4,1.4c0,0-0.2,0.2-0.5,0.4l-1.2-1.6C209.3,440.7,209.4,440.6,209.3,440.6"/>
						<path class="st9" d="M209,440.8l1.2,1.6c-0.5,0.3-1.2,0.8-2.2,1.2l-0.8-1.8C208.1,441.4,208.7,441,209,440.8"/>
						<path class="st8" d="M207.3,441.8l0.8,1.8c-0.7,0.3-1.6,0.6-2.5,0.8l-0.3-2C206,442.2,206.7,442,207.3,441.8"/>
						<path class="st7" d="M205.2,442.4l0.3,2c-0.5,0.1-1.1,0.2-1.7,0.2c-0.3,0-0.6,0-0.9-0.1l0.2-2c0.2,0,0.5,0,0.7,0
							C204.3,442.5,204.8,442.5,205.2,442.4"/>
						<path class="st5" d="M203.2,442.5l-0.2,2c-1.2-0.1-2.5-0.6-3.7-1.5l1.1-1.6C201.3,442,202.2,442.4,203.2,442.5"/>
						<path class="st18" d="M200.3,441.4l-1.1,1.6c-0.4-0.3-0.8-0.6-1.2-1l1.4-1.4C199.7,440.9,200,441.1,200.3,441.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000107550764674132335800000015489999628947633832_" class="st1" d="M209.8,441l-0.4-0.4c0,0,0,0,0,0
							C209.5,440.7,209.6,440.9,209.8,441z"/>
					</defs>
					<clipPath id="SVGID_00000042015628625872904960000005261898755632843677_">
						<use xlink:href="#SVGID_00000107550764674132335800000015489999628947633832_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000042015628625872904960000005261898755632843677_);enable-background:new    ;">
						<polyline class="st9" points="209.3,440.6 209.8,441 209.8,441 209.3,440.6 209.3,440.6 						"/>
						<polyline class="st10" points="209.3,440.6 209.8,441 209.8,441 209.4,440.6 209.3,440.6 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000116915642416872500930000006193232192195978428_" class="st1" d="M191.7,256l-1.4-1.4
							c3.5-3.5,8.9-8.9,9.2-9.2c0.2-0.2,3.3-3.3,7-3.3c1.4,0,2.9,0.5,4.3,1.9l-1.4,1.4c-1-1-2-1.3-2.9-1.3c-2.8,0-5.5,2.6-5.6,2.7
							C200.6,247.1,195.2,252.5,191.7,256z"/>
					</defs>
					<clipPath id="SVGID_00000064328298681013067650000006076922917661869495_">
						<use xlink:href="#SVGID_00000116915642416872500930000006193232192195978428_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000064328298681013067650000006076922917661869495_);enable-background:new    ;">
						<path class="st9" d="M191.7,256l-1.4-1.4c3.5-3.5,8.9-8.9,9.2-9.2c0,0,0.1-0.1,0.2-0.2l1.4,1.4c-0.1,0.1-0.1,0.1-0.1,0.1
							C200.6,247.1,195.2,252.5,191.7,256"/>
						<path class="st10" d="M201,246.7l-1.4-1.4c0.3-0.3,1-0.9,1.9-1.5l1,1.7C201.8,246,201.3,246.5,201,246.7"/>
						<path class="st11" d="M202.6,245.5l-1-1.7c0.5-0.3,1.1-0.7,1.7-0.9l0.7,1.9C203.5,244.9,203,245.2,202.6,245.5"/>
						<path class="st12" d="M204,244.7l-0.7-1.9c0.4-0.2,0.9-0.3,1.4-0.5l0.4,1.9C204.7,244.4,204.3,244.5,204,244.7"/>
						<path class="st13" d="M205,244.3l-0.4-1.9c0.4-0.1,0.7-0.2,1.1-0.2l0.2,2C205.6,244.2,205.3,244.2,205,244.3"/>
						<path class="st14" d="M205.9,244.1l-0.2-2c0.2,0,0.5,0,0.7,0c0.1,0,0.3,0,0.4,0l-0.1,2c-0.1,0-0.2,0-0.3,0
							C206.3,244.1,206.1,244.1,205.9,244.1"/>
						<path class="st51" d="M206.7,244.1l0.1-2c0.3,0,0.7,0.1,1,0.2l-0.5,1.9C207.1,244.2,206.9,244.1,206.7,244.1"/>
						<path class="st52" d="M207.4,244.2l0.5-1.9c0.4,0.1,0.8,0.2,1.1,0.4l-0.9,1.8C207.9,244.4,207.6,244.3,207.4,244.2"/>
						<path class="st53" d="M208.1,244.5l0.9-1.8c0.6,0.3,1.2,0.7,1.8,1.3l-1.4,1.4C208.9,245,208.5,244.7,208.1,244.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000107559105055813124440000003514013678456049808_" class="st1" d="M220.1,390.2L220.1,390.2
							c-0.6,2.7-1.2,7.6,1.2,10.1h0C218.9,397.8,219.5,392.9,220.1,390.2z"/>
					</defs>
					<clipPath id="SVGID_00000130609562552411698640000013846276659660984463_">
						<use xlink:href="#SVGID_00000107559105055813124440000003514013678456049808_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000130609562552411698640000013846276659660984463_);enable-background:new    ;">
						<path class="st16" d="M220.1,390.2L220.1,390.2c-0.1,0.4-0.2,0.8-0.2,1.3h0C220,391,220.1,390.6,220.1,390.2"/>
						<path class="st34" d="M219.9,391.5L219.9,391.5c-0.1,0.9-0.3,1.9-0.3,2.9h0C219.6,393.4,219.7,392.4,219.9,391.5"/>
						<path class="st33" d="M219.6,394.4L219.6,394.4c0,0.7,0,1.4,0.1,2h0C219.6,395.7,219.6,395.1,219.6,394.4"/>
						<path class="st32" d="M219.7,396.4L219.7,396.4c0.1,0.5,0.2,1.1,0.4,1.6h0C219.9,397.5,219.8,396.9,219.7,396.4"/>
						<path class="st31" d="M220,398L220,398c0.3,0.9,0.7,1.7,1.4,2.3h0C220.7,399.6,220.3,398.8,220,398"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000095326426363531691810000004231061262555988117_" class="st1" d="M223.5,286.4L223.5,286.4
							c-0.2,0.1-3.3,2.9-3.7,7.2h0C220.2,289.4,223.3,286.6,223.5,286.4z"/>
					</defs>
					<clipPath id="SVGID_00000008850605151759058620000010928586857515508643_">
						<use xlink:href="#SVGID_00000095326426363531691810000004231061262555988117_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000008850605151759058620000010928586857515508643_);enable-background:new    ;">
						<path class="st3" d="M223.5,286.4L223.5,286.4c-0.1,0.1-1.4,1.2-2.4,3.2h0C222.1,287.7,223.4,286.5,223.5,286.4"/>
						<path class="st35" d="M221,289.6L221,289.6c-0.3,0.6-0.6,1.2-0.8,1.8h0C220.5,290.8,220.7,290.1,221,289.6"/>
						<path class="st16" d="M220.2,291.4L220.2,291.4c-0.1,0.5-0.3,1-0.4,1.5h0C220,292.4,220.1,291.9,220.2,291.4"/>
						<path class="st34" d="M219.9,292.9L219.9,292.9c0,0.2-0.1,0.5-0.1,0.7h0C219.8,293.4,219.8,293.2,219.9,292.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000021803455648176483850000000386246619544041640_" class="st1" d="M134,425l0-2c43.6,0,79-35.4,79-79
							c0-43.6-35.4-79-79-79c-43.6,0-79,35.4-79,79c0,43.6,35.4,79,79,79L134,425c-44.7,0-81-36.3-81-81c0-44.7,36.3-81,81-81
							c44.7,0,81,36.3,81,81C215,388.7,178.7,425,134,425z"/>
					</defs>
					<clipPath id="SVGID_00000174567671581061961380000006533767124247206027_">
						<use xlink:href="#SVGID_00000021803455648176483850000000386246619544041640_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000174567671581061961380000006533767124247206027_);enable-background:new    ;">
						<path class="st14" d="M134,425l0-2c2.7,0,5.3-0.1,7.9-0.4l0.2,2C139.4,424.9,136.7,425,134,425"/>
						<path class="st13" d="M142.1,424.6l-0.2-2c4.1-0.4,8.1-1.1,12-2.2l0.5,1.9C150.5,423.4,146.3,424.2,142.1,424.6"/>
						<path class="st12" d="M154.5,422.4l-0.5-1.9c4.1-1.1,8.1-2.5,11.9-4.1l0.8,1.8C162.7,419.9,158.7,421.3,154.5,422.4"/>
						<path class="st11" d="M166.7,418.1l-0.8-1.8c3.9-1.7,7.7-3.8,11.2-6.1l1.1,1.7C174.5,414.3,170.7,416.4,166.7,418.1"/>
						<path class="st10" d="M178.2,411.9l-1.1-1.7c3.9-2.5,7.5-5.4,10.9-8.6l1.4,1.5C185.9,406.4,182.2,409.3,178.2,411.9"/>
						<path class="st9" d="M189.4,403.1l-1.4-1.5c3.8-3.5,7.2-7.4,10.2-11.6l1.6,1.2C196.7,395.5,193.2,399.5,189.4,403.1"/>
						<path class="st8" d="M199.8,391.2l-1.6-1.2c3.4-4.8,6.3-9.9,8.6-15.4l1.8,0.8C206.3,381.1,203.3,386.4,199.8,391.2"/>
						<path class="st7" d="M208.6,375.5l-1.8-0.8c2.9-6.9,4.9-14.3,5.7-22l2,0.2C213.6,360.8,211.6,368.4,208.6,375.5"/>
						<path class="st5" d="M214.5,352.9l-2-0.2c0.3-2.8,0.5-5.7,0.5-8.7c0-10.9-2.2-21.3-6.2-30.8l1.8-0.8
							c4.1,9.7,6.4,20.4,6.4,31.6C215,347,214.8,350,214.5,352.9"/>
						<path class="st18" d="M208.6,312.4l-1.8,0.8c-5.7-13.5-15.1-25.1-26.9-33.5l1.2-1.6C193.1,286.7,202.7,298.6,208.6,312.4"/>
						<path class="st5" d="M181,278l-1.2,1.6c-10.7-7.6-23.3-12.6-37-14.2l0.2-2C157.1,265.1,170.1,270.2,181,278"/>
						<path class="st7" d="M143,263.5l-0.2,2c-2.9-0.3-5.8-0.5-8.8-0.5c-4.8,0-9.4,0.4-14,1.2l-0.4-2c4.7-0.8,9.4-1.3,14.3-1.3
							C137.1,263,140.1,263.2,143,263.5"/>
						<path class="st8" d="M119.6,264.3l0.4,2c-5.9,1-11.5,2.7-16.8,5l-0.8-1.8C107.9,267.1,113.6,265.3,119.6,264.3"/>
						<path class="st9" d="M102.4,269.4l0.8,1.8c-4.7,2-9.2,4.4-13.4,7.3l-1.1-1.7C93,274,97.6,271.4,102.4,269.4"/>
						<path class="st10" d="M88.7,276.9l1.1,1.7c-3.8,2.6-7.4,5.5-10.6,8.6l-1.4-1.4C81.1,282.5,84.8,279.5,88.7,276.9"/>
						<path class="st11" d="M77.8,285.7l1.4,1.4c-3.1,3-6,6.2-8.5,9.7l-1.6-1.2C71.6,292.1,74.6,288.8,77.8,285.7"/>
						<path class="st12" d="M69,295.7l1.6,1.2c-2.5,3.3-4.7,6.8-6.6,10.5l-1.8-0.9C64.2,302.7,66.5,299.1,69,295.7"/>
						<path class="st13" d="M62.2,306.4l1.8,0.9c-1.9,3.7-3.6,7.5-4.9,11.5l-1.9-0.6C58.6,314.2,60.2,310.2,62.2,306.4"/>
						<path class="st14" d="M57.2,318.2l1.9,0.6c-1.3,3.9-2.3,8-3,12.2l-2-0.3C54.8,326.4,55.8,322.3,57.2,318.2"/>
						<path class="st51" d="M54.1,330.7l2,0.3c-0.7,4.2-1.1,8.5-1.1,12.9c0,0.5,0,0.9,0,1.4l-2,0c0-0.5,0-1,0-1.4
							C53,339.5,53.4,335,54.1,330.7"/>
						<path class="st52" d="M53,345.4l2,0c0.1,6,0.9,11.8,2.2,17.4l-1.9,0.5C53.9,357.5,53.1,351.6,53,345.4"/>
						<path class="st53" d="M55.3,363.3l1.9-0.5c5.5,22.7,20.9,41.6,41.3,51.8l-0.9,1.8C76.7,405.9,61,386.5,55.3,363.3"/>
						<path class="st52" d="M97.6,416.4l0.9-1.8c5.1,2.6,10.5,4.6,16.1,6l-0.5,1.9C108.4,421.1,102.9,419,97.6,416.4"/>
						<path class="st51" d="M114.2,422.6l0.5-1.9c4.5,1.1,9.2,1.9,14,2.2l-0.1,2C123.6,424.5,118.8,423.7,114.2,422.6"/>
						<path class="st14" d="M128.5,424.8l0.1-2c1.8,0.1,3.5,0.2,5.3,0.2l0,2C132.2,425,130.3,424.9,128.5,424.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000077290404332561578340000002215199049157690291_" class="st1" d="M228.5,370.2L228.5,370.2
							c-2,7-4.9,13.7-8.3,20h0C223.6,383.9,226.4,377.2,228.5,370.2z"/>
					</defs>
					<clipPath id="SVGID_00000134947291563173545820000010919333634977403302_">
						<use xlink:href="#SVGID_00000077290404332561578340000002215199049157690291_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000134947291563173545820000010919333634977403302_);enable-background:new    ;">
						<path class="st16" d="M228.5,370.2L228.5,370.2c-0.3,1.1-0.6,2.2-1,3.2h0C227.8,372.4,228.2,371.3,228.5,370.2"/>
						<path class="st35" d="M227.5,373.4L227.5,373.4c-1.8,5.6-4.2,11-6.9,16.1h0C223.3,384.4,225.6,379,227.5,373.4"/>
						<path class="st3" d="M220.5,389.5L220.5,389.5c-0.1,0.2-0.2,0.5-0.4,0.7h0C220.3,390,220.4,389.7,220.5,389.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000173851640157635250420000008508222495625939891_" class="st1" d="M218.4,389.2l1.8,1
							c-0.6,2.7-1.3,7.6,1.2,10.1l-1.4,1.4C216.7,398.4,217.5,392.7,218.4,389.2z"/>
					</defs>
					<clipPath id="SVGID_00000171704894252757285720000009772513895193874098_">
						<use xlink:href="#SVGID_00000173851640157635250420000008508222495625939891_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000171704894252757285720000009772513895193874098_);enable-background:new    ;">
						<path class="st14" d="M218.4,389.2l1.8,1c-0.1,0.4-0.2,0.8-0.3,1.3l-1.9-0.6C218.1,390.3,218.3,389.7,218.4,389.2"/>
						<path class="st51" d="M218,390.8l1.9,0.6c-0.2,1-0.3,2.2-0.3,3.4l-2,0.1C217.6,393.5,217.8,392.1,218,390.8"/>
						<path class="st52" d="M217.6,394.9l2-0.1c0,0.8,0.1,1.6,0.2,2.4l-1.9,0.6C217.7,396.9,217.6,395.9,217.6,394.9"/>
						<path class="st53" d="M217.9,397.8l1.9-0.6c0.3,1.2,0.7,2.2,1.5,3l-1.4,1.4C218.9,400.6,218.3,399.3,217.9,397.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000108275533105770847850000000503215511577116848_" class="st1" d="M219.8,293.6L219.8,293.6
							c4,7,7.1,14.6,9.3,22.6h0C226.9,308.3,223.7,300.7,219.8,293.6z"/>
					</defs>
					<clipPath id="SVGID_00000095310634668019865150000012378234508736339388_">
						<use xlink:href="#SVGID_00000108275533105770847850000000503215511577116848_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000095310634668019865150000012378234508736339388_);enable-background:new    ;">
						<path class="st31" d="M219.8,293.6L219.8,293.6c3.3,5.8,6,12,8,18.4h0C225.7,305.6,223,299.5,219.8,293.6"/>
						<path class="st32" d="M227.8,312.1L227.8,312.1c0.5,1.4,0.9,2.8,1.2,4.2h0C228.7,314.9,228.2,313.5,227.8,312.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000107564896702183879760000006529748960137956521_" class="st1" d="M221.4,400.3L221.4,400.3
							c3.5,3.5,8.9,8.9,9.2,9.2h0C230.3,409.2,224.9,403.8,221.4,400.3z"/>
					</defs>
					<clipPath id="SVGID_00000113314980410132192380000003543355515562501259_">
						<use xlink:href="#SVGID_00000107564896702183879760000006529748960137956521_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000113314980410132192380000003543355515562501259_);enable-background:new    ;">
						<path class="st31" d="M221.4,400.3L221.4,400.3c3.5,3.5,8.9,8.9,9.2,9.2h0C230.3,409.2,224.9,403.8,221.4,400.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000106870257040263822980000002948882759029279122_" class="st1" d="M222.1,285l1.4,1.4
							c-0.2,0.1-3.3,2.9-3.7,7.2l-2.1,0.4C218.3,288.4,221.9,285.1,222.1,285z"/>
					</defs>
					<clipPath id="SVGID_00000029021886277067823040000010246981947597113514_">
						<use xlink:href="#SVGID_00000106870257040263822980000002948882759029279122_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000029021886277067823040000010246981947597113514_);enable-background:new    ;">
						<path class="st10" d="M222.1,285l1.4,1.4c0,0-0.1,0.1-0.2,0.2l-1.4-1.4C222,285.1,222.1,285,222.1,285"/>
						<path class="st11" d="M221.9,285.2l1.4,1.4c-0.3,0.2-0.7,0.7-1.2,1.4l-1.6-1.2C221.1,286,221.6,285.5,221.9,285.2"/>
						<path class="st12" d="M220.5,286.8l1.6,1.2c-0.3,0.4-0.7,1-1,1.6l-1.8-0.8C219.7,288,220.1,287.3,220.5,286.8"/>
						<path class="st13" d="M219.3,288.8l1.8,0.8c-0.3,0.5-0.5,1-0.7,1.6l-1.9-0.4C218.7,290,219,289.4,219.3,288.8"/>
						<path class="st14" d="M218.4,290.8l1.9,0.4c-0.2,0.5-0.4,1.1-0.5,1.7l-2,0.2C218,292.2,218.2,291.4,218.4,290.8"/>
						<path class="st51" d="M217.9,293l2-0.2c0,0.3-0.1,0.5-0.1,0.8l-2.1,0.4C217.8,293.7,217.8,293.3,217.9,293"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000097471219951606850590000012586146372792830091_" class="st1" d="M232,420.8L232,420.8
							c-4.9,4.9-20.4,20.4-21.2,21.2h0C211.6,441.2,227,425.7,232,420.8z"/>
					</defs>
					<clipPath id="SVGID_00000132071088103639094740000015951762050321796227_">
						<use xlink:href="#SVGID_00000097471219951606850590000012586146372792830091_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000132071088103639094740000015951762050321796227_);enable-background:new    ;">
						<path class="st3" d="M232,420.8L232,420.8c-4.9,4.9-20.4,20.4-21.2,21.2h0C211.6,441.2,227,425.7,232,420.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000002364983178499096090000006473578392323550898_" class="st1" d="M232,277.9L232,277.9
							c-5.6,5.7-8.3,8.3-8.5,8.5h0C223.6,286.3,226.3,283.6,232,277.9z"/>
					</defs>
					<clipPath id="SVGID_00000176010336527894413910000003468945633097283200_">
						<use xlink:href="#SVGID_00000002364983178499096090000006473578392323550898_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000176010336527894413910000003468945633097283200_);enable-background:new    ;">
						<path class="st3" d="M232,277.9L232,277.9c-5.6,5.7-8.3,8.3-8.5,8.5h0C223.6,286.3,226.3,283.6,232,277.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000093143210704264090900000011744641669681139642_" class="st1" d="M210.8,244L210.8,244
							c5,4.9,20.4,20.4,21.2,21.2h0C231.2,264.4,215.7,249,210.8,244z"/>
					</defs>
					<clipPath id="SVGID_00000025432277827544987990000017999841157985817503_">
						<use xlink:href="#SVGID_00000093143210704264090900000011744641669681139642_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000025432277827544987990000017999841157985817503_);enable-background:new    ;">
						<path class="st31" d="M210.8,244L210.8,244c5,4.9,20.4,20.4,21.2,21.2h0C231.2,264.4,215.7,249,210.8,244"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000127014687566167126530000006470412520700872870_" class="st1" d="M226.7,369.2l1.8,1
							c-2.1,7-4.9,13.7-8.3,20l-1.8-1C221.8,383.1,224.5,376.3,226.7,369.2z"/>
					</defs>
					<clipPath id="SVGID_00000090978147993020860960000017067074414444753545_">
						<use xlink:href="#SVGID_00000127014687566167126530000006470412520700872870_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000090978147993020860960000017067074414444753545_);enable-background:new    ;">
						<path class="st14" d="M226.7,369.2l1.8,1c-0.3,0.9-0.5,1.7-0.8,2.6l-1.8-1C226.1,370.9,226.4,370.1,226.7,369.2"/>
						<path class="st13" d="M225.9,371.8l1.8,1c-1.7,5.3-3.9,10.5-6.4,15.4l-1.8-0.9C222,382.4,224,377.2,225.9,371.8"/>
						<path class="st12" d="M219.4,387.2l1.8,0.9c-0.4,0.7-0.7,1.4-1.1,2l-1.8-1C218.7,388.6,219.1,387.9,219.4,387.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000138544447200010442160000012381202745531821471_" class="st1" d="M229,316.3L229,316.3
							c1.7,2.2,4.2,4.7,7,4.7h0C233.2,321,230.7,318.5,229,316.3z"/>
					</defs>
					<clipPath id="SVGID_00000160896523426322933500000008767045611077679012_">
						<use xlink:href="#SVGID_00000138544447200010442160000012381202745531821471_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000160896523426322933500000008767045611077679012_);enable-background:new    ;">
						<path class="st31" d="M229,316.3L229,316.3c1.1,1.4,2.5,3,4.2,3.9h0C231.5,319.3,230.1,317.7,229,316.3"/>
						<path class="st32" d="M233.2,320.2L233.2,320.2c0.4,0.2,0.8,0.4,1.1,0.5h0C233.9,320.6,233.5,320.4,233.2,320.2"/>
						<path class="st33" d="M234.3,320.7L234.3,320.7c0.3,0.1,0.5,0.2,0.8,0.2h0C234.8,320.9,234.6,320.8,234.3,320.7"/>
						<path class="st34" d="M235.1,320.9L235.1,320.9c0.2,0,0.5,0.1,0.7,0.1h0C235.6,321,235.3,321,235.1,320.9"/>
						<path class="st16" d="M235.8,321L235.8,321c0.1,0,0.1,0,0.2,0h0C235.9,321,235.9,321,235.8,321"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000155108853019443022110000006793888546073523354_" class="st1" d="M217.7,294.1l2.1-0.4
							c4,7,7.1,14.6,9.3,22.6l-2,0.5C224.9,309.2,221.9,301.5,217.7,294.1z"/>
					</defs>
					<clipPath id="SVGID_00000144310923656823813980000013204027440244394627_">
						<use xlink:href="#SVGID_00000155108853019443022110000006793888546073523354_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000144310923656823813980000013204027440244394627_);enable-background:new    ;">
						<path class="st53" d="M217.7,294.1l2.1-0.4c4,7,7.1,14.6,9.3,22.6l-2,0.5C224.9,309.2,221.9,301.5,217.7,294.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000182505713427159780530000015878978233863488400_" class="st1" d="M236.9,367L236.9,367
							c-1.6,0-5.6,0.3-8.4,3.2h0C231.3,367.3,235.3,367,236.9,367z"/>
					</defs>
					<clipPath id="SVGID_00000137128194750785254600000004914274178337147284_">
						<use xlink:href="#SVGID_00000182505713427159780530000015878978233863488400_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000137128194750785254600000004914274178337147284_);enable-background:new    ;">
						<path class="st3" d="M236.9,367L236.9,367c-1.6,0-5.6,0.3-8.4,3.2h0C231.3,367.3,235.3,367,236.9,367"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000173867527111312432930000010238654414160999614_" class="st1" d="M230.6,409.5L230.6,409.5
							c0.3,0.3,6.4,6.4,1.4,11.3h0C236.9,415.8,230.9,409.8,230.6,409.5z"/>
					</defs>
					<clipPath id="SVGID_00000142170400655493109490000002511596017816395946_">
						<use xlink:href="#SVGID_00000173867527111312432930000010238654414160999614_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000142170400655493109490000002511596017816395946_);enable-background:new    ;">
						<path class="st31" d="M230.6,409.5L230.6,409.5c0.2,0.2,2.2,2.2,3,4.8h0C232.7,411.6,230.7,409.6,230.6,409.5"/>
						<path class="st32" d="M233.6,414.2L233.6,414.2c0.2,0.4,0.3,0.9,0.3,1.3h0C233.8,415.1,233.7,414.7,233.6,414.2"/>
						<path class="st33" d="M233.9,415.6L233.9,415.6c0.1,0.3,0.1,0.6,0.1,1h0C233.9,416.2,233.9,415.9,233.9,415.6"/>
						<path class="st34" d="M233.9,416.5L233.9,416.5c0,0.3,0,0.6-0.1,0.8h0C233.9,417.1,233.9,416.8,233.9,416.5"/>
						<path class="st16" d="M233.8,417.4L233.8,417.4c0,0.3-0.1,0.6-0.2,0.8h0C233.7,417.9,233.8,417.7,233.8,417.4"/>
						<path class="st35" d="M233.6,418.2L233.6,418.2c-0.1,0.3-0.2,0.6-0.4,1h0C233.4,418.8,233.5,418.5,233.6,418.2"/>
						<path class="st3" d="M233.2,419.2L233.2,419.2c-0.3,0.5-0.7,1.1-1.2,1.6h0C232.5,420.3,232.9,419.7,233.2,419.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000032636103872648285080000017069357429992947078_" class="st1" d="M232,265.2L232,265.2
							c0.3,0.4,5.7,7.1,0,12.7h0C237.6,272.3,232.3,265.6,232,265.2z"/>
					</defs>
					<clipPath id="SVGID_00000044156496577361046250000003581739902972017827_">
						<use xlink:href="#SVGID_00000032636103872648285080000017069357429992947078_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000044156496577361046250000003581739902972017827_);enable-background:new    ;">
						<path class="st31" d="M232,265.2L232,265.2c0.1,0.2,1.4,1.7,2.1,3.9h0C233.4,267,232.1,265.4,232,265.2"/>
						<path class="st32" d="M234.1,269.2L234.1,269.2c0.2,0.6,0.3,1.2,0.4,1.8h0C234.4,270.3,234.3,269.7,234.1,269.2"/>
						<path class="st33" d="M234.5,271L234.5,271c0.1,0.4,0.1,0.9,0.1,1.3h0C234.6,271.8,234.5,271.4,234.5,271"/>
						<path class="st34" d="M234.6,272.3L234.6,272.3c0,0.4,0,0.8-0.1,1.1h0C234.5,273,234.5,272.6,234.6,272.3"/>
						<path class="st16" d="M234.4,273.4L234.4,273.4c-0.1,0.4-0.1,0.7-0.3,1.1h0C234.3,274.1,234.4,273.8,234.4,273.4"/>
						<path class="st35" d="M234.2,274.5L234.2,274.5c-0.1,0.4-0.3,0.9-0.6,1.3h0C233.8,275.4,234,275,234.2,274.5"/>
						<path class="st3" d="M233.6,275.8L233.6,275.8c-0.4,0.7-0.9,1.4-1.6,2.1h0C232.7,277.2,233.2,276.5,233.6,275.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000080207132683307178460000006261478722827344307_" class="st1" d="M209.4,245.4l1.4-1.4
							c4.9,4.9,20.4,20.4,21.2,21.2l-1.4,1.4C229.7,265.8,214.3,250.4,209.4,245.4z"/>
					</defs>
					<clipPath id="SVGID_00000075864794929671984970000016328650812084218031_">
						<use xlink:href="#SVGID_00000080207132683307178460000006261478722827344307_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000075864794929671984970000016328650812084218031_);enable-background:new    ;">
						<path class="st53" d="M209.4,245.4l1.4-1.4c4.9,4.9,20.4,20.4,21.2,21.2l-1.4,1.4C229.7,265.8,214.3,250.4,209.4,245.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000025425300545654967110000009462859244176053400_" class="st1" d="M230.6,419.4l1.4,1.4
							c-4.9,4.9-20.4,20.4-21.2,21.2l-1.4-1.4C210.2,439.8,225.6,424.3,230.6,419.4z"/>
					</defs>
					<clipPath id="SVGID_00000121977825347411458800000008557921047319519382_">
						<use xlink:href="#SVGID_00000025425300545654967110000009462859244176053400_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000121977825347411458800000008557921047319519382_);enable-background:new    ;">
						<path class="st11" d="M230.6,419.4l1.4,1.4c-4.9,4.9-20.4,20.4-21.2,21.2l-1.4-1.4C210.2,439.8,225.6,424.3,230.6,419.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000160872749336573453200000012800811431555812757_" class="st1" d="M230.6,276.5l1.4,1.4
							c-5.7,5.7-8.3,8.3-8.5,8.5l-1.4-1.4C222.2,284.9,224.9,282.2,230.6,276.5z"/>
					</defs>
					<clipPath id="SVGID_00000153692605937094218210000015028518348329805993_">
						<use xlink:href="#SVGID_00000160872749336573453200000012800811431555812757_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000153692605937094218210000015028518348329805993_);enable-background:new    ;">
						<path class="st11" d="M230.6,276.5l1.4,1.4c-5.7,5.7-8.3,8.3-8.5,8.5l-1.4-1.4C222.2,284.9,224.9,282.2,230.6,276.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000018239554447851083510000007737251282259124610_" class="st1" d="M220,401.7l1.4-1.4
							c3.5,3.5,8.9,8.9,9.2,9.2c0.3,0.3,6.4,6.4,1.4,11.3l-1.4-1.4c3.5-3.4-1.2-8.2-1.4-8.5C228.9,410.6,223.5,405.2,220,401.7z"/>
					</defs>
					<clipPath id="SVGID_00000074428605046217663370000006268684781580125617_">
						<use xlink:href="#SVGID_00000018239554447851083510000007737251282259124610_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000074428605046217663370000006268684781580125617_);enable-background:new    ;">
						<path class="st53" d="M220,401.7l1.4-1.4c3.5,3.5,8.9,8.9,9.2,9.2c0.2,0.2,2.4,2.4,3.1,5.2l-2,0.3c-0.6-2.1-2.4-3.9-2.5-4
							C228.9,410.6,223.5,405.2,220,401.7"/>
						<path class="st52" d="M231.6,414.9l2-0.3c0.1,0.5,0.2,1.1,0.2,1.6l-2-0.1C231.8,415.7,231.8,415.3,231.6,414.9"/>
						<path class="st51" d="M231.9,416.1l2,0.1c0,0.4,0,0.7-0.1,1.1l-2-0.4C231.9,416.7,231.9,416.4,231.9,416.1"/>
						<path class="st14" d="M231.8,416.9l2,0.4c-0.1,0.3-0.1,0.6-0.2,0.9l-1.9-0.7C231.8,417.4,231.8,417.2,231.8,416.9"/>
						<path class="st13" d="M231.7,417.6l1.9,0.7c-0.1,0.3-0.2,0.6-0.4,0.8l-1.8-0.9C231.6,418,231.6,417.8,231.7,417.6"/>
						<path class="st12" d="M231.5,418.2l1.8,0.9c-0.1,0.3-0.3,0.5-0.5,0.8l-1.6-1.1C231.2,418.6,231.4,418.4,231.5,418.2"/>
						<path class="st11" d="M231.1,418.8l1.6,1.1c-0.2,0.3-0.5,0.6-0.7,0.9l-1.4-1.4C230.8,419.2,230.9,419,231.1,418.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000098182941593309052880000017873117497600581256_" class="st1" d="M230.6,266.6l1.4-1.4
							c0.3,0.4,5.7,7.1,0,12.7l-1.4-1.4C234.9,272.2,230.6,266.7,230.6,266.6z"/>
					</defs>
					<clipPath id="SVGID_00000160169059704199699740000014688244472367645862_">
						<use xlink:href="#SVGID_00000098182941593309052880000017873117497600581256_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000160169059704199699740000014688244472367645862_);enable-background:new    ;">
						<path class="st53" d="M230.6,266.6l1.4-1.4c0.1,0.2,1.7,2.1,2.3,4.6l-2,0.4C231.8,268.2,230.6,266.7,230.6,266.6"/>
						<path class="st52" d="M232.3,270.2l2-0.4c0.2,0.6,0.3,1.3,0.3,2l-2-0.1C232.5,271.3,232.4,270.7,232.3,270.2"/>
						<path class="st51" d="M232.5,271.8l2,0.1c0,0.5,0,1-0.1,1.5l-2-0.4C232.5,272.6,232.5,272.2,232.5,271.8"/>
						<path class="st14" d="M232.5,273l2,0.4c-0.1,0.4-0.2,0.8-0.3,1.2l-1.9-0.6C232.3,273.6,232.4,273.3,232.5,273"/>
						<path class="st13" d="M232.2,273.9l1.9,0.6c-0.1,0.4-0.3,0.8-0.5,1.1l-1.8-0.9C232,274.5,232.1,274.2,232.2,273.9"/>
						<path class="st12" d="M231.9,274.8l1.8,0.9c-0.2,0.4-0.4,0.8-0.7,1.1l-1.6-1.1C231.5,275.4,231.7,275.1,231.9,274.8"/>
						<path class="st11" d="M231.3,275.7l1.6,1.1c-0.3,0.4-0.6,0.8-1,1.1l-1.4-1.4C230.8,276.2,231.1,276,231.3,275.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000059294332088035897750000006486046559602714780_" class="st1" d="M227.1,316.8l2-0.5
							c1.6,2.2,4.1,4.7,6.9,4.7l0,2C232.2,323,229.2,319.9,227.1,316.8z"/>
					</defs>
					<clipPath id="SVGID_00000008124101757255488730000012281706583532535690_">
						<use xlink:href="#SVGID_00000059294332088035897750000006486046559602714780_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000008124101757255488730000012281706583532535690_);enable-background:new    ;">
						<path class="st63" d="M227.1,316.8l2-0.5c0.6,0.8,1.3,1.6,2.1,2.4l-1.4,1.3C228.7,319,227.8,317.9,227.1,316.8"/>
						<path class="st53" d="M229.7,320l1.4-1.3c0.8,0.7,1.6,1.3,2.5,1.8l-0.8,1.8C231.7,321.7,230.6,320.9,229.7,320"/>
						<path class="st52" d="M232.8,322.2l0.8-1.8c0.4,0.2,0.8,0.3,1.2,0.4l-0.4,2C233.8,322.7,233.3,322.5,232.8,322.2"/>
						<path class="st51" d="M234.4,322.8l0.4-2c0.3,0.1,0.6,0.1,0.9,0.1l-0.1,2C235.2,323,234.8,322.9,234.4,322.8"/>
						<path class="st14" d="M235.6,323l0.1-2c0.1,0,0.2,0,0.3,0l0,2C235.8,323,235.7,323,235.6,323"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000163037938546924302070000002270152280700703414_" class="st1" d="M258,358L258,358c0,0.5-1,9-9,9h0
							C257,367,257.9,358.5,258,358z"/>
					</defs>
					<clipPath id="SVGID_00000028294039320887236570000000829515950300253579_">
						<use xlink:href="#SVGID_00000163037938546924302070000002270152280700703414_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000028294039320887236570000000829515950300253579_);enable-background:new    ;">
						<path class="st34" d="M258,358L258,358c0,0.1,0,0.3-0.1,0.5h0C257.9,358.3,258,358.1,258,358"/>
						<path class="st16" d="M257.9,358.5L257.9,358.5c-0.1,0.5-0.2,1.2-0.5,2h0C257.7,359.7,257.8,359,257.9,358.5"/>
						<path class="st35" d="M257.4,360.5L257.4,360.5c-0.2,0.6-0.5,1.3-0.8,2h0C256.9,361.8,257.2,361.1,257.4,360.5"/>
						<path class="st3" d="M256.6,362.5L256.6,362.5c-1.2,2.3-3.5,4.5-7.6,4.5h0C253.1,367,255.3,364.7,256.6,362.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000008839433097417340530000001692657418379196345_" class="st1" d="M258,328L258,328c0,7,0,28.8,0,30h0
							C258,356.8,258,335,258,328z"/>
					</defs>
					<clipPath id="SVGID_00000084526125045329356390000003669632124999023275_">
						<use xlink:href="#SVGID_00000008839433097417340530000001692657418379196345_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000084526125045329356390000003669632124999023275_);enable-background:new    ;">
						<path class="st33" d="M258,328L258,328c0,7,0,28.8,0,30h0C258,356.8,258,335,258,328"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000158029767982496337870000014468679282103955383_" class="st1" d="M249,321L249,321c0.5,0,9,0,9,7h0
							C258,321,249.4,321,249,321z"/>
					</defs>
					<clipPath id="SVGID_00000041979631673938770840000010062406144767785639_">
						<use xlink:href="#SVGID_00000158029767982496337870000014468679282103955383_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000041979631673938770840000010062406144767785639_);enable-background:new    ;">
						<path class="st16" d="M249,321L249,321c0.1,0,0.2,0,0.3,0h0C249.1,321,249,321,249,321"/>
						<path class="st34" d="M249.3,321L249.3,321c0.4,0,1.2,0.1,2,0.2h0C250.4,321.1,249.7,321,249.3,321"/>
						<path class="st33" d="M251.3,321.2L251.3,321.2c0.6,0.1,1.2,0.3,1.9,0.5h0C252.5,321.5,251.9,321.3,251.3,321.2"/>
						<path class="st32" d="M253.2,321.7L253.2,321.7c0.5,0.2,1.1,0.4,1.6,0.7h0C254.2,322.1,253.7,321.9,253.2,321.7"/>
						<path class="st31" d="M254.7,322.4L254.7,322.4c1.3,0.7,2.4,1.8,2.9,3.4h0C257.1,324.2,256,323.1,254.7,322.4"/>
						<path class="st32" d="M257.7,325.8L257.7,325.8c0.1,0.4,0.2,0.8,0.3,1.2h0C257.9,326.6,257.8,326.2,257.7,325.8"/>
						<path class="st33" d="M257.9,327L257.9,327c0.1,0.3,0.1,0.6,0.1,1h0C258,327.7,258,327.3,257.9,327"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000114034242573498385320000013501215324813227955_" class="st1" d="M249,365l0,2c-8,0-11.8,0-12,0
							c-1.5,0-5.6,0.2-8.5,3.2l-1.8-1c3.8-3.9,8.6-4.2,10.3-4.2C237.2,365,241,365,249,365z"/>
					</defs>
					<clipPath id="SVGID_00000108278317306678976260000011443172538176802493_">
						<use xlink:href="#SVGID_00000114034242573498385320000013501215324813227955_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000108278317306678976260000011443172538176802493_);enable-background:new    ;">
						<path class="st7" d="M249,365l0,2c-8,0-11.8,0-12,0c-0.6,0-1.5,0-2.6,0.2l-0.4-1.9c1.3-0.2,2.4-0.3,3-0.3
							C237.2,365,241,365,249,365"/>
						<path class="st8" d="M234,365.3l0.4,1.9c-0.8,0.1-1.8,0.4-2.7,0.8l-0.9-1.7C231.9,365.8,233,365.5,234,365.3"/>
						<path class="st9" d="M230.8,366.3l0.9,1.7c-0.6,0.2-1.1,0.5-1.7,0.9l-1.3-1.4C229.4,367,230.1,366.6,230.8,366.3"/>
						<path class="st10" d="M228.6,367.5l1.3,1.4c-0.4,0.3-0.9,0.6-1.3,1l-1.7-1.1C227.6,368.3,228.1,367.9,228.6,367.5"/>
						<path class="st11" d="M227,368.8l1.7,1.1c-0.1,0.1-0.2,0.2-0.3,0.3l-1.8-1C226.8,369,226.9,368.9,227,368.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000175298736411671621020000005163813401888892848_" class="st1" d="M236,323l0-2c5,0,12.6,0,13,0l0,2
							C248.6,323,241,323,236,323z"/>
					</defs>
					<clipPath id="SVGID_00000081606014193452207640000008905888908908477571_">
						<use xlink:href="#SVGID_00000175298736411671621020000005163813401888892848_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000081606014193452207640000008905888908908477571_);enable-background:new    ;">
						<path class="st14" d="M236,323l0-2c5,0,12.6,0,13,0l0,2C248.6,323,241,323,236,323"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000069361104130000527430000012826760385690538152_" class="st1" d="M256,358h2c0,0.5-1,9-9,9l0-2
							C255.1,365.1,255.9,358.1,256,358z"/>
					</defs>
					<clipPath id="SVGID_00000140732148224964118980000001260829930667820987_">
						<use xlink:href="#SVGID_00000069361104130000527430000012826760385690538152_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000140732148224964118980000001260829930667820987_);enable-background:new    ;">
						<path class="st51" d="M256,358h2c0,0.1,0,0.2-0.1,0.5l-2-0.2C256,358.1,256,358,256,358"/>
						<path class="st14" d="M255.9,358.3l2,0.2c-0.1,0.5-0.2,1.2-0.5,2.1l-1.9-0.6C255.7,359.2,255.9,358.6,255.9,358.3"/>
						<path class="st13" d="M255.5,359.9l1.9,0.6c-0.2,0.6-0.4,1.2-0.8,1.8l-1.7-1C255.2,360.8,255.4,360.3,255.5,359.9"/>
						<path class="st12" d="M254.9,361.3l1.7,1c-0.2,0.5-0.5,0.9-0.9,1.4l-1.5-1.3C254.5,362.1,254.7,361.7,254.9,361.3"/>
						<path class="st11" d="M254.2,362.4l1.5,1.3c-0.3,0.4-0.6,0.7-1,1.1l-1.3-1.5C253.8,363,254,362.7,254.2,362.4"/>
						<path class="st10" d="M253.5,363.3l1.3,1.5c-0.3,0.3-0.7,0.6-1.1,0.9l-1.1-1.7C253,363.8,253.2,363.5,253.5,363.3"/>
						<path class="st9" d="M252.6,364l1.1,1.7c-0.4,0.3-0.8,0.5-1.3,0.7l-0.8-1.8C252,364.3,252.3,364.2,252.6,364"/>
						<path class="st8" d="M251.7,364.5l0.8,1.8c-0.5,0.2-1.1,0.4-1.7,0.5l-0.4-2C250.8,364.8,251.3,364.7,251.7,364.5"/>
						<path class="st7" d="M250.3,364.9l0.4,2c-0.5,0.1-1.1,0.2-1.8,0.2l0-2C249.5,365,249.9,365,250.3,364.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000125598374654617310450000012392450147827470984_" class="st1" d="M256,328h2c0,7,0,28.8,0,30h-2
							C256,356.8,256,335,256,328z"/>
					</defs>
					<clipPath id="SVGID_00000177475411751548003070000000289587035823426945_">
						<use xlink:href="#SVGID_00000125598374654617310450000012392450147827470984_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000177475411751548003070000000289587035823426945_);enable-background:new    ;">
						<path class="st51" d="M256,328h2c0,7,0,28.8,0,30h-2C256,356.8,256,335,256,328"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000049180371230250731470000007560598993862842300_" class="st1" d="M249,323l0-2c0.4,0,9,0,9,7h-2
							C256.1,323.1,249.3,323,249,323z"/>
					</defs>
					<clipPath id="SVGID_00000034053105793554830890000014773915065827350953_">
						<use xlink:href="#SVGID_00000049180371230250731470000007560598993862842300_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000034053105793554830890000014773915065827350953_);enable-background:new    ;">
						<path class="st14" d="M249,323l0-2c0.1,0,0.3,0,0.7,0l-0.1,2C249.2,323,249,323,249,323"/>
						<path class="st51" d="M249.5,323l0.1-2c0.6,0,1.6,0.1,2.7,0.4l-0.7,1.9C250.8,323.1,250,323.1,249.5,323"/>
						<path class="st52" d="M251.7,323.4l0.7-1.9c0.8,0.2,1.6,0.5,2.3,0.9l-1.2,1.7C252.9,323.7,252.3,323.5,251.7,323.4"/>
						<path class="st53" d="M253.5,324l1.2-1.7c1.4,0.8,2.6,2,3.1,3.9l-1.9,0.5C255.5,325.5,254.6,324.6,253.5,324"/>
						<path class="st52" d="M255.8,326.8l1.9-0.5c0.1,0.5,0.2,1,0.2,1.5l-2,0.1C256,327.5,255.9,327.1,255.8,326.8"/>
						<path class="st51" d="M256,327.9l2-0.1c0,0.1,0,0.1,0,0.2h-2C256,328,256,327.9,256,327.9"/>
					</g>
				</g>
				<g>
					<path class="st147" d="M249,323c0.4,0,7.1,0.1,7,5c0,7,0,28.8,0,30c0,0.1-0.9,7.1-7,7c-8,0-11.8,0-12,0
						c-1.7,0-6.5,0.2-10.3,4.2c-2.2,7.2-4.9,13.9-8.3,20.1c-0.8,3.5-1.7,9.1,1.6,12.5c3.5,3.5,8.9,8.9,9.2,9.2
						c0.3,0.3,5,5.1,1.4,8.5c-4.9,4.9-20.4,20.4-21.2,21.2c0.1-0.1-2.5,1.9-5.5,1.9c-1.4,0-2.9-0.5-4.4-1.9
						c-5.6-5.7-8.3-8.3-8.5-8.5c-0.1-0.2-3.8-4.3-9.5-4.3c-0.7,0-1.2-0.1-2.1,0.2c-5.8,3-11.5,5.3-26.8,10.2
						c7.5-1.4,0.5,1.5,0.4,7.9c0,5,0,12.6,0,13c0,0.4-0.1,7.1-5,7c-7,0-28.9,0-30,0c-0.1,0-7.1-0.9-7-7c0-8,0-11.8,0-12
						c0-0.3,0.5-8-6.2-11.7c-5.8-1.9-11.4-4.1-26.5-12c7.4,5.4,6.5,4.3,2.9,4.3c-2.4,0-5,0.5-6.9,2.4c-3.5,3.5-8.9,8.9-9.2,9.2
						c-0.1,0.1-2.8,2.7-5.6,2.7c-0.9,0-1.9-0.3-2.9-1.3c-4.9-4.9-20.4-20.4-21.2-21.2c-0.1-0.1-4.4-5.6,0-9.9
						c5.7-5.7,8.3-8.3,8.5-8.5c0.2-0.2,5.5-4.8,4.1-11.7c-3.2-5.9-5.5-11.8-7.5-17.5c-1.3-2.4-5.1-8.7-10.6-8.8c-5,0-12.6,0-13,0
						c-0.4,0-7.1-0.1-7-5c0-7,0-28.8,0-30c0-0.1,0.9-7.1,7-7c8,0,11.8,0,12,0c1.8,0,6.6-0.2,10.4-4.3c2.2-7.4,4.9-14.5,8.4-20.9
						c0.8-3.7,1.1-8.5-1.8-11.5c-3.5-3.5-8.9-8.9-9.2-9.2c-0.3-0.3-5-5.1-1.4-8.5c4.9-4.9,20.4-20.4,21.2-21.2
						c-0.1,0.1,2.5-1.9,5.5-1.9c1.4,0,2.9,0.5,4.4,1.9c5.7,5.7,8.3,8.3,8.5,8.5c0.1,0.1,3.4,3.9,9.2,4.3c7.5-4.4,15.3-7.5,23.1-9.7
						c3-2.2,5.7-5,5.7-8.6c0-5,0-12.6,0-13c0-0.4,0.1-7.1,5-7c7,0,28.9,0,30,0c0.1,0,7.1,0.9,7,7c0,8,0,11.8,0,12
						c0,0.2-0.3,4.8,3.1,9.2c8.6,2.5,16.6,5.7,23.8,9.8c1.5,0.3,2.1,0.4,2.9,0.3c2.4,0,5-0.5,6.9-2.4c3.5-3.5,8.9-8.9,9.2-9.2
						c0.1-0.1,2.8-2.7,5.6-2.7c0.9,0,1.9,0.3,2.9,1.3c4.9,5,20.4,20.4,21.2,21.2c0.1,0.1,4.4,5.6,0,9.9c-5.7,5.7-8.3,8.3-8.5,8.5
						c-0.1,0.1-3.8,3.3-4.4,9.1c4.2,7.4,7.2,15.1,9.3,22.7c2.2,3.1,5.1,6.2,8.9,6.2C241,323,248.6,323,249,323z M134,425
						c44.7,0,81-36.3,81-81c0-44.7-36.3-81-81-81c-44.7,0-81,36.3-81,81C53,388.7,89.3,425,134,425"/>
				</g>
			</g>
		</g>
	</g>
</g>
<g class="${classes.rotatingCog}">
	<path class="st0" d="M680.1,685.5l-0.4,0c-0.6-0.1-4-0.5-7.5-2.5c-5.5-3-8.6-8.3-8.6-14.5v-12.4l0,0c0-0.4-0.1-1.6-0.7-2.2
		c-4.9-1.6-9.8-3.5-14.4-5.9l-0.7-0.2c-0.5-0.2-2.4-0.7-4-0.7c-0.2,0-0.3,0-0.4,0l-8.6,8.6c-0.9,0.9-6,5.6-12.7,5.6
		c-3.8,0-7.2-1.5-10-4.2l-21.5-21.5l-0.3-0.3c-0.4-0.5-2.5-3.2-3.6-7c-1.7-6-0.2-12,4.2-16.3l8.8-8.8l0,0c0.3-0.3,0.8-1,1-1.7
		c-2.7-5.2-5-10.7-6.9-16.2c-0.8-1.3-1.6-2.2-2.1-2.7h-12.2c-8.4,0-17-5.2-17-15v-30.4l0-0.4c0.1-0.6,0.5-4,2.5-7.5
		c3-5.5,8.3-8.6,14.5-8.6h12.4l0,0c0.4,0,0.8-0.1,1.2-0.2c2-6.4,4.5-12.6,7.6-18.5c0.1-0.7,0.1-1.2,0-1.5l-8.6-8.6
		c-0.4-0.4-2.7-2.8-4.2-6.2c-2.6-5.9-1.5-12,2.8-16.4l21.5-21.5l0.3-0.3c0.9-0.7,5.7-4.3,11.9-4.3c4.3,0,8.3,1.7,11.5,4.9l8.8,8.8
		l0,0c0.1,0.1,0.3,0.2,0.5,0.4c6.3-3.4,13-6.1,19.9-8.2c0.1-0.1,0.1-0.2,0.2-0.2v-12.2c0-8.4,5.2-17,15-17h30.4l0.4,0
		c0.6,0.1,4,0.5,7.5,2.5c5.5,3,8.6,8.3,8.6,14.5v12.4l0,0c0,0.1,0,0.2,0,0.3c7,2.2,13.7,5,20.1,8.6c0,0,0,0,0,0l8.6-8.6
		c0.9-0.9,6-5.6,12.7-5.6c3.8,0,7.2,1.5,10,4.2l21.5,21.5l0.3,0.3c0.4,0.5,2.5,3.2,3.6,7c1.7,6,0.2,12-4.2,16.3l-8.8,8.8l0,0
		c-0.1,0.1-0.2,0.3-0.4,0.5c3.3,6.3,5.9,12.9,7.8,19.6c0.2,0.2,0.4,0.4,0.5,0.5h12.2c8.4,0,17,5.2,17,15v30.4l0,0.4
		c-0.1,0.6-0.5,4-2.5,7.5c-3,5.5-8.3,8.6-14.5,8.6h-12.4l0,0c-0.3,0-0.8,0.1-1.2,0.2c-2,6.1-4.5,12.1-7.6,17.8
		c-0.2,1.1-0.2,1.8-0.2,2.3l8.6,8.6c0.4,0.4,2.7,2.8,4.2,6.2c2.6,5.9,1.5,12-2.8,16.4l-21.5,21.5l-0.3,0.3
		c-0.9,0.7-5.7,4.3-11.9,4.3c-4.3,0-8.3-1.7-11.5-4.9l-8.8-8.8l0,0c-0.4-0.4-1.1-0.8-1.6-1c-5,2.6-10.3,4.8-15.7,6.5
		c-1.2,0.7-2.7,1.8-3.3,2.5v12.2c0,8.4-5.2,17-15,17H680.1z M696.5,482.5c-39.1,0-71,31.9-71,71s31.9,71,71,71s71-31.9,71-71
		S735.6,482.5,696.5,482.5z"/>
	<g>
		<g>
			<g class="st1">
				<g class="st1">
					<defs>
						<path id="SVGID_00000146476676447933835420000017956589266492874151_" class="st1" d="M619.2,454.9c-0.1-0.1-0.3-0.3-0.4-0.4
							L619.2,454.9C619.2,454.9,619.2,454.9,619.2,454.9z"/>
					</defs>
					<clipPath id="SVGID_00000011017631271068574310000000340214292803578256_">
						<use xlink:href="#SVGID_00000146476676447933835420000017956589266492874151_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000011017631271068574310000000340214292803578256_);enable-background:new    ;">
						<polyline class="st3" points="618.7,454.5 619.2,454.9 619.2,454.9 618.7,454.5 618.7,454.5 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000070804439413652173450000000098145109986680248_" class="st1" d="M572.5,567.5h-2c0-7,0-28.8,0-30h2
							C572.5,538.7,572.5,560.5,572.5,567.5z"/>
					</defs>
					<clipPath id="SVGID_00000123434049470594863290000017536148883372443582_">
						<use xlink:href="#SVGID_00000070804439413652173450000000098145109986680248_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000123434049470594863290000017536148883372443582_);enable-background:new    ;">
						<path class="st5" d="M572.5,567.5h-2c0-7,0-28.8,0-30h2C572.5,538.7,572.5,560.5,572.5,567.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000127017298279093363380000008140952209669624203_" class="st1" d="M572.5,537.5h-2c0-0.5,1-9,9-9l0,2
							C573.4,530.4,572.5,537.4,572.5,537.5z"/>
					</defs>
					<clipPath id="SVGID_00000144324351285131344810000010507684522184854415_">
						<use xlink:href="#SVGID_00000127017298279093363380000008140952209669624203_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000144324351285131344810000010507684522184854415_);enable-background:new    ;">
						<path class="st7" d="M572.5,537.5h-2c0-0.2,0.2-1.7,0.9-3.5l1.8,0.8C572.7,536.3,572.5,537.4,572.5,537.5"/>
						<path class="st8" d="M573.3,534.9l-1.8-0.8c0.3-0.7,0.7-1.4,1.2-2.1l1.6,1.3C573.8,533.8,573.5,534.3,573.3,534.9"/>
						<path class="st9" d="M574.2,533.2l-1.6-1.3c0.3-0.5,0.7-0.9,1.1-1.3l1.3,1.5C574.7,532.5,574.4,532.8,574.2,533.2"/>
						<path class="st10" d="M575,532.2l-1.3-1.5c0.3-0.3,0.7-0.6,1.1-0.9l1.1,1.7C575.6,531.7,575.3,531.9,575,532.2"/>
						<path class="st11" d="M575.9,531.5l-1.1-1.7c0.3-0.2,0.7-0.4,1.1-0.6l0.8,1.8C576.4,531.2,576.2,531.3,575.9,531.5"/>
						<path class="st12" d="M576.7,531l-0.8-1.8c0.4-0.2,0.8-0.3,1.2-0.4l0.6,1.9C577.3,530.8,577,530.9,576.7,531"/>
						<path class="st13" d="M577.6,530.7l-0.6-1.9c0.4-0.1,0.9-0.2,1.4-0.2l0.2,2C578.3,530.6,578,530.6,577.6,530.7"/>
						<path class="st14" d="M578.7,530.5l-0.2-2c0.3,0,0.7-0.1,1-0.1l0,2C579.2,530.5,579,530.5,578.7,530.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000115504514064918994650000005003622562043252902_" class="st1" d="M591.6,528.5L591.6,528.5
							C591.6,528.5,591.5,528.5,591.6,528.5L591.6,528.5C591.5,528.5,591.6,528.5,591.6,528.5z"/>
					</defs>
					<clipPath id="SVGID_00000002374852224953605320000011866927523956537272_">
						<use xlink:href="#SVGID_00000115504514064918994650000005003622562043252902_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000002374852224953605320000011866927523956537272_);enable-background:new    ;">
						<path class="st16" d="M591.5,528.5L591.5,528.5C591.5,528.5,591.6,528.5,591.5,528.5L591.5,528.5
							C591.6,528.5,591.5,528.5,591.5,528.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000020358683024157635070000010375082255417812630_" class="st1" d="M592.5,572.5l0,2c-5,0-12.6,0-13,0
							c-0.4,0-9,0-9-7h2c-0.1,4.9,6.6,5,7,5C579.9,572.5,587.5,572.5,592.5,572.5z"/>
					</defs>
					<clipPath id="SVGID_00000105386960548356772430000015114273998446068366_">
						<use xlink:href="#SVGID_00000020358683024157635070000010375082255417812630_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000105386960548356772430000015114273998446068366_);enable-background:new    ;">
						<path class="st7" d="M592.5,572.5l0,2c-5,0-12.6,0-13,0c-0.1,0-0.7,0-1.5-0.1l0.3-2c0.6,0.1,1.1,0.1,1.1,0.1
							C579.9,572.5,587.5,572.5,592.5,572.5"/>
						<path class="st5" d="M578.4,572.4l-0.3,2c-1.5-0.2-3.9-0.7-5.6-2.2l1.5-1.4C575.3,571.9,577.2,572.3,578.4,572.4"/>
						<path class="st18" d="M574,570.8l-1.5,1.4c-0.5-0.5-1-1.1-1.4-1.8l1.8-0.8C573.2,570,573.5,570.4,574,570.8"/>
						<path class="st5" d="M572.9,569.5l-1.8,0.8c-0.4-0.8-0.6-1.7-0.6-2.8h2C572.5,568.3,572.6,568.9,572.9,569.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000088833612494993683770000012711638720792093608_" class="st1" d="M646.4,637c0,0.1,0.1,0.2,0.1,0.2
							c0.2,0.2,0.4,0.3,0.6,0.4c-1.9-1.4-4.4-3.4-6.2-4.8C644.5,635.5,646.1,636.6,646.4,637z"/>
					</defs>
					<clipPath id="SVGID_00000069397337409847883750000004049357068748612520_">
						<use xlink:href="#SVGID_00000088833612494993683770000012711638720792093608_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000069397337409847883750000004049357068748612520_);enable-background:new    ;">
						<path class="st9" d="M647.1,637.6l-6.2-4.8c0,0,0.1,0,0.1,0.1L647.1,637.6C647.1,637.6,647.1,637.6,647.1,637.6"/>
						<path class="st8" d="M647.1,637.6l-6.2-4.8c0.1,0.1,0.2,0.1,0.2,0.2L647.1,637.6C647.2,637.7,647.1,637.7,647.1,637.6"/>
						<path class="st7" d="M647.2,637.7l-6-4.7c0.1,0.1,0.2,0.1,0.2,0.2L647.2,637.7C647.3,637.7,647.2,637.7,647.2,637.7"/>
						<path class="st5" d="M647.3,637.8l-5.9-4.6c0.1,0.1,0.2,0.1,0.2,0.2L647.3,637.8C647.3,637.8,647.3,637.8,647.3,637.8"/>
						<path class="st18" d="M647.3,637.8l-5.7-4.4c0.1,0.1,0.1,0.1,0.2,0.2L647.3,637.8C647.4,637.9,647.4,637.8,647.3,637.8"/>
						<path class="st20" d="M647.4,637.9l-5.6-4.3c0,0,0.1,0.1,0.1,0.1L647.4,637.9C647.4,637.9,647.4,637.9,647.4,637.9"/>
						<path class="st21" d="M647.5,637.9l-5.5-4.3c0,0,0.1,0.1,0.1,0.1L647.5,637.9C647.5,637.9,647.5,637.9,647.5,637.9"/>
						<path class="st22" d="M647.5,637.9l-5.4-4.2c0,0,0.1,0,0.1,0.1L647.5,637.9C647.5,638,647.5,637.9,647.5,637.9"/>
						<path class="st23" d="M647.5,638l-5.3-4.2c0,0,0.1,0,0.1,0.1L647.5,638C647.5,638,647.5,638,647.5,638"/>
						<path class="st24" d="M647.5,638l-5.3-4.1c0,0,0.1,0,0.1,0.1L647.5,638C647.6,638,647.6,638,647.5,638"/>
						<path class="st25" d="M647.6,638l-5.2-4.1c0,0,0.1,0,0.1,0.1L647.6,638C647.6,638,647.6,638,647.6,638"/>
						<path class="st26" d="M647.6,638l-5.1-4c0,0,0.1,0,0.1,0.1L647.6,638C647.6,638.1,647.6,638,647.6,638"/>
						<path class="st27" d="M647.6,638.1l-5.1-4c0,0,0.1,0,0.1,0.1L647.6,638.1C647.7,638.1,647.6,638.1,647.6,638.1"/>
						<path class="st28" d="M647.7,638.1l-5-3.9c0,0,0.1,0,0.1,0.1L647.7,638.1C647.7,638.1,647.7,638.1,647.7,638.1"/>
						<path class="st29" d="M647.7,638.1l-4.9-3.9c0,0,0.1,0,0.1,0.1L647.7,638.1C647.7,638.1,647.7,638.1,647.7,638.1"/>
						<path class="st30" d="M647.7,638.1l-4.9-3.9c0,0,0.1,0,0.1,0.1L647.7,638.1C647.7,638.1,647.7,638.1,647.7,638.1"/>
						<path class="st31" d="M647.7,638.2l-4.8-3.8c0,0,0.1,0,0.1,0.1L647.7,638.2C647.8,638.2,647.8,638.2,647.7,638.2"/>
						<path class="st32" d="M647.8,638.2l-4.7-3.8c0,0,0.1,0,0.1,0.1L647.8,638.2C647.8,638.2,647.8,638.2,647.8,638.2"/>
						<path class="st33" d="M647.8,638.2l-4.6-3.7c0,0,0.1,0,0.1,0.1L647.8,638.2C647.8,638.2,647.8,638.2,647.8,638.2"/>
						<path class="st34" d="M647.8,638.2l-4.6-3.7c0,0,0.1,0,0.1,0.1L647.8,638.2C647.8,638.2,647.8,638.2,647.8,638.2"/>
						<path class="st16" d="M647.8,638.2l-4.5-3.6c0,0,0.1,0,0.1,0.1L647.8,638.2C647.9,638.3,647.8,638.3,647.8,638.2"/>
						<path class="st35" d="M647.9,638.3l-4.4-3.6c0,0,0.1,0,0.1,0.1L647.9,638.3C647.9,638.3,647.9,638.3,647.9,638.3"/>
						<path class="st3" d="M647.9,638.3l-4.3-3.5c1.8,1.3,2.7,1.9,2.9,2.2l1.3,1.8C648.2,638.8,648.3,638.7,647.9,638.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000101781543699729173690000012389202465113905071_" class="st1" d="M597.9,628.9l-1.4,1.4
							c-0.3-0.4-5.7-7.1,0-12.7l1.4,1.4C593.5,623.3,597.9,628.8,597.9,628.9z"/>
					</defs>
					<clipPath id="SVGID_00000111154101905575717600000003919015500799664008_">
						<use xlink:href="#SVGID_00000101781543699729173690000012389202465113905071_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000111154101905575717600000003919015500799664008_);enable-background:new    ;">
						<path class="st18" d="M597.9,628.9l-1.4,1.4c-0.1-0.1-1-1.3-1.7-3l1.9-0.7C597.2,627.9,597.9,628.8,597.9,628.9"/>
						<path class="st5" d="M596.6,626.6l-1.9,0.7c-0.5-1.3-1-2.9-0.8-4.7l2,0.3C595.9,624.3,596.2,625.5,596.6,626.6"/>
						<path class="st7" d="M596,622.9l-2-0.3c0.1-0.7,0.2-1.4,0.6-2.2l1.9,0.7C596.2,621.8,596,622.3,596,622.9"/>
						<path class="st8" d="M596.4,621.2l-1.9-0.7c0.2-0.6,0.5-1.1,0.9-1.6l1.6,1.1C596.8,620.3,596.6,620.8,596.4,621.2"/>
						<path class="st9" d="M597.1,619.9l-1.6-1.1c0.3-0.4,0.7-0.8,1.1-1.2l1.4,1.4C597.6,619.3,597.3,619.6,597.1,619.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000098222576209042641090000005468920274469858970_" class="st1" d="M579.5,530.5l0-2c8,0,11.8,0,12,0
							c1.5,0,5.7-0.2,8.6-3.3l1.8,1c-3.8,4-8.6,4.3-10.4,4.3C591.3,530.5,587.5,530.5,579.5,530.5z"/>
					</defs>
					<clipPath id="SVGID_00000124844361239853420020000014431947311455750822_">
						<use xlink:href="#SVGID_00000098222576209042641090000005468920274469858970_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000124844361239853420020000014431947311455750822_);enable-background:new    ;">
						<path class="st14" d="M579.5,530.5l0-2c8,0,11.8,0,12,0c0.4,0,0.9,0,1.5-0.1l0.2,2c-0.7,0.1-1.3,0.1-1.7,0.1
							C591.3,530.5,587.5,530.5,579.5,530.5"/>
						<path class="st13" d="M593.2,530.4l-0.2-2c0.6-0.1,1.3-0.2,2.1-0.4l0.6,1.9C594.7,530.2,593.9,530.3,593.2,530.4"/>
						<path class="st12" d="M595.6,530l-0.6-1.9c0.6-0.1,1.1-0.3,1.7-0.6l0.9,1.7C597,529.5,596.3,529.8,595.6,530"/>
						<path class="st11" d="M597.7,529.2l-0.9-1.7c0.5-0.2,1-0.5,1.5-0.8l1.3,1.5C598.9,528.6,598.3,528.9,597.7,529.2"/>
						<path class="st10" d="M599.5,528.2l-1.3-1.5c0.5-0.3,0.9-0.6,1.3-1l1.6,1.2C600.7,527.4,600.1,527.8,599.5,528.2"/>
						<path class="st9" d="M601.2,526.9l-1.6-1.2c0.2-0.2,0.3-0.3,0.5-0.5l1.8,1C601.7,526.4,601.5,526.7,601.2,526.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000100378142220617521550000011646328274235358907_" class="st1" d="M603.1,581.3l-1.9,0.6
							c-1.1-1.9-4.5-7.4-8.7-7.4l0-2C597.9,572.6,601.8,578.9,603.1,581.3z"/>
					</defs>
					<clipPath id="SVGID_00000011718705242157589600000015462601431172064691_">
						<use xlink:href="#SVGID_00000100378142220617521550000011646328274235358907_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000011718705242157589600000015462601431172064691_);enable-background:new    ;">
						<path class="st18" d="M603.1,581.3l-1.9,0.6c-0.8-1.3-2.7-4.5-5.3-6.2l0.9-1.8C600,575.9,602.1,579.6,603.1,581.3"/>
						<path class="st5" d="M596.8,573.9l-0.9,1.8c-0.9-0.6-1.9-1-2.9-1.1l0.1-2C594.5,572.7,595.7,573.2,596.8,573.9"/>
						<path class="st7" d="M593.2,572.5l-0.1,2c-0.2,0-0.3,0-0.5,0l0-2C592.7,572.5,592.9,572.5,593.2,572.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000127041745808813024180000013081143797739932320_" class="st1" d="M597.9,619l-1.4-1.4
							c5.7-5.7,8.3-8.3,8.5-8.5l1.4,1.4C606.3,610.6,603.6,613.3,597.9,619z"/>
					</defs>
					<clipPath id="SVGID_00000124150888997843491000000009737014071727806892_">
						<use xlink:href="#SVGID_00000127041745808813024180000013081143797739932320_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000124150888997843491000000009737014071727806892_);enable-background:new    ;">
						<path class="st9" d="M597.9,619l-1.4-1.4c5.7-5.7,8.3-8.3,8.5-8.5l1.4,1.4C606.3,610.6,603.6,613.3,597.9,619"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000051352861949101413030000003790158493738954665_" class="st1" d="M608.5,493.8l-1.4,1.4
							c-3.5-3.5-8.9-8.9-9.2-9.2c-0.3-0.3-6.4-6.4-1.4-11.3l1.4,1.4c-3.5,3.4,1.2,8.2,1.4,8.5C599.6,484.9,605,490.3,608.5,493.8z"
							/>
					</defs>
					<clipPath id="SVGID_00000067202806681188859360000009847399923455675554_">
						<use xlink:href="#SVGID_00000051352861949101413030000003790158493738954665_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000067202806681188859360000009847399923455675554_);enable-background:new    ;">
						<path class="st18" d="M608.5,493.8l-1.4,1.4c-3.5-3.5-8.9-8.9-9.2-9.2c-0.1-0.1-1.5-1.5-2.5-3.5l1.9-0.7
							c0.8,1.5,1.9,2.6,2,2.8C599.6,484.9,605,490.3,608.5,493.8"/>
						<path class="st5" d="M597.4,481.9l-1.9,0.7c-0.6-1.2-1-2.6-0.8-4l2,0.3C596.6,479.9,596.9,480.9,597.4,481.9"/>
						<path class="st7" d="M596.6,478.8l-2-0.3c0.1-0.5,0.2-1.1,0.4-1.6l1.9,0.8C596.7,478,596.6,478.4,596.6,478.8"/>
						<path class="st8" d="M596.9,477.6l-1.9-0.8c0.2-0.4,0.4-0.8,0.7-1.2l1.6,1.1C597.2,477.1,597,477.3,596.9,477.6"/>
						<path class="st9" d="M597.4,476.8l-1.6-1.1c0.2-0.3,0.5-0.6,0.8-0.9l1.4,1.4C597.7,476.3,597.5,476.6,597.4,476.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000171697212315679148790000004392578630757742783_" class="st1" d="M601.9,526.2l-1.8-1
							c2-7.3,4.9-14.3,8.4-20.9l1.8,1C606.8,511.7,604.1,518.8,601.9,526.2z"/>
					</defs>
					<clipPath id="SVGID_00000177457466951993312010000007432452110885953185_">
						<use xlink:href="#SVGID_00000171697212315679148790000004392578630757742783_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000177457466951993312010000007432452110885953185_);enable-background:new    ;">
						<path class="st7" d="M601.9,526.2l-1.8-1c0.9-3.4,2-6.7,3.3-9.9l1.9,0.9C604,519.4,602.9,522.8,601.9,526.2"/>
						<path class="st8" d="M605.3,516.2l-1.9-0.9c1.5-3.8,3.2-7.4,5.1-11l1.8,1C608.4,508.8,606.8,512.4,605.3,516.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000114768429230993310700000017660765852113552558_" class="st1" d="M610.5,598.8l-2,0.4
							c-3-5.5-5.5-11.3-7.4-17.3l1.9-0.6C605,587,607.4,592.9,610.5,598.8z"/>
					</defs>
					<clipPath id="SVGID_00000116958326048845806320000002181833968491947451_">
						<use xlink:href="#SVGID_00000114768429230993310700000017660765852113552558_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000116958326048845806320000002181833968491947451_);enable-background:new    ;">
						<path class="st18" d="M610.5,598.8l-2,0.4c-0.9-1.7-1.8-3.4-2.6-5.2l1.9-0.7C608.7,595.1,609.6,597,610.5,598.8"/>
						<path class="st5" d="M607.8,593.3l-1.9,0.7c-1.8-3.9-3.4-8-4.8-12.2l1.9-0.6C604.4,585.2,606,589.3,607.8,593.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000112627557471906153490000002508408461303447453_" class="st1" d="M610.3,505.3l-1.8-1
							c0.5-2.8,0.8-6.9-1.4-9.1l1.4-1.4C611.5,496.8,611.1,501.6,610.3,505.3z"/>
					</defs>
					<clipPath id="SVGID_00000154411467894753408650000001763545423525065114_">
						<use xlink:href="#SVGID_00000112627557471906153490000002508408461303447453_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000154411467894753408650000001763545423525065114_);enable-background:new    ;">
						<path class="st7" d="M610.3,505.3l-1.8-1c0.1-0.3,0.1-0.7,0.2-1l1.9,0.7C610.5,504.5,610.4,504.9,610.3,505.3"/>
						<path class="st5" d="M610.6,504l-1.9-0.7c0.3-2,0.3-4.3-0.4-6.2l1.8-0.8C611.1,498.7,611,501.5,610.6,504"/>
						<path class="st18" d="M610.1,496.3l-1.8,0.8c-0.3-0.7-0.7-1.4-1.2-1.9l1.4-1.4C609.2,494.5,609.8,495.4,610.1,496.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000075843065065326297200000005077064250185585086_" class="st1" d="M606.4,610.5l-1.4-1.4
							c0.2-0.2,4.7-4.2,3.6-9.9l2-0.4C611.9,605.7,606.6,610.3,606.4,610.5z"/>
					</defs>
					<clipPath id="SVGID_00000092443420639280121060000010990585310233577101_">
						<use xlink:href="#SVGID_00000075843065065326297200000005077064250185585086_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000092443420639280121060000010990585310233577101_);enable-background:new    ;">
						<path class="st10" d="M606.4,610.5l-1.4-1.4c0,0,0.1-0.1,0.1-0.1l1.4,1.4C606.5,610.4,606.4,610.5,606.4,610.5"/>
						<path class="st9" d="M606.6,610.3l-1.4-1.4c0.3-0.3,0.9-0.8,1.5-1.7l1.6,1.1C607.5,609.4,606.9,610.1,606.6,610.3"/>
						<path class="st8" d="M608.3,608.4l-1.6-1.1c0.5-0.6,0.9-1.4,1.3-2.3l1.9,0.7C609.4,606.7,608.8,607.6,608.3,608.4"/>
						<path class="st7" d="M609.8,605.6l-1.9-0.7c0.4-0.8,0.6-1.8,0.7-2.8l2,0.2C610.6,603.5,610.2,604.6,609.8,605.6"/>
						<path class="st5" d="M610.7,602.2l-2-0.2c0.1-0.9,0.1-1.8-0.1-2.8l2-0.4C610.8,600,610.8,601.2,610.7,602.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000070813976505812881780000005765709717160818820_" class="st1" d="M619.1,650.1l-1.4,1.4
							c-4.9-5-20.4-20.4-21.2-21.2l1.4-1.4C598.7,629.7,614.2,645.1,619.1,650.1z"/>
					</defs>
					<clipPath id="SVGID_00000017480076730436651630000004616862467563480975_">
						<use xlink:href="#SVGID_00000070813976505812881780000005765709717160818820_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000017480076730436651630000004616862467563480975_);enable-background:new    ;">
						<path class="st18" d="M619.1,650.1l-1.4,1.4c-4.9-5-20.4-20.4-21.2-21.2l1.4-1.4C598.7,629.7,614.2,645.1,619.1,650.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000145748174828930528480000005522064516685924498_" class="st1" d="M618.7,454.5l0.4,0.4c0,0,0,0,0,0
							C619,454.8,618.9,454.6,618.7,454.5z"/>
					</defs>
					<clipPath id="SVGID_00000116237679157488440800000000593559172342822034_">
						<use xlink:href="#SVGID_00000145748174828930528480000005522064516685924498_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000116237679157488440800000000593559172342822034_);enable-background:new    ;">
						<polyline class="st10" points="619.1,454.9 618.7,454.5 618.7,454.5 619.1,454.9 619.1,454.9 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000031198474216605109840000005957408354415402136_" class="st1" d="M597.9,476.1l-1.4-1.4
							c4.9-4.9,20.4-20.4,21.2-21.2l1.4,1.4C618.3,455.7,602.9,471.2,597.9,476.1z"/>
					</defs>
					<clipPath id="SVGID_00000167391541682337847020000015666743591717908876_">
						<use xlink:href="#SVGID_00000031198474216605109840000005957408354415402136_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000167391541682337847020000015666743591717908876_);enable-background:new    ;">
						<path class="st9" d="M597.9,476.1l-1.4-1.4c4.9-4.9,20.4-20.4,21.2-21.2l1.4,1.4C618.3,455.7,602.9,471.2,597.9,476.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000109031907722846849930000005528784692100515712_" class="st1" d="M629,650.1L629,650.1
							c-0.2,0.2-3.3,3.3-7,3.3h0C625.7,653.4,628.9,650.2,629,650.1z"/>
					</defs>
					<clipPath id="SVGID_00000158010208281196491600000014168624146214432405_">
						<use xlink:href="#SVGID_00000109031907722846849930000005528784692100515712_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000158010208281196491600000014168624146214432405_);enable-background:new    ;">
						<path class="st3" d="M629,650.1L629,650.1c-0.2,0.2-3.3,3.3-7,3.3h0C625.7,653.4,628.9,650.2,629,650.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000042713711280658340890000010082836878895746221_" class="st1" d="M624.6,451L624.6,451
							c1.9,0,3.9,0.6,5.8,2.5h0C628.6,451.6,626.5,451,624.6,451z"/>
					</defs>
					<clipPath id="SVGID_00000011737041909100982100000000925192533037657756_">
						<use xlink:href="#SVGID_00000042713711280658340890000010082836878895746221_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000011737041909100982100000000925192533037657756_);enable-background:new    ;">
						<path class="st16" d="M624.6,451L624.6,451c0.1,0,0.2,0,0.3,0h0C624.8,451,624.7,451,624.6,451"/>
						<path class="st34" d="M624.9,451L624.9,451c0.4,0,0.8,0.1,1.2,0.1h0C625.7,451,625.3,451,624.9,451"/>
						<path class="st33" d="M626.1,451.1L626.1,451.1c0.4,0.1,0.8,0.2,1.1,0.3h0C626.8,451.3,626.4,451.2,626.1,451.1"/>
						<path class="st32" d="M627.2,451.4L627.2,451.4c0.4,0.1,0.9,0.3,1.3,0.6h0C628,451.7,627.6,451.6,627.2,451.4"/>
						<path class="st31" d="M628.5,452L628.5,452c0.7,0.4,1.3,0.9,2,1.5h0C629.8,452.9,629.1,452.4,628.5,452"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000145768112693827855540000001171083691767490464_" class="st1" d="M619.1,454.9c-0.1-0.1-0.3-0.3-0.4-0.4
							l-1-1c0.2-0.1,3.1-2.5,6.9-2.5c1.9,0,3.9,0.6,5.8,2.5l-1.4,1.4c-1.5-1.5-3-1.9-4.4-1.9C621.6,453,619.1,454.9,619.1,454.9z"/>
					</defs>
					<clipPath id="SVGID_00000076598825005108902470000005737265367063179938_">
						<use xlink:href="#SVGID_00000145768112693827855540000001171083691767490464_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000076598825005108902470000005737265367063179938_);enable-background:new    ;">
						<path class="st10" d="M619.1,454.9l-1.4-1.4c0,0,0.3-0.2,0.7-0.5l1.2,1.6C619.3,454.8,619.1,454.9,619.1,454.9"/>
						<path class="st11" d="M619.6,454.6l-1.2-1.6c0.5-0.3,1.1-0.7,1.9-1.1l0.8,1.8C620.5,454.1,620,454.4,619.6,454.6"/>
						<path class="st12" d="M621.2,453.8l-0.8-1.8c0.5-0.2,1.1-0.5,1.8-0.6l0.5,1.9C622.1,453.4,621.6,453.6,621.2,453.8"/>
						<path class="st13" d="M622.6,453.3l-0.5-1.9c0.5-0.1,1-0.2,1.6-0.3l0.2,2C623.5,453.1,623,453.2,622.6,453.3"/>
						<path class="st14" d="M623.9,453l-0.2-2c0.3,0,0.6,0,0.9,0c0.2,0,0.4,0,0.6,0l-0.1,2c-0.1,0-0.3,0-0.4,0
							C624.4,453,624.1,453,623.9,453"/>
						<path class="st51" d="M625.1,453l0.1-2c0.4,0,0.9,0.1,1.4,0.2l-0.5,1.9C625.7,453.1,625.4,453,625.1,453"/>
						<path class="st52" d="M626.1,453.2l0.5-1.9c0.5,0.1,1,0.3,1.6,0.6l-0.9,1.8C626.9,453.4,626.5,453.3,626.1,453.2"/>
						<path class="st53" d="M627.3,453.6l0.9-1.8c0.8,0.4,1.6,0.9,2.3,1.7l-1.4,1.4C628.4,454.3,627.8,453.9,627.3,453.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000034773099765291092070000000833375843293393034_" class="st1" d="M638.2,640.9L638.2,640.9
							c-3.5,3.5-8.9,8.9-9.2,9.2l0,0C629.3,649.8,634.7,644.4,638.2,640.9z"/>
					</defs>
					<clipPath id="SVGID_00000127734723389590867600000011180621472721848757_">
						<use xlink:href="#SVGID_00000034773099765291092070000000833375843293393034_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000127734723389590867600000011180621472721848757_);enable-background:new    ;">
						<path class="st3" d="M638.2,640.9L638.2,640.9c-3.5,3.5-8.9,8.9-9.2,9.2h0C629.3,649.8,634.7,644.4,638.2,640.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000159438449221585937930000004633848736326920342_" class="st1" d="M630.5,453.5L630.5,453.5
							c5.7,5.7,8.4,8.3,8.5,8.5l0,0C638.8,461.9,636.1,459.2,630.5,453.5z"/>
					</defs>
					<clipPath id="SVGID_00000168821080510000578050000008117588764494928307_">
						<use xlink:href="#SVGID_00000159438449221585937930000004633848736326920342_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000168821080510000578050000008117588764494928307_);enable-background:new    ;">
						<path class="st31" d="M630.5,453.5L630.5,453.5c5.7,5.7,8.4,8.3,8.5,8.5h0C638.8,461.9,636.1,459.2,630.5,453.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000124880870875842280640000009534318101965054097_" class="st1" d="M643.7,639.1L643.7,639.1
							c-2,0-4.1,0.4-5.5,1.8l0,0C639.6,639.5,641.7,639.1,643.7,639.1z"/>
					</defs>
					<clipPath id="SVGID_00000013186494688766485580000013954967706155157426_">
						<use xlink:href="#SVGID_00000124880870875842280640000009534318101965054097_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000013186494688766485580000013954967706155157426_);enable-background:new    ;">
						<path class="st3" d="M643.7,639.1L643.7,639.1c-2,0-4.1,0.4-5.5,1.8h0C639.6,639.5,641.7,639.1,643.7,639.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000101101079025030139060000004303052944288429446_" class="st1" d="M636.8,639.5l1.4,1.4
							c-3.5,3.5-8.9,8.9-9.2,9.2c-0.2,0.2-3.3,3.3-7,3.3c-1.4,0-2.9-0.5-4.3-1.9l1.4-1.4c1,1,2,1.3,2.9,1.3c2.8,0,5.5-2.6,5.6-2.7
							C627.9,648.4,633.3,643,636.8,639.5z"/>
					</defs>
					<clipPath id="SVGID_00000045583495379966723590000018180224771939275934_">
						<use xlink:href="#SVGID_00000101101079025030139060000004303052944288429446_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000045583495379966723590000018180224771939275934_);enable-background:new    ;">
						<path class="st11" d="M636.8,639.5l1.4,1.4c-3.5,3.5-8.9,8.9-9.2,9.2c0,0,0,0-0.1,0.1l-1.4-1.4c0,0,0,0,0.1-0.1
							C627.9,648.4,633.3,643,636.8,639.5"/>
						<path class="st10" d="M627.6,648.7l1.4,1.4c-0.2,0.2-0.9,0.8-1.8,1.5l-1.1-1.7C626.9,649.4,627.4,648.9,627.6,648.7"/>
						<path class="st9" d="M626.1,649.9l1.1,1.7c-0.6,0.4-1.3,0.8-2,1.1l-0.7-1.9C625.1,650.6,625.6,650.2,626.1,649.9"/>
						<path class="st8" d="M624.5,650.8l0.7,1.9c-0.6,0.2-1.2,0.4-1.9,0.6l-0.3-2C623.5,651.2,624,651,624.5,650.8"/>
						<path class="st7" d="M623,651.3l0.3,2c-0.4,0.1-0.8,0.1-1.2,0.1c-0.2,0-0.4,0-0.7,0l0.2-2c0.1,0,0.3,0,0.4,0
							C622.4,651.4,622.7,651.4,623,651.3"/>
						<path class="st5" d="M621.6,651.4l-0.2,2c-0.9-0.1-1.8-0.4-2.7-1.1l1.1-1.6C620.4,651.1,621,651.3,621.6,651.4"/>
						<path class="st18" d="M619.8,650.6l-1.1,1.6c-0.3-0.2-0.6-0.5-0.9-0.8l1.4-1.4C619.4,650.3,619.6,650.5,619.8,650.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000100342189454907344530000004954650925737960344_" class="st1" d="M638.9,462L638.9,462
							c0.2,0.2,3,3.4,7.3,3.7h0C641.9,465.3,639.1,462.2,638.9,462z"/>
					</defs>
					<clipPath id="SVGID_00000165218669134578829020000014735607083236384134_">
						<use xlink:href="#SVGID_00000100342189454907344530000004954650925737960344_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000165218669134578829020000014735607083236384134_);enable-background:new    ;">
						<path class="st31" d="M638.9,462L638.9,462c0.1,0.1,1.2,1.3,3,2.4h0C640.1,463.3,639,462.1,638.9,462"/>
						<path class="st32" d="M641.9,464.4L641.9,464.4c0.6,0.3,1.2,0.6,1.9,0.8h0C643.1,465,642.5,464.7,641.9,464.4"/>
						<path class="st33" d="M643.8,465.2L643.8,465.2c0.5,0.2,1,0.3,1.5,0.4h0C644.8,465.5,644.3,465.4,643.8,465.2"/>
						<path class="st34" d="M645.3,465.6L645.3,465.6c0.3,0.1,0.6,0.1,0.9,0.1h0C645.9,465.7,645.6,465.6,645.3,465.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000088848458832019582150000017791120508059362204_" class="st1" d="M629,454.9l1.4-1.4
							c5.7,5.7,8.3,8.3,8.5,8.5l-1.4,1.4C637.4,463.3,634.7,460.6,629,454.9z"/>
					</defs>
					<clipPath id="SVGID_00000126305591879869079280000017753213218241828539_">
						<use xlink:href="#SVGID_00000088848458832019582150000017791120508059362204_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000126305591879869079280000017753213218241828539_);enable-background:new    ;">
						<path class="st53" d="M629,454.9l1.4-1.4c5.7,5.7,8.3,8.3,8.5,8.5l-1.4,1.4C637.4,463.3,634.7,460.6,629,454.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000145054552015920092560000007987514294225097404_" class="st1" d="M649.8,639.9L649.8,639.9
							C649.9,639.9,649.9,639.8,649.8,639.9L649.8,639.9C649.9,639.8,649.9,639.9,649.8,639.9z"/>
					</defs>
					<clipPath id="SVGID_00000134943890837469807050000011223299330280959631_">
						<use xlink:href="#SVGID_00000145054552015920092560000007987514294225097404_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000134943890837469807050000011223299330280959631_);enable-background:new    ;">
						<path class="st31" d="M649.9,639.8L649.9,639.8C649.9,639.8,649.9,639.8,649.9,639.8L649.9,639.8
							C649.9,639.8,649.9,639.8,649.9,639.8"/>
						<path class="st32" d="M649.9,639.8L649.9,639.8C649.9,639.8,649.9,639.8,649.9,639.8L649.9,639.8
							C649.9,639.8,649.9,639.8,649.9,639.8"/>
						<path class="st33" d="M649.9,639.8L649.9,639.8C649.9,639.8,649.9,639.8,649.9,639.8L649.9,639.8
							C649.9,639.8,649.9,639.8,649.9,639.8"/>
						<polyline class="st34" points="649.9,639.8 649.9,639.8 649.9,639.8 649.9,639.8 649.9,639.8 						"/>
						<polyline class="st16" points="649.9,639.8 649.9,639.8 649.9,639.8 649.9,639.8 649.9,639.8 						"/>
						<polyline class="st35" points="649.9,639.8 649.9,639.8 649.9,639.9 649.9,639.9 649.9,639.8 						"/>
						<path class="st3" d="M649.9,639.9L649.9,639.9C649.9,639.9,649.9,639.9,649.9,639.9L649.9,639.9
							C649.8,639.9,649.9,639.9,649.9,639.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000021807830415365476230000001813799859822939825_" class="st1" d="M667.3,644.8l-1,1.8
							c-5.7-1.8-11.2-4.1-16.5-6.8l-9.1-7C655.9,640.7,661.5,642.9,667.3,644.8z"/>
					</defs>
					<clipPath id="SVGID_00000008129168269869183840000001444399225537885339_">
						<use xlink:href="#SVGID_00000021807830415365476230000001813799859822939825_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000008129168269869183840000001444399225537885339_);enable-background:new    ;">
						<path class="st5" d="M667.3,644.8l-1,1.8c-5.7-1.8-11.2-4.1-16.5-6.8l-9.1-7C655.9,640.7,661.5,642.9,667.3,644.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000171717094538257291770000008105500135196148912_" class="st1" d="M646.4,637c0,0,0,0.1,0.1,0.1
							c0,0,0,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c1.1,0.8,2.1,1.6,3.2,2.4c0.4,0.4-2.8-0.7-6.1-0.7c-2.1,0-4.1,0.4-5.5,1.8l-1.4-1.4
							c1.9-1.9,4.5-2.4,6.9-2.4C645.6,637.1,646.7,637.4,646.4,637z"/>
					</defs>
					<clipPath id="SVGID_00000052793532124234249270000003014811218412535723_">
						<use xlink:href="#SVGID_00000171717094538257291770000008105500135196148912_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000052793532124234249270000003014811218412535723_);enable-background:new    ;">
						<path class="st3" d="M640.8,632.8l9.1,7c0,0,0,0,0,0.1l-7.9-6.1C641.7,633.4,641.3,633.1,640.8,632.8"/>
						<path class="st35" d="M642,633.7l7.9,6.1c0,0,0,0,0,0l-7.7-6C642.2,633.8,642.1,633.7,642,633.7"/>
						<path class="st16" d="M642.2,633.8l7.7,6c0,0,0,0,0,0l-7.5-5.9C642.4,633.9,642.3,633.9,642.2,633.8"/>
						<path class="st34" d="M642.4,634l7.5,5.9c0,0,0,0,0,0l-7.3-5.7C642.6,634.1,642.5,634,642.4,634"/>
						<path class="st33" d="M642.6,634.1l7.3,5.7c0,0,0,0,0,0l-7.1-5.6C642.7,634.2,642.7,634.2,642.6,634.1"/>
						<path class="st32" d="M642.8,634.2l7.1,5.6c0,0,0,0,0,0L642.8,634.2C642.8,634.3,642.8,634.3,642.8,634.2"/>
						<path class="st31" d="M642.9,634.3l7,5.6c0,0,0,0,0,0L642.9,634.3C642.9,634.3,642.9,634.3,642.9,634.3"/>
						<path class="st30" d="M642.9,634.3l6.9,5.5c0,0,0,0,0,0L642.9,634.3C643,634.4,643,634.4,642.9,634.3"/>
						<path class="st29" d="M643,634.4l6.9,5.5c0,0,0,0,0,0L643,634.4C643,634.4,643,634.4,643,634.4"/>
						<path class="st28" d="M643.1,634.4l6.8,5.4c0,0,0,0,0,0L643.1,634.4C643.1,634.5,643.1,634.4,643.1,634.4"/>
						<path class="st27" d="M643.1,634.5l6.7,5.4c0,0,0,0,0,0L643.1,634.5C643.2,634.5,643.1,634.5,643.1,634.5"/>
						<path class="st26" d="M643.2,634.5l6.7,5.3c0,0,0,0,0,0L643.2,634.5C643.2,634.6,643.2,634.5,643.2,634.5"/>
						<path class="st25" d="M643.2,634.6l6.6,5.3c0,0,0,0,0,0L643.2,634.6C643.3,634.6,643.3,634.6,643.2,634.6"/>
						<path class="st24" d="M643.3,634.6l6.5,5.3c0,0,0,0,0,0L643.3,634.6C643.4,634.6,643.3,634.6,643.3,634.6"/>
						<path class="st23" d="M643.4,634.7l6.5,5.2c0,0,0,0,0,0L643.4,634.7C643.4,634.7,643.4,634.7,643.4,634.7"/>
						<path class="st22" d="M643.4,634.7l6.4,5.2c0,0,0,0,0,0L643.4,634.7C643.5,634.7,643.5,634.7,643.4,634.7"/>
						<path class="st21" d="M643.5,634.7l6.3,5.1c0,0,0,0,0,0L643.5,634.7C643.5,634.8,643.5,634.8,643.5,634.7"/>
						<path class="st20" d="M643.5,634.8l6.3,5.1c0,0,0,0,0,0L643.5,634.8C643.6,634.8,643.6,634.8,643.5,634.8"/>
						<path class="st18" d="M643.6,634.8l6.2,5c0,0,0,0,0,0L643.6,634.8C643.6,634.9,643.6,634.8,643.6,634.8"/>
						<path class="st5" d="M643.7,634.9l6.1,5c0,0,0,0,0,0L643.7,634.9C643.7,634.9,643.7,634.9,643.7,634.9"/>
						<path class="st7" d="M643.7,634.9l6.1,5c0,0,0,0,0,0L643.7,634.9C643.8,634.9,643.7,634.9,643.7,634.9"/>
						<path class="st8" d="M643.8,635l6,4.9c0,0,0,0,0,0L643.8,635C643.8,635,643.8,635,643.8,635"/>
						<path class="st9" d="M643.8,635l5.9,4.9c0,0,0,0,0,0L643.8,635C643.9,635,643.9,635,643.8,635"/>
						<path class="st10" d="M643.9,635l5.9,4.8c0,0,0,0,0,0L643.9,635C643.9,635.1,643.9,635.1,643.9,635"/>
						<path class="st11" d="M644,635.1l5.8,4.8c0,0,0,0,0,0L644,635.1C644,635.1,644,635.1,644,635.1"/>
						<path class="st12" d="M644,635.1l5.7,4.7c0,0,0,0,0,0L644,635.1C644.1,635.2,644,635.1,644,635.1"/>
						<path class="st13" d="M644.1,635.2l5.7,4.7c0,0,0,0,0,0L644.1,635.2C644.1,635.2,644.1,635.2,644.1,635.2"/>
						<path class="st14" d="M644.1,635.2l5.6,4.7c0,0,0,0,0,0L644.1,635.2C644.2,635.2,644.2,635.2,644.1,635.2"/>
						<path class="st51" d="M644.2,635.2l5.5,4.6c0,0,0,0,0,0L644.2,635.2C644.2,635.3,644.2,635.3,644.2,635.2"/>
						<path class="st52" d="M644.2,635.3l5.5,4.6c0,0,0,0,0,0L644.2,635.3C644.3,635.3,644.3,635.3,644.2,635.3"/>
						<path class="st53" d="M644.3,635.3l5.4,4.5c0,0,0,0,0,0L644.3,635.3C644.3,635.3,644.3,635.3,644.3,635.3"/>
						<path class="st63" d="M644.3,635.4l5.4,4.5c0,0,0,0,0,0L644.3,635.4C644.4,635.4,644.4,635.4,644.3,635.4"/>
						<path class="st64" d="M644.4,635.4l5.3,4.5c0,0,0,0,0,0L644.4,635.4C644.4,635.4,644.4,635.4,644.4,635.4"/>
						<path class="st65" d="M644.4,635.4l5.3,4.5c0,0,0,0,0,0L644.4,635.4C644.4,635.4,644.4,635.4,644.4,635.4"/>
						<path class="st66" d="M644.5,635.4l5.2,4.4c0,0,0,0,0,0L644.5,635.4C644.5,635.5,644.5,635.4,644.5,635.4"/>
						<path class="st67" d="M644.5,635.5l5.2,4.4c0,0,0,0,0,0L644.5,635.5C644.5,635.5,644.5,635.5,644.5,635.5"/>
						<path class="st68" d="M644.5,635.5l5.1,4.4c0,0,0,0,0,0L644.5,635.5C644.6,635.5,644.5,635.5,644.5,635.5"/>
						<path class="st69" d="M644.6,635.5l5.1,4.3c0,0,0,0,0,0L644.6,635.5C644.6,635.5,644.6,635.5,644.6,635.5"/>
						<path class="st70" d="M644.6,635.5l5,4.3c0,0,0,0,0,0L644.6,635.5C644.6,635.6,644.6,635.6,644.6,635.5"/>
						<path class="st71" d="M644.6,635.6l5,4.3c0,0,0,0,0,0L644.6,635.6C644.7,635.6,644.7,635.6,644.6,635.6"/>
						<path class="st72" d="M644.7,635.6l4.9,4.3c0,0,0,0,0,0L644.7,635.6C644.7,635.6,644.7,635.6,644.7,635.6"/>
						<path class="st73" d="M644.7,635.6l4.9,4.2c0,0,0,0,0,0L644.7,635.6C644.7,635.6,644.7,635.6,644.7,635.6"/>
						<path class="st74" d="M644.7,635.6l4.8,4.2c0,0,0,0,0,0L644.7,635.6C644.8,635.7,644.8,635.7,644.7,635.6"/>
						<path class="st75" d="M644.8,635.7l4.8,4.2c0,0,0,0,0,0L644.8,635.7C644.8,635.7,644.8,635.7,644.8,635.7"/>
						<path class="st76" d="M644.8,635.7l4.8,4.1c0,0,0,0,0,0L644.8,635.7C644.8,635.7,644.8,635.7,644.8,635.7"/>
						<path class="st77" d="M644.8,635.7l4.7,4.1c0,0,0,0,0,0L644.8,635.7C644.9,635.7,644.9,635.7,644.8,635.7"/>
						<path class="st78" d="M644.9,635.7l4.7,4.1c0,0,0,0,0,0L644.9,635.7C644.9,635.8,644.9,635.8,644.9,635.7"/>
						<path class="st77" d="M644.9,635.8l4.6,4.1c0,0,0,0,0,0L644.9,635.8C645,635.8,645,635.8,644.9,635.8"/>
						<path class="st76" d="M645,635.8l4.5,4c0,0,0,0,0,0L645,635.8C645.1,635.9,645,635.9,645,635.8"/>
						<path class="st75" d="M645.1,635.9l4.4,3.9c0,0,0,0,0,0L645.1,635.9C645.1,635.9,645.1,635.9,645.1,635.9"/>
						<path class="st74" d="M645.1,635.9l4.3,3.9c0,0,0,0,0,0L645.1,635.9C645.2,636,645.2,636,645.1,635.9"/>
						<path class="st73" d="M645.2,636l4.2,3.8c0,0,0,0,0,0L645.2,636C645.3,636,645.2,636,645.2,636"/>
						<path class="st72" d="M645.3,636l4.1,3.8c0,0,0,0,0,0L645.3,636C645.3,636.1,645.3,636.1,645.3,636"/>
						<path class="st71" d="M645.3,636.1l4,3.7c0,0,0,0,0,0L645.3,636.1C645.4,636.1,645.4,636.1,645.3,636.1"/>
						<path class="st70" d="M645.4,636.1l3.9,3.7c0,0,0,0,0,0L645.4,636.1C645.4,636.2,645.4,636.1,645.4,636.1"/>
						<path class="st69" d="M645.5,636.2l3.9,3.6c0,0,0,0,0,0L645.5,636.2C645.5,636.2,645.5,636.2,645.5,636.2"/>
						<path class="st68" d="M645.5,636.2l3.8,3.6c0,0,0,0,0,0L645.5,636.2C645.5,636.2,645.5,636.2,645.5,636.2"/>
						<path class="st67" d="M645.6,636.2l3.7,3.6c0,0,0,0,0,0L645.6,636.2C645.6,636.3,645.6,636.3,645.6,636.2"/>
						<path class="st66" d="M645.6,636.3l3.6,3.5c0,0,0,0,0,0L645.6,636.3C645.6,636.3,645.6,636.3,645.6,636.3"/>
						<path class="st65" d="M645.6,636.3l3.5,3.5c0,0,0,0,0,0L645.6,636.3C645.7,636.3,645.7,636.3,645.6,636.3"/>
						<path class="st64" d="M645.7,636.4l3.5,3.4c0,0,0,0,0,0L645.7,636.4C645.7,636.4,645.7,636.4,645.7,636.4"/>
						<path class="st63" d="M645.7,636.4l3.4,3.4c0,0,0,0,0,0L645.7,636.4C645.8,636.4,645.7,636.4,645.7,636.4"/>
						<path class="st53" d="M645.8,636.4l3.3,3.4c0,0,0,0-0.1,0L645.8,636.4C645.8,636.5,645.8,636.4,645.8,636.4"/>
						<path class="st52" d="M645.8,636.5l3.2,3.3c0,0,0,0-0.1,0L645.8,636.5C645.9,636.5,645.9,636.5,645.8,636.5"/>
						<path class="st51" d="M645.9,636.5l3.1,3.2c0,0,0,0-0.1,0L645.9,636.5C645.9,636.6,645.9,636.5,645.9,636.5"/>
						<path class="st14" d="M646,636.6l3,3.2c0,0,0,0-0.1,0L646,636.6C646,636.6,646,636.6,646,636.6"/>
						<path class="st13" d="M646,636.6l2.9,3.1c0,0,0,0-0.1,0L646,636.6C646.1,636.7,646,636.6,646,636.6"/>
						<path class="st12" d="M646.1,636.7l2.7,3c0,0-0.1,0-0.1,0L646.1,636.7C646.1,636.7,646.1,636.7,646.1,636.7"/>
						<path class="st11" d="M646.2,636.7l2.5,2.9c0,0-0.1,0-0.1,0L646.2,636.7C646.2,636.8,646.2,636.8,646.2,636.7"/>
						<path class="st10" d="M646.2,636.8l2.3,2.9c-0.1,0-0.1,0-0.2,0L646.2,636.8C646.3,636.9,646.3,636.8,646.2,636.8"/>
						<path class="st9" d="M646.3,636.9l2.1,2.7c-0.1,0-0.2,0-0.2,0L646.3,636.9C646.4,637,646.4,636.9,646.3,636.9"/>
						<path class="st8" d="M646.4,637l1.8,2.6c-0.2,0-0.3-0.1-0.5-0.1l-1.3-2.4C646.4,637.1,646.4,637,646.4,637"/>
						<path class="st7" d="M646.4,637.1l1.3,2.4c-1.1-0.2-2.5-0.4-4-0.4c-0.7,0-1.4,0-2.1,0.2l-0.4-2c0.8-0.2,1.7-0.2,2.5-0.2
							C645.3,637.1,646.4,637.3,646.4,637.1"/>
						<path class="st8" d="M641.2,637.3l0.4,2c-0.6,0.1-1.1,0.3-1.7,0.5l-0.8-1.8C639.8,637.7,640.5,637.5,641.2,637.3"/>
						<path class="st9" d="M639.1,637.9l0.8,1.8c-0.4,0.1-0.7,0.3-1,0.5l-1.2-1.6C638.2,638.4,638.7,638.1,639.1,637.9"/>
						<path class="st10" d="M637.8,638.7l1.2,1.6c-0.2,0.2-0.5,0.3-0.7,0.6l-1.4-1.4C637.2,639.1,637.5,638.9,637.8,638.7"/>
						<path class="st11" d="M636.9,639.4l1.4,1.4c0,0,0,0,0,0L636.9,639.4C636.8,639.4,636.9,639.4,636.9,639.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000096032222209100713160000009066439503267807882_" class="st1" d="M637.5,463.4l1.4-1.4
							c0.1,0.2,3,3.4,7.3,3.7l0.5,2C640.9,467.3,637.6,463.5,637.5,463.4z"/>
					</defs>
					<clipPath id="SVGID_00000170983248771008010830000013593187153022440876_">
						<use xlink:href="#SVGID_00000096032222209100713160000009066439503267807882_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000170983248771008010830000013593187153022440876_);enable-background:new    ;">
						<path class="st53" d="M637.5,463.4l1.4-1.4c0.1,0.1,1.5,1.7,3.9,2.8l-0.6,1.9C639.3,465.4,637.6,463.5,637.5,463.4"/>
						<path class="st52" d="M642.3,466.7l0.6-1.9c0.6,0.3,1.3,0.5,2,0.7l0,2C643.9,467.3,643.1,467,642.3,466.7"/>
						<path class="st51" d="M644.9,467.5l0-2c0.4,0.1,0.9,0.2,1.4,0.2l0.5,2C646.1,467.7,645.5,467.6,644.9,467.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000047762558672785570660000018396523448340774037_" class="st1" d="M646.7,467.8l-0.5-2
							c7.1-4.1,14.8-7.4,23-9.6l0.5,2C662,460.3,654.2,463.4,646.7,467.8z"/>
					</defs>
					<clipPath id="SVGID_00000178200700870338906610000004277094163005551268_">
						<use xlink:href="#SVGID_00000047762558672785570660000018396523448340774037_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000178200700870338906610000004277094163005551268_);enable-background:new    ;">
						<path class="st11" d="M646.7,467.8l-0.5-2c3.1-1.8,6.3-3.4,9.6-4.8l0.8,1.9C653.3,464.2,650,465.9,646.7,467.8"/>
						<path class="st12" d="M656.6,462.8l-0.8-1.9c4.3-1.9,8.8-3.5,13.4-4.8l0.5,2C665.4,459.3,660.9,460.9,656.6,462.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000062904069611940647220000011530061031517660303_" class="st1" d="M673.5,668.5h-2c0-8,0-11.8,0-12h2
							C673.5,656.7,673.5,660.5,673.5,668.5z"/>
					</defs>
					<clipPath id="SVGID_00000134242952368563045360000003179035825613265843_">
						<use xlink:href="#SVGID_00000062904069611940647220000011530061031517660303_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000134242952368563045360000003179035825613265843_);enable-background:new    ;">
						<path class="st5" d="M673.5,668.5h-2c0-8,0-11.8,0-12h2C673.5,656.7,673.5,660.5,673.5,668.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000150060790265162482820000007853356423236381860_" class="st1" d="M673.5,656.5h-2c0-0.3,0.4-6.9-5.2-9.9
							l1-1.8C674,648.5,673.5,656.2,673.5,656.5z"/>
					</defs>
					<clipPath id="SVGID_00000134932400667652765510000008369447432176878239_">
						<use xlink:href="#SVGID_00000150060790265162482820000007853356423236381860_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000134932400667652765510000008369447432176878239_);enable-background:new    ;">
						<path class="st5" d="M673.5,656.5h-2c0-0.2,0.2-2.7-0.9-5.3l1.8-0.9C673.7,653.4,673.5,656.3,673.5,656.5"/>
						<path class="st18" d="M672.4,650.4l-1.8,0.9c-0.6-1.4-1.6-2.8-3.1-4l1.2-1.6C670.5,647,671.7,648.7,672.4,650.4"/>
						<path class="st5" d="M668.6,645.7l-1.2,1.6c-0.3-0.2-0.7-0.5-1.1-0.7l1-1.8C667.8,645.1,668.2,645.3,668.6,645.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000135650643551212639170000000405690439975900064_" class="st1" d="M669.8,458.1l-0.5-2
							c2.1-1.6,4.3-4,4.3-6.6h2C675.5,453.1,672.8,455.8,669.8,458.1z"/>
					</defs>
					<clipPath id="SVGID_00000181799152687667804900000007555741707103083674_">
						<use xlink:href="#SVGID_00000135650643551212639170000000405690439975900064_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000181799152687667804900000007555741707103083674_);enable-background:new    ;">
						<path class="st10" d="M669.8,458.1l-0.5-2c0.4-0.4,0.9-0.7,1.3-1.1l1.1,1.6C671,457.1,670.4,457.6,669.8,458.1"/>
						<path class="st9" d="M671.6,456.5l-1.1-1.6c0.6-0.5,1.1-1.1,1.6-1.8l1.6,1.1C673.1,455.1,672.4,455.8,671.6,456.5"/>
						<path class="st8" d="M673.7,454.3l-1.6-1.1c0.4-0.5,0.7-1,0.9-1.5l1.9,0.7C674.6,453,674.2,453.7,673.7,454.3"/>
						<path class="st7" d="M674.9,452.3l-1.9-0.7c0.2-0.5,0.4-1,0.4-1.6l2,0.2C675.4,450.9,675.2,451.6,674.9,452.3"/>
						<path class="st5" d="M675.5,450.2l-2-0.2c0-0.2,0-0.4,0-0.5h2C675.5,449.7,675.5,450,675.5,450.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000060014087827849464550000002447390665314246332_" class="st1" d="M717.3,647.3c1.7-0.1,3.7-0.3,7.7-0.8
							c-3,0.3-6.9,0.8-9.9,1.1C716.1,647.4,716.8,647.3,717.3,647.3z"/>
					</defs>
					<clipPath id="SVGID_00000000207665277227089350000012772707300134935448_">
						<use xlink:href="#SVGID_00000060014087827849464550000002447390665314246332_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000000207665277227089350000012772707300134935448_);enable-background:new    ;">
						<path class="st14" d="M725.1,646.5l-9.9,1.1c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st13" d="M725.1,646.5l-9.9,1.1c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st12" d="M725.1,646.5l-9.8,1.1c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st11" d="M725.1,646.5l-9.8,1.1c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st10" d="M725.1,646.5l-9.7,1c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st9" d="M725.1,646.5l-9.7,1c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st8" d="M725.1,646.5l-9.7,1c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st7" d="M725.1,646.5l-9.6,1c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st5" d="M725.1,646.5l-9.6,1c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st18" d="M725.1,646.5l-9.5,1c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st20" d="M725.1,646.5l-9.5,1c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st21" d="M725.1,646.5l-9.4,1c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st22" d="M725.1,646.5l-9.4,1c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st23" d="M725.1,646.5l-9.3,1c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st24" d="M725.1,646.5l-9.3,1c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st25" d="M725.1,646.5l-9.2,1c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st26" d="M725.1,646.5l-9.2,0.9c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st27" d="M725.1,646.5l-9.2,0.9c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st28" d="M725.1,646.5l-9.1,0.9c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st29" d="M725.1,646.5l-9.1,0.9c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st30" d="M725.1,646.5l-9,0.9c0,0,0,0,0,0L725.1,646.5C725.1,646.5,725.1,646.5,725.1,646.5"/>
						<path class="st31" d="M725.1,646.5l-9,0.9c0,0,0,0,0,0L725.1,646.5C725.1,646.6,725.1,646.5,725.1,646.5"/>
						<path class="st32" d="M725,646.6l-8.9,0.9c0,0,0,0,0,0L725,646.6C725,646.6,725,646.6,725,646.6"/>
						<path class="st33" d="M725,646.6l-8.9,0.9c0,0,0.1,0,0.1,0L725,646.6C725,646.6,725,646.6,725,646.6"/>
						<path class="st34" d="M725,646.6l-8.8,0.9c0.1,0,0.1,0,0.2,0L725,646.6C725,646.6,725,646.6,725,646.6"/>
						<path class="st16" d="M725,646.6l-8.5,0.8c0.1,0,0.1,0,0.2,0L725,646.6C725,646.6,725,646.6,725,646.6"/>
						<path class="st35" d="M725,646.6l-8.3,0.8c0.1,0,0.1,0,0.2,0L725,646.6C725,646.6,725,646.6,725,646.6"/>
						<path class="st3" d="M725,646.6l-8.1,0.7c0.2,0,0.3,0,0.5-0.1l7.5-0.6C724.9,646.7,724.9,646.6,725,646.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000072251815708517471460000002868237208704845744_" class="st1" d="M680.5,675.5l0,2c-0.4,0-9-1-9-9h2
							C673.4,674.6,680.4,675.5,680.5,675.5z"/>
					</defs>
					<clipPath id="SVGID_00000080895360197703092940000009099218204024497551_">
						<use xlink:href="#SVGID_00000072251815708517471460000002868237208704845744_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000080895360197703092940000009099218204024497551_);enable-background:new    ;">
						<path class="st5" d="M680.5,675.5l0,2c-0.3,0-3.4-0.4-5.9-2.4l1.3-1.5C677.9,675.2,680.4,675.5,680.5,675.5"/>
						<path class="st18" d="M675.9,673.6l-1.3,1.5c-0.9-0.7-1.7-1.6-2.3-2.9l1.8-0.9C674.5,672.3,675.2,673.1,675.9,673.6"/>
						<path class="st5" d="M674.1,671.4l-1.8,0.9c-0.5-1-0.8-2.3-0.8-3.8h2C673.5,669.6,673.7,670.6,674.1,671.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000134931550353520518950000012127560319043656614_" class="st1" d="M675.5,449.5h-2c0-5,0-12.6,0-13
							c0-0.4,0-9,7-9l0,2c-4.9-0.1-5,6.6-5,7C675.5,436.9,675.5,444.5,675.5,449.5z"/>
					</defs>
					<clipPath id="SVGID_00000173154982042918358340000003891582920491262911_">
						<use xlink:href="#SVGID_00000134931550353520518950000012127560319043656614_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000173154982042918358340000003891582920491262911_);enable-background:new    ;">
						<path class="st5" d="M675.5,449.5h-2c0-5,0-12.6,0-13c0-0.1,0-0.6,0.1-1.3l2,0.3c-0.1,0.5-0.1,0.9-0.1,1
							C675.5,436.9,675.5,444.5,675.5,449.5"/>
						<path class="st7" d="M675.6,435.5l-2-0.3c0.1-0.9,0.3-2.3,0.9-3.6l1.8,1C675.8,433.7,675.6,434.7,675.6,435.5"/>
						<path class="st8" d="M676.2,432.7l-1.8-1c0.2-0.6,0.5-1.2,0.9-1.7l1.5,1.4C676.6,431.8,676.4,432.2,676.2,432.7"/>
						<path class="st9" d="M676.9,431.4l-1.5-1.4c0.2-0.3,0.5-0.6,0.8-0.9l1.3,1.6C677.3,430.9,677.1,431.1,676.9,431.4"/>
						<path class="st10" d="M677.5,430.6l-1.3-1.6c0.2-0.2,0.5-0.4,0.8-0.6l1,1.7C677.8,430.3,677.7,430.5,677.5,430.6"/>
						<path class="st11" d="M678.1,430.2l-1-1.7c0.2-0.2,0.5-0.3,0.8-0.4l0.8,1.8C678.4,429.9,678.2,430.1,678.1,430.2"/>
						<path class="st12" d="M678.6,429.9l-0.8-1.8c0.3-0.1,0.6-0.2,0.9-0.3l0.5,1.9C679,429.7,678.8,429.8,678.6,429.9"/>
						<path class="st13" d="M679.2,429.6l-0.5-1.9c0.3-0.1,0.6-0.1,1-0.2l0.2,2C679.7,429.5,679.4,429.6,679.2,429.6"/>
						<path class="st14" d="M679.9,429.5l-0.2-2c0.3,0,0.5,0,0.8,0l0,2C680.3,429.5,680.1,429.5,679.9,429.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000088103024678105306640000012107744334520418975_" class="st1" d="M696.5,474.5L696.5,474.5
							c-43.6,0-79,35.4-79,79c0,43.6,35.4,79,79,79h0c-43.6,0-79-35.4-79-79C617.5,509.9,652.9,474.5,696.5,474.5z"/>
					</defs>
					<clipPath id="SVGID_00000114048950728981055940000003595207160109171127_">
						<use xlink:href="#SVGID_00000088103024678105306640000012107744334520418975_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000114048950728981055940000003595207160109171127_);enable-background:new    ;">
						<path class="st3" d="M696.5,474.5L696.5,474.5c-30,0-56,16.7-69.4,41.3h0C640.5,491.2,666.5,474.5,696.5,474.5"/>
						<path class="st35" d="M627.1,515.8L627.1,515.8c-2.2,4.1-4.1,8.4-5.6,12.9h0C623,524.2,624.8,519.9,627.1,515.8"/>
						<path class="st16" d="M621.5,528.6L621.5,528.6c-1.2,3.7-2.2,7.5-2.8,11.5h0C619.3,536.2,620.3,532.4,621.5,528.6"/>
						<path class="st34" d="M618.6,540.1L618.6,540.1c-0.6,3.7-1,7.6-1.1,11.4h0C617.6,547.7,618,543.8,618.6,540.1"/>
						<path class="st33" d="M617.5,551.6L617.5,551.6c0,0.6,0,1.3,0,1.9c0,3.5,0.2,6.9,0.7,10.2h0c-0.4-3.3-0.7-6.8-0.7-10.2
							C617.5,552.9,617.5,552.2,617.5,551.6"/>
						<path class="st32" d="M618.2,563.7L618.2,563.7c0.6,4.8,1.7,9.4,3.1,13.8h0C619.8,573.1,618.8,568.5,618.2,563.7"/>
						<path class="st31" d="M621.2,577.6L621.2,577.6c6.1,19.1,19.3,35.1,36.6,44.8h0C640.6,612.7,627.4,596.7,621.2,577.6"/>
						<path class="st32" d="M657.8,622.4L657.8,622.4c4.1,2.3,8.5,4.3,13,5.8h0C666.3,626.7,661.9,624.7,657.8,622.4"/>
						<path class="st33" d="M670.8,628.2L670.8,628.2c3.7,1.3,7.6,2.3,11.5,3h0C678.4,630.5,674.5,629.5,670.8,628.2"/>
						<path class="st34" d="M682.3,631.2L682.3,631.2c3.7,0.7,7.6,1.1,11.4,1.2h0C689.9,632.3,686.1,631.9,682.3,631.2"/>
						<path class="st16" d="M693.8,632.5L693.8,632.5c0.9,0,1.8,0,2.7,0h0C695.6,632.5,694.7,632.5,693.8,632.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000036214293458308750680000006003767358912325288_" class="st1" d="M717.5,668.5L717.5,668.5c0,0.4,0,9-7,9
							h0C717.5,677.5,717.5,668.9,717.5,668.5z"/>
					</defs>
					<clipPath id="SVGID_00000043455387905438727980000011343761620007934368_">
						<use xlink:href="#SVGID_00000036214293458308750680000006003767358912325288_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000043455387905438727980000011343761620007934368_);enable-background:new    ;">
						<path class="st33" d="M717.5,668.5L717.5,668.5c0,0,0,0.1,0,0.2h0C717.5,668.6,717.5,668.5,717.5,668.5"/>
						<path class="st34" d="M717.5,668.7L717.5,668.7c0,0.4,0,1.1-0.2,2h0C717.4,669.8,717.5,669.1,717.5,668.7"/>
						<path class="st16" d="M717.3,670.7L717.3,670.7c-0.1,0.6-0.2,1.2-0.4,1.9h0C717.1,671.9,717.2,671.3,717.3,670.7"/>
						<path class="st35" d="M716.8,672.6L716.8,672.6c-0.2,0.5-0.4,1.1-0.7,1.6h0C716.4,673.6,716.7,673.1,716.8,672.6"/>
						<path class="st3" d="M716.2,674.1L716.2,674.1c-1,1.8-2.7,3.4-5.7,3.4h0C713.5,677.5,715.2,676,716.2,674.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000168077224698424445900000006370149466358769064_" class="st1" d="M717.5,655.5L717.5,655.5
							c0,5,0,12.6,0,13h0C717.5,668.1,717.5,660.5,717.5,655.5z"/>
					</defs>
					<clipPath id="SVGID_00000088837461558922623060000003554972073325260168_">
						<use xlink:href="#SVGID_00000168077224698424445900000006370149466358769064_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000088837461558922623060000003554972073325260168_);enable-background:new    ;">
						<path class="st33" d="M717.5,655.5L717.5,655.5c0,5,0,12.6,0,13h0C717.5,668.1,717.5,660.5,717.5,655.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000179632283013180423620000005337432202610813601_" class="st1" d="M719.5,436.5L719.5,436.5
							c0,8,0,11.8,0,12h0C719.5,448.3,719.5,444.5,719.5,436.5z"/>
					</defs>
					<clipPath id="SVGID_00000133530019739440661130000011446452059531242377_">
						<use xlink:href="#SVGID_00000179632283013180423620000005337432202610813601_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000133530019739440661130000011446452059531242377_);enable-background:new    ;">
						<path class="st33" d="M719.5,436.5L719.5,436.5c0,8,0,11.8,0,12h0C719.5,448.3,719.5,444.5,719.5,436.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000109006820103365322270000000745295267661434762_" class="st1" d="M710.5,427.5L710.5,427.5
							c0.5,0.1,9,1,9,9h0C719.5,428.5,710.9,427.5,710.5,427.5z"/>
					</defs>
					<clipPath id="SVGID_00000158750981118458596150000006408978337403252142_">
						<use xlink:href="#SVGID_00000109006820103365322270000000745295267661434762_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000158750981118458596150000006408978337403252142_);enable-background:new    ;">
						<path class="st34" d="M710.5,427.5L710.5,427.5c0.1,0,0.3,0,0.7,0.1h0C710.8,427.5,710.6,427.5,710.5,427.5"/>
						<path class="st33" d="M711.1,427.6L711.1,427.6c0.5,0.1,1.2,0.2,2,0.5h0C712.3,427.8,711.6,427.7,711.1,427.6"/>
						<path class="st32" d="M713.1,428.1L713.1,428.1c0.6,0.2,1.3,0.5,2,0.9h0C714.4,428.6,713.8,428.3,713.1,428.1"/>
						<path class="st31" d="M715.1,429L715.1,429c1.6,0.9,3.2,2.4,4,4.6h0C718.3,431.3,716.7,429.9,715.1,429"/>
						<path class="st32" d="M719.1,433.6L719.1,433.6c0.2,0.5,0.3,1,0.4,1.5h0C719.3,434.6,719.2,434.1,719.1,433.6"/>
						<path class="st33" d="M719.4,435.2L719.4,435.2c0.1,0.4,0.1,0.9,0.1,1.3h0C719.5,436,719.5,435.6,719.4,435.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000145773566166729502570000005940047778790490301_" class="st1" d="M710.5,675.5l0,2c-7,0-28.9,0-30,0l0-2
							C681.6,675.5,703.5,675.5,710.5,675.5z"/>
					</defs>
					<clipPath id="SVGID_00000071536908077658853090000001761559299161776039_">
						<use xlink:href="#SVGID_00000145773566166729502570000005940047778790490301_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000071536908077658853090000001761559299161776039_);enable-background:new    ;">
						<path class="st7" d="M710.5,675.5l0,2c-7,0-28.9,0-30,0l0-2C681.6,675.5,703.5,675.5,710.5,675.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000178896167941458612320000017628019367935197109_" class="st1" d="M680.5,429.5l0-2c7,0,28.9,0,30,0l0,2
							C709.3,429.5,687.5,429.5,680.5,429.5z"/>
					</defs>
					<clipPath id="SVGID_00000125597818632394137760000009519923957184115876_">
						<use xlink:href="#SVGID_00000178896167941458612320000017628019367935197109_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000125597818632394137760000009519923957184115876_);enable-background:new    ;">
						<path class="st14" d="M680.5,429.5l0-2c7,0,28.9,0,30,0l0,2C709.3,429.5,687.5,429.5,680.5,429.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000044861896370134258560000010158402225834459797_" class="st1" d="M719.5,448.5L719.5,448.5
							c0,0.2-0.2,4.2,2.3,7.5h0C719.2,452.7,719.5,448.7,719.5,448.5z"/>
					</defs>
					<clipPath id="SVGID_00000083047412553217942370000004704801735098236840_">
						<use xlink:href="#SVGID_00000044861896370134258560000010158402225834459797_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000083047412553217942370000004704801735098236840_);enable-background:new    ;">
						<path class="st34" d="M719.5,448.5L719.5,448.5c0,0,0,0.1,0,0.2h0C719.5,448.6,719.5,448.5,719.5,448.5"/>
						<path class="st33" d="M719.5,448.7L719.5,448.7c0,0.3,0,0.9,0.1,1.7h0C719.5,449.7,719.5,449,719.5,448.7"/>
						<path class="st32" d="M719.6,450.5L719.6,450.5c0.1,0.6,0.2,1.4,0.5,2.2h0C719.8,451.9,719.7,451.1,719.6,450.5"/>
						<path class="st31" d="M720.1,452.7L720.1,452.7c0.3,1.1,0.9,2.2,1.7,3.3h0C720.9,454.9,720.4,453.7,720.1,452.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000044154295809552296070000010454748430857727659_" class="st1" d="M715.5,655.5h2c0,5,0,12.6,0,13
							c0,0.4,0,9-7,9l0-2c4.9,0.1,5-6.6,5-7C715.5,668.1,715.5,660.5,715.5,655.5z"/>
					</defs>
					<clipPath id="SVGID_00000136371318617576741250000005525540781718363550_">
						<use xlink:href="#SVGID_00000044154295809552296070000010454748430857727659_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000136371318617576741250000005525540781718363550_);enable-background:new    ;">
						<path class="st51" d="M715.5,655.5h2c0,5,0,12.6,0,13c0,0.1,0,1-0.2,2.1l-2-0.4c0.1-0.9,0.1-1.5,0.1-1.6
							C715.5,668.1,715.5,660.5,715.5,655.5"/>
						<path class="st14" d="M715.3,670.1l2,0.4c-0.1,0.6-0.3,1.4-0.5,2.1l-1.8-0.9C715.1,671.2,715.3,670.6,715.3,670.1"/>
						<path class="st13" d="M715,671.8l1.8,0.9c-0.2,0.5-0.4,1-0.6,1.4l-1.7-1.2C714.7,672.5,714.8,672.1,715,671.8"/>
						<path class="st12" d="M714.5,672.9l1.7,1.2c-0.2,0.4-0.4,0.7-0.7,1.1l-1.5-1.4C714.2,673.5,714.4,673.2,714.5,672.9"/>
						<path class="st11" d="M714,673.7l1.5,1.4c-0.2,0.3-0.4,0.5-0.7,0.8l-1.3-1.6C713.7,674.1,713.9,673.9,714,673.7"/>
						<path class="st10" d="M713.5,674.3l1.3,1.6c-0.2,0.2-0.5,0.4-0.8,0.6l-1.1-1.7C713.2,674.7,713.4,674.5,713.5,674.3"/>
						<path class="st9" d="M713,674.8l1.1,1.7c-0.3,0.2-0.6,0.4-0.9,0.5l-0.8-1.9C712.5,675.1,712.8,674.9,713,674.8"/>
						<path class="st8" d="M712.3,675.2l0.8,1.9c-0.4,0.2-0.8,0.3-1.3,0.4l-0.4-2C711.7,675.4,712,675.3,712.3,675.2"/>
						<path class="st7" d="M711.4,675.4l0.4,2c-0.4,0.1-0.9,0.1-1.3,0.1l0-2C710.8,675.5,711.1,675.5,711.4,675.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000133518668667408158250000008379364836744914585_" class="st1" d="M725.4,646.5L725.4,646.5
							c0.1,0-7.9,4-7.9,9h0C717.5,650.5,725.5,646.5,725.4,646.5z"/>
					</defs>
					<clipPath id="SVGID_00000110451433731313421640000009970745896319273628_">
						<use xlink:href="#SVGID_00000133518668667408158250000008379364836744914585_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000110451433731313421640000009970745896319273628_);enable-background:new    ;">
						<polyline class="st34" points="725.4,646.5 725.4,646.5 725.4,646.5 725.4,646.5 725.4,646.5 						"/>
						<polyline class="st16" points="725.4,646.5 725.4,646.5 725.4,646.5 725.4,646.5 725.4,646.5 						"/>
						<path class="st35" d="M725.4,646.5L725.4,646.5C725.4,646.5,725.4,646.5,725.4,646.5L725.4,646.5
							C725.4,646.5,725.4,646.5,725.4,646.5"/>
						<path class="st3" d="M725.4,646.5L725.4,646.5c-0.3,0.2-5.2,2.8-7.1,6.3h0C720.2,649.3,725,646.7,725.4,646.5"/>
						<path class="st35" d="M718.3,652.8L718.3,652.8c-0.2,0.4-0.3,0.7-0.5,1.1h0C717.9,653.5,718.1,653.1,718.3,652.8"/>
						<path class="st16" d="M717.8,653.9L717.8,653.9c-0.1,0.3-0.1,0.5-0.2,0.8h0C717.6,654.4,717.7,654.1,717.8,653.9"/>
						<path class="st34" d="M717.6,654.7L717.6,654.7c0,0.2-0.1,0.5-0.1,0.7h0C717.5,655.1,717.5,654.9,717.6,654.7"/>
						<path class="st33" d="M717.5,655.4L717.5,655.4c0,0,0,0.1,0,0.1h0C717.5,655.5,717.5,655.4,717.5,655.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000101798754277112121300000006014125526490556037_" class="st1" d="M717.5,436.5h2c0,8,0,11.8,0,12h-2
							C717.5,448.3,717.5,444.5,717.5,436.5z"/>
					</defs>
					<clipPath id="SVGID_00000048468665660488885430000007546626998180487815_">
						<use xlink:href="#SVGID_00000101798754277112121300000006014125526490556037_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000048468665660488885430000007546626998180487815_);enable-background:new    ;">
						<path class="st51" d="M717.5,436.5h2c0,8,0,11.8,0,12h-2C717.5,448.3,717.5,444.5,717.5,436.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000106828626048170651350000008258315182762960549_" class="st1" d="M710.5,429.5l0-2c0.4,0.1,9,1,9,9h-2
							C717.6,430.4,710.6,429.5,710.5,429.5z"/>
					</defs>
					<clipPath id="SVGID_00000034082477424905734180000015933676804975336073_">
						<use xlink:href="#SVGID_00000106828626048170651350000008258315182762960549_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000034082477424905734180000015933676804975336073_);enable-background:new    ;">
						<path class="st51" d="M710.5,429.5l0-2c0.1,0,0.6,0.1,1.4,0.2l-0.4,2C710.9,429.6,710.5,429.5,710.5,429.5"/>
						<path class="st52" d="M711.5,429.7l0.4-2c0.8,0.2,1.8,0.5,2.9,1.1l-1,1.7C712.9,430.1,712.1,429.8,711.5,429.7"/>
						<path class="st53" d="M713.7,430.5l1-1.7c1.9,1,3.8,2.6,4.5,5.4l-1.9,0.5C716.8,432.6,715.2,431.3,713.7,430.5"/>
						<path class="st52" d="M717.3,434.7l1.9-0.5c0.2,0.6,0.2,1.3,0.3,2l-2,0.1C717.5,435.7,717.4,435.2,717.3,434.7"/>
						<path class="st51" d="M717.5,436.3l2-0.1c0,0.1,0,0.2,0,0.3h-2C717.5,436.4,717.5,436.4,717.5,436.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000158721107544025091530000003434256627334724535_" class="st1" d="M771.9,650.1c0.1,0.1,0.3,0.3,0.4,0.4
							L771.9,650.1C771.9,650.1,771.9,650.1,771.9,650.1z"/>
					</defs>
					<clipPath id="SVGID_00000078044938787968869320000002091501537942693554_">
						<use xlink:href="#SVGID_00000158721107544025091530000003434256627334724535_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000078044938787968869320000002091501537942693554_);enable-background:new    ;">
						<polyline class="st3" points="772.3,650.5 771.9,650.1 771.9,650.1 772.3,650.5 772.3,650.5 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000162316228272346018670000000835385222658408598_" class="st1" d="M717.5,448.5h2c0,0.2-0.3,4.2,2.3,7.5
							l-1.2,1.8C717.2,453.3,717.5,448.7,717.5,448.5z"/>
					</defs>
					<clipPath id="SVGID_00000134217822559438616340000005120937362387380633_">
						<use xlink:href="#SVGID_00000162316228272346018670000000835385222658408598_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000134217822559438616340000005120937362387380633_);enable-background:new    ;">
						<path class="st51" d="M717.5,448.5h2c0,0,0,0.2,0,0.5l-2,0.1C717.5,448.7,717.5,448.5,717.5,448.5"/>
						<path class="st52" d="M717.5,449.1l2-0.1c0,0.5,0,1.3,0.2,2.2l-1.9,0.5C717.6,450.6,717.5,449.7,717.5,449.1"/>
						<path class="st53" d="M717.8,451.7l1.9-0.5c0.3,1.4,0.8,3.1,2,4.7l-1.2,1.8C719,455.6,718.2,453.4,717.8,451.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000033350606200466222330000000228700273221364363_" class="st1" d="M717.3,647.3c2.1-0.2,4.3-0.4,8.1-0.8
							c0.1,0-7.9,4-7.9,9h-2C715.6,649.9,721,647,717.3,647.3z"/>
					</defs>
					<clipPath id="SVGID_00000124133471142810087200000017060304951483789203_">
						<use xlink:href="#SVGID_00000033350606200466222330000000228700273221364363_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000124133471142810087200000017060304951483789203_);enable-background:new    ;">
						<path class="st3" d="M715.1,647.6l10.3-1.1c0,0,0,0,0,0l-9.2,0.9C715.9,647.5,715.5,647.5,715.1,647.6"/>
						<path class="st35" d="M716.2,647.4l9.2-0.9c0,0,0,0,0,0L716.2,647.4C716.2,647.4,716.2,647.4,716.2,647.4"/>
						<path class="st16" d="M716.2,647.4l9.1-0.9c0,0,0,0,0,0L716.2,647.4C716.2,647.4,716.2,647.4,716.2,647.4"/>
						<path class="st34" d="M716.2,647.4l9.1-0.9c0,0,0,0,0,0L716.2,647.4C716.2,647.4,716.2,647.4,716.2,647.4"/>
						<path class="st33" d="M716.3,647.4l9.1-0.9c0,0,0,0,0,0L716.3,647.4C716.3,647.4,716.3,647.4,716.3,647.4"/>
						<path class="st32" d="M716.3,647.4l9-0.9c0,0,0,0,0,0L716.3,647.4C716.3,647.4,716.3,647.4,716.3,647.4"/>
						<path class="st31" d="M716.3,647.4l9-0.9c0,0,0,0,0,0L716.3,647.4C716.3,647.4,716.3,647.4,716.3,647.4"/>
						<path class="st30" d="M716.3,647.4l9-0.9c0,0,0,0,0,0L716.3,647.4C716.4,647.4,716.4,647.4,716.3,647.4"/>
						<path class="st29" d="M716.4,647.4l8.9-0.9c0,0,0,0,0,0L716.4,647.4C716.4,647.4,716.4,647.4,716.4,647.4"/>
						<path class="st28" d="M716.4,647.4l8.9-0.9c0,0,0,0,0,0L716.4,647.4C716.4,647.4,716.4,647.4,716.4,647.4"/>
						<path class="st27" d="M716.4,647.4l8.9-0.8c0,0,0,0,0,0L716.4,647.4C716.5,647.4,716.4,647.4,716.4,647.4"/>
						<path class="st26" d="M716.5,647.4l8.8-0.8c0,0,0,0,0,0L716.5,647.4C716.5,647.4,716.5,647.4,716.5,647.4"/>
						<path class="st25" d="M716.5,647.4l8.8-0.8c0,0,0,0,0,0L716.5,647.4C716.5,647.4,716.5,647.4,716.5,647.4"/>
						<path class="st24" d="M716.5,647.4l8.8-0.8c0,0,0,0,0,0L716.5,647.4C716.5,647.4,716.5,647.4,716.5,647.4"/>
						<path class="st23" d="M716.6,647.4l8.7-0.8c0,0,0,0,0,0L716.6,647.4C716.6,647.4,716.6,647.4,716.6,647.4"/>
						<path class="st22" d="M716.6,647.4l8.7-0.8c0,0,0,0,0,0L716.6,647.4C716.6,647.4,716.6,647.4,716.6,647.4"/>
						<path class="st21" d="M716.6,647.4l8.7-0.8c0,0,0,0,0,0L716.6,647.4C716.6,647.4,716.6,647.4,716.6,647.4"/>
						<path class="st20" d="M716.6,647.4l8.6-0.8c0,0,0,0,0,0L716.6,647.4C716.7,647.4,716.6,647.4,716.6,647.4"/>
						<path class="st18" d="M716.7,647.4l8.6-0.8c0,0,0,0,0,0L716.7,647.4C716.7,647.4,716.7,647.4,716.7,647.4"/>
						<path class="st5" d="M716.7,647.4l8.6-0.8c0,0,0,0,0,0L716.7,647.4C716.7,647.4,716.7,647.4,716.7,647.4"/>
						<path class="st7" d="M716.7,647.4l8.5-0.8c0,0,0,0,0,0L716.7,647.4C716.7,647.3,716.7,647.4,716.7,647.4"/>
						<path class="st8" d="M716.7,647.3l8.5-0.8c0,0,0,0,0,0L716.7,647.3C716.8,647.3,716.8,647.3,716.7,647.3"/>
						<path class="st9" d="M716.8,647.3l8.5-0.8c0,0,0,0,0,0L716.8,647.3C716.8,647.3,716.8,647.3,716.8,647.3"/>
						<path class="st10" d="M716.8,647.3l8.4-0.8c0,0,0,0,0,0L716.8,647.3C716.8,647.3,716.8,647.3,716.8,647.3"/>
						<path class="st11" d="M716.8,647.3l8.4-0.7c0,0,0,0,0,0L716.8,647.3C716.8,647.3,716.8,647.3,716.8,647.3"/>
						<path class="st12" d="M716.8,647.3l8.4-0.7c0,0,0,0,0,0L716.8,647.3C716.9,647.3,716.9,647.3,716.8,647.3"/>
						<path class="st13" d="M716.9,647.3l8.3-0.7c0,0,0,0,0,0L716.9,647.3C716.9,647.3,716.9,647.3,716.9,647.3"/>
						<path class="st14" d="M716.9,647.3l8.3-0.7c0,0,0,0,0,0L716.9,647.3C716.9,647.3,716.9,647.3,716.9,647.3"/>
						<path class="st51" d="M716.9,647.3l8.3-0.7c0,0,0,0,0,0L716.9,647.3C716.9,647.3,716.9,647.3,716.9,647.3"/>
						<path class="st52" d="M716.9,647.3l8.2-0.7c0,0,0,0-0.1,0l-7.9,0.6C717.1,647.3,717,647.3,716.9,647.3"/>
						<path class="st51" d="M717.2,647.3l7.9-0.6c0,0-0.1,0-0.1,0l-7.6,0.6C717.3,647.3,717.3,647.3,717.2,647.3"/>
						<path class="st14" d="M717.4,647.3l7.6-0.6c0,0-0.1,0-0.1,0.1l-7.4,0.5C717.5,647.3,717.5,647.3,717.4,647.3"/>
						<path class="st13" d="M717.6,647.3l7.4-0.5c-0.1,0-0.1,0.1-0.2,0.1l-6.8,0.4C717.8,647.3,717.7,647.3,717.6,647.3"/>
						<path class="st12" d="M717.9,647.3l6.8-0.4c-0.1,0.1-0.3,0.2-0.5,0.3l-5.9,0.2C718.2,647.3,718.1,647.3,717.9,647.3"/>
						<path class="st11" d="M718.3,647.3l5.9-0.2c-1.2,0.8-3.3,2.1-4.8,3.9l-2.4-0.7C718.1,648.6,719.1,647.6,718.3,647.3"/>
						<path class="st12" d="M717.1,650.4l2.4,0.7c-0.4,0.5-0.8,1-1.1,1.5l-2.1-0.6C716.5,651.4,716.8,650.9,717.1,650.4"/>
						<path class="st13" d="M716.3,652l2.1,0.6c-0.2,0.4-0.4,0.7-0.5,1.1l-2-0.4C716,652.8,716.1,652.3,716.3,652"/>
						<path class="st14" d="M715.8,653.2l2,0.4c-0.1,0.3-0.2,0.7-0.3,1l-2-0.2C715.6,654,715.7,653.6,715.8,653.2"/>
						<path class="st51" d="M715.6,654.4l2,0.2c-0.1,0.3-0.1,0.6-0.1,0.9h-2C715.5,655.1,715.5,654.7,715.6,654.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000047026277860055131530000002694497468845726866_" class="st1" d="M742.3,639.4L742.3,639.4
							c-5.4,2.9-11,5.2-16.9,7.1h0C731.3,644.6,736.9,642.3,742.3,639.4z"/>
					</defs>
					<clipPath id="SVGID_00000048482211365733490220000003681956998526498184_">
						<use xlink:href="#SVGID_00000047026277860055131530000002694497468845726866_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000048482211365733490220000003681956998526498184_);enable-background:new    ;">
						<path class="st3" d="M742.3,639.4L742.3,639.4c-5.4,2.9-11,5.2-16.9,7.1h0C731.3,644.6,736.9,642.3,742.3,639.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000016757593267846899280000001780030402985983156_" class="st1" d="M744,639.3L744,639.3
							c-0.5,0-1.1,0-1.7,0.2h0C742.9,639.3,743.4,639.3,744,639.3z"/>
					</defs>
					<clipPath id="SVGID_00000085222821716796791990000002908454045418371763_">
						<use xlink:href="#SVGID_00000016757593267846899280000001780030402985983156_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000085222821716796791990000002908454045418371763_);enable-background:new    ;">
						<path class="st3" d="M744,639.3L744,639.3c-0.5,0-1.1,0-1.7,0.2h0C742.9,639.3,743.4,639.3,744,639.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000041974216989316714590000003745687069023025594_" class="st1" d="M721.8,456L721.8,456
							c8.4,2.3,16.3,5.6,23.6,9.8h0C738,461.5,730.1,458.2,721.8,456z"/>
					</defs>
					<clipPath id="SVGID_00000128457627280736546770000008455935951627998361_">
						<use xlink:href="#SVGID_00000041974216989316714590000003745687069023025594_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000128457627280736546770000008455935951627998361_);enable-background:new    ;">
						<path class="st33" d="M721.8,456L721.8,456c2.1,0.6,4.2,1.2,6.3,1.9h0C726,457.2,723.9,456.5,721.8,456"/>
						<path class="st32" d="M728,457.9L728,457.9c5.7,1.9,11.1,4.4,16.2,7.3h0C739.1,462.2,733.7,459.8,728,457.9"/>
						<path class="st31" d="M744.2,465.1L744.2,465.1c0.4,0.2,0.8,0.4,1.2,0.6h0C745,465.6,744.6,465.3,744.2,465.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000054263804148624125390000000667883530015336586_" class="st1" d="M745.4,465.8L745.4,465.8
							c0.6,0.1,1.3,0.1,1.9,0.1h0C746.6,465.9,746,465.8,745.4,465.8z"/>
					</defs>
					<clipPath id="SVGID_00000039825824786847053950000000423589476318021010_">
						<use xlink:href="#SVGID_00000054263804148624125390000000667883530015336586_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000039825824786847053950000000423589476318021010_);enable-background:new    ;">
						<path class="st34" d="M745.4,465.8L745.4,465.8c0.5,0,0.9,0.1,1.4,0.1h0C746.3,465.9,745.8,465.8,745.4,465.8"/>
						<path class="st16" d="M746.7,465.9L746.7,465.9c0.2,0,0.4,0,0.6,0h0C747.1,465.9,746.9,465.9,746.7,465.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000004508342776362270160000002949516678887862718_" class="st1" d="M741.9,637.4l0.4,2
							c-5.4,2.9-11,5.2-16.9,7.1l-10.3,1.1C730.4,642.7,736.1,640.4,741.9,637.4z"/>
					</defs>
					<clipPath id="SVGID_00000108267626960419555590000000350486440058269369_">
						<use xlink:href="#SVGID_00000004508342776362270160000002949516678887862718_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000108267626960419555590000000350486440058269369_);enable-background:new    ;">
						<path class="st9" d="M741.9,637.4l0.4,2c-3.1,1.7-6.3,3.1-9.6,4.5l-2.6-1.3C735.2,640.7,738.6,639.2,741.9,637.4"/>
						<path class="st8" d="M730.1,642.6l2.6,1.3c-2.4,1-4.8,1.8-7.3,2.6l-10.3,1.1C721.6,645.5,726.3,643.9,730.1,642.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000051348218059768422440000010328781787243601335_" class="st1" d="M720.6,457.7l1.2-1.8
							c8.4,2.3,16.3,5.6,23.6,9.8l-1,1.8C737.2,463.4,729.2,460.2,720.6,457.7z"/>
					</defs>
					<clipPath id="SVGID_00000101061191836678492210000010740851292707024316_">
						<use xlink:href="#SVGID_00000051348218059768422440000010328781787243601335_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000101061191836678492210000010740851292707024316_);enable-background:new    ;">
						<path class="st52" d="M720.6,457.7l1.2-1.8c7.5,2,14.7,4.9,21.4,8.6l-1,1.8C735.6,462.7,728.3,459.9,720.6,457.7"/>
						<path class="st53" d="M742.2,466.3l1-1.8c0.7,0.4,1.5,0.8,2.2,1.2l-1,1.8C743.6,467.1,742.9,466.7,742.2,466.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000070105311286179116550000015305025344671948965_" class="st1" d="M753.5,641.6l-1.4,1.4
							c-0.2-0.2-3.3-3.8-8.1-3.8c-0.5,0-1.1,0-1.7,0.2l-0.4-2c0.8-0.2,1.4-0.2,2.1-0.2C749.7,637.3,753.3,641.4,753.5,641.6z"/>
					</defs>
					<clipPath id="SVGID_00000043432808250178493490000008743143628709917366_">
						<use xlink:href="#SVGID_00000070105311286179116550000015305025344671948965_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000043432808250178493490000008743143628709917366_);enable-background:new    ;">
						<path class="st18" d="M753.5,641.6l-1.4,1.4c-0.1-0.1-0.7-0.8-1.9-1.7l1.1-1.6C752.6,640.7,753.4,641.5,753.5,641.6"/>
						<path class="st5" d="M751.3,639.7l-1.1,1.6c-1.2-0.9-3-1.8-5.1-2l0.2-2C747.8,637.6,749.9,638.7,751.3,639.7"/>
						<path class="st7" d="M745.2,637.3l-0.2,2c-0.3,0-0.7-0.1-1-0.1c-0.4,0-0.9,0-1.3,0.1l-0.2-2c0.6-0.1,1-0.1,1.6-0.1
							C744.4,637.3,744.8,637.3,745.2,637.3"/>
						<path class="st8" d="M742.4,637.3l0.2,2c-0.1,0-0.3,0-0.4,0.1l-0.4-2C742.1,637.4,742.3,637.3,742.4,637.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000049924409183392834090000010671624361224118973_" class="st1" d="M744.3,467.6l1-1.8
							c0.6,0.1,1.3,0.1,1.9,0.1c2.1,0,4.1-0.4,5.5-1.8l1.4,1.4c-1.9,1.9-4.5,2.4-6.9,2.4C746.5,468,745.8,467.8,744.3,467.6z"/>
					</defs>
					<clipPath id="SVGID_00000145033106886030961290000000517356103607256194_">
						<use xlink:href="#SVGID_00000049924409183392834090000010671624361224118973_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000145033106886030961290000000517356103607256194_);enable-background:new    ;">
						<path class="st51" d="M744.3,467.6l1-1.8c0.4,0,0.7,0.1,1.1,0.1l-0.2,2C745.8,467.8,745.2,467.7,744.3,467.6"/>
						<path class="st14" d="M746.3,467.9l0.2-2c0.3,0,0.5,0,0.8,0c0.4,0,0.8,0,1.3-0.1l0.2,2c-0.5,0-1,0.1-1.5,0.1
							C746.9,467.9,746.6,467.9,746.3,467.9"/>
						<path class="st13" d="M748.7,467.8l-0.2-2c0.5,0,1-0.1,1.5-0.3l0.6,1.9C750,467.7,749.4,467.8,748.7,467.8"/>
						<path class="st12" d="M750.6,467.5l-0.6-1.9c0.4-0.1,0.7-0.2,1.1-0.4l0.9,1.8C751.5,467.2,751.1,467.4,750.6,467.5"/>
						<path class="st11" d="M752,467l-0.9-1.8c0.3-0.1,0.6-0.3,0.8-0.4l1.1,1.7C752.7,466.6,752.3,466.8,752,467"/>
						<path class="st10" d="M753.1,466.4l-1.1-1.7c0.3-0.2,0.5-0.3,0.7-0.6l1.4,1.5C753.7,465.9,753.4,466.2,753.1,466.4"/>
						<path class="st9" d="M754,465.7l-1.4-1.5c0,0,0.1-0.1,0.1-0.1l1.4,1.4C754.1,465.6,754.1,465.6,754,465.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000090262858733871777340000010174223928183633564_" class="st1" d="M761.9,650.1l-1.4,1.4
							c-5.7-5.7-8.3-8.3-8.5-8.5l1.4-1.4C753.6,641.7,756.3,644.4,761.9,650.1z"/>
					</defs>
					<clipPath id="SVGID_00000059998711925523858430000001702216352652348344_">
						<use xlink:href="#SVGID_00000090262858733871777340000010174223928183633564_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000059998711925523858430000001702216352652348344_);enable-background:new    ;">
						<path class="st18" d="M761.9,650.1l-1.4,1.4c-5.7-5.7-8.3-8.3-8.5-8.5l1.4-1.4C753.6,641.7,756.3,644.4,761.9,650.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000174585355993358501190000012792809826846048906_" class="st1" d="M773.3,651.5L773.3,651.5
							c-0.1,0.1-3.1,2.5-6.9,2.5h0C770.1,654,773.1,651.6,773.3,651.5z"/>
					</defs>
					<clipPath id="SVGID_00000153668313164294906790000016752157186593827215_">
						<use xlink:href="#SVGID_00000174585355993358501190000012792809826846048906_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000153668313164294906790000016752157186593827215_);enable-background:new    ;">
						<path class="st3" d="M773.3,651.5L773.3,651.5c-0.1,0.1-3.1,2.5-6.9,2.5h0C770.1,654,773.1,651.6,773.3,651.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000114771441446008037910000017666657205940558494_" class="st1" d="M768.9,451.6L768.9,451.6
							c1.4,0,2.9,0.5,4.3,1.9h0C771.9,452.1,770.4,451.6,768.9,451.6z"/>
					</defs>
					<clipPath id="SVGID_00000043415412623171710060000008841491521047932048_">
						<use xlink:href="#SVGID_00000114771441446008037910000017666657205940558494_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000043415412623171710060000008841491521047932048_);enable-background:new    ;">
						<path class="st16" d="M768.9,451.6L768.9,451.6c0.1,0,0.2,0,0.2,0h0C769.1,451.6,769,451.6,768.9,451.6"/>
						<path class="st34" d="M769.2,451.6L769.2,451.6c0.3,0,0.6,0,0.9,0.1h0C769.7,451.7,769.4,451.6,769.2,451.6"/>
						<path class="st33" d="M770,451.7L770,451.7c0.3,0,0.6,0.1,0.8,0.2h0C770.5,451.8,770.3,451.8,770,451.7"/>
						<path class="st32" d="M770.8,451.9L770.8,451.9c0.3,0.1,0.6,0.3,1,0.4h0C771.5,452.2,771.1,452,770.8,451.9"/>
						<path class="st31" d="M771.8,452.4L771.8,452.4c0.5,0.3,1,0.7,1.5,1.2h0C772.8,453,772.3,452.6,771.8,452.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000170275057400453319360000008190811249157544077_" class="st1" d="M771.9,650.1c0.1,0.1,0.3,0.3,0.4,0.4
							l1,1c-0.2,0.1-3.1,2.5-6.9,2.5c-1.9,0-3.9-0.6-5.8-2.5l1.4-1.4c1.5,1.5,3,1.9,4.4,1.9C769.4,652,771.8,650.1,771.9,650.1z"/>
					</defs>
					<clipPath id="SVGID_00000030481725151039597150000012202381370443653528_">
						<use xlink:href="#SVGID_00000170275057400453319360000008190811249157544077_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000030481725151039597150000012202381370443653528_);enable-background:new    ;">
						<polyline class="st9" points="771.8,650.1 773.3,651.5 773.3,651.5 771.8,650.1 771.8,650.1 						"/>
						<path class="st10" d="M771.8,650.1l1.4,1.4c0,0-0.2,0.2-0.5,0.4l-1.2-1.6C771.8,650.2,771.9,650.1,771.8,650.1"/>
						<path class="st9" d="M771.5,650.3l1.2,1.6c-0.5,0.3-1.2,0.8-2.2,1.2l-0.8-1.8C770.6,650.9,771.2,650.5,771.5,650.3"/>
						<path class="st8" d="M769.8,651.3l0.8,1.8c-0.7,0.3-1.6,0.6-2.5,0.8l-0.3-2C768.5,651.7,769.2,651.5,769.8,651.3"/>
						<path class="st7" d="M767.7,651.9l0.3,2c-0.5,0.1-1.1,0.2-1.7,0.2c-0.3,0-0.6,0-0.9-0.1l0.2-2c0.2,0,0.5,0,0.7,0
							C766.8,652,767.3,652,767.7,651.9"/>
						<path class="st5" d="M765.7,652l-0.2,2c-1.2-0.1-2.5-0.6-3.7-1.5l1.1-1.6C763.8,651.5,764.7,651.9,765.7,652"/>
						<path class="st18" d="M762.8,650.9l-1.1,1.6c-0.4-0.3-0.8-0.6-1.2-1l1.4-1.4C762.2,650.4,762.5,650.6,762.8,650.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000033343506693282448460000009227287631293050028_" class="st1" d="M772.3,650.5l-0.4-0.4c0,0,0,0,0,0
							C772,650.2,772.1,650.4,772.3,650.5z"/>
					</defs>
					<clipPath id="SVGID_00000076589604490483954460000006412523889963593362_">
						<use xlink:href="#SVGID_00000033343506693282448460000009227287631293050028_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000076589604490483954460000006412523889963593362_);enable-background:new    ;">
						<polyline class="st9" points="771.8,650.1 772.3,650.5 772.3,650.5 771.8,650.1 771.8,650.1 						"/>
						<polyline class="st10" points="771.8,650.1 772.3,650.5 772.3,650.5 771.9,650.1 771.8,650.1 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000018234935863735351080000018080618000746700734_" class="st1" d="M754.2,465.5l-1.4-1.4
							c3.5-3.5,8.9-8.9,9.2-9.2c0.2-0.2,3.3-3.3,7-3.3c1.4,0,2.9,0.5,4.3,1.9l-1.4,1.4c-1-1-2-1.3-2.9-1.3c-2.8,0-5.5,2.6-5.6,2.7
							C763.1,456.6,757.7,462,754.2,465.5z"/>
					</defs>
					<clipPath id="SVGID_00000020367241255178649700000016805739939621269380_">
						<use xlink:href="#SVGID_00000018234935863735351080000018080618000746700734_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000020367241255178649700000016805739939621269380_);enable-background:new    ;">
						<path class="st9" d="M754.2,465.5l-1.4-1.4c3.5-3.5,8.9-8.9,9.2-9.2c0,0,0.1-0.1,0.2-0.2l1.4,1.4c-0.1,0.1-0.1,0.1-0.1,0.1
							C763.1,456.6,757.7,462,754.2,465.5"/>
						<path class="st10" d="M763.5,456.2l-1.4-1.4c0.3-0.3,1-0.9,1.9-1.5l1,1.7C764.3,455.5,763.8,456,763.5,456.2"/>
						<path class="st11" d="M765.1,455l-1-1.7c0.5-0.3,1.1-0.7,1.7-0.9l0.7,1.9C766,454.4,765.5,454.7,765.1,455"/>
						<path class="st12" d="M766.5,454.2l-0.7-1.9c0.4-0.2,0.9-0.3,1.4-0.5l0.4,1.9C767.2,453.9,766.8,454,766.5,454.2"/>
						<path class="st13" d="M767.5,453.8l-0.4-1.9c0.4-0.1,0.7-0.2,1.1-0.2l0.2,2C768.1,453.7,767.8,453.7,767.5,453.8"/>
						<path class="st14" d="M768.4,453.6l-0.2-2c0.2,0,0.5,0,0.7,0c0.1,0,0.3,0,0.4,0l-0.1,2c-0.1,0-0.2,0-0.3,0
							C768.8,453.6,768.6,453.6,768.4,453.6"/>
						<path class="st51" d="M769.2,453.6l0.1-2c0.3,0,0.7,0.1,1,0.2l-0.5,1.9C769.6,453.7,769.4,453.6,769.2,453.6"/>
						<path class="st52" d="M769.9,453.7l0.5-1.9c0.4,0.1,0.8,0.2,1.1,0.4l-0.9,1.8C770.4,453.9,770.1,453.8,769.9,453.7"/>
						<path class="st53" d="M770.6,454l0.9-1.8c0.6,0.3,1.2,0.7,1.8,1.3l-1.4,1.4C771.4,454.5,771,454.2,770.6,454"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000010286342607601325060000015348122905065839784_" class="st1" d="M782.6,599.7L782.6,599.7
							c-0.6,2.7-1.2,7.6,1.2,10.1h0C781.4,607.3,782,602.4,782.6,599.7z"/>
					</defs>
					<clipPath id="SVGID_00000164502149281055708850000000213169746984475050_">
						<use xlink:href="#SVGID_00000010286342607601325060000015348122905065839784_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000164502149281055708850000000213169746984475050_);enable-background:new    ;">
						<path class="st16" d="M782.6,599.7L782.6,599.7c-0.1,0.4-0.2,0.8-0.2,1.3h0C782.5,600.5,782.6,600.1,782.6,599.7"/>
						<path class="st34" d="M782.4,601L782.4,601c-0.1,0.9-0.3,1.9-0.3,2.9h0C782.1,602.9,782.2,601.9,782.4,601"/>
						<path class="st33" d="M782.1,603.9L782.1,603.9c0,0.7,0,1.4,0.1,2h0C782.1,605.2,782.1,604.6,782.1,603.9"/>
						<path class="st32" d="M782.2,605.9L782.2,605.9c0.1,0.5,0.2,1.1,0.4,1.6h0C782.4,607,782.3,606.4,782.2,605.9"/>
						<path class="st31" d="M782.5,607.5L782.5,607.5c0.3,0.9,0.7,1.7,1.4,2.3h0C783.2,609.1,782.8,608.3,782.5,607.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000085964314741986849140000014706463883700770445_" class="st1" d="M786,495.9L786,495.9
							c-0.2,0.1-3.3,2.9-3.7,7.2h0C782.7,498.9,785.8,496.1,786,495.9z"/>
					</defs>
					<clipPath id="SVGID_00000173139556735181785760000007149622129510031532_">
						<use xlink:href="#SVGID_00000085964314741986849140000014706463883700770445_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000173139556735181785760000007149622129510031532_);enable-background:new    ;">
						<path class="st3" d="M786,495.9L786,495.9c-0.1,0.1-1.4,1.2-2.4,3.2h0C784.6,497.2,785.9,496,786,495.9"/>
						<path class="st35" d="M783.5,499.1L783.5,499.1c-0.3,0.6-0.6,1.2-0.8,1.8h0C783,500.3,783.2,499.6,783.5,499.1"/>
						<path class="st16" d="M782.7,500.9L782.7,500.9c-0.1,0.5-0.3,1-0.4,1.5h0C782.5,501.9,782.6,501.4,782.7,500.9"/>
						<path class="st34" d="M782.4,502.4L782.4,502.4c0,0.2-0.1,0.5-0.1,0.7h0C782.3,502.9,782.3,502.7,782.4,502.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000180342670968197694370000015285579210586049193_" class="st1" d="M696.5,634.5l0-2c43.6,0,79-35.4,79-79
							c0-43.6-35.4-79-79-79c-43.6,0-79,35.4-79,79c0,43.6,35.4,79,79,79L696.5,634.5c-44.7,0-81-36.3-81-81c0-44.7,36.3-81,81-81
							c44.7,0,81,36.3,81,81C777.5,598.2,741.2,634.5,696.5,634.5z"/>
					</defs>
					<clipPath id="SVGID_00000078014837607732494350000000762387761443804042_">
						<use xlink:href="#SVGID_00000180342670968197694370000015285579210586049193_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000078014837607732494350000000762387761443804042_);enable-background:new    ;">
						<path class="st14" d="M696.5,634.5l0-2c2.7,0,5.3-0.1,7.9-0.4l0.2,2C701.9,634.4,699.2,634.5,696.5,634.5"/>
						<path class="st13" d="M704.6,634.1l-0.2-2c4.1-0.4,8.1-1.1,12-2.2l0.5,1.9C713,632.9,708.8,633.7,704.6,634.1"/>
						<path class="st12" d="M717,631.9l-0.5-1.9c4.1-1.1,8.1-2.5,11.9-4.1l0.8,1.8C725.2,629.4,721.2,630.8,717,631.9"/>
						<path class="st11" d="M729.2,627.6l-0.8-1.8c3.9-1.7,7.7-3.8,11.2-6.1l1.1,1.7C737,623.8,733.2,625.9,729.2,627.6"/>
						<path class="st10" d="M740.7,621.4l-1.1-1.7c3.9-2.5,7.5-5.4,10.9-8.6l1.4,1.5C748.4,615.9,744.7,618.8,740.7,621.4"/>
						<path class="st9" d="M751.9,612.6l-1.4-1.5c3.8-3.5,7.2-7.4,10.2-11.6l1.6,1.2C759.2,605,755.7,609,751.9,612.6"/>
						<path class="st8" d="M762.3,600.7l-1.6-1.2c3.4-4.8,6.3-9.9,8.6-15.4l1.8,0.8C768.8,590.6,765.8,595.9,762.3,600.7"/>
						<path class="st7" d="M771.1,585l-1.8-0.8c2.9-6.9,4.9-14.3,5.7-22l2,0.2C776.1,570.3,774.1,577.9,771.1,585"/>
						<path class="st5" d="M777,562.4l-2-0.2c0.3-2.8,0.5-5.7,0.5-8.7c0-10.9-2.2-21.3-6.2-30.8l1.8-0.8c4.1,9.7,6.4,20.4,6.4,31.6
							C777.5,556.5,777.3,559.5,777,562.4"/>
						<path class="st18" d="M771.1,521.9l-1.8,0.8c-5.7-13.5-15.1-25.1-26.9-33.5l1.2-1.6C755.6,496.2,765.2,508.1,771.1,521.9"/>
						<path class="st5" d="M743.5,487.5l-1.2,1.6c-10.7-7.6-23.3-12.6-37-14.2l0.2-2C719.6,474.6,732.6,479.7,743.5,487.5"/>
						<path class="st7" d="M705.5,473l-0.2,2c-2.9-0.3-5.8-0.5-8.8-0.5c-4.8,0-9.4,0.4-14,1.2l-0.4-2c4.7-0.8,9.4-1.3,14.3-1.3
							C699.6,472.5,702.6,472.7,705.5,473"/>
						<path class="st8" d="M682.1,473.8l0.4,2c-5.9,1-11.5,2.7-16.8,5l-0.8-1.8C670.4,476.6,676.1,474.8,682.1,473.8"/>
						<path class="st9" d="M664.9,478.9l0.8,1.8c-4.7,2-9.2,4.4-13.4,7.3l-1.1-1.7C655.5,483.5,660.1,480.9,664.9,478.9"/>
						<path class="st10" d="M651.2,486.4l1.1,1.7c-3.8,2.6-7.4,5.5-10.6,8.6l-1.4-1.4C643.6,492,647.3,489,651.2,486.4"/>
						<path class="st11" d="M640.3,495.2l1.4,1.4c-3.1,3-6,6.2-8.5,9.7l-1.6-1.2C634.1,501.6,637.1,498.3,640.3,495.2"/>
						<path class="st12" d="M631.5,505.2l1.6,1.2c-2.5,3.3-4.7,6.8-6.6,10.5l-1.8-0.9C626.7,512.2,629,508.6,631.5,505.2"/>
						<path class="st13" d="M624.7,515.9l1.8,0.9c-1.9,3.7-3.6,7.5-4.9,11.5l-1.9-0.6C621.1,523.7,622.7,519.7,624.7,515.9"/>
						<path class="st14" d="M619.7,527.7l1.9,0.6c-1.3,3.9-2.3,8-3,12.2l-2-0.3C617.3,535.9,618.3,531.8,619.7,527.7"/>
						<path class="st51" d="M616.6,540.2l2,0.3c-0.7,4.2-1.1,8.5-1.1,12.9c0,0.5,0,0.9,0,1.4l-2,0c0-0.5,0-1,0-1.4
							C615.5,549,615.9,544.5,616.6,540.2"/>
						<path class="st52" d="M615.5,554.9l2,0c0.1,6,0.9,11.8,2.2,17.4l-1.9,0.5C616.4,567,615.6,561.1,615.5,554.9"/>
						<path class="st53" d="M617.8,572.8l1.9-0.5c5.5,22.7,20.9,41.6,41.3,51.8l-0.9,1.8C639.2,615.4,623.5,596,617.8,572.8"/>
						<path class="st52" d="M660.1,625.9l0.9-1.8c5.1,2.6,10.5,4.6,16.1,6l-0.5,1.9C670.9,630.6,665.4,628.5,660.1,625.9"/>
						<path class="st51" d="M676.7,632.1l0.5-1.9c4.5,1.1,9.2,1.9,14,2.2l-0.1,2C686.1,634,681.3,633.2,676.7,632.1"/>
						<path class="st14" d="M691,634.3l0.1-2c1.8,0.1,3.5,0.2,5.3,0.2l0,2C694.7,634.5,692.8,634.4,691,634.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000023975458082351520790000013808590817235821222_" class="st1" d="M791,579.7L791,579.7
							c-2,7-4.9,13.7-8.3,20h0C786.1,593.4,788.9,586.7,791,579.7z"/>
					</defs>
					<clipPath id="SVGID_00000086652102070500771100000011682253942260970655_">
						<use xlink:href="#SVGID_00000023975458082351520790000013808590817235821222_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000086652102070500771100000011682253942260970655_);enable-background:new    ;">
						<path class="st16" d="M791,579.7L791,579.7c-0.3,1.1-0.6,2.2-1,3.2h0C790.3,581.9,790.7,580.8,791,579.7"/>
						<path class="st35" d="M790,582.9L790,582.9c-1.8,5.6-4.2,11-6.9,16.1h0C785.8,593.9,788.1,588.5,790,582.9"/>
						<path class="st3" d="M783,599L783,599c-0.1,0.2-0.2,0.5-0.4,0.7h0C782.8,599.5,782.9,599.2,783,599"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000103238735310786899870000010987519877546661566_" class="st1" d="M780.9,598.7l1.8,1
							c-0.6,2.7-1.3,7.6,1.2,10.1l-1.4,1.4C779.2,607.9,780,602.2,780.9,598.7z"/>
					</defs>
					<clipPath id="SVGID_00000128486869402236856420000010559116180187264411_">
						<use xlink:href="#SVGID_00000103238735310786899870000010987519877546661566_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000128486869402236856420000010559116180187264411_);enable-background:new    ;">
						<path class="st14" d="M780.9,598.7l1.8,1c-0.1,0.4-0.2,0.8-0.3,1.3l-1.9-0.6C780.6,599.8,780.8,599.2,780.9,598.7"/>
						<path class="st51" d="M780.5,600.3l1.9,0.6c-0.2,1-0.3,2.2-0.3,3.4l-2,0.1C780.1,603,780.3,601.6,780.5,600.3"/>
						<path class="st52" d="M780.1,604.4l2-0.1c0,0.8,0.1,1.6,0.2,2.4l-1.9,0.6C780.2,606.4,780.1,605.4,780.1,604.4"/>
						<path class="st53" d="M780.4,607.3l1.9-0.6c0.3,1.2,0.7,2.2,1.5,3l-1.4,1.4C781.4,610.1,780.8,608.8,780.4,607.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000116957248647935745610000008047774248187452824_" class="st1" d="M782.3,503.1L782.3,503.1
							c4,7,7.1,14.6,9.3,22.6h0C789.4,517.8,786.2,510.2,782.3,503.1z"/>
					</defs>
					<clipPath id="SVGID_00000000917829220415823260000010710358310780138903_">
						<use xlink:href="#SVGID_00000116957248647935745610000008047774248187452824_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000000917829220415823260000010710358310780138903_);enable-background:new    ;">
						<path class="st31" d="M782.3,503.1L782.3,503.1c3.3,5.8,6,12,8,18.4h0C788.2,515.1,785.5,509,782.3,503.1"/>
						<path class="st32" d="M790.3,521.6L790.3,521.6c0.5,1.4,0.9,2.8,1.2,4.2h0C791.2,524.4,790.7,523,790.3,521.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000134218366045363730080000002199184743729846158_" class="st1" d="M783.9,609.8L783.9,609.8
							c3.5,3.5,8.9,8.9,9.2,9.2h0C792.8,618.7,787.4,613.3,783.9,609.8z"/>
					</defs>
					<clipPath id="SVGID_00000099628293889813228990000010124984668449763241_">
						<use xlink:href="#SVGID_00000134218366045363730080000002199184743729846158_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000099628293889813228990000010124984668449763241_);enable-background:new    ;">
						<path class="st31" d="M783.9,609.8L783.9,609.8c3.5,3.5,8.9,8.9,9.2,9.2h0C792.8,618.7,787.4,613.3,783.9,609.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000005955244348106043580000000811853497517166004_" class="st1" d="M784.6,494.5l1.4,1.4
							c-0.2,0.1-3.3,2.9-3.7,7.2l-2.1,0.4C780.8,497.9,784.4,494.6,784.6,494.5z"/>
					</defs>
					<clipPath id="SVGID_00000176723450825547062460000002940750090512491707_">
						<use xlink:href="#SVGID_00000005955244348106043580000000811853497517166004_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000176723450825547062460000002940750090512491707_);enable-background:new    ;">
						<path class="st10" d="M784.6,494.5l1.4,1.4c0,0-0.1,0.1-0.2,0.2l-1.4-1.4C784.5,494.6,784.6,494.5,784.6,494.5"/>
						<path class="st11" d="M784.4,494.7l1.4,1.4c-0.3,0.2-0.7,0.7-1.2,1.4l-1.6-1.2C783.6,495.5,784.1,495,784.4,494.7"/>
						<path class="st12" d="M783,496.3l1.6,1.2c-0.3,0.4-0.7,1-1,1.6l-1.8-0.8C782.2,497.5,782.6,496.8,783,496.3"/>
						<path class="st13" d="M781.8,498.3l1.8,0.8c-0.3,0.5-0.5,1-0.7,1.6l-1.9-0.4C781.2,499.5,781.5,498.9,781.8,498.3"/>
						<path class="st14" d="M780.9,500.3l1.9,0.4c-0.2,0.5-0.4,1.1-0.5,1.7l-2,0.2C780.5,501.7,780.7,500.9,780.9,500.3"/>
						<path class="st51" d="M780.4,502.5l2-0.2c0,0.3-0.1,0.5-0.1,0.8l-2.1,0.4C780.3,503.2,780.3,502.8,780.4,502.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000173143719550032718460000014688955818177922971_" class="st1" d="M794.5,630.3L794.5,630.3
							c-4.9,5-20.4,20.4-21.2,21.2h0C774.1,650.7,789.5,635.2,794.5,630.3z"/>
					</defs>
					<clipPath id="SVGID_00000101820228900518674900000011376007618560219564_">
						<use xlink:href="#SVGID_00000173143719550032718460000014688955818177922971_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000101820228900518674900000011376007618560219564_);enable-background:new    ;">
						<path class="st3" d="M794.5,630.3L794.5,630.3c-4.9,5-20.4,20.4-21.2,21.2h0C774.1,650.7,789.5,635.2,794.5,630.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000013903293913607913670000005087862374478724018_" class="st1" d="M794.5,487.4L794.5,487.4
							c-5.6,5.7-8.3,8.3-8.5,8.5h0C786.1,495.8,788.8,493.1,794.5,487.4z"/>
					</defs>
					<clipPath id="SVGID_00000153707405701456973330000018443455760798641804_">
						<use xlink:href="#SVGID_00000013903293913607913670000005087862374478724018_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000153707405701456973330000018443455760798641804_);enable-background:new    ;">
						<path class="st3" d="M794.5,487.4L794.5,487.4c-5.6,5.7-8.3,8.3-8.5,8.5h0C786.1,495.8,788.8,493.1,794.5,487.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000008106637578530717110000013682429302804739462_" class="st1" d="M773.3,453.5L773.3,453.5
							c5,4.9,20.4,20.4,21.2,21.2h0C793.7,473.9,778.2,458.5,773.3,453.5z"/>
					</defs>
					<clipPath id="SVGID_00000154425578421316910590000006442326465803067065_">
						<use xlink:href="#SVGID_00000008106637578530717110000013682429302804739462_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000154425578421316910590000006442326465803067065_);enable-background:new    ;">
						<path class="st31" d="M773.3,453.5L773.3,453.5c5,4.9,20.4,20.4,21.2,21.2h0C793.7,473.9,778.2,458.5,773.3,453.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000089532374255170463460000005604365931516645028_" class="st1" d="M789.2,578.7l1.8,1
							c-2.1,7-4.9,13.7-8.3,20l-1.8-1C784.3,592.6,787,585.8,789.2,578.7z"/>
					</defs>
					<clipPath id="SVGID_00000143609120261887669650000012074486691818698641_">
						<use xlink:href="#SVGID_00000089532374255170463460000005604365931516645028_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000143609120261887669650000012074486691818698641_);enable-background:new    ;">
						<path class="st14" d="M789.2,578.7l1.8,1c-0.3,0.9-0.5,1.7-0.8,2.6l-1.8-1C788.6,580.4,788.9,579.6,789.2,578.7"/>
						<path class="st13" d="M788.4,581.3l1.8,1c-1.7,5.3-3.9,10.5-6.4,15.4l-1.8-0.9C784.5,591.9,786.5,586.7,788.4,581.3"/>
						<path class="st12" d="M781.9,596.7l1.8,0.9c-0.4,0.7-0.7,1.4-1.1,2l-1.8-1C781.2,598.1,781.6,597.4,781.9,596.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000133507920106873309630000014193125836161521039_" class="st1" d="M791.5,525.8L791.5,525.8
							c1.7,2.2,4.2,4.7,7,4.7h0C795.7,530.5,793.2,528,791.5,525.8z"/>
					</defs>
					<clipPath id="SVGID_00000162320472573162679290000011363308238559155121_">
						<use xlink:href="#SVGID_00000133507920106873309630000014193125836161521039_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000162320472573162679290000011363308238559155121_);enable-background:new    ;">
						<path class="st31" d="M791.5,525.8L791.5,525.8c1.1,1.4,2.5,3,4.2,3.9h0C794,528.8,792.6,527.2,791.5,525.8"/>
						<path class="st32" d="M795.7,529.7L795.7,529.7c0.4,0.2,0.8,0.4,1.1,0.5h0C796.4,530.1,796,529.9,795.7,529.7"/>
						<path class="st33" d="M796.8,530.2L796.8,530.2c0.3,0.1,0.5,0.2,0.8,0.2h0C797.3,530.4,797.1,530.3,796.8,530.2"/>
						<path class="st34" d="M797.6,530.4L797.6,530.4c0.2,0,0.5,0.1,0.7,0.1h0C798.1,530.5,797.8,530.5,797.6,530.4"/>
						<path class="st16" d="M798.3,530.5L798.3,530.5c0.1,0,0.1,0,0.2,0h0C798.4,530.5,798.4,530.5,798.3,530.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000132802765844652217520000001813595610040294543_" class="st1" d="M780.2,503.6l2.1-0.4
							c4,7,7.1,14.6,9.3,22.6l-2,0.5C787.4,518.7,784.4,511,780.2,503.6z"/>
					</defs>
					<clipPath id="SVGID_00000091697959697395311260000001517634703561806776_">
						<use xlink:href="#SVGID_00000132802765844652217520000001813595610040294543_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000091697959697395311260000001517634703561806776_);enable-background:new    ;">
						<path class="st53" d="M780.2,503.6l2.1-0.4c4,7,7.1,14.6,9.3,22.6l-2,0.5C787.4,518.7,784.4,511,780.2,503.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000053540069782568284780000005183236690764230053_" class="st1" d="M799.4,576.5L799.4,576.5
							c-1.6,0-5.6,0.3-8.4,3.2h0C793.8,576.8,797.8,576.5,799.4,576.5z"/>
					</defs>
					<clipPath id="SVGID_00000163789744564099223360000017022922344976415894_">
						<use xlink:href="#SVGID_00000053540069782568284780000005183236690764230053_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000163789744564099223360000017022922344976415894_);enable-background:new    ;">
						<path class="st3" d="M799.4,576.5L799.4,576.5c-1.6,0-5.6,0.3-8.4,3.2h0C793.8,576.8,797.8,576.5,799.4,576.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000177446198778364686570000008738654459464184214_" class="st1" d="M793.1,619L793.1,619
							c0.3,0.3,6.4,6.4,1.4,11.3h0C799.4,625.3,793.4,619.3,793.1,619z"/>
					</defs>
					<clipPath id="SVGID_00000031194546841557856030000002275086918820694656_">
						<use xlink:href="#SVGID_00000177446198778364686570000008738654459464184214_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000031194546841557856030000002275086918820694656_);enable-background:new    ;">
						<path class="st31" d="M793.1,619L793.1,619c0.2,0.2,2.2,2.2,3,4.8h0C795.2,621.1,793.2,619.1,793.1,619"/>
						<path class="st32" d="M796.1,623.7L796.1,623.7c0.2,0.4,0.3,0.9,0.3,1.3h0C796.3,624.6,796.2,624.2,796.1,623.7"/>
						<path class="st33" d="M796.4,625.1L796.4,625.1c0.1,0.3,0.1,0.6,0.1,1h0C796.4,625.7,796.4,625.4,796.4,625.1"/>
						<path class="st34" d="M796.4,626L796.4,626c0,0.3,0,0.6-0.1,0.8h0C796.4,626.6,796.4,626.3,796.4,626"/>
						<path class="st16" d="M796.3,626.9L796.3,626.9c0,0.3-0.1,0.6-0.2,0.8h0C796.2,627.4,796.3,627.2,796.3,626.9"/>
						<path class="st35" d="M796.1,627.7L796.1,627.7c-0.1,0.3-0.2,0.6-0.4,1l0,0C795.9,628.3,796,628,796.1,627.7"/>
						<path class="st3" d="M795.7,628.7L795.7,628.7c-0.3,0.5-0.7,1.1-1.2,1.6h0C795,629.8,795.4,629.2,795.7,628.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000053525013799283319830000009057869242950746003_" class="st1" d="M794.5,474.7L794.5,474.7
							c0.3,0.4,5.7,7.1,0,12.7h0C800.1,481.8,794.8,475.1,794.5,474.7z"/>
					</defs>
					<clipPath id="SVGID_00000036952517363183537150000004229664982563869081_">
						<use xlink:href="#SVGID_00000053525013799283319830000009057869242950746003_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000036952517363183537150000004229664982563869081_);enable-background:new    ;">
						<path class="st31" d="M794.5,474.7L794.5,474.7c0.1,0.2,1.4,1.7,2.1,3.9h0C795.9,476.5,794.6,474.9,794.5,474.7"/>
						<path class="st32" d="M796.6,478.7L796.6,478.7c0.2,0.6,0.3,1.2,0.4,1.8h0C796.9,479.8,796.8,479.2,796.6,478.7"/>
						<path class="st33" d="M797,480.5L797,480.5c0.1,0.4,0.1,0.9,0.1,1.3h0C797.1,481.3,797,480.9,797,480.5"/>
						<path class="st34" d="M797.1,481.8L797.1,481.8c0,0.4,0,0.8-0.1,1.1h0C797,482.5,797,482.1,797.1,481.8"/>
						<path class="st16" d="M796.9,482.9L796.9,482.9c-0.1,0.4-0.1,0.7-0.3,1.1h0C796.8,483.6,796.9,483.3,796.9,482.9"/>
						<path class="st35" d="M796.7,484L796.7,484c-0.1,0.4-0.3,0.9-0.6,1.3h0C796.3,484.9,796.5,484.5,796.7,484"/>
						<path class="st3" d="M796.1,485.3L796.1,485.3c-0.4,0.7-0.9,1.4-1.6,2.1h0C795.2,486.7,795.7,486,796.1,485.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000171717727514864566680000018193233115450722234_" class="st1" d="M771.9,454.9l1.4-1.4
							c4.9,4.9,20.4,20.4,21.2,21.2l-1.4,1.4C792.2,475.3,776.8,459.9,771.9,454.9z"/>
					</defs>
					<clipPath id="SVGID_00000153699135315415335810000005975244601024681089_">
						<use xlink:href="#SVGID_00000171717727514864566680000018193233115450722234_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000153699135315415335810000005975244601024681089_);enable-background:new    ;">
						<path class="st53" d="M771.9,454.9l1.4-1.4c4.9,4.9,20.4,20.4,21.2,21.2l-1.4,1.4C792.2,475.3,776.8,459.9,771.9,454.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000114060746510285596630000018124918053625189527_" class="st1" d="M793.1,628.9l1.4,1.4
							c-4.9,5-20.4,20.4-21.2,21.2l-1.4-1.4C772.7,649.3,788.1,633.8,793.1,628.9z"/>
					</defs>
					<clipPath id="SVGID_00000137830284672287942350000013890370810446488214_">
						<use xlink:href="#SVGID_00000114060746510285596630000018124918053625189527_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000137830284672287942350000013890370810446488214_);enable-background:new    ;">
						<path class="st11" d="M793.1,628.9l1.4,1.4c-4.9,5-20.4,20.4-21.2,21.2l-1.4-1.4C772.7,649.3,788.1,633.8,793.1,628.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000000929264734218290830000015691197394802177929_" class="st1" d="M793.1,486l1.4,1.4
							c-5.7,5.7-8.3,8.3-8.5,8.5l-1.4-1.4C784.7,494.4,787.4,491.7,793.1,486z"/>
					</defs>
					<clipPath id="SVGID_00000180357246251457442060000013686265390098822810_">
						<use xlink:href="#SVGID_00000000929264734218290830000015691197394802177929_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000180357246251457442060000013686265390098822810_);enable-background:new    ;">
						<path class="st11" d="M793.1,486l1.4,1.4c-5.7,5.7-8.3,8.3-8.5,8.5l-1.4-1.4C784.7,494.4,787.4,491.7,793.1,486"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000110452356454017809880000004054529957737563807_" class="st1" d="M782.5,611.2l1.4-1.4
							c3.5,3.5,8.9,8.9,9.2,9.2c0.3,0.3,6.4,6.4,1.4,11.3l-1.4-1.4c3.5-3.4-1.2-8.2-1.4-8.5C791.4,620.1,786,614.7,782.5,611.2z"/>
					</defs>
					<clipPath id="SVGID_00000118361838840344070210000014803317881868218293_">
						<use xlink:href="#SVGID_00000110452356454017809880000004054529957737563807_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000118361838840344070210000014803317881868218293_);enable-background:new    ;">
						<path class="st53" d="M782.5,611.2l1.4-1.4c3.5,3.5,8.9,8.9,9.2,9.2c0.2,0.2,2.4,2.4,3.1,5.2l-2,0.3c-0.6-2.1-2.4-3.9-2.5-4
							C791.4,620.1,786,614.7,782.5,611.2"/>
						<path class="st52" d="M794.1,624.4l2-0.3c0.1,0.5,0.2,1.1,0.2,1.6l-2-0.1C794.3,625.2,794.3,624.8,794.1,624.4"/>
						<path class="st51" d="M794.4,625.6l2,0.1c0,0.4,0,0.7-0.1,1.1l-2-0.4C794.4,626.2,794.4,625.9,794.4,625.6"/>
						<path class="st14" d="M794.3,626.4l2,0.4c-0.1,0.3-0.1,0.6-0.2,0.9l-1.9-0.7C794.3,626.9,794.3,626.7,794.3,626.4"/>
						<path class="st13" d="M794.2,627.1l1.9,0.7c-0.1,0.3-0.2,0.6-0.4,0.8l-1.8-0.9C794.1,627.5,794.1,627.3,794.2,627.1"/>
						<path class="st12" d="M794,627.7l1.8,0.9c-0.1,0.3-0.3,0.5-0.5,0.8l-1.6-1.1C793.7,628.1,793.9,627.9,794,627.7"/>
						<path class="st11" d="M793.6,628.3l1.6,1.1c-0.2,0.3-0.5,0.6-0.7,0.9l-1.4-1.4C793.3,628.7,793.4,628.5,793.6,628.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000123430914439751343560000000866136918336481956_" class="st1" d="M793.1,476.1l1.4-1.4
							c0.3,0.4,5.7,7.1,0,12.7l-1.4-1.4C797.4,481.7,793.1,476.2,793.1,476.1z"/>
					</defs>
					<clipPath id="SVGID_00000123405107103885161670000004400479130275687826_">
						<use xlink:href="#SVGID_00000123430914439751343560000000866136918336481956_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000123405107103885161670000004400479130275687826_);enable-background:new    ;">
						<path class="st53" d="M793.1,476.1l1.4-1.4c0.1,0.2,1.7,2.1,2.3,4.6l-2,0.4C794.3,477.7,793.1,476.2,793.1,476.1"/>
						<path class="st52" d="M794.8,479.7l2-0.4c0.2,0.6,0.3,1.3,0.3,2l-2-0.1C795,480.8,794.9,480.2,794.8,479.7"/>
						<path class="st51" d="M795,481.3l2,0.1c0,0.5,0,1-0.1,1.5l-2-0.4C795,482.1,795,481.7,795,481.3"/>
						<path class="st14" d="M795,482.5l2,0.4c-0.1,0.4-0.2,0.8-0.3,1.2l-1.9-0.6C794.8,483.1,794.9,482.8,795,482.5"/>
						<path class="st13" d="M794.7,483.4l1.9,0.6c-0.1,0.4-0.3,0.8-0.5,1.1l-1.8-0.9C794.5,484,794.6,483.7,794.7,483.4"/>
						<path class="st12" d="M794.4,484.3l1.8,0.9c-0.2,0.4-0.4,0.8-0.7,1.1l-1.6-1.1C794,484.9,794.2,484.6,794.4,484.3"/>
						<path class="st11" d="M793.8,485.2l1.6,1.1c-0.3,0.4-0.6,0.8-1,1.1l-1.4-1.4C793.3,485.7,793.6,485.5,793.8,485.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000078043815979400978090000014413312163288451514_" class="st1" d="M789.6,526.3l2-0.5
							c1.6,2.2,4.1,4.7,6.9,4.7l0,2C794.7,532.5,791.7,529.4,789.6,526.3z"/>
					</defs>
					<clipPath id="SVGID_00000174587170265403731890000015745788613549238445_">
						<use xlink:href="#SVGID_00000078043815979400978090000014413312163288451514_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000174587170265403731890000015745788613549238445_);enable-background:new    ;">
						<path class="st63" d="M789.6,526.3l2-0.5c0.6,0.8,1.3,1.6,2.1,2.4l-1.4,1.3C791.2,528.5,790.3,527.4,789.6,526.3"/>
						<path class="st53" d="M792.2,529.5l1.4-1.3c0.8,0.7,1.6,1.3,2.5,1.8l-0.8,1.8C794.2,531.2,793.1,530.4,792.2,529.5"/>
						<path class="st52" d="M795.3,531.7l0.8-1.8c0.4,0.2,0.8,0.3,1.2,0.4l-0.4,2C796.3,532.2,795.8,532,795.3,531.7"/>
						<path class="st51" d="M796.9,532.3l0.4-2c0.3,0.1,0.6,0.1,0.9,0.1l-0.1,2C797.7,532.5,797.3,532.4,796.9,532.3"/>
						<path class="st14" d="M798.1,532.5l0.1-2c0.1,0,0.2,0,0.3,0l0,2C798.3,532.5,798.2,532.5,798.1,532.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000109735823108986706650000000482248273928052139_" class="st1" d="M820.5,567.5L820.5,567.5c0,0.5-1,9-9,9
							h0C819.5,576.5,820.4,568,820.5,567.5z"/>
					</defs>
					<clipPath id="SVGID_00000081647502609185294560000017158946473551459225_">
						<use xlink:href="#SVGID_00000109735823108986706650000000482248273928052139_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000081647502609185294560000017158946473551459225_);enable-background:new    ;">
						<path class="st34" d="M820.5,567.5L820.5,567.5c0,0.1,0,0.3-0.1,0.5h0C820.4,567.8,820.5,567.6,820.5,567.5"/>
						<path class="st16" d="M820.4,568L820.4,568c-0.1,0.5-0.2,1.2-0.5,2h0C820.2,569.2,820.3,568.5,820.4,568"/>
						<path class="st35" d="M819.9,570L819.9,570c-0.2,0.6-0.5,1.3-0.8,2h0C819.4,571.3,819.7,570.6,819.9,570"/>
						<path class="st3" d="M819.1,572L819.1,572c-1.2,2.3-3.5,4.5-7.6,4.5h0C815.6,576.5,817.8,574.2,819.1,572"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000087392106348107478370000003567543138976770206_" class="st1" d="M820.5,537.5L820.5,537.5
							c0,7,0,28.8,0,30h0C820.5,566.3,820.5,544.5,820.5,537.5z"/>
					</defs>
					<clipPath id="SVGID_00000060739706385337617950000004072096427254831759_">
						<use xlink:href="#SVGID_00000087392106348107478370000003567543138976770206_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000060739706385337617950000004072096427254831759_);enable-background:new    ;">
						<path class="st33" d="M820.5,537.5L820.5,537.5c0,7,0,28.8,0,30h0C820.5,566.3,820.5,544.5,820.5,537.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000179622891485600059640000002865735717608077224_" class="st1" d="M811.5,530.5L811.5,530.5c0.5,0,9,0,9,7
							h0C820.5,530.5,811.9,530.5,811.5,530.5z"/>
					</defs>
					<clipPath id="SVGID_00000039125170715789833010000013935152077919498928_">
						<use xlink:href="#SVGID_00000179622891485600059640000002865735717608077224_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000039125170715789833010000013935152077919498928_);enable-background:new    ;">
						<path class="st16" d="M811.5,530.5L811.5,530.5c0.1,0,0.2,0,0.3,0h0C811.6,530.5,811.5,530.5,811.5,530.5"/>
						<path class="st34" d="M811.8,530.5L811.8,530.5c0.4,0,1.2,0.1,2,0.2h0C812.9,530.6,812.2,530.5,811.8,530.5"/>
						<path class="st33" d="M813.8,530.7L813.8,530.7c0.6,0.1,1.2,0.3,1.9,0.5h0C815,531,814.4,530.8,813.8,530.7"/>
						<path class="st32" d="M815.7,531.2L815.7,531.2c0.5,0.2,1.1,0.4,1.6,0.7h0C816.7,531.6,816.2,531.4,815.7,531.2"/>
						<path class="st31" d="M817.2,531.9L817.2,531.9c1.3,0.7,2.4,1.8,2.9,3.4h0C819.6,533.7,818.5,532.6,817.2,531.9"/>
						<path class="st32" d="M820.2,535.3L820.2,535.3c0.1,0.4,0.2,0.8,0.3,1.2h0C820.4,536.1,820.3,535.7,820.2,535.3"/>
						<path class="st33" d="M820.4,536.5L820.4,536.5c0.1,0.3,0.1,0.6,0.1,1h0C820.5,537.2,820.5,536.8,820.4,536.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000002355397687619790810000011721470429082140570_" class="st1" d="M811.5,574.5l0,2c-8,0-11.8,0-12,0
							c-1.5,0-5.6,0.2-8.5,3.2l-1.8-1c3.8-3.9,8.6-4.2,10.3-4.2C799.7,574.5,803.5,574.5,811.5,574.5z"/>
					</defs>
					<clipPath id="SVGID_00000157285148542382449270000002813603602113553040_">
						<use xlink:href="#SVGID_00000002355397687619790810000011721470429082140570_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000157285148542382449270000002813603602113553040_);enable-background:new    ;">
						<path class="st7" d="M811.5,574.5l0,2c-8,0-11.8,0-12,0c-0.6,0-1.5,0-2.6,0.2l-0.4-1.9c1.3-0.2,2.4-0.3,3-0.3
							C799.7,574.5,803.5,574.5,811.5,574.5"/>
						<path class="st8" d="M796.5,574.8l0.4,1.9c-0.8,0.1-1.8,0.4-2.7,0.8l-0.9-1.7C794.4,575.3,795.5,575,796.5,574.8"/>
						<path class="st9" d="M793.3,575.8l0.9,1.7c-0.6,0.2-1.1,0.5-1.7,0.9l-1.3-1.4C791.9,576.5,792.6,576.1,793.3,575.8"/>
						<path class="st10" d="M791.1,577l1.3,1.4c-0.4,0.3-0.9,0.6-1.3,1l-1.7-1.1C790.1,577.8,790.6,577.4,791.1,577"/>
						<path class="st11" d="M789.5,578.3l1.7,1.1c-0.1,0.1-0.2,0.2-0.3,0.3l-1.8-1C789.3,578.5,789.4,578.4,789.5,578.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000049912320018285560730000016631893770277882275_" class="st1" d="M798.5,532.5l0-2c5,0,12.6,0,13,0l0,2
							C811.1,532.5,803.5,532.5,798.5,532.5z"/>
					</defs>
					<clipPath id="SVGID_00000140703632212092524660000017196823761826753167_">
						<use xlink:href="#SVGID_00000049912320018285560730000016631893770277882275_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000140703632212092524660000017196823761826753167_);enable-background:new    ;">
						<path class="st14" d="M798.5,532.5l0-2c5,0,12.6,0,13,0l0,2C811.1,532.5,803.5,532.5,798.5,532.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000034802991754966123760000014362007625367439508_" class="st1" d="M818.5,567.5h2c0,0.5-1,9-9,9l0-2
							C817.6,574.6,818.4,567.6,818.5,567.5z"/>
					</defs>
					<clipPath id="SVGID_00000093898690140323186480000015286843466363962256_">
						<use xlink:href="#SVGID_00000034802991754966123760000014362007625367439508_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000093898690140323186480000015286843466363962256_);enable-background:new    ;">
						<path class="st51" d="M818.5,567.5h2c0,0.1,0,0.2-0.1,0.5l-2-0.2C818.5,567.6,818.5,567.5,818.5,567.5"/>
						<path class="st14" d="M818.4,567.8l2,0.2c-0.1,0.5-0.2,1.2-0.5,2.1l-1.9-0.6C818.2,568.7,818.4,568.1,818.4,567.8"/>
						<path class="st13" d="M818,569.4l1.9,0.6c-0.2,0.6-0.4,1.2-0.8,1.8l-1.7-1C817.7,570.3,817.9,569.8,818,569.4"/>
						<path class="st12" d="M817.4,570.8l1.7,1c-0.2,0.5-0.5,0.9-0.9,1.4l-1.5-1.3C817,571.6,817.2,571.2,817.4,570.8"/>
						<path class="st11" d="M816.7,571.9l1.5,1.3c-0.3,0.4-0.6,0.7-1,1.1l-1.3-1.5C816.3,572.5,816.5,572.2,816.7,571.9"/>
						<path class="st10" d="M816,572.8l1.3,1.5c-0.3,0.3-0.7,0.6-1.1,0.9l-1.1-1.7C815.5,573.2,815.7,573,816,572.8"/>
						<path class="st9" d="M815.1,573.5l1.1,1.7c-0.4,0.3-0.8,0.5-1.3,0.7l-0.8-1.8C814.5,573.8,814.8,573.7,815.1,573.5"/>
						<path class="st8" d="M814.2,574l0.8,1.8c-0.5,0.2-1.1,0.4-1.7,0.5l-0.4-2C813.3,574.3,813.8,574.2,814.2,574"/>
						<path class="st7" d="M812.8,574.4l0.4,2c-0.5,0.1-1.1,0.2-1.8,0.2l0-2C812,574.5,812.4,574.5,812.8,574.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000159437788125570891470000000790888430288674954_" class="st1" d="M818.5,537.5h2c0,7,0,28.8,0,30h-2
							C818.5,566.3,818.5,544.5,818.5,537.5z"/>
					</defs>
					<clipPath id="SVGID_00000126281225467496177740000000125653134283249568_">
						<use xlink:href="#SVGID_00000159437788125570891470000000790888430288674954_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000126281225467496177740000000125653134283249568_);enable-background:new    ;">
						<path class="st51" d="M818.5,537.5h2c0,7,0,28.8,0,30h-2C818.5,566.3,818.5,544.5,818.5,537.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000148624547739508323950000009821376658671680189_" class="st1" d="M811.5,532.5l0-2c0.4,0,9,0,9,7h-2
							C818.6,532.6,811.8,532.5,811.5,532.5z"/>
					</defs>
					<clipPath id="SVGID_00000093869389985030286570000007115577269544170393_">
						<use xlink:href="#SVGID_00000148624547739508323950000009821376658671680189_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000093869389985030286570000007115577269544170393_);enable-background:new    ;">
						<path class="st14" d="M811.5,532.5l0-2c0.1,0,0.3,0,0.7,0l-0.1,2C811.7,532.5,811.5,532.5,811.5,532.5"/>
						<path class="st51" d="M812,532.5l0.1-2c0.6,0,1.6,0.1,2.7,0.4l-0.7,1.9C813.3,532.6,812.5,532.6,812,532.5"/>
						<path class="st52" d="M814.2,532.9l0.7-1.9c0.8,0.2,1.6,0.5,2.3,0.9l-1.2,1.7C815.4,533.2,814.8,533,814.2,532.9"/>
						<path class="st53" d="M816,533.5l1.2-1.7c1.4,0.8,2.6,2,3.1,3.9l-1.9,0.5C818,535,817.1,534.1,816,533.5"/>
						<path class="st52" d="M818.3,536.3l1.9-0.5c0.1,0.5,0.2,1,0.2,1.5l-2,0.1C818.5,537,818.4,536.6,818.3,536.3"/>
						<path class="st51" d="M818.5,537.4l2-0.1c0,0.1,0,0.1,0,0.2h-2C818.5,537.5,818.5,537.4,818.5,537.4"/>
					</g>
				</g>
				<g>
					<path class="st147" d="M811.5,532.5c0.4,0,7.1,0.1,7,5c0,7,0,28.8,0,30c0,0.1-0.9,7.1-7,7c-8,0-11.8,0-12,0
						c-1.7,0-6.5,0.2-10.3,4.2c-2.2,7.2-4.9,13.9-8.3,20.1c-0.8,3.5-1.7,9.1,1.6,12.5c3.5,3.5,8.9,8.9,9.2,9.2
						c0.3,0.3,5,5.1,1.4,8.5c-4.9,4.9-20.4,20.4-21.2,21.2c0.1-0.1-2.5,1.9-5.5,1.9c-1.4,0-2.9-0.5-4.4-1.9
						c-5.6-5.7-8.3-8.3-8.5-8.5c-0.1-0.2-3.8-4.3-9.5-4.3c-0.7,0-1.2-0.1-2.1,0.2c-5.8,3-11.5,5.3-26.8,10.2
						c7.5-1.4,0.5,1.5,0.4,7.9c0,5,0,12.6,0,13c0,0.4-0.1,7.1-5,7c-7,0-28.9,0-30,0c-0.1,0-7.1-0.9-7-7c0-8,0-11.8,0-12
						c0-0.3,0.5-8-6.2-11.7c-5.8-1.9-11.4-4.1-26.5-12c7.4,5.4,6.5,4.3,2.9,4.3c-2.4,0-5,0.5-6.9,2.4c-3.5,3.5-8.9,8.9-9.2,9.2
						c-0.1,0.1-2.8,2.7-5.6,2.7c-0.9,0-1.9-0.3-2.9-1.3c-4.9-4.9-20.4-20.4-21.2-21.2c-0.1-0.1-4.4-5.6,0-9.9
						c5.7-5.7,8.3-8.3,8.5-8.5c0.2-0.2,5.5-4.8,4.1-11.7c-3.2-5.9-5.5-11.8-7.5-17.5c-1.3-2.4-5.1-8.7-10.6-8.8c-5,0-12.6,0-13,0
						c-0.4,0-7.1-0.1-7-5c0-7,0-28.8,0-30c0-0.1,0.9-7.1,7-7c8,0,11.8,0,12,0c1.8,0,6.6-0.2,10.4-4.3c2.2-7.4,4.9-14.5,8.4-20.9
						c0.8-3.7,1.1-8.5-1.8-11.5c-3.5-3.5-8.9-8.9-9.2-9.2c-0.3-0.3-5-5.1-1.4-8.5c4.9-4.9,20.4-20.4,21.2-21.2
						c-0.1,0.1,2.5-1.9,5.5-1.9c1.4,0,2.9,0.5,4.4,1.9c5.7,5.7,8.3,8.3,8.5,8.5c0.1,0.1,3.4,3.9,9.2,4.3c7.5-4.4,15.3-7.5,23.1-9.7
						c3-2.2,5.7-5,5.7-8.6c0-5,0-12.6,0-13c0-0.4,0.1-7.1,5-7c7,0,28.9,0,30,0c0.1,0,7.1,0.9,7,7c0,8,0,11.8,0,12
						c0,0.2-0.3,4.8,3.1,9.2c8.6,2.5,16.6,5.7,23.8,9.8c1.5,0.3,2.1,0.4,2.9,0.3c2.4,0,5-0.5,6.9-2.4c3.5-3.5,8.9-8.9,9.2-9.2
						c0.1-0.1,2.8-2.7,5.6-2.7c0.9,0,1.9,0.3,2.9,1.3c4.9,5,20.4,20.4,21.2,21.2c0.1,0.1,4.4,5.6,0,9.9c-5.7,5.7-8.3,8.3-8.5,8.5
						c-0.1,0.1-3.8,3.3-4.4,9.1c4.2,7.4,7.2,15.1,9.3,22.7c2.2,3.1,5.1,6.2,8.9,6.2C803.5,532.5,811.1,532.5,811.5,532.5z
						 M696.5,634.5c44.7,0,81-36.3,81-81c0-44.7-36.3-81-81-81c-44.7,0-81,36.3-81,81C615.5,598.2,651.8,634.5,696.5,634.5"/>
				</g>
			</g>
		</g>
	</g>
</g>
<g class="${classes.rotatingCog}">
	<path class="st0" d="M304.1,684.5l-0.4,0c-0.6-0.1-4-0.5-7.5-2.5c-5.5-3-8.6-8.3-8.6-14.5v-12.4l0,0c0-0.4-0.1-1.6-0.7-2.2
		c-4.9-1.6-9.8-3.5-14.4-5.9l-0.7-0.2c-0.5-0.2-2.4-0.7-4-0.7c-0.2,0-0.3,0-0.4,0l-8.6,8.6c-0.9,0.9-6,5.6-12.7,5.6
		c-3.8,0-7.2-1.5-10-4.2l-21.5-21.5l-0.3-0.3c-0.4-0.5-2.5-3.2-3.6-7c-1.7-6-0.2-12,4.2-16.3l8.8-8.8l0,0c0.3-0.3,0.8-1,1-1.7
		c-2.7-5.2-5-10.7-6.9-16.2c-0.8-1.3-1.6-2.2-2.1-2.7h-12.2c-8.4,0-17-5.2-17-15v-30.4l0-0.4c0.1-0.6,0.5-4,2.5-7.5
		c3-5.5,8.3-8.6,14.5-8.6h12.4l0,0c0.4,0,0.8-0.1,1.2-0.2c2-6.4,4.5-12.6,7.6-18.5c0.1-0.7,0.1-1.2,0-1.5l-8.6-8.6
		c-0.4-0.4-2.7-2.8-4.2-6.2c-2.6-5.9-1.5-12,2.8-16.4l21.5-21.5l0.3-0.3c0.9-0.7,5.7-4.3,11.9-4.3c4.3,0,8.3,1.7,11.5,4.9l8.8,8.8
		l0,0c0.1,0.1,0.3,0.2,0.5,0.4c6.3-3.4,13-6.1,19.9-8.2c0.1-0.1,0.1-0.2,0.2-0.2v-12.2c0-8.4,5.2-17,15-17h30.4l0.4,0
		c0.6,0.1,4,0.5,7.5,2.5c5.5,3,8.6,8.3,8.6,14.5v12.4l0,0c0,0.1,0,0.2,0,0.3c7,2.2,13.7,5,20.1,8.6c0,0,0,0,0,0l8.6-8.6
		c0.9-0.9,6-5.6,12.7-5.6c3.8,0,7.2,1.5,10,4.2l21.5,21.5l0.3,0.3c0.4,0.5,2.5,3.2,3.6,7c1.7,6,0.2,12-4.2,16.3l-8.8,8.8l0,0
		c-0.1,0.1-0.2,0.3-0.4,0.5c3.3,6.3,5.9,12.9,7.8,19.6c0.2,0.2,0.4,0.4,0.5,0.5h12.2c8.4,0,17,5.2,17,15v30.4l0,0.4
		c-0.1,0.6-0.5,4-2.5,7.5c-3,5.5-8.3,8.6-14.5,8.6h-12.4l0,0c-0.3,0-0.8,0.1-1.2,0.2c-2,6.1-4.5,12.1-7.6,17.8
		c-0.2,1.1-0.2,1.8-0.2,2.3l8.6,8.6c0.4,0.4,2.7,2.8,4.2,6.2c2.6,5.9,1.5,12-2.8,16.4l-21.5,21.5l-0.3,0.3
		c-0.9,0.7-5.7,4.3-11.9,4.3c-4.3,0-8.3-1.7-11.5-4.9l-8.8-8.8l0,0c-0.4-0.4-1.1-0.8-1.6-1c-5,2.6-10.3,4.8-15.7,6.5
		c-1.2,0.7-2.7,1.8-3.3,2.5v12.2c0,8.4-5.2,17-15,17H304.1z M320.5,481.5c-39.1,0-71,31.9-71,71s31.9,71,71,71s71-31.9,71-71
		S359.6,481.5,320.5,481.5z"/>
	<g>
		<g>
			<g class="st1">
				<g class="st1">
					<defs>
						<path id="SVGID_00000008135082932389939570000015014100384729848197_" class="st1" d="M243.2,453.9c-0.1-0.1-0.3-0.3-0.4-0.4
							L243.2,453.9C243.2,453.9,243.2,453.9,243.2,453.9z"/>
					</defs>
					<clipPath id="SVGID_00000110441380238758952350000002365493446171523975_">
						<use xlink:href="#SVGID_00000008135082932389939570000015014100384729848197_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000110441380238758952350000002365493446171523975_);enable-background:new    ;">
						<polyline class="st3" points="242.7,453.5 243.2,453.9 243.2,453.9 242.7,453.5 242.7,453.5 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000064355382231706795310000012894130213120207247_" class="st1" d="M196.5,566.5h-2c0-7,0-28.8,0-30h2
							C196.5,537.7,196.5,559.5,196.5,566.5z"/>
					</defs>
					<clipPath id="SVGID_00000007421649174599272340000016309112015139530649_">
						<use xlink:href="#SVGID_00000064355382231706795310000012894130213120207247_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000007421649174599272340000016309112015139530649_);enable-background:new    ;">
						<path class="st5" d="M196.5,566.5h-2c0-7,0-28.8,0-30h2C196.5,537.7,196.5,559.5,196.5,566.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000012434694159055714150000008287232977422393517_" class="st1" d="M196.5,536.5h-2c0-0.5,1-9,9-9l0,2
							C197.4,529.4,196.5,536.4,196.5,536.5z"/>
					</defs>
					<clipPath id="SVGID_00000106108886294942534290000017007005639858995849_">
						<use xlink:href="#SVGID_00000012434694159055714150000008287232977422393517_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000106108886294942534290000017007005639858995849_);enable-background:new    ;">
						<path class="st7" d="M196.5,536.5h-2c0-0.2,0.2-1.7,0.9-3.5l1.8,0.8C196.7,535.3,196.5,536.4,196.5,536.5"/>
						<path class="st8" d="M197.3,533.9l-1.8-0.8c0.3-0.7,0.7-1.4,1.2-2.1l1.6,1.3C197.8,532.8,197.5,533.3,197.3,533.9"/>
						<path class="st9" d="M198.2,532.2l-1.6-1.3c0.3-0.5,0.7-0.9,1.1-1.3l1.3,1.5C198.7,531.5,198.4,531.8,198.2,532.2"/>
						<path class="st10" d="M199,531.2l-1.3-1.5c0.3-0.3,0.7-0.6,1.1-0.9l1.1,1.7C199.6,530.7,199.3,530.9,199,531.2"/>
						<path class="st11" d="M199.9,530.5l-1.1-1.7c0.3-0.2,0.7-0.4,1.1-0.6l0.8,1.8C200.4,530.2,200.2,530.3,199.9,530.5"/>
						<path class="st12" d="M200.7,530l-0.8-1.8c0.4-0.2,0.8-0.3,1.2-0.4l0.6,1.9C201.3,529.8,201,529.9,200.7,530"/>
						<path class="st13" d="M201.6,529.7l-0.6-1.9c0.4-0.1,0.9-0.2,1.4-0.2l0.2,2C202.3,529.6,202,529.6,201.6,529.7"/>
						<path class="st14" d="M202.7,529.5l-0.2-2c0.3,0,0.7-0.1,1-0.1l0,2C203.2,529.5,203,529.5,202.7,529.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000069369743564135344580000012919568715347837084_" class="st1" d="M215.6,527.5L215.6,527.5
							C215.6,527.5,215.5,527.5,215.6,527.5L215.6,527.5C215.5,527.5,215.6,527.5,215.6,527.5z"/>
					</defs>
					<clipPath id="SVGID_00000130636451192316203690000002856831564050718095_">
						<use xlink:href="#SVGID_00000069369743564135344580000012919568715347837084_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000130636451192316203690000002856831564050718095_);enable-background:new    ;">
						<path class="st16" d="M215.5,527.5L215.5,527.5C215.5,527.5,215.6,527.5,215.5,527.5L215.5,527.5
							C215.6,527.5,215.5,527.5,215.5,527.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000011021345744256525230000018307887807849289139_" class="st1" d="M216.5,571.5l0,2c-5,0-12.6,0-13,0
							c-0.4,0-9,0-9-7h2c-0.1,4.9,6.6,5,7,5C203.9,571.5,211.5,571.5,216.5,571.5z"/>
					</defs>
					<clipPath id="SVGID_00000181047462931630796120000014401828652894731189_">
						<use xlink:href="#SVGID_00000011021345744256525230000018307887807849289139_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000181047462931630796120000014401828652894731189_);enable-background:new    ;">
						<path class="st7" d="M216.5,571.5l0,2c-5,0-12.6,0-13,0c-0.1,0-0.7,0-1.5-0.1l0.3-2c0.6,0.1,1.1,0.1,1.1,0.1
							C203.9,571.5,211.5,571.5,216.5,571.5"/>
						<path class="st5" d="M202.4,571.4l-0.3,2c-1.5-0.2-3.9-0.7-5.6-2.2l1.5-1.4C199.3,570.9,201.2,571.3,202.4,571.4"/>
						<path class="st18" d="M198,569.8l-1.5,1.4c-0.5-0.5-1-1.1-1.4-1.8l1.8-0.8C197.2,569,197.5,569.4,198,569.8"/>
						<path class="st5" d="M196.9,568.5l-1.8,0.8c-0.4-0.8-0.6-1.7-0.6-2.8h2C196.5,567.3,196.6,567.9,196.9,568.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000054970868596285854590000006942678953928111275_" class="st1" d="M270.4,636c0,0.1,0.1,0.2,0.1,0.2
							c0.2,0.2,0.4,0.3,0.6,0.4c-1.9-1.4-4.4-3.4-6.2-4.8C268.5,634.5,270.1,635.6,270.4,636z"/>
					</defs>
					<clipPath id="SVGID_00000147923052411073088340000011764219747597151146_">
						<use xlink:href="#SVGID_00000054970868596285854590000006942678953928111275_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000147923052411073088340000011764219747597151146_);enable-background:new    ;">
						<path class="st9" d="M271.1,636.6l-6.2-4.8c0,0,0.1,0,0.1,0.1L271.1,636.6C271.1,636.6,271.1,636.6,271.1,636.6"/>
						<path class="st8" d="M271.1,636.6l-6.2-4.8c0.1,0.1,0.2,0.1,0.2,0.2L271.1,636.6C271.2,636.7,271.1,636.7,271.1,636.6"/>
						<path class="st7" d="M271.2,636.7l-6-4.7c0.1,0.1,0.2,0.1,0.2,0.2L271.2,636.7C271.3,636.7,271.2,636.7,271.2,636.7"/>
						<path class="st5" d="M271.3,636.8l-5.9-4.6c0.1,0.1,0.2,0.1,0.2,0.2L271.3,636.8C271.3,636.8,271.3,636.8,271.3,636.8"/>
						<path class="st18" d="M271.3,636.8l-5.7-4.4c0.1,0.1,0.1,0.1,0.2,0.2L271.3,636.8C271.4,636.9,271.4,636.8,271.3,636.8"/>
						<path class="st20" d="M271.4,636.9l-5.6-4.3c0,0,0.1,0.1,0.1,0.1L271.4,636.9C271.4,636.9,271.4,636.9,271.4,636.9"/>
						<path class="st21" d="M271.5,636.9l-5.5-4.3c0,0,0.1,0.1,0.1,0.1L271.5,636.9C271.5,636.9,271.5,636.9,271.5,636.9"/>
						<path class="st22" d="M271.5,636.9l-5.4-4.2c0,0,0.1,0,0.1,0.1L271.5,636.9C271.5,637,271.5,636.9,271.5,636.9"/>
						<path class="st23" d="M271.5,637l-5.3-4.2c0,0,0.1,0,0.1,0.1L271.5,637C271.5,637,271.5,637,271.5,637"/>
						<path class="st24" d="M271.5,637l-5.3-4.1c0,0,0.1,0,0.1,0.1L271.5,637C271.6,637,271.6,637,271.5,637"/>
						<path class="st25" d="M271.6,637l-5.2-4.1c0,0,0.1,0,0.1,0.1L271.6,637C271.6,637,271.6,637,271.6,637"/>
						<path class="st26" d="M271.6,637l-5.1-4c0,0,0.1,0,0.1,0.1L271.6,637C271.6,637.1,271.6,637,271.6,637"/>
						<path class="st27" d="M271.6,637.1l-5.1-4c0,0,0.1,0,0.1,0.1L271.6,637.1C271.7,637.1,271.6,637.1,271.6,637.1"/>
						<path class="st28" d="M271.7,637.1l-5-3.9c0,0,0.1,0,0.1,0.1L271.7,637.1C271.7,637.1,271.7,637.1,271.7,637.1"/>
						<path class="st29" d="M271.7,637.1l-4.9-3.9c0,0,0.1,0,0.1,0.1L271.7,637.1C271.7,637.1,271.7,637.1,271.7,637.1"/>
						<path class="st30" d="M271.7,637.1l-4.9-3.9c0,0,0.1,0,0.1,0.1L271.7,637.1C271.7,637.1,271.7,637.1,271.7,637.1"/>
						<path class="st31" d="M271.7,637.2l-4.8-3.8c0,0,0.1,0,0.1,0.1L271.7,637.2C271.8,637.2,271.8,637.2,271.7,637.2"/>
						<path class="st32" d="M271.8,637.2l-4.7-3.8c0,0,0.1,0,0.1,0.1L271.8,637.2C271.8,637.2,271.8,637.2,271.8,637.2"/>
						<path class="st33" d="M271.8,637.2l-4.6-3.7c0,0,0.1,0,0.1,0.1L271.8,637.2C271.8,637.2,271.8,637.2,271.8,637.2"/>
						<path class="st34" d="M271.8,637.2l-4.6-3.7c0,0,0.1,0,0.1,0.1L271.8,637.2C271.8,637.2,271.8,637.2,271.8,637.2"/>
						<path class="st16" d="M271.8,637.2l-4.5-3.6c0,0,0.1,0,0.1,0.1L271.8,637.2C271.9,637.3,271.8,637.3,271.8,637.2"/>
						<path class="st35" d="M271.9,637.3l-4.4-3.6c0,0,0.1,0,0.1,0.1L271.9,637.3C271.9,637.3,271.9,637.3,271.9,637.3"/>
						<path class="st3" d="M271.9,637.3l-4.3-3.5c1.8,1.3,2.7,1.9,2.9,2.2l1.3,1.8C272.2,637.8,272.3,637.7,271.9,637.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000148657839179923324750000014456908850592857508_" class="st1" d="M221.9,627.9l-1.4,1.4
							c-0.3-0.4-5.7-7.1,0-12.7l1.4,1.4C217.5,622.3,221.9,627.8,221.9,627.9z"/>
					</defs>
					<clipPath id="SVGID_00000168092498151869720010000003463286163756970892_">
						<use xlink:href="#SVGID_00000148657839179923324750000014456908850592857508_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000168092498151869720010000003463286163756970892_);enable-background:new    ;">
						<path class="st18" d="M221.9,627.9l-1.4,1.4c-0.1-0.1-1-1.3-1.7-3l1.9-0.7C221.2,626.9,221.9,627.8,221.9,627.9"/>
						<path class="st5" d="M220.6,625.6l-1.9,0.7c-0.5-1.3-1-2.9-0.8-4.7l2,0.3C219.9,623.3,220.2,624.5,220.6,625.6"/>
						<path class="st7" d="M220,621.9l-2-0.3c0.1-0.7,0.2-1.4,0.6-2.2l1.9,0.7C220.2,620.8,220,621.3,220,621.9"/>
						<path class="st8" d="M220.4,620.2l-1.9-0.7c0.2-0.6,0.5-1.1,0.9-1.6l1.6,1.1C220.8,619.3,220.6,619.8,220.4,620.2"/>
						<path class="st9" d="M221.1,618.9l-1.6-1.1c0.3-0.4,0.7-0.8,1.1-1.2l1.4,1.4C221.6,618.3,221.3,618.6,221.1,618.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000024687376160199558500000001068488930422193340_" class="st1" d="M203.5,529.5l0-2c8,0,11.8,0,12,0
							c1.5,0,5.7-0.2,8.6-3.3l1.8,1c-3.8,4-8.6,4.3-10.4,4.3C215.3,529.5,211.5,529.5,203.5,529.5z"/>
					</defs>
					<clipPath id="SVGID_00000089535527077078489270000000054864255357633920_">
						<use xlink:href="#SVGID_00000024687376160199558500000001068488930422193340_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000089535527077078489270000000054864255357633920_);enable-background:new    ;">
						<path class="st14" d="M203.5,529.5l0-2c8,0,11.8,0,12,0c0.4,0,0.9,0,1.5-0.1l0.2,2c-0.7,0.1-1.3,0.1-1.7,0.1
							C215.3,529.5,211.5,529.5,203.5,529.5"/>
						<path class="st13" d="M217.2,529.4l-0.2-2c0.6-0.1,1.3-0.2,2.1-0.4l0.6,1.9C218.7,529.2,217.9,529.3,217.2,529.4"/>
						<path class="st12" d="M219.6,529l-0.6-1.9c0.6-0.1,1.1-0.3,1.7-0.6l0.9,1.7C221,528.5,220.3,528.8,219.6,529"/>
						<path class="st11" d="M221.7,528.2l-0.9-1.7c0.5-0.2,1-0.5,1.4-0.8l1.3,1.5C222.9,527.6,222.3,527.9,221.7,528.2"/>
						<path class="st10" d="M223.5,527.2l-1.3-1.5c0.5-0.3,0.9-0.6,1.3-1l1.6,1.2C224.7,526.4,224.1,526.8,223.5,527.2"/>
						<path class="st9" d="M225.2,525.9l-1.6-1.2c0.2-0.2,0.3-0.3,0.5-0.5l1.8,1C225.7,525.4,225.5,525.7,225.2,525.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000096741748065905464980000001520334632772540292_" class="st1" d="M227.1,580.3l-1.9,0.6
							c-1.1-1.9-4.5-7.4-8.7-7.4l0-2C221.9,571.6,225.8,577.9,227.1,580.3z"/>
					</defs>
					<clipPath id="SVGID_00000045620749944454579780000008782809337199960462_">
						<use xlink:href="#SVGID_00000096741748065905464980000001520334632772540292_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000045620749944454579780000008782809337199960462_);enable-background:new    ;">
						<path class="st18" d="M227.1,580.3l-1.9,0.6c-0.8-1.3-2.7-4.5-5.3-6.2l0.9-1.8C224,574.9,226.1,578.6,227.1,580.3"/>
						<path class="st5" d="M220.8,572.9l-0.9,1.8c-0.9-0.6-1.9-1-2.9-1.1l0.1-2C218.5,571.7,219.7,572.2,220.8,572.9"/>
						<path class="st7" d="M217.2,571.5l-0.1,2c-0.2,0-0.3,0-0.5,0l0-2C216.7,571.5,216.9,571.5,217.2,571.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000091726966401212797700000011320462654128327576_" class="st1" d="M221.9,618l-1.4-1.4
							c5.7-5.7,8.3-8.3,8.5-8.5l1.4,1.4C230.3,609.6,227.6,612.3,221.9,618z"/>
					</defs>
					<clipPath id="SVGID_00000156570742174117854530000016897112655381281976_">
						<use xlink:href="#SVGID_00000091726966401212797700000011320462654128327576_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000156570742174117854530000016897112655381281976_);enable-background:new    ;">
						<path class="st9" d="M221.9,618l-1.4-1.4c5.7-5.7,8.3-8.3,8.5-8.5l1.4,1.4C230.3,609.6,227.6,612.3,221.9,618"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000163786862852429692820000000057038729012629172_" class="st1" d="M232.5,492.8l-1.4,1.4
							c-3.5-3.5-8.9-8.9-9.2-9.2c-0.3-0.3-6.4-6.4-1.4-11.3l1.4,1.4c-3.5,3.4,1.2,8.2,1.4,8.5C223.6,483.9,229,489.3,232.5,492.8z"
							/>
					</defs>
					<clipPath id="SVGID_00000101065895726463148110000009387028512186545300_">
						<use xlink:href="#SVGID_00000163786862852429692820000000057038729012629172_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000101065895726463148110000009387028512186545300_);enable-background:new    ;">
						<path class="st18" d="M232.5,492.8l-1.4,1.4c-3.5-3.5-8.9-8.9-9.2-9.2c-0.1-0.1-1.5-1.5-2.5-3.5l1.9-0.7
							c0.8,1.5,1.9,2.6,2,2.8C223.6,483.9,229,489.3,232.5,492.8"/>
						<path class="st5" d="M221.4,480.9l-1.9,0.7c-0.6-1.2-1-2.6-0.8-4l2,0.3C220.6,478.9,220.9,479.9,221.4,480.9"/>
						<path class="st7" d="M220.6,477.8l-2-0.3c0.1-0.5,0.2-1.1,0.4-1.6l1.9,0.8C220.7,477,220.6,477.4,220.6,477.8"/>
						<path class="st8" d="M220.9,476.6l-1.9-0.8c0.2-0.4,0.4-0.8,0.7-1.2l1.6,1.1C221.2,476.1,221,476.3,220.9,476.6"/>
						<path class="st9" d="M221.4,475.8l-1.6-1.1c0.2-0.3,0.5-0.6,0.8-0.9l1.4,1.4C221.7,475.3,221.5,475.6,221.4,475.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000027597954556123803230000000174871480363414959_" class="st1" d="M225.9,525.2l-1.8-1
							c2-7.3,4.9-14.3,8.4-20.9l1.8,1C230.8,510.7,228.1,517.8,225.9,525.2z"/>
					</defs>
					<clipPath id="SVGID_00000029022797838247999330000012010117074325495951_">
						<use xlink:href="#SVGID_00000027597954556123803230000000174871480363414959_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000029022797838247999330000012010117074325495951_);enable-background:new    ;">
						<path class="st7" d="M225.9,525.2l-1.8-1c0.9-3.4,2-6.7,3.3-9.9l1.9,0.9C228,518.4,226.9,521.8,225.9,525.2"/>
						<path class="st8" d="M229.3,515.2l-1.9-0.9c1.5-3.8,3.2-7.4,5.1-11l1.8,1C232.4,507.8,230.8,511.4,229.3,515.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000090296339750720757010000008122360261357552830_" class="st1" d="M234.5,597.8l-2,0.4
							c-3-5.5-5.5-11.3-7.4-17.3l1.9-0.6C229,586,231.4,591.9,234.5,597.8z"/>
					</defs>
					<clipPath id="SVGID_00000003785158440630415500000011016572143059860146_">
						<use xlink:href="#SVGID_00000090296339750720757010000008122360261357552830_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000003785158440630415500000011016572143059860146_);enable-background:new    ;">
						<path class="st18" d="M234.5,597.8l-2,0.4c-0.9-1.7-1.8-3.4-2.6-5.2l1.9-0.7C232.7,594.1,233.6,596,234.5,597.8"/>
						<path class="st5" d="M231.8,592.3l-1.9,0.7c-1.8-3.9-3.4-8-4.8-12.2l1.9-0.6C228.4,584.2,230,588.3,231.8,592.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000005235533399338198560000000648238017968069566_" class="st1" d="M234.3,504.3l-1.8-1
							c0.5-2.8,0.8-6.9-1.4-9.1l1.4-1.4C235.5,495.8,235.1,500.6,234.3,504.3z"/>
					</defs>
					<clipPath id="SVGID_00000085965963286499187770000007479708618857188522_">
						<use xlink:href="#SVGID_00000005235533399338198560000000648238017968069566_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000085965963286499187770000007479708618857188522_);enable-background:new    ;">
						<path class="st7" d="M234.3,504.3l-1.8-1c0.1-0.3,0.1-0.7,0.2-1l1.9,0.7C234.5,503.5,234.4,503.9,234.3,504.3"/>
						<path class="st5" d="M234.6,503l-1.9-0.7c0.3-2,0.3-4.3-0.4-6.2l1.8-0.8C235.1,497.7,235,500.5,234.6,503"/>
						<path class="st18" d="M234.1,495.3l-1.8,0.8c-0.3-0.7-0.7-1.4-1.2-1.9l1.4-1.4C233.2,493.5,233.8,494.4,234.1,495.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000041253470869372635890000000239924074871668881_" class="st1" d="M230.4,609.5l-1.4-1.4
							c0.2-0.2,4.7-4.2,3.6-9.9l2-0.4C235.9,604.7,230.6,609.3,230.4,609.5z"/>
					</defs>
					<clipPath id="SVGID_00000133519492607671345010000008234822391867883185_">
						<use xlink:href="#SVGID_00000041253470869372635890000000239924074871668881_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000133519492607671345010000008234822391867883185_);enable-background:new    ;">
						<path class="st10" d="M230.4,609.5l-1.4-1.4c0,0,0.1-0.1,0.1-0.1l1.4,1.4C230.5,609.4,230.4,609.5,230.4,609.5"/>
						<path class="st9" d="M230.6,609.3l-1.4-1.4c0.3-0.3,0.9-0.8,1.5-1.7l1.6,1.1C231.5,608.4,230.9,609.1,230.6,609.3"/>
						<path class="st8" d="M232.3,607.4l-1.6-1.1c0.5-0.6,0.9-1.4,1.3-2.3l1.9,0.7C233.4,605.7,232.8,606.6,232.3,607.4"/>
						<path class="st7" d="M233.8,604.6l-1.9-0.7c0.4-0.8,0.6-1.8,0.7-2.8l2,0.2C234.6,602.5,234.2,603.6,233.8,604.6"/>
						<path class="st5" d="M234.7,601.2l-2-0.2c0.1-0.9,0.1-1.8-0.1-2.8l2-0.4C234.8,599,234.8,600.2,234.7,601.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000129204442630156253590000006909994887904334478_" class="st1" d="M243.1,649.1l-1.4,1.4
							c-4.9-5-20.4-20.4-21.2-21.2l1.4-1.4C222.7,628.7,238.2,644.1,243.1,649.1z"/>
					</defs>
					<clipPath id="SVGID_00000021078550023267483350000007186800257073061016_">
						<use xlink:href="#SVGID_00000129204442630156253590000006909994887904334478_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000021078550023267483350000007186800257073061016_);enable-background:new    ;">
						<path class="st18" d="M243.1,649.1l-1.4,1.4c-4.9-5-20.4-20.4-21.2-21.2l1.4-1.4C222.7,628.7,238.2,644.1,243.1,649.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000031910786274150877420000017973764779550566284_" class="st1" d="M242.7,453.5l0.4,0.4c0,0,0,0,0,0
							C243,453.8,242.9,453.6,242.7,453.5z"/>
					</defs>
					<clipPath id="SVGID_00000013900047578128126250000000653669340131055750_">
						<use xlink:href="#SVGID_00000031910786274150877420000017973764779550566284_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000013900047578128126250000000653669340131055750_);enable-background:new    ;">
						<polyline class="st10" points="243.1,453.9 242.7,453.5 242.7,453.5 243.1,453.9 243.1,453.9 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000145770439970148947220000004297369759139479439_" class="st1" d="M221.9,475.1l-1.4-1.4
							c4.9-4.9,20.4-20.4,21.2-21.2l1.4,1.4C242.3,454.7,226.9,470.2,221.9,475.1z"/>
					</defs>
					<clipPath id="SVGID_00000067217025969619434680000001766744198652629436_">
						<use xlink:href="#SVGID_00000145770439970148947220000004297369759139479439_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000067217025969619434680000001766744198652629436_);enable-background:new    ;">
						<path class="st9" d="M221.9,475.1l-1.4-1.4c4.9-4.9,20.4-20.4,21.2-21.2l1.4,1.4C242.3,454.7,226.9,470.2,221.9,475.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000109729695587575217150000014623596773114143107_" class="st1" d="M253,649.1L253,649.1
							c-0.2,0.2-3.3,3.3-7,3.3h0C249.7,652.4,252.9,649.2,253,649.1z"/>
					</defs>
					<clipPath id="SVGID_00000071562074660915393520000003560747143590266525_">
						<use xlink:href="#SVGID_00000109729695587575217150000014623596773114143107_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000071562074660915393520000003560747143590266525_);enable-background:new    ;">
						<path class="st3" d="M253,649.1L253,649.1c-0.2,0.2-3.3,3.3-7,3.3h0C249.7,652.4,252.9,649.2,253,649.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000111154056394487205400000017834582424205747335_" class="st1" d="M248.6,450L248.6,450
							c1.9,0,3.9,0.6,5.8,2.5h0C252.6,450.6,250.5,450,248.6,450z"/>
					</defs>
					<clipPath id="SVGID_00000075855397294843419360000003768581041557441158_">
						<use xlink:href="#SVGID_00000111154056394487205400000017834582424205747335_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000075855397294843419360000003768581041557441158_);enable-background:new    ;">
						<path class="st16" d="M248.6,450L248.6,450c0.1,0,0.2,0,0.3,0h0C248.8,450,248.7,450,248.6,450"/>
						<path class="st34" d="M248.9,450L248.9,450c0.4,0,0.8,0.1,1.2,0.1h0C249.7,450,249.3,450,248.9,450"/>
						<path class="st33" d="M250.1,450.1L250.1,450.1c0.4,0.1,0.8,0.2,1.1,0.3h0C250.8,450.3,250.4,450.2,250.1,450.1"/>
						<path class="st32" d="M251.2,450.4L251.2,450.4c0.4,0.1,0.9,0.3,1.3,0.6h0C252,450.7,251.6,450.6,251.2,450.4"/>
						<path class="st31" d="M252.5,451L252.5,451c0.7,0.4,1.3,0.9,2,1.5h0C253.8,451.9,253.1,451.4,252.5,451"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000183243561693378158430000012978114487944507322_" class="st1" d="M243.1,453.9c-0.1-0.1-0.3-0.3-0.4-0.4
							l-1-1c0.2-0.1,3.1-2.5,6.9-2.5c1.9,0,3.9,0.6,5.8,2.5l-1.4,1.4c-1.5-1.5-3-1.9-4.4-1.9C245.6,452,243.1,453.9,243.1,453.9z"/>
					</defs>
					<clipPath id="SVGID_00000131340174624388792490000010997922556005083546_">
						<use xlink:href="#SVGID_00000183243561693378158430000012978114487944507322_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000131340174624388792490000010997922556005083546_);enable-background:new    ;">
						<path class="st10" d="M243.1,453.9l-1.4-1.4c0,0,0.3-0.2,0.7-0.5l1.2,1.6C243.3,453.8,243.1,453.9,243.1,453.9"/>
						<path class="st11" d="M243.6,453.6l-1.2-1.6c0.5-0.3,1.1-0.7,1.9-1.1l0.8,1.8C244.5,453.1,244,453.4,243.6,453.6"/>
						<path class="st12" d="M245.2,452.8l-0.8-1.8c0.5-0.2,1.1-0.5,1.8-0.6l0.5,1.9C246.1,452.4,245.6,452.6,245.2,452.8"/>
						<path class="st13" d="M246.6,452.3l-0.5-1.9c0.5-0.1,1-0.2,1.6-0.3l0.2,2C247.5,452.1,247,452.2,246.6,452.3"/>
						<path class="st14" d="M247.9,452l-0.2-2c0.3,0,0.6,0,0.9,0c0.2,0,0.4,0,0.6,0l-0.1,2c-0.1,0-0.3,0-0.4,0
							C248.4,452,248.1,452,247.9,452"/>
						<path class="st51" d="M249.1,452l0.1-2c0.4,0,0.9,0.1,1.4,0.2l-0.5,1.9C249.7,452.1,249.4,452,249.1,452"/>
						<path class="st52" d="M250.1,452.2l0.5-1.9c0.5,0.1,1,0.3,1.6,0.6l-0.9,1.8C250.9,452.4,250.5,452.3,250.1,452.2"/>
						<path class="st53" d="M251.3,452.6l0.9-1.8c0.8,0.4,1.6,0.9,2.3,1.7l-1.4,1.4C252.4,453.3,251.8,452.9,251.3,452.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000063608235517419617560000015222205252228114835_" class="st1" d="M262.2,639.9L262.2,639.9
							c-3.5,3.5-8.9,8.9-9.2,9.2l0,0C253.3,648.8,258.7,643.4,262.2,639.9z"/>
					</defs>
					<clipPath id="SVGID_00000155131084916498485230000016607215974880994210_">
						<use xlink:href="#SVGID_00000063608235517419617560000015222205252228114835_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000155131084916498485230000016607215974880994210_);enable-background:new    ;">
						<path class="st3" d="M262.2,639.9L262.2,639.9c-3.5,3.5-8.9,8.9-9.2,9.2h0C253.3,648.8,258.7,643.4,262.2,639.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000020393667145296595490000010997744833809973396_" class="st1" d="M254.5,452.5L254.5,452.5
							c5.7,5.7,8.4,8.3,8.5,8.5l0,0C262.8,460.9,260.1,458.2,254.5,452.5z"/>
					</defs>
					<clipPath id="SVGID_00000174574822091406335290000000288215384076595074_">
						<use xlink:href="#SVGID_00000020393667145296595490000010997744833809973396_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000174574822091406335290000000288215384076595074_);enable-background:new    ;">
						<path class="st31" d="M254.5,452.5L254.5,452.5c5.7,5.7,8.4,8.3,8.5,8.5h0C262.8,460.9,260.1,458.2,254.5,452.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000009568197481924536120000011176135489078179255_" class="st1" d="M267.7,638.1L267.7,638.1
							c-2,0-4.1,0.4-5.5,1.8l0,0C263.6,638.5,265.7,638.1,267.7,638.1z"/>
					</defs>
					<clipPath id="SVGID_00000054948649427927589900000016133625590053589130_">
						<use xlink:href="#SVGID_00000009568197481924536120000011176135489078179255_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000054948649427927589900000016133625590053589130_);enable-background:new    ;">
						<path class="st3" d="M267.7,638.1L267.7,638.1c-2,0-4.1,0.4-5.5,1.8h0C263.6,638.5,265.7,638.1,267.7,638.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000111152969560385850880000014475841611461467579_" class="st1" d="M260.8,638.5l1.4,1.4
							c-3.5,3.5-8.9,8.9-9.2,9.2c-0.2,0.2-3.3,3.3-7,3.3c-1.4,0-2.9-0.5-4.3-1.9l1.4-1.4c1,1,2,1.3,2.9,1.3c2.8,0,5.5-2.6,5.6-2.7
							C251.9,647.4,257.3,642,260.8,638.5z"/>
					</defs>
					<clipPath id="SVGID_00000163763846764648779030000001810366559796689793_">
						<use xlink:href="#SVGID_00000111152969560385850880000014475841611461467579_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000163763846764648779030000001810366559796689793_);enable-background:new    ;">
						<path class="st11" d="M260.8,638.5l1.4,1.4c-3.5,3.5-8.9,8.9-9.2,9.2c0,0,0,0-0.1,0.1l-1.4-1.4c0,0,0,0,0.1-0.1
							C251.9,647.4,257.3,642,260.8,638.5"/>
						<path class="st10" d="M251.6,647.7l1.4,1.4c-0.2,0.2-0.9,0.8-1.8,1.5l-1.1-1.7C250.9,648.4,251.4,647.9,251.6,647.7"/>
						<path class="st9" d="M250.1,648.9l1.1,1.7c-0.6,0.4-1.3,0.8-2,1.1l-0.7-1.9C249.1,649.6,249.6,649.2,250.1,648.9"/>
						<path class="st8" d="M248.5,649.8l0.7,1.9c-0.6,0.2-1.2,0.4-1.9,0.6l-0.3-2C247.5,650.2,248,650,248.5,649.8"/>
						<path class="st7" d="M247,650.3l0.3,2c-0.4,0.1-0.8,0.1-1.2,0.1c-0.2,0-0.4,0-0.7,0l0.2-2c0.1,0,0.3,0,0.4,0
							C246.4,650.4,246.7,650.4,247,650.3"/>
						<path class="st5" d="M245.6,650.4l-0.2,2c-0.9-0.1-1.8-0.4-2.7-1.1l1.1-1.6C244.4,650.1,245,650.3,245.6,650.4"/>
						<path class="st18" d="M243.8,649.6l-1.1,1.6c-0.3-0.2-0.6-0.5-0.9-0.8l1.4-1.4C243.4,649.3,243.6,649.5,243.8,649.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000180349186024371916750000001450626042424054965_" class="st1" d="M262.9,461L262.9,461
							c0.2,0.2,3,3.4,7.3,3.7h0C265.9,464.3,263.1,461.2,262.9,461z"/>
					</defs>
					<clipPath id="SVGID_00000059279169892621819400000004101637093410252205_">
						<use xlink:href="#SVGID_00000180349186024371916750000001450626042424054965_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000059279169892621819400000004101637093410252205_);enable-background:new    ;">
						<path class="st31" d="M262.9,461L262.9,461c0.1,0.1,1.2,1.3,3,2.4h0C264.1,462.3,263,461.1,262.9,461"/>
						<path class="st32" d="M265.9,463.4L265.9,463.4c0.6,0.3,1.2,0.6,1.9,0.8h0C267.1,464,266.5,463.7,265.9,463.4"/>
						<path class="st33" d="M267.8,464.2L267.8,464.2c0.5,0.2,1,0.3,1.5,0.4h0C268.8,464.5,268.3,464.4,267.8,464.2"/>
						<path class="st34" d="M269.3,464.6L269.3,464.6c0.3,0.1,0.6,0.1,0.9,0.1h0C269.9,464.7,269.6,464.6,269.3,464.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000016787629374739804580000015269882331015104673_" class="st1" d="M253,453.9l1.4-1.4
							c5.7,5.7,8.3,8.3,8.5,8.5l-1.4,1.4C261.4,462.3,258.7,459.6,253,453.9z"/>
					</defs>
					<clipPath id="SVGID_00000123439677991008344950000000311187028919174580_">
						<use xlink:href="#SVGID_00000016787629374739804580000015269882331015104673_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000123439677991008344950000000311187028919174580_);enable-background:new    ;">
						<path class="st53" d="M253,453.9l1.4-1.4c5.7,5.7,8.3,8.3,8.5,8.5l-1.4,1.4C261.4,462.3,258.7,459.6,253,453.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000143577048829729386600000009471277696532800185_" class="st1" d="M273.8,638.9L273.8,638.9
							C273.9,638.9,273.9,638.8,273.8,638.9L273.8,638.9C273.9,638.8,273.9,638.9,273.8,638.9z"/>
					</defs>
					<clipPath id="SVGID_00000166662506742218273580000003777281343239333046_">
						<use xlink:href="#SVGID_00000143577048829729386600000009471277696532800185_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000166662506742218273580000003777281343239333046_);enable-background:new    ;">
						<path class="st31" d="M273.9,638.8L273.9,638.8C273.9,638.8,273.9,638.8,273.9,638.8L273.9,638.8
							C273.9,638.8,273.9,638.8,273.9,638.8"/>
						<path class="st32" d="M273.9,638.8L273.9,638.8C273.9,638.8,273.9,638.8,273.9,638.8L273.9,638.8
							C273.9,638.8,273.9,638.8,273.9,638.8"/>
						<path class="st33" d="M273.9,638.8L273.9,638.8C273.9,638.8,273.9,638.8,273.9,638.8L273.9,638.8
							C273.9,638.8,273.9,638.8,273.9,638.8"/>
						<polyline class="st34" points="273.9,638.8 273.9,638.8 273.9,638.8 273.9,638.8 273.9,638.8 						"/>
						<polyline class="st16" points="273.9,638.8 273.9,638.8 273.9,638.8 273.9,638.8 273.9,638.8 						"/>
						<polyline class="st35" points="273.9,638.8 273.9,638.8 273.9,638.9 273.9,638.9 273.9,638.8 						"/>
						<path class="st3" d="M273.9,638.9L273.9,638.9C273.9,638.9,273.9,638.9,273.9,638.9L273.9,638.9
							C273.8,638.9,273.9,638.9,273.9,638.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000121997917332623455420000012647259555102055331_" class="st1" d="M291.3,643.8l-1,1.8
							c-5.7-1.8-11.2-4.1-16.5-6.8l-9.1-7C279.9,639.7,285.5,641.9,291.3,643.8z"/>
					</defs>
					<clipPath id="SVGID_00000162317524970343945650000013624676695041265072_">
						<use xlink:href="#SVGID_00000121997917332623455420000012647259555102055331_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000162317524970343945650000013624676695041265072_);enable-background:new    ;">
						<path class="st5" d="M291.3,643.8l-1,1.8c-5.7-1.8-11.2-4.1-16.5-6.8l-9.1-7C279.9,639.7,285.5,641.9,291.3,643.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000103984534330363918070000017769156490099764147_" class="st1" d="M270.4,636c0,0,0,0.1,0.1,0.1
							c0,0,0,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c1.1,0.8,2.1,1.6,3.2,2.4c0.4,0.4-2.8-0.7-6.1-0.7c-2.1,0-4.1,0.4-5.5,1.8l-1.4-1.4
							c1.9-1.9,4.5-2.4,6.9-2.4C269.6,636.1,270.7,636.4,270.4,636z"/>
					</defs>
					<clipPath id="SVGID_00000139981025130751003630000012923780736940138633_">
						<use xlink:href="#SVGID_00000103984534330363918070000017769156490099764147_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000139981025130751003630000012923780736940138633_);enable-background:new    ;">
						<path class="st3" d="M264.8,631.8l9.1,7c0,0,0,0,0,0.1l-7.9-6.1C265.7,632.4,265.3,632.1,264.8,631.8"/>
						<path class="st35" d="M266,632.7l7.9,6.1c0,0,0,0,0,0l-7.7-6C266.2,632.8,266.1,632.7,266,632.7"/>
						<path class="st16" d="M266.2,632.8l7.7,6c0,0,0,0,0,0l-7.5-5.9C266.4,632.9,266.3,632.9,266.2,632.8"/>
						<path class="st34" d="M266.4,633l7.5,5.9c0,0,0,0,0,0l-7.3-5.7C266.6,633.1,266.5,633,266.4,633"/>
						<path class="st33" d="M266.6,633.1l7.3,5.7c0,0,0,0,0,0l-7.1-5.6C266.7,633.2,266.7,633.2,266.6,633.1"/>
						<path class="st32" d="M266.8,633.2l7.1,5.6c0,0,0,0,0,0L266.8,633.2C266.8,633.3,266.8,633.3,266.8,633.2"/>
						<path class="st31" d="M266.9,633.3l7,5.6c0,0,0,0,0,0L266.9,633.3C266.9,633.3,266.9,633.3,266.9,633.3"/>
						<path class="st30" d="M266.9,633.3l6.9,5.5c0,0,0,0,0,0L266.9,633.3C267,633.4,267,633.4,266.9,633.3"/>
						<path class="st29" d="M267,633.4l6.9,5.5c0,0,0,0,0,0L267,633.4C267,633.4,267,633.4,267,633.4"/>
						<path class="st28" d="M267.1,633.4l6.8,5.4c0,0,0,0,0,0L267.1,633.4C267.1,633.5,267.1,633.4,267.1,633.4"/>
						<path class="st27" d="M267.1,633.5l6.7,5.4c0,0,0,0,0,0L267.1,633.5C267.2,633.5,267.1,633.5,267.1,633.5"/>
						<path class="st26" d="M267.2,633.5l6.7,5.3c0,0,0,0,0,0L267.2,633.5C267.2,633.6,267.2,633.5,267.2,633.5"/>
						<path class="st25" d="M267.2,633.6l6.6,5.3c0,0,0,0,0,0L267.2,633.6C267.3,633.6,267.3,633.6,267.2,633.6"/>
						<path class="st24" d="M267.3,633.6l6.5,5.3c0,0,0,0,0,0L267.3,633.6C267.4,633.6,267.3,633.6,267.3,633.6"/>
						<path class="st23" d="M267.4,633.7l6.5,5.2c0,0,0,0,0,0L267.4,633.7C267.4,633.7,267.4,633.7,267.4,633.7"/>
						<path class="st22" d="M267.4,633.7l6.4,5.2c0,0,0,0,0,0L267.4,633.7C267.5,633.7,267.5,633.7,267.4,633.7"/>
						<path class="st21" d="M267.5,633.7l6.3,5.1c0,0,0,0,0,0L267.5,633.7C267.5,633.8,267.5,633.8,267.5,633.7"/>
						<path class="st20" d="M267.5,633.8l6.3,5.1c0,0,0,0,0,0L267.5,633.8C267.6,633.8,267.6,633.8,267.5,633.8"/>
						<path class="st18" d="M267.6,633.8l6.2,5c0,0,0,0,0,0L267.6,633.8C267.6,633.9,267.6,633.8,267.6,633.8"/>
						<path class="st5" d="M267.7,633.9l6.1,5c0,0,0,0,0,0L267.7,633.9C267.7,633.9,267.7,633.9,267.7,633.9"/>
						<path class="st7" d="M267.7,633.9l6.1,5c0,0,0,0,0,0L267.7,633.9C267.8,633.9,267.7,633.9,267.7,633.9"/>
						<path class="st8" d="M267.8,634l6,4.9c0,0,0,0,0,0L267.8,634C267.8,634,267.8,634,267.8,634"/>
						<path class="st9" d="M267.8,634l5.9,4.9c0,0,0,0,0,0L267.8,634C267.9,634,267.9,634,267.8,634"/>
						<path class="st10" d="M267.9,634l5.9,4.8c0,0,0,0,0,0L267.9,634C267.9,634.1,267.9,634.1,267.9,634"/>
						<path class="st11" d="M268,634.1l5.8,4.8c0,0,0,0,0,0L268,634.1C268,634.1,268,634.1,268,634.1"/>
						<path class="st12" d="M268,634.1l5.7,4.7c0,0,0,0,0,0L268,634.1C268.1,634.2,268,634.1,268,634.1"/>
						<path class="st13" d="M268.1,634.2l5.7,4.7c0,0,0,0,0,0L268.1,634.2C268.1,634.2,268.1,634.2,268.1,634.2"/>
						<path class="st14" d="M268.1,634.2l5.6,4.7c0,0,0,0,0,0L268.1,634.2C268.2,634.2,268.2,634.2,268.1,634.2"/>
						<path class="st51" d="M268.2,634.2l5.5,4.6c0,0,0,0,0,0L268.2,634.2C268.2,634.3,268.2,634.3,268.2,634.2"/>
						<path class="st52" d="M268.2,634.3l5.5,4.6c0,0,0,0,0,0L268.2,634.3C268.3,634.3,268.3,634.3,268.2,634.3"/>
						<path class="st53" d="M268.3,634.3l5.4,4.5c0,0,0,0,0,0L268.3,634.3C268.3,634.3,268.3,634.3,268.3,634.3"/>
						<path class="st63" d="M268.3,634.4l5.4,4.5c0,0,0,0,0,0L268.3,634.4C268.4,634.4,268.4,634.4,268.3,634.4"/>
						<path class="st64" d="M268.4,634.4l5.3,4.5c0,0,0,0,0,0L268.4,634.4C268.4,634.4,268.4,634.4,268.4,634.4"/>
						<path class="st65" d="M268.4,634.4l5.3,4.5c0,0,0,0,0,0L268.4,634.4C268.4,634.4,268.4,634.4,268.4,634.4"/>
						<path class="st66" d="M268.5,634.4l5.2,4.4c0,0,0,0,0,0L268.5,634.4C268.5,634.5,268.5,634.4,268.5,634.4"/>
						<path class="st67" d="M268.5,634.5l5.2,4.4c0,0,0,0,0,0L268.5,634.5C268.5,634.5,268.5,634.5,268.5,634.5"/>
						<path class="st68" d="M268.5,634.5l5.1,4.4c0,0,0,0,0,0L268.5,634.5C268.6,634.5,268.5,634.5,268.5,634.5"/>
						<path class="st69" d="M268.6,634.5l5.1,4.3c0,0,0,0,0,0L268.6,634.5C268.6,634.5,268.6,634.5,268.6,634.5"/>
						<path class="st70" d="M268.6,634.5l5,4.3c0,0,0,0,0,0L268.6,634.5C268.6,634.6,268.6,634.6,268.6,634.5"/>
						<path class="st71" d="M268.6,634.6l5,4.3c0,0,0,0,0,0L268.6,634.6C268.7,634.6,268.6,634.6,268.6,634.6"/>
						<path class="st72" d="M268.7,634.6l4.9,4.3c0,0,0,0,0,0L268.7,634.6C268.7,634.6,268.7,634.6,268.7,634.6"/>
						<path class="st73" d="M268.7,634.6l4.9,4.2c0,0,0,0,0,0L268.7,634.6C268.7,634.6,268.7,634.6,268.7,634.6"/>
						<path class="st74" d="M268.7,634.6l4.8,4.2c0,0,0,0,0,0L268.7,634.6C268.8,634.7,268.8,634.7,268.7,634.6"/>
						<path class="st75" d="M268.8,634.7l4.8,4.2c0,0,0,0,0,0L268.8,634.7C268.8,634.7,268.8,634.7,268.8,634.7"/>
						<path class="st76" d="M268.8,634.7l4.8,4.1c0,0,0,0,0,0L268.8,634.7C268.8,634.7,268.8,634.7,268.8,634.7"/>
						<path class="st77" d="M268.8,634.7l4.7,4.1c0,0,0,0,0,0L268.8,634.7C268.9,634.7,268.9,634.7,268.8,634.7"/>
						<path class="st78" d="M268.9,634.7l4.7,4.1c0,0,0,0,0,0L268.9,634.7C268.9,634.8,268.9,634.8,268.9,634.7"/>
						<path class="st77" d="M268.9,634.8l4.6,4.1c0,0,0,0,0,0L268.9,634.8C269,634.8,269,634.8,268.9,634.8"/>
						<path class="st76" d="M269,634.8l4.5,4c0,0,0,0,0,0L269,634.8C269.1,634.9,269,634.9,269,634.8"/>
						<path class="st75" d="M269.1,634.9l4.4,3.9c0,0,0,0,0,0L269.1,634.9C269.1,634.9,269.1,634.9,269.1,634.9"/>
						<path class="st74" d="M269.1,634.9l4.3,3.9c0,0,0,0,0,0L269.1,634.9C269.2,635,269.2,635,269.1,634.9"/>
						<path class="st73" d="M269.2,635l4.2,3.8c0,0,0,0,0,0L269.2,635C269.3,635,269.2,635,269.2,635"/>
						<path class="st72" d="M269.3,635l4.1,3.8c0,0,0,0,0,0L269.3,635C269.3,635.1,269.3,635.1,269.3,635"/>
						<path class="st71" d="M269.3,635.1l4,3.7c0,0,0,0,0,0L269.3,635.1C269.4,635.1,269.4,635.1,269.3,635.1"/>
						<path class="st70" d="M269.4,635.1l3.9,3.7c0,0,0,0,0,0L269.4,635.1C269.4,635.2,269.4,635.1,269.4,635.1"/>
						<path class="st69" d="M269.5,635.2l3.9,3.6c0,0,0,0,0,0L269.5,635.2C269.5,635.2,269.5,635.2,269.5,635.2"/>
						<path class="st68" d="M269.5,635.2l3.8,3.6c0,0,0,0,0,0L269.5,635.2C269.5,635.2,269.5,635.2,269.5,635.2"/>
						<path class="st67" d="M269.6,635.2l3.7,3.6c0,0,0,0,0,0L269.6,635.2C269.6,635.3,269.6,635.3,269.6,635.2"/>
						<path class="st66" d="M269.6,635.3l3.6,3.5c0,0,0,0,0,0L269.6,635.3C269.6,635.3,269.6,635.3,269.6,635.3"/>
						<path class="st65" d="M269.6,635.3l3.5,3.5c0,0,0,0,0,0L269.6,635.3C269.7,635.3,269.7,635.3,269.6,635.3"/>
						<path class="st64" d="M269.7,635.4l3.5,3.4c0,0,0,0,0,0L269.7,635.4C269.7,635.4,269.7,635.4,269.7,635.4"/>
						<path class="st63" d="M269.7,635.4l3.4,3.4c0,0,0,0,0,0L269.7,635.4C269.8,635.4,269.7,635.4,269.7,635.4"/>
						<path class="st53" d="M269.8,635.4l3.3,3.4c0,0,0,0-0.1,0L269.8,635.4C269.8,635.5,269.8,635.4,269.8,635.4"/>
						<path class="st52" d="M269.8,635.5l3.2,3.3c0,0,0,0-0.1,0L269.8,635.5C269.9,635.5,269.9,635.5,269.8,635.5"/>
						<path class="st51" d="M269.9,635.5l3.1,3.2c0,0,0,0-0.1,0L269.9,635.5C269.9,635.6,269.9,635.5,269.9,635.5"/>
						<path class="st14" d="M270,635.6l3,3.2c0,0,0,0-0.1,0L270,635.6C270,635.6,270,635.6,270,635.6"/>
						<path class="st13" d="M270,635.6l2.9,3.1c0,0,0,0-0.1,0L270,635.6C270.1,635.7,270,635.6,270,635.6"/>
						<path class="st12" d="M270.1,635.7l2.7,3c0,0-0.1,0-0.1,0L270.1,635.7C270.1,635.7,270.1,635.7,270.1,635.7"/>
						<path class="st11" d="M270.2,635.7l2.5,2.9c0,0-0.1,0-0.1,0L270.2,635.7C270.2,635.8,270.2,635.8,270.2,635.7"/>
						<path class="st10" d="M270.2,635.8l2.3,2.9c-0.1,0-0.1,0-0.2,0L270.2,635.8C270.3,635.9,270.3,635.8,270.2,635.8"/>
						<path class="st9" d="M270.3,635.9l2.1,2.7c-0.1,0-0.2,0-0.2,0L270.3,635.9C270.4,636,270.4,635.9,270.3,635.9"/>
						<path class="st8" d="M270.4,636l1.8,2.6c-0.2,0-0.3-0.1-0.5-0.1l-1.3-2.4C270.4,636.1,270.4,636,270.4,636"/>
						<path class="st7" d="M270.4,636.1l1.3,2.4c-1.1-0.2-2.5-0.4-4-0.4c-0.7,0-1.4,0-2.1,0.2l-0.4-2c0.8-0.2,1.7-0.2,2.5-0.2
							C269.3,636.1,270.4,636.3,270.4,636.1"/>
						<path class="st8" d="M265.2,636.3l0.4,2c-0.6,0.1-1.1,0.3-1.7,0.5l-0.8-1.8C263.8,636.7,264.5,636.5,265.2,636.3"/>
						<path class="st9" d="M263.1,636.9l0.8,1.8c-0.4,0.1-0.7,0.3-1,0.5l-1.2-1.6C262.2,637.4,262.7,637.1,263.1,636.9"/>
						<path class="st10" d="M261.8,637.7l1.2,1.6c-0.2,0.2-0.5,0.3-0.7,0.6l-1.4-1.4C261.2,638.1,261.5,637.9,261.8,637.7"/>
						<path class="st11" d="M260.9,638.4l1.4,1.4c0,0,0,0,0,0L260.9,638.4C260.8,638.4,260.9,638.4,260.9,638.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000017488464538771045350000004657126111746978493_" class="st1" d="M261.5,462.4l1.4-1.4
							c0.1,0.2,3,3.4,7.3,3.7l0.5,2C264.9,466.3,261.6,462.5,261.5,462.4z"/>
					</defs>
					<clipPath id="SVGID_00000081646993836024431150000002507249507867253648_">
						<use xlink:href="#SVGID_00000017488464538771045350000004657126111746978493_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000081646993836024431150000002507249507867253648_);enable-background:new    ;">
						<path class="st53" d="M261.5,462.4l1.4-1.4c0.1,0.1,1.5,1.7,3.9,2.8l-0.6,1.9C263.3,464.4,261.6,462.5,261.5,462.4"/>
						<path class="st52" d="M266.3,465.7l0.6-1.9c0.6,0.3,1.3,0.5,2,0.7l0,2C267.9,466.3,267.1,466,266.3,465.7"/>
						<path class="st51" d="M268.9,466.5l0-2c0.4,0.1,0.9,0.2,1.4,0.2l0.5,2C270.1,466.7,269.5,466.6,268.9,466.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000029767496992878214240000018100575660324289951_" class="st1" d="M270.7,466.8l-0.5-2
							c7.1-4.1,14.8-7.4,23-9.6l0.5,2C286,459.3,278.2,462.4,270.7,466.8z"/>
					</defs>
					<clipPath id="SVGID_00000183233579310691133700000014371485030706961328_">
						<use xlink:href="#SVGID_00000029767496992878214240000018100575660324289951_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000183233579310691133700000014371485030706961328_);enable-background:new    ;">
						<path class="st11" d="M270.7,466.8l-0.5-2c3.1-1.8,6.3-3.4,9.6-4.8l0.8,1.9C277.3,463.2,274,464.9,270.7,466.8"/>
						<path class="st12" d="M280.6,461.8l-0.8-1.9c4.3-1.9,8.8-3.5,13.4-4.8l0.5,2C289.4,458.3,284.9,459.9,280.6,461.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000021823378381908208630000014219122584574313382_" class="st1" d="M297.5,667.5h-2c0-8,0-11.8,0-12h2
							C297.5,655.7,297.5,659.5,297.5,667.5z"/>
					</defs>
					<clipPath id="SVGID_00000009552130316307857440000001284351369277807269_">
						<use xlink:href="#SVGID_00000021823378381908208630000014219122584574313382_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000009552130316307857440000001284351369277807269_);enable-background:new    ;">
						<path class="st5" d="M297.5,667.5h-2c0-8,0-11.8,0-12h2C297.5,655.7,297.5,659.5,297.5,667.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000084503956070964493110000015741829522937069989_" class="st1" d="M297.5,655.5h-2c0-0.3,0.4-6.9-5.2-9.9
							l1-1.8C298,647.5,297.5,655.2,297.5,655.5z"/>
					</defs>
					<clipPath id="SVGID_00000047031870662785081730000012145350616845366920_">
						<use xlink:href="#SVGID_00000084503956070964493110000015741829522937069989_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000047031870662785081730000012145350616845366920_);enable-background:new    ;">
						<path class="st5" d="M297.5,655.5h-2c0-0.2,0.2-2.7-0.9-5.3l1.8-0.9C297.7,652.4,297.5,655.3,297.5,655.5"/>
						<path class="st18" d="M296.4,649.4l-1.8,0.9c-0.6-1.4-1.6-2.8-3.1-4l1.2-1.6C294.5,646,295.7,647.7,296.4,649.4"/>
						<path class="st5" d="M292.6,644.7l-1.2,1.6c-0.3-0.2-0.7-0.5-1.1-0.7l1-1.8C291.8,644.1,292.2,644.3,292.6,644.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000006682436061997867780000003275765021338946449_" class="st1" d="M293.8,457.1l-0.5-2
							c2.1-1.6,4.3-4,4.3-6.6h2C299.5,452.1,296.8,454.8,293.8,457.1z"/>
					</defs>
					<clipPath id="SVGID_00000092430075357213213210000001938780020506397630_">
						<use xlink:href="#SVGID_00000006682436061997867780000003275765021338946449_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000092430075357213213210000001938780020506397630_);enable-background:new    ;">
						<path class="st10" d="M293.8,457.1l-0.5-2c0.4-0.4,0.9-0.7,1.3-1.1l1.1,1.6C295,456.1,294.4,456.6,293.8,457.1"/>
						<path class="st9" d="M295.6,455.5l-1.1-1.6c0.6-0.5,1.1-1.1,1.6-1.8l1.6,1.1C297.1,454.1,296.4,454.8,295.6,455.5"/>
						<path class="st8" d="M297.7,453.3l-1.6-1.1c0.4-0.5,0.7-1,0.9-1.5l1.9,0.7C298.6,452,298.2,452.7,297.7,453.3"/>
						<path class="st7" d="M298.9,451.3l-1.9-0.7c0.2-0.5,0.4-1,0.4-1.6l2,0.2C299.4,449.9,299.2,450.6,298.9,451.3"/>
						<path class="st5" d="M299.5,449.2l-2-0.2c0-0.2,0-0.4,0-0.5h2C299.5,448.7,299.5,449,299.5,449.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000052081957959049443680000003205542615636804745_" class="st1" d="M341.3,646.3c1.7-0.1,3.7-0.3,7.7-0.8
							c-3,0.3-6.9,0.8-9.9,1.1C340.1,646.4,340.8,646.3,341.3,646.3z"/>
					</defs>
					<clipPath id="SVGID_00000067208449771025827310000002254512827849720220_">
						<use xlink:href="#SVGID_00000052081957959049443680000003205542615636804745_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000067208449771025827310000002254512827849720220_);enable-background:new    ;">
						<path class="st14" d="M349.1,645.5l-9.9,1.1c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st13" d="M349.1,645.5l-9.9,1.1c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st12" d="M349.1,645.5l-9.8,1.1c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st11" d="M349.1,645.5l-9.8,1.1c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st10" d="M349.1,645.5l-9.7,1c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st9" d="M349.1,645.5l-9.7,1c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st8" d="M349.1,645.5l-9.7,1c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st7" d="M349.1,645.5l-9.6,1c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st5" d="M349.1,645.5l-9.6,1c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st18" d="M349.1,645.5l-9.5,1c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st20" d="M349.1,645.5l-9.5,1c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st21" d="M349.1,645.5l-9.4,1c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st22" d="M349.1,645.5l-9.4,1c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st23" d="M349.1,645.5l-9.3,1c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st24" d="M349.1,645.5l-9.3,1c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st25" d="M349.1,645.5l-9.2,1c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st26" d="M349.1,645.5l-9.2,0.9c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st27" d="M349.1,645.5l-9.2,0.9c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st28" d="M349.1,645.5l-9.1,0.9c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st29" d="M349.1,645.5l-9.1,0.9c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st30" d="M349.1,645.5l-9,0.9c0,0,0,0,0,0L349.1,645.5C349.1,645.5,349.1,645.5,349.1,645.5"/>
						<path class="st31" d="M349.1,645.5l-9,0.9c0,0,0,0,0,0L349.1,645.5C349.1,645.6,349.1,645.5,349.1,645.5"/>
						<path class="st32" d="M349,645.6l-8.9,0.9c0,0,0,0,0,0L349,645.6C349,645.6,349,645.6,349,645.6"/>
						<path class="st33" d="M349,645.6l-8.9,0.9c0,0,0.1,0,0.1,0L349,645.6C349,645.6,349,645.6,349,645.6"/>
						<path class="st34" d="M349,645.6l-8.8,0.9c0.1,0,0.1,0,0.2,0L349,645.6C349,645.6,349,645.6,349,645.6"/>
						<path class="st16" d="M349,645.6l-8.5,0.8c0.1,0,0.1,0,0.2,0L349,645.6C349,645.6,349,645.6,349,645.6"/>
						<path class="st35" d="M349,645.6l-8.3,0.8c0.1,0,0.1,0,0.2,0L349,645.6C349,645.6,349,645.6,349,645.6"/>
						<path class="st3" d="M349,645.6l-8.1,0.7c0.2,0,0.3,0,0.5-0.1l7.5-0.6C348.9,645.7,348.9,645.6,349,645.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000057853797938544849040000001293862957912810909_" class="st1" d="M304.5,674.5l0,2c-0.4,0-9-1-9-9h2
							C297.4,673.6,304.4,674.5,304.5,674.5z"/>
					</defs>
					<clipPath id="SVGID_00000078743811155571603600000015218425451789302712_">
						<use xlink:href="#SVGID_00000057853797938544849040000001293862957912810909_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000078743811155571603600000015218425451789302712_);enable-background:new    ;">
						<path class="st5" d="M304.5,674.5l0,2c-0.3,0-3.4-0.4-5.9-2.4l1.3-1.5C301.9,674.2,304.4,674.5,304.5,674.5"/>
						<path class="st18" d="M299.9,672.6l-1.3,1.5c-0.9-0.7-1.7-1.6-2.3-2.9l1.8-0.9C298.5,671.3,299.2,672.1,299.9,672.6"/>
						<path class="st5" d="M298.1,670.4l-1.8,0.9c-0.5-1-0.8-2.3-0.8-3.8h2C297.5,668.6,297.7,669.6,298.1,670.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000159470180238115301820000001392352606220726169_" class="st1" d="M299.5,448.5h-2c0-5,0-12.6,0-13
							c0-0.4,0-9,7-9l0,2c-4.9-0.1-5,6.6-5,7C299.5,435.9,299.5,443.5,299.5,448.5z"/>
					</defs>
					<clipPath id="SVGID_00000018227476466255823420000008412896510276299164_">
						<use xlink:href="#SVGID_00000159470180238115301820000001392352606220726169_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000018227476466255823420000008412896510276299164_);enable-background:new    ;">
						<path class="st5" d="M299.5,448.5h-2c0-5,0-12.6,0-13c0-0.1,0-0.6,0.1-1.3l2,0.3c-0.1,0.5-0.1,0.9-0.1,1
							C299.5,435.9,299.5,443.5,299.5,448.5"/>
						<path class="st7" d="M299.6,434.5l-2-0.3c0.1-0.9,0.3-2.3,0.9-3.6l1.8,1C299.8,432.7,299.6,433.7,299.6,434.5"/>
						<path class="st8" d="M300.2,431.7l-1.8-1c0.2-0.6,0.5-1.2,0.9-1.7l1.5,1.4C300.6,430.8,300.4,431.2,300.2,431.7"/>
						<path class="st9" d="M300.9,430.4l-1.5-1.4c0.2-0.3,0.5-0.6,0.8-0.9l1.3,1.6C301.3,429.9,301.1,430.1,300.9,430.4"/>
						<path class="st10" d="M301.5,429.6l-1.3-1.6c0.2-0.2,0.5-0.4,0.8-0.6l1,1.7C301.8,429.3,301.7,429.5,301.5,429.6"/>
						<path class="st11" d="M302.1,429.2l-1-1.7c0.2-0.2,0.5-0.3,0.8-0.4l0.8,1.8C302.4,428.9,302.2,429.1,302.1,429.2"/>
						<path class="st12" d="M302.6,428.9l-0.8-1.8c0.3-0.1,0.6-0.2,0.9-0.3l0.5,1.9C303,428.7,302.8,428.8,302.6,428.9"/>
						<path class="st13" d="M303.2,428.6l-0.5-1.9c0.3-0.1,0.6-0.1,1-0.2l0.2,2C303.7,428.5,303.4,428.6,303.2,428.6"/>
						<path class="st14" d="M303.9,428.5l-0.2-2c0.3,0,0.5,0,0.8,0l0,2C304.3,428.5,304.1,428.5,303.9,428.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000154417045080315309030000004630256492045361837_" class="st1" d="M320.5,473.5L320.5,473.5
							c-43.6,0-79,35.4-79,79c0,43.6,35.4,79,79,79h0c-43.6,0-79-35.4-79-79C241.5,508.9,276.9,473.5,320.5,473.5z"/>
					</defs>
					<clipPath id="SVGID_00000144327632210918811360000005333202852847455155_">
						<use xlink:href="#SVGID_00000154417045080315309030000004630256492045361837_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000144327632210918811360000005333202852847455155_);enable-background:new    ;">
						<path class="st3" d="M320.5,473.5L320.5,473.5c-30,0-56,16.7-69.4,41.3h0C264.5,490.2,290.5,473.5,320.5,473.5"/>
						<path class="st35" d="M251.1,514.8L251.1,514.8c-2.2,4.1-4.1,8.4-5.6,12.9h0C247,523.2,248.8,518.9,251.1,514.8"/>
						<path class="st16" d="M245.5,527.6L245.5,527.6c-1.2,3.7-2.2,7.5-2.8,11.5h0C243.3,535.2,244.3,531.4,245.5,527.6"/>
						<path class="st34" d="M242.6,539.1L242.6,539.1c-0.6,3.7-1,7.6-1.1,11.4h0C241.6,546.7,242,542.8,242.6,539.1"/>
						<path class="st33" d="M241.5,550.6L241.5,550.6c0,0.6,0,1.3,0,1.9c0,3.5,0.2,6.9,0.7,10.2h0c-0.4-3.3-0.7-6.8-0.7-10.2
							C241.5,551.9,241.5,551.2,241.5,550.6"/>
						<path class="st32" d="M242.2,562.7L242.2,562.7c0.6,4.8,1.7,9.4,3.1,13.8h0C243.8,572.1,242.8,567.5,242.2,562.7"/>
						<path class="st31" d="M245.2,576.6L245.2,576.6c6.1,19.1,19.3,35.1,36.6,44.8h0C264.6,611.7,251.4,595.7,245.2,576.6"/>
						<path class="st32" d="M281.8,621.4L281.8,621.4c4.1,2.3,8.5,4.3,13,5.8h0C290.3,625.7,285.9,623.7,281.8,621.4"/>
						<path class="st33" d="M294.8,627.2L294.8,627.2c3.7,1.3,7.6,2.3,11.5,3h0C302.4,629.5,298.5,628.5,294.8,627.2"/>
						<path class="st34" d="M306.3,630.2L306.3,630.2c3.7,0.7,7.6,1.1,11.4,1.2h0C313.9,631.3,310.1,630.9,306.3,630.2"/>
						<path class="st16" d="M317.8,631.5L317.8,631.5c0.9,0,1.8,0,2.7,0h0C319.6,631.5,318.7,631.5,317.8,631.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000130614352460433500500000004241905483804539794_" class="st1" d="M341.5,667.5L341.5,667.5c0,0.4,0,9-7,9
							h0C341.5,676.5,341.5,667.9,341.5,667.5z"/>
					</defs>
					<clipPath id="SVGID_00000141456678734878864030000007423176180502886072_">
						<use xlink:href="#SVGID_00000130614352460433500500000004241905483804539794_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000141456678734878864030000007423176180502886072_);enable-background:new    ;">
						<path class="st33" d="M341.5,667.5L341.5,667.5c0,0,0,0.1,0,0.2h0C341.5,667.6,341.5,667.5,341.5,667.5"/>
						<path class="st34" d="M341.5,667.7L341.5,667.7c0,0.4,0,1.1-0.2,2h0C341.4,668.8,341.5,668.1,341.5,667.7"/>
						<path class="st16" d="M341.3,669.7L341.3,669.7c-0.1,0.6-0.2,1.2-0.4,1.9h0C341.1,670.9,341.2,670.3,341.3,669.7"/>
						<path class="st35" d="M340.8,671.6L340.8,671.6c-0.2,0.5-0.4,1.1-0.7,1.6h0C340.4,672.6,340.7,672.1,340.8,671.6"/>
						<path class="st3" d="M340.2,673.1L340.2,673.1c-1,1.8-2.7,3.4-5.7,3.4h0C337.5,676.5,339.2,675,340.2,673.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000039098087863179089500000008824604550923388344_" class="st1" d="M341.5,654.5L341.5,654.5
							c0,5,0,12.6,0,13h0C341.5,667.1,341.5,659.5,341.5,654.5z"/>
					</defs>
					<clipPath id="SVGID_00000001643642268205031990000015150510731756659375_">
						<use xlink:href="#SVGID_00000039098087863179089500000008824604550923388344_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000001643642268205031990000015150510731756659375_);enable-background:new    ;">
						<path class="st33" d="M341.5,654.5L341.5,654.5c0,5,0,12.6,0,13h0C341.5,667.1,341.5,659.5,341.5,654.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000098203345025775563820000006657250453900165818_" class="st1" d="M343.5,435.5L343.5,435.5
							c0,8,0,11.8,0,12h0C343.5,447.3,343.5,443.5,343.5,435.5z"/>
					</defs>
					<clipPath id="SVGID_00000130607493567863961250000007314909615187191204_">
						<use xlink:href="#SVGID_00000098203345025775563820000006657250453900165818_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000130607493567863961250000007314909615187191204_);enable-background:new    ;">
						<path class="st33" d="M343.5,435.5L343.5,435.5c0,8,0,11.8,0,12h0C343.5,447.3,343.5,443.5,343.5,435.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000162328618831548736550000002285184372565489313_" class="st1" d="M334.5,426.5L334.5,426.5
							c0.5,0.1,9,1,9,9h0C343.5,427.5,334.9,426.5,334.5,426.5z"/>
					</defs>
					<clipPath id="SVGID_00000067928799041975064280000006559523466218197151_">
						<use xlink:href="#SVGID_00000162328618831548736550000002285184372565489313_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000067928799041975064280000006559523466218197151_);enable-background:new    ;">
						<path class="st34" d="M334.5,426.5L334.5,426.5c0.1,0,0.3,0,0.7,0.1h0C334.8,426.5,334.6,426.5,334.5,426.5"/>
						<path class="st33" d="M335.1,426.6L335.1,426.6c0.5,0.1,1.2,0.2,2,0.5h0C336.3,426.8,335.6,426.7,335.1,426.6"/>
						<path class="st32" d="M337.1,427.1L337.1,427.1c0.6,0.2,1.3,0.5,2,0.9h0C338.4,427.6,337.8,427.3,337.1,427.1"/>
						<path class="st31" d="M339.1,428L339.1,428c1.6,0.9,3.2,2.4,4,4.6h0C342.3,430.3,340.7,428.9,339.1,428"/>
						<path class="st32" d="M343.1,432.6L343.1,432.6c0.2,0.5,0.3,1,0.4,1.5h0C343.3,433.6,343.2,433.1,343.1,432.6"/>
						<path class="st33" d="M343.4,434.2L343.4,434.2c0.1,0.4,0.1,0.9,0.1,1.3h0C343.5,435,343.5,434.6,343.4,434.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000046326703305833417930000007851972835281959838_" class="st1" d="M334.5,674.5l0,2c-7,0-28.9,0-30,0l0-2
							C305.6,674.5,327.5,674.5,334.5,674.5z"/>
					</defs>
					<clipPath id="SVGID_00000163784614868996630390000007311906659601283462_">
						<use xlink:href="#SVGID_00000046326703305833417930000007851972835281959838_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000163784614868996630390000007311906659601283462_);enable-background:new    ;">
						<path class="st7" d="M334.5,674.5l0,2c-7,0-28.9,0-30,0l0-2C305.6,674.5,327.5,674.5,334.5,674.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000176734436619426855240000003774447748131857573_" class="st1" d="M304.5,428.5l0-2c7,0,28.9,0,30,0l0,2
							C333.3,428.5,311.5,428.5,304.5,428.5z"/>
					</defs>
					<clipPath id="SVGID_00000042707471950693645910000003645991352529016760_">
						<use xlink:href="#SVGID_00000176734436619426855240000003774447748131857573_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000042707471950693645910000003645991352529016760_);enable-background:new    ;">
						<path class="st14" d="M304.5,428.5l0-2c7,0,28.9,0,30,0l0,2C333.3,428.5,311.5,428.5,304.5,428.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000075125526301936926110000014687766444606947006_" class="st1" d="M343.5,447.5L343.5,447.5
							c0,0.2-0.2,4.2,2.3,7.5h0C343.2,451.7,343.5,447.7,343.5,447.5z"/>
					</defs>
					<clipPath id="SVGID_00000167357862994372764560000014259794688910978232_">
						<use xlink:href="#SVGID_00000075125526301936926110000014687766444606947006_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000167357862994372764560000014259794688910978232_);enable-background:new    ;">
						<path class="st34" d="M343.5,447.5L343.5,447.5c0,0,0,0.1,0,0.2h0C343.5,447.6,343.5,447.5,343.5,447.5"/>
						<path class="st33" d="M343.5,447.7L343.5,447.7c0,0.3,0,0.9,0.1,1.7h0C343.5,448.7,343.5,448,343.5,447.7"/>
						<path class="st32" d="M343.6,449.5L343.6,449.5c0.1,0.6,0.2,1.4,0.5,2.2h0C343.8,450.9,343.7,450.1,343.6,449.5"/>
						<path class="st31" d="M344.1,451.7L344.1,451.7c0.3,1.1,0.9,2.2,1.7,3.3h0C344.9,453.9,344.4,452.7,344.1,451.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000000904602201928377740000005327936619484807588_" class="st1" d="M339.5,654.5h2c0,5,0,12.6,0,13
							c0,0.4,0,9-7,9l0-2c4.9,0.1,5-6.6,5-7C339.5,667.1,339.5,659.5,339.5,654.5z"/>
					</defs>
					<clipPath id="SVGID_00000029758540808929802860000004127680662971943069_">
						<use xlink:href="#SVGID_00000000904602201928377740000005327936619484807588_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000029758540808929802860000004127680662971943069_);enable-background:new    ;">
						<path class="st51" d="M339.5,654.5h2c0,5,0,12.6,0,13c0,0.1,0,1-0.2,2.1l-2-0.4c0.1-0.9,0.1-1.5,0.1-1.6
							C339.5,667.1,339.5,659.5,339.5,654.5"/>
						<path class="st14" d="M339.3,669.1l2,0.4c-0.1,0.6-0.3,1.4-0.5,2.1l-1.8-0.9C339.1,670.2,339.3,669.6,339.3,669.1"/>
						<path class="st13" d="M339,670.8l1.8,0.9c-0.2,0.5-0.4,1-0.6,1.4l-1.7-1.2C338.7,671.5,338.8,671.1,339,670.8"/>
						<path class="st12" d="M338.5,671.9l1.7,1.2c-0.2,0.4-0.4,0.7-0.7,1.1l-1.5-1.4C338.2,672.5,338.4,672.2,338.5,671.9"/>
						<path class="st11" d="M338,672.7l1.5,1.4c-0.2,0.3-0.4,0.5-0.7,0.8l-1.3-1.6C337.7,673.1,337.9,672.9,338,672.7"/>
						<path class="st10" d="M337.5,673.3l1.3,1.6c-0.2,0.2-0.5,0.4-0.8,0.6l-1.1-1.7C337.2,673.7,337.4,673.5,337.5,673.3"/>
						<path class="st9" d="M337,673.8l1.1,1.7c-0.3,0.2-0.6,0.4-0.9,0.5l-0.8-1.9C336.5,674.1,336.8,673.9,337,673.8"/>
						<path class="st8" d="M336.3,674.2l0.8,1.9c-0.4,0.2-0.8,0.3-1.3,0.4l-0.4-2C335.7,674.4,336,674.3,336.3,674.2"/>
						<path class="st7" d="M335.4,674.4l0.4,2c-0.4,0.1-0.9,0.1-1.3,0.1l0-2C334.8,674.5,335.1,674.5,335.4,674.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000012474578385560026980000011519383965850562187_" class="st1" d="M349.4,645.5L349.4,645.5
							c0.1,0-7.9,4-7.9,9h0C341.5,649.5,349.5,645.5,349.4,645.5z"/>
					</defs>
					<clipPath id="SVGID_00000007405914725450615300000015660157543520298388_">
						<use xlink:href="#SVGID_00000012474578385560026980000011519383965850562187_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000007405914725450615300000015660157543520298388_);enable-background:new    ;">
						<polyline class="st34" points="349.4,645.5 349.4,645.5 349.4,645.5 349.4,645.5 349.4,645.5 						"/>
						<polyline class="st16" points="349.4,645.5 349.4,645.5 349.4,645.5 349.4,645.5 349.4,645.5 						"/>
						<path class="st35" d="M349.4,645.5L349.4,645.5C349.4,645.5,349.4,645.5,349.4,645.5L349.4,645.5
							C349.4,645.5,349.4,645.5,349.4,645.5"/>
						<path class="st3" d="M349.4,645.5L349.4,645.5c-0.3,0.2-5.2,2.8-7.1,6.3h0C344.2,648.3,349,645.7,349.4,645.5"/>
						<path class="st35" d="M342.3,651.8L342.3,651.8c-0.2,0.4-0.3,0.7-0.5,1.1h0C341.9,652.5,342.1,652.1,342.3,651.8"/>
						<path class="st16" d="M341.8,652.9L341.8,652.9c-0.1,0.3-0.1,0.5-0.2,0.8h0C341.6,653.4,341.7,653.1,341.8,652.9"/>
						<path class="st34" d="M341.6,653.7L341.6,653.7c0,0.2-0.1,0.5-0.1,0.7h0C341.5,654.1,341.5,653.9,341.6,653.7"/>
						<path class="st33" d="M341.5,654.4L341.5,654.4c0,0,0,0.1,0,0.1h0C341.5,654.5,341.5,654.4,341.5,654.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000135689484380496413090000015292605316982348935_" class="st1" d="M341.5,435.5h2c0,8,0,11.8,0,12h-2
							C341.5,447.3,341.5,443.5,341.5,435.5z"/>
					</defs>
					<clipPath id="SVGID_00000005268114933448224510000008356754283838427022_">
						<use xlink:href="#SVGID_00000135689484380496413090000015292605316982348935_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000005268114933448224510000008356754283838427022_);enable-background:new    ;">
						<path class="st51" d="M341.5,435.5h2c0,8,0,11.8,0,12h-2C341.5,447.3,341.5,443.5,341.5,435.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000057854252127537484120000015361578122762201003_" class="st1" d="M334.5,428.5l0-2c0.4,0.1,9,1,9,9h-2
							C341.6,429.4,334.6,428.5,334.5,428.5z"/>
					</defs>
					<clipPath id="SVGID_00000053528932887776905460000010636241056956120476_">
						<use xlink:href="#SVGID_00000057854252127537484120000015361578122762201003_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000053528932887776905460000010636241056956120476_);enable-background:new    ;">
						<path class="st51" d="M334.5,428.5l0-2c0.1,0,0.6,0.1,1.4,0.2l-0.4,2C334.9,428.6,334.5,428.5,334.5,428.5"/>
						<path class="st52" d="M335.5,428.7l0.4-2c0.8,0.2,1.8,0.5,2.9,1.1l-1,1.7C336.9,429.1,336.1,428.8,335.5,428.7"/>
						<path class="st53" d="M337.7,429.5l1-1.7c1.9,1,3.8,2.6,4.5,5.4l-1.9,0.5C340.8,431.6,339.2,430.3,337.7,429.5"/>
						<path class="st52" d="M341.3,433.7l1.9-0.5c0.2,0.6,0.2,1.3,0.3,2l-2,0.1C341.5,434.7,341.4,434.2,341.3,433.7"/>
						<path class="st51" d="M341.5,435.3l2-0.1c0,0.1,0,0.2,0,0.3h-2C341.5,435.4,341.5,435.4,341.5,435.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000156570760365848009360000010974425620493418129_" class="st1" d="M395.9,649.1c0.1,0.1,0.3,0.3,0.4,0.4
							L395.9,649.1C395.9,649.1,395.9,649.1,395.9,649.1z"/>
					</defs>
					<clipPath id="SVGID_00000096048481122887328420000013662231139650494359_">
						<use xlink:href="#SVGID_00000156570760365848009360000010974425620493418129_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000096048481122887328420000013662231139650494359_);enable-background:new    ;">
						<polyline class="st3" points="396.3,649.5 395.9,649.1 395.9,649.1 396.3,649.5 396.3,649.5 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000034051560516065244060000014593117728213473431_" class="st1" d="M341.5,447.5h2c0,0.2-0.3,4.2,2.3,7.5
							l-1.2,1.8C341.2,452.3,341.5,447.7,341.5,447.5z"/>
					</defs>
					<clipPath id="SVGID_00000142160393565795112400000001117607431886567331_">
						<use xlink:href="#SVGID_00000034051560516065244060000014593117728213473431_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000142160393565795112400000001117607431886567331_);enable-background:new    ;">
						<path class="st51" d="M341.5,447.5h2c0,0,0,0.2,0,0.5l-2,0.1C341.5,447.7,341.5,447.5,341.5,447.5"/>
						<path class="st52" d="M341.5,448.1l2-0.1c0,0.5,0,1.3,0.2,2.2l-1.9,0.5C341.6,449.6,341.5,448.7,341.5,448.1"/>
						<path class="st53" d="M341.8,450.7l1.9-0.5c0.3,1.4,0.8,3.1,2,4.7l-1.2,1.8C343,454.6,342.2,452.4,341.8,450.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000056429758790636049930000018150769665826456996_" class="st1" d="M341.3,646.3c2.1-0.2,4.3-0.4,8.1-0.8
							c0.1,0-7.9,4-7.9,9h-2C339.6,648.9,345,646,341.3,646.3z"/>
					</defs>
					<clipPath id="SVGID_00000057148667087308466570000013656204476452937355_">
						<use xlink:href="#SVGID_00000056429758790636049930000018150769665826456996_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000057148667087308466570000013656204476452937355_);enable-background:new    ;">
						<path class="st3" d="M339.1,646.6l10.3-1.1c0,0,0,0,0,0l-9.2,0.9C339.9,646.5,339.5,646.5,339.1,646.6"/>
						<path class="st35" d="M340.2,646.4l9.2-0.9c0,0,0,0,0,0L340.2,646.4C340.2,646.4,340.2,646.4,340.2,646.4"/>
						<path class="st16" d="M340.2,646.4l9.1-0.9c0,0,0,0,0,0L340.2,646.4C340.2,646.4,340.2,646.4,340.2,646.4"/>
						<path class="st34" d="M340.2,646.4l9.1-0.9c0,0,0,0,0,0L340.2,646.4C340.2,646.4,340.2,646.4,340.2,646.4"/>
						<path class="st33" d="M340.3,646.4l9.1-0.9c0,0,0,0,0,0L340.3,646.4C340.3,646.4,340.3,646.4,340.3,646.4"/>
						<path class="st32" d="M340.3,646.4l9-0.9c0,0,0,0,0,0L340.3,646.4C340.3,646.4,340.3,646.4,340.3,646.4"/>
						<path class="st31" d="M340.3,646.4l9-0.9c0,0,0,0,0,0L340.3,646.4C340.3,646.4,340.3,646.4,340.3,646.4"/>
						<path class="st30" d="M340.3,646.4l9-0.9c0,0,0,0,0,0L340.3,646.4C340.4,646.4,340.4,646.4,340.3,646.4"/>
						<path class="st29" d="M340.4,646.4l8.9-0.9c0,0,0,0,0,0L340.4,646.4C340.4,646.4,340.4,646.4,340.4,646.4"/>
						<path class="st28" d="M340.4,646.4l8.9-0.9c0,0,0,0,0,0L340.4,646.4C340.4,646.4,340.4,646.4,340.4,646.4"/>
						<path class="st27" d="M340.4,646.4l8.9-0.8c0,0,0,0,0,0L340.4,646.4C340.5,646.4,340.4,646.4,340.4,646.4"/>
						<path class="st26" d="M340.5,646.4l8.8-0.8c0,0,0,0,0,0L340.5,646.4C340.5,646.4,340.5,646.4,340.5,646.4"/>
						<path class="st25" d="M340.5,646.4l8.8-0.8c0,0,0,0,0,0L340.5,646.4C340.5,646.4,340.5,646.4,340.5,646.4"/>
						<path class="st24" d="M340.5,646.4l8.8-0.8c0,0,0,0,0,0L340.5,646.4C340.5,646.4,340.5,646.4,340.5,646.4"/>
						<path class="st23" d="M340.6,646.4l8.7-0.8c0,0,0,0,0,0L340.6,646.4C340.6,646.4,340.6,646.4,340.6,646.4"/>
						<path class="st22" d="M340.6,646.4l8.7-0.8c0,0,0,0,0,0L340.6,646.4C340.6,646.4,340.6,646.4,340.6,646.4"/>
						<path class="st21" d="M340.6,646.4l8.7-0.8c0,0,0,0,0,0L340.6,646.4C340.6,646.4,340.6,646.4,340.6,646.4"/>
						<path class="st20" d="M340.6,646.4l8.6-0.8c0,0,0,0,0,0L340.6,646.4C340.7,646.4,340.6,646.4,340.6,646.4"/>
						<path class="st18" d="M340.7,646.4l8.6-0.8c0,0,0,0,0,0L340.7,646.4C340.7,646.4,340.7,646.4,340.7,646.4"/>
						<path class="st5" d="M340.7,646.4l8.6-0.8c0,0,0,0,0,0L340.7,646.4C340.7,646.4,340.7,646.4,340.7,646.4"/>
						<path class="st7" d="M340.7,646.4l8.5-0.8c0,0,0,0,0,0L340.7,646.4C340.7,646.3,340.7,646.4,340.7,646.4"/>
						<path class="st8" d="M340.7,646.3l8.5-0.8c0,0,0,0,0,0L340.7,646.3C340.8,646.3,340.8,646.3,340.7,646.3"/>
						<path class="st9" d="M340.8,646.3l8.5-0.8c0,0,0,0,0,0L340.8,646.3C340.8,646.3,340.8,646.3,340.8,646.3"/>
						<path class="st10" d="M340.8,646.3l8.4-0.8c0,0,0,0,0,0L340.8,646.3C340.8,646.3,340.8,646.3,340.8,646.3"/>
						<path class="st11" d="M340.8,646.3l8.4-0.7c0,0,0,0,0,0L340.8,646.3C340.8,646.3,340.8,646.3,340.8,646.3"/>
						<path class="st12" d="M340.8,646.3l8.4-0.7c0,0,0,0,0,0L340.8,646.3C340.9,646.3,340.9,646.3,340.8,646.3"/>
						<path class="st13" d="M340.9,646.3l8.3-0.7c0,0,0,0,0,0L340.9,646.3C340.9,646.3,340.9,646.3,340.9,646.3"/>
						<path class="st14" d="M340.9,646.3l8.3-0.7c0,0,0,0,0,0L340.9,646.3C340.9,646.3,340.9,646.3,340.9,646.3"/>
						<path class="st51" d="M340.9,646.3l8.3-0.7c0,0,0,0,0,0L340.9,646.3C340.9,646.3,340.9,646.3,340.9,646.3"/>
						<path class="st52" d="M340.9,646.3l8.2-0.7c0,0,0,0-0.1,0l-7.9,0.6C341.1,646.3,341,646.3,340.9,646.3"/>
						<path class="st51" d="M341.2,646.3l7.9-0.6c0,0-0.1,0-0.1,0l-7.6,0.6C341.3,646.3,341.3,646.3,341.2,646.3"/>
						<path class="st14" d="M341.4,646.3l7.6-0.6c0,0-0.1,0-0.1,0.1l-7.4,0.5C341.5,646.3,341.5,646.3,341.4,646.3"/>
						<path class="st13" d="M341.6,646.3l7.4-0.5c-0.1,0-0.1,0.1-0.2,0.1l-6.8,0.4C341.8,646.3,341.7,646.3,341.6,646.3"/>
						<path class="st12" d="M341.9,646.3l6.8-0.4c-0.1,0.1-0.3,0.2-0.5,0.3l-5.9,0.2C342.2,646.3,342.1,646.3,341.9,646.3"/>
						<path class="st11" d="M342.3,646.3l5.9-0.2c-1.2,0.8-3.3,2.1-4.8,3.9l-2.4-0.7C342.1,647.6,343.1,646.6,342.3,646.3"/>
						<path class="st12" d="M341.1,649.4l2.4,0.7c-0.4,0.5-0.8,1-1.1,1.5l-2.1-0.6C340.5,650.4,340.8,649.9,341.1,649.4"/>
						<path class="st13" d="M340.3,651l2.1,0.6c-0.2,0.4-0.4,0.7-0.5,1.1l-2-0.4C340,651.8,340.1,651.3,340.3,651"/>
						<path class="st14" d="M339.8,652.2l2,0.4c-0.1,0.3-0.2,0.7-0.3,1l-2-0.2C339.6,653,339.7,652.6,339.8,652.2"/>
						<path class="st51" d="M339.6,653.4l2,0.2c-0.1,0.3-0.1,0.6-0.1,0.9h-2C339.5,654.1,339.5,653.7,339.6,653.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000081610856923813496140000018322496295093794698_" class="st1" d="M366.3,638.4L366.3,638.4
							c-5.4,2.9-11,5.2-16.9,7.1h0C355.3,643.6,360.9,641.3,366.3,638.4z"/>
					</defs>
					<clipPath id="SVGID_00000168836703228976604620000007558523581771759278_">
						<use xlink:href="#SVGID_00000081610856923813496140000018322496295093794698_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000168836703228976604620000007558523581771759278_);enable-background:new    ;">
						<path class="st3" d="M366.3,638.4L366.3,638.4c-5.4,2.9-11,5.2-16.9,7.1h0C355.3,643.6,360.9,641.3,366.3,638.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000046317099057928023530000005836217498883370125_" class="st1" d="M368,638.3L368,638.3
							c-0.5,0-1.1,0-1.7,0.2h0C366.9,638.3,367.4,638.3,368,638.3z"/>
					</defs>
					<clipPath id="SVGID_00000124861954236363973220000013528193272722378168_">
						<use xlink:href="#SVGID_00000046317099057928023530000005836217498883370125_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000124861954236363973220000013528193272722378168_);enable-background:new    ;">
						<path class="st3" d="M368,638.3L368,638.3c-0.5,0-1.1,0-1.7,0.2h0C366.9,638.3,367.4,638.3,368,638.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000016043567334058034750000016451327250510847137_" class="st1" d="M345.8,455L345.8,455
							c8.4,2.3,16.3,5.6,23.6,9.8h0C362,460.5,354.1,457.2,345.8,455z"/>
					</defs>
					<clipPath id="SVGID_00000124142989795137449550000005803938609636716987_">
						<use xlink:href="#SVGID_00000016043567334058034750000016451327250510847137_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000124142989795137449550000005803938609636716987_);enable-background:new    ;">
						<path class="st33" d="M345.8,455L345.8,455c2.1,0.6,4.2,1.2,6.3,1.9h0C350,456.2,347.9,455.5,345.8,455"/>
						<path class="st32" d="M352,456.9L352,456.9c5.7,1.9,11.1,4.4,16.2,7.3h0C363.1,461.2,357.7,458.8,352,456.9"/>
						<path class="st31" d="M368.2,464.1L368.2,464.1c0.4,0.2,0.8,0.4,1.2,0.6h0C369,464.6,368.6,464.3,368.2,464.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000142874306615553277280000005357078410767084189_" class="st1" d="M369.4,464.8L369.4,464.8
							c0.6,0.1,1.3,0.1,1.9,0.1h0C370.6,464.9,370,464.8,369.4,464.8z"/>
					</defs>
					<clipPath id="SVGID_00000083059005628114542830000005720640082989708462_">
						<use xlink:href="#SVGID_00000142874306615553277280000005357078410767084189_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000083059005628114542830000005720640082989708462_);enable-background:new    ;">
						<path class="st34" d="M369.4,464.8L369.4,464.8c0.5,0,0.9,0.1,1.4,0.1h0C370.3,464.9,369.8,464.8,369.4,464.8"/>
						<path class="st16" d="M370.7,464.9L370.7,464.9c0.2,0,0.4,0,0.6,0h0C371.1,464.9,370.9,464.9,370.7,464.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000124864609912565382330000006421286200140822930_" class="st1" d="M365.9,636.4l0.4,2
							c-5.4,2.9-11,5.2-16.9,7.1l-10.3,1.1C354.4,641.7,360.1,639.4,365.9,636.4z"/>
					</defs>
					<clipPath id="SVGID_00000066478256932632282350000011355444230029173438_">
						<use xlink:href="#SVGID_00000124864609912565382330000006421286200140822930_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000066478256932632282350000011355444230029173438_);enable-background:new    ;">
						<path class="st9" d="M365.9,636.4l0.4,2c-3.1,1.7-6.3,3.1-9.6,4.5l-2.6-1.3C359.2,639.7,362.6,638.2,365.9,636.4"/>
						<path class="st8" d="M354.1,641.6l2.6,1.3c-2.4,1-4.8,1.8-7.3,2.6l-10.3,1.1C345.6,644.5,350.3,642.9,354.1,641.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000181053766527257522110000016374620214645606558_" class="st1" d="M344.6,456.7l1.2-1.8
							c8.4,2.3,16.3,5.6,23.6,9.8l-1,1.8C361.2,462.4,353.2,459.2,344.6,456.7z"/>
					</defs>
					<clipPath id="SVGID_00000060723346765690164280000012652271920406157976_">
						<use xlink:href="#SVGID_00000181053766527257522110000016374620214645606558_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000060723346765690164280000012652271920406157976_);enable-background:new    ;">
						<path class="st52" d="M344.6,456.7l1.2-1.8c7.5,2,14.7,4.9,21.4,8.6l-1,1.8C359.6,461.7,352.3,458.9,344.6,456.7"/>
						<path class="st53" d="M366.2,465.3l1-1.8c0.7,0.4,1.5,0.8,2.2,1.2l-1,1.8C367.6,466.1,366.9,465.7,366.2,465.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000025424891367630078860000009030972158431030207_" class="st1" d="M377.5,640.6l-1.4,1.4
							c-0.2-0.2-3.3-3.8-8.1-3.8c-0.5,0-1.1,0-1.7,0.2l-0.4-2c0.8-0.2,1.4-0.2,2.1-0.2C373.7,636.3,377.3,640.4,377.5,640.6z"/>
					</defs>
					<clipPath id="SVGID_00000132768215447631970050000016169814790951500681_">
						<use xlink:href="#SVGID_00000025424891367630078860000009030972158431030207_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000132768215447631970050000016169814790951500681_);enable-background:new    ;">
						<path class="st18" d="M377.5,640.6l-1.4,1.4c-0.1-0.1-0.7-0.8-1.9-1.7l1.1-1.6C376.6,639.7,377.4,640.5,377.5,640.6"/>
						<path class="st5" d="M375.3,638.7l-1.1,1.6c-1.2-0.9-3-1.8-5.1-2l0.2-2C371.8,636.6,373.9,637.7,375.3,638.7"/>
						<path class="st7" d="M369.2,636.3l-0.2,2c-0.3,0-0.7-0.1-1-0.1c-0.4,0-0.9,0-1.3,0.1l-0.2-2c0.6-0.1,1-0.1,1.6-0.1
							C368.4,636.3,368.8,636.3,369.2,636.3"/>
						<path class="st8" d="M366.4,636.3l0.2,2c-0.1,0-0.3,0-0.4,0.1l-0.4-2C366.1,636.4,366.3,636.3,366.4,636.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000166642669939986695180000014309605174998372265_" class="st1" d="M368.3,466.6l1-1.8
							c0.6,0.1,1.3,0.1,1.9,0.1c2.1,0,4.1-0.4,5.5-1.8l1.4,1.4c-1.9,1.9-4.5,2.4-6.9,2.4C370.5,467,369.8,466.8,368.3,466.6z"/>
					</defs>
					<clipPath id="SVGID_00000018212897071507361190000001173065546530593668_">
						<use xlink:href="#SVGID_00000166642669939986695180000014309605174998372265_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000018212897071507361190000001173065546530593668_);enable-background:new    ;">
						<path class="st51" d="M368.3,466.6l1-1.8c0.4,0,0.7,0.1,1.1,0.1l-0.2,2C369.8,466.8,369.2,466.7,368.3,466.6"/>
						<path class="st14" d="M370.3,466.9l0.2-2c0.3,0,0.5,0,0.8,0c0.4,0,0.8,0,1.3-0.1l0.2,2c-0.5,0-1,0.1-1.5,0.1
							C370.9,466.9,370.6,466.9,370.3,466.9"/>
						<path class="st13" d="M372.7,466.8l-0.2-2c0.5,0,1-0.1,1.5-0.3l0.6,1.9C374,466.7,373.4,466.8,372.7,466.8"/>
						<path class="st12" d="M374.6,466.5l-0.6-1.9c0.4-0.1,0.7-0.2,1.1-0.4l0.9,1.8C375.5,466.2,375.1,466.4,374.6,466.5"/>
						<path class="st11" d="M376,466l-0.9-1.8c0.3-0.1,0.6-0.3,0.8-0.4l1.1,1.7C376.7,465.6,376.3,465.8,376,466"/>
						<path class="st10" d="M377.1,465.4l-1.1-1.7c0.3-0.2,0.5-0.3,0.7-0.6l1.4,1.5C377.7,464.9,377.4,465.2,377.1,465.4"/>
						<path class="st9" d="M378,464.7l-1.4-1.5c0,0,0.1-0.1,0.1-0.1l1.4,1.4C378.1,464.6,378.1,464.6,378,464.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000051355225106795456450000000675516308015602095_" class="st1" d="M385.9,649.1l-1.4,1.4
							c-5.7-5.7-8.3-8.3-8.5-8.5l1.4-1.4C377.6,640.7,380.3,643.4,385.9,649.1z"/>
					</defs>
					<clipPath id="SVGID_00000181809223531705911190000016024969310128617129_">
						<use xlink:href="#SVGID_00000051355225106795456450000000675516308015602095_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000181809223531705911190000016024969310128617129_);enable-background:new    ;">
						<path class="st18" d="M385.9,649.1l-1.4,1.4c-5.7-5.7-8.3-8.3-8.5-8.5l1.4-1.4C377.6,640.7,380.3,643.4,385.9,649.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000146469730920062993440000015767136695486992573_" class="st1" d="M397.3,650.5L397.3,650.5
							c-0.1,0.1-3.1,2.5-6.9,2.5h0C394.1,653,397.1,650.6,397.3,650.5z"/>
					</defs>
					<clipPath id="SVGID_00000034791344409571702990000008390269304984092832_">
						<use xlink:href="#SVGID_00000146469730920062993440000015767136695486992573_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000034791344409571702990000008390269304984092832_);enable-background:new    ;">
						<path class="st3" d="M397.3,650.5L397.3,650.5c-0.1,0.1-3.1,2.5-6.9,2.5h0C394.1,653,397.1,650.6,397.3,650.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000045588087134109587660000007257694411828745091_" class="st1" d="M392.9,450.6L392.9,450.6
							c1.4,0,2.9,0.5,4.3,1.9h0C395.9,451.1,394.4,450.6,392.9,450.6z"/>
					</defs>
					<clipPath id="SVGID_00000043425022385035404140000001637480989586871714_">
						<use xlink:href="#SVGID_00000045588087134109587660000007257694411828745091_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000043425022385035404140000001637480989586871714_);enable-background:new    ;">
						<path class="st16" d="M392.9,450.6L392.9,450.6c0.1,0,0.2,0,0.2,0h0C393.1,450.6,393,450.6,392.9,450.6"/>
						<path class="st34" d="M393.2,450.6L393.2,450.6c0.3,0,0.6,0,0.9,0.1h0C393.7,450.7,393.4,450.6,393.2,450.6"/>
						<path class="st33" d="M394,450.7L394,450.7c0.3,0,0.6,0.1,0.8,0.2h0C394.5,450.8,394.3,450.8,394,450.7"/>
						<path class="st32" d="M394.8,450.9L394.8,450.9c0.3,0.1,0.6,0.3,1,0.4h0C395.5,451.2,395.1,451,394.8,450.9"/>
						<path class="st31" d="M395.8,451.4L395.8,451.4c0.5,0.3,1,0.7,1.5,1.2h0C396.8,452,396.3,451.6,395.8,451.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000101789231208491260280000001363579719860877748_" class="st1" d="M395.9,649.1c0.1,0.1,0.3,0.3,0.4,0.4
							l1,1c-0.2,0.1-3.1,2.5-6.9,2.5c-1.9,0-3.9-0.6-5.8-2.5l1.4-1.4c1.5,1.5,3,1.9,4.4,1.9C393.4,651,395.8,649.1,395.9,649.1z"/>
					</defs>
					<clipPath id="SVGID_00000036965990920979674380000005346757319631542974_">
						<use xlink:href="#SVGID_00000101789231208491260280000001363579719860877748_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000036965990920979674380000005346757319631542974_);enable-background:new    ;">
						<polyline class="st9" points="395.8,649.1 397.3,650.5 397.3,650.5 395.8,649.1 395.8,649.1 						"/>
						<path class="st10" d="M395.8,649.1l1.4,1.4c0,0-0.2,0.2-0.5,0.4l-1.2-1.6C395.8,649.2,395.9,649.1,395.8,649.1"/>
						<path class="st9" d="M395.5,649.3l1.2,1.6c-0.5,0.3-1.2,0.8-2.2,1.2l-0.8-1.8C394.6,649.9,395.2,649.5,395.5,649.3"/>
						<path class="st8" d="M393.8,650.3l0.8,1.8c-0.7,0.3-1.6,0.6-2.5,0.8l-0.3-2C392.5,650.7,393.2,650.5,393.8,650.3"/>
						<path class="st7" d="M391.7,650.9l0.3,2c-0.5,0.1-1.1,0.2-1.7,0.2c-0.3,0-0.6,0-0.9-0.1l0.2-2c0.2,0,0.5,0,0.7,0
							C390.8,651,391.3,651,391.7,650.9"/>
						<path class="st5" d="M389.7,651l-0.2,2c-1.2-0.1-2.5-0.6-3.7-1.5l1.1-1.6C387.8,650.5,388.7,650.9,389.7,651"/>
						<path class="st18" d="M386.8,649.9l-1.1,1.6c-0.4-0.3-0.8-0.6-1.2-1l1.4-1.4C386.2,649.4,386.5,649.6,386.8,649.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000072259444759681701750000017789495248295065519_" class="st1" d="M396.3,649.5l-0.4-0.4c0,0,0,0,0,0
							C396,649.2,396.1,649.4,396.3,649.5z"/>
					</defs>
					<clipPath id="SVGID_00000142876194729906793300000004613972295927535753_">
						<use xlink:href="#SVGID_00000072259444759681701750000017789495248295065519_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000142876194729906793300000004613972295927535753_);enable-background:new    ;">
						<polyline class="st9" points="395.8,649.1 396.3,649.5 396.3,649.5 395.8,649.1 395.8,649.1 						"/>
						<polyline class="st10" points="395.8,649.1 396.3,649.5 396.3,649.5 395.9,649.1 395.8,649.1 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000065042614220368367720000001327892357480041870_" class="st1" d="M378.2,464.5l-1.4-1.4
							c3.5-3.5,8.9-8.9,9.2-9.2c0.2-0.2,3.3-3.3,7-3.3c1.4,0,2.9,0.5,4.3,1.9l-1.4,1.4c-1-1-2-1.3-2.9-1.3c-2.8,0-5.5,2.6-5.6,2.7
							C387.1,455.6,381.7,461,378.2,464.5z"/>
					</defs>
					<clipPath id="SVGID_00000152251640976120010610000002258607385421001640_">
						<use xlink:href="#SVGID_00000065042614220368367720000001327892357480041870_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000152251640976120010610000002258607385421001640_);enable-background:new    ;">
						<path class="st9" d="M378.2,464.5l-1.4-1.4c3.5-3.5,8.9-8.9,9.2-9.2c0,0,0.1-0.1,0.2-0.2l1.4,1.4c-0.1,0.1-0.1,0.1-0.1,0.1
							C387.1,455.6,381.7,461,378.2,464.5"/>
						<path class="st10" d="M387.5,455.2l-1.4-1.4c0.3-0.3,1-0.9,1.9-1.5l1,1.7C388.3,454.5,387.8,455,387.5,455.2"/>
						<path class="st11" d="M389.1,454l-1-1.7c0.5-0.3,1.1-0.7,1.7-0.9l0.7,1.9C390,453.4,389.5,453.7,389.1,454"/>
						<path class="st12" d="M390.5,453.2l-0.7-1.9c0.4-0.2,0.9-0.3,1.4-0.5l0.4,1.9C391.2,452.9,390.8,453,390.5,453.2"/>
						<path class="st13" d="M391.5,452.8l-0.4-1.9c0.4-0.1,0.7-0.2,1.1-0.2l0.2,2C392.1,452.7,391.8,452.7,391.5,452.8"/>
						<path class="st14" d="M392.4,452.6l-0.2-2c0.2,0,0.5,0,0.7,0c0.1,0,0.3,0,0.4,0l-0.1,2c-0.1,0-0.2,0-0.3,0
							C392.8,452.6,392.6,452.6,392.4,452.6"/>
						<path class="st51" d="M393.2,452.6l0.1-2c0.3,0,0.7,0.1,1,0.2l-0.5,1.9C393.6,452.7,393.4,452.6,393.2,452.6"/>
						<path class="st52" d="M393.9,452.7l0.5-1.9c0.4,0.1,0.8,0.2,1.1,0.4l-0.9,1.8C394.4,452.9,394.1,452.8,393.9,452.7"/>
						<path class="st53" d="M394.6,453l0.9-1.8c0.6,0.3,1.2,0.7,1.8,1.3l-1.4,1.4C395.4,453.5,395,453.2,394.6,453"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000059288393163300918670000010019224303338388399_" class="st1" d="M406.6,598.7L406.6,598.7
							c-0.6,2.7-1.2,7.6,1.2,10.1h0C405.4,606.3,406,601.4,406.6,598.7z"/>
					</defs>
					<clipPath id="SVGID_00000077292101665022186390000004341737441933677445_">
						<use xlink:href="#SVGID_00000059288393163300918670000010019224303338388399_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000077292101665022186390000004341737441933677445_);enable-background:new    ;">
						<path class="st16" d="M406.6,598.7L406.6,598.7c-0.1,0.4-0.2,0.8-0.2,1.3h0C406.5,599.5,406.6,599.1,406.6,598.7"/>
						<path class="st34" d="M406.4,600L406.4,600c-0.1,0.9-0.3,1.9-0.3,2.9h0C406.1,601.9,406.2,600.9,406.4,600"/>
						<path class="st33" d="M406.1,602.9L406.1,602.9c0,0.7,0,1.4,0.1,2h0C406.1,604.2,406.1,603.6,406.1,602.9"/>
						<path class="st32" d="M406.2,604.9L406.2,604.9c0.1,0.5,0.2,1.1,0.4,1.6h0C406.4,606,406.3,605.4,406.2,604.9"/>
						<path class="st31" d="M406.5,606.5L406.5,606.5c0.3,0.9,0.7,1.7,1.4,2.3h0C407.2,608.1,406.8,607.3,406.5,606.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000070812224911446659610000016740465081359558788_" class="st1" d="M410,494.9L410,494.9
							c-0.2,0.1-3.3,2.9-3.7,7.2h0C406.7,497.9,409.8,495.1,410,494.9z"/>
					</defs>
					<clipPath id="SVGID_00000160184378311973330700000000771080979041312390_">
						<use xlink:href="#SVGID_00000070812224911446659610000016740465081359558788_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000160184378311973330700000000771080979041312390_);enable-background:new    ;">
						<path class="st3" d="M410,494.9L410,494.9c-0.1,0.1-1.4,1.2-2.4,3.2h0C408.6,496.2,409.9,495,410,494.9"/>
						<path class="st35" d="M407.5,498.1L407.5,498.1c-0.3,0.6-0.6,1.2-0.8,1.8h0C407,499.3,407.2,498.6,407.5,498.1"/>
						<path class="st16" d="M406.7,499.9L406.7,499.9c-0.1,0.5-0.3,1-0.4,1.5h0C406.5,500.9,406.6,500.4,406.7,499.9"/>
						<path class="st34" d="M406.4,501.4L406.4,501.4c0,0.2-0.1,0.5-0.1,0.7h0C406.3,501.9,406.3,501.7,406.4,501.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000032647073042208899470000000647926306124281262_" class="st1" d="M320.5,633.5l0-2c43.6,0,79-35.4,79-79
							c0-43.6-35.4-79-79-79c-43.6,0-79,35.4-79,79c0,43.6,35.4,79,79,79L320.5,633.5c-44.7,0-81-36.3-81-81c0-44.7,36.3-81,81-81
							c44.7,0,81,36.3,81,81C401.5,597.2,365.2,633.5,320.5,633.5z"/>
					</defs>
					<clipPath id="SVGID_00000173875502352347465120000001643006270359681669_">
						<use xlink:href="#SVGID_00000032647073042208899470000000647926306124281262_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000173875502352347465120000001643006270359681669_);enable-background:new    ;">
						<path class="st14" d="M320.5,633.5l0-2c2.7,0,5.3-0.1,7.9-0.4l0.2,2C325.9,633.4,323.2,633.5,320.5,633.5"/>
						<path class="st13" d="M328.6,633.1l-0.2-2c4.1-0.4,8.1-1.1,12-2.2l0.5,1.9C337,631.9,332.8,632.7,328.6,633.1"/>
						<path class="st12" d="M341,630.9l-0.5-1.9c4.1-1.1,8.1-2.5,11.9-4.1l0.8,1.8C349.2,628.4,345.2,629.8,341,630.9"/>
						<path class="st11" d="M353.2,626.6l-0.8-1.8c3.9-1.7,7.7-3.8,11.2-6.1l1.1,1.7C361,622.8,357.2,624.9,353.2,626.6"/>
						<path class="st10" d="M364.7,620.4l-1.1-1.7c3.9-2.5,7.5-5.4,10.9-8.6l1.4,1.5C372.4,614.9,368.7,617.8,364.7,620.4"/>
						<path class="st9" d="M375.9,611.6l-1.4-1.5c3.8-3.5,7.2-7.4,10.2-11.6l1.6,1.2C383.2,604,379.7,608,375.9,611.6"/>
						<path class="st8" d="M386.3,599.7l-1.6-1.2c3.4-4.8,6.3-9.9,8.6-15.4l1.8,0.8C392.8,589.6,389.8,594.9,386.3,599.7"/>
						<path class="st7" d="M395.1,584l-1.8-0.8c2.9-6.9,4.9-14.3,5.7-22l2,0.2C400.1,569.3,398.1,576.9,395.1,584"/>
						<path class="st5" d="M401,561.4l-2-0.2c0.3-2.8,0.5-5.7,0.5-8.7c0-10.9-2.2-21.3-6.2-30.8l1.8-0.8c4.1,9.7,6.4,20.4,6.4,31.6
							C401.5,555.5,401.3,558.5,401,561.4"/>
						<path class="st18" d="M395.1,520.9l-1.8,0.8c-5.7-13.5-15.1-25.1-26.9-33.5l1.2-1.6C379.6,495.2,389.2,507.1,395.1,520.9"/>
						<path class="st5" d="M367.5,486.5l-1.2,1.6c-10.7-7.6-23.3-12.6-37-14.2l0.2-2C343.6,473.6,356.6,478.7,367.5,486.5"/>
						<path class="st7" d="M329.5,472l-0.2,2c-2.9-0.3-5.8-0.5-8.8-0.5c-4.8,0-9.4,0.4-14,1.2l-0.4-2c4.7-0.8,9.4-1.3,14.3-1.3
							C323.6,471.5,326.6,471.7,329.5,472"/>
						<path class="st8" d="M306.1,472.8l0.4,2c-5.9,1-11.5,2.7-16.8,5l-0.8-1.8C294.4,475.6,300.1,473.8,306.1,472.8"/>
						<path class="st9" d="M288.9,477.9l0.8,1.8c-4.7,2-9.2,4.4-13.4,7.3l-1.1-1.7C279.5,482.5,284.1,479.9,288.9,477.9"/>
						<path class="st10" d="M275.2,485.4l1.1,1.7c-3.8,2.6-7.4,5.5-10.6,8.6l-1.4-1.4C267.6,491,271.3,488,275.2,485.4"/>
						<path class="st11" d="M264.3,494.2l1.4,1.4c-3.1,3-6,6.2-8.5,9.7l-1.6-1.2C258.1,500.6,261.1,497.3,264.3,494.2"/>
						<path class="st12" d="M255.5,504.2l1.6,1.2c-2.5,3.3-4.7,6.8-6.6,10.5l-1.8-0.9C250.7,511.2,253,507.6,255.5,504.2"/>
						<path class="st13" d="M248.7,514.9l1.8,0.9c-1.9,3.7-3.6,7.5-4.9,11.5l-1.9-0.6C245.1,522.7,246.7,518.7,248.7,514.9"/>
						<path class="st14" d="M243.7,526.7l1.9,0.6c-1.3,3.9-2.3,8-3,12.2l-2-0.3C241.3,534.9,242.3,530.8,243.7,526.7"/>
						<path class="st51" d="M240.6,539.2l2,0.3c-0.7,4.2-1.1,8.5-1.1,12.9c0,0.5,0,0.9,0,1.4l-2,0c0-0.5,0-1,0-1.4
							C239.5,548,239.9,543.5,240.6,539.2"/>
						<path class="st52" d="M239.5,553.9l2,0c0.1,6,0.9,11.8,2.2,17.4l-1.9,0.5C240.4,566,239.6,560.1,239.5,553.9"/>
						<path class="st53" d="M241.8,571.8l1.9-0.5c5.5,22.7,20.9,41.6,41.3,51.8l-0.9,1.8C263.2,614.4,247.5,595,241.8,571.8"/>
						<path class="st52" d="M284.1,624.9l0.9-1.8c5.1,2.6,10.5,4.6,16.1,6l-0.5,1.9C294.9,629.6,289.4,627.5,284.1,624.9"/>
						<path class="st51" d="M300.7,631.1l0.5-1.9c4.5,1.1,9.2,1.9,14,2.2l-0.1,2C310.1,633,305.3,632.2,300.7,631.1"/>
						<path class="st14" d="M315,633.3l0.1-2c1.8,0.1,3.5,0.2,5.3,0.2l0,2C318.7,633.5,316.8,633.4,315,633.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000151514009273207924690000017766726757428455854_" class="st1" d="M415,578.7L415,578.7
							c-2,7-4.9,13.7-8.3,20h0C410.1,592.4,412.9,585.7,415,578.7z"/>
					</defs>
					<clipPath id="SVGID_00000067227880993498570910000009014362108512723862_">
						<use xlink:href="#SVGID_00000151514009273207924690000017766726757428455854_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000067227880993498570910000009014362108512723862_);enable-background:new    ;">
						<path class="st16" d="M415,578.7L415,578.7c-0.3,1.1-0.6,2.2-1,3.2h0C414.3,580.9,414.7,579.8,415,578.7"/>
						<path class="st35" d="M414,581.9L414,581.9c-1.8,5.6-4.2,11-6.9,16.1h0C409.8,592.9,412.1,587.5,414,581.9"/>
						<path class="st3" d="M407,598L407,598c-0.1,0.2-0.2,0.5-0.4,0.7h0C406.8,598.5,406.9,598.2,407,598"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000037653193873822503590000013445318153596256663_" class="st1" d="M404.9,597.7l1.8,1
							c-0.6,2.7-1.3,7.6,1.2,10.1l-1.4,1.4C403.2,606.9,404,601.2,404.9,597.7z"/>
					</defs>
					<clipPath id="SVGID_00000032621085914080007980000001555468577000556477_">
						<use xlink:href="#SVGID_00000037653193873822503590000013445318153596256663_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000032621085914080007980000001555468577000556477_);enable-background:new    ;">
						<path class="st14" d="M404.9,597.7l1.8,1c-0.1,0.4-0.2,0.8-0.3,1.3l-1.9-0.6C404.6,598.8,404.8,598.2,404.9,597.7"/>
						<path class="st51" d="M404.5,599.3l1.9,0.6c-0.2,1-0.3,2.2-0.3,3.4l-2,0.1C404.1,602,404.3,600.6,404.5,599.3"/>
						<path class="st52" d="M404.1,603.4l2-0.1c0,0.8,0.1,1.6,0.2,2.4l-1.9,0.6C404.2,605.4,404.1,604.4,404.1,603.4"/>
						<path class="st53" d="M404.4,606.3l1.9-0.6c0.3,1.2,0.7,2.2,1.5,3l-1.4,1.4C405.4,609.1,404.8,607.8,404.4,606.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000036223210960711299550000003900914469691910571_" class="st1" d="M406.3,502.1L406.3,502.1
							c4,7,7.1,14.6,9.3,22.6h0C413.4,516.8,410.2,509.2,406.3,502.1z"/>
					</defs>
					<clipPath id="SVGID_00000158747642980269979780000004233486875433077384_">
						<use xlink:href="#SVGID_00000036223210960711299550000003900914469691910571_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000158747642980269979780000004233486875433077384_);enable-background:new    ;">
						<path class="st31" d="M406.3,502.1L406.3,502.1c3.3,5.8,6,12,8,18.4h0C412.2,514.1,409.5,508,406.3,502.1"/>
						<path class="st32" d="M414.3,520.6L414.3,520.6c0.5,1.4,0.9,2.8,1.2,4.2h0C415.2,523.4,414.7,522,414.3,520.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000109752052283168057070000017575611696247480750_" class="st1" d="M407.9,608.8L407.9,608.8
							c3.5,3.5,8.9,8.9,9.2,9.2h0C416.8,617.7,411.4,612.3,407.9,608.8z"/>
					</defs>
					<clipPath id="SVGID_00000074420565876046108950000003015296510772436865_">
						<use xlink:href="#SVGID_00000109752052283168057070000017575611696247480750_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000074420565876046108950000003015296510772436865_);enable-background:new    ;">
						<path class="st31" d="M407.9,608.8L407.9,608.8c3.5,3.5,8.9,8.9,9.2,9.2h0C416.8,617.7,411.4,612.3,407.9,608.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000071552312529251876080000003582820638095846058_" class="st1" d="M408.6,493.5l1.4,1.4
							c-0.2,0.1-3.3,2.9-3.7,7.2l-2.1,0.4C404.8,496.9,408.4,493.6,408.6,493.5z"/>
					</defs>
					<clipPath id="SVGID_00000115496045397640187590000002821714463652142760_">
						<use xlink:href="#SVGID_00000071552312529251876080000003582820638095846058_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000115496045397640187590000002821714463652142760_);enable-background:new    ;">
						<path class="st10" d="M408.6,493.5l1.4,1.4c0,0-0.1,0.1-0.2,0.2l-1.4-1.4C408.5,493.6,408.6,493.5,408.6,493.5"/>
						<path class="st11" d="M408.4,493.7l1.4,1.4c-0.3,0.2-0.7,0.7-1.2,1.4l-1.6-1.2C407.6,494.5,408.1,494,408.4,493.7"/>
						<path class="st12" d="M407,495.3l1.6,1.2c-0.3,0.4-0.7,1-1,1.6l-1.8-0.8C406.2,496.5,406.6,495.8,407,495.3"/>
						<path class="st13" d="M405.8,497.3l1.8,0.8c-0.3,0.5-0.5,1-0.7,1.6l-1.9-0.4C405.2,498.5,405.5,497.9,405.8,497.3"/>
						<path class="st14" d="M404.9,499.3l1.9,0.4c-0.2,0.5-0.4,1.1-0.5,1.7l-2,0.2C404.5,500.7,404.7,499.9,404.9,499.3"/>
						<path class="st51" d="M404.4,501.5l2-0.2c0,0.3-0.1,0.5-0.1,0.8l-2.1,0.4C404.3,502.2,404.3,501.8,404.4,501.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000005958833267724985650000017191100987203786128_" class="st1" d="M418.5,629.3L418.5,629.3
							c-4.9,5-20.4,20.4-21.2,21.2h0C398.1,649.7,413.5,634.2,418.5,629.3z"/>
					</defs>
					<clipPath id="SVGID_00000075859350170282017220000017691519587427715758_">
						<use xlink:href="#SVGID_00000005958833267724985650000017191100987203786128_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000075859350170282017220000017691519587427715758_);enable-background:new    ;">
						<path class="st3" d="M418.5,629.3L418.5,629.3c-4.9,5-20.4,20.4-21.2,21.2h0C398.1,649.7,413.5,634.2,418.5,629.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000125577497246467343060000005715564400805088168_" class="st1" d="M418.5,486.4L418.5,486.4
							c-5.6,5.7-8.3,8.3-8.5,8.5h0C410.1,494.8,412.8,492.1,418.5,486.4z"/>
					</defs>
					<clipPath id="SVGID_00000011024004720916211320000004367560966559238047_">
						<use xlink:href="#SVGID_00000125577497246467343060000005715564400805088168_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000011024004720916211320000004367560966559238047_);enable-background:new    ;">
						<path class="st3" d="M418.5,486.4L418.5,486.4c-5.6,5.7-8.3,8.3-8.5,8.5h0C410.1,494.8,412.8,492.1,418.5,486.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000142871711219656414480000008051051872006551209_" class="st1" d="M397.3,452.5L397.3,452.5
							c5,4.9,20.4,20.4,21.2,21.2h0C417.7,472.9,402.2,457.5,397.3,452.5z"/>
					</defs>
					<clipPath id="SVGID_00000094590049568393655510000011268341127981640585_">
						<use xlink:href="#SVGID_00000142871711219656414480000008051051872006551209_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000094590049568393655510000011268341127981640585_);enable-background:new    ;">
						<path class="st31" d="M397.3,452.5L397.3,452.5c5,4.9,20.4,20.4,21.2,21.2h0C417.7,472.9,402.2,457.5,397.3,452.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000165213898232929547470000017402406604390802359_" class="st1" d="M413.2,577.7l1.8,1
							c-2.1,7-4.9,13.7-8.3,20l-1.8-1C408.3,591.6,411,584.8,413.2,577.7z"/>
					</defs>
					<clipPath id="SVGID_00000109727796362035463220000002613927113588792465_">
						<use xlink:href="#SVGID_00000165213898232929547470000017402406604390802359_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000109727796362035463220000002613927113588792465_);enable-background:new    ;">
						<path class="st14" d="M413.2,577.7l1.8,1c-0.3,0.9-0.5,1.7-0.8,2.6l-1.8-1C412.6,579.4,412.9,578.6,413.2,577.7"/>
						<path class="st13" d="M412.4,580.3l1.8,1c-1.7,5.3-3.9,10.5-6.4,15.4l-1.8-0.9C408.5,590.9,410.5,585.7,412.4,580.3"/>
						<path class="st12" d="M405.9,595.7l1.8,0.9c-0.4,0.7-0.7,1.4-1.1,2l-1.8-1C405.2,597.1,405.6,596.4,405.9,595.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000042013114374305376860000004002764906998220164_" class="st1" d="M415.5,524.8L415.5,524.8
							c1.7,2.2,4.2,4.7,7,4.7h0C419.7,529.5,417.2,527,415.5,524.8z"/>
					</defs>
					<clipPath id="SVGID_00000039117043080462600560000009342052038393854646_">
						<use xlink:href="#SVGID_00000042013114374305376860000004002764906998220164_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000039117043080462600560000009342052038393854646_);enable-background:new    ;">
						<path class="st31" d="M415.5,524.8L415.5,524.8c1.1,1.4,2.5,3,4.2,3.9h0C418,527.8,416.6,526.2,415.5,524.8"/>
						<path class="st32" d="M419.7,528.7L419.7,528.7c0.4,0.2,0.8,0.4,1.1,0.5h0C420.4,529.1,420,528.9,419.7,528.7"/>
						<path class="st33" d="M420.8,529.2L420.8,529.2c0.3,0.1,0.5,0.2,0.8,0.2h0C421.3,529.4,421.1,529.3,420.8,529.2"/>
						<path class="st34" d="M421.6,529.4L421.6,529.4c0.2,0,0.5,0.1,0.7,0.1h0C422.1,529.5,421.8,529.5,421.6,529.4"/>
						<path class="st16" d="M422.3,529.5L422.3,529.5c0.1,0,0.1,0,0.2,0h0C422.4,529.5,422.4,529.5,422.3,529.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000106830686085902603290000003196098077381624196_" class="st1" d="M404.2,502.6l2.1-0.4
							c4,7,7.1,14.6,9.3,22.6l-2,0.5C411.4,517.7,408.4,510,404.2,502.6z"/>
					</defs>
					<clipPath id="SVGID_00000101814847969861090590000012619995343269109656_">
						<use xlink:href="#SVGID_00000106830686085902603290000003196098077381624196_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000101814847969861090590000012619995343269109656_);enable-background:new    ;">
						<path class="st53" d="M404.2,502.6l2.1-0.4c4,7,7.1,14.6,9.3,22.6l-2,0.5C411.4,517.7,408.4,510,404.2,502.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000156573579376557181820000014076758046241693334_" class="st1" d="M423.4,575.5L423.4,575.5
							c-1.6,0-5.6,0.3-8.4,3.2h0C417.8,575.8,421.8,575.5,423.4,575.5z"/>
					</defs>
					<clipPath id="SVGID_00000059278037267966752470000015206940938951177624_">
						<use xlink:href="#SVGID_00000156573579376557181820000014076758046241693334_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000059278037267966752470000015206940938951177624_);enable-background:new    ;">
						<path class="st3" d="M423.4,575.5L423.4,575.5c-1.6,0-5.6,0.3-8.4,3.2h0C417.8,575.8,421.8,575.5,423.4,575.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000026134855447984018470000006845022778878077088_" class="st1" d="M417.1,618L417.1,618
							c0.3,0.3,6.4,6.4,1.4,11.3h0C423.4,624.3,417.4,618.3,417.1,618z"/>
					</defs>
					<clipPath id="SVGID_00000119808965389472828930000000262222734415548294_">
						<use xlink:href="#SVGID_00000026134855447984018470000006845022778878077088_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000119808965389472828930000000262222734415548294_);enable-background:new    ;">
						<path class="st31" d="M417.1,618L417.1,618c0.2,0.2,2.2,2.2,3,4.8h0C419.2,620.1,417.2,618.1,417.1,618"/>
						<path class="st32" d="M420.1,622.7L420.1,622.7c0.2,0.4,0.3,0.9,0.3,1.3h0C420.3,623.6,420.2,623.2,420.1,622.7"/>
						<path class="st33" d="M420.4,624.1L420.4,624.1c0.1,0.3,0.1,0.6,0.1,1h0C420.4,624.7,420.4,624.4,420.4,624.1"/>
						<path class="st34" d="M420.4,625L420.4,625c0,0.3,0,0.6-0.1,0.8h0C420.4,625.6,420.4,625.3,420.4,625"/>
						<path class="st16" d="M420.3,625.9L420.3,625.9c0,0.3-0.1,0.6-0.2,0.8h0C420.2,626.4,420.3,626.2,420.3,625.9"/>
						<path class="st35" d="M420.1,626.7L420.1,626.7c-0.1,0.3-0.2,0.6-0.4,1l0,0C419.9,627.3,420,627,420.1,626.7"/>
						<path class="st3" d="M419.7,627.7L419.7,627.7c-0.3,0.5-0.7,1.1-1.2,1.6h0C419,628.8,419.4,628.2,419.7,627.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000109002662486661841250000007608480089271438736_" class="st1" d="M418.5,473.7L418.5,473.7
							c0.3,0.4,5.7,7.1,0,12.7h0C424.1,480.8,418.8,474.1,418.5,473.7z"/>
					</defs>
					<clipPath id="SVGID_00000104693851214999364860000009554048518087111829_">
						<use xlink:href="#SVGID_00000109002662486661841250000007608480089271438736_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000104693851214999364860000009554048518087111829_);enable-background:new    ;">
						<path class="st31" d="M418.5,473.7L418.5,473.7c0.1,0.2,1.4,1.7,2.1,3.9h0C419.9,475.5,418.6,473.9,418.5,473.7"/>
						<path class="st32" d="M420.6,477.7L420.6,477.7c0.2,0.6,0.3,1.2,0.4,1.8h0C420.9,478.8,420.8,478.2,420.6,477.7"/>
						<path class="st33" d="M421,479.5L421,479.5c0.1,0.4,0.1,0.9,0.1,1.3h0C421.1,480.3,421,479.9,421,479.5"/>
						<path class="st34" d="M421.1,480.8L421.1,480.8c0,0.4,0,0.8-0.1,1.1h0C421,481.5,421,481.1,421.1,480.8"/>
						<path class="st16" d="M420.9,481.9L420.9,481.9c-0.1,0.4-0.1,0.7-0.3,1.1h0C420.8,482.6,420.9,482.3,420.9,481.9"/>
						<path class="st35" d="M420.7,483L420.7,483c-0.1,0.4-0.3,0.9-0.6,1.3h0C420.3,483.9,420.5,483.5,420.7,483"/>
						<path class="st3" d="M420.1,484.3L420.1,484.3c-0.4,0.7-0.9,1.4-1.6,2.1h0C419.2,485.7,419.7,485,420.1,484.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000003080445643087117940000004057670831008109953_" class="st1" d="M395.9,453.9l1.4-1.4
							c4.9,4.9,20.4,20.4,21.2,21.2l-1.4,1.4C416.2,474.3,400.8,458.9,395.9,453.9z"/>
					</defs>
					<clipPath id="SVGID_00000177463482151323601770000007383371121167757186_">
						<use xlink:href="#SVGID_00000003080445643087117940000004057670831008109953_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000177463482151323601770000007383371121167757186_);enable-background:new    ;">
						<path class="st53" d="M395.9,453.9l1.4-1.4c4.9,4.9,20.4,20.4,21.2,21.2l-1.4,1.4C416.2,474.3,400.8,458.9,395.9,453.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000159434285118513637270000017022324225034271879_" class="st1" d="M417.1,627.9l1.4,1.4
							c-4.9,5-20.4,20.4-21.2,21.2l-1.4-1.4C396.7,648.3,412.1,632.8,417.1,627.9z"/>
					</defs>
					<clipPath id="SVGID_00000052822762242119072300000002417309252240281002_">
						<use xlink:href="#SVGID_00000159434285118513637270000017022324225034271879_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000052822762242119072300000002417309252240281002_);enable-background:new    ;">
						<path class="st11" d="M417.1,627.9l1.4,1.4c-4.9,5-20.4,20.4-21.2,21.2l-1.4-1.4C396.7,648.3,412.1,632.8,417.1,627.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000115487481082150334580000014810219519449563818_" class="st1" d="M417.1,485l1.4,1.4
							c-5.7,5.7-8.3,8.3-8.5,8.5l-1.4-1.4C408.7,493.4,411.4,490.7,417.1,485z"/>
					</defs>
					<clipPath id="SVGID_00000130641467377066758900000000480884221989568917_">
						<use xlink:href="#SVGID_00000115487481082150334580000014810219519449563818_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000130641467377066758900000000480884221989568917_);enable-background:new    ;">
						<path class="st11" d="M417.1,485l1.4,1.4c-5.7,5.7-8.3,8.3-8.5,8.5l-1.4-1.4C408.7,493.4,411.4,490.7,417.1,485"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000090252386890141904730000002846222960065608885_" class="st1" d="M406.5,610.2l1.4-1.4
							c3.5,3.5,8.9,8.9,9.2,9.2c0.3,0.3,6.4,6.4,1.4,11.3l-1.4-1.4c3.5-3.4-1.2-8.2-1.4-8.5C415.4,619.1,410,613.7,406.5,610.2z"/>
					</defs>
					<clipPath id="SVGID_00000052105559039351933420000008293078101488152481_">
						<use xlink:href="#SVGID_00000090252386890141904730000002846222960065608885_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000052105559039351933420000008293078101488152481_);enable-background:new    ;">
						<path class="st53" d="M406.5,610.2l1.4-1.4c3.5,3.5,8.9,8.9,9.2,9.2c0.2,0.2,2.4,2.4,3.1,5.2l-2,0.3c-0.6-2.1-2.4-3.9-2.5-4
							C415.4,619.1,410,613.7,406.5,610.2"/>
						<path class="st52" d="M418.1,623.4l2-0.3c0.1,0.5,0.2,1.1,0.2,1.6l-2-0.1C418.3,624.2,418.3,623.8,418.1,623.4"/>
						<path class="st51" d="M418.4,624.6l2,0.1c0,0.4,0,0.7-0.1,1.1l-2-0.4C418.4,625.2,418.4,624.9,418.4,624.6"/>
						<path class="st14" d="M418.3,625.4l2,0.4c-0.1,0.3-0.1,0.6-0.2,0.9l-1.9-0.7C418.3,625.9,418.3,625.7,418.3,625.4"/>
						<path class="st13" d="M418.2,626.1l1.9,0.7c-0.1,0.3-0.2,0.6-0.4,0.8l-1.8-0.9C418.1,626.5,418.1,626.3,418.2,626.1"/>
						<path class="st12" d="M418,626.7l1.8,0.9c-0.1,0.3-0.3,0.5-0.5,0.8l-1.6-1.1C417.7,627.1,417.9,626.9,418,626.7"/>
						<path class="st11" d="M417.6,627.3l1.6,1.1c-0.2,0.3-0.5,0.6-0.7,0.9l-1.4-1.4C417.3,627.7,417.4,627.5,417.6,627.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000094606914310695314110000002083582136578354316_" class="st1" d="M417.1,475.1l1.4-1.4
							c0.3,0.4,5.7,7.1,0,12.7l-1.4-1.4C421.4,480.7,417.1,475.2,417.1,475.1z"/>
					</defs>
					<clipPath id="SVGID_00000086667388572902054670000017806402022576005761_">
						<use xlink:href="#SVGID_00000094606914310695314110000002083582136578354316_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000086667388572902054670000017806402022576005761_);enable-background:new    ;">
						<path class="st53" d="M417.1,475.1l1.4-1.4c0.1,0.2,1.7,2.1,2.3,4.6l-2,0.4C418.3,476.7,417.1,475.2,417.1,475.1"/>
						<path class="st52" d="M418.8,478.7l2-0.4c0.2,0.6,0.3,1.3,0.3,2l-2-0.1C419,479.8,418.9,479.2,418.8,478.7"/>
						<path class="st51" d="M419,480.3l2,0.1c0,0.5,0,1-0.1,1.5l-2-0.4C419,481.1,419,480.7,419,480.3"/>
						<path class="st14" d="M419,481.5l2,0.4c-0.1,0.4-0.2,0.8-0.3,1.2l-1.9-0.6C418.8,482.1,418.9,481.8,419,481.5"/>
						<path class="st13" d="M418.7,482.4l1.9,0.6c-0.1,0.4-0.3,0.8-0.5,1.1l-1.8-0.9C418.5,483,418.6,482.7,418.7,482.4"/>
						<path class="st12" d="M418.4,483.3l1.8,0.9c-0.2,0.4-0.4,0.8-0.7,1.1l-1.6-1.1C418,483.9,418.2,483.6,418.4,483.3"/>
						<path class="st11" d="M417.8,484.2l1.6,1.1c-0.3,0.4-0.6,0.8-1,1.1l-1.4-1.4C417.3,484.7,417.6,484.5,417.8,484.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000142152128004082681260000018300662459524069508_" class="st1" d="M413.6,525.3l2-0.5
							c1.6,2.2,4.1,4.7,6.9,4.7l0,2C418.7,531.5,415.7,528.4,413.6,525.3z"/>
					</defs>
					<clipPath id="SVGID_00000083804895947305033530000016647107848201596069_">
						<use xlink:href="#SVGID_00000142152128004082681260000018300662459524069508_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000083804895947305033530000016647107848201596069_);enable-background:new    ;">
						<path class="st63" d="M413.6,525.3l2-0.5c0.6,0.8,1.3,1.6,2.1,2.4l-1.4,1.3C415.2,527.5,414.3,526.4,413.6,525.3"/>
						<path class="st53" d="M416.2,528.5l1.4-1.3c0.8,0.7,1.6,1.3,2.5,1.8l-0.8,1.8C418.2,530.2,417.1,529.4,416.2,528.5"/>
						<path class="st52" d="M419.3,530.7l0.8-1.8c0.4,0.2,0.8,0.3,1.2,0.4l-0.4,2C420.3,531.2,419.8,531,419.3,530.7"/>
						<path class="st51" d="M420.9,531.3l0.4-2c0.3,0.1,0.6,0.1,0.9,0.1l-0.1,2C421.7,531.5,421.3,531.4,420.9,531.3"/>
						<path class="st14" d="M422.1,531.5l0.1-2c0.1,0,0.2,0,0.3,0l0,2C422.3,531.5,422.2,531.5,422.1,531.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000074430822093379508690000003593613633654761398_" class="st1" d="M444.5,566.5L444.5,566.5c0,0.5-1,9-9,9
							h0C443.5,575.5,444.4,567,444.5,566.5z"/>
					</defs>
					<clipPath id="SVGID_00000050627935728618280700000013978376601244559245_">
						<use xlink:href="#SVGID_00000074430822093379508690000003593613633654761398_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000050627935728618280700000013978376601244559245_);enable-background:new    ;">
						<path class="st34" d="M444.5,566.5L444.5,566.5c0,0.1,0,0.3-0.1,0.5h0C444.4,566.8,444.5,566.6,444.5,566.5"/>
						<path class="st16" d="M444.4,567L444.4,567c-0.1,0.5-0.2,1.2-0.5,2h0C444.2,568.2,444.3,567.5,444.4,567"/>
						<path class="st35" d="M443.9,569L443.9,569c-0.2,0.6-0.5,1.3-0.8,2h0C443.4,570.3,443.7,569.6,443.9,569"/>
						<path class="st3" d="M443.1,571L443.1,571c-1.2,2.3-3.5,4.5-7.6,4.5h0C439.6,575.5,441.8,573.2,443.1,571"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000092423053892343876880000012863551447067975062_" class="st1" d="M444.5,536.5L444.5,536.5
							c0,7,0,28.8,0,30h0C444.5,565.3,444.5,543.5,444.5,536.5z"/>
					</defs>
					<clipPath id="SVGID_00000165945004904126050380000014675206705986476968_">
						<use xlink:href="#SVGID_00000092423053892343876880000012863551447067975062_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000165945004904126050380000014675206705986476968_);enable-background:new    ;">
						<path class="st33" d="M444.5,536.5L444.5,536.5c0,7,0,28.8,0,30h0C444.5,565.3,444.5,543.5,444.5,536.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000041292089582917410440000003660445090193306783_" class="st1" d="M435.5,529.5L435.5,529.5c0.5,0,9,0,9,7
							h0C444.5,529.5,435.9,529.5,435.5,529.5z"/>
					</defs>
					<clipPath id="SVGID_00000102518761045807295010000013905311131125749422_">
						<use xlink:href="#SVGID_00000041292089582917410440000003660445090193306783_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000102518761045807295010000013905311131125749422_);enable-background:new    ;">
						<path class="st16" d="M435.5,529.5L435.5,529.5c0.1,0,0.2,0,0.3,0h0C435.6,529.5,435.5,529.5,435.5,529.5"/>
						<path class="st34" d="M435.8,529.5L435.8,529.5c0.4,0,1.2,0.1,2,0.2h0C436.9,529.6,436.2,529.5,435.8,529.5"/>
						<path class="st33" d="M437.8,529.7L437.8,529.7c0.6,0.1,1.2,0.3,1.9,0.5h0C439,530,438.4,529.8,437.8,529.7"/>
						<path class="st32" d="M439.7,530.2L439.7,530.2c0.5,0.2,1.1,0.4,1.6,0.7h0C440.7,530.6,440.2,530.4,439.7,530.2"/>
						<path class="st31" d="M441.2,530.9L441.2,530.9c1.3,0.7,2.4,1.8,2.9,3.4h0C443.6,532.7,442.5,531.6,441.2,530.9"/>
						<path class="st32" d="M444.2,534.3L444.2,534.3c0.1,0.4,0.2,0.8,0.3,1.2h0C444.4,535.1,444.3,534.7,444.2,534.3"/>
						<path class="st33" d="M444.4,535.5L444.4,535.5c0.1,0.3,0.1,0.6,0.1,1h0C444.5,536.2,444.5,535.8,444.4,535.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000066488272130200218080000010839903970991095944_" class="st1" d="M435.5,573.5l0,2c-8,0-11.8,0-12,0
							c-1.5,0-5.6,0.2-8.5,3.2l-1.8-1c3.8-3.9,8.6-4.2,10.3-4.2C423.7,573.5,427.5,573.5,435.5,573.5z"/>
					</defs>
					<clipPath id="SVGID_00000115512673402676453920000001433318186779852205_">
						<use xlink:href="#SVGID_00000066488272130200218080000010839903970991095944_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000115512673402676453920000001433318186779852205_);enable-background:new    ;">
						<path class="st7" d="M435.5,573.5l0,2c-8,0-11.8,0-12,0c-0.6,0-1.5,0-2.6,0.2l-0.4-1.9c1.3-0.2,2.4-0.3,3-0.3
							C423.7,573.5,427.5,573.5,435.5,573.5"/>
						<path class="st8" d="M420.5,573.8l0.4,1.9c-0.8,0.1-1.8,0.4-2.7,0.8l-0.9-1.7C418.4,574.3,419.5,574,420.5,573.8"/>
						<path class="st9" d="M417.3,574.8l0.9,1.7c-0.6,0.2-1.1,0.5-1.7,0.9l-1.3-1.4C415.9,575.5,416.6,575.1,417.3,574.8"/>
						<path class="st10" d="M415.1,576l1.3,1.4c-0.4,0.3-0.9,0.6-1.3,1l-1.7-1.1C414.1,576.8,414.6,576.4,415.1,576"/>
						<path class="st11" d="M413.5,577.3l1.7,1.1c-0.1,0.1-0.2,0.2-0.3,0.3l-1.8-1C413.3,577.5,413.4,577.4,413.5,577.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000150089958973497893720000011149230937399145610_" class="st1" d="M422.5,531.5l0-2c5,0,12.6,0,13,0l0,2
							C435.1,531.5,427.5,531.5,422.5,531.5z"/>
					</defs>
					<clipPath id="SVGID_00000065039208803116009670000014141832560722464693_">
						<use xlink:href="#SVGID_00000150089958973497893720000011149230937399145610_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000065039208803116009670000014141832560722464693_);enable-background:new    ;">
						<path class="st14" d="M422.5,531.5l0-2c5,0,12.6,0,13,0l0,2C435.1,531.5,427.5,531.5,422.5,531.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000074435114794431399430000017976955104165722293_" class="st1" d="M442.5,566.5h2c0,0.5-1,9-9,9l0-2
							C441.6,573.6,442.4,566.6,442.5,566.5z"/>
					</defs>
					<clipPath id="SVGID_00000065759063564700628300000013599259589970788540_">
						<use xlink:href="#SVGID_00000074435114794431399430000017976955104165722293_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000065759063564700628300000013599259589970788540_);enable-background:new    ;">
						<path class="st51" d="M442.5,566.5h2c0,0.1,0,0.2-0.1,0.5l-2-0.2C442.5,566.6,442.5,566.5,442.5,566.5"/>
						<path class="st14" d="M442.4,566.8l2,0.2c-0.1,0.5-0.2,1.2-0.5,2.1l-1.9-0.6C442.2,567.7,442.4,567.1,442.4,566.8"/>
						<path class="st13" d="M442,568.4l1.9,0.6c-0.2,0.6-0.4,1.2-0.8,1.8l-1.7-1C441.7,569.3,441.9,568.8,442,568.4"/>
						<path class="st12" d="M441.4,569.8l1.7,1c-0.2,0.5-0.5,0.9-0.9,1.4l-1.5-1.3C441,570.6,441.2,570.2,441.4,569.8"/>
						<path class="st11" d="M440.7,570.9l1.5,1.3c-0.3,0.4-0.6,0.7-1,1.1l-1.3-1.5C440.3,571.5,440.5,571.2,440.7,570.9"/>
						<path class="st10" d="M440,571.8l1.3,1.5c-0.3,0.3-0.7,0.6-1.1,0.9l-1.1-1.7C439.5,572.2,439.7,572,440,571.8"/>
						<path class="st9" d="M439.1,572.5l1.1,1.7c-0.4,0.3-0.8,0.5-1.3,0.7l-0.8-1.8C438.5,572.8,438.8,572.7,439.1,572.5"/>
						<path class="st8" d="M438.2,573l0.8,1.8c-0.5,0.2-1.1,0.4-1.7,0.5l-0.4-2C437.3,573.3,437.8,573.2,438.2,573"/>
						<path class="st7" d="M436.8,573.4l0.4,2c-0.5,0.1-1.1,0.2-1.8,0.2l0-2C436,573.5,436.4,573.5,436.8,573.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000057111626276837177680000012560313866275879096_" class="st1" d="M442.5,536.5h2c0,7,0,28.8,0,30h-2
							C442.5,565.3,442.5,543.5,442.5,536.5z"/>
					</defs>
					<clipPath id="SVGID_00000026131530845120436710000006109569907786311574_">
						<use xlink:href="#SVGID_00000057111626276837177680000012560313866275879096_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000026131530845120436710000006109569907786311574_);enable-background:new    ;">
						<path class="st51" d="M442.5,536.5h2c0,7,0,28.8,0,30h-2C442.5,565.3,442.5,543.5,442.5,536.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000090286876808623979080000007743463094500861576_" class="st1" d="M435.5,531.5l0-2c0.4,0,9,0,9,7h-2
							C442.6,531.6,435.8,531.5,435.5,531.5z"/>
					</defs>
					<clipPath id="SVGID_00000060004173490739486420000001968889770787568030_">
						<use xlink:href="#SVGID_00000090286876808623979080000007743463094500861576_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000060004173490739486420000001968889770787568030_);enable-background:new    ;">
						<path class="st14" d="M435.5,531.5l0-2c0.1,0,0.3,0,0.7,0l-0.1,2C435.7,531.5,435.5,531.5,435.5,531.5"/>
						<path class="st51" d="M436,531.5l0.1-2c0.6,0,1.6,0.1,2.7,0.4l-0.7,1.9C437.3,531.6,436.5,531.6,436,531.5"/>
						<path class="st52" d="M438.2,531.9l0.7-1.9c0.8,0.2,1.6,0.5,2.3,0.9l-1.2,1.7C439.4,532.2,438.8,532,438.2,531.9"/>
						<path class="st53" d="M440,532.5l1.2-1.7c1.4,0.8,2.6,2,3.1,3.9l-1.9,0.5C442,534,441.1,533.1,440,532.5"/>
						<path class="st52" d="M442.3,535.3l1.9-0.5c0.1,0.5,0.2,1,0.2,1.5l-2,0.1C442.5,536,442.4,535.6,442.3,535.3"/>
						<path class="st51" d="M442.5,536.4l2-0.1c0,0.1,0,0.1,0,0.2h-2C442.5,536.5,442.5,536.4,442.5,536.4"/>
					</g>
				</g>
				<g>
					<path class="st147" d="M435.5,531.5c0.4,0,7.1,0.1,7,5c0,7,0,28.8,0,30c0,0.1-0.9,7.1-7,7c-8,0-11.8,0-12,0
						c-1.7,0-6.5,0.2-10.3,4.2c-2.2,7.2-4.9,13.9-8.3,20.1c-0.8,3.5-1.7,9.1,1.6,12.5c3.5,3.5,8.9,8.9,9.2,9.2
						c0.3,0.3,5,5.1,1.4,8.5c-4.9,4.9-20.4,20.4-21.2,21.2c0.1-0.1-2.5,1.9-5.5,1.9c-1.4,0-2.9-0.5-4.4-1.9
						c-5.6-5.7-8.3-8.3-8.5-8.5c-0.1-0.2-3.8-4.3-9.5-4.3c-0.7,0-1.2-0.1-2.1,0.2c-5.8,3-11.5,5.3-26.8,10.2
						c7.5-1.4,0.5,1.5,0.4,7.9c0,5,0,12.6,0,13c0,0.4-0.1,7.1-5,7c-7,0-28.9,0-30,0c-0.1,0-7.1-0.9-7-7c0-8,0-11.8,0-12
						c0-0.3,0.5-8-6.2-11.7c-5.8-1.9-11.4-4.1-26.5-12c7.4,5.4,6.5,4.3,2.9,4.3c-2.4,0-5,0.5-6.9,2.4c-3.5,3.5-8.9,8.9-9.2,9.2
						c-0.1,0.1-2.8,2.7-5.6,2.7c-0.9,0-1.9-0.3-2.9-1.3c-4.9-4.9-20.4-20.4-21.2-21.2c-0.1-0.1-4.4-5.6,0-9.9
						c5.7-5.7,8.3-8.3,8.5-8.5c0.2-0.2,5.5-4.8,4.1-11.7c-3.2-5.9-5.5-11.8-7.5-17.5c-1.3-2.4-5.1-8.7-10.6-8.8c-5,0-12.6,0-13,0
						c-0.4,0-7.1-0.1-7-5c0-7,0-28.8,0-30c0-0.1,0.9-7.1,7-7c8,0,11.8,0,12,0c1.8,0,6.6-0.2,10.4-4.3c2.2-7.4,4.9-14.5,8.4-20.9
						c0.8-3.7,1.1-8.5-1.8-11.5c-3.5-3.5-8.9-8.9-9.2-9.2c-0.3-0.3-5-5.1-1.4-8.5c4.9-4.9,20.4-20.4,21.2-21.2
						c-0.1,0.1,2.5-1.9,5.5-1.9c1.4,0,2.9,0.5,4.4,1.9c5.7,5.7,8.3,8.3,8.5,8.5c0.1,0.1,3.4,3.9,9.2,4.3c7.5-4.4,15.3-7.5,23.1-9.7
						c3-2.2,5.7-5,5.7-8.6c0-5,0-12.6,0-13c0-0.4,0.1-7.1,5-7c7,0,28.9,0,30,0c0.1,0,7.1,0.9,7,7c0,8,0,11.8,0,12
						c0,0.2-0.3,4.8,3.1,9.2c8.6,2.5,16.6,5.7,23.8,9.8c1.5,0.3,2.1,0.4,2.9,0.3c2.4,0,5-0.5,6.9-2.4c3.5-3.5,8.9-8.9,9.2-9.2
						c0.1-0.1,2.8-2.7,5.6-2.7c0.9,0,1.9,0.3,2.9,1.3c4.9,5,20.4,20.4,21.2,21.2c0.1,0.1,4.4,5.6,0,9.9c-5.7,5.7-8.3,8.3-8.5,8.5
						c-0.1,0.1-3.8,3.3-4.4,9.1c4.2,7.4,7.2,15.1,9.3,22.7c2.2,3.1,5.1,6.2,8.9,6.2C427.5,531.5,435.1,531.5,435.5,531.5z
						 M320.5,633.5c44.7,0,81-36.3,81-81c0-44.7-36.3-81-81-81c-44.7,0-81,36.3-81,81C239.5,597.2,275.8,633.5,320.5,633.5"/>
				</g>
			</g>
		</g>
	</g>
</g>
<g class="${classes.rotatingCog}">
	<path class="st442" d="M631.8,248l-0.4-0.2c-0.6-0.3-3.5-2-6.1-5c-4-4.8-5-10.9-2.7-16.6l4.6-11.6l0,0c0.1-0.4,0.5-1.5,0.2-2.3
		c-4-3.3-7.8-6.9-11.3-10.7l-0.6-0.5c-0.4-0.4-2-1.5-3.5-2.1c-0.2-0.1-0.3-0.1-0.4-0.1l-11.2,4.8c-1.2,0.5-7.6,3.1-13.8,0.6
		c-3.5-1.4-6.2-4-7.7-7.6l-12.1-27.9l-0.1-0.4c-0.2-0.6-1.1-3.9-0.7-7.9c0.6-6.2,4.2-11.2,9.9-13.7l11.4-4.9l0,0
		c0.4-0.2,1.1-0.6,1.6-1.2c-0.6-5.8-0.8-11.8-0.4-17.6c-0.3-1.5-0.7-2.7-1-3.3l-11.3-4.5c-7.9-3.1-13.9-11-10.3-20.2L577,66.8
		l0.2-0.4c0.3-0.6,2-3.5,5-6.1c4.8-4,10.9-5,16.6-2.7l11.6,4.6l0,0c0.3,0.1,0.8,0.2,1.2,0.3c4.2-5.2,8.8-10.1,13.9-14.4
		c0.3-0.6,0.5-1.1,0.6-1.4l-4.8-11.2c-0.2-0.6-1.5-3.6-1.6-7.4c-0.2-6.4,3-11.8,8.6-14.2l27.9-12.1l0.4-0.1
		c1.1-0.3,6.8-1.9,12.6,0.4c4,1.6,7.1,4.6,8.9,8.7l4.9,11.4l0,0c0.1,0.2,0.2,0.3,0.3,0.5c7.1-0.8,14.4-0.9,21.5-0.3
		c0.1,0,0.2-0.1,0.3-0.1l4.5-11.3c3.1-7.9,11-13.9,20.2-10.3L758.2,12l0.4,0.2c0.6,0.3,3.5,2,6.1,5c4,4.8,5,10.9,2.7,16.6l-4.6,11.6
		l0,0c0,0.1-0.1,0.2-0.1,0.3c5.7,4.6,10.9,9.7,15.6,15.4c0,0,0,0,0,0l11.2-4.8c1.2-0.5,7.6-3.1,13.8-0.6c3.5,1.4,6.2,4,7.7,7.6
		l12.1,27.9l0.1,0.4c0.2,0.6,1.1,3.9,0.7,7.9c-0.6,6.2-4.2,11.2-9.9,13.7l-11.4,4.9l0,0c-0.1,0.1-0.3,0.2-0.5,0.3
		c0.7,7,0.8,14.1,0.1,21.2c0.1,0.3,0.2,0.5,0.3,0.6l11.3,4.5c7.9,3.1,13.9,11,10.3,20.2L813,193.2l-0.2,0.4c-0.3,0.6-2,3.5-5,6.1
		c-4.8,4-10.9,5-16.6,2.7l-11.6-4.6l0,0c-0.3-0.1-0.8-0.2-1.2-0.3c-4.1,5-8.6,9.6-13.6,13.7c-0.6,0.9-0.8,1.6-1,2l4.8,11.2
		c0.2,0.6,1.5,3.6,1.6,7.4c0.2,6.4-3,11.8-8.6,14.2l-27.9,12.1l-0.4,0.1c-1.1,0.3-6.8,1.9-12.6-0.4c-4-1.6-7.1-4.6-8.9-8.7l-5-11.4
		l0,0c-0.2-0.5-0.7-1.2-1.2-1.5c-5.6,0.5-11.3,0.6-16.9,0.3c-1.4,0.2-3.1,0.7-4,1.1l-4.5,11.3c-3.1,7.9-11,13.9-20.2,10.3L631.8,248
		z M721.6,65.3c-36.4-14.4-77.7,3.6-92.1,40s3.6,77.7,40,92.1s77.7-3.6,92.1-40S758,79.6,721.6,65.3z"/>
	<g>
		<g>
			<g class="st1">
				<g class="st1">
					<defs>
						<path id="SVGID_00000114780749919520302560000017962262663744858775_" class="st1" d="M616.7,187.8c0.6,1,1.2,2.1,2.5,4.3
							c-1.3-2.2-3-5.1-4.3-7.2C615.7,186.1,616.3,187.1,616.7,187.8z"/>
					</defs>
					<clipPath id="SVGID_00000149351475164055284090000003873689345782624428_">
						<use xlink:href="#SVGID_00000114780749919520302560000017962262663744858775_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000149351475164055284090000003873689345782624428_);enable-background:new    ;">
						<path class="st444" d="M619.1,192.1l-4.3-7.2c0,0,0,0,0,0L619.1,192.1C619.1,192.1,619.1,192.1,619.1,192.1"/>
						<path class="st445" d="M619.1,192.1l-4.3-7.2c0,0,0,0,0,0L619.1,192.1C619.1,192.1,619.1,192.1,619.1,192.1"/>
						<path class="st446" d="M619.1,192.1l-4.2-7.2c0,0,0,0,0,0L619.1,192.1C619.2,192.1,619.1,192.1,619.1,192.1"/>
						<path class="st447" d="M619.2,192.1l-4.2-7.2c0,0,0,0,0,0L619.2,192.1C619.2,192.1,619.2,192.1,619.2,192.1"/>
						<path class="st448" d="M619.2,192.1l-4.2-7.2c0,0,0,0,0,0L619.2,192.1C619.2,192.1,619.2,192.1,619.2,192.1"/>
						<path class="st449" d="M619.2,192.1L615,185c0,0,0,0,0,0L619.2,192.1C619.2,192.1,619.2,192.1,619.2,192.1"/>
						<path class="st450" d="M619.2,192.1L615,185c0,0,0,0,0,0L619.2,192.1C619.2,192.1,619.2,192.1,619.2,192.1"/>
						<path class="st451" d="M619.2,192.1L615,185c0,0,0,0,0,0L619.2,192.1C619.2,192.1,619.2,192.1,619.2,192.1"/>
						<path class="st452" d="M619.2,192.1l-4.1-7.1c0,0,0,0,0,0L619.2,192.1C619.2,192.1,619.2,192.1,619.2,192.1"/>
						<path class="st453" d="M619.2,192.1l-4.1-7c0,0,0,0,0,0L619.2,192.1C619.2,192.2,619.2,192.1,619.2,192.1"/>
						<path class="st454" d="M619.2,192.2l-4.1-7c0,0,0,0,0,0L619.2,192.2C619.2,192.2,619.2,192.2,619.2,192.2"/>
						<path class="st455" d="M619.2,192.2l-4.1-7c0,0,0,0,0,0L619.2,192.2C619.2,192.2,619.2,192.2,619.2,192.2"/>
						<path class="st456" d="M619.2,192.2l-4.1-7c0,0,0,0,0,0L619.2,192.2C619.2,192.2,619.2,192.2,619.2,192.2"/>
						<path class="st457" d="M619.2,192.2l-4.1-6.9c0,0,0,0,0,0L619.2,192.2C619.2,192.2,619.2,192.2,619.2,192.2"/>
						<path class="st458" d="M619.2,192.2l-4.1-6.9c0,0,0,0,0,0L619.2,192.2C619.2,192.2,619.2,192.2,619.2,192.2"/>
						<path class="st459" d="M619.2,192.2l-4-6.9c0,0,0,0,0,0L619.2,192.2C619.2,192.2,619.2,192.2,619.2,192.2"/>
						<path class="st460" d="M619.2,192.2l-4-6.9c0,0,0,0,0,0L619.2,192.2C619.2,192.2,619.2,192.2,619.2,192.2"/>
						<path class="st461" d="M619.2,192.2l-4-6.8c0,0,0,0,0,0L619.2,192.2C619.2,192.2,619.2,192.2,619.2,192.2"/>
						<path class="st462" d="M619.2,192.2l-4-6.8c0,0,0,0,0,0L619.2,192.2C619.2,192.2,619.2,192.2,619.2,192.2"/>
						<path class="st463" d="M619.2,192.2l-4-6.8c0,0,0,0,0,0L619.2,192.2C619.2,192.2,619.2,192.2,619.2,192.2"/>
						<path class="st464" d="M619.2,192.2l-4-6.8c0,0,0,0,0,0L619.2,192.2C619.2,192.3,619.2,192.3,619.2,192.2"/>
						<path class="st465" d="M619.2,192.3l-3.9-6.7c0,0,0,0,0,0L619.2,192.3C619.3,192.3,619.3,192.3,619.2,192.3"/>
						<path class="st466" d="M619.3,192.3l-3.9-6.7c0,0,0,0,0,0L619.3,192.3C619.3,192.3,619.3,192.3,619.3,192.3"/>
						<path class="st467" d="M619.3,192.3l-3.9-6.7c0,0,0,0,0,0L619.3,192.3C619.3,192.3,619.3,192.3,619.3,192.3"/>
						<path class="st468" d="M619.3,192.3l-3.9-6.7c0,0,0,0,0,0L619.3,192.3C619.3,192.3,619.3,192.3,619.3,192.3"/>
						<path class="st469" d="M619.3,192.3l-3.9-6.6c0,0,0,0,0,0L619.3,192.3C619.3,192.3,619.3,192.3,619.3,192.3"/>
						<path class="st470" d="M619.3,192.3l-3.9-6.6c0,0,0,0,0,0L619.3,192.3C619.3,192.3,619.3,192.3,619.3,192.3"/>
						<path class="st471" d="M619.3,192.3l-3.8-6.6c0,0,0,0,0,0L619.3,192.3C619.3,192.3,619.3,192.3,619.3,192.3"/>
						<path class="st472" d="M619.3,192.3l-3.8-6.6c0,0,0,0,0,0L619.3,192.3C619.3,192.3,619.3,192.3,619.3,192.3"/>
						<path class="st473" d="M619.3,192.3l-3.8-6.6c0,0,0,0,0,0L619.3,192.3C619.3,192.3,619.3,192.3,619.3,192.3"/>
						<path class="st474" d="M619.3,192.3l-3.8-6.5c0,0,0,0,0,0L619.3,192.3C619.3,192.3,619.3,192.3,619.3,192.3"/>
						<path class="st475" d="M619.3,192.3l-3.8-6.5c0.5,0.8,0.9,1.4,1.1,1.9l2.7,4.9C619.4,192.7,619.4,192.5,619.3,192.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000112627213022174093040000004688729822241386158_" class="st1" d="M576.2,165.2l-1.8,0.8
							c-0.1-0.4-2.7-8.6,4.7-11.8l0.8,1.8C574.2,158.4,576.2,165.1,576.2,165.2z"/>
					</defs>
					<clipPath id="SVGID_00000090284704606739988650000015194860770439123108_">
						<use xlink:href="#SVGID_00000112627213022174093040000004688729822241386158_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000090284704606739988650000015194860770439123108_);enable-background:new    ;">
						<path class="st456" d="M576.2,165.2l-1.8,0.8c-0.1-0.2-0.7-2.4-0.5-4.9l2,0.3C575.7,163.4,576.2,165.1,576.2,165.2"/>
						<path class="st455" d="M575.9,161.5l-2-0.3c0.1-0.8,0.3-1.7,0.6-2.6l1.8,0.9C576.1,160.1,576,160.8,575.9,161.5"/>
						<path class="st454" d="M576.4,159.4l-1.8-0.9c0.2-0.5,0.5-1.1,0.9-1.6l1.6,1.2C576.8,158.6,576.5,159,576.4,159.4"/>
						<path class="st453" d="M577,158.2l-1.6-1.2c0.3-0.4,0.6-0.8,1-1.1l1.4,1.4C577.5,157.6,577.3,157.9,577,158.2"/>
						<path class="st452" d="M577.8,157.3l-1.4-1.4c0.3-0.3,0.7-0.6,1.2-0.9l1.1,1.6C578.4,156.8,578.1,157.1,577.8,157.3"/>
						<path class="st451" d="M578.7,156.6l-1.1-1.6c0.4-0.3,0.9-0.5,1.4-0.8l0.8,1.8C579.4,156.2,579,156.4,578.7,156.6"/>
						<path class="st450" d="M579.8,156l-0.8-1.8c0,0,0.1,0,0.1,0L579.8,156C579.8,156,579.8,156,579.8,156"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000176758875000873353120000007359972300350948275_" class="st1" d="M575.1,98.8l-1.9-0.7
							c2.6-6.5,10.6-26.8,11-27.9l1.9,0.7C585.7,72,577.7,92.3,575.1,98.8z"/>
					</defs>
					<clipPath id="SVGID_00000047740409844238629170000016817567485340827297_">
						<use xlink:href="#SVGID_00000176758875000873353120000007359972300350948275_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000047740409844238629170000016817567485340827297_);enable-background:new    ;">
						<path class="st455" d="M575.1,98.8l-1.9-0.7c2.6-6.5,10.6-26.8,11-27.9l1.9,0.7C585.7,72,577.7,92.3,575.1,98.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000044874308806710527470000003002995453540731046_" class="st1" d="M592.7,64.4L592.7,64.4
							c1,0,2.1,0.2,3.2,0.6l0,0C594.8,64.6,593.7,64.4,592.7,64.4z"/>
					</defs>
					<clipPath id="SVGID_00000049929682619895968050000003766111734860578985_">
						<use xlink:href="#SVGID_00000044874308806710527470000003002995453540731046_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000049929682619895968050000003766111734860578985_);enable-background:new    ;">
						<path class="st473" d="M592.7,64.4L592.7,64.4c0.1,0,0.2,0,0.3,0h0C592.9,64.4,592.8,64.4,592.7,64.4"/>
						<path class="st472" d="M593,64.4L593,64.4c0.4,0,0.8,0.1,1.2,0.1h0C593.7,64.5,593.3,64.5,593,64.4"/>
						<path class="st471" d="M594.1,64.6L594.1,64.6c0.4,0.1,0.9,0.2,1.4,0.4h0C595,64.8,594.6,64.6,594.1,64.6"/>
						<path class="st470" d="M595.5,64.9L595.5,64.9c0.2,0,0.3,0.1,0.4,0.2h0C595.8,65,595.6,65,595.5,64.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000142860792649034504790000016114501197794092943_" class="st1" d="M588.1,192.7l-1.8,0.8
							c-2.8-6.4-11.5-26.5-11.9-27.5l1.8-0.8C576.7,166.3,585.4,186.3,588.1,192.7z"/>
					</defs>
					<clipPath id="SVGID_00000178911815986828026790000016244978645900670878_">
						<use xlink:href="#SVGID_00000142860792649034504790000016114501197794092943_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000178911815986828026790000016244978645900670878_);enable-background:new    ;">
						<path class="st457" d="M588.1,192.7l-1.8,0.8c-2.8-6.4-11.5-26.5-11.9-27.5l1.8-0.8C576.7,166.3,585.4,186.3,588.1,192.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000115473535709075685200000012216318498341567619_" class="st1" d="M597.4,196.4L597.4,196.4
							c-0.1,0-2.3,1-4.9,1h0C595.1,197.4,597.2,196.4,597.4,196.4z"/>
					</defs>
					<clipPath id="SVGID_00000091723903781502472820000001417488597455409853_">
						<use xlink:href="#SVGID_00000115473535709075685200000012216318498341567619_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000091723903781502472820000001417488597455409853_);enable-background:new    ;">
						<path class="st475" d="M597.4,196.4L597.4,196.4c-0.1,0-2.3,1-4.9,1h0C595.1,197.4,597.2,196.4,597.4,196.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000049911231341879042280000011644714675018460585_" class="st1" d="M579.8,156l-0.8-1.8
							c7.3-3.2,10.8-4.7,11-4.8l0.8,1.8C590.7,151.3,587.2,152.8,579.8,156z"/>
					</defs>
					<clipPath id="SVGID_00000020367210854668023620000009946902208663177908_">
						<use xlink:href="#SVGID_00000049911231341879042280000011644714675018460585_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000020367210854668023620000009946902208663177908_);enable-background:new    ;">
						<path class="st450" d="M579.8,156l-0.8-1.8c7.3-3.2,10.8-4.7,11-4.8l0.8,1.8C590.7,151.3,587.2,152.8,579.8,156"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000052084697548313233530000015989388794569260463_" class="st1" d="M591.9,110.8l-0.7,1.9
							c-4.7-1.8-11.7-4.6-12.1-4.8c-0.4-0.2-8.4-3.3-5.8-9.8l1.9,0.7c-1.9,4.5,4.3,7.1,4.7,7.2C580.1,106.2,587.2,109,591.9,110.8z"
							/>
					</defs>
					<clipPath id="SVGID_00000101078935713837938790000015643958561944848787_">
						<use xlink:href="#SVGID_00000052084697548313233530000015989388794569260463_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000101078935713837938790000015643958561944848787_);enable-background:new    ;">
						<path class="st456" d="M591.9,110.8l-0.7,1.9c-4.7-1.8-11.7-4.6-12.1-4.8c-0.2-0.1-1.9-0.7-3.5-2l1.5-1.4
							c1.3,1,2.6,1.5,2.7,1.6C580.1,106.2,587.2,109,591.9,110.8"/>
						<path class="st457" d="M577,104.4l-1.5,1.4c-0.8-0.7-1.6-1.5-2.1-2.5l1.9-0.8C575.8,103.3,576.4,103.9,577,104.4"/>
						<path class="st456" d="M575.3,102.6l-1.9,0.8c-0.5-1-0.8-2.1-0.7-3.5l2,0.2C574.7,101,574.9,101.8,575.3,102.6"/>
						<path class="st455" d="M574.8,100.1l-2-0.2c0.1-0.6,0.2-1.2,0.5-1.8l1.9,0.7C574.9,99.2,574.8,99.7,574.8,100.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000072250835375659406060000002147212050115809162_" class="st1" d="M586.1,70.9l-1.9-0.7
							c0.2-0.3,3-5.7,8.4-5.7c1,0,2.1,0.2,3.2,0.6l-0.7,1.9c-0.9-0.4-1.7-0.5-2.5-0.5C588.5,66.4,586.1,70.9,586.1,70.9z"/>
					</defs>
					<clipPath id="SVGID_00000157296712385110376730000012236160636096514450_">
						<use xlink:href="#SVGID_00000072250835375659406060000002147212050115809162_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000157296712385110376730000012236160636096514450_);enable-background:new    ;">
						<path class="st454" d="M586.1,70.9l-1.9-0.7c0-0.1,0.3-0.6,0.8-1.3l1.6,1.1C586.3,70.5,586.1,70.9,586.1,70.9"/>
						<path class="st453" d="M586.7,70l-1.6-1.1c0.4-0.6,1-1.3,1.7-1.9l1.3,1.5C587.5,69,587,69.5,586.7,70"/>
						<path class="st452" d="M588,68.5l-1.3-1.5c0.4-0.4,0.9-0.8,1.5-1.1l1,1.7C588.8,67.8,588.4,68.1,588,68.5"/>
						<path class="st451" d="M589.2,67.5l-1-1.7c0.4-0.3,0.8-0.5,1.3-0.7l0.7,1.9C589.9,67.1,589.5,67.3,589.2,67.5"/>
						<path class="st450" d="M590.2,67l-0.7-1.9c0.4-0.2,0.8-0.3,1.2-0.4l0.5,1.9C590.8,66.7,590.5,66.8,590.2,67"/>
						<path class="st449" d="M591.2,66.6l-0.5-1.9c0.4-0.1,0.8-0.2,1.2-0.2l0.2,2C591.8,66.5,591.5,66.6,591.2,66.6"/>
						<path class="st448" d="M592.1,66.5l-0.2-2c0.3,0,0.5,0,0.8,0c0.2,0,0.3,0,0.5,0l-0.1,2c-0.1,0-0.3,0-0.4,0
							C592.5,66.4,592.3,66.4,592.1,66.5"/>
						<path class="st447" d="M593.1,66.5l0.1-2c0.5,0,1,0.1,1.5,0.2l-0.5,1.9C593.8,66.5,593.4,66.5,593.1,66.5"/>
						<path class="st446" d="M594.2,66.6l0.5-1.9c0.4,0.1,0.8,0.2,1.2,0.4l-0.7,1.9C594.8,66.8,594.5,66.7,594.2,66.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000101803827616615051290000016943938701372723108_" class="st1" d="M595.9,65.1L595.9,65.1
							c7.5,2.9,11,4.3,11.2,4.4l0,0C606.9,69.4,603.4,68,595.9,65.1z"/>
					</defs>
					<clipPath id="SVGID_00000173868148415083466670000001837467028119878835_">
						<use xlink:href="#SVGID_00000101803827616615051290000016943938701372723108_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000173868148415083466670000001837467028119878835_);enable-background:new    ;">
						<path class="st470" d="M595.9,65.1L595.9,65.1c7.5,2.9,11,4.3,11.2,4.4h0C606.9,69.4,603.4,68,595.9,65.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000111892484148020689780000002884933013862578564_" class="st1" d="M598.5,122.8l-2-0.1
							c-0.3-2.1-1.5-8.5-5.3-10l0.7-1.9C596.9,112.9,598.1,120.1,598.5,122.8z"/>
					</defs>
					<clipPath id="SVGID_00000039093052282476879380000012199898591710381213_">
						<use xlink:href="#SVGID_00000111892484148020689780000002884933013862578564_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000039093052282476879380000012199898591710381213_);enable-background:new    ;">
						<path class="st456" d="M598.5,122.8l-2-0.1c-0.1-1-0.5-3-1.2-5l1.8-0.8C597.9,119.2,598.3,121.5,598.5,122.8"/>
						<path class="st457" d="M597,116.8l-1.8,0.8c-0.7-1.8-1.7-3.5-3.1-4.5l1-1.7C595,112.7,596.2,114.8,597,116.8"/>
						<path class="st456" d="M593.1,111.5l-1,1.7c-0.3-0.2-0.6-0.4-1-0.5l0.7-1.9C592.3,111,592.7,111.2,593.1,111.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000129167896335687249600000011271819205033409936_" class="st1" d="M590.8,151.2l-0.8-1.8
							c0.3-0.1,5.9-2.1,6.9-7.9l2,0.4C597.7,148.8,591.1,151.1,590.8,151.2z"/>
					</defs>
					<clipPath id="SVGID_00000015329826704182496870000002130675472160205699_">
						<use xlink:href="#SVGID_00000129167896335687249600000011271819205033409936_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000015329826704182496870000002130675472160205699_);enable-background:new    ;">
						<path class="st450" d="M590.8,151.2l-0.8-1.8c0,0,0.2-0.1,0.5-0.2l0.8,1.8C591.1,151.1,590.9,151.2,590.8,151.2"/>
						<path class="st451" d="M591.4,151l-0.8-1.8c0.4-0.2,1.1-0.5,1.8-1l1.1,1.7C592.6,150.4,591.9,150.8,591.4,151"/>
						<path class="st452" d="M593.5,149.9l-1.1-1.7c0.5-0.3,1.1-0.8,1.7-1.3l1.4,1.4C594.7,149,594.1,149.5,593.5,149.9"/>
						<path class="st453" d="M595.4,148.4l-1.4-1.4c0.5-0.4,0.9-1,1.4-1.5l1.7,1.1C596.5,147.2,596,147.8,595.4,148.4"/>
						<path class="st454" d="M597,146.5l-1.7-1.1c0.4-0.6,0.8-1.2,1.1-1.9l1.9,0.8C597.9,145.1,597.5,145.8,597,146.5"/>
						<path class="st455" d="M598.3,144.2l-1.9-0.8c0.3-0.6,0.4-1.3,0.6-2l2,0.4C598.8,142.7,598.6,143.5,598.3,144.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000007417481867400783670000015846556274904030370_" class="st1" d="M599,141.9l-2-0.4
							c-0.8-6.2-1-12.5-0.5-18.8l2,0.1C598.2,128.9,598.2,135.2,599,141.9z"/>
					</defs>
					<clipPath id="SVGID_00000118359677939406395270000009470239858812024473_">
						<use xlink:href="#SVGID_00000007417481867400783670000015846556274904030370_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000118359677939406395270000009470239858812024473_);enable-background:new    ;">
						<path class="st456" d="M599,141.9l-2-0.4c-0.8-6.2-1-12.5-0.5-18.8l2,0.1C598.2,128.9,598.2,135.2,599,141.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000011731515768175659380000012567402147794714536_" class="st1" d="M609.3,191.2L609.3,191.2
							c-4.6,2-11.5,5-11.9,5.2h0C597.7,196.2,604.7,193.2,609.3,191.2z"/>
					</defs>
					<clipPath id="SVGID_00000103237251912924498780000007010390880187394487_">
						<use xlink:href="#SVGID_00000011731515768175659380000012567402147794714536_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000103237251912924498780000007010390880187394487_);enable-background:new    ;">
						<path class="st475" d="M609.3,191.2L609.3,191.2c-4.6,2-11.5,5-11.9,5.2h0C597.7,196.2,604.7,193.2,609.3,191.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000001650636232556744090000017643572698959120828_" class="st1" d="M611.4,190.8L611.4,190.8
							c-0.7,0-1.5,0.1-2.1,0.4h0C610,190.9,610.7,190.8,611.4,190.8z"/>
					</defs>
					<clipPath id="SVGID_00000012450238957240095520000015141605368628275861_">
						<use xlink:href="#SVGID_00000001650636232556744090000017643572698959120828_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000012450238957240095520000015141605368628275861_);enable-background:new    ;">
						<path class="st475" d="M611.4,190.8L611.4,190.8c-0.7,0-1.5,0.1-2.1,0.4h0C610,190.9,610.7,190.8,611.4,190.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000183931558822598953450000017401475432847194524_" class="st1" d="M607.1,69.5L607.1,69.5
							c0.1,0.1,2.2,1,5,1l0,0C609.3,70.5,607.2,69.5,607.1,69.5z"/>
					</defs>
					<clipPath id="SVGID_00000170245601315571233370000012452175546060420496_">
						<use xlink:href="#SVGID_00000183931558822598953450000017401475432847194524_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000170245601315571233370000012452175546060420496_);enable-background:new    ;">
						<path class="st470" d="M607.1,69.5L607.1,69.5c0.1,0,0.3,0.2,0.8,0.3h0C607.4,69.7,607.1,69.5,607.1,69.5"/>
						<path class="st471" d="M607.9,69.8L607.9,69.8c0.5,0.2,1.1,0.3,1.9,0.5h0C609,70.2,608.4,70,607.9,69.8"/>
						<path class="st472" d="M609.8,70.3L609.8,70.3c0.6,0.1,1.2,0.2,1.8,0.2h0C611,70.5,610.3,70.4,609.8,70.3"/>
						<path class="st473" d="M611.6,70.5L611.6,70.5c0.1,0,0.3,0,0.4,0h0C611.9,70.5,611.8,70.5,611.6,70.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000074422415360911144790000012798673024821592722_" class="st1" d="M595.2,66.9l0.7-1.9
							c7.4,2.9,11,4.3,11.2,4.4l-0.7,1.9C606.2,71.3,602.6,69.9,595.2,66.9z"/>
					</defs>
					<clipPath id="SVGID_00000109748043496739652760000004392578301431057066_">
						<use xlink:href="#SVGID_00000074422415360911144790000012798673024821592722_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000109748043496739652760000004392578301431057066_);enable-background:new    ;">
						<path class="st446" d="M595.2,66.9l0.7-1.9c7.4,2.9,11,4.3,11.2,4.4l-0.7,1.9C606.2,71.3,602.6,69.9,595.2,66.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000019637617231513899540000015498274825650244243_" class="st1" d="M659.8,11.2c-0.2-0.4-0.4-0.9-0.5-1.3
							L659.8,11.2C659.8,11.2,659.8,11.2,659.8,11.2z"/>
					</defs>
					<clipPath id="SVGID_00000001643639912857409270000014458107929807988927_">
						<use xlink:href="#SVGID_00000019637617231513899540000015498274825650244243_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000001643639912857409270000014458107929807988927_);enable-background:new    ;">
						<polyline class="st454" points="659.2,9.9 659.8,11.2 659.8,11.2 659.2,9.9 659.2,9.9 						"/>
						<polyline class="st455" points="659.2,9.9 659.8,11.2 659.8,11.2 659.2,9.9 659.2,9.9 						"/>
						<polyline class="st456" points="659.2,9.9 659.8,11.2 659.8,11.2 659.2,9.9 659.2,9.9 						"/>
						<polyline class="st457" points="659.2,9.9 659.8,11.2 659.8,11.2 659.2,9.9 659.2,9.9 						"/>
						<polyline class="st458" points="659.2,9.9 659.8,11.2 659.8,11.2 659.2,9.9 659.2,9.9 						"/>
						<polyline class="st459" points="659.2,9.9 659.8,11.2 659.8,11.2 659.2,9.9 659.2,9.9 						"/>
						<polyline class="st460" points="659.2,9.9 659.8,11.2 659.8,11.2 659.2,9.9 659.2,9.9 						"/>
						<polyline class="st461" points="659.2,9.9 659.8,11.2 659.8,11.2 659.2,9.9 659.2,9.9 						"/>
						<polyline class="st462" points="659.2,9.9 659.8,11.2 659.8,11.2 659.2,9.9 659.2,9.9 						"/>
						<polyline class="st463" points="659.2,9.9 659.8,11.2 659.8,11.2 659.2,9.9 659.2,9.9 						"/>
						<polyline class="st464" points="659.2,9.9 659.8,11.2 659.8,11.2 659.2,9.9 659.2,9.9 						"/>
						<polyline class="st465" points="659.2,9.9 659.8,11.2 659.8,11.2 659.2,9.9 659.2,9.9 						"/>
						<polyline class="st466" points="659.2,9.9 659.8,11.2 659.8,11.2 659.2,9.9 659.2,9.9 						"/>
						<polyline class="st467" points="659.2,9.9 659.8,11.2 659.8,11.2 659.2,9.9 659.2,9.9 						"/>
						<polyline class="st468" points="659.2,9.9 659.8,11.2 659.8,11.2 659.3,9.9 659.2,9.9 						"/>
						<polyline class="st469" points="659.3,9.9 659.8,11.2 659.8,11.2 659.3,9.9 659.3,9.9 						"/>
						<polyline class="st470" points="659.3,9.9 659.8,11.2 659.8,11.2 659.3,9.9 659.3,9.9 						"/>
						<polyline class="st471" points="659.3,9.9 659.8,11.2 659.8,11.2 659.3,9.9 659.3,9.9 						"/>
						<polyline class="st472" points="659.3,9.9 659.8,11.2 659.8,11.2 659.3,9.9 659.3,9.9 						"/>
						<polyline class="st473" points="659.3,9.9 659.8,11.2 659.8,11.2 659.3,9.9 659.3,9.9 						"/>
						<polyline class="st474" points="659.3,9.9 659.8,11.2 659.8,11.2 659.3,9.9 659.3,9.9 						"/>
						<polyline class="st475" points="659.3,9.9 659.8,11.2 659.8,11.2 659.3,9.9 659.3,9.9 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000093154518303460726580000012534970339781708428_" class="st1" d="M608.5,189.4l0.8,1.8
							c-4.6,2-11.6,5-11.9,5.2c-0.1,0-2.3,1-4.9,1c-2.3,0-4.8-0.8-6.2-3.8l1.8-0.8c0.9,2.1,2.6,2.6,4.3,2.6c2.2,0,4-0.8,4.1-0.8
							C596.9,194.4,603.9,191.3,608.5,189.4z"/>
					</defs>
					<clipPath id="SVGID_00000168813029793527613520000004991481737972013698_">
						<use xlink:href="#SVGID_00000093154518303460726580000012534970339781708428_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000168813029793527613520000004991481737972013698_);enable-background:new    ;">
						<path class="st453" d="M608.5,189.4l0.8,1.8c-4.6,2-11.6,5-11.9,5.2c0,0,0,0,0,0l-0.8-1.8c0,0,0,0,0,0
							C596.9,194.4,603.9,191.3,608.5,189.4"/>
						<path class="st454" d="M596.5,194.5l0.8,1.8c-0.2,0.1-1.3,0.5-2.7,0.8l-0.3-2C595.5,195,596.4,194.6,596.5,194.5"/>
						<path class="st455" d="M594.3,195.2l0.3,2c-0.6,0.1-1.4,0.2-2.1,0.2c-0.3,0-0.7,0-1-0.1l0.3-2c0.3,0,0.5,0,0.8,0
							C593.1,195.4,593.7,195.3,594.3,195.2"/>
						<path class="st456" d="M591.7,195.3l-0.3,2c-1.1-0.1-2.2-0.5-3.2-1.2l1.2-1.6C590.1,195,590.9,195.2,591.7,195.3"/>
						<path class="st457" d="M589.5,194.5l-1.2,1.6c-0.8-0.6-1.5-1.4-2-2.6l1.8-0.8C588.5,193.5,589,194.1,589.5,194.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000002363392440644536450000008679289686724551838_" class="st1" d="M620.5,194.5L620.5,194.5
							C620.5,194.5,620.5,194.5,620.5,194.5L620.5,194.5C620.6,194.5,620.5,194.5,620.5,194.5z"/>
					</defs>
					<clipPath id="SVGID_00000179636577711206032150000011723116134672936339_">
						<use xlink:href="#SVGID_00000002363392440644536450000008679289686724551838_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000179636577711206032150000011723116134672936339_);enable-background:new    ;">
						<polyline class="st469" points="620.5,194.4 620.5,194.4 620.5,194.4 620.5,194.4 620.5,194.4 						"/>
						<path class="st470" d="M620.5,194.4L620.5,194.4C620.5,194.4,620.5,194.4,620.5,194.4L620.5,194.4
							C620.5,194.4,620.5,194.4,620.5,194.4"/>
						<polyline class="st471" points="620.5,194.4 620.5,194.4 620.5,194.5 620.5,194.5 620.5,194.4 						"/>
						<polyline class="st472" points="620.5,194.5 620.5,194.5 620.5,194.5 620.5,194.5 620.5,194.5 						"/>
						<polyline class="st473" points="620.5,194.5 620.5,194.5 620.5,194.5 620.5,194.5 620.5,194.5 						"/>
						<polyline class="st474" points="620.5,194.5 620.5,194.5 620.5,194.5 620.5,194.5 620.5,194.5 						"/>
						<path class="st475" d="M620.5,194.5L620.5,194.5C620.5,194.5,620.5,194.5,620.5,194.5L620.5,194.5
							C620.5,194.5,620.5,194.5,620.5,194.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000148628570684184394450000007397056426973822640_" class="st1" d="M606.3,71.4l0.7-1.9
							c0.1,0.1,2.2,1,4.9,1c1.3,0,2.8-0.2,4.2-0.9l1.3,1.6c-2.2,1.1-4,1.3-5.6,1.3C608.8,72.5,606.4,71.4,606.3,71.4z"/>
					</defs>
					<clipPath id="SVGID_00000120519730527820145430000003743405475567521205_">
						<use xlink:href="#SVGID_00000148628570684184394450000007397056426973822640_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000120519730527820145430000003743405475567521205_);enable-background:new    ;">
						<path class="st446" d="M606.3,71.4l0.7-1.9c0.1,0,0.8,0.4,1.9,0.6l-0.5,1.9C607.2,71.7,606.4,71.4,606.3,71.4"/>
						<path class="st447" d="M608.5,72.1l0.5-1.9c0.6,0.2,1.4,0.3,2.3,0.4l-0.1,2C610.1,72.4,609.2,72.3,608.5,72.1"/>
						<path class="st448" d="M611.1,72.5l0.1-2c0.3,0,0.5,0,0.8,0c0.3,0,0.7,0,1,0l0.2,2c-0.4,0-0.8,0.1-1.2,0.1
							C611.7,72.5,611.4,72.5,611.1,72.5"/>
						<path class="st449" d="M613.3,72.5l-0.2-2c0.5,0,1-0.1,1.5-0.3l0.6,1.9C614.5,72.3,613.9,72.4,613.3,72.5"/>
						<path class="st450" d="M615.2,72.1l-0.6-1.9c0.5-0.1,0.9-0.3,1.4-0.5l1.2,1.7C616.4,71.7,615.8,71.9,615.2,72.1"/>
						<path class="st451" d="M617.1,71.4l-1.2-1.7c0.1,0,0.2-0.1,0.3-0.1l1.3,1.6C617.4,71.3,617.3,71.3,617.1,71.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000046310692107702337500000014819596820614394551_" class="st1" d="M616.6,187.8c0.6,1,0.6,1,3.9,6.7
							c0.2,0.5-4.8-3.7-9.1-3.7c-0.7,0-1.5,0.1-2.1,0.4l-0.8-1.8c1-0.4,2-0.6,2.9-0.6C615.7,188.8,618.5,191.2,616.6,187.8z"/>
					</defs>
					<clipPath id="SVGID_00000149364961095048792910000005334701608760290211_">
						<use xlink:href="#SVGID_00000046310692107702337500000014819596820614394551_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000149364961095048792910000005334701608760290211_);enable-background:new    ;">
						<path class="st475" d="M614.8,184.8l5.7,9.6c0,0,0,0,0,0L614.8,184.8C614.9,184.9,614.9,184.8,614.8,184.8"/>
						<path class="st474" d="M614.9,184.9l5.6,9.6c0,0,0,0,0,0L614.9,184.9C614.9,184.9,614.9,184.9,614.9,184.9"/>
						<path class="st473" d="M614.9,184.9l5.6,9.5c0,0,0,0,0,0L614.9,184.9C614.9,185,614.9,184.9,614.9,184.9"/>
						<path class="st472" d="M615,185l5.6,9.5c0,0,0,0,0,0L615,185C615,185,615,185,615,185"/>
						<path class="st471" d="M615,185l5.5,9.4c0,0,0,0,0,0L615,185C615,185.1,615,185,615,185"/>
						<path class="st470" d="M615,185.1l5.5,9.4c0,0,0,0,0,0L615,185.1C615,185.1,615,185.1,615,185.1"/>
						<path class="st469" d="M615.1,185.1l5.5,9.3c0,0,0,0,0,0L615.1,185.1C615.1,185.2,615.1,185.2,615.1,185.1"/>
						<path class="st468" d="M615.1,185.2l5.4,9.3c0,0,0,0,0,0L615.1,185.2C615.1,185.2,615.1,185.2,615.1,185.2"/>
						<path class="st467" d="M615.1,185.2l5.4,9.2c0,0,0,0,0,0L615.1,185.2C615.1,185.3,615.1,185.3,615.1,185.2"/>
						<path class="st466" d="M615.2,185.3l5.4,9.2c0,0,0,0,0,0L615.2,185.3C615.2,185.3,615.2,185.3,615.2,185.3"/>
						<path class="st465" d="M615.2,185.3l5.3,9.1c0,0,0,0,0,0L615.2,185.3C615.2,185.4,615.2,185.4,615.2,185.3"/>
						<path class="st464" d="M615.2,185.4l5.3,9.1c0,0,0,0,0,0L615.2,185.4C615.2,185.4,615.2,185.4,615.2,185.4"/>
						<path class="st463" d="M615.2,185.4l5.3,9c0,0,0,0,0,0L615.2,185.4C615.3,185.5,615.3,185.5,615.2,185.4"/>
						<path class="st462" d="M615.3,185.5l5.3,9c0,0,0,0,0,0L615.3,185.5C615.3,185.5,615.3,185.5,615.3,185.5"/>
						<path class="st461" d="M615.3,185.5l5.2,8.9c0,0,0,0,0,0L615.3,185.5C615.3,185.6,615.3,185.6,615.3,185.5"/>
						<path class="st460" d="M615.3,185.6l5.2,8.9c0,0,0,0,0,0L615.3,185.6C615.4,185.6,615.4,185.6,615.3,185.6"/>
						<path class="st459" d="M615.4,185.6l5.2,8.8c0,0,0,0,0,0L615.4,185.6C615.4,185.7,615.4,185.7,615.4,185.6"/>
						<path class="st458" d="M615.4,185.7l5.1,8.8c0,0,0,0,0,0L615.4,185.7C615.4,185.7,615.4,185.7,615.4,185.7"/>
						<path class="st457" d="M615.4,185.7l5.1,8.7c0,0,0,0,0,0L615.4,185.7C615.5,185.8,615.4,185.8,615.4,185.7"/>
						<path class="st456" d="M615.5,185.8l5.1,8.7c0,0,0,0,0,0L615.5,185.8C615.5,185.8,615.5,185.8,615.5,185.8"/>
						<path class="st455" d="M615.5,185.8l5,8.6c0,0,0,0,0,0L615.5,185.8C615.5,185.9,615.5,185.8,615.5,185.8"/>
						<path class="st454" d="M615.5,185.9l5,8.6c0,0,0,0,0,0L615.5,185.9C615.5,185.9,615.5,185.9,615.5,185.9"/>
						<path class="st453" d="M615.5,185.9l5,8.6c0,0,0,0,0,0L615.5,185.9C615.6,185.9,615.6,185.9,615.5,185.9"/>
						<path class="st452" d="M615.6,186l4.9,8.5c0,0,0,0,0,0L615.6,186C615.6,186,615.6,186,615.6,186"/>
						<path class="st451" d="M615.6,186l4.9,8.5c0,0,0,0,0,0L615.6,186C615.6,186,615.6,186,615.6,186"/>
						<path class="st450" d="M615.6,186l4.9,8.4c0,0,0,0,0,0L615.6,186C615.6,186.1,615.6,186,615.6,186"/>
						<path class="st449" d="M615.6,186.1l4.9,8.4c0,0,0,0,0,0L615.6,186.1C615.7,186.1,615.6,186.1,615.6,186.1"/>
						<path class="st448" d="M615.7,186.1l4.8,8.4c0,0,0,0,0,0L615.7,186.1C615.7,186.1,615.7,186.1,615.7,186.1"/>
						<path class="st447" d="M615.7,186.2l4.8,8.3c0,0,0,0,0,0L615.7,186.2C615.7,186.2,615.7,186.2,615.7,186.2"/>
						<path class="st446" d="M615.7,186.2l4.8,8.3c0,0,0,0,0,0L615.7,186.2C615.7,186.2,615.7,186.2,615.7,186.2"/>
						<path class="st445" d="M615.7,186.2l4.8,8.2c0,0,0,0,0,0L615.7,186.2C615.8,186.3,615.7,186.2,615.7,186.2"/>
						<path class="st444" d="M615.8,186.3l4.7,8.2c0,0,0,0,0,0L615.8,186.3C615.8,186.3,615.8,186.3,615.8,186.3"/>
						<path class="st497" d="M615.8,186.3l4.7,8.2c0,0,0,0,0,0L615.8,186.3C615.8,186.3,615.8,186.3,615.8,186.3"/>
						<path class="st498" d="M615.8,186.3l4.7,8.1c0,0,0,0,0,0L615.8,186.3C615.8,186.4,615.8,186.4,615.8,186.3"/>
						<path class="st499" d="M615.8,186.4l4.7,8.1c0,0,0,0,0,0L615.8,186.4C615.8,186.4,615.8,186.4,615.8,186.4"/>
						<path class="st500" d="M615.9,186.4l4.6,8.1c0,0,0,0,0,0L615.9,186.4C615.9,186.4,615.9,186.4,615.9,186.4"/>
						<path class="st501" d="M615.9,186.5l4.6,8c0,0,0,0,0,0L615.9,186.5C615.9,186.5,615.9,186.5,615.9,186.5"/>
						<path class="st502" d="M615.9,186.5l4.6,8c0,0,0,0,0,0L615.9,186.5C615.9,186.5,615.9,186.5,615.9,186.5"/>
						<path class="st503" d="M615.9,186.5l4.5,7.9c0,0,0,0,0,0L615.9,186.5C615.9,186.6,615.9,186.5,615.9,186.5"/>
						<path class="st504" d="M615.9,186.6l4.5,7.9c0,0,0,0,0,0L615.9,186.6C616,186.6,615.9,186.6,615.9,186.6"/>
						<path class="st505" d="M616,186.6l4.5,7.9c0,0,0,0,0,0L616,186.6C616,186.6,616,186.6,616,186.6"/>
						<path class="st506" d="M616,186.6l4.5,7.8c0,0,0,0,0,0L616,186.6C616,186.7,616,186.6,616,186.6"/>
						<path class="st507" d="M616,186.7l4.4,7.8c0,0,0,0,0,0L616,186.7C616,186.7,616,186.7,616,186.7"/>
						<path class="st508" d="M616,186.7l4.4,7.8c0,0,0,0,0,0L616,186.7C616,186.7,616,186.7,616,186.7"/>
						<path class="st509" d="M616,186.7l4.4,7.7c0,0,0,0,0,0L616,186.7C616.1,186.8,616.1,186.8,616,186.7"/>
						<path class="st508" d="M616.1,186.8l4.4,7.7c0,0,0,0,0,0L616.1,186.8C616.1,186.8,616.1,186.8,616.1,186.8"/>
						<path class="st507" d="M616.1,186.9l4.3,7.6c0,0,0,0,0,0L616.1,186.9C616.1,186.9,616.1,186.9,616.1,186.9"/>
						<path class="st506" d="M616.2,186.9l4.2,7.5c0,0,0,0,0,0L616.2,186.9C616.2,187,616.2,187,616.2,186.9"/>
						<path class="st505" d="M616.2,187l4.2,7.4c0,0,0,0,0,0L616.2,187C616.2,187.1,616.2,187,616.2,187"/>
						<path class="st504" d="M616.2,187.1l4.1,7.4c0,0,0,0,0,0L616.2,187.1C616.3,187.1,616.3,187.1,616.2,187.1"/>
						<path class="st503" d="M616.3,187.1l4.1,7.3c0,0,0,0,0,0L616.3,187.1C616.3,187.2,616.3,187.2,616.3,187.1"/>
						<path class="st502" d="M616.3,187.2l4,7.2c0,0,0,0,0,0L616.3,187.2C616.4,187.3,616.3,187.2,616.3,187.2"/>
						<path class="st501" d="M616.4,187.3l4,7.1c0,0,0,0,0,0L616.4,187.3C616.4,187.3,616.4,187.3,616.4,187.3"/>
						<path class="st500" d="M616.4,187.3l3.9,7.1c0,0,0,0,0,0L616.4,187.3C616.4,187.4,616.4,187.4,616.4,187.3"/>
						<path class="st499" d="M616.4,187.4l3.9,7c0,0,0,0,0,0L616.4,187.4C616.5,187.5,616.4,187.4,616.4,187.4"/>
						<path class="st498" d="M616.5,187.5l3.8,6.9c0,0,0,0,0,0L616.5,187.5C616.5,187.5,616.5,187.5,616.5,187.5"/>
						<path class="st497" d="M616.5,187.6l3.7,6.8c0,0,0,0,0,0l-3.6-6.7C616.6,187.6,616.5,187.6,616.5,187.6"/>
						<path class="st444" d="M616.6,187.7l3.6,6.7c0,0,0,0,0,0l-3.5-6.5C616.6,187.8,616.6,187.7,616.6,187.7"/>
						<path class="st445" d="M616.7,187.8l3.5,6.5c0,0,0,0,0,0l-3.4-6.4C616.7,187.9,616.7,187.8,616.7,187.8"/>
						<path class="st446" d="M616.7,187.9l3.4,6.4c0,0,0,0,0,0l-3.4-6.3C616.7,188,616.7,187.9,616.7,187.9"/>
						<path class="st447" d="M616.8,188l3.4,6.3c0,0,0,0,0,0l-3.3-6.1C616.8,188.1,616.8,188,616.8,188"/>
						<path class="st448" d="M616.8,188.1l3.3,6.1c0,0-0.1,0-0.1-0.1l-3.1-5.9C616.9,188.2,616.8,188.2,616.8,188.1"/>
						<path class="st449" d="M616.9,188.3l3.1,5.9c0,0-0.1,0-0.1-0.1l-2.9-5.7C617,188.4,616.9,188.3,616.9,188.3"/>
						<path class="st450" d="M617,188.5l2.9,5.7c0,0-0.1,0-0.1-0.1l-2.7-5.5C617,188.6,617,188.5,617,188.5"/>
						<path class="st451" d="M617.1,188.6l2.7,5.5c-0.1,0-0.1-0.1-0.2-0.1l-2.5-5.1C617.1,188.8,617.1,188.7,617.1,188.6"/>
						<path class="st452" d="M617.1,188.8l2.5,5.1c-0.1,0-0.2-0.1-0.2-0.2l-2.2-4.7C617.2,189,617.2,188.9,617.1,188.8"/>
						<path class="st453" d="M617.2,189.1l2.2,4.7c-0.1-0.1-0.3-0.2-0.4-0.2l-1.8-4.2C617.2,189.3,617.2,189.2,617.2,189.1"/>
						<path class="st454" d="M617.2,189.3l1.8,4.2c-0.2-0.1-0.4-0.3-0.7-0.4l-1.2-3.6C617.2,189.5,617.2,189.4,617.2,189.3"/>
						<path class="st455" d="M617.1,189.6l1.2,3.6c-0.5-0.3-1.2-0.7-1.8-1l-0.3-2.6C616.6,189.6,616.9,189.7,617.1,189.6"/>
						<path class="st456" d="M616.2,189.6l0.3,2.6c-1.2-0.6-2.7-1.1-4.1-1.3l0.1-2C614.1,189,615.3,189.4,616.2,189.6"/>
						<path class="st455" d="M612.5,188.8l-0.1,2c-0.3,0-0.7-0.1-1-0.1c-0.4,0-0.7,0-1,0.1l-0.4-2c0.5-0.1,0.9-0.1,1.4-0.1
							C611.8,188.8,612.2,188.8,612.5,188.8"/>
						<path class="st454" d="M610,188.9l0.4,2c-0.4,0.1-0.7,0.2-1.1,0.3l-0.8-1.8C609,189.1,609.5,189,610,188.9"/>
						<path class="st453" d="M608.5,189.3l0.8,1.8c0,0,0,0,0,0L608.5,189.3C608.5,189.3,608.5,189.3,608.5,189.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000078045707036243957390000002976195891324470946_" class="st1" d="M617.6,71.2l-1.3-1.6
							c4.6-6.1,9.8-11.5,15.5-16.3l1.3,1.6C627.5,59.5,622.4,65.1,617.6,71.2z"/>
					</defs>
					<clipPath id="SVGID_00000007417577900712444000000016774472301984677249_">
						<use xlink:href="#SVGID_00000078045707036243957390000002976195891324470946_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000007417577900712444000000016774472301984677249_);enable-background:new    ;">
						<path class="st453" d="M617.6,71.2l-1.3-1.6c3.4-4.6,7.2-8.8,11.3-12.6l1.4,1.5C624.9,62.3,621.2,66.6,617.6,71.2"/>
						<path class="st452" d="M629,58.5l-1.4-1.5c1.4-1.3,2.8-2.5,4.2-3.7l1.3,1.6C631.7,56,630.3,57.2,629,58.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000091713182785566085380000000523971715688610176_" class="st1" d="M634.9,205.5l-1.5,1.3
							c-4.7-3.8-9-7.9-12.8-12.4l-5.7-9.6C625.8,197.5,630.2,201.6,634.9,205.5z"/>
					</defs>
					<clipPath id="SVGID_00000060728847391018507420000016221320642876517517_">
						<use xlink:href="#SVGID_00000091713182785566085380000000523971715688610176_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000060728847391018507420000016221320642876517517_);enable-background:new    ;">
						<path class="st457" d="M634.9,205.5l-1.5,1.3c-0.8-0.6-1.6-1.3-2.4-2l1.5-1.4C633.3,204.1,634.1,204.8,634.9,205.5"/>
						<path class="st456" d="M632.4,203.4l-1.5,1.4c-3.5-3-6.7-6.1-9.6-9.5l-4.3-8.1C624.7,196.1,628.8,200.2,632.4,203.4"/>
						<path class="st457" d="M617,187.3l4.3,8.1c-0.3-0.3-0.5-0.6-0.8-0.9l-5.7-9.6C615.6,185.7,616.3,186.5,617,187.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000021806729251763876290000015303049200913982883_" class="st1" d="M635.6,43.5l-1.8,0.8
							c-2-4.6-5-11.6-5.2-11.9c-0.2-0.4-3.6-8.3,2.8-11l0.8,1.8c-4.5,1.8-1.9,8.1-1.8,8.4C630.6,31.9,633.7,38.9,635.6,43.5z"/>
					</defs>
					<clipPath id="SVGID_00000106136571616621587730000012589639469238058134_">
						<use xlink:href="#SVGID_00000021806729251763876290000015303049200913982883_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000106136571616621587730000012589639469238058134_);enable-background:new    ;">
						<path class="st457" d="M635.6,43.5l-1.8,0.8c-2-4.6-5-11.6-5.2-11.9c0,0,0,0,0,0l1.8-0.8c0,0,0,0,0,0
							C630.6,31.9,633.7,38.9,635.6,43.5"/>
						<path class="st456" d="M630.5,31.5l-1.8,0.8c-0.1-0.3-1.3-3-1-5.8l2,0.5C629.5,29.1,630.3,31.3,630.5,31.5"/>
						<path class="st455" d="M629.6,27l-2-0.5c0.1-0.7,0.2-1.4,0.5-2l1.8,0.9C629.8,26,629.7,26.5,629.6,27"/>
						<path class="st454" d="M629.9,25.5l-1.8-0.9c0.2-0.4,0.4-0.8,0.7-1.2l1.6,1.2C630.2,24.9,630,25.2,629.9,25.5"/>
						<path class="st453" d="M630.4,24.6l-1.6-1.2c0.2-0.3,0.5-0.6,0.7-0.8l1.4,1.5C630.7,24.2,630.5,24.4,630.4,24.6"/>
						<path class="st452" d="M630.9,24l-1.4-1.5c0.3-0.2,0.5-0.5,0.8-0.7l1.1,1.6C631.3,23.7,631.1,23.8,630.9,24"/>
						<path class="st451" d="M631.5,23.5l-1.1-1.6c0.3-0.2,0.6-0.4,1-0.6l0.8,1.8C631.9,23.3,631.7,23.4,631.5,23.5"/>
						<path class="st450" d="M632.2,23.2l-0.8-1.8c0,0,0.1,0,0.1,0L632.2,23.2C632.2,23.1,632.2,23.2,632.2,23.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000128485365530156561600000008976507389716928431_" class="st1" d="M635.9,238.9l-0.7,1.9
							c-0.4-0.2-8-4.2-5.1-11.7l1.9,0.7C629.6,235.5,635.8,238.8,635.9,238.9z"/>
					</defs>
					<clipPath id="SVGID_00000047767968228394186090000017751087776386553217_">
						<use xlink:href="#SVGID_00000128485365530156561600000008976507389716928431_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000047767968228394186090000017751087776386553217_);enable-background:new    ;">
						<path class="st456" d="M635.9,238.9l-0.7,1.9c-0.1,0-0.4-0.2-1-0.6l1-1.7C635.6,238.7,635.9,238.9,635.9,238.9"/>
						<path class="st457" d="M635.2,238.5l-1,1.7c-1.1-0.8-3-2.2-4-4.4l1.9-0.8C632.9,236.7,634.4,237.9,635.2,238.5"/>
						<path class="st456" d="M632.1,235l-1.9,0.8c-0.6-1.2-0.9-2.6-0.7-4.2l2,0.2C631.3,233,631.6,234.1,632.1,235"/>
						<path class="st455" d="M631.5,231.7l-2-0.2c0.1-0.8,0.3-1.6,0.6-2.5l1.9,0.7C631.7,230.5,631.5,231.1,631.5,231.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000005971120927516986650000018286510982712069524_" class="st1" d="M632,229.8l-1.9-0.7
							c2.9-7.4,4.3-11,4.4-11.2l1.9,0.7C636.3,218.8,634.9,222.4,632,229.8z"/>
					</defs>
					<clipPath id="SVGID_00000154410825586239777450000000838584467821254546_">
						<use xlink:href="#SVGID_00000005971120927516986650000018286510982712069524_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000154410825586239777450000000838584467821254546_);enable-background:new    ;">
						<path class="st455" d="M632,229.8l-1.9-0.7c2.9-7.4,4.3-11,4.4-11.2l1.9,0.7C636.3,218.8,634.9,222.4,632,229.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000003798622228109161230000002819473010479779466_" class="st1" d="M633.1,54.8l-1.3-1.6
							c1.5-2.4,3.3-6.1,2-9l1.8-0.8C637.3,47.3,635.1,51.7,633.1,54.8z"/>
					</defs>
					<clipPath id="SVGID_00000098936172735249300070000004107266082323534525_">
						<use xlink:href="#SVGID_00000003798622228109161230000002819473010479779466_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000098936172735249300070000004107266082323534525_);enable-background:new    ;">
						<path class="st454" d="M633.1,54.8l-1.3-1.6c0.5-0.8,1-1.7,1.4-2.7l1.8,0.9C634.4,52.6,633.8,53.8,633.1,54.8"/>
						<path class="st455" d="M635,51.4l-1.8-0.9c0.4-1,0.8-2.1,0.9-3.2l2,0.1C636,48.8,635.6,50.1,635,51.4"/>
						<path class="st456" d="M636.1,47.5l-2-0.1c0.1-1.1,0.1-2.1-0.4-3.1l1.8-0.8C636.2,44.8,636.3,46.1,636.1,47.5"/>
						<polyline class="st457" points="635.6,43.5 633.8,44.3 633.8,44.3 635.6,43.5 635.6,43.5 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000132801630480451251220000012873782774009482684_" class="st1" d="M678.7,226.1c2.1,0.5,4.4,1.1,9.2,2.2
							c-3.7-0.8-8.5-2-12.2-2.8C677,225.7,677.9,225.9,678.7,226.1z"/>
					</defs>
					<clipPath id="SVGID_00000181777792284366722540000014201251333246605717_">
						<use xlink:href="#SVGID_00000132801630480451251220000012873782774009482684_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000181777792284366722540000014201251333246605717_);enable-background:new    ;">
						<path class="st499" d="M687.9,228.3l-12.2-2.8c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st498" d="M687.9,228.3l-12.2-2.8c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st497" d="M687.9,228.3l-12.1-2.8c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st444" d="M687.9,228.3l-12.1-2.8c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st445" d="M687.9,228.3l-12-2.8c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st446" d="M687.9,228.3l-12-2.8c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st447" d="M687.9,228.3l-11.9-2.8c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st448" d="M687.9,228.3l-11.9-2.8c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st449" d="M687.9,228.3l-11.9-2.8c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st450" d="M687.9,228.3l-11.8-2.8c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st451" d="M687.9,228.3l-11.8-2.7c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st452" d="M687.9,228.3l-11.7-2.7c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st453" d="M687.9,228.3l-11.7-2.7c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st454" d="M687.9,228.3l-11.7-2.7c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st455" d="M687.9,228.3l-11.6-2.7c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st456" d="M687.9,228.3l-11.6-2.7c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st457" d="M687.9,228.3l-11.5-2.7c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st458" d="M687.9,228.3l-11.5-2.7c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st459" d="M687.9,228.3l-11.5-2.7c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st460" d="M687.9,228.3l-11.4-2.7c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st461" d="M687.9,228.3l-11.4-2.7c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st462" d="M687.9,228.3l-11.3-2.7c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st463" d="M687.9,228.3l-11.3-2.7c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st464" d="M687.9,228.3l-11.3-2.7c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st465" d="M687.9,228.3l-11.2-2.6c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st466" d="M687.9,228.3l-11.2-2.6c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st467" d="M687.9,228.3l-11.1-2.6c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st468" d="M687.9,228.3l-11.1-2.6c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st469" d="M687.9,228.3l-11.1-2.6c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st470" d="M687.9,228.3l-11-2.6c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st471" d="M687.9,228.3l-11-2.6c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st472" d="M687.9,228.3l-10.9-2.6c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st473" d="M687.9,228.3l-10.9-2.6c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st474" d="M687.9,228.3l-10.9-2.6c0,0,0,0,0,0L687.9,228.3C687.9,228.3,687.9,228.3,687.9,228.3"/>
						<path class="st475" d="M687.9,228.3l-10.8-2.6c0.6,0.1,1.2,0.3,1.7,0.4l8.9,2.3C687.7,228.4,687.8,228.3,687.9,228.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000024689895170202463610000001049976777481083821_" class="st1" d="M636.4,218.7l-1.9-0.7
							c0.1-0.3,2.9-6.3-1.2-11.1l1.5-1.3C639.8,211.4,636.5,218.4,636.4,218.7z"/>
					</defs>
					<clipPath id="SVGID_00000130642095111095899630000007122851933299535782_">
						<use xlink:href="#SVGID_00000024689895170202463610000001049976777481083821_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000130642095111095899630000007122851933299535782_);enable-background:new    ;">
						<path class="st454" d="M636.4,218.7l-1.9-0.7c0,0,0.1-0.1,0.1-0.3l1.9,0.7C636.4,218.5,636.4,218.6,636.4,218.7"/>
						<path class="st455" d="M636.5,218.4l-1.9-0.7c0.2-0.5,0.7-1.8,0.9-3.4l2,0.2C637.3,216.3,636.7,217.8,636.5,218.4"/>
						<path class="st456" d="M637.5,214.4l-2-0.2c0.2-1.5,0.1-3.3-0.7-5.1l1.8-0.8C637.6,210.4,637.7,212.6,637.5,214.4"/>
						<path class="st457" d="M636.6,208.3l-1.8,0.8c-0.3-0.8-0.8-1.5-1.4-2.3l1.5-1.3C635.6,206.4,636.2,207.3,636.6,208.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000134961184259877114770000013278059053413471675_" class="st1" d="M632.3,23.1l-0.8-1.8
							c6.4-2.8,26.5-11.5,27.5-11.9l0.8,1.8C658.7,11.7,638.7,20.4,632.3,23.1z"/>
					</defs>
					<clipPath id="SVGID_00000056420568781130098610000011235832991202618544_">
						<use xlink:href="#SVGID_00000134961184259877114770000013278059053413471675_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000056420568781130098610000011235832991202618544_);enable-background:new    ;">
						<path class="st450" d="M632.3,23.1l-0.8-1.8c6.4-2.8,26.5-11.5,27.5-11.9l0.8,1.8C658.7,11.7,638.7,20.4,632.3,23.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000057137293386805777320000007204647691628748989_" class="st1" d="M662.5,8.9L662.5,8.9
							c2.8,0,6.5,0.9,8.3,5.2h0C669,9.8,665.3,8.9,662.5,8.9z"/>
					</defs>
					<clipPath id="SVGID_00000111175058194104117910000001126234296014273688_">
						<use xlink:href="#SVGID_00000057137293386805777320000007204647691628748989_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000111175058194104117910000001126234296014273688_);enable-background:new    ;">
						<path class="st473" d="M662.5,8.9L662.5,8.9c0.2,0,0.3,0,0.4,0h0C662.8,8.9,662.7,8.9,662.5,8.9"/>
						<path class="st472" d="M663,8.9L663,8.9c0.5,0,1.1,0.1,1.6,0.2h0C664,9,663.5,8.9,663,8.9"/>
						<path class="st471" d="M664.6,9.1L664.6,9.1c0.5,0.1,0.9,0.2,1.4,0.4h0C665.5,9.3,665,9.1,664.6,9.1"/>
						<path class="st470" d="M666,9.4L666,9.4c0.5,0.2,0.9,0.4,1.4,0.6h0C666.9,9.8,666.4,9.6,666,9.4"/>
						<path class="st469" d="M667.3,10L667.3,10c1.4,0.8,2.6,2,3.5,4h0C670,12.1,668.7,10.8,667.3,10"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000005951428882009989940000004589747057670986655_" class="st1" d="M663.8,249.9l-0.7,1.9
							c-6.5-2.6-26.8-10.6-27.9-11l0.7-1.9C637,239.3,657.3,247.3,663.8,249.9z"/>
					</defs>
					<clipPath id="SVGID_00000123408087910858575110000003206166942189373874_">
						<use xlink:href="#SVGID_00000005951428882009989940000004589747057670986655_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000123408087910858575110000003206166942189373874_);enable-background:new    ;">
						<path class="st456" d="M663.8,249.9l-0.7,1.9c-6.5-2.6-26.8-10.6-27.9-11l0.7-1.9C637,239.3,657.3,247.3,663.8,249.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000182516683098747337530000004824719261631233423_" class="st1" d="M672.9,246L672.9,246
							c-0.1,0.3-2.5,6.3-7.4,6.3l0,0C670.4,252.3,672.7,246.3,672.9,246z"/>
					</defs>
					<clipPath id="SVGID_00000036242043125819353840000008261682999772278150_">
						<use xlink:href="#SVGID_00000182516683098747337530000004824719261631233423_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000036242043125819353840000008261682999772278150_);enable-background:new    ;">
						<path class="st474" d="M672.9,246L672.9,246c0,0.1-0.2,0.6-0.6,1.3h0C672.6,246.6,672.8,246.1,672.9,246"/>
						<path class="st475" d="M672.2,247.3L672.2,247.3c-1,1.8-3.2,5-6.7,5h0C669,252.3,671.2,249.2,672.2,247.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000092421985377720367510000012680510906554746275_" class="st1" d="M670.8,14L670.8,14
							c3.2,7.3,4.7,10.8,4.8,11l0,0C675.5,24.9,674,21.4,670.8,14z"/>
					</defs>
					<clipPath id="SVGID_00000044161897394405561020000003361009659171676075_">
						<use xlink:href="#SVGID_00000092421985377720367510000012680510906554746275_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000044161897394405561020000003361009659171676075_);enable-background:new    ;">
						<path class="st469" d="M670.8,14L670.8,14c3.2,7.3,4.7,10.8,4.8,11h0C675.5,24.9,674,21.4,670.8,14"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000150809245435181603820000012764226051390688157_" class="st1" d="M677.6,233.9L677.6,233.9
							c-1.8,4.7-4.6,11.7-4.8,12.1h0C673,245.6,675.8,238.5,677.6,233.9z"/>
					</defs>
					<clipPath id="SVGID_00000045599926556431681840000011537244509786241672_">
						<use xlink:href="#SVGID_00000150809245435181603820000012764226051390688157_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000045599926556431681840000011537244509786241672_);enable-background:new    ;">
						<path class="st474" d="M677.6,233.9L677.6,233.9c-1.8,4.7-4.6,11.7-4.8,12.1h0C673,245.6,675.8,238.5,677.6,233.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000147180175965826698610000014523670958489632143_" class="st1" d="M659.8,11.2c-0.2-0.4-0.4-0.9-0.5-1.3
							L659,9.4c0.1,0,1.5-0.5,3.5-0.5c2.8,0,6.5,0.9,8.3,5.2l-1.8,0.8c-1.4-3.3-4.2-3.9-6.5-3.9C660.9,10.9,659.8,11.2,659.8,11.2z"
							/>
					</defs>
					<clipPath id="SVGID_00000169552980833477433470000009365340004344648606_">
						<use xlink:href="#SVGID_00000147180175965826698610000014523670958489632143_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000169552980833477433470000009365340004344648606_);enable-background:new    ;">
						<polyline class="st475" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st474" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st473" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st472" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st471" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st470" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st469" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st468" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st467" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st466" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st465" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st464" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st463" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st462" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st461" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st460" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st459" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st458" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st457" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st456" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st455" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st454" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st453" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st452" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st451" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<polyline class="st450" points="659.8,11.2 659,9.4 659,9.4 659.8,11.2 659.8,11.2 						"/>
						<path class="st449" d="M659.8,11.2L659,9.4c0.1,0,0.9-0.3,2.1-0.4l0.2,2C660.4,11,659.7,11.2,659.8,11.2"/>
						<path class="st448" d="M661.3,10.9l-0.2-2c0.4,0,0.9-0.1,1.4-0.1c0.3,0,0.6,0,0.8,0l-0.2,2c-0.2,0-0.5,0-0.7,0
							C662.1,10.9,661.7,10.9,661.3,10.9"/>
						<path class="st447" d="M663.2,10.9l0.2-2c0.6,0,1.2,0.1,1.9,0.3l-0.5,1.9C664.2,11,663.7,10.9,663.2,10.9"/>
						<path class="st446" d="M664.7,11.1l0.5-1.9c0.6,0.2,1.3,0.4,1.9,0.7l-1,1.8C665.7,11.4,665.2,11.3,664.7,11.1"/>
						<path class="st445" d="M666.1,11.7l1-1.8c1.5,0.8,2.8,2,3.7,4.1l-1.8,0.8C668.3,13.2,667.3,12.3,666.1,11.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000133491335590765388430000001122342620371787914_" class="st1" d="M675.6,25L675.6,25
							c0.1,0.2,1.5,4.2,5.4,6.1l0,0C677.1,29.3,675.7,25.3,675.6,25z"/>
					</defs>
					<clipPath id="SVGID_00000015325527110714138010000007166530861728329662_">
						<use xlink:href="#SVGID_00000133491335590765388430000001122342620371787914_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000015325527110714138010000007166530861728329662_);enable-background:new    ;">
						<path class="st469" d="M675.6,25L675.6,25c0.1,0.2,1.4,3.9,5,5.9h0C677,28.9,675.7,25.2,675.6,25"/>
						<path class="st470" d="M680.6,31L680.6,31c0.2,0.1,0.3,0.2,0.5,0.2h0C680.9,31.1,680.7,31,680.6,31"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000022530936755620305050000016330908201672890552_" class="st1" d="M669,14.8l1.8-0.8
							c3.2,7.3,4.7,10.8,4.8,11l-1.8,0.8C673.7,25.7,672.2,22.2,669,14.8z"/>
					</defs>
					<clipPath id="SVGID_00000147178383904552351130000009842366087560567456_">
						<use xlink:href="#SVGID_00000022530936755620305050000016330908201672890552_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000147178383904552351130000009842366087560567456_);enable-background:new    ;">
						<path class="st445" d="M669,14.8l1.8-0.8c3.2,7.3,4.7,10.8,4.8,11l-1.8,0.8C673.7,25.7,672.2,22.2,669,14.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000170272312593067220270000016740176582282068900_" class="st1" d="M675.8,233.1l1.9,0.7
							c-1.8,4.7-4.6,11.7-4.8,12.1c-0.1,0.3-2.5,6.3-7.4,6.3c-0.8,0-1.6-0.1-2.4-0.5l0.7-1.9c0.6,0.3,1.2,0.4,1.7,0.4
							c3.4,0.1,5.4-4.8,5.5-5C671.2,244.9,673.9,237.8,675.8,233.1z"/>
					</defs>
					<clipPath id="SVGID_00000150079155477830032540000000074711495101713050_">
						<use xlink:href="#SVGID_00000170272312593067220270000016740176582282068900_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000150079155477830032540000000074711495101713050_);enable-background:new    ;">
						<path class="st449" d="M675.8,233.1l1.9,0.7c-1.8,4.7-4.6,11.7-4.8,12.1c0,0.1-0.2,0.5-0.5,1.1l-1.8-0.9
							c0.3-0.5,0.4-0.8,0.4-0.9C671.2,244.9,673.9,237.8,675.8,233.1"/>
						<path class="st450" d="M670.6,246.1l1.8,0.9c-0.3,0.5-0.7,1.2-1.2,1.9l-1.5-1.3C670,247.1,670.4,246.6,670.6,246.1"/>
						<path class="st451" d="M669.6,247.7l1.5,1.3c-0.3,0.4-0.7,0.9-1.2,1.3l-1.2-1.6C669.1,248.4,669.4,248.1,669.6,247.7"/>
						<path class="st452" d="M668.7,248.8l1.2,1.6c-0.3,0.3-0.7,0.6-1.1,0.9l-1-1.8C668.2,249.3,668.5,249,668.7,248.8"/>
						<path class="st453" d="M667.9,249.5l1,1.8c-0.3,0.2-0.7,0.4-1.1,0.6l-0.7-1.9C667.4,249.8,667.7,249.6,667.9,249.5"/>
						<path class="st454" d="M667.1,249.9l0.7,1.9c-0.4,0.2-0.8,0.3-1.2,0.4l-0.3-2C666.5,250.2,666.8,250.1,667.1,249.9"/>
						<path class="st455" d="M666.2,250.2l0.3,2c-0.3,0.1-0.7,0.1-1,0.1c-0.2,0-0.4,0-0.6,0l0.2-2c0.1,0,0.3,0,0.4,0
							C665.7,250.3,666,250.3,666.2,250.2"/>
						<path class="st456" d="M665.1,250.2l-0.2,2c-0.6-0.1-1.2-0.2-1.8-0.5l0.7-1.9C664.3,250.1,664.7,250.2,665.1,250.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000114067481698270352360000012993170291668442788_" class="st1" d="M688.3,228.4L688.3,228.4
							c0.1,0-8.8,0.8-10.6,5.5h0C679.5,229.2,688.4,228.4,688.3,228.4z"/>
					</defs>
					<clipPath id="SVGID_00000088102843810802364090000014253955948643824572_">
						<use xlink:href="#SVGID_00000114067481698270352360000012993170291668442788_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000088102843810802364090000014253955948643824572_);enable-background:new    ;">
						<polyline class="st470" points="688.3,228.4 688.3,228.4 688.3,228.4 688.3,228.4 688.3,228.4 						"/>
						<polyline class="st471" points="688.3,228.4 688.3,228.4 688.3,228.4 688.3,228.4 688.3,228.4 						"/>
						<polyline class="st472" points="688.3,228.4 688.3,228.4 688.3,228.4 688.3,228.4 688.3,228.4 						"/>
						<path class="st473" d="M688.3,228.4L688.3,228.4C688.3,228.4,688.3,228.4,688.3,228.4L688.3,228.4
							C688.3,228.4,688.3,228.4,688.3,228.4"/>
						<path class="st474" d="M688.3,228.4L688.3,228.4C688.3,228.4,688.3,228.4,688.3,228.4L688.3,228.4
							C688.3,228.4,688.3,228.4,688.3,228.4"/>
						<path class="st475" d="M688.3,228.4L688.3,228.4c-0.6,0.1-8.2,0.9-10.4,4.9h0C680.1,229.4,687.7,228.5,688.3,228.4"/>
						<path class="st474" d="M677.9,233.3L677.9,233.3c-0.1,0.2-0.2,0.4-0.2,0.5h0C677.7,233.7,677.8,233.5,677.9,233.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000135672104171940082280000015598781611800687775_" class="st1" d="M730.2,248.8c0.2,0.6,0.4,1,0.5,1.3
							L730.2,248.8C730.2,248.8,730.2,248.8,730.2,248.8z"/>
					</defs>
					<clipPath id="SVGID_00000080166528247553314430000017525199154318452099_">
						<use xlink:href="#SVGID_00000135672104171940082280000015598781611800687775_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000080166528247553314430000017525199154318452099_);enable-background:new    ;">
						<polyline class="st450" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st451" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st452" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st453" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st454" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st455" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st456" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st457" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st458" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st459" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st460" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st461" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st462" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st463" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st464" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st465" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st466" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st467" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st468" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st469" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st470" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st471" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st472" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st473" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st474" points="730.8,250.1 730.2,248.8 730.2,248.8 730.8,250.1 730.8,250.1 						"/>
						<polyline class="st475" points="730.8,250.1 730.2,248.8 730.2,248.8 730.7,250.1 730.8,250.1 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000131359077316519327890000017066369503976630671_" class="st1" d="M673.8,25.8l1.8-0.8
							c0.1,0.2,1.5,4.2,5.4,6.1l-0.3,2.1C675.5,30.7,673.8,26,673.8,25.8z"/>
					</defs>
					<clipPath id="SVGID_00000163040718088029857160000004823745220395082160_">
						<use xlink:href="#SVGID_00000131359077316519327890000017066369503976630671_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000163040718088029857160000004823745220395082160_);enable-background:new    ;">
						<path class="st445" d="M673.8,25.8l1.8-0.8c0,0.1,0.4,1.2,1.3,2.5l-1.6,1.2C674.2,27.2,673.8,25.9,673.8,25.8"/>
						<path class="st444" d="M675.4,28.7l1.6-1.2c0.5,0.7,1.1,1.4,1.9,2.1l-1,1.6C676.7,30.5,676,29.5,675.4,28.7"/>
						<path class="st445" d="M677.8,31.3l1-1.6c0.6,0.6,1.4,1.1,2.2,1.5l-0.3,2.1C679.6,32.7,678.6,32,677.8,31.3"/>
						<polyline class="st446" points="680.7,33.3 681,31.2 681,31.2 680.7,33.3 680.7,33.3 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000165235925400773206860000011574607488180931220_" class="st1" d="M678.7,226.1c2.4,0.6,4.9,1.2,9.6,2.3
							c0.1,0-8.8,0.8-10.7,5.5l-1.9-0.7C677.9,228,683.9,227.5,678.7,226.1z"/>
					</defs>
					<clipPath id="SVGID_00000157989034026555633000000016927733049025405839_">
						<use xlink:href="#SVGID_00000165235925400773206860000011574607488180931220_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000157989034026555633000000016927733049025405839_);enable-background:new    ;">
						<path class="st475" d="M675.7,225.5l12.6,2.9c0,0,0,0,0,0l-12.5-2.9C675.8,225.5,675.7,225.5,675.7,225.5"/>
						<path class="st474" d="M675.8,225.5l12.5,2.9c0,0,0,0,0,0l-12.4-2.9C675.9,225.5,675.8,225.5,675.8,225.5"/>
						<path class="st473" d="M675.9,225.5l12.4,2.9c0,0,0,0,0,0l-12.2-2.9C676,225.5,676,225.5,675.9,225.5"/>
						<path class="st472" d="M676.1,225.6l12.2,2.9c0,0,0,0,0,0l-12.1-2.8C676.1,225.6,676.1,225.6,676.1,225.6"/>
						<path class="st471" d="M676.2,225.6l12.1,2.8c0,0,0,0,0,0l-12-2.8C676.3,225.6,676.2,225.6,676.2,225.6"/>
						<path class="st470" d="M676.3,225.6l12,2.8c0,0,0,0,0,0l-11.9-2.8C676.4,225.6,676.3,225.6,676.3,225.6"/>
						<path class="st469" d="M676.4,225.6l11.9,2.8c0,0,0,0,0,0l-11.7-2.8C676.5,225.6,676.5,225.6,676.4,225.6"/>
						<path class="st468" d="M676.5,225.7l11.7,2.8c0,0,0,0,0,0l-11.6-2.7C676.6,225.7,676.6,225.7,676.5,225.7"/>
						<path class="st467" d="M676.7,225.7l11.6,2.7c0,0,0,0,0,0l-11.5-2.7C676.7,225.7,676.7,225.7,676.7,225.7"/>
						<path class="st466" d="M676.8,225.7l11.5,2.7c0,0,0,0,0,0l-11.4-2.7C676.8,225.7,676.8,225.7,676.8,225.7"/>
						<path class="st465" d="M676.9,225.7l11.4,2.7c0,0,0,0,0,0l-11.3-2.7C677,225.7,676.9,225.7,676.9,225.7"/>
						<path class="st464" d="M677,225.7l11.3,2.7c0,0,0,0,0,0L677,225.7C677,225.8,677,225.8,677,225.7"/>
						<path class="st463" d="M677.1,225.8l11.2,2.7c0,0,0,0,0,0L677.1,225.8C677.1,225.8,677.1,225.8,677.1,225.8"/>
						<path class="st462" d="M677.1,225.8l11.1,2.6c0,0,0,0,0,0L677.1,225.8C677.2,225.8,677.1,225.8,677.1,225.8"/>
						<path class="st461" d="M677.2,225.8l11.1,2.6c0,0,0,0,0,0L677.2,225.8C677.2,225.8,677.2,225.8,677.2,225.8"/>
						<path class="st460" d="M677.2,225.8l11,2.6c0,0,0,0,0,0L677.2,225.8C677.3,225.8,677.2,225.8,677.2,225.8"/>
						<path class="st459" d="M677.3,225.8l10.9,2.6c0,0,0,0,0,0L677.3,225.8C677.3,225.8,677.3,225.8,677.3,225.8"/>
						<path class="st458" d="M677.3,225.8l10.9,2.6c0,0,0,0,0,0L677.3,225.8C677.4,225.8,677.4,225.8,677.3,225.8"/>
						<path class="st457" d="M677.4,225.8l10.8,2.6c0,0,0,0,0,0L677.4,225.8C677.4,225.8,677.4,225.8,677.4,225.8"/>
						<path class="st456" d="M677.4,225.8l10.8,2.6c0,0,0,0,0,0L677.4,225.8C677.5,225.8,677.4,225.8,677.4,225.8"/>
						<path class="st455" d="M677.5,225.8l10.7,2.6c0,0,0,0,0,0L677.5,225.8C677.5,225.9,677.5,225.9,677.5,225.8"/>
						<path class="st454" d="M677.5,225.9l10.7,2.6c0,0,0,0,0,0L677.5,225.9C677.6,225.9,677.5,225.9,677.5,225.9"/>
						<path class="st453" d="M677.6,225.9l10.6,2.6c0,0,0,0,0,0L677.6,225.9C677.6,225.9,677.6,225.9,677.6,225.9"/>
						<path class="st452" d="M677.6,225.9l10.5,2.6c0,0,0,0,0,0L677.6,225.9C677.7,225.9,677.6,225.9,677.6,225.9"/>
						<path class="st451" d="M677.7,225.9l10.5,2.5c0,0,0,0,0,0L677.7,225.9C677.7,225.9,677.7,225.9,677.7,225.9"/>
						<path class="st450" d="M677.7,225.9l10.4,2.5c0,0,0,0,0,0L677.7,225.9C677.8,225.9,677.7,225.9,677.7,225.9"/>
						<path class="st449" d="M677.8,225.9l10.4,2.5c0,0,0,0,0,0L677.8,225.9C677.8,225.9,677.8,225.9,677.8,225.9"/>
						<path class="st448" d="M677.8,225.9l10.3,2.5c0,0,0,0,0,0L677.8,225.9C677.8,225.9,677.8,225.9,677.8,225.9"/>
						<path class="st447" d="M677.9,225.9l10.3,2.5c0,0,0,0,0,0L677.9,225.9C677.9,225.9,677.9,225.9,677.9,225.9"/>
						<path class="st446" d="M677.9,225.9l10.2,2.5c0,0,0,0,0,0L677.9,225.9C677.9,225.9,677.9,225.9,677.9,225.9"/>
						<path class="st445" d="M678,226l10.2,2.5c0,0,0,0,0,0L678,226C678,226,678,226,678,226"/>
						<path class="st444" d="M678,226l10.1,2.5c0,0,0,0,0,0L678,226C678,226,678,226,678,226"/>
						<path class="st497" d="M678,226l10.1,2.5c0,0,0,0,0,0L678,226C678.1,226,678.1,226,678,226"/>
						<path class="st498" d="M678.1,226l10,2.5c0,0,0,0,0,0L678.1,226C678.1,226,678.1,226,678.1,226"/>
						<path class="st499" d="M678.1,226l10,2.5c0,0,0,0,0,0L678.1,226C678.2,226,678.1,226,678.1,226"/>
						<path class="st500" d="M678.2,226l9.9,2.4c0,0,0,0,0,0L678.2,226C678.2,226,678.2,226,678.2,226"/>
						<path class="st499" d="M678.2,226l9.8,2.4c0,0,0,0,0,0l-9.6-2.4C678.3,226,678.3,226,678.2,226"/>
						<path class="st498" d="M678.4,226.1l9.6,2.4c0,0,0,0,0,0l-9.4-2.4C678.5,226.1,678.5,226.1,678.4,226.1"/>
						<path class="st497" d="M678.6,226.1l9.4,2.4c0,0,0,0,0,0l-9.2-2.3C678.6,226.1,678.6,226.1,678.6,226.1"/>
						<path class="st444" d="M678.7,226.1l9.2,2.3c0,0,0,0,0,0l-9.1-2.3C678.8,226.2,678.7,226.1,678.7,226.1"/>
						<path class="st445" d="M678.8,226.2l9.1,2.3c0,0,0,0,0,0l-8.9-2.3C678.9,226.2,678.9,226.2,678.8,226.2"/>
						<path class="st446" d="M679,226.2l8.9,2.3c0,0,0,0-0.1,0l-8.7-2.3C679.1,226.2,679,226.2,679,226.2"/>
						<path class="st447" d="M679.1,226.2l8.7,2.3c0,0-0.1,0-0.1,0l-8.3-2.2C679.3,226.3,679.2,226.3,679.1,226.2"/>
						<path class="st448" d="M679.4,226.3l8.3,2.2c0,0-0.1,0-0.1,0l-7.9-2.1C679.6,226.4,679.5,226.3,679.4,226.3"/>
						<path class="st449" d="M679.6,226.4l7.9,2.1c-0.1,0-0.1,0-0.2,0l-7.4-2.1C679.8,226.5,679.7,226.4,679.6,226.4"/>
						<path class="st450" d="M679.9,226.5l7.4,2.1c-0.1,0-0.2,0-0.4,0.1l-6.7-2C680.2,226.6,680.1,226.5,679.9,226.5"/>
						<path class="st451" d="M680.3,226.7l6.7,2c-0.2,0-0.5,0.1-0.7,0.1l-5.6-1.8C680.6,226.8,680.5,226.7,680.3,226.7"/>
						<path class="st452" d="M680.7,226.9l5.6,1.8c-0.7,0.1-1.4,0.3-2.3,0.6l-3.4-1.6C680.8,227.4,680.9,227.2,680.7,226.9"/>
						<path class="st453" d="M680.5,227.7l3.4,1.6c-0.7,0.2-1.4,0.5-2,0.8l-2.4-1.5C680,228.2,680.3,228,680.5,227.7"/>
						<path class="st452" d="M679.5,228.6l2.4,1.5c-0.9,0.4-1.8,0.9-2.6,1.5l-1.8-1.3C678.3,229.6,679,229.1,679.5,228.6"/>
						<path class="st451" d="M677.6,230.4l1.8,1.3c-0.3,0.3-0.7,0.6-0.9,0.9l-1.7-1.1C677,231.1,677.3,230.7,677.6,230.4"/>
						<path class="st450" d="M676.7,231.5l1.7,1.1c-0.2,0.3-0.4,0.5-0.6,0.8l-1.8-0.9C676.3,232.1,676.5,231.8,676.7,231.5"/>
						<path class="st449" d="M676.1,232.5l1.8,0.9c-0.1,0.2-0.2,0.3-0.2,0.5l-1.9-0.7C675.9,232.9,676,232.7,676.1,232.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000114762672190091031920000004120580864013633425_" class="st1" d="M695.6,52.3L695.6,52.3
							c-31.5,0-61.2,19-73.5,50c-16,40.6,3.9,86.5,44.5,102.5c9.5,3.8,19.3,5.5,29,5.5l0,0c-9.6,0-19.5-1.8-29-5.5
							c-40.6-16-60.5-61.9-44.5-102.5C634.3,71.2,664.1,52.3,695.6,52.3z"/>
					</defs>
					<clipPath id="SVGID_00000098213062675525850890000006011357104816755844_">
						<use xlink:href="#SVGID_00000114762672190091031920000004120580864013633425_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000098213062675525850890000006011357104816755844_);enable-background:new    ;">
						<path class="st475" d="M695.6,52.3L695.6,52.3c-28.4,0-55.4,15.4-69.4,41.2h0C640.2,67.7,667.2,52.3,695.6,52.3"/>
						<path class="st474" d="M626.2,93.5L626.2,93.5c-1.5,2.8-2.9,5.7-4.1,8.8c-0.5,1.4-1.1,2.8-1.5,4.2h0c0.5-1.4,1-2.8,1.5-4.2
							C623.3,99.2,624.7,96.3,626.2,93.5"/>
						<path class="st473" d="M620.6,106.5L620.6,106.5c-1.3,3.8-2.2,7.7-2.9,11.6h0C618.3,114.2,619.3,110.3,620.6,106.5"/>
						<path class="st472" d="M617.7,118.1L617.7,118.1c-0.6,3.8-1,7.6-1.1,11.3h0C616.7,125.6,617,121.9,617.7,118.1"/>
						<path class="st471" d="M616.6,129.4L616.6,129.4c-0.1,4,0.1,8,0.6,11.9h0C616.7,137.4,616.5,133.4,616.6,129.4"/>
						<path class="st470" d="M617.2,141.3L617.2,141.3c0.6,4.8,1.7,9.4,3.1,14h0C618.9,150.8,617.8,146.1,617.2,141.3"/>
						<path class="st469" d="M620.3,155.3L620.3,155.3c5.9,18.5,18.6,34.8,36.4,44.8h0C638.9,190.1,626.2,173.9,620.3,155.3"/>
						<path class="st470" d="M656.7,200.1L656.7,200.1c3.1,1.8,6.4,3.3,9.8,4.7c1.1,0.4,2.3,0.9,3.4,1.3h0c-1.1-0.4-2.3-0.8-3.4-1.3
							C663.1,203.4,659.8,201.9,656.7,200.1"/>
						<path class="st471" d="M670,206L670,206c3.9,1.3,7.7,2.3,11.6,3h0C677.7,208.4,673.8,207.4,670,206"/>
						<path class="st472" d="M681.6,209.1L681.6,209.1c3.8,0.7,7.5,1.1,11.3,1.2h0C689.1,210.1,685.4,209.7,681.6,209.1"/>
						<path class="st473" d="M692.9,210.3L692.9,210.3c0.9,0,1.8,0,2.7,0h0C694.6,210.3,693.8,210.3,692.9,210.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000104666074066219792780000004553989759305648046_" class="st1" d="M695.5,30.1L695.5,30.1
							c3.5,0,7,0.2,10.5,0.6h0C702.5,30.3,699,30.1,695.5,30.1z"/>
					</defs>
					<clipPath id="SVGID_00000108275769741945180060000001934548115536651949_">
						<use xlink:href="#SVGID_00000104666074066219792780000004553989759305648046_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000108275769741945180060000001934548115536651949_);enable-background:new    ;">
						<path class="st473" d="M695.5,30.1L695.5,30.1c1.1,0,2.2,0,3.3,0.1h0C697.7,30.1,696.6,30.1,695.5,30.1"/>
						<path class="st472" d="M698.8,30.2L698.8,30.2c2.4,0.1,4.8,0.2,7.1,0.5h0C703.6,30.4,701.2,30.3,698.8,30.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000096058080136131122250000005765496710412944050_" class="st1" d="M706.6,228L706.6,228
							c-3.7,0.4-7.4,0.6-11.2,0.6h0C699.2,228.7,702.9,228.5,706.6,228z"/>
					</defs>
					<clipPath id="SVGID_00000175308512734340281850000018201473277314864041_">
						<use xlink:href="#SVGID_00000096058080136131122250000005765496710412944050_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000175308512734340281850000018201473277314864041_);enable-background:new    ;">
						<path class="st475" d="M706.6,228L706.6,228c-3.7,0.4-7.4,0.6-11.2,0.6h0C699.2,228.7,702.9,228.5,706.6,228"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000147936158696595010740000004664596294208382911_" class="st1" d="M680.7,33.3l0.3-2.1
							c4.8-0.7,9.6-1.1,14.5-1.1c3.5,0,7,0.2,10.4,0.6l-0.2,2c-3.3-0.3-6.8-0.6-10.2-0.6C690.8,32.2,686,32.5,680.7,33.3z"/>
					</defs>
					<clipPath id="SVGID_00000006702944766731437220000004938674688557124013_">
						<use xlink:href="#SVGID_00000147936158696595010740000004664596294208382911_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000006702944766731437220000004938674688557124013_);enable-background:new    ;">
						<path class="st449" d="M680.7,33.3l0.3-2.1c1.8-0.3,3.6-0.5,5.3-0.6l0.1,2C684.6,32.7,682.7,33,680.7,33.3"/>
						<path class="st448" d="M686.4,32.6l-0.1-2c3-0.3,6.1-0.4,9.1-0.4c2.6,0,5.2,0.1,7.8,0.3l-0.1,2c-2.6-0.2-5.2-0.4-7.7-0.4
							C692.6,32.2,689.6,32.3,686.4,32.6"/>
						<path class="st447" d="M703.2,32.5l0.1-2c0.9,0.1,1.7,0.2,2.6,0.2l-0.2,2C704.9,32.6,704,32.5,703.2,32.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000073704183479365746230000007136760691347659937_" class="st1" d="M707,226l-0.4,2
							c-3.7,0.4-7.5,0.6-11.2,0.6c-2.4,0-4.7-0.1-7.1-0.3l-12.6-2.9c15.1,1.1,17.4,1.2,19.7,1.2C699,226.6,702.8,226.5,707,226z"/>
					</defs>
					<clipPath id="SVGID_00000021811526307023913980000002405808933504322434_">
						<use xlink:href="#SVGID_00000073704183479365746230000007136760691347659937_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000021811526307023913980000002405808933504322434_);enable-background:new    ;">
						<path class="st455" d="M707,226l-0.4,2c-3.7,0.4-7.5,0.6-11.2,0.6c-2.4,0-4.7-0.1-7.1-0.3l-12.6-2.9
							c15.1,1.1,17.4,1.2,19.7,1.2C699,226.6,702.8,226.5,707,226"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000084489993864055145100000013050993301869127321_" class="st1" d="M705.7,32.7l0.2-2
							c2.5-0.8,5.4-2.1,6.4-4.6l1.9,0.7C712.9,30.1,709.4,31.7,705.7,32.7z"/>
					</defs>
					<clipPath id="SVGID_00000062180741283653114990000008950323155673069995_">
						<use xlink:href="#SVGID_00000084489993864055145100000013050993301869127321_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000062180741283653114990000008950323155673069995_);enable-background:new    ;">
						<path class="st450" d="M705.7,32.7l0.2-2c0.8-0.2,1.6-0.5,2.3-0.9l0.6,1.8C707.9,32.1,706.8,32.4,705.7,32.7"/>
						<path class="st451" d="M708.9,31.6l-0.6-1.8c0.5-0.2,1.1-0.5,1.6-0.8l1.1,1.6C710.3,31,709.6,31.3,708.9,31.6"/>
						<path class="st452" d="M710.9,30.6l-1.1-1.6c0.4-0.3,0.8-0.5,1.1-0.9l1.4,1.4C711.9,29.9,711.5,30.2,710.9,30.6"/>
						<path class="st453" d="M712.4,29.5l-1.4-1.4c0.3-0.3,0.6-0.6,0.8-0.9l1.7,1.1C713.1,28.7,712.7,29.1,712.4,29.5"/>
						<path class="st454" d="M713.4,28.3l-1.7-1.1c0.2-0.3,0.4-0.7,0.6-1l1.8,0.8C713.9,27.5,713.7,27.9,713.4,28.3"/>
						<path class="st455" d="M714.2,27l-1.8-0.8c0,0,0-0.1,0-0.1l1.9,0.7C714.2,26.9,714.2,26.9,714.2,27"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000007429817017775777150000001454731749129097123_" class="st1" d="M716.2,234.2l-1.8,0.8
							c-0.1-0.3-2.1-5.8-7.7-6.9l0.4-2C713.8,227.4,716.1,233.9,716.2,234.2z"/>
					</defs>
					<clipPath id="SVGID_00000069370336125074512170000009774878918920491197_">
						<use xlink:href="#SVGID_00000007429817017775777150000001454731749129097123_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000069370336125074512170000009774878918920491197_);enable-background:new    ;">
						<path class="st456" d="M716.2,234.2l-1.8,0.8c0,0-0.1-0.2-0.2-0.4l1.8-0.8C716.1,233.9,716.2,234.1,716.2,234.2"/>
						<path class="st457" d="M716,233.7l-1.8,0.8c-0.4-0.9-1.5-3.2-3.7-4.8l1.1-1.6C714.2,230,715.6,232.6,716,233.7"/>
						<path class="st456" d="M711.6,228.1l-1.1,1.6c-1-0.8-2.3-1.4-3.9-1.7l0.4-2C708.9,226.4,710.4,227.2,711.6,228.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000078767061986309126930000008427360378340790935_" class="st1" d="M724.5,7.7L724.5,7.7
							c0.8,0,1.6,0.1,2.4,0.5l0,0C726.1,7.9,725.2,7.7,724.5,7.7z"/>
					</defs>
					<clipPath id="SVGID_00000049900526158215903900000000001651486130201756_">
						<use xlink:href="#SVGID_00000078767061986309126930000008427360378340790935_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000049900526158215903900000000001651486130201756_);enable-background:new    ;">
						<path class="st473" d="M724.5,7.7L724.5,7.7c0.1,0,0.1,0,0.2,0h0C724.6,7.7,724.6,7.7,724.5,7.7"/>
						<path class="st472" d="M724.7,7.7L724.7,7.7c0.3,0,0.6,0,0.9,0.1h0C725.3,7.8,725,7.7,724.7,7.7"/>
						<path class="st471" d="M725.6,7.8L725.6,7.8c0.3,0.1,0.7,0.1,1,0.3h0C726.2,8,725.9,7.9,725.6,7.8"/>
						<path class="st470" d="M726.6,8.1L726.6,8.1c0.1,0,0.2,0.1,0.3,0.1h0C726.8,8.2,726.7,8.1,726.6,8.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000006681109654961983560000017552433808001764752_" class="st1" d="M721,245.2l-1.8,0.8
							c-3.2-7.3-4.7-10.8-4.8-11l1.8-0.8C716.3,234.3,717.8,237.8,721,245.2z"/>
					</defs>
					<clipPath id="SVGID_00000107583786767532503360000012189547132748882364_">
						<use xlink:href="#SVGID_00000006681109654961983560000017552433808001764752_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000107583786767532503360000012189547132748882364_);enable-background:new    ;">
						<path class="st457" d="M721,245.2l-1.8,0.8c-3.2-7.3-4.7-10.8-4.8-11l1.8-0.8C716.3,234.3,717.8,237.8,721,245.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000029753233772595392750000006945470364236409225_" class="st1" d="M731,250.6L731,250.6
							c-0.1,0-1.5,0.5-3.5,0.5l0,0C729.5,251.1,730.9,250.7,731,250.6z"/>
					</defs>
					<clipPath id="SVGID_00000119120040149195054430000010127298258761731222_">
						<use xlink:href="#SVGID_00000029753233772595392750000006945470364236409225_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000119120040149195054430000010127298258761731222_);enable-background:new    ;">
						<path class="st475" d="M731,250.6L731,250.6c-0.1,0-1.5,0.5-3.5,0.5h0C729.5,251.1,730.9,250.7,731,250.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000017516784099543238960000015992459980775136437_" class="st1" d="M714.2,26.9l-1.9-0.7
							c1.8-4.7,4.6-11.7,4.8-12.1c0.1-0.3,2.5-6.3,7.4-6.3c0.8,0,1.6,0.1,2.4,0.5l-0.7,1.9c-0.6-0.3-1.2-0.4-1.7-0.4
							c-3.4-0.1-5.4,4.8-5.5,5C718.8,15.1,716,22.2,714.2,26.9z"/>
					</defs>
					<clipPath id="SVGID_00000000938592030894655210000004995856222939963269_">
						<use xlink:href="#SVGID_00000017516784099543238960000015992459980775136437_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000000938592030894655210000004995856222939963269_);enable-background:new    ;">
						<path class="st455" d="M714.2,26.9l-1.9-0.7c1.8-4.7,4.6-11.7,4.8-12.1c0,0,0-0.1,0.1-0.1l1.8,0.8c0,0.1,0,0.1,0,0.1
							C718.8,15.1,716,22.2,714.2,26.9"/>
						<path class="st454" d="M719,14.6l-1.8-0.8c0.2-0.4,0.7-1.5,1.5-2.7l1.5,1.3C719.6,13.4,719.1,14.3,719,14.6"/>
						<path class="st453" d="M720.3,12.4l-1.5-1.3c0.4-0.5,0.9-1.1,1.4-1.6l1.2,1.6C720.9,11.6,720.6,12,720.3,12.4"/>
						<path class="st452" d="M721.3,11.2l-1.2-1.6c0.3-0.3,0.7-0.6,1.1-0.9l0.9,1.8C721.9,10.7,721.6,10.9,721.3,11.2"/>
						<path class="st451" d="M722.2,10.5l-0.9-1.8c0.3-0.2,0.6-0.4,0.9-0.5l0.7,1.9C722.6,10.2,722.4,10.3,722.2,10.5"/>
						<path class="st450" d="M722.8,10.1l-0.7-1.9c0.3-0.1,0.6-0.2,0.9-0.3l0.4,1.9C723.2,9.9,723,10,722.8,10.1"/>
						<path class="st449" d="M723.5,9.9L723,7.9c0.3-0.1,0.6-0.1,0.9-0.2l0.2,2C723.9,9.8,723.7,9.8,723.5,9.9"/>
						<path class="st448" d="M724.1,9.7l-0.2-2c0.2,0,0.4,0,0.6,0c0.1,0,0.3,0,0.4,0l-0.1,2c-0.1,0-0.2,0-0.3,0
							C724.4,9.7,724.2,9.7,724.1,9.7"/>
						<path class="st447" d="M724.7,9.7l0.1-2c0.4,0,0.7,0.1,1.1,0.2l-0.5,1.9C725.2,9.8,725,9.8,724.7,9.7"/>
						<path class="st446" d="M725.5,9.9l0.5-1.9c0.3,0.1,0.6,0.2,0.9,0.3l-0.7,1.9C726,10,725.7,9.9,725.5,9.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000169521013638864157080000008387636644209232302_" class="st1" d="M730.2,248.8c0.2,0.4,0.4,0.9,0.5,1.3
							l0.2,0.6c-0.1,0-1.5,0.5-3.5,0.5c-2.8,0-6.4-0.9-8.3-5.1l1.8-0.8c1.4,3.2,4.2,3.9,6.5,3.9
							C729.1,249.1,730.2,248.8,730.2,248.8z"/>
					</defs>
					<clipPath id="SVGID_00000067925136966587941770000009799423875224606650_">
						<use xlink:href="#SVGID_00000169521013638864157080000008387636644209232302_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000067925136966587941770000009799423875224606650_);enable-background:new    ;">
						<polyline class="st475" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st474" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st473" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st472" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st471" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st470" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st469" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st468" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st467" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st466" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st465" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st464" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st463" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st462" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st461" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st460" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st459" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st458" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st457" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st456" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<polyline class="st455" points="730.2,248.8 731,250.6 731,250.6 730.2,248.8 730.2,248.8 						"/>
						<path class="st454" d="M730.2,248.8l0.8,1.8c-0.1,0-0.4,0.1-1,0.2l-0.4-1.9C730,248.9,730.2,248.8,730.2,248.8"/>
						<path class="st455" d="M729.6,248.9l0.4,1.9c-0.6,0.1-1.5,0.2-2.5,0.2c-0.4,0-0.9,0-1.3-0.1l0.3-2c0.4,0,0.7,0.1,1.1,0.1
							C728.3,249.1,729.1,249,729.6,248.9"/>
						<path class="st456" d="M726.4,249.1l-0.3,2c-1.4-0.2-3-0.6-4.4-1.7l1.2-1.6C724,248.6,725.2,248.9,726.4,249.1"/>
						<path class="st457" d="M723,247.8l-1.2,1.6c-1-0.8-1.9-1.9-2.6-3.4l1.8-0.8C721.5,246.3,722.2,247.2,723,247.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000015332885291901933700000015289641286215573384_" class="st1" d="M726.9,8.2L726.9,8.2
							c6.5,2.6,26.8,10.6,27.9,11h0C753.8,18.8,733.4,10.8,726.9,8.2z"/>
					</defs>
					<clipPath id="SVGID_00000155825378158634625960000010555649514308845494_">
						<use xlink:href="#SVGID_00000015332885291901933700000015289641286215573384_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000155825378158634625960000010555649514308845494_);enable-background:new    ;">
						<path class="st470" d="M726.9,8.2L726.9,8.2c6.5,2.6,26.8,10.6,27.9,11h0C753.8,18.8,733.4,10.8,726.9,8.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000018197549419007361400000014571465850621653669_" class="st1" d="M755.5,42.1L755.5,42.1
							c-0.1,0.2-1.8,3.8-0.6,7.8l0,0C753.7,45.9,755.4,42.3,755.5,42.1z"/>
					</defs>
					<clipPath id="SVGID_00000157998470853461069210000009488665769605479591_">
						<use xlink:href="#SVGID_00000018197549419007361400000014571465850621653669_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000157998470853461069210000009488665769605479591_);enable-background:new    ;">
						<path class="st474" d="M755.5,42.1L755.5,42.1c0,0.1-0.2,0.4-0.4,1h0C755.3,42.5,755.5,42.1,755.5,42.1"/>
						<path class="st473" d="M755.1,43.1L755.1,43.1c-0.1,0.5-0.3,1.1-0.5,1.9h0C754.8,44.2,754.9,43.5,755.1,43.1"/>
						<path class="st472" d="M754.6,44.9L754.6,44.9c-0.1,0.5-0.1,1.1-0.2,1.8h0C754.5,46.1,754.5,45.5,754.6,44.9"/>
						<path class="st471" d="M754.5,46.7L754.5,46.7c0,0.5,0,1.1,0.1,1.7h0C754.5,47.8,754.5,47.2,754.5,46.7"/>
						<path class="st470" d="M754.6,48.4L754.6,48.4c0.1,0.5,0.2,1,0.3,1.5h0C754.7,49.3,754.6,48.8,754.6,48.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000103954192498207075420000002310593620899522190_" class="st1" d="M758.5,238.7L758.5,238.7
							c-6.4,2.8-26.5,11.5-27.5,11.9l0,0C732,250.2,752.1,241.5,758.5,238.7z"/>
					</defs>
					<clipPath id="SVGID_00000107553205519466632150000014945062417412590526_">
						<use xlink:href="#SVGID_00000103954192498207075420000002310593620899522190_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000107553205519466632150000014945062417412590526_);enable-background:new    ;">
						<path class="st475" d="M758.5,238.7L758.5,238.7c-6.4,2.8-26.5,11.5-27.5,11.9h0C732,250.2,752.1,241.5,758.5,238.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000022553881732581768790000014149422822361669808_" class="st1" d="M758.7,205.9L758.7,205.9
							c-1.6,2.3-3.9,6.6-2.6,9.8h0C754.8,212.5,757.2,208.2,758.7,205.9z"/>
					</defs>
					<clipPath id="SVGID_00000054949170654363520610000013841122258644808321_">
						<use xlink:href="#SVGID_00000022553881732581768790000014149422822361669808_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000054949170654363520610000013841122258644808321_);enable-background:new    ;">
						<path class="st475" d="M758.7,205.9L758.7,205.9c-0.4,0.6-0.8,1.3-1.2,2.1h0C757.9,207.2,758.3,206.5,758.7,205.9"/>
						<path class="st474" d="M757.5,208L757.5,208c-0.4,0.8-0.9,1.8-1.2,2.7h0C756.6,209.7,757,208.8,757.5,208"/>
						<path class="st473" d="M756.3,210.7L756.3,210.7c-0.2,0.5-0.3,1.1-0.4,1.6h0C756,211.7,756.1,211.2,756.3,210.7"/>
						<path class="st472" d="M755.9,212.3L755.9,212.3c-0.1,0.4-0.1,0.8-0.1,1.1h0C755.8,213,755.8,212.6,755.9,212.3"/>
						<path class="st471" d="M755.8,213.4L755.8,213.4c0,0.3,0,0.6,0.1,1h0C755.8,214,755.7,213.7,755.8,213.4"/>
						<path class="st470" d="M755.8,214.4L755.8,214.4c0.1,0.3,0.1,0.6,0.2,0.9h0C755.9,215,755.8,214.7,755.8,214.4"/>
						<path class="st469" d="M756,215.3L756,215.3c0.1,0.2,0.1,0.3,0.2,0.4h0C756.1,215.6,756.1,215.4,756,215.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000025405417302108740200000017042112339963944874_" class="st1" d="M759.9,30.9L759.9,30.9
							c-2.9,7.4-4.3,11-4.4,11.2l0,0C755.6,41.9,757,38.4,759.9,30.9z"/>
					</defs>
					<clipPath id="SVGID_00000170238813087593988850000013857634655071928709_">
						<use xlink:href="#SVGID_00000025405417302108740200000017042112339963944874_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000170238813087593988850000013857634655071928709_);enable-background:new    ;">
						<path class="st474" d="M759.9,30.9L759.9,30.9c-2.9,7.4-4.3,11-4.4,11.2h0C755.6,41.9,757,38.4,759.9,30.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000121280938356214995670000000668923779929884572_" class="st1" d="M756.2,215.7L756.2,215.7
							c2,4.6,5,11.6,5.2,11.9h0C761.2,227.3,758.2,220.3,756.2,215.7z"/>
					</defs>
					<clipPath id="SVGID_00000018204872529089759750000007326833009824301735_">
						<use xlink:href="#SVGID_00000121280938356214995670000000668923779929884572_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000018204872529089759750000007326833009824301735_);enable-background:new    ;">
						<path class="st469" d="M756.2,215.7L756.2,215.7c2,4.6,5,11.6,5.2,11.9h0C761.2,227.3,758.2,220.3,756.2,215.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000033355346568834985930000013831586828600500620_" class="st1" d="M753.6,41.3l1.9,0.7
							c-0.1,0.2-1.8,3.8-0.6,7.8l-1.7,1.2C751.6,45.7,753.6,41.5,753.6,41.3z"/>
					</defs>
					<clipPath id="SVGID_00000182529494308953421400000016701513949533380995_">
						<use xlink:href="#SVGID_00000033355346568834985930000013831586828600500620_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000182529494308953421400000016701513949533380995_);enable-background:new    ;">
						<path class="st449" d="M753.6,41.3l1.9,0.7c0,0.1-0.2,0.4-0.3,0.8l-1.9-0.6C753.5,41.7,753.6,41.4,753.6,41.3"/>
						<path class="st448" d="M753.3,42.3l1.9,0.6c-0.2,0.5-0.3,1.1-0.5,1.8l-1.9-0.3C752.9,43.5,753.1,42.8,753.3,42.3"/>
						<path class="st447" d="M752.7,44.4l1.9,0.3c-0.1,0.6-0.2,1.2-0.2,1.9l-1.9,0.2C752.5,45.9,752.6,45.1,752.7,44.4"/>
						<path class="st446" d="M752.5,46.8l1.9-0.2c0,0.8,0,1.7,0.2,2.6l-1.8,1C752.6,48.9,752.5,47.8,752.5,46.8"/>
						<path class="st445" d="M752.9,50.1l1.8-1c0,0.2,0.1,0.4,0.2,0.7l-1.7,1.2C753,50.7,752.9,50.4,752.9,50.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000176737287998455791500000006260276074443390386_" class="st1" d="M754.8,19.2L754.8,19.2
							c0.4,0.2,8,4.2,5.1,11.7h0C762.8,23.5,755.2,19.4,754.8,19.2z"/>
					</defs>
					<clipPath id="SVGID_00000056417589140445866900000011361080036764181141_">
						<use xlink:href="#SVGID_00000176737287998455791500000006260276074443390386_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000056417589140445866900000011361080036764181141_);enable-background:new    ;">
						<path class="st470" d="M754.8,19.2L754.8,19.2c0,0,0.1,0,0.2,0.1l0,0C754.9,19.3,754.9,19.2,754.8,19.2"/>
						<path class="st469" d="M755,19.3L755,19.3c0.8,0.5,4.1,2.5,5.2,5.9h0C759.1,21.8,755.8,19.8,755,19.3"/>
						<path class="st470" d="M760.2,25.2L760.2,25.2c0.1,0.4,0.3,0.9,0.3,1.3h0C760.4,26.1,760.3,25.7,760.2,25.2"/>
						<path class="st471" d="M760.5,26.6L760.5,26.6c0.1,0.4,0.1,0.8,0.1,1.2h0C760.6,27.4,760.6,27,760.5,26.6"/>
						<path class="st472" d="M760.6,27.8L760.6,27.8c0,0.4,0,0.8-0.1,1.2h0C760.5,28.6,760.6,28.2,760.6,27.8"/>
						<path class="st473" d="M760.5,29L760.5,29c-0.1,0.5-0.2,0.9-0.3,1.4h0C760.3,29.9,760.4,29.4,760.5,29"/>
						<path class="st474" d="M760.1,30.4L760.1,30.4c0,0.2-0.1,0.4-0.2,0.5h0C760,30.7,760,30.6,760.1,30.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000033347146078085426020000001661928859164726458_" class="st1" d="M726.2,10.1l0.7-1.9
							c6.5,2.6,26.8,10.6,27.9,11l-0.7,1.9C753,20.7,732.7,12.7,726.2,10.1z"/>
					</defs>
					<clipPath id="SVGID_00000062900375386133703050000003013960003206292368_">
						<use xlink:href="#SVGID_00000033347146078085426020000001661928859164726458_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000062900375386133703050000003013960003206292368_);enable-background:new    ;">
						<path class="st446" d="M726.2,10.1l0.7-1.9c6.5,2.6,26.8,10.6,27.9,11l-0.7,1.9C753,20.7,732.7,12.7,726.2,10.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000023257565970255259240000005712573180153898427_" class="st1" d="M761.3,227.7L761.3,227.7
							c0.2,0.4,3.6,8.3-2.8,11h0C764.9,235.9,761.5,228.1,761.3,227.7z"/>
					</defs>
					<clipPath id="SVGID_00000160179540241360376530000013663180299780224158_">
						<use xlink:href="#SVGID_00000023257565970255259240000005712573180153898427_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000160179540241360376530000013663180299780224158_);enable-background:new    ;">
						<path class="st469" d="M761.3,227.7L761.3,227.7c0.1,0.1,0.2,0.5,0.4,1.2h0C761.6,228.2,761.4,227.7,761.3,227.7"/>
						<path class="st470" d="M761.8,228.8L761.8,228.8c0.2,0.6,0.4,1.5,0.6,2.4h0C762.2,230.3,762,229.4,761.8,228.8"/>
						<path class="st471" d="M762.3,231.2L762.3,231.2c0.1,0.5,0.1,1,0.1,1.6h0C762.4,232.2,762.4,231.7,762.3,231.2"/>
						<path class="st472" d="M762.4,232.8L762.4,232.8c0,0.4,0,0.8-0.1,1.2h0C762.4,233.6,762.4,233.2,762.4,232.8"/>
						<path class="st473" d="M762.3,234L762.3,234c0,0.3-0.1,0.7-0.2,1h0C762.2,234.7,762.2,234.3,762.3,234"/>
						<path class="st474" d="M762,235L762,235c-0.1,0.3-0.2,0.7-0.4,1h0C761.8,235.7,761.9,235.3,762,235"/>
						<path class="st475" d="M761.6,236L761.6,236c-0.6,1.1-1.6,2-3.1,2.7h0C760.1,238,761,237.1,761.6,236"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000014604761716943812250000015015214423928212393_" class="st1" d="M757.4,204.3l1.3,1.6
							c-1.6,2.3-4,6.6-2.6,9.8l-1.8,0.8C752.5,212.2,755.4,207.3,757.4,204.3z"/>
					</defs>
					<clipPath id="SVGID_00000106859306951103896810000008496871482041696896_">
						<use xlink:href="#SVGID_00000014604761716943812250000015015214423928212393_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000106859306951103896810000008496871482041696896_);enable-background:new    ;">
						<path class="st450" d="M757.4,204.3l1.3,1.6c-0.4,0.7-1,1.5-1.4,2.4l-1.7-1C756.2,206.2,756.9,205.2,757.4,204.3"/>
						<path class="st449" d="M755.6,207.3l1.7,1c-0.4,0.7-0.7,1.5-1,2.2l-1.9-0.5C754.7,209.1,755.2,208.1,755.6,207.3"/>
						<path class="st448" d="M754.4,210l1.9,0.5c-0.2,0.6-0.3,1.1-0.4,1.7l-2-0.2C754,211.4,754.2,210.7,754.4,210"/>
						<path class="st447" d="M753.9,212.1l2,0.2c-0.1,0.5-0.1,0.9-0.1,1.3l-2,0.2C753.8,213.2,753.8,212.6,753.9,212.1"/>
						<path class="st446" d="M753.8,213.7l2-0.2c0,0.4,0,0.9,0.1,1.3l-1.9,0.5C753.8,214.8,753.8,214.3,753.8,213.7"/>
						<path class="st445" d="M754,215.4l1.9-0.5c0.1,0.3,0.2,0.6,0.3,0.9l-1.8,0.8C754.2,216.2,754.1,215.8,754,215.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000037662335732908591330000002780480937689588918_" class="st1" d="M757.7,236.9l0.8,1.8
							c-6.4,2.8-26.5,11.5-27.5,11.9l-0.8-1.8C731.3,248.3,751.3,239.6,757.7,236.9z"/>
					</defs>
					<clipPath id="SVGID_00000163791383642721857260000017012226895069881234_">
						<use xlink:href="#SVGID_00000037662335732908591330000002780480937689588918_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000163791383642721857260000017012226895069881234_);enable-background:new    ;">
						<path class="st453" d="M757.7,236.9l0.8,1.8c-6.4,2.8-26.5,11.5-27.5,11.9l-0.8-1.8C731.3,248.3,751.3,239.6,757.7,236.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000098919350738379700300000003880210909795705011_" class="st1" d="M758,30.2l1.9,0.7
							c-2.9,7.4-4.3,11-4.4,11.2l-1.9-0.7C753.7,41.2,755.1,37.6,758,30.2z"/>
					</defs>
					<clipPath id="SVGID_00000052798020231042608080000015849199573845779132_">
						<use xlink:href="#SVGID_00000098919350738379700300000003880210909795705011_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000052798020231042608080000015849199573845779132_);enable-background:new    ;">
						<path class="st449" d="M758,30.2l1.9,0.7c-2.9,7.4-4.3,11-4.4,11.2l-1.9-0.7C753.7,41.2,755.1,37.6,758,30.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000020392553857100334150000009302659522174445979_" class="st1" d="M754.1,21.1l0.7-1.9
							c0.4,0.2,8,4.2,5.1,11.7l-1.9-0.7C760.4,24.5,754.2,21.2,754.1,21.1z"/>
					</defs>
					<clipPath id="SVGID_00000095330054490160514670000014717812341026420663_">
						<use xlink:href="#SVGID_00000020392553857100334150000009302659522174445979_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000095330054490160514670000014717812341026420663_);enable-background:new    ;">
						<path class="st445" d="M754.1,21.1l0.7-1.9c0.3,0.2,4.4,2.4,5.5,6.4l-2,0.5C757.5,22.9,754.2,21.1,754.1,21.1"/>
						<path class="st446" d="M758.4,26.1l2-0.5c0.1,0.6,0.2,1.1,0.2,1.8l-2,0.1C758.5,27,758.5,26.6,758.4,26.1"/>
						<path class="st447" d="M758.6,27.5l2-0.1c0,0.5,0,1-0.1,1.5l-2-0.3C758.5,28.3,758.6,27.9,758.6,27.5"/>
						<path class="st448" d="M758.5,28.6l2,0.3c-0.1,0.5-0.2,0.9-0.4,1.4l-1.9-0.6C758.3,29.4,758.4,29,758.5,28.6"/>
						<path class="st449" d="M758.2,29.8l1.9,0.6c-0.1,0.2-0.1,0.4-0.2,0.6l-1.9-0.7C758.1,30,758.1,29.9,758.2,29.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000029008876798565567050000009995008749107141518_" class="st1" d="M754.3,216.5l1.8-0.8
							c2,4.6,5,11.6,5.2,11.9c0.2,0.4,3.6,8.3-2.8,11l-0.8-1.8c4.5-1.8,1.9-8.1,1.8-8.4C759.4,228.1,756.3,221.1,754.3,216.5z"/>
					</defs>
					<clipPath id="SVGID_00000051369586236572155980000008823202894853019817_">
						<use xlink:href="#SVGID_00000029008876798565567050000009995008749107141518_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000051369586236572155980000008823202894853019817_);enable-background:new    ;">
						<path class="st445" d="M754.3,216.5l1.8-0.8c2,4.6,5,11.6,5.2,11.9c0.1,0.1,0.4,0.9,0.7,2l-2,0.4c-0.2-0.9-0.5-1.5-0.5-1.6
							C759.4,228.1,756.3,221.1,754.3,216.5"/>
						<path class="st446" d="M760,230.1l2-0.4c0.2,0.8,0.4,1.7,0.4,2.7l-2-0.2C760.3,231.4,760.2,230.7,760,230.1"/>
						<path class="st447" d="M760.4,232.2l2,0.2c0,0.5,0,1.1-0.1,1.6l-2-0.6C760.4,233,760.4,232.6,760.4,232.2"/>
						<path class="st448" d="M760.3,233.4l2,0.6c-0.1,0.4-0.2,0.8-0.3,1.1l-1.9-0.8C760.2,234,760.3,233.7,760.3,233.4"/>
						<path class="st449" d="M760.1,234.2l1.9,0.8c-0.1,0.3-0.2,0.6-0.4,0.9l-1.7-1.1C760,234.7,760.1,234.5,760.1,234.2"/>
						<path class="st450" d="M759.9,234.9l1.7,1.1c-0.1,0.3-0.3,0.5-0.5,0.7l-1.6-1.3C759.7,235.3,759.8,235.1,759.9,234.9"/>
						<path class="st451" d="M759.6,235.5l1.6,1.3c-0.2,0.2-0.4,0.5-0.6,0.7l-1.4-1.4C759.3,235.8,759.4,235.6,759.6,235.5"/>
						<path class="st452" d="M759.1,235.9l1.4,1.4c-0.2,0.2-0.5,0.4-0.8,0.7l-1.2-1.6C758.8,236.3,759,236.1,759.1,235.9"/>
						<path class="st453" d="M758.6,236.4l1.2,1.6c-0.4,0.2-0.8,0.5-1.2,0.7l-0.8-1.8C758,236.7,758.3,236.6,758.6,236.4"/>
						<polyline class="st454" points="757.7,236.9 758.5,238.7 758.5,238.7 757.7,236.9 757.7,236.9 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000075152820118644694540000007782781388569554571_" class="st1" d="M754.9,49.8L754.9,49.8
							c7,5.2,13.1,11.2,18.4,17.8l0,0C768,61,761.8,55,754.9,49.8z"/>
					</defs>
					<clipPath id="SVGID_00000030486695806078898140000000805900654842253992_">
						<use xlink:href="#SVGID_00000075152820118644694540000007782781388569554571_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000030486695806078898140000000805900654842253992_);enable-background:new    ;">
						<path class="st469" d="M754.9,49.8L754.9,49.8c7,5.2,13.1,11.2,18.4,17.8h0C768,61,761.8,55,754.9,49.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000116223926540380495960000000912664441656803740_" class="st1" d="M773.8,190.4L773.8,190.4
							c-4.5,5.8-9.6,11-15.1,15.5l0,0C764.3,201.3,769.3,196.1,773.8,190.4z"/>
					</defs>
					<clipPath id="SVGID_00000155825004011970753050000009676462901586307507_">
						<use xlink:href="#SVGID_00000116223926540380495960000000912664441656803740_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000155825004011970753050000009676462901586307507_);enable-background:new    ;">
						<path class="st475" d="M773.8,190.4L773.8,190.4c-4.5,5.8-9.6,11-15.1,15.5h0C764.3,201.3,769.3,196.1,773.8,190.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000132087707594389576140000014286249349308962730_" class="st1" d="M778,189.5L778,189.5
							c-1.3,0-2.7,0.2-4.1,0.9l0,0C775.2,189.7,776.7,189.5,778,189.5z"/>
					</defs>
					<clipPath id="SVGID_00000034769344714485019910000008528540278682859703_">
						<use xlink:href="#SVGID_00000132087707594389576140000014286249349308962730_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000034769344714485019910000008528540278682859703_);enable-background:new    ;">
						<path class="st475" d="M778,189.5L778,189.5c-1.3,0-2.7,0.2-4.1,0.9h0C775.2,189.7,776.7,189.5,778,189.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000077305987458651651680000009509804287332976796_" class="st1" d="M773.2,67.7L773.2,67.7
							c1.6,0.8,3.6,1.6,5.4,1.6l0,0C776.8,69.2,774.8,68.5,773.2,67.7z"/>
					</defs>
					<clipPath id="SVGID_00000171682447222754853610000010549212537182071224_">
						<use xlink:href="#SVGID_00000077305987458651651680000009509804287332976796_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000171682447222754853610000010549212537182071224_);enable-background:new    ;">
						<path class="st470" d="M773.2,67.7L773.2,67.7c0.7,0.4,1.5,0.7,2.3,1h0C774.7,68.4,773.9,68,773.2,67.7"/>
						<path class="st471" d="M775.5,68.7L775.5,68.7c0.6,0.2,1.1,0.3,1.6,0.4h0C776.6,69,776.1,68.8,775.5,68.7"/>
						<path class="st472" d="M777.2,69.1L777.2,69.1c0.4,0.1,0.8,0.1,1.2,0.1h0C777.9,69.2,777.6,69.2,777.2,69.1"/>
						<path class="st473" d="M778.3,69.2L778.3,69.2c0.1,0,0.2,0,0.2,0h0C778.5,69.2,778.4,69.2,778.3,69.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000028294633621935797230000009523897802939207300_" class="st1" d="M753.1,51l1.7-1.2
							C761.8,55,768,61,773.2,67.7l-1.6,1.3C766.5,62.4,760.2,56.5,753.1,51z"/>
					</defs>
					<clipPath id="SVGID_00000069394655121808918720000015015565803126710661_">
						<use xlink:href="#SVGID_00000028294633621935797230000009523897802939207300_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000069394655121808918720000015015565803126710661_);enable-background:new    ;">
						<path class="st445" d="M753.1,51l1.7-1.2C761.8,55,768,61,773.2,67.7l-1.6,1.3C766.5,62.4,760.2,56.5,753.1,51"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000018935125282802935350000011464257184331458969_" class="st1" d="M772.6,188.7l1.3,1.6
							c-4.5,5.8-9.6,11-15.1,15.5l-1.3-1.6C762.9,199.9,767.9,194.6,772.6,188.7z"/>
					</defs>
					<clipPath id="SVGID_00000148622509940415027560000005328697858593240498_">
						<use xlink:href="#SVGID_00000018935125282802935350000011464257184331458969_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000148622509940415027560000005328697858593240498_);enable-background:new    ;">
						<path class="st451" d="M772.6,188.7l1.3,1.6c-3,3.8-6.2,7.4-9.6,10.7l-1.4-1.5C766.2,196.2,769.4,192.6,772.6,188.7"/>
						<path class="st452" d="M762.8,199.5l1.4,1.5c-1.8,1.7-3.6,3.3-5.5,4.8l-1.3-1.6C759.3,202.8,761.1,201.2,762.8,199.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000060018728840238602480000017158191930694398387_" class="st1" d="M771.6,68.9l1.6-1.3
							c1.6,0.8,3.5,1.6,5.3,1.6c0.7,0,1.5-0.1,2.1-0.4l0.8,1.8c-1,0.4-2,0.6-2.9,0.6C776.3,71.2,774.1,70.4,771.6,68.9z"/>
					</defs>
					<clipPath id="SVGID_00000078019953957641961010000011598340693920803743_">
						<use xlink:href="#SVGID_00000060018728840238602480000017158191930694398387_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000078019953957641961010000011598340693920803743_);enable-background:new    ;">
						<path class="st445" d="M771.6,68.9l1.6-1.3c0.5,0.2,1,0.5,1.5,0.7l-1,1.7C773,69.7,772.3,69.3,771.6,68.9"/>
						<path class="st446" d="M773.7,70l1-1.7c0.6,0.3,1.3,0.5,2,0.6l-0.4,1.9C775.4,70.7,774.6,70.4,773.7,70"/>
						<path class="st447" d="M776.2,70.9l0.4-1.9c0.5,0.1,1,0.2,1.4,0.2l-0.1,2C777.4,71.2,776.8,71.1,776.2,70.9"/>
						<path class="st448" d="M778,71.2l0.1-2c0.2,0,0.3,0,0.5,0c0.2,0,0.4,0,0.6,0l0.2,2c-0.3,0-0.5,0-0.8,0
							C778.4,71.2,778.2,71.2,778,71.2"/>
						<path class="st449" d="M779.4,71.2l-0.2-2c0.3,0,0.6-0.1,0.8-0.1l0.5,1.9C780.1,71.1,779.7,71.2,779.4,71.2"/>
						<path class="st450" d="M780.5,71l-0.5-1.9c0.2-0.1,0.5-0.1,0.7-0.2l0.8,1.8C781.2,70.8,780.8,70.9,780.5,71"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000093177011870194427770000004417226345900991906_" class="st1" d="M783.6,188.6l-0.7,1.9
							c-0.1-0.1-2.2-1-4.9-1c-1.3,0-2.7,0.2-4.1,0.9l-1.3-1.6c2.2-1,3.9-1.3,5.4-1.3C781.2,187.5,783.6,188.6,783.6,188.6z"/>
					</defs>
					<clipPath id="SVGID_00000160186578862017317750000005775836283583146914_">
						<use xlink:href="#SVGID_00000093177011870194427770000004417226345900991906_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000160186578862017317750000005775836283583146914_);enable-background:new    ;">
						<path class="st456" d="M783.6,188.6l-0.7,1.9c-0.1,0-1.5-0.7-3.6-0.9l0.2-2C781.9,187.8,783.6,188.6,783.6,188.6"/>
						<path class="st455" d="M779.6,187.6l-0.2,2c-0.4,0-0.9-0.1-1.4-0.1c-0.6,0-1.2,0-1.8,0.2l-0.4-1.9c0.8-0.2,1.5-0.2,2.2-0.2
							C778.5,187.5,779.1,187.5,779.6,187.6"/>
						<path class="st454" d="M775.7,187.7l0.4,1.9c-0.7,0.1-1.4,0.3-2,0.6l-1.2-1.7C774,188.1,774.9,187.9,775.7,187.7"/>
						<path class="st453" d="M772.9,188.6l1.2,1.7c-0.1,0-0.2,0.1-0.3,0.1l-1.3-1.6C772.7,188.7,772.8,188.6,772.9,188.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000067921190999720960840000006530394038441501090_" class="st1" d="M793.8,116L793.8,116
							c1.1,8,1.2,16.2,0.3,24.4l0,0C795.1,132.2,794.9,124,793.8,116z"/>
					</defs>
					<clipPath id="SVGID_00000088851160829918371610000012103364145557287299_">
						<use xlink:href="#SVGID_00000067921190999720960840000006530394038441501090_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000088851160829918371610000012103364145557287299_);enable-background:new    ;">
						<path class="st470" d="M793.8,116L793.8,116c0.1,0.3,0.1,0.6,0.1,0.9h0C793.9,116.6,793.9,116.3,793.8,116"/>
						<path class="st471" d="M794,116.9L794,116.9c0.6,4.9,0.9,9.9,0.8,14.9h0C794.8,126.8,794.6,121.8,794,116.9"/>
						<path class="st472" d="M794.7,131.8L794.7,131.8c-0.1,2.9-0.3,5.7-0.6,8.6h0C794.5,137.5,794.7,134.6,794.7,131.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000113333038568137528810000014583979857437148857_" class="st1" d="M770.9,161l-1.9-0.7
							c16-40.6-3.9-86.5-44.5-102.5c-9.5-3.8-19.3-5.5-29-5.5c-31.5,0-61.2,19-73.5,50c-16,40.6,3.9,86.5,44.5,102.5
							c9.5,3.8,19.3,5.5,29,5.5c31.5,0,61.2-19,73.5-50L770.9,161c-12.6,31.9-43.1,51.3-75.4,51.3c-9.9,0-19.7-1.5-29.7-5.7
							c-41.6-16.4-62-63.5-45.6-105.1c12.6-31.8,43.1-51.3,75.4-51.3c9.9,0,19.7,1.5,29.7,5.7C766.9,72.4,787.3,119.4,770.9,161z"/>
					</defs>
					<clipPath id="SVGID_00000177447051565976425580000006429364426780675240_">
						<use xlink:href="#SVGID_00000113333038568137528810000014583979857437148857_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000177447051565976425580000006429364426780675240_);enable-background:new    ;">
						<path class="st455" d="M770.9,161l-1.9-0.7c2.6-6.7,4.3-13.6,5.1-20.4l2,0.2C775.3,147.1,773.6,154.2,770.9,161"/>
						<path class="st456" d="M776.1,140.1l-2-0.2c1.5-13.7-0.7-27.4-5.9-39.7l1.8-0.8C775.4,112,777.6,126,776.1,140.1"/>
						<path class="st457" d="M770,99.4l-1.8,0.8c-5.6-13.1-14.7-24.6-26.7-33.1l1.2-1.6C754.9,74.1,764.2,86,770,99.4"/>
						<path class="st456" d="M742.7,65.4l-1.2,1.6c-5.1-3.7-10.8-6.8-16.9-9.2c-5.4-2.1-10.8-3.6-16.3-4.5l0.3-2.1
							c5.6,0.9,11.1,2.4,16.7,4.7C731.6,58.4,737.4,61.6,742.7,65.4"/>
						<path class="st455" d="M708.5,51.3l-0.3,2.1c-4.2-0.7-8.5-1-12.7-1c-4.7,0-9.3,0.4-13.9,1.2l-0.4-2c4.7-0.8,9.4-1.3,14.2-1.3
							C699.9,50.3,704.2,50.6,708.5,51.3"/>
						<path class="st454" d="M681.4,51.5l0.4,2c-5.9,1.1-11.6,2.8-17.1,5.1l-0.8-1.8C669.5,54.4,675.3,52.6,681.4,51.5"/>
						<path class="st453" d="M663.8,56.8l0.8,1.8c-4.7,2-9.1,4.4-13.3,7.2l-1.1-1.7C654.5,61.3,659.1,58.8,663.8,56.8"/>
						<path class="st452" d="M650.2,64.2l1.1,1.7c-3.8,2.6-7.4,5.5-10.7,8.7l-1.4-1.4C642.6,69.8,646.3,66.8,650.2,64.2"/>
						<path class="st451" d="M639.2,73.1l1.4,1.4c-3.1,3-5.9,6.2-8.5,9.7l-1.6-1.2C633.2,79.4,636.1,76.1,639.2,73.1"/>
						<path class="st450" d="M630.5,83l1.6,1.2c-2.4,3.2-4.6,6.7-6.5,10.4l-1.8-0.9C625.8,89.9,628,86.3,630.5,83"/>
						<path class="st449" d="M623.8,93.6l1.8,0.9c-1.3,2.5-2.5,5.1-3.5,7.7c-0.5,1.3-1,2.5-1.4,3.8l-1.9-0.6
							c0.4-1.3,0.9-2.6,1.4-3.9C621.3,98.8,622.5,96.2,623.8,93.6"/>
						<path class="st448" d="M618.8,105.5l1.9,0.6c-1.4,4.1-2.4,8.3-3.1,12.5l-2-0.3C616.3,114,617.4,109.7,618.8,105.5"/>
						<path class="st447" d="M615.6,118.2l2,0.3c-0.8,4.7-1.1,9.4-1,14l-2,0C614.5,127.8,614.8,123,615.6,118.2"/>
						<path class="st446" d="M614.6,132.6l2,0c0.1,5.9,0.9,11.8,2.3,17.5l-1.9,0.5C615.4,144.7,614.7,138.7,614.6,132.6"/>
						<path class="st445" d="M616.9,150.5l1.9-0.5c5.3,21.8,19.9,41,41,51.7l-0.9,1.8C637.3,192.6,622.4,172.9,616.9,150.5"/>
						<path class="st446" d="M658.9,203.5l0.9-1.8c2.2,1.1,4.4,2.1,6.7,3c2.4,0.9,4.7,1.7,7.1,2.4l-0.5,2c-2.4-0.7-4.9-1.6-7.4-2.6
							C663.5,205.7,661.2,204.7,658.9,203.5"/>
						<path class="st447" d="M673.2,209.2l0.5-2c5.3,1.5,10.6,2.5,16,2.9l-0.1,2C684.1,211.7,678.6,210.8,673.2,209.2"/>
						<path class="st448" d="M689.5,212.1l0.1-2c2,0.1,4,0.2,5.9,0.2c2.6,0,5.1-0.1,7.7-0.4l0.2,2c-2.6,0.3-5.2,0.4-7.9,0.4
							C693.5,212.3,691.5,212.3,689.5,212.1"/>
						<path class="st449" d="M703.4,211.9l-0.2-2c4.2-0.4,8.3-1.1,12.3-2.2l0.5,1.9C711.9,210.8,707.7,211.5,703.4,211.9"/>
						<path class="st450" d="M716,209.7l-0.5-1.9c4-1.1,8-2.4,11.8-4.1l0.8,1.8C724.2,207.2,720.2,208.6,716,209.7"/>
						<path class="st451" d="M728.1,205.5l-0.8-1.8c4-1.8,7.8-3.8,11.5-6.2l1.1,1.7C736.2,201.5,732.2,203.7,728.1,205.5"/>
						<path class="st452" d="M739.9,199.1l-1.1-1.7c3.8-2.5,7.4-5.3,10.8-8.4l1.4,1.5C747.5,193.6,743.8,196.5,739.9,199.1"/>
						<path class="st453" d="M750.9,190.4l-1.4-1.5c3.7-3.4,7.1-7.3,10.1-11.4l1.6,1.2C758.2,183,754.7,186.9,750.9,190.4"/>
						<path class="st454" d="M761.3,178.7l-1.6-1.2c3.4-4.7,6.4-9.9,8.7-15.6l1.8,0.8C767.8,168.5,764.8,173.8,761.3,178.7"/>
						<path class="st455" d="M770.2,162.7l-1.8-0.8c0.2-0.6,0.5-1.1,0.7-1.7l1.9,0.7C770.7,161.6,770.4,162.2,770.2,162.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000116922570378857436040000001855682691469873599_" class="st1" d="M794.1,140.4L794.1,140.4
							c0.7,2.7,2.1,5.9,4.7,6.9l0,0C796.3,146.3,794.9,143.1,794.1,140.4z"/>
					</defs>
					<clipPath id="SVGID_00000053544226838969055150000007688448782084006550_">
						<use xlink:href="#SVGID_00000116922570378857436040000001855682691469873599_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000053544226838969055150000007688448782084006550_);enable-background:new    ;">
						<path class="st470" d="M794.1,140.4L794.1,140.4c0.1,0.3,0.2,0.6,0.3,0.9h0C794.3,141,794.2,140.7,794.1,140.4"/>
						<path class="st469" d="M794.4,141.3L794.4,141.3c0.7,2.2,1.9,4.6,3.9,5.7h0C796.3,146,795.1,143.6,794.4,141.3"/>
						<path class="st470" d="M798.3,147.1L798.3,147.1c0.2,0.1,0.4,0.2,0.6,0.3h0C798.7,147.3,798.5,147.2,798.3,147.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000083065830234724344270000013062484159487255942_" class="st1" d="M799.9,110.6L799.9,110.6
							c-0.2,0.1-4.1,1.5-6.1,5.3l0,0C795.8,112.1,799.7,110.7,799.9,110.6z"/>
					</defs>
					<clipPath id="SVGID_00000066515175133742811830000013778288521197256064_">
						<use xlink:href="#SVGID_00000083065830234724344270000013062484159487255942_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000066515175133742811830000013778288521197256064_);enable-background:new    ;">
						<path class="st475" d="M799.9,110.6L799.9,110.6c-0.2,0.1-4,1.5-6,5.1h0C795.9,112.1,799.7,110.7,799.9,110.6"/>
						<path class="st474" d="M794,115.7L794,115.7c0,0.1-0.1,0.2-0.1,0.2h0C793.9,115.9,793.9,115.8,794,115.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000005961101990205927480000015533362564243643274_" class="st1" d="M791.8,115.6l2.1,0.3
							c1.1,8,1.2,16.2,0.3,24.4l-2-0.2C792.9,132.3,792.9,124.1,791.8,115.6z"/>
					</defs>
					<clipPath id="SVGID_00000022535441893900536770000005797118040648703138_">
						<use xlink:href="#SVGID_00000005961101990205927480000015533362564243643274_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000022535441893900536770000005797118040648703138_);enable-background:new    ;">
						<path class="st446" d="M791.8,115.6l2.1,0.3c0.4,3,0.7,5.9,0.8,9l-2.1,0C792.5,121.8,792.2,118.7,791.8,115.6"/>
						<path class="st447" d="M792.6,124.9l2.1,0c0.2,5.1,0.1,10.3-0.5,15.5l-2-0.2C792.6,135.2,792.8,130.1,792.6,124.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000175283103180730937670000008196339441753317013_" class="st1" d="M797.5,62.6L797.5,62.6
							c2.3,0,4.9,0.8,6.2,3.8h0C802.4,63.4,799.8,62.6,797.5,62.6z"/>
					</defs>
					<clipPath id="SVGID_00000172422473918721156090000007676426167454318268_">
						<use xlink:href="#SVGID_00000175283103180730937670000008196339441753317013_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000172422473918721156090000007676426167454318268_);enable-background:new    ;">
						<path class="st473" d="M797.5,62.6L797.5,62.6c0.1,0,0.2,0,0.3,0h0C797.7,62.6,797.6,62.6,797.5,62.6"/>
						<path class="st472" d="M797.8,62.6L797.8,62.6c0.4,0,0.8,0.1,1.2,0.1h0C798.6,62.7,798.2,62.7,797.8,62.6"/>
						<path class="st471" d="M799.1,62.8L799.1,62.8c0.4,0.1,0.7,0.1,1,0.3h0C799.7,62.9,799.4,62.8,799.1,62.8"/>
						<path class="st470" d="M800.1,63L800.1,63c0.4,0.1,0.7,0.3,1,0.4h0C800.8,63.3,800.4,63.2,800.1,63"/>
						<path class="st469" d="M801.1,63.5L801.1,63.5c1.1,0.6,2,1.5,2.6,3h0C803,65,802.1,64.1,801.1,63.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000153703114735026978130000000136326023749051801_" class="st1" d="M794.8,193.1l-0.7,1.9
							c-7.4-2.9-11-4.3-11.2-4.4l0.7-1.9C783.8,188.7,787.4,190.1,794.8,193.1z"/>
					</defs>
					<clipPath id="SVGID_00000062893667335999383680000014227093946650219945_">
						<use xlink:href="#SVGID_00000153703114735026978130000000136326023749051801_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000062893667335999383680000014227093946650219945_);enable-background:new    ;">
						<path class="st456" d="M794.8,193.1l-0.7,1.9c-7.4-2.9-11-4.3-11.2-4.4l0.7-1.9C783.8,188.7,787.4,190.1,794.8,193.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000054229075200529887820000002140791104046572450_" class="st1" d="M805.7,189.8L805.7,189.8
							c-0.1,0.3-3,5.7-8.4,5.7l0,0C802.7,195.6,805.6,190.1,805.7,189.8z"/>
					</defs>
					<clipPath id="SVGID_00000144340254915556378150000003084786478723265939_">
						<use xlink:href="#SVGID_00000054229075200529887820000002140791104046572450_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000144340254915556378150000003084786478723265939_);enable-background:new    ;">
						<path class="st474" d="M805.7,189.8L805.7,189.8C805.7,189.9,805.7,189.9,805.7,189.8L805.7,189.8
							C805.7,189.9,805.7,189.9,805.7,189.8"/>
						<path class="st475" d="M805.7,189.9L805.7,189.9c-0.3,0.7-3.2,5.7-8.4,5.7h0C802.5,195.6,805.4,190.6,805.7,189.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000134961205549586504760000015745666795362599581_" class="st1" d="M792.1,140.2l2,0.2
							c0.7,2.7,2.1,5.9,4.7,6.9l-0.7,1.9C794.6,147.8,793,143.9,792.1,140.2z"/>
					</defs>
					<clipPath id="SVGID_00000129891858367464928720000010552515034040101563_">
						<use xlink:href="#SVGID_00000134961205549586504760000015745666795362599581_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000129891858367464928720000010552515034040101563_);enable-background:new    ;">
						<path class="st445" d="M792.1,140.2l2,0.2c0.7,2.5,2,5.6,4.3,6.8l-0.9,1.8C794.5,147.4,793,143.7,792.1,140.2"/>
						<path class="st446" d="M797.6,149l0.9-1.8c0.1,0.1,0.2,0.1,0.4,0.2l-0.7,1.9C798,149.1,797.8,149.1,797.6,149"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000048486188836143742040000004921061966171464379_" class="st1" d="M799.1,108.8l0.8,1.8
							c-0.2,0.1-4.1,1.5-6.1,5.3l-2.1-0.3C794.4,110.5,799,108.8,799.1,108.8z"/>
					</defs>
					<clipPath id="SVGID_00000060015477023370305720000007284388733249966780_">
						<use xlink:href="#SVGID_00000048486188836143742040000004921061966171464379_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000060015477023370305720000007284388733249966780_);enable-background:new    ;">
						<path class="st454" d="M799.1,108.8l0.8,1.8c0,0-0.1,0.1-0.3,0.1l-0.8-1.8C799,108.8,799.1,108.8,799.1,108.8"/>
						<path class="st453" d="M798.8,108.9l0.8,1.8c-0.4,0.2-1.1,0.5-2,1.1l-1.2-1.6C797.5,109.5,798.3,109.1,798.8,108.9"/>
						<path class="st452" d="M796.5,110.2l1.2,1.6c-0.5,0.3-1.1,0.8-1.6,1.3l-1.5-1.2C795.2,111.2,795.8,110.7,796.5,110.2"/>
						<path class="st451" d="M794.5,111.9l1.5,1.2c-0.4,0.4-0.8,0.8-1.2,1.3l-1.8-0.8C793.5,113,794,112.4,794.5,111.9"/>
						<path class="st450" d="M793,113.6l1.8,0.8c-0.3,0.4-0.6,0.9-0.9,1.4l-2.1-0.4C792.2,114.8,792.6,114.2,793,113.6"/>
						<path class="st449" d="M791.8,115.5l2.1,0.4c0,0,0,0.1-0.1,0.1l-2.1-0.3C791.8,115.6,791.8,115.5,791.8,115.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000073704637292250795390000002698354326222179996_" class="st1" d="M781.5,70.6l-0.8-1.8
							c4.6-2,11.6-5,11.9-5.2c0.1,0,2.3-1,4.9-1c2.3,0,4.8,0.8,6.2,3.8l-1.8,0.8c-0.9-2.1-2.6-2.6-4.3-2.6c-2.2,0-4,0.8-4.1,0.8
							C793.1,65.6,786.1,68.7,781.5,70.6z"/>
					</defs>
					<clipPath id="SVGID_00000087398728007883602900000006487044846793397891_">
						<use xlink:href="#SVGID_00000073704637292250795390000002698354326222179996_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000087398728007883602900000006487044846793397891_);enable-background:new    ;">
						<path class="st450" d="M781.5,70.6l-0.8-1.8c4.6-2,11.6-5,11.9-5.2c0.1,0,0.7-0.3,1.7-0.6l0.5,1.9c-0.8,0.2-1.4,0.4-1.4,0.5
							C793.1,65.6,786.1,68.7,781.5,70.6"/>
						<path class="st449" d="M794.8,65l-0.5-1.9c0.6-0.2,1.3-0.3,2.1-0.4l0.2,2C795.9,64.8,795.3,64.9,794.8,65"/>
						<path class="st448" d="M796.6,64.7l-0.2-2c0.4,0,0.7-0.1,1.1-0.1c0.2,0,0.4,0,0.6,0l-0.2,2c-0.2,0-0.3,0-0.5,0
							C797.2,64.6,796.9,64.7,796.6,64.7"/>
						<path class="st447" d="M798,64.7l0.2-2c0.5,0,1,0.1,1.4,0.2l-0.6,1.9C798.7,64.7,798.3,64.7,798,64.7"/>
						<path class="st446" d="M799,64.8l0.6-1.9c0.5,0.1,0.9,0.3,1.3,0.5l-1,1.8C799.6,65,799.3,64.9,799,64.8"/>
						<path class="st445" d="M799.9,65.2l1-1.8c1.1,0.6,2.1,1.5,2.8,3.1l-1.8,0.8C801.4,66.2,800.7,65.5,799.9,65.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000160890145065409408240000016844262411861714824_" class="st1" d="M810.9,105.8L810.9,105.8
							c-7.3,3.2-10.8,4.7-11,4.8l0,0C800.1,110.5,803.6,109,810.9,105.8z"/>
					</defs>
					<clipPath id="SVGID_00000134947631560644384280000007642963881147321508_">
						<use xlink:href="#SVGID_00000160890145065409408240000016844262411861714824_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000134947631560644384280000007642963881147321508_);enable-background:new    ;">
						<path class="st475" d="M810.9,105.8L810.9,105.8c-7.3,3.2-10.8,4.7-11,4.8h0C800.1,110.5,803.6,109,810.9,105.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000120559337207323470070000013840734453163150754_" class="st1" d="M798.9,147.3L798.9,147.3
							c4.7,1.8,11.7,4.6,12.1,4.8h0C810.6,152,803.5,149.2,798.9,147.3z"/>
					</defs>
					<clipPath id="SVGID_00000081616694580815223810000008242723863407829902_">
						<use xlink:href="#SVGID_00000120559337207323470070000013840734453163150754_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000081616694580815223810000008242723863407829902_);enable-background:new    ;">
						<path class="st470" d="M798.9,147.3L798.9,147.3c4.7,1.8,11.7,4.6,12.1,4.8h0C810.6,152,803.5,149.2,798.9,147.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000124879018200365087820000002173783795665650361_" class="st1" d="M803.9,189.1l1.9,0.7
							c-0.2,0.3-3,5.7-8.4,5.7c-1,0-2.1-0.2-3.2-0.6l0.7-1.9c0.9,0.4,1.7,0.5,2.5,0.5C801.4,193.6,803.9,189.1,803.9,189.1z"/>
					</defs>
					<clipPath id="SVGID_00000149360363457615865030000018343832960206972600_">
						<use xlink:href="#SVGID_00000124879018200365087820000002173783795665650361_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000149360363457615865030000018343832960206972600_);enable-background:new    ;">
						<path class="st450" d="M803.9,189.1l1.9,0.7c0,0.1-0.3,0.7-0.9,1.4l-1.6-1.1C803.6,189.5,803.9,189.1,803.9,189.1"/>
						<path class="st451" d="M803.2,190.1l1.6,1.1c-0.4,0.5-0.8,1.1-1.4,1.6l-1.3-1.5C802.5,191,802.9,190.5,803.2,190.1"/>
						<path class="st452" d="M802.1,191.4l1.3,1.5c-0.4,0.4-0.9,0.8-1.5,1.2l-1-1.7C801.3,192.1,801.7,191.8,802.1,191.4"/>
						<path class="st453" d="M800.9,192.4l1,1.7c-0.5,0.3-1,0.6-1.5,0.8l-0.7-1.9C800.1,192.9,800.5,192.6,800.9,192.4"/>
						<path class="st454" d="M799.7,193.1l0.7,1.9c-0.5,0.2-1.1,0.4-1.7,0.5l-0.3-2C798.8,193.4,799.3,193.2,799.7,193.1"/>
						<path class="st455" d="M798.4,193.5l0.3,2c-0.4,0.1-0.9,0.1-1.4,0.1c-0.3,0-0.6,0-0.9,0l0.2-2c0.2,0,0.4,0,0.7,0
							C797.7,193.6,798,193.5,798.4,193.5"/>
						<path class="st456" d="M796.6,193.5l-0.2,2c-0.7-0.1-1.5-0.3-2.4-0.6l0.7-1.9C795.5,193.3,796.1,193.5,796.6,193.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000018930700741704258250000007277726263451499684_" class="st1" d="M803.7,66.5L803.7,66.5
							c2.8,6.4,11.5,26.5,12,27.5h0C815.2,92.9,806.5,72.9,803.7,66.5z"/>
					</defs>
					<clipPath id="SVGID_00000073716805937103368560000016761142662698035127_">
						<use xlink:href="#SVGID_00000018930700741704258250000007277726263451499684_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000073716805937103368560000016761142662698035127_);enable-background:new    ;">
						<path class="st469" d="M803.7,66.5L803.7,66.5c2.8,6.4,11.5,26.5,12,27.5h0C815.2,92.9,806.5,72.9,803.7,66.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000152960090993174687070000016887426393572855993_" class="st1" d="M816.8,161.9L816.8,161.9
							c-2.6,6.5-10.6,26.8-11,27.9h0C806.2,188.8,814.2,168.5,816.8,161.9z"/>
					</defs>
					<clipPath id="SVGID_00000113317285640608811340000016043859074581959343_">
						<use xlink:href="#SVGID_00000152960090993174687070000016887426393572855993_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000113317285640608811340000016043859074581959343_);enable-background:new    ;">
						<path class="st474" d="M816.8,161.9L816.8,161.9c-2.6,6.5-10.6,26.8-11,27.9h0C806.2,188.8,814.2,168.5,816.8,161.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000015316574319274771790000000568811877990197391_" class="st1" d="M815.6,94L815.6,94
							c0.1,0.4,2.7,8.6-4.7,11.8h0C818.3,102.6,815.7,94.4,815.6,94z"/>
					</defs>
					<clipPath id="SVGID_00000158729752751248537610000011751290211335356837_">
						<use xlink:href="#SVGID_00000015316574319274771790000000568811877990197391_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000158729752751248537610000011751290211335356837_);enable-background:new    ;">
						<path class="st470" d="M815.6,94L815.6,94c0.1,0.1,0.3,0.9,0.4,2h0C815.9,94.9,815.6,94.1,815.6,94"/>
						<path class="st471" d="M816,96L816,96c0.1,0.6,0.1,1.2,0.1,1.9h0C816.2,97.2,816.1,96.6,816,96"/>
						<path class="st472" d="M816.1,98L816.1,98c0,0.5,0,1-0.1,1.6h0C816.1,99,816.1,98.5,816.1,98"/>
						<path class="st473" d="M816,99.5L816,99.5c-0.1,0.4-0.2,0.9-0.3,1.4h0C815.8,100.4,815.9,100,816,99.5"/>
						<path class="st474" d="M815.7,100.9L815.7,100.9c-0.1,0.5-0.3,0.9-0.6,1.3h0C815.3,101.8,815.5,101.3,815.7,100.9"/>
						<path class="st475" d="M815.1,102.2L815.1,102.2c-0.8,1.4-2,2.7-4.1,3.6h0C813,104.9,814.3,103.6,815.1,102.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000141452163266536593540000015677556131068704384_" class="st1" d="M810.1,104l0.8,1.8
							c-7.3,3.2-10.8,4.7-11,4.8l-0.8-1.8C799.3,108.7,802.8,107.2,810.1,104z"/>
					</defs>
					<clipPath id="SVGID_00000172442276677388207250000014509741749320404911_">
						<use xlink:href="#SVGID_00000141452163266536593540000015677556131068704384_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000172442276677388207250000014509741749320404911_);enable-background:new    ;">
						<path class="st453" d="M810.1,104l0.8,1.8c-7.3,3.2-10.8,4.7-11,4.8l-0.8-1.8C799.3,108.7,802.8,107.2,810.1,104"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000047775941680150930520000014724322429741608113_" class="st1" d="M798.1,149.2l0.7-1.9
							c4.7,1.8,11.7,4.6,12.1,4.8l-0.7,1.9C809.8,153.8,802.8,151,798.1,149.2z"/>
					</defs>
					<clipPath id="SVGID_00000147210038753210590060000015108748827829584513_">
						<use xlink:href="#SVGID_00000047775941680150930520000014724322429741608113_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000147210038753210590060000015108748827829584513_);enable-background:new    ;">
						<path class="st446" d="M798.1,149.2l0.7-1.9c4.7,1.8,11.7,4.6,12.1,4.8l-0.7,1.9C809.8,153.8,802.8,151,798.1,149.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000023987172570132369500000001708254968209605515_" class="st1" d="M811,152.1L811,152.1
							c0.4,0.2,8.4,3.3,5.8,9.8h0C819.3,155.4,811.4,152.3,811,152.1z"/>
					</defs>
					<clipPath id="SVGID_00000085217476202405190460000006059755528526968998_">
						<use xlink:href="#SVGID_00000023987172570132369500000001708254968209605515_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000085217476202405190460000006059755528526968998_);enable-background:new    ;">
						<path class="st470" d="M811,152.1L811,152.1c0.1,0,0.8,0.3,1.6,0.8h0C811.7,152.4,811.1,152.2,811,152.1"/>
						<path class="st469" d="M812.5,152.9L812.5,152.9c1.5,0.9,3.7,2.4,4.5,4.8h0C816.2,155.3,814,153.7,812.5,152.9"/>
						<path class="st470" d="M817,157.7L817,157.7c0.1,0.3,0.2,0.6,0.2,1h0C817.2,158.3,817.1,158,817,157.7"/>
						<path class="st471" d="M817.2,158.7L817.2,158.7c0,0.3,0.1,0.6,0.1,0.9h0C817.3,159.3,817.3,159,817.2,158.7"/>
						<path class="st472" d="M817.3,159.6L817.3,159.6c0,0.3,0,0.6-0.1,0.9h0C817.2,160.2,817.3,159.9,817.3,159.6"/>
						<path class="st473" d="M817.2,160.5L817.2,160.5c0,0.3-0.1,0.7-0.3,1.1h0C817,161.2,817.1,160.8,817.2,160.5"/>
						<path class="st474" d="M816.9,161.5L816.9,161.5c0,0.1-0.1,0.3-0.1,0.4h0C816.8,161.8,816.9,161.7,816.9,161.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000013907629922001446400000011689068441697522097_" class="st1" d="M801.8,67.3l1.8-0.8
							c2.8,6.4,11.5,26.5,11.9,27.5l-1.8,0.8C813.3,93.7,804.6,73.7,801.8,67.3z"/>
					</defs>
					<clipPath id="SVGID_00000098913893474535409270000013986774282853996698_">
						<use xlink:href="#SVGID_00000013907629922001446400000011689068441697522097_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000098913893474535409270000013986774282853996698_);enable-background:new    ;">
						<path class="st445" d="M801.8,67.3l1.8-0.8c2.8,6.4,11.5,26.5,11.9,27.5l-1.8,0.8C813.3,93.7,804.6,73.7,801.8,67.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000014593889573086083660000001282696609135524763_" class="st1" d="M814.9,161.2l1.9,0.7
							c-2.6,6.5-10.6,26.8-11,27.9l-1.9-0.7C804.3,188,812.3,167.7,814.9,161.2z"/>
					</defs>
					<clipPath id="SVGID_00000035500426392183083280000010751093064705811366_">
						<use xlink:href="#SVGID_00000014593889573086083660000001282696609135524763_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000035500426392183083280000010751093064705811366_);enable-background:new    ;">
						<path class="st449" d="M814.9,161.2l1.9,0.7c-2.6,6.5-10.6,26.8-11,27.9l-1.9-0.7C804.3,188,812.3,167.7,814.9,161.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000131342059345024801490000004444058659469120927_" class="st1" d="M813.8,94.8l1.8-0.8
							c0.1,0.4,2.7,8.6-4.7,11.8l-0.8-1.8C815.8,101.6,813.8,94.9,813.8,94.8z"/>
					</defs>
					<clipPath id="SVGID_00000114032784743876102040000003138768929603372973_">
						<use xlink:href="#SVGID_00000131342059345024801490000004444058659469120927_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000114032784743876102040000003138768929603372973_);enable-background:new    ;">
						<path class="st445" d="M813.8,94.8l1.8-0.8c0,0.1,0.1,0.3,0.2,0.7l-1.9,0.6C813.8,95,813.8,94.8,813.8,94.8"/>
						<path class="st446" d="M813.9,95.3l1.9-0.6c0.1,0.6,0.3,1.6,0.3,2.8l-2,0C814.1,96.5,814,95.7,813.9,95.3"/>
						<path class="st447" d="M814.1,97.4l2,0c0,0.6,0,1.3-0.1,2l-2-0.4C814.1,98.4,814.1,97.9,814.1,97.4"/>
						<path class="st448" d="M814,99l2,0.4c-0.1,0.5-0.2,1-0.4,1.5l-1.9-0.7C813.9,99.8,814,99.4,814,99"/>
						<path class="st449" d="M813.8,100.1l1.9,0.7c-0.1,0.4-0.3,0.8-0.5,1.2l-1.8-1C813.5,100.8,813.7,100.5,813.8,100.1"/>
						<path class="st450" d="M813.4,101.1l1.8,1c-0.2,0.3-0.4,0.7-0.6,1l-1.6-1.2C813.1,101.6,813.2,101.4,813.4,101.1"/>
						<path class="st451" d="M812.9,101.9l1.6,1.2c-0.2,0.3-0.5,0.6-0.8,0.9l-1.4-1.4C812.5,102.4,812.7,102.2,812.9,101.9"/>
						<path class="st452" d="M812.2,102.6l1.4,1.4c-0.3,0.3-0.7,0.6-1.1,0.9l-1.2-1.6C811.7,103.1,812,102.9,812.2,102.6"/>
						<path class="st453" d="M811.4,103.3l1.2,1.6c-0.5,0.3-1,0.6-1.6,0.9l-0.8-1.8C810.6,103.8,811,103.6,811.4,103.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000008842749493908787260000010631312275475917728_" class="st1" d="M810.2,154l0.7-1.9
							c0.4,0.2,8.4,3.3,5.8,9.8l-1.9-0.7C816.8,156.7,810.6,154.1,810.2,154z"/>
					</defs>
					<clipPath id="SVGID_00000146490780781102943510000012617778547901618338_">
						<use xlink:href="#SVGID_00000008842749493908787260000010631312275475917728_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000146490780781102943510000012617778547901618338_);enable-background:new    ;">
						<path class="st446" d="M810.2,154l0.7-1.9c0.1,0,0.5,0.2,1,0.5l-0.9,1.8C810.6,154.1,810.3,154,810.2,154"/>
						<path class="st445" d="M811,154.4l0.9-1.8c1.5,0.8,4.4,2.6,5.1,5.4l-2,0.4C814.5,156.4,812.2,155,811,154.4"/>
						<path class="st446" d="M815.1,158.4l2-0.4c0.1,0.4,0.2,0.9,0.2,1.3l-2,0C815.2,159,815.2,158.7,815.1,158.4"/>
						<path class="st447" d="M815.2,159.4l2,0c0,0.3,0,0.7-0.1,1.1l-2-0.3C815.2,159.9,815.3,159.6,815.2,159.4"/>
						<path class="st448" d="M815.2,160.1l2,0.3c-0.1,0.3-0.1,0.7-0.3,1.1l-1.9-0.6C815.1,160.6,815.2,160.4,815.2,160.1"/>
						<path class="st449" d="M815,160.9l1.9,0.6c0,0.1-0.1,0.3-0.2,0.4l-1.9-0.7C814.9,161.1,815,161,815,160.9"/>
					</g>
				</g>
				<g>
					<path class="st458" d="M810.2,154c0.3,0.1,6.6,2.7,4.7,7.2c-2.6,6.5-10.6,26.8-11,27.9c0,0-2.4,4.5-6.6,4.4
						c-0.7,0-1.6-0.1-2.5-0.5c-7.4-2.9-11-4.3-11.2-4.4c0,0-2.5-1.2-5.7-1.2c-1.5,0-3.3,0.3-5.4,1.3c-4.7,5.8-9.7,11.1-15.1,15.6
						c-2.1,2.9-4.9,7.9-3.1,12.2c2,4.6,5,11.6,5.2,11.9c0.1,0.3,2.7,6.6-1.8,8.4c-6.4,2.8-26.5,11.5-27.5,11.9
						c0.2-0.1-1,0.3-2.7,0.3c-2.3,0-5-0.7-6.5-3.9c-3.2-7.3-4.7-10.8-4.8-11c-0.1-0.3-2.4-6.8-9.2-8.1c-4.2,0.4-8,0.6-11.6,0.6
						c-2.3,0-4.7-0.1-19.7-1.2c10.1,1.9,2.6,1.7,0.1,7.7c-1.8,4.7-4.6,11.7-4.8,12.1c-0.1,0.2-2.1,5.1-5.5,5c-0.5,0-1.1-0.1-1.7-0.4
						c-6.5-2.6-26.8-10.6-27.9-11c-0.1-0.1-6.3-3.4-3.9-9.1c2.9-7.4,4.3-11,4.4-11.2c0.1-0.3,3.4-7.2-1.5-13.2
						c-4.7-3.9-9.1-8-20-20.7c4.8,7.5,1.7,4-3.4,4c-1,0-2,0.2-2.9,0.6c-4.6,2-11.6,5-11.9,5.2c-0.1,0-1.9,0.8-4.1,0.8
						c-1.7,0-3.4-0.5-4.3-2.6c-2.8-6.4-11.5-26.5-11.9-27.5c0-0.1-2-6.8,3.6-9.2c7.3-3.2,10.8-4.7,11-4.8c0.3-0.1,6.9-2.5,8.1-9.3
						c-0.8-6.7-0.8-13-0.5-19.1c-0.3-2.7-1.6-10-6.6-12c-4.7-1.8-11.7-4.6-12.1-4.8c-0.3-0.1-6.6-2.7-4.7-7.2
						c2.6-6.5,10.6-26.8,11-27.9c0,0,2.4-4.5,6.6-4.4c0.7,0,1.6,0.1,2.5,0.5c7.4,2.9,11,4.3,11.2,4.4c0,0,2.5,1.2,5.7,1.2
						c1.6,0,3.4-0.3,5.6-1.3c4.8-6.1,9.9-11.7,15.5-16.3c2-3.1,4.2-7.5,2.6-11.4c-2-4.6-5-11.6-5.2-11.9c-0.1-0.3-2.7-6.6,1.8-8.4
						c6.4-2.8,26.5-11.5,27.5-11.9c-0.2,0.1,1-0.3,2.7-0.3c2.3,0,5.1,0.7,6.5,3.9c3.2,7.3,4.7,10.8,4.8,11c0.1,0.2,1.7,4.8,6.9,7.4
						c5.3-0.8,10.1-1.1,14.8-1.1c3.4,0,6.9,0.3,10.2,0.6c3.6-1,7.1-2.6,8.5-5.9c1.8-4.7,4.6-11.7,4.8-12.1c0.1-0.2,2.1-5.1,5.5-5
						c0.5,0,1.1,0.1,1.7,0.4c6.5,2.6,26.8,10.6,27.9,11c0.1,0.1,6.3,3.4,4,9.1c-2.9,7.4-4.3,11-4.4,11.2c-0.1,0.1-2,4.3-0.5,9.7
						c7.1,5.5,13.4,11.4,18.5,17.9c2.5,1.4,4.7,2.3,6.9,2.3c1,0,2-0.2,2.9-0.6c4.6-2,11.6-5,11.9-5.2c0.1,0,1.9-0.8,4.1-0.8
						c1.7,0,3.4,0.5,4.3,2.6c2.8,6.4,11.5,26.5,11.9,27.5c0,0.1,2,6.8-3.6,9.2c-7.3,3.2-10.8,4.7-11,4.8c-0.2,0.1-4.8,1.7-7.4,6.8
						c1.2,8.5,1.1,16.7,0.3,24.6c0.9,3.7,2.5,7.6,6,9C802.8,151,809.8,153.8,810.2,154z M770.9,161c16.4-41.6-4-88.7-45.6-105.1
						c-10-4.1-19.8-5.7-29.7-5.7c-32.3,0-62.8,19.4-75.4,51.3c-16.4,41.6,4,88.7,45.6,105.1c10,4.1,19.8,5.7,29.7,5.7
						C727.8,212.3,758.3,192.9,770.9,161"/>
				</g>
			</g>
		</g>
	</g>
</g>
<g class="${classes.rotatingCog}">
	<path class="st442" d="M258.8,248l-0.4-0.2c-0.6-0.3-3.5-2-6.1-5c-4-4.8-5-10.9-2.7-16.6l4.6-11.6l0,0c0.1-0.4,0.5-1.5,0.2-2.3
		c-4-3.3-7.8-6.9-11.3-10.7l-0.6-0.5c-0.4-0.4-2-1.5-3.5-2.1c-0.2-0.1-0.3-0.1-0.4-0.1l-11.2,4.8c-1.2,0.5-7.6,3.1-13.8,0.6
		c-3.5-1.4-6.2-4-7.7-7.6l-12.1-27.9l-0.1-0.4c-0.2-0.6-1.1-3.9-0.7-7.9c0.6-6.2,4.2-11.2,9.9-13.7l11.4-4.9l0,0
		c0.4-0.2,1.1-0.6,1.6-1.2c-0.6-5.8-0.8-11.8-0.4-17.6c-0.3-1.5-0.7-2.7-1-3.3l-11.3-4.5c-7.9-3.1-13.9-11-10.3-20.2L204,66.8
		l0.2-0.4c0.3-0.6,2-3.5,5-6.1c4.8-4,10.9-5,16.6-2.7l11.6,4.6l0,0c0.3,0.1,0.8,0.2,1.2,0.3c4.2-5.2,8.8-10.1,13.9-14.4
		c0.3-0.6,0.5-1.1,0.6-1.4l-4.8-11.2c-0.2-0.6-1.5-3.6-1.6-7.4c-0.2-6.4,3-11.8,8.6-14.2l27.9-12.1l0.4-0.1
		c1.1-0.3,6.8-1.9,12.6,0.4c4,1.6,7.1,4.6,8.9,8.7l4.9,11.4l0,0c0.1,0.2,0.2,0.3,0.3,0.5c7.1-0.8,14.4-0.9,21.5-0.3
		c0.1,0,0.2-0.1,0.3-0.1l4.5-11.3c3.1-7.9,11-13.9,20.2-10.3L385.2,12l0.4,0.2c0.6,0.3,3.5,2,6.1,5c4,4.8,5,10.9,2.7,16.6l-4.6,11.6
		l0,0c0,0.1-0.1,0.2-0.1,0.3c5.7,4.6,10.9,9.7,15.6,15.4c0,0,0,0,0,0l11.2-4.8c1.2-0.5,7.6-3.1,13.8-0.6c3.5,1.4,6.2,4,7.7,7.6
		l12.1,27.9l0.1,0.4c0.2,0.6,1.1,3.9,0.7,7.9c-0.6,6.2-4.2,11.2-9.9,13.7l-11.4,4.9l0,0c-0.1,0.1-0.3,0.2-0.5,0.3
		c0.7,7,0.8,14.1,0.1,21.2c0.1,0.3,0.2,0.5,0.3,0.6l11.3,4.5c7.9,3.1,13.9,11,10.3,20.2L440,193.2l-0.2,0.4c-0.3,0.6-2,3.5-5,6.1
		c-4.8,4-10.9,5-16.6,2.7l-11.6-4.6l0,0c-0.3-0.1-0.8-0.2-1.2-0.3c-4.1,5-8.6,9.6-13.6,13.7c-0.6,0.9-0.8,1.6-1,2l4.8,11.2
		c0.2,0.6,1.5,3.6,1.6,7.4c0.2,6.4-3,11.8-8.6,14.2l-27.9,12.1l-0.4,0.1c-1.1,0.3-6.8,1.9-12.6-0.4c-4-1.6-7.1-4.6-8.9-8.7l-5-11.4
		l0,0c-0.2-0.5-0.7-1.2-1.2-1.5c-5.6,0.5-11.3,0.6-16.9,0.3c-1.4,0.2-3.1,0.7-4,1.1l-4.5,11.3c-3.1,7.9-11,13.9-20.2,10.3L258.8,248
		z M348.6,65.3c-36.4-14.4-77.7,3.6-92.1,40c-14.4,36.4,3.6,77.7,40,92.1s77.7-3.6,92.1-40C403,120.9,385,79.6,348.6,65.3z"/>
	<g>
		<g>
			<g class="st1">
				<g class="st1">
					<defs>
						<path id="SVGID_00000013902751250540809090000000399978806602461115_" class="st1" d="M243.7,187.8c0.6,1,1.2,2.1,2.5,4.3
							c-1.3-2.2-3-5.1-4.3-7.2C242.7,186.1,243.3,187.1,243.7,187.8z"/>
					</defs>
					<clipPath id="SVGID_00000157285710246902172090000011187437594544315020_">
						<use xlink:href="#SVGID_00000013902751250540809090000000399978806602461115_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000157285710246902172090000011187437594544315020_);enable-background:new    ;">
						<path class="st444" d="M246.1,192.1l-4.3-7.2c0,0,0,0,0,0L246.1,192.1C246.1,192.1,246.1,192.1,246.1,192.1"/>
						<path class="st445" d="M246.1,192.1l-4.3-7.2c0,0,0,0,0,0L246.1,192.1C246.1,192.1,246.1,192.1,246.1,192.1"/>
						<path class="st446" d="M246.1,192.1l-4.2-7.2c0,0,0,0,0,0L246.1,192.1C246.2,192.1,246.1,192.1,246.1,192.1"/>
						<path class="st447" d="M246.2,192.1l-4.2-7.2c0,0,0,0,0,0L246.2,192.1C246.2,192.1,246.2,192.1,246.2,192.1"/>
						<path class="st448" d="M246.2,192.1l-4.2-7.2c0,0,0,0,0,0L246.2,192.1C246.2,192.1,246.2,192.1,246.2,192.1"/>
						<path class="st449" d="M246.2,192.1L242,185c0,0,0,0,0,0L246.2,192.1C246.2,192.1,246.2,192.1,246.2,192.1"/>
						<path class="st450" d="M246.2,192.1L242,185c0,0,0,0,0,0L246.2,192.1C246.2,192.1,246.2,192.1,246.2,192.1"/>
						<path class="st451" d="M246.2,192.1L242,185c0,0,0,0,0,0L246.2,192.1C246.2,192.1,246.2,192.1,246.2,192.1"/>
						<path class="st452" d="M246.2,192.1l-4.1-7.1c0,0,0,0,0,0L246.2,192.1C246.2,192.1,246.2,192.1,246.2,192.1"/>
						<path class="st453" d="M246.2,192.1l-4.1-7c0,0,0,0,0,0L246.2,192.1C246.2,192.2,246.2,192.1,246.2,192.1"/>
						<path class="st454" d="M246.2,192.2l-4.1-7c0,0,0,0,0,0L246.2,192.2C246.2,192.2,246.2,192.2,246.2,192.2"/>
						<path class="st455" d="M246.2,192.2l-4.1-7c0,0,0,0,0,0L246.2,192.2C246.2,192.2,246.2,192.2,246.2,192.2"/>
						<path class="st456" d="M246.2,192.2l-4.1-7c0,0,0,0,0,0L246.2,192.2C246.2,192.2,246.2,192.2,246.2,192.2"/>
						<path class="st457" d="M246.2,192.2l-4.1-6.9c0,0,0,0,0,0L246.2,192.2C246.2,192.2,246.2,192.2,246.2,192.2"/>
						<path class="st458" d="M246.2,192.2l-4.1-6.9c0,0,0,0,0,0L246.2,192.2C246.2,192.2,246.2,192.2,246.2,192.2"/>
						<path class="st459" d="M246.2,192.2l-4-6.9c0,0,0,0,0,0L246.2,192.2C246.2,192.2,246.2,192.2,246.2,192.2"/>
						<path class="st460" d="M246.2,192.2l-4-6.9c0,0,0,0,0,0L246.2,192.2C246.2,192.2,246.2,192.2,246.2,192.2"/>
						<path class="st461" d="M246.2,192.2l-4-6.8c0,0,0,0,0,0L246.2,192.2C246.2,192.2,246.2,192.2,246.2,192.2"/>
						<path class="st462" d="M246.2,192.2l-4-6.8c0,0,0,0,0,0L246.2,192.2C246.2,192.2,246.2,192.2,246.2,192.2"/>
						<path class="st463" d="M246.2,192.2l-4-6.8c0,0,0,0,0,0L246.2,192.2C246.2,192.2,246.2,192.2,246.2,192.2"/>
						<path class="st464" d="M246.2,192.2l-4-6.8c0,0,0,0,0,0L246.2,192.2C246.2,192.3,246.2,192.3,246.2,192.2"/>
						<path class="st465" d="M246.2,192.3l-3.9-6.7c0,0,0,0,0,0L246.2,192.3C246.3,192.3,246.3,192.3,246.2,192.3"/>
						<path class="st466" d="M246.3,192.3l-3.9-6.7c0,0,0,0,0,0L246.3,192.3C246.3,192.3,246.3,192.3,246.3,192.3"/>
						<path class="st467" d="M246.3,192.3l-3.9-6.7c0,0,0,0,0,0L246.3,192.3C246.3,192.3,246.3,192.3,246.3,192.3"/>
						<path class="st468" d="M246.3,192.3l-3.9-6.7c0,0,0,0,0,0L246.3,192.3C246.3,192.3,246.3,192.3,246.3,192.3"/>
						<path class="st469" d="M246.3,192.3l-3.9-6.6c0,0,0,0,0,0L246.3,192.3C246.3,192.3,246.3,192.3,246.3,192.3"/>
						<path class="st470" d="M246.3,192.3l-3.9-6.6c0,0,0,0,0,0L246.3,192.3C246.3,192.3,246.3,192.3,246.3,192.3"/>
						<path class="st471" d="M246.3,192.3l-3.8-6.6c0,0,0,0,0,0L246.3,192.3C246.3,192.3,246.3,192.3,246.3,192.3"/>
						<path class="st472" d="M246.3,192.3l-3.8-6.6c0,0,0,0,0,0L246.3,192.3C246.3,192.3,246.3,192.3,246.3,192.3"/>
						<path class="st473" d="M246.3,192.3l-3.8-6.6c0,0,0,0,0,0L246.3,192.3C246.3,192.3,246.3,192.3,246.3,192.3"/>
						<path class="st474" d="M246.3,192.3l-3.8-6.5c0,0,0,0,0,0L246.3,192.3C246.3,192.3,246.3,192.3,246.3,192.3"/>
						<path class="st475" d="M246.3,192.3l-3.8-6.5c0.5,0.8,0.9,1.4,1.1,1.9l2.7,4.9C246.4,192.7,246.4,192.5,246.3,192.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000124144036069576552810000003660636173222092989_" class="st1" d="M203.2,165.2l-1.8,0.8
							c-0.1-0.4-2.7-8.6,4.7-11.8l0.8,1.8C201.2,158.4,203.2,165.1,203.2,165.2z"/>
					</defs>
					<clipPath id="SVGID_00000157993710274167264090000010477818695264274086_">
						<use xlink:href="#SVGID_00000124144036069576552810000003660636173222092989_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000157993710274167264090000010477818695264274086_);enable-background:new    ;">
						<path class="st456" d="M203.2,165.2l-1.8,0.8c-0.1-0.2-0.7-2.4-0.5-4.9l2,0.3C202.7,163.4,203.2,165.1,203.2,165.2"/>
						<path class="st455" d="M202.9,161.5l-2-0.3c0.1-0.8,0.3-1.7,0.6-2.6l1.8,0.9C203.1,160.1,203,160.8,202.9,161.5"/>
						<path class="st454" d="M203.4,159.4l-1.8-0.9c0.2-0.5,0.5-1.1,0.9-1.6l1.6,1.2C203.8,158.6,203.5,159,203.4,159.4"/>
						<path class="st453" d="M204,158.2l-1.6-1.2c0.3-0.4,0.6-0.8,1-1.1l1.4,1.4C204.5,157.6,204.3,157.9,204,158.2"/>
						<path class="st452" d="M204.8,157.3l-1.4-1.4c0.3-0.3,0.7-0.6,1.2-0.9l1.1,1.6C205.4,156.8,205.1,157.1,204.8,157.3"/>
						<path class="st451" d="M205.7,156.6l-1.1-1.6c0.4-0.3,0.9-0.5,1.4-0.8l0.8,1.8C206.4,156.2,206,156.4,205.7,156.6"/>
						<path class="st450" d="M206.8,156l-0.8-1.8c0,0,0.1,0,0.1,0L206.8,156C206.8,156,206.8,156,206.8,156"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000058588212452597948460000009962311977947111600_" class="st1" d="M202.1,98.8l-1.9-0.7
							c2.6-6.5,10.6-26.8,11-27.9l1.9,0.7C212.7,72,204.7,92.3,202.1,98.8z"/>
					</defs>
					<clipPath id="SVGID_00000066498402870921841820000001569666137038144421_">
						<use xlink:href="#SVGID_00000058588212452597948460000009962311977947111600_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000066498402870921841820000001569666137038144421_);enable-background:new    ;">
						<path class="st455" d="M202.1,98.8l-1.9-0.7c2.6-6.5,10.6-26.8,11-27.9l1.9,0.7C212.7,72,204.7,92.3,202.1,98.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000135673194479915519930000010075716391976164772_" class="st1" d="M219.7,64.4L219.7,64.4
							c1,0,2.1,0.2,3.2,0.6l0,0C221.8,64.6,220.7,64.4,219.7,64.4z"/>
					</defs>
					<clipPath id="SVGID_00000052084726572826208620000015236363817101129398_">
						<use xlink:href="#SVGID_00000135673194479915519930000010075716391976164772_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000052084726572826208620000015236363817101129398_);enable-background:new    ;">
						<path class="st473" d="M219.7,64.4L219.7,64.4c0.1,0,0.2,0,0.3,0h0C219.9,64.4,219.8,64.4,219.7,64.4"/>
						<path class="st472" d="M220,64.4L220,64.4c0.4,0,0.8,0.1,1.2,0.1h0C220.7,64.5,220.3,64.5,220,64.4"/>
						<path class="st471" d="M221.1,64.6L221.1,64.6c0.4,0.1,0.9,0.2,1.4,0.4h0C222,64.8,221.6,64.6,221.1,64.6"/>
						<path class="st470" d="M222.5,64.9L222.5,64.9c0.2,0,0.3,0.1,0.4,0.2h0C222.8,65,222.6,65,222.5,64.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000091697630831181207550000011717289392048671164_" class="st1" d="M215.1,192.7l-1.8,0.8
							c-2.8-6.4-11.5-26.5-11.9-27.5l1.8-0.8C203.7,166.3,212.4,186.3,215.1,192.7z"/>
					</defs>
					<clipPath id="SVGID_00000073722994862944779090000008890321636320489656_">
						<use xlink:href="#SVGID_00000091697630831181207550000011717289392048671164_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000073722994862944779090000008890321636320489656_);enable-background:new    ;">
						<path class="st457" d="M215.1,192.7l-1.8,0.8c-2.8-6.4-11.5-26.5-11.9-27.5l1.8-0.8C203.7,166.3,212.4,186.3,215.1,192.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000115483335838364771860000001199949215749738131_" class="st1" d="M224.4,196.4L224.4,196.4
							c-0.1,0-2.3,1-4.9,1h0C222.1,197.4,224.2,196.4,224.4,196.4z"/>
					</defs>
					<clipPath id="SVGID_00000016779159916725465650000012947674560030722475_">
						<use xlink:href="#SVGID_00000115483335838364771860000001199949215749738131_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000016779159916725465650000012947674560030722475_);enable-background:new    ;">
						<path class="st475" d="M224.4,196.4L224.4,196.4c-0.1,0-2.3,1-4.9,1h0C222.1,197.4,224.2,196.4,224.4,196.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000090996591084560192080000002512293857951895202_" class="st1" d="M206.8,156l-0.8-1.8
							c7.3-3.2,10.8-4.7,11-4.8l0.8,1.8C217.7,151.3,214.2,152.8,206.8,156z"/>
					</defs>
					<clipPath id="SVGID_00000166642934115898933800000004686062385015847821_">
						<use xlink:href="#SVGID_00000090996591084560192080000002512293857951895202_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000166642934115898933800000004686062385015847821_);enable-background:new    ;">
						<path class="st450" d="M206.8,156l-0.8-1.8c7.3-3.2,10.8-4.7,11-4.8l0.8,1.8C217.7,151.3,214.2,152.8,206.8,156"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000107553125223730655670000008363848155325610370_" class="st1" d="M218.9,110.8l-0.7,1.9
							c-4.7-1.8-11.7-4.6-12.1-4.8c-0.4-0.2-8.4-3.3-5.8-9.8l1.9,0.7c-1.9,4.5,4.3,7.1,4.7,7.2C207.1,106.2,214.2,109,218.9,110.8z"
							/>
					</defs>
					<clipPath id="SVGID_00000008128159277903017950000018001142726836799411_">
						<use xlink:href="#SVGID_00000107553125223730655670000008363848155325610370_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000008128159277903017950000018001142726836799411_);enable-background:new    ;">
						<path class="st456" d="M218.9,110.8l-0.7,1.9c-4.7-1.8-11.7-4.6-12.1-4.8c-0.2-0.1-1.9-0.7-3.5-2l1.5-1.4
							c1.3,1,2.6,1.5,2.7,1.6C207.1,106.2,214.2,109,218.9,110.8"/>
						<path class="st457" d="M204,104.4l-1.5,1.4c-0.8-0.7-1.6-1.5-2.1-2.5l1.9-0.8C202.8,103.3,203.4,103.9,204,104.4"/>
						<path class="st456" d="M202.3,102.6l-1.9,0.8c-0.5-1-0.8-2.1-0.7-3.5l2,0.2C201.7,101,201.9,101.8,202.3,102.6"/>
						<path class="st455" d="M201.8,100.1l-2-0.2c0.1-0.6,0.2-1.2,0.5-1.8l1.9,0.7C201.9,99.2,201.8,99.7,201.8,100.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000025423030418209637910000016086656651281191064_" class="st1" d="M213.1,70.9l-1.9-0.7
							c0.2-0.3,3-5.7,8.4-5.7c1,0,2.1,0.2,3.2,0.6l-0.7,1.9c-0.9-0.4-1.7-0.5-2.5-0.5C215.5,66.4,213.1,70.9,213.1,70.9z"/>
					</defs>
					<clipPath id="SVGID_00000103258918910037141700000006848487079832551855_">
						<use xlink:href="#SVGID_00000025423030418209637910000016086656651281191064_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000103258918910037141700000006848487079832551855_);enable-background:new    ;">
						<path class="st454" d="M213.1,70.9l-1.9-0.7c0-0.1,0.3-0.6,0.8-1.3l1.6,1.1C213.3,70.5,213.1,70.9,213.1,70.9"/>
						<path class="st453" d="M213.7,70l-1.6-1.1c0.4-0.6,1-1.3,1.7-1.9l1.3,1.5C214.5,69,214,69.5,213.7,70"/>
						<path class="st452" d="M215,68.5l-1.3-1.5c0.4-0.4,0.9-0.8,1.5-1.1l1,1.7C215.8,67.8,215.4,68.1,215,68.5"/>
						<path class="st451" d="M216.2,67.5l-1-1.7c0.4-0.3,0.8-0.5,1.3-0.7l0.7,1.9C216.9,67.1,216.5,67.3,216.2,67.5"/>
						<path class="st450" d="M217.2,67l-0.7-1.9c0.4-0.2,0.8-0.3,1.2-0.4l0.5,1.9C217.8,66.7,217.5,66.8,217.2,67"/>
						<path class="st449" d="M218.2,66.6l-0.5-1.9c0.4-0.1,0.8-0.2,1.2-0.2l0.2,2C218.8,66.5,218.5,66.6,218.2,66.6"/>
						<path class="st448" d="M219.1,66.5l-0.2-2c0.3,0,0.5,0,0.8,0c0.2,0,0.3,0,0.5,0l-0.1,2c-0.1,0-0.3,0-0.4,0
							C219.5,66.4,219.3,66.4,219.1,66.5"/>
						<path class="st447" d="M220.1,66.5l0.1-2c0.5,0,1,0.1,1.5,0.2l-0.5,1.9C220.8,66.5,220.4,66.5,220.1,66.5"/>
						<path class="st446" d="M221.2,66.6l0.5-1.9c0.4,0.1,0.8,0.2,1.2,0.4l-0.7,1.9C221.8,66.8,221.5,66.7,221.2,66.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000104692940817500176680000017367332458876823683_" class="st1" d="M222.9,65.1L222.9,65.1
							c7.5,2.9,11,4.3,11.2,4.4l0,0C233.9,69.4,230.4,68,222.9,65.1z"/>
					</defs>
					<clipPath id="SVGID_00000113324410109268998420000013345991980710595247_">
						<use xlink:href="#SVGID_00000104692940817500176680000017367332458876823683_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000113324410109268998420000013345991980710595247_);enable-background:new    ;">
						<path class="st470" d="M222.9,65.1L222.9,65.1c7.5,2.9,11,4.3,11.2,4.4h0C233.9,69.4,230.4,68,222.9,65.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000046312121564609382750000013761124998221054342_" class="st1" d="M225.5,122.8l-2-0.1
							c-0.3-2.1-1.5-8.5-5.3-10l0.7-1.9C223.9,112.9,225.1,120.1,225.5,122.8z"/>
					</defs>
					<clipPath id="SVGID_00000065073703992644273260000010238858769735048850_">
						<use xlink:href="#SVGID_00000046312121564609382750000013761124998221054342_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000065073703992644273260000010238858769735048850_);enable-background:new    ;">
						<path class="st456" d="M225.5,122.8l-2-0.1c-0.1-1-0.5-3-1.2-5l1.8-0.8C224.9,119.2,225.3,121.5,225.5,122.8"/>
						<path class="st457" d="M224,116.8l-1.8,0.8c-0.7-1.8-1.7-3.5-3.1-4.5l1-1.7C222,112.7,223.2,114.8,224,116.8"/>
						<path class="st456" d="M220.1,111.5l-1,1.7c-0.3-0.2-0.6-0.4-1-0.5l0.7-1.9C219.3,111,219.7,111.2,220.1,111.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000124133373762938671070000004132960484241281441_" class="st1" d="M217.8,151.2l-0.8-1.8
							c0.3-0.1,5.9-2.1,6.9-7.9l2,0.4C224.7,148.8,218.1,151.1,217.8,151.2z"/>
					</defs>
					<clipPath id="SVGID_00000142885673058360355110000000001537638749255821_">
						<use xlink:href="#SVGID_00000124133373762938671070000004132960484241281441_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000142885673058360355110000000001537638749255821_);enable-background:new    ;">
						<path class="st450" d="M217.8,151.2l-0.8-1.8c0,0,0.2-0.1,0.5-0.2l0.8,1.8C218.1,151.1,217.9,151.2,217.8,151.2"/>
						<path class="st451" d="M218.4,151l-0.8-1.8c0.4-0.2,1.1-0.5,1.8-1l1.1,1.7C219.6,150.4,218.9,150.8,218.4,151"/>
						<path class="st452" d="M220.5,149.9l-1.1-1.7c0.5-0.3,1.1-0.8,1.7-1.3l1.4,1.4C221.7,149,221.1,149.5,220.5,149.9"/>
						<path class="st453" d="M222.4,148.4l-1.4-1.4c0.5-0.4,0.9-1,1.4-1.5l1.7,1.1C223.5,147.2,223,147.8,222.4,148.4"/>
						<path class="st454" d="M224,146.5l-1.7-1.1c0.4-0.6,0.8-1.2,1.1-1.9l1.9,0.8C224.9,145.1,224.5,145.8,224,146.5"/>
						<path class="st455" d="M225.3,144.2l-1.9-0.8c0.3-0.6,0.4-1.3,0.6-2l2,0.4C225.8,142.7,225.6,143.5,225.3,144.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000085952371587370928210000000851286963790328231_" class="st1" d="M226,141.9l-2-0.4
							c-0.8-6.2-1-12.5-0.5-18.8l2,0.1C225.2,128.9,225.2,135.2,226,141.9z"/>
					</defs>
					<clipPath id="SVGID_00000090987807226754941490000003785166545833516720_">
						<use xlink:href="#SVGID_00000085952371587370928210000000851286963790328231_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000090987807226754941490000003785166545833516720_);enable-background:new    ;">
						<path class="st456" d="M226,141.9l-2-0.4c-0.8-6.2-1-12.5-0.5-18.8l2,0.1C225.2,128.9,225.2,135.2,226,141.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000151522607168016430290000007148140370112448900_" class="st1" d="M236.3,191.2L236.3,191.2
							c-4.6,2-11.5,5-11.9,5.2h0C224.7,196.2,231.7,193.2,236.3,191.2z"/>
					</defs>
					<clipPath id="SVGID_00000152238421988388115180000003704813819147525296_">
						<use xlink:href="#SVGID_00000151522607168016430290000007148140370112448900_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000152238421988388115180000003704813819147525296_);enable-background:new    ;">
						<path class="st475" d="M236.3,191.2L236.3,191.2c-4.6,2-11.5,5-11.9,5.2h0C224.7,196.2,231.7,193.2,236.3,191.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000119117823323591498810000009181733676779728568_" class="st1" d="M238.4,190.8L238.4,190.8
							c-0.7,0-1.5,0.1-2.1,0.4h0C237,190.9,237.7,190.8,238.4,190.8z"/>
					</defs>
					<clipPath id="SVGID_00000166661352891269995960000017488566284529518783_">
						<use xlink:href="#SVGID_00000119117823323591498810000009181733676779728568_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000166661352891269995960000017488566284529518783_);enable-background:new    ;">
						<path class="st475" d="M238.4,190.8L238.4,190.8c-0.7,0-1.5,0.1-2.1,0.4h0C237,190.9,237.7,190.8,238.4,190.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000101811867783474648710000008882266708327007872_" class="st1" d="M234.1,69.5L234.1,69.5
							c0.1,0.1,2.2,1,5,1l0,0C236.3,70.5,234.2,69.5,234.1,69.5z"/>
					</defs>
					<clipPath id="SVGID_00000045608637976993396660000005240177883080906686_">
						<use xlink:href="#SVGID_00000101811867783474648710000008882266708327007872_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000045608637976993396660000005240177883080906686_);enable-background:new    ;">
						<path class="st470" d="M234.1,69.5L234.1,69.5c0.1,0,0.3,0.2,0.8,0.3h0C234.4,69.7,234.1,69.5,234.1,69.5"/>
						<path class="st471" d="M234.9,69.8L234.9,69.8c0.5,0.2,1.1,0.3,1.9,0.5h0C236,70.2,235.4,70,234.9,69.8"/>
						<path class="st472" d="M236.8,70.3L236.8,70.3c0.6,0.1,1.2,0.2,1.8,0.2h0C238,70.5,237.3,70.4,236.8,70.3"/>
						<path class="st473" d="M238.6,70.5L238.6,70.5c0.1,0,0.3,0,0.4,0h0C238.9,70.5,238.8,70.5,238.6,70.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000129897760408564253260000003068940858147115913_" class="st1" d="M222.2,66.9l0.7-1.9
							c7.4,2.9,11,4.3,11.2,4.4l-0.7,1.9C233.2,71.3,229.6,69.9,222.2,66.9z"/>
					</defs>
					<clipPath id="SVGID_00000137816214634051525660000007628693740267577764_">
						<use xlink:href="#SVGID_00000129897760408564253260000003068940858147115913_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000137816214634051525660000007628693740267577764_);enable-background:new    ;">
						<path class="st446" d="M222.2,66.9l0.7-1.9c7.4,2.9,11,4.3,11.2,4.4l-0.7,1.9C233.2,71.3,229.6,69.9,222.2,66.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000115477666199536432230000009258811261501705091_" class="st1" d="M286.8,11.2c-0.2-0.4-0.4-0.9-0.5-1.3
							L286.8,11.2C286.8,11.2,286.8,11.2,286.8,11.2z"/>
					</defs>
					<clipPath id="SVGID_00000041292642600103495470000009765831713808327870_">
						<use xlink:href="#SVGID_00000115477666199536432230000009258811261501705091_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000041292642600103495470000009765831713808327870_);enable-background:new    ;">
						<polyline class="st454" points="286.2,9.9 286.8,11.2 286.8,11.2 286.2,9.9 286.2,9.9 						"/>
						<polyline class="st455" points="286.2,9.9 286.8,11.2 286.8,11.2 286.2,9.9 286.2,9.9 						"/>
						<polyline class="st456" points="286.2,9.9 286.8,11.2 286.8,11.2 286.2,9.9 286.2,9.9 						"/>
						<polyline class="st457" points="286.2,9.9 286.8,11.2 286.8,11.2 286.2,9.9 286.2,9.9 						"/>
						<polyline class="st458" points="286.2,9.9 286.8,11.2 286.8,11.2 286.2,9.9 286.2,9.9 						"/>
						<polyline class="st459" points="286.2,9.9 286.8,11.2 286.8,11.2 286.2,9.9 286.2,9.9 						"/>
						<polyline class="st460" points="286.2,9.9 286.8,11.2 286.8,11.2 286.2,9.9 286.2,9.9 						"/>
						<polyline class="st461" points="286.2,9.9 286.8,11.2 286.8,11.2 286.2,9.9 286.2,9.9 						"/>
						<polyline class="st462" points="286.2,9.9 286.8,11.2 286.8,11.2 286.2,9.9 286.2,9.9 						"/>
						<polyline class="st463" points="286.2,9.9 286.8,11.2 286.8,11.2 286.2,9.9 286.2,9.9 						"/>
						<polyline class="st464" points="286.2,9.9 286.8,11.2 286.8,11.2 286.2,9.9 286.2,9.9 						"/>
						<polyline class="st465" points="286.2,9.9 286.8,11.2 286.8,11.2 286.2,9.9 286.2,9.9 						"/>
						<polyline class="st466" points="286.2,9.9 286.8,11.2 286.8,11.2 286.2,9.9 286.2,9.9 						"/>
						<polyline class="st467" points="286.2,9.9 286.8,11.2 286.8,11.2 286.2,9.9 286.2,9.9 						"/>
						<polyline class="st468" points="286.2,9.9 286.8,11.2 286.8,11.2 286.3,9.9 286.2,9.9 						"/>
						<polyline class="st469" points="286.3,9.9 286.8,11.2 286.8,11.2 286.3,9.9 286.3,9.9 						"/>
						<polyline class="st470" points="286.3,9.9 286.8,11.2 286.8,11.2 286.3,9.9 286.3,9.9 						"/>
						<polyline class="st471" points="286.3,9.9 286.8,11.2 286.8,11.2 286.3,9.9 286.3,9.9 						"/>
						<polyline class="st472" points="286.3,9.9 286.8,11.2 286.8,11.2 286.3,9.9 286.3,9.9 						"/>
						<polyline class="st473" points="286.3,9.9 286.8,11.2 286.8,11.2 286.3,9.9 286.3,9.9 						"/>
						<polyline class="st474" points="286.3,9.9 286.8,11.2 286.8,11.2 286.3,9.9 286.3,9.9 						"/>
						<polyline class="st475" points="286.3,9.9 286.8,11.2 286.8,11.2 286.3,9.9 286.3,9.9 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000103253156719119514920000015679274592774809991_" class="st1" d="M235.5,189.4l0.8,1.8
							c-4.6,2-11.6,5-11.9,5.2c-0.1,0-2.3,1-4.9,1c-2.3,0-4.8-0.8-6.2-3.8l1.8-0.8c0.9,2.1,2.6,2.6,4.3,2.6c2.2,0,4-0.8,4.1-0.8
							C223.9,194.4,230.9,191.3,235.5,189.4z"/>
					</defs>
					<clipPath id="SVGID_00000122693047332091515210000017084204682836698756_">
						<use xlink:href="#SVGID_00000103253156719119514920000015679274592774809991_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000122693047332091515210000017084204682836698756_);enable-background:new    ;">
						<path class="st453" d="M235.5,189.4l0.8,1.8c-4.6,2-11.6,5-11.9,5.2c0,0,0,0,0,0l-0.8-1.8c0,0,0,0,0,0
							C223.9,194.4,230.9,191.3,235.5,189.4"/>
						<path class="st454" d="M223.5,194.5l0.8,1.8c-0.2,0.1-1.3,0.5-2.7,0.8l-0.3-2C222.5,195,223.4,194.6,223.5,194.5"/>
						<path class="st455" d="M221.3,195.2l0.3,2c-0.6,0.1-1.4,0.2-2.1,0.2c-0.3,0-0.7,0-1-0.1l0.3-2c0.3,0,0.5,0,0.8,0
							C220.1,195.4,220.7,195.3,221.3,195.2"/>
						<path class="st456" d="M218.7,195.3l-0.3,2c-1.1-0.1-2.2-0.5-3.2-1.2l1.2-1.6C217.1,195,217.9,195.2,218.7,195.3"/>
						<path class="st457" d="M216.5,194.5l-1.2,1.6c-0.8-0.6-1.5-1.4-2-2.6l1.8-0.8C215.5,193.5,216,194.1,216.5,194.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000081611494719165710700000002854216964805020298_" class="st1" d="M247.5,194.5L247.5,194.5
							C247.5,194.5,247.5,194.5,247.5,194.5L247.5,194.5C247.6,194.5,247.5,194.5,247.5,194.5z"/>
					</defs>
					<clipPath id="SVGID_00000051371783953751917350000006825555363487536054_">
						<use xlink:href="#SVGID_00000081611494719165710700000002854216964805020298_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000051371783953751917350000006825555363487536054_);enable-background:new    ;">
						<polyline class="st469" points="247.5,194.4 247.5,194.4 247.5,194.4 247.5,194.4 247.5,194.4 						"/>
						<path class="st470" d="M247.5,194.4L247.5,194.4C247.5,194.4,247.5,194.4,247.5,194.4L247.5,194.4
							C247.5,194.4,247.5,194.4,247.5,194.4"/>
						<polyline class="st471" points="247.5,194.4 247.5,194.4 247.5,194.5 247.5,194.5 247.5,194.4 						"/>
						<polyline class="st472" points="247.5,194.5 247.5,194.5 247.5,194.5 247.5,194.5 247.5,194.5 						"/>
						<polyline class="st473" points="247.5,194.5 247.5,194.5 247.5,194.5 247.5,194.5 247.5,194.5 						"/>
						<polyline class="st474" points="247.5,194.5 247.5,194.5 247.5,194.5 247.5,194.5 247.5,194.5 						"/>
						<path class="st475" d="M247.5,194.5L247.5,194.5C247.5,194.5,247.5,194.5,247.5,194.5L247.5,194.5
							C247.5,194.5,247.5,194.5,247.5,194.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000152248921636418967210000001025025737134802873_" class="st1" d="M233.3,71.4l0.7-1.9
							c0.1,0.1,2.2,1,4.9,1c1.3,0,2.8-0.2,4.2-0.9l1.3,1.6c-2.2,1.1-4,1.3-5.6,1.3C235.8,72.5,233.4,71.4,233.3,71.4z"/>
					</defs>
					<clipPath id="SVGID_00000073715805467110194940000007687402093364684174_">
						<use xlink:href="#SVGID_00000152248921636418967210000001025025737134802873_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000073715805467110194940000007687402093364684174_);enable-background:new    ;">
						<path class="st446" d="M233.3,71.4l0.7-1.9c0.1,0,0.8,0.4,1.9,0.6l-0.5,1.9C234.2,71.7,233.4,71.4,233.3,71.4"/>
						<path class="st447" d="M235.5,72.1l0.5-1.9c0.6,0.2,1.4,0.3,2.3,0.4l-0.1,2C237.1,72.4,236.2,72.3,235.5,72.1"/>
						<path class="st448" d="M238.1,72.5l0.1-2c0.3,0,0.5,0,0.8,0c0.3,0,0.7,0,1,0l0.2,2c-0.4,0-0.8,0.1-1.2,0.1
							C238.7,72.5,238.4,72.5,238.1,72.5"/>
						<path class="st449" d="M240.3,72.5l-0.2-2c0.5,0,1-0.1,1.5-0.3l0.6,1.9C241.5,72.3,240.9,72.4,240.3,72.5"/>
						<path class="st450" d="M242.2,72.1l-0.6-1.9c0.5-0.1,0.9-0.3,1.4-0.5l1.2,1.7C243.4,71.7,242.8,71.9,242.2,72.1"/>
						<path class="st451" d="M244.1,71.4l-1.2-1.7c0.1,0,0.2-0.1,0.3-0.1l1.3,1.6C244.4,71.3,244.3,71.3,244.1,71.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000043436999975842660380000002045104488387218599_" class="st1" d="M243.6,187.8c0.6,1,0.6,1,3.9,6.7
							c0.2,0.5-4.8-3.7-9.1-3.7c-0.7,0-1.5,0.1-2.1,0.4l-0.8-1.8c1-0.4,2-0.6,2.9-0.6C242.7,188.8,245.5,191.2,243.6,187.8z"/>
					</defs>
					<clipPath id="SVGID_00000125573433177063619730000007403274556245781635_">
						<use xlink:href="#SVGID_00000043436999975842660380000002045104488387218599_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000125573433177063619730000007403274556245781635_);enable-background:new    ;">
						<path class="st475" d="M241.9,184.8l5.7,9.6c0,0,0,0,0,0L241.9,184.8C241.9,184.9,241.9,184.8,241.9,184.8"/>
						<path class="st474" d="M241.9,184.9l5.6,9.6c0,0,0,0,0,0L241.9,184.9C241.9,184.9,241.9,184.9,241.9,184.9"/>
						<path class="st473" d="M241.9,184.9l5.6,9.5c0,0,0,0,0,0L241.9,184.9C241.9,185,241.9,184.9,241.9,184.9"/>
						<path class="st472" d="M242,185l5.6,9.5c0,0,0,0,0,0L242,185C242,185,242,185,242,185"/>
						<path class="st471" d="M242,185l5.5,9.4c0,0,0,0,0,0L242,185C242,185.1,242,185,242,185"/>
						<path class="st470" d="M242,185.1l5.5,9.4c0,0,0,0,0,0L242,185.1C242,185.1,242,185.1,242,185.1"/>
						<path class="st469" d="M242.1,185.1l5.5,9.3c0,0,0,0,0,0L242.1,185.1C242.1,185.2,242.1,185.2,242.1,185.1"/>
						<path class="st468" d="M242.1,185.2l5.4,9.3c0,0,0,0,0,0L242.1,185.2C242.1,185.2,242.1,185.2,242.1,185.2"/>
						<path class="st467" d="M242.1,185.2l5.4,9.2c0,0,0,0,0,0L242.1,185.2C242.1,185.3,242.1,185.3,242.1,185.2"/>
						<path class="st466" d="M242.2,185.3l5.4,9.2c0,0,0,0,0,0L242.2,185.3C242.2,185.3,242.2,185.3,242.2,185.3"/>
						<path class="st465" d="M242.2,185.3l5.3,9.1c0,0,0,0,0,0L242.2,185.3C242.2,185.4,242.2,185.4,242.2,185.3"/>
						<path class="st464" d="M242.2,185.4l5.3,9.1c0,0,0,0,0,0L242.2,185.4C242.2,185.4,242.2,185.4,242.2,185.4"/>
						<path class="st463" d="M242.2,185.4l5.3,9c0,0,0,0,0,0L242.2,185.4C242.3,185.5,242.3,185.5,242.2,185.4"/>
						<path class="st462" d="M242.3,185.5l5.3,9c0,0,0,0,0,0L242.3,185.5C242.3,185.5,242.3,185.5,242.3,185.5"/>
						<path class="st461" d="M242.3,185.5l5.2,8.9c0,0,0,0,0,0L242.3,185.5C242.3,185.6,242.3,185.6,242.3,185.5"/>
						<path class="st460" d="M242.3,185.6l5.2,8.9c0,0,0,0,0,0L242.3,185.6C242.4,185.6,242.4,185.6,242.3,185.6"/>
						<path class="st459" d="M242.4,185.6l5.2,8.8c0,0,0,0,0,0L242.4,185.6C242.4,185.7,242.4,185.7,242.4,185.6"/>
						<path class="st458" d="M242.4,185.7l5.1,8.8c0,0,0,0,0,0L242.4,185.7C242.4,185.7,242.4,185.7,242.4,185.7"/>
						<path class="st457" d="M242.4,185.7l5.1,8.7c0,0,0,0,0,0L242.4,185.7C242.5,185.8,242.4,185.8,242.4,185.7"/>
						<path class="st456" d="M242.5,185.8l5.1,8.7c0,0,0,0,0,0L242.5,185.8C242.5,185.8,242.5,185.8,242.5,185.8"/>
						<path class="st455" d="M242.5,185.8l5,8.6c0,0,0,0,0,0L242.5,185.8C242.5,185.9,242.5,185.8,242.5,185.8"/>
						<path class="st454" d="M242.5,185.9l5,8.6c0,0,0,0,0,0L242.5,185.9C242.5,185.9,242.5,185.9,242.5,185.9"/>
						<path class="st453" d="M242.5,185.9l5,8.6c0,0,0,0,0,0L242.5,185.9C242.6,185.9,242.6,185.9,242.5,185.9"/>
						<path class="st452" d="M242.6,186l4.9,8.5c0,0,0,0,0,0L242.6,186C242.6,186,242.6,186,242.6,186"/>
						<path class="st451" d="M242.6,186l4.9,8.5c0,0,0,0,0,0L242.6,186C242.6,186,242.6,186,242.6,186"/>
						<path class="st450" d="M242.6,186l4.9,8.4c0,0,0,0,0,0L242.6,186C242.6,186.1,242.6,186,242.6,186"/>
						<path class="st449" d="M242.6,186.1l4.9,8.4c0,0,0,0,0,0L242.6,186.1C242.7,186.1,242.6,186.1,242.6,186.1"/>
						<path class="st448" d="M242.7,186.1l4.8,8.4c0,0,0,0,0,0L242.7,186.1C242.7,186.1,242.7,186.1,242.7,186.1"/>
						<path class="st447" d="M242.7,186.2l4.8,8.3c0,0,0,0,0,0L242.7,186.2C242.7,186.2,242.7,186.2,242.7,186.2"/>
						<path class="st446" d="M242.7,186.2l4.8,8.3c0,0,0,0,0,0L242.7,186.2C242.7,186.2,242.7,186.2,242.7,186.2"/>
						<path class="st445" d="M242.7,186.2l4.8,8.2c0,0,0,0,0,0L242.7,186.2C242.8,186.3,242.7,186.2,242.7,186.2"/>
						<path class="st444" d="M242.8,186.3l4.7,8.2c0,0,0,0,0,0L242.8,186.3C242.8,186.3,242.8,186.3,242.8,186.3"/>
						<path class="st497" d="M242.8,186.3l4.7,8.2c0,0,0,0,0,0L242.8,186.3C242.8,186.3,242.8,186.3,242.8,186.3"/>
						<path class="st498" d="M242.8,186.3l4.7,8.1c0,0,0,0,0,0L242.8,186.3C242.8,186.4,242.8,186.4,242.8,186.3"/>
						<path class="st499" d="M242.8,186.4l4.7,8.1c0,0,0,0,0,0L242.8,186.4C242.8,186.4,242.8,186.4,242.8,186.4"/>
						<path class="st500" d="M242.9,186.4l4.6,8.1c0,0,0,0,0,0L242.9,186.4C242.9,186.4,242.9,186.4,242.9,186.4"/>
						<path class="st501" d="M242.9,186.5l4.6,8c0,0,0,0,0,0L242.9,186.5C242.9,186.5,242.9,186.5,242.9,186.5"/>
						<path class="st502" d="M242.9,186.5l4.6,8c0,0,0,0,0,0L242.9,186.5C242.9,186.5,242.9,186.5,242.9,186.5"/>
						<path class="st503" d="M242.9,186.5l4.5,7.9c0,0,0,0,0,0L242.9,186.5C242.9,186.6,242.9,186.5,242.9,186.5"/>
						<path class="st504" d="M242.9,186.6l4.5,7.9c0,0,0,0,0,0L242.9,186.6C243,186.6,242.9,186.6,242.9,186.6"/>
						<path class="st505" d="M243,186.6l4.5,7.9c0,0,0,0,0,0L243,186.6C243,186.6,243,186.6,243,186.6"/>
						<path class="st506" d="M243,186.6l4.5,7.8c0,0,0,0,0,0L243,186.6C243,186.7,243,186.6,243,186.6"/>
						<path class="st507" d="M243,186.7l4.4,7.8c0,0,0,0,0,0L243,186.7C243,186.7,243,186.7,243,186.7"/>
						<path class="st508" d="M243,186.7l4.4,7.8c0,0,0,0,0,0L243,186.7C243,186.7,243,186.7,243,186.7"/>
						<path class="st509" d="M243,186.7l4.4,7.7c0,0,0,0,0,0L243,186.7C243.1,186.8,243.1,186.8,243,186.7"/>
						<path class="st508" d="M243.1,186.8l4.4,7.7c0,0,0,0,0,0L243.1,186.8C243.1,186.8,243.1,186.8,243.1,186.8"/>
						<path class="st507" d="M243.1,186.9l4.3,7.6c0,0,0,0,0,0L243.1,186.9C243.1,186.9,243.1,186.9,243.1,186.9"/>
						<path class="st506" d="M243.2,186.9l4.2,7.5c0,0,0,0,0,0L243.2,186.9C243.2,187,243.2,187,243.2,186.9"/>
						<path class="st505" d="M243.2,187l4.2,7.4c0,0,0,0,0,0L243.2,187C243.2,187.1,243.2,187,243.2,187"/>
						<path class="st504" d="M243.2,187.1l4.1,7.4c0,0,0,0,0,0L243.2,187.1C243.3,187.1,243.3,187.1,243.2,187.1"/>
						<path class="st503" d="M243.3,187.1l4.1,7.3c0,0,0,0,0,0L243.3,187.1C243.3,187.2,243.3,187.2,243.3,187.1"/>
						<path class="st502" d="M243.3,187.2l4,7.2c0,0,0,0,0,0L243.3,187.2C243.4,187.3,243.3,187.2,243.3,187.2"/>
						<path class="st501" d="M243.4,187.3l4,7.1c0,0,0,0,0,0L243.4,187.3C243.4,187.3,243.4,187.3,243.4,187.3"/>
						<path class="st500" d="M243.4,187.3l3.9,7.1c0,0,0,0,0,0L243.4,187.3C243.4,187.4,243.4,187.4,243.4,187.3"/>
						<path class="st499" d="M243.4,187.4l3.9,7c0,0,0,0,0,0L243.4,187.4C243.5,187.5,243.4,187.4,243.4,187.4"/>
						<path class="st498" d="M243.5,187.5l3.8,6.9c0,0,0,0,0,0L243.5,187.5C243.5,187.5,243.5,187.5,243.5,187.5"/>
						<path class="st497" d="M243.5,187.6l3.7,6.8c0,0,0,0,0,0l-3.6-6.7C243.6,187.6,243.5,187.6,243.5,187.6"/>
						<path class="st444" d="M243.6,187.7l3.6,6.7c0,0,0,0,0,0l-3.5-6.5C243.6,187.8,243.6,187.7,243.6,187.7"/>
						<path class="st445" d="M243.7,187.8l3.5,6.5c0,0,0,0,0,0l-3.4-6.4C243.7,187.9,243.7,187.8,243.7,187.8"/>
						<path class="st446" d="M243.7,187.9l3.4,6.4c0,0,0,0,0,0l-3.4-6.3C243.7,188,243.7,187.9,243.7,187.9"/>
						<path class="st447" d="M243.8,188l3.4,6.3c0,0,0,0,0,0l-3.3-6.1C243.8,188.1,243.8,188,243.8,188"/>
						<path class="st448" d="M243.8,188.1l3.3,6.1c0,0-0.1,0-0.1-0.1l-3.1-5.9C243.9,188.2,243.8,188.2,243.8,188.1"/>
						<path class="st449" d="M243.9,188.3l3.1,5.9c0,0-0.1,0-0.1-0.1l-2.9-5.7C244,188.4,243.9,188.3,243.9,188.3"/>
						<path class="st450" d="M244,188.5l2.9,5.7c0,0-0.1,0-0.1-0.1l-2.7-5.5C244,188.6,244,188.5,244,188.5"/>
						<path class="st451" d="M244.1,188.6l2.7,5.5c-0.1,0-0.1-0.1-0.2-0.1l-2.5-5.1C244.1,188.8,244.1,188.7,244.1,188.6"/>
						<path class="st452" d="M244.1,188.8l2.5,5.1c-0.1,0-0.2-0.1-0.2-0.2l-2.2-4.7C244.2,189,244.2,188.9,244.1,188.8"/>
						<path class="st453" d="M244.2,189.1l2.2,4.7c-0.1-0.1-0.3-0.2-0.4-0.2l-1.8-4.2C244.2,189.3,244.2,189.2,244.2,189.1"/>
						<path class="st454" d="M244.2,189.3l1.8,4.2c-0.2-0.1-0.4-0.3-0.7-0.4l-1.2-3.6C244.2,189.5,244.2,189.4,244.2,189.3"/>
						<path class="st455" d="M244.1,189.6l1.2,3.6c-0.5-0.3-1.2-0.7-1.8-1l-0.3-2.6C243.6,189.6,243.9,189.7,244.1,189.6"/>
						<path class="st456" d="M243.2,189.6l0.3,2.6c-1.2-0.6-2.7-1.1-4.1-1.3l0.1-2C241.1,189,242.3,189.4,243.2,189.6"/>
						<path class="st455" d="M239.5,188.8l-0.1,2c-0.3,0-0.7-0.1-1-0.1c-0.4,0-0.7,0-1,0.1l-0.4-2c0.5-0.1,0.9-0.1,1.4-0.1
							C238.8,188.8,239.2,188.8,239.5,188.8"/>
						<path class="st454" d="M237,188.9l0.4,2c-0.4,0.1-0.7,0.2-1.1,0.3l-0.8-1.8C236,189.1,236.5,189,237,188.9"/>
						<path class="st453" d="M235.5,189.3l0.8,1.8c0,0,0,0,0,0L235.5,189.3C235.5,189.3,235.5,189.3,235.5,189.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000039820484758554256570000014448238008502726533_" class="st1" d="M244.6,71.2l-1.3-1.6
							c4.6-6.1,9.8-11.5,15.5-16.3l1.3,1.6C254.5,59.5,249.4,65.1,244.6,71.2z"/>
					</defs>
					<clipPath id="SVGID_00000160175989954768565580000012999798845247827361_">
						<use xlink:href="#SVGID_00000039820484758554256570000014448238008502726533_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000160175989954768565580000012999798845247827361_);enable-background:new    ;">
						<path class="st453" d="M244.6,71.2l-1.3-1.6c3.4-4.6,7.2-8.8,11.3-12.6l1.4,1.5C251.9,62.3,248.2,66.6,244.6,71.2"/>
						<path class="st452" d="M256,58.5l-1.4-1.5c1.4-1.3,2.8-2.5,4.2-3.7l1.3,1.6C258.7,56,257.3,57.2,256,58.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000069388797324686677710000003068446840323763609_" class="st1" d="M261.9,205.5l-1.5,1.3
							c-4.7-3.8-9-7.9-12.8-12.4l-5.7-9.6C252.8,197.5,257.2,201.6,261.9,205.5z"/>
					</defs>
					<clipPath id="SVGID_00000027566430470201117500000004699113713105131169_">
						<use xlink:href="#SVGID_00000069388797324686677710000003068446840323763609_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000027566430470201117500000004699113713105131169_);enable-background:new    ;">
						<path class="st457" d="M261.9,205.5l-1.5,1.3c-0.8-0.6-1.6-1.3-2.4-2l1.5-1.4C260.3,204.1,261.1,204.8,261.9,205.5"/>
						<path class="st456" d="M259.4,203.4l-1.5,1.4c-3.5-3-6.7-6.1-9.6-9.5l-4.3-8.1C251.7,196.1,255.8,200.2,259.4,203.4"/>
						<path class="st457" d="M244,187.3l4.3,8.1c-0.3-0.3-0.5-0.6-0.8-0.9l-5.7-9.6C242.6,185.7,243.3,186.5,244,187.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000155842562977321122620000006423381258740543134_" class="st1" d="M262.6,43.5l-1.8,0.8
							c-2-4.6-5-11.6-5.2-11.9c-0.2-0.4-3.6-8.3,2.8-11l0.8,1.8c-4.5,1.8-1.9,8.1-1.8,8.4C257.6,31.9,260.7,38.9,262.6,43.5z"/>
					</defs>
					<clipPath id="SVGID_00000181797296635640423670000013813205328497095840_">
						<use xlink:href="#SVGID_00000155842562977321122620000006423381258740543134_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000181797296635640423670000013813205328497095840_);enable-background:new    ;">
						<path class="st457" d="M262.6,43.5l-1.8,0.8c-2-4.6-5-11.6-5.2-11.9c0,0,0,0,0,0l1.8-0.8c0,0,0,0,0,0
							C257.6,31.9,260.7,38.9,262.6,43.5"/>
						<path class="st456" d="M257.5,31.5l-1.8,0.8c-0.1-0.3-1.3-3-1-5.8l2,0.5C256.5,29.1,257.3,31.3,257.5,31.5"/>
						<path class="st455" d="M256.6,27l-2-0.5c0.1-0.7,0.2-1.4,0.5-2l1.8,0.9C256.8,26,256.7,26.5,256.6,27"/>
						<path class="st454" d="M256.9,25.5l-1.8-0.9c0.2-0.4,0.4-0.8,0.7-1.2l1.6,1.2C257.2,24.9,257,25.2,256.9,25.5"/>
						<path class="st453" d="M257.4,24.6l-1.6-1.2c0.2-0.3,0.5-0.6,0.7-0.8l1.4,1.5C257.7,24.2,257.5,24.4,257.4,24.6"/>
						<path class="st452" d="M257.9,24l-1.4-1.5c0.3-0.2,0.5-0.5,0.8-0.7l1.1,1.6C258.3,23.7,258.1,23.8,257.9,24"/>
						<path class="st451" d="M258.5,23.5l-1.1-1.6c0.3-0.2,0.6-0.4,1-0.6l0.8,1.8C258.9,23.3,258.7,23.4,258.5,23.5"/>
						<path class="st450" d="M259.2,23.2l-0.8-1.8c0,0,0.1,0,0.1,0L259.2,23.2C259.2,23.1,259.2,23.2,259.2,23.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000117676160103576172350000017718549122772487041_" class="st1" d="M262.9,238.9l-0.7,1.9
							c-0.4-0.2-8-4.2-5.1-11.7l1.9,0.7C256.6,235.5,262.8,238.8,262.9,238.9z"/>
					</defs>
					<clipPath id="SVGID_00000173840440736108669860000010507207281840452225_">
						<use xlink:href="#SVGID_00000117676160103576172350000017718549122772487041_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000173840440736108669860000010507207281840452225_);enable-background:new    ;">
						<path class="st456" d="M262.9,238.9l-0.7,1.9c-0.1,0-0.4-0.2-1-0.6l1-1.7C262.6,238.7,262.9,238.9,262.9,238.9"/>
						<path class="st457" d="M262.2,238.5l-1,1.7c-1.1-0.8-3-2.2-4-4.4l1.9-0.8C259.9,236.7,261.4,237.9,262.2,238.5"/>
						<path class="st456" d="M259.1,235l-1.9,0.8c-0.6-1.2-0.9-2.6-0.7-4.2l2,0.2C258.3,233,258.6,234.1,259.1,235"/>
						<path class="st455" d="M258.5,231.7l-2-0.2c0.1-0.8,0.3-1.6,0.6-2.5l1.9,0.7C258.7,230.5,258.5,231.1,258.5,231.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000089536564878126937060000011105860074612192143_" class="st1" d="M259,229.8l-1.9-0.7
							c2.9-7.4,4.3-11,4.4-11.2l1.9,0.7C263.3,218.8,261.9,222.4,259,229.8z"/>
					</defs>
					<clipPath id="SVGID_00000070089169963929640730000001718237558417957013_">
						<use xlink:href="#SVGID_00000089536564878126937060000011105860074612192143_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000070089169963929640730000001718237558417957013_);enable-background:new    ;">
						<path class="st455" d="M259,229.8l-1.9-0.7c2.9-7.4,4.3-11,4.4-11.2l1.9,0.7C263.3,218.8,261.9,222.4,259,229.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000170261508922441229460000005754400006588359552_" class="st1" d="M260.1,54.8l-1.3-1.6
							c1.5-2.4,3.3-6.1,2-9l1.8-0.8C264.3,47.3,262.1,51.7,260.1,54.8z"/>
					</defs>
					<clipPath id="SVGID_00000162317515886463635680000001793377512142021765_">
						<use xlink:href="#SVGID_00000170261508922441229460000005754400006588359552_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000162317515886463635680000001793377512142021765_);enable-background:new    ;">
						<path class="st454" d="M260.1,54.8l-1.3-1.6c0.5-0.8,1-1.7,1.4-2.7l1.8,0.9C261.4,52.6,260.8,53.8,260.1,54.8"/>
						<path class="st455" d="M262,51.4l-1.8-0.9c0.4-1,0.8-2.1,0.9-3.2l2,0.1C263,48.8,262.6,50.1,262,51.4"/>
						<path class="st456" d="M263.1,47.5l-2-0.1c0.1-1.1,0.1-2.1-0.4-3.1l1.8-0.8C263.2,44.8,263.3,46.1,263.1,47.5"/>
						<polyline class="st457" points="262.6,43.5 260.8,44.3 260.8,44.3 262.6,43.5 262.6,43.5 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000038398917218673571560000011575593548842415025_" class="st1" d="M305.7,226.1c2.1,0.5,4.4,1.1,9.2,2.2
							c-3.7-0.8-8.5-2-12.2-2.8C304,225.7,304.9,225.9,305.7,226.1z"/>
					</defs>
					<clipPath id="SVGID_00000047752062213663025840000016688561812103903933_">
						<use xlink:href="#SVGID_00000038398917218673571560000011575593548842415025_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000047752062213663025840000016688561812103903933_);enable-background:new    ;">
						<path class="st499" d="M314.9,228.3l-12.2-2.8c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st498" d="M314.9,228.3l-12.2-2.8c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st497" d="M314.9,228.3l-12.1-2.8c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st444" d="M314.9,228.3l-12.1-2.8c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st445" d="M314.9,228.3l-12-2.8c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st446" d="M314.9,228.3l-12-2.8c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st447" d="M314.9,228.3l-11.9-2.8c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st448" d="M314.9,228.3l-11.9-2.8c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st449" d="M314.9,228.3l-11.9-2.8c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st450" d="M314.9,228.3l-11.8-2.8c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st451" d="M314.9,228.3l-11.8-2.7c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st452" d="M314.9,228.3l-11.7-2.7c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st453" d="M314.9,228.3l-11.7-2.7c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st454" d="M314.9,228.3l-11.7-2.7c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st455" d="M314.9,228.3l-11.6-2.7c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st456" d="M314.9,228.3l-11.6-2.7c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st457" d="M314.9,228.3l-11.5-2.7c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st458" d="M314.9,228.3l-11.5-2.7c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st459" d="M314.9,228.3l-11.5-2.7c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st460" d="M314.9,228.3l-11.4-2.7c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st461" d="M314.9,228.3l-11.4-2.7c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st462" d="M314.9,228.3l-11.3-2.7c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st463" d="M314.9,228.3l-11.3-2.7c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st464" d="M314.9,228.3l-11.3-2.7c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st465" d="M314.9,228.3l-11.2-2.6c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st466" d="M314.9,228.3l-11.2-2.6c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st467" d="M314.9,228.3l-11.1-2.6c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st468" d="M314.9,228.3l-11.1-2.6c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st469" d="M314.9,228.3l-11.1-2.6c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st470" d="M314.9,228.3l-11-2.6c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st471" d="M314.9,228.3l-11-2.6c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st472" d="M314.9,228.3l-10.9-2.6c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st473" d="M314.9,228.3l-10.9-2.6c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st474" d="M314.9,228.3l-10.9-2.6c0,0,0,0,0,0L314.9,228.3C314.9,228.3,314.9,228.3,314.9,228.3"/>
						<path class="st475" d="M314.9,228.3l-10.8-2.6c0.6,0.1,1.2,0.3,1.7,0.4l8.9,2.3C314.7,228.4,314.8,228.3,314.9,228.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000076582831209428555380000005018128640506544801_" class="st1" d="M263.4,218.7l-1.9-0.7
							c0.1-0.3,2.9-6.3-1.2-11.1l1.5-1.3C266.8,211.4,263.5,218.4,263.4,218.7z"/>
					</defs>
					<clipPath id="SVGID_00000150796213987397768820000005125291468895680936_">
						<use xlink:href="#SVGID_00000076582831209428555380000005018128640506544801_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000150796213987397768820000005125291468895680936_);enable-background:new    ;">
						<path class="st454" d="M263.4,218.7l-1.9-0.7c0,0,0.1-0.1,0.1-0.3l1.9,0.7C263.4,218.5,263.4,218.6,263.4,218.7"/>
						<path class="st455" d="M263.5,218.4l-1.9-0.7c0.2-0.5,0.7-1.8,0.9-3.4l2,0.2C264.3,216.3,263.7,217.8,263.5,218.4"/>
						<path class="st456" d="M264.5,214.4l-2-0.2c0.2-1.5,0.1-3.3-0.7-5.1l1.8-0.8C264.6,210.4,264.7,212.6,264.5,214.4"/>
						<path class="st457" d="M263.6,208.3l-1.8,0.8c-0.3-0.8-0.8-1.5-1.4-2.3l1.5-1.3C262.6,206.4,263.2,207.3,263.6,208.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000029041235734503854900000012946570047749654410_" class="st1" d="M259.3,23.1l-0.8-1.8
							c6.4-2.8,26.5-11.5,27.5-11.9l0.8,1.8C285.7,11.7,265.7,20.4,259.3,23.1z"/>
					</defs>
					<clipPath id="SVGID_00000082332285055748704970000014729483167952245677_">
						<use xlink:href="#SVGID_00000029041235734503854900000012946570047749654410_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000082332285055748704970000014729483167952245677_);enable-background:new    ;">
						<path class="st450" d="M259.3,23.1l-0.8-1.8c6.4-2.8,26.5-11.5,27.5-11.9l0.8,1.8C285.7,11.7,265.7,20.4,259.3,23.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000173146928455374771660000011519256277666014606_" class="st1" d="M289.5,8.9L289.5,8.9
							c2.8,0,6.5,0.9,8.3,5.2h0C296,9.8,292.3,8.9,289.5,8.9z"/>
					</defs>
					<clipPath id="SVGID_00000044166867551859330560000005517930135751198615_">
						<use xlink:href="#SVGID_00000173146928455374771660000011519256277666014606_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000044166867551859330560000005517930135751198615_);enable-background:new    ;">
						<path class="st473" d="M289.5,8.9L289.5,8.9c0.2,0,0.3,0,0.4,0h0C289.8,8.9,289.7,8.9,289.5,8.9"/>
						<path class="st472" d="M290,8.9L290,8.9c0.5,0,1.1,0.1,1.6,0.2h0C291,9,290.5,8.9,290,8.9"/>
						<path class="st471" d="M291.6,9.1L291.6,9.1c0.5,0.1,0.9,0.2,1.4,0.4h0C292.5,9.3,292,9.1,291.6,9.1"/>
						<path class="st470" d="M293,9.4L293,9.4c0.5,0.2,0.9,0.4,1.4,0.6h0C293.9,9.8,293.4,9.6,293,9.4"/>
						<path class="st469" d="M294.3,10L294.3,10c1.4,0.8,2.6,2,3.5,4h0C297,12.1,295.7,10.8,294.3,10"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000087391976761370289980000003774929858807243668_" class="st1" d="M290.8,249.9l-0.7,1.9
							c-6.5-2.6-26.8-10.6-27.9-11l0.7-1.9C264,239.3,284.3,247.3,290.8,249.9z"/>
					</defs>
					<clipPath id="SVGID_00000140700017137570742910000017175694473596396714_">
						<use xlink:href="#SVGID_00000087391976761370289980000003774929858807243668_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000140700017137570742910000017175694473596396714_);enable-background:new    ;">
						<path class="st456" d="M290.8,249.9l-0.7,1.9c-6.5-2.6-26.8-10.6-27.9-11l0.7-1.9C264,239.3,284.3,247.3,290.8,249.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000075876636554567847790000005968944784894075805_" class="st1" d="M299.9,246L299.9,246
							c-0.1,0.3-2.5,6.3-7.4,6.3l0,0C297.4,252.3,299.7,246.3,299.9,246z"/>
					</defs>
					<clipPath id="SVGID_00000088853524902597111390000007178151877735076285_">
						<use xlink:href="#SVGID_00000075876636554567847790000005968944784894075805_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000088853524902597111390000007178151877735076285_);enable-background:new    ;">
						<path class="st474" d="M299.9,246L299.9,246c0,0.1-0.2,0.6-0.6,1.3h0C299.6,246.6,299.8,246.1,299.9,246"/>
						<path class="st475" d="M299.2,247.3L299.2,247.3c-1,1.8-3.2,5-6.7,5h0C296,252.3,298.2,249.2,299.2,247.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000076578500960702539800000001479275182643825321_" class="st1" d="M297.8,14L297.8,14
							c3.2,7.3,4.7,10.8,4.8,11l0,0C302.5,24.9,301,21.4,297.8,14z"/>
					</defs>
					<clipPath id="SVGID_00000147203859909005326680000012593996003571829158_">
						<use xlink:href="#SVGID_00000076578500960702539800000001479275182643825321_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000147203859909005326680000012593996003571829158_);enable-background:new    ;">
						<path class="st469" d="M297.8,14L297.8,14c3.2,7.3,4.7,10.8,4.8,11h0C302.5,24.9,301,21.4,297.8,14"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000180363617806528121160000012243299557241167278_" class="st1" d="M304.6,233.9L304.6,233.9
							c-1.8,4.7-4.6,11.7-4.8,12.1h0C300,245.6,302.8,238.5,304.6,233.9z"/>
					</defs>
					<clipPath id="SVGID_00000005970980939648318230000009446073094965530019_">
						<use xlink:href="#SVGID_00000180363617806528121160000012243299557241167278_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000005970980939648318230000009446073094965530019_);enable-background:new    ;">
						<path class="st474" d="M304.6,233.9L304.6,233.9c-1.8,4.7-4.6,11.7-4.8,12.1h0C300,245.6,302.8,238.5,304.6,233.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000106860511256834646220000017649082198019168934_" class="st1" d="M286.8,11.2c-0.2-0.4-0.4-0.9-0.5-1.3
							L286,9.4c0.1,0,1.5-0.5,3.5-0.5c2.8,0,6.5,0.9,8.3,5.2l-1.8,0.8c-1.4-3.3-4.2-3.9-6.5-3.9C287.9,10.9,286.8,11.2,286.8,11.2z"
							/>
					</defs>
					<clipPath id="SVGID_00000079470318610822596460000000584581095854742412_">
						<use xlink:href="#SVGID_00000106860511256834646220000017649082198019168934_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000079470318610822596460000000584581095854742412_);enable-background:new    ;">
						<polyline class="st475" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st474" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st473" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st472" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st471" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st470" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st469" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st468" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st467" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st466" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st465" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st464" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st463" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st462" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st461" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st460" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st459" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st458" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st457" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st456" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st455" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st454" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st453" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st452" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st451" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<polyline class="st450" points="286.8,11.2 286,9.4 286,9.4 286.8,11.2 286.8,11.2 						"/>
						<path class="st449" d="M286.8,11.2L286,9.4c0.1,0,0.9-0.3,2.1-0.4l0.2,2C287.4,11,286.7,11.2,286.8,11.2"/>
						<path class="st448" d="M288.3,10.9l-0.2-2c0.4,0,0.9-0.1,1.4-0.1c0.3,0,0.6,0,0.8,0l-0.2,2c-0.2,0-0.5,0-0.7,0
							C289.1,10.9,288.7,10.9,288.3,10.9"/>
						<path class="st447" d="M290.2,10.9l0.2-2c0.6,0,1.2,0.1,1.9,0.3l-0.5,1.9C291.2,11,290.7,10.9,290.2,10.9"/>
						<path class="st446" d="M291.7,11.1l0.5-1.9c0.6,0.2,1.3,0.4,1.9,0.7l-1,1.8C292.7,11.4,292.2,11.3,291.7,11.1"/>
						<path class="st445" d="M293.1,11.7l1-1.8c1.5,0.8,2.8,2,3.7,4.1l-1.8,0.8C295.3,13.2,294.3,12.3,293.1,11.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000011712220165257697890000002627148804110053510_" class="st1" d="M302.6,25L302.6,25
							c0.1,0.2,1.5,4.2,5.4,6.1l0,0C304.1,29.3,302.7,25.3,302.6,25z"/>
					</defs>
					<clipPath id="SVGID_00000117648386936228381210000003600372730911122580_">
						<use xlink:href="#SVGID_00000011712220165257697890000002627148804110053510_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000117648386936228381210000003600372730911122580_);enable-background:new    ;">
						<path class="st469" d="M302.6,25L302.6,25c0.1,0.2,1.4,3.9,5,5.9h0C304,28.9,302.7,25.2,302.6,25"/>
						<path class="st470" d="M307.6,31L307.6,31c0.2,0.1,0.3,0.2,0.5,0.2h0C307.9,31.1,307.7,31,307.6,31"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000080918403020999317400000015434784011430594488_" class="st1" d="M296,14.8l1.8-0.8
							c3.2,7.3,4.7,10.8,4.8,11l-1.8,0.8C300.7,25.7,299.2,22.2,296,14.8z"/>
					</defs>
					<clipPath id="SVGID_00000121238522323648795280000011372335165180551575_">
						<use xlink:href="#SVGID_00000080918403020999317400000015434784011430594488_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000121238522323648795280000011372335165180551575_);enable-background:new    ;">
						<path class="st445" d="M296,14.8l1.8-0.8c3.2,7.3,4.7,10.8,4.8,11l-1.8,0.8C300.7,25.7,299.2,22.2,296,14.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000132059314414139093840000015909989983851510403_" class="st1" d="M302.8,233.1l1.9,0.7
							c-1.8,4.7-4.6,11.7-4.8,12.1c-0.1,0.3-2.5,6.3-7.4,6.3c-0.8,0-1.6-0.1-2.4-0.5l0.7-1.9c0.6,0.3,1.2,0.4,1.7,0.4
							c3.4,0.1,5.4-4.8,5.5-5C298.2,244.9,300.9,237.8,302.8,233.1z"/>
					</defs>
					<clipPath id="SVGID_00000086686118763814974340000003497852716175388067_">
						<use xlink:href="#SVGID_00000132059314414139093840000015909989983851510403_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000086686118763814974340000003497852716175388067_);enable-background:new    ;">
						<path class="st449" d="M302.8,233.1l1.9,0.7c-1.8,4.7-4.6,11.7-4.8,12.1c0,0.1-0.2,0.5-0.5,1.1l-1.8-0.9
							c0.3-0.5,0.4-0.8,0.4-0.9C298.2,244.9,300.9,237.8,302.8,233.1"/>
						<path class="st450" d="M297.6,246.1l1.8,0.9c-0.3,0.5-0.7,1.2-1.2,1.9l-1.5-1.3C297,247.1,297.4,246.6,297.6,246.1"/>
						<path class="st451" d="M296.6,247.7l1.5,1.3c-0.3,0.4-0.7,0.9-1.2,1.3l-1.2-1.6C296.1,248.4,296.4,248.1,296.6,247.7"/>
						<path class="st452" d="M295.7,248.8l1.2,1.6c-0.3,0.3-0.7,0.6-1.1,0.9l-1-1.8C295.2,249.3,295.5,249,295.7,248.8"/>
						<path class="st453" d="M294.9,249.5l1,1.8c-0.3,0.2-0.7,0.4-1.1,0.6l-0.7-1.9C294.4,249.8,294.7,249.6,294.9,249.5"/>
						<path class="st454" d="M294.1,249.9l0.7,1.9c-0.4,0.2-0.8,0.3-1.2,0.4l-0.3-2C293.5,250.2,293.8,250.1,294.1,249.9"/>
						<path class="st455" d="M293.2,250.2l0.3,2c-0.3,0.1-0.7,0.1-1,0.1c-0.2,0-0.4,0-0.6,0l0.2-2c0.1,0,0.3,0,0.4,0
							C292.7,250.3,293,250.3,293.2,250.2"/>
						<path class="st456" d="M292.1,250.2l-0.2,2c-0.6-0.1-1.2-0.2-1.8-0.5l0.7-1.9C291.3,250.1,291.7,250.2,292.1,250.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000088107841536422224750000012444369627967410865_" class="st1" d="M315.3,228.4L315.3,228.4
							c0.1,0-8.8,0.8-10.6,5.5h0C306.5,229.2,315.4,228.4,315.3,228.4z"/>
					</defs>
					<clipPath id="SVGID_00000130629102800575217100000016128793822946133690_">
						<use xlink:href="#SVGID_00000088107841536422224750000012444369627967410865_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000130629102800575217100000016128793822946133690_);enable-background:new    ;">
						<polyline class="st470" points="315.3,228.4 315.3,228.4 315.3,228.4 315.3,228.4 315.3,228.4 						"/>
						<polyline class="st471" points="315.3,228.4 315.3,228.4 315.3,228.4 315.3,228.4 315.3,228.4 						"/>
						<polyline class="st472" points="315.3,228.4 315.3,228.4 315.3,228.4 315.3,228.4 315.3,228.4 						"/>
						<path class="st473" d="M315.3,228.4L315.3,228.4C315.3,228.4,315.3,228.4,315.3,228.4L315.3,228.4
							C315.3,228.4,315.3,228.4,315.3,228.4"/>
						<path class="st474" d="M315.3,228.4L315.3,228.4C315.3,228.4,315.3,228.4,315.3,228.4L315.3,228.4
							C315.3,228.4,315.3,228.4,315.3,228.4"/>
						<path class="st475" d="M315.3,228.4L315.3,228.4c-0.6,0.1-8.2,0.9-10.4,4.9h0C307.1,229.4,314.7,228.5,315.3,228.4"/>
						<path class="st474" d="M304.9,233.3L304.9,233.3c-0.1,0.2-0.2,0.4-0.2,0.5h0C304.7,233.7,304.8,233.5,304.9,233.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000108279139623313486710000011870372363583796365_" class="st1" d="M357.2,248.8c0.2,0.6,0.4,1,0.5,1.3
							L357.2,248.8C357.2,248.8,357.2,248.8,357.2,248.8z"/>
					</defs>
					<clipPath id="SVGID_00000121984673379215953160000004154298649394358193_">
						<use xlink:href="#SVGID_00000108279139623313486710000011870372363583796365_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000121984673379215953160000004154298649394358193_);enable-background:new    ;">
						<polyline class="st450" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st451" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st452" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st453" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st454" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st455" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st456" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st457" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st458" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st459" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st460" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st461" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st462" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st463" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st464" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st465" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st466" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st467" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st468" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st469" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st470" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st471" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st472" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st473" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st474" points="357.8,250.1 357.2,248.8 357.2,248.8 357.8,250.1 357.8,250.1 						"/>
						<polyline class="st475" points="357.8,250.1 357.2,248.8 357.2,248.8 357.7,250.1 357.8,250.1 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000009557597544235564390000016793574607284480185_" class="st1" d="M300.8,25.8l1.8-0.8
							c0.1,0.2,1.5,4.2,5.4,6.1l-0.3,2.1C302.5,30.7,300.8,26,300.8,25.8z"/>
					</defs>
					<clipPath id="SVGID_00000093870755703014881300000001883365125670111876_">
						<use xlink:href="#SVGID_00000009557597544235564390000016793574607284480185_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000093870755703014881300000001883365125670111876_);enable-background:new    ;">
						<path class="st445" d="M300.8,25.8l1.8-0.8c0,0.1,0.4,1.2,1.3,2.5l-1.6,1.2C301.2,27.2,300.8,25.9,300.8,25.8"/>
						<path class="st444" d="M302.4,28.7l1.6-1.2c0.5,0.7,1.1,1.4,1.9,2.1l-1,1.6C303.7,30.5,303,29.5,302.4,28.7"/>
						<path class="st445" d="M304.8,31.3l1-1.6c0.6,0.6,1.4,1.1,2.2,1.5l-0.3,2.1C306.6,32.7,305.6,32,304.8,31.3"/>
						<polyline class="st446" points="307.7,33.3 308,31.2 308,31.2 307.7,33.3 307.7,33.3 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000168807520266943692700000004524493082388132006_" class="st1" d="M305.7,226.1c2.4,0.6,4.9,1.2,9.6,2.3
							c0.1,0-8.8,0.8-10.7,5.5l-1.9-0.7C304.9,228,310.9,227.5,305.7,226.1z"/>
					</defs>
					<clipPath id="SVGID_00000092449376648303366780000006269053973865152696_">
						<use xlink:href="#SVGID_00000168807520266943692700000004524493082388132006_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000092449376648303366780000006269053973865152696_);enable-background:new    ;">
						<path class="st475" d="M302.7,225.5l12.6,2.9c0,0,0,0,0,0l-12.5-2.9C302.8,225.5,302.7,225.5,302.7,225.5"/>
						<path class="st474" d="M302.8,225.5l12.5,2.9c0,0,0,0,0,0l-12.4-2.9C302.9,225.5,302.8,225.5,302.8,225.5"/>
						<path class="st473" d="M302.9,225.5l12.4,2.9c0,0,0,0,0,0l-12.2-2.9C303,225.5,303,225.5,302.9,225.5"/>
						<path class="st472" d="M303.1,225.6l12.2,2.9c0,0,0,0,0,0l-12.1-2.8C303.1,225.6,303.1,225.6,303.1,225.6"/>
						<path class="st471" d="M303.2,225.6l12.1,2.8c0,0,0,0,0,0l-12-2.8C303.3,225.6,303.2,225.6,303.2,225.6"/>
						<path class="st470" d="M303.3,225.6l12,2.8c0,0,0,0,0,0l-11.9-2.8C303.4,225.6,303.3,225.6,303.3,225.6"/>
						<path class="st469" d="M303.4,225.6l11.9,2.8c0,0,0,0,0,0l-11.7-2.8C303.5,225.6,303.5,225.6,303.4,225.6"/>
						<path class="st468" d="M303.5,225.7l11.7,2.8c0,0,0,0,0,0l-11.6-2.7C303.6,225.7,303.6,225.7,303.5,225.7"/>
						<path class="st467" d="M303.7,225.7l11.6,2.7c0,0,0,0,0,0l-11.5-2.7C303.7,225.7,303.7,225.7,303.7,225.7"/>
						<path class="st466" d="M303.8,225.7l11.5,2.7c0,0,0,0,0,0l-11.4-2.7C303.8,225.7,303.8,225.7,303.8,225.7"/>
						<path class="st465" d="M303.9,225.7l11.4,2.7c0,0,0,0,0,0l-11.3-2.7C304,225.7,303.9,225.7,303.9,225.7"/>
						<path class="st464" d="M304,225.7l11.3,2.7c0,0,0,0,0,0L304,225.7C304,225.8,304,225.8,304,225.7"/>
						<path class="st463" d="M304.1,225.8l11.2,2.7c0,0,0,0,0,0L304.1,225.8C304.1,225.8,304.1,225.8,304.1,225.8"/>
						<path class="st462" d="M304.1,225.8l11.1,2.6c0,0,0,0,0,0L304.1,225.8C304.2,225.8,304.1,225.8,304.1,225.8"/>
						<path class="st461" d="M304.2,225.8l11.1,2.6c0,0,0,0,0,0L304.2,225.8C304.2,225.8,304.2,225.8,304.2,225.8"/>
						<path class="st460" d="M304.2,225.8l11,2.6c0,0,0,0,0,0L304.2,225.8C304.3,225.8,304.2,225.8,304.2,225.8"/>
						<path class="st459" d="M304.3,225.8l10.9,2.6c0,0,0,0,0,0L304.3,225.8C304.3,225.8,304.3,225.8,304.3,225.8"/>
						<path class="st458" d="M304.3,225.8l10.9,2.6c0,0,0,0,0,0L304.3,225.8C304.4,225.8,304.4,225.8,304.3,225.8"/>
						<path class="st457" d="M304.4,225.8l10.8,2.6c0,0,0,0,0,0L304.4,225.8C304.4,225.8,304.4,225.8,304.4,225.8"/>
						<path class="st456" d="M304.4,225.8l10.8,2.6c0,0,0,0,0,0L304.4,225.8C304.5,225.8,304.4,225.8,304.4,225.8"/>
						<path class="st455" d="M304.5,225.8l10.7,2.6c0,0,0,0,0,0L304.5,225.8C304.5,225.9,304.5,225.9,304.5,225.8"/>
						<path class="st454" d="M304.5,225.9l10.7,2.6c0,0,0,0,0,0L304.5,225.9C304.6,225.9,304.5,225.9,304.5,225.9"/>
						<path class="st453" d="M304.6,225.9l10.6,2.6c0,0,0,0,0,0L304.6,225.9C304.6,225.9,304.6,225.9,304.6,225.9"/>
						<path class="st452" d="M304.6,225.9l10.5,2.6c0,0,0,0,0,0L304.6,225.9C304.7,225.9,304.6,225.9,304.6,225.9"/>
						<path class="st451" d="M304.7,225.9l10.5,2.5c0,0,0,0,0,0L304.7,225.9C304.7,225.9,304.7,225.9,304.7,225.9"/>
						<path class="st450" d="M304.7,225.9l10.4,2.5c0,0,0,0,0,0L304.7,225.9C304.8,225.9,304.7,225.9,304.7,225.9"/>
						<path class="st449" d="M304.8,225.9l10.4,2.5c0,0,0,0,0,0L304.8,225.9C304.8,225.9,304.8,225.9,304.8,225.9"/>
						<path class="st448" d="M304.8,225.9l10.3,2.5c0,0,0,0,0,0L304.8,225.9C304.8,225.9,304.8,225.9,304.8,225.9"/>
						<path class="st447" d="M304.9,225.9l10.3,2.5c0,0,0,0,0,0L304.9,225.9C304.9,225.9,304.9,225.9,304.9,225.9"/>
						<path class="st446" d="M304.9,225.9l10.2,2.5c0,0,0,0,0,0L304.9,225.9C304.9,225.9,304.9,225.9,304.9,225.9"/>
						<path class="st445" d="M305,226l10.2,2.5c0,0,0,0,0,0L305,226C305,226,305,226,305,226"/>
						<path class="st444" d="M305,226l10.1,2.5c0,0,0,0,0,0L305,226C305,226,305,226,305,226"/>
						<path class="st497" d="M305,226l10.1,2.5c0,0,0,0,0,0L305,226C305.1,226,305.1,226,305,226"/>
						<path class="st498" d="M305.1,226l10,2.5c0,0,0,0,0,0L305.1,226C305.1,226,305.1,226,305.1,226"/>
						<path class="st499" d="M305.1,226l10,2.5c0,0,0,0,0,0L305.1,226C305.2,226,305.1,226,305.1,226"/>
						<path class="st500" d="M305.2,226l9.9,2.4c0,0,0,0,0,0L305.2,226C305.2,226,305.2,226,305.2,226"/>
						<path class="st499" d="M305.2,226l9.8,2.4c0,0,0,0,0,0l-9.6-2.4C305.3,226,305.3,226,305.2,226"/>
						<path class="st498" d="M305.4,226.1l9.6,2.4c0,0,0,0,0,0l-9.4-2.4C305.5,226.1,305.5,226.1,305.4,226.1"/>
						<path class="st497" d="M305.6,226.1l9.4,2.4c0,0,0,0,0,0l-9.2-2.3C305.6,226.1,305.6,226.1,305.6,226.1"/>
						<path class="st444" d="M305.7,226.1l9.2,2.3c0,0,0,0,0,0l-9.1-2.3C305.8,226.2,305.7,226.1,305.7,226.1"/>
						<path class="st445" d="M305.8,226.2l9.1,2.3c0,0,0,0,0,0l-8.9-2.3C305.9,226.2,305.9,226.2,305.8,226.2"/>
						<path class="st446" d="M306,226.2l8.9,2.3c0,0,0,0-0.1,0l-8.7-2.3C306.1,226.2,306,226.2,306,226.2"/>
						<path class="st447" d="M306.1,226.2l8.7,2.3c0,0-0.1,0-0.1,0l-8.3-2.2C306.3,226.3,306.2,226.3,306.1,226.2"/>
						<path class="st448" d="M306.4,226.3l8.3,2.2c0,0-0.1,0-0.1,0l-7.9-2.1C306.6,226.4,306.5,226.3,306.4,226.3"/>
						<path class="st449" d="M306.6,226.4l7.9,2.1c-0.1,0-0.1,0-0.2,0l-7.4-2.1C306.8,226.5,306.7,226.4,306.6,226.4"/>
						<path class="st450" d="M306.9,226.5l7.4,2.1c-0.1,0-0.2,0-0.4,0.1l-6.7-2C307.2,226.6,307.1,226.5,306.9,226.5"/>
						<path class="st451" d="M307.3,226.7l6.7,2c-0.2,0-0.5,0.1-0.7,0.1l-5.6-1.8C307.6,226.8,307.5,226.7,307.3,226.7"/>
						<path class="st452" d="M307.7,226.9l5.6,1.8c-0.7,0.1-1.4,0.3-2.3,0.6l-3.4-1.6C307.8,227.4,307.9,227.2,307.7,226.9"/>
						<path class="st453" d="M307.5,227.7l3.4,1.6c-0.7,0.2-1.4,0.5-2,0.8l-2.4-1.5C307,228.2,307.3,228,307.5,227.7"/>
						<path class="st452" d="M306.5,228.6l2.4,1.5c-0.9,0.4-1.8,0.9-2.6,1.5l-1.8-1.3C305.3,229.6,306,229.1,306.5,228.6"/>
						<path class="st451" d="M304.6,230.4l1.8,1.3c-0.3,0.3-0.7,0.6-0.9,0.9l-1.7-1.1C304,231.1,304.3,230.7,304.6,230.4"/>
						<path class="st450" d="M303.7,231.5l1.7,1.1c-0.2,0.3-0.4,0.5-0.6,0.8l-1.8-0.9C303.3,232.1,303.5,231.8,303.7,231.5"/>
						<path class="st449" d="M303.1,232.5l1.8,0.9c-0.1,0.2-0.2,0.3-0.2,0.5l-1.9-0.7C302.9,232.9,303,232.7,303.1,232.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000127025667997432443610000014940474410680754102_" class="st1" d="M322.6,52.3L322.6,52.3
							c-31.5,0-61.2,19-73.5,50c-16,40.6,3.9,86.5,44.5,102.5c9.5,3.8,19.3,5.5,29,5.5l0,0c-9.6,0-19.5-1.8-29-5.5
							c-40.6-16-60.5-61.9-44.5-102.5C261.3,71.2,291.1,52.3,322.6,52.3z"/>
					</defs>
					<clipPath id="SVGID_00000041279811535217569100000016258888475923425977_">
						<use xlink:href="#SVGID_00000127025667997432443610000014940474410680754102_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000041279811535217569100000016258888475923425977_);enable-background:new    ;">
						<path class="st475" d="M322.6,52.3L322.6,52.3c-28.4,0-55.4,15.4-69.4,41.2h0C267.2,67.7,294.2,52.3,322.6,52.3"/>
						<path class="st474" d="M253.2,93.5L253.2,93.5c-1.5,2.8-2.9,5.7-4.1,8.8c-0.5,1.4-1.1,2.8-1.5,4.2h0c0.5-1.4,1-2.8,1.5-4.2
							C250.3,99.2,251.7,96.3,253.2,93.5"/>
						<path class="st473" d="M247.6,106.5L247.6,106.5c-1.3,3.8-2.2,7.7-2.9,11.6h0C245.3,114.2,246.3,110.3,247.6,106.5"/>
						<path class="st472" d="M244.7,118.1L244.7,118.1c-0.6,3.8-1,7.6-1.1,11.3h0C243.7,125.6,244,121.9,244.7,118.1"/>
						<path class="st471" d="M243.6,129.4L243.6,129.4c-0.1,4,0.1,8,0.6,11.9h0C243.7,137.4,243.5,133.4,243.6,129.4"/>
						<path class="st470" d="M244.2,141.3L244.2,141.3c0.6,4.8,1.7,9.4,3.1,14h0C245.9,150.8,244.8,146.1,244.2,141.3"/>
						<path class="st469" d="M247.3,155.3L247.3,155.3c5.9,18.5,18.6,34.8,36.4,44.8h0C265.9,190.1,253.2,173.9,247.3,155.3"/>
						<path class="st470" d="M283.7,200.1L283.7,200.1c3.1,1.8,6.4,3.3,9.8,4.7c1.1,0.4,2.3,0.9,3.4,1.3h0c-1.1-0.4-2.3-0.8-3.4-1.3
							C290.1,203.4,286.8,201.9,283.7,200.1"/>
						<path class="st471" d="M297,206L297,206c3.9,1.3,7.7,2.3,11.6,3h0C304.7,208.4,300.8,207.4,297,206"/>
						<path class="st472" d="M308.6,209.1L308.6,209.1c3.8,0.7,7.5,1.1,11.3,1.2h0C316.1,210.1,312.4,209.7,308.6,209.1"/>
						<path class="st473" d="M319.9,210.3L319.9,210.3c0.9,0,1.8,0,2.7,0h0C321.6,210.3,320.8,210.3,319.9,210.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000124129660277553589620000015452792337737075355_" class="st1" d="M322.5,30.1L322.5,30.1
							c3.5,0,7,0.2,10.5,0.6h0C329.5,30.3,326,30.1,322.5,30.1z"/>
					</defs>
					<clipPath id="SVGID_00000034074406413872863580000008613647867279509429_">
						<use xlink:href="#SVGID_00000124129660277553589620000015452792337737075355_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000034074406413872863580000008613647867279509429_);enable-background:new    ;">
						<path class="st473" d="M322.5,30.1L322.5,30.1c1.1,0,2.2,0,3.3,0.1h0C324.7,30.1,323.6,30.1,322.5,30.1"/>
						<path class="st472" d="M325.8,30.2L325.8,30.2c2.4,0.1,4.8,0.2,7.1,0.5h0C330.6,30.4,328.2,30.3,325.8,30.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000137818524262259716600000014000177399738678710_" class="st1" d="M333.6,228L333.6,228
							c-3.7,0.4-7.4,0.6-11.2,0.6h0C326.2,228.7,329.9,228.5,333.6,228z"/>
					</defs>
					<clipPath id="SVGID_00000130610860226210357400000011338464132297417094_">
						<use xlink:href="#SVGID_00000137818524262259716600000014000177399738678710_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000130610860226210357400000011338464132297417094_);enable-background:new    ;">
						<path class="st475" d="M333.6,228L333.6,228c-3.7,0.4-7.4,0.6-11.2,0.6h0C326.2,228.7,329.9,228.5,333.6,228"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000130616730619842019010000010807890566756996228_" class="st1" d="M307.7,33.3l0.3-2.1
							c4.8-0.7,9.6-1.1,14.5-1.1c3.5,0,7,0.2,10.4,0.6l-0.2,2c-3.3-0.3-6.8-0.6-10.2-0.6C317.8,32.2,313,32.5,307.7,33.3z"/>
					</defs>
					<clipPath id="SVGID_00000026153044839157331590000013863511018939371657_">
						<use xlink:href="#SVGID_00000130616730619842019010000010807890566756996228_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000026153044839157331590000013863511018939371657_);enable-background:new    ;">
						<path class="st449" d="M307.7,33.3l0.3-2.1c1.8-0.3,3.6-0.5,5.3-0.6l0.1,2C311.6,32.7,309.7,33,307.7,33.3"/>
						<path class="st448" d="M313.4,32.6l-0.1-2c3-0.3,6.1-0.4,9.1-0.4c2.6,0,5.2,0.1,7.8,0.3l-0.1,2c-2.6-0.2-5.2-0.4-7.7-0.4
							C319.6,32.2,316.6,32.3,313.4,32.6"/>
						<path class="st447" d="M330.2,32.5l0.1-2c0.9,0.1,1.7,0.2,2.6,0.2l-0.2,2C331.9,32.6,331,32.5,330.2,32.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000131369127301941999170000010550935601234725267_" class="st1" d="M334,226l-0.4,2
							c-3.7,0.4-7.5,0.6-11.2,0.6c-2.4,0-4.7-0.1-7.1-0.3l-12.6-2.9c15.1,1.1,17.4,1.2,19.7,1.2C326,226.6,329.8,226.5,334,226z"/>
					</defs>
					<clipPath id="SVGID_00000163760276706503755390000017768611902943493273_">
						<use xlink:href="#SVGID_00000131369127301941999170000010550935601234725267_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000163760276706503755390000017768611902943493273_);enable-background:new    ;">
						<path class="st455" d="M334,226l-0.4,2c-3.7,0.4-7.5,0.6-11.2,0.6c-2.4,0-4.7-0.1-7.1-0.3l-12.6-2.9
							c15.1,1.1,17.4,1.2,19.7,1.2C326,226.6,329.8,226.5,334,226"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000106841323128664697860000000161005907024034238_" class="st1" d="M332.7,32.7l0.2-2
							c2.5-0.8,5.4-2.1,6.4-4.6l1.9,0.7C339.9,30.1,336.4,31.7,332.7,32.7z"/>
					</defs>
					<clipPath id="SVGID_00000034080338760492205020000016202146422526591369_">
						<use xlink:href="#SVGID_00000106841323128664697860000000161005907024034238_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000034080338760492205020000016202146422526591369_);enable-background:new    ;">
						<path class="st450" d="M332.7,32.7l0.2-2c0.8-0.2,1.6-0.5,2.3-0.9l0.6,1.8C334.9,32.1,333.8,32.4,332.7,32.7"/>
						<path class="st451" d="M335.9,31.6l-0.6-1.8c0.5-0.2,1.1-0.5,1.6-0.8l1.1,1.6C337.3,31,336.6,31.3,335.9,31.6"/>
						<path class="st452" d="M337.9,30.6l-1.1-1.6c0.4-0.3,0.8-0.5,1.1-0.9l1.4,1.4C338.9,29.9,338.5,30.2,337.9,30.6"/>
						<path class="st453" d="M339.4,29.5l-1.4-1.4c0.3-0.3,0.6-0.6,0.8-0.9l1.7,1.1C340.1,28.7,339.7,29.1,339.4,29.5"/>
						<path class="st454" d="M340.4,28.3l-1.7-1.1c0.2-0.3,0.4-0.7,0.6-1l1.8,0.8C340.9,27.5,340.7,27.9,340.4,28.3"/>
						<path class="st455" d="M341.2,27l-1.8-0.8c0,0,0-0.1,0-0.1l1.9,0.7C341.2,26.9,341.2,26.9,341.2,27"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000142879572714579388960000008278787204555737250_" class="st1" d="M343.2,234.2l-1.8,0.8
							c-0.1-0.3-2.1-5.8-7.7-6.9l0.4-2C340.8,227.4,343.1,233.9,343.2,234.2z"/>
					</defs>
					<clipPath id="SVGID_00000075868393942462848510000004443457846905715621_">
						<use xlink:href="#SVGID_00000142879572714579388960000008278787204555737250_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000075868393942462848510000004443457846905715621_);enable-background:new    ;">
						<path class="st456" d="M343.2,234.2l-1.8,0.8c0,0-0.1-0.2-0.2-0.4l1.8-0.8C343.1,233.9,343.2,234.1,343.2,234.2"/>
						<path class="st457" d="M343,233.7l-1.8,0.8c-0.4-0.9-1.5-3.2-3.7-4.8l1.1-1.6C341.2,230,342.6,232.6,343,233.7"/>
						<path class="st456" d="M338.6,228.1l-1.1,1.6c-1-0.8-2.3-1.4-3.9-1.7l0.4-2C335.9,226.4,337.4,227.2,338.6,228.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000083053647676102869670000016232235266975578533_" class="st1" d="M351.5,7.7L351.5,7.7
							c0.8,0,1.6,0.1,2.4,0.5l0,0C353.1,7.9,352.2,7.7,351.5,7.7z"/>
					</defs>
					<clipPath id="SVGID_00000072961019294972643970000008723639305357942920_">
						<use xlink:href="#SVGID_00000083053647676102869670000016232235266975578533_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000072961019294972643970000008723639305357942920_);enable-background:new    ;">
						<path class="st473" d="M351.5,7.7L351.5,7.7c0.1,0,0.1,0,0.2,0h0C351.6,7.7,351.6,7.7,351.5,7.7"/>
						<path class="st472" d="M351.7,7.7L351.7,7.7c0.3,0,0.6,0,0.9,0.1h0C352.3,7.8,352,7.7,351.7,7.7"/>
						<path class="st471" d="M352.6,7.8L352.6,7.8c0.3,0.1,0.7,0.1,1,0.3h0C353.2,8,352.9,7.9,352.6,7.8"/>
						<path class="st470" d="M353.6,8.1L353.6,8.1c0.1,0,0.2,0.1,0.3,0.1h0C353.8,8.2,353.7,8.1,353.6,8.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000127743555018212480990000009605605894984515493_" class="st1" d="M348,245.2l-1.8,0.8
							c-3.2-7.3-4.7-10.8-4.8-11l1.8-0.8C343.3,234.3,344.8,237.8,348,245.2z"/>
					</defs>
					<clipPath id="SVGID_00000128484567779141247970000006987149013686413952_">
						<use xlink:href="#SVGID_00000127743555018212480990000009605605894984515493_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000128484567779141247970000006987149013686413952_);enable-background:new    ;">
						<path class="st457" d="M348,245.2l-1.8,0.8c-3.2-7.3-4.7-10.8-4.8-11l1.8-0.8C343.3,234.3,344.8,237.8,348,245.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000044178454821165556650000012084723382001839254_" class="st1" d="M358,250.6L358,250.6
							c-0.1,0-1.5,0.5-3.5,0.5l0,0C356.5,251.1,357.9,250.7,358,250.6z"/>
					</defs>
					<clipPath id="SVGID_00000075156081088506362960000010045194077224958361_">
						<use xlink:href="#SVGID_00000044178454821165556650000012084723382001839254_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000075156081088506362960000010045194077224958361_);enable-background:new    ;">
						<path class="st475" d="M358,250.6L358,250.6c-0.1,0-1.5,0.5-3.5,0.5h0C356.5,251.1,357.9,250.7,358,250.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000143610136175448104780000010941221499141154199_" class="st1" d="M341.2,26.9l-1.9-0.7
							c1.8-4.7,4.6-11.7,4.8-12.1c0.1-0.3,2.5-6.3,7.4-6.3c0.8,0,1.6,0.1,2.4,0.5l-0.7,1.9c-0.6-0.3-1.2-0.4-1.7-0.4
							c-3.4-0.1-5.4,4.8-5.5,5C345.8,15.1,343,22.2,341.2,26.9z"/>
					</defs>
					<clipPath id="SVGID_00000041291508449041911520000012471081820650714030_">
						<use xlink:href="#SVGID_00000143610136175448104780000010941221499141154199_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000041291508449041911520000012471081820650714030_);enable-background:new    ;">
						<path class="st455" d="M341.2,26.9l-1.9-0.7c1.8-4.7,4.6-11.7,4.8-12.1c0,0,0-0.1,0.1-0.1l1.8,0.8c0,0.1,0,0.1,0,0.1
							C345.8,15.1,343,22.2,341.2,26.9"/>
						<path class="st454" d="M346,14.6l-1.8-0.8c0.2-0.4,0.7-1.5,1.5-2.7l1.5,1.3C346.6,13.4,346.1,14.3,346,14.6"/>
						<path class="st453" d="M347.3,12.4l-1.5-1.3c0.4-0.5,0.9-1.1,1.4-1.6l1.2,1.6C347.9,11.6,347.6,12,347.3,12.4"/>
						<path class="st452" d="M348.3,11.2l-1.2-1.6c0.3-0.3,0.7-0.6,1.1-0.9l0.9,1.8C348.9,10.7,348.6,10.9,348.3,11.2"/>
						<path class="st451" d="M349.2,10.5l-0.9-1.8c0.3-0.2,0.6-0.4,0.9-0.5l0.7,1.9C349.6,10.2,349.4,10.3,349.2,10.5"/>
						<path class="st450" d="M349.8,10.1l-0.7-1.9c0.3-0.1,0.6-0.2,0.9-0.3l0.4,1.9C350.2,9.9,350,10,349.8,10.1"/>
						<path class="st449" d="M350.5,9.9L350,7.9c0.3-0.1,0.6-0.1,0.9-0.2l0.2,2C350.9,9.8,350.7,9.8,350.5,9.9"/>
						<path class="st448" d="M351.1,9.7l-0.2-2c0.2,0,0.4,0,0.6,0c0.1,0,0.3,0,0.4,0l-0.1,2c-0.1,0-0.2,0-0.3,0
							C351.4,9.7,351.2,9.7,351.1,9.7"/>
						<path class="st447" d="M351.7,9.7l0.1-2c0.4,0,0.7,0.1,1.1,0.2l-0.5,1.9C352.2,9.8,352,9.8,351.7,9.7"/>
						<path class="st446" d="M352.5,9.9l0.5-1.9c0.3,0.1,0.6,0.2,0.9,0.3l-0.7,1.9C353,10,352.7,9.9,352.5,9.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000072245571045048287300000010821214274529619080_" class="st1" d="M357.2,248.8c0.2,0.4,0.4,0.9,0.5,1.3
							l0.2,0.6c-0.1,0-1.5,0.5-3.5,0.5c-2.8,0-6.4-0.9-8.3-5.1l1.8-0.8c1.4,3.2,4.2,3.9,6.5,3.9
							C356.1,249.1,357.2,248.8,357.2,248.8z"/>
					</defs>
					<clipPath id="SVGID_00000122680820327797828780000017569007602582631092_">
						<use xlink:href="#SVGID_00000072245571045048287300000010821214274529619080_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000122680820327797828780000017569007602582631092_);enable-background:new    ;">
						<polyline class="st475" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st474" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st473" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st472" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st471" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st470" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st469" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st468" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st467" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st466" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st465" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st464" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st463" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st462" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st461" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st460" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st459" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st458" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st457" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st456" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<polyline class="st455" points="357.2,248.8 358,250.6 358,250.6 357.2,248.8 357.2,248.8 						"/>
						<path class="st454" d="M357.2,248.8l0.8,1.8c-0.1,0-0.4,0.1-1,0.2l-0.4-1.9C357,248.9,357.2,248.8,357.2,248.8"/>
						<path class="st455" d="M356.6,248.9l0.4,1.9c-0.6,0.1-1.5,0.2-2.5,0.2c-0.4,0-0.9,0-1.3-0.1l0.3-2c0.4,0,0.7,0.1,1.1,0.1
							C355.3,249.1,356.1,249,356.6,248.9"/>
						<path class="st456" d="M353.4,249.1l-0.3,2c-1.4-0.2-3-0.6-4.4-1.7l1.2-1.6C351,248.6,352.2,248.9,353.4,249.1"/>
						<path class="st457" d="M350,247.8l-1.2,1.6c-1-0.8-1.9-1.9-2.6-3.4l1.8-0.8C348.5,246.3,349.2,247.2,350,247.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000080207851241173446380000001197842832387488136_" class="st1" d="M353.9,8.2L353.9,8.2
							c6.5,2.6,26.8,10.6,27.9,11h0C380.8,18.8,360.4,10.8,353.9,8.2z"/>
					</defs>
					<clipPath id="SVGID_00000081629347999992570440000013271683032323859884_">
						<use xlink:href="#SVGID_00000080207851241173446380000001197842832387488136_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000081629347999992570440000013271683032323859884_);enable-background:new    ;">
						<path class="st470" d="M353.9,8.2L353.9,8.2c6.5,2.6,26.8,10.6,27.9,11h0C380.8,18.8,360.4,10.8,353.9,8.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000132077355352719243310000001628668369438688447_" class="st1" d="M382.5,42.1L382.5,42.1
							c-0.1,0.2-1.8,3.8-0.6,7.8l0,0C380.7,45.9,382.4,42.3,382.5,42.1z"/>
					</defs>
					<clipPath id="SVGID_00000075884689040684459000000006907567097289159588_">
						<use xlink:href="#SVGID_00000132077355352719243310000001628668369438688447_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000075884689040684459000000006907567097289159588_);enable-background:new    ;">
						<path class="st474" d="M382.5,42.1L382.5,42.1c0,0.1-0.2,0.4-0.4,1h0C382.3,42.5,382.5,42.1,382.5,42.1"/>
						<path class="st473" d="M382.1,43.1L382.1,43.1c-0.1,0.5-0.3,1.1-0.5,1.9h0C381.8,44.2,381.9,43.5,382.1,43.1"/>
						<path class="st472" d="M381.6,44.9L381.6,44.9c-0.1,0.5-0.1,1.1-0.2,1.8h0C381.5,46.1,381.5,45.5,381.6,44.9"/>
						<path class="st471" d="M381.5,46.7L381.5,46.7c0,0.5,0,1.1,0.1,1.7h0C381.5,47.8,381.5,47.2,381.5,46.7"/>
						<path class="st470" d="M381.6,48.4L381.6,48.4c0.1,0.5,0.2,1,0.3,1.5h0C381.7,49.3,381.6,48.8,381.6,48.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000145741939697418608550000000685914839315434899_" class="st1" d="M385.5,238.7L385.5,238.7
							c-6.4,2.8-26.5,11.5-27.5,11.9l0,0C359,250.2,379.1,241.5,385.5,238.7z"/>
					</defs>
					<clipPath id="SVGID_00000040546349165274683430000013970623366288488593_">
						<use xlink:href="#SVGID_00000145741939697418608550000000685914839315434899_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000040546349165274683430000013970623366288488593_);enable-background:new    ;">
						<path class="st475" d="M385.5,238.7L385.5,238.7c-6.4,2.8-26.5,11.5-27.5,11.9h0C359,250.2,379.1,241.5,385.5,238.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000042728068039704305580000017326540303184253119_" class="st1" d="M385.7,205.9L385.7,205.9
							c-1.6,2.3-3.9,6.6-2.6,9.8h0C381.8,212.5,384.2,208.2,385.7,205.9z"/>
					</defs>
					<clipPath id="SVGID_00000068642240280228321600000004253486503281229465_">
						<use xlink:href="#SVGID_00000042728068039704305580000017326540303184253119_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000068642240280228321600000004253486503281229465_);enable-background:new    ;">
						<path class="st475" d="M385.7,205.9L385.7,205.9c-0.4,0.6-0.8,1.3-1.2,2.1h0C384.9,207.2,385.3,206.5,385.7,205.9"/>
						<path class="st474" d="M384.5,208L384.5,208c-0.4,0.8-0.9,1.8-1.2,2.7h0C383.6,209.7,384,208.8,384.5,208"/>
						<path class="st473" d="M383.3,210.7L383.3,210.7c-0.2,0.5-0.3,1.1-0.4,1.6h0C383,211.7,383.1,211.2,383.3,210.7"/>
						<path class="st472" d="M382.9,212.3L382.9,212.3c-0.1,0.4-0.1,0.8-0.1,1.1h0C382.8,213,382.8,212.6,382.9,212.3"/>
						<path class="st471" d="M382.8,213.4L382.8,213.4c0,0.3,0,0.6,0.1,1h0C382.8,214,382.7,213.7,382.8,213.4"/>
						<path class="st470" d="M382.8,214.4L382.8,214.4c0.1,0.3,0.1,0.6,0.2,0.9h0C382.9,215,382.8,214.7,382.8,214.4"/>
						<path class="st469" d="M383,215.3L383,215.3c0.1,0.2,0.1,0.3,0.2,0.4h0C383.1,215.6,383.1,215.4,383,215.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000093891196605608224780000016831634545461572756_" class="st1" d="M386.9,30.9L386.9,30.9
							c-2.9,7.4-4.3,11-4.4,11.2l0,0C382.6,41.9,384,38.4,386.9,30.9z"/>
					</defs>
					<clipPath id="SVGID_00000070838490377525607250000005845440626359540925_">
						<use xlink:href="#SVGID_00000093891196605608224780000016831634545461572756_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000070838490377525607250000005845440626359540925_);enable-background:new    ;">
						<path class="st474" d="M386.9,30.9L386.9,30.9c-2.9,7.4-4.3,11-4.4,11.2h0C382.6,41.9,384,38.4,386.9,30.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000062902616674915799080000012944532158384937878_" class="st1" d="M383.2,215.7L383.2,215.7
							c2,4.6,5,11.6,5.2,11.9h0C388.2,227.3,385.2,220.3,383.2,215.7z"/>
					</defs>
					<clipPath id="SVGID_00000007424881608136481710000007613676814074024379_">
						<use xlink:href="#SVGID_00000062902616674915799080000012944532158384937878_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000007424881608136481710000007613676814074024379_);enable-background:new    ;">
						<path class="st469" d="M383.2,215.7L383.2,215.7c2,4.6,5,11.6,5.2,11.9h0C388.2,227.3,385.2,220.3,383.2,215.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000137826491988418651630000009925019489290265020_" class="st1" d="M380.6,41.3l1.9,0.7
							c-0.1,0.2-1.8,3.8-0.6,7.8l-1.7,1.2C378.6,45.7,380.6,41.5,380.6,41.3z"/>
					</defs>
					<clipPath id="SVGID_00000010992998460918722920000010435320332302785182_">
						<use xlink:href="#SVGID_00000137826491988418651630000009925019489290265020_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000010992998460918722920000010435320332302785182_);enable-background:new    ;">
						<path class="st449" d="M380.6,41.3l1.9,0.7c0,0.1-0.2,0.4-0.3,0.8l-1.9-0.6C380.5,41.7,380.6,41.4,380.6,41.3"/>
						<path class="st448" d="M380.3,42.3l1.9,0.6c-0.2,0.5-0.3,1.1-0.5,1.8l-1.9-0.3C379.9,43.5,380.1,42.8,380.3,42.3"/>
						<path class="st447" d="M379.7,44.4l1.9,0.3c-0.1,0.6-0.2,1.2-0.2,1.9l-1.9,0.2C379.5,45.9,379.6,45.1,379.7,44.4"/>
						<path class="st446" d="M379.5,46.8l1.9-0.2c0,0.8,0,1.7,0.2,2.6l-1.8,1C379.6,48.9,379.5,47.8,379.5,46.8"/>
						<path class="st445" d="M379.9,50.1l1.8-1c0,0.2,0.1,0.4,0.2,0.7l-1.7,1.2C380,50.7,379.9,50.4,379.9,50.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000155120743339060645810000001698599440142442684_" class="st1" d="M381.8,19.2L381.8,19.2
							c0.4,0.2,8,4.2,5.1,11.7h0C389.8,23.5,382.2,19.4,381.8,19.2z"/>
					</defs>
					<clipPath id="SVGID_00000034787871409575796100000006099482514309186721_">
						<use xlink:href="#SVGID_00000155120743339060645810000001698599440142442684_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000034787871409575796100000006099482514309186721_);enable-background:new    ;">
						<path class="st470" d="M381.8,19.2L381.8,19.2c0,0,0.1,0,0.2,0.1l0,0C381.9,19.3,381.9,19.2,381.8,19.2"/>
						<path class="st469" d="M382,19.3L382,19.3c0.8,0.5,4.1,2.5,5.2,5.9h0C386.1,21.8,382.8,19.8,382,19.3"/>
						<path class="st470" d="M387.2,25.2L387.2,25.2c0.1,0.4,0.3,0.9,0.3,1.3h0C387.4,26.1,387.3,25.7,387.2,25.2"/>
						<path class="st471" d="M387.5,26.6L387.5,26.6c0.1,0.4,0.1,0.8,0.1,1.2h0C387.6,27.4,387.6,27,387.5,26.6"/>
						<path class="st472" d="M387.6,27.8L387.6,27.8c0,0.4,0,0.8-0.1,1.2h0C387.5,28.6,387.6,28.2,387.6,27.8"/>
						<path class="st473" d="M387.5,29L387.5,29c-0.1,0.5-0.2,0.9-0.3,1.4h0C387.3,29.9,387.4,29.4,387.5,29"/>
						<path class="st474" d="M387.1,30.4L387.1,30.4c0,0.2-0.1,0.4-0.2,0.5h0C387,30.7,387,30.6,387.1,30.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000039119256761998631940000016463717910752761786_" class="st1" d="M353.2,10.1l0.7-1.9
							c6.5,2.6,26.8,10.6,27.9,11l-0.7,1.9C380,20.7,359.7,12.7,353.2,10.1z"/>
					</defs>
					<clipPath id="SVGID_00000150082933749051011540000014585642984626260402_">
						<use xlink:href="#SVGID_00000039119256761998631940000016463717910752761786_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000150082933749051011540000014585642984626260402_);enable-background:new    ;">
						<path class="st446" d="M353.2,10.1l0.7-1.9c6.5,2.6,26.8,10.6,27.9,11l-0.7,1.9C380,20.7,359.7,12.7,353.2,10.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000149346028913253314820000013050190826907632303_" class="st1" d="M388.3,227.7L388.3,227.7
							c0.2,0.4,3.6,8.3-2.8,11h0C391.9,235.9,388.5,228.1,388.3,227.7z"/>
					</defs>
					<clipPath id="SVGID_00000005246340298707607420000012116284890806554034_">
						<use xlink:href="#SVGID_00000149346028913253314820000013050190826907632303_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000005246340298707607420000012116284890806554034_);enable-background:new    ;">
						<path class="st469" d="M388.3,227.7L388.3,227.7c0.1,0.1,0.2,0.5,0.4,1.2h0C388.6,228.2,388.4,227.7,388.3,227.7"/>
						<path class="st470" d="M388.8,228.8L388.8,228.8c0.2,0.6,0.4,1.5,0.6,2.4h0C389.2,230.3,389,229.4,388.8,228.8"/>
						<path class="st471" d="M389.3,231.2L389.3,231.2c0.1,0.5,0.1,1,0.1,1.6h0C389.4,232.2,389.4,231.7,389.3,231.2"/>
						<path class="st472" d="M389.4,232.8L389.4,232.8c0,0.4,0,0.8-0.1,1.2h0C389.4,233.6,389.4,233.2,389.4,232.8"/>
						<path class="st473" d="M389.3,234L389.3,234c0,0.3-0.1,0.7-0.2,1h0C389.2,234.7,389.2,234.3,389.3,234"/>
						<path class="st474" d="M389,235L389,235c-0.1,0.3-0.2,0.7-0.4,1h0C388.8,235.7,388.9,235.3,389,235"/>
						<path class="st475" d="M388.6,236L388.6,236c-0.6,1.1-1.6,2-3.1,2.7h0C387.1,238,388,237.1,388.6,236"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000075871781226572344220000007916116039122657459_" class="st1" d="M384.4,204.3l1.3,1.6
							c-1.6,2.3-4,6.6-2.6,9.8l-1.8,0.8C379.5,212.2,382.4,207.3,384.4,204.3z"/>
					</defs>
					<clipPath id="SVGID_00000152256143659092106430000013333310113267569057_">
						<use xlink:href="#SVGID_00000075871781226572344220000007916116039122657459_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000152256143659092106430000013333310113267569057_);enable-background:new    ;">
						<path class="st450" d="M384.4,204.3l1.3,1.6c-0.4,0.7-1,1.5-1.4,2.4l-1.7-1C383.2,206.2,383.9,205.2,384.4,204.3"/>
						<path class="st449" d="M382.6,207.3l1.7,1c-0.4,0.7-0.7,1.5-1,2.2l-1.9-0.5C381.7,209.1,382.2,208.1,382.6,207.3"/>
						<path class="st448" d="M381.4,210l1.9,0.5c-0.2,0.6-0.3,1.1-0.4,1.7l-2-0.2C381,211.4,381.2,210.7,381.4,210"/>
						<path class="st447" d="M380.9,212.1l2,0.2c-0.1,0.5-0.1,0.9-0.1,1.3l-2,0.2C380.8,213.2,380.8,212.6,380.9,212.1"/>
						<path class="st446" d="M380.8,213.7l2-0.2c0,0.4,0,0.9,0.1,1.3l-1.9,0.5C380.8,214.8,380.8,214.3,380.8,213.7"/>
						<path class="st445" d="M381,215.4l1.9-0.5c0.1,0.3,0.2,0.6,0.3,0.9l-1.8,0.8C381.2,216.2,381.1,215.8,381,215.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000151504334970619513090000011800572656773309872_" class="st1" d="M384.7,236.9l0.8,1.8
							c-6.4,2.8-26.5,11.5-27.5,11.9l-0.8-1.8C358.3,248.3,378.3,239.6,384.7,236.9z"/>
					</defs>
					<clipPath id="SVGID_00000049211431236983585420000002128048526968203156_">
						<use xlink:href="#SVGID_00000151504334970619513090000011800572656773309872_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000049211431236983585420000002128048526968203156_);enable-background:new    ;">
						<path class="st453" d="M384.7,236.9l0.8,1.8c-6.4,2.8-26.5,11.5-27.5,11.9l-0.8-1.8C358.3,248.3,378.3,239.6,384.7,236.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000168820761275563028660000002865235576055230348_" class="st1" d="M385,30.2l1.9,0.7
							c-2.9,7.4-4.3,11-4.4,11.2l-1.9-0.7C380.7,41.2,382.1,37.6,385,30.2z"/>
					</defs>
					<clipPath id="SVGID_00000156579804878133683200000001765257248562171576_">
						<use xlink:href="#SVGID_00000168820761275563028660000002865235576055230348_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000156579804878133683200000001765257248562171576_);enable-background:new    ;">
						<path class="st449" d="M385,30.2l1.9,0.7c-2.9,7.4-4.3,11-4.4,11.2l-1.9-0.7C380.7,41.2,382.1,37.6,385,30.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000088134569626828621380000002302000805789956518_" class="st1" d="M381.1,21.1l0.7-1.9
							c0.4,0.2,8,4.2,5.1,11.7l-1.9-0.7C387.4,24.5,381.2,21.2,381.1,21.1z"/>
					</defs>
					<clipPath id="SVGID_00000115511089034514204270000008456154233669310365_">
						<use xlink:href="#SVGID_00000088134569626828621380000002302000805789956518_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000115511089034514204270000008456154233669310365_);enable-background:new    ;">
						<path class="st445" d="M381.1,21.1l0.7-1.9c0.3,0.2,4.4,2.4,5.5,6.4l-2,0.5C384.5,22.9,381.2,21.1,381.1,21.1"/>
						<path class="st446" d="M385.4,26.1l2-0.5c0.1,0.6,0.2,1.1,0.2,1.8l-2,0.1C385.5,27,385.5,26.6,385.4,26.1"/>
						<path class="st447" d="M385.6,27.5l2-0.1c0,0.5,0,1-0.1,1.5l-2-0.3C385.5,28.3,385.6,27.9,385.6,27.5"/>
						<path class="st448" d="M385.5,28.6l2,0.3c-0.1,0.5-0.2,0.9-0.4,1.4l-1.9-0.6C385.3,29.4,385.4,29,385.5,28.6"/>
						<path class="st449" d="M385.2,29.8l1.9,0.6c-0.1,0.2-0.1,0.4-0.2,0.6l-1.9-0.7C385.1,30,385.1,29.9,385.2,29.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000063623812818979178990000013507386402169559702_" class="st1" d="M381.3,216.5l1.8-0.8
							c2,4.6,5,11.6,5.2,11.9c0.2,0.4,3.6,8.3-2.8,11l-0.8-1.8c4.5-1.8,1.9-8.1,1.8-8.4C386.4,228.1,383.3,221.1,381.3,216.5z"/>
					</defs>
					<clipPath id="SVGID_00000181062972547190546880000017145150781035892881_">
						<use xlink:href="#SVGID_00000063623812818979178990000013507386402169559702_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000181062972547190546880000017145150781035892881_);enable-background:new    ;">
						<path class="st445" d="M381.3,216.5l1.8-0.8c2,4.6,5,11.6,5.2,11.9c0.1,0.1,0.4,0.9,0.7,2l-2,0.4c-0.2-0.9-0.5-1.5-0.5-1.6
							C386.4,228.1,383.3,221.1,381.3,216.5"/>
						<path class="st446" d="M387,230.1l2-0.4c0.2,0.8,0.4,1.7,0.4,2.7l-2-0.2C387.3,231.4,387.2,230.7,387,230.1"/>
						<path class="st447" d="M387.4,232.2l2,0.2c0,0.5,0,1.1-0.1,1.6l-2-0.6C387.4,233,387.4,232.6,387.4,232.2"/>
						<path class="st448" d="M387.3,233.4l2,0.6c-0.1,0.4-0.2,0.8-0.3,1.1l-1.9-0.8C387.2,234,387.3,233.7,387.3,233.4"/>
						<path class="st449" d="M387.1,234.2l1.9,0.8c-0.1,0.3-0.2,0.6-0.4,0.9l-1.7-1.1C387,234.7,387.1,234.5,387.1,234.2"/>
						<path class="st450" d="M386.9,234.9l1.7,1.1c-0.1,0.3-0.3,0.5-0.5,0.7l-1.6-1.3C386.7,235.3,386.8,235.1,386.9,234.9"/>
						<path class="st451" d="M386.6,235.5l1.6,1.3c-0.2,0.2-0.4,0.5-0.6,0.7l-1.4-1.4C386.3,235.8,386.4,235.6,386.6,235.5"/>
						<path class="st452" d="M386.1,235.9l1.4,1.4c-0.2,0.2-0.5,0.4-0.8,0.7l-1.2-1.6C385.8,236.3,386,236.1,386.1,235.9"/>
						<path class="st453" d="M385.6,236.4l1.2,1.6c-0.4,0.2-0.8,0.5-1.2,0.7l-0.8-1.8C385,236.7,385.3,236.6,385.6,236.4"/>
						<polyline class="st454" points="384.7,236.9 385.5,238.7 385.5,238.7 384.7,236.9 384.7,236.9 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000033365912136953779160000013834030055670877884_" class="st1" d="M381.9,49.8L381.9,49.8
							c7,5.2,13.1,11.2,18.4,17.8l0,0C395,61,388.8,55,381.9,49.8z"/>
					</defs>
					<clipPath id="SVGID_00000018220729891046916640000017259950997270972802_">
						<use xlink:href="#SVGID_00000033365912136953779160000013834030055670877884_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000018220729891046916640000017259950997270972802_);enable-background:new    ;">
						<path class="st469" d="M381.9,49.8L381.9,49.8c7,5.2,13.1,11.2,18.4,17.8h0C395,61,388.8,55,381.9,49.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000075845701524503291770000006300599561101890217_" class="st1" d="M400.8,190.4L400.8,190.4
							c-4.5,5.8-9.6,11-15.1,15.5l0,0C391.3,201.3,396.3,196.1,400.8,190.4z"/>
					</defs>
					<clipPath id="SVGID_00000010269701352702944860000008297932416962658742_">
						<use xlink:href="#SVGID_00000075845701524503291770000006300599561101890217_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000010269701352702944860000008297932416962658742_);enable-background:new    ;">
						<path class="st475" d="M400.8,190.4L400.8,190.4c-4.5,5.8-9.6,11-15.1,15.5h0C391.3,201.3,396.3,196.1,400.8,190.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000062885416352577127110000017477581166512563076_" class="st1" d="M405,189.5L405,189.5
							c-1.3,0-2.7,0.2-4.1,0.9l0,0C402.2,189.7,403.7,189.5,405,189.5z"/>
					</defs>
					<clipPath id="SVGID_00000104667497880922123490000009388104881744498579_">
						<use xlink:href="#SVGID_00000062885416352577127110000017477581166512563076_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000104667497880922123490000009388104881744498579_);enable-background:new    ;">
						<path class="st475" d="M405,189.5L405,189.5c-1.3,0-2.7,0.2-4.1,0.9h0C402.2,189.7,403.7,189.5,405,189.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000050620535297938342820000007885057541502651018_" class="st1" d="M400.2,67.7L400.2,67.7
							c1.6,0.8,3.6,1.6,5.4,1.6l0,0C403.8,69.2,401.8,68.5,400.2,67.7z"/>
					</defs>
					<clipPath id="SVGID_00000164501584008308590920000006819784219917434797_">
						<use xlink:href="#SVGID_00000050620535297938342820000007885057541502651018_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000164501584008308590920000006819784219917434797_);enable-background:new    ;">
						<path class="st470" d="M400.2,67.7L400.2,67.7c0.7,0.4,1.5,0.7,2.3,1h0C401.7,68.4,400.9,68,400.2,67.7"/>
						<path class="st471" d="M402.5,68.7L402.5,68.7c0.6,0.2,1.1,0.3,1.6,0.4h0C403.6,69,403.1,68.8,402.5,68.7"/>
						<path class="st472" d="M404.2,69.1L404.2,69.1c0.4,0.1,0.8,0.1,1.2,0.1h0C404.9,69.2,404.6,69.2,404.2,69.1"/>
						<path class="st473" d="M405.3,69.2L405.3,69.2c0.1,0,0.2,0,0.2,0h0C405.5,69.2,405.4,69.2,405.3,69.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000106129146298845371380000004093498723524704404_" class="st1" d="M380.1,51l1.7-1.2
							C388.8,55,395,61,400.2,67.7l-1.6,1.3C393.5,62.4,387.2,56.5,380.1,51z"/>
					</defs>
					<clipPath id="SVGID_00000116233374838255753690000015922337373257741243_">
						<use xlink:href="#SVGID_00000106129146298845371380000004093498723524704404_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000116233374838255753690000015922337373257741243_);enable-background:new    ;">
						<path class="st445" d="M380.1,51l1.7-1.2C388.8,55,395,61,400.2,67.7l-1.6,1.3C393.5,62.4,387.2,56.5,380.1,51"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000037671040728197739080000004145822622383166654_" class="st1" d="M399.6,188.7l1.3,1.6
							c-4.5,5.8-9.6,11-15.1,15.5l-1.3-1.6C389.9,199.9,394.9,194.6,399.6,188.7z"/>
					</defs>
					<clipPath id="SVGID_00000034072285608996318220000012716624660149655207_">
						<use xlink:href="#SVGID_00000037671040728197739080000004145822622383166654_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000034072285608996318220000012716624660149655207_);enable-background:new    ;">
						<path class="st451" d="M399.6,188.7l1.3,1.6c-3,3.8-6.2,7.4-9.6,10.7l-1.4-1.5C393.2,196.2,396.4,192.6,399.6,188.7"/>
						<path class="st452" d="M389.8,199.5l1.4,1.5c-1.8,1.7-3.6,3.3-5.5,4.8l-1.3-1.6C386.3,202.8,388.1,201.2,389.8,199.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000050664326276013122150000013565191133445637271_" class="st1" d="M398.6,68.9l1.6-1.3
							c1.6,0.8,3.5,1.6,5.3,1.6c0.7,0,1.5-0.1,2.1-0.4l0.8,1.8c-1,0.4-2,0.6-2.9,0.6C403.3,71.2,401.1,70.4,398.6,68.9z"/>
					</defs>
					<clipPath id="SVGID_00000003790982890303519230000016015175927668773031_">
						<use xlink:href="#SVGID_00000050664326276013122150000013565191133445637271_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000003790982890303519230000016015175927668773031_);enable-background:new    ;">
						<path class="st445" d="M398.6,68.9l1.6-1.3c0.5,0.2,1,0.5,1.5,0.7l-1,1.7C400,69.7,399.3,69.3,398.6,68.9"/>
						<path class="st446" d="M400.7,70l1-1.7c0.6,0.3,1.3,0.5,2,0.6l-0.4,1.9C402.4,70.7,401.6,70.4,400.7,70"/>
						<path class="st447" d="M403.2,70.9l0.4-1.9c0.5,0.1,1,0.2,1.4,0.2l-0.1,2C404.4,71.2,403.8,71.1,403.2,70.9"/>
						<path class="st448" d="M405,71.2l0.1-2c0.2,0,0.3,0,0.5,0c0.2,0,0.4,0,0.6,0l0.2,2c-0.3,0-0.5,0-0.8,0
							C405.4,71.2,405.2,71.2,405,71.2"/>
						<path class="st449" d="M406.4,71.2l-0.2-2c0.3,0,0.6-0.1,0.8-0.1l0.5,1.9C407.1,71.1,406.7,71.2,406.4,71.2"/>
						<path class="st450" d="M407.5,71l-0.5-1.9c0.2-0.1,0.5-0.1,0.7-0.2l0.8,1.8C408.2,70.8,407.8,70.9,407.5,71"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000083793534288129154690000014811540387145805234_" class="st1" d="M410.6,188.6l-0.7,1.9
							c-0.1-0.1-2.2-1-4.9-1c-1.3,0-2.7,0.2-4.1,0.9l-1.3-1.6c2.2-1,3.9-1.3,5.4-1.3C408.2,187.5,410.6,188.6,410.6,188.6z"/>
					</defs>
					<clipPath id="SVGID_00000106126181154932476140000017678705903297133230_">
						<use xlink:href="#SVGID_00000083793534288129154690000014811540387145805234_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000106126181154932476140000017678705903297133230_);enable-background:new    ;">
						<path class="st456" d="M410.6,188.6l-0.7,1.9c-0.1,0-1.5-0.7-3.6-0.9l0.2-2C408.9,187.8,410.6,188.6,410.6,188.6"/>
						<path class="st455" d="M406.6,187.6l-0.2,2c-0.4,0-0.9-0.1-1.4-0.1c-0.6,0-1.2,0-1.8,0.2l-0.4-1.9c0.8-0.2,1.5-0.2,2.2-0.2
							C405.5,187.5,406.1,187.5,406.6,187.6"/>
						<path class="st454" d="M402.7,187.7l0.4,1.9c-0.7,0.1-1.4,0.3-2,0.6l-1.2-1.7C401,188.1,401.9,187.9,402.7,187.7"/>
						<path class="st453" d="M399.9,188.6l1.2,1.7c-0.1,0-0.2,0.1-0.3,0.1l-1.3-1.6C399.7,188.7,399.8,188.6,399.9,188.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000155143760529540605710000014240380557368616626_" class="st1" d="M420.8,116L420.8,116
							c1.1,8,1.2,16.2,0.3,24.4l0,0C422.1,132.2,421.9,124,420.8,116z"/>
					</defs>
					<clipPath id="SVGID_00000039124734257353770650000006446591191052826502_">
						<use xlink:href="#SVGID_00000155143760529540605710000014240380557368616626_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000039124734257353770650000006446591191052826502_);enable-background:new    ;">
						<path class="st470" d="M420.8,116L420.8,116c0.1,0.3,0.1,0.6,0.1,0.9h0C420.9,116.6,420.9,116.3,420.8,116"/>
						<path class="st471" d="M421,116.9L421,116.9c0.6,4.9,0.9,9.9,0.8,14.9h0C421.8,126.8,421.6,121.8,421,116.9"/>
						<path class="st472" d="M421.7,131.8L421.7,131.8c-0.1,2.9-0.3,5.7-0.6,8.6h0C421.5,137.5,421.7,134.6,421.7,131.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000005236224877550587990000002162718452424125056_" class="st1" d="M397.9,161l-1.9-0.7
							c16-40.6-3.9-86.5-44.5-102.5c-9.5-3.8-19.3-5.5-29-5.5c-31.5,0-61.2,19-73.5,50c-16,40.6,3.9,86.5,44.5,102.5
							c9.5,3.8,19.3,5.5,29,5.5c31.5,0,61.2-19,73.5-50L397.9,161c-12.6,31.9-43.1,51.3-75.4,51.3c-9.9,0-19.7-1.5-29.7-5.7
							c-41.6-16.4-62-63.5-45.6-105.1c12.6-31.8,43.1-51.3,75.4-51.3c9.9,0,19.7,1.5,29.7,5.7C393.9,72.4,414.3,119.4,397.9,161z"/>
					</defs>
					<clipPath id="SVGID_00000013167782230743859680000009935242568506050189_">
						<use xlink:href="#SVGID_00000005236224877550587990000002162718452424125056_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000013167782230743859680000009935242568506050189_);enable-background:new    ;">
						<path class="st455" d="M397.9,161l-1.9-0.7c2.6-6.7,4.3-13.6,5.1-20.4l2,0.2C402.3,147.1,400.6,154.2,397.9,161"/>
						<path class="st456" d="M403.1,140.1l-2-0.2c1.5-13.7-0.7-27.4-5.9-39.7l1.8-0.8C402.4,112,404.6,126,403.1,140.1"/>
						<path class="st457" d="M397,99.4l-1.8,0.8c-5.6-13.1-14.7-24.6-26.7-33.1l1.2-1.6C381.9,74.1,391.2,86,397,99.4"/>
						<path class="st456" d="M369.7,65.4l-1.2,1.6c-5.1-3.7-10.8-6.8-16.9-9.2c-5.4-2.1-10.8-3.6-16.3-4.5l0.3-2.1
							c5.6,0.9,11.1,2.4,16.7,4.7C358.6,58.4,364.4,61.6,369.7,65.4"/>
						<path class="st455" d="M335.5,51.3l-0.3,2.1c-4.2-0.7-8.5-1-12.7-1c-4.7,0-9.3,0.4-13.9,1.2l-0.4-2c4.7-0.8,9.4-1.3,14.2-1.3
							C326.9,50.3,331.2,50.6,335.5,51.3"/>
						<path class="st454" d="M308.4,51.5l0.4,2c-5.9,1.1-11.6,2.8-17.1,5.1l-0.8-1.8C296.5,54.4,302.3,52.6,308.4,51.5"/>
						<path class="st453" d="M290.8,56.8l0.8,1.8c-4.7,2-9.1,4.4-13.3,7.2l-1.1-1.7C281.5,61.3,286.1,58.8,290.8,56.8"/>
						<path class="st452" d="M277.2,64.2l1.1,1.7c-3.8,2.6-7.4,5.5-10.7,8.7l-1.4-1.4C269.6,69.8,273.3,66.8,277.2,64.2"/>
						<path class="st451" d="M266.2,73.1l1.4,1.4c-3.1,3-5.9,6.2-8.5,9.7l-1.6-1.2C260.2,79.4,263.1,76.1,266.2,73.1"/>
						<path class="st450" d="M257.5,83l1.6,1.2c-2.4,3.2-4.6,6.7-6.5,10.4l-1.8-0.9C252.8,89.9,255,86.3,257.5,83"/>
						<path class="st449" d="M250.8,93.6l1.8,0.9c-1.3,2.5-2.5,5.1-3.5,7.7c-0.5,1.3-1,2.5-1.4,3.8l-1.9-0.6
							c0.4-1.3,0.9-2.6,1.4-3.9C248.3,98.8,249.5,96.2,250.8,93.6"/>
						<path class="st448" d="M245.8,105.5l1.9,0.6c-1.4,4.1-2.4,8.3-3.1,12.5l-2-0.3C243.3,114,244.4,109.7,245.8,105.5"/>
						<path class="st447" d="M242.6,118.2l2,0.3c-0.8,4.7-1.1,9.4-1,14l-2,0C241.5,127.8,241.8,123,242.6,118.2"/>
						<path class="st446" d="M241.6,132.6l2,0c0.1,5.9,0.9,11.8,2.3,17.5l-1.9,0.5C242.4,144.7,241.7,138.7,241.6,132.6"/>
						<path class="st445" d="M243.9,150.5l1.9-0.5c5.3,21.8,19.9,41,41,51.7l-0.9,1.8C264.3,192.6,249.4,172.9,243.9,150.5"/>
						<path class="st446" d="M285.9,203.5l0.9-1.8c2.2,1.1,4.4,2.1,6.7,3c2.4,0.9,4.7,1.7,7.1,2.4l-0.5,2c-2.4-0.7-4.9-1.6-7.4-2.6
							C290.5,205.7,288.2,204.7,285.9,203.5"/>
						<path class="st447" d="M300.2,209.2l0.5-2c5.3,1.5,10.6,2.5,16,2.9l-0.1,2C311.1,211.7,305.6,210.8,300.2,209.2"/>
						<path class="st448" d="M316.5,212.1l0.1-2c2,0.1,4,0.2,5.9,0.2c2.6,0,5.1-0.1,7.7-0.4l0.2,2c-2.6,0.3-5.2,0.4-7.9,0.4
							C320.5,212.3,318.5,212.3,316.5,212.1"/>
						<path class="st449" d="M330.4,211.9l-0.2-2c4.2-0.4,8.3-1.1,12.3-2.2l0.5,1.9C338.9,210.8,334.7,211.5,330.4,211.9"/>
						<path class="st450" d="M343,209.7l-0.5-1.9c4-1.1,8-2.4,11.8-4.1l0.8,1.8C351.2,207.2,347.2,208.6,343,209.7"/>
						<path class="st451" d="M355.1,205.5l-0.8-1.8c4-1.8,7.8-3.8,11.5-6.2l1.1,1.7C363.2,201.5,359.2,203.7,355.1,205.5"/>
						<path class="st452" d="M366.9,199.1l-1.1-1.7c3.8-2.5,7.4-5.3,10.8-8.4l1.4,1.5C374.5,193.6,370.8,196.5,366.9,199.1"/>
						<path class="st453" d="M377.9,190.4l-1.4-1.5c3.7-3.4,7.1-7.3,10.1-11.4l1.6,1.2C385.2,183,381.7,186.9,377.9,190.4"/>
						<path class="st454" d="M388.3,178.7l-1.6-1.2c3.4-4.7,6.4-9.9,8.7-15.6l1.8,0.8C394.8,168.5,391.8,173.8,388.3,178.7"/>
						<path class="st455" d="M397.2,162.7l-1.8-0.8c0.2-0.6,0.5-1.1,0.7-1.7l1.9,0.7C397.7,161.6,397.4,162.2,397.2,162.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000012447782549584733210000007926626569830724225_" class="st1" d="M421.1,140.4L421.1,140.4
							c0.7,2.7,2.1,5.9,4.7,6.9l0,0C423.3,146.3,421.9,143.1,421.1,140.4z"/>
					</defs>
					<clipPath id="SVGID_00000074405347709043753200000015034401130419714435_">
						<use xlink:href="#SVGID_00000012447782549584733210000007926626569830724225_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000074405347709043753200000015034401130419714435_);enable-background:new    ;">
						<path class="st470" d="M421.1,140.4L421.1,140.4c0.1,0.3,0.2,0.6,0.3,0.9h0C421.3,141,421.2,140.7,421.1,140.4"/>
						<path class="st469" d="M421.4,141.3L421.4,141.3c0.7,2.2,1.9,4.6,3.9,5.7h0C423.3,146,422.1,143.6,421.4,141.3"/>
						<path class="st470" d="M425.3,147.1L425.3,147.1c0.2,0.1,0.4,0.2,0.6,0.3h0C425.7,147.3,425.5,147.2,425.3,147.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000137109093824315872720000006501554248005400996_" class="st1" d="M426.9,110.6L426.9,110.6
							c-0.2,0.1-4.1,1.5-6.1,5.3l0,0C422.8,112.1,426.7,110.7,426.9,110.6z"/>
					</defs>
					<clipPath id="SVGID_00000038401127983003255690000015062080700026041994_">
						<use xlink:href="#SVGID_00000137109093824315872720000006501554248005400996_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000038401127983003255690000015062080700026041994_);enable-background:new    ;">
						<path class="st475" d="M426.9,110.6L426.9,110.6c-0.2,0.1-4,1.5-6,5.1h0C422.9,112.1,426.7,110.7,426.9,110.6"/>
						<path class="st474" d="M421,115.7L421,115.7c0,0.1-0.1,0.2-0.1,0.2h0C420.9,115.9,420.9,115.8,421,115.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000061454275731420809370000001043891054916552329_" class="st1" d="M418.8,115.6l2.1,0.3
							c1.1,8,1.2,16.2,0.3,24.4l-2-0.2C419.9,132.3,419.9,124.1,418.8,115.6z"/>
					</defs>
					<clipPath id="SVGID_00000092440913148141630200000012274621621214335128_">
						<use xlink:href="#SVGID_00000061454275731420809370000001043891054916552329_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000092440913148141630200000012274621621214335128_);enable-background:new    ;">
						<path class="st446" d="M418.8,115.6l2.1,0.3c0.4,3,0.7,5.9,0.8,9l-2.1,0C419.5,121.8,419.2,118.7,418.8,115.6"/>
						<path class="st447" d="M419.6,124.9l2.1,0c0.2,5.1,0.1,10.3-0.5,15.5l-2-0.2C419.6,135.2,419.8,130.1,419.6,124.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000109741922366860684760000002187790966759842237_" class="st1" d="M424.5,62.6L424.5,62.6
							c2.3,0,4.9,0.8,6.2,3.8h0C429.4,63.4,426.8,62.6,424.5,62.6z"/>
					</defs>
					<clipPath id="SVGID_00000170961047712638534620000005849816960514517423_">
						<use xlink:href="#SVGID_00000109741922366860684760000002187790966759842237_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000170961047712638534620000005849816960514517423_);enable-background:new    ;">
						<path class="st473" d="M424.5,62.6L424.5,62.6c0.1,0,0.2,0,0.3,0h0C424.7,62.6,424.6,62.6,424.5,62.6"/>
						<path class="st472" d="M424.8,62.6L424.8,62.6c0.4,0,0.8,0.1,1.2,0.1h0C425.6,62.7,425.2,62.7,424.8,62.6"/>
						<path class="st471" d="M426.1,62.8L426.1,62.8c0.4,0.1,0.7,0.1,1,0.3h0C426.7,62.9,426.4,62.8,426.1,62.8"/>
						<path class="st470" d="M427.1,63L427.1,63c0.4,0.1,0.7,0.3,1,0.4h0C427.8,63.3,427.4,63.2,427.1,63"/>
						<path class="st469" d="M428.1,63.5L428.1,63.5c1.1,0.6,2,1.5,2.6,3h0C430,65,429.1,64.1,428.1,63.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000057120409111143327010000005767930235744294326_" class="st1" d="M421.8,193.1l-0.7,1.9
							c-7.4-2.9-11-4.3-11.2-4.4l0.7-1.9C410.8,188.7,414.4,190.1,421.8,193.1z"/>
					</defs>
					<clipPath id="SVGID_00000015325543626913783380000003068361988365261502_">
						<use xlink:href="#SVGID_00000057120409111143327010000005767930235744294326_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000015325543626913783380000003068361988365261502_);enable-background:new    ;">
						<path class="st456" d="M421.8,193.1l-0.7,1.9c-7.4-2.9-11-4.3-11.2-4.4l0.7-1.9C410.8,188.7,414.4,190.1,421.8,193.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000077317527206054674670000001554588039243323546_" class="st1" d="M432.7,189.8L432.7,189.8
							c-0.1,0.3-3,5.7-8.4,5.7l0,0C429.7,195.6,432.6,190.1,432.7,189.8z"/>
					</defs>
					<clipPath id="SVGID_00000151523586197469645480000009984570412219201966_">
						<use xlink:href="#SVGID_00000077317527206054674670000001554588039243323546_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000151523586197469645480000009984570412219201966_);enable-background:new    ;">
						<path class="st474" d="M432.7,189.8L432.7,189.8C432.7,189.9,432.7,189.9,432.7,189.8L432.7,189.8
							C432.7,189.9,432.7,189.9,432.7,189.8"/>
						<path class="st475" d="M432.7,189.9L432.7,189.9c-0.3,0.7-3.2,5.7-8.4,5.7h0C429.5,195.6,432.4,190.6,432.7,189.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000120521580680202536330000011644816724902601384_" class="st1" d="M419.1,140.2l2,0.2
							c0.7,2.7,2.1,5.9,4.7,6.9l-0.7,1.9C421.6,147.8,420,143.9,419.1,140.2z"/>
					</defs>
					<clipPath id="SVGID_00000115516394704754499590000009645406334917976732_">
						<use xlink:href="#SVGID_00000120521580680202536330000011644816724902601384_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000115516394704754499590000009645406334917976732_);enable-background:new    ;">
						<path class="st445" d="M419.1,140.2l2,0.2c0.7,2.5,2,5.6,4.3,6.8l-0.9,1.8C421.5,147.4,420,143.7,419.1,140.2"/>
						<path class="st446" d="M424.6,149l0.9-1.8c0.1,0.1,0.2,0.1,0.4,0.2l-0.7,1.9C425,149.1,424.8,149.1,424.6,149"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000111897953744051519810000012424551587307415724_" class="st1" d="M426.1,108.8l0.8,1.8
							c-0.2,0.1-4.1,1.5-6.1,5.3l-2.1-0.3C421.4,110.5,426,108.8,426.1,108.8z"/>
					</defs>
					<clipPath id="SVGID_00000096059052656027640590000004145083628687732136_">
						<use xlink:href="#SVGID_00000111897953744051519810000012424551587307415724_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000096059052656027640590000004145083628687732136_);enable-background:new    ;">
						<path class="st454" d="M426.1,108.8l0.8,1.8c0,0-0.1,0.1-0.3,0.1l-0.8-1.8C426,108.8,426.1,108.8,426.1,108.8"/>
						<path class="st453" d="M425.8,108.9l0.8,1.8c-0.4,0.2-1.1,0.5-2,1.1l-1.2-1.6C424.5,109.5,425.3,109.1,425.8,108.9"/>
						<path class="st452" d="M423.5,110.2l1.2,1.6c-0.5,0.3-1.1,0.8-1.6,1.3l-1.5-1.2C422.2,111.2,422.8,110.7,423.5,110.2"/>
						<path class="st451" d="M421.5,111.9l1.5,1.2c-0.4,0.4-0.8,0.8-1.2,1.3l-1.8-0.8C420.5,113,421,112.4,421.5,111.9"/>
						<path class="st450" d="M420,113.6l1.8,0.8c-0.3,0.4-0.6,0.9-0.9,1.4l-2.1-0.4C419.2,114.8,419.6,114.2,420,113.6"/>
						<path class="st449" d="M418.8,115.5l2.1,0.4c0,0,0,0.1-0.1,0.1l-2.1-0.3C418.8,115.6,418.8,115.5,418.8,115.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000035492689376770934280000005187437214495977143_" class="st1" d="M408.5,70.6l-0.8-1.8
							c4.6-2,11.6-5,11.9-5.2c0.1,0,2.3-1,4.9-1c2.3,0,4.8,0.8,6.2,3.8l-1.8,0.8c-0.9-2.1-2.6-2.6-4.3-2.6c-2.2,0-4,0.8-4.1,0.8
							C420.1,65.6,413.1,68.7,408.5,70.6z"/>
					</defs>
					<clipPath id="SVGID_00000140004872139063421060000017269960172062540735_">
						<use xlink:href="#SVGID_00000035492689376770934280000005187437214495977143_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000140004872139063421060000017269960172062540735_);enable-background:new    ;">
						<path class="st450" d="M408.5,70.6l-0.8-1.8c4.6-2,11.6-5,11.9-5.2c0.1,0,0.7-0.3,1.7-0.6l0.5,1.9c-0.8,0.2-1.4,0.4-1.4,0.5
							C420.1,65.6,413.1,68.7,408.5,70.6"/>
						<path class="st449" d="M421.8,65l-0.5-1.9c0.6-0.2,1.3-0.3,2.1-0.4l0.2,2C422.9,64.8,422.3,64.9,421.8,65"/>
						<path class="st448" d="M423.6,64.7l-0.2-2c0.4,0,0.7-0.1,1.1-0.1c0.2,0,0.4,0,0.6,0l-0.2,2c-0.2,0-0.3,0-0.5,0
							C424.2,64.6,423.9,64.7,423.6,64.7"/>
						<path class="st447" d="M425,64.7l0.2-2c0.5,0,1,0.1,1.4,0.2l-0.6,1.9C425.7,64.7,425.3,64.7,425,64.7"/>
						<path class="st446" d="M426,64.8l0.6-1.9c0.5,0.1,0.9,0.3,1.3,0.5l-1,1.8C426.6,65,426.3,64.9,426,64.8"/>
						<path class="st445" d="M426.9,65.2l1-1.8c1.1,0.6,2.1,1.5,2.8,3.1l-1.8,0.8C428.4,66.2,427.7,65.5,426.9,65.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000162319268085082433780000002122286615058546602_" class="st1" d="M437.9,105.8L437.9,105.8
							c-7.3,3.2-10.8,4.7-11,4.8l0,0C427.1,110.5,430.6,109,437.9,105.8z"/>
					</defs>
					<clipPath id="SVGID_00000116920797581312380920000015194722891742726545_">
						<use xlink:href="#SVGID_00000162319268085082433780000002122286615058546602_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000116920797581312380920000015194722891742726545_);enable-background:new    ;">
						<path class="st475" d="M437.9,105.8L437.9,105.8c-7.3,3.2-10.8,4.7-11,4.8h0C427.1,110.5,430.6,109,437.9,105.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000121973503112848203150000004265694305813273000_" class="st1" d="M425.9,147.3L425.9,147.3
							c4.7,1.8,11.7,4.6,12.1,4.8h0C437.6,152,430.5,149.2,425.9,147.3z"/>
					</defs>
					<clipPath id="SVGID_00000160170692250227026440000013641375597640208019_">
						<use xlink:href="#SVGID_00000121973503112848203150000004265694305813273000_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000160170692250227026440000013641375597640208019_);enable-background:new    ;">
						<path class="st470" d="M425.9,147.3L425.9,147.3c4.7,1.8,11.7,4.6,12.1,4.8h0C437.6,152,430.5,149.2,425.9,147.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000166635633102712498850000017350188588388816294_" class="st1" d="M430.9,189.1l1.9,0.7
							c-0.2,0.3-3,5.7-8.4,5.7c-1,0-2.1-0.2-3.2-0.6l0.7-1.9c0.9,0.4,1.7,0.5,2.5,0.5C428.4,193.6,430.9,189.1,430.9,189.1z"/>
					</defs>
					<clipPath id="SVGID_00000028302734434460446640000004588675092707559069_">
						<use xlink:href="#SVGID_00000166635633102712498850000017350188588388816294_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000028302734434460446640000004588675092707559069_);enable-background:new    ;">
						<path class="st450" d="M430.9,189.1l1.9,0.7c0,0.1-0.3,0.7-0.9,1.4l-1.6-1.1C430.6,189.5,430.9,189.1,430.9,189.1"/>
						<path class="st451" d="M430.2,190.1l1.6,1.1c-0.4,0.5-0.8,1.1-1.4,1.6l-1.3-1.5C429.5,191,429.9,190.5,430.2,190.1"/>
						<path class="st452" d="M429.1,191.4l1.3,1.5c-0.4,0.4-0.9,0.8-1.5,1.2l-1-1.7C428.3,192.1,428.7,191.8,429.1,191.4"/>
						<path class="st453" d="M427.9,192.4l1,1.7c-0.5,0.3-1,0.6-1.5,0.8l-0.7-1.9C427.1,192.9,427.5,192.6,427.9,192.4"/>
						<path class="st454" d="M426.7,193.1l0.7,1.9c-0.5,0.2-1.1,0.4-1.7,0.5l-0.3-2C425.8,193.4,426.3,193.2,426.7,193.1"/>
						<path class="st455" d="M425.4,193.5l0.3,2c-0.4,0.1-0.9,0.1-1.4,0.1c-0.3,0-0.6,0-0.9,0l0.2-2c0.2,0,0.4,0,0.7,0
							C424.7,193.6,425,193.5,425.4,193.5"/>
						<path class="st456" d="M423.6,193.5l-0.2,2c-0.7-0.1-1.5-0.3-2.4-0.6l0.7-1.9C422.5,193.3,423.1,193.5,423.6,193.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000009565800785426267630000001583643398525554841_" class="st1" d="M430.7,66.5L430.7,66.5
							c2.8,6.4,11.5,26.5,12,27.5h0C442.2,92.9,433.5,72.9,430.7,66.5z"/>
					</defs>
					<clipPath id="SVGID_00000145781146928949873170000010573146389087571850_">
						<use xlink:href="#SVGID_00000009565800785426267630000001583643398525554841_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000145781146928949873170000010573146389087571850_);enable-background:new    ;">
						<path class="st469" d="M430.7,66.5L430.7,66.5c2.8,6.4,11.5,26.5,12,27.5h0C442.2,92.9,433.5,72.9,430.7,66.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000099652769944879621560000005214565629911772558_" class="st1" d="M443.8,161.9L443.8,161.9
							c-2.6,6.5-10.6,26.8-11,27.9h0C433.2,188.8,441.2,168.5,443.8,161.9z"/>
					</defs>
					<clipPath id="SVGID_00000073679284367805393400000015164603569323378354_">
						<use xlink:href="#SVGID_00000099652769944879621560000005214565629911772558_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000073679284367805393400000015164603569323378354_);enable-background:new    ;">
						<path class="st474" d="M443.8,161.9L443.8,161.9c-2.6,6.5-10.6,26.8-11,27.9h0C433.2,188.8,441.2,168.5,443.8,161.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000118376716568704457640000011788312313329493409_" class="st1" d="M442.6,94L442.6,94
							c0.1,0.4,2.7,8.6-4.7,11.8h0C445.3,102.6,442.7,94.4,442.6,94z"/>
					</defs>
					<clipPath id="SVGID_00000172426476804157037930000006161780219853610138_">
						<use xlink:href="#SVGID_00000118376716568704457640000011788312313329493409_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000172426476804157037930000006161780219853610138_);enable-background:new    ;">
						<path class="st470" d="M442.6,94L442.6,94c0.1,0.1,0.3,0.9,0.4,2h0C442.9,94.9,442.6,94.1,442.6,94"/>
						<path class="st471" d="M443,96L443,96c0.1,0.6,0.1,1.2,0.1,1.9h0C443.2,97.2,443.1,96.6,443,96"/>
						<path class="st472" d="M443.1,98L443.1,98c0,0.5,0,1-0.1,1.6h0C443.1,99,443.1,98.5,443.1,98"/>
						<path class="st473" d="M443,99.5L443,99.5c-0.1,0.4-0.2,0.9-0.3,1.4h0C442.8,100.4,442.9,100,443,99.5"/>
						<path class="st474" d="M442.7,100.9L442.7,100.9c-0.1,0.5-0.3,0.9-0.6,1.3h0C442.3,101.8,442.5,101.3,442.7,100.9"/>
						<path class="st475" d="M442.1,102.2L442.1,102.2c-0.8,1.4-2,2.7-4.1,3.6h0C440,104.9,441.3,103.6,442.1,102.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000070805679429178182290000001956033363535867271_" class="st1" d="M437.1,104l0.8,1.8
							c-7.3,3.2-10.8,4.7-11,4.8l-0.8-1.8C426.3,108.7,429.8,107.2,437.1,104z"/>
					</defs>
					<clipPath id="SVGID_00000026863076472786103760000016421131145332921740_">
						<use xlink:href="#SVGID_00000070805679429178182290000001956033363535867271_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000026863076472786103760000016421131145332921740_);enable-background:new    ;">
						<path class="st453" d="M437.1,104l0.8,1.8c-7.3,3.2-10.8,4.7-11,4.8l-0.8-1.8C426.3,108.7,429.8,107.2,437.1,104"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000070090749600987663750000005228376047969338767_" class="st1" d="M425.1,149.2l0.7-1.9
							c4.7,1.8,11.7,4.6,12.1,4.8l-0.7,1.9C436.8,153.8,429.8,151,425.1,149.2z"/>
					</defs>
					<clipPath id="SVGID_00000063626378224324598220000005846254944943337902_">
						<use xlink:href="#SVGID_00000070090749600987663750000005228376047969338767_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000063626378224324598220000005846254944943337902_);enable-background:new    ;">
						<path class="st446" d="M425.1,149.2l0.7-1.9c4.7,1.8,11.7,4.6,12.1,4.8l-0.7,1.9C436.8,153.8,429.8,151,425.1,149.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000139269527159576089970000004854114774834488212_" class="st1" d="M438,152.1L438,152.1
							c0.4,0.2,8.4,3.3,5.8,9.8h0C446.3,155.4,438.4,152.3,438,152.1z"/>
					</defs>
					<clipPath id="SVGID_00000022541398653421442630000009025397559778948487_">
						<use xlink:href="#SVGID_00000139269527159576089970000004854114774834488212_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000022541398653421442630000009025397559778948487_);enable-background:new    ;">
						<path class="st470" d="M438,152.1L438,152.1c0.1,0,0.8,0.3,1.6,0.8h0C438.7,152.4,438.1,152.2,438,152.1"/>
						<path class="st469" d="M439.5,152.9L439.5,152.9c1.5,0.9,3.7,2.4,4.5,4.8h0C443.2,155.3,441,153.7,439.5,152.9"/>
						<path class="st470" d="M444,157.7L444,157.7c0.1,0.3,0.2,0.6,0.2,1h0C444.2,158.3,444.1,158,444,157.7"/>
						<path class="st471" d="M444.2,158.7L444.2,158.7c0,0.3,0.1,0.6,0.1,0.9h0C444.3,159.3,444.3,159,444.2,158.7"/>
						<path class="st472" d="M444.3,159.6L444.3,159.6c0,0.3,0,0.6-0.1,0.9h0C444.2,160.2,444.3,159.9,444.3,159.6"/>
						<path class="st473" d="M444.2,160.5L444.2,160.5c0,0.3-0.1,0.7-0.3,1.1h0C444,161.2,444.1,160.8,444.2,160.5"/>
						<path class="st474" d="M443.9,161.5L443.9,161.5c0,0.1-0.1,0.3-0.1,0.4h0C443.8,161.8,443.9,161.7,443.9,161.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000076585959009037619940000004436506414790200981_" class="st1" d="M428.8,67.3l1.8-0.8
							c2.8,6.4,11.5,26.5,11.9,27.5l-1.8,0.8C440.3,93.7,431.6,73.7,428.8,67.3z"/>
					</defs>
					<clipPath id="SVGID_00000126323593778742815370000001834737159918836387_">
						<use xlink:href="#SVGID_00000076585959009037619940000004436506414790200981_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000126323593778742815370000001834737159918836387_);enable-background:new    ;">
						<path class="st445" d="M428.8,67.3l1.8-0.8c2.8,6.4,11.5,26.5,11.9,27.5l-1.8,0.8C440.3,93.7,431.6,73.7,428.8,67.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000135655156178231131630000012683813465885175971_" class="st1" d="M441.9,161.2l1.9,0.7
							c-2.6,6.5-10.6,26.8-11,27.9l-1.9-0.7C431.3,188,439.3,167.7,441.9,161.2z"/>
					</defs>
					<clipPath id="SVGID_00000168833259517313100770000001796059047249726368_">
						<use xlink:href="#SVGID_00000135655156178231131630000012683813465885175971_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000168833259517313100770000001796059047249726368_);enable-background:new    ;">
						<path class="st449" d="M441.9,161.2l1.9,0.7c-2.6,6.5-10.6,26.8-11,27.9l-1.9-0.7C431.3,188,439.3,167.7,441.9,161.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000156545416352960796290000006027391718599335296_" class="st1" d="M440.8,94.8l1.8-0.8
							c0.1,0.4,2.7,8.6-4.7,11.8l-0.8-1.8C442.8,101.6,440.8,94.9,440.8,94.8z"/>
					</defs>
					<clipPath id="SVGID_00000005973811488047403150000015918762626388054692_">
						<use xlink:href="#SVGID_00000156545416352960796290000006027391718599335296_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000005973811488047403150000015918762626388054692_);enable-background:new    ;">
						<path class="st445" d="M440.8,94.8l1.8-0.8c0,0.1,0.1,0.3,0.2,0.7l-1.9,0.6C440.8,95,440.8,94.8,440.8,94.8"/>
						<path class="st446" d="M440.9,95.3l1.9-0.6c0.1,0.6,0.3,1.6,0.3,2.8l-2,0C441.1,96.5,441,95.7,440.9,95.3"/>
						<path class="st447" d="M441.1,97.4l2,0c0,0.6,0,1.3-0.1,2l-2-0.4C441.1,98.4,441.1,97.9,441.1,97.4"/>
						<path class="st448" d="M441,99l2,0.4c-0.1,0.5-0.2,1-0.4,1.5l-1.9-0.7C440.9,99.8,441,99.4,441,99"/>
						<path class="st449" d="M440.8,100.1l1.9,0.7c-0.1,0.4-0.3,0.8-0.5,1.2l-1.8-1C440.5,100.8,440.7,100.5,440.8,100.1"/>
						<path class="st450" d="M440.4,101.1l1.8,1c-0.2,0.3-0.4,0.7-0.6,1l-1.6-1.2C440.1,101.6,440.2,101.4,440.4,101.1"/>
						<path class="st451" d="M439.9,101.9l1.6,1.2c-0.2,0.3-0.5,0.6-0.8,0.9l-1.4-1.4C439.5,102.4,439.7,102.2,439.9,101.9"/>
						<path class="st452" d="M439.2,102.6l1.4,1.4c-0.3,0.3-0.7,0.6-1.1,0.9l-1.2-1.6C438.7,103.1,439,102.9,439.2,102.6"/>
						<path class="st453" d="M438.4,103.3l1.2,1.6c-0.5,0.3-1,0.6-1.6,0.9l-0.8-1.8C437.6,103.8,438,103.6,438.4,103.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000085221003962257927700000012934593539050616465_" class="st1" d="M437.2,154l0.7-1.9
							c0.4,0.2,8.4,3.3,5.8,9.8l-1.9-0.7C443.8,156.7,437.6,154.1,437.2,154z"/>
					</defs>
					<clipPath id="SVGID_00000005258505969950648480000013824408580355501704_">
						<use xlink:href="#SVGID_00000085221003962257927700000012934593539050616465_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000005258505969950648480000013824408580355501704_);enable-background:new    ;">
						<path class="st446" d="M437.2,154l0.7-1.9c0.1,0,0.5,0.2,1,0.5l-0.9,1.8C437.6,154.1,437.3,154,437.2,154"/>
						<path class="st445" d="M438,154.4l0.9-1.8c1.5,0.8,4.4,2.6,5.1,5.4l-2,0.4C441.5,156.4,439.2,155,438,154.4"/>
						<path class="st446" d="M442.1,158.4l2-0.4c0.1,0.4,0.2,0.9,0.2,1.3l-2,0C442.2,159,442.2,158.7,442.1,158.4"/>
						<path class="st447" d="M442.2,159.4l2,0c0,0.3,0,0.7-0.1,1.1l-2-0.3C442.2,159.9,442.3,159.6,442.2,159.4"/>
						<path class="st448" d="M442.2,160.1l2,0.3c-0.1,0.3-0.1,0.7-0.3,1.1l-1.9-0.6C442.1,160.6,442.2,160.4,442.2,160.1"/>
						<path class="st449" d="M442,160.9l1.9,0.6c0,0.1-0.1,0.3-0.2,0.4l-1.9-0.7C441.9,161.1,442,161,442,160.9"/>
					</g>
				</g>
				<g>
					<path class="st458" d="M437.2,154c0.3,0.1,6.6,2.7,4.7,7.2c-2.6,6.5-10.6,26.8-11,27.9c0,0-2.4,4.5-6.6,4.4
						c-0.7,0-1.6-0.1-2.5-0.5c-7.4-2.9-11-4.3-11.2-4.4c0,0-2.5-1.2-5.7-1.2c-1.5,0-3.3,0.3-5.4,1.3c-4.7,5.8-9.7,11.1-15.1,15.6
						c-2.1,2.9-4.9,7.9-3.1,12.2c2,4.6,5,11.6,5.2,11.9c0.1,0.3,2.7,6.6-1.8,8.4c-6.4,2.8-26.5,11.5-27.5,11.9
						c0.2-0.1-1,0.3-2.7,0.3c-2.3,0-5-0.7-6.5-3.9c-3.2-7.3-4.7-10.8-4.8-11c-0.1-0.3-2.4-6.8-9.2-8.1c-4.2,0.4-8,0.6-11.6,0.6
						c-2.3,0-4.7-0.1-19.7-1.2c10.1,1.9,2.6,1.7,0.1,7.7c-1.8,4.7-4.6,11.7-4.8,12.1c-0.1,0.2-2.1,5.1-5.5,5c-0.5,0-1.1-0.1-1.7-0.4
						c-6.5-2.6-26.8-10.6-27.9-11c-0.1-0.1-6.3-3.4-3.9-9.1c2.9-7.4,4.3-11,4.4-11.2c0.1-0.3,3.4-7.2-1.5-13.2
						c-4.7-3.9-9.1-8-20-20.7c4.8,7.5,1.7,4-3.4,4c-1,0-2,0.2-2.9,0.6c-4.6,2-11.6,5-11.9,5.2c-0.1,0-1.9,0.8-4.1,0.8
						c-1.7,0-3.4-0.5-4.3-2.6c-2.8-6.4-11.5-26.5-11.9-27.5c0-0.1-2-6.8,3.6-9.2c7.3-3.2,10.8-4.7,11-4.8c0.3-0.1,6.9-2.5,8.1-9.3
						c-0.8-6.7-0.8-13-0.5-19.1c-0.3-2.7-1.6-10-6.6-12c-4.7-1.8-11.7-4.6-12.1-4.8c-0.3-0.1-6.6-2.7-4.7-7.2
						c2.6-6.5,10.6-26.8,11-27.9c0,0,2.4-4.5,6.6-4.4c0.7,0,1.6,0.1,2.5,0.5c7.4,2.9,11,4.3,11.2,4.4c0,0,2.5,1.2,5.7,1.2
						c1.6,0,3.4-0.3,5.6-1.3c4.8-6.1,9.9-11.7,15.5-16.3c2-3.1,4.2-7.5,2.6-11.4c-2-4.6-5-11.6-5.2-11.9c-0.1-0.3-2.7-6.6,1.8-8.4
						c6.4-2.8,26.5-11.5,27.5-11.9c-0.2,0.1,1-0.3,2.7-0.3c2.3,0,5.1,0.7,6.5,3.9c3.2,7.3,4.7,10.8,4.8,11c0.1,0.2,1.7,4.8,6.9,7.4
						c5.3-0.8,10.1-1.1,14.8-1.1c3.4,0,6.9,0.3,10.2,0.6c3.6-1,7.1-2.6,8.5-5.9c1.8-4.7,4.6-11.7,4.8-12.1c0.1-0.2,2.1-5.1,5.5-5
						c0.5,0,1.1,0.1,1.7,0.4c6.5,2.6,26.8,10.6,27.9,11c0.1,0.1,6.3,3.4,4,9.1c-2.9,7.4-4.3,11-4.4,11.2c-0.1,0.1-2,4.3-0.5,9.7
						c7.1,5.5,13.4,11.4,18.5,17.9c2.5,1.4,4.7,2.3,6.9,2.3c1,0,2-0.2,2.9-0.6c4.6-2,11.6-5,11.9-5.2c0.1,0,1.9-0.8,4.1-0.8
						c1.7,0,3.4,0.5,4.3,2.6c2.8,6.4,11.5,26.5,11.9,27.5c0,0.1,2,6.8-3.6,9.2c-7.3,3.2-10.8,4.7-11,4.8c-0.2,0.1-4.8,1.7-7.4,6.8
						c1.2,8.5,1.1,16.7,0.3,24.6c0.9,3.7,2.5,7.6,6,9C429.8,151,436.8,153.8,437.2,154z M397.9,161c16.4-41.6-4-88.7-45.6-105.1
						c-10-4.1-19.8-5.7-29.7-5.7c-32.3,0-62.8,19.4-75.4,51.3c-16.4,41.6,4,88.7,45.6,105.1c10,4.1,19.8,5.7,29.7,5.7
						C354.8,212.3,385.3,192.9,397.9,161"/>
				</g>
			</g>
		</g>
	</g>
</g>
<g class="${classes.rotatingCog}">
	<path class="st442" d="M399.6,848.8l-0.3-0.3c-0.4-0.5-2.5-3.2-3.6-7c-1.8-6-0.3-12,4.1-16.4l8.7-8.8l0,0c0.3-0.3,1-1.2,1.1-2
		c-2.4-4.6-4.5-9.4-6.2-14.3l-0.3-0.6c-0.3-0.5-1.2-2.2-2.4-3.3c-0.1-0.1-0.2-0.2-0.3-0.3l-12.2,0.1c-1.3,0-8.2-0.2-13-4.9
		c-2.7-2.6-4.1-6.1-4.1-10l-0.2-30.4l0-0.4c0.1-0.6,0.5-4,2.4-7.5c3-5.5,8.3-8.7,14.4-8.7l12.4-0.1l0,0c0.4,0,1.3-0.1,1.9-0.5
		c1.7-5.6,3.9-11.1,6.5-16.4c0.3-1.5,0.4-2.7,0.4-3.4l-8.7-8.6c-6-5.9-8.5-15.6-1.6-22.6l21.4-21.7l0.3-0.3c0.5-0.4,3.2-2.5,7-3.6
		c6-1.8,12-0.3,16.4,4.1l8.8,8.7l0,0c0.3,0.2,0.6,0.5,1,0.7c5.9-3.2,12.1-5.8,18.4-7.8c0.5-0.4,0.9-0.8,1.1-1l-0.1-12.2
		c0-0.6,0-3.9,1.4-7.4c2.3-6,7.4-9.6,13.5-9.7l30.4-0.2l0.4,0c1.1,0.1,7,0.9,11.5,5.3c3.1,3,4.7,7,4.7,11.5l0.1,12.4l0,0
		c0,0.2,0,0.4,0.1,0.6c6.9,2.1,13.6,4.8,19.9,8.2c0.1,0,0.2,0,0.3,0l8.6-8.7c5.9-6,15.6-8.5,22.6-1.6l21.7,21.4l0.3,0.3
		c0.4,0.5,2.5,3.2,3.6,7c1.8,6,0.3,12-4.1,16.4l-8.7,8.8l0,0c-0.1,0.1-0.1,0.2-0.2,0.3c3.5,6.4,6.2,13.2,8.3,20.3c0,0,0,0,0,0
		l12.2-0.1c1.3,0,8.2,0.2,13,4.9c2.7,2.6,4.1,6.1,4.1,10l0.2,30.4l0,0.4c-0.1,0.6-0.5,4-2.4,7.5c-3,5.5-8.3,8.7-14.4,8.7l-12.4,0.1
		l0,0c-0.2,0-0.4,0-0.6,0.1c-2.1,6.8-4.8,13.3-8.2,19.5c0,0.3,0,0.5,0,0.7l8.7,8.6c6,5.9,8.5,15.6,1.6,22.6l-21.4,21.7l-0.3,0.3
		c-0.5,0.4-3.2,2.5-7,3.6c-6,1.8-12,0.3-16.4-4.1l-8.8-8.7l0,0c-0.3-0.2-0.6-0.5-1-0.7c-5.7,3-11.7,5.4-17.9,7.3
		c-0.9,0.6-1.4,1.2-1.7,1.5l0.1,12.2c0,0.6,0,3.9-1.4,7.4c-2.3,6-7.4,9.6-13.5,9.7l-30.4,0.2l-0.4,0c-1.1-0.1-7-0.9-11.5-5.3
		c-3.1-3-4.7-7-4.7-11.5l-0.1-12.4l0,0c0-0.5-0.2-1.3-0.5-1.9c-5.4-1.7-10.7-3.9-15.7-6.4c-1.4-0.4-3.2-0.6-4.1-0.5l-8.6,8.7
		c-5.9,6-15.6,8.5-22.6,1.6L399.6,848.8z M553.9,715.9c-27.9-27.5-72.9-27.2-100.4,0.6c-27.5,27.9-27.2,72.9,0.6,100.4
		c27.9,27.5,72.9,27.2,100.4-0.6C582,788.4,581.8,743.4,553.9,715.9z"/>
	<g>
		<g>
			<g class="st1">
				<g class="st1">
					<defs>
						<path id="SVGID_00000163789839445437200830000003326558322153412482_" class="st1" d="M409,785.9c0.2,1.7,0.4,3.4,0.9,6.7
							c-0.4-2.7-0.9-6.4-1.3-9.1C408.8,784.5,408.9,785.3,409,785.9z"/>
					</defs>
					<clipPath id="SVGID_00000101805062655488611910000012896381438905056131_">
						<use xlink:href="#SVGID_00000163789839445437200830000003326558322153412482_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000101805062655488611910000012896381438905056131_);enable-background:new    ;">
						<path class="st497" d="M409.9,792.6l-1.3-9.1c0,0,0,0,0,0L409.9,792.6C409.9,792.6,409.9,792.6,409.9,792.6"/>
						<path class="st444" d="M409.9,792.6l-1.3-9.1c0,0,0,0,0,0L409.9,792.6C409.9,792.6,409.9,792.6,409.9,792.6"/>
						<path class="st445" d="M409.9,792.7l-1.3-9.1c0,0,0,0,0,0L409.9,792.7C409.9,792.7,409.9,792.7,409.9,792.7"/>
						<path class="st446" d="M409.9,792.7l-1.3-9c0,0,0,0,0,0L409.9,792.7C409.9,792.7,409.9,792.7,409.9,792.7"/>
						<path class="st447" d="M409.9,792.7l-1.2-9c0,0,0,0,0,0L409.9,792.7C409.9,792.7,409.9,792.7,409.9,792.7"/>
						<path class="st448" d="M409.9,792.7l-1.2-9c0,0,0,0,0,0L409.9,792.7C409.9,792.7,409.9,792.7,409.9,792.7"/>
						<path class="st449" d="M409.9,792.7l-1.2-8.9c0,0,0,0,0,0L409.9,792.7C409.9,792.7,409.9,792.7,409.9,792.7"/>
						<path class="st450" d="M409.9,792.7l-1.2-8.9c0,0,0,0,0,0L409.9,792.7C409.9,792.7,409.9,792.7,409.9,792.7"/>
						<path class="st451" d="M409.9,792.7l-1.2-8.9c0,0,0,0,0,0L409.9,792.7C409.9,792.7,409.9,792.7,409.9,792.7"/>
						<path class="st452" d="M409.9,792.7l-1.2-8.8c0,0,0,0,0,0L409.9,792.7C409.9,792.7,409.9,792.7,409.9,792.7"/>
						<path class="st453" d="M409.9,792.7l-1.2-8.8c0,0,0,0,0,0L409.9,792.7C409.9,792.7,409.9,792.7,409.9,792.7"/>
						<path class="st454" d="M409.9,792.7l-1.2-8.8c0,0,0,0,0,0L409.9,792.7C409.9,792.7,409.9,792.7,409.9,792.7"/>
						<path class="st455" d="M409.9,792.8l-1.2-8.7c0,0,0,0,0,0L409.9,792.8C409.9,792.8,409.9,792.8,409.9,792.8"/>
						<path class="st456" d="M409.9,792.8l-1.2-8.7c0,0,0,0,0,0L409.9,792.8C409.9,792.8,409.9,792.8,409.9,792.8"/>
						<path class="st457" d="M409.9,792.8l-1.2-8.7c0,0,0,0,0,0L409.9,792.8C409.9,792.8,409.9,792.8,409.9,792.8"/>
						<path class="st458" d="M409.9,792.8l-1.2-8.7c0,0,0,0,0,0L409.9,792.8C409.9,792.8,409.9,792.8,409.9,792.8"/>
						<path class="st459" d="M409.9,792.8l-1.2-8.6c0,0,0,0,0,0L409.9,792.8C409.9,792.8,409.9,792.8,409.9,792.8"/>
						<path class="st460" d="M409.9,792.8l-1.2-8.6c0,0,0,0,0,0L409.9,792.8C409.9,792.8,409.9,792.8,409.9,792.8"/>
						<path class="st461" d="M409.9,792.8l-1.2-8.6c0,0,0,0,0,0L409.9,792.8C409.9,792.8,409.9,792.8,409.9,792.8"/>
						<path class="st462" d="M409.9,792.8l-1.2-8.5c0,0,0,0,0,0L409.9,792.8C409.9,792.8,409.9,792.8,409.9,792.8"/>
						<path class="st463" d="M409.9,792.8l-1.2-8.5c0,0,0,0,0,0L409.9,792.8C409.9,792.8,409.9,792.8,409.9,792.8"/>
						<path class="st464" d="M409.9,792.8l-1.1-8.5c0,0,0,0,0,0L409.9,792.8C409.9,792.8,409.9,792.8,409.9,792.8"/>
						<path class="st465" d="M409.9,792.8l-1.1-8.4c0,0,0,0,0,0L409.9,792.8C409.9,792.8,409.9,792.8,409.9,792.8"/>
						<path class="st466" d="M409.9,792.8l-1.1-8.4c0,0,0,0,0,0L409.9,792.8C409.9,792.9,409.9,792.8,409.9,792.8"/>
						<path class="st467" d="M409.9,792.9l-1.1-8.4c0,0,0,0,0,0L409.9,792.9C409.9,792.9,409.9,792.9,409.9,792.9"/>
						<path class="st468" d="M409.9,792.9l-1.1-8.4c0,0,0,0,0,0L409.9,792.9C409.9,792.9,409.9,792.9,409.9,792.9"/>
						<path class="st469" d="M409.9,792.9l-1.1-8.3c0,0,0,0,0,0L409.9,792.9C409.9,792.9,409.9,792.9,409.9,792.9"/>
						<path class="st470" d="M409.9,792.9l-1.1-8.3c0,0,0,0,0,0L409.9,792.9C409.9,792.9,409.9,792.9,409.9,792.9"/>
						<path class="st471" d="M409.9,792.9l-1.1-8.3c0,0,0,0,0,0L409.9,792.9C409.9,792.9,409.9,792.9,409.9,792.9"/>
						<path class="st472" d="M409.9,792.9l-1.1-8.2c0,0,0,0,0,0L409.9,792.9C409.9,792.9,409.9,792.9,409.9,792.9"/>
						<path class="st473" d="M409.9,792.9l-1.1-8.2c0,0,0,0,0,0L409.9,792.9C409.9,792.9,409.9,792.9,409.9,792.9"/>
						<path class="st474" d="M409.9,792.9l-1.1-8.2c0,0,0,0,0,0L409.9,792.9C409.9,792.9,409.9,792.9,409.9,792.9"/>
						<path class="st475" d="M409.9,792.9l-1.1-8.2c0.1,0.4,0.1,0.8,0.2,1.2l0.9,7.1C409.9,793,409.9,793,409.9,792.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000072281123361950421070000016168816149492082572_" class="st1" d="M427,667.9c-0.1-0.1-0.3-0.3-0.4-0.4
							L427,667.9C427,667.9,427,667.9,427,667.9z"/>
					</defs>
					<clipPath id="SVGID_00000040538327641202255600000003841201153411531169_">
						<use xlink:href="#SVGID_00000072281123361950421070000016168816149492082572_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000040538327641202255600000003841201153411531169_);enable-background:new    ;">
						<polyline class="st475" points="426.6,667.5 427,667.9 427,667.9 426.6,667.5 426.6,667.5 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000028324588583188825260000014768092388665890946_" class="st1" d="M381.1,780.8l-2,0c0-7-0.2-28.9-0.2-30
							l2,0C380.9,751.9,381.1,773.8,381.1,780.8z"/>
					</defs>
					<clipPath id="SVGID_00000029029701686285366640000000913914567010135939_">
						<use xlink:href="#SVGID_00000028324588583188825260000014768092388665890946_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000029029701686285366640000000913914567010135939_);enable-background:new    ;">
						<path class="st456" d="M381.1,780.8l-2,0c0-7-0.2-28.9-0.2-30l2,0C380.9,751.9,381.1,773.8,381.1,780.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000023281603653327937620000014486731500879260855_" class="st1" d="M380.9,750.8l-2,0c0-0.5,0.9-9,8.9-9.1
							l0,2C381.7,743.7,380.9,750.6,380.9,750.8z"/>
					</defs>
					<clipPath id="SVGID_00000095302049504210666230000005964472929012226190_">
						<use xlink:href="#SVGID_00000023281603653327937620000014486731500879260855_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000095302049504210666230000005964472929012226190_);enable-background:new    ;">
						<path class="st455" d="M380.9,750.8l-2,0c0-0.2,0.2-1.8,0.9-3.5l1.8,0.8C381.1,749.5,380.9,750.7,380.9,750.8"/>
						<path class="st454" d="M381.7,748.1l-1.8-0.8c0.3-0.7,0.7-1.4,1.2-2.1l1.6,1.3C382.2,747,381.9,747.6,381.7,748.1"/>
						<path class="st453" d="M382.6,746.4l-1.6-1.3c0.3-0.4,0.7-0.9,1.1-1.3l1.3,1.5C383.1,745.7,382.8,746.1,382.6,746.4"/>
						<path class="st452" d="M383.4,745.4l-1.3-1.5c0.3-0.3,0.7-0.6,1.1-0.9l1.1,1.7C384,744.9,383.7,745.2,383.4,745.4"/>
						<path class="st451" d="M384.3,744.7l-1.1-1.7c0.3-0.2,0.7-0.4,1.1-0.6l0.8,1.8C384.8,744.4,384.6,744.6,384.3,744.7"/>
						<path class="st450" d="M385.1,744.3l-0.8-1.8c0.4-0.2,0.8-0.3,1.2-0.4l0.6,1.9C385.7,744,385.4,744.1,385.1,744.3"/>
						<path class="st449" d="M386.1,744l-0.6-1.9c0.4-0.1,0.9-0.2,1.4-0.2l0.2,2C386.7,743.8,386.4,743.9,386.1,744"/>
						<path class="st448" d="M387.1,743.8l-0.2-2c0.3,0,0.6,0,1-0.1l0,2C387.6,743.7,387.4,743.7,387.1,743.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000158726211252427298020000008415117574770927027_" class="st1" d="M401.2,787.7L401.2,787.7
							c-5,0-12.6,0.1-13,0.1h0C388.6,787.7,396.2,787.7,401.2,787.7z"/>
					</defs>
					<clipPath id="SVGID_00000180363194929295576180000017203558761891210117_">
						<use xlink:href="#SVGID_00000158726211252427298020000008415117574770927027_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000180363194929295576180000017203558761891210117_);enable-background:new    ;">
						<path class="st475" d="M401.2,787.7L401.2,787.7c-5,0-12.6,0.1-13,0.1h0C388.6,787.7,396.2,787.7,401.2,787.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000054963133357774055730000000358356941697030557_" class="st1" d="M410.2,795.1L410.2,795.1
							C410.2,795.1,410.2,795.1,410.2,795.1L410.2,795.1C410.2,795.1,410.2,795.1,410.2,795.1z"/>
					</defs>
					<clipPath id="SVGID_00000116931265851522835370000005266346215771055290_">
						<use xlink:href="#SVGID_00000054963133357774055730000000358356941697030557_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000116931265851522835370000005266346215771055290_);enable-background:new    ;">
						<path class="st472" d="M410.2,795L410.2,795C410.2,795.1,410.2,795.1,410.2,795L410.2,795C410.2,795.1,410.2,795.1,410.2,795"
							/>
						<path class="st473" d="M410.2,795.1L410.2,795.1C410.2,795.1,410.2,795.1,410.2,795.1L410.2,795.1
							C410.2,795.1,410.2,795.1,410.2,795.1"/>
						<polyline class="st474" points="410.2,795.1 410.2,795.1 410.2,795.1 410.2,795.1 410.2,795.1 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000045602668399297312330000010750281552439346619_" class="st1" d="M406.9,842l-1.4,1.4
							c-0.3-0.4-5.7-7-0.1-12.7l1.4,1.4C402.5,836.4,406.8,841.9,406.9,842z"/>
					</defs>
					<clipPath id="SVGID_00000046333884225947687900000011088481757520966561_">
						<use xlink:href="#SVGID_00000045602668399297312330000010750281552439346619_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000046333884225947687900000011088481757520966561_);enable-background:new    ;">
						<path class="st457" d="M406.9,842l-1.4,1.4c-0.1-0.1-1.1-1.3-1.8-3l1.9-0.7C406.2,841,406.9,841.9,406.9,842"/>
						<path class="st456" d="M405.6,839.7l-1.9,0.7c-0.6-1.3-1-2.9-0.8-4.7l2,0.3C404.8,837.3,405.2,838.6,405.6,839.7"/>
						<path class="st455" d="M404.9,836l-2-0.3c0.1-0.7,0.2-1.5,0.6-2.2l1.9,0.7C405.1,834.8,405,835.4,404.9,836"/>
						<path class="st454" d="M405.3,834.3l-1.9-0.7c0.2-0.5,0.5-1.1,0.9-1.6l1.7,1.1C405.8,833.4,405.5,833.8,405.3,834.3"/>
						<path class="st453" d="M406.1,833l-1.7-1.1c0.3-0.4,0.6-0.8,1-1.2l1.4,1.4C406.5,832.4,406.3,832.7,406.1,833"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000176039492987445522510000001537697979483324806_" class="st1" d="M409,785.9c0.3,2.3,0.5,4.2,1.2,9.1
							c0,0.5-4-7.4-9-7.4c-5,0-12.6,0.1-13,0.1c-0.3,0-9-0.1-9-6.9l2,0c-0.1,4.8,6.9,5,7,5c0.4,0,8-0.1,13-0.1
							C406.9,785.8,409.6,791.4,409,785.9z"/>
					</defs>
					<clipPath id="SVGID_00000013887727640545801260000011882831290391000493_">
						<use xlink:href="#SVGID_00000176039492987445522510000001537697979483324806_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000013887727640545801260000011882831290391000493_);enable-background:new    ;">
						<path class="st475" d="M408.6,783.5l1.6,11.5c0,0,0,0,0,0L408.6,783.5C408.6,783.6,408.6,783.5,408.6,783.5"/>
						<path class="st474" d="M408.6,783.6l1.6,11.5c0,0,0,0,0,0L408.6,783.6C408.6,783.6,408.6,783.6,408.6,783.6"/>
						<path class="st473" d="M408.6,783.6l1.6,11.4c0,0,0,0,0,0L408.6,783.6C408.6,783.7,408.6,783.6,408.6,783.6"/>
						<path class="st472" d="M408.6,783.7l1.6,11.4c0,0,0,0,0,0L408.6,783.7C408.6,783.7,408.6,783.7,408.6,783.7"/>
						<path class="st471" d="M408.6,783.7l1.6,11.3c0,0,0,0,0,0L408.6,783.7C408.6,783.8,408.6,783.7,408.6,783.7"/>
						<path class="st470" d="M408.6,783.8l1.6,11.3c0,0,0,0,0,0L408.6,783.8C408.7,783.8,408.7,783.8,408.6,783.8"/>
						<path class="st469" d="M408.7,783.8l1.6,11.2c0,0,0,0,0,0L408.7,783.8C408.7,783.9,408.7,783.8,408.7,783.8"/>
						<path class="st468" d="M408.7,783.9l1.5,11.2c0,0,0,0,0,0L408.7,783.9C408.7,783.9,408.7,783.9,408.7,783.9"/>
						<path class="st467" d="M408.7,783.9l1.5,11.1c0,0,0,0,0,0L408.7,783.9C408.7,784,408.7,783.9,408.7,783.9"/>
						<path class="st466" d="M408.7,784l1.5,11.1c0,0,0,0,0,0L408.7,784C408.7,784,408.7,784,408.7,784"/>
						<path class="st465" d="M408.7,784l1.5,11.1c0,0,0,0,0,0L408.7,784C408.7,784,408.7,784,408.7,784"/>
						<path class="st464" d="M408.7,784.1l1.5,11c0,0,0,0,0,0L408.7,784.1C408.7,784.1,408.7,784.1,408.7,784.1"/>
						<path class="st463" d="M408.7,784.1l1.5,11c0,0,0,0,0,0L408.7,784.1C408.7,784.1,408.7,784.1,408.7,784.1"/>
						<path class="st462" d="M408.7,784.2l1.5,10.9c0,0,0,0,0,0L408.7,784.2C408.7,784.2,408.7,784.2,408.7,784.2"/>
						<path class="st461" d="M408.7,784.2l1.5,10.9c0,0,0,0,0,0L408.7,784.2C408.7,784.2,408.7,784.2,408.7,784.2"/>
						<path class="st460" d="M408.7,784.2l1.5,10.8c0,0,0,0,0,0L408.7,784.2C408.7,784.3,408.7,784.3,408.7,784.2"/>
						<path class="st459" d="M408.7,784.3l1.5,10.8c0,0,0,0,0,0L408.7,784.3C408.7,784.3,408.7,784.3,408.7,784.3"/>
						<path class="st458" d="M408.7,784.3l1.5,10.7c0,0,0,0,0,0L408.7,784.3C408.8,784.4,408.7,784.4,408.7,784.3"/>
						<path class="st457" d="M408.8,784.4l1.5,10.7c0,0,0,0,0,0L408.8,784.4C408.8,784.4,408.8,784.4,408.8,784.4"/>
						<path class="st456" d="M408.8,784.4l1.4,10.6c0,0,0,0,0,0L408.8,784.4C408.8,784.5,408.8,784.4,408.8,784.4"/>
						<path class="st455" d="M408.8,784.5l1.4,10.6c0,0,0,0,0,0L408.8,784.5C408.8,784.5,408.8,784.5,408.8,784.5"/>
						<path class="st454" d="M408.8,784.5l1.4,10.6c0,0,0,0,0,0L408.8,784.5C408.8,784.5,408.8,784.5,408.8,784.5"/>
						<path class="st453" d="M408.8,784.6l1.4,10.5c0,0,0,0,0,0L408.8,784.6C408.8,784.6,408.8,784.6,408.8,784.6"/>
						<path class="st452" d="M408.8,784.6l1.4,10.5c0,0,0,0,0,0L408.8,784.6C408.8,784.6,408.8,784.6,408.8,784.6"/>
						<path class="st451" d="M408.8,784.6l1.4,10.4c0,0,0,0,0,0L408.8,784.6C408.8,784.7,408.8,784.7,408.8,784.6"/>
						<path class="st450" d="M408.8,784.7l1.4,10.4c0,0,0,0,0,0L408.8,784.7C408.8,784.7,408.8,784.7,408.8,784.7"/>
						<path class="st449" d="M408.8,784.7l1.4,10.3c0,0,0,0,0,0L408.8,784.7C408.8,784.8,408.8,784.7,408.8,784.7"/>
						<path class="st448" d="M408.8,784.8l1.4,10.3c0,0,0,0,0,0l-1.3-10.2C408.8,784.8,408.8,784.8,408.8,784.8"/>
						<path class="st447" d="M408.8,784.9l1.3,10.2c0,0,0,0,0,0l-1.3-9.9C408.9,785,408.8,785,408.8,784.9"/>
						<path class="st446" d="M408.9,785.1l1.3,9.9c0,0,0,0,0,0l-1.2-9.7C408.9,785.3,408.9,785.2,408.9,785.1"/>
						<path class="st445" d="M408.9,785.4l1.2,9.7c0,0,0,0,0,0l-1.2-9.4C408.9,785.5,408.9,785.4,408.9,785.4"/>
						<path class="st444" d="M408.9,785.6l1.2,9.4c0,0,0,0,0,0l-1.1-9.2C409,785.7,408.9,785.6,408.9,785.6"/>
						<path class="st497" d="M409,785.8l1.1,9.2c0,0,0,0,0,0l-1.1-9.1C409,785.8,409,785.8,409,785.8"/>
						<path class="st444" d="M409,785.9l1.1,9.1c0,0,0,0,0,0L409,786C409,786,409,785.9,409,785.9"/>
						<path class="st445" d="M409,786l1.1,8.9c0,0,0,0,0,0l-1-8.7C409,786.1,409,786.1,409,786"/>
						<path class="st446" d="M409,786.2l1,8.7c0,0,0,0,0,0l-1-8.5C409,786.3,409,786.2,409,786.2"/>
						<path class="st447" d="M409,786.3l1,8.5c0,0,0,0,0,0l-1-8.4C409,786.4,409,786.4,409,786.3"/>
						<path class="st448" d="M409,786.5l1,8.4c0,0,0,0,0,0l-0.9-8.2C409,786.6,409,786.5,409,786.5"/>
						<path class="st449" d="M409.1,786.6l0.9,8.2c0,0,0-0.1-0.1-0.1l-0.8-7.9C409.1,786.8,409.1,786.7,409.1,786.6"/>
						<path class="st450" d="M409.1,786.8l0.8,7.9c0,0-0.1-0.1-0.1-0.1l-0.7-7.5C409.1,787,409.1,786.9,409.1,786.8"/>
						<path class="st451" d="M409.1,787.1l0.7,7.5c0,0-0.1-0.1-0.1-0.1l-0.7-7.1C409.1,787.3,409.1,787.2,409.1,787.1"/>
						<path class="st452" d="M409.1,787.4l0.7,7.1c-0.1-0.1-0.1-0.2-0.2-0.3l-0.5-6.5C409.1,787.6,409.1,787.5,409.1,787.4"/>
						<path class="st453" d="M409,787.7l0.5,6.5c-0.1-0.1-0.2-0.3-0.3-0.4l-0.3-5.7C409,788,409,787.9,409,787.7"/>
						<path class="st454" d="M408.9,788.1l0.3,5.7c-0.2-0.3-0.4-0.6-0.6-0.9l0-4.6C408.8,788.3,408.9,788.2,408.9,788.1"/>
						<path class="st455" d="M408.6,788.3l0,4.6c-0.5-0.6-1-1.3-1.6-1.9l0.4-3.1C408,788.1,408.3,788.3,408.6,788.3"/>
						<path class="st456" d="M407.5,787.9l-0.4,3.1c-1.4-1.5-3.2-3-5.2-3.2l0.1-2C404.4,786,406.3,787.2,407.5,787.9"/>
						<path class="st455" d="M401.9,785.7l-0.1,2c-0.2,0-0.4,0-0.6,0c-5,0-12.6,0.1-13,0.1c-0.1,0-0.5,0-1.3-0.1l0.3-2
							c0.6,0.1,0.9,0.1,1,0.1c0.4,0,8-0.1,13-0.1C401.4,785.7,401.7,785.7,401.9,785.7"/>
						<path class="st456" d="M387.2,785.7l-0.3,2c-1.5-0.2-4-0.7-5.7-2.2l1.5-1.4C384,785.1,386,785.5,387.2,785.7"/>
						<path class="st457" d="M382.6,784l-1.5,1.4c-0.6-0.5-1.2-1.2-1.5-2.1l1.8-0.8C381.7,783.2,382.1,783.6,382.6,784"/>
						<path class="st456" d="M381.5,782.6l-1.8,0.8c-0.3-0.7-0.5-1.6-0.5-2.6l2,0C381.1,781.5,381.2,782.1,381.5,782.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000043421178344378026320000003114224450519794829_" class="st1" d="M455.4,849.8c0.3,0.5,0.3,0.5,3.4,2.7
							c-3-2.2-7-5.1-10-7.3C453.3,848.3,455.2,849.4,455.4,849.8z"/>
					</defs>
					<clipPath id="SVGID_00000065788880434724797880000005630113254771590549_">
						<use xlink:href="#SVGID_00000043421178344378026320000003114224450519794829_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000065788880434724797880000005630113254771590549_);enable-background:new    ;">
						<path class="st508" d="M458.8,852.6l-10-7.3c0.1,0.1,0.2,0.1,0.3,0.2L458.8,852.6C458.8,852.6,458.8,852.6,458.8,852.6"/>
						<path class="st507" d="M458.8,852.6l-9.8-7.1c0.2,0.2,0.4,0.3,0.7,0.4L458.8,852.6C458.8,852.6,458.8,852.6,458.8,852.6"/>
						<path class="st506" d="M458.8,852.6l-9.1-6.7c0.1,0.1,0.3,0.2,0.4,0.3L458.8,852.6C458.8,852.6,458.8,852.6,458.8,852.6"/>
						<path class="st505" d="M458.8,852.6l-8.7-6.4c0.1,0.1,0.2,0.1,0.3,0.2L458.8,852.6C458.8,852.6,458.8,852.6,458.8,852.6"/>
						<path class="st504" d="M458.8,852.6l-8.4-6.3c0.1,0.1,0.2,0.1,0.3,0.2L458.8,852.6C458.8,852.6,458.8,852.6,458.8,852.6"/>
						<path class="st503" d="M458.8,852.6l-8.2-6.1c0.1,0.1,0.2,0.1,0.2,0.2L458.8,852.6C458.8,852.6,458.8,852.6,458.8,852.6"/>
						<path class="st502" d="M458.8,852.6l-7.9-5.9c0.1,0,0.1,0.1,0.2,0.1L458.8,852.6C458.8,852.6,458.8,852.6,458.8,852.6"/>
						<path class="st501" d="M458.8,852.6l-7.7-5.8c0,0,0.1,0.1,0.1,0.1L458.8,852.6C458.8,852.6,458.8,852.6,458.8,852.6"/>
						<path class="st500" d="M458.8,852.6l-7.6-5.7c0,0,0.1,0.1,0.1,0.1L458.8,852.6C458.8,852.6,458.8,852.6,458.8,852.6"/>
						<path class="st499" d="M458.8,852.6l-7.5-5.7c0,0,0.1,0.1,0.1,0.1L458.8,852.6C458.8,852.6,458.8,852.6,458.8,852.6"/>
						<path class="st498" d="M458.8,852.6l-7.4-5.6c0,0,0.1,0.1,0.1,0.1L458.8,852.6C458.8,852.6,458.8,852.6,458.8,852.6"/>
						<path class="st497" d="M458.8,852.6l-7.2-5.5c0,0,0.1,0,0.1,0.1L458.8,852.6C458.8,852.6,458.8,852.6,458.8,852.6"/>
						<path class="st444" d="M458.8,852.6l-7.1-5.4c0,0,0.1,0,0.1,0.1L458.8,852.6C458.8,852.6,458.8,852.6,458.8,852.6"/>
						<path class="st445" d="M458.8,852.6l-7-5.4c0,0,0.1,0,0.1,0.1L458.8,852.6C458.8,852.6,458.8,852.6,458.8,852.6"/>
						<path class="st446" d="M458.8,852.6l-6.9-5.3c0,0,0.1,0,0.1,0.1L458.8,852.6C458.8,852.6,458.8,852.6,458.8,852.6"/>
						<path class="st447" d="M458.8,852.6l-6.8-5.2c0,0,0.1,0,0.1,0.1L458.8,852.6C458.8,852.6,458.8,852.6,458.8,852.6"/>
						<path class="st448" d="M458.8,852.6l-6.7-5.2c0,0,0.1,0,0.1,0.1L458.8,852.6C458.8,852.6,458.8,852.6,458.8,852.6"/>
						<path class="st449" d="M458.8,852.6l-6.6-5.1c0,0,0.1,0,0.1,0.1L458.8,852.6C458.7,852.6,458.7,852.6,458.8,852.6"/>
						<path class="st450" d="M458.7,852.6l-6.5-5c0,0,0.1,0,0.1,0.1L458.7,852.6C458.7,852.6,458.7,852.6,458.7,852.6"/>
						<path class="st451" d="M458.7,852.6l-6.4-5c0,0,0.1,0,0.1,0.1L458.7,852.6C458.7,852.6,458.7,852.6,458.7,852.6"/>
						<path class="st452" d="M458.7,852.6l-6.3-4.9c0,0,0.1,0,0.1,0.1L458.7,852.6C458.7,852.6,458.7,852.6,458.7,852.6"/>
						<path class="st453" d="M458.7,852.6l-6.2-4.8c0,0,0.1,0,0.1,0.1L458.7,852.6C458.7,852.6,458.7,852.6,458.7,852.6"/>
						<path class="st454" d="M458.7,852.6l-6.1-4.8c0,0,0.1,0,0.1,0.1L458.7,852.6C458.7,852.6,458.7,852.6,458.7,852.6"/>
						<path class="st455" d="M458.7,852.6l-6-4.7c0,0,0.1,0,0.1,0.1L458.7,852.6C458.7,852.6,458.7,852.6,458.7,852.6"/>
						<path class="st456" d="M458.7,852.6l-5.9-4.7c0,0,0,0,0,0L458.7,852.6C458.7,852.6,458.7,852.6,458.7,852.6"/>
						<path class="st457" d="M458.7,852.6l-5.8-4.6c0,0,0,0,0,0L458.7,852.6C458.7,852.6,458.7,852.6,458.7,852.6"/>
						<path class="st458" d="M458.7,852.6l-5.8-4.6c0,0,0,0,0,0L458.7,852.6C458.7,852.6,458.7,852.6,458.7,852.6"/>
						<path class="st459" d="M458.6,852.6l-5.7-4.6c0,0,0,0,0,0L458.6,852.6C458.6,852.6,458.6,852.6,458.6,852.6"/>
						<path class="st460" d="M458.6,852.6L453,848c0,0,0,0,0,0L458.6,852.6C458.6,852.6,458.6,852.6,458.6,852.6"/>
						<path class="st461" d="M458.6,852.6l-5.6-4.5c0,0,0,0,0,0L458.6,852.6C458.6,852.6,458.6,852.6,458.6,852.6"/>
						<path class="st462" d="M458.6,852.6l-5.6-4.5c0,0,0,0,0,0L458.6,852.6C458.6,852.6,458.6,852.6,458.6,852.6"/>
						<path class="st463" d="M458.6,852.6l-5.5-4.5c0,0,0,0,0,0L458.6,852.6C458.6,852.6,458.6,852.6,458.6,852.6"/>
						<path class="st464" d="M458.6,852.6l-5.5-4.5c0,0,0,0,0,0L458.6,852.6C458.6,852.6,458.6,852.6,458.6,852.6"/>
						<path class="st465" d="M458.6,852.6l-5.5-4.4c0,0,0,0,0,0L458.6,852.6C458.6,852.6,458.6,852.6,458.6,852.6"/>
						<path class="st466" d="M458.6,852.6l-5.4-4.4c0,0,0,0,0,0L458.6,852.6C458.6,852.6,458.6,852.6,458.6,852.6"/>
						<path class="st467" d="M458.6,852.6l-5.4-4.4c0,0,0,0,0,0L458.6,852.6C458.6,852.6,458.6,852.6,458.6,852.6"/>
						<path class="st468" d="M458.6,852.6l-5.3-4.4c0,0,0,0,0,0L458.6,852.6C458.6,852.6,458.6,852.6,458.6,852.6"/>
						<path class="st469" d="M458.6,852.6l-5.3-4.3c0,0,0,0,0,0L458.6,852.6C458.6,852.6,458.6,852.6,458.6,852.6"/>
						<path class="st470" d="M458.6,852.6l-5.3-4.3c0,0,0,0,0,0L458.6,852.6C458.6,852.6,458.6,852.6,458.6,852.6"/>
						<path class="st471" d="M458.6,852.6l-5.2-4.3c0,0,0,0,0,0L458.6,852.6C458.6,852.6,458.6,852.6,458.6,852.6"/>
						<path class="st472" d="M458.6,852.6l-5.2-4.3c0,0,0,0,0,0L458.6,852.6C458.5,852.6,458.6,852.6,458.6,852.6"/>
						<path class="st473" d="M458.5,852.6l-5.1-4.2c0,0,0,0,0,0L458.5,852.6C458.5,852.6,458.5,852.6,458.5,852.6"/>
						<path class="st474" d="M458.5,852.6l-5.1-4.2c0,0,0,0,0,0L458.5,852.6C458.5,852.6,458.5,852.6,458.5,852.6"/>
						<path class="st475" d="M458.5,852.6l-5-4.2c1.2,0.8,1.8,1.2,1.9,1.4l1.6,2.5C457.7,852.4,458.2,852.5,458.5,852.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000076564871517917905530000005443169733001513858_" class="st1" d="M387.9,743.7l0-2c8-0.1,11.8-0.1,12-0.1
							c1.5,0,6.7-0.3,9.5-4.5l1.7,1.1c-3.4,5.1-9.4,5.4-11.2,5.4C399.7,743.7,395.9,743.7,387.9,743.7z"/>
					</defs>
					<clipPath id="SVGID_00000096024698364104526460000008137309537307818155_">
						<use xlink:href="#SVGID_00000076564871517917905530000005443169733001513858_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000096024698364104526460000008137309537307818155_);enable-background:new    ;">
						<path class="st448" d="M387.9,743.7l0-2c8-0.1,11.8-0.1,12-0.1c0.3,0,0.8,0,1.4-0.1l0.2,2c-0.7,0.1-1.2,0.1-1.6,0.1
							C399.7,743.7,395.9,743.7,387.9,743.7"/>
						<path class="st449" d="M401.5,743.6l-0.2-2c0.6-0.1,1.3-0.2,2.1-0.4l0.6,1.9C403,743.4,402.2,743.5,401.5,743.6"/>
						<path class="st450" d="M403.9,743.1l-0.6-1.9c0.6-0.1,1.2-0.3,1.8-0.6l0.9,1.8C405.3,742.7,404.6,743,403.9,743.1"/>
						<path class="st451" d="M406,742.4l-0.9-1.8c0.5-0.2,1-0.5,1.5-0.8l1.1,1.6C407.2,741.8,406.6,742.2,406,742.4"/>
						<path class="st452" d="M407.8,741.5l-1.1-1.6c0.4-0.3,0.9-0.6,1.3-1l1.4,1.4C408.8,740.7,408.3,741.1,407.8,741.5"/>
						<path class="st453" d="M409.3,740.2l-1.4-1.4c0.4-0.4,0.8-0.9,1.2-1.4l1.6,1.2C410.3,739.2,409.8,739.8,409.3,740.2"/>
						<path class="st454" d="M410.8,738.6l-1.6-1.2c0.1-0.1,0.1-0.2,0.2-0.3l1.7,1.1C410.9,738.4,410.9,738.5,410.8,738.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000062893091957348378940000011489794815098542991_" class="st1" d="M406.8,832.1l-1.4-1.4
							c5.6-5.7,8.3-8.4,8.4-8.5l1.4,1.4C415.1,823.7,412.5,826.4,406.8,832.1z"/>
					</defs>
					<clipPath id="SVGID_00000034805896352029460230000003836522744529151415_">
						<use xlink:href="#SVGID_00000062893091957348378940000011489794815098542991_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000034805896352029460230000003836522744529151415_);enable-background:new    ;">
						<path class="st453" d="M406.8,832.1l-1.4-1.4c5.6-5.7,8.3-8.4,8.4-8.5l1.4,1.4C415.1,823.7,412.5,826.4,406.8,832.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000182514206772059109820000000336934974841597087_" class="st1" d="M416.7,706.9l-1.4,1.4
							c-3.6-3.5-9-8.8-9.3-9.1c-0.3-0.3-6.4-6.3-1.5-11.3l1.4,1.4c-3.5,3.4,1.2,8.2,1.5,8.5C407.7,698,413.1,703.3,416.7,706.9z"/>
					</defs>
					<clipPath id="SVGID_00000118372331552801443880000007931417579754403730_">
						<use xlink:href="#SVGID_00000182514206772059109820000000336934974841597087_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000118372331552801443880000007931417579754403730_);enable-background:new    ;">
						<path class="st457" d="M416.7,706.9l-1.4,1.4c-3.6-3.5-9-8.8-9.3-9.1c-0.2-0.1-1.6-1.6-2.5-3.5l1.9-0.7c0.8,1.5,1.9,2.6,2,2.8
							C407.7,698,413.1,703.3,416.7,706.9"/>
						<path class="st456" d="M405.4,695l-1.9,0.7c-0.6-1.2-1-2.6-0.8-4l2,0.3C404.6,693,404.9,694,405.4,695"/>
						<path class="st455" d="M404.7,691.9l-2-0.3c0.1-0.5,0.2-1.1,0.4-1.6l1.9,0.8C404.8,691.1,404.7,691.5,404.7,691.9"/>
						<path class="st454" d="M404.9,690.7l-1.9-0.8c0.2-0.4,0.4-0.8,0.7-1.2l1.6,1.1C405.2,690.1,405,690.4,404.9,690.7"/>
						<path class="st453" d="M405.4,689.9l-1.6-1.1c0.2-0.3,0.5-0.6,0.8-0.9l1.4,1.4C405.7,689.4,405.6,689.7,405.4,689.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000156554845459018316860000003821739014730955677_" class="st1" d="M411,738.3l-1.7-1.1
							c1.7-6,4-11.9,6.9-17.5l1.8,0.9C415.4,726,412.9,731.8,411,738.3z"/>
					</defs>
					<clipPath id="SVGID_00000076566708220741225750000014267922686921409207_">
						<use xlink:href="#SVGID_00000156554845459018316860000003821739014730955677_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000076566708220741225750000014267922686921409207_);enable-background:new    ;">
						<path class="st455" d="M411,738.3l-1.7-1.1c0.9-3.1,1.9-6.2,3.1-9.2l1.9,0.8C413.1,731.8,412,734.9,411,738.3"/>
						<path class="st454" d="M414.3,728.8l-1.9-0.8c1.1-2.8,2.4-5.6,3.8-8.4l1.8,0.9C416.7,723.2,415.5,725.9,414.3,728.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000011731185754429975920000014104253725168096145_" class="st1" d="M419.1,810.9l-1.9,0.6
							c-2.8-5.3-5.1-10.8-6.9-16.4l-1.6-11.5C413.9,799.9,416.3,805.4,419.1,810.9z"/>
					</defs>
					<clipPath id="SVGID_00000144329890916049751690000012339073673994932397_">
						<use xlink:href="#SVGID_00000011731185754429975920000014104253725168096145_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000144329890916049751690000012339073673994932397_);enable-background:new    ;">
						<path class="st457" d="M419.1,810.9l-1.9,0.6c-0.4-0.7-0.7-1.4-1.1-2.1l1.9-0.7C418.4,809.4,418.7,810.1,419.1,810.9"/>
						<path class="st456" d="M418,808.7l-1.9,0.7c-2.3-4.6-4.3-9.4-5.9-14.3l-1.6-11.5C413.2,797.8,415.6,803.8,418,808.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000177443126987483109790000007310646352390110887_" class="st1" d="M418,720.5l-1.8-0.9
							c0.6-2.1,2-8.4-1-11.3l1.4-1.4C420.5,710.7,418.8,717.9,418,720.5z"/>
					</defs>
					<clipPath id="SVGID_00000136389918225391059930000016590186216665219970_">
						<use xlink:href="#SVGID_00000177443126987483109790000007310646352390110887_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000136389918225391059930000016590186216665219970_);enable-background:new    ;">
						<path class="st455" d="M418,720.5l-1.8-0.9c0.2-0.7,0.5-2,0.7-3.5l2,0.2C418.7,718.1,418.3,719.6,418,720.5"/>
						<path class="st456" d="M418.9,716.3l-2-0.2c0.2-1.8,0.2-4-0.4-5.8l1.8-0.9C419.2,711.7,419.2,714.2,418.9,716.3"/>
						<path class="st457" d="M418.3,709.5l-1.8,0.9c-0.3-0.8-0.7-1.5-1.3-2.1l1.4-1.4C417.4,707.6,417.9,708.5,418.3,709.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000109725156466646670790000012972695350493724569_" class="st1" d="M415.3,823.5l-1.4-1.4
							c0.3-0.2,5.1-4.6,3.3-10.7l1.9-0.6C421.3,818.2,415.5,823.3,415.3,823.5z"/>
					</defs>
					<clipPath id="SVGID_00000020368637966964279300000017097939602973308087_">
						<use xlink:href="#SVGID_00000109725156466646670790000012972695350493724569_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000020368637966964279300000017097939602973308087_);enable-background:new    ;">
						<path class="st452" d="M415.3,823.5l-1.4-1.4c0,0,0.1-0.1,0.1-0.1l1.4,1.4C415.3,823.5,415.3,823.5,415.3,823.5"/>
						<path class="st453" d="M415.4,823.4L414,822c0.3-0.2,0.8-0.8,1.5-1.7l1.7,1.1C416.4,822.5,415.7,823.2,415.4,823.4"/>
						<path class="st454" d="M417.1,821.5l-1.7-1.1c0.5-0.6,0.9-1.4,1.3-2.3l1.9,0.7C418.2,819.8,417.6,820.7,417.1,821.5"/>
						<path class="st455" d="M418.7,818.7l-1.9-0.7c0.4-0.8,0.6-1.8,0.7-2.8l2,0.2C419.4,816.6,419.1,817.7,418.7,818.7"/>
						<path class="st456" d="M419.5,815.4l-2-0.2c0.1-1.2,0-2.4-0.4-3.8l1.9-0.6C419.6,812.5,419.7,814,419.5,815.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000156580599189380132760000008018833512755105974_" class="st1" d="M405.9,689.2l-1.4-1.4
							c4.9-5,20.3-20.5,21.1-21.3l1.4,1.4C426.2,668.7,410.9,684.3,405.9,689.2z"/>
					</defs>
					<clipPath id="SVGID_00000047747237121663986660000012861629227819081865_">
						<use xlink:href="#SVGID_00000156580599189380132760000008018833512755105974_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000047747237121663986660000012861629227819081865_);enable-background:new    ;">
						<path class="st453" d="M405.9,689.2l-1.4-1.4c4.9-5,20.3-20.5,21.1-21.3l1.4,1.4C426.2,668.7,410.9,684.3,405.9,689.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000096773069386727055590000010158633030330100382_" class="st1" d="M428.3,863.1l-1.4,1.4
							c-5-4.9-20.5-20.3-21.3-21.1l1.4-1.4C407.7,842.8,423.3,858.1,428.3,863.1z"/>
					</defs>
					<clipPath id="SVGID_00000157987580638078103310000001391819890063219073_">
						<use xlink:href="#SVGID_00000096773069386727055590000010158633030330100382_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000157987580638078103310000001391819890063219073_);enable-background:new    ;">
						<path class="st457" d="M428.3,863.1l-1.4,1.4c-5-4.9-20.5-20.3-21.3-21.1l1.4-1.4C407.7,842.8,423.3,858.1,428.3,863.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000152949336330883259690000015227513763313746617_" class="st1" d="M438.2,863L438.2,863
							c-0.2,0.2-3.3,3.3-7,3.3h0C434.9,866.3,438,863.2,438.2,863z"/>
					</defs>
					<clipPath id="SVGID_00000165227898143109584810000011037972419879306426_">
						<use xlink:href="#SVGID_00000152949336330883259690000015227513763313746617_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000165227898143109584810000011037972419879306426_);enable-background:new    ;">
						<path class="st475" d="M438.2,863L438.2,863c-0.2,0.2-3.3,3.3-7,3.3h0C434.9,866.3,438,863.2,438.2,863"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000149360274665283577700000006008781117121374623_" class="st1" d="M432.5,663.9L432.5,663.9
							c1.9,0,3.9,0.6,5.8,2.5h0C436.4,664.6,434.4,663.9,432.5,663.9z"/>
					</defs>
					<clipPath id="SVGID_00000035494574795145714730000012051505012832794755_">
						<use xlink:href="#SVGID_00000149360274665283577700000006008781117121374623_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000035494574795145714730000012051505012832794755_);enable-background:new    ;">
						<path class="st473" d="M432.5,663.9L432.5,663.9c0.1,0,0.2,0,0.3,0h0C432.7,663.9,432.6,663.9,432.5,663.9"/>
						<path class="st472" d="M432.8,663.9L432.8,663.9c0.4,0,0.8,0.1,1.2,0.1h0C433.6,664,433.2,664,432.8,663.9"/>
						<path class="st471" d="M434,664.1L434,664.1c0.4,0.1,0.8,0.2,1.1,0.3h0C434.7,664.2,434.3,664.1,434,664.1"/>
						<path class="st470" d="M435.1,664.4L435.1,664.4c0.4,0.1,0.9,0.3,1.3,0.6h0C436,664.7,435.5,664.5,435.1,664.4"/>
						<path class="st469" d="M436.4,664.9L436.4,664.9c0.7,0.4,1.3,0.9,2,1.5h0C437.7,665.8,437,665.3,436.4,664.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000132809271980325197540000016389919487670001852_" class="st1" d="M427,667.9c-0.1-0.1-0.3-0.3-0.4-0.4
							l-1-1c0.2-0.1,3.2-2.6,7-2.6c1.9,0,3.9,0.6,5.8,2.5l-1.4,1.4c-1.5-1.4-3-1.9-4.4-1.9C429.5,665.9,427,667.9,427,667.9z"/>
					</defs>
					<clipPath id="SVGID_00000070829496049344015650000016940170125544444802_">
						<use xlink:href="#SVGID_00000132809271980325197540000016389919487670001852_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000070829496049344015650000016940170125544444802_);enable-background:new    ;">
						<path class="st452" d="M427,667.9l-1.4-1.4c0,0,0.3-0.3,0.8-0.6l1.2,1.6C427.2,667.8,427,667.9,427,667.9"/>
						<path class="st451" d="M427.5,667.5l-1.2-1.6c0.5-0.3,1.1-0.7,1.9-1l0.8,1.8C428.4,667,427.9,667.3,427.5,667.5"/>
						<path class="st450" d="M429.1,666.7l-0.8-1.8c0.5-0.2,1.1-0.5,1.8-0.6l0.5,1.9C430,666.4,429.5,666.5,429.1,666.7"/>
						<path class="st449" d="M430.5,666.2l-0.5-1.9c0.5-0.1,1-0.2,1.6-0.3l0.2,2C431.4,666,430.9,666.1,430.5,666.2"/>
						<path class="st448" d="M431.8,666l-0.2-2c0.3,0,0.6,0,0.9,0c0.2,0,0.4,0,0.6,0l-0.1,2c-0.1,0-0.3,0-0.4,0
							C432.3,665.9,432.1,666,431.8,666"/>
						<path class="st447" d="M433,666l0.1-2c0.5,0,0.9,0.1,1.4,0.2l-0.5,1.9C433.6,666,433.3,666,433,666"/>
						<path class="st446" d="M434,666.1l0.5-1.9c0.5,0.1,1,0.3,1.6,0.6l-0.9,1.8C434.8,666.4,434.4,666.2,434,666.1"/>
						<path class="st445" d="M435.2,666.6l0.9-1.8c0.8,0.4,1.6,0.9,2.3,1.7l-1.4,1.4C436.3,667.3,435.7,666.8,435.2,666.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000003084499483434476450000009765063807788320654_" class="st1" d="M438.3,666.4L438.3,666.4
							c5.7,5.6,8.4,8.3,8.5,8.4h0C446.7,674.7,444,672,438.3,666.4z"/>
					</defs>
					<clipPath id="SVGID_00000119831321443025269870000018240451408693679754_">
						<use xlink:href="#SVGID_00000003084499483434476450000009765063807788320654_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000119831321443025269870000018240451408693679754_);enable-background:new    ;">
						<path class="st469" d="M438.3,666.4L438.3,666.4c5.7,5.6,8.4,8.3,8.5,8.4h0C446.7,674.7,444,672,438.3,666.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000090986001386400896120000003254178165376856994_" class="st1" d="M447.3,853.7L447.3,853.7
							c-3.5,3.6-8.8,9-9.1,9.3h0C438.4,862.7,443.8,857.3,447.3,853.7z"/>
					</defs>
					<clipPath id="SVGID_00000082369573276098251710000014743143978063773109_">
						<use xlink:href="#SVGID_00000090986001386400896120000003254178165376856994_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000082369573276098251710000014743143978063773109_);enable-background:new    ;">
						<path class="st475" d="M447.3,853.7L447.3,853.7c-3.5,3.6-8.8,9-9.1,9.3h0C438.4,862.7,443.8,857.3,447.3,853.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000014628288532840409250000011323155150216050056_" class="st1" d="M452.9,851.9L452.9,851.9
							c-2.1,0-4.2,0.4-5.6,1.8h0C448.7,852.4,450.8,851.9,452.9,851.9z"/>
					</defs>
					<clipPath id="SVGID_00000081611306299342655720000012873587249386137495_">
						<use xlink:href="#SVGID_00000014628288532840409250000011323155150216050056_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000081611306299342655720000012873587249386137495_);enable-background:new    ;">
						<path class="st475" d="M452.9,851.9L452.9,851.9c-2.1,0-4.2,0.4-5.6,1.8h0C448.7,852.4,450.8,851.9,452.9,851.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000065043123188301285480000013997242576853447044_" class="st1" d="M436.9,667.8l1.4-1.4
							c5.7,5.6,8.4,8.3,8.5,8.4l-1.4,1.4C445.3,676.1,442.6,673.5,436.9,667.8z"/>
					</defs>
					<clipPath id="SVGID_00000165917204706566719390000014449036717502491839_">
						<use xlink:href="#SVGID_00000065043123188301285480000013997242576853447044_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000165917204706566719390000014449036717502491839_);enable-background:new    ;">
						<path class="st445" d="M436.9,667.8l1.4-1.4c5.7,5.6,8.4,8.3,8.5,8.4l-1.4,1.4C445.3,676.1,442.6,673.5,436.9,667.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000095320787759030766530000013889451132715117465_" class="st1" d="M445.9,852.3l1.4,1.4
							c-3.5,3.6-8.8,9-9.1,9.3c-0.2,0.2-3.3,3.3-7,3.3c-1.4,0-2.9-0.5-4.3-1.9l1.4-1.4c1,1,2,1.3,2.9,1.3c2.8,0,5.5-2.6,5.6-2.7
							C437,861.3,442.3,855.9,445.9,852.3z"/>
					</defs>
					<clipPath id="SVGID_00000059280244836029403860000001259762123091517866_">
						<use xlink:href="#SVGID_00000095320787759030766530000013889451132715117465_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000059280244836029403860000001259762123091517866_);enable-background:new    ;">
						<path class="st451" d="M445.9,852.3l1.4,1.4c-3.5,3.6-8.8,9-9.1,9.3c0,0,0,0-0.1,0.1l-1.4-1.4c0,0,0.1-0.1,0.1-0.1
							C437,861.3,442.3,855.9,445.9,852.3"/>
						<path class="st452" d="M436.7,861.7l1.4,1.4c-0.2,0.2-0.9,0.9-1.8,1.5l-1.1-1.7C435.9,862.4,436.5,861.9,436.7,861.7"/>
						<path class="st453" d="M435.1,862.9l1.1,1.7c-0.6,0.4-1.3,0.8-2,1.1l-0.7-1.9C434.1,863.5,434.7,863.2,435.1,862.9"/>
						<path class="st454" d="M433.5,863.8l0.7,1.9c-0.6,0.2-1.2,0.4-1.8,0.6l-0.3-2C432.6,864.2,433.1,864,433.5,863.8"/>
						<path class="st455" d="M432.1,864.3l0.3,2c-0.4,0.1-0.8,0.1-1.2,0.1c-0.2,0-0.4,0-0.6,0l0.2-2c0.1,0,0.3,0,0.4,0
							C431.5,864.3,431.8,864.3,432.1,864.3"/>
						<path class="st456" d="M430.7,864.3l-0.2,2c-0.9-0.1-1.8-0.4-2.7-1.1l1.1-1.6C429.5,864,430.1,864.3,430.7,864.3"/>
						<path class="st457" d="M428.9,863.6l-1.1,1.6c-0.3-0.2-0.6-0.5-0.9-0.8l1.4-1.4C428.5,863.3,428.7,863.4,428.9,863.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000077284971727576196770000003733742456087035805_" class="st1" d="M446.9,674.9L446.9,674.9
							c0.2,0.2,3.3,3.7,8,3.7h0C450.2,678.6,447,675,446.9,674.9z"/>
					</defs>
					<clipPath id="SVGID_00000129892784800566164620000012716321279701177769_">
						<use xlink:href="#SVGID_00000077284971727576196770000003733742456087035805_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000129892784800566164620000012716321279701177769_);enable-background:new    ;">
						<path class="st469" d="M446.9,674.9L446.9,674.9c0.1,0.1,1.2,1.3,3,2.3h0C448,676.2,447,675,446.9,674.9"/>
						<path class="st470" d="M449.8,677.2L449.8,677.2c0.6,0.3,1.2,0.6,1.9,0.8h0C451,677.8,450.4,677.5,449.8,677.2"/>
						<path class="st471" d="M451.7,678L451.7,678c0.5,0.2,1,0.3,1.5,0.4h0C452.7,678.3,452.2,678.2,451.7,678"/>
						<path class="st472" d="M453.2,678.4L453.2,678.4c0.5,0.1,0.9,0.1,1.4,0.1h0C454.1,678.5,453.6,678.5,453.2,678.4"/>
						<path class="st473" d="M454.6,678.5L454.6,678.5c0.1,0,0.2,0,0.3,0h0C454.8,678.6,454.7,678.6,454.6,678.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000160873286217842333870000016074842653409348537_" class="st1" d="M459.2,852.9L459.2,852.9
							C459.2,852.9,459.2,852.9,459.2,852.9L459.2,852.9C459.2,852.9,459.2,852.9,459.2,852.9z"/>
					</defs>
					<clipPath id="SVGID_00000013899267122903674460000002605551915856056988_">
						<use xlink:href="#SVGID_00000160873286217842333870000016074842653409348537_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000013899267122903674460000002605551915856056988_);enable-background:new    ;">
						<polyline class="st469" points="459.2,852.9 459.2,852.9 459.2,852.9 459.2,852.9 459.2,852.9 						"/>
						<polyline class="st470" points="459.2,852.9 459.2,852.9 459.2,852.9 459.2,852.9 459.2,852.9 						"/>
						<polyline class="st471" points="459.2,852.9 459.2,852.9 459.2,852.9 459.2,852.9 459.2,852.9 						"/>
						<polyline class="st472" points="459.2,852.9 459.2,852.9 459.2,852.9 459.2,852.9 459.2,852.9 						"/>
						<polyline class="st473" points="459.2,852.9 459.2,852.9 459.2,852.9 459.2,852.9 459.2,852.9 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000035523300304980131670000017038339549971273888_" class="st1" d="M445.4,676.3l1.4-1.4
							c0.2,0.2,3.3,3.7,8,3.7l0.5,2C449.3,680.5,445.6,676.4,445.4,676.3z"/>
					</defs>
					<clipPath id="SVGID_00000008117013014430044210000004881108689058335879_">
						<use xlink:href="#SVGID_00000035523300304980131670000017038339549971273888_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000008117013014430044210000004881108689058335879_);enable-background:new    ;">
						<path class="st445" d="M445.4,676.3l1.4-1.4c0.1,0.1,1.5,1.7,3.8,2.7l-0.7,1.8C447.1,678.2,445.5,676.4,445.4,676.3"/>
						<path class="st446" d="M450,679.4l0.7-1.8c0.6,0.3,1.4,0.6,2.1,0.7l-0.1,2C451.7,680.1,450.8,679.8,450,679.4"/>
						<path class="st447" d="M452.7,680.3l0.1-2c0.5,0.1,1,0.2,1.6,0.2l0.3,2C454,680.5,453.3,680.4,452.7,680.3"/>
						<path class="st448" d="M454.7,680.5l-0.3-2c0.2,0,0.4,0,0.6,0l0.5,2C455.2,680.6,454.9,680.5,454.7,680.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000050632217596232065670000001527457312717770380_" class="st1" d="M477.4,858.1l-1.1,1.7
							c-5.8-1.7-11.5-4-17-6.8l-10.5-7.6C465.4,853.7,471.1,856.1,477.4,858.1z"/>
					</defs>
					<clipPath id="SVGID_00000023246316332694378820000015170494872825203132_">
						<use xlink:href="#SVGID_00000050632217596232065670000001527457312717770380_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000023246316332694378820000015170494872825203132_);enable-background:new    ;">
						<path class="st456" d="M477.4,858.1l-1.1,1.7c-5.8-1.7-11.5-4-17-6.8l-10.5-7.6C465.4,853.7,471.1,856.1,477.4,858.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000049180656980471692090000016955951924215518345_" class="st1" d="M455.4,849.8c0.2,0.3,0.3,0.4,0.8,0.8
							c0.2,0.2,0.2,0.2,3,2.2c0.1,0.1-3.1-1-6.3-1c-2.1,0-4.2,0.4-5.6,1.8l-1.4-1.4c1.9-1.9,4.6-2.4,7-2.4
							C454.6,849.9,455.6,850.2,455.4,849.8z"/>
					</defs>
					<clipPath id="SVGID_00000015326424814780058080000008927889253266513341_">
						<use xlink:href="#SVGID_00000049180656980471692090000016955951924215518345_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000015326424814780058080000008927889253266513341_);enable-background:new    ;">
						<path class="st475" d="M448.8,845.3l10.5,7.6c0,0-0.1,0-0.3-0.1l-6.1-4.8C451.8,847.3,450.5,846.4,448.8,845.3"/>
						<path class="st474" d="M452.8,848l6.1,4.8c0,0,0,0,0,0L452.8,848C452.9,848,452.9,848,452.8,848"/>
						<path class="st473" d="M452.9,848l6,4.8c0,0,0,0,0,0L452.9,848C452.9,848,452.9,848,452.9,848"/>
						<path class="st472" d="M452.9,848l6,4.8c0,0,0,0,0,0L452.9,848C452.9,848,452.9,848,452.9,848"/>
						<path class="st471" d="M453,848.1l5.9,4.8c0,0,0,0,0,0L453,848.1C453,848.1,453,848.1,453,848.1"/>
						<path class="st470" d="M453,848.1l5.9,4.7c0,0,0,0,0,0L453,848.1C453,848.1,453,848.1,453,848.1"/>
						<path class="st469" d="M453,848.1l5.8,4.7c0,0,0,0,0,0L453,848.1C453.1,848.1,453,848.1,453,848.1"/>
						<path class="st468" d="M453.1,848.1l5.8,4.7c0,0,0,0,0,0L453.1,848.1C453.1,848.1,453.1,848.1,453.1,848.1"/>
						<path class="st467" d="M453.1,848.2l5.7,4.6c0,0,0,0,0,0L453.1,848.2C453.1,848.2,453.1,848.2,453.1,848.2"/>
						<path class="st466" d="M453.1,848.2l5.7,4.6c0,0,0,0,0,0L453.1,848.2C453.2,848.2,453.2,848.2,453.1,848.2"/>
						<path class="st465" d="M453.2,848.2l5.6,4.6c0,0,0,0,0,0L453.2,848.2C453.2,848.2,453.2,848.2,453.2,848.2"/>
						<path class="st464" d="M453.2,848.2l5.6,4.6c0,0,0,0,0,0L453.2,848.2C453.2,848.2,453.2,848.2,453.2,848.2"/>
						<path class="st463" d="M453.2,848.3l5.6,4.5c0,0,0,0,0,0L453.2,848.3C453.3,848.3,453.3,848.3,453.2,848.3"/>
						<path class="st462" d="M453.3,848.3l5.5,4.5c0,0,0,0,0,0L453.3,848.3C453.3,848.3,453.3,848.3,453.3,848.3"/>
						<path class="st461" d="M453.3,848.3l5.5,4.5c0,0,0,0,0,0L453.3,848.3C453.3,848.3,453.3,848.3,453.3,848.3"/>
						<path class="st460" d="M453.4,848.3l5.4,4.5c0,0,0,0,0,0L453.4,848.3C453.4,848.3,453.4,848.3,453.4,848.3"/>
						<path class="st459" d="M453.4,848.3l5.4,4.4c0,0,0,0,0,0L453.4,848.3C453.4,848.4,453.4,848.4,453.4,848.3"/>
						<path class="st458" d="M453.4,848.4l5.3,4.4c0,0,0,0,0,0L453.4,848.4C453.4,848.4,453.4,848.4,453.4,848.4"/>
						<path class="st457" d="M453.5,848.4l5.3,4.4c0,0,0,0,0,0L453.5,848.4C453.5,848.4,453.5,848.4,453.5,848.4"/>
						<path class="st456" d="M453.5,848.4l5.2,4.4c0,0,0,0,0,0L453.5,848.4C453.5,848.4,453.5,848.4,453.5,848.4"/>
						<path class="st455" d="M453.5,848.4l5.2,4.3c0,0,0,0,0,0L453.5,848.4C453.5,848.4,453.5,848.4,453.5,848.4"/>
						<path class="st454" d="M453.5,848.4l5.2,4.3c0,0,0,0,0,0L453.5,848.4C453.6,848.5,453.5,848.4,453.5,848.4"/>
						<path class="st453" d="M453.6,848.5l5.1,4.3c0,0,0,0,0,0L453.6,848.5C453.6,848.5,453.6,848.5,453.6,848.5"/>
						<path class="st452" d="M453.6,848.5l5.1,4.3c0,0,0,0,0,0L453.6,848.5C453.6,848.5,453.6,848.5,453.6,848.5"/>
						<path class="st451" d="M453.6,848.5l5.1,4.3c0,0,0,0,0,0L453.6,848.5C453.6,848.5,453.6,848.5,453.6,848.5"/>
						<path class="st450" d="M453.6,848.5l5.1,4.3c0,0,0,0,0,0L453.6,848.5C453.6,848.5,453.6,848.5,453.6,848.5"/>
						<path class="st449" d="M453.7,848.5l5,4.2c0,0,0,0,0,0L453.7,848.5C453.7,848.5,453.7,848.5,453.7,848.5"/>
						<path class="st448" d="M453.7,848.5l5,4.2c0,0,0,0,0,0L453.7,848.5C453.7,848.5,453.7,848.5,453.7,848.5"/>
						<path class="st447" d="M453.7,848.5l5,4.2c0,0,0,0,0,0L453.7,848.5C453.7,848.6,453.7,848.6,453.7,848.5"/>
						<path class="st446" d="M453.7,848.6l4.9,4.2c0,0,0,0,0,0L453.7,848.6C453.7,848.6,453.7,848.6,453.7,848.6"/>
						<path class="st445" d="M453.7,848.6l4.9,4.2c0,0,0,0,0,0L453.7,848.6C453.8,848.6,453.7,848.6,453.7,848.6"/>
						<path class="st444" d="M453.8,848.6l4.9,4.2c0,0,0,0,0,0L453.8,848.6C453.8,848.6,453.8,848.6,453.8,848.6"/>
						<path class="st497" d="M453.8,848.6l4.8,4.1c0,0,0,0,0,0L453.8,848.6C453.8,848.6,453.8,848.6,453.8,848.6"/>
						<path class="st498" d="M453.8,848.6l4.8,4.1c0,0,0,0,0,0L453.8,848.6C453.8,848.6,453.8,848.6,453.8,848.6"/>
						<path class="st499" d="M453.8,848.6l4.8,4.1c0,0,0,0,0,0L453.8,848.6C453.8,848.6,453.8,848.6,453.8,848.6"/>
						<path class="st500" d="M453.8,848.6l4.8,4.1c0,0,0,0,0,0L453.8,848.6C453.9,848.7,453.9,848.7,453.8,848.6"/>
						<path class="st501" d="M453.9,848.7l4.7,4.1c0,0,0,0,0,0L453.9,848.7C453.9,848.7,453.9,848.7,453.9,848.7"/>
						<path class="st502" d="M453.9,848.7l4.7,4.1c0,0,0,0,0,0L453.9,848.7C453.9,848.7,453.9,848.7,453.9,848.7"/>
						<path class="st503" d="M453.9,848.7l4.7,4c0,0,0,0,0,0L453.9,848.7C453.9,848.7,453.9,848.7,453.9,848.7"/>
						<path class="st504" d="M453.9,848.7l4.6,4c0,0,0,0,0,0L453.9,848.7C453.9,848.7,453.9,848.7,453.9,848.7"/>
						<path class="st505" d="M454,848.7l4.6,4c0,0,0,0,0,0L454,848.7C454,848.7,454,848.7,454,848.7"/>
						<path class="st506" d="M454,848.7l4.6,4c0,0,0,0,0,0L454,848.7C454,848.7,454,848.7,454,848.7"/>
						<path class="st507" d="M454,848.7l4.6,4c0,0,0,0,0,0L454,848.7C454,848.8,454,848.8,454,848.7"/>
						<path class="st508" d="M454,848.8l4.5,4c0,0,0,0,0,0L454,848.8C454,848.8,454,848.8,454,848.8"/>
						<path class="st509" d="M454,848.8l4.5,3.9c0,0,0,0,0,0L454,848.8C454,848.8,454,848.8,454,848.8"/>
						<path class="st725" d="M454,848.8l4.5,3.9c0,0,0,0,0,0L454,848.8C454.1,848.8,454.1,848.8,454,848.8"/>
						<path class="st726" d="M454.1,848.8l4.4,3.9c0,0,0,0,0,0L454.1,848.8C454.1,848.8,454.1,848.8,454.1,848.8"/>
						<path class="st725" d="M454.1,848.8l4.4,3.9c0,0,0,0,0,0L454.1,848.8C454.1,848.8,454.1,848.8,454.1,848.8"/>
						<path class="st509" d="M454.1,848.9l4.3,3.9c0,0,0,0,0,0L454.1,848.9C454.2,848.9,454.2,848.9,454.1,848.9"/>
						<path class="st508" d="M454.2,848.9l4.3,3.8c0,0,0,0,0,0L454.2,848.9C454.2,848.9,454.2,848.9,454.2,848.9"/>
						<path class="st507" d="M454.2,848.9l4.2,3.8c0,0,0,0,0,0L454.2,848.9C454.3,848.9,454.3,848.9,454.2,848.9"/>
						<path class="st506" d="M454.3,848.9l4.1,3.7c0,0,0,0,0,0L454.3,848.9C454.3,849,454.3,849,454.3,848.9"/>
						<path class="st505" d="M454.3,849l4,3.7c0,0,0,0,0,0L454.3,849C454.4,849,454.4,849,454.3,849"/>
						<path class="st504" d="M454.4,849l4,3.7c0,0,0,0,0,0L454.4,849C454.4,849,454.4,849,454.4,849"/>
						<path class="st503" d="M454.4,849l3.9,3.6c0,0,0,0,0,0L454.4,849C454.5,849.1,454.4,849.1,454.4,849"/>
						<path class="st502" d="M454.5,849.1l3.8,3.6c0,0,0,0,0,0L454.5,849.1C454.5,849.1,454.5,849.1,454.5,849.1"/>
						<path class="st501" d="M454.5,849.1l3.8,3.6c0,0,0,0,0,0L454.5,849.1C454.5,849.1,454.5,849.1,454.5,849.1"/>
						<path class="st500" d="M454.6,849.1l3.7,3.5c0,0,0,0,0,0L454.6,849.1C454.6,849.2,454.6,849.1,454.6,849.1"/>
						<path class="st499" d="M454.6,849.2l3.6,3.5c0,0,0,0,0,0L454.6,849.2C454.6,849.2,454.6,849.2,454.6,849.2"/>
						<path class="st498" d="M454.6,849.2l3.5,3.4c0,0,0,0,0,0L454.6,849.2C454.7,849.2,454.7,849.2,454.6,849.2"/>
						<path class="st497" d="M454.7,849.2l3.5,3.4c0,0,0,0,0,0L454.7,849.2C454.7,849.3,454.7,849.2,454.7,849.2"/>
						<path class="st444" d="M454.7,849.3l3.4,3.4c0,0,0,0,0,0L454.7,849.3C454.8,849.3,454.8,849.3,454.7,849.3"/>
						<path class="st445" d="M454.8,849.3l3.3,3.3c0,0,0,0,0,0L454.8,849.3C454.8,849.3,454.8,849.3,454.8,849.3"/>
						<path class="st446" d="M454.8,849.3l3.2,3.3c0,0,0,0,0,0L454.8,849.3C454.9,849.3,454.8,849.3,454.8,849.3"/>
						<path class="st447" d="M454.9,849.4l3.1,3.2c0,0,0,0,0,0L454.9,849.4C454.9,849.4,454.9,849.4,454.9,849.4"/>
						<path class="st448" d="M454.9,849.4l3.1,3.2c0,0,0,0-0.1,0L454.9,849.4C455,849.4,454.9,849.4,454.9,849.4"/>
						<path class="st449" d="M455,849.4l2.9,3.1c0,0-0.1,0-0.1,0L455,849.4C455,849.5,455,849.5,455,849.4"/>
						<path class="st450" d="M455.1,849.5l2.8,3.1c0,0-0.1,0-0.1,0L455.1,849.5C455.1,849.5,455.1,849.5,455.1,849.5"/>
						<path class="st451" d="M455.1,849.6l2.6,3c0,0-0.1,0-0.1,0L455.1,849.6C455.2,849.6,455.2,849.6,455.1,849.6"/>
						<path class="st452" d="M455.2,849.6l2.4,2.9c-0.1,0-0.1,0-0.2,0L455.2,849.6C455.3,849.7,455.2,849.6,455.2,849.6"/>
						<path class="st453" d="M455.3,849.7l2.1,2.8c-0.1,0-0.2,0-0.2-0.1L455.3,849.7C455.4,849.8,455.3,849.7,455.3,849.7"/>
						<path class="st454" d="M455.4,849.8l1.8,2.6c-0.2,0-0.3-0.1-0.5-0.1l-1.3-2.4C455.4,849.9,455.4,849.8,455.4,849.8"/>
						<path class="st455" d="M455.4,849.9l1.3,2.4c-1.1-0.2-2.5-0.4-3.8-0.4c-0.7,0-1.4,0.1-2.1,0.2l-0.4-2c0.9-0.2,1.7-0.2,2.6-0.2
							C454.4,849.9,455.4,850.1,455.4,849.9"/>
						<path class="st454" d="M450.3,850.1l0.4,2c-0.6,0.1-1.2,0.3-1.7,0.5l-0.8-1.8C448.9,850.5,449.6,850.3,450.3,850.1"/>
						<path class="st453" d="M448.2,850.8l0.8,1.8c-0.4,0.1-0.7,0.3-1,0.5l-1.2-1.6C447.3,851.2,447.7,851,448.2,850.8"/>
						<path class="st452" d="M446.9,851.5l1.2,1.6c-0.2,0.2-0.5,0.4-0.7,0.6l-1.4-1.4C446.2,852,446.6,851.7,446.9,851.5"/>
						<path class="st451" d="M445.9,852.3l1.4,1.4c0,0,0,0-0.1,0.1L445.9,852.3C445.9,852.3,445.9,852.3,445.9,852.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000178923437084602286090000003557043223991272586_" class="st1" d="M455.4,680.6l-0.5-2
							c7-3.8,13.9-6.8,21.1-8.9l0.6,1.9C469.5,673.7,462.7,676.8,455.4,680.6z"/>
					</defs>
					<clipPath id="SVGID_00000136394229156241186000000012839727611398688388_">
						<use xlink:href="#SVGID_00000178923437084602286090000003557043223991272586_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000136394229156241186000000012839727611398688388_);enable-background:new    ;">
						<path class="st451" d="M455.4,680.6l-0.5-2c3.2-1.7,6.4-3.3,9.6-4.7l0.7,1.9C462,677.2,458.7,678.8,455.4,680.6"/>
						<path class="st450" d="M465.2,675.8l-0.7-1.9c3.8-1.7,7.6-3.1,11.5-4.2l0.6,1.9C472.7,672.7,469,674.1,465.2,675.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000070802572063364792180000014237769939086217657_" class="st1" d="M482.7,881.1l-2,0
							c-0.1-8-0.1-11.8-0.1-12l2,0C482.7,869.3,482.7,873.1,482.7,881.1z"/>
					</defs>
					<clipPath id="SVGID_00000024710018209665554390000005429625923501980322_">
						<use xlink:href="#SVGID_00000070802572063364792180000014237769939086217657_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000024710018209665554390000005429625923501980322_);enable-background:new    ;">
						<path class="st456" d="M482.7,881.1l-2,0c-0.1-8-0.1-11.8-0.1-12l2,0C482.7,869.3,482.7,873.1,482.7,881.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000021100740043528299890000005564753053127822982_" class="st1" d="M482.7,869.1l-2,0
							c0-0.3,0.4-6.2-4.4-9.4l1.1-1.7C483.1,862,482.7,868.9,482.7,869.1z"/>
					</defs>
					<clipPath id="SVGID_00000070818678839620257980000011720063714719186568_">
						<use xlink:href="#SVGID_00000021100740043528299890000005564753053127822982_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000070818678839620257980000011720063714719186568_);enable-background:new    ;">
						<path class="st456" d="M482.7,869.1l-2,0c0-0.2,0.1-2.6-1-5.2l1.8-0.9C482.8,866.1,482.7,869,482.7,869.1"/>
						<path class="st457" d="M481.5,863.1l-1.8,0.9c-0.6-1.3-1.5-2.7-2.9-3.8l1.2-1.6C479.7,859.9,480.8,861.5,481.5,863.1"/>
						<path class="st456" d="M478,858.5l-1.2,1.6c-0.2-0.1-0.4-0.3-0.5-0.4l1.1-1.7C477.6,858.2,477.8,858.4,478,858.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000154407267025714764260000009064024451811241899_" class="st1" d="M476.5,671.6l-0.6-1.9
							c2.3-1.6,5.4-4.4,5.4-7.5l2,0C483.3,666.3,479.6,669.5,476.5,671.6z"/>
					</defs>
					<clipPath id="SVGID_00000080185674765779607850000016633244575247834532_">
						<use xlink:href="#SVGID_00000154407267025714764260000009064024451811241899_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000080185674765779607850000016633244575247834532_);enable-background:new    ;">
						<path class="st452" d="M476.5,671.6l-0.6-1.9c0.7-0.5,1.6-1.2,2.3-1.9l1.3,1.5C478.6,670.1,477.5,670.9,476.5,671.6"/>
						<path class="st453" d="M479.6,669.2l-1.3-1.5c0.6-0.6,1.2-1.2,1.6-1.8l1.7,1.1C481,667.8,480.3,668.6,479.6,669.2"/>
						<path class="st454" d="M481.6,667l-1.7-1.1c0.4-0.5,0.7-1,0.9-1.6l1.9,0.7C482.4,665.7,482.1,666.4,481.6,667"/>
						<path class="st455" d="M482.7,665l-1.9-0.7c0.2-0.5,0.4-1.1,0.5-1.6l2,0.2C483.2,663.6,483,664.3,482.7,665"/>
						<path class="st456" d="M483.3,662.9l-2-0.2c0-0.2,0-0.4,0-0.6l2,0C483.3,662.4,483.3,662.6,483.3,662.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000047782204322947162970000017229339166911883434_" class="st1" d="M483.3,662.1l-2,0c0-5-0.1-12.6-0.1-13
							c0-0.5-0.1-9,6.9-9l0,2c-4.9-0.1-5,6.7-5,7C483.3,649.5,483.3,657.1,483.3,662.1z"/>
					</defs>
					<clipPath id="SVGID_00000168085816341102641710000007099561493678272941_">
						<use xlink:href="#SVGID_00000047782204322947162970000017229339166911883434_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000168085816341102641710000007099561493678272941_);enable-background:new    ;">
						<path class="st456" d="M483.3,662.1l-2,0c0-5-0.1-12.6-0.1-13c0-0.1,0-0.6,0.1-1.4l2,0.3c-0.1,0.6-0.1,1-0.1,1.1
							C483.3,649.5,483.3,657.1,483.3,662.1"/>
						<path class="st455" d="M483.3,648.1l-2-0.3c0.1-0.9,0.3-2.3,0.9-3.5l1.8,1C483.6,646.3,483.4,647.3,483.3,648.1"/>
						<path class="st454" d="M484,645.3l-1.8-1c0.2-0.6,0.5-1.1,0.9-1.7l1.5,1.4C484.4,644.4,484.1,644.8,484,645.3"/>
						<path class="st453" d="M484.6,644l-1.5-1.4c0.2-0.3,0.5-0.6,0.8-0.9l1.3,1.6C485,643.5,484.8,643.7,484.6,644"/>
						<path class="st452" d="M485.2,643.3l-1.3-1.6c0.2-0.2,0.5-0.4,0.8-0.6l1,1.7C485.6,642.9,485.4,643.1,485.2,643.3"/>
						<path class="st451" d="M485.8,642.8l-1-1.7c0.2-0.2,0.5-0.3,0.8-0.4l0.8,1.8C486.2,642.5,486,642.7,485.8,642.8"/>
						<path class="st450" d="M486.4,642.5l-0.8-1.8c0.3-0.1,0.6-0.2,0.9-0.3l0.5,1.9C486.8,642.3,486.6,642.4,486.4,642.5"/>
						<path class="st449" d="M487,642.2l-0.5-1.9c0.3-0.1,0.6-0.1,1-0.2l0.2,2C487.4,642.1,487.2,642.2,487,642.2"/>
						<path class="st448" d="M487.7,642.1l-0.2-2c0.2,0,0.5,0,0.8,0l0,2C488,642.1,487.8,642.1,487.7,642.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000075879897695528921510000004941759451555835563_" class="st1" d="M489.8,888.1l0,2c-0.4,0-9-0.9-9.1-8.9
							l2,0C482.7,887.3,489.6,888.1,489.8,888.1z"/>
					</defs>
					<clipPath id="SVGID_00000027589130308263391490000002360965383570619316_">
						<use xlink:href="#SVGID_00000075879897695528921510000004941759451555835563_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000027589130308263391490000002360965383570619316_);enable-background:new    ;">
						<path class="st456" d="M489.8,888.1l0,2c-0.3,0-3.5-0.4-6-2.3l1.3-1.5C487.2,887.8,489.7,888.1,489.8,888.1"/>
						<path class="st457" d="M485.2,886.2l-1.3,1.5c-0.9-0.7-1.8-1.7-2.3-2.9l1.8-0.9C483.8,884.9,484.4,885.7,485.2,886.2"/>
						<path class="st456" d="M483.3,884l-1.8,0.9c-0.5-1-0.8-2.2-0.8-3.7l2,0C482.7,882.2,482.9,883.2,483.3,884"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000123433520412108058520000011960527997938342281_" class="st1" d="M504,687.4L504,687.4
							c-20.4,0-40.7,7.8-56.2,23.5c-30.6,31-30.3,81.1,0.7,111.7c15.4,15.2,35.4,22.8,55.5,22.8h0c-20,0-40.1-7.6-55.5-22.8
							c-31-30.7-31.4-80.7-0.7-111.7C463.2,695.3,483.6,687.4,504,687.4z"/>
					</defs>
					<clipPath id="SVGID_00000136378573376541425460000005776375667655803013_">
						<use xlink:href="#SVGID_00000123433520412108058520000011960527997938342281_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000136378573376541425460000005776375667655803013_);enable-background:new    ;">
						<path class="st475" d="M504,687.4L504,687.4c-20.4,0-40.7,7.8-56.2,23.5c-5.3,5.4-9.7,11.4-13.2,17.7h0
							c3.5-6.4,7.9-12.3,13.2-17.7C463.2,695.3,483.6,687.4,504,687.4"/>
						<path class="st474" d="M434.6,728.6L434.6,728.6c-2.2,4.1-4.1,8.4-5.6,12.8h0C430.5,737.1,432.3,732.8,434.6,728.6"/>
						<path class="st473" d="M429,741.5L429,741.5c-1.2,3.8-2.2,7.7-2.9,11.6h0C426.8,749.1,427.8,745.3,429,741.5"/>
						<path class="st472" d="M426.1,753L426.1,753c-0.6,3.8-1,7.6-1.1,11.5h0C425.1,760.7,425.5,756.8,426.1,753"/>
						<path class="st471" d="M425,764.5L425,764.5c-0.1,4,0.1,8.1,0.6,12.1h0C425.2,772.6,424.9,768.5,425,764.5"/>
						<path class="st470" d="M425.7,776.6L425.7,776.6c0.6,4.7,1.6,9.4,3.1,13.9h0C427.3,785.9,426.3,781.3,425.7,776.6"/>
						<path class="st469" d="M428.8,790.5L428.8,790.5c3.8,11.8,10.4,22.9,19.8,32.1c5.2,5.1,10.8,9.3,16.9,12.7h0
							c-6-3.4-11.7-7.6-16.9-12.7C439.1,813.3,432.5,802.3,428.8,790.5"/>
						<path class="st470" d="M465.4,835.3L465.4,835.3c4.2,2.3,8.5,4.3,12.9,5.8h0C473.9,839.6,469.5,837.7,465.4,835.3"/>
						<path class="st471" d="M478.3,841.1L478.3,841.1c3.8,1.3,7.7,2.3,11.6,3h0C486,843.4,482.1,842.4,478.3,841.1"/>
						<path class="st472" d="M489.9,844.1L489.9,844.1c3.8,0.7,7.6,1.1,11.4,1.2h0C497.5,845.2,493.7,844.8,489.9,844.1"/>
						<path class="st473" d="M501.3,845.4L501.3,845.4c0.9,0,1.8,0,2.7,0h0C503.1,845.4,502.2,845.4,501.3,845.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000085232717111831601390000000470926169888354480_" class="st1" d="M519.8,889.9L519.8,889.9
							c-7,0-28.8,0.2-30,0.2h0C490.9,890.1,512.8,889.9,519.8,889.9z"/>
					</defs>
					<clipPath id="SVGID_00000156558372493437159440000009203134382352358298_">
						<use xlink:href="#SVGID_00000085232717111831601390000000470926169888354480_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000156558372493437159440000009203134382352358298_);enable-background:new    ;">
						<path class="st475" d="M519.8,889.9L519.8,889.9c-7,0-28.8,0.2-30,0.2h0C490.9,890.1,512.8,889.9,519.8,889.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000144299936439350437320000008825841747582045582_" class="st1" d="M526.6,867.9L526.6,867.9
							c0,5,0.1,12.6,0.1,13h0C526.7,880.5,526.7,872.9,526.6,867.9z"/>
					</defs>
					<clipPath id="SVGID_00000047759472124270189140000006765608878732162239_">
						<use xlink:href="#SVGID_00000144299936439350437320000008825841747582045582_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000047759472124270189140000006765608878732162239_);enable-background:new    ;">
						<path class="st471" d="M526.6,867.9L526.6,867.9c0,5,0.1,12.6,0.1,13h0C526.7,880.5,526.7,872.9,526.6,867.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000174571581148492447870000010425730657265669303_" class="st1" d="M526.7,880.9L526.7,880.9
							c0,0.5,0.1,9-6.9,9h0C526.8,889.9,526.7,881.3,526.7,880.9z"/>
					</defs>
					<clipPath id="SVGID_00000030478353658298743100000000239531951241181057_">
						<use xlink:href="#SVGID_00000174571581148492447870000010425730657265669303_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000030478353658298743100000000239531951241181057_);enable-background:new    ;">
						<path class="st471" d="M526.7,880.9L526.7,880.9c0,0,0,0.1,0,0.3h0C526.7,881,526.7,880.9,526.7,880.9"/>
						<path class="st472" d="M526.7,881.1L526.7,881.1c0,0.4,0,1.1-0.2,2h0C526.7,882.3,526.7,881.5,526.7,881.1"/>
						<path class="st473" d="M526.5,883.1L526.5,883.1c-0.1,0.6-0.2,1.2-0.4,1.8h0C526.3,884.4,526.4,883.7,526.5,883.1"/>
						<path class="st474" d="M526.1,885L526.1,885c-0.2,0.5-0.4,1.1-0.7,1.6h0C525.7,886,525.9,885.5,526.1,885"/>
						<path class="st475" d="M525.4,886.6L525.4,886.6c-1,1.8-2.7,3.3-5.6,3.3h0C522.7,889.9,524.4,888.4,525.4,886.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000105388854212934433280000002868623558818471599_" class="st1" d="M488.2,642.1l0-2c7,0,28.8-0.2,30-0.2
							l0,2C517.1,641.9,495.2,642.1,488.2,642.1z"/>
					</defs>
					<clipPath id="SVGID_00000174562110631157856140000000442447511213678516_">
						<use xlink:href="#SVGID_00000105388854212934433280000002868623558818471599_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000174562110631157856140000000442447511213678516_);enable-background:new    ;">
						<path class="st448" d="M488.2,642.1l0-2c7,0,28.8-0.2,30-0.2l0,2C517.1,641.9,495.2,642.1,488.2,642.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000104679231796716874140000018363992628686312382_" class="st1" d="M518.2,639.9L518.2,639.9
							c0.5,0,9,0.9,9.1,8.9h0C527.2,640.9,518.7,640,518.2,639.9z"/>
					</defs>
					<clipPath id="SVGID_00000003069862699117597140000014616164788023826840_">
						<use xlink:href="#SVGID_00000104679231796716874140000018363992628686312382_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000003069862699117597140000014616164788023826840_);enable-background:new    ;">
						<path class="st472" d="M518.2,639.9L518.2,639.9c0.1,0,0.3,0,0.8,0.1h0C518.5,639.9,518.3,639.9,518.2,639.9"/>
						<path class="st471" d="M518.9,640L518.9,640c0.5,0.1,1.2,0.2,2,0.5h0C520.1,640.3,519.4,640.1,518.9,640"/>
						<path class="st470" d="M520.9,640.5L520.9,640.5c0.6,0.2,1.3,0.5,2,0.9h0C522.2,641,521.5,640.7,520.9,640.5"/>
						<path class="st469" d="M522.9,641.4L522.9,641.4c1.6,0.9,3.2,2.3,3.9,4.6h0C526.1,643.8,524.5,642.3,522.9,641.4"/>
						<path class="st470" d="M526.8,646L526.8,646c0.2,0.5,0.3,1,0.4,1.6h0C527.1,647,527,646.5,526.8,646"/>
						<path class="st471" d="M527.2,647.6L527.2,647.6c0.1,0.4,0.1,0.8,0.1,1.3h0C527.3,648.4,527.2,648,527.2,647.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000153702957301979918460000004348804264748242083_" class="st1" d="M527.3,648.9L527.3,648.9
							c0.1,8,0.1,11.8,0.1,12h0C527.3,660.6,527.3,656.9,527.3,648.9z"/>
					</defs>
					<clipPath id="SVGID_00000065070101010693292350000007870755304778349481_">
						<use xlink:href="#SVGID_00000153702957301979918460000004348804264748242083_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000065070101010693292350000007870755304778349481_);enable-background:new    ;">
						<path class="st471" d="M527.3,648.9L527.3,648.9c0.1,8,0.1,11.8,0.1,12h0C527.3,660.6,527.3,656.9,527.3,648.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000144338766898785887670000001034845981692084915_" class="st1" d="M519.8,887.9l0,2c-7,0-28.9,0.2-30,0.2
							l0-2C490.9,888.1,512.8,887.9,519.8,887.9z"/>
					</defs>
					<clipPath id="SVGID_00000059307025296241817460000011256021470970958005_">
						<use xlink:href="#SVGID_00000144338766898785887670000001034845981692084915_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000059307025296241817460000011256021470970958005_);enable-background:new    ;">
						<path class="st455" d="M519.8,887.9l0,2c-7,0-28.9,0.2-30,0.2l0-2C490.9,888.1,512.8,887.9,519.8,887.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000131366387436151199570000008131330349721388474_" class="st1" d="M527.3,660.8L527.3,660.8
							c0,0.2-0.2,4.5,2.6,7.8l0,0C527.1,665.3,527.3,661.1,527.3,660.8z"/>
					</defs>
					<clipPath id="SVGID_00000132065130989387670580000008109954872295172746_">
						<use xlink:href="#SVGID_00000131366387436151199570000008131330349721388474_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000132065130989387670580000008109954872295172746_);enable-background:new    ;">
						<path class="st472" d="M527.3,660.8L527.3,660.8c0,0,0,0.1,0,0.2h0C527.3,660.9,527.3,660.9,527.3,660.8"/>
						<path class="st471" d="M527.3,661L527.3,661c0,0.3,0,0.9,0.1,1.7h0C527.3,662,527.3,661.3,527.3,661"/>
						<path class="st470" d="M527.4,662.8L527.4,662.8c0.1,0.6,0.2,1.4,0.5,2.2h0C527.7,664.2,527.5,663.4,527.4,662.8"/>
						<path class="st469" d="M527.9,665L527.9,665c0.4,1.2,1,2.5,2,3.7h0C528.9,667.5,528.3,666.2,527.9,665"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000039833337652779666270000003054073608551868581_" class="st1" d="M532.9,859.8L532.9,859.8
							c-2.3,1.5-6.2,4.5-6.2,8h0C526.6,864.4,530.5,861.3,532.9,859.8z"/>
					</defs>
					<clipPath id="SVGID_00000100376764562014789370000007579937260527984032_">
						<use xlink:href="#SVGID_00000039833337652779666270000003054073608551868581_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000100376764562014789370000007579937260527984032_);enable-background:new    ;">
						<path class="st475" d="M532.9,859.8L532.9,859.8c-1.7,1.1-4.2,3-5.4,5.3h0C528.6,862.8,531.2,860.9,532.9,859.8"/>
						<path class="st474" d="M527.4,865.1L527.4,865.1c-0.2,0.4-0.3,0.7-0.5,1.1h0C527,865.8,527.2,865.4,527.4,865.1"/>
						<path class="st473" d="M526.9,866.2L526.9,866.2c-0.1,0.3-0.1,0.5-0.2,0.8h0C526.8,866.7,526.8,866.5,526.9,866.2"/>
						<path class="st472" d="M526.7,867L526.7,867c0,0.2-0.1,0.5-0.1,0.7h0C526.6,867.5,526.7,867.2,526.7,867"/>
						<path class="st471" d="M526.6,867.7L526.6,867.7c0,0.1,0,0.1,0,0.2h0C526.6,867.8,526.6,867.8,526.6,867.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000060014589621566253280000017900645790018819252_" class="st1" d="M524.6,867.9l2,0c0,5,0.1,12.6,0.1,13
							c0,0.5,0.1,9-6.9,9l0-2c4.9,0.1,5-6.7,5-7C524.7,880.5,524.7,872.9,524.6,867.9z"/>
					</defs>
					<clipPath id="SVGID_00000159461357503240422460000010030982747312592534_">
						<use xlink:href="#SVGID_00000060014589621566253280000017900645790018819252_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000159461357503240422460000010030982747312592534_);enable-background:new    ;">
						<path class="st447" d="M524.6,867.9l2,0c0,5,0.1,12.6,0.1,13c0,0.1,0,1-0.2,2.2l-2-0.4c0.1-0.9,0.1-1.6,0.1-1.7
							C524.7,880.5,524.7,872.9,524.6,867.9"/>
						<path class="st448" d="M524.6,882.6l2,0.4c-0.1,0.6-0.3,1.3-0.5,2.1l-1.8-0.9C524.4,883.6,524.5,883.1,524.6,882.6"/>
						<path class="st449" d="M524.2,884.2l1.8,0.9c-0.2,0.5-0.4,1-0.6,1.4l-1.7-1.2C523.9,885,524.1,884.6,524.2,884.2"/>
						<path class="st450" d="M523.8,885.3l1.7,1.2c-0.2,0.4-0.4,0.7-0.7,1l-1.5-1.4C523.5,885.9,523.6,885.6,523.8,885.3"/>
						<path class="st451" d="M523.3,886.1l1.5,1.4c-0.2,0.3-0.4,0.5-0.7,0.8l-1.3-1.6C523,886.5,523.1,886.3,523.3,886.1"/>
						<path class="st452" d="M522.8,886.7l1.3,1.6c-0.2,0.2-0.5,0.4-0.8,0.6l-1.1-1.7C522.4,887.1,522.6,886.9,522.8,886.7"/>
						<path class="st453" d="M522.2,887.2l1.1,1.7c-0.3,0.2-0.6,0.4-0.9,0.5l-0.8-1.9C521.8,887.5,522,887.3,522.2,887.2"/>
						<path class="st454" d="M521.6,887.6l0.8,1.9c-0.4,0.2-0.8,0.3-1.3,0.4l-0.4-2C521,887.8,521.3,887.7,521.6,887.6"/>
						<path class="st455" d="M520.7,887.8l0.4,2c-0.4,0.1-0.8,0.1-1.3,0.1l0-2C520.1,887.9,520.4,887.9,520.7,887.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000181800470959373974800000002374389881786427525_" class="st1" d="M518.2,641.9l0-2c0.5,0,9,0.9,9.1,8.9
							l-2,0C525.3,642.7,518.3,641.9,518.2,641.9z"/>
					</defs>
					<clipPath id="SVGID_00000136379382353515187360000015187404067862820782_">
						<use xlink:href="#SVGID_00000181800470959373974800000002374389881786427525_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000136379382353515187360000015187404067862820782_);enable-background:new    ;">
						<path class="st447" d="M518.2,641.9l0-2c0.1,0,0.7,0.1,1.5,0.3l-0.4,2C518.7,642,518.2,641.9,518.2,641.9"/>
						<path class="st446" d="M519.3,642.1l0.4-2c0.8,0.2,1.8,0.5,2.8,1l-1,1.7C520.7,642.5,519.9,642.3,519.3,642.1"/>
						<path class="st445" d="M521.5,642.9l1-1.7c1.9,1,3.8,2.6,4.5,5.5l-1.9,0.5C524.5,645,523,643.7,521.5,642.9"/>
						<path class="st446" d="M525.1,647.2l1.9-0.5c0.1,0.6,0.2,1.3,0.2,2l-2,0.1C525.2,648.2,525.2,647.7,525.1,647.2"/>
						<path class="st447" d="M525.3,648.7l2-0.1c0,0.1,0,0.1,0,0.2l-2,0C525.3,648.8,525.3,648.8,525.3,648.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000018230806844256859450000010146654758706001597_" class="st1" d="M525.3,648.9l2,0c0.1,8,0.1,11.8,0.1,12
							l-2,0C525.3,660.7,525.3,656.9,525.3,648.9z"/>
					</defs>
					<clipPath id="SVGID_00000127025809483165903450000008350341195432614556_">
						<use xlink:href="#SVGID_00000018230806844256859450000010146654758706001597_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000127025809483165903450000008350341195432614556_);enable-background:new    ;">
						<path class="st447" d="M525.3,648.9l2,0c0.1,8,0.1,11.8,0.1,12l-2,0C525.3,660.7,525.3,656.9,525.3,648.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000152227379146948960720000010791297766809031301_" class="st1" d="M581,862.1c0.1,0.1,0.3,0.3,0.4,0.4
							L581,862.1C581,862.1,581,862.1,581,862.1z"/>
					</defs>
					<clipPath id="SVGID_00000062911461214394682320000015264921355232881835_">
						<use xlink:href="#SVGID_00000152227379146948960720000010791297766809031301_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000062911461214394682320000015264921355232881835_);enable-background:new    ;">
						<polyline class="st475" points="581.4,862.5 581,862.1 581,862.1 581.4,862.5 581.4,862.5 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000149377506766901335500000003162809993940992667_" class="st1" d="M525.3,660.9l2,0c0,0.2-0.3,4.5,2.6,7.8
							l-1.1,1.8C525,666,525.3,661,525.3,660.9z"/>
					</defs>
					<clipPath id="SVGID_00000029758986459673436880000016769571884416532926_">
						<use xlink:href="#SVGID_00000149377506766901335500000003162809993940992667_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000029758986459673436880000016769571884416532926_);enable-background:new    ;">
						<path class="st447" d="M525.3,660.9l2,0c0,0,0,0.2,0,0.5l-2,0.1C525.3,661.1,525.3,660.9,525.3,660.9"/>
						<path class="st446" d="M525.3,661.4l2-0.1c0,0.5,0,1.3,0.2,2.2l-1.9,0.5C525.4,662.9,525.3,662,525.3,661.4"/>
						<path class="st445" d="M525.6,664l1.9-0.5c0.2,0.9,0.5,2,1.1,3.1l-1.5,1.2C526.3,666.5,525.9,665.2,525.6,664"/>
						<path class="st444" d="M527.1,667.9l1.5-1.2c0.2,0.5,0.5,0.9,0.8,1.3l-1.3,1.6C527.8,669.1,527.4,668.5,527.1,667.9"/>
						<path class="st445" d="M528.2,669.6l1.3-1.6c0.1,0.2,0.3,0.4,0.5,0.6l-1.1,1.8C528.6,670.2,528.4,669.9,528.2,669.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000130624235477365804530000013637282339294098066_" class="st1" d="M532.3,857.9l0.6,1.9
							c-2.3,1.5-6.2,4.5-6.2,8l-2,0C524.7,863.2,529.2,859.8,532.3,857.9z"/>
					</defs>
					<clipPath id="SVGID_00000008125484643801378770000017219391341213650311_">
						<use xlink:href="#SVGID_00000130624235477365804530000013637282339294098066_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000008125484643801378770000017219391341213650311_);enable-background:new    ;">
						<path class="st453" d="M532.3,857.9l0.6,1.9c-0.3,0.2-0.6,0.4-0.9,0.6l-0.8-1.8C531.5,858.4,531.9,858.1,532.3,857.9"/>
						<path class="st452" d="M531.1,858.6l0.8,1.8c-0.8,0.6-1.7,1.3-2.5,2.1l-1.4-1.4C529,860.1,530.1,859.3,531.1,858.6"/>
						<path class="st451" d="M528.1,861.1l1.4,1.4c-0.5,0.5-0.9,1-1.3,1.5l-1.7-1.1C526.9,862.3,527.5,861.7,528.1,861.1"/>
						<path class="st450" d="M526.4,862.9l1.7,1.1c-0.3,0.4-0.5,0.8-0.7,1.2l-1.8-0.8C525.8,863.8,526.1,863.4,526.4,862.9"/>
						<path class="st449" d="M525.6,864.3l1.8,0.8c-0.2,0.3-0.3,0.6-0.4,1l-1.9-0.5C525.2,865.2,525.3,864.7,525.6,864.3"/>
						<path class="st448" d="M525,865.6l1.9,0.5c-0.1,0.3-0.2,0.6-0.2,0.9l-2-0.3C524.8,866.3,524.9,866,525,865.6"/>
						<path class="st447" d="M524.7,866.8l2,0.3c0,0.3-0.1,0.6-0.1,0.9l-2,0C524.6,867.5,524.7,867.1,524.7,866.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000121973482597719536300000009498042845015065763_" class="st1" d="M529.9,668.6L529.9,668.6
							c8,2.1,15.8,5.2,23.1,9.3h0C545.7,673.8,537.9,670.7,529.9,668.6z"/>
					</defs>
					<clipPath id="SVGID_00000090992843039325481770000017192746526316198045_">
						<use xlink:href="#SVGID_00000121973482597719536300000009498042845015065763_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000090992843039325481770000017192746526316198045_);enable-background:new    ;">
						<path class="st471" d="M529.9,668.6L529.9,668.6c2.4,0.6,4.7,1.3,7.1,2.1h0C534.6,670,532.3,669.3,529.9,668.6"/>
						<path class="st470" d="M537,670.8L537,670.8c5.5,1.9,10.9,4.3,16.1,7.2h0C547.8,675,542.5,672.7,537,670.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000094591953126557175570000006347338251988038321_" class="st1" d="M553.1,851.4L553.1,851.4
							c-6.6,3.6-13.3,6.3-20.2,8.4h0C539.7,857.8,546.4,855,553.1,851.4z"/>
					</defs>
					<clipPath id="SVGID_00000020385564397026334400000009706249409484025750_">
						<use xlink:href="#SVGID_00000094591953126557175570000006347338251988038321_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000020385564397026334400000009706249409484025750_);enable-background:new    ;">
						<path class="st475" d="M553.1,851.4L553.1,851.4c-6.6,3.6-13.3,6.3-20.2,8.4h0C539.7,857.8,546.4,855,553.1,851.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000116913920625128648700000016738151974098181781_" class="st1" d="M553,677.9L553,677.9
							c0.7,0.1,1.4,0.1,2.1,0.1h0C554.4,678.1,553.7,678,553,677.9z"/>
					</defs>
					<clipPath id="SVGID_00000021080130730082218940000015965542401602134409_">
						<use xlink:href="#SVGID_00000116913920625128648700000016738151974098181781_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000021080130730082218940000015965542401602134409_);enable-background:new    ;">
						<path class="st472" d="M553,677.9L553,677.9c0.5,0.1,1,0.1,1.6,0.1h0C554,678,553.5,678,553,677.9"/>
						<path class="st473" d="M554.6,678.1L554.6,678.1c0.2,0,0.4,0,0.6,0h0C554.9,678.1,554.8,678.1,554.6,678.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000065041889105543987710000005951745291314347413_" class="st1" d="M528.8,670.4l1.1-1.8
							c8,2.1,15.7,5.2,23.1,9.3l-1,1.8C544.9,675.9,537.2,672.6,528.8,670.4z"/>
					</defs>
					<clipPath id="SVGID_00000141443417649532938980000002329151695585326992_">
						<use xlink:href="#SVGID_00000065041889105543987710000005951745291314347413_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000141443417649532938980000002329151695585326992_);enable-background:new    ;">
						<path class="st446" d="M528.8,670.4l1.1-1.8c8,2.1,15.7,5.2,23.1,9.3l-1,1.8C544.9,675.9,537.2,672.6,528.8,670.4"/>
						<path class="st445" d="M552,679.7l1-1.8c0,0,0,0,0,0L552,679.7C552,679.7,552,679.7,552,679.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000111152764458209692430000002243369779164848305_" class="st1" d="M552.6,849.4l0.5,2
							c-6.6,3.6-13.4,6.3-20.2,8.4l-0.6-1.9C539,855.9,545.7,853,552.6,849.4z"/>
					</defs>
					<clipPath id="SVGID_00000047768934107888155510000003688683150836220332_">
						<use xlink:href="#SVGID_00000111152764458209692430000002243369779164848305_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000047768934107888155510000003688683150836220332_);enable-background:new    ;">
						<path class="st453" d="M552.6,849.4l0.5,2c-3.5,1.9-7.1,3.6-10.7,5l-0.7-1.9C545.3,853,548.9,851.3,552.6,849.4"/>
						<path class="st454" d="M541.8,854.5l0.7,1.9c-3.2,1.3-6.4,2.4-9.6,3.4l-0.6-1.9C535.4,857,538.6,855.8,541.8,854.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000181789741451127161900000015152795521937773498_" class="st1" d="M552,679.7l1-1.8
							c0.7,0.1,1.4,0.1,2.1,0.1c2.1,0,4.2-0.4,5.6-1.8l1.4,1.4c-1.9,1.9-4.6,2.4-7,2.4C554.2,680.1,553.5,680,552,679.7z"/>
					</defs>
					<clipPath id="SVGID_00000134246210411330853320000007139178554405566626_">
						<use xlink:href="#SVGID_00000181789741451127161900000015152795521937773498_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000134246210411330853320000007139178554405566626_);enable-background:new    ;">
						<path class="st447" d="M552,679.7l1-1.8c0.4,0,0.8,0.1,1.2,0.1l-0.2,2C553.5,680,552.9,679.9,552,679.7"/>
						<path class="st448" d="M554.1,680.1l0.2-2c0.3,0,0.6,0,0.9,0c0.4,0,0.9,0,1.3-0.1l0.2,2c-0.5,0-1,0.1-1.5,0.1
							C554.8,680.1,554.4,680.1,554.1,680.1"/>
						<path class="st449" d="M556.6,680l-0.2-2c0.5,0,1-0.1,1.5-0.3l0.6,1.9C557.9,679.8,557.3,679.9,556.6,680"/>
						<path class="st450" d="M558.5,679.7l-0.6-1.9c0.4-0.1,0.7-0.2,1.1-0.4l0.9,1.8C559.4,679.4,559,679.5,558.5,679.7"/>
						<path class="st451" d="M559.9,679.2l-0.9-1.8c0.3-0.1,0.6-0.3,0.8-0.4l1.1,1.7C560.6,678.8,560.3,679,559.9,679.2"/>
						<path class="st452" d="M561,678.6l-1.1-1.7c0.3-0.2,0.5-0.3,0.7-0.6l1.4,1.5C561.6,678.1,561.3,678.4,561,678.6"/>
						<path class="st453" d="M561.9,677.8l-1.4-1.5c0,0,0.1-0.1,0.1-0.1l1.4,1.4C562.1,677.7,562,677.8,561.9,677.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000160871905519585032020000010965924280659225233_" class="st1" d="M562.5,853.7l-1.4,1.4
							c-0.2-0.2-3.3-3.7-8-3.7l-0.5-2C558.7,849.5,562.4,853.6,562.5,853.7z"/>
					</defs>
					<clipPath id="SVGID_00000150102076390120929280000015856620226706725010_">
						<use xlink:href="#SVGID_00000160871905519585032020000010965924280659225233_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000150102076390120929280000015856620226706725010_);enable-background:new    ;">
						<path class="st457" d="M562.5,853.7l-1.4,1.4c-0.1-0.1-1-1.1-2.5-2l1-1.7C561.4,852.5,562.5,853.6,562.5,853.7"/>
						<path class="st456" d="M559.6,851.4l-1,1.7c-1.2-0.8-2.8-1.5-4.7-1.6l-0.2-2C556.2,849.7,558.2,850.6,559.6,851.4"/>
						<path class="st455" d="M553.7,849.5l0.2,2c-0.3,0-0.6,0-0.9,0l-0.5-2C553,849.4,553.3,849.5,553.7,849.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000130631812392149365380000016356117159451624611_" class="st1" d="M571.1,862.2l-1.4,1.4
							c-5.7-5.6-8.4-8.3-8.5-8.4l1.4-1.4C562.7,853.9,565.4,856.5,571.1,862.2z"/>
					</defs>
					<clipPath id="SVGID_00000069371174034760136190000017085232856071068297_">
						<use xlink:href="#SVGID_00000130631812392149365380000016356117159451624611_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000069371174034760136190000017085232856071068297_);enable-background:new    ;">
						<path class="st457" d="M571.1,862.2l-1.4,1.4c-5.7-5.6-8.4-8.3-8.5-8.4l1.4-1.4C562.7,853.9,565.4,856.5,571.1,862.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000074442184456828627410000011668707295348863634_" class="st1" d="M576.8,663.7L576.8,663.7
							c1.4,0,2.9,0.5,4.3,1.9h0C579.8,664.1,578.3,663.7,576.8,663.7z"/>
					</defs>
					<clipPath id="SVGID_00000144339513311104042950000009237113434168231829_">
						<use xlink:href="#SVGID_00000074442184456828627410000011668707295348863634_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000144339513311104042950000009237113434168231829_);enable-background:new    ;">
						<path class="st473" d="M576.8,663.7L576.8,663.7c0.1,0,0.1,0,0.2,0h0C577,663.7,576.9,663.7,576.8,663.7"/>
						<path class="st472" d="M577.1,663.7L577.1,663.7c0.3,0,0.6,0,0.8,0.1h0C577.6,663.7,577.3,663.7,577.1,663.7"/>
						<path class="st471" d="M577.9,663.7L577.9,663.7c0.3,0,0.6,0.1,0.8,0.2h0C578.4,663.9,578.2,663.8,577.9,663.7"/>
						<path class="st470" d="M578.7,664L578.7,664c0.3,0.1,0.6,0.3,1,0.4h0C579.4,664.2,579,664.1,578.7,664"/>
						<path class="st469" d="M579.7,664.4L579.7,664.4c0.5,0.3,1,0.6,1.5,1.1h0C580.7,665,580.2,664.7,579.7,664.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000051358055987730947510000000751309372247095428_" class="st1" d="M582.4,863.5L582.4,863.5
							c-0.1,0.1-3.1,2.6-6.9,2.6h0C579.2,866.1,582.2,863.6,582.4,863.5z"/>
					</defs>
					<clipPath id="SVGID_00000145767694193744078940000007435900824927718843_">
						<use xlink:href="#SVGID_00000051358055987730947510000000751309372247095428_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000145767694193744078940000007435900824927718843_);enable-background:new    ;">
						<path class="st475" d="M582.4,863.5L582.4,863.5c-0.1,0.1-3.1,2.6-6.9,2.6h0C579.2,866.1,582.2,863.6,582.4,863.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000101069600914891027330000011320870916711739801_" class="st1" d="M562.1,677.7l-1.4-1.4
							c3.5-3.6,8.9-9,9.1-9.3c0.2-0.2,3.3-3.3,7-3.3c1.4,0,2.9,0.5,4.3,1.9l-1.4,1.4c-1-1-1.9-1.3-2.9-1.3c-2.8,0-5.5,2.6-5.6,2.7
							C571,668.7,565.6,674.1,562.1,677.7z"/>
					</defs>
					<clipPath id="SVGID_00000093143873621729928430000011484477248714362045_">
						<use xlink:href="#SVGID_00000101069600914891027330000011320870916711739801_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000093143873621729928430000011484477248714362045_);enable-background:new    ;">
						<path class="st453" d="M562.1,677.7l-1.4-1.4c3.5-3.6,8.9-9,9.1-9.3c0,0,0.1-0.1,0.2-0.2l1.4,1.4c-0.1,0.1-0.2,0.2-0.2,0.2
							C571,668.7,565.6,674.1,562.1,677.7"/>
						<path class="st452" d="M571.4,668.2l-1.4-1.4c0.3-0.3,1-0.9,1.9-1.5l1,1.7C572.3,667.5,571.7,668,571.4,668.2"/>
						<path class="st451" d="M573,667l-1-1.7c0.5-0.3,1.1-0.6,1.7-0.9l0.7,1.9C573.9,666.5,573.4,666.7,573,667"/>
						<path class="st450" d="M574.4,666.2l-0.7-1.9c0.4-0.2,0.9-0.4,1.4-0.5l0.4,1.9C575.1,665.9,574.7,666.1,574.4,666.2"/>
						<path class="st449" d="M575.5,665.8l-0.4-1.9c0.4-0.1,0.7-0.2,1.1-0.2l0.2,2C576,665.7,575.7,665.8,575.5,665.8"/>
						<path class="st448" d="M576.3,665.7l-0.2-2c0.2,0,0.5,0,0.7,0c0.1,0,0.3,0,0.4,0l-0.1,2c-0.1,0-0.2,0-0.3,0
							C576.7,665.7,576.5,665.7,576.3,665.7"/>
						<path class="st447" d="M577.1,665.7l0.1-2c0.3,0,0.7,0.1,1,0.2l-0.5,1.9C577.5,665.7,577.3,665.7,577.1,665.7"/>
						<path class="st446" d="M577.8,665.8l0.5-1.9c0.4,0.1,0.8,0.2,1.2,0.4l-0.9,1.8C578.3,665.9,578,665.8,577.8,665.8"/>
						<path class="st445" d="M578.6,666.1l0.9-1.8c0.6,0.3,1.2,0.7,1.7,1.2l-1.4,1.4C579.3,666.5,578.9,666.3,578.6,666.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000035499797163192104520000001695321585030507442_" class="st1" d="M581,862.1c0.1,0.1,0.3,0.3,0.4,0.4l1,1
							c-0.2,0.1-3.2,2.6-7,2.6c-1.9,0-3.9-0.6-5.8-2.5l1.4-1.4c1.5,1.4,3,1.9,4.4,1.9C578.5,864.1,581,862.1,581,862.1z"/>
					</defs>
					<clipPath id="SVGID_00000075124803679311100400000000534081233522760872_">
						<use xlink:href="#SVGID_00000035499797163192104520000001695321585030507442_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000075124803679311100400000000534081233522760872_);enable-background:new    ;">
						<path class="st452" d="M581,862.1l1.4,1.4c0,0-0.2,0.2-0.5,0.4l-1.2-1.6C580.8,862.2,581,862.1,581,862.1"/>
						<path class="st453" d="M580.6,862.3l1.2,1.6c-0.5,0.3-1.3,0.8-2.3,1.2l-0.8-1.8C579.6,863,580.2,862.6,580.6,862.3"/>
						<path class="st454" d="M578.8,863.3l0.8,1.8c-0.7,0.3-1.6,0.6-2.5,0.8l-0.3-2C577.5,863.8,578.2,863.6,578.8,863.3"/>
						<path class="st455" d="M576.8,863.9l0.3,2c-0.5,0.1-1.1,0.2-1.7,0.2c-0.3,0-0.6,0-0.9,0l0.2-2c0.2,0,0.5,0,0.7,0
							C575.9,864.1,576.4,864,576.8,863.9"/>
						<path class="st456" d="M574.8,864l-0.2,2c-1.2-0.1-2.5-0.6-3.7-1.5l1.1-1.6C572.9,863.6,573.8,863.9,574.8,864"/>
						<path class="st457" d="M572,862.9l-1.1,1.6c-0.4-0.3-0.8-0.6-1.1-1l1.4-1.4C571.4,862.4,571.7,862.7,572,862.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000118366649595217076720000012681510416177542077_" class="st1" d="M581.4,862.5l-0.4-0.4c0,0,0,0,0,0
							C581.1,862.2,581.2,862.4,581.4,862.5z"/>
					</defs>
					<clipPath id="SVGID_00000174574515215422603130000010255837827248690823_">
						<use xlink:href="#SVGID_00000118366649595217076720000012681510416177542077_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000174574515215422603130000010255837827248690823_);enable-background:new    ;">
						<polyline class="st452" points="581,862.1 581.4,862.5 581.4,862.5 581,862.1 581,862.1 						"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000141417874924922616210000018444576981854977980_" class="st1" d="M591.1,813.5L591.1,813.5
							c-0.4,2.7-0.4,6.3,1.6,8.2h0C590.7,819.7,590.7,816.2,591.1,813.5z"/>
					</defs>
					<clipPath id="SVGID_00000119797434673920314760000015190045891066484370_">
						<use xlink:href="#SVGID_00000141417874924922616210000018444576981854977980_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000119797434673920314760000015190045891066484370_);enable-background:new    ;">
						<path class="st472" d="M591.1,813.5L591.1,813.5c-0.1,0.7-0.2,1.5-0.2,2.3h0C590.9,815,591,814.2,591.1,813.5"/>
						<path class="st471" d="M590.9,815.7L590.9,815.7c0,0.7,0,1.4,0.1,2h0C590.9,817.1,590.9,816.4,590.9,815.7"/>
						<path class="st470" d="M591,817.8L591,817.8c0.1,0.6,0.2,1.1,0.4,1.6h0C591.2,818.9,591.1,818.3,591,817.8"/>
						<path class="st469" d="M591.4,819.4L591.4,819.4c0.3,0.9,0.7,1.7,1.4,2.3h0C592.1,821.1,591.6,820.3,591.4,819.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000087392818711239019170000018008524971252173445_" class="st1" d="M594.1,707.9L594.1,707.9
							c-0.1,0.1-3.1,2.8-3.6,6.9h0C591,710.6,594,708,594.1,707.9z"/>
					</defs>
					<clipPath id="SVGID_00000002367794010701098270000003945480272963694241_">
						<use xlink:href="#SVGID_00000087392818711239019170000018008524971252173445_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000002367794010701098270000003945480272963694241_);enable-background:new    ;">
						<path class="st475" d="M594.1,707.9L594.1,707.9c-0.1,0.1-1.3,1.2-2.4,3.1h0C592.8,709.1,594,707.9,594.1,707.9"/>
						<path class="st474" d="M591.8,711L591.8,711c-0.3,0.6-0.6,1.2-0.8,1.8h0C591.2,712.1,591.5,711.5,591.8,711"/>
						<path class="st473" d="M590.9,712.8L590.9,712.8c-0.1,0.5-0.3,1-0.4,1.5h0C590.7,713.8,590.8,713.3,590.9,712.8"/>
						<path class="st472" d="M590.6,714.3L590.6,714.3c0,0.1,0,0.3-0.1,0.4h0C590.5,714.6,590.5,714.5,590.6,714.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000044893389225942902980000003170586832304635546_" class="st1" d="M589.3,812.5l1.8,1
							c-0.4,2.7-0.4,6.3,1.6,8.2l-1.4,1.4C588.6,820.5,588.7,816.2,589.3,812.5z"/>
					</defs>
					<clipPath id="SVGID_00000083778715687436114950000005327979066743699352_">
						<use xlink:href="#SVGID_00000044893389225942902980000003170586832304635546_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000083778715687436114950000005327979066743699352_);enable-background:new    ;">
						<path class="st447" d="M589.3,812.5l1.8,1c-0.1,0.8-0.2,1.8-0.2,2.7L589,816C589,814.8,589.1,813.6,589.3,812.5"/>
						<path class="st446" d="M589,816l1.9,0.1c0,0.8,0.1,1.7,0.2,2.5l-1.9,0.5C589,818.1,589,817.1,589,816"/>
						<path class="st445" d="M589.3,819.1l1.9-0.5c0.3,1.2,0.7,2.3,1.6,3.1l-1.4,1.4C590.2,822,589.6,820.6,589.3,819.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000023276457437631726300000018025241624482735754_" class="st1" d="M590.5,714.8L590.5,714.8
							c4.4,7.5,7.7,15.4,9.9,23.6h0C598.2,730.2,594.9,722.2,590.5,714.8z"/>
					</defs>
					<clipPath id="SVGID_00000096758113099513138500000008992924660581480110_">
						<use xlink:href="#SVGID_00000023276457437631726300000018025241624482735754_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000096758113099513138500000008992924660581480110_);enable-background:new    ;">
						<path class="st469" d="M590.5,714.8L590.5,714.8c3.7,6.3,6.6,12.9,8.8,19.7h0C597.1,727.7,594.2,721.1,590.5,714.8"/>
						<path class="st470" d="M599.3,734.5L599.3,734.5c0.4,1.3,0.8,2.6,1.2,3.9h0C600,737,599.7,735.8,599.3,734.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000052824460908404966770000007606651610193314451_" class="st1" d="M600.4,790.9L600.4,790.9
							c-2.1,7.8-5.2,15.4-9.3,22.6h0C595.2,806.3,598.3,798.7,600.4,790.9z"/>
					</defs>
					<clipPath id="SVGID_00000162318810680028066920000000191007468091532191_">
						<use xlink:href="#SVGID_00000052824460908404966770000007606651610193314451_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000162318810680028066920000000191007468091532191_);enable-background:new    ;">
						<path class="st473" d="M600.4,790.9L600.4,790.9c-0.4,1.7-1,3.4-1.5,5h0C599.5,794.2,600,792.6,600.4,790.9"/>
						<path class="st474" d="M598.9,795.9L598.9,795.9c-1.8,5.5-4.1,10.9-7,16.1h0C594.7,806.8,597.1,801.4,598.9,795.9"/>
						<path class="st475" d="M591.9,812L591.9,812c-0.3,0.5-0.5,1-0.8,1.5h0C591.4,813,591.7,812.5,591.9,812"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000157987045884979742060000010624564856561607602_" class="st1" d="M592.7,706.5l1.4,1.4
							c-0.2,0.1-3.1,2.8-3.6,6.9l-2.1,0.4C589.1,709.6,592.6,706.6,592.7,706.5z"/>
					</defs>
					<clipPath id="SVGID_00000180354662163825352470000017328974027799711360_">
						<use xlink:href="#SVGID_00000157987045884979742060000010624564856561607602_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000180354662163825352470000017328974027799711360_);enable-background:new    ;">
						<path class="st452" d="M592.7,706.5l1.4,1.4c0,0-0.1,0.1-0.2,0.1l-1.4-1.4C592.6,706.5,592.7,706.5,592.7,706.5"/>
						<path class="st451" d="M592.5,706.6l1.4,1.4c-0.2,0.2-0.7,0.7-1.2,1.3l-1.6-1.2C591.8,707.4,592.3,706.9,592.5,706.6"/>
						<path class="st450" d="M591.2,708.2l1.6,1.2c-0.3,0.4-0.7,1-1,1.6l-1.8-0.8C590.4,709.3,590.8,708.7,591.2,708.2"/>
						<path class="st449" d="M590,710.1l1.8,0.8c-0.3,0.5-0.5,1-0.7,1.6l-1.9-0.3C589.4,711.4,589.7,710.7,590,710.1"/>
						<path class="st448" d="M589.1,712.2l1.9,0.3c-0.2,0.6-0.4,1.2-0.5,1.8l-2,0.3C588.7,713.7,588.9,712.9,589.1,712.2"/>
						<path class="st447" d="M588.5,714.5l2-0.3c0,0.2-0.1,0.3-0.1,0.5l-2.1,0.4C588.5,715,588.5,714.7,588.5,714.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000004509626245344652890000012859873769298033848_" class="st1" d="M592.7,821.7L592.7,821.7
							c3.6,3.5,9,8.9,9.3,9.1h0C601.7,830.6,596.3,825.2,592.7,821.7z"/>
					</defs>
					<clipPath id="SVGID_00000166663913998603493380000016912837783299670923_">
						<use xlink:href="#SVGID_00000004509626245344652890000012859873769298033848_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000166663913998603493380000016912837783299670923_);enable-background:new    ;">
						<path class="st469" d="M592.7,821.7L592.7,821.7c3.6,3.5,9,8.9,9.3,9.1h0C601.7,830.6,596.3,825.2,592.7,821.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000079485231823754068050000001854283106763311286_" class="st1" d="M561.6,823.3l-1.4-1.4
							c30.6-31.1,30.3-81.1-0.7-111.7c-15.4-15.2-35.5-22.8-55.5-22.8c-20.4,0-40.8,7.8-56.2,23.5c-30.6,31-30.3,81.1,0.7,111.7
							c15.4,15.2,35.4,22.8,55.5,22.8c20.4,0,40.8-7.8,56.2-23.5L561.6,823.3c-15.8,16-36.7,24.1-57.6,24.1
							c-20.6,0-41.1-7.8-56.9-23.4c-31.8-31.4-32.2-82.7-0.7-114.5c15.8-16,36.7-24.1,57.6-24.1c20.6,0,41.1,7.8,56.9,23.4
							C592.7,740.2,593.1,791.5,561.6,823.3z"/>
					</defs>
					<clipPath id="SVGID_00000074423837024016228150000018085075336480675213_">
						<use xlink:href="#SVGID_00000079485231823754068050000001854283106763311286_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000074423837024016228150000018085075336480675213_);enable-background:new    ;">
						<path class="st453" d="M561.6,823.3l-1.4-1.4c2.9-3,5.6-6.1,8-9.4l1.6,1.2C567.4,817,564.6,820.3,561.6,823.3"/>
						<path class="st454" d="M569.8,813.7l-1.6-1.2c3.5-4.9,6.4-10.1,8.7-15.5l1.8,0.8C576.4,803.3,573.4,808.7,569.8,813.7"/>
						<path class="st455" d="M578.7,797.8l-1.8-0.8c3-7.1,4.9-14.4,5.7-21.9l2,0.2C583.7,783,581.7,790.6,578.7,797.8"/>
						<path class="st456" d="M584.5,775.3l-2-0.2c1.5-13.3-0.4-26.8-5.7-39.3l1.8-0.8C584,747.8,586,761.7,584.5,775.3"/>
						<path class="st457" d="M578.7,735l-1.8,0.8c-3.9-9.3-9.7-18-17.3-25.6c-3.1-3-6.3-5.7-9.7-8.2l1.2-1.6
							c3.5,2.5,6.8,5.3,9.9,8.4C568.7,716.5,574.7,725.4,578.7,735"/>
						<path class="st456" d="M551,700.4l-1.2,1.6c-11.2-8-24.1-12.7-37.2-14.2l0.2-2C526.3,687.4,539.5,692.2,551,700.4"/>
						<path class="st455" d="M512.8,685.9l-0.2,2c-2.9-0.3-5.7-0.5-8.6-0.5c-4.7,0-9.4,0.4-14,1.2l-0.4-2c4.7-0.9,9.6-1.3,14.4-1.3
							C506.9,685.4,509.9,685.6,512.8,685.9"/>
						<path class="st454" d="M489.6,686.7l0.4,2c-5.8,1-11.5,2.7-17,5.1l-0.8-1.8C477.9,689.5,483.7,687.8,489.6,686.7"/>
						<path class="st453" d="M472.2,691.9l0.8,1.8c-4.6,2-9.1,4.4-13.3,7.3l-1.1-1.7C462.9,696.4,467.5,693.9,472.2,691.9"/>
						<path class="st452" d="M458.6,699.3l1.1,1.7c-3.7,2.5-7.3,5.4-10.6,8.6l-1.4-1.4C451.2,704.8,454.8,701.9,458.6,699.3"/>
						<path class="st451" d="M447.7,708.1l1.4,1.4c-0.5,0.4-0.9,0.9-1.3,1.3c-2.7,2.7-5.1,5.6-7.3,8.5l-1.6-1.2c2.3-3,4.8-6,7.5-8.8
							C446.8,709,447.3,708.6,447.7,708.1"/>
						<path class="st450" d="M438.9,718.3l1.6,1.2c-2.5,3.3-4.7,6.8-6.5,10.4l-1.8-0.9C434.1,725.3,436.3,721.7,438.9,718.3"/>
						<path class="st449" d="M432.2,729l1.8,0.9c-1.9,3.6-3.5,7.4-4.8,11.2l-1.9-0.6C428.6,736.5,430.2,732.7,432.2,729"/>
						<path class="st448" d="M427.3,740.4l1.9,0.6c-1.4,4.1-2.4,8.3-3.1,12.5l-2-0.3C424.8,748.9,425.9,744.6,427.3,740.4"/>
						<path class="st447" d="M424.1,753.2l2,0.3c-0.8,4.7-1.1,9.4-1,14.1l-2,0C422.9,762.9,423.3,758,424.1,753.2"/>
						<path class="st446" d="M423,767.7l2,0c0.1,5.8,0.8,11.6,2.2,17.2l-1.9,0.5C423.9,779.6,423.1,773.7,423,767.7"/>
						<path class="st445" d="M425.2,785.4l1.9-0.5c3.3,13.9,10.4,27,21.3,37.7c6.1,6.1,13,10.9,20.4,14.6L468,839
							c-7.5-3.7-14.6-8.7-20.9-14.9C435.9,813,428.7,799.6,425.2,785.4"/>
						<path class="st446" d="M468,839l0.9-1.8c5.2,2.6,10.5,4.5,16,5.9l-0.5,1.9C478.8,843.6,473.2,841.6,468,839"/>
						<path class="st447" d="M484.4,845l0.5-1.9c4.5,1.1,9.2,1.9,13.8,2.2l-0.1,2C493.8,846.9,489,846.2,484.4,845"/>
						<path class="st448" d="M498.6,847.2l0.1-2c1.8,0.1,3.5,0.2,5.3,0.2c2.6,0,5.2-0.1,7.8-0.4l0.2,2c-2.6,0.3-5.3,0.4-8,0.4
							C502.2,847.4,500.4,847.4,498.6,847.2"/>
						<path class="st449" d="M512,847l-0.2-2c4.1-0.4,8.2-1.1,12.2-2.2l0.5,1.9C520.4,845.9,516.2,846.6,512,847"/>
						<path class="st450" d="M524.5,844.8l-0.5-1.9c4-1,7.9-2.4,11.8-4.1l0.8,1.8C532.7,842.3,528.6,843.7,524.5,844.8"/>
						<path class="st451" d="M536.6,840.6l-0.8-1.8c3.9-1.7,7.7-3.8,11.3-6.1l1.1,1.7C544.5,836.7,540.6,838.8,536.6,840.6"/>
						<path class="st452" d="M548.2,834.3l-1.1-1.7c3.8-2.5,7.4-5.3,10.8-8.5l1.4,1.5C555.8,828.9,552.1,831.8,548.2,834.3"/>
						<path class="st453" d="M559.3,825.6l-1.4-1.5c0.8-0.7,1.6-1.5,2.3-2.3l1.4,1.4C560.8,824.1,560.1,824.9,559.3,825.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000001635861571813696170000015321818249389209519_" class="st1" d="M581.1,665.5L581.1,665.5
							c5,4.9,20.5,20.3,21.4,21.1h0C601.7,685.8,586.1,670.4,581.1,665.5z"/>
					</defs>
					<clipPath id="SVGID_00000000207822684083964570000007297321020373301650_">
						<use xlink:href="#SVGID_00000001635861571813696170000015321818249389209519_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000000207822684083964570000007297321020373301650_);enable-background:new    ;">
						<path class="st469" d="M581.1,665.5L581.1,665.5c5,4.9,20.5,20.3,21.4,21.1h0C601.7,685.8,586.1,670.4,581.1,665.5"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000013871742549725307830000001142928365181504152_" class="st1" d="M602.6,699.3L602.6,699.3
							c-5.6,5.7-8.3,8.4-8.4,8.5h0C594.3,707.7,596.9,705,602.6,699.3z"/>
					</defs>
					<clipPath id="SVGID_00000098190863809922317030000015230487402185622435_">
						<use xlink:href="#SVGID_00000013871742549725307830000001142928365181504152_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000098190863809922317030000015230487402185622435_);enable-background:new    ;">
						<path class="st475" d="M602.6,699.3L602.6,699.3c-5.6,5.7-8.3,8.4-8.4,8.5h0C594.3,707.7,596.9,705,602.6,699.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000088132132525472744210000016987435405912610221_" class="st1" d="M603.5,842.2L603.5,842.2
							c-4.9,5-20.3,20.5-21.1,21.3h0C583.2,862.7,598.5,847.1,603.5,842.2z"/>
					</defs>
					<clipPath id="SVGID_00000103267279848712992010000003349989201015649207_">
						<use xlink:href="#SVGID_00000088132132525472744210000016987435405912610221_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000103267279848712992010000003349989201015649207_);enable-background:new    ;">
						<path class="st475" d="M603.5,842.2L603.5,842.2c-4.9,5-20.3,20.5-21.1,21.3h0C583.2,862.7,598.5,847.1,603.5,842.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000180339614813724770460000014692811685659627904_" class="st1" d="M600.4,738.3L600.4,738.3
							c1.6,2,3.9,4,6.4,4h0C604.3,742.4,602,740.3,600.4,738.3z"/>
					</defs>
					<clipPath id="SVGID_00000015325521448045585430000000519424022639432635_">
						<use xlink:href="#SVGID_00000180339614813724770460000014692811685659627904_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000015325521448045585430000000519424022639432635_);enable-background:new    ;">
						<path class="st469" d="M600.4,738.3L600.4,738.3c1,1.2,2.2,2.4,3.6,3.2h0C602.6,740.8,601.4,739.6,600.4,738.3"/>
						<path class="st470" d="M604,741.5L604,741.5c0.4,0.2,0.8,0.4,1.1,0.5h0C604.7,741.9,604.4,741.7,604,741.5"/>
						<path class="st471" d="M605.1,742.1L605.1,742.1c0.3,0.1,0.6,0.2,0.8,0.2h0C605.7,742.2,605.4,742.2,605.1,742.1"/>
						<path class="st472" d="M605.9,742.3L605.9,742.3c0.2,0,0.5,0.1,0.7,0.1h0C606.4,742.3,606.2,742.3,605.9,742.3"/>
						<path class="st473" d="M606.6,742.4L606.6,742.4c0.1,0,0.1,0,0.2,0h0C606.8,742.4,606.7,742.4,606.6,742.4"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000180348031436360113860000009701640682005606533_" class="st1" d="M588.4,715.2l2.1-0.4
							c4.4,7.5,7.7,15.4,9.9,23.6l-2,0.5C596.3,730.9,592.8,723,588.4,715.2z"/>
					</defs>
					<clipPath id="SVGID_00000160898080320567044730000006804096202572203439_">
						<use xlink:href="#SVGID_00000180348031436360113860000009701640682005606533_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000160898080320567044730000006804096202572203439_);enable-background:new    ;">
						<path class="st445" d="M588.4,715.2l2.1-0.4c4.2,7.3,7.5,15,9.7,23l-2,0.6C596.1,730.5,592.7,722.8,588.4,715.2"/>
						<path class="st446" d="M598.3,738.3l2-0.6c0.1,0.2,0.1,0.4,0.2,0.6l-2,0.5C598.4,738.7,598.3,738.5,598.3,738.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000088134257074368148350000012267330662480666017_" class="st1" d="M598.7,789.7l1.8,1.1
							c-2.1,7.8-5.2,15.4-9.3,22.6l-1.8-1C593.1,805.5,596.4,798,598.7,789.7z"/>
					</defs>
					<clipPath id="SVGID_00000108310602357339161790000018341134226910155957_">
						<use xlink:href="#SVGID_00000088134257074368148350000012267330662480666017_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000108310602357339161790000018341134226910155957_);enable-background:new    ;">
						<path class="st448" d="M598.7,789.7l1.8,1.1c-0.4,1.6-0.9,3.2-1.4,4.7l-1.8-0.9C597.7,793.1,598.2,791.4,598.7,789.7"/>
						<path class="st449" d="M597.2,794.7l1.8,0.9c-1.6,5-3.6,9.8-6.1,14.5l-1.9-0.9C593.4,804.6,595.5,799.8,597.2,794.7"/>
						<path class="st450" d="M591.1,809.2l1.9,0.9c-0.6,1.1-1.2,2.3-1.8,3.4l-1.8-1C589.9,811.4,590.5,810.3,591.1,809.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000030469355077290976380000012902793450773279114_" class="st1" d="M608.1,788.3L608.1,788.3
							c-1.3,0-4.9,0.2-7.6,2.5l0,0C603.2,788.5,606.7,788.3,608.1,788.3z"/>
					</defs>
					<clipPath id="SVGID_00000124863761653802630420000007207752423385937295_">
						<use xlink:href="#SVGID_00000030469355077290976380000012902793450773279114_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000124863761653802630420000007207752423385937295_);enable-background:new    ;">
						<path class="st475" d="M608.1,788.3L608.1,788.3c-1.3,0-4.9,0.2-7.6,2.5h0C603.2,788.5,606.7,788.3,608.1,788.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000037671845444773757660000004969635733120011939_" class="st1" d="M602.5,686.6L602.5,686.6
							c0.3,0.4,5.7,7,0.1,12.7h0C608.2,693.6,602.8,686.9,602.5,686.6z"/>
					</defs>
					<clipPath id="SVGID_00000126286884784471873460000008455847319534177206_">
						<use xlink:href="#SVGID_00000037671845444773757660000004969635733120011939_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000126286884784471873460000008455847319534177206_);enable-background:new    ;">
						<path class="st469" d="M602.5,686.6L602.5,686.6c0.1,0.2,1.4,1.8,2.2,4h0C603.9,688.3,602.6,686.8,602.5,686.6"/>
						<path class="st470" d="M604.6,690.6L604.6,690.6c0.2,0.6,0.3,1.2,0.4,1.8h0C604.9,691.7,604.8,691.1,604.6,690.6"/>
						<path class="st471" d="M605,692.4L605,692.4c0.1,0.4,0.1,0.9,0.1,1.3h0C605.1,693.2,605.1,692.8,605,692.4"/>
						<path class="st472" d="M605.1,693.7L605.1,693.7c0,0.4,0,0.8-0.1,1.1h0C605.1,694.4,605.1,694,605.1,693.7"/>
						<path class="st473" d="M605,694.8L605,694.8c-0.1,0.4-0.1,0.7-0.3,1.1h0C604.8,695.6,604.9,695.2,605,694.8"/>
						<path class="st474" d="M604.7,695.9L604.7,695.9c-0.1,0.4-0.3,0.9-0.6,1.3h0C604.4,696.8,604.6,696.4,604.7,695.9"/>
						<path class="st475" d="M604.1,697.2L604.1,697.2c-0.4,0.7-0.9,1.4-1.6,2.1h0C603.2,698.6,603.8,697.9,604.1,697.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000155115839261592236700000011721319461495411099_" class="st1" d="M602,830.9L602,830.9
							c0.3,0.3,6.4,6.3,1.5,11.3h0C608.4,837.2,602.3,831.2,602,830.9z"/>
					</defs>
					<clipPath id="SVGID_00000154416614276935897600000011278598619678700477_">
						<use xlink:href="#SVGID_00000155115839261592236700000011721319461495411099_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000154416614276935897600000011278598619678700477_);enable-background:new    ;">
						<path class="st469" d="M602,830.9L602,830.9c0.2,0.2,2.2,2.2,3.1,4.8h0C604.2,833,602.2,831,602,830.9"/>
						<path class="st470" d="M605,835.6L605,835.6c0.2,0.4,0.3,0.9,0.3,1.3h0C605.3,836.5,605.2,836.1,605,835.6"/>
						<path class="st471" d="M605.3,837L605.3,837c0.1,0.3,0.1,0.6,0.1,1h0C605.4,837.6,605.4,837.3,605.3,837"/>
						<path class="st472" d="M605.4,837.9L605.4,837.9c0,0.3,0,0.6-0.1,0.8h0C605.3,838.5,605.4,838.2,605.4,837.9"/>
						<path class="st473" d="M605.3,838.8L605.3,838.8c0,0.3-0.1,0.6-0.2,0.8h0C605.2,839.3,605.2,839,605.3,838.8"/>
						<path class="st474" d="M605.1,839.6L605.1,839.6c-0.1,0.3-0.2,0.6-0.4,1h0C604.8,840.2,605,839.9,605.1,839.6"/>
						<path class="st475" d="M604.7,840.6L604.7,840.6c-0.3,0.5-0.7,1.1-1.2,1.6h0C604,841.6,604.4,841.1,604.7,840.6"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000141413536950187455090000017988203231277946516_" class="st1" d="M579.7,666.9l1.4-1.4
							c5,4.9,20.5,20.3,21.3,21.1l-1.4,1.4C600.3,687.2,584.7,671.9,579.7,666.9z"/>
					</defs>
					<clipPath id="SVGID_00000164486507315493003040000001433388048212119691_">
						<use xlink:href="#SVGID_00000141413536950187455090000017988203231277946516_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000164486507315493003040000001433388048212119691_);enable-background:new    ;">
						<path class="st445" d="M579.7,666.9l1.4-1.4c5,4.9,20.5,20.3,21.3,21.1l-1.4,1.4C600.3,687.2,584.7,671.9,579.7,666.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000053513997018779802910000003780946019610755222_" class="st1" d="M601.1,697.9l1.4,1.4
							c-5.6,5.7-8.3,8.4-8.4,8.5l-1.4-1.4C592.8,706.3,595.5,703.6,601.1,697.9z"/>
					</defs>
					<clipPath id="SVGID_00000103249344122901869540000016258102262411463814_">
						<use xlink:href="#SVGID_00000053513997018779802910000003780946019610755222_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000103249344122901869540000016258102262411463814_);enable-background:new    ;">
						<path class="st451" d="M601.1,697.9l1.4,1.4c-5.6,5.7-8.3,8.4-8.4,8.5l-1.4-1.4C592.8,706.3,595.5,703.6,601.1,697.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000017491502204531884570000014893864347103171248_" class="st1" d="M602,840.7l1.4,1.4
							c-4.9,5-20.3,20.5-21.1,21.3l-1.4-1.4C581.8,861.3,597.1,845.7,602,840.7z"/>
					</defs>
					<clipPath id="SVGID_00000111902560542601204930000013992021710217411739_">
						<use xlink:href="#SVGID_00000017491502204531884570000014893864347103171248_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000111902560542601204930000013992021710217411739_);enable-background:new    ;">
						<path class="st451" d="M602,840.7l1.4,1.4c-4.9,5-20.3,20.5-21.1,21.3l-1.4-1.4C581.8,861.3,597.1,845.7,602,840.7"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000182508225898211375850000018436751395850701241_" class="st1" d="M601.1,688l1.4-1.4
							c0.3,0.4,5.7,7,0.1,12.7l-1.4-1.4C605.5,693.6,601.1,688.1,601.1,688z"/>
					</defs>
					<clipPath id="SVGID_00000124139972819952449990000011197706978108825219_">
						<use xlink:href="#SVGID_00000182508225898211375850000018436751395850701241_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000124139972819952449990000011197706978108825219_);enable-background:new    ;">
						<path class="st445" d="M601.1,688l1.4-1.4c0.1,0.2,1.7,2.1,2.3,4.6l-2,0.4C602.3,689.6,601.1,688.1,601.1,688"/>
						<path class="st446" d="M602.8,691.6l2-0.4c0.2,0.6,0.3,1.3,0.3,2.1l-2-0.1C603.1,692.7,603,692.1,602.8,691.6"/>
						<path class="st447" d="M603.1,693.2l2,0.1c0,0.5,0,0.9-0.1,1.4l-2-0.4C603.1,694,603.1,693.6,603.1,693.2"/>
						<path class="st448" d="M603,694.4l2,0.4c-0.1,0.4-0.2,0.8-0.3,1.2l-1.9-0.6C602.9,695,603,694.7,603,694.4"/>
						<path class="st449" d="M602.8,695.3l1.9,0.6c-0.1,0.4-0.3,0.8-0.5,1.1l-1.8-0.9C602.6,695.9,602.7,695.6,602.8,695.3"/>
						<path class="st450" d="M602.4,696.2l1.8,0.9c-0.2,0.4-0.4,0.8-0.7,1.2l-1.6-1.1C602.1,696.8,602.3,696.5,602.4,696.2"/>
						<path class="st451" d="M601.9,697.1l1.6,1.1c-0.3,0.4-0.6,0.7-0.9,1.1l-1.4-1.4C601.4,697.7,601.6,697.4,601.9,697.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000146464701952355033130000000851269325806573464_" class="st1" d="M591.3,823.1l1.4-1.4
							c3.6,3.5,9,8.9,9.3,9.1c0.3,0.3,6.4,6.3,1.5,11.3l-1.4-1.4c3.5-3.4-1.2-8.2-1.5-8.5C600.3,832,594.9,826.6,591.3,823.1z"/>
					</defs>
					<clipPath id="SVGID_00000055665339576837312480000007034653566542389162_">
						<use xlink:href="#SVGID_00000146464701952355033130000000851269325806573464_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000055665339576837312480000007034653566542389162_);enable-background:new    ;">
						<path class="st445" d="M591.3,823.1l1.4-1.4c3.6,3.5,9,8.9,9.3,9.1c0.2,0.2,2.5,2.4,3.2,5.3l-2,0.3c-0.6-2.1-2.4-3.9-2.5-4.1
							C600.3,832,594.9,826.6,591.3,823.1"/>
						<path class="st446" d="M603.1,836.4l2-0.3c0.1,0.5,0.2,1,0.2,1.6l-2-0.1C603.3,837.1,603.2,836.8,603.1,836.4"/>
						<path class="st447" d="M603.3,837.5l2,0.1c0,0.4,0,0.7-0.1,1.1l-2-0.4C603.3,838.1,603.3,837.8,603.3,837.5"/>
						<path class="st448" d="M603.3,838.3l2,0.4c0,0.3-0.1,0.6-0.2,0.9l-1.9-0.7C603.2,838.8,603.3,838.6,603.3,838.3"/>
						<path class="st449" d="M603.2,839l1.9,0.7c-0.1,0.3-0.2,0.6-0.4,0.8l-1.8-0.9C603,839.4,603.1,839.2,603.2,839"/>
						<path class="st450" d="M602.9,839.6l1.8,0.9c-0.1,0.3-0.3,0.6-0.5,0.8l-1.6-1.1C602.7,840,602.8,839.8,602.9,839.6"/>
						<path class="st451" d="M602.6,840.2l1.6,1.1c-0.2,0.3-0.4,0.6-0.7,0.8l-1.4-1.4C602.2,840.6,602.4,840.4,602.6,840.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000033344054632046265650000002126577845902359481_" class="st1" d="M620.1,788.3L620.1,788.3
							c-8,0.1-11.8,0.1-12,0.1h0C608.3,788.3,612.1,788.3,620.1,788.3z"/>
					</defs>
					<clipPath id="SVGID_00000029765104679154352630000012184720532188099002_">
						<use xlink:href="#SVGID_00000033344054632046265650000002126577845902359481_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000029765104679154352630000012184720532188099002_);enable-background:new    ;">
						<path class="st475" d="M620.1,788.3L620.1,788.3c-8,0.1-11.8,0.1-12,0.1h0C608.3,788.3,612.1,788.3,620.1,788.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000098909555473002733860000010388310201267913907_" class="st1" d="M619.8,742.3L619.8,742.3
							c0.3,0,9,0.1,9.1,6.9h0C628.8,742.3,620.1,742.3,619.8,742.3z"/>
					</defs>
					<clipPath id="SVGID_00000183233529901049172740000005086850507558556077_">
						<use xlink:href="#SVGID_00000098909555473002733860000010388310201267913907_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000183233529901049172740000005086850507558556077_);enable-background:new    ;">
						<path class="st473" d="M619.8,742.3L619.8,742.3c0,0,0.1,0,0.2,0h0C619.9,742.3,619.9,742.3,619.8,742.3"/>
						<path class="st472" d="M620,742.3L620,742.3c0.4,0,1.1,0.1,2,0.2h0C621.1,742.3,620.4,742.3,620,742.3"/>
						<path class="st471" d="M622.1,742.5L622.1,742.5c0.6,0.1,1.3,0.3,1.9,0.5h0C623.3,742.8,622.6,742.6,622.1,742.5"/>
						<path class="st470" d="M624,743L624,743c0.6,0.2,1.1,0.4,1.6,0.7h0C625.1,743.4,624.5,743.2,624,743"/>
						<path class="st469" d="M625.6,743.7L625.6,743.7c1.3,0.7,2.4,1.8,3,3.5h0C628,745.5,626.9,744.4,625.6,743.7"/>
						<path class="st470" d="M628.6,747.2L628.6,747.2c0.1,0.4,0.2,0.7,0.3,1.1h0C628.8,747.9,628.7,747.5,628.6,747.2"/>
						<path class="st471" d="M628.8,748.3L628.8,748.3c0,0.3,0.1,0.6,0.1,0.9h0C628.9,748.9,628.9,748.6,628.8,748.3"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000147931324522394480700000016879738574537210805_" class="st1" d="M628.9,749.2L628.9,749.2
							c0.1,7,0.2,28.8,0.2,30h0C629.1,778.1,628.9,756.2,628.9,749.2z"/>
					</defs>
					<clipPath id="SVGID_00000060711765608159665230000015225464586247321268_">
						<use xlink:href="#SVGID_00000147931324522394480700000016879738574537210805_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000060711765608159665230000015225464586247321268_);enable-background:new    ;">
						<path class="st471" d="M628.9,749.2L628.9,749.2c0.1,7,0.2,28.8,0.2,30h0C629.1,778.1,628.9,756.2,628.9,749.2"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000083780366335723830850000000039246633341058236_" class="st1" d="M629.1,779.2L629.1,779.2
							c0,0.5-0.9,9-8.9,9.1h0C628.1,788.2,629,779.7,629.1,779.2z"/>
					</defs>
					<clipPath id="SVGID_00000178890961614051811080000014692394843710858892_">
						<use xlink:href="#SVGID_00000083780366335723830850000000039246633341058236_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000178890961614051811080000014692394843710858892_);enable-background:new    ;">
						<path class="st472" d="M629.1,779.2L629.1,779.2c0,0.1,0,0.3-0.1,0.6h0C629,779.5,629.1,779.3,629.1,779.2"/>
						<path class="st473" d="M629,779.8L629,779.8c-0.1,0.5-0.2,1.2-0.5,2h0C628.7,781,628.9,780.3,629,779.8"/>
						<path class="st474" d="M628.5,781.8L628.5,781.8c-0.2,0.6-0.5,1.3-0.8,2h0C628,783.1,628.3,782.4,628.5,781.8"/>
						<path class="st475" d="M627.6,783.8L627.6,783.8c-1.2,2.2-3.4,4.5-7.5,4.5h0C624.2,788.2,626.4,786,627.6,783.8"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000142142570447479730060000010862494129246419345_" class="st1" d="M620.1,786.3l0,2
							c-8,0.1-11.8,0.1-12,0.1c-1.3,0-4.9,0.2-7.7,2.5l-1.8-1.1c3.8-3.2,8-3.4,9.5-3.4C608.3,786.3,612.1,786.3,620.1,786.3z"/>
					</defs>
					<clipPath id="SVGID_00000071523347074546845980000011836897895755173047_">
						<use xlink:href="#SVGID_00000142142570447479730060000010862494129246419345_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000071523347074546845980000011836897895755173047_);enable-background:new    ;">
						<path class="st455" d="M620.1,786.3l0,2c-8,0.1-11.8,0.1-12,0.1c-0.5,0-1.4,0-2.5,0.2l-0.4-1.9c1.3-0.3,2.3-0.3,2.9-0.3
							C608.3,786.3,612.1,786.3,620.1,786.3"/>
						<path class="st454" d="M605.2,786.6l0.4,1.9c-0.8,0.1-1.8,0.4-2.7,0.8l-1-1.7C603.1,787.1,604.2,786.8,605.2,786.6"/>
						<path class="st453" d="M601.9,787.7l1,1.7c-0.6,0.2-1.2,0.6-1.7,0.9l-1.5-1.3C600.4,788.4,601.2,788,601.9,787.7"/>
						<path class="st452" d="M599.7,788.9l1.5,1.3c-0.3,0.2-0.5,0.4-0.8,0.6l-1.8-1.1C599,789.4,599.3,789.2,599.7,788.9"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000057847697660004539980000013866548282634614922_" class="st1" d="M598.4,738.9l2-0.5c1.6,2,3.9,4,6.4,4
							c5,0,12.6-0.1,13-0.1c0.3,0,9,0.1,9,6.9l-2,0c0.1-4.8-6.9-5-7-5c-0.4,0-8,0.1-13,0.1C603.4,744.3,600.7,741.9,598.4,738.9z"/>
					</defs>
					<clipPath id="SVGID_00000111159744759130613390000016026016907503987861_">
						<use xlink:href="#SVGID_00000057847697660004539980000013866548282634614922_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000111159744759130613390000016026016907503987861_);enable-background:new    ;">
						<path class="st444" d="M598.4,738.9l2-0.5c0.6,0.7,1.2,1.4,1.9,2l-1.4,1.4C600.1,740.9,599.2,739.9,598.4,738.9"/>
						<path class="st445" d="M601,741.7l1.4-1.4c0.6,0.6,1.3,1.1,2.1,1.4l-0.8,1.8C602.7,743.1,601.8,742.5,601,741.7"/>
						<path class="st446" d="M603.7,743.6l0.8-1.8c0.4,0.2,0.8,0.3,1.2,0.4l-0.4,1.9C604.7,744,604.2,743.8,603.7,743.6"/>
						<path class="st447" d="M605.2,744.1l0.4-1.9c0.3,0.1,0.6,0.1,0.9,0.1l-0.1,2C606,744.3,605.6,744.2,605.2,744.1"/>
						<path class="st448" d="M606.4,744.3l0.1-2c0.1,0,0.2,0,0.3,0c5,0,12.6-0.1,13-0.1c0,0,0.2,0,0.5,0l-0.1,2c-0.2,0-0.4,0-0.4,0
							c-0.4,0-8,0.1-13,0.1C606.7,744.4,606.5,744.3,606.4,744.3"/>
						<path class="st447" d="M620.3,744.3l0.1-2c0.6,0,1.6,0.1,2.7,0.4l-0.7,1.9C621.5,744.4,620.7,744.3,620.3,744.3"/>
						<path class="st446" d="M622.4,744.6l0.7-1.9c0.8,0.2,1.6,0.5,2.4,0.9l-1.2,1.7C623.7,745,623,744.8,622.4,744.6"/>
						<path class="st445" d="M624.3,745.3l1.2-1.7c1.4,0.8,2.7,2,3.2,3.9l-1.9,0.5C626.4,746.8,625.4,745.9,624.3,745.3"/>
						<path class="st446" d="M626.8,748.1l1.9-0.5c0.1,0.4,0.2,0.9,0.2,1.5l-2,0.1C626.9,748.8,626.8,748.4,626.8,748.1"/>
						<path class="st447" d="M626.9,749.1l2-0.1c0,0,0,0.1,0,0.1L626.9,749.1C626.9,749.2,626.9,749.2,626.9,749.1"/>
					</g>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000106117597268915351780000002907254897129653380_" class="st1" d="M627.1,779.2l2,0c0,0.5-0.9,9-8.9,9.1
							l0-2C626.3,786.3,627,779.4,627.1,779.2z"/>
					</defs>
					<clipPath id="SVGID_00000069397364745316788440000001123910048307172254_">
						<use xlink:href="#SVGID_00000106117597268915351780000002907254897129653380_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000069397364745316788440000001123910048307172254_);enable-background:new    ;">
						<path class="st447" d="M627.1,779.2l2,0c0,0.1,0,0.2-0.1,0.5l-2-0.2C627,779.4,627.1,779.2,627.1,779.2"/>
						<path class="st448" d="M627,779.6l2,0.2c-0.1,0.5-0.2,1.3-0.5,2.1l-1.9-0.7C626.8,780.5,626.9,779.9,627,779.6"/>
						<path class="st449" d="M626.6,781.2l1.9,0.7c-0.2,0.5-0.4,1.1-0.7,1.7l-1.7-1C626.2,782.1,626.4,781.6,626.6,781.2"/>
						<path class="st450" d="M626,782.6l1.7,1c-0.2,0.5-0.5,0.9-0.9,1.4l-1.5-1.3C625.6,783.3,625.8,783,626,782.6"/>
						<path class="st451" d="M625.3,783.7l1.5,1.3c-0.3,0.4-0.6,0.7-0.9,1.1l-1.3-1.5C624.9,784.3,625.1,784,625.3,783.7"/>
						<path class="st452" d="M624.6,784.5l1.3,1.5c-0.3,0.3-0.7,0.6-1.1,0.9l-1.1-1.7C624.1,785,624.3,784.8,624.6,784.5"/>
						<path class="st453" d="M623.8,785.2l1.1,1.7c-0.4,0.3-0.8,0.5-1.3,0.7l-0.8-1.8C623.1,785.6,623.5,785.4,623.8,785.2"/>
						<path class="st454" d="M622.8,785.8l0.8,1.8c-0.5,0.2-1.1,0.4-1.7,0.5l-0.4-2C621.9,786.1,622.4,785.9,622.8,785.8"/>
						<path class="st455" d="M621.4,786.2l0.4,2c-0.5,0.1-1.1,0.1-1.7,0.2l0-2C620.6,786.3,621,786.2,621.4,786.2"/>
					</g>
				</g>
				<g>
					<path class="st458" d="M626.9,749.2c0,7,0.2,28.8,0.2,30c0,0.1-0.8,7.1-6.9,7c-8,0.1-11.8,0.1-12,0.1c-1.5,0-5.6,0.2-9.5,3.4
						c-2.3,8.3-5.5,15.8-9.3,22.7c-0.6,3.7-0.7,8,2,10.7c3.6,3.5,9,8.9,9.2,9.1c0.3,0.3,5,5.1,1.5,8.5c-4.9,5-20.3,20.5-21.1,21.3
						c0.1-0.1-2.5,2-5.5,2c-1.4,0-2.9-0.5-4.4-1.9c-5.7-5.6-8.4-8.3-8.5-8.4c-0.1-0.2-3.8-4.3-9.9-4.3c-6.9,3.6-13.6,6.5-20.3,8.4
						c-3,1.9-7.6,5.3-7.6,10c0,5,0.1,12.6,0.1,13c0,0.4-0.1,7.1-5,7c-7,0-28.9,0.2-30,0.2c-0.1,0-7.1-0.8-7-6.9
						c-0.1-8-0.1-11.8-0.1-12c0-0.3,0.4-7.2-5.3-11.1c-6.3-1.9-11.9-4.3-28.6-12.8c8.5,5.8,7.7,4.7,4.1,4.7c-2.4,0-5.1,0.5-7,2.4
						c-3.5,3.6-8.8,9-9.1,9.3c-0.1,0.1-2.8,2.8-5.6,2.7c-0.9,0-1.9-0.3-2.9-1.3c-5-4.9-20.5-20.3-21.3-21.1
						c-0.1-0.1-4.4-5.6-0.1-9.9c5.6-5.7,8.3-8.4,8.4-8.5c0.2-0.2,6-5.3,3.8-12.7c-2.8-5.5-5.2-10.9-10.5-27.3c1.6,9.2-1,2.2-7.4,2.1
						c-5,0-12.6,0.1-13,0.1c-0.2,0-7.1-0.2-7-5c0-7-0.2-28.9-0.2-30c0-0.1,0.8-7.1,7-7c8-0.1,11.8-0.1,12-0.1
						c1.8,0,7.8-0.3,11.2-5.4c1.9-6.4,4.3-12.3,7-17.7c0.8-2.6,2.4-9.8-1.4-13.7c-3.6-3.5-9-8.8-9.3-9.1c-0.3-0.3-5-5.1-1.5-8.5
						c4.9-5,20.3-20.5,21.1-21.3c-0.1,0.1,2.5-2,5.5-2c1.4,0,2.9,0.5,4.4,1.9c5.7,5.6,8.4,8.3,8.5,8.4c0.1,0.2,3.8,4.3,10,4.3
						c7.2-3.8,14.1-6.9,21.1-9c3.1-2.1,6.8-5.3,6.8-9.4c0-5-0.1-12.6-0.1-13c0-0.4,0.1-7.1,5-7c7,0,28.8-0.2,30-0.2
						c0.1,0,7.1,0.8,7,7c0.1,8,0.1,11.8,0.1,12c0,0.2-0.3,5.1,3.5,9.5c8.4,2.2,16.1,5.5,23.2,9.3c1.5,0.3,2.2,0.4,3.1,0.3
						c2.4,0,5.1-0.5,7-2.4c3.5-3.6,8.9-9,9.1-9.2c0.1-0.1,2.8-2.8,5.6-2.7c0.9,0,1.9,0.3,2.9,1.3c5,4.9,20.5,20.3,21.3,21.1
						c0.1,0.1,4.4,5.6,0.1,9.9c-5.6,5.7-8.3,8.4-8.4,8.5c-0.1,0.1-3.6,3.2-4.3,8.7c4.4,7.8,7.8,15.7,10,23.7c2.2,3,5,5.4,8.4,5.5
						c5,0,12.6-0.1,13-0.1C620,744.3,627,744.4,626.9,749.2z M561.6,823.3c31.4-31.8,31.1-83.1-0.7-114.6
						c-15.8-15.6-36.4-23.4-56.9-23.4c-20.9,0-41.8,8-57.6,24.1c-31.4,31.8-31.1,83.1,0.7,114.5c15.8,15.6,36.3,23.4,56.9,23.4
						C524.9,847.4,545.8,839.4,561.6,823.3"/>
				</g>
				<g class="st1">
					<defs>
						<path id="SVGID_00000127010983556758933300000003766348519209203641_" class="st1" d="M626.9,749.2l2,0c0,7,0.2,28.8,0.2,30
							l-2,0C627.1,778.1,626.9,756.2,626.9,749.2z"/>
					</defs>
					<clipPath id="SVGID_00000060751526349175881070000010282106682972520865_">
						<use xlink:href="#SVGID_00000127010983556758933300000003766348519209203641_"  style="overflow:visible;"/>
					</clipPath>
					<g style="clip-path:url(#SVGID_00000060751526349175881070000010282106682972520865_);enable-background:new    ;">
						<path class="st447" d="M626.9,749.2l2,0c0,7,0.2,28.8,0.2,30l-2,0C627.1,778.1,626.9,756.2,626.9,749.2"/>
					</g>
				</g>
			</g>
		</g>
	</g>
</g>
<g>
	<g>
		<path class="st792" d="M380.5,499.4c13.7,15.4,21.5,36.2,19.9,58.5c-3.1,43.8-41.1,76.8-84.9,73.7c-21.3-1.5-40.1-11.3-53.4-26
			l-186.4-208C61.8,382,54,361.2,55.6,338.8c3.1-43.8,41.1-76.8,84.9-73.7c21.4,1.5,40.3,11.4,53.6,26.2L380.5,499.4z"/>
	</g>
</g>
<g>
	<g>
		<path class="st792" d="M754.5,499.4c13.7,15.4,21.5,36.2,19.9,58.5c-3.1,43.8-41.1,76.8-84.9,73.7c-21.3-1.5-40.1-11.3-53.4-26
			l-186.4-208c-13.9-15.5-21.7-36.3-20.1-58.7c3.1-43.8,41.1-76.8,84.9-73.7c21.4,1.5,40.3,11.4,53.6,26.2L754.5,499.4z"/>
	</g>
</g>
<g>
	<g>
		<path class="st792" d="M262.5,499.4c-13.7,15.4-21.5,36.2-19.9,58.5c3.1,43.8,41.1,76.8,84.9,73.7c21.3-1.5,40.1-11.3,53.4-26
			l186.4-208c13.9-15.5,21.7-36.3,20.1-58.7c-3.1-43.8-41.1-76.8-84.9-73.7c-21.4,1.5-40.3,11.4-53.6,26.2L262.5,499.4z"/>
	</g>
</g>
      `
      }} />
  )
}


export const AnimationCogsLarge = (props?: AnimationCogsLargeProps): JSX.Element => {
  return (
    <ParentSize
      debounceTime={200}>
      { (sizeProps): JSX.Element => <AnimationSurface {...sizeProps} {...props} /> }
    </ParentSize>
  )
}
