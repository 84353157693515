
import React from 'react'

import { Box, BoxProps, InputAdornment, makeAppStyles, TextField, TextFieldProps } from '@percept/mui'

import { Lock, Person, VpnKey } from '@percept/mui/icons'


export type AuthInputProps = Partial<TextFieldProps> & {
  BoxProps?: BoxProps
}

type AuthInput = React.FC<AuthInputProps>


const useInputStyles = makeAppStyles( theme => ({
  input: {
    ...theme.appComponents.authenticator.input,
  },
}))


const makeAuthInput = (
  displayName: string,
  defaultProps: AuthInputProps,
): AuthInput => {
  const WrappedInput: AuthInput = ({ InputProps = {}, BoxProps = {}, ...props }) => {
    const classes = useInputStyles()
    return (
      <Box
        my={3}
        display='flex'
        {...BoxProps}>
        <TextField
          fullWidth
          {...defaultProps}
          InputProps={{
            ...defaultProps.InputProps || {},
            ...InputProps,
            classes: {
              ...classes,
              ...InputProps.classes || {},
            },
          }}
          {...props} />
      </Box>
    )
  }
  
  WrappedInput.displayName = displayName

  return WrappedInput
}


export const Username = makeAuthInput('Username', {
  placeholder: 'Username',
  key: 'username',
  name: 'username',
  type: 'email',
  InputProps: {
    startAdornment: (
      <InputAdornment position='start'>
        <Person />
      </InputAdornment>
    ),
  }
})


export const Password = makeAuthInput('Password', {
  placeholder: 'Password',
  key: 'password',
  name: 'password',
  type: 'password',
  InputProps: {
    startAdornment: (
      <InputAdornment position='start'>
        <Lock />
      </InputAdornment>
    ),
  }
})


export const Code = makeAuthInput('Code', {
  placeholder: 'Code',
  key: 'code',
  name: 'code',
  type: 'text',
  InputProps: {
    startAdornment: (
      <InputAdornment position='start'>
        <VpnKey />
      </InputAdornment>
    ),
  }
})
