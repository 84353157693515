import React, { useContext } from 'react'

import { makeAppStyles, Typography, CreateCSSProperties } from '@percept/mui'

import { UserIdRestrictions, UserPrivilegeContext, getActiveUserIdRestrictions } from '@percept/app-components'

import { LinkButton } from './LinkButton'
import { GlobalPrivilege } from '@percept/types'

import { userHasGlobalPrivileges } from '@percept/utils'
import { every } from 'lodash-es'


export type LinkSection = {
  title: string
  description: string | JSX.Element
  globalPrivileges?: GlobalPrivilege[] | null
  userIdRestrictions?: UserIdRestrictions | null
  path?: string
  openInNewWindow?: boolean
  imageSrc?: string
  imageContent?: JSX.Element
  imageHeader?: string | JSX.Element
}


const useLinkSectionStyles = makeAppStyles( theme => ({
  section: (props: { alternatePlacement: boolean }): CreateCSSProperties => ({
    height: 496,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    background: '#f2f2f2',
    '&:nth-child(even)': {
      background: '#fff',
      flexDirection: props.alternatePlacement ? 'row-reverse' : 'row',
    },
    padding: theme.spacing(2, 12),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 6),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  }),
  sectionInner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '50%',
    padding: theme.spacing(8, 2),
  },
  title: {
    fontSize: 38,
    lineHeight: 1.125,
    fontWeight: 700,
    marginBottom: theme.spacing(3),
  },
  description: {
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 500,
    marginBottom: theme.spacing(3),
  },
  button: {
    maxWidth: 'max-content',
    marginTop: theme.spacing(2),
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  imageHeader: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '26px',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
}))


export type LinkSectionsProps = {
  sections: LinkSection[]
  alternatePlacement?: boolean
}

export const LinkSections = ({
  sections,
  alternatePlacement = true,
}: LinkSectionsProps): JSX.Element => {
  const classes = useLinkSectionStyles({ alternatePlacement })
  const { global_privileges, user } = useContext(UserPrivilegeContext)
  const allowedSections = sections.filter((section): boolean => {
    const requiredFlags: boolean[] = []
    if( section.globalPrivileges ){
      requiredFlags.push(
        userHasGlobalPrivileges(section.globalPrivileges, global_privileges)
      )
    }
    const activeUserRestrictions = getActiveUserIdRestrictions(section.userIdRestrictions)
    if( activeUserRestrictions ){
      requiredFlags.push(activeUserRestrictions.includes(user && user.cognito_id))
    }
    return !requiredFlags.length || every(requiredFlags)
  })
  return (
    <div>
      { allowedSections.map( (section, i) => (
        <div
          key={`section-${i}`}
          className={classes.section}>
          <div className={classes.sectionInner}>
            <Typography variant='h3' className={classes.title}>
              { section.title }
            </Typography>
            <Typography className={classes.description}>
              { section.description }
            </Typography>
            <LinkButton
              className={classes.button}
              dummy={!section.path}
              comingSoon={!section.path}
              to={section.path || ''}
              openInNewWindow={section.openInNewWindow} />
          </div>
          <div className={classes.sectionInner}>
            { section.imageHeader && (
              <Typography className={classes.imageHeader} variant='h4'>{ section.imageHeader }</Typography>
            )}
            { section.imageSrc && (
              <img className={classes.img} src={section.imageSrc} />
            )}
            { section.imageContent }
          </div>
        </div>
      ))}
    </div>
  )
}
