
import React from 'react'

import { Box, Typography, TypographyProps } from '@material-ui/core'

import { makeAppStyles } from '../../themes'

import { ReportHealth } from '../ReportHealth'

import { isNumber } from '@percept/utils'

import { HealthType } from '@percept/types'


const useStyles = makeAppStyles({
  typography: {
    display: 'inline-flex',
    alignItems: 'center',
  }
})


type TitleProps = {
  type: 'tab' | 'section'
  title: string
  health?: HealthType
  TypographyProps?: Partial<TypographyProps>
}

export const Title = ({ type, title, health, TypographyProps = {} }: TitleProps): JSX.Element => {

  const classes = useStyles()

  return (
    <Typography
      className={classes.typography}
      variant={type === 'tab' ? 'h3' : 'h4'}
      {...TypographyProps}>

      { title }

      { isNumber(health) && (
        <Box
          ml={2}>
          <ReportHealth
            animate={false}
            orientation='horizontal'
            width='6rem'
            height='1.25rem'
            fontSize='1.4rem'
            health={health} />
        </Box>
      ) }

    </Typography>
  )
}

export default Title
