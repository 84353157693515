import { UseMutateFunction, UseMutationResult, useMutation } from 'react-query'

import qs from 'query-string'

import { saveAs } from 'file-saver'

import { apiClient } from 'dashboard/src/api/clients'

import { CurrencyType } from 'dashboard/src/components/ReportingDashboard/types'


export type ExCoDownloadProps = {
  start_date: string
  end_date: string
  markets?: string[]
  currency_type?: CurrencyType
}


export type ExCoDownloadHookValue = (
  Omit<UseMutationResult<void, unknown, ExCoDownloadProps>, 'mutate'> & {
    download: UseMutateFunction<void, unknown, ExCoDownloadProps>
  }
)

export const useExCoDownload = (): ExCoDownloadHookValue => {
  const { mutate, ...hookValue } = useMutation({
    mutationFn: async (props: ExCoDownloadProps) => {

      const response = await apiClient.get<Blob>(
        `/exco/report-export`,
        {
          params: props,
          paramsSerializer(params) {
            return qs.stringify(params)
          },
          responseType: 'blob',
          headers: {
            'Accept': 'application/octet-stream',
          }
        }
      )

      let filename = 'ExCoReport.xlsx'
      // Get filename from content disposition header if available
      const contentDisposition: string = response.headers && response.headers['content-disposition']
      if( contentDisposition ){
        const headerValues = contentDisposition.split(';').map( s => s.trim())
        const filenameValue = headerValues.find( v => v.startsWith('filename='))
        if( filenameValue ){
          filename = filenameValue.replace('filename=', '')
        }
      }

      saveAs(response.data, filename)
    }
  })

  return {
    download: mutate,
    ...hookValue,
  }
}
