import React from 'react'
import { makeFlagIconComponent } from './lib'


export const GH = makeFlagIconComponent(
  <>
    <path fill="#006b3f" d="M0 0h512v512H0z"/>
    <path fill="#fcd116" d="M0 0h512v341.3H0z"/>
    <path fill="#ce1126" d="M0 0h512v170.7H0z"/>
    <path d="m256 170.7 55.5 170.6L166.3 236h179.4L200.6 341.3z"/>
  </>
)
