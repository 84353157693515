import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { QueryFunction } from 'react-query'


const setAuthorizationHeader = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers.Authorization = `Bearer ${sessionStorage.getItem('PERCEPT_TOKEN')}`
  return config
}


export const makeAuthorizedApiClient = (instanceConfig: AxiosRequestConfig): AxiosInstance => {
  const apiClient = axios.create(instanceConfig)
  apiClient.interceptors.request.use(setAuthorizationHeader, (err) => Promise.reject(err))

  return apiClient
}


export const apiClients = {
  core: makeAuthorizedApiClient({
    baseURL: `${process.env.CORE_API_ROOT}/v1`
  }),
  performance: makeAuthorizedApiClient({
    baseURL: `${process.env.PERFORMANCE_API_ROOT}/v1`,
  }),
}


export const makeClientQueryFn = <T, R = T>(
  apiClient: AxiosInstance,
  requestConfig: AxiosRequestConfig,
  responseTransform?: (response: AxiosResponse<T>) => R
): QueryFunction<R> => {
  return async (): Promise<R> => {
    const response = await apiClient(requestConfig) as AxiosResponse<R>
    if( responseTransform ){
      return responseTransform(response as unknown as AxiosResponse<T>)
    }
    return response.data
  }
}
