import { Card, CardContent, CardHeader, Collapse } from '@material-ui/core'
import React, { useState } from 'react'
import { TabSectionHeader } from '../PartnershipTabs'
import { ScoreboardTable } from '../ReportTables'
import { MetaReportResponse } from './Market'
import { ProviderLogo } from '@percept/mui'

export type MetaReportProps = {
  reports: MetaReportResponse
  headers: string[]
}

export const MetaReport = ({ reports, headers }: MetaReportProps): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  return(
    <Card elevation={2}>
      <CardHeader
        title={
          <TabSectionHeader
            title={
              <ProviderLogo provider='META' size={1.25} />
            }
            mt={1}
            px={0}
            isCollapsed={isCollapsed}
            handleCollapse={setIsCollapsed}/>
        } />
      <Collapse in={!isCollapsed}>
        <CardContent>
          <ScoreboardTable
            reports={reports.data}
            additionalInfoReports={[reports.averages_row, reports.total_percent_row]}
            headers={headers}
            minColorScale={reports.min_value}
            maxColorScale={reports.max_value}/>
        </CardContent>
      </Collapse>
    </Card>
  )
}
