
import { getSeriesKeyFromAction } from '@percept/redux/utils'

import {
  FILTER_AUDIT_ENTITIES,
  CLEAR_AUDIT_ENTITY_FILTER } from '../actions'


const initialState = {}

export default (state = initialState, action) => {
  
  const { payload } = action
  
  const series_id = getSeriesKeyFromAction(action)

  switch(action.type){
    
    case FILTER_AUDIT_ENTITIES:

      return {
        ...state,
        [series_id]: {
          ...(state[series_id] || {}),
          [payload.provider]: {
            type: payload.type,
            id: payload.id
          }
        }
      }

    case CLEAR_AUDIT_ENTITY_FILTER:

      return {
        ...state, 
        [series_id]: {
          ...(state[series_id] || {}),
          [payload.provider]: {
            type: null,
            id: null
          }
        }
      }
    

    default:
      return state
  }
}
