import React, { useEffect, useState } from 'react'

import {
  Badge,
  BadgeProps,
  Box,
  Divider,
} from '@material-ui/core'

import { ArrowDropDown } from '../../icons'

import { identity } from 'lodash-es'

import { makeAppStyles } from '../../themes'

import { CheckboxGroup, CheckboxGroupProps } from '../Inputs'

import { RoundedPlainTextButton } from '../Buttons'

import { ButtonPopover, ButtonPopoverProps } from '../Popovers'


export type MultiSelectBaseProps<T> = {
  value: T[]
  options: T[]
  badgeColor?: BadgeProps['color']
  areValuesEqual?: CheckboxGroupProps<T>['areValuesEqual']
  renderItem?: (value: T) => React.ReactNode
  onChange: (value: T[]) => void
  getSortValue: (value: T) => string | number | null
  ButtonPopoverProps?: Partial<Omit<ButtonPopoverProps, 'children'>>
  itemLabel: string
  selectPrompt: string
}


const useBadgeStyles = makeAppStyles({
  badge: {
    right: 4,
    top: '50%',
  },
})

export function MultiSelectBase<T>({
  value,
  options,
  onChange,
  ButtonPopoverProps,
  itemLabel,
  selectPrompt,
  getSortValue,
  badgeColor = 'secondary',
  renderItem = identity,
  areValuesEqual,
}: MultiSelectBaseProps<T>): JSX.Element {

  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  const areFiltersApplied = (
    value.map(getSortValue).sort().join() === localValue.map(getSortValue).sort().join()
  )

  const badgeClasses = useBadgeStyles()

  const totalSelected = localValue.length
  const label = (
    <Badge color={badgeColor} classes={badgeClasses} badgeContent={totalSelected} showZero>
      <span style={{paddingRight: 20}}>
        {itemLabel}s
      </span>
    </Badge>
  )

  const isEmptyValue = localValue.length === 0

  return (
    <ButtonPopover
      variant='contained'
      endIcon={<ArrowDropDown />}
      ButtonComponent={RoundedPlainTextButton}
      buttonContent={label}
      onClose={(): void => {
        setLocalValue(value)
      }}
      {...ButtonPopoverProps}>
      { ({ onClose }): JSX.Element => (
        <Box p={2}>
          <Box ml={0}>
            <CheckboxGroup
              name={`${itemLabel}s`}
              value={localValue}
              areValuesEqual={areValuesEqual}
              options={
                options.map( value => ({
                  value,
                  label: renderItem(value),
                }))
              }
              onChange={setLocalValue} />
          </Box>
          <Box maxWidth='100%' flexBasis='100%' mt={1} px={0}>
            <Divider />
          </Box>
          <Box pt={2} textAlign='right'>
            <RoundedPlainTextButton
              variant='contained'
              color='secondary'
              size='small'
              disabled={areFiltersApplied || isEmptyValue}
              onClick={(): void => {
                onChange(localValue)
                onClose()
              }}>
              { isEmptyValue ? selectPrompt : areFiltersApplied ? 'Filters Applied' : 'Apply Filters'}
            </RoundedPlainTextButton>
          </Box>
        </Box>
      )}
    </ButtonPopover>
  )
}
