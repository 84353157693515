import { formatMoney } from '@percept/mui'

import { separateThousands } from '@percept/utils'

import { round } from 'lodash-es'

import { SponsorshipsFormItem, SponsorshipsFormSchema } from './typings'


export const sponsorshipsMoneyFormatter = (amount: number | null): string => (
  formatMoney({ amount, currency: 'GBP', abbreviate: false })
)

export const sponsorshipsNumberFormatter = (amount: number | null): string => (
  separateThousands(round(Number(amount), 2))
)


export const produceEntryFormSchema = (formSchema: SponsorshipsFormSchema): SponsorshipsFormSchema => {
  // We don't show derived metrics on entry forms, and need to trim any trailing dividers
  return formSchema.map( sectionWrapper => ({
    ...sectionWrapper,
    sections: sectionWrapper.sections.map( section => {
      const formItemsWithoutDerivedMetrics = section.formItems.filter( formItem => (
        formItem.type !== 'DERIVED_METRIC'
      ))
      const trimmedFormItems = formItemsWithoutDerivedMetrics.reduce( (acc, item, i, arr) => {
        if( item.type === 'DIVIDER' ){
          const next = arr[i + 1]
          if( !next || next.type === 'DIVIDER' ){
            return acc
          }
        }
        acc.push(item)
        return acc
      }, [] as SponsorshipsFormItem[])
      return {
        ...section,
        formItems: trimmedFormItems,
      }
    }),
  }))
}
