import React, { useEffect } from 'react'

import { useHistory } from 'react-router'

import { apiClients } from '@percept/hooks'


const DEBUG = process.env.NODE_ENV === 'development'


type LocationLike = {
  pathname: string
  search: string
}

const initialLocation = window.location

const getTrackingUrl = (location: LocationLike): string => (
  `${initialLocation.protocol}//${initialLocation.host}${location.pathname}${location.search}`
)

export const trackPageView = (url: string): void => {
  const payload = {
    url,
    event_type: 'PAGE_VIEW',
  }
  apiClients.core.post('/user-events/', payload).then( response => {
    if( DEBUG ){
      console.log('Page view successfully recorded', response.data)
    }
  }).catch( e => {
    if( DEBUG ){
      console.error('Page view could not be recorded', e)
    }
  })
}


let hasTrackedLandingPage = false


const LivePageViewTracker = (): null => {
  const { listen } = useHistory()

  useEffect(() => {
    if( !hasTrackedLandingPage ){
      hasTrackedLandingPage = true
      trackPageView(getTrackingUrl(initialLocation))
    }
    const unlisten = listen((location, action) => {
      const url = getTrackingUrl(location)
      if( DEBUG ){
        console.log('History action', action, url)
      }
      if( action === 'PUSH' ){
        trackPageView(url)
      }
    })

    return unlisten
  }, [listen])
  return null
}


export const PageViewTracker = (): JSX.Element | null => {
  if( DEBUG ){
    console.log('Page view tracking disabled in development')
    return null
  }
  return <LivePageViewTracker />
}
