
import { fetchTypes } from './constants'

import {
  all,
  mapValues,
  isNumber,
} from '@percept/utils'

import {
  Dictionary,
  FetchTypes,
  PaginationParams,
  ReduxAction,
  Selector,
  StoreState,
} from '@percept/types'

import { 
  EmptyPaginationCursor,
  emptyPaginationCursor,
  EmptyPaginationLimit,
  emptyPaginationLimit,
} from '@percept/constants'


export const getFetchTypes = (baseType: string): FetchTypes => (
  mapValues(
    fetchTypes,
    (v) => `${baseType}_${v}`
  )
)


export const actionCreator = (type: string) => (
  (payload = {}, opts = {}): ReduxAction => (
    ({ type, payload, ...opts })
  )
)


export const typedActionCreator = <T>(type: string) => (
  (payload: T, opts: Dictionary = {}): ReduxAction => (
    ({ type, payload, ...opts })
  )
)


export const makePayloadAdapter = <T, P>(
  adapter: ((payload: P) => T)
): ((action: ReduxAction) => T) => (
    (action): T => adapter(action.payload)
  )


export const makeNestedSelector = <T, A extends Array<unknown>>(selector: Selector, prop: string) => (
  (state: StoreState, ...args: A): T => (
    selector(state, ...args)[prop]
  )
)


export const getPaginationParams = (
  { cursor = '', limit = 0, }: ({
    cursor?: EmptyPaginationCursor | string
    limit?: EmptyPaginationLimit | number
  })
): Partial<PaginationParams> => ({
  ...(cursor && cursor !== emptyPaginationCursor && { cursor } || {}),
  ...(limit && limit !== emptyPaginationLimit && { limit } || {}),
})


type Key = string | number


export const joinKeys = (...keys: Key[]): string => keys.map(String).filter(Boolean).join('|')



type GetKeyProps = Dictionary | null

type GetKeyFromActionProps = {
  payload?: Dictionary | null
  meta?: Dictionary | null
}


const getKey = (key: Key, defaultValue = '') => (props: GetKeyProps): Key | null => props && props[key] || defaultValue

const getKeyFromAction = <Default = ''>(
  key: string,
  defaultValue: Default = '' as unknown as Default
): ((props: GetKeyFromActionProps) => (string | Default)) => (
    ({ meta }: GetKeyFromActionProps): string | Default => (
      (meta && meta[key]) || defaultValue
    )
  )


const isValidKey = (k: string | number | null | undefined | object): k is Key => (
  Boolean(isNumber(k) || typeof k === 'string' && k.length)
)

const areValidKeys = (keys: (Key | null)[]): keys is Key[] => all(keys, isValidKey)

const validKeys = (...keys: (Key | null)[]): string => !areValidKeys(keys) ? '' : joinKeys(...keys)

const validKeysWith = <T>(
  ...fns: ((x: T) => Key | null)[]
) => (
    (props: T): string => (
      validKeys(...fns.map( fn => fn(props) ))
    )
  )

const keysWith = <T>(
  ...fns: ((x: T) => Key | null)[]
) => (
    (props: T): string => (
      joinKeys(...(fns.map( fn => fn(props))).filter(isValidKey))
    )
  )



/* Key utility definitions */

export const getPaginationTokenKey = getKey('pagination_token')
export const getPaginationTokenKeyFromAction = getKeyFromAction('pagination_token')

export const getPageKey = getKey('page')
export const getPageKeyFromAction = getKeyFromAction('page')

export const getSizeKey = getKey('size')
export const getSizeKeyFromAction = getKeyFromAction('size')

export const getPaginationKey = validKeysWith(
  getPageKey,
  getSizeKey
)
export const getPaginationKeyFromAction = validKeysWith(
  getPageKeyFromAction,
  getSizeKeyFromAction,
)

export const getPlatformUnitKey = getKey('org_unit_id')
export const getPlatformUnitKeyFromAction = getKeyFromAction('org_unit_id')

export const getSeriesGroupKey = getKey('series_group_id')
export const getSeriesGroupKeyFromAction = getKeyFromAction('series_group_id')

export const getSeriesKey = getKey('series_id')
export const getSeriesKeyFromAction = getKeyFromAction('series_id')

export const getReportKey = getKey('report_id')
export const getReportKeyFromAction = getKeyFromAction('report_id')

export const getAuditKey = getReportKey
export const getAuditKeyFromAction = getReportKeyFromAction

export const getEntityTypeKey = getKey('entity_type')
export const getEntityTypeFromAction = getKeyFromAction('entity_type')

export const getEntityIdKey = getKey('entity_id')
export const getEntityIdFromAction = getKeyFromAction('entity_id')

export const getReportResultKey = getKey('result_id', 'ACTIVE')
export const getReportResultKeyFromAction = getKeyFromAction('result_id', 'ACTIVE')

export const getEntityKey = validKeysWith(
  getEntityTypeKey,
  getEntityIdKey,
  getReportResultKey,
)

export const getEntityKeyFromAction = validKeysWith(
  getEntityTypeFromAction,
  getEntityIdFromAction,
  getReportResultKeyFromAction,
)

export const getCognitoIdKey = getKey('cognito_id')
export const getCognitoIdKeyFromAction = getKeyFromAction('cognito_id')

export const getCognitoConfigurationKey = getKey('user_pool', 'PERCEPT')
export const getCognitoConfigurationKeyFromAction = getKeyFromAction('user_pool', 'PERCEPT')

export const getWorkflowExecutionKey = getKey('workflow_execution_id')
export const getWorkflowExecutionKeyFromAction = getKeyFromAction('workflow_execution_id')


/* Performance Reporting */

export const getCampaignIdsKey = (item: Dictionary | null | undefined): Key | null => {
  if( !(item && item.campaign_ids && item.campaign_ids.length ) ){
    return null
  }
  return item.campaign_ids.join('|')
}

export const getCampaignIdsKeyFromAction = ({ meta }: GetKeyFromActionProps): Key | null => {
  return getCampaignIdsKey(meta)
}

export const getReferenceDateKey = getKey('reference_date')
export const getReferenceDateKeyFromAction = getKeyFromAction('reference_date')

export const getStartDateKey = getKey('start_date')
export const getStartDateKeyFromAction = getKeyFromAction('start_date')

export const getEndDateKey = getKey('end_date')
export const getEndDateKeyFromAction = getKeyFromAction('end_date')

export const getChannelKey = getKey('channel')
export const getChannelKeyFromAction = getKeyFromAction('channel')

export const getProviderKey = getKey('provider')
export const getProviderKeyFromAction = getKeyFromAction('provider')

export const getAdNetworkKey = getKey('network')
export const getAdNetworkKeyFromAction = getKeyFromAction('network')

export const getPerformanceRangeKey = getKey('period')
export const getPerformanceRangeKeyFromAction = getKeyFromAction('period')

export const getPerformanceChunkingKey = getKey('chunking')
export const getPerformanceChunkingKeyFromAction = getKeyFromAction('chunking')

export const getCurrencyConversionKey = getKey('target_currency')
export const getCurrencyConversionKeyFromAction = getKeyFromAction('target_currency')


export const getProviderAndReferenceDateKey = validKeysWith(
  getProviderKey,
  getReferenceDateKey,
)

export const getProviderAndReferenceDateKeyFromAction = validKeysWith(
  getProviderKeyFromAction,
  getReferenceDateKeyFromAction,
)

export const getNestedComparisonKey = validKeysWith(
  getProviderKey,
  getCurrencyConversionKey,
  getReferenceDateKey,
)

export const getNestedComparisonKeyFromAction = validKeysWith(
  getProviderKeyFromAction,
  getCurrencyConversionKeyFromAction,
  getReferenceDateKeyFromAction,
)

export const getNestedDoubleVerifyComparisonKey = validKeysWith(
  getProviderKey,
  getReferenceDateKey,
)

export const getNestedDoubleVerifyComparisonKeyFromAction = validKeysWith(
  getProviderKeyFromAction,
  getReferenceDateKeyFromAction,
)



export const getPerformanceTimeseriesKey = validKeysWith(
  getPlatformUnitKey,
  getPerformanceRangeKey,
  getPerformanceChunkingKey,
  getCurrencyConversionKey,
  getReferenceDateKey,
)
export const getPerformanceTimeseriesKeyFromAction = validKeysWith(
  getPlatformUnitKeyFromAction,
  getPerformanceRangeKeyFromAction,
  getPerformanceChunkingKeyFromAction,
  getCurrencyConversionKeyFromAction,
  getReferenceDateKeyFromAction,
)

export const getChannelPerformanceTimeseriesKey = validKeysWith(
  getPlatformUnitKey,
  getChannelKey,
  getPerformanceRangeKey,
  getPerformanceChunkingKey,
  getCurrencyConversionKey,
  getReferenceDateKey,
)
export const getChannelPerformanceTimeseriesKeyFromAction = validKeysWith(
  getPlatformUnitKeyFromAction,
  getChannelKeyFromAction,
  getPerformanceRangeKeyFromAction,
  getPerformanceChunkingKeyFromAction,
  getCurrencyConversionKeyFromAction,
  getReferenceDateKeyFromAction,
)

export const getProviderPerformanceTotalsKey = validKeysWith(
  getPlatformUnitKey,
  getProviderKey,
  getStartDateKey,
  getEndDateKey,
  getCurrencyConversionKey,
)
export const getProviderPerformanceTotalsKeyFromAction = validKeysWith(
  getPlatformUnitKeyFromAction,
  getProviderKeyFromAction,
  getStartDateKeyFromAction,
  getEndDateKeyFromAction,
  getCurrencyConversionKeyFromAction,
)

export const getNestedPerformanceTotalsKey = validKeysWith(
  getProviderKey,
  getStartDateKey,
  getEndDateKey,
  getCurrencyConversionKey,
)
export const getNestedPerformanceTotalsKeyFromAction = validKeysWith(
  getProviderKeyFromAction,
  getStartDateKeyFromAction,
  getEndDateKeyFromAction,
  getCurrencyConversionKeyFromAction,
)

export const getProviderPerformanceTimeseriesKey = validKeysWith(
  getPlatformUnitKey,
  getProviderKey,
  getPerformanceRangeKey,
  getPerformanceChunkingKey,
  getCurrencyConversionKey,
  getReferenceDateKey,
)
export const getProviderPerformanceTimeseriesKeyFromAction = validKeysWith(
  getPlatformUnitKeyFromAction,
  getProviderKeyFromAction,
  getPerformanceRangeKeyFromAction,
  getPerformanceChunkingKeyFromAction,
  getCurrencyConversionKeyFromAction,
  getReferenceDateKeyFromAction,
)

export const getDoubleVerifyProviderPerformanceTimeseriesKey = validKeysWith(
  getPlatformUnitKey,
  getProviderKey,
  getPerformanceRangeKey,
  getPerformanceChunkingKey,
  getReferenceDateKey,
)
export const getDoubleVerifyProviderPerformanceTimeseriesKeyFromAction = validKeysWith(
  getPlatformUnitKeyFromAction,
  getProviderKeyFromAction,
  getPerformanceRangeKeyFromAction,
  getPerformanceChunkingKeyFromAction,
  getReferenceDateKeyFromAction,
)

export const getAdNetworkPerformanceTimeseriesKey = validKeysWith(
  getPlatformUnitKey,
  getAdNetworkKey,
  getPerformanceRangeKey,
  getPerformanceChunkingKey,
  getCurrencyConversionKey,
  getReferenceDateKey,
)
export const getAdNetworkPerformanceTimeseriesKeyFromAction = validKeysWith(
  getPlatformUnitKeyFromAction,
  getAdNetworkKeyFromAction,
  getPerformanceRangeKeyFromAction,
  getPerformanceChunkingKeyFromAction,
  getCurrencyConversionKeyFromAction,
  getReferenceDateKeyFromAction,
)


export const getProviderDataAvailabilityKey = validKeysWith(
  getProviderKey,
  getKey('level'),
  getKey('account_id', 'UNFILTERED'),
)
export const getProviderDataAvailabilityKeyFromAction = validKeysWith(
  getProviderKeyFromAction,
  getKeyFromAction('level'),
  getKeyFromAction('account_id', 'UNFILTERED'),
)


/* Structural Reporting */

export const getInsightsKey = getSeriesKey
export const getInsightsKeyFromAction = getSeriesKeyFromAction

export const getInsightsReportTypeKey = getKey('insights_report_type')
export const getInsightsReportTypeKeyFromAction = getKeyFromAction('insights_report_type')

export const getInsightsReportKey = validKeysWith(
  getSeriesKey,
  getReportKey,
  getInsightsReportTypeKey,
  getReportResultKey,
)
export const getInsightsReportKeyFromAction = validKeysWith(
  getSeriesKeyFromAction,
  getReportKeyFromAction,
  getInsightsReportTypeKeyFromAction,
  getReportResultKeyFromAction,
)

export const getPlatformUnitAndCurrencyKey = validKeysWith(
  getPlatformUnitKey,
  getCurrencyConversionKey,
)
export const getPlatformUnitAndCurrencyKeyFromAction = validKeysWith(
  getPlatformUnitKeyFromAction,
  getCurrencyConversionKeyFromAction,
)


/* Layouts */

export const getReportLayoutKey = getKey('report_layout_id')
export const getReportLayoutKeyFromAction = getKeyFromAction('report_layout_id')


/* Metrics */

export const getMetricIdKey = getKey('metric_id')
export const getMetricIdFromAction = getKeyFromAction('metric_id')

export const getDimensionKey = getKey('dimension')
export const getDimensionFromAction = getKeyFromAction('dimension')

export const getMetricKey = validKeysWith(
  getEntityTypeKey,
  getEntityIdKey,
  getMetricIdKey,
  getDimensionKey,
)
export const getMetricKeyFromAction = validKeysWith(
  getEntityTypeFromAction,
  getEntityIdFromAction,
  getMetricIdFromAction,
  getDimensionFromAction
)



export const getCreateSeriesKey = validKeysWith(
  getProviderKey,
)

export const getCreateSeriesKeyFromAction = validKeysWith(
  getProviderKeyFromAction,
)

export const getCursorKey = getKey('cursor', 'NO_CURSOR')
export const getCursorKeyFromAction = getKeyFromAction('cursor', 'NO_CURSOR')

export const getLimitKey = getKey('limit', 'NO_LIMIT')
export const getLimitKeyFromAction = getKeyFromAction('limit', 'NO_LIMIT')

export const getTimeSeriesKey = validKeysWith(
  getSeriesKey,
  getEntityTypeKey,
  getEntityIdKey,
  getMetricIdKey,
  getDimensionKey,
  getCursorKey,
  getLimitKey,
)

export const getTimeSeriesKeyFromAction = validKeysWith(
  getSeriesKeyFromAction,
  getEntityTypeFromAction,
  getEntityIdFromAction,
  getMetricIdFromAction,
  getDimensionFromAction,
  getCursorKeyFromAction,
  getLimitKeyFromAction,
)


/* Tasks */

export const getTaskKey = getKey('task_id')
export const getTaskKeyFromAction = getKeyFromAction('task_id')


/* Annotations */

export const getAnnotationKey = keysWith(
  getSeriesKey,
  getAuditKey,
  getMetricIdKey,
  getDimensionKey,
  getKey('segment')
)

export const getAnnotationKeyFromAction = keysWith(
  getSeriesKeyFromAction,
  getAuditKeyFromAction,
  getMetricIdFromAction,
  getDimensionFromAction,
  getKeyFromAction('segment')
)
