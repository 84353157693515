import { Selector } from 'react-redux'

import {
  ApiResponse,
  PerformanceTimeseriesByProvider,
  PlatformUnitParams,
  PerformanceReportingAggregationParams,
  PerformanceReportingCurrencyParams,
  ProviderParams,
  ChannelParams,
  ChannelPerformanceTimeseries,
  AdNetworkPerformanceTimeseries,
  AdNetworkParams,
  PerformanceComparisons,
  PerformanceTotals,
  ReferenceDateParams,
  DoubleVerifyPerformanceComparisons,
  DoubleVerifyPerformanceTimeseriesByProvider,
  CurrencyParams,
  DateRangeParams,
  ProviderDataAvailabilityTimeseries,
  ReportProvider,
  DoubleVerifyProvider,
  ApiStatusResponse,
  ProviderReferenceDates,
  PerformanceTotalsWithNetwork,
  PerformanceTotalsWithSubNetwork,
  PerformanceTotalsWithCampaignObjective,
} from '@percept/types'

import { apiInitialState } from '../../constants'

import {
  getAdNetworkPerformanceTimeseriesKey,
  getChannelPerformanceTimeseriesKey,
  getDoubleVerifyProviderPerformanceTimeseriesKey,
  getNestedComparisonKey,
  getNestedDoubleVerifyComparisonKey,
  getNestedPerformanceTotalsKey,
  getPerformanceTimeseriesKey,
  getPlatformUnitKey,
  getProviderDataAvailabilityKey,
  getProviderPerformanceTimeseriesKey,
} from '../../utils'

import { get } from 'lodash-es'

import { getPath } from '@percept/utils'

import { PerformanceReportingState } from './typings'


type StoreWithPerformanceReporting = {
  performanceReporting: PerformanceReportingState
}

type PerformanceReportingSelector<V, P = undefined> = (
  Selector<StoreWithPerformanceReporting, V, P>
)


export const getProviderPerformanceTotals: PerformanceReportingSelector<
  ApiResponse<PerformanceTotals>,
  PlatformUnitParams & ProviderParams & DateRangeParams & Partial<CurrencyParams>
> = (state, params) => (
  get(
    state.performanceReporting.totalsByProvider,
    [getPlatformUnitKey(params) || '', getNestedPerformanceTotalsKey(params) || ''],
    apiInitialState
  )
)

export const getProviderPerformanceTotalsByNetwork: PerformanceReportingSelector<
  ApiResponse<PerformanceTotalsWithNetwork>,
  PlatformUnitParams & ProviderParams & DateRangeParams & Partial<CurrencyParams>
> = (state, params) => (
  get(
    state.performanceReporting.totalsByProviderAndNetwork,
    [getPlatformUnitKey(params) || '', getNestedPerformanceTotalsKey(params) || ''],
    apiInitialState
  )
)

export const getProviderPerformanceTotalsBySubNetwork: PerformanceReportingSelector<
  ApiResponse<PerformanceTotalsWithSubNetwork>,
  PlatformUnitParams & ProviderParams & DateRangeParams & Partial<CurrencyParams>
> = (state, params) => (
  get(
    state.performanceReporting.totalsByProviderAndSubNetwork,
    [getPlatformUnitKey(params) || '', getNestedPerformanceTotalsKey(params) || ''],
    apiInitialState
  )
)

export const getProviderPerformanceTotalsByCampaignObjective: PerformanceReportingSelector<
  ApiResponse<PerformanceTotalsWithCampaignObjective>,
  PlatformUnitParams & ProviderParams & DateRangeParams & Partial<CurrencyParams>
> = (state, params) => (
  get(
    state.performanceReporting.totalsByProviderAndCampaignObjective,
    [getPlatformUnitKey(params) || '', getNestedPerformanceTotalsKey(params) || ''],
    apiInitialState
  )
)


export const getProviderPerformanceComparisons: PerformanceReportingSelector<
  ApiResponse<PerformanceComparisons>,
  PlatformUnitParams & ProviderParams & Partial<ReferenceDateParams>
> = (state, params) => (
  get(
    state.performanceReporting.comparisonsByProvider,
    [getPlatformUnitKey(params) || '', getNestedComparisonKey(params)],
    apiInitialState
  )
)


export const getDoubleVerifyProviderPerformanceComparisons: PerformanceReportingSelector<
  ApiResponse<DoubleVerifyPerformanceComparisons>,
  PlatformUnitParams & ProviderParams & Partial<ReferenceDateParams>
> = (state, params) => (
  get(
    state.performanceReporting.doubleVerifyComparisonsByProvider,
    [getPlatformUnitKey(params) || '', getNestedDoubleVerifyComparisonKey(params)],
    apiInitialState
  )
)


export const getPerformanceTimeseries: PerformanceReportingSelector<
  ApiResponse<PerformanceTimeseriesByProvider>,
  PlatformUnitParams & PerformanceReportingAggregationParams & Partial<PerformanceReportingCurrencyParams>
> = (state, params) => (
  get(
    state.performanceReporting.timeseries.aggregated,
    getPerformanceTimeseriesKey(params),
    apiInitialState
  )
)

export const getPerformanceTimeseriesByProvider: PerformanceReportingSelector<
  ApiResponse<PerformanceTimeseriesByProvider>,
  PlatformUnitParams & PerformanceReportingAggregationParams & Partial<PerformanceReportingCurrencyParams>
> = (state, params) => (
  get(
    state.performanceReporting.timeseries.aggregated,
    getPerformanceTimeseriesKey(params),
    apiInitialState
  )
)

export const getChannelPerformanceTimeseries: PerformanceReportingSelector<
  ApiResponse<ChannelPerformanceTimeseries>,
  PlatformUnitParams & ChannelParams & PerformanceReportingAggregationParams & Partial<PerformanceReportingCurrencyParams>
> = (state, params) => (
  get(
    state.performanceReporting.timeseries.byChannel,
    getChannelPerformanceTimeseriesKey(params),
    apiInitialState
  )
)

export const getProviderPerformanceTimeseries: PerformanceReportingSelector<
  ApiResponse<PerformanceTimeseriesByProvider>,
  PlatformUnitParams & ProviderParams & PerformanceReportingAggregationParams & Partial<PerformanceReportingCurrencyParams>
> = (state, params) => (
  getPath(
    state.performanceReporting.timeseries.byProvider,
    getProviderPerformanceTimeseriesKey(params),
    apiInitialState
  )
)

export const getDoubleVerifyProviderPerformanceTimeseries: PerformanceReportingSelector<
  ApiResponse<DoubleVerifyPerformanceTimeseriesByProvider>,
  PlatformUnitParams & ProviderParams & PerformanceReportingAggregationParams & Partial<PerformanceReportingCurrencyParams>
> = (state, params) => (
  getPath(
    state.performanceReporting.timeseries.byDoubleVerifyProvider,
    getDoubleVerifyProviderPerformanceTimeseriesKey(params),
    apiInitialState
  )
)

export const getAdNetworkPerformanceTimeseries: PerformanceReportingSelector<
  ApiResponse<AdNetworkPerformanceTimeseries>,
  PlatformUnitParams & AdNetworkParams & PerformanceReportingAggregationParams & Partial<PerformanceReportingCurrencyParams>
> = (state, params) => (
  getPath(
    state.performanceReporting.timeseries.byAdNetwork,
    getAdNetworkPerformanceTimeseriesKey(params),
    apiInitialState
  )
)


export const getProviderDataAvailability: PerformanceReportingSelector<
  ApiResponse<ProviderDataAvailabilityTimeseries>,
  {
    provider: ReportProvider | DoubleVerifyProvider
    level: 'ACCOUNT' | 'CAMPAIGN'
    account_id?: string
  }
> = (state, params) => (
  get(
    state.performanceReporting.availability,
    getProviderDataAvailabilityKey(params),
    apiInitialState
  )
)

export const getProviderReferenceDates: PerformanceReportingSelector<
  ApiResponse<ProviderReferenceDates>
> = state => (
  state.performanceReporting.referenceDates.active
)

export const getEditProviderReferenceDates: PerformanceReportingSelector<
  ApiStatusResponse<ProviderReferenceDates>
> = state => (
  state.performanceReporting.referenceDates.edit
)
