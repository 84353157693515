import {
  Box,
  IconButton,
  Modal,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import { ArrowDropDown, Close } from '@material-ui/icons'
import { useNavigation } from '@percept/hooks'
import { PerceptAppBar, RoundedPlainTextButtonMenu, makeAppStyles } from '@percept/mui'
import React, { PropsWithChildren, ReactNode, useRef, useState } from 'react'
import { Prompt, useLocation } from 'react-router'
import { ACTIVE_FORM_ROUTES } from 'constans'
import { ConfirmModal } from '../Modal'
import { get } from 'lodash-es'
import { Location } from 'history'

const useClasses = makeAppStyles((theme) => ({
  appBarContainer: {
    justifyContent: 'center',
  },
  appBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  appBarHeader: {
    color: theme.palette.primary.contrastText,
    fontSize: 18,
    fontWeight: 700,
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    color: theme.palette.primary.contrastText,
  },
  appBarInsert: {
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  },
  yearSelector: {
    marginLeft: theme.spacing(2),
  },
  container: {
    padding: '40px 128px',
  },
  contentContainer: {
    padding: theme.spacing(17, 4, 4, 4),
  },
}))

const useTabClasses = makeAppStyles( theme => ({
  root: {
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 700,
    minHeight: theme.spacing(6),
    color: 'white',
  },
  tab: {
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 700,
    minHeight: theme.spacing(6),
    color: 'white',
  },
  indicator: (
    theme.appComponents.appBarTabIndicator ?
      theme.appComponents.appBarTabIndicator.colorSecondary :
      {
        backgroundColor: theme.palette.secondary.main,
      }
  ),
}))

type FormLayoutProps = {
  title: string | JSX.Element
  isConflictScreen: boolean
  modalDisabled?: boolean
  modalTitle: string
  modalText: string
  modalSubmitText: string
  modalCancelText: string
  selectedYear: string | undefined
  changeYear: (value: string) => void
  yearsList: { id: string; name: string }[] | undefined
} & ReactNode

export const FormLayout = ({
  children,
  title,
  isConflictScreen,
  modalDisabled = false,
  modalCancelText,
  modalSubmitText,
  modalText,
  modalTitle,
  selectedYear,
  changeYear,
  yearsList,
}: PropsWithChildren<FormLayoutProps>): JSX.Element => {
  const classes = useClasses()
  const tabClasses = useTabClasses()
  const { pathname } = useLocation()
  const navigate = useNavigation()
  const [lastLocation, setLastLocation] = useState<Location | null>(null)

  const confirmRef = useRef(false)

  const [isOpen, setIsOpen] = useState(false)
  const formUrlPart = pathname.substring(0, pathname.lastIndexOf('/'))
  const baseUrlPart = formUrlPart.substring(0, formUrlPart.lastIndexOf('/'))
  const lastUrlPart = pathname.substring(
    pathname.lastIndexOf('/'),
    pathname.length
  )

  const possibleTabs = ACTIVE_FORM_ROUTES.map((route) => route.path)
  const activeTab = possibleTabs.find((el) => el === lastUrlPart)

  const handleFormClose = (): void => {
    if( modalDisabled ){
      navigate(baseUrlPart)
    }else{
      setIsOpen(true)
    }
  }

  const handleClose = (): void => {
    setIsOpen(false)
    setLastLocation(null)
  }

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if(
      !modalDisabled
      && nextLocation
      && !confirmRef.current
      && (!lastLocation || lastLocation.pathname !== nextLocation.pathname)
    ){
      setIsOpen(true)
      setLastLocation(nextLocation)
      return false
    }
    return true
  }

  return (
    <Box>
      <PerceptAppBar
        color='primary'
        appBarInserts={[
          <div key='form' className={classes.appBarInsert}>
            <Tabs
              key='form'
              classes={tabClasses}
              value={activeTab}
              indicatorColor='secondary'
              onChange={(_, value: string): void => {
                navigate(formUrlPart + value)
              }}>
              {ACTIVE_FORM_ROUTES.map((route, index) => (
                <Tab
                  key={index}
                  value={route.path}
                  className={tabClasses.tab}
                  label={route.props.title}
                />
              ))}
            </Tabs>
          </div>
        ]}>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          flexBasis='100%'>
          <Typography variant='h4' className={classes.appBarHeader}>
            {title}

            { !isConflictScreen && yearsList && selectedYear && (
              <RoundedPlainTextButtonMenu
                TriggerProps={{
                  className: classes.yearSelector,
                  size: 'small',
                  variant: 'contained',
                  endIcon: <ArrowDropDown />,
                }}
                value={selectedYear}
                label={
                  get(yearsList.find( y => y.id === selectedYear ), 'name', '')
                }
                options={
                  yearsList.map( y => ({
                    value: y.id,
                    label: y.name,
                  }))
                }
                onChange={(e, value) => {
                  changeYear(value)
                }}
              />
            )}
          </Typography>

          <IconButton className={classes.closeButton} onClick={handleFormClose}>
            <Close color='inherit' />
          </IconButton>
        </Box>
      </PerceptAppBar>

      <Box className={classes.contentContainer}>{children}</Box>

      <Modal open={isOpen} onClose={handleClose}>
        <ConfirmModal
          handleSubmit={(): void => {
            confirmRef.current = true
            setIsOpen(false)
            if( lastLocation ){
              navigate(lastLocation.pathname)
            }else{
              navigate(baseUrlPart)
            }
          }}
          handleCancel={handleClose}
          title={modalTitle}
          text={modalText}
          submitText={modalSubmitText}
          cancelText={modalCancelText}
        />
      </Modal>

      <Prompt
        when={!modalDisabled}
        message={handleBlockedNavigation} />
    </Box>
  )
}
