import React, { Fragment } from 'react'

import { Money, CellRenderers, SentimentDelta, Typography, Box } from '@percept/mui'

import { automaticPrecisionPercentageFormatter } from '@percept/mui/charts'

import {
  displayNumber,
  isInvertedSentiment,
  isMonetaryDimension,
  isPercentageDimension,
  isRateBasedDimension,
} from '@percept/utils'

import { AnyPerformanceDimension } from '@percept/types'

import { PerformanceReportingDatum } from 'types'


const toRoundedNumber = (v: unknown): number => (
  Math.round(Number(v))
)


const getCast = (dimension: AnyPerformanceDimension | undefined): ((v: unknown) => number) => {
  if( dimension === 'clicks' || dimension === 'conversions' ){
    return toRoundedNumber
  }
  return Number
}

export function DeltaRenderer<T extends object>(
  props: T & {
    deltaKey: keyof T
    dimension?: AnyPerformanceDimension
    invertSentimentCheck?: (dimension: AnyPerformanceDimension) => boolean
    cast?: (value: unknown) => number
  }
): JSX.Element {

  const value = props[props.deltaKey]

  if( value === null ){
    return (
      <Box color='text.disabled'>
        N / A
      </Box>
    )
  }

  const cast = props.cast || Number

  const delta = Math.round(cast(value))

  const sentimentCheck = props.invertSentimentCheck || isInvertedSentiment

  const invertSentiment = !!props.dimension && sentimentCheck(props.dimension)

  return (
    <SentimentDelta invertSentiment={invertSentiment} value={delta} />
  )
}


export function PerformanceValueRenderer<T extends {
  value: number | null
  dimension?: AnyPerformanceDimension
  currency?: string | null
}>(props: T): JSX.Element {
  const { currency, dimension } = props
  let { value } = props
  if( value === null || isNaN(value) ){
    return (
      <Typography variant='body2' color='textSecondary'>––</Typography>
    )
  }

  if( dimension && isMonetaryDimension(dimension) ){
    return (
      <Money
        amount={Number(value)}
        abbreviate={true}
        precision={
          !value ?
            0 :
            value < 0.01 ?
              4 :
              2
        }
        currency={currency} />
    )
  }

  // Coerce rate-based metrics in the 0...1 range to percentages
  if( dimension && isRateBasedDimension(dimension) ){
    value = value && (Number(value) * 100)
  }

  if( dimension && isPercentageDimension(dimension) ){
    return (
      <Fragment>
        { automaticPrecisionPercentageFormatter(value) }
      </Fragment>
    )
  }

  const cast = getCast(dimension)

  return (
    <Fragment>
      { displayNumber(cast(value)) }
    </Fragment>
  )
}


export const defaultRenderers: CellRenderers<
  PerformanceReportingDatum & {
    dimension?: AnyPerformanceDimension
    currency?: string | null
  }
> = {
  // eslint-disable-next-line react/display-name, react/prop-types
  now: ({ dimension, now, currency }) => {
    return (
      <PerformanceValueRenderer
        value={now}
        dimension={dimension}
        currency={currency} />
    )
  },
  // eslint-disable-next-line react/display-name, react/prop-types
  week: (props) => (
    <DeltaRenderer deltaKey='week' {...props} />
  ),
  // eslint-disable-next-line react/display-name, react/prop-types
  month: (props) => (
    <DeltaRenderer deltaKey='month' {...props} />
  ),
  // eslint-disable-next-line react/display-name, react/prop-types
  year: (props) => (
    <DeltaRenderer deltaKey='year' {...props} />
  ),
}
