import { Campaign, SpendingCell, Conflicts } from '../types'
import { isEqual } from 'lodash-es'

const MONTH_ORDER = [
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
  'Jan',
  'Feb',
  'Mar',
]

export const checkResolvedConflicts = (data: Campaign[]): boolean => {
  const isConflicts = data.map((el) => ({
    ...el,
    data: el.data.map((e) => (e.solved !== '' ? true : false)),
  }))

  return isConflicts.every((el) => el.data.every((v) => v === true))
}

const fillCampaignDataWithMissedIds = (
  cells: SpendingCell[],
  conflictCells: SpendingCell[]
) => {
  const result = cells.map((el) => {
    const conflictCell = conflictCells.find((e) => e.month === el.month)
    return conflictCell ? { ...el, id: conflictCell.id, solved: '' } : el
  })
  return result
}
export const combineAllConflicts = (
  data: Campaign[],
  createConflicts: Conflicts['create_conflicts'],
  updateConflicts: Conflicts['update_conflicts']
) => {
  const conflicts = data.map((el) =>
    el.data.some((e) =>
      e.id ? updateConflicts.map((a) => a.id).includes(e.id) : false
    )
      ? {
        ...el,
        conflict: true,
        data: el.data.map((cell) =>
          updateConflicts.some((conf) => conf.id === cell.id)
            ? { ...cell, solved: '' }
            : cell
        ),
      }
      : el
  )

  const combinedConflicts = conflicts.map((campaign) => {
    const createConflictCampaign = createConflicts.find((el) =>
      isEqual(
        { ...campaign, data: '', conflict: '' },
        { ...el, data: '', conflict: '' }
      )
    )
    return createConflictCampaign
      ? {
        ...campaign,
        data: fillCampaignDataWithMissedIds(
          campaign.data,
          createConflictCampaign.data
        ),
        conflict: true,
      }
      : campaign
  })

  const conflictsWithSortedSpendings  = combinedConflicts.map((el) => ({
    ...el,
    data: el.data.sort(
      (a, b) =>
        MONTH_ORDER.indexOf(a.month.slice(0, 3)) -
      MONTH_ORDER.indexOf(b.month.slice(0, 3))
    ),
  }))

  return conflictsWithSortedSpendings
}
