import React from 'react'

import { Dialog, DialogProps, makeAppStyles } from '@percept/mui'


const useDialogStyles = makeAppStyles( theme => ({
  paper: {
    backgroundColor: theme.palette.background.default,
  },
}))


export const DefaultBackgroundDialog = (props: DialogProps): JSX.Element => (
  <Dialog classes={useDialogStyles()} {...props} />
)
