import { useMemo } from 'react'

import { find, omit, pick, sortBy, uniq } from 'lodash-es'

import { differenceInCalendarDays } from 'date-fns'

import { coerceReportProvider, parseDimension } from '@percept/utils'

import {
  ApiLoader,
  DateType,
  DimensionType,
  LocationParams,
  Nullable,
  ParsedDimension,
  StructuralReport,
  ReportEntityPayload,
  SeriesReportMetric,
  SeriesReportMetricParams,
  SortConfig,
  ApiResponse,
  ReportSeries,
} from '@percept/types'

import { ReportDashboardAppDataHooks } from './typings'

import { PropLoaderHookValue } from '@percept/hooks/libv2'


export const defaultSortConfig: SortConfig = {
  key: 'name',
  order: 'ASC'
}


export const performanceDimensions: DimensionType[] = ['cost', 'impressions', 'views', 'clicks', 'conversions']

export const defaultDimensions: DimensionType[] = ['count', ...performanceDimensions]

export const getDimensionsForPerformanceTail = (tail: string): string[] => {
  if( !tail || tail === 'audit_period' ) return defaultDimensions
  return [
    'count',
    ...performanceDimensions.map( d => `${d}_${tail}` ),
  ]
}


export type PerformanceTailConfiguration = {
  dimensions: string[]
  reportPeriodTail: string | null
  activePerformanceTail: string
  performanceTailOptions: {
    value: string
    label: string,
    isDefault: boolean
  }[]
}


export const getCleanLocationParams = ({
  params,
  include,
  exclude,
}: {
  params: LocationParams
  include?: string[]
  exclude?: string[]
}): Partial<LocationParams> => {
  let output: Partial<LocationParams> = params
  if( include ){
    output = pick(params, include)
  }
  output = omit(output, ['path', 'report_id', 'series_id', 'series_group_id', 'metric_id'])
  if( exclude ){
    output = omit(output, exclude)
  }
  return output
}


const getNumReportDays = ({ start, end }: { start: DateType, end: DateType }): number => (
  Math.abs(
    differenceInCalendarDays(new Date(start), new Date(end))
  ) + 1
)


const getAvailablePerformanceTails = (payload: ReportEntityPayload | null): string[] => {
  if( !payload ) return ['']
  let performanceTails: ParsedDimension[] = []

  if( payload.metrics ){
    Object.keys(payload.metrics).forEach( metricId => {
      if( payload.metrics ){
        // Handle mapped / unampped
        const dimensionObject = payload.metrics[metricId].dimensions || payload.metrics[metricId]
        Object.keys(dimensionObject).forEach( dimension => {
          const parsed = parseDimension(dimension)
          if( parsed.tail ){
            performanceTails.push(parsed)
          }
        })
      }
    })

    performanceTails = sortBy(performanceTails, 'length')
  }

  return uniq(performanceTails.map( p => p.tail) )
}


export const usePerformanceTailConfiguration = ({
  report,
  entity,
  performanceTail }: {
  report: StructuralReport | null
  entity: ReportEntityPayload | null
  performanceTail: string | null
}): PerformanceTailConfiguration => {
  return useMemo(() => {
    let reportPeriodTail: string | null = null

    const availablePerformanceTails = getAvailablePerformanceTails(entity)

    if( report ){
      if( report.start === report.end ){
        reportPeriodTail = '1d'
      }else{
        const delta = getNumReportDays(report)
        reportPeriodTail = `${delta}d`
      }
    }

    const options = availablePerformanceTails.map( tail => {
      const isDefault = tail === reportPeriodTail
      const resolvedTail = isDefault ? (reportPeriodTail || '') : tail
      return {
        value: tail,
        label: resolvedTail,
        isDefault,
      }
    })

    const performanceTailOptions = sortBy(
      options,
      option => parseInt(option.label)
    )

    let activePerformanceTail = ''

    if( performanceTail === null && reportPeriodTail === '1d' && availablePerformanceTails.includes('7d') ){
      activePerformanceTail = '7d'
    }else{
      for( const t of [performanceTail, reportPeriodTail] ){
        if( t !== null && availablePerformanceTails.includes(t) ){
          activePerformanceTail = t
          break
        }
      }
    }

    const dimensions = (
      activePerformanceTail && availablePerformanceTails.includes(activePerformanceTail) ?
        getDimensionsForPerformanceTail(activePerformanceTail) :
        defaultDimensions
    )

    return {
      dimensions,
      reportPeriodTail,
      activePerformanceTail,
      performanceTailOptions,
    }
  }, [report, entity, performanceTail])
}


export const makeUseDerivedMetricSeries = (
  useMetricSeriesHook: ReportDashboardAppDataHooks['useSeriesMetric'],
  seriesData: ReportSeries | null,
) => (params: Nullable<SeriesReportMetricParams>): PropLoaderHookValue<SeriesReportMetric[], SeriesReportMetricParams> => {

  let secondaryDimensionOverride: string | null = params.dimension

  const parsedDimension = parseDimension(params.dimension)

  const provider = seriesData && coerceReportProvider(seriesData.provider.slug)

  if( provider === 'facebook' && parsedDimension.tail === '7d' ){
    secondaryDimensionOverride = parsedDimension.dimension
  }

  const [primarySeriesMetrics, loadPrimarySeriesMetrics] = useMetricSeriesHook(params)

  const [secondarySeriesMetrics, loadSecondarySeriesMetrics] = useMetricSeriesHook({
    ...params,
    dimension: secondaryDimensionOverride as SeriesReportMetricParams['dimension'],
  })

  const requiresStitching = secondaryDimensionOverride !== params.dimension

  const mappedSeriesMetrics = useMemo(() => {
    let loading = primarySeriesMetrics.loading

    const mappedPrimary = (
      primarySeriesMetrics.data ?
        primarySeriesMetrics.data :
        null
    )

    let data = mappedPrimary

    if( requiresStitching ){

      loading = loading || secondarySeriesMetrics.loading

      const mappedSecondary = (
        secondarySeriesMetrics.data ?
          secondarySeriesMetrics.data :
          null
      )

      if( !loading && mappedPrimary && (mappedSecondary || secondarySeriesMetrics.error) ){

        data = mappedPrimary.map( original => {
          // We only want to apply this where the original report was weekly,
          // as we're only doing this on `7d` performance tail variants
          const dayDelta = getNumReportDays(original)
          if( dayDelta === 7 ){
            const replacement = find(mappedSecondary, s => s.report_id === original.report_id )
            return replacement || original
          }
          return original
        })
      }else{
        data = null
      }

    }

    return {
      ...primarySeriesMetrics,
      loading,
      data,
    } as ApiResponse<SeriesReportMetric[]>

  }, [primarySeriesMetrics, secondarySeriesMetrics, requiresStitching])

  const loader: ApiLoader<Nullable<SeriesReportMetricParams> | undefined> = (params): void => {
    if( !primarySeriesMetrics.loading ){
      loadPrimarySeriesMetrics(params)
    }
    if( !secondarySeriesMetrics.loading && requiresStitching ){
      loadSecondarySeriesMetrics({
        ...params || {},
        dimension: secondaryDimensionOverride,
      } as SeriesReportMetricParams)
    }
  }

  return [mappedSeriesMetrics, loader]
}
