import React, { useContext, useState } from 'react'

import { Box, Tab, Tabs, Typography, makeAppStyles } from '@percept/mui'
import { TabType } from './PartnershipTabs'
import { Articles } from './Articles'
import { Events } from './Events'
import { Market } from './Market/Market'
import { Markets } from './Markets/Markets'
import { PartnershipsDataSubmit } from './DataSubmit/PartnershipsDataSubmit'
import { UserPrivilegeContext } from '@percept/app-components'
import { VODAFONE_GLOBAL_ID } from 'vodafoneMarkets'
import { userHasOrgPrivileges } from '@percept/utils'

const tabs: TabType[] = [
  { label:'Articles & Case Studies', value: 'articles' },
  { label:'Market Report', value: 'market' },
  { label:'All Markets', value: 'markets' },
  { label:'Calendar', value: 'events' },
]

const useStyles = makeAppStyles( theme => ({
  container: {
    padding: theme.spacing(6, 6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 6),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    fontSize: 38,
    lineHeight: 1.125,
    fontWeight: 700,
    marginBottom: theme.spacing(4),
  },
  orderedList: {
    fontSize: 18,
    paddingLeft: '1em',
    margin: theme.spacing(1.5, 0),
    '& li': {
      marginBottom: theme.spacing(0.75),
      '&:last-child': {
        marginBottom: 0,
      }
    }
  },
  text: {
    fontSize: 18,
  },
  tabs: {
    margin: theme.spacing(4,0),
    borderBottom: `1px solid ${theme.palette.text.hint}`,
  },
  tab: {
    textTransform: 'none',
  },
}))

export const Partnerships = (): JSX.Element => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(tabs[0].value)
  
  const privileges = useContext(UserPrivilegeContext)

  const canEdit = userHasOrgPrivileges(
    ['partnerships.edit'],
    VODAFONE_GLOBAL_ID,
    privileges.org_privileges
  )

  const handleChange = (_event: React.ChangeEvent<{}>, value: string): void => {
    setActiveTab(value)
  }


  return (
    <Box className={classes.container}>
      <Typography variant='h3' className={classes.title}>
        Welcome to Partnerships
      </Typography>
      <Typography className={classes.text}>
        We are dedicated to empowering diverse partnerships, promoting innovation through learning and exposure.
        Our three pillars are:
      </Typography>
      <ol className={classes.orderedList}>
        <li>
          <strong>Expand</strong> - Harness new capabilities and partners to meet evolving needs.
        </li>
        <li>
          <strong>Enable</strong> - Improve media fundamentals and foster continuous learning.
        </li>
        <li>
          <strong>Extract</strong> - Support VPC & markets in maximizing value from digital media investment.
        </li>
      </ol>
      <Typography paragraph className={classes.text}>
        Explore the latest scorecards for YouTube and Meta, stay informed about upcoming and completed webinars, and
        access up-to-date articles related to our media partnerships.
      </Typography>
      <Typography className={classes.text}>
        Join us in shaping the future of media collaboration.
      </Typography>

      <Tabs
        indicatorColor='primary'
        textColor='primary'
        variant='fullWidth'
        className={classes.tabs}
        value={activeTab}
        onChange={handleChange}>
        {tabs.map((tab: TabType) => (
          <Tab className={classes.tab} key={tab.label} value={tab.value} label={tab.label} />
        ))}
        {canEdit && (<Tab className={classes.tab} key={'submit'} value={'submit'} label={'Submit Data'} />)}
      </Tabs>

      {activeTab === 'articles' && (<Articles />)}
      {activeTab === 'events' && (<Events />)}
      {activeTab === 'market' && (<Market />)}
      {activeTab === 'markets' && (<Markets />)}
      {activeTab === 'submit' && canEdit && (<PartnershipsDataSubmit />)}
    </Box>
  )
}
