import { weightedScore } from './utils'

import { areIntervalsOverlapping } from 'date-fns'

import { get } from 'lodash-es'

import { DateRange, PotentialEfficiencyDatum } from '@percept/types'


const getAveragedPotentialEffiencyForRange = (
  potentialEffiencyDatum: PotentialEfficiencyDatum,
  datumRange: [number, number],
  overallRange: [number, number]
): PotentialEfficiencyDatum => {
  /* eslint-disable @typescript-eslint/camelcase */
  if( datumRange[0] === datumRange[1] ){
    return potentialEffiencyDatum
  }
  if( datumRange[0] >= overallRange[0] && datumRange[1] <= overallRange[1] ){
    return potentialEffiencyDatum
  }
  const dayInMillis = 24 * 60 * 60 * 1000
  const rangeStart = Math.max(datumRange[0], overallRange[0])
  const rangeEnd = Math.min(datumRange[1], overallRange[1])
  const totalDays = Math.ceil((datumRange[1] - datumRange[0]) / dayInMillis) + 1
  const daysToAverage = Math.ceil((rangeEnd - rangeStart) / dayInMillis) + 1
  const multiplier = daysToAverage / totalDays
  const { total_cost, transformed_cost } = potentialEffiencyDatum
  const averagedTotalCost = total_cost === null ? null : total_cost * multiplier
  const averagedTransformedCost = transformed_cost === null ? null : transformed_cost * multiplier
  return {
    ...potentialEffiencyDatum,
    total_cost: averagedTotalCost,
    transformed_cost: averagedTransformedCost,
  }
  /* eslint-enable @typescript-eslint/camelcase */
}


export const aggregatePotentialEfficiencies = (
  potentialEffiencies: PotentialEfficiencyDatum[],
  dateRange?: DateRange | null
): Omit<PotentialEfficiencyDatum, 'start' | 'end'> => {
  /* eslint-disable @typescript-eslint/camelcase */
  let total_cost = 0, total_transformed_cost = 0
  const scores: number[] = []
  const weights: number[] = []
  const overallRange: [number, number] | null = (
    dateRange
    && [new Date(dateRange.start).getTime(), new Date(dateRange.end).getTime()]
    || null
  )
  const overallInterval: Interval | null = dateRange && {
    start: new Date(dateRange.start),
    end: new Date(dateRange.end),
  } || null
  for( const datum of potentialEffiencies ){
    if( overallInterval ){
      const datumInterval: Interval = {
        start: new Date(datum.start),
        end: new Date(datum.end),
      }
      if( !areIntervalsOverlapping(overallInterval, datumInterval, { inclusive: true }) ){
        continue
      }
    }
    const datumRange: [number, number] = [new Date(datum.start).getTime(), new Date(datum.end).getTime()]
    const averagedDatum = (
      overallRange ?
        getAveragedPotentialEffiencyForRange(datum, datumRange, overallRange) :
        datum
    )
    const cost = Number(averagedDatum.total_cost)
    const transformed_cost = Number(averagedDatum.transformed_cost)
    total_cost += cost
    total_transformed_cost += transformed_cost
    scores.push(Number(averagedDatum.potential_efficiency_ratio))
    weights.push(total_cost)
  }

  // const percentageWeighted = weightedScore(scores, weights)
  const straightPercentage = (!total_transformed_cost || !total_cost) ? null : total_transformed_cost / total_cost

  return {
    total_cost: potentialEffiencies.length === 0 ? null : total_cost,
    transformed_cost: potentialEffiencies.length === 0 ? null : total_transformed_cost,
    potential_efficiency_ratio: straightPercentage,
    currency: get(potentialEffiencies[0], 'currency', null),
  }
  /* eslint-enable @typescript-eslint/camelcase */
}
