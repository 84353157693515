
import React, { Fragment, memo } from 'react'

import { renderers } from './lib'

import { all, getPath } from '@percept/utils'

import { LayoutNodeType, TipSettings, TipLabel, Dictionary, HealthType, MetricIdentification } from '@percept/types'

import { ReportDashboardDataDependencies, ReportDashboardProps } from './typings'


export type MainProps = (
  LayoutNodeType &
  Pick<
    ReportDashboardProps,
    'setActiveMetric' | 'setExportMetric' | 'setSeriesMetric' |
    'debug' | 'activeLayoutItemPath' | 'onActivateLayoutItemPath' |
    'metadata' | 'displayType' | 'seriesChartType' | 'setSeriesChartType' |
    'metricAnnotations'
  > &
  Omit<
    ReportDashboardDataDependencies,
    'report'
  > & {
    debug?: boolean
    autoExpand?: boolean
    members: LayoutNodeType[]
    previous?: ReportDashboardDataDependencies['metrics'] | null
    dimensionOptions?: string[]
    healthDimension?: string
    performanceTail?: string | null
    currency?: string | null
    activeTab?: string | null
    filterLegend?: boolean
    sectionScores: Dictionary<HealthType>
    headerContent?: React.ReactNode
    onSegmentClick?: (params: MetricIdentification) => void
    activeLayoutItemPath?: string
    onActivateLayoutItemPath?: (path: string) => void
    tipSettings?: TipSettings
    onToggleTip?: (label: TipLabel) => void
    tipPriority?: TipLabel[]
  }
)


export const Main = ({
  members,
  activeTab,
  sectionScores,
  headerContent = null,
  healthDimension = 'count',
  tipSettings,
  onToggleTip,
  tipPriority,
  ...props
}: MainProps): JSX.Element => {

  const isTabbed = all(members, m => m.type === 'tab' )

  return (
    <Fragment>

      { headerContent }

      {/** Utility component for displaying flattened layout hierarchy by tab */}
      {/* { isTabbed && (
        <PrintLayoutTable
          tabs={members}
          payload={props.payload}
          metadata={props.metadata} />
      )} */}

      { members.map( (member, i) => {
        
        // Cast the type here, we'll bail if we don't have a valid renderer for this layout item
        // Ugly fix to accept combined recursive props within dashboard components
        const Renderer = renderers[member.type as keyof (typeof renderers)] as unknown as (
          (props: LayoutNodeType & { path: string, healthDimension: string }) => JSX.Element
        )

        const isActiveTab = (member.key || member.name) === activeTab

        if( isTabbed && !isActiveTab ){
          return null
        }
        
        if( !Renderer ){
          console.warn('Invalid type supplied to dashboard layout: ', member.type)
          return null
        }

        if( getPath(member, ['features', 'hide']) === true ){
          if( props.debug ){
            console.log('Skipping hidden layout section', member)
          }
          return null
        }

        const tipProps = (isActiveTab || i === 0) ? { tipSettings, onToggleTip, tipPriority } : {}

        return (
          <Renderer
            path={`${i}`}
            {...props}
            {...member}
            key={member.key || member.name || `${member.type}-${i}`}
            healthDimension={healthDimension}
            {...tipProps}
            {...(
              member.type === 'tab' &&
              { health: getPath(sectionScores, member.key || member.name) } || {}
            )} />
        )
      }) }
    
    </Fragment>
  )
}

export default memo(Main)
