
import { createApiReducer, createKeyedReducer } from '@percept/redux/reducers'

import {
  getTimeSeriesKeyFromAction,
} from '@percept/redux/utils'

import { metricSeries } from '@percept/api/adapters'

import { LOAD_SERIES_REPORT_METRIC } from '../../actions'

import {
  Reducer,
} from '@percept/types'

import { SeriesState } from '../../typings'


const byId: Reducer<SeriesState['timeSeries']['byId']> = createKeyedReducer(
  createApiReducer(LOAD_SERIES_REPORT_METRIC, {
    getPayloadFromAction: ({ payload }) => (
      metricSeries(payload)
    )
  }),
  getTimeSeriesKeyFromAction
)

export default byId
