export type MFAStep = 'beginSetup' | 'showLink' | 'getQR' | 'chooseDeviceName' | 'completeSetup' | 'enableMFA'

export type MFAInputs = Partial<{
  code: string | null
  deviceName: string | null
}>

export type MFAReducerState = {
  currentStep: MFAStep
  accessToken: string
  username: string
  inputs: MFAInputs
  totpURI: string
  totpSecret: string
  totpSession: string
}

export const initialState: MFAReducerState = {
  currentStep: 'beginSetup',
  accessToken: '',
  username: '',
  inputs: {},
  totpURI: '',
  totpSecret: '',
  totpSession: '',
}


export type MFAAction = {
  type: (
    'BEGIN_SETUP_COMPLETE' | 'UPDATE_CODE' | 'UPDATE_DEVICE_NAME' | 'COMPLETE_SETUP' | 'SET_CURRENT_STEP'
  )
  payload: any
}


export default (state: MFAReducerState, action: MFAAction): MFAReducerState => {
  switch(action.type){

    case 'SET_CURRENT_STEP':
      return {
        ...state,
        currentStep: action.payload,
      }

    case 'BEGIN_SETUP_COMPLETE':
      return {
        ...state,
        totpURI: action.payload.provisioning_uri,
        totpSecret: action.payload.secret_code,
        currentStep: 'getQR',
      }

    case 'UPDATE_CODE':
      return {
        ...state,
        inputs: {
          ...state.inputs,
          code: action.payload,
        },
      }

    case 'UPDATE_DEVICE_NAME':
      return {
        ...state,
        inputs: {
          ...state.inputs,
          deviceName: action.payload,
        },
      }

    case 'COMPLETE_SETUP':
      return state

  }
}