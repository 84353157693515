import React, { Fragment, useState } from 'react'

import { Button, ButtonProps, Popover, PopoverProps } from '@material-ui/core'


type ButtonComponentType<P extends ButtonProps> = React.ComponentType<P>


export type ButtonPopoverProps<P extends ButtonProps = ButtonProps> = Omit<P, 'onClick' | 'children'> & {
  ButtonComponent?: ButtonComponentType<P>
  buttonContent?: React.ReactNode | React.ReactNode[]
  PopoverProps?: Partial<Omit<PopoverProps, 'open' | 'onClose' | 'anchorEl' | 'children'>>
  children: JSX.Element | JSX.Element[] | React.ReactNode | ((props: { onClose: () => void }) => JSX.Element)
  onClose?: (reason?: 'backdropClick' | 'escapeKeyDown') => void
}


export function ButtonPopover<P extends ButtonProps>({
  ButtonComponent = Button,
  PopoverProps = {},
  buttonContent,
  children,
  onClose,
  ...props
}: ButtonPopoverProps<P>): JSX.Element {

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (reason?: 'backdropClick' | 'escapeKeyDown'): void => {
    setAnchorEl(null)
    onClose && onClose(reason)
  }

  const open = Boolean(anchorEl)

  return (
    <Fragment>

      <ButtonComponent
        onClick={handleClick}
        {...props as unknown as P}>
        { buttonContent }
      </ButtonComponent>

      <Popover
        anchorEl={anchorEl}
        open={open}
        {...PopoverProps}
        onClose={(e, reason): void => {
          handleClose(reason)
        }}>

        { typeof children === 'function' ? children({ onClose: handleClose }) : children }

      </Popover>

    </Fragment>
  )
}
