import React, { useContext } from 'react'

import { Link } from 'react-router-dom'

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  makeAppStyles,
} from '@percept/mui'

import { BrandGrowthPlannerContext } from '../../contexts'

import { getBGPRoute } from 'components/BrandGrowthPlanner/utils'


type ContentSection = {
  title: string
  content: React.ReactNode
}

const defaultContentSections: ContentSection[] = [
  {
    title: 'What',
    content: (
      <ul>
        <li>
          Brand Growth planner is a decision support tool that enables strategic planning of media investments to unlock growth
        </li>
        <li>
          It is a dynamic tool for ongoing decision support that brings together data, best practices and global guidance to drive
          better focus, smarter investment & greater effectiveness of media
        </li>
        <li>
          Brand Growth Planner produces a blueprint of a market strategy almost instantly and significantly reduces time challenges
          collating the data together
        </li>
      </ul>
    ),
  },
  {
    title: 'How',
    content: (
      <ul>
        <li>
          Brand Growth Planner enables users to connect the dots across data sources combining brand health, media investments,
          measurement & market context in one easy to use platform
        </li>
        <li>
          Brand Growth Planner also consolidates Vodafone guidelines in one single place enabling all aspects of media planning &
          execution for Vodafone to be planner & synchronised effectively
        </li>
      </ul>
    )
  }
]


const useStyles = makeAppStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(5),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  cardHeaderRoot: {
    padding: 0,
    backgroundColor: theme.palette.grey[200],
  },
  cardHeaderTitle: {
    fontSize: 25,
    fontWeight: 700,
    padding: theme.spacing(2),
  },
  cardContent: {
    padding: theme.spacing(3, 3, 3, 2),
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '26px',
    '& > ul': {
      margin: 0,
      paddingInlineStart: '1em',
    },
    '& > ul li': {
      marginBottom: theme.spacing(2),
    },
    '& > ul li:last-of-type': {
      marginBottom: 0,
    },
  },
  inlineLink: {
    margin: theme.spacing(0, 0.25, 0, 0.5),
    textDecoration: 'underline',
    textUnderlineOffset: '5px',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    transition: theme.transitions.create('color'),
  },
}))


const submitAnalysisRoute = getBGPRoute('SUBMIT')


export const AboutBGPPage = (): JSX.Element => {
  const classes = useStyles()

  const { isGlobalUser } = useContext(BrandGrowthPlannerContext)

  let contentSections = defaultContentSections

  if( !isGlobalUser ){
    contentSections = [
      ...contentSections,
      {
        title: 'Get Started',
        content: (
          <>
            Click on
            <Link
              className={classes.inlineLink}
              to={submitAnalysisRoute}>
              Submit Data for Analysis
            </Link>
            , you will then be directed to your latest report to get an overview of your market
          </>
        )
      }
    ]
  }

  return (
    <Box>
      {contentSections.map((contentSection, i) => (
        <Card key={i} className={classes.card}>
          <CardHeader
            classes={{
              root: classes.cardHeaderRoot,
              title: classes.cardHeaderTitle,
            }}
            title={contentSection.title} />
          <CardContent className={classes.cardContent}>
            {contentSection.content}
          </CardContent>
        </Card>
      ))}
    </Box>
  )
}
