/* eslint-disable no-console */

const logger = (...args) =>
  console.log(
    '%cSW',
    'background: #5c748c; color: #ebeff3; padding: 2px 5px; font-weight: bold; border-radius: 3px',
    ...args
  )

export default ({
  swPath = '/service-worker.js',
  onUpdateFound = null,
  onUpdateTrigger = null,
  onUpdateSuccess = null,
  onUpdateError = null,
  updateInterval = null,
  debug = false
} = {}) => {

  const log = debug ? logger : () => {},
        forceLog = logger
  
  const { navigator } = window
  
  if( !('serviceWorker' in navigator) ){
    return
  }

  let existingRegistration = false

  const listenForWaitingServiceWorker = (reg) => {

    // Triggers the actual update on invocation
    const updater = () => {
      log('Updater function triggered')
      if( reg.waiting ){
        log('skipWaiting triggered on waiting service worker')
        reg.waiting.postMessage('skipWaiting')
      }else{
        // The waiting service worker already installed, despite appearing
        // to be an available update which should have waited.
        // This shouldn't ever fire due to the existing registration check,
        // but we'll keep it in as a safety net.
        typeof onUpdateSuccess === 'function' && onUpdateSuccess()
      }
    }

    const awaitStateChange = () => {
      reg.installing.addEventListener('statechange', (e) => {
        
        log('Installing registration state => ', e.target.state)
        log('Registrations:')
        log(' -- Installing', reg.installing && reg.installing.state)
        log(' -- Waiting', reg.waiting && reg.waiting.state)
        log('Existing registration', existingRegistration)

        // Catch a waiting service worker and inform consuming code that an update is available.
        // We need to ensure that there is a waiting registration, that it is not in the process
        // of activating or has activated, and that this is not a first installation (in this case
        // existingRegistration will be false)
        if(
          e.target.state === 'installed'
          && existingRegistration
          && reg.waiting
          && !['activated', 'activating'].includes(reg.waiting.state)
          && typeof onUpdateFound === 'function'
        ){
          
          log('New service worker installed and waiting, notifying update available')
          log('Waiting service worker object', reg.waiting && reg.waiting.state)
          onUpdateFound(updater)
          
        }
      })
    }
    // Bail if no valid registration object is found
    if( !reg ){
      log('No valid registration object found')
      return
    }
    // Catch a waiting service worker and inform consuming code that an update is available
    if( reg.waiting && typeof onUpdateFound === 'function' ){
      log('Current registration object waiting, notifying update available')
      return onUpdateFound(updater)
    }
    // Service worker is installing, listen for installation
    if( reg.installing ){
      log('Installing, listening for statechange')
      awaitStateChange()
    }
    // Add event listener directly to the registration object
    reg.addEventListener('updatefound', awaitStateChange)
  
  }

  navigator.serviceWorker.ready.then( () => {
    log('Ready')
    existingRegistration = true
  })

  // Actual update logic - reload window on controller change

  let isRefreshing = false

  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if( isRefreshing ){
      return
    }
    isRefreshing = true
    typeof onUpdateTrigger === 'function' && onUpdateTrigger()
    log('Update triggered')
    window.location.reload()
  })

  // Now we can start everything off - first, check for existing registrations
  // so we can avoid false positives when detecting installing service workers,
  // as the first install also fires the 'onupdatefound' event.

  navigator.serviceWorker.getRegistration().then( existing => {

    existingRegistration = !!existing
    log(existingRegistration ? 'Existing registration found' : 'First install')

    let updateErrors = {}

    // Now we can register the service worker, and listen for state changes
    // knowing whether this a genuine update or a first install
    navigator.serviceWorker.register(swPath).then( reg => {

      listenForWaitingServiceWorker(reg)

      // Check for updates on a timer if an updateInterval was provided
      if( typeof updateInterval === 'number' ){
        setInterval(() => {
          // Always log update checks
          forceLog('Checking for update...')
          try {
            reg.update()
            updateErrors = {}
          } catch(e) {
            forceLog('Update error', e)
            if( typeof onUpdateError === 'function' ){
              updateErrors[e.message] = updateErrors[e.message] || 0
              updateErrors[e.message] += 1
              if( updateErrors[e.message] > 1 ){
                forceLog('Throwing duplicated update error')
                onUpdateError(e)
              }
            }
          }
        }, updateInterval)
      }

    }).catch(log)

  }).catch(log)
  
}