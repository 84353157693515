
import {
  createApiReducer,
  createKeyedReducer,
} from '@percept/redux/reducers'

import {
  getCreateSeriesKeyFromAction
} from '@percept/redux/utils'

import {
  CREATE_SERIES,
  CREATE_TRIAL_SERIES,
  CLEAR_CREATE_SERIES,
  CLEAR_CREATE_TRIAL_SERIES,
} from '../../actions'

import { ApiStatusResponse, Series } from '@percept/types'


import { Reducer, SeriesGroupWrapper } from '@percept/types'


const createSeries: Reducer<SeriesGroupWrapper['createSeries']> =  createKeyedReducer(
  createApiReducer<Series, ApiStatusResponse<Series>>(CREATE_SERIES, {
    processActionTypes: [CREATE_SERIES, CREATE_TRIAL_SERIES],
    resetActionTypes: [CLEAR_CREATE_SERIES, CLEAR_CREATE_TRIAL_SERIES]
  }),
  getCreateSeriesKeyFromAction
)

export default createSeries
