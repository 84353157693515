import React, { Fragment } from 'react'

import {
  BackdropLoader,
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  PerceptAppBar,
  Typography,
  makeAppStyles,
  Divider,
} from '@percept/mui'

import { Build, Close } from '@percept/mui/icons'

import { ExamplesDataTable } from '@percept/app-components'

import { EstimatedChange } from './EstimatedChange'

import { useFilteredMetricMetadata } from '@percept/hooks'

import { Insight, ReportProvider } from '@percept/types'


type InsightDialogProps = (
  Omit<DialogProps, 'children' | 'open' | 'onClose'> & {
    insight: Insight
    provider: ReportProvider
    onClose: () => void
  }
)


const useStyles = makeAppStyles( theme => ({
  appBarTypography: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(3),
  },
  recommendation: {
    margin: theme.spacing(6, 0),
    '&:first-of-type': {
      marginTop: theme.spacing(4),
    },
    '&:last-of-type': {
      marginBottom: theme.spacing(4),
    },
  },
  recommendationTitle: {
    fontSize: 18,
    marginBottom: theme.spacing(2),
  },
  recommendationTypography: {
    // marginBottom: theme.spacing(2),
    padding: theme.spacing(3, 2),
    backgroundColor: theme.palette.info.dark,
    borderRadius: theme.shape.borderRadius,
  },
  recommendationIcon: {
    fontSize: '0.85em',
    position: 'relative',
    top: 1,
    marginRight: theme.spacing(1),
  },
}))


const useDialogStyles = makeAppStyles( theme => ({
  paper: {
    backgroundColor: theme.palette.background.default,
  },
}))


export function InsightDialog({
  provider,
  insight,
  ...props
}: InsightDialogProps): JSX.Element {

  const [metadata] = useFilteredMetricMetadata({ provider })

  const classes = useStyles()

  const dialogClasses = useDialogStyles()

  return (
    <Dialog
      open
      classes={dialogClasses}
      {...props}>

      <PerceptAppBar
        color='secondary'
        startIcon={
          <IconButton
            onClick={props.onClose}>
            <Close />
          </IconButton>
        }>

        <Typography
          variant='h5'
          className={classes.appBarTypography}>
          { insight.name }
        </Typography>

        { insight.estimated_change && (
          <EstimatedChange
            {...insight.estimated_change}
            currency={insight.currency} />
        )}
      </PerceptAppBar>

      <Box pt={4}>

        { metadata.data ? (
          <Fragment>

            <DialogContent>
              { insight.recommendations.map(({ title, text, entities }, i) => {
                return (
                  <Fragment key={i}>
                    <div className={classes.recommendation}>
                      { title && (
                        <Typography
                          variant='h4'
                          className={classes.recommendationTitle}>
                          { title }
                        </Typography>
                      )}

                      <Typography variant='h5' className={classes.recommendationTypography}>
                        <Build className={classes.recommendationIcon} />
                        { text }
                      </Typography>

                      { entities && metadata.data && (
                        <ExamplesDataTable
                          examples={entities}
                          metadata={metadata.data}
                          currency={insight.currency || null}
                          dimension='cost' />
                      )}
                    </div>

                    { i < (insight.recommendations.length - 1) && (
                      <Divider />
                    )}
                  </Fragment>
                )
              })}
            </DialogContent>

          </Fragment>
        ) : (
          <BackdropLoader />
        )}

      </Box>

    </Dialog>
  )
}
