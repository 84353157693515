import React, { Fragment, useContext } from 'react'

import { DefaultMediaWizardLayout } from './DefaultMediaWizardLayout'
import { Carousel, CarouselItem } from 'components/Carousel'
import { NewsHeader, NewsLink, WizardNewsSummary } from 'components/News'
import { LinkSection, LinkSections, vodafoneExternalLinks } from 'components/Links'

import { useDocumentTitle } from '@percept/hooks'

import { UserPrivilegeContext } from '@percept/app-components'

import { get } from 'lodash-es'

import carouselWhite2 from '../images/carousel-backgrounds/carousel-white-2.jpg'
import carouselGrey2 from '../images/carousel-backgrounds/carousel-grey-2.jpg'
import carouselBlack2 from '../images/carousel-backgrounds/carousel-black-2.jpg'

// import infoGraphicCarbonDioxide from '../images/infographics/5.4-tons-light-bg.svg'
import infoGraphicSustainability from '../images/infographics/globe-green.svg'
// import infoGraphic46Percent from '../images/infographics/46-percent-dark-bg.svg'

import infoGraphicMacbookMQD from '../images/infographics/macbook-mqd.png'
import infoGraphicMacbookCarbonCalculator from '../images/infographics/macbook-carbon-calculator.png'
import infoGraphicIpadCreativeX from '../images/infographics/ipad-creativex.png'
import infoGraphicProviders from '../images/infographics/provider-logos.svg'
import infoGraphicCalculator from '../images/infographics/calculator-light-bg.svg'

import { AnimationDigitalMedia } from 'animations'

import { InfographicCQS } from 'components/StaticInfographics'
import { vodafonePalette } from '@percept/mui'


const carouselItems: CarouselItem[] = [
  {
    title: 'Media that works',
    description: (
      <Fragment>
        Track, review and analyse your digital media, to maximise impact and reduce wastage.
        Sharing best practices across search, social and programmatic formats.
      </Fragment>

    ),
    textColor: 'black',
    backgroundImageSrc: carouselWhite2,
    // infoGraphicSrc: infoGraphicDigitalMedia,
    GraphicComponent: AnimationDigitalMedia,
  },
  // {
  //   title: 'Did you know strong creative performance drives profitability by 46%?',
  //   description: (
  //     <Fragment>
  //       Get the most from your creative assets, with best practice guidance across Meta, YouTube,
  //       TikTok, Adform and DV360.
  //     </Fragment>
  //   ),
  //   textColor: 'white',
  //   backgroundImageSrc: carouselBlack2,
  //   infoGraphicSrc: infoGraphic46Percent,
  // },
  {
    title: (
      <>
        Maximize the impact of every creative decision in digital, through{' '}
        <span style={{color: vodafonePalette.red}}>
          creative pre-flight testing
        </span>
        {' '}and hitting 80% Creative Quality Score target
      </>
    ),
    titleSize: 0.825,
    backgroundImageSrc: carouselBlack2,
    textColor: 'white',
    infoGraphicContent: <InfographicCQS />,
  },
  {
    title: 'Did you know that Vodafone media emitted 16,958 tCO2e in 2023?',
    description: (
      <Fragment>
        That&apos;s equivalent to roughly 3,800 round trip flights between London and Cape Town.
        Measure and minimise your media carbon emissions, to support Vodafone&apos;s commitment to being
        net zero by 2040.
      </Fragment>
    ),
    textColor: 'black',
    backgroundImageSrc: carouselGrey2,
    infoGraphicSrc: infoGraphicSustainability,
  },
]


export const Optimisation = (): JSX.Element => {

  useDocumentTitle({ paths: ['Optimisation'] })

  const privileges = useContext(UserPrivilegeContext)
  const default_org_unit_id = get(privileges.user, 'default_org_unit_id', null)

  const linkSections: LinkSection[] = [
    {
      title: 'Media Quality Dashboard',
      description: (
        <Fragment>
          Track, review and analyse your digital media performance to maximise impact
          and reduce wastage across Search (Google), Social (Meta and YouTube) and
          Programmatic (Adform and DV360). Get inspired by what other Vodafone markets
          are doing.
        </Fragment>
      ),
      globalPrivileges: ['structuralReporting.viewAny', 'performanceOverview.viewAny'],
      imageSrc: infoGraphicMacbookMQD,
      path: default_org_unit_id ? (
        `/dashboards/${default_org_unit_id}`
      ) : undefined,
      openInNewWindow: true,
    },
    {
      title: 'Creative Quality Dashboard',
      description: (
        <Fragment>
          Access your creative quality scores and spend rates across Meta, YouTube, TikTok
          and Programmatic (Adform and DV360). Using real-time data to optimise your
          campaigns and share learnings across our markets.
        </Fragment>
      ),
      imageSrc: infoGraphicIpadCreativeX,
      path: vodafoneExternalLinks.creativeX,
      openInNewWindow: true,
    },
    {
      title: 'Media Carbon Calculator',
      description: (
        <Fragment>
          <p>
            Measure and minimise carbon emissions across your media activities, by channel,
            supplier and format. Share learnings across markets to inspire others to reduce
            their carbon footprint.
          </p>
          <p>
            Get inspired by how other Vodafone markets are reducing their carbon footprint.
          </p>
        </Fragment>
      ),
      imageSrc: infoGraphicMacbookCarbonCalculator,
      path: vodafoneExternalLinks.carbonCalculator,
      openInNewWindow: true,
    },
    {
      title: 'Digital Media Partnerships',
      description: (
        <Fragment>
          Fuel your ongoing media growth with access to JBP strategies and benchmarking scorecards.
          Explore the latest news, resources and webinars to support your campaign performance.
        </Fragment>
      ),
      globalPrivileges: ['partnerships.viewAny'],
      path: '/wizard/optimisation/partnerships',
      openInNewWindow: true,
      imageSrc: infoGraphicProviders,
    },
    {
      title: 'Sponsorship Evaluation Calculator',
      description: (
        <Fragment>
          Drive better commercial decisions across digital and real estate, with our bespoke asset
          inventory and evaluation tool.
        </Fragment>
      ),
      globalPrivileges: ['sponsorships.viewAny'],
      path: '/wizard/optimisation/sponsorships',
      openInNewWindow: true,
      imageSrc: infoGraphicCalculator,
    }
  ]

  return (
    <DefaultMediaWizardLayout
      breadcrumbs={[
        {
          path: '/wizard/optimisation',
          name: 'Optimisation',
        },
      ]}>

      <Carousel items={carouselItems} />

      <LinkSections sections={linkSections} />

      <NewsHeader />
      
      <WizardNewsSummary />

      <NewsLink />

    </DefaultMediaWizardLayout>
  )
}
