import React, { useState } from 'react'

import { SmartCampaignAssessmentHookProps, SmartCampaignRow, useSmartCampaigns } from './hooks'

import {
  Card,
  CellRenderers,
  Column,
  Divider,
  LinearProgress,
  Loader,
  SimpleTable,
  TablePagination,
  Typography,
  makeAppStyles,
} from '@percept/mui'
import { MarketDisplayLabel } from 'components/MarketDisplay'
import { TablePaginationActions } from 'components/UserManagement/tableComponents'
import { QuadrantType } from '@percept/types'
import { dmyFormatter } from '@percept/mui/charts'


export type SmartCampaignTableProps = SmartCampaignAssessmentHookProps & {
  quadrant: QuadrantType
}


const renderers: CellRenderers<SmartCampaignRow> = {
  /* eslint-disable react/display-name, react/prop-types */
  market: ({ market }) => {
    return (
      <MarketDisplayLabel
        iso_code={market.market_iso_code}
        name={market.market_name} />
    )
  },
  normalized_link: ({ normalized_link }) => {
    if( !normalized_link ){
      return null
    }
    return (
      <a
        href={normalized_link}
        rel='noopener noreferrer'
        target='_blank'
        style={{
          textDecoration: 'underline',
          textUnderlineOffset: 5,
        }}>
        { normalized_link }
      </a>
    )
  },
  campaign_date: ({ campaign_date }) => (
    <>
      {dmyFormatter(campaign_date)}
    </>
  )
  /* eslint-enable react/display-name, react/prop-types */
}

const columns: Column<SmartCampaignRow>[] = [
  {
    key: 'market',
    label: 'Market',
    align: 'left',
  },
  {
    key: 'brand',
    label: 'Brand',
    align: 'left',
  },
  {
    key: 'campaign_id',
    label: 'Campaign ID',
    align: 'left',
  },
  {
    key: 'campaign_name',
    label: 'Campaign Name',
    align: 'left',
  },
  {
    key: 'campaign_date',
    label: 'Campaign Date',
    align: 'left',
  },
  {
    key: 'normalized_link',
    label: 'Link',
    align: 'left',
  },
]


const useStyles = makeAppStyles( theme => ({
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  spacedLeft: {
    marginLeft: theme.spacing(2),
  },
  card: {
    position: 'relative',
    paddingTop: theme.spacing(0.5),
  },
  enabledIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(0.75),
  },
  disabledIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(0.75),
  },
  neutralIcon: {
    color: 'inherit',
    marginRight: theme.spacing(0.75),
  },
  loadingIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 4,
    width: '100%',
  },
  tableCell: {
    fontSize: 13,
    fontWeight: 500,
    padding: theme.spacing(1, 2),
  },
  tableRowClickable: {
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '& .MuiTableCell-body': {
        color: theme.palette.primary.contrastText,
      },
      '& $tableVodafoneIcon': {
        fill: 'currentColor',
      },
      '& $enabledIcon': {
        color: 'inherit',
      },
      '& $disabledIcon': {
        color: 'inherit',
      },
    },
  },
  tableVodafoneIcon: {
    width: 18,
    height: 18,
    transition: theme.transitions.create('fill'),
    fill: theme.palette.primary.main,
  },
  tableExternalIcon: {
    fontSize: 18,
  },
  logo: {
    color: theme.palette.primary.main,
    margin: theme.spacing(0, 1),
  },
}))


export const SmartCampaignTable = ({ start, end, markets, reportType, quadrant }: SmartCampaignTableProps): JSX.Element => {

  const [page, setPage] = useState(1)

  const [limit, setLimit] = useState(25)
  
  const campaignsHook = useSmartCampaigns({
    start,
    end,
    markets,
    reportType,
    quadrant,
    page,
    limit,
  })

  const classes = useStyles()

  return (
    <Card elevation={2} className={classes.card}>
      { campaignsHook.isRefetching && (
        <LinearProgress variant='indeterminate' className={classes.loadingIndicator} />
      )}
      { campaignsHook.data ? (
        <>
          <SimpleTable
            classes={{
              tableRowClickable: classes.tableRowClickable,
              tableCell: classes.tableCell,
            }}
            columns={columns}
            renderers={renderers}
            rows={campaignsHook.data.items} />
          <Divider />
          <TablePagination
            component={'div'}
            ActionsComponent={TablePaginationActions}
            page={page - 1}
            rowsPerPage={campaignsHook.data.size}
            rowsPerPageOptions={[limit]}
            labelDisplayedRows={(pageInfo): JSX.Element => (
              <Typography variant='h5'>
                {`${pageInfo.from} - ${pageInfo.to} of ${pageInfo.count}`}
              </Typography>
            )}
            count={campaignsHook.data.total}
            onChangePage={(e, page): void => {
              setPage(page + 1)
            }} />
        </>
      ) : (
        <Loader preset='centered' minHeight='20rem' />
      )}
    </Card>
  )
}
