import { apiInitialState } from '../constants'

import { PaginatedReducerState, TokenPaginatedReducerState } from '../reducers'

import { ApiResponse, PaginatedApiResponse, PaginatedResponse, TokenPaginatedApiResponse, TokenPaginatedResponse } from '@percept/types'


export const paginationResponseResolver = <T>(
  { byPage, pageSize }: PaginatedReducerState<T[]>
): PaginatedApiResponse<T[]> => {
  let data: T[] | null = null

  let currentPage = 0
  let nextPage: number | null = null
  let totalItems: number | null = null
  
  let response: ApiResponse<PaginatedResponse<T[]>> = apiInitialState

  let loading = false

  let done = false
  while( !done ){
    if( byPage[currentPage + 1] ){
      currentPage += 1
      response = byPage[currentPage]
      if( response ){
        loading = response.loading || loading
      }
      if( !response || !response.data ){
        nextPage = currentPage
        done = true
        break
      }
      data = (data || [] as T[]).concat(response.data.items)
      totalItems = response.data.total
      if( totalItems === data.length ){
        nextPage = null
      }else{
        nextPage = currentPage + 1
      }
    }else{
      done = true
      break
    }
  }

  return {
    ...response,
    loading,
    data,
    nextPage,
    pageSize,
    totalItems,
  }
}


export const tokenPaginationResponseResolver = <T>(
  { initial, byToken }: TokenPaginatedReducerState<T[]>
): TokenPaginatedApiResponse<T[]> => {
  const initialPayload = initial.data
  let data: T[] | null = initialPayload && initialPayload.items

  let currentToken = initialPayload && initialPayload.pagination_token
  let nextToken: string | null = null
  
  let response: ApiResponse<TokenPaginatedResponse<T[]>> = initial || apiInitialState

  let done = false
  while( !done ){
    if( currentToken ){
      response = byToken[currentToken]
      if( !response || !response.data ){
        nextToken = currentToken
        done = true
        break
      }
      data = (data || [] as T[]).concat(response.data.items)
      if( response.data.pagination_token ){
        currentToken = response.data.pagination_token
      }else{
        done = true
        break
      }
    }else{
      done = true
      break
    }
  }

  return {
    ...response,
    data,
    nextToken,
  }
}
