import React, { useState } from 'react'

import { GlobalUserCalculationView, PrefillSubmissionForm } from './estimateComponents'

import { MarketingCPMsForm } from './MarketingCPMsForm'

import { SubApplicationTabContext, SubApplicationTabs } from 'components/SubApplicationTabs'


export const GlobalView = (): JSX.Element => {
  const [activeTabValue, setActiveTabValue] = useState('view')

  return (
    <SubApplicationTabContext.Provider value={{ activeTabValue, setActiveTabValue }}>
      <SubApplicationTabs
        tabs={[
          {
            label: 'View Calculations',
            value: 'view',
            content: <GlobalUserCalculationView />,
          },
          {
            label: 'Submit Prefill Data',
            value: 'submit-prefills',
            content: <PrefillSubmissionForm onSubmit={(): void => setActiveTabValue('view')} />,
          },
          {
            label: 'Submit Marketing CPMs',
            value: 'submit-cpms',
            content: <MarketingCPMsForm onSubmit={(): void => setActiveTabValue('view')} />,
          }
        ]} />
    </SubApplicationTabContext.Provider>
  )
}
