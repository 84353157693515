import React, { useEffect, useState } from 'react'
import { MetaReports } from './MetaReports'
import { scoreboardHeaders } from '../PartnershipsData'
import { Box, Card, CardContent, CardHeader, Loader, ProviderLogo, Typography } from '@percept/mui'
import { MonthSelect } from 'components/Selects'
import { formatDate } from '../Market/Market'
import { YoutubeReports } from './YoutubeReports'
import { useCreativeQualityScoreTimeseriesByMarket } from '@percept/hooks'
import {
  usePartnershipsHistoricMetaReports,
  usePartnershipsHistoricYouTubeReports,
  usePartnershipsMetaReports,
  usePartnershipsReferenceDates,
  usePartnershipsYouTubeReports,
} from '../partnershipsHooks'
import { endOfMonth, format, startOfMonth, subYears } from 'date-fns'
import { get } from 'lodash-es'
import { parseCreativeQualityScoreData } from '../dataUtils'


export const Markets = (): JSX.Element => {

  const referenceDates = usePartnershipsReferenceDates()

  const [date, setDate] = useState<Date | null>(null)
  
  useEffect(() => {
    if( referenceDates.data && !date ){
      const latestAvailable = referenceDates.data.combined_providers.report_dates[0]
      if( latestAvailable ){
        setDate(new Date(latestAvailable))
      }
    }
  }, [referenceDates.data, date])

  const referenceDate = date && formatDate(date)

  const {
    data: youtubeReports,
    isLoading: youtubeReportsLoading,
    isError: youtubeReportsError,
  } = usePartnershipsYouTubeReports(referenceDate)

  const {
    data: youtubeHistoricReports,
    isLoading: youtubeHistoricReportsLoading,
    isError: youtubeHistoricReportsError,
  } = usePartnershipsHistoricYouTubeReports(referenceDate)

  const {
    data: metaReport,
    isLoading: metaLoading,
    isError: metaError,
  } = usePartnershipsMetaReports(referenceDate)
  
  const {
    data: metaReportHistoric,
    isLoading: metaHistoricLoading,
    isError: metaHistoricError,
  } = usePartnershipsHistoricMetaReports(referenceDate)

  const creativeXEndDate = date && endOfMonth(date)
  const creativeXStartDate = creativeXEndDate && startOfMonth(subYears(creativeXEndDate, 1))

  const {
    data: creativeXData,
    isLoading: creativeXLoading,
    isError: creativeXError,
  } = useCreativeQualityScoreTimeseriesByMarket({
    start_date: creativeXStartDate && format(creativeXStartDate, 'yyyy-MM-dd'),
    end_date: creativeXEndDate && format(creativeXEndDate, 'yyyy-MM-dd'),
    filters: [
      {
        publisher: 'youtube'
      }
    ]
  })

  const parsedCreativeXData = creativeXData && parseCreativeQualityScoreData(creativeXData)

  const monthOptions = get(referenceDates.data, ['combined_providers', 'report_dates'], []).map( d => new Date(d))

  return (
    <>
      { date && (
        <Box display='flex' justifyContent='end' mb={4}>
          <MonthSelect
            value={date}
            options={monthOptions}
            onChange={setDate} />
        </Box>
      )}

      {(youtubeReportsLoading || youtubeHistoricReportsLoading || creativeXLoading) && <Loader preset='centered' minHeight='16rem' />}
      {(youtubeReportsError || youtubeHistoricReportsError || creativeXError) && (
        <Card elevation={2}>
          <CardHeader
            title={
              <ProviderLogo provider='youtube' size={2} />
            } />
          <CardContent>
            <Typography variant='h5'>
              There are no reports available for the selected date, or an error occured.
            </Typography>
          </CardContent>
        </Card>
      )}
      {youtubeReports && youtubeHistoricReports && parsedCreativeXData && referenceDate && (
        <YoutubeReports 
          reports={youtubeReports} 
          monthlyReports={youtubeHistoricReports}
          creativeXData={parsedCreativeXData}
          referenceDate={referenceDate} />
      )}

      {(metaLoading || metaHistoricLoading) && <Loader preset='centered' minHeight='16rem' />}
      {metaError || metaHistoricError && (
        <Card>
          <CardHeader
            title={
              <ProviderLogo provider='META' size={1.5} />
            } />
          <CardContent>
            <Typography variant='h5'>
              There are no reports available for the selected date, or an error occured.
            </Typography>
          </CardContent>
        </Card>
      )}
      {metaReport && metaReportHistoric && (
        <Box mt={6}>
          <MetaReports 
            reports={metaReport.data} 
            additionalInfoReports={[metaReport.averages_row, metaReport.total_percent_row]} 
            quarterlyReports={metaReportHistoric.data} 
            headers={scoreboardHeaders}
            minColorScale={metaReport.min_value}
            maxColorScale={metaReport.max_value}
            reportQuarter={metaReport.report_quarter}
          />
        </Box>
      )}
      
    </>
  )
}
