import React, { useContext } from 'react'

import { QuadrantReport } from 'components/SmartCampaignAssessment/QuadrantCharts'

import { ParentSize } from '@visx/responsive'

import { UserPrivilegeContext } from '@percept/app-components'

import { CarouselItem } from 'components/Carousel'

import {
  useSmartCampaignAssesmentQuadrantReport,
  useSmartCampaignFilters,
} from 'components/SmartCampaignAssessment/hooks'

import { quadrantReportsToChartData } from 'components/SmartCampaignAssessment/lib'

import { LiveCarouselHookProps } from './typings'

import { maxBy } from 'lodash-es'

import { getFinancialYearStart, userHasGlobalPrivileges } from '@percept/utils'

import { vodafoneMarkets } from 'vodafoneMarkets'


export const useSmartCampaignAssessmentCarouselItem = (props: LiveCarouselHookProps): CarouselItem | null => {
  const privileges = useContext(UserPrivilegeContext)

  const hasRequiredPrivileges = userHasGlobalPrivileges(
    ['vfSmartCampaignAssessment.view'],
    privileges.global_privileges,
  )

  const { data: filters, isError: filtersError } = useSmartCampaignFilters({
    enabled: hasRequiredPrivileges,
  })

  let start: Date | null = null
  let end: Date | null = null
  let markets: string[] = []

  if( filters ){
    if( filters.reference_end_date && filters.reference_start_date ){
      end = new Date(filters.reference_end_date)
      start = maxBy(
        [
          new Date(filters.reference_start_date),
          getFinancialYearStart(end),
        ],
        d => d.getTime()
      ) as Date
      markets = vodafoneMarkets.reduce( (acc, m) => {
        if( filters.market_ids.includes(m.id) ){
          acc.push(m.id)
        }
        return acc
      }, [] as string[])
    }
  }

  const {
    data: quadrantReport,
    isError: quadrantReportError,
  } = useSmartCampaignAssesmentQuadrantReport({
    start,
    end,
    markets,
    reportType: 'VODAFONE',
    enabled: hasRequiredPrivileges,
  })

  if( !hasRequiredPrivileges || filtersError || quadrantReportError ){
    return null
  }

  if( !quadrantReport ){
    return {
      ...props,
      title: '',
      loading: true,
    }
  }

  const chartData = quadrantReportsToChartData({
    baseline: quadrantReport,
    comparison: null
  })

  return {
    title: (
      'Watch which market campaigns are reaching consumers\' hearts'
    ),
    description: 'Click here for a deep dive on our campaigns',
    href: '/wizard/reporting/smart-campaign-assessment',
    infoGraphicContent: (
      <div style={{width: '100%', height: 'calc(100% - 100px)'}}>
        <ParentSize>
          { ({ width, height }): JSX.Element => (
            <QuadrantReport
              width={width}
              height={height}
              {...chartData} />
          )}
        </ParentSize>
      </div>
    ),
    ...props,
  }
}
