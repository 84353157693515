import React from 'react'

import { omit } from 'lodash-es'

import { AnnotationsProps } from './typings'

import { ChartAnnotationValue } from '../typings'

import { NumberValue } from 'd3-scale'

import { NumberLike } from '@visx/scale'


const coerceAnnotationValue = (
  value: ChartAnnotationValue,
  scale: (n: NumberValue) => NumberLike | undefined
): number | string => (
  typeof value === 'string' ? value : Number(scale(value))
)


export const Annotations = ({
  annotations,
  xScale,
  yScale,
  width,
  height,
  xOffset = 0,
}: AnnotationsProps): JSX.Element => {
  return (
    <svg width={width} height={height} x={xOffset}>
      { annotations.map( (annotation, i) => {

        const key = `${annotation.type}-${i}`

        switch( annotation.type ){

          case 'box': {
            const additionalProps = omit(annotation, ['type', 'values'])
            const { values } = annotation
            const y = coerceAnnotationValue(values.y, yScale)
            const rectProps = {
              x: coerceAnnotationValue(values.x, xScale),
              width: (
                values.width ?
                  coerceAnnotationValue(values.width, xScale) :
                  '100%'
              ),
              y,
              height: (
                values.height ?
                  coerceAnnotationValue(values.height, yScale) :
                  typeof y === 'string' ?
                    y :
                    height - y
              ),
              ...additionalProps,
            }
            
            return (
              <rect
                key={key}
                pointerEvents='none'
                {...rectProps} />
            )
          }

          case 'line': {
            const { values } = annotation
            const x = coerceAnnotationValue(values.x, xScale)
            const y = coerceAnnotationValue(values.y, yScale)
            const additionalProps = omit(annotation, ['type', 'values', 'label'])
            const lineProps = {
              x1: x,
              x2: '100%',
              y1: y,
              y2: y,
              ...additionalProps,
            }
            
            return (
              <line
                key={key}
                pointerEvents='none'
                {...lineProps} />
            )
          }
        }

      })}
    </svg>
  )
}
