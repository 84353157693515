import React, { useState } from 'react'

import { MarketUserCalculationView, EstimateSubmissionForm } from './estimateComponents'

import { SubApplicationTabContext, SubApplicationTabs } from 'components/SubApplicationTabs'


export const MarketView = (): JSX.Element => {

  const [activeTabValue, setActiveTabValue] = useState('view')

  return (
    <SubApplicationTabContext.Provider value={{ activeTabValue, setActiveTabValue }}>
      <SubApplicationTabs
        tabs={[
          {
            label: 'View Calculations',
            value: 'view',
            content: <MarketUserCalculationView />,
          },
          {
            label: 'Submit Data',
            value: 'submit',
            content: <EstimateSubmissionForm onSubmit={(): void => setActiveTabValue('view')} />,
          },
        ]} />
    </SubApplicationTabContext.Provider>
  )
}
