import React from 'react'

import { Box, Typography } from '@percept/mui'

import { DefaultMediaWizardLayout } from './DefaultMediaWizardLayout'

import { WizardNews } from 'components/News'

import { useDocumentTitle } from '@percept/hooks'


export const News = (): JSX.Element => {
  useDocumentTitle({ paths: ['News'] })
  return (
    <DefaultMediaWizardLayout
      breadcrumbs={[
        {
          path: '/wizard/news',
          name: 'News',
        },
      ]}>
      <Box mt={3} mb={5} px={4}>
        <Typography variant='h1' align='center'>
          Stay Informed & Empowered
        </Typography>
      </Box>
      <WizardNews />
    </DefaultMediaWizardLayout>
  )
}
