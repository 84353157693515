import React from 'react'

import { DefaultMediaWizardLayout } from './DefaultMediaWizardLayout'

import { Partnerships as PartnershipsComponent } from 'components/Partnerships'
import { Breadcrumb } from 'components/Links'

import { useDocumentTitle } from '@percept/hooks'


const breadcrumbs: Breadcrumb[] = [
  {
    path: '/wizard/optimisation',
    name: 'Optimisation',
  },
  {
    path: '/wizard/optimisation/partnerships',
    name: 'Partnerships',
  },
]


export const Partnerships = (): JSX.Element => {
  useDocumentTitle({ paths: ['Optimisation', 'Partnerships'] })
  return (
    <DefaultMediaWizardLayout
      breadcrumbs={breadcrumbs}>

      <PartnershipsComponent />

    </DefaultMediaWizardLayout>
  )
}
