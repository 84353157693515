import React, { useContext } from 'react'
import { Box, Typography } from '@material-ui/core'
import { Loader, makeAppStyles } from '@percept/mui'
import { ReportSchemaService } from '../../api/report-schemas'
import { BrandGrowthPlannerContext } from '../../contexts'
import { ReportSchema } from './components'

const useStyles = makeAppStyles((theme) => ({
  description: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '26px',
  },
  prefillsNote: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
  },
}))

export function SubmitAnalysisPage(): JSX.Element {
  const classes = useStyles()

  const { marketId } = useContext(BrandGrowthPlannerContext)

  const {
    data: reportSchema,
    isLoading: isLoadingReportSchema,
    isError: isErrorReportSchema,
    isIdle: isIdleReportSchema,
  } = ReportSchemaService.useReportSchema(marketId)

  const CONTACT_MAIL = 'kristof.seibt@vodafone.com'

  return (
    <>
      <Typography className={classes.description}>
        Please complete this section to the best of your knowledge where possible -
        note that some data has been pre-filled accordingly.
      </Typography>

      { isIdleReportSchema ? (
        null
      ) : isLoadingReportSchema ? (
        <Loader preset='centered' minHeight='10rem' />
      ) : isErrorReportSchema ? (
        <Box
          display='flex'
          justifyContent='center'
          p={4}>
          <Typography variant='h6'>
            An error occurred loading the form
          </Typography>
        </Box>
      ) : (
        <>
          <Box mt={2}>
            <Typography className={classes.prefillsNote}>
              Please note that data was populated by Group on your behalf - if you
              have more recent data, please contact{' '}
              <a href={`mailto:${CONTACT_MAIL}`}>{CONTACT_MAIL}</a>.
            </Typography>
          </Box>

          <Box mt={4}>
            <ReportSchema reportSchema={reportSchema} />
          </Box>
        </>
      )}

    </>
  )
}
