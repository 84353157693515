
import {
  Reducer,
} from '@percept/types'


const createResetReducer = <T>(
  types: string[] = [],
  reducer: Reducer<T>,
  defaultState: any = undefined
): Reducer<T> => {

  const initialState = (
    typeof defaultState !== 'undefined' ?
      defaultState :
      reducer(undefined, { type: '', payload: null })
  )

  return (state, action, ...args) => {
    if( types && types.indexOf(action.type) !== -1 ){
      return initialState
    }
    
    return reducer(state, action, ...args)
  }
}


export default createResetReducer
