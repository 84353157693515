import {
  ApiResponse,
  PlatformUnit,
  LoginResponse,
  RefreshResponse,
  User,
  UserResponse,
  AssociateSoftwareTokenResponse,
  StateOnlyResponse,
  ApiStatusResponse,
  MFADeliveryMedium,
  BaseAuthResponse,
  SingleSignOnMetadata,
  UserPrivileges
} from '@percept/types'

import { createSelector } from 'reselect'

import { get, some } from 'lodash-es'

import { Selector } from 'react-redux'

import { UserState } from './typings'


type StoreWithUser = {
  user: UserState
}

type UserSelector<TProps, TOwnProps = null> = Selector<StoreWithUser, TProps, TOwnProps>


export const getUserAttributes: UserSelector<User | null> = (state) => (
  state.user.attributes
)

export const getMyUser: UserSelector<ApiResponse<UserResponse>> = state => (
  state.user.me
)

export const getMyUserDetail: UserSelector<ApiResponse<UserResponse>> = state => (
  state.user.detail
)

export const getMyUserPrivileges: UserSelector<ApiResponse<UserPrivileges>> = state => (
  state.user.privileges
)

export const getUserPlatformUnits: UserSelector<ApiResponse<PlatformUnit[]>> = createSelector(
  getMyUser,
  response => ({
    ...response,
    data: response.data && response.data.org_units,
  } as ApiResponse<PlatformUnit[]>)
)

export const getDefaultPlatformUnitId: UserSelector<string | null> = state => (
  state.user.defaultPlatformUnitId
)

export const getUserSignInResponse: UserSelector<ApiResponse<LoginResponse>> = (state) => (
  state.user.signInResponse
)

export const getUserSetInitialPasswordResponse: UserSelector<
  ApiResponse<StateOnlyResponse>
> = state => (
  state.user.setInitialPasswordResponse
)

export const getUserTokenRefreshResponse: UserSelector<ApiResponse<RefreshResponse>> = (state) => (
  state.user.refreshResponse
)

export const getSingleSignOnMetadata: UserSelector<ApiResponse<SingleSignOnMetadata>> = state => (
  state.user.singleSignOnMetadataResponse
)

export const getSingleSignOnCodeExchange: UserSelector<ApiResponse<LoginResponse>> = state => (
  state.user.singleSignOnCodeExchange
)

export const getSingleSignOnRefreshResponse: UserSelector<ApiResponse<RefreshResponse>> = (state) => (
  state.user.singleSignOnRefreshResponse
)


export const getUserIdToken: UserSelector<string | null> = state => (
  state.user.idToken
)

export const getUserRefreshToken: UserSelector<string | null> = state => (
  state.user.refreshToken
)

export const getUserAccessToken: UserSelector<string | null> = state => (
  state.user.accessToken
)

export const getUserId: UserSelector<string | null> = state => (
  state.user.userId
)

export const getUserBeginMFASetupResponse: UserSelector<
  ApiStatusResponse<AssociateSoftwareTokenResponse>
> = state => (
  state.user.mfa.beginSoftwareMfaSetup
)

export const getUserCompleteMFASetupResponse: UserSelector<
  ApiStatusResponse<StateOnlyResponse>
> = state => (
  state.user.mfa.completeSoftwareMfaSetup
)

export const getUserToggleMFAStateResponse: UserSelector<
  ApiStatusResponse<StateOnlyResponse>
> = state => (
  state.user.mfa.toggleSoftwareMfaState
)

export const getUserCodeChallengeResponse: UserSelector<
  ApiResponse<LoginResponse>
> = state => (
  state.user.mfa.codeChallenge
)

export const getUserDerivedMfaDeliveryMedium: UserSelector<
  MFADeliveryMedium | null
> = state => (
  state.user.mfa.derivedActiveDeliveryMedium
)

const EMPTY_ARRAY: unknown[] = []

export const getUserMfaOptions: UserSelector<
  MFADeliveryMedium[]
> = state => (
  get(state.user.detail.data, 'mfa_setting_list') || EMPTY_ARRAY as MFADeliveryMedium[]
)

export const getUserMfaPreference: UserSelector<
  MFADeliveryMedium | null
> = state => (
  get(state.user.detail.data, 'preferred_mfa_setting', null)
)

export const getCanManageMFA: UserSelector<boolean | null> = state => (
  state.user.canManageMFA
)

export const getUserAuthResponse: UserSelector<BaseAuthResponse | null> = createSelector(
  getUserSignInResponse,
  getUserCodeChallengeResponse,
  getUserTokenRefreshResponse,
  getSingleSignOnCodeExchange,
  getSingleSignOnRefreshResponse,
  (signInResponse, codeChallengeResponse, tokenRefreshResponse, ssoCodeExchangeResponse, ssoRefreshResponse) => (
    signInResponse.data ||
    codeChallengeResponse.data ||
    tokenRefreshResponse.data ||
    ssoCodeExchangeResponse.data ||
    ssoRefreshResponse.data
  )
)

export const getUserLoggedIn: UserSelector<boolean> = createSelector(
  getUserAuthResponse,
  Boolean
)

export const getIsLoggedInWithMfaRequirement: UserSelector<boolean> = createSelector(
  getUserSignInResponse,
  getUserCompleteMFASetupResponse,
  getUserToggleMFAStateResponse,
  getUserCodeChallengeResponse,
  getSingleSignOnCodeExchange,
  getSingleSignOnRefreshResponse,
  getUserTokenRefreshResponse,
  (
    signInResponse,
    completeMFAResponse,
    toggleMFAStateResponse,
    codeChallengeResponse,
    ssoCodeExchangeResponse,
    ssoRefreshResponse,
    tokenRefreshResponse,
  ) => {
    const hasLoggedInWithMfa = some([
      codeChallengeResponse.data,
      ssoCodeExchangeResponse.data,
      ssoRefreshResponse.data,
      tokenRefreshResponse.data,
    ])
    if( hasLoggedInWithMfa ){
      return true
    }
    if( signInResponse.data && completeMFAResponse.data && toggleMFAStateResponse.data ){
      return true
    }
    return false
  }
)

export const getUserAdminStatus: UserSelector<boolean | null> = state => {
  const authResponse = getUserAuthResponse(state)
  return authResponse && (authResponse.groups || []).includes('Admins')
}

