
import {
  SET_ACTIVE_SEGMENT,
  CLEAR_ACTIVE_SEGMENT,
} from '../actions'

import { Reducer } from '@percept/types'

import { ClientReportState } from '../typings'


const activeSegment: Reducer<ClientReportState['activeSegment']> = (state = null, action) => {
  
  switch(action.type){
    
    case SET_ACTIVE_SEGMENT:
      return {
        ...(state || {}),
        ...action.payload
      }

    case CLEAR_ACTIVE_SEGMENT:
      return null

    default:
      return state
  
  }
}

export default activeSegment
