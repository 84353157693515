import React from 'react'

import {
  Card,
  CardContent,
  CardContentProps,
  CardProps,
  CardStrip,
  CardStripColorConfiguration,
  IconButtonMenu,
  makeAppStyles,
  ProviderLogo,
  SeriesSelect,
  Typography,
  TypographyProps,
} from '@percept/mui'

import { MoreVert } from '@percept/mui/icons'

import { channelDisplayMap } from '@percept/constants'

import { ChannelKey, ReportProvider, ReportSeries } from '@percept/types'


export type ChannelHeaderProps = (
  CardProps &
  Partial<
    Omit<
      CardStripColorConfiguration,
      'animate'
    >
  > & {
    channel: ChannelKey
    provider?: ReportProvider
    seriesListing?: ReportSeries[]
    onSeriesClick?: (series: ReportSeries) => void
    CardContentProps?: CardContentProps
    TypographyProps?: TypographyProps
    secondaryHeaderContent?: React.ReactNode
  }
)


const useStyles = makeAppStyles( theme => ({
  typography: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 1),
    height: 28,
  },
  seriesSelect: {
    marginLeft: theme.spacing(0.75),
  },
}))


export const ChannelHeader = ({
  channel,
  provider,
  color,
  health,
  children,
  CardContentProps = {},
  TypographyProps = {},
  seriesListing,
  onSeriesClick,
  secondaryHeaderContent,
  ...props
}: ChannelHeaderProps): JSX.Element => {

  const classes = useStyles()

  return (
    <Card {...props}>
      <CardStrip color={color || channel} health={health} />

      <CardContent {...CardContentProps}>

        <Typography
          variant='h4'
          className={classes.typography}
          {...TypographyProps}>

          { provider ? (
            <ProviderLogo
              provider={provider}
              size={1.25} />
          ) : (
            channelDisplayMap[channel].label
          )}

          { seriesListing && (
            <SeriesSelect
              MenuComponent={IconButtonMenu}
              value={undefined}
              series={seriesListing}
              channelFilter={channel}
              label={
                <MoreVert />
              }
              TriggerProps={{
                // disabled: !seriesListing.length,
                className: classes.seriesSelect,
              }}
              onChange={
                onSeriesClick ?
                  ((e, series): void => onSeriesClick(series)) :
                  undefined
              } />
          )}

          { secondaryHeaderContent }

        </Typography>

        { children }

      </CardContent>
    </Card>
  )
}
