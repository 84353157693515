
import { combineReducers, createKeyedReducer } from '@percept/redux/reducers'

import { getAuditKeyFromAction, getFetchTypes } from '@percept/redux/utils'

import { LOAD_METRIC_METADATA } from '../../metricMetadata/actions'

import { mapValues } from '@percept/utils'

import root, { raw as rawAudit } from './root'
import payload from './payload'
import entities from './entities'
import metrics from './metrics'
import filters from './filters'
import entitySort from './entitySort'
import activeMetric from './activeMetric'
import activeSegment from './activeSegment'
import exportOptions from './exportOptions'
import search from './search'
import settings from './settings'


const auditWrapper = combineReducers({
  root, payload, entities, metrics
})

const defaultByIdReducer = createKeyedReducer(
  auditWrapper,
  getAuditKeyFromAction
)

const metadataFetchTypes = getFetchTypes(LOAD_METRIC_METADATA)

const byId = (state = {}, action, ...args) => {
  // Respond to metadata fetch success with all loaded audits,
  // to enable merging metric data when metadata has loaded second.
  if( action.type === metadataFetchTypes.success ){
    return mapValues(
      state,
      s => auditWrapper(s, action)
    )
  }
  // Pass through all arguments, metricMetadata is passed in within
  // a third argument when loaded first
  return defaultByIdReducer(state, action, ...args)
}


export default combineReducers({
  byId,
  filters,
  entitySort,
  activeMetric,
  activeSegment,
  exportOptions,
  search,
  settings,
})

export const raw = combineReducers({
  byId: createKeyedReducer(rawAudit, getAuditKeyFromAction),
  filters,
  entitySort,
  activeMetric,
  activeSegment,
  exportOptions,
  search,
  settings,
})
