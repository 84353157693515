
export const LOAD_JOBS = 'LOAD_JOBS'

export const LOAD_JOB_PAYLOAD = 'LOAD_JOB_PAYLOAD'

export const LOAD_JOB_ENTITY = 'LOAD_JOB_ENTITY'

export const LOAD_JOB_METRIC = 'LOAD_JOB_METRIC'


export const loadJobs = () => ({
  type: LOAD_JOBS,
  resource: '/jobs'
})

export const loadJobPayload = ({ series_id, report_id, job_id }) => ({
  type: LOAD_JOB_PAYLOAD,
  resource: `/jobs/${job_id}/payload`,
  meta: { series_id, report_id, job_id }
})

export const loadJobEntity = ({ series_id, report_id, job_id, entity_type, entity_id }) => ({
  type: LOAD_JOB_ENTITY,
  resource: `/jobs/${job_id}/payload`,
  options: {
    params: {
      'entity-type': entity_type,
      'entity-id': entity_id
    }
  },
  meta: { series_id, report_id, job_id, entity_type, entity_id }
})

export const loadJobMetric = ({ series_id, report_id, job_id, entity_type, entity_id, metric_id, dimension }) => ({
  type: LOAD_JOB_METRIC,
  resource: `/jobs/${job_id}/payload`,
  options: {
    params: {
      'entity-type': entity_type,
      'entity-id': entity_id,
      'metric-id': metric_id,
      'metric-agg': dimension
    }
  },
  meta: { series_id, report_id, job_id, entity_type, entity_id, metric_id, dimension }
})
