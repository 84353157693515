
import React, { Fragment, useState } from 'react'

import { IconButton, IconButtonProps, Popover, PopoverProps } from '@material-ui/core'

import { Menu, SvgIconComponent } from '../../icons'


export type MetricActionPopoverProps = {
  children: (
    React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[] |
    ((props: { onClose: () => void }) => JSX.Element)
  )
  IconComponent?: SvgIconComponent
  IconButtonProps?: Partial<IconButtonProps>
  PopoverProps?: Partial<PopoverProps>
}

export const MetricActionPopover = ({
  children,
  IconComponent = Menu,
  IconButtonProps = {},
  PopoverProps = {},
}: MetricActionPopoverProps): JSX.Element => {

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const onClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <IconButton
        {...IconButtonProps}
        onClick={(e): void => {
          setAnchorEl(e.currentTarget)
        }}>
        <IconComponent />
      </IconButton>

      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        {...PopoverProps}
        onClose={onClose}>
        { typeof children === 'function' ? children({ onClose }) : children }
      </Popover>

    </Fragment>
  )
}

export default MetricActionPopover
