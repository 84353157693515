
import { combineReducers } from 'redux'

import byId from './byId'
import activeProvider from './activeProvider'
import timeSeries from './timeSeries'
import settings from './settings'

import { Reducer } from '@percept/types'

import { SeriesState } from '../typings'


const clientSeries: Reducer<SeriesState> = combineReducers({
  byId,
  activeProvider,
  timeSeries,
  settings,
})

export default clientSeries