import React from 'react'

import { MultiSelectBase, MultiSelectBaseProps } from './MultiSelectBase'

import { PlatformUnit } from '@percept/types'


export type MultiPlatformUnitSelectProps = Omit<
  MultiSelectBaseProps<PlatformUnit>, 'selectPrompt' | 'itemLabel' | 'getSortValue'
>

const getPlatformUnitSortValue = (platformUnit: PlatformUnit): string => platformUnit.id

const renderPlatformUnit = (platformUnit: PlatformUnit): string => platformUnit.name

const arePlatformUnitsEqual = (a: PlatformUnit, b: PlatformUnit): boolean => (
  a.id === b.id
)

export const MultiPlatformUnitSelect = (props: MultiPlatformUnitSelectProps): JSX.Element => {
  return (
    <MultiSelectBase
      itemLabel='Organisation'
      selectPrompt='Select an organisation'
      renderItem={renderPlatformUnit}
      getSortValue={getPlatformUnitSortValue}
      areValuesEqual={arePlatformUnitsEqual}
      {...props} />
  )
}
