import React from 'react'

import { ButtonProps, MenuAbstractionProps, MenuOption, ProviderLogo, RoundedPlainTextButtonMenu } from '@percept/mui'
import { ArrowDropDown } from '@percept/mui/icons'

import { AdvertisingPartner } from '@percept/types'
import { capitalize } from 'lodash-es'
import { PartnerCategory } from './types'


export type CategorySelectProps = (
  Pick<
    MenuAbstractionProps<ButtonProps, PartnerCategory>,
    'value' | 'onChange'
  >
  & Partial<Omit<MenuAbstractionProps<ButtonProps, PartnerCategory>, 'children'>>
  & {
    emptySelectLabel?: React.ReactNode
  }
)


const partners: AdvertisingPartner[] = [
  'GOOGLE',
  'META',
  'TIKTOK',
  'AMAZON',
  'SPOTIFY',
  'TEADS',
]

const additionalOptions: MenuOption<PartnerCategory>[] = [
  { value: 'GENERAL', label: 'General' }
]


export const isAdvertisingPartner = (value: string): value is AdvertisingPartner => partners.includes(value as AdvertisingPartner)

export const CategorySelect = ({
  value,
  emptySelectLabel = 'Select Category',
  ...props
}: CategorySelectProps): JSX.Element => {
  return (
    <RoundedPlainTextButtonMenu
      TriggerProps={{
        color: 'primary',
        variant: 'contained',
        size: 'medium',
        endIcon: <ArrowDropDown />,
      }}
      value={value}
      label={
        value && isAdvertisingPartner(value) ?
          <ProviderLogo size={1.65} provider={value} /> :
          value ?
            capitalize(value) :
            emptySelectLabel
      }
      options={
        [
          { value: undefined, label: emptySelectLabel },
          ...additionalOptions,
          ...partners.map( value  => ({
            value,
            label: <ProviderLogo size={1.65} provider={value} />,
          }))
        ]
      }
      {...props} 
    />
  )
}
