import React from 'react'

import { DefaultLayout } from './DefaultLayout'

import { DataWarehouse } from '../components/DataWarehouse'


export const DataWarehouseScreen = (): JSX.Element => {
  return (
    <DefaultLayout>
      <DataWarehouse />
    </DefaultLayout>
  )
}

export default DataWarehouseScreen
