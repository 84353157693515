import React from 'react'

import { Link } from 'react-router-dom'

import { PlainTextButton, ButtonProps, makeAppStyles } from '@percept/mui'
import { ChevronRight, Schedule } from '@percept/mui/icons'


export type LinkButtonProps = (
  Omit<ButtonProps, 'onClick'> & {
    to: string
    dummy?: boolean
    comingSoon?: boolean
    openInNewWindow?: boolean
  }
)


const useStyles = makeAppStyles( theme => ({
  button: {
    height: 62,
    fontSize: 22,
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  endIcon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(0.5),
    fontSize: '27px !important',
    borderRadius: '100%',
    stroke: 'none',
  },
  active: {
    color: theme.palette.primary.main,
    backgroundColor: 'white',
  }
}))


export const LinkButton = ({
  to,
  children = 'Show me more',
  className = '',
  dummy = false,
  openInNewWindow = false,
  comingSoon = false,
  ...props
}: LinkButtonProps): JSX.Element => {
  const classes = useStyles()

  let buttonClassName = classes.button
  if( comingSoon ){
    buttonClassName += (' ' + classes.disabled)
  }
  if( !openInNewWindow ){
    buttonClassName += (' ' + className)
  }

  const buttonProps: ButtonProps = comingSoon ? {
    color: 'default',
    endIcon: <Schedule fontSize='large' className={classes.endIcon} />
  } : {
    endIcon: <ChevronRight fontSize='large' className={`${classes.endIcon} ${classes.active}`} />
  }

  children = comingSoon ? 'Coming soon' : children

  const button = (
    <PlainTextButton
      className={buttonClassName}
      variant='contained'
      color='primary'
      size='large'
      {...buttonProps}
      {...props}>
      { children }
    </PlainTextButton>
  )

  if( dummy ){
    return button
  }

  if( openInNewWindow ){
    return (
      <a
        className={className}
        href={to}
        target='_blank'
        rel='noopener noreferrer'>
        { button }
      </a>
    )
  }

  return (
    <Link
      to={to}>
      { button }
    </Link>
  )
}
