
import React from 'react'

import { Backdrop, BackdropProps, Box, BoxProps, CircularProgress, CircularProgressProps } from '@material-ui/core'

import { makeAppStyles } from '../../themes'


type LoaderPreset = 'fullsize' | 'fullscreen' | 'centered' | 'inline'

const fillAvailableSpace: Partial<BoxProps> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  width: '100%',
  height: '100%',
}

const boxPresets: Partial<Record<LoaderPreset, Partial<BoxProps>>> = {
  fullsize: {
    ...fillAvailableSpace,
  },
  fullscreen: {
    ...fillAvailableSpace,
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inline: {
    display: 'inline-flex',
    alignItems: 'center',
  }
}

const loaderPresets: Partial<Record<LoaderPreset, Partial<CircularProgressProps>>> = {
  fullsize: {
    size: '3rem',
  },
  fullscreen: {
    size: '3rem'
  },
  centered: {
    size: '3rem',
  },
  inline: {
    size: '1em',
  },
}


export type LoaderProps = Omit<BoxProps, 'color'> & Pick<CircularProgressProps, 'variant' | 'size' | 'color'> & {
  preset?: LoaderPreset
}


export const Loader = ({ size, color, preset, variant, children, ...boxProps }: LoaderProps): JSX.Element => {

  const boxPreset = preset && boxPresets[preset]

  const loaderPreset = preset && loaderPresets[preset]

  return (
    <Box
      display='flex'
      flexDirection='column'
      {...(boxPreset || {})}
      {...boxProps}>

      <CircularProgress
        {...(loaderPreset || { size })}
        variant={variant}
        color={color} />

      { children }

    </Box>
  )
}


export type BackdropLoaderProps = LoaderProps & {
  BackdropProps?: Partial<BackdropProps>
}


const useBackdropClasses = makeAppStyles( theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    pointerEvents: 'none',
  },
}) )


export const BackdropLoader = ({ BackdropProps = {}, ...LoaderProps }: BackdropLoaderProps): JSX.Element => (
  <Backdrop
    open
    classes={useBackdropClasses()}
    {...BackdropProps}
    unmountOnExit>
    <Loader
      preset='fullscreen'
      size='6rem'
      {...LoaderProps} />
  </Backdrop>
)
