import React from 'react'

import { sum, uniq } from 'lodash-es'

import { format as formatDate } from 'date-fns'

import {
  Box,
  Checkbox,
  DateRangeInput,
  DateRangeValue,
  formatMoney,
  FormLabel,
  ListItemText,
  MenuItem,
  RangeSlider,
  RangeSliderValue,
  Select,
} from '@percept/mui'

import { percentageFormatter } from '@percept/mui/charts'

import { providerRenderer } from './defaultRenderers'

import { capitalize } from 'lodash-es'

import { displayNumber } from '@percept/utils'

import { MUIDataTableColumnOptions } from 'mui-datatables'

import { ReportProvider } from '@percept/types'
import { reportProviders } from '@percept/constants'


const formLabelStyle: React.CSSProperties = {
  fontSize: 10.5,
}


const moneyFormatter = (
  amount: number | string | null | undefined,
  currency: string | null | undefined,
): string => {
  amount = Number(amount)
  const precision = amount !== 0 && amount < 100 ? 2 : 0
  return formatMoney({ amount, currency, precision })
}


export const rangeCustomFilterListOptions: MUIDataTableColumnOptions['customFilterListOptions'] = {
  render: (value) => value.join(' – '),
}

export const percentageRangeCustomFilterListOptions: MUIDataTableColumnOptions['customFilterListOptions'] = {
  render: (value) => value.map(percentageFormatter).join(' – '),
}


export const makeCurrencyRangeCustomFilterListOptions = (
  currency: string | null | undefined
): MUIDataTableColumnOptions['customFilterListOptions'] => {
  return {
    render: (value: string[]): string => (
      uniq(value).map( amount =>
        moneyFormatter(amount, currency)
      ).join(' – ')
    ),
  }
}


export const dateRangeCustomFilterListOptions: MUIDataTableColumnOptions['customFilterListOptions'] = {
  render: (value: string[]): string => (
    uniq(value).map( d =>
      formatDate(Number(d), 'dd/MM/yy')
    ).join(' – ')
  ),
}


export const providerCustomFilterListOptions: MUIDataTableColumnOptions['customFilterListOptions'] = {
  render: (value: ReportProvider): React.ReactNode => (
    providerRenderer(value)
  ),
}

export const __providerCustomFilterListOptions: MUIDataTableColumnOptions['customFilterListOptions'] = {
  render: (value: ReportProvider): string => (
    value === 'adwords' ? 'Google Ads' : capitalize(value)
  ),
}

export const providerFilterOptions: MUIDataTableColumnOptions['filterOptions'] = {
  // eslint-disable-next-line react/display-name
  display: (filterList, onChange, index, column): JSX.Element => {
    return (
      <div>
        <FormLabel style={formLabelStyle}>{ column.name }</FormLabel>
        <Select
          multiple
          value={filterList[index]}
          placeholder='Select provider'
          renderValue={(value): React.ReactNode => providerRenderer(value as ReportProvider)}
          onChange={(event): void => {
            onChange(event.target.value as string, index, column)
          }}>
          { reportProviders.map( item => (
            <MenuItem key={item} value={item}>
              <Checkbox
                color='primary'
                checked={filterList[index].indexOf(item) > -1}
              />
              <ListItemText
                primary={
                  providerRenderer(item)
                } />
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  },
}


export const rangeFilterOptions: MUIDataTableColumnOptions['filterOptions'] = {
  names: [],
  logic(value, filters): boolean {
    const min = Number(filters[0]), max = Number(filters[1])
    const numberValue = Number(value)
    const shouldFilter = (numberValue < min || numberValue > max)
    return shouldFilter
  },
}


export const makeCurrencyRangeFilterOptions = (
  domain: RangeSliderValue,
  currency: string | null | undefined
): MUIDataTableColumnOptions['filterOptions'] => {
  return {
    ...rangeFilterOptions,
    display(filterList, onChange, index, column): JSX.Element {
      const max = domain[1]
      const precision = max !== 0 && max < 100 ? 2 : 0
      const disabled = sum(domain) === 0
      return (
        <div>
          <FormLabel style={formLabelStyle}>{ column.name }</FormLabel>
          <Box
            mx={1}>
            <RangeSlider
              value={
                filterList[index].length ?
                  filterList[index].map(Number) as RangeSliderValue :
                  domain
              }
              disabled={disabled}
              domain={domain}
              step={precision === 2 ? 0.01 : 1}
              valueLabelFormat={(amount): string => (
                formatMoney({ amount, currency, precision: amount === 0 ? 0 : precision })
              )}
              onChange={(e, value): void => {
                onChange((value as number[]).map(String), index, column)
              }} />
          </Box>
        </div>
      )
    }
  }
}


export const makeRangeFilterOptions = (
  domain: RangeSliderValue,
  formatter?: (n: number) => React.ReactNode
): MUIDataTableColumnOptions['filterOptions'] => {
  return {
    ...rangeFilterOptions,
    display(filterList, onChange, index, column): JSX.Element {
      const disabled = sum(domain) === 0
      return (
        <div>
          <FormLabel style={formLabelStyle}>{ column.name }</FormLabel>
          <Box
            mx={1}>
            <RangeSlider
              value={
                filterList[index].length ?
                  filterList[index].map(Number) as RangeSliderValue :
                  domain
              }
              disabled={disabled}
              domain={domain}
              valueLabelFormat={formatter || displayNumber}
              onChange={(e, value): void => {
                onChange((value as number[]).map(String), index, column)
              }} />
          </Box>
        </div>
      )
    }
  }
}


export const makeDateFilterOptions = (domain: DateRangeValue): MUIDataTableColumnOptions['filterOptions'] => {
  return {
    ...rangeFilterOptions,
    logic(value, filters): boolean {
      const min = Number(filters[0]), max = Number(filters[1])
      const numberValue = new Date(value).getTime()
      const shouldFilter = (numberValue < min || numberValue > max)
      return shouldFilter
    },
    display(filterList, onChange, index, column): JSX.Element {
      const disabled = domain[0] === domain[1]
      return (
        <DateRangeInput
          name='date-range'
          label={column.name}
          value={
            filterList[index].length ?
              filterList[index].map(Number) as DateRangeValue :
              domain
          }
          disabled={disabled}
          domain={domain}
          onChange={(dates): void => {
            onChange((dates as number[]).map(String), index, column)
          }} />
      )
    }
  }
}
