import { Selector } from 'react-redux'

import { createSelector } from 'reselect'

import { NotificationState } from './typings'

import { Notification, Toast } from '@percept/types'


type StoreWithNotifications = {
  notifications: NotificationState
}

type NotificationsSelector<V, P = undefined> = (
  Selector<StoreWithNotifications, V, P>
)

export const getToasts: NotificationsSelector<Toast[]> = createSelector(
  (state) => state.notifications.toasts,
  ({ byId, ids }) => (
    ids.map( id => byId[id] )
  )
)


export const getNotifications: NotificationsSelector<Notification[]> = createSelector(
  (state) => state.notifications.notifications,
  ({ byId, ids }) => (
    ids.map( id => byId[id] )
  )
)
