import {
  deslugify,
  getConversionRate,
  getCPA,
  getCPC,
  getCPM,
  getCPV,
  getCTR,
  isArray,
  mapKeys,
} from '@percept/utils'

import { sortBy, uniqBy } from 'lodash-es'

import { dimensionMap } from '@percept/constants'

import {
  Dictionary,
  EntityType,
  PerformanceSummary,
  PerformanceReportingDimension,
  PerformanceValue,
  ReportProvider,
  PerformanceDimensionType,
  DimensionType,
} from '@percept/types'


export type ExampleType = {
  id: string | number,
  type: EntityType,
  name: string,
}


export type PerfColumns = (
  'currency' | 'cost' | 'impressions' | 'views' | 'clicks' | 'conversions'
)

export type DerivedPerfColumns = (
  'cpa' | 'cpc' | 'ctr' | 'cpm' | 'conversion_rate' | 'cpv'
)

export type BaseExampleColumns = PerfColumns | DerivedPerfColumns | (
  'id' | 'name' | 'account_id' | 'account_name' | 'campaign_id' | 'campaign_name' |
  // Include sentinel '__SEGMENT__' key for distribution collation
  '__SEGMENT__'
)

export type GoogleAdsColumns = BaseExampleColumns | (
  'adgroup_id' | 'adgroup_name'
)

export type FacebookColumns = BaseExampleColumns | (
  'adset_id' | 'adset_name' | 'ad_id' | 'ad_name'
)

export type AdformColumns = BaseExampleColumns

export type DV360Columns = BaseExampleColumns | (
  'insertionorder_id' | 'insertionorder_name' | 'lineitem_id' | 'lineitem_name'
)


export type ExampleColumn = (
  GoogleAdsColumns | FacebookColumns | AdformColumns | DV360Columns
)


export type ExampleRow = Partial<
  Record<ExampleColumn | string, string | number | null>
>

const baseColumns: BaseExampleColumns[] = [
  'name',
  '__SEGMENT__',
  'id',
  'account_id',
  'account_name',
  'campaign_id',
  'campaign_name',
]

const perfColumns: PerfColumns[] = [
  'currency',
  'cost',
  'impressions',
  'clicks',
  'conversions',
]

const perfColumnsWithViews: PerfColumns[] = [
  'currency',
  'cost',
  'impressions',
  'views',
  'clicks',
  'conversions',
]

const derivedPerfColumns: DerivedPerfColumns[] = [
  'cpm',
  'cpc',
  'ctr',
  'cpa',
  'conversion_rate',
]

const derivedPerfColumnsWithViews: DerivedPerfColumns[] = [
  'cpm',
  'cpv',
  'cpc',
  'ctr',
  'cpa',
  'conversion_rate',
]

export const providerColumnMap: Record<
  ReportProvider, ExampleColumn[]
> = {
  adwords: [
    ...baseColumns,
    'adgroup_id',
    'adgroup_name',
    ...perfColumns,
    ...derivedPerfColumns,
  ],
  facebook: [
    ...baseColumns,
    'adset_id',
    'adset_name',
    'ad_id',
    'ad_name',
    ...perfColumnsWithViews,
    ...derivedPerfColumnsWithViews,
  ],
  adform: [
    ...baseColumns,
    ...perfColumns,
    ...derivedPerfColumns,
  ],
  dv360: [
    ...baseColumns,
    'insertionorder_id',
    'insertionorder_name',
    'lineitem_id',
    'lineitem_name',
    ...perfColumns,
    ...derivedPerfColumns,
  ]
}

export const exampleColumns: ExampleColumn[] = [
  'name',
  'id',
  '__SEGMENT__',
  'account_id',
  'account_name',
  'campaign_id',
  'campaign_name',
  'adgroup_id',
  'adgroup_name',
  'adset_id',
  'adset_name',
  'ad_id',
  'ad_name',
  'insertionorder_id',
  'insertionorder_name',
  'lineitem_id',
  'lineitem_name',
  ...perfColumnsWithViews,
  ...derivedPerfColumnsWithViews,
]


const dimensions: PerformanceDimensionType[] = [
  'cost', 'impressions', 'clicks', 'conversions',
]

export const dimensionLabelMappings: Record<PerformanceDimensionType, string> = (
  dimensions.reduce( (acc, dimension) => {
    const labelMapping = dimensionMap[dimension] || { text: deslugify(dimension) }
    acc[dimension] = labelMapping.abbreviatedText || labelMapping.text
    return acc
  }, {} as Record<PerformanceDimensionType, string>)
)


export const exampleColumnMappings: Record<ExampleColumn, string> = {
  'id': 'ID',
  'name': 'Name',
  '__SEGMENT__': 'Segment',
  'account_id': 'Account ID',
  'account_name': 'Account',
  'campaign_id': 'Campaign ID',
  'campaign_name': 'Campaign',
  'adgroup_id': 'Ad Group ID',
  'adgroup_name': 'Ad Group',
  'adset_id': 'Adset ID',
  'adset_name': 'Adset',
  'ad_id': 'Ad ID',
  'ad_name': 'Ad',
  'insertionorder_id': 'Insertion Order ID',
  'insertionorder_name': 'Insertion Order',
  'lineitem_id': 'Line Item ID',
  'lineitem_name': 'Line Item',
  'currency': 'Currency',
  'cpm': 'CPM',
  'cpv': 'CPV',
  'cpc': 'CPC',
  'ctr': 'CTR',
  'cpa': 'CPA',
  'conversion_rate': 'Conv. Rate',
  ...dimensionLabelMappings,
}


export type Item = (
  {
    hierarchy: ExampleType[]
    id: string | number
    name: string
    type: EntityType,
    performance: PerformanceSummary
    attributes?: Dictionary
  } & (
    Record<
      PerformanceReportingDimension, PerformanceValue
    >
  )
)


export const getCleanAttribute = (attribute: string): string => attribute.replace(/^attribute\|/, '')


type RowMapperOptions = {
  activePerformanceTail: string | null | undefined
  extraAttributes?: Dictionary
}


const resolvePerformance = (
  performance: PerformanceSummary,
  performanceTail: string | null | undefined
): PerformanceSummary => {

  if( !(performance) ){
    return performance
  }

  const canonicalPerformance = (
    performanceTail ? {
      ...performance,
      ...(dimensions.reduce( (acc, dimension) => {
        const resolvedDimension = `${dimension}_${performanceTail}`
        acc[dimension] = performance[resolvedDimension]
        return acc
      }, {} as Record<DimensionType, PerformanceValue>)),
    } : performance
  )

  return {
    ...canonicalPerformance,
    cpc: getCPC(canonicalPerformance),
    ctr: getCTR(canonicalPerformance),
    cpa: getCPA(canonicalPerformance),
    cpm: getCPM(canonicalPerformance),
    ...(typeof canonicalPerformance.views !== 'undefined' && {
      cpv: getCPV(canonicalPerformance),
    } || {}),
    conversion_rate: getConversionRate(canonicalPerformance),
  }
}


export const exampleToRow = (
  { hierarchy = [], id, name, performance, attributes, ...rest }: Item,
  { activePerformanceTail, extraAttributes = {} }: RowMapperOptions
): ExampleRow => {
  
  const output = {
    id,
    name,
    ...rest,
    ...resolvePerformance(performance, activePerformanceTail),
    ...mapKeys(attributes || {}, getCleanAttribute),
    ...(extraAttributes),
  } as Partial<ExampleRow>

  hierarchy.forEach( item => {
    const { type } = item
    const prefix = type.toLowerCase().replace(/\s+/g, '')
    output[`${prefix}_id`] = item.id
    output[`${prefix}_name`] = item.name
  })

  return output as ExampleRow

}


const itemToString = (item: string | number | string[] | null | undefined): string => {
  let output = ''
  if( typeof item === 'string' ){
    output = item
  }
  if( typeof item === 'number' ){
    output = String(item)
  }
  if( isArray(item) ){
    output = item.join(', ')
  }
  if( /[/,]/.test(output) ){
    return `"${output}"`
  }
  return output
}


export type DataFrame = {
  fieldnames: string[]
  displayFieldnames: string[]
  rows: (string | number | null | undefined)[][]
}

const allPerfColumns = [...perfColumnsWithViews, ...derivedPerfColumns]

export const dataToDataFrame = ({
  rows,
  columns = [],
  disablePerformanceColumns = false,
}: {
  rows: Record<string, number | null | string | undefined>[],
  columns?: { key: string, text: string }[],
  disablePerformanceColumns?: boolean
}): DataFrame => {
  // Determine applicable column names in order from first row
  const firstRow = rows[0]

  const activeColumns = exampleColumns.reduce( (acc, col) => {
    if( firstRow && typeof firstRow[col] !== 'undefined' ){
      acc.push({
        key: col,
        text: exampleColumnMappings[col] || col,
      })
    }
    return acc
  }, [] as { key: string, text: string }[])
  
  const customColumns = columns.reduce( (acc, column) => {
    if( firstRow && typeof firstRow[column.key] !== 'undefined' ){
      acc.push(column)
    }
    return acc
  }, [] as { key: string, text: string }[])

  let uniqColumns = uniqBy([
    ...activeColumns,
    ...sortBy(customColumns, 'text'),
  ], 'key')
  
  if( disablePerformanceColumns ){
    uniqColumns = uniqColumns.filter( c => !allPerfColumns.includes(c.key as PerfColumns | DerivedPerfColumns) )
  }

  return {
    fieldnames: uniqColumns.map( c => c.key ),
    displayFieldnames: uniqColumns.map( c => c.text ),
    rows: rows.map( item =>
      uniqColumns.map( col => item[col.key] )
    )
  }
}


export const dataToCSV = ({
  rows,
  columns,
}: {
  rows: Record<string, number | null | string>[],
  columns: { key: string, text: string }[]
}): string => {
  // Determine applicable column names in order from first row
  const {
    displayFieldnames,
    rows: frameRows,
  } = dataToDataFrame({
    rows,
    columns,
  })

  return [
    displayFieldnames.join(', '),
    ...(
      frameRows.map( frame => (
        frame.map(itemToString).join(', ')
      ))
    )
  ].join('\n')

}
