import {
  AddCircleOutline,
  Visibility,
  MonetizationOn,
  Mouse,
  Shop,
  MonetizationOnOutlined,
  MouseOutlined,
  SvgIconComponent,
  DoubleVerifyIcon,
} from '.'

import { AnyPerformanceDimension, DimensionType } from '@percept/types'


export const dimensionIcons: Record<AnyPerformanceDimension | DimensionType, SvgIconComponent> = {
  cost: MonetizationOn,
  count: AddCircleOutline,
  conversions: Shop,
  clicks: Mouse,
  impressions: Visibility,
  views: Visibility,
  cpc: MonetizationOnOutlined,
  ctr: MouseOutlined,
  cpa: MonetizationOnOutlined,
  cpm: MonetizationOnOutlined,
  cpv: MonetizationOnOutlined,
  // DoubleVerify
  authentic_ads: DoubleVerifyIcon,
  authentic_impressions: DoubleVerifyIcon,
  authentic_rate: DoubleVerifyIcon,
  authentic_video_viewable_impressions: DoubleVerifyIcon,
  authentic_video_viewable_rate: DoubleVerifyIcon,
  block_rate: DoubleVerifyIcon,
  blocks: DoubleVerifyIcon,
  brand_safe_ads: DoubleVerifyIcon,
  brand_safe_rate: DoubleVerifyIcon,
  brand_safety_rate: DoubleVerifyIcon,
  display_viewable_impressions: DoubleVerifyIcon,
  display_viewable_rate: DoubleVerifyIcon,
  fraud_free_rate: DoubleVerifyIcon,
  fraudsivt_free_ads: DoubleVerifyIcon,
  fraudsivt_free_impressions: DoubleVerifyIcon,
  in_geo_ads: DoubleVerifyIcon,
  in_geo_rate: DoubleVerifyIcon,
  monitored_ads: DoubleVerifyIcon,
  monitored_impressions: DoubleVerifyIcon,
  suitable_rate: DoubleVerifyIcon,
  video_authentic_impressions: DoubleVerifyIcon,
  video_authentic_rate: DoubleVerifyIcon,
  video_viewable_impressions: DoubleVerifyIcon,
  viewable_impressions: DoubleVerifyIcon,
  viewablity_video_rate: DoubleVerifyIcon,
  yt_brand_safe_impressions: DoubleVerifyIcon,
  yt_ss_measured_impressions: DoubleVerifyIcon,
  yt_suitable_impressions: DoubleVerifyIcon,
  // Structural
  unique_daily_campaigns_max: AddCircleOutline,
  unique_daily_campaigns_mean: AddCircleOutline,
  unique_daily_campaigns_min: AddCircleOutline,
}
