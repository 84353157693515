
import {
  isObject,
  getPath
} from '@percept/utils'

import {
  Dictionary,
  NormalizedError,
} from '@percept/types'


export const normalizeErrorResponse = (
  errorResponse: Dictionary,
  params: Dictionary = {}
): NormalizedError => {

  const error = (
    errorResponse.data && errorResponse.data.data ?
      errorResponse.data.data :
      errorResponse.data ?
        errorResponse.data :
        errorResponse
  )

  const status = Number(errorResponse && errorResponse.status)
  const detail = getPath(error, ['response', 'data', 'detail']) as string | Dictionary

  const message = (
    typeof error === 'string' ?
      error :
      !error ?
        'An error occurred' :
        typeof detail === 'string' ?
          detail :
          detail && typeof detail.message === 'string' ?
            detail.message :
            detail && typeof detail.msg === 'string' ?
              detail.msg :
              error.message ?
                error.message :
                error.msg ?
                  error.msg :
                  'An error occurred'
  )

  return {
    message,
    status,
    ...(
      isObject(getPath(error, ['response', 'data'])) ?
        error.response.data : (error && error.response || {})
    ),
    ...(error && error.originalError && {
      name: getPath(error, ['originalError', 'title'], '')
    } || {}),
    ...(params || {}),
  }

}
