import React from 'react'

import { DefaultMediaWizardLayout } from './DefaultMediaWizardLayout'

import { MediaMixModelling as MediaMixModellingComponent } from 'components/MediaMixModelling'
import { Breadcrumb } from 'components/Links'

import { useDocumentTitle } from '@percept/hooks'

const breadcrumbs: Breadcrumb[] = [
  {
    path: '/wizard/strategy',
    name: 'Strategy',
  },
  {
    path: '/wizard/strategy/media-mix-modelling',
    name: 'Media Mix Modelling',
  },
]

export const MediaMixModelling = (): JSX.Element => {
  useDocumentTitle({ paths: ['Strategy', 'Media Mix Modelling'] })
  return (
    <DefaultMediaWizardLayout
      breadcrumbs={breadcrumbs}>

      <MediaMixModellingComponent />

    </DefaultMediaWizardLayout>
  )
}
