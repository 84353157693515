import { Reducer } from 'redux'

import {
  SET_APP_THEME_BRANDING,
  SET_APP_THEME_TYPE_PREFERENCE
} from './actions'

import { ReduxAction } from '@percept/types'

import { ThemingState } from './typings'

import { noop } from 'lodash-es'


const themePreferenceKey = 'THEME_PREFERENCE'

const themePreferenceOptions: ThemingState['themePreference'][] = [
  'DARK', 'LIGHT', 'AUTO'
]

const isThemePreference = (value: string): value is ThemingState['themePreference'] => (
  themePreferenceOptions.includes(value as ThemingState['themePreference'])
)

const getStoredThemePreference = (): ThemingState['themePreference'] => {
  const defaultValue: ThemingState['themePreference'] = 'AUTO'
  try{
    const storedValue =  localStorage.getItem(themePreferenceKey)
    if( storedValue && isThemePreference(storedValue) ){
      return storedValue
    }
    return defaultValue
  }catch(e){
    return defaultValue
  }
}


const initialState: ThemingState = {
  themeBranding: process.env.BRANDING,
  themePreference: getStoredThemePreference(),
}

const reducer: Reducer<ThemingState, ReduxAction> = (state = initialState, action) => {
  switch(action.type){
    case SET_APP_THEME_TYPE_PREFERENCE:
      try{
        localStorage.setItem(themePreferenceKey, action.payload)
      }catch(e){
        noop()
      }
      return {
        ...state,
        themePreference: action.payload,
      }
    
    case SET_APP_THEME_BRANDING:
      return {
        ...state,
        themeBranding: action.payload,
      }

    default: return state
  }
}

export default reducer
