
import { actionCreator } from '@percept/redux'

import {
  AsyncReduxAction,
  ReportParams,
  EntityParams,
  ReportMetricParams,
} from '@percept/types'


export const LOAD_CLIENT_REPORT = 'LOAD_CLIENT_REPORT'
export const LOAD_CLIENT_REPORT_PAYLOAD = 'LOAD_CLIENT_REPORT_PAYLOAD'
export const LOAD_CLIENT_REPORT_ENTITY = 'LOAD_CLIENT_REPORT_ENTITY'
export const LOAD_CLIENT_REPORT_METRIC = 'LOAD_CLIENT_REPORT_METRIC'

export const FILTER_CLIENT_REPORT_ENTITIES = 'FILTER_CLIENT_REPORT_ENTITIES'
export const CLEAR_CLIENT_REPORT_ENTITY_FILTER = 'CLEAR_CLIENT_REPORT_ENTITY_FILTER'

export const SORT_CLIENT_REPORT_ENTITIES = 'SORT_CLIENT_REPORT_ENTITIES'

export const SET_ACTIVE_SEGMENT = 'SET_ACTIVE_SEGMENT'
export const CLEAR_ACTIVE_SEGMENT = 'CLEAR_ACTIVE_SEGMENT'

export const SET_ACTIVE_METRIC = 'SET_ACTIVE_METRIC'
export const CLEAR_ACTIVE_METRIC = 'CLEAR_ACTIVE_METRIC'

export const SET_EXPORT_METRIC = 'SET_EXPORT_METRIC'
export const CLEAR_EXPORT_METRIC = 'CLEAR_EXPORT_METRIC'
export const SET_EXPORT_TYPE = 'SET_EXPORT_TYPE'
export const SET_EXPORT_OPTIONS = 'SET_EXPORT_OPTIONS'

export const SET_DISPLAY_TYPE = 'SET_DISPLAY_TYPE'
export const SET_METRIC_ORDER = 'SET_METRIC_ORDER'

export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'
export const SAVE_SEARCH_QUERY = 'SAVE_SEARCH_QUERY'


export const loadClientReport = ({ report_id }: ReportParams): AsyncReduxAction => ({
  type: LOAD_CLIENT_REPORT,
  resource: `/reports/${report_id}`,
  meta: { report_id }
})

export const loadClientReportPayload = ({ report_id }: ReportParams): AsyncReduxAction => ({
  type: LOAD_CLIENT_REPORT_PAYLOAD,
  resource: `/reports/${report_id}/payload`,
  meta: { report_id }
})

export const loadClientReportEntity = (
  { report_id, entity_type, entity_id }: ReportParams & EntityParams
): AsyncReduxAction => ({
  type: LOAD_CLIENT_REPORT_ENTITY,
  resource: `/reports/${report_id}/payload`,
  options: {
    params: {
      'entity-type': entity_type.toLowerCase(),
      'entity-id': entity_id
    }
  },
  meta: { report_id, entity_type, entity_id }
})

export const loadClientReportMetric = (
  { report_id, entity_type, entity_id, metric_id, dimension }: ReportMetricParams
): AsyncReduxAction => ({
  type: LOAD_CLIENT_REPORT_METRIC,
  resource: `/reports/${report_id}/payload`,
  options: {
    params: {
      'entity-type': entity_type.toLowerCase(),
      'entity-id': entity_id,
      'metric-id': metric_id,
      'metric-agg': dimension
    }
  },
  meta: { report_id, entity_type, entity_id, metric_id, dimension }
})


export const filterClientReportEntities = actionCreator(FILTER_CLIENT_REPORT_ENTITIES)
export const clearClientReportEntityFilter = actionCreator(CLEAR_CLIENT_REPORT_ENTITY_FILTER)

export const sortClientReportEntities = actionCreator(SORT_CLIENT_REPORT_ENTITIES)

export const setActiveSegment = actionCreator(SET_ACTIVE_SEGMENT)
export const clearActiveSegment = actionCreator(CLEAR_ACTIVE_SEGMENT)

export const setActiveMetric = actionCreator(SET_ACTIVE_METRIC)
export const clearActiveMetric = actionCreator(CLEAR_ACTIVE_METRIC)

export const setExportMetric = actionCreator(SET_EXPORT_METRIC)
export const clearExportMetric = actionCreator(CLEAR_EXPORT_METRIC)
export const setExportType = actionCreator(SET_EXPORT_TYPE)
export const setExportOptions = actionCreator(SET_EXPORT_OPTIONS)

export const setDisplayType = actionCreator(SET_DISPLAY_TYPE)
export const setMetricOrder = actionCreator(SET_METRIC_ORDER)

export const setSearchQuery = actionCreator(SET_SEARCH_QUERY)
export const saveSearchQuery = actionCreator(SAVE_SEARCH_QUERY)
