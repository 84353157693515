
import React from 'react'

import { AttributeMetric } from './AttributeMetric'

import { DistributionMetric } from './DistributionMetric'

import { ValueMetric } from './ValueMetric'

import { MetricProps } from './typings'

export const Metric = ({
  metric,
  metric_type,
  ...props
}: MetricProps): JSX.Element => {

  const description = props.description || props.descriptions && props.descriptions.s

  const metricProps = {
    ...props,
    description,
  }

  if(
    metric && (metric.metric_type === 'distribution' || metric.metric_type === 'distribution_non_mutex')
    || !metric && (metric_type === 'distribution' || metric_type === 'distribution_non_mutex')
  ){
    return <DistributionMetric metric={metric} {...metricProps} />
  }

  if(
    metric && metric.metric_type === 'attribute'
    || !metric && metric_type === 'attribute'
  ){
    return <AttributeMetric metric={metric} {...metricProps} />
  }

  return <ValueMetric metric={metric} {...metricProps} />

}
