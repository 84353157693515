
import React from 'react'

import { Button, ButtonProps } from '@material-ui/core'

import { makeAppStyles } from '../../themes'
import { MenuOption } from '../Menus'


export type ButtonSelectProps<V = unknown, C extends ButtonProps = ButtonProps> = {
  value: V
  options: (
    MenuOption<V> & {
      key?: string | number
      icon?: React.ReactNode
    }
  )[]
  selectedVariant?: ButtonProps['variant']
  unselectedVariant?: ButtonProps['variant']
  selectedColor?: ButtonProps['color']
  unselectedColor?: ButtonProps['color']
  onChange: (e: React.MouseEvent<HTMLButtonElement>, value: V) => void
  ButtonComponent?: React.FC<C>
  ButtonProps?: Partial<
    Omit<
      C, 'onClick' | 'color' | 'variant'
    >
  >
} & Partial<
  Omit<
    React.HTMLAttributes<HTMLSpanElement>, 'onChange'
  >
>


const useStyles = makeAppStyles( theme => ({
  text: {
    padding: theme.spacing(0.75, 2),
  },
  textSizeSmall: {
    padding: theme.spacing(0.5, 1.25),
  },
  textSizeLarge: {
    padding: theme.spacing(1, 2.75),
  },
}) )


export function ButtonSelect<V = unknown, C extends ButtonProps = ButtonProps>({
  value,
  options,
  onChange,
  ButtonComponent = Button,
  selectedColor = 'primary',
  selectedVariant = 'contained',
  unselectedColor = 'default',
  unselectedVariant = 'text',
  ButtonProps = {},
  ...props
}: ButtonSelectProps<V, C>): JSX.Element {

  const buttonClasses = useStyles()

  return (
    <span {...props}>
      { options.map( (option, i) => {
        const selected = option.value === value
        return (
          <ButtonComponent
            classes={buttonClasses}
            startIcon={option.icon}
            {...ButtonProps as C}
            key={option.key || i}
            color={selected ? selectedColor : unselectedColor}
            variant={selected ? selectedVariant : unselectedVariant}
            onClick={(e): void => {
              onChange(e, option.value)
            }}>
            { typeof option.label !== 'undefined' ? option.label : option.value }
          </ButtonComponent>
        )
      })}
    </span>
  )
}

