import { brandedTypedThemes } from './brandedAppThemes'

import { brandtechAppTheme } from './brandtechAppTheme'
import { tpaTheme } from './tpaTheme'

import { AppBranding, ThemeType } from '@percept/types'

import { AppTheme } from './themeAugmentation'


const { BRANDING } = process.env


export const getCurrentThemeTypePreference = (): ThemeType => {
  if( window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ){
    return 'DARK'
  }
  return 'LIGHT'
}

const defaultThemeType: ThemeType = getCurrentThemeTypePreference()

export const getAvailableTypedThemes = (branding: AppBranding): Record<ThemeType, AppTheme> => (
  brandedTypedThemes[branding] || brandedTypedThemes.PERCEPT
)

export const availableTypedThemes = getAvailableTypedThemes(BRANDING)

export const defaultAppTheme = availableTypedThemes[defaultThemeType]
