import React, { Fragment } from 'react'

import { Logo } from './Logo'

import { SimpleLogoComponentProps } from './typings'


export const TPADigitalLogo = (props: SimpleLogoComponentProps): JSX.Element => {
  return (
    <Logo
      fullViewBox='0 0 56 24'
      fullAspectRatio={56 / 24}
      paths={
        <Fragment>
          <path d='M27.794526 8.824653h-2.036518v5.303428h2.036518c1.5894 0 2.7382-1.112905 2.7382-2.651714s-1.1488-2.651714-2.7382-2.651714zm-.022843 4.242742h-.84855V9.893496h.84855c.925241 0 1.566547.642939 1.566547 1.582871s-.641306 1.591028-1.566547 1.591028zm3.534527 1.060686h1.165125V8.824653H31.30621zm4.730655-2.769206v.879551h1.618772c-.199083.545028-.815912.908927-1.502909.908927-.979094 0-1.612242-.682101-1.612242-1.666091s.652729-1.675886 1.612242-1.675886c.636974-.026409 1.224795.34106 1.480066.925246h1.204287c-.254564-1.189601-1.365836-2.023461-2.7072-2.023461-.741542-.019506-1.458538.266496-1.983069.791028s-.810534 1.241526-.791032 1.983073c0 1.591028 1.210815 2.76431 2.800215 2.76431 1.6155 0 2.757777-1.227135 2.757777-2.886697zm3.735248 2.769206h1.163491V8.824653h-1.163491zm6.187873-5.303428h-4.174198v1.068843h1.504537v4.234585h1.171648V9.893496h1.504547zm3.457836 5.303428h1.27283l-2.183381-5.303428h-1.108015l-2.121371 5.303428h1.241816l.370427-.998676h2.144219zm-1.450684-3.901692l.709839 1.901074h-1.419689zm4.542992 2.841006V8.824653h-1.165125v5.303428h3.234276v-1.060686z' />
          <path d='M22.327916 10.621287c-.388748 0-.739216.234176-.887981.593334s-.066537.772562.20835 1.047444.688291.357116 1.047444.20835.593329-.499233.593329-.887981c0-.530826-.430316-.961147-.961142-.961147z' />
          <path d='M8.901268 8.710423H4.609571v1.1178h1.546968v4.422244h1.197759V9.828223h1.54697zm3.051511 0H9.818353v5.538411h1.191232v-1.724839h.936668c1.117798 0 1.958189-.815912 1.958189-1.905969s-.842022-1.907603-1.951663-1.907603zm-.055481 2.705567h-.887713V9.828223h.881186c.283916 0 .546269.15147.688227.397349s.14196.548818 0 .794698-.404311.397348-.688227.397348zm5.974107 2.832844h1.305459l-2.240496-5.538411h-1.134115l-2.18175 5.538411h1.277718l.380218-1.044366h2.204591zm-1.489856-4.07956l.729425 1.985932h-1.468641zm6.907509-3.082515V-.289085H-.25v23.53906h23.539058v-7.416642h-1.981032v5.435606H1.726139V1.687054h19.581887v5.399705z' />
        </Fragment>
      }
      {...props} />
  )
}

export const TPAAuditLogo = (props: SimpleLogoComponentProps): JSX.Element => {
  return (
    <Logo
      fullViewBox='0 0 54 24'
      fullAspectRatio={54 / 24}
      paths={
        <Fragment>
          <path fillRule='evenodd' d='M-.04 0h23.1355v7.3254h-1.9932V1.9938H1.9538v19.1941h19.1485v-5.3316h1.9932v7.3259H-.04V0z' />
          <path fillRule='evenodd' d='M27.685191 8.809222c.3983-.0149.7924.0002 1.1828.0457l2.1088 5.2859c-.4336.0151-.8656 0-1.2973-.0466l-.3713-.9039-2.0866-.0233c-.0891.3143-.1973.6237-.3246.9272-.4318.054-.8646.0617-1.2979.0232l2.0861-5.3082zm.5569 1.5297c.0286.0053.052.0203.0689.0466l.6259 1.6688c-.4328.0616-.8653.0616-1.2974 0l.6026-1.7154z' />
          <path fillRule='evenodd' d='M32.599896 8.809091h1.2058l.0229 3.5234c.2097.6418.65.8975 1.3214.7649.3776-.1453.6015-.4158.6725-.8114l.0231-3.4769h1.2053l-.0232 3.6168c-.2377 1.2167-.9795 1.8348-2.2255 1.8543-1.1758-.0385-1.9022-.6259-2.179-1.762l-.0233-3.7091z' />
          <path fillRule='evenodd' d='M39.183636 8.808987l2.5963.0233c1.4013.3196 2.128 1.2008 2.1789 2.6426-.0562 1.463-.7988 2.3442-2.2256 2.643l-2.5496.0229v-5.3318zm1.2056 1.1128l1.1592.0232c.7715.2157 1.1576.7258 1.1586 1.5299s-.3861 1.3138-1.1586 1.5303l-1.1592.0231v-3.1065z' />
          <path fillRule='evenodd' d='M45.86 8.809091h1.2056v5.3321H45.86v-5.3321z' />
          <path fillRule='evenodd' d='M52.999973 8.809091v1.1122h-1.484v4.2199h-1.2052v-4.2199h-1.4835v-1.1122h4.1727z' />
          <path fillRule='evenodd' d='M4.735455 8.855455h4.2652v1.1589h-1.5299v4.3122h-1.2518v-4.3122h-1.4835v-1.1589z' />
          <path fillRule='evenodd' d='M9.835455 8.85535l2.504.0233c.9982.227 1.5233.8528 1.5764 1.8777-.0573.9456-.5368 1.5561-1.4374 1.8316-.4774.0618-.9558.0851-1.4374.0693v1.6693h-1.2056v-5.4712zm1.2056 1.1594l1.1128.0232c.4911.2517.6224.6304.3935 1.1359-.0869.1022-.1867.1872-.3012.2549-.3978.0655-.7999.0884-1.2051.0695v-1.4835z' />
          <path fillRule='evenodd' d='M15.723609 8.855618c.3872-.0157.7744 0 1.1595.0466l2.1789 5.4241h-1.3451l-.3246-1.0197c-.7108-.008-1.4215 0-2.1322.0234l-.3713.9504c-.4318.0459-.8646.0616-1.2979.0459l2.1327-5.4707zm.556 1.5298l.6958 1.8085c-.4477.0615-.8964.0615-1.3441 0l.6483-1.8085z' />
          <path fillRule='evenodd' d='M22.028869 10.71c.9841.1289 1.2856.654.9044 1.5765-.4016.4077-.8571.4767-1.368.2083-.3917-.3766-.4767-.8176-.2549-1.3207.1817-.2567.4212-.4116.7185-.4641z' />
        </Fragment>
      }
      {...props} />
  )
}
