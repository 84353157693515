export enum MarketSegment {
  SUSTAIN = 'Sustain',
  SCALE = 'Scale',
  ENHANCE = 'Enhance',
  EVANGELIZE = 'Evangelize',
}

export interface Market {
  id: string
  name: string
  iso_code: string
  ordinal_no: number
  report_count: number | null
}
