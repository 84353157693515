import React, { useMemo } from 'react'

import {
  DerivedPerformanceDimensionType,
  DimensionType,
  DistributionMetric,
  HealthType,
  MetricMetadataDisplayOptions,
  MetricSegmentType,
  PerformanceDimensionType,
  PerformanceReportingDimension,
  PerformanceValue,
} from '@percept/types'

import { Box, Typography } from '@material-ui/core'

import { AppTheme, makeAppStyles, useAppTheme } from '../../themes'

import { CardStrip } from '../Cards'

import { PlainTextButton } from '../Buttons'

import { Column, SimpleTable } from '../Tables'

import { Health } from '../Health'

import { Money } from '../Money'

import { CloudDownload } from '../../icons'

import { LegendItem, percentageFormatter } from '../../charts'

import { every, find, flatten, get, uniq } from 'lodash-es'

import {
  deslugify,
  getCPA,
  getCPC,
  getCPM,
  getCPV,
  getCTR,
  parseDimension,
  separateThousands,
  triggerDownload,
} from '@percept/utils'

import { applySegmentMetadata } from './lib'

import { dimensionMap } from '@percept/constants'


export type MetricTableProps = {
  metric: DistributionMetric
  health: HealthType
  currency: string | null
  availableDimensions: string[]
  display_options: MetricMetadataDisplayOptions
  title: string
}

type MetricTableRowKey = DimensionType | 'cpc' | 'cpa' | 'cpm' | 'ctr' | 'cpv'

type MetricTableRow = {
  [k in MetricTableRowKey]: PerformanceValue
} & {
  segment: string
  health: number | null
  color: string
}


const metricToTableRows = (
  metric: DistributionMetric,
  display_options: MetricMetadataDisplayOptions,
  availableDimensions: string[],
  appTheme: AppTheme,
): MetricTableRow[] => {

  const availableDimensionMap = availableDimensions.reduce( (acc, dimension) => {
    acc[dimension] = parseDimension(dimension).dimension
    return acc
  }, {} as Record<string, DimensionType>)

  const parsedSegmentMap = availableDimensions.reduce( (acc, dimension) => {
    const parsed = availableDimensionMap[dimension]
    acc[parsed] = applySegmentMetadata(metric.dimensions[dimension].segments, display_options)
    return acc
  }, {} as Record<DimensionType, MetricSegmentType[]>)
  
  const allSegments = uniq(
    flatten(
      flatten(
        Object.values(parsedSegmentMap)
      ).map( s => String(s.label) )
    )
  )

  const defaultColourScale = appTheme.chart.getInformationalColourScale([
    0, allSegments.length - 1
  ])

  const rows = allSegments.reduce( (acc, segment, i) => {

    const perfData = availableDimensions.reduce( (acc, dimension) => {
      const parsed = availableDimensionMap[dimension]
      const dimensionalSegments = parsedSegmentMap[parsed]
      const match = find(dimensionalSegments, s => String(s.label) === segment )
      acc[parsed] = get(match, 'value', null)
      return acc
    }, {} as Record<DimensionType, PerformanceValue>)

    if( perfData.count ){
      const countHealthMatch = find(parsedSegmentMap.count, s => String(s.label) === segment )
      const health = get(countHealthMatch, 'health', null)
      const color = (
        health === null ?
          defaultColourScale(i) :
          appTheme.chart.healthColourScale(health)
      )
      acc.push({
        segment,
        health,
        color,
        ...perfData,
        cpc: getCPC(perfData),
        cpa: getCPA(perfData),
        cpm: getCPM(perfData),
        ctr: getCTR(perfData),
        cpv: getCPV(perfData),
      })
    }

    return acc

  }, [] as MetricTableRow[])
  
  return rows
}


const dimensionOrder: (
  (PerformanceDimensionType | PerformanceReportingDimension | DerivedPerformanceDimensionType)
  & keyof MetricTableRow
)[] = [
  'cost',
  'impressions',
  'views',
  'clicks',
  'conversions',
  'cpm',
  'cpv',
  'cpc',
  'ctr',
  'cpa',
]

const columns: Column<MetricTableRow>[] = [
  {
    key: 'segment',
    label: 'Segment',
    align: 'left',
  },
  {
    key: 'count',
    label: 'Total',
    align: 'right',
  },
  ...(
    dimensionOrder.map( d => ({
      key: d,
      label: get(dimensionMap[d], 'text') || deslugify(d),
      align: 'right',
    }) as Column<MetricTableRow>)
  )
]


const derivedDimensionDependencies: Partial<Record<keyof MetricTableRow, PerformanceDimensionType[]>> = {
  cpa: ['cost', 'conversions'],
  cpm: ['cost', 'impressions'],
  cpc: ['cost', 'clicks'],
  cpv: ['cost', 'views'],
  ctr: ['clicks', 'impressions'],
  cost: ['cost'],
  conversions: ['conversions'],
  impressions: ['impressions'],
  clicks: ['clicks'],
}


/* Export helpers */

const itemToString = (item: string | number | null | undefined): string => {
  let output = ''
  if( typeof item === 'string' ){
    output = item
  }
  if( typeof item === 'number' ){
    output = String(item)
  }
  if( /[/,]/.test(output) ){
    return `"${output}"`
  }
  return output
}

const rowsToCSV = (tableRows: MetricTableRow[], columns: Column<MetricTableRow>[]): string => {
  const header: string = columns.map( ({ label }) => label ).join(', ')
  const rowValues: string[] = tableRows.map( row => (
    columns.map( ({ key }) => itemToString(row[key]) ).join(', ')
  ))
  return [
    header,
    ...rowValues
  ].join('\n')
}


const useStyles = makeAppStyles( theme => ({
  nullEntry: {
    color: theme.palette.text.hint
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  health: {
    marginLeft: theme.spacing(1),
  },
}))


export const MetricTable = ({
  metric,
  health,
  display_options,
  title,
  currency,
  availableDimensions,
}: MetricTableProps): JSX.Element => {

  const appTheme = useAppTheme()

  const rows = useMemo(() => {
    return metricToTableRows(metric, display_options, availableDimensions, appTheme)
  }, [metric, display_options, availableDimensions, appTheme])

  const classes = useStyles()

  const parsedAvailableDimensions = availableDimensions.map( d => parseDimension(d).dimension )

  const renderableColumns = columns.filter( ({ key }) => {
    const sampleRow = rows[0]
    const dependencies = derivedDimensionDependencies[key]
    if( !dependencies ) return (!sampleRow || typeof sampleRow[key] !== 'undefined')
    return every(dependencies, d => (
      parsedAvailableDimensions.includes(d) && (
        !sampleRow || typeof sampleRow[d] !== 'undefined'
      )
    ))
  })

  const nullEntryElement = (
    <span className={classes.nullEntry}>––</span>
  )

  return (
    <Box>

      <CardStrip
        color='health'
        health={health} />

      <Box
        p={2}
        pb={3}
        display='flex'
        alignItems='center'
        justifyContent='space-between'>

        <Typography variant='h5' className={classes.title}>
          { title }
          { health !== null && (
            <Health
              className={classes.health}
              value={health}
              fontSize='1.25em' />
          )}
        </Typography>

        <PlainTextButton
          variant='contained'
          size='small'
          startIcon={
            <CloudDownload />
          }
          onClick={(): void => {
            const csv = rowsToCSV(rows, renderableColumns)
            triggerDownload({
              filename: `${title}.csv`,
              contents: csv,
              mimeType: 'text/csv',
            })
          }}>
          Download CSV
        </PlainTextButton>
      </Box>

      <SimpleTable
        sortable
        rows={rows}
        columns={renderableColumns}
        renderers={{
          /* eslint-disable react/display-name, react/prop-types */
          segment: ({ segment, color }): JSX.Element => (
            <LegendItem
              datum={{
                label: segment,
                fill: color,
              }} />
          ),
          cost: ({ cost }): JSX.Element => (
            cost === null ?
              nullEntryElement :
              <Money amount={cost} currency={currency} abbreviate={false} />
          ),
          count: ({ count }): JSX.Element => (
            count === null ?
              nullEntryElement :
              <span>{ separateThousands(count) }</span>
          ),
          impressions: ({ impressions }): JSX.Element => (
            impressions === null ?
              nullEntryElement :
              <span>{ separateThousands(impressions) }</span>
          ),
          clicks: ({ clicks }): JSX.Element => (
            clicks === null ?
              nullEntryElement :
              <span>{ separateThousands(clicks) }</span>
          ),
          conversions: ({ conversions }): JSX.Element => (
            conversions === null ?
              nullEntryElement :
              <span>{ separateThousands(conversions) }</span>
          ),
          views: ({ views }): JSX.Element => (
            views === null ?
              nullEntryElement :
              <span>{ separateThousands(views) }</span>
          ),
          cpa: ({ cpa }): JSX.Element => (
            cpa === null ?
              nullEntryElement :
              <Money amount={cpa} currency={currency} abbreviate={false} />
          ),
          cpm: ({ cpm }): JSX.Element => (
            cpm === null ?
              nullEntryElement :
              <Money amount={cpm} currency={currency} abbreviate={false} />
          ),
          cpc: ({ cpc }): JSX.Element => (
            cpc === null ?
              nullEntryElement :
              <Money amount={cpc} currency={currency} abbreviate={false} />
          ),
          cpv: ({ cpv }): JSX.Element => (
            cpv === null ?
              nullEntryElement :
              <Money amount={cpv} currency={currency} abbreviate={false} />
          ),
          ctr: ({ ctr }): JSX.Element => (
            ctr === null ?
              nullEntryElement :
              <span>{ percentageFormatter(ctr) }</span>
          ),
        }} />

    </Box>
  )
}
