import { useMemo } from 'react'

import { AppTheme } from '@percept/mui'

import { ChartData, detailedPercentageFormatter, getMoneyFormatter, numberFormatter } from '@percept/mui/charts'

import { getCPA, getCPC, getCTR, getReportResultSummary } from '@percept/utils'

import { get } from 'lodash-es'

import { dimensionMap } from '@percept/constants'

import {
  PerformanceDimensionType,
  DerivedPerformanceDimensionType,
  PerformanceSummary,
  PerformanceValue,
  StructuralReport,
} from '@percept/types'

import { TimelineDatum } from 'types'


type SeriesDimension = PerformanceDimensionType | DerivedPerformanceDimensionType


type SeriesDataset = {
  dimension: SeriesDimension
  formatter: (value: number | null) => string
  currency: string | null
  label: string
  data: ChartData<TimelineDatum>
  color: string
}


const seriesDimensions: SeriesDimension[] = [
  'cost',
  'impressions',
  'clicks',
  'conversions',
  'cpa',
  'cpc',
  'ctr',
]


const getDimensionFormatter = (dimension: SeriesDimension, currency: string | null): SeriesDataset['formatter'] => {
  switch(dimension){
    case 'cost':
    case 'cpa':
    case 'cpc': return getMoneyFormatter(currency)
    case 'ctr': return detailedPercentageFormatter
    default: return numberFormatter
  }
}


const resolvePerformance = (summary: PerformanceSummary): Record<SeriesDimension, PerformanceValue> => {

  const perf = seriesDimensions.reduce( (obj, field) => {
    obj[field] = (
      !summary ? null :
        typeof summary[`search_${field}`] === 'number' ?
          summary[`search_${field}`] : summary[field]
    )
    return obj
  }, {} as Record<SeriesDimension, PerformanceValue>)

  return {
    ...perf,
    cpa: getCPA(perf),
    cpc: getCPC(perf),
    ctr: getCTR(perf),
  }
}


export const useSeriesDatasets = ({
  reports,
  appTheme,
}: {
  reports: StructuralReport[]
  appTheme: AppTheme
}): SeriesDataset[] => {

  return useMemo(() => {
    const mappedReportData = reports.map( report => {
      const summary = getReportResultSummary(report)
      const payload = resolvePerformance(summary && summary.performance)
      return {
        payload,
        label: new Date(report.end).getTime(),
        start: new Date(report.start).getTime(),
        end: new Date(report.end).getTime(),
        currency: summary && summary.currency_code,
      }
    })

    const currency = get(
      mappedReportData, [0, 'currency'], null
    )

    return seriesDimensions.map( dimension => {
      const color = appTheme.palette.secondary.main
      return {
        dimension,
        label: (
          dimensionMap[dimension as PerformanceDimensionType] &&
          dimensionMap[dimension as PerformanceDimensionType].text ||
          // We want to capitalize all letters here, as the only non-mapped dimensions
          // in this view are 'CPC' and 'CTR'
          dimension.toUpperCase()
        ),
        formatter: getDimensionFormatter(
          dimension,
          currency
        ),
        currency,
        data: mappedReportData.map( ({ start, end, label, payload }) => {
          return {
            label,
            value: payload[dimension],
            color,
            start,
            end,
          }
        }),
        color
      }
    })
  }, [reports, appTheme])
}
