
import React, { useMemo } from 'react'

import { Box, BoxProps } from '@material-ui/core'

import TabSwitcher from './TabSwitcher'

import {
  MetricSearchDrawer,
  getMetricSearchResults,
  MetricSearchConfig,
} from '../MetricSearch'

import { useSectionScoresObject } from '@percept/hooks'

import { getPath } from '@percept/utils'

import { LayoutNodeType } from '@percept/types'

import { ReportDashboardDataDependencies, ReportDashboardProps } from './typings'


export type ControlAppBarInsertProps = (
  ReportDashboardDataDependencies &
  Pick<
    ReportDashboardProps,
    'activeTab' | 'impactWeighted' | 'layout' | 'activePerformanceTail' | 'dimensionOptions' | 'healthDimension' |
    'setActiveMetric' | 'onActivateLayoutItemPath'
  > & {
    onTabChange?: (e: React.ChangeEvent<{}>, tab: string) => void
    searchConfig?: MetricSearchConfig | null
    setSearchConfig?: (config: MetricSearchConfig) => void
    BoxProps?: BoxProps
  }
)


export const ControlAppBarInsert = ({
  entity,
  metrics,
  metadata,
  impactWeighted,
  layout,
  activeTab,
  searchConfig,
  setSearchConfig,
  healthDimension = 'count',
  dimensionOptions,
  activePerformanceTail = '',
  setActiveMetric,
  onTabChange,
  onActivateLayoutItemPath,
  BoxProps = {},
}: ControlAppBarInsertProps): JSX.Element | null => {

  const sectionScores = useSectionScoresObject(
    layout,
    metrics,
    {
      healthDimension,
      impactWeighted,
      metricMetadata: metadata,
    }
  )

  const search = getPath(layout, ['features', 'search']) !== false

  const layoutMetrics = useMemo(() => {
    if( !(search && searchConfig) ){
      return null
    }
    return getMetricSearchResults({
      entity,
      metrics,
      metadata,
      searchConfig,
      layout,
    })
  }, [search, metrics, entity, metadata, searchConfig, layout])

  return (

    <Box width='100%' display='flex' {...BoxProps}>

      { (entity && metrics && search && searchConfig && setSearchConfig && layout) ? (
        <MetricSearchDrawer
          config={searchConfig}
          layout={layout}
          payload={metrics}
          setActiveMetric={setActiveMetric}
          metadata={metadata}
          entity={entity}
          impactWeighted={impactWeighted}
          matches={layoutMetrics}
          onChange={setSearchConfig}
          onActivateLayoutItemPath={onActivateLayoutItemPath}
          tabs={layout.members}
          dimensionOptions={dimensionOptions}
          performanceTail={activePerformanceTail}
          healthDimension={healthDimension} />
      ) : null }

      { activeTab && (
        <TabSwitcher
          tabs={layout.members as (LayoutNodeType & { key: string, name: string })[]}
          sectionScores={sectionScores}
          value={activeTab}
          onChange={onTabChange} />
      )}

    </Box>
  )

}
