import { defaultStyles } from '@visx/tooltip'
import { ScaleOrdinal } from 'd3'
import { Tooltip as TooltipComponent} from '@percept/mui/charts/Tooltip'
import React from 'react'
import { TooltipInPortalProps } from '@visx/tooltip/lib/hooks/useTooltipInPortal'

interface Props {
  top: number | undefined
  left: number | undefined
  label: string
  colorScale: ScaleOrdinal<string, string>
  value: number
  valueFormatter: (value: number | string | null) => string
  TooltipInPortal: React.FC<TooltipInPortalProps>
}

const tooltipStyles = {
  ...defaultStyles,
  zIndex: 9999,
  margin: 0,
  padding: 0,
  backgroundColor: 'transparent',
  color: 'unset',
}

export const Tooltip = ({
  top,
  left,
  label,
  colorScale,
  value,
  valueFormatter,
  TooltipInPortal
}: Props): JSX.Element => {
  return (
    <TooltipInPortal top={top} left={left} style={tooltipStyles}>
      <TooltipComponent
        tooltipData={{
          label: label,
          datum: {
            value: value,
            label: label,
          },
        }}
        color={colorScale(label)}
        valueFormatter={valueFormatter}
      />
    </TooltipInPortal>
  )
}
