import { SubmissionTrackerData } from 'api/services/Api'
import { TableRow } from './SubmissionTrackerTable'
import { format } from 'date-fns'
import { SubmissionMonth } from './SubmissionTrackerTable'
import { sortBy } from 'lodash-es'

export const transformData = (
  data: SubmissionTrackerData[] | undefined,
  validMonths: Date[],
): TableRow[] => {
  
  const generateRowKey = (datum: SubmissionTrackerData): string => (
    [datum.org_unit_name, datum.agency_name, datum.department_name].join('|')
  )

  if( !data ) return []
  const keyedSubmissionMap = data.reduce((acc, d) => {
    const key = generateRowKey(d)
    acc[key] = acc[key] || []
    acc[key].push(d)
    return acc
  }, {} as Record<string, SubmissionTrackerData[]>)

  const isoMonths = validMonths.map( m => format(m, 'yyyy-MM-dd'))

  type KeyedTableRow = TableRow & { key: string }

  const keyedRows = Object.entries(keyedSubmissionMap).reduce((acc, [key, data]) => {
    const monthSubmissions = isoMonths.reduce((acc, m) => {
      const match = data.find( d => d.report_date === m )
      acc[m] = {
        isUpdated: !!match,
        date: match ? format(new Date(match.added_at), 'dd/MM/yy') : undefined,
      }
      return acc
    }, {} as Record<string, SubmissionMonth>)
    const rowBase = {
      market: data[0].org_unit_name,
      agency: data[0].agency_name,
      department: data[0].department_name,
    }
    acc.push({
      key,
      ...rowBase,
      ...monthSubmissions,
    } as unknown as KeyedTableRow)
    return acc
  }, [] as KeyedTableRow[])

  return sortBy(keyedRows, 'key')
}
