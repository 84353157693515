import React from 'react'

import { DefaultMediaWizardLayout } from './DefaultMediaWizardLayout'

import { Analytics as AnalyticsComponent } from 'components/Analytics'
import { Breadcrumb } from 'components/Links'

import { useDocumentTitle } from '@percept/hooks'


const breadcrumbs: Breadcrumb[] = [
  {
    path: '/wizard/analytics',
    name: 'Analytics',
  },
]


export const Analytics = (): JSX.Element => {
  useDocumentTitle({ paths: ['Analytics'] })
  return (
    <DefaultMediaWizardLayout
      breadcrumbs={breadcrumbs}>

      <AnalyticsComponent />

    </DefaultMediaWizardLayout>
  )
}
