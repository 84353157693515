
import { actionCreator } from '@percept/redux'

import { Dictionary, ReduxAction } from '@percept/types'


export const SET_TOKEN = 'SET_TOKEN'

export const RESET_TOKEN = 'RESET_TOKEN'

export const SET_TOKEN_ERROR = 'SET_TOKEN_ERROR'

export const RESET_TOKEN_ERROR = 'RESET_TOKEN_ERROR'


export const setToken = (payload: Dictionary): ReduxAction => ({
  type: SET_TOKEN,
  payload: {
    ...payload,
    createdAt: Date.now()
  }
})

export const resetToken = actionCreator(RESET_TOKEN)

export const setTokenError = (): ReduxAction => ({
  type: SET_TOKEN_ERROR,
  payload: {
    name: 'Authorization Error',
    message: 'Your token has expired.'
  },
  error: true
})

export const resetTokenError = actionCreator(RESET_TOKEN_ERROR)
