
import {
  SET_ACTIVE_SERIES_METRIC,
  CLEAR_ACTIVE_SERIES_METRIC,
} from '../../actions'

import {
  ReduxAction,
  Dictionary,
} from '@percept/types'


export default (state = null, action: ReduxAction): null | Dictionary => {

  switch(action.type){

    case SET_ACTIVE_SERIES_METRIC:
      return {
        ...(state || {}),
        ...action.payload
      }

    case CLEAR_ACTIVE_SERIES_METRIC:
      return null

    default:
      return state

  }

}
