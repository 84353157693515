import React from 'react'

import { useDocumentTitle } from '@percept/hooks'

import { DefaultMediaWizardLayout } from './DefaultMediaWizardLayout'

import { Overview as OverviewView } from 'components/Overview'


export const Overview = (): JSX.Element => {
  useDocumentTitle({ paths: ['Overview'] })
  return (
    <DefaultMediaWizardLayout
      breadcrumbs={[
        {
          path: '/wizard/overview',
          name: 'Overview',
        },
      ]}>

      <OverviewView />

    </DefaultMediaWizardLayout>
  )
}
