import React, { Fragment, useEffect, useMemo, useRef } from 'react'

import { Box, Checkbox, Typography, FormControlLabel } from '@material-ui/core'

import { ButtonSelect, ButtonSelectProps } from '../Selects'

import { HealthSlider, RangeSliderValue } from '../Inputs'

import { RoundedPlainTextButton } from '../Buttons'

import { ChipMenu, MenuOption } from '../Menus'

import { ButtonPopover } from '../Popovers'

import { makeAppStyles } from '../../themes'

import { Dashboard, FilterList, FormatListBulleted, Sort } from '../../icons'

import LazySearch from './LazySearch'

import { percentageFormatter } from '../../charts'

import { MetricSearchResults } from './MetricSearchResults'

import { MetricSearchProps, MetricSortKey } from './typings'

import { find, get } from 'lodash-es'


const useStyles = makeAppStyles( theme => ({
  searchContainer: {
    height: `calc(100vh - ${theme.spacing(34)}px)`,
    overflowY: 'auto',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
  },
  flexLeft: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  spacedLeft: {
    marginLeft: theme.spacing(1.5),
  },
  healthSlider: {
    width: theme.spacing(16),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  formControlLabel: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
  },
  buttonSmall: {
    height: theme.spacing(3),
  }
}) )


const { APP, WORKLOAD_TYPE } = process.env

const DEBUG = APP === 'admin' || WORKLOAD_TYPE !== 'PROD'


type SearchView = 'LAYOUT' | 'LIST'

const viewOptions: ButtonSelectProps<SearchView>['options'] = [
  {
    value: 'LAYOUT',
    label: 'Layout',
    icon: <Dashboard />,
  },
  {
    value: 'LIST',
    label: 'List',
    icon: <FormatListBulleted />,
  },
]


const sortLabelMap: Record<MetricSortKey, string> = {
  A_TO_Z: 'A to Z',
  HEALTH_ASC: 'Health Asc',
  HEALTH_DESC: 'Health Desc',
  RANK: 'Best Match',
}

const sortKeys: MetricSortKey[] = [
  'A_TO_Z', 'RANK', 'HEALTH_ASC', 'HEALTH_DESC',
]

const sortOptions: MenuOption<MetricSortKey>[] = sortKeys.map( value => ({
  value,
  label: sortLabelMap[value]
}))


export const MetricSearch = ({
  config,
  layout,
  payload,
  metadata,
  entity,
  impactWeighted,
  tabs,
  matches,
  healthDimension = 'count',
  onChange,
  onActivateLayoutItemPath,
  setActiveMetric,
  onClose,
  isVisible = true,
}: MetricSearchProps): JSX.Element => {

  const rangeValue: RangeSliderValue = useMemo(() => (
    [config.minHealth || 0, config.maxHealth || 1]
  ), [config])

  const view: SearchView = config.view || 'LAYOUT'

  const containerRef = useRef<HTMLDivElement | null>(null)

  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if( isVisible && inputRef.current ){
      inputRef.current.focus()
    }
  }, [isVisible])

  const classes = useStyles()

  return (
    <Fragment>

      <Box
        display='flex'
        flexWrap='wrap'
        alignItems='center'
        mb={3}>

        <Box
          display='flex'
          flexGrow={1}
          mr={3}>
          <LazySearch
            fullWidth
            inputRef={inputRef}
            placeholder='Search'
            color='secondary'
            value={config.query || ''}
            onChange={(e): void => {
              onChange({
                query: e.target.value
              })
            }}
            onReset={(): void => {
              onChange({
                query: ''
              })
              inputRef.current && inputRef.current.focus()
            }} />
        </Box>

        <div className={classes.flexLeft}>
          <ButtonSelect
            ButtonProps={{
              size: 'small',
            }}
            ButtonComponent={RoundedPlainTextButton}
            selectedColor='secondary'
            value={view}
            options={viewOptions}
            onChange={(e, value): void => {
              onChange({
                view: value,
                searchLayout: value === 'LAYOUT' || !DEBUG
              })
            }} />
        </div>

      </Box>

      <Box
        display='flex'
        flexWrap='wrap'
        alignItems='center'
        mb={3}>

        { tabs && tabs.length && (
          <ChipMenu
            TriggerProps={{
              disabled: !!(config.allowPayloadSearch && config.searchLayout === false),
              color: 'secondary',
              size: 'small',
              icon: <FilterList />,
            }}
            value={
              !config.tabs || config.tabs.length === tabs.length ?
                'all' :
                config.tabs[0]
            }
            label={
              !config.tabs || config.tabs.length === tabs.length ?
                'All Tabs' :
                get(find(tabs, t => config.tabs && (t.key || t.name) === config.tabs[0]), 'name')
            }
            options={
              [
                { value: 'all', label: 'All Tabs' },
                ...tabs.map( ({ key, name }) => {
                  key = key || name
                  return {
                    key,
                    value: key,
                    label: name,
                  }
                })
              ]
            }
            onChange={(e, value): void => {
              onChange({
                tabs: value === 'all' ? undefined : [String(value)]
              })
            }} />
        )}

        <ButtonPopover
          ButtonComponent={RoundedPlainTextButton}
          className={`${classes.spacedLeft} ${classes.buttonSmall}`}
          buttonContent={
            config.healthOnly ? (
              `Health ${percentageFormatter(Math.round((config.minHealth || 0) * 100))} - ` +
              `${percentageFormatter(Math.round((config.maxHealth || 1) * 100))} `
            ) : 'Health Filter'
          }
          color={
            config.healthOnly ?
              'secondary' :
              'default'
          }
          variant={
            config.healthOnly ?
              'contained' :
              'text'
          }
          size='small'
          startIcon={
            <FilterList />
          }>
          <Box p={2}>
            <Typography variant='h6'>Health Filter</Typography>
            <div className={classes.section}>
              <FormControlLabel
                classes={{
                  label: classes.formControlLabel,
                }}
                label='Enabled'
                control={
                  <Checkbox
                    checked={config.healthOnly === true}
                    onChange={(): void => {
                      onChange({
                        healthOnly: config.healthOnly === true ? false : true
                      })
                    }} />
                } />

              <HealthSlider
                className={classes.healthSlider}
                disabled={config.healthOnly !== true}
                value={rangeValue}
                color='secondary'
                onChange={(e, [minHealth, maxHealth]): void => {
                  onChange({
                    minHealth,
                    maxHealth,
                  })
                }} />
            </div>
          </Box>
        </ButtonPopover>

        { view === 'LIST' && (
          <div
            className={classes.flexLeft}>
            <Typography
              variant='subtitle1'>
              Sort By
            </Typography>
            <ChipMenu
              TriggerProps={{
                className: classes.spacedLeft,
                color: 'secondary',
                size: 'small',
                icon: <Sort />
              }}
              value={config.sortBy}
              label={sortLabelMap[config.sortBy || 'RANK']}
              options={sortOptions}
              onChange={(e, sortBy: MetricSortKey): void => {
                onChange({
                  sortBy
                })
              }} />
          </div>
        )}
      </Box>

      <Box
        mb={1}
        fontSize={14}
        fontWeight='bold'>
        { matches && matches.length ? (
          `${matches.length} metric${matches.length === 1 ? '' : 's'}`
        ) : (
          'No metrics match these filters'
        )}
      </Box>
      
      <div
        className={classes.searchContainer}
        ref={containerRef}>
        <MetricSearchResults
          containerRef={containerRef}
          view={view}
          searchConfig={config}
          matches={matches || []}
          impactWeighted={impactWeighted}
          healthDimension={healthDimension}
          onActivateLayoutItemPath={onActivateLayoutItemPath && ((path): void => {
            onActivateLayoutItemPath(path)
            onClose && onClose()
          })}
          setActiveMetric={setActiveMetric && ((metric): void => {
            setActiveMetric(metric)
            onClose && onClose()
          })}
          layout={layout}
          payload={payload}
          entity={entity}
          metadata={metadata} />
      </div>

    </Fragment>
  )
}
