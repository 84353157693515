import { CurrencyType, ListItem } from '../types'
import MediaInvestmentApi, {
  DataByPeriodProps,
} from 'api/services/MediaInvestment'
import CompetitiveReportsApi, {
  CompetitiveDataProps,
  CompetitiveReportType
} from 'api/services/CompetitiveReports'
import { SpendingType } from 'api/services/Api'
import { Filters } from 'enums/Filters'
import { PossibleFiltersOptions } from './InvestmentReport'
import { find, noop } from 'lodash-es'
import { UseQueryResult } from 'react-query'

import {
  CampaignPillarSectionTitle,
  CompetitiveReportPath,
  CompetitiveReportKey,
  MediaInvestmentPath,
  MediaMixSectionTitle,
  MediaSpendExportRequestParams,
  ScopeOfDataType,
} from './typings'


type DataHookExportParams = Pick<MediaSpendExportRequestParams, 'export_type' | 'export_sub_type'>

type DataHookConfig = {
  spendingType: SpendingType
  hook: (props: DataByPeriodProps) => UseQueryResult<ListItem[], Error>
  path: MediaInvestmentPath | CompetitiveReportPath
  reportKey?: CompetitiveReportKey
  scope: ScopeOfDataType
  sectionTitle?: CampaignPillarSectionTitle | MediaMixSectionTitle
  exportParams?: DataHookExportParams | null
  competitiveReportType?: CompetitiveReportType | null
}


const dataHookConfigsBySpendingType: Record<SpendingType, Omit<DataHookConfig, 'spendingType'>[]> = {
  // Media Investment
  primary_spend: [
    // Campaign Pillar By Period
    {
      path: MediaInvestmentPath.CampaignPillar,
      scope: ScopeOfDataType.Period,
      sectionTitle: CampaignPillarSectionTitle.CampaignPillar,
      hook: MediaInvestmentApi.useCampaignPillar,
      exportParams: {
        export_type: 'campaign_pillar',
        export_sub_type: 'period',
      },
    },
    // Campaign Pillar By Market
    {
      path: MediaInvestmentPath.CampaignPillar,
      scope: ScopeOfDataType.Market,
      sectionTitle: CampaignPillarSectionTitle.CampaignPillar,
      hook: MediaInvestmentApi.useCampaignPillarByMarket,
      exportParams: {
        export_type: 'campaign_pillar',
        export_sub_type: 'market',
      },
    },
    // Brand vs Performance By Period
    {
      path: MediaInvestmentPath.CampaignPillar,
      scope: ScopeOfDataType.Period,
      sectionTitle: CampaignPillarSectionTitle.BrandPerformance,
      hook: MediaInvestmentApi.useBrandPerformance,
      exportParams: {
        export_type: 'brand_vs_performance',
        export_sub_type: 'period',
      },
    },
    // Brand vs Performance By Market
    {
      path: MediaInvestmentPath.CampaignPillar,
      scope: ScopeOfDataType.Market,
      sectionTitle: CampaignPillarSectionTitle.BrandPerformance,
      hook: MediaInvestmentApi.useBrandPerformanceByMarket,
      exportParams: {
        export_type: 'brand_vs_performance',
        export_sub_type: 'market',
      },
    },
    // Campaign Sub Pillar
    {
      path: MediaInvestmentPath.CampaignPillar,
      scope: ScopeOfDataType.Period,
      sectionTitle: CampaignPillarSectionTitle.CampaignSubPillar,
      hook: MediaInvestmentApi.useCampaignSubPillar,
      exportParams: {
        export_type: 'campaign_sub_pillar',
        export_sub_type: 'period',
      },
    },
    // Consumer vs Business
    {
      path: MediaInvestmentPath.CampaignPillar,
      scope: ScopeOfDataType.Period,
      sectionTitle: CampaignPillarSectionTitle.ConsumerBusiness,
      hook: MediaInvestmentApi.useConsumerVsBusiness,
      exportParams: {
        export_type: 'consumer_vs_business',
        export_sub_type: 'period',
      },
    },
    // Media Mix By Period
    {
      path: MediaInvestmentPath.MediaMix,
      scope: ScopeOfDataType.Period,
      sectionTitle: MediaMixSectionTitle.MediaInvest,
      hook: MediaInvestmentApi.useMediaMix,
      exportParams: {
        export_type: 'media_mix',
        export_sub_type: 'period',
      }
    },
    // Media Mix By Market
    {
      path: MediaInvestmentPath.MediaMix,
      scope: ScopeOfDataType.Market,
      sectionTitle: MediaMixSectionTitle.MediaInvest,
      hook: MediaInvestmentApi.useMediaMixByMarket,
      exportParams: {
        export_type: 'media_mix',
        export_sub_type: 'market'
      },
    },
    // Media Mix By Campaign
    {
      path: MediaInvestmentPath.MediaMix,
      scope: ScopeOfDataType.CampaignPillar,
      sectionTitle: MediaMixSectionTitle.MediaInvest,
      hook: MediaInvestmentApi.useMediaMixByCampaign,
      exportParams: {
        export_type: 'media_mix',
        export_sub_type: 'campaign_pillar',
      },
    },
    // Media Mix By Sub Campaign
    {
      path: MediaInvestmentPath.MediaMix,
      scope: ScopeOfDataType.CampaignSubPillar,
      sectionTitle: MediaMixSectionTitle.MediaInvest,
      hook: MediaInvestmentApi.useMediaMixBySubCampaign,
      exportParams: {
        export_type: 'media_mix',
        export_sub_type: 'campaign_sub_pillar',
      },
    },
    // Media Mix YOY
    {
      path: MediaInvestmentPath.MediaMix,
      scope: ScopeOfDataType.Period,
      sectionTitle: MediaMixSectionTitle.MediaMixPercentage,
      hook: MediaInvestmentApi.useMediaMixYOY,
      exportParams: {
        export_type: 'media_mix',
        export_sub_type: 'year_over_year',
      },
    },
    // Media Mix TV vs Digital
    {
      path: MediaInvestmentPath.MediaMix,
      scope: ScopeOfDataType.Period,
      sectionTitle: MediaMixSectionTitle.TVDigital,
      hook: MediaInvestmentApi.useMediaMixTVVsDigital,
      exportParams: {
        export_type: 'media_mix',
        export_sub_type: 'tv_vs_digital',
      },
    },
    // Second Brand By Period
    {
      path: MediaInvestmentPath.SecondBrand,
      scope: ScopeOfDataType.Period,
      hook: MediaInvestmentApi.useSecondBrandByPeriod,
      exportParams: {
        export_type: 'second_brand',
        export_sub_type: 'period',
      },
    },
    // Second Brand By Campaign Pillar
    {
      path: MediaInvestmentPath.SecondBrand,
      scope: ScopeOfDataType.CampaignPillar,
      hook: MediaInvestmentApi.useSecondBrandByCampaignPillar,
      exportParams: {
        export_type: 'second_brand',
        export_sub_type: 'campaign_pillar',
      },
    },
    // Second Brand By Media Mix
    {
      path: MediaInvestmentPath.SecondBrand,
      scope: ScopeOfDataType.MediaMix,
      hook: MediaInvestmentApi.useSecondBrandByMediaMix,
      exportParams: {
        export_type: 'second_brand',
        export_sub_type: 'media_mix',
      },
    },
    // Second Brand By Digital Media Mix
    {
      path: MediaInvestmentPath.SecondBrand,
      scope: ScopeOfDataType.DigitalMediaMix,
      hook: MediaInvestmentApi.useSecondBrandByDigitalMediaMix,
      exportParams: {
        export_type: 'second_brand',
        export_sub_type: 'digital_media_mix',
      },
    },
    // Brand Messaging By Market
    {
      path: MediaInvestmentPath.BrandMessaging,
      scope: ScopeOfDataType.Market,
      hook: MediaInvestmentApi.useBrandMessagingByMarket,
      exportParams: {
        export_type: 'message',
        export_sub_type: 'market',
      },
    },
    // Brand Messaging By Campaign Pillar
    {
      path: MediaInvestmentPath.BrandMessaging,
      scope: ScopeOfDataType.CampaignPillar,
      hook: MediaInvestmentApi.useBrandMessagingByCampaignPillar,
      exportParams: {
        export_type: 'message',
        export_sub_type: 'campaign_pillar',
      },
    },
    // Brand Messaging By Media Mix
    {
      path: MediaInvestmentPath.BrandMessaging,
      scope: ScopeOfDataType.MediaMix,
      hook: MediaInvestmentApi.useBrandMessagingByMediaMix,
      exportParams: {
        export_type: 'message',
        export_sub_type: 'media_mix',
      },
    },
    // Funding Source By Market
    {
      path: MediaInvestmentPath.FundingSource,
      scope: ScopeOfDataType.Market,
      hook: MediaInvestmentApi.useFundingSourceByMarket,
      exportParams: {
        export_type: 'funding_source',
        export_sub_type: 'market',
      },
    },
    // Funding Source By Campaign Pillar
    {
      path: MediaInvestmentPath.FundingSource,
      scope: ScopeOfDataType.CampaignPillar,
      hook: MediaInvestmentApi.useFundingSourceByCampaign,
      exportParams: {
        export_type: 'funding_source',
        export_sub_type: 'campaign_pillar',
      },
    },
    // Funding Source By Sub Campaign Pillar
    {
      path: MediaInvestmentPath.FundingSource,
      scope: ScopeOfDataType.CampaignSubPillar,
      hook: MediaInvestmentApi.useFundingSourceBySubCampaign,
      exportParams: {
        export_type: 'funding_source',
        export_sub_type: 'campaign_sub_pillar',
      },
    },
    // Funding Source By Media Mix
    {
      path: MediaInvestmentPath.FundingSource,
      scope: ScopeOfDataType.MediaMix,
      hook: MediaInvestmentApi.useFundingSourceByMediaMix,
      exportParams: {
        export_type: 'funding_source',
        export_sub_type: 'media_mix',
      },
    },
    // Funding Source By Digital Media Mix
    {
      path: MediaInvestmentPath.FundingSource,
      scope: ScopeOfDataType.DigitalMediaMix,
      hook: MediaInvestmentApi.useFundingSourceByDigitalMediaMix,
      exportParams: {
        export_type: 'funding_source',
        export_sub_type: 'digital_media_mix',
      },
    },
    // Funding Source By Brand vs Performance
    {
      path: MediaInvestmentPath.FundingSource,
      scope: ScopeOfDataType.BrandVsPerformance,
      hook: MediaInvestmentApi.useFundingSourceByBrandVsPerformance,
      exportParams: {
        export_type: 'funding_source',
        export_sub_type: 'brand_vs_performance',
      },
    },
    // Product By Market
    {
      path: MediaInvestmentPath.Product,
      scope: ScopeOfDataType.Market,
      hook: MediaInvestmentApi.useProductByMarket,
      exportParams: {
        export_type: 'product',
        export_sub_type: 'market',
      },
    },
    // Product By Campaign Pillar
    {
      path: MediaInvestmentPath.Product,
      scope: ScopeOfDataType.CampaignPillar,
      hook: MediaInvestmentApi.useProductByCampaign,
      exportParams: {
        export_type: 'product',
        export_sub_type: 'campaign_pillar',
      },
    },
    // Product By Media Mix
    {
      path: MediaInvestmentPath.Product,
      scope: ScopeOfDataType.MediaMix,
      hook: MediaInvestmentApi.useProductByMediaMix,
      exportParams: {
        export_type: 'product',
        export_sub_type: 'media_mix',
      },
    },
  ],

  // Competitive SOS
  sos_spend: [
    // SOS - Overview
    {
      path: 'share-of-spend',
      reportKey: 'sos-overview',
      scope: ScopeOfDataType.Period,
      hook: CompetitiveReportsApi.useSOSSpendingAllCompetitorsByMarket,
      competitiveReportType: 'all-competitors',
    },
    // SOS - By Market
    {
      path: 'share-of-spend',
      reportKey: 'sos-by-market',
      scope: ScopeOfDataType.Period,
      hook: CompetitiveReportsApi.useSOSSpendingByMarket,
      competitiveReportType: 'Vodafone',
    },
    // SOS - Largest Competitor
    {
      path: 'share-of-spend',
      reportKey: 'sos-largest-competitor',
      scope: ScopeOfDataType.Period,
      hook: CompetitiveReportsApi.useSOSSpendingLargestCompetitorByMarket,
      competitiveReportType: 'largest-competitor',
    },
    // SOS - Gap to Largest Competitor
    {
      path: 'share-of-spend',
      reportKey: 'sos-gap-to-largest-competitor',
      scope: ScopeOfDataType.Period,
      hook: CompetitiveReportsApi.useSOSLargestCompetitorGap,
      competitiveReportType: 'gap-largest-competitor',
    },
    // SOS - Largest Competitor Trend
    {
      path: 'share-of-spend',
      reportKey: 'sos-trend-vs-largest-competitor',
      scope: ScopeOfDataType.Period,
      hook: CompetitiveReportsApi.useSOSLargestCompetitorTrend,
      competitiveReportType: null,
    },
    // SOS - Gap to Nearest Competitor
    {
      path: 'share-of-spend',
      reportKey: 'sos-gap-to-nearest-competitor',
      scope: ScopeOfDataType.Period,
      hook: CompetitiveReportsApi.useSOSNearestCompetitorGap,
      competitiveReportType: null,
    },
  ],

  // Competitive SOV
  sov_spend: [
    // SOV - Overview
    {
      path: 'tv-share-of-voice',
      reportKey: 'sov-overview',
      scope: ScopeOfDataType.Period,
      hook: CompetitiveReportsApi.useSOVSpendingAllCompetitorsByMarket,
      competitiveReportType: 'all-competitors',
    },
    // SOV - By Market
    {
      path: 'tv-share-of-voice',
      reportKey: 'sov-by-market',
      scope: ScopeOfDataType.Period,
      hook: CompetitiveReportsApi.useSOVSpendingByMarket,
      competitiveReportType: 'Vodafone',
    },
    // SOV - Largest Competitor
    {
      path: 'tv-share-of-voice',
      reportKey: 'sov-largest-competitor',
      scope: ScopeOfDataType.Period,
      hook: CompetitiveReportsApi.useSOVSpendingLargestCompetitorByMarket,
      competitiveReportType: 'largest-competitor',
    },
    // SOV - Gap to Largest Competitor
    {
      path: 'tv-share-of-voice',
      reportKey: 'sov-gap-to-largest-competitor',
      scope: ScopeOfDataType.Period,
      hook: CompetitiveReportsApi.useSOVLargestCompetitorGap,
      competitiveReportType: 'gap-largest-competitor',
    },
    // SOV - Gap to Nearest Competitor
    {
      path: 'tv-share-of-voice',
      reportKey: 'sov-gap-to-nearest-competitor',
      scope: ScopeOfDataType.Period,
      hook: CompetitiveReportsApi.useSOVNearestCompetitorGap,
      competitiveReportType: null,
    },
  ]
}


const emptyQueryResult: UseQueryResult<ListItem[], Error> = {
  data: undefined,
  isError: false,
  isFetched: false,
  isFetchedAfterMount: false,
  isFetching: false,
  isIdle: true,
  isLoading: false,
  isLoadingError: false,
  isPlaceholderData: true,
  isPreviousData: false,
  isRefetchError: false,
  isRefetching: false,
  isStale: false,
  isSuccess: false,
  error: null,
  errorUpdateCount: 0,
  errorUpdatedAt: 0,
  refetch: () => new Promise(noop),
  remove: noop,
  status: 'idle',
  dataUpdatedAt: 0,
  failureCount: 0,
}

const useEmptyQueryResult = () => emptyQueryResult

type InvestmentReportHookProps = {
  url: string | undefined
  title: string
  reportKey?: CompetitiveReportKey
  scope?: string
  periodFormat: string
  filterOptions: PossibleFiltersOptions
  currencyType?: CurrencyType
}

type InvestmentReportHookValue = UseQueryResult<ListItem[], Error> & {
  spendingType: SpendingType
  exportParams: DataHookExportParams | null
  dataHookProps: DataByPeriodProps
  competitiveReportType: CompetitiveReportType | null
}


export const useInvestmentReportData = ({
  url,
  title,
  reportKey,
  scope = ScopeOfDataType.Period,
  periodFormat,
  filterOptions,
  currencyType,
}: InvestmentReportHookProps): InvestmentReportHookValue => {
  const period = `${periodFormat}ly`

  const dataHookProps: DataByPeriodProps = {
    period,
    currencyType,
    funding_sources: filterOptions[Filters.FundingSource],
    markets: filterOptions[Filters.Markets],
    media_sub_channels: filterOptions[Filters.MediaSubChannel],
    financial_years: filterOptions[Filters.FinancialYear],
    years: filterOptions[Filters.CalendarYears],
    media_channels: filterOptions[Filters.MediaChannel],
    campaigns: filterOptions[Filters.Campaign],
    sub_campaigns: filterOptions[Filters.SubCampaign],
    second_brands: filterOptions[Filters.SecondBrand],
    buy_types: filterOptions[Filters.BuyType],
    jbps: filterOptions[Filters.JBP],
    // Competitive
    actual_competitors: filterOptions[Filters.ActualCompetitor],
    competitors: filterOptions[Filters.Competitor],
    competitive_second_brands: filterOptions[Filters.CompetitiveSecondBrand],
    agencies: filterOptions[Filters.Agency],
    agency_discounts: filterOptions[Filters.AgencyDiscount],
    start_month: filterOptions[Filters.StartMonth],
    end_month: filterOptions[Filters.EndMonth],
    // TODO - switch to updated competitive API
  }

  let matchedDataHookConfig: DataHookConfig | null = null
  for( const [spendingType, hookConfigs] of Object.entries(dataHookConfigsBySpendingType) ){
    const match = find(
      hookConfigs,
      config => (
        url === config.path &&
        (reportKey ? reportKey === config.reportKey : true) &&
        (config.sectionTitle ? title === config.sectionTitle : true) &&
        scope === config.scope
      )
    ) || null
    if( match ){
      matchedDataHookConfig = {...match, spendingType: spendingType as SpendingType }
      break
    }
  }


  if( !matchedDataHookConfig ){
    console.warn(`Could not match report ${title}:${scope} to data hook config`)
  }

  const useDataHook = matchedDataHookConfig ? matchedDataHookConfig.hook : useEmptyQueryResult

  const queryResult = useDataHook(dataHookProps)

  return {
    ...queryResult,
    spendingType: matchedDataHookConfig && matchedDataHookConfig.spendingType || 'primary_spend',
    exportParams: matchedDataHookConfig && matchedDataHookConfig.exportParams || null,
    dataHookProps,
    competitiveReportType: matchedDataHookConfig && matchedDataHookConfig.competitiveReportType || null,
  }
}
