
import * as actions from './actions'
import reducer, { makeReducer } from './reducer'
import selectors, { makeSelectors } from './selectors'

import { ReduxBundle } from '@percept/types'


export { actions, reducer, selectors }

export default { actions, reducer, selectors } as ReduxBundle


export const makeBundle = (opts = {}): ReduxBundle => {

  return {
    actions,
    reducer: makeReducer(opts),
    selectors: makeSelectors(opts),
  }
}
