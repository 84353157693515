import { combineReducers, Reducer } from 'redux'

import { createApiReducer, createKeyedReducer } from '../../reducers'

import { LOAD_REPORT_SERIES_CONFIG_SCHEMA } from './actions'

import { getProviderKeyFromAction } from '../../utils'

import {
  ReduxAction,
} from '@percept/types'

import { JsonSchemaState } from './typings'


const jsonSchemasReducer: Reducer<JsonSchemaState, ReduxAction> = combineReducers({
  reportSeriesConfigs: createKeyedReducer(
    createApiReducer(LOAD_REPORT_SERIES_CONFIG_SCHEMA),
    getProviderKeyFromAction,
  ),
})


export default jsonSchemasReducer
