import React from 'react'

import { Link } from 'react-router-dom'

import { Box, RoundedPlainTextButton, RoundedPlainTextButtonMenu } from '@percept/mui'

import { ArrowDropDown, dimensionIcons, TableChart } from '@percept/mui/icons'

import { CurrencySelect } from 'components/CurrencySelect'

import { getPerformanceDimensionOptions } from 'components/PerformanceDimensionSelect'

import { DoubleVerifyReferenceDateTrigger, ReferenceDateSelect } from 'components/ReferenceDates'

import {
  usePerformanceReportingDimension,
  useReferenceDate,
  useReferenceDateBehaviour,
} from 'hooks'

import { getPotentialEfficiencyLabel } from 'components/Organisation'

import { dimensionMap } from '@percept/constants'

import { AnyPerformanceDimension, PlatformUnit } from '@percept/types'
import { platformUnitHasDoubleVerify } from 'components/Organisation/lib'
import { isPlatformUnitContainer } from '@percept/utils'


export type PerformanceControlsProps = {
  platformUnit: PlatformUnit
  availableDimensions: AnyPerformanceDimension[]
  referenceDate: Date | null
  doubleVerifyReferenceDate: Date | null
  requiresDoubleVerifyTrigger: boolean
  maxDate: Date | null
  showDimensionSelector?: boolean
  potentialEfficiencyEnabled?: boolean
  potentialEfficiencyActive?: boolean
  onDimensionChange?: (dimension: AnyPerformanceDimension | 'potential_efficiency') => void
}

export const PerformanceControls = ({
  platformUnit,
  availableDimensions,
  referenceDate,
  requiresDoubleVerifyTrigger,
  doubleVerifyReferenceDate,
  maxDate,
  showDimensionSelector = true,
  potentialEfficiencyEnabled = true,
  potentialEfficiencyActive = false,
  onDimensionChange,
}: PerformanceControlsProps): JSX.Element => {

  const [activeDimension, setActiveDimension] = usePerformanceReportingDimension()

  const potentialEfficiencyLabel = getPotentialEfficiencyLabel(platformUnit)

  const dimensionOptions = getPerformanceDimensionOptions(availableDimensions)
  const options = !potentialEfficiencyEnabled ? dimensionOptions : [
    {
      key: 'potentialEfficency',
      value: 'potential_efficiency',
      label: potentialEfficiencyLabel,
    },
    ...dimensionOptions
  ]

  const [ , setActiveReferenceDate] = useReferenceDate()

  const hasDoubleVerify = platformUnitHasDoubleVerify(platformUnit)

  const mediaQualityRateEnabled = hasDoubleVerify && !isPlatformUnitContainer(platformUnit)

  const dataWareHouseEnabled = process.env.APP === 'vodafone'

  const [ , setReferenceDateBehaviour] = useReferenceDateBehaviour()

  const TriggerIconComponent = activeDimension && dimensionIcons[activeDimension]

  const startIcon = TriggerIconComponent && (
    <TriggerIconComponent />
  )

  return (
    <Box display='flex' justifyContent='space-between'>

      <Box display='flex'>
        <Box mr={2}>
          <CurrencySelect
            TriggerProps={{
              variant: 'contained',
              color: 'secondary',
            }} />
        </Box>

        { showDimensionSelector &&(
          <Box mr={2}>
            <RoundedPlainTextButtonMenu
              value={
                potentialEfficiencyActive ?
                  'potential_efficiency' :
                  activeDimension
              }
              label={
                potentialEfficiencyActive ?
                  potentialEfficiencyLabel :
                  activeDimension && dimensionMap[activeDimension].text
              }
              TriggerProps={{
                startIcon,
                endIcon: <ArrowDropDown />,
                variant: 'contained',
                color: 'secondary',
              }}
              options={options}
              onChange={(e, dimension): void => {
                setActiveDimension(dimension)
                if( onDimensionChange ){
                  onDimensionChange(dimension)
                }
              }} />
          </Box>
        )}

        { !potentialEfficiencyActive && referenceDate && (
          <Box mr={5}>
            <ReferenceDateSelect
              value={referenceDate}
              hasDoubleVerify={hasDoubleVerify}
              maxDate={maxDate}
              onChange={setActiveReferenceDate} />
          </Box>
        )}

        { !potentialEfficiencyActive && requiresDoubleVerifyTrigger && doubleVerifyReferenceDate && (
          <DoubleVerifyReferenceDateTrigger
            referenceDate={doubleVerifyReferenceDate}
            onClick={(): void => {
              setReferenceDateBehaviour('LATEST_ALL_INCLUDING_DOUBLEVERIFY')
            }} />
        )}
      </Box>

      <Box display='flex' alignItems='center'>
        <Link to={`/dashboards/${platformUnit.id}/performance-report`}>
          <RoundedPlainTextButton
            color='secondary'
            variant='contained'
            startIcon={<TableChart />}>
            Custom Performance Report
          </RoundedPlainTextButton>
        </Link>

        { mediaQualityRateEnabled && (
          <Box ml={2}>
            <Link to={`/dashboards/${platformUnit.id}/media-quality-rate`}>
              <RoundedPlainTextButton
                color='secondary'
                variant='contained'
                startIcon={<TableChart />}>
                Media Quality Rate
              </RoundedPlainTextButton>
            </Link>
          </Box>
        )}

        { dataWareHouseEnabled && (
          <Box ml={2}>
            <Link to={`/dashboards/${platformUnit.id}/data-warehouse`}>
              <RoundedPlainTextButton
                color='secondary'
                variant='contained'
                startIcon={<TableChart />}>
                Data Warehouse (Beta)
              </RoundedPlainTextButton>
            </Link>
          </Box>
        )}
      </Box>

    </Box>
  )
}
