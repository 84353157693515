import React, { useContext } from 'react'

import {
  AppTheme,
  Box,
  ClassNameMap,
  Grid,
  MetricVisualisationWrap,
  Money,
  PercentageDonut,
} from '@percept/mui'

import { OverviewCard } from './OverviewCard'

import { OverviewContext } from './overviewContext'

import { deltaLabelMap, getDateLabel, getTooltipContent, overviewDimensionMetadata } from './lib'

import { percentagePointDeltaFormatter, percentageDeltaFormatter } from '@percept/mui/charts'

import { ComparisonMethod } from '@percept/utils'

import { MarketDatum, OverviewClassNames, OverviewGranularity } from './typings'


export type MarketDatumVisualisationProps = {
  datum: MarketDatum
  classes: ClassNameMap<OverviewClassNames>
  granularity: OverviewGranularity
  comparisonMethod: ComparisonMethod
  appTheme: AppTheme
  height?: number
  fontSize?: string | number
  deltaFontSize?: string | number
  isStaticData?: boolean
}

export const MarketDatumVisualisation = ({
  datum,
  classes,
  granularity,
  comparisonMethod,
  appTheme,
  height = 48,
  fontSize = 14,
  deltaFontSize = 18,
  isStaticData = false,
}: MarketDatumVisualisationProps): JSX.Element => {
  const dimensionMetadata = overviewDimensionMetadata[datum.dimension]

  const { referenceDate }= useContext(OverviewContext)

  const changeLabel = deltaLabelMap[granularity]

  const title = (
    isStaticData ?
      dimensionMetadata.label :
      `${dimensionMetadata.label} ${getDateLabel(granularity, referenceDate)}`
  )

  const datumGranularity: OverviewGranularity = (
    dimensionMetadata.category !== 'Optimisation' ?
      'year' :
      granularity
  )

  const tooltipContent = (
    isStaticData ?
      getTooltipContent(datumGranularity, new Date('2022-06-30')) :
      getTooltipContent(datumGranularity, referenceDate)
  )

  const percentageChangeFormatter = (
    comparisonMethod === 'ABSOLUTE' ?
      percentagePointDeltaFormatter :
      percentageDeltaFormatter
  )

  return (
    <OverviewCard
      elevation={3}
      classes={classes}
      title={title}
      category={dimensionMetadata.category}
      tooltipContent={tooltipContent}>
      <Box display='flex' alignItems='center' p={2}>
        <Grid container spacing={3} justify='space-between'>
          <MetricVisualisationWrap
            label={dimensionMetadata.scoreLabel || 'Score'}>
            { datum.current === null ? (
              <Box
                height={height}
                width='100%'
                display='flex'
                alignItems='center'
                justifyContent='center'
                fontSize={deltaFontSize}
                color='text.disabled'>
                N / A
              </Box>
            ) : (
              <PercentageDonut
                height={height}
                width={height}
                donutThickness={0.25} 
                fontSize={fontSize}
                value={datum.current}
                color={appTheme.palette.primary.main}
                trackColor={appTheme.palette.action.disabledBackground} />
            )}
          </MetricVisualisationWrap>
          <MetricVisualisationWrap
            label={changeLabel}>
            <Box
              height={height}
              width='100%'
              display='flex'
              alignItems='center'
              justifyContent='center'
              fontSize={deltaFontSize}
              fontWeight={datum.change === null ? 500 : 700}
              color={datum.change === null ? 'text.disabled' : 'inherit'}>
              { datum.change === null || datum.change === undefined ?
                'N / A' :
                percentageChangeFormatter(datum.change)
              }
            </Box>
          </MetricVisualisationWrap>
          { typeof datum.value !== 'undefined' && (
            <MetricVisualisationWrap
              label={'Value'}>
              <Box
                height={height}
                width='100%'
                display='flex'
                alignItems='center'
                justifyContent='center'
                fontSize={deltaFontSize}
                fontWeight={datum.value === null ? 500 : 700}
                color={datum.value === null ? 'text.disabled' : 'inherit'}>
                { datum.value === null ? 'N / A' : (
                  <Money abbreviate={false} amount={datum.value} currency='EUR' />
                )}
              </Box>
            </MetricVisualisationWrap>
          )}
          {/* {datum.previous && (<MetricVisualisationWrap
            label={dimensionMetadata.deltaLabel}>
            <Box
              height={height}
              width='100%'
              display='flex'
              alignItems='center'
              justifyContent='center'
              fontSize={deltaFontSize}
              fontWeight={delta === null ? 500 : 700}
              color={delta === null ? 'text.disabled' : 'inherit'}>
              { delta === null ? 'N / A' : (
                `${delta >= 0 ? '+' : ''}${round(delta, 2)}pp`
              )}
            </Box>
          </MetricVisualisationWrap>)} */}
          { datum.target && (<MetricVisualisationWrap
            label='Target'>
            { datum.target === null ? (
              <Box
                height={height}
                width='100%'
                display='flex'
                alignItems='center'
                justifyContent='center'
                fontSize={deltaFontSize}
                color='text.disabled'>
                N / A
              </Box>
            ) : (
              <PercentageDonut
                height={height}
                width={height}
                donutThickness={0.25}
                fontSize={fontSize}
                value={datum.target}
                color={appTheme.palette.primary.main}
                trackColor={appTheme.palette.action.disabledBackground} />
            )}
          </MetricVisualisationWrap>)}
        </Grid>
      </Box>
    </OverviewCard>
  )
}
