import React from 'react'

import { DefaultMediaWizardLayout } from './DefaultMediaWizardLayout'

import { Pathways as PathwaysComponent } from 'components/Pathways'
import { Breadcrumb } from 'components/Links'

import { useDocumentTitle } from '@percept/hooks'

const breadcrumbs: Breadcrumb[] = [
  {
    path: '/wizard/strategy',
    name: 'Strategy',
  },
  {
    path: '/wizard/strategy/pathways',
    name: 'Pathways',
  },
]

export const Pathways = (): JSX.Element => {
  useDocumentTitle({ paths: ['Strategy', 'Pathways'] })
  return (
    <DefaultMediaWizardLayout
      breadcrumbs={breadcrumbs}>

      <PathwaysComponent />

    </DefaultMediaWizardLayout>
  )
}
