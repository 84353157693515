import React from 'react'

import {
  Typography,
  makeAppStyles,
  Grid,
  AppTheme,
  useAppTheme,
  PercentageDonut,
  Loader,
  ChannelThemeProvider,
} from '@percept/mui'

import { KeyTasks } from '@percept/app-components'

import { ArrowDownward, ArrowUpward } from '@percept/mui/icons'

import { ChannelHeader } from 'components/ChannelHeader'

import { isNumber } from 'lodash-es'

import { HealthType, Insight, ReportProvider, ReportSeries } from '@percept/types'

import { useNavigation } from '@percept/hooks'

import { useInsightReportMetadata } from './useInsightReportMetadata'

import { providerChannelMap } from '@percept/constants'


export type ChannelDiagnosticsProps = {
  seriesListing: ReportSeries[] | null
  provider: ReportProvider
  insights?: Insight[]
}


const useStyles = makeAppStyles( theme => ({
  channelHeaderCardContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  healthContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  healthTypography: {
    marginRight: theme.spacing(1.5),
    fontSize: 14,
  },
  trendingIconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  trendingIcon: {
    fontSize: '3rem',
    marginRight: theme.spacing(1.5),
    color: theme.palette.action.disabled,
  },
  insight: {
    margin: theme.spacing(3, 0),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  insightsHeader: {
    marginBottom: theme.spacing(4),
  },
}))


const resolveHealthColor = (health: HealthType | undefined, appTheme: AppTheme): string => {
  if( !isNumber(health) ) return ''
  return appTheme.chart.healthColourScale(health)
}


const resolveHealthSentiment = (health: HealthType | undefined): string => {
  if( !health ) return 'critical'
  if( health < 0.34 ) return 'poor'
  if( health < 0.66 ) return 'okay'
  if( health < 0.9 ) return 'good'
  return 'excellent'
}



export const ChannelDiagnostics = (props: ChannelDiagnosticsProps): JSX.Element => {
  return (
    <ChannelThemeProvider
      channel={providerChannelMap[props.provider]}>
      <ChannelDiagnosticsContent {...props} />
    </ChannelThemeProvider>
  )
}


function ChannelDiagnosticsContent({ seriesListing, provider, insights }: ChannelDiagnosticsProps): JSX.Element {

  const channel = providerChannelMap[provider]

  const navigate = useNavigation()

  const classes = useStyles()

  const appTheme = useAppTheme()

  const { loading, health, trending } = useInsightReportMetadata(
    seriesListing,
    channel
  )
    
  const healthColor = resolveHealthColor(health, appTheme)

  const TrendIconComponent = (
    trending === 'down' ?
      ArrowDownward :
      trending === 'up' ?
        ArrowUpward :
        null
  )

  return (
    <Grid container spacing={1}>

      <Grid item xs={12}>
        <ChannelHeader
          CardContentProps={{
            className: classes.channelHeaderCardContent,
          }}
          channel={channel}
          color='health'
          health={health}
          seriesListing={seriesListing || undefined}
          onSeriesClick={({ id }): void => {
            navigate(`/series/${id}`)
          }}>

          { !loading ? (
            <div className={classes.healthContainer}>
              <Typography
                variant='subtitle1'
                className={classes.healthTypography}>
                Overall health is {resolveHealthSentiment(health)}
              </Typography>

              { TrendIconComponent && (
                <TrendIconComponent
                  className={classes.trendingIcon} />
              )}

              <PercentageDonut
                width={60}
                height={60}
                color={healthColor}
                donutThickness={0.2}
                fontSize={16}
                value={(health || 0) * 100} />

            </div>
          ) : (
            <Loader preset='centered' />
          )}

        </ChannelHeader>
      </Grid>

      <Grid item xs={12}>
        { insights && (
          <KeyTasks
            provider={provider}
            insights={insights} />
        )}
      </Grid>  

    </Grid>
  )
}
