
import { createAuditMetricReducer } from '@percept/redux/reducers'

import {
  LOAD_AUDIT_METRIC,
  LOAD_AUDIT } from '../actions'

import { LOAD_METRIC_METADATA } from '../../metricMetadata/actions' 


export default createAuditMetricReducer({
  loadAuditType: LOAD_AUDIT,
  loadMetricType: LOAD_AUDIT_METRIC,
  loadMetricMetadataType: LOAD_METRIC_METADATA,
})
