import React from 'react'

import { Link } from 'react-router-dom'

import {
  Box,
  Grid,
  GridProps,
  makeAppStyles,
  SecondaryTooltip,
  StatCard,
  StatCardDatum,
  Typography,
  useAppTheme,
} from '@percept/mui'

import { PercentageBlobVisualisation, PercentageVisualisation } from './PercentageVisualisations'

import { useLayoutGridSpacing } from 'hooks'

import { orgLabelDisplayMap } from '../Organisation'

import { get } from 'lodash-es'

import { getDomain } from '@percept/mui/charts/lib'

import { useNestedProviderPillarScores } from '@percept/hooks'

import { pillars, channelPillars, scaleValue, useQualityAnalysis, QualityAnalysisDatum, pillarDescriptionMap } from './lib'

import { MetricPillar, PlatformUnit, ReportProvider } from '@percept/types'

import { pillarLabelMap, providerChannelMap } from '@percept/constants'


const useStyles = makeAppStyles( theme => ({
  gridItem: {
    marginTop: theme.spacing(-1),
  },
  labelContainer: {
    marginTop: theme.spacing(-0.5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '5rem',
  },
  label: {
    marginBottom: theme.spacing(0.5),
  },
  caption: {
    padding: theme.spacing(0.5),
    fontSize: 11,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
    }
  },
}) )


const statCardGridProps: Partial<GridProps> = {
  spacing: 1,
  justify: 'space-evenly',
  alignItems: 'flex-start',
}


export const MultiOrgStructuralSummary = ({
  platformUnit,
  provider,
}: {
  platformUnit: PlatformUnit
  provider: ReportProvider | null
}): JSX.Element => {

  const [nestedPillarScores] = useNestedProviderPillarScores({
    org_unit_id: platformUnit.id,
  })

  const { unit_type } = platformUnit

  const label = unit_type && orgLabelDisplayMap[unit_type].toLowerCase() || 'dashboard'

  const {
    best,
    worst,
    improving
  } = useQualityAnalysis({
    provider,
    pillarScores: nestedPillarScores.data,
  })

  const gridSpacing = useLayoutGridSpacing()

  const classes = useStyles()

  const toStatCardData = (
    item: Record<MetricPillar, QualityAnalysisDatum | null> | null
  ): StatCardDatum[] => {
    const mappablePillars = provider ? channelPillars[providerChannelMap[provider]] : pillars
    return mappablePillars.map( pillar => {
      const match = get(item, pillar, null)
      return {
        value: match && match.value,
        label: (
          <span className={classes.labelContainer}>
            <Typography
              className={classes.label}
              variant='subtitle1'>
              <SecondaryTooltip
                placement='bottom-start'
                title={pillarDescriptionMap[pillar]}>
                <span>
                  { pillarLabelMap[pillar] }
                </span>
              </SecondaryTooltip>
            </Typography>
            { match && (
              <Link to={`/dashboards/${match.id}`}>
                <Typography
                  className={classes.caption}
                  variant='caption'>
                  { match.name }
                </Typography>
              </Link>
            )}
          </span>
        )
      }
    })
  }

  const improversData = improving ? toStatCardData(improving) : []

  const performersData = best ? toStatCardData(best) : []

  const improversDomain = getDomain(improversData)

  const opportunitiesData = worst ? toStatCardData(worst) : []

  const appTheme = useAppTheme()

  const improversColor = appTheme.palette.success.main

  const improversTextColor = appTheme.palette.background.paper

  const statCardGridItemProps: Partial<GridProps> = {
    xs: provider === 'adwords' ? 4 : 3,
    justify: 'space-between',
    alignItems: 'center',
    className: classes.gridItem,
  }

  return (
    <Grid container spacing={gridSpacing}>

      <Grid item xs={12} md={6} lg={4}>
        <StatCard
          title='Top Performers'
          description={`The ${label}s with the highest health scores for each category`}
          health={1}
          showHealthPercentage={false}
          data={performersData}
          GridProps={statCardGridProps}
          GridItemProps={statCardGridItemProps}
          VisualisationComponent={(props): JSX.Element =>
            props.value === null ? (
              <Box
                color='text.disabled'
                display='flex'
                alignItems='center'
                justifyContent='center'
                height={60}
                fontSize={16}
                fontWeight={700}>
                N / A
              </Box>
            ) : (
              <PercentageVisualisation
                {...props}
                width={58}
                height={58} />
            )
          } />
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <StatCard
          title='Top Improvers'
          description={
            `The ${label}s with the greatest absolute increase shown across the last 2 health scores for each category`
          }
          health={1}
          showHealthPercentage={false}
          data={improversData}
          GridProps={statCardGridProps}
          GridItemProps={statCardGridItemProps}
          VisualisationComponent={(props): JSX.Element =>
            props.value === null ? (
              <Box
                color='text.disabled'
                display='flex'
                alignItems='center'
                justifyContent='center'
                height={60}
                fontSize={16}
                fontWeight={700}>
                N / A
              </Box>
            ) : (
              <PercentageBlobVisualisation
                {...props}
                size={
                  scaleValue({
                    value: props.value,
                    domain: improversDomain,
                    range: [40, 60]
                  })
                }
                width={60}
                height={60}
                color={improversColor}
                textColor={improversTextColor} />
            )
          } />
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <StatCard
          title='Top Opportunities'
          description={`The ${label}s with the lowest health scores for each category`}
          health={0}
          showHealthPercentage={false}
          data={opportunitiesData}
          GridProps={statCardGridProps}
          GridItemProps={statCardGridItemProps}
          VisualisationComponent={(props): JSX.Element =>
            props.value === null ? (
              <Box
                color='text.disabled'
                display='flex'
                alignItems='center'
                justifyContent='center'
                height={60}
                fontSize={16}
                fontWeight={700}>
                N / A
              </Box>
            ) : (
              <PercentageVisualisation
                {...props}
                width={58}
                height={58} />
            )
          } />
      </Grid>

    </Grid>
  )
}
