
import { createApiReducer } from '@percept/redux/reducers'

import { LOAD_AUDIT } from '../actions'

import { audit } from '@percept/api/adapters'

import { Report, Dictionary } from '@percept/types'


const getPayloadFromAction = (
  { payload }: { payload: Dictionary<any> }
): Report => (
  audit(payload)
)


export default createApiReducer<Report>(LOAD_AUDIT, { getPayloadFromAction })

export const raw = createApiReducer(LOAD_AUDIT)
