import React, { FC, useState } from 'react'

import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  DatePicker,
  FormControl,
  FormHelperText,
  Grid,
  LinearProgress,
  Paper,
  PlainTextButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeAppStyles,
} from '@percept/mui'
import { ArrowDropDown, Check, Close, Edit } from '@percept/mui/icons'

import { Formik, FormikErrors } from 'formik'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { PartnershipsEvent, getEventsPaginated } from '../Events'
import { createEvent, deleteEvent, updateEvent } from '../partnershipsClient'
import { format, parseISO } from 'date-fns'
import { isEmpty } from 'lodash-es'
import { CategorySelect } from '../CategorySelect'
import { useNotifications } from '@percept/hooks'

const headers = ['Date', 'Event title', 'Event summary', 'Event lik', 'Post event information', 'Action']

const initialValues: PartnershipsEvent = {
  id: '',
  title: '',
  start_datetime: '',
  end_datetime: '',
  summary: '',
  partner_category: undefined,
  external_url: '',
  additional_info: '',
  local_timezone_info: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss'),
}

const useStyles = makeAppStyles( theme => ({
  tableHeader: {
    backgroundColor: theme.palette.background.default,
  },
  tableRow: {
    height: '50px'
  },
  tableHeaderCell: {
    border: `1px solid ${theme.palette.divider}`,
    padding: '2px'
  },
  tableCell: {
    padding: '2px',
    border: `1px solid ${theme.palette.divider}`,
  },
  tableContainer: {
    marginTop: '25px',
    overflow: 'hidden',
    position: 'relative',
  },
  inputField: {
    padding: '10px'
  },
  actionButton: {
    margin: '5px',
    width: '80%',
    fontSize: '12px'
  }
}))

const useBackdropStyles = makeAppStyles( theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

export const SubmitEvents: FC = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const [page, setPage] = useState(1)
  const [,addNotification] = useNotifications()
  const [isUpdate, setIsUpdate] = useState(false)
  const [open, setOpen] = useState(false)
  const [event, setEvent] = useState('')

  const handleClose = (): void => {
    setOpen(false)
  }
  
  const handleOpen = (id: string): void => {
    setEvent(id)
    setOpen(true)
  }

  const createEventMutation = useMutation(async (data: PartnershipsEvent) => {
    if(isUpdate){
      await updateEvent(data)
    }else{
      await createEvent(data)
    }
  },
  {
    onSuccess: () => {
      queryClient.invalidateQueries(['events'])
    },
    onSettled: () => {
      setIsUpdate(false)
    }
  })

  const deleteEventMutation = useMutation(async (id: string) => {
    await deleteEvent(id)
  },
  {
    onSuccess: () => {
      queryClient.invalidateQueries(['events'])
      addNotification({
        type: 'success',
        name: 'Success',
        message: 'Event deleted successfully',
        ttl: 1500
      })
    },
    onError: () => {
      addNotification({
        type: 'error',
        name: 'Error',
        message: 'Failed to delete',
        ttl: 1500
      })
    }
  })

  const { data, isPreviousData } = useQuery({
    queryKey: ['events', { page }],
    queryFn: () => getEventsPaginated(page, null), 
    keepPreviousData: true,
  })
  

  return (
    <Box p={3} bgcolor="grey.100">
      <Backdrop 
        open={open}
        onClick={handleClose} 
        classes={useBackdropStyles()}>
        <Alert
          variant='info'
          header='Confirm Delete'
          p={2}>
          <Box p={2}>Are you sure? This action cannot be undone</Box>
          <Box display='flex' justifyContent='space-between' px={3}>
            <PlainTextButton variant='contained' onClick={(): void => deleteEventMutation.mutate(event)}>Yes</PlainTextButton>
            <PlainTextButton variant='contained' onClick={(): void => handleClose()}>No</PlainTextButton>
          </Box>
        </Alert>
      </Backdrop>
      <Formik
        initialValues={initialValues}
        validate={(values): FormikErrors<PartnershipsEvent> => {
          const errors: FormikErrors<PartnershipsEvent> = {}
          if( !values.title ){
            errors.title = 'Title is required'
          }
          if( !values.start_datetime ){
            errors.start_datetime = 'Start date is required'
          }
          if( !values.end_datetime ){
            errors.end_datetime = 'End date is required'
          }
          if( values.partner_category === undefined ){
            errors.partner_category = 'Partner is required'
          }
          if( !values.summary ){
            errors.summary = 'Summary is required'
          }
          if( !values.external_url ){
            errors.external_url = 'Event URL is required'
          }
         
          return errors
        }}
        onSubmit={(values: PartnershipsEvent, { resetForm }): void => {
          createEventMutation.mutate(values, { onSuccess: () => resetForm()})
        }}>
        {({ values, setValues, setFieldValue, handleChange, handleBlur, handleSubmit, errors }): JSX.Element => (
          <>
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  {createEventMutation.error && (
                    <Box p={2}>
                      <Typography variant='h5' color='primary'>
                        An error occurred and the event could not be stored.
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth className={classes.inputField}>
                    <FormHelperText>Start date</FormHelperText>
                    <DatePicker
                      helperText={errors.start_datetime}
                      error={!!errors.start_datetime}
                      variant='dialog'
                      format='dd/MM/yy'
                      value={values.start_datetime || null}
                      onChange={(value): void => {
                        if (value) {
                          setFieldValue('start_datetime', format(value, 'yyyy-MM-dd\'T\'HH:mm:ss'))
                        }
                      } } />
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth className={classes.inputField}>
                    <FormHelperText>End date</FormHelperText>
                    <DatePicker
                      helperText={errors.end_datetime}
                      error={!!errors.end_datetime}
                      variant='dialog'
                      format='dd/MM/yy'
                      value={values.end_datetime || null}
                      onChange={(value): void => {
                        if (value) {
                          setFieldValue('end_datetime', format(value, 'yyyy-MM-dd\'T\'HH:mm:ss'))
                        }
                      } } />
                  </FormControl>
                </Grid>

                <Grid item xs={8}>
                  <FormControl className={classes.inputField}>
                    <FormHelperText>Event Category</FormHelperText>
                    <CategorySelect
                      value={values.partner_category}
                      OverrideTriggerComponent={PlainTextButton}
                      TriggerProps={{
                        size: 'large',
                        style: {display: 'inline-flex'},
                        variant: 'contained',
                        color: 'default',
                        endIcon: <ArrowDropDown />,
                      }}
                      onChange={(e, value): void => {
                        setFieldValue('partner_category', value)
                      }} />
                  </FormControl>
                </Grid>

                <Grid item xs={8}>
                  <FormControl fullWidth className={classes.inputField}>
                    <FormHelperText>Title</FormHelperText>
                    <TextField
                      helperText={errors.title}
                      variant='filled'
                      error={!!errors.title}
                      name='title'
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur} />
                  </FormControl>
                </Grid>

                <Grid item xs={8}>
                  <FormControl fullWidth className={classes.inputField}>
                    <FormHelperText>Event summary</FormHelperText>
                    <TextField
                      helperText={errors.summary}
                      variant='filled'
                      error={!!errors.summary}
                      multiline
                      rows={5}
                      name='summary'
                      value={values.summary}
                      onChange={handleChange}
                      onBlur={handleBlur} />
                  </FormControl>
                </Grid>

                <Grid item xs={8}>
                  <FormControl fullWidth className={classes.inputField}>
                    <FormHelperText>Link</FormHelperText>
                    <TextField
                      helperText={errors.external_url}
                      variant='filled'
                      error={!!errors.external_url}
                      name='external_url'
                      value={values.external_url}
                      onChange={handleChange}
                      onBlur={handleBlur} />
                  </FormControl>
                </Grid>

                <Grid item xs={8}>
                  <FormControl fullWidth className={classes.inputField}>
                    <FormHelperText>Post event information</FormHelperText>
                    <TextField
                      helperText={errors.additional_info}
                      variant='filled'
                      error={!!errors.additional_info}
                      multiline
                      rows={5}
                      name='additional_info'
                      value={values.additional_info}
                      onChange={handleChange}
                      onBlur={handleBlur} />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Box mt={4} display='flex' justifyContent='flex-end' alignItems='end'>
                    <PlainTextButton
                      type='submit'
                      variant='contained'
                      disabled={!isEmpty(errors) || createEventMutation.isLoading}
                      color='primary'
                      startIcon={createEventMutation.isLoading ?
                        <CircularProgress size='1em' color='inherit' /> :
                        <Check />}>
                      {isUpdate ? 'Update Event': 'Add New Event'}
                    </PlainTextButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
              <TableContainer className={classes.tableContainer} component={Paper}>
                { isPreviousData && (
                  <LinearProgress variant='indeterminate' style={{position: 'absolute', width: '100%'}} />
                )}
                <Table aria-label="All-Events">
                  <TableHead className={classes.tableHeader}>
                    <TableRow className={classes.tableRow}>
                      {headers.map((header: string) => (
                        <TableCell className={classes.tableHeaderCell} key={header} align="center">{header}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data && (data.items || []).map((event: PartnershipsEvent) => (
                      <TableRow key={event.id}>
                        <TableCell className={classes.tableCell} align="center">
                          Start date:{format(parseISO(event.start_datetime), 'yyyy-MM-dd')}
                          <br />
                          End date:{format(parseISO(event.end_datetime), 'yyyy-MM-dd')}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {event.title}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {event.summary}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {event.external_url && <a href={event.external_url} target='_blank' rel='noopener noreferrer'>Link</a>}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {event.additional_info || ''}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          <PlainTextButton
                            startIcon={<Edit/>}
                            className={classes.actionButton}
                            size='small'
                            variant='contained'
                            onClick={(): void => {
                              setIsUpdate(true)
                              const match = data.items.find( evt => evt.id === event.id )
                              if( match ){
                                setValues(match)
                              }
                            }}>
                            Edit
                          </PlainTextButton>
                          <PlainTextButton
                            startIcon={<Close/>}
                            color='secondary'
                            size='small'
                            className={classes.actionButton}
                            variant='contained'
                            onClick={(): void => handleOpen(event.id?.toString())}>
                            Delete
                          </PlainTextButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {data && (
                  <Box display='flex' justifyContent='space-between' p={2}>
                    <PlainTextButton
                      variant='contained'
                      disabled={isPreviousData || !data.previousPage}
                      onClick={(): void => setPage(data.previousPage || page)}>
                      Previous
                    </PlainTextButton>
                    <Typography>{page}</Typography>
                    <PlainTextButton
                      variant='contained'
                      disabled={isPreviousData || !data.nextPage}
                      onClick={(): void => setPage(data.nextPage || page)}>
                      Next
                    </PlainTextButton>
                  </Box>
                )}
              </TableContainer>
          </>
        )}
      </Formik>
    </Box>
  )
}