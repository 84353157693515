import React from 'react'

import { FlagIconProps } from './lib'

import * as flags from './flags'


export type FlagProps = FlagIconProps & { isoCode: flags.FlagName }

export const Flag = ({ isoCode, ...props }: FlagProps): JSX.Element | null => {
  const FlagIcon = flags[isoCode]
  if( FlagIcon ){
    return <FlagIcon {...props} />
  }
  return null
}
