import React from 'react'

import { DefaultMediaWizardLayout } from './DefaultMediaWizardLayout'

import { BrandGrowthPlanner as BrandGrowthPlannerComponent } from 'components/BrandGrowthPlanner'
import { Breadcrumb } from 'components/Links'

import { useDocumentTitle } from '@percept/hooks'


const breadcrumbs: Breadcrumb[] = [
  {
    path: '/wizard/strategy',
    name: 'Strategy',
  },
  {
    path: '/wizard/strategy/brand-growth-planner',
    name: 'Brand Growth Planner',
  },
]


export const BrandGrowthPlanner = (): JSX.Element => {
  useDocumentTitle({ paths: ['Strategy', 'Brand Growth Planner'] })
  return (
    <DefaultMediaWizardLayout
      breadcrumbs={breadcrumbs}>

      <BrandGrowthPlannerComponent />

    </DefaultMediaWizardLayout>
  )
}
