
import {
  SET_SERIES_CHART_TYPE,
  SET_SERIES_PERFORMANCE_TAIL,
  SET_SERIES_HEALTH_DIMENSION,
} from '../actions'

import {
  Reducer,
} from '@percept/types'

import { SeriesState } from '../typings'


const initialState: SeriesState['settings'] = {
  chartType: 'area',
  healthDimension: 'count',
  performanceTail: null,
}


const settings: Reducer<SeriesState['settings']> = (state = initialState, action) => {

  switch(action.type){

    case SET_SERIES_CHART_TYPE:
      return {
        ...state,
        chartType: action.payload
      }

    case SET_SERIES_PERFORMANCE_TAIL:
      return {
        ...state,
        performanceTail: action.payload
      }

    case SET_SERIES_HEALTH_DIMENSION:
      return {
        ...state,
        healthDimension: action.payload
      }

    default:
      return state
  }

}

export default settings
