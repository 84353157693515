
import React, { Fragment } from 'react'

import {
  Alert,
  AlertProps,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  TypographyProps,
  makeAppStyles,
} from '@percept/mui'

import { CheckCircle, Cancel } from '@percept/mui/icons'

import { hasNoTrailingWhitespace, hasMixedCase, hasNumbers, hasSymbols } from './utils'


const { PASSWORD_REQUIREMENTS } = process.env


export const MessageContent = (): JSX.Element => (
  <Fragment>
    <Typography>Your password must:</Typography>
    <ul>
      <li>Contain at least { PASSWORD_REQUIREMENTS.LENGTH } characters</li>
      <li>Have no whitespace characters at the beginning or end</li>
      { PASSWORD_REQUIREMENTS.MIXED_CASE && <li>Contain a mix of uppercase and lowercase characters</li> }
      { PASSWORD_REQUIREMENTS.SYMBOLS && <li>Contain at least one special character</li> }
      { PASSWORD_REQUIREMENTS.NUMBERS && <li>Contain at least one number</li> }
    </ul>
  </Fragment>
)


const listItemTypographyProps: TypographyProps = {
  variant: 'subtitle1'
}


const useStyles = makeAppStyles( theme => ({
  failIcon: {
    color: theme.palette.action.disabled,
    transition: theme.transitions.create('color'),
  },
  passIcon: {
    color: theme.palette.secondary.contrastText,
    transition: theme.transitions.create('color'),
  }
}))


const PasswordRequirement = ({
  condition,
  text,
}: {
  condition: boolean
  text: string
}): JSX.Element => {
  const classes = useStyles()
  const IconComponent = condition ? CheckCircle : Cancel
  return (
    <ListItem>
      <ListItemIcon
        className={condition ? classes.passIcon : classes.failIcon}>
        <IconComponent color='inherit' />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={listItemTypographyProps}
        primary={text} />
    </ListItem>
  )
}


const LivePasswordRequirements = ({
  password,
  temporaryPassword,
}: {
  password: string
  temporaryPassword: string | null
}): JSX.Element => {
  return (
    <Fragment>
      <Typography>Your password must:</Typography>
      <List dense>
        {/* Temporary password not allowed */}
        { temporaryPassword !== null && (
          <PasswordRequirement
            condition={!!password && (password !== temporaryPassword)}
            text='Not be equal to your temporary password' />
        )}
        {/* Password length */}
        <PasswordRequirement
          condition={password.length >= PASSWORD_REQUIREMENTS.LENGTH}
          text={`Contain at least ${PASSWORD_REQUIREMENTS.LENGTH} characters`} />
        {/* Whitespace */}
        <PasswordRequirement
          condition={hasNoTrailingWhitespace(password)}
          text='Have no whitespace characters at the beginning or end' />
        {/* Mixed case */}
        { PASSWORD_REQUIREMENTS.MIXED_CASE && (
          <PasswordRequirement
            condition={hasMixedCase(password)}
            text='Contain a mix of uppercase and lowercase characters' />
        )}
        {/* Special characters */}
        { PASSWORD_REQUIREMENTS.SYMBOLS && (
          <PasswordRequirement
            condition={hasSymbols(password)}
            text='Contain at least one special character' />
        )}
        {/* Digits */}
        { PASSWORD_REQUIREMENTS.NUMBERS && (
          <PasswordRequirement
            condition={hasNumbers(password)}
            text='Contain at least one number' />
        )}
      </List>
    </Fragment>
  )
}


export const PasswordRequirements = ({
  password,
  temporaryPassword = null,
  ...props
}: { password: string, temporaryPassword?: string | null } & Partial<AlertProps>): JSX.Element => (
  <Alert
    variant='info'
    header='Password Requirements'
    message={
      <Box mt={2} px={1}>
        <LivePasswordRequirements
          password={password}
          temporaryPassword={temporaryPassword} />
      </Box>
    }
    {...props} />
)