import { PrefillPlaceholder } from '../types'

export enum QuestionType {
  INPUT = 'input',
  RADIO = 'radio',
  SELECT = 'select',
}

export enum InputType {
  TEXT = 'text',
  NUMBER = 'number',
  RADIO = 'radio',
}

export enum InputFormat {
  PERCENT = 'percent',
}

export interface FormElementAttrs {
  attrs?: { [k: string]: any }
}

export interface Question {
  id: string
  label: string
  question_type: QuestionType
  question_key: string
  help_text?: string
  prefill_key?: string
  is_required?: boolean
  is_readonly?: boolean
  value?: any
}

export interface InputQuestion extends Question, FormElementAttrs {
  input_type: InputType
  question_type: QuestionType.INPUT
  input_format?: InputFormat
}

export type OptionType = FormElementAttrs & {
  label: string
  description?: string | null
}

export interface RadioQuestion extends Question {
  options: OptionType[]
  question_type: QuestionType.RADIO
}

export interface SelectQuestion extends Question, FormElementAttrs {
  options: OptionType[]
  question_type: QuestionType.SELECT
}

export type FieldsetQuestion = InputQuestion | RadioQuestion | SelectQuestion

export interface ReportSchemaFieldset {
  legend?: string
  fieldsets?: ReportSchemaFieldset[]
  questions?: FieldsetQuestion[]
}

export interface ReportSchema {
  id: string
  schema: ReportSchemaFieldset
}

export interface ReportSchemaWithDetails extends ReportSchema {
  prefill_placeholders: PrefillPlaceholder[]
}
