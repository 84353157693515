/* eslint-disable no-console */
import { palette } from '@percept/theme'

import {
  Dictionary,
  MetricSegmentType,
  ReportDates,
  MetricDataType,
  DateType,
  HealthType,
  WithHealthType,
  RAGColour,
  RAGObject,
  DatumType,
  LayoutNodeType,
  LayoutMetricType,
  LayoutOptionalMetrics,
  DateRange,
  ApiResponse,
  AnnotationPoints,
  AnnotationPoint,
  AnnotationLevel,
  DimensionType,
  AppBranding,
  ParsedDimension,
  HealthAggregation,
  HealthAggregations,
  WithHealthAggregations,
  LayoutType,
  MetricsPayload,
  Task,
  ReportMetricsPayload,
  PlatformUnit,
  PlatformUnitContainer,
  ReportProviderV2,
  ReportProvider,
  URLReportProvider,
  DistributionMetricData,
  ProportionMetricData,
  ValueMetricData,
  AnyPerformanceDimension,
  ReportProviderEnum,
  DoubleVerifyProvider,
  DoubleVerifyProviderEnum,
  AnyApiResponse,
  ApiResponseIdle,
  ApiResponseLoading,
  ApiResponseError,
  PerformanceDimensionType,
  StructuralReport,
  StructuralReportResult,
  StructuralReportResultType,
  StructuralReportResultSummary,
  PerformanceProvider,
  PerformanceProviderEnum,
} from '@percept/types'

import { timeFormat } from 'd3-time-format'

import { ScaleLinear, scaleLinear } from 'd3-scale'

import NumberAbbreviate from 'number-abbreviate'

import { find, round as lodashRound } from 'lodash-es'

import { format as dateFormat, max as maxDate, min as minDate } from 'date-fns'

import { saveAs } from 'file-saver'

import { dimensionMap } from '@percept/constants'


/* Dev */

export const trace = (thing: any): any => { console.info(thing); return thing }

/* Types */

export const isArray = <T>(thing: unknown): thing is any[] => Object.prototype.toString.call(thing) === '[object Array]'

export const isObject = <T>(thing: unknown): thing is object => thing !== null && Object.prototype.toString.call(thing) === '[object Object]'

export const isPromise = <T>(thing: any): thing is Promise<any> => !!(thing && typeof thing.then === 'function')

export const isDefined = <T>(thing: unknown): boolean => typeof thing !== 'undefined'

export const isNumber = (n: any): n is number => typeof n === 'number' && !isNaN(n)

/* Properties */


export const getPath = <T>(
  obj: any,
  path: any | any[],
  defaultValue: T = null
): T => {
  let nested = obj,
      match = true
  if( typeof path === 'string' || isNumber(path) ){
    return obj && isDefined(obj[path]) ? obj[path] : defaultValue
  }
  if( !isArray(path) ){
    if( path ){
      console.warn('Incorrect usage of getPath(), expected string or array, received ', typeof path, path)
      console.warn('Called on', obj)
    }
    return defaultValue
  }
  path.forEach( prop => {
    if( nested && isDefined(nested[prop]) ){
      nested = nested[prop]
    }else{
      match = false
      nested = {}
    }
  })
  return match ? nested as T : defaultValue
}

export const sortBy = <T>(
  arr: T[],
  keys: string | string[],
  reverse = false,
  defaultValue: any = undefined
): T[] =>
    arr.slice().sort( (a, b) => {
      defaultValue = typeof defaultValue !== 'undefined' ? defaultValue : reverse ? -Infinity : Infinity
      const compareA = getPath(a, keys, defaultValue),
            compareB = getPath(b, keys, defaultValue)
      if( compareA > compareB ) return reverse ? -1 : 1
      if( compareA == compareB ) return 0
      return reverse ? 1 : -1
    })


/* Mappers */

export { mapValues } from 'lodash-es'


export const mapKeys = <T extends Dictionary, V>(
  obj: T,
  mapper: (key: string, value: V, object: T) => string
): Dictionary<V> => (
    Object.keys(obj).reduce( (acc: Dictionary<V>, k: string) => {
      const key = mapper(k, obj[k] as V, obj)
      acc[key] = obj[k]
      return acc
    }, {})
  )



/* Filters */

export const omit = <T>(
  obj: Dictionary<T>,
  omitKeys: string[]
): Dictionary<T> =>
    Object.keys(obj).reduce( (acc: Dictionary<T>, k: string) => {
      if( omitKeys.indexOf(k) === -1 ) acc[k] = obj[k]
      return acc
    }, {})


export const include = <T>(
  obj: Dictionary<T>,
  includeKeys: string[]
): Dictionary<T> =>
    Object.keys(obj).reduce( (acc: Dictionary<T>, k: string) => {
      if( includeKeys.indexOf(k) !== -1 ) acc[k] = obj[k]
      return acc
    }, {})


/* Array */

const isFalsy = <T>(thing: T): boolean => !thing

export const trim = <T>(
  arr: T[],
  shouldTrimItem: (item: T) => boolean = isFalsy
): T[] => {
  if( !arr || !arr.length ){
    return []
  }

  const trimmed = arr.slice(),
        len = arr.length
  
  let i = 0

  for( ; i++; i < len ){
    if( shouldTrimItem(arr[i]) ){
      trimmed.shift()
    }else{
      break
    }
  }

  i = len

  while( i-- ){
    if( shouldTrimItem(arr[i]) ){
      trimmed.pop()
    }else{
      break
    }
  }

  return trimmed
}


type PassArray<T> = T[]
type FailArray<T> = T[]

export const partition = <T>(
  arr: T[],
  predicate: (item: T) => boolean
): [PassArray<T>, FailArray<T>] => {

  const pass: T[] = []

  const fail: T[] = []
  
  let i = arr.length

  while( i-- ){
    if( predicate(arr[i]) ) pass.push(arr[i])
    else fail.push(arr[i])
  }
  
  return [pass, fail]
}


export const difference = <T>(
  primary: T[],
  secondary: T[]
): T[] =>
    primary.reduce( (diff: T[], item: T) => {
      if( !secondary.includes(item) ){
        diff.push(item)
      }
      return diff
    }, [])


export const differenceBy = (
  prop: string
) => <T>(
  source: Dictionary<T>[],
  target: Dictionary<T>[]
): Dictionary<T>[] =>
    target.reduce( (arr: Dictionary<T>[], item: Dictionary<T>) => {
      let hasItem = false
      for( let i = 0; i < source.length; i++ ){
        if( source[i][prop] === item[prop] ){
          hasItem = true
          break
        }
      }
      if( !hasItem ){
        arr.push(item)
      }
      return arr
    }, [])


export const uniq = <T>(
  arr: T[]
): T[] => {
  const seen: T[] = [],
        output: T[] = []
  arr.forEach( item => {
    if( !seen.includes(item) ){
      seen.push(item)
      output.push(item)
    }
  })
  return output
}

export const uniqBy = (
  prop: string
): <T extends Dictionary>(x: T[]) => T[] => <T extends Dictionary>(
  arr: T[]
): T[] => {
  const seen: T[] = []

  const output: T[] = []

  arr.forEach( item => {
    if( !seen.includes(item[prop]) ){
      seen.push(item[prop])
      output.push(item)
    }
  })

  return output
}


export const union = <T>(
  primary: T[],
  secondary: T[]
): T[] => uniq([...primary, ...secondary])


export const unionBy = (
  prop: string
): <T extends Dictionary>(...arrs: T[][]) => T[] => <T extends Dictionary>(
  ...arrs: T[][]
): T[] =>
    uniqBy(prop)<T>(
      arrs.reduce((a: T[], b: T[]): T[] => a.concat(b))
    )


export const intersection = <T>(
  primary: T[],
  secondary: T[]
): T[] =>
    primary.reduce( (arr: T[], item: T) => {
      if( secondary.includes(item) ){
        arr.push(item)
      }
      return arr
    }, [])



export const any = <T>(
  items: T[] | null | undefined,
  predicate: (x: T) => boolean = Boolean
): boolean => {
  if( items ){
    let i = items.length || 0
    while( i-- ){
      if( predicate(items[i]) ) return true
    }
  }
  return false
}


export const all = <T>(
  items: T[] | null | undefined,
  predicate: (x: T) => boolean = Boolean
): boolean => {
  if( items ){
    let i = items.length || 0
    if( i === 0 ){
      return false
    }
    while( i-- ){
      if( !predicate(items[i]) ) return false
    }
    return true
  }
  return false
}


export const takeWhile = <T>(
  items: T[] | null | undefined,
  predicate: (x: T) => boolean = Boolean
): T[] => {
  const arr = []
  let i = 0
  if( items && items.length ){
    while( i < items.length ){
      if( predicate(items[i]) ){
        arr.push(items[i])
        i++
      }else{
        break
      }
    }
  }
  return arr
}

export const takeWhileRight = <T>(
  items: T[] | null | undefined,
  predicate: (x: T) => boolean = Boolean
): T[] => {
  const arr = []
  let i = items && items.length || 0
  if( items ){
    while( i-- ){
      if( predicate(items[i])){
        arr.push(items[i])
      }else{
        break
      }
    }
  }
  return arr
}


export const findNext = <T>(
  items: T[] | null | undefined,
  predicate: (x: T) => boolean = Boolean
): T | null => {
  if( items && items.length ){
    let i = 0
    while( i < items.length ){
      if( predicate(items[i]) ){
        return items[i]
      }
      i++
    }
  }
  return null
}


export const findLast = <T>(
  items: T[] | null | undefined,
  predicate: (x: T) => boolean = Boolean
): T | null  => {
  if( items ){
    let i = items.length || 0
    while( i-- ){
      if( predicate(items[i]) ){
        return items[i]
      }
    }
  }
  return null
}


export const first = <T>(
  items: T[]
): T => items[0]


export const last = <T>(
  items: T[]
): T => items[items.length - 1]


export const arrayMove = <T>(items: T[], oldIndex: number, newIndex: number): T[] => {
  if (newIndex >= items.length) {
    let k = newIndex - items.length + 1
    while( k-- ){
      items.push(undefined as unknown as T)
    }
  }
  items.splice(newIndex, 0, items.splice(oldIndex, 1)[0])
  return items
}


/* Generic collection helpers */

export const size = (collection: object | any[] | null): number =>
  isArray(collection) ? collection.length :
    isObject(collection) ? Object.keys(collection).length :
      0


export const shallowEqual = <T>(
  objectA: Dictionary<T> | null | undefined,
  objectB: Dictionary<T> | null | undefined,
): boolean => {
  if( objectA === objectB ) return true

  if( !isObject(objectA) || !isObject(objectB) ) return false
  
  const aKeys = Object.keys(objectA)
  const bKeys = Object.keys(objectB)

  if( aKeys.length !== bKeys.length ) return false

  for( const key of aKeys ){
    if( objectA[key] !== objectB[key] ) return false
  }

  return true
}


/* Generic array methods */

export const flatten = <T>(...arrs: T[][]): T[] => (
  arrs.reduce( (acc, arr) => acc.concat(arr), [])
)

export const range = (...args: number[]): number[] => {
  let start = 0,
      stop = 1,
      step = 1

  if( args.length === 1 ){
    start = 0
    stop = args[0]
    step = 1
  }else if( args.length > 1 ){
    start = args[0]
    stop = args[1]
    step = args[2] || 1
  }

  const result = []

  while( start < stop ){
    result.push(start)
    start += step
  }

  return result
}


/* String methods */

export const capitalize = (str: string): string => (str || '').charAt(0).toUpperCase() + (str || '').slice(1)

export const capitalCase = (str: string): string => (str || '').charAt(0).toUpperCase() + (str || '').slice(1).toLowerCase()

export const capitalizeAll = (sentence: string): string => (sentence || '').split(' ').map(capitalize).join(' ')

export const slugify = (str: string): string => (str || '').split(' ').join('-').toLowerCase()

export const deslugify = (text: string): string => text.split('_').map(capitalCase).join(' ')

export const slugifyDateRange = (dateRange: [number, number], format = '%d-%m-%Y'): string => {
  const formatter = timeFormat(format)
  return dateRange.map( date => formatter(new Date(date)) ).join('--')
}


const EMAIL_RE = (
  /**
   * https://regexr.com/2rhq7
   */
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
)

export const isValidEmail = (email: string): boolean => (
  !!email.match(EMAIL_RE)
)


/* Functions */

export const identity = <T>(thing: T): T => thing

export const throttle = (fn: (...params: any[]) => any, limit: number): (() => void) => {
  let wait = false
  return function(this: Function): void {
    const context = this,
          args = arguments
    if( !wait ){
      fn.apply(context, [].slice.call(args))
      wait = true
      setTimeout( () => {
        wait = false
      }, limit)
    }
  }
}

export const debounce = (fn: (...params: any[]) => any, delay: number): (() => void) => {
  let timer: any = null
  return function(this: Function): void {
    const context = this,
          args = arguments
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(context, [].slice.call(args))
    }, delay)
  }
}


export const pipe = <T = any>(
  ...fns: Function[]
) => (x: any): T => fns.reduce((v, f) => f(v), x)


/* Maths */



const add = (a: number, b: number): number => a + b

export const sum = (values: number[]): number => values.reduce(add, 0)

export const avg = (values: number[]): number => values.length ? sum(values) / values.length : 0

export const clamp = (value: number, min: number, max: number): number => Math.min(Math.max(value, min), max)

export const round = lodashRound

export const getPercentage = (segment: number, total: number, precision = 2): number => (
  round((segment / total) * 100, precision)
)

export const normalize = (values: number[]): number[] => {
  const total = sum(values)
  const normalizer = total === 0 ? 0 : 1 / total
  return values.map( v => v * normalizer )
}

export const zip = <A, B>(a: A[], b: B[]): ([A, B])[] => {
  const output = []
  for( let i = 0; i < a.length; i++ ){
    output.push([ a[i], b[i] ] as [ A, B])
  }
  return output
}

export const weightedScore = (scores: number[], weights: number[]): number => {
  const scoreables = zip(scores, normalize(weights))
  return sum(
    scoreables.map( ([ score, weight ]) => score * weight )
  )
}

export const metricDataToArray = (data: Dictionary | any[] | null): MetricSegmentType[] => (
  data && !isArray(data) && isDefined(data.value) ?
    [data] :
    isArray(data) ?
      data :
      []
)

export const getHealthScore = (data: MetricDataType | null): number | null => {

  const filtered = metricDataToArray(data).filter( d => isNumber(d.value) && isNumber(d.health) )

  if( filtered.length === 0 ){
    return null
  }

  return clamp(
    weightedScore(
      filtered.map( d => d.health || 0 ),
      filtered.map( d => d.value || 0 )
    ),
    0,
    1
  )
}


// Derived performance rate metrics return null when a valid calculation can not be made.
// Consuming code can therefore decide what to do with 'null' values,
// rather than assuming a value of 0 has been determined from valid input. 

export const getCPC = (
  { cost, clicks }:
  { cost: number | null; clicks: number | null }
): number | null => {
  if( isNumber(cost) && isNumber(clicks) && clicks > 0 ){
    return cost / clicks
  }
  return null
}

export const getCPA = (
  { cost, conversions }:
  { cost: number | null; conversions: number | null }
): number | null => {
  if( isNumber(cost) && isNumber(conversions) && conversions > 0 ){
    return cost / conversions
  }
  return null
}

export const getCPM = (
  { cost, impressions }:
  { cost: number | null; impressions: number | null }
): number | null => {
  if( isNumber(cost) && isNumber(impressions) && impressions > 0 ){
    return cost / (impressions / 1000)
  }
  return null
}

export const getCTR = (
  { clicks, impressions, ratio = false }:
  { clicks: number | null; impressions: number | null; ratio?: boolean }
): number | null => {
  if( isNumber(clicks) && isNumber(impressions) && impressions > 0 ){
    const ctr = clicks / impressions
    return ratio ? ctr : ctr * 100
  }
  return null
}

export const getCPV = (
  { cost, views }:
  { cost: number | null; views: number | null }
): number | null => {
  if( isNumber(cost) && isNumber(views) && views > 0 ){
    return cost / views
  }
  return null
}

export const getConversionRate = (
  { clicks, conversions }:
  { clicks: number | null; conversions: number | null }
): number | null => {
  if( isNumber(conversions) && isNumber(clicks) && clicks > 0 ){
    return conversions / clicks
  }
  return null
}

export const isMonetaryDimension = (dimension: AnyPerformanceDimension): boolean => {
  return ['cost', 'cpc', 'cpa', 'cpm', 'cpv'].indexOf(dimension) !== -1
}

export const isRateBasedDimension = (dimension: AnyPerformanceDimension): boolean => {
  return dimension.includes('rate')
}

export const isPercentageDimension = (dimension: AnyPerformanceDimension): boolean => {
  return dimension === 'ctr' || isRateBasedDimension(dimension)
}

export const isInvertedSentiment = (dimension: AnyPerformanceDimension): boolean => {
  return ['cpc', 'cpa', 'cpm', 'cpv'].indexOf(dimension) !== -1
}

export const isAveragingDimension = (dimension: AnyPerformanceDimension): boolean => {
  return ['cpa', 'cpc', 'ctr', 'cpm', 'cpv'].indexOf(dimension) !== -1
}

export const isConvertingDimension = (dimension: AnyPerformanceDimension): boolean => {
  return ['cost', 'avg_cpc', 'avg_cpa', 'avg_cpv', 'avg_cpm'].indexOf(dimension) !== -1
}


export function isPlatformUnitContainer<Extensions extends object = {}>(
  platformUnit: PlatformUnit<Extensions> | null
): platformUnit is PlatformUnitContainer<Extensions> {
  return Boolean(
    platformUnit
    && (platformUnit as PlatformUnitContainer).children
    && (platformUnit as PlatformUnitContainer).children.length
  )
}


export const coerceReportProvider = (
  provider: ReportProviderV2 | ReportProvider | PerformanceProvider | URLReportProvider | ReportProviderEnum | PerformanceProviderEnum
): ReportProvider | PerformanceProvider => {
  switch(provider){
    case 'GOOGLE_ADS':
    case 'google-ads':
    case 2: return 'adwords'
    case 'FACEBOOK': 
    case 1: return 'facebook'
    case 'ADFORM':
    case 3: return 'adform'
    case 'DV360':
    case 7: return 'dv360'
    case 'AMAZON_ADS':
    case 'amazon-ads':
    case 10: return 'AMAZON_ADS'
    case 'TIKTOK':
    case 'tiktok':
    case 9: return 'TIKTOK'
    default: return provider
  }
}


export const coerceDoubleVerifyProvider = (
  provider: DoubleVerifyProviderEnum | DoubleVerifyProvider
): DoubleVerifyProvider => {
  if( provider === 4 ) return 'doubleverify_facebook'
  if( provider === 5 ) return 'doubleverify_adwords'
  if( provider === 6 ) return 'doubleverify_adform'
  return provider
}

/* Report Summaries */

export const normalizeHealth = (
  health: number | null | undefined | (number | null)[]
): number | null => {
  if( isNumber(health) ){
    return health
  }
  if( isArray(health) ){
    const filtered = health.filter(isNumber)
    if( !filtered.length ){
      return null
    }
    return avg(filtered)
  }
  return null
}

export const getHealthFrom = (obj: WithHealthType | null | undefined): HealthType => (
  !obj ?
    null : normalizeHealth(
      isNumber(obj.impact_weighted_count_health) ?
        obj.impact_weighted_count_health :
        isNumber(obj.count_health) ?
          obj.count_health :
          isNumber(obj.score) ?
            obj.score :
            ( isNumber(obj.health) || isArray(obj.health) ) ?
              obj.health :
              null
    )
)

export const getReportResult = (
  report: StructuralReport,
  resultType: StructuralReportResultType = 'METRIC_REPORT',
): StructuralReportResult | null => (
  find(report.results, r => r.result_type === resultType) || null
)

export const getReportResultSummary = (
  report: StructuralReport,
  resultType: StructuralReportResultType = 'METRIC_REPORT',
): StructuralReportResultSummary | null => {
  const result = getReportResult(report, resultType)
  return result && result.summary
}

export const getReportResultHealth = (
  report: StructuralReport,
  resultType: StructuralReportResultType = 'METRIC_REPORT',
): HealthType => {
  const summary = getReportResultSummary(report, resultType)
  return getHealthFrom(summary)
}

export const isReportImpactWeighted = (report: StructuralReport): boolean => {
  const summary = getReportResultSummary(report, 'METRIC_REPORT')
  return !!(summary && summary.impact_weighted_count_health !== null)
}


/* Display numbers */

const numberAbbreviator = new NumberAbbreviate(['k', 'm', 'bn', 'tr'])

export const displayNumber = (n: number, precision = 2): string => {
  const isNegative = n < 0
  const absoluteValue = Math.abs(n)
  const formatted = (
    absoluteValue < 1000 ?
      round(absoluteValue, precision) :
      numberAbbreviator.abbreviate(absoluteValue, 2)
  )
  if( isNegative ){
    return `-${formatted}`
  }
  return formatted
}

export const separateThousands = (n: number | string): string => {
  const parts = n.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}


/* Display dates */

export const formatAuditDates = (
  { start, end, from, until, format = 'dd/MM/yy' }:
  { start?: DateType; end?: DateType; from?: DateType; until?: DateType; format?: string } = {}
): string[] => {
  const reportStart = start || from
  const reportEnd = end || until
  return [
    reportStart && dateFormat(new Date(reportStart), format) || '',
    reportEnd && dateFormat(new Date(reportEnd), format) || ''
  ].filter(Boolean)
}


export const getDateRangeString = (params: object | null | undefined): string => {
  const dates = formatAuditDates(params || undefined)

  if( dates.length === 2 ){
    return dates.join(' > ')
  }

  return ''
}


export const getSeriesDateRange = (audits: any[] | null | undefined): DateRange => (
  (audits || []).reduce( (acc: DateRange, a: ReportDates) => {
    const start = a.start || a.from || ''
    const end = a.end || a.until || ''
    acc.start = acc.start ? minDate(new Date(start) || acc.start, acc.start) : new Date(start)
    acc.end = acc.end ? maxDate(new Date(end) || acc.end, acc.end) : new Date(end)
    return acc
  }, { start: '', end: '' })
)


const ordinalSuffixes: Dictionary<string> = {
  1: 'st',
  2: 'nd',
  3: 'rd'
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']


export const displayDate = (
  date: DateType,
  { day = true, month = true, year = true }: Dictionary<boolean> = {}
): string => {
  const dateObject = new Date(date)
  let displayStr = ''
  if( day ){
    const dayNumber = dateObject.getDate(),
          dayStr = String(dayNumber),
          ordinal = ordinalSuffixes[dayStr.slice(dayStr.length - 1)] || 'th'
    displayStr += `${dayStr}${ordinal}`
  }
  if( month ){
    displayStr += ` ${months[dateObject.getMonth()]}`
  }
  if( year ){
    displayStr += ` ${dateObject.getFullYear()}`
  }
  return displayStr
}

export const displayTimeDelta = (a: DateType, b: DateType): string => {
  const dateA = Math.abs(new Date(a).getTime()),
        dateB = Math.abs(new Date(b).getTime())

  let delta = Math.abs(Math.round(dateA - dateB))

  let s = 0,
      m = 0,
      h = 0,
      d = 0

  const ONE_SECOND = 1000
  const ONE_MINUTE = 60 * ONE_SECOND
  const ONE_HOUR = ONE_MINUTE * 60
  const ONE_DAY = ONE_HOUR * 24
  
  if( delta > ONE_DAY ){
    d = ( delta - (delta % ONE_DAY) ) / ONE_DAY
    delta -= (d * ONE_DAY)
  }
  if( delta > ONE_HOUR ){
    h = ( delta - (delta % ONE_HOUR) ) / ONE_HOUR
    delta -= (h * ONE_HOUR)
  }
  if( delta > ONE_MINUTE ){
    m = ( delta - (delta % ONE_MINUTE) ) / ONE_MINUTE
    delta -= (m * ONE_MINUTE)
  }
  if( delta > ONE_SECOND ){
    s = ( delta - (delta % ONE_SECOND) ) / ONE_SECOND
  }

  return [
    { key: 'd', value: d },
    { key: 'h', value: h },
    { key: 'm', value: m },
    { key: 's', value: s },
  ].filter(
    x => x.value > 0
  ).slice(
    0, 2
  ).map(
    ({ key, value }) => `${value}${key}`
  ).join(' ')

}

/* API Data */

export const shouldAttemptLoad = <T>(response: AnyApiResponse<T>): response is ApiResponseIdle<T> => (
  Boolean(response && response.data === null && !response.loading && !response.error)
)

export const isLoading = <T>(response: AnyApiResponse<T>): response is (ApiResponseLoading<T> | ApiResponseIdle<T>) => (
  Boolean(!response || response.loading || (!response.data && !response.error))
)

export const isErrorResponse = <T>(response: AnyApiResponse<T>): response is ApiResponseError<T> => (
  Boolean(response && response.error !== null)
)

export const apiResponseOf = <T>(data: T): ApiResponse<T> => ({
  data,
  loading: false,
  error: null,
  lastFetched: Date.now()
})


/* Metrics */



export const metricDataToObject = (data: Dictionary | any[] | null): Dictionary<MetricSegmentType> => (
  isArray(data) ?
    data.reduce( (acc: Dictionary<MetricSegmentType>, d: MetricSegmentType) => {
      acc[d.label] = d
      return acc
    }, {}) :
    isObject(data) ?
      data :
      {}
)

export const metricDataHasValues = (
  data: MetricDataType | null
): boolean => {
  if( !data ) return false
  const dataArray = metricDataToArray(data)
  return Boolean(
    dataArray.length && any(
      dataArray,
      d => d && isNumber(d.value) && d.value > 0
    )
  )
}

export const segmentToString = (segment?: string | Dictionary | null): string => (
  !segment ?
    '' :
    typeof segment === 'string' ?
      segment :
      typeof segment.label === 'string' ?
        segment.label :
        '' 
)



export const parseDimension = (perfDimension?: string | null): ParsedDimension => {
  const perfTailRegex = /([a-z]+)_(\d+)([a-z])/

  perfDimension = (perfDimension || 'count')

  const match = perfTailRegex.exec(perfDimension)

  if( !match ){
    const dimension = perfDimension as PerformanceDimensionType
    const defaultLabel = deslugify(dimension)
    const { text = defaultLabel, abbreviatedText, icon } = dimensionMap[dimension] || {}
    return {
      raw: dimension,
      dimension,
      tail: '',
      label: text,
      shortLabel: abbreviatedText || text,
      icon,
      length: null,
      unit: null,
    }
  }

  const dimension = match[1] as PerformanceDimensionType
  const defaultLabel = deslugify(dimension)

  const { text = defaultLabel, abbreviatedText, icon } = dimensionMap[dimension] || {}

  const length = Number(match[2])
  const unit = match[3]

  const tail = `${length}${unit}`

  return {
    raw: perfDimension,
    dimension,
    tail,
    label: text,
    shortLabel: abbreviatedText || text,
    icon,
    length,
    unit,
  } 
}


export const isDistributionMetricData = (item: Dictionary): item is DistributionMetricData => Boolean(
  item && item.segments
)

export const isProportionMetricData = (item: Dictionary): item is ProportionMetricData => Boolean(
  item && item.enumerator
)

export const isValueMetricData = (item: Dictionary): item is ValueMetricData => Boolean(
  item && 'value' in item
)


/* Tasks */

const taskRunStates: Task['status'][] = [
  'START_REQUESTED',
  'STARTED',
  'RUNNING',
]

export const isTaskRunning = (task: Pick<Task, 'status'> & Partial<Task>): boolean => (
  taskRunStates.includes(task.status)
)


/* Layouts */

const isLayoutNodeType = (n: LayoutNodeType | LayoutMetricType | LayoutOptionalMetrics): n is LayoutNodeType => {
  return isArray(getPath(n, 'members'))
}

const isLayoutOptionalMetrics = (n: LayoutNodeType | LayoutMetricType | LayoutOptionalMetrics): n is LayoutOptionalMetrics => {
  return n.type === 'optional'
}

export const collectMetricIdsFromLayout = (layoutNode: LayoutNodeType, payload?: MetricsPayload | ReportMetricsPayload | null): string[] => {
  const metricIds: string[] = []

  function collectMetricIdsFrom(node: LayoutNodeType | LayoutMetricType | LayoutOptionalMetrics): void {
    if( node ){
      if( isLayoutOptionalMetrics(node) ){
        if( payload ){
          if( node.operator === 'any' ){
            for( const metric of node.metrics ){
              if( payload[metric.id] ){
                metricIds.push(metric.id)
                break
              }
            }
          }else if( node.operator === 'all' ){
            if( all(node.metrics, m => !!payload[m.id]) ){
              node.metrics.forEach(collectMetricIdsFrom)
            }
          }else{
            console.warn('Unhandled optional operator', node.operator)
          }
        }
      }else if( isLayoutNodeType(node) ){
        if( node.members.length ){
          node.members.forEach(collectMetricIdsFrom)
        }
      }else{
        metricIds.push(node.id)
      }
    }
  }

  collectMetricIdsFrom(layoutNode)

  return metricIds
}

export const getTabDisplayName = (tabKey: string, layout: LayoutType | null): string => (
  getPath(
    findNext(layout && layout.members, m => m.key === tabKey ),
    'name',
    tabKey
  )
)


/* Annotations */

const annotationHierarchy: (keyof AnnotationPoints)[] = [
  'series_id',
  'report_id',
  'metric_id',
  'dimension',
  'segment'
]

const reverseAnnotationHierarchy = annotationHierarchy.slice().reverse()

const annotationLevelMap: Record<AnnotationPoint, AnnotationLevel> = {
  'series_id': 'SERIES',
  'report_id': 'REPORT',
  'metric_id': 'METRIC',
  'dimension': 'METRIC_DIMENSION',
  'segment': 'METRIC_SEGMENT',
}


export const getAnnotationPath = (props: Partial<AnnotationPoints>): string => (
  annotationHierarchy.reduce( (acc: { label: AnnotationPoint; value: string }[], k) => {
    if( props[k] ){
      acc.push({
        label: k,
        value: String(props[k])
      })
    }
    return acc
  }, []).map( ({ label, value }) => (
    `${label}::${value}`
  )).join(';;')
)

export const parseAnnotationPath = (annotationPath: string): AnnotationPoints => (
  annotationPath.split(';;').reduce( (acc: Dictionary, keyValuePair) => {
    const [annotationPoint, value] = (
      keyValuePair.split('::')  as [AnnotationPoint, string | DimensionType]
    )
    acc[annotationPoint] = value
    return acc
  }, annotationHierarchy.reduce( (acc: Dictionary, h) => {
    acc[h] = ''
    return acc
  }, {}) ) as AnnotationPoints
) 


export const getAnnotationLevel = (props: Partial<AnnotationPoints>): AnnotationLevel => {
  const annotationLevel = findNext(reverseAnnotationHierarchy, key => !!props[key])
  return (
    annotationLevel ?
      annotationLevelMap[annotationLevel] :
      'SERIES'
  )
}


/* Health */


export const normalizeHealthAggregation = (obj: Partial<HealthAggregation> | Dictionary | null | undefined): HealthAggregation => ({
  avg: normalizeHealth(getPath(obj, 'avg')),
  impact_weighted_avg: normalizeHealth(getPath(obj, 'impact_weighted_avg'))
})


export const normalizeHealthAggregations = (
  obj: Partial<HealthAggregations> | Dictionary | null | undefined
): HealthAggregations => {
  const aggregationKeys = uniq([
    'count',
    ...(Object.keys(obj || {})),
  ])

  return aggregationKeys.reduce( (acc, k) => {
    acc[k] = normalizeHealthAggregation(obj && obj[k])
    return acc
  }, {} as HealthAggregations)
}


export const getHealthAggregationsFrom = (
  obj: (WithHealthAggregations & WithHealthType) | null | undefined
): HealthAggregations => (
  normalizeHealthAggregations(
    !obj ?
      undefined :
      obj.health_aggregations || {
        count: {
          avg: obj.count_health || obj.health || obj.score,
          impact_weighted_avg: obj.impact_weighted_count_health,
        }
      }
  )
)


/* RAG utilities */

const ragColours: RAGColour[] = ['green', 'amber', 'red']

export const healthToRAG = (health: number[]): RAGObject => health.reduce( (obj: RAGObject, value) => {
  const factorOf3 = value * 3,
        color: RAGColour = factorOf3 >= 2 ? 'green' : factorOf3 >= 1 ? 'amber' : 'red'
  obj[color] += 1
  return obj
}, { red: 0, amber: 0, green: 0})

export const getRAGColour = (value: RAGColour | number): string => {
  if( typeof value === 'string' && ragColours.includes(value) ){
    return palette[value]
  }
  const factorOf3 = Number(value) * 3
  if( factorOf3 >= 2 ) return palette.green
  if( factorOf3 >= 1 ) return palette.amber
  return palette.red
}

export const getRAGDonutValues = (rag: RAGObject): DatumType[] => ragColours.map( label => ({
  label, value: rag[label] || 0, color: palette[label]
}) )

export const healthToRAGDonutValues = (health: any): DatumType[] => getRAGDonutValues(healthToRAG(health))


export const getRAGColourScale = (domain: [number, number] = [0, 1]): ScaleLinear<number, string> => {
  // NOTE: Health domain can be thought of as min/max alone,
  // but transitioning across the middle colour requires a 3-value domain in D3.
  // We simply derive this midpoint silently.
  const scaleDomain = [domain[0], (domain[1] - domain[0]) / 2,  domain[1]]
  return scaleLinear<number, string>()
    .domain(scaleDomain)
    .range([palette.red, palette.amber, palette.green] as unknown as number[])
}


const brandedChartColours: Record<AppBranding, string[]> = {
  PERCEPT: [ '#68a6d0', '#8253a0' ],
  EBIQUITY: [ '#68a6d0', '#5a2b87' ],
}

const { BRANDING } = process.env

export const getChartColourScale = (domain: [number, number]): ScaleLinear<number, string> => (
  scaleLinear<number, string>()
    .domain(domain)
    .range(brandedChartColours[BRANDING] as unknown as number[])
)



/* React and Prop Types */


export const typesOrNull = (...propTypes: Function[]) =>
  (props: Dictionary, propName: string, componentName: string, ...args: any[]): Error | null => {
    const { [propName]: value } = props
    if( value === null ){
      return null
    }
  
    const errors: Error[] = []
  
    let valid = false

    propTypes.forEach( (propType: Function) => {
      const result = propType(props, propName, componentName, ...args)
      if( result instanceof Error ){
        errors.push(result)
      }else{
        valid = true
      }
    })

    return valid ? null : errors[0]
  }


/* General utility */

export const copyToClipboard = (text: string): void => {
  const textArea = document.createElement('textarea')
  textArea.setAttribute('style','width:1px;border:0;opacity:0;')
  document.body.appendChild(textArea)
  textArea.value = text
  textArea.select()
  document.execCommand('copy')
  document.body.removeChild(textArea)
}


const dataURItoBlob = (dataURI: string): Blob => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)

  // create a view into the buffer
  const ia = new Uint8Array(ab)

  // set the bytes of the buffer to the correct values
  for( let i = 0; i < byteString.length; i++ ){
    ia[i] = byteString.charCodeAt(i)
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], {type: mimeString})
  return blob

}


export const triggerDownload = (
  { filename, contents, dataURL = null, mimeType = 'text/plain' }:
  { filename: string; contents: string; dataURL?: string | null; mimeType?: string }
): void => {

  const blob = dataURL ? dataURItoBlob(dataURL) : new Blob([contents], {type: mimeType})

  saveAs(blob, filename)

}
