
import React, { Fragment, memo, useState } from 'react'

import { Box, IconButton, Drawer } from '@material-ui/core'

import { Search } from '../../icons'

import { MetricSearch } from './MetricSearch'

import { MetricSearchProps } from './typings'
import { makeAppStyles } from '../../themes'


const useStyles = makeAppStyles( theme => ({
  drawer: {
    height: '100vh',
    overflow: 'hidden',
    maxWidth: '36rem',
  },
  modal: {
    zIndex: `${theme.zIndex.appBar - 1} !important` as unknown as number,
  },
  iconButton: {
    display: 'inline-flex',
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(2),
  },
}) )


export type MetricSearchDrawerProps = MetricSearchProps


export const MetricSearchDrawer = memo((props: MetricSearchDrawerProps): JSX.Element => {

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const classes = useStyles()

  return (
    <Fragment>

      <IconButton
        className={classes.iconButton}
        color='inherit'
        onClick={handleClick}>
        <Search />
      </IconButton>

      <Drawer
        classes={{
          modal: classes.modal,
          paper: classes.drawer,
        }}
        open={open}
        keepMounted
        variant='temporary'
        onClose={handleClose}>
        
        <Box
          pt={15}
          pr={2}
          pl={3}>
          <MetricSearch
            onClose={handleClose}
            isVisible={open}
            {...props} />
        </Box>

      </Drawer>

    </Fragment>
  )
})
