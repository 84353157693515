
import { apiInitialState } from '@percept/redux'


import {
  getMetricKey,
  getEntityKey,
} from '@percept/redux/utils'

import { getPath, any, isNumber, normalizeHealth, apiResponseOf, getHealthFrom } from '@percept/utils'

import {
  StoreState,
  ApiResponse,
  MaybeId,
  Report,
  ReportPayload,
  Dictionary,
  ReportEntity,
  LegacyReportMetric,
  DimensionType,
  AuditProvider,
  ReportEntities,
  ReportEntityFilter,
  MetricSegmentType,
  PerformanceSummary,
  MetricsPayload,
  HealthType,
} from '@percept/types'


const { PERCEPT_ENV } = process.env


const DEBUG = PERCEPT_ENV === 'dev'


type AuditWrapper = {
  root: ApiResponse<Report>,
  payload: ApiResponse<ReportPayload>,
  entities: Dictionary<ApiResponse<ReportEntity>>,
  metrics: Dictionary<ApiResponse<LegacyReportMetric>>,
}


export const makeSelectors = (
  { statePath = ['audits'], raw = false }:
  { statePath: string[], raw: boolean } = { statePath: ['audits'], raw: false }
) => {


  const getAuditWrapper = (
    state: StoreState,
    report_id: MaybeId
  ): AuditWrapper => (
    getPath(state, [...statePath, 'byId', report_id], {
      root: apiInitialState,
      payload: apiInitialState,
      entities: {},
      metrics: {}
    })
  )

  const getAuditWrappersById = (state: StoreState): Dictionary<AuditWrapper> => (
    getPath(state, [...statePath, 'byId'])
  )
    

  const getAudit = (
    state: StoreState,
    report_id: MaybeId
  ): AuditWrapper['root'] => (
    getAuditWrapper(state, report_id).root
  )

  const getAuditPayload = (
    state: StoreState,
    report_id: MaybeId
  ): AuditWrapper['payload'] => (
    getAuditWrapper(state, report_id).payload
  )

  const getAuditEntities = (
    state: StoreState,
    report_id: MaybeId
  ): AuditWrapper['entities'] => (
    getAuditWrapper(state, report_id).entities
  )

  const getAuditEntity = (
    state: StoreState,
    { report_id, entity_type, entity_id }:
    { report_id: MaybeId, entity_type: MaybeId, entity_id: MaybeId }
  ): ApiResponse<ReportEntity> => (
    getPath(
      getAuditEntities(state, report_id),
      getEntityKey({ entity_type, entity_id }),
      apiInitialState
    )
  )

  const getAuditMetrics = (
    state: StoreState,
    report_id: MaybeId
  ): AuditWrapper['metrics'] => (
    getAuditWrapper(state, report_id).metrics
  )

  const getAuditMetric = (
    state: StoreState,
    { report_id, entity_type, entity_id, metric_id, dimension }:
    { report_id: MaybeId, entity_type: MaybeId, entity_id: MaybeId, metric_id: MaybeId, dimension: DimensionType | null } 
  ): ApiResponse<LegacyReportMetric> => (
    getPath(
      getAuditWrapper(state, report_id).metrics,
      getMetricKey({ entity_type, entity_id, metric_id, dimension }),
      apiInitialState
    )
  )


  const getRawAudit = (
    state: StoreState,
    report_id: MaybeId
  ) => (
    getPath(state, [...statePath, 'byId', report_id], apiInitialState)
  )

  // Filter / sort

  const getAuditEntityFilter = (
    state: StoreState,
    series_id: MaybeId,
    provider: AuditProvider | null
  ): ReportEntityFilter => (
    getPath(state, [...statePath, 'filters', series_id, provider], { type: null, id: null })
  )

  const getAuditEntitySort = (state: StoreState) => getPath(state, [...statePath, 'entitySort'])

  const ensureFilter = (
    audit: Report | null,
    entityFilter: ReportEntityFilter | null | undefined = { type: '', id: '' }
  ) => ({
    type: getPath(entityFilter, 'type', audit && audit.root_entity_type),
    id: getPath(entityFilter, 'id', audit && audit.root_entity_id),
  })


  // Metric Detail

  const getActiveMetric = (state: StoreState) => getPath(state, [...statePath, 'activeMetric'])


  // Segments

  const getActiveSegment = (state: StoreState) => getPath(state, [...statePath, 'activeSegment'])

  const segmentDataSelector = (
    audit: Report | null,
    metrics: Dictionary<ApiResponse<LegacyReportMetric>>,
    activeSegment: Dictionary | null
  ): ApiResponse<LegacyReportMetric> => {

    if( !audit ) return apiInitialState

    const { metric_id, dimension, entity_type, entity_id } = activeSegment || {}

    const inlineData = getPath(
      audit,
      ['data', 'entities', entity_type, entity_id, 'metrics', metric_id, dimension],
      {}
    )

    if( inlineData && any((inlineData.data || []), (d: MetricSegmentType) => !!d.examples) ){
      return apiResponseOf<LegacyReportMetric>(inlineData)
    }

    return getPath(
      metrics,
      getMetricKey({ entity_type, entity_id, metric_id, dimension}),
      apiInitialState
    )
  }

  const getActiveAuditMetric = (state: StoreState) => {
    const activeSegment = getActiveSegment(state) || {},
          { report_id } = activeSegment

    const auditWrapper = getAuditWrapper(state, report_id),
          { metrics, root } = auditWrapper

    return segmentDataSelector(root.data, metrics, activeSegment)
  }


  // View settings

  const getDisplayType = (state: StoreState) => getPath(state, [...statePath, 'settings', 'displayType'])

  const getMetricOrder = (state: StoreState) => getPath(state, [...statePath, 'settings', 'metricOrder'])


  // Export

  const getExportMetric = (state: StoreState) => getPath(state, [...statePath, 'exportOptions', 'metric'])

  const getExportType = (state: StoreState) => getPath(state, [...statePath, 'exportOptions', 'exportType'])

  const getExportOptions = (state: StoreState) => (
    getPath(state, [...statePath, 'exportOptions', 'optionsByType'])[getExportType(state)]
  )


  // Entity-level metrics

  const filterAuditData = (
    audit: Report | null,
    entities: Dictionary<ApiResponse<ReportEntity>>,
    entityFilter: ReportEntityFilter | null,
    defaultValue: any = apiInitialState
  ): ApiResponse<ReportEntity> => {

    if( !audit ) return defaultValue

    const { type, id } = ensureFilter(audit, entityFilter)

    // Legacy audits store per-entity metric data inline - check for this and
    // return a valid api wrapper response shape containing the data
    const inlineData = getPath(audit, ['entities', type, id, 'metrics'], null)

    if( inlineData ){
      return apiResponseOf<ReportEntity>(inlineData)
    }

    return getPath(entities, [`${type}|${id}`], defaultValue)
  }

  const getFilteredAuditData = (
    state: StoreState,
    report_id: MaybeId,
    entityFilter: ReportEntityFilter | null = null
  ): ApiResponse<ReportEntity> => {
    const { payload, entities } = getAuditWrapper(state, report_id),
          audit = payload.data

    return filterAuditData(audit, entities, entityFilter)

  }


  // Performance

  const makeDataFilter = <T>(
    key: string,
    defaultValue?: T
  ) => (
      (audit: Report | null, entityFilter: ReportEntityFilter | null): T => {
        const { type, id } = ensureFilter(audit, entityFilter)
        return getPath(audit, ['entities', type, id, key], defaultValue)
      }
    )

  const filterPerformance = makeDataFilter<PerformanceSummary>('performance')

  const filterCurrencyCode = makeDataFilter<string | null>('currency_code')


  const makeDataFilterSelector = <T>(
    getter: (report: Report | null, entityFilter: ReportEntityFilter) => T,
  ) => (
      (state: StoreState, report_id: MaybeId, entityFilter: ReportEntityFilter): T => (
        getter(getAuditPayload(state, report_id).data, entityFilter)
      )
    )


  const getFilteredPerformanceData = makeDataFilterSelector(filterPerformance)

  const getFilteredCurrencyCode = makeDataFilterSelector(filterCurrencyCode)


  // Health


  const filterHealth = (
    audit: ReportPayload | null,
    entityFilter: ReportEntityFilter | null,
  ): HealthType => {
    const { type, id } = ensureFilter(audit, entityFilter)
    
    const isRoot = (
      !(type && id) || audit && ( type === audit.root_entity_type && id === audit.root_entity_id )
    )
    
    if( !audit ){
      return null
    }

    if( isRoot ){
      return getHealthFrom(audit)
    }

    const entityMetadata: ReportEntity = getPath(
      audit,
      ['entities', type, id]
    ) || {}

    return getHealthFrom(entityMetadata)
  }


  const getFilteredHealth = (
    state: StoreState,
    report_id: MaybeId,
    entityFilter: ReportEntityFilter | null,
  ): HealthType => {
    const audit = getAuditPayload(state, report_id).data

    return filterHealth(audit, entityFilter)
  }


  // Entity metadata

  const getEntityMetadata = (audit: Report | null): ReportEntity | Dictionary => {
    if( !audit ){
      return {}
    }
    const { entities } = audit
    return Object.keys(entities).reduce( (obj: Dictionary, level) => {
      if( entities[level] ){
        obj[level] = Object.keys(entities[level]).map( k => entities[level][k] )
      }
      return obj
    }, {})
  }

  const getAuditEntityMetadata = (
    state: StoreState,
    report_id: MaybeId
  ) => {
    const { data } = getAudit(state, report_id)
    return getEntityMetadata(data)
  }


  // Search

  const getAuditSearchQuery = (state: StoreState) => getPath(state, [...statePath, 'search', 'query'], '')

  const getAuditSavedSearches = (state: StoreState) => getPath(state, [...statePath, 'search', 'saved'], [])


  return {
    // Redux state selectors
    getAudit,
    getAuditPayload,
    getAuditEntities,
    getAuditEntity,
    getAuditMetrics,
    getAuditMetric,
    getAuditWrapper,
    getAuditWrappersById,
    getRawAudit,
    getAuditEntityMetadata,
    getAuditEntityFilter,
    getAuditEntitySort,
    getActiveSegment,
    getActiveMetric,
    getActiveAuditMetric,
    getFilteredAuditData,
    getFilteredPerformanceData,
    getFilteredHealth,
    getFilteredCurrencyCode,
    getExportMetric,
    getExportType,
    getExportOptions,
    getDisplayType,
    getMetricOrder,
    getAuditSearchQuery,
    getAuditSavedSearches,
    // Audit selectors
    filterAuditData,
    filterPerformance,
    filterHealth,
    filterCurrencyCode,
    getEntityMetadata,
    segmentDataSelector,
  }

}


export default makeSelectors()
