
import {
  SET_SERIES_CHART_TYPE,
  SET_SERIES_PERFORMANCE_TAIL,
  SET_SERIES_HEALTH_DIMENSION,
} from '../actions'

import {
  ReduxAction,
  StackedChartType,
  DimensionType,
} from '@percept/types'


type SeriesSettings = {
  chartType: StackedChartType
  healthDimension: DimensionType
  performanceTail: string | null
}

const initialState: SeriesSettings = {
  chartType: 'area',
  healthDimension: 'count',
  performanceTail: null,
}


export default (state: SeriesSettings = initialState, action: ReduxAction): SeriesSettings => {

  switch(action.type){

    case SET_SERIES_CHART_TYPE:
      return {
        ...state,
        chartType: action.payload
      }

    case SET_SERIES_PERFORMANCE_TAIL:
      return {
        ...state,
        performanceTail: action.payload
      }

    case SET_SERIES_HEALTH_DIMENSION:
      return {
        ...state,
        healthDimension: action.payload
      }

    default:
      return state
  }

}
