import React, { Fragment, useEffect } from 'react'

import {
  BackdropLoader,
  Box,
  Card,
  Checkbox,
  CircularProgress,
  DateRangeInput,
  Divider,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeAppStyles,
  RoundedPlainTextButton,
  Typography,
  Badge,
  ReportRange,
  RoundedPlainTextButtonMenu,
  MenuOption,
} from '@percept/mui'

import { PageHeader } from 'components/PageHeader'

import { PerformanceReportDataTable } from './PerformanceReportTable'

import { Check, Close, DonutLarge, Schedule } from '@percept/mui/icons'

import { CurrencySelect } from 'components/CurrencySelect'

import { useActiveOrgEnvironment } from 'components/Organisation'

import { ProviderTable } from './ProviderTable'

import { usePlatformUnitProviderInfo } from '@percept/hooks'

import { useTargetCurrency } from 'hooks'

import { usePerformanceReportReducer } from './performanceReportReducer'

import {
  buildPerformanceReportCSV,
  getPerformanceReportFilename, 
  isCampaignObjectiveAvailable, 
  usePerformanceReport,
} from './lib'

import { capitalize, intersection } from 'lodash-es'

import { isVodafonePlatformUnit } from '@percept/utils'

import { differenceInCalendarDays } from 'date-fns'

import { providerLabelMap, providersWithCampaignObjectiveSegmentation } from '@percept/constants'

import { DerivedAggregationPeriod, PerformanceReportSegmentation } from './typings'

import { PlatformUnit } from '@percept/types'


const { PREVIEW } = process.env


const useStyles = makeAppStyles( theme => ({
  providerTrigger: {
    marginRight: theme.spacing(1),
    color: 'white',
  },
  card: {
    marginTop: theme.spacing(3),
  },
  inputGroup: {
    margin: theme.spacing(3),
  },
  providerInputGroup: {
    minWidth: '21rem',
  },
  badgeCount: {
    top: 8,
    right: -14,
  },
  inputGroupDivider: {
    marginRight: 0,
    margin: theme.spacing(3),
    marginLeft: 0,
  },
  inputGroupHeader: {
    marginBottom: theme.spacing(1.5),
  },
  helperText: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  listItem: {
    paddingRight: theme.spacing(2),
  },
  checkboxControl: {
    display: 'block',
    marginBottom: theme.spacing(0.5),
  },
  checkbox: {
    padding: theme.spacing(1),
  },
  dateRangeInput: {
    maxWidth: '4.5rem',
  },
  platformUnitList: {
    maxHeight: '11rem',
    overflowY: 'auto',
  },
  providerLogo: {
    position: 'relative',
    top: 1,
  },
  tableTypography: {
    display: 'flex',
    alignItems: 'center',
  },
  dateRange: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
}))


const getAggregationOptions = ({
  platformUnits,
  startDate,
  endDate,
}: {
  platformUnits: PlatformUnit[]
  startDate: Date
  endDate: Date
}): MenuOption<DerivedAggregationPeriod | null>[] => {
  const hasChunking = platformUnits.length === 1
  const hasWeekly = hasChunking && (
    Math.abs(differenceInCalendarDays(startDate, endDate)) < 6
  )
  const hasMonthly = hasChunking && (
    Math.abs(differenceInCalendarDays(startDate, endDate)) < 30
  )
  return [
    { value: null, label: 'Full Range' },
    {
      value: 'DAILY',
      label: 'Daily',
      disabled: !hasChunking,
    },
    {
      value: '7_DAYS',
      label: '7 Days',
      disabled: !hasWeekly,
    },
    {
      value: 'WEEKLY',
      label: 'Weekly',
      disabled: !hasWeekly,
    },
    {
      value: '30_DAYS',
      label: '30 Days',
      disabled: !hasMonthly,
    },
    {
      value: 'MONTHLY',
      label: 'Monthly',
      disabled: !hasMonthly,
    },
  ]
}


export const PerformanceReport = (): JSX.Element => {

  const { activePlatformUnit, loading } = useActiveOrgEnvironment()

  const org_unit_id = activePlatformUnit && activePlatformUnit.id

  const [providerInfoApiResponse] = usePlatformUnitProviderInfo({ org_unit_id })

  const [{
    platformUnit,
    selectedPlatformUnits,
    providerInfo,
    providerOptions,
    providers,
    segmentation,
    aggregationPeriod,
    startDate,
    endDate,
    minDate,
    maxDate,
  }, dispatch] = usePerformanceReportReducer()

  const [currency] = useTargetCurrency()

  const [performanceReport, loadPerformanceReport] = usePerformanceReport({
    selectedPlatformUnits,
    providers,
    segmentation,
    aggregationPeriod,
    startDate,
    endDate,
    maxDate,
    currency,
  })

  useEffect(() => {
    if( activePlatformUnit && !platformUnit ){
      dispatch({
        type: 'SET_PLATFORM_UNIT',
        payload: activePlatformUnit,
      })
    }
  }, [dispatch, activePlatformUnit, platformUnit])

  useEffect(() => {
    if( providerInfoApiResponse.data && !providerInfo ){
      dispatch({
        type: 'SET_PROVIDER_INFO',
        payload: providerInfoApiResponse.data,
      })
    }
  }, [dispatch, providerInfoApiResponse.data, providerInfo])

  const paramsAreValid = Boolean(
    selectedPlatformUnits.length &&
    providers.length &&
    startDate &&
    endDate
  )

  const classes = useStyles()

  const canSegmentByCampaignObjective = isCampaignObjectiveAvailable(providers)
  const availableCampaignObjectiveProviders = intersection(
    providersWithCampaignObjectiveSegmentation, providerOptions
  )

  let campainObjectiveLabelSuffix = ''
  if( !canSegmentByCampaignObjective && availableCampaignObjectiveProviders.length ){
    campainObjectiveLabelSuffix = availableCampaignObjectiveProviders.reduce( (acc, provider, i) => {
      const joinString = (
        i === 0 ?
          '' :
          i === availableCampaignObjectiveProviders.length - 1 ?
            ' & ' :
            ','
      )
      return acc + joinString + providerLabelMap[provider]
    }, '')
    campainObjectiveLabelSuffix = `(${campainObjectiveLabelSuffix} only)`
  }

  const chunkingEnabled = selectedPlatformUnits.length === 1

  return (
    <Fragment>

      <Box
        my={4}
        mx={3}>

        <PageHeader />

        <Box mb={5}>

          <Typography variant='h5'>Performance Report</Typography>

          { activePlatformUnit && (
            <Card
              className={classes.card}>

              <Box display='flex' flexWrap='wrap'>

                <div className={classes.inputGroup}>
                  <Badge
                    classes={{
                      badge: classes.badgeCount
                    }}
                    color='secondary'
                    badgeContent={selectedPlatformUnits.length}>
                    <Typography
                      variant='subtitle1'
                      className={classes.inputGroupHeader}>
                      Organisations
                    </Typography>
                  </Badge>

                  <List className={classes.platformUnitList} disablePadding>
                    <ListItem
                      className={classes.listItem}
                      dense
                      button
                      disableGutters
                      selected={selectedPlatformUnits.includes(activePlatformUnit)}
                      onClick={(): void => {
                        dispatch({
                          type: 'TOGGLE_PLATFORM_UNIT',
                          payload: activePlatformUnit,
                        })
                      }}>
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            root: classes.checkbox,
                          }}
                          color='secondary'
                          size='small'
                          checked={selectedPlatformUnits.includes(activePlatformUnit)} />
                      </ListItemIcon>
                      <ListItemText
                        primary={activePlatformUnit.name} />
                    </ListItem>

                    { activePlatformUnit.children && (
                      <Fragment>
                        <Divider />

                        { activePlatformUnit.children.map( unit => {

                          const selected = selectedPlatformUnits.includes(unit)

                          return (
                            <ListItem
                              key={unit.id}
                              className={classes.listItem}
                              dense
                              button
                              disableGutters
                              selected={selected}
                              onClick={(): void => {
                                dispatch({
                                  type: 'TOGGLE_PLATFORM_UNIT',
                                  payload: unit
                                })
                              }}>
                              <ListItemIcon>
                                <Checkbox
                                  classes={{
                                    root: classes.checkbox,
                                  }}
                                  color='secondary'
                                  size='small'
                                  checked={selected} />
                              </ListItemIcon>
                              <ListItemText
                                primary={unit.name} />
                            </ListItem>
                          )
                        })}

                      </Fragment>
                    )}
                  </List>
                </div>

                <Divider
                  className={classes.inputGroupDivider}
                  orientation='vertical'
                  flexItem />

                <div
                  className={classes.inputGroup + ' ' + classes.providerInputGroup}>
                  
                  <Badge
                    classes={{
                      badge: classes.badgeCount
                    }}
                    color='secondary'
                    badgeContent={providers.length}>
                    <Typography
                      variant='subtitle1'
                      className={classes.inputGroupHeader}>
                      Providers
                    </Typography>
                  </Badge>

                  { providerInfo && !!providerOptions.length && (
                    <ProviderTable
                      selectedProviders={providers}
                      providerOptions={providerOptions}
                      providerInfo={providerInfo}
                      toggleProvider={provider => (
                        dispatch({
                          type: 'TOGGLE_PROVIDER',
                          payload: provider
                        })
                      )}
                      toggleAllProviders={(): void => {
                        dispatch({ type: 'TOGGLE_ALL_PROVIDERS' })
                      }} />
                  )}

                </div>

                <Divider
                  className={classes.inputGroupDivider}
                  orientation='vertical'
                  flexItem />

                { startDate && endDate && (
                  <Fragment>
                    <div className={classes.inputGroup}>

                      <Typography
                        variant='subtitle1'
                        className={classes.inputGroupHeader}>
                        Date Range
                      </Typography>

                      <DateRangeInput
                        value={[startDate, endDate]}
                        domain={[minDate, maxDate]}
                        inputProps={{
                          className: classes.dateRangeInput,
                        }}
                        minDateMessage={
                          'Date is earlier than data allows'
                        }
                        maxDateMessage={
                          'Date is later than data allows'
                        }
                        onChange={([start, end]): void => {
                          dispatch({
                            type: 'SET_DATES',
                            payload: {
                              startDate: new Date(start),
                              endDate: new Date(end),
                            }
                          })
                        }} />

                      <Box mt={4}>
                        <Typography
                          variant='subtitle1'
                          className={classes.inputGroupHeader}>
                          Currency
                        </Typography>
                        <CurrencySelect
                          TriggerProps={{
                            variant: 'contained',
                            size: 'small',
                          }} />
                      </Box>

                      { PREVIEW && (
                        <Box mt={4}>
                          <Typography
                            variant='subtitle1'
                            className={classes.inputGroupHeader}>
                            Aggregation Period
                          </Typography>

                          <RoundedPlainTextButtonMenu
                            TriggerProps={{
                              variant: 'contained',
                              size: 'small',
                              disabled: !chunkingEnabled,
                              startIcon: <Schedule />,
                            }}
                            value={aggregationPeriod}
                            label={
                              !aggregationPeriod ?
                                'Full Range' :
                                aggregationPeriod.split('_').map(capitalize).join(' ')
                            }
                            options={getAggregationOptions({
                              platformUnits: selectedPlatformUnits,
                              startDate,
                              endDate,
                            })}
                            onChange={(e, value: DerivedAggregationPeriod): void => {
                              dispatch({
                                type: 'TOGGLE_AGGREGATION_PERIOD',
                                payload: value || null,
                              })
                            }} />

                          <FormHelperText className={classes.helperText}>
                            { chunkingEnabled ? (
                              'Enabled'
                            ) : (
                              'Available for single organisations at a time'
                            )}
                          </FormHelperText>
                        </Box>
                      )}

                      <Box mt={4}>
                        <Typography
                          variant='subtitle1'
                          className={classes.inputGroupHeader}>
                          Segmentation
                        </Typography>

                        <RoundedPlainTextButtonMenu
                          TriggerProps={{
                            variant: 'contained',
                            size: 'small',
                            startIcon: <DonutLarge />,
                          }}
                          value={segmentation}
                          label={
                            segmentation === 'network' ?
                              'Network' :
                              segmentation === 'sub_network' ?
                                'Channel' :
                                segmentation === 'campaign_objective' ?
                                  'Campaign Objective' :
                                  'None'
                          }
                          options={[
                            { value: null, label: 'None' },
                            { value: 'network', label: 'Network' },
                            { value: 'sub_network', label: 'Channel' },
                            ...(isVodafonePlatformUnit(activePlatformUnit) && [
                              {
                                value: 'campaign_objective',
                                label: (
                                  canSegmentByCampaignObjective ?
                                    'Campaign Objective' :
                                    `Campaign Objective ${campainObjectiveLabelSuffix}` 
                                ),
                                disabled: !canSegmentByCampaignObjective,
                              },
                            ] || []),
                          ]}
                          onChange={(e, value: PerformanceReportSegmentation): void => {
                            dispatch({
                              type: 'TOGGLE_NETWORK_SEGMENTATION',
                              payload: value || null,
                            })
                          }} />
                      </Box>

                    </div>

                    <Divider
                      className={classes.inputGroupDivider}
                      orientation='vertical'
                      flexItem />

                  </Fragment>
                )}
                
                <div className={classes.inputGroup}>
                  <RoundedPlainTextButton
                    variant='contained'
                    size='large'
                    disabled={
                      !paramsAreValid || performanceReport.loading
                    }
                    endIcon={
                      performanceReport.loading ?
                        <CircularProgress color='inherit' size='1em' /> :
                        paramsAreValid ?
                          <Check /> :
                          <Close />
                    }
                    onClick={(): void => {
                      loadPerformanceReport()
                    }}>
                    {
                      performanceReport.loading ?
                        'Loading Report' :
                        performanceReport.data ?
                          'Refresh Report' :
                          'Generate Report'
                    }
                  </RoundedPlainTextButton>
                </div>

              </Box>

            </Card>

          )}
        </Box>

        { !!(performanceReport.data && performanceReport.data.length) && (
          <PerformanceReportDataTable
            options={{
              download: true,
              onDownload: (_, __, columns, data): string | false => {
                return buildPerformanceReportCSV(columns, data)
              },
              downloadOptions: {
                filename: getPerformanceReportFilename({
                  startDate,
                  endDate,
                }),
              },
            }}
            title={
              <Box
                display='flex'
                alignItems='center'
                fontWeight={700}
                fontSize='16px'
                mr={4}>
                Performance Report
                <ReportRange
                  TypographyProps={{
                    className: classes.dateRange,
                    variant: 'h6',
                  }}
                  start={startDate}
                  end={endDate} />
              </Box>
            }
            performanceReport={performanceReport.data}
            segmentation={segmentation}
            aggregationPeriod={aggregationPeriod} />
        )}

      </Box>

      { (!activePlatformUnit || loading ) && (
        <BackdropLoader />
      )}

    </Fragment>
  )
}
