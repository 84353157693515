import React, { useMemo, useRef } from 'react'

import { Box } from '@material-ui/core'

import { PlainTextButtonMenu } from '../Menus'

import { MenuAbstractionProps, MenuOption } from '../Menus/makeMenuComponent'

import { Health } from '../Health'

import { ReportRange } from '../ReportRange'

import { DateRange, Today } from '../../icons'

import { makeAppStyles } from '../../themes'

import { sortBy } from 'lodash-es'

import { getReportResultHealth } from '@percept/utils'

import { Report, StructuralReport } from '@percept/types'


const useStyles = makeAppStyles( theme => ({
  selected: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.contrastText,
    },
  },
}))


type RequiredReportAttributes = Pick<
  Report,
  'report_id' | 'start' | 'end' | 'health' | 'status'
>


const areReportsEqual = (a: StructuralReport | undefined, b: StructuralReport | undefined): boolean => (
  Boolean(a && b && a.id === b.id)
)


type ExpectedTriggerProps = {
  disabled?: boolean
  className?: string
  startIcon?: React.ReactNode
}


export type ReportSelectProps = {
  reports: StructuralReport[]
  reportFilter?: (report: StructuralReport) => boolean 
  MenuComponent?: React.ComponentType<MenuAbstractionProps<ExpectedTriggerProps, StructuralReport>>
} & Omit<MenuAbstractionProps<ExpectedTriggerProps, StructuralReport>, 'options' | 'children'>


function defaultReportFilter(
  report: StructuralReport
): boolean {
  return !!report.results.length
}


export function ReportSelect({
  value,
  onChange,
  reports,
  MenuComponent = PlainTextButtonMenu,
  TriggerProps,
  reportFilter = defaultReportFilter,
  ...props
}: ReportSelectProps): JSX.Element {

  const label = value ? (
    <ReportRange
      disableTypography
      start={value.start}
      end={value.end} />
  ) : (
    'Select Report'
  )

  const filterRef = useRef(reportFilter)
  filterRef.current = reportFilter

  const options = useMemo(() => {
    const filteredReports = (
      filterRef.current ?
        reports.filter(filterRef.current) :
        reports
    )
    return sortBy(
      filteredReports.map( report => {
        const health = getReportResultHealth(report)
        return {
          value: report,
          label: (
            <Box
              display='flex'
              flexGrow={1}
              alignItems='center'
              fontWeight={700}
              fontSize={14}>

              <ReportRange
                disableTypography
                start={report.start}
                end={report.end} />

              { health !== null && (
                <Box
                  ml='auto'
                  pl={2}>
                  <Health
                    value={health} />
                </Box>
              )}

            </Box>
          ),
          start: report.start,
          end: report.end,
        }
      }) as MenuOption<StructuralReport>[],
      ['end', 'start']
    ).reverse()
  }, [reports, filterRef])

  const classes = useStyles()

  return (
    <MenuComponent
      label={label}
      value={value}
      selectedClassName={classes.selected}
      isEqual={areReportsEqual}
      {...props}
      onChange={onChange}
      TriggerProps={{
        startIcon: (
          value && value.start !== value.end ?
            <DateRange /> :
            <Today />
        ),
        disabled: !options.length,
        ...(TriggerProps || {}),
      }}
      options={options} />
  )

}
