import React from 'react'

import { Route, Switch } from 'react-router-dom'

import MediaWizardRoutes from 'MediaWizardRoutes'

import DashboardRoutes from 'dashboard/src/DashboardRoutes'
import ReportingRoutes from 'dashboard/src/ReportingRoutes'


const Routes = (): JSX.Element => {
  return (
    <Switch>
      <Route path='/wizard'>
        <MediaWizardRoutes />
      </Route>
      <Route path='/reporting'>
        <ReportingRoutes />
      </Route>
      <Route path='/'>
        <DashboardRoutes />
      </Route>
    </Switch>
  )
}

export default Routes
