import React from 'react'

import { Money, useAppTheme, useChannelColorStyles } from '@percept/mui'

import { percentageDeltaFormatter, percentageFormatter } from '@percept/mui/charts'

import { getPercentage } from '@percept/utils'

import { channelDisplayMap } from '@percept/constants'

import { ChannelKey } from '@percept/types'

import { CellRenderer } from './typings'


export const ChannelCellRenderer: CellRenderer<{
  channel: ChannelKey
  // eslint-disable-next-line react/prop-types
}> = ({ channel }): JSX.Element => {

  const channelClasses = useChannelColorStyles()

  return (
    <span className={channelClasses[channel] || undefined}>
      { channelDisplayMap[channel].label }
    </span>
  )
}


export function SentimentDelta({
  value,
  renderPositiveSign = true,
  invertSentiment = false,
  ...props
}: {
  value: number | null | undefined
  renderPositiveSign?: boolean
  invertSentiment?: boolean
} & React.HTMLAttributes<HTMLSpanElement>): JSX.Element {

  const { palette } = useAppTheme()

  value = value || 0

  const color = (
    value > 0 ?
      (invertSentiment ? palette.error.light : palette.success.light) :
      value < 0 ?
        (invertSentiment ? palette.success.light : palette.error.light) :
        palette.action.disabled
  )

  return (
    <span
      {...props}
      style={{
        color
      }}>
      {
        renderPositiveSign ?
          percentageDeltaFormatter(value) :
          percentageFormatter(value)
      }
    </span>
  )
}


export function DeltaCellRenderer<T extends object>(
  props: T & {
    baselineKey: keyof T
    deltaKey: keyof T
    cast?: (value: unknown) => number
  }
): JSX.Element {

  const cast = props.cast || Number

  const delta = Math.round(
    getPercentage(
      cast(props[props.deltaKey]),
      cast(props[props.baselineKey])
    ) - 100
  )

  return (
    <SentimentDelta value={delta} />
  )
}


export function SpendCellRenderer<T extends { currency?: string | null | undefined }>(
  props: T & {
    columnKey: keyof T
    abbreviate?: boolean
  }
): JSX.Element {
  return (
    <Money
      amount={Number(props[props.columnKey])}
      abbreviate={props.abbreviate === undefined ? true : props.abbreviate}
      currency={props.currency} />
  )
}
