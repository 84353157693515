import React from 'react'

import {
  RoundedPlainTextButton,
  Typography,
  makeAppStyles,
  BackdropLoader,
} from '@percept/mui'

import { VerifiedUser, Block, CheckCircleOutline } from '@percept/mui/icons'

import { useDerivedMFADeliveryMedium, useToggleMFAState } from '@percept/hooks'

import { MFAComponentProps } from './lib'


const useStyles = makeAppStyles( theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2.5),
    transition: theme.transitions.create('color'),
    fontSize: 20,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  enabled: {
    color: theme.chart.healthColourScale(1),
  },
  disabled: {
    color: theme.palette.action.disabled,
  },
  button: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(5),
  },
}))



export const ToggleMultiFactorAuthentication = ({
  accessToken,
}: MFAComponentProps): JSX.Element => {

  const activeDeliveryMedium = useDerivedMFADeliveryMedium()

  const isEnabled = !!activeDeliveryMedium

  const [status, toggleMFAState] = useToggleMFAState({
    access_token: accessToken,
    enabled: !isEnabled,
  })

  const classes = useStyles()

  const headerClassName = classes.header // isEnabled ? `${classes.header} ${classes.enabled}` : `${classes.header} ${classes.disabled}`
  const IconComponent = isEnabled ? CheckCircleOutline : Block
  const iconClassName = isEnabled ? `${classes.icon} ${classes.enabled}` : `${classes.icon} ${classes.disabled}`

  return (
    <div className={classes.root}>
      <Typography
        variant='h5'
        className={headerClassName}>
        <IconComponent
          className={iconClassName}
          color='inherit' />
        { isEnabled ? 'Enabled' : 'Disabled' }
      </Typography>

      <RoundedPlainTextButton
        className={classes.button}
        size='large'
        variant='contained'
        startIcon={
          isEnabled ?
            <Block /> :
            <VerifiedUser />
        }
        disabled={status.loading}
        onClick={(): void => {
          toggleMFAState({
            access_token: accessToken,
            enabled: !isEnabled,
          })
        }}>
        {isEnabled ? 'Disable' : 'Enable'} Two Factor Authentication
      </RoundedPlainTextButton>

      <BackdropLoader
        BackdropProps={{
          open: status.loading
        }} />

    </div>
  )
}
