
import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react'

import { throttle } from '@percept/utils'

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { DateType } from '@percept/types'

TimeAgo.addLocale(en)



export const useToggle = (initial = false): [boolean, (override?: any) => void] => {
  const [toggled, update] = useState(Boolean(initial))

  const toggle = useCallback((override?: boolean | undefined): void => (
    update( prev => typeof override === 'boolean' ? override : !prev )
  ), [])

  return [toggled, toggle]
}


export const usePrevious = <T>(value: T, deps = []) => {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [value, ...deps])
  
  return ref.current || value
}


export const useEvent = (
  event: string,
  handler: EventListener | null,
  context: EventTarget = document
): void => {
  const previousHandler = usePrevious(handler)

  useEffect(() => {

    const evts = event.split(' ')
    
    if( handler && previousHandler && handler !== previousHandler ){
      evts.forEach( evt => {
        context.removeEventListener(evt, previousHandler)
      })
    }
    
    if( handler ){
      evts.forEach( evt => context.addEventListener(evt, handler) )
    }
    
    return (): void => {
      if( handler ){
        evts.forEach( evt => context.removeEventListener(evt, handler) )
      }
    }
  
  }, [event, handler, previousHandler, context])
}


type WindowHookProps = {
  enabled?: true
  throttleBy?: number
}

type WindowSize = {
  width: number
  height: number
}

export const useWindowSize = ({ throttleBy = 150, enabled = true }: WindowHookProps = {}): WindowSize => {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  const handleResize = useMemo(() => (
    enabled ? (
      throttle(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }, throttleBy)
    ) : (
      null
    )
  ), [throttleBy, enabled])

  useEvent('resize', handleResize, window)

  return windowSize
}


type ScrollPosition = {
  x: number
  y: number  
}

export const useScroll = ({ throttleBy = 150 }: WindowHookProps = {}): ScrollPosition => {

  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>({
    x: window.scrollX,
    y: window.scrollY
  })

  const handleResize = useCallback(
    throttle(() => {
      setScrollPosition({
        x: window.scrollX,
        y: window.scrollY
      })
    }, throttleBy),
    [throttleBy])

  useEvent('scroll', handleResize, window)

  return scrollPosition
}


export const useTimeago = ({ timer = null, interval = 10 * 1000 } = {}) => {

  const [timerInstance, setTimerInstance] = useState(timer || new TimeAgo('en-US'))

  const [intervalRef, setIntervalRef] = useState<number | undefined>(undefined)

  useEffect(() => {

    if( !timer && !intervalRef ){
      setTimerInstance(new TimeAgo('en-US'))

      const newIntervalRef = window.setInterval(() => {
        setTimerInstance(new TimeAgo('en-US'))
      }, interval)

      setIntervalRef(newIntervalRef)
    }
    
    return () => {
      clearInterval(intervalRef)
    }
  }, [intervalRef, timer, setTimerInstance, setIntervalRef, interval])

  const formatter = useCallback( (d: DateType): string => {
    return timerInstance.format(new Date(d))
  }, [timerInstance])

  return [formatter]

}
