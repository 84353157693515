import { ReduxAction } from '@percept/types'

import { ThemingState } from './typings'


export const SET_APP_THEME_TYPE_PREFERENCE = 'SET_APP_THEME_TYPE_PREFERENCE'

export const setAppThemeTypePreference = (payload: ThemingState['themePreference']): ReduxAction => ({
  type: SET_APP_THEME_TYPE_PREFERENCE,
  payload,
})


export const SET_APP_THEME_BRANDING = 'SET_APP_THEME_BRANDING'

export const setAppThemeBranding = (payload: ThemingState['themeBranding']): ReduxAction => ({
  type: SET_APP_THEME_BRANDING,
  payload,
})
