
import { actionCreator } from '@percept/redux'

import {
  AsyncReduxAction,
  EntityType,
  DimensionType,
  ReduxAction,
  Report,
  ReportStatus,
  DateType,
} from '@percept/types'


export const LOAD_SERIES = 'LOAD_SERIES'
export const LOAD_SERIES_AUDITS = 'LOAD_SERIES_AUDITS'

export const CREATE_REPORT = 'CREATE_REPORT'
export const CLEAR_CREATE_REPORT = 'CLEAR_CREATE_REPORT'

export const CREATE_INITIAL_REPORT = 'CREATE_INITIAL_REPORT'
export const CLEAR_CREATE_INITIAL_REPORT = 'CLEAR_CREATE_INITIAL_REPORT'


export const LOAD_SERIES_OAUTH_URL = 'LOAD_SERIES_OAUTH_URL'

export const SAVE_SERIES_REFRESH_TOKEN = 'SAVE_SERIES_REFRESH_TOKEN'
export const CLEAR_SAVE_SERIES_REFRESH_TOKEN = 'CLEAR_SAVE_SERIES_REFRESH_TOKEN'

export const LOAD_SERIES_ACCOUNTS = 'LOAD_SERIES_ACCOUNTS'

export const MUTATE_SERIES = 'MUTATE_SERIES'
export const CLEAR_MUTATE_SERIES = 'CLEAR_MUTATE_SERIES'

export const DELETE_SERIES = 'DELETE_SERIES'
export const CLEAR_DELETE_SERIES = 'CLEAR_DELETE_SERIES'

export const LOAD_SERIES_METRIC = 'LOAD_SERIES_METRIC'
export const SET_ACTIVE_SERIES_METRIC = 'SET_ACTIVE_SERIES_METRIC'
export const CLEAR_ACTIVE_SERIES_METRIC = 'CLEAR_ACTIVE_SERIES_METRIC'

export const SET_ACTIVE_SERIES_PROVIDER = 'SET_ACTIVE_SERIES_PROVIDER'

export const SET_SERIES_CHART_TYPE = 'SET_SERIES_CHART_TYPE'
export const SET_SERIES_PERFORMANCE_TAIL = 'SET_SERIES_PERFORMANCE_TAIL'
export const SET_SERIES_HEALTH_DIMENSION = 'SET_SERIES_HEALTH_DIMENSION'


type RequiresSeriesId<T = {}> = T & {
  series_id: string
}


export const loadSeries = ({ series_id }: RequiresSeriesId): AsyncReduxAction => ({
  type: LOAD_SERIES,
  resource: `/series/${series_id}`,
  meta: { series_id }
})

export const loadSeriesAudits = (
  { series_id, limit = 500 }: RequiresSeriesId<{ limit?: number }>
): AsyncReduxAction => ({
  type: LOAD_SERIES_AUDITS,
  resource: `/series/${series_id}/reports`,
  options: {
    params: {
      limit,
    },
  },
  meta: { series_id }
})


interface CreateReportProps extends Pick<Report, 'status' | 'start' | 'end'> {
  series_id: string
}

export const createReport = ({ series_id, start, end }: CreateReportProps): AsyncReduxAction => ({
  type: CREATE_REPORT,
  resource: `/series/${series_id}/reports`,
  options: {
    method: 'POST',
    data: {
      status: 'SCHEDULED',
      from: start,
      until: end,
    } as {
      status: ReportStatus,
      from: DateType
      until: DateType
    }
  },
  meta: { series_id }
})

export const clearCreateReport = ({ series_id }: { series_id: string }): ReduxAction => ({
  type: CLEAR_CREATE_REPORT,
  payload: { series_id },
  meta: { series_id },
})


export const createInitialReport = ({ series_id }: { series_id: string }): AsyncReduxAction => ({
  type: CREATE_INITIAL_REPORT,
  resource: `/series/${series_id}/initial-report`,
  options: {
    method: 'POST'
  },
  meta: { series_id },
})

export const clearCreateInitialReport = ({ series_id }: { series_id: string }): ReduxAction => ({
  type: CLEAR_CREATE_INITIAL_REPORT,
  payload: { series_id },
  meta: { series_id },
})


export const loadSeriesOAuthUrl = ({ series_id }: RequiresSeriesId): AsyncReduxAction => ({
  type: LOAD_SERIES_OAUTH_URL,
  resource: `/series/${series_id}/adwords-consent-url`,
  meta: { series_id }
})

export const loadSeriesAccounts = ({ series_id }: RequiresSeriesId): AsyncReduxAction => ({
  type: LOAD_SERIES_ACCOUNTS,
  resource: `/series/${series_id}/adwords-accounts`,
  meta: { series_id }
})

export const saveSeriesRefreshToken = (
  { series_id, token }:
  { series_id: string, token: string }
): AsyncReduxAction => ({
  type: SAVE_SERIES_REFRESH_TOKEN,
  resource: `/series/${series_id}/adwords-token`,
  options: {
    method: 'POST',
    data: { code: token }
  },
  meta: { series_id }
})

export const clearSaveSeriesRefreshToken = actionCreator(CLEAR_SAVE_SERIES_REFRESH_TOKEN)


export const mutateSeries = (
  { series_group_id, series_id, ...data }:
  { series_group_id: string, series_id: string }
): AsyncReduxAction => ({
  type: MUTATE_SERIES,
  resource: `/series/${series_id}`,
  options: {
    method: 'PATCH',
    data,
  },
  meta: {
    series_group_id, series_id
  },
  successActions: [
    loadSeries({ series_id }),
  ]
})

export const clearMutateSeries = (
  { series_id }: { series_id?: string } = {}
): ReduxAction => ({
  type: CLEAR_MUTATE_SERIES,
  payload: null,
  meta: { series_id }
})

export const deleteSeries = ({ series_id }: RequiresSeriesId): AsyncReduxAction => ({
  type: DELETE_SERIES,
  resource: `/series/${series_id}`,
  options: {
    method: 'DELETE' 
  },
  meta: { series_id },
})

export const clearDeleteSeries = (
  { series_id }: { series_id?: string } = {}
): ReduxAction => ({
  type: CLEAR_DELETE_SERIES,
  payload: null,
  meta: { series_id }
})


type LoadSeriesMetricProps = {
  series_id: string,
  metric_id: string,
  entity_type: EntityType,
  entity_id: string | number,
  dimension: DimensionType,
  limit?: number,
  cursor?: string,
}

export const loadSeriesMetric = (
  { series_id, metric_id, entity_type, entity_id, dimension, limit = 0, cursor = '' }: LoadSeriesMetricProps
): AsyncReduxAction => ({
  type: LOAD_SERIES_METRIC,
  resource: `series/${series_id}/time-series/${entity_type}/${entity_id}/${encodeURIComponent(metric_id)}/${dimension}`,
  options: {
    params: {
      cursor,
      limit,
    } 
  },
  meta: { series_id, metric_id, entity_type, entity_id, dimension, cursor, limit }
})

export const setActiveSeriesMetric = actionCreator(SET_ACTIVE_SERIES_METRIC)

export const clearActiveSeriesMetric = actionCreator(CLEAR_ACTIVE_SERIES_METRIC)

export const setActiveSeriesProvider = actionCreator(SET_ACTIVE_SERIES_PROVIDER)

export const setSeriesChartType = actionCreator(SET_SERIES_CHART_TYPE)

export const setSeriesPerformanceTail = (payload: string): ReduxAction => ({
  type: SET_SERIES_PERFORMANCE_TAIL,
  payload,
})

export const setSeriesHealthDimension = (payload: DimensionType): ReduxAction => ({
  type: SET_SERIES_HEALTH_DIMENSION,
  payload,
})
