import { isBefore } from 'date-fns'
import { Campaign, CampaignWithKey, SpendingCell } from '../types'
import { FormColumns } from 'enums/FormColumns'


export const generateRowKey = (row: Campaign): string => {
  const ignoreKeys = [
    'data', 'currency', 'editable'
  ]
  const keys = Object.keys(row).filter((key) => (
    !ignoreKeys.includes(key)
  )).sort() as FormColumns[]

  const fragments = keys.map( key => (
    row[key] === null ? `${key}:NONE` : `${key}:${row[key].name}`
  ))

  return fragments.join('|')
}

export const addKeyToCampaign = (row: Campaign): CampaignWithKey => ({
  ...row,
  key: generateRowKey(row),
})


export const findDuplicates = (data: Campaign[]): boolean[] => {
  const rowKeys = data.map(generateRowKey)
  const duplicateFlags = rowKeys.map((key, i) => {
    return rowKeys.some((keyToCompare, j) => {
      // Ignore the same row when the indices match
      if( i === j ){
        return false
      }
      // Compare generated row keys
      return key === keyToCompare
    })
  })
  return duplicateFlags
}


export const findEmptyRow = (data: Campaign[]): boolean[] =>
  data.map((el) =>
    Object.values({ ...el, data: 'value' }).some(
      (v) => v === '' || (v === null ? false : typeof v === 'object' ? v.id === '' : false)
    )
  )

export const fillDataWithEmptyMonth = (
  data: SpendingCell[],
  months: string[],
  modified: string
): SpendingCell[] => {
  const emptyMonths = months.filter((el) => !data.some((e) => e.month === el))
  const res = emptyMonths.map((el) => ({
    month: el,
    amount: '',
    modified,
  }))
  return [...data, ...res]
}


const monthLookup: Record<string, number> = {
  'JAN': 1,
  'FEB': 2,
  'MAR': 3,
  'APR': 4,
  'MAY': 5,
  'JUN': 6,
  'JUL': 7,
  'AUG': 8,
  'SEP': 9,
  'OCT': 10,
  'NOV': 11,
  'DEC': 12,
}

export const filterFutureMonths = (
  months: { id: string; name: string; }[]
): { id: string; name: string; }[] => {
  const now = new Date()
  return months.filter( m => {
    try{
      const [month, YY] = m.name.split('-')
      const YYYY = Number('20' + YY)
      const M = monthLookup[month.toUpperCase()]
      const referenceDate = new Date(YYYY, M - 1, 1)
      return isBefore(referenceDate, now)
    }catch(e){
      return true
    }
  })
}
