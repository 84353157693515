
import React, { Fragment, useMemo, useState } from 'react'

import {
  CardStrip,
  ChipMenu,
  List,
  ListItem,
  ListItemText,
  MenuOption,
  SearchableItems,
  SentimentDelta,
  Typography,
  makeAppStyles,
} from '@percept/mui'

import { ChartData } from '@percept/mui/charts'

import {
  SvgIconComponent,
  SwapVert,
  TrendingDown,
  TrendingUp,
} from '@percept/mui/icons'

import { round, sortBy } from 'lodash-es'

import { DatumType } from '@percept/types'


export type VarianceSortOrder = 'asc' | 'desc' | 'abs'


export type VarianceDetailProps<T extends DatumType> = {
  title: string
  data: ChartData<T>
  invertSentiment?: boolean
  sort?: VarianceSortOrder
  onItemClick?: (item: T) => void
}


const sortOptions: Record<VarianceSortOrder, {
  IconComponent: SvgIconComponent,
  label: string
}> = {
  asc: {
    label: 'Greatest Increase',
    IconComponent: TrendingUp,
  },
  desc: {
    label: 'Greatest Decrease',
    IconComponent: TrendingDown,
  },
  abs: {
    label: 'Greatest Change',
    IconComponent: SwapVert,
  },
}


const sortMenuOrder: VarianceSortOrder[] = [
  'abs',
  'asc',
  'desc',
]


const sortMenuOptions: MenuOption<VarianceSortOrder>[] = sortMenuOrder.map( value => ({
  value,
  label: sortOptions[value].label,
}) )


const useStyles = makeAppStyles( theme => ({
  content: {
    padding: theme.spacing(2, 3),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  chipMenuContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  chipMenu: {
    marginLeft: theme.spacing(1),
  },
  listContainer: {
    marginTop: theme.spacing(2),
  },
  listItem: {
    '&:nth-child(odd)': {
      background: theme.palette.action.disabledBackground,
    },
  },
  sentimentDelta: {
    fontSize: 13,
    fontWeight: theme.typography.fontWeightBold,
    minWidth: '4.5em',
  },
}) )


export function VarianceDetail<T extends DatumType>({
  title,
  data,
  sort = 'abs',
  invertSentiment = false,
  onItemClick,
}: VarianceDetailProps<T>): JSX.Element {

  const [sortOrder, setSortOrder] = useState(sort)

  const items = useMemo(() => {
    const sortIteratee = (
      sortOrder === 'asc' ?
        ((d: DatumType): number => (d.value || 0) * -1 ) :
        sortOrder == 'desc' ?
          'value' :
          ((d: DatumType): number => Math.abs(d.value || 0) * -1 )
    )
    return sortBy(data, sortIteratee)
  }, [data, sortOrder])

  const classes = useStyles()

  const { label, IconComponent } = sortOptions[sortOrder]

  return (
    <Fragment>

      {/* <CardStrip color='secondary' /> */}

      <div className={classes.content}>

        <Typography
          className={classes.title}
          variant='h5'>
          { title }
        </Typography>

        <div className={classes.chipMenuContainer}>
          <Typography
            variant='subtitle1'>
            Sort by
          </Typography>

          <ChipMenu
            value={sortOrder}
            TriggerProps={{
              className: classes.chipMenu,
              size: 'small',
              color: 'secondary',
              icon: <IconComponent />,
            }}
            label={label}
            options={sortMenuOptions}
            onChange={(e, value: VarianceSortOrder): void => setSortOrder(value)} />
        </div>

        <div className={classes.listContainer}>

          <SearchableItems
            ItemWrapComponent={List}
            searchIteratee='label'
            pluralText='markets'
            InputProps={{
              autoFocus: true,
              color: 'secondary',
            }}
            renderItem={(item): JSX.Element => (
              <ListItem
                className={classes.listItem}
                dense
                {...(!!onItemClick && {
                  onClick: (): void => onItemClick(item),
                  // TypeScript definitions for ListItem aren't perfect, as you can see...
                  // `button` is typed to true | undefined with onClick, which works
                  // when props aren't injected in this manner, so we do this to keep it happy.
                  button: true as unknown as false,
                } || {})}
                key={item.label}>

                <SentimentDelta
                  className={classes.sentimentDelta}
                  invertSentiment={invertSentiment}
                  value={round(item.value || 0, 1)} />

                <ListItemText
                  primaryTypographyProps={{variant: 'subtitle1'}}
                  primary={item.label} />

              </ListItem>
            )}
            items={items} />

        </div>

      </div>

    </Fragment>
  )
}
