import React from 'react'

import { DefaultLayout } from './DefaultLayout'

import { PerformanceReport } from '../components/PerformanceReport'


export const PerformanceReportScreen = (): JSX.Element => {
  return (
    <DefaultLayout>
      <PerformanceReport />
    </DefaultLayout>
  )
}

export default PerformanceReportScreen
