import React, { FC, useEffect, useState } from 'react'
import { useInfiniteQuery, useQueryClient } from 'react-query'
import { Grid, Box, CircularProgress, Loader, ProviderLogo, RoundedPlainTextButton, Divider } from '@percept/mui'
import { Add } from '@percept/mui/icons'
import { CategorySelect, isAdvertisingPartner } from './CategorySelect'
import { TabSectionHeader } from './PartnershipTabs'
import { LinkCard } from './LinkCard'
import { partnershipsClient } from './partnershipsClient'
import { format } from 'date-fns'
import { capitalize } from 'lodash-es'
import { InfinitePaginationWrapper, PartnerCategory, PartnershipsArticle } from './types'
import { CaseStudy } from './CaseStudy'
import { PARTNERSHIPS_QUERY_KEY } from './constants'


export const PartnershipsArticleSummary = ({
  image_url,
  partner_category,
  publish_date,
  summary,
  title,
  external_url,
}: PartnershipsArticle): JSX.Element => {
  return (
    <LinkCard
      title={title}
      summaryElements={[summary]}
      metadataContent={
        <>
          { format(new Date(publish_date), 'dd MMMM yyyy') }
          { partner_category && (
            <>
              <span style={{margin: '0 6px'}}>|</span>
              { isAdvertisingPartner(partner_category) ?
                <ProviderLogo size={1.25} provider={partner_category} /> :
                <strong>{capitalize(partner_category)}</strong>
              }
            </>
          )}
        </>
      }
      imageUrl={image_url}
      externalUrl={external_url}
      externalUrlLinkText='Link to the article' />
  )
}


export const getArticlesPaginated = async (
  page: number, category?: PartnerCategory | null, upcoming?: boolean, previous?: boolean
): Promise<InfinitePaginationWrapper<PartnershipsArticle>> => {
  const res = await partnershipsClient
    .get(`/articles`, {
      params: { limit: 5, page_number: page, upcoming, previous, partner_category: category, order_by_desc: 'publish_date'  },
    })
  const hasNext = page * res.data.size < res.data.total
  return {
    nextPage: hasNext ? page + 1 : undefined,
    previousPage: page > 1 ? page - 1 : undefined,
    items: res.data.items,
  }
}


export const Articles: FC = () => {
  const [category, setCategory] = useState<PartnerCategory | null>(null)
  const queryClient = useQueryClient()

  const {isLoading, isError, data, isFetchingNextPage, hasNextPage, fetchNextPage, refetch} = useInfiniteQuery({
    queryKey: [PARTNERSHIPS_QUERY_KEY, 'infinite', category],
    getNextPageParam: (prevData: InfinitePaginationWrapper<PartnershipsArticle>) => prevData.nextPage,
    queryFn: ({ pageParam = 1 }) => getArticlesPaginated(pageParam, category),
  })

  useEffect(() => {
    refetch()
    queryClient.invalidateQueries([PARTNERSHIPS_QUERY_KEY, 'infinite', category])
  }, [category, refetch, queryClient])
  
  return (
    <>
      <TabSectionHeader collapsible={false} title="Latest Articles">
        <CategorySelect
          value={category || undefined}
          emptySelectLabel='View All'
          onChange={(_e, value): void => {
            setCategory(value || null)
          }} />
      </TabSectionHeader> 
      <Box mt={3}>
        {isLoading && (<Loader preset='centered' minHeight='10rem' />)}
        {isError && (<Box width="100%" textAlign="center">Error occurred while fetching data.</Box>)}
        {!isLoading && (
          <>
            <Grid container spacing={3}>
              {data && (data.pages.flatMap( data => data.items ) ?? [])
                .filter( article => article.partner_category === category || category === null )
                .map( article => (
                  <Grid item xs={12} md={6} lg={4} xl={3} key={article.title}>
                    <PartnershipsArticleSummary
                      id={article.id}
                      publish_date={article.publish_date}
                      title={article.title}
                      summary={article.summary}
                      image_url={article.image_url}
                      local_timezone_info={article.local_timezone_info}
                      external_url={article.external_url}
                      image_upload={article.image_upload}
                      partner_category={article.partner_category}
                      publish={article.publish}
                    />
                  </Grid>
                ))}
            </Grid>
            {hasNextPage && (
              <Box marginTop={6} textAlign="center">
                <RoundedPlainTextButton
                  color='primary'
                  variant='contained'
                  size='large'
                  disabled={isFetchingNextPage}
                  startIcon={
                    isFetchingNextPage ?
                      <CircularProgress size='1em' color='inherit' /> :
                      <Add />
                  }
                  onClick={(): void => {
                    fetchNextPage()
                  }}>
                  Load More
                </RoundedPlainTextButton>
              </Box>
            )}
          </>
        )}
      </Box>

      <Box my={6}>
        <Divider />
      </Box>

      <TabSectionHeader collapsible={false} title="Case Studies" />
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <CaseStudy />
          </Grid>
        </Grid>
      </Box>
  </>
  )
}
