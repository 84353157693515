import {
  adminUsers,
  clientSeries,
  clientReports,
  jsonSchemas,
  performanceReporting,
  platformUnits,
  structuralReporting,
  user,
} from '@percept/redux/bundles'

import { every, pick } from 'lodash-es'

import {
  CognitoConfigurationParams,
  InsightsReportLoadParams,
  Nullable,
  PaginatedParams,
  SeriesParams,
  SoftwareMFAStateParams,
  TokenPaginatedParams,
} from '@percept/types'

import {
  makeLoaderHook,
  makeMutationHook,
  makePropLoaderHook,
  makePropMutationHook,
  makeSelectorHook,
  makeDispatchHook,
} from './libv2'


const makeFilteredValidator = <T extends object>(
  ignoreKeys: string[]
) => (
    params?: PaginatedParams<T> | Nullable<PaginatedParams<T>> | null
  ): params is PaginatedParams<T> => {
    if( !params ){
      return true
    }
    const values = Object.values(
      pick(params, Object.keys(params).filter( k => !ignoreKeys.includes(k) ))
    )
    return values.length === 0 || every(values)
  }


const paginationRequestValidator = makeFilteredValidator<PaginatedParams>(['page', 'size'])

const userRequestValidator = makeFilteredValidator<TokenPaginatedParams<CognitoConfigurationParams>>(['paginated_token'])


/* Admin Users */

export const usePaginatedAdminUsers = makePropLoaderHook(
  adminUsers.selectors.getPaginatedUsers,
  adminUsers.actions.loadUsers,
  { autoload: true },
)

export const useAdminUser = makePropLoaderHook(
  adminUsers.selectors.getUser,
  adminUsers.actions.loadUser,
  { autoload: true },
)

export const useCreateUser = makeMutationHook(
  adminUsers.selectors.getCreateUser,
  adminUsers.actions.createUser,
  adminUsers.actions.resetCreateUser,
)

export const useEditUser = makeMutationHook(
  adminUsers.selectors.getEditUser,
  adminUsers.actions.editUser,
  adminUsers.actions.resetEditUser,
)

export const useResendUserInvite = makeMutationHook(
  adminUsers.selectors.getResendUserInvite,
  adminUsers.actions.resendUserInvite,
  adminUsers.actions.resetResendUserInvite,
)


/* User */

export const useSignIn = makePropLoaderHook(
  user.selectors.getUserSignInResponse,
  user.actions.signIn,
  { autoload: false },
)

export const useSignOut = makeDispatchHook(
  user.actions.signOut,
)

export const useTokenRefresh = makePropLoaderHook(
  user.selectors.getUserTokenRefreshResponse,
  user.actions.oAuthRefresh,
  { autoload: false },
)

export const useBeginMFASetup = makePropMutationHook(
  user.selectors.getUserBeginMFASetupResponse,
  user.actions.beginSoftwareMfaSetup,
  user.actions.resetBeginSoftwareMfaSetup,
)

export const useCompleteMFASetup = makePropMutationHook(
  user.selectors.getUserCompleteMFASetupResponse,
  user.actions.completeSoftwareMfaSetup,
  user.actions.resetCompleteSoftwareMfaSetup,
)

export const useToggleMFAState = makePropMutationHook(
  user.selectors.getUserToggleMFAStateResponse,
  user.actions.toggleSoftwareMfaState,
  user.actions.resetToggleSoftwareMfaState,
  // NOTE - custom validator required here, as the default
  // implementation would suppress requests with `enabled: false`
  { validate: (params): params is SoftwareMFAStateParams => !!(params && params.access_token) },
)

export const useCodeChallengeResponse = makePropLoaderHook(
  user.selectors.getUserCodeChallengeResponse,
  user.actions.respondToCodeChallenge,
  { autoload: false }
)

export const useDerivedMFADeliveryMedium = makeSelectorHook(
  user.selectors.getUserDerivedMfaDeliveryMedium
)

export const useMFAOptions = makeSelectorHook(
  user.selectors.getUserMfaOptions
)

export const useMFAPreference = makeSelectorHook(
  user.selectors.getUserMfaPreference
)

export const useCanManageMFA = makeSelectorHook(
  user.selectors.getCanManageMFA
)

export const useResetMFASetup = makeDispatchHook(
  user.actions.resetMfaSetup
)

export const useDefaultPlatformUnitId = makeSelectorHook(
  user.selectors.getDefaultPlatformUnitId
)

export const useMyUser = makeLoaderHook(
  user.selectors.getMyUser,
  user.actions.loadMyUser,
  { autoload: true },
)

export const useMyUserDetail = makeLoaderHook(
  user.selectors.getMyUserDetail,
  user.actions.loadMyUserDetail,
  { autoload: true },
)

export const useMyUserPrivileges = makeLoaderHook(
  user.selectors.getMyUserPrivileges,
  user.actions.loadMyUserPrivileges,
  { autoload: true },
)

export const useSetInitialPassword = makePropLoaderHook(
  user.selectors.getUserSetInitialPasswordResponse,
  user.actions.setInitialPassword,
  { autoload: false },
)

export const useSingleSignOnMetadata = makeLoaderHook(
  user.selectors.getSingleSignOnMetadata,
  user.actions.loadSingleSignOnMetadata,
  { autoload: true },
)

export const useSingleSignOnCodeExchange = makeMutationHook(
  user.selectors.getSingleSignOnCodeExchange,
  user.actions.exchangeSingleSignOnCode,
  user.actions.resetExchangeSingleSignOnCode,
)


/* Platform Units */

export const usePaginatedPlatformUnits = makePropLoaderHook(
  platformUnits.selectors.getPaginatedPlatformUnits,
  platformUnits.actions.loadPlatformUnits,
  { autoload: true, validate: paginationRequestValidator },
)


export const useUserPlatformUnits = makeLoaderHook(
  user.selectors.getUserPlatformUnits,
  user.actions.loadMyUser,
  { autoload: true },
)

export const usePlatformUnit = makePropLoaderHook(
  platformUnits.selectors.getPlatformUnit,
  platformUnits.actions.loadPlatformUnit,
  { autoload: true },
)

export const useAddPlatformUnit = makeMutationHook(
  platformUnits.selectors.getAddPlatformUnit,
  platformUnits.actions.addPlatformUnit,
  platformUnits.actions.resetAddPlatformUnit,
)

export const useAddPlatformUnitPerformanceReportingProvider = makeMutationHook(
  platformUnits.selectors.getAddPlatformUnitPerformanceReportingProvider,
  platformUnits.actions.addPlatformUnitPerformanceReportingProvider,
  platformUnits.actions.resetAddPlatformUnitPerformanceReportingProvider,
)

export const useAddPlatformUnitStructuralReportSeries = makeMutationHook(
  platformUnits.selectors.getAddPlatformUnitStructuralReportSeries,
  platformUnits.actions.addPlatformUnitStructuralReportSeries,
  platformUnits.actions.resetAddPlatformUnitStructuralReportSeries,
)

export const useEditPlatformUnit = makeMutationHook(
  platformUnits.selectors.getEditPlatformUnit,
  platformUnits.actions.editPlatformUnit,
  platformUnits.actions.resetEditPlatformUnit,
)

export const useDeletePlatformUnit = makeMutationHook(
  platformUnits.selectors.getDeletePlatformUnit,
  platformUnits.actions.deletePlatformUnit,
  platformUnits.actions.resetDeletePlatformUnit,
)

export const usePlatformUnitTree = makePropLoaderHook(
  platformUnits.selectors.getPlatformUnitTree,
  platformUnits.actions.loadPlatformUnitTree,
  { autoload: true },
)

export const usePlatformUnitProviderInfo = makePropLoaderHook(
  platformUnits.selectors.getPlatformUnitProviderInfo,
  platformUnits.actions.loadPlatformUnitProviderInfo,
  { autoload: true },
)


/* Report Series */

export const useReportSeries = makePropLoaderHook(
  structuralReporting.selectors.getReportSeries,
  structuralReporting.actions.loadReportSeries,
  { autoload: true }
)

export const useAdminReportSeries = makePropLoaderHook(
  structuralReporting.selectors.getReportSeriesDetail,
  structuralReporting.actions.loadReportSeriesDetail,
  { autoload: true }
)

export const useEditReportSeries = makePropMutationHook(
  structuralReporting.selectors.getEditReportSeries,
  structuralReporting.actions.editReportSeries,
  structuralReporting.actions.resetEditReportSeries,
  // NOTE - custom validator required to enable valid `false` values
  { validate: (params): params is SeriesParams => !!(params && params.series_id) },
)

export const useCreateReportTask = makePropMutationHook(
  structuralReporting.selectors.getCreateReportTask,
  structuralReporting.actions.createReportTask,
  structuralReporting.actions.resetCreateReportTask,
)


/* Legacy Series Proxies */


/* Series (Legacy API) */

// export const useClientSeries = makePropLoaderHook(
//   clientSeries.selectors.getClientSeries,
//   clientSeries.actions.loadSeries,
//   { autoload: true }
// )

export const useClientSeriesOAuthUrl = makePropLoaderHook(
  clientSeries.selectors.getClientSeriesOAuthUrl,
  clientSeries.actions.loadSeriesOAuthUrl,
  /**
   * NOTE - we don't want to `autoload` this request,
   * as it should be made on demand when series OAuth is required.
   */
  { autoload: false }
)

export const useSaveClientSeriesRefreshToken = makePropMutationHook(
  clientSeries.selectors.getClientSeriesRefreshToken,
  clientSeries.actions.saveSeriesRefreshToken,
  clientSeries.actions.clearSaveSeriesRefreshToken,
)


// export const useClientSeriesReports = makePropLoaderHook(
//   clientSeries.selectors.getClientSeriesReports,
//   clientSeries.actions.loadSeriesReports,
//   { autoload: true }
// )

// export const useActiveClientSeriesReports = makePropSelectorHook(
//   clientSeries.selectors.getActiveClientSeriesReports
// )

// export const useClientReport = makePropLoaderHook(
//   clientReports.selectors.getClientReport,
//   clientReports.actions.loadClientReport,
//   { autoload: true }
// )

export const useClientReportPayload = makePropLoaderHook(
  clientReports.selectors.getClientReportPayload,
  clientReports.actions.loadClientReportPayload,
  { autoload: true }
)

export const useClientReportEntityPayload = makePropLoaderHook(
  clientReports.selectors.getClientReportEntity,
  clientReports.actions.loadClientReportEntity,
  { autoload: true }
)

export const useClientSeriesReportMetric = makePropLoaderHook(
  clientSeries.selectors.getSeriesReportMetric,
  clientSeries.actions.loadSeriesReportMetric,
  { autoload: true }
)

export const useClientReportMetric = makePropLoaderHook(
  clientReports.selectors.getClientReportMetric,
  clientReports.actions.loadClientReportMetric,
  { autoload: true }
)


/* Structural Reporting */

// Metadata 

export const usePaginatedMetricMetadata = makePropLoaderHook(
  structuralReporting.selectors.getPaginatedMetricMetadata,
  structuralReporting.actions.loadAllMetricMetadata,
  { autoload: true, validate: paginationRequestValidator },
)

export const useFilteredMetricMetadata = makePropLoaderHook(
  structuralReporting.selectors.getMetricMetadataByProvider,
  structuralReporting.actions.loadMetricMetadataByProvider,
  { autoload: true }
)

export const useAddMetricMetadata = makeMutationHook(
  structuralReporting.selectors.getAddMetricMetadata,
  structuralReporting.actions.addMetricMetadata,
  structuralReporting.actions.resetAddMetricMetadata,
)

export const useEditMetricMetadata = makeMutationHook(
  structuralReporting.selectors.getEditMetricMetadata,
  structuralReporting.actions.editMetricMetadata,
  structuralReporting.actions.resetEditMetricMetadata,
)

// Layouts

export const usePaginatedReportLayouts = makePropLoaderHook(
  structuralReporting.selectors.getPaginatedReportLayouts,
  structuralReporting.actions.loadReportLayouts,
  { autoload: true, validate: paginationRequestValidator },
)

export const useReportLayout = makePropLoaderHook(
  structuralReporting.selectors.getReportLayout,
  structuralReporting.actions.loadReportLayout,
  { autoload: true }
)

export const useAddReportLayout = makeMutationHook(
  structuralReporting.selectors.getAddReportLayout,
  structuralReporting.actions.addReportLayout,
  structuralReporting.actions.resetAddReportLayout,
)

export const useEditReportLayout = makeMutationHook(
  structuralReporting.selectors.getEditReportLayout,
  structuralReporting.actions.editReportLayout,
  structuralReporting.actions.resetEditReportLayout,
)

export const useReportLayoutPayload = makePropLoaderHook(
  structuralReporting.selectors.getReportLayoutPayload,
  structuralReporting.actions.loadReportLayout,
  { autoload: true }
)

// Structural Reports

export const useRerunReportSeries = makeMutationHook(
  structuralReporting.selectors.getRerunReportSeries,
  structuralReporting.actions.rerunReportSeries,
  structuralReporting.actions.resetRerunReportSeries
)

export const useRerunReportWorkflow = makeMutationHook(
  structuralReporting.selectors.getRerunReportWorkflow,
  structuralReporting.actions.rerunReportWorkflow,
  structuralReporting.actions.resetRerunReportWorkflow,
)

export const useRerunReportWorkflowCompletionHandlers = makeMutationHook(
  structuralReporting.selectors.getRerunReportWorkflowCompletionHandlers,
  structuralReporting.actions.rerunReportWorkflowCompletionHandlers,
  structuralReporting.actions.resetRerunReportWorkflowCompletionHandlers,
)

export const useSetActiveWorkflowResults = makeMutationHook(
  structuralReporting.selectors.getSetActiveReportWorkflowResults,
  structuralReporting.actions.setActiveReportWorkflowResults,
  structuralReporting.actions.resetSetActiveReportWorkflowResults,
)

export const useAddReportSeriesReportWorkflow = makeMutationHook(
  structuralReporting.selectors.getAddReportSeriesReportWorkflow,
  structuralReporting.actions.addReportSeriesReportWorkflow,
  structuralReporting.actions.resetAddReportSeriesReportWorkflow,
)

export const useAddReportSeriesReportWorkflowFromLegacyTask = makeMutationHook(
  structuralReporting.selectors.getAddReportSeriesReportWorkflowFromLegacyTask,
  structuralReporting.actions.addReportSeriesReportWorkflowFromLegacyTask,
  structuralReporting.actions.resetAddReportSeriesReportWorkflowFromLegacyTask,
)

export const useWorkflowExecutionHistory = makePropLoaderHook(
  structuralReporting.selectors.getWorkflowExecutionHistory,
  structuralReporting.actions.loadWorkflowExecutionHistory,
  { autoload: true },
)

export const useReportSeriesReports = makePropLoaderHook(
  structuralReporting.selectors.getReportSeriesReports,
  structuralReporting.actions.loadReportSeriesReports,
  { autoload: true },
)

export const useReportSeriesMetricTimeseries = makePropLoaderHook(
  structuralReporting.selectors.getReportSeriesMetricTimeseries,
  structuralReporting.actions.loadReportSeriesMetricTimeseries,
  { autoload: true },
)

export const useReportSeriesReport = makePropLoaderHook(
  structuralReporting.selectors.getReportSeriesReport,
  structuralReporting.actions.loadReportSeriesReport,
  { autoload: true },
)

export const useReportSeriesReportWorkflows = makePropLoaderHook(
  structuralReporting.selectors.getReportSeriesReportWorkflows,
  structuralReporting.actions.loadReportSeriesReportWorkflows,
  { autoload: true },
)

export const useReportSeriesReportLegacyTasks = makePropLoaderHook(
  structuralReporting.selectors.getReportSeriesReportLegacyTasks,
  structuralReporting.actions.loadReportSeriesReportLegacyTasks,
  { autoload: true },
)

export const useReportSeriesReportStructure = makePropLoaderHook(
  structuralReporting.selectors.getReportSeriesReportStructure,
  structuralReporting.actions.loadReportSeriesReportStructure,
  { autoload: true },
)

export const useReportSeriesReportPayload = makePropLoaderHook(
  structuralReporting.selectors.getReportSeriesReportPayload,
  structuralReporting.actions.loadReportSeriesReportPayload,
  { autoload: true },
)

export const useReportSeriesReportEntity = makePropLoaderHook(
  structuralReporting.selectors.getReportSeriesReportEntity,
  structuralReporting.actions.loadReportSeriesReportEntity,
  { autoload: true },
)

export const useReportSeriesReportMetric = makePropLoaderHook(
  structuralReporting.selectors.getReportSeriesReportMetric,
  structuralReporting.actions.loadReportSeriesReportMetric,
  { autoload: true },
)

export const useReportSeriesReportInsightsReport = makePropLoaderHook(
  structuralReporting.selectors.getReportSeriesReportInsightsReport,
  structuralReporting.actions.loadReportSeriesReportInsightsReport,
  {
    autoload: true,
    validate: (props): props is InsightsReportLoadParams => Boolean(
      props && props.insights_report_type && props.series_id && props.report_id
    ),
  },
)

export const useReportSeriesLatestInsightsReport = makePropLoaderHook(
  structuralReporting.selectors.getReportSeriesInsightsReport,
  structuralReporting.actions.loadReportSeriesLatestInsightReport,
  { autoload: true },
)

export const useReportSeriesPillarScores = makePropLoaderHook(
  structuralReporting.selectors.getReportSeriesPillarScores,
  structuralReporting.actions.loadReportSeriesPillarScores,
  { autoload: true },
)

export const useProviderPillarScores = makePropLoaderHook(
  structuralReporting.selectors.getPlatformUnitProviderPillarScores,
  structuralReporting.actions.loadPlatformUnitProviderPillarScores,
  { autoload: true },
)

export const useChannelPillarScores = makePropLoaderHook(
  structuralReporting.selectors.getPlatformUnitChannelPillarScores,
  structuralReporting.actions.loadPlatformUnitChannelPillarScores,
  { autoload: true },
)

export const useNestedProviderPillarScores = makePropLoaderHook(
  structuralReporting.selectors.getNestedPlatformUnitProviderPillarScores,
  structuralReporting.actions.loadPlatformUnitChildrenProviderPillarScores,
  { autoload: true },
)

export const useNestedChannelPillarScores = makePropLoaderHook(
  structuralReporting.selectors.getNestedPlatformUnitChannelPillarScores,
  structuralReporting.actions.loadPlatformUnitChildrenChannelPillarScores,
  { autoload: true },
)

export const useReportSeriesPotentialEfficiencies = makePropLoaderHook(
  structuralReporting.selectors.getReportSeriesPotentialEfficiencies,
  structuralReporting.actions.loadReportSeriesPotentialEfficiencies,
  { autoload: true },
)

export const usePlatformUnitPotentialEfficiencies = makePropLoaderHook(
  structuralReporting.selectors.getPlatformUnitPotentialEfficiencies,
  structuralReporting.actions.loadPlatformUnitPotentialEfficiencies,
  { autoload: true },
)

export const usePotentialEfficiencyConfiguration = makePropLoaderHook(
  structuralReporting.selectors.getPotentialEfficiencyConfiguration,
  structuralReporting.actions.loadPotentialEfficiencyConfiguration,
  { autoload: true },
)


/* Performance Reporting */

export const useProviderPerformanceTotals = makePropLoaderHook(
  performanceReporting.selectors.getProviderPerformanceTotals,
  performanceReporting.actions.loadProviderPerformanceTotals,
  { autoload: true },
)

export const useProviderPerformanceTotalsByNetwork = makePropLoaderHook(
  performanceReporting.selectors.getProviderPerformanceTotalsByNetwork,
  performanceReporting.actions.loadProviderPerformanceTotalsByNetwork,
  { autoload: true },
)

export const useProviderPerformanceTotalsBySubNetwork = makePropLoaderHook(
  performanceReporting.selectors.getProviderPerformanceTotalsBySubNetwork,
  performanceReporting.actions.loadProviderPerformanceTotalsBySubNetwork,
  { autoload: true },
)

export const useProviderPerformanceTotalsByCampaignObjective = makePropLoaderHook(
  performanceReporting.selectors.getProviderPerformanceTotalsByCampaignObjective,
  performanceReporting.actions.loadProviderPerformanceTotalsByCampaignObjective,
  { autoload: true },
)

export const useProviderPerformanceComparisons = makePropLoaderHook(
  performanceReporting.selectors.getProviderPerformanceComparisons,
  performanceReporting.actions.loadProviderPerformanceComparisons,
  { autoload: true },
)

export const useDoubleVerifyProviderPerformanceComparisons = makePropLoaderHook(
  performanceReporting.selectors.getDoubleVerifyProviderPerformanceComparisons,
  performanceReporting.actions.loadDoubleVerifyProviderPerformanceComparisons,
  { autoload: true },
)

export const usePerformanceTimeseriesByProvider = makePropLoaderHook(
  performanceReporting.selectors.getPerformanceTimeseriesByProvider,
  performanceReporting.actions.loadPerformanceTimeseriesByProvider,
  { autoload: true },
)

export const useChannelPerformanceTimeseries = makePropLoaderHook(
  performanceReporting.selectors.getChannelPerformanceTimeseries,
  performanceReporting.actions.loadChannelPerformanceTimeseries,
  { autoload: true },
)

export const useProviderPerformanceTimeseries = makePropLoaderHook(
  performanceReporting.selectors.getProviderPerformanceTimeseries,
  performanceReporting.actions.loadProviderPerformanceTimeseries,
  { autoload: true },
)

export const useDoubleVerifyProviderPerformanceTimeseries = makePropLoaderHook(
  performanceReporting.selectors.getDoubleVerifyProviderPerformanceTimeseries,
  performanceReporting.actions.loadDoubleVerifyProviderPerformanceTimeseries,
  { autoload: true },
)

export const useAdNetworkPerformanceTimeseries = makePropLoaderHook(
  performanceReporting.selectors.getAdNetworkPerformanceTimeseries,
  performanceReporting.actions.loadAdNetworkPerformanceTimeseries,
  { autoload: true },
)

export const useProviderReferenceDates = makeLoaderHook(
  performanceReporting.selectors.getProviderReferenceDates,
  performanceReporting.actions.loadProviderReferenceDates,
  { autoload: true },
)

export const useEditProviderReferenceDates = makeMutationHook(
  performanceReporting.selectors.getEditProviderReferenceDates,
  performanceReporting.actions.setProviderReferenceDates,
  performanceReporting.actions.clearSetProviderReferenceDates,
)


/* JSON Schemas */

export const useReportSeriesConfigSchema = makePropLoaderHook(
  jsonSchemas.selectors.getReportSeriesConfigSchema,
  jsonSchemas.actions.loadReportSeriesConfigSchema,
  { autoload: true },
)
