
import React, { useMemo } from 'react'

import { dimensionIcons, SvgIconComponent } from '../../../icons'

import { intersection, parseDimension, flatten } from '@percept/utils'
import { groupBy, mapValues, sortBy, find } from 'lodash-es'

import { ParsedDimension, DimensionType } from '@percept/types'


const keyOrder = ['all', 'count', 'cost', 'impressions', 'views', 'clicks', 'conversions']


type DimensionOption = {
  dimension: DimensionType
  tail: string
  key: string
  value: string
  label: React.ReactNode
  IconComponent: SvgIconComponent
  active?: boolean
  disabled?: boolean
}


const parsedDimensionToOption = ({ raw, dimension, label, tail }: ParsedDimension): DimensionOption => ({
  dimension,
  tail,
  key: raw,
  value: raw,
  label,
  IconComponent: dimensionIcons[dimension],
})


export const parseDimensionOptions = (options: string[], tail: string | null): DimensionOption[] => {

  const parsed = options.map(parseDimension).filter( d => (
    d.dimension !== 'count' ?
      d.tail === (tail || '') :
      true
  ))

  const byDimension = groupBy(parsed, 'dimension')

  // Sort dimension options by tail length
  const sortedByTail = mapValues(
    byDimension,
    parsedDimension => sortBy(parsedDimension, d => d.length || 0)
  )

  // Sort parsed dimensions by default key order
  const sortedByDimension = flatten(
    ...intersection(
      keyOrder,
      Object.keys(byDimension)
    ).map( dimension => sortedByTail[dimension] )
  )

  return sortedByDimension.map(parsedDimensionToOption)
}


type UseDimensionOptionsProps = {
  value: string
  options: string[]
  activePerformanceTail: string | null
}

type UseDimensionOptionsHookValue = {
  activeDimension?: DimensionOption
  IconComponent?: SvgIconComponent
  options: DimensionOption[]
}

export const useDimensionOptions = (
  { value, options, activePerformanceTail }: UseDimensionOptionsProps
): UseDimensionOptionsHookValue => (
  useMemo(() => {

    const parsedOptions = parseDimensionOptions(options, activePerformanceTail)

    const displayOptions = parsedOptions.map( o => ({
      ...o,
      active: o.value === value,
      disabled: options.indexOf(o.value) === -1
    }))

    const activeDimension = find(displayOptions, o => o.active )

    return {
      activeDimension,
      options: displayOptions,
      IconComponent: activeDimension && activeDimension.IconComponent,
    }

  }, [value, options, activePerformanceTail])
)
