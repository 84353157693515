import {
  dimensionMap,
  performanceReportingDimensionOrder,
  rawPerformanceReportingDimensionMap,
  doubleVerifyDimensionsByProvider,
} from '@percept/constants'

import {
  AnyPerformanceDimension,
  DoubleVerifyPerformanceComparisons,
  DoubleVerifyPerformanceReportingDimension,
  DoubleVerifyPerformanceTimeseries,
  PerformanceComparisons,
  PerformanceRangeKey,
  PerformanceReportingDimension,
  PerformanceTimeseriesByProvider,
  PerformanceValue,
  PlatformUnit,
  PrimaryPerformanceDataProvider,
  RawPerformanceReportingDimension,
  ReportProvider,
} from '@percept/types'

import { isNumber, some, sortBy, xor } from 'lodash-es'

import { coerceReportProvider, isAveragingDimension, isConvertingDimension } from '@percept/utils'

import {
  PerformanceReportingDatum,
} from 'types'


type UseChannelDatasetsProps = {
  timeseriesByProvider: PerformanceTimeseriesByProvider | null
  doubleVerifyTimeseriesByProvider: Record<ReportProvider, DoubleVerifyPerformanceTimeseries | null> | null
  comparisonsByProvider: Record<ReportProvider, PerformanceComparisons | null> | null
  doubleVerifyComparisonsByProvider: Record<ReportProvider, DoubleVerifyPerformanceComparisons | null> | null
  platformUnit: PlatformUnit | null
  currency: string
  absoluteValues?: boolean
}


const rangeMap: Record<
  'week' | 'month' | 'year',
  'd7' | 'd28' | 'd365'
> = {
  week: 'd7',
  month: 'd28',
  year: 'd365',
}


export const resolvePerformanceReportingDimensions = ({
  platformUnit,
  provider,
  includeDoubleVerify = false,
}: {
  platformUnit: PlatformUnit
  provider?: PrimaryPerformanceDataProvider | null
  includeDoubleVerify?: boolean
}): AnyPerformanceDimension[] => {
  const { performance_reporting } = platformUnit

  const providerFilter = provider

  if( performance_reporting && performance_reporting.providers ){
    const disabledDimensions: Set<PerformanceReportingDimension> = new Set()

    let doubleVerifyDimensions: DoubleVerifyPerformanceReportingDimension[] = []

    const matchingProviders = performance_reporting.providers.filter( ({ provider }) => (
      providerFilter ? coerceReportProvider(provider.slug) === providerFilter : true
    ))

    matchingProviders.forEach( ({ field_config, augmentations, provider }) => {

      if( field_config ){
        field_config.forEach( ({ field_name, enabled }) => {
          if( !enabled && rawPerformanceReportingDimensionMap[field_name] ){
            disabledDimensions.add(rawPerformanceReportingDimensionMap[field_name])
          }
        })
      }

      if( augmentations && augmentations.doubleverify ){
        doubleVerifyDimensions = doubleVerifyDimensions.concat(
          doubleVerifyDimensionsByProvider[coerceReportProvider(provider.slug)]
        )
      }
    })

    const enabledPrimaryDimensions = xor(
      performanceReportingDimensionOrder,
      Array.from(disabledDimensions)
    )

    return [
      ...enabledPrimaryDimensions,
      ...(includeDoubleVerify && doubleVerifyDimensions || []),
    ]
  }

  return performanceReportingDimensionOrder
}


export const resolveDoubleVerifyPerformanceReportingDimensions = ({
  platformUnit,
  provider,
}: {
  platformUnit: PlatformUnit
  provider?: PrimaryPerformanceDataProvider | null
}): DoubleVerifyPerformanceReportingDimension[] | null => {
  const { performance_reporting } = platformUnit

  const providerFilter = provider

  if( performance_reporting && performance_reporting.providers ){

    let doubleVerifyDimensions: DoubleVerifyPerformanceReportingDimension[] = []

    const matchingProviders = performance_reporting.providers.filter( ({ provider }) => (
      providerFilter ? coerceReportProvider(provider.slug) === providerFilter : true
    ))

    matchingProviders.forEach( ({ augmentations, provider }) => {

      if( augmentations && augmentations.doubleverify ){
        doubleVerifyDimensions = doubleVerifyDimensions.concat(
          doubleVerifyDimensionsByProvider[coerceReportProvider(provider.slug)]
        )
      }
    })

    return doubleVerifyDimensions.length ? doubleVerifyDimensions : null
  }

  return null
}


export const platformUnitHasDoubleVerify = (platformUnit: PlatformUnit, provider?: PrimaryPerformanceDataProvider | null): boolean => {
  let providers = (
    platformUnit.performance_reporting
    && platformUnit.performance_reporting.providers
  )
  if( !providers ){
    return false
  }
  if( provider ){
    providers = providers.filter( p => coerceReportProvider(p.provider.slug) === provider )
  }
  return some(providers, p => Boolean(
    p && p.augmentations && p.augmentations.doubleverify
  ))
}


type AnyPerformanceDatum = (
  Partial<
    Record<
      RawPerformanceReportingDimension | AnyPerformanceDimension, PerformanceValue
    >
  >
)


const accessPerformanceValue = (
  datum: AnyPerformanceDatum,
  dimension: AnyPerformanceDimension
): PerformanceValue => {

  const coercedDimension = (
    isAveragingDimension(dimension) ? `avg_${dimension}` : dimension
  ) as PerformanceReportingDimension

  const valueKeys = (
    isConvertingDimension(coercedDimension) ?
      [`converted_${coercedDimension}`, coercedDimension] :
      [coercedDimension]
  ) as RawPerformanceReportingDimension[]

  for( const valueKey of valueKeys ){
    if( isNumber(datum[valueKey]) && isFinite(Number(datum[valueKey])) ){
      return Number(datum[valueKey])
    }
  }
  return null
}


type RelativePerformanceComparisonProps = {
  comparisons: PerformanceComparisons | DoubleVerifyPerformanceComparisons
  dimension: AnyPerformanceDimension
  absoluteValues?: boolean
  nullifyKeys?: PerformanceRangeKey[]
}


export const deriveRelativePerformanceComparisons = ({
  comparisons,
  dimension,
  absoluteValues = false,
  nullifyKeys = []
}: RelativePerformanceComparisonProps): PerformanceReportingDatum => {
  const keys: PerformanceRangeKey[] = [
    'now', 'week', 'month', 'year'
  ]

  const relative = keys.reduce( (acc, key) => {
    let value: PerformanceValue = null

    if( !nullifyKeys.includes(key) ){

      const targetComparison = (
        key === 'now' ?
          comparisons.d1 :
          comparisons[rangeMap[key]]
      )

      if( targetComparison ){
        // Comparisons are not guaranteed to be in chronological order
        const [historic, recent] = sortBy(targetComparison, 'period_end')
        if( recent ){
          const now = accessPerformanceValue(recent as AnyPerformanceDatum, dimension)
          if( key === 'now' || absoluteValues ){
            value = now
          }else if( historic ){
            const then = accessPerformanceValue(historic as AnyPerformanceDatum, dimension)
            if( now !== null && then !== null ){
              // Calculate percentage of recent against historic for relative percentage
              const deltaPercentage = (
                ((now - then) / then) * 100
              )
              if( isNumber(deltaPercentage) && isFinite(deltaPercentage) ){
                value = deltaPercentage
              }
            }
          }
        }
      }

    }

    acc[key] = value

    return acc

  }, {} as Record<PerformanceRangeKey, PerformanceValue>)

  return {
    key: dimension,
    label: dimensionMap[dimension].abbreviatedText || dimensionMap[dimension].text,
    ...relative,
  }
}
