import React, { Fragment, useEffect, useMemo, useState } from 'react'

import {
  BackdropLoader,
  BackdropProps,
  Box,
  Card,
  CardContent,
  CardStrip,
  Divider,
  makeAppStyles,
  ProviderLogo,
  RoundedPlainTextButton,
  Typography,
  useAppTheme,
} from '@percept/mui'

import { PageHeader } from '../PageHeader'

import { SingleUnitDimensionalSummary } from 'components/DimensionalSummary'

import { QualityAnalysis } from 'components/QualityAnalysis'

import { PerformanceControls } from 'components/PerformanceControls'

import { cellRenderers } from './channelTable'

import { useProviderPillarScores, usePlatformUnit } from '@percept/hooks'

import {
  useNestedDoubleVerifyProviderComparisons,
  useNestedDoubleVerifyProviderTimeseries,
  useNestedProviderComparisons,
  useNestedProviderTimeseries,
  usePerformanceReportingDimension,
  useReferenceDate,
  useTargetCurrency,
} from 'hooks'

import { isStructuralReportProvider, usePerformanceDatasets, usePlatformUnitDatasets } from './lib'

import { get, some } from 'lodash-es'

import { isLoading } from '@percept/utils'

import { format, isBefore } from 'date-fns'

import { PerformanceReportingDatum } from 'types'

import { PerformanceTable, PerformanceTableProps } from 'components/Tables'

import {
  ChannelKey,
  PerformanceReportingDimension,
  PlatformUnitLeaf,
  PlatformUnitProviderInfo,
  PrimaryPerformanceDataProvider,
} from '@percept/types'

import {
  platformUnitHasDoubleVerify,
  resolveDoubleVerifyPerformanceReportingDimensions,
  resolvePerformanceReportingDimensions,
} from 'components/Organisation/lib'

import { dimensionMap, providerDoubleVerifyMap } from '@percept/constants'
import { PotentialEfficiencies } from 'components/CrossChannelDashboard/PotentialEfficiencies'


const performanceTableProps: Partial<
  PerformanceTableProps<PerformanceReportingDatum & { dimension: PerformanceReportingDimension }>
> = {
  stickyHeader: true,
  renderers: cellRenderers,
}


export type SingleUnitChannelProps = {
  platformUnit: PlatformUnitLeaf
  channel: ChannelKey | null
  provider?: PrimaryPerformanceDataProvider | null
  referenceDate: Date | null
  providerInfo: PlatformUnitProviderInfo
}


const useStyles = makeAppStyles( theme => ({
  doubleVerifyTable: {
    marginTop: theme.spacing(2),
  },
  doubleVerifyTrigger: {
    marginLeft: theme.spacing(2),
    height: 22,
  },
  doubleVerifyHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 18,
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  doubleVerifyLogo: {
    marginLeft: theme.spacing(0.75),
    position: 'relative',
    top: 1.5,
  },
}) )



export const SingleUnitChannel = ({
  platformUnit,
  channel,
  provider = null,
  providerInfo,
  referenceDate,
}: SingleUnitChannelProps): JSX.Element => {

  const [currency] = useTargetCurrency()

  const [activeDimension, setActiveDimension] = usePerformanceReportingDimension()

  const [efficiencyActive, setEfficiencyActive] = useState(true)

  const [ , setActiveReferenceDate] = useReferenceDate()

  const referenceDateParam = referenceDate && format(referenceDate, 'yyyy-MM-dd')

  const org_unit_id = platformUnit.id

  const org_unit_ids = useMemo(() => {
    if( platformUnit && platformUnit.children && platformUnit.children.length ){
      return platformUnit.children.map( ({ id }) => id )
    }else if( platformUnit ){
      return [platformUnit.id]
    }
    return []
  }, [platformUnit])

  const [platformUnitDetail] = usePlatformUnit({
    org_unit_id,
  })

  const hasDoubleVerify = !!(
    platformUnitDetail.data && platformUnitHasDoubleVerify(platformUnitDetail.data)
  )

  const providerReferenceDate = provider && get(
    providerInfo, [provider, 'reference_date'], null
  )

  const doubleVerifyProvider = provider && providerDoubleVerifyMap[provider]

  const reportedDoubleVerifyReferenceDate = doubleVerifyProvider && get(
    providerInfo, [doubleVerifyProvider, 'reference_date'], null
  )

  const doubleVerifyReferenceDate = (
    reportedDoubleVerifyReferenceDate
    && referenceDate
    && (
      reportedDoubleVerifyReferenceDate.getTime() > referenceDate.getTime() ?
        referenceDate :
        reportedDoubleVerifyReferenceDate
    )
  )

  const requiresDoubleVerifyTrigger = !!(
    hasDoubleVerify &&
    doubleVerifyProvider &&
    referenceDate &&
    doubleVerifyReferenceDate &&
    isBefore(doubleVerifyReferenceDate, referenceDate)
  )

  const showDoubleVerify = !!(
    hasDoubleVerify &&
    doubleVerifyProvider &&
    !requiresDoubleVerifyTrigger
  )

  const {
    availableDimensions,
    primaryDimensions,
    doubleVerifyDimensions,
  } = useMemo(() => {
    if( platformUnitDetail.data ){
      const primaryDimensions = resolvePerformanceReportingDimensions({
        platformUnit: platformUnitDetail.data,
        provider,
      })
      const doubleVerifyDimensions = resolveDoubleVerifyPerformanceReportingDimensions({
        platformUnit: platformUnitDetail.data,
        provider,
      })
      const availableDimensions = [
        ...primaryDimensions,
        ...(showDoubleVerify && doubleVerifyDimensions || []),
      ]
      return {
        availableDimensions,
        primaryDimensions,
        doubleVerifyDimensions,
      }
    }
    return {
      availableDimensions: [],
      primaryDimensions: [],
      doubleVerifyDimensions: []
    }
  }, [provider, platformUnitDetail.data, showDoubleVerify])
  
  useEffect(() => {
    if( availableDimensions && !availableDimensions.includes(activeDimension) ){
      setActiveDimension(availableDimensions[0])
    }
  }, [availableDimensions, activeDimension, setActiveDimension])

  const timeseriesByUnit = useNestedProviderTimeseries({
    org_unit_ids,
    provider,
    chunking: 'MONTH',
    period: 'DAYS_365',
    target_currency: currency,
    ...(referenceDateParam && {
      reference_date: referenceDateParam
    })
  })

  const doubleVerifyTimeseriesByUnit = useNestedDoubleVerifyProviderTimeseries({
    org_unit_ids,
    provider,
    chunking: 'MONTH',
    period: 'DAYS_365',
    enabled: showDoubleVerify,
    ...(referenceDateParam && {
      reference_date: referenceDateParam
    })
  })

  const comparisonsByUnit = useNestedProviderComparisons({
    org_unit_ids,
    provider,
    target_currency: currency,
    ...(referenceDateParam && {
      reference_date: referenceDateParam
    })
  })

  const doubleVerifyComparisonsByUnit = useNestedDoubleVerifyProviderComparisons({
    org_unit_ids,
    provider,
    enabled: showDoubleVerify,
    ...(referenceDateParam && {
      reference_date: referenceDateParam
    })
  })

  const [pillarScores] = useProviderPillarScores({
    org_unit_id,
  })

  const appTheme = useAppTheme()

  const platformUnitDatasets = usePlatformUnitDatasets({
    platformUnit,
    timeseriesByUnit: timeseriesByUnit.data,
    doubleVerifyTimeseriesByUnit: doubleVerifyTimeseriesByUnit.data,
    comparisonsByUnit: comparisonsByUnit.data,
    doubleVerifyComparisonsByUnit: doubleVerifyComparisonsByUnit.data,
    currency,
    appTheme,
    channel,
  })

  const dimensionalDatasets = usePerformanceDatasets({
    datasets: platformUnitDatasets,
    appTheme,
    dimension: activeDimension,
    currency,
  })

  const showContent = Boolean(
    platformUnit &&
    !comparisonsByUnit.loading &&
    !timeseriesByUnit.loading && (
      showDoubleVerify ? (
        !doubleVerifyTimeseriesByUnit.loading &&
        !doubleVerifyComparisonsByUnit.loading
      ) : true
    )
  )

  const backdropProps: Partial<BackdropProps> = {
    open: (
      some([comparisonsByUnit, timeseriesByUnit, pillarScores], isLoading) || (
        showDoubleVerify && some([doubleVerifyComparisonsByUnit, doubleVerifyTimeseriesByUnit], isLoading)
      )
    )
  }

  const classes = useStyles()

  return (
    <Box
      my={4}
      mx={3}>

      { showContent && availableDimensions && availableDimensions.includes(activeDimension) && (
        <Fragment>

          <PageHeader />

          { provider && isStructuralReportProvider(provider) && (
            <>
              <Box mt={5} mb={3}>
                <Typography variant='h5'>
                  Quality Analysis
                </Typography>
              </Box>

              <QualityAnalysis
                platformUnit={platformUnit}
                provider={provider} />

              <Box my={3}>
                <Divider />
              </Box>
            </>
          )}

          <PerformanceControls
            platformUnit={platformUnit}
            availableDimensions={availableDimensions}
            referenceDate={referenceDate}
            maxDate={providerReferenceDate}
            potentialEfficiencyActive={efficiencyActive}
            onDimensionChange={(dimension): void => {
              setEfficiencyActive(dimension === 'potential_efficiency')
            }}
            doubleVerifyReferenceDate={doubleVerifyReferenceDate}
            requiresDoubleVerifyTrigger={requiresDoubleVerifyTrigger} />

          { efficiencyActive ? (
            <Box mt={4}>
              <PotentialEfficiencies
                suppressLoader={!showContent}
                org_unit_id={platformUnit.id} />
            </Box>
          ) : (
            <Fragment>
              <Box mt={4}>
                <SingleUnitDimensionalSummary
                  dimension={activeDimension}
                  referenceDate={referenceDate}
                  color={channel || 'primary'}
                  datasets={dimensionalDatasets}
                  currency={currency}
                  PerformanceTableProps={performanceTableProps} />
              </Box>

              <Box mt={5} mb={3}>
                <Typography variant='h5'>
                  Performance Analysis
                </Typography>
              </Box>

              <Card>
                {/* <CardStrip color={channel || 'secondary'} /> */}
                <CardContent>
                  <Box mt={1}>
                    <PerformanceTable
                      referenceDate={referenceDate}
                      rows={
                        primaryDimensions.filter( 
                          d => !!get(platformUnitDatasets[0].datasets, d),
                        ).map( d => ({
                          ...get(platformUnitDatasets[0].datasets, [d, 'relative'], {}),
                          dimension: d,
                          label: dimensionMap[d].text,
                          currency
                        }) ) as PerformanceTableProps<PerformanceReportingDatum>['rows']
                      } />
                  </Box>

                  { hasDoubleVerify && (
                    <Fragment>

                      <Typography
                        className={classes.doubleVerifyHeader}
                        variant='h5'>
                        Brand Safety by

                        <ProviderLogo
                          className={classes.doubleVerifyLogo}
                          provider='doubleverify'
                          size={1}
                          units='em' />

                        { requiresDoubleVerifyTrigger && doubleVerifyReferenceDate && (
                          <RoundedPlainTextButton
                            className={classes.doubleVerifyTrigger}
                            color='primary'
                            variant='contained'
                            size='small'
                            onClick={(): void => {
                              setActiveReferenceDate(doubleVerifyReferenceDate)
                            }}>
                            Available up to {format(doubleVerifyReferenceDate, 'dd/MM/yy')}
                          </RoundedPlainTextButton>
                        )}
                      </Typography>

                      { doubleVerifyDimensions && !requiresDoubleVerifyTrigger && (
                        <PerformanceTable
                          className={classes.doubleVerifyTable}
                          referenceDate={doubleVerifyReferenceDate}
                          stickyHeader
                          rows={
                            doubleVerifyDimensions.filter( 
                              d => !!get(platformUnitDatasets[0].datasets, d),
                            ).map( d => ({
                              ...get(platformUnitDatasets[0].datasets, [d, 'relative'], {}),
                              dimension: d,
                              label: dimensionMap[d].text,
                              currency
                            }) ) as PerformanceTableProps<PerformanceReportingDatum>['rows']
                          } />
                      )}

                    </Fragment>
                  )}
                </CardContent>
              </Card>
            </Fragment>
          )} 
        </Fragment>
      )}

      <BackdropLoader
        BackdropProps={backdropProps} />

    </Box>
  )
}
