import React, { Fragment, useMemo } from 'react'

import {
  Box,
  Card,
  CardContent,
  CardStrip,
  Loader,
  SimpleTable,
  Typography,
} from '@percept/mui'

import { DimensionalSummary } from 'components/DimensionalSummary'

import { PerformanceTableProps } from 'components/Tables'

import { DownloadTrigger } from './DownloadTrigger'

import { usePerformanceTotalsTableStyles } from '../styles'

import { useOrgUnitPotentialEfficiencyScores } from '@percept/hooks'

import { useTargetCurrency } from 'hooks'

import { flatten, maxBy, uniq, uniqBy } from 'lodash-es'

import { addDays, format, subYears} from 'date-fns'

import {
  cellRenderers,
  rangedValueCellRenderers,
} from './lib'

import { usePotentialEfficiencyDatasets, useRangedPotentialEfficiencyTableData } from './datasets'

import { PerformanceReportingDatum } from 'types'

import {
  PlatformUnitParams,
  PotentialEfficiencyDatum,
  ProviderPotentialEfficiencyScores,
  ReportProviderV2,
} from '@percept/types'



export const PotentialEfficiencies = ({
  org_unit_id,
  suppressLoader = false,
}: PlatformUnitParams & {
  suppressLoader?: boolean
}): JSX.Element => {

  const [target_currency] = useTargetCurrency()

  const today = new Date()
  const oneYearAgo = subYears(today, 1)

  const cacheTime = 1000 * 60 * 60 * 24

  const currentYearEfficiencies = useOrgUnitPotentialEfficiencyScores({
    org_unit_id,
    currency: target_currency,
    reference_date: format(today, 'yyyy-MM-dd'),
    start_date: format(addDays(oneYearAgo, 1), 'yyyy-MM-dd'),
    cacheTime,
  })

  const previousYearEfficiencies = useOrgUnitPotentialEfficiencyScores({
    org_unit_id,
    currency: target_currency,
    reference_date: format(oneYearAgo, 'yyyy-MM-dd'),
    start_date: format(subYears(oneYearAgo, 1), 'yyyy-MM-dd'),
    cacheTime,
  })

  const combinedEffiencies = useMemo(() => {
    if( !currentYearEfficiencies.data || !previousYearEfficiencies.data ){
      return null
    }
    const combinedResponses = [...previousYearEfficiencies.data, ...currentYearEfficiencies.data]
    const byProvider: Record<ReportProviderV2, ProviderPotentialEfficiencyScores> = combinedResponses.reduce( (acc, response) => {
      const provider = response.source_provider.slug
      if( acc[provider] ){
        acc[provider].potential_efficiency_scores = uniqBy(
          acc[provider].potential_efficiency_scores.concat(response.potential_efficiency_scores),
          'start'
        )
      }else{
        acc[provider] = response
      }
      return acc
    }, {} as Record<ReportProviderV2, ProviderPotentialEfficiencyScores>)
    return Object.values(byProvider)
  }, [currentYearEfficiencies.data, previousYearEfficiencies.data])

  const datasets = usePotentialEfficiencyDatasets({
    potentialEfficiencyScores: combinedEffiencies,
    currency: target_currency,
  })

  const { rows, columns } = useRangedPotentialEfficiencyTableData({ datasets })

  const {
    latestReport,
    referenceDate,
  } = useMemo(() => {
    let referenceDate: Date | null = null
    let latestReport: PotentialEfficiencyDatum | null = null
    if( combinedEffiencies ){
      const allData = flatten(
        combinedEffiencies.map( g => g.potential_efficiency_scores)
      )
      latestReport = maxBy(allData, d => d.end) || null
      if( latestReport ){
        referenceDate = new Date(latestReport.end)
        latestReport = {
          ...latestReport,
          start: new Date(latestReport.start).getTime(),
          end: new Date(latestReport.end).getTime(),
        }
      }
    }
    return {
      latestReport,
      referenceDate,
    }
  }, [combinedEffiencies])

  const tableClasses = usePerformanceTotalsTableStyles()

  if( !datasets || !combinedEffiencies ){
    if( suppressLoader ){
      return <Box height='12rem' />
    }else{
      return <Loader height='12rem' preset='centered' />
    }
  }

  // console.group('FY WASTAGE')
  // for( const dataset of datasets ){
  //   console.group(dataset.provider)
  //   for( const quarter of dataset.quarterlyDataset ){
  //     console.log('RANGE', new Date(quarter.start), new Date(quarter.end))
  //     console.log('TOTAL', round(quarter.value || 0, 2))
  //     console.log('%', Math.round((quarter.potential_efficiency_ratio || 0) * 100))
  //   }
  //   console.groupEnd()
  // }
  // console.groupEnd()

  const nowLabel = !latestReport ? undefined : (
    uniq([latestReport.start, latestReport.end]).map( d => format(d, 'dd/MM/yy')).join(' - ')
  )

  return (
    <Fragment>
      <DimensionalSummary
        dimension='cost'
        labelHistory={
          <Fragment>
            History
            <Box display='inline-flex' ml={2}>
              <DownloadTrigger
                org_unit_id={org_unit_id}
                currency={target_currency}
                datasets={datasets}
                color='secondary' />
            </Box>
          </Fragment>
        }
        labelChange='Comparison'
        labelSplit='Breakdown'
        referenceDate={referenceDate}
        axisChartMinDate={oneYearAgo}
        datasets={datasets}
        currency={target_currency}
        color='neutral'
        PerformanceTableProps={{
          nowLabel,
          showNowColumn: false,
          renderers: cellRenderers as PerformanceTableProps<PerformanceReportingDatum>['renderers'],
          showQuarterlyFigure: true,
        }} />

      <Box my={3} />

      <Box mb={3}>
        <Typography variant='h5'>
          Totals
        </Typography>
      </Box>

      <Card>
        {/* <CardStrip color='neutral' /> */}
        <CardContent>
          <SimpleTable
            classes={tableClasses}
            columns={columns}
            rows={rows}
            renderers={rangedValueCellRenderers}
            sortable />
        </CardContent>
      </Card>
    </Fragment>
  )
}
