import React from 'react'
import { Box, Card, Divider, Typography, makeAppStyles } from '@percept/mui'
import { ChevronRightThin } from '@percept/mui/icons'
import { StackedBarChart } from 'dashboard/src/components/ReportingDashboard/Charts'
import { ParentSize } from '@visx/responsive'
import { allMarkets} from './graphData'
import { round } from 'lodash-es'


const useStyles = makeAppStyles( theme => ({
  typography: {
    display: 'flex',
    alignItems: 'center',
  },
  separatorIcon: {
    margin: theme.spacing(0, 1),
    fontSize: '0.75em',
    strokeWidth: 2,
    color: 'rgba(0, 0, 0, 0.65)',
  }
}))


const roiFormatter = (value: string | number | null): string => (
  String(round(Number(value), 2))
)

export const MMMAllMarkets = (): JSX.Element => {

  const classes = useStyles()

  return (
    <Card elevation={2}>
      <Box mx={1.5} my={2}>
        <Typography className={classes.typography} variant='h4'>
          All Markets
          <ChevronRightThin className={classes.separatorIcon} />
          ROI
        </Typography>
      </Box>

      <Divider />

      <Box p={3}>
        <ParentSize>
          {({ width }): JSX.Element => (
            <StackedBarChart
              dataFormat='percentage'
              dataObject={allMarkets}
              valueFormatter={roiFormatter}
              width={width}
              yDomain={[0, 8]}
            />
          )}
        </ParentSize>
      </Box>
    </Card>
  )
}

