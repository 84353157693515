/* API Adapter
 * @versions: 1 | 1.1
 */

import { getPath } from '@percept/utils'


const auditPayload = ({
  from,
  until,
  audit,
  audit_summary
}) => ({
  start: from,
  end: until,
  performance: getPath(audit_summary, 'performance', {}),
  health: getPath(audit_summary, 'health', []),
  entities: getPath(audit, 'entities', {}),
  hasExamples: false,
  root_entity_type: getPath(audit, 'root_entity_type', null),
  root_entity_id: getPath(audit, 'root_entity_id', null),
})


export default auditPayload
