import React from 'react'

import { Box, BoxProps, Typography } from '@material-ui/core'

import { makeAppStyles } from '../../themes'

import { identity } from 'lodash-es'

import { ChartProps, DatumFormatters, SVGDatumType } from '../typings'


export type LegendProps<T extends SVGDatumType = SVGDatumType> = (
  Pick<
    ChartProps<T>, 'data' | 'onSegmentClick'
  > & Partial<
    Pick<
      DatumFormatters<T>,
      'labelFormatter'
    >
  > & {
    filterZero?: boolean
  } & BoxProps
)


export type LegendItemProps<T extends SVGDatumType = SVGDatumType> = (
  {
    datum: Omit<T, 'value'>
  } &
  Partial<
    Pick<
      DatumFormatters<T>,
      'labelFormatter'
    >
  > &
  React.HTMLAttributes<HTMLDivElement>
)


const useStyles = makeAppStyles( theme => ({
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.25),
    marginRight: theme.spacing(2),
  },
  label: {
    fontSize: 11,
  },
  dot: {
    marginRight: theme.spacing(1),
  },
  pointer: {
    cursor: 'pointer',
  },
}))



export function LegendItem<T extends SVGDatumType = SVGDatumType>({
  datum,
  labelFormatter = identity,
  className = '',
  ...props
}: LegendItemProps<T>): JSX.Element {

  const classes = useStyles()

  const { label, color, fill, fillOpacity = 1 } = datum

  const legendClassName = `${classes.legendItem} ${props.onClick ? classes.pointer : ''} ${className}`

  return (
    <div
      className={legendClassName}
      {...props}>

      <svg className={classes.dot} width={10} height={10} overflow='visible'>
        <circle
          r={5}
          cx={5}
          cy={5}
          fill={color || fill}
          fillOpacity={fillOpacity}
          stroke={color || fill}
          strokeWidth={2} />
      </svg>

      <Typography
        variant='subtitle1'
        className={classes.label}
        noWrap>
        { labelFormatter(label) }
      </Typography>
    </div>
  )

}


export function Legend<T extends SVGDatumType = SVGDatumType>({
  data,
  labelFormatter = identity,
  filterZero = true,
  onSegmentClick,
  ...props
}: LegendProps<T>): JSX.Element {

  return (
    <Box
      display='flex'
      flexWrap='wrap'
      alignItems='center'
      {...props}>

      { data.map( (datum) => {

        const { value } = datum

        if( filterZero && (value === 0 || value === null) ){
          return null
        }

        return (
          <LegendItem
            key={datum.label}
            datum={datum}
            labelFormatter={labelFormatter}
            onClick={
              onSegmentClick ? ((): void => {
                onSegmentClick(datum)
              }) : undefined
            } />
        )

      })}

    </Box>
  )

}
