import React, { useCallback, useEffect, useRef } from 'react'

import { Tooltip, TooltipData, TooltipProps } from '../Tooltip/Tooltip'

import { useTooltip, useTooltipInPortal } from '@visx/tooltip'

import ResizeObserverPolyfill from 'resize-observer-polyfill'

import { localPoint } from '@visx/event'

import { bisector } from 'd3-array'

import { find, identity } from 'lodash-es'

import { getDatasetTotal, getNumberValue } from '../lib'

import { ScaleLinear } from 'd3-scale'

import { UseTooltipInPortal, UseTooltipPortalOptions } from '@visx/tooltip/lib/hooks/useTooltipInPortal'

import { CombinedChartProps, DatumFormatters, ScaleRequirements, SVGDatumType } from '../typings'


export type QuantitativeTooltipHookProps<T extends SVGDatumType> = (
  Pick<
    CombinedChartProps<T>, 'data' | 'datasets' | 'refreshTooltipBoundsOnMount'
  > &
  ScaleRequirements &
  Pick<
    DatumFormatters<T>, 'labelFormatter' | 'datumLabelFormatter'
  > &
  Omit<
    UseTooltipPortalOptions, 'polyfill'
  > & {
    labels: number[]
  }
)


const tooltipStyles: React.CSSProperties = {
  margin: 0,
  padding: 0,
  background: 'none',
  pointerEvents: 'none',
  position: 'absolute',
  lineHeight: 1,
  zIndex: 1099,
}


export type TooltipEventHandler = (
  e: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>
) => void


export type QuantitativeTooltipHookValue<T extends SVGDatumType> = (
  {
    tooltipTop: number
    tooltipLeft: number
    tooltipData: TooltipData<T> | undefined
    hideTooltip: () => void
    handleTooltip: TooltipEventHandler
    TooltipComponent?: ((props: Omit<TooltipProps, 'datum'>) => JSX.Element) | null
  } &
  Pick<
    UseTooltipInPortal, 'TooltipInPortal' | 'containerRef'
  >
)


type DisplayDatum<T extends SVGDatumType> = (
  Omit<T, 'label'> & {
    label: string
    total: number
  }
)


export const useQuantitativeTooltip = <T extends SVGDatumType>({
  data,
  datasets,
  labels,
  xScale,
  yScale,
  scroll = true,
  detectBounds = true,
  refreshTooltipBoundsOnMount,
  labelFormatter,
  datumLabelFormatter,
  ...options
}: QuantitativeTooltipHookProps<T>): QuantitativeTooltipHookValue<T> => {

  const { containerRef, TooltipInPortal, forceRefreshBounds } = useTooltipInPortal({
    scroll,
    detectBounds,
    ...options,
    polyfill: ResizeObserverPolyfill,
  })

  const forceRefreshBoundsRef = useRef(forceRefreshBounds)

  /*
  NOTE - we need to do this as some animations on ancestor components have been observed to
  interfere with bounds detection, e.g slide transitions making the tooltip relative to where
  the containing element _started_, and not where it is _now_. This 1 second delay has been
  picked as a kind of magic number which is longer than most of our animations but not so long
  that user interaction is likely to start before this fires.
  Not an exact science, so we may need to tweak this.
  */
  useEffect(() => {
    if( refreshTooltipBoundsOnMount ){
      setTimeout(forceRefreshBoundsRef.current, 1000)
    }
  }, [refreshTooltipBoundsOnMount])

  const {
    tooltipTop = 0,
    tooltipLeft = 0,
    tooltipData,
    showTooltip,
    hideTooltip,
  } = useTooltip<TooltipData<T>>()

  const formatRef = useRef(labelFormatter)

  formatRef.current = labelFormatter

  const handleTooltip: TooltipEventHandler = useCallback( (e) => {

    const { x } = localPoint(e) || { x: 0 }

    const x0 = (xScale as ScaleLinear<number, number>).invert(x)

    const index = bisector<number, number>(identity).left(labels, x0, 1)

    const l0 = index === 0 ? 0 : labels[index - 1]
    const l1 = labels[index]

    let d = l0

    if( l1 ){
      d = x0.valueOf() - l0.valueOf() > l1.valueOf() - x0.valueOf() ? l1 : l0
    }

    const datum = data && find(data, dt => dt.label === d )

    const datumArray: DisplayDatum<T>[] | undefined = datasets && datasets.reduce( (acc, { data, label, color }) => {
      const match = find(data, dt => dt.label === d )
      if( match && match.value ){
        acc.push({
          ...match,
          label,
          color,
          total: getDatasetTotal(data),
        })
      }
      return acc
    }, [] as DisplayDatum<T>[])

    if( datum || datumArray && datumArray.length ){

      const tooltipData: TooltipData<T> = {
        label: formatRef.current(d),
        ...(datumArray && {
          data: datumArray,
          ...(datumLabelFormatter && {
            label: datumLabelFormatter(datumArray[0] as unknown as T)
          }),
        } || {}),
        ...(datum && data && {
          datum: {
            ...datum,
            label: formatRef.current(datum.label),
            total: getDatasetTotal(data),
          },
        } || {}),
      }

      const referenceDatum = datumArray && datumArray[0] || datum

      if( referenceDatum ){
        showTooltip({
          tooltipData,
          tooltipLeft: Number(xScale(d)),
          tooltipTop: Number(yScale(getNumberValue(referenceDatum))),
        })
      }
    }

  }, [data, datasets, showTooltip, xScale, yScale, labels, datumLabelFormatter])

  const TooltipComponent = tooltipData && (
    // eslint-disable-next-line react/display-name
    (props: Omit<TooltipProps, 'datum' | 'data' | 'tooltipData'>): JSX.Element => (
      <TooltipInPortal
        key={Math.random()}
        top={tooltipTop - 8}
        left={tooltipLeft + 8}
        style={tooltipStyles}>

        <Tooltip
          {...props}
          datumLabelFormatter={datumLabelFormatter}
          tooltipData={tooltipData} />

      </TooltipInPortal>
    )
  )

  return {
    handleTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop,
    tooltipLeft,
    TooltipInPortal,
    containerRef,
    TooltipComponent,
  }

}
