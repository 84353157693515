import { FormColumns } from 'enums/FormColumns'
import { OptionType } from '../Filters'
import { Campaign } from '../types'

type Option = {
  options: OptionType[] | undefined
  name: string
  label: string
  description: string
}

interface Props {
  column: Option
  selectOptions: Campaign
  optionsList: Option[]
  orgId: string
}

export const isDisabledColumn = (
  selectOptions: Campaign,
  priorityColumn: FormColumns,
  secondaryColumn: FormColumns,
  actualColumnName: string
): boolean =>
  actualColumnName === secondaryColumn &&
  selectOptions[priorityColumn].id === ''

export const filterOptions = ({
  column,
  selectOptions,
  optionsList,
  orgId,
}: Props): OptionType[] => {
  console.log('JFC', {
    column,
    selectOptions,
    optionsList,
  })
  const isSelectColumn = (
    priorityColumn: FormColumns,
    secondaryColumn: FormColumns
  ) =>
    column.name === secondaryColumn && selectOptions[priorityColumn].id !== ''

  const selectSubOptions = (
    priorityColumn: FormColumns,
    // subValue: 'sub_channels' | 'sub_campaign'
  ): OptionType[] => {
    return ((column.options || []) as OptionType[]).filter((e) => {
      const priorityColumnValue = optionsList.find(
        (option) => option.name === priorityColumn
      )
      const priorityColumnOptions =
        priorityColumnValue &&
        (priorityColumnValue.options as unknown as {
          value: string
          name: string
          children: {
            id: string
            name: string
          }[]
        }[] )
      const selectOption = priorityColumnOptions?.find(
        (option) => option.value === selectOptions[priorityColumn].id
      )

      const matches = (
        selectOption
          ?  selectOption.children.findIndex(
            (option) => option.id === e.value
          ) !== -1 
          : true
      )
      console.log('MATCH', matches)
      return matches

    })
  }

  return column.name === FormColumns.secondBrand
    ? column.options || []
    : isSelectColumn(FormColumns.competitor, FormColumns.competitveSecondBrand)
      ? (
        column.options as {
          value: string
          name: string
          competitor: {
            id: string
            name: string
          }
        }[]
      ).filter(
        (e) => e.competitor.id === selectOptions[FormColumns.competitor].id
      )
      : isSelectColumn(FormColumns.campaignPillar, FormColumns.campaignSubPillar)
        ? selectSubOptions(FormColumns.campaignPillar)
        : isSelectColumn(FormColumns.mediaChannel, FormColumns.mediaSubChannel)
          ? selectSubOptions(FormColumns.mediaChannel)
          : (column.options || [] as {
        value: string
        name: string
      }[])
}
