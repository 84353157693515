import React, { Fragment, useContext } from 'react'

import { makeAppStyles } from '@percept/mui'
import { BarChart } from '@percept/mui/icons'

import { DefaultMediaWizardLayout } from './DefaultMediaWizardLayout'
import { Carousel, CarouselItem } from 'components/Carousel'
import { NewsHeader, NewsLink, WizardNewsSummary } from 'components/News'
import { LinkSection, LinkSections } from 'components/Links'

import { useDocumentTitle } from '@percept/hooks'

import { UserPrivilegeContext } from '@percept/app-components'

import { useBrandShareCarouselItem, useSmartCampaignAssessmentCarouselItem, useSOSMarketShareCarouselItem } from 'carousel-hooks'

import { get } from 'lodash-es'

// import carouselWhite1 from '../images/carousel-backgrounds/carousel-white-1.jpg'
import carouselGrey1 from '../images/carousel-backgrounds/carousel-grey-1.jpg'
import carouselBlack2 from '../images/carousel-backgrounds/carousel-black-2.jpg'

// import infoGraphicTrendline from '../images/infographics/trendline.svg'
import infoGraphic60Percent from '../images/infographics/60-percent-light-bg.svg'
// import infoGraphicNewCustomers from '../images/infographics/new-customers-light-bg.png'

import infoGraphicSmartCampaignAssessment from '../images/infographics/smart-campaign-assessment.jpg'

import infoGraphicWastageTrendReports from '../images/infographics/macbook-wtr.png'

// import infoGraphicMediaInvestment from '../images/infographics/media-investment-2-light-bg.svg'
// import infoGraphicKPIs from '../images/infographics/kpis-2-light-bg.png'
// import infoGraphicSpeechmark from '../images/logos/living-speechmark-1.png'
import {
  // AnimationKPIs,
  AnimationMediaInvestment,
} from 'animations'


const useTrendlineInfoGraphicContentStyles = makeAppStyles( theme => ({
  infoGraphicContent: {
    position: 'absolute',
    bottom: '42%',
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 124,
    animation: '$zoomInXL 0.75s linear',
    transformBox: 'fill-box',
    transformOrigin: 'center',
    [theme.breakpoints.down('lg')]: {
      animation: '$zoomInLG 0.75s linear',
      fontSize: 96,
    },
    [theme.breakpoints.down('md')]: {
      animation: '$zoomInMD 0.75s linear',
      fontSize: 92,
    },
    [theme.breakpoints.down('sm')]: {
      animation: '$zoomInSM 0.75s linear',
      fontSize: 70,
    },
    [theme.breakpoints.down('xs')]: {
      animation: '$zoomInXS 0.75s linear',
      fontSize: 50,
    },
  },
  '@keyframes zoomInXL': {
    from: { fontSize: 24 },
    to: { fontSize: 124 },
  },
  '@keyframes zoomInLG': {
    from: { fontSize: 24 },
    to: { fontSize: 96 },
  },
  '@keyframes zoomInMD': {
    from: { fontSize: 24 },
    to: { fontSize: 92 },
  },
  '@keyframes zoomInSM': {
    from: { fontSize: 24 },
    to: { fontSize: 70 },
  },
  '@keyframes zoomInXS': {
    from: { fontSize: 24 },
    to: { fontSize: 50 },
  },
}))

const TrendlineInfoGraphicContent = ({ children }: React.PropsWithChildren<{}>): JSX.Element => {
  const classes = useTrendlineInfoGraphicContentStyles()
  return (
    <div className={classes.infoGraphicContent}>{ children }</div>
  )
}

const useFallbackInfoGraphicContentStyles = makeAppStyles( theme => ({
  infoGraphicContent: {
    color: theme.palette.primary.main,
    fontSize: 400,
    animation: '$zoomInXL 0.75s linear',
    transformBox: 'fill-box',
    transformOrigin: 'center',
    [theme.breakpoints.down('lg')]: {
      animation: '$zoomInLG 0.75s linear',
      fontSize: 300,
    },
    [theme.breakpoints.down('md')]: {
      animation: '$zoomInMD 0.75s linear',
      fontSize: 250,
    },
    [theme.breakpoints.down('sm')]: {
      animation: '$zoomInSM 0.75s linear',
      fontSize: 200,
    },
    [theme.breakpoints.down('xs')]: {
      animation: '$zoomInXS 0.75s linear',
      fontSize: 150,
    },
  },
  '@keyframes zoomInXL': {
    from: { fontSize: 24 },
    to: { fontSize: 400 },
  },
  '@keyframes zoomInLG': {
    from: { fontSize: 24 },
    to: { fontSize: 300 },
  },
  '@keyframes zoomInMD': {
    from: { fontSize: 24 },
    to: { fontSize: 250 },
  },
  '@keyframes zoomInSM': {
    from: { fontSize: 24 },
    to: { fontSize: 200 },
  },
  '@keyframes zoomInXS': {
    from: { fontSize: 24 },
    to: { fontSize: 150 },
  },
}))

const FallbackInfoGraphicContent = (): JSX.Element => {
  const classes = useFallbackInfoGraphicContentStyles()
  return (
    <div className={classes.infoGraphicContent}><BarChart fontSize='inherit' /></div>
  )
}


const fallbackReportingCarouselItem: CarouselItem = {
  title: 'Reporting made easy - explore apps here!',
  description: '',
  textColor: 'black',
  backgroundImageSrc: carouselGrey1,
  infoGraphicContent: <FallbackInfoGraphicContent />
}


export const Reporting = (): JSX.Element => {

  useDocumentTitle({ paths: ['Reporting'] })

  const privileges = useContext(UserPrivilegeContext)
  const default_org_unit_id = get(privileges.user, 'default_org_unit_id', null)

  const sosCarouselItem = useSOSMarketShareCarouselItem({
    textColor: 'black',
    backgroundImageSrc: carouselGrey1,
  })

  const brandShareCarouselItem = useBrandShareCarouselItem({
    textColor: 'white',
    backgroundImageSrc: carouselBlack2,
  })

  const scaCarouselItem = useSmartCampaignAssessmentCarouselItem({
    textColor: 'black',
    backgroundImageSrc: carouselGrey1,
  })

  const carouselItems: CarouselItem[] = []
  if( sosCarouselItem ){
    carouselItems.push(sosCarouselItem)
  }
  if( brandShareCarouselItem ){
    carouselItems.push(brandShareCarouselItem)
  }
  if( scaCarouselItem ){
    carouselItems.push(scaCarouselItem)
  }

  // Add fallback carousel in case of API error / permissions issues
  if( !carouselItems.length ){
    carouselItems.push(fallbackReportingCarouselItem)
  }

  // const carouselItems: CarouselItem[] = [
  //   {
  //     title: '€110m global media investment in Q1 FY23/24',
  //     textColor: 'white',
  //     backgroundImageSrc: carouselBlack2,
  //     infoGraphicSrc: infoGraphicTrendline,
  //     infoGraphicContent: (
  //       <TrendlineInfoGraphicContent>
  //         €110m
  //       </TrendlineInfoGraphicContent>
  //     )
  //   },
  //   {
  //     title: 'Brand Share increased by 6pts YoY, now reaching 57%',
  //     description: (
  //       <Fragment>
  //         We need to increase our brand investment to reach our goal of 60%. Improving our metrics and
  //         the long-term health of the brand.
  //       </Fragment>
  //     ),
  //     textColor: 'black',
  //     backgroundImageSrc: carouselGrey1,
  //     infoGraphicSrc: infoGraphic60Percent,
  //   },
  //   {
  //     title: 'Media Strategy for growth',
  //     description: (
  //       <Fragment>
  //         Media has a significant impact in attracting new customers, generating between 5% to 27% new
  //         customers, by market.
  //       </Fragment>
  //     ),
  //     textColor: 'black',
  //     backgroundImageSrc: carouselWhite1,
  //     infoGraphicSrc: infoGraphicNewCustomers,
  //   },
  // ]

  const linkSections: LinkSection[] = [
    {
      title: 'Media Investment Platform',
      description: (
        <Fragment>
          Make informed decisions with access to the full suite of Vodafone media investment data.
          Compare past and present spend, to anticipate trends.
        </Fragment>
      ),
      globalPrivileges: ['mediaInvestment.viewAny'],
      // imageSrc: infoGraphicMediaInvestment,
      imageContent: <AnimationMediaInvestment />,
      path: default_org_unit_id ? (
        `/reporting/${default_org_unit_id}`
      ) : undefined,
      openInNewWindow: true,
    },
    {
      title: 'Smart Campaign Assessment',
      description: (
        <>
          Access the Smart Campaign Assessment results for both Vodafone and Competitors. The following section
          allows you to see, access and compare campaigns according to their performance on both ad imprint and triggers.
        </>
      ),
      globalPrivileges: ['vfSmartCampaignAssessment.view'],
      imageSrc: infoGraphicSmartCampaignAssessment,
      path: '/wizard/reporting/smart-campaign-assessment',
      openInNewWindow: true,
    },
    {
      title: 'Wastage Trend Reports',
      description: (
        <>
          Identify and monitor your media wastage trends with this tool. See where your investment is being inefficiently used
          in digital media and creative, within our main ad buying platforms.
        </>
      ),
      imageSrc: infoGraphicWastageTrendReports,
      path: '/wizard/reporting/wastage-trend-reports',
      globalPrivileges: ['vfWastageTrendReports.viewAny'],
      openInNewWindow: true,
    }
    // {
    //   title: 'KPIs to drive media effectiveness',
    //   description: (
    //     <Fragment>
    //       Use the Vodafone Media Effectiveness Framework to drive greater effectiveness with your media budgets,
    //       through a mix of long and short term measures.
    //     </Fragment>
    //   ),
    //   // imageSrc: infoGraphicKPIs,
    //   imageContent: <AnimationKPIs />,
    // },
    // {
    //   title: 'Brand Consistency',
    //   description: (
    //     <Fragment>
    //       Our Brand Identity is one of our most important assets, check your brand id consistency scores
    //     </Fragment>
    //   ),
    //   imageSrc: infoGraphicSpeechmark,
    // }
  ]

  return (
    <DefaultMediaWizardLayout
      breadcrumbs={[
        {
          path: '/wizard/reporting',
          name: 'Reporting',
        },
      ]}>

      <Carousel items={carouselItems} />

      <LinkSections sections={linkSections} />

      <NewsHeader />
      
      <WizardNewsSummary />

      <NewsLink />

    </DefaultMediaWizardLayout>
  )
}
