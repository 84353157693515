
import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'

import { ReduxBundle } from '../lib'

import { MetricMetadataState } from './typings'


type MetricMetadataActions = typeof actions
type MetricMetadataSelectors = typeof selectors


export type MetricMetadataBundle = ReduxBundle<MetricMetadataState, MetricMetadataActions, MetricMetadataSelectors>


export { actions, reducer, selectors }


export const makeBundle = (): MetricMetadataBundle => ({
  actions,
  reducer,
  selectors
})

export default { actions, reducer, selectors } as MetricMetadataBundle
