
import {
  Next,
  Action,
  StoreState,
} from '@percept/types'


export default () => (next: Next) => (action: Action): StoreState => {
  
  try{
  
    return next(action)
  
  }catch(e){
  
    return next({
      type: 'REDUX_ERROR',
      error: true,
      payload: e,
    })
  
  }
}
