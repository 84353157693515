import React from 'react'

import { Box, BoxProps } from '@percept/mui'


export const NewsHeader = (props: BoxProps): JSX.Element => {
  return (
    <Box
      fontSize={44}
      lineHeight='48px'
      fontWeight={700}
      px={3}
      mt={8}
      mb={5}
      display='flex'
      alignItems='center'
      justifyContent='center'
      {...props}>
      News
    </Box>
  )
}
