
import { createKeyedReducer } from '@percept/redux/reducers'

import {
  getTimeSeriesKeyFromAction,
  getFetchTypes } from '@percept/redux/utils'

import { apiInitialState } from '@percept/redux/constants'

import adapters, { singleMetric } from '@percept/api/adapters'

import {
  applySegments,
  getMetricMetadataById } from '../../../../lib'

import { LOAD_SERIES_METRIC } from '../../actions'

import { LOAD_METRIC_METADATA } from '../../../metricMetadata/actions'

import {
  getPath,
  mapValues,
} from '@percept/utils'

import {
  ApiResponse,
  MetricMetadataType,
  DimensionType,
  MetricTypeEnum,
  MetricDataType,
  MetricType,
  ReduxAction,
  SeriesMetric,
  Dictionary,
  MetricMetadataEntryType,
} from '@percept/types'


const seriesMetricFetchTypes = getFetchTypes(LOAD_SERIES_METRIC)

const metricMetadataFetchTypes = getFetchTypes(LOAD_METRIC_METADATA)


interface ResponseMetric extends MetricType {
  id: string,
  dimension: DimensionType,
  metric_type: MetricTypeEnum,
  data: MetricDataType | null
}


const mapAudits = (
  audits: Dictionary[],
  metricMetadata: MetricMetadataType,
  { metric_id, dimension }: { metric_id?: string, dimension?: DimensionType } = {}
): SeriesMetric[] => {

  return adapters.metricSeries(audits).map( a => {

    const responseMetric: Partial<ResponseMetric> = a.metric || {}
    
    dimension = dimension || responseMetric.dimension || 'count'
    metric_id = metric_id || responseMetric.id || ''

    const metric_type: MetricTypeEnum | null = getPath(
      metricMetadata,
      [metric_id, 'metric_type']
    ) || (
      responseMetric.metric_type
    ) || (
      null
    )

    const metric = metric_type ? singleMetric({
      ...a.metric,
      metric_type
    }) : responseMetric

    return {
      ...a,
      metric: metric_type && metricMetadata ? {
        ...metric,
        dimension,
        id: metric_id,
        data: applySegments({
          data: metric.data || null,
          metadata: getMetricMetadataById(metricMetadata, metric_id || '')
        })
      } : {
        ...metric,
        metric_type,
      }
    } as SeriesMetric
  })

}


const defaultSeriesMetricReducer = (
  state: ApiResponse<SeriesMetric[]> = apiInitialState,
  action: ReduxAction,
  metricMetadata: MetricMetadataType,
): ApiResponse<SeriesMetric[]> => {
  
  const { metric_id, dimension } = action.meta || {}

  switch(action.type){

    case seriesMetricFetchTypes.start:
      return { ...state, loading: true }
    
    case seriesMetricFetchTypes.error:
      return { ...state, loading: false, error: action.payload }
    
    case seriesMetricFetchTypes.success:
      return {
        loading: false,
        error: null,
        data: mapAudits(action.payload || [], metricMetadata, { metric_id, dimension })
      }

    default:
      return state
  }

}


const defaultKeyedReducer = createKeyedReducer(
  defaultSeriesMetricReducer,
  getTimeSeriesKeyFromAction
)


export default (
  state: Dictionary<ApiResponse<SeriesMetric[]>> = {},
  action: ReduxAction,
  metricMetadata: MetricMetadataType,
): Dictionary<ApiResponse<SeriesMetric[]>> => {

  if( action.type === metricMetadataFetchTypes.success && Object.keys(state).length ){

    const metadata = (action.payload || []).reduce( (acc: MetricMetadataType, m: MetricMetadataEntryType) => {
      acc[m.metric_id] = m
      return acc
    }, {}) as MetricMetadataType

    // NOTE: These have been mapped already.
    // We need to reach in and adapt the metric data directly

    return mapValues(
      state,
      seriesMetric => ({
        ...seriesMetric,
        ...(
          seriesMetric.data && {
            data: seriesMetric.data.map( ({ metric, id, ...rest }) => {

              const adaptedMetric = singleMetric(metric)

              return {
                id,
                metric: {
                  ...adaptedMetric,
                  data: applySegments({
                    data: adaptedMetric.data || null,
                    metadata: getMetricMetadataById(metadata, id)
                  })
                },
                ...rest,
              }

            })
          } || {}
        )
      })
    )

  }

  return defaultKeyedReducer(state, action, metricMetadata)

}
