import { QuadrantDatum, QuadrantReportProps } from './QuadrantCharts'

import { SmartCampaignFiltersResponse, SmartCampaignQuadrantResponse } from './hooks'

import { some } from 'lodash-es'

import { startOfMonth } from 'date-fns'

import { QuadrantType } from '@percept/types'

import { FlagName } from 'components/BrandGrowthQuadrantChart'


export const parseTimeRange = (dates: (Date | null)[]): { start: Date | null, end: Date | null } => {
  const [start, end ] = dates
  if( start && end ){
    return { start, end, }
  }
  return { start: null, end: null }
}

export const isNullTimeRange = (dates: (Date | null)[]): boolean => (
  some(dates, d => d === null)
)


export const parseFilterDateRange = (response: SmartCampaignFiltersResponse | undefined): { start: Date | null, end: Date | null } => {
  if( response && response.reference_start_date && response.reference_end_date ){
    return {
      start: startOfMonth(new Date(response.reference_start_date)),
      end: startOfMonth(new Date(response.reference_end_date)),
    }
  }
  return { start: null, end: null }
}


const quadrantReportToDataset = (
  response: SmartCampaignQuadrantResponse
): Record<QuadrantType, QuadrantDatum> => (
  response.rows.reduce( (acc, row) => {
    acc[row.quadrant_position] = {
      value: Math.round(Number(row.campaign_percentage.replace('%', ''))),
      isoCodes: row.markets.map( m => m.market_iso_code ) as FlagName[],
    }
    return acc
  }, {} as Record<QuadrantType, QuadrantDatum>)
)


export const quadrantReportsToChartData = ({
  baseline,
  comparison,
}: {
  baseline: SmartCampaignQuadrantResponse | null
  comparison: SmartCampaignQuadrantResponse | null
}): Pick<QuadrantReportProps, 'primaryQuadrantDataset' | 'secondaryQuadrantDataset'> => {
  return {
    primaryQuadrantDataset: baseline && quadrantReportToDataset(baseline) || null,
    secondaryQuadrantDataset: comparison && quadrantReportToDataset(comparison) || null,
  }
}
