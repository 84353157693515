import React from 'react'

import { animated } from 'react-spring'

import { Box, IconButton } from '@material-ui/core'

import { Alert } from '../Alert'

import { Close } from '../../icons'

import { LifeIndicatorProps, useLifeIndicatorSpring, useLifeIndicatorStyles } from './lib'

import { Toast as ToastType } from '@percept/types'


export type ToastProps = (
  Omit<ToastType, 'onClose'> &
  Pick<LifeIndicatorProps, 'id'> &
  Partial<Pick<LifeIndicatorProps, 'ttl'>> & {
    onClose: (id: string | number) => void
  }
)


export const Toast = ({
  id,
  type,
  ttl,
  message,
  onClose,
  ...props
}: ToastProps): JSX.Element => {

  const animatedProps = useLifeIndicatorSpring({
    id,
    ttl,
    onRest: onClose
  })

  const classes = useLifeIndicatorStyles()

  return (
    <Alert
      variant={type}
      position='relative'
      width='28rem'
      pt={2}
      pl={2}
      pr={4}
      pb={ttl ? 3 : 2}
      fontSize={14}
      lineHeight={1.3}
      boxShadow={10}
      {...props}>

      <IconButton
        className={classes.closeIcon}
        color='inherit'
        onClick={(): void => {
          if( id ){
            onClose(id)
          }
        }}>
        <Close />
      </IconButton>

      <Box
        mt={1}>
        { message }
      </Box>

      { !!ttl && (
        <animated.div
          className={classes.indicator}
          style={animatedProps} />
      )}

    </Alert>
  )
}
