
import { pipe } from '@percept/utils'

// import migrateFilters from './migrateFilters'
import migrateTips from './migrateTips'


export const migrate = pipe(
  // migrateFilters,
  migrateTips,
)
