
import React from 'react'

import { Alert, Box, Typography } from '@percept/mui'

import * as Buttons from './Buttons'
import * as Inputs from './Inputs'

// import OnboardError from './OnboardError'

import { PasswordRequirements } from './PasswordRequirements'

import getMessage from './getMessage'

import { isValidPassword } from './utils'

import { AuthComponentProps } from './lib'

import { AppBranding } from '@percept/types'


const { BRANDING } = process.env


const appBrandingWelcomeMessageMap: Record<AppBranding, string> = {
  PERCEPT: 'Welcome to Percept',
  TPA: 'Welcome to TPA Audit',
  BRANDTECH: 'Welcome to The Brandtech Group',
  VODAFONE: 'Welcome to the Vodafone Media Wizard',
}


const Onboard = ({
  inputs,
  updateInputs,
  setInitialPassword,
  initialToken,
  loading,
  error,
  classes,
  ButtonComponent,
  AuthInputProps = {},
}: AuthComponentProps): JSX.Element => {

  const isValid = isValidPassword(inputs.password) && (
    initialToken ?
      inputs.password !== initialToken :
      true
  )

  return (
    <form
      onSubmit={(e): void => {
        e.preventDefault()
        if( isValid ){
          setInitialPassword()
        }
      }}>

      <Typography
        className={classes.heading}
        variant='h3'>
        { appBrandingWelcomeMessageMap[BRANDING || 'PERCEPT'] }
      </Typography>

      <Box my={3}>
        <Typography>
          Please choose a new password to continue
        </Typography>
      </Box>

      { error && (
        <Alert my={3} variant='error' message={getMessage(error)} />
      ) }

      <PasswordRequirements
        password={inputs.password || ''}
        temporaryPassword={initialToken}
        my={3} />

      { inputs.username && (
        <Inputs.Username
          {...AuthInputProps}
          InputProps={{
            ...(AuthInputProps.InputProps || {}),
            readOnly: true
          }}
          value={inputs.username} />
      ) || null }

      <Inputs.Password
        BoxProps={{
          mb: 5,
        }}
        {...AuthInputProps}
        autoFocus
        placeholder='New Password'
        value={inputs.password || ''}
        onChange={(e): void => updateInputs({ password: e.target.value })} />

      <Buttons.Confirm
        ButtonComponent={ButtonComponent}
        loading={loading}
        disabled={loading || !isValid || !!error}
        type='submit'>
        Go
      </Buttons.Confirm>

    </form>
  )
}

export default Onboard
