import { useContext } from 'react'

import { UserPrivilegeContext } from '@percept/app-components'

import { VodafoneMarket, isVodafoneGlobalUser, vodafoneMarkets } from 'vodafoneMarkets'


export const useOverviewUserMarketOptions = (): VodafoneMarket[] => {
  const { user } = useContext(UserPrivilegeContext)
  if( isVodafoneGlobalUser(user.default_org_unit_id) ){
    return vodafoneMarkets
  }
  return vodafoneMarkets.filter( market => market.id === user.default_org_unit_id )
}
