import { useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { channels } from 'components/CrossChannelDashboard/lib'

import { apiInitialState, getInsightsKey } from '@percept/redux'

import { structuralReporting } from '@percept/redux/bundles'

import { apiResponseOf, coerceReportProvider, isErrorResponse, isLoading, shouldAttemptLoad } from '@percept/utils'

import { every, some } from 'lodash-es'

import { usePlatformUnit } from '@percept/hooks'

import { generateTimeSeries, randomNumberLike } from '@percept/lorem'

import { channelDisplayMap } from '@percept/constants'

import { ApiResponse, ChannelKey, Insight, Nullable, PlatformUnitParams, ReportProvider, SeriesParams } from '@percept/types'

import { AppTheme } from '@percept/mui'

import { ChartData } from '@percept/mui/charts'

import { DiagnosticsTableRow } from './DiagnosticsTable'

import { StoreState } from 'types'


type CrossProviderInsights = Partial<Record<ReportProvider, Insight[]>>

type InsightsHookValue = ApiResponse<CrossProviderInsights>


export const useInsights = ({ org_unit_id }: Nullable<PlatformUnitParams>): InsightsHookValue => {

  const [detail] = usePlatformUnit({ org_unit_id })

  const insightsResponses = useSelector<StoreState, Record<string, ApiResponse<Insight[]>>>( state => (
    state.structuralReporting.insights
  ))

  const dispatch = useDispatch()

  return useMemo(() => {
    if( !org_unit_id ){
      return apiInitialState
    }

    if( detail.data && org_unit_id ){
      const { report_series } = detail.data
      const seriesWithInsights = (report_series || []).filter( s => (
        !!s.output_config.recommendations_report
      ))
      if( seriesWithInsights.length ){

        const insightResponseParams: [
          SeriesParams & PlatformUnitParams,
          ApiResponse<Insight[]>,
          ReportProvider
        ][] = seriesWithInsights.map( ({ id, provider }) => {
          const params = {
            org_unit_id,
            series_id: id,
          }
          const key = getInsightsKey(params) || ''
          return [params, insightsResponses[key] || apiInitialState, coerceReportProvider(provider.slug)]
        })

        insightResponseParams.forEach( ([params, response]) => {
          if( shouldAttemptLoad(response) ){
            dispatch(
              structuralReporting.actions.loadReportSeriesInsights(params)
            )
          }
        })

        const insightResponses = insightResponseParams.map(([, r]) => r )

        if( some(insightResponses, isLoading) ){
          return apiInitialState
        }

        if( every(insightResponses, isErrorResponse) ){
          console.info('No insights available for this platform unit')
          return {
            loading: false,
            lastFetched: Date.now(),
            data: null,
            error: {
              status: 404,
              message: 'Insights could not be loaded'
            }
          }
        }

        const crossProviderInsights = insightResponseParams.reduce( (acc, [, response, provider]) => {
          if( response.data ){
            acc[provider] = response.data
          }
          return acc
        }, {} as CrossProviderInsights)

        return apiResponseOf(crossProviderInsights)
      }
    }

    return apiInitialState
  }, [org_unit_id, detail.data, insightsResponses, dispatch])
}


type ChannelCPADataset = {
  channel: ChannelKey
  data: ChartData
}

export const sampleChannelCPADatasets: ChannelCPADataset[] = channels.map(
  (channel) => ({
    channel,
    data: generateTimeSeries({
      start: '2020-01-01',
      end: '2020-12-31',
      granularity: 'MONTHLY',
      seed: 50,
    }),
  })
)

export const useChannelCPADatasets = ({
  datasets,
  appTheme,
}: {
  datasets: ChannelCPADataset[]
  appTheme: AppTheme
}): (ChannelCPADataset & { label: string; key: string; color: string })[] => {
  return useMemo(() => {
    return datasets.map(({ channel, data }) => ({
      key: channel,
      label: channelDisplayMap[channel].label,
      channel,
      data,
      color: appTheme.palette.channel[channel].main,
    }))
  }, [datasets, appTheme])
}

export const sampleChannelPerformanceRows: DiagnosticsTableRow[] = channels.map(
  (channel) => ({
    label: channel,
    channel,
    conversions: randomNumberLike(100, true),
    cost: randomNumberLike(10000),
    cpa: randomNumberLike(50, true),
    currency: 'GBP',
  })
)

export const sampleRecommendations = [
  { key: 'search', text: 'Reduce Search budget by 50%' },
  { key: 'social', text: 'Increase Social budget by 100%' },
]
