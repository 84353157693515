
import { SET_ACTIVE_SERIES_GROUP_SERIES } from '../../actions'

import { Reducer, SeriesGroupWrapper } from '@percept/types'


const activeSeries: Reducer<SeriesGroupWrapper['activeSeries']> = (state = null, action) => {
  if( action.type === SET_ACTIVE_SERIES_GROUP_SERIES ){
    return action.payload.series_id
  }
  return state
}

export default activeSeries
