import { useMemo } from 'react'

import { SimpleTableProps, useAppTheme } from '@percept/mui'

import { percentageFormatter } from '@percept/mui/charts'

import {
  EfficiencyReportingDatum,
  generateComparisons,
  generateRangedValues,
  getRangedWeekLabel,
  EfficiencyTimeseriesDatum,
  ProviderIdent,
  RangedPotentialEffiencyRow,
  RangedValue,
  rangedWeekKeyMap,
  rangedWeekValues,
  // generateQuarterlyFinancialYearValues,
} from './lib'

import { find, pick, round, sortBy, uniqBy } from 'lodash-es'

import { CSVData, coerceReportProvider, formatAuditDates } from '@percept/utils'

import { providerChannelMap, providerLabelMap, reportProviders } from '@percept/constants'

import { PerformanceDimensionDataset } from 'types'

import {
  AnyPerformanceDimension,
  DateRange,
  ProviderPotentialEfficiencyScores,
  ReportProvider,
} from '@percept/types'


export type PotentialEfficiencyDataset = (
  ProviderIdent & {
    key: string
    label: string
    dataset: PerformanceDimensionDataset & ProviderIdent & {
      rangedValues: RangedValue[]
      relative: EfficiencyReportingDatum & ProviderIdent & {
        dimension: AnyPerformanceDimension
      }
      timeseries: EfficiencyTimeseriesDatum[]
    }
    // quarterlyDataset: EfficiencyTimeseriesDatum[]
    color: string
  }
)

export const usePotentialEfficiencyDatasets = ({
  potentialEfficiencyScores,
  currency,
}: {
  potentialEfficiencyScores: ProviderPotentialEfficiencyScores[] | null
  currency: string | null
}): PotentialEfficiencyDataset[] | null => {
  const appTheme = useAppTheme()
  return useMemo(() => {
    if( !potentialEfficiencyScores ){
      return null
    }
    const derivedDatasets = potentialEfficiencyScores.reduce( (acc, grouping) => {
      const provider = coerceReportProvider(grouping.source_provider.slug as ReportProvider)
      const channel = providerChannelMap[provider]
      const timeseries: EfficiencyTimeseriesDatum[] = grouping.potential_efficiency_scores.map( score => {
        const start = new Date(score.start).getTime()
        const end = new Date(score.end).getTime()
        return {
          ...score,
          start,
          end,
          label: end,
          value: score.transformed_cost,
        }
      })
      acc.push({
        key: provider,
        label: providerLabelMap[provider],
        provider,
        channel,
        color: appTheme.palette.channel[channel].main,
        // quarterlyDataset: generateQuarterlyFinancialYearValues(timeseries),
        dataset: {
          key: provider,
          label: providerLabelMap[provider],
          provider,
          channel,
          currency: currency,
          timeseries,
          rangedValues: generateRangedValues(timeseries),
          relative: {
            key: provider,
            label: providerLabelMap[provider],
            dimension: 'cost',
            provider,
            channel,
            ...generateComparisons(timeseries),
          }
        }
      })
      return acc
    }, [] as PotentialEfficiencyDataset[])

    return sortBy(derivedDatasets, d => reportProviders.indexOf(coerceReportProvider(d.provider)))
  }, [potentialEfficiencyScores, appTheme, currency])

}


export const useRangedPotentialEfficiencyTableData = ({
  datasets
}: {
  datasets: PotentialEfficiencyDataset[] | null
}): SimpleTableProps<RangedPotentialEffiencyRow> => {
  return useMemo(() => {
    return {
      rows: (datasets || []).map( d => {
        const rangedValues = d.dataset.rangedValues.reduce( (acc, { datum, weeks }) => {
          const key = weeks === 0 ? 'fyToDate' : rangedWeekKeyMap[weeks]
          acc[key] = datum
          return acc
        }, {} as Record<string, EfficiencyTimeseriesDatum | null>)
        return {
          provider: d.provider,
          channel: d.channel,
          currency: d.dataset.currency,
          ...rangedValues,
        }
      }) as RangedPotentialEffiencyRow[],
      columns: [
        { key: 'provider', label: '', align: 'left' },
        ...rangedWeekValues.map( value => ({
          key: rangedWeekKeyMap[value],
          label: getRangedWeekLabel(value),
          align: 'right',
        })),
        { key: 'fyToDate', label: 'Financial Year', align: 'right' },
      ] as SimpleTableProps<RangedPotentialEffiencyRow>['columns'],
    }
  }, [datasets])
}


export const generatePotentialEfficiencyCSV = ({
  datasets,
  dimension,
}: {
  datasets: PotentialEfficiencyDataset[]
  dimension: 'cost' | 'percentage'
}): CSVData => {

  const allDateRanges = datasets.reduce( (acc, dataset) => {
    for( const d of dataset.dataset.timeseries ){
      acc.push(pick(d, 'start', 'end'))
    }
    return acc
  }, [] as DateRange[])

  const canonicalDateRanges = sortBy(
    uniqBy(allDateRanges, r => `${r.start}:${r.end}` ),
    'start'
  )

  const dateColumns = canonicalDateRanges.map( d => formatAuditDates(d).join(' - '))

  return {
    columns: ['Provider', ...dateColumns],
    rows: datasets.map( d => {
      const provider = providerLabelMap[d.provider]
      const values = canonicalDateRanges.map( r => {
        const match = find(d.dataset.timeseries, t => (
          t.start === r.start && t.end === r.end
        ))
        if( match ){
          return (
            dimension === 'cost' ?
              round(Number(match.value), 2).toFixed(2) :
              percentageFormatter((match.potential_efficiency_ratio || 0) * 100)
          )
        }
        return '-'
      })
      return [
        provider,
        ...values
      ]
    })
  }
}
