import { format } from 'date-fns'
import { BGPTab, tabSlugMap } from './tabs'


export const formatDate = (date: string | number | Date): string => (
  format(new Date(date), 'dd/MM/yy')
)

export const isVodafoneGlobalOrgUnit = (org_unit_id: string): boolean => (
  org_unit_id === '10143060198421'
)


export const bgpTopLevelRoute = '/wizard/strategy/brand-growth-planner'

export const getBGPRoute = (tab: BGPTab): string => (
  `${bgpTopLevelRoute}?tab=${tabSlugMap[tab]}`
)
