import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'

import { ReduxBundle } from '../lib'

import { ThemingState } from './typings'

type ThemingActions = typeof actions
type ThemingSelectors = typeof selectors


export type ThemingBundle = ReduxBundle<ThemingState, ThemingActions, ThemingSelectors>

    
export { actions, reducer, selectors }


export const makeBundle = (): ThemingBundle => ({
  actions,
  reducer,
  selectors,
})

export default { actions, reducer, selectors } as ThemingBundle
