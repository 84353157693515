
import { Selector } from 'react-redux'

import { ThemingState } from './typings'

import {
  AppTheme,
  defaultAppTheme,
  getAvailableTypedThemes,
  getCurrentThemeTypePreference,
} from '@percept/mui'


type StoreWithTheming = {
  theming: ThemingState
}

type ThemingSelector<TProps, TOwnProps = null> = Selector<StoreWithTheming, TProps, TOwnProps>


export const getAppThemeTypePreference: ThemingSelector<ThemingState['themePreference']> = state => (
  state.theming.themePreference
)

export const getAppThemeBranding: ThemingSelector<ThemingState['themeBranding']> = state => (
  state.theming.themeBranding
)

export const getActiveAppTheme: ThemingSelector<AppTheme> = state => {
  const themeTypePreference = getAppThemeTypePreference(state)
  const newThemeType = (
    themeTypePreference === 'AUTO' ?
      getCurrentThemeTypePreference() :
      themeTypePreference
  )
  const appThemeBranding = getAppThemeBranding(state)
  const availableTypedThemes = getAvailableTypedThemes(appThemeBranding)
  if( availableTypedThemes && availableTypedThemes[newThemeType] ){
    return availableTypedThemes[newThemeType]
  }
  return defaultAppTheme
}
