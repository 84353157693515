
import React from 'react'

import { animated, useSpring } from 'react-spring'

import { interpolatePath, PathCommandObject } from 'd3-interpolate-path'

import { usePrevious } from '@percept/hooks'

import { SizeRequirements } from './typings'


export type AnimatedPathProps = (
  {
    d: string
    initialPath?: string
    animate?: boolean
    animateInitial?: boolean
    excludeSegment?: (a: PathCommandObject, b: PathCommandObject) => boolean
  } &
  SizeRequirements &
  Partial<
    React.SVGAttributes<SVGPathElement>
  >
)


type PathTransitionProps = {
  t: number
  fill: string | undefined
  stroke: string | undefined
  strokeOpacity: number
  fillOpacity: number
}


export const AnimatedPath = ({
  d,
  animate = true,
  animateInitial,
  fill,
  stroke,
  width,
  height,
  excludeSegment,
  fillOpacity = 1,
  strokeOpacity = 1,
  initialPath,
  ...props }: AnimatedPathProps): JSX.Element => {

  const previousD = usePrevious(d)

  const animateFrom = (
    d === previousD && animateInitial && initialPath ?
      initialPath :
      previousD
  )

  const reset = animateInitial ? d !== previousD : true

  const interpolator = interpolatePath(animateFrom, d, excludeSegment)

  const animatedProps = useSpring<PathTransitionProps>({
    t: 1,
    fill,
    stroke,
    fillOpacity: (d || reset) ? fillOpacity : 0,
    strokeOpacity: (d || reset) ? strokeOpacity : 0,
    immediate: !animate,
    reset,
    from: {
      t: 0,
      fill,
      stroke,
      fillOpacity,
      strokeOpacity,
    }
  } as (React.CSSProperties & PathTransitionProps))

  return (
    <animated.path
      d={animatedProps.t.interpolate(interpolator)}
      fill={animatedProps.fill}
      fillOpacity={animatedProps.fillOpacity}
      stroke={animatedProps.stroke}
      strokeOpacity={animatedProps.strokeOpacity}
      {...props} />
  )
}
