import React, { useMemo } from 'react'

import { SimpleTable, ProviderLogo } from '@percept/mui'

import { WastageProvider } from '@percept/hooks'

import { MarketDisplayLabel } from 'components/MarketDisplay'

import {
  deriveComparisons,
  providerOrder,
  vodafoneMarketsById,
  wastageSegmentationTickFormatters,
} from '../dataUtils'

import {
  WastageTrendTableProps,
  comparisonLoadingRenderers,
  getColumns,
  getWastageTrendRows,
  renderers,
} from './tableUtils'

import { get } from 'lodash-es'


export const WastageTrendTable = ({
  variant,
  segmentation,
  comparisonType,
  comparisonMethod = 'RELATIVE',
  isLoadingComparisons = false,
  supportsComparison,
  spendDatasets,
  spendComparisonDatasets,
  wastagePercentageDataset,
  wastagePercentageComparisonDataset,
  creativeQualityScoreDataset,
  creativeQualityScoreComparisonDataset,
  totalCreativeWastageResponse,
  totalCreativeWastageComparisonResponse,
}: WastageTrendTableProps): JSX.Element => {

  const columns = getColumns({
    wastageVariant: variant,
    wastageSegmentation: segmentation,
    comparisonType: supportsComparison ? comparisonType : null,
    comparisonMethod,
  })

  const comparisons = useMemo(() => {
    return comparisonType && deriveComparisons({
      segmentation,
      comparisonMethod,
      comparisonType,
      datasets: {
        efficientSpend: {
          earliest: get(
            spendComparisonDatasets.find( d => d.key === 'efficientSpend' ),
            'data',
            null,
          ),
          latest: get(
            spendDatasets.find( d => d.key === 'efficientSpend' ),
            'data',
            null,
          )
        },
        wastedSpend: {
          earliest: get(
            spendComparisonDatasets.find( d => d.key === 'wastedSpend' ),
            'data',
            null,
          ),
          latest: get(
            spendDatasets.find( d => d.key === 'wastedSpend' ),
            'data',
            null,
          )
        },
        wastagePercentage: {
          earliest: wastagePercentageComparisonDataset,
          latest: wastagePercentageDataset, 
        },
        creativeQualityScore: {
          earliest: creativeQualityScoreComparisonDataset,
          latest: creativeQualityScoreDataset,
        }
      }
    })
  }, [
    segmentation,
    comparisonType,
    comparisonMethod,
    spendDatasets,
    spendComparisonDatasets,
    wastagePercentageDataset,
    wastagePercentageComparisonDataset,
    creativeQualityScoreDataset,
    creativeQualityScoreComparisonDataset,
  ])

  const { rows, totalRow } = getWastageTrendRows({
    spendDatasets,
    wastagePercentageDataset,
    creativeQualityScoreDataset,
    comparisons,
    totalCreativeWastageResponse,
    totalCreativeWastageComparisonResponse,
  })

  const cellRenderers = (
    supportsComparison && isLoadingComparisons && comparisonType ?
      comparisonLoadingRenderers :
      renderers
  )

  return (
    <SimpleTable
      columns={columns}
      rows={rows}
      footerRows={totalRow && [totalRow]}
      renderers={{
        ...cellRenderers,
        label: ({ label }): JSX.Element => {
          if( label === 'Total' ){
            return <>{label}</>
          }
          if( providerOrder.includes(label as unknown as WastageProvider) ){
            return (
              <ProviderLogo
                provider={label as unknown as WastageProvider}
                size={1.35}
                style={{position: 'relative', top: 4}} />
            )
          }
          if( segmentation === 'ORG_UNIT' ){
            const market = vodafoneMarketsById[label]
            if( market ){
              return <MarketDisplayLabel {...market} />
            }
            return <>{label}</>
          }
          const formatter = wastageSegmentationTickFormatters[segmentation]
          return (
            <>{formatter(label, 0, [])}</>
          )
        }
      }} />
  )
}
