export enum Filters  {
  Agency = 'agency',
  Department = 'department',
  MarketsGroup ='groups',
  Markets = 'markets',
  FundingSource = 'funding_source',
  Campaign = 'campaign-pillar',
  SubCampaign = 'sub-campaign-pillar',
  SecondBrand = 'second-brand',
  MediaChannel = 'media-channel',
  MediaSubChannel = 'media-sub-channel',
  Message = 'message',
  Product = 'product',
  CalendarYears = 'calendar-year',
  FinancialYear = 'financial-year',
  BuyType = 'buy-type',
  Partner = 'partner',
  JBP = 'jbp',
  
  // COMPETITIVE
  Competitor = 'competitor',
  ActualCompetitor = 'actual-competitor',
  StartMonth = 'start-month',
  EndMonth = 'end-month',

  // LEGACY
  AgencyDiscount = 'agency-discount',
  CompetitiveSecondBrand = 'competitive-second-brand',
  Source = 'source',
  SpotLength = 'spot-lenth',
  TimeLag = 'time-lag',
  TradedAudience = 'traded-audience',
}