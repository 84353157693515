
import React, { useState, useMemo, useEffect } from 'react'

import {
  Input,
  InputAdornment,
  Box,
  Card,
  CardProps,
  Divider,
} from '@material-ui/core'

import { makeAppStyles } from '../../themes'

import { TreeView } from '../TreeView'

import { RoundedPlainTextButton } from '../Buttons'

import { Search, Close, Folder } from '../../icons'

import { EntityListItem, EntityListItemProps } from './EntityListItem'

import { SortControl } from './SortControl'

import { getPath } from '@percept/utils'

import { TreeEntityListItem } from './typings'

import { ReportEntities, ReportEntityFilter } from '@percept/types'

import {  
  dimensions,
  groupOptions,
  getMatchingEntitiesByType,
  getParentEntityType,
  isTopLevelEntityType,
} from './lib'

import {
  SortConfig,
} from './typings'

import { dimensionMap } from '@percept/constants'


const { WORKLOAD_TYPE, APP } = process.env

const DEBUG = (WORKLOAD_TYPE !== 'PROD' || APP === 'admin')


export type EntityListProps = {
  entities: ReportEntities
  activeEntity: ReportEntityFilter
  onEntityClick: (entityFilter: ReportEntityFilter) => void
  sortEntities: (sortConfig: Partial<SortConfig>) => void
  sortBy: SortConfig
  filterNoData?: boolean
} & CardProps


const useStyles = makeAppStyles( theme => ({
  parentTreeNode: {
    padding: 0,
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  entityListItem: {
    padding: theme.spacing(0.5, 1.5, 0.5, 0.75),
    fontWeight: theme.typography.fontWeightBold,
  },
  activeEntityListItem: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFF',
  },
  groupButton: {
    display: 'inline-flex',
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5, 1.5),
  },
}) )


export const EntityList = ({
  entities,
  activeEntity,
  sortEntities,
  onEntityClick,
  sortBy,
  filterNoData = !DEBUG,
  ...props
}: EntityListProps): JSX.Element | null => {

  const [query, setQuery] = useState('')

  const [entityListType, setEntityListType] = useState(getParentEntityType(activeEntity.type))

  const activeId = String(activeEntity.id)

  const { key, order } = sortBy

  const entitiesByType = useMemo(() => {
    return getMatchingEntitiesByType({
      entities,
      matchingQuery: query.toLowerCase(),
      activeId,
      sortKey: key,
      sortOrder: order,
      filterNoData,
    })
  }, [query, entities, activeId, key, order, filterNoData])

  const entityListOptions = groupOptions.filter( option => (
    getPath(entitiesByType, [option.value, 'length'], 0) > 0
  ))

  // Add an 'Accounts' option to house the single account within the filter,
  // in this case no account groups will be present but campaign groups will be
  // as this filter is only exposed for multi-entity payloads
  if( !entitiesByType.AccountGroup && entitiesByType.Account ){
    entityListOptions.unshift({
      key: 'Account',
      value: 'Account',
      label: 'Accounts',
    })
  }

  useEffect(() => {
    if( entityListType === 'AccountGroup' && !entitiesByType.AccountGroup ){
      setEntityListType('Account')
    }
  }, [entitiesByType, entityListType])

  const classes = useStyles()

  return (
    <Card {...props}>

      <Box
        display='flex'
        ml='auto'
        mt={2}
        mb={2}>

        { entityListOptions.map( ({ value, label }) => {
          const selected = entityListType === value
          return (
            <RoundedPlainTextButton
              key={value}
              className={classes.groupButton}
              size='small'
              color={selected ? 'secondary' : 'default'}
              variant={selected ? 'contained' : 'text'}
              startIcon={<Folder />}
              onClick={(): void => {
                setEntityListType(value)
              }}>
              { label }
            </RoundedPlainTextButton>
          )
        })}

        <Box
          display='flex'
          ml={5}
          mr={1.5}>

          <Input
            autoFocus
            placeholder='Search...'
            value={query}
            color='secondary'
            startAdornment={
              <InputAdornment position='start'>
                { query ?
                  <Close
                    onClick={(): void => setQuery('')} /> :
                  <Search />
                }
              </InputAdornment>
            }
            onChange={(e): void => setQuery(e.target.value)} />

        </Box>

      </Box>

      <Divider />
  
      <Box
        display='flex'
        flexGrow={1}
        fontWeight={700}
        textAlign='right'
        mr={1.5}>
        
        <SortControl
          sortKey='name'
          label='Name'
          sortConfig={sortBy}
          onSort={sortEntities}
          display='flex'
          flexGrow={1}
          justifyContent='flex-end'
          mr={2}
          ml={1} />

        <SortControl
          sortKey='health'
          label='Health'
          sortConfig={sortBy}
          onSort={sortEntities}
          width='6rem' />

        { dimensions.map( dimension => (
          <SortControl
            key={dimension}
            sortConfig={sortBy}
            sortKey={dimension as SortConfig['key']}
            label={
              dimensionMap[dimension].abbreviatedText
              || dimensionMap[dimension].text
            }
            width='6rem'
            mr={0.25}
            onSort={sortEntities} />
        ))}

      </Box>

      <Divider />

      <TreeView<TreeEntityListItem>
        defaultDepth={query ? -1 : 0}
        hierarchy={entitiesByType[entityListType]}
        activeIds={
          [activeId]
        }
        nodeMargin='1rem'
        NodeComponent={(props: EntityListItemProps): JSX.Element => (
          <EntityListItem
            {...props}
            className={
              `${classes.entityListItem} ${props.active ? classes.activeEntityListItem : ''}`
            } />
        )}
        parentClassName={classes.parentTreeNode}
        onNodeClick={(node: TreeEntityListItem): void => {
          if( isTopLevelEntityType(node.type) && node.id ){
            onEntityClick(node)
          }
        }} />

    </Card>
    
  )
}

export default EntityList
