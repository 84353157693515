
import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'

import { ReduxBundle } from '../lib'

import { ClientReportState } from './typings'


type ClientReportActions = typeof actions
type ClientReportSelectors = typeof selectors


export type ClientReportBundle = ReduxBundle<ClientReportState, ClientReportActions, ClientReportSelectors>


export { actions, reducer, selectors }


export const makeBundle = (): ClientReportBundle => ({
  actions,
  reducer,
  selectors
})

export default { actions, reducer, selectors } as ClientReportBundle
