
import { createAuditEntityReducer } from '@percept/redux/reducers'

import {
  LOAD_AUDIT,
  LOAD_AUDIT_ENTITY } from '../actions'

import { LOAD_METRIC_METADATA } from '../../metricMetadata/actions' 


export default createAuditEntityReducer({
  loadAuditType: LOAD_AUDIT,
  loadEntityType: LOAD_AUDIT_ENTITY,
  loadMetricMetadataType: LOAD_METRIC_METADATA,
})
