import 'react-hot-loader'

import React from 'react'
import { render } from 'react-dom'

import { Provider } from 'react-redux'
import createStore from 'dashboard/src/redux/createStore'

import { createBrowserHistory } from 'history'

import App from 'components/App'

/* Polyfills */

import 'whatwg-fetch'

/* Manual polyfill imports
 * The babel config will only inject polyfills based on browser targets determined
 * by code it sees. This is impossible with some pre-built libraries which are either
 * deps or deps of deps, and so to ensure coverage we need to specify some manual polyfills.
 */
import 'core-js/features/number/is-finite'
import 'core-js/features/array/find'
import 'core-js/features/object/entries'
import 'core-js/features/string/starts-with'
import 'core-js/features/object/get-prototype-of'

/* Load Global CSS to enable sticky footer */
import './css/main.css'

/* Load Vodafone fonts */
import './css/font.css'


const { WORKLOAD_TYPE, NODE_ENV } = process.env

/* Store and middleware dependencies */

const history = createBrowserHistory()

import api from '@percept/api'

import persistence from 'dashboard/src/persistence'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

/* Update detection */

const checkForUpdates = NODE_ENV !== 'development'

const updateInterval = NODE_ENV !== 'development' ? 1000 * 60 * 2 : null

/* Debug flag */

const debug = WORKLOAD_TYPE !== 'PROD'

/* Build */

const store = createStore({ history, api, persistence })
export const queryClient = new QueryClient()

render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <App
        debug={debug}
        history={history}
        checkForUpdates={checkForUpdates}
        updateInterval={updateInterval}
      />
    </Provider>

    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,

  document.getElementById('app')
)
