import { ParentSize } from '@visx/responsive'
import React, { useLayoutEffect, useRef } from 'react'

import * as d3 from 'd3'

import { circleAnimationDefElements } from './lib'

import { AnimationSurfaceProps } from './typings'


type Point = {
  x: number
  y: number
}

const makeCircle = (
  surface: d3.Selection<SVGSVGElement | SVGGElement, unknown, null, undefined>,
  coordinates: Point,
  radius: number,
  attrs: Record<string, string | number>
): d3.Selection<SVGCircleElement, unknown, null, undefined> => {
  const el = surface.append('circle')
    .attr('cx', coordinates.x)
    .attr('cy', coordinates.y)
    .attr('r', radius)
  Object.keys(attrs).forEach( attr => {
    el.attr(attr, attrs[attr])
  })
  return el
}


const AnimationSurface = ({
  width,
  height
}: AnimationSurfaceProps): JSX.Element => {
  const svgRef = useRef<SVGSVGElement | null>(null)

  useLayoutEffect(() => {
    const rootEl = svgRef.current
    if( !rootEl ){
      console.warn('No root element for animation')
      return
    }

    const MID_X = width / 2
    const MID_Y = height / 2

    const canvasSize = Math.min(width, height)
    const halfCanvasSize = canvasSize / 2
    const thirdCanvasSize = canvasSize / 3

    const circleSize = Math.min(width / 4.5, height / 4.5)
    const fontSize = circleSize / 6
    const bevelWidth = 10

    const innerCircleMargin = 4
    
    const startRadius = 0
    const endRadius = circleSize / 2

    const circleDuration = 1000
    const lineDuration = 500

    const midpointMiddle = {
      x: MID_X,
      y: MID_Y,
    }

    const midpointTopLeft = {
      x: MID_X - (thirdCanvasSize),
      y: MID_Y - (thirdCanvasSize)
    }
    const midpointTopRight = {
      x: MID_X + (thirdCanvasSize),
      y: MID_Y - (thirdCanvasSize)
    }
    const midpointLeft = {
      x: MID_X - (halfCanvasSize),
      y: MID_Y,
    }
    const midpointRight = {
      x: MID_X + (halfCanvasSize),
      y: MID_Y,
    }
    const midpointBottomLeft = {
      x: MID_X - (thirdCanvasSize),
      y: MID_Y + (thirdCanvasSize)
    }
    const midpointBottomRight = {
      x: MID_X + (thirdCanvasSize),
      y: MID_Y + (thirdCanvasSize)
    }

    const svg = d3.select(rootEl)
    // Clear surfaces
    svg.selectAll('g').remove()
    const background = svg.append('g')
    const surface = svg.append('g')

    // Begin Media Wizard Circle
    const homeCircleWrap = surface.append('g')
      .attr('filter', 'url(#innerShadow)')
    const subWrap = homeCircleWrap.append('g')
      .attr('filter', 'url(#dropShadow)')

    const homeCircle = makeCircle(
      subWrap,
      midpointMiddle,
      startRadius,
      { fill:  'url(#radialGradientRed)' }
    )
    const homeCircleBevel = makeCircle(
      subWrap,
      midpointMiddle,
      startRadius,
      {
        fill: 'none',
        stroke: '#DCDCDC',
        'stroke-width': 0,
        filter:  'url(#innerShadowPath)',
      }
    )
    const whiteCircle = makeCircle(
      subWrap,
      midpointMiddle,
      startRadius - innerCircleMargin,
      {
        fill:  'none',
        stroke: 'white',
        'stroke-width': 1,
      }
    )

    whiteCircle.transition()
      .duration(circleDuration)
      .attr('r', endRadius - innerCircleMargin)
    homeCircleBevel.transition()
      .duration(circleDuration)
      .attr('r', endRadius + (bevelWidth / 2))
      .attr('stroke-width', bevelWidth)

    homeCircle.transition()
      .duration(circleDuration)
      .attr('r', endRadius)
      .on('end', () => {
        const homeFontSize = fontSize * 1
        surface.append('text')
          .attr('x', midpointMiddle.x)
          .attr('y', midpointMiddle.y - (fontSize / 1))
          .text('Media')
          .attr('fill', 'white')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'central')
          .attr('font-size', homeFontSize)
          .attr('font-weight', 700)
        
        surface.append('text')
          .attr('x', midpointMiddle.x)
          .attr('y', midpointMiddle.y)
          .text('Investment')
          .attr('fill', 'white')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'central')
          .attr('font-size', homeFontSize)
          .attr('font-weight', 700)

        surface.append('text')
          .attr('x', midpointMiddle.x)
          .attr('y', midpointMiddle.y + (fontSize / 1))
          .text('Platform')
          .attr('fill', 'white')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'central')
          .attr('font-size', homeFontSize)
          .attr('font-weight', 700)
        
        const line = background.append('line')
          .attr('stroke', '#DCDCDC')
          .attr('stroke-width', bevelWidth)
          .attr('stroke-linecap', 'round')
          .attr('filter', 'url(#innerShadowPath)')
          .attr('x1', midpointMiddle.x)
          .attr('y1', midpointMiddle.y)
          .attr('x2', midpointMiddle.x)
          .attr('y2', midpointMiddle.y)

        line.transition()
          .duration(lineDuration)
          .attr('x2', midpointTopRight.x)
          .attr('y2', midpointTopRight.y)
          .on('end', () => {
            // Start 'Strategy'
            // Begin Strategy Circle
            const strategyCircleWrap = surface.append('g')
              .attr('filter', 'url(#innerShadow)')
            const subWrapStrategy = strategyCircleWrap.append('g')
              .attr('filter', 'url(#dropShadow)')

            const strategyCircle = makeCircle(
              subWrapStrategy,
              midpointTopRight,
              startRadius,
              { fill:  'url(#radialGradientWhite)' }
            )
            const strategyCircleBevel = makeCircle(
              subWrapStrategy,
              midpointTopRight,
              startRadius,
              {
                fill: 'none',
                stroke: '#DCDCDC',
                'stroke-width': 0,
                filter:  'url(#innerShadowPath)',
              }
            )

            strategyCircleBevel.transition()
              .duration(circleDuration)
              .attr('r', endRadius + (bevelWidth / 2))
              .attr('stroke-width', bevelWidth)
            strategyCircle.transition()
              .duration(circleDuration)
              .attr('r', endRadius)
              .on('end', () => {

                surface.append('text')
                  .attr('x', midpointTopRight.x)
                  .attr('y', midpointTopRight.y - (fontSize / 1.75))
                  .text('Global')
                  .attr('fill', 'black')
                  .attr('text-anchor', 'middle')
                  .attr('dominant-baseline', 'central')
                  .attr('font-size', fontSize)
                  .attr('font-weight', 500)
                
                surface.append('text')
                  .attr('x', midpointTopRight.x)
                  .attr('y', midpointTopRight.y + (fontSize / 1.75))
                  .text('Investment')
                  .attr('fill', 'black')
                  .attr('text-anchor', 'middle')
                  .attr('dominant-baseline', 'central')
                  .attr('font-size', fontSize)
                  .attr('font-weight', 500)

                const line = background.append('line')
                  .attr('stroke', '#DCDCDC')
                  .attr('stroke-width', bevelWidth)
                  .attr('stroke-linecap', 'round')
                  .attr('filter', 'url(#innerShadowPath)')
                  .attr('x1', midpointMiddle.x)
                  .attr('y1', midpointMiddle.y)
                  .attr('x2', midpointMiddle.x)
                  .attr('y2', midpointMiddle.y)

                line.transition()
                  .duration(lineDuration)
                  .attr('x2', midpointRight.x)
                  .attr('y2', midpointRight.y)
                  .on('end', () => {
                    // Start 'Optimisation' circle
                    const optimisationCircleWrap = surface.append('g')
                      .attr('filter', 'url(#innerShadow)')
                    const subWrapOptimisation = optimisationCircleWrap.append('g')
                      .attr('filter', 'url(#dropShadow)')

                    const optimisationCircle = makeCircle(
                      subWrapOptimisation,
                      midpointRight,
                      startRadius,
                      { fill:  'url(#radialGradientWhite)' }
                    )
                    const optimisationCircleBevel = makeCircle(
                      subWrapOptimisation,
                      midpointRight,
                      startRadius,
                      {
                        fill: 'none',
                        stroke: '#DCDCDC',
                        'stroke-width': 0,
                        filter:  'url(#innerShadowPath)',
                      }
                    )

                    optimisationCircleBevel.transition()
                      .duration(circleDuration)
                      .attr('r', endRadius + (bevelWidth / 2))
                      .attr('stroke-width', bevelWidth)

                    optimisationCircle.transition()
                      .duration(circleDuration)
                      .attr('r', endRadius)
                      .on('end', () => {
                        
                        surface.append('text')
                          .attr('x', midpointRight.x)
                          .attr('y', midpointRight.y - (fontSize / 0.75))
                          .text('Global')
                          .attr('fill', 'black')
                          .attr('text-anchor', 'middle')
                          .attr('dominant-baseline', 'central')
                          .attr('font-size', fontSize)
                          .attr('font-weight', 500)
                        
                        surface.append('text')
                          .attr('x', midpointRight.x)
                          .attr('y', midpointRight.y - (fontSize / 2))
                          .text('Investment')
                          .attr('fill', 'black')
                          .attr('text-anchor', 'middle')
                          .attr('dominant-baseline', 'central')
                          .attr('font-size', fontSize)
                          .attr('font-weight', 500)

                        surface.append('text')
                          .attr('x', midpointRight.x)
                          .attr('y', midpointRight.y + (fontSize / 2))
                          .text('YoY')
                          .attr('fill', 'black')
                          .attr('text-anchor', 'middle')
                          .attr('dominant-baseline', 'central')
                          .attr('font-size', fontSize)
                          .attr('font-weight', 500)

                        surface.append('text')
                          .attr('x', midpointRight.x)
                          .attr('y', midpointRight.y + (fontSize / 0.75))
                          .text('Growth')
                          .attr('fill', 'black')
                          .attr('text-anchor', 'middle')
                          .attr('dominant-baseline', 'central')
                          .attr('font-size', fontSize)
                          .attr('font-weight', 500)
                        
                        const line = background.append('line')
                          .attr('stroke', '#DCDCDC')
                          .attr('stroke-width', bevelWidth)
                          .attr('stroke-linecap', 'round')
                          .attr('filter', 'url(#innerShadowPath)')
                          .attr('x1', midpointMiddle.x)
                          .attr('y1', midpointMiddle.y)
                          .attr('x2', midpointMiddle.x)
                          .attr('y2', midpointMiddle.y)
      
                        line.transition()
                          .duration(lineDuration)
                          .attr('x2', midpointBottomRight.x)
                          .attr('y2', midpointBottomRight.y)
                          .on('end', () => {
                            // Start 'Reporting' circle
                            const reportingCircleWrap = surface.append('g')
                              .attr('filter', 'url(#innerShadow)')
                            const subWrapReporting = reportingCircleWrap.append('g')
                              .attr('filter', 'url(#dropShadow)')
      
                            const reportingCircle = makeCircle(
                              subWrapReporting,
                              midpointBottomRight,
                              startRadius,
                              { fill:  'url(#radialGradientWhite)' }
                            )
                            const reportingCircleBevel = makeCircle(
                              subWrapReporting,
                              midpointBottomRight,
                              startRadius,
                              {
                                fill: 'none',
                                stroke: '#DCDCDC',
                                'stroke-width': 0,
                                filter:  'url(#innerShadowPath)',
                              }
                            )
      
                            reportingCircleBevel.transition()
                              .duration(circleDuration)
                              .attr('r', endRadius + (bevelWidth / 2))
                              .attr('stroke-width', bevelWidth)

                            reportingCircle.transition()
                              .duration(circleDuration)
                              .attr('r', endRadius)
                              .on('end', () => {
                                
                                surface.append('text')
                                  .attr('x', midpointBottomRight.x)
                                  .attr('y', midpointBottomRight.y - (fontSize / 1.75))
                                  .text('Digital')
                                  .attr('fill', 'black')
                                  .attr('text-anchor', 'middle')
                                  .attr('dominant-baseline', 'central')
                                  .attr('font-size', fontSize)
                                  .attr('font-weight', 500)
                                
                                surface.append('text')
                                  .attr('x', midpointBottomRight.x)
                                  .attr('y', midpointBottomRight.y + (fontSize / 1.75))
                                  .text('Mix')
                                  .attr('fill', 'black')
                                  .attr('text-anchor', 'middle')
                                  .attr('dominant-baseline', 'central')
                                  .attr('font-size', fontSize)
                                  .attr('font-weight', 500)

                                const line = background.append('line')
                                  .attr('stroke', '#DCDCDC')
                                  .attr('stroke-width', bevelWidth)
                                  .attr('stroke-linecap', 'round')
                                  .attr('filter', 'url(#innerShadowPath)')
                                  .attr('x1', midpointMiddle.x)
                                  .attr('y1', midpointMiddle.y)
                                  .attr('x2', midpointMiddle.x)
                                  .attr('y2', midpointMiddle.y)
              
                                line.transition()
                                  .duration(lineDuration)
                                  .attr('x2', midpointBottomLeft.x)
                                  .attr('y2', midpointBottomLeft.y)
                                  .on('end', () => {
                                    // Start next circle
                                    const nextCircleWrap = surface.append('g')
                                      .attr('filter', 'url(#innerShadow)')
                                    const subWrap = nextCircleWrap.append('g')
                                      .attr('filter', 'url(#dropShadow)')
              
                                    const nextCircle = makeCircle(
                                      subWrap,
                                      midpointBottomLeft,
                                      startRadius,
                                      { fill:  'url(#radialGradientWhite)' }
                                    )
                                    const nextCircleBevel = makeCircle(
                                      subWrap,
                                      midpointBottomLeft,
                                      startRadius,
                                      {
                                        fill: 'none',
                                        stroke: '#DCDCDC',
                                        'stroke-width': 0,
                                        filter:  'url(#innerShadowPath)',
                                      }
                                    )

                                    nextCircleBevel.transition()
                                      .duration(circleDuration)
                                      .attr('r', endRadius + (bevelWidth / 2))
                                      .attr('stroke-width', bevelWidth)

                                    nextCircle.transition()
                                      .duration(circleDuration)
                                      .attr('r', endRadius)
                                      .on('end', () => {
                                
                                        surface.append('text')
                                          .attr('x', midpointBottomLeft.x)
                                          .attr('y', midpointBottomLeft.y - (fontSize / 1))
                                          .text('Brand')
                                          .attr('fill', 'black')
                                          .attr('text-anchor', 'middle')
                                          .attr('dominant-baseline', 'central')
                                          .attr('font-size', fontSize)
                                          .attr('font-weight', 500)
                                        
                                        surface.append('text')
                                          .attr('x', midpointBottomLeft.x)
                                          .attr('y', midpointBottomLeft.y)
                                          .text('v')
                                          .attr('fill', 'black')
                                          .attr('text-anchor', 'middle')
                                          .attr('dominant-baseline', 'central')
                                          .attr('font-size', fontSize)
                                          .attr('font-weight', 500)
                                        
                                        surface.append('text')
                                          .attr('x', midpointBottomLeft.x)
                                          .attr('y', midpointBottomLeft.y + (fontSize / 1))
                                          .text('Performance')
                                          .attr('fill', 'black')
                                          .attr('text-anchor', 'middle')
                                          .attr('dominant-baseline', 'central')
                                          .attr('font-size', fontSize)
                                          .attr('font-weight', 500)
        
                                        const line = background.append('line')
                                          .attr('stroke', '#DCDCDC')
                                          .attr('stroke-width', bevelWidth)
                                          .attr('stroke-linecap', 'round')
                                          .attr('filter', 'url(#innerShadowPath)')
                                          .attr('x1', midpointMiddle.x)
                                          .attr('y1', midpointMiddle.y)
                                          .attr('x2', midpointMiddle.x)
                                          .attr('y2', midpointMiddle.y)
                      
                                        line.transition()
                                          .duration(lineDuration)
                                          .attr('x2', midpointLeft.x)
                                          .attr('y2', midpointLeft.y)
                                          .on('end', () => {
                                            // Start next circle
                                            const nextCircleWrap = surface.append('g')
                                              .attr('filter', 'url(#innerShadow)')
                                            const subWrap = nextCircleWrap.append('g')
                                              .attr('filter', 'url(#dropShadow)')
                      
                                            const nextCircle = makeCircle(
                                              subWrap,
                                              midpointLeft,
                                              startRadius,
                                              { fill:  'url(#radialGradientWhite)' }
                                            )
                                            const nextCircleBevel = makeCircle(
                                              subWrap,
                                              midpointLeft,
                                              startRadius,
                                              {
                                                fill: 'none',
                                                stroke: '#DCDCDC',
                                                'stroke-width': 0,
                                                filter:  'url(#innerShadowPath)',
                                              }
                                            )
        
                                            nextCircleBevel.transition()
                                              .duration(circleDuration)
                                              .attr('r', endRadius + (bevelWidth / 2))
                                              .attr('stroke-width', bevelWidth)
        
                                            nextCircle.transition()
                                              .duration(circleDuration)
                                              .attr('r', endRadius)
                                              .on('end', () => {
                                                surface.append('text')
                                                  .attr('x', midpointLeft.x)
                                                  .attr('y', midpointLeft.y)
                                                  .text('Product')
                                                  .attr('fill', 'black')
                                                  .attr('text-anchor', 'middle')
                                                  .attr('dominant-baseline', 'central')
                                                  .attr('font-size', fontSize)
                                                  .attr('font-weight', 500)
                
                                                const line = background.append('line')
                                                  .attr('stroke', '#DCDCDC')
                                                  .attr('stroke-width', bevelWidth)
                                                  .attr('stroke-linecap', 'round')
                                                  .attr('filter', 'url(#innerShadowPath)')
                                                  .attr('x1', midpointMiddle.x)
                                                  .attr('y1', midpointMiddle.y)
                                                  .attr('x2', midpointMiddle.x)
                                                  .attr('y2', midpointMiddle.y)
                              
                                                line.transition()
                                                  .duration(lineDuration)
                                                  .attr('x2', midpointTopLeft.x)
                                                  .attr('y2', midpointTopLeft.y)
                                                  .on('end', () => {
                                                    // Start next circle
                                                    const nextCircleWrap = surface.append('g')
                                                      .attr('filter', 'url(#innerShadow)')
                                                    const subWrap = nextCircleWrap.append('g')
                                                      .attr('filter', 'url(#dropShadow)')
                              
                                                    const nextCircle = makeCircle(
                                                      subWrap,
                                                      midpointTopLeft,
                                                      startRadius,
                                                      { fill:  'url(#radialGradientWhite)' }
                                                    )
                                                    const nextCircleBevel = makeCircle(
                                                      subWrap,
                                                      midpointTopLeft,
                                                      startRadius,
                                                      {
                                                        fill: 'none',
                                                        stroke: '#DCDCDC',
                                                        'stroke-width': 0,
                                                        filter:  'url(#innerShadowPath)',
                                                      }
                                                    )
                                                    nextCircleBevel.transition()
                                                      .duration(circleDuration)
                                                      .attr('r', endRadius + (bevelWidth / 2))
                                                      .attr('stroke-width', bevelWidth)
                
                                                    nextCircle.transition()
                                                      .duration(circleDuration)
                                                      .attr('r', endRadius)
                                                      .on('end', () => {
                                                        surface.append('text')
                                                          .attr('x', midpointTopLeft.x)
                                                          .attr('y', midpointTopLeft.y - (fontSize / 1.75))
                                                          .text('Media')
                                                          .attr('fill', 'black')
                                                          .attr('text-anchor', 'middle')
                                                          .attr('dominant-baseline', 'central')
                                                          .attr('font-size', fontSize)
                                                          .attr('font-weight', 500)
                                                        
                                                        surface.append('text')
                                                          .attr('x', midpointTopLeft.x)
                                                          .attr('y', midpointTopLeft.y + (fontSize / 1.75))
                                                          .text('Mix')
                                                          .attr('fill', 'black')
                                                          .attr('text-anchor', 'middle')
                                                          .attr('dominant-baseline', 'central')
                                                          .attr('font-size', fontSize)
                                                          .attr('font-weight', 500)
                                                      })
                                                  })
                                              })
                                          })
                                      })
                                  })
                              })
                          })
                      })
                  })
              })
          })
      })
    
  }, [width, height])

  return (
    <svg
      ref={svgRef}
      width={width}
      height={height}
      viewBox={`0 -15 ${width} ${height + 30}`}>
      <defs>
        { circleAnimationDefElements }
      </defs>
    </svg>
  )

}


export const AnimationMediaInvestment = (): JSX.Element => {
  return (
    <ParentSize
      debounceTime={100}>
      { AnimationSurface }
    </ParentSize>
  )
}
