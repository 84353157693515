
import { actionCreator } from '../../utils'

import {
  AsyncReduxAction,
  AnnotationPoints,
  ReduxAction,
} from '@percept/types'

import {
  getAnnotationPath,
  getAnnotationLevel,
} from '@percept/utils'


export const LOAD_ANNOTATIONS = 'LOAD_ANNOTATIONS'

export const TOGGLE_ANNOTATIONS = 'TOGGLE_ANNOTATIONS'
export const CLOSE_ANNOTATIONS = 'CLOSE_ANNOTATIONS'

export const SET_ANNOTATION_CONTEXT = 'SET_ANNOTATION_CONTEXT'
export const CLEAR_ANNOTATION_CONTEXT = 'CLEAR_ANNOTATION_CONTEXT'

export const ADD_ANNOTATION = 'ADD_ANNOTATION'
export const CLEAR_ADD_ANNOTATION = 'CLEAR_ADD_ANNOTATION'

export const EDIT_ANNOTATION = 'EDIT_ANNOTATION'
export const CLEAR_EDIT_ANNOTATION = 'CLEAR_EDIT_ANNOTATION'

export const DELETE_ANNOTATION = 'DELETE_ANNOTATION'
export const CLEAR_DELETE_ANNOTATION = 'CLEAR_DELETE_ANNOTATION'


export const loadAnnotations = ({ series_id }: { series_id: string }): AsyncReduxAction => ({
  type: LOAD_ANNOTATIONS,
  resource: `/annotations/series/${series_id}`,
  meta: { series_id },
})


export const toggleAnnotations = actionCreator(TOGGLE_ANNOTATIONS)

export const setAnnotationContext = (annotationPoints: AnnotationPoints): ReduxAction => ({
  type: SET_ANNOTATION_CONTEXT,
  payload: annotationPoints,
  meta: annotationPoints,
})

export const clearAnnotationContext = actionCreator(CLEAR_ANNOTATION_CONTEXT)

// export const loadMockAnnotations = (): MockAsyncReduxAction => trace({
//   type: LOAD_ANNOTATIONS,
//   resource: '/annotations',
//   test: true,
//   delay: 200,
//   data: sampleAnnotations
// })



interface AddAnnotationProps extends AnnotationPoints {
  payload: string,
}


export const addAnnotation = ({ series_id, payload, ...props }: AddAnnotationProps): AsyncReduxAction => ({
  type: ADD_ANNOTATION,
  resource: `/annotations/series/${series_id}`,
  options: {
    method: 'POST',
    data: {
      attachment_point_type: getAnnotationLevel({ series_id, ...props }),
      attachment_point_path: getAnnotationPath({ series_id, ...props }),
      series_id,
      payload
    },
  },
  meta: { series_id, ...props },
  successActions: [
    loadAnnotations({ series_id })
  ],
})

export const clearAddAnnotation = actionCreator(CLEAR_ADD_ANNOTATION)


interface EditAnnotationProps extends AddAnnotationProps {
  annotation_id: string,
}

export const editAnnotation = ({ annotation_id, series_id, payload, ...props }: EditAnnotationProps): AsyncReduxAction => ({
  type: EDIT_ANNOTATION,
  resource: `/annotations/${annotation_id}`,
  options: {
    method: 'PATCH',
    data: {
      attachment_point_type: getAnnotationLevel({ series_id, ...props }),
      attachment_point_path: getAnnotationPath({ series_id, ...props }),
      series_id,
      payload
    },
  },
  meta: { series_id, ...props },
  successActions: [
    loadAnnotations({ series_id })
  ]
})

export const clearEditAnnotation = actionCreator(CLEAR_EDIT_ANNOTATION)


interface RemoveAnnotationProps extends AnnotationPoints {
  annotation_id: string,
}

export const deleteAnnotation = ({ annotation_id, series_id, ...props }: RemoveAnnotationProps): AsyncReduxAction => ({
  type: DELETE_ANNOTATION,
  resource: `/annotations/${annotation_id}`,
  options: {
    method: 'DELETE'
  },
  meta: { annotation_id, series_id, ...props },
  successActions: [
    loadAnnotations({ series_id })
  ]
})

export const clearDeleteAnnotation = actionCreator(CLEAR_DELETE_ANNOTATION)
