import React from 'react'

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  InputAdornment,
  InputProps,
  RoundedPlainTextButtonMenu,
  SvgIconProps,
} from '@percept/mui'

import { ArrowDropDown, Email, Person } from '@percept/mui/icons'

import { getMarketLabel, getUserTypeLabel } from './attributeComponents'

import { intersection, xor } from 'lodash-es'

import {
  TenantUserAttribute,
  TenantUserAttributeOptionValue,
  TenantUserAttributeSelect,
  TenantUserAttributeSingleValue,
} from '@percept/types'


const getAttributeValueLabel = (parentAttribute: TenantUserAttribute, attribute: TenantUserAttributeOptionValue): JSX.Element | React.ReactNode => {
  if( parentAttribute.name === 'Market' ){
    return getMarketLabel(attribute.name)
  }

  if( parentAttribute.name === 'User Type' ){
    return getUserTypeLabel(attribute.name)
  }

  return attribute.name
}


export const TenantUserAttributeSelectComponent = ({
  attribute,
  value,
  disabled,
  onChange,
}: {
  attribute: TenantUserAttributeSelect
  value: string | undefined
  disabled?: boolean
  onChange: (event: React.MouseEvent, value: unknown) => void
}): JSX.Element => {
  const match = attribute.values.find( v => v.id === value )
  const label = match ? getAttributeValueLabel(attribute, match) : `Select ${attribute.name}`

  return (
    <FormControl>
      <FormLabel style={{marginBottom: 8,}}>{ attribute.name }</FormLabel>
      <RoundedPlainTextButtonMenu
        TriggerProps={{
          variant: 'contained',
          endIcon: !disabled && <ArrowDropDown />,
          disabled,
        }}
        value={value}
        label={label}
        options={
          attribute.values.filter( v => v.enabled ).map( v => ({
            value: v.id,
            label: getAttributeValueLabel(attribute, v)
          }))
        }
        onChange={onChange} />
    </FormControl>
  )
}


export const TenantUserAttributeCheckboxComponent = ({
  attribute,
  value,
  onChange,
}: {
  attribute: TenantUserAttributeSelect
  value: string[] | undefined
  onChange: (value: string[]) => void
}): JSX.Element => {
  const stringValues: string[] = value || []
  const allValues = attribute.values.filter( v => v.enabled ).map( v => v.id )
  return (
    <FormControl component='fieldset'>
      <FormLabel>{attribute.name}</FormLabel>
      <FormGroup>
        <FormControlLabel
          name='select_all'
          label={
            intersection(stringValues, allValues).length === allValues.length ?
              'Deselect All' :
              'Select All'
          }
          control={
            <Checkbox
              size='small'
              color='primary'
              indeterminate
              checked={
                intersection(stringValues, allValues).length === allValues.length
              }
              onChange={(): void => {
                onChange(
                  intersection(stringValues, allValues).length === allValues.length ?
                    [] :
                    allValues
                )
              }} />
          } />
        { attribute.values.filter( v => v.enabled ).map( ({ id, name }) => (
          <FormControlLabel
            key={id}
            label={name}
            control={
              <Checkbox
                name='global_roles'
                size='small'
                color='primary'
                checked={stringValues.includes(id)}
                onChange={(): void => {
                  onChange(xor(stringValues, [id]))
                }} />
            } />
        ))}
      </FormGroup>
    </FormControl>
  )
}


export const TenantUserAttributeValueComponent = ({
  attribute,
  value,
  ...props
}: {
  attribute: TenantUserAttributeSingleValue
  value: string
} & Partial<InputProps>): JSX.Element => {
  const iconColor: SvgIconProps['color'] = !props.disabled ? 'primary' : 'disabled'
  return (
    <>
      <FormLabel>{ attribute.name }</FormLabel>
      <Input
        name={attribute.id}
        type={attribute.input_type || 'text'}
        value={value}
        fullWidth
        startAdornment={
          attribute.input_type === 'email' ? (
            <InputAdornment position='start'>
              <Email color={iconColor} />
            </InputAdornment>
          ) : attribute.name === 'Name' ? (
            <InputAdornment position='start'>
              <Person color={iconColor} />
            </InputAdornment>
          ) : false
        }
        {...props} />
    </>
  )
}
