
import { useMemo } from 'react'

import { AppTheme, darken, lighten } from '@percept/mui'

import { ChartData, detailedPercentageFormatter, getMoneyFormatter, numberFormatter, SVGDatumType } from '@percept/mui/charts'

import { getCPA, getCPC, getCPM, getCTR, getReportResultSummary } from '@percept/utils'

import { get, sortBy } from 'lodash-es'

import { dimensionMap } from '@percept/constants'

import {
  PerformanceDimensionType,
  DerivedPerformanceDimensionType,
  PerformanceSummary,
  PerformanceValue,
  StructuralReport,
  StructuralReportResultType,
} from '@percept/types'


export type TimelineDatum = SVGDatumType & {
  start: number
  end: number
}


export type SeriesDimension = PerformanceDimensionType | DerivedPerformanceDimensionType


export type SeriesPerformanceSegmentation = 'search' | 'video'


export type SeriesDataset = {
  dimension: SeriesDimension
  formatter: (value: number | null) => string
  currency: string | null
  label: string
  data: ChartData<TimelineDatum>
  color: string
  backgroundColor?: string
}


const baseSeriesDimensions: SeriesDimension[] = [
  'cost',
  'impressions',
  'clicks',
  'conversions',
]

const seriesDimensions: SeriesDimension[] = [
  ...baseSeriesDimensions,
  'cpa',
  'cpc',
  'ctr',
  'cpm',
]


const getDimensionFormatter = (dimension: SeriesDimension, currency: string | null): SeriesDataset['formatter'] => {
  switch(dimension){
    case 'cost':
    case 'cpa':
    case 'cpm':
    case 'cpc': return getMoneyFormatter(currency)
    case 'ctr': return detailedPercentageFormatter
    default: return numberFormatter
  }
}


const resolvePerformance = (
  summary: PerformanceSummary,
  segmentation: SeriesPerformanceSegmentation | null,
): Record<SeriesDimension, PerformanceValue> | null => {

  let hasRequiredSegmentation = true

  const perf = baseSeriesDimensions.reduce((acc, dimension) => {
    let value: PerformanceValue = null
    if( summary ){
      const key = segmentation ? `${segmentation}_${dimension}` : dimension
      if( typeof summary[key] !== 'undefined' ){
        value = summary[key]
      }else{
        hasRequiredSegmentation = false
      }
    }
    acc[dimension] = value
    return acc
  }, {} as Record<SeriesDimension, PerformanceValue>)

  if( !hasRequiredSegmentation ){
    return null
  }

  return {
    ...perf,
    cpa: getCPA(perf),
    cpc: getCPC(perf),
    ctr: getCTR(perf),
    cpm: getCPM(perf),
  }
}


export const useSeriesDatasets = ({
  reports,
  segmentation,
  appTheme,
  resultType,
  maxReports = null,
}: {
  reports: StructuralReport[] | null
  segmentation: SeriesPerformanceSegmentation | null
  resultType: StructuralReportResultType
  appTheme: AppTheme
  maxReports?: number | null
}): SeriesDataset[] => {

  return useMemo(() => {
    if( !reports ){
      return []
    }

    const completedReports = reports.filter( r => !!r.results.length )
    const chronologicalReports = sortBy(completedReports, r => r.end)
    const filteredReports = (
      (!maxReports || maxReports >= chronologicalReports.length) ?
        chronologicalReports :
        chronologicalReports.slice(
          chronologicalReports.length - 1 - maxReports
        )
    )

    const mappedReportData = filteredReports.reduce( (acc, report) => {
      const summary = getReportResultSummary(report, resultType)
      const payload = resolvePerformance(summary && summary.performance, segmentation)
      if( payload ){
        acc.push({
          payload,
          label: new Date(report.end).getTime(),
          start: new Date(report.start).getTime(),
          end: new Date(report.end).getTime(),
          currency: summary && summary.currency_code,
        })
      }
      return acc
    }, [] as ({
      payload: Record<SeriesDimension, PerformanceValue>
      label: number
      start: number
      end: number
      currency: string | null
    })[])

    const currency = get(
      mappedReportData, [0, 'currency'], null
    )

    return seriesDimensions.map( (dimension): SeriesDataset => {
      const color = appTheme.palette.secondary.main
      // const colorTransform = appTheme.palette.type === 'dark' ? darken : lighten
      // const backgroundColor = colorTransform(appTheme.palette.secondary.dark, 0.55)
      const backgroundColor = appTheme.palette.background.paper
      return {
        backgroundColor,
        color,
        currency,
        dimension,
        label: (
          dimensionMap[dimension as PerformanceDimensionType] &&
          dimensionMap[dimension as PerformanceDimensionType].text ||
          // We want to capitalize all letters here, as the only non-mapped dimensions
          // in this view are 'CPC' and 'CTR'
          dimension.toUpperCase()
        ),
        formatter: getDimensionFormatter(
          dimension,
          currency
        ),
        data: mappedReportData.map( ({ start, end, label, payload }) => {
          return {
            label,
            value: payload[dimension],
            color,
            start,
            end,
          }
        }),
      }
    })
  }, [reports, maxReports, segmentation, appTheme, resultType])
}
