import React, { Fragment } from 'react'

import { Box, Grid, GridSize, Typography } from '@percept/mui'

import { ChannelDiagnostics } from './ChannelDiagnostics'

import { DiagnosticsGridFragmentProps } from '../typings'

import { ChannelDiagnosticsProps } from './ChannelDiagnostics'

import { Insight } from '@percept/types'


export type StructuralDiagnosticsProps = (
  DiagnosticsGridFragmentProps &
  Pick<
    ChannelDiagnosticsProps, 'seriesListing'
  > & {
    insights: Partial<Record<'adwords' | 'facebook', Insight[]>>
  }
)


export const StructuralDiagnostics = ({
  seriesListing,
  insights,
  gridClasses,
  gridSpacing,
}: StructuralDiagnosticsProps): JSX.Element => {

  const numProviders = Object.keys(insights).length

  const md: GridSize = numProviders === 1 ? 12 : 6

  return (
    <Fragment>

      <Grid item xs={12}>
        <Typography variant='h5'>Structural Analysis</Typography>
      </Grid>

      { insights.adwords && (
        <Grid item xs={12} md={md}>
          <Box mb={5}>
            <ChannelDiagnostics
              seriesListing={seriesListing}
              provider='adwords'
              insights={insights.adwords} />
          </Box>
        </Grid>
      )}

      { insights.facebook && (
        <Grid item xs={12} md={md}>
          <ChannelDiagnostics
            seriesListing={seriesListing}
            provider='facebook'
            insights={insights.facebook} />
        </Grid>
      )}

    </Fragment>
  )
}
