import React, { Fragment, useState } from 'react'

import { CardMedia, Dialog, IconButton } from '@percept/mui'

import { PlayCircleFilled } from '@percept/mui/icons'


export type VideoPreviewProps = {
  src: string
}

export const VideoPreview = ({ src }: VideoPreviewProps): JSX.Element => {

  const [open, setOpen] = useState(false)

  return (
    <Fragment>

      <IconButton
        onClick={(): void => setOpen(true)}>
        <PlayCircleFilled />
      </IconButton>

      <Dialog
        open={open}
        keepMounted={false}
        onClose={(): void => setOpen(false)}>
        <CardMedia
          component='video'
          autoPlay={open}
          controls
          src={src} />
      </Dialog>

    </Fragment>
  )
}
