import React from 'react'

import { Box, Typography, makeAppStyles } from '@percept/mui'


type TitleProps = {
  titleText: string
  subtitleText?: string
  labelText?: string
  downloadButton?: JSX.Element | null
}
const useTitleClasses = makeAppStyles((theme) => ({
  titleGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2.5),
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontSize: '12px',
    border: '1px solid #fff',
    padding: `0px  ${theme.spacing(0.7)}px`,
    marginLeft: theme.spacing(1.5),
    borderRadius: '2px',
  },
}))

export const TitleComponent = ({
  titleText,
  subtitleText,
  labelText,
  downloadButton,
}: TitleProps): JSX.Element => {
  const classes = useTitleClasses()
  return (
    <Box my={4}>
      <Box className={classes.titleGroup}>
        <Box className={classes.title}>
          <Typography variant='h2'>{titleText}</Typography>
          {labelText && (
            <Typography className={classes.label}>{labelText}</Typography>
          )}
        </Box>
        { downloadButton }
      </Box>
      <Typography variant='subtitle2'>{subtitleText}</Typography>
    </Box>
  )
}
