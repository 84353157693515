import React, { useEffect, useState } from 'react'

import {
  Box,
  ButtonPopover,
  ButtonPopoverProps,
  Divider,
  Grid,
  MonthRangePicker,
  MonthRangePickerProps,
  RoundedPlainTextButton,
  Typography,
  makeAppStyles,
} from '@percept/mui'

import { ArrowDropDown } from '@percept/mui/icons'

import { every } from 'lodash-es'

import { startOfMonth } from 'date-fns'

import { dmyFormatter } from '@percept/mui/charts'


type DateListValue = MonthRangePickerProps['value']

const areDateListsEqual = (a: DateListValue | null, b: DateListValue | null): boolean => {
  if( !a && !b ) return true
  if( !a || !b ) return false
  return every(a, (d, i) => {
    const dateToCompare = b[i]
    if( d === null ) return dateToCompare === null
    return d && dateToCompare && (
      dmyFormatter(startOfMonth(d)) === dmyFormatter(startOfMonth(dateToCompare))
    )
  })
}


const useStyles = makeAppStyles( theme => ({
  grid: {
    width: 520,
    padding: theme.spacing(2),
  },
  dividerContainer: {
    padding: theme.spacing(2, 3),
  },
  slider: {
    margin: theme.spacing(0, 2),
  },
  buttonContainer: {
    textAlign: 'right',
  }
}))


export const MonthRangeSelect = ({
  value,
  onChange,
  minDate,
  maxDate,
  ButtonPopoverProps,
  ...props
}: MonthRangePickerProps & {
  ButtonPopoverProps?: Partial<Omit<ButtonPopoverProps, 'children'>>
}): JSX.Element => {

  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  const areFiltersApplied = areDateListsEqual(value, localValue)

  const classes = useStyles()

  return (
    <ButtonPopover
      ButtonComponent={RoundedPlainTextButton}
      endIcon={<ArrowDropDown />}
      variant='contained'
      buttonContent='Time Range'
      onClose={(): void => {
        setLocalValue(value)
      }}
      {...ButtonPopoverProps}>
      { ({ onClose }): JSX.Element => (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <Typography variant='h5'>
              Time Range
            </Typography>
            <MonthRangePicker
              minDate={minDate}
              maxDate={maxDate}
              value={localValue}
              onChange={setLocalValue}
              {...props} />
          </Grid>
          <Box maxWidth='100%' flexBasis='100%' mt={1} px={1}>
            <Divider />
          </Box>
          <Grid item xs={12} className={classes.buttonContainer}>
            <RoundedPlainTextButton
              variant='contained'
              color='secondary'
              size='small'
              disabled={areFiltersApplied}
              onClick={(): void => {
                onChange(localValue)
                onClose()
              }}>
              { areFiltersApplied ? 'Filter Applied' : 'Apply Filter'}
            </RoundedPlainTextButton>
          </Grid>
        </Grid>
      )}
    </ButtonPopover>
  )
}
