import React from 'react'

import { OutlinedInput, ClassNameMap, makeAppStyles, LazyInput } from '@percept/mui'

import { TABLE_FORM_CELL_WIDTH, TABLE_FORM_ROW_HEIGHT } from './constants'
import { separateThousands } from '@percept/utils'


export type TableInputClassName = 'root' | 'input' | 'placeholder'
export type TableInputClassNameMap = ClassNameMap<TableInputClassName>

export const useTableInputStyles = makeAppStyles<{}, TableInputClassName>( theme => ({
  root: {
    height: TABLE_FORM_ROW_HEIGHT - 20,
    margin: '10px 8px',
    width: TABLE_FORM_CELL_WIDTH,
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input': {
      textAlign: 'end'
    }
  },
  input: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    border: `1px solid ${theme.palette.action.disabled}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0, 1),
    height: '100%',
    fontSize: 14,
    fontWeight: 700,
  },
}))


export type TableInputProps = {
  value: string
  month: string
  id: number
  classes: TableInputClassNameMap
  editingEnabled?: boolean
  handleInputChange: (e: any, month: string, id: number) => void
}

export const TableInput = ({
  classes,
  value,
  id,
  month,
  editingEnabled = true,
  handleInputChange,
}: TableInputProps): JSX.Element => {
  if( !editingEnabled ){
    return (
      <div className={classes.root}>
        <div className={classes.placeholder}>
          {value.length ? separateThousands(value) : value}
        </div>
      </div>
    )
  }
  return (
    <LazyInput
      InputComponent={OutlinedInput}
      className={classes.root}
      value={value}
      type='text'
      inputProps={{
        className: classes.input,
      }}
      onChange={(e): void => handleInputChange(e, month, id)}
    />
  )
}
