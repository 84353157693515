import React, { useMemo, useState } from 'react'

import {
  deriveCardStripColor,
  Grid,
  makeAppStyles,
  ReportRange,
  useAppTheme,
} from '@percept/mui'

import {
  ChartData,
  detailedPercentageFormatter,
  dmyFormatter,
  longDayMonthYearFormatter,
  ResponsiveLine,
  LineProps,
  SVGDatumType,
} from '@percept/mui/charts'

import { DimensionalSummaryItem } from './DimensionalSummaryItem'

import { useLayoutGridSpacing } from 'hooks'

import { getDefaultPerformanceReportingDomain } from 'components/CrossChannelDashboard'

import { isMonetaryDimension, isPercentageDimension, isRateBasedDimension } from '@percept/utils'

import { get } from 'lodash-es'

import { dimensionMap } from '@percept/constants'

import { DimensionalSummaryProps } from './typings'

import { PerformanceReportingDatum, TimelineDatum } from 'types'

import { PerformanceReportingDimension } from '@percept/types'


const useStyles = makeAppStyles( theme => ({
  card: {
    flexGrow: 1,
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  cardContentTable: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'stretch',
    height: '100%',
    marginTop : theme.spacing(1),
  },
  tableContainer: {
    maxHeight: 200,
    overflowY: 'auto',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridItemTable: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  chart: {
    margin: theme.spacing(1, 1, 0, 1),
    width: '100%',
  },
}) )


const CHART_FILL_OPACITY = 0.25

const lineProps: Omit<LineProps<TimelineDatum>, 'data' | 'width'> = {
  xScaleType: 'time',
  arbitraryTimeline: true,
  grid: 'rows',
  axisLine: true,
  axisText: true,
  height: 200,
  numYTicks: 5,
  fillOpacity: CHART_FILL_OPACITY,
  xTickFormatter: longDayMonthYearFormatter,
  tooltipLabelFormatter: dmyFormatter,
  // eslint-disable-next-line react/display-name
  tooltipLabelDatumFormatter: (d) => {
    return (
      <ReportRange
        start={d.start}
        end={d.end} />
    )
  }
}


export function SingleUnitDimensionalSummary<
  T extends PerformanceReportingDatum & {
    dimension: PerformanceReportingDimension
    currency?: string | null
  }
>({
  dimension,
  currency,
  labelHistory,
  color,
  datasets,
}: DimensionalSummaryProps<T>): JSX.Element {

  const [defaultXDomain] = useState(getDefaultPerformanceReportingDomain)

  const label = dimensionMap[dimension].text

  const appTheme = useAppTheme()

  const chartColor = deriveCardStripColor({ color, appTheme })

  const isRateBased = isRateBasedDimension(dimension)

  const axisChartData: ChartData<TimelineDatum> = useMemo(() => {
    // Coerce rate-based metrics to percentages
    let unitData: ChartData<TimelineDatum> = get(datasets, [0, 'dataset', 'timeseries'], [])
    if( isRateBased ){
      unitData = unitData.map( d => ({
        ...d,
        value: d.value && (Number(d.value) * 100),
      }))
    }
    return unitData.map( (d: TimelineDatum) => ({
      ...d,
      color: chartColor,
    }))
  }, [datasets, chartColor, isRateBased])

  const summaryClasses = useStyles()

  const gridSpacing = useLayoutGridSpacing()

  labelHistory = labelHistory || `${label} History`

  const dimensionalChartProps = {
    currency: isMonetaryDimension(dimension) ? currency : null,
    yTickFormatter: isPercentageDimension(dimension) ? detailedPercentageFormatter : undefined,
  }

  return (
    <Grid container spacing={gridSpacing}>

      <DimensionalSummaryItem
        classes={summaryClasses}
        header={labelHistory}
        color={color}
        xs={12}>

        <div className={summaryClasses.chart}>
          <ResponsiveLine
            defaultXDomain={defaultXDomain}
            data={axisChartData}
            {...lineProps as Omit<LineProps<SVGDatumType>, 'data'>}
            {...dimensionalChartProps}
            fill={chartColor} />
        </div>

      </DimensionalSummaryItem>

    </Grid>
  )
}
