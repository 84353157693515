
import React from 'react'

import {
  Box,
  Card,
  CardContent,
  CardStrip,
  Grid,
  Typography,
} from '@percept/mui'

import { DimensionalSummaryItemProps } from './typings'


export const DimensionalSummaryItem = ({
  color,
  header,
  children,
  classes,
  ...props
}: DimensionalSummaryItemProps): JSX.Element => (
  <Grid item className={classes.gridItem} xs={12} {...props}>

    <Box mb={3}>
      <Typography variant='h5' className={classes.header}>
        { header }
      </Typography>
    </Box>

    <Card className={classes.card}>

      {/* <CardStrip color={color} /> */}

      <CardContent className={classes.cardContent}>
        { children }
      </CardContent>
    </Card>

  </Grid>
)
