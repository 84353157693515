export enum FormColumns {
  buyType = 'buy_type',
  agency = 'agency',
  agencyDiscount = 'agency_discount',
  campaignPillar = 'campaign',
  campaignSubPillar = 'sub_campaign',
  competitor = 'competitor',
  competitveSecondBrand = 'competitive_second_brand',
  dataSource = 'source',
  equivSpotLengths = 'equiv_spot_lengths',
  grossNet = 'spending_type',
  jbp = 'jbp',
  fundingSource = 'funding_source',
  market = 'market',
  mediaChannel = 'media_channel',
  mediaSubChannel = 'media_sub_channel',
  message = 'message',
  product = 'product',
  secondBrand = 'second_brand',
  spotLengths = 'spot_length',
  timeLags = 'time_lag',
  tradedAudience = 'traded_audience',
}
