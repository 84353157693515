import { OptionType } from '../Filters'
import { FormColumns } from 'enums/FormColumns'
import Api, { Breakdown, BreakdownFilterItem, OrgUnitInvestmentBreakdownsResponse } from 'api/services/Api'
import { PlatformUnitParams } from '@percept/types'
import { useMemo } from 'react'
import { investmentFilterToOptionType } from '../utils'


export type WebFormColumn = {
  name: string
  label: string
  description: string
}

type WebFormColumWithOptions = WebFormColumn & {
  options: OptionType[] | undefined
}


const formColumnBreakdownMap: Record<string, Breakdown> = {
  [FormColumns.agency]: 'agency',
  [FormColumns.buyType]: 'buy_type',
  [FormColumns.campaignPillar]: 'campaigns_pillar',
  [FormColumns.campaignSubPillar]: 'campaigns_sub_pillar',
  [FormColumns.fundingSource]: 'funding_source',
  [FormColumns.jbp]: 'jbp',
  [FormColumns.mediaChannel]: 'media_channel',
  [FormColumns.mediaSubChannel]: 'media_sub_channel',
  [FormColumns.message]: 'message',
  [FormColumns.product]: 'product',
  [FormColumns.secondBrand]: 'second_brand',
}


const mapBreakdownItems = (
  filterItems: BreakdownFilterItem[]
): OptionType[] => (
  filterItems.map(investmentFilterToOptionType)
)

const adaptBreakdownsResponse = (response: OrgUnitInvestmentBreakdownsResponse): Record<Breakdown, OptionType[]> => {
  return {
    agency: mapBreakdownItems(response.agencies),
    buy_type: mapBreakdownItems(response.buy_types),
    campaigns_pillar: mapBreakdownItems(response.campaigns),
    // TODO - sub pillar and parent item shenanigans!
    campaigns_sub_pillar: mapBreakdownItems(response.campaigns),
    department: mapBreakdownItems(response.departments),
    funding_source: mapBreakdownItems(response.funding_sources),
    jbp: mapBreakdownItems(response.jbps),
    media_channel: mapBreakdownItems(response.media_channels),
    // TODO - sub channel and parent item shenanigans!
    media_sub_channel: mapBreakdownItems(response.media_channels),
    message: mapBreakdownItems(response.messages),
    product: mapBreakdownItems(response.products),
    second_brand: mapBreakdownItems(response.second_brands),
  }
}


export const useColumnOptions = ({
  org_unit_id,
  columns,
}: PlatformUnitParams & {
  columns: WebFormColumn[]
}): WebFormColumWithOptions[] => {

  const queryResult = Api.useInvestmentBreakdowns(org_unit_id)

  return useMemo(() => {
    if( !queryResult.data ){
      return columns.map( c => ({...c, options: undefined}))
    }

    const adaptedResponse = adaptBreakdownsResponse(queryResult.data)

    const mappedOptions: WebFormColumWithOptions[] = columns.map( c => {
      const key = formColumnBreakdownMap[c.name]
      if( !key ){
        console.warn('No key found for column', c.name)
        return {...c, options: []}
      }
      return {
        ...c,
        options: adaptedResponse[key]
      }
    })

    return mappedOptions
  }, [queryResult, columns])
}
