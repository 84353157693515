
import React from 'react'

import { Gauge } from './Gauge'

import { GaugeProps, getPercentageDataset, PercentageGaugeTypeProps } from './lib'

import { useAppTheme } from '../../themes'

import { DatumType } from '@percept/types'


export type PercentageGaugeProps<T extends DatumType> = (
  PercentageGaugeTypeProps &
  Omit<
    GaugeProps<T>,
    'data'
  >
)


export function PercentageGauge<T extends DatumType>({
  value,
  color = '',
  trackColor = 'rgba(0,0,0,0.15)',
  invert,
  isHealthPercentage,
  ...props
}: PercentageGaugeProps<T>): JSX.Element {

  const appTheme = useAppTheme()

  const dataset = getPercentageDataset<T>({
    value,
    invert,
    color,
    trackColor,
    isHealthPercentage,
    appTheme,
  })

  return (
    <Gauge
      data={dataset.data}
      {...props} />
  )

}
