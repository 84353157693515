import React from 'react'

import { Box, Chip, ChipProps, Money, useAppTheme, withAppStyles } from '@percept/mui'

import { Check, Close } from '@percept/mui/icons'

import { VideoPreview } from './VideoPreview'

import { deslugify, separateThousands } from '@percept/utils'

import { format as dateFormat } from 'date-fns'


type ListToTagProps = ChipProps & {
  items: {
    label: string
    icon?: JSX.Element
  }[]
}


const TinyChip = withAppStyles( theme => ({
  root: {
    fontSize: 11,
    height: 'unset',
    lineHeight: 1,
    marginRight: theme.spacing(0.75),
    padding: theme.spacing(0.5, 0),
    backgroundColor: theme.palette.secondary.dark,
  }
}))(Chip)


const ListToTags = ({ color, items, ...props }: ListToTagProps): JSX.Element => (
  <Box
    display='flex'
    alignItems='center'>
    { items.map( item => (
      <TinyChip
        key={item.label}
        label={item.label}
        icon={item.icon}
        color={color}
        {...props} />
    ))}
  </Box>
)


const BooleanIcon = ({
  state,
}: { state: boolean }): JSX.Element => {
  const appTheme = useAppTheme()
  const IconComponent = state ? Check : Close
  const htmlColor = (
    state ?
      appTheme.palette.success.main :
      appTheme.palette.error.main
  )
  return (
    <IconComponent
      htmlColor={htmlColor} />
  )
} 


export type EntityAttributeProps = React.HTMLAttributes<HTMLSpanElement> & {
  format: 'text' | 'currency' | 'number' | 'list' | 'percentage' | 'date' | 'video_preview'
  prettyPrint?: boolean
  value: string | number | string[] | null | undefined
  currency?: string | null
}


export const EntityAttribute = ({
  format,
  value,
  currency,
  prettyPrint = false,
  ...props
}: EntityAttributeProps): JSX.Element => {

  let displayValue: React.ReactChild | null = null

  switch(format){
    case 'currency':
      displayValue = (
        <Money
          amount={Number(value)}
          currency={currency}
          abbreviate={false} />
      )
      break

    case 'number':
      displayValue = separateThousands(Number(value))
      break

    case 'percentage':
      displayValue = `${separateThousands(Number(value))}%`
      break

    case 'list':
      if( value && (value as string[]).length ){
        displayValue = (
          <ListToTags
            size='small'
            color='secondary'
            items={(value as string[]).map( v => ({
              label: prettyPrint ? deslugify(v) : v,
            }))} />
        )
      }
      break

    case 'date':
      if( value ){
        displayValue = dateFormat(new Date(value as number | string), 'dd/MM/yy')
      }
      break

    case 'video_preview':
      if( value ){
        displayValue = (
          <VideoPreview src={String(value)} />
          // <CardMedia
          //   component='video'
          //   onClick={(e: React.MouseEvent): void => {
          //     e.preventDefault()
          //   }}
          //   controls
          //   src={String(value)} />
        )
      }
      break

    default:
      if( typeof value === 'boolean' ){
        displayValue = (
          <BooleanIcon
            state={value} />
        )
      }else{
        displayValue = (
          value === null ?
            null :
            prettyPrint ?
              deslugify(String(value)) :
              String(value)
        )
      }
      break
  }

  return (
    <span {...props}>
      { displayValue }
    </span>
  )
}
