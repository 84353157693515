import React from 'react'

import { BasicSankeyNode, BasicSankeyLink, Sankey, SankeyProps } from './Sankey'

import { ParentSize } from '@visx/responsive'

import { ParentSizeProps } from '@visx/responsive/lib/components/ParentSize'


export type ResponsiveSankeyProps<
  Node extends BasicSankeyNode = BasicSankeyNode,
  Link extends BasicSankeyLink = BasicSankeyLink
> = (
  Omit<
    SankeyProps<Node, Link>, 'width' | 'height'
  > & Partial<
    Pick<
      SankeyProps<Node, Link>, 'width' | 'height'
    >
  > & Partial<
    ParentSizeProps
  >
)

export function ResponsiveSankey<
  Node extends BasicSankeyNode = BasicSankeyNode,
  Link extends BasicSankeyLink = BasicSankeyLink
>({
  className,
  debounceTime = 50,
  enableDebounceLeadingCall = true,
  ignoreDimensions,
  parentSizeStyles,
  ...props
}: ResponsiveSankeyProps<Node, Link>): JSX.Element {
  return (
    <ParentSize
      className={className}
      debounceTime={debounceTime}
      enableDebounceLeadingCall={enableDebounceLeadingCall}
      ignoreDimensions={ignoreDimensions}
      parentSizeStyles={parentSizeStyles}>
      { ({ width, height }): JSX.Element => (
        <Sankey
          {...props}
          width={Math.floor(props.width || width)}
          height={Math.floor(props.height || height)} />
      )}
    </ParentSize>
  )
}
