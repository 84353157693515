import { Box, BackdropLoader } from '@percept/mui'
import { useActivePlatformUnit } from 'components/Organisation'
import { DashboardLayout } from 'components/ReportingDashboard/DashboardLayout'
import { Sidebar } from 'components/ReportingDashboard/Sidebar'
import { TableForm } from 'components/ReportingDashboard/TableForm/TableForm'
import { SubmissionTracker } from 'components/ReportingDashboard/SubmissionTracker'
import {
  COMPETITIVE_INVESTMENT_ROUTES,
  FORM_ROUTES,
  MEDIA_INVESTMENT_ROUTES,
  POSSIBLE_FILTERS_COMPETITIVE_SOS,
  POSSIBLE_FILTERS_COMPETITIVE_SOV,
  POSSIBLE_FILTERS_MEDIA_INVESTMENT,
  SIDEBAR_TABS_COMPETITIVE_INVESTMENT,
  SIDEBAR_TABS_MEDIA_INVESTMENT,
} from 'constans'
import React, { ReactElement } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { DefaultLayout } from 'screens/DefaultLayout'
import PageNotFound from 'screens/PageNotFound'
import { DashboardType } from 'redux/ReportingDashboard/reducers/filtersReducer'
import { FilterItem } from 'components/ReportingDashboard/Filters'
import { RestrictedRoute } from '@percept/app-components'


export enum Reporting {
  MediaInvestment = 'media-investment',
  CompetitiveInvestment = 'competitive-investment',
  SubmissionTracker = 'submission-tracker',
  Form = 'investment-form',
}

const Organisation = (): ReactElement => {
  const activePlatformUnit = useActivePlatformUnit()
  if (activePlatformUnit) {
    return (
      <Redirect
        to={`${activePlatformUnit.id}/${Reporting.MediaInvestment}${MEDIA_INVESTMENT_ROUTES[0].path}`}
      />
    )
  }
  return <BackdropLoader />
}

const ReportingContainer = ({ children }: React.PropsWithChildren<{}>): JSX.Element => {
  return (
    <Box
      flexGrow={1}
      marginLeft='240px'
      padding='40px 36px'>
      { children }
    </Box>
  )
}

const ReportingRoutes = (): JSX.Element => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/:org_unit_id`} component={Organisation} />
      <Route path={`${path}/:org_unit_id/${Reporting.Form}`}>
        <Switch>
          {FORM_ROUTES.map((route, index) => {
            return (
              <Route
                key={index}
                path={`${path}/:org_unit_id/${Reporting.Form}` + route.path}>
                <TableForm {...route.props} />
              </Route>
            )
          })}
          <Redirect
            to={`${path}/:org_unit_id/${Reporting.Form}/media-investment`}
          />
        </Switch>
      </Route>
      <Route path={`${path}/:org_unit_id/${Reporting.SubmissionTracker}/:submission_tracker_type`}>
        <SubmissionTracker />
      </Route>

      <DefaultLayout dashboardType='media-investment'>
        <Route path={`${path}/:org_unit_id/${Reporting.MediaInvestment}`}>
          <ReportingContainer>
            <Sidebar tabs={SIDEBAR_TABS_MEDIA_INVESTMENT} />
            <Switch>
              {MEDIA_INVESTMENT_ROUTES.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={
                      `${path}/:org_unit_id/${Reporting.MediaInvestment}` +
                      route.path
                    }>
                    <DashboardLayout
                      reports={route.props}
                      dashboardType='filtersMedia'
                      filters={POSSIBLE_FILTERS_MEDIA_INVESTMENT}
                    />
                  </Route>
                )
              })}
              <Redirect
                to={`${path}/:org_unit_id/${Reporting.MediaInvestment}${MEDIA_INVESTMENT_ROUTES[0].path}`}
              />
            </Switch>
          </ReportingContainer>
        </Route>
        <Route path={`${path}/:org_unit_id/${Reporting.CompetitiveInvestment}`}>
          <ReportingContainer>
            <Sidebar tabs={SIDEBAR_TABS_COMPETITIVE_INVESTMENT} />
            <Switch>
              {COMPETITIVE_INVESTMENT_ROUTES.map((route, index) => {
                // NOTE - we currently use the same storage key for both SOS and SOV variant endpoints,
                // as we need to fulfil the requirement to persist the common subset of filters between
                // views.
                // We effectively handle this in the query layer, where we selectively apply the filters
                // that are valid for either of SOS/SOV.
                const isShareOfSpend = route.path.includes('share-of-spend')
                const dashboardType: DashboardType = 'filtersCompetitive'
                // const dashboardType: DashboardType = (
                //   isShareOfSpend ?
                //     'filtersCompetitiveSOS' :
                //     'filtersCompetitiveSOV'
                // )
                // NOTE ALSO - we use the 'possible filters' for either variant as detected, as
                // not all filters currently exposed can be applied to both
                const filters: FilterItem[] = (
                  isShareOfSpend ?
                    POSSIBLE_FILTERS_COMPETITIVE_SOS :
                    POSSIBLE_FILTERS_COMPETITIVE_SOV
                )

                const requiredGlobalPrivileges = route.requiredGlobalPrivileges || [
                  'mediaInvestment.viewAny'
                ]

                const requiredOrgPrivileges = route.requiredOrgPrivileges || [
                  'mediaInvestment.competitive.view'
                ]

                return (
                  <RestrictedRoute
                    key={index}
                    fallbackRedirect={(location) => {
                      const { pathname } = location
                      return [
                        ...pathname.split('/').slice(0, 3),
                        Reporting.MediaInvestment,
                        MEDIA_INVESTMENT_ROUTES[0].path,
                      ].join('/')
                    }}
                    requiredGlobalPrivileges={requiredGlobalPrivileges}
                    requiredOrgPrivileges={requiredOrgPrivileges}
                    orgUnitId={(location) => {
                      return location.pathname.split('/')[2]
                    }}
                    path={
                      `${path}/:org_unit_id/${Reporting.CompetitiveInvestment}` +
                      route.path
                    }>
                    <DashboardLayout
                      reports={route.props}
                      dashboardType={dashboardType}
                      filters={filters}
                    />
                  </RestrictedRoute>
                )
              })}
              <Redirect
                to={`${path}/:org_unit_id/${Reporting.CompetitiveInvestment}${COMPETITIVE_INVESTMENT_ROUTES[0].path}`}
              />
            </Switch>
          </ReportingContainer>
        </Route>
      </DefaultLayout>
      <Route component={PageNotFound} />
    </Switch>
  )
}

export default ReportingRoutes
