import { MenuOption } from '@percept/mui'

import { AdvertisingPartner } from '@percept/types'


export const partners: MenuOption<AdvertisingPartner>[] = [
  {value: 'GOOGLE', label: 'GOOGLE'},
  {value: 'META', label: 'META'},
  {value: 'TIKTOK', label: 'TIKTOK'},
  {value: 'AMAZON', label: 'AMAZON'},
  {value: 'SPOTIFY', label: 'SPOTIFY'},
  {value: 'TEADS', label: 'TEADS'}
]

export const scoreboardHeaders = [
  'Desired Brand Outcome',
  'Reach',
  'Frequency',
  'Broad Targeting',
  'Sustained Duration',
  'Multiple Formats',
  'Effective Creative',
  'Amplify Other Voices',
  'Create Deeper Experiences',
  'Increase Relevance',
  'Measurement Approach',
]

export const youtubeHeaders = [
  'Score', 'Global average'
]

export const graphDomains = [
  {value: 'audience_index', label: 'Audience Index'},
  {value: 'account_format_mix', label: 'Account Format Mix'}, 
  {value: 'measurement_index', label: 'Measurement Index'}, 
  {value: 'campaign_set_up', label: 'Campaign Set Up'}, 
  {value: 'campaign_count', label: 'Campaign Count'}, 
]