import * as localForage from 'localforage'


export const makeStorageGetter = <T>(key: string) => (): Promise<T> => (
  new Promise( (resolve, reject) => {
    localForage.getItem<T>(key).then( value => {
      if( !value ) return reject()
      return resolve(value)
    }).catch(reject)
  })
)

export const makeStorageSetter = <T>(key: string) => ( value: T): Promise<T> => (
  new Promise( (resolve, reject) => {
    localForage.setItem<T>(key, value).then( response => {
      if( !response && response !== value ) return reject()
      return resolve(response)
    }).catch(reject)
  })
)

export const makeStorageDeletor = (key: string) => (): Promise<void> => (
  new Promise( (resolve, reject) => {
    return localForage.removeItem(key).then(resolve).catch(reject)
  })
)
