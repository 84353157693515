
import {
  Reducer,
  ReduxAction,
} from '@percept/types'


const createKeyedReducer = <T, K extends string = string>(
  reducer: Reducer<T>,
  getKey: (a: ReduxAction) => K | null
): Reducer<Record<K, T>> => {

  if( typeof reducer !== 'function' ){
    throw new Error('You must provide a reducer function as first argument to createKeyReducer()')
  }

  if( typeof getKey !== 'function' ){
    throw new Error(
      'You must provide a function as second argument to createKeyReducer()'
      +' to determine the reducer key from the action'
    )
  }

  return (state: Record<K, T> = {} as Record<K, T>, action, ...args): Record<K, T> => {
    const key = getKey(action)
    if( key ){
      return { ...state, [key]: reducer(state[key], action, ...args) }
    }
    return state
  }

}


export default createKeyedReducer
