import React, { useMemo } from 'react'

import { makeAppStyles } from '@percept/mui'

import {
  SmartTable,
  SmartTableProps,
  SmartTableColumn,
  smartTableComponents,
} from '@percept/app-components'

import { performanceReportingDimensionOrder, dimensionMap } from '@percept/constants'

import { PlatformUnitProviderPerformance, PerformanceReportSegmentation, DerivedAggregationPeriod } from './typings'

import { uniqBy } from 'lodash-es'

import { isMonetaryDimension, isPercentageDimension, isRateBasedDimension, separateThousands } from '@percept/utils'


const useStyles = makeAppStyles( theme => ({
  nullValue: {
    color: theme.palette.text.hint,
  },
  providerLogo: {
    position: 'relative',
    top: 3,
  },
}))


const primarySmartTableColumns: SmartTableColumn<PlatformUnitProviderPerformance>[] = [
  {
    key: 'org_unit_name',
    name: 'Organisation',
    label: 'Organisation',
    pinned: true,
    filter: false,
    renderShortcut: 'bold',
  },
  {
    key: 'provider',
    name: 'Provider',
    label: 'Provider',
    renderShortcut: 'provider',
    filter: false,
  },
  {
    key: 'currency',
    name: 'Currency',
    display: 'excluded',
    download: true,
    filter: false,
    searchable: false,
  },
]


const wastageSmartTableColumns: SmartTableColumn<PlatformUnitProviderPerformance>[] = [
  {
    key: 'potential_efficiency_cost',
    name: 'Wastage',
    label: 'Wastage',
    renderShortcut: 'currency',
    filter: false,
    searchable: false,
  },
]


const performanceSmartTableColumns: SmartTableColumn<PlatformUnitProviderPerformance>[] = (
  performanceReportingDimensionOrder.map( key => ({
    key,
    name: dimensionMap[key].text,
    renderShortcut: (
      isMonetaryDimension(key) ?
        'currency' :
        (isRateBasedDimension(key) || key === 'ctr') ?
          'rate-based' :
          isPercentageDimension(key) ?
            'percentage' :
            'numeric'
    )
  }) as SmartTableColumn<PlatformUnitProviderPerformance> )
)


const smartTableColumnsWithNetwork: SmartTableColumn<PlatformUnitProviderPerformance>[] = [
  ...primarySmartTableColumns,
  {
    key: 'network',
    name: 'Network',
    label: 'Network',
  },
  ...performanceSmartTableColumns,
]

const smartTableColumnsWithSubNetwork: SmartTableColumn<PlatformUnitProviderPerformance>[] = [
  ...primarySmartTableColumns,
  {
    key: 'sub_network',
    name: 'Channel',
    label: 'Channel',
  },
  ...performanceSmartTableColumns,
]

const smartTableColumnsWithCampaignObjective: SmartTableColumn<PlatformUnitProviderPerformance>[] = [
  ...primarySmartTableColumns,
  {
    key: 'campaign_objective',
    name: 'Campaign Objective',
    label: 'Campaign Objective',
  },
  ...performanceSmartTableColumns,
]


const defaultSmartTableColumns: SmartTableColumn<PlatformUnitProviderPerformance>[] = [
  ...primarySmartTableColumns,
  ...performanceSmartTableColumns,
]


const aggregationSmartTableColumns: SmartTableColumn<PlatformUnitProviderPerformance>[] = [
  {
    key: 'start',
    name: 'Period Start',
    label: 'Period Start',
    renderShortcut: 'date',
    download: true,
    filter: false,
    searchable: false,
  },
  {
    key: 'end',
    name: 'Period End',
    label: 'Period End',
    renderShortcut: 'date',
    download: true,
    filter: false,
    searchable: false,
  },
]


type PerformanceReportDataTableProps = {
  performanceReport: PlatformUnitProviderPerformance[]
  segmentation: PerformanceReportSegmentation | null
  aggregationPeriod: DerivedAggregationPeriod | null
} & Omit<SmartTableProps<PlatformUnitProviderPerformance>, 'columns' | 'rows' | 'renderers'>


export const PerformanceReportDataTable = ({
  performanceReport,
  segmentation,
  aggregationPeriod,
  ...props
}: PerformanceReportDataTableProps): JSX.Element => {

  const classes = useStyles()

  const nullValue = (
    <span className={classes.nullValue}>
      ––
    </span>
  )

  const notApplicableValue = (
    <span className={classes.nullValue}>
      N / A
    </span>
  )

  const valueOrNullRenderer = (value: string | undefined): React.ReactNode => (
    value || nullValue
  )
  
  const currency = performanceReport[0] && performanceReport[0].currency

  const activeColumns = useMemo(() => {
    const columns = (
      segmentation === 'network' ?
        smartTableColumnsWithNetwork :
        segmentation === 'sub_network' ?
          smartTableColumnsWithSubNetwork :
          segmentation === 'campaign_objective' ?
            smartTableColumnsWithCampaignObjective :
            defaultSmartTableColumns
    )
    if( aggregationPeriod ){
      columns.splice(1, 0, ...aggregationSmartTableColumns)
    }
    if( segmentation === null ){
      columns.splice(4, 0, ...wastageSmartTableColumns)
    }
    return uniqBy(columns, 'key')
  }, [segmentation, aggregationPeriod])

  return (
    <SmartTable
      {...props}
      currency={currency}
      components={smartTableComponents}
      renderers={{
        network: valueOrNullRenderer,
        sub_network: valueOrNullRenderer,
        conversions: (value): React.ReactNode => (
          value === 'N/A' ?
            notApplicableValue :
            value === null ?
              nullValue :
              separateThousands(value)
        )
      }}
      items={performanceReport}
      columns={activeColumns}
      nullValueContent={nullValue} />
  )
}
