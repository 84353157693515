import { Box, makeAppStyles, useAppTheme } from '@percept/mui'
import { ResponsiveMultiLine, getMoneyFormatter, percentageFormatter } from '@percept/mui/charts'
import { LegendOrdinal } from '@visx/legend'
import React from 'react'
import { Summary } from '../DashboardLayout'
import { ListItem } from '../types'
import { CHART_HEIGHT } from './constants'
import { useChartClasses, visxLegendStyles } from './styles'

const useStyles = makeAppStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: '20px',
    height: CHART_HEIGHT,
    backgroundColor: theme.palette.background.paper,
    padding: '40px 20px 40px 20px',
  },
}))

export const MultiLineChart = ({
  data,
  dataFormat = 'percentage',
}: {
  data: ListItem[]
  dataFormat?: string
}): JSX.Element => {
  const appTheme = useAppTheme()
  const classes = useStyles()
  const chartClasses = useChartClasses()

  const groupData = data.find((el) => el.row_group === Summary.AllTotal)

  const ordinalDomain =
    groupData && groupData.data ?
      groupData.data.map( d => d.data_type) :
      data.map(d => d.row_group)

  const colorScale = appTheme.chart.getOrdinalColourScale(ordinalDomain)

  const transformedData = groupData
    ? groupData.data
      ? groupData.data.map((el, idx) => ({
        color: colorScale(el.data_type),
        key: String(idx),
        label: el.data_type,
        data: el.costs.map((el) => ({
          label: el.type_value,
          value: dataFormat === 'currency' ? +el.spend : +el.percent,
        })),
      }))
      : []
    : data.map((el, idx) => ({
      color: colorScale(el.row_group),
      key: String(idx),
      label: el.row_group,
      data: el.costs.map((el) => ({
        label: el.type_value,
        value: dataFormat === 'currency' ? +el.spend : +el.percent,
      })),
    }))

  const isFinancialYear = transformedData.some((e) =>
    e.data.some((e) => e.label.includes('FY'))
  )

  const dataset = isFinancialYear
    ? transformedData.map((el) => ({
      ...el,
      data: el.data.map((e) => {
        return { ...e, label: e.label.slice(2) }
      }),
    }))
    : transformedData

  const yTickFormatter =
    dataFormat === 'currency' ?
      getMoneyFormatter(data[0].total_currency) :
      percentageFormatter

  return (
    <div className={classes.root}>
      <ResponsiveMultiLine
        possibleNegativeValues={true}
        datasets={dataset}
        axisLine
        roundYDomain
        grid={true}
        axisText
        numXTicks={dataset[0].data.length}
        numYTicks={5}
        yTickFormatter={yTickFormatter}
        xTickFormatter={(x): string => {
          const startYear = +x.toString().slice(0, 2)
          return isFinancialYear ? `FY${startYear}${startYear + 1}` : x
        }}
      />
      <Box className={chartClasses.legend}>
        <LegendOrdinal
          style={visxLegendStyles}
          scale={colorScale}
          direction='row'
          labelMargin='0 30px 0 0'
        />
      </Box>
    </div>
  )
}
