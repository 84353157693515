import React from 'react'

import { OverviewAnalysisItem } from './typings'

import { BrandGrowthDatum } from 'components/BrandGrowthQuadrantChart'


export const marketBrandGrowthData: BrandGrowthDatum[] = [
  {
    label: 'UK',
    value: 'SCALE',
    previousValue: 'SCALE',
  },
  {
    label: 'TR',
    value: 'SCALE',
    previousValue: 'SCALE',
  },
  {
    label: 'RO',
    value: 'SCALE',
    previousValue: 'SCALE',
  },
  {
    label: 'ES',
    value: 'EVANGELIZE',
    previousValue: 'EVANGELIZE',
  },
  {
    label: 'IT',
    value: 'ENHANCE',
    previousValue: 'EVANGELIZE',
  },
  {
    label: 'DE',
    value: 'ENHANCE',
    previousValue: 'ENHANCE',
  },
  {
    label: 'ZA',
    value: 'ENHANCE',
    previousValue: 'ENHANCE',
  },
  {
    label: 'CZ',
    value: 'SCALE',
    previousValue: 'ENHANCE',
  },
  {
    label: 'IE',
    value: 'SUSTAIN',
    previousValue: 'ENHANCE',
  },
  {
    label: 'GR',
    value: 'ENHANCE',
    previousValue: 'ENHANCE',
  },
  {
    label: 'PT',
    value: 'SUSTAIN',
    previousValue: 'SUSTAIN',
  },
  {
    label: 'EG',
    value: 'SUSTAIN',
    previousValue: 'SUSTAIN',
  },
  {
    label: 'AL',
    value: 'SUSTAIN',
    previousValue: 'SUSTAIN',
  },
]


export const multiMarketAnalysis: OverviewAnalysisItem[] = [
  {
    label: 'Strategy',
    texts: [
      <>
        Ireland joined the “Sustain” area and Italy moved from “Evangelize” to “Enhance” segment. 
        Czech Republic now in “Scale” area by losing leadership on Non-User Consideration.
      </>
    ],
  },
  {
    label: 'Optimisation',
    texts: [
      <>
        Media wastage has decreased 14% vs Q4 but it still accounts for 5% of total digital investment. 
        Although we&apos;ve seen improvements across almost all markets, Italy and Greece have gotten worse.
        Google Ads account for almost 2/3 of total media wastage, which can be reduced by following the 
        specific best practices identified on the Media Quality Dashboard.
      </>,
      <>
        For Creative Quality, some markets especially Spain, UK, Ireland, Portugal, and Greece, 
        demonstrated strong double digit CQS. However, some still present very low CQS against the 80% Target. 
        Simple Messaging remains the biggest opportunity area to improve and adhere to Platforms Best Practices.
      </>
    ],
  },
  {
    label: 'Reporting',
    texts: [
      <>
        SOS: 6 markets decline YOY, 5 markets increase, however 7 markets are on track regarding their target. 
        Greece not reprting due to media law. TK is not spending in ATL due to earthquake.
      </>,
      <>
        Brand Share: 7 markets increased brand share, 6 markets decreased. 7 markets are investing below the ambition of 60% brand share.
      </>,
    ],
  },
]
