
import { StoreState, DeeplinkParameters } from '@percept/types'


const EMPTY_OBJECT = {}


export const getDeeplinkParameters = (state: StoreState): DeeplinkParameters => {
  return state.chromeExtension.deepLinkParameters || EMPTY_OBJECT
}
