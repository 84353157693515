import React from 'react'
import { Box } from '@material-ui/core'
import { makeAppStyles } from '@percept/mui'
import { Step as IStep } from '../../../api/report-messages'
import { StepItem } from './StepItem'

interface StepProps {
  step: IStep
}

const useStyles = makeAppStyles((theme) => ({
  cards: {
    display: 'grid',
    gap: '1rem',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '3rem',
    },
  },
}))

export function Step({
  step: { status, guidance, how_to, is_on_track },
}: StepProps): JSX.Element {
  const classes = useStyles()

  return (
    <Box className={classes.cards}>
      {status && (
        <StepItem title='Status' label={status} isOnTrack={is_on_track} />
      )}
      {guidance && (
        <StepItem title='Guidance' label={guidance} isOnTrack={is_on_track} />
      )}
      {how_to && (
        <StepItem title='How to' label={how_to} isOnTrack={is_on_track} />
      )}
    </Box>
  )
}
