
import { combineReducers } from 'redux'

import active from './active'
import byId from './byId'


export default combineReducers({
  active,
  byId,
})
