
import { createApiReducer } from '@percept/redux/reducers'

import { LOAD_SERIES_GROUP_DETAIL } from '../../actions'

import { seriesGroupDetail } from '@percept/api/adapters'

import { Reducer, SeriesGroupWrapper } from '@percept/types'


const detail: Reducer<SeriesGroupWrapper['detail']> = createApiReducer(LOAD_SERIES_GROUP_DETAIL, {
  getPayloadFromAction: ({ payload }) => seriesGroupDetail(payload || {})
})

export default detail
