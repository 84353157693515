
import { annotation as annotationAdapter } from '@percept/api'

import {
  getPath,
  flatten,
  zip,
  takeWhile,
  sortBy,
} from '@percept/utils'

import {
  Dictionary,
  Annotation,
  AnnotationPoint,
  AnnotationPoints,
  AnnotationLevel,
  AnnotationObject,
  AnnotationHierarchy,
} from '@percept/types'


const annotationPointMap: Record<AnnotationLevel, AnnotationPoint> = {
  'SERIES': 'series_id',
  'REPORT': 'report_id',
  'METRIC': 'metric_id',
  'METRIC_DIMENSION': 'dimension',
  'METRIC_SEGMENT': 'segment',
}

const annotationHierarchyOrder: AnnotationLevel[] = [
  'SERIES', 'REPORT', 'METRIC', 'METRIC_DIMENSION', 'METRIC_SEGMENT'
]


export const getAnnotationHierarchyObjectPath = (
  params: AnnotationPoints,
  order: AnnotationLevel[] = annotationHierarchyOrder
  ): (AnnotationLevel | string)[] => (
  flatten(
    ...zip(
      order,
      order.map(
        level => params[annotationPointMap[level]]
      )
    ).filter(
      ([, param]) => !!param
    ) as [AnnotationLevel, string][]
  )
)


const annotationLevels: AnnotationLevel[] = [
  'SERIES', 'REPORT', 'METRIC', 'METRIC_DIMENSION', 'METRIC_SEGMENT'
]

/** 'Upsert' a hierarchy object into the passed in `hierarchy`. Mutates `hierarchy` */
export const upsertHierarchyObject = (
  hierarchy: AnnotationHierarchy,
  annotation: Annotation
) => {
  const objectPath = getAnnotationHierarchyObjectPath(annotation)
  const entityAnnotationObject: AnnotationObject | null = getPath(hierarchy, objectPath)

  if( !entityAnnotationObject ){
    // Set the missing entry on the parent hierarchy object
    const { level } = annotation

    const parentOrder = takeWhile(
      annotationHierarchyOrder,
      l => l !== level
    )
    const parentObjectPath = getAnnotationHierarchyObjectPath(annotation, parentOrder)

    let ref: Dictionary = hierarchy

    parentObjectPath.forEach( (k: AnnotationLevel | string) => {
      ref[k] = (ref[k] || {}) as AnnotationObject
      ref = ref[k]
    })

    const parentObject: Dictionary = getPath(hierarchy, parentObjectPath)

    parentObject[level] = (parentObject[level] || {}) as Dictionary

    const entityKey = annotation[annotationPointMap[level]] as string

    parentObject[level][entityKey] = {
      annotations: []
    } as AnnotationObject

    return parentObject[level][entityKey] as AnnotationObject

  }

  return entityAnnotationObject
}


export const parseHierarchy = (payload: any): AnnotationHierarchy => {
  const hierarchy: AnnotationHierarchy = { SERIES: {} }

  payload = payload || []

  payload.forEach( (a: any) => {
    const annotation = annotationAdapter(a)
    const annotationObject = upsertHierarchyObject(hierarchy, annotation)
    annotationObject.annotations = sortBy(
      (annotationObject.annotations || []).concat([annotation]),
      'created_at'
    )
  })

  return hierarchy
}
