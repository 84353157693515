import React, { Fragment, useMemo } from 'react'

import {
  Alert,
  BackdropLoader,
  Box,
  Grid,
  makeAppStyles,
  Typography,
} from '@percept/mui'

import { PageHeader } from 'components/PageHeader'

import { MetricList, MetricListHeader } from './MetricList'

import { ReportSummary } from './ReportSummary'

import { ReportLink } from './ReportLink'

import { SeriesHistory } from './SeriesHistory'

import { SeriesSettingsMenu } from './SeriesSettingsMenu'

import {
  useNavigation,
  useFilteredMetricMetadata,
  useReportSeries,
  useReportSeriesReport,
  useReportSeriesReports,
  useReportSeriesReportEntity,
  useReportSeriesReportPayload,
  useReportLayoutPayload,
} from '@percept/hooks'

import { useLayoutGridSpacing } from 'hooks'

import { useActivePlatformUnit } from 'components/Organisation'

import { useMetricLists } from './useMetricLists'

import { get, some, sortBy } from 'lodash-es'

import { coerceReportProvider, isLoading } from '@percept/utils'

import { ApiResponse, MetricIdentification, SeriesParams } from '@percept/types'


const useStyles = makeAppStyles( theme => ({
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  seriesGroupName: {
    color: theme.palette.text.primary,
  },
  separator: {
    fontSize: 24,
    margin: theme.spacing(0, 0.5),
    color: theme.palette.action.disabled,
  },
  seriesGroupChip: {
    marginRight: theme.spacing(2),
    fontSize: 22,
    padding: theme.spacing(2.5, 0.5),
    borderRadius: 22,
    cursor: 'pointer',
  },
  divider: {
    margin: theme.spacing(4, 0)
  },
  grid: {
    marginTop: theme.spacing(-0.5),
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    height: 24,
    marginBottom: theme.spacing(3),
  },
  metricList: {
    marginBottom: theme.spacing(2),
  },
  seriesHistory: {
    width: '100%',
  },
}))


const oAuthEnabledPlatformUnitIds: string[] = [
  '2536597406288446468',
]


export const Series = ({ series_id }: SeriesParams): JSX.Element => {

  const activePlatformUnit = useActivePlatformUnit()

  const [series] = useReportSeries({
    series_id,
  })

  const [reports] = useReportSeriesReports({
    series_id,
  })

  const completedReports = useMemo(() => {
    return sortBy(reports.data, 'end').filter( r => !!r.results.length )
  }, [reports.data])


  const { report_id, previous_report_id } = useMemo(() => {
    const sortedReports = sortBy(
      completedReports,
      'end'
    ).reverse()
    
    return {
      report_id: get(sortedReports, [0, 'id'], null),
      previous_report_id: get(sortedReports, [1, 'id'], null),
    }
  }, [completedReports])


  const [report] = useReportSeriesReport({
    series_id,
    report_id,
  })
  const [previousReport] = useReportSeriesReport({
    series_id,
    report_id: previous_report_id,
  })

  const [payload] = useReportSeriesReportPayload({
    series_id,
    report_id,
  })
  const [previousPayload] = useReportSeriesReportPayload({
    series_id,
    report_id: previous_report_id,
  })

  const [reportEntity] = useReportSeriesReportEntity({
    series_id,
    report_id,
    entity_id: payload.data && payload.data.root_entity_id,
    entity_type: payload.data && payload.data.root_entity_type,
  })

  const [previousReportEntity] = useReportSeriesReportEntity({
    series_id,
    report_id: previous_report_id,
    entity_id: previousPayload.data && previousPayload.data.root_entity_id,
    entity_type: previousPayload.data && previousPayload.data.root_entity_type,
  })

  const provider = series.data && coerceReportProvider(series.data.provider.slug)
  const report_layout_id = series.data && series.data.layout_id

  const [metricMetadata] = useFilteredMetricMetadata({ provider })

  const [layout] = useReportLayoutPayload({ report_layout_id })

  const metricLists = useMetricLists({
    metrics: reportEntity.data && reportEntity.data.metrics,
    previousMetrics: previousReportEntity.data && previousReportEntity.data.metrics,
    metadata: metricMetadata.data,
    layout: layout.data,
  })

  const navigate = useNavigation()

  const onMetricClick = (metric: MetricIdentification): void => {
    if( report_id ){
      /**
       * NOTE - include `percept_referrer` QS param with a value of 'series' to
       * signal to `ReportDashboardDriver` to return to this view from metric deeplinks.
       */
      navigate(`/series/${series_id}/reports/${report_id}`, {
        search: {
          metric_id: metric.metric_id,
          percept_referrer: 'series'
        }
      })
    }
  }

  const hasReports = !!(completedReports && completedReports.length)
  
  const loadingSignals: ApiResponse[] = [
    series,
    reports,
    layout,
    ...(hasReports && [
      report,
      reportEntity,
      metricMetadata,
    ] || []),
    hasReports && previous_report_id && previousReport || null,
  ].filter( (a): a is ApiResponse => a !== null )

  const loading = some(loadingSignals, isLoading)

  const classes = useStyles()

  const gridSpacing = useLayoutGridSpacing()

  const isOAuthEnabled = (
    activePlatformUnit && oAuthEnabledPlatformUnitIds.includes(activePlatformUnit.id)
    && provider === 'adwords'
  )

  return (
    <Box px={3} py={4}>

      <PageHeader />

      <Grid container className={classes.grid} spacing={gridSpacing}>

        { series.error && (
          <Grid item xs={12}>
            <Alert {...series.error} />
          </Grid>
        )}

        { (
          !loading && report.data && metricMetadata.data && 
          reportEntity.data && layout.data && completedReports
        ) && (
          <Fragment>

            {/* TODO - OAuth not implemented in V2 API */}
            {/* { isOAuthEnabled && series.data && (
              <Grid item xs={12}>
                <SeriesSettingsMenu
                  series={series.data} />
              </Grid>
            )} */}

            <Grid item xs={12} md={6} lg={5} xl={4}>

              <Grid container spacing={gridSpacing}>

                <Grid item xs={12}>

                  <MetricListHeader
                    metricList={metricLists.priority}
                    className={classes.cardTitle}>
                    Priority
                  </MetricListHeader>

                  <MetricList
                    className={classes.metricList}
                    BoxProps={{
                      maxHeight: '23rem',
                    }}
                    onMetricClick={onMetricClick}
                    health={0.5}
                    metrics={metricLists.priority}
                    metadata={metricMetadata.data}
                    payload={reportEntity.data.metrics}
                    entity={reportEntity.data}
                    impactWeighted={false} // TODO!
                    layout={layout.data} />

                </Grid>

                <Grid item xs={12}>

                  <MetricListHeader
                    metricList={metricLists.worst}
                    className={classes.cardTitle}>
                    Trending Down
                  </MetricListHeader>

                  <MetricList
                    className={classes.metricList}
                    onMetricClick={onMetricClick}
                    health={0}
                    metrics={metricLists.worst}
                    metadata={metricMetadata.data}
                    payload={reportEntity.data.metrics}
                    entity={reportEntity.data}
                    impactWeighted={false} // TODO!
                    layout={layout.data} />

                </Grid>

                <Grid item xs={12}>

                  <MetricListHeader
                    metricList={metricLists.best}
                    className={classes.cardTitle}>
                    Trending Up
                  </MetricListHeader>

                  <MetricList
                    className={classes.metricList}
                    onMetricClick={onMetricClick}
                    health={1}
                    metrics={metricLists.best}
                    metadata={metricMetadata.data}
                    payload={reportEntity.data.metrics}
                    entity={reportEntity.data}
                    impactWeighted={false} // TODO!
                    layout={layout.data} />

                </Grid>

              </Grid>

            </Grid>

            <Grid item xs={12} md={6} lg={7} xl={8}>

              <Typography variant='h5' className={classes.cardTitle}>
                { completedReports.length > 1 ? (
                  'History'
                ) : (
                  'Latest Report'
                )}

                { hasReports && report.data && series_id && (
                  <ReportLink
                    series_id={series_id}
                    report={report.data} />
                )}
              </Typography>

              { completedReports.length > 1 ? (
                <SeriesHistory
                  className={classes.seriesHistory}
                  reports={completedReports}
                  gridSpacing={gridSpacing} />
              ) : (
                <ReportSummary
                  report={report.data} />
              )}
            </Grid>

          </Fragment>
        )}

      </Grid>

      <BackdropLoader
        BackdropProps={{
          open: loading,
        }}  />

    </Box>
  )
}

export default Series
