import React, { useContext } from 'react'

import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  InputLabel,
  ListItemText,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  TextField,
  Typography,
  makeAppStyles,
  TextFieldProps,
} from '@percept/mui'

import { ReportSchemaContext } from '../../../contexts'

import {
  FieldsetQuestion,
  InputFormat,
  InputQuestion,
  RadioQuestion,
  SelectQuestion,
} from '../../../api/report-schemas'

import { format } from 'date-fns'

import { PrefillPlaceholder } from 'components/BrandGrowthPlanner/api/types'


const useQuestionStyles = makeAppStyles(() => ({
  prefillNote: {
    color: 'rgba(0, 0, 0, 0.7)',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
  },
}))

type QuestionFieldControlProps = {
  value: unknown
  onChange: (value: unknown) => void
}

type QuestionProps = {
  question: FieldsetQuestion
} & QuestionFieldControlProps


const InputQuestionComponent = (props: InputQuestion & QuestionFieldControlProps): JSX.Element => {
  const textFieldProps: Partial<TextFieldProps> = props.input_type === 'number' ? {
    inputProps: {
      min: 0
    }
  } : {}
  return (
    <TextField
      {...props.attrs}
      label={props.label}
      type={props.input_type}
      name={props.id}
      value={props.value}
      onChange={(e): void => {
        if( props.input_type === 'number' && Number(e.target.value) < 0 ){
          // Explicitly disallow setting negative values. Min works in number inputs when keyboard
          // shortcuts are used for up/down, but you can still type a minus sign and get past it.
          props.onChange(0)
        }else{
          props.onChange(e.target.value)
        }
      }}
      required={props.is_required}
      disabled={props.is_readonly}
      variant='outlined'
      helperText={props.help_text}
      fullWidth
      InputProps={{
        readOnly: props.is_readonly,
        ...(props.input_format && {
          endAdornment: (
            <InputAdornment position='start'>
              {props.input_format == InputFormat.PERCENT && '%'}
            </InputAdornment>
          ),
        }),
      }}
      {...textFieldProps}
    />
  )
}

const RadioQuestionComponent = (props: RadioQuestion & QuestionFieldControlProps): JSX.Element => {
  return (
    <FormControl component='fieldset' required={props.is_required} fullWidth>
      <FormLabel component='legend'>{props.label}</FormLabel>
      <RadioGroup
        value={props.value || ''}
        onChange={(_, value): void => {
          props.onChange(value)
        }}>
        {props.options.map((option, oIndex) => (
          <FormControlLabel
            key={`${props.id}-${oIndex}`}
            control={
              <Radio
                {...option.attrs}
                name={props.id}
                value={option.label}
                color='primary'
              />
            }
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

const SelectQuestionComponent = (props: SelectQuestion & QuestionFieldControlProps): JSX.Element => {
  return (
    <FormControl variant='outlined' required={props.is_required} fullWidth>
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      <Select
        value={props.value || ''}
        onChange={(e): void => {
          props.onChange(e.target.value)
        }}
        required={props.is_required}
        disabled={props.is_readonly}
        label={props.label}
        inputProps={{ id: props.id, name: props.id }}>
        {props.options.map((option, oIndex) => (
          <MenuItem
            key={`${props.id}-${oIndex}`}
            {...option.attrs}
            value={option.label}>
            <ListItemText
              primary={option.label}
              secondary={option.description} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}


const QuestionField = (props: FieldsetQuestion & QuestionFieldControlProps): JSX.Element | null => {
  switch(props.question_type){
    case 'radio': return <RadioQuestionComponent {...props} />
    case 'select': return <SelectQuestionComponent {...props} />
    case 'input': return <InputQuestionComponent {...props} />
    default: return null
  }
}


const formatPrefillPeriod = (prefill: PrefillPlaceholder): string => {
  const date = new Date(prefill.date)
  if( prefill.period_format === 'CALENDAR_QUARTER' ){
    const formatter = 'QQQ-yy'
    return `${format(date, formatter)} - Calendar`
  }
  if( prefill.period_format === 'MONTH_YEAR' ){
    const formatter = 'MMM-yy'
    return format(date, formatter)
  }
  // Financial quarter
  const zeroIndexedMonth = date.getUTCMonth()
  const financialQuarter = (
    zeroIndexedMonth < 3 ?
      4 :
      Math.floor(zeroIndexedMonth / 3)
  )
  let YY = Number(format(date, 'yy'))
  if( zeroIndexedMonth < 3 ){
    YY -= 1
  }
  return `FY Q${financialQuarter}-${YY}/${YY + 1}`
}


export function Question({ question, value, onChange }: QuestionProps): JSX.Element | null {
  const { prefillPlaceholders } = useContext(ReportSchemaContext)
  const classes = useQuestionStyles()

  const { prefill_key } = question

  const prefill = prefillPlaceholders.find(
    (prefill) => prefill.key === prefill_key
  )

  return (
    <div>
      <QuestionField
        {...question}
        value={prefill ? prefill.value : value}
        onChange={onChange} />

      {prefill && (
        <Box mt={0.5}>
          <Typography className={classes.prefillNote}>
            Source: {formatPrefillPeriod(prefill)} - {prefill.source}
          </Typography>
        </Box>
      )}
    </div>
  )
}
