import React from 'react'

import {
  Box,
  DialogProps,
  IconButton,
  PerceptAppBar,
  Typography,
} from '@percept/mui'

import { ArrowBack } from '@percept/mui/icons'

import {
  useFilteredMetricMetadata,
  useReportSeriesMetricTimeseries,
  useReportSeriesReportMetric,
} from '@percept/hooks'

import { DefaultBackgroundDialog } from './DefaultBackgroundDialog'

import { MetricDetail, MetricDetailProps } from '../ReportDashboardApp'

import { get } from 'lodash-es'

import { makeSeriesPropLoader } from '../../utils'

import { ReportProvider } from '@percept/types'


export type MetricDetailDialogProps = (
  Omit<
    MetricDetailProps,
    'metadata' | 'provider' | 'useMetric' | 'useSeriesMetric'
  > & {
    title?: string | null
    provider: ReportProvider | null
    DialogProps: Partial<Omit<DialogProps, 'onClose'>> & {
      onClose: () => void
    }
  }
)


const useMetric = makeSeriesPropLoader(useReportSeriesReportMetric)


export const MetricDetailDialog = ({ DialogProps, title, ...props }: MetricDetailDialogProps): JSX.Element => {

  const [metadata] = useFilteredMetricMetadata({ provider: props.provider })

  title = title || (
    props.metric_id &&
    get(metadata.data, [props.metric_id, 'title'])
  )

  return (
    <DefaultBackgroundDialog
      open
      fullScreen
      scroll='paper'
      {...DialogProps}>
      
      <PerceptAppBar
        color='secondary'
        startIcon={
          <IconButton
            color='inherit'
            onClick={DialogProps.onClose}>
            <ArrowBack />
          </IconButton>
        }>
        { title && (
          <Box ml={2}>
            <Typography variant='h5'>
              { title }
            </Typography>
          </Box>
        )}
      </PerceptAppBar>
      
      <Box pt={10} px={3}>
        <MetricDetail
          metadata={metadata}
          useMetric={useMetric}
          useSeriesMetric={useReportSeriesMetricTimeseries}
          {...props} />
      </Box>
    </DefaultBackgroundDialog>
  )
}
