import React, { Fragment } from 'react'

import {
  Box,
  Chip,
  makeAppStyles,
  Metric,
  TextMetricVisualisation,
  Tooltip,
  ChipProps,
  TextMetricVisualisationProps,
  NumberValueProps,
  ListValueProps,
  TextValueProps,
  AppTheme,
} from '@percept/mui'

import {
  DSLItem,
  DSLItemEntityCount,
  DSLItemRecommendedAction,
  DSLText,
  useDSL,
} from './dsl'

import { structuralEntityTypeLabelMap } from '@percept/constants'

import { orderBy } from 'lodash-es'

import { parseDimension, separateThousands } from '@percept/utils'

import { DimensionType, EntityType, Metric as MetricType, ProportionMetric, ValueMetric } from '@percept/types'


export type DSLRendererDependencies = {
  dimension?: DimensionType | null
  currency?: string | null
  performanceTail?: string | null
  entityCountColor?: string
  showEntityCountChip?: boolean
  recommendedActionColor?: string
  textColor?: string
}


type DSLItemBaseRendererProps<T extends DSLItem = DSLItem> = {
  payload: T
  appTheme: AppTheme
} & DSLRendererDependencies


export const DSLTextRenderer = ({ payload }: DSLItemBaseRendererProps<DSLText>): JSX.Element => {
  const paragraphs = payload.value.split('\n')
  return (
    <Fragment>
      { paragraphs.map( (p, i) => (
        <Fragment key={`dsl-paragraph-${i}`}>
          <span>{ p }</span>
          { p !== '' && paragraphs.length > 1 && i < paragraphs.length - 1 && <div style={{height: 12}} /> }
        </Fragment>
      ))}
    </Fragment>
  )
}


type SimpleEntityCount = {
  value: number | null
  total: number | null
}

const resolveProportionMetric = (metric: Omit<ProportionMetric, 'metric_type'>): SimpleEntityCount => ({
  value: metric.dimensions.count.enumerator.value,
  total: metric.dimensions.count.denominator.value,
})


const dimensionOrder: DimensionType[] = ['count', 'cost', 'impressions', 'views', 'clicks', 'conversions']

const resolveMetricDimensions = (
  metric: Omit<ProportionMetric | ValueMetric, 'metric_type'>,
  performanceTail?: string | null,
): string[] => {
  const options = Object.keys(metric.dimensions).map(parseDimension)

  const resolved = options.filter( d => (
    d.dimension === 'count' || (
      performanceTail ? (d.tail === performanceTail) : !d.tail
    )
  ))

  const orderedDimensions = orderBy(
    resolved,
    d => dimensionOrder.indexOf(d.dimension)
  )

  return orderedDimensions.map( d => d.raw )
}


const allCapsRegex = /^[A-Z]+$/

const resolveEntityLabel = ({
  entity_type,
  value,
  total,
}: {
  value?: number | null
  total?: number | null
  entity_type: EntityType
}): string => {
  const counter = (
    !value ?
      'No' :
      Number(value) === Number(total) ?
        'All' :
        separateThousands(value)
  )

  const rawEntityLabel = (
    structuralEntityTypeLabelMap[entity_type] || entity_type
  )
  // Respect casing on entity type label fragments where all caps are used
  const convertedCaseFragments = rawEntityLabel.split(' ').map( rawFragment => (
    allCapsRegex.test(rawFragment) ?
      rawFragment :
      rawFragment.toLowerCase()
  ))

  const entityLabel = convertedCaseFragments.join(' ')

  const suffix = (value == 1) && counter !== 'All' ? '' : (
    entityLabel.endsWith('h') ?
      'es' :
      's'
  )

  return `${counter} ${entityLabel + suffix}`
}


const useMetricChipClasses = makeAppStyles( theme => ({
  root: {
    position: 'relative',
    maxWidth: 'unset',
    top: -1,
    height: '1.75em',
    fontSize: '0.75em',
    display: 'inline-flex',
    alignItems: 'center',
    margin: theme.spacing(0, 0.25, 0, 0.75),
  },
  label: {
    padding: theme.spacing(0.25),
  },
}))


const useTooltipClasses = makeAppStyles( theme => ({
  tooltip: {
    padding: 0,
    backgroundColor: 'none',
    maxWidth: 'unset',
    boxShadow: theme.shadows[16],
  },
}))


export type MetricChipProps<P extends NumberValueProps | ListValueProps | TextValueProps> = {
  ChipProps?: Partial<Omit<ChipProps, 'label'>>
} & TextMetricVisualisationProps<P>

function MetricChip<P extends NumberValueProps | ListValueProps | TextValueProps>({
  ChipProps = {},
  ...visualisationProps
}: MetricChipProps<P>): JSX.Element {
  return (
    <Chip
      color='secondary'
      variant='outlined'
      classes={useMetricChipClasses()}
      {...ChipProps}
      label={
        <TextMetricVisualisation
          {...visualisationProps as any} />  // TextMetricVisualisationProps typing is currently somewhat flawed
      } />
  )
}


export const DSLEntityCountRenderer = ({
  payload,
  dimension,
  currency,
  appTheme,
  entityCountColor = 'secondary.light',
  showEntityCountChip = true,
}: DSLItemBaseRendererProps<DSLItemEntityCount>): JSX.Element => {
  const simpleCount = resolveProportionMetric(payload)
  const entityLabel = resolveEntityLabel({
    entity_type: payload.entity_type,
    ...simpleCount,
  })
  return (
    <Tooltip
      title={
        <Metric
          id='no-id-ea'
          title={payload.description || entityLabel}
          metric={({ metric_type: 'proportion', ...payload } as MetricType)}
          MetricCardProps={{
            actionPopoverContent: null,
            GridProps: {
              spacing: 2,
            },
          }}
          format='percentage'
          currency={currency}
          dimensionOptions={resolveMetricDimensions(payload)}
          displayType='MIDI'
          display_options={{
            chart_type: 'single_value',
            segmenting_category: null,
            segments: null,
            priority: null,
          }} />
      }
      classes={useTooltipClasses()}>
      <span>
        <Box
          color={entityCountColor}
          fontWeight={appTheme.typography.fontWeightBold}
          display='inline-flex'>
          { entityLabel }
        </Box>

        { showEntityCountChip && (
          <MetricChip
            metric_type='proportion'
            displayType='MICRO'
            format='ratio'
            dimension={dimension || 'count'}
            currency={currency}
            value={simpleCount.value}
            total={simpleCount.total === null ? 0 : simpleCount.total} />
        )}
      </span>
    </Tooltip>
  )
}


export const DSLRecommendedActionRenderer = ({
  payload,
  appTheme,
  recommendedActionColor = 'warning.light',
}: DSLItemBaseRendererProps<DSLItemRecommendedAction>): JSX.Element => {
  return (
    <Box
      display='flex'
      alignItems='flex-start'
      mt={2}
      mr={6}
      fontSize={14}
      fontWeight={appTheme.typography.fontWeightBold}
      color={recommendedActionColor}>
      { payload.text }
    </Box>
  )
}


export type DSLItemRendererProps = {
  payload: DSLItem
  appTheme: AppTheme
} & DSLRendererDependencies

export const DSLItemRenderer = (props: DSLItemRendererProps): JSX.Element | null => {
  switch(props.payload.type){
    case 'ENTITY_COUNT':
      return <DSLEntityCountRenderer {...props as DSLItemBaseRendererProps<DSLItemEntityCount>} />
    case 'TEXT':
      return <DSLTextRenderer {...props as DSLItemBaseRendererProps<DSLText>} />
    case 'RECOMMENDED_ACTION':
      return <DSLRecommendedActionRenderer {...props as DSLItemBaseRendererProps<DSLItemRecommendedAction>} />
    default:
      console.warn('Unhandled DSL Item', props.payload)
      return null
  }
}


export type DSLRendererProps = {
  appTheme: AppTheme
  text?: string | null
  showRecommendations?: boolean
  showEntityCountChip?: boolean
} & DSLRendererDependencies

export const DSLRenderer = ({ appTheme, text, showRecommendations = true, ...props }: DSLRendererProps): JSX.Element => {
  
  const dslItems = useDSL(text || '')

  const paletteSubtype = (
    appTheme.palette.type === 'dark' ? 'light' : 'dark'
  )
  const recommendedActionColor = `warning.${paletteSubtype}`

  return (
    <Box
      display='inline'>
      { dslItems.map( (item, i) => (
        item.type === 'RECOMMENDED_ACTION' && !showRecommendations ?
          null : (
            <DSLItemRenderer
              key={`dsl-item-${i}`}
              payload={item}
              appTheme={appTheme}
              recommendedActionColor={recommendedActionColor}
              {...props} />
          )
      ))}
    </Box>
  ) 
}
