
import {
  createApiReducer,
  createKeyedReducer,
  combineReducers,
} from '@percept/redux/reducers'

import { getSeriesKeyFromAction } from '@percept/redux/utils'

import {
  LOAD_SERIES,
  LOAD_SERIES_AUDITS,
  MUTATE_SERIES,
  CLEAR_MUTATE_SERIES,
  DELETE_SERIES,
  LOAD_SERIES_OAUTH_URL,
  LOAD_SERIES_ACCOUNTS,
  SAVE_SERIES_REFRESH_TOKEN,
  CLEAR_DELETE_SERIES,
  CLEAR_SAVE_SERIES_REFRESH_TOKEN,
  CREATE_REPORT,
  CLEAR_CREATE_REPORT,
  CREATE_INITIAL_REPORT,
  CLEAR_CREATE_INITIAL_REPORT,
} from '../actions'

import {
  series,
  seriesAudits,
} from '@percept/api/adapters'

import {
  Series,
  Report,
  Reducer,
  Dictionary,
  ApiResponse,
  ApiStatusResponse,
  AdwordsAccountHierarchy,
} from '@percept/types'


type SeriesWrapper = {
  series: Reducer<ApiResponse<Series>>
  audits: Reducer<ApiResponse<Report[]>>
  mutation: Reducer<ApiStatusResponse<Series>>
  oAuthUrl: Reducer<ApiResponse<string>>
  accounts: Reducer<ApiResponse<AdwordsAccountHierarchy>>
  refreshToken: Reducer<ApiStatusResponse<string>>
  createReport: Reducer<ApiStatusResponse<Report>>
}


const makeReducer = (raw?: boolean): Reducer<Dictionary<SeriesWrapper>> => createKeyedReducer(
  combineReducers<SeriesWrapper>({
    series: createApiReducer(
      LOAD_SERIES,
      !raw && {
        getPayloadFromAction: ({ payload }): Series => series(payload || {})
      } || undefined
    ),
    audits: createApiReducer(
      LOAD_SERIES_AUDITS,
      !raw && {
        getPayloadFromAction: ({ payload }): Report[] => seriesAudits(payload || [])
      } || undefined
    ),
    mutation: createApiReducer(MUTATE_SERIES, { 
      process: true,
      processActionTypes: [MUTATE_SERIES, DELETE_SERIES],
      resetActionTypes: [CLEAR_MUTATE_SERIES, CLEAR_DELETE_SERIES],
    }),
    oAuthUrl: createApiReducer(LOAD_SERIES_OAUTH_URL, {
      getPayloadFromAction: ({ payload }) => payload && payload.URL || ''
    }),
    accounts: createApiReducer(LOAD_SERIES_ACCOUNTS),
    refreshToken: createApiReducer(SAVE_SERIES_REFRESH_TOKEN, {
      process: true,
      resetActionTypes: [CLEAR_SAVE_SERIES_REFRESH_TOKEN]
    }),
    createReport: createApiReducer(CREATE_REPORT, { 
      process: true,
      processActionTypes: [CREATE_REPORT, CREATE_INITIAL_REPORT],
      resetActionTypes: [CLEAR_CREATE_REPORT, CLEAR_CREATE_INITIAL_REPORT],
    })
  }),
  getSeriesKeyFromAction
)


export const raw = makeReducer(true)


export default makeReducer()
