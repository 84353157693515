import { combineReducers } from 'redux'

import { createApiReducer, createKeyedReducer, createPaginatedReducer } from '../../reducers'

import {
  ADD_PLATFORM_UNIT,
  ADD_PLATFORM_UNIT_PERFORMANCE_REPORTING_PROVIDER,
  ADD_PLATFORM_UNIT_STRUCTURAL_REPORT_SERIES,
  DELETE_PLATFORM_UNIT,
  EDIT_PLATFORM_UNIT,
  LOAD_PLATFORM_UNIT,
  LOAD_PLATFORM_UNITS,
  LOAD_PLATFORM_UNIT_PROVIDER_INFO,
  LOAD_PLATFORM_UNIT_TREE,
  RESET_ADD_PLATFORM_UNIT,
  RESET_ADD_PLATFORM_UNIT_PERFORMANCE_REPORTING_PROVIDER,
  RESET_ADD_PLATFORM_UNIT_STRUCTURAL_REPORT_SERIES,
  RESET_DELETE_PLATFORM_UNIT,
  RESET_EDIT_PLATFORM_UNIT,
} from './actions'

import { getPlatformUnitKeyFromAction, makePayloadAdapter } from '../../utils'

import { platformUnitProviderInfo, platformUnit } from '@percept/api/adapters/platformUnit'

import { Reducer } from 'redux'

import { ApiStatusResponse, PerformanceInventory, PlatformUnit, ReduxAction, ReportSeries } from '@percept/types'

import { PlatformUnitState } from './typings'


const paginatedListing: Reducer<PlatformUnitState['paginatedListing'], ReduxAction> = createPaginatedReducer(
  LOAD_PLATFORM_UNITS
)

const byId: Reducer<PlatformUnitState['byId'], ReduxAction> = createKeyedReducer(
  combineReducers({
    summary: createApiReducer(LOAD_PLATFORM_UNIT, {
      getPayloadFromAction: makePayloadAdapter(platformUnit)
    }),
    detail: createApiReducer(LOAD_PLATFORM_UNIT_TREE, {
      getPayloadFromAction: makePayloadAdapter(platformUnit)
    }),
  }),
  getPlatformUnitKeyFromAction
)

const providerInfoById: Reducer<PlatformUnitState['providerInfoById'], ReduxAction> = createKeyedReducer(
  createApiReducer(LOAD_PLATFORM_UNIT_PROVIDER_INFO, {
    getPayloadFromAction: makePayloadAdapter(platformUnitProviderInfo)
  }),
  getPlatformUnitKeyFromAction,
)


const reducer: Reducer<PlatformUnitState, ReduxAction> = combineReducers({
  paginatedListing,
  byId,
  providerInfoById,
  add: createApiReducer<PlatformUnit, ApiStatusResponse<PlatformUnit>>(ADD_PLATFORM_UNIT, {
    resetActionTypes: [RESET_ADD_PLATFORM_UNIT],
  }),
  edit: createApiReducer<PlatformUnit, ApiStatusResponse<PlatformUnit>>(EDIT_PLATFORM_UNIT, {
    resetActionTypes: [RESET_EDIT_PLATFORM_UNIT],
  }),
  delete: createApiReducer<PlatformUnit, ApiStatusResponse<PlatformUnit>>(DELETE_PLATFORM_UNIT, {
    resetActionTypes: [RESET_DELETE_PLATFORM_UNIT],
  }),
  addPerformanceReportingProvider: createApiReducer<PerformanceInventory, ApiStatusResponse<PerformanceInventory>>(
    ADD_PLATFORM_UNIT_PERFORMANCE_REPORTING_PROVIDER, {
      resetActionTypes: [RESET_ADD_PLATFORM_UNIT_PERFORMANCE_REPORTING_PROVIDER],
    }
  ),
  addStructuralReportSeries: createApiReducer<ReportSeries, ApiStatusResponse<ReportSeries>>(
    ADD_PLATFORM_UNIT_STRUCTURAL_REPORT_SERIES, {
      resetActionTypes: [RESET_ADD_PLATFORM_UNIT_STRUCTURAL_REPORT_SERIES],
    }
  ),
})

export default reducer
