import React, { createContext, useContext } from 'react'

import { Tabs, Tab, makeAppStyles } from '@percept/mui'
import { noop } from 'lodash-es'

export type TabType = {
  label: React.ReactNode
  value: string
  content: JSX.Element
}


const useStyles = makeAppStyles( theme => ({
  tabs: {
    margin: theme.spacing(4,0),
    borderBottom: `1px solid ${theme.palette.text.hint}`,
  },
  tab: {
    textTransform: 'none',
  },
}))


export type SubApplicationTabContextType = {
  activeTabValue: string
  setActiveTabValue: (activeTabValue: string) => void
}

export const SubApplicationTabContext = createContext<SubApplicationTabContextType>({
  activeTabValue: '',
  setActiveTabValue: noop
})

export type SubApplicationTabsProps = {
  tabs: TabType[]
}

export const SubApplicationTabs = ({ tabs }: SubApplicationTabsProps): JSX.Element => {
  const classes = useStyles()

  const { activeTabValue, setActiveTabValue } = useContext(SubApplicationTabContext)

  const activeTabEntry = tabs.find( t => t.value === activeTabValue )

  const content = activeTabEntry && activeTabEntry.content || false

  return (
    <>
      <Tabs
        indicatorColor='primary'
        textColor='primary'
        variant='fullWidth'
        className={classes.tabs}
        value={activeTabValue}
        onChange={(_, value: string): void => {
          setActiveTabValue(value)
        }}>
        {tabs.map((tab, i) => (
          <Tab key={`tab-${i}`} className={classes.tab} value={tab.value} label={tab.label} />
        ))}
      </Tabs>
      { content }
    </>
  )
}
