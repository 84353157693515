import React, { useMemo, useState } from 'react'

import {
  Box,
  Card,
  CardContent,
  CardProps,
  CardStrip,
  Chip,
  Grid,
  Typography,
  makeAppStyles,
  useAppTheme,
  Collapse,
  Fade,
  IconButtonPopover,
  ReportRange,
} from '@percept/mui'

import {
  ChartData,
  MultiDataset,
  percentageDeltaFormatter,
  percentageFormatter,
  ResponsiveHistogramWithTooltip,
  ResponsiveMultiLine,
  LineProps,
  shortMonthFormatter,
  longDayMonthYearFormatter,
  SVGDatumType,
} from '@percept/mui/charts'

import { dimensionIcons } from '@percept/mui/icons'

import { PerformanceTable } from 'components/Tables'

import { cellRenderers } from '../channelTable'

import { TimeRangeSelect } from './TimeRangeSelect'

import { VarianceDetail } from './VarianceDetail'

import { ArrowDropDown, ZoomIn } from '@material-ui/icons'

import { useNavigation } from '@percept/hooks'

import { usePerformanceTotalsTableStyles } from 'components/CrossChannelDashboard/styles'

import { get, sortBy } from 'lodash-es'

import { subDays } from 'date-fns'

import { isMonetaryDimension, isPercentageDimension, isRateBasedDimension } from '@percept/utils'

import { getDatasetSortValue } from '../lib'

import { PerformanceDimensionDataset, TimelineDatum } from 'types'

import { AnyPerformanceDimension, DatumType, PerformanceDimensionType, PerformanceRangeKey } from '@percept/types'


const useStyles = makeAppStyles( theme => ({
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexColumnEnd: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    marginRight: theme.spacing(1),
  },
  histogram: {
    padding: theme.spacing(0.5, 1, 2, 0),
  },
  performanceTableContainer: {
    height: theme.spacing(34),
    overflowY: 'auto',
    marginBottom: theme.spacing(1),
  },
  decreasing: {
    fontSize: 12,
    color: theme.palette.action.disabled,
    margin: theme.spacing(2, 0, 1, 0),
  },
  chip: {
    margin: theme.spacing(0, 1, 1, 0),
    fontSize: 11,
    padding: 4,
    height: 'unset',
    lineHeight: 1.35,
  },
  chipLabel: {
    padding: theme.spacing(0, 0.5),
  },
  multiline: {
    width: '100%',
    padding: theme.spacing(2, 1, 0, 2),
  },
}) )


export type Tracker<
  D extends PerformanceDimensionDataset = PerformanceDimensionDataset,
> = {
  key: string | number
  label: string | number
  dimension: AnyPerformanceDimension
  datasets: {
    key: string
    label: string
    color: string
    dataset: D
  }[]
}

export type PerformanceTrackerProps<D extends PerformanceDimensionDataset = PerformanceDimensionDataset> = (
  Omit<
    Tracker<D>,
    'label'
  > & {
    header: React.ReactNode
    currency?: string | null
    referenceDate?: Date | null
    invertSentiment?: boolean
    editControl?: JSX.Element
    isEditing?: boolean
    initialRange?: PerformanceRangeKey
  }
  & CardProps
)


const lineProps: Partial<LineProps<TimelineDatum>> = {
  arbitraryTimeline: true,
  grid: 'rows',
  xTickFormatter: longDayMonthYearFormatter,
  // eslint-disable-next-line react/display-name
  tooltipLabelDatumFormatter: (d) => {
    return (
      <ReportRange
        start={d.start}
        end={d.end} />
    )
  }
}

const rateBasedLineProps: Partial<LineProps> = {
  yTickFormatter: percentageFormatter,
  domain: [0, 100],
}


export function PerformanceTracker<D extends PerformanceDimensionDataset>({
  header,
  datasets,
  dimension,
  currency,
  referenceDate,
  editControl,
  initialRange = 'week',
  invertSentiment = false,
  isEditing = false,
  ...props
}: PerformanceTrackerProps<D>): JSX.Element {

  const [range, setRange] = useState(initialRange)

  const appTheme = useAppTheme()

  const isRateBased = isRateBasedDimension(dimension)

  const varianceDataset: ChartData<DatumType & { unit_id: string }> = useMemo(() => {
    return datasets.map( ({ dataset, label, key }) => {
      const numberValue = Number(get(dataset, ['relative', range]))
      const value = isNaN(numberValue) ? 0 : numberValue

      let healthValue = Number(value) < 0 ? 0 : 1

      if( invertSentiment ){
        healthValue = Number(!healthValue)
      }

      return {
        label,
        value,
        color: appTheme.chart.healthColourScale(healthValue),
        unit_id: key,
      }
    })
  }, [datasets, invertSentiment, range, appTheme])

  const spendDatasets = useMemo(() => {
    return sortBy(
      datasets,
      d => getDatasetSortValue({
        dataset: d.dataset || null,
        rangeKeys: [range]
      })
    ).filter( d =>
      !!(d.dataset && d.dataset.relative)
    ).map( ({ dataset, key, label, color }) => ({
      ...dataset.relative,
      dimension,
      currency,
      key,
      label,
      color,
    }))
  }, [datasets, range, currency, dimension])

  const decreasingDatasets: MultiDataset[] = useMemo(() => {
    const colourScale = appTheme.chart.getInformationalColourScale([0, 2])
    return sortBy(
      datasets,
      d => getDatasetSortValue({
        dataset: d.dataset,
        rangeKeys: [range],
        filter: 'min',
      })
    ).slice(0, 3).map( ({ dataset, key, label }, i) => ({
      key,
      label,
      color: colourScale(i),
      // Coerce rate-based metrics to percentages
      data: (
        isRateBased ?
          (dataset && dataset.timeseries || []).map( d => ({
            ...d,
            value: d.value && (Number(d.value) * 100),
          })) :
          (dataset && dataset.timeseries || [])
      ),
    }))
  }, [datasets, range, appTheme, isRateBased])

  const classes = useStyles()

  const tableClasses = usePerformanceTotalsTableStyles()

  const navigate = useNavigation()

  const IconComponent = dimensionIcons[dimension]

  return (
    <Card {...props}>

      {/* <CardStrip color='secondary' /> */}

      <CardContent>

        <Grid container spacing={3}>

          <Grid className={classes.flexColumn} item xs={12} md={6} lg={4}>

            <Grid item xs={12}>

              <Box
                display='flex'
                alignItems='center'
                mb={isEditing ? 0 : 2}>

                { editControl }

                <Typography variant='h5' className={classes.header}>
                  { IconComponent && (
                    <IconComponent
                      className={classes.headerIcon} />
                  )}
                  { header }
                </Typography>

                <Fade in={!isEditing}>
                  <Box ml='auto'>
                    <TimeRangeSelect
                      TriggerProps={{
                        size: 'small',
                        variant: 'outlined',
                        endIcon: <ArrowDropDown />
                      }}
                      value={range}
                      onChange={(e, value): void => {
                        setRange(value)
                      }} />
                  </Box>
                </Fade>

              </Box>

            </Grid>

            <Grid item xs={12}>
              <Collapse in={!isEditing}>
                <IconButtonPopover
                  IconComponent={ZoomIn}
                  color='secondary'>
                  <VarianceDetail
                    title={`Market ${header} Variance`}
                    invertSentiment={invertSentiment}
                    data={varianceDataset}
                    onItemClick={({ unit_id }): void => {
                      navigate(`/dashboards/${unit_id}`)
                    }} />
                </IconButtonPopover>
              </Collapse>
            </Grid>

            <Grid className={classes.flexColumnEnd} item xs={12}>

              <Collapse in={!isEditing}>

                <ResponsiveHistogramWithTooltip
                  className={classes.histogram}
                  height={150}
                  grid='rows'
                  axisLine
                  axisText
                  verticalMargin={0}
                  numXTicks={0}
                  xOffset={40}
                  yTickFormatter={percentageDeltaFormatter}
                  mirrorYDomain
                  // alternateBackground={appTheme.palette.action.disabledBackground}
                  dimension={dimension as PerformanceDimensionType}
                  data={varianceDataset} />

              </Collapse>

            </Grid>

          </Grid>

          <Grid className={classes.flexColumnEnd} item xs={12} md={6} lg={4}>

            <Collapse in={!isEditing}>

              <div className={classes.performanceTableContainer}>

                <PerformanceTable
                  classes={tableClasses}
                  rows={spendDatasets}
                  referenceDate={referenceDate}
                  renderers={cellRenderers} />

              </div>

            </Collapse>

          </Grid>

          <Grid className={classes.flexColumnEnd} item xs={12} md={6} lg={4}>

            <Collapse in={!isEditing}>

              <Typography variant='h5'>Consistently Decreasing</Typography>

              <div className={classes.decreasing}>
                { decreasingDatasets.map( d => (
                  <Chip
                    classes={{
                      root: classes.chip,
                      labelSmall: classes.chipLabel,
                    }}
                    key={d.label}
                    size='small'
                    color='secondary'
                    label={d.label} />
                ))}
              </div>

              <div className={classes.multiline}>
                <ResponsiveMultiLine
                  datasets={decreasingDatasets}
                  defaultXDomain={
                    referenceDate ? [
                      subDays(referenceDate, 364).getTime(),
                      referenceDate.getTime(),
                    ] : undefined
                  }
                  xScaleType='time'
                  height={170}
                  grid
                  axisLine
                  axisText
                  numYTicks={5}
                  xTickFormatter={shortMonthFormatter}
                  yTickFormatter={
                    isPercentageDimension(dimension) ?
                      percentageFormatter :
                      undefined
                  }
                  {...lineProps as Partial<LineProps<SVGDatumType>>}
                  {...(isRateBased && rateBasedLineProps || {})}
                  currency={isMonetaryDimension(dimension) ? currency : null} />
              </div>

            </Collapse>

          </Grid>

        </Grid>

      </CardContent>

    </Card>
  )
}
