
export const produceKeyedMapping = <T extends object>(
  items: T[] | null | undefined,
  key: Extract<keyof T, string>
): Record<string, T> => {
  if( !items ){
    return {}
  }
  return items.reduce( (acc, item) => {
    acc[item[key] as string] = item
    return acc
  }, {} as Record<string, T>)
}
