
import {
  Dictionary,
  Reducer,
  ReduxAction,
  AsyncReduxAction,
} from '@percept/types'

import {
  mapValues,
} from '@percept/utils'


type ReducerObject = Record<string, Reducer>


export default <T = ReducerObject>(reducers: T) => (
  (state: Dictionary = {}, action: ReduxAction, ...args: unknown[]): ReducerObject => (
    mapValues(
      reducers,
      (reducer: Reducer, key: string) => reducer(state[key], action, ...args)
    )
  )
)
