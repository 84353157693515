import React, { Fragment, useEffect, useMemo } from 'react'

import { Box, CellRenderers, ChannelCellRenderer, Loader, ProviderLogo, useAppTheme } from '@percept/mui'

import { DimensionalSummary } from 'components/DimensionalSummary'

import { PerformanceTotals } from './PerformanceTotals'

import { usePerformanceTimeseriesByProvider, usePlatformUnit } from '@percept/hooks'

import { PerformanceTableProps } from 'components/Tables'

import { useComparisonsByProvider, usePerformanceReportingDimension } from 'hooks'

import { useProviderDatasets } from './perfUtils'

import { useChannelSummaries } from './lib'

import { format } from 'date-fns'

import { isLoading } from '@percept/utils'

import { getPotentialEfficiencyLabel } from 'components/Organisation'
import { resolvePerformanceReportingDimensions } from 'components/Organisation/lib'

import { channelDisplayMap, providerLabelMap } from '@percept/constants'

import {
  ChannelKey,
  PlatformUnit,
  PrimaryPerformanceDataProvider,
} from '@percept/types'

import { PerformanceReportingDatum } from 'types'
import { some } from 'lodash-es'


export type CrossChannelPerformanceSummaryProps = {
  platformUnit: PlatformUnit
  providers: PrimaryPerformanceDataProvider[]
  referenceDate: Date | null
  currency: string
}


const cellRenderers: CellRenderers<
  PerformanceReportingDatum & {
    channel: ChannelKey
    provider?: PrimaryPerformanceDataProvider
  }
> = {
  // eslint-disable-next-line react/display-name, react/prop-types
  label: ({ channel, provider }) => (
    provider ? (
      <ProviderLogo size={1.5} provider={provider} />
    ) : (
      <ChannelCellRenderer channel={channel} />
    )
  )
}



export const CrossChannelPerformanceSummary = ({
  platformUnit,
  providers,
  referenceDate,
  currency,
}: CrossChannelPerformanceSummaryProps): JSX.Element => {

  const org_unit_id = platformUnit && platformUnit.id

  const [platformUnitDetail] = usePlatformUnit({
    org_unit_id,
  })

  const referenceDateParam = referenceDate && format(referenceDate, 'yyyy-MM-dd')

  const comparisonsByProvider = useComparisonsByProvider({
    org_unit_id,
    providers,
    target_currency: currency,
    ...(referenceDateParam && {
      reference_date: referenceDateParam,
    })
  })

  const [timeseriesByProvider] = usePerformanceTimeseriesByProvider({
    org_unit_id,
    period: 'DAYS_365',
    chunking: 'MONTH',
    target_currency: currency,
    ...(referenceDateParam && {
      reference_date: referenceDateParam,
    })
  })

  const datasets = useProviderDatasets({
    timeseriesByProvider: timeseriesByProvider.data,
    doubleVerifyTimeseriesByProvider: null,
    comparisonsByProvider: comparisonsByProvider.data,
    doubleVerifyComparisonsByProvider: null,
    platformUnit: platformUnitDetail.data,
    currency,
  })

  const appTheme = useAppTheme()

  const channelSummaries = useChannelSummaries({
    appTheme,
    datasets,
  })

  const availableDimensions = useMemo(() => {
    if( platformUnitDetail.data ){
      return resolvePerformanceReportingDimensions({
        platformUnit: platformUnitDetail.data,
      })
    }
    return undefined
  }, [platformUnitDetail.data])

  const [activeDimension, setActiveDimension] = usePerformanceReportingDimension()

  useEffect(() => {
    if( availableDimensions && !availableDimensions.includes(activeDimension) ){
      setActiveDimension(availableDimensions[0])
    }
  }, [availableDimensions, activeDimension, setActiveDimension])

  const spendDatasets = useMemo(() => {
    return channelSummaries.map( ({ channel, provider, datasets }) => {
      return {
        key: provider || channel,
        label: provider ? providerLabelMap[provider] : channelDisplayMap[channel].label,
        provider,
        channel,
        dimension: activeDimension,
        color: appTheme.palette.channel[channel].main,
        dataset: {
          ...(datasets[activeDimension] || {}),
          channel,
          provider,
          dimension: activeDimension,
          relative: {
            ...(datasets[activeDimension] && datasets[activeDimension].relative),
            label: channel,
            channel,
            provider,
            dimension: activeDimension,
          }
        }
      }
    })
  }, [activeDimension, channelSummaries, appTheme])

  const loading = some([
    platformUnitDetail,
    timeseriesByProvider,
    comparisonsByProvider,
  ], isLoading)

  if( loading ){
    return <Loader preset='fullsize' minHeight='10rem' />
  }

  return (
    <Fragment>
      <DimensionalSummary
        dimension={activeDimension}
        color='neutral'
        currency={currency}
        referenceDate={referenceDate}
        datasets={spendDatasets}
        labelHistory='History'
        labelChange='Comparison'
        labelSplit='Breakdown'
        PerformanceTableProps={{
          renderers: cellRenderers as PerformanceTableProps<PerformanceReportingDatum>['renderers'],
        }} />

      <Box my={3}>
        <PerformanceTotals
          org_unit_id={platformUnit.id}
          dimension={activeDimension}
          currency={currency}
          providers={providers}
          referenceDate={referenceDate}
          potentialEfficiencyLabel={getPotentialEfficiencyLabel(platformUnit)} />
      </Box>
    </Fragment>
  )
}
