import React, { useState } from 'react'

import {
  Box,
  BoxProps,
  ClassNameMap,
  Collapse,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputProps,
  SecondaryIconTooltip,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
  makeAppStyles,
} from '@percept/mui'

import { ArrowDownward, ArrowUpward } from '@percept/mui/icons'

import { sponsorshipsNumberFormatter } from './utils'

import { round } from 'lodash-es'

import {
  SponsorshipsDerivedMetricField,
  SponsorshipsDerivedMetricsPayload,
  SponsorshipsFormField,
  SponsorshipsFormItem,
  SponsorshipsFormValues,
} from './typings'


export type SponsorshipsFormClassName = (
  'divider' | 'text' | 'card' | 'nestedCard' | 'nestedCardTitle' |
  'inputField' | 'inputAdornment' | 'note' | 'tooltip'
)

export const useFormStyles = makeAppStyles<{}, SponsorshipsFormClassName>( theme => ({
  divider: {
    margin: '20px 0'
  },
  text: {
    fontSize: 18
  },
  card: {
    margin: theme.spacing(8, 0),
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: theme.spacing(3),
    }
  },
  nestedCard: {
    marginBottom: theme.spacing(6),
    '&:last-child': {
      marginBottom: 0,
    }
  },
  nestedCardTitle: {
    fontSize: 22,
  },
  tooltip: {
    marginLeft: theme.spacing(0.5),
  },
  inputField: {
    margin: theme.spacing(1, 0),
    width: '51%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& .MuiFormHelperText-root': {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      marginBottom: theme.spacing(1),
    },
    '& .MuiFilledInput-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.085)',
      },
    },
    '& .MuiFilledInput-input': {
      padding: theme.spacing(1.5),
      fontSize: 16,
    },
    '& .MuiInputAdornment-root .MuiTypography-body1': {
      fontSize: 16,
    },
  },
  inputAdornment: {
    fontSize: 16,
  },
  note: {
    margin: theme.spacing(2, 0),
  }
}))


export type FormItemProps = {
  formItem: SponsorshipsFormItem
  values: SponsorshipsFormValues
  classes: ClassNameMap<SponsorshipsFormClassName>
  derivedMetrics?: SponsorshipsDerivedMetricsPayload
} & Omit<TextFieldProps, 'value'>

export type SponsorshipsFormFieldComponentProps = (
  Pick<FormItemProps, 'classes'> &
  Partial<Omit<TextFieldProps, 'value'>> & {
    field: SponsorshipsFormField
    value: string | number | undefined
  }
)

export const SponsorshipsFormFieldComponent = ({
  classes,
  value,
  onChange,
  field,
  ...props
}: SponsorshipsFormFieldComponentProps): JSX.Element => {
  let TextFieldInputProps: InputProps | undefined = undefined
  const inputProps: TextFieldProps['inputProps'] = {
    min: 0
  }
  if( field.format === 'PERCENT' ){
    inputProps.max = 100
    if( typeof value !== 'undefined' && value !== '' ){
      value = round(Number(value) * 100, 4)// * 100
    }
    TextFieldInputProps = {
      endAdornment: (
        <InputAdornment className={classes.inputAdornment} position='end'>
          %
        </InputAdornment>
      )
    }
  }

  const changeHandler = (
    onChange && (
      (e: React.ChangeEvent<HTMLInputElement>): void => {
        if( e.target.value !== '' ){
          if( Number(e.target.value) < 0 ){
            e.target.value = '0'
          }
          if( field.format === 'PERCENT' ){
            e.target.value = (round(Number(e.target.value), 4) / 100).toString()
          }
        }
        onChange(e)
      }
    )
  )

  return (
    <FormControl className={classes.inputField}>
      <FormHelperText>
        {field.label}
        {field.tooltip && (
          <SecondaryIconTooltip
            title={field.tooltip}
            className={classes.tooltip} />
        )}
      </FormHelperText>
      <TextField
        type='number'
        variant='filled'
        inputProps={inputProps}
        InputProps={TextFieldInputProps}
        name={field.name}
        value={value}
        onChange={changeHandler}
        {...props} />
    </FormControl>
  )
}


export type SponsorshipsDerivedMetricComponentProps = (
  Pick<FormItemProps, 'classes'> & {
    field: SponsorshipsDerivedMetricField 
    value: number | undefined
  }
)

export const SponsorshipsDerivedMetricComponent = ({
  classes,
  field,
  value,
}: SponsorshipsDerivedMetricComponentProps): JSX.Element => {
  return (
    <Box display='flex' justifyContent='space-between' my={1}>
      <Typography className={classes.text}>{field.label}</Typography>
      <Typography variant='subtitle1' className={classes.text}>
        {sponsorshipsNumberFormatter(value || null)}
      </Typography>
    </Box>
  )
}

export const FormItem = ({ formItem, classes, values, derivedMetrics, ...props }: FormItemProps): JSX.Element | null => {
  switch(formItem.type){
    case 'SUBHEADER':
      return <Typography variant='h6'>{formItem.title}</Typography>
    case 'DIVIDER':
      return <Divider className={classes.divider} />
    case 'FORM_FIELD':
      return (
        <SponsorshipsFormFieldComponent
          classes={classes}
          value={values[formItem.name]}
          field={formItem}
          {...props} />
      )
    case 'DERIVED_METRIC':
      return (
        derivedMetrics ?
          <SponsorshipsDerivedMetricComponent
            classes={classes}
            field={formItem}
            value={derivedMetrics[formItem.name]} /> :
          null
      )
    default:
      return null
  }
}


export type FormSectionProps = {
  title: React.ReactNode
  divided?: boolean
  TypographyProps?: TypographyProps
} & Omit<BoxProps, 'title'>

export const FormSection = (
  { title, children, divided = false, TypographyProps = {}, ...props }: React.PropsWithChildren<FormSectionProps>
): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const IconComponent = isCollapsed ? ArrowDownward : ArrowUpward
  return (
    <div>
      <Box display='flex' justifyContent='space-between' p={2} {...props}>
        <Typography variant='h3' {...TypographyProps}>{title}</Typography>
        <IconButton
          color='inherit'
          onClick={(): void => setIsCollapsed(!isCollapsed)}>
          <IconComponent color='inherit' />
        </IconButton>
      </Box>
      <Collapse in={!isCollapsed}>
        { divided && <Box mx={2} mb={2}><Divider /></Box>}
        { children }
      </Collapse>
    </div>
  )
}
