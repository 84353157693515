import { ReportSchemaFieldset } from 'components/BrandGrowthPlanner/api/report-schemas'
import { every } from 'lodash-es'


const collectRequiredQuestionIds = (fieldsets: ReportSchemaFieldset[]): string[] => {

  const questionIds: string[] = []

  const walkFieldset = (fieldset: ReportSchemaFieldset): void => {
    if( fieldset.questions ){
      fieldset.questions.forEach( q => {
        if( q.is_required ){
          questionIds.push(q.id)
        }
      })
    }
    if( fieldset.fieldsets ){
      fieldset.fieldsets.forEach(walkFieldset)
    }
  }

  fieldsets.forEach(walkFieldset)

  return questionIds
}


export const hasRequiredAnswers = (
  fieldsets: ReportSchemaFieldset[],
  values: Record<string, unknown>
): boolean => {
  const requiredQuestionIds = collectRequiredQuestionIds(fieldsets)
  return every(requiredQuestionIds, id => typeof values[id] !== 'undefined')
}


export const collectPrefillValues = (
  fieldsets: ReportSchemaFieldset[]
): Record<string, unknown> => {

  const prefillValues: Record<string, unknown> = {}

  const walkFieldset = (fieldset: ReportSchemaFieldset): void => {
    if( fieldset.questions ){
      fieldset.questions.forEach( q => {
        if( q.prefill_key ){
          prefillValues[q.id] = q.value
        }
      })
    }
    if( fieldset.fieldsets ){
      fieldset.fieldsets.forEach(walkFieldset)
    }
  }

  fieldsets.forEach(walkFieldset)

  return prefillValues
}
