import * as actions from './actions'

import { getFetchTypes } from '../../utils'
import { ReduxAction } from '@percept/types'


const signInFetchTypes = getFetchTypes(actions.SIGN_IN)
const codeChallengeFetchTypes = getFetchTypes(actions.RESPOND_TO_CODE_CHALLENGE)
const oAuthRefreshFetchTypes = getFetchTypes(actions.OAUTH_REFRESH)
const ssoExchangeFetchTypes = getFetchTypes(actions.EXCHANGE_SINGLE_SIGN_ON_CODE)
const ssoRefreshFetchTypes = getFetchTypes(actions.OAUTH_REFRESH_SSO)

const loginSuccessTypes = [
  signInFetchTypes.success,
  codeChallengeFetchTypes.success,
  oAuthRefreshFetchTypes.success,
  ssoExchangeFetchTypes.success,
  ssoRefreshFetchTypes.success,
]

export const isLoginSuccessAction = (action: ReduxAction): boolean => (
  loginSuccessTypes.includes(action.type)
)
