
import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'

import { ReduxBundle } from '@percept/types'


export const makeBundle = (): ReduxBundle => ({ actions, reducer, selectors })

export default makeBundle()
