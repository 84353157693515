
import {
  createKeyedReducer,
} from '@percept/redux/reducers'

import { combineReducers } from 'redux'

import { getSeriesGroupKeyFromAction } from '@percept/redux/utils'

import summary from './summary'
import detail from './detail'
import activeProvider from './activeProvider'
import activeSeries from './activeSeries'
import createSeries from './createSeries'

import { SeriesGroupState } from '../../typings'

import { Reducer } from '@percept/types'


const reducer: Reducer<SeriesGroupState['byId']> = createKeyedReducer(
  combineReducers({
    summary,
    detail,
    activeProvider,
    activeSeries,
    createSeries
  }),
  getSeriesGroupKeyFromAction
)

export default reducer
