import React, { useContext } from 'react'

import { Box, Typography } from '@percept/mui'

import { UserPrivilegeContext } from '@percept/app-components'

import { BrandGrowthQuadrantChart } from 'components/BrandGrowthQuadrantChart'
import { ReportService } from 'components/BrandGrowthPlanner/api/reports'
import { parseLastSegmentationQuartal, parseMarketSegmentation } from 'components/BrandGrowthPlanner/pages/all-markets/components'

import { formatFinancialQuarter } from 'components/Overview/lib'

import { userHasGlobalPrivileges, userHasOrgPrivileges } from '@percept/utils'

import { CarouselItem } from 'components/Carousel'

import { VODAFONE_GLOBAL_ID } from 'vodafoneMarkets'

import { LiveCarouselHookProps } from './typings'


export const useBGPCarouselItem = (props: LiveCarouselHookProps): CarouselItem | null => {
  const privileges = useContext(UserPrivilegeContext)

  const hasBGPPrivilege = userHasGlobalPrivileges(
    ['brandGrowthPlanner.viewAny'],
    privileges.global_privileges,
  )

  const hasOverviewPrivilege = userHasGlobalPrivileges(
    ['vfMediaWizardOverview.viewAny'],
    privileges.global_privileges,
  )

  const hasSegmentationPrivilege = userHasOrgPrivileges(
    ['brandGrowthPlanner.viewSegmentation'],
    VODAFONE_GLOBAL_ID,
    privileges.org_privileges,
  )

  const hasRequiredPrivileges = hasOverviewPrivilege || (
    hasBGPPrivilege && hasSegmentationPrivilege
  )

  const {
    data: marketsSegmentation,
    isError,
  } = ReportService.useMarketsSegmentation({
    enabled: hasRequiredPrivileges
  })

  if( !hasRequiredPrivileges || isError ){
    return null
  }
  const markets = marketsSegmentation && parseMarketSegmentation(marketsSegmentation)
  const quartal = marketsSegmentation && parseLastSegmentationQuartal(marketsSegmentation)
  if( !markets || !quartal ){
    return {
      title: '',
      loading: true,
      ...props,
    }
  }
  return {
    title: 'Increase your media impact to move to Sustain quadrant',
    href: hasOverviewPrivilege ? '/wizard/overview' : null,
    infoGraphicContent: (
      <Box height='100%' width='100%' p={3}>
        <Box ml={6} mb={2}>
          <Typography variant='subtitle1'>
            {formatFinancialQuarter(new Date(quartal.start_date))} Customer Appreciation Rankings
          </Typography>
        </Box>
        <Box height='calc(100% - 36px)'>
          <BrandGrowthQuadrantChart
            markets={markets} />
        </Box>
      </Box>
    ),
    ...props,
  }
}
