
import React from 'react'

import { Logo } from './Logo'

import { LogoComponentProps } from './typings'


const fullViewBox = '0 128.127 276.846 43.764',
      fullAspectRatio = 6.325884288456265,
      shortViewBox = '0 128.127 36.23 43.764',
      shortAspectRatio = 0.827849373914633


export const Percept = ({ 
  ...props
}: LogoComponentProps): JSX.Element => {

  return (
    <Logo
      fullViewBox={fullViewBox}
      fullAspectRatio={fullAspectRatio}
      shortViewBox={shortViewBox}
      shortAspectRatio={shortAspectRatio}
      paths={
        props.short ? (
          <path d="M35.015,136.406c-0.757-1.753-1.918-3.302-3.39-4.52c-1.581-1.257-3.404-2.174-5.356-2.693 c-2.301-0.619-4.676-0.917-7.059-0.885H1.657c-0.439-0.005-0.86,0.172-1.164,0.49c-0.318,0.303-0.497,0.724-0.494,1.164v6.178 c0,0.928,0.752,1.68,1.68,1.68l0,0h17.221c1.502-0.061,2.988,0.333,4.264,1.13c1.125,0.753,1.687,1.992,1.687,3.714 s-0.561,2.964-1.684,3.725c-1.276,0.797-2.764,1.191-4.268,1.13H1.68c-0.928,0-1.68,0.752-1.68,1.68v20.416 c-0.003,0.439,0.175,0.86,0.494,1.164c0.303,0.318,0.724,0.497,1.164,0.493h8.042c0.91-0.015,1.643-0.748,1.657-1.657v-12.524h7.854 c2.384,0.036,4.761-0.271,7.059-0.907c1.94-0.538,3.755-1.456,5.337-2.701c1.475-1.197,2.638-2.734,3.39-4.479 c0.85-2.002,1.269-4.161,1.231-6.336C36.266,140.518,35.853,138.385,35.015,136.406z"/>
        ) : (
          <g>
            <path d="M221.622,128.251c2.392-0.032,4.776,0.269,7.085,0.893c1.951,0.524,3.773,1.445,5.354,2.705 c1.479,1.215,2.648,2.765,3.412,4.52c0.836,1.985,1.244,4.123,1.201,6.275c0.047,2.173-0.362,4.331-1.201,6.335 c-0.758,1.75-1.93,3.291-3.412,4.49c-1.588,1.25-3.407,2.171-5.354,2.713c-2.301,0.645-4.684,0.955-7.074,0.922h-7.887v12.551 c-0.01,0.914-0.748,1.651-1.661,1.662h-8.058c-0.912-0.013-1.648-0.75-1.66-1.662v-39.742c-0.006-0.441,0.173-0.864,0.493-1.167 c0.304-0.319,0.727-0.498,1.167-0.494H221.622z M213.746,147.518h7.567c1.506,0.061,2.995-0.333,4.274-1.13 c1.128-0.753,1.691-1.994,1.691-3.722c0-1.728-0.563-2.968-1.691-3.721c-1.277-0.799-2.768-1.193-4.274-1.13h-7.567V147.518z"/> <path d="M71.978,161.455c0.912,0.008,1.649,0.745,1.658,1.656v6.506c-0.012,0.91-0.748,1.645-1.658,1.657h-29.09 c-0.909-0.015-1.643-0.748-1.657-1.657v-39.656c-0.003-0.439,0.175-0.86,0.494-1.164c0.304-0.317,0.725-0.494,1.164-0.49h28.601 c0.441-0.003,0.863,0.175,1.168,0.493c0.313,0.305,0.488,0.724,0.486,1.16v6.505c-0.008,0.912-0.746,1.649-1.657,1.657H52.587v6.629 h17.542c0.91,0.012,1.645,0.747,1.657,1.657v6.506c-0.014,0.908-0.748,1.643-1.657,1.656H52.576v6.883H71.978z"/>
            <path d="M98.112,128.308c2.441-0.032,4.876,0.257,7.243,0.859c1.962,0.496,3.807,1.373,5.432,2.58 c1.497,1.149,2.679,2.659,3.435,4.388c0.836,1.967,1.244,4.089,1.198,6.227c0,3.071-0.634,5.619-1.902,7.643 c-1.296,2.05-3.178,3.664-5.401,4.633l7.612,14.607c0.111,0.208,0.173,0.438,0.181,0.674c-0.002,0.745-0.604,1.349-1.348,1.353 h-8.347c-0.819,0-1.401-0.185-1.748-0.554c-0.321-0.332-0.6-0.702-0.829-1.104l-6.814-13.139h-5.65v13.135 c-0.012,0.91-0.747,1.646-1.657,1.658h-8.035c-0.91-0.013-1.645-0.748-1.657-1.658v-39.648c-0.008-0.437,0.163-0.857,0.475-1.164 c0.309-0.322,0.737-0.499,1.183-0.49H98.112z M91.177,146.9h6.935c1.484,0.057,2.957-0.283,4.268-0.983 c1.123-0.65,1.685-1.837,1.688-3.56s-0.56-2.909-1.688-3.56c-1.312-0.697-2.784-1.035-4.268-0.979h-6.935V146.9z"/>
            <path d="M131.488,144.881c-0.041,0.527-0.071,1.254-0.09,2.188c-0.019,0.934-0.034,1.883-0.034,2.885c0,1.002,0,1.97,0.034,2.919 c0.034,0.949,0.049,1.694,0.09,2.219c0.078,0.911,0.264,1.81,0.554,2.678c0.278,0.844,0.738,1.615,1.349,2.261 c0.658,0.677,1.453,1.206,2.332,1.551c1.119,0.416,2.307,0.611,3.5,0.577c1.164,0.034,2.326-0.131,3.435-0.486 c0.798-0.269,1.546-0.669,2.211-1.187c0.538-0.424,0.986-0.952,1.318-1.552c0.281-0.505,0.518-1.032,0.708-1.578 c0.127-0.498,0.356-0.964,0.674-1.367c0.251-0.251,0.722-0.377,1.413-0.377h8.039c0.355-0.009,0.699,0.127,0.953,0.377 c0.257,0.245,0.401,0.586,0.398,0.941c-0.034,2.04-0.561,4.042-1.537,5.835c-1,1.849-2.379,3.465-4.049,4.742 c-1.823,1.393-3.867,2.473-6.045,3.193c-2.428,0.811-4.974,1.212-7.533,1.188c-2.687,0.043-5.359-0.4-7.888-1.307 c-2.183-0.797-4.193-2.006-5.921-3.561c-1.614-1.471-2.898-3.269-3.767-5.273c-0.883-2.009-1.394-4.162-1.506-6.354 c-0.083-1.66-0.125-3.443-0.125-5.349c0-1.906,0.042-3.73,0.125-5.473c0.072-2.194,0.572-4.353,1.473-6.354 c0.884-1.996,2.166-3.79,3.767-5.273c1.73-1.548,3.749-2.739,5.94-3.507c2.537-0.907,5.216-1.35,7.91-1.307 c2.56-0.026,5.105,0.374,7.534,1.183c2.179,0.722,4.221,1.801,6.045,3.194c1.668,1.278,3.048,2.895,4.049,4.742 c0.975,1.793,1.502,3.794,1.537,5.835c-0.008,0.741-0.611,1.337-1.352,1.337h-8.046c-0.697,0-1.167-0.128-1.413-0.376 c-0.318-0.405-0.548-0.872-0.674-1.371c-0.191-0.545-0.429-1.073-0.708-1.578c-0.332-0.6-0.78-1.127-1.318-1.552 c-0.667-0.514-1.414-0.915-2.211-1.187c-1.109-0.355-2.271-0.52-3.435-0.486c-1.193-0.035-2.381,0.162-3.5,0.58 c-0.871,0.341-1.66,0.864-2.312,1.533c-0.611,0.645-1.071,1.417-1.349,2.26C131.768,143.098,131.576,143.983,131.488,144.881z"/>
            <path d="M194.57,161.455c0.91,0.012,1.645,0.746,1.656,1.656v6.506c-0.014,0.909-0.748,1.643-1.656,1.657h-29.091 c-0.911-0.013-1.646-0.747-1.657-1.657v-39.656c-0.009-0.437,0.163-0.857,0.475-1.164c0.309-0.322,0.737-0.499,1.183-0.49h28.597 c0.441-0.004,0.863,0.174,1.168,0.493c0.316,0.304,0.494,0.725,0.489,1.164v6.501c-0.008,0.912-0.745,1.649-1.657,1.657h-18.901 v6.629h17.553c0.912,0.008,1.649,0.746,1.657,1.657v6.506c-0.012,0.91-0.746,1.645-1.657,1.656h-17.553v6.883H194.57z"/>
            <path d="M275.188,128.308c0.44-0.004,0.864,0.174,1.168,0.493c0.317,0.304,0.494,0.725,0.489,1.164v6.501 c-0.008,0.912-0.745,1.649-1.657,1.657H264.39v31.486c-0.015,0.91-0.748,1.644-1.657,1.658h-8.035 c-0.909-0.015-1.643-0.748-1.656-1.658v-31.486h-10.808c-0.911-0.008-1.649-0.746-1.657-1.657v-6.505 c-0.004-0.439,0.173-0.86,0.49-1.164c0.305-0.318,0.727-0.495,1.167-0.49H275.188z"/>
            <path d="M35.015,136.406c-0.757-1.753-1.918-3.302-3.39-4.52c-1.581-1.257-3.404-2.174-5.356-2.693 c-2.301-0.619-4.676-0.917-7.059-0.885H1.657c-0.439-0.005-0.86,0.172-1.164,0.49c-0.318,0.303-0.497,0.724-0.494,1.164v6.178 c0,0.928,0.752,1.68,1.68,1.68l0,0h17.221c1.502-0.061,2.988,0.333,4.264,1.13c1.125,0.753,1.687,1.992,1.687,3.714 s-0.561,2.964-1.684,3.725c-1.276,0.797-2.764,1.191-4.268,1.13H1.68c-0.928,0-1.68,0.752-1.68,1.68v20.416 c-0.003,0.439,0.175,0.86,0.494,1.164c0.303,0.318,0.724,0.497,1.164,0.493h8.042c0.91-0.015,1.643-0.748,1.657-1.657v-12.524h7.854 c2.384,0.036,4.761-0.271,7.059-0.907c1.94-0.538,3.755-1.456,5.337-2.701c1.475-1.197,2.638-2.734,3.39-4.479 c0.85-2.002,1.269-4.161,1.231-6.336C36.266,140.518,35.853,138.385,35.015,136.406z"/>
          </g>
        )
      }
      {...props} />
  )
}
      

export const P = (props: LogoComponentProps): JSX.Element => <Percept short {...props} />
