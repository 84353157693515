import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import brandGrowthPlannerApi from '..'
import { Market } from './types'

export const MarketService = {
  useMarkets: (
    options?: Omit<
      UseQueryOptions<Market[], AxiosError>,
      'queryKey' | 'queryFn'
    >
  ): UseQueryResult<Market[], AxiosError> =>
    useQuery<Market[], AxiosError>(
      ['bgp', 'markets'],
      async (): Promise<Market[]> => {
        const { data } = await brandGrowthPlannerApi.get<Market[]>('/markets/')
        return data
      },
      {
        refetchOnWindowFocus: false,
        ...options,
      }
    ),
}
