import React from 'react'

import {
  BackdropLoader,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  IconButton,
  Typography,
  makeAppStyles,
} from '@percept/mui'

import { Close } from '@percept/mui/icons'

import { SetupMultiFactorAuthentication } from './SetupMultiFactorAuthentication'
import { ToggleMultiFactorAuthentication } from './ToggleMultiFactorAuthentication'

import { useDerivedMFADeliveryMedium, useMyUserDetail, useResetMFASetup } from '@percept/hooks'

import { useAccessToken, MFAComponentProps } from './lib'

import { capitalize } from 'lodash-es'


const { MFA_REQUIRED } = process.env


const useDialogStyles = makeAppStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
  },
})


const useStyles = makeAppStyles( theme => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}))


export type MultiFactorAuthenticationDependencies = {
  accessToken: string
  username: string
}

export type MultiFactorAuthenticationProps = {
  mode: 'SETUP' | 'MANAGE'
  onClose: () => void
}

export const MultiFactorAuthentication = ({
  mode,
  onClose
}: MultiFactorAuthenticationProps): JSX.Element => {

  const activeDeliveryMedium = useDerivedMFADeliveryMedium()

  const accessToken = useAccessToken()

  const [user] = useMyUserDetail()

  const username = user.data && user.data.email

  const resetMFASetup = useResetMFASetup()

  const classes = useStyles()

  const dialogClasses = useDialogStyles()

  const title = `${capitalize(mode)} Two Factor Authentication`

  if( !(accessToken && username) ){
    return <BackdropLoader />
  }

  const handleClose = (): void => {
    onClose()
    resetMFASetup()
  }

  const componentProps: MFAComponentProps = {
    activeDeliveryMedium,
    accessToken,
    username,
    onClose: handleClose,
  }

  return (
    <Dialog
      open
      classes={dialogClasses}
      disableEscapeKeyDown
      disableBackdropClick
      fullWidth
      maxWidth='sm'
      onClose={handleClose}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        { !MFA_REQUIRED && (
          <IconButton
            className={classes.icon}
            onClick={handleClose}>
            <Close />
          </IconButton>
        )}
        <Typography variant='h5'>
          { title }
        </Typography>
      </DialogTitle>

      <Divider />

      <DialogContent>
        { mode === 'MANAGE' ? (
          <ToggleMultiFactorAuthentication {...componentProps} />
        ) : (
          <SetupMultiFactorAuthentication {...componentProps} />
        )}
      </DialogContent>
    </Dialog>
  )
}
