import React, { useState } from 'react'
import {
  Box,
  Chip,
  Dialog,
  IconButton,
  PerceptAppBar,
  RoundedPlainTextButton,
  RoundedPlainTextButtonMenu,
  Typography,
  makeAppStyles,
  useAppTheme,
} from '@percept/mui'
import { ArrowDropDown, ChevronRightThin, Close } from '@percept/mui/icons'
import { SmartCampaignTable, SmartCampaignTableProps } from './SmartCampaignTable'
import { SmartCampaignReportType } from './hooks'
import { QuadrantType } from '@percept/types'
import { capitalize } from 'lodash-es'
import { quadrantContrastTextFill, quadrantHealth } from './QuadrantCharts'
import { format } from 'date-fns'


type CampaignListingParams = {
  dateRange: [Date | null, Date | null]
  reportType: SmartCampaignReportType
}


const useListingStyles = makeAppStyles( theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
  comparisonTypeAppBar: {
    height: '48px !important',
    padding: 0,
  },
  tab: {
    minHeight: '48px important',
    textTransform: 'none',
  },
  tabIndicator: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.contrastText,
  },
  comparisonTypeControl: {
    marginBottom: theme.spacing(2),
  },
  externalLink: {
    textDecoration: 'underline',
    textUnderlineOffset: '2px',
    marginLeft: 3,
    transition: theme.transitions.create('color'),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))

const SMART_CAMPAIGN_LINK = 'https://vodafone.mip.marketlogicsoftware.com/knowledge/5f96a3e123496439ea036d73'

const CampaignListing = ({
  quadrant,
  markets,
  marketLabel,
  dateRange,
  reportType,
}: CampaignListingParams & Pick<SmartCampaignTableProps, 'markets' | 'quadrant'> & {
  quadrant: QuadrantType
  marketLabel: React.ReactNode
}): JSX.Element => {
  const classes = useListingStyles()
  const appTheme = useAppTheme()
  return (
    <div>
      <Typography className={classes.header} variant='h4'>
        { capitalize(reportType) } Campaigns
        <Chip
          className={classes.chip}
          style={{
            color: quadrantContrastTextFill[quadrant],
            backgroundColor: appTheme.chart.healthColourScale(
              quadrantHealth[quadrant]
            ),
          }}
          label={
            `${quadrant.split('_').map(capitalize).join(' ')} Quadrant`
          } />
      </Typography>

      <Box display='flex' alignItems='center' my={1.5} fontWeight={600} fontSize={16}>
        { marketLabel }
        <Box ml={2} display='flex' fontWeight={500} alignItems='center'>
          { format(dateRange[0] as Date, 'MMMM yyyy')}
          <ChevronRightThin fontSize='inherit' style={{margin: '0 4px'}} />
          { format(dateRange[1] as Date, 'MMMM yyyy')}
        </Box>
      </Box>
      
      <Box mb={2} fontSize={15}>
        The full Smart Campaign Assessment report for each campaign can be accessed
        <a
          className={classes.externalLink}
          href={SMART_CAMPAIGN_LINK}
          rel='noopener noreferrer'
          target='_blank'>
          here
        </a>
      </Box>

      <SmartCampaignTable
        start={dateRange[0]}
        end={dateRange[1]}
        markets={markets}
        reportType={reportType}
        quadrant={quadrant} />
    </div>
  )
}


export type SmartCampaignListingProps = {
  quadrant: QuadrantType
  baseline: CampaignListingParams
  comparison: CampaignListingParams | null
  markets: string[]
  marketLabel: React.ReactNode
  onClose: () => void
}

const useStyles = makeAppStyles( theme => ({
  dialogExit:{
    marginLeft: 'auto',
  },
  dialogHeader:{
    fontSize: 18,
  },
  comparisonTypeControl: {
    marginBottom: theme.spacing(2),
  },
}))


export const SmartCampaignListing = ({
  quadrant,
  baseline,
  comparison,
  markets,
  marketLabel,
  onClose,
}: SmartCampaignListingProps): JSX.Element => {

  const [activeTabValue, setActiveTabValue] = useState('baseline')

  const classes = useStyles()

  return (
    <Dialog
      open
      onClose={onClose}
      fullScreen
      scroll='body'>
      <PerceptAppBar
        color='default'>
        <Typography className={classes.dialogHeader} variant='h4'>Smart Campaign Assessment</Typography>
        <IconButton
          className={classes.dialogExit}
          color='inherit'
          onClick={onClose}>
          <Close />
        </IconButton>
      </PerceptAppBar>

      <Box p={3} pt={9.5}>
        { comparison ? (
          <RoundedPlainTextButtonMenu
            TriggerProps={{
              className: classes.comparisonTypeControl,
              variant: 'contained',
              color: 'secondary',
              endIcon: <ArrowDropDown />,
            }}
            value={activeTabValue}
            label={capitalize(activeTabValue)}
            options={[
              { value: 'baseline', label: 'Baseline' },
              { value: 'comparison', label: 'Comparison' },
            ]}
            onChange={(e, value): void => setActiveTabValue(value)} />
        ) : (
          <RoundedPlainTextButton
            className={classes.comparisonTypeControl}
            variant='contained'
            color='secondary'>
            {capitalize(activeTabValue)}
          </RoundedPlainTextButton>
        )}

        { activeTabValue === 'baseline' ? (
          <CampaignListing
            {...baseline}
            quadrant={quadrant}
            marketLabel={marketLabel}
            markets={markets} />
        ) : comparison && (         
          <CampaignListing
            {...comparison}
            quadrant={quadrant}
            marketLabel={marketLabel}
            markets={markets} />
        )}
      </Box>
    </Dialog>
  )
}
