import qs from 'query-string'

import { addNotification } from '../notifications/actions'

import {
  AdminUserListingParams,
  AsyncReduxAction,
  CognitoConfiguration,
  CreateUserParams,
  EditUserParams,
  ReduxAction,
  UserAttributes,
  UserParams,
} from '@percept/types'


const { CORE_API_ROOT } = process.env


const createAsyncAdminUserAction = (action: AsyncReduxAction): AsyncReduxAction => ({
  ...action,
  options: {
    baseURL: `${CORE_API_ROOT}/v1`,
    paramsSerializer: (params): string => {
      return (
        qs.stringify(params, {
          arrayFormat: 'none',
        })
      )
    },
    ...action.options || {},
  }
})


export const LOAD_USERS = 'LOAD_USERS'

export const loadUsers = (params?: AdminUserListingParams): AsyncReduxAction => createAsyncAdminUserAction({
  type: LOAD_USERS,
  resource: '/users/',
  options: { params },
  meta: params,
})


export const LOAD_USER = 'LOAD_USER'

export const loadUser = (
  { cognito_id }: Pick<UserAttributes, 'cognito_id'>
): AsyncReduxAction => createAsyncAdminUserAction({
  type: LOAD_USER,
  resource: `/users/${cognito_id}`,
  meta: { cognito_id },
})


export const CREATE_USER = 'CREATE_USER'

export const createUser = (data: CreateUserParams): AsyncReduxAction => createAsyncAdminUserAction({
  type: CREATE_USER,
  resource: '/users/',
  options: {
    method: 'POST',
    data,
  },
  pendingActions: [
    addNotification({
      id: 'create-user',
      type: 'info',
      loading: true,
      message: 'Creating user...',
      ttl: null,
    })
  ],
  successActions: [
    addNotification({
      id: 'create-user',
      type: 'success',
      message: 'User created',
    }),
    loadUsers(),
  ],
  getErrorActions: error => [
    addNotification({
      id: 'create-user',
      type: 'error',
      message: error.message,
      ttl: 0,
    })
  ],
  notifyError: false,
})

export const RESET_CREATE_USER = 'RESET_CREATE_USER'

export const resetCreateUser = (): ReduxAction => ({
  type: RESET_CREATE_USER,
})


export const EDIT_USER = 'EDIT_USER'

export const editUser = (
  { user_id, ...data }: UserParams & Partial<EditUserParams>
): AsyncReduxAction => createAsyncAdminUserAction({
  type: EDIT_USER,
  resource: `/users/${user_id}`,
  options: {
    method: 'PATCH',
    data,
  },
  pendingActions: [
    addNotification({
      id: 'edit-user',
      type: 'info',
      loading: true,
      message: 'Editing user...',
    })
  ],
  successActions: [
    addNotification({
      id: 'edit-user',
      type: 'success',
      message: 'User successfully edited',
    }),
    loadUsers(),
  ],
  getErrorActions: error => [
    addNotification({
      id: 'edit-user',
      type: 'error',
      message: `User could not be edited: ${error.message}`,
      ttl: 0,
    })
  ],
  notifyError: false,
})

export const RESET_EDIT_USER = 'RESET_EDIT_USER'

export const resetEditUser = (): ReduxAction => ({
  type: RESET_EDIT_USER,
})


export const RESEND_USER_INVITE = 'RESEND_USER_INVITE'

export const resendUserInvite = (
  { user_pool, ...data }: { cognito_id: string, user_pool: CognitoConfiguration }
): AsyncReduxAction => createAsyncAdminUserAction({
  type: RESEND_USER_INVITE,
  resource: '/users/resend-invitation',
  options: {
    method: 'POST',
    params: {
      user_pool,
    },
    data,
  },
  pendingActions: [
    addNotification({
      id: 'resend-user-invite',
      type: 'info',
      loading: true,
      message: `Resending ${user_pool} user invite...`,
      ttl: null,
    })
  ],
  successActions: [
    addNotification({
      id: 'resend-user-invite',
      type: 'success',
      message: `${user_pool} user invite resent`,
    }),
  ],
  getErrorActions: error => [
    addNotification({
      id: 'resend-user-invite',
      type: 'error',
      message: error.message,
      ttl: 0,
    })
  ],
  notifyError: false,
})

export const RESET_RESEND_USER_INVITE = 'RESET_RESEND_USER_INVITE'

export const resetResendUserInvite = (): ReduxAction => ({
  type: RESET_RESEND_USER_INVITE,
})
