import React, { useEffect, useState } from 'react'

import { Box, Card, CardContent, CardHeader, Loader, ProviderLogo, Typography } from '@percept/mui'

import { YoutubeReport } from './YoutubeReport'

import { MetaReport } from './MetaReport'

import { MarketSelect } from 'components/MarketDisplay'

import { MonthSelect } from 'components/Selects'

import { scoreboardHeaders, youtubeHeaders } from '../PartnershipsData'

import { endOfMonth, format, startOfMonth, subYears } from 'date-fns'

import {
  usePartnershipsMarkets,
  usePartnershipsMetaReportForMarket,
  usePartnershipsReferenceDates,
  usePartnershipsYouTubeReportAverageForMarket,
  usePartnershipsYouTubeReportForMarket,
} from '../partnershipsHooks'

import { Market as MarketType } from '../types'

import { get } from 'lodash-es'
import { useCreativeQualityScoreTimeseriesByMarket } from '@percept/hooks'
import { parseCreativeQualityScoreData } from '../dataUtils'



export const formatDate = (date: Date): string => {
  const newDate = date.setDate(1)
  return format(newDate, 'yyyy-MM-dd')
}

export const Market = (): JSX.Element => {

  const referenceDates = usePartnershipsReferenceDates()
  
  const [date, setDate] = useState<Date | null>(null)
  
  useEffect(() => {
    if( referenceDates.data && !date ){
      const latestAvailable = referenceDates.data.combined_providers.report_dates[0]
      if( latestAvailable ){
        setDate(new Date(latestAvailable))
      }
    }
  }, [referenceDates.data, date])
  
  const referenceDate = date && formatDate(date)
  
  const markets = usePartnershipsMarkets()

  const [market, setMarket] = useState<MarketType | null>(null)

  const isoCode = market && market.iso_code

  useEffect(() => {
    if( !market && markets.data ){
      setMarket(markets.data[0])
    }
  }, [markets.data, market])

  const {
    data: ytAverageData,
    isLoading: ytAverageLoading,
    isError: ytAverageError,
  } = usePartnershipsYouTubeReportAverageForMarket(isoCode, referenceDate)

  const {
    data: ytGraphData,
    isLoading: ytGraphDataLoading,
    isError: ytGraphDataError,
  } = usePartnershipsYouTubeReportForMarket(isoCode, referenceDate)

  const {
    data: metaReport,
    isLoading: metaLoading,
    isError: metaError,
  } = usePartnershipsMetaReportForMarket(isoCode, referenceDate)

  const creativeXEndDate = date && endOfMonth(date)
  const creativeXStartDate = creativeXEndDate && startOfMonth(subYears(creativeXEndDate, 1))

  const {
    data: creativeXData,
    isLoading: creativeXLoading,
    isError: creativeXError,
  } = useCreativeQualityScoreTimeseriesByMarket({
    start_date: creativeXStartDate && format(creativeXStartDate, 'yyyy-MM-dd'),
    end_date: creativeXEndDate && format(creativeXEndDate, 'yyyy-MM-dd'),
    filters: [
      {
        publisher: 'youtube'
      }
    ]
  })

  let creativeQualityScore = null
  let globalAverageCreativeQualityScore = null

  if( creativeXData && referenceDate && market ){
    const { grouped, averaged } = parseCreativeQualityScoreData(creativeXData)
    creativeQualityScore = get(grouped, [referenceDate, market.iso_code], null)
    globalAverageCreativeQualityScore = get(averaged, referenceDate, null)
  }

  const monthOptions = get(referenceDates.data, ['combined_providers', 'report_dates'], []).map( d => new Date(d))

  return (
    <>
      <Box display='flex' justifyContent='end' mb={3}>
        { market && markets.data && (
          <Box pr={1}>
            <MarketSelect
              value={market.id}
              options={markets.data}
              onChange={setMarket} />
          </Box>
        )}
        { date && (
          <MonthSelect
            value={date}
            options={monthOptions}
            onChange={setDate} />
        )}
      </Box>
      {(ytAverageLoading || ytGraphDataLoading || creativeXLoading) && (<Loader preset='centered' minHeight='16rem' />)}
      {(ytAverageError || ytGraphDataError || creativeXError) && (
        <Card elevation={2}>
          <CardHeader
            title={
              <ProviderLogo provider='youtube' size={2} />
            } />
          <CardContent>
            <Typography variant='h5'>
              There are no reports available for the selected date, or an error occured.
            </Typography>
          </CardContent>
        </Card>
      )}
      {ytAverageData && ytGraphData && creativeXData && (
        <Box my={4}>
          <YoutubeReport
            report={ytAverageData}
            graphData={ytGraphData}
            headers={youtubeHeaders} 
            creativeQualityScore={creativeQualityScore}
            globalAverageCreativeQualityScore={globalAverageCreativeQualityScore} />
        </Box>
      )}

      {metaLoading && (<Loader preset='centered' minHeight='16rem' />)}
      {metaError && (
        <Card>
          <CardHeader
            title={
              <ProviderLogo provider='META' size={1.5} />
            } />
          <CardContent>
            <Typography variant='h5'>
              There are no reports available for the selected date, or an error occured.
            </Typography>
          </CardContent>
        </Card>
      )}
      {metaReport && (
        <MetaReport reports={metaReport} headers={scoreboardHeaders}/>
      )}
     
    </>
  )
}
