import React, { Fragment } from 'react'

import {
  Chip,
  Tooltip,
  ReportRange,
  makeAppStyles,
} from '@percept/mui'

import { OpenInNew } from '@percept/mui/icons'

import { Link } from 'react-router-dom'

import { StructuralReport } from '@percept/types'


const useStyles = makeAppStyles( theme => ({
  reportChip: {
    height: 'unset',
    lineHeight: 1,
    padding: theme.spacing(0.5, 0, 0.5, 0.25),
    marginLeft: theme.spacing(2),
    cursor: 'pointer',
    fontSize: 13,
  },
  reportChipLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  reportChipIcon: {
    fontSize: '1.25em',
  },
  latestReportHeader: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3),
  },
  reportRange: {
    marginLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    alignItems: 'center',
  },
  reportLink: {
    marginLeft: 'auto',
  },
}))


export const ReportLink = ({
  series_id,
  report,
  text = 'View Latest Report',
}: {
  series_id: string
  report: StructuralReport
  text?: string
}): JSX.Element => {

  const classes = useStyles()
  
  return (
    <Link
      to={`/series/${series_id}/reports/${report.id}`}>
      <Tooltip
        title={text}>
        <Chip
          classes={{
            root: classes.reportChip,
            label: classes.reportChipLabel,
            icon: classes.reportChipIcon,
          }}
          color='secondary'
          icon={<OpenInNew />}
          label={
            <Fragment>
              { text }
              <span className={classes.reportRange}>
                <ReportRange
                  disableTypography
                  start={report.start}
                  end={report.end} />
              </span>
            </Fragment>
          } />
      </Tooltip>
    </Link>
  )
}
