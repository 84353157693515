export const reorderColumns = (list: string[]): string[] => {
  const monthList = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  if (list.length === 0) return list

  if (monthList.includes(list[0].slice(0, 3))) {
    list.sort((a, b) => {
      const first = a.split('-')
      const second = b.split('-')
      if (first[1] === second[1])
        return monthList.indexOf(first[0]) - monthList.indexOf(second[0])
      return Number(first[1]) - Number(second[1])
    })
  }

  if (list[0].slice(0, 2) === 'FY') {
    list.sort((a, b) => Number(a.slice(-2)) - Number(b.slice(-2)))
  }

  if (list[0].slice(0, 2) === 'FQ') {
    list.sort((a, b) => {
      const first = a.split('-')
      const second = b.split('-')
      if (first[1] === second[1])
        return Number(first[0].slice(-1)) - Number(second[0].slice(-1))
      return Number(first[1]) - Number(second[1])
    })
  }

  return list
}
