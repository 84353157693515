
import api from './api'
import adapters from './adapters'


export default api

export * from './adapters'

type SchemaKey = 'audit_payload' | 'audit_payload_root' | 'report_layout'

const getAdapter = (
  { schema_key, schema_version }:
  { schema_key: SchemaKey, schema_version: string }
) => {
  schema_version = schema_version || '1'
  if( adapters[schema_key] && adapters[schema_key][schema_version] ){
    return adapters[schema_key][schema_version]
  }
  throw new Error(`No adapter found for ${schema_key} v${schema_version}.`)
}


export { getAdapter }