
import {
  apiInitialState,
  apiInitialStateWithProcessing,
} from '@percept/redux'

import { Selector } from 'react-redux'

import { getPath } from '@percept/utils'

import { getCreateSeriesKey } from '../../utils'

import {
  ApiResponse,
  ApiStatusResponse,
  SeriesGroup,
  SeriesGroupWrapper,
  Series,
  SeriesGroupParams,
  ReportProvider,
  Nullable,
  ReportLimitParams
} from '@percept/types'

import { SeriesGroupState } from './typings'


type StoreWithSeriesGroups = {
  seriesGroups: SeriesGroupState
}


export const getSeriesGroups: Selector<
  StoreWithSeriesGroups, ApiResponse<SeriesGroup[]>
> = (state) => (
  state.seriesGroups.overview || apiInitialState
)

/**
 * NOTE: 
 * 
 * We expose an optional report limit, which can be configured via action creator params.
 * This _currently_ isn't changed during the lifetime of the app, and so we don't need to
 * store or query multiple variants of series group listing.
 * To opt into this variance explicitly in the future, and also to maintain TypeScript functionality, we expose
 * separate selectors / hooks etc for limited / non-limited series groups.
 * In practice, `getLimitedSeriesGroups` won't be required for the time being,
 * but should be configured to support all features of the API.
 */
export const getLimitedSeriesGroups: Selector<
  StoreWithSeriesGroups, ApiResponse<SeriesGroup[]>, ReportLimitParams
> = (state, params) => (
  getSeriesGroups(state)
)

export const getSeriesGroupWrappers: Selector<
  StoreWithSeriesGroups, Record<string, SeriesGroupWrapper>
> = (state) => (
  state.seriesGroups.byId
)

const getSeriesGroupById: Selector<
  StoreWithSeriesGroups, SeriesGroupWrapper, SeriesGroup['series_group_id']
> = (state, series_group_id) => (
  getPath(state.seriesGroups.byId, [series_group_id], {
    summary: apiInitialState,
    detail: apiInitialState,
    activeProvider: 'adwords',
    activeSeries: null,
    createSeries: {},
  })
)

export const getSeriesGroup: Selector<
  StoreWithSeriesGroups, SeriesGroupWrapper['summary'], SeriesGroupParams
> = (state, { series_group_id }) => (
  getSeriesGroupById(state, series_group_id).summary
)

export const getSeriesGroupDetail: Selector<
  StoreWithSeriesGroups, SeriesGroupWrapper['detail'], SeriesGroupParams
> = (state, { series_group_id }) => (
  getSeriesGroupById(state, series_group_id).detail
)

export const getActiveSeriesGroupProvider: Selector<
  StoreWithSeriesGroups, SeriesGroupWrapper['activeProvider'], SeriesGroupParams
> = (state, { series_group_id }) => (
  getSeriesGroupById(state, series_group_id).activeProvider
)

export const getActiveSeriesGroupSeries: Selector<
  StoreWithSeriesGroups, SeriesGroupWrapper['activeSeries'], SeriesGroupParams
> = (state, { series_group_id }) => (
  getSeriesGroupById(state, series_group_id).activeSeries
)

export const getCreateSeries: Selector<
  StoreWithSeriesGroups, ApiStatusResponse<Series>, SeriesGroupParams & Nullable<{ provider: ReportProvider }>
> = (state, { series_group_id, provider }) => (
  getPath(
    getSeriesGroupById(state, series_group_id).createSeries,
    getCreateSeriesKey({ series_group_id, provider }),
    apiInitialStateWithProcessing,
  )
)

export const getMutateSeriesGroup: Selector<StoreWithSeriesGroups, ApiStatusResponse<SeriesGroup>> = (state) => (
  state.seriesGroups.edit
)

export const getAdwordsSeriesSetup: Selector<
  StoreWithSeriesGroups, ApiStatusResponse, SeriesGroupParams
> = (state, { series_group_id }) => (
  getPath(
    getCreateSeries(state, { series_group_id, provider: 'adwords' }),
    'status',
    apiInitialStateWithProcessing
  )
)
