
import { actionCreator } from '@percept/redux'

import {
  AsyncReduxAction,
  ReduxAction,
  SeriesGroupParams,
  ProviderParams,
  SeriesParams,
  CurrentAdwordsSeriesConfig,
  ReportLimitParams,
} from '@percept/types'


export const LOAD_SERIES_GROUPS = 'LOAD_SERIES_GROUPS'
export const LOAD_SERIES_GROUP = 'LOAD_SERIES_GROUP'
export const LOAD_SERIES_GROUP_DETAIL = 'LOAD_SERIES_GROUP_DETAIL'

export const MUTATE_SERIES_GROUP = 'MUTATE_SERIES_GROUP'
export const CLEAR_MUTATE_SERIES_GROUP = 'CLEAR_MUTATE_SERIES_GROUP'

export const DELETE_SERIES_GROUP = 'DELETE_SERIES_GROUP'
export const CLEAR_DELETE_SERIES_GROUP = 'CLEAR_DELETE_SERIES_GROUP'

export const SET_ACTIVE_SERIES_GROUP_PROVIDER = 'SET_ACTIVE_SERIES_GROUP_PROVIDER'
export const SET_ACTIVE_SERIES_GROUP_SERIES = 'SET_ACTIVE_SERIES_GROUP_SERIES'

export const CREATE_SERIES = 'CREATE_SERIES'
export const CLEAR_CREATE_SERIES = 'CLEAR_CREATE_SERIES'

export const CREATE_TRIAL_SERIES = 'CREATE_TRIAL_SERIES'
export const CLEAR_CREATE_TRIAL_SERIES = 'CLEAR_CREATE_TRIAL_SERIES'

export const COMPLETE_SERIES_SETUP = 'COMPLETE_SERIES_SETUP'
export const CLEAR_SERIES_SETUP = 'CLEAR_SERIES_SETUP'


export const loadSeriesGroups = ({
  reportLimit = 500,
}: ReportLimitParams = {}): AsyncReduxAction => ({
  type: LOAD_SERIES_GROUPS,
  options: {
    params: {
      'report-limit': reportLimit,
    },
  },
  resource: '/series-groups',
})

export const loadSeriesGroup = ({ series_group_id }: SeriesGroupParams): AsyncReduxAction => ({
  type: LOAD_SERIES_GROUP,
  resource: `/series-groups/${series_group_id}`,
  meta: { series_group_id }
})

export const loadSeriesGroupDetail = ({ series_group_id }: SeriesGroupParams): AsyncReduxAction => ({
  type: LOAD_SERIES_GROUP_DETAIL,
  resource: `/series-groups/${series_group_id}/series`,
  meta: { series_group_id }
})

export const deleteSeriesGroup = ({ series_group_id }: SeriesGroupParams): AsyncReduxAction => ({
  type: DELETE_SERIES_GROUP,
  resource: `/series-groups/${series_group_id}`,
  options: {
    method: 'DELETE' 
  },
  meta: { series_group_id }
})

export const clearDeleteSeriesGroup = actionCreator(CLEAR_DELETE_SERIES_GROUP)


type MutableSeriesGroupProps = {
  name: string,
}

export const mutateSeriesGroup = (
  { series_group_id, ...data }: SeriesGroupParams & Partial<MutableSeriesGroupProps>
): AsyncReduxAction => ({
  type: MUTATE_SERIES_GROUP,
  resource: `/series-groups/${series_group_id}`,
  options: {
    method: 'PATCH',
    data 
  },
  meta: { series_group_id }
})

export const clearMutateSeriesGroup = ({ series_group_id }: SeriesGroupParams): ReduxAction => ({
  type: CLEAR_MUTATE_SERIES_GROUP,
  payload: { series_group_id },
  meta: { series_group_id }
})


type CreateSeriesProps = SeriesGroupParams & ProviderParams & {
  name: string
  config: CurrentAdwordsSeriesConfig
  profile: string
  report_layout_id: string
}

export const createSeries = ({ series_group_id, provider, name, config, profile, report_layout_id }: CreateSeriesProps): AsyncReduxAction => ({
  type: CREATE_SERIES,
  resource: `/series-groups/${series_group_id}/series`,
  options: {
    method: 'POST',
    data: {
      provider,
      name,
      config,
      profile,
      report_layout_id,
    }
  },
  meta: { series_group_id, provider }
})

export const clearCreateSeries = ({ series_group_id, provider }: SeriesGroupParams & ProviderParams): ReduxAction => ({
  type: CLEAR_CREATE_SERIES,
  payload: { series_group_id, provider },
  meta: { series_group_id, provider }
})

export const createTrialSeries = ({ series_group_id, provider, name, config }: CreateSeriesProps): AsyncReduxAction => ({
  type: CREATE_TRIAL_SERIES,
  resource: `/series-groups/${series_group_id}/trial-series`,
  options: {
    method: 'POST',
    data: {
      provider,
      name,
      config,
    }
  },
  meta: { series_group_id, provider }
})

export const clearCreateTrialSeries = ({ series_group_id, provider }: SeriesGroupParams & ProviderParams): ReduxAction => ({
  type: CLEAR_CREATE_TRIAL_SERIES,
  payload: { series_group_id, provider },
  meta: { series_group_id, provider }
})


export const completeSeriesSetup = (
  { series_group_id, series_id, config }: SeriesGroupParams & SeriesParams & Pick<CreateSeriesProps, 'config'>
): AsyncReduxAction => ({
  type: COMPLETE_SERIES_SETUP,
  resource: `/series/${series_id}/complete-setup`,
  options: {
    method: 'POST',
    data: {
      config,
    },
  },
  meta: { series_group_id, series_id }
})

export const clearSeriesSetup = ({ series_group_id, series_id }: SeriesGroupParams & SeriesParams): ReduxAction => ({
  type: CLEAR_SERIES_SETUP,
  payload: { series_group_id, series_id },
  meta: { series_group_id, series_id },
})


export const setActiveSeriesGroupProvider = actionCreator(SET_ACTIVE_SERIES_GROUP_PROVIDER)

export const setActiveSeriesGroupSeries = actionCreator(SET_ACTIVE_SERIES_GROUP_SERIES)
