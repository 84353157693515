
import { createApiReducer } from '@percept/redux/reducers'

import { LOAD_SERIES_GROUP } from '../../actions'

import { seriesGroup } from '@percept/api/adapters'


export default createApiReducer(LOAD_SERIES_GROUP, {
  getPayloadFromAction: ({ payload }) => seriesGroup(payload || {})
})
