import { Box, BoxProps, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import { ProviderLogo, makeAppStyles, useAppTheme } from '@percept/mui'
import { AllMarketsYoutubeReportModel, HeaderValues, AllMarketsValues, MarketValue, ScoreboardReportType } from './types'
import { percentageFormatter } from '@percept/mui/charts'

const useStyles = makeAppStyles( theme => ({
  tableHeader: {
    backgroundColor: theme.palette.background.default,
  },
  tableRow: {
    height: '50px'
  },
  tableCell: {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    padding: '2px'
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  itemDividedTop: {
    borderTop: `1px solid ${theme.palette.action.active} !important`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  logo: {
    marginRight: 2,
    position: 'relative',
    top: 1,
  },
  colorGreen: {
    backgroundColor: theme.palette.success.main
  },
  colorYellow: {
    backgroundColor: theme.palette.warning.light
  },
  colorRed: {
    backgroundColor: theme.palette.error.main,
  },
  colorWhite: {
    backgroundColor: theme.palette.primary.contrastText
  },
  colorTurquoise: {
    backgroundColor: theme.palette.info.light,
    opacity: 0.8,
  },
  colorLightGreen: {
    backgroundColor: theme.palette.success.light
  },
  colorOrange: {
    backgroundColor: theme.palette.warning.main
  }
}))

export type ScoreboardTableProps = {
  reports: ScoreboardReportType[]
  additionalInfoReports?: ScoreboardReportType[]
  headers: string[]
  minColorScale: number
  maxColorScale: number
} & BoxProps

export const ScoreboardTable = (
  { reports, additionalInfoReports, headers, minColorScale, maxColorScale, ...props }: ScoreboardTableProps
): JSX.Element => {
  const classes = useStyles()

  const appTheme = useAppTheme()
  const healthColourScale = appTheme.chart.generateHealthColourScale([minColorScale, maxColorScale])
  const getCellColorClass = (value: string | number | null): string => {
    if(!value) return ''
    return healthColourScale(+value)
  }

  return (<Box {...props}>
    <TableContainer component={Paper}>
      <Table aria-label="Scoreboard table">
        <TableHead className={classes.tableHeader}>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell} rowSpan={3} align="center">Market</TableCell>
            <TableCell className={classes.tableCell} colSpan={7} align="center">Foundations</TableCell>
            <TableCell className={classes.tableCell} colSpan={3} align="center">Multipliers</TableCell>
            <TableCell className={classes.tableCell} align="center">Insights</TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell} colSpan={5} align="center">Connected Delivery</TableCell>
            <TableCell className={classes.tableCell} colSpan={2} align="center">Connected Storytelling</TableCell>
            <TableCell className={classes.tableCell} colSpan={2} align="center">Connected Storytelling</TableCell>
            <TableCell className={classes.tableCell} align="center">Connected Delivery</TableCell>
            <TableCell className={classes.tableCell} align="center">Connected Insight</TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            {headers.map(header => (
              <TableCell className={classes.tableCell} key={header} align="center">{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map((report) => (
            <TableRow key={report.label}>
              <TableCell component="th" scope="row">
                {report.label}
              </TableCell>
              {(Object.keys(report) as (keyof ScoreboardReportType)[]).map((key) => {
                if(key !== 'label' && key !== 'ordinal_no'){
                  return (
                    <TableCell 
                      key={key} 
                      align="center"
                      style={{backgroundColor: getCellColorClass(report[key])}} 
                      className={`${classes.tableCell}`} >
                      {report[key] !== null ? Number(report[key]).toFixed(1) : ''}
                    </TableCell>
                  )
                }}
              )}
            </TableRow>
          ))
          }
          {additionalInfoReports && additionalInfoReports.map((report) => (
            <TableRow key={report.label}>
              <TableCell component="th" scope="row">
                {report.label}
              </TableCell>
              {(Object.keys(report) as (keyof ScoreboardReportType)[]).map((key) => {
                if(key !== 'label' && key !== 'ordinal_no'){
                  return (
                    <TableCell className={`${classes.tableCell}`} key={key} align="center">
                      {report[key] !== null ? Number(report[key]).toFixed(1) : ''}
                      {report.label === 'Out of 100' ? '%' : ''}
                    </TableCell>
                  )
                }}
              )}
            </TableRow>
          ))
          }
        </TableBody>
      </Table>
    </TableContainer>  
  </Box>)
}

export type CombinedReport = (
  AllMarketsYoutubeReportModel & {
    creative_quality_score?: AllMarketsValues
  }
)

export type StandardReportsTableProps = {
  report: CombinedReport
} & BoxProps

export const StandardReportsTable = ({ report, ...props }: StandardReportsTableProps): JSX.Element => {
  const classes = useStyles()
  const appTheme = useAppTheme()
  
  const healthColourScale = appTheme.chart.generateHealthColourScale([report.min_value, report.max_value])
  const totalHealthColourScale = appTheme.chart.generateHealthColourScale([report.min_value, report.max_value])
  
  const getCellColorClass = (value: string | number | null): string => {
    if(!value) return ''
    return healthColourScale(+value)
  }
  const getTotalCellColorClass = (value: string | number | null): string => {
    if(!value) return ''
    return totalHealthColourScale(+value)
  }

  return (
    <Box {...props}>
      <TableContainer component={Paper}>
        <Table aria-label="All-Markets table">
          <TableHead className={classes.tableHeader}>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell} />
              {report.header_values.map((header: HeaderValues, index: number) => (
                <TableCell className={classes.tableCell} key={`${header}${index}`} align="center">{header.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {report.data_rows.map((row: AllMarketsValues, index: number) => (
              <TableRow key={`${index}${row}`}>
                <TableCell component="th" scope="row">
                  {row.label}
                </TableCell>
                {row.market_values && row.market_values.map((value: MarketValue) => (
                  <TableCell style={{backgroundColor: getCellColorClass(value.value)}} className={classes.tableCell} align="center" key={value.ordinal_no}>
                    {value.value ? value.value.toFixed(1): '-'}
                  </TableCell>
                ))}
              </TableRow>
            ))}

            {report.total_percent && report.total_percent.market_values.length > 0 && (
              <TableRow>
                <TableCell component="th" scope="row">
                  {report.total_percent.label}
                </TableCell>
                {report.total_percent.market_values.map((value: MarketValue, index: number) => (
                  <TableCell key={`${index}-${value.value}`} className={classes.tableCell} align="center">
                    {value.value ? value.value.toFixed(1) + ` %` : '-'}
                  </TableCell>
                ))}
              </TableRow>
            )}

            {report.campaign_count && report.campaign_count.market_values?.length > 0 && (
              <TableRow>
                <TableCell component="th" scope="row">
                  {report.campaign_count.label}
                </TableCell>
                {report.campaign_count.market_values.map((value: MarketValue, index: number) => (
                  <TableCell key={`${value.value}-${index}`} className={classes.tableCell} align="center">
                    {value.value ? value.value.toFixed(1) : '-'}
                  </TableCell>
                ))}
              </TableRow>
            )}

            {report.creative_quality_score && report.creative_quality_score.market_values.length > 0 && (
              <TableRow className={classes.itemDividedTop}>
                <TableCell className={classes.flexCenter} component="th" scope="row">
                  <ProviderLogo className={classes.logo} provider='CREATIVE_X' size={1.35} />
                  Quality Score
                </TableCell>
                {report.creative_quality_score.market_values.map((value: MarketValue, index: number) => (
                  <TableCell
                    key={`${index}-${value.value}`}
                    className={`${classes.tableCell} ${classes.itemDividedTop}`}
                    align="center">
                    {value.value === null ? '-' : percentageFormatter(value.value * 100)}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
