
import { combineReducers, Reducer } from 'redux'

import {
  TOGGLE_CHART_DETAIL,
  TOGGLE_CHART_SUMMARY,
  TOGGLE_TIPS,
  TOGGLE_TIP,
  ENABLE_ALL_TIPS,
  DISABLE_ALL_TIPS,
  DISMISS_TIP_TOAST,
} from 'redux/actions'

import { getPath } from '@percept/utils'

import { mapValues } from 'lodash-es'

import { MetricDisplayType, TipLabel } from '@percept/types'


type DashboardDisplayState = {
  display: MetricDisplayType
}

const auditChartsInitialState: DashboardDisplayState = {
  display: 'DETAIL'
}

const auditCharts: Reducer<DashboardDisplayState> = (state = auditChartsInitialState, action) => {
  switch(action.type){
    case TOGGLE_CHART_SUMMARY:
      return { ...state, display: 'SUMMARY' }
    case TOGGLE_CHART_DETAIL:
      return { ...state, display: 'DETAIL' }
    default:
      return state
  }
}


type TipState = {
  enabled: boolean
  notified: boolean
  dismissed: boolean
  byLabel: Record<TipLabel, boolean>
}

const tipsInitialState: TipState = {
  enabled: true,
  byLabel: {
    reportSelector: true,
    metricDetail: true,
    metricDimension: true,
    metricExamples: true,
    accountFilter: true,
    priorityMetrics: true, 
  },
  notified: false,
  dismissed: false,
}

const tips: Reducer<TipState> = (state = tipsInitialState, action) => {
  switch(action.type){
    case TOGGLE_TIPS:
      return {
        ...state,
        enabled: !state.enabled
      }

    case TOGGLE_TIP: {
      return {
        ...state,
        byLabel: {
          ...state.byLabel,
          [action.payload]: !getPath(state.byLabel, action.payload)
        },
      }
    }

    case ENABLE_ALL_TIPS:
      return {
        ...state,
        byLabel: mapValues(state.byLabel, () => true)
      }

    case DISABLE_ALL_TIPS:
      return {
        ...state,
        byLabel: mapValues(state.byLabel, () => false)
      }

    case DISMISS_TIP_TOAST:
      return {
        ...state,
        dismissed: true,
      }

    default:
      return state
  }
}


export type SettingsState = {
  auditCharts: DashboardDisplayState
  tips: TipState
}


export default combineReducers<SettingsState>({
  auditCharts,
  tips,
})
