import React, { Fragment, useEffect, useMemo, useState } from 'react'

import {
  Box,
  BackdropLoader,
  makeAppStyles,
  ProviderLogo,
  RoundedPlainTextButtonMenu,
  Slider,
  Typography,
  useAppTheme,
  formatMoney,
} from '@percept/mui'

import { BasicSankeyLink, BasicSankeyNode, ResponsiveSankey, SankeyProps } from '@percept/mui/charts'

import { ArrowDropDown } from '@percept/mui/icons'

import { PerformanceDimensionSelect } from 'components/PerformanceDimensionSelect'

import {
  useNavigation,
  useNestedProviderPillarScores,
  usePerformanceTimeseriesByProvider,
  usePlatformUnitProviderInfo,
} from '@percept/hooks'

import {
  useNestedTimeseriesByProvider,
  useTargetCurrency,
} from 'hooks'

import { find, findIndex, flatten, get, intersection, some, sortBy, uniq } from 'lodash-es'

import { format } from 'date-fns'

import { displayNumber, isLoading, isMonetaryDimension } from '@percept/utils'

import { resolvePerformanceReportingDimensions } from 'components/Organisation/lib'

import { deriveLatestAvailableReferenceDate } from 'components/ReferenceDates'

import { dimensionMap, providerChannelMap, providerLabelMap } from '@percept/constants'

import {
  HealthType,
  AnyPerformanceDimension,
  PlatformUnit,
  PlatformUnitParams,
  ReportProvider,
} from '@percept/types'



type HealthSankeyLink = BasicSankeyLink & {
  health?: HealthType
  provider?: ReportProvider
  url?: string
}

type HealthSankeyProps = SankeyProps<BasicSankeyNode, HealthSankeyLink>


export type HealthVsPerformanceViewProps = PlatformUnitParams & {
  ready: boolean
  platformUnit: PlatformUnit | null
  defaultProviders: ReportProvider[]
  activeProviders: ReportProvider[]
  setActiveProviders: (providers: ReportProvider[]) => void
}


const useStyles = makeAppStyles( theme => ({
  spacedRight: {
    marginRight: theme.spacing(2),
  },
  providerLogo: {
    minHeight: '1.65em',
  },
}))



const nodeSort: HealthSankeyProps['nodeSort'] = (a, b) => {
  const aVal = Number(a.value),
        bVal = Number(b.value)
  if( aVal > bVal ) return -1
  if( aVal < bVal ) return 1
  return 0
}


const orderLinksForRender: HealthSankeyProps['orderLinksForRender'] = (links) => {
  return sortBy(links, l => (l as unknown as HealthSankeyLink).health ).reverse()
}


type TimeDatum = {
  start?: number
  date?: number
}

function makeGetMatchedDatapoint<T extends TimeDatum>(
  monthString: string,
): ((data: T[] | null | undefined) => T | null) {
  return (data): T | null => {
    if( !data || !data.length ) return null
    for( const d of data ){
      const time = d.date || d.start
      if( time ){
        const dMonth = format(new Date(time), 'MMMM yyyy')
        if( dMonth === monthString ){
          return d
        }
      }
    }
    return null
  }
}


const makeKey = (...strings: string[]): string => strings.join('-')


const getBinnedHeath = (health: HealthType): number => {
  health = Number(health)
  if( health < 0.5 ) return 0
  if( health < 0.7 ) return 0.5
  return 1
}


export const HealthVsPerformanceView = ({
  org_unit_id,
  activeProviders,
  setActiveProviders,
  platformUnit,
  defaultProviders,
  ready,
}: HealthVsPerformanceViewProps): JSX.Element => {

  const org_unit_ids = useMemo(() => {
    if( platformUnit && platformUnit.children && platformUnit.children.length ){
      return platformUnit.children.map( ({ id }) => id )
    }else{
      return []
    }
  }, [platformUnit])

  const [providerInfo] = usePlatformUnitProviderInfo({ org_unit_id })

  const referenceDate = useMemo(() => {
    return deriveLatestAvailableReferenceDate({
      providerInfo: providerInfo.data,
      enabledProviders: activeProviders,
    })
  }, [providerInfo.data, activeProviders])

  const [currency] = useTargetCurrency()

  const [activeDimension, setActiveDimension] = useState<AnyPerformanceDimension>('cost')

  const availableDimensions = useMemo(() => {
    const platformUnits = platformUnit && platformUnit.children || []
    return intersection(
      ['cost','impressions', 'clicks', 'conversions'] as AnyPerformanceDimension[],
      uniq(flatten(
        platformUnits.map( platformUnit => resolvePerformanceReportingDimensions({
          platformUnit,
        }))
      ))
    )
  }, [platformUnit])

  const referenceDateParam = referenceDate && format(referenceDate, 'yyyy-MM-dd')

  const [timeseriesByProvider] = usePerformanceTimeseriesByProvider({
    org_unit_id,
    period: 'DAYS_365',
    chunking: 'MONTH',
    target_currency: currency,
    ...(referenceDateParam && {
      reference_date: referenceDateParam,
    })
  })

  const providerTimeseriesByUnit = useNestedTimeseriesByProvider({
    org_unit_ids,
    period: 'DAYS_365',
    chunking: 'MONTH',
    target_currency: currency,
    ...(referenceDateParam && {
      reference_date: referenceDateParam,
    })
  })

  const [nestedPillarScores] = useNestedProviderPillarScores({
    org_unit_id,
  })

  const dateSelectOptions = useMemo(() => {
    if( !nestedPillarScores.data ){
      return []
    }
    const options = new Set<string>()
    nestedPillarScores.data.forEach( ({ byProvider }) => {
      Object.values(byProvider).forEach( ({ scores }) => {
        scores.forEach( score => {
          options.add(format(new Date(score.date), 'yyyy-MM-dd'))
        })
      })
      byProvider.adwords.scores.forEach
    })
    return Array.from(options).sort().map( d => new Date(d))
  }, [nestedPillarScores.data])

  const [activeMonth, setActiveMonth] = useState<Date | null>(null)

  const [sliderValue, setSliderValue] = useState(-1)
  
  useEffect(() => {
    if( sliderValue === -1 && activeMonth ){
      const index = findIndex(dateSelectOptions, d => d.getTime() === activeMonth.getTime())
      if( index !== -1 ){
        setSliderValue(index)
      }
    }
  }, [sliderValue, activeMonth, dateSelectOptions])

  useEffect(() => {
    if( !activeMonth && dateSelectOptions.length ){
      setActiveMonth(dateSelectOptions[dateSelectOptions.length - 1])
    }
  }, [activeMonth, dateSelectOptions])

  const theme = useAppTheme()

  const sankeyData = useMemo(() => {

    const pillarsByUnit = nestedPillarScores.data
    const byProvider = timeseriesByProvider.data
    const nestedByProvider = providerTimeseriesByUnit.data

    if( !(platformUnit && pillarsByUnit && byProvider && nestedByProvider && activeMonth) ){
      return {
        nodes: [],
        links: [],
      }
    }
    const orgs = platformUnit.children || []

    if( platformUnit && orgs.length < 2 ){
      throw new Error('Bad redirect! Too many child orgs for this view')
    }

    const initialNodes = [{
      id: activeDimension,
      name: dimensionMap[activeDimension].text,
    }]

    const monthString = activeMonth && format(activeMonth, 'MMMM yyyy') || ''

    const getMatchedDatapoint = makeGetMatchedDatapoint(monthString)

    const providerGraphData: Pick<HealthSankeyProps, 'nodes' | 'links'> = (
      activeProviders.length < 2 ?
        {
          nodes: initialNodes,
          links: []
        } : (
          activeProviders.reduce( (acc, provider) => {
            const channel = providerChannelMap[provider]

            const color = theme.palette.channel[channel].main

            const providerDatapoint = getMatchedDatapoint(
              get(byProvider[provider], ['datasets', activeDimension]),
            )

            acc.nodes.push({
              id: provider,
              name: providerLabelMap[provider],
              color,
            })
            acc.links.push({
              key: makeKey(activeDimension, provider),
              provider,
              target: provider,
              source: activeDimension,
              value: get(providerDatapoint, 'value') || 0, 
              color,
            })

            return acc
          }, {
            nodes: initialNodes,
            links: []
          } as Pick<HealthSankeyProps, 'nodes' | 'links'>)
        )
    )

    const graphData = orgs.reduce( (acc, { id, name }) => {
      const pillarData = find(pillarsByUnit, u => u.org_unit_id === id )

      if( pillarData ){

        const performanceLinks: HealthSankeyProps['links'] = []

        activeProviders.forEach( provider => {
          const healthDatapoint = getMatchedDatapoint(
            get(pillarData.byProvider, [provider, 'scores'])
          )
          const health = get(healthDatapoint, 'overall')
          if( health !== null ){
            const binnedHealth = getBinnedHeath(health)
            const color = theme.chart.healthColourScale(binnedHealth)
            // const color = theme.chart.healthColourScale(Number(health))
            const performanceDatapoint = getMatchedDatapoint(
              get(nestedByProvider, [id, provider, 'datasets', activeDimension])
            )
            const performanceValue = get(performanceDatapoint, 'value') || 0
            if( performanceValue ){
              const source = activeProviders.length === 1 ? activeDimension : provider
              performanceLinks.push({
                key: makeKey(source, id),
                target: id,
                source,
                value: performanceValue,
                color,
                health,
                provider,
                url: `/dashboards/${id}`,
              })
            }
          }
        })

        if( performanceLinks.length ){
          acc.links = acc.links.concat(performanceLinks)
          acc.nodes.push({
            id,
            name,
          })
        }
      }

      return acc
    }, providerGraphData)

    return graphData

  }, [
    platformUnit, nestedPillarScores.data, timeseriesByProvider.data, providerTimeseriesByUnit.data,
    activeDimension, activeProviders, activeMonth, theme
  ])

  const loading = some([
    nestedPillarScores,
    providerInfo,
    timeseriesByProvider,
    providerTimeseriesByUnit,
  ], isLoading) || activeProviders.length === 0

  const classes = useStyles()

  const navigate = useNavigation()

  const valueFormatter = (value: number | undefined): string => (
    isMonetaryDimension(activeDimension) ?
      formatMoney({ amount: value || 0, currency, abbreviate: true }) :
      displayNumber(value || 0)
  )

  return (
    <Fragment>

      <Box
        mt={5}
        px={3}>

        { ready && activeProviders.length !== 0 && (
          <Box display='flex'>

            <Box display='flex' alignItems='center' mr={1}>

              <RoundedPlainTextButtonMenu
                TriggerProps={{
                  className: classes.spacedRight,
                  variant: 'contained',
                  color: 'secondary',
                  endIcon: <ArrowDropDown />
                }}
                value={
                  activeProviders.length === 1 ?
                    activeProviders[0] :
                    'all'
                }
                label={
                  activeProviders.length === 1 ? (
                    <ProviderLogo
                      className={classes.providerLogo}
                      size={1.5}
                      provider={activeProviders[0]} />
                  ) : (
                    'All Providers'
                  )
                }
                options={[
                  ...(defaultProviders.length > 1) && [{
                    value: 'all',
                    label: 'All Providers',
                  }] || [],
                  ...defaultProviders.map( value => ({
                    value,
                    label: (
                      <ProviderLogo
                        className={classes.providerLogo}
                        size={1.5}
                        provider={value} />
                    )
                  }))
                ]}
                onChange={(e, value: ReportProvider | 'all'): void => {
                  if( value !== 'all' ){
                    setActiveProviders([value])
                  }else{
                    setActiveProviders(defaultProviders)
                  }
                }} />

              <PerformanceDimensionSelect
                value={activeDimension}
                availableDimensions={availableDimensions}
                TriggerProps={{
                  className: classes.spacedRight,
                  color: 'secondary',
                  variant: 'contained',
                }}
                onChange={(e, value): void => {
                  setActiveDimension(value)
                }} />

            </Box>

            { activeMonth && ready && (
              <Box
                display='flex'
                alignItems='center'
                flexGrow={1}
                justifyContent='space-between'>
                <Typography
                  variant='h5'>
                  { format(activeMonth, 'MMMM yyyy') }
                </Typography>

                <Box
                  minWidth='30rem'
                  height={24}
                  display='flex'
                  alignItems='center'
                  ml={3}
                  mr={1}>
                  <Slider
                    color='secondary'
                    track={false}
                    value={sliderValue}
                    min={0}
                    max={dateSelectOptions.length - 1}
                    marks={
                      dateSelectOptions.map( (d, i) => ({
                        value: i,
                        label: format(d, 'MMM'),
                      }))
                    }
                    onChange={(e, value): void => {
                      setSliderValue(value as number)
                    }}
                    onChangeCommitted={(e, value): void => {
                      const index = value as number
                      setSliderValue(index)
                      const newMonth = dateSelectOptions[index]
                      if( newMonth ){
                        setActiveMonth(newMonth)
                      }
                    }} />

                </Box>

              </Box>
            )}

          </Box>
        )}        

        { !!(ready && !loading && platformUnit && sankeyData.links.length) && (
          <Box mt={4} height='calc(100vh - 15rem)'>
            <ResponsiveSankey
              valueFormatter={valueFormatter}
              nodePadding={20}
              nodeColor={theme.palette.primary.main}
              nodeSort={nodeSort}
              orderLinksForRender={orderLinksForRender}
              onLinkClick={(e, link): void => {
                if( link.url ){
                  navigate(link.url)
                }else if( link.provider ){
                  setActiveProviders([link.provider])
                }
              }}
              {...sankeyData} />
          </Box>
        )}

      </Box>

      <BackdropLoader
        BackdropProps={{
          open: loading || !ready,
        }} />

    </Fragment>
  )
}
