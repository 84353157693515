import axios from 'axios'
import qs from 'query-string'

const { MEDIA_INVESTMENT_API_ROOT } = process.env

export const apiClient = axios.create({
  baseURL: `${MEDIA_INVESTMENT_API_ROOT}/v1`,
  paramsSerializer: (params): string => (
    qs.stringify(params, {
      arrayFormat: 'none',
    })
  ),
})
apiClient.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${sessionStorage.getItem('PERCEPT_TOKEN')}`
  return config
},
(err) => Promise.reject(err)
)
