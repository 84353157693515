
import React from 'react'

import { Button, Chip, IconButton } from '@material-ui/core'

import { PlainTextButton, RoundedPlainTextButton } from '../Buttons'

import { makeMenuComponent } from './makeMenuComponent'


export * from './makeMenuComponent'


export const ButtonMenu = makeMenuComponent({
  TriggerComponent: Button,
  getTriggerProps: ({ label, value }) => ({ children: label || value as React.ReactNode })
})


export const ChipMenu = makeMenuComponent({
  TriggerComponent: Chip,
  getTriggerProps: ({ label, value }) => ({ label: label || value as React.ReactNode })
})

export const PlainTextButtonMenu = makeMenuComponent({
  TriggerComponent: PlainTextButton,
  getTriggerProps: ({ label, value }) => ({ children: label || value as React.ReactNode })
})

export const RoundedPlainTextButtonMenu = makeMenuComponent({
  TriggerComponent: RoundedPlainTextButton,
  getTriggerProps: ({ label, value }) => ({ children: label || value as React.ReactNode })
})


export const IconButtonMenu = makeMenuComponent({
  TriggerComponent: IconButton,
  getTriggerProps: ({ label, value }) => ({ children: label || value as React.ReactNode })
})
