import { FilterItem, OptionType } from './typings'
import Api, { InvestmentFilter, InvestmentFiltersResponse } from '../../../api/services/Api'
import { Filters as ListOfFilters } from '../../../enums/Filters'
import { useMemo } from 'react'
import { flatten, sortBy, uniqBy } from 'lodash-es'
import { investmentFilterToOptionType } from '../utils'


const filterMap: Record<string, InvestmentFilter> = {
  agency: 'agency',
  buy_type: 'buy_type',
  [ListOfFilters.BuyType]: 'buy_type',
  [ListOfFilters.Campaign]: 'campaigns_pillar',
  [ListOfFilters.SubCampaign]: 'campaigns_sub_pillar',
  department: 'department',
  [ListOfFilters.FinancialYear]: 'financial_year',
  funding_source: 'funding_source',
  jbp: 'jbp',
  [ListOfFilters.Markets]: 'org_unit',
  [ListOfFilters.MediaChannel]: 'media_channel',
  [ListOfFilters.MediaSubChannel]: 'media_sub_channel',
  message: 'message',
  product: 'product',
  [ListOfFilters.SecondBrand]: 'second_brand',
}


const flattenFiltersResponse = (response: InvestmentFiltersResponse): Record<InvestmentFilter, OptionType[]> => {
  return {
    agency: response.agencies.map(investmentFilterToOptionType),
    buy_type: response.buy_types.map(investmentFilterToOptionType),
    campaigns_pillar: response.campaigns.map(investmentFilterToOptionType),
    campaigns_sub_pillar: sortBy(
      uniqBy(
        flatten(response.campaigns.map( c =>
          c.children.map( s => ({
            ...s,
            name: `${c.name} - ${s.name}`
          }))
        )),
        'id'
      ),
      'name'
    ).map(investmentFilterToOptionType),
    department: sortBy(
      uniqBy(
        flatten(response.org_units.map( o => o.departments )),
        'id'
      ),
      'name'
    ).map(investmentFilterToOptionType),
    financial_year: response.financial_years.map(investmentFilterToOptionType),
    funding_source: response.funding_sources.map(investmentFilterToOptionType),
    jbp: response.jbps.map(investmentFilterToOptionType),
    media_channel: response.media_channels.map(investmentFilterToOptionType),
    media_sub_channel: sortBy(
      uniqBy(
        flatten(response.media_channels.map( m =>
          m.children.map( c => ({
            ...c,
            name: `${m.name} - ${c.name}`
          }))
        )),
        'id'
      ),
      'name'
    ).map(investmentFilterToOptionType),
    message: response.messages.map(investmentFilterToOptionType),
    org_unit: response.org_units.map(investmentFilterToOptionType),
    product: response.products.map(investmentFilterToOptionType),
    second_brand: uniqBy(
      flatten(response.org_units.map( o => o.second_brands )),
      'id'
    ).map(investmentFilterToOptionType),
  }
}

export const useInvestmentFilters = (
  filterItems: FilterItem[]
): { loading: boolean; result: FilterItem[]; } => {

  const response = Api.useInvestmentFilters()

  const filters = useMemo<FilterItem[]>(() => {
    if( !response.data ){
      return []
    }
    const flattenedFilters = flattenFiltersResponse(response.data)
    return filterItems.map( filterItem => {
      return {
        ...filterItem,
        groups: filterItem.groups.map((group) => {
          const { group_value } = group
          const key = filterMap[group_value]
          if( key && flattenedFilters[key] ){
            return {...group, options: flattenedFilters[key]}
          }
          if( group_value !== 'calendar-year' ){
            console.warn('MISSING FILTER MAPPING', group_value, key)
          }
          return group
        }),
      }
    })
  }, [response.data, filterItems])

  return { result: filters, loading: response.isLoading }
}
