import { useMemo } from 'react'

import { CreativeWastageResponseWithMarket, OrgUnitProviderPotentialEfficiencyScores } from '@percept/hooks'

import {
  getDerivedPotentialEfficienciesByIsoCode,
  getDerivedPotentialEfficiencyDatum,
  getDerivedTotalWastageDatum,
  resolveCreativeXDatasetsByMarket,
  isCreativeXDimension,
  staticDimensions,
} from './dataUtils'

import { TableConfig, makeTables } from './lib'

import { get, mapValues, sortBy, sum, uniqBy, zip } from 'lodash-es'

import { addMonths, format, isBefore, isEqual } from 'date-fns'

import { ComparisonMethod, getComparisonFunction, getFinancialYearStart, parseFinancialYearStart, produceKeyedMapping } from '@percept/utils'

import { getComparisonDateRanges } from './utils'

import { vodafoneMarkets } from 'vodafoneMarkets'

import { quarterlyByMarket, yearlyByMarket } from './fixtureData'

import MediaInvestmentApi from 'dashboard/src/api/services/Api'
import InvestmentReportApi from 'dashboard/src/api/services/MediaInvestment'
import CompetitiveApi from 'dashboard/src/api/services/CompetitiveReports'
import { InvestmentFiltersResponse } from 'dashboard/src/api/services/Api'
import { CellItem, ListItem, NestedItem } from 'dashboard/src/components/ReportingDashboard/types'

import {
  BreakdownDatum,
  CreativeXDimension,
  MarketDatum,
  MarketOverviewDatum,
  OptionalComparisonDateRanges,
  OptionalDateRange,
  OverviewEntity,
  OverviewGranularity,
  OverviewTableRow,
} from './typings'
import { PerformanceValue } from '@percept/types'


export type GlobalOverviewTablesHookProps = {
  referenceDate: Date
  granularity: OverviewGranularity
  dateRanges: OptionalComparisonDateRanges
  comparisonMethod: ComparisonMethod
  primaryCreativeWastageByMarket: CreativeWastageResponseWithMarket[] | undefined
  comparisonCreativeWastageByMarket: CreativeWastageResponseWithMarket[] | undefined
  potentialEfficiencyRatiosByMarket: OrgUnitProviderPotentialEfficiencyScores[] | undefined
  secondaryPotentialEfficiencyRatiosByMarket: OrgUnitProviderPotentialEfficiencyScores[] | undefined
  investmentDataByMarket: MediaInvestmentReportsByMarket['data'] | undefined
  competitiveSOSByMarket: Record<string, GenericMarketDatum> | undefined
}

export const useGlobalOverviewTables = ({
  referenceDate,
  granularity,
  dateRanges,
  comparisonMethod,
  primaryCreativeWastageByMarket,
  comparisonCreativeWastageByMarket,
  potentialEfficiencyRatiosByMarket,
  secondaryPotentialEfficiencyRatiosByMarket,
  investmentDataByMarket,
  competitiveSOSByMarket,
}: GlobalOverviewTablesHookProps): TableConfig[] => {
  return useMemo(() => {
    
    let creativeDatasets: Record<string, Record<CreativeXDimension, MarketDatum>> | null = null
    let potentialEfficiencyDatasets: Record<string, MarketDatum> | null = null
    let totalWastageDatasets: Record<string, MarketDatum> | null = null
    if(
      primaryCreativeWastageByMarket
      && comparisonCreativeWastageByMarket
      && potentialEfficiencyRatiosByMarket
      // && secondaryCreativeWastageByMarket
      // && secondaryComparisonCreativeWastageByMarket
    ){
      creativeDatasets = resolveCreativeXDatasetsByMarket({
        primary: primaryCreativeWastageByMarket,
        comparison: comparisonCreativeWastageByMarket,
        // secondary: secondaryCreativeWastageByMarket,
        // secondaryComparison: secondaryComparisonCreativeWastageByMarket,
        granularity,
        comparisonMethod,
      })

      const combinedPotentialEffiencies = [
        ...potentialEfficiencyRatiosByMarket,
        ...(secondaryPotentialEfficiencyRatiosByMarket || []),
      ]

      const primaryPotentialEfficiencies = getDerivedPotentialEfficienciesByIsoCode(
        combinedPotentialEffiencies,
        dateRanges.primary,
      )

      const comparisonPotentialEfficiencies = getDerivedPotentialEfficienciesByIsoCode(
        combinedPotentialEffiencies,
        dateRanges.comparison,
      )

      const primaryCreativeWastageMapping = produceKeyedMapping(primaryCreativeWastageByMarket, 'market')
      const comparisonCreativeWastageMapping = produceKeyedMapping(comparisonCreativeWastageByMarket, 'market')

      potentialEfficiencyDatasets = vodafoneMarkets.reduce( (acc, { iso_code }) => {
        acc[iso_code] = {
          granularity,
          ...getDerivedPotentialEfficiencyDatum({
            primary: primaryPotentialEfficiencies[iso_code] || null,
            comparison: comparisonPotentialEfficiencies[iso_code] || null,
            comparisonMethod,
          }),
        }
        return acc
      }, {} as Record<string, MarketDatum>)

      totalWastageDatasets = vodafoneMarkets.reduce( (acc, { iso_code, creativeXMarket }) => {
        acc[iso_code] = {
          granularity,
          ...getDerivedTotalWastageDatum({
            primaryCreativeWastage: primaryCreativeWastageMapping[creativeXMarket] || null,
            secondaryCreativeWastage: comparisonCreativeWastageMapping[creativeXMarket] || null,
            primaryPotentialEfficiency: primaryPotentialEfficiencies[iso_code] || null,
            secondaryPotentialEfficiency: comparisonPotentialEfficiencies[iso_code] || null,
            comparisonMethod,
          }),
        }
        return acc
      }, {} as Record<string, MarketDatum>)
    }

    const overviewData = vodafoneMarkets.map( ({ iso_code, shareOfSpendTarget }) => {
      const quarterlyValues = quarterlyByMarket[iso_code as OverviewEntity]
      const yearlyValues = yearlyByMarket[iso_code as OverviewEntity]
      if( !quarterlyValues || !yearlyValues ){
        return null
      }

      const sourceValues = granularity === 'quarter' ? quarterlyValues : yearlyValues

      const scores = sourceValues.scores.map( values => {

        if( isCreativeXDimension(values.dimension) && creativeDatasets ){
          return creativeDatasets[iso_code][values.dimension]
        }

        if( values.dimension === 'digital_wastage' && potentialEfficiencyDatasets ){
          return potentialEfficiencyDatasets[iso_code]
        }

        if( values.dimension === 'total_wastage' && totalWastageDatasets ){
          return totalWastageDatasets[iso_code]
        }

        if( values.dimension === 'share_of_spend' && competitiveSOSByMarket ){
          return competitiveSOSByMarket[iso_code] ? {
            ...competitiveSOSByMarket[iso_code],
            target: shareOfSpendTarget,
            dimension: 'share_of_spend',
            granularity,
          } : null
        }

        if( granularity !== 'quarter' && staticDimensions.includes(values.dimension) ){
          console.warn('Static dimension', values.dimension)
          // NOTE! The actual static yearly values are stored in the quarterly values file for some reason.
          return quarterlyValues.scores.find( v => v.dimension === values.dimension ) || null
        }
        return values
      }).filter( v => v !== null ) as MarketDatum[]

      const derivedScores: MarketDatum[] = sourceValues.breakdowns.map( b => {

        if( b.dimension === 'brand_investment_share' && investmentDataByMarket ){
          return investmentDataByMarket.brand_investment_share[iso_code] || null
        }

        if( b.dimension === 'share_of_spend' && competitiveSOSByMarket ){
          return competitiveSOSByMarket[iso_code] ? {
            ...competitiveSOSByMarket[iso_code],
            target: shareOfSpendTarget,
            dimension: 'share_of_spend',
            granularity,
          } : null
        }

        // We probably want to support the following static breakdown calculation as a fallback,
        // but we should now finally be using API values for all of this data.
        console.error(b.dimension)

        let sourceBreakdown: BreakdownDatum | null = b
        if( staticDimensions.includes(b.dimension) ){
          // NOTE! The actual static yearly values are stored in the quarterly values file for some reason.
          sourceBreakdown = quarterlyValues.breakdowns.find( q => q.dimension === b.dimension ) || null
        }
        if( !sourceBreakdown ){
          return null
        }
        const matchedDatumLabel = (
          b.dimension === 'share_of_spend' ?
            'Vodafone' :
            'All Media'
        )
        const matchedDatum = sourceBreakdown.segments.find( s => s.segment === matchedDatumLabel )
        return {
          dimension: b.dimension,
          granularity,
          current: get(matchedDatum, 'current', null),
          value: get(matchedDatum, 'value', null),
          previous: get(matchedDatum, 'previous', null),
          change: get(matchedDatum, 'change'),
          target: get(matchedDatum, 'target', null),
        }
      }).filter( b => b !== null ) as MarketDatum[]
      return {
        market_name: iso_code,
        scores: [...scores, ...derivedScores],
      }
    }).filter( v => v !== null ) as MarketOverviewDatum[]

    return makeTables(overviewData, granularity, referenceDate)
  }, [
    granularity,
    dateRanges,
    referenceDate,
    comparisonMethod,
    primaryCreativeWastageByMarket,
    comparisonCreativeWastageByMarket,
    potentialEfficiencyRatiosByMarket,
    secondaryPotentialEfficiencyRatiosByMarket,
    investmentDataByMarket,
    competitiveSOSByMarket,
    // secondaryCreativeWastageByMarket.data,
    // secondaryComparisonCreativeWastageByMarket.data,
  ])
}


const getMonthLabels = (dateRange: OptionalDateRange): string[] => {
  if( !dateRange ){
    return []
  }
  let current = dateRange.start
  const monthLabels: string[] = []
  while( isBefore(current, dateRange.end) ){
    monthLabels.push(format(current, 'MMM-yy'))
    current = addMonths(current, 1)
  }
  return monthLabels
}

const collectCellItemValues = (
  cellItems: NestedItem['costs'],
  labels: string[],
  key: 'spend' | 'percent',
): number[] => {
  return cellItems.reduce( (acc, cellItem) => {
    if( labels.includes(cellItem.type_value) ){
      acc.push(Number(cellItem[key]))
    }
    return acc
  }, [] as number[])
}

const aggregateListItemTotalSpend = (
  listItem: ListItem,
  labels: string[]
): number => (
  sum(
    collectCellItemValues(listItem.costs, labels, 'spend')
  )
)

const aggregateListItemTypeValueSpend = (
  listItem: ListItem,
  labels: string[],
  typeValue: string
): number | null => {
  const matchingItem = (listItem.data || []).find( d => d.data_type === typeValue )
  if( !matchingItem ){
    return null
  }
  return sum(
    collectCellItemValues(matchingItem.costs, labels, 'spend')
  )
}

const getMediaInvestmentListItemPercentage = (
  listItem: ListItem,
  labels: string[],
  itemTypeValue: string
): number | null => {
  const itemTypeSum = aggregateListItemTypeValueSpend(listItem, labels, itemTypeValue)
  if( itemTypeSum === null ){
    return null
  }
  const totalSum = aggregateListItemTotalSpend(listItem, labels)
  if( !totalSum ){
    return null
  }
  return itemTypeSum / totalSum * 100
}


export type GenericGlobalDataHookProps = {
  referenceDate: Date | null
  granularity?: OverviewGranularity
  comparisonMethod?: ComparisonMethod
  enabled?: boolean
}


export type MediaInvestmentReportsByMarketHookProps = (
  GenericGlobalDataHookProps & {
    includeGroups?: boolean
  }
)

export type MediaInvestmentReportsByMarket = {
  isLoading: boolean
  isError: boolean
  data: Record<
    'brand_investment_share',
    Record<string, MarketDatum>
  > | undefined
}

export const useMediaInvestmentReportsByMarket = ({
  referenceDate,
  granularity = 'year',
  comparisonMethod = 'ABSOLUTE',
  enabled = true,
  includeGroups = false,
}: MediaInvestmentReportsByMarketHookProps): MediaInvestmentReportsByMarket => {

  const dateRanges = getComparisonDateRanges({
    primaryGranularity: granularity,
    secondaryGranularity: granularity,
    referenceDate,
  })

  const brandInvestmentShare = InvestmentReportApi.useBrandPerformance({
    period: 'monthly',
    currencyType: 'dynamic',
    enabled,
  })

  const primaryMonthLabels = getMonthLabels(dateRanges.primary)
  const comparisonMonthLabels = getMonthLabels(dateRanges.comparison)

  let brandInvestmentShareDatasets: Record<string, MarketDatum> = {}

  const comparisonFunction = getComparisonFunction(comparisonMethod)

  if( brandInvestmentShare.data ){
    brandInvestmentShareDatasets = brandInvestmentShare.data.reduce( (acc, item) => {
      if( !includeGroups && item.row_group === 'All total' ){
        return acc
      }

      const mappingKey = item.row_group === 'All total' ? item.row_group : item.row_group.slice(-2)

      const current = getMediaInvestmentListItemPercentage(
        item, primaryMonthLabels, 'Brand'
      )
      const previous = getMediaInvestmentListItemPercentage(
        item, comparisonMonthLabels, 'Brand'
      )

      acc[mappingKey] = {
        granularity,
        dimension: 'brand_investment_share',
        current,
        previous,
        change: comparisonFunction(current, previous),
        target: 60
      }

      return acc
    }, {} as Record<string, MarketDatum>)

  }

  const isLoading = brandInvestmentShare.isLoading || !referenceDate || !brandInvestmentShare.data

  return {
    isLoading,
    isError: brandInvestmentShare.isError,
    data: isLoading ? undefined : {
      brand_investment_share: brandInvestmentShareDatasets,
    },
  }
}


type GenericMarketDatum = Omit<MarketDatum, 'dimension' | 'target' | 'granularity'>

type GenericGlobalDataHookValue<T = GenericMarketDatum> = {
  isLoading: boolean
  data: Record<string, T> | undefined
}

export const useConsumerBusinessMixByMarket = ({
  granularity = 'year',
  referenceDate,
  comparisonMethod = 'ABSOLUTE',
  enabled = true,
}: GenericGlobalDataHookProps): GenericGlobalDataHookValue => {

  const dateRanges = getComparisonDateRanges({
    primaryGranularity: granularity,
    secondaryGranularity: granularity,
    referenceDate,
  })

  const consumerVsBusiness = InvestmentReportApi.useConsumerVsBusiness({
    period: 'monthly',
    currencyType: 'dynamic',
    enabled,
  })

  const primaryMonthLabels = getMonthLabels(dateRanges.primary)
  const comparisonMonthLabels = getMonthLabels(dateRanges.comparison)

  let consumerVsBusinessDatasets: Record<string, GenericMarketDatum> = {}

  const comparisonFunction = getComparisonFunction(comparisonMethod)

  if( consumerVsBusiness.data ){
    consumerVsBusinessDatasets = consumerVsBusiness.data.reduce( (acc, item) => {
      if( item.row_group === 'All total' ){
        return acc
      }

      const current = getMediaInvestmentListItemPercentage(
        item, primaryMonthLabels, 'Business'
      )
      const previous = getMediaInvestmentListItemPercentage(
        item, comparisonMonthLabels, 'Business'
      )
      const isoCode = item.row_group.slice(-2)

      acc[isoCode] = {
        current,
        previous,
        change: comparisonFunction(current, previous),
      }

      return acc
    }, {} as Record<string, GenericMarketDatum>)

  }

  const isLoading = consumerVsBusiness.isLoading || !referenceDate || !consumerVsBusiness.data

  return {
    isLoading,
    data: isLoading ? undefined : consumerVsBusinessDatasets,
  }
}


const stringToPerformanceValue = (value: string | undefined | null): PerformanceValue => {
  if( value === null || typeof value === 'undefined' || typeof value === 'string' && !value.length ){
    return null
  }
  return Number(value)
}


export const useCompetitiveSOSByMarket = ({
  granularity = 'year',
  referenceDate,
  comparisonMethod = 'ABSOLUTE',
  enabled = true,
}: GenericGlobalDataHookProps): GenericGlobalDataHookValue => {

  const dateRanges = getComparisonDateRanges({
    primaryGranularity: granularity,
    secondaryGranularity: granularity,
    referenceDate,
  })

  const primaryRange = dateRanges.primary
  const startMonth = primaryRange && format(primaryRange.start, 'MMM')
  const endMonth = primaryRange && format(primaryRange.end, 'MMM')

  const competitiveReport = CompetitiveApi.useSOSSpendingByMarket({
    period: '',
    enabled: enabled && !!(startMonth && endMonth),
    ...(startMonth && endMonth && {
      start_month: [startMonth],
      end_month: [endMonth],
    }),
  })

  let sosOverviewDataByMarket: Record<string, GenericMarketDatum> = {}

  const comparisonFunction = getComparisonFunction(comparisonMethod)

  if( competitiveReport.data && dateRanges.primary && dateRanges.comparison ){

    type LoopConfig = ['previous' | 'current', Date]
    const loopConfigs: LoopConfig[] = [
      ['previous', getFinancialYearStart(dateRanges.comparison.start)],
      ['current', getFinancialYearStart(dateRanges.primary.start)],
    ]

    const sosRawDataByMarket = competitiveReport.data.reduce( (acc, listItem) => {
      if( listItem.isTotalRow !== true ){
        const marketName = listItem.row_group
        const market = vodafoneMarkets.find( m => m.name === marketName )
        if( !market ){
          console.warn('Missing market name', marketName, vodafoneMarkets)
          return acc
        }
        const comparisonCellItems: Record<'previous' | 'current', CellItem | null> = {
          previous: null,
          current: null,
        }
        
        for( const datum of listItem.costs ){
          const datumFyStart = parseFinancialYearStart(datum.type_value)
          for( const [property, dateObject] of loopConfigs ){
            if( isEqual(datumFyStart, dateObject) ){
              if( comparisonCellItems[property] ){
                throw new Error('Too many matches for FY')
              }
              comparisonCellItems[property] = datum
            }

          }
        }
        acc[market.iso_code] = comparisonCellItems
      }
      return acc
    }, {} as Record<string, Record<'previous' | 'current', CellItem | null>>)

    sosOverviewDataByMarket = mapValues(sosRawDataByMarket, (datum): GenericMarketDatum => {
      const current = stringToPerformanceValue(get(datum.current, 'percent'))
      const previous = stringToPerformanceValue(get(datum.previous, 'percent'))
      return {
        current,
        previous,
        change: comparisonFunction(current, previous),
      }
    })

  }

  const isLoading = competitiveReport.isLoading || !referenceDate || !competitiveReport.data

  return {
    isLoading,
    data: isLoading ? undefined : sosOverviewDataByMarket,
  }
}


type LabelledGenericMarketDatum = GenericMarketDatum & { label: string }


export const useCompetitiveSOSAllCompetitorsByMarket = ({
  granularity = 'year',
  referenceDate,
  comparisonMethod = 'ABSOLUTE',
  enabled = true,
}: GenericGlobalDataHookProps): GenericGlobalDataHookValue<LabelledGenericMarketDatum[]> => {

  const dateRanges = getComparisonDateRanges({
    primaryGranularity: granularity,
    secondaryGranularity: granularity,
    referenceDate,
  })

  const primaryRange = dateRanges.primary
  const startMonth = primaryRange && format(primaryRange.start, 'MMM')
  const endMonth = primaryRange && format(primaryRange.end, 'MMM')

  const competitiveReport = CompetitiveApi.useSOSSpendingAllCompetitorsByMarket({
    period: '',
    enabled: enabled && !!(startMonth && endMonth),
    ...(startMonth && endMonth && {
      start_month: [startMonth],
      end_month: [endMonth],
    }),
  })

  let sosOverviewDataByMarket: Record<string, LabelledGenericMarketDatum[]> = {}

  const comparisonFunction = getComparisonFunction(comparisonMethod)

  if( competitiveReport.data && dateRanges.primary && dateRanges.comparison ){

    type LoopConfig = ['previous' | 'current', Date]
    const loopConfigs: LoopConfig[] = [
      ['previous', getFinancialYearStart(dateRanges.comparison.start)],
      ['current', getFinancialYearStart(dateRanges.primary.start)],
    ]

    const sosRawDataByMarket = competitiveReport.data.reduce( (acc, listItem) => {
      if( listItem.isTotalRow !== true ){
        const marketName = listItem.row_group
        const market = vodafoneMarkets.find( m => m.name === marketName )
        if( !market ){
          console.warn('Missing market name', marketName, vodafoneMarkets)
          return acc
        }
        const comparisonCellItems: Record<'previous' | 'current', CellItem[] | null> = {
          previous: null,
          current: null,
        }
        for( const datum of (listItem.data || []) ){
          for( const nestedDatum of datum.costs ){
            const datumFyStart = parseFinancialYearStart(nestedDatum.type_value)
            for( const [property, dateObject] of loopConfigs ){
              if( isEqual(datumFyStart, dateObject) ){
                if( !comparisonCellItems[property] ){
                  comparisonCellItems[property] = []
                }
                (comparisonCellItems[property] as CellItem[]).push({
                  ...nestedDatum,
                  type_value: datum.data_type,
                })
              }
            }
          }
        }
        acc[market.iso_code] = comparisonCellItems
      }
      return acc
    }, {} as Record<string, Record<'previous' | 'current', CellItem[] | null>>)

    sosOverviewDataByMarket = mapValues(sosRawDataByMarket, (data): LabelledGenericMarketDatum[] => {
      const combined = zip(data.current, data.previous)
      const labelledData = combined.filter( (pair) => typeof pair !== 'undefined' ).map( ([current, previous]) => {
        const currentValue = stringToPerformanceValue(get(current, 'percent'))
        const previousValue = stringToPerformanceValue(get(previous, 'percent'))
        return {
          label: previous && previous.type_value || current && current.type_value || '',
          current: currentValue,
          previous: previousValue,
          change: comparisonFunction(currentValue, previousValue),
        }
      })

      return uniqBy(
        sortBy(labelledData, d => d.label === 'Vodafone' ? -1 : d.label.charCodeAt(0) ),
        d => d.label
      )
    })
  }

  const isLoading = competitiveReport.isLoading || !referenceDate || !competitiveReport.data

  return {
    isLoading,
    data: isLoading ? undefined : sosOverviewDataByMarket,
  }
}

const resolveFilterId = (
  investmentFilters: InvestmentFiltersResponse,
  key: keyof InvestmentFiltersResponse,
  value: string
): string | null => {
  const match = investmentFilters[key].find( f => f.name === value )
  if( match ){
    return match.id
  }
  return null
}


export type BrandInvestmentShareBreakdownsByMarket = {
  isLoading: boolean
  data: Record<string, OverviewTableRow[]> | undefined
}


export const useBrandInvestmentShareBreakdownByMarket = ({
  referenceDate,
  comparisonMethod = 'ABSOLUTE',
  enabled = true,
}: MediaInvestmentReportsByMarketHookProps): BrandInvestmentShareBreakdownsByMarket => {
  const granularity: OverviewGranularity = 'year'

  const dateRanges = getComparisonDateRanges({
    primaryGranularity: granularity,
    secondaryGranularity: granularity,
    referenceDate,
  })

  const filters = MediaInvestmentApi.useInvestmentFilters()

  const campaignPillarFilters = (
    filters.data ?
      [
        resolveFilterId(filters.data, 'campaigns', 'Brand'),
        resolveFilterId(filters.data, 'campaigns', 'Brand Product'),
      ] :
      []
  ).filter(Boolean) as string[]

  const mediaMix = InvestmentReportApi.useMediaMix({
    period: 'monthly',
    currencyType: 'dynamic',
    campaigns: campaignPillarFilters,
    enabled: enabled && !!filters.data,
  })

  const isLoading = !referenceDate || !filters.data || !mediaMix.data

  let brandInvestmentShareBreakdownDatasets: Record<string, OverviewTableRow[]> = {}

  if( mediaMix.data ){
    const primaryMonthLabels = getMonthLabels(dateRanges.primary)
    const comparisonMonthLabels = getMonthLabels(dateRanges.comparison)
    const comparisonFunction = getComparisonFunction(comparisonMethod)
    brandInvestmentShareBreakdownDatasets = mediaMix.data.reduce( (acc, item) => {
      if( item.row_group === 'All total' ){
        return acc
      }

      const primarySum = aggregateListItemTotalSpend(item, primaryMonthLabels)
      const comparisonSum = aggregateListItemTotalSpend(item, comparisonMonthLabels)

      const tvData = (item.data || []).find( d => d.data_type === 'TV' )
      const digitalData = (item.data || []).find( d => d.data_type === 'Digital' )

      const sourceConfigs: [string, NestedItem | undefined][] = [
        ['TV', tvData],
        ['Digital', digitalData]
      ]

      const marketTableRows: OverviewTableRow[] = sourceConfigs.map(([label, item]) => {
        if( !item ){
          return {
            label,
            score: null,
            previous: null,
            delta: null,
            target: null,
          }
        }
        const primaryTypeValueSum = sum(
          collectCellItemValues(item.costs, primaryMonthLabels, 'spend')
        )
        const comparisonTypeValueSum = sum(
          collectCellItemValues(item.costs, comparisonMonthLabels, 'spend')
        )

        const current = (
          !(primaryTypeValueSum || primarySum) ?
            null :
            primaryTypeValueSum / primarySum * 100
        )

        const previous = (
          !(comparisonTypeValueSum || comparisonSum) ?
            null :
            comparisonTypeValueSum / comparisonSum * 100
        )

        return {
          label,
          score: current,
          previous,
          delta: comparisonFunction(current, previous),
          target: null,
        }
      })

      const isoCode = item.row_group.slice(-2)

      acc[isoCode] = marketTableRows
      return acc
    }, {} as Record<string, OverviewTableRow[]>)

  }

  return {
    isLoading,
    data: isLoading ? undefined : brandInvestmentShareBreakdownDatasets,
  }
}
