import { Reducer } from 'redux'

import { SET_MEDIA_INVESTMENT_CURRENCY_TYPE } from '../actions'

import { ReduxAction } from '@percept/types'

import { CurrencyType } from 'components/ReportingDashboard/types'


export const currencyTypeReducer: Reducer<CurrencyType, ReduxAction> = (
  state = 'dynamic',
  action
) => {
  if( action.type === SET_MEDIA_INVESTMENT_CURRENCY_TYPE ){
    return action.payload
  }

  return state
}

export default currencyTypeReducer
