import { vodafonePalette } from '@percept/mui'

import { MultiDataset } from '@percept/mui/charts'


export const combinedPathwaysData: MultiDataset[] = [
  {
    key: 'passive',
    label: 'Passive',
    color: vodafonePalette.springGreen,
    data: [
      {
        label: 'Global Avg.',
        color: vodafonePalette.springGreen,
        value: 15,
      },
      {
        label: 'Germany',
        color: vodafonePalette.springGreen,
        value: 19,
      },
      {
        label: 'United Kingdom',
        color: vodafonePalette.springGreen,
        value: 17,
      },
      {
        label: 'Italy',
        color: vodafonePalette.springGreen,
        value: 14,
      },
      {
        label: 'South Africa',
        color: vodafonePalette.springGreen,
        value: 10,
      },
      {
        label: 'Czech Republic',
        color: vodafonePalette.springGreen,
        value: 17,
      },
      {
        label: 'Spain',
        color: vodafonePalette.springGreen,
        value: 12,
      },
      {
        label: 'Greece',
        color: vodafonePalette.springGreen,
        value: 13,
      },
      {
        label: 'Ireland',
        color: vodafonePalette.springGreen,
        value: 18,
      },
      {
        label: 'Romania',
        color: vodafonePalette.springGreen,
        value: 11,
      },
      {
        label: 'Egypt',
        color: vodafonePalette.springGreen,
        value: 27,
      },
      {
        label: 'Turkey',
        color: vodafonePalette.springGreen,
        value: 11,
      },
    ]
  },
  {
    key: 'exploration',
    label: 'Exploration',
    color: vodafonePalette.aquaBlue,
    data: [
      {
        label: 'Global Avg.',
        color: vodafonePalette.aquaBlue,
        value: 10,
      },
      {
        label: 'Germany',
        color: vodafonePalette.aquaBlue,
        value: 9,
      },
      {
        label: 'United Kingdom',
        color: vodafonePalette.aquaBlue,
        value: 13,
      },
      {
        label: 'Italy',
        color: vodafonePalette.aquaBlue,
        value: 16,
      },
      {
        label: 'South Africa',
        color: vodafonePalette.aquaBlue,
        value: 12,
      },
      {
        label: 'Czech Republic',
        color: vodafonePalette.aquaBlue,
        value: 6,
      },
      {
        label: 'Spain',
        color: vodafonePalette.aquaBlue,
        value: 11,
      },
      {
        label: 'Greece',
        color: vodafonePalette.aquaBlue,
        value: 6,
      },
      {
        label: 'Ireland',
        color: vodafonePalette.aquaBlue,
        value: 16,
      },
      {
        label: 'Romania',
        color: vodafonePalette.aquaBlue,
        value: 8,
      },
      {
        label: 'Egypt',
        color: vodafonePalette.aquaBlue,
        value: 6,
      },
      {
        label: 'Turkey',
        color: vodafonePalette.aquaBlue,
        value: 11,
      },
    ],
  },
  {
    key: 'experience',
    label: 'Experience',
    color: vodafonePalette.freshOrange,
    data: [
      {
        label: 'Global Avg.',
        color: vodafonePalette.freshOrange,
        value: 7,
      },
      {
        label: 'Germany',
        color: vodafonePalette.freshOrange,
        value: 7,
      },
      {
        label: 'United Kingdom',
        color: vodafonePalette.freshOrange,
        value: 6,
      },
      {
        label: 'Italy',
        color: vodafonePalette.freshOrange,
        value: 7,
      },
      {
        label: 'South Africa',
        color: vodafonePalette.freshOrange,
        value: 6,
      },
      {
        label: 'Czech Republic',
        color: vodafonePalette.freshOrange,
        value: 5,
      },
      {
        label: 'Spain',
        color: vodafonePalette.freshOrange,
        value: 8,
      },
      {
        label: 'Greece',
        color: vodafonePalette.freshOrange,
        value: 6,
      },
      {
        label: 'Ireland',
        color: vodafonePalette.freshOrange,
        value: 5,
      },
      {
        label: 'Romania',
        color: vodafonePalette.freshOrange,
        value: 7,
      },
      {
        label: 'Egypt',
        color: vodafonePalette.freshOrange,
        value: 8,
      },
      {
        label: 'Turkey',
        color: vodafonePalette.freshOrange,
        value: 8,
      },
    ],
  },
  {
    key: 'loyalty',
    label: 'Loyalty',
    color: vodafonePalette.redViolet,
    data: [
      {
        label: 'Global Avg.',
        color: vodafonePalette.redViolet,
        value: 68,
      },
      {
        label: 'Germany',
        color: vodafonePalette.redViolet,
        value: 65,
      },
      {
        label: 'United Kingdom',
        color: vodafonePalette.redViolet,
        value: 64,
      },
      {
        label: 'Italy',
        color: vodafonePalette.redViolet,
        value: 64,
      },
      {
        label: 'South Africa',
        color: vodafonePalette.redViolet,
        value: 73,
      },
      {
        label: 'Czech Republic',
        color: vodafonePalette.redViolet,
        value: 73,
      },
      {
        label: 'Spain',
        color: vodafonePalette.redViolet,
        value: 68,
      },
      {
        label: 'Greece',
        color: vodafonePalette.redViolet,
        value: 75,
      },
      {
        label: 'Ireland',
        color: vodafonePalette.redViolet,
        value: 61,
      },
      {
        label: 'Romania',
        color: vodafonePalette.redViolet,
        value: 74,
      },
      {
        label: 'Egypt',
        color: vodafonePalette.redViolet,
        value: 59,
      },
      {
        label: 'Turkey',
        color: vodafonePalette.redViolet,
        value: 69,
      },
    ],
  },
]

export type JourneyGraphType = {
  market: string
  passive: number
  exploration: number
  experience: number
  loyalty: number
}

export const journeyGraphDataMap = combinedPathwaysData.reduce( (acc, dataset) => {
  const key = dataset.label.toLowerCase() as keyof JourneyGraphType
  for( const datum of dataset.data ){
    const market = String(datum.label)
    if( !acc[market] ){
      acc[market] = {} as JourneyGraphType
    }
    acc[market][key] = Number(datum.value)
  }
  return acc
}, {} as Record<string, JourneyGraphType>)
