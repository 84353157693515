import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'

import { ReduxBundle } from '../lib'

import { StructuralReportingState } from './typings'


type StructuralReportingActions = typeof actions
type StructuralReportingSelectors = typeof selectors


export type StructuralReportingBundle = ReduxBundle<StructuralReportingState, StructuralReportingActions, StructuralReportingSelectors>


export { actions, reducer, selectors }


export const makeBundle = (): StructuralReportingBundle => ({
  actions,
  reducer,
  selectors
})

export default { actions, reducer, selectors } as StructuralReportingBundle

export * from './typings'
