import React from 'react'

import { makeAppStyles } from '@percept/mui'

import { ArrowDownward, ArrowUpward } from '@percept/mui/icons'


const useStyles = makeAppStyles( theme => ({
  root: {
    display:'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: 72,
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(3),
    [theme.breakpoints.down(1380)]: {
      fontSize: 60,
    },
    [theme.breakpoints.down(1200)]: {
      fontSize: 50,
    },
    [theme.breakpoints.down(1000)]: {
      fontSize: 40,
    },
    [theme.breakpoints.down(680)]: {
      fontSize: 30,
    },
    [theme.breakpoints.down(560)]: {
      fontSize: 25,
    },
  },
  textWrapper: {
    display:'flex',
    alignItems:'center',
    marginBottom: theme.spacing(5),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  explainer: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.35,
  },
  icon: {
    fontSize: 82,
    margin: theme.spacing(0, 1),
    [theme.breakpoints.down(1380)]: {
      fontSize: 70,
    },
    [theme.breakpoints.down(1200)]: {
      fontSize: 60,
    },
    [theme.breakpoints.down(1000)]: {
      fontSize: 50,
    },
    [theme.breakpoints.down(680)]: {
      fontSize: 40,
    },
    [theme.breakpoints.down(560)]: {
      fontSize: 30,
    },
  },
  iconPointingUp: {
    transform: 'rotate(45deg)',
  },
  iconPointingDown: {
    transform: 'rotate(-45deg)',
  },
}))


export const InfographicCQS = (): JSX.Element => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.textWrapper}>
        10%
        <ArrowUpward className={`${classes.icon} ${classes.iconPointingUp}`} />
        in CQS
      </div>
      <div className={classes.textWrapper}>
        = 2%
        <ArrowDownward className={`${classes.icon} ${classes.iconPointingDown}`} />
        in CPM
      </div>
      <div className={classes.explainer}>
        Based on 1M ads, 1T impressions analysed by CreativeX.
        <br />
        Across all channels, a 10% increase in CQS is associated with a 2% decrease in CPM.
      </div>
    </div>
  )
}
