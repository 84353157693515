import { saveAs } from 'file-saver'


export const extractWebFontRules = (): CSSRule[] => {
  return Array.from(document.styleSheets).reduce( (acc, styleSheet) => {
    const cssRules = Array.from(styleSheet.cssRules)
    const rulesFontFace = cssRules.filter(rule => rule.cssText.startsWith('@font-face'))
    acc = acc.concat(rulesFontFace)
    return acc
  }, [] as CSSRule[])
}

type ExtractedWebFont = [CSSRule, string]

type ResolvedWebFont = [CSSRule, Blob]


const trimLeadingSlash = (route: string): string => (
  route.replace(/^\/+/, '')
)

const urlJoin = (...routes: string[]): string => (
  [
    routes[0],
    ...routes.slice(1).map(trimLeadingSlash),
  ].join('/')
)

const fontFaceUrlRegex = /url\(\\?"([\S]+)\\?"\)/

const getFontFaceUrl = (fontFaceRule: CSSRule): string | null => {
  const { protocol, host } = location
  const match = fontFaceRule.cssText.match(fontFaceUrlRegex)
  const fontPath = match && match[1]
  if( fontPath ){
    if( !fontPath.startsWith('http') ){
      return urlJoin(`${protocol}//${host}`, fontPath)
    }
    return fontPath
  }
  return null
}

const fetchBinary = (url: string): Promise<Blob> => (
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.open('GET', url, true)
    xhr.onreadystatechange = (): void => {
      if( xhr.readyState == 4 ){
        if( xhr.status === 200 ){
          return resolve(xhr.response)
        }else{
          return reject(new Error(`Resource could not be accessed from route ${url}`))
        }
      }
    }
    xhr.send(null)
  })
)

const makePromiseWithState = <T, P>(promise: Promise<P>, state: T): Promise<[T, P]> => {
  return new Promise((resolve, reject) => {
    return promise.then( response => {
      resolve([state, response])
    }).catch(reject)
  })
}


const extractWebFonts = (): ExtractedWebFont[] => {
  const rules = extractWebFontRules()
  const resolvedWebFonts: ExtractedWebFont[] = []
  rules.forEach( rule => {
    const url = getFontFaceUrl(rule)
    if( url ){
      resolvedWebFonts.push([rule, url])
    }
  })
  return resolvedWebFonts
}


export const resolveWebFonts = (): Promise<ResolvedWebFont[]> => {
  const extractedWebFonts = extractWebFonts()
  const promisesWithState = extractedWebFonts.map( ([rule, url]) => (
    makePromiseWithState(fetchBinary(url), rule)
  ))
  return new Promise((resolve, reject) => {
    Promise.all(promisesWithState).then(resolve).catch(reject)
  })
}

const getEmbeddedFontFaceRuleCSSText = ([rule, blob]: ResolvedWebFont): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (): void => {
      const embeddedUrl = `url(${reader.result})`
      const cssText = rule.cssText.replace(fontFaceUrlRegex, embeddedUrl)
      return resolve(cssText)
    }
    reader.onerror = (): void => {
      return reject(reader.error)
    }
    reader.readAsDataURL(blob)
  })
}

export const getEmbeddedFontFaceRules = (): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    resolveWebFonts().then( resolvedFonts => {
      Promise.all(resolvedFonts.map(getEmbeddedFontFaceRuleCSSText)).then(resolve).catch(reject)
    }).catch(reject)
  })
}

const fontFamilyRegex = /font-family:\W([A-z]+)/

const requiredSvgRootAttributes: [string, string][] = [
  ['xmlns', 'http://www.w3.org/2000/svg'],
  ['xmlns:xlink', 'http://www.w3.org/1999/xlink'],
]

const getElementForExport = (
  element: SVGElement,
  fontFaceStyles?: string[]
): SVGElement => {
  // Embedded fonts using the approach outlined at https://vecta.io/blog/how-to-use-fonts-in-svg
  const clone = element.cloneNode(true) as SVGElement
  let fontFamily: string | null = null
  if( fontFaceStyles ){
    const fontFamilies = fontFaceStyles.reduce( (acc, s) => {
      const match = s.match(fontFamilyRegex)
      if( match && match[1] ){
        acc.push(match[1])
      }
      return acc
    }, [] as string[])
    fontFamily = fontFamilies[0] || null
    const defs = document.createElement('defs')
    const style = document.createElement('style')
    style.innerHTML = fontFaceStyles.join('\n')
    defs.appendChild(style)
    clone.prepend(defs)
  }

  requiredSvgRootAttributes.forEach( ([attr, value]) => {
    clone.setAttribute(attr, value)
  })
  if( fontFamily ){
    const textElements = clone.querySelectorAll('text')
    Array.from(textElements).forEach( element => {
      element.setAttribute('font-family', fontFamily)
    })
  }

  return clone
}

type WrappingElementOptions = {
  margin: Record<'top' | 'bottom' | 'left' | 'right', number>;
  attributes?: Partial<React.SVGAttributes<SVGElement>>;
};

const wrapSVGElement = (
  element: SVGElement,
  { margin, attributes }: WrappingElementOptions
): SVGElement => {

  if( attributes ){
    Object.entries(attributes).forEach( ([attr, value]) => {
      if( value !== null && value !== undefined ){
        element.setAttribute(attr, String(value))
      }
    })
  }

  const wrappingElement = document.createElement('svg')

  requiredSvgRootAttributes.forEach( ([attr, value]) => {
    wrappingElement.setAttribute(attr, value)
  })

  element.setAttribute('x', String(margin.left))
  element.setAttribute('y', String(margin.top))

  wrappingElement.setAttribute(
    'width',
    String(
      margin.left
      + margin.right
      + Number(element.getAttribute('width'))
    )
  )
  wrappingElement.setAttribute(
    'height',
    String(
      margin.top
      + margin.bottom
      + Number(element.getAttribute('height'))
    )
  )
  wrappingElement.appendChild(element)

  return wrappingElement as unknown as SVGElement
}

const svgElementToFile = (
  element: SVGElement
): Blob => {
  const xmlDocumentHeader = (
    `<?xml version="1.0" ?>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
      "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">`
  )
  return new Blob([xmlDocumentHeader + '\n' + element.outerHTML])
}


export const downloadSVG = (
  element: SVGElement,
  filename: string,
  wrappingOptions?: WrappingElementOptions
): Promise<void> => {
  return new Promise((resolve, reject) => {
    getEmbeddedFontFaceRules().then( cssTexts => {
      let clone = getElementForExport(element, cssTexts)
      if( wrappingOptions ){
        clone = wrapSVGElement(clone, wrappingOptions)
      }
      saveAs(
        svgElementToFile(clone),
        filename,
      )
      return resolve()
    }).catch(reject)
  })
}
