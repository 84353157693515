
import React from 'react'

import {
  makeAppStyles,
  CellRenderer,
  CellRenderers,
} from '@percept/mui'

import { PerformanceReportingDatum } from 'types'


const useLabelStyles = makeAppStyles( theme => ({
  root: {
    fontSize: 12,
    display: 'inline-flex',
    flex: '0 0 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 13,
    },
  },
}) )


export const LabelCellRenderer: CellRenderer<PerformanceReportingDatum> = ({
  // eslint-disable-next-line react/prop-types
  label
}) => {
  const classes = useLabelStyles()
  return (
    <span className={classes.root}>
      { label }
    </span>
  )
}


export const cellRenderers: CellRenderers<PerformanceReportingDatum> = {
  label: LabelCellRenderer,
}
