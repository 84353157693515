import { PlatformUnitParams } from '@percept/types'
import { apiClient } from 'api/clients'
import { OptionType } from 'components/ReportingDashboard/Filters'
import {
  Campaign,
  DataProps,
  UpdateProps,
} from 'components/ReportingDashboard/types'
import { WebFormTypes } from 'enums/WebFormTypes'
import { queryClient } from 'index'
import { useState } from 'react'
import {
  Query,
  QueryClient,
  QueryKey,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { AxiosError } from 'axios'
import { isMediaInvestmentReportQuery } from './MediaInvestment'
import { isCompetitiveReportQuery, isCompetitiveSOSReportQuery, isCompetitiveSOVReportQuery } from './CompetitiveReports'
import { format } from 'date-fns'

interface CampaignPillarType {
  campaignPillar: OptionType[]
  subCampaignPillar: OptionType[]
}

interface MediaChannelType {
  mediaChannel: OptionType[]
  mediaSubChannel: OptionType[]
}

type UpdateFormResponse = {
  update_conflicts: Array<{
    new_amount: string
    id: string
  }>
  create_conflicts: Campaign[]
}


export type BaseFileUploadError = {
  type: string
  location: string
  message: string
  level: string
}


export type SheetError = BaseFileUploadError & {
  sheet: string
  data: string
}

export type RowError = BaseFileUploadError & {
  row: number
  sheet: string
}

export type RowReferenceError = RowError & {
  type: string
}

export type ConflictingDataError = BaseFileUploadError & {
  row: number
  sheet: string
  month_name: string
  month: string
  identity: string
  existing_value_micros: number | null
  new_value_micros: number | null
}

export type InputRowError = RowError & {
    data: string
}

export type UploadErrorContainer = {
  sheet: SheetError[]
  input_rows: InputRowError[]
  conflicts: ConflictingDataError[]
  references: RowReferenceError[]
}

export type FileUploadResponse = {
  status: 'success' | 'failure'
  added_data: {
    row_count: number
  }
  errors: UploadErrorContainer | null
  file_metadata: {
    filename: string
    filesize: number
  }
  warnings: UploadErrorContainer | null
}

type BaseFilterType = (
  | 'agency'
  | 'buy_type'
  | 'campaigns_pillar'
  | 'campaigns_sub_pillar'
  | 'department'
  | 'funding_source'
  | 'jbp'
  | 'media_channel'
  | 'media_sub_channel'
  | 'message'
  | 'product'
  | 'second_brand'
)


export type InvestmentFilter = (
  | BaseFilterType
  | 'financial_year'
  | 'org_unit'
)

export type CompetitiveFilter = (
  | 'org_unit'
  | 'media_channel'
  | 'media_sub_channel'
  | 'competitor'
  | 'actual_competitor'
)

export type BaseCompetitiveFilterType = (
  | 'actual_competitor'
  | 'competitor'
  | 'competitive_second_brand'
  | 'source'
  | 'time_lag'
  | 'agency'
)
  
export type SOSFilter = (
  | BaseCompetitiveFilterType
  | 'agency_discount'
  | 'media_channel'
  | 'org_unit'
)
  
export type SOVFilter = (
  | BaseCompetitiveFilterType
  | 'spot_length'
  | 'traded_audience'
)

export type Breakdown = (
  | BaseFilterType
)


export type InvestmentFilterItem = {
  id: string | null
  name: string
  type: InvestmentFilter
}

export type ParentInvestmentFilterItem = InvestmentFilterItem & {
  children: InvestmentFilterItem[]
}

export type OrgUnitInvestmentItem = {
  id: string | null
  name: string
  type: 'org_unit'
  departments: InvestmentFilterItem[]
  second_brands: InvestmentFilterItem[]
}

export type InvestmentFiltersResponse = {
  jbps: InvestmentFilterItem[]
  media_channels: ParentInvestmentFilterItem[]
  campaigns: ParentInvestmentFilterItem[]
  funding_sources: InvestmentFilterItem[]
  buy_types: InvestmentFilterItem[]
  products: InvestmentFilterItem[]
  messages: InvestmentFilterItem[]
  agencies: InvestmentFilterItem[]
  org_units: OrgUnitInvestmentItem[]
  financial_years: InvestmentFilterItem[]
}

export type BaseCompetitiveFiltersResponse = {
  competitors: InvestmentFilterItem[]
  competitive_second_brands: InvestmentFilterItem[]
  sources: InvestmentFilterItem[]
  agencies: InvestmentFilterItem[]
  time_lags: InvestmentFilterItem[]
}

export type CompetitiveSOSFiltersResponse = BaseCompetitiveFiltersResponse & {
  media_channels: ParentInvestmentFilterItem[]
  agency_discounts: InvestmentFilterItem[]
  org_units: OrgUnitInvestmentItem[]
}

export type CompetitiveSOVFiltersResponse = BaseCompetitiveFiltersResponse & {
  media_channels: ParentInvestmentFilterItem[]
  spot_lengths: InvestmentFilterItem[]
  traded_audiences: InvestmentFilterItem[]
}

export type CompetitiveFiltersResponse = {
  markets: OrgUnitInvestmentItem[]
  media_channels: ParentInvestmentFilterItem[]
  media_sub_channels: ParentInvestmentFilterItem[]
  competitors: InvestmentFilterItem[]
  actual_competitors: InvestmentFilterItem[]
}


export type BreakdownFilterItem = {
  id: string | null
  name: string
  type: Breakdown
}

export type ParentBreakdownFilterItem = BreakdownFilterItem & {
  children: BreakdownFilterItem[]
}

export type OrgUnitInvestmentBreakdownsResponse = {
  agencies: BreakdownFilterItem[]
  buy_types: BreakdownFilterItem[]
  campaigns: ParentBreakdownFilterItem[]
  departments: BreakdownFilterItem[]
  funding_sources: BreakdownFilterItem[]
  jbps: BreakdownFilterItem[]
  media_channels: ParentBreakdownFilterItem[]
  messages: BreakdownFilterItem[]
  products: BreakdownFilterItem[]
  second_brands: BreakdownFilterItem[]
}

export type SubmissionTrackerData = {
  id: string
  report_date: string
  org_unit_id: string
  org_unit_name: string
  agency_id: string
  agency_name: string
  department_id: string
  department_name: string
  user_id: string
  status: string
  spending_type: string
  added_at: string
}

export type IncompleteData = {
  org_unit_name: string
  start_date: string
  end_date: string
  period_display_name: string
  is_completed: boolean
}

export type IncompleteMarkets = {
  org_unit_name: string
  is_completed: boolean
}

export type SpreadsheetUploadType = 'MEDIA_SPEND' | 'COMPETITIVE_SHARE_OF_SPEND' | 'COMPETITIVE_SHARE_OF_VOICE'

export type SpendingType =
  | 'primary_spend'
  | 'sos_spend'
  | 'sov_spend'


const makeApiQuery = <T, R = T>(
  route: string,
  adapter?: (payload: R) => T
) => {
  return async () => {
    const response = await apiClient.get<R>(route)
    if( adapter ){
      return adapter(response.data)
    }
    return response.data
  }
}


const makeQueryInvalidation = (
  queryClient: QueryClient,
  queryKeys: QueryKey[],
): Promise<unknown> => {
  return Promise.allSettled([
    queryKeys.map( queryKey => (
      queryClient.invalidateQueries({
        queryKey
      })
    ))
  ])
  // const invalidationQueryHashes = queryKeys.map(hashQueryKey)
  // return queryClient.invalidateQueries({
  //   predicate: query => (
  //     invalidationQueryHashes.includes(
  //       hashQueryKey(query.queryKey)
  //     )
  //   )
  // })
}

export default {
  // Filters endpoints
  useInvestmentFilters: (): UseQueryResult<InvestmentFiltersResponse, Error> => (
    useQuery<InvestmentFiltersResponse, Error>(
      ['investmentFilters'],
      makeApiQuery('/filters/investments'),
      { staleTime: Infinity, refetchOnMount: false, refetchOnWindowFocus: false }
    )
  ),
  // Separate Competitive SOS / SOV filter endpoints
  useCompetitiveSOSFilters: (): UseQueryResult<CompetitiveSOSFiltersResponse, Error> => (
    useQuery<CompetitiveSOSFiltersResponse, Error>(
      ['sosFilters'],
      // makeApiQuery('/filters/sos'),  // legacy endpoint - we may end up back here
      makeApiQuery('/competitive-reports/sos_filters'),
      { staleTime: Infinity, refetchOnMount: false, refetchOnWindowFocus: false }
    )
  ),
  useCompetitiveSOVFilters: (): UseQueryResult<CompetitiveSOVFiltersResponse, Error> => (
    useQuery<CompetitiveSOVFiltersResponse, Error>(
      ['sovFilters'],
      // makeApiQuery('/filters/sov'),  // legacy endpoint - we may end up back here
      makeApiQuery('/competitive-reports/sov_filters'),
      { staleTime: Infinity, refetchOnMount: false, refetchOnWindowFocus: false }
    )
  ),
  // Breakdowns endpoints
  useInvestmentBreakdowns: (org_unit_id: string): UseQueryResult<OrgUnitInvestmentBreakdownsResponse, Error> => (
    useQuery<OrgUnitInvestmentBreakdownsResponse, Error>(
      ['investmentBreakdowns', org_unit_id],
      makeApiQuery(`breakdowns/investments/${org_unit_id}`),
      { staleTime: Infinity, refetchOnMount: false, refetchOnWindowFocus: false }
    )
  ),
  useMarkets: (flag: boolean): UseQueryResult<OptionType[], Error> =>
    useQuery<OptionType[], Error>(
      ['markets'],
      async () => {
        const res = await apiClient.get<{
          results: { id: string; name: string }[]
        }>('/markets/')

        return res.data.results.map((el) => {
          return { value: el.id, name: el.name }
        })
      },
      { enabled: flag, refetchOnWindowFocus: false }
    ),
  useFundingSource: (flag: boolean): UseQueryResult<OptionType[], Error> =>
    useQuery<OptionType[], Error>(
      ['fundingSource'],
      async () => {
        const res = await apiClient.get<{
          results: { id: string; name: string }[]
        }>('/funding_sources/')

        return res.data.results.map((el) => {
          return { value: el.id, name: el.name }
        })
      },
      { enabled: flag }
    ),
  useCampaignPillar: (
    flag: boolean
  ): UseQueryResult<CampaignPillarType, Error> =>
    useQuery<CampaignPillarType, Error>(
      ['campaignPillar'],
      async () => {
        const res = await apiClient.get<{
          results: {
            id: string
            name: string
            sub_campaign: { id: string; name: string }[]
          }[]
        }>('/campaigns/')

        const campaignPillar = res.data.results.map((el) => {
          return { value: el.id, name: el.name }
        })

        const subCampaignPillar = res.data.results.reduce(
          (result: OptionType[], el) => {
            const subArray = el.sub_campaign
              ? el.sub_campaign.map((subEl) => {
                return { value: subEl.id, name: `${el.name} - ${subEl.name}` }
              })
              : []
            return result.concat(subArray)
          },
          []
        )

        return {
          campaignPillar: campaignPillar,
          subCampaignPillar: subCampaignPillar,
        }
      },
      { enabled: flag }
    ),
  useSecondBrand: (flag: boolean): UseQueryResult<OptionType[], Error> =>
    useQuery<OptionType[], Error>(
      ['secondBrand'],
      async () => {
        const res = await apiClient.get<{
          results: {
            id: string
            name: string
            org_unit_id: string
            org_unit_name: string
          }[]
        }>('/second_brands/')

        return res.data.results.map((el) => {
          return { value: el.id, name: el.name }
        })
      },
      { enabled: flag }
    ),
  useMediaChannel: (flag: boolean): UseQueryResult<MediaChannelType, Error> =>
    useQuery<MediaChannelType, Error>(
      ['mediaChannel'],
      async () => {
        const res = await apiClient.get<{
          results: {
            id: string
            name: string
            sub_channels: { id: string; name: string }[]
          }[]
        }>('/media_channels/')

        const mediaChannel = res.data.results.map((el) => {
          return { value: el.id, name: el.name }
        })
        const mediaSubChannel = res.data.results.reduce(
          (result: OptionType[], el) => {
            const subArray = el.sub_channels.map((subEl) => {
              return { value: subEl.id, name: `${el.name} - ${subEl.name}` }
            })
            return result.concat(subArray)
          },
          []
        )

        return { mediaChannel: mediaChannel, mediaSubChannel: mediaSubChannel }
      },
      { enabled: flag }
    ),
  useBuyType: (flag: boolean): UseQueryResult<OptionType[], Error> =>
    useQuery<OptionType[], Error>(
      ['buyType'],
      async () => {
        const res = await apiClient.get<{
          results: {
            id: string
            name: string
          }[]
        }>('/buy_types/')

        return res.data.results.map((el) => {
          return { value: el.id, name: el.name }
        })
      },
      { enabled: flag }
    ),
  useJBP: (flag: boolean): UseQueryResult<OptionType[], Error> =>
    useQuery<OptionType[], Error>(
      ['JBP'],
      async () => {
        const res = await apiClient.get<{
          results: {
            id: string
            name: string
          }[]
        }>('/jbps/')

        return res.data.results.map((el) => {
          return { value: el.id, name: el.name }
        })
      },
      { enabled: flag }
    ),
  useFormData: (org_unit_id: string): UseQueryResult<DataProps, Error> =>
    useQuery<DataProps, Error>(['formData'], async () => {
      const res = await apiClient.get(
        `/web_forms/fy/current_year/${org_unit_id}`
      )
      return res.data
    }),
  useFormDataFY: (
    org_unit_id: string,
    financial_year_id: string | undefined,
    webformType: WebFormTypes,
    flag: boolean
  ): UseQueryResult<{ data: DataProps; time: string }, Error> =>
    useQuery<{ data: DataProps; time: string }, Error>(
      ['formDataFY', webformType, org_unit_id, financial_year_id],
      async () => {
        const res = await apiClient.get(
          `/web_forms/${
            webformType === WebFormTypes.CompetitiveInvestment
              ? 'sos/'
              : webformType === WebFormTypes.CompetitiveTVSOV
                ? 'sov/'
                : ''
          }fy/${financial_year_id}/${org_unit_id}${
            webformType === WebFormTypes.MediaInvestment ? '/' : ''
          }`
        )

        const dataRenderTime = new Date()
          .toISOString()
          .replace('T', ' ')
          .replace('Z', '')
        return { data: res.data, time: dataRenderTime }
      },
      { enabled: flag, refetchOnWindowFocus: false }
    ),
  useUpdateFormData: (webformType: WebFormTypes) => {
    const { data, error, mutate, reset, isLoading, isSuccess } = useMutation<
      UpdateFormResponse,
      Error,
      UpdateProps
    >({
      mutationFn: async ({ fyId, orgId, investments, webformType }) => {
        const res = await apiClient.post<UpdateFormResponse>(
          `/web_forms/${
            webformType === WebFormTypes.CompetitiveInvestment
              ? 'sos/'
              : webformType === WebFormTypes.CompetitiveTVSOV
                ? 'sov/'
                : ''
          }fy/${fyId}/${orgId}${
            webformType === WebFormTypes.MediaInvestment ? '/' : ''
          }`,
          { investments }
        )
        return res.data
      },
      onSuccess: () => {
        return Promise.allSettled([
          makeQueryInvalidation(queryClient, [
            ['formDataFY'],
            [webformType, 'submissionTracker'],
          ]),
          queryClient.invalidateQueries({
            predicate: isMediaInvestmentReportQuery
          })
        ])
      },
    })
    const conflictTime = new Date()
      .toISOString()
      .replace('T', ' ')
      .replace('Z', '')
    return {
      updateForm: mutate,
      reset,
      data,
      error,
      conflictTime,
      isLoading,
      isSuccess,
    }
  },
  useSubmitFormDataFY: (webformType: WebFormTypes) => {
    const { data, error, mutate, reset, isSuccess, isLoading } = useMutation<
      UpdateFormResponse,
      Error,
      UpdateProps
    >({
      mutationFn: async ({ fyId, orgId, investments }) => {
        const res = await apiClient.post<UpdateFormResponse>(
          `/web_forms/${
            webformType === WebFormTypes.CompetitiveInvestment
              ? 'sos/'
              : webformType === WebFormTypes.CompetitiveTVSOV
                ? 'sov/'
                : ''
          }fy/${fyId}/${orgId}/submit`,
          { investments }
        )
        return res.data
      },
      onSuccess: () => {
        return makeQueryInvalidation(queryClient, [
          ['formDataFY'],
          [webformType, 'submissionTracker'],
        ])
      },
    })
    const conflictTime = new Date()
      .toISOString()
      .replace('T', ' ')
      .replace('Z', '')
    return {
      submitForm: mutate,
      reset,
      data,
      error,
      conflictTime,
      isSuccess,
      isLoading,
    }
  },
  useDeleteRow: (webformType: WebFormTypes) => {
    const { data, error, mutate, reset, isLoading, isSuccess } = useMutation<
      unknown,
      Error,
      { id: string; updated_at: string }[]
    >({
      mutationFn: async (row) => {
        const response = await apiClient.delete(
          `/${
            webformType === WebFormTypes.CompetitiveInvestment
              ? 'sos_'
              : webformType === WebFormTypes.CompetitiveTVSOV
                ? 'sov_'
                : ''
          }spendings/bulk`,
          {
            data: row,
          }
        )
        return response
      },
      onSuccess: () => {
        return Promise.allSettled([
          makeQueryInvalidation(queryClient, [
            ['formDataFY'],
            [webformType, 'submissionTracker'],
          ]),
          queryClient.invalidateQueries({
            predicate: isMediaInvestmentReportQuery
          })
        ])
      },
    })

    return {
      deleteRow: mutate,
      reset,
      data,
      error,
      isLoading,
      isSuccess,
    }
  },
  useFinancialYears: (
    flag: boolean
  ): UseQueryResult<{ id: string; name: string }[], Error> =>
    useQuery<{ id: string; name: string }[], Error>(
      ['financialYears'],
      async () => {
        const res = await apiClient.get<{
          results: { id: string; name: string }[]
        }>('/financial_years/')

        return res.data.results
      },
      { enabled: flag, refetchOnWindowFocus: false }
    ),
  useSubmissionTrackerForWebformType: (
    webformType: WebFormTypes
  ): UseQueryResult<SubmissionTrackerData[], Error> =>
    useQuery<SubmissionTrackerData[], Error>(
      [webformType, 'submissionTracker'],
      async () => {
        const res = await apiClient.get<{ results: SubmissionTrackerData[] }>(
          `/submission_trackers/${
            webformType === WebFormTypes.CompetitiveInvestment
              ? 'sos'
              : webformType === WebFormTypes.CompetitiveTVSOV
                ? 'sov'
                : 'primary'
          }_spending`
        )
        return res.data.results
      },
      { refetchOnWindowFocus: false }
    ),
  useSubmissionTracker: ({
    spendingType,
    ...options
  }: {
    spendingType: SpendingType
    // start_date: Date | null = null
    } & Partial<UseQueryOptions<SubmissionTrackerData[], Error>>
  ): UseQueryResult<SubmissionTrackerData[], Error> =>
    useQuery<SubmissionTrackerData[], Error>(
      [spendingType, 'submissionTracker'],
      async () => {
        const res = await apiClient.get<{ results: SubmissionTrackerData[] }>(
          '/report_imports/spending',
          {
            params: {
              spending_type: spendingType,
              // ...(start_date && { start_date: format(start_date, 'yyyy-MM-dd') }),
            }
          },
        )
        return res.data.results
      },
      { retry: false, refetchOnWindowFocus: false, ...options }
    ),
  useIncompleteData: (
    period: string,
    spending_type: SpendingType,
    flag: boolean,
  ): UseQueryResult<IncompleteData[], Error> =>
    useQuery<IncompleteData[], Error>(
      [period, spending_type, 'incompleteData'],
      async () => {
        const res = await apiClient.get<{ results: IncompleteData[] }>(
          `/report_imports/spending/${period}`,
          { params: { spending_type } },
        )
        return res.data.results
      },
      { enabled: flag, retry: false, refetchOnWindowFocus: false }
    ),
  useIncompleteMarkets: (
    fin_years: string[],
    spending_type: SpendingType,
    flag: boolean,
  ): UseQueryResult<IncompleteMarkets[], Error> =>
    useQuery<IncompleteMarkets[], Error>(
      [fin_years, spending_type, 'incompleteMarkets'],
      async () => {
        const res = await apiClient.get<{ results: IncompleteData[] }>(
          '/report_imports/financial_years',
          { params: { fin_years, spending_type } },
        )
        return res.data.results
      },
      { enabled: flag, retry: false, refetchOnWindowFocus: false }
    ),
  
  
  // MMIR Upload
  useSpreadsheetUpload: ({ org_unit_id }: PlatformUnitParams) => {
    const queryClient = useQueryClient()
    const [progress, setProgress] = useState({loaded: 0, total: 0})
    const mutation = useMutation<
      FileUploadResponse,
      AxiosError<FileUploadResponse>,
      { sheetType: SpreadsheetUploadType, file: Blob }
    >({
      mutationFn: async ({ sheetType, file }) => {
        const formData = new FormData()
        formData.append('upload_file', file)
        setProgress({loaded: 0, total: file.size})
        const response = await apiClient({
          url: `/upload/${org_unit_id}/`,
          method: 'POST',
          data: formData,
          params: { upload_sheet: sheetType },
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (e) => {
            setProgress({
              loaded: e.loaded,
              total: e.total,
            })
          }
        })
        return response.data
      },
      onSuccess: (_, { sheetType }) => {
        const predicate: (q: Query) => boolean = (
          sheetType === 'MEDIA_SPEND' ?
            isMediaInvestmentReportQuery :
            sheetType === 'COMPETITIVE_SHARE_OF_SPEND' ?
              isCompetitiveSOSReportQuery :
              isCompetitiveSOVReportQuery
        )
        return queryClient.invalidateQueries({ predicate })
      },
    })
    
    

    return {
      ...mutation,
      progress,
      reset: () => {
        mutation.reset()
        setProgress({loaded: 0, total: 0})
      }
    }
  } 
}

