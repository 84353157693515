import React, { Fragment, useEffect } from 'react'

import { Link } from 'react-router-dom'

import { Alert, Box, Button, CircularProgress, Divider, ProviderLogo, Typography } from '@percept/mui'

import { ArrowBack, InfoOutlined, Error as ErrorIcon, Check } from '@percept/mui/icons'

import { ChannelLayout } from './ChannelLayout'

import { useLocationParams, useNavigation, useSaveClientSeriesRefreshToken } from '@percept/hooks'

import { coerceReportProvider } from '@percept/utils'

import { providerChannelMap } from '@percept/constants'


export const SeriesAuthHandler = (): JSX.Element => {

  const { state, code, provider } = useLocationParams()

  const series_id = state ? String(state) : null
  const token = code ? String(code) : null

  const reportProvider = provider && coerceReportProvider(provider)

  const channel = reportProvider && providerChannelMap[reportProvider]

  const [status, saveToken, resetStatus] = useSaveClientSeriesRefreshToken({
    series_id,
    token,
  })

  useEffect(() => {
    if( token && series_id && !status.processing && !status.processed && !status.error ){
      saveToken({ token, series_id })
    }
  }, [status, saveToken, token, series_id])

  const navigate = useNavigation()

  useEffect(() => {
    if( status.processed ){
      setTimeout(() => {
        resetStatus({ series_id })
        if( series_id ){
          navigate(`/series/${series_id}`)
        }else{
          navigate('/')
        }
      }, 1000)
    }
  }, [status, resetStatus, navigate, series_id])

  const statusIcon = (
    status.error ?
      <ErrorIcon /> :
      status.processed ?
        <Check /> :
        status.processing ?
          <CircularProgress size='1em' color='inherit' /> :
          <InfoOutlined />
  )

  return (
    <ChannelLayout channel={channel}>
      <Box py={4} px={3}>

        <Box mt={5} display='flex' justifyContent='center'>

          <Alert
            variant={
              status.error ?
                'error' :
                status.processed ?
                  'success' :
                  'info'
            }
            p={5}
            pt={3}
            maxWidth='50rem'>

            { reportProvider && (
              <ProviderLogo
                provider={reportProvider}
                size={36}
                units='px' />
            )}

            <Box mt={3} fontSize={24} display='flex' alignItems='center'>
              <Box mr={1}>
                { statusIcon }
              </Box>
              { status.error ? (
                'Authorization could not be processed'
              ) : status.processed ? (
                'Authorization processed. Redirecting...'
              ) : (
                'Processing authorization...'
              )}
            </Box>

            { status.error && (
              <Fragment>
                <Box mb={6} fontSize={18}>
                  <p>{ status.error.message }</p>
                  Please try returning to the homepage, or selecting a dashboard from the menu.
                </Box>

                <Box display='flex' justifyContent='center'>
                  <Link to='/'>
                    <Button
                      size='large'
                      variant='contained'
                      startIcon={<ArrowBack />}>
                      Back To Homepage
                    </Button>
                  </Link>
                </Box>
              </Fragment>
            )}

          </Alert>

        </Box>

      </Box>
    </ChannelLayout>
  )
}

export default SeriesAuthHandler
