import {
  CompetitiveHistogram,
  CompetitiveStackedHistogram,
  CompetitiveTrendMultiLine,
  listItemsToChartData,
  nestedListItemsToMultiDatasets,
} from 'components/ReportingDashboard/Charts'
import { Report } from 'components/ReportingDashboard/DashboardLayout'
import { format } from 'date-fns'
import { Filters } from 'enums/Filters'
import { FormColumns } from 'enums/FormColumns'
import { WebFormTypes } from 'enums/WebFormTypes'
import { range } from 'lodash-es'
import { financialYearFormatter } from '@percept/mui/charts'
import { RestrictedRouteProps } from '@percept/app-components'


export const ALL_REPORTING_FEATURES_ENABLED =
  process.env.NODE_ENV === 'development' ||
  (process.env.WORKLOAD_TYPE !== 'PROD')


export const CALENDAR_YEAR_EPOCH = 2015

const calendarYearOptions = (
  range(CALENDAR_YEAR_EPOCH, new Date().getUTCFullYear() + 1).map( value => ({
    name: String(value),
    value: String(value),
  }))
)

const monthOptions = (
  range(12).map( i => {
    const date = new Date(CALENDAR_YEAR_EPOCH, i, 1)
    return {
      name: format(date, 'MMMM'),
      value: format(date, 'MMM'),
    }
  })
)

const financialYearMonthOptions = [
  ...monthOptions.slice(3),
  ...monthOptions.slice(0, 3),
]

export type InvestmentReportRouteDefinition = {
  path: string
  title: string
  props: Report[]
} & Partial<
  Pick<
    RestrictedRouteProps,
    'allowAdmin' | 'requiredGlobalPrivileges' | 'requiredOrgPrivileges'
  >
>


export const MEDIA_INVESTMENT_ROUTES: InvestmentReportRouteDefinition[] = [
  {
    path: '/campaign-pillar',
    title: 'Campaign Pillar',
    props: [
      {
        title: 'Campaign Pillar',
        subTitle:
          'Top-line campaign investment per Brand, Brand Product, Performance',
        selectOptions: [
          { label: 'Period', value: 'period' },
          { label: 'Market', value: 'market' },
        ],
        chart: 'stacked-bar-chart',
      },
      {
        title: 'Campaign Sub Pillar',
        subTitle:
          'Media investment broken down into Purpose-led, Pro-bono, Product, Performance, Business',
      },
      {
        title: 'Brand vs Performance',
        subTitle: 'Investment split of Brand & Brand product vs. Performance',
        selectOptions: [
          { label: 'Period', value: 'period' },
          { label: 'Market', value: 'market' },
        ],
      },
      {
        title: 'Consumer vs Business',
        subTitle:
          'Campaign investment by target: Consumer invest vs. Business invest',
      },
    ],
  },
  {
    path: '/media-invest-by-channel',
    title: 'Media Mix',
    props: [
      {
        title: 'Media Invest by Channel',
        subTitle:
          'Investment by media type: Digital, TV, Print, OOH, Radio, Cinema, Direct Mail',
        selectOptions: [          
          { label: 'Period', value: 'period' },
          { label: 'Market', value: 'market' },
          { label: 'Campaign Pillar', value: 'campaign-pillar' },
          { label: 'Campaign Sub Pillar', value: 'campaign-sub-pillar' },
        ],
      },
      ...(
        ALL_REPORTING_FEATURES_ENABLED
          ? [
            {
              subTitle: 'Investment year on year change per media type',
              title: 'Media Mix YOY in %',
              chart: 'multiline-chart',
              singleFormat: 'percentage',
              onlyYear: true,
            } as Report
          ] : []
      ),
      {
        title: 'Media Mix TV vs Digital',
        subTitle: 'Investment detail: TV vs. Digital',
        onlyYear: true,
      },
    ],
  },
  {
    path: '/second-brand',
    title: 'Second Brand',
    props: [
      {
        title: 'Second Brand',
        subTitle: 'Investment by 2nd brand',
        selectOptions: [
          { label: 'Period', value: 'period', chart: 'bar-group-chart' },
          { label: 'Campaign Pillar', value: 'campaign-pillar' },
          { label: 'Media Mix', value: 'media-mix' },
          { label: 'Digital Media Mix', value: 'digital-media-mix' },
        ],
        chart: 'stacked-bar-chart'
      },
    ],
  },
  {
    path: '/brand-messaging',
    title: 'Brand Messaging',
    props: [
      {
        title: 'Brand Messaging',
        subTitle:
          'Investment by message type: Green, Inclusion, Digital Society, Other',
        selectOptions: [
          { label: 'Market', value: 'market' },
          { label: 'Campaign Pillar', value: 'campaign-pillar' },
          { label: 'Media Mix', value: 'media-mix' },
        ],
      },
    ],
  },
  {
    path: '/product',
    title: 'Product',
    props: [
      {
        title: 'Product',
        subTitle:
          'Investment by product: Mobile, Fixed, Converged, TV, Purpose, Business, etc',
        selectOptions: [
          { label: 'Market', value: 'market' },
          { label: 'Campaign Pillar', value: 'campaign-pillar' },
          { label: 'Media Mix', value: 'media-mix' },
        ],
      },
    ],
  },
  {
    path: '/funding-source',
    title: 'Funding Source',
    props: [
      {
        title: 'Funding Source',
        subTitle: 'Investment by source of funds: A&R and Media (OPEX)',
        selectOptions: [
          { label: 'Market', value: 'market' },
          { label: 'Campaign Pillar', value: 'campaign-pillar' },
          { label: 'Campaign Sub Pillar', value: 'campaign-sub-pillar' },
          { label: 'Media Mix', value: 'media-mix' },
          { label: 'Digital Media Mix', value: 'digital-media-mix' },
          { label: 'Brand vs Performance', value: 'brand-vs-performance' },
        ],
      },
    ],
  },
]


export const COMPETITIVE_INVESTMENT_ROUTES: InvestmentReportRouteDefinition[] = [
  {
    path: '/share-of-spend',
    title: 'Share of Spend',
    props: [
      {
        reportKey: 'sos-by-market',
        title: 'Vodafone',
        subTitle: 'Share of Spend - Vodafone',
        defaultDataFormat: 'percentage',
        onlyYear: true,
        Chart: {
          Component: CompetitiveHistogram,
          adapter: listItemsToChartData,
        },
        chartColumnSelect: {
          label: 'Financial Year',
          defaultIndex: -1,
          reverseSort: true,
        },
      },
      {
        reportKey: 'sos-largest-competitor',
        title: 'Largest Competitor',
        subTitle: 'Share of Spend - Vodafone largest competitors',
        onlyYear: true,
        defaultDataFormat: 'percentage',
        Chart: {
          Component: CompetitiveHistogram,
          adapter: listItemsToChartData,
        },
        chartColumnSelect: {
          label: 'Financial Year',
          defaultIndex: -1,
          reverseSort: true,
        },
      },
      {
        reportKey: 'sos-gap-to-largest-competitor',
        title: 'Gap to Largest Competitor',
        subTitle: 'Share of Spend - Vodafone compared to largest competitor in pp',
        onlyYear: true,
        defaultDataFormat: 'percentage',
        Chart: {
          Component: CompetitiveHistogram,
          adapter: listItemsToChartData,
          componentProps: {
            mirrorYDomain: true,
          },
        },
        chartColumnSelect: {
          label: 'Financial Year',
          defaultIndex: -1,
          reverseSort: true,
        },
      },
      {
        reportKey: 'sos-trend-vs-largest-competitor',
        title: 'Trend vs Largest Competitor',
        subTitle: 'Share of Spend - Vodafone compared to largest competitor over time',
        onlyYear: true,
        defaultViewType: 'chart',
        defaultDataFormat: 'percentage',
        Chart: {
          Component: CompetitiveTrendMultiLine,
          adapter: nestedListItemsToMultiDatasets,
          includeTotals: true,
          dataConfiguration: {
            isTimeline: true,
            ignoreKeys: ['Difference'],
            segmentDimensionExclusions: {
              'All total': [
                'FY1516',
                'FY1617',
                'FY1718',
                'FY1819',
              ]
            },
            domainPositions: [
              [0, 'Vodafone']
            ],
          },
          componentProps: {
            xScaleType: 'time',
            arbitraryTimeline: true,
            xTickFormatter: financialYearFormatter,
          },
        },
        chartRowSelect: {
          label: 'Market',
          defaultIndex: 0,
        },
      },
      {
        reportKey: 'sos-overview',
        title: 'All Competitors',
        subTitle: 'Share of Spend - all Vodafone competitors per market',
        onlyYear: true,
        defaultDataFormat: 'percentage',
        Chart: {
          Component: CompetitiveStackedHistogram,
          adapter: nestedListItemsToMultiDatasets,
          dataConfiguration: {
            isTimeline: true,
            domainPositions: [
              [0, 'Vodafone']
            ],
          },
          componentProps: {
            xTickFormatter: financialYearFormatter,
          },
        },
        chartRowSelect: {
          label: 'Market',
          defaultIndex: 0,
        },
      },
    ],
  },
  // SOV routes
  {
    path: '/tv-share-of-voice',
    title: 'TV Share of Voice',
    props: [
      {
        reportKey: 'sov-by-market',
        title: 'Vodafone',
        subTitle: 'Share of Voice - Vodafone',
        selectType: 'selectScopeOfData',
        onlyYear: true,
        defaultDataFormat: 'percentage',
        Chart: {
          Component: CompetitiveHistogram,
          adapter: listItemsToChartData,
        },
        chartColumnSelect: {
          label: 'Financial Year',
          defaultIndex: -1,
          reverseSort: true,
        },
      },
      {
        reportKey: 'sov-largest-competitor',
        title: 'Largest Competitor',
        subTitle: 'Share of Voice - Vodafone largest competitors',
        onlyYear: true,
        defaultDataFormat: 'percentage',
        Chart: {
          Component: CompetitiveHistogram,
          adapter: listItemsToChartData,
        },
        chartColumnSelect: {
          label: 'Financial Year',
          defaultIndex: -1,
          reverseSort: true,
        },
      },
      {
        reportKey: 'sov-gap-to-largest-competitor',
        title: 'Gap to Largest Competitor',
        subTitle: 'Share of Voice - Vodafone compared to largest competitor in pp',
        onlyYear: true,
        defaultDataFormat: 'percentage',
        Chart: {
          Component: CompetitiveHistogram,
          adapter: listItemsToChartData,
          componentProps: {
            mirrorYDomain: true,
          },
        },
        chartColumnSelect: {
          label: 'Financial Year',
          defaultIndex: -1,
          reverseSort: true,
        },
      },
      // {
      //   reportKey: 'sov-gap-to-nearest-competitor',
      //   title: 'Gap to Nearest Competitor',
      //   subTitle: 'Share of Voice - Vodafone compared to nearest competitor in pp',
      //   chart: 'bar-group-chart',
      //   onlyYear: true,
      //   defaultViewType: 'chart',
      //   defaultDataFormat: 'percentage',
      //   Chart: {
      //     Component: CompetitiveHistogram,
      //     adapter: listItemsToChartData,
      //     componentProps: {
      //       mirrorYDomain: true,
      //     },
      //   },
      //   chartColumnSelect: {
      //     label: 'Financial Year',
      //     defaultIndex: -1,
      //     reverseSort: true,
      //   },
      // },
      {
        reportKey: 'sov-overview',
        title: 'All Competitors',
        subTitle: 'Share of Voice - all Vodafone competitors per market',
        onlyYear: true,
        defaultDataFormat: 'percentage',
        Chart: {
          Component: CompetitiveStackedHistogram,
          adapter: nestedListItemsToMultiDatasets,
          dataConfiguration: {
            isTimeline: true,
            domainPositions: [
              [0, 'Vodafone']
            ],
          },
          componentProps: {
            xTickFormatter: financialYearFormatter,
          },
        },
        chartRowSelect: {
          label: 'Market',
          defaultIndex: 0,
        },
      },
    ],
  },
]

export const SIDEBAR_TABS_MEDIA_INVESTMENT = MEDIA_INVESTMENT_ROUTES.map(
  (el) => ({
    title: el.title,
    value: el.path,
  })
)

export const SIDEBAR_TABS_COMPETITIVE_INVESTMENT =
  COMPETITIVE_INVESTMENT_ROUTES.map((el) => ({
    title: el.title,
    value: el.path,
  }))

export const POSSIBLE_FILTERS_MEDIA_INVESTMENT = [
  {
    filter_name: 'Market',
    filter_value: 'market',
    groups: [
      {
        group_name: 'Markets',
        group_value: Filters.Markets,
        options: [],
      },
    ],
  },
  {
    filter_name: 'Period',
    filter_value: 'period',
    groups: [
      {
        group_name: 'Financial Year',
        group_value: Filters.FinancialYear,
        options: [],
      },
      ...(ALL_REPORTING_FEATURES_ENABLED && [
        {
          group_name: 'Calendar Year',
          group_value: Filters.CalendarYears,
          options: calendarYearOptions,
        },
      ] || []),
    ],
  },
  {
    filter_name: 'Second Brand',
    filter_value: 'second-brand',
    groups: [
      {
        group_name: 'Second Brand',
        group_value: Filters.SecondBrand,
        options: [],
      },
    ],
  },
  {
    filter_name: 'Media Channel',
    filter_value: 'media-channel',
    groups: [
      {
        group_name: 'Media Channel',
        group_value: Filters.MediaChannel,
        options: [],
      },
    ],
  },
  {
    filter_name: 'Media Sub Channel',
    filter_value: 'media-sub-channel',
    groups: [
      {
        group_name: 'Media Sub Channel',
        group_value: Filters.MediaSubChannel,
        options: [],
      },
    ],
  },
  {
    filter_name: 'Funding Source',
    filter_value: 'funding_source',
    groups: [
      {
        group_name: 'Funding Source',
        group_value: Filters.FundingSource,
        options: [],
      },
    ],
  },
  {
    filter_name: 'Campaign Pillar',
    filter_value: 'campaign-pillar',
    groups: [
      {
        group_name: 'Campaign Pillar',
        group_value: Filters.Campaign,
        options: [],
      },
    ],
  },
  {
    filter_name: 'Sub-Campaign Pillar',
    filter_value: Filters.SubCampaign,
    groups: [
      {
        group_name: 'Sub-Campaign Pillar',
        group_value: Filters.SubCampaign,
        options: [],
      },
    ],
  },
  {
    filter_name: 'Buy Type',
    filter_value: Filters.BuyType,
    groups: [
      {
        group_name: 'Buy Type',
        group_value: Filters.BuyType,
        options: [],
      },
    ],
  },
  {
    filter_name: 'JBP',
    filter_value: Filters.JBP,
    groups: [
      {
        group_name: 'JBP',
        group_value: Filters.JBP,
        options: [],
      },
    ],
  },
]


const BASE_COMPETITIVE_FILTERS = [
  {
    filter_name: 'Start Month',
    filter_value: 'start-month',
    isSingleValue: true,
    groups: [
      {
        group_name: 'Start Month',
        group_value: Filters.StartMonth,
        options: financialYearMonthOptions,
      },
    ]
  },
  {
    filter_name: 'End Month',
    filter_value: 'end-month',
    isSingleValue: true,
    groups: [
      {
        group_name: 'End Month',
        group_value: Filters.EndMonth,
        options: financialYearMonthOptions,
      },
    ]
  },
]


export const POSSIBLE_FILTERS_COMPETITIVE = [
  ...BASE_COMPETITIVE_FILTERS,
  // {
  //   filter_name: 'Media Sub Channel',
  //   filter_value: 'media-sub-channel',
  //   groups: [
  //     {
  //       group_name: 'Media Sub Channel',
  //       group_value: Filters.MediaSubChannel,
  //       options: [],
  //     },
  //   ],
  // },
  // {
  //   filter_name: 'Competitor',
  //   filter_value: 'competitor',
  //   groups: [
  //     {
  //       group_name: 'Competitor',
  //       group_value: Filters.Competitor,
  //       options: [],
  //     },
  //   ],
  // },
  // {
  //   filter_name: 'Actual Competitor',
  //   filter_value: 'actual-competitor',
  //   groups: [
  //     {
  //       group_name: 'Actual Competitor',
  //       group_value: Filters.ActualCompetitor,
  //       options: [],
  //     },
  //   ],
  // },
]

export const POSSIBLE_FILTERS_COMPETITIVE_SOS = [
  ...BASE_COMPETITIVE_FILTERS,
  {
    filter_name: 'Media Channel',
    filter_value: 'media-channel',
    groups: [
      {
        group_name: 'Media Channel',
        group_value: Filters.MediaChannel,
        options: [],
      },
    ],
  },
]

export const POSSIBLE_FILTERS_COMPETITIVE_SOV = [
  ...BASE_COMPETITIVE_FILTERS,
]

// export const POSSIBLE_FILTERS_COMPETITIVE_SOS = [
//   {
//     filter_name: 'Period',
//     filter_value: 'period',
//     groups: [
//       {
//         group_name: 'Financial Year',
//         group_value: Filters.FinancialYear,
//         options: [],
//       },
//       ...(ALL_REPORTING_FEATURES_ENABLED && [
//         {
//           group_name: 'Calendar Year',
//           group_value: Filters.CalendarYears,
//           options: calendarYearOptions,
//         },
//       ] || []),
//     ],
//   },
//   {
//     filter_name: 'Media Channel',
//     filter_value: 'media-channel',
//     groups: [
//       {
//         group_name: 'Media Channel',
//         group_value: Filters.MediaChannel,
//         options: [],
//       },
//     ],
//   },
//   {
//     filter_name: 'Competitors',
//     filter_value: 'competitors',
//     groups: [
//       {
//         group_name: 'Competitors',
//         group_value: Filters.Competitor,
//         options: [],
//       },
//     ],
//   },
//   {
//     filter_name: 'Competitive Second Brand',
//     filter_value: 'competitive-second-brand',
//     groups: [
//       {
//         group_name: 'Competitive Second Brand',
//         group_value: Filters.CompetitiveSecondBrand,
//         options: [],
//       },
//     ],
//   },
//   {
//     filter_name: 'Source',
//     filter_value: 'source',
//     groups: [
//       {
//         group_name: 'Source',
//         group_value: Filters.Source,
//         options: [],
//       },
//     ],
//   },
//   {
//     filter_name: 'Agency',
//     filter_value: 'agency',
//     groups: [
//       {
//         group_name: 'Agency',
//         group_value: Filters.Agency,
//         options: [],
//       },
//     ],
//   },
//   {
//     filter_name: 'Agency Discount',
//     filter_value: 'agency-discount',
//     groups: [
//       {
//         group_name: 'Agency Discount',
//         group_value: Filters.AgencyDiscount,
//         options: [],
//       },
//     ],
//   },
//   {
//     filter_name: 'Time Lag',
//     filter_value: 'time-lag',
//     groups: [
//       {
//         group_name: 'Time Lag',
//         group_value: Filters.TimeLag,
//         options: [],
//       },
//     ],
//   },
// ]

// export const POSSIBLE_FILTERS_COMPETITIVE_SOV = [
//   {
//     filter_name: 'Period',
//     filter_value: 'period',
//     groups: [
//       {
//         group_name: 'Financial Year',
//         group_value: Filters.FinancialYear,
//         options: [],
//       },
//       ...(ALL_REPORTING_FEATURES_ENABLED && [
//         {
//           group_name: 'Calendar Year',
//           group_value: Filters.CalendarYears,
//           options: calendarYearOptions,
//         },
//       ] || []),
//     ],
//   },
//   {
//     filter_name: 'Media Channel',
//     filter_value: 'media-channel',
//     groups: [
//       {
//         group_name: 'Media Channel',
//         group_value: Filters.MediaChannel,
//         options: [],
//       },
//     ],
//   },
//   {
//     filter_name: 'Competitors',
//     filter_value: 'competitors',
//     groups: [
//       {
//         group_name: 'Competitors',
//         group_value: Filters.Competitor,
//         options: [],
//       },
//     ],
//   },
//   {
//     filter_name: 'Competitive Second Brand',
//     filter_value: 'competitive-second-brand',
//     groups: [
//       {
//         group_name: 'Competitive Second Brand',
//         group_value: Filters.CompetitiveSecondBrand,
//         options: [],
//       },
//     ],
//   },
//   {
//     filter_name: 'Source',
//     filter_value: 'source',
//     groups: [
//       {
//         group_name: 'Source',
//         group_value: Filters.Source,
//         options: [],
//       },
//     ],
//   },
//   {
//     filter_name: 'Agency',
//     filter_value: 'agency',
//     groups: [
//       {
//         group_name: 'Agency',
//         group_value: Filters.Agency,
//         options: [],
//       },
//     ],
//   },
//   {
//     filter_name: 'Time Lag',
//     filter_value: 'time-lag',
//     groups: [
//       {
//         group_name: 'Time Lag',
//         group_value: Filters.TimeLag,
//         options: [],
//       },
//     ],
//   },
//   {
//     filter_name: 'Spot Length',
//     filter_value: 'spot-length',
//     groups: [
//       {
//         group_name: 'Spot Length',
//         group_value: Filters.SpotLength,
//         options: [],
//       },
//     ],
//   },
//   {
//     filter_name: 'Traded Audience',
//     filter_value: 'traded-audience',
//     groups: [
//       {
//         group_name: 'Traded Audience',
//         group_value: Filters.TradedAudience,
//         options: []
//       }
//     ]
//   }
// ]

export const MEDIA_INVESTMENT_FORM_COLUMNS = [
  {
    name: FormColumns.secondBrand,
    label: 'BRAND',
    description: 'Investment by 2nd brand',
  },
  {
    name: FormColumns.campaignPillar,
    label: 'CAMPAIGN PILLAR',
    description:
      'Top-line campaign investment per Brand, Brand Product, Performance',
  },
  {
    name: FormColumns.campaignSubPillar,
    description:
      'Media investment broken down into Purpose-led, Pro-bono, Product, Performance, Business',
    label: 'SUB-CAMPAIGN PILLAR',
  },
  {
    name: FormColumns.buyType,
    description:
      'Investment per: Programmatic, Direct Buy, Credits, Apple Fund',
    label: 'BUY TYPE',
  },
  {
    name: FormColumns.mediaChannel,
    description:
      'Investment by media type: Digital, TV, Print, OOH, Radio, Cinema, Direct Mail',
    label: 'MEDIA CHANNEL',
  },
  {
    name: FormColumns.mediaSubChannel,
    description:
      'Investment broken down into details per Media Channel: Paid Social, Video, Audio, Native, Display, etc',
    label: 'MEDIA-SUB CHANNEL',
  },
  {
    name: FormColumns.fundingSource,
    description: 'Investment by source of funds: A&R and Media (OPEX)',
    label: 'FUNDING SOURCE',
  },
  {
    name: FormColumns.message,
    description:
      'Investment by message type: Green, Inclusion, Digital Society, Other',
    label: 'MESSAGE',
  },
  {
    name: FormColumns.product,
    description:
      'Investment by product: Mobile, Fixed, Converged, TV, Purpose, Business, etc',
    label: 'PRODUCT',
  },
  {
    name: FormColumns.jbp,
    description:
      'Joint Business Partnerships with Vendors such as Google, Meta, TikTok, etc.',
    label: 'JBP/VENDOR',
  },
]

export const COMPETITIVE_INVESTMENT_FORM_COLUMNS = [
  {
    name: FormColumns.agency,
    label: 'AGENCY',
    description:
      'Any 3rd party supporting Vodafone in running Media Report activities',
  },
  {
    name: FormColumns.mediaChannel,
    description:
      'Investment by media type: Digital, TV, Print, OOH, Radio, Cinema, Direct Mail',
    label: 'MEDIA CHANNEL',
  },
  {
    name: FormColumns.dataSource,
    description: 'Provenience of the data',
    label: 'DATA SOURCE',
  },
  {
    name: FormColumns.competitor,
    description: 'Direct competitors to Vodafone and Vodafone sub-brands',
    label: 'COMPETITORS',
  },
  {
    name: FormColumns.competitveSecondBrand,
    description: 'Investment by 2nd brand',
    label: 'BRAND',
  },
  {
    name: FormColumns.timeLags,
    description: '',
    label: 'TIME LAG',
  },
  {
    name: FormColumns.grossNet,
    description:
      'Net investment is Gross minus costs (e.g. taxes, headcount, facilities, etc)',
    label: 'GROSS/NET',
  },
  {
    name: FormColumns.agencyDiscount,
    description: 'Any 3rd party related discount applied to the investment',
    label: 'AGENCY DISCOUNT',
  },
]

export const COMPETITIVE_TV_SHARE_OF_VOICE_FORM_COLUMNS = [
  {
    name: FormColumns.agency,
    description:
      'Any 3rd party supporting Vodafone in running Media Report activities',
    label: 'AGENCY',
  },
  {
    name: FormColumns.tradedAudience,
    description: 'Audience segment of focus',
    label: 'AUDIENCE',
  },
  {
    name: FormColumns.dataSource,
    description: 'Provenience of the data',
    label: 'DATA SOURCE',
  },
  {
    name: FormColumns.competitor,
    description: 'Direct competitors to Vodafone and Vodafone sub-brands',
    label: 'COMPETITORS',
  },
  {
    name: FormColumns.competitveSecondBrand,
    description: 'Investment by 2nd brand',
    label: 'BRAND',
  },
  {
    name: FormColumns.timeLags,
    description: '',
    label: 'TIME LAG',
  },
  {
    name: FormColumns.equivSpotLengths,
    description: '',
    label: 'CONFIRM 30" EQUIV SPOT LENGTHS',
  },
  {
    name: FormColumns.spotLengths,
    description: '',
    label: 'IF "NO" SELECT SPOT LENGTHS',
  },
]
export const FORM_ROUTES = [
  {
    path: '/media-investment',
    props: {
      title: 'Media Investment',
      tableColumnsWithOptions: MEDIA_INVESTMENT_FORM_COLUMNS,
      value: WebFormTypes.MediaInvestment,
    },
  },
  {
    path: '/competitive-investment',
    props: {
      title: 'Competitive Investment',
      tableColumnsWithOptions: COMPETITIVE_INVESTMENT_FORM_COLUMNS,
      value: WebFormTypes.CompetitiveInvestment,
    },
  },
  {
    path: '/competitve-tv-share-of-voice',
    props: {
      title: 'Competitive TV Share of Voice',
      tableColumnsWithOptions: COMPETITIVE_TV_SHARE_OF_VOICE_FORM_COLUMNS,
      value: WebFormTypes.CompetitiveTVSOV,
    },
  },
]

export const ACTIVE_FORM_ROUTES = ALL_REPORTING_FEATURES_ENABLED
  ? FORM_ROUTES
  : FORM_ROUTES.slice(0, 1)
