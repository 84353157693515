
import axios from 'axios'


const { API_ROOT } = process.env

const api = axios.create({
  baseURL: API_ROOT
})

export default api
