import React, { useEffect, useState, useContext } from 'react'
import {
  Badge,
  Box,
  FormControl,
  InputLabel,
  Loader,
  MenuItem,
  RoundedPlainTextButton,
  Select,
  Typography,
  makeAppStyles,
} from '@percept/mui'
import { formatDate } from '../../utils'
import { ReportService } from '../../api/reports'
import {
  BrandGrowthPlannerContext,
  BrandGrowthPlannerTabContext,
} from '../../contexts'
import { MarketService } from '../../api/markets'
import { Report } from './components'

const useStyles = makeAppStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 168,
  },
  note: {
    fontSize: '18px',
  },
  submitAnalysisNote: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > button': {
      fontSize: '16px',
      margin: '0 0.4em',
    },
  },
  reportCountBadge: {
    right: -16,
    top: '50%',
    fontWeight: 700,
  },
}))


type ReportContentProps = {
  marketId: string | undefined
  reportId: string | undefined
  isLoading: boolean
  loaderMessage: string | null
  isError: boolean
  errorMessage: string | null
}

function ReportContent({
  marketId,
  reportId,
  isLoading,
  loaderMessage,
  isError,
  errorMessage,
}: ReportContentProps): JSX.Element | null {

  const {
    data: report,
    isLoading: isLoadingReport,
    isError: isErrorReport,
    isIdle: isIdleReport,
  } = ReportService.useReport(marketId, reportId)


  if( isErrorReport ){
    isError = true
    errorMessage = 'An error occurred loading the report'
  }
  if( isLoadingReport ){
    isLoading = true
    loaderMessage = 'Loading report...'
  }

  if( isLoading ){
    return (
      <Loader preset='centered'>
        <Typography style={{fontSize: 18}}>{ loaderMessage }</Typography>
      </Loader>
    )
  }

  if( isError ){
    return (
      <Typography style={{fontSize: 18}}>{ errorMessage }</Typography>
    )
  }

  if (isIdleReport || !report) return null

  return (
    <Box marginTop={8}>
      <Report report={report} />
    </Box>
  )
}


export function LatestReportPage(): JSX.Element {
  const classes = useStyles()

  const { isGlobalUser, marketId } = useContext(BrandGrowthPlannerContext)

  const { navigateToTab } = useContext(BrandGrowthPlannerTabContext)

  const [selectedMarketId, setSelectedMarketId] = useState<string>()
  const [selectedReportId, setSelectedReportId] = useState<string>()

  const {
    data: markets,
    isLoading: isLoadingMarkets,
    isError: isErrorMarkets,
  } = MarketService.useMarkets({
    enabled: isGlobalUser,
  })

  const {
    data: reports,
    isLoading: isLoadingReports,
    isError: isErrorReports,
    isIdle: isIdleReports,
  } = ReportService.useReports(selectedMarketId)

  useEffect(() => {
    if (markets && markets.length && !selectedMarketId) {
      setSelectedMarketId(markets[0].id)
    }
  }, [markets, selectedMarketId])

  useEffect(() => {
    if (reports && reports.length && !selectedReportId) {
      const lastReport = reports[reports.length - 1]

      if (lastReport) {
        setSelectedReportId(lastReport.id)
      }
    }
  }, [reports, selectedReportId])

  useEffect(() => {
    if (!isGlobalUser) {
      setSelectedMarketId(marketId)
    }
  }, [isGlobalUser, marketId])

  const handleSelectedMarketChange = (
    e: React.ChangeEvent<{
      value: unknown
    }>
  ): void => {
    const marketId = e.target.value as string

    setSelectedMarketId(marketId)
    setSelectedReportId(undefined)
  }

  const handleSelectedReportChange = (
    e: React.ChangeEvent<{
      value: unknown
    }>
  ): void => {
    const reportId = e.target.value as string
    const report = reports?.find((report): boolean => report.id === reportId)

    if (report !== undefined) {
      setSelectedReportId(report.id)
    }
  }

  const selectedMarket = (
    selectedMarketId && markets && markets.find( m => m.id === selectedMarketId )
  ) || null

  const loaderMesssage = (
    isLoadingMarkets ?
      'Loading markets...' :
      isLoadingReports ? 
        'Loading reports...' :
        null
  )

  const errorMessage = (
    isErrorMarkets ?
      'An error occurred loading markets' :
      isErrorReports ?
        'An error occurred loading reports' :
        null
  )

  const selectMarketLabel = isLoadingMarkets ? 'Loading markets...' : 'Market'
  const selectReportDateLabel = isLoadingReports
    ? 'Loading report dates...'
    : 'Report date'

  return (
    <>
      <Box display='flex' justifyContent='flex-end' flexWrap='wrap'>
        {isGlobalUser && (
          <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel htmlFor='selectMarket'>{selectMarketLabel}</InputLabel>
            <Select
              label={selectMarketLabel}
              inputProps={{ id: 'selectMarket' }}
              value={selectedMarketId ?? ''}
              onChange={handleSelectedMarketChange}>
              {markets &&
                markets.map((market) => (
                  <MenuItem key={market.id} value={market.id}>
                    <Badge
                      showZero
                      color='secondary'
                      classes={{
                        badge: classes.reportCountBadge,
                      }}
                      badgeContent={market.report_count}>
                      <span>
                        {market.name}
                      </span>
                    </Badge>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}

        <FormControl variant='outlined' className={classes.formControl}>
          <InputLabel htmlFor='selectReportDate'>
            {selectReportDateLabel}
          </InputLabel>
          <Select
            label={selectReportDateLabel}
            inputProps={{ id: 'selectReportDate' }}
            value={selectedReportId ?? ''}
            onChange={handleSelectedReportChange}>
            {reports &&
              reports.map((report) => (
                <MenuItem key={report.id} value={report.id}>
                  {report.name ? `${report.name} - ` : ''}{' '}
                  {formatDate(report.created_at)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>

      { isIdleReports ? 
        null :
        reports && !reports.length ? (
          !isGlobalUser ? (
            <Box textAlign='center' p={3}>
              <div className={`${classes.note} ${classes.submitAnalysisNote}`}>
                Please
                <RoundedPlainTextButton
                  size='small'
                  variant='contained'
                  color='primary'
                  onClick={(): void => {
                    navigateToTab('SUBMIT')
                  }}>
                  Submit Analysis
                </RoundedPlainTextButton>
                in order to view your first report
              </div>
            </Box>
          ) : (
            <Box textAlign='center' p={3}>
              <Typography className={classes.note}>
                There are no reports for {selectedMarket ? selectedMarket.name : 'this market'}.
                Reports are created by markets after submitting data for analysis
              </Typography>
            </Box>
          )
        ) : (
          <ReportContent
            marketId={selectedMarketId}
            reportId={selectedReportId}
            isLoading={isLoadingMarkets || isLoadingReports}
            loaderMessage={loaderMesssage}
            isError={isErrorMarkets || isErrorReports}
            errorMessage={errorMessage} />
        )
      }

    </>
  )
}
