
import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'

import { ReduxBundle } from '../lib'

import { SeriesState } from './typings'


type SeriesActions = typeof actions
type SeriesSelectors = typeof selectors


export type SeriesBundle = ReduxBundle<SeriesState, SeriesActions, SeriesSelectors>


export { actions, reducer, selectors }


export const makeBundle = (): SeriesBundle => ({
  actions,
  reducer,
  selectors
})

export default { actions, reducer, selectors } as SeriesBundle
