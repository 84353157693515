
import moize from 'moize'

import { any, getHealthFrom, getPath } from '@percept/utils'

import { mapValues, sortBy } from 'lodash-es'

import {
  PerformanceDimensionType,
  ReportEntities,
  ReportEntity,
  Dictionary,
  EntityType,
  TopLevelEntityType,
} from '@percept/types'

import {
  EntityListType,
  TreeEntityListItem,
  SortConfig,
  IntermediateEntity,
  PerformanceValues
} from './typings'


export const dimensions = [
  'cost',
  'impressions',
  'clicks',
  'conversions',
] as PerformanceDimensionType[]


type EntityGroupOption = {
  key: EntityListType
  value: EntityListType
  label: string
}

export const groupOptions: EntityGroupOption[] = [
  { key: 'AccountGroup', value: 'AccountGroup', label: 'Account Groups' },
  { key: 'CampaignGroup', value: 'CampaignGroup', label: 'Campaign Groups' },
]


export const entityTypeMembers = {
  AccountGroup: 'Account',
  CampaignGroup: 'Campaign'
} as Record<EntityListType, EntityType>


export const reverseEntityTypeMembers = Object.entries(entityTypeMembers).reduce(
  (acc: Record<EntityType, EntityListType>, [k, v]) => {
    acc[v] = k as EntityListType
    return acc
  }, {} as Record<EntityType, EntityListType>)


export const getParentEntityType = (entityType: EntityType): EntityListType => {
  if( typeof entityTypeMembers[entityType as EntityListType] !== 'undefined' ){
    return entityType as EntityListType
  }
  if( typeof reverseEntityTypeMembers[entityType] !== 'undefined' ){
    return reverseEntityTypeMembers[entityType]
  }
  return 'AccountGroup'
}

export const isTopLevelEntityType = (entityType: EntityType): entityType is TopLevelEntityType => {
  return (
    entityType === 'AccountGroup'
    || entityType === 'CampaignGroup'
    || entityType === 'Account'
  )
}


export const mapEntities = (entities: ReportEntity[], id = ''): TreeEntityListItem[] => (
  entities.map( e => {
    const performance = e.performance || {} as Dictionary
    return {
      id: e.id,
      active: !!(id && e.id === id),
      member_type: e.member_type,
      type: e.type,
      members: e.members as TreeEntityListItem[],
      performanceValues: {
        cost: performance.cost,
        clicks: performance.clicks,
        conversions: performance.conversions,
        impressions: performance.impressions,
      } as PerformanceValues,
      currency: e.currency_code,
      name: e.name,
      health: getHealthFrom(e),
    }
  })
)


export const resolveEntityMapping = (
  entities: ReportEntities
): ReportEntities => {
  /* 
  The API can return entities which have incorrect names.
  These names are correct within the 'members' listing of
  groups, so we treat that as the source of truth instead.
  */
  const memberEntityMapping = Object.values(entities).reduce( (acc, entityMapping) => {
    Object.values(entityMapping).forEach( entity => {
      if( entity.members ){
        entity.members.forEach( member => {
          acc[member.id] = member.name
        })
      }
    })
    return acc
  }, {} as Record<string, string>)

  return mapValues(entities, mapping => {
    return mapValues(mapping, e => {
      return {
        ...e,
        name: memberEntityMapping[e.id] || e.name
      }
    })
  })
}


export const entitiesToNestedArray = moize.simple(
  (entities: ReportEntities) => {
    const entityTypes = Object.keys(entities) as EntityType[]

    const resolvedEntityMapping = resolveEntityMapping(entities)
    
    return entityTypes.reduce( (obj: Record<EntityType, ReportEntity[]>, level) => {
      if( resolvedEntityMapping[level] ){
        obj[level] = Object.keys(resolvedEntityMapping[level]).map( k => {
          const reportEntity = resolvedEntityMapping[level][k]
          const isRoot = reportEntity.id === '123' && reportEntity.name === 'All Accounts'

          const member_type = (
            isRoot ?
              'Account' :
              reportEntity.member_type
          )

          let members

          if( isRoot || reportEntity.members && member_type ){
            members = (
              isRoot ?
                Object.values(resolvedEntityMapping.Account || obj.Account || {}) :
                (reportEntity.members || []).map( ({ id, name }) => ({
                  id,
                  name,
                  ...getPath(resolvedEntityMapping, [member_type, id], {}),
                }) )
            ) as ReportEntity[]

            members = mapEntities(
              members.map( m => ({ ...m, type: member_type })) as ReportEntity[]
            )

          }

          return {
            ...reportEntity,
            type: level,
            member_type,
            members
          }
        })
      }
      return obj
    }, {} as Record<EntityType, ReportEntity[]>)
  }
)


export function noDataFilter<T extends IntermediateEntity>(e: T): boolean {
  return e.active || e.name !== 'No Data'
}


export const getMatchingEntitiesByType = ({
  entities,
  matchingQuery,
  activeId,
  sortKey,
  sortOrder,
  filterNoData,
}: {
  entities: ReportEntities,
  matchingQuery: string,
  activeId: string,
  sortKey: SortConfig['key'],
  sortOrder: SortConfig['order'],
  filterNoData: boolean
}): Record<EntityType, TreeEntityListItem[]> => {

  const entityArraysByType = entitiesToNestedArray(entities)

  const filterer = filterNoData ? noDataFilter : ((): boolean => true)

  const entityTypes = Object.keys(entityArraysByType) as EntityType[]

  return entityTypes.reduce( (obj: Record<EntityType, TreeEntityListItem[]>, entityType) => {
    
    if( Object.keys(entityArraysByType[entityType]).length ){

      const mappedEntities = mapEntities(entityArraysByType[entityType], activeId)

      const sortIteratees = (
        dimensions.indexOf(sortKey as PerformanceDimensionType) !== -1 ?
          (e: TreeEntityListItem): number | null => e.performanceValues && e.performanceValues[sortKey as PerformanceDimensionType] :
          [sortKey]
      )

      let sortedEntities = sortBy(
        mappedEntities,
        sortIteratees,
      )

      const isDescending = sortOrder === 'DESC'

      if( isDescending ){
        sortedEntities = sortedEntities.reverse()
      }

      const filteredEntities = sortedEntities.map( m => {
        let members = sortBy(
          m.members,
          sortIteratees
        ).filter(filterer)

        if( isDescending ){
          members = members.reverse()
        }

        return {
          ...m,
          active: m.id === activeId,
          members,
        }
      }).filter(filterer)

      obj[entityType] = (
        matchingQuery ?
          filteredEntities.filter( e => {
            return (
              e.name.toLowerCase().indexOf(matchingQuery) > -1
              || e.members && any(e.members, m => (
                !!(m.name && m.name.toLowerCase().indexOf(matchingQuery) > -1)
              ))
            )
          }).map( e => ({
            ...e,
            members: (
              e.members ?
                e.members.filter( m => m.name.toLowerCase().indexOf(matchingQuery) > - 1 ) :
                undefined
            )
          })) :
          filteredEntities
      )
    }

    return obj
  }, {} as Record<EntityType, TreeEntityListItem[]>)
}
