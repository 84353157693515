import React from 'react'

import { Box, Card, CardProps, Chip, ClassNameMap, Divider, SecondaryIconTooltip, Typography } from '@percept/mui'

import { OverviewClassNames } from './typings'


export type OverviewCardProps = {
  title: string
  category: string
  tooltipContent?: string | JSX.Element
  classes: ClassNameMap<OverviewClassNames>
} & CardProps

export const OverviewCard = ({
  title,
  category,
  tooltipContent,
  children,
  classes,
  ...props
}: OverviewCardProps): JSX.Element => {
  return (
    <Card {...props}>
      <Box display='flex' alignItems='center' justifyContent='space-between' p={1.5}>
        <Typography
          variant='h5'
          className={classes.header}>
          { title }
          { tooltipContent && (
            <SecondaryIconTooltip
              className={classes.tooltip}
              title={tooltipContent} />
          )}
        </Typography>
        <Chip
          size='small'
          color='primary'
          label={category} />
      </Box>
      <Divider />
      {children}
    </Card>
  )
}
