import { makeAuthorizedApiClient } from '@percept/hooks'

const { VODAFONE_CUSTOM_API_ROOT } = process.env


export const newsClient = makeAuthorizedApiClient({
  baseURL: `${VODAFONE_CUSTOM_API_ROOT}/news/v1`,
})

export const newsAdminClient = makeAuthorizedApiClient({
  baseURL: `${VODAFONE_CUSTOM_API_ROOT}/news/v1/admin`,
})
