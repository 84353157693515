import React, { useMemo, useState } from 'react'

import {
  Grid,
  makeAppStyles,
  ReportRange,
} from '@percept/mui'

import {
  ChartData,
  detailedPercentageFormatter,
  dmyFormatter,
  LineProps,
  longDayMonthYearFormatter,
  MultiDataset,
  percentageFormatter,
  ResponsiveMultiLine,
  ResponsiveStackedArea,
  StackedAreaProps,
  SVGDatumType,
} from '@percept/mui/charts'

import { DimensionalSummaryItem } from './DimensionalSummaryItem'

import { PerformanceTable } from '../Tables'

import { useLayoutGridSpacing } from 'hooks'

import { getDefaultPerformanceReportingDomain } from 'components/CrossChannelDashboard'

import { get } from 'lodash-es'

import { isMonetaryDimension, isPercentageDimension, isRateBasedDimension } from '@percept/utils'

import { dimensionMap } from '@percept/constants'

import { DimensionalSummaryClasses, DimensionalSummaryProps } from './typings'

import { PerformanceReportingDatum, TimelineDatum } from 'types'

import { AnyPerformanceDimension } from '@percept/types'


const useStyles = makeAppStyles<{}, DimensionalSummaryClasses>( theme => ({
  card: {
    flexGrow: 1,
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  cardContentTable: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'stretch',
    height: '100%',
    paddingTop: 0,
    paddingBottom: '4px !important',
  },
  tableContainer: {
    maxHeight: 306,
    overflowY: 'auto',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridItemTable: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: '1.35em',
  },
  chart: {
    margin: theme.spacing(1, 1, 0, 1),
    width: '100%',
  },
}) )


const useTableStyles = makeAppStyles( theme => ({
  tableCell: {
    padding: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
  }
}))


const CHART_FILL_OPACITY = 0.75

const stackedAreaProps: Omit<StackedAreaProps<TimelineDatum>, 'datasets' | 'width'> = {
  xScaleType: 'time',
  arbitraryTimeline: true,
  grid: 'rows',
  axisLine: true,
  axisText: true,
  // height: 200,
  height: 260,
  numYTicks: 5,
  svgPathProps: {
    fillOpacity: CHART_FILL_OPACITY,
  },
  xTickFormatter: longDayMonthYearFormatter,
  tooltipLabelFormatter: dmyFormatter,
  // eslint-disable-next-line react/display-name
  tooltipLabelDatumFormatter: (d) => {
    return (
      <ReportRange
        start={d.start}
        end={d.end} />
    )
  }
}

const rateBasedLineProps: Partial<LineProps> = {
  yTickFormatter: percentageFormatter,
  domain: [0, 100],
}


export function DimensionalSummary<
  T extends PerformanceReportingDatum & {
    dimension: AnyPerformanceDimension
    currency?: string | null
  }
>({
  dimension,
  referenceDate,
  axisChartMinDate,
  currency,
  labelHistory,
  labelChange,
  color,
  datasets,
  PerformanceTableProps = {},
}: DimensionalSummaryProps<T>): JSX.Element {

  const [defaultXDomain] = useState(getDefaultPerformanceReportingDomain)

  const label = dimensionMap[dimension].text

  const isRateBased = isRateBasedDimension(dimension)

  const axisChartMinDateRef = axisChartMinDate && axisChartMinDate.getTime()

  const axisChartData: MultiDataset<TimelineDatum>[] = useMemo(() => {
    return datasets.map( ({ key, label, dataset, color }) => {
      const timeseries = get(dataset, 'timeseries', [])
      // Coerce rate-based metrics to percentages
      let data: ChartData<TimelineDatum> = (
        isRateBased ?
          timeseries.map( d => ({
            ...d,
            value: d.value && (Number(d.value) * 100),
          })) :
          timeseries
      )
      if( axisChartMinDateRef ){
        data = data.filter( d => d.start >= axisChartMinDateRef )
      }
      return {
        key,
        label,
        color,
        data,
        fillOpacity: isRateBased ? 0 : CHART_FILL_OPACITY,
      }
    })
  }, [datasets, isRateBased, axisChartMinDateRef])

  const PrimaryChartComponent = (
    isRateBased ?
      ResponsiveMultiLine :
      ResponsiveStackedArea
  )

  const tableRows: T[] = useMemo(() => {
    return datasets.map( d => ({ ...d.dataset.relative, dimension, currency })) as T[]
  }, [datasets, dimension, currency])

  const summaryClasses = useStyles()

  const tableClasses = useTableStyles()

  const gridSpacing = useLayoutGridSpacing()

  labelChange = labelChange || `${label} Comparison`
  labelHistory = labelHistory || `${label} History`

  const dimensionalChartProps = {
    currency: isMonetaryDimension(dimension) ? currency : null,
    yTickFormatter: isPercentageDimension(dimension) ? detailedPercentageFormatter : undefined,
  }

  return (
    <Grid container spacing={gridSpacing}>

      <DimensionalSummaryItem
        classes={summaryClasses}
        header={labelHistory}
        color={color}
        xs={12}
        md={6}
        lg={7}
        xl={8}>

        <div className={summaryClasses.chart}>
          <PrimaryChartComponent
            defaultXDomain={defaultXDomain}
            datasets={axisChartData as MultiDataset<SVGDatumType>[]}
            {...stackedAreaProps as Omit<StackedAreaProps<SVGDatumType>, 'datasets'>}
            {...dimensionalChartProps}
            {...(isRateBased && rateBasedLineProps || {})} />
        </div>

      </DimensionalSummaryItem>

      <DimensionalSummaryItem
        classes={{
          ...summaryClasses,
          cardContent: summaryClasses.cardContentTable,
          gridItem: summaryClasses.gridItemTable,
        }}
        header={labelChange}
        color={color}
        xs={12}
        md={6}
        lg={5}
        xl={4}>
        
        <div className={summaryClasses.tableContainer}>
          <PerformanceTable
            rows={tableRows}
            referenceDate={referenceDate}
            showNowColumn={false}
            classes={tableClasses}
            {...PerformanceTableProps} />
        </div>

      </DimensionalSummaryItem>
    </Grid>
  )
}
