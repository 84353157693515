import React from 'react'

import { ScaleRequirements, SVGDatumType } from '../typings'

import { sum } from 'lodash-es'

import { TooltipData } from './Tooltip'


export type TooltipIndicatorProps<T extends SVGDatumType> = (
  {
    data: TooltipData<T>
    x: number
    y: number
    height: number | string
    stroke: string
    width?: number
    radius?: number
    stacked?: boolean
  } &
  ScaleRequirements
)


const Dot = ({ cx, cy, fill, r, ...props }: React.SVGAttributes<SVGCircleElement>): JSX.Element => (
  <g>
    <circle
      stroke='none'
      r={r}
      fill={fill}
      cx={cx}
      cy={cy}
      {...props}
      pointerEvents='none' />

    <circle
      cx={cx}
      cy={cy}
      r={Number(r) - 1}
      strokeWidth={2}
      fill='none'
      stroke='black'
      strokeOpacity={0.25}
      pointerEvents='none' />
  </g>
)


export function TooltipIndicator<T extends SVGDatumType>({
  data,
  yScale,
  x,
  height,
  width = 2,
  radius = 6,
  stroke,
  stacked,
}: TooltipIndicatorProps<T>): JSX.Element {

  const dataArray = data.data || data.datum && [data.datum] || []

  return (
    <g>

      <line
        strokeDasharray='5,2'
        x1={x}
        x2={x}
        y1={0}
        y2={height}
        stroke={stroke}
        strokeWidth={width}
        pointerEvents='none' />

      { dataArray.map( (d, i) => {

        if( d.value !== null ){
          const value = Number(d.value)

          const cumulativeValue = stacked ? (
            sum(dataArray.slice(0, i).map( dx => Number(dx.value || 0) ))
          ) + value : value

          return (
            <Dot
              key={`${d.label}-${i}`}
              r={radius}
              cx={x}
              cy={Number(yScale(cumulativeValue))}
              fill={d.color} />
          )
        }

        return null
      })}

    </g>
  )
}
