import React from 'react'

import { Box, DialogProps, IconButton, PerceptAppBar } from '@percept/mui'

import { DefaultBackgroundDialog } from './DefaultBackgroundDialog'

import { Close } from '@percept/mui/icons'

import { InsightsReportOutputUnitType, MetricMetadataType } from '@percept/types'

import { InsightsReportOutputUnitDetail } from './InsightsReportComponents'

import { InsightsReportTreePopover, InsightsReportTreePopoverProps } from './InsightsReportTree'

import { ItemRenderer } from './lib'


export type DetailDialogProps = {
  title: string | null | undefined
  outputUnit: InsightsReportOutputUnitType & { id: string }
  metricMetadata: MetricMetadataType
  DialogProps: Partial<Omit<DialogProps, 'onClose'>> & {
    onClose: () => void
  }
} & Omit<InsightsReportTreePopoverProps, 'activeOutputUnit'>

export const DetailDialog: ItemRenderer<DetailDialogProps> = ({
  insightsReportTree,
  outputUnit,
  setActiveOutputUnit,
  DialogProps,
  showSectionHealth,
  ...props
}): JSX.Element => {
  return (
    <DefaultBackgroundDialog
      open
      fullScreen
      maxWidth={false}
      {...DialogProps}>

      <PerceptAppBar
        color='secondary'
        startIcon={
          <IconButton
            color='inherit'
            onClick={DialogProps.onClose}>
            <Close />
          </IconButton>
        }>
        <Box ml={2} mr={1}>
          <InsightsReportTreePopover
            insightsReportTree={insightsReportTree}
            showSectionHealth={showSectionHealth}
            activeOutputUnit={outputUnit}
            setActiveOutputUnit={setActiveOutputUnit} />
        </Box>
      </PerceptAppBar>

      <Box pt={12.5} px={3} pb={3}>
        <InsightsReportOutputUnitDetail
          parent={outputUnit}
          setActiveOutputUnit={setActiveOutputUnit}
          {...props}
          {...outputUnit} />
      </Box>
    </DefaultBackgroundDialog>
  )
}
