
import React from 'react'

import { ParentSize } from '@visx/responsive'

import { BaseChartProps, SVGDatumType } from './typings'

import { ParentSizeProps } from '@visx/responsive/lib/components/ParentSize'


export type ResponsiveChartProps<
  P extends BaseChartProps<T>,
  T extends SVGDatumType
> = (
  Omit<
    P, 'width' | 'height'
  > & Partial<
    Pick<
      P, 'width' | 'height'
    >
  > & Partial<
    ParentSizeProps
  >
)

export function makeResponsiveChart<
  P extends BaseChartProps<T>,
  T extends SVGDatumType
>(
  Component: React.FC<P>
): (props: ResponsiveChartProps<P, T>) => JSX.Element {

  const ResponsiveComponent = ({
    className,
    debounceTime = 50,
    enableDebounceLeadingCall = true,
    ignoreDimensions,
    parentSizeStyles,
    ...props
  }: ResponsiveChartProps<P, T>): JSX.Element => {
    return (
      <ParentSize
        className={className}
        debounceTime={debounceTime}
        enableDebounceLeadingCall={enableDebounceLeadingCall}
        ignoreDimensions={ignoreDimensions}
        parentSizeStyles={parentSizeStyles}>
        { ({ width, height }): JSX.Element => (
          <Component
            {...props as P}
            width={Math.floor(props.width || width)}
            height={Math.floor(props.height || height)} />
        )}
      </ParentSize>
    )
  }

  ResponsiveComponent.displayName = `Responsive${Component.displayName}`

  return ResponsiveComponent
}
