import {
  ApiResponse,
  ApiStatusResponse,
  ChannelPillarScores,
  CurrencyParams,
  EntityParams,
  Insight,
  InsightsReportLoadParams,
  InsightsReportType,
  InsightsReportTypeParams,
  LayoutType,
  LegacyTask,
  MetricMetadataEntryType,
  MetricMetadataType,
  NestedPlatformUnitMetricPillarChannelScores,
  NestedPlatformUnitMetricPillarScores,
  PlatformUnitParams,
  PotentialEfficiencyDatum,
  PotentialEfficiencyInsightConfiguration,
  ProviderPillarScores,
  ProviderPotentialEfficiencyScores,
  ReportEntityPayload,
  ReportLayoutParams,
  ReportMetric,
  ReportMetricParams,
  ReportParams,
  ReportPayload,
  ReportProviderParams,
  ReportResultParams,
  ReportSeries,
  ReportSeriesDetail,
  ReportSeriesPillarScores,
  RerunReportSeriesResponse,
  SeriesParams,
  SeriesReportMetric,
  SeriesReportMetricParams,
  StateOnlyResponse,
  StructuralReport,
  StructuralReportLayout,
  WorkflowExecution,
  WorkflowExecutionCreation,
  WorkflowExecutionHistory,
  WorkflowExecutionParams,
} from '@percept/types'

import { get, identity, isEqual } from 'lodash-es'

import { apiResponseOf } from '@percept/utils'

import { Selector } from 'react-redux'

import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'

import { apiInitialState, apiInitialStateWithProcessing } from '../../constants'

import { paginationResponseResolver } from '../../selectors'

import { PaginatedReducerState } from '../../reducers'

import {
  getEntityKey,
  getMetricKey,
  getTimeSeriesKey,
  getInsightsKey,
  getInsightsReportKey,
  getPlatformUnitKey,
  getSeriesKey,
  getReportKey,
  getReportLayoutKey,
  getWorkflowExecutionKey,
  getReportResultKey,
  getPlatformUnitAndCurrencyKey,
  getProviderKey,
} from '../../utils'

import { StructuralReportWrapper, ReportSeriesWrapper, StructuralReportingState } from './typings'


type StateWithStructuralReporting = {
  structuralReporting: StructuralReportingState
}


type StructuralReportingSelector<TProps, TOwnProps = null> = Selector<
  StateWithStructuralReporting, TProps, TOwnProps
>


const createShallowEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual
)


/* Metadata */

export const getMetricMetadataByProvider: StructuralReportingSelector<
  ApiResponse<MetricMetadataType>,
  ReportProviderParams
> = createShallowEqualSelector<
  StateWithStructuralReporting, ReportProviderParams, any, ApiResponse<MetricMetadataType>
>(
  (state, { provider }) => {
    const response = state.structuralReporting.metricMetadata.byProvider[provider]

    if( response && response.error ) return {
      ...response,
      data: null,
    }

    if( !response || !response.data ) return apiInitialState

    return apiResponseOf(
      response.data.reduce((acc, d) => {
        acc[d.metric_id] = d
        return acc
      }, {} as MetricMetadataType)
    )
  },
  identity
)

export const getMetricMetadataPaginationState: StructuralReportingSelector<
  PaginatedReducerState<MetricMetadataEntryType[]>
> = state => (
  state.structuralReporting.metricMetadata.list
)

export const getPaginatedMetricMetadata = createSelector(
  getMetricMetadataPaginationState,
  paginationResponseResolver,
)

export const getAddMetricMetadata: StructuralReportingSelector<
  ApiStatusResponse<MetricMetadataEntryType>
> = state => (
  state.structuralReporting.metricMetadata.add
)

export const getEditMetricMetadata: StructuralReportingSelector<
  ApiStatusResponse<MetricMetadataEntryType>
> = state => (
  state.structuralReporting.metricMetadata.edit
)


/* Report Layouts */


export const getReportLayoutsPaginationState: StructuralReportingSelector<
  PaginatedReducerState<StructuralReportLayout[]>
> = (state) => (
  state.structuralReporting.reportLayouts.list
)

export const getPaginatedReportLayouts = createSelector(
  getReportLayoutsPaginationState,
  paginationResponseResolver,
)

export const getReportLayout: StructuralReportingSelector<
  ApiResponse<StructuralReportLayout>,
  ReportLayoutParams
> = (state, params) => (
  get(
    state.structuralReporting.reportLayouts.byId,
    getReportLayoutKey(params) || '',
    apiInitialState
  )
)

export const getAddReportLayout: StructuralReportingSelector<
  ApiStatusResponse<StructuralReportLayout>
> = state => (
  state.structuralReporting.reportLayouts.add
)

export const getEditReportLayout: StructuralReportingSelector<
  ApiStatusResponse<StructuralReportLayout>
> = state => (
  state.structuralReporting.reportLayouts.edit
)

export const getReportLayoutPayload: StructuralReportingSelector<
  ApiResponse<LayoutType>,
  ReportLayoutParams
> = createShallowEqualSelector<
  StateWithStructuralReporting, ReportLayoutParams, any, ApiResponse<LayoutType>
>(
  (state, params) => {
    const reportLayout = getReportLayout(state, params)
    if( reportLayout.data ){
      return {
        ...reportLayout,
        data: reportLayout.data.payload
      }
    }
    return reportLayout
  },
  identity
)


/* Report Series */


const defaulReportSeriesWrapper: ReportSeriesWrapper = {
  summary: apiInitialState,
  detail: apiInitialState,
  createReportTask: apiInitialStateWithProcessing,
  reports: apiInitialState,
  scores: apiInitialState,
  potentialEfficiencies: apiInitialState,
  metricTimeseries: {},
}


const getReportSeriesWrapper: StructuralReportingSelector<
  ReportSeriesWrapper,
  SeriesParams
> = (state, params) => (
  get(
    state.structuralReporting.reportSeries,
    getSeriesKey(params) || '',
    defaulReportSeriesWrapper
  )
)


export const getReportSeries: StructuralReportingSelector<
  ApiResponse<ReportSeries>,
  SeriesParams
> = (state, params) => (
  getReportSeriesWrapper(state, params).summary
)


export const getReportSeriesDetail: StructuralReportingSelector<
  ApiResponse<ReportSeriesDetail>,
  SeriesParams
> = (state, params) => (
  getReportSeriesWrapper(state, params).detail
)


export const getEditReportSeries: StructuralReportingSelector<
  ApiStatusResponse<ReportSeries>,
  SeriesParams
> = (state) => (
  state.structuralReporting.editReportSeries
)


export const getReportSeriesReports: StructuralReportingSelector<
  ApiResponse<StructuralReport[]>,
  SeriesParams
> = (state, params) => (
  getReportSeriesWrapper(state, params).reports
)


export const getReportSeriesPillarScores: StructuralReportingSelector<
  ApiResponse<ReportSeriesPillarScores>,
  SeriesParams
> = (state, params) => (
  getReportSeriesWrapper(state, params).scores
)


export const getReportSeriesPotentialEfficiencies: StructuralReportingSelector<
  ApiResponse<PotentialEfficiencyDatum[]>,
  SeriesParams
> = (state, params) => (
  getReportSeriesWrapper(state, params).potentialEfficiencies
)


export const getCreateReportTask: StructuralReportingSelector<
  ApiStatusResponse<WorkflowExecutionCreation>,
  SeriesParams
> = (state, params) => (
  getReportSeriesWrapper(state, params).createReportTask
)


export const getReportSeriesMetricTimeseries: StructuralReportingSelector<
  ApiResponse<SeriesReportMetric[]>,
  SeriesReportMetricParams
> = (state, params) => (
  get(
    getReportSeriesWrapper(state, params).metricTimeseries,
    getTimeSeriesKey(params) || '',
    apiInitialState
  )
)


/* Structural Reports */

const defaultReportWrapper: StructuralReportWrapper = {
  report: apiInitialState,
  structure: apiInitialState,
  payloadsByResultId: {},
  entities: {},
  metrics: {},
  insightsReports: {},
  workflows: apiInitialState,
  legacyTasks: apiInitialState,
}


const getStructuralReportWrapper: StructuralReportingSelector<
  StructuralReportWrapper,
  ReportParams
> = (state, params) => (
  get(
    state.structuralReporting.structuralReports,
    getReportKey(params) || '',
    defaultReportWrapper
  )
)


export const getReportSeriesReport: StructuralReportingSelector<
  ApiResponse<StructuralReport>,
  ReportParams
> = (state, params) => (
  getStructuralReportWrapper(state, params).report
)

export const getReportSeriesReportWorkflows: StructuralReportingSelector<
  ApiResponse<WorkflowExecution[]>,
  ReportParams
> = (state, params) => (
  getStructuralReportWrapper(state, params).workflows
)

export const getReportSeriesReportLegacyTasks: StructuralReportingSelector<
  ApiResponse<LegacyTask[]>,
  ReportParams
> = (state, params) => (
  getStructuralReportWrapper(state, params).legacyTasks
)

export const getReportSeriesReportStructure: StructuralReportingSelector<
  ApiResponse<StructuralReport>,
  ReportParams
> = (state, params) => (
  getStructuralReportWrapper(state, params).structure
)

export const getReportSeriesReportPayload: StructuralReportingSelector<
  ApiResponse<ReportPayload>,
  ReportParams & Partial<ReportResultParams>
> = (state, params) => (
  get(
    getStructuralReportWrapper(state, params).payloadsByResultId,
    getReportResultKey(params) || '',
    apiInitialState
  )
)

export const getReportSeriesReportEntity: StructuralReportingSelector<
  ApiResponse<ReportEntityPayload>,
  ReportParams & EntityParams & Partial<ReportResultParams>
> = (state, params) => (
  get(
    getStructuralReportWrapper(state, params).entities,
    getEntityKey(params) || '',
    apiInitialState
  )
)

export const getReportSeriesReportMetric: StructuralReportingSelector<
  ApiResponse<ReportMetric>,
  ReportMetricParams & Partial<ReportResultParams>
> = (state, params) => (
  get(
    getStructuralReportWrapper(state, params).metrics,
    getMetricKey(params) || '',
    apiInitialState
  )
)


export const getReportSeriesInsights: StructuralReportingSelector<
  ApiResponse<Insight[]>,
  SeriesParams
> = (state, params) => (
  get(
    state.structuralReporting.insights,
    getInsightsKey(params) || '',
    apiInitialState
  )
)

export const getReportSeriesInsightsReport: StructuralReportingSelector<
  ApiResponse<InsightsReportType>,
  SeriesParams & InsightsReportTypeParams
> = (state, params) => (
  get(
    state.structuralReporting.insightsReports,
    getInsightsReportKey(params) || '',
    apiInitialState
  )
)

export const getReportSeriesReportInsightsReport: StructuralReportingSelector<
  ApiResponse<InsightsReportType>,
  InsightsReportLoadParams
> = (state, params) => (
  get(
    getStructuralReportWrapper(state, params).insightsReports,
    getInsightsReportKey(params) || '',
    apiInitialState
  )
)

export const getPlatformUnitProviderPillarScores: StructuralReportingSelector<
  ApiResponse<ProviderPillarScores>,
  PlatformUnitParams
> = (state, params) => (
  get(
    state.structuralReporting.platformUnitPillarScores.byProvider,
    getPlatformUnitKey(params) || '',
    apiInitialState
  )
)

export const getPlatformUnitChannelPillarScores: StructuralReportingSelector<
  ApiResponse<ChannelPillarScores>,
  PlatformUnitParams
> = (state, params) => (
  get(
    state.structuralReporting.platformUnitPillarScores.byChannel,
    getPlatformUnitKey(params) || '',
    apiInitialState
  )
)

export const getNestedPlatformUnitProviderPillarScores: StructuralReportingSelector<
  ApiResponse<NestedPlatformUnitMetricPillarScores>,
  PlatformUnitParams
> = (state, params) => (
  get(
    state.structuralReporting.platformUnitPillarScores.childUnitsByProvider,
    getPlatformUnitKey(params) || '',
    apiInitialState
  )
)

export const getNestedPlatformUnitChannelPillarScores: StructuralReportingSelector<
  ApiResponse<NestedPlatformUnitMetricPillarChannelScores>,
  PlatformUnitParams
> = (state, params) => (
  get(
    state.structuralReporting.platformUnitPillarScores.childUnitsByChannel,
    getPlatformUnitKey(params) || '',
    apiInitialState
  )
)

export const getPlatformUnitPotentialEfficiencies: StructuralReportingSelector<
  ApiResponse<ProviderPotentialEfficiencyScores[]>,
  PlatformUnitParams & CurrencyParams
> = (state, params) => (
  get(
    state.structuralReporting.platformUnitPotentialEffiencies,
    getPlatformUnitAndCurrencyKey(params) || '',
    apiInitialState
  )
)

export const getPotentialEfficiencyConfiguration: StructuralReportingSelector<
  ApiResponse<PotentialEfficiencyInsightConfiguration[]>,
  ReportProviderParams
> = (state, params) => (
  get(
    state.structuralReporting.potentialEfficiencyConfigurations,
    getProviderKey(params) || '',
    apiInitialState
  )
)


/* Workflows */

export const getAddReportSeriesReportWorkflow: StructuralReportingSelector<
  ApiStatusResponse<WorkflowExecutionCreation>
> = state => (
  state.structuralReporting.addWorkflow
)

export const getAddReportSeriesReportWorkflowFromLegacyTask: StructuralReportingSelector<
  ApiStatusResponse<WorkflowExecutionCreation>
> = state => (
  state.structuralReporting.addWorkflowFromLegacyTask
)

export const getRerunReportWorkflow: StructuralReportingSelector<
  ApiStatusResponse<WorkflowExecutionCreation>
> = state => (
  state.structuralReporting.workflowRerun
)

export const getRerunReportSeries: StructuralReportingSelector<
  ApiStatusResponse<RerunReportSeriesResponse>
> = state => (
  state.structuralReporting.reportSeriesRerun
)

export const getRerunReportWorkflowCompletionHandlers: StructuralReportingSelector<
  ApiStatusResponse<StateOnlyResponse>
> = state => (
  state.structuralReporting.workflowCompletionHandlerRerun
)

export const getSetActiveReportWorkflowResults: StructuralReportingSelector<
  ApiStatusResponse<WorkflowExecution>
> = state => (
  state.structuralReporting.setActiveWorkflowResults
)

export const getWorkflowExecutionHistory: StructuralReportingSelector<
  ApiResponse<WorkflowExecutionHistory>,
  WorkflowExecutionParams
> = (state, params) => (
  get(
    state.structuralReporting.workflowExecutionHistoryById,
    getWorkflowExecutionKey(params) || '',
    apiInitialState
  )
)
