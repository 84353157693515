import {
  SVGDatumType,
  getMoneyFormatter,
  percentageFormatter,
} from '@percept/mui/charts'

import { TickFormatter, TickLabelProps } from '@visx/axis'

import { ScaleBand, ScaleLinear } from 'd3'


export type LegendConfiguration = {
  label: string
  x: number
  width: number
  color: string
}


export const getScaleBandMidPointAccessor = <T extends SVGDatumType>(
  scale: ScaleBand<string>
) => (d: T): number => (scale(d.label as string) || 0) + (scale.bandwidth() / 2)

export const getLinearScaleRatePercentageAccessor = <T extends SVGDatumType>(
  scale: ScaleLinear<number, number>
) => (d: T): number => scale(d.value || 0)


const tickFontSize = 13
const tickFontWeight = 500

export const getXTickLabelProps: TickLabelProps<string> = () => ({
  dominantBaseline: 'central',
  textAnchor: 'middle',
  fontWeight: tickFontWeight,
  fontSize: tickFontSize,
})

export const getPrimaryYTickLabelProps: TickLabelProps<{ valueOf: () => number }> = () => ({
  dx: -6,
  dominantBaseline: 'central',
  textAnchor: 'end',
  fontWeight: tickFontWeight,
  fontSize: tickFontSize,
})

export const getSecondaryYTickLabelProps: TickLabelProps<{ valueOf: () => number }> = () => ({
  dx: 6,
  dominantBaseline: 'central',
  textAnchor: 'start',
  fontWeight: tickFontWeight,
  fontSize: tickFontSize,
})

export const spendTickFormatter = (
  getMoneyFormatter('EUR') as unknown as TickFormatter<{ valueOf: () => number }>
)

export const percentageTickFormatter = (
  percentageFormatter as unknown as TickFormatter<{ valueOf: () => number }>
)
