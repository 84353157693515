import { combineReducers, Reducer } from 'redux'

import { createApiReducer, createKeyedReducer, createPaginatedReducer } from '../../reducers'

import {
  LOAD_METRIC_METADATA_BY_PROVIDER,
  LOAD_REPORT_SERIES,
  LOAD_REPORT_SERIES_DETAIL,
  LOAD_REPORT_SERIES_INSIGHTS,
  LOAD_REPORT_SERIES_INSIGHTS_REPORT,
  LOAD_REPORT_SERIES_METRIC_TIMESERIES,
  LOAD_REPORT_SERIES_PILLAR_SCORES,
  LOAD_REPORT_SERIES_REPORT,
  LOAD_REPORT_SERIES_REPORT_ENTITY,
  LOAD_REPORT_SERIES_REPORT_METRIC,
  LOAD_REPORT_SERIES_REPORT_PAYLOAD,
  LOAD_REPORT_SERIES_REPORT_STRUCTURE,
  LOAD_REPORT_SERIES_REPORTS,
  LOAD_ALL_METRIC_METADATA,
  LOAD_REPORT_LAYOUTS,
  LOAD_REPORT_LAYOUT,
  EDIT_REPORT_LAYOUT,
  RESET_EDIT_REPORT_LAYOUT,
  EDIT_METRIC_METADATA,
  RESET_EDIT_METRIC_METADATA,
  ADD_METRIC_METADATA,
  RESET_ADD_METRIC_METADATA,
  ADD_REPORT_LAYOUT,
  RESET_ADD_REPORT_LAYOUT,
  CREATE_REPORT_TASK,
  RESET_CREATE_REPORT_TASK,
  EDIT_REPORT_SERIES,
  RESET_EDIT_REPORT_SERIES,
  LOAD_REPORT_SERIES_REPORT_WORKFLOWS,
  RERUN_REPORT_WORKFLOW,
  RESET_RERUN_REPORT_WORKFLOW,
  LOAD_WORKFLOW_EXECUTION_HISTORY,
  ADD_REPORT_SERIES_REPORT_WORKFLOW,
  RESET_ADD_REPORT_SERIES_REPORT_WORKFLOW,
  SET_ACTIVE_REPORT_WORKFLOW_RESULTS,
  RESET_SET_ACTIVE_REPORT_WORKFLOW_RESULTS,
  LOAD_REPORT_SERIES_REPORT_INSIGHTS_REPORT,
  LOAD_REPORT_SERIES_POTENTIAL_EFFICIENCIES,
  RERUN_REPORT_WORKFLOW_COMPLETION_HANDLERS,
  RESET_RERUN_REPORT_WORKFLOW_COMPLETION_HANDLERS,
  LOAD_REPORT_SERIES_REPORT_LEGACY_TASKS,
  ADD_REPORT_SERIES_REPORT_WORKFLOW_FROM_LEGACY_TASK,
  RESET_ADD_REPORT_SERIES_REPORT_WORKFLOW_FROM_LEGACY_TASK,
  RERUN_REPORT_SERIES,
  RESET_RERUN_REPORT_SERIES,
  LOAD_PLATFORM_UNIT_POTENTIAL_EFFICIENCIES,
  LOAD_POTENTIAL_EFFICIENCY_CONFIGURATION,
  LOAD_PLATFORM_UNIT_CHANNEL_PILLAR_SCORES,
  LOAD_PLATFORM_UNIT_CHILDREN_CHANNEL_PILLAR_SCORES,
  LOAD_PLATFORM_UNIT_PROVIDER_PILLAR_SCORES,
  LOAD_PLATFORM_UNIT_CHILDREN_PROVIDER_PILLAR_SCORES,
} from './actions'

import {
  nestedPlatformUnitPillarScores,
  nestedPlatformUnitChannelPillarScores,
  platformUnitPillarScores,
  platformUnitChannelPillarScores,
  metricSeries,
  reportPayload,
  reportSeriesPillarScores,
  insightsReport,
  potentialEfficiencies,
} from '@percept/api/adapters'

import {
  getEntityKeyFromAction,
  getInsightsKeyFromAction,
  getInsightsReportKeyFromAction,
  getMetricKeyFromAction,
  getPlatformUnitKeyFromAction,
  getProviderKeyFromAction,
  getSeriesKeyFromAction,
  getReportKeyFromAction,
  getReportLayoutKeyFromAction,
  getTimeSeriesKeyFromAction,
  makePayloadAdapter,
  getWorkflowExecutionKeyFromAction,
  getReportResultKeyFromAction,
  getPlatformUnitAndCurrencyKeyFromAction,
} from '../../utils'

import {
  ChannelSlug,
  MetricPillarChannelScoreResponse,
  MetricPillarScoreResponse,
  PlatformUnitMetricPillarChannelScores,
  PlatformUnitMetricPillarScores,
  ReduxAction,
  ReportProvider,
} from '@percept/types'

import { StructuralReportingState } from './typings'
import { coerceReportProvider } from '@percept/utils'


const reducer: Reducer<StructuralReportingState, ReduxAction> = combineReducers({
  reportSeries: createKeyedReducer(
    combineReducers({
      summary: createApiReducer(LOAD_REPORT_SERIES),
      detail: createApiReducer(LOAD_REPORT_SERIES_DETAIL),
      reports: createApiReducer(LOAD_REPORT_SERIES_REPORTS),
      scores: createApiReducer(LOAD_REPORT_SERIES_PILLAR_SCORES, {
        getPayloadFromAction: makePayloadAdapter(reportSeriesPillarScores)
      }),
      potentialEfficiencies: createApiReducer(LOAD_REPORT_SERIES_POTENTIAL_EFFICIENCIES, {
        getPayloadFromAction: makePayloadAdapter(potentialEfficiencies)
      }),
      createReportTask: createApiReducer(
        CREATE_REPORT_TASK, {
          resetActionTypes: [RESET_CREATE_REPORT_TASK],
        }
      ),
      metricTimeseries: createKeyedReducer(
        createApiReducer(LOAD_REPORT_SERIES_METRIC_TIMESERIES, {
          getPayloadFromAction: makePayloadAdapter(metricSeries),
        }),
        getTimeSeriesKeyFromAction,
      ),
    }),
    getSeriesKeyFromAction,
  ),
  editReportSeries: createApiReducer(EDIT_REPORT_SERIES, {
    resetActionTypes: [RESET_EDIT_REPORT_SERIES]
  }),
  structuralReports: createKeyedReducer(
    combineReducers({
      report: createApiReducer(LOAD_REPORT_SERIES_REPORT),
      structure: createApiReducer(LOAD_REPORT_SERIES_REPORT_STRUCTURE),
      payloadsByResultId: createKeyedReducer(
        createApiReducer(LOAD_REPORT_SERIES_REPORT_PAYLOAD, {
          getPayloadFromAction: makePayloadAdapter(reportPayload)
        }),
        getReportResultKeyFromAction,
      ),
      entities: createKeyedReducer(
        createApiReducer(LOAD_REPORT_SERIES_REPORT_ENTITY),
        getEntityKeyFromAction,
      ),
      metrics: createKeyedReducer(
        createApiReducer(LOAD_REPORT_SERIES_REPORT_METRIC),
        getMetricKeyFromAction,
      ),
      insightsReports: createKeyedReducer(
        createApiReducer(LOAD_REPORT_SERIES_REPORT_INSIGHTS_REPORT, {
          getPayloadFromAction: makePayloadAdapter(insightsReport),
        }),
        getInsightsReportKeyFromAction,
      ),
      workflows: createApiReducer(LOAD_REPORT_SERIES_REPORT_WORKFLOWS),
      legacyTasks: createApiReducer(LOAD_REPORT_SERIES_REPORT_LEGACY_TASKS),
    }),
    getReportKeyFromAction,
  ),
  addWorkflow: createApiReducer(ADD_REPORT_SERIES_REPORT_WORKFLOW, {
    resetActionTypes: [RESET_ADD_REPORT_SERIES_REPORT_WORKFLOW],
  }),
  addWorkflowFromLegacyTask: createApiReducer(ADD_REPORT_SERIES_REPORT_WORKFLOW_FROM_LEGACY_TASK, {
    resetActionTypes: [RESET_ADD_REPORT_SERIES_REPORT_WORKFLOW_FROM_LEGACY_TASK],
  }),
  workflowRerun: createApiReducer(RERUN_REPORT_WORKFLOW, {
    resetActionTypes: [RESET_RERUN_REPORT_WORKFLOW],
  }),
  reportSeriesRerun: createApiReducer(RERUN_REPORT_SERIES, {
    resetActionTypes: [RESET_RERUN_REPORT_SERIES],
  }),
  workflowCompletionHandlerRerun: createApiReducer(RERUN_REPORT_WORKFLOW_COMPLETION_HANDLERS, {
    resetActionTypes: [RESET_RERUN_REPORT_WORKFLOW_COMPLETION_HANDLERS],
  }),
  setActiveWorkflowResults: createApiReducer(SET_ACTIVE_REPORT_WORKFLOW_RESULTS, {
    resetActionTypes: [RESET_SET_ACTIVE_REPORT_WORKFLOW_RESULTS],
  }),
  workflowExecutionHistoryById: createKeyedReducer(
    createApiReducer(LOAD_WORKFLOW_EXECUTION_HISTORY),
    getWorkflowExecutionKeyFromAction,
  ),
  metricMetadata: combineReducers({
    list: createPaginatedReducer(LOAD_ALL_METRIC_METADATA),
    byProvider: createKeyedReducer(
      createApiReducer(LOAD_METRIC_METADATA_BY_PROVIDER),
      getProviderKeyFromAction,
    ),
    add: createApiReducer(ADD_METRIC_METADATA, {
      resetActionTypes: [RESET_ADD_METRIC_METADATA],
    }),
    edit: createApiReducer(EDIT_METRIC_METADATA, {
      resetActionTypes: [RESET_EDIT_METRIC_METADATA],
    }),
  }),
  reportLayouts: combineReducers({
    list: createPaginatedReducer(LOAD_REPORT_LAYOUTS),
    byId: createKeyedReducer(
      createApiReducer(LOAD_REPORT_LAYOUT),
      getReportLayoutKeyFromAction,
    ),
    add: createApiReducer(ADD_REPORT_LAYOUT, {
      resetActionTypes: [RESET_ADD_REPORT_LAYOUT],
    }),
    edit: createApiReducer(EDIT_REPORT_LAYOUT, {
      resetActionTypes: [RESET_EDIT_REPORT_LAYOUT],
    }),
  }),
  insights: createKeyedReducer(
    createApiReducer(LOAD_REPORT_SERIES_INSIGHTS),
    getInsightsKeyFromAction,
  ),
  insightsReports: createKeyedReducer(
    createApiReducer(LOAD_REPORT_SERIES_INSIGHTS_REPORT, {
      getPayloadFromAction: makePayloadAdapter(insightsReport),
    }),
    getInsightsReportKeyFromAction,
  ),
  platformUnitPillarScores: combineReducers({
    byProvider: createKeyedReducer(
      createApiReducer(LOAD_PLATFORM_UNIT_PROVIDER_PILLAR_SCORES, {
        getPayloadFromAction: ({ payload }) => {
          const providerScores = (payload as MetricPillarScoreResponse[]).map(platformUnitPillarScores)
          return providerScores.reduce( (acc, providerScore) => {
            acc[providerScore.provider] = providerScore
            return acc
          }, {} as Record<ReportProvider, PlatformUnitMetricPillarScores>)
        }
      }),
      getPlatformUnitKeyFromAction
    ),
    byChannel: createKeyedReducer(
      createApiReducer(LOAD_PLATFORM_UNIT_CHANNEL_PILLAR_SCORES, {
        getPayloadFromAction: ({ payload }) => {
          const providerScores = (payload as MetricPillarChannelScoreResponse[]).map(platformUnitChannelPillarScores)
          return providerScores.reduce( (acc, channelScore) => {
            acc[channelScore.channel] = channelScore
            return acc
          }, {} as Record<ChannelSlug, PlatformUnitMetricPillarChannelScores>)
        }
      }),
      getPlatformUnitKeyFromAction
    ),
    childUnitsByProvider: createKeyedReducer(
      createApiReducer(LOAD_PLATFORM_UNIT_CHILDREN_PROVIDER_PILLAR_SCORES, {
        getPayloadFromAction: makePayloadAdapter(nestedPlatformUnitPillarScores)
      }),
      getPlatformUnitKeyFromAction
    ),
    childUnitsByChannel: createKeyedReducer(
      createApiReducer(LOAD_PLATFORM_UNIT_CHILDREN_CHANNEL_PILLAR_SCORES, {
        getPayloadFromAction: makePayloadAdapter(nestedPlatformUnitChannelPillarScores)
      }),
      getPlatformUnitKeyFromAction
    )
  }),
  platformUnitPotentialEffiencies: createKeyedReducer(
    createApiReducer(LOAD_PLATFORM_UNIT_POTENTIAL_EFFICIENCIES),
    getPlatformUnitAndCurrencyKeyFromAction
  ),
  potentialEfficiencyConfigurations: createKeyedReducer(
    createApiReducer(LOAD_POTENTIAL_EFFICIENCY_CONFIGURATION),
    props => coerceReportProvider(getProviderKeyFromAction(props) as unknown as ReportProvider),
  ),
})

export default reducer
