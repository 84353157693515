import { ReduxAction } from '@percept/types'
import { Reducer } from 'redux'
import { APPLY_DERIVED_COMPETITIVE_FILTER, APPLY_FILTERS } from '../actions'

export type FiltersList = Array<{
  filterValue: string
  isSingleValue?: boolean
  options: { group_value: string; isSingleValue?: boolean; options: { value: string; name: string }[] }
}>

export type DashboardType = 'filtersMedia' | 'filtersCompetitive' | 'filtersCompetitiveSOS' | 'filtersCompetitiveSOV'

export type FiltersReduxState = Record<DashboardType, FiltersList> & {
  derivedCompetitive: {
    row: string | null
    column: string | null
  }
}

export type DerivedCompetitiveFilterPayload = {
  filterType: 'column' | 'row'
  filterValue: string | null
}


const initialState: FiltersReduxState = {
  filtersMedia: [],
  filtersCompetitive: [],
  filtersCompetitiveSOS: [],
  filtersCompetitiveSOV: [],
  derivedCompetitive: {
    row: null,
    column: null,
  },
}

const filtersReducer: Reducer<FiltersReduxState, ReduxAction> = (
  state = initialState,
  action
) => {
  if( action.type === APPLY_FILTERS ){
    return {
      ...state,
      [action.payload.dashboardType as keyof FiltersReduxState]:
        action.payload.list,
    }
  }
  if( action.type === APPLY_DERIVED_COMPETITIVE_FILTER ){
    const payload = action.payload as DerivedCompetitiveFilterPayload
    return {
      ...state,
      derivedCompetitive: {
        ...state.derivedCompetitive,
        [payload.filterType]: payload.filterValue,
      },
    }
  }

  return state
}

export default filtersReducer
