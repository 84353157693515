
import {
  SET_ACTIVE_METRIC,
  CLEAR_ACTIVE_METRIC,
} from '../actions'


export default (state = null, action) => {
  
  switch(action.type){
    
    case SET_ACTIVE_METRIC:
      return {
        ...(state || {}),
        ...action.payload
      }

    case CLEAR_ACTIVE_METRIC:
      return null

    default:
      return state
  
  }
}
