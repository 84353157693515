import React, { useRef, useEffect, useState } from 'react'

import {
  Box,
  BoxProps,
  Card,
  CardContent,
  CardProps,
  CardStrip,
  Chip,
  makeAppStyles,
  MetricSearchClasses,
  MetricSearchConfig,
  MetricSearchResults,
  MetricSearchResultsProps,
  Typography,
  TypographyProps,
} from '@percept/mui'

import { HealthType, MetricIdentification } from '@percept/types'

import { get } from 'lodash-es'


export type MetricListProps = (
  {
    health: HealthType
    metrics?: MetricSearchResultsProps['matches'] | null
    onMetricClick?: (props: MetricIdentification) => void
    BoxProps?: BoxProps
  } &
  Pick<
    MetricSearchResultsProps,
    'displayType' | 'layout' | 'payload' | 'entity' | 'metadata' | 'impactWeighted'
  > &
  CardProps
)



export type MetricListHeaderProps = (
  TypographyProps & {
    metricList?: Array<unknown> | null
  }
)


const useMetricListHeaderStyles = makeAppStyles( theme => ({
  typography: {
    display: 'flex',
    alignItems: 'center',
    height: 24,
    // height: '1em',
  },
  chip: {
    marginLeft: theme.spacing(1.5),
    padding: 0,
  },
}))


export const MetricListHeader = ({ metricList, className = '', children, ...props }: MetricListHeaderProps): JSX.Element => {

  const classes = useMetricListHeaderStyles()

  const count = get(metricList, 'length', 0)

  return (
    <Typography
      variant='h5'
      className={`${classes.typography} ${className}`}
      {...props}>

      { children }

      <Chip
        className={classes.chip}
        size='small'
        label={count}
        color={count === 0 ? 'default' : 'secondary'} />

    </Typography>
  )
}


const useStyles = makeAppStyles( theme => ({
  root: {
    overflowY: 'auto',
  },
}))


const useSearchClasses = makeAppStyles<
  {}, keyof Pick<MetricSearchClasses, 'entry' | 'title'>
>( theme => ({
  entry: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '4.5rem',
    padding: theme.spacing(1, 1.5, 0.5, 1.5),
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  title: {
    display: 'inline-flex',
    textAlign: 'left',
    maxWidth: '20rem',
    marginRight: theme.spacing(3),
    flexWrap: 'wrap',
    fontSize: 14,
  },
  // metric: {
  //   marginLeft: 'auto',
  //   display: 'flex',
  //   alignItems: 'center',
  // },
  // health: {
  //   marginRight: theme.spacing(1),
  //   textAlign: 'right',
  // },
}) )


const simpleSearchConfig: MetricSearchConfig = {
  view: 'LAYOUT',
  dimension: 'count'
}


const EMPTY_MATCHES: MetricSearchResultsProps['matches'] = []


export const MetricList = ({
  health,
  metrics,
  displayType = 'MICRO',
  layout,
  entity,
  payload,
  metadata,
  impactWeighted,
  onMetricClick,
  BoxProps = {
    maxHeight: '13.5rem',
  },
  ...props
}: MetricListProps): JSX.Element => {

  const containerRef = useRef<HTMLDivElement | null>(null)

  const classes = useStyles()

  const searchClasses = useSearchClasses()

  // Trigger another render on mount to ensure the ref is populated
  // and avoid viewport-dependent metrics failing to render
  const [, triggerRender] = useState(false)

  useEffect(() => {
    triggerRender(true)
  }, [])

  const matches = metrics || EMPTY_MATCHES

  return (
    <Card
      {...props}>

      <CardStrip color='health' health={health} />

      <CardContent>

        <Box
          className={classes.root}
          {...BoxProps}>

          <div ref={containerRef}>

            <MetricSearchResults
              classes={searchClasses}
              view='LIST'
              displayType={displayType}
              matches={matches}
              containerRef={containerRef}
              layout={layout}
              searchConfig={simpleSearchConfig}
              impactWeighted={impactWeighted}
              entity={entity}
              setActiveMetric={onMetricClick}
              payload={payload}
              metadata={metadata} />

            { !matches.length && (
              <Typography variant='h5' color='textSecondary'>
                No metrics from the latest report match this filter
              </Typography>
            )}

          </div>

        </Box>

      </CardContent>

    </Card>
  )
}
