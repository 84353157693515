
import { createApiReducer, createKeyedReducer } from '../../../reducers'

import { getReportKeyFromAction } from '../../../utils'

import {
  LOAD_CLIENT_REPORT,
} from '../actions'  

import { report } from '@percept/api/adapters'

import { Reducer } from '@percept/types'

import { ClientReportState } from '../typings'


const byId: Reducer<ClientReportState['byId']> = createKeyedReducer(
  createApiReducer(LOAD_CLIENT_REPORT, {
    getPayloadFromAction: ({ payload }) => (
      report(payload)
    )
  }),
  getReportKeyFromAction
)

export default byId
