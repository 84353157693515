import React from 'react'

import { Group } from '@visx/group'

import { scaleBand, scaleLinear } from '@visx/scale'

import { AnimatedBars } from './AnimatedBars'

import { get, range } from 'lodash-es'

import { ensureValidDomain, getLabel, getMergedLabels, getValue } from '../lib'

import { HistogramBarDatum, StackedHistogramProps } from './typings'

import { GenericAxisChart } from '../GenericAxisChart'

import { MultiDataset, SVGDatumType } from '../typings'

import { Annotations } from '../Quantitative/Annotations'

import { ScaleBand, ScaleLinear } from 'd3-scale'


export function getDatasetStacksArray<T extends SVGDatumType>({
  datasets,
  fillOpacity,
  strokeOpacity,
  yMax,
  xDomain,
  xScale,
  yScale,
}: (
  Pick<StackedHistogramProps<T>, 'datasets'> &
  Pick<React.SVGAttributes<SVGSVGElement>, 'fillOpacity' | 'strokeOpacity'> & {
    yMax: number
    xDomain: string[]
    xScale: ScaleBand<string>
    yScale: ScaleLinear<number, number>
  }
)): [MultiDataset<T>, HistogramBarDatum<T>[]][] {

  return datasets.reduce( (datasetAcc, dataset, datasetIndex) => {
    datasetAcc.push([
      dataset,
      dataset.data.reduce( (acc, d) => {

        const datumXDomainIndex = xDomain.indexOf(d.label as string)

        const totalOffset = range(datasetIndex).reduce( (totalAcc, prevIndex) => {
          const matchingDataset = datasetAcc[prevIndex]
          const matchingDatum = get(matchingDataset, 1, []).find( match => (
            xDomain.indexOf(match.label as string) === datumXDomainIndex
          ))
          totalAcc += get(matchingDatum, 'height', 0)

          return totalAcc
        }, 0)

        const yValue = getValue(d) || 0
        const scaledValue = yScale(yValue)
        const zeroPoint = yScale(0)
        const yDelta = scaledValue

        const rawHeight = Math.abs(
          (
            yValue === 0 ?
              0 :
              yDelta
          ) - (zeroPoint * (yDelta < 0 ? -1 : 1))
        )

        const height = (
          yValue === 0 ?
            0 :
            scaleLinear({
              domain: [yMax, 0],
              range: [yMax, 0]
            })(rawHeight)
        )

        const y = (
          yValue <= 0 ?
            zeroPoint :
            ( zeroPoint - height )
        ) - totalOffset

        const label = getLabel(d)
        const x = xScale(label.toString()) || 0
        const width = xScale.bandwidth()

        acc.push({
          label,
          width,
          height,
          x,
          y,
          data: d,
          fillOpacity: d.fillOpacity === undefined ? fillOpacity : d.fillOpacity,
          strokeOpacity: d.strokeOpacity === undefined ? strokeOpacity : d.strokeOpacity,
        })
        return acc
      }, [] as HistogramBarDatum<T>[])
    ])
    return datasetAcc
  }, [] as [MultiDataset<T>, HistogramBarDatum<T>[]][])
}


export function StackedHistogram<T extends SVGDatumType>({
  datasets,
  domain,
  width,
  height,
  onSegmentClick,
  onSegmentMouseOver,
  onSegmentMouseOut,
  animate = true,
  animateInitial = false,
  minBarHeight = 4,
  verticalMargin,
  barPaddingRatio = 0.3,
  xOffset,
  containerRef,
  svgBarProps = {},
  alternateBackground,
  mirrorYDomain,
  fillOverride,
  annotations,
  defs,
  backgroundElement,
  foregroundElement,
  svgClassName,
  ...props
}: StackedHistogramProps<T>): JSX.Element | null {

  const hasAxisText = !!props.axisText

  const canonicalXOffset = (
    xOffset !== undefined ?
      xOffset :
      hasAxisText && props.numYTicks !== 0 ?
        36 :
        0
  )

  verticalMargin = (
    typeof verticalMargin !== 'undefined' ?
      verticalMargin :
      hasAxisText && props.numXTicks !== 0 ?
        20 :
        0
  )

  const {
    fillOpacity = 1,
    strokeOpacity = 0
  } = svgBarProps

  const xMax = width - canonicalXOffset

  const yMax = height - verticalMargin

  const xDomain = getMergedLabels(datasets, false) as string[]

  const xScale = scaleBand<string>({
    range: [canonicalXOffset, width],
    round: true,
    domain: xDomain,
    padding: barPaddingRatio,
  })

  const dataTotalsByLabel = datasets.reduce( (acc, dataset) => {
    dataset.data.forEach( (datum) => {
      acc[datum.label] = acc[datum.label] || 0
      acc[datum.label] += Number(datum.value)
    })
    return acc
  }, {} as Record<string, number>)

  const maxStackValue = Math.max(...Object.values(dataTotalsByLabel))

  const yScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: ensureValidDomain(
      domain || (mirrorYDomain ? [-maxStackValue, maxStackValue] : [0, maxStackValue])
    ),
    nice: hasAxisText,
  })

  const stackedBarArrays = getDatasetStacksArray({
    datasets,
    yMax,
    xDomain,
    xScale,
    yScale,
    fillOpacity,
    strokeOpacity,
  })

  if( !(width > 0 && height > 0) ){
    return (
      <svg ref={containerRef} width={width || '100%'} height={height || '100%'} />
    )
  }

  return (
    <svg
      ref={containerRef}
      className={svgClassName}
      width={width}
      height={height}
      onMouseOut={onSegmentMouseOut}
      overflow='visible'>

      { defs && <defs>{ defs }</defs> }

      <rect x={0} y={0} width='100%' height='100%' opacity={0} fill='rgb(0,0,0)' />

      { backgroundElement || null }

      <GenericAxisChart
        xScale={xScale}
        yScale={yScale}
        width={width}
        height={height}
        columnOffset={xScale.bandwidth() / 2}
        xOffset={canonicalXOffset}
        verticalMargin={verticalMargin}
        animate={animate}
        animateInitial={animateInitial}
        datasets={datasets}
        rotateXTicks={true}
        {...props}>

        {/* { alternateBackground && (
          <Group>
            { bars.map( (bar, i ) => (
              i % 2 === 0 ? null : (
                <rect
                  fill={alternateBackground}
                  width={bar.width + (bar.width * barPaddingRatio)}
                  height={yMax}
                  x={bar.x - (bar.width * (barPaddingRatio / 2))}
                  y={0} />
              )
            ))}
          </Group>
        )} */}

        <Group>
          { stackedBarArrays.map( ([dataset, bars], i) => (
            <AnimatedBars
              key={`stack-${i}`}
              bars={bars}
              animate={animate}
              animateInitial={animateInitial}
              includeBarBackground={false}
              xScale={xScale}
              yScale={yScale}
              maxHeight={yMax}
              onSegmentClick={onSegmentClick}
              onSegmentMouseOver={onSegmentMouseOver}
              svgBarProps={svgBarProps}
              fillOverride={fillOverride} />
          ))}

        </Group>

        { !!(annotations && annotations.length) && (
          <Annotations
            annotations={annotations}
            xScale={xScale}
            yScale={yScale}
            width={xMax}
            height={yMax}
            xOffset={canonicalXOffset} />
        )}

      </GenericAxisChart>

      { foregroundElement || null }

    </svg>
  )
}

StackedHistogram.displayName = 'StackedHistogram'
