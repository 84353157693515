import { Box, Money, makeAppStyles } from '@percept/mui'
import React from 'react'
import { TABLE_FORM_ROW_HEIGHT } from './constants'

const useClasses = makeAppStyles((theme) => ({
  totalHeader: {
    ...theme.appComponents.table.footer,
  },
  total: {
    ...theme.appComponents.table.footer,
    borderTop: `1px solid ${theme.appComponents.table.footer.borderColor}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    height: TABLE_FORM_ROW_HEIGHT + 1,
    width: 120,
    padding: theme.spacing(2),
    fontSize: 13,
    fontWeight: 700,
  },
  money: {
    textAlign: 'end',
    width: '100%',
    marginRight: theme.spacing(1),
  },
}))

export const TableFormTotal = ({
  totalData,
  currency,
}: {
  totalData: string[]
  currency: string
  financialYear: string
}): JSX.Element => {
  const classes = useClasses()
  return (
    <Box display='flex' flexDirection='column'>
      {totalData.map((el, id) => (
        <div
          key={id}
          className={classes.total}>
          <span className={classes.money}>
            <Money
              amount={Number(el)}
              currency={currency} />
          </span>
        </div>
      ))}
    </Box>
  )
}
