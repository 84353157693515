
import React, { Component } from 'react'

import { Metric } from '../Metric'

import Main from './Main'
import Section from './Section'


import {
  getPath,
  isNumber,
  avg,
  collectMetricIdsFromLayout,
} from '@percept/utils'

import { throttle, every } from 'lodash-es'

import {
  Metric as MetricType,
  ReportMetricsPayload,
  HealthType,
  LayoutNodeType,
  AttributeMetric,
} from '@percept/types'

import { MetricProps } from '../Metric/typings'

import { ReportDashboardProps } from './typings'

import { LayoutTab } from './TabSwitcher'


export const resolveAttributeMetric = ({
  id,
  attributes,
  performanceAttributes,
  performanceTail,
}: {
  id: string
  attributes: Record<string, AttributeMetric['value']> | null
  performanceAttributes: Record<string, Record<string, number | null>> | null
  performanceTail?: string | undefined | null
}): AttributeMetric | null => {

  if( attributes && Object.keys(attributes).includes(id) ){
    return {
      metric_type: 'attribute',
      value: attributes[id],
    }
  }

  if( performanceAttributes && Object.keys(performanceAttributes).includes(id) ){
    return {
      metric_type: 'attribute',
      value: getPath(performanceAttributes[id], performanceTail || 'audit_period', null),
    }
  }
  
  return null
}


const isTab = (m: LayoutTab | LayoutNodeType): m is LayoutTab => m.type === 'tab'


export const getSectionScores = (
  layoutNode: LayoutNodeType,
  metrics: ReportMetricsPayload
): Record<string, HealthType> => {

  const { members } = layoutNode

  if( !members || !members.length ) return {}

  if( !every(members, isTab) ) return {}

  return (members as LayoutTab[]).reduce( (acc, member) => {
    const { members, title, name } = member

    if( members && members.length ){

      const health = collectMetricIdsFromLayout(member, metrics).reduce( (acc, id) => {
        const health = getPath(metrics, [id, 'dimensions', 'count', 'health'])
        if( isNumber(health) ){
          acc.push(health)
        }
        return acc
      }, [] as number[])

      acc[title || name] = health && health.length ? avg(health) : null

    }

    return acc
  }, {} as Record<string, HealthType>)
}



export const getMetricData = (combinedData: ReportMetricsPayload, id: string, dimension?: string): MetricType | null => {
  dimension = dimension === 'all' ? 'count' : dimension

  const entry = combinedData && combinedData[id]
  if( entry ){
    if( !dimension && entry.dimensions || dimension && entry.dimensions[dimension] ){
      return entry
    }
  }
  if( !combinedData ){
    console.warn('Missing metric data? No data found for', id)
  }

  return null
}


type DashboardMetricComponentProps<T = {}> = (
  Partial<MetricProps> &
  ReportDashboardProps &
  LayoutNodeType &
  {
    id: string
    performanceTail: string
    dimensionOptions: string[]
    placeholder?: boolean
    autoExpand?: boolean
  } &
  T
)


const DashboardMetric = ({
  placeholder = false,
  id,
  dimension,
  metrics,
  attributes,
  performanceAttributes,
  metadata,
  previous,
  metricAnnotations,
  healthDimension,
  performanceTail,
  ...props
}: DashboardMetricComponentProps<Pick<MetricProps, 'id' | 'dimension'>>): JSX.Element => {

  const metric = (
    !metadata[id] ?
      null :
      getMetricData(metrics, id, dimension) ||
      resolveAttributeMetric({
        id,
        attributes,
        performanceAttributes,
        performanceTail
      })
  )

  return (
    <Metric
      loading={placeholder}
      dimension={dimension}
      {...metadata && metadata[id]}
      id={id}
      /*
        #NOTE: We inject derived metricProps last,
        as attribute metrics will be marked as missing from the payload   
      */
      {...props}
      metric={metric} />
  )
}


export type PlaceholderComponentProps = React.PropsWithChildren<{
  containerRef?: React.Ref<HTMLDivElement>
}>

type Bounds = {
  top: number
  bottom: number
}

export type MetricOrPlaceholderProps = Partial<MetricProps> & DashboardMetricComponentProps & {
  getDefaultNodeBounds?: () => Bounds
  getContextBounds?: (context?: HTMLElement | Window) => Bounds
  context?: HTMLElement
  detectionMargin?: number
  debounceTime?: number
  PlaceholderComponent?: (props: PlaceholderComponentProps) => JSX.Element | null
}

export class MetricOrPlaceholder extends Component<
  MetricOrPlaceholderProps,
  { placeholder: boolean }
> {

  containerRef: React.RefObject<HTMLDivElement>

  scrollHandler: (e: Event) => void

  constructor(props: MetricOrPlaceholderProps){
    super(props)
    this.state = {
      placeholder: true
    }
    this.containerRef = React.createRef()
    this.scrollHandler = throttle(this.onScroll.bind(this), props.debounceTime || 50, { trailing: true })
  }

  onScroll(): void {
    const node = this.containerRef && this.containerRef.current
    const { context = window, detectionMargin = 50, getDefaultNodeBounds } = this.props

    if( context ){

      const nodeBounds = (
        !node && getDefaultNodeBounds && getDefaultNodeBounds()
      ) || (
        node && node.getBoundingClientRect()
      )

      if( nodeBounds ){

        const contextBounds = (
          this.props.getContextBounds ?
            this.props.getContextBounds(context) : (
              {
                top: window.scrollY,
                bottom: window.innerHeight + window.scrollY,
              }
            )
        )

        const { top, bottom } = contextBounds

        const elementOffset = nodeBounds.top + top

        const isInViewport = (
          elementOffset >= (top - detectionMargin) &&
          elementOffset <= (bottom + detectionMargin)
        )

        if( isInViewport ){
          this.setState({ placeholder: false })
          context.removeEventListener('scroll', this.scrollHandler)
        }
      }

    }
  }

  componentDidMount(): void {
    if( this.state.placeholder ){
      this.onScroll()
      const { context = window } = this.props
      if( context ){
        context.addEventListener('scroll', this.scrollHandler)
        window.addEventListener('resize', this.scrollHandler)
      }
    }
  }

  componentDidUpdate(prevProps: MetricOrPlaceholderProps): void {
    const { context = window } = this.props

    if( this.state.placeholder && context && context !== prevProps.context ){
      this.onScroll()
      context.addEventListener('scroll', this.scrollHandler)
      if( prevProps.context ){
        prevProps.context.removeEventListener('scroll', this.scrollHandler)
      }
    }
  }

  componentWillUnmount(): void {
    const { context = window } = this.props
    if( context ){
      context.removeEventListener('scroll', this.scrollHandler)
    }
    window.removeEventListener('resize', this.scrollHandler)
  }

  render(): JSX.Element {
    const { PlaceholderComponent } = this.props

    if( this.state.placeholder && PlaceholderComponent ){
      return <PlaceholderComponent containerRef={this.containerRef} />
    }

    return (
      <DashboardMetric
        loading={this.state.placeholder}
        containerRef={this.containerRef}
        {...this.props} />
    )
  }

}


const defaultDimensionOptions = ['count']

const MetricRenderer = ({ autoExpand, dimensionOptions, ...props }: DashboardMetricComponentProps): JSX.Element => {
  return (
    <MetricOrPlaceholder
      detectionMargin={100}
      dimensionOptions={autoExpand ? dimensionOptions : defaultDimensionOptions}
      {...props} />
  )
}


export const renderers = {
  'main': Main,
  'section': Section,
  'tab': Section,
  'donut': MetricRenderer,
  'histogram': MetricRenderer,
  'text': MetricRenderer,
  'single_value': MetricRenderer,
  'percentage': MetricRenderer,
  'ratio': MetricRenderer,
  'fraction': MetricRenderer,
  'attribute': MetricRenderer,
  'number': MetricRenderer,
  'currency': MetricRenderer,
  'list': MetricRenderer,
}
