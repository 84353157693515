import React from 'react'

import { Alert, Box, CircularProgress, PlainTextButton } from '@percept/mui'

import { LockOpen, ErrorOutline } from '@percept/mui/icons'

import { useSingleSignOnMetadata } from '@percept/hooks'

import { getSingleSignOnLink } from './lib'

const { WORKLOAD_TYPE } = process.env

export const SingleSignOn = (): JSX.Element => {

  const [{data, error}] = useSingleSignOnMetadata()

  const singleSignOnUrl = data && getSingleSignOnLink(data)

  return (
    <>
      <PlainTextButton
        variant='contained'
        color='primary'
        size='large'
        disabled={!data}
        startIcon={
          error ?
            <ErrorOutline /> :
            (!data) ?
              <CircularProgress size='1em' color='inherit' /> :
              <LockOpen />
        }
        onClick={(): void => {
          if( singleSignOnUrl ){
            window.location.href = singleSignOnUrl
          }
        }}>
        { error ? 'Single Sign On Unavailable' : 'Log in with Vodafone'}
      </PlainTextButton>

      { error && WORKLOAD_TYPE !== 'DEV' && (
        <Alert
          mt={3}
          variant='info'
          header='Vodafone Users in the UK'
          message={
            <Box p={1} pt={2}>
              Please try turning off your VPN and reloading the page while we work on a solution
            </Box>
          } />
      )}
    </>
  )
}
