import React from 'react'
import { makeFlagIconComponent } from './lib'


export const NL = makeFlagIconComponent(
  <>
    <path fill="#21468b" d="M0 0h512v512H0z"/>
    <path fill="#fff" d="M0 0h512v341.3H0z"/>
    <path fill="#ae1c28" d="M0 0h512v170.7H0z"/>
  </>
)
