
import {
  apiInitialState,
  apiInitialStateWithProcessing } from '../constants'

import {
  getFetchTypes
} from '../utils'

import {
  Reducer,
  ReduxAction,
  ApiResponse,
  Dictionary,
  FetchType,
} from '@percept/types'


const defaultAdditionalStateKeys = ['start', 'success', 'error']

const defaultGetPayloadFromAction = <T>({ payload }: ReduxAction): T => payload


const getAdditionalState = <T = unknown>(
  additionalStateValue: Dictionary<unknown> | Reducer<ApiResponse<T>>,
  state: ApiResponse<T>,
  action: ReduxAction,
  ...args: unknown[]
): Dictionary => (
    typeof additionalStateValue === 'function' ?
      additionalStateValue(state, action, ...args) :
      additionalStateValue
  )


const processUpdates = {
  start: { processing: true, processed: false },
  progress: { processing: true, processed: false },
  success: { processing: false, processed: true },
  error: { processing: false, processed: false }
}


const getProcessTypes = (processTypes: string[] | null): Dictionary<FetchType> | null => (
  !processTypes ?
    null :
    processTypes.reduce( (acc: Dictionary<FetchType>, processType) => ({
      ...acc,
      ...(
        Object.entries(getFetchTypes(processType)).reduce( (acc: Dictionary<any>, [k, v]) => {
          acc[v] = k
          return acc
        }, {})
      )
    }), {})
)


interface CreateApiReducerConfig<T> {
  getPayloadFromAction?: (a: ReduxAction) => T,
  process?: boolean,
  additionalStates?: Dictionary,
  processActionTypes?: string[],
  resetActionTypes?: string[],
  rehydrateActionType?: string | null,
}


export default <T = any, R extends ApiResponse = ApiResponse<T>>(baseType: string, {
  getPayloadFromAction = defaultGetPayloadFromAction,
  process = false,
  additionalStates = {},
  processActionTypes = [],
  resetActionTypes = [],
  rehydrateActionType = null
}: CreateApiReducerConfig<T> = {}): Reducer<R> => {

  const actionTypes = getFetchTypes(baseType)

  const hasProcessing = process || processActionTypes.length
  
  const processTypes = getProcessTypes(processActionTypes)

  const parsedAdditionalStates = defaultAdditionalStateKeys.reduce( (obj: Dictionary, k) => {
    if( typeof additionalStates[k] !== 'undefined' ){
      obj[k] = additionalStates[k]
    }else{
      obj[k] = {}
    }
    return obj
  }, {})

  const initialState = (
    hasProcessing ?
      apiInitialStateWithProcessing :
      apiInitialState
  ) as R

  const reducer: Reducer<R> = (state = initialState, action, ...args: any[]) => {
    // Check if rehydration is required
    if( rehydrateActionType && rehydrateActionType === action.type ){
      return {
        ...apiInitialState,
        ...state,
        loading: false, // Reset loading flag,
        ...(hasProcessing && { processing: false, processed: false }) // Reset processing flags if applicable
      }
    }
    // Check if action is a supplemental process type, and
    // return the current state merged with the specified update
    if( processTypes && processTypes[action.type] ){
      const fetchType = processTypes[action.type]
      const update = processUpdates[fetchType]
      return {
        ...state,
        ...update,
        ...(
          fetchType === 'success' && {
            data: getPayloadFromAction(action),
            ...(action.timestamp && {
              lastFetched: action.timestamp
            } || {}),
          } || {}
        )
      }
    }
    // Check if action is the supplied reset type, if any
    if( resetActionTypes.includes(action.type) ){
      return initialState
    }
    // Otherwise, check if the action is a fetch type variant
    // of the base type supplied to the reducer
    switch(action.type){
      case actionTypes.start:
        return {
          ...state,
          ...(getAdditionalState(parsedAdditionalStates.start, state, action, ...args)),
          ...(process && processUpdates.start),
          loading: true,
        }
      case actionTypes.progress:
        return {
          ...state,
          progress: action.payload,
          ...(process && processUpdates.progress),
          ...(getAdditionalState(parsedAdditionalStates.progress, state, action, ...args)),
        }
      case actionTypes.success:
        return {
          ...state,
          ...(getAdditionalState(parsedAdditionalStates.success, state, action, ...args)),
          ...(process && processUpdates.success),
          ...(action.timestamp && { lastFetched: action.timestamp }),
          data: getPayloadFromAction(action),
          loading: false,
          error: null,
        }
      case actionTypes.error:
        return {
          ...state,
          ...(getAdditionalState(parsedAdditionalStates.error, state, action, ...args)),
          ...(process && processUpdates.error),
          loading: false,
          error: action.payload,
        } 
      default:
        return state
    }
  }

  return reducer

}
