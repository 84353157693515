import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  CircularProgress,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeAppStyles } from '@percept/mui'
import { ChevronRight } from '@percept/mui/icons'
import { ReportService } from '../../../api/reports'
import { ReportSchemaWithDetails } from '../../../api/report-schemas'
import {
  BrandGrowthPlannerContext,
  FormSubmissionContext,
  QuestionContext,
  ReportSchemaContext,
} from '../../../contexts'
import { Fieldsets } from './Fieldsets'
import { Questions } from './Questions'
import { collectPrefillValues, hasRequiredAnswers } from './lib'

interface ReportSchemaProps {
  reportSchema: ReportSchemaWithDetails
}

const useStyles = makeAppStyles((theme) => ({
  fieldsetLegend: {
    fontSize: 25,
    lineHeight: '40px',
    fontWeight: 700,
  },
  submitButton: {
    fontSize: 16,
    fontWeight: 700,
    padding: '1rem 1.25rem',
  },
  submitButtonIcon: {
    marginLeft: theme.spacing(1),
    borderRadius: '100%',
    stroke: 'none',
  },
  iconActive: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
  },
  iconDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.075)',
  },
  accordionDetailsContainer: {
    margin: 0,
  },
  errorMsg: {
    color: '#e60000',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
  },
}))


export function ReportSchema({ reportSchema }: ReportSchemaProps): JSX.Element {
  const classes = useStyles()
  const { marketId } = useContext(BrandGrowthPlannerContext)
  const { onSubmit } = useContext(FormSubmissionContext)

  const [questionValues, setQuestionValues] = useState<Record<string, unknown>>({})

  const onQuestionChange = (questionId: string, value: unknown): void => {
    setQuestionValues( prev => ({...prev, [questionId]: value }))
  }

  const fieldsets = reportSchema.schema.fieldsets

  useEffect(() => {
    if( fieldsets && !Object.keys(questionValues).length ){
      setQuestionValues(
        collectPrefillValues(fieldsets)
      )
    }
  }, [fieldsets, questionValues])

  const isValid = hasRequiredAnswers(fieldsets || [], questionValues)

  const { mutate, isLoading, isError } = ReportService.useSubmitReport()

  const submitDisabled = !isValid || isLoading

  const handleSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault()

    const payload = {
      report_schema_id: reportSchema.id,
      answers: Object.entries(questionValues).map(([questionId, answer]) => ({
        question_id: questionId,
        answer,
      })),
    }

    mutate(
      { marketId, payload },
      {
        onSuccess: () => {
          onSubmit()
        },
      }
    )
  }


  return (
    <ReportSchemaContext.Provider
      value={{ prefillPlaceholders: reportSchema.prefill_placeholders }}>
      <QuestionContext.Provider
        value={{
          questionValues,
          onQuestionChange,
        }}>
        <form onSubmit={handleSubmit}>
          {fieldsets &&
            fieldsets.map(({ legend, fieldsets, questions }, index) => (
              <Accordion key={index} defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {legend && (
                    <Typography className={classes.fieldsetLegend}>
                      {legend}
                    </Typography>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <Container
                    maxWidth='sm'
                    disableGutters
                    className={classes.accordionDetailsContainer}>
                    {fieldsets && <Fieldsets fieldsets={fieldsets} />}
                    {questions && <Questions questions={questions} />}
                  </Container>
                </AccordionDetails>
              </Accordion>
            ))}

          {isError && (
            <Box mt={4}>
              <Typography className={classes.errorMsg}>
                An error occurred submitting the form
              </Typography>
            </Box>
          )}

          <Box mt={5}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={submitDisabled}
              className={classes.submitButton}
              endIcon={
                isLoading ? (
                  <CircularProgress size='1em' color='inherit' />
                ) : (
                  <ChevronRight
                    fontSize='large'
                    className={`${classes.submitButtonIcon} ${submitDisabled ? classes.iconDisabled : classes.iconActive}`}
                  />
                )
              }>
              Submit
            </Button>
          </Box>
        </form>
      </QuestionContext.Provider>
    </ReportSchemaContext.Provider>
  )
}
