import React from 'react'

import { makeAppStyles } from '@percept/mui'
import { CloudDownload, OpenInNew } from '@percept/mui/icons'

import { Asset } from 'application-assets/typings'

import assetsManifest from 'application-assets/manifest'


const useStyles = makeAppStyles( theme => ({
  download: {
    fontSize: 16,
    fontWeight: 700,
    display: 'inline-flex',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'underline solid transparent',
    textUnderlineOffset: '5px',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: `underline solid ${theme.palette.primary.main}`,
    },
    transition: theme.transitions.create(['color', 'text-decoration']),
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: '1.25em',
  }
}))


export const resolveAsset = (assetIdentifier: string): Asset | null => {
  const { assets } = assetsManifest
  const asset = assets[assetIdentifier]
  if( !asset ){
    console.warn('Could not find application asset', assetIdentifier)
    return null
  }
  return asset
} 


export type AssetLinkProps = {
  assetIdentifier: string
  showIcon?: boolean
  linkContent?: React.ReactNode
} & Partial<Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'children'>>

export const AssetLink = ({
  assetIdentifier,
  showIcon = true,
  linkContent,
  ...props
}: AssetLinkProps): JSX.Element | null => {
  const classes = useStyles()

  const asset = resolveAsset(assetIdentifier)

  if( !asset ){
    return null
  }

  const { outputPath } = assetsManifest

  const anchorProps: Partial<React.AnchorHTMLAttributes<HTMLAnchorElement>> = {
    href: `${outputPath}/${asset.filename}.${asset.extension}`
  }

  const IconComponent = asset.type === 'DOWNLOAD' ? CloudDownload : OpenInNew

  if( asset.type === 'DOWNLOAD' ){
    anchorProps.download = `${asset.displayFilename}.${asset.extension}`
  }else{
    anchorProps.rel = 'noopener noreferrer'
    anchorProps.target = '_blank'
  }

  return (
    <a
      className={classes.download}
      {...anchorProps}
      {...props}>
      { showIcon && <IconComponent className={classes.icon} /> }
      { linkContent || asset.displayFilename }
    </a>
  )  
}
