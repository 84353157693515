import React from 'react'
import { OverviewAnalysisItem, OverviewEntity } from './typings'


export const singleMarketAnalysis: Partial<Record<OverviewEntity, OverviewAnalysisItem[]>> = {
  DE: [
    {
      label: 'Strategy',
      texts: [
        <>
          VF dropped to #4, 9pts behind #3 (1+1), DTAG is leading with 45% SOS. 
        </>,
        <>
          Brand Share 20pts behind ambition, especially in digital low brand share.
        </>
      ],
    },
    {
      label: 'Optimisation',
      texts: [
        <>
          Media wastage has been decreasing consistently, especially in Search and Programmatic. However, Meta should be prioritised as it has the highest wastage share at 7% media wastage and increasing.
        </>,
        <>
          Creative Wastage can be decreased through adhering to platforms best practices and dirivng Creative Quality Score higher. 
        </>,
        <>
          The biggest opportunity to decrease overall wastage (media + creative) is by focusing on improving creative quality. 
        </>,
        <>
          The Creative Quality Score remains low against 80% target.
        </>,
        <>
          The biggest opportunity to drive the score higher are around adherence to best practices for Simple Messaging, Call to action, Optimal Length.
        </>,
      ],
    },
  ],

  UK: [
    {
      label: 'Strategy',
      texts: [
        <>
          Strong SOS for VF with 15% just 10pts behind Sky and joined #2 in market. 
        </>,
        <>
          Good increase in Brand share, especially in digital.
        </>
      ],
    },
    {
      label: 'Optimisation',
      texts: [
        <>
          Creative Wastage can be decreased through adhering to platforms best practices and dirivng Creative Quality Score higher. 
        </>,
        <>
          The Creative Quality Score half way to achieving 80% target. The biggest oportunity to drive the score higher is with Simple Messaging.
        </>
      ],
    },
  ],

  IT: [
    {
      label: 'Strategy',
      texts: [
        <>
          Good improvement vs last year and strong #2 in market, just 6pts behind leader. 
        </>,
        <>
          Quite on target.
        </>
      ],
    },
    {
      label: 'Optimisation',
      texts: [
        <>
          Media wastage continued to increase this quarter but at a lower pace. IT is still the market with biggest % wastage across markets (double the average). Google Ads is the biggest opportunity as it has the biggest wastage per channel (13%) and accounts for 88% of all media wastage. 
        </>,
        <>
          Creative Wastage can be decreased through adhering to platforms best practices and dirivng Creative Quality Score higher. 
        </>,
        <>
          The Creative Quality Score is at low level against 80% target. There's big opportunity to address low scores through adhering to best practices for Simple Messaging, Call to Action, Optimal Length.
        </>
      ],
    },
  ],

  ES: [
    {
      label: 'Strategy',
      texts: [
        <>
          Strong start in FY (+19pts), leading the SOS ranking. 
        </>,
        <>
          Brand share behind ambition of 60%.
        </>
      ],
    },
    {
      label: 'Optimisation',
      texts: [
        <>
          Media wastage massively improved this quarter, especially Meta and Programmatic channels. Google Ads is the biggest opportunity as it has the biggest wastage per channel (7%) and accounts for 74% of all media wastage.
        </>,
        <>
          Creative Wastage can be decreased through adhering to platforms best practices and dirivng Creative Quality Score higher.  
        </>,
        <>
          The Creative Quality Score is contiunously increasing and close to  achieving 80% target. 
        </>
      ],
    },
  ],

  ZA: [
    {
      label: 'Strategy',
      texts: [
        <>
          MTN with strong start in year (+13pts) outperfomring competition. VF strong #2. 
        </>,
        <>
          Improved brand share, however still 8pts behind ambition.
        </>
      ],
    },
    {
      label: 'Optimisation',
      texts: [
        <>
          Media wastage has been decreasing overall, but South Africa is the 2nd market with highest %. Google Ads is the biggest opportunity as it has the biggest wastage per channel (9%) and accounts for more than half of all media wastage.
        </>,
        <>
          Creative Wastage can be decreased through adhering to platforms best practices and dirivng Creative Quality Score higher.  
        </>,
        <>
          The Creative Quality Score is at relatively low level against 80% target. Biggest ares for opportunity are around adhering to best practices for Simple Messaging and Optimal Length. 
        </>
      ],
    },
  ],


  TR: [
    {
      label: 'Strategy',
      texts: [
        <>
          No ATL spend due to earthquake. 
        </>
      ],
    },
    {
      label: 'Optimisation',
      texts: [
        <>
          Media wastage increased QoQ, mainly due to very limited investment in the previous quarter in relation to a national natural disaster. Overall, the biggest opportunity is to focus on Meta which has the biggest wastage per channel (6%).
        </>,
        <>
          Creative Wastage can be decreased through adhering to platforms best practices and dirivng Creative Quality Score higher.  
        </>,
        <>
          The Creative Quality Score is at relatively low level against 80% target. Biggest ares for opportunity are around adhering to best practices for Simple Messaging and Optimal Length. 
        </>
      ],
    },
  ],

  RO: [
    {
      label: 'Strategy',
      texts: [
        <>
          Slight decline YOY, still #2 behind Orange. 
        </>,
        <>
          Decrease in brand share (-12pts) and behind ambition.
        </>
      ],
    },
    {
      label: 'Optimisation',
      texts: [
        <>
          Media wastage massively improved this quarter across all channels. Google Ads and Meta still have around 5% media wastage each, which can be further reduced by following the specific best practices on the Media Quality Dashboard.
        </>,
        <>
          Creative Wastage can be decreased through adhering to platforms best practices and dirivng Creative Quality Score higher.  
        </>,
        <>
          The Creative Quality Score is continuously improving towards 80% target. With main area for opportunity being Simple Messaging.
        </>
      ],
    },
  ],

  GR: [
    {
      label: 'Strategy',
      texts: [
        <>
          Decline in brand share, but still on 54% (6pts behind ambition).
        </>
      ],
    },
    {
      label: 'Optimisation',
      texts: [
        <>
          Media wastage increased this quarter, mainly due to Google Ads. This channel has the biggest wastage per channel (6%) and accounts for 72% of all media wastage.
        </>,
        <>
          Creative Wastage can be decreased through adhering to platforms best practices and dirivng Creative Quality Score higher.   
        </>,
        <>
          The Creative Quality Score is gradually getting closer to 80% target. With main area for opportunity being Call to Action and Simple Messaging.
        </>
      ],
    },
  ],

  PT: [
    {
      label: 'Strategy',
      texts: [
        <>
          Still leading SOS, bit shrinking gap to #2 (2pts), as Altice improved by 19pts YOY.
        </>,
        <>
          Strong focus on brand investments.
        </>
      ],
    },
    {
      label: 'Optimisation',
      texts: [
        <>
          Media wastage has been decreasing overall, but Meta and Adform are the main opportunities. Each waste 6% of their budget and overall account for over half of all media wastage.
        </>,
        <>
          Creative Wastage can be decreased through adhering to platforms best practices and dirivng Creative Quality Score higher.    
        </>,
        <>
          The Creative Quality Score is gradually getting closer to 80% target. Opportunity areas are Simple Messaging, Call to action, Thumbnail Branding.
        </>
      ],
    },
  ],

  IE: [
    {
      label: 'Strategy',
      texts: [
        <>
          Increase is SOS leading to joined #2 (with 3). Sky is leading despite 11pts decrease.
        </>,
        <>
          Strong focus on brand investments.
        </>
      ],
    },
    {
      label: 'Optimisation',
      texts: [
        <>
          Media wastage has been decreasing overall, and it's the lowest across markets. From all channels, Meta has the highest wastage per channel (6%) and over half of all wastage.
        </>,
        <>
          Creative Wastage can be decreased through adhering to platforms best practices and dirivng Creative Quality Score higher.     
        </>,
        <>
          The Creative Quality Score is gradually getting closer to 80% target. Main opportunity area to drive the score even higher is in Simple Messaging.
        </>
      ],
    },
  ],

  CZ: [
    {
      label: 'Strategy',
      texts: [
        <>
          Hardly movements in market, all players on same level YOY.
        </>,
        <>
          Slight increase and well on track.
        </>
      ],
    },
    {
      label: 'Optimisation',
      texts: [
        <>
          Media wastage has been decreasing overall, but Meta requires attention as it's the channel with highest wastage (6%) per channel. Adform has also been increasing and accounts for 44% of all wastage.
        </>,
        <>
          Creative Wastage can be decreased through adhering to platforms best practices and dirivng Creative Quality Score higher.     
        </>,
        <>
          The Creative Quality Score is relatively low against the 80% target. Main opportunity areas to drive the score higher are in Simple Messaging, Thumbnail Branding, C2A.
        </>
      ],
    },
  ],

  AL: [
    {
      label: 'Strategy',
      texts: [
        <>
          SOS under pressure from ONE (meger).
        </>,
        <>
          Well on track.
        </>
      ],
    },
    {
      label: 'Optimisation',
      texts: [
        <>
          Media wastage is limited to Meta, since Google Ads and Programmatic have very residual investments. Increased attention to following specific best practices in Meta can help reduce this wastage to below 1%.
        </>,
        <>
          Creative Wastage can be decreased through adhering to platforms best practices and dirivng Creative Quality Score higher.     
        </>,
        <>
          The Creative Quality Score keeps growing. Main opportunity area to drive the score higher is in Simple Messaging.
        </>
      ],
    },
  ],

  EG: [
    {
      label: 'Strategy',
      texts: [
        <>
          Hardly movements on market. VF still leading ranking with 33%.
        </>,
        <>
          Strong focus on brand investments.
        </>
      ],
    },
    {
      label: 'Optimisation',
      texts: [
        <>
          Media wastage has been decreasing overall. However, Meta can be further improved by following the specific best practices on the Media Quality Dashboard. Meta has the highest wastage per channel (6%) and 84% of all wastage.
        </>,
        <>
          Creative Wastage can be decreased through adhering to platforms best practices and dirivng Creative Quality Score higher.      
        </>,
        <>
          The Creative Quality Score keeps growing. Main opportunity areas to drive the score is in Simple Messaging, Aspect Ratios, Thumbnail Branding.
        </>
      ],
    },
  ]

}
