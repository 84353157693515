import React, { Fragment, useContext } from 'react'

import { vodafonePalette } from '@percept/mui'

import { DefaultMediaWizardLayout } from './DefaultMediaWizardLayout'
import { Carousel, CarouselItem } from 'components/Carousel'
import { NewsHeader, NewsLink, WizardNewsSummary } from 'components/News'
import { LinkSection, LinkSections, vodafoneExternalLinks } from 'components/Links'

import { useDocumentTitle } from '@percept/hooks'

import carouselWhite1 from '../images/carousel-backgrounds/carousel-white-1.jpg'
import carouselGrey2 from '../images/carousel-backgrounds/carousel-grey-2.jpg'
import carouselBlack1 from '../images/carousel-backgrounds/carousel-black-1.jpg'

import infoGraphicNewCustomers from '../images/infographics/new-customers-light-bg.png'
import infoGraphicSustainability from '../images/infographics/sustainability-2-light-bg.svg'
import infoGraphicCustomer from '../images/infographics/customer-growth-dark-bg.svg'

import infoGraphicPathways from '../images/infographics/pathways-grey-bg.png'
// import infoGraphicBrandGrowth from '../images/infographics/brand-growth-2-light-bg.svg'
import infoGraphicMediaMixModelling from '../images/infographics/media-mix-modelling-4-light-bg.svg'
// import infoGraphicMediaStrategy from '../images/infographics/media-strategy-dark-bg.svg'
import imageRepresentation from '../images/stock/representation.jpg'
import { AnimationBrandGrowth, AnimationSquares } from 'animations'
import { UserPrivilegeContext } from '@percept/app-components'
import { userHasGlobalPrivileges } from '@percept/utils'


const AnimationSquaresDark = (): JSX.Element => <AnimationSquares variant='dark' />


const linkSections: LinkSection[] = [
  {
    title: 'Pathways: connecting the customer journey',
    description: (
      <Fragment>
        Use Pathways to prioritise and plan media based on deep human data and insights.
      </Fragment>
    ),
    globalPrivileges: ['vfMicrosites.pathways.view'],
    path: '/wizard/strategy/pathways',
    openInNewWindow: true,
    imageSrc: infoGraphicPathways,
  },
  {
    title: 'Brand Growth Planner',
    description: (
      <Fragment>
        A dynamic platform and playbook to guide your annual planning and support ongoing
        strategic decisions.
      </Fragment>
    ),
    globalPrivileges: ['brandGrowthPlanner.viewAny'],
    // imageSrc: infoGraphicBrandGrowth,
    path: '/wizard/strategy/brand-growth-planner',
    openInNewWindow: true,
    imageContent: (
      <AnimationBrandGrowth />
    )
  },
  {
    title: 'Media Mix Modelling',
    description: (
      <Fragment>
        Create a bigger commercial impact by improving your Media Mix. Measure performance against
        primary media KPIs to optimise media investment, across all channels.
      </Fragment>
    ),
    globalPrivileges: ['vfMicrosites.mmm.view'],
    path: '/wizard/strategy/media-mix-modelling',
    openInNewWindow: true,
    imageSrc: infoGraphicMediaMixModelling,
    imageHeader: 'Total investment across markets',
  },
  {
    title: 'Representation',
    description: (
      <Fragment>
        Did you know that 94% of brands show women in a primary role but most instances in stereotypical roles?
        See how representation is reflected in your ads.
      </Fragment>
    ),
    imageSrc: imageRepresentation,
    path: vodafoneExternalLinks.representation,
    openInNewWindow: true,
  },
]


export const Strategy = (): JSX.Element => {

  useDocumentTitle({ paths: ['Strategy'] })

  const privileges = useContext(UserPrivilegeContext)

  const pathwaysLink = (
    userHasGlobalPrivileges(['vfMicrosites.pathways.view'], privileges.global_privileges) ?
      '/wizard/strategy/pathways' :
      null
  )

  const carouselItems: CarouselItem[] = [
    {
      title: 'Media Strategy for growth',
      description: (
        <Fragment>
          Create smarter media strategies, powered by intelligence and diagnostic tools:<br />
          Pathways, Brand Growth Planner and Media Mix Modelling
        </Fragment>
      ),
      textColor: 'white',
      backgroundOverride: '#000',
      // infoGraphicSrc: infoGraphicMediaStrategy,
      GraphicComponent: AnimationSquaresDark,
      infoGraphicFixedRight: true,
    },
    {
      title: 'Media Strategy for growth',
      description: (
        <Fragment>
          Media plays a big role in attracting a range of new customers
        </Fragment>
      ),
      textColor: 'black',
      backgroundImageSrc: carouselGrey2,
      infoGraphicSrc: infoGraphicNewCustomers,
    },
    // {
    //   title: 'Refurbished phones reduce environmental impact by 87%',
    //   description: (
    //     <Fragment>
    //       With 45% of households choosing brands committed to sustainability,
    //       we must place a stronger focus on sustainable messaging.
    //     </Fragment>
    //   ),
    //   textColor: 'black',
    //   backgroundImageSrc: carouselWhite1,
    //   infoGraphicSrc: infoGraphicSustainability,
    //   infoGraphicHeader: 'Reduce impact by',
    //   infoGraphicHeaderStyle: {
    //     color: vodafonePalette.red,
    //     fontSize: 45,
    //   },
    // },
    { /* LEGACY Carousel Item */
      title: 'Putting the customer at the heart of growth',
      description: (
        <Fragment>
          Use data to drive decisions and create meaningful connections with our
          customers through media investments.
        </Fragment>
      ),
      textColor: 'white',
      backgroundImageSrc: carouselBlack1,
      infoGraphicSrc: infoGraphicCustomer,
    },
    // { /* NEW Carousel Item */
    //   title: 'Placing the Customer at the Center of Growth',
    //   description: (
    //     <Fragment>
    //       Utilize these insights to gain a deeper understanding of our customers
    //     </Fragment>
    //   ),
    //   href: pathwaysLink,
    //   textColor: 'white',
    //   backgroundImageSrc: carouselBlack1,
    //   // infoGraphicSrc: infoGraphicCustomer,
    // },
  ]

  return (
    <DefaultMediaWizardLayout
      breadcrumbs={[
        {
          path: '/wizard/strategy',
          name: 'Strategy',
        },
      ]}>

      <Carousel items={carouselItems} />

      <LinkSections sections={linkSections} />

      <NewsHeader />
      
      <WizardNewsSummary />

      <NewsLink />

    </DefaultMediaWizardLayout>
  )
}
