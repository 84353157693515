
import { Tooltip } from '@material-ui/core'

import { withAppStyles } from '../../themes'


export const EntityTooltip = withAppStyles( theme => ({
  tooltip: {
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 12,
  },
}))(Tooltip)
