
import React, { useMemo } from 'react'

import { useSprings, animated } from 'react-spring'

import { makeAppStyles } from '../../themes'

import { mapGaugeData, GaugeProps } from './lib'

import { DatumType } from '@percept/types'


const useStyles = makeAppStyles( theme => ({
  gauge: {
    display: 'flex',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  horizontal: {
    flexDirection: 'row',
    '& $segment': {
      height: '100%',
    },
  },
  vertical: {
    flexDirection: 'column',
    transform: 'rotate(180deg)',
    '& $segment': {
      width: '100%'
    },
  },
  segment: {
    position: 'relative',
  },
}) )


export function Gauge<T extends DatumType>({
  data,
  width = '100%',
  height = '100%',
  orientation = 'horizontal',
  className = '',
  animate = true
}: GaugeProps<T>): JSX.Element {

  const gaugeData = useMemo(() => mapGaugeData(data || []), [data])

  const dimensionKey = orientation === 'horizontal' ? 'width' : 'height'

  const springs = useSprings(
    gaugeData.length,
    gaugeData.map( ({ color, weight }) => ({
      from: animate && {
        background: color,
        [dimensionKey]: '0%'
      },
      to: {
        background: color,
        [dimensionKey]: `${weight * 100}%`
      }
    }) )
  )

  const classes = useStyles()

  return (
    <div
      className={`${classes.gauge} ${classes[orientation]} ${className}`}
      style={{width, height}}>

      { springs.map( (style, i) => (
        <animated.div
          className={classes.segment}
          key={data[i].key || data[i].label || i}
          style={style} />
      ))}
    
    </div>
  )

}
