import React, { useMemo } from 'react'

import {
  sankey as d3Sankey,
  sankeyLinkHorizontal as d3SankeyLinkHorizontal,
  SankeyLink,
  SankeyGraph,
  SankeyNode,
  SankeyLinkMinimal,
} from 'd3-sankey'

import { path as d3Path } from 'd3-path'

// import { animated, useSpring } from 'react-spring'

import { TextShadow } from '../effects'

import { identity } from 'lodash-es'


export type BasicSankeyNode = {
  id: string
  name: string
  color?: string
}

export type BasicSankeyLink = {
  color?: string
  key?: string | number
}


function sankeyPathLinkHorizontal<
  Node extends BasicSankeyNode,
  Link extends BasicSankeyLink
>(link: SankeyLinkMinimal<Node, Link>): string {
  const sx = (link.source as SankeyNode<Node, Link>).x1 || 0
  const tx = (link.target && (link.target as SankeyNode<Node, Link>).x0 || 0)
  const halfWidth = Math.max(1, (link.width || 0)) / 2
  const sy0 = (link.y0 || 0) - halfWidth
  const sy1 = (link.y0 || 0) + halfWidth
  const ty0 = (link.y1 || 0) - halfWidth
  const ty1 = (link.y1 || 0) + halfWidth

  const offset = 0
  
  const halfX = (tx - sx) / 2

  const path = d3Path()
  path.moveTo(sx, sy0)

  let cpx1 = sx + halfX
  let cpy1 = sy0 + offset
  let cpx2 = sx + halfX
  let cpy2 = ty0 - offset
  path.bezierCurveTo(cpx1, cpy1, cpx2, cpy2, tx, ty0)
  path.lineTo(tx, ty1)

  cpx1 = sx + halfX
  cpy1 = ty1 - offset
  cpx2 = sx + halfX
  cpy2 = sy1 + offset
  path.bezierCurveTo(cpx1, cpy1, cpx2, cpy2, sx, sy1)
  path.lineTo(sx, sy0)
  return path.toString()
}


function SankeyNodeComponent<Node extends BasicSankeyNode, Link extends BasicSankeyLink>({
  name,
  x0 = 0,
  x1 = 0,
  y0 = 0,
  y1 = 0,
  color,
  value,
  valueFormatter = identity,
  textProps = {},
  onClick,
}: SankeyNode<Node, Link> & {
  valueFormatter?: (value: number | undefined) => string
  onClick?: (e: React.MouseEvent | React.TouchEvent) => void
  textProps?: Partial<React.SVGAttributes<SVGTextElement>>
}): JSX.Element {

  return (
    <g onClick={onClick}>
      <rect
        // stroke={color || 'currentColor'}
        // strokeWidth={1}
        // strokeOpacity={0.15}
        fill={color || 'currentColor'}
        cursor={onClick ? 'pointer' : 'default'}
        x={x0}
        y={y0}
        width={x1 - x0}
        height={y1 - y0} />
      <text
        fill='white'
        filter='url(#textShadow)'
        fontSize={12}
        x={x1}
        y={
          y0 + ((y1 - y0) / 2)
        }
        textAnchor='start'
        dominantBaseline='central'
        {...textProps}>
        { name || 'No Name' } - { valueFormatter(value) }
      </text>
    </g>
  )
}


// const SankeyLinkComponent = (
//   { color = 'currentColor', width, ...props }: React.SVGAttributes<SVGPathElement>
// ): JSX.Element => {

//   const animatedProps = useSpring({
//     color,
//     width,
//     to: {
//       color,
//       width,
//     }
//   })
  
//   return (
//     <animated.path
//       strokeWidth={animatedProps.width}
//       fill='none'
//       stroke={animatedProps.color || 'currentColor'}
//       {...props} />
//   )
// }


const rightTextProps: Partial<React.SVGAttributes<SVGTextElement>> = {
  dominantBaseline: 'central',
  textAnchor: 'start',
  dx: 8,
}

const leftTextProps: Partial<React.SVGAttributes<SVGTextElement>> = {
  dominantBaseline: 'central',
  textAnchor: 'end',
  dx: -12,
}


type SankeySorter<Node extends BasicSankeyNode, Link extends BasicSankeyLink> = (
  a: SankeyNode<Node, Link>, b: SankeyNode<Node, Link>
) => number


export type SankeyProps<
  Node extends BasicSankeyNode = BasicSankeyNode,
  Link extends BasicSankeyLink = BasicSankeyLink
> = {
  width: number
  height: number
  nodes: Node[]
  links: SankeyLink<Node, Link>[]
  nodeSort?: SankeySorter<Node, Link>
  nodeColor?: string 
  linkColor?: string 
  nodePadding?: number
  nodeWidth?: number
  valueFormatter?: (value: number | undefined) => string
  orderLinksForRender?: (links: SankeyLinkMinimal<Node, Link>[]) => SankeyLinkMinimal<Node, Link>[]
  onLinkClick?: (
    e: React.MouseEvent | React.TouchEvent,
    link: SankeyLinkMinimal<Node, Link> & Link
  ) => void
  onNodeClick?: (e: React.MouseEvent | React.TouchEvent, node: Node) => void
}

export function Sankey<
  Node extends BasicSankeyNode = BasicSankeyNode,
  Link extends BasicSankeyLink = BasicSankeyLink
>({
  width,
  height,
  nodes,
  nodeColor,
  links,
  linkColor,
  nodeSort,
  nodeWidth = 10,
  nodePadding = 16,
  valueFormatter = identity,
  orderLinksForRender = identity,
  onNodeClick,
  onLinkClick,
}: SankeyProps<Node, Link>): JSX.Element {

  const graph = useMemo(() => {
    const sankey = d3Sankey<SankeyGraph<Node, Link>, Node, Link>()
    
    sankey.nodeWidth(nodeWidth)
    sankey.nodePadding(nodePadding)
    // sankey.nodeAlign(sankeyRight)

    sankey.nodeId( d => d.id )

    sankey.size([width, height])

    nodeSort && sankey.nodeSort(nodeSort)
    nodeSort && sankey.linkSort(nodeSort)

    return sankey({
      nodes,
      links,
    })
  }, [width, height, nodes, links, nodeSort, nodeWidth, nodePadding])

  const orderedLinks = orderLinksForRender(graph.links)

  // const springs = useSprings(orderedLinks.length, orderedLinks.map( link => {
  //   const animationParams = {
  //     width: Math.max(1, link.width || 0),
  //     color: (link as unknown as Link).color || linkColor || 'currentColor',
  //     d: d3SankeyLinkHorizontal()(link) || '',
  //   }
  //   if( !animationParams.color || !animationParams.width || !animationParams.d ){
  //     console.log('Missing keys', animationParams)
  //   }
  //   return animationParams
  // }))

  return (
    <div>

      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}>

        <defs>
          <TextShadow />
        </defs>

        { orderedLinks.map( (link, i) => {
          // const animatedProps = springs[i]
          // console.log('ANIMATED PROPS', animatedProps)
          const key = `link-${(link as unknown as Link).key || i}`
          const color = (link as unknown as Link).color || linkColor || 'currentColor'
          const pathProps: Partial<React.SVGAttributes<SVGPathElement>> = {
            // d: d3SankeyLinkHorizontal()(link) || '',
            // fill: 'none',
            // strokeWidth: Math.max(1, link.width || 0),
            d: sankeyPathLinkHorizontal(link) || '',
            fill: color,
            // fillOpacity: 0.85,
            stroke: color,
            strokeWidth: 0.5,
            // strokeOpacity: 1,
            opacity: 0.85,
            ...(onLinkClick && {
              cursor: 'pointer',
              onClick: (e): void => {
                onLinkClick(e, link as SankeyLinkMinimal<Node, Link> & Link)
              },
            } || {}),
          }

          //   return (
          //     <animated.path
          //       {...pathProps}
          //       key={key}
          //       strokeWidth={animatedProps.width}
          //       d={animatedProps.d}
          //       stroke={animatedProps.color} />
          //   )
          return (
            <path key={key} {...pathProps} />
          )
          // return (
          //   <SankeyLinkComponent
          //     key={key}
          //     {...pathProps}
          //     color={
          //       (link as unknown as Link).color || linkColor
          //     }
          //     strokeOpacity={0.85}
          //     width={Math.max(1, link.width || 0)} />
          // )
        }) }

        { graph.nodes.map( (n, i) => {
          const { x0 = 0 } = n
          const textProps = x0 >= 10 ? leftTextProps : rightTextProps
          return (
            <SankeyNodeComponent
              key={n.id || `node-${i}`}
              {...n}
              onClick={(e): void => {
                if( onNodeClick ){
                  onNodeClick(e, n)
                }
              }}
              color={nodeColor || n.color}
              valueFormatter={valueFormatter}
              textProps={textProps} />
          )
        }) }

        

      </svg>

    </div>
  )
}

