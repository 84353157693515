
import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'

import { ReduxBundle, Bundler } from '../lib'

import { JsonSchemaState } from './typings'


type JsonSchemaActions = typeof actions
type JsonSchemaSelectors = typeof selectors


export type JsonSchemaBundle = ReduxBundle<JsonSchemaState, JsonSchemaActions, JsonSchemaSelectors>


export { actions, reducer, selectors }


export const makeBundle: Bundler<JsonSchemaBundle> = () => ({
  actions,
  reducer,
  selectors
})

export default { actions, reducer, selectors } as JsonSchemaBundle