import { flatten, sum, uniq } from 'lodash-es'

import { Accessor } from '@visx/shape/lib/types'

import { ChartData, Domain, MultiDataset, SVGDatumType } from './typings'


export interface HasValue {
  value: number | null
}

export const getValue: Accessor<HasValue, number | null> = (datum) => (
  datum.value
)

export const getNumberValue: Accessor<HasValue, number> = (datum) => (
  Number(getValue(datum))
)


export interface HasLabel {
  label: string | number
}

export const getLabel: Accessor<HasLabel, string | number> = (datum) => (
  datum.label
)

export const getNumberLabel: Accessor<HasLabel, number> = (datum) => (
  Number(getLabel(datum))
)


export interface HasOptionalColor {
  color?: string | null
}

export const getColor: Accessor<HasOptionalColor, string | null> = (datum) => (
  datum.color || null
)


export const getDomain = <D = HasValue>(
  data: D[],
  valueAccessor?: Accessor<D, number | null>,
  initialDomain: Domain = [0, 0]
): Domain => {

  const accessor = (
    valueAccessor || getValue
  ) as Accessor<D, number | null>

  return (
    data.reduce( (acc, d) => {
      acc[0] = Math.min(acc[0], accessor(d) || 0)
      acc[1] = Math.max(acc[1], accessor(d) || 0)
      return acc
    }, initialDomain)
  )
}


export const getMirrorDomain = <D = HasValue>(
  data: D[],
  valueAccessor?: Accessor<D, number | null>,
  initialDomain: Domain = [0, 0]
): Domain => {
  const [min, max] = getDomain(data, valueAccessor, initialDomain)
  const maxPeak = Math.max(Math.abs(min), Math.abs(max))
  return [maxPeak * -1, maxPeak]
}


export const isValidDomain = ([min, max]: Domain): boolean => Boolean(
  !( min === 0 && max === 0 )
  && isFinite(min)
  && isFinite(max)
)


export const ensureValidDomain = (domain: Domain): Domain => {
  if( !isValidDomain(domain) ){
    return [0, 1]
  }

  return domain
}


export function getMergedLabels<T extends SVGDatumType = SVGDatumType>(
  datasets: MultiDataset<T>[],
  sort = true
): T['label'][] {
  const labels = uniq(
    flatten(
      datasets.map( d => d.data.map(getLabel))
    )
  )
  if( sort ){
    labels.sort()
  }
  return labels
}


export function filterZeroDatasets<T extends SVGDatumType = SVGDatumType>(
  datasets: MultiDataset<T>[]
): MultiDataset<T>[] {
  return datasets.filter( ({ data }) => sum(data.map(getNumberValue)) > 0 )
}


export function getDatasetTotal<T extends SVGDatumType = SVGDatumType>(
  data: ChartData<T>
): number {
  return sum(data.map(getNumberValue))
}
