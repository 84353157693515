import React from 'react'

import { ChannelLayout } from './ChannelLayout'

import { useLocationParams, usePlatformUnit } from '@percept/hooks'

import { Diagnostics } from '../components/Diagnostics'

import { providerChannelMap } from '@percept/constants'

import { ChannelKey, PlatformUnit, ReportProviderV2 } from '@percept/types'


const getInsightsProviders = (unit: PlatformUnit | null): ReportProviderV2[] => {
  if( !unit ){
    return []
  }
  return (unit.report_series || []).reduce( (acc, s) => {
    if( s.output_config.recommendations_report ){
      acc.push(s.provider.slug)
    }
    return acc
  }, [] as ReportProviderV2[])
}


export const InsightsScreen = (): JSX.Element => {

  const { org_unit_id } = useLocationParams()

  const [{ data }] = usePlatformUnit({ org_unit_id })

  const providers = getInsightsProviders(data)

  const channel: ChannelKey | null = (
    providers.length === 1 ?
      providerChannelMap[providers[0]] :
      null
  )

  return (
    <ChannelLayout channel={channel}>
      <Diagnostics />
    </ChannelLayout>
  )
}

export default InsightsScreen
