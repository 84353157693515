import React, { useContext, useEffect, useRef, useState } from 'react'

import {
  Box,
  ButtonDialog,
  ButtonPopover,
  Checkbox,
  FormControlLabel,
  Loader,
  RoundedPlainTextButton,
  RoundedPlainTextButtonMenu,
  Typography,
  makeAppStyles,
  useAppTheme,
} from '@percept/mui'
import { ArrowDropDown, ChevronRightThin, CloudDownload, InfoOutlined } from '@percept/mui/icons'

import { TimeComparisonFilter, TimeComparisonValue } from './TimeComparisonFilter'

import { MultiMarketSelect } from 'components/Selects'

import { SmartCampaignAssessmentUpload } from './SmartCampaignAssessmentUpload'

import { SmartCampaignReportType, useSmartCampaignAssesmentQuadrantReport, useSmartCampaignFilters } from './hooks'

import { get, some, sortBy } from 'lodash-es'

import { isNullTimeRange, parseFilterDateRange, parseTimeRange, quadrantReportsToChartData } from './lib'

import { QuadrantType } from '@percept/types'
import { VODAFONE_GLOBAL_ID, VodafoneMarket, legacyMarkets, partnerMarkets, vodafoneMarkets } from 'vodafoneMarkets'
import { SmartCampaignListing } from './SmartCampaignListing'
import { SmartCampaignInfographic } from './SmartCampaignInfographic'
import { QuadrantReport } from './QuadrantCharts/QuadrantReport'
import { UserPrivilegeContext } from '@percept/app-components'
import { downloadSVG, produceKeyedMapping, userHasOrgPrivileges } from '@percept/utils'
import { format } from 'date-fns'
import { quadrantHealth } from './QuadrantCharts'


const useStyles = makeAppStyles( theme => ({
  title: {
    fontSize: 38,
    lineHeight: 1.125,
    fontWeight: 700,
    marginBottom: theme.spacing(4),
  },
  text: {
    fontSize: 18,
    lineHeight: 1.35,
    maxWidth: '68em',
  },
  control: {
    marginRight: theme.spacing(2),
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  checkbox: {
    marginLeft: theme.spacing(2),
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.35)',
  },
  chartHeader: {
    marginBottom: theme.spacing(1),
  },
  legend: {
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  legendItemSeparator: {
    color: theme.palette.action.disabled,
    marginRight: theme.spacing(1),
  },
  legendItemSmall: {
    marginRight: theme.spacing(0.5),
  },
  legendDateRangeSeparator: {
    fontSize: 13,
    margin: theme.spacing(0, 0.25),
  },
}))


type ComparisonType = 'vodafone' | 'vodafoneVsCompetitor' | 'competitor'

const comparisonLabels: Record<ComparisonType, string> = {
  vodafone: 'Vodafone',
  vodafoneVsCompetitor: 'Vodafone vs Competitors',
  competitor: 'Competitors',
}

const smartCampaignMarkets: VodafoneMarket[] = [
  ...vodafoneMarkets,
  ...legacyMarkets,
  ...partnerMarkets,
]

const marketsById = produceKeyedMapping(smartCampaignMarkets, 'id')

const getVodafoneMarkets = (marketIds: string[]): VodafoneMarket[] => {
  const markets = marketIds.reduce( (acc, id) => {
    if( marketsById[id] ){
      acc.push(marketsById[id])
    }
    return acc
  }, [] as VodafoneMarket[])
  return sortBy(markets, 'ordinal_no')
}

const displayList = (items: string[]): string => {
  if( !items.length ){
    return ''
  }
  if( items.length === 1 ){
    return items[0]
  }
  const commaSeparated = [
    ...items.slice(0, items.length - 1),
  ].join(', ')
  return `${commaSeparated} and ${items[items.length - 1]}`
}


export const SmartCampaignAssessment = (): JSX.Element => {

  const classes = useStyles()

  const privileges = useContext(UserPrivilegeContext)

  const [comparisonType, setComparisonType] = useState<ComparisonType>('vodafone')

  const [marketIds, setMarketIds] = useState<string[]>([])

  const [flagsEnabled, setFlagsEnabled] = useState(true)

  const filters = useSmartCampaignFilters()

  const availableDateRange = parseFilterDateRange(filters.data)

  const [timeComparison, setTimeComparison] = useState<TimeComparisonValue>({
    baseline: [availableDateRange.start, availableDateRange.end],
    comparison: [null, null],
  })

  useEffect(() => {
    if( filters.data && isNullTimeRange(timeComparison.baseline) ){
      const parsedRange = parseFilterDateRange(filters.data)
      if( parsedRange.start && parsedRange.end ){
        setTimeComparison( prev => ({
          ...prev,
          baseline: [
            parsedRange.start,
            parsedRange.end
          ]
        }))
      }
    }
  }, [timeComparison, filters.data])

  useEffect(() => {
    if( filters.data && !marketIds.length ){
      setMarketIds(filters.data.market_ids)
    }
  }, [filters.data, marketIds])

  const availableMarkets = filters.data && getVodafoneMarkets(filters.data.market_ids) || []

  const selectedMarkets = availableMarkets.filter( m => marketIds.includes(m.id) )

  const allMarketsSelected = selectedMarkets.length === availableMarkets.length

  const [activeQuadrant, setActiveQuadrant] = useState<QuadrantType | null>(null)

  const baselineReportType: SmartCampaignReportType = comparisonType === 'competitor' ? 'COMPETITOR' : 'VODAFONE'

  const baselineCampaignAssessmentHook = useSmartCampaignAssesmentQuadrantReport({
    ...parseTimeRange(timeComparison.baseline),
    markets: marketIds,
    reportType: baselineReportType,
  })

  const comparisonTimeRange = comparisonType !== 'vodafoneVsCompetitor' ? timeComparison.comparison : timeComparison.baseline
  const comparisonReportType: SmartCampaignReportType = comparisonType === 'vodafone' ? 'VODAFONE' : 'COMPETITOR'

  const comparisonCampaignAssessmentHook = useSmartCampaignAssesmentQuadrantReport({
    ...parseTimeRange(comparisonTimeRange),
    markets: marketIds,
    reportType: comparisonReportType,
  })

  const isError = some(
    [baselineCampaignAssessmentHook, comparisonCampaignAssessmentHook],
    hook => hook.isError
  )

  const hasComparisonRange = (
    comparisonType === 'vodafoneVsCompetitor' || !isNullTimeRange(timeComparison.comparison)
  )

  const baselineCampaignCount = get(baselineCampaignAssessmentHook.data, 'total_campaign_count', null)

  const comparisonCampaignCount = (
    !hasComparisonRange ? null : get(comparisonCampaignAssessmentHook.data, 'total_campaign_count', null)
  )

  const chartData = (
    baselineCampaignAssessmentHook.data
    && (!hasComparisonRange ? true : comparisonCampaignAssessmentHook.data)
    && quadrantReportsToChartData({
      baseline: baselineCampaignAssessmentHook.data,
      comparison: comparisonCampaignAssessmentHook.data || null,
    })
    || null
  )

  const canUploadData = (
    userHasOrgPrivileges(
      ['vfSmartCampaignAssessment.edit'],
      VODAFONE_GLOBAL_ID,
      privileges.org_privileges,
    )
  )

  const appTheme = useAppTheme()

  const chartRef = useRef<SVGSVGElement | null>(null)

  const marketLabel = allMarketsSelected ? 'All Markets' : displayList(
    selectedMarkets.map( m => m.name )
  )

  return (
    <Box p={3}>
      <Typography variant='h1' className={classes.title}>Welcome to Smart Campaign Assessment</Typography>
      <Typography className={classes.text}>
        Smart Campaign Assessments are research driven reports that determine the performance and effectiveness of campaigns in
        driving consumer consideration and action. Of all the metrics tested, <strong>Ad Imprint</strong> has the greatest correlation
        to brand consideration, whilst <strong>Triggers</strong> determine the short-term action consumers are likely to take as a
        result of seeing the campaign. To drive future brand growth as well as short term action, campaigns should deliver on both metrics.
      </Typography>
      <Box mt={1}>
        <Typography className={classes.text}>
          The below quadrant chart reports on the effectiveness of both Vodafone and Competitor campaigns in doing so.
        </Typography>
      </Box>

      <Box my={4} display='flex' justifyContent='space-between' alignItems='center'>
        <Box display='flex' alignItems='center'>
          <RoundedPlainTextButtonMenu
            TriggerProps={{
              className: classes.control,
              size: 'small',
              variant: 'contained',
              color: 'secondary',
              endIcon: <ArrowDropDown />,
            }}
            value={comparisonType}
            label={comparisonLabels[comparisonType]}
            options={
              (['vodafone', 'vodafoneVsCompetitor', 'competitor'] as ComparisonType[]).map( value => ({
                value,
                label: comparisonLabels[value],
              }))
            }
            onChange={(e, value): void => {
              setComparisonType(value)
            }} />

          <MultiMarketSelect
            ButtonPopoverProps={{
              className: classes.control,
              color: 'secondary',
              size: 'small',
              buttonContent: 'Market Filter',
            }}
            value={marketIds}
            options={
              filters.data && getVodafoneMarkets(filters.data.market_ids) || []
            }
            onChange={(value): void => {
              setMarketIds(value)
            }} />

          <ButtonPopover
            className={classes.control}
            variant='contained'
            color='secondary'
            endIcon={<ArrowDropDown />}
            size='small'
            ButtonComponent={RoundedPlainTextButton}
            buttonContent='Time Filter'>
            { ({ onClose }): JSX.Element => (
              <TimeComparisonFilter
                comparisonEnabled={comparisonType !== 'vodafoneVsCompetitor'}
                value={timeComparison}
                onChange={(value): void => {
                  setTimeComparison(value)
                  onClose()
                }}
                minDate={availableDateRange.start as Date}
                maxDate={availableDateRange.end as Date} />
            )}
          </ButtonPopover>

          <FormControlLabel
            className={classes.checkbox}
            label='Show Flags'
            control={<Checkbox />}
            checked={flagsEnabled}
            onChange={(e, checked): void => {
              setFlagsEnabled(checked)
            }} />
        </Box>

        { chartData && (
          <Box>
            <ButtonDialog
              ButtonComponent={RoundedPlainTextButton}
              buttonContent='Chart Explainer'
              startIcon={<InfoOutlined />}
              size='small'
              className={classes.control}
              DialogProps={{
                maxWidth: false,
                BackdropProps: {
                  classes: {
                    root: classes.backdrop,
                  }
                }
              }}
              variant='contained'>
              <SmartCampaignInfographic />
            </ButtonDialog>

            {/* NOTE - currently commented until we add titling and legends to the SVG */}
            {/* <RoundedPlainTextButton
              className={classes.control}
              size='small'
              color='secondary'
              variant='contained'
              startIcon={<CloudDownload />}
              onClick={(): void => {
                if( chartRef.current ){
                  downloadSVG(chartRef.current, 'smart-campaign-assessment.svg').then(console.log)
                }
              }}>
              Export Chart
            </RoundedPlainTextButton> */}

            { canUploadData && (
              <SmartCampaignAssessmentUpload
                ButtonProps={{
                  className: classes.control,
                  size: 'small'
                }} />
            )}
          </Box>
        )}
      </Box>
      
      { isError ? (
        <Box p={6}>
          <Typography variant='h5'>
            Quadrant report could not be loaded
          </Typography>
        </Box>
      ) : !chartData ? (
        <Loader preset='fullsize' minHeight={700} />
      ) : (
        <Box display='flex' flexBasis='100%' width='100%' flexDirection='column' alignItems='center'>
          <Typography
            className={classes.chartHeader}
            variant='h5'>
            { marketLabel }
          </Typography>

          <QuadrantReport
            {...chartData}
            svgRef={chartRef}
            flagsEnabled={flagsEnabled}
            onQuadrantClick={setActiveQuadrant}
            width={700}
            height={700} />

          <Box mt={2} pl={'50px'} display='flex' flexDirection='column' alignItems='flex-start' width={700}>
            { baselineCampaignCount !== null && (
              <Typography className={classes.legend}>
                <svg className={classes.legendItem} width={24} height={24}>
                  <rect
                    width='50%'
                    height='50%'
                    x={0}
                    y={0}
                    fill={appTheme.chart.healthColourScale(quadrantHealth.top_left)} />
                  <rect
                    width='50%'
                    height='50%'
                    x='50%'
                    y={0}
                    fill={appTheme.chart.healthColourScale(quadrantHealth.top_right)} />
                  <rect
                    width='50%'
                    height='50%'
                    x='50%'
                    y='50%'
                    fill={appTheme.chart.healthColourScale(quadrantHealth.bottom_right)} />
                  <rect
                    width='50%'
                    height='50%'
                    x={0}
                    y='50%'
                    fill={appTheme.chart.healthColourScale(quadrantHealth.bottom_left)} />
                </svg>
                <strong className={classes.legendItem}>Baseline</strong>
                <span className={classes.legendItemSeparator}>|</span>
                <span className={classes.legendItem}>
                  { comparisonType === 'competitor' ? 'Competitor' : 'Vodafone'}
                </span>
                { !isNullTimeRange(timeComparison.baseline) && (
                <>
                  <span className={classes.legendItemSeparator}>|</span>
                  <span className={classes.legendItem}>
                    { format(timeComparison.baseline[0] as Date, 'MMMM yyyy')}
                    <ChevronRightThin fontSize='inherit' className={classes.legendDateRangeSeparator} />
                    { format(timeComparison.baseline[1] as Date, 'MMMM yyyy')}
                  </span>
                </>
                )}
                <span className={classes.legendItemSeparator}>|</span>
                {baselineCampaignCount} Campaigns
              </Typography>
            )}
            { comparisonCampaignCount !== null && (
              <Typography className={classes.legend}>
                <svg className={classes.legendItem} width={24} height={24}>
                  <rect width={22} height={22} x={1} y={1} stroke='#000' strokeWidth={2} strokeDasharray='6 3' fill='none' />
                </svg>
                <strong className={classes.legendItem}>Comparison</strong>
                <span className={classes.legendItemSeparator}>|</span>
                <span className={classes.legendItem}>
                  { comparisonType === 'vodafone' ? 'Vodafone' : 'Competitor'}
                </span>
                { !isNullTimeRange(comparisonTimeRange) && (
                <>
                  <span className={classes.legendItemSeparator}>|</span>
                  <span className={classes.legendItem}>
                    { format(comparisonTimeRange[0] as Date, 'MMMM yyyy')}
                    <ChevronRightThin fontSize='inherit' className={classes.legendDateRangeSeparator} />
                    { format(comparisonTimeRange[1] as Date, 'MMMM yyyy')}
                  </span>
                </>
                )}
                <span className={classes.legendItemSeparator}>|</span>
                {comparisonCampaignCount} Campaigns
              </Typography>
            )}
          </Box>
        </Box>
      )}

      { activeQuadrant && (
        <SmartCampaignListing
          quadrant={activeQuadrant}
          markets={marketIds}
          marketLabel={marketLabel}
          baseline={{
            dateRange: timeComparison.baseline,
            reportType: baselineReportType,
          }}
          comparison={
            !hasComparisonRange ?
              null : {
                dateRange: comparisonTimeRange,
                reportType: comparisonReportType,
              }
          }
          onClose={(): void => setActiveQuadrant(null)} />
      )}

    </Box>
  )
}
