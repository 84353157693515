
import React from 'react'

import { ThemeProvider, ThemeProviderProps, useTheme } from '@material-ui/core'

import { AppTheme } from './themeAugmentation'


export type AppThemeProviderProps = ThemeProviderProps<AppTheme>


export function AppThemeProvider(props: AppThemeProviderProps): JSX.Element {
  return (
    <ThemeProvider
      {...props} />
  )
}


export function useAppTheme(): AppTheme {
  return useTheme<AppTheme>()
}
