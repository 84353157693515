
import React, { useMemo } from 'react'

import { Box } from '@material-ui/core'

import { Loader } from '../Loader'

import { MetricVisualisationWrap, MetricCard } from '../Cards'

import { ErrorMetric, NotApplicable } from './ErrorMetrics'

import { DistributionMetricVisualisation, presets } from './DistributionMetricVisualisation'

import MetricDebug from './MetricDebug'

import { MetricTable } from './MetricTable'

import { PlainTextButton } from '../Buttons'

import { ButtonDialog } from '../Dialogs'

import { TableChart, ZoomIn } from '../../icons'

import { useCommonMetricStyles } from './styles'

import {
  displayMappings,
  metricContainsSignal
} from './lib'

import {
  isNumber,
  parseDimension,
} from '@percept/utils'

import { every, get, intersection } from 'lodash-es'

import {
  CommonMetricRendererProps,
  MetricProps,
  VisualisationContainer,
} from './typings'

import { DistributionMetric as DistributionMetricType } from '@percept/types'


const { APP, WORKLOAD_TYPE } = process.env

const DEBUG = APP === 'admin' || WORKLOAD_TYPE !== 'PROD'

const defaultDimensionOptions = ['count']


export type DistributionMetricProps = (
  Omit<
    MetricProps,
    'metric' | 'metric_type'
  > & {
    metric: DistributionMetricType | null
  }
)

export const DistributionMetric = ({
  id,
  metric,
  previousHealth,
  loadingPrevious,
  title,
  description,
  annotations,
  dimensionOptions = defaultDimensionOptions,
  loading,
  debug = false,
  displayType = 'DETAIL',
  onAnnotateMetric,
  setActiveMetric,
  containerRef,
  tipSettings,
  onToggleTip,
  tipPriority,
  WrapComponent,
  LoaderProps = {},
  MetricCardProps = {},
  ...props
}: DistributionMetricProps): JSX.Element => {

  const displayOptions = displayMappings[displayType],
        { showDescriptions } = displayOptions

  const visualisationType = props.display_options.chart_type === 'donut' ? 'donut' : 'histogram'

  const sizeProps = presets[displayType][visualisationType]

  const onActivateDetail: CommonMetricRendererProps['onActivateDetail'] = useMemo(() => {
    return typeof setActiveMetric === 'function' ? (
      (params): void => {
        const { segment } = params || {}
        if( (!segment || !isNumber(segment.value) || segment.value > 0) ){
          setActiveMetric({
            metric_id: id,
            dimension: 'count',
            title,
            ...(params || {})
          })
        }
      }
    ) : undefined
  }, [setActiveMetric, id, title])

  const classes = useCommonMetricStyles()

  const availableDimensions = (
    metric ?
      intersection(
        dimensionOptions,
        Object.keys(metric.dimensions)
      ) :
      defaultDimensionOptions
  )

  const visualisations: VisualisationContainer[] = availableDimensions.map( dimension => {

    const isNotApplicable = (
      !metric
      || !metric.dimensions[dimension]
      || !metricContainsSignal(metric, dimension)
    )

    const parsedDimension = parseDimension(dimension)

    const { label } = parsedDimension

    return {
      key: dimension,
      dimension,
      label,
      isNotApplicable,
      content: (
        loading ? (
          <Loader
            preset='fullsize'
            {...sizeProps}
            {...LoaderProps} />
        ) : metric === null ? (
          <ErrorMetric
            className={classes.muted} />
        ) : isNotApplicable ? (
          <NotApplicable
            className={classes.muted} />
        ) : (
          <DistributionMetricVisualisation
            id={id}
            title={title}
            displayType={displayType}
            visualisationType={visualisationType}
            {...sizeProps}
            {...props}
            segments={metric.dimensions[dimension].segments}
            dimension={dimension}
            onActivateDetail={onActivateDetail} />
        )
      )
    }
  })

  if( WrapComponent ){
    return (
      <WrapComponent
        containerRef={containerRef}>
        { visualisations.map(({ content }) => content ) }
      </WrapComponent>
    )
  }

  const health = get(metric, ['dimensions', 'count', 'health'], null)



  return (
    <MetricCard
      title={title}
      description={showDescriptions && description || undefined}
      health={health}
      showHeader={true}
      actionPopoverDisabled={
        every(visualisations, v => v.isNotApplicable )
      }
      actionPopoverContent={
        ({ onClose }): JSX.Element => (
          <Box p={2} display='flex' flexDirection='column'>
            <PlainTextButton
              className={classes.actionButton}
              variant='contained'
              size='small'
              startIcon={<ZoomIn />}
              onClick={(): void => {
                setActiveMetric && setActiveMetric({
                  metric_id: id,
                  dimension: 'count',
                  title,
                })
              }}>
              View Detail
            </PlainTextButton>
            { !!(availableDimensions.length > 1 && metric) && (
              <ButtonDialog
                className={classes.actionButton}
                variant='contained'
                size='small'
                ButtonComponent={PlainTextButton}
                startIcon={<TableChart />}
                buttonContent='View as Table'
                onClose={onClose}
                DialogProps={{
                  keepMounted: false,
                  maxWidth: 'xl',
                  BackdropProps: {
                    classes: {
                      root: classes.dashboardBackdrop
                    },
                  },
                }}>
                <MetricTable
                  metric={metric}
                  health={health}
                  availableDimensions={availableDimensions}
                  currency={props.currency || null}
                  display_options={props.display_options}
                  title={title} />
              </ButtonDialog>
            )}
          </Box>
        )
      }
      debugContent={
        DEBUG && (
          <MetricDebug id={id} />
        ) || null
      }
      {...MetricCardProps}
      containerRef={containerRef}>

      { visualisations.map( ({ label, isNotApplicable, dimension, content }, i) => {

        return (
          <MetricVisualisationWrap
            key={i}
            label={label}
            disabled={isNotApplicable || metric === null}
            onClick={
              (!isNotApplicable && setActiveMetric) ? (
                (): void => {
                  setActiveMetric({
                    metric_id: id,
                    dimension,
                    title,
                  })
                }
              ) : undefined
            }>
            
            { content }

          </MetricVisualisationWrap>
        )
      })}

    </MetricCard>
  )
}
