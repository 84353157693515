
import auditPayload from './v1.3'

import { getPath, any } from '@percept/utils'


const getAnyObjectValue = obj => obj[Object.keys(obj)[0]]

// Test a metric from the audit to determine if examples are inline 
const hasInlineExamples = payload => {
  const auditEntities = getPath(payload, ['audit', 'entities'], {})

  const testType = getAnyObjectValue(auditEntities || {}) || {},
        testEntity = getAnyObjectValue(testType) || {},
        testMetric = getAnyObjectValue(testEntity.metrics || {}) || {},
        testDimension = getAnyObjectValue(testMetric) || {},
        testData = testDimension.data || []

  return any(testData, d => typeof d.examples !== 'undefined' )
}

export default payload => ({
  ...auditPayload(payload),
  hasExamples: hasInlineExamples(payload)
})
