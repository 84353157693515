import React from 'react'

import { DefaultLayout } from './DefaultLayout'

import { MediaQualityRate } from '../components/MediaQualityRate'


export const MediaQualityRateScreen = (): JSX.Element => {
  return (
    <DefaultLayout>
      <MediaQualityRate />
    </DefaultLayout>
  )
}

export default MediaQualityRateScreen
