import React from 'react'

import { Language, OpenInNew } from '@percept/mui/icons'

import { Flag, FlagName } from 'components/BrandGrowthQuadrantChart'

import vodafoneShortLogo from '../../images/logos/vodafone-logo-short.svg'


export const getMarketLabel = (marketName: string): JSX.Element => {
  let icon: React.ReactNode = false
  const isoCode = marketName.split(' ')[1]
  if( isoCode === 'Group' ){
    icon = <Language fontSize='small' style={{marginRight: 6, marginLeft: -2}} />
  }else{
    icon = (
      <Flag
        isoCode={isoCode as FlagName}
        size={16}
        rounded
        border='#666'
        style={{marginRight: 8}} />
    )
  }
  return (
    <span style={{display: 'flex', alignItems: 'center'}}>
      { icon }
      { marketName }
    </span>
  )
}


export const getUserTypeLabel = (userType: string): JSX.Element => {
  let icon: React.ReactNode = false
  if( userType === 'Vodafone User' ){
    icon = <img style={{height: 18, width: 18, marginRight: 6}} src={vodafoneShortLogo} />
  }else if( userType === 'Agency User' ){
    icon = <OpenInNew fontSize='small' style={{marginRight: 6, marginLeft: -2}} />
  }
  return (
    <span style={{display: 'flex', alignItems: 'center'}}>
      { icon }
      { userType }
    </span>
  )
}
