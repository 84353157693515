
import React, { useState, useRef } from 'react'

import { Input, InputProps, InputAdornment } from '@material-ui/core'

import { Close, Search } from '../../icons'

import { debounce } from 'lodash-es'


type InputPayload = { value: string }

type InputEvent = React.ChangeEvent<InputPayload>

export type LazySearchProps = Omit<InputProps, 'onChange'> & {
  onChange: (e: InputEvent) => void
  onReset?: () => void
  delay?: number
}

export const LazySearch = ({ value, onChange, delay = 50, onReset, ...props }: LazySearchProps): JSX.Element => {

  const throttledChange = useRef<React.ChangeEventHandler<InputPayload> | null>(null)

  if( !throttledChange.current ){
    throttledChange.current = debounce((e: React.ChangeEvent<InputPayload>): void => {
      onChange(e)
    }, delay)
  }

  const [localQuery, setLocalQuery] = useState(value)

  const handleChange = (e: React.ChangeEvent<InputPayload>): void => {
    setLocalQuery(e.target.value)
    if( throttledChange.current ){
      e.persist()
      throttledChange.current(e)
    }
  }

  return (
    <Input
      autoFocus
      startAdornment={
        <InputAdornment position='start'>
          { !localQuery || !onReset ?
            <Search /> :
            <Close
              style={{cursor: 'pointer'}}
              onClick={(): void => {
                onReset()
                setLocalQuery('')
              }} /> }
        </InputAdornment>
      }
      {...props}
      value={localQuery}
      onChange={handleChange} />
  )

}

export default LazySearch
