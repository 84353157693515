import { find, sortBy } from 'lodash-es'

import { useReportSeriesReports } from '@percept/hooks'

import { getReportResultHealth } from '@percept/utils'

import { providerChannelMap } from '@percept/constants'

import { ChannelKey, HealthType, ReportSeries } from '@percept/types'


type InsightReportMetadata = {
  loading: boolean
  health: HealthType
  trending: 'up' | 'down' | null
}


const getTrend = (latest: HealthType, previous: HealthType): 'up' | 'down' | null => {
  if( latest !== null && previous !== null ){
    return (latest - previous) < 0 ? 'down' : 'up'
  }
  return null
}


export const useInsightReportMetadata = (
  seriesListing: ReportSeries[] | null,
  channel: ChannelKey
): InsightReportMetadata => {

  const matchingSeries = find(
    seriesListing,
    s => providerChannelMap[s.provider.slug] === channel
  ) || null

  const series_id = matchingSeries && matchingSeries.id

  const [reports] = useReportSeriesReports({
    series_id,
  })

  const sortedReports = sortBy(
    (reports.data || []).filter( r => !!r.results.length ),
    r => new Date(r.end).getTime()
  ).reverse()

  const health = sortedReports[0] ? getReportResultHealth(sortedReports[0]) : null
  const previousHealth = sortedReports[1] ? getReportResultHealth(sortedReports[1]) : null

  return {
    loading: reports.loading || (!reports.data && !reports.error),
    health,
    trending: getTrend(health, previousHealth),
  }

}
