import React, { Fragment, useRef, useState } from 'react'

import { Box, ButtonProps, FormControlLabel, Popover, Radio, RadioGroup, RoundedPlainTextButton } from '@percept/mui'

import { ArrowDropDown, CloudDownload } from '@percept/mui/icons'

import { usePlatformUnit } from '@percept/hooks'

import { downloadCSV } from '@percept/utils'

import { PotentialEfficiencyDataset, generatePotentialEfficiencyCSV } from './datasets'

import { getPotentialEfficiencyLabel } from 'components/Organisation'

import { capitalize } from 'lodash-es'


export type DownloadTriggerProps = {
  org_unit_id: string | null
  datasets: PotentialEfficiencyDataset[]
  currency: string | null
} & Omit<ButtonProps, 'onClick'>

export const DownloadTrigger = ({
  org_unit_id,
  datasets,
  currency,
  ...props
}: DownloadTriggerProps) => {

  const [dimension, setDimension] = useState<'cost' | 'percentage'>('cost')

  const [platformUnit] = usePlatformUnit({ org_unit_id })

  const [open, setOpen] = useState(false)

  const buttonRef = useRef<HTMLButtonElement | null>(null)

  return (
    <Fragment>
      <RoundedPlainTextButton
        ref={buttonRef}
        size='small'
        variant='contained'
        endIcon={<ArrowDropDown />}
        {...props}
        onClick={(): void => {
          setOpen( prev => !prev )
        }}>
        Download CSV
      </RoundedPlainTextButton>
      <Popover
        open={open}
        onClose={(): void => {
          setOpen(false)
        }}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}>
        <Box p={2}>
          <RadioGroup
            value={dimension}
            onChange={(_, value): void => {
              setDimension(value as 'cost' | 'percentage')
            }}>
            <FormControlLabel value='cost' label='Spend' control={<Radio />} />
            <FormControlLabel value='percentage' label='Percentage' control={<Radio />} />
          </RadioGroup>
          <Box mt={3} mb={2}>
            <RoundedPlainTextButton
              size='small'
              variant='contained'
              color={props.color}
              startIcon={<CloudDownload />}
              onClick={(): void => {
                if( platformUnit.data ){
                  const label = getPotentialEfficiencyLabel(platformUnit.data)
                  let filename = `${platformUnit.data.name} - ${label} - ${capitalize(dimension)}`
                  if( dimension === 'cost' && currency ){
                    filename += ` - ${currency}`
                  }
                  const data = generatePotentialEfficiencyCSV({
                    datasets,
                    dimension,
                  })
                  downloadCSV({ filename, data })
                }
                setOpen(false)
              }}>
              Download
            </RoundedPlainTextButton>

          </Box>
        </Box>
      </Popover>
    </Fragment>
  )
}
