import React from 'react'


export type FallbackProps = { resetError?: () => void }

export type FallbackComponent = (props: FallbackProps) => JSX.Element

type ErrorBoundaryProps = React.PropsWithChildren<{
  reportError?: (reportErrorProps: { error: Error, errorInfo: React.ErrorInfo }) => void
  FallbackComponent?: FallbackComponent
  fallbackContent?: JSX.Element | React.ReactNode
}>

type ErrorBoundaryState = { hasError: boolean }


export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.warn(error)
    if( this.props.reportError ){
      this.props.reportError({ error, errorInfo })
    }
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      const { fallbackContent, FallbackComponent } = this.props
      if( fallbackContent ){
        return fallbackContent
      }
      if( !FallbackComponent ){
        return null
      }
      return <FallbackComponent resetError={(): void => this.setState({ hasError: false })} />
    }

    return this.props.children
  }
}
