import React from 'react'

import { TickLabelProps } from '@visx/axis'

import { NumberLike } from '@visx/scale'

import { AppTheme } from '../themes'


export type ExtensibleTextProps = {
  textAnchor: 'end' | 'middle' | 'start' | 'inherit'
  angle: number
  width: number
} & Pick<
  React.SVGAttributes<SVGTextElement>, 'dominantBaseline' | 'fontSize' | 'dx' | 'dy'
>


type HigherOrderThemedLabelPropsGenerator = (
  appTheme: AppTheme,
  extensions?: Partial<ExtensibleTextProps>
) => TickLabelProps<string | NumberLike>


export const makeGetYTickLabelProps: HigherOrderThemedLabelPropsGenerator = (
  (appTheme, extensions = {}) => (
    () => ({
      dx: -8,
      textAnchor: 'end',
      fontSize: 10,
      dominantBaseline: 'central',
      fill: appTheme.palette.text.primary,
      ...extensions,
    })
  )
)


export const makeGetXTickLabelProps: HigherOrderThemedLabelPropsGenerator = (
  (appTheme, extensions = {}) => (
    () => ({
      textAnchor: 'middle',
      fontSize: 10,
      dominantBaseline: 'hanging',
      fill: appTheme.palette.text.primary,
      ...extensions,
    })
  )
)
