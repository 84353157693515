import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { replacePrefillPlaceholders } from '../../pages/submit-analysis/utils'
import brandGrowthPlannerApi from '..'
import { ReportSchemaWithDetails } from './types'

export const ReportSchemaService = {
  useReportSchema: (
    marketId?: string,
    options?: Omit<
      UseQueryOptions<ReportSchemaWithDetails, AxiosError>,
      'queryKey' | 'queryFn'
    >
  ): UseQueryResult<ReportSchemaWithDetails, AxiosError> =>
    useQuery<ReportSchemaWithDetails, AxiosError>(
      ['bgp', 'report-schema'],
      async (): Promise<ReportSchemaWithDetails> => {
        const { data } =
          await brandGrowthPlannerApi.get<ReportSchemaWithDetails>(
            `/markets/${marketId}/report-schema`
          )

        replacePrefillPlaceholders({
          fieldsets: [data.schema],
          placeholders: data.prefill_placeholders,
        })

        return data
      },
      {
        refetchOnWindowFocus: false,
        enabled: marketId !== undefined,
        ...options,
      }
    ),
}
