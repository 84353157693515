import React from 'react'
import {
  CellRenderers,
  Column,
  SimpleTable,
  TableRow,
  makeAppStyles,
} from '@percept/mui'
import { TableCells } from './TableCells'
import { format } from 'date-fns'


export type SubmissionMonth = {
  isUpdated: boolean
  date?: string
}

export type TableRow = {
  market: string
  agency: string
  department: string
} & Record<string, SubmissionMonth>

const useTableClasses = makeAppStyles( theme => ({
  tableCell: {
    fontSize: 14,
    padding: theme.spacing(0.75),
  },
  headerCell: {
    fontSize: 14,
    padding: theme.spacing(1, 0.75),
    fontWeight: 700,
  }
}))

const getSubmissionMonthSortValue = (value: SubmissionMonth): string | undefined => value.date

export const SubmissionTrackerTable = ({ rows, validMonths }: { rows: TableRow[], validMonths: Date[] }): JSX.Element => {
  const monthKeys = validMonths.map( m => format(m, 'yyyy-MM-dd'))

  const renderers = monthKeys.reduce((acc, monthName) => {
    /* eslint-disable react/display-name, react/prop-types */
    acc[monthName] = (row) => (
      <TableCells isUpdated={row[monthName].isUpdated} date={row[monthName].date}/>
    )
    /* eslint-enable react/display-name, react/prop-types */
    return acc
  }, {} as CellRenderers<TableRow>)

  const columns: Column<TableRow>[] = [
    {
      key: 'market',
      label: 'Market',
      align: 'left',
    },
    {
      key: 'agency',
      label: 'Agency',
      align: 'left',
    },
    {
      key: 'department',
      label: 'Department',
      align: 'left',
    },
    ...validMonths.map( (key) => ({
      key:  format(key, 'yyyy-MM-dd'), 
      label: format(key, 'MMM-yy'),
      align: 'center',
      getSortValue: getSubmissionMonthSortValue,
    })) as Column<TableRow>[],
  ]

  const tableClasses = useTableClasses()

  return (
    <SimpleTable
      sortable
      classes={tableClasses}
      columns={columns}
      renderers={renderers}
      rows={rows} />
  )
}
