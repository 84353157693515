import { AppTheme } from '@percept/mui'

import { get, pick } from 'lodash-es'

import { parse } from './dsl'

import {
  MetricMetadataType,
  InsightsReportFindingType,
  ReportEntityPayload,
  MetricIdentification,
  HealthType,
  InsightsReportRelatedMetric,
  InsightsReportOutputUnitType,
  InsightsReportType,
  PotentialEfficiencyInsightConfiguration,
} from '@percept/types'


export type InsightOutputSummary = Pick<
  InsightsReportFindingType, 'id' | 'text' | 'health' | 'entities'
> & {
  title?: string | null
}

export type ItemParent = {
  text?: string | null
  health: HealthType
}


export type ItemRendererDependencies = {
  appTheme: AppTheme
  currency: string | null
  metricMetadata: MetricMetadataType
  reportEntity: ReportEntityPayload
  potentialEfficiencyConfigurations?: Record<string, PotentialEfficiencyInsightConfiguration>
  setActiveMetric: (metricIdent: MetricIdentification) => void
  setActiveOutput: (output: InsightOutputSummary) => void
  setActiveOutputUnit: (outputUnit: (InsightsReportOutputUnitType & { id?: string | null }) | null) => void
}


export type ItemRenderer<Item extends object> = (
  props: Item & ItemRendererDependencies
) => JSX.Element


export function getItemRendererDependencies<T>(props: T & ItemRendererDependencies): ItemRendererDependencies {
  return pick(
    props, 
    [
      'appTheme',
      'currency',
      'metricMetadata',
      'potentialEfficiencyConfigurations',
      'reportEntity',
      'setActiveMetric',
      'setActiveOutput',
      'setActiveOutputUnit'
    ]
  )
}


export const parseRelatedMetric = (objectOrString: string | InsightsReportRelatedMetric): InsightsReportRelatedMetric => {
  if( typeof objectOrString === 'string'){
    return {
      metric_id: objectOrString,
    }
  }
  return objectOrString
}


export const isNullInsightsReport = ({ sections }: Pick<InsightsReportType, 'sections'>): boolean => {
  for( const section of sections ){
    for( const member of section.members ){
      if( member.entities && member.entities.length ){
        return false
      }
      for( const finding of member.findings ){
        if( finding.entities && finding.entities.length ){
          return false
        }
        const dslItems = parse(finding.text || '')
        for( const item of dslItems ){
          if( item.type === 'ENTITY_COUNT' && get(item.dimensions, ['count', 'denominator', 'value']) ){
            return false
          }
        }
      }
    }
  }
  return true
}
