import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'

import {
  Box,
  CircularProgress,
  Dialog,
  DialogProps,
  Divider,
  Grid,
  RoundedPlainTextButton,
  RoundedPlainTextButtonMenu,
  Typography,
  makeAppStyles,
} from '@percept/mui'

import { ArrowDropDown, Block, Check, CheckCircle, Close, DeleteForever, Person, PersonAdd } from '@percept/mui/icons'

import {
  TenantUserAttributeCheckboxComponent,
  TenantUserAttributeSelectComponent,
  TenantUserAttributeValueComponent,
} from './formComponents'

import { Formik, FormikErrors } from 'formik'

import { getInitialValues, getPayload, isBlueprint, isTenantUserAttributeSelect, isVodafoneGroupUser } from './formUtils'

import {
  TenantUserManagementListingUser,
  useEditPendingSSOUser,
  useEditTenantUser,
  useTenantUserManagementCreateSSOUser,
  useTenantUserManagementCreateUser,
  // useTenantUserManagementEstablishedUser,
} from '@percept/hooks'

import { UserPrivilegeContext } from '@percept/app-components'

import { isAdminUser, isValidEmail } from '@percept/utils'

import { ListingViewType, generateUserAttributesForComponent } from './lib'

import { TenantUserAttribute, TenantUserAttributeSelect, TenantUserManagementMetadata } from '@percept/types'


const useStyles = makeAppStyles( theme => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  darkenedBackdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  dialogTitleIcon: {
    marginRight: theme.spacing(1.5),
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  inputContainer: {
    marginBottom: theme.spacing(3),
  },
  submitButton: {
    marginLeft: theme.spacing(3),
  },
  formLabel: {
    display: 'block',
    marginBottom: theme.spacing(1),
  },
}))


export type UserManagementDialogProps = Omit<DialogProps, 'onClose'> & {
  tenantId: string
  userManagementMetadata: TenantUserManagementMetadata
  editUser?: TenantUserManagementListingUser | null
  editUserId?: string | null
  // setEditUser: (editUser: TenantUserManagementListingUser) => void
  editUserContext: ListingViewType
  setListingViewType: (listingViewType: ListingViewType) => void
  onClose: () => void
}


export const UserManagementDialog = ({
  tenantId,
  userManagementMetadata,
  onClose,
  editUser,
  // editUserId = null,
  editUserContext,
  setListingViewType,
  ...props
}: UserManagementDialogProps): JSX.Element => {
  const classes = useStyles()

  const privileges = useContext(UserPrivilegeContext)

  const isSuperAdmin = isAdminUser(privileges.user)

  const [deletionUserObject, setDeletionUserObject] = useState<TenantUserManagementListingUser | null>(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const deleteDialogCloseHandler = (): void => {
    setDeleteDialogOpen(false)
    // Add a delay to avoid flash of missing content
    setTimeout(() => {
      setDeletionUserObject(null)
    }, 200)
  }

  // const [editUserContextOverride, setEditUserContextOverride] = useState<ListingViewType | null>(null)

  // const editEstablishedUserHook = useTenantUserManagementEstablishedUser(
  //   tenantId, editUserId, { enabled: !!(editUserId && editUserContext === 'ACTIVE_USERS') }
  // )

  const userCreation = useTenantUserManagementCreateUser(tenantId)
  const ssoUserCreation = useTenantUserManagementCreateSSOUser(tenantId)

  // if( ssoUserCreation.error ){
  //   console.log('ERROR', ssoUserCreation.error.response)
  // }

  const userEdit = useEditTenantUser(tenantId)
  const ssoUserEdit = useEditPendingSSOUser(tenantId)

  const loading = (
    userCreation.isLoading
    || ssoUserCreation.isLoading
    || userEdit.isLoading
    || ssoUserEdit.isLoading
  )

  const onCloseRef = useRef(onClose)
  onCloseRef.current = onClose

  useEffect(() => {
    if( userCreation.data ){
      userCreation.reset()
      setListingViewType('ACTIVE_USERS')
      onCloseRef.current()
    }
    if( ssoUserCreation.data ){
      ssoUserCreation.reset()
      setListingViewType('PENDING_SSO_USERS')
      onCloseRef.current()
    }
    if( userEdit.data ){
      userEdit.reset()
      setListingViewType('ACTIVE_USERS')
      onCloseRef.current()
    }
    if( ssoUserEdit.data ){
      ssoUserEdit.reset()
      setListingViewType('PENDING_SSO_USERS')
      onCloseRef.current()
    }
  }, [userCreation, ssoUserCreation, userEdit, ssoUserEdit, setListingViewType])

  const tenantUserAttributes = generateUserAttributesForComponent(
    userManagementMetadata.attribute_groups,
    editUser,
  )
  const tenantUserOptions = userManagementMetadata.option_groups

  const DialogIconComponent = editUser ? Person : PersonAdd
  const dialogTitleText = editUser ? 'Edit User' : 'Add User'
  const dialogConfirmText = editUser ? 'Save Changes' : 'Add User'

  const initialValues = getInitialValues({
    attributes: tenantUserAttributes,
    options: tenantUserOptions,
    existingUserObject: editUser,
  })

  const enabledUserAttributes: TenantUserAttribute[] = tenantUserAttributes.filter( attr => attr.enabled )

  const enabledUserOptions: TenantUserAttributeSelect[] = tenantUserOptions.filter( attr => attr.enabled )

  type TenantUserFormState = typeof initialValues

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth='md'
        onClose={onClose}
        {...props}>
        <Box p={3}>
          <Formik
            initialValues={initialValues}
            validate={(values): FormikErrors<TenantUserFormState> => {
              const errors: FormikErrors<TenantUserFormState> = {}
              for( const attr of tenantUserAttributes ){
                if( attr.enabled ){
                  const currentValue = values[attr.id]
                  if( attr.name === 'Email' ){
                    if( !currentValue || !isValidEmail(currentValue.toLowerCase()) ){
                      errors[attr.id] = 'Email must be a valid email address'
                    }
                  }else if( !currentValue ){
                    if( attr.name === 'Name' && isBlueprint(tenantUserAttributes, values) ){
                      continue
                    }
                    errors[attr.id] = `${attr.name} is a required field`
                  }
                }
              }
              // Require at least one regular feature option on creation only
              if( !editUser ){
                for( const optionAttr of tenantUserOptions ){
                  if( optionAttr.enabled && optionAttr.slug === 'features' ){
                    const currentValue = values[optionAttr.id]
                    if( !currentValue || !currentValue.length ){
                      errors[optionAttr.id] = `At least one ${optionAttr.name} is required`
                    }
                  }
                }
              }
              return errors
            }}
            onSubmit={(values): void => {
              const payload = getPayload({
                attributes: tenantUserAttributes,
                options: tenantUserOptions,
                formValues: values
              })
              if( editUser ){
                const mutationHook = (
                  editUserContext === 'PENDING_SSO_USERS' ?
                    ssoUserEdit :
                    userEdit
                )
                mutationHook.mutate({ user_id: editUser.user_id, ...payload })
              }else{
                const mutationHook = (
                  isBlueprint(tenantUserAttributes, values) ?
                    ssoUserCreation :
                    userCreation
                )
                mutationHook.mutate(payload)
              }
            }}>
            { ({ values, setFieldValue, isValid, handleBlur, handleChange, handleSubmit }): JSX.Element => {

              const isSSOUser = isBlueprint(tenantUserAttributes, values)

              const isGroupUser = isVodafoneGroupUser(tenantUserAttributes, values)

              return (
                <form onSubmit={handleSubmit}>
                  <Typography variant='h4' className={classes.dialogTitle}>
                    <DialogIconComponent className={classes.dialogTitleIcon} />
                    {dialogTitleText}
                    {!!editUser && (
                      <RoundedPlainTextButtonMenu
                        TriggerProps={{
                          className: classes.marginLeftAuto,
                          color: 'secondary',
                          variant: 'contained',
                          startIcon: (
                            values.enabled ?
                              <CheckCircle /> :
                              <Block />
                          ),
                          endIcon: <ArrowDropDown />,
                        }}
                        label={values.enabled ? 'Enabled' : 'Disabled'}
                        value={values.enabled}
                        options={[
                          {value: true, label: 'Enabled'},
                          {value: false, label: 'Disabled'},
                        ]}
                        onChange={(e, value): void => {
                          setFieldValue('enabled', value)
                        }} />
                    )}
                    {/* {!!existingUser.data && (
                      <RoundedPlainTextButton
                        className={classes.deleteButton}
                        variant='contained'
                        color='primary'
                        startIcon={<DeleteForever />}
                        onClick={(): void => {
                          setDeletionUserObject(existingUser.data)
                          setDeleteDialogOpen(true)
                        }}>
                        Delete User
                      </RoundedPlainTextButton>
                    )} */}
                  </Typography>

                  <Divider className={classes.divider} />

                  <Box mt={4}>
                    <Grid container spacing={5}>
                      <Grid item xs={12} md={6}>
                        { enabledUserAttributes.map( attr => {
                          const canEdit = (
                            attr.slug === 'email' ?
                              (!editUser || editUserContext === 'PENDING_SSO_USERS') :
                              attr.slug === 'name' ?
                                !isSSOUser :
                                attr.slug === 'user-type' ?
                                  !editUser || (
                                    isSuperAdmin
                                    && editUserContext !== 'PENDING_SSO_USERS'
                                    && editUser.email.includes('percept')
                                  ) :
                                  true
                          )
                          return (
                            <div key={attr.id} className={classes.inputContainer}>
                              { isTenantUserAttributeSelect(attr) ? (
                                <TenantUserAttributeSelectComponent
                                  value={values[attr.id]}
                                  attribute={attr}
                                  disabled={!canEdit}
                                  onChange={(e, value): void => {
                                    setFieldValue(attr.id, value)
                                  }} />
                              ) : (
                                <TenantUserAttributeValueComponent
                                  value={values[attr.id]}
                                  attribute={attr}
                                  readOnly={!canEdit}
                                  disabled={!canEdit}
                                  placeholder={
                                    attr.slug === 'name' && isSSOUser ?
                                      'Not Required' :
                                      attr.name
                                  }
                                  onChange={(e): void => {
                                    if( attr.slug === 'email' ){
                                      e.target.value = e.target.value.toLowerCase()
                                    }
                                    handleChange(e)
                                  }}
                                  onBlur={handleBlur} />
                              )}
                            </div>
                          )
                        })}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Grid container spacing={3}>
                          { enabledUserOptions.filter( o => isGroupUser ? true : o.slug !== 'privileged-features' ).map( (attr, i, optionGroups) => (
                            <Fragment key={attr.id}>
                              <Grid
                                item
                                style={{
                                  maxWidth: `calc(${100 / optionGroups.length}% - 13px)`
                                }}>
                                <TenantUserAttributeCheckboxComponent
                                  attribute={attr}
                                  value={values[attr.id] || []}
                                  onChange={(values): void => {
                                    setFieldValue(attr.id, values)
                                  }} />
                              </Grid>
                              { i < (optionGroups.length - 1) && (
                                <Grid item>
                                  <Divider orientation='vertical' />
                                </Grid>
                              )}
                            </Fragment>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Box mt={4}>
                      <Divider className={classes.divider} />
                    </Box>

                    <Box mt={3} display='flex' justifyContent='flex-end'>
                      <RoundedPlainTextButton
                        variant='contained'
                        onClick={onClose}
                        startIcon={<Close />}>
                        Cancel
                      </RoundedPlainTextButton>
                      <RoundedPlainTextButton
                        type='submit'
                        variant='contained'
                        color='primary'
                        startIcon={
                          loading ?
                            <CircularProgress size='1em' color='inherit' /> :
                            <Check />
                        }
                        className={classes.submitButton}
                        disabled={!isValid || loading}>
                        {dialogConfirmText}
                      </RoundedPlainTextButton>
                    </Box>
                  </Box>
                </form>
              )
            }}
          </Formik>
        </Box>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        classes={{
          container: classes.darkenedBackdrop,
        }}
        onClose={deleteDialogCloseHandler}>
        <Box p={3}>
          <Typography variant='h4'>Are you sure?</Typography>

          {deletionUserObject && (
            <Box mt={3} fontSize={18} lineHeight='22px'>
              Do you want to delete user {deletionUserObject.name} ({deletionUserObject.email})?
            </Box>
          )}

          <Box mt={2} fontSize={18} lineHeight='22px'>
            Deleting users cannot be undone.
          </Box>

          <Box mt={3} display='flex' justifyContent='flex-end'>
            <RoundedPlainTextButton
              variant='contained'
              onClick={deleteDialogCloseHandler}
              startIcon={<Close />}>
              Cancel
            </RoundedPlainTextButton>
            <RoundedPlainTextButton
              variant='contained'
              color='primary'
              startIcon={<DeleteForever />}
              className={classes.submitButton}>
              Delete User
            </RoundedPlainTextButton>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}
