import { useMemo } from 'react'

import { HealthType, InsightsReportOutputUnitType, InsightsReportSectionType, InsightsReportType } from '@percept/types'

export type HierarchyIdentifier = 'REPORT' | 'SECTION' | 'OUTPUT_UNIT'

export type InsightsReportTreeHierarchy = (
  InsightsReportType & {
    id: string
    name: string
    health: HealthType
    type: HierarchyIdentifier
    members?: (InsightsReportSectionType & {
      id: string
      health: HealthType
      type: HierarchyIdentifier
      name: string
      members?: (InsightsReportOutputUnitType & {
        id: string
        name: string
        type: HierarchyIdentifier
      })[]
    })[]
  }
)

export const useInsightsReportTreeHierarchy = (
  insightsReport: InsightsReportType | null
): InsightsReportTreeHierarchy | null => {

  return useMemo(() => {

    if( !insightsReport ){
      return null
    }

    const transform = (reportItem: InsightsReportType): InsightsReportTreeHierarchy => {

      return {
        ...reportItem,
        id: reportItem.name,
        type: 'REPORT',
        members: reportItem.sections && reportItem.sections.map( (m, i) => ({
          id: String(i),
          type: 'SECTION',
          ...m,
          // members: [],
          members: m.members.map( (o, j) => ({
            id: `${i}-${j}`,
            name: o.text || 'Some name',
            type: 'OUTPUT_UNIT',
            ...o,
          })),
        })),
      }
    }

    return transform(insightsReport)

  }, [insightsReport])
}


export const useFilteredHierarchy = (
  hierarchy: InsightsReportTreeHierarchy | null,
  query: string
): InsightsReportTreeHierarchy | null => {

  query = query.toLowerCase()

  return useMemo(() => {

    if( !hierarchy || !query ){
      return hierarchy
    }

    return {
      ...hierarchy,
      members: hierarchy.members && hierarchy.members.filter( m => (
        m.name.toLowerCase().indexOf(query) > -1
      )),
    }

  }, [hierarchy, query])

}