import React from 'react'

import { animated } from 'react-spring'

import { Box, BoxProps, Button, Fade } from '@material-ui/core'

import { Notification } from './Notification'

import { useCollapsingItemsTransition } from './lib'

import { Cancel } from '../../icons'

import { Notification as NotificationType } from '@percept/types'


export type NotificationsProps = {
  prepend?: NotificationType | null
  notifications: NotificationType[]
  onClose: (id: string | number) => void
  onCloseAll?: () => void
} & BoxProps


export const Notifications = ({ prepend, notifications, onClose, onCloseAll, ...props }: NotificationsProps): JSX.Element => {

  const { transitions, makeRef } = useCollapsingItemsTransition(
    prepend ? [prepend, ...notifications] : notifications,
  )

  return (
    <Box
      position='fixed'
      right='1.25rem'
      top='4em'
      {...props}>

      { !!onCloseAll && (
        <Fade in={notifications.length > 1}>
          <Box
            position='fixed'
            right={16}
            top={8}
            boxShadow={16}>
            <Button
              variant='contained'
              startIcon={
                <Cancel />
              }
              onClick={(): void => onCloseAll()}>
              Dismiss All
            </Button>
          </Box>
        </Fade>
      )}

      { transitions.map( ({ item, props, key}) => (
        <animated.div
          key={key}
          style={props}>
          <div
            style={{paddingBottom: '16px'}}
            ref={makeRef(item)}>
            <Notification
              {...item}
              onClose={onClose} />
          </div>
        </animated.div>

      ))}

    </Box>
  )
}
