/* eslint-disable no-console */

import { getFetchTypes } from '../utils'

import {
  get,
  isArray,
  isObject,
} from 'lodash-es'

import { Middleware, MiddlewareAPI } from 'redux'

import { user } from '../bundles'

import { Action, AsyncReduxAction, MockAsyncReduxAction, Dictionary, HttpMethod } from '@percept/types'

import { normalizeErrorResponse } from './lib'


const { NODE_ENV } = process.env,
      isDebug = NODE_ENV === 'development'


const isAsyncReduxAction = (action: Dictionary): action is AsyncReduxAction => Boolean(
  typeof action.resource === 'string'
)


const isAction = (action: Dictionary): action is Action => Boolean(action && action.type)

// Store ongoing requests to ensure no unnecessary api requests are triggered
const currentRequests: Dictionary = {}



type ProcessRequestProps<A extends AsyncReduxAction = AsyncReduxAction> = {
  store: MiddlewareAPI
  action: A
}

type RequestProcessor<A extends AsyncReduxAction = AsyncReduxAction> = (props: ProcessRequestProps<A>) => void


const processTestRequest: RequestProcessor<MockAsyncReduxAction> = ({ store, action }) => {

  const asyncActionTypes = getFetchTypes(action.type)
  
  return (
    new Promise( (resolve) => {
      store.dispatch({
        type: asyncActionTypes.start
      })
      setTimeout( () => {
        resolve(
          store.dispatch({
            type: asyncActionTypes.success,
            payload: action.mock
          })
        )
      }, action.delay || 1000)
    })
  )
}


const serializeRequest = (action: AsyncReduxAction | MockAsyncReduxAction): string => {
  const { options: { data = {}, params = {}, method = 'GET' } = {}, meta = {} } = action
  
  let paramString = ''

  if( isObject(params) && Object.keys(params).length ){
    paramString = JSON.stringify(params)
  }else if( isObject(data) && Object.keys(data).length ){
    paramString = JSON.stringify(data)
  }
  
  const requestKey = [
    action.resource,
    paramString,
    method,
    JSON.stringify(meta)
  ].join('|')
  
  return requestKey
}


export interface FetchOptions extends Dictionary {
  url: string
  method: HttpMethod
  params?: Dictionary
  data?: Dictionary | string | number
  headers?: Dictionary
}

export type Api = (options: FetchOptions) => Promise<{
  data: unknown
  status: number
  statusText: string
  headers: Dictionary
}>


export type AsyncMiddlewareProps = {
  api: Api
}


const isMockAction = (action: Dictionary): action is MockAsyncReduxAction => Boolean(
  action && typeof action.mock !== 'undefined'
)


export const asyncMiddlewareCreator = ({ api }: AsyncMiddlewareProps): Middleware => {

  // Define a function to perform requests with dependencies in scope
  const processRequest: RequestProcessor = ({ store, action }) => {

    const asyncActionTypes = getFetchTypes(action.type)

    const requestKey = serializeRequest(action)

    currentRequests[requestKey] = true

    const tokenValue = user.selectors.getUserIdToken(store.getState())

    store.dispatch({ type: asyncActionTypes.start, ...(action.meta && { meta: action.meta }) })

    isArray(action.pendingActions) && action.pendingActions.filter(isAction).forEach(store.dispatch)

    const httpMethod = (
      get(action.options, 'method', 'GET')
    ) as HttpMethod
      
    api({
      url: action.resource,
      method: httpMethod,
      headers: {
        'Content-Type': 'application/json',
        ...( tokenValue && { 'Authorization': `Bearer ${tokenValue}` })
      },
      ...(action.options || {}),
    }).then( response => {
      
      delete currentRequests[requestKey] // Remove the resource from current requests
      
      const timestamp = Date.now()

      const payload = response.data
      
      store.dispatch({
        type: asyncActionTypes.success,
        payload,
        timestamp,
        ...(action.meta && { meta: action.meta })
      })
      
      // Handle action chaining
      isArray(action.successActions) &&
        action.successActions.filter(isAction).forEach(store.dispatch)

      typeof action.getSuccessActions === 'function' &&
        ( action.getSuccessActions(payload) || [] ).filter(isAction).forEach(store.dispatch)
    
    }).catch( error => {
      
      isDebug && console.error('Fetch error', error)
      
      delete currentRequests[requestKey]

      const normalizedError = normalizeErrorResponse(error, { method: httpMethod })

      store.dispatch({
        type: asyncActionTypes.error,
        payload: normalizedError,
        error: true,
        timestamp: Date.now(),
        ...(action.meta && { meta: action.meta }),
        notifyError: action.notifyError,
      })
      
      // Handle action chaining
      isArray(action.errorActions) && (
        action.errorActions.filter(isAction).forEach(store.dispatch)
      )

      typeof action.getErrorActions === 'function' && (
        ( action.getErrorActions(normalizedError) || [] ).filter(isAction).forEach(store.dispatch)
      )
    
    })

  }

  // Return the actual middleware
  return store => next => (action: Action | AsyncReduxAction | MockAsyncReduxAction): Action | void => {

    // Pass through any actions without a route
    if( !isAsyncReduxAction(action) ){
      return next(action)
    }

    const requestKey = serializeRequest(action)

    // Ignore any duplicated requests
    if( currentRequests[requestKey] ){
      return
    }

    if( isMockAction(action) ){
      return processTestRequest({ store, action })
    }

    return processRequest({ store, action })

  }

}
