import React, { useMemo, useState } from 'react'

import {
  Box,
  Card,
  dateRangeCalculators,
  DateRangePopover,
  DateRangePresetOption,
  Loader,
  RoundedPlainTextButtonMenu,
  SimpleTable,
  Typography,
  makeAppStyles,
  LinearProgress,
  useAppTheme,
  Grid,
  RoundedPlainTextButton,
  CircularProgress,
} from '@percept/mui'

import { ArrowDropDown, CloudDownload } from '@percept/mui/icons'

import { capitalize, get, some } from 'lodash-es'

import { useTenantMarketUserEvents, useTenantMarketUserEventsCSVExport } from '@percept/app-components'

import { dmyFormatter, LegendItem, MultiLineProps, numberFormatter, ResponsiveMultiLine } from '@percept/mui/charts'

import { VODAFONE_GLOBAL_ID } from 'vodafoneMarkets'

import {
  analyticsTableColumns,
  AnalyticsTableRow,
  getAnalyticsTimeseriesDatasets,
  getCombinedApplicationTimeseriesDataset,
  getMultiDatasetDefs,
  getPrimaryTableRows,
  getSecondaryTableRowMapping,
  ViewType,
} from './lib'


const useStyles = makeAppStyles( theme => ({
  title: {
    marginRight: theme.spacing(3),
  },
  selector: {
    marginRight: theme.spacing(2),
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  chartCard: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(2),
    position: 'relative',
  },
  chartContainer: {
    margin: theme.spacing(2, 2, 1, 2),
  },
  tableContainer: {
    position: 'relative',
  },
  refetchIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 4,
    zIndex: theme.zIndex.appBar,
  },
}))


const CHART_PROPS: Partial<MultiLineProps> = {
  height: 300,
  xScaleType: 'time',
  grid: 'rows',
  axisText: true,
  yTickFormatter: numberFormatter,
  xTickFormatter: dmyFormatter,
}

const maxDate = new Date()

const analyticsDateRangePresetOptions: DateRangePresetOption[] = [
  'today',
  'yesterday',
  'last-7-days',
  'last-30-days',
  'last-week',
  'last-month',
  'last-quarter',
  'current-year',
  'last-year',
]

const defaultDateRangePreset: DateRangePresetOption = 'last-7-days'

export const Analytics = (): JSX.Element => {
 
  const [dateRangePreset, setDateRangePreset] = useState<DateRangePresetOption>(defaultDateRangePreset)

  const [dateRange, setDateRange] = useState<[Date, Date]>(() => (
    dateRangeCalculators[defaultDateRangePreset](maxDate)
  ))

  const primaryQueryResult = useTenantMarketUserEvents({
    tenant_id: VODAFONE_GLOBAL_ID,
    start: dateRange[0],
    end: dateRange[1],
    segments: ['MARKET', 'PRIMARY_APPLICATION'],
  })

  const secondaryQueryResult = useTenantMarketUserEvents({
    tenant_id: VODAFONE_GLOBAL_ID,
    start: dateRange[0],
    end: dateRange[1],
    segments: ['MARKET', 'SECONDARY_APPLICATION'],
  })

  const marketTimeseriesQueryResult = useTenantMarketUserEvents({
    tenant_id: VODAFONE_GLOBAL_ID,
    start: dateRange[0],
    end: dateRange[1],
    segments: ['DATE', 'MARKET'],
  })

  const primaryApplicationTimeseriesQueryResult = useTenantMarketUserEvents({
    tenant_id: VODAFONE_GLOBAL_ID,
    start: dateRange[0],
    end: dateRange[1],
    segments: ['DATE', 'PRIMARY_APPLICATION'],
  })

  const secondaryApplicationTimeseriesQueryResult = useTenantMarketUserEvents({
    tenant_id: VODAFONE_GLOBAL_ID,
    start: dateRange[0],
    end: dateRange[1],
    segments: ['DATE', 'SECONDARY_APPLICATION'],
  })

  const [viewType, setViewType] = useState<ViewType>('USERS')

  const exportHook = useTenantMarketUserEventsCSVExport()

  /* Table data */

  const primaryRows = useMemo(() => {
    if( !primaryQueryResult.data ){
      return []
    }
    return getPrimaryTableRows(primaryQueryResult.data, viewType)
  }, [primaryQueryResult.data, viewType])

  const secondaryRowMapping = useMemo(() => {
    if( !secondaryQueryResult.data ){
      return {}
    }
    return getSecondaryTableRowMapping(secondaryQueryResult.data, viewType)
  }, [secondaryQueryResult.data, viewType])

  /* Timeseries data */

  const appTheme = useAppTheme()

  const timeseries = useMemo(() => {
    if(
      !marketTimeseriesQueryResult.data
      || !primaryApplicationTimeseriesQueryResult.data
      || !secondaryApplicationTimeseriesQueryResult.data
    ){
      return null
    }
    return {
      market: getAnalyticsTimeseriesDatasets({
        userEvents: marketTimeseriesQueryResult.data,
        dateRange,
        viewType,
        appTheme,
        groupBy: 'MARKET',
      }),
      combinedApplication: getCombinedApplicationTimeseriesDataset({
        primary: primaryApplicationTimeseriesQueryResult.data,
        secondary: secondaryApplicationTimeseriesQueryResult.data,
        dateRange,
        viewType,
        appTheme,
      }),
    }
  }, [
    marketTimeseriesQueryResult.data,
    primaryApplicationTimeseriesQueryResult.data,
    secondaryApplicationTimeseriesQueryResult.data,
    dateRange,
    viewType,
    appTheme
  ])

  const timeseriesHooks = [
    marketTimeseriesQueryResult,
    primaryApplicationTimeseriesQueryResult,
    secondaryApplicationTimeseriesQueryResult,
  ]

  const isTimeseriesLoading = some(timeseriesHooks, q => q.isLoading)

  const isTimeseriesError = some(timeseriesHooks, q => q.isError)

  const getRowGroup = (row: AnalyticsTableRow): AnalyticsTableRow[] => (
    secondaryRowMapping[row.label] || []
  )

  const viewTypeLabel = capitalize(viewType)

  const appTimeseriesDataSignal = get(timeseries, ['combinedApplication', 0, 'data', 'length'], 0)
  const marketTimeseriesDataSignal = get(timeseries, ['market', 0, 'data', 'length'], 0)

  const timeseriesDataSignals = [
    appTimeseriesDataSignal,
    marketTimeseriesDataSignal,
  ]

  const hasAnyTimeseriesData = some(timeseriesDataSignals)

  const numTicks = Number(
    timeseries && Math.min(
      Math.max(...timeseriesDataSignals),
      10
    )
  )

  const classes = useStyles()

  return (
    <Box p={3}>
      <Box display='flex' alignItems='center' mb={3}>
        <Typography variant='h2' className={classes.title}>
          Analytics
        </Typography>

        <DateRangePopover
          ButtonProps={{
            className: classes.selector,
            color: 'default',
          }}
          color='primary'
          dateRangePreset={dateRangePreset}
          dateRangePresetOptions={analyticsDateRangePresetOptions}
          setDateRangePreset={setDateRangePreset}
          value={dateRange}
          onChange={setDateRange}
          maxDate={maxDate} />

        <RoundedPlainTextButtonMenu
          TriggerProps={{
            className: classes.selector,
            variant: 'contained',
            color: 'default',
            size: 'small',
            endIcon: <ArrowDropDown />,
          }}
          value={viewType}
          label={capitalize(viewType)}
          options={[
            { value: 'USERS', label: 'Users' },
            { value: 'VIEWS', label: 'Views' },
          ]}
          onChange={(e, value): void => {
            setViewType(value)
          }} />
      </Box>

      { isTimeseriesError ? (
        <Box my={10} display='flex' justifyContent='center'>
          <Typography variant='h3' color='textSecondary'>
            An error occurred and timeseries data could not be loaded
          </Typography>
        </Box>
      ) : isTimeseriesLoading ? (
        <Loader preset='fullsize' minHeight={CHART_PROPS.height} />
      ) : timeseries && hasAnyTimeseriesData && (
        <Box mb={5}>
          <Grid container spacing={4}>
            <Grid item xs={6} className={classes.gridItem}>
              <Card className={classes.chartCard}>
                { (marketTimeseriesQueryResult.isRefetching) && (
                  <LinearProgress className={classes.refetchIndicator} />
                ) }
                <Typography variant='h5'>
                  {viewTypeLabel} over time by market
                </Typography>
                <div className={classes.chartContainer}>
                  <ResponsiveMultiLine
                    {...CHART_PROPS}
                    defs={getMultiDatasetDefs(timeseries.market)}
                    numXTicks={marketTimeseriesDataSignal ? numTicks : 0}
                    datasets={timeseries.market} />
                </div>

                <Box display='flex' alignItems='center' flexWrap='wrap'>
                  { timeseries.market.map( d => (
                    <LegendItem
                      key={d.key}
                      datum={d} />
                  ))}
                </Box>
              </Card>
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <Card className={classes.chartCard}>
                { (primaryApplicationTimeseriesQueryResult.isRefetching) && (
                  <LinearProgress className={classes.refetchIndicator} />
                ) }
                <Typography variant='h5'>
                  {viewTypeLabel} over time by section
                </Typography>
                <div className={classes.chartContainer}>
                  <ResponsiveMultiLine
                    {...CHART_PROPS}
                    defs={getMultiDatasetDefs(timeseries.combinedApplication)}
                    numXTicks={appTimeseriesDataSignal ? numTicks : 0}
                    datasets={timeseries.combinedApplication} />
                </div>

                <Box display='flex' alignItems='center' flexWrap='wrap'>
                  { timeseries.combinedApplication.map( d => (
                    <LegendItem
                      key={d.key}
                      datum={d} />
                  ))}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}
      
      { (primaryQueryResult.error || secondaryQueryResult.error) ? (
        <Box my={10} display='flex' justifyContent='center'>
          <Typography variant='h3' color='textSecondary'>
            An error occurred and the data could not be loaded
          </Typography>
        </Box>
      ) : !(primaryQueryResult.data && secondaryQueryResult.data) ? (
        <Loader preset='fullsize' minHeight='15rem' />
      ) : !primaryRows.length ? (
        <Box my={10} display='flex' justifyContent='center'>
          <Typography variant='h3' color='textSecondary'>
            No results for selected time period
          </Typography>
        </Box>
      ) : (
        <>
          <Box display='flex' justifyContent='flex-end' mb={3}>
            <RoundedPlainTextButton
              variant='contained'
              color='secondary'
              size='small'
              disabled={exportHook.isLoading}
              startIcon={
                exportHook.isLoading ?
                  <CircularProgress size='1em' color='inherit' /> :
                  <CloudDownload />
              }
              onClick={(): void => {
                exportHook.mutate({
                  tenant_id: VODAFONE_GLOBAL_ID,
                  start: dateRange[0],
                  end: dateRange[1],
                  cardinality_type: viewType,
                })
              }}>
              Download CSV
            </RoundedPlainTextButton>
          </Box>

          <Card className={classes.tableContainer}>
            <SimpleTable
              size='small'
              sortable
              pinFirstColumn
              stickyHeader
              unsetStickyHeaderZIndex={false}
              wrapCellText={false}
              grouped={true}
              getRowGroup={getRowGroup}
              columns={analyticsTableColumns}
              rows={primaryRows} />
            { (primaryQueryResult.isRefetching || secondaryQueryResult.isRefetching) && (
              <LinearProgress className={classes.refetchIndicator} />
            ) }
          </Card>
        </>
      )}
    </Box>
  )
}
