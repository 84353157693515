
import { useMemo } from 'react'

import {
  getPath,
  isNumber,
  sortBy,
  unionBy,
  mapValues,
  getHealthFrom,
} from '@percept/utils'

import {
  useLayoutMetricIds,
} from '@percept/hooks'

import {
  LayoutType,
  DimensionType,
  PayloadMetric,
  MetricMetadataType,
  MetricMetadataEntryType,
  MetricMetadataPriority,
  ReportMetricsPayload,
} from '@percept/types'


type MetricHealthStub = {
  id: string,
  health: number
}

export interface MetricComparison {
  id: string
  dimension: DimensionType | string,
  metric: PayloadMetric,
  previousMetric: PayloadMetric | null,
}

export interface PriorityMetric extends MetricComparison {
  priority: number,
}

export interface TrendingMetric extends MetricComparison {
  previousMetric: PayloadMetric,
  delta: number
}

type TrendingMetricContainer = {
  best: TrendingMetric[]
  worst: TrendingMetric[]
}


const getPriority = (metadataEntry: MetricMetadataEntryType): MetricMetadataPriority | null =>
  getPath(metadataEntry, ['display_options', 'priority'])


const useIdsWithHealth = (
  metrics: ReportMetricsPayload | null,
  healthDimension: DimensionType | string,
  whitelist: string[] | null = null
): MetricHealthStub[] => {

  return useMemo(() => {

    if( !metrics ){
      return []
    }

    return Object.keys(metrics).reduce( (acc: MetricHealthStub[], id: string) => {
      // Some metrics may appear in the payload, but not in the layout
      // We don't want to expose these, so silently skip any metrics
      // not appearing in the layout
      if( whitelist && whitelist.length && whitelist.indexOf(id) === -1 ){
        return acc
      }

      const health = getHealthFrom(getPath(metrics[id], ['dimensions', healthDimension]))

      if( isNumber(health) ){
        acc.push({ id, health })
      }

      return acc

    }, [])

  }, [metrics, whitelist, healthDimension])

}


interface UsePriorityProps {
  metrics: ReportMetricsPayload | null
  previousMetrics: ReportMetricsPayload | null
  metadata: MetricMetadataType | null
  healthDimension: DimensionType | string
  whitelist?: string[] | null
}

const usePriority = (
  { metrics, previousMetrics, healthDimension, metadata, whitelist }: UsePriorityProps
): PriorityMetric[] | null => {

  const idsWithHealth = useIdsWithHealth(metrics, healthDimension, whitelist)

  return useMemo(() => {

    if( !metrics ){
      return null
    }

    const withPriority = idsWithHealth.reduce( (acc: PriorityMetric[], { id, health }) => {
      const priority = getPriority(getPath(metadata, [id]))
      if( priority &&
          isNumber(priority.impact) &&
          isNumber(priority.simplicity)
      ){
        acc.push({
          id,
          dimension: healthDimension,
          metric: metrics[id],
          previousMetric: getPath(previousMetrics, [id]),
          priority: (1 - health) * priority.impact * priority.simplicity
        })
      }
      return acc
    }, [])

    return sortBy(withPriority, 'priority', true)

  }, [metrics, previousMetrics, metadata, idsWithHealth, healthDimension])
} 


const unionById = unionBy('id')


const useHealthDifference = (
  { metrics, previousMetrics, healthDimension = 'count', whitelist }: Omit<UsePriorityProps, 'metadata'>
  // { metrics: MetricsPayload | null, previousMetrics: MetricsPayload | null, whitelist: string[] | null }
): TrendingMetricContainer => {

  const currentWithHealth = useIdsWithHealth(metrics, healthDimension, whitelist)

  const previousWithHealth = useIdsWithHealth(previousMetrics, healthDimension, whitelist)

  return useMemo(() => {

    if( !currentWithHealth.length || !previousWithHealth.length ){
      return { best: [], worst: [] }
    }

    const unionWithHealth = unionById(currentWithHealth, previousWithHealth)

    const byHealthDifference = unionWithHealth.reduce( (acc: TrendingMetricContainer, m: MetricHealthStub) => {

      const metric: PayloadMetric | null = getPath(metrics, [m.id]),
            previousMetric: PayloadMetric | null = getPath(previousMetrics, [m.id])

      const metricHealth = getPath(metric, ['dimensions', healthDimension, 'health']),
            previousMetricHealth = getPath(previousMetric, ['dimensions', healthDimension, 'health'])

      if( metric && previousMetric && isNumber(metricHealth) && isNumber(previousMetricHealth) ){

        const delta = Number(metricHealth) - Number(previousMetricHealth)

        // Filter out any metrics where the delta is equivalent to less than 1%
        const receiver = delta >= 0.05 ? acc.best : delta <= -0.05 ? acc.worst : null

        receiver && receiver.push({
          id: m.id,
          dimension: healthDimension,
          metric,
          previousMetric,
          delta
        })

      }

      return acc

    }, { best: [], worst: [] } as TrendingMetricContainer)

    return mapValues(
      byHealthDifference,
      // sort 'best' by health descending
      (arr: TrendingMetric[], k) => sortBy(arr, 'delta', k === 'best')
    ) as TrendingMetricContainer

  }, [currentWithHealth, previousWithHealth, metrics, previousMetrics, healthDimension])

}


type UseMetricListsProps = {
  metrics: ReportMetricsPayload | null
  previousMetrics: ReportMetricsPayload | null
  metadata: MetricMetadataType | null
  layout: LayoutType | null
  healthDimension?: DimensionType | string
}


type MetricLists = {
  priority: PriorityMetric[] | null
  best: TrendingMetric[] | null
  worst: TrendingMetric[] | null
}


export const useMetricLists = ({
  metrics,
  previousMetrics,
  healthDimension = 'count',
  metadata,
  layout,
}: UseMetricListsProps
): MetricLists => {

  // const metrics = getPath(entity.data, 'metrics') as MetricsPayload | null,
  //       previousMetrics = getPath(previousEntity.data, 'metrics') as MetricsPayload | null

  // Use latest payload as reference point for any optional metric filtering
  const whitelist = useLayoutMetricIds(layout, metrics)

  const priority = usePriority({
    metrics,
    previousMetrics,
    healthDimension,
    metadata,
    whitelist,
  })

  const { best, worst } = useHealthDifference({ metrics, previousMetrics, healthDimension, whitelist })

  return { priority, best, worst }

}
