import React from 'react'

import { animated } from 'react-spring'

import { Box, Button, IconButton, CircularProgress } from '@material-ui/core'

import { Alert } from '../Alert'

import { Close } from '../../icons'

import { useLifeIndicatorSpring, useLifeIndicatorStyles } from './lib'

import { Notification as NotificationType } from '@percept/types'


export type NotificationProps = (
  Omit<
    NotificationType, 'onClose'
  > & {
    onClose: (id: string | number) => void
  }
)


export const Notification = ({
  id,
  type,
  name,
  alertIcon,
  loading,
  message,
  ttl,
  buttonActions,
  onClose,
}: NotificationProps): JSX.Element => {

  const animatedProps = useLifeIndicatorSpring({
    id,
    ttl,
    onRest: onClose
  })

  const classes = useLifeIndicatorStyles()

  const icon = (
    loading ?
      <CircularProgress size='1.5em' color='inherit' /> :
      alertIcon
  )

  return (
    <Alert
      variant={type}
      header={name}
      icon={icon}
      position='relative'
      width='25rem'
      pt={2}
      pl={2}
      pr={4}
      pb={ttl ? 3 : 2}
      fontSize={15}
      lineHeight={1.4}
      boxShadow={18}>

      <IconButton
        className={classes.closeIcon}
        color='inherit'
        onClick={(): void => {
          if( id ){
            onClose(id)
          }
        }}>
        <Close />
      </IconButton>

      <Box
        mr={name ? -2 : 0}>

        <Box
          mt={name ? 2.5 : 1}
          mb={buttonActions ? 1.5 : 0}>
          { message }
        </Box>

        { buttonActions && (
          <Box mt={3}>
            { buttonActions.map(({ content, startIcon, onClick }, i) => (
              <Button
                key={`${id}-${i}`}
                variant='contained'
                fullWidth
                startIcon={startIcon}
                onClick={onClick}>
                { content }
              </Button>
            ))}
          </Box>
        )}

      </Box>

      { !!ttl && (
        <animated.div
          className={classes.indicator}
          style={animatedProps} />
      )}

    </Alert>
  )
}
