import { Box, IconButton, Snackbar, SnackbarCloseReason, Typography } from '@material-ui/core'
import { Close, InfoOutlined } from '@material-ui/icons'
import { makeAppStyles } from '@percept/mui'
import React from 'react'

const useBannerClasses = makeAppStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    alignItems: 'center',
    width: '50vw'
  },
  text:{
    flexGrow: 2
  },
  span: {
    fontWeight: 700,
  },
}))

interface Props {
  open: boolean
  closeHandlerBox: (reason: SnackbarCloseReason) => void
  closeHandler: () => void
}

export const Banner = ({ open, closeHandlerBox, closeHandler }: Props): JSX.Element => {
  const bannerClasses = useBannerClasses()
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={5000}
      onClose={(_, reason): void => closeHandlerBox(reason)}
      action={
        <Box className={bannerClasses.root} mx={4} my={1}>
          <InfoOutlined fontSize='small' />
          <Typography variant='body2' className={bannerClasses.text}>
            Remember to click{' '}
            <span className={bannerClasses.span}>Apply filters</span> button in
            order to apply selected Global filters
          </Typography>
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={closeHandler}>
            <Close fontSize='small' />
          </IconButton>
        </Box>
      }
    />
  )
}
