import React from 'react'

import { AppTheme, Box, DialogProps, IconButton, PerceptAppBar, Typography } from '@percept/mui'

import { DefaultBackgroundDialog } from './DefaultBackgroundDialog'

import { ExamplesDataTable } from '../ReportDashboardApp'

import { ArrowBack } from '@percept/mui/icons'

import { DimensionType, MetricExample, MetricMetadataType } from '@percept/types'
import { DSLRenderer } from './DSLComponents'


export type ExamplesDialogProps = {
  appTheme: AppTheme
  title: string | null | undefined
  text: string | null | undefined
  examples: MetricExample[]
  metricMetadata: MetricMetadataType
  DialogProps: Partial<Omit<DialogProps, 'onClose'>> & {
    onClose: () => void
  }
  currency?: string
  dimension?: DimensionType
}

export const ExamplesDialog = ({
  appTheme,
  title,
  text,
  examples,
  metricMetadata,
  DialogProps,
  currency,
  dimension = 'cost',
}: ExamplesDialogProps): JSX.Element => {
  return (
    <DefaultBackgroundDialog
      open
      fullScreen
      maxWidth={false}
      {...DialogProps}>
      <PerceptAppBar
        color='secondary'
        startIcon={
          <IconButton
            color='inherit'
            onClick={DialogProps.onClose}>
            <ArrowBack />
          </IconButton>
        }>
        <Box ml={2}>
          <Typography variant='h5'>
            { title }
          </Typography>
        </Box>
      </PerceptAppBar>
      <Box pt={12} px={3}>
        <Typography variant='h5'>
          <DSLRenderer
            appTheme={appTheme}
            text={text} />
        </Typography>
        <Box mt={5}>
          <ExamplesDataTable
            examples={examples}
            dimension={dimension}
            currency={currency}
            metadata={metricMetadata} />
        </Box>
      </Box>
    </DefaultBackgroundDialog>
  )
}
