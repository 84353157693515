import { Box, Tab, Tabs, Typography } from '@percept/mui'
import React, { FC, useState } from 'react'
import { TabType } from '../PartnershipTabs'
import { makeAppStyles } from '@percept/mui'
import { SubmitScorecard } from './SubmitScorecard'
import { SubmitEvents } from './SubmitEvents'
import { SubmitArticles } from './SubmitArticles'

import { createArticle, deleteArticle, getOgData, updateArticle } from '../partnershipsClient'
import { getArticlesPaginated } from '../Articles'
import { PARTNERSHIPS_QUERY_KEY } from '../constants'


const tabs: TabType[] = [
  {label:'YouTube Scorecard', value: 'youtube'},
  {label:'Meta Scorecard', value: 'meta'},
  {label:'Articles', value: 'articles'},
  {label:'Calendar', value: 'calendar'},
]

const useStyles = makeAppStyles( theme => ({
  tabs: {
    margin: theme.spacing(4,0),
  },
  tab: {
    textTransform: 'none',
  },
  text: {
    marginBottom: '20px',
    fontSize: '16px'
  }
}))

export const PartnershipsDataSubmit: FC = () => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(tabs[0].value)
  
  const handleChange = (_event: React.ChangeEvent<{}>, value: string): void => {
    setActiveTab(value)
  }

  return (
    <Box>
      <Tabs
        indicatorColor='secondary'
        className={classes.tabs}
        value={activeTab}
        onChange={handleChange}>
        {tabs.map((tab: TabType) => (
          <Tab key={tab.label} className={classes.tab} value={tab.value} label={tab.label} />
        ))}
      </Tabs>

      {activeTab === 'youtube' && (
        <SubmitScorecard partner='Youtube' type='youtube_report'> 
          <Typography className={classes.text}>To add a new month of data for Youtube, click Upload Youtube.</Typography>
          <Typography className={classes.text}>Note that only the latest month in the file will be added to the data.</Typography>
        </SubmitScorecard>)
      }
      {activeTab === 'meta' && (
        <SubmitScorecard partner='Meta' type='meta_report'> 
          <Typography className={classes.text}>To add a new quarter of data for Meta, click the Upload Meta.</Typography>
          <Typography className={classes.text}>Note that only the latest month in the file will be added to the data.</Typography>
        </SubmitScorecard>)
      }
      {activeTab === 'articles' && (
        <SubmitArticles
          queryKey={PARTNERSHIPS_QUERY_KEY}
          createArticle={createArticle}
          updateArticle={updateArticle}
          deleteArticle={deleteArticle}
          getOpenGraphData={getOgData}
          getArticles={getArticlesPaginated} />
      )}
      {activeTab === 'calendar' && (<SubmitEvents />)}
    </Box>
  )
}