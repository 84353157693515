import React from 'react'


export type FlagIconProps = React.PropsWithChildren<
  {
    size: number
    rounded?: boolean
    border?: string
  } & React.SVGAttributes<SVGSVGElement>
>

export type FlagIcon = (props: FlagIconProps) => JSX.Element

export const makeFlagIconComponent = (
  svgChildren: JSX.Element | JSX.Element[],
  rootSvgProps?: React.SVGAttributes<SVGSVGElement>
): FlagIcon => {
  const FlagIconComponent = ({ size, rounded, border, ...props }: FlagIconProps): JSX.Element => {
    return (
      <svg
        width={size}
        height={size}
        viewBox='0 0 512 512'
        {...rootSvgProps}
        {...props}>
        { rounded && (
          <defs>
            <clipPath id='circleClipPath'>
              <circle cx='50%' cy='50%' r='50%' />
            </clipPath>
          </defs>
        )}
        <g
          clipPath={rounded ? 'url(#circleClipPath)' : undefined}>
          { svgChildren }
        </g>
        { rounded && border && (
          <circle stroke={border} fill='none' strokeWidth={(512 / size) * 1} cx='50%' cy='50%' r='50%' />
        )}
        { !rounded && border && (
          <rect stroke={border} fill='none' strokeWidth={(512 / size) * 1} width='100%' height='100%' />
        )}
      </svg>
    )
  }
  return FlagIconComponent
}
