import { ReportParams, SeriesParams } from '@percept/types'
import { createContext } from 'react'


export type InsightsReportContextValue = (
  SeriesParams
  & ReportParams
  & {
    version: number
    insights_report_type: string
    result_id?: string | null
  }
)

export const InsightsReportContext = createContext<InsightsReportContextValue>(
  {} as InsightsReportContextValue
)
