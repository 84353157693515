import React, { useMemo } from 'react'

import { useAppTheme } from '../../themes'

import {
  MultiDataset,
  ChartData,
  ResponsiveMultiLine,
  QuantitativeChartProps,
  ResponsiveStackedArea,
  ResponsiveChartProps,
  MultiLineProps,
  SVGDatumType,
  longDayMonthYearFormatter,
  dmyFormatter,
} from '../../charts'

import { ReportRange } from '../ReportRange'

import { applySegmentMetadata } from './lib'

import { flatten, get, uniq } from 'lodash-es'

import { formatAuditDates } from '@percept/utils'

import {
  AttributeMetricData,
  DistributionMetricData,
  MetricMetadataEntryType,
  MetricSegmentType,
  MetricTypeEnum,
  ProportionMetricData,
  SeriesReportMetric,
  ValueMetricData,
  WithHealth,
} from '@percept/types'


type TimelineDatum = SVGDatumType & {
  start: number
  end: number
}


type TypedMetricSeries<T extends MetricTypeEnum> = (
  SeriesReportMetric & {
    metric: null | (
      (
        T extends ('distribution' | 'distribution_non_mutex') ?
          DistributionMetricData :
          T extends 'value' ?
            ValueMetricData :
            T extends ('ratio' | 'proportion') ?
              ProportionMetricData :
              AttributeMetricData
      ) & WithHealth
    )
  }
)[]


export type MetricSeriesProps<T extends MetricTypeEnum> = (
  Partial<
    Omit<
      QuantitativeChartProps<TimelineDatum>,
      'datasets' | 'data'
    >
  > & {
    metricType: T
    metricSeries: TypedMetricSeries<T>
    metadataEntry: MetricMetadataEntryType
    variant?: 'line' | 'area'
    activeSegment?: string
  }
)


const defaultChartProps: Omit<MultiLineProps<TimelineDatum>, 'datasets' | 'width'> = {
  xScaleType: 'time',
  arbitraryTimeline: true,
  grid: 'rows',
  axisLine: true,
  axisText: true,
  height: 300,
  roundXDomain: false,
  numYTicks: 6,
  xTickFormatter: longDayMonthYearFormatter,
  tooltipLabelFormatter: dmyFormatter,
  // eslint-disable-next-line react/display-name
  tooltipLabelDatumFormatter: (d) => {
    return (
      <ReportRange
        start={d.start}
        end={d.end} />
    )
  }
}


export function MetricSeries<T extends MetricTypeEnum>({
  metricType,
  metricSeries,
  metadataEntry,
  variant = 'line',
  activeSegment,
  ...props
}: MetricSeriesProps<T>): JSX.Element {

  const appTheme = useAppTheme()

  const datasets = useMemo(() => {
    const segments = metadataEntry.display_options.segments

    if( (metricType === 'distribution' || metricType === 'distribution_non_mutex') && segments ){

      const mappedSeries = (metricSeries as TypedMetricSeries<'distribution'>).map( (m) => ({
        ...m,
        key: m.report_id,
        label: formatAuditDates({ ...m, format: 'dd/MM/yy' }).join(' – '),
        color: appTheme.chart.healthColourScale(m.health || 0),
        data: applySegmentMetadata(
          m.metric ? m.metric.segments : {},
          metadataEntry.display_options
        ).reduce( (acc, s) => {
          acc[s.label] = s
          return acc
        }, {} as Record<string, MetricSegmentType>)
      }))

      if( metadataEntry.display_options.dynamic ){
        // Gather all possible segments
        const segmentKeySet = uniq(
          flatten(
            mappedSeries.map( s => Object.keys(s.data) )
          )
        ).sort()

        const defaultColourScale = appTheme.chart.getInformationalColourScale(
          [0, Math.max(segmentKeySet.length - 1, 1)]
        )

        return segmentKeySet.map ( (label, i) => {
          const color = defaultColourScale(i)
          return {
            key: label,
            label,
            color,
            data: mappedSeries.map( m => {
              const segment = m.data[label]
              const health = get(segment, 'health', null)
              return {
                value: get(segment, 'value', null),
                label: new Date(m.end).getTime(),
                start: new Date(m.start).getTime(),
                end: new Date(m.end).getTime(),
                color: (
                  health === null ?
                    color :
                    appTheme.chart.healthColourScale(health)
                ),
                health,
              }
            }) as ChartData<MetricSegmentType>
          }
        })
      }

      const defaultColourScale = appTheme.chart.getInformationalColourScale(
        [0, Math.max(segments.length - 1, 1)]
      )

      return segments.map( ({ label, health }, i) => {
        const color = (
          health === null ?
            defaultColourScale(i) :
            appTheme.chart.healthColourScale(health)
        )
        let svgProps = {}
        if( activeSegment ){
          const isActive = activeSegment === label
          svgProps = {
            strokeOpacity: isActive ? 1 : 0.5,
            fillOpacity: (
              variant === 'line' ?
                0 : (
                  isActive ? 1 : 0.5
                )
            ),
          }
        }
        return {
          key: label,
          color,
          label,
          ...svgProps,
          data: mappedSeries.map( m => ({
            value: get(m.data[label], 'value', null),
            label: new Date(m.end).getTime(),
            start: new Date(m.start).getTime(),
            end: new Date(m.end).getTime(),
            color,
            health,
          })) as ChartData<MetricSegmentType>
        }
      }) as MultiDataset[]
    }

    const singleValueColourScale = appTheme.chart.getInformationalColourScale([0, 1])

    const color = singleValueColourScale(0)

    if( metricType === 'value' ){
      return [
        {
          key: metadataEntry.title,
          label: metadataEntry.title,
          color,
          data: (metricSeries as TypedMetricSeries<'value'>).map( m => ({
            value: m.metric ? m.metric.value : null,
            label: new Date(m.end).getTime(),
            start: new Date(m.start).getTime(),
            end: new Date(m.end).getTime(),
            color,
            health: null,
          }))
        }
      ]
    }

    if( metricType === 'proportion' || metricType === 'ratio' ){
      return [
        {
          key: metadataEntry.title,
          label: metadataEntry.title,
          color,
          data: (metricSeries as TypedMetricSeries<'proportion' | 'ratio'>).map( m => ({
            value: m.metric ? m.metric.enumerator.value : null,
            label: new Date(m.end).getTime(),
            start: new Date(m.start).getTime(),
            end: new Date(m.end).getTime(),
            color,
            health: null,
          }))
        }
      ]
    }

    return []
  }, [metricSeries, metadataEntry, metricType, appTheme, activeSegment, variant])

  const Renderer = (
    variant === 'line' ?
      ResponsiveMultiLine :
      ResponsiveStackedArea
  ) as (props: ResponsiveChartProps<MultiLineProps<TimelineDatum>, TimelineDatum>) => JSX.Element

  return (
    <Renderer
      {...defaultChartProps}
      {...props}
      datasets={datasets}
      xScaleType='time' />
  )
}

export default MetricSeries
