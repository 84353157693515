import React, {  useMemo } from 'react'

import { Group } from '@visx/group'

import { scaleBand, scaleLinear } from '@visx/scale'

import { AnimatedBars } from './AnimatedBars'

import { ensureValidDomain, getDomain, getLabel, getMirrorDomain, getValue } from '../lib'

import { HistogramBarDatum, HistogramProps } from './typings'

import { GenericAxisChart } from '../GenericAxisChart'

import { SVGDatumType } from '../typings'
import { Annotations } from '../Quantitative/Annotations'


export function Histogram<T extends SVGDatumType>({
  data,
  domain,
  width,
  height,
  onSegmentClick,
  onSegmentMouseOver,
  onSegmentMouseOut,
  animate = true,
  animateInitial = false,
  minBarHeight = 4,
  verticalMargin,
  barPaddingRatio = 0.3,
  xOffset,
  containerRef,
  svgBarProps = {},
  alternateBackground,
  mirrorYDomain,
  fillOverride,
  annotations,
  defs,
  backgroundElement,
  foregroundElement,
  svgClassName,
  ...props
}: HistogramProps<T>): JSX.Element | null {

  const hasAxisText = !!props.axisText

  const canonicalXOffset = (
    xOffset !== undefined ?
      xOffset :
      hasAxisText && props.numYTicks !== 0 ?
        36 :
        0
  )

  verticalMargin = (
    typeof verticalMargin !== 'undefined' ?
      verticalMargin :
      hasAxisText && props.numXTicks !== 0 ?
        20 :
        0
  )

  const {
    fillOpacity = 1,
    strokeOpacity = 0
  } = svgBarProps

  const xMax = width - canonicalXOffset

  const yMax = height - verticalMargin

  const { xScale, yScale } = useMemo(() => {
    return {
      xScale: scaleBand<string>({
        range: [canonicalXOffset, width],
        round: true,
        domain: data.map(getLabel).map(String),
        padding: barPaddingRatio,
      }),
      yScale: scaleLinear<number>({
        range: [yMax, 0],
        domain: ensureValidDomain(domain || (mirrorYDomain ? getMirrorDomain(data) : getDomain(data))),
        nice: hasAxisText,
      }),
    }
  }, [data, canonicalXOffset, width, yMax, barPaddingRatio, domain, mirrorYDomain, hasAxisText])

  const bars = useMemo<HistogramBarDatum<T>[]>(() => {
    return data.map( d => {

      const yValue = getValue(d) || 0
      const scaledValue = yScale(yValue)
      const zeroPoint = yScale(0)
      const yDelta = scaledValue

      const rawHeight = Math.abs(
        (
          yValue === 0 ?
            0 :
            yDelta
        ) - (zeroPoint * (yDelta < 0 ? -1 : 1))
      )

      const height = (
        yValue === 0 ?
          0 :
          scaleLinear({
            domain: [yMax, 0],
            range: [yMax, minBarHeight]
          })(rawHeight)
      )

      const y = (
        yValue <= 0 ?
          zeroPoint :
          ( zeroPoint - height )
      )

      const label = getLabel(d)
      const x = xScale(label.toString()) || 0
      const width = xScale.bandwidth()

      return {
        label,
        width,
        height,
        x,
        y,
        data: d,
        fillOpacity: d.fillOpacity === undefined ? fillOpacity : d.fillOpacity,
        strokeOpacity: d.strokeOpacity === undefined ? strokeOpacity : d.strokeOpacity,
      }
    })
  }, [data, xScale, yScale, yMax, minBarHeight, fillOpacity, strokeOpacity])

  if( !(width > 0 && height > 0) ){
    return (
      <svg ref={containerRef} width={width || '100%'} height={height || '100%'} />
    )
  }

  return (
    <svg
      ref={containerRef}
      className={svgClassName}
      width={width}
      height={height}
      onMouseOut={onSegmentMouseOut}
      overflow='visible'>

      { defs && <defs>{ defs }</defs> }

      <rect x={0} y={0} width='100%' height='100%' opacity={0} fill='rgb(0,0,0)' />

      { backgroundElement || null }

      <GenericAxisChart
        xScale={xScale}
        yScale={yScale}
        width={width}
        height={height}
        columnOffset={xScale.bandwidth() / 2}
        xOffset={canonicalXOffset}
        verticalMargin={verticalMargin}
        animate={animate}
        animateInitial={animateInitial}
        data={data}
        rotateXTicks={true}
        {...props}>

        { alternateBackground && (
          <Group>
            { bars.map( (bar, i ) => (
              i % 2 === 0 ? null : (
                <rect
                  fill={alternateBackground}
                  width={bar.width + (bar.width * barPaddingRatio)}
                  height={yMax}
                  x={bar.x - (bar.width * (barPaddingRatio / 2))}
                  y={0} />
              )
            ))}
          </Group>
        )}

        <Group>

          <AnimatedBars
            bars={bars}
            animate={animate}
            animateInitial={animateInitial}
            xScale={xScale}
            yScale={yScale}
            maxHeight={yMax}
            onSegmentClick={onSegmentClick}
            onSegmentMouseOver={onSegmentMouseOver}
            svgBarProps={svgBarProps}
            fillOverride={fillOverride} />

        </Group>

        { !!(annotations && annotations.length) && (
          <Annotations
            annotations={annotations}
            xScale={xScale}
            yScale={yScale}
            width={xMax}
            height={yMax}
            xOffset={canonicalXOffset} />
        )}

      </GenericAxisChart>

      { foregroundElement || null }

    </svg>
  )
}

Histogram.displayName = 'Histogram'
