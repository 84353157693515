import React, { useState } from 'react'

import {
  Box,
  ClassNameMap,
  Health,
  IconButton,
  LazySearch,
  makeAppStyles,
  OverridingNodeComponentProps,
  PlainTextButton,
  TreeView,
  Typography,
} from '@percept/mui'

import { ArrowDropDown, ArrowDropUp } from '@percept/mui/icons'

import { InsightsReportTreeHierarchy, useFilteredHierarchy } from './lib'

import { get } from 'lodash-es'

import { InsightsReportType } from '@percept/types'


type InsightsReportTreeClassName = (
  'search' | 'section' | 'expander' | 'orgLeaf' | 'orgContainer' | 'buttonLabel' | 'typography' |
  'seriesVariant'
)

type InsightsReportTreeClassNameMap = ClassNameMap<InsightsReportTreeClassName>

type InsightsReportTreeNodeProps = (
  OverridingNodeComponentProps<InsightsReportTreeHierarchy> & {
    insightsReportTreeClasses: InsightsReportTreeClassNameMap
    showSectionHealth: boolean
  }
)


const useStyles = makeAppStyles<{}, InsightsReportTreeClassName>( theme => ({
  search: {
    backgroundColor: theme.palette.background.paper,
    zIndex: 2,
  },
  section: {
    display: 'flex',
    alignItems: 'center',
  },
  expander: {
    marginRight: theme.spacing(2),
  },
  orgContainer: {
    marginTop: theme.spacing(2),
  },
  orgLeaf: {
    marginTop: theme.spacing(1),
  },
  typography: {
    fontSize: 14,
  },
  buttonLabel: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  seriesVariant: {
    position: 'relative',
    top: -1,
    marginLeft: theme.spacing(1),
  },
}))


function InsightsReportTreeNodeComponent(props: InsightsReportTreeNodeProps): JSX.Element {

  const {
    name,
    health,
    expanded,
    path,
    showSectionHealth,
    active,
    onClick,
    onExpand,
    style,
    members,
    insightsReportTreeClasses,
  } = props

  const isContainer = !!members

  const className = [
    insightsReportTreeClasses.section,
    isContainer ? insightsReportTreeClasses.orgContainer : insightsReportTreeClasses.orgLeaf
  ].join(' ')

  const showHealth = showSectionHealth ? true : !!(path && path.length > 2)

  const clickHandler = onClick ? ((): void => onClick(props)) : undefined

  return (
    <div
      className={className}
      style={style}>
      
      { !!(onExpand && isContainer) && (
        <IconButton
          onClick={onExpand}>
          { expanded ? <ArrowDropDown /> : <ArrowDropUp /> }
        </IconButton>
      )}
      
      <PlainTextButton
        size='medium'
        variant={active ? 'contained' : 'text'}
        color={active ? 'secondary' : 'default'}
        classes={{
          label: insightsReportTreeClasses.buttonLabel,
        }}
        onClick={clickHandler}
        className={insightsReportTreeClasses.typography}>
        { name }
        { showHealth && health !== null && (
          <Box ml={1}>
            <Health
              showBackground
              value={health}
              fontSize='1em' />
          </Box>
        )}
      </PlainTextButton>
    </div>
  )
}


export type InsightsReportTreeProps = {
  insightsReportTree: InsightsReportTreeHierarchy
  onNodeClick: (nodeRepr: InsightsReportTreeHierarchy) => void
  showSectionHealth: boolean
  activeIds?: string[]
}


export const InsightsReportTree = ({
  insightsReportTree,
  showSectionHealth,
  activeIds,
  onNodeClick,
}: InsightsReportTreeProps): JSX.Element => {

  const [query, setQuery] = useState('')

  const filteredHierarchy = useFilteredHierarchy(
    insightsReportTree,
    query
  )

  const insightsReportTreeClasses = useStyles()

  return (
    <Box
      display='flex'
      flexDirection='column'
      maxHeight='calc(100vh - 100px)'
      overflow='auto'>

      <Box
        className={insightsReportTreeClasses.search}
        position='sticky'
        top={0}
        paddingBottom={1}
        paddingTop={1}
        paddingX={2}>
        <LazySearch
          autoFocus
          fullWidth
          value={query}
          placeholder='Search...'
          onReset={(): void => {
            setQuery('')
          }}
          onChange={(e): void => {
            setQuery(e.target.value)
          }} />
      </Box>

      { !!(filteredHierarchy && get(filteredHierarchy.members, 'length') === 0 && query) && (
        <Box
          my={2}
          zIndex={3}
          paddingX={2}>
          <Typography
            variant='h6'
            color='textSecondary'>
            No activity matches this query
          </Typography>
        </Box>
      )}

      { filteredHierarchy && (
        <TreeView
          hierarchy={filteredHierarchy}
          defaultDepth={-1}
          nodeMargin={24}
          paddingRight={2}
          paddingLeft={1}
          marginTop={-2.5}
          onNodeClick={onNodeClick}
          activeIds={activeIds}
          NodeComponent={(props): JSX.Element => (
            <InsightsReportTreeNodeComponent
              insightsReportTreeClasses={insightsReportTreeClasses}
              showSectionHealth={showSectionHealth}
              {...props as unknown as OverridingNodeComponentProps<InsightsReportType>} />
          )} />
      )}
    </Box>
  )
}
