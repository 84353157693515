
import { Selector } from 'react-redux'

import { TokenState } from './typings'

import { DateType, NormalizedError } from '@percept/types'


type StoreWithToken = {
  token: TokenState
}


export const getToken: Selector<StoreWithToken, TokenState> = state => (
  state.token
)

export const getTokenCreatedAt: Selector<StoreWithToken, DateType | null> = state => (
  state.token.createdAt
)

export const getTokenError: Selector<StoreWithToken, NormalizedError | null> = state => (
  state.token.error
)
