import React, { Fragment } from 'react'

import {
  BackdropLoader,
  BackdropProps,
  Box,
  Grid,
} from '@percept/mui'

import { PageHeader } from 'components/PageHeader'

import { StructuralDiagnostics } from './StructuralDiagnostics'

import { useLayoutGridSpacing } from 'hooks'

import { useActiveOrgEnvironment } from 'components/Organisation'

import { useInsights } from './lib'

import { useGridStyles } from './styles'

import { isLoading } from '@percept/utils'

import { DiagnosticsGridFragmentProps } from './typings'


export const Diagnostics = (): JSX.Element => {

  const { loading, activePlatformUnit } = useActiveOrgEnvironment()

  const seriesListing = activePlatformUnit && activePlatformUnit.report_series || []

  const org_unit_id = activePlatformUnit && activePlatformUnit.id

  const insights = useInsights({ org_unit_id })

  const ready = !loading && !isLoading(insights)

  const backdropProps: Partial<BackdropProps> = {
    open: !ready,
  }

  const gridSpacing = useLayoutGridSpacing()

  const gridClasses = useGridStyles()

  const gridFragmentProps: DiagnosticsGridFragmentProps = {
    gridSpacing,
    gridClasses,
  }

  return (
    <Fragment>
      <Box my={4} mx={3}>
        <PageHeader />

        { ready && insights.data && (
          <Box mt={5}>
            <Grid container spacing={gridSpacing} alignItems='stretch'>
              <StructuralDiagnostics
                seriesListing={seriesListing}
                insights={insights.data}
                {...gridFragmentProps} />
            </Grid>
          </Box>
        )}
      </Box>

      <BackdropLoader BackdropProps={backdropProps} />
    </Fragment>
  )
}
