
import { createApiReducer } from '@percept/redux/reducers'

import {
  MUTATE_SERIES_GROUP,
  DELETE_SERIES_GROUP,
  CLEAR_MUTATE_SERIES_GROUP,
} from '../actions'

import { ApiStatusResponse, Reducer, SeriesGroup } from '@percept/types'

import { SeriesGroupState} from '../typings'


const edit: Reducer<SeriesGroupState['edit']> = createApiReducer<SeriesGroup, ApiStatusResponse<SeriesGroup>>(MUTATE_SERIES_GROUP, { 
  process: true,
  processActionTypes: [MUTATE_SERIES_GROUP, DELETE_SERIES_GROUP],
  resetActionTypes: [CLEAR_MUTATE_SERIES_GROUP]
})

export default edit
