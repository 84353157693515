import { TemplatePlaceholder } from '../../api/types'
import { Segment } from '../../api/report-messages'

interface ReplaceMessagePlaceholdersParams {
  message: Segment
  placeholders: TemplatePlaceholder[]
}

export function replaceMessagePlaceholders({
  message,
  placeholders,
}: ReplaceMessagePlaceholdersParams): Segment {
  let replacedMessage = JSON.stringify(message)

  placeholders.forEach((placeholder) => {
    replacedMessage = replacedMessage.replaceAll(
      placeholder.placeholder,
      placeholder.value
    )
  })

  return JSON.parse(replacedMessage)
}
