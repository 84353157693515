
import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  REMOVE_ALL_NOTIFICATIONS,
  ADD_TOAST,
  REMOVE_TOAST,
  REMOVE_ALL_TOASTS,
} from './actions'

import { omit } from '@percept/utils'

import {
  ReduxAction,
  Notification,
  NotificationItemBase,
} from '@percept/types'

import { combineReducers, Reducer  } from 'redux'

import { NotificationState, NotificationItemState } from './typings'


const initialItemState: NotificationItemState<NotificationItemBase> = {
  byId: {},
  ids: []
}

const initialState: NotificationState = {
  notifications: initialItemState,
  toasts: initialItemState,
}


const makeNotificationItemsReducer = <T extends NotificationItemBase>(
  addAction: string,
  removeAction: string,
  removeAllAction: string,
): Reducer<NotificationItemState<T>, ReduxAction> => {

  const initialState: NotificationItemState<T> = {
    byId: {} as Record<T['id'], T>,
    ids: []
  }

  return (state = initialState, action): NotificationItemState<T> => {
    switch(action.type){
      
      case addAction:
        return {
          byId: {
            ...state.byId,
            [action.payload.id]: action.payload
          },
          ids: state.ids.includes(action.payload.id) ? state.ids : [...state.ids, action.payload.id]
        }

      case removeAction:
        return {
          byId: omit(state.byId, [action.payload.id]) as Record<T['id'], T>,
          ids: state.ids.filter( id => id !== action.payload.id )
        }

      case removeAllAction:
        return initialState

      default:
        return state
    
    }
  }
}


const notificationsBundleReducer: Reducer<NotificationState, ReduxAction> = combineReducers({
  notifications: makeNotificationItemsReducer(
    ADD_NOTIFICATION,
    REMOVE_NOTIFICATION,
    REMOVE_ALL_NOTIFICATIONS,
  ),
  toasts: makeNotificationItemsReducer(
    ADD_TOAST,
    REMOVE_TOAST,
    REMOVE_ALL_TOASTS,
  ),
})


let errorId = 0

const normalizeError = (err: { name: string, message: string } | Error): Pick<Notification, 'message' | 'name'> => (
  err instanceof Error ? {
    name: `${err.name} — ${err.message}`,
    message: err.stack || 'No stack trace provided.',
  } : err
)

export const withFetchErrorsReducer: Reducer<NotificationState, ReduxAction> = (state = initialState, action) => (
  notificationsBundleReducer(
    state,
    (!action.error || action.notifyError === false) ? action : {
      type: ADD_NOTIFICATION,
      payload: {
        id: `error-${++errorId}`,
        type: 'error',
        ...normalizeError(action.payload),
      }
    }
  )
)

export default notificationsBundleReducer
