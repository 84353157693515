import React from 'react'

import { AppTheme, AppThemeProvider, AppThemeProviderProps, darken } from '@percept/mui'

import { memoize, merge } from 'lodash-es'

import { ChannelKey } from '@percept/types'


export type SmartTableChannelThemeProviderProps = (
  Pick<
    AppThemeProviderProps,
    'children'
  > & {
    channel?: ChannelKey | null
  }
)


const getDataTableChannelTheme = memoize(
  (theme: AppTheme, channel?: ChannelKey | null): AppTheme => {
    /**
       * NOTE
       * 
       * We switch `primary` and `secondary` around here,
       * as all MUIDataTable UI is scoped to `primary` for the main accents
       * and we want a channel-specific theme.
       */
    const palette = {
      ...theme.palette,
      primary: channel ? theme.palette.channel[channel] : theme.palette.secondary,
      secondary: theme.palette.primary,
    }

    const muted = palette.action.disabledBackground

    const mutedPrimary = darken(palette.primary.main, 0.4)

    return {
      ...theme,

      palette,

      overrides: merge({
        MUIDataTable: {
          root: {
            overflowX: 'auto',
            // Simulate border with box-shadow - coupled with inset boxShadow below
            '& tbody:nth-of-type(1) tr:nth-of-type(1) td': {
              borderTop: 'none !important',
            },
          },
        },
        MUIDataTableToolbar: {
          root: {
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
          },
          icon: {
            marginLeft: theme.spacing(1.5),
          },
          filterPaper: {
            boxShadow: theme.shadows['16'],
          },
        },
        MUIDataTableSearch: {
          searchText: {
            color: theme.palette.secondary.main,
          }
        },
        MUIDataTableFilter: {
          root: {
            backgroundColor: theme.palette.background.paper,
          },
          gridListTile: {
            alignSelf: 'baseline',
            marginTop: theme.spacing(0.5),
            padding: `${theme.spacing(1.5)}px !important`,
          }
        },
        MUIDataTableHeadCell: {
          root: {
            padding: theme.spacing(0.75, 2),
            borderTop: 'none !important',
            borderBottom: 'none !important',
            // Simulate border with box-shadow
            boxShadow: `inset 0 1px 0 ${muted}, inset 0 -1px 0 ${muted}`,
            '&:first-of-type': {
              boxShadow: `inset 0 1px 0 ${mutedPrimary}, inset 0 -1px 0 ${mutedPrimary}`,
            },
          },
          toolButton: {
            // NOTE - we need to inherit color so theme-based fixed columns with other background colors
            // can specify a suitable contrast text color 
            color: 'inherit',
            fontSize: 12,
            padding: theme.spacing(0.5, 0.75),
          },
          sortActive: {
            color: 'inherit',
          },
          data: {
            whiteSpace: 'nowrap',
          },
          fixedHeader: {
            zIndex: 1,
          },
        },
        MuiTableSortLabel: {
          root: {
            color: 'inherit',
            '&$active': {
              color: 'inherit',
            },
          },
          active: {
            color: 'inherit',
          },
          icon: {
            color: 'inherit !important',
          },
        },
        MUIDataTableHeadRow: {
          root: {},
        },
        MuiTableCell: {
          root: {
            padding: theme.spacing(0, 2),
            whiteSpace: 'nowrap',
            color: 'inherit',
          },
          body: {
            color: 'inherit',
          },
        },
        MUIDataTableBodyCell: {
          root: {
          }
        },
        MUIDataTableFooter: {
          root: {
            borderTop: `1px solid ${muted}`,
          },
        },
      },
      theme.overrides)
    }
  }
)


/**
 * Nested SmartTable theme provider, requires `AppTheme` to be provided to an ancestor component
 */
export function SmartTableChannelThemeProvider({ channel, children }: SmartTableChannelThemeProviderProps): JSX.Element {
  return (
    <AppThemeProvider
      theme={(parentTheme): AppTheme => getDataTableChannelTheme(parentTheme, channel)}>
      { children }
    </AppThemeProvider>
  )
}
