
import {
  SET_SEARCH_QUERY,
  SAVE_SEARCH_QUERY
} from '../actions'

import { Reducer } from '@percept/types'

import { ClientReportState } from '../typings'


const initialState: ClientReportState['search'] = {
  query: '',
  saved: []
}


const search: Reducer<ClientReportState['search']> = (state = initialState, action) => {
  switch(action.type){

    case SET_SEARCH_QUERY:
      return { ...state, query: action.payload }
    
    case SAVE_SEARCH_QUERY:
      return { ...state, saved: [ action.payload, ...state.saved ] }

    default:
      return state

  }
}

export default search
