import React from 'react'

import { DefaultMediaWizardLayout } from './DefaultMediaWizardLayout'

import { WastageTrendReports as WastageTrendReportsComponent } from 'components/WastageTrendReports'
import { Breadcrumb } from 'components/Links'

import { useDocumentTitle } from '@percept/hooks'


const breadcrumbs: Breadcrumb[] = [
  {
    path: '/wizard/reporting',
    name: 'Reporting',
  },
  {
    path: '/wizard/reporting/wastage-trend-reports',
    name: 'Wastage Trend Reports',
  },
]


export const WastageTrendReports = (): JSX.Element => {
  useDocumentTitle({ paths: ['Reporting', 'Wastage Trend Reports'] })
  return (
    <DefaultMediaWizardLayout
      breadcrumbs={breadcrumbs}>

      <WastageTrendReportsComponent />

    </DefaultMediaWizardLayout>
  )
}
