
import {
  SET_ACTIVE_SERIES_REPORT_METRIC,
  CLEAR_ACTIVE_SERIES_REPORT_METRIC,
} from '../../actions'

import {
  Reducer,
} from '@percept/types'

import { SeriesState } from '../../typings'


const active: Reducer<SeriesState['timeSeries']['active']> = (state = null, action) => {

  switch(action.type){

    case SET_ACTIVE_SERIES_REPORT_METRIC:
      return {
        ...(state || {}),
        ...action.payload
      }

    case CLEAR_ACTIVE_SERIES_REPORT_METRIC:
      return null

    default:
      return state

  }

}

export default active
