

import React from 'react'

import { useSpring, OpaqueInterpolation } from 'react-spring'

import { makeAppStyles } from '../../themes'

import { AnimatedBlob } from './AnimatedBlob'

import { AnimatedPercentage } from './AnimatedPercentage'

import { PercentageGaugeTypeProps } from './lib'


const useStyles = makeAppStyles({
  root: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  valueWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
})


export type PercentageBlobProps = (
  Pick<
    PercentageGaugeTypeProps,
    'value' | 'formatter'
  > &
  Pick<
    React.CSSProperties,
    'color' | 'fontSize'
  > & {
    size: number
    width?: number
    height?: number
    textColor?: 'gauge' | string
  }
)


export function PercentageBlob({
  value,
  size,
  color = '',
  textColor = 'inherit',
  fontSize = '1em',
  formatter,
  width,
  height,
}: PercentageBlobProps): JSX.Element {

  const originalValue = value

  const numberValue: number = value || 0

  const animatedProps = useSpring({
    value: numberValue,
    color,
    to: {
      value: numberValue,
      color,
    },
  })

  const classes = useStyles()

  return (
    <div
      className={classes.root}>

      <AnimatedBlob
        size={size}
        width={width}
        height={height}
        color={color} />

      <div className={classes.valueWrapper}>

        { originalValue !== null && (
          <AnimatedPercentage
            fontSize={fontSize}
            value={animatedProps.value as OpaqueInterpolation<number>}
            formatter={formatter}
            color={textColor === 'gauge' ? animatedProps.color : textColor} />
        )}

      </div>

    </div>
  )
}
