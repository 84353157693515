import React from 'react'
import { Box } from '@material-ui/core'
import { Loader, makeAppStyles } from '@percept/mui'
import { ReportService } from '../../api/reports'
import {
  InvestForImpactTimeline,
  MarketsSegmentationOverview,
  MarketsSegmentationTable,
} from './components'

const useStyles = makeAppStyles({
  grid: {
    display: 'grid',
    gap: '48px',
  },
})


export const AllMarketsPage = (): JSX.Element | null => {
  const classes = useStyles()

  const {
    data: marketsSegmentation,
    isError,
  } = ReportService.useMarketsSegmentation()

  if (isError) return <>An error occurred getting markets segmentation</>
  if (!marketsSegmentation) return <Loader preset='centered' height='10rem' />

  return (
    <Box className={classes.grid}>
      <MarketsSegmentationOverview marketsSegmentation={marketsSegmentation} />

      <MarketsSegmentationTable marketsSegmentation={marketsSegmentation} />

      {/* <InvestForImpactTimeline /> */}
    </Box>
  )
}
