
import React from 'react'

import { Box, BoxProps } from '@material-ui/core'

import {
  normalizeHealth,
  isNumber,
  round
} from '@percept/utils'

import { Health } from '../Health'

import { PercentageGauge } from '../Gauge'

import { HealthType } from '@percept/types'


export type ReportHealthProps = {
  health: HealthType
  width?: string
  height?: string
  orientation?: 'horizontal' | 'vertical'
  fontSize?: string
  className?: string
  alignItems?: 'center' | 'baseline' | 'flexEnd' | 'flexStart'
  scoreOnRight?: boolean
  chartClassName?: string
  animate?: boolean
} & BoxProps

export const ReportHealth = ({
  health,
  height = '100%',
  width = '100%',
  orientation = 'horizontal',
  fontSize = '1.5rem',
  alignItems = 'center',
  scoreOnRight = false,
  chartClassName = '',
  animate = false,
  ...props }: ReportHealthProps): JSX.Element | null => {

  const normalizedHealth = normalizeHealth(health)

  if( !isNumber(normalizedHealth)  ){
    return null
  }

  const percentageValue = round(normalizedHealth * 100)

  const score = (
    <Box
      ml={scoreOnRight ? 1 : 0}
      alignItems={alignItems}
      height='100%'
      display='flex'
      justifyContent='center'>
      <Health
        animate={animate}
        value={normalizedHealth}
        fontSize={fontSize} />
    </Box>
  )

  return (
    <Box
      display='flex'
      fontSize={fontSize}
      alignItems={alignItems}
      {...props}>

      { !scoreOnRight && score }

      <PercentageGauge
        animate={animate}
        className={chartClassName}
        value={percentageValue}
        isHealthPercentage
        width={width}
        height={height}
        orientation={orientation} />

      { scoreOnRight && score }

    </Box>
  )
}
