import React from 'react'

import { Box, BoxProps } from '@percept/mui'

import { LinkButton } from 'components/Links/LinkButton'


export const NewsLink = (props: BoxProps): JSX.Element => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      my={3}
      {...props}>
      <LinkButton
        // dummy // NOTE - News route temporarily disabled
        to='/wizard/news'>
        See all news
      </LinkButton>
    </Box>
  )
}
