
import { useEffect, useCallback, useMemo } from 'react'

import { usePrevious } from './genericHooks'

import { useDispatch, useSelector } from 'react-redux'
 
import {
  shouldAttemptLoad,
  all,
  isArray,
  shallowEqual,
  isObject,
} from '@percept/utils'

import {
  getUserLoggedIn
} from '@percept/redux/bundles/user/selectors'

import { ClientReportState as ReportState } from '@percept/redux/bundles/clientReports/typings'
import { NotificationState } from '@percept/redux/bundles/notifications/typings'
import { SeriesGroupState } from '@percept/redux/bundles/seriesGroups/typings'
import { SeriesState } from '@percept/redux/bundles/clientSeries/typings'
import { UserState } from '@percept/redux/bundles/user/typings'

import {
  Dictionary,
  ReduxAction,
  AsyncReduxAction,
  Selector,
  ApiResponse,
  ApiLoader,
  ApiMutator,
  ApiStatusResponse,
  ClearApiStatus,
  AdwordsAccountHierarchy,
  ReduxDispatcher,
} from '@percept/types'


type UpdaterFunction = (...args: unknown[]) => void

type ResetterFunction = (...args: unknown[]) => void


type StoreState = (
  {
    seriesGroups: SeriesGroupState
  } & {
    series: SeriesState
  } & {
    reports: ReportState
  } & {
    user: UserState
  } & {
    notifications: NotificationState
  }
) & Dictionary



const EMPTY_OBJECT: {} = {}


export const normalizeAdwordsAccountHierarchy = (
  accounts: AdwordsAccountHierarchy[]
): Dictionary<AdwordsAccountHierarchy> => {
  const byId: Dictionary<AdwordsAccountHierarchy> = {}

  const collectAll = (m: AdwordsAccountHierarchy): void => {
    if( m.children ){
      m.children.forEach(collectAll)
    }else if( m.id && !m.is_manager ){
      byId[m.id] = m
    }
  }

  if( accounts && accounts.length ) accounts.forEach(collectAll)

  return byId
}

export const makeUpdateHook = <T>(
  getter: Selector<T>,
  setter: (...args: any[]) => ReduxAction,
) => (): [T, ReduxDispatcher<T>] => {
    const dispatch = useDispatch()

    const item = useSelector(getter)

    const updater = useCallback( (...args) => {
      dispatch(setter(...args))
    }, [dispatch])

    return [item, updater]
  }

export const makeUpdateHookWithArguments = <T>(
  getter: Selector<T>,
  setter: (...args: any[]) => ReduxAction,
) => (...args: unknown[]): [T, ReduxDispatcher<T>] => {
    const dispatch = useDispatch()

    const item = useSelector( state => getter(state, ...args))

    const updater = useCallback( (...cbArgs) => {
      dispatch(setter(...cbArgs))
    }, [dispatch])

    return [item, updater]
  }

export const makeUpdateAndResetHook = <T>(
  selector: Selector<T>,
  updater: UpdaterFunction,
  resetter: ResetterFunction,
) => (): [T, ReduxDispatcher<T>, ResetterFunction] => {
    const dispatch = useDispatch()

    const item = useSelector(selector)

    const itemUpdater = useCallback( (...args) => {
      dispatch(updater(...args))
    }, [dispatch])

    const itemResetter = useCallback( (...args) => {
      dispatch(resetter(...args))
    }, [dispatch])

    return [item, itemUpdater, itemResetter]
  }


export const makeSelectorHook = <T>(
  selector: Selector<T>
) => (
    (...args: unknown[]): T => (
      useSelector( (state: StoreState) => selector(state, ...args))
    )
  )

interface EntityHookProps<T, P>{
  selector?: Selector<ApiResponse<T>>,
  makeSelector?: (params: P) => (state: StoreState) => ApiResponse<T>,
  actionCreator: (params: P) => AsyncReduxAction,
  id?: string,
  deps?: string[],
  test?: ((props?: Dictionary<unknown>) => boolean) | boolean | string[],
}

interface EntityLoadHookProps<T = unknown, P = unknown> extends EntityHookProps<T, P>{
  autoload?: boolean,
}


export const makeEntityLoadHook = <T, P extends Dictionary>({
  selector,
  makeSelector,
  actionCreator,
  id,
  deps,
  test,
  autoload = true,
}: EntityLoadHookProps<T, P>) => (
    (props?: P): [ApiResponse<T>, ApiLoader<P>] => {

      const dispatch = useDispatch()

      const isLoggedIn = useSelector(getUserLoggedIn)

      const boundSelector = (
        isObject(props) && typeof makeSelector === 'function' ?
          makeSelector(props) :
          props && selector && id ? (
            (state: StoreState): ApiResponse<T> => selector(state, props[id])
          ) :
            selector
      )

      if( !boundSelector ){
        console.error('Could not resolve a selector function from entity load hook props')
        console.error('  ---  Given  ---  ')
        console.error({ selector, makeSelector, actionCreator, id, deps })
        throw new Error('Could not resolve a selector function from entity load hook props')
      }

      const entity = useSelector(boundSelector)

      const loadProps = (
        props ?
          deps && deps.length ?
            deps.reduce( (acc: Dictionary<unknown>, dep) => {
              acc[dep] = props[dep]
              return acc
            }, {}) :
            id ?
              { [id]: props[id] } :
              undefined :
          undefined
      )
      
      const previousLoadProps = usePrevious(loadProps)

      const stableLoadProps = (
        loadProps === previousLoadProps || loadProps && shallowEqual(loadProps, previousLoadProps) ?
          previousLoadProps : loadProps
      )

      const syncEntity = useCallback<ApiLoader<P>>( (params?: P): void => {
        if( !isLoggedIn || entity.loading ){
          return
        }

        const mergedParams: Dictionary<unknown> = { ...(stableLoadProps || {}), ...(params || {}) }

        if( test ){

          if( typeof test === 'function' && !test(mergedParams) ){
            return
          }else{

            const evaluableDeps: string[] = (
              isArray(test) ?
                test :
                isArray(deps) ?
                  deps:
                  []
            )

            const evaluate = Object.values(
              evaluableDeps.reduce( (acc: Dictionary<unknown>, t) => {
                acc[t] = mergedParams[t]
                return acc
              }, {})
            )

            if( evaluate.length && !all(evaluate) ){
              return
            }
          }
        }

        dispatch(actionCreator(mergedParams))
      }, [dispatch, entity, stableLoadProps, isLoggedIn])

      useEffect(() => {
        if( autoload && shouldAttemptLoad(entity) ){
          syncEntity()
        }
      }, [entity, syncEntity])

      return [entity, syncEntity]
    }
  )


interface EntityMutationHookProps<T, P> extends EntityHookProps<T, P> {
  selector?: Selector<ApiStatusResponse<T>>,
  makeSelector?: (params: P) => (state: StoreState) => ApiStatusResponse<T>,
  clearActionCreator: (...args: any[]) => ReduxAction,
}

export const makeEntityMutationHook = <T, P extends (Dictionary<unknown> | undefined) = undefined>({
  selector,
  makeSelector,
  actionCreator,
  clearActionCreator,
  id,
  deps,
  test
}: EntityMutationHookProps<T, P>) => (
    (props: P): [ApiStatusResponse<T>, ApiMutator<P>, ClearApiStatus<T>] => {

      const dispatch = useDispatch()

      const boundSelector = (
        isObject(props) && typeof makeSelector === 'function' ?
          makeSelector(props) :
          props && selector && id ? (
            (state: StoreState): ApiStatusResponse<T> => selector(state, props[id])
          ) : selector
      )

      if( !boundSelector ){
        console.warn('Could not resolve a selector function from entity mutation hook props')
        console.warn('  ---  Given  ---  ')
        console.warn({ selector, makeSelector, actionCreator, id, deps })
        throw new Error('Could not resolve a selector function from entity mutation hook props')
      }

      const mutation = useSelector(boundSelector)

      let filteredProps = EMPTY_OBJECT

      if( props ){
        if( deps && deps.length ){
          filteredProps = deps.reduce( (acc: Dictionary<unknown>, dep) => {
            acc[dep] = props[dep]
            return acc
          }, {})
        }
        if( id ){
          filteredProps = { [id]: props[id] }
        }
      }

      const previousFilteredProps = usePrevious(filteredProps)

      const stableFilteredProps = (
        shallowEqual(filteredProps, previousFilteredProps) ?
          previousFilteredProps : filteredProps
      )

      const mutateEntity = useCallback<ApiMutator<P>>( (params?: Dictionary) => {

        const mergedParams: Dictionary<unknown> = { ...stableFilteredProps, ...(params || {}) }

        if( test ){

          if( typeof test === 'function' && !test(mergedParams) ){
            return
          }else{

            const evaluableDeps: string[] = (
              isArray(test) ?
                test :
                isArray(deps) ?
                  deps:
                  []
            )

            const evaluate = Object.values(
              evaluableDeps.reduce( (acc: Dictionary<unknown>, t) => {
                acc[t] = mergedParams[t]
                return acc
              }, {})
            )

            if( evaluate.length && !all(evaluate) ){
              return
            }
          }
        }

        dispatch(actionCreator(mergedParams))
      }, [dispatch, stableFilteredProps])

      const clearEntityMutation = useCallback<ClearApiStatus<T>>( params => {
        dispatch(
          clearActionCreator(
            params || stableFilteredProps ?
              { ...stableFilteredProps || {}, ...params || {} } :
              undefined
          )
        )
      }, [dispatch, stableFilteredProps])

      return [mutation, mutateEntity, clearEntityMutation]
    }
  )


// Alias deletion hook creator to mutation hook creator
// - the behaviour for both is currently aligned, but may not be at some point in the future.
export const makeEntityDeletionHook = makeEntityMutationHook
