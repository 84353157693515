
import { createApiReducer } from '@percept/redux/reducers'

import { LOAD_SERIES_GROUPS } from '../actions'

import { seriesGroups } from '@percept/api/adapters'

import { SeriesGroupState } from '../typings'

import { Reducer } from '@percept/types'


const overview: Reducer<SeriesGroupState['overview']> = createApiReducer(LOAD_SERIES_GROUPS, { 
  getPayloadFromAction: ({ payload }) => seriesGroups(payload || [])
})

export default overview
