import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeAppStyles } from '@percept/mui'
import infoGraphicBrandGrowth from '../../images/infographics/brand-growth-2-light-bg.svg'

const useHeroStyles = makeAppStyles((theme) => ({
  title: {
    fontSize: 32,
    lineHeight: '32px',
    fontWeight: 700,
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.up('sm')]: {
      fontSize: 48,
      lineHeight: '48px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 56,
      lineHeight: '56px',
    },
  },
  description: {
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 400,
  },
  img: {
    width: '100%',
    maxWidth: '20rem',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  imgWrapper: {
    marginTop: '3rem',
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
}))

export const Hero = (): JSX.Element => {
  const classes = useHeroStyles()

  return (
    <Grid container alignItems='center'>
      <Grid item sm={6}>
        <Typography variant='h3' className={classes.title}>
          Welcome to the Brand Growth Planner
        </Typography>
        <Typography className={classes.description}>
          A dynamic tool for ongoing decision support that brings together data, best practices
          and global guidance to drive better focus, smarter investment & greater effectiveness of media.
        </Typography>
      </Grid>

      <Grid
        item
        container
        justify='center'
        sm={6}
        className={classes.imgWrapper}>
        <img className={classes.img} src={infoGraphicBrandGrowth} />
      </Grid>
    </Grid>
  )
}
