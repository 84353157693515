
import { apiInitialState, apiInitialStateWithProcessing, makeNestedSelector } from '@percept/redux'

import { Selector as TypedSelector } from 'react-redux'

import { getTimeSeriesKey } from '@percept/redux/utils'

import {
  getPath,
  uniqBy,
  // mapValues,
} from '@percept/utils'

import {
  createSelector,
  // createSelectorCreator,
  // defaultMemoize
} from 'reselect'

import {
  Selector,
  SeriesParams,
  ApiResponse,
  Series,
  SeriesReport,
  Report,
  ApiStatusResponse,
  AdwordsAccountHierarchy,
  EntityType,
  DimensionType,
  StackedChartType,
  Dictionary,
  SeriesMetric,
  ReportProvider,
  HydratedSeries,
  MetricParams,
  SeriesReportMetric,
} from '@percept/types'

import { getSeriesGroups } from '../seriesGroups/selectors'

import { SeriesState, ClientSeriesWrapper } from './typings'


// import { isEqual } from 'lodash-es'


// const createShallowEqualSelector = createSelectorCreator(
//   defaultMemoize,
//   isEqual
// )


type StoreWithSeries = {
  series: SeriesState
}


type SeriesSelectors = {
  getClientSeriesWrappers: Selector<Dictionary<ClientSeriesWrapper>>
  getSeries: Selector<ApiResponse<Series>>
  getSeriesReports: Selector<ApiResponse<SeriesReport[]>>

  // TODO: This is horrible! Need to fulfill admin app separately
  // due to issue with endpoint and pagination...
  getOriginalSeriesReports: Selector<ApiResponse<SeriesReport[]>>

  getCreateReport: Selector<ApiStatusResponse<Report>>
  getSeriesMutation: Selector<ApiStatusResponse<Series>>
  getSeriesRefreshToken: Selector<ApiStatusResponse<string>>
  getSeriesAccounts: Selector<ApiResponse<AdwordsAccountHierarchy[]>>
  getSeriesOAuthUrl: Selector<ApiResponse<string>>
  getSeriesMetrics: Selector<Dictionary<ApiResponse<SeriesMetric[]>>>
  getSeriesMetric: Selector<ApiResponse<SeriesMetric[]>>
  getActiveSeriesMetric: Selector<Dictionary | null>
  getActiveSeriesProvider: Selector<ReportProvider | null>
  getActiveReportOverviews: Selector<SeriesReport[]>
  getFilteredReportOverviews: Selector<SeriesReport[]>
  getSeriesProviderOptions: Selector<ReportProvider[]>
  // Export selector generators
  makeGetActiveReportOverviews: () => Selector<SeriesReport[]>
  makeGetSeriesProviderOptions: () => Selector<ReportProvider[]>
  makeGetFilteredReportOverviews: () => Selector<SeriesReport[]>
  makeGetActiveSeriesProvider: () => Selector<ReportProvider>
  getSeriesChartType: Selector<StackedChartType>
  getSeriesPerformanceTail: Selector<string | null>
  getSeriesHealthDimension: Selector<DimensionType>
}




type SeriesSelectorArgs = [string | null | undefined]


const uniqByProvider = uniqBy('provider')


type ClientSeriesSelector<Value, OwnProps = null> = TypedSelector<StoreWithSeries, Value, OwnProps>


export const getClientSeries: ClientSeriesSelector<
  ApiResponse<Series>, SeriesParams
> = (state, { series_id }) => (
  getPath(state.series.byId, [series_id, 'series'], apiInitialState)
)

export const getClientSeriesReports: ClientSeriesSelector<
  ApiResponse<SeriesReport[]>, SeriesParams
> = (state, { series_id }) => (
  getPath(state.series.byId, [series_id, 'reports'], apiInitialState)
)

export const getActiveClientSeriesReports: ClientSeriesSelector<
  SeriesReport[], SeriesParams
> = createSelector(
  getClientSeriesReports,
  (reports) => (
    reports.data ? reports.data.filter( r => r.status === 'COMPLETED' ) : []
  )
)

export const getClientSeriesOAuthUrl: ClientSeriesSelector<
  ApiResponse<string>, SeriesParams
> = (state, { series_id }) => (
  getPath(state.series.byId, [series_id, 'oAuthUrl'], apiInitialState)
)

export const getClientSeriesRefreshToken: ClientSeriesSelector<
  ApiStatusResponse<string>, SeriesParams
> = (state, { series_id }) => (
  getPath(state.series.byId, [series_id, 'refreshToken'], apiInitialStateWithProcessing)
)

export const getClientSeriesAdwordsAccounts: ClientSeriesSelector<
  ApiResponse<AdwordsAccountHierarchy>, SeriesParams
> = (state, { series_id }) => (
  getPath(state.series.byId, [series_id, 'accounts'], apiInitialState)
)

export const getSeriesChartType: ClientSeriesSelector<
  StackedChartType
> = (state) => (
  state.series.settings.chartType
)

export const getSeriesPerformanceTail: ClientSeriesSelector<
  string | null
> = (state) => (
  state.series.settings.performanceTail
)


export const getActiveSeriesReportMetric: ClientSeriesSelector<
  SeriesReportMetric | null
> = (state) => (
  state.series.timeSeries.active
)

export const getSeriesReportMetric: ClientSeriesSelector<
  ApiResponse<SeriesReportMetric[]>, SeriesParams & MetricParams
> = (state, params) => (
  getPath(
    state.series.timeSeries.byId,
    getTimeSeriesKey(params),
    apiInitialState
  )
)


export const makeSelectors = ({ raw = false } = {}): SeriesSelectors => {

  const statePath = raw ? ['series', 'raw'] : ['series']

  const getClientSeriesWrappers = (state: StoreWithSeries): Dictionary<ClientSeriesWrapper> => (
    getPath(state, [...statePath, 'byId']) || {}
  )

  const getClientSeriesWrapperById = (state: StoreWithSeries, series_id: string | null): ClientSeriesWrapper => getPath(
    getClientSeriesWrappers(state),
    series_id,
    {
      series: apiInitialState,
      reports: apiInitialState,
      accounts: apiInitialState,
      oAuthUrl: apiInitialStateWithProcessing,
      mutation: apiInitialStateWithProcessing,
      refreshToken: apiInitialStateWithProcessing,
      createReport: apiInitialStateWithProcessing,
    }
  )

  const getSeries = makeNestedSelector<ApiResponse<Series>, SeriesSelectorArgs>(
    getClientSeriesWrapperById, 'series'
  )

  const getOriginalSeriesReports = makeNestedSelector<ApiResponse<SeriesReport[]>, SeriesSelectorArgs>(
    getClientSeriesWrapperById, 'reports'
  )

  // TODO: Temporary patch for using series groups endpoint to populate series reports listing
  const getSeriesReports = createSelector(
    getSeriesGroups,
    (state: StoreWithSeries, series_id: string) => (series_id),
    (seriesGroups, series_id): ApiResponse<SeriesReport[]> => {

      if( !seriesGroups.data ){
        return {
          ...seriesGroups,
          data: null,
        }
      }

      let matchedSeries: HydratedSeries | null = null
      for( const group of seriesGroups.data ){
        for( const series of (group.series || []) ){
          if( series.series_id === series_id ){
            matchedSeries = series
            break
          }
        }
      }

      return {
        ...seriesGroups,
        data: matchedSeries ? matchedSeries.reports : [],
      }
    }
  )

  const getSeriesMutation = makeNestedSelector<ApiStatusResponse, SeriesSelectorArgs>(
    getClientSeriesWrapperById, 'mutation'
  )

  const getSeriesOAuthUrl = makeNestedSelector<ApiResponse<string>, SeriesSelectorArgs>(
    getClientSeriesWrapperById, 'oAuthUrl'
  )

  const getSeriesRefreshToken = makeNestedSelector<ApiStatusResponse, SeriesSelectorArgs>(
    getClientSeriesWrapperById, 'refreshToken'
  )

  const getSeriesAccounts = makeNestedSelector<ApiResponse<AdwordsAccountHierarchy[]>, SeriesSelectorArgs>(
    getClientSeriesWrapperById, 'accounts'
  )

  const getCreateReport = makeNestedSelector<ApiStatusResponse<Report>, SeriesSelectorArgs>(
    getClientSeriesWrapperById, 'createReport'
  )

  type SeriesMetricProps = {
    series_id: string | null,
    entity_type: EntityType | null,
    entity_id: string | number | null,
    metric_id: string | null,
    dimension: DimensionType | null,
    cursor?: string,
    limit?: number
  }
  
  const getSeriesMetric = (state: StoreWithSeries, {
    series_id,
    entity_id,
    metric_id,
    entity_type,
    dimension,
    cursor = '',
    limit = 0,
  }: SeriesMetricProps): ApiResponse<SeriesMetric[]> => (
    getPath(
      state,
      [
        ...statePath,
        'timeSeries',
        'byId',
        getTimeSeriesKey({ series_id, entity_type, entity_id, metric_id, dimension, cursor, limit }),
      ],
      apiInitialState
    )
  )

  const getSeriesMetrics = (state: StoreWithSeries): Dictionary<ApiResponse<SeriesMetric[]>> => (
    getPath(
      state,
      [
        ...statePath,
        'timeSeries',
        'byId'
      ],
      {}
    )
  )

  // const getSeriesMetricsById = createShallowEqualSelector(
  //   (state, params) => {
  //     const timeSeriesById = getPath(state, [...statePath, 'timeSeries', 'byId']) || {}
  //     return Object.keys(timeSeriesById).reduce()
  //   }
  // )
  
  const getActiveSeriesMetric = (state: StoreWithSeries) => state.series.timeSeries.active
  
  const getProviderBySeriesId = (state: StoreWithSeries, series_id: string) => (
    state.series.activeProvider[series_id]
  )

  const makeGetSeriesProviderOptions = () =>
    createSelector(
      getSeriesReports,
      reports =>
        uniqByProvider(
          (reports.data || []).filter( o => o.status !== 'SCHEDULED' )
        ).map( o => o.provider )
    )

  const getSeriesProviderOptions = makeGetSeriesProviderOptions()


  const makeGetActiveSeriesProvider = () =>
    createSelector(
      [getProviderBySeriesId, getSeriesProviderOptions],
      (provider, options) =>
        provider ? provider : options.length === 1 ? options[0] : 'adwords'
    )

  const getActiveSeriesProvider = makeGetActiveSeriesProvider()


  const makeGetFilteredReportOverviews = () =>
    createSelector(
      getSeriesReports,
      seriesReports => (seriesReports.data || []).filter( o => o.status !== 'SCHEDULED' )
    )
        


  const getFilteredReportOverviews = makeGetFilteredReportOverviews()


  const makeGetActiveReportOverviews = () =>
    createSelector(
      getFilteredReportOverviews,
      overviews => overviews.filter( o => o.status === 'COMPLETED' )
    )

  const getActiveReportOverviews = makeGetActiveReportOverviews()

  const getSeriesChartType = (state: StoreWithSeries): StackedChartType => (
    state.series.settings.chartType
  )

  const getSeriesPerformanceTail = (state: StoreWithSeries): string | null => (
    state.series.settings.performanceTail
  )

  const getSeriesHealthDimension = (state: StoreWithSeries): DimensionType => (
    state.series.settings.healthDimension || 'count'
  )

  return {
    getClientSeriesWrappers,
    getSeries,
    getSeriesReports,

    // TODO: This is horrible! Need to fulfill admin app separately
    // due to issue with endpoint and pagination...
    getOriginalSeriesReports,

    getCreateReport,
    getSeriesMutation,
    getSeriesRefreshToken,
    getSeriesAccounts,
    getSeriesOAuthUrl,
    getSeriesMetrics,
    getSeriesMetric,
    getActiveSeriesMetric,
    getActiveSeriesProvider,
    getActiveReportOverviews,
    getFilteredReportOverviews,
    getSeriesProviderOptions,
    // Export selector generators
    makeGetActiveReportOverviews,
    makeGetSeriesProviderOptions,
    makeGetFilteredReportOverviews,
    makeGetActiveSeriesProvider,
    getSeriesChartType,
    getSeriesPerformanceTail,
    getSeriesHealthDimension,
  }

}


export default makeSelectors()
