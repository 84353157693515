import React from 'react'

import { ChannelLayout } from './ChannelLayout'

import { ChannelViewManager } from '../components/Channel'

import { useLocationParams } from '@percept/hooks'


export const ChannelScreen = (): JSX.Element => {

  const { channel } = useLocationParams()

  return (
    <ChannelLayout channel={channel}>
      <ChannelViewManager channel={channel} />
    </ChannelLayout>
  )
}

export default ChannelScreen
