
import * as actions from './actions'
import reducer, { withFetchErrorsReducer } from './reducer'
import * as selectors from './selectors'

import { ReduxBundle, Bundler } from '../lib'

import { NotificationState } from './typings'


type NotificationActions = typeof actions
type NotificationSelectors = typeof selectors


export type NotificationBundle = ReduxBundle<NotificationState, NotificationActions, NotificationSelectors>


export { actions, reducer, selectors }


export const notificationsWithFetchErrors = {
  actions,
  reducer: withFetchErrorsReducer,
  selectors
}

export const makeBundle: Bundler<NotificationBundle, { allErrors?: boolean }> = ({ allErrors = false } = {}) => ({
  actions,
  reducer: allErrors ? withFetchErrorsReducer : reducer,
  selectors
})

export default { actions, reducer, selectors } as NotificationBundle