import { useMemo } from 'react'

import {
  DisplayDataset,
  DoubleVerifyPerformanceDatasets,
  PerformanceDatasets,
  PerformanceDimensionDataset,
} from 'types'

import { mapValues, sortBy } from 'lodash-es'

import { isPlatformUnitContainer } from '@percept/utils'

import { deriveRelativePerformanceComparisons } from 'components/Organisation/lib'

import { reportProviders } from '@percept/constants'

import {
  AnyPerformanceDimension,
  ChannelKey,
  DoubleVerifyPerformanceComparisons,
  DoubleVerifyPerformanceReportingDimension,
  DoubleVerifyProviderPerformanceTimeseries,
  PerformanceComparisons,
  PerformanceRangeKey,
  PerformanceValue,
  PlatformUnit,
  PrimaryPerformanceDataProvider,
  ProviderPerformanceTimeseries,
  ReportProvider,
} from '@percept/types'

import { AppTheme } from '@percept/mui'


export const isStructuralReportProvider = (
  performanceDataProvider: PrimaryPerformanceDataProvider
): performanceDataProvider is ReportProvider => (
  reportProviders.includes(performanceDataProvider as ReportProvider)
)


export const scaleValue = ({
  value,
  domain: [min, max] = [0, 1],
  range: [lower, upper] = [0, 1],
}: {
  value: number | null | undefined
  range?: [number, number]
  domain?: [number, number]
}): number => {
  const scaled = (
    ( (upper - lower) * ((value || 0) - min) ) / (max - min)
  ) + lower

  return scaled
}


export const usePlatformUnitDatasets = ({
  platformUnit,
  timeseriesByUnit,
  doubleVerifyTimeseriesByUnit,
  comparisonsByUnit,
  doubleVerifyComparisonsByUnit,
  currency,
  channel,
  appTheme,
}: {
  platformUnit: PlatformUnit | null
  timeseriesByUnit: Record<string, ProviderPerformanceTimeseries | null> | null
  doubleVerifyTimeseriesByUnit: Record<string, DoubleVerifyProviderPerformanceTimeseries | null> | null
  comparisonsByUnit: Record<string, PerformanceComparisons | null> | null
  doubleVerifyComparisonsByUnit: Record<string, DoubleVerifyPerformanceComparisons | null> | null
  currency: string | null
  channel?: ChannelKey | null
  appTheme: AppTheme
}): DisplayDataset<{ color: string }>[] => {

  return useMemo(() => {

    if( !timeseriesByUnit || !comparisonsByUnit || !platformUnit ){
      return []
    }

    const color = (
      channel && appTheme.palette.channel[channel].main
      || appTheme.palette.secondary.main
    )

    const entries: Pick<PlatformUnit, 'id' | 'name'>[] = (
      isPlatformUnitContainer(platformUnit) ?
        platformUnit.children :
        [platformUnit]
    )

    return entries.map( ({ id, name }) => {

      let datasets: PerformanceDatasets | null = null

      let doubleVerifyDatasets: DoubleVerifyPerformanceDatasets | null = null

      const unitEntry = timeseriesByUnit[id]

      const sourceDatasets = unitEntry && unitEntry.datasets

      const comparisons = comparisonsByUnit && comparisonsByUnit[id]

      if( sourceDatasets ){

        datasets = mapValues(
          sourceDatasets, (timeseries, dimension: AnyPerformanceDimension) => {

            const relative = comparisons ? deriveRelativePerformanceComparisons({
              comparisons,
              dimension,
              /**
               * NOTE - temporarily nullify adform 365d comparisons
               * and all CPV comparisons
               */
              nullifyKeys: channel === 'programmatic' ? [
                'year',
                ...(dimension === 'cpv' && [
                  'now',
                  'month',
                  'week',
                ] || []),
              ].filter( (d): d is PerformanceRangeKey => !!d ) : [],
            }) : {}

            return {
              key: `${dimension}-${id}`,
              label: name,
              currency,
              timeseries,
              relative,
            } as PerformanceDimensionDataset
          }) as unknown as Record<AnyPerformanceDimension, PerformanceDimensionDataset>
      }

      const doubleVerifyUnitEntry = doubleVerifyTimeseriesByUnit && doubleVerifyTimeseriesByUnit[id]

      const doubleVerifySourceDatasets = doubleVerifyUnitEntry && doubleVerifyUnitEntry.datasets

      const doubleVerifyComparisons = doubleVerifyComparisonsByUnit && doubleVerifyComparisonsByUnit[id]

      if( doubleVerifySourceDatasets ){

        doubleVerifyDatasets = mapValues(
          doubleVerifySourceDatasets, (timeseries, dimension: DoubleVerifyPerformanceReportingDimension) => {

            const relative = doubleVerifyComparisons ? deriveRelativePerformanceComparisons({
              comparisons: doubleVerifyComparisons,
              dimension,
              /**
               * NOTE - temporarily nullify adform 365d comparisons
               * and all CPV comparisons
               */
              nullifyKeys: channel === 'programmatic' ? [
                'year',
              ].filter( (d): d is PerformanceRangeKey => !!d ) : [],
            }) : {}

            return {
              key: `${dimension}-${id}`,
              label: name,
              currency,
              timeseries,
              relative,
            } as PerformanceDimensionDataset
          }) as unknown as Record<DoubleVerifyPerformanceReportingDimension, PerformanceDimensionDataset>
        
        if( datasets ){
          
          datasets = {
            ...datasets,
            ...doubleVerifyDatasets,
          }

        }

      }
        
      return {
        key: id,
        label: name,
        datasets,
        color
      }
    }) as DisplayDataset<{ color: string }>[]

  }, [
    platformUnit, currency, appTheme, channel,
    timeseriesByUnit, doubleVerifyTimeseriesByUnit,
    comparisonsByUnit, doubleVerifyComparisonsByUnit,
  ])
}


export const getDatasetSortValue = ({
  dataset,
  rangeKeys,
  filter = 'abs',
}: {
  dataset: PerformanceDimensionDataset | null
  rangeKeys?: PerformanceRangeKey[]
  filter?: 'min' | 'max' | 'abs'
}): number | null | undefined => {

  if( !dataset ){
    return null
  }

  const rangeMap: Record<
    'now' | PerformanceRangeKey, PerformanceValue
  > = dataset.relative

  const peakSignalKeys: PerformanceRangeKey[] = (
    rangeKeys || ['week', 'month', 'year']
  )

  const deltas = peakSignalKeys.map( k => (
    rangeMap[k] || 0
  ))

  let normalizedDeltas: number[] = []

  switch(filter){
    case 'abs':
      normalizedDeltas = deltas.map(Math.abs)
      break

    case 'min':
      normalizedDeltas = deltas.map( d => d * -1 )
      break

    default:
      normalizedDeltas = deltas
  }

  if( !normalizedDeltas.length ) return null

  const maximumPeak = Math.max(...normalizedDeltas)

  return maximumPeak * -1
}


export const getDatasetsSortValue = ({
  datasets,
  key = 'cost',
  filter = 'abs',
}: {
  datasets: PerformanceDatasets | null
  key: keyof PerformanceDatasets
  filter?: 'min' | 'max' | 'abs'
}): number | null | undefined => {

  return getDatasetSortValue({
    dataset: datasets && datasets[key],
    filter,
  })
}


export const pluckPerformanceDatasets = (
  datasets: DisplayDataset[],
  key: keyof PerformanceDatasets,
  sorter: (dataset: DisplayDataset) => number | null | undefined
): { key: string, label: string, dataset: PerformanceDimensionDataset }[] => {
  const sorted = sortBy(
    datasets,
    sorter
  ).filter( d => !!d.datasets && d.datasets[key] )

  return sorted.map( ({ datasets, ...rest }) => {
    return {
      ...rest,
      dataset: {
        ...datasets[key],
        timeseries: datasets[key].timeseries.map( d => ({
          ...d,
        })),
        relative: {
          ...datasets[key].relative,
          label: rest.label,
        }
      }
    }
  })

}


export const usePerformanceDatasets = ({
  datasets,
  currency,
  appTheme,
  dimension = 'cost'
}: {
  datasets: DisplayDataset[]
  currency: string
  appTheme: AppTheme
  dimension?: AnyPerformanceDimension
}): {
  key: string,
  label: string,
  color: string,
  dataset: PerformanceDimensionDataset,
  dimension: AnyPerformanceDimension
}[] => {

  return useMemo(() => {

    const sorted = pluckPerformanceDatasets(
      datasets,
      dimension,
      d => getDatasetsSortValue({
        datasets: d.datasets,
        key: dimension,
        filter: 'abs',
      })
    )

    const colourScale = appTheme.chart.getInformationalColourScale([0, sorted.length - 1])
    
    return sorted.map( ({ dataset, ...rest }, i) => {
      return {
        ...rest,
        dimension,
        color: colourScale(i),
        dataset: {
          ...dataset,
          currency,
          dimension,
          timeseries: dataset.timeseries.map( d => ({
            ...d,
            color: colourScale(i),
          })),
          relative: {
            dimension,
            ...dataset.relative,
            label: rest.label,
          }
        }
      }
    })

  }, [datasets, appTheme, dimension, currency])
}
