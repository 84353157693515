/** A module to house utilities we don't really want to persist,
 * but need to implement for various operational reasons.
*/

import { some } from 'lodash-es'

import { PlatformUnit } from '@percept/types'


export const isVodafonePlatformUnit = (platformUnit: PlatformUnit | null): boolean | null => (
  platformUnit && Boolean(platformUnit.name.startsWith('Voda'))
)


// Temporary hack to derive DoubleVerify-enabled state
const vodafoneDoubleVerifyNameFragments = [
  'Global',
  'AL',
  'CZ',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'PT',
  'RO',
  'ZA',
  'ES',
  'TR',
  'UK',
]


export const isDoubleVerifyPlatformUnit = (platformUnit: PlatformUnit): boolean => {
  if( !isVodafonePlatformUnit(platformUnit) ){
    return false
  }
  const { name } = platformUnit
  return some(vodafoneDoubleVerifyNameFragments, str => name.includes(str))
}
