import { Box } from '@material-ui/core'
import { Money } from '@percept/mui'
import { spacedPercentageFormatter } from '@percept/mui/charts'
import React from 'react'
import { Summary } from '../DashboardLayout'
import { ListItem } from '../types'
import { useStyles } from './TableCells'
import { AsteriskIcon } from '@percept/mui/icons'

export const TableTotalColumn = ({
  row,
  depth = 0,
  opend,
  dimensions,
  dataFormat,
}: {
  row: ListItem
  depth?: number
  opend: string[]
  dimensions: string[]
  dataFormat: string
}): JSX.Element => {
  const classes = useStyles()
  const isTotalRow = row.row_group === Summary.AllTotal || !!row.isTotalRow
  const className = [
    classes.cell,
    (
      isTotalRow
        ? classes.totalItem
        : row.data && depth === 0
          ? classes.groupItem
          : classes.item
    ),
    (row.data || isTotalRow) && classes.bold,
  ].filter(Boolean).join(' ')
  return (
    <Box>
      <div
        className={className}>
        {row.totalHasIncompleteData ? <AsteriskIcon className={classes.incomplete} fontSize='inherit' /> : null}
        {dataFormat === 'currency' ? (
          <Money
            amount={Number(row.total)}
            currency={row.total_currency}
          />
        ) : (
          spacedPercentageFormatter(row.total_percentage)
        )}
      </div>

      {opend.includes(row.row_group) && (
        <Box display='flex' flexDirection='column'>
          {(row.data ?? []).map((member) => (
            <TableTotalColumn
              key={member.data_type}
              dataFormat={dataFormat}
              row={{
                ...member,
                isTotalRow,
                row_group: isTotalRow ? row.row_group : member.data_type,
                costs: member.costs,
                total_currency: row.total_currency,
                total_percentage: member.average ? member.average : member.total_percentage
              }}
              depth={depth + 1}
              opend={[]}
              dimensions={dimensions}
            />
          ))}
        </Box>
      )}
    </Box>
  )
}
