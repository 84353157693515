import React from 'react'

import {
  ButtonProps,
  makeAppStyles,
  ProviderLogo,
  RoundedPlainTextButton,
} from '@percept/mui'
import { format } from 'date-fns'


export type DoubleVerifyReferenceDateTriggerProps = (
  ButtonProps & {
    referenceDate: Date
  }
)


const useStyles = makeAppStyles( theme => ({
  doubleVerifyTrigger: {
    marginTop: theme.spacing(2),
  },
  doubleVerifyLogo: {
    position: 'relative',
    top: 1,
    marginRight: theme.spacing(0.5),
  },
}) )


export const DoubleVerifyReferenceDateTrigger = ({
  referenceDate,
  ...props
}: DoubleVerifyReferenceDateTriggerProps): JSX.Element => {

  const classes = useStyles()

  return (
    <RoundedPlainTextButton
      variant='contained'
      color='secondary'
      {...props}>
      <ProviderLogo
        className={classes.doubleVerifyLogo}
        provider='doubleverify'
        size={1}
        units='em' />
      available up to { format(referenceDate, 'dd/MM/yy') }
    </RoundedPlainTextButton>
  )
}
