
import {
  getPath,
  getHealthFrom,
  getHealthAggregationsFrom,
  isNumber,
  mapValues,
} from '@percept/utils'

import { ReportPayload, Dictionary, ReportEntities } from '@percept/types'


const reportPayload = ({
  from,
  until,
  audit,
  summary,
  audit_summary,
}: Dictionary): ReportPayload => {

  const resolvedSummary = summary || audit_summary || {}

  const {
    performance = null,
    currency_code = null,
  } = resolvedSummary

  const health = getHealthFrom(resolvedSummary)

  const health_aggregations = getHealthAggregationsFrom(resolvedSummary)

  const isImpactWeighted = (
    isNumber(health_aggregations.count.impact_weighted_avg)
  )

  const unmappedEntities = getPath(audit, 'entities') as ReportEntities

  const entities = mapValues(
    unmappedEntities,
    (byType) => (
      mapValues(
        byType,
        byId => ({
          ...byId,
          derived: {
            attributes: (getPath(byId, ['derived', 'attributes']) || {}),
            performance: (getPath(byId, ['derived', 'performance']) || {}),
          },
        })
      )
    )
  )

  return {
    start: from,
    end: until,
    performance,
    health,
    health_aggregations,
    isImpactWeighted,
    currency_code,
    entities,
    root_entity_type: getPath(audit, 'root_entity_type'),
    root_entity_id: getPath(audit, 'root_entity_id'),
  }

}

export default reportPayload