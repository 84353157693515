
import {
  SET_TOKEN,
  RESET_TOKEN,
  SET_TOKEN_ERROR,
  RESET_TOKEN_ERROR,
} from './actions'

import { ReduxAction } from '@percept/types'

import { TokenState } from './typings'


const initialState: TokenState = {
  value: null,
  createdAt: null,
  expiresAt: null,
  error: null,
}


export default (state = initialState, action: ReduxAction): TokenState => {
  switch(action.type){

    case RESET_TOKEN:
      return initialState
    
    case SET_TOKEN:
      return {
        value: action.payload.value,
        createdAt: action.payload.value === state.value ? state.createdAt : action.payload.createdAt,
        expiresAt: action.payload.expiresAt,
        error: null
      }
    
    case RESET_TOKEN_ERROR:
      return {
        ...state,
        error: null
      }

    case SET_TOKEN_ERROR:
      return {
        ...initialState,
        error: action.payload || true
      }

    default:
      return state

  }

}