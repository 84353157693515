
import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'

import { ReduxBundle } from '../lib'

import { SeriesGroupState } from './typings'


type SeriesGroupActions = typeof actions
type SeriesGroupSelectors = typeof selectors


export type SeriesGroupBundle = ReduxBundle<SeriesGroupState, SeriesGroupActions, SeriesGroupSelectors>


export { actions, reducer, selectors }


export const makeBundle = (): SeriesGroupBundle => ({
  actions,
  reducer,
  selectors
})

export default { actions, reducer, selectors } as SeriesGroupBundle
