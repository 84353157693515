
import {
  apiInitialState,
  getFetchTypes,
} from '@percept/redux'

import { getMetricKeyFromAction } from '@percept/redux/utils'

import adapters from '@percept/api/adapters'

import {
  getAuditEntities,
  hasInlineExamples,
} from './lib'

import {
  getMetricMetadataById,
  applySegments,
} from '../lib'

import {
  getPath,
  include,
  getHealthFrom,
  mapValues,
} from '@percept/utils'

import {
  Dictionary,
  Reducer,
  ReduxAction,
  MetricMetadataType,
  ApiResponse,
  LegacyReportMetric,
  MetricTypeEnum,
} from '@percept/types'


// Reduce an action payload into a set of metric data
const storeInlineExamples = (
  action: ReduxAction,
  metricMetadata: MetricMetadataType | null
) => {
  const auditEntities = getAuditEntities(action.payload)

  const { metric_type } = action.meta || {}
  
  return Object.keys(auditEntities).reduce( (output: Dictionary<ApiResponse<LegacyReportMetric>>, entityType) => {

    const byId = auditEntities[entityType]

    Object.keys(byId).forEach( entityID => {

      const entity = byId[entityID]

      const metrics = entity.metrics || {}

      Object.keys(metrics).forEach( metricID => {

        const byDimension = adapters.metric(metrics[metricID])

        Object.keys(byDimension).forEach( dimension => {

          const storageKey = `${entityType}|${entityID}|${metricID}|${dimension}`,
                metricData = byDimension[dimension]

          output[storageKey] = {
            ...apiInitialState,
            data: metricMetadata ? {
              metric_id: metricID,
              metric_type: getPath(metricData, 'metric_type', metric_type),
              health: getHealthFrom(metricData),
              data: applySegments({
                data: metricData.data,
                metadata: getMetricMetadataById(metricMetadata, metricID)
              })
            } : ({
              metric_id: metricID,
              metric_type: getPath(metricData, 'metric_type', metric_type),
              health: getHealthFrom(metricData),
              data: metricData.data
            })
          }

        })

      })

    })

    return output

  }, {})
}


const createAuditMetricReducer = (
  { loadAuditType, loadMetricType, loadMetricMetadataType }:
  { loadAuditType: string, loadMetricType: string, loadMetricMetadataType: string }
): Reducer<Dictionary<ApiResponse<LegacyReportMetric>>> => {

  if( !loadAuditType || !loadMetricType ){
    throw new Error(
      'You must provide an options object with the keys `loadAuditType` & `loadMetricType` to createAuditMetricReducer()'
    )
  }

  /* Default split payload reducer generation */

  const auditMetricFetchTypes = getFetchTypes(loadMetricType)

  const metricMetadataFetchTypes = getFetchTypes(loadMetricMetadataType)

  const defaultKeyedReducer = (state: Dictionary<ApiResponse<LegacyReportMetric>> = {}, action: ReduxAction, metricMetadata: MetricMetadataType | null) => {
    const { meta = {} } = action,
          { metric_id, metric_type } = meta
    
    const storageKey = getMetricKeyFromAction(action)

    if( action.type === metricMetadataFetchTypes.success && Object.keys(state).length ){

      return mapValues(
        state,
        reportMetric => ({
          ...reportMetric,
          data: reportMetric.data ? {
            ...reportMetric.data,
            data: applySegments({
              data: reportMetric.data.data || null,
              metadata: getMetricMetadataById(action.payload, reportMetric.data.metric_id)
            })
            
          } : reportMetric.data,
        })
      )

    }
    
    if( storageKey ){

      if( action.type === auditMetricFetchTypes.success ){

        const parsed_metric_type = getPath(action.payload, 'metric_type', metric_type)

        const adaptedMetric = adapters.singleMetric({
          ...action.payload,
          metric_type: parsed_metric_type,
        })

        return {
          ...state,
          [storageKey]: {
            ...apiInitialState,
            data: {
              ...adaptedMetric,
              metric_id,
              data: applySegments({
                data: adaptedMetric.data,
                metadata: getMetricMetadataById(metricMetadata || [], metric_id)
              })
            }
          }
        }

      }

      if( action.type === auditMetricFetchTypes.start ){
        return {
          ...state, 
          [storageKey]: { ...apiInitialState, loading: true }
        }
      }

      if( action.type === auditMetricFetchTypes.error ){
        return {
          ...state,
          [storageKey]: { ...apiInitialState, error: action.payload }
        }
      
      }
    
    }

    return state

  }


  /* Single payload dummy API response reducer generation */

  const auditFetchTypes = getFetchTypes(loadAuditType)


  /* Main reducer */

  return (
    state: Dictionary<ApiResponse<LegacyReportMetric>> = {},
    action,
    metricMetadata: MetricMetadataType | null = null
  ) => {

    const { type, payload = {} } = action

    if( type === auditFetchTypes.success && hasInlineExamples(payload) ){
      return storeInlineExamples(action, metricMetadata)
    }

    return defaultKeyedReducer(state, action, metricMetadata)

  }


}


export default createAuditMetricReducer
