import React from 'react'
import { makeFlagIconComponent } from './lib'


export const ZA = makeFlagIconComponent(
  <>
    <defs>
      <clipPath id="ZA-a">
        <path fillOpacity=".7" d="M70.1 0h499.6v499.6H70.1z"/>
      </clipPath>
    </defs>
    <g clipPath="url(#ZA-a)" transform="translate(-71.9) scale(1.0248)">
      <g fillRule="evenodd" strokeWidth="1pt">
        <path d="M0 397.9v-296l220.4 147.9L0 397.9z"/>
        <path fill="#000c8a" d="m150.4 499.7 247.4-166.5h351.6v166.5h-599z"/>
        <path fill="#e1392d" d="M134.5 0h615v166.6H397.7S137.8-1.6 134.5 0z"/>
        <path fill="#ffb915" d="M0 62.5v39.3l220.4 148L0 397.8v39.4l277.6-187.4L0 62.5z"/>
        <path fill="#007847" d="M0 62.5V0h92.6l294 199h362.8v101.7H386.6l-294 198.9H0v-62.4l277.6-187.4L0 62.5z"/>
        <path fill="#fff" d="M92.6 0h57.8l247.4 166.6h351.6V199H386.6L92.6 0zm0 499.7h57.8l247.4-166.5h351.6v-32.4H386.6l-294 198.8z"/>
      </g>
    </g>
  </>
)
