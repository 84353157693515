import React, { Fragment } from 'react'

import { Box, Card, Divider, Typography, formatMoney } from '@percept/mui'

import { ValueText } from './ValueCalculation'

import { FormSection, useFormStyles } from './formComponents'

import { SponsorshipsMarketingCPMMetric, SponsorshipsMarketingCPMs } from './typings'


export type MarketingCPMsProps = {
  estimatedCPMs: SponsorshipsMarketingCPMs
}

export const marketingCPMMetrics: { metric: SponsorshipsMarketingCPMMetric, label: string }[] = [
  {
    metric: 'net_website_logged_out_cpm',
    label: 'Net Website Logged Out CPM',
  },
  {
    metric: 'net_website_logged_in_cpm',
    label: 'Net Website Logged In CPM',
  },
  {
    metric: 'net_app_logged_out_cpm',
    label: 'Net App Logged Out CPM',
  },
  {
    metric: 'net_app_logged_in_cpm',
    label: 'Net App Logged In CPM',
  },
  {
    metric: 'social_cpm',
    label: 'Social CPM',
  },
  {
    metric: 'ooh_cpm',
    label: 'OOH CPM',
  },
  {
    metric: 'ooh_big_screen_cpm',
    label: 'OOH Big Screen CPM',
  },
  {
    metric: 'email_cpm',
    label: 'Email CPM',
  },
  {
    metric: 'email_cpc',
    label: 'Email CPC',
  },
  {
    metric: 'radio_listens_cpl',
    label: 'Radio Listens CPL',
  },
  {
    metric: 'instore_promo_cpm',
    label: 'Instore Promo CPM',
  },
  {
    metric: 'facebook_cpm',
    label: 'Facebook CPM',
  },
  {
    metric: 'instagram_cpm',
    label: 'Instagram CPM',
  },
  {
    metric: 'twitter_cpm',
    label: 'Twitter CPM',
  },
  {
    metric: 'other_social_cpm',
    label: 'Other Social CPM',
  },
]

export const MarketingCPMs = ({
  estimatedCPMs
}: MarketingCPMsProps): JSX.Element => {

  const classes = useFormStyles()

  return (
    <Card className={classes.card} elevation={2}>
      <FormSection title='Market Average Value Estimation' bgcolor='primary.main' color='white'>
        <Box p={2}>
          <Typography variant='h4' className={classes.nestedCardTitle}>
            Estimated Marketing CPMs
          </Typography>
          <Typography className={classes.note} variant='body1'>
            <strong>Note:{' '}</strong>
            These values are used in the calculations and are defined by Dentsu annually.
          </Typography>
          {marketingCPMMetrics.map( ({ metric, label }, i) => (
            <Fragment key={metric}>
              <ValueText
                label={label}
                value={
                  formatMoney({
                    amount: estimatedCPMs.cpm_values[metric],
                    currency: estimatedCPMs.currency,
                    abbreviate: false,
                  })
                } />
              { i < (marketingCPMMetrics.length - 1) && <Divider /> }
            </Fragment>
          ))}
        </Box>
      </FormSection>
    </Card>
  )
}
