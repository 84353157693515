import React, { useMemo } from 'react'

import { ParentSize } from '@visx/responsive'

import { makeAppStyles, useAppTheme } from '@percept/mui'

import * as Flags from './flags'

import { capitalize, find, sortBy } from 'lodash-es'


export type BrandGrowthQuadrant = 'SCALE' | 'ENHANCE' | 'EVANGELIZE' | 'SUSTAIN'

export type BrandGrowthDatum = {
  label: string
  value: BrandGrowthQuadrant
  previousValue?: BrandGrowthQuadrant | null
}

export type BrandGrowthQuadrantChartProps = {
  markets: BrandGrowthDatum[]
}

const outerAxisMargin = 24
const innerAxisMargin = 24
const totalMargin = outerAxisMargin + innerAxisMargin


type QuadrantMarket = (
  {
    datum: BrandGrowthDatum
  }
  & Record<'r' | 'cx' | 'cy', number>
  & React.SVGAttributes<SVGCircleElement>
)

type QuadrantMarketConfig = {
  width: number
  height: number
  maxMarketsPerLine: number
}

const parseQuadrantMarkets = (
  markets: BrandGrowthDatum[],
  quadrant: BrandGrowthQuadrant,
  config: QuadrantMarketConfig,
): QuadrantMarket[] => {
  const filteredMarkets = markets.filter( m => (
    [m.value, m.previousValue].includes(quadrant)
  ))

  const sortOrder: [number, number] = (
    quadrant === 'EVANGELIZE' || quadrant === 'SCALE' ?
      [-1, 1] :
      [1, -1]
  )
  const sortIteratees = [
    (m: BrandGrowthDatum): number => m.value === quadrant ? sortOrder[0] : sortOrder[1],
    'label'
  ]
  const sortedMarkets = sortBy(filteredMarkets, sortIteratees)

  const circleSize = Math.min(config.width / 8, config.height / 3)
  const halfCircle = circleSize / 2
  const circleMargin = Math.min(config.width / 16, config.height / 12)
  const circleOffset = circleMargin + halfCircle
  const paddedCircleSize = circleSize + circleMargin

  const isTopAligned = ['SCALE', 'ENHANCE'].includes(quadrant)

  const linesRequired = Math.ceil(
    sortedMarkets.length / config.maxMarketsPerLine
  )
  const initialCenterY = (
    isTopAligned ?
      circleOffset :
      config.height - (paddedCircleSize * (linesRequired - 1)) - circleOffset
  )

  return sortedMarkets.map( (m, i) => {
    const circleNumber = i + 1

    let cy = initialCenterY
    let cx = circleOffset + (i * paddedCircleSize)

    if( circleNumber > config.maxMarketsPerLine ){
      const groupingDelta = Math.ceil(circleNumber / config.maxMarketsPerLine) - 1
      cy += (paddedCircleSize * (groupingDelta))
      cx = circleOffset + ((i - (groupingDelta * config.maxMarketsPerLine)) * paddedCircleSize)
    }

    return {
      datum: m,
      cx,
      cy,
      r: halfCircle,
      opacity: m.value === quadrant ? 1 : 0.25,
    }
  })
}


type ParsedQuadrant = (
  {
    quadrant: BrandGrowthQuadrant
    text: React.SVGAttributes<SVGTextElement>
    markets: QuadrantMarket[]
  }
  & Record<'x' | 'y', number>
  & React.SVGAttributes<SVGRectElement>
)


const useStyles = makeAppStyles( theme => ({
  tooltipContainer: {
    '&:hover > $tooltip': {
      opacity: 1,
    }
  },
  tooltip: {
    opacity: 0,
    transition: theme.transitions.create('opacity'),
    userSelect: 'none',
  },
}))


const BrandGrowthQuadrantSVG = ({
  width,
  height,
  markets,
}: BrandGrowthQuadrantChartProps & {
  width: number
  height: number
}): JSX.Element => {
  const appTheme = useAppTheme()

  const quadrantWidth = (width - totalMargin) / 2
  const quadrantHeight = (height - totalMargin) / 2

  const { quadrants, annotations } = useMemo(() => {
    const labelFontSize = Math.max(quadrantWidth / 20, quadrantHeight / 12)
    const labelTextProps = {
      fontSize: labelFontSize,
      fontWeight: 700,
      dominantBaseline: 'hanging',
    }
    const quadrantMarketConfig: QuadrantMarketConfig = {
      width: quadrantWidth,
      height: quadrantHeight,
      maxMarketsPerLine: 5,
    }
    const quadrants: ParsedQuadrant[] = [
      {
        quadrant: 'SCALE',
        text: {
          x: 10,
          y: quadrantHeight - labelFontSize - 10,
          ...labelTextProps,
        },
        x: totalMargin,
        y: quadrantHeight,
        fill: appTheme.chart.healthColourScale(0),
        markets: parseQuadrantMarkets(markets, 'SCALE', quadrantMarketConfig),
      },
      {
        quadrant: 'ENHANCE',
        text: {
          x: quadrantWidth - 10,
          y: quadrantHeight - labelFontSize - 10,
          textAnchor: 'end',
          ...labelTextProps,
        },
        x: totalMargin + quadrantWidth,
        y: quadrantHeight,
        fill: appTheme.chart.healthColourScale(0.5),
        markets: parseQuadrantMarkets(markets, 'ENHANCE', quadrantMarketConfig),
      },
      {
        quadrant: 'EVANGELIZE',
        text: {
          x: 10,
          y: 10,
          ...labelTextProps,
        },
        x: totalMargin,
        y: 0,
        fill: appTheme.chart.healthColourScale(0.5),
        markets: parseQuadrantMarkets(markets, 'EVANGELIZE', quadrantMarketConfig),
      },
      {
        quadrant: 'SUSTAIN',
        text: {
          x: quadrantWidth - 10,
          y: 10,
          textAnchor: 'end',
          ...labelTextProps,
        },
        x: totalMargin + quadrantWidth,
        y: 0,
        fill: appTheme.chart.healthColourScale(1),
        markets: parseQuadrantMarkets(markets, 'SUSTAIN', quadrantMarketConfig),
      }
    ]

    const annotations = quadrants.reduce( (acc, q) => {

      const requiresAnnotations = q.markets.filter( m => (
        m.datum.value !== q.quadrant
      ))

      for( const m of requiresAnnotations ){
        const { label, value } = m.datum
        const matchedQuadrant = find(
          quadrants,
          q => q.quadrant === value
        )
        if( matchedQuadrant ){
          const matchedNode = find(
            matchedQuadrant.markets,
            m => m.datum.label === label ? true : false
          )
          if( matchedNode ){

            const end = {
              x: matchedNode.cx + matchedQuadrant.x,
              y: matchedNode.cy + matchedQuadrant.y,
            }
            const start = {
              x: m.cx + q.x,
              y: m.cy + q.y,
            }

            let controlOffsetX = 100
            let startOffsetX = matchedNode.r
            let endOffsetX = startOffsetX * -1
            if( start.x > end.x ){
              controlOffsetX = -100
              startOffsetX *= -1
              endOffsetX *= -1
            }

            const controlOffsetY = matchedNode.r * -2.5

            const d = [
              'M',
              start.x + startOffsetX,
              start.y,
              'C',
              start.x + startOffsetX + controlOffsetX,
              start.y + controlOffsetY,
              end.x - controlOffsetX,
              end.y + controlOffsetY,
              end.x + endOffsetX,
              end.y,
            ].join(' ')
            acc.push({
              d,
              stroke: 'black',
              strokeWidth: 2,
              fill: 'none',
              markerEnd: 'url(#markerArrowLine)',
              opacity: 0.35,
            })
          }
        }
      }
      return acc
    }, [] as React.SVGAttributes<SVGPathElement>[])
    return { quadrants, annotations }
  }, [markets, quadrantWidth, quadrantHeight, appTheme])

  const axisFontSize = outerAxisMargin / 1.5

  const quadrantFontSize = innerAxisMargin / 2

  const classes = useStyles()

  return (
    <svg
      width={width}
      height={height}>
      <defs>
        <marker 
          id='markerArrowAxis'
          orient='auto' 
          markerWidth='8' 
          markerHeight='8' 
          fill={appTheme.chart.grid.stroke}
          refX='7'
          refY='4'>
          <polygon points='0 0, 8 4, 0 8' />
        </marker>
        <marker
          id='markerArrowLine'
          orient='auto'
          markerWidth='8'
          markerHeight='8'
          fill='black'
          refX='7'
          refY='4'>
          <polygon points='0 0, 8 4, 0 8' />
        </marker>
      </defs>
      {/* Axes */}
      <line
        x1={outerAxisMargin}
        x2={outerAxisMargin}
        y1={height - outerAxisMargin}
        y2={0}
        stroke={appTheme.chart.grid.stroke}
        strokeWidth={2}
        markerEnd='url(#markerArrowAxis)' />
      <line
        x1={outerAxisMargin}
        x2={width}
        y1={height - outerAxisMargin}
        y2={height - outerAxisMargin}
        stroke={appTheme.chart.grid.stroke}
        strokeWidth={2}
        markerEnd='url(#markerArrowAxis)' />
      {/* Axis Labels */}
      <text
        x={outerAxisMargin}
        y={height}
        fontSize={axisFontSize}
        dominantBaseline='auto'
        textAnchor='start'>
        Non-User Consideration
      </text>
      <text
        x={0}
        y={height - outerAxisMargin}
        fontSize={axisFontSize}
        dominantBaseline='hanging'
        transform={`rotate(-90 0 ${height - outerAxisMargin})`}
        textAnchor='start'>
        Net Promoter Score
      </text>
      {/* Quadrant Labels */}
      <text
        x={totalMargin}
        y={height - outerAxisMargin - (innerAxisMargin / 2)}
        fontSize={quadrantFontSize}
        dominantBaseline='central'
        textAnchor='start'>
        Challenger
      </text>
      <text
        x={totalMargin - (innerAxisMargin / 2)}
        y={height - totalMargin}
        fontSize={quadrantFontSize}
        dominantBaseline='central'
        transform={`rotate(-90 ${totalMargin - (innerAxisMargin / 2)} ${height - totalMargin})`}
        textAnchor='start'>
        Challenger
      </text>
      <text
        x={totalMargin + quadrantWidth}
        y={height - outerAxisMargin - (innerAxisMargin / 2)}
        fontSize={quadrantFontSize}
        dominantBaseline='central'
        textAnchor='start'>
        Leader
      </text>
      <text
        x={totalMargin - (innerAxisMargin / 2)}
        y={quadrantHeight}
        fontSize={quadrantFontSize}
        dominantBaseline='central'
        transform={`rotate(-90 ${totalMargin - (innerAxisMargin / 2)} ${quadrantHeight})`}
        textAnchor='start'>
        Leader
      </text>
      {/* Quadrants */}
      { quadrants.map( ({ quadrant, text: textProps, x, y, fill, markets }) => (
        <svg
          key={quadrant}
          x={x}
          y={y}
          width={quadrantWidth}
          height={quadrantHeight}>
          <rect
            x='0'
            y='0'
            width='100%'
            height='100%'
            fill={fill} />
          <text fill='white' {...textProps}>
            { capitalize(quadrant) }
          </text>
          { markets.map( ({ datum, opacity, ...circleProps }) => {
            const FlagIcon = Flags[datum.label as Flags.FlagName]
            let marketNode = null
            if( FlagIcon ){
              const tooltipWidth = circleProps.r * 1.25
              const tooltipHeight = tooltipWidth / 1.5
              marketNode = (
                <>
                  <FlagIcon
                    rounded
                    border='white'
                    size={circleProps.r * 2}
                    x={circleProps.cx - circleProps.r}
                    y={circleProps.cy - circleProps.r} />
                  <g className={classes.tooltip}>
                    <rect
                      fill='white'
                      stroke='black'
                      rx={4}
                      x={circleProps.cx - (tooltipWidth / 2)}
                      y={circleProps.cy - (tooltipHeight / 2)}
                      width={tooltipWidth}
                      height={tooltipHeight} />
                    <text
                      x={circleProps.cx}
                      y={circleProps.cy}
                      fontSize={tooltipHeight * 0.75}
                      fontWeight={700}
                      dominantBaseline='central'
                      textAnchor='middle'
                      fill='black'>
                      { datum.label }
                    </text>
                  </g>
                </>
              )
            }else{
              marketNode = (
                <>
                  <circle
                    fill='white'
                    stroke='none'
                    {...circleProps} />
                  <text
                    x={circleProps.cx}
                    y={circleProps.cy}
                    fontSize={circleProps.r / 1.5}
                    dominantBaseline='central'
                    textAnchor='middle'
                    fill='black'>
                    { datum.label }
                  </text>
                </>
              )
            }
            return (
              <g
                key={datum.label}
                className={classes.tooltipContainer}
                opacity={opacity}>
                { marketNode }
              </g>
            )
          })}
        </svg>
      ))}
      {/* Annotations */}
      { annotations.map( (a, i) => (
        <path key={i} {...a} />
      ))}
    </svg>
  )
}


export const BrandGrowthQuadrantChart = (props: BrandGrowthQuadrantChartProps): JSX.Element => {
  return (
    <ParentSize>
      { (sizeProps): JSX.Element => (
        <BrandGrowthQuadrantSVG {...props} {...sizeProps} />
      )}
    </ParentSize>
  )
}
