
import React from 'react'

import { Box, BoxProps } from '@material-ui/core'

import { Error as ErrorIcon } from '../../icons'


export const ErrorMetric = ({ children, ...props }: BoxProps): JSX.Element => (
  <Box
    display='flex'
    alignItems='center'
    justifyContent='center'
    px={1.5}
    pb={2}
    {...props}>
    { children || <ErrorIcon color='error' style={{fontSize: '2.5rem'}} /> }
  </Box>
)


export const NotApplicable = ({ children, ...props }: BoxProps): JSX.Element => (
  <Box
    display='flex'
    alignItems='center'
    justifyContent='center'
    fontSize='1.5rem'
    py={3}
    px={1.5}
    letterSpacing='0.05rem'
    {...props}>
    { children || 'N / A' }
  </Box>
)
