import React, { useState } from 'react'

import {
  Box,
  Checkbox,
  Divider,
  Grid,
  MonthRangePicker,
  RoundedPlainTextButton,
  Typography,
  makeAppStyles,
} from '@percept/mui'

import { dmyFormatter } from '@percept/mui/charts'
import { isNullTimeRange } from './lib'


export type TimeComparisonValue = {
  baseline: [Date | null, Date | null]
  comparison: [Date | null, Date | null]
}


export type TimeComparisonFilterProps = {
  comparisonEnabled?: boolean
  minDate: Date
  maxDate: Date
  value: TimeComparisonValue
  onChange: (value: TimeComparisonValue) => void
}


const areDateListsEqual = (a: (Date | null)[], b: (Date | null)[]): boolean => (
  !a && !b ?
    true :
    !a || !b ?
      false : (
        a.map( d => d ? dmyFormatter(d) : 'NONE').sort().join('')
        === b.map( d => d ? dmyFormatter(d) : 'NONE').sort().join('')
      )
)

const useStyles = makeAppStyles( theme => ({
  grid: {
    width: 520,
    padding: theme.spacing(2),
  },
  dividerContainer: {
    padding: theme.spacing(2, 3),
  },
  slider: {
    margin: theme.spacing(0, 2),
  },
  buttonContainer: {
    textAlign: 'right',
  }
}))


export const TimeComparisonFilter = ({
  value,
  onChange,
  minDate,
  maxDate,
  comparisonEnabled = true,
}: TimeComparisonFilterProps): JSX.Element => {

  const [localBaseline, setLocalBaseline] = useState(value.baseline)
  const [localComparison, setLocalComparison] = useState(value.comparison)

  const [comparisonActive, setComparisonActive] = useState(
    comparisonEnabled && !isNullTimeRange(localComparison)
  )

  const areFiltersApplied = (
    areDateListsEqual(value.baseline, localBaseline)
    && (
      !comparisonEnabled ? true : areDateListsEqual(value.comparison, localComparison)
    )
  )

  const classes = useStyles()

  return (
    <Grid container spacing={3} className={classes.grid}>
      <Grid item xs={12}>
        <Typography variant='h5'>
          { comparisonEnabled ? 'Baseline' : 'Time Range' }
        </Typography>
        <MonthRangePicker
          variant='financial'
          minDate={minDate}
          maxDate={maxDate}
          value={localBaseline}
          onChange={setLocalBaseline} />
      </Grid>
      <Box maxWidth='100%' flexBasis='100%' mt={1} px={1}>
        <Divider />
      </Box>
      { comparisonEnabled && (
        <>
          <Grid item xs={12}>
            <Typography style={{cursor: 'pointer'}} variant='h5' onClick={(): void => {
              if( comparisonActive ){
                setComparisonActive(false)
                setLocalComparison([null, null])
              }else{
                setComparisonActive(true)
              }
            }}>
              Comparison
              <Checkbox
                size='small'
                checked={comparisonActive} />
            </Typography>
            { comparisonActive && (
              <MonthRangePicker
                variant='financial'
                minDate={minDate}
                maxDate={maxDate}
                value={localComparison}
                onChange={setLocalComparison} />
            )}
          </Grid>
          <Box maxWidth='100%' flexBasis='100%' mt={1} px={1}>
            <Divider />
          </Box>
        </>
      )}
      <Grid item xs={12} className={classes.buttonContainer}>
        <RoundedPlainTextButton
          variant='contained'
          color='secondary'
          size='small'
          disabled={areFiltersApplied}
          onClick={(): void => {
            onChange({
              baseline: localBaseline,
              comparison: localComparison,
            })
          }}>
          { areFiltersApplied ? 'Filters Applied' : 'Apply Filters'}
        </RoundedPlainTextButton>
      </Grid>
    </Grid>
  )
}
