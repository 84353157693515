import React from 'react'

import { makeResponsiveChart } from '../makeResponsiveChart'

import { makeQuantitativeChart } from './makeQuantitativeChart'

import { AreaComponent, LineComponent, StackedAreaComponent } from './PathComponents'

import { AreaProps, LineProps, MultiLineProps, StackedAreaProps } from './typings'


export * from './typings'


export const Area: React.FC<AreaProps> = makeQuantitativeChart(
  AreaComponent,
  'Area'
)

export const ResponsiveArea = makeResponsiveChart(Area)


export const StackedArea: React.FC<StackedAreaProps> = makeQuantitativeChart(
  StackedAreaComponent,
  'StackedArea',
  {
    stacked: true,
  }
)

export const ResponsiveStackedArea = makeResponsiveChart(StackedArea)


export const StreamGraph: React.FC<StackedAreaProps> = (props) => (
  <StackedArea
    stackOffset='silhouette'
    curve='monotoneX'
    enableTooltip={false}
    {...props} />
)

export const ResponsiveStreamGraph = makeResponsiveChart(StreamGraph)


export const Line: React.FC<LineProps> = makeQuantitativeChart(
  LineComponent,
  'Line'
)

export const ResponsiveLine = makeResponsiveChart(Line)


export const MultiLine: React.FC<MultiLineProps> = makeQuantitativeChart(
  LineComponent,
  'MultiLine',
)

export const ResponsiveMultiLine = makeResponsiveChart(MultiLine)
