import { SponsorshipsFormSchema } from './typings'

export const sponsorshipsFormSchema: SponsorshipsFormSchema = [
  {
    title: 'Vodafone Touchpoints',
    sections: [
      {
        title: 'Vodafone CRM',
        formItems: [
          {
            type: 'FORM_FIELD',
            name: 'crm_members',
            label: 'CRM Members',
            tooltip: 'Total number of users on database from the region/market being valued',
          },
          {
            type: 'FORM_FIELD',
            name: 'active_rate',
            format: 'PERCENT',
            label: 'Active Rate',
            tooltip: 'Total number of active users on database (definition for \'Active Member\' can vary according to local market parameters)',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'crm_active_members',
            label: 'CRM Active Members',
          },
        ],
      },
      {
        title: 'Vodafone Real Estate',
        formItems: [
          {
            type: 'FORM_FIELD',
            name: 'number_of_stores',
            label: 'Number of Stores',
            tooltip: 'Number of stores in the region/market being valued',
          },
          {
            type: 'FORM_FIELD',
            name: 'flagship_store_average_monthly_footfall',
            label: 'Flagship Store Average Monthly Footfall',
            tooltip: 'Average monthly footfall for Flagship Stores (stores that contain the new interactive screen)',
          },
          {
            type: 'FORM_FIELD',
            name: 'store_average_monthly_footfall',
            label: 'Store Average Monthly Footfall',
            tooltip: 'Average monthly footfall for all Stores in region/market',
          },
          {
            type: 'FORM_FIELD',
            name: 'stores_with_radio',
            label: 'Stores With Radio',
            tooltip: 'Number of stores within region/market with radio capability instore',
          },
          {
            type: 'FORM_FIELD',
            name: 'supplier_panels_per_store',
            label: 'Supplier Panels Per Store',
            tooltip: 'Average number of panels showcasing suppliers within each store',
          },
          {
            type: 'FORM_FIELD',
            name: 'paym_payg_panels_per_store',
            label: 'PAYM/PAYG Panels Per Store',
            tooltip: 'Number of panels per store within region',
          },
          {
            type: 'FORM_FIELD',
            name: 'business_panels_per_store',
            label: 'Business Panels Per Store',
            tooltip: 'Number of Vodafone Business panels per store within region',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'annual_supplier_panel_impressions',
            label: 'Annual Supplier Panel Impressions',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'annual_paym_payg_impressions',
            label: 'Annual PAYM/PAYG Impressions',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'annual_flagship_immersive_screen_impressions',
            label: 'Annual Flagship Immersive Screen Impressions',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'annual_business_panel_impressions',
            label: 'Annual Business Panel Impressions',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'annual_instore_media_impressions',
            label: 'Annual Instore Media Impressions',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'annual_radio_listens',
            label: 'Annual Radio Listens',
          },
        ]
      },
      {
        title: 'Vodafone Digital Estate',
        formItems: [
          // Business
          {
            type: 'SUBHEADER',
            title: 'Business SITE/APP'
          },
          {
            type: 'FORM_FIELD',
            name: 'business_website_visits_per_month',
            label: 'Business Website Visits Per Month',
            tooltip: 'Average number of visits (or sessions) per month',
          },
          {
            type: 'FORM_FIELD',
            name: 'business_website_pages_with_ads_per_visit',
            label: 'Business Website Pages With Ads Per Visit',
            tooltip: 'Average number of pages viewed per visit/session with advertising',
          },
          {
            type: 'FORM_FIELD',
            name: 'business_website_logged_in_rate',
            format: 'PERCENT',
            label: 'Business Website Logged In Rate',
            tooltip: 'Proportion of visits in which the user logins into their profile and are therefore identifiable',
          },
          {
            type: 'FORM_FIELD',
            name: 'business_website_conversion_rate',
            format: 'PERCENT',
            label: 'Business Website Conversion Rate',
            tooltip: 'Proportion of visits that result in a transaction per month',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'business_website_transactions_per_month',
            label: 'Business Website Transactions Per Month',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'annual_business_website_impressions',
            label: 'Annual Business Website Impressions',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'FORM_FIELD',
            name: 'business_app_visits_per_month',
            label: 'Business App Visits Per Month',
            tooltip: 'Average number of app visits (or sessions) per month',
          },
          {
            type: 'FORM_FIELD',
            name: 'business_app_screenviews_with_ads_per_visit',
            label: 'Business App Screenviews With Ads Per Visit',
            tooltip: 'Average number of screens viewed per visit/session with advertising',
          },
          {
            type: 'FORM_FIELD',
            name: 'business_app_logged_in_rate',
            format: 'PERCENT',
            label: 'Business App Logged In Rate',
            tooltip: 'Proportion of visits in which the user logins into their profile and are therefore identifiable',
          },
          {
            type: 'FORM_FIELD',
            name: 'business_app_conversion_rate',
            format: 'PERCENT',
            label: 'Business App Conversion Rate',
            tooltip: 'Proportion of visits that result in a transaction per month',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'business_app_transactions_per_month',
            label: 'Business App Transactions Per Month',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'annual_business_app_impressions',
            label: 'Annual Business App Impressions',
          },
          {
            type: 'DIVIDER',
          },
          // Consumer
          {
            type: 'SUBHEADER',
            title: 'Consumer SITE/APP'
          },
          {
            type: 'FORM_FIELD',
            name: 'consumer_website_visits_per_month',
            label: 'Consumer Website Visits Per Month',
            tooltip: 'Average number of visits (or sessions) per month',
          },
          {
            type: 'FORM_FIELD',
            name: 'consumer_website_pages_with_ads_per_visit',
            label: 'Consumer Website Pages With Ads Per Visit',
            tooltip: 'Average number of pages viewed per visit/session with advertising',
          },
          {
            type: 'FORM_FIELD',
            name: 'consumer_website_logged_in_rate',
            format: 'PERCENT',
            label: 'Consumer Website Logged In Rate',
            tooltip: 'Proportion of visits in which the user logins into their profile and are therefore identifiable',
          },
          {
            type: 'FORM_FIELD',
            name: 'consumer_website_conversion_rate',
            format: 'PERCENT',
            label: 'Consumer Website Conversion Rate',
            tooltip: 'Proportion of visits that result in a transaction per month',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'consumer_website_transactions_per_month',
            label: 'Consumer Website Transactions Per Month',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'annual_consumer_website_impressions',
            label: 'Annual Consumer Website Impressions',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'FORM_FIELD',
            name: 'consumer_app_visits_per_month',
            label: 'Consumer App Visits Per Month',
            tooltip: 'Average number of app visits (or sessions) per month',
          },
          {
            type: 'FORM_FIELD',
            name: 'consumer_app_screenviews_with_ads_per_visit',
            label: 'Consumer App Screenviews With Ads Per Visit',
            tooltip: 'Average number of screens viewed per visit/session with advertising',
          },
          {
            type: 'FORM_FIELD',
            name: 'consumer_app_logged_in_rate',
            format: 'PERCENT',
            label: 'Consumer App Logged In Rate',
            tooltip: 'Proportion of visits in which the user logins into their profile and are therefore identifiable',
          },
          {
            type: 'FORM_FIELD',
            name: 'consumer_app_conversion_rate',
            format: 'PERCENT',
            label: 'Consumer App Conversion Rate',
            tooltip: 'Proportion of visits that result in a transaction per month',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'consumer_app_transactions_per_month',
            label: 'Consumer App Transactions Per Month',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'annual_consumer_app_impressions',
            label: 'Annual Consumer App Impressions',
          },
          {
            type: 'DIVIDER',
          },
          // Social
          {
            type: 'FORM_FIELD',
            name: 'facebook_reach',
            label: 'Facebook Reach',
            tooltip: 'Average post reach on Facebook',
          },
          {
            type: 'FORM_FIELD',
            name: 'sponsored_facebook_posts_per_year',
            label: 'Sponsored Facebook Posts Per Year',
            tooltip: 'How many sponsored Facebook posts are posted per year',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'annual_facebook_impressions',
            label: 'Annual Facebook Impressions',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'FORM_FIELD',
            name: 'twitter_reach',
            label: 'Twitter Reach',
            tooltip: 'Average post reach on Twitter',
          },
          {
            type: 'FORM_FIELD',
            name: 'sponsored_twitter_posts_per_year',
            label: 'Sponsored Twitter Posts Per Year',
            tooltip: 'How many sponsored Twitter posts are posted per year',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'annual_twitter_impressions',
            label: 'Annual Twitter Impressions',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'FORM_FIELD',
            name: 'instagram_reach',
            label: 'Instagram Reach',
            tooltip: 'Average post reach on Instagram',
          },
          {
            type: 'FORM_FIELD',
            name: 'sponsored_instagram_posts_per_year',
            label: 'Sponsored Instagram Posts Per Year',
            tooltip: 'How many sponsored Instagram posts are posted per year',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'annual_instagram_impressions',
            label: 'Annual Instagram Impressions',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'FORM_FIELD',
            name: 'global_business_social_channel_reach',
            label: 'Global Business Social Channel Reach',
          },
          {
            type: 'FORM_FIELD',
            name: 'sponsored_global_business_social_channel_posts_per_year',
            label: 'Global Business Social Channel Posts Per Year',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'annual_global_business_social_reach',
            label: 'Annual Global Business Social Reach',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'FORM_FIELD',
            name: 'local_market_vodafone_business_impressions_per_post',
            label: 'Local Market Vodafone Business Impressions Per Post',
          },
          {
            type: 'FORM_FIELD',
            name: 'local_market_vodafone_business_posts_per_year',
            label: 'Local Market Vodafone Business Posts Per Year',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'annual_local_market_business_social_reach',
            label: 'Annual Local Market Business Social Posts Reach',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'FORM_FIELD',
            name: 'employee_advocacy_reach',
            label: 'Employee Advocacy Reach',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'annual_social_impressions',
            label: 'Annual Social Impressions (includes duplicates)',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'FORM_FIELD',
            name: 'annual_emails_sent',
            label: 'Annual Emails Sent',
          },
          {
            type: 'FORM_FIELD',
            name: 'email_open_rate',
            format: 'PERCENT',
            label: 'Email Open Rate',
          },
          {
            type: 'FORM_FIELD',
            name: 'email_click_rate',
            format: 'PERCENT',
            label: 'Email Click Rate',
          },
          {
            type: 'DIVIDER',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'annual_email_opens',
            label: 'Annual Opened Emails',
          },
          {
            type: 'DERIVED_METRIC',
            name: 'annual_email_clicks',
            label: 'Annual Email Clicks',
          },
        ],
      },
    ], 
  },
]
