
import {
  combineReducers,
  createApiReducer,
  createKeyedReducer,
} from '../../reducers'

import {
  LOAD_ANNOTATIONS,
  ADD_ANNOTATION,
  EDIT_ANNOTATION,
  CLEAR_ADD_ANNOTATION,
  CLEAR_EDIT_ANNOTATION,
  DELETE_ANNOTATION,
  CLEAR_DELETE_ANNOTATION,
  TOGGLE_ANNOTATIONS,
  SET_ANNOTATION_CONTEXT,
  CLEAR_ANNOTATION_CONTEXT,
} from './actions'

import { getAnnotationKey, getAnnotationKeyFromAction, getSeriesKeyFromAction } from '../../utils'

import { annotation as annotationAdapter } from '@percept/api'

import { Dictionary, Annotation, ReduxAction, AnnotationPoints } from '@percept/types'

import { parseHierarchy } from './lib'

import { mapValues, sortBy } from '@percept/utils'


const bySeriesId = createKeyedReducer(
  createApiReducer(LOAD_ANNOTATIONS, {
    getPayloadFromAction: ({ payload }): Dictionary<Annotation[]> => (
      mapValues(
        (payload || []).reduce( (acc: Dictionary<Annotation[]>, annotation: Annotation) => {
          const parsedAnnotation = annotationAdapter(annotation)
          const annotationKey = getAnnotationKey(parsedAnnotation)
          acc[annotationKey] = acc[annotationKey] || []
          acc[annotationKey].push(annotationAdapter(annotation))
          return acc
        }, {}),
        (annotations: Annotation[]) => (
          sortBy(annotations, 'created_at')
        ) 
      )
    )
  }),
  getAnnotationKeyFromAction
)

const hierarchy = createKeyedReducer(
  createApiReducer(LOAD_ANNOTATIONS, {
    getPayloadFromAction: ({ payload }) => (
      parseHierarchy(payload)
    )
  }),
  getSeriesKeyFromAction
)


const create = createApiReducer(ADD_ANNOTATION, {
  process: true,
  resetActionTypes: [CLEAR_ADD_ANNOTATION]
})

const edit = createApiReducer(EDIT_ANNOTATION, {
  process: true,
  resetActionTypes: [CLEAR_EDIT_ANNOTATION]
})

const remove = createApiReducer(DELETE_ANNOTATION, {
  process: true,
  resetActionTypes: [CLEAR_DELETE_ANNOTATION]
})


const initialContext: Partial<AnnotationPoints> = {}

const context = (state = initialContext, action: ReduxAction) => (
  action.type === SET_ANNOTATION_CONTEXT ?
    { ...state, ...action.payload } :
    action.type === CLEAR_ANNOTATION_CONTEXT ?
      {} :
      state
)


const active = (state = false, action: ReduxAction) => (
  action.type === TOGGLE_ANNOTATIONS ?
    !state :
    action.type === SET_ANNOTATION_CONTEXT ?
      true :
      action.type === CLEAR_ANNOTATION_CONTEXT ?
        false :
        state
)
  

export default combineReducers({
  bySeriesId,
  hierarchy,
  create,
  edit,
  remove,
  active,
  context,
})
