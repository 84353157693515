
import { combineReducers } from '@percept/redux/reducers'

import active from './active'
import byId from './byId'


export default combineReducers({
  active,
  byId,
})
