import React from 'react'

import { DefaultMediaWizardLayout } from './DefaultMediaWizardLayout'

import { SmartCampaignAssessment as SmartCampaignAssessmentComponent } from 'components/SmartCampaignAssessment'
import { Breadcrumb } from 'components/Links'

import { useDocumentTitle } from '@percept/hooks'


const breadcrumbs: Breadcrumb[] = [
  {
    path: '/wizard/reporting',
    name: 'Reporting',
  },
  {
    path: '/wizard/reporting/smart-campaign-assessment',
    name: 'Smart Campaign Assessment',
  },
]


export const SmartCampaignAssessment = (): JSX.Element => {
  useDocumentTitle({ paths: ['Reporting', 'Smart Campaign Assessment'] })
  return (
    <DefaultMediaWizardLayout
      breadcrumbs={breadcrumbs}>

      <SmartCampaignAssessmentComponent />

    </DefaultMediaWizardLayout>
  )
}
