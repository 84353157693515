import React, { useState } from 'react'

import MUIDataTable, {
  debounceSearchRender,
  MUIDataTableColumn,
  MUIDataTableColumnOptions,
  MUIDataTableOptions,
  MUIDataTableProps,
} from 'mui-datatables'

import { BackdropLoader, formatMoney, Tooltip } from '@percept/mui'

import { automaticPrecisionPercentageFormatter, getDomain, percentageFormatter, rateFormatter } from '@percept/mui/charts'

import { useTimeago } from '@percept/hooks'

import { format as formatDate } from 'date-fns'

import { SmartTableColumn, useRows } from './lib'

import {
  boldRenderer,
  booleanRenderer,
  copyToClipboardRenderer,
  providerRenderer,
} from './defaultRenderers'

import {
  dateRangeCustomFilterListOptions,
  makeCurrencyRangeCustomFilterListOptions,
  makeCurrencyRangeFilterOptions,
  makeDateFilterOptions,
  makeRangeFilterOptions,
  percentageRangeCustomFilterListOptions,
  // providerCustomFilterListOptions,
  // providerFilterOptions,
  rangeCustomFilterListOptions,
  // rangeFilterOptions,
} from './filterUtils'

import { SmartTableChannelThemeProvider } from './SmartTableChannelThemeProvider'

import { identity } from 'lodash-es'

import { separateThousands } from '@percept/utils'

import { useDataTableClasses } from './styles'

import { ChannelKey, ReportProvider } from '@percept/types'


export type SmartTableProps<
  Item extends object,
  EditableParams extends object = {},
> = {
  items?: Item[] | null
  columns: SmartTableColumn<Item>[]
  title: React.ReactNode
  renderers?: Partial<{
    [K in keyof Item]: (value: Item[K]) => React.ReactNode
  }>
  renderRowAction?: (item: Item) => React.ReactNode
  onItemClick?: (item: Item) => void
  defaultSort?: {
    name: string
    direction: 'asc' | 'desc'
  }
  dateFormat?: string
  loading?: boolean
  currency?: string
  channel?: ChannelKey | null
  nullValueContent?: React.ReactNode
} & Omit<MUIDataTableProps, 'columns' | 'data'>


const defaultTableOptions: MUIDataTableOptions = {
  print: false,
  download: false,
  fixedHeader: true,
  tableBodyMaxHeight: 'calc(100vh - 224px)',
  responsive: 'standard',
  selectableRows: 'none',
  jumpToPage: true,
  rowsPerPage: 50,
  rowsPerPageOptions: [25, 50, 100, 200],
  filterType: 'multiselect',
  rowHover: false,
  customSearchRender: debounceSearchRender(50),
}


function RenderDataTable<T extends object>({
  title,
  data,
  items,
  onItemClick,
  columns,
  renderers,
  dateFormat = 'dd/MM/yy',
  renderRowAction,
  defaultSort,
  currency,
  nullValueContent = '--',
  ...props
}: (
  Omit<
    SmartTableProps<T>, 'loading' | 'channel' | 'syncItems'
  > & {
    data: any[][]
  }
)): JSX.Element {

  const classes = useDataTableClasses()

  const [sortOrder, setSortOrder] = useState(defaultSort)

  const [relativeTime] = useTimeago()

  const smartTableColumns: MUIDataTableColumn[] = columns.map(
    ({ key, name, label, pinned, renderShortcut, filter = true, sort = true, ...columnOptions }, columnIndex) => {

      const defaultOptions: MUIDataTableColumnOptions = pinned ? {
        setCellProps: (): React.HTMLAttributes<HTMLTableCellElement> => ({
          className: classes.fixedColumnCell,
        }),
        setCellHeaderProps: (): React.HTMLAttributes<HTMLTableCellElement> => ({
          className: classes.fixedColumnHeaderCell,
        }),
        filter,
        sort,
      } : {
        filter,
        sort,
      }

      const withPinnedColumn = (
        renderer: (d: number, i: number) => React.ReactNode
      ): MUIDataTableColumnOptions['customBodyRenderLite'] => {
        const wrappedRenderer: MUIDataTableColumnOptions['customBodyRenderLite'] = (...args) => {
          return (
            <span className={classes.fixedColumnCellContent}>
              { renderer(...args) }
            </span>
          )
        }

        return wrappedRenderer
      }

      let options: MUIDataTableColumnOptions = columnOptions

      if( renderers && renderers[key] ){
        options = {
          customBodyRenderLite: (dataIndex): React.ReactNode => {
            const value = data[dataIndex][columnIndex]
            const renderer = renderers && renderers[key]
            if( renderer ){
              return renderer(value)
            }
            return value
          },
          ...columnOptions,
        }
      }else if( renderShortcut ){
        if( renderShortcut === 'date' || renderShortcut === 'relative-date' ){
          const dates = data.map( row =>
            row[columnIndex] ?
              new Date(String(row[columnIndex])).getTime() :
              null
          ).filter( (d): d is number => d !== null )

          const domain: [number, number] = [
            Math.min(...dates),
            Math.max(...dates),
          ]

          options = {
            filterType: 'custom',
            filterOptions: makeDateFilterOptions(domain),
            customFilterListOptions: dateRangeCustomFilterListOptions,
            customBodyRenderLite: (dataIndex): React.ReactNode => {
              const value = new Date(data[dataIndex][columnIndex])
              return renderShortcut === 'relative-date' ?
                relativeTime && (
                  <Tooltip
                    title={formatDate(value, 'HH:mm dd/MM/yy')}>
                    <span>{ relativeTime(value) }</span>
                  </Tooltip>
                ) || '' :
                formatDate(value, dateFormat)
            },
            ...columnOptions,
          }
        }
        if( renderShortcut === 'copy-to-clipboard' ){
          options = {
            // eslint-disable-next-line react/display-name
            customBodyRenderLite: (dataIndex): React.ReactNode => {
              const value = data[dataIndex][columnIndex]
              return copyToClipboardRenderer(value)
            },
            ...columnOptions,
          }
        }
        if( renderShortcut === 'boolean' ){
          options = {
            // eslint-disable-next-line react/display-name
            customBodyRenderLite: (dataIndex): React.ReactNode => {
              const value = Boolean(data[dataIndex][columnIndex])
              return booleanRenderer(value)
            },
            ...columnOptions,
          }
        }
        if( renderShortcut === 'bold' ){
          options = {
            customBodyRenderLite: (dataIndex): React.ReactNode => {
              const value = data[dataIndex][columnIndex]
              return boldRenderer(value)
            },
            ...columnOptions,
          }
        }
        if( renderShortcut === 'provider' ){
          options = {
            // filterType: 'custom',
            // customFilterListOptions: providerCustomFilterListOptions,
            // filterOptions: providerFilterOptions,
            // customBodyRender: (value) => (
            //   providerRenderer(value as ReportProvider)
            // ),
            customBodyRenderLite: (dataIndex): React.ReactNode => {
              const value = data[dataIndex][columnIndex]
              return providerRenderer(value as ReportProvider)
            },
            ...columnOptions,
          }
        }
        if( renderShortcut === 'currency' ){
          options = {
            filterType: 'custom',
            customFilterListOptions: makeCurrencyRangeCustomFilterListOptions(currency),
            filterOptions: makeCurrencyRangeFilterOptions(
              getDomain<number>(
                data.map( row => Number(row[columnIndex]) ),
                identity,
                [0, 0]
              ),
              currency
            ),
            customBodyRenderLite: (dataIndex): React.ReactNode => {
              const value = data[dataIndex][columnIndex]
              if( value === null ) return nullValueContent
              return formatMoney({
                amount: Number(value),
                currency,
                abbreviate: false
              })
            },
            ...columnOptions,
          }
        }
        if( renderShortcut === 'percentage' || renderShortcut === 'numeric' || renderShortcut === 'rate-based' ){
          const isPercentage = renderShortcut === 'percentage'
          const isRateBased = renderShortcut === 'rate-based'
          const valueFormatter = (
            isRateBased ?
              rateFormatter :
              isPercentage ?
                automaticPrecisionPercentageFormatter :
                separateThousands
          )
          options = {
            filterType: 'custom',
            customFilterListOptions: (
              renderShortcut === 'percentage' ?
                percentageRangeCustomFilterListOptions :
                rangeCustomFilterListOptions
            ),
            filterOptions: makeRangeFilterOptions(
              getDomain<number>(
                data.map( row => Number(row[columnIndex]) ),
                identity,
                [0, 0]
              ),
              (
                isRateBased ?
                  rateFormatter :
                  isPercentage ?
                    percentageFormatter :
                    undefined
              ),
            ),
            customBodyRenderLite: (dataIndex): React.ReactNode => {
              const value = data[dataIndex][columnIndex]
              if( value === null || value === undefined ) return nullValueContent
              return valueFormatter(value)
            },
            ...columnOptions,
          }
        }
        if( renderShortcut === 'action' && renderRowAction ){
          options = {
            filter: false,
            sort: false,
            empty: true,
            // eslint-disable-next-line react/display-name
            customBodyRenderLite: (dataIndex): React.ReactNode => {
              const item = items && items[dataIndex]
              return item ? renderRowAction(item) : null
            },
            ...columnOptions,
          }
        }
      }

      if( pinned && options.customBodyRenderLite ){
        options.customBodyRenderLite = withPinnedColumn(options.customBodyRenderLite)
      }

      return {
        label: label || name,
        name,
        options: {
          ...defaultOptions,
          ...options,
        },
      } as MUIDataTableColumn
    }
  )

  return (
    <MUIDataTable
      {...props}
      title={title}
      data={data}
      columns={smartTableColumns}
      options={{
        ...defaultTableOptions,
        ...(props.options || {}),
        ...(!!onItemClick && {
          onRowClick: (_, rowMeta): void => {
            const item = items && items[rowMeta.dataIndex]
            if( item ){
              onItemClick(item)
            }
          }
        } || {}),
        sortOrder,
        onColumnSortChange: (name, direction): void => {
          setSortOrder({ name, direction })
        },
        setRowProps: (): { className: string } => ({
          className: `${classes.row} ${onItemClick ? classes.pointer : ''}`,
        }),
      }} />
  )
}


export function SmartTable<
  Item extends object,
  EditableParams extends object,
>({
  title,
  items,
  columns,
  renderers,
  channel,
  dateFormat = 'dd/MM/yy',
  loading,
  ...props
}: SmartTableProps<Item, EditableParams>): JSX.Element {

  const data = useRows(items || [], columns)

  return (
    <SmartTableChannelThemeProvider
      channel={channel}>

      <RenderDataTable
        title={title}
        data={data}
        items={items}
        columns={columns}
        renderers={renderers}
        dateFormat={dateFormat}
        {...props} />

      <BackdropLoader
        BackdropProps={{
          open: loading
        }} />

    </SmartTableChannelThemeProvider>
  )
}
