import { groupBy, sumBy } from 'lodash-es'
import { Campaign } from '../types'

export const sumByMonth = (data: Campaign[]): Record<string, number> => {
  const groupedData = groupBy(data.map((e) => e.data).flat(), 'month')

  return Object.keys(groupedData).reduce(
    (a, c) => ({
      ...a,
      [c]: sumBy(groupedData[c], (item) => Number(item['amount'])).toFixed(2),
    }),
    {}
  )
}
export const sumByMarket = (data: Campaign[]) => {
  return data.map((el) =>
    Number(el.data.reduce((acc, e) => acc + +e.amount, 0)).toFixed(2)
  )
}
