import { makeAppStyles } from '@percept/mui'
import React from 'react'


export const useChartClasses = makeAppStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: 20,
    paddingBottom: 4,
    textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  legend: {
    margin: theme.spacing(2, 3),
    maxWidth: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    fontSize: theme.typography.body2.fontSize,
  },
}))


export const visxLegendStyles: React.CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
}
