import { ListItem } from 'dashboard/src/components/ReportingDashboard/types'
import { sortBy } from 'lodash-es'
import { vodafoneMarkets } from 'vodafoneMarkets'

export const graphDE = [
  {
    row_group: 'Vodafone UK', 
    data: [
      {
        data_type: 'Brand ATL',
        total:'156000000.00',
        total_percentage: '80.00',
        costs: [
          {
            type_value:'Observed',
            spend:'57720000.00',
            percent:'37.00'
          },
          {
            type_value:'Reco',
            spend:'67080000.00',
            percent:'43.00'
          },
        ],
      },
      {
        data_type: 'Brand Digital',
        total:'156000000.00',
        total_percentage: '32.00',
        costs: [
          {
            type_value:'Observed',
            spend:'23400000.00',
            percent:'15.00'
          },
          {
            type_value:'Reco',
            spend:'26520000.00',
            percent:'17.00'
          },
        ],
      },
      {
        data_type: 'Performance ATL',
        total:'156000000.00',
        total_percentage: '41.00',
        costs: [
          {
            type_value:'Observed',
            spend:'26520000.00',
            percent:'17.00'
          },
          {
            type_value:'Reco',
            spend:'37440000.00',
            percent:'24.00'
          },
        ],
      },
      {
        data_type: 'Performance Digital',
        total:'156000000.00',
        total_percentage: '45.00',
        costs: [
          {
            type_value:'Observed',
            spend:'46800000.0',
            percent:'30.00'
          },
          {
            type_value:'Reco',
            spend:'23400000.00',
            percent:'15.00'
          },
        ],
      }
    ],
    costs: [
      {
        type_value:'Observed',
        spend:'156000000.00',
        percent:'100.00'
      },
      {
        type_value:'',
        spend:'156000000.00',
        percent:'100.00'
      },
      {
        type_value:'Reco',
        spend:'156000000.00',
        percent:'100.00'
      },
  
    ], 
    'total':'156000000.00',
    'total_currency':'EUR',
    'total_percentage':'100.00',
  },
]

export const graphIT = [
  {
    row_group: 'Vodafone IT', 
    data: [
      {
        data_type: 'Brand ATL',
        total:'256000000.00',
        total_percentage: '80.00',
        costs: [
          {
            type_value:'Observed',
            spend:'11720000.00',
            percent:'17.00'
          },
          {
            type_value:'Reco',
            spend:'37080000.00',
            percent:'23.00'
          },
        ],
      },
      {
        data_type: 'Brand Digital',
        total:'126000000.00',
        total_percentage: '22.00',
        costs: [
          {
            type_value:'Observed',
            spend:'23400000.00',
            percent:'15.00'
          },
          {
            type_value:'Reco',
            spend:'26520000.00',
            percent:'11.00'
          },
        ],
      },
      {
        data_type: 'Performance ATL',
        total:'156000000.00',
        total_percentage: '41.00',
        costs: [
          {
            type_value:'Observed',
            spend:'16520000.00',
            percent:'27.00'
          },
          {
            type_value:'Reco',
            spend:'17440000.00',
            percent:'34.00'
          },
        ],
      },
      {
        data_type: 'Performance Digital',
        total:'156000000.00',
        total_percentage: '25.00',
        costs: [
          {
            type_value:'Observed',
            spend:'26800000.0',
            percent:'35.00'
          },
          {
            type_value:'Reco',
            spend:'13400000.00',
            percent:'14.00'
          },
        ],
      }
    ],
    costs: [
      {
        type_value:'Observed',
        spend:'156000000.00',
        percent:'100.00'
      },
      {
        type_value:'',
        spend:'156000000.00',
        percent:'100.00'
      },
      {
        type_value:'Reco',
        spend:'156000000.00',
        percent:'100.00'
      },
  
    ], 
    'total':'156000000.00',
    'total_currency':'EUR',
    'total_percentage':'100.00',
  },
]

const allMarketsData = [
  {
    row_group: 'Vodafone UK', 
    data: [
      // {
      //   data_type: 'South Africa',
      //   total:'18.00',
      //   total_percentage: '18.00',
      //   costs: [
      //     {
      //       type_value:'South Africa',
      //       spend:'16.9',
      //       percent:'16.9'
      //     },
      //   ],
      // },
      {
        data_type: 'Italy',
        total:'18.00',
        total_percentage: '18.00',
        costs: [
          {
            type_value:'Italy',
            spend:'7.6',
            percent:'7.6'
          },
        ],
      },
      {
        data_type: 'Portugal',
        total:'18.00',
        total_percentage: '18.00',
        costs: [
          {
            type_value:'Portugal',
            spend:'5.4',
            percent:'5.4'
          },
        ],
      },
      {
        data_type: 'Spain',
        total:'18.00',
        total_percentage: '18.00',
        costs: [
          {
            type_value:'Spain',
            spend:'5.1',
            percent:'5.1'
          },
        ],
      },
      {
        data_type: 'Ireland',
        total:'18.00',
        total_percentage: '18.00',
        costs: [
          {
            type_value:'Ireland',
            spend:'3.6',
            percent:'3.6'
          },
        ],
      },
      {
        data_type: 'Romania',
        total:'18.00',
        total_percentage: '18.00',
        costs: [
          {
            type_value:'Romania',
            spend:'3.0',
            percent:'3.0'
          },
        ],
      },
      {
        data_type: 'Greece',
        total:'18.00',
        total_percentage: '18.00',
        costs: [
          {
            type_value:'Greece',
            spend:'3.0',
            percent:'3.0'
          },
        ],
      },
      {
        data_type: 'Egypt',
        total:'18.00',
        total_percentage: '18.00',
        costs: [
          {
            type_value:'Egypt',
            spend:'2.5',
            percent:'2.5'
          },
        ],
      },
      {
        data_type: 'Turkey',
        total:'18.00',
        total_percentage: '18.00',
        costs: [
          {
            type_value:'Turkey',
            spend:'1.9',
            percent:'1.'
          },
        ],
      },
      {
        data_type: 'Albania',
        total:'18.00',
        total_percentage: '18.00',
        costs: [
          {
            type_value:'Albania',
            spend:'1.4',
            percent:'1.4'
          },
        ],
      },
      {
        data_type: 'Germany',
        total:'18.00',
        total_percentage: '18.00',
        costs: [
          {
            type_value:'Germany',
            spend:'1.3',
            percent:'1.3'
          },
        ],
      },
      {
        data_type: 'United Kingdom',
        total:'18.00',
        total_percentage: '18.00',
        costs: [
          {
            type_value:'United Kingdom',
            spend:'1.1',
            percent:'1.1'
          },
        ],
      },
      {
        data_type: 'Czech Republic',
        total:'18.00',
        total_percentage: '18.00',
        costs: [
          {
            type_value:'Czech Republic',
            spend:'0.60',
            percent:'0.60'
          },
        ],
      },
      
    ],
    costs: [
      {
        type_value:'Czech Republic',
        spend:'1.00',
        percent:'100.00'
      },
      {
        type_value:'United Kingdom',
        spend:'1.00',
        percent:'100.00'
      },
      {
        type_value:'Germany',
        spend:'1.00',
        percent:'100.00'
      },
      {
        type_value:'Albania',
        spend:'1.00',
        percent:'100.00'
      },
      {
        type_value:'Turkey',
        spend:'1.00',
        percent:'100.00'
      },
      {
        type_value:'Egypt',
        spend:'1.00',
        percent:'100.00'
      },
      {
        type_value:'Greece',
        spend:'1.00',
        percent:'100.00'
      },
      {
        type_value:'Romania',
        spend:'1.00',
        percent:'100.00'
      },
      {
        type_value:'Ireland',
        spend:'1.00',
        percent:'100.00'
      },
      {
        type_value:'Spain',
        spend:'1.00',
        percent:'100.00'
      },
      {
        type_value:'Portugal',
        spend:'1.00',
        percent:'100.00'
      },
      {
        type_value:'Italy',
        spend:'1.00',
        percent:'100.00'
      },
      // {
      //   type_value:'South Africa',
      //   spend:'1.00',
      //   percent:'100.00',
      // },
    ], 
    'total':'8.00',
    'total_currency':'EUR',
    'total_percentage':'8.00',
  },
]


const getMarketSortValue = (marketName: string): number | null => {
  const match = vodafoneMarkets.find( source => source.name === marketName )
  if( !match ) return null
  return match.ordinal_no
}

export const allMarkets = allMarketsData.map( m => ({
  ...m,
  data: sortBy(
    m.data,
    d => getMarketSortValue(d.data_type)
  ),
  costs: sortBy(
    m.costs,
    c => getMarketSortValue(c.type_value)
  ),
}))

export type Market = {
  market: string
  id: string
  iso_code: string
  graphData: ListItem[]
  notes: string
  details: string[]
  reportAssetIdentifier?: string
}

export const marketsJSON: Market[] = [
  {
    market: 'Germany',
    id: '12830040392735',
    iso_code: 'DE',
    graphData: graphDE,
    notes: 'Any notes about how MM is calculated, what should be done about the results etc etc.',
    details: [
      'Brand comms increase to 61% in particular Brand ATL',
      'Performance ATL to increase as well, decline weak Performance Digital',
      'Up to 178K incremental gross additions, 2%',
      '28MM EUR annual Revenue Potential',
      'Total ROI increasing 1.4 to 2.0',
      'To realize potential, detailed channel recommendation and hence, more granular country models are required',
    ],
    reportAssetIdentifier: 'mmm-de-08-2023',
  },
]