
import v1 from './v1'
import v1_2 from './v1.2'
import v1_3 from './v1.3'
import v1_4 from './v1.4'
import v1_5 from './v1.5'
import v1_6 from './v1.6'

export default {
  '__default': v1_4,
  '1': v1,
  '1.1': v1,
  '1.2': v1_2,
  '1.3': v1_3,
  '1.4': v1_4,
  '1.5': v1_5,
  '1.6': v1_6
}
