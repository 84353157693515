import { UseQueryResult, useQuery } from 'react-query'

import { apiClients, makeClientQueryFn } from './lib'

import { sortBy } from 'lodash-es'

import { CurrencyParams, DateRangeParams, Nullable, PlatformUnitParams } from '@percept/types'


export type CreativeQualityScoreResponse = {
  creative_quality_score: number | null
}

export type CreativeXPerformanceResponse = {
  cost: number | null
  converted_cost: number | null
  clicks: number | null
  impressions: number | null
  video_3_sec_views: number | null
  video_15_sec_views: number | null
  video_30_sec_views: number | null
  video_25_views: number | null
  video_50_views: number | null
  video_75_views: number | null
  video_100_views: number | null
  estimated_ad_recall_rate: number | null
}


export type CreativeWastageResponse = {
  creative_wastage_converted_cost: number | null
  creative_wastage_cost: number | null
  total_cost: number | null
  total_converted_cost: number | null
  creative_wastage_percentage: number | null
  creative_quality_score: number | null
}

export type CreativeWastageResponseWithMarket = CreativeWastageResponse & {
  market: string
}

export type CreativeQualityScoreTimeseriesDatum = {
  creative_quality_score: number | null
  market: string
  month_start: string
}


export type CreativeXChannelFilter = {
  channel?: number | null
  publisher?: string | null
}


const getChannelFiltersQueryKey = (filters?: CreativeXChannelFilter[] | null): string => {
  if( !filters || !filters.length ){
    return 'NONE'
  }
  const sortedFilters = sortBy(filters, f => f.channel)
  return sortedFilters.map( f => (
    [f.channel, f.publisher].filter(Boolean).join('/')
  )).join('|')
}


type CreativeXChannelFilterParams = {
  filters: CreativeXChannelFilter[]
}

type MarketParams = {
  market: string
}

type CreativeXRequestBase = (
  Nullable<DateRangeParams>
  & Partial<CurrencyParams>
  & Partial<Nullable<CreativeXChannelFilterParams>>
)

type CreativeXPerformanceRequestBase = CreativeXRequestBase & Partial<CurrencyParams>

type CreativeXPlatformUnitRequestParams = CreativeXRequestBase & PlatformUnitParams
type CreativeXPlatformUnitPerformanceRequestParams = CreativeXPerformanceRequestBase & PlatformUnitParams

type CreativeXMarketRequestParams = CreativeXRequestBase & MarketParams
type CreativeXMarketPerformanceRequestParams = CreativeXPerformanceRequestBase & MarketParams


// Peformance API CreativeX Channel Enum:
// ADFORM = 1
// DV360 = 2
// FACEBOOK_ADS = 3
// GOOGLE_ADS = 4
// INSTAGRAM_ADS = 5
// TIKTOK_ADS = 6


const defaultCreativeXChannelFilters: CreativeXChannelFilter[] = [
  { channel: 3 },  // Facebook
  // { channel: 5 },  // Instagram
  // { channel: 4, publisher: 'youtube' },  // Google Ads / YouTube
  { publisher: 'youtube' },  // YouTube
]


export const useCreativeXReferenceDate = (
  market?: string
): UseQueryResult<{ reference_date: string | null }> => (
  useQuery(
    ['creativeXReferenceDate', market],
    makeClientQueryFn(
      apiClients.performance,
      {
        url: '/creative-x/reference-date',
        params: {
          ...(market && { market })
        },
      },
    ),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )
)


export const useOrgUnitCreativeQualityScore = ({
  org_unit_id,
  start_date,
  end_date,
  filters = defaultCreativeXChannelFilters,
}: CreativeXPlatformUnitRequestParams): UseQueryResult<CreativeQualityScoreResponse> => (
  useQuery({
    queryKey: [
      'creativeQualityScore',
      org_unit_id,
      start_date,
      end_date,
      getChannelFiltersQueryKey(filters),
    ],
    queryFn: async () => {
      const response = await apiClients.performance.post(
        `/creative-x/unit/${org_unit_id}/creative-quality-score`,
        {
          start: start_date,
          end: end_date,
          channel_filters: filters,
        }
      )
      return response.data
    },
    enabled: !!(start_date && end_date),
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
)


export const useMarketCreativeQualityScore = ({
  market,
  start_date,
  end_date,
  filters = defaultCreativeXChannelFilters,
}: CreativeXMarketRequestParams): UseQueryResult<CreativeQualityScoreResponse> => (
  useQuery({
    queryKey: [
      'creativeQualityScoreForMarket',
      market,
      start_date,
      end_date,
      getChannelFiltersQueryKey(filters),
    ],
    queryFn: async () => {
      const response = await apiClients.performance.post(
        '/creative-x/creative-quality-score',
        {
          market,
          start: start_date,
          end: end_date,
          ...(filters && {
            channel_filters: filters,
          }),
        }
      )
      return response.data
    },
    enabled: !!(start_date && end_date),
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
)


export const useOrgUnitCreativeXPerformance = ({
  org_unit_id,
  start_date,
  end_date,
  target_currency = 'EUR',
  filters = defaultCreativeXChannelFilters,
  // filters = null,
}: CreativeXPlatformUnitPerformanceRequestParams): UseQueryResult<CreativeQualityScoreResponse> => (
  useQuery({
    queryKey: [
      'creativeXPerformance',
      org_unit_id,
      start_date,
      end_date,
      target_currency,
      getChannelFiltersQueryKey(filters),
    ],
    queryFn: async () => {
      const response = await apiClients.performance.post(
        `/creative-x/unit/${org_unit_id}/creative-performance`,
        {
          start: start_date,
          end: end_date,
          target_currency,
          ...(filters && {
            channel_filters: filters,
          }),
        }
      )
      return response.data
    },
    enabled: !!(start_date && end_date),
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
)


export const useMarketCreativeXPerformance = ({
  market,
  start_date,
  end_date,
  target_currency = 'EUR',
  filters = defaultCreativeXChannelFilters,
}: CreativeXMarketPerformanceRequestParams): UseQueryResult<CreativeXPerformanceResponse> => (
  useQuery({
    queryKey: [
      'creativeXPerformanceForMarket',
      market,
      start_date,
      end_date,
      getChannelFiltersQueryKey(filters),
    ],
    queryFn: async () => {
      const response = await apiClients.performance.post(
        '/creative-x/creative-performance',
        {
          market,
          start: start_date,
          end: end_date,
          target_currency,
          ...(filters && {
            channel_filters: filters,
          }),
        }
      )
      return response.data
    },
    enabled: !!(start_date && end_date),
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
)


export const useOrgUnitCreativeWastage = ({
  org_unit_id,
  start_date,
  end_date,
  target_currency = 'EUR',
  filters = defaultCreativeXChannelFilters,
}: CreativeXPlatformUnitPerformanceRequestParams): UseQueryResult<CreativeWastageResponse> => (
  useQuery({
    queryKey: [
      'creativeWastage',
      org_unit_id,
      start_date,
      end_date,
      target_currency,
      getChannelFiltersQueryKey(filters),
    ],
    queryFn: async () => {
      const response = await apiClients.performance.post(
        `/creative-x/unit/${org_unit_id}/creative-wastage`,
        {
          start: start_date,
          end: end_date,
          target_currency,
          ...(filters && {
            channel_filters: filters,
          }),
        }
      )
      return response.data
    },
    enabled: !!(start_date && end_date),
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
)


export const useMarketCreativeWastage = ({
  market,
  start_date,
  end_date,
  target_currency = 'EUR',
  filters = defaultCreativeXChannelFilters,
}: CreativeXMarketPerformanceRequestParams): UseQueryResult<CreativeWastageResponse> => (
  useQuery({
    queryKey: [
      'creativeWastageForMarket',
      market,
      start_date,
      end_date,
      target_currency,
      getChannelFiltersQueryKey(filters),
    ],
    queryFn: async () => {
      const response = await apiClients.performance.post(
        '/creative-x/creative-wastage',
        {
          market,
          start: start_date,
          end: end_date,
          channel_filters: filters,
          target_currency,
        }
      )
      return response.data
    },
    enabled: !!(start_date && end_date),
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
)


export const useCreativeWastageByMarket = ({
  start_date,
  end_date,
  target_currency = 'EUR',
  filters = defaultCreativeXChannelFilters,
}: CreativeXPerformanceRequestBase): UseQueryResult<CreativeWastageResponseWithMarket[]> => (
  useQuery({
    queryKey: [
      'creativeWastageByMarket',
      start_date,
      end_date,
      target_currency,
      getChannelFiltersQueryKey(filters),
    ],
    queryFn: async () => {
      const response = await apiClients.performance.post(
        `/creative-x/creative-wastage-by-market`,
        {
          start: start_date,
          end: end_date,
          target_currency,
          ...(filters && {
            channel_filters: filters,
          }),
        }
      )
      return response.data
    },
    enabled: !!(start_date && end_date),
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
)

export const useCreativeQualityScoreTimeseriesByMarket = ({
  start_date,
  end_date,
  target_currency = 'EUR',
  filters = defaultCreativeXChannelFilters,
}: CreativeXPerformanceRequestBase): UseQueryResult<CreativeQualityScoreTimeseriesDatum[]> => (
  useQuery({
    queryKey: [
      'creativeQualityScoreTimeseriesByMarket',
      start_date,
      end_date,
      target_currency,
      getChannelFiltersQueryKey(filters),
    ],
    queryFn: async () => {
      const response = await apiClients.performance.post(
        `/creative-x/creative-quality-score-timeseries-by-market`,
        {
          start: start_date,
          end: end_date,
          target_currency,
          ...(filters && {
            channel_filters: filters,
          }),
        }
      )
      return response.data
    },
    enabled: !!(start_date && end_date),
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
)
