import React, { Fragment, useEffect, useMemo, useState } from 'react'

import {
  Alert,
  applySegmentMetadata,
  BackdropLoader,
  Box,
  Button,
  Card,
  CardContent,
  CardStrip,
  DimensionSelect,
  Grid,
  Health,
  Loader,
  makeAppStyles,
  Metric,
  MetricProps,
  MetricSeries,
  Typography,
  useAppTheme,
} from '@percept/mui'

import { Check, Close } from '@percept/mui/icons'

import { ExamplesDataTable } from './ExamplesDataTable'

import { DonutProps, HistogramProps, LegendItem } from '@percept/mui/charts'

import { find, findIndex, flatten, get, orderBy, xorBy } from 'lodash-es'

import { isDistributionMetricData, isProportionMetricData, isValueMetricData } from '@percept/utils'

import { MetricExample, MetricSegmentType } from '@percept/types'

import { MetricDetailProps } from '../typings'


const useStyles = makeAppStyles( theme => ({
  grid: {
    marginTop: theme.spacing(1),
  },
  cardContent: {
    position: 'relative',
    paddingTop: theme.spacing(3),
  },
  chartCard: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100%',
    overflow: 'visible',
  },
  chartCardContent: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    minHeight: 350,
    width: '100%',
    position: 'relative',
    '&:last-child': {
      paddingBottom: theme.spacing(4),
    },
  },
  multiSelectToggle: {
    marginRight: theme.spacing(3),
  },
  legendCardContent: {
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  legendItem: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  titleWrap: {
    position: 'relative',
  },
  titleShrink: {
    display: 'inline-flex',
    paddingRight: theme.spacing(12),
    flex: '0 0 auto',
  },
  health: {
    position: 'absolute',
    right: 0,
    top: theme.spacing(0.25),
    minWidth: 'unset',
    padding: 0,
  },
  description: {
    marginTop: theme.spacing(3),
    color: theme.palette.action.disabled,
    maxWidth: '70em',
  },
}) )


const histogramProps: Partial<HistogramProps<MetricSegmentType>> = {
  height: 300,
  grid: true,
  axisText: true,
  axisLine: true,
  animate: true,
  animateInitial: true,
  numYTicks: 6,
  svgBarProps: {
    fillOpacity: 0.2,
    strokeOpacity: 1,
    strokeWidth: 2,
  },
}


const donutProps: Partial<DonutProps<MetricSegmentType>> = {
  height: 300,
  showLabels: true,
  padAngle: 0.035,
  cornerRadius: 4,
  pathProps: {
    fillOpacity: 0.2,
    strokeWidth: 4,
  },
  textProps: {},
}


const PlainMetricWrap = ({ children }: React.PropsWithChildren<Record<string, any>>): JSX.Element => (
  <Fragment>{ children }</Fragment>
)


export const MetricDetail = ({
  provider,
  series_id,
  report_id,
  entity_id,
  entity_type,
  metric_id,
  result_id,
  dimension,
  metadata,
  currency,
  reportMetric,
  useMetric,
  useSeriesMetric,
  activePerformanceTail,
  onDimensionChange,
  segment,
  onSegmentChange,
}: MetricDetailProps): JSX.Element => {

  const [metric] = useMetric({
    series_id,
    report_id,
    entity_id,
    entity_type,
    metric_id,
    ...(result_id && {
      result_id
    }),
    dimension,
  })

  const [seriesMetric] = useSeriesMetric({
    series_id,
    entity_id,
    entity_type,
    metric_id,
    dimension,
  })

  const metadataEntry = metadata.data && metric_id && metadata.data[metric_id]

  const appTheme = useAppTheme()

  const mappedSegments = useMemo(() => {
    if( metric.data ){
      if( isDistributionMetricData(metric.data) && metadataEntry ){
        const segments = applySegmentMetadata(
          metric.data.segments,
          metadataEntry.display_options,
        )
        const defaultColourScale = appTheme.chart.getInformationalColourScale([0, segments.length - 1])

        return segments.map( (segment, i) => ({
          ...segment,
          color: (
            segment.health !== null ?
              appTheme.chart.healthColourScale(segment.health) :
              defaultColourScale(i)
          )
        }))
      }
    }
    return []
  }, [metric.data, metadataEntry, appTheme])

  const [activeSegments, setActiveSegments] = useState<MetricSegmentType[]>(() => {
    return segment ? mappedSegments.filter( s => String(s.label) === segment ) : []
  })

  const [multiSelect, setMultiSelect] = useState(false)

  useEffect(() => {
    if( segment && !activeSegments.length && mappedSegments.length ){
      const derivedActive = mappedSegments.filter( s => String(s.label) === segment )
      if( derivedActive.length ){
        setActiveSegments(derivedActive)
      }
    }
  }, [mappedSegments, segment, activeSegments])

  const examples = useMemo(() => {
    if( metric.data ){
      if( activeSegments.length ){

        const matches = mappedSegments.filter( m => (
          !!find(activeSegments, s => s.label === m.label )
        ))
        if( matches.length ){
          return flatten(
            matches.map( (m) => {
              const __SEGMENT__ = m.label
              return (m.examples && m.examples.default || []).map( e => ({
                ...e,
                __SEGMENT__,
              }) )
            })
          )
        }
      }
      if( isProportionMetricData(metric.data) && metric.data.enumerator.examples ){
        return metric.data.enumerator.examples.default as MetricExample[] | null
      }
      if( isValueMetricData(metric.data) && metric.data.examples ){
        return metric.data.examples.default as MetricExample[] | null
      }
    }
    return null
  }, [metric.data, activeSegments, mappedSegments])

  const onSegmentClick: MetricProps['onSegmentClick'] = (({ segment }): void => {
    if( segment && segment.value ){
      if( !multiSelect && onSegmentChange ){
        const segmentString = String(segment.label)
        onSegmentChange(segmentString)
        setActiveSegments(mappedSegments.filter( s => String(s.label) === segmentString ))
      }else{
        setActiveSegments( prev => (
          orderBy(
            xorBy(prev, [segment], 'label'),
            s => findIndex(mappedSegments, m => m.label === s.label )
          )
        ))
      }
    }
  })

  const classes = useStyles()

  if( !(metric_id && metadata.data) ){
    return (
      <BackdropLoader />
    )
  }

  if( !metadataEntry ){
    return (
      <Alert
        variant='error'
        header='Missing Metadata'
        message={`No metadata entry for metric with id ${metric_id}`} />
    )
  }

  const health = get(reportMetric, ['dimensions', dimension || 'count', 'health'])

  return (
    <Grid className={classes.grid} container spacing={3} justify='center' alignItems='stretch'>

      <Grid item xs={12}>

        <Card>

          <CardStrip
            color='health'
            health={health} />

          <CardContent
            className={classes.cardContent}>

            <div className={classes.titleWrap}>
              <Typography
                variant='h4'
                className={health !== null ? classes.titleShrink : undefined}>
                { metadataEntry.title }
              </Typography>

              { health !== null && (
                <Health
                  className={classes.health}
                  fontSize='1.875rem'
                  value={health} />
              )}
            </div>

            <Typography
              variant='body1'
              className={classes.description}>
              { metadataEntry.descriptions.s }
            </Typography>

          </CardContent>
        </Card>

      </Grid>

      <Grid item xs={12}>

        <Card>
          <CardContent
            className={classes.cardContent}>

            <Box ml='auto'>
              <DimensionSelect
                activePerformanceTail={activePerformanceTail}
                value={dimension || 'count'}
                options={Object.keys(reportMetric.dimensions)}
                onChange={onDimensionChange} />
            </Box>

          </CardContent>
        </Card>

      </Grid>

      <Grid item xs={12} lg={4}>

        <Card
          className={classes.chartCard}>
          <CardContent className={classes.chartCardContent}>
            <Metric
              responsive
              WrapComponent={PlainMetricWrap}
              id={metric_id}
              metric={reportMetric}
              dimension={dimension || 'count'}
              dimensionOptions={[dimension || 'count']}
              currency={currency}
              {...metadataEntry}
              activeSegments={activeSegments}
              onSegmentClick={onSegmentClick}
              HistogramProps={histogramProps}
              DonutProps={donutProps} />
          </CardContent>
        </Card>

      </Grid>

      <Grid item xs={12} lg={8}>

        <Card
          className={classes.chartCard}>
          <CardContent className={classes.chartCardContent}>

            { (seriesMetric.error || seriesMetric.data && seriesMetric.data.length < 2) ? (
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                fontSize='2rem'
                flexGrow={1}
                p={2}
                lineHeight={1.25}
                fontWeight={700}
                color='action.disabled'
                letterSpacing='0.05rem'>
                History Unavailable
              </Box>
            ) : seriesMetric.data ? (
              <MetricSeries
                height={300}
                metricSeries={seriesMetric.data}
                metricType={reportMetric.metric_type}
                metadataEntry={metadataEntry}
                activeSegment={segment} />
            ) : (
              <Loader
                preset='fullsize' />
            )}

          </CardContent>
        </Card>

      </Grid>

      { metric.data && isDistributionMetricData(metric.data) && (
        <Grid item xs={12}>
          <Card>
            <CardContent className={classes.legendCardContent}>
              <Button
                className={classes.multiSelectToggle}
                size='small'
                color={multiSelect ? 'default' : 'secondary'}
                startIcon={
                  multiSelect ?
                    <Check /> :
                    <Close />
                }
                onClick={(): void => {
                  setMultiSelect( prev => !prev )
                }}>
                Multi-Select
              </Button>

              { mappedSegments.map( s => (
                !s.value ?
                  null : (
                    <LegendItem
                      key={s.label}
                      datum={{
                        ...s,
                        fillOpacity: find(activeSegments, a => a.label === s.label ) ? 1 : 0.2,
                      }}
                      onClick={(): void => {
                        onSegmentClick({ metric_id, segment: s })
                      }} />
                  )
              ))}
            </CardContent>
          </Card>
        </Grid>
      )}

      <Grid item xs={12}>

        { metric.error ? (
          <Alert variant='error' {...metric.error} />
        ) : !metric.data ? (
          <Loader preset='fullsize' minHeight='10rem' />
        ) : (provider && metadata.data && !activeSegments.length && isDistributionMetricData(metric.data)) ? (
          <Alert
            variant='info'
            header='Select Segment'
            p={2}>
            <Box fontSize={16} mt={3} mb={2}>
              Click on a segment to view examples.
            </Box>

            <Box fontSize={12}>
              Hint: You can enable multiple segments at a time
            </Box>
          </Alert>
        ) : !!(provider && metadata.data && examples) && (
          <ExamplesDataTable
            metadata={metadata.data}
            provider={provider}
            examples={examples}
            dimension={dimension}
            activePerformanceTail={activePerformanceTail}
            activeSegments={activeSegments}
            currency={currency} />
        )}

      </Grid>

    </Grid>
  )
}
