import React from 'react'

import { CellRenderers, Checkbox, ProviderLogo, SimpleTable, makeAppStyles } from '@percept/mui'

import { minDatesByProvider } from './performanceReportReducer'

import { format } from 'date-fns'

import { every, intersection } from 'lodash-es'

import { primaryPerformanceDataProviders } from '@percept/constants'

import { PlatformUnitProviderInfo, PrimaryPerformanceDataProvider } from '@percept/types'


type ProviderTableRow = {
  provider: PrimaryPerformanceDataProvider
  selected: boolean
  startDate: Date
  endDate: Date
}


const useStyles = makeAppStyles( theme => ({
  headerCell: {
    padding: theme.spacing(0.5, 1),
    fontWeight: theme.typography.fontWeightBold,
  },
  tableCell: {
    padding: theme.spacing(0.5, 1),
  },
  tableRowClickable: {
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      '& .MuiTableCell-body': {
        color: theme.palette.secondary.contrastText,
      },
      '& .MuiCheckbox-root': {
        color: theme.palette.secondary.contrastText,
      },
    },
  },
}))


const renderers: CellRenderers<ProviderTableRow> = {
  /* eslint-disable react/display-name, react/prop-types */
  selected: ({ selected }) => (
    <Checkbox checked={selected} />
  ),
  provider: ({ provider }) => (
    <ProviderLogo
      size={1.4}
      units='em'
      provider={provider}
      style={{
        position: 'relative',
        top: 4,
      }} />
  ),
  startDate: ({ startDate }) => (
    <>
      {format(startDate, 'dd/MM/yy')}
    </>
  ),
  endDate: ({ endDate }) => (
    <>
      {format(endDate, 'dd/MM/yy')}
    </>
  ),
  /* eslint-enable react/display-name, react/prop-types */
}


export type ProviderTableProps = {
  selectedProviders: PrimaryPerformanceDataProvider[]
  providerOptions: PrimaryPerformanceDataProvider[]
  providerInfo: PlatformUnitProviderInfo
  toggleProvider: (provider: PrimaryPerformanceDataProvider) => void
  toggleAllProviders: () => void
}

export const ProviderTable = ({
  selectedProviders,
  providerOptions,
  providerInfo,
  toggleProvider,
  toggleAllProviders,
}: ProviderTableProps): JSX.Element => {
  const rows: ProviderTableRow[] = intersection(primaryPerformanceDataProviders, providerOptions).map( provider => ({
    provider,
    selected: selectedProviders.includes(provider),
    startDate: minDatesByProvider[provider],
    endDate: providerInfo[provider].reference_date,
  }))
  return (
    <SimpleTable
      classes={useStyles()}
      rows={rows}
      renderers={renderers}
      columns={[
        {
          key: 'selected',
          label: (
            <Checkbox
              checked={every(rows, r => r.selected)}
              onClick={(): void => {
                toggleAllProviders()
              }} />
          ),
          align: 'left',
        },
        {
          key: 'provider',
          label: 'Provider',
          align: 'left',
        },
        {
          key: 'startDate',
          label: 'Data From',
          align: 'right',
        },
        {
          key: 'endDate',
          label: 'Data Until',
          align: 'right',
        }
      ]}
      onRowClick={(e, row): void => {
        toggleProvider(row.provider)
      }} />
  )
}