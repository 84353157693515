import { REHYDRATE, PERSIST } from 'redux/actions'

import { user } from '@percept/redux/bundles'

import { get, noop, pick } from 'lodash-es'

import { StoreState } from 'types'

import { Dictionary } from '@percept/types'

import { Middleware } from 'redux'


type PartialStoreState = Partial<{
  [K in keyof StoreState]: StoreState[K] extends object ? Partial<StoreState[K]> : StoreState[K] 
}>

const defaultSerialize = (state: StoreState): PartialStoreState => {
  return {
    appState: pick(
      state.appState,
      [
        'referenceDateBehaviour',
        'performanceComparisonRange',
        'insightsReportViewType',
        'targetCurrency'
      ] as (keyof StoreState['appState'])[]
    ),
    settings: state.settings,
    filters: state.filters,
    theming: state.theming,
    mediaInvestmentCurrencyType: state.mediaInvestmentCurrencyType,
  }
}


const STATE_KEY = 'state'

let timeoutRef: NodeJS.Timeout | null = null
let isInitialLogin = true
let hasLoggedIn = false

interface Persistence {
  selectDb(dbName: string): void
  get(key: string): Promise<unknown>
  set(key: string, value: unknown): Promise<unknown>
  drop(): Promise<unknown>
}

export type PersistenceMiddlewareConfig = {
  persistence: Persistence
  serialize?: (state: StoreState) => Dictionary
}


export const persistenceMiddlewareCreator = ({
  persistence,
  serialize = defaultSerialize
}: PersistenceMiddlewareConfig): Middleware<{}, StoreState> => {

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return store => next => action => {

    if( action.type === PERSIST ){
      persistence.set(action.payload.key, action.payload).then(noop).catch(noop)
      return
    }

    if( user.utils.isLoginSuccessAction(action) ){

      hasLoggedIn = true
      const result = next(action)
      const userId = get(action.payload, 'user_id', 'SSO_USER')

      if( isInitialLogin ){
        try{
          persistence.selectDb(`percept-dashboard-${userId}`)
          persistence.get(STATE_KEY).then( state => {
            if( state !== null ){
              store.dispatch({ type: REHYDRATE, payload: { state } })
            }
          }).catch(noop)
        }catch(e){ noop() }
      }

      isInitialLogin = false
      return result

    }else if( action.type === user.actions.USER_LOGOUT ){

      try{
        persistence.drop().then(noop)
      }catch(e){ noop() }

    }else if( hasLoggedIn ){

      timeoutRef && clearTimeout(timeoutRef)
      timeoutRef = setTimeout(() => {
        try{
          const dump = serialize(store.getState())
          persistence.set(STATE_KEY, dump)
        }catch(e){ noop() }
      }, 1000)

    }

    return next(action)

  }

}
