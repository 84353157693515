import {
  ApiResponse,
  TokenPaginatedResponse,
  Reducer,
  PaginatedResponse,
} from '@percept/types'

import createApiReducer from './createApiReducer'

import { apiInitialState } from '../constants'

import {
  getFetchTypes,
  getPageKeyFromAction,
  getSizeKeyFromAction,
  getPaginationTokenKeyFromAction,
} from '../utils'


export type TokenPaginatedReducerState<T> = {
  initial: ApiResponse<TokenPaginatedResponse<T>>
  byToken: Record<string, ApiResponse<TokenPaginatedResponse<T>>>
}


export const createTokenPaginatedReducer = <T>(
  actionType: string,
): Reducer<TokenPaginatedReducerState<T[]>> => {

  const defaultReducer = createApiReducer<TokenPaginatedResponse<T[]>>(actionType)

  const initialState: TokenPaginatedReducerState<T[]> = {
    initial: apiInitialState,
    byToken: {},
  }

  return (state: TokenPaginatedReducerState<T[]> =  initialState, action): TokenPaginatedReducerState<T[]> => {

    const paginationToken = getPaginationTokenKeyFromAction(action)

    if( paginationToken ){
      return {
        ...state,
        byToken: {
          ...state.byToken,
          [paginationToken]: defaultReducer(state.byToken[paginationToken], action),
        },
      }
    }
    
    return {
      ...state,
      initial: defaultReducer(state.initial, action),
    }
  }

}


export type PaginatedReducerState<T> = {
  pageSize: number | null
  byPage: Record<string, ApiResponse<PaginatedResponse<T>>>
}


export const createPaginatedReducer = <T>(
  actionType: string,
): Reducer<PaginatedReducerState<T[]>> => {

  const defaultReducer = createApiReducer<PaginatedResponse<T[]>>(actionType)

  const fetchTypes = getFetchTypes(actionType)

  const initialState: PaginatedReducerState<T[]> = {
    pageSize: null,
    byPage: {},
  }

  return (state: PaginatedReducerState<T[]> =  initialState, action): PaginatedReducerState<T[]> => {

    const pageNumber = Number(getPageKeyFromAction(action)) || 1

    const pageSize = Number(getSizeKeyFromAction(action)) || state.pageSize

    /* NOTE - empty out other pages if we're loading or reloading the first page */
    if( action.type === fetchTypes.start && pageNumber === 1 ){
      return {
        pageSize,
        byPage: {
          [pageNumber]: defaultReducer(state.byPage[pageNumber], action),
        },
      }
    }

    return {
      pageSize,
      byPage: {
        ...state.byPage,
        [pageNumber]: defaultReducer(state.byPage[pageNumber], action),
      },
    }
  }
    
}


export default createTokenPaginatedReducer
