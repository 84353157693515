import React from 'react'

import { Chip, ChipProps, makeAppStyles, Typography } from '@percept/mui'

import { ZoomIn } from '@percept/mui/icons'

import { MetricExample } from '@percept/types'


export type StructuralEntityListProps = {
  entities: MetricExample[]
  limit?: number
  toggleDetail: (e: React.MouseEvent) => void
}


const EntitySummary = ({
  entity,
  ...props
}: Partial<ChipProps> & {
  entity: MetricExample
}): JSX.Element => {
  return (
    <Chip
      color='secondary'
      size='small'
      label={
        <Typography variant='inherit' noWrap>
          { entity.name }
        </Typography>
      }
      {...props} />
  )
}


const useStyles = makeAppStyles( theme => ({
  entitySummary: {
    fontSize: 12,
    margin: theme.spacing(0.5, 0),
    maxWidth: '100%',
  },
}))


export const StructuralEntityList = ({
  entities,
  limit = 3,
  toggleDetail,
}: StructuralEntityListProps): JSX.Element => {

  const cappedEntities = entities.slice(0, Math.min(limit, entities.length))

  const numCapped = entities.length - cappedEntities.length

  const classes = useStyles()

  return (
    <div>
      { cappedEntities.map( entity => {
        return (
          <div key={entity.id}>
            <EntitySummary
              className={classes.entitySummary}
              entity={entity} />
          </div>
        )
      })}
      <Chip
        className={classes.entitySummary}
        icon={<ZoomIn />}
        label={numCapped ? `${numCapped} more...` : 'View Detail...'}
        size='small'
        onClick={toggleDetail} />
    </div>
  )
}
