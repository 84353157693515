
import { combineReducers } from 'redux'

import byId from './byId'
import edit from './edit'
import overview from './overview'

import { SeriesGroupState } from '../typings'


export default combineReducers<SeriesGroupState>({
  byId,
  edit,
  overview,
})
