import { ListItem, NestedItem } from '../types'

export const transformGroupData = (
  data: ListItem,
  dataFormat: string
): Record<string, string>[] => {
  const column_name = data.costs.map((el) => el.type_value)
  const resultObj: Record<string, string>[] = column_name.map((el) => {
    return { column: el }
  })
  data.data &&
    data.data.map((group) =>
      group.costs.map((el) => {
        resultObj.map((resultObject) => {
          resultObject.column === el.type_value
            ? dataFormat === 'currency'
              ? (resultObject[group.data_type] = el.spend)
              : (resultObject[group.data_type] = el.percent)
            : null
        })
      })
    )
  return resultObj
}

export const transformPlainData = (
  data: ListItem[],
  dataFormat: string
): Record<string, string>[] => {
  const result = data.map((item) => {
    const resultObj = item.costs.reduce((acc, e) => {
      return dataFormat === 'currency'
        ? { ...acc, [e.type_value]: e.spend }
        : { ...acc, [e.type_value]: e.percent }
    }, {})
    return { ...resultObj, ['column']: item.row_group }
  })

  return result
}

export const joinGroupDataToOneArray = (data: ListItem[]): ListItem[] => {
  const transformData = data.reduce((sum: ListItem[], el) => {
    el.data
      ? el.data.map((i) =>
        sum.splice(0, 0, {
          row_group: i.data_type,
          costs: i.costs,
          total: i.total,
          total_percentage: i.total_percentage,
          total_currency: el.total_currency,
        })
      )
      : sum.splice(0, 0, el)
    return sum
  }, [])

  return transformData
}

