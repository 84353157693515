
import { SET_ACTIVE_SERIES_PROVIDER } from '../actions'

import {
  ReduxAction,
  Dictionary,
  AuditProvider,
} from '@percept/types'


export default (state: Dictionary<AuditProvider> = {}, action: ReduxAction): Dictionary<AuditProvider> => {

  switch(action.type){

    case SET_ACTIVE_SERIES_PROVIDER:
      return {
        ...state,
        [action.payload.series_id]: action.payload.provider
      }

    default:
      return state

  }

}
