
import React, { Fragment } from 'react'

import { AppBar, AppBarProps } from '@material-ui/core'

import { makeAppStyles } from '../../themes'


const useStyles = makeAppStyles( theme => ({
  appBar: {
    padding: 0,
    zIndex: theme.zIndex.drawer + 1,
    height: 'unset !important',
  },
  appBarInsert: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    minHeight: theme.spacing(5.5),
  },
  main: {
    padding: theme.spacing(4, 3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(6.5, 3, 4, 3),
    },
  },
}) )


export type DashboardChromeProps = React.PropsWithChildren<{
  appBarInserts?: JSX.Element[]
  AppBarProps?: Partial<AppBarProps>
}>


export const DashboardChrome = ({ appBarInserts = [], children, AppBarProps = {} }: DashboardChromeProps): JSX.Element | null => {

  const classes = useStyles()

  const className = `${classes.appBar} ${AppBarProps.className || ''}`

  return (
    <Fragment>
    
      <AppBar
        position='sticky'
        color='primary'
        {...AppBarProps}
        className={className}>

        { !!appBarInserts.length && (
          <Fragment>

            { appBarInserts.map( (insert, i) => {
              return (
                <div key={i} className={classes.appBarInsert}>{ insert }</div>
              )
            })}

          </Fragment>
        )}

      </AppBar>

      <div className={classes.main}>
        { children }
      </div>

    </Fragment>
  )

}
