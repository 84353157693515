import React from 'react'

import {
  BackdropLoader,
  Box,
  BoxProps,
  CircularProgress,
  Grid,
  LinearProgress,
  Loader,
  RoundedPlainTextButton,
  Typography,
} from '@percept/mui'
import { Add } from '@percept/mui/icons'

import { useInfiniteQuery, useQuery } from 'react-query'

import { makeClientQueryFn } from '@percept/hooks'

import { newsClient } from './clients'

import { flatten } from 'lodash-es'

import { WIZARD_ARTICLES_QUERY_KEY } from './constants'

import { InfinitePaginationWrapper, PartnershipsArticle } from 'components/Partnerships/types'

import { PartnershipsArticleSummary } from 'components/Partnerships/Articles'

import { PaginatedResponse } from '@percept/types'

import fallbackImage from '../../images/news/fallback.jpg'


export const NewsArticles = ({
  articles
}: {
  articles: PartnershipsArticle[]
}): JSX.Element => {
  return (
    <Grid container spacing={3}>
      { articles.map( (article, i) => (
        <Grid
          key={`article-${i}`}
          item
          xs={12}
          sm={6}
          md={4}>
          <PartnershipsArticleSummary
            {...article}
            image_url={article.image_url || fallbackImage} />
        </Grid>
      ))}
    </Grid>
  )
}



export const getWizardArticlesPaginated = async (
  page: number, upcoming?: boolean, previous?: boolean
): Promise<InfinitePaginationWrapper<PartnershipsArticle>> => {
  const res = await newsClient.get<PaginatedResponse<PartnershipsArticle[]>>('/articles', {
    params: { limit: 6, page_number: page, upcoming, previous, order_by_desc: 'publish_date' },
  })
  const hasNext = page * res.data.size < res.data.total
  return {
    nextPage: hasNext ? page + 1 : undefined,
    previousPage: page > 1 ? page - 1 : undefined,
    items: res.data.items,
  }
}

export const WizardNews = (): JSX.Element => {

  const { isLoading, isError, data, isRefetching, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [WIZARD_ARTICLES_QUERY_KEY, 'infinite'],
    getNextPageParam: (prevData: InfinitePaginationWrapper<PartnershipsArticle>) => prevData.nextPage,
    queryFn: ({ pageParam = 1 }) => getWizardArticlesPaginated(pageParam),
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 5,
  })

  const articles = flatten(
    (data && data.pages || []).map( p => p.items )
  )

  return (
    <Box mx={3} mb={5} position='relative'>
      { isRefetching && (
        <div style={{height: 10, width: '100%', position: 'absolute'}}>
          <LinearProgress variant='indeterminate' />
        </div>
      )}
      { isLoading ? (
        <BackdropLoader />
      ): isError ? (
        <Typography variant='h2'>
          Articles could not be loaded
        </Typography>
      ) : (
        <>
          <NewsArticles articles={articles} />
          { hasNextPage && (
            <Box marginTop={6} textAlign="center">
              <RoundedPlainTextButton
                color='primary'
                variant='contained'
                size='large'
                disabled={isFetchingNextPage}
                startIcon={
                  isFetchingNextPage ?
                    <CircularProgress size='1em' color='inherit' /> :
                    <Add />
                }
                onClick={(): void => {
                  fetchNextPage()
                }}>
                Load More
              </RoundedPlainTextButton>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}


export const WizardNewsSummary = ({
  total = 3,
  ...props
}: {
  total?: number
} & BoxProps): JSX.Element => {

  const { isError, data } = useQuery({
    queryKey: [WIZARD_ARTICLES_QUERY_KEY, 'summary', total],
    queryFn: makeClientQueryFn<PaginatedResponse<PartnershipsArticle[]>>(
      newsClient,
      {
        url: '/articles',
        params: { limit: total, page_number: 1, order_by_desc: 'publish_date' },
      }
    ),
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 5,
  })

  return (
    <Box px={3} pb={5} {...props}>
      { isError ? (
        <Typography>
          Articles could not be loaded
        </Typography>
      ) : !data ? (
        <Loader preset='fullsize' minHeight='10rem'>
          Loading articles...
        </Loader>
      ) : (
        <NewsArticles articles={data.items} />
      )}
    </Box>
  )
}
