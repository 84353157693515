

import React, { useState, useMemo, useRef, Fragment } from 'react'

import {
  Input,
  InputProps,
  IconButton,
  Typography,
  InputAdornment,
} from '@material-ui/core'

import { makeAppStyles } from '../../themes'

import { Close, Search } from '../../icons'

import { iteratee, sortBy } from 'lodash-es'

import { Dictionary } from '@percept/types'


const useStyles = makeAppStyles( theme => ({
  inputContainer: {
    position: 'relative',
    marginBottom: theme.spacing(2),
  },
  searchIconButton: {
    color: 'inherit',
    transition: `color ${theme.transitions.easing} ${theme.transitions.duration}`,
  },
  searchIcon: {
    fontSize: '1.25rem',
  },
}) )


export type SearchableItemsProps<T> = {
  items: T[]
  renderItem: (props: T) => JSX.Element
  searchIteratee?: string | ((item: T) => string)
  sortIteratee?: string
  InputProps?: Partial<InputProps>
  pluralText?: string
  ItemWrapComponent?: React.ComponentType<React.PropsWithChildren<Dictionary>>
}

export function SearchableItems<T>({
  items,
  renderItem,
  searchIteratee = 'name',
  sortIteratee = 'name',
  InputProps = {},
  pluralText = 'items',
  ItemWrapComponent = Fragment,
}: SearchableItemsProps<T>): JSX.Element {

  const [query, setQuery] = useState('')

  const classes = useStyles()

  const getItemMatcher = iteratee(searchIteratee) as ((item: T) => string)
  const itemMatcherRef = useRef(getItemMatcher)

  const filteredItems = useMemo(() => {
    if( query ){
      const matcher = query.toLowerCase()
      const filtered = items.filter( item => (
        (itemMatcherRef.current(item) || '').toLowerCase().indexOf(matcher) !== -1
      ))
      return sortBy(filtered, sortIteratee)
    }
    return items
  }, [query, items, sortIteratee])

  return (
    <Fragment>

      <div className={classes.inputContainer}>
        <Input
          fullWidth
          placeholder={`Search ${pluralText}...`}
          startAdornment={
            <InputAdornment position='start'>
              <IconButton
                className={classes.searchIconButton}
                disabled={!query}
                onClick={(): void => setQuery('')}>
                { query ?
                  <Close
                    className={classes.searchIcon} /> :
                  <Search
                    className={classes.searchIcon} />
                }
              </IconButton>
            </InputAdornment>
          }
          {...InputProps}
          value={query}
          onChange={(e): void => setQuery(e.target.value)} />
      </div>

      { filteredItems.length ? (
        <ItemWrapComponent>
          { filteredItems.map( (item, i) => (
            renderItem({ ...item, key: i })
          ))}
        </ItemWrapComponent>
      ) : (
        <Typography
          variant='body2'>
          No {pluralText} {items.length === 0 ? 'available' : 'match this search'}
        </Typography>
      )}

    </Fragment>
  )
}

export default SearchableItems

