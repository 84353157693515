import { Histogram } from './Histogram'
import { StackedHistogram } from './StackedHistogram'

import { makeChartWithTooltip } from '../makeChartWithTooltip'

import { makeResponsiveChart } from '../makeResponsiveChart'

import { HistogramProps, StackedHistogramProps } from './typings'

import { DatumType } from '@percept/types'


export * from './Histogram'
export * from './StackedHistogram'

export * from './typings'


export const ResponsiveHistogram = makeResponsiveChart(
  Histogram
)


export const HistogramWithTooltip = makeChartWithTooltip<DatumType, HistogramProps<DatumType>>(
  Histogram
)


export const ResponsiveHistogramWithTooltip = makeResponsiveChart(
  HistogramWithTooltip
)


export const ResponsiveStackedHistogram = makeResponsiveChart(
  StackedHistogram
)

export const StackedHistogramWithTooltip = makeChartWithTooltip<DatumType, StackedHistogramProps<DatumType>>(
  StackedHistogram
)


export const ResponsiveStackedHistogramWithTooltip = makeResponsiveChart(
  StackedHistogramWithTooltip
)
