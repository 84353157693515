/* eslint-disable semi */
import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { FC, PropsWithChildren, useRef, useState, } from 'react';
import PropTypes from 'prop-types';
import { makeAppStyles } from '@percept/mui';
import { useMutation } from 'react-query';
import { postScorecard } from '../partnershipsClient';
import { format } from 'date-fns';

export type SubmitScorecardProps = {
  partner: string
  type: string
}

const useStyles = makeAppStyles( theme => ({
  text: {
    marginBottom: '20px',
    fontSize: '16px',
  },
  errorMessage: {
    color: theme.palette.error.main
  },
  container: {
    backgroundColor: theme.palette.primary.contrastText,
    padding: '10px'
  },
  uploadButton: {
    display: 'flex',
    justifyContent: 'center',
  }
}))

export const SubmitScorecard: FC<PropsWithChildren<SubmitScorecardProps>> = ({ partner, type, children }) => {
  const classes = useStyles()
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [isUploaded, setIsUploaded] = useState<boolean | null>(null)
  
  const uploadFileMutation = useMutation(async (file: File) => {
    await postScorecard(file, type)
  });

  const handleFileUpload = async (file: File): Promise<void> => {
    try {
      await uploadFileMutation.mutateAsync(file);
      setIsUploaded(true);
    } catch (error) {
      setIsUploaded(false);
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = event.target.files?.[0]

    if (file) {
      await handleFileUpload(file)
    }
  };

  const handleButtonClick = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  };

  return (
    <Box p={3} bgcolor="grey.100">
      <Grid container className={`${classes.container} ${classes.uploadButton}`}>
        <Grid item xs={12} sm={9} md={10}>
          {children}
        </Grid>
        <Grid item xs={12} sm={3} md={2} className={classes.uploadButton}>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <Button onClick={handleButtonClick}>Upload {partner}</Button>
        </Grid>
      </Grid>
      {isUploaded !== null && 
        (<Box my={4} className={`${classes.container}`}>
          {isUploaded === true && <Typography>{partner} scorecard data for <strong>{format(new Date(),'MM-yyyy')}</strong> successfully uploaded.</Typography>}
          {isUploaded === false && <Typography className={classes.errorMessage}>There was an error in the file uploaded, please check and try again.</Typography>}
        </Box>)
      }
    </Box>
  )
}

SubmitScorecard.propTypes = {
  partner: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.node
}
