import React from 'react'

import { RoundedPlainTextButton, ButtonProps } from '@percept/mui'
import { CloudDownload } from '@percept/mui/icons'

import { capitalize, round, sortBy } from 'lodash-es'

import { pillarLabelMap, providerChannelMap } from '@percept/constants'

import { CSVData, downloadCSV } from '@percept/utils'

import { format } from 'date-fns'

import { ReportProviderV2, ReportSeriesPillarScore } from '@percept/types'
import { channelPillars } from 'components/QualityAnalysis'


const pillarScoresToCSV = (pillarScores: ReportSeriesPillarScore[], provider: ReportProviderV2): CSVData => {
  const columns: (keyof ReportSeriesPillarScore)[] = ['date', 'overall', ...channelPillars[providerChannelMap[provider]]]
  const rows = sortBy(pillarScores, 'date').reverse().map( (row): string[] => (
    columns.map( (column): string => {
      const value = row[column]
      if( value === null ){
        return ''
      }
      if( column === 'date' ){
        return format(new Date(value), 'dd/MM/yyyy')
      }
      return String(
        round(Number(value) * 100, 2)
      ) + '%'
    })
  ))
  return {
    columns: columns.map( c => c === 'date' || c === 'overall' ? capitalize(c) : pillarLabelMap[c]),
    rows,
  }
}


export type PillarScoresDownloadProps = {
  pillarScores: ReportSeriesPillarScore[]
  filename?: string
  provider: ReportProviderV2
}

export const PillarScoresDownload = ({
  pillarScores,
  filename = 'Pillar Score Download',
  provider,
  ...props
}: PillarScoresDownloadProps & Partial<ButtonProps>): JSX.Element => {
  return (
    <RoundedPlainTextButton
      variant='contained'
      color='secondary'
      size='small'
      startIcon={<CloudDownload />}
      {...props}
      onClick={(): void => {
        const csvData = pillarScoresToCSV(pillarScores, provider)
        downloadCSV({
          filename,
          data: csvData,
        })
      }}>
      { props.children || 'Download Score History' }
    </RoundedPlainTextButton>
  )
}
