import React from 'react'
import { Box, BoxProps, Button, Tooltip } from '@material-ui/core'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'
import { makeAppStyles } from '@percept/mui'

export type SortControlProps = {
  isActiveKey: boolean
  label: string
  orderBy: 'ASC' | 'DESC' | undefined
  onSort: (order: 'ASC' | 'DESC' | undefined) => void
} & Partial<BoxProps>

const useStyles = makeAppStyles(() => ({
  label: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
}))

export const SortControl = ({
  isActiveKey,
  label,
  orderBy,
  onSort,
  ...props
}: SortControlProps): JSX.Element => {
  const classes = useStyles()

  return (
    <Box my={1} height={32} display='flex' alignItems='center' {...props}>
      <Button
        size='small'
        style={{ textTransform: 'none' }}
        startIcon={
          isActiveKey ? (
            orderBy === 'ASC' ? (
              <ArrowUpward />
            ) : (
              <ArrowDownward />
            )
          ) : undefined
        }
        onClick={(): void => {
          onSort(
            orderBy === 'ASC' ? 'DESC' : orderBy === 'DESC' ? undefined : 'ASC'
          )
        }}>
        {label.length > 18 ? (
          <Tooltip title={label} placement='top'>
            <span className={classes.label}>{label}</span>
          </Tooltip>
        ) : (
          label
        )}
      </Button>
    </Box>
  )
}
