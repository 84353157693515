import { isNumber, sortBy } from 'lodash-es'

import { coerceReportProvider, slugify } from '@percept/utils'

import {
  ChannelPillarScores,
  InsightsReportType,
  MetricPillarChannelScoreResponse,
  MetricPillarScoreResponse,
  MetricPillarScores,
  NestedMetricPillarChannelScoreResponse,
  NestedMetricPillarScoreResponse,
  NestedPlatformUnitMetricPillarChannelScores,
  NestedPlatformUnitMetricPillarScores,
  PillarScoreResponseDatum,
  PlatformUnitMetricPillarChannelScores,
  PlatformUnitMetricPillarScores,
  PotentialEfficiencyDatum,
  ProviderPillarScores,
  ReportLayout,
  ReportSeriesPillarScoreResponse,
  ReportSeriesPillarScores,
} from '@percept/types'


export const reportLayout = ({ payload, ...layout }: ReportLayout): ReportLayout => {
  const members = (payload.members || []).map( m => {
    if( m.type !== 'tab' || m.key ){
      return m
    }
    return {
      ...m,
      key: slugify(m.name)
    }
  })

  const features = payload.features || {}

  return {
    ...layout,
    payload: {
      ...payload,
      members,
      features,
    }
  }
}


const numberOrNull = (value: number | null | undefined): number | null => (
  isNumber(value) ? value : null
)


const adaptPillarScoreData = (
  scores: PillarScoreResponseDatum[]
): ReportSeriesPillarScores => (
  sortBy(
    scores.map( (source) => ({
      date: new Date(source.date).getTime(),
      overall: numberOrNull(source.overall_score),
      structure: numberOrNull(source.structure_score),
      creative: numberOrNull(source.creative_score),
      audience: numberOrNull(source.audience_score),
      brand_safety: numberOrNull(source.brand_safety_score),
    } as MetricPillarScores & { date: number })),
    'date'
  )
)


export const platformUnitPillarScores = (
  response: MetricPillarScoreResponse
): PlatformUnitMetricPillarScores => ({
  provider: coerceReportProvider(response.source_provider.slug),
  scores: adaptPillarScoreData(response.scores),
})


export const platformUnitChannelPillarScores = (
  response: MetricPillarChannelScoreResponse
): PlatformUnitMetricPillarChannelScores => ({
  channel: response.channel,
  scores: adaptPillarScoreData(response.scores),
})


export const reportSeriesPillarScores = (
  response: ReportSeriesPillarScoreResponse
): ReportSeriesPillarScores => {
  return adaptPillarScoreData(response)
}


export const nestedPlatformUnitPillarScores = (
  response: NestedMetricPillarScoreResponse
): NestedPlatformUnitMetricPillarScores => {
  return response.map( ({ provider_scores, unit }) => {
    const byProvider = provider_scores.map(platformUnitPillarScores).reduce( (acc, providerScore) => {
      acc[providerScore.provider] = providerScore
      return acc
    }, {} as ProviderPillarScores)
    return {
      org_unit_id: unit,
      byProvider,
    }
  })
}


export const nestedPlatformUnitChannelPillarScores = (
  response: NestedMetricPillarChannelScoreResponse
): NestedPlatformUnitMetricPillarChannelScores => {
  return response.map( ({ channel_scores, unit }) => {
    const byChannel = channel_scores.map(platformUnitChannelPillarScores).reduce( (acc, channelScore) => {
      acc[channelScore.channel] = channelScore
      return acc
    }, {} as ChannelPillarScores)
    return {
      org_unit_id: unit,
      byChannel,
    }
  })
}


export const potentialEfficiencies = (
  response: PotentialEfficiencyDatum[]
): PotentialEfficiencyDatum[] => {
  return response.map( d => ({
    ...d,
    start: new Date(d.start).getTime(),
    end: new Date(d.end).getTime(),
  }))
}


export const insightsReport = (
  response: InsightsReportType
): InsightsReportType => {
  const { entity_mapping } = response
  if( !entity_mapping ){
    return response
  }
  return {
    ...response,
    sections: response.sections.map( s => ({
      ...s,
      members: s.members.map( m => ({
        ...m,
        entities: m.entities || (
          m.entity_ids && m.entity_ids.length ?
            m.entity_ids.map( id => entity_mapping[id] ) :
            null
        ),
        findings: m.findings.map( f => ({
          ...f,
          entities: f.entities || (
            f.entity_ids && f.entity_ids.length ?
              f.entity_ids.map( id => entity_mapping[id] ) :
              null
          ),
        }))
      }))
    }))
  }
}
