import { ListingUserParameter, TenantUserManagementListingUser } from '@percept/hooks'
import { isTenantUserAttributeSelect } from './formUtils'
import { TenantUserAttribute } from '@percept/types'

export type DerivedListingUser = Pick<
  TenantUserManagementListingUser,
  'email' | 'enabled' | 'user_id' | 'created' | 'activated_at' | 'modified' | 'last_login'
> & {
  name: string
  user_type?: string
  user_status?: string
  market?: string
}

export type ListingViewType = 'ACTIVE_USERS' | 'PENDING_SSO_USERS'


const userDetailAttributes: TenantUserAttribute[] = [
  {
    id: 'name',
    slug: 'name',
    name: 'Name',
    enabled: true,
    payload: null,
    input_type: 'text',
  },
  {
    id: 'email',
    slug: 'email',
    name: 'Email',
    enabled: true,
    payload: null,
    input_type: 'email',
  },
]


const resolveAttribute = (
  listingUserAttributes: TenantUserManagementListingUser['user_attributes'] | undefined,
  slug: string,
): ListingUserParameter | undefined => {
  const match = listingUserAttributes && listingUserAttributes.find( a => a.group && a.group.slug === slug )
  return match && match.value
}


export const generateUserAttributesForComponent = (
  attributes: TenantUserAttribute[],
  existingUserObject: TenantUserManagementListingUser | null | undefined
): TenantUserAttribute[] => {
  let spliceIndex = 0
  const generatedAttributes = attributes.slice()
  if( attributes[0] && attributes[0].slug === 'user-type' ){
    spliceIndex = 1
  }
  generatedAttributes.splice(spliceIndex, 0, ...userDetailAttributes)

  const userStatusValue = existingUserObject && resolveAttribute(existingUserObject.user_attributes, 'user-status')
  const isUnknownSSOUser = !!(userStatusValue && userStatusValue.slug === 'unknown-sso-user')

  return generatedAttributes.map( attr => {
    if( !isTenantUserAttributeSelect(attr) ){
      return attr
    }
    let enabled = attr.enabled
    if( attr.slug === 'user-status' ){
      enabled = isUnknownSSOUser
    }
    return {
      ...attr,
      enabled,
      values: attr.values.map( value => {
        let enabled = value.enabled
        if( value.slug === 'unknown' && !isUnknownSSOUser ){
          enabled = false
        }
        return {
          ...value,
          enabled,
        }
      })
    }
  })
}


export const generateDerivedListingUser = (
  listingUser: TenantUserManagementListingUser
): DerivedListingUser => {
  const user_type = resolveAttribute(listingUser.user_attributes, 'user-type')
  const market = resolveAttribute(listingUser.user_attributes, 'market')
  const user_status = resolveAttribute(listingUser.user_attributes, 'user-status')
  return {
    user_id: listingUser.user_id,
    enabled: listingUser.enabled,
    email: listingUser.email,
    name: listingUser.name || listingUser.email,
    created: listingUser.created,
    activated_at: listingUser.activated_at,
    modified: listingUser.modified,
    last_login: listingUser.last_login,
    user_type: user_type && user_type.name,
    user_status: user_status && user_status.name,
    market: market && market.name,
  }
}
