import { Box, Button, Chip, Typography } from '@material-ui/core'
import { Cancel, ExpandMore } from '@material-ui/icons'
import { makeAppStyles } from '@percept/mui'
import React from 'react'

interface Props {
  filter_name: string
  options: {value: string, name: string}[]
  clickHandler: (e: React.MouseEvent<HTMLButtonElement>) => void
  deleteHandler: (value: {value: string, name: string}) => void
}

const useAggregationFiltersClasses = makeAppStyles((theme) => ({
  root: {
    gap: theme.spacing(1),
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  chipButton: {
    height: theme.spacing(4),
  },
  button: {
    color: 'inherit',
    minWidth: theme.spacing(3),
  },
  textTitle: {
    fontWeight: 700,
  },
  chip: {
    fontWeight: 400,
    color: 'inherit',
  },
  deleteIcon: {
    color: 'inherit',
    opacity: 0.35,
    '&:hover': {
      color: 'inherit',
      opacity: 0.65,
    },
    transition: theme.transitions.create('opacity'),
  },
}))

export const AggregationButton = ({
  filter_name,
  options,
  clickHandler,
  deleteHandler,
}: Props): JSX.Element => {
  const aggregationClasses = useAggregationFiltersClasses()

  return (
    <Box display='flex' className={aggregationClasses.root}>
      <Typography variant='body2' className={aggregationClasses.textTitle}>
        {filter_name}:
      </Typography>
      {options.map((option) => (
        <Chip
          size='small'
          variant='outlined'
          className={aggregationClasses.chip}
          key={option.value}
          label={option.name}
          deleteIcon={
            <Cancel className={aggregationClasses.deleteIcon} />
          }
          onDelete={(): void => deleteHandler(option)}
        />
      ))}
      <Button className={aggregationClasses.button} onClick={clickHandler}>
        <ExpandMore fontSize='small' />
      </Button>
    </Box>
  )
}
