import AppComponent, { AppProps, NavigationOptions } from './App'

import { Dispatch } from 'redux'

import { connect } from 'react-redux'

import { getLocation, replace, push } from 'connected-react-router'

import qs from 'query-string'

import {
  reportError,
} from 'dashboard/src/redux/actions'

import { user, notifications } from '@percept/redux/bundles'


const { MFA_REQUIRED } = process.env


const stateToProps = (state: any): Pick<
  AppProps,
  'initialLocation' | 'location' | 'loggedIn' | 'refreshResponse' | 'ssoRefreshResponse' | 'userPrivileges'
> => {
  return {
    initialLocation: state.appState.initialLocation,
    location: getLocation(state),
    loggedIn: (
      MFA_REQUIRED ?
        user.selectors.getIsLoggedInWithMfaRequirement(state) :
        user.selectors.getUserLoggedIn(state)
    ),
    refreshResponse: user.selectors.getUserTokenRefreshResponse(state),
    ssoRefreshResponse: user.selectors.getSingleSignOnRefreshResponse(state),
    userPrivileges: user.selectors.getMyUserPrivileges(state),
  }
}

const dispatchToProps = (dispatch: Dispatch): Pick<
  AppProps,
  'addNotification' | 'addToast' | 'navigate' | 'checkRefreshToken' | 'reportError'
> => ({
  checkRefreshToken: (): void => {
    dispatch(user.actions.checkRefreshToken())
  },
  addNotification: (notification): void => {
    dispatch(notifications.actions.addNotification(notification))
  },
  addToast: (toast): void => {
    dispatch(notifications.actions.addToast(toast))
  },
  navigate: (route: string, options: NavigationOptions = {}): void => {
    const actionCreator = options.replace ? replace : push
    const routeSearch = options.search ? qs.stringify(options.search) : ''
    const parsedRoute = routeSearch ? route + '?' + routeSearch : route
    dispatch(actionCreator(parsedRoute))
  },
  reportError: ({ error, errorInfo }: { error: Error, errorInfo: React.ErrorInfo }): void => {
    dispatch(reportError({ error, errorInfo }))
  },
})


export default connect( stateToProps, dispatchToProps )( AppComponent )
