import React from 'react'
import { makeFlagIconComponent } from './lib'


export const IE = makeFlagIconComponent(
  <>
    <g fillRule="evenodd" strokeWidth="1pt">
      <path fill="#fff" d="M0 0h512v512H0z"/>
      <path fill="#009A49" d="M0 0h170.7v512H0z"/>
      <path fill="#FF7900" d="M341.3 0H512v512H341.3z"/>
    </g>
  </>
)
