import { min } from 'date-fns'

import { PlatformUnit, ProviderReferenceDates } from '@percept/types'


export function flattenNestedItems<T extends { children?: T[] }>(items: T[]): T[] {
  let output: T[] = []
  for( const item of items ){
    output.push(item)
    if( item.children ){
      output = output.concat(flattenNestedItems(item.children))
    }
  }
  return output
}

export const areOrgsEqual = (a: PlatformUnit | null, b: PlatformUnit | null): boolean => !!(
  a && b && a.id === b.id
)

export const getMaxDate = (referenceDates: ProviderReferenceDates): Date | null => {
  const providers: (keyof ProviderReferenceDates)[] = [
    'adform',
    'adwords',
    'doubleverify_adform',
    'doubleverify_adwords',
    'doubleverify_facebook',
    'dv360',
    'facebook',
    'AMAZON_ADS',
  ]
  const dates: Date[] = []
  for( const provider of providers ){
    if( referenceDates[provider] && referenceDates[provider].reference_date ){
      dates.push(new Date(referenceDates[provider].reference_date))
    }
  }
  return min(dates) || null
}
