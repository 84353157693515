import React from 'react'

import { CellRenderer, CellRenderers, Column, formatMoney, ProviderLogo, SimpleTableProps } from '@percept/mui'

import { DataWarehouseProvider, DataWarehouseQueryResponse } from './hooks'

import { Dictionary } from '@percept/types'

import { deslugify, separateThousands } from '@percept/utils'

import { intersection, xor } from 'lodash-es'

import { automaticPrecisionPercentageFormatter } from '@percept/mui/charts'

import { performanceReportingDimensionOrder } from '@percept/constants'


export type GenericTableRow = Dictionary


const primaryDimensionOrder: string[] = [
  'org_unit_name',
  'organisation',
  'provider'
]

const monetaryColumns: string[] = [
  'cost',
  'cost_per_conversion',
  'average_cpc',
  'average_cpm',
  'average_cpv',
]
const numericColumns: string[] = [
  'impressions',
  'conversions',
  'video_views',
  'clicks',
]
const rateBasedColumns: string[] = [
  'average_ctr',
]

const defaultCellRenderers: CellRenderers<GenericTableRow> = {
  /* eslint-disable react/display-name, react/prop-types */
  provider: ({ provider }) => (
    <ProviderLogo provider={provider as DataWarehouseProvider} size={1.35} />
  ),
  /* eslint-enable react/display-name, react/prop-types */
}

const displayNA = <span style={{opacity: 0.5}}>N / A</span>

const nullFormatter = (value: string | number | null, formatter: (value: number) => string) => {
  if( value === null ){
    return displayNA
  }
  return formatter(Number(value))
}

const makeCellRenderers = (currency: string): CellRenderers<GenericTableRow> => {
  return {
    ...numericColumns.reduce( (acc, column) => {
      acc[column] = (row) => (
        <>{nullFormatter(row[column], separateThousands)}</>
      )
      return acc
    }, {} as Record<string, CellRenderer<GenericTableRow>>),
    ...rateBasedColumns.reduce( (acc, column) => {
      acc[column] = (row) => (
        <>{nullFormatter(row[column], val => automaticPrecisionPercentageFormatter(val * 100))}</>
      )
      return acc
    }, {} as Record<string, CellRenderer<GenericTableRow>>),
    ...monetaryColumns.reduce( (acc, column) => {
      acc[column] = (row) => (
        <>{nullFormatter(row[column], amount => formatMoney({ amount, abbreviate: false, currency }))}</>
      )
      return acc
    }, {} as Record<string, CellRenderer<GenericTableRow>>)
  }
}

const getDimensionSortValue = (value: string): string => (
  value.toUpperCase()
)


const getOrderedKeys = (keys: string[], order: string[]): string[] => {
  const recognisedKeys = intersection(order, keys)
  const remainingKeys = xor(recognisedKeys, keys)
  remainingKeys.sort()
  return [
    ...recognisedKeys,
    ...remainingKeys,
  ]
}

const getMetricLabel = (key: string): string => {
  key = key.replace('average', 'Avg.')
  let deslugified = deslugify(key)
  for( const m of ['Cpc', 'Ctr', 'Cpm', 'Cpv', 'Cpa'] ){
    deslugified = deslugified.replace(m, m.toUpperCase())
  }
  return deslugified
}


export const getDataWarehouseTableProps = (
  queryResponse: DataWarehouseQueryResponse,
  currency: string
): Pick<SimpleTableProps<GenericTableRow>, 'columns' | 'rows' | 'renderers' | 'defaultRenderer' | 'grouped'> => {
  
  const dimensions = queryResponse.metadata.dimensions
  const sampleRow = queryResponse.rows[0]
  const metricKeys = (
    sampleRow ?
      Object.keys(sampleRow.metrics) : [
        ...monetaryColumns,
        ...numericColumns,
        ...rateBasedColumns,
      ]
  )

  const orderedMetricKeys = getOrderedKeys(metricKeys, performanceReportingDimensionOrder)
  const orderedDimensionKeys = getOrderedKeys(Object.keys(dimensions), primaryDimensionOrder)

  const visibleDimensions = orderedDimensionKeys.filter( k => dimensions[k].internal !== true)

  const columns: Column<GenericTableRow>[] = [
    ...visibleDimensions.map( key => ({
      key,
      label: dimensions[key].label,
      align: 'left',
      getSortValue: getDimensionSortValue,
    }) as Column<GenericTableRow>),
    ...orderedMetricKeys.map( key => ({
      key,
      label: getMetricLabel(key),
      align: 'right'
    }) as Column<GenericTableRow>)
  ]

  const rows: GenericTableRow[] = queryResponse.rows.map( row => ({
    ...row.dimensions,
    ...row.metrics,
  }))

  return {
    grouped: false,
    columns,
    rows,
    renderers: {
      ...defaultCellRenderers,
      ...makeCellRenderers(currency),
    },
  }
}
