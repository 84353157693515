import { SingleMarketOverviewDatum } from './typings'

export const singleQuarterlyMarketValues: SingleMarketOverviewDatum[] = [
  {
    market_name: 'DE',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'quarter',
        segments: [
          {
            segment: 'Vodafone',
            current: 19,
            previous: 22,
            target: 29,
          },
          {
            segment: 'DTAG',
            current: 37,
            previous: 32,
            target: null,
          },
          {
            segment: 'O2',
            current: 17,
            previous: 19,
            target: null,
          },
          {
            segment: '1+1',
            current: 19,
            previous: 20,
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'quarter',
        segments: [
          {
            segment: 'All Media',
            current: 40,
            previous: 37, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 55, 
            previous: 100, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 20,
            previous: 21,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'quarter',
        current: 22,
        previous: null,
        change: null,
        value: null,
        target: 20,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'quarter',
        current: 4.01,
        previous: null,
        change: -15.08,
        value: 631833.43,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'quarter',
        current: 3.61,
        previous: null,
        change: 22.34,
        value: 903472.09,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'quarter',
        current: 9.35,
        previous: null,
        change: 5.14,
        value: 1535306,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'quarter',
        current: 4.41,
        previous: null,
        change: -39.09,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'quarter',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'UK',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'quarter',
        segments: [
          {
            segment: 'Vodafone',
            current: 13,
            previous: 10,
            target: 15,
          },
          {
            segment: 'BT',
            current: 12,
            previous: 11,
            target: null,
          },
          {
            segment: 'Sky',
            current: 23,
            previous: 23,
            target: null,
          },
          {
            segment: 'O2',
            current: 7,
            previous: 8,
            target: null,
          },
          {
            segment: 'Tesco Mobile',
            current: 10,
            previous: 7,
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'quarter',
        segments: [
          {
            segment: 'All Media',
            current: 75,
            previous: 55, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 94, 
            previous: 86, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 52,
            previous: 30,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'quarter',
        current: 24,
        previous: null,
        change: null,
        value: null,
        target: 20,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'quarter',
        current: 4.39,
        previous: null,
        change: -11.18,
        value: 339868.58,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'quarter',
        current: 38.73,
        previous: null,
        change: -29.88,
        value: 896050.33,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'quarter',
        current: 27.68,
        previous: null,
        change: 27.56,
        value: 1235919,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'quarter',
        current: 43.19,
        previous: null,
        change: 55.04,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'quarter',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'IT',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'quarter',
        segments: [
          {
            segment: 'Vodafone',
            current: 22,
            previous: 19,
            target: 22,
          },
          {
            segment: 'TIM',
            current: 28,
            previous: 30,
            target: null,
          },
          {
            segment: 'WindTre',
            current: 17,
            previous: 17,
            target: null,
          },
          {
            segment: 'fastweb',
            current: 10,
            previous: 8,
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'quarter',
        segments: [
          {
            segment: 'All Media',
            current: 56,
            previous: 40, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 80, 
            previous: 53, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 17,
            previous: 19,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'quarter',
        current: 21,
        previous: null,
        change: null,
        value: null,
        target: 20,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'quarter',
        current: 10.52,
        previous: null,
        change: 3.10,
        value: 409141.23,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'quarter',
        current: 57.12,
        previous: null,
        change: 3.71,
        value: 378912.98,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'quarter',
        current: 9.43,
        previous: null,
        change: -35.39,
        value: 788054,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'quarter',
        current: 6,
        previous: null,
        change: -34.65,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'quarter',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'ES',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'quarter',
        segments: [
          {
            segment: 'Vodafone',
            current: 21,
            previous: 14,
            target: 22,
          },
          {
            segment: 'Telefonica',
            current: 25,
            previous: 32,
            target: null,
          },
          {
            segment: 'Orange',
            current: 19,
            previous: 18,
            target: null,
          },
          {
            segment: 'Jazztel',
            current: 8,
            previous: 9,
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'quarter',
        segments: [
          {
            segment: 'All Media',
            current: 43,
            previous: 55, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 54, 
            previous: 70, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 31,
            previous: 40,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'quarter',
        current: 33,
        previous: null,
        change: null,
        value: null,
        target: 30,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'quarter',
        current: 4.88,
        previous: null,
        change: -35.70,
        value: 154853.16,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'quarter',
        current: 13.05,
        previous: null,
        change: -53.32,
        value: 60376.91,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'quarter',
        current: 4.16,
        previous: null,
        change: -43.69,
        value: 215230,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'quarter',
        current: 65.19,
        previous: null,
        change: 29.38,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'quarter',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'ZA',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'quarter',
        segments: [
          {
            segment: 'Vodafone',
            current: 23,
            previous: 33,
            target: 37,
          },
          {
            segment: 'MTN',
            current: 55,
            previous: 35,
            target: null,
          },
          {
            segment: 'Rain Mobile',
            current: 10,
            previous: 9,
            target: null,
          },
          {
            segment: 'Telekom',
            current: 10,
            previous: 15,
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'quarter',
        segments: [
          {
            segment: 'All Media',
            current: 52,
            previous: 42, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 78, 
            previous: 75, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 36,
            previous: 24,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'quarter',
        current: 39,
        previous: null,
        change: null,
        value: null,
        target: 35,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'quarter',
        current: 6.61,
        previous: null,
        change: -5.98,
        value: 122425.66,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'quarter',
        current: 62.44,
        previous: null,
        change: -9.30,
        value: 365762.94,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'quarter',
        current: 24.18,
        previous: null,
        change: -21.31,
        value: 488189,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'quarter',
        current: 13.91,
        previous: null,
        change: 50.23,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'quarter',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'TR',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'quarter',
        segments: [
          {
            segment: 'Vodafone',
            current: 17,
            previous: 27,
            target: 30,
          },
          {
            segment: 'Turkcell',
            current: 36,
            previous: 38,
            target: null,
          },
          {
            segment: 'Telekom',
            current: 47,
            previous: 35,
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'quarter',
        segments: [
          {
            segment: 'All Media',
            current: 78,
            previous: 34, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 100, 
            previous: 38, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 61,
            previous: 23,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'quarter',
        current: 46,
        previous: null,
        change: null,
        value: null,
        target: 35,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'quarter',
        current: 5.51,
        previous: null,
        change: 89.62,
        value: 110985.4,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'quarter',
        current: 68.40,
        previous: null,
        change: 9.26,
        value: 111916.3,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'quarter',
        current: 10.99,
        previous: null,
        change: 34.70,
        value: 222902,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'quarter',
        current: 16.01,
        previous: null,
        change: 21.07,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'quarter',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'RO',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'quarter',
        segments: [
          {
            segment: 'Vodafone',
            current: 26,
            previous: 34,
            target: 35,
          },
          {
            segment: 'Telekom',
            current: 11,
            previous: 9,
            target: null,
          },
          {
            segment: 'Orange',
            current: 47,
            previous: 45,
            target: null,
          },
          {
            segment: 'RCS&RDS',
            current: 16,
            previous: 12,
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'quarter',
        segments: [
          {
            segment: 'All Media',
            current: 49,
            previous: 61, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 70, 
            previous: 82, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 33,
            previous: 61,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'quarter',
        current: 27,
        previous: null,
        change: null,
        value: null,
        target: 35,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'quarter',
        current: 4.71,
        previous: null,
        change: -38.48,
        value: 48056.14,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'quarter',
        current: 61.11,
        previous: null,
        change: -19.97,
        value: 270793.21,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'quarter',
        current: 31.12,
        previous: null,
        change: -21.44,
        value: 318849,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'quarter',
        current: 16.79,
        previous: null,
        change: 686.87,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'quarter',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'GR',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'quarter',
        segments: [
          {
            segment: 'Vodafone',
            current: null,
            previous: null, //  YoY Change CSV value = -7, for now we have to do like this cause of speed current - previous = 12 - 9 = -7
            target: 20,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'quarter',
        segments: [
          {
            segment: 'All Media',
            current: 73,
            previous: 62, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 62, 
            previous: 91, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 25,
            previous: 41,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'quarter',
        current: 55,
        previous: null,
        change: null,
        value: null,
        target: 35,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'quarter',
        current: 5.87,
        previous: null,
        change: 2.84,
        value: 52067.23,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'quarter',
        current: 34.79,
        previous: null,
        change: -40.41,
        value: 114971.82,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'quarter',
        current: 20.42,
        previous: null,
        change: 0.09,
        value: 167039,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'quarter',
        current: 35.92,
        previous: null,
        change: 94.57,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'quarter',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'PT',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'quarter',
        segments: [
          {
            segment: 'Vodafone',
            current: 31,
            previous: 36,
            target: 30,
          },
          {
            segment: 'NOS',
            current: 23,
            previous: 46, //  YoY Change CSV value = -7, for now we have to do like this cause of speed current - previous = 12 - 9 = -7
            target: null,
          },
          {
            segment: 'Altice',
            current: 46,
            previous: 18, //  YoY Change CSV value = -7, for now we have to do like this cause of speed current - previous = 12 - 9 = -7
            target: null,
          },
          {
            segment: 'Telekom',
            current: 0,
            previous: 0, //  YoY Change CSV value = -7, for now we have to do like this cause of speed current - previous = 12 - 9 = -7
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'quarter',
        segments: [
          {
            segment: 'All Media',
            current: 78,
            previous: 91, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 100, 
            previous: 100, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 34,
            previous: 63,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'quarter',
        current: 42,
        previous: null,
        change: null,
        value: null,
        target: 35,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'quarter',
        current: 4.53,
        previous: null,
        change: -1.28,
        value: 40061.34,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'quarter',
        current: 34.86,
        previous: null,
        change: -19.12,
        value: 105317.29,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'quarter',
        current: 17.26,
        previous: null,
        change: -18.28,
        value: 145379,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'quarter',
        current: 39.81,
        previous: null,
        change: 25.68,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'quarter',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'IE',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'quarter',
        segments: [
          {
            segment: 'Vodafone',
            current: 24,
            previous: 18,
            target: 26,
          },
          {
            segment: 'Sky',
            current: 28,
            previous: 45,
            target: null,
          },
          {
            segment: '3',
            current: 17,
            previous: 20,
            target: null,
          },
          {
            segment: 'Virgin Media',
            current: 19,
            previous: 15,
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'quarter',
        segments: [
          {
            segment: 'All Media',
            current: 76,
            previous: 86, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 100, 
            previous: 100, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 23,
            previous: 59,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'quarter',
        current: 44,
        previous: null,
        change: null,
        value: null,
        target: 35,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'quarter',
        current: 2.68,
        previous: null,
        change: -22.56,
        value: 15035.03,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'quarter',
        current: 37.13,
        previous: null,
        change: -36.77,
        value: 6706.06,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'quarter',
        current: 3.57,
        previous: null,
        change: -56.33,
        value: 21741,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'quarter',
        current: 45.38,
        previous: null,
        change: 136.17,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'quarter',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'CZ',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'quarter',
        segments: [
          {
            segment: 'Vodafone',
            current: 31,
            previous: 27,
            target: 35,
          },
          {
            segment: 'O2',
            current: 33,
            previous: 35,
            target: null,
          },
          {
            segment: 'DTAG',
            current: 36,
            previous: 38,
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'quarter',
        segments: [
          {
            segment: 'All Media',
            current: 64,
            previous: 60, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 63, 
            previous: 66, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 57,
            previous: 46,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'quarter',
        current: 70,
        previous: null,
        change: null,
        value: null,
        target: 35,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'quarter',
        current: 3.98,
        previous: null,
        change: -6.29,
        value: 35695.81,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'quarter',
        current: 42.76,
        previous: null,
        change: 3.49,
        value: 167752.8,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'quarter',
        current: 27.03,
        previous: null,
        change: 11.41,
        value: 203449,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'quarter',
        current: 23.46,
        previous: null,
        change: 7.37,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'quarter',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'AL',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'quarter',
        segments: [
          {
            segment: 'Vodafone',
            current: 50,
            previous: 57,
            target: 50,
          },
          {
            segment: 'Albtelecom&Eagle',
            current: 0,
            previous: 9,
            target: null,
          },
          {
            segment: 'ONE',
            current: 50,
            previous: 35,
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'quarter',
        segments: [
          {
            segment: 'All Media',
            current: 64,
            previous: 53, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 57, 
            previous: 48, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 73,
            previous: 53,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'quarter',
        current: 31,
        previous: null,
        change: null,
        value: null,
        target: 35,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'quarter',
        current: 5.84,
        previous: null,
        change: -4.52,
        value: 3103.47,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'quarter',
        current: 76.37,
        previous: null,
        change: 3.44,
        value: 18171.71,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'quarter',
        current: 12.53,
        previous: null,
        change: -24.31,
        value: 21275,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'quarter',
        current: 19.50,
        previous: null,
        change: 126.08,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'quarter',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
  {
    market_name: 'EG',
    breakdowns: [
      {
        dimension: 'share_of_spend',
        granularity: 'quarter',
        segments: [
          {
            segment: 'Vodafone',
            current: 31,
            previous: 33,
            target: 35,
          },
          {
            segment: 'Etisalat',
            current: 18,
            previous: 18,
            target: null,
          },
          {
            segment: 'Orange',
            current: 20,
            previous: 21,
            target: null,
          },
          {
            segment: 'Telekom',
            current: 31,
            previous: 34,
            target: null,
          },
        ],
      },
      {
        dimension: 'brand_investment_share',
        granularity: 'quarter',
        segments: [
          {
            segment: 'All Media',
            current: 88,
            previous: 85, // CSV YoY Change = 3 > 40-37 = 3
            value: null,
            target: 60,
          },
          {
            segment: 'TV',
            current: 89, 
            previous: 93, // CSV YoY Change = -45 > 100-55 = -45
            value: null,
            target: null,
          },
          {
            segment: 'Digital',
            current: 76,
            previous: 84,  // CSV YoY Change = -1 > 20-21 = -1
            value: null,
            target: null,
          },
        ],
      },
    ],
    scores: [
      {
        dimension: 'production_vs_media',
        granularity: 'quarter',
        current: 27,
        previous: null,
        change: null,
        value: null,
        target: 35,
      },
      {
        dimension: 'digital_wastage',
        granularity: 'quarter',
        current: 5.09,
        previous: null,
        change: -7.28,
        value: 30483.85,
        target: null,
      },
      {
        dimension: 'creative_wastage',
        granularity: 'quarter',
        current: 71.01,
        previous: null,
        change: -0.99,
        value: 268976.67,
        target: null,
      },
      {
        dimension: 'total_wastage',
        granularity: 'quarter',
        current: 22.78,
        previous: null,
        change: -56.54,
        value: 299461,
        target: null,
      },
      {
        dimension: 'creative_quality_score',
        granularity: 'quarter',
        current: 4.95,
        previous: null,
        change: 92.99,
        value: null,
        target: 80,
      },
      // {
      //   dimension: 'media_quality_rate',
      //   granularity: 'quarter',
      //   current: null,
      //   previous: null,
      //   change: null,
      //   value: null,
      //   target: null,
      // },
    ],
  },
]