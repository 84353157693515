
import { Selector } from 'react-redux'

import { apiInitialState } from '../../constants'

import {
  StoreState,
  ApiResponse,
  LayoutType,
  ReportLayoutParams,
} from '@percept/types'

import { LayoutState } from './typings'


type StoreWithLayouts = {
  layouts: LayoutState
}

/**
 * TODO:
 * 
 * Deprecate this, or move to admin-only layouts bundle?
 * Check usage
 */
export const getLayouts = (state: StoreState): ApiResponse<LayoutType[]> => (
  state.layouts.overview
)

export const getLayout: Selector<
  StoreWithLayouts, ApiResponse<LayoutType>, ReportLayoutParams
> = (state, { report_layout_id }) => (
  report_layout_id && state.layouts.byId[report_layout_id] || apiInitialState
)
