import React from 'react'
import { makeFlagIconComponent } from './lib'


export const HU = makeFlagIconComponent(
  <>
    <g fillRule="evenodd">
      <path fill="#fff" d="M512 512H0V0h512z"/>
      <path fill="#388d00" d="M512 512H0V341.3h512z"/>
      <path fill="#d43516" d="M512 170.8H0V.1h512z"/>
    </g>
  </>
)
