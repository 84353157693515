import React from 'react'

import {
  MUIDataTableFilterList,
  TableFilterList,
} from 'mui-datatables'

import {
  Box,
  Chip,
  ChipProps,
  makeAppStyles,
} from '@percept/mui'


const useFilterChipStyles = makeAppStyles( theme => ({
  root: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    display: 'flex',
    fontSize: 10,
    fontWeight: 500,
    marginRight: theme.spacing(1),
  },
  valueContent: {
    display: 'flex',
    maxWidth: '12rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))


type ExamplesFilterChipProps = (
  ChipProps & {
    columnNames: MUIDataTableFilterList['columnNames']
    index: number
  }
)


const ExamplesFilterChip = ({
  label,
  onDelete,
  ...props
}: ExamplesFilterChipProps): JSX.Element => {

  const classes = useFilterChipStyles()

  return (
    <Chip
      className={classes.root}
      size='small'
      color='primary'
      onDelete={onDelete}
      label={
        <span className={classes.container}>
          { props.columnNames && props.columnNames[props.index] && (
            <span className={classes.label}>
              { props.columnNames[props.index].name }
            </span>
          )}
          <span className={classes.valueContent}>
            { label }
          </span>
        </span>
      } />
  )
}


/* MUIDataTable type defs aren't the best, we need to define some existing extension points */

type TableFilterListProps = (
  MUIDataTableFilterList & {
    ItemComponent?: React.FC<ExamplesFilterChipProps>
  }
)

const TypedTableFilterList = TableFilterList as React.ComponentType<TableFilterListProps>

const ExamplesFilterList: React.ComponentType<TableFilterListProps> = (props) => {
  return (
    <Box mb={2} lineHeight={1.25}>
      <TypedTableFilterList
        {...props}
        ItemComponent={ExamplesFilterChip} />
    </Box>
  )
}


type CustomComponents = {
  TableFilterList: React.ComponentType<TableFilterListProps>
}


export const smartTableComponents: CustomComponents = {
  TableFilterList: ExamplesFilterList,
}
