import React from 'react'

import { useAppTheme } from '@percept/mui'

import {
  ChartData,
  MultiDataset,
  SizeRequirements,
  getDatasetStacksArray,
} from '@percept/mui/charts'

import { AnimatedBars } from '@percept/mui/charts/Histogram/AnimatedBars'
import { LineComponent } from '@percept/mui/charts/Quantitative/PathComponents'

import { ensureValidDomain } from '@percept/mui/charts/lib'

import { GridRows } from '@visx/grid'

import { Group } from '@visx/group'

import {
  WastageSegmentation,
  WastageVariant,
} from '@percept/hooks'

import { Axis } from '@visx/axis'

import { scaleBand, scaleLinear } from '@visx/scale'

import { flatten, get, uniq } from 'lodash-es'

import {
  datasetColorSchemesMapping,
  datasetConfigurations,
  legendFontSize,
  pathDatasets,
  wastageSegmentationTickFormatters,
} from '../dataUtils'

import {
  LegendConfiguration,
  getLinearScaleRatePercentageAccessor,
  getPrimaryYTickLabelProps,
  getScaleBandMidPointAccessor,
  getSecondaryYTickLabelProps,
  getXTickLabelProps,
  percentageTickFormatter,
  spendTickFormatter,
} from './lib'

import {
  AllDatasets,
  PathDataset,
} from '../typings'


export type WastageTrendChartProps = {
  variant: WastageVariant
  segmentation: WastageSegmentation
  spendDatasets: MultiDataset[] | null
  creativeQualityScoreDataset: ChartData | null
  wastagePercentageDataset: ChartData | null
  showCreativeQualityScore: boolean
  showWastageSpend: boolean
  showWastagePercentage: boolean
  svgRef?: React.MutableRefObject<SVGSVGElement | null>
} & SizeRequirements


const timeBasedSegmentations: WastageSegmentation[] = [
  'CALENDAR_YEAR',
  'FINANCIAL_YEAR',
  'MONTH',
  'QUARTER'
]


export const WastageTrendChart = ({
  width,
  height,
  variant,
  segmentation,
  spendDatasets,
  creativeQualityScoreDataset,
  wastagePercentageDataset,
  showCreativeQualityScore,
  showWastagePercentage,
  showWastageSpend,
  svgRef,
}: WastageTrendChartProps): JSX.Element => {

  const appTheme = useAppTheme()

  const canonicalXOffset = 72

  const verticalMargin = 80

  const legendVerticalOffset = 48

  const xMax = width - canonicalXOffset

  const yMax = height - verticalMargin

  spendDatasets = spendDatasets || []

  const datasets: (ChartData | null | false)[] = [
    showCreativeQualityScore && creativeQualityScoreDataset,
    showWastagePercentage && wastagePercentageDataset,
    showWastageSpend && get(spendDatasets[0], 'data', null),
    showWastageSpend && get(spendDatasets[1], 'data', null),
  ]
  const applicableDatasets = datasets.filter( d => !!d ) as ChartData[]

  const xDomain = uniq(
    flatten(
      applicableDatasets
    ).map( d => String(d.label))
  )
  if( timeBasedSegmentations.includes(segmentation) ){
    xDomain.sort()
  }

  const xScale = scaleBand<string>({
    range: [canonicalXOffset, xMax],
    round: true,
    domain: xDomain,
    padding: 0.1,
  })

  const dataTotalsByLabel = spendDatasets.reduce( (acc, dataset) => {
    dataset.data.forEach( (datum) => {
      acc[datum.label] = acc[datum.label] || 0
      acc[datum.label] += Number(datum.value)
    })
    return acc
  }, {} as Record<string, number>)

  const maxStackValue = Math.max(...Object.values(dataTotalsByLabel))

  const yScaleSpend = scaleLinear<number>({
    range: [yMax, 0],
    domain: ensureValidDomain([0, maxStackValue]),
    nice: true,
  })

  const yScalePercentage = scaleLinear({
    range: [yMax, 0],
    domain: [0, 100],
  })

  const showPercentageScale = showWastagePercentage || showCreativeQualityScore
  const showSpendScale = showWastageSpend

  const axisConfiguration = {
    spend: {
      scale: yScaleSpend,
      formatter: spendTickFormatter,
    },
    percentage: {
      scale: yScalePercentage,
      formatter: percentageTickFormatter,
    }
  }

  const primaryAxis: (keyof (typeof axisConfiguration)) | null = (
    showSpendScale ?
      'spend' :
      showPercentageScale ?
        'percentage' :
        null
  )

  const secondaryAxis: (keyof (typeof axisConfiguration)) | null = (
    showSpendScale && showPercentageScale ?
      'percentage' : null
  )

  const primaryAxisConfiguration = primaryAxis && axisConfiguration[primaryAxis]
  const secondaryAxisConfiguration = secondaryAxis && axisConfiguration[secondaryAxis]

  const spendDatasetStacksArray = getDatasetStacksArray({
    datasets: spendDatasets,
    yMax,
    yScale: yScaleSpend,
    xDomain,
    xScale,
  })

  const axisStrokeColor = '#BBB'
  const tickStrokeColor = '#666'

  const colorScheme = datasetColorSchemesMapping[variant]

  if( !(width > 0 && height > 0) ){
    return (
      <svg ref={svgRef} width={width || '100%'} height={height || '100%'} />
    )
  }

  const pathComponentXAccessor = getScaleBandMidPointAccessor(xScale)
  const pathComponentYAccessor = getLinearScaleRatePercentageAccessor(yScalePercentage)

  const markerSize = 3

  const pathDatasetMapping: Record<PathDataset, ChartData | null> = {
    creativeQualityScore: creativeQualityScoreDataset,
    wastagePercentage: wastagePercentageDataset,
  }
  const enabledPathDatasets: PathDataset[] = []
  if( showCreativeQualityScore ) enabledPathDatasets.push('creativeQualityScore')
  if( showWastagePercentage ) enabledPathDatasets.push('wastagePercentage')

  const enabledDatasets: AllDatasets[] = (
    showWastageSpend ? [
      'efficientSpend', 'wastedSpend', ...enabledPathDatasets
    ] : enabledPathDatasets
  )

  const legendConfigurations: LegendConfiguration[] = enabledDatasets.reduce( (acc, d, i) => {
    const prev = acc[i - 1]
    const x = prev ? prev.x + prev.width + 32 : canonicalXOffset
    const { displayLabel, displayLabelWidth } = datasetConfigurations[d]
    const color = colorScheme[d]
    acc.push({
      label: displayLabel,
      x,
      width: displayLabelWidth,
      color,
    })
    return acc
  }, [] as LegendConfiguration[])

  return (
    <svg ref={svgRef} width={width} height={height}>

      <defs>
        { pathDatasets.map( pathDataset => (
          <marker
            key={`marker-${pathDataset}`}
            id={datasetConfigurations[pathDataset].markerId}
            viewBox={`0 0 ${markerSize * 2} ${markerSize * 2}`}
            refX={markerSize}
            refY={markerSize}
            markerWidth={markerSize}
            markerHeight={markerSize}>
            <circle
              cx={markerSize}
              cy={markerSize}
              r={markerSize}
              fill={colorScheme[pathDataset]} />
          </marker>
        ))}
      </defs>

      { primaryAxisConfiguration && (
        <GridRows
          left={canonicalXOffset}
          scale={primaryAxisConfiguration.scale}
          width={xMax - canonicalXOffset}
          height={yMax}
          stroke={appTheme.chart.grid.stroke} />
      )}

      { primaryAxisConfiguration && (
        <Axis
          orientation='bottom'
          scale={xScale}
          tickLabelProps={getXTickLabelProps}
          tickStroke={tickStrokeColor}
          tickLength={8}
          tickFormat={wastageSegmentationTickFormatters[segmentation]}
          stroke={axisStrokeColor}
          top={Number(yScaleSpend(yScaleSpend.domain()[0]))} />
      )}

      { primaryAxisConfiguration && (
        <Axis
          orientation='left'
          scale={primaryAxisConfiguration.scale}
          tickLabelProps={getPrimaryYTickLabelProps}
          left={canonicalXOffset}
          tickFormat={primaryAxisConfiguration.formatter}
          tickStroke={tickStrokeColor}
          stroke={axisStrokeColor}
          tickLength={8} />
      )}

      { secondaryAxisConfiguration && (
        <Axis
          orientation='right'
          scale={secondaryAxisConfiguration.scale}
          tickLabelProps={getSecondaryYTickLabelProps}
          left={width - canonicalXOffset}
          tickFormat={secondaryAxisConfiguration.formatter}
          tickStroke={tickStrokeColor}
          stroke={axisStrokeColor}
          tickLength={8} />
      )}

      { showWastageSpend && (
        <Group>
          { spendDatasetStacksArray.map( ([dataset, bars]) => (
            <AnimatedBars
              key={`stack-${dataset.key}`}
              bars={bars}
              animate={true}
              animateInitial={false}
              includeBarBackground={false}
              xScale={xScale}
              yScale={yScaleSpend}
              maxHeight={yMax}
            />
          ))}
        </Group>
      )}

      { enabledPathDatasets.map( pathDataset => {
        const chartData = pathDatasetMapping[pathDataset]
        if( !chartData ){
          return null
        }
        const { markerUrl } = datasetConfigurations[pathDataset]
        return (
          <Group key={`group-${pathDataset}`}>
            <LineComponent
              data={chartData}
              labels={xDomain}
              stroke={colorScheme[pathDataset]}
              svgPathProps={{
                strokeWidth: 4,
                markerStart: markerUrl,
                markerMid: markerUrl,
                markerEnd: markerUrl,
              }}
              xScale={xScale}
              yScale={yScalePercentage}
              width={xMax}
              height={yMax}
              xAccessor={pathComponentXAccessor}
              yAccessor={pathComponentYAccessor} />
          </Group>
        )
      })}

      <Group>
        { legendConfigurations.map( ({ label, color, x }) => {
          const rectSize = 16
          const offsetY = yMax + legendVerticalOffset
          const offsetYCentered = yMax + legendVerticalOffset + (rectSize / 2)
          return (
            <Group key={label}>
              <rect
                x={x}
                y={offsetY}
                width={rectSize}
                height={rectSize}
                fill={color} />
              <text
                x={x + rectSize + 5}
                y={offsetYCentered}
                fontSize={legendFontSize}
                dominantBaseline='central'>
                { label }
              </text>
            </Group>
          )
        }, )}
      </Group>

    </svg>
  )
}
