import React, { Fragment, useEffect, useRef, useState } from 'react'

import { ParentSize } from '@visx/responsive'

import { makeAppStyles } from '@percept/mui'

import { randomChoice } from '@percept/lorem'

import { range } from 'lodash-es'

import { AnimationSurfaceProps } from './typings'


const NUM_SQUARES = 6
const SQUARE_MARGIN = 4
const HALF_SQUARE_MARGIN = SQUARE_MARGIN / 2

const COLOR_TRANSITION_DURATION = 800

const TICK_FREQUENCY = 1000


type ColorClassName = 'darkRed' | 'red' | 'lightGrey' | 'grey' | 'transparent' | 'darkGrey' | 'crimson'

const useStyles = makeAppStyles<{}, ColorClassName | 'root' | 'item'>({
  root: {
    width: '100%',
    height: '100%',
  },
  item: {
    display: 'inline-block',
    height: `calc(${100 / NUM_SQUARES}% - ${SQUARE_MARGIN + HALF_SQUARE_MARGIN}px)`,
    width: `calc(${100 / NUM_SQUARES}% - ${SQUARE_MARGIN + HALF_SQUARE_MARGIN}px)`,
    margin: `0 ${SQUARE_MARGIN}px 0px ${HALF_SQUARE_MARGIN}px`,
    transition: `all ${COLOR_TRANSITION_DURATION}ms ease-in`,
    backgroundColor: 'rgba(255,255,255,0)',
  },
  darkRed: {
    backgroundColor: '#6B0202',
  },
  red: {
    backgroundColor: '#E60000',
    boxShadow: 'inset 4px 4px 4px rgba(255, 255, 255, 0.5), inset -4px -4px 4px rgba(0, 0, 0, .4)',
  },
  crimson: {
    backgroundColor: '#3F0000',
  },
  lightGrey: {
    backgroundColor: '#C3C1C1',
  },
  grey: {
    backgroundColor: '#888888',
  },
  darkGrey: {
    backgroundColor: '#1A1A1A',
  },
  transparent: {
    backgroundColor: 'rgba(255,255,255,0)',
  },
})


const variantColorOptions: Record<'light' | 'dark', ColorClassName[]> = {
  light: ['darkRed', 'grey', 'lightGrey', 'red', 'transparent'],
  dark: ['crimson', 'darkGrey', 'darkRed', 'red', 'transparent'],
}

const generateSquareClassNames = (
  totalSquares: number,
  variant: 'light' | 'dark' = 'light',
): ColorClassName[][] => {
  return range(totalSquares).map( () => (
    range(totalSquares).map( () => randomChoice(variantColorOptions[variant]))
  ))
}


const AnimationSurface = ({ variant, width, height }: AnimationSurfaceProps & {
  variant?: 'light' | 'dark'
}): JSX.Element => {

  const [squareClassNames, setSquareClassNames] = useState(() => generateSquareClassNames(NUM_SQUARES, variant))

  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if( intervalRef.current ){
      clearInterval(intervalRef.current)
    }
    intervalRef.current = setInterval(
      () => setSquareClassNames(generateSquareClassNames(NUM_SQUARES, variant)),
      TICK_FREQUENCY,
    )
    return (): void => {
      if( intervalRef.current ){
        clearInterval(intervalRef.current)
      }
    }
  }, [variant])

  const classes = useStyles()

  const size = Math.min(width, height)

  return (
    <div className={classes.root} style={{width: size, height: size}}>
      { squareClassNames.map( (row, i) => (
        <Fragment
          key={`row-${i}`}>
          { row.map( (className, j) => (
            <div
              key={`row-${i}-item-${j}`}
              className={`${classes.item} ${classes[className]}`} />
          ))}
        </Fragment>
      ))}
    </div>
  )
}

export const AnimationSquares = ({ variant }: { variant?: 'light' | 'dark' }): JSX.Element => {
  return (
    <ParentSize
      parentSizeStyles={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
      }}
      debounceTime={100}>
      { (props): JSX.Element => <AnimationSurface variant={variant} {...props} /> }
    </ParentSize>
  )
}
