import axios from 'axios'

const { VODAFONE_CUSTOM_API_ROOT } = process.env

export const brandGrowthPlannerApi = axios.create({
  baseURL: `${VODAFONE_CUSTOM_API_ROOT}/bgp/v1`,
})

brandGrowthPlannerApi.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${sessionStorage.getItem('PERCEPT_TOKEN')}`
  return config
},
(err) => Promise.reject(err)
)

export default brandGrowthPlannerApi
