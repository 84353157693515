import { user } from '@percept/redux/bundles'

import { makeSelectorHook } from '@percept/hooks/libv2'

import { MFADeliveryMedium } from '@percept/types'


export type MFAComponentProps = {
  activeDeliveryMedium: MFADeliveryMedium | null
  accessToken: string
  username: string
  onClose: () => void
}


export const useUserId = makeSelectorHook(user.selectors.getUserId)

export const useAccessToken = makeSelectorHook(user.selectors.getUserAccessToken)
