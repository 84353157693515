import {
  ApiResponse,
  ApiStatusResponse,
  PerformanceInventory,
  PlatformUnit,
  PlatformUnitParams,
  PlatformUnitProviderInfo,
  ReportSeries,
} from '@percept/types'

import { createSelector } from 'reselect'

import { get } from 'lodash-es'

import { Selector } from 'react-redux'

import { getPlatformUnitKey } from '../../utils'

import { paginationResponseResolver } from '../../selectors'

import { PaginatedReducerState } from '../../reducers'

import { apiInitialState } from '../../constants'

import { PlatformUnitState } from './typings'


type StoreWithPlatformUnitState = {
  platformUnits: PlatformUnitState
}

type PlatformUnitSelector<TProps, TOwnProps = null> = Selector<StoreWithPlatformUnitState, TProps, TOwnProps>


export const getPlatformUnitsPaginationState: PlatformUnitSelector<
  PaginatedReducerState<PlatformUnit[]>
> = (state) => (
  state.platformUnits.paginatedListing
)

export const getPaginatedPlatformUnits = createSelector(
  getPlatformUnitsPaginationState,
  paginationResponseResolver,
)


export const getPlatformUnit: PlatformUnitSelector<
  ApiResponse<PlatformUnit>, PlatformUnitParams
> = (state, params) => (
  get(state.platformUnits.byId, [getPlatformUnitKey(params) || '', 'summary'], apiInitialState)
)

export const getAddPlatformUnit: PlatformUnitSelector<
  ApiStatusResponse<PlatformUnit>
> = state => (
  state.platformUnits.add
)

export const getAddPlatformUnitPerformanceReportingProvider: PlatformUnitSelector<
  ApiStatusResponse<PerformanceInventory>
> = state => (
  state.platformUnits.addPerformanceReportingProvider
)

export const getAddPlatformUnitStructuralReportSeries: PlatformUnitSelector<
  ApiStatusResponse<ReportSeries>
> = state => (
  state.platformUnits.addStructuralReportSeries
)

export const getEditPlatformUnit: PlatformUnitSelector<
  ApiStatusResponse<PlatformUnit>
> = state => (
  state.platformUnits.edit
)

export const getDeletePlatformUnit: PlatformUnitSelector<
  ApiStatusResponse<PlatformUnit>
> = state => (
  state.platformUnits.delete
)

export const getPlatformUnitTree: PlatformUnitSelector<
  ApiResponse<PlatformUnit>, PlatformUnitParams
> = (state, params) => (
  get(state.platformUnits.byId, [getPlatformUnitKey(params) || '', 'detail'], apiInitialState)
)

export const getPlatformUnitProviderInfo: PlatformUnitSelector<
  ApiResponse<PlatformUnitProviderInfo>, PlatformUnitParams
> = (state, params) => (
  get(state.platformUnits.providerInfoById, getPlatformUnitKey(params) || '', apiInitialState)
)
