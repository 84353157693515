
import { Dictionary, TipSettings } from '@percept/types'

import { StoreState } from 'types'


export default (state: Partial<StoreState>): Dictionary => {
  
  const { tips } = state.settings || {}

  // Merge new tip settings if any are saved
  if( tips ){
    return {
      ...state,
      settings: {
        ...state.settings,
        tips: {
          ...tips,
          byLabel: {
            metricDetail: true,
            reportSelector: true,
            accountFilter: true,
            metricDimension: true,
            metricExamples: true,
            priorityMetrics: true,
            // Always merge the stored tip settings last, so we don't overwrite
            // saved state for users who have already seen and dismissed newer tips
            ...tips.byLabel,
          }
        } as TipSettings
      }
    }
  }

  return state
}
