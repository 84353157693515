import React, { Fragment, useEffect, useMemo, useState } from 'react'

import { Container, Draggable } from 'react-smooth-dnd'

import { PerformanceTracker, Tracker } from './PerformanceTracker'

import { Box, makeAppStyles, RoundedPlainTextButton, Typography } from '@percept/mui'

import { Check, Edit } from '@percept/mui/icons'

import { arrayMove, isInvertedSentiment } from '@percept/utils'

import { dimensionMap } from '@percept/constants'

import { AnyPerformanceDimension } from '@percept/types'

import { DisplayDataset, PerformanceDatasets } from 'types'


type PerformanceTrackerOrder = (keyof PerformanceDatasets)[]


export type PerformanceTrackersProps = {
  datasets: DisplayDataset<{ color: string }>[]
  currency: string
  availableDimensions: AnyPerformanceDimension[]
  referenceDate?: Date | null
  order?: PerformanceTrackerOrder
}


const useStyles = makeAppStyles( theme => ({
  tracker: {
    marginBottom: theme.spacing(3),
  },
  draggableItem: {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
  editTrigger: {
    marginLeft: theme.spacing(3),
    padding: theme.spacing(0, 1),
  },
  editTriggerStartIcon: {
    fontSize: '1.1em !important',
    margin: theme.spacing(0.25),
  },
  dragHandle: {
    marginRight: theme.spacing(1),
  },
}) )


export const PerformanceTrackers = ({
  datasets,
  availableDimensions,
  currency,
  referenceDate,
}: PerformanceTrackersProps): JSX.Element => {

  const [currentOrder, setOrder] = useState(availableDimensions)

  const [isEditingOrder, setIsEditingOrder] = useState(false)

  useEffect(() => {
    if( availableDimensions.length !== currentOrder.length ){
      setOrder(availableDimensions)
    }
  }, [availableDimensions, currentOrder])

  const trackers: Tracker[] = useMemo(() => {
    return currentOrder.map( dimension => ({
      key: dimension,
      dimension,
      label: (
        dimensionMap[dimension]
        && dimensionMap[dimension].text
        || dimension
      ),
      datasets: datasets.map( d => ({
        key: d.key,
        dimension,
        label: d.label,
        color: d.color,
        dataset: d.datasets[dimension],
      }) ),
    }) )
  }, [datasets, currentOrder])

  const classes = useStyles()

  return (
    <Fragment>

      <Box display='flex' alignItems='center' mb={3}>

        <Typography variant='h5'>Performance Trackers</Typography>

        <RoundedPlainTextButton
          classes={{
            root: classes.editTrigger,
            iconSizeSmall: classes.editTriggerStartIcon,
          }}
          color={isEditingOrder ? 'default' : 'primary'}
          variant='contained'
          size='small'
          startIcon={
            isEditingOrder ?
              <Check className={classes.editTriggerStartIcon} /> :
              <Edit className={classes.editTriggerStartIcon} />
          }
          onClick={(): void => setIsEditingOrder( prev => !prev )}>
          { isEditingOrder ? 'Finish Editing' : 'Edit Order' }
        </RoundedPlainTextButton>

      </Box>

      <Container
        getChildPayload={(index): Tracker => trackers[index]}
        lockAxis='y'
        onDrop={({ removedIndex, addedIndex }): void => {
          if( removedIndex !== null && addedIndex !== null ){
            const copy = currentOrder.slice()
            arrayMove(copy, removedIndex, addedIndex)
            setOrder(copy)
          }
        }}
        dragHandleSelector='.tracker-drag-handle'>

        { trackers.map( ({ key, label, dimension, datasets }) => {

          return (
            <Draggable
              key={key}>
              <PerformanceTracker
                className={
                  classes.tracker + (
                    isEditingOrder ?
                      ` tracker-drag-handle ${classes.draggableItem}` :
                      ''
                  )
                }
                currency={currency}
                referenceDate={referenceDate}
                dimension={dimension}
                invertSentiment={isInvertedSentiment(dimension)}
                isEditing={isEditingOrder}
                key={key}
                header={label}
                datasets={datasets} />
            </Draggable>
          )
        })}

      </Container>

    </Fragment>
  )
}
