
import {
  SET_DISPLAY_TYPE,
  SET_METRIC_ORDER,
} from '../actions'

import { Reducer } from '@percept/types'

import { ClientReportState } from '../typings'


const initialState: ClientReportState['settings'] = {
  displayType: 'SUMMARY',
  metricOrder: 'default',
}


const settings: Reducer<ClientReportState['settings']> = (state = initialState, action) => {
  switch(action.type){

    case SET_DISPLAY_TYPE:
      return { ...state, displayType: action.payload }

    case SET_METRIC_ORDER:
      return { ...state, metricOrder: action.payload }

    default:
      return state

  }
}

export default settings
