import { orderBy } from 'lodash-es'
import { Summary } from '../DashboardLayout'
import { ListItem, NestedItem } from '../type'
import { SortConfiguration } from './ReportTable'

export const sortTable = (
  data: ListItem[],
  sortKey: SortConfiguration['key'],
  sortOrder: SortConfiguration['order'] = 'ASC',
  dimensions: string[]
): ListItem[] => {
  const key =
    dimensions.indexOf(sortKey) !== -1
      ? (e: ListItem | NestedItem): number | null => {
        const value = e.costs.find((e) => e.type_value === sortKey)
        return value ? +value.spend : null
      }
      : sortKey === 'total'
        ? (e: ListItem | NestedItem): number | null => {
          return +e.total
        }
        : [sortKey]

  const order = [sortOrder.toLocaleLowerCase() as 'asc' | 'desc']
  const sortedData = orderBy(data, key, order)

  const filteredData = sortedData[0].data
    ? sortedData.map((m) => {
      if (!m.data) {
        return m
      }
      
      const members = orderBy(m.data, key, order)

      return {
        ...m,
        data: members,
      }
    })
    : sortedData

  const grandTotal = filteredData.find((e) => e.row_group === Summary.AllTotal)
  if (grandTotal) {
    return [
      ...filteredData.filter((e) => e.row_group != Summary.AllTotal),
      grandTotal,
    ]
  }
  return filteredData
}
