import { addDays, addMonths, addWeeks, isFirstDayOfMonth, startOfMonth, subDays, subMonths } from 'date-fns'

import { randomNumberLike } from './maths'

import { DatumType } from '@percept/types'


export const TODAY = new Date()

export const YESTERDAY = subDays(TODAY, 1)

export const LATEST_FIRST_OF_MONTH = (
  isFirstDayOfMonth(TODAY) ?
    TODAY : 
    startOfMonth(subMonths(TODAY, 1))
)


export type DateRangeOptions = {
  start: Date | string | number
  end: Date | string | number
  granularity: 'DAILY' | 'WEEKLY' | 'MONTHLY'

}

export type TimeSeriesDataOptions = DateRangeOptions & {
  seed?: number | null
}


export const generateTimeseriesDates = ({
  start,
  end,
  granularity,
}: DateRangeOptions): [Date, Date][] => {
  const referenceDate = new Date(start)
  const limit = new Date(end)

  const adder = (
    granularity === 'DAILY' ?
      addDays :
      granularity === 'WEEKLY' ?
        addWeeks :
        addMonths
  )

  let current = referenceDate

  const dates: [Date, Date][] = []

  while( +current < +limit ){
    const next = adder(current, 1)
    dates.push([current, subDays(next, 1)])
    current = next
  }

  return dates
}


export const generateTimeSeries = ({
  start, end, granularity = 'DAILY', seed = 50000
}: TimeSeriesDataOptions): DatumType[] => {
  const dates = generateTimeseriesDates({ start, end, granularity })
  return dates.map( ([start]) => ({
    label: start.getTime(),
    value: seed === null ? null : randomNumberLike(seed),
  }))
}
