import React from 'react'

import { Box, makeAppStyles } from '@percept/mui'

import { FallbackProps } from '@percept/app-components'

import { DefaultMediaWizardLayout } from './DefaultMediaWizardLayout'
import { useNavigation } from '@percept/hooks'


const useStyles = makeAppStyles( theme => ({
  link: {
    cursor: 'pointer',
    color: 'inherit',
    textDecoration: 'underline solid',
    textUnderlineOffset: '5px',
    marginLeft: '0.25em',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: `underline solid ${theme.palette.primary.main}`,
    },
    transition: theme.transitions.create(['color', 'text-decoration']),
  },
}))


export const ErrorScreen = ({ resetError }: FallbackProps): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigation()
  return (
    <DefaultMediaWizardLayout>
      <Box display='flex' flexDirection='column' alignItems='center' padding={10}>
        <Box mb={10} fontSize={52} fontWeight={700}>
          Something went wrong.
        </Box>
        <Box fontSize={24} mb={6}>
          We&apos;ve made a note of it and we&apos;ll try and get it fixed quickly.
        </Box>
        <Box fontSize={24}>
          In the meantime, please try reloading or going
          <span
            onClick={(): void => {
              navigate('/wizard/home')
              resetError()
            }}
            className={classes.link}>back to the home screen</span>
        </Box>
      </Box>
    </DefaultMediaWizardLayout>
  )
}
