
import React from 'react'

import { Box, BoxProps } from '@material-ui/core'

import { LogoComponentProps } from './typings'


export type LogoProps = LogoComponentProps & BoxProps & {
  fullViewBox: string
  fullAspectRatio: number
  shortViewBox?: string
  shortAspectRatio?: number
  paths: JSX.Element
}


const defaultBoxProps: Partial<BoxProps> = {
  display: 'inline-flex',
  alignItems: 'center',
  overflow: 'hidden',
}

const centeredBoxProps: Partial<BoxProps> = {
  display: 'flex',
  alignItems: 'center',

}

export const Logo = ({ 
  size = 2,
  units = 'em',
  centered = false,
  logoClassName = '',
  color = 'currentColor',
  opacity = 1,
  short,
  paths,
  fullViewBox,
  fullAspectRatio,
  shortViewBox,
  shortAspectRatio,
  ...props
}: LogoProps): JSX.Element => {

  shortViewBox = shortViewBox || fullViewBox
  shortAspectRatio = shortAspectRatio || fullAspectRatio

  const widthMultiplier = short ? shortAspectRatio : fullAspectRatio,
        viewBox = short ? shortViewBox : fullViewBox,
        height = `${size}${units}`,
        width = `${size * widthMultiplier}${units}`

  return (
    <Box
      {...defaultBoxProps}
      {...(centered && centeredBoxProps || {})}
      {...props}>

      <svg
        fill={color}
        opacity={opacity}
        height={height}
        width={width}
        className={logoClassName}>

        <svg viewBox={viewBox}>
          { paths }
        </svg>
      </svg>

    </Box>
  )
}
