
import { actionCreator } from '@percept/redux'

import {
  ReduxAction,
  Notification,
  Toast
} from '@percept/types'


export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'
export const REMOVE_ALL_NOTIFICATIONS = 'REMOVE_ALL_NOTIFICATIONS'


export type AddNotificationParams = (
  Pick<
    Notification, 'message'
  > &
  Partial<
    Notification
  >
)

export const addNotification = (function(): ((params: AddNotificationParams) => ReduxAction) {

  let id = 0

  return (params: AddNotificationParams): ReduxAction => ({
    type: ADD_NOTIFICATION,
    payload: {
      id: ++id,
      ttl: 2500,
      type: 'info',
      ...params,
    } as Notification
  })

})()

export const removeNotification = actionCreator(REMOVE_NOTIFICATION)

export const removeAllNotifications = actionCreator(REMOVE_ALL_NOTIFICATIONS)


/* Toasts */

export const ADD_TOAST = 'ADD_TOAST'
export const REMOVE_TOAST = 'REMOVE_TOAST'
export const REMOVE_ALL_TOASTS = 'REMOVE_ALL_TOASTS'


export type AddToastParams = (
  Pick<
    Toast, 'message'
  > &
  Partial<
    Toast
  >
)

export const addToast = (function(): ((params: AddToastParams) => ReduxAction) {

  let id = 0

  return (params: AddToastParams): ReduxAction => ({
    type: ADD_TOAST,
    payload: {
      id: ++id,
      ttl: 2500,
      type: 'info',
      ...params,
    } as Toast
  })

})()

export const removeToast = actionCreator(REMOVE_TOAST)

export const removeAllToasts = actionCreator(REMOVE_ALL_TOASTS)
