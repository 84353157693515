import { useMemo } from 'react'

import { MUIDataTableColumnOptions } from 'mui-datatables'


export type SmartTableColumn<T extends object> = {
  key: Extract<keyof T, string>
  name: string
  label?: string
  pinned?: boolean
  renderShortcut?: (
    'date' | 'relative-date' | 'copy-to-clipboard' | 'boolean' | 'action' | 'provider' |
    'currency' | 'percentage' | 'rate-based' | 'numeric' | 'bold'
  )
  filter?: boolean
  sort?: boolean
} & Partial<MUIDataTableColumnOptions>

export type CSVRow = any[]


const objectsToRows = <T extends object>(items: T[], columns: SmartTableColumn<T>[]): CSVRow[] => {
  return items.map( item => (
    columns.map( column => (
      item[column.key]
    ))
  ))
}


export function useRows<T extends object>(items: T[], columns: SmartTableColumn<T>[]): CSVRow[] {
  return useMemo(() => {
    return objectsToRows(items, columns)
  }, [items, columns])
}
