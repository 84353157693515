import { FormControl, MenuItem, Select } from '@material-ui/core'
import { makeAppStyles } from '@percept/mui'
import React, { ReactNode } from 'react'

type Props = {
  options: {name: string,value: string}[]
  errrRow: boolean
  handleChange: (
    e: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
    name: ReactNode
  ) => void
  selected: string
  disabled: boolean
}

const useStyles = makeAppStyles(() => ({
  formControl: {
    width: '190px',
    padding: '10px 0',
    marginRight: '16px',
  },
  menuPaper: {
    maxHeight: 300,
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#C6CBD0',
      borderRadius: '20px',
    },
  },
}))

export const TableSelect = ({
  options,
  handleChange,
  selected,
  errrRow,
  disabled
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <FormControl
      variant='outlined'
      size='small'
      error={errrRow}
      className={classes.formControl}>
      <Select
        variant='outlined'
        value={selected}
        disabled={disabled}
        MenuProps={{ classes: { paper: classes.menuPaper } }}
        onChange={handleChange}>
        {options.map((e, idx) => (
          <MenuItem key={idx} value={e.value}>
            {e.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
