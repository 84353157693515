import React from 'react'

import { Grid, GridProps, IconButton, makeAppStyles } from '@percept/mui'

import { ChevronLeftThin, ChevronRightThin } from '@percept/mui/icons'

import MUICarousel from 'react-material-ui-carousel'

import { ChannelSummaryItem, ChannelSummaryItemProps } from './ChannelSummaryItem'

import { resolveDoubleVerifyPerformanceReportingDimensions, resolvePerformanceReportingDimensions } from 'components/Organisation/lib'

import { chunk, get } from 'lodash-es'

import { isBefore } from 'date-fns'

import { providerDoubleVerifyMap } from '@percept/constants'

import { ChannelSummaryType } from './typings'

import { PerformanceComparisonRange, PlatformUnit, PlatformUnitProviderInfo } from '@percept/types'


export type ChannelSummaryProps = (
  Pick<
    ChannelSummaryItemProps,
    'currency' | 'referenceDate' | 'setReferenceDate' | 'seriesListing' | 'onSeriesClick'
  > & {
    channelSummaries: ChannelSummaryType[]
    platformUnit: PlatformUnit
    includeDoubleVerify?: boolean
    providerReferenceDates?: PlatformUnitProviderInfo | null
    performanceComparisonRange?: PerformanceComparisonRange
    GridItemProps?: Partial<Omit<GridProps, 'item' | 'container'>>
    GridContainerProps?: Partial<Omit<GridProps, 'item' | 'container'>>
  }
)


export const ChannelSummary = ({
  channelSummaries,
  GridContainerProps = {},
  GridItemProps = {},
  platformUnit,
  includeDoubleVerify,
  providerReferenceDates,
  referenceDate,
  ...props
}: ChannelSummaryProps): JSX.Element => {

  return (
    <Grid container spacing={3} {...GridContainerProps}>
      
      { channelSummaries.map( summary => {

        const { provider } = summary

        const doubleVerifyProvider = provider && providerDoubleVerifyMap[provider]

        const doubleVerifyReferenceDate = (
          includeDoubleVerify && doubleVerifyProvider ?
            get(providerReferenceDates, [doubleVerifyProvider, 'reference_date'], null) :
            null
        )

        const showDoubleVerify = !!(
          includeDoubleVerify &&
          referenceDate &&
          doubleVerifyReferenceDate &&
          !isBefore(doubleVerifyReferenceDate, referenceDate)
        )

        const availableDimensions = resolvePerformanceReportingDimensions({
          platformUnit,
          provider,
        })

        const doubleVerifyDimensions = (
          showDoubleVerify ?
            resolveDoubleVerifyPerformanceReportingDimensions({
              platformUnit,
              provider
            }) :
            null
        )

        return (
          <Grid key={summary.provider} item container xs={12} {...GridItemProps}>
            <ChannelSummaryItem
              availableDimensions={availableDimensions}
              doubleVerifyDimensions={doubleVerifyDimensions}
              doubleVerifyReferenceDate={doubleVerifyReferenceDate}
              referenceDate={referenceDate}
              includeDoubleVerify={
                !!(includeDoubleVerify && doubleVerifyDimensions && doubleVerifyDimensions.length)
              }
              {...props}
              {...summary} />
          </Grid>
        )
      })}
      
    </Grid>
  )
}


const useStyles = makeAppStyles( theme => {
  const background = (
    theme.palette.type === 'dark' ?
      'rgba(255,255,255,0.15)' :
      'rgba(0,0,0,0.15)'
  )
  return {
    navButton: {
      top: 374,
      fontSize: 32,
      opacity: 0.75,
      background,
      transition: theme.transitions.create('opacity'),
      '&:hover': {
        background,
        opacity: 1,
      },
    },
    navButtonLeft: {
      left: 10,
    },
    navButtonRight: {
      right: 10,
    }
  }
})


export const ChannelSummaryCarousel = ({
  channelSummaries,
  GridContainerProps = {},
  GridItemProps = {},
  platformUnit,
  includeDoubleVerify,
  providerReferenceDates,
  referenceDate,
  viewBatchSize,
  ...props
}: ChannelSummaryProps & {
  viewBatchSize: number
}): JSX.Element => {

  const batches = chunk(channelSummaries, viewBatchSize)

  const classes = useStyles()

  const navButtonsInvisible = viewBatchSize === channelSummaries.length
  const navButtonsPermanent = !navButtonsInvisible && viewBatchSize < channelSummaries.length

  return (
    <MUICarousel
      autoPlay={false}
      navButtonsWrapperProps={{
        style: {
          height: '100vh',
          position: 'fixed',
        }
      }}
      NavButton={({ onClick, next }) => {
        const IconComponent = next ? ChevronRightThin : ChevronLeftThin
        const className = `${classes.navButton} ${next ? classes.navButtonRight : classes.navButtonLeft}`
        return (
          <IconButton className={className} onClick={() => onClick()}>
            <IconComponent color='inherit' fontSize='inherit' />
          </IconButton>
        )
      }}
      navButtonsAlwaysInvisible={navButtonsInvisible}
      navButtonsAlwaysVisible={navButtonsPermanent}
      indicators={false}
      animation='slide'>
      { batches.map( (batch, i) => (
        <Grid key={`batch-${i}`} container style={{paddingBottom: 24}} spacing={3} {...GridContainerProps}>
          { batch.map( summary => {

            const { provider } = summary

            const doubleVerifyProvider = provider && providerDoubleVerifyMap[provider]

            const doubleVerifyReferenceDate = (
              includeDoubleVerify && doubleVerifyProvider ?
                get(providerReferenceDates, [doubleVerifyProvider, 'reference_date'], null) :
                null
            )

            const showDoubleVerify = !!(
              includeDoubleVerify &&
              referenceDate &&
              doubleVerifyReferenceDate &&
              !isBefore(doubleVerifyReferenceDate, referenceDate)
            )

            const availableDimensions = resolvePerformanceReportingDimensions({
              platformUnit,
              provider,
            })

            const doubleVerifyDimensions = (
              showDoubleVerify ?
                resolveDoubleVerifyPerformanceReportingDimensions({
                  platformUnit,
                  provider
                }) :
                null
            )

            return (
              <Grid key={summary.provider} item container xs={12} {...GridItemProps}>

                <ChannelSummaryItem
                  key={summary.provider}
                  availableDimensions={availableDimensions}
                  doubleVerifyDimensions={doubleVerifyDimensions}
                  doubleVerifyReferenceDate={doubleVerifyReferenceDate}
                  referenceDate={referenceDate}
                  includeDoubleVerify={
                    !!(includeDoubleVerify && doubleVerifyDimensions && doubleVerifyDimensions.length)
                  }
                  {...props}
                  {...summary} />
              </Grid>
            )
          })}
        </Grid>
      ))}
    </MUICarousel>
  )
}
