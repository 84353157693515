import React, { useState, useRef } from 'react'

import { Input, InputProps } from '@material-ui/core'

import { debounce } from 'lodash-es'


type InputPayload = { value: string }

type InputEvent = React.ChangeEvent<InputPayload>

export type LazyInputProps = Omit<InputProps, 'onChange'> & {
  onChange: (e: InputEvent) => void
  delay?: number
  InputComponent?: React.FC<InputProps>
}

export const LazyInput = ({ value, onChange, delay = 50, InputComponent = Input, ...props }: LazyInputProps): JSX.Element => {

  const throttledChange = useRef<React.ChangeEventHandler<InputPayload> | null>(null)

  throttledChange.current = debounce((e: React.ChangeEvent<InputPayload>): void => {
    onChange(e)
  }, delay)

  const [localValue, setLocalValue] = useState(value)

  const handleChange = (e: React.ChangeEvent<InputPayload>): void => {
    setLocalValue(e.target.value)
    if( throttledChange.current ){
      e.persist()
      throttledChange.current(e)
    }
  }

  return (
    <InputComponent
      {...props}
      value={localValue}
      onChange={handleChange} />
  )

}

export default LazyInput
