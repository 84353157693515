import { PerformanceValue } from '@percept/types'


export type ComparisonMethod = 'ABSOLUTE' | 'RELATIVE'

export type ComparisonFunction = (comparator: PerformanceValue, benchmark: PerformanceValue) => PerformanceValue


export const getPercentageDelta: ComparisonFunction = (comparator, benchmark) => {
  if( benchmark === null || comparator === null ){
    return null
  }
  return (comparator - benchmark) / benchmark * 100
}

export const getAbsoluteDelta: ComparisonFunction = (comparator, benchmark) => {
  if( benchmark === null || comparator === null ){
    return null
  }
  return comparator - benchmark
}

export const getComparisonFunction = (comparisonMethod: ComparisonMethod): ComparisonFunction => (
  comparisonMethod === 'ABSOLUTE' ? getAbsoluteDelta : getPercentageDelta
)
