
import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'

import { ReduxBundle } from '../lib'

import { PlatformUnitState } from './typings'


type PlatformUnitActions = typeof actions
type PlatformUnitSelectors = typeof selectors


export type PlatformUnitBundle = ReduxBundle<PlatformUnitState, PlatformUnitActions, PlatformUnitSelectors>


export { actions, reducer, selectors }


export const makeBundle = (): PlatformUnitBundle => ({
  actions,
  reducer,
  selectors
})

export default { actions, reducer, selectors } as PlatformUnitBundle
