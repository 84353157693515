
import React, { Fragment, useState, useRef } from 'react'

import { Popover, Tooltip, Typography, PopoverProps } from '@material-ui/core'

import { makeAppStyles } from '../../themes'

import { FileCopy } from '../../icons'

import { copyToClipboard } from '@percept/utils'


const useStyles = makeAppStyles( theme => ({
  tooltip: {
    marginTop: -10,
    zIndex: theme.zIndex.tooltip,
    pointerEvents: 'none',
  },
  copyable: {
    cursor: 'pointer',
    display: 'inline',
    alignItems: 'center',
  },
  icon: {
    fontSize: '1em',
    marginRight: theme.spacing(0.5),
    verticalAlign: 'middle',
    position: 'relative',
    top: -2,
  },
  paperSuccess: {
    background: theme.palette.success.dark,
    padding: theme.spacing(1),
  },
  paperError: {
    background: theme.palette.error.dark,
    padding: theme.spacing(1),
  },
}) )


const popoverPositioningProps: Partial<PopoverProps> = {
  anchorOrigin: {
    horizontal: 'center',
    vertical: 'bottom',
  },
  transformOrigin: {
    horizontal: 'center',
    vertical: 'top',
  }
}


type CopyToClipboardProps = React.HTMLAttributes<HTMLSpanElement> & {
  value: string | number
  content?: string | React.ReactNode
}

export const CopyToClipboard = ({ value, content, className = '', ...props }: CopyToClipboardProps): JSX.Element => {

  const [status, setStatus] = useState<'success' | 'error' | null>(null)

  const triggerRef = useRef<HTMLSpanElement | null>(null)

  const classes = useStyles()

  const handleSuccess = (): void => {
    setStatus('success')
    setTimeout(() => {
      try{
        setStatus(null)
      }catch(e){
        return false
      }
    }, 750)
  }

  const handleError = (): void => {
    setStatus('error')
    setTimeout(() => {
      try{
        setStatus(null)
      }catch(e){
        return false
      }
    }, 1500)
  }

  const handleCopy = (e: React.MouseEvent): void => {
    e.preventDefault()
    e.stopPropagation()
    navigator.permissions.query({ name: 'clipboard-write' as PermissionName }).then( result => {
      if( result.state === 'granted' ){
        navigator.clipboard.writeText(String(value)).then(handleSuccess).catch(handleError)
      }else{
        handleError()
      }
    }).catch(() => {
      copyToClipboard(String(value))
      handleSuccess()
    })
  }

  return (
    <Fragment>

      <Tooltip
        title='Click to copy'
        PopperProps={{
          className: classes.tooltip,
        }}
      >
        <span
          {...props}
          ref={triggerRef}
          className={`${classes.copyable} ${className}`}
          onClick={handleCopy}>

          <FileCopy
            className={classes.icon} />

          { content || value }

        </span>
      </Tooltip>

      <Popover
        classes={{
          paper: status === 'error' ? classes.paperError : classes.paperSuccess,
        }}
        open={!!status}
        anchorEl={triggerRef.current}
        {...popoverPositioningProps}>

        <Typography
          variant='subtitle1'>
          { status === 'error' ? 'Clipboard could not be accessed' : 'Copied to clipboard' }
        </Typography>

      </Popover>

    </Fragment>
  )
}
