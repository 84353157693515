import React from 'react'

import {
  Box,
  BoxProps,
  ButtonProps,
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  MenuAbstractionProps,
  MenuOption,
  RoundedPlainTextButtonMenu,
  Tab,
  Tabs,
  makeAppStyles,
} from '@percept/mui'

import { ArrowDropDown } from '@percept/mui/icons'

import { WastageSegmentation, WastageVariant } from '@percept/hooks'


const tabOptions: MenuOption<WastageVariant>[] = [
  {
    value: 'COMBINED',
    label: 'Total Wastage',
  },
  {
    value: 'DIGITAL',
    label: 'Digital Wastage',
  },
  {
    value: 'CREATIVE',
    label: 'Creative Wastage',
  }
]

const useTabStyles = makeAppStyles( theme => ({
  tabs: {
    margin: theme.spacing(4,0),
    borderBottom: `1px solid ${theme.palette.text.hint}`,
  },
  tab: {
    textTransform: 'none',
  },
}))


type BasicSelectProps<T> = {
  value: T
  onChange: (value: T) => void
}


export const WastageTrendTabs = ({
  value,
  onChange,
}: BasicSelectProps<WastageVariant>): JSX.Element => {
  const classes = useTabStyles()
  return (
    <Tabs
      indicatorColor='primary'
      textColor='primary'
      variant='fullWidth'
      className={classes.tabs}
      value={value}
      onChange={(_, value: WastageVariant): void => {
        onChange(value)
      }}>
      {tabOptions.map( tab => (
        <Tab key={`tab-${tab.value}`} className={classes.tab} {...tab} />
      ))}
    </Tabs>
  )
}


const wastageSegmentationDisplayLabels: Record<WastageSegmentation, string> = {
  PROVIDER: 'Vendor',
  ORG_UNIT: 'Market',
  FINANCIAL_YEAR: 'Financial Year',
  CALENDAR_YEAR: 'Calendar Year',
  MONTH: 'Month',
  QUARTER: 'Quarter',
}

const wastageSegmentationValueOrder: WastageSegmentation[] = [
  'PROVIDER',
  'ORG_UNIT',
  'FINANCIAL_YEAR',
  'CALENDAR_YEAR',
  'QUARTER',
  'MONTH',
]

const segmentationOptions: MenuOption<WastageSegmentation>[] = (
  wastageSegmentationValueOrder.map( value => ({
    value,
    label: wastageSegmentationDisplayLabels[value],
  }))
)

export const WastageSegmentationSelect = ({
  value,
  onChange,
  TriggerProps,
  ...props
}: (
  BasicSelectProps<WastageSegmentation>
  & Partial<Omit<MenuAbstractionProps<ButtonProps, WastageSegmentation>, 'onChange' | 'value' | 'children'>>
)): JSX.Element => {
  return (
    <RoundedPlainTextButtonMenu
      TriggerProps={{
        variant: 'contained',
        color: 'secondary',
        endIcon: <ArrowDropDown />,
        ...TriggerProps,
      }}
      value={value}
      label={wastageSegmentationDisplayLabels[value]}
      options={segmentationOptions}
      onChange={(e, value: WastageSegmentation): void => {
        onChange(value)
      }}
      {...props} />
  )
}


export type ControlBoxProps = React.PropsWithChildren<
  {
    label: React.ReactNode
  } & Omit<BoxProps, 'label'>
>

export const ControlBox = ({
  label,
  children,
  ...props
}: ControlBoxProps): JSX.Element => {
  // <Box my={3}>
  //   <Typography variant='h4'>Filter</Typography>

  //   <Box mt={2} display='flex' alignItems='center'>

  //   </Box>
  // </Box>
  return (
    <Box
      display='flex'
      alignItems='center'
      {...props}>
      { label }
      { React.Children.map(
        children, (el, i) => (
          <Box
            key={i}
            ml={2}>
            { el }
          </Box>
        )
      )}
    </Box>
  )
}

export type StateHookCheckboxProps = (
  Pick<FormControlLabelProps, 'label'> & {
    hookValue: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  }
)

export const StateHookCheckbox = ({
  label,
  hookValue,
}: StateHookCheckboxProps): JSX.Element => {
  const [checked, setValue] = hookValue

  return (
    <FormControlLabel
      label={label}
      control={<Checkbox />}
      checked={checked}
      onChange={(e, value): void => setValue(value)} />
  )
}
