import { combineReducers, Reducer } from 'redux'

import { createApiReducer, createKeyedReducer } from '../../reducers'

import {
  LOAD_AD_NETWORK_PERFORMANCE_TIMESERIES,
  LOAD_CHANNEL_PERFORMANCE_TIMESERIES,
  LOAD_PERFORMANCE_TIMESERIES_BY_PROVIDER,
  LOAD_PROVIDER_PERFORMANCE_TIMESERIES,
  LOAD_DOUBLE_VERIFY_PROVIDER_PERFORMANCE_TIMESERIES,
  LOAD_PROVIDER_PERFORMANCE_COMPARISONS,
  LOAD_DOUBLE_VERIFY_PROVIDER_PERFORMANCE_COMPARISONS,
  LOAD_PROVIDER_PERFORMANCE_TOTALS,
  LOAD_PROVIDER_PERFORMANCE_TOTALS_BY_NETWORK,
  LOAD_PROVIDER_PERFORMANCE_TOTALS_BY_SUB_NETWORK,
  LOAD_PROVIDER_PERFORMANCE_TOTALS_BY_CAMPAIGN_OBJECTIVE,
  LOAD_PROVIDER_DATA_AVAILABILITY_TIMESERIES,
  LOAD_PROVIDER_REFERENCE_DATES,
  SET_PROVIDER_REFERENCE_DATES,
  CLEAR_SET_PROVIDER_REFERENCE_DATES,
} from './actions'

import {
  getAdNetworkPerformanceTimeseriesKeyFromAction,
  getChannelPerformanceTimeseriesKeyFromAction,
  getPerformanceTimeseriesKeyFromAction,
  getPlatformUnitKeyFromAction,
  getNestedComparisonKeyFromAction,
  getNestedDoubleVerifyComparisonKeyFromAction,
  getProviderPerformanceTimeseriesKeyFromAction,
  getDoubleVerifyProviderPerformanceTimeseriesKeyFromAction,
  makePayloadAdapter,
  getNestedPerformanceTotalsKeyFromAction,
  getProviderDataAvailabilityKeyFromAction,
} from '../../utils'

import {
  adNetworkPerformanceTimeseries,
  channelPerformanceTimeseries,
  performanceTimeseriesByProvider,
  providerPerformanceTimeseries,
  doubleVerifyProviderPerformanceTimeseries,
  performanceComparisons,
  performanceTotals,
  performanceTotalsWithNetwork,
  performanceTotalsWithSubNetwork,
  performanceTotalsWithCampaignObjective,
  providerReferenceDates,
} from '@percept/api/adapters'

import { ReduxAction } from '@percept/types'

import { PerformanceReportingState } from './typings'


const totalsByProvider: Reducer<
  PerformanceReportingState['totalsByProvider'], ReduxAction
> = createKeyedReducer(
  createKeyedReducer(
    createApiReducer(LOAD_PROVIDER_PERFORMANCE_TOTALS, {
      getPayloadFromAction: makePayloadAdapter(performanceTotals)
    }),
    getNestedPerformanceTotalsKeyFromAction
  ),
  getPlatformUnitKeyFromAction
) 

const totalsByProviderAndNetwork: Reducer<
  PerformanceReportingState['totalsByProviderAndNetwork'], ReduxAction
> = createKeyedReducer(
  createKeyedReducer(
    createApiReducer(LOAD_PROVIDER_PERFORMANCE_TOTALS_BY_NETWORK, {
      getPayloadFromAction: ({ payload, meta }) => {
        return performanceTotalsWithNetwork(payload, meta && meta.provider)
      },
    }),
    getNestedPerformanceTotalsKeyFromAction
  ),
  getPlatformUnitKeyFromAction
) 

const totalsByProviderAndSubNetwork: Reducer<
  PerformanceReportingState['totalsByProviderAndSubNetwork'], ReduxAction
> = createKeyedReducer(
  createKeyedReducer(
    createApiReducer(LOAD_PROVIDER_PERFORMANCE_TOTALS_BY_SUB_NETWORK, {
      getPayloadFromAction: ({ payload, meta }) => {
        return performanceTotalsWithSubNetwork(payload, meta && meta.provider)
      },
    }),
    getNestedPerformanceTotalsKeyFromAction
  ),
  getPlatformUnitKeyFromAction
)

const totalsByProviderAndCampaignObjective: Reducer<
  PerformanceReportingState['totalsByProviderAndCampaignObjective'], ReduxAction
> = createKeyedReducer(
  createKeyedReducer(
    createApiReducer(LOAD_PROVIDER_PERFORMANCE_TOTALS_BY_CAMPAIGN_OBJECTIVE, {
      getPayloadFromAction: ({ payload, meta }) => {
        return performanceTotalsWithCampaignObjective(payload, meta && meta.provider)
      },
    }),
    getNestedPerformanceTotalsKeyFromAction
  ),
  getPlatformUnitKeyFromAction
)

const comparisonsByProvider = createKeyedReducer(
  createKeyedReducer(
    createApiReducer(LOAD_PROVIDER_PERFORMANCE_COMPARISONS, {
      getPayloadFromAction: makePayloadAdapter(performanceComparisons)
    }),
    getNestedComparisonKeyFromAction
  ),
  getPlatformUnitKeyFromAction
) as Reducer<
  PerformanceReportingState['comparisonsByProvider'], ReduxAction
>

const doubleVerifyComparisonsByProvider = createKeyedReducer(
  createKeyedReducer(
    createApiReducer(LOAD_DOUBLE_VERIFY_PROVIDER_PERFORMANCE_COMPARISONS, {
      getPayloadFromAction: makePayloadAdapter(performanceComparisons)
    }),
    getNestedDoubleVerifyComparisonKeyFromAction
  ),
  getPlatformUnitKeyFromAction
) as Reducer<
  PerformanceReportingState['doubleVerifyComparisonsByProvider'], ReduxAction
>


const timeseries: Reducer<PerformanceReportingState['timeseries'], ReduxAction> = combineReducers({
  aggregated: createKeyedReducer(
    createApiReducer(LOAD_PERFORMANCE_TIMESERIES_BY_PROVIDER, {
      getPayloadFromAction: makePayloadAdapter(performanceTimeseriesByProvider),
    }),
    getPerformanceTimeseriesKeyFromAction,
  ),
  byChannel: createKeyedReducer(
    createApiReducer(LOAD_CHANNEL_PERFORMANCE_TIMESERIES, {
      getPayloadFromAction: makePayloadAdapter(channelPerformanceTimeseries)
    }),
    getChannelPerformanceTimeseriesKeyFromAction
  ),
  byProvider: createKeyedReducer(
    createApiReducer(LOAD_PROVIDER_PERFORMANCE_TIMESERIES, {
      getPayloadFromAction: makePayloadAdapter(providerPerformanceTimeseries)
    }),
    getProviderPerformanceTimeseriesKeyFromAction
  ),
  byDoubleVerifyProvider: createKeyedReducer(
    createApiReducer(LOAD_DOUBLE_VERIFY_PROVIDER_PERFORMANCE_TIMESERIES, {
      getPayloadFromAction: makePayloadAdapter(doubleVerifyProviderPerformanceTimeseries)
    }),
    getDoubleVerifyProviderPerformanceTimeseriesKeyFromAction
  ),
  byAdNetwork: createKeyedReducer(
    createApiReducer(LOAD_AD_NETWORK_PERFORMANCE_TIMESERIES, {
      getPayloadFromAction: makePayloadAdapter(adNetworkPerformanceTimeseries)
    }),
    getAdNetworkPerformanceTimeseriesKeyFromAction
  ),
})


const availability: Reducer<PerformanceReportingState['availability'], ReduxAction> = createKeyedReducer(
  createApiReducer(
    LOAD_PROVIDER_DATA_AVAILABILITY_TIMESERIES
  ),
  getProviderDataAvailabilityKeyFromAction
)

const referenceDates = combineReducers<PerformanceReportingState['referenceDates']>({
  active: createApiReducer(LOAD_PROVIDER_REFERENCE_DATES, {
    getPayloadFromAction: makePayloadAdapter(providerReferenceDates)
  }),
  edit: createApiReducer(SET_PROVIDER_REFERENCE_DATES, {
    process: true,
    resetActionTypes: [CLEAR_SET_PROVIDER_REFERENCE_DATES],
  }),
})


export default combineReducers<PerformanceReportingState>({
  comparisonsByProvider,
  doubleVerifyComparisonsByProvider,
  totalsByProvider,
  totalsByProviderAndNetwork,
  totalsByProviderAndSubNetwork,
  totalsByProviderAndCampaignObjective,
  timeseries,
  availability,
  referenceDates,
})
