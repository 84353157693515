import React from 'react'

import { RangeSlider, RangeSliderProps } from './RangeSlider'

import { round } from 'lodash-es'


export type HealthSliderProps = Omit<RangeSliderProps, 'domain'>


const healthDomain: RangeSliderProps['domain'] = [0, 1]


const healthPercentageFormatter: RangeSliderProps['valueLabelFormat'] = (value) => {
  return `${round(value * 100, 0)}%`
}


export const HealthSlider = (props: HealthSliderProps): JSX.Element => {

  return (
    <RangeSlider
      domain={healthDomain}
      valueLabelFormat={healthPercentageFormatter}
      step={0.01}
      {...props} />
  )
}
