import React, { Fragment, useEffect, useRef } from 'react'

import { useDispatch } from 'react-redux'

import { notifications } from '@percept/redux/bundles'

import { Alert, Box, Button, Typography } from '@percept/mui'

import { PersonAdd } from '@percept/mui/icons'

import { SingleSignOn } from './SingleSignOn'

import * as Buttons from './Buttons'
import * as Inputs from './Inputs'

import { signInToast } from './FlashMessages'

import getMessage from './getMessage'

import { AuthComponentProps } from './lib'

import { WorkloadContext } from '@percept/types'


const SUPPORT_EMAIL_MAP: Record<WorkloadContext, string> = {
  PERCEPT: 'support@wearepercept.com',
  VODAFONE: 'vodafone@wearepercept.com',
}


const SUPPORT_EMAIL = (
  SUPPORT_EMAIL_MAP[process.env.WORKLOAD_CONTEXT] || SUPPORT_EMAIL_MAP.PERCEPT
)


const SignIn = ({
  loading,
  dispatch,
  inputs,
  updateInputs,
  enableSingleSignOn = false,
  onSignUp,
  error,
  attemptSignIn,
  classes,
  invitationExpired = false,
  isUnknownLogin,
  ButtonComponent,
  AuthInputProps = {},
  hideSignInHeader = false,
}: AuthComponentProps): JSX.Element => {

  const { username, password } = inputs

  const reduxDispatch = useDispatch()

  const hasNotified = useRef(false)

  useEffect(() => {
    if( signInToast && !loading && !hasNotified.current ){
      hasNotified.current = true
      reduxDispatch(notifications.actions.addToast(signInToast))
    }
  }, [loading, reduxDispatch])

  return (
    <Fragment>

      { enableSingleSignOn && (
        <Box display='flex' flexDirection='column' justifyContent='center'>
          <SingleSignOn />
          <Box
            display='flex'
            alignItems='center'
            fontSize={20}
            fontWeight={500}
            mt={3}
            mb={1}>
            <div style={{borderBottom: '1px solid', width: '100%'}} />
            <span style={{margin: '0 12px'}}>Or</span>
            <div style={{borderBottom: '1px solid', width: '100%'}} />
          </Box>
        </Box>
      )}

      { !hideSignInHeader && (
        <Typography
          className={classes.heading}
          variant='h3'>
          Sign In

          { onSignUp && (
            <Box ml='auto'>
              <Button
                size='small'
                startIcon={
                  <PersonAdd />
                }
                onClick={onSignUp}>
                Sign Up
              </Button>
            </Box>
          )}
        </Typography>
      )}

      <form onSubmit={(e): void => e.preventDefault()}>
        { invitationExpired ? (
          <Alert
            my={3}
            variant='error'
            header='Invitation Expired'
            message={
              <Box p={1} pt={2}>
                Your account invitation may have expired if it was sent more than 7 days ago.
                <br />
                <br />
                Please contact <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> to renew your account invitation
              </Box>
            } />
        ) : isUnknownLogin ? (
          <Alert
            my={3}
            variant='error'
            message='Username or password not recognised' />
        ) : !!error && (
          <Alert
            my={3}
            variant='error'
            message={getMessage(error)} />
        )}

        <Inputs.Username
          autoFocus
          {...AuthInputProps}
          disabled={invitationExpired}
          value={username || ''}
          onChange={(e): void => updateInputs({ username: e.target.value })} />

        <Inputs.Password
          BoxProps={{
            mb: 5,
          }}
          {...AuthInputProps}
          disabled={invitationExpired}
          value={password || ''}
          onChange={(e): void => updateInputs({ password: e.target.value })} />

        <Buttons.Confirm
          ButtonComponent={ButtonComponent}
          disabled={loading || !username || !password || invitationExpired}
          loading={loading}
          IconComponent={null}
          size='large'
          type='submit'
          onClick={attemptSignIn}>
          Sign In
        </Buttons.Confirm>
        
        <Box
          mt={3}>
          <ButtonComponent
            size='small'
            disabled={invitationExpired}
            onClick={(): void => dispatch({ type: 'FORGOT_PASSWORD' })}>
            Forgot Password
          </ButtonComponent>
        </Box>

      </form>

    </Fragment>
  )

}

export default SignIn
