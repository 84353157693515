import React from 'react'

import { Alert, Box, Typography } from '@percept/mui'

import * as Buttons from './Buttons'
import * as Inputs from './Inputs'

import { PasswordRequirements } from './PasswordRequirements'

import getMessage from './getMessage'

import { AuthComponentProps } from './lib'


const RequireNewPassword = ({
  inputs,
  updateInputs,
  dispatch,
  loading,
  error,
  classes,
  ButtonComponent,
  AuthInputProps = {}
}: AuthComponentProps): JSX.Element => {


  return (
    <form onSubmit={(e): void => e.preventDefault()}>

      <Typography
        className={classes.heading}
        variant='h3'>
        Change Password
      </Typography>

      { !!error && (
        <Alert
          my={3}
          variant='error'
          message={getMessage(error)} />
      )}

      <PasswordRequirements
        password={inputs.password || ''}
        my={3} />

      <Inputs.Password
        BoxProps={{
          mb: 5,
        }}
        {...AuthInputProps}
        required
        autoFocus
        placeholder='New Password'
        value={inputs.password || ''}
        onChange={(e): void => updateInputs({ password: e.target.value })} />

      <Buttons.Confirm
        ButtonComponent={ButtonComponent}
        loading={loading}
        onClick={(): void => {
          console.log('Submit change password')
        }}>
        Change
      </Buttons.Confirm>

      <Box mt={3}>
        <Buttons.BackToSignIn
          ButtonComponent={ButtonComponent}
          onClick={(): void => dispatch({ type: 'SIGN_IN' })} />
      </Box>

    </form>
  )
}

export default RequireNewPassword
