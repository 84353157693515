
import { makeStyles, withStyles, WithStyles, StyledComponentProps, Theme } from '@material-ui/core'
import { PropInjector } from '@material-ui/types'
import { Styles, WithStylesOptions, ClassNameMap, CSSProperties, CreateCSSProperties } from '@material-ui/core/styles/withStyles'

import { AppTheme } from './themeAugmentation'
import { ChannelKey } from '@percept/types'


export const generateSafariOnlyStyles = (styles: CSSProperties | CreateCSSProperties): CreateCSSProperties => ({
  '@media not all and (min-resolution:.001dpcm)': {
    '@supports (-webkit-appearance:none)': {
      ...styles
    },
  },
})


export function makeAppStyles<
  Props extends object = {},
  ClassKey extends string = string
>(
  styles: Styles<AppTheme, Props, ClassKey>,
  options?: WithStylesOptions<AppTheme>
): (
  keyof Props extends never // `makeStyles` where the passed `styles` do not depend on props
    ? (props?: any) => ClassNameMap<ClassKey> // `makeStyles` where the passed `styles` do depend on props
    : (props: Props) => ClassNameMap<ClassKey>
){
  return makeStyles(styles, options)
}

export function withAppStyles<
  ClassKey extends string,
  Options extends WithStylesOptions<AppTheme> = {},
  Props extends object = {},
>(
  styles: Styles<AppTheme, Props, ClassKey>,
  options?: Options
): PropInjector<WithStyles<ClassKey, Options['withTheme']>, StyledComponentProps<ClassKey> & Props> {
  return withStyles<ClassKey, Options, Props>(styles as Styles<Theme, Props, ClassKey>, options)
}


export const useChannelColorStyles = makeAppStyles<{}, ChannelKey>( theme => ({
  search: {
    color: theme.palette.channel.search.main,
  },
  social: {
    color: theme.palette.channel.social.main,
  },
  programmatic: {
    color: theme.palette.channel.programmatic.main,
  },
}) )


export const useChannelBackgroundStyles = makeAppStyles<{}, ChannelKey>( theme => ({
  search: {
    color: theme.palette.channel.search.contrastText,
    backgroundColor: theme.palette.channel.search.main,
    '&:hover': {
      color: theme.palette.channel.search.contrastText,
      backgroundColor: theme.palette.channel.search.main,
    },
    transition: theme.transitions.create('backgroundColor'),
  },
  social: {
    color: theme.palette.channel.social.contrastText,
    backgroundColor: theme.palette.channel.social.main,
    '&:hover': {
      color: theme.palette.channel.social.contrastText,
      backgroundColor: theme.palette.channel.social.main,
    },
    transition: theme.transitions.create('backgroundColor'),
  },
  programmatic: {
    color: theme.palette.channel.programmatic.contrastText,
    backgroundColor: theme.palette.channel.programmatic.main,
    '&:hover': {
      color: theme.palette.channel.programmatic.contrastText,
      backgroundColor: theme.palette.channel.programmatic.main,
    },
    transition: theme.transitions.create('backgroundColor'),
  },
}) )
