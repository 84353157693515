import React, { Fragment } from 'react'

import { Box, BoxProps, PerceptAppBar, PerceptAppBarProps } from '@percept/mui'

import { HomeButton } from 'components/HomeButton'

import { UserPopover } from '@percept/app-components'

import { PlatformUnitCrumbs } from 'components/PlatformUnitCrumbs'

import { PlatformUnitTabs } from 'components/PlatformUnitTabs'

import { useActiveOrgEnvironment } from 'components/Organisation'
import { PlatformDashboardsTabs } from 'components/PlatformDashboardsTabs'
import { SubnavbarTabs } from 'components/ReportingDashboard/SubnavbarTabs'

export type DefaultLayoutProps = React.PropsWithChildren<{
  AppBarProps?: Partial<PerceptAppBarProps>
  dashboardType?: string
}>

const flexProps: BoxProps = {
  display: 'flex',
  alignItems: 'center',
}

export const DefaultLayout = ({
  children,
  AppBarProps = {},
  dashboardType = 'optimisation',
}: DefaultLayoutProps): JSX.Element => {
  const activeOrgEnvironment = useActiveOrgEnvironment()
  /*
  NOTE - we used to support showing MI within the dashboard app,
  but in the new infrastructure we only support this from within
  the vodafone app. Leaving this here, but dormant, in case we
  want to reinstate for some reason.
  */
  // const reportingEnabled = (
  //   PERCEPT_ENV !== 'prod'
  //   && activeOrgEnvironment.hasExtendedMediaReporting
  // )
  const reportingEnabled = false
  const appBarInserts = [
    <Box
      key={0}
      style={{
        background: 'rgba(0, 0, 0, 0.15)',
        width: '100%',
      }}>
      {dashboardType === 'optimisation' ? (
        <PlatformUnitTabs />
      ) : (
        <SubnavbarTabs />
      )}
    </Box>,
    ...(AppBarProps.appBarInserts || []),
  ]
  const crumbs = (
    <PlatformUnitCrumbs
      {...activeOrgEnvironment}
      ButtonProps={{
        size: 'small',
      }}
      key='crumbs'
    />
  )

  return (
    <Fragment>
      <PerceptAppBar
        position='fixed'
        startIcon={
          <Box {...flexProps}>
            <HomeButton />
            { reportingEnabled ? (
              <PlatformDashboardsTabs />
            ) : (
              <Box ml={0.5} {...flexProps}>{crumbs}</Box>
            )}
          </Box>
        }
        {...AppBarProps}
        appBarInserts={appBarInserts}>
        {AppBarProps.children}
        { reportingEnabled ? (
          <Box {...flexProps} marginLeft='auto'>
            <Box mr={1.5} {...flexProps}>{crumbs}</Box>
            <UserPopover />
          </Box>
        ) : (
          <Box {...flexProps} marginLeft='auto'>
            <UserPopover />
          </Box>
        )}
      </PerceptAppBar>
      <Box pt={11}>{children}</Box>
    </Fragment>
  )
}
