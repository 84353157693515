import React from 'react'

import { Box, Dialog, DialogContent, DialogTitle, IconButton, PerceptAppBar, Typography } from '@percept/mui'
import { Close } from '@percept/mui/icons'

import { SubmitArticles } from 'components/Partnerships/DataSubmit/SubmitArticles'
import { getArticleFormData } from 'components/Partnerships/partnershipsClient'

import { newsAdminClient } from './clients'
import { getWizardArticlesPaginated } from './News'
import { WIZARD_ARTICLES_QUERY_KEY } from './constants'
import {
  PartnershipsArticleCreationParams,
  PartnershipsArticleMutationParams,
} from 'components/Partnerships/types'


export const createArticle = async (article: PartnershipsArticleCreationParams) => {
  const formData = getArticleFormData(article)
  const res = await newsAdminClient.post('/articles', formData)
  return res.data
}

const updateArticle = async (article: PartnershipsArticleMutationParams) => {
  const formData = getArticleFormData(article)
  const res = await newsAdminClient.put(`/articles/${article.id}`, formData)
  return res.data
}

const deleteArticle = async (id: string) => {
  const res = await newsAdminClient.delete(`/articles/${id}`)
  return res.data
}

const getOpenGraphData = async (article_url: string) => {
  const res = await newsAdminClient.get('/opengraph_article', { params: { article_url} })
  return res.data
}


export const WizardNewsAdmin = ({
  onClose
}: {
  onClose: () => void
}): JSX.Element => {
  return (
    <Dialog
      open
      onClose={(): void => onClose()}
      fullScreen>
      <PerceptAppBar
        color='primary'>
        <Box display='flex' alignItems='center'>
          <Box mr={3}>
            <IconButton
              color='inherit'
              onClick={(): void => onClose()}>
              <Close />
            </IconButton>
          </Box>
          <Typography variant='h4'>Media Wizard News Admin</Typography>
        </Box>
      </PerceptAppBar>
      <Box mt={6}>
        <DialogContent>
          <SubmitArticles
            queryKey={WIZARD_ARTICLES_QUERY_KEY}
            createArticle={createArticle}
            updateArticle={updateArticle}
            deleteArticle={deleteArticle}
            getOpenGraphData={getOpenGraphData}
            getArticles={getWizardArticlesPaginated}
            requirePartnerCategory={false}
            requireImage={false} />
        </DialogContent>
      </Box>
    </Dialog>
  )
}
