import { GridSpacing, useAppTheme, useMediaQuery } from '@percept/mui'

import { DocumentTitleHookProps, useDocumentTitle } from '@percept/hooks'

import { AppBranding } from '@percept/types'

import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'


const { BRANDING } = process.env


export const useBreakpoint = (): Breakpoint => {
  const appTheme = useAppTheme()
  const keys: readonly Breakpoint[] = [...appTheme.breakpoints.keys]
  return keys.reduce((output: Breakpoint, key: Breakpoint) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const matches = useMediaQuery(appTheme.breakpoints.up(key))
    return matches ? key : output
  }, 'xs')
}


export const useLayoutGridSpacing = (): GridSpacing => {
  const breakpoint = useBreakpoint()
  switch(breakpoint){
    case 'sm': return 4
    case 'md': return 2
    case 'lg': return 3
    default: return 5
  }
}


const brandingTitleMap: Record<AppBranding, string> = {
  PERCEPT: 'Percept',
  TPA: 'TPA Audit',
  BRANDTECH: 'Brandtech Media Group',
  VODAFONE: 'Vodafone Media Wizard',
}

export const useDashboardPageTitle = (props: DocumentTitleHookProps): void => {
  useDocumentTitle({
    site: brandingTitleMap[BRANDING || 'PERCEPT'],
    ...props,
  })
}
