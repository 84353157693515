
import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'

import { ReduxBundle } from '../lib'

import { AdminUsersState } from './typings'


type AdminUsersActions = typeof actions
type AdminUsersSelectors = typeof selectors


export type AdminUsersBundle = ReduxBundle<AdminUsersState, AdminUsersActions, AdminUsersSelectors>


export { actions, reducer, selectors }


export const makeBundle = (): AdminUsersBundle => ({
  actions,
  reducer,
  selectors
})

export default { actions, reducer, selectors } as AdminUsersBundle
