import React, { Fragment } from 'react'

import { Box, Card, Divider, Typography, TypographyProps, makeAppStyles } from '@percept/mui'

import { FormSection } from './formComponents'

import { sponsorshipsMoneyFormatter } from './utils'

import { SponsorshipsValueCalculation, SponsorshipsValueCalculationMetric } from './typings'

const useStyles = makeAppStyles( theme => ({
  boxContainer: {
    padding: '16px',
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  text: {
    fontSize: '18px'
  }
}))

export type ValueTextProps = {
  label: string
  value: string
} & TypographyProps

export const ValueText = ({ label, value, ...props }: ValueTextProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Box display='flex' justifyContent='space-between' my={1}>
      <Typography className={classes.text} {...props}>{label}</Typography>
      <Typography variant='subtitle1' className={classes.text} {...props}>{value}</Typography>
    </Box>
  )
}


export type ValueCalculationProps = {
  valueCalculation: SponsorshipsValueCalculation
}

const componentValueCalculationMetrics: { metric: SponsorshipsValueCalculationMetric, label: string }[] = [
  {
    metric: 'website_value',
    label: 'Website Value',
  },
  {
    metric: 'app_value',
    label: 'App Value',
  },
  {
    metric: 'ooh_value',
    label: 'OOH Value',
  },
  {
    metric: 'social_value',
    label: 'Social Value',
  },
  {
    metric: 'email_open_value',
    label: 'Email Open Value',
  },
  {
    metric: 'email_click_value',
    label: 'Email Click Value',
  },
]


export const ValueCalculation = ({ valueCalculation }: ValueCalculationProps): JSX.Element => {
  return (
    <Card elevation={2}>
      <FormSection title='Total Value Estimation' bgcolor='primary.main' color='white'>
        <Box p={2}>
          { componentValueCalculationMetrics.map( ({ metric, label }) => (
            <Fragment key={metric}>
              <ValueText
                label={label}
                value={sponsorshipsMoneyFormatter(valueCalculation[metric])} />
              <Divider />
            </Fragment>
          ))}
          <Box display='flex' justifyContent='space-between' mt={2}>
            <Typography variant='h3'>Total Annual Value</Typography>
            <Typography variant='h3'>{sponsorshipsMoneyFormatter(valueCalculation.total_value)}</Typography>
          </Box>
        </Box>
      </FormSection>
    </Card>
  )
}
