import { getPath } from '@percept/utils'

import {
  AuthStatus,
  UserIdent,
  AuthAction,
  AuthInputs,
} from './lib'

import { Dictionary, RequiresMFAResponse } from '@percept/types'


const { WORKLOAD_TYPE } = process.env


export type AuthReducerState = {
  status: AuthStatus
  inputs: AuthInputs
  error: Error | Dictionary | null
  user: UserIdent | null
  mfaChallenge: RequiresMFAResponse | null
  onboarding: boolean
  initialToken: string | null
}

export const initialState: AuthReducerState = {
  status: 'signIn',
  inputs: {},
  mfaChallenge: null,
  error: null,
  onboarding: false,
  user: null,
  initialToken: null,
}


export default (state: AuthReducerState, action: AuthAction): AuthReducerState => {

  switch(action.type){

    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        inputs: getPath(action.payload, 'code') === 'NotAuthorizedException' ?
          { ...state.inputs, password: '' } :
          state.inputs
      }

    case 'SIGN_OUT':
      return {
        ...initialState,
        status: 'signIn'
      }

    case 'USER_NOT_FOUND':
      return {
        ...state,
        status: 'signIn',
        inputs: {
          ...state.inputs,
          ...(action.payload || {}),
          password: '',
        },
        error: {
          code: 'OnboardError'
        },
        onboarding: true,
      }

    case 'CONFIRM_SIGN_IN_REQUIRED':
      return {
        ...state,
        status: 'confirmSignIn',
        mfaChallenge: action.payload,
      }

    case 'CONFIRM_SIGN_UP_REQUIRED':
      return {
        ...state,
        status: 'confirmSignUp',
      }

    case 'REQUIRE_MFA':
      return {
        ...state,
        status: 'requireMFA',
      }

    case 'PASSWORD_RESET_REQUIRED':
      return {
        ...state,
        status: 'onboard',
        inputs: {
          ...state.inputs,
          password: '',
          ...(action.payload || {}),
        },
      }

    case 'NEW_PASSWORD_REQUIRED':
      return {
        ...state,
        status: 'requireNewPassword',
        inputs: {
          ...state.inputs,
          password: '',
        }
      }

    case 'FORGOT_PASSWORD':
      return {
        ...state,
        status: 'forgotPassword',
        user: action.payload,
        inputs: {
          ...state.inputs,
          password: '',
        }
      }

    case 'CONTACT_VERIFICATION_REQUIRED':
      return {
        ...state,
        status: 'verifyContact',
        user: action.payload,
      }

    case 'SETUP_TOTP':
      return {
        ...state,
        status: 'TOTPSetup',
        user: action.payload,
      }

      // case 'SIGN_UP':
      //   return {
      //     ...state,
      //     status: 'signUp',
      //     inputs: {
      //       ...state.inputs,
      //       password: '',
      //       ...(action.payload || {}),
      //     },
      //     error: action.error || null,
      //     onboarding: true,
      //   }
    
    case 'STORE_ONBOARD_PARAMS':
      return {
        ...state,
        inputs: {
          ...state.inputs,
          username: action.payload.username,
          password: '',
        },
        initialToken: action.payload.token,
        onboarding: true,
      }

    case 'ONBOARD_SIGN_IN':
      return {
        ...state,
        status: 'signIn',
        inputs: {
          ...state.inputs,
          password: '',
          ...(action.payload || {}),
        },
        error: action.error || null,
        onboarding: true,
      }

    case 'USER_ONBOARDING':
      return {
        ...state,
        status: 'onboard',
        onboarding: true,
        inputs: {
          ...state.inputs,
          password: '',
        },
        initialToken: state.initialToken || state.inputs.password || null,
      }
      
    case 'SIGN_IN':
      return {
        ...state,
        status: 'signIn',
        inputs: {
          ...state.inputs,
          username: '',
          password: '',
          ...(action.payload || {}),
        },
        error: null,
      }

    case 'SIGN_IN_SUCCESS':
      return {
        ...state,
        inputs: {},
        status: 'signedIn',
        user: action.payload,
        error: null,
      }

    case 'UPDATE_INPUTS':
      return {
        ...state,
        inputs: {
          ...state.inputs,
          ...(action.payload || {}),
        }
      }

    default:

      if( WORKLOAD_TYPE === 'PROD' ){
        return state
      }

      throw new Error('Unexpected action type received')

  }
}