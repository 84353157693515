
import { combineReducers } from 'redux'

import {
  createApiReducer,
  createKeyedReducer,
} from '@percept/redux/reducers'

import { getSeriesKeyFromAction } from '@percept/redux/utils'

import {
  LOAD_SERIES,
  LOAD_SERIES_REPORTS,
  MUTATE_SERIES,
  CLEAR_MUTATE_SERIES,
  DELETE_SERIES,
  LOAD_SERIES_OAUTH_URL,
  LOAD_SERIES_ACCOUNTS,
  SAVE_SERIES_REFRESH_TOKEN,
  CLEAR_DELETE_SERIES,
  CLEAR_SAVE_SERIES_REFRESH_TOKEN,
  CREATE_REPORT,
  CLEAR_CREATE_REPORT,
  CREATE_INITIAL_REPORT,
  CLEAR_CREATE_INITIAL_REPORT,
} from '../actions'

import {
  series,
  seriesReports,
} from '@percept/api/adapters'

import {
  Series,
  Report,
  Reducer,
  ApiStatusResponse,
  AdwordsAccountHierarchy,
  SeriesReport,
} from '@percept/types'

import { SeriesState } from '../typings'


const reducer: Reducer<SeriesState['byId']> = createKeyedReducer(
  combineReducers({
    series: createApiReducer<Series>(LOAD_SERIES, {
      getPayloadFromAction: ({ payload }): Series => series(payload || {})
    }),
    reports: createApiReducer<SeriesReport[]>(LOAD_SERIES_REPORTS, {
      getPayloadFromAction: ({ payload }) => seriesReports(payload || [])
    }),
    mutation: createApiReducer<Series, ApiStatusResponse<Series>>(MUTATE_SERIES, { 
      process: true,
      processActionTypes: [MUTATE_SERIES, DELETE_SERIES],
      resetActionTypes: [CLEAR_MUTATE_SERIES, CLEAR_DELETE_SERIES],
    }),
    oAuthUrl: createApiReducer<string>(LOAD_SERIES_OAUTH_URL, {
      getPayloadFromAction: ({ payload }) => payload && payload.URL || ''
    }),
    accounts: createApiReducer<AdwordsAccountHierarchy>(LOAD_SERIES_ACCOUNTS),
    refreshToken: createApiReducer<string, ApiStatusResponse<string>>(SAVE_SERIES_REFRESH_TOKEN, {
      process: true,
      resetActionTypes: [CLEAR_SAVE_SERIES_REFRESH_TOKEN]
    }),
    createReport: createApiReducer<Report, ApiStatusResponse<Report>>(CREATE_REPORT, { 
      process: true,
      processActionTypes: [CREATE_REPORT, CREATE_INITIAL_REPORT],
      resetActionTypes: [CLEAR_CREATE_REPORT, CLEAR_CREATE_INITIAL_REPORT],
    })
  }),
  getSeriesKeyFromAction
)


export default reducer
