
import {
  mapValues,
  pipe,
  isArray,
  getHealthFrom,
  isDefined,
  any,
  isObject,
  sum,
  metricDataToArray,
  getPath,
  isNumber,
} from '@percept/utils'

import {
  MetricDataType,
  MetricType,
  MetricTypeEnum,
  MetricSegmentType,
  MetricDimensions,
  Dictionary,
  DatumType,
  HealthType,
} from '@percept/types'


const DEBUG = process.env.WORKLOAD_TYPE === 'DEV'


const isMetricDimensionObject = (d: any): d is Dictionary<MetricDimensions> => (
  d && isObject(d.dimensions)
)

const getDimensions = (
  metric: MetricDimensions | Dictionary<MetricDimensions>
): MetricDimensions => (
  isMetricDimensionObject(metric) ? metric.dimensions : metric
)

const getMetricTotal = (data: MetricDataType) => (
  sum(metricDataToArray(data).map( d => d.value || 0))
)


interface HealthableDatum extends DatumType {
  health: HealthType,
  score?: HealthType,
  count_health?: HealthType,
  impact_weighted_count_health?: HealthType,
}

const withHealth = (segment: HealthableDatum): MetricSegmentType => ({
  ...segment,
  health: getHealthFrom(segment)
})

const segmentsWithHealth = (segments: HealthableDatum[]) => mapValues(segments, withHealth)

const toSortedSegments = (segments: Dictionary<MetricSegmentType>): MetricSegmentType[] => (
  Object.keys(segments).sort().map( label => ({
    label,
    ...segments[label]
  }) )
)

const normalizeMetricData = pipe<MetricSegmentType[]>(segmentsWithHealth, toSortedSegments)


const distribution = (dimensionData: any): MetricType => {

  const health = getHealthFrom(dimensionData)

  let data

  if( isDefined(dimensionData.segments)  ){
    data = normalizeMetricData(dimensionData.segments)
    if( DEBUG && health === null && any(
      data,
      d => d.health !== null
    ) ){
      console.warn('Missing health', dimensionData)
    }
  }else if( dimensionData.data && dimensionData.data.length === 1 ) {
    data = withHealth(dimensionData.data[0])
  }else{
    data = (
      isArray(dimensionData.data) ?
        dimensionData.data.map(withHealth) :
        dimensionData.data
    )
  }

  return {
    metric_type: 'distribution',
    data,
    health,
    total: isNumber(dimensionData.total) ? dimensionData.total : getMetricTotal(data)
  }

}


const value = (dimensionData: any): MetricType => {

  const metricData = (
    isArray(dimensionData.data) ?
      dimensionData.data[0] :
      dimensionData.data ?
        dimensionData.data :
        dimensionData
  )

  const data = isDefined(metricData.value) ? {
    value: metricData.value,
    label: 'TOTAL',
    health: getHealthFrom(dimensionData),
    examples: metricData.examples,
  } : normalizeMetricData(metricData)

  return  {
    metric_type: 'value',
    data, 
    health: getHealthFrom(dimensionData),
    total: getMetricTotal(data),
  }

}


const proportion = (dimensionData: any): MetricType => ({
  metric_type: 'proportion',
  data: isDefined(dimensionData.enumerator) ? {
    value: dimensionData.enumerator.value,
    health: getHealthFrom(dimensionData),
    label: 'ENUMERATOR',
    examples: dimensionData.enumerator.examples,
  } : normalizeMetricData(dimensionData),
  health: getHealthFrom(dimensionData),
  total: (
    isDefined(dimensionData.denominator) ?
      dimensionData.denominator.value :
      getPath(dimensionData, 'total')
  )
})


const ratio = (d: any): MetricType => ({
  ...proportion(d),
  metric_type: 'ratio'
})


const attribute = (d: any): MetricType => {

  const value = (
    isDefined(d.value) ?
      d.value :
      (d.data && isDefined(d.data.value)) ?
        d.data.value :
        null
  )

  return {
    metric_type: 'attribute',
    data: { value },
    health: null,
    total: isNumber(value) && value || 0,
  }
}


const adaptersByType = {
  distribution,
  distribution_non_mutex: distribution,
  value,
  proportion,
  ratio,
  attribute,
}


const getMetricType = (metric: Dictionary): MetricTypeEnum => {
  if( metric.metric_type ){
    return metric.metric_type
  }
  if( metric.segments ) return 'distribution'
  if( metric.denominator ) return 'ratio'
  if( typeof metric.value !== 'undefined' ) return 'value'
  if( isArray(metric.data) ){
    if( metric.data.length > 1 ){
      return 'distribution'
    }
    if( metric.data.length === 1 ){
      if( getPath(metric.data, [0, 'label']) === 'ENUMERATOR' ){
        return 'ratio'
      }
      return 'value'
    }
  }
  return 'distribution'
}


interface MultiDimensionMetric extends Dictionary {
  metric_type: MetricTypeEnum,
  dimensions?: MetricDimensions
}

export default (metric: MultiDimensionMetric): MetricDimensions => {
  
  const metric_type = getMetricType(metric.count || metric)

  return mapValues(
    getDimensions(metric),
    adaptersByType[metric_type]
  )

}

export const singleMetric = (metric: Dictionary): MetricType => {

  const metric_type = getMetricType(metric)

  return adaptersByType[metric_type](metric)

}
