
import React, { Fragment } from 'react'

import { AnimatedGridColumns, AnimatedGridRows } from '@visx/react-spring'

import { GridColumns, GridRows } from '@visx/grid'

import { ScaleRequirements, SizeRequirements, TickConfiguration } from './typings'


export type GridsProps = (
  SizeRequirements &
  ScaleRequirements &
  TickConfiguration & {
    grids?: 'columns' | 'rows' | boolean
    animate?: boolean
    columnOffset?: number
    rowOffset?: number
    stroke?: string
  }
)


export const Grids = ({
  animate = true,
  grids = true,
  xScale,
  yScale,
  width,
  height,
  columnOffset,
  rowOffset,
  stroke,
  numXTicks,
  numYTicks,
}: GridsProps): JSX.Element => {

  const ColumnsComponent = animate ? AnimatedGridColumns : GridColumns
  const RowsComponent = animate ? AnimatedGridRows : GridRows

  return (
    <Fragment>

      { grids !== 'rows' && (
        <ColumnsComponent
          animationTrajectory='outside'
          scale={xScale}
          left={columnOffset}
          height={height}
          stroke={stroke}
          numTicks={numXTicks} />
      )}

      { grids !== 'columns' && (
        <RowsComponent
          animationTrajectory='outside'
          scale={yScale}
          width={width}
          left={rowOffset}
          stroke={stroke}
          numTicks={numYTicks} />
      )}

    </Fragment>
  )
}
