import { UseQueryResult, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import { partnershipsClient } from './partnershipsClient'

import { makeClientQueryFn } from '@percept/hooks'

import { AllMarketsYoutubeReportModel, Market, MetaReportResponse } from './types'


export const usePartnershipsMarkets = (): UseQueryResult<Market[], AxiosError> => {
  return useQuery({
    queryKey: ['partnershipsMarkets'],
    queryFn: makeClientQueryFn(
      partnershipsClient,
      {
        url: '/markets'
      }
    )
  })
}


export type PartnershipsReferenceDatesResponse = Record<
  'combined_providers' | 'meta_provider' | 'youtube_provider',
  {
    report_dates: string[]
    report_months: string[]
    report_completed_fin_quarters: string[]
  }
>

export const usePartnershipsReferenceDates = (): UseQueryResult<
  PartnershipsReferenceDatesResponse, AxiosError
> => {
  return useQuery({
    queryKey: ['partnershipsReferenceDates'],
    queryFn: makeClientQueryFn(
      partnershipsClient,
      {
        url: '/report_date'
      }
    ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}


export const usePartnershipsReferenceDatesForMarket = (market_iso_code: string): UseQueryResult<
  PartnershipsReferenceDatesResponse, AxiosError
> => {
  return useQuery({
    queryKey: ['partnershipsReferenceDatesForMarket', market_iso_code],
    queryFn: makeClientQueryFn(
      partnershipsClient,
      {
        url: `/report_date/market/${market_iso_code}`
      }
    ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}

// YouTube

export const usePartnershipsYouTubeReports = (referenceDate: string | null): UseQueryResult<
  AllMarketsYoutubeReportModel, AxiosError
> => {
  return useQuery({
    queryKey: ['youtubeAllMarkets', referenceDate],
    queryFn: makeClientQueryFn(
      partnershipsClient,
      {
        url: '/youtube_report/all_markets',
        params: { report_date: referenceDate }
      }
    ),
    enabled: !!referenceDate,
    refetchOnWindowFocus: false,
  })
}

export const usePartnershipsHistoricYouTubeReports = (referenceDate: string | null): UseQueryResult<
  AllMarketsYoutubeReportModel, AxiosError
> => {
  return useQuery({
    queryKey: ['youTubeAllMarketsHistoric', referenceDate],
    queryFn: makeClientQueryFn(
      partnershipsClient,
      {
        url: '/youtube_report_historic/all_markets',
        params: { report_date: referenceDate }
      }
    ),
    enabled: !!referenceDate,
    refetchOnWindowFocus: false,
  })
}


export const usePartnershipsYouTubeReportAverageForMarket = (
  marketIsoCode: string | null, referenceDate: string | null
): UseQueryResult<any, AxiosError> => {
  return useQuery({
    queryKey: ['youTubeReportAverage', marketIsoCode, referenceDate],
    queryFn: makeClientQueryFn(
      partnershipsClient,
      {
        url: '/youtube_report_average',
        params: { report_date: referenceDate, market_iso_code: marketIsoCode },
      }
    ),
    enabled: !!(marketIsoCode && referenceDate),
  })
}


export const usePartnershipsYouTubeReportForMarket = (
  marketIsoCode: string | null, referenceDate: string | null
): UseQueryResult<any, AxiosError> => {
  return useQuery({
    queryKey: ['youTubeReport', marketIsoCode, referenceDate],
    queryFn: makeClientQueryFn(
      partnershipsClient,
      {
        url: '/youtube_report',
        params: { report_date: referenceDate, market_iso_code: marketIsoCode },
      }
    ),
    enabled: !!(marketIsoCode && referenceDate),
  })
}


// Meta

export const usePartnershipsMetaReports = (referenceDate: string | null): UseQueryResult<
  MetaReportResponse, AxiosError
> => {
  return useQuery({
    queryKey: ['metaAllMarkets', referenceDate],
    queryFn: makeClientQueryFn(
      partnershipsClient,
      {
        url: '/meta_report/all_markets',
        params: { report_date: referenceDate }
      }
    ),
    enabled: !!referenceDate,
    refetchOnWindowFocus: false,
  })
}

export const usePartnershipsHistoricMetaReports = (referenceDate: string | null): UseQueryResult<
  MetaReportResponse, AxiosError
> => {
  return useQuery({
    queryKey: ['metaAllMarketsHistoric', referenceDate],
    queryFn: makeClientQueryFn(
      partnershipsClient,
      {
        url: '/meta_report_historic/all_markets',
        params: { report_date: referenceDate }
      }
    ),
    enabled: !!referenceDate,
    refetchOnWindowFocus: false,
  })
}


export const usePartnershipsMetaReportForMarket = (
  marketIsoCode: string | null, referenceDate: string | null
): UseQueryResult<any, AxiosError> => {
  return useQuery({
    queryKey: ['metaReport', marketIsoCode, referenceDate],
    queryFn: makeClientQueryFn(
      partnershipsClient,
      {
        url: '/meta_report',
        params: { report_date: referenceDate, market_iso_code: marketIsoCode },
      }
    ),
    enabled: !!(marketIsoCode && referenceDate),
  })
}
