import React from 'react'

import { RoundedPlainTextButtonMenu } from '@percept/mui'

import { ArrowDropDown } from '@percept/mui/icons'

import { format } from 'date-fns'


const formatMonthLabel = (date: Date): string => (
  format(date, 'MMMM yyyy')
)

const areMonthsEqual = (a: Date, b: Date): boolean => (
  formatMonthLabel(a) === formatMonthLabel(b)
)

export const MonthSelect = ({
  value,
  options,
  onChange,
}: {
  value: Date
  options: Date[]
  onChange: (value: Date) => void
}): JSX.Element => {
  return (
    <RoundedPlainTextButtonMenu
      TriggerProps={{
        variant: 'contained',
        endIcon: <ArrowDropDown />,
      }}
      value={value}
      label={formatMonthLabel(value)}
      isEqual={areMonthsEqual}
      options={
        options.map( date => ({
          value: date,
          label: formatMonthLabel(date),
        }))
      }
      onChange={(_, value: Date): void => {
        onChange(value)
      }} />
  )
}
