
import { SET_ACTIVE_SERIES_GROUP_PROVIDER } from '../../actions'

import { Reducer, SeriesGroupWrapper } from '@percept/types'


const activeProvider: Reducer<SeriesGroupWrapper['activeProvider']> = (state = 'adwords', action) => {
  if( action.type === SET_ACTIVE_SERIES_GROUP_PROVIDER ){
    return action.payload.provider
  }
  return state
}

export default activeProvider
