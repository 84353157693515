import React, { Fragment } from 'react'


import {
  Alert,
  Chip,
  Collapse,
  deriveCardStripColor,
  Divider,
  Fade,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  makeAppStyles,
  Typography,
  useAppTheme,
} from '@percept/mui'

import {
  Error as ErrorIcon,
  Info,
  Check,
  SvgIconComponent,
  OpenInNew,
  Lightbulb,
  ZoomIn,
} from '@percept/mui/icons'

import { StructuralEntityList } from './StructuralEntityList'

import { EstimatedChange } from './EstimatedChange'

import { clamp } from 'lodash-es'

import { HealthType, Insight } from '@percept/types'


export type StructuralInsightProps = (
  Insight & {
    defaultOpen?: boolean
    collapsed?: boolean
    onToggleCollapse: () => void
    toggleDetail: (e: React.MouseEvent) => void
  }
) & ListItemProps


const useStyles = makeAppStyles( theme => ({
  card: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 18,
  },
  cardContent: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    }
  },
  titleChip: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(2.75, 1),
    borderRadius: 24,
    width: '100%',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    [theme.breakpoints.down(1600)]: {
      fontSize: 16,
      padding: theme.spacing(2.5, 0.5),
    },
  },
  titleChipLabel: {
    display: 'flex',
    flexBasis: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    backgroundColor: 'rgba(0,0,0,0.25)',
    borderRadius: '1em',
    paddingRight: theme.spacing(1),
  },
  titleChipPrimary: {
    display: 'flex',
    alignItems: 'center',
  },
  titleChipEstimatedChange: {
    [theme.breakpoints.down(1600)]: {
      fontSize: 12,
    },
  },
  listItemIcon: {
    marginTop: theme.spacing(1),
  },
  sentimentIcon: {
    fontSize: 40,
  },
  description: {
    fontSize: 16,
    margin: theme.spacing(3, 1, 1, 1),
    lineHeight: 1.5,
    [theme.breakpoints.down(1600)]: {
      fontSize: 14,
    },
  },
  recommendation: {
    margin: theme.spacing(2, 0),
    '&:first-of-type': {
      marginTop: 0,
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  recommendationTitle: {
    marginBottom: theme.spacing(1),
  },
  recommendationText: {
    fontSize: 16,
    lineHeight: 1.4,
    [theme.breakpoints.down(1600)]: {
      fontSize: 14,
    },
  },
  explanationTypography: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
    lineHeight: 1.4,
    [theme.breakpoints.down(1600)]: {
      fontSize: 13,
    },
  },
  relatedMetrics: {
    marginTop: theme.spacing(3),
  },
  relatedMetric: {
    margin: theme.spacing(1, 0),
    cursor: 'pointer',
  }
}))


type DerivedSentiment = 'critical' | 'warning' | 'neutral' | 'positive'


const insightSentimentDisplayMap: Record<
  DerivedSentiment,
  {
    health: HealthType
    IconComponent: SvgIconComponent
  }
> = {
  critical: {
    health: 0,
    IconComponent: ErrorIcon,
  },
  warning: {
    health: 0.5,
    IconComponent: ErrorIcon,
  },
  neutral: {
    health: null,
    IconComponent: Info,
  },
  positive: {
    health: 1,
    IconComponent: Check,
  },
}


const getSentiment = (health: HealthType): DerivedSentiment => {
  if( health === null ) return 'neutral'
  if( health >= 1 ) return 'positive'
  if( health >= 0.5 ) return 'warning'
  return 'critical'
}


export const StructuralInsight = ({
  severity,
  explanations,
  recommendations,
  name,
  external_links,
  estimated_change,
  currency,
  toggleDetail,
  collapsed,
  onToggleCollapse,
  ...props
}: StructuralInsightProps): JSX.Element => {

  const classes = useStyles()

  const health = severity === null ? null : clamp(1 - (severity || 0), 0, 1)

  const sentiment = getSentiment(health)

  const { IconComponent } = insightSentimentDisplayMap[sentiment]


  const color = deriveCardStripColor({
    color: 'health',
    health,
    appTheme: useAppTheme(),
  })

  return (
    <ListItem
      alignItems='flex-start'
      {...props}
      button={false}>
      <ListItemIcon
        className={classes.listItemIcon}>
        <IconComponent
          className={classes.sentimentIcon}
          style={{ color }} />
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={
          <Chip
            className={classes.titleChip}
            classes={{
              label: classes.titleChipLabel
            }}
            style={{
              backgroundColor: color,
            }}
            label={
              <Fragment>
                <span className={classes.titleChipPrimary}>
                  { name }
                  <IconButton
                    onClick={toggleDetail}>
                    <ZoomIn />
                  </IconButton>
                </span>
                { estimated_change && (
                  <EstimatedChange
                    className={classes.titleChipEstimatedChange}
                    showLabel={false}
                    currency={currency}
                    {...estimated_change} />
                )}
              </Fragment>
            }
            onClick={onToggleCollapse} />
        }
        secondary={
          <Collapse in={!collapsed}>
            <Fade in={!collapsed}>
              <div>
                { recommendations && (
                  <Typography
                    className={classes.description}>
                    { recommendations.map( ({ title, text, entities }, i) => (
                      <Fragment key={`suggestion-${i}`}>
                        <div className={classes.recommendation}>
                          { title && (
                            <Typography
                              variant='h5'
                              className={classes.recommendationTitle}>
                              { title }
                            </Typography>
                          )}
                          <Typography
                            className={classes.recommendationText}
                            paragraph>
                            { text }
                          </Typography>
                          { !!(entities && entities.length) && (
                            <StructuralEntityList
                              entities={entities}
                              toggleDetail={toggleDetail} />
                          )}
                        </div>
                        { i < (recommendations.length - 1) && (
                          <Divider />
                        )}
                      </Fragment>
                    ))}
                  </Typography>
                )}
                { explanations && (
                  <Alert
                    variant='info'
                    icon={<Lightbulb />}
                    className={classes.description}>
                    { explanations.map( (text, i) => (
                      <Typography
                        key={`explanation-${i}`}
                        className={classes.explanationTypography}>
                        { text }
                      </Typography>
                    ))}
                  </Alert>
                )}

                {/* { !!(metricLinks && metricLinks.length) && (
                  <div className={classes.relatedMetrics}>
                    <Typography variant='subtitle1'>
                      Related Metrics
                    </Typography>
                    { metricLinks.map( ({ metric_id, report_id, title }) => (
                      <Link
                        key={metric_id}
                        to={`/reports/${report_id}?metric_id=${metric_id}`}>
                        <Chip
                          className={classes.relatedMetric}
                          label={title}
                          size='small'
                          icon={<OpenInNew />} />
                      </Link>
                    ))}
                  </div>
                )} */}

                { !!(external_links && external_links.length) && (
                  <div className={classes.relatedMetrics}>
                    <Typography variant='subtitle1'>
                      Related Articles
                    </Typography>
                    { external_links.map( ({ title, url }, i) => (
                      <a
                        key={`external-${i}`}
                        href={url}
                        target='_blank'
                        rel='noopener noreferrer'>
                        <Chip
                          className={classes.relatedMetric}
                          label={title}
                          size='small'
                          icon={<OpenInNew />} />
                      </a>
                    ))}
                  </div>
                )}

              </div>

            </Fade>
          </Collapse>
        } />
    </ListItem>
  )
}
