import { useMemo } from 'react'

import { AppTheme } from '@percept/mui'

import { mapValues } from 'lodash-es'

import { startOfMonth, subYears } from 'date-fns'

import {
  ChannelKey,
  PlatformUnit,
} from '@percept/types'

import {
  ChannelDataset,
  PerformanceReportingDatum,
} from 'types'

import { ChannelSummaryType } from 'components/ChannelSummary/typings'

import { Domain } from '@percept/mui/charts'



export const channels: ChannelKey[] = ['search', 'social', 'programmatic']


export type HomeChannelDatum = (
  PerformanceReportingDatum & {
    channel: ChannelKey
  }
)


export type HomeChannelDataset = {
  channel: ChannelKey
  data: PerformanceReportingDatum[]
}


export const getDefaultPerformanceReportingDomain = (): Domain => {
  const thisMonth = startOfMonth(new Date())
  const lastYear = subYears(thisMonth, 1)
  return [
    lastYear.getTime(),
    thisMonth.getTime(),
  ]
}

export const useChannelSummaries = ({
  appTheme,
  datasets
}: {
  appTheme: AppTheme,
  datasets: ChannelDataset[]
}): ChannelSummaryType[] => {
  return useMemo(() => {
    return datasets.map( ({ channel, provider, datasets }) => ({
      channel,
      provider,
      pillars: [],
      datasets: mapValues(
        datasets || {},
        (v) => {
          return {
            ...v,
            channel,
            provider,
            timeseries: v.timeseries.map( t => ({
              ...t,
              color: appTheme.palette.channel[channel].main,
            }) ),
          }
        }
      )
    }) )
  }, [appTheme, datasets])
}


export const findUnitFromTree = (platformUnits: PlatformUnit[], org_unit_id: string): PlatformUnit | null => {
  for( const unit of platformUnits ){
    if( unit.id === org_unit_id ){
      return unit
    }else if( unit.children ){
      const match = findUnitFromTree(unit.children, org_unit_id)
      if( match ){
        return match
      }
    }
  }
  return null
}
