import { get, maxBy, minBy } from 'lodash-es'

import { primaryPerformanceDataProviders } from '@percept/constants'

import {
  PerformanceDataProvider,
  PlatformUnitProviderInfo,
  PrimaryPerformanceDataProvider,
  ReferenceDateBehaviour,
} from '@percept/types'


type DeriveReferenceDateProps = {
  providerInfo: PlatformUnitProviderInfo | null
  enabledProviders: (PerformanceDataProvider)[]
  activeReferenceDate: Date | null
  referenceDateBehaviour: ReferenceDateBehaviour
}

export const deriveCanonicalReferenceDate = ({
  providerInfo,
  enabledProviders,
  activeReferenceDate,
  referenceDateBehaviour,
}: DeriveReferenceDateProps): Date | null => {

  if( referenceDateBehaviour === 'CUSTOM' ){
    return activeReferenceDate
  }

  if( !providerInfo ){
    return null
  }

  const matchingDates = enabledProviders.reduce( (acc, provider) => {
    const referenceDate = get(providerInfo[provider], 'reference_date', null)
    if( referenceDate ){
      acc.push(referenceDate)
    }
    return acc
  }, [] as Date[])

  if( !matchingDates.length ){
    return null
  }

  if( referenceDateBehaviour === 'LATEST_ALL_INCLUDING_DOUBLEVERIFY' ){
    return minBy(matchingDates, d => d.getTime()) || null
  }

  const matchingPrimaryProviders = enabledProviders.filter( p => (
    primaryPerformanceDataProviders.includes(p as PrimaryPerformanceDataProvider)
  )) as PrimaryPerformanceDataProvider[]

  const matchingPrimaryProviderDates = matchingPrimaryProviders.reduce( (acc, provider) => {
    const referenceDate = get(providerInfo[provider], 'reference_date', null)
    if( referenceDate ){
      acc.push(referenceDate)
    }
    return acc
  }, [] as Date[])

  if( referenceDateBehaviour === 'LATEST_ALL' ){
    return minBy(matchingPrimaryProviderDates, d => d.getTime()) || null
  }

  // 'LATEST_ANY'
  return maxBy(matchingPrimaryProviderDates, d => d.getTime()) || null

}


export const deriveLatestAvailableReferenceDate = ({
  providerInfo,
  enabledProviders,
}: Pick<
  DeriveReferenceDateProps, 'providerInfo' | 'enabledProviders'
>): Date | null => {
  return deriveCanonicalReferenceDate({
    providerInfo,
    enabledProviders,
    activeReferenceDate: null,
    referenceDateBehaviour: 'LATEST_ANY',
  })
}
