import React from 'react'

import { Grid, GridProps, Typography, TypographyProps } from '@material-ui/core'

import { RoundedPlainTextButton } from '../Buttons'
import { MenuOption, RoundedPlainTextButtonMenu } from '../Menus'
import { ArrowDropDown } from '../../icons'

import { makeAppStyles } from '../../themes'

import { addMonths, format, isAfter, isBefore, max, min } from 'date-fns'

import { chunk } from 'lodash-es'

import { financialYearFormatter } from '../../charts'


const useMonthPickerStyles = makeAppStyles( theme => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  row: {
    margin: theme.spacing(1, 0),
  },
  column: {
    margin: theme.spacing(0, 0.5),
  },
  popover: {
    width: 196,
    marginTop: 44,
    marginLeft: 10,
  },
}))


export type MonthPickerProps = {
  minDate: Date
  maxDate?: Date | null
  value: Date | undefined | null
  onChange: (value: Date) => void
  variant?: 'calendar' | 'financial'
  color?: 'primary' | 'secondary'
  buttonSize?: 'small' | 'medium' | 'large'
}


type DateMenuOption = MenuOption<Date> & { year: string }

type MonthOptionsGenerator = (
  props: Record<'minDate' | 'maxDate', Date> & { variant: 'calendar' | 'financial' }
) => DateMenuOption[]

const getMonthOptions: MonthOptionsGenerator = ({
  minDate,
  maxDate,
  variant,
}): DateMenuOption[] => {
  let startYear = minDate.getFullYear()
  let endYear = maxDate.getFullYear()
  const startMonth = variant === 'financial' ? 3 : 0
  let maxMonth = new Date(endYear, 11, 1)
  if( variant === 'financial' ){
    if( maxDate.getMonth() >= 3 ){
      endYear += 1
    }
    if( minDate.getMonth() < 3 ){
      startYear -= 1
    }
    maxMonth = new Date(endYear, 2, 1)
  }
  const getYearLabel = (
    variant === 'financial' ?
      financialYearFormatter :
      (d: Date): string => String(d.getFullYear())
  )
  let current = new Date(startYear, startMonth, 1)
  const options: DateMenuOption[] = []
  while( !isAfter(current, maxMonth) ){
    options.push({
      value: current,
      label: format(current, 'MMM'),
      year: getYearLabel(current),
      disabled: isBefore(current, minDate) || isAfter(current, maxDate)
    })
    current = addMonths(current, 1)
  }
  return options
}

const getKeyedOptionsByLabel = (
  options: DateMenuOption[]
): Record<string, DateMenuOption[]> => {
  return options.reduce( (acc, option): Record<string, DateMenuOption[]> => {
    acc[option.year] = acc[option.year] || []
    acc[option.year].push(option)
    return acc
  }, {} as Record<string, DateMenuOption[]>)
}


export const MonthPicker = ({
  minDate,
  maxDate,
  variant = 'calendar',
  color = 'primary',
  buttonSize = 'small',
  value = null,
  onChange,
}: MonthPickerProps): JSX.Element => {

  const classes = useMonthPickerStyles()

  maxDate = maxDate || new Date()

  const monthOptionsByYear = getKeyedOptionsByLabel(
    getMonthOptions({
      variant,
      minDate: min([value || minDate, minDate]),
      maxDate: max([value || maxDate, maxDate]),
    })
  )

  const yearOptions = Object.keys(monthOptionsByYear).sort()

  let valueYearLabel = yearOptions[yearOptions.length - 1]

  if( value ){
    if( variant === 'calendar' ){
      valueYearLabel = String(value.getFullYear())
    }else{
      valueYearLabel = financialYearFormatter(value)
    }
  }

  const monthOptions = monthOptionsByYear[valueYearLabel]

  const valueMonth = value && value.getMonth()

  const monthRows = chunk(monthOptions, 3)

  return (
    <div>
      <RoundedPlainTextButtonMenu
        TriggerProps={{
          variant: 'contained',
          size: buttonSize,
          color,
          endIcon: <ArrowDropDown />,
          fullWidth: true,
        }}
        MenuProps={{
          PopoverClasses: {
            paper: classes.popover,
          }
        }}
        value={valueYearLabel}
        options={yearOptions.map( value => ({
          value,
          label: value,
        }))}
        onChange={(_, year): void => {
          const options = monthOptionsByYear[year] || []
          const validOptions = options.filter( o => !o.disabled )
          if( valueMonth !== null ){
            const optionsByMonth = validOptions.reduce( (acc, option) => {
              acc[option.value.getMonth()] = option
              return acc
            }, {} as Record<number, DateMenuOption>)
            if( optionsByMonth[valueMonth] ){
              return onChange(optionsByMonth[valueMonth].value)
            }
          }
          onChange(validOptions[validOptions.length - 1].value)
        }} />
      <div>
        {monthRows.map( (row, i) => (
          <div className={classes.row} key={`row-${i}`}>
            { row.map( (month, j) => {
              const isSelected = (
                month.year === valueYearLabel
                && month.value.getMonth() === valueMonth
              )
              return (
                <RoundedPlainTextButton
                  key={`row-${i}-col-${j}`}
                  className={classes.column}
                  disabled={month.disabled}
                  variant={isSelected ? 'contained' : 'outlined'}
                  color={isSelected ? color : 'default'}
                  size={buttonSize}
                  onClick={(): void => {
                    onChange(month.value)
                  }}>
                  {month.label || month.value}
                </RoundedPlainTextButton>
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}


export type MonthRangePickerProps = (
  Omit<MonthPickerProps, 'value' | 'onChange'> &
  {
    value: [Date | null, Date | null]
    onChange: (value: [Date | null, Date | null]) => void
    GridProps?: Partial<Omit<GridProps, 'item' | 'container'>>
    TypographyProps?: Partial<Omit<TypographyProps, 'children'>>
  }
)

export const MonthRangePicker = ({
  minDate,
  maxDate,
  value,
  onChange,
  GridProps = {},
  TypographyProps = {},
  ...props
}: MonthRangePickerProps): JSX.Element => {
  maxDate = maxDate || new Date()
  return (
    <Grid container spacing={3} {...GridProps}>
      <Grid item xs={6}>
        <Typography align='center' {...TypographyProps}>Start</Typography>
        <MonthPicker
          minDate={minDate}
          maxDate={maxDate}
          value={value[0]}
          onChange={(start): void => {
            let end = value[1]
            if( !end || isAfter(start, end) ){
              end = start
            }
            onChange([start, end])
          }}
          {...props} />
      </Grid>
      <Grid item xs={6}>
        <Typography align='center' {...TypographyProps}>End</Typography>
        <MonthPicker
          minDate={minDate}
          maxDate={maxDate}
          value={value[1]}
          onChange={(end): void => {
            let start = value[0]
            if( !start || isAfter(start, end) ){
              start = end
            }
            onChange([start, end])
          }}
          {...props} />
      </Grid>
    </Grid>
  )
}
