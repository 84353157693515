import React from 'react'

import { AppBar as MuiAppBar, AppBarProps } from '@material-ui/core'

import { makeAppStyles } from '../../themes'


const useAppBarStyles = makeAppStyles( theme => ({
  root: {
    justifyContent: 'center',
    zIndex: theme.zIndex.drawer + 2,
    height: 'unset !important',
    padding: 0,
    transition: theme.transitions.create(['color', 'backgroundColor']),
  },
  colorPrimary: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  appBarInsert: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: theme.spacing(5.5),
  },
  primaryAppBarInsert: {
    minHeight: theme.spacing(6.5),
  },
  padded: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}) )


export type PerceptAppBarProps = AppBarProps & {
  startIcon?: JSX.Element
  appBarInserts?: JSX.Element[]
}


export const PerceptAppBar = ({
  color = 'primary',
  startIcon,
  children,
  appBarInserts = [],
  ...props
}: PerceptAppBarProps): JSX.Element => {

  const classes = useAppBarStyles()

  return (
    <MuiAppBar
      classes={{
        root: classes.root,
        colorPrimary: classes.colorPrimary,
      }}
      color={color}
      {...props}>

      <div className={classes.appBarInsert + ' ' + classes.padded + ' ' + classes.primaryAppBarInsert}>

        { startIcon }

        { children }

      </div>

      { appBarInserts.map( (insert, i) => (
        <div
          key={`app-bar-insert-${i}`}
          className={classes.appBarInsert}>
          { insert }
        </div>
      ))}

    </MuiAppBar>
  )
}
