import React, { Fragment, useMemo } from 'react'

import {
  Grid,
  GridProps,
  MetricCard,
  useAppTheme,
} from '@percept/mui'

import { useNavigation, useNestedProviderPillarScores } from '@percept/hooks'

import { useLayoutGridSpacing } from 'hooks'

import {
  channelPillars,
  pillarDescriptionMap,
  pillars,
  useMultiOrgQualityAnalysis,
} from './lib'

import { orgLabelDisplayMap } from 'components/Organisation'

import {
  ChartData,
  percentageDeltaFormatter,
  ResponsiveHistogramWithTooltip,
} from '@percept/mui/charts'

import { mapValues } from 'lodash-es'

import { avg } from '@percept/utils'

import { pillarLabelMap, providerChannelMap } from '@percept/constants'

import { DatumType, MetricPillar, PlatformUnitContainer, ReportProvider } from '@percept/types'


export type MultiOrgStructuralVarianceProps = {
  platformUnit: PlatformUnitContainer
  provider: ReportProvider
}


const statCardGridProps: Partial<GridProps> = {
  spacing: 1,
  justify: 'space-evenly',
  alignItems: 'flex-start',
}


type ChartConfigByPillar = Record<
  MetricPillar,
  {
    data: ChartData<DatumType & { id: string }>
  }
>


export const MultiOrgStructuralVariance = ({
  platformUnit,
  provider,
}: MultiOrgStructuralVarianceProps): JSX.Element => {

  const [nestedPillarScores] = useNestedProviderPillarScores({
    org_unit_id: platformUnit.id,
  })

  const multiOrgQualityAnalysis = useMultiOrgQualityAnalysis({
    provider,
    pillarScores: nestedPillarScores.data,
  })

  const appTheme = useAppTheme()

  const chartConfigByPillar: ChartConfigByPillar = useMemo(() => {
    return mapValues(
      multiOrgQualityAnalysis,
      (qaData) => {

        const average = avg(
          qaData.map( d => d.value ).filter( n => n !== null ) as number[]
        )

        return {
          data: qaData.map( d => {

            const varianceValue = (
              d.value === null ?
                null :
                (d.value - average)
            )

            return {
              ...d,
              value: varianceValue,
              label: d.name,
              color: appTheme.chart.healthColourScale(
                Number(varianceValue) > 0 ? 1 : 0
              ),
            }
          }),
        }
      }
    ) as unknown as ChartConfigByPillar
  }, [multiOrgQualityAnalysis, appTheme])

  const navigate = useNavigation()

  const gridSpacing = useLayoutGridSpacing()

  const { unit_type } = platformUnit

  const unitLabel = unit_type && orgLabelDisplayMap[unit_type].toLowerCase() || 'dashboard'

  const channel = provider && providerChannelMap[provider]

  const mappablePillars = channel ? channelPillars[channel] : pillars

  const gridItemProps: Partial<GridProps> = {
    xs: 12,
    md: 6,
    lg: provider === 'adwords' ? 4 : 3,
    justify: 'space-between',
    alignItems: 'center',
  }

  return (
    <Grid container spacing={gridSpacing}>

      { mappablePillars.map( pillar => {

        return (
          <Grid key={pillar} item {...gridItemProps}>

            <MetricCard
              color={channel || undefined}
              title={pillarLabelMap[pillar]}
              description={
                <Fragment>
                  { pillarDescriptionMap[pillar] }.
                  <br />
                  <br />
                  Distribution showing the latest health scores for each { unitLabel } relative to the average
                  of all { unitLabel }s.
                </Fragment>
              }
              GridProps={statCardGridProps}>

              <ResponsiveHistogramWithTooltip
                height={106}
                {...chartConfigByPillar[pillar]}
                onSegmentClick={(d): void => {
                  const typedOrgDatum = d as unknown as DatumType & { id: string }
                  if( typedOrgDatum.id ){
                    navigate(`/dashboards/${typedOrgDatum.id}`)
                  }
                }}
                mirrorYDomain={true}
                axisLine={true}
                axisText={true}
                grid='rows'
                yTickFormatter={percentageDeltaFormatter}
                numYTicks={5}
                numXTicks={0} />

            </MetricCard>

          </Grid>
        )
      })}

    </Grid>
  )
}
