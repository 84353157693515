
import { combineReducers } from 'redux'

import activeMetric from './activeMetric'
import activeSegment from './activeSegment'
import byId from './byId'
import entities from './entities'
import entitySort from './entitySort'
import filters from './filters'
// import exportOptions from './exportOptions'
import metrics from './metrics'
import payloads from './payloads'
import search from './search'
import settings from './settings'

import { ClientReportState } from '../typings'


export default combineReducers<ClientReportState>({
  activeMetric,
  activeSegment,
  byId,
  entities,
  entitySort,
  filters,
  metrics,
  payloads,
  search,
  settings,
})
