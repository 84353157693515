import React from 'react'

import {
  QuadrantChart,
  getQuadrantDatumAttributes,
  quadrantContrastTextFill,
  quadrantHealth,
} from './QuadrantCharts'

import { Box, Typography, makeAppStyles } from '@percept/mui'

import { Text } from '@visx/text'

import { QuadrantType } from '@percept/types'


const WIDTH = 500
const HEIGHT = 500

const quadrants: QuadrantType[] = [
  'bottom_left',
  'bottom_right',
  'top_left',
  'top_right',
]

const quadrantInfographicText: Record<QuadrantType, string> = {
  bottom_left: 'Did not deliver on Ad Imprint or Triggers',
  bottom_right: 'Delivered on Triggers but not Ad Imprint',
  top_left: 'Delivered on Ad Imprint but not Triggers',
  top_right: 'Delivered on Ad Imprint and Triggers',
}

const useStyles = makeAppStyles( theme => ({
  ul: {
    fontSize: 15,
    maxWidth: 400,
    paddingTop: theme.spacing(6),
    marginRight: theme.spacing(3),
    '& > li': {
      paddingBottom: theme.spacing(2),
    },
    '& > li:last-of-type': {
      paddingBottom: 0,
    },
  },
  legend: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(4),
    padding: theme.spacing(1, 0),
    fontSize: 14,
  },
  legendIcon: {
    height: 28,
    width: 28,
    borderRadius: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 14,
    fontWeight: 600,
    marginRight: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}))

export const SmartCampaignInfographic = (): JSX.Element => {

  const classes = useStyles()

  return (
    <Box display='flex'>
      <div>
        <Box my={2} width={WIDTH}>
          <Typography align='center' variant='h4'>
            Vodafone Smart Campaign Performance
          </Typography>
        </Box>

        <Box p={2}>
          <QuadrantChart
            width={WIDTH}
            height={HEIGHT}>
            { ({
              x,
              y,
              width,
              height,
              midPointX,
              midPointY,
              healthColourScale,
            }): JSX.Element => {

              const quadrantInfographics = quadrants.map( quadrant => {
                const attrs = getQuadrantDatumAttributes({
                  quadrant,
                  value: 60,
                  x,
                  y,
                  midPointX,
                  midPointY,
                  width,
                  height,
                  offset: 20,
                })

                const centerX = attrs.x + (attrs.width / 2)

                const textFill = quadrantContrastTextFill[quadrant]

                return (
                  <g key={quadrant}>
                    <rect {...attrs} fill={healthColourScale(quadrantHealth[quadrant])} />
                    <text
                      x={centerX}
                      y={attrs.y + 20}
                      fontSize={18}
                      fontWeight={600}
                      textAnchor='middle'
                      dominantBaseline='hanging'
                      fill={textFill}>
                      𝑥 %
                    </text>
                    <Text
                      x={centerX}
                      y={attrs.y + 50}
                      width={attrs.width - 60}
                      height={attrs.height - 60}
                      fill={textFill}
                      fontSize={16}
                      fontWeight={600}
                      textAnchor='middle'
                      verticalAnchor='start'>
                      { quadrantInfographicText[quadrant] }
                    </Text>
                  </g>
                )
              })

              return (
                <g>
                  <line stroke='red' x1={x} x2={width + x} y1={midPointY} y2={midPointY} />
                  <line stroke='red' x1={midPointX + x} x2={midPointX + x} y1={y} y2={height + y} />
                  { quadrantInfographics }
                </g>
              )
            }}
          </QuadrantChart>

          <div className={classes.legend}>
            <span className={classes.legendIcon}>𝑥 %</span> – proportion of ads achieving this performance
          </div>
        </Box>
      </div>

      <ul className={classes.ul}>
        <li>
          Smart campaign tracking measures <strong>short and long term impact</strong> of
          Vodafone and competitor campaigns
        </li>
        <li>
          <strong>Triggers</strong> are rational metrics that determine whether any short
          term action was taken as a result of seeing the brands ads i.e &quot;Talk&quot;
          about it to others, &quot;Learn&quot; more about it or &quot;Do&quot; something
          / consider doing something
        </li>
        <li>
          <strong>Ad Imprint</strong> measures the emotional response and impact seeing the
          brands ads had on them i.e it was memorable and left a lasting positive impression
        </li>
        <li>
          Whilst both are important, <strong>Ad imprint has the greatest correlation to
          brand consideration
          </strong>
        </li>
        <li>
          Campaigns that deliver on <strong>ad imprint are also more likely to drive short
          term action</strong> (triggers) too. (77% of campaigns that scored well on Ad imprint
          also delivered on triggers, however only 34% of those driving short term triggers
          performed well on Ad Imprint)
        </li>
      </ul>
    </Box>
  )
}
