/* eslint-disable no-console */

import { user } from '@percept/redux/bundles'

import { getFetchTypes } from '@percept/redux'

import { Middleware } from 'redux'

import defaultActionHandlers, { GAEvent } from './actionHandlers'

import { isFunction, noop } from 'lodash-es'

import { getPath, isArray } from '@percept/utils'


const privilegesFetchTypes = getFetchTypes(user.actions.LOAD_MY_USER_PRIVILEGES)


const setupGoogleAnalyticsScript = (GA_TRACKING_ID: string): void => {
  window.ga = window.ga || function(){(window.ga.q=window.ga.q||[]).push(arguments)}
  window.ga.l = +new Date

  // Load script asynchronously
  const gaScript = document.createElement('script')
  gaScript.async = true
  gaScript.src = 'https://www.google-analytics.com/analytics.js'

  // Create tracker object on script load
  gaScript.onload = () => {
    window.ga('create', GA_TRACKING_ID, 'auto')
  }
  // Good old IE
  gaScript.onreadystatechange = () => {
    if( gaScript.readyState == 'complete' ){
      window.ga('create', GA_TRACKING_ID, 'auto')
    }
  }

  document.body.appendChild(gaScript)
}


const setupGoogleTagManagerScript = (GTAG_ID: string, user_id: string): void => {
  // Load script asynchronously
  const gTagScript = document.createElement('script')
  gTagScript.async = true
  gTagScript.src = `https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`
  window.dataLayer = window.dataLayer || []
  window.gtag = window.gtag || function(){window.dataLayer.push(arguments)}
  gTagScript.onload = () => {
    const { gtag } = window
    gtag('js', new Date())
    gtag('config', GTAG_ID, { user_id })
  }
  // Good old IE
  gTagScript.onreadystatechange = () => {
    const { gtag } = window
    gtag('js', new Date())
    gtag('config', GTAG_ID, { user_id })
  }
  document.body.appendChild(gTagScript)
}


type AnalyticsMiddlewareProps = {
  GA_TRACKING_ID: string | undefined
  GTAG_ID: string | undefined
  actionHandlers?: Record<string, (...args: any[]) => GAEvent | GAEvent[] | null>
  debug?: boolean
}

const analyticsMiddlewareCreator = ({
  GA_TRACKING_ID,
  GTAG_ID,
  actionHandlers = defaultActionHandlers,
  debug = false 
}: AnalyticsMiddlewareProps): Middleware => {
  // Set up analytics
  if( !debug ){
    if( GA_TRACKING_ID ){
      setupGoogleAnalyticsScript(GA_TRACKING_ID)
    }
  }else{
    console.log('Running analytics middleware in debug mode')
  }

  return store => next => action => {

    const { ga } = window

    // Store the previous state
    const previousState = store.getState()

    // Dispatch the action and store the resulting state
    const result = next(action)

    const nextState = store.getState()

    if( !debug && action.type === privilegesFetchTypes.success ){
      const user_id = getPath(action, ['payload', 'user', 'user_id'], null)
      if( user_id && GTAG_ID ){
        setupGoogleTagManagerScript(GTAG_ID, user_id)
      }
      if( user_id ){
        try{
          if( isFunction(ga) ){
            ga('set', 'userId', user_id)
          }
        }catch(e){ noop() }
      }
    }

    if( isFunction(ga) && typeof actionHandlers[action.type] === 'function' ){

      const params = actionHandlers[action.type]({
        previous: previousState,
        action,
        next: nextState
      })

      if( params ){
        if( !debug ){
          isArray(params) ? params.map( p => ga('send', p) ) : ga('send', params)
        }else{
          if( !isArray(params) || params.length ){
            console.log('Analytics debug -> Params to send:', params)
          }
        }

      }
    
    }
    
    return result
  
  }
}

export default analyticsMiddlewareCreator
