
import React from 'react'

import { useAppTheme, makeAppStyles, AppTheme } from '../../themes'

import { useSpring, animated } from 'react-spring'

import { isNumber } from '@percept/utils'

import { ChannelKey, HealthType } from '@percept/types'


const useStyles = makeAppStyles( theme => ({
  root: {
    width: '100%',
    height: theme.spacing(0.75),
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
}) )


export type CardStripColor = 'primary' | 'secondary' | 'health' | 'neutral' | ChannelKey


export type CardStripColorConfiguration = {
  color: CardStripColor
  health?: HealthType
  animate?: boolean
}


export type CardStripProps = (
  CardStripColorConfiguration &
  React.HTMLAttributes<HTMLDivElement>
)


export const deriveCardStripColor = ({ color, health, appTheme }: CardStripColorConfiguration & { appTheme: AppTheme }): string => {
  const hasValue = isNumber(health)

  const numberValue: number = health || 0

  const colorString = (
    color === 'health' ?
      (
        hasValue && appTheme.chart.healthColourScale(numberValue) ||
        appTheme.palette.neutral.main
      ) : (
        color === 'primary' || color === 'secondary' || color === 'neutral'
      ) ?
        appTheme.palette[color].main :
        appTheme.palette.channel[color].main
  )

  return colorString
}


export const ReactSpringCardStrip = ({
  color,
  health,
  style,
  className = '',
  animate = true,
  ...props
}: CardStripProps): JSX.Element | null => {

  const appTheme = useAppTheme()

  const colorString = deriveCardStripColor({
    color,
    health,
    appTheme,
  })

  const animatedStyle = useSpring({
    immediate: !animate,
    from: {
      color: colorString
    },
    color: colorString,
  })

  const classes = useStyles()

  return (
    <animated.div
      className={
        `${classes.root} ${className}`
      }
      {...props}
      style={{
        ...(style || {}),
        backgroundColor: animatedStyle.color,
        ...animatedStyle}
      } />
  )
}



const useCssCardStripStyles = makeAppStyles( theme => ({
  root: ({ color, health, animate }: Pick<CardStripProps, 'color' | 'health' | 'animate'>) => {
    const colorString = deriveCardStripColor({
      color,
      health,
      appTheme: theme,
    })

    return {
      width: '100%',
      height: theme.spacing(0.75),
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      backgroundColor: colorString,
      transition: theme.transitions.create('background-color', {
        easing: 'cubic-bezier( 0.165,  0.84,  0.44,  1 )',
        duration: animate === false ? 0 : 1000,
      }),
    }
  },
}) )


export const CssCardStrip = ({ color, health, animate, className = '', ...props }: CardStripProps): JSX.Element => {

  const classes = useCssCardStripStyles({ color, health, animate })

  return (
    <div
      className={`${classes.root} ${className}`}
      {...props} />
  )
}


export const CardStrip = CssCardStrip
