import React, { Fragment, useContext } from 'react'

import { Link } from 'react-router-dom'

import {
  Box,
  BoxProps,
  ClassNameMap,
  formatMoney,
  Grid,
  makeAppStyles,
  Typography,
  vodafonePalette,
} from '@percept/mui'

import {
  BarChart,
  ChevronRight,
  HandshakeIcon,
  PlayArrowOutlined,
  Image as ImageIcon,
  Eco,
} from '@percept/mui/icons'

import { DefaultMediaWizardLayout } from './DefaultMediaWizardLayout'
import { Carousel, CarouselItem } from 'components/Carousel'
import { NewsHeader, NewsLink, WizardNewsSummary } from 'components/News'
import { LinkButton } from 'components/Links/LinkButton'
// import { MotionText } from 'components/Text'

import { useDocumentTitle } from '@percept/hooks'

import { separateThousands, userHasGlobalPrivileges } from '@percept/utils'

import carouselGrey1 from '../images/carousel-backgrounds/carousel-grey-1.jpg'
import carouselGrey2 from '../images/carousel-backgrounds/carousel-grey-2.jpg'

// import infoGraphicMediaWizard from '../images/infographics/media-wizard-2-light-bg.svg'
// import infoGraphicMMM from '../images/infographics/media-mix-modelling-2-light-bg.svg'
// import infoGraphicMediaStrategy from '../images/infographics/media-strategy-light-bg.svg'
// import infoGraphicOptimisation from '../images/infographics/optimisation-light-bg.svg'
// import infoGraphicOptimisationCarousel from '../images/infographics/optimisation-2-dark-bg.png'
import infoGraphicReporting from '../images/infographics/reporting-2-light-bg.png'

import { InfographicCQS } from 'components/StaticInfographics'

import { useBGPCarouselItem } from 'carousel-hooks'

import {
  AnimationCogsLarge,
  AnimationCogsSmall,
  AnimationMediaWizard,
  AnimationSquares,
} from 'animations'
import { UserPrivilegeContext } from '@percept/app-components'
import { GlobalPrivilege } from '@percept/types'
import { vodafoneExternalLinks } from 'components/Links'
import { get } from 'lodash-es'


const headlineFigures: { value: string, description: string}[] = [
  {
    value: '12%',
    description: 'increase in Meta clicks YoY',
  },
  {
    value: separateThousands(38694),
    description: 'total in-flight posts analysed by CreativeX in H2 2022',
  },
  {
    value: formatMoney({ amount: 3000000, currency: 'EUR', abbreviate: true }),
    description: 'wastage in Paid Search across all markets in past 6 months',
  },
]


const HeadlineFigure = ({
  value,
  description,
  BoxProps = {},
}: {
  value: string
  description: string
  BoxProps?: BoxProps
}): JSX.Element => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      margin='auto'
      // maxWidth={300}
      {...BoxProps}>
      <Box color='primary.main' mb={6} fontSize={88} fontWeight={700}>
        { value }
      </Box>
      <Box textAlign='center' fontSize={18}>
        { description }
      </Box>
    </Box>
  )
}


const LinkCard = ({
  to,
  title,
  content,
  BoxProps = {},
}: {
  to: string
  title: string
  content: JSX.Element | string
  color?: string
  BoxProps?: BoxProps
}): JSX.Element => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      borderRadius={10}
      p={4}
      minHeight={400}
      {...BoxProps}>
      <Box mb={4} fontWeight={700} fontSize={28}>
        { title }
      </Box>
      <Box fontSize={18} fontWeight={500}>
        { content }
      </Box>
      <Box marginTop='auto'>
        <Link to={to}>
          <Box
            display='flex'
            alignItems='center'
            fontSize={20}
            fontWeight={500}>
            View page <ChevronRight />
          </Box>
        </Link>
      </Box>
    </Box>
  )
}


const HomeLinkSection = ({
  to,
  title,
  content,
  classes,
  infoGraphicSrc,
  infoGraphicElement,
}: {
  to: string
  title: string
  content: JSX.Element | string
  color?: string
  className?: string
  infoGraphicSrc?: string
  infoGraphicElement?: JSX.Element | null
  classes: ClassNameMap<'root' | 'textContent' | 'infoGraphic' | 'img'>
}): JSX.Element => {
  return (
    <div
      className={classes.root}>
      <Box className={classes.textContent} width='50%'>
        {/* <MotionText
          value={title.toUpperCase()}
          fontSize='48px'
          strokeWidth={2}
          fontWeight={700} /> */}
        <Box fontSize={38} lineHeight='42px' fontWeight={700}>
          { title }
          <br />
          for growth
        </Box>
        <Box mt={3} mb={5} fontSize={18} lineHeight='26px' fontWeight={500}>
          { content }
        </Box>
        <LinkButton to={to} />
      </Box>
      <Box className={classes.infoGraphic} width='50%'>
        { infoGraphicSrc && (
          <img className={classes.img} src={infoGraphicSrc} />
        )}
        { infoGraphicElement }
      </Box>
    </div>
  )
}


const useLinkCardStyles = makeAppStyles({
  linkCard1: {
    color: 'white',
    backgroundImage: 'linear-gradient(rgb(130,0,0), rgb(230,0,0))',
  },
  linkCard2: {
    color: 'white',
    backgroundColor: vodafonePalette.slateGrey,
  },
  linkCard3: {
    color: 'black',
    backgroundImage: 'linear-gradient(#eee, #ccc)',
  },
})


const useLinkSectionStyles = makeAppStyles( theme => ({
  linkSection: {
    display: 'flex',
    alignItems: 'center',
  },
  paddedLeft: {
    padding: theme.spacing(8, 6, 8, 15),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(8, 6, 8, 9),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 3),
    },
  },
  paddedRight: {
    padding: theme.spacing(8, 15, 8, 6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(8, 9, 8, 6),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 3),
    },
  },
  infoGraphic: {
    display: 'flex',
    maxHeight: 400,
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  infoGraphicFixedRight: {
    textAlign: 'right',
    maxHeight: '100%',
  },
  squareAnimation: {
    height: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
  linkSection1: {
    color: 'black',
    // stroke: 'black',
    fill: 'transparent',
    backgroundColor: '#f2f2f2',
    height: 440,
  },
  linkSection1InfoGraphic: {
    textAlign: 'right',
    paddingRight: 0,
  },
  linkSection2: {
    flexDirection: 'row-reverse',
    color: 'black',
    // stroke: theme.palette.primary.main,
    fill: 'transparent',
    backgroundColor: 'white',
  },
  linkSection2InfoGraphic: {
    display: 'flex',
    height: 550,
  },
  linkSection3: {
    color: 'black',
    // stroke: 'black',
    fill: 'transparent',
    backgroundColor: '#f2f2f2',
  },
  shortcutLink: {
    color: 'inherit',
    textDecoration: 'underline solid transparent',
    textUnderlineOffset: '4px',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: `underline solid ${theme.palette.primary.main}`,
    },
    transition: theme.transitions.create(['color', 'text-decoration']),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  fontWeightBold: {
    fontWeight: 700,
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 6,
    borderTopRightRadius: 0,
    height: 38,
    width: 38,
    fontSize: 32,
    color: 'white',
    marginRight: theme.spacing(1),
  },
}))


const useIntroStyles = makeAppStyles( theme => ({
  root: {
    padding: theme.spacing(6, 12, 12, 12),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 6, 10, 6),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 3, 9, 3),
    },
  },
  title: {
    fontSize: 38,
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  description: {
    fontSize: 18,
    lineHeight: '26px',
    maxWidth: '48em',
    marginTop: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
}))


const PrivilegedLink = ({
  label,
  to,
  linkClassName,
  requiredGlobalPrivileges,
  userGlobalPrivileges,
}: {
  label: string
  to?: string | null
  linkClassName?: string
  requiredGlobalPrivileges?: GlobalPrivilege[] | null
  userGlobalPrivileges?: GlobalPrivilege[] | null
}): JSX.Element => {
  const requiresPrivileges = !!requiredGlobalPrivileges
  const hasRequiredPrivileges = !!(
    requiredGlobalPrivileges && userGlobalPrivileges && userHasGlobalPrivileges(
      requiredGlobalPrivileges,
      userGlobalPrivileges,
    )
  )
  if( !to || (requiresPrivileges && !hasRequiredPrivileges) ){
    return <>{label}</>
  }

  return (
    <a
      className={linkClassName}
      href={to}
      target='_blank'
      rel='noopener noreferrer'>
      { label }
    </a>
  )
}


export const Home = (): JSX.Element => {

  useDocumentTitle({ paths: ['Home'] })

  const privileges = useContext(UserPrivilegeContext)
  const defaultOrgUnitId = get(privileges.user, 'default_org_unit_id', null)
  const mqdLink = defaultOrgUnitId && `/dashboards/${defaultOrgUnitId}`

  const bgpCarouselItem = useBGPCarouselItem({
    backgroundImageSrc: carouselGrey1,
    textColor: 'black',
  })

  const carouselItems: CarouselItem[] = [
    {
      title: 'Welcome to the Media Wizard',
      description: (
        <Fragment>
          Vodafone&apos;s online decision engine, combining intelligence on strategy, optimisation
          and reporting in one automated platform. Giving you the tools, insights and real-time performance data,
          to unlock greater growth with your media investment.
        </Fragment>
      ),
      backgroundImageSrc: carouselGrey2,
      textColor: 'black',
      // infoGraphicSrc: infoGraphicMediaWizard,
      infoGraphicContent: <AnimationMediaWizard />,
    },
    ...(bgpCarouselItem && [bgpCarouselItem] || []),
    // {
    //   title: 'Improve media ROI and impact with the latest Media Mix Modelling',
    //   backgroundImageSrc: carouselGrey1,
    //   textColor: 'black',
    //   infoGraphicHeader: '+13% ROI by increasing total brand investment, unlocking 250m revenue potential',
    //   infoGraphicSrc: infoGraphicMMM,
    // },
    {
      title: (
        'Minimise digital wastage, utilising automated monitoring of best practices across digital platforms'
      ),
      titleSize: 0.85,
      description: (
        <Fragment>
          Analysing 300+ digital media quality KPIs, to enable an increase from 16% to 80%,
          across our core digital benchmarks.
        </Fragment>
      ),
      backgroundOverride: '#000',
      textColor: 'white',
      href: mqdLink,
      // infoGraphicSrc: infoGraphicOptimisationCarousel,
      infoGraphicFixedRight: true,
      infoGraphicContent: (
        <AnimationCogsLarge
          style={{
            marginRight: -48
          }} />
      )
    },
    {
      title: (
        <>
          Maximize the impact of every creative decision in digital, through{' '}
          <span style={{color: vodafonePalette.red}}>
            creative pre-flight testing
          </span>
          {' '}and hitting 80% Creative Quality Score target
        </>
      ),
      titleSize: 0.825,
      backgroundImageSrc: carouselGrey2,
      textColor: 'black',
      infoGraphicContent: <InfographicCQS />,
    },
  ]

  const linkSectionClasses = useLinkSectionStyles()
  const introClasses = useIntroStyles()

  return (
    <DefaultMediaWizardLayout>

      <Carousel
        items={carouselItems} />

      <div className={introClasses.root}>
        <Typography align='center' variant='h2' className={introClasses.title}>
          Grow your media with data-driven decisions
        </Typography>
        <Typography align='center' className={introClasses.description}>
          Increase efficiency, improve performance and reach your customers more effectively with the new Media Wizard.
        </Typography>
      </div>

      {/* <Box mt={8} mb={6}>
        <Grid container spacing={3}>
          { headlineFigures.map( (headlineFigure, i) => (
            <Grid
              key={`headlineFigure-${i}`}
              item
              xs={4}>
              <HeadlineFigure {...headlineFigure} />
            </Grid>
          ))}
        </Grid>
      </Box> */}

      <HomeLinkSection
        to='/wizard/strategy'
        title='Media Strategy'
        classes={{
          root: `${linkSectionClasses.linkSection} ${linkSectionClasses.linkSection1}`,
          textContent: linkSectionClasses.paddedLeft,
          // infoGraphic: linkSectionClasses.infoGraphicFixedRight,
          infoGraphic: linkSectionClasses.squareAnimation,
          img: linkSectionClasses.img,
        }}
        // infoGraphicSrc={infoGraphicMediaStrategy}
        infoGraphicElement={<AnimationSquares />}
        content={
          <Box>
            <p className={linkSectionClasses.fontWeightBold}>
              Create smarter media strategies, powered by intelligence and diagnostic tools:
            </p>
            <ul style={{paddingLeft: '1rem'}}>
              <li>
                <PrivilegedLink
                  label='Pathways'
                  linkClassName={linkSectionClasses.shortcutLink}
                  userGlobalPrivileges={privileges.global_privileges}
                  requiredGlobalPrivileges={['vfMicrosites.pathways.view']}
                  to='/wizard/strategy/pathways' />
              </li>
              <li>
                <PrivilegedLink
                  label='Brand Growth Planner'
                  linkClassName={linkSectionClasses.shortcutLink}
                  userGlobalPrivileges={privileges.global_privileges}
                  requiredGlobalPrivileges={['brandGrowthPlanner.viewAny']}
                  to='/wizard/strategy/brand-growth-planner' />
              </li>
              <li>
                <PrivilegedLink
                  label='Media Mix Modelling'
                  linkClassName={linkSectionClasses.shortcutLink}
                  userGlobalPrivileges={privileges.global_privileges}
                  requiredGlobalPrivileges={['vfMicrosites.mmm.view']}
                  to='/wizard/strategy/media-mix-modelling' />
              </li>
              <li>
                <PrivilegedLink
                  label='Representation'
                  linkClassName={linkSectionClasses.shortcutLink}
                  to={vodafoneExternalLinks.representation} />
              </li>
            </ul>
          </Box>
        } />

      <HomeLinkSection
        to='/wizard/optimisation'
        title='Optimisation'
        classes={{
          root: `${linkSectionClasses.linkSection} ${linkSectionClasses.linkSection2}`,
          textContent: linkSectionClasses.paddedRight,
          infoGraphic: `${linkSectionClasses.linkSection2InfoGraphic} ${linkSectionClasses.paddedLeft}`,
          img: linkSectionClasses.img,
        }}
        // infoGraphicSrc={infoGraphicOptimisation}
        infoGraphicElement={<AnimationCogsSmall />}
        content={
          <Box>
            <p className={linkSectionClasses.fontWeightBold}>
              Maximise your campaign results with real-time data, tools and support,
              to drive efficiency and reduce media wastage:
            </p>
            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={6} className={linkSectionClasses.flex}>
                  <span className={linkSectionClasses.iconWrapper}>
                    <BarChart fontSize='inherit' />
                  </span>
                  <PrivilegedLink
                    label='Media Quality Dashboard'
                    linkClassName={linkSectionClasses.shortcutLink}
                    userGlobalPrivileges={privileges.global_privileges}
                    requiredGlobalPrivileges={['structuralReporting.viewAny', 'performanceOverview.viewAny']}
                    to={mqdLink} />
                </Grid>
                <Grid item xs={6} className={linkSectionClasses.flex}>
                  <span className={linkSectionClasses.iconWrapper}>
                    <PlayArrowOutlined fontSize='inherit' />
                  </span>
                  <PrivilegedLink
                    label='Partnerships Scorecards & Webinars'
                    linkClassName={linkSectionClasses.shortcutLink}
                    userGlobalPrivileges={privileges.global_privileges}
                    requiredGlobalPrivileges={['partnerships.viewAny']}
                    to='/wizard/optimisation/partnerships' />
                </Grid>
                <Grid item xs={6} className={linkSectionClasses.flex}>
                  <span className={linkSectionClasses.iconWrapper}>
                    <ImageIcon fontSize='inherit' />
                  </span>
                  <PrivilegedLink
                    label='Creative Quality Dashboard'
                    linkClassName={linkSectionClasses.shortcutLink}
                    to={vodafoneExternalLinks.creativeX} />
                </Grid>
                <Grid item xs={6} className={linkSectionClasses.flex}>
                  <span className={linkSectionClasses.iconWrapper}>
                    <HandshakeIcon fontSize='inherit' />
                  </span>
                  <PrivilegedLink
                    label='Sponsorships Value Calculator'
                    linkClassName={linkSectionClasses.shortcutLink}
                    userGlobalPrivileges={privileges.global_privileges}
                    requiredGlobalPrivileges={['sponsorships.viewAny']}
                    to='/wizard/optimisation/sponsorships' />
                </Grid>
                <Grid item xs={6} className={linkSectionClasses.flex}>
                  <span className={linkSectionClasses.iconWrapper}>
                    <Eco fontSize='inherit' />
                  </span>
                  <PrivilegedLink
                    label='Media Carbon Calculator'
                    linkClassName={linkSectionClasses.shortcutLink}
                    to={vodafoneExternalLinks.carbonCalculator} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        } />

      <HomeLinkSection
        to='/wizard/reporting'
        classes={{
          root: `${linkSectionClasses.linkSection} ${linkSectionClasses.linkSection3}`,
          textContent: linkSectionClasses.paddedLeft,
          infoGraphic: `${linkSectionClasses.infoGraphic} ${linkSectionClasses.paddedRight}`,
          img: linkSectionClasses.img,
        }}
        title='Reporting'
        infoGraphicSrc={infoGraphicReporting}
        content={
          <Box>
            <p className={linkSectionClasses.fontWeightBold}>
              Make informed, transparent business and campaign decisions, using the latest
              investment data and key brand metrics.
            </p>
            <ul style={{paddingLeft: '1rem'}}>
              <li>
                <PrivilegedLink
                  label='Media Investment Platform'
                  linkClassName={linkSectionClasses.shortcutLink}
                  userGlobalPrivileges={privileges.global_privileges}
                  requiredGlobalPrivileges={['mediaInvestment.viewAny']}
                  to={defaultOrgUnitId && `/reporting/${defaultOrgUnitId}`} />
              </li>
              <li>
                <PrivilegedLink
                  label='Smart Campaign Assessment'
                  linkClassName={linkSectionClasses.shortcutLink}
                  userGlobalPrivileges={privileges.global_privileges}
                  requiredGlobalPrivileges={['vfSmartCampaignAssessment.view']}
                  to='/wizard/reporting/smart-campaign-assessment' />
              </li>
              <li>
                <PrivilegedLink
                  label='Wastage Trend Reports'
                  linkClassName={linkSectionClasses.shortcutLink}
                  userGlobalPrivileges={privileges.global_privileges}
                  requiredGlobalPrivileges={['vfWastageTrendReports.viewAny']}
                  to='/wizard/reporting/wastage-trend-reports' />
              </li>
            </ul>
          </Box>
        } />

      <NewsHeader />
      
      <WizardNewsSummary />

      <NewsLink />

    </DefaultMediaWizardLayout>
  )
}
