
import { actionCreator } from '@percept/redux'

import {
  AsyncReduxAction,
  DimensionType,
} from '@percept/types'


export const LOAD_AUDIT = 'LOAD_AUDIT'
export const LOAD_AUDIT_PAYLOAD = 'LOAD_AUDIT_PAYLOAD'
export const LOAD_AUDIT_ENTITY = 'LOAD_AUDIT_ENTITY'
export const LOAD_AUDIT_METRIC = 'LOAD_AUDIT_METRIC'

export const FILTER_AUDIT_ENTITIES = 'FILTER_AUDIT_ENTITIES'
export const CLEAR_AUDIT_ENTITY_FILTER = 'CLEAR_AUDIT_ENTITY_FILTER'

export const SORT_AUDIT_ENTITIES = 'SORT_AUDIT_ENTITIES'

export const SET_ACTIVE_SEGMENT = 'SET_ACTIVE_SEGMENT'
export const CLEAR_ACTIVE_SEGMENT = 'CLEAR_ACTIVE_SEGMENT'

export const SET_ACTIVE_METRIC = 'SET_ACTIVE_METRIC'
export const CLEAR_ACTIVE_METRIC = 'CLEAR_ACTIVE_METRIC'

export const SET_EXPORT_METRIC = 'SET_EXPORT_METRIC'
export const CLEAR_EXPORT_METRIC = 'CLEAR_EXPORT_METRIC'
export const SET_EXPORT_TYPE = 'SET_EXPORT_TYPE'
export const SET_EXPORT_OPTIONS = 'SET_EXPORT_OPTIONS'

export const SET_DISPLAY_TYPE = 'SET_DISPLAY_TYPE'
export const SET_METRIC_ORDER = 'SET_METRIC_ORDER'

export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'
export const SAVE_SEARCH_QUERY = 'SAVE_SEARCH_QUERY'


export const loadAudit = (
  { report_id, ...meta }:
  { report_id: string }
): AsyncReduxAction => ({
  type: LOAD_AUDIT,
  resource: `/reports/${report_id}`,
  meta: { report_id, ...meta }
})

export const loadAuditPayload = (
  { report_id, ...meta }:
  { report_id: string }
): AsyncReduxAction => ({
  type: LOAD_AUDIT_PAYLOAD,
  resource: `/reports/${report_id}/payload`,
  meta: { report_id, ...meta }
})

export const loadAuditEntity = (
  { report_id, entity_type, entity_id, ...meta }:
  { report_id: string, entity_type: string, entity_id: string }
): AsyncReduxAction => ({
  type: LOAD_AUDIT_ENTITY,
  resource: `/reports/${report_id}/payload`,
  options: {
    params: {
      'entity-type': entity_type.toLowerCase(),
      'entity-id': entity_id
    }
  },
  meta: { report_id, entity_type, entity_id, ...meta }
})

export const loadAuditMetric = (
  { report_id, entity_type, entity_id, metric_id, dimension, ...meta }:
  { report_id: string, entity_type: string, entity_id: string, metric_id: string, dimension: DimensionType }
): AsyncReduxAction => ({
  type: LOAD_AUDIT_METRIC,
  resource: `/reports/${report_id}/payload`,
  options: {
    params: {
      'entity-type': entity_type.toLowerCase(),
      'entity-id': entity_id,
      'metric-id': metric_id,
      'metric-agg': dimension
    }
  },
  meta: { report_id, entity_type, entity_id, metric_id, dimension, ...meta }
})


export const filterAuditEntities = actionCreator(FILTER_AUDIT_ENTITIES)
export const clearAuditEntityFilter = actionCreator(CLEAR_AUDIT_ENTITY_FILTER)

export const sortAuditEntities = actionCreator(SORT_AUDIT_ENTITIES)

export const setActiveSegment = actionCreator(SET_ACTIVE_SEGMENT)
export const clearActiveSegment = actionCreator(CLEAR_ACTIVE_SEGMENT)

export const setActiveMetric = actionCreator(SET_ACTIVE_METRIC)
export const clearActiveMetric = actionCreator(CLEAR_ACTIVE_METRIC)

export const setExportMetric = actionCreator(SET_EXPORT_METRIC)
export const clearExportMetric = actionCreator(CLEAR_EXPORT_METRIC)
export const setExportType = actionCreator(SET_EXPORT_TYPE)
export const setExportOptions = actionCreator(SET_EXPORT_OPTIONS)

export const setDisplayType = actionCreator(SET_DISPLAY_TYPE)
export const setMetricOrder = actionCreator(SET_METRIC_ORDER)

export const setSearchQuery = actionCreator(SET_SEARCH_QUERY)
export const saveSearchQuery = actionCreator(SAVE_SEARCH_QUERY)


export default {
  LOAD_AUDIT,
  loadAudit,

  LOAD_AUDIT_METRIC,
  loadAuditMetric,

  LOAD_AUDIT_ENTITY,
  loadAuditEntity,

  LOAD_AUDIT_PAYLOAD,
  loadAuditPayload,

  FILTER_AUDIT_ENTITIES,
  filterAuditEntities,

  CLEAR_AUDIT_ENTITY_FILTER,
  clearAuditEntityFilter,

  SORT_AUDIT_ENTITIES,
  sortAuditEntities,

  SET_ACTIVE_SEGMENT,
  setActiveSegment,

  CLEAR_ACTIVE_SEGMENT,
  clearActiveSegment,

  SET_ACTIVE_METRIC,
  setActiveMetric,

  CLEAR_ACTIVE_METRIC,
  clearActiveMetric,

  SET_EXPORT_METRIC,
  setExportMetric,

  CLEAR_EXPORT_METRIC,
  clearExportMetric,

  SET_EXPORT_TYPE,
  setExportType,

  SET_EXPORT_OPTIONS,
  setExportOptions,

  SET_DISPLAY_TYPE,
  setDisplayType,

  SET_METRIC_ORDER,
  setMetricOrder,

  SET_SEARCH_QUERY,
  setSearchQuery,

  SAVE_SEARCH_QUERY,
  saveSearchQuery,
}
