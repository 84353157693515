import React from 'react'

import { Box, Card, CardProps, Divider, Grid, GridProps, Typography } from '@percept/mui'


export type OverviewAnalysisProps = {
  items: {
    label: string,
    texts: (string | JSX.Element)[]
  }[]
  GridItemProps: Omit<GridProps, 'item' | 'container'>
} & CardProps

export const OverviewAnalysis = ({
  items,
  GridItemProps,
  ...props
}: OverviewAnalysisProps): JSX.Element => {
  return (
    <Card {...props}>
      <Box p={1.5}>
        <Typography variant='h5'>Focus Areas & Insight</Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <Grid container spacing={2}>
          { items.map( item => (
            <Grid key={item.label} item {...GridItemProps}>
              <Typography variant='h6'>{item.label}</Typography>
              <Typography paragraph>
                <ul style={{paddingLeft: '1rem'}}>
                  { item.texts.map( (text, i) => (
                    <li key={i}>{text}</li>
                  ))}
                </ul>
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Card>
  )
}
