import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { ReportSchemaFieldset } from '../../../api/report-schemas'
import { Questions } from './Questions'
import { makeAppStyles } from '@percept/mui'

interface FieldsetsProps {
  fieldsets: ReportSchemaFieldset[]
}

const useFieldsetsStyles = makeAppStyles((theme) => ({
  fieldsets: {
    display: 'grid',
    gap: '2rem',
  },
  legend: {
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: 400,
    marginBottom: theme.spacing(2),
  },
}))

export function Fieldsets({ fieldsets }: FieldsetsProps): JSX.Element {
  const classes = useFieldsetsStyles()

  return (
    <Box className={classes.fieldsets}>
      {fieldsets.map(({ legend, fieldsets, questions }, index) => (
        <div key={index}>
          {legend && (
            <Typography className={classes.legend}>{legend}</Typography>
          )}
          {questions && <Questions questions={questions} />}
          {fieldsets && <Fieldsets fieldsets={fieldsets} />}
        </div>
      ))}
    </Box>
  )
}
