
import React, { Fragment, useEffect, useMemo, useState } from 'react'

import { Box, Button, IconButton } from '@material-ui/core'

import { makeAppStyles } from '../../themes'

import { TreeView, OverridingNodeComponentProps } from '../TreeView'

import { MetricSummary } from './MetricSummary'

import { ArrowDropDown, ArrowDropUp, Folder, FolderOpen } from '../../icons'

import { filterLayoutWithMatches, withLayoutItemPaths } from './searchUtils'

import { getPath } from '@percept/utils'

import {
  LayoutNodeType,
  LayoutType,
  MetricDisplayType,
  MetricType,
} from '@percept/types'

import {
  DashboardHandlers,
  SearchDependencies,
  LayoutItemSummaryProps,
  MetricSearchConfig,
  MetricSearchResult,
  MetricSearchClasses,
} from './typings'


const useStyles = makeAppStyles<{}, keyof MetricSearchClasses>( theme => ({
  root: {
    padding: 0,
  },
  section: {
    display: 'flex',
    alignItems: 'center',
  },
  clickable: {
    cursor: 'pointer',
    // '&:hover': {
    //   backgroundColor: 'rgba(255,255,255,0.25)',
    // }
  },
  entry: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '4rem',
    padding: theme.spacing(1, 1),
    cursor: 'pointer',
    backgroundColor: 'rgba(255,255,255,0)',
    transition: theme.transitions.create('backgroundColor'),
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.15)',
    },
    '&:focus': {
      
    },
  },
  focused: {
    // backgroundColor: theme.palette.secondary.dark,
    // outline: 'none',
    // border: `2px solid ${theme.palette.secondary.light}`,
  },
  title: {
    display: 'inline-flex',
    textAlign: 'left',
    maxWidth: '20rem',
    marginRight: theme.spacing(3),
    flexWrap: 'wrap',
  },
  metric: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  health: {
    marginRight: theme.spacing(1),
    textAlign: 'right',
  },
}) )


export type MetricSearchResultsProps = (
  SearchDependencies &
  DashboardHandlers &
  {
    view: 'LAYOUT' | 'LIST'
    searchConfig: MetricSearchConfig
    matches: MetricSearchResult[]
    displayType?: MetricDisplayType
    containerRef?: React.MutableRefObject<HTMLDivElement | null>
    classes?: Partial<MetricSearchClasses>
  }
)

type VirtualizedMetricSearchResultsProps = Omit<MetricSearchResultsProps, 'searchConfig'> & {
  containerNode: HTMLDivElement | null
  searchClasses: MetricSearchClasses
  focusedMetric?: string | null
}

export const MetricSearchResultsList = ({
  matches,
  metadata,
  payload,
  entity,
  containerNode,
  searchClasses,
  displayType,
  healthDimension = 'count',
  setActiveMetric,
  // focusedMetric,
}: VirtualizedMetricSearchResultsProps): JSX.Element | null => {

  if( !matches.length ){
    return null
  }

  return (
    <Fragment>
      { matches.map( ({ id }, i) => {
        return (
          <MetricSummary
            key={`${id}-${i}`}
            id={id}
            // focused={id === focusedMetric}
            searchClasses={searchClasses}
            displayType={displayType}
            containerNode={containerNode}
            payload={payload}
            entity={entity}
            metadata={metadata}
            healthDimension={healthDimension}
            onClick={(): void => {
              const payloadMetric = getPath<MetricType>(payload, [id, 'dimensions', 'count'])
              if( payloadMetric && setActiveMetric ){
                setActiveMetric({
                  metric_id: id,
                  ...payloadMetric
                })
              }
            }} />
        )
      })}
    </Fragment>
  )
}


type NodeComponentProps = (
  LayoutItemSummaryProps & {
    containerNode: HTMLDivElement | null
    searchClasses: MetricSearchClasses
    focused?: boolean
  }
)

const NodeComponent = ({
  payload,
  expanded,
  onExpand,
  metadata,
  onClick,
  healthDimension = 'count',
  entity,
  style,
  searchClasses,
  containerNode,
  // focused,
  ...props
}: NodeComponentProps): JSX.Element => {

  const {
    type,
    name,
    id,
  } = props

  const clickHandler = onClick ? (
    (): void => onClick(props)
  ) : undefined

  if( id ){
    return (
      <MetricSummary
        style={style}
        key={id}
        id={id}
        // focused={focused}
        searchClasses={searchClasses}
        containerNode={containerNode}
        payload={payload}
        entity={entity}
        metadata={metadata}
        onClick={clickHandler} />
    ) 
  }

  return (
    <Box
      style={style}
      className={searchClasses.section}>
      
      <Box
        mr={1}>
        <IconButton
          onClick={onExpand}>
          { expanded ? <ArrowDropDown /> : <ArrowDropUp /> }
        </IconButton>
      </Box>

      <Button
        className={searchClasses.title}
        startIcon={expanded ? <FolderOpen /> : <Folder />}
        size='small'
        onClick={clickHandler}>
        { name }
      </Button>

    </Box>
  )
}


export const MetricSearchResultsLayout = ({ 
  matches,
  layout,
  payload,
  entity,
  metadata,
  impactWeighted,
  healthDimension,
  onActivateLayoutItemPath,
  setActiveMetric,
  containerNode,
  searchClasses,
  focusedMetric,
}: VirtualizedMetricSearchResultsProps): JSX.Element | null => {

  const hierarchy = useMemo(() => {
    if( !layout ) return layout
    const filteredHierarchy = filterLayoutWithMatches({
      matches,
      layout: withLayoutItemPaths(layout)
    })
    return (
      filteredHierarchy && filteredHierarchy.members
    )  as (LayoutType & { nodePath: number[] })[]
  }, [matches, layout])

  if( !matches.length ){
    return null
  }

  return hierarchy && (
    <TreeView
      defaultDepth={-1}
      nodeMargin='1rem'
      className={searchClasses.root}
      NodeComponent={(props): JSX.Element => (
        <NodeComponent
          key={props.id}
          payload={payload}
          entity={entity}
          metadata={metadata}
          impactWeighted={impactWeighted}
          healthDimension={healthDimension}
          searchClasses={searchClasses}
          {...(props as unknown as OverridingNodeComponentProps<LayoutNodeType> & LayoutNodeType)}
          containerNode={containerNode}
          focused={props.id === focusedMetric}
          onClick={(node): void => {
            if( node.id ){
              // Handle metric deeplink
              const payloadMetric = getPath<MetricType>(payload, [node.id, 'dimensions', 'count'])
              if( payloadMetric && setActiveMetric ){
                setActiveMetric({
                  metric_id: node.id,
                })
              }
            }else if( onActivateLayoutItemPath ){
              const path = getPath<number[]>(node, 'nodePath', []).join('-')
              if( path ){
                onActivateLayoutItemPath(path)
              }
            }
          }} />
      )}
      hierarchy={hierarchy} />
  )

}


export const MetricSearchResults = ({
  searchConfig,
  containerRef,
  classes = {},
  ...props
}: MetricSearchResultsProps): JSX.Element => {

  const { view } = props

  const Renderer = view === 'LAYOUT' ? MetricSearchResultsLayout : MetricSearchResultsList

  const node = containerRef && containerRef.current || null

  // const [focused, setFocused] = useState<string | null>(null)

  // Reset container scroll on view/matches/config change
  useEffect(() => {
    if( node ){
      node.scrollTo(0, 0)
    }
  }, [view, props.matches, searchConfig, node])

  // useEffect(() => {
  //   if( props.matches && props.matches.length ){
  //     const isPresent = focused && props.matches.filter( m => m.id === focused ).length > 0
  //     if( !isPresent ){
  //       setFocused(props.matches[0].id)
  //     }
  //   }
  // }, [props.matches, focused])

  const searchClasses = {
    ...useStyles(),
    ...classes
  }

  return (
    <Renderer
      {...props}
      // focusedMetric={focused}
      searchClasses={searchClasses}
      containerNode={node} />
  )
}
