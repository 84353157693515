
import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'

import { ReduxBundle } from '../lib'

import { UserState } from './typings'
import { ReduxAction } from '@percept/types'

import { isLoginSuccessAction } from './utils'

type UserActions = typeof actions
type UserSelectors = typeof selectors


export type UserBundle = ReduxBundle<UserState, UserActions, UserSelectors> & {
  utils: {
    isLoginSuccessAction: (action: ReduxAction) => boolean
  }
}

const utils: UserBundle['utils'] = {
  isLoginSuccessAction,
}
    
export { actions, reducer, selectors, utils }


export const makeBundle = (): UserBundle => ({
  actions,
  reducer,
  selectors,
  utils,
})

export default { actions, reducer, selectors, utils } as UserBundle
