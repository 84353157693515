import { PrefillPlaceholder } from '../../api/types'
import { ReportSchemaFieldset } from '../../api/report-schemas'

interface ReplacePrefillValuesParams {
  fieldsets: ReportSchemaFieldset[]
  placeholders: PrefillPlaceholder[]
}

export function replacePrefillPlaceholders({
  fieldsets,
  placeholders,
}: ReplacePrefillValuesParams): ReportSchemaFieldset[] {
  fieldsets.forEach(({ fieldsets, questions }) => {
    if (questions) {
      questions.forEach((question) => {
        const prefillKey = question.prefill_key

        if (prefillKey) {
          const placeholder = placeholders.find(
            (placeholder) => placeholder.key === prefillKey
          )

          if (!placeholder) {
            throw new Error(`Missing placeholder value for key: ${prefillKey}`)
          }

          question.value = placeholder.value
        }
      })
    }

    if (fieldsets) {
      replacePrefillPlaceholders({
        fieldsets,
        placeholders,
      })
    }
  })

  return fieldsets
}
