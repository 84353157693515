
import {
  SET_ACTIVE_METRIC,
  CLEAR_ACTIVE_METRIC,
} from '../actions'

import { Reducer } from '@percept/types'

import { ClientReportState } from '../typings'


const activeMetric: Reducer<ClientReportState['activeMetric']> = (state = null, action) => {
  
  switch(action.type){
    
    case SET_ACTIVE_METRIC:
      return {
        ...(state || {}),
        ...action.payload
      }

    case CLEAR_ACTIVE_METRIC:
      return null

    default:
      return state
  
  }
}

export default activeMetric
