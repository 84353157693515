
import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'

import { ReduxBundle } from '../lib'

import { TokenState } from './typings'


type TokenActions = typeof actions
type TokenSelectors = typeof selectors


export type TokenBundle = ReduxBundle<TokenState, TokenActions, TokenSelectors>


export { actions, reducer, selectors }


export const makeBundle = (): TokenBundle => ({
  actions,
  reducer,
  selectors
})

export default { actions, reducer, selectors } as TokenBundle
