import React from 'react'

import { Box, Typography } from '@percept/mui'
import { Error as ErrorIcon } from '@percept/mui/icons'

import { NotificationItemBase, WorkloadContext } from '@percept/types'


const { APP, WORKLOAD_CONTEXT } = process.env

const signInToastsByContext: Partial<
  Record<
    WorkloadContext,
    Partial<Record<'admin' | 'vodafone' | 'dashboard' | 'public' | 'datorama', NotificationItemBase>>
  >
> = {
  VODAFONE: {
    vodafone: {
      id: 'EULA',
      type: 'info',
      ttl: 5000,
      message: (
        <Box p={1}>
          <Box display='flex' alignItems='flex-center'>
            <Box mr={1}>
              <ErrorIcon color='inherit' />
            </Box>
            <Typography variant='h6'>Important</Typography>
          </Box>
          <Box mt={2} fontSize={16} lineHeight='24px'>
            By signing in to this platform you are agreeing to Vodafone&apos;s EULA
            and the responsibility of accessing C3-level data
          </Box>
        </Box>
      )
    }
  }
}

const applicationSignInToasts = signInToastsByContext[WORKLOAD_CONTEXT] || {}

export const signInToast = applicationSignInToasts[APP] || null
