import { createContext } from 'react'

import { OptionalComparisonDateRanges, OverviewGranularity } from './typings'

import { ComparisonMethod } from '@percept/utils'


export type OverviewContextType = {
  referenceDate: Date
  setReferenceDate: (referenceDate: Date) => void
  maxDate: Date
  granularity: OverviewGranularity
  setGranularity: (granularity: OverviewGranularity) => void
  dateRanges: OptionalComparisonDateRanges
  comparisonMethod: ComparisonMethod
  setComparisonMethod: (comparisonMethod: ComparisonMethod) => void
}

export const OverviewContext = createContext<OverviewContextType>({} as OverviewContextType)
