import { Selector } from 'react-redux'

import { get } from 'lodash-es'

import { apiInitialState } from '../../constants'

import { getProviderKey } from '../../utils'

import {
  ApiResponse,
  Dictionary,
  ReportProviderV2,
} from '@percept/types'

import { JsonSchemaState } from './typings'


type StoreWithJsonSchemaState = {
  jsonSchemas: JsonSchemaState
}

type JsonSchemaSelector<TProps, TOwnProps = null> = Selector<StoreWithJsonSchemaState, TProps, TOwnProps>


export const getReportSeriesConfigSchema: JsonSchemaSelector<
  ApiResponse<Dictionary>,
  { provider: ReportProviderV2 }
> = (state, params) => (
  get(
    state.jsonSchemas.reportSeriesConfigs,
    getProviderKey(params) || '',
    apiInitialState
  )
)
