import { UseMutationResult, UseQueryOptions, UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query'

import { useDispatch } from 'react-redux'

import { notifications } from '@percept/redux/bundles'

import { apiClients, makeClientQueryFn } from './lib'

import { AxiosError } from 'axios'

import { CreateTenantUserPayload, EditTenantUserPayload, PaginatedResponse, TenantUserManagementMetadata } from '@percept/types'


export const useTenantUserManagementMetadata = (
  tenant_id: string
): UseQueryResult<TenantUserManagementMetadata> => (
  useQuery({
    queryKey: ['userManagementMetadata', tenant_id],
    queryFn: makeClientQueryFn<TenantUserManagementMetadata>(
      apiClients.core,
      { url: `/tenant-management/${tenant_id}/users/metadata` },
      (response) => ({
        ...response.data,
        attribute_groups: response.data.attribute_groups.map( g => ({
          ...g,
          values: g.values.map( v => ({
            ...v,
            blueprint: v.slug === 'vodafone-user',
          }))
        }))
      })
    ),
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
)


export type ListingUserParameter = {
  id: string
  slug: string
  name: string
  enabled: boolean
}

export type TenantUserManagementListingUser = {
  user_id: string
  enabled: boolean
  default_org_unit_id: string
  tenant_org_unit_id: string
  email: string
  name?: string
  created: string
  activated_at: string
  modified: string
  last_login?: string
  user_attributes: {
    group: ListingUserParameter
    value: ListingUserParameter
  }[]
  user_options: {
    group: ListingUserParameter
    values: ListingUserParameter[]
  }[]
}


export const useTenantUserManagementEstablishedUsers = (
  tenant_id: string,
  page: number,
  queryOptions: Partial<UseQueryOptions<PaginatedResponse<TenantUserManagementListingUser[]>>> = {}
): UseQueryResult<PaginatedResponse<TenantUserManagementListingUser[]>> => (
  useQuery({
    queryKey: ['userManagementEstablishedUsers', tenant_id, page],
    queryFn: makeClientQueryFn<PaginatedResponse<TenantUserManagementListingUser[]>>(apiClients.core, {
      url: `/tenant-management/${tenant_id}/users/established-users`,
      params: { page }
    }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: false,
    ...queryOptions,
  })
)

export const useTenantUserManagementEstablishedUser = (
  tenant_id: string,
  user_id: string | null,
  queryOptions: Partial<UseQueryOptions<TenantUserManagementListingUser>> = {}
): UseQueryResult<TenantUserManagementListingUser> => (
  useQuery({
    queryKey: ['userManagementEstablishedUsers', tenant_id, user_id],
    queryFn: makeClientQueryFn<TenantUserManagementListingUser>(apiClients.core, {
      url: `/tenant-management/${tenant_id}/users/established-users/${user_id}`,
    }),
    refetchOnWindowFocus: false,
    retry: false,
    ...queryOptions,
  })
)


export const useTenantUserManagementPendingSSOUsers = (
  tenant_id: string,
  page: number,
  queryOptions: Partial<UseQueryOptions<PaginatedResponse<TenantUserManagementListingUser[]>>> = {}
): UseQueryResult<PaginatedResponse<TenantUserManagementListingUser[]>> => (
  useQuery({
    queryKey: ['userManagementPendingSSOUsers', tenant_id, page],
    queryFn: makeClientQueryFn<PaginatedResponse<TenantUserManagementListingUser[]>>(apiClients.core, {
      url: `/tenant-management/${tenant_id}/users/pending-sso-users`,
      params: { page }
    }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: false,
    ...queryOptions,
  })
)


export const useTenantUser = (
  tenant_id: string,
  user_id: string | null,
  queryOptions: Partial<UseQueryOptions<TenantUserManagementListingUser>> = {}
): UseQueryResult<TenantUserManagementListingUser> => (
  useQuery({
    queryKey: ['tenantUser', tenant_id, user_id],
    queryFn: makeClientQueryFn<TenantUserManagementListingUser>(
      apiClients.core,
      { url: `/tenant-management/${tenant_id}/users/established-users/${user_id}` },
    ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!user_id,
    retry: false,
    ...queryOptions,
  })
)


type CreateUserResponse = {
  user_id: string
  enabled: true
  default_org_unit_id: string
  tenant_org_unit_id: string
  name: string
  email: string
  previous_login: string | null
}

type CreateUserError = {
  detail: {
    cognito_id: string | null
    user_id: string
  }
}


export const useTenantUserManagementCreateUser = (
  tenant_id: string
): UseMutationResult<CreateUserResponse, AxiosError<CreateUserError>, CreateTenantUserPayload> => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  return useMutation({
    mutationKey: ['userManagementCreateUser', tenant_id],
    mutationFn: async (payload: CreateTenantUserPayload) => {
      const response = await apiClients.core.post<CreateUserResponse>(
        `/tenant-management/${tenant_id}/users/established-users`,
        payload
      )
      return response.data
    },
    onMutate: (payload): void => {
      dispatch(
        notifications.actions.addNotification({
          id: 'create-user',
          type: 'info',
          name: 'Creating User',
          loading: true,
          ttl: 0,
          message: `Creating user ${payload.email}...`,
        })
      )
    },
    onError: (error, payload): void => {
      const errorMessage = (
        error.response && error.response.status === 409 ?
          'user already exists' :
          error.message
      )
      dispatch(
        notifications.actions.addNotification({
          id: 'create-user',
          type: 'error',
          name: 'User Creation Failed',
          ttl: 5000,
          loading: false,
          message: `User ${payload.email} could not be created - ${errorMessage}`,
        })
      )
    },
    onSuccess: (response): void => {
      queryClient.invalidateQueries(['userManagementEstablishedUsers', tenant_id])
      dispatch(
        notifications.actions.addNotification({
          id: 'create-user',
          type: 'success',
          name: 'User Created',
          loading: false,
          message: `User ${response.email} was successfully created`,
        })
      )
    }
  })
}


type CreateSSOUserResponse = Omit<CreateUserResponse, 'name' | 'previous_login'>


export const useTenantUserManagementCreateSSOUser = (
  tenant_id: string
): UseMutationResult<CreateSSOUserResponse, AxiosError<CreateUserError>, CreateTenantUserPayload> => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  return useMutation({
    mutationKey: ['userManagementCreateSSOUser', tenant_id],
    mutationFn: async (payload: CreateTenantUserPayload) => {
      const response = await apiClients.core.post<CreateSSOUserResponse>(
        `/tenant-management/${tenant_id}/users/pending-sso-users`,
        payload
      )
      return response.data
    },
    onMutate: (payload): void => {
      dispatch(
        notifications.actions.addNotification({
          id: 'create-user',
          type: 'info',
          name: 'Creating User',
          loading: true,
          ttl: 0,
          message: `Creating user ${payload.email}...`,
        })
      )
    },
    onError: (error, payload): void => {
      const errorMessage = (
        error.response && error.response.status === 409 ?
          'user already exists' :
          error.message
      )
      dispatch(
        notifications.actions.addNotification({
          id: 'create-user',
          type: 'error',
          name: 'User Creation Failed',
          ttl: 5000,
          loading: false,
          message: `User ${payload.email} could not be created - ${errorMessage}`,
        })
      )
    },
    onSuccess: (response): void => {
      queryClient.invalidateQueries(['userManagementPendingSSOUsers', tenant_id])
      dispatch(
        notifications.actions.addNotification({
          id: 'create-user',
          type: 'success',
          name: 'User Created',
          loading: false,
          message: `User ${response.email} was successfully created`,
        })
      )
    },
  })
}


export const useEditTenantUser = (
  tenant_id: string
): UseMutationResult<CreateSSOUserResponse, AxiosError, EditTenantUserPayload> => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  return useMutation({
    mutationKey: ['userManagementEditTenantUser', tenant_id],
    mutationFn: async ({ user_id, ...payload }: EditTenantUserPayload) => {
      const response = await apiClients.core.put<CreateSSOUserResponse>(
        `/tenant-management/${tenant_id}/users/established-users/${user_id}`,
        payload
      )
      return response.data
    },
    onError: (error, payload): void => {
      dispatch(
        notifications.actions.addNotification({
          type: 'error',
          name: 'User Edit Failed',
          ttl: 0,
          message: `User ${payload.email} could not be edited - ${error.message}`,
        })
      )
    },
    onSuccess: (response): void => {
      queryClient.invalidateQueries(['userManagementEstablishedUsers', tenant_id])
      queryClient.invalidateQueries(['tenantUser', tenant_id, response.user_id])
      dispatch(
        notifications.actions.addNotification({
          type: 'success',
          name: 'User Edited',
          message: `User ${response.email} was successfully edited`,
        })
      )
    }
  })
}


export const useEditPendingSSOUser = (
  tenant_id: string
): UseMutationResult<CreateSSOUserResponse, AxiosError, EditTenantUserPayload> => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  return useMutation({
    mutationKey: ['userManagementEditPendingSSOUser', tenant_id],
    mutationFn: async ({ user_id, ...payload }: EditTenantUserPayload) => {
      const response = await apiClients.core.put<CreateSSOUserResponse>(
        `/tenant-management/${tenant_id}/users/pending-sso-users/${user_id}`,
        payload
      )
      return response.data
    },
    onError: (error, payload): void => {
      dispatch(
        notifications.actions.addNotification({
          type: 'error',
          name: 'User Edit Failed',
          ttl: 0,
          message: `User ${payload.email} could not be edited - ${error.message}`,
        })
      )
    },
    onSuccess: (response): void => {
      queryClient.invalidateQueries(['userManagementPendingSSOUsers', tenant_id])
      queryClient.invalidateQueries(['tenantUser', tenant_id, response.user_id])
      dispatch(
        notifications.actions.addNotification({
          type: 'success',
          name: 'User Edited',
          message: `User ${response.email} was successfully edited`,
        })
      )
    }
  })
}
