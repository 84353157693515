import React from 'react'

import { Divider, makeAppStyles } from '@percept/mui'

import {
  Instagram,
  Facebook,
  Twitter,
  YouTube,
  SvgIconComponent,
} from '@percept/mui/icons'

import { FooterLinks } from './FooterLinks'

import logoInverted from '../../images/logos/vodafone-logo-short-inverted.svg'


const useFooterStyles = makeAppStyles( theme => ({
  footer: {
    width: '100vw',
    padding: theme.spacing(3),
    flexGrow: 0,
    flexShrink: 0,
    color: 'white',
    backgroundColor: 'black', 
    fontSize: 14,
    fontWeight: 500,
  },
  logo: {
    width: 40,
    height: 40,
    margin: theme.spacing(1, 0),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  flexLeft: {
    marginLeft: 'auto',
  },
  socialIcon: {
    color: 'white',
    marginRight: theme.spacing(2),
    transition: theme.transitions.create('color'),
    '&:hover': {
      color: '#bbb',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  divider: {
    color: theme.palette.text.disabled,
    backgroundColor: '#7e7e7e',
    width: '100%',
    margin: theme.spacing(3, 0),
  },
  disclaimer: {
    textAlign: 'center',
  },
  copyright: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  }
}))


const socialIcons: { href: string, IconComponent: SvgIconComponent}[] = [
  {
    href: 'https://www.instagram.com/vodafone/',
    IconComponent: Instagram,
  },
  {
    href: 'https://www.youtube.com/vodafonemedia',
    IconComponent: YouTube,
  },
  {
    href: 'https://twitter.com/VodafoneGroup',
    IconComponent: Twitter,
  },
  {
    href: 'https://www.facebook.com/thevodafonegroup',
    IconComponent: Facebook,
  }
]

export type FooterProps = {
  showApplicationLinks?: boolean
}

export const Footer = ({
  showApplicationLinks = true,
}: FooterProps): JSX.Element => {
  const classes = useFooterStyles()
  return (
    <div className={classes.footer}>
      <div className={classes.flex}>
        <img className={classes.logo} src={logoInverted} /> 
        {/* <div className={classes.flexLeft}>
          { socialIcons.map( ({ href, IconComponent }) => (
            <a
              key={href}
              className={classes.socialIcon}
              target='_blank'
              rel='noopener noreferrer'
              href={href}>
              <IconComponent />
            </a>
          ))}
        </div> */}
      </div>

      <Divider className={classes.divider} />

      { showApplicationLinks && <FooterLinks /> }

      <div className={classes.disclaimer}>
        Vodafone Limited is authorised and regulated by the Financial Conduct Authority for consumer credit lending and
        insurance distribution activity (Financial Services Register Number 71220) Registered in England and Wales.<br /> Company Number 01471587.
        Registered Office: Vodafone House, The Connection, Newbury, Berkshire, RG14 2FN.
      </div>

      <div className={classes.copyright}>
        &copy; {new Date().getFullYear()} Vodafone Limited
      </div>
    </div>
  )
}
