import React from 'react'
import { makeFlagIconComponent } from './lib'


export const DE = makeFlagIconComponent(
  <>
    <path fill="#ffce00" d="M0 341.3h512V512H0z"/>
    <path d="M0 0h512v170.7H0z"/>
    <path fill="#d00" d="M0 170.7h512v170.6H0z"/>
  </>
)
