import { addNotification } from '../notifications/actions'

import { providerLabelMap, providerStringEnumMap } from '@percept/constants'

import {
  AddPerformanceReportingParams,
  AddPlatformUnitParams,
  AddReportSeriesParams,
  AsyncReduxAction,
  EditPlatformUnitParams,
  PaginatedParams,
  PlatformUnitParams,
  ReduxAction,
} from '@percept/types'


const { CORE_API_ROOT, PERFORMANCE_API_ROOT, APP } = process.env

const isAdmin = APP === 'admin'


const createAsyncPlatformUnitAction = (action: AsyncReduxAction): AsyncReduxAction => ({
  ...action,
  options: {
    baseURL: `${CORE_API_ROOT}/v1`,
    ...action.options || {},
  }
})

export const LOAD_PLATFORM_UNITS = 'LOAD_PLATFORM_UNITS'

export const loadPlatformUnits = (params?: PaginatedParams): AsyncReduxAction => (
  createAsyncPlatformUnitAction({
    type: LOAD_PLATFORM_UNITS,
    resource: '/org-units/',
    options: {
      params
    },
    meta: params,
  })
)


export const LOAD_PLATFORM_UNIT = 'LOAD_PLATFORM_UNIT'

export const loadPlatformUnit = ({ org_unit_id }: PlatformUnitParams): AsyncReduxAction => (
  createAsyncPlatformUnitAction({
    type: LOAD_PLATFORM_UNIT,
    resource: `/org-units/${org_unit_id}`,
    options: {
      params: {
        admin: isAdmin,
      },
    },
    meta: {
      org_unit_id,
    },
  })
)


export const LOAD_PLATFORM_UNIT_TREE = 'LOAD_PLATFORM_UNIT_TREE'

export const loadPlatformUnitTree = ({ org_unit_id }: PlatformUnitParams): AsyncReduxAction => (
  createAsyncPlatformUnitAction({
    type: LOAD_PLATFORM_UNIT_TREE,
    resource: `/org-units/${org_unit_id}/tree`,
    meta: {
      org_unit_id,
    },
  })
)


export const ADD_PLATFORM_UNIT = 'ADD_PLATFORM_UNIT'

export const addPlatformUnit = (data: AddPlatformUnitParams): AsyncReduxAction => (
  createAsyncPlatformUnitAction({
    type: ADD_PLATFORM_UNIT,
    resource: '/org-units/',
    options: {
      method: 'POST',
      data,
    },
    successActions: [
      addNotification({
        type: 'success',
        message: `Organisation '${data.name}' successfully created`,
      }),
      loadPlatformUnits(),
      ...(data.parent_id && [
        loadPlatformUnitTree({ org_unit_id: data.parent_id })
      ] || []),
    ]
  })
)

export const RESET_ADD_PLATFORM_UNIT = 'RESET_ADD_PLATFORM_UNIT'

export const resetAddPlatformUnit = (): ReduxAction => ({
  type: RESET_ADD_PLATFORM_UNIT,
})


export const ADD_PLATFORM_UNIT_PERFORMANCE_REPORTING_PROVIDER = 'ADD_PLATFORM_UNIT_PERFORMANCE_REPORTING_PROVIDER'

export const addPlatformUnitPerformanceReportingProvider = (
  { org_unit_id, provider }: PlatformUnitParams & AddPerformanceReportingParams
): AsyncReduxAction => (
  createAsyncPlatformUnitAction({
    type: ADD_PLATFORM_UNIT_PERFORMANCE_REPORTING_PROVIDER,
    resource: `/org-units/${org_unit_id}/performance-reporting`,
    options: {
      method: 'POST',
      data: {
        provider_type: providerStringEnumMap[provider],
      },
    },
    successActions: [
      addNotification({
        type: 'success',
        message: `${providerLabelMap[provider]} performance reporting successfully created`,
      }),
      loadPlatformUnits(),
      loadPlatformUnitTree({ org_unit_id }),
    ],
  })
)

export const RESET_ADD_PLATFORM_UNIT_PERFORMANCE_REPORTING_PROVIDER = 'RESET_ADD_PLATFORM_UNIT_PERFORMANCE_REPORTING_PROVIDER'

export const resetAddPlatformUnitPerformanceReportingProvider = (): ReduxAction => ({
  type: RESET_ADD_PLATFORM_UNIT_PERFORMANCE_REPORTING_PROVIDER,
})


export const ADD_PLATFORM_UNIT_STRUCTURAL_REPORT_SERIES = 'ADD_PLATFORM_UNIT_STRUCTURAL_REPORT_SERIES'

export const addPlatformUnitStructuralReportSeries = (
  { org_unit_id, ...data }: PlatformUnitParams & AddReportSeriesParams
): AsyncReduxAction => (
  createAsyncPlatformUnitAction({
    type: ADD_PLATFORM_UNIT_STRUCTURAL_REPORT_SERIES,
    resource: `/org-units/${org_unit_id}/report-series`,
    options: {
      method: 'POST',
      data,
    },
    successActions: [
      addNotification({
        type: 'success',
        message: `${providerLabelMap[data.provider_type]} report series '${data.name}' successfully created`,
      }),
      loadPlatformUnits(),
      loadPlatformUnitTree({ org_unit_id }),
    ],
  })
)

export const RESET_ADD_PLATFORM_UNIT_STRUCTURAL_REPORT_SERIES = 'RESET_ADD_PLATFORM_UNIT_STRUCTURAL_REPORT_SERIES'

export const resetAddPlatformUnitStructuralReportSeries = (): ReduxAction => ({
  type: RESET_ADD_PLATFORM_UNIT_STRUCTURAL_REPORT_SERIES,
})


export const EDIT_PLATFORM_UNIT = 'EDIT_PLATFORM_UNIT'

export const editPlatformUnit = ({ org_unit_id, ...data }: PlatformUnitParams & Partial<EditPlatformUnitParams>): AsyncReduxAction => (
  createAsyncPlatformUnitAction({
    type: EDIT_PLATFORM_UNIT,
    resource: `/org-units/${org_unit_id}`,
    options: {
      method: 'PATCH',
      data,
    },
    meta: {
      org_unit_id,
    },
    successActions: [
      loadPlatformUnitTree({ org_unit_id }),
    ],
  })
)

export const RESET_EDIT_PLATFORM_UNIT = 'RESET_EDIT_PLATFORM_UNIT'

export const resetEditPlatformUnit = (): ReduxAction => ({
  type: RESET_EDIT_PLATFORM_UNIT,
})


export const DELETE_PLATFORM_UNIT = 'DELETE_PLATFORM_UNIT'

export const deletePlatformUnit = ({ org_unit_id }: PlatformUnitParams): AsyncReduxAction => (
  createAsyncPlatformUnitAction({
    type: DELETE_PLATFORM_UNIT,
    resource: `/org-units/${org_unit_id}`,
    options: {
      method: 'DELETE',
    },
    meta: {
      org_unit_id,
    },
  })
)

export const RESET_DELETE_PLATFORM_UNIT = 'RESET_DELETE_PLATFORM_UNIT'

export const resetDeletePlatformUnit = (): ReduxAction => ({
  type: RESET_DELETE_PLATFORM_UNIT,
})


export const LOAD_PLATFORM_UNIT_PROVIDER_INFO = 'LOAD_PLATFORM_UNIT_PROVIDER_INFO'

export const loadPlatformUnitProviderInfo = ({
  org_unit_id,
}: PlatformUnitParams): AsyncReduxAction => ({
  type: LOAD_PLATFORM_UNIT_PROVIDER_INFO,
  resource: `/meta/provider-info/${org_unit_id}`,
  // Platform unit provider info is sourced from the performance API
  options: {
    baseURL: `${PERFORMANCE_API_ROOT}/v1`,
  },
  meta: {
    org_unit_id,
  },
})
