
import { createApiReducer, createKeyedReducer } from '../../../reducers'

import { getEntityKeyFromAction, getReportKeyFromAction } from '../../../utils'

import {
  LOAD_CLIENT_REPORT_ENTITY,
} from '../actions'  

import { Reducer } from '@percept/types'

import { ClientReportState } from '../typings'

// import { reportPayload } from '@percept/api/adapters'


const entities: Reducer<ClientReportState['entities']> = createKeyedReducer(
  createKeyedReducer(
    createApiReducer(LOAD_CLIENT_REPORT_ENTITY, {
      // #TODO: Adapter required? 
      getPayloadFromAction: ({ payload }) => (
        payload
      )
    }),
    getEntityKeyFromAction
  ),
  getReportKeyFromAction
)

export default entities
