
import { AsyncReduxAction } from '@percept/types'

export const LOAD_LAYOUTS = 'LOAD_LAYOUTS'
export const LOAD_LAYOUT = 'LOAD_LAYOUT'


export const loadLayouts = (): AsyncReduxAction => ({
  type: LOAD_LAYOUTS,
  resource: '/report-layouts'
})

export const loadLayout = ({ report_layout_id }: { report_layout_id: string }): AsyncReduxAction => ({
  type: LOAD_LAYOUT,
  resource: `/report-layouts/${report_layout_id}`,
  meta: { report_layout_id }
})
