import {
  CreateTenantUserPayload,
  Dictionary,
  TenantUserAttribute,
  TenantUserAttributeSelect,
} from '@percept/types'

import { TenantUserManagementListingUser } from '@percept/hooks'


export const isTenantUserAttributeSelect = (attribute: TenantUserAttribute): attribute is TenantUserAttributeSelect => (
  !!(attribute as TenantUserAttributeSelect).values
)

export const isBlueprint = (attributes: TenantUserAttribute[], values: Dictionary): boolean => {
  for( const attribute of attributes ){
    if( isTenantUserAttributeSelect(attribute) ){
      const currentValue = values[attribute.id]
      for( const option of attribute.values ){
        if( option.blueprint && option.id === currentValue ){
          return true
        }
      }
    }
  }

  return false
}

export const isVodafoneGroupUser = (attributes: TenantUserAttribute[], values: Dictionary): boolean => {
  for( const attribute of attributes ){
    if( isTenantUserAttributeSelect(attribute) ){
      const currentValue = values[attribute.id]
      for( const option of attribute.values ){
        if( option.slug === 'vodafone-group' && option.id === currentValue ){
          return true
        }
      }
    }
  }

  return false
}


export const getInitialValues = ({
  attributes,
  options,
  existingUserObject,
}: {
  attributes: TenantUserAttribute[]
  options: TenantUserAttributeSelect[]
  existingUserObject: TenantUserManagementListingUser | null | undefined
}): Dictionary => {
  const initialAttributeValues = attributes.reduce((acc, attr) => {
    if( attr.enabled ){
      let value = ''
      if( existingUserObject ){
        if( ['name', 'email'].includes(attr.slug) ){
          value = existingUserObject[attr.slug as 'name' | 'email'] || ''
        }else if( isTenantUserAttributeSelect(attr) ){
          const match = existingUserObject.user_attributes.find( a => a.group.slug === attr.slug )
          if( match ){
            const matchedValue = attr.values.find( v => v.slug === match.value.slug )
            value = matchedValue && matchedValue.id || ''
          }
        }
        acc[attr.id] = value
      }
    }
    return acc
  }, {} as Dictionary)

  const initialOptionValues = options.reduce((acc, attr) => {
    if( attr.enabled ){
      const value: string[] = []
      if( existingUserObject && isTenantUserAttributeSelect(attr) ){
        const match = existingUserObject.user_options.find( a => a.group.slug === attr.slug )
        if( match ){
          for( const v of match.values ){
            const match = attr.values.find( attrValue => attrValue.slug === v.slug )
            if( match ){
              value.push(match.id)
            }
          }
        }
      }
      acc[attr.id] = value
    }
    return acc
  }, {} as Dictionary)

  return {
    enabled: existingUserObject ? existingUserObject.enabled : true,
    ...initialAttributeValues,
    ...initialOptionValues,
  }
}


type AttributeGroupValue = {
  attribute_group_id: string
  attribute_value_id: string
}

type OptionGroupValue = {
  option_group_id: string
  option_value_ids: string[]
}


export const getPayload = ({
  attributes,
  options,
  formValues,
}: {
  attributes: TenantUserAttribute[]
  options: TenantUserAttributeSelect[]
  formValues: Dictionary
}): CreateTenantUserPayload => {
  return {
    name: formValues.name || '',
    email: formValues.email,
    enabled: !!formValues.enabled,
    user_attributes: attributes.reduce( (acc, attr) => {
      // Skip injected attributes
      if( ['name', 'email'].includes(attr.id) ){
        return acc
      }
      if( attr.enabled ){
        const value = formValues[attr.id]
        if( value ){
          acc.push({ attribute_group_id: attr.id, attribute_value_id: value })
        }
      }
      return acc
    }, [] as AttributeGroupValue[]),
    user_options: options.reduce( (acc, attr) => {
      if( attr.enabled ){
        const value = formValues[attr.id]
        if( value ){
          acc.push({ option_group_id: attr.id, option_value_ids: value })
        }
      }
      return acc
    }, [] as OptionGroupValue[]),
  }
}
