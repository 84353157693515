import React, { Fragment, useEffect, useState } from 'react'

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  Typography,
  ButtonProps,
  RoundedPlainTextButton,
  Box,
  LinearProgress,
  CircularProgress,
  makeAppStyles,
  Chip,
  AppBar,
  Alert,
} from '@percept/mui'

import { useDropzone, Accept } from 'react-dropzone'

import {
  Close,
  Check,
  InsertDriveFile,
  CheckCircleOutline,
  ErrorOutline,
  WarningOutlined,
  Undo,
  Help,
  CloudUpload,
  ChevronRightThin,
} from '@percept/mui/icons'

import { get, some, sortBy } from 'lodash-es'

import { SmartCampaignUploadResponse, useSmartCampaignAssesmentUpload } from './hooks'


export type SmartCampaignAssessmentUploadProps = {
  ButtonProps?: ButtonProps
}

const acceptSpreadsheet: Accept = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xslx'],
}


const VODAFONE_SUPPORT_EMAIL = 'vodafone@wearepercept.com'


const humanReadableFilesize = (bytes: number, precision = 1): string => {
  const thresh = 1000

  if (Math.abs(bytes) < thresh) {
    return bytes + 'B'
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let u = -1
  const r = 10**precision

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return bytes.toFixed(precision) + ' ' + units[u]
}


const useStatusStyles = makeAppStyles(theme => ({
  info: {
    color: theme.palette.info.main
  },
  warning: {
    color: theme.palette.warning.main
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
}))


const collapseErrorContainers = (
  errorContainers: { message: string, location_info: string | null }[]
): { message: string, values: string }[] => {
  const valuesByMessage = errorContainers.reduce( (acc, container) => {
    if( container.location_info ){
      acc[container.message] = acc[container.message] || []
      acc[container.message].push(container.location_info.replace('Near ', ''))
    }
    return acc
  }, {} as Record<string, string[]>)


  return sortBy(
    Object.entries(valuesByMessage).map( ([message, values]) => ({
      message: message.replace(/\.$/, ''),
      values: values.sort().join(', '),
    })),
    'message'
  )
}

const parseUploadFeedback = (
  response: SmartCampaignUploadResponse
): Record<'errors' | 'warnings', { message: string, values: string }[] | null> => {
  let errors = null
  if( response.errors && response.errors.length ){
    errors = collapseErrorContainers(response.errors)
  }
  let warnings = null
  if( response.warnings && response.warnings.length ){
    warnings = collapseErrorContainers(response.warnings)
  }
  return { errors, warnings }
}


const UploadFeedback = ({
  response
}: {
  response: SmartCampaignUploadResponse
}): JSX.Element => {

  let statusFeedback: JSX.Element | null = null

  const { warnings, errors } = parseUploadFeedback(response)

  const isError = !!errors

  const classes = useStatusStyles()

  if( !isError ){
    statusFeedback = (
      <Typography paragraph>
        <strong>
          Your upload was successful!
        </strong>
      </Typography>
    )
  }else if( errors || warnings ){
    statusFeedback = (
      <>
        { errors && (
          <>
            <Typography variant='h6' className={classes.error}>Errors</Typography>
            <ul style={{fontSize: 14, marginLeft: -40, marginTop: 4}}>
              { errors.map( (e, i) => (
                <li style={{display: 'flex', alignItems: 'center'}} key={`error-${i}`}>
                  { e.message }
                  <ChevronRightThin
                    style={{
                      fontSize: 12,
                      color: 'rgba(0,0,0,0.5)',
                      margin: '0 4px',
                    }} />
                  <strong>
                    { e.values }
                  </strong>
                </li>
              ))}
            </ul>
          </>
        )}
        { warnings && (
          <>
            <Typography variant='h5' className={classes.warning}>Warnings</Typography>
            <ul style={{fontSize: 14, marginLeft: -40, marginTop: 4}}>
              { warnings.map( (e, i) => (
                <li style={{display: 'flex', alignItems: 'center'}} key={`warning-${i}`}>
                  { e.message }
                  <ChevronRightThin
                    style={{
                      fontSize: 12,
                      color: 'rgba(0,0,0,0.5)',
                      margin: '0 4px',
                    }} />
                  <strong>
                    { e.values }
                  </strong>
                </li>
              ))}
            </ul>
          </>
        )}
      </>
    )
  }else{
    const filename = get(response, ['file_metadata', 'filename'], 'Smart Campaign Results file')
    statusFeedback = (
      <Fragment>
        <Typography>
          <strong>{filename}</strong> could not be uploaded or contains errors
        </Typography>
      </Fragment>
    )
  }

  return statusFeedback
}


const useStyles = makeStyles( theme => ({
  appBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
  },
  content: {
    paddingTop: theme.spacing(3),
  },
  dropzone: {
    minHeight: '10rem',
    minWidth: '20rem',
    padding: theme.spacing(3),
    margin: theme.spacing(4, 0),
    borderRadius: theme.shape.borderRadius,
    border: 'dashed 3px',
    color: theme.palette.action.disabled,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  fileChip: {
    margin: theme.spacing(4, 0, 2, 0),
  },
}) )

export const SmartCampaignAssessmentUpload = ({
  ButtonProps = {},
}: SmartCampaignAssessmentUploadProps): JSX.Element => {

  const mutationHook = useSmartCampaignAssesmentUpload()

  const [view, setView] = useState<'upload' | 'status'>('upload')

  const [file, setFile] = useState<File | null>(null)

  const onDrop = ([file]: File[]): void => {
    if( file ){
      setFile(file)
      mutationHook.reset()
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptSpreadsheet,
  })

  const [open, setOpen] = useState(false)

  const onClose = (): void => {
    setOpen(false)
    setFile(null)
    mutationHook.reset()
    setView('upload')
  }

  const classes = useStyles()

  const statusClasses = useStatusStyles()

  let content = null

  const mutationData = mutationHook.data

  const isPartialSuccess = !!(
    mutationData && some(
      ['warnings', 'conflicts', 'errors'],
      key => !!(
        get(mutationData, ['warnings', key, 'length'])
      )
    )
  )

  if( mutationHook.data ){
    content = (
      <Box pb={2}>
        <Typography
          className={isPartialSuccess ? statusClasses.warning : statusClasses.success}
          variant='h5'
          style={{display: 'flex', alignItems: 'center'}}>
          { isPartialSuccess ? (
            <WarningOutlined color='inherit' style={{marginRight: 6}} />
          ): (
            <CheckCircleOutline color='inherit' style={{marginRight: 6}} />
          )}
          { isPartialSuccess ? 'Upload Partially Succeeded' : 'Upload Successful'}
        </Typography>
        <Box pt={2}>
          <UploadFeedback response={mutationHook.data} />
        </Box>
      </Box>
    )
  }else if( mutationHook.isLoading ){
    const { loaded, total } = mutationHook.progress
    const progressPercent = (loaded / total) * 100
    content = (
      <Box pt={2} pb={2}>
        <Typography className={statusClasses.info} variant='h5' style={{display: 'flex', alignItems: 'center'}}>
          <CircularProgress size='1.25rem' color='inherit' style={{marginRight: 6}} />
          { file ?
            `Uploading ${file.name}` :
            'Uploading File'
          }
        </Typography>
        <Box py={8}>
          <Typography variant='subtitle1' paragraph>
            Uploaded {humanReadableFilesize(loaded)} / {humanReadableFilesize(total)}
          </Typography>
          <LinearProgress variant='determinate' value={progressPercent} />
        </Box>
      </Box>
    )
  }else if( mutationHook.error ){
    // NOTE - errors that aren't upload failures, but specifically unexpected exceptions such as internal server errors,
    // present with a garbled string payload under `response.data`, rather than a response which tells us about the
    // upload failure. So we check for this and conditionally show just the error message before assuming we have a valid
    // upload failure response. Also we check for falsy response object and fallback to a message or suitable default.
    const isServerError = (
      mutationHook.error.response && typeof mutationHook.error.response.data === 'string'
    )
    content = (
      <Box pb={2}>
        <Typography className={statusClasses.error} variant='h5' style={{display: 'flex', alignItems: 'center'}}>
          <ErrorOutline color='inherit' style={{marginRight: 6}} />
          Upload Failed
        </Typography>
        <Box pt={2}>
          { isServerError ? (
            <Typography paragraph>
              { mutationHook.error.message }
            </Typography>
          ) : mutationHook.error.response ? (
            <UploadFeedback response={mutationHook.error.response.data} />
          ) : (
            <Typography paragraph>
              { mutationHook.error.message || 'Server error' }
            </Typography>
          )}
          <Alert
            icon={<Help style={{marginRight: 6}} />}
            display='inline-flex'
            px={1.5}
            mt={2}
            mb={3}
            message={
              <>
                Email us at <strong><a href={`mailto:${VODAFONE_SUPPORT_EMAIL}`}>{ VODAFONE_SUPPORT_EMAIL }</a></strong>{' '}
                if you require any assistance with your upload
              </>
            } />
        </Box>
      </Box>
    )
  }

  const success = mutationHook.data && mutationHook.data.errors.length === 0

  useEffect(() => {
    if( success ){
      setFile(null)
    }
  }, [success])

  return (
    <Fragment>

      <RoundedPlainTextButton
        variant='contained'
        startIcon={
          <CloudUpload />
        }
        {...ButtonProps}
        onClick={(): void => setOpen(true)}>
        Upload Data
      </RoundedPlainTextButton>

      <Dialog
        open={open}
        maxWidth='md'
        fullWidth
        disableEscapeKeyDown
        disableBackdropClick
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
        onClose={onClose}>

        <AppBar
          className={classes.appBar}
          color='primary'
          position='relative'>
          <Typography variant='h4'>
            Upload Smart Campaign Results File
          </Typography>
        </AppBar>

        <DialogContent className={classes.content}>

          { mutationHook.isLoading && (
            <Typography paragraph>
              We are uploading your file, we will tell you when the process is complete. 
              Please, do not close this page whilst we run this operation
            </Typography>
          )}

          { content }

          { mutationHook.isError && (
            <RoundedPlainTextButton
              variant='contained'
              startIcon={<Undo />}
              onClick={(): void => {
                mutationHook.reset()
                setFile(null)
                setView('upload')
              }}>
              Try Another File
            </RoundedPlainTextButton>
          )}

          { view === 'upload' && (
            <div
              className={classes.dropzone}
              {...getRootProps()}>
              <input
                {...getInputProps()} />
              <Typography
                color='inherit'
                variant='h4'>
                Drag the Smart Campaign Results file here, or click to select a file
              </Typography>
              { file && (
                <Chip
                  className={classes.fileChip}
                  color='secondary'
                  size='medium'
                  icon={<InsertDriveFile />}
                  label={
                    <span style={{display: 'flex', alignItems: 'center'}}>
                      <Typography variant='subtitle2'>
                        {file.name}
                      </Typography>
                      <Typography variant='subtitle1' style={{marginLeft: 6}}>
                        {humanReadableFilesize(file.size)}
                      </Typography>
                    </span>
                  } />
              )}
            </div>
          )}

        </DialogContent>

        <DialogActions>
          <Button
            variant='contained'
            color='default'
            disabled={mutationHook.isLoading}
            startIcon={
              <Close />
            }
            onClick={onClose}>
            { view === 'upload' ? 'Cancel' : 'Close' }
          </Button>

          { view === 'upload' && (
            <Button
              variant='contained'
              color='primary'
              disabled={!file || mutationHook.isLoading}
              startIcon={
                <Check />
              }
              onClick={(): void => {
                if( file ){
                  mutationHook.mutate(file)
                  setView('status')
                }
              }}>
              Upload
            </Button>
          )}

        </DialogActions>

      </Dialog>

    </Fragment>
  )
}

