
import React from 'react'

import { SvgIcon, SvgIconProps } from '@material-ui/core'


/* Custom icons */

/* Source SVG should be of size 24px x 24px */ 


export const DoubleVerifyIcon = (props?: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}>
    <path
      d='M.642891 18c-.493158 0-.892857-.41681-.892857-.931277V6.431277c0-.514358.399699-.931277.892857-.931277s.892857.416919.892857.931277v10.637446c0 .514467-.399698.931277-.892857.931277m3.951127 0H3.369582c-.519671 0-.940977-.416701-.940977-.930977s.421306-.930922.940977-.930922h1.224436c2.445384 0 4.434858-1.968493 4.434858-4.387883S7.039402 7.362063 4.594018 7.362063H3.369582c-.519671 0-.940977-.416865-.940977-.931086S2.849911 5.5 3.369582 5.5h1.224436c3.483064 0 6.31673 2.80383 6.31673 6.250218S8.077082 18 4.594018 18m12.992293-.000002c-.344304 0-.673948-.199018-.829152-.538063L11.889442 6.82487c-.21335-.466129-.015313-1.020132.442287-1.237461s1.001304-.015613 1.214709.450598l4.867826 10.637065c.213242.466129.015204 1.02005-.442341 1.237543-.124796.059264-.256294.087383-.385612.087383m1.728497-3.571429c-.125667 0-.253133-.028174-.374549-.087683-.444249-.217765-.636455-.772776-.429208-1.239641l3.135082-7.062476c.20741-.466864.735364-.669016 1.179613-.450979s.636401.772667.429154 1.239559l-3.135082 7.062558c-.150626.339235-.470679.538662-.80501.538662'
    />
  </SvgIcon>
)

export const QrCodeIcon = (props?: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}>
    <path d="M3,11h8V3H3V11z M5,5h4v4H5V5z"/><path d="M3,21h8v-8H3V21z M5,15h4v4H5V15z"/><path d="M13,3v8h8V3H13z M19,9h-4V5h4V9z"/><rect height="2" width="2" x="19" y="19"/><rect height="2" width="2" x="13" y="13"/><rect height="2" width="2" x="15" y="15"/><rect height="2" width="2" x="13" y="17"/><rect height="2" width="2" x="15" y="19"/><rect height="2" width="2" x="17" y="17"/><rect height="2" width="2" x="17" y="13"/><rect height="2" width="2" x="19" y="15"/>
  </SvgIcon>
)


export const PasswordIcon = (props?: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}>
    <path d="M0,0h24v24H0V0z" fill="none"/>
    <path d="M2,17h20v2H2V17z M3.15,12.95L4,11.47l0.85,1.48l1.3-0.75L5.3,10.72H7v-1.5H5.3l0.85-1.47L4.85,7L4,8.47L3.15,7l-1.3,0.75 L2.7,9.22H1v1.5h1.7L1.85,12.2L3.15,12.95z M9.85,12.2l1.3,0.75L12,11.47l0.85,1.48l1.3-0.75l-0.85-1.48H15v-1.5h-1.7l0.85-1.47 L12.85,7L12,8.47L11.15,7l-1.3,0.75l0.85,1.47H9v1.5h1.7L9.85,12.2z M23,9.22h-1.7l0.85-1.47L20.85,7L20,8.47L19.15,7l-1.3,0.75 l0.85,1.47H17v1.5h1.7l-0.85,1.48l1.3,0.75L20,11.47l0.85,1.48l1.3-0.75l-0.85-1.48H23V9.22z"/>
  </SvgIcon>
)


export const HandshakeIcon = (props?: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}>
    <path d='M11.875 20q.1 0 .2-.05t.15-.1l8.2-8.2q.3-.3.438-.675.137-.375.137-.75 0-.4-.137-.763-.138-.362-.438-.637l-4.25-4.25q-.275-.3-.637-.438Q15.175 4 14.775 4q-.375 0-.75.137-.375.138-.675.438l-.275.275 1.85 1.875q.375.35.55.8.175.45.175.95 0 1.05-.712 1.762-.713.713-1.763.713-.5 0-.962-.175-.463-.175-.813-.525L9.525 8.4 5.15 12.775q-.075.075-.113.163-.037.087-.037.187 0 .2.15.362.15.163.35.163.1 0 .2-.05t.15-.1l3.4-3.4 1.4 1.4-3.375 3.4q-.075.075-.113.162-.037.088-.037.188 0 .2.15.35.15.15.35.15.1 0 .2-.05t.15-.1l3.4-3.375 1.4 1.4-3.375 3.4q-.075.05-.112.15-.038.1-.038.2 0 .2.15.35.15.15.35.15.1 0 .188-.038.087-.037.162-.112l3.4-3.375 1.4 1.4-3.4 3.4q-.075.075-.112.162-.038.088-.038.188 0 .2.163.35.162.15.362.15Zm-.025 2q-.925 0-1.637-.613-.713-.612-.838-1.537-.85-.125-1.425-.7-.575-.575-.7-1.425-.85-.125-1.412-.712-.563-.588-.688-1.413-.95-.125-1.55-.825-.6-.7-.6-1.65 0-.5.188-.963.187-.462.537-.812l5.8-5.775L12.8 8.85q.05.075.15.112.1.038.2.038.225 0 .375-.137.15-.138.15-.363 0-.1-.037-.2-.038-.1-.113-.15L9.95 4.575q-.275-.3-.638-.438Q8.95 4 8.55 4q-.375 0-.75.137-.375.138-.675.438L3.6 8.125q-.225.225-.375.525-.15.3-.2.6-.05.3 0 .612.05.313.2.588l-1.45 1.45q-.425-.575-.625-1.263Q.95 9.95 1 9.25q.05-.7.35-1.363.3-.662.825-1.187L5.7 3.175Q6.3 2.6 7.038 2.3 7.775 2 8.55 2t1.512.3q.738.3 1.313.875l.275.275.275-.275q.6-.575 1.337-.875Q14 2 14.775 2q.775 0 1.513.3.737.3 1.312.875L21.825 7.4q.575.575.875 1.325.3.75.3 1.525 0 .775-.3 1.512-.3.738-.875 1.313l-8.2 8.175q-.35.35-.813.55-.462.2-.962.2ZM9.375 8Z' />
  </SvgIcon>
)


export const ChevronRightThin = (props?: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}>
    <path
      fill='none'
      stroke={props && props.fill || 'currentColor'}
      strokeLinecap='round'
      shapeRendering='auto'
      d='M7 2 L17 12 7 22'
    />
  </SvgIcon>
)


export const ChevronLeftThin = (props?: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}>
    <path
      fill='none'
      stroke={props && props.fill || 'currentColor'}
      strokeLinecap='round'
      shapeRendering='auto'
      d='M17 2 L7 12 17 22'
    />
  </SvgIcon>
)

export const AsteriskIcon = (props?: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}>
    <polygon
      points="20.79,9.23 18.79,5.77 14,8.54 14,3 10,3 10,8.54 5.21,5.77 3.21,9.23 8,12 3.21,14.77 5.21,18.23 10,15.46 10,21 14,21 14,15.46 18.79,18.23 20.79,14.77 16,12"/>
  </SvgIcon>
)

/*
export const Icon = (props?: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}>
    <path
      d=''
    />
  </SvgIcon>
)
*/
