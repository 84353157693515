import React from 'react'
import { makeFlagIconComponent } from './lib'


export const QA = makeFlagIconComponent(
  <>
    <path fill="#8d1b3d" d="M0 0h512v512H0z"/>
    <path fill="#fff" d="M0 0v512h113l104.2-28.4L113 455l104.2-28.4L113 398.2l104.2-28.4L113 341.3 217.2 313 113 284.4 217.2 256 113 227.6 217.2 199 113 170.7l104.2-28.5L113 113.8l104.2-28.5L113 57l104.2-28.4L113 0H0z"/>
  </>
)
