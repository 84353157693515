
import { createApiReducer } from '@percept/redux/reducers'

import { LOAD_AUDIT_PAYLOAD } from '../actions'

import { auditPayload } from '@percept/api/adapters'


const getPayloadFromAction = ({ payload }) => auditPayload(payload)


export default createApiReducer(LOAD_AUDIT_PAYLOAD, { getPayloadFromAction })

export const raw = createApiReducer(LOAD_AUDIT_PAYLOAD)
