
import actions from './actions'
import reducer, { raw as rawReducer } from './reducer'
import selectors, { makeSelectors } from './selectors'

import { ReduxBundle } from '@percept/types'


export { actions, reducer, rawReducer, selectors }

export default { actions, reducer, rawReducer, selectors } as ReduxBundle


export const makeBundle = ({ statePath = ['audits'], raw = false }): ReduxBundle => {
  
  return {
    actions,
    reducer: raw ? rawReducer : reducer,
    selectors: makeSelectors({ statePath, raw })
  }

}
