import { makeAppStyles } from '@percept/mui'

import { GridStylesClassKey } from './typings'


export const useGridStyles = makeAppStyles<{}, GridStylesClassKey>((theme) => ({
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  stretchGridItem: {
    alignSelf: 'stretch',
  },
  fullHeight: {
    height: '100%',
  },
  flexGrow: {
    flexGrow: 1,
  },
  cardTitle: {
    marginBottom: theme.spacing(3),
  },
  card: {
    flexGrow: 1,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  stretchCardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    height: '100%',
  },
}))
