import { createContext } from 'react'

import { noop } from 'lodash-es'

import { BGPTab } from './tabs'

import { PrefillPlaceholder } from './api/types'


export type BrandGrowthPlannerContextValue = {
  isGlobalUser: boolean
  marketId: string
}

export const BrandGrowthPlannerContext = createContext<BrandGrowthPlannerContextValue>({
  isGlobalUser: false,
  marketId: '',
})


export type FormSubmissionContextValue = {
  onSubmit: () => void
}

export const FormSubmissionContext = createContext<FormSubmissionContextValue>({
  onSubmit: noop,
})


export type QuestionContextValue = {
  questionValues: Record<string, unknown>
  onQuestionChange: (questionId: string, value: unknown) => void
}

export const QuestionContext = createContext<QuestionContextValue>({
  questionValues: {},
  onQuestionChange: noop,
})


export type BrandGrowthPlannerTabContextValue = {
  navigateToTab: (tab: BGPTab) => void
}

export const BrandGrowthPlannerTabContext = createContext<BrandGrowthPlannerTabContextValue>({
  navigateToTab: noop
})


export type ReportSchemaContextValue = {
  prefillPlaceholders: PrefillPlaceholder[]
}

export const ReportSchemaContext = createContext<ReportSchemaContextValue>({
  prefillPlaceholders: []
})
