
import {
  SET_SEARCH_QUERY,
  SAVE_SEARCH_QUERY
} from '../actions'


const initialState = {
  query: '',
  saved: []
}


export default (state = initialState, action) => {
  switch(action.type){

    case SET_SEARCH_QUERY:
      return { ...state, query: action.payload }
    
    case SAVE_SEARCH_QUERY:
      return { ...state, saved: [ action.payload, ...state.saved ] }

    default:
      return state

  }
}
