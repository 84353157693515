import React from 'react'

import { animated, OpaqueInterpolation } from 'react-spring'

import { round } from 'lodash-es'
import { useAppTheme } from '../../themes'


type ValueFormatter = (n: number) => React.ReactNode


export type AnimatedPercentageProps = (
  {
    value: OpaqueInterpolation<number>
    color?: string | OpaqueInterpolation<string>
    formatter?: ValueFormatter
  } &
  Pick<
    React.CSSProperties,
    'fontSize'
  > & Partial<
    Omit<
      React.HTMLAttributes<HTMLSpanElement>,
      'children' | 'style'
    >
  >
)


const defaultPercentageFormatter: ValueFormatter = (value) => {
  return `${round(value, 0)}%`
}


export function AnimatedPercentage({
  value,
  color = 'inherit',
  fontSize = '1em',
  formatter = defaultPercentageFormatter,
  ...props
}: AnimatedPercentageProps): JSX.Element {

  const appTheme = useAppTheme()

  return (
    <animated.span
      {...props}
      style={{
        fontWeight: appTheme.typography.fontWeightBold,
        fontSize,
        color
      }}>

      { value.interpolate(formatter) }

    </animated.span>
  )
}
