import React from 'react'
import { makeFlagIconComponent } from './lib'


export const CZ = makeFlagIconComponent(
  <>
    <path fill="#fff" d="M0 0h512v256H0z"/>
    <path fill="#d7141a" d="M0 256h512v256H0z"/>
    <path fill="#11457e" d="M300 256 0 56v400z"/>
  </>
)
