import React, { useState } from 'react'
import {
  Box,
  Card,
  Divider,
  Grid,
  PlainTextButton,
  RoundedPlainTextButtonMenu,
  Typography,
  capitalize,
  formatMoney,
  makeAppStyles,
} from '@percept/mui'
import { ArrowDropDown, ArrowRight, CloudDownload } from '@percept/mui/icons'
import { ParentSize } from '@visx/responsive'
import { StackedBarChart } from 'dashboard/src/components/ReportingDashboard/Charts'
import { AssetLink } from 'components/AssetLink'
import { Market, marketsJSON } from './graphData'

const useStyles = makeAppStyles( theme => ({
  graphMiddleHeader: {
    textAlign: 'center',
    backgroundColor: theme.palette.grey[300],
    padding: '5px',
  },
  graphHeader:{
    paddingLeft: '45px',
    paddingRight: '45px'
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  euroSymbol: {
    height: '12px',
    width: '12px'
  },
  infoBox: {
    fontSize: '18px'
  },
  container: {
    padding: '16px',
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  detailTypography: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    marginBottom: theme.spacing(1.5),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}))

export type MMMMarketProps = {
  isGlobalUser: boolean
  defaultMarketId: string
}

export const MMMMarket = ({
  isGlobalUser,
  defaultMarketId,
}: MMMMarketProps): JSX.Element => {

  const initialMarketId = isGlobalUser ? marketsJSON[0].id : defaultMarketId

  const [marketId, setMarketId] = useState<string>(initialMarketId)

  const market = marketId && marketsJSON.find( m => m.id === marketId ) || null

  return (
    <Card elevation={2}>
      <Box display='flex' alignItems='center' justifyContent='space-between' m={1.5}>
        <Typography variant='h4'>Directional Recommendation</Typography>
        { isGlobalUser && (
          <RoundedPlainTextButtonMenu
            TriggerProps={{
              color: 'primary',
              variant: 'contained',
              size: 'medium',
              startIcon: <ArrowDropDown />,
            }}
            value={market}
            label={market ? capitalize(market.market) : 'Select market'}
            options={marketsJSON.map((option: Market) => ({
              value: option.id,
              label: capitalize(option.market),
            })) || []
            }
            onChange={(_e, value: string): void => {
              setMarketId(value)
            }}
          />
        )}
      </Box>
      <Divider />
      { market ? (
        <MMMGraph market={market} />
      ) : (
        <Box p={8} display='flex' justifyContent='center'>
          <Typography variant='h4'>
            Coming Soon
          </Typography>
        </Box>
      )}
    </Card>
  )
}


export type MMMGraphProps = {
  market: Market
}

export const MMMGraph = ({ market }: MMMGraphProps): JSX.Element => {
  const classes = useStyles()

  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box width='100%' pt={3}>
            <Grid container className={classes.graphHeader}> 
              <Grid item xs={5} className={classes.gridItem}>
                <Typography align='center'>
                  { formatMoney({ amount: 156000000, currency: 'EUR' }) }
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Box className={classes.graphMiddleHeader}>
                  <Typography>Up to 178K contracts</Typography>
                  <Typography>
                    { formatMoney({ amount: 28000000, currency: 'EUR' }) }{' '}
                    annual revenue
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} className={classes.gridItem}>
                <Typography align='center'>
                  { formatMoney({ amount: 156000000, currency: 'EUR' }) }
                </Typography>
              </Grid>
            </Grid>
            <Grid container> 
              <Grid item xs={12}>
                <ParentSize>
                  {({ width }): JSX.Element => (
                    <StackedBarChart
                      dataFormat={'percentage'}
                      dataObject={market.graphData}
                      width={width} />
                  )}
                </ParentSize>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box width='100%' height='100%' display='flex' flexDirection='column' justifyContent='space-between' px={5} pt={3}>
            <Box className={classes.infoBox} height='100%'>
              {market.details.map( el => (
                <Typography key={el} className={classes.detailTypography}>
                  <ArrowRight /> {el}
                </Typography>
              ))}
            </Box>
            { !!market.reportAssetIdentifier && (
              <Box mx={1} my={3}>
                <AssetLink
                  assetIdentifier={market.reportAssetIdentifier}
                  showIcon={false}
                  className=''
                  linkContent={
                    <PlainTextButton
                      color='primary'
                      variant='contained'
                      endIcon={<CloudDownload />}>
                      Download Report
                    </PlainTextButton>
                  } />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
