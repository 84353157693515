
import {
  SET_EXPORT_METRIC,
  CLEAR_EXPORT_METRIC,
  SET_EXPORT_TYPE,
  SET_EXPORT_OPTIONS } from '../actions'


const initialState = {
  exportType: 'CSV',
  metric: null,
  optionsByType: {
    PNG: {
      transparent: false,
      showHealth: false,
      showTitle: false
    },
    CSV: {},
  },
}

export default (state = initialState, action) => {

  switch(action.type){

    case SET_EXPORT_METRIC:
      return { ...state, metric: action.payload }

    case CLEAR_EXPORT_METRIC:
      return { ...state, metric: null }

    case SET_EXPORT_TYPE:
      return { ...state, exportType: action.payload }

    case SET_EXPORT_OPTIONS:
      return {
        ...state,
        optionsByType: {
          ...state.optionsByType,
          [state.exportType]: {
            ...(state.optionsByType[state.exportType] || {}),
            ...action.payload
          }
        }
      }

    default:
      return state

  }

}
