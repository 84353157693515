import { Box, Button, Card, Typography } from '@material-ui/core'
import { makeAppStyles } from '@percept/mui'
import React from 'react'

const useStyles = makeAppStyles((theme) => ({
  modalCard: {
    position: 'absolute',
    width: 430,
    zIndex: 999999,
    left: '50%',
    top: '50%',
    padding: '8px 24px 32px',
    transform: 'translate(-50%, -50%)',
    boxShadow:
      '0px 4px 4px rgba(26, 32, 46, 0.25), 0px 10px 10px rgba(26, 32, 46, 0.25)',
    borderRadius: '8px',
  },
  title: {
    padding: '16px 0',
    borderBottom: `1px solid ${theme.appComponents.table.footer.borderColor}`,
  },
  text: {
    padding: '20px 0',
    borderBottom: `1px solid ${theme.appComponents.table.footer.borderColor}`,
  },
  buttonGroup: {
    paddingTop: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    '& >': {
      textTransform: 'none',
    },
  },
}))
type Props = {
  title: string
  text: string
  submitText: string
  cancelText: string
  handleSubmit: () => void
  handleCancel: () => void
}
export const ConfirmModal = ({
  title,
  text,
  submitText,
  cancelText,
  handleSubmit,
  handleCancel,
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <Card className={classes.modalCard}>
      <Typography variant='h4' className={classes.title}>
        {title}
      </Typography>
      <Typography variant='body1' className={classes.text}>
        {text}
      </Typography>
      <Box className={classes.buttonGroup}>
        <Button onClick={handleCancel}>{cancelText}</Button>
        <Button variant='contained' onClick={handleSubmit}>
          {submitText}
        </Button>
      </Box>
    </Card>
  )
}
