
import { createApiReducer, createKeyedReducer } from '../../../reducers'

import { getReportKeyFromAction } from '../../../utils'

import {
  LOAD_CLIENT_REPORT_PAYLOAD,
} from '../actions'  

import { reportPayload } from '@percept/api/adapters'

import { Reducer } from '@percept/types'

import { ClientReportState } from '../typings'


const payloads: Reducer<ClientReportState['payloads']> = createKeyedReducer(
  createApiReducer(LOAD_CLIENT_REPORT_PAYLOAD, {
    getPayloadFromAction: ({ payload }) => (
      reportPayload(payload)
    )
  }),
  getReportKeyFromAction
)

export default payloads