import { Box, Card, CardContent, CardHeader, Collapse, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { TabSectionHeader } from '../PartnershipTabs'
import { ScoreboardTable } from '../ReportTables'
import { ScoreboardReportType } from '../types'
import { ProviderLogo } from '@percept/mui'

export type MetaReportsProps = {
  reports: ScoreboardReportType[]
  additionalInfoReports?: ScoreboardReportType[]
  quarterlyReports: ScoreboardReportType[]
  headers: string[]
  reportQuarter?: string
  minColorScale: number
  maxColorScale: number
}

export const MetaReports = (
  { reports, quarterlyReports, additionalInfoReports, minColorScale, maxColorScale, headers, reportQuarter }: MetaReportsProps
): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  return(
    <Card elevation={2}>
      <CardHeader
        title={
          <TabSectionHeader
            title={
              <ProviderLogo provider='META' size={1.25} />
            }
            my={1}
            px={0.5}
            isCollapsed={isCollapsed}
            handleCollapse={setIsCollapsed}/>
        } />
      <Collapse in={!isCollapsed}>
        <CardContent>
          <Box px={2}>
            <Typography variant="h4">
              {reportQuarter} Scorecard | All Available Markets
            </Typography>
            <ScoreboardTable
              my={2}
              mb={6}
              key="qx"
              reports={reports}
              headers={headers}
              additionalInfoReports={additionalInfoReports}
              minColorScale={minColorScale}
              maxColorScale={maxColorScale} />
            <Typography variant="h4">
              Scorecard Timeline | All Markets Average
            </Typography>
            <ScoreboardTable
              my={2}
              key="all-qx"
              reports={quarterlyReports}
              headers={headers}
              minColorScale={minColorScale}
              maxColorScale={maxColorScale} />
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  )
}
