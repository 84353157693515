import React from 'react'

import { ClassNameMap, darken, lighten, makeAppStyles } from '@percept/mui'


export type SmartTableClassName = (
  'row' | 'fixedColumnHeaderCell' | 'fixedColumnCell' | 'fixedColumnCellContent' | 'fixedColumnCellTypography' |
  'noWrap' | 'pointer'
)


export type SmartTableClasses = ClassNameMap<SmartTableClassName>


export const useDataTableClasses = makeAppStyles<{}, SmartTableClassName>( theme => {

  const oddBackground = theme.palette.action.disabledBackground

  const oddHeaderBackground = (
    theme.palette.type === 'dark' ?
      darken(theme.palette.primary.dark, 0.25) :
      darken(theme.palette.primary.light, 0.1)
  )

  const evenHeaderBackground = (
    theme.palette.type === 'dark' ?
      darken(theme.palette.primary.dark, 0.35) :
      darken(theme.palette.primary.light, 0.25)
  )

  const evenBackground = theme.palette.background.paper

  const hoverBackground = (
    theme.palette.type === 'dark' ?
      theme.palette.primary.dark :
      lighten(theme.palette.primary.light, 0.1)
  )
  const hoverColor = '#FFF'

  const shadowRightPseudoElement: React.CSSProperties = {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    width: theme.spacing(2),
    height: '100%',
    background: `linear-gradient(to right, ${
      'rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35) 10%, rgba(0, 0, 0, 0.1) 60%, rgba(0, 0, 0, 0) 100%'
    })`,
    zIndex: 2,
  }

  const shadowRightBasePseudoElement: React.CSSProperties = {
    ...shadowRightPseudoElement,
    background: 'unset',
    zIndex: 1,
  }

  return {
    row: {
      '&:nth-child(odd), &:nth-child(odd) td$fixedColumnCell, &:nth-child(odd) td$fixedColumnCell:first-child div::before': { 
        backgroundColor: oddBackground,
      },
      '&:nth-child(odd) td$fixedColumnCell:first-child': {
        backgroundColor: oddHeaderBackground,
      },
      '&:nth-child(even), &:nth-child(even) td$fixedColumnCell, &:nth-child(even) td$fixedColumnCell:first-child div::before': {
        backgroundColor: evenBackground,
      },
      '&:nth-child(even) td$fixedColumnCell:first-child': {
        backgroundColor: evenHeaderBackground,
      },
      '&:hover, &:hover td$fixedColumnCell': {
        backgroundColor: hoverBackground,
        color: hoverColor,
      },
      '&:hover td$fixedColumnCell:first-child, &:hover td$fixedColumnCell:first-child div::before': {
        backgroundColor: hoverBackground,
        color: hoverColor,
      },
      '& td$fixedColumnCell': {
        padding: 0,
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& div$fixedColumnCell': {
        padding: '13px 36px 13px 16px', //theme.spacing(2, 6, 2, 3),
        '&::before': shadowRightBasePseudoElement,
        '&::after': shadowRightPseudoElement,
      },
    },
    pointer: {
      cursor: 'pointer',
    },
    fixedColumnCellContent: {
      maxWidth: '14rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.up('md')]: {
        maxWidth: '20rem',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '30rem',
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: '50rem',
      },
    },
    fixedColumnHeaderCell: {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 102,
      // IMPORTANT! `min-width` must be set to 100% for sticky headers
      // to fill header elements where the number of columns is insufficient
      // to trigger column pinning and the natural cell width is larger than the
      //maximum configured
      minWidth: '100%',
      color: '#FFF',
      backgroundColor: darken(evenHeaderBackground, 0.2),
      '&::after': shadowRightPseudoElement,
      '&::before': {
        ...shadowRightBasePseudoElement,
        background: evenBackground,
        borderTop: `1px solid ${theme.palette.action.disabledBackground}`,
        borderBottom: `1px solid ${theme.palette.action.disabledBackground}`,
      },
      paddingLeft: theme.spacing(3),
    },
    fixedColumnCell: {
      position: 'sticky',
      left: 0,
      zIndex: 101,
      display: 'flex',
      flexDirection: 'column',
      color: '#FFF',
    },
    noWrap: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    fixedColumnCellTypography: {
      maxWidth: 'min-content',
    },
  }
})
