import { useMemo } from 'react'

import { EntityType, ProportionMetric, RatioMetric, ValueMetric } from '@percept/types'


export type DSLText = {
  type: 'TEXT'
  value: string
}

export type CommonDSLAttributes = {
  description?: string | null
}

export type DSLItemValue = {
  type: 'VALUE_METRIC'
} & Omit<ValueMetric, 'metric_type'> & CommonDSLAttributes

export type DSLItemProportion = {
  type: 'PROPORTION_METRIC'
} & Omit<ProportionMetric, 'metric_type'> & CommonDSLAttributes

export type DSLItemRatio = {
  type: 'RATIO_METRIC'
} & Omit<RatioMetric, 'metric_type'> & CommonDSLAttributes

export type DSLItemEntityCount = {
  type: 'ENTITY_COUNT'
  entity_type: EntityType
} & Omit<DSLItemProportion, 'type'> & CommonDSLAttributes

export type DSLItemRecommendedAction = {
  type: 'RECOMMENDED_ACTION'
  text: string
} & CommonDSLAttributes


export type DSLJson = (
  DSLItemValue | DSLItemProportion | DSLItemRatio | DSLItemEntityCount | DSLItemRecommendedAction
)

export type DSLItem = (
  DSLText | DSLJson
)


const ENTER_DSL = '__--'
const EXIT_DSL = '--__'

const HAS_DSL_RE = new RegExp(`${ENTER_DSL}.*${EXIT_DSL}`, 'g')

const DSL_SPLIT_RE = new RegExp(`(?=${ENTER_DSL}.*${EXIT_DSL})`, 'g')

const BEFORE_DSL_RE = new RegExp(`(.*)${ENTER_DSL}`)
const AFTER_DSL_RE = new RegExp(`${EXIT_DSL}(.*)`)

const ENTER_DSL_RE = new RegExp(`^${ENTER_DSL}`)
const EXIT_DSL_RE = new RegExp(`${EXIT_DSL}`)

const trimDSL = (input: string): string => (
  input.replace(ENTER_DSL_RE, '').replace(EXIT_DSL_RE, '')
)


export const containsDSL = (input: string): boolean => Boolean(
  input.match(HAS_DSL_RE)
)


export const parseDSLString = (input: string): DSLJson | null => {
  let json: any = null
  if( !input ){
    return json
  }
  try{
    json = JSON.parse(input)
  }catch(e){
    console.warn('Error parsing JSON', input)
    console.error(e)
    json = null
  }
  if( !json || !json.type ){
    return null
  }

  if( json.type === 'RECOMMENDED_ACTION' ){
    return {
      type: 'RECOMMENDED_ACTION',
      text: json.text,
    }
  }

  const baseDSLItem = {
    type: json.type,
    dimensions: json.dimensions,
    description: json.description || null,
  }

  switch(json.type as DSLJson['type']){
    case 'VALUE_METRIC':
    case 'PROPORTION_METRIC':
    case 'RATIO_METRIC':
      return baseDSLItem
    case 'ENTITY_COUNT':
      return {
        ...baseDSLItem,
        entity_type: json.entity_type,
      }
    default:
      console.warn('Unhandled DSL Json', json)
      return null
  }
}


export const parse = (input: string): DSLItem[] => {

  if( !containsDSL(input) ){
    return [{
      type: 'TEXT',
      value: input
    }]
  }

  const items = input.split(DSL_SPLIT_RE)

  return items.reduce( (acc, item) => {

    if( containsDSL(item) ){
      let prefixDSLItem: DSLItem | null = null
      let suffixDSLItem: DSLItem | null = null

      const prefixMatch = BEFORE_DSL_RE.exec(item)
      if( prefixMatch && prefixMatch[1] ){
        prefixDSLItem = {
          type: 'TEXT',
          value: prefixMatch[1]
        }
        item = item.replace(BEFORE_DSL_RE, '')
      }

      const suffixMatch = AFTER_DSL_RE.exec(item)

      if( suffixMatch && suffixMatch[1] ){
        suffixDSLItem = {
          type: 'TEXT',
          value: suffixMatch[1],
        }
        item = item.replace(AFTER_DSL_RE, '')
      }

      const jsonString = trimDSL(item)
      const parsed = parseDSLString(jsonString)
      if( parsed ){
        if( prefixDSLItem ){
          acc.push(prefixDSLItem)
        }
        acc.push(parsed)
        if( suffixDSLItem ){
          acc.push(suffixDSLItem)
        }
        return acc
      }
    }

    acc.push({
      type: 'TEXT',
      value: item,
    })

    return acc

  }, [] as DSLItem[])

}


export const useDSL = (input: string): DSLItem[] => {
  return useMemo(() => parse(input), [input])
}
