import { ParentSize } from '@visx/responsive'
import React, { useLayoutEffect, useRef } from 'react'

import * as d3 from 'd3'

import { circleAnimationDefElements } from './lib'

import { AnimationSurfaceProps } from './typings'


type Point = {
  x: number
  y: number
}

const makeCircle = (
  surface: d3.Selection<SVGSVGElement | SVGGElement, unknown, null, undefined>,
  coordinates: Point,
  radius: number,
  attrs: Record<string, string | number>
): d3.Selection<SVGCircleElement, unknown, null, undefined> => {
  const el = surface.append('circle')
    .attr('cx', coordinates.x)
    .attr('cy', coordinates.y)
    .attr('r', radius)
  Object.keys(attrs).forEach( attr => {
    el.attr(attr, attrs[attr])
  })
  return el
}


const makePieChart = (
  surface: d3.Selection<SVGSVGElement | SVGGElement, unknown, null, undefined>,
  coordinates: Point,
  radius: number,
): void => {
  const data = [45, 90, 360 - 90 - 45]
  const color = ['#000', '#E60000', '#BEBEBE']
  const size = radius * 2

  const pie = d3.pie()
    .padAngle(0.1)
    .endAngle(-0.125 * Math.PI)
    .startAngle(1.875 * Math.PI)

  const arcs = data.map( (_, i) => (
    d3.arc()
      .innerRadius(radius / 8)
      .outerRadius(radius - (i * 2))
      .padAngle(0.1)
  ))

  const pieData = pie(data)
  const svg = surface.append('svg')
    .attr('viewBox', [-size / 2, -size / 2, size, size].join(' '))
    .attr('width', size)
    .attr('height', size)
    .attr('x', coordinates.x - radius)
    .attr('y', coordinates.y - radius)
    .append('g')

  const els = []
  for( const _ of data ){
    els.push(
      svg.append('path')
    )
  }

  svg
    .selectAll('path')
    .data(pieData)
    .join('path')
    .attr('fill', (d, i) => color[i])
    .attr('d', (d, i) => arcs[i](d))
}


const makePersonGraphic = (
  surface: d3.Selection<SVGSVGElement | SVGGElement, unknown, null, undefined>,
  coordinates: Point,
  radius: number,
): void => {
  const color = ['#BEBEBE', '#E60000',  '#000']
  const height = radius / 1.5
  const width = height * (45 / 20)
  const points = [
    {
      x: coordinates.x - radius / 2 - width / 3,
      y: coordinates.y - height / 4,
    },
    {
      x: coordinates.x - width / 2,
      y: coordinates.y - radius / 2 - height / 4,
    },
    {
      x: (coordinates.x - radius / 2),
      y: coordinates.y - height / 4,
    },
  ]
  points.forEach( (point, i) => {
    const personSvg = surface.append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', '0 0 20 45')
      .attr('x', point.x)
      .attr('y', point.y)
    const mainGroup = personSvg.append('g')
      .attr('transform', 'translate(-0.5 -0.5)')
      
    mainGroup.append('g')
      .attr('transform', 'translate(0 9.890631)')
      .append('path')
      .attr('d', 'M 18.0309 0.3372 C 17.7035 0.1126 17.3339 0 16.9369 0 L 2.2024 0 C 1.5054 0 0.9087 0.3654 0.4974 0.9275 C 0.1842 1.2366 0 1.6441 0 2.1079 L 0 15.7668 C 0 16.6665 0.7103 17.3971 1.5915 17.3971 C 2.4716 17.3971 3.1962 16.6665 3.1962 15.7668 L 3.1962 5.5789 L 4.1203 5.5789 L 4.1203 17.9733 C 4.1203 18.0859 4.1203 18.2117 4.149 18.31 C 4.149 18.3807 4.149 18.4513 4.149 18.5352 L 4.149 32.7846 C 4.149 34.0771 5.157 35.117 6.4221 35.117 C 7.6871 35.117 8.7095 34.0771 8.7095 32.7846 L 8.7095 20.5165 L 11.0398 20.5165 L 11.0398 32.7702 C 11.0398 34.0495 12.0632 35.1027 13.3273 35.1027 C 14.5922 35.1027 15.6156 34.0495 15.6156 32.7702 L 15.6156 18.5075 C 15.6156 18.4227 15.6156 18.3386 15.6014 18.2405 C 15.6014 18.1555 15.6156 18.0573 15.6156 17.959 L 15.6156 5.6069 L 16.3965 5.6069 L 16.3965 15.6408 C 16.3965 16.5396 17.1068 17.2702 18.0023 17.2702 C 18.8978 17.2702 19.5938 16.5396 19.5938 15.6408 L 19.5938 1.9673 C 19.5938 1.0961 18.9255 0.3794 18.0872 0.3372 L 18.0309 0.3372 Z')
      .attr('fill', color[i])
    mainGroup.append('g')
      .attr('transform', 'translate(5.270707 0)')
      .append('path')
      .attr('d', 'M 4.3056 8.53 C 6.678 8.53 8.6102 6.6188 8.6102 4.2579 C 8.6102 1.8972 6.678 0 4.3056 0 C 1.9323 0 0 1.9111 0 4.2579 C 0 6.6048 1.9323 8.53 4.3056 8.53 Z')
      .attr('fill', color[i])
  })
}


const makeBarChart = (
  surface: d3.Selection<SVGSVGElement | SVGGElement, unknown, null, undefined>,
  coordinates: Point,
  radius: number,
): void => {
  const color = ['#BEBEBE', '#000', '#E60000',]
  const bars = [60, 40, 90]
  const width = radius
  const height = radius * 1.5
  const barMargin = 10
  const barWidth = (100 / 3) - barMargin
  const chartSvg = surface.append('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', '0 0 100 100')
    .attr('x', coordinates.x - (radius / 2))
    .attr('y', coordinates.y - (radius / 1.25))
  bars.forEach((bar, i) => {
    chartSvg.append('rect')
      .attr('x', `${(barMargin / 2) + i * (barWidth + barMargin)}%`)
      .attr('y', 100 - bar)
      .attr('width', barWidth)
      .attr('height', bar)
      .attr('fill', color[i])
  })
}

const AnimationSurface = ({
  width,
  height
}: AnimationSurfaceProps): JSX.Element => {
  const svgRef = useRef<SVGSVGElement | null>(null)

  useLayoutEffect(() => {
    const rootEl = svgRef.current
    if( !rootEl ){
      console.warn('No root element for animation')
      return
    }

    const MID_X = width / 2
    const MID_Y = height / 2

    const circleSize = Math.min(width / 5, height / 4)
    const fontSize = circleSize / 6
    const bevelWidth = 12

    const innerCircleMargin = 8
    
    const startRadius = 0
    const endRadius = circleSize / 2

    const circleDuration = 1000
    const lineDuration = 500

    const midpointTop = {
      x: MID_X,
      y: MID_Y - (MID_Y / 2)
    }
    const midpointLeft = {
      x: MID_X - (MID_X / 2),
      y: MID_Y,
    }
    const midpointRight = {
      x: MID_X + (MID_X / 2),
      y: MID_Y,
    }
    const midpointBottom = {
      x: MID_X,
      y: MID_Y + (MID_Y / 2),
    }

    const svg = d3.select(rootEl)
    // Clear surfaces
    svg.selectAll('g').remove()
    const background = svg.append('g')
    const surface = svg.append('g')

    // Begin Media Wizard Circle
    const homeCircleWrap = surface.append('g')
      .attr('filter', 'url(#innerShadow)')
    const subWrap = homeCircleWrap.append('g')
      .attr('filter', 'url(#dropShadow)')

    const homeCircle = makeCircle(
      subWrap,
      midpointBottom,
      startRadius,
      { fill:  'url(#radialGradientRed)' }
    )
    const homeCircleBevel = makeCircle(
      subWrap,
      midpointBottom,
      startRadius,
      {
        fill: 'none',
        stroke: '#DCDCDC',
        'stroke-width': 0,
        filter:  'url(#innerShadowPath)',
      }
    )
    const whiteCircle = makeCircle(
      subWrap,
      midpointBottom,
      startRadius - innerCircleMargin,
      {
        fill:  'none',
        stroke: 'white',
        'stroke-width': 2,
      }
    )

    whiteCircle.transition()
      .duration(circleDuration)
      .attr('r', endRadius - innerCircleMargin)
    homeCircleBevel.transition()
      .duration(circleDuration)
      .attr('r', endRadius + (bevelWidth / 2))
      .attr('stroke-width', bevelWidth)

    homeCircle.transition()
      .duration(circleDuration)
      .attr('r', endRadius)
      .on('end', () => {
        surface.append('text')
          .attr('x', midpointBottom.x)
          .attr('y', midpointBottom.y - (fontSize / 1.5))
          .text('Digital')
          .attr('fill', 'white')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'central')
          .attr('font-size', fontSize * 1.2)
          .attr('font-weight', 700)
        
        surface.append('text')
          .attr('x', midpointBottom.x)
          .attr('y', midpointBottom.y + (fontSize / 1.5))
          .text('Media')
          .attr('fill', 'white')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'central')
          .attr('font-size', fontSize * 1.2)
          .attr('font-weight', 700)
        
        const line = background.append('line')
          .attr('stroke', '#DCDCDC')
          .attr('stroke-width', bevelWidth)
          .attr('stroke-linecap', 'round')
          .attr('filter', 'url(#innerShadowPath)')
          .attr('x1', midpointBottom.x)
          .attr('y1', midpointBottom.y)
          .attr('x2', midpointBottom.x)
          .attr('y2', midpointBottom.y)

        line.transition()
          .duration(lineDuration)
          .attr('x2', midpointLeft.x)
          .attr('y2', midpointLeft.y)
          .on('end', () => {
            // Start 'Strategy'
            // Begin Strategy Circle
            const strategyCircleWrap = surface.append('g')
              .attr('filter', 'url(#innerShadow)')
            const subWrapStrategy = strategyCircleWrap.append('g')
              .attr('filter', 'url(#dropShadow)')

            const strategyCircle = makeCircle(
              subWrapStrategy,
              midpointLeft,
              startRadius,
              { fill:  'url(#radialGradientWhite)' }
            )
            const strategyCircleBevel = makeCircle(
              subWrapStrategy,
              midpointLeft,
              startRadius,
              {
                fill: 'none',
                stroke: '#DCDCDC',
                'stroke-width': 0,
                filter:  'url(#innerShadowPath)',
              }
            )

            strategyCircleBevel.transition()
              .duration(circleDuration)
              .attr('r', endRadius + (bevelWidth / 2))
              .attr('stroke-width', bevelWidth)
            strategyCircle.transition()
              .duration(circleDuration)
              .attr('r', endRadius)
              .on('end', () => {

                makePieChart(surface, midpointLeft, endRadius * 0.66)
                
                const line = background.append('line')
                  .attr('stroke', '#DCDCDC')
                  .attr('stroke-width', bevelWidth)
                  .attr('stroke-linecap', 'round')
                  .attr('filter', 'url(#innerShadowPath)')
                  .attr('x1', midpointBottom.x)
                  .attr('y1', midpointBottom.y)
                  .attr('x2', midpointBottom.x)
                  .attr('y2', midpointBottom.y)

                line.transition()
                  .duration(lineDuration)
                  .attr('x2', midpointTop.x)
                  .attr('y2', midpointTop.y)
                  .on('end', () => {
                    // Start 'Optimisation' circle
                    const optimisationCircleWrap = surface.append('g')
                      .attr('filter', 'url(#innerShadow)')
                    const subWrapOptimisation = optimisationCircleWrap.append('g')
                      .attr('filter', 'url(#dropShadow)')

                    const optimisationCircle = makeCircle(
                      subWrapOptimisation,
                      midpointTop,
                      startRadius,
                      { fill:  'url(#radialGradientWhite)' }
                    )
                    const optimisationCircleBevel = makeCircle(
                      subWrapOptimisation,
                      midpointTop,
                      startRadius,
                      {
                        fill: 'none',
                        stroke: '#DCDCDC',
                        'stroke-width': 0,
                        filter:  'url(#innerShadowPath)',
                      }
                    )

                    optimisationCircleBevel.transition()
                      .duration(circleDuration)
                      .attr('r', endRadius + (bevelWidth / 2))
                      .attr('stroke-width', bevelWidth)

                    optimisationCircle.transition()
                      .duration(circleDuration)
                      .attr('r', endRadius)
                      .on('end', () => {

                        makePersonGraphic(
                          surface, midpointTop, endRadius
                        )
                        
                        const line = background.append('line')
                          .attr('stroke', '#DCDCDC')
                          .attr('stroke-width', bevelWidth)
                          .attr('stroke-linecap', 'round')
                          .attr('filter', 'url(#innerShadowPath)')
                          .attr('x1', midpointBottom.x)
                          .attr('y1', midpointBottom.y)
                          .attr('x2', midpointBottom.x)
                          .attr('y2', midpointBottom.y)
      
                        line.transition()
                          .duration(lineDuration)
                          .attr('x2', midpointRight.x)
                          .attr('y2', midpointRight.y)
                          .on('end', () => {
                            // Start 'Reporting' circle
                            const reportingCircleWrap = surface.append('g')
                              .attr('filter', 'url(#innerShadow)')
                            const subWrapReporting = reportingCircleWrap.append('g')
                              .attr('filter', 'url(#dropShadow)')
      
                            const reportingCircle = makeCircle(
                              subWrapReporting,
                              midpointRight,
                              startRadius,
                              { fill:  'url(#radialGradientWhite)' }
                            )
                            const reportingCircleBevel = makeCircle(
                              subWrapReporting,
                              midpointRight,
                              startRadius,
                              {
                                fill: 'none',
                                stroke: '#DCDCDC',
                                'stroke-width': 0,
                                filter:  'url(#innerShadowPath)',
                              }
                            )
      
                            reportingCircleBevel.transition()
                              .duration(circleDuration)
                              .attr('r', endRadius + (bevelWidth / 2))
                              .attr('stroke-width', bevelWidth)

                            reportingCircle.transition()
                              .duration(circleDuration)
                              .attr('r', endRadius)
                              .on('end', () => {
                                makeBarChart(surface, midpointRight, endRadius)
                              })
                          })
                      })
                  })
              })
          })
      })
    
  }, [width, height])

  return (
    <svg
      ref={svgRef}
      width={width}
      height={height}>
      <defs>
        { circleAnimationDefElements }
      </defs>
    </svg>
  )

}


export const AnimationDigitalMedia = (): JSX.Element => {
  return (
    <ParentSize
      debounceTime={100}>
      { AnimationSurface }
    </ParentSize>
  )
}
