import React, { Fragment, useEffect, useMemo, useState } from 'react'

import {
  BackdropLoader,
  BackdropProps,
  Box,
  Divider,
  ProviderLogo,
  RoundedPlainTextButton,
  Typography,
  useAppTheme,
} from '@percept/mui'

import { PageHeader } from '../PageHeader'

import { DimensionalSummary } from 'components/DimensionalSummary'

import { QualityAnalysis } from 'components/QualityAnalysis'

import { PerformanceControls } from 'components/PerformanceControls'

import { PerformanceTrackers } from './PerformanceTrackers'

import { cellRenderers } from './channelTable'

import { useNestedProviderPillarScores, useProviderPillarScores, usePlatformUnit } from '@percept/hooks'

import {
  useNestedDoubleVerifyProviderComparisons,
  useNestedDoubleVerifyProviderTimeseries,
  useNestedProviderComparisons,
  useNestedProviderTimeseries,
  usePerformanceReportingDimension,
  useTargetCurrency,
} from 'hooks'

import { isStructuralReportProvider, usePerformanceDatasets, usePlatformUnitDatasets } from './lib'

import { get, some } from 'lodash-es'

import { isLoading } from '@percept/utils'

import { format, isBefore } from 'date-fns'

import { channelDisplayMap, providerDoubleVerifyMap } from '@percept/constants'

import { PerformanceReportingDatum } from 'types'

import { PerformanceTableProps } from 'components/Tables'

import {
  ChannelKey,
  PerformanceReportingDimension,
  PlatformUnitContainer,
  PlatformUnitProviderInfo,
  PrimaryPerformanceDataProvider,
} from '@percept/types'

import { platformUnitHasDoubleVerify, resolvePerformanceReportingDimensions } from 'components/Organisation/lib'


const performanceTableProps: Partial<
  PerformanceTableProps<PerformanceReportingDatum & { dimension: PerformanceReportingDimension }>
> = {
  renderers: cellRenderers,
}


export type MultiUnitChannelProps = {
  platformUnit: PlatformUnitContainer
  channel: ChannelKey | null
  provider?: PrimaryPerformanceDataProvider | null
  referenceDate: Date | null
  providerInfo: PlatformUnitProviderInfo
}


export const MultiUnitChannel = ({
  platformUnit,
  channel,
  provider = null,
  providerInfo,
  referenceDate,
}: MultiUnitChannelProps): JSX.Element => {

  const [currency] = useTargetCurrency()

  const [activeDimension, setActiveDimension] = usePerformanceReportingDimension()

  const referenceDateParam = referenceDate && format(referenceDate, 'yyyy-MM-dd')
  
  const org_unit_id = platformUnit.id

  const org_unit_ids = useMemo(() => {
    if( platformUnit.children && platformUnit.children.length ){
      return platformUnit.children.map( ({ id }) => id )
    }else{
      return [platformUnit.id]
    }
  }, [platformUnit])

  const [platformUnitDetail] = usePlatformUnit({
    org_unit_id,
  })

  const hasDoubleVerify = !!(
    platformUnitDetail.data && platformUnitHasDoubleVerify(platformUnitDetail.data, provider)
  )

  const providerReferenceDate = provider && get(
    providerInfo, [provider, 'reference_date'], null
  )

  const doubleVerifyProvider = provider && providerDoubleVerifyMap[provider]

  const reportedDoubleVerifyReferenceDate = doubleVerifyProvider && get(
    providerInfo, [doubleVerifyProvider, 'reference_date'], null
  )

  const doubleVerifyReferenceDate = (
    reportedDoubleVerifyReferenceDate
    && referenceDate
    && (
      reportedDoubleVerifyReferenceDate.getTime() > referenceDate.getTime() ?
        referenceDate :
        reportedDoubleVerifyReferenceDate
    )
  )

  const requiresDoubleVerifyTrigger = !!(
    hasDoubleVerify &&
    doubleVerifyProvider &&
    referenceDate &&
    doubleVerifyReferenceDate &&
    isBefore(doubleVerifyReferenceDate, referenceDate)
  )

  const showDoubleVerify = !!(
    hasDoubleVerify &&
    doubleVerifyProvider &&
    !requiresDoubleVerifyTrigger
  )

  const availableDimensions = useMemo(() => {
    if( platformUnitDetail.data ){
      return resolvePerformanceReportingDimensions({
        platformUnit: platformUnitDetail.data,
        provider,
        includeDoubleVerify: showDoubleVerify,
      })
    }
    return undefined
  }, [provider, platformUnitDetail.data, showDoubleVerify])
  
  useEffect(() => {
    if( availableDimensions && !availableDimensions.includes(activeDimension) ){
      setActiveDimension(availableDimensions[0])
    }
  }, [availableDimensions, activeDimension, setActiveDimension])

  const timeseriesByUnit = useNestedProviderTimeseries({
    org_unit_ids,
    provider,
    chunking: 'MONTH',
    period: 'DAYS_365',
    target_currency: currency,
    ...(referenceDateParam && {
      reference_date: referenceDateParam
    })
  })

  const doubleVerifyTimeseriesByUnit = useNestedDoubleVerifyProviderTimeseries({
    org_unit_ids,
    provider,
    chunking: 'MONTH',
    period: 'DAYS_365',
    enabled: showDoubleVerify,
    ...(referenceDateParam && {
      reference_date: referenceDateParam
    })
  })

  const comparisonsByUnit = useNestedProviderComparisons({
    org_unit_ids,
    provider,
    target_currency: currency,
    ...(referenceDateParam && {
      reference_date: referenceDateParam
    })
  })

  const doubleVerifyComparisonsByUnit = useNestedDoubleVerifyProviderComparisons({
    org_unit_ids,
    provider,
    enabled: showDoubleVerify,
    ...(referenceDateParam && {
      reference_date: referenceDateParam
    })
  })

  const [pillarScores] = useProviderPillarScores({
    org_unit_id,
  })

  const [nestedPillarScores] = useNestedProviderPillarScores({
    org_unit_id,
  })

  const channelLabel: React.ReactNode | null = (
    provider && <ProviderLogo provider={provider} size={1.25} /> ||
    channel && channelDisplayMap[channel].label
  )

  const appTheme = useAppTheme()

  const platformUnitDatasets = usePlatformUnitDatasets({
    platformUnit,
    timeseriesByUnit: timeseriesByUnit.data,
    doubleVerifyTimeseriesByUnit: doubleVerifyTimeseriesByUnit.data,
    comparisonsByUnit: comparisonsByUnit.data,
    doubleVerifyComparisonsByUnit: doubleVerifyComparisonsByUnit.data,
    currency,
    appTheme,
    channel,
  })

  const dimensionalDatasets = usePerformanceDatasets({
    datasets: platformUnitDatasets,
    appTheme,
    dimension: activeDimension,
    currency,
  })

  const [qaViewType, setQaViewType] = useState<'DETAIL' | 'SUMMARY'>('SUMMARY')

  const showContent = Boolean(
    !comparisonsByUnit.loading &&
    !timeseriesByUnit.loading &&
    !nestedPillarScores.loading && (
      showDoubleVerify ? (
        !doubleVerifyTimeseriesByUnit.loading &&
        !doubleVerifyComparisonsByUnit.loading
      ) : true
    )
  )

  const backdropProps: Partial<BackdropProps> = {
    open: (
      some(
        [
          comparisonsByUnit, timeseriesByUnit, nestedPillarScores, pillarScores,
        ],
        isLoading
      ) || (
        showDoubleVerify && some([doubleVerifyComparisonsByUnit, doubleVerifyTimeseriesByUnit], isLoading)
      )
    )
  }

  return (
    <Box
      my={4}
      mx={3}>

      { showContent && availableDimensions && availableDimensions.includes(activeDimension) && (
        <Fragment>

          <PageHeader
            header={
              channelLabel ?
                `Group ${channelLabel} Overview` :
                'Group Overview'
            } />

          { provider && isStructuralReportProvider(provider) && (
            <>
              <Box display='flex' alignItems='center' height={24} mt={5} mb={3}>
                <Typography variant='h5'>
                  Quality Analysis
                </Typography>

                <Box ml={2}>
                  <RoundedPlainTextButton
                    variant='contained'
                    color='secondary'
                    size='small'
                    onClick={(): void => {
                      setQaViewType( prev => prev === 'DETAIL' ? 'SUMMARY' : 'DETAIL' )
                    }}>
                    { qaViewType === 'DETAIL' ? 'View Summary' : 'View Detail' }
                  </RoundedPlainTextButton>
                </Box>
              </Box>

              <QualityAnalysis
                platformUnit={platformUnit}
                provider={provider}
                viewType={qaViewType} />

              <Box my={3}>
                <Divider />
              </Box>
            </>
          )}

          <PerformanceControls
            platformUnit={platformUnit}
            availableDimensions={availableDimensions}
            referenceDate={referenceDate}
            maxDate={providerReferenceDate}
            doubleVerifyReferenceDate={doubleVerifyReferenceDate}
            requiresDoubleVerifyTrigger={requiresDoubleVerifyTrigger} />

          <Box mt={5}>
            <DimensionalSummary
              dimension={activeDimension}
              referenceDate={referenceDate}
              color={channel || 'primary'}
              datasets={dimensionalDatasets}
              currency={currency}
              PerformanceTableProps={performanceTableProps} />
          </Box>

          <Box mt={4}>
            <PerformanceTrackers
              currency={currency}
              availableDimensions={availableDimensions}
              referenceDate={referenceDate}
              datasets={platformUnitDatasets} />
          </Box>

        </Fragment>
      )}

      <BackdropLoader
        BackdropProps={backdropProps} />

    </Box>
  )
}
