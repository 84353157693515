
import React from 'react'

import {
  ButtonProps,
  MenuAbstractionProps,
  MenuOption,
  PlainTextButtonMenu,
} from '@percept/mui'

import { PerformanceRangeKey } from '@percept/types'


export type TimeRangeSelectProps = (
  Omit<
    MenuAbstractionProps<ButtonProps, PerformanceRangeKey>,
    'options' | 'children'
  > & {
    rangeOptions?: PerformanceRangeKey[]
  }
)


const defaultTimeRangeOptionValues: PerformanceRangeKey[] = [
  'week',
  'month',
  'year',
]

const timeRangeLabelMap: Record<PerformanceRangeKey, string> = {
  now: 'Yesterday',
  week: 'Week',
  month: 'Month',
  year: 'Year',
}


export const TimeRangeSelect = ({
  value,
  rangeOptions = defaultTimeRangeOptionValues,
  ...props
}: TimeRangeSelectProps): JSX.Element => {

  const menuOptions: MenuOption<PerformanceRangeKey>[] = defaultTimeRangeOptionValues.map( value => ({
    label: timeRangeLabelMap[value],
    value,
    disabled: rangeOptions.indexOf(value) === -1,
  }) )

  return (
    <PlainTextButtonMenu
      {...props}
      value={value}
      label={value && timeRangeLabelMap[value]}
      options={menuOptions} />
  )
}
