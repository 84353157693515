import { displayNumber, separateThousands, round, isNumber } from '@percept/utils'

/* Some currencies such as Hungarian forints are displayed as '10 Ft',
 * whereas most currency symbols appear before the value (i.e '£10').
 * Due to this variation, and the fact we want to 'humanise' large values
 * as '10k' or '10m' where the currency symbol may also be a letter prefix,
 * we need a bit of ceremony around currency formatting.
 */

/* eslint-disable react/display-name */


type Formatters = {
  full: (amount: number | null | string | JSX.Element) => string
  symbol: string
}

const dollarFormatters: Formatters = {
  full: displayAmount => '$' + displayAmount,
  symbol: '$',
}

const yenFormatters: Formatters = {
  full: displayAmount => '¥' + displayAmount,
  symbol: '¥',
}


/* eslint-disable @typescript-eslint/explicit-function-return-type */

const currencyFormatters: Record<string, Formatters> = {
  __default: { // Default formatters, passes through amount and simplifies component logic
    full: displayAmount => String(displayAmount),
    symbol: '',
  },
  AED: { // United Arab Emirates Dirham
    full: displayAmount => 'د.إ' + displayAmount,
    symbol: 'د.إ',
  },
  ALL: { // Albanian Lek
    full: displayAmount => displayAmount + ' Lek',
    symbol: 'Lek',
  },
  ARS: dollarFormatters, // Argentinian Peso
  AUD: dollarFormatters, // AU Dollars
  BGN: { // Bulgarian Lev
    full: displayAmount => 'Лв' + displayAmount,
    symbol: 'Лв',
  },
  BND: dollarFormatters, // Brunei Darussalam Dollars
  BOB: { // Bolivian Boliviano
    full: displayAmount => 'Bs. ' + displayAmount,
    symbol: 'Bs',
  },
  BRL: { // Brazilian Real
    full: displayAmount => 'R$' + displayAmount,
    symbol: 'R$',
  },
  CAD: dollarFormatters, // Canadian Dollars
  CHF: { // Swiss Francs
    full: displayAmount => displayAmount + ' CHF',
    symbol: 'CHF',
  },
  CLP: dollarFormatters, // Chilean Peso
  CNY: yenFormatters, // Chinese Yen,
  COP: dollarFormatters, // Colombian Peso
  CZK: { // Czech Koruna
    full: displayAmount => displayAmount + ' Kč',
    symbol: 'Kč',
  },
  EGP: {
    full: displayAmount => 'E£' + displayAmount,
    symbol: 'E£',
  },
  EUR: { // Euros
    full: displayAmount => '€' + displayAmount,
    symbol: '€',
  },
  GBP: { // Pounds Sterling
    full: displayAmount => '£' + displayAmount,
    symbol: '£',
  },
  GHS: { // Ghanaian Cedi
    full: displayAmount => 'GH₵' + displayAmount,
    symbol: 'GH₵',
  },
  HUF: { // Hungarian Forints
    full: displayAmount => displayAmount + ' Ft',
    symbol: 'Ft',
  },
  ILS: {
    // Israeli New Shekel
    full: displayAmount => '₪' + displayAmount,
    symbol: '₪',
  },
  INR: { // Indian Rupees
    full: displayAmount => '₹' + displayAmount,
    symbol: '₹',
  },
  JPY: {  // Japanese Yen
    full: displayAmount => '¥' + displayAmount,
    symbol: '¥',
  },
  NZD: dollarFormatters, // NZ Dollars
  QAR_: { // Qatari Riyals
    full: displayAmount => 'ر.ق' + displayAmount,
    symbol: 'ر.ق',
  },
  RON: { // Romanian Lei
    full: displayAmount => displayAmount + ' lei',
    symbol: 'lei'
  },
  SEK: { // Swedish Krona
    full: displayAmount => displayAmount + ' kr',
    symbol: 'kr',
  },
  TRY: { // Turkish Lira
    full: displayAmount => '₺' + displayAmount,
    symbol: '₺',
  },
  USD: dollarFormatters, // US Dollars
  ZAR: { // South African Rand
    full: displayAmount => 'R' + displayAmount,
    symbol: 'R',
  },
}


export type MoneyProps = {
  amount: number | null
  currency?: string | null
  renderAmount?: (amount: number) => JSX.Element | string | number | null
  abbreviate?: boolean
  precision?: number
  forceRenderSign?: boolean
}


const automaticMonetaryPrecisionFormatter = (n: number, precision: number): string => {
  if( precision < 2 ){
    return round(n, precision).toFixed(precision)
  }
  const automaticPrecision = (
    n >= 0.01 ? 2 :
      n >= 0.001 ? 3 :
        4
  )
  const precisionValue = String(round(n, automaticPrecision))
  const [integer, mantissa] = precisionValue.split('.')
  const formattedMantissa = (
    mantissa ?
      (mantissa.length < 2 ? `${mantissa}0` : mantissa) :
      '00'
  )
  return `${integer}.${formattedMantissa}`
}


export const formatMoney = ({ amount, currency, renderAmount, abbreviate = true, precision = 2, forceRenderSign = false }: MoneyProps): string => {
  const formatters = (
    currencyFormatters[currency || '__default']
    || currencyFormatters.__default
  )
  if( !isNumber(amount) ){
    return formatters.symbol
  }
  const numberValue = Number(amount)
  const absoluteValue = Math.abs(numberValue)
  const prefix = (
    forceRenderSign && numberValue > 0 ?
      '+' : numberValue < 0 ?
        '-' : ''
  )
  const formatValue = (
    typeof renderAmount === 'function' ?
      renderAmount(amount) :
      (abbreviate && absoluteValue >= 1000) ?
        displayNumber(absoluteValue) :
        separateThousands(automaticMonetaryPrecisionFormatter(absoluteValue, precision))
  )
  return prefix + ' ' + formatters.full(formatValue)
}
