import { makeAppStyles } from '@percept/mui'

export const useAccordionStyles = makeAppStyles({
  accordionTitle: {
    fontSize: 25,
    fontWeight: 700,
    lineHeight: '40px',
  },
  accordionContent: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '26px',
  },
})
