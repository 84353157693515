import React from 'react'

import { DefaultLayout } from './DefaultLayout'

import { Organisation } from '../components/Organisation'


export const OrganisationScreen = (): JSX.Element => {
  return (
    <DefaultLayout>
      <Organisation />
    </DefaultLayout>
  )
}

export default OrganisationScreen
