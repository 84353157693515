/* API Adapters - Non schema'd resources */

import {
  getPath,
  sortBy,
  getHealthFrom,
  getAnnotationLevel,
  parseAnnotationPath,
  getHealthAggregationsFrom,
  isNumber,
  mapValues,
} from '@percept/utils'

import {
  Report,
  Dictionary,
  Series,
  SeriesGroup,
  SeriesMetric,
  Annotation,
  User,
  HydratedSeries,
  SeriesGroupDetailItem,
  MetricsPayload,
  SeriesReport,
} from '@percept/types'

import { singleMetric } from './metric'


export const report = ({
  audit_id,
  report_id,
  series_id,
  final_task_id,
  from,
  until,
  status,
  summary = {}
}: Dictionary = {}): Report => {

  const health = getHealthFrom(summary)

  const health_aggregations = getHealthAggregationsFrom(summary)

  const isImpactWeighted = (
    isNumber(health_aggregations.count.impact_weighted_avg)
  )

  return {
    id: report_id || audit_id,
    report_id: report_id || audit_id,
    task_id: final_task_id,
    series_id,
    start: from,
    end: until,
    health,
    health_aggregations,
    isImpactWeighted,
    status,
    performance: getPath(summary, 'performance', null),
    currency_code: getPath(summary, 'currency_code', null),
  }

}

export const audit = report


export const series = (
  { series_group_id, series_id, name, provider, report_layout_id, config, has_provider_data_access }: Dictionary = {}
): Series => ({
  series_group_id,
  series_id,
  config,
  name,
  provider,
  report_layout_id,
  has_provider_data_access: Boolean(has_provider_data_access),
})


export const seriesReport = report
export const seriesAudit = report

export const seriesReports = (reports: null | Dictionary[]): SeriesReport[] => (
  sortBy((reports || []).map(seriesReport), 'start')
)

export const seriesAudits = seriesReports


export const hydratedSeries = (props: Dictionary): HydratedSeries => ({
  ...series(props),
  reports: seriesReports(props.reports)
})

export const seriesGroup = ({ series_group_id, name, series, created_at }: Dictionary = {}): SeriesGroup => ({
  series_group_id,
  name,
  ...(
    series && { series: series.map(hydratedSeries) }
  ),
  created_at,
})

export const seriesGroups = (seriesGroups: null | Dictionary[]): SeriesGroup[] => (
  (seriesGroups || []).map(seriesGroup)
)

export const seriesGroupDetail = (seriesList: null | Dictionary[]): SeriesGroupDetailItem[] => (
  (seriesList || []).map(series)
)

export const metricSeries = (series: null | Dictionary[]): SeriesMetric[] => (
  sortBy(
    (series || []).map( ({ id, report_id, from, start, until, end, metric, dimension, ...rest }) => ({
      id: id || report_id,
      report_id: id || report_id,
      start: start || from,
      end: end || until,
      health: getHealthFrom(metric),
      metric,
      dimension,
      ...rest
    }) ),
    'start'
  )
)


export const annotation = ({
  annotation_id,
  series_id,
  payload,
  created_by,
  created_by_name,
  created_by_email,
  created_by_admin,
  attachment_point_path,
  created_at
}: Dictionary): Annotation => {

  const attachmentParams = parseAnnotationPath(attachment_point_path)

  const level = getAnnotationLevel({ series_id, ...attachmentParams })

  return ({
    annotation_id,
    series_id,
    payload,
    level,
    ...attachmentParams,
    percept: !!created_by_admin,
    created_by: {
      user_id: created_by,
      name: created_by_name,
      email: created_by_email
    },
    created_at
  })

}


export const user = ({
  username,
  sub,
  user_id,
  name,
  email,
  phone,
  phone_number,
  groups,
}: Dictionary): User => ({
  user_id: user_id || username || sub,
  name,
  email,
  phone: phone || phone_number || '',
  groups,
})


export const attributesToMetrics = (attributes: Dictionary): MetricsPayload => {
  return mapValues(attributes, a => {
    return {
      attribute: singleMetric({
        metric_type: 'attribute',
        value: a
      })
    }
  })
}
