import { Typography, makeAppStyles } from '@percept/mui'
import React from 'react'

const useClasses = makeAppStyles((theme) => ({
  notUpdated: {
    alignItems: 'center',
    minWidth: '4.2rem',
    maxWidth: '9.5rem',
    minHeight: '2rem',
    backgroundColor: theme.palette.error.dark,
  },
  updated: {
    alignItems: 'center',
    minWidth: '4.2rem',
    maxWidth: '9.5rem',
    minHeight: '2rem',
    backgroundColor: theme.palette.success.light,
  },
  chip: {
    fontWeight: 500,
    '& .MuiChip-label:first-letter': {
      textTransform: 'uppercase',
    },
  },
  updatedChip: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  date: {
    color:'white',
    fontSize: 10,
    fontWeight: 700,
    paddingTop: '6px',
  },
}))

export const TableCells = ({
  isUpdated,
  date,
}: {
  isUpdated: boolean
  date?: string
}): JSX.Element => {
  const classes = useClasses()
  return !isUpdated ? (
    <div className={classes.notUpdated} />
  ) : (
    <div className={classes.updated}>
      {date && (
        <Typography className={classes.date} variant='body2'>
          {date}
        </Typography>
      )}
    </div>
  )
}
