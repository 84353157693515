
import React, { useMemo } from 'react'

import {
  HistogramWithTooltip,
  DonutWithTooltip,
  Donut,
  DonutProps as DonutComponentProps,
  Histogram,
  HistogramProps as HistogramComponentProps,
  ResponsiveHistogramWithTooltip,
  ResponsiveDonutWithTooltip,
} from '@percept/mui/charts'

import { useAppTheme } from '../../themes'

import {
  applySegmentMetadata,
} from './lib'

import {
  dimensionMap,
  performanceReportingDimensionOrder,
} from '@percept/constants'

import {
  isNumber,
  parseDimension,
} from '@percept/utils'

import { find, get, isFunction } from 'lodash-es'

import { MetricSegmentType, DistributionSegments, MetricDisplayType } from '@percept/types'

import { MetricProps, CommonMetricRendererProps, MetricVisualisationProps } from './typings'

import { ChartProps } from '../../charts/typings'


export type DistributionMetricVisualisationProps = (
  CommonMetricRendererProps &
  Pick<
    MetricVisualisationProps,
    'HistogramProps' | 'DonutProps' | 'responsive' | 'activeSegments'
  > & {
    visualisationType: 'donut' | 'histogram'
    segments: DistributionSegments
    width: number
    height: number
  } &
  Pick<
    MetricProps,
    'dimension' | 'onSegmentClick' | 'tooltip' | 'tooltipPercentage' | 'filterLegend' | 'currency'
  >
)



export const presets: Record<
  MetricDisplayType, Record<
    'donut' | 'histogram', {
      width: number
      height: number
    }
  >
> = {
  DETAIL: {
    donut: {
      width: 180,
      height: 180,
    },
    histogram: {
      width: 300,
      height: 180,
    },
  },
  SUMMARY: {
    donut: {
      width: 85,
      height: 85,
    },
    histogram: {
      width: 170,
      height: 85,
    },
  },
  MINI: {
    donut: {
      width: 60,
      height: 60,
    },
    histogram: {
      width: 100,
      height: 60,
    },
  },
  MICRO: {
    donut: {
      width: 50,
      height: 50,
    },
    histogram: {
      width: 80,
      height: 50,
    },
  },
}


const getAxisLabelForDimension = (dimension: string): string => {
  if( dimension === 'count' ) return 'Total'
  for( const d of performanceReportingDimensionOrder ){
    if( dimension.includes(d) ) return dimensionMap[d].text
  }
  return ''
}


export const DistributionMetricVisualisation = ({
  visualisationType,
  id,
  segments,
  activeSegments,
  title,
  dimension,
  currency,
  display_options,
  displayType,
  onSegmentClick,
  onActivateDetail,
  HistogramProps = {},
  DonutProps = {},
  width,
  height,
  tooltip = true,
  responsive = false,
}: DistributionMetricVisualisationProps): JSX.Element => {

  const sizeProps = { width, height }

  const fillOpacity = Number(get(
    HistogramProps, ['svgPathProps', 'fillOpacity'], get(
      DonutProps, ['pathProps', 'fillOpacity'], 1
    )
  ))

  const theme = useAppTheme()

  const data = useMemo(() => {
    const mapped = applySegmentMetadata(segments, display_options)
    const { healthColourScale, getInformationalColourScale } = theme.chart
    const defaultScale = getInformationalColourScale([0, mapped.length - 1])
    return mapped.map( (d, i) => {
      const health = isNumber(d.health) ? d.health : null
      return {
        ...d,
        health,
        color: d.color || (
          isNumber(health) ?
            healthColourScale(health) :
            defaultScale(i)
        ) || 'currentColor',
        fillOpacity: find(activeSegments, s => s.label === d.label) ? 1 : fillOpacity,
      }
    })
  }, [segments, display_options, theme, activeSegments, fillOpacity])

  const onMetricSegmentClick = useMemo(() => {
    if( isFunction(onActivateDetail) ){
      return (
        (segment?: MetricSegmentType): void => {
          if( segment && isNumber(segment.value) && segment.value > 0 ){
            onActivateDetail({ segment, dimension })
          }
        }
      )
    }
    if( typeof onSegmentClick === 'function' ){
      return (
        (segment?: MetricSegmentType): void  => {
          if( segment && isNumber(segment.value) && segment.value > 0 ){
            onSegmentClick({
              segment, title, metric_id: id, dimension
            })
          }
        }
      )
    }
    return undefined
  }, [onActivateDetail, onSegmentClick, title, id, dimension])

  const commonProps: ChartProps<MetricSegmentType> = {
    animate: true,
    animateInitial: false,
    data,
    ...sizeProps,
    dimension: parseDimension(dimension).dimension,
    tooltipPercentage: true,
    currency,
    onSegmentClick: onMetricSegmentClick,
  }

  const responsiveReset  = { width: undefined }

  if( visualisationType === 'donut' ){

    const Renderer = (
      responsive ?
        ResponsiveDonutWithTooltip :
        tooltip ?
          DonutWithTooltip :
          Donut
    ) as React.FC<DonutComponentProps<MetricSegmentType>>

    return (
      <Renderer
        {...commonProps}
        {...responsive && responsiveReset}
        {...DonutProps} />
    )
  }

  const Renderer = (
    responsive ?
      ResponsiveHistogramWithTooltip :
      tooltip ?
        HistogramWithTooltip :
        Histogram
  ) as React.FC<HistogramComponentProps<MetricSegmentType>>

  return (
    <Renderer
      {...commonProps}
      {...responsive && responsiveReset}
      {...(displayType === 'DETAIL' && {
        xAxisLabel: get(display_options.labels, 'x'),
        yAxisLabel: getAxisLabelForDimension(dimension),
      })}
      {...HistogramProps} />
  )
}
