
import React from 'react'

import {
  Box,
  BoxProps,
  Chip,
} from '@material-ui/core'

import { makeAppStyles } from '../../themes'

import { ArrowUpward, ArrowDownward } from '../../icons'

import { TreeEntityListItem } from './typings'

import {
  SortConfig,
} from './typings'
import { Dictionary } from '@percept/types'


export type SortControlProps = (
  {
    sortKey: keyof TreeEntityListItem
    label: React.ReactNode
    sortConfig: SortConfig
    onSort: (config: SortConfig) => void
  } & Partial<BoxProps>
)


const useSortControlStyles = makeAppStyles( theme => ({
  chip: ({ active }: { active: boolean }): Dictionary => ({
    fontSize: 12,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    color: active ? '#FFF' : theme.palette.text.primary,
  }),
}) )


export const SortControl = ({ sortKey, label, sortConfig, onSort, ...props }: SortControlProps): JSX.Element => {

  const isActiveKey = sortKey === sortConfig.key

  const classes = useSortControlStyles({ active: isActiveKey })

  return (
    <Box
      my={1}
      {...props}>

      <Chip
        size='small'
        className={classes.chip}
        label={label}
        color={isActiveKey ? 'secondary' : 'default'}
        icon={
          !isActiveKey ? 
            undefined :
            sortConfig.order === 'ASC' ?
              <ArrowUpward /> :
              <ArrowDownward />
        }
        onClick={(): void => {
          onSort({
            key: sortKey,
            order: (
              isActiveKey ? (
                sortConfig.order === 'ASC' ?
                  'DESC' :
                  'ASC'
              ) : (
                sortConfig.order
              )
            )
          })
        }} />

    </Box>
  )
}
