import React, { useContext } from 'react'
import { Box } from '@material-ui/core'
import { makeAppStyles } from '@percept/mui'
import { FieldsetQuestion } from '../../../api/report-schemas'
import { Question } from './Question'
import { QuestionContext } from 'components/BrandGrowthPlanner/contexts'

interface QuestionsProps {
  questions: FieldsetQuestion[]
}

const useQuestionsStyles = makeAppStyles(() => ({
  box: {
    display: 'grid',
    gap: '2rem',

    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#f2f2f2',
    },

    '& .MuiFormLabel-root': {
      fontSize: '18px',
    },

    '& .MuiInputLabel-shrink': {
      fontSize: '20px',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      fontSize: '19px',
    },

    '& .MuiListItemText-root, .MuiListItemText-multiline': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
}))

export function Questions({ questions }: QuestionsProps): JSX.Element {
  const classes = useQuestionsStyles()

  const { questionValues, onQuestionChange } = useContext(QuestionContext)

  return (
    <Box className={classes.box}>
      {questions.map((question) => (
        <Question
          key={question.id}
          question={question}
          value={questionValues[question.id]}
          onChange={(value): void => {
            onQuestionChange(question.id, value)
          }} />
      ))}
    </Box>
  )
}
