import { useEffect, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
  loadDoubleVerifyProviderPerformanceComparisons,
  loadDoubleVerifyProviderPerformanceTimeseries,
  loadProviderPerformanceComparisons,
  loadProviderPerformanceTimeseries,
  loadPerformanceTimeseriesByProvider,
} from '@percept/redux/bundles/performanceReporting/actions'

import { ReportSeriesWrapper } from '@percept/redux/bundles/structuralReporting'

import {
  loadReportSeries,
} from '@percept/redux/bundles/structuralReporting/actions'

import {
  getProviderPerformanceTimeseriesKey,
  getNestedComparisonKey,
  getNestedDoubleVerifyComparisonKey,
  getDoubleVerifyProviderPerformanceTimeseriesKey,
  getPerformanceTimeseriesKey,
} from '@percept/redux'

import { find, get, some, sortBy } from 'lodash-es'

import { isErrorResponse, isLoading, shouldAttemptLoad } from '@percept/utils'

import { providerDoubleVerifyMap, reportProviders } from '@percept/constants'

import {
  ApiResponse,
  DoubleVerifyPerformanceComparisons,
  DoubleVerifyProvider,
  DoubleVerifyProviderPerformanceTimeseries,
  Nullable,
  PerformanceComparisons,
  PerformanceProviderParams,
  PerformanceReportingAggregationParams,
  PerformanceReportingCurrencyParams,
  PerformanceTimeseriesByProvider,
  PlatformUnitParams,
  PrimaryPerformanceDataProvider,
  ProviderPerformanceTimeseries,
  ReferenceDateParams,
  ReportProvider,
  ReportSeries,
  StructuralReportConfig,
} from '@percept/types'

import { StoreState } from 'types'



const pullSeriesIds = (
  reportingConfigs: StructuralReportConfig[]
): string[] => (
  reportingConfigs.map(({ id }) => id )
)


export function useDerivedSeriesListing<P extends ReportProvider>(
  structuralReporting: StructuralReportConfig<P>[]
): ApiResponse<ReportSeries[]> {

  const seriesById = useSelector<StoreState, Record<string, ReportSeriesWrapper>>( state => (
    state.structuralReporting.reportSeries
  ))

  const dispatch = useDispatch()

  useEffect(() => {
    const seriesIds = pullSeriesIds(structuralReporting)
    seriesIds.forEach( (series_id) => {
      if( series_id ){
        const seriesWrapper = seriesById[series_id]
        if( !seriesWrapper || shouldAttemptLoad(seriesWrapper.summary) ){
          dispatch(loadReportSeries({ series_id }))
        }
      }
    })
  }, [dispatch, structuralReporting, seriesById])

  return useMemo(() => {

    const ids = pullSeriesIds(structuralReporting)

    const idWrapperPairs = ids.map( id => [
      id,
      seriesById[id] && seriesById[id].summary
    ]).filter( ([, wrapper]) => Boolean(wrapper) ) as [string, ApiResponse<ReportSeries>][]

    const loading = idWrapperPairs.length < ids.length || (
      some(idWrapperPairs, ([, wrapper]) => isLoading(wrapper))
    )

    const error = get(
      find(idWrapperPairs, ([, wrapper]) => isErrorResponse(wrapper)),
      'error',
      null
    )

    const loadedIdWrapperPairs = idWrapperPairs.filter( ([, wrapper]) => wrapper.data !== null )

    let data: ReportSeries[] | null = null

    if( !loading && loadedIdWrapperPairs.length ){
      const seriesWithIdFixed = loadedIdWrapperPairs.map( ([id, wrapper]) => ({
        ...(wrapper.data as ReportSeries),
        id,
        series_id: id,
      }))
      data = sortBy(seriesWithIdFixed, 'name')
    }

    return {
      loading,
      data,
      error,
    }

  }, [seriesById, structuralReporting])

}

export type ProviderComparisonsHookProps = (
  {
    providers: PrimaryPerformanceDataProvider[]
  } &
  Nullable<
    PlatformUnitParams &
    Partial<ReferenceDateParams> & {
      target_currency?: string
    }
  >
)

export function useComparisonsByProvider({
  org_unit_id,
  reference_date,
  providers,
  target_currency,
}: ProviderComparisonsHookProps): ApiResponse<Record<PrimaryPerformanceDataProvider, PerformanceComparisons | null>> {

  const comparisonsByProvider = useSelector<
    StoreState,
    Record<
      string, ApiResponse<PerformanceComparisons>
    > | null
  >( state => (
    org_unit_id ? state.performanceReporting.comparisonsByProvider[org_unit_id] : null
  ))

  const dispatch = useDispatch()

  useEffect(() => {
    providers.forEach( provider => {
      if( org_unit_id && target_currency && reference_date ){
        const requestKey = getNestedComparisonKey({
          provider,
          reference_date,
          target_currency,
        })
        const wrapper = comparisonsByProvider && comparisonsByProvider[requestKey]
        if( !wrapper || shouldAttemptLoad(wrapper) ){
          dispatch(loadProviderPerformanceComparisons({
            provider,
            org_unit_id,
            reference_date,
            target_currency,
          }))
        }
      }
    })
  }, [dispatch, providers, comparisonsByProvider, org_unit_id, reference_date, target_currency])

  return useMemo(() => {

    const responses: ApiResponse[] = (
      comparisonsByProvider ?
        Object.values(comparisonsByProvider) :
        []
    )
    const loading = responses.length < providers.length || some(responses, isLoading)

    const data = loading ? null : (
      providers.reduce( (acc, provider) => {
        const requestKey = getNestedComparisonKey({
          provider,
          reference_date,
          target_currency,
        })
        acc[provider] = get(comparisonsByProvider, [requestKey, 'data'], null)
        return acc
      }, {} as Record<PrimaryPerformanceDataProvider, PerformanceComparisons | null>)
    )

    return {
      loading,
      data,
      error: null,
    }

  }, [comparisonsByProvider, providers, reference_date, target_currency])

}


export function useDoubleVerifyComparisonsByProvider({
  org_unit_id,
  reference_date,
  enabledDoubleVerifyProviders,
}: (
  Nullable<PlatformUnitParams> &
  Partial<ReferenceDateParams> & {
    enabledDoubleVerifyProviders?: DoubleVerifyProvider[] | null
  }
)): ApiResponse<Record<PrimaryPerformanceDataProvider, DoubleVerifyPerformanceComparisons | null>> {

  const comparisonsByProvider = useSelector<
    StoreState,
    Record<
      string, ApiResponse<DoubleVerifyPerformanceComparisons>
    > | null
  >( state => (
    org_unit_id ? state.performanceReporting.doubleVerifyComparisonsByProvider[org_unit_id] : null
  ))

  const dispatch = useDispatch()

  useEffect(() => {
    
    reportProviders.forEach( provider => {
      const enabled = !!(
        enabledDoubleVerifyProviders &&
        enabledDoubleVerifyProviders.includes(providerDoubleVerifyMap[provider])
      )
      if( enabled && org_unit_id ){
        const requestKey = getNestedDoubleVerifyComparisonKey({
          provider,
          reference_date,
        })
        const wrapper = comparisonsByProvider && comparisonsByProvider[requestKey]
        if( !wrapper || shouldAttemptLoad(wrapper) ){
          dispatch(loadDoubleVerifyProviderPerformanceComparisons({
            provider,
            org_unit_id,
            reference_date,
          }))
        }
      }
    })
  }, [dispatch, comparisonsByProvider, org_unit_id, reference_date, enabledDoubleVerifyProviders])

  return useMemo(() => {

    const expectedResponseLength = get(enabledDoubleVerifyProviders, 'length', 3)

    if( !expectedResponseLength ){
      return {
        loading: false,
        data: null,
        error: null,
      }
    }

    const enabledProviders = (
      !enabledDoubleVerifyProviders ?
        [] :
        reportProviders.filter( provider => enabledDoubleVerifyProviders.includes(providerDoubleVerifyMap[provider]) )
    )

    const providerRequestKeyPairs: [ReportProvider, string][] = enabledProviders.map( provider => {
      return [
        provider,
        getNestedDoubleVerifyComparisonKey({
          provider,
          reference_date,
        })
      ]
    })

    const responses: ApiResponse[] = (
      comparisonsByProvider ?
        providerRequestKeyPairs.map( ([ , k]) => comparisonsByProvider[k] ) :
        []
    )
    const loading = responses.length < expectedResponseLength || some(responses, isLoading)

    const data = loading ? null : (
      providerRequestKeyPairs.reduce( (acc, [provider, key]) => {
        acc[provider] = get(comparisonsByProvider, [key, 'data'], null)
        return acc
      }, {} as Record<PrimaryPerformanceDataProvider, DoubleVerifyPerformanceComparisons | null>)
    )

    return {
      loading,
      data,
      error: null,
    }

  }, [comparisonsByProvider, reference_date, enabledDoubleVerifyProviders])

}


export function useDoubleVerifyTimeseriesByProvider({
  org_unit_id,
  period,
  chunking,
  reference_date,
  enabledDoubleVerifyProviders,
}: (
  Nullable<PlatformUnitParams> &
  PerformanceReportingAggregationParams &
  Partial<
    ReferenceDateParams
  > & {
    enabledDoubleVerifyProviders?: DoubleVerifyProvider[] | null
  }
)): ApiResponse<Record<PrimaryPerformanceDataProvider, DoubleVerifyProviderPerformanceTimeseries | null>> {

  const timeseriesByProvider = useSelector<
    StoreState,
    Record<
      string, ApiResponse<DoubleVerifyProviderPerformanceTimeseries>
    > | null
  >( state => (
    state.performanceReporting.timeseries.byDoubleVerifyProvider
  ))

  const dispatch = useDispatch()

  useEffect(() => {
    
    reportProviders.forEach( provider => {
      const enabled = !!(
        enabledDoubleVerifyProviders &&
        enabledDoubleVerifyProviders.includes(providerDoubleVerifyMap[provider])
      )
      if( enabled && org_unit_id ){
        const requestKey = getDoubleVerifyProviderPerformanceTimeseriesKey({
          org_unit_id,
          provider,
          period,
          chunking,
          reference_date,
        })
        const wrapper = timeseriesByProvider && timeseriesByProvider[requestKey]
        if( !wrapper || shouldAttemptLoad(wrapper) ){
          dispatch(loadDoubleVerifyProviderPerformanceTimeseries({
            org_unit_id,
            provider,
            period,
            chunking,
            reference_date,
          }))
        }
      }
    })
  }, [dispatch, timeseriesByProvider, org_unit_id, reference_date, period, chunking, enabledDoubleVerifyProviders])

  return useMemo(() => {

    const expectedResponseLength = get(enabledDoubleVerifyProviders, 'length', 3)

    if( !expectedResponseLength ){
      return {
        loading: false,
        data: null,
        error: null,
      }
    }

    const enabledProviders = (
      !enabledDoubleVerifyProviders ?
        [] :
        reportProviders.filter( provider => enabledDoubleVerifyProviders.includes(providerDoubleVerifyMap[provider]) )
    )

    const providerRequestKeyPairs: [ReportProvider, string][] = enabledProviders.map( provider => {
      return [
        provider,
        getDoubleVerifyProviderPerformanceTimeseriesKey({
          org_unit_id,
          provider,
          period,
          chunking,
          reference_date,
        })
      ]
    })

    const responses: ApiResponse[] = (
      timeseriesByProvider ?
        providerRequestKeyPairs.map( ([ , k]) => timeseriesByProvider[k] ) :
        []
    )
    const loading = responses.length < expectedResponseLength || some(responses, isLoading)

    const data = loading ? null : (
      providerRequestKeyPairs.reduce( (acc, [provider, key]) => {
        acc[provider] = get(timeseriesByProvider, [key, 'data'], null)
        return acc
      }, {} as Record<PrimaryPerformanceDataProvider, DoubleVerifyProviderPerformanceTimeseries | null>)
    )

    return {
      loading,
      data,
      error: null,
    }

  }, [timeseriesByProvider, reference_date, org_unit_id, period, chunking, enabledDoubleVerifyProviders])

}


export function useNestedProviderComparisons({
  org_unit_ids,
  provider,
  reference_date,
  target_currency,
}: (
  Nullable<
    PerformanceProviderParams
  > &
  Partial<
    ReferenceDateParams
  > & {
    org_unit_ids: string[]
    target_currency?: string
  }
)): ApiResponse<Record<string, PerformanceComparisons | null>> {

  const dispatch = useDispatch()

  const comparisonsByProvider = useSelector<
    StoreState,
    Record<string, Record<
      string, ApiResponse<PerformanceComparisons>
    > | null>
  >( state => (
    state.performanceReporting.comparisonsByProvider
  ))

  useEffect(() => {
    if( provider ){
      org_unit_ids.forEach( org_unit_id => {
        const providerWrapper = comparisonsByProvider[org_unit_id]
        const requestKey = getNestedComparisonKey({
          provider,
          reference_date,
          target_currency,
        })
        const wrapper = providerWrapper && providerWrapper[requestKey]
        if( !wrapper || shouldAttemptLoad(wrapper) ){
          dispatch(loadProviderPerformanceComparisons({
            provider,
            org_unit_id,
            reference_date,
            target_currency,
          }))
        }
      })
    }
  }, [dispatch, org_unit_ids, provider, comparisonsByProvider, reference_date, target_currency])

  return useMemo(() => {

    const responses: ApiResponse[] = (
      org_unit_ids.map( org_unit_id => {
        const requestKey = getNestedComparisonKey({
          provider,
          reference_date,
          target_currency,
        })
        return get(comparisonsByProvider, [org_unit_id, requestKey])
      }).filter( (a): a is ApiResponse => !!a )
    )
    const loading = responses.length !== org_unit_ids.length || some(responses, isLoading)

    const data = loading ? null : (
      org_unit_ids.reduce( (acc, org_unit_id) => {
        const requestKey = getNestedComparisonKey({
          provider,
          reference_date,
          target_currency,
        })
        if( org_unit_id && requestKey ){
          acc[org_unit_id] = get(comparisonsByProvider, [org_unit_id, requestKey, 'data'])
        }
        return acc
      }, {} as Record<string, PerformanceComparisons | null>)
    )

    return {
      loading,
      data,
      error: null,
    }

  }, [provider, reference_date, target_currency, org_unit_ids, comparisonsByProvider])

}


export function useNestedDoubleVerifyProviderComparisons({
  org_unit_ids,
  provider,
  reference_date,
  enabled,
}: (
  Nullable<
    PerformanceProviderParams
  > &
  Partial<
    ReferenceDateParams
  > & {
    org_unit_ids: string[]
    enabled?: boolean
  }
)): ApiResponse<Record<string, DoubleVerifyPerformanceComparisons | null>> {

  const dispatch = useDispatch()

  const comparisonsByProvider = useSelector<
    StoreState,
    Record<string, Record<
      string, ApiResponse<DoubleVerifyPerformanceComparisons>
    > | null>
  >( state => (
    state.performanceReporting.doubleVerifyComparisonsByProvider
  ))

  useEffect(() => {
    if( enabled && provider ){
      org_unit_ids.forEach( org_unit_id => {
        const providerWrapper = comparisonsByProvider[org_unit_id]
        const requestKey = getNestedDoubleVerifyComparisonKey({
          provider,
          reference_date,
        })
        const wrapper = providerWrapper && providerWrapper[requestKey]
        if( !wrapper || shouldAttemptLoad(wrapper) ){
          dispatch(loadDoubleVerifyProviderPerformanceComparisons({
            provider,
            org_unit_id,
            reference_date,
          }))
        }
      })
    }
  }, [dispatch, org_unit_ids, provider, comparisonsByProvider, reference_date, enabled])

  return useMemo(() => {

    if( !enabled ){
      return {
        loading: false,
        data: null,
        error: null,
      }
    }

    const responses: ApiResponse[] = (
      org_unit_ids.map( org_unit_id => {
        const requestKey = getNestedDoubleVerifyComparisonKey({
          provider,
          reference_date,
        })
        return get(comparisonsByProvider, [org_unit_id, requestKey])
      }).filter( (a): a is ApiResponse => !!a )
    )
    const loading = responses.length !== org_unit_ids.length || some(responses, isLoading)

    const data = loading ? null : (
      org_unit_ids.reduce( (acc, org_unit_id) => {
        const requestKey = getNestedDoubleVerifyComparisonKey({
          provider,
          reference_date,
        })
        if( org_unit_id && requestKey ){
          acc[org_unit_id] = get(comparisonsByProvider, [org_unit_id, requestKey, 'data'])
        }
        return acc
      }, {} as Record<string, DoubleVerifyPerformanceComparisons | null>)
    )

    return {
      loading,
      data,
      error: null,
    }

  }, [provider, reference_date, org_unit_ids, comparisonsByProvider, enabled])

}


export function useNestedProviderTimeseries({
  org_unit_ids,
  provider,
  period,
  chunking,
  target_currency,
  reference_date,
}: (
  Nullable<
    PerformanceProviderParams
  > &
    PerformanceReportingAggregationParams &
    PerformanceReportingCurrencyParams &
    Partial<
      ReferenceDateParams
    > & {
      org_unit_ids: string[]
    }
)): ApiResponse<Record<string, ProviderPerformanceTimeseries | null>> {

  const dispatch = useDispatch()

  const timeseriesByProvider = useSelector<
    StoreState,
    Record<
      string, ApiResponse<ProviderPerformanceTimeseries>
    >
  >( state => (
    state.performanceReporting.timeseries.byProvider
  ))

  useEffect(() => {
    if( provider ){
      org_unit_ids.forEach( org_unit_id => {
        const key = getProviderPerformanceTimeseriesKey({
          org_unit_id,
          provider,
          period,
          chunking,
          target_currency,
          reference_date,
        })
        const wrapper = timeseriesByProvider[key]
        if( key && (!wrapper || shouldAttemptLoad(wrapper)) ){
          dispatch(loadProviderPerformanceTimeseries({
            provider,
            org_unit_id,
            period,
            chunking,
            target_currency,
            reference_date,
          }))
        }
      })
    }
  }, [dispatch, org_unit_ids, provider, timeseriesByProvider, period, chunking, target_currency, reference_date])

  return useMemo(() => {

    const idRequestKeyPairs: (string | null)[][] = org_unit_ids.map( org_unit_id => (
      [
        org_unit_id,
        getProviderPerformanceTimeseriesKey({
          org_unit_id,
          provider,
          period,
          chunking,
          target_currency,
          reference_date,
        }),
      ]
    ))

    const responses: ApiResponse[] = (
      idRequestKeyPairs.map( ([/* id */, key]) => (
        key && timeseriesByProvider[key]
      )).filter( (a): a is ApiResponse => !!a )
    )
    const loading = responses.length !== org_unit_ids.length || some(responses, isLoading)

    const data = loading ? null : (
      idRequestKeyPairs.reduce( (acc, [org_unit_id, key]) => {
        if( org_unit_id && key ){
          acc[org_unit_id] = timeseriesByProvider[key].data
        }
        return acc
      }, {} as Record<string, ProviderPerformanceTimeseries | null>)
    )

    return {
      loading,
      data,
      error: null,
    }

  }, [provider, org_unit_ids, timeseriesByProvider, period, chunking, target_currency, reference_date])

}


export function useNestedTimeseriesByProvider({
  org_unit_ids,
  period,
  chunking,
  target_currency,
  reference_date,
}: (
    PerformanceReportingAggregationParams &
    PerformanceReportingCurrencyParams &
    Partial<
      ReferenceDateParams
    > & {
      org_unit_ids: string[]
    }
)): ApiResponse<Record<string, Record<PrimaryPerformanceDataProvider, ProviderPerformanceTimeseries | null>>> {

  const dispatch = useDispatch()

  const aggregatedByProvider = useSelector<
    StoreState,
    Record<
      string, ApiResponse<PerformanceTimeseriesByProvider>
    >
  >( state => (
    state.performanceReporting.timeseries.aggregated
  ))

  useEffect(() => {

    org_unit_ids.forEach( org_unit_id => {
      const key = getPerformanceTimeseriesKey({
        org_unit_id,
        period,
        chunking,
        target_currency,
        reference_date,
      })
      const wrapper = aggregatedByProvider[key]
      if( key && (!wrapper || shouldAttemptLoad(wrapper)) ){
        dispatch(loadPerformanceTimeseriesByProvider({
          org_unit_id,
          period,
          chunking,
          target_currency,
          reference_date,
        }))
      }
    })

  }, [dispatch, org_unit_ids, aggregatedByProvider, period, chunking, target_currency, reference_date])

  return useMemo(() => {

    const requestParams = org_unit_ids.reduce( (acc, id) => {
      acc.push({
        id,
        key: getPerformanceTimeseriesKey({
          org_unit_id: id,
          period,
          chunking,
          target_currency,
          reference_date,
        }),
      })
      return acc 
    }, [] as {
      id: string | null
      key: string | null
    }[])

    const responses: ApiResponse[] = (
      requestParams.map( ({ key }) => (
        key && aggregatedByProvider[key]
      )).filter( (a): a is ApiResponse => !!a )
    )
    const loading = responses.length !== requestParams.length || some(responses, isLoading)

    const data = loading ? null : (
      requestParams.reduce( (acc, { id, key }) => {
        if( id && key ){
          const data = aggregatedByProvider[key].data
          if( data ){
            acc[id] = data
          }
        }
        return acc
      }, {} as Record<string, Record<PrimaryPerformanceDataProvider, ProviderPerformanceTimeseries | null>>)
    )

    return {
      loading,
      data,
      error: null,
    }

  }, [org_unit_ids, aggregatedByProvider, period, chunking, target_currency, reference_date])

}


export function useNestedDoubleVerifyProviderTimeseries({
  org_unit_ids,
  provider,
  period,
  chunking,
  reference_date,
  enabled,
}: (
  Nullable<
    PerformanceProviderParams
  > &
    PerformanceReportingAggregationParams &
    Partial<
      ReferenceDateParams
    > & {
      org_unit_ids: string[]
      enabled?: boolean
    }
)): ApiResponse<Record<string, DoubleVerifyProviderPerformanceTimeseries | null>> {

  const dispatch = useDispatch()

  const timeseriesByProvider = useSelector<
    StoreState,
    Record<
      string, ApiResponse<DoubleVerifyProviderPerformanceTimeseries>
    >
  >( state => (
    state.performanceReporting.timeseries.byDoubleVerifyProvider
  ))

  useEffect(() => {
    if( enabled && provider ){
      org_unit_ids.forEach( org_unit_id => {
        const key = getDoubleVerifyProviderPerformanceTimeseriesKey({
          org_unit_id,
          provider,
          period,
          chunking,
          reference_date,
        })
        const wrapper = timeseriesByProvider[key]
        if( key && (!wrapper || shouldAttemptLoad(wrapper)) ){
          dispatch(loadDoubleVerifyProviderPerformanceTimeseries({
            provider,
            org_unit_id,
            period,
            chunking,
            reference_date,
          }))
        }
      })
    }
  }, [dispatch, org_unit_ids, provider, timeseriesByProvider, period, chunking, reference_date, enabled])

  return useMemo(() => {

    if( !enabled ){
      return {
        loading: false,
        data: null,
        error: null,
      }
    }

    const idRequestKeyPairs: (string | null)[][] = org_unit_ids.map( org_unit_id => (
      [
        org_unit_id,
        getDoubleVerifyProviderPerformanceTimeseriesKey({
          org_unit_id,
          provider,
          period,
          chunking,
          reference_date,
        }),
      ]
    ))

    const responses: ApiResponse[] = (
      idRequestKeyPairs.map( ([/* id */, key]) => (
        key && timeseriesByProvider[key]
      )).filter( (a): a is ApiResponse => !!a )
    )
    const loading = responses.length !== org_unit_ids.length || some(responses, isLoading)

    const data = loading ? null : (
      idRequestKeyPairs.reduce( (acc, [org_unit_id, key]) => {
        if( org_unit_id && key ){
          acc[org_unit_id] = timeseriesByProvider[key].data
        }
        return acc
      }, {} as Record<string, DoubleVerifyProviderPerformanceTimeseries | null>)
    )

    return {
      loading,
      data,
      error: null,
    }

  }, [provider, org_unit_ids, timeseriesByProvider, period, chunking, reference_date, enabled])

}
