import React, { useMemo } from 'react'

import { AnimatedPie } from './AnimatedPie'

import Pie, { PieArcDatum } from '@visx/shape/lib/shapes/Pie'

import { Group } from '@visx/group'

import { Legend } from '../Legend'

import { makeChartWithTooltip } from '../makeChartWithTooltip'

import { makeResponsiveChart } from '../makeResponsiveChart'

import { getLabel, getValue } from '../lib'

import { DatumType } from '@percept/types'

import { ChartData, SVGDatumType } from '../typings'

import { DonutProps, PieArcDatumEventHandler } from './typings'


function pieValue<T extends SVGDatumType>(datum: T): number {
  return getValue(datum) || 0
}


function getKey<T extends SVGDatumType>({ data }: PieArcDatum<T>): string {
  return getLabel(data).toString()
}


function getColor<T extends SVGDatumType>({ data }: PieArcDatum<T>): string {
  return data.color || 'red'
}


// Constant sorting to retain segment order
function pieSort(): number {
  return -1
}


function resolvePadAngle<T extends SVGDatumType>(data: ChartData<T>, padAngle: number): number {
  if( data.length === 1 ) return 0
  let withSignal = 0
  for( const d of data ){
    if( d.value ) withSignal++
    if( withSignal > 1 ) return padAngle
  }
  return 0
}


const divStyle: React.CSSProperties = {
  position: 'relative',
}

export function Donut<T extends SVGDatumType>({
  data,
  width,
  height,
  onSegmentClick,
  onSegmentMouseOver,
  onSegmentMouseOut,
  padAngle = 0.02,
  // Default start angle at 12 o'clock,
  // with segments radiating clockwise over 360°
  startAngle = Math.PI * 2,
  endAngle = Math.PI * -2,
  cornerRadius = 0,
  donutThickness = 0.35,
  animate = true,
  animateInitial = false,
  showLabels = false,
  legend = false,
  pathProps,
  textProps,
  containerRef,
  backgroundElement,
  foregroundElement,
  svgClassName,
  defs,
}: DonutProps<T>): JSX.Element | null {

  const size = Math.min(width, height)
  const radius = size / 2
  const innerRadius = radius - (radius * donutThickness)

  // Filter out any segments without signal to avoid compound
  // padded angles spanning empty datasets
  const { filteredData, resolvedPadAngle } = useMemo(() => {
    if( padAngle === 0 ){
      return {
        filteredData: data,
        resolvedPadAngle: 0,
      }
    }
    const filteredData = data.filter( d => !!d.value )
    return {
      filteredData,
      resolvedPadAngle: resolvePadAngle(filteredData, padAngle)
    }
  }, [data, padAngle])

  const onMouseOverDatum: PieArcDatumEventHandler<T> | undefined = (
    onSegmentMouseOver ?
      ((e, d): void => {
        onSegmentMouseOver(e, d.data, data)
      }) :
      undefined
  )

  const onClickDatum: PieArcDatumEventHandler<T> | undefined = (
    onSegmentClick ?
      ((e, d): void => {
        onSegmentClick(d.data)
      }) :
      undefined
  )

  if( !size ){
    return (
      <div style={divStyle}>
        <svg ref={containerRef} width={width || '100%'} height={height || '100%'} />
      </div>
    )
  }

  return (
    <div style={divStyle}>
      <svg
        ref={containerRef}
        className={svgClassName}
        width={size}
        height={size}
        overflow='visible'>

        { defs && <defs>{defs}</defs> }

        { backgroundElement || null }

        <Group top={radius} left={radius}>

          <Pie
            data={filteredData}
            pieValue={pieValue}
            outerRadius={radius}
            innerRadius={innerRadius}
            cornerRadius={cornerRadius}
            padAngle={resolvedPadAngle}
            startAngle={startAngle}
            endAngle={endAngle}
            pieSort={pieSort}>
            { (pieProps): JSX.Element => {
              return (
                <AnimatedPie
                  {...pieProps}
                  pathProps={pathProps}
                  textProps={textProps}
                  showLabels={showLabels}
                  animate={animate}
                  animateInitial={animateInitial}
                  getKey={getKey}
                  getColor={getColor}
                  onClickDatum={onClickDatum}
                  onMouseOverDatum={onMouseOverDatum}
                  onMouseOutDatum={onSegmentMouseOut} />
              )
            }}
          </Pie>

        </Group>

        { foregroundElement || null }

      </svg>

      { legend && (
        <Legend
          mt={3}
          data={data}
          onSegmentClick={onSegmentClick} />
      )}

    </div>
  )
}

Donut.displayName = 'Donut'


export const DonutWithTooltip = makeChartWithTooltip<DatumType, DonutProps<DatumType>>(Donut)


export const ResponsiveDonutWithTooltip = makeResponsiveChart(
  DonutWithTooltip
)
