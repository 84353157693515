
import { makeAppStyles } from '../../themes'


export const useCommonMetricStyles = makeAppStyles( theme => ({
  muted: {
    color: `${theme.palette.action.disabled} !important`,
  },
  actionButton: {
    marginBottom: theme.spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  dashboardBackdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
}) )
