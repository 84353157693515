

import React, { Fragment } from 'react'

import { animated, useTransition, interpolate } from 'react-spring'

import { PieArcDatum } from '@visx/shape/lib/shapes/Pie'

import { getColor } from '../lib'

import { AnimatedPieProps } from './typings'

import { SVGDatumType } from '../typings'


// react-spring transition definitions
type AnimatedStyles = Partial<{
  startAngle: number
  endAngle: number
  opacity: number
  color?: string
  fillOpacity?: number
}>


function fromLeaveTransition<T extends SVGDatumType>({ endAngle, data }: PieArcDatum<T>): AnimatedStyles {
  const color = getColor(data) || undefined
  return {
    // enter from 360° if end angle is > 180°
    startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
    endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
    opacity: 0,
    color,
    fillOpacity: 0,
  }
}


function enterUpdateTransition<T extends SVGDatumType>({ startAngle, endAngle, data }: PieArcDatum<T>): AnimatedStyles {
  const color = getColor(data) || undefined
  return {
    startAngle,
    endAngle,
    opacity: 1,
    color,
    fillOpacity: data.fillOpacity === undefined ? 1 : Number(data.fillOpacity),
  }
}


export function AnimatedPie<Datum extends SVGDatumType>({
  animate = true,
  animateInitial = false,
  arcs,
  path,
  getKey,
  getColor,
  onClickDatum,
  onMouseOverDatum,
  onMouseOutDatum,
  showLabels = false,
  pathProps = {},
  textProps = {},
}: AnimatedPieProps<Datum>): JSX.Element {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(
    arcs,
    getKey,
    {
      from: animateInitial ? fromLeaveTransition : enterUpdateTransition,
      enter: enterUpdateTransition,
      update: enterUpdateTransition,
      leave: animate ? fromLeaveTransition : enterUpdateTransition,
    },
  )
  return (
    <Fragment>
      { transitions.map(
        ({
          item: arc,
          props,
          key,
        }: {
          item: PieArcDatum<Datum>
          props: AnimatedStyles
          key: string
        }) => {

          let centroid = [0, 0]

          const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1

          if( showLabels && hasSpaceForLabel ){
            centroid = path.centroid(arc)
          }

          const fill = getColor(arc)

          return (
            <g key={key}>
              <animated.path
                // compute interpolated path d attribute from intermediate angle values
                d={interpolate([props.startAngle as number, props.endAngle as number], (startAngle, endAngle) =>
                  path({
                    ...arc,
                    startAngle,
                    endAngle,
                  }),
                )}
                fill={props.color || fill}
                stroke={props.color || fill}
                strokeWidth={0}
                {...pathProps}
                fillOpacity={props.fillOpacity}
                cursor={onClickDatum ? 'pointer' : 'default'}
                onClick={
                  onClickDatum ?
                    ((e): void => onClickDatum(e, arc)) :
                    undefined
                }
                onTouchStart={
                  onClickDatum ?
                    ((e): void => onClickDatum(e, arc)) :
                    undefined
                }
                onMouseMove={
                  onMouseOverDatum ?
                    ((e): void => onMouseOverDatum(e, arc)) :
                    undefined
                }
                onMouseOut={onMouseOutDatum} />

              { showLabels && hasSpaceForLabel && (
                <animated.g style={{ opacity: props.opacity }}>
                  <text
                    fill='currentColor'
                    dy='.33em'
                    fontSize={10}
                    fontWeight={500}
                    textAnchor='middle'
                    pointerEvents='none'
                    {...textProps}
                    x={centroid[0]}
                    y={centroid[1]}>
                    { getKey(arc) }
                  </text>
                </animated.g>
              )}
            </g>
          )
        },
      ) }
    </Fragment>
  )
}
