import React from 'react'


export const TextShadow = ({
  id = 'textShadow',
  ...props
}: React.SVGAttributes<SVGFilterElement>): JSX.Element => (
  <filter id={id} height='140%' y='-20%' width='140%' x='-20%' {...props}>
    <feDropShadow dx='0' dy='0' stdDeviation='4' />
    <feDropShadow dx='0' dy='0' stdDeviation='6' />
    <feDropShadow dx='0' dy='0' stdDeviation='8' />
    {/* <feDropShadow dx='0' dy='0' stdDeviation='10' />
    <feDropShadow dx='0' dy='0' stdDeviation='10' /> */}
  </filter>
)