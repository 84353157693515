
import { combineReducers } from 'redux'

import {
  createApiReducer,
  createKeyedReducer,
} from '../../reducers'

import { reportLayout } from '@percept/api'

import { LOAD_LAYOUT } from './actions'

import { LayoutType } from '@percept/types'

import { LayoutState } from './typings'


export default combineReducers<LayoutState>({
  byId: createKeyedReducer(
    createApiReducer<LayoutType>(LOAD_LAYOUT, {
      getPayloadFromAction: ({ payload }) => reportLayout(payload.payload)
    }),
    action => action.meta && action.meta.report_layout_id || null
  )
})
