import React, { useEffect, useState } from 'react'

import { ChannelThemeProvider } from '@percept/mui'

import { HealthVsPerformanceView, HealthVsPerformanceViewProps } from './HealthVsPerformanceView'

import { useActiveOrgEnvironment } from 'components/Organisation'

import { providerChannelMap } from '@percept/constants'

import { ReportProvider } from '@percept/types'


export type HealthVsPerformanceProps = Pick<HealthVsPerformanceViewProps, 'org_unit_id'>


export const HealthVsPerformance = (props: HealthVsPerformanceProps): JSX.Element => {

  const { activePlatformUnit, providers, ready } = useActiveOrgEnvironment()

  const [activeProviders, setActiveProviders] = useState<ReportProvider[]>([])

  const channel = activeProviders.length === 1 ? providerChannelMap[activeProviders[0]] : null

  useEffect(() => {
    if( providers.length && !activeProviders.length ){
      setActiveProviders(providers)
    }
  }, [providers, activeProviders])

  return (
    <ChannelThemeProvider
      channel={channel}>
      <HealthVsPerformanceView
        activeProviders={activeProviders}
        setActiveProviders={setActiveProviders}
        defaultProviders={providers}
        ready={ready}
        platformUnit={activePlatformUnit}
        {...props} />
    </ChannelThemeProvider>
  )
}
