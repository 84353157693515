import { Box, Card, CardContent, Collapse, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { useMemo, useRef, useState } from 'react'
import { TabSectionHeader } from '../PartnershipTabs'
import { ProviderLogo, SecondaryIconTooltip, makeAppStyles, useAppTheme } from '@percept/mui'
import { GraphData, YoutubeDimension, YoutubeGraphData, YoutubeReportProp } from '../types'
import { graphDomains } from '../PartnershipsData'
import { MultiDataset, ResponsiveMultiLine, SVGDatumType, dmyFormatter, longMonthYearFormatter, percentageFormatter } from '@percept/mui/charts'
import { partition, sortBy } from 'lodash-es'

const tooltips: Partial<Record<YoutubeDimension, string>> = {
  'audience_index': 'Score awarded for targeting certain types of audiences in a campaign and whether it\'s relevant for campaign\'s marketing objective',
  'account_format_mix': 'Score awarded for using a certain number of video formats in the advertiser account or video ad variations for YT',
  'campaign_set_up' : 'Score awarded for having certain campaign settings best practices implemented',
  'measurement_index': 'Score awarded for using a certain number of measurement solutions in the account of the campaign',
}

export type YoutubeReportProps = {
  report: YoutubeReportProp
  graphData: YoutubeGraphData
  headers: string[]
  creativeQualityScore: number | null
  globalAverageCreativeQualityScore: number | null
}

const useStyles = makeAppStyles( theme => ({
  tableHeader: {
    backgroundColor: theme.palette.background.default,
  },
  tableCell: {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  itemDividedTop: {
    borderTop: `1px solid ${theme.palette.action.active}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  logo: {
    marginRight: 2,
    position: 'relative',
    top: 1,
  },
  colorGreen: {
    backgroundColor: theme.palette.success.main
  },
  colorYellow: {
    backgroundColor: theme.palette.warning.main
  },
  colorRed: {
    backgroundColor: theme.palette.error.main
  },
  tooltip: {
    marginLeft: theme.spacing(1),
  },
}))

export const YoutubeReport = (
  { report, headers, graphData, creativeQualityScore, globalAverageCreativeQualityScore }: YoutubeReportProps
): JSX.Element => {
  const classes = useStyles()
  const appTheme = useAppTheme()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const graphColorScale = useRef(
    appTheme.chart.getOrdinalColourScale(graphDomains.map(domain => domain.value))
  )
  const healthColourScale = appTheme.chart.generateHealthColourScale([report.min_value, report.max_value])
  const getCellColorClass = (value: string | number | null): string => {
    if(!value) return ''
    return healthColourScale(+value)
  }

  const datasets = useMemo(() => {
    return graphDomains.map( ({ value, label }) => ({
      key: value,
      label: label,
      color: graphColorScale.current(value),
      data: sortBy(
        graphData[value as keyof GraphData].map( item => ({
          value: item.value,
          label: new Date(item.report_date).getTime()
        })),
        'label'
      ),
    })) as MultiDataset<SVGDatumType>[]
  }, [graphData])

  const [campaignCountRows, otherRows] = partition(report.data, d => d.dimension === 'campaign_count')

  return(
    <Card elevation={2}>
      <TabSectionHeader
        title={
          <ProviderLogo provider='youtube' size={1.5} />
        }
        my={1.5}
        px={2}
        isCollapsed={isCollapsed}
        handleCollapse={setIsCollapsed}/>
      <Collapse in={!isCollapsed}>
        <CardContent>
          <TableContainer component={Paper}>
            <Table aria-label='customized table'>
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell className={classes.tableCell}>
                    {dmyFormatter(report.report_date)}
                  </TableCell>
                  {headers.map(header => {
                    return (<TableCell className={classes.tableCell} key={header} align='center'>{header}</TableCell>)
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {otherRows.map((row) => {
                  const tooltipContent = tooltips[row.dimension]
                  return (
                    <TableRow key={row.label}>
                      <TableCell className={classes.tableCell} component='th' scope='row' style={{ verticalAlign: 'middle' }}>
                        <Box display='flex' alignItems='center'>
                          {row.label}
                          { tooltipContent && (
                            <SecondaryIconTooltip className={classes.tooltip} title={tooltipContent} />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align='center' style={{backgroundColor: getCellColorClass(+row.score)}}>{Math.round(+row.score)}</TableCell>
                      <TableCell align='center' style={{backgroundColor: getCellColorClass(+row.global_average)}}>{Math.round(+row.global_average)}</TableCell>
                    </TableRow>
                  )
                })}

                <TableRow>
                  <TableCell className={classes.tableCell} component='th' scope='row'>Total %</TableCell>
                  <TableCell className={classes.tableCell} align='center'>{(+report.total_percent).toFixed(1)}%</TableCell>
                  <TableCell className={classes.tableCell} align='center'>{(+report.total_percent_global_avg).toFixed(1)}%</TableCell>
                </TableRow>

                {campaignCountRows.map((row) => (
                  <TableRow key={row.label}>
                    <TableCell className={classes.tableCell} component='th' scope='row'>
                      {row.label}
                    </TableCell>
                    <TableCell className={classes.tableCell} align='center'>{Math.round(+row.score)}</TableCell>
                    <TableCell className={classes.tableCell} align='center'>{Math.round(+row.global_average)}</TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell className={`${classes.tableCell} ${classes.itemDividedTop}`} component='th' scope='row'>
                    <span className={classes.flexCenter}>
                      <ProviderLogo className={classes.logo} provider='CREATIVE_X' size={1.35} />
                      Quality Score
                    </span>
                  </TableCell>
                  <TableCell className={`${classes.tableCell} ${classes.itemDividedTop}`} align='center'>
                    { creativeQualityScore === null ? '-' : percentageFormatter(creativeQualityScore * 100)}
                  </TableCell>
                  <TableCell className={`${classes.tableCell} ${classes.itemDividedTop}`} align='center'>
                    { globalAverageCreativeQualityScore === null ? '-' : percentageFormatter(globalAverageCreativeQualityScore * 100)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={3} pr={6} pl={2} bgcolor='grey.100' mt={4} mb={2}>
            <ResponsiveMultiLine
              height={300}
              grid
              axisText
              axisLine
              xTickFormatter={longMonthYearFormatter}
              xScaleType='time'
              datasets={datasets} />
          </Box>
          <Box display='flex' alignItems='center' justifyContent='center'>
            {graphDomains.map(domain => (
              <Box key={domain.value}  display='flex' alignItems='center' justifyContent='center'>
                <Box mx={1} width={10} height={10} bgcolor={graphColorScale.current(domain.value)}/><Typography>{domain.label}</Typography>
              </Box>))}
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  )
}
