import { theming } from '@percept/redux/bundles'

import { makeSelectorHook, makeSynchronousHook } from '@percept/hooks/libv2'


export const useActiveAppTheme = makeSelectorHook(
  theming.selectors.getActiveAppTheme
)

export const useManageThemeTypePreference = makeSynchronousHook(
  theming.selectors.getAppThemeTypePreference,
  theming.actions.setAppThemeTypePreference,
)
