
import React, { Fragment } from 'react'

import { withAppStyles } from '../../themes'


const globalCssOverrides: Record<'@global', Record<string, React.CSSProperties>> = {
  '@global': {
    /* Enable MUI and Semantic UI to better co-exist in transitional applications */
    'html': {
      fontSize: 'unset !important',
    },
    'svg:not(:root)': {
      overflow: 'visible',
    },
    /* Disable raw browser anchor styling */
    'a': {
      textDecoration: 'none',
      color: 'inherit',
    },
    /* Fix padding glitch affecting use of Dialog / Popover in more recent Windows/Edge environments */ 
    '#app': {
      paddingLeft: 'calc(100vw - 100%)',
    },
    /* Disable browser focus style override in earlier iOS Safari versions */
    '*': {
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0) !important',
    }
  },
}

export const GlobalCss = withAppStyles(
  globalCssOverrides
)(
  ({ children }: { children?: React.ReactChild | React.ReactChild[] }): JSX.Element => (
    <Fragment>
      { children }
    </Fragment>
  )
)
