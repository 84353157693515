import React, { SVGAttributes } from 'react'
import { useAppTheme } from '@percept/mui'
import { ScaleLinear } from 'd3'
import { PositionalAttributes, SizeAttributes } from './types'


export type QuadrantChartChildProps = SizeAttributes & PositionalAttributes & {
  healthColourScale: ScaleLinear<number, string>
  midPointX: number
  midPointY: number
}

export type QuadrantChartProps = (
  SizeAttributes &
  Partial<SVGAttributes<SVGSVGElement>> & {
    svgRef?: React.MutableRefObject<SVGSVGElement | null>
    children: (props: QuadrantChartChildProps) => JSX.Element
  }
)


const scaChartAxisRange: { value: number, label: string }[] = [
  {
    value: 0,
    label: 'Bottom 20%',
  },
  {
    value: 0.25,
    label: 'Bottom 40%',
  },
  {
    value: 0.5,
    label: 'Top 60%',
  },
  {
    value: 0.75,
    label: 'Top 40%',
  },
  {
    value: 1,
    label: 'Top 20%',
  },
]


type SCAChartAxisProps = SizeAttributes & {
  colourScale: ScaleLinear<number, string>
} & Partial<SVGAttributes<SVGGElement>>

export const SCAChartAxis = ({
  colourScale,
  width,
  height,
  ...props
}: SCAChartAxisProps): JSX.Element => {
  const tickWidth = Math.floor(width / scaChartAxisRange.length)
  return (
    <g {...props}>
      { scaChartAxisRange.map( ({ value, label }, i) => (
        <g key={label}>
          <rect
            width={tickWidth}
            height={height}
            fill={colourScale(value)}
            x={`${tickWidth * i}`} />
          <text
            x={`${tickWidth * i}`}
            fontSize={height / 1.5}
            fontWeight={600}
            fill='#FFF'
            stroke='none'
            textAnchor='middle'
            dx={tickWidth / 2}
            dy={height / 2}
            alignmentBaseline='central'>
            { label }
          </text>
        </g>
      ))}
    </g>
  )
}


export const QuadrantChart = ({
  width,
  height,
  children,
  svgRef,
  ...props
}: QuadrantChartProps): JSX.Element => {

  const appTheme = useAppTheme()

  const { healthColourScale } = appTheme.chart

  const axisMargin = 20
  const axisTextMargin = 30
  const axisTextFontSize = 18

  const marginOffset = axisMargin + axisTextMargin
  
  const offsetWidth = width - marginOffset
  const offsetHeight = height - marginOffset

  const midPointY = offsetHeight * 0.4
  const midPointX = offsetWidth * 0.6

  // NOTE - define both midpoints as half surface for square quadrants
  // const midPointY = offsetHeight / 2
  // const midPointX = offsetWidth / 2

  return (
    <svg ref={svgRef} height={height} width={width} {...props}>
      <SCAChartAxis
        transform={`rotate(-90 ${offsetHeight} ${-axisTextMargin}) translate(${-axisTextMargin} ${-offsetHeight})`}
        width={offsetHeight}
        height={axisMargin}
        colourScale={healthColourScale} />
      
      <SCAChartAxis
        transform={`translate(${axisMargin + axisTextMargin} ${offsetHeight})`}
        width={offsetWidth}
        height={axisMargin}
        colourScale={healthColourScale} />

      <text
        y={offsetHeight / 2}
        x={axisTextMargin / 2}
        textAnchor='middle'
        dominantBaseline='central'
        transform={`rotate(-90 ${axisTextMargin / 2} ${offsetHeight / 2})`}
        fontSize={axisTextFontSize}>
        Long Term Brand Perceptions (Ad Imprint)
      </text>

      <text
        y={height - (axisTextMargin / 2)}
        x={(offsetWidth / 2) + marginOffset}
        textAnchor='middle'
        dominantBaseline='central'
        fontSize={axisTextFontSize}>
        Short Term Reaction (Triggers)
      </text>

      { children({
        width: offsetWidth,
        height: offsetHeight,
        x: marginOffset,
        y: 0,
        midPointX,
        midPointY,
        healthColourScale,
      }) }
    </svg>
  )
}
