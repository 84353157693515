
import { getAnnotationKey } from '../../utils'

import { apiInitialState } from '../../constants'

import { getPath } from '@percept/utils'

import {
  StoreState,
  Dictionary,
  ApiResponse,
  Annotation,
  ApiStatusResponse,
  AnnotationPoints,
  AnnotationPoint,
  AnnotationHierarchy,
  AnnotationObject,
} from '@percept/types'

import { getAnnotationHierarchyObjectPath } from './lib'


const EMPTY_ARRAY: [] = []


export const getAnnotations = (
  state: StoreState, { series_id }: { series_id: string}
): ApiResponse<Dictionary<Annotation[]>> => (
  state.annotations.bySeriesId[series_id] || apiInitialState
)

export const getAttachedAnnotations = (state: StoreState, props: AnnotationPoints): Annotation[] => (
  getPath(
    getAnnotations(state, props),
    ['data', getAnnotationKey(props)],
    EMPTY_ARRAY
  )
)

export const getCreateAnnotation = (state: StoreState): ApiStatusResponse<Annotation> => (
  state.annotations.create
)

export const getMutateAnnotation = (state: StoreState): ApiStatusResponse<Annotation> => (
  state.annotations.edit
)

export const getDeleteAnnotation = (state: StoreState): ApiStatusResponse<Annotation> => (
  state.annotations.remove
)

export const getAnnotationsActive = (state: StoreState): boolean => (
  state.annotations.active
)

export const getAnnotationContext = (state: StoreState): Record<AnnotationPoint, string | null> => (
  state.annotations.context
)

export const getEntityAnnotationObject = (
  state: StoreState,
  annotationPoints: AnnotationPoints
) => {
  const { series_id } = annotationPoints
  if( series_id ){
    const hierarchy: AnnotationHierarchy | null = getPath(state.annotations.hierarchy, [series_id, 'data'])
    if( hierarchy ){
      const objectPath = getAnnotationHierarchyObjectPath(
        annotationPoints
      )
      return getPath<AnnotationObject | null>(hierarchy, objectPath)
    }
  }
  return null
}
