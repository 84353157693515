
import { useEffect } from 'react'

import { usePrevious } from '@percept/hooks'

import { Location } from 'history'


export type ScrollToTopProps = {
  location: Location
  shouldScroll?: (curr: Location, prev: Location) => boolean
  children: JSX.Element | null
}

export const ScrollToTop = ({ location, shouldScroll, children }: ScrollToTopProps): JSX.Element | null => {

  const previousLocation = usePrevious(location)

  useEffect(() => {
    if( location !== previousLocation && (
      typeof shouldScroll !== 'function' || shouldScroll(location, previousLocation)
    ) ){
      window.scrollTo(0, 0)
    }
  }, [location, previousLocation, shouldScroll])

  return children

}
