import React from 'react'
import { Box, Grid, Typography, makeAppStyles } from '@percept/mui'
import { CheckCircle, Cancel } from '@material-ui/icons'
import { ReportWithDetails } from '../../../api/reports'
import { SegmentFieldset as ISegmentFieldset } from '../../../api/report-messages'
import { SegmentFieldset } from './SegmentFieldset'
import {
  BrandGrowthQuadrant,
  BrandGrowthQuadrantChart,
} from 'components/BrandGrowthQuadrantChart'
import { AssetLink } from 'components/AssetLink'

const useStyles = makeAppStyles((theme) => ({
  title: {
    fontSize: 36,
    fontWeight: 700,
    lineHeight: '40px',
  },
  messageTitle: {
    fontSize: 18,
    lineHeight: '26px',
  },
  segmentFieldset: {
    marginTop: 64,
  },
  principleBoxes: {
    display: 'grid',
    gap: '8px',
  },
  principleBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
    padding: '16px',

    '&.isOnTrack': {
      color: theme.chart.healthColourScale(1),
    },
    '&.isNotOnTrack': {
      color: theme.chart.healthColourScale(0),
    },
  },
  principleBoxNumber: {
    flexShrink: 0,
    marginRight: '16px',
  },
  principleBoxNumberLabel: {
    fontSize: '28px',
    fontWeight: 700,
  },
  principleBoxContent: {
    flex: 1,
  },
  principleBoxIcon: {
    flexShrink: 0,

    '& svg': {
      width: '32px',
      height: '32px',
    },
  },
  principleBoxLegend: {
    fontSize: '20px',
    fontWeight: 700,
  },
  principleBoxSummary: {
    fontSize: '16px',
    marginTop: '4px',
  },
}))

interface ReportProps {
  report: ReportWithDetails
}

export const Report = ({ report }: ReportProps): JSX.Element => {
  const classes = useStyles()

  return (
    <>
      <Grid container alignItems='center' spacing={6}>
        <Grid item sm={6}>
          <Typography variant='h2' className={classes.title}>
            Market Segmentation Overview
          </Typography>

          <Box mt={4}>
            <PrinciplesOverview fieldsets={report.message.fieldsets} />
          </Box>

          <Box mt={4}>
            <Typography className={classes.messageTitle}>
              {report.message.title}
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box height={320}>
            <BrandGrowthQuadrantChart
              markets={[
                {
                  label: report.market.iso_code,
                  value:
                    report.market_segment.toUpperCase() as BrandGrowthQuadrant,
                },
              ]}
            />
          </Box>
        </Grid>
      </Grid>

      <Box className={classes.segmentFieldset}>
        {report.message.fieldsets.map((fieldset, index) => (
          <SegmentFieldset key={index} fieldset={fieldset} marketSegment={report.market_segment} />
        ))}
      </Box>

      <Box mt={6} fontSize={16}>
        If you would like to find out more, please download the full guidance here:
        { ['bgp-planning-for-growth-1.0', 'bgp-planning-for-growth-2.0'].map( assetIdentifier => (
          <Box ml={2} mt={1.5} key={assetIdentifier}>
            <AssetLink assetIdentifier={assetIdentifier} />
          </Box>
        ))}
      </Box>
    </>
  )
}

interface PrinciplesOverviewProps {
  fieldsets: ISegmentFieldset[]
}

export const PrinciplesOverview = ({
  fieldsets,
}: PrinciplesOverviewProps): JSX.Element => {
  const classes = useStyles()

  return (
    <>
      <Typography variant='h3'>
        Principles Covered in Brand Growth Planner
      </Typography>

      <Box mt={2} className={classes.principleBoxes}>
        {fieldsets.map((fieldset, index) => {
          const allStepsOnTrack = fieldset.steps.every(
            (step) => step.is_on_track
          )

          const ordinal = index + 1
          const ordinalFormatted = ordinal < 10 ? `0${ordinal}` : ordinal

          return (
            <Box
              key={index}
              className={`${classes.principleBox} ${
                allStepsOnTrack ? 'isOnTrack' : 'isNotOnTrack'
              }`}>
              <Box className={classes.principleBoxNumber}>
                <Typography className={classes.principleBoxNumberLabel}>
                  {ordinalFormatted}
                </Typography>
              </Box>
              <Box className={classes.principleBoxContent}>
                <Typography className={classes.principleBoxLegend}>
                  {fieldset.legend}
                </Typography>
                {fieldset.summary && (
                  <Typography className={classes.principleBoxSummary}>
                    {fieldset.summary}
                  </Typography>
                )}
              </Box>
              <Box className={classes.principleBoxIcon}>
                {allStepsOnTrack ? <CheckCircle /> : <Cancel />}
              </Box>
            </Box>
          )
        })}
      </Box>
    </>
  )
}
