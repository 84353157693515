
import React, { Fragment, useState } from 'react'

import { IconButton, IconButtonProps, Popover, PopoverProps } from '@material-ui/core'

import { SvgIconComponent } from '../../icons'


export type IconButtonPopoverProps = Omit<IconButtonProps, 'onClick' | 'children'> & {
  IconComponent: SvgIconComponent
  PopoverProps?: Partial<Omit<PopoverProps, 'open' | 'onClose' | 'anchorEl' | 'children'>>
  children: (
    JSX.Element | JSX.Element[] | React.ReactNode
    | ((props: { isOpen: boolean, onClose: () => void }) => JSX.Element)
  )
}


export const IconButtonPopover = ({
  IconComponent,
  PopoverProps = {},
  children,
  ...props
}: IconButtonPopoverProps): JSX.Element => {

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Fragment>

      <IconButton
        color='inherit'
        onClick={handleClick}
        {...props}>
        <IconComponent />
      </IconButton>

      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...PopoverProps}>

        { typeof children === 'function' ? children({ isOpen: open, onClose: handleClose }) : children }

      </Popover>

    </Fragment>
  )
}
