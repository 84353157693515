import { Box, Button, Grid, Typography } from '@material-ui/core'
import { makeAppStyles } from '@percept/mui'
import React from 'react'
import { useConflictStyles } from './ConflictHandling'
import { Close, Done, Undo } from '@material-ui/icons'
import { SpendingCell, UpdateConflict } from '../types'

type Props = {
  cell: SpendingCell
  prevCell: UpdateConflict
  handleReject: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    amount: string
  ) => void
  handleAccept: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    amount: string
  ) => void
  handleUndo: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => void
}

const useResolutionStyles = makeAppStyles((theme) => ({
  rejectedCell: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  rejectedText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: theme.palette.error.main,
    textTransform: 'capitalize',
  },
  acceptedCell: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  acceptedText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: theme.palette.success.main,
    textTransform: 'capitalize',
  },
  button: {
    textTransform: 'none',
  },
}))

export const ConflictResolution = ({
  cell,
  prevCell,
  handleReject,
  handleAccept,
  handleUndo,
}: Props) => {
  const classes = useConflictStyles()
  const classesResolution = useResolutionStyles()

  return (
    <Grid container key={cell.id} xs={12} className={classes.propertyContainer}>
      <Grid item xs={3} className={classes.gridItem}>
        <Typography className={classes.propertyLabel}>{cell.month}</Typography>
      </Grid>
      {cell.solved !== 'accepted' ? (
        <Grid
          item
          xs={cell.solved === 'rejected' ? 6 : 3}
          className={`${classes.gridItem} ${classesResolution.rejectedCell}`}>
          <Typography variant='body1'> {prevCell.new_amount}</Typography>
        </Grid>
      ) : (
        <></>
      )}
      {cell.solved !== 'rejected' ? (
        <Grid
          item
          xs={cell.solved === 'accepted' ? 6 : 3}
          className={`${classes.gridItem} ${classesResolution.acceptedCell}`}>
          <Typography variant='body1'> {cell.amount}</Typography>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item xs={3} className={classes.gridItem}>
        {cell.solved ? (
          <Grid container alignItems='center'>
            <Grid
              item
              xs={6}
              className={
                cell.solved === 'rejected'
                  ? classesResolution.rejectedText
                  : classesResolution.acceptedText
              }>
              {cell.solved === 'rejected' ? <Close /> : <Done />}
              {cell.solved}
            </Grid>
            <Grid item xs={6}>
              <Button
                variant='outlined'
                size='small'
                className={classesResolution.button}
                onClick={(e): void => handleUndo(e, prevCell.id)}
                startIcon={<Undo />}>
                Undo
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Box display='flex' gridGap='10px'>
            <Button
              variant='outlined'
              size='small'
              className={classesResolution.button}
              onClick={(e): void => handleReject(e, cell.id, prevCell.new_amount)}
              startIcon={<Close />}>
              Reject
            </Button>
            <Button
              variant='outlined'
              size='small'
              className={classesResolution.button}
              onClick={(e): void => handleAccept(e, cell.id,prevCell.new_amount)}
              startIcon={<Done />}>
              Accept
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  )
}
