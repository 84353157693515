import React from 'react'

import { ScaleLinear } from 'd3-scale'


export type HealthGradientProps = {
  id: string
  colourScale: ScaleLinear<number, string>
} & React.SVGAttributes<SVGLinearGradientElement>


export const HealthGradient = ({ id, colourScale, ...props }: HealthGradientProps): JSX.Element => (
  <linearGradient
    id={id}
    x1='0%'
    x2='0%'
    y1='0%'
    y2='100%'
    gradientTransform='rotate(90)'
    gradientUnits='userSpaceOnUse'
    {...props}>
    <stop
      stopColor={colourScale(1)}
      offset='0%' />
    <stop
      stopColor={colourScale(0.5)}
      offset='50%' />
    <stop
      stopColor={colourScale(0)}
      offset='100%' />
  </linearGradient>
)
