import React, { Fragment } from 'react'

import { Chip, ChipProps, formatMoney, makeAppStyles, SecondaryTooltip } from '@percept/mui'

import { TrendingDown, TrendingUp } from '@percept/mui/icons'

import { isInvertedSentiment, isMonetaryDimension, isPercentageDimension } from '@percept/utils'

import { percentageFormatter } from '@percept/mui/charts'

import { round } from 'lodash-es'

import { dimensionMap } from '@percept/constants'

import { AnyPerformanceDimension } from '@percept/types'


export type EstimatedChangeProps = {
  dimension: AnyPerformanceDimension
  value: number | null
  currency?: string | null
  showLabel?: boolean
} & Partial<Omit<ChipProps, 'icon' | 'label'>>


const getSentiment = (n: number, dimension: AnyPerformanceDimension): 'positive' | 'negative' => {
  if( isInvertedSentiment(dimension) ){
    return n < 0 ? 'positive' : 'negative'
  }
  return n > 0 ? 'positive' : 'negative'
}


const useStyles = makeAppStyles( theme => ({
  chip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.dark,
    fontSize: 14,
  },
  upliftLabel: {
    color: theme.palette.primary.dark,
    marginRight: theme.spacing(1.5),
  },
  value: {
    marginLeft: theme.spacing(0.5),
  },
  positive: {
    color: theme.palette.success.dark,
  },
  negative: {
    color: theme.palette.error.dark,
  }
}))


export const EstimatedChange = ({
  dimension,
  value,
  currency = null,
  showLabel = true,
  className,
  ...props
}: EstimatedChangeProps): JSX.Element | null => {

  const dimensionLabel = dimensionMap[dimension].text

  const numberValue = Number(value)

  const prefix = numberValue < 0 ? '-' : '+'

  const trendType = numberValue < 0 ? 'decrease' : 'increase'

  const absValue = Math.abs(numberValue)

  const valueContent = prefix + (
    isMonetaryDimension(dimension) ?
      formatMoney({ amount: absValue, currency }) :
      isPercentageDimension(dimension) ?
        percentageFormatter(absValue) :
        round(absValue, 2)
  )

  const sentiment = getSentiment(numberValue, dimension)

  const IconComponent = (
    numberValue > 0 ?
      TrendingUp :
      TrendingDown
  )

  const classes = useStyles()

  const valueClassName = classes.value + ' ' + classes[sentiment]

  const chipClassName = [classes.chip, className].filter(Boolean).join(' ')

  if( sentiment === 'negative' ){
    // What the user can't see, can't hurt them...
    return null
  }

  return (
    <SecondaryTooltip
      title={
        `Estimated potential ${trendType} in ${dimensionLabel} based on all recommendations being implemented`
      }>
      <Chip
        className={chipClassName}
        size='small'
        {...props}
        icon={
          <IconComponent color='inherit' className={classes.chip} />
        }
        label={
          <Fragment>
            { showLabel && (
              <span className={classes.upliftLabel}>
              Potential Change
              </span>
            )}
            { dimensionLabel }
            <span className={valueClassName}>
              { valueContent }
            </span>
          </Fragment>
        } />
    </SecondaryTooltip>
  )
}
