import React from 'react'
import {
  Box,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeAppStyles,
  Chip,
} from '@percept/mui'

import {
  BrandGrowthDatum,
  BrandGrowthQuadrant,
  BrandGrowthQuadrantChart,
} from 'components/BrandGrowthQuadrantChart'

import { useAccordionStyles } from './styles'

import { capitalize } from 'lodash-es'

import { MarketSegment } from '../../../api/markets/types'
import {
  MarketsSegmentation,
  MarketsSegmentationQuartal,
} from '../../../api/reports'

interface MarketSegmentationTileProps {
  marketSegment: MarketSegment | null
}

const useTileStyles = makeAppStyles({
  segmentTile: {
    width: '100%',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > span': {
      pointerEvents: 'none',
      fontSize: '16px',
    },
    [`&.${MarketSegment.SUSTAIN}`]: {
      backgroundColor: '#A1AB35',
    },
    [`&.${MarketSegment.SCALE}`]: {
      backgroundColor: '#E60000',
      color: '#FFFFFF',
    },
    [`&.${MarketSegment.ENHANCE}`]: {
      backgroundColor: '#F6CD45',
    },
    [`&.${MarketSegment.EVANGELIZE}`]: {
      backgroundColor: '#F6CD45',
    },
    '&.empty': {
      backgroundColor: '#D9D9D9',
    },
  },
})

const MarketSegmentationTile = ({
  marketSegment,
}: MarketSegmentationTileProps): JSX.Element => {
  const classes = useTileStyles()
  return (
    <div className={`${classes.segmentTile} ${marketSegment ?? 'empty'}`}>
      <span>{marketSegment}</span>
    </div>
  )
}

const useTableStyles = makeAppStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 168,
  },
  quartalLabel: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '28px',
    textAlign: 'center',
  },
  marketLabel: {
    fontWeight: 700,
    fontSize: '25px',
    lineHeight: '40px',
  },
  tableRow: {
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 1)',
    },
  },
}))

type MarketSegmentationProps = {
  marketsSegmentation: MarketsSegmentation
}

export const MarketsSegmentationTable = ({
  marketsSegmentation,
}: MarketSegmentationProps): JSX.Element | null => {
  const classes = useTableStyles()
  const accordionClasses = useAccordionStyles()

  const { quartals = [] } = marketsSegmentation

  const quartalLabels = quartals.map(
    (quartal) => quartal.quartal
  )

  return (
    <div>
      <Typography className={accordionClasses.accordionTitle}>
        Market Segmentation Change
      </Typography>
      <Typography className={accordionClasses.accordionContent}>
        Segmentation progress of each market over the past 12 months
      </Typography>
      <Box mt={3}>
        <TableContainer component={Paper}>
          <Table size='small'>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell></TableCell>
                {quartalLabels.map((label, index) => (
                  <TableCell key={`${label}${index}`}>
                    <Typography className={classes.quartalLabel}>
                      {label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {marketsSegmentation.iso_codes.map((isoCode) => (
                <TableRow key={isoCode} className={classes.tableRow}>
                  <TableCell component='th' scope='row'>
                    <Typography className={classes.marketLabel}>
                      {isoCode}
                    </Typography>
                  </TableCell>
                  {quartalLabels.map((quartalLabel) => {
                    const quartal = quartals.find(
                      (quartal) => quartal.quartal === quartalLabel
                    ) as MarketsSegmentationQuartal
                    const marketSegment = quartal.markets_segments[isoCode]
                    return (
                      <TableCell key={`${isoCode}${quartal.quartal}`}>
                        <MarketSegmentationTile
                          marketSegment={
                            marketSegment ? marketSegment : null
                          }
                        />
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  )
}


export const parseLastSegmentationQuartal = (
  marketsSegmentation: MarketsSegmentation
): MarketsSegmentationQuartal | null => {
  const { quartals = [] } = marketsSegmentation
  return quartals[quartals.length - 1] || null
} 


export const parseMarketSegmentation = (
  marketsSegmentation: MarketsSegmentation
): BrandGrowthDatum[] => {
  const lastQuartal = parseLastSegmentationQuartal(marketsSegmentation)

  return (
    lastQuartal ?
      Object.entries(lastQuartal.markets_segments).map( ([isoCode, marketSegment]) => ({
        label: isoCode,
        value: marketSegment.toUpperCase() as BrandGrowthQuadrant,
      })) :
      []
  )
}


const useOverviewStyles = makeAppStyles( theme => ({
  messageTitle: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '26px',
  },
  EVANGELIZE: {
    backgroundColor: theme.chart.healthColourScale(0.5),
  },
  SCALE: {
    backgroundColor: theme.chart.healthColourScale(0),
    color: '#FFF',
  },
  SUSTAIN: {
    backgroundColor: theme.chart.healthColourScale(1),
  },
  ENHANCE: {
    backgroundColor: theme.chart.healthColourScale(0.5),
  },
}))

export const MarketsSegmentationOverview = ({
  marketsSegmentation,
}: MarketSegmentationProps): JSX.Element | null => {
  const classes = useOverviewStyles()

  const lastQuartal = parseLastSegmentationQuartal(marketsSegmentation)

  const brandGrowthQuadrantChartMarkets = parseMarketSegmentation(marketsSegmentation)

  const marketsByQuadrant = brandGrowthQuadrantChartMarkets.reduce( (acc, datum) => {
    acc[datum.value] = acc[datum.value] || []
    acc[datum.value].push(datum.label)
    return acc
  }, {} as Record<BrandGrowthQuadrant, string[]>)
  
  const quadrantOrder: BrandGrowthQuadrant[] = ['SCALE', 'ENHANCE', 'EVANGELIZE', 'SUSTAIN']
  
  return (
    <Grid container alignItems='center' spacing={6}>
      <Grid item sm={6}>
        <Typography variant='h2'>
          Market Segmentation Overview
        </Typography>
        <Box mt={1}>
          { lastQuartal && (
            <Typography className={classes.messageTitle}>
              Markets have been segmented into quadrants based on their Net Promoter Score (NPS)
              and Non-User Consideration (NUC) rank within their market. Here are the latest classifications
              based on the {lastQuartal.quartal} report
            </Typography>
          )}
        </Box>
        <Box mt={1}>
          { quadrantOrder.map( quadrant => {
            const markets = marketsByQuadrant[quadrant] || []
            return (
              <Box display='flex' alignItems='center' mb={0.5} key={quadrant} className={classes.messageTitle}>
                <strong>
                  {capitalize(quadrant)}
                </strong>
                <Chip
                  size='small'
                  classes={{root: classes[quadrant]}}
                  style={{margin: '0 8px'}}
                  label={markets.length} />
                { !!markets.length && `(${markets.join(', ')})` }
              </Box>
            )
          })}
        </Box>
      </Grid>
      <Grid item sm={6}>
        <Box height={320}>
          <BrandGrowthQuadrantChart markets={brandGrowthQuadrantChartMarkets} />
        </Box>
      </Grid>
    </Grid>
  )
}
