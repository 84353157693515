import React from 'react'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { AxisScale } from '@visx/xychart'

interface Props {
  margin: { top: number; right: number; bottom: number; left: number }
  yMax: number
  xScale: AxisScale
  yScale: AxisScale
  color: string
  maxWidth: number
  tickFormatter: (value: number | string | null) => string
}

export const Axis = ({
  margin,
  yMax,
  xScale,
  yScale,
  color,
  tickFormatter,
  maxWidth
}: Props): JSX.Element => {
  return (
    <>
      <AxisBottom
        top={yMax + margin.top}
        left={margin.left}
        scale={xScale}
        stroke={color}
        tickStroke={color}
        tickLabelProps={() => ({
          fill: color,
          fontSize: 11,
          textAnchor: 'middle',
          dy: 15,
          width: maxWidth,
        })}
      />
      <AxisLeft
        left={margin.left}
        top={margin.top}
        scale={yScale}
        tickFormat={tickFormatter}
        stroke={color}
        hideTicks={true}
        tickLabelProps={() => ({
          fill: color,
          fontSize: 11,
          textAnchor: 'end',
        })}
      />
    </>
  )
}
