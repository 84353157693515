import React from 'react'

import { RoundedPlainTextButtonMenu } from '@percept/mui'

import { ArrowDropDown } from '@percept/mui/icons'

import { getDateLabel } from './lib'

import { areDatesEqual } from './utils'

import { OverviewGranularity } from './typings'
import { ComparisonMethod } from '@percept/utils'
import { capitalize } from 'lodash-es'


const granularityLabels: Record<OverviewGranularity, string> = {
  month: 'Month',
  quarter: 'Quarter',
  year: 'Financial Year to Date',
  year_on_year_month: 'Year on Year (Month)',
  year_on_year_quarter: 'Year on Year (Quarter)',
}

const granularityOptions: OverviewGranularity[] = ['month', 'quarter', 'year', 'year_on_year_month', 'year_on_year_quarter']

export const GranularitySelect = ({
  value,
  onChange,
}: {
  value: OverviewGranularity
  onChange: (granularity: OverviewGranularity) => void
}): JSX.Element => {
  return (
    <RoundedPlainTextButtonMenu
      value={value}
      label={granularityLabels[value]}
      TriggerProps={{
        variant: 'contained',
        endIcon: <ArrowDropDown />,
      }}
      options={
        granularityOptions.map( value => ({
          value,
          label: granularityLabels[value],
        }))
      }
      onChange={(_, value): void => {
        onChange(value)
      }} />
  )
}


export const DatePeriodSelect = ({
  granularity,
  value,
  options,
  onChange,
}: {
  granularity: OverviewGranularity
  value: Date
  options: Date[]
  onChange: (value: Date) => void
}): JSX.Element => {
  return (
    <RoundedPlainTextButtonMenu
      TriggerProps={{
        variant: 'contained',
        color: 'secondary',
        endIcon: <ArrowDropDown />,
      }}
      value={value}
      label={getDateLabel(granularity, value)}
      isEqual={areDatesEqual}
      options={
        options.map( date => ({
          value: date,
          label: getDateLabel(granularity, date),
        }))
      }
      onChange={(_, value: Date): void => {
        onChange(value)
      }} />
  )
}


const comparisonMethodLabels: Record<ComparisonMethod, string> = {
  ABSOLUTE: 'Absolute %',
  RELATIVE: 'Relative %',
}

const comparisonMethodOptions: ComparisonMethod[] = ['ABSOLUTE', 'RELATIVE']

export const ComparisonMethodSelect = ({
  value,
  onChange,
}: {
  value: ComparisonMethod
  onChange: (granularity: ComparisonMethod) => void
}): JSX.Element => {
  return (
    <RoundedPlainTextButtonMenu
      value={value}
      label={comparisonMethodLabels[value]}
      TriggerProps={{
        variant: 'contained',
        color: 'secondary',
        endIcon: <ArrowDropDown />,
      }}
      options={
        comparisonMethodOptions.map( value => ({
          value,
          label: comparisonMethodLabels[value],
        }))
      }
      onChange={(_, value): void => {
        onChange(value)
      }} />
  )
}
