
import React from 'react'

import { Typography } from '@material-ui/core'

import { Health } from '../Health'

import { MetricOrPlaceholder, PlaceholderComponentProps } from '../ReportDashboard/lib'

import { getPath } from '@percept/utils'

import { MetricDisplayType, MetricMetadataEntryType } from '@percept/types'

import { LayoutItemSummaryProps, MetricSearchClasses } from './typings'


type Bounds = {
  top: number
  bottom: number
}


const getDefaultNodeBounds = (): Bounds => ({
  top: 0,
  bottom: window.innerHeight,
})


const getContextBounds = (el: HTMLElement): Bounds => {
  const rect = el.getBoundingClientRect()
  return {
    top: el.scrollTop,
    bottom: rect.bottom + el.scrollTop,
  }
}


const PlaceholderComponent = ({ containerRef }: PlaceholderComponentProps): JSX.Element => (
  <div
    ref={containerRef}
    style={{
      height: '3rem',
      width: '3rem'
    }} />
)


const WrapComponent = ({
  containerRef,
  children
}: React.PropsWithChildren<{
  containerRef?: React.Ref<HTMLDivElement>
}>): JSX.Element => (
  <div ref={containerRef}>
    { children }
  </div>
)


export type MetricSummaryProps = (
  Pick<
    LayoutItemSummaryProps,
    'payload' | 'metadata' | 'healthDimension' | 'entity'
  > & {
    id: string
    searchClasses: MetricSearchClasses
    // focused?: boolean
    displayType?: MetricDisplayType
    style?: React.CSSProperties
    containerNode: HTMLElement | null
    onClick?: () => void
  }
)

export const MetricSummary = ({
  payload,
  metadata,
  id,
  searchClasses,
  healthDimension = 'count',
  onClick,
  entity,
  containerNode,
  displayType = 'MICRO',
  // focused,
  ...props
}: MetricSummaryProps): JSX.Element | null => {

  const metadataEntry = getPath<MetricMetadataEntryType>(metadata, id)

  if( !(metadata && metadataEntry && payload) ){
    return null
  }

  const { display_options } = metadataEntry
  const { chart_type } = display_options

  const className = [
    searchClasses.entry,
    onClick && searchClasses.clickable,
    // focused && searchClasses.focused,
  ].filter(Boolean).join(' ')
  
  return (
    <div
      className={className}
      // tabIndex={focused ? 0 : -1}
      onClick={onClick}>

      <Typography className={searchClasses.title} variant='subtitle1'>
        { metadataEntry.title }
      </Typography>

      <div className={searchClasses.metric}>

        <Health
          className={searchClasses.health}
          value={
            getPath(payload, [id, 'dimensions', healthDimension, 'health'])
          }
          fontSize='1.2rem' />

        <MetricOrPlaceholder
          id={id}
          display_options={display_options}
          metric_type={chart_type}
          displayType={displayType}
          disablePadding
          transparent
          metrics={payload}
          {...(entity && 
            {
              attributes: getPath(entity.derived, 'attributes'),
              performanceAttributes: getPath(entity.derived, 'performance'),
            }
          || {})}
          metadata={metadata}
          {...props}
          context={containerNode}
          getDefaultNodeBounds={getDefaultNodeBounds}
          getContextBounds={getContextBounds}
          PlaceholderComponent={PlaceholderComponent}
          WrapComponent={WrapComponent}
          tooltip={false}
          showHeader={false}
          showFooter={false} />

      </div>

    </div>
  )
}
