
import { getPath, any } from '@percept/utils'

import {
  Dictionary,
  ReportEntities,
  MetricSegmentType,
} from '@percept/types'


export const getAnyObjectValue = (obj: Dictionary<any>) => obj[Object.keys(obj)[0]]

export const getAuditEntities = (actionPayload: any): ReportEntities => {
  // action payload can consist of an audit wrapper,
  // or the contents of a specific job payload.
  // Checking for `schema_key` as a root property indicates
  // this is a job payload

  if( actionPayload.schema_key ){
    return getPath(actionPayload, ['audit', 'entities'], {})
  }

  return getPath(actionPayload, ['payload', 'audit', 'entities'], {})
}

// Test a metric from the action payload to determine if examples are inline 
export const hasInlineExamples = (payload: any) => {
  const auditEntities = getAuditEntities(payload)

  const testType = getAnyObjectValue(auditEntities || {}) || {},
        testEntity = getAnyObjectValue(testType) || {},
        testMetric = getAnyObjectValue(testEntity.metrics || {}) || {},
        testDimension = getAnyObjectValue(testMetric) || {},
        testData: MetricSegmentType[] = testDimension.data || []

  return any(testData, d => typeof d.examples !== 'undefined' )
}


export const hasInlineEntities = (payload: any) => {
  const auditEntities = getAuditEntities(payload)

  const testType = getAnyObjectValue(auditEntities || {}) || {},
        testEntity = getAnyObjectValue(testType) || {},
        testMetric = getAnyObjectValue(testEntity.metrics || {}) || {}

  return Object.keys(testMetric).length > 0  
}
