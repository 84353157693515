import { useQuery, UseQueryResult } from 'react-query'

import { PerformanceProvider, ReportProviderV2 } from '@percept/types'
import { format } from 'date-fns'
import { apiClients, makeClientQueryFn } from '@percept/hooks'
import qs from 'query-string'
import { deslugify } from '@percept/utils'

export type DataWarehouseReportGroupingBase = {
  title: string
  key: string
  index: number
  info: string | null
}

export type DataWarehouseReportGrouping = (
  DataWarehouseReportGroupingBase & {
    sub_groupings?: DataWarehouseReportGroupingBase[]
  }
)

export type DataWarehouseProvider = (
  ReportProviderV2 | PerformanceProvider
)

export type DataWarehouseQueryResponse = {
  metadata: {
    period: {
      start_date: string
      end_date: string
    }
    currency: string
    freshness: {
      min: number
      max: number
    }
    dimensions: Record<string, {
      label: string
      info: string | null
      internal?: boolean
    }>
    providers: DataWarehouseProvider[]
  }
  rows: {
    dimensions: Record<string, string>
    metrics: Record<string, number | null>
    metadata: {
      min_freshness: number
      max_freshness: number
      missing_currency: number
      unique_currencies: string[]
    }
  }[]
}


export const useDataWarehouseQueryGroupings = (): UseQueryResult<DataWarehouseReportGrouping[]> => {
  return useQuery({
    queryKey: ['dataWarehouse', 'groupings'],
    queryFn: makeClientQueryFn<DataWarehouseReportGrouping[]>(
      apiClients.core,
      { url: '/performance/available-groupings' }
    ),
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  })
}


export type DataWarehouseQueryHookProps = {
  grouping: string | null
  sub_grouping?: string | null
  org_unit_ids: string[] | null
  providers?: string[] | null
  start: Date | null
  end: Date | null
  currency: string
}

export const useDataWarehouseQuery = ({
  grouping,
  sub_grouping,
  org_unit_ids,
  providers,
  start,
  end,
  currency,
}: DataWarehouseQueryHookProps): UseQueryResult<DataWarehouseQueryResponse> => {
  const start_date = start && format(start, 'yyyy-MM-dd')
  const end_date = end && format(end, 'yyyy-MM-dd')
  return useQuery({
    queryKey: ['dataWarehouse', grouping, sub_grouping, providers, start_date, end_date, org_unit_ids, currency],
    queryFn: makeClientQueryFn<DataWarehouseQueryResponse>(
      apiClients.core,
      {
        url: '/performance/query',
        params: {
          org_unit_ids,
          providers,
          start_date,
          end_date,
          grouping,
          sub_grouping,
          target_currency: currency,
        },
        paramsSerializer: qs.stringify
      },
      response => {
        if( grouping === 'CAMPAIGN_PILLAR' ){
          return ({
            ...response.data,
            metadata: {
              ...response.data.metadata,
              dimensions: {
                ...response.data.metadata.dimensions,
                org_unit_id: {
                  ...(
                    response.data.metadata.dimensions.org_unit_id || {}
                  ),
                  internal: true,
                }
              }
            },
            rows: response.data.rows.map( r => ({
              ...r,
              dimensions: {
                ...r.dimensions,
                grouping: deslugify(r.dimensions.grouping),
              }
            }))
          })
        }
        return response.data
      }
    ),
    enabled: !!(grouping && org_unit_ids && org_unit_ids.length && start && end),
    cacheTime: 1000 * 60 * 1,
    refetchOnWindowFocus: false,
    retry: false,
  })
}
