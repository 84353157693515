import React from 'react'

import {
  MetricCard,
  MetricVisualisationWrap,
  NumberValueProps,
  TextMetricVisualisation,
  TextMetricVisualisationProps,
} from '@percept/mui'

import { capitalize, get } from 'lodash-es'

import { dimensionMap } from '@percept/constants'

import { getHealthFrom, getReportResultSummary } from '@percept/utils'

import { PerformanceDimensionType, StructuralReport } from '@percept/types'


export type ReportSummaryProps = {
  report: StructuralReport
  header?: React.ReactNode
}


const deriveMetricsFromReport = (
  report: StructuralReport
): (
  TextMetricVisualisationProps<NumberValueProps> & {
    label: React.ReactNode
  }
)[] => {
  const summary = getReportResultSummary(report)
  return ['cost', 'impressions', 'clicks', 'conversions'].map( dimension => ({
    metric_type: 'value',
    displayType: 'SUMMARY',
    format: dimension === 'cost' ? 'currency' : 'number',
    currency: summary && summary.currency_code,
    value: get(summary && summary.performance, dimension) || 0,
    dimension,
    label: (
      dimensionMap[dimension as PerformanceDimensionType]
      && dimensionMap[dimension as PerformanceDimensionType].text
      || capitalize(dimension)
    ),
  }))
}


export const ReportSummary = ({
  report,
  header,
}: ReportSummaryProps): JSX.Element => {

  const metrics = deriveMetricsFromReport(report)

  const summary = getReportResultSummary(report)

  const health = getHealthFrom(summary)

  return (

    <MetricCard
      fullHeight={false}
      health={health}
      title={header}>

      { metrics.map( ({ dimension, value, label }) => (
        <MetricVisualisationWrap
          key={dimension}
          label={label}>

          <TextMetricVisualisation
            metric_type='value'
            displayType='SUMMARY'
            format={dimension === 'cost' ? 'currency' : 'number'}
            value={value}
            dimension={dimension}
            currency={summary && summary.currency_code} />

        </MetricVisualisationWrap>
      ))}

    </MetricCard>
  )
}
