
export type BGPTab = 'REPORTS' | 'SUBMIT' | 'MARKETS' | 'ABOUT'

export const TAB_SLUG_SEARCH_PARAM = 'tab'

export const tabSlugMap: Record<BGPTab, string> = {
  REPORTS: 'reports',
  SUBMIT: 'submit',
  MARKETS: 'markets',
  ABOUT: 'about',
}

export const slugTabMap = (
  Object.entries(tabSlugMap).reduce( (acc, [k, v]) => {
    acc[v] = k as BGPTab
    return acc
  }, {} as Record<string, BGPTab>)
)

export const userTabs: BGPTab[] = [
  'ABOUT',
  'SUBMIT',
  'REPORTS',
]

export const globalUserTabs: BGPTab[] = [
  'ABOUT',
  'MARKETS',
  'REPORTS',
]

export const tabLabels: Record<BGPTab, string> = {
  REPORTS: 'View Reports',
  MARKETS: 'All Markets',
  SUBMIT: 'Submit Data for Analysis',
  ABOUT: 'About Brand Growth Planner',
}

export const defaultTab: BGPTab = 'ABOUT'
