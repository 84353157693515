import React, { useState } from 'react'

import {
  Alert,
  Backdrop,
  Box,
  CardMedia,
  Chip,
  CircularProgress,
  DatePicker,
  FormControl,
  FormHelperText,
  Grid,
  LinearProgress,
  Paper,
  RoundedPlainTextButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeAppStyles,
} from '@percept/mui'
import { ArrowDropDown, Check, Close, Edit, InsertDriveFile } from '@percept/mui/icons'

import { CategorySelect } from '../CategorySelect'

import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useDropzone, Accept } from 'react-dropzone'

import { useNotifications } from '@percept/hooks'

import { Formik, FormikErrors } from 'formik'

import { format } from 'date-fns'

import { get, isEmpty, omit } from 'lodash-es'

import { AxiosError } from 'axios'

import {
  InfinitePaginationWrapper,
  PartnershipsArticle,
  PartnershipsArticleCreationParams,
  PartnershipsArticleMutationParams,
} from '../types'
import { humanReadableFilesize } from '@percept/utils'

const headers = ['Date', 'Headline', 'Article summary', 'Image', 'Link', 'Action']

const initialValues: PartnershipsArticleMutationParams = {
  id: '',
  title: '',
  publish_date: '',
  summary: '',
  partner_category: undefined,
  local_timezone_info: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss'),
  image_url: '',
  external_url: '',
  image_upload: undefined,
  publish: true
}

const useStyles = makeAppStyles( theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    pointerEvents: 'none',
  },
  tableHeader: {
    backgroundColor: theme.palette.background.default,
  },
  tableRow: {
    height: '50px'
  },
  tableHeaderCell: {
    border: `1px solid ${theme.palette.divider}`,
    padding: '2px'
  },
  tableCell: {
    padding: '2px',
    border: `1px solid ${theme.palette.divider}`,
  },
  tableContainer: {
    marginTop: '25px',
    overflow: 'hidden',
    position: 'relative',
  },
  inputField: {
    padding: '10px'
  },
  cardMedia: {
    height: 100,
    objectPosition: 'top',
  },
  actionButton: {
    margin: '5px',
    width: '80%',
    fontSize: '12px'
  }
}))

const useBackdropStyles = makeAppStyles( theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))


const useImageUploadStyles = makeAppStyles( theme => ({
  dropzone: {
    minHeight: '10rem',
    minWidth: '20rem',
    padding: theme.spacing(3),
    margin: theme.spacing(4, 0),
    borderRadius: theme.shape.borderRadius,
    border: 'dashed 3px',
    color: theme.palette.action.disabled,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  fileChip: {
    margin: theme.spacing(4, 0, 2, 0),
  },
}) )


const acceptImage: Accept = {
  'image/jpeg': ['.jpeg'],
  'image/jpg': ['.jpg'],
  'image/webp': ['.webp'],
  'image/svg': ['.svg'],
  'image/png': ['.png'],
}

const ImageUpload = ({
  file,
  setFile,
}: {
  file: File | undefined
  setFile: (file: File) => void
}): JSX.Element => {

  const onDrop = ([file]: File[]): void => {
    if( file ){
      setFile(file)
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptImage,
  })

  const classes = useImageUploadStyles()

  return (
    <div
      className={classes.dropzone}
      {...getRootProps()}>
      <input
        {...getInputProps()} />
      <Typography
        color='inherit'>
        Drag an image here, or click to select a file
      </Typography>
      { file && (
        <Chip
          className={classes.fileChip}
          color='secondary'
          size='medium'
          icon={<InsertDriveFile />}
          label={
            <span style={{display: 'flex', alignItems: 'center'}}>
              <Typography variant='subtitle2'>
                {file.name}
              </Typography>
              <Typography variant='subtitle1' style={{marginLeft: 6}}>
                {humanReadableFilesize(file.size)}
              </Typography>
            </span>
          } />
      )}
    </div>
  )
}


export type SubmitArticlesProps = {
  queryKey: string
  createArticle: (article: PartnershipsArticleCreationParams) => Promise<any>
  updateArticle: (article: PartnershipsArticleMutationParams) => Promise<any>
  deleteArticle: (articleId: string) => Promise<any>
  getOpenGraphData: (articleUrl: string) => Promise<any>
  getArticles: (pageNumber: number) => Promise<InfinitePaginationWrapper<PartnershipsArticle>>
  dateFormat?: string
  requirePartnerCategory?: boolean
  requireImage?: boolean
}

export const SubmitArticles = ({
  queryKey,
  createArticle,
  updateArticle,
  deleteArticle,
  getOpenGraphData,
  getArticles,
  dateFormat = 'dd/MM/yy',
  requirePartnerCategory = true,
  requireImage = true,
}: SubmitArticlesProps): JSX.Element => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const [isFormEnabled, setIsFormEnabled] = useState(false)
  const [isLoadingOg, setIsLoadingOg] = useState<boolean | null>()
  const [isUpdate, setIsUpdate] = useState(false)
  const [page, setPage] = useState(1)
  const [,addNotification] = useNotifications()
  const [open, setOpen] = useState(false)
  const [article, setArticle] = useState('')

  const handleClose = (): void => {
    setOpen(false)
  }
  
  const handleOpen = (id: string): void => {
    setArticle(id)
    setOpen(true)
  }

  const createArticleMutation = useMutation<void, AxiosError<{ detail: string }>, PartnershipsArticleMutationParams>(
    async (data: PartnershipsArticleMutationParams) => {
      if(isUpdate){
        await updateArticle(data)
      }else{
        await createArticle(data)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey])
        addNotification({
          type: 'success',
          name: 'Success',
          message: 'Article created successfully',
        })
      },
      onError: (error) => {
        const errorMessage = get(error.response, ['data', 'detail'], 'The article could not be created')
        addNotification({
          type: 'error',
          name: error.name || 'Error',
          message: errorMessage,
          ttl: 0,
        })
      },
      onSettled: () => {
        setIsFormEnabled(false)
        setIsUpdate(false)
      }
    }
  )

  const deleteArticleMutation = useMutation<void, AxiosError<{ detail: string }>, string>(async (id: string) => {
    await deleteArticle(id)
  },
  {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey])
      addNotification({
        type: 'success',
        name: 'Success',
        message: 'Article deleted successfully',
      })
    },
    onError: (error) => {
      const errorMessage = get(error.response, ['data', 'detail'], 'The article could not be deleted')
      addNotification({
        type: 'error',
        name: 'Error',
        message: errorMessage,
        ttl: 0,
      })
    }
  })


  const { data, isPreviousData, isRefetching, isFetching } = useQuery({
    queryKey: [queryKey, { page }],
    queryFn: () => getArticles(page), 
    keepPreviousData: true,
  })

  return (
    <Box p={3} bgcolor='grey.100'>
      <Backdrop 
        open={open}
        onClick={handleClose} 
        classes={useBackdropStyles()}>
        <Alert
          variant='info'
          header='Confirm Delete'
          p={2}>
          <Box p={2}>Are you sure? This action cannot be undone</Box>
          <Box display='flex' justifyContent='space-between' px={3}>
            <RoundedPlainTextButton variant='contained' onClick={(): void => deleteArticleMutation.mutate(article)}>Yes</RoundedPlainTextButton>
            <RoundedPlainTextButton variant='contained' onClick={(): void => handleClose()}>No</RoundedPlainTextButton>
          </Box>
        </Alert>
      </Backdrop>
      <Formik
        initialValues={initialValues}
        validate={(values): FormikErrors<PartnershipsArticleMutationParams> => {
          const errors: FormikErrors<PartnershipsArticleMutationParams> = {}
          if( !values.title ){
            errors.title = 'Title is required'
          }
          if( !values.publish_date ){
            errors.publish_date = 'Date is required'
          }
          if( !values.summary ){
            errors.summary = 'Summary is required'
          }
          if( requirePartnerCategory && values.partner_category === undefined ){
            errors.partner_category = 'Partner is required'
          }
          if( requireImage && !values.image_url && !values.image_upload ){
            errors.image_url = 'Image is required'
            errors.image_upload = 'Image is required'
          }
          if( !values.external_url ){
            errors.external_url = 'Article URL is required'
          }
         
          return errors
        }}
        onSubmit={(values: PartnershipsArticleMutationParams, { resetForm }): void => {
          const mutationValues = requirePartnerCategory ? values : omit(values, 'partner_category')
          createArticleMutation.mutate(
            {
              ...mutationValues, 
              publish: true, 
              local_timezone_info: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss'),
            },
            {
              onSuccess: () => resetForm()
            }
          )
        }}> 
        {({ values, setFieldValue, handleChange, handleBlur, handleSubmit, setValues, setTouched, errors }): JSX.Element => (
          <>
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={8}>
                  <FormControl fullWidth className={classes.inputField}>
                    <FormHelperText>Link</FormHelperText>
                    <TextField
                      error={!!errors.external_url}
                      variant='filled'
                      name='external_url'
                      value={values.external_url}
                      onChange={handleChange}
                      onBlur={handleBlur} 
                      helperText={errors.external_url} />
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <Box mt={4} display='flex' justifyContent='flex-end' alignItems='center'>
                    <RoundedPlainTextButton
                      variant='contained'
                      color='primary'
                      startIcon={isLoadingOg ?
                        <CircularProgress size='1em' color='inherit' /> :
                        <Check />}
                      onClick={async (): Promise<void> => {
                        try {
                          setIsLoadingOg(true)
                          const ogValues = await getOpenGraphData(values.external_url)
                          setIsFormEnabled(true)
                          setValues( prev => ({
                            ...ogValues,
                            external_url: values.external_url,
                            publish_date: ogValues.published_date || prev.publish_date,
                          }))
                          setIsLoadingOg(false)
                        } catch (error) {
                          setIsFormEnabled(true)
                          setIsLoadingOg(false)
                        }
                      }}
                    >
                      Load
                    </RoundedPlainTextButton>
                  </Box>
                </Grid>

                {isFormEnabled && (
                  <Grid item container xs={12}>
                    <Grid item xs={8}>
                      <FormControl fullWidth className={classes.inputField}>
                        <FormHelperText>Article date</FormHelperText>
                        <DatePicker
                          helperText={errors.publish_date}
                          error={!!errors.publish_date}
                          variant='dialog'
                          format={dateFormat}
                          value={values.publish_date || null}
                          onChange={(value): void => {
                            if (value) {
                              setFieldValue('publish_date', format(value, 'yyyy-MM-dd'))
                            }
                          } } />
                      </FormControl>
                    </Grid>

                    { requirePartnerCategory && (
                      <Grid item xs={8}>
                        <FormControl className={classes.inputField}>
                          <FormHelperText>Article Category</FormHelperText>
                          <CategorySelect
                            value={values.partner_category}
                            OverrideTriggerComponent={RoundedPlainTextButton}
                            TriggerProps={{
                              size: 'large',
                              style: {display: 'inline-flex'},
                              variant: 'contained',
                              color: 'default',
                              endIcon: <ArrowDropDown />,
                            }}
                            onChange={(e, value): void => {
                              setFieldValue('partner_category', value)
                            }} />
                        </FormControl>
                      </Grid>
                    )}

                    <Grid item xs={8}>
                      <FormControl fullWidth className={classes.inputField}>
                        <FormHelperText>Title</FormHelperText>
                        <TextField
                          helperText={errors.title}  
                          error={!!errors.title}
                          variant='filled'
                          name='title'
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur} />
                      </FormControl>
                    </Grid>

                    <Grid item xs={8}>
                      <FormControl fullWidth className={classes.inputField}>
                        <FormHelperText>Article summary</FormHelperText>
                        <TextField
                          helperText={errors.summary}  
                          error={!!errors.summary}
                          variant='filled'
                          multiline
                          rows={5}
                          name='summary'
                          value={values.summary}
                          onChange={handleChange}
                          onBlur={handleBlur} />
                      </FormControl>
                    </Grid>

                    <Grid item xs={8}>
                      {!values.image_url && (
                        <FormControl fullWidth className={classes.inputField}>
                          <FormHelperText>Image Upload</FormHelperText>
                          <ImageUpload
                            file={values.image_upload}
                            setFile={(file): void => {
                              setFieldValue('image_upload', file)
                            }} />
                        </FormControl>
                      )}
                    </Grid>

                    <Grid item xs={8}>
                      {!values.image_upload && (
                        <FormControl fullWidth className={classes.inputField}>
                          <FormHelperText>Image URL</FormHelperText>
                          <TextField
                            helperText={errors.image_url}  
                            error={!!errors.image_url}
                            variant='filled'
                            name='image_url'
                            value={values.image_url}
                            onChange={handleChange}
                            onBlur={handleBlur} />
                        </FormControl>
                      )}
                    </Grid>
                    
                    <Grid item xs={12}>
                      <Box mt={4} display='flex' justifyContent='flex-end' alignItems='center'>
                        <Box mr={2}>
                          <RoundedPlainTextButton
                            variant='contained'
                            startIcon={<Close />}
                            onClick={(): void => {
                              setValues(initialValues)
                              setTouched({})
                              setIsFormEnabled(false)
                              setIsUpdate(false)
                            }}>
                            Cancel
                          </RoundedPlainTextButton>
                        </Box>
                        <RoundedPlainTextButton
                          type='submit'
                          variant='contained'
                          color='primary'
                          disabled={!isEmpty(errors) || createArticleMutation.isLoading}
                          startIcon={createArticleMutation.isLoading ?
                            <CircularProgress size='1em' color='inherit' /> :
                            <Check />}>
                          {isUpdate ? 'Update Article': 'Add New Article'}
                        </RoundedPlainTextButton>
                      </Box>
                    </Grid>
                  </Grid>)}
              </Grid>
            </form>
            <TableContainer className={classes.tableContainer} component={Paper}>
              { (isPreviousData || isRefetching || isFetching) && (
                <LinearProgress variant='indeterminate' style={{position: 'absolute', width: '100%'}} />
              )}
              <Table aria-label='All-Articles table'>
                <TableHead className={classes.tableHeader}>
                  <TableRow className={classes.tableRow}>
                    {headers.map((header: string) => (
                      <TableCell className={classes.tableHeaderCell} key={header} align='center'>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && (data.items || []).map((article: PartnershipsArticle) => (
                    <TableRow key={article.id}>
                      <TableCell className={classes.tableCell} align='center'>
                        {format(new Date(article.publish_date), dateFormat)}
                      </TableCell>
                      <TableCell className={classes.tableCell} align='center'>
                        {article.title}
                      </TableCell>
                      <TableCell className={classes.tableCell} align='center'>
                        {article.summary}
                      </TableCell>
                      <TableCell className={classes.tableCell} align='center'>
                        <CardMedia
                          component='img'
                          className={classes.cardMedia}
                          src={article.image_url} />
                      </TableCell>
                      <TableCell className={classes.tableCell} align='center'>
                        {article.external_url && <a href={article.external_url} target='_blank' rel='noopener noreferrer'>Link</a>}
                      </TableCell>
                      <TableCell className={classes.tableCell} align='center'>
                        <RoundedPlainTextButton
                          startIcon={<Edit/>}
                          className={classes.actionButton}
                          size='small'
                          variant='contained'
                          onClick={(): void => {
                            setIsUpdate(true)
                            setIsFormEnabled(true)
                            const match = data.items.find( item => item.id === article.id)
                            if( match ){
                              setValues({
                                ...match,
                                // Reset image URLs when they point to our S3 bucket, these are presigned
                                // and so can't be stored
                                image_url: (
                                  match.image_url && match.image_url.includes('vf-custom-data-percept.s3.amazonaws.com') ?
                                    undefined :
                                    match.image_url
                                ),
                                image_upload: undefined,
                                summary: match.summary.toString(),
                              })
                            }
                          }}>
                          Edit
                        </RoundedPlainTextButton>
                        <RoundedPlainTextButton
                          color='secondary'
                          className={classes.actionButton}
                          startIcon={<Close />}
                          size='small'
                          variant='contained'
                          onClick={(): void => handleOpen(article.id?.toString())}>
                          Delete
                        </RoundedPlainTextButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {data && (
                <Box display='flex' justifyContent='space-between' p={2}>
                  <RoundedPlainTextButton
                    variant='contained'
                    disabled={isPreviousData || !data.previousPage}
                    onClick={(): void => setPage(data.previousPage || page)}>
                    Previous
                  </RoundedPlainTextButton>
                  <Typography>{page}</Typography>
                  <RoundedPlainTextButton
                    variant='contained'
                    disabled={isPreviousData || !data.nextPage}
                    onClick={(): void => setPage(data.nextPage || page)}>
                    Next
                  </RoundedPlainTextButton>
                </Box>
              )}
            </TableContainer>
          </>
        )}
      </Formik>
    </Box>
  )
}

