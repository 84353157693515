
import { isArray, isNumber, getPath } from '@percept/utils'

import {
  Token,
  User,
  UserGroups,
  UserSession,
  Dictionary,
} from '@percept/types'

const { PASSWORD_REQUIREMENTS } = process.env


export const hasNoTrailingWhitespace = (password: string): boolean => (
  /^\S.*\S$/.test(password)
)

export const hasMixedCase = (password: string): boolean => (
  /.*([A-Z]+.*[a-z]+.*)|([a-z]+.*[A-Z]+.*).*/.test(password)
)

export const hasSymbols = (password: string): boolean => (
  // Matches any of the special characters according to Cognito
  // See https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
  /.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-].*/.test(password)
)

export const hasNumbers = (password: string): boolean => (
  /.*[\d].*/.test(password)
)


export const isValidPassword = (password?: string): boolean => {
  const { LENGTH, MIXED_CASE, SYMBOLS, NUMBERS } = PASSWORD_REQUIREMENTS

  return Boolean(
    password
    && password.length >= LENGTH
    && hasNoTrailingWhitespace(password)
    && (
      MIXED_CASE ?
        hasMixedCase(password)
        : true
    ) && (
      SYMBOLS ?
        hasSymbols(password)
        : true
    ) && (
      NUMBERS ?
        hasNumbers(password)
        : true
    )
  )
}

export const isValidCode = (code?: string): code is string => Boolean(
  code && code.length === 6
)


export const isValidToken = (parsedToken: Token, expiryMargin = 2000): boolean => !!(
  parsedToken && !parsedToken.error &&
  parsedToken.value && isNumber(parsedToken.expiresAt) &&
  parsedToken.expiresAt > ( Date.now() + expiryMargin )
)


export const parseAccessToken = (userSession: UserSession): Token | null => {

  const { accessToken: { jwtToken = null, payload = null } = {} } = userSession || {}

  const token = {
    value: jwtToken,
    expiresAt: payload && (payload.exp * 1000)
  }

  return isValidToken(token) ? token : null
}



const parseAccessTokenPayload = (payload: Dictionary): User => {
  return {
    name: payload.name || '',
    email: payload.email || '',
    phone: payload.phone_number || '',
    user_id: payload.sub || '',
    groups: (
      isArray(payload['cognito:groups']) ?
        payload['cognito:groups'] :
        []
    )
  }
}

export const parseUser = (userSession: Dictionary): User => {
  const attributes = getPath(userSession, ['idToken', 'payload'], {} as Dictionary)
  return parseAccessTokenPayload(attributes)
}


export const parseUserGroups = (userSession: UserSession): UserGroups => {
  const { accessToken: { payload } } = userSession
  return isArray(payload['cognito:groups']) ? payload['cognito:groups'] : []
}


export const getCurrentUser = (): Promise<{ user: User, token: Token }> => (
  new Promise((resolve, reject) => {
    reject(new Error('No user found'))
  })
)
