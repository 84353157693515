import React from 'react'

import { DefaultLayout } from './DefaultLayout'

import { HealthVsPerformance } from '../components/HealthVsPerformance'

import { useLocationParams } from '@percept/hooks'


export const HealthVsPerformanceScreen = (): JSX.Element => {

  const { org_unit_id } = useLocationParams()

  return (
    <DefaultLayout>
      <HealthVsPerformance
        org_unit_id={org_unit_id || ''} />
    </DefaultLayout>
  )
}

export default HealthVsPerformanceScreen
