import { PrimaryApplication, SecondaryApplication } from './hooks'

import { deslugify } from '@percept/utils'


export const primaryApplicationLabelMap: Record<PrimaryApplication, string> = {
  VODAFONE_MEDIA_WIZARD: 'Media Wizard',
  MEDIA_INVESTMENT: 'Media Investment Platform',
  MEDIA_QUALITY_DASHBOARD: 'Media Quality Dashboard',
}

export const secondaryApplicationLabelMap: Record<SecondaryApplication, string> = {
  ANALYTICS: 'Analytics',
  BRAND_GROWTH_PLANNER: 'Brand Growth Planner',
  COMPETITIVE_INVESTMENT_SOS: 'Competitive Investment SOS',
  COMPETITIVE_INVESTMENT_SOV: 'Competitive Investment TV SOV',
  CUSTOM_PERFORMANCE_REPORT: 'Custom Performance Report',
  DATA_WAREHOUSE: 'Data Warehouse',
  HOME: 'Home',
  INSIGHTS: 'Insights',
  MEDIA_MIX_MODELLING: 'Media Mix Modelling',
  MEDIA_QUALITY_RATE: 'Media Quality Rate',
  NEWS: 'News',
  OPTIMISATION: 'Optimisation',
  OVERVIEW: 'Overview',
  WIZARD_OVERVIEW: 'Media Wizard Overview',
  PERFORMANCE_OVERVIEW: 'Performance Overview',
  PARTNERSHIPS: 'Partnerships',
  PATHWAYS: 'Pathways',
  PRIMARY_INVESTMENT: 'Media Investment',
  REPORT_DASHBOARD: 'Report Dashboard',
  REPORTING: 'Reporting',
  SMART_CAMPAIGN_ASSESSMENT: 'Smart Campaign Assessment',
  SPONSORSHIPS: 'Sponsorships',
  STRATEGY: 'Strategy',
  SUBMISSION_TRACKER: 'Submission Tracker',
  WASTAGE_TREND_REPORTS: 'Wastage Trend Reports',
}


export const getPrimaryApplicationLabel = (application: PrimaryApplication): string => (
  primaryApplicationLabelMap[application] || deslugify(application)
)

export const getSecondaryApplicationLabel = (application: SecondaryApplication): string => (
  secondaryApplicationLabelMap[application] || deslugify(application)
)
