import {
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { AxiosError } from 'axios'
import { replaceMessagePlaceholders } from '../../pages/latest-report/utils'
import brandGrowthPlannerApi from '..'
import {
  Report,
  ReportCreate,
  ReportWithDetails,
  MarketsSegmentation,
} from './types'

export const ReportService = {
  useReports: (
    marketId?: string,
    options?: Omit<
      UseQueryOptions<Report[], AxiosError>,
      'queryKey' | 'queryFn'
    >
  ): UseQueryResult<Report[], AxiosError> =>
    useQuery<Report[], AxiosError>(
      ['bgp', 'markets', marketId, 'reports'],
      async (): Promise<Report[]> => {
        const { data } = await brandGrowthPlannerApi.get(
          `/markets/${marketId}/reports`
        )
        return data
      },
      {
        refetchOnWindowFocus: false,
        enabled: marketId !== undefined,
        ...options,
      }
    ),
  useReport: (
    marketId?: string,
    reportId?: string,
    options?: Omit<
      UseQueryOptions<ReportWithDetails, AxiosError>,
      'queryKey' | 'queryFn'
    >
  ): UseQueryResult<ReportWithDetails, AxiosError> =>
    useQuery<ReportWithDetails, AxiosError>(
      ['bgp', 'markets', marketId, 'reports', reportId],
      async (): Promise<ReportWithDetails> => {
        const { data } = await brandGrowthPlannerApi.get(
          `/markets/${marketId}/reports/${reportId}`
        )

        const { message, placeholders = [] } = data

        data.message = replaceMessagePlaceholders({
          message,
          placeholders: placeholders,
        })

        return data
      },
      {
        refetchOnWindowFocus: false,
        enabled: marketId !== undefined && reportId !== undefined,
        ...options,
      }
    ),
  useMarketsSegmentation: (
    options?: Omit<
      UseQueryOptions<MarketsSegmentation, AxiosError>,
      'queryKey' | 'queryFn'
    >
  ): UseQueryResult<MarketsSegmentation, AxiosError> =>
    useQuery<MarketsSegmentation, AxiosError>(
      ['bgp', 'markets-segmentation'],
      async (): Promise<MarketsSegmentation> => {
        const { data } = await brandGrowthPlannerApi.get(
          '/reports/markets-segmentation'
        )
        return data
      },
      {
        refetchOnWindowFocus: false,
        retry: false,
        cacheTime: Infinity,
        ...options,
      }
    ),
  useSubmitReport: (): UseMutationResult<
    Report,
    AxiosError,
    { marketId: string; payload: ReportCreate }
  > => {
    const queryClient = useQueryClient()

    return useMutation<
      Report,
      AxiosError,
      { marketId: string; payload: ReportCreate }
    >(
      async ({ marketId, payload }): Promise<Report> => {
        const { data } = await brandGrowthPlannerApi.post<Report>(
          `/markets/${marketId}/report`,
          payload
        )

        return data
      },
      {
        onSuccess: (data, { marketId }) => {
          queryClient.setQueryData<Report[]>(
            ['bgp', 'markets', marketId, 'reports'],
            (oldData = []) => [...oldData, data]
          )
        },
      }
    )
  },
}
