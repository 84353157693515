import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { Box, Typography } from '@percept/mui'

import { useMyUserDetail } from '@percept/hooks'

import { notifications } from '@percept/redux/bundles'

import timeago from 'timeago.js'


export const UserWelcomeToast = (): null => {
  const [user] = useMyUserDetail()

  const dispatch = useDispatch()

  useEffect(() => {
    if( user.data && user.data.previous_login ){
      const lastLoginTime = new Date(user.data.previous_login)
      const timer = timeago()
      dispatch(
        notifications.actions.addToast({
          id: 'welcome-user',
          type: 'success',
          message: (
            <Box>
              <Typography variant='h5'>
                Welcome back, {user.data.name}
              </Typography>

              <Box mt={1.5} style={{opacity: 0.85}} fontSize={13}>
                Last logged in {timer.format(lastLoginTime)}
              </Box>
            </Box>
          ),
          ttl: 3500,
        })
      )
    }
  }, [user.data, dispatch])

  return null
}
