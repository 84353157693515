import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react'

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  Typography,
  ButtonProps,
  RoundedPlainTextButton,
  Box,
  LinearProgress,
  CircularProgress,
  makeAppStyles,
  Chip,
  BoxProps,
  AppBar,
  ButtonPopover,
  ClassNameMap,
  PlainTextButton,
  Alert,
  RoundedPlainTextButtonMenu,
} from '@percept/mui'

import { useDropzone, Accept } from 'react-dropzone'

import {
  Close,
  Check,
  Image,
  InsertDriveFile,
  CheckCircleOutline,
  ErrorOutline,
  WarningOutlined,
  Undo,
  ChevronRight,
  ZoomIn,
  Help,
  ArrowDropDown,
  ListAlt,
} from '@percept/mui/icons'

import { PlatformUnitParams } from '@percept/types'

import Api, {
  ConflictingDataError,
  FileUploadResponse,
  InputRowError,
  RowReferenceError,
  SheetError,
  SpreadsheetUploadType,
  UploadErrorContainer,
} from 'api/services/Api'

import { every, get, mapValues, some, sortBy } from 'lodash-es'

import { deslugify, humanReadableFilesize, userHasOrgPrivileges } from '@percept/utils'

import { UserPrivilegeContext } from '@percept/app-components'


export type DataUploadProps = (
  PlatformUnitParams & {
    ButtonProps?: ButtonProps
  }
)

const acceptSpreadsheet: Accept = {
  'text/csv': ['.csv'],
  'application/vnd.ms-excel': ['.xls', '.xlsx'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xslx'],
}


const VODAFONE_SUPPORT_EMAIL = 'vodafone@wearepercept.com'


const displayMicros = (micros: number | null): string => {
  if( micros === null ){
    return '--'
  }
  return (micros / 1000000).toFixed(2)
}

const useStatusStyles = makeAppStyles(theme => ({
  info: {
    color: theme.palette.info.main
  },
  warning: {
    color: theme.palette.warning.main
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
}))


type UploadErrorSummary<T extends object> = {
  count: number
  message: string
  rows: T[]
}

type UploadErrorSummaryContainer = {
  conflicts: UploadErrorSummary<ConflictingDataError>[]
  references: UploadErrorSummary<RowReferenceError>[]
  input_rows: UploadErrorSummary<InputRowError>[]
  sheet: UploadErrorSummary<SheetError>[]
}

type UploadErrorType = ConflictingDataError | RowReferenceError | InputRowError | SheetError


const parseUploadErrorSummaryContainer = (
  errorContainer: UploadErrorContainer | null
): UploadErrorSummaryContainer | null => {
  if( !errorContainer ){
    return null
  }
  if( every(Object.values(errorContainer), arr => arr.length === 0 ) ){
    return null
  }
  return mapValues(errorContainer, errorObjects => {
    const byMessage = errorObjects.reduce( (acc, e) => {
      acc[e.message] = acc[e.message] || {
        count: 0,
        message: e.message,
        rows: []
      }
      acc[e.message].count += 1
      acc[e.message].rows.push(e) 
      return acc
    }, {} as Record<string, { count: number; message: string, rows: UploadErrorType[] }>)
    return sortBy(
      Object.values(byMessage),
      'count',
    ).reverse()
  }) as UploadErrorSummaryContainer
}


const parseUploadFeedback = (
  response: FileUploadResponse
): Record<'errors' | 'warnings', UploadErrorSummaryContainer | null> => {
  return {
    errors: parseUploadErrorSummaryContainer(response.errors),
    warnings: parseUploadErrorSummaryContainer(response.warnings),
  }
}


const useErrorFeedbackStyles = makeAppStyles( theme => ({
  header: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  tooltipTrigger: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  tooltip: {
    maxWidth: 'none',
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.dark,
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 1.6,
    padding: theme.spacing(1),
    boxShadow: theme.shadows['12'],
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  rowContainer: {
    maxHeight: '50vh',
    overflow: 'scroll',
  },
  errorMessage: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    fontSize: 13,
    borderTop: `1px solid ${theme.palette.action.disabled}`,
  },
  separator: {
    opacity: 0.5,
  },
}))


const ErrorRowDetail = ({
  count,
  message,
  children,
  classes,
}: React.PropsWithChildren<{
  count: number
  message: string
  classes: ClassNameMap<'button' | 'header' | 'rowContainer' | 'tooltip'>
}>): JSX.Element => {
  return (
    <ButtonPopover
      ButtonComponent={PlainTextButton}
      className={classes.button}
      size='small'
      startIcon={<ZoomIn />}
      buttonContent={
        `${count} row${count === 1 ? '': 's'}`
      }
      PopoverProps={{
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'center',
          horizontal: 'left',
        },
      }}>
      <Box minWidth='min-content' className={classes.tooltip}>
        <Typography variant='h5' className={classes.header}>{ message }</Typography>
        <div className={classes.rowContainer}>
          { children }
        </div>
      </Box>
    </ButtonPopover>
  )
}


const UploadErrorFeedback = ({
  errorSummaryContainer,
  title,
  titleClassName,
  ...props
}: {
  errorSummaryContainer: UploadErrorSummaryContainer
  title: string | JSX.Element
  titleClassName?: string
} & BoxProps): JSX.Element => {
  const classes = useErrorFeedbackStyles()
  return (
    <Box {...props}>
      <Typography className={titleClassName} variant='h6'>{title}</Typography>
      <Box mt={0.5}>
        { errorSummaryContainer.references && (
          errorSummaryContainer.references.map( (r, i) => (
            <div key={`detail-references-${i}`}>
              <Typography className={classes.errorMessage}>
                { r.message }
                <ErrorRowDetail
                  classes={classes}
                  count={r.count}
                  message={r.message}>
                  { r.rows.map( (row, i) => (
                    <div key={i} className={classes.row}>
                      { row.sheet }
                      <ChevronRight className={classes.separator} />
                      Row { row.row }
                    </div>
                  ))}
                </ErrorRowDetail>
              </Typography>
            </div>
          ))
        )}

        { errorSummaryContainer.conflicts && (
          errorSummaryContainer.conflicts.map( (r, i) => (
            <div key={`detail-conflicts-${i}`}>
              <Typography className={classes.errorMessage}>
                { r.message }
                <ErrorRowDetail
                  classes={classes}
                  count={r.count}
                  message={r.message}>
                  { r.rows.map( (row, i) => (
                    <div key={i} className={classes.row}>
                      { row.sheet }
                      <ChevronRight className={classes.separator} />
                      { row.month_name }
                      <ChevronRight className={classes.separator} />
                      Row { row.row }
                      <ChevronRight className={classes.separator} />
                      Previous: { displayMicros(row.existing_value_micros) } vs {' '}
                      New: { displayMicros(row.new_value_micros) }
                    </div>
                  ))}
                </ErrorRowDetail>
              </Typography>
            </div>
          ))
        )}

        { errorSummaryContainer.input_rows && (
          errorSummaryContainer.input_rows.map( (r, i) => (
            <div key={`detail-input_rows-${i}`}>
              <Typography className={classes.errorMessage}>
                { r.message }
                <ErrorRowDetail
                  classes={classes}
                  count={r.count}
                  message={r.message}>
                  { r.rows.map( (row, i) => (
                    <div key={i} className={classes.row}>
                      { row.sheet }
                      <ChevronRight className={classes.separator} />
                      Row { row.row }
                      <ChevronRight className={classes.separator} />
                      { row.data }
                    </div>
                  ))}
                </ErrorRowDetail>
              </Typography>
            </div>
          ))
        )}

        { errorSummaryContainer.sheet && (
          errorSummaryContainer.sheet.map( (r, i) => (
            <div key={`detail-sheet-${i}`}>
              <Typography className={classes.errorMessage}>
                { r.message }
                <ErrorRowDetail
                  classes={classes}
                  count={r.count}
                  message={r.message}>
                  { r.rows.map( (row, i) => (
                    <div key={i} className={classes.row}>
                      { row.sheet }
                      <ChevronRight className={classes.separator} />
                      { row.data }
                    </div>
                  ))}
                </ErrorRowDetail>
              </Typography>
            </div>
          ))
        )}
      </Box>
    </Box>
  )
}


const UploadFeedback = ({
  response
}: {
  response: FileUploadResponse
}): JSX.Element => {

  const { warnings, errors } = useMemo(() => {
    return parseUploadFeedback(response)
  }, [response])

  const classes = useStatusStyles()

  const feedback = (
    <Fragment>
      { errors && (
        <UploadErrorFeedback
          my={3}
          title='Errors'
          titleClassName={classes.error}
          errorSummaryContainer={errors} />
      )}
      { warnings && (
        <UploadErrorFeedback
          my={3}
          title='Warnings'
          titleClassName={classes.warning}
          errorSummaryContainer={warnings} />
      )}
    </Fragment>
  )

  let statusFeedback: JSX.Element | null = null

  if( response.status === 'success' ){
    const { row_count } = response.added_data
    const isPlural = row_count !== 1
    const isNoOp = row_count === 0 && (
      !errors || every(Object.values(errors), v => !v.length )
    ) && (
      !warnings || every(Object.values(warnings), v => !v.length )
    )
    statusFeedback = (
      <Fragment>
        <Typography paragraph>
          <strong>
            { isNoOp ? (
              'No rows were added, this file has likely been uploaded previously'
            ) : (
              'Your upload was successful!'
            )}
          </strong>
        </Typography>
        <Typography paragraph>
          <strong>
            {row_count} row{isPlural ? 's' : ''}
          </strong>
          { isPlural ? ' were' : ' was'} added
        </Typography>
        { !isNoOp && (
          <Typography>
            Thank you for your help, your data is now showing in the Media Investment reports
          </Typography>
        )}
      </Fragment>
    )
  }else{
    const filename = get(response, ['file_metadata', 'filename'], 'MMIR file')
    statusFeedback = (
      <Fragment>
        <Typography>
          <strong>{filename}</strong> could not be uploaded or contains errors
        </Typography>
      </Fragment>
    )
  }

  return (
    <Fragment>
      { statusFeedback }
      { feedback }
    </Fragment>
  )
}


const useStyles = makeStyles( theme => ({
  appBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
  },
  content: {
    paddingTop: theme.spacing(3),
  },
  dropzone: {
    minHeight: '10rem',
    minWidth: '20rem',
    padding: theme.spacing(3),
    margin: theme.spacing(4, 0),
    borderRadius: theme.shape.borderRadius,
    border: 'dashed 3px',
    color: theme.palette.action.disabled,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  fileChip: {
    margin: theme.spacing(4, 0, 2, 0),
  },
}) )


const uploadSheetTypes: SpreadsheetUploadType[] = ['MEDIA_SPEND', 'COMPETITIVE_SHARE_OF_SPEND', 'COMPETITIVE_SHARE_OF_VOICE']

export const DataUpload = ({
  org_unit_id,
  ButtonProps = {},
}: DataUploadProps): JSX.Element => {

  const mutationHook = Api.useSpreadsheetUpload({ org_unit_id })

  const [view, setView] = useState<'upload' | 'status'>('upload')

  const { org_privileges } = useContext(UserPrivilegeContext)

  const enabledSheetTypeFlags: Record<SpreadsheetUploadType, boolean> = {
    COMPETITIVE_SHARE_OF_SPEND: userHasOrgPrivileges(
      ['mediaInvestment.manageData.competitive.edit'],
      org_unit_id,
      org_privileges,
    ),
    COMPETITIVE_SHARE_OF_VOICE: userHasOrgPrivileges(
      ['mediaInvestment.manageData.competitive.edit'],
      org_unit_id,
      org_privileges,
    ),
    MEDIA_SPEND: userHasOrgPrivileges(
      [
        'mediaInvestment.manageData.atl.edit',
        'mediaInvestment.manageData.digital.edit',
      ],
      org_unit_id,
      org_privileges,
    ),
  }

  const enabledSheetTypes = uploadSheetTypes.filter( t => !!enabledSheetTypeFlags[t] )

  const defaultSheetTypeValue: SpreadsheetUploadType | null = enabledSheetTypes.length === 1 ? enabledSheetTypes[0] : null

  const [sheetType, setSheetType] = useState<SpreadsheetUploadType | null>(defaultSheetTypeValue)

  const [file, setFile] = useState<File | null>(null)

  const onDrop = ([file]: File[]) => {
    if( file ){
      setFile(file)
      mutationHook.reset()
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptSpreadsheet,
  })

  const [open, setOpen] = useState(false)

  const onClose = (): void => {
    setOpen(false)
    setFile(null)
    mutationHook.reset()
    setSheetType(defaultSheetTypeValue)
    setView('upload')
  }

  const classes = useStyles()

  const statusClasses = useStatusStyles()

  let content = null

  const mutationData = mutationHook.data

  const isPartialSuccess = !!(
    mutationData && some(
      ['warnings', 'conflicts', 'errors'],
      key => !!(
        get(mutationData, ['warnings', key, 'length'])
      )
    )
  )

  if( mutationHook.data ){
    content = (
      <Box pb={2}>
        <Typography
          className={isPartialSuccess ? statusClasses.warning : statusClasses.success}
          variant='h5'
          style={{display: 'flex', alignItems: 'center'}}>
          { isPartialSuccess ? (
            <WarningOutlined color='inherit' style={{marginRight: 6}} />
          ): (
            <CheckCircleOutline color='inherit' style={{marginRight: 6}} />
          )}
          { isPartialSuccess ? 'Upload Partially Succeeded' : 'Upload Successful'}
        </Typography>
        <Box pt={2}>
          <UploadFeedback response={mutationHook.data} />
        </Box>
      </Box>
    )
  }else if( mutationHook.isLoading ){
    const { loaded, total } = mutationHook.progress
    const progressPercent = (loaded / total) * 100
    content = (
      <Box pt={2} pb={2}>
        <Typography className={statusClasses.info} variant='h5' style={{display: 'flex', alignItems: 'center'}}>
          <CircularProgress size='1.25rem' color='inherit' style={{marginRight: 6}} />
          { file ?
            `Uploading ${file.name}` :
            'Uploading File'
          }
        </Typography>
        <Box py={8}>
          <Typography variant='subtitle1' paragraph>
            Uploaded {humanReadableFilesize(loaded)} / {humanReadableFilesize(total)}
          </Typography>
          <LinearProgress variant='determinate' value={progressPercent} />
        </Box>
      </Box>
    )
  }else if( mutationHook.error ){
    // NOTE - errors that aren't upload failures, but specifically unexpected exceptions such as internal server errors,
    // present with a garbled string payload under `response.data`, rather than a response which tells us about the
    // upload failure. So we check for this and conditionally show just the error message before assuming we have a valid
    // upload failure response. Also we check for falsy response object and fallback to a message or suitable default.
    const isServerError = (
      mutationHook.error.response && typeof mutationHook.error.response.data === 'string'
    )
    content = (
      <Box pb={2}>
        <Typography className={statusClasses.error} variant='h5' style={{display: 'flex', alignItems: 'center'}}>
          <ErrorOutline color='inherit' style={{marginRight: 6}} />
          Upload Failed
        </Typography>
        <Box pt={2}>
          { isServerError ? (
            <Typography paragraph>
              { mutationHook.error.message }
            </Typography>
          ) : mutationHook.error.response ? (
            <UploadFeedback response={mutationHook.error.response.data} />
          ) : (
            <Typography paragraph>
              { mutationHook.error.message || 'Server error' }
            </Typography>
          )}
          <Alert
            icon={<Help style={{marginRight: 6}} />}
            display='inline-flex'
            px={1.5}
            mt={2}
            mb={3}
            message={
              <>
                Email us at <strong><a href={`mailto:${VODAFONE_SUPPORT_EMAIL}`}>{ VODAFONE_SUPPORT_EMAIL }</a></strong>{' '}
                if you require any assistance with your upload
              </>
            } />
        </Box>
      </Box>
    )
  }

  const success = mutationHook.data && mutationHook.data.status === 'success'

  useEffect(() => {
    if( success ){
      setFile(null)
    }
  }, [success])

  return (
    <Fragment>

      <RoundedPlainTextButton
        variant='contained'
        size='small'
        startIcon={
          <Image />
        }
        {...ButtonProps}
        onClick={(): void => setOpen(true)}>
        Upload MMIR File
      </RoundedPlainTextButton>

      <Dialog
        open={open}
        maxWidth='md'
        fullWidth
        disableEscapeKeyDown
        disableBackdropClick
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
        onClose={onClose}>

        <AppBar
          className={classes.appBar}
          color='primary'
          position='relative'>
          <Typography variant='h4'>
            Upload MMIR File
          </Typography>

          { (view === 'status' || mutationHook.isLoading) && sheetType && (
            <Chip
              icon={<ListAlt />}
              label={deslugify(sheetType)} />
          ) }
        </AppBar>

        <DialogContent className={classes.content}>

          { view === 'upload' && !mutationHook.isLoading && (
            <Fragment>
              <Typography paragraph>
                Use the upload feature to submit your MMIR template file to Vodafone Group. Once uploaded, the data you reported
                will immediately show in the reports.
              </Typography>
              <Typography paragraph>
                Use this feature both to upload new data or edit old data into the system.
              </Typography>
              <Typography paragraph>
                Please note that the system will replace all the data for the month that you have selected in the spreadsheet “month selector”.
              </Typography>
              <Typography paragraph>
                Thank you and happy uploading!
              </Typography>

              <Box mt={2} display='flex' justifyContent='center'>
                <RoundedPlainTextButtonMenu
                  TriggerProps={{
                    size: 'large',
                    variant: 'contained',
                    startIcon: <ListAlt />,
                    endIcon: <ArrowDropDown />,
                  }}
                  value={sheetType}
                  label={sheetType === null ? 'Select Spreadsheet Type' : deslugify(sheetType)}
                  options={
                    enabledSheetTypes.map( value => ({
                      value,
                      label: deslugify(value)
                    }))
                  }
                  onChange={(e, value: SpreadsheetUploadType): void => {
                    setSheetType(value)
                  }} />
              </Box>
            </Fragment>
          )}

          { mutationHook.isLoading && (
            <Typography paragraph>
              We are uploading your file, we will tell you when the process is complete. 
              Please, do not close this page whilst we run this operation
            </Typography>
          )}

          { content }

          { mutationHook.isError && (
            <RoundedPlainTextButton
              variant='contained'
              startIcon={<Undo />}
              onClick={(): void => {
                mutationHook.reset()
                setFile(null)
                setView('upload')
              }}>
              Try Another File
            </RoundedPlainTextButton>
          )}

          { view === 'upload' && (
            <div
              className={classes.dropzone}
              {...getRootProps()}>
              <input
                {...getInputProps()} />
              <Typography
                color='inherit'
                variant='h4'>
                Drag the MMIR template here, or click to select a file
              </Typography>
              { file && (
                <Chip
                  className={classes.fileChip}
                  color='secondary'
                  size='medium'
                  icon={<InsertDriveFile />}
                  label={
                    <span style={{display: 'flex', alignItems: 'center'}}>
                      <Typography variant='subtitle2'>
                        {file.name}
                      </Typography>
                      <Typography variant='subtitle1' style={{marginLeft: 6}}>
                        {humanReadableFilesize(file.size)}
                      </Typography>
                    </span>
                  } />
              )}
            </div>
          )}

        </DialogContent>

        <DialogActions>
          <Button
            variant='contained'
            color='default'
            disabled={mutationHook.isLoading}
            startIcon={
              <Close />
            }
            onClick={onClose}>
            { view === 'upload' ? 'Cancel' : 'Close' }
          </Button>

          { view === 'upload' && (
            <Button
              variant='contained'
              color='primary'
              disabled={!sheetType || !file || mutationHook.isLoading}
              startIcon={
                <Check />
              }
              onClick={(): void => {
                if( sheetType && file ){
                  mutationHook.mutate({ sheetType, file })
                  setView('status')
                }
              }}>
              Upload
            </Button>
          )}

        </DialogActions>

      </Dialog>

    </Fragment>
  )
}

