import { AdformMediaType, AdformRTBInventorySourceType } from '@percept/types'


export const adformRTBInventorySourceEnumMapping: Record<number, AdformRTBInventorySourceType> = {
  1: 'ACEEX',
  2: 'ACROSSMEDIA_241',
  3: 'ADAGIO',
  4: 'ADAPTIVE_MEDIA',
  5: 'ADAPT_MX',
  6: 'ADASTA',
  7: 'ADCLICK',
  8: 'ADCOLONY',
  9: 'ADEVINTA',
  10: 'ADHESE',
  11: 'ADHOUSE',
  12: 'ADINMO',
  13: 'ADIPOLO',
  14: 'ADKAORA',
  15: 'ADMAN_MEDIA',
  16: 'ADMASTER',
  17: 'ADMATIC',
  18: 'ADMIXER',
  19: 'ADPONE',
  20: 'ADPROFIT',
  21: 'ADQUOTA_LATVIA',
  22: 'ADRINO_MOBILE',
  23: 'ADSPARC',
  24: 'ADS_INTERACTIVE',
  25: 'ADTARGET',
  26: 'ADTECH_LAB',
  27: 'ADVERTY',
  28: 'ADVISER',
  29: 'ADYOULIKE',
  30: 'AD_NORDICS',
  31: 'AGORA',
  32: 'ALAYANS_MEDIA',
  33: 'ALLER_MEDIA',
  34: 'ALL_MEDIA_DIGITAL',
  35: 'ALMA_MEDIA',
  36: 'AMEDIA',
  37: 'ANTENA_DIGITAL',
  38: 'ANYCLIP',
  39: 'APESTER',
  40: 'APPSTOCK',
  41: 'ARAB_TRADE_DESK',
  42: 'ARCHONPH',
  43: 'ASCENDEUM_CO',
  44: 'AUSTRIAPLUS',
  45: 'AUTOSCOUT24',
  46: 'AVANTIS_VIDEO',
  47: 'AVTO_NET',
  48: 'AXEL_SPRINGER',
  49: 'AZERION_TECHNOLOGY',
  50: 'BEHRENTZ_MEDIA',
  51: 'BERLINGSKE_MEDIA',
  52: 'BIDGENCY',
  53: 'BIZZCLICK',
  54: 'BLOGGERSDELIGHT',
  55: 'BOLDWIN',
  56: 'BONNIER_NEWS',
  57: 'BOONS_MEDIA',
  58: 'BRAND_COMMUNITY_NETWORK',
  59: 'BRIDGEUPP',
  60: 'BROADSIGN',
  61: 'BRO_MEDIA',
  62: 'BUGSYEMPIRE',
  63: 'BURDA',
  64: 'BUSINESS_INSIDER',
  65: 'CAVIXO',
  66: 'CENTRAL',
  67: 'CIAOPEOPLE',
  68: 'CINER_DIRECT_INVOICING',
  69: 'CLICK_PERFORMANCE',
  70: 'CLOUD_ADRIA',
  71: 'CMC_CITY_MEDIA',
  72: 'CNN',
  73: 'CONCEPT',
  74: 'CONNATIX_NATIVE_EXCHANGE',
  75: 'CONNECTAD',
  76: 'CRITEO_COMMERCE_GRID',
  77: 'CSFD',
  78: 'CZECH_NEWS_CENTER',
  79: 'DAILYBREAK',
  80: 'DAILYMOTION',
  81: 'DELFI',
  82: 'DELIDATAX',
  83: 'DELIVERY_MEDIA',
  84: 'DET_NORDJYSKE_MEDIEHUS',
  85: 'DIANOMI',
  86: 'DIENA',
  87: 'DIGINET_LTU',
  88: 'DIGITAL_MATTER',
  89: 'DIGITAL_TREE_MEDIA',
  90: 'DN_MEDIA_GROUP',
  91: 'DPG_MEDIA',
  92: 'EADV',
  93: 'ECONOMIA',
  94: 'EGMONT',
  95: 'EKSPRESS_MEEDIA',
  96: 'EKSTRA_BLADET',
  97: 'ELITE_APPGRADE',
  98: 'EL_CONFIDENCIAL_DIGITAL',
  99: 'EMODO',
  100: 'EMX_DIGITAL',
  101: 'ENIRO',
  102: 'ENOVATIVE_MEDIA',
  103: 'EQUATIV',
  104: 'ETAPLIUS',
  105: 'ETARGET',
  106: 'EUROZET',
  107: 'EVERYSPORT',
  108: 'EVOLUTION_ADV',
  109: 'EXECUTE_MEDIA',
  110: 'FACTOR_ELEVEN',
  111: 'FANBYTE',
  112: 'FILMVANDAAG_ADFORM_SSP',
  113: 'FINANSAVISEN',
  114: 'FINYA',
  115: 'FLIGHTRADAR24',
  116: 'FOLDERZ',
  117: 'FORBES',
  118: 'FOUNDRY',
  119: 'FOUR_W_MARKETPLACE',
  120: 'FOX_NEWS',
  121: 'FREESTAR',
  122: 'FREEWHEEL_SSP',
  123: 'FUNKE_DIGITAL',
  124: 'FYBER_MARKETPLACE',
  125: 'GADSME',
  126: 'GAMELOFT',
  127: 'GAMERMEDIA',
  128: 'GANDUL_MEDIA_NETWORK',
  129: 'GENERATION_PREMIUM',
  130: 'GEOGUESSR',
  131: 'GISMETEO',
  132: 'GITBERRY',
  133: 'GOOGLE_ADS',
  134: 'GOTA_MEDIA',
  135: 'GOURMET_ADS',
  136: 'GREMI_BUSINESS_COMMUNICATION',
  137: 'GRUPA_NATEMAT',
  138: 'GRUPA_RMF',
  139: 'GRUPA_WIRTUALNA',
  140: 'GULE_SIDER',
  141: 'GUMGUM',
  142: 'GUMTREE_SOUTH',
  143: 'HAPPYPANCAKE',
  144: 'HB_AGENCY',
  145: 'HEISE_MEDIEN',
  146: 'HEMNET',
  147: 'HIC_MOBILE',
  148: 'HIGHFIVVE',
  149: 'HIMEDIA',
  150: 'HITTA',
  151: 'HIVESTACK',
  152: 'HOLA',
  153: 'HOLID',
  154: 'HUUTO_NET',
  155: 'HVG',
  156: 'IDEAPROGRAMMATIC_NETWORK',
  157: 'IION_SSP',
  158: 'IMPACTIFY_LU',
  159: 'IMPRESSION_MEDIA',
  160: 'IMPROVE_DIGITAL',
  161: 'IMPROVE_MEDIA',
  162: 'INDAMEDIA',
  163: 'INDEX',
  164: 'INFINETY_ONLINE',
  165: 'INFORMATIONSGRUPPEN',
  166: 'INFOR_BIZNES',
  167: 'INMOBI',
  168: 'INSTICATOR',
  169: 'INTERACTIVEMEDIA_CCSP',
  170: 'INTERNET',
  171: 'INTERNET_INFO',
  172: 'INVESTING',
  173: 'INVIEWADVERTISING',
  174: 'ITALIAONLINE',
  175: 'I_R_V',
  176: 'JOJ',
  177: 'JYLLANDSPOSTEN',
  178: 'KEENKALE',
  179: 'KEY_MOBILE',
  180: 'KRAK_DISPLAY_NETWORK',
  181: 'KRONE',
  182: 'KUEEZ',
  183: 'KURZY',
  184: 'LADBIBLE',
  185: 'LEEADS',
  186: 'LETGO',
  187: 'LIFE_OF_SVEA',
  188: 'LIVAD',
  189: 'LMEDS',
  190: 'LOOPME',
  191: 'LUPON_MEDIA',
  192: 'MAD_MEN_CONSULTING',
  193: 'MAFRA',
  194: 'MAGNITE_CTV_PLATFORM',
  195: 'MAGNITE_DV_PLATFORM',
  196: 'MAGNITE_STREAMING',
  197: 'MAILONLINE',
  198: 'MAIL_METRO_MEDIA',
  199: 'MANATEE_NETWORK',
  200: 'MARKETPLACE_BY_AOL',
  201: 'MAROELA_MEDIA',
  202: 'MATCH',
  203: 'MATHRICS',
  204: 'MAXETISE',
  205: 'MEDIA24',
  206: 'MEDIAAD',
  207: 'MEDIAHUIS',
  208: 'MEDIAMOND',
  209: 'MEDIASQUARE',
  210: 'MEDIA_FARM',
  211: 'MEDIA_GROUP',
  212: 'MEDIA_IMPACT',
  213: 'MEDIEKOMPANIET',
  214: 'METUP',
  215: 'MICROSOFT_AD_EXCHANGE',
  216: 'MIMEDA_NETWORK',
  217: 'MINMAVE',
  218: 'MMEDIA',
  219: 'MODRY_KONIK',
  220: 'MONEYTAG',
  221: 'MOTHER_TONGUE_MEDIA',
  222: 'MSO_DIGITAL',
  223: 'MTVUUTISET_DISPLAYOUTSTREAM',
  224: 'MULTI_CHANNEL_NETWORK',
  225: 'NA',
  226: 'NETADDICTION',
  227: 'NETPOINT_MEDIA',
  228: 'NETPOOL',
  229: 'NETSENSE_PUBLISHER',
  230: 'NEWSNER',
  231: 'NEWS_AND_MEDIA_HOLDING',
  232: 'NEXT14',
  233: 'NEXTDAYMEDIA',
  234: 'NINE_DOTS_MEDIA',
  235: 'NOJESGUIDEN_MEDIA',
  236: 'NOKTA',
  237: 'NOSTEMEDIA',
  238: 'NOVY_CAS',
  239: 'NRC',
  240: 'NTM',
  241: 'NWT_MEDIA',
  242: 'OCM_S2S',
  243: 'OE24',
  244: 'OGURY',
  245: 'OLX',
  246: 'ONEDIO',
  247: 'ONEFOOTBALL',
  248: 'ONETAG',
  249: 'ONE_EIGHT_EIGHT_ONE',
  250: 'ONE_FIVE_MIN',
  251: 'ONE_ONE_MAILMEDIA_UNITED_INTERNET_MEDIA',
  252: 'ONE_XL',
  253: 'ONNETWORK',
  254: 'ONOMAGIC',
  255: 'OPENX',
  256: 'OPTAD360',
  257: 'ORANGE_CLICK_MEDIA',
  258: 'ORTB_KUEEZ',
  259: 'OTAVAMEDIA',
  260: 'OUTBRAIN',
  261: 'OZONE',
  262: 'PAZAR3',
  263: 'PERFORMAX',
  264: 'PETIT_PRESS',
  265: 'PET_MEDIA_GROUP',
  266: 'PHAISTOS_NETWORK',
  267: 'PIEMME',
  268: 'PIXAD',
  269: 'PMPG',
  270: 'PODIUM_NORTH',
  271: 'POLARIS_MEDIA',
  272: 'POP_MEDIA',
  273: 'PORT80',
  274: 'PRAVDA',
  275: 'PREMIO_WEBSYSTEM',
  276: 'PRESS',
  277: 'PRESSCOMM_TECH',
  278: 'PRIMA_ONLINE',
  279: 'PRIME_REAL_TIME',
  280: 'PRIMIS',
  281: 'PRISGUIDEN',
  282: 'PRISMA_MEDIA',
  283: 'PROFF',
  284: 'PROJECT_ADV',
  285: 'PROJECT_AGORA',
  286: 'PROVIZION',
  287: 'PUBGALAXY',
  288: 'PUBLISHERS',
  289: 'PUBLY',
  290: 'PUBMATIC',
  291: 'PUBSTREAM_ADVERTISING',
  292: 'PUSHFIRE',
  293: 'QUARTER_MEDIA_SSP',
  294: 'R2B2_PREMIUM_ONLINE',
  295: 'RADIO_GROUP',
  296: 'RAINBERRY',
  297: 'RAKUTEN_TV',
  298: 'RANTAPALLO',
  299: 'RAPTIVE',
  300: 'RATSIT',
  301: 'REFINERY_89',
  302: 'REFRESHER',
  303: 'REKLAM9',
  304: 'REKLAMSTORE',
  305: 'RICH_AUDIENCE_MARKETPLACE',
  306: 'RINGIER',
  307: 'RISE',
  308: 'RND_REDAKTIONSNETZWERK',
  309: 'ROSSEL',
  310: 'RP_DIGITAL',
  311: 'RTB_ONE',
  312: 'SAHIBINDEN',
  313: 'SALESWORKS',
  314: 'SCREEN',
  315: 'SCREENONDEMAND',
  316: 'SEEDTAG',
  317: 'SELECT_MEDIA',
  318: 'SEMSEOYMAS',
  319: 'SETUPAD',
  320: 'SEZNAM',
  321: 'SHARETHROUGH',
  322: 'SHOWHEROES',
  323: 'SIGMA_BIS',
  324: 'SIMPLY_BLACK_MEDIA',
  325: 'SJAELLANDSKE_MEDIER',
  326: 'SKY',
  327: 'SMAATO',
  328: 'SMARTAD',
  329: 'SMARTCLIP',
  330: 'SMARTSTREAM_TV',
  331: 'SMARTYADS',
  332: 'SMG',
  333: 'SMILE_WANTED',
  334: 'SNIGEL',
  335: 'SOFTONIC',
  336: 'SOLUTION',
  337: 'SOVRN',
  338: 'SPIRIT_MEDIA',
  339: 'SPORTSBIBELEN',
  340: 'SPORTSPORT_BA',
  341: 'SPOTXCHANGE',
  342: 'SQUID',
  343: 'STAILAMEDIA',
  344: 'STAMPEN',
  345: 'STARTITUP_GROUP',
  346: 'STARTSIDEN',
  347: 'STEP_NETWORK',
  348: 'STREAMKEY_TV',
  349: 'STROER_SSP',
  350: 'SUBLIME_SKINZ',
  351: 'SUNMEDIA_TV_SSP',
  352: 'SYNONYMER',
  353: 'TABOOLA',
  354: 'TAPPX',
  355: 'TARGETVIDEO',
  356: 'TEADS',
  357: 'TELEGRAFI',
  358: 'THEMATIC_DIGITAL',
  359: 'THETA',
  360: 'THETA_MARKETPLACE',
  361: 'THE_ADS',
  362: 'THE_DAILY_EXPRESS',
  363: 'THE_DAILY_MIRROR',
  364: 'THE_GOOD_MEN_PROJECT',
  365: 'THE_INDEPENDENT',
  366: 'THE_MONEYTIZER',
  367: 'THE_TELEGRAPH',
  368: 'THE_WEATHER_CHANNEL',
  369: 'THREE_THREE_ACROSS',
  370: 'TIME_FOR_FRIENDS',
  371: 'TIME_OUT',
  372: 'TOLOCAL',
  373: 'TORI',
  374: 'TRADERA',
  375: 'TRAFFECTIVE',
  376: 'TRINA_MARKETING',
  377: 'TRIPLELIFT',
  378: 'TRITABLE',
  379: 'TRITON_DIGITAL',
  380: 'TRUSTED_MEDIA_BRANDS_INTERNATIONAL',
  381: 'TURKUVAZ',
  382: 'TV2',
  383: 'TVGUIDE',
  384: 'TVN',
  385: 'TVP',
  386: 'TV_MARKIZA',
  387: 'TV_NOVA',
  388: 'TWO_CIRCLES_ICC',
  389: 'UNBLOCKIA',
  390: 'UNIBOTS',
  391: 'UNRULY',
  392: 'UNRULYX',
  393: 'VENATUS_MEDIA_LIMITED',
  394: 'VIADS_ADVERTISING',
  395: 'VICINITY_MEDIA',
  396: 'VIDAZOO',
  397: 'VIDEONOW',
  398: 'VIDEO_INTELLIGENCE',
  399: 'VIDOOMY_SSP',
  400: 'VIDVERTO',
  401: 'VINTED',
  402: 'VIOUSLY',
  403: 'VIRALIZE',
  404: 'VK_MEDIA',
  405: 'VLTAVA_LABE_MEDIA',
  406: 'WAY_TO_GROW',
  407: 'WEBADS',
  408: 'WEBEDIA_EXCHANGE',
  409: 'WEBNOVINY_ISITA',
  410: 'WEDOTV',
  411: 'WETTER',
  412: 'WETTERONLINE',
  413: 'WILLHABEN',
  414: 'WIRTUALNA_MEDIA_S_A',
  415: 'XANDR_MONETIZE_SSP',
  416: 'XOME',
  417: 'XXL_MARKETING_SOLUTION',
  418: 'YAHOO_EXCHANGE',
  419: 'YEARXERO',
  420: 'YEEBASE_MEDIA',
  421: 'YIELDLAB',
  422: 'YIELDLOVE',
  423: 'YIELDMO',
  424: 'YIELDRISER',
  425: 'YIELD_BIRD',
  426: 'YIELD_ON_DEMAND',
  427: 'YIELD_OPTIMISERS',
  428: 'YOBEE',
  429: 'YOC',
  430: 'YOC_VIS_X',
  431: 'ZOOPLA',
  432: 'ZOZNAM',
  433: 'ZPR_MEDIA_GROUP',
  434: 'BIDSCUBE',
  435: 'MONDO_RS',
  436: 'ROME2RIO',
  437: 'THE_MOBILE_AGENCY',
  438: 'TRADE_HOUSE_MEDIA',
  439: 'WAIPU_TV',
  440: 'GOGEL_PREMIUM_SUPPLY',
  441: 'PROGRAMMATIC_PREMIUM_COLLECTION',
  442: 'XYMATIC',
  443: 'PIGEOON',
  444: 'ZILLOW',
  445: 'KOLNER_STADTANZEIGER',
  446: 'ALEHDET',
  447: 'GLOMEX',
  448: 'RHYTHMONE',
  449: 'AUDIENZZ',
  450: 'ROYA_MEDIA_GROUP',
  451: 'GOLDBACH',
  452: 'SANOMA',
  453: 'VISION_THING_DOOH',
  454: 'EMITTENTE_EDITORIALE',
  455: 'MEMBRANA_MEDIA',
  456: 'TRANSFERMARKT',
  457: 'DIGITAL_TURBINE',
  458: 'GRAVITE',
  459: 'MOTORIK',
  460: 'PRESSFIRE',
  461: 'ADARABIA',
  462: 'ADSYIELD',
  463: 'ANZU',
  464: 'BRAINFOOD_PUBLISHING',
  465: 'DIGITAL_GREEN',
  466: 'MASSARIUS',
  467: 'PHILIPS',
  468: 'WONDERUS_OY',
  469: 'XITE',
  470: 'RTL',
  471: 'RED_VIEW_MEDIA',
  472: 'SILVER_BULLET',
  473: 'MEDIAWORKS',
}


export const adformRTBInventorySourceDisplayLabelMapping: Record<AdformRTBInventorySourceType, string> = {
  ACEEX: 'Aceex',
  ACROSSMEDIA_241: 'AcrossMedia 241',
  AD_NORDICS: 'Ad Nordics',
  ADAGIO: 'Adagio',
  ADAPT_MX: 'Adapt Mx',
  ADAPTIVE_MEDIA: 'Adaptive Media',
  ADARABIA: 'AdArabia',
  ADASTA: 'Adasta',
  ADCLICK: 'Adclick',
  ADCOLONY: 'AdColony',
  ADEVINTA: 'Adevinta',
  ADHESE: 'Adhese',
  ADHOUSE: 'Adhouse',
  ADINMO: 'AdInMo',
  ADIPOLO: 'Adipolo',
  ADKAORA: 'Adkaora',
  ADMAN_MEDIA: 'Adman Media',
  ADMASTER: 'Admaster',
  ADMATIC: 'Admatic',
  ADMIXER: 'Admixer',
  ADPONE: 'Adpone',
  ADPROFIT: 'Adprofit',
  ADQUOTA_LATVIA: 'Adquota Latvia',
  ADRINO_MOBILE: 'Adrino Mobile',
  ADS_INTERACTIVE: 'Ads Interactive',
  ADSPARC: 'Adsparc',
  ADSYIELD: 'AdsYield',
  ADTARGET: 'Adtarget',
  ADTECH_LAB: 'Adtech Lab',
  ADVERTY: 'Adverty',
  ADVISER: 'Adviser',
  ADYOULIKE: 'Adyoulike',
  AGORA: 'Agora',
  ALAYANS_MEDIA: 'Alayans Media',
  ALEHDET: 'Alehdet',
  ALL_MEDIA_DIGITAL: 'All Media Digital',
  ALLER_MEDIA: 'Aller Media',
  ALMA_MEDIA: 'Alma Media',
  AMEDIA: 'Amedia',
  ANTENA_DIGITAL: 'Antena Digital',
  ANYCLIP: 'Anyclip',
  ANZU: 'Anzu',
  APESTER: 'Apester',
  APPSTOCK: 'Appstock',
  ARAB_TRADE_DESK: 'Arab Trade Desk',
  ARCHONPH: 'Archonph',
  ASCENDEUM_CO: 'Ascendeum',
  AUDIENZZ: 'Audienzz',
  AUSTRIAPLUS: 'Austria Plus',
  AUTOSCOUT24: 'Autoscout24',
  AVANTIS_VIDEO: 'Avantis Video',
  AVTO_NET: 'Avto',
  AXEL_SPRINGER: 'Axel Springer',
  AZERION_TECHNOLOGY: 'Azerion Technology',
  BEHRENTZ_MEDIA: 'Behrentz Media',
  BERLINGSKE_MEDIA: 'Berlingske Media',
  BIDGENCY: 'Bidgency',
  BIDSCUBE: 'Bidscube',
  BIZZCLICK: 'BizzClick',
  BLOGGERSDELIGHT: 'Bloggers Delight',
  BOLDWIN: 'BoldWin',
  BONNIER_NEWS: 'Bonnier News',
  BOONS_MEDIA: 'Boons Media',
  BRAINFOOD_PUBLISHING: 'Brainfood Publishing',
  BRAND_COMMUNITY_NETWORK: 'Brand Community Network',
  BRIDGEUPP: 'BridgeUpp',
  BRO_MEDIA: 'Bro Media',
  BROADSIGN: 'Broadsign',
  BUGSYEMPIRE: 'BugsyEmpire',
  BURDA: 'Burda',
  BUSINESS_INSIDER: 'Business Insider',
  CAVIXO: 'Cavixo',
  CENTRAL: 'Central',
  CIAOPEOPLE: 'CiaoPeople',
  CINER_DIRECT_INVOICING: 'Ciner',
  CLICK_PERFORMANCE: 'Click Performance',
  CLOUD_ADRIA: 'Cloud Adria',
  CMC_CITY_MEDIA: 'CMC City Media',
  CNN: 'CNN',
  CONCEPT: 'Concept',
  CONNATIX_NATIVE_EXCHANGE: 'Connatix Native Exchange',
  CONNECTAD: 'ConnectAd',
  CRITEO_COMMERCE_GRID: 'Criteo Commerce Grid',
  CSFD: 'ČSFD',
  CZECH_NEWS_CENTER: 'Czech News Center',
  DAILYBREAK: 'Dailybreak',
  DAILYMOTION: 'Dailymotion',
  DELFI: 'Delfi',
  DELIDATAX: 'Delidatax',
  DELIVERY_MEDIA: 'Delivery Media',
  DET_NORDJYSKE_MEDIEHUS: 'Det Nordjyske Mediehus',
  DIANOMI: 'Dianomi',
  DIENA: 'Diena',
  DIGINET_LTU: 'Diginet LTU',
  DIGITAL_GREEN: 'Digital Green',
  DIGITAL_MATTER: 'Digital Matter',
  DIGITAL_TREE_MEDIA: 'Digital Tree Media',
  DIGITAL_TURBINE: 'Digital Turbine',
  DN_MEDIA_GROUP: 'DN Media Group',
  DPG_MEDIA: 'DPG Media',
  EADV: 'EADV',
  ECONOMIA: 'Economia',
  EGMONT: 'Egmont',
  EKSPRESS_MEEDIA: 'Ekspress Meedia',
  EKSTRA_BLADET: 'Ekstra Bladet',
  EL_CONFIDENCIAL_DIGITAL: 'El Confidencial Digital',
  ELITE_APPGRADE: 'Elite Appgrade',
  EMITTENTE_EDITORIALE: 'Emittente Editoriale',
  EMODO: 'Emodo',
  EMX_DIGITAL: 'EMX Digital',
  ENIRO: 'Eniro',
  ENOVATIVE_MEDIA: 'E-novative Media',
  EQUATIV: 'Equativ',
  ETAPLIUS: 'Etaplius',
  ETARGET: 'Etarget',
  EUROZET: 'Eurozet',
  EVERYSPORT: 'Everysport',
  EVOLUTION_ADV: 'Evolution ADV',
  EXECUTE_MEDIA: 'Execute Media',
  FACTOR_ELEVEN: 'Factor 11',
  FANBYTE: 'Fanbyte',
  FILMVANDAAG_ADFORM_SSP: 'Filmvandaag',
  FINANSAVISEN: 'Finansavisen',
  FINYA: 'Finya',
  FLIGHTRADAR24: 'FlightRadar24',
  FOLDERZ: 'Folderz',
  FORBES: 'Forbes',
  FOUNDRY: 'Foundry',
  FOUR_W_MARKETPLACE: '4W Marketplace',
  FOX_NEWS: 'Fox News',
  FREESTAR: 'FreeStar',
  FREEWHEEL_SSP: 'FreeWheel',
  FUNKE_DIGITAL: 'Funke Digital',
  FYBER_MARKETPLACE: 'Fyber Marketplace',
  GADSME: 'Gadsme',
  GAMELOFT: 'Gameloft',
  GAMERMEDIA: 'Gamermedia',
  GANDUL_MEDIA_NETWORK: 'Gandul Media Network',
  GENERATION_PREMIUM: 'Generation Premium',
  GEOGUESSR: 'Geoguessr',
  GISMETEO: 'Gismeteo',
  GITBERRY: 'Gitberry',
  GLOMEX: 'Glomex',
  GOGEL_PREMIUM_SUPPLY: 'Gogel Premium Supply',
  GOLDBACH: 'Goldbach',
  GOOGLE_ADS: 'Google Ads',
  GOTA_MEDIA: 'Gota Media',
  GOURMET_ADS: 'Gourmet Ads',
  GRAVITE: 'Gravite',
  GREMI_BUSINESS_COMMUNICATION: 'Gremi Business Communication',
  GRUPA_NATEMAT: 'Groupa NaTemat',
  GRUPA_RMF: 'Grupa RMF',
  GRUPA_WIRTUALNA: 'Grupa Wirtualna',
  GULE_SIDER: 'Gule Sider',
  GUMGUM: 'GumGum',
  GUMTREE_SOUTH: 'Gumtree',
  HAPPYPANCAKE: 'HappyPancake',
  HB_AGENCY: 'HB Agency',
  HEISE_MEDIEN: 'Heise Medien',
  HEMNET: 'Hemnet',
  HIC_MOBILE: 'Hic Mobile',
  HIGHFIVVE: 'Highfivve',
  HIMEDIA: 'Hi-Media',
  HITTA: 'Hitta',
  HIVESTACK: 'Hivestack',
  HOLA: 'Hola',
  HOLID: 'Holid',
  HUUTO_NET: 'Huuto',
  HVG: 'HVG',
  I_R_V: 'I.R.V.',
  IDEAPROGRAMMATIC_NETWORK: 'IdeaProgrammatic Network',
  IION_SSP: 'Iion SSP',
  IMPACTIFY_LU: 'Impactify',
  IMPRESSION_MEDIA: 'Impression Media',
  IMPROVE_DIGITAL: 'Improve Digital',
  IMPROVE_MEDIA: 'Improve Media',
  INDAMEDIA: 'Indamedia',
  INDEX: 'Index',
  INFINETY_ONLINE: 'Infinety Online',
  INFOR_BIZNES: 'Infor Biznes',
  INFORMATIONSGRUPPEN: 'Informationsgruppen',
  INMOBI: 'InMobi',
  INSTICATOR: 'Insticator',
  INTERACTIVEMEDIA_CCSP: 'InteractiveMedia CCSP',
  INTERNET_INFO: 'Internet Info',
  INTERNET: 'Internet',
  INVESTING: 'Investing',
  INVIEWADVERTISING: 'InView Advertising',
  ITALIAONLINE: 'Italia Online',
  JOJ: 'JOJ',
  JYLLANDSPOSTEN: 'JyllandsPosten',
  KEENKALE: 'Keenkale',
  KEY_MOBILE: 'Key Mobile',
  KOLNER_STADTANZEIGER: 'Kölner Stadt-Anzeiger',
  KRAK_DISPLAY_NETWORK: 'Krak Display Network',
  KRONE: 'Krone',
  KUEEZ: 'Kueez',
  KURZY: 'Kurzy',
  LADBIBLE: 'Ladbible',
  LEEADS: 'Leeads',
  LETGO: 'Letgo',
  LIFE_OF_SVEA: 'Life of Svea',
  LIVAD: 'Livad',
  LMEDS: 'LME/DS',
  LOOPME: 'LoopMe',
  LUPON_MEDIA: 'Lupon Media',
  MAD_MEN_CONSULTING: 'Mad Men Consulting',
  MAFRA: 'Mafra',
  MAGNITE_CTV_PLATFORM: 'Magnite CTV Platform',
  MAGNITE_DV_PLATFORM: 'Magnite DV+ Platform',
  MAGNITE_STREAMING: 'Magnite Streaming',
  MAIL_METRO_MEDIA: 'Mail Metro Media',
  MAILONLINE: 'MailOnline',
  MANATEE_NETWORK: 'Manatee Network',
  MARKETPLACE_BY_AOL: 'Marketplace by AOL',
  MAROELA_MEDIA: 'Maroela Media',
  MASSARIUS: 'Massarius',
  MATCH: 'Match',
  MATHRICS: 'Mathrics',
  MAXETISE: 'Maxetise',
  MEDIA_FARM: 'Media Farm',
  MEDIA_GROUP: 'Media Group',
  MEDIA_IMPACT: 'Media Impact',
  MEDIA24: 'Media24',
  MEDIAAD: 'MediaAd',
  MEDIAHUIS: 'Mediahuis',
  MEDIAMOND: 'Mediamond',
  MEDIASQUARE: 'MediaSquare',
  MEDIAWORKS: 'Mediaworks',
  MEDIEKOMPANIET: 'Mediekompaniet',
  MEMBRANA_MEDIA: 'Membrana Media',
  METUP: 'Metup',
  MICROSOFT_AD_EXCHANGE: 'Microsoft Ad Exchange',
  MIMEDA_NETWORK: 'Mimeda Network',
  MINMAVE: 'Minwave',
  MMEDIA: 'M-Media',
  MODRY_KONIK: 'Modrý Koník',
  MONDO_RS: 'Mondo RS',
  MONEYTAG: 'Moneytag',
  MOTHER_TONGUE_MEDIA: 'Mother Tongue Media',
  MOTORIK: 'Motorik',
  MSO_DIGITAL: 'MSO Digital',
  MTVUUTISET_DISPLAYOUTSTREAM: 'Mtvuutiset Display Outstream',
  MULTI_CHANNEL_NETWORK: 'Multi Channel Network',
  NA: 'N/A',
  NETADDICTION: 'Netaddiction',
  NETPOINT_MEDIA: 'Netpoint Media',
  NETPOOL: 'Netpool',
  NETSENSE_PUBLISHER: 'Netsense Publisher',
  NEWS_AND_MEDIA_HOLDING: 'News and Media Holding',
  NEWSNER: 'Newsner',
  NEXT14: 'Next14',
  NEXTDAYMEDIA: 'NextDayMedia',
  NINE_DOTS_MEDIA: '9 Dots Media',
  NOJESGUIDEN_MEDIA: 'Nöjesguiden Media',
  NOKTA: 'Nokta',
  NOSTEMEDIA: 'Nostemedia',
  NOVY_CAS: 'Novy Cas',
  NRC: 'NRC',
  NTM: 'NTM',
  NWT_MEDIA: 'NWT Media',
  OCM_S2S: 'OCM S2S',
  OE24: 'OE24',
  OGURY: 'Ogury',
  OLX: 'OLX',
  ONE_EIGHT_EIGHT_ONE: '1881',
  ONE_FIVE_MIN: '15min',
  ONE_ONE_MAILMEDIA_UNITED_INTERNET_MEDIA: '1&1 Mail&Media',
  ONE_XL: '1XL',
  ONEDIO: 'Onedio',
  ONEFOOTBALL: 'OneFootball',
  ONETAG: 'OneTag',
  ONNETWORK: 'Onnetwork',
  ONOMAGIC: 'Onomagic',
  OPENX: 'OpenX',
  OPTAD360: 'OptAd360',
  ORANGE_CLICK_MEDIA: 'Orange Click Media',
  ORTB_KUEEZ: 'oRTB Kueez',
  OTAVAMEDIA: 'Otavamedia',
  OUTBRAIN: 'Outbrain',
  OZONE: 'Ozone',
  PAZAR3: 'Pazar3',
  PERFORMAX: 'Performax',
  PET_MEDIA_GROUP: 'Pet Media Group',
  PETIT_PRESS: 'Petit Press',
  PHAISTOS_NETWORK: 'Phaistos Network',
  PHILIPS: 'Philips',
  PIEMME: 'Piemme',
  PIGEOON: 'Pigeoon',
  PIXAD: 'Pixad',
  PMPG: 'PMPG',
  PODIUM_NORTH: 'Podium North',
  POLARIS_MEDIA: 'Polaris Media',
  POP_MEDIA: 'Pop Media',
  PORT80: 'Port:80',
  PRAVDA: 'Pravda',
  PREMIO_WEBSYSTEM: 'Premio Websystem',
  PRESS: 'Press',
  PRESSCOMM_TECH: 'PressComm Tech',
  PRESSFIRE: 'Pressfire',
  PRIMA_ONLINE: 'Prima Online',
  PRIME_REAL_TIME: 'Prime Real Time',
  PRIMIS: 'Primis',
  PRISGUIDEN: 'Prisguiden',
  PRISMA_MEDIA: 'Prisma Media',
  PROFF: 'Proff',
  PROGRAMMATIC_PREMIUM_COLLECTION: 'Programmatic Premium Collection',
  PROJECT_ADV: 'Project ADV',
  PROJECT_AGORA: 'Project Agora',
  PROVIZION: 'ProVizion',
  PUBGALAXY: 'PubGalaxy',
  PUBLISHERS: 'Publishers +',
  PUBLY: 'Publy',
  PUBMATIC: 'Pubmatic',
  PUBSTREAM_ADVERTISING: 'PubStream Advertising',
  PUSHFIRE: 'Pushfire',
  QUARTER_MEDIA_SSP: 'Quarter Media SSP',
  R2B2_PREMIUM_ONLINE: 'R2B2 Premium Online',
  RADIO_GROUP: 'Radio Group',
  RAINBERRY: 'Rainberry',
  RAKUTEN_TV: 'Rakuten',
  RANTAPALLO: 'Rantapallo',
  RAPTIVE: 'Raptive',
  RATSIT: 'Ratsit',
  RED_VIEW_MEDIA: 'Red View Media',
  REFINERY_89: 'Refinery 89',
  REFRESHER: 'Refresher',
  REKLAM9: 'Reklam9',
  REKLAMSTORE: 'ReklamStore',
  RHYTHMONE: 'Rhythm One',
  RICH_AUDIENCE_MARKETPLACE: 'Rich Audience Marketplace',
  RINGIER: 'Ringier',
  RISE: 'Rise',
  RND_REDAKTIONSNETZWERK: 'RND RedaktionsNetzwerk',
  ROME2RIO: 'Rome2Rio',
  ROSSEL: 'Rossel',
  ROYA_MEDIA_GROUP: 'Roya Media Group',
  RP_DIGITAL: 'RP Digital',
  RTB_ONE: 'RTB One',
  RTL: 'RTL',
  SAHIBINDEN: 'Sahibinden',
  SALESWORKS: 'Salesworks',
  SANOMA: 'Sanoma',
  SCREEN: 'Screen',
  SCREENONDEMAND: 'ScreenOnDemand',
  SEEDTAG: 'Seedtag',
  SELECT_MEDIA: 'Select Media',
  SEMSEOYMAS: 'SemSeoyMas',
  SETUPAD: 'Setupad',
  SEZNAM: 'Seznam',
  SHARETHROUGH: 'Sharethrough',
  SHOWHEROES: 'ShowHeroes',
  SIGMA_BIS: 'Sigma Bis',
  SILVER_BULLET: 'Silver Bullet',
  SIMPLY_BLACK_MEDIA: 'Simply Black Media',
  SJAELLANDSKE_MEDIER: 'Sjællandske Medier',
  SKY: 'Sky',
  SMAATO: 'Smaato',
  SMARTAD: 'SmartAD',
  SMARTCLIP: 'SmartClip',
  SMARTSTREAM_TV: 'SmartStream TV',
  SMARTYADS: 'SmartyAds',
  SMG: 'SMG',
  SMILE_WANTED: 'Smile Wanted',
  SNIGEL: 'Snigel',
  SOFTONIC: 'Softonic',
  SOLUTION: 'Solution',
  SOVRN: 'Sovrn',
  SPIRIT_MEDIA: 'Spirit Media',
  SPORTSBIBELEN: 'Sportsbibelen',
  SPORTSPORT_BA: 'SportSport BA',
  SPOTXCHANGE: 'SpotXchange',
  SQUID: 'Squid',
  STAILAMEDIA: 'Stailamedia',
  STAMPEN: 'Stampen',
  STARTITUP_GROUP: 'Startitup Group',
  STARTSIDEN: 'Startsiden',
  STEP_NETWORK: 'Step Network',
  STREAMKEY_TV: 'Streamkey TV',
  STROER_SSP: 'Ströer SSP',
  SUBLIME_SKINZ: 'Sublime Skinz',
  SUNMEDIA_TV_SSP: 'Sunmedia TV SSP',
  SYNONYMER: 'Synonymer',
  TABOOLA: 'Taboola',
  TAPPX: 'Tappx',
  TARGETVIDEO: 'TargetVideo',
  TEADS: 'Teads',
  TELEGRAFI: 'Telegrafi',
  THE_ADS: 'The Ads',
  THE_DAILY_EXPRESS: 'The Daily Express',
  THE_DAILY_MIRROR: 'The Daily Mirror',
  THE_GOOD_MEN_PROJECT: 'The Good Men Project',
  THE_INDEPENDENT: 'The Independent',
  THE_MOBILE_AGENCY: 'The Mobile Agency',
  THE_MONEYTIZER: 'The Moneytizer',
  THE_TELEGRAPH: 'The Telegraph',
  THE_WEATHER_CHANNEL: 'The Weather Channel',
  THEMATIC_DIGITAL: 'Thematic Digital',
  THETA_MARKETPLACE: 'Theta Marketplace',
  THETA: 'Theta',
  THREE_THREE_ACROSS: '33Across',
  TIME_FOR_FRIENDS: 'Time For Friends',
  TIME_OUT: 'Time Out',
  TOLOCAL: 'ToLocal',
  TORI: 'Tori',
  TRADE_HOUSE_MEDIA: 'Trade House Media',
  TRADERA: 'Tradera',
  TRAFFECTIVE: 'Traffective',
  TRANSFERMARKT: 'Transfermarkt',
  TRINA_MARKETING: 'Trina Marketing',
  TRIPLELIFT: 'TripleLift',
  TRITABLE: 'Tri-Table',
  TRITON_DIGITAL: 'Triton Digital',
  TRUSTED_MEDIA_BRANDS_INTERNATIONAL: 'Trusted Media Brands International',
  TURKUVAZ: 'Turkuvaz',
  TV_MARKIZA: 'TV Markiza',
  TV_NOVA: 'TV Nova',
  TV2: 'TV2',
  TVGUIDE: 'TVGuide',
  TVN: 'TVN',
  TVP: 'TVP',
  TWO_CIRCLES_ICC: 'Two Circles ICC',
  UNBLOCKIA: 'Unblockia',
  UNIBOTS: 'Unibots',
  UNRULY: 'Unruly',
  UNRULYX: 'UnrulyX',
  VENATUS_MEDIA_LIMITED: 'Venatus Media Limited',
  VIADS_ADVERTISING: 'Viads Advertising',
  VICINITY_MEDIA: 'Vicinity Media',
  VIDAZOO: 'Vidazoo',
  VIDEO_INTELLIGENCE: 'Video Intelligence',
  VIDEONOW: 'Videonow',
  VIDOOMY_SSP: 'Vidoomy SSP',
  VIDVERTO: 'Vidverto',
  VINTED: 'Vinted',
  VIOUSLY: 'Viously',
  VIRALIZE: 'Viralize',
  VISION_THING_DOOH: 'Vision Thing DOOH',
  VK_MEDIA: 'VK Media',
  VLTAVA_LABE_MEDIA: 'Vltava Labe Media',
  WAIPU_TV: 'Waipu TV',
  WAY_TO_GROW: 'Way To Grow',
  WEBADS: 'WebAds',
  WEBEDIA_EXCHANGE: 'Webedia Exchange',
  WEBNOVINY_ISITA: 'Webnoviny',
  WEDOTV: 'Wedotv',
  WETTER: 'Wetter',
  WETTERONLINE: 'WetterOnline',
  WILLHABEN: 'Willhaben',
  WIRTUALNA_MEDIA_S_A: 'Wirtualna Media',
  WONDERUS_OY: 'Wonderus Oy',
  XANDR_MONETIZE_SSP: 'Xandr Monetize SSP',
  XITE: 'Xite',
  XOME: 'XOME',
  XXL_MARKETING_SOLUTION: 'XXL Marketing Solution',
  XYMATIC: 'Xymatic',
  YAHOO_EXCHANGE: 'Yahoo Exchange',
  YEARXERO: 'YearXero',
  YEEBASE_MEDIA: 'Yeebase Media',
  YIELD_BIRD: 'Yield Bird',
  YIELD_ON_DEMAND: 'Yield on Demand',
  YIELD_OPTIMISERS: 'Yield Optimisers',
  YIELDLAB: 'Yieldlab',
  YIELDLOVE: 'Yieldlove',
  YIELDMO: 'Yieldmo',
  YIELDRISER: 'YieldRiser',
  YOBEE: 'Yobee',
  YOC_VIS_X: 'YOC Vis.X',
  YOC: 'YOC',
  ZILLOW: 'Zillow',
  ZOOPLA: 'Zoopla',
  ZOZNAM: 'Zoznam',
  ZPR_MEDIA_GROUP: 'ZPR Media Group',
}


export const adformMediaTypeEnumMapping: Record<number, AdformMediaType> = {
  1: 'NA',
  2: 'ACORIANO_ORIENTAL',
  3: 'ACP',
  4: 'ACROSS_MEDIA',
  5: 'ACUITY',
  6: 'ACUNN',
  7: 'ADACTIVE',
  8: 'ADALLIANCE',
  9: 'ADAPTIVE_MEDIA',
  10: 'ADBREAK_NETWORK',
  11: 'ADCLICK',
  12: 'ADCOLONY',
  13: 'ADDCOMUNICATION',
  14: 'ADDEFEND',
  15: 'ADDOOR',
  16: 'ADEVINTA',
  17: 'ADFORM',
  18: 'ADF_NETWORK',
  19: 'ADGAGE',
  20: 'ADGRAVITY',
  21: 'ADIMPULSION',
  22: 'ADKAORA',
  23: 'ADLUDIO',
  24: 'ADMAN',
  25: 'ADMAX',
  26: 'ADMOB',
  27: 'ADNAMI',
  28: 'ADNBOOST',
  29: 'ADOMIUM',
  30: 'ADPLAY',
  31: 'ADPULSE',
  32: 'ADSLZONE',
  33: 'ADSMOVIL',
  34: 'ADSOCY',
  35: 'ADSQUARE',
  36: 'ADS_INTERACTIVE',
  37: 'ADTOPMEDIA',
  38: 'ADVANCE_MEDIA',
  39: 'ADVICEME',
  40: 'AFILIACION',
  41: 'AHRESP',
  42: 'AIRPUSH',
  43: 'AKCE',
  44: 'AKLAMIO',
  45: 'ALPHATV',
  46: 'ALSA',
  47: 'AMADEUS',
  48: 'AMAZON',
  49: 'AMNET',
  50: 'AMPLIFY',
  51: 'ANABEL',
  52: 'ANIMAL_MAKER',
  53: 'ANTENNA',
  54: 'ANTEVENIO',
  55: 'AOL',
  56: 'APPCELERATE',
  57: 'APPLE_ESFERA',
  58: 'ARGJIROLAJM',
  59: 'ARKEERO',
  60: 'ARUKERESO',
  61: 'AS',
  62: 'ATHENSVOICE',
  63: 'ATHINORAMA',
  64: 'ATMEDIA',
  65: 'ATRESMEDIA',
  66: 'ATTICA_MEDIA',
  67: 'AUDEX',
  68: 'AUDIOEMOTION',
  69: 'AUDIOXI',
  70: 'AUDIO_ONE',
  71: 'AUTOCASION',
  72: 'AUTOVIT',
  73: 'AVD',
  74: 'AVRAGEMEDIA',
  75: 'AWIN',
  76: 'AXEL_SPRINGER',
  77: 'AXSP',
  78: 'AZERION',
  79: 'A_BOLA',
  80: 'BAUER',
  81: 'BAUVERLAG',
  82: 'BEALION',
  83: 'BEINSPORTS',
  84: 'BEINTOO',
  85: 'BEYUP',
  86: 'BIDMEDIA',
  87: 'BILD',
  88: 'BING',
  89: 'BITPORT',
  90: 'BLACKC',
  91: 'BLAZEMEDIA',
  92: 'BLISS',
  93: 'BLIS_MEDIA',
  94: 'BLITZ',
  95: 'BLUEMEDIA',
  96: 'BOND_DIJITAL',
  97: 'BRAND_AGE',
  98: 'BREAKEVEN',
  99: 'BRIEFING',
  100: 'BTC_ECHO_ONLINE',
  101: 'BUCATARAS',
  102: 'BUNDLE',
  103: 'BURDA',
  104: 'BUSINESSMAG',
  105: 'BUSINESSTARGET',
  106: 'BUSINESS_AD',
  107: 'BUSINESS_INSIDER',
  108: 'BUSINESS_TECH',
  109: 'CAPETOWNETC',
  110: 'CAPITAL',
  111: 'CAPTIFY',
  112: 'CENTRAL_MEDIA_NETWORK',
  113: 'CIAOPEOPLE',
  114: 'CIMRI',
  115: 'CINCO_DIAS',
  116: 'CINEMANIA',
  117: 'CINER',
  118: 'CITYNEWS',
  119: 'CLASS',
  120: 'CLICKPOINT',
  121: 'CLICKPROFIT',
  122: 'CLUB_INFLUENCERS',
  123: 'CMSALES',
  124: 'CNA',
  125: 'CNN',
  126: 'COGNITIVE',
  127: 'COMCAST',
  128: 'COMMS_DEALER_10',
  129: 'COMPARASEMPLICE',
  130: 'COMPUTERHOY',
  131: 'CONDENAST',
  132: 'CONNECTEDSTORIES',
  133: 'CONNECTEDTV',
  134: 'CONTENTS',
  135: 'CONVERGE',
  136: 'CPM_MEDIOS',
  137: 'CREAMOS_PUBLICIDAD',
  138: 'CRITEO',
  139: 'CTAWEB',
  140: 'CUDODIGITAL',
  141: 'CUMHURIYET',
  142: 'CZECH_NEWS_CENTER',
  143: 'DAILYHUNT',
  144: 'DAILYMOTION',
  145: 'DAILYPOST',
  146: 'DAILY_MAIL',
  147: 'DATAWAD',
  148: 'DATMEAN',
  149: 'DAX',
  150: 'DBSHAPER',
  151: 'DEEZER',
  152: 'DELIVERY_MEDIA',
  153: 'DEMIROREN',
  154: 'DEMOPTIMAZER',
  155: 'DENTSU_TECHSERVICES_PROGRAMMATICO',
  156: 'DFV',
  157: 'DIARIO_NOTICIAS',
  158: 'DIGITAL360',
  159: 'DIGITALLIFE',
  160: 'DIGITALMAXAUDIO',
  161: 'DIGITALVENTURE',
  162: 'DIGITAL_FIRE',
  163: 'DINHEIRO_VIVO',
  164: 'DIRECTINTELLIGENCE',
  165: 'DISCOVERY',
  166: 'DISNEY',
  167: 'DISPLAYNOW',
  168: 'DISTRIBUICAO_HOJE',
  169: 'DIVADLA',
  170: 'DMBIT',
  171: 'DMG',
  172: 'DNU_MAFRA',
  173: 'DOGUS',
  174: 'DOUBLECLICK_DBM',
  175: 'DOVECONVIENE',
  176: 'DRITARE',
  177: 'DUNYA',
  178: 'DV360',
  179: 'DWORKS',
  180: 'EBAY',
  181: 'EBUSINESSCONSULTING',
  182: 'ECO',
  183: 'ECONOMIA',
  184: 'ECONOMIA_DIGITAL',
  185: 'EDISCOM',
  186: 'EDS',
  187: 'EIKON',
  188: 'ELEADS',
  189: 'ELEFTHERIA',
  190: 'ELTIEMPO',
  191: 'EL_BURLADERO',
  192: 'EL_CONFIDENCIAL',
  193: 'EL_DEBATE',
  194: 'EL_DESMARQUE',
  195: 'EL_ECONOMISTA',
  196: 'EL_ESPANOL',
  197: 'EL_INDEPENDIENTE',
  198: 'EL_MUNDO',
  199: 'EL_PAIS',
  200: 'EMOTIKA',
  201: 'ENGAGEYA',
  202: 'ENICON',
  203: 'EPEY',
  204: 'EPI',
  205: 'EPIXEIRO',
  206: 'EPROFESSIONAL',
  207: 'ERADIO',
  208: 'ERETAIL',
  209: 'ESCUDO_DIGITAL',
  210: 'ESPINOFF',
  211: 'ESTADIO_DEPORTIVO',
  212: 'ESTATE_LIVING',
  213: 'ETAILMENT',
  214: 'EURO2DAY',
  215: 'EURONEWS',
  216: 'EVOLUTIONADV',
  217: 'EVOMEDIA',
  218: 'EXAME_INFORMATICA',
  219: 'EXECUTIVE_DIGEST',
  220: 'EXITBANNER',
  221: 'EXITBEE',
  222: 'EXPANSION',
  223: 'EXPONENTIAL',
  224: 'EXPRESSO',
  225: 'EXPRESSO_ECONOMIA',
  226: 'EXXEN',
  227: 'FACEBOOK',
  228: 'FACILE',
  229: 'FACTOR_ELEVEN',
  230: 'FANPLAYR',
  231: 'FILATHLOS',
  232: 'FILMTORO',
  233: 'FINTONIC',
  234: 'FIRSTONLINE',
  235: 'FISIOZONE',
  236: 'FLIX',
  237: 'FLUIDDO_REDE_TRENDY',
  238: 'FORBES',
  239: 'FOTOCASA',
  240: 'FOUR_OD',
  241: 'FOUR_W',
  242: 'FOXTV',
  243: 'FREEDA',
  244: 'FREEVEE',
  245: 'FREEWHEEL',
  246: 'FRIENDZ',
  247: 'FURGRUNDER',
  248: 'FUTURE',
  249: 'GAMELOFT',
  250: 'GATE2000',
  251: 'GAZETATEMA',
  252: 'GAZZETTA',
  253: 'GEARBURN',
  254: 'GEKO',
  255: 'GEZIMANYA',
  256: 'GIGA',
  257: 'GLOBAL_MEDIA_GROUP',
  258: 'GMAIL',
  259: 'GOODLOOP',
  260: 'GOOGLE_ADS',
  261: 'GOSSIP_TV',
  262: 'GRANDE_CONSUMO',
  263: 'GROUPM',
  264: 'GRUPO_GODO',
  265: 'GRUPO_ZETA',
  266: 'GSH',
  267: 'GSP',
  268: 'GUJ_EMS',
  269: 'GUMGUM',
  270: 'GZT',
  271: 'HDBLOG',
  272: 'HDOPEN',
  273: 'HEARST',
  274: 'HEATWORLD',
  275: 'HEJ',
  276: 'HENNEO',
  277: 'HIBURY_MEDIA',
  278: 'HICMOBILE',
  279: 'HIMEDIA',
  280: 'HIPERSUPER',
  281: 'HISPANIDAD',
  282: 'HITS_SITE_1',
  283: 'HOBBY_CONSOLAS',
  284: 'HOLA',
  285: 'HOOPLA',
  286: 'HOPI',
  287: 'HORIZON',
  288: 'HTTPOOL',
  289: 'HTXT_CO',
  290: 'HURRIYET',
  291: 'HVG',
  292: 'ICTNSTRATEGY',
  293: 'IEFIMERIDA',
  294: 'IGPDECAUX',
  295: 'IKUB',
  296: 'ILAB',
  297: 'IMDB',
  298: 'IMPRESA',
  299: 'IMPRESACITY',
  300: 'IMPRESSION_MEDIA',
  301: 'IN',
  302: 'INBOX_INSIGHTS',
  303: 'INDAMEDIA',
  304: 'INDUSTRIA_E_AMBIENTE',
  305: 'INFINETY',
  306: 'INFINIA',
  307: 'INFLUENT',
  308: 'INGATLAN',
  309: 'INLAB',
  310: 'INNO3',
  311: 'INSIDER',
  312: 'INSKIN',
  313: 'INSTAGRAM',
  314: 'INTERNET_INFO',
  315: 'INTO',
  316: 'INTV',
  317: 'INVIBES',
  318: 'IOL',
  319: 'IQ_DIGITAL',
  320: 'IRISH_SUN_ONLINE',
  321: 'IRISH_TIMES',
  322: 'ISIC',
  323: 'ITALIAATAVOLA',
  324: 'ITV',
  325: 'IVOOX',
  326: 'JAGUAR',
  327: 'JAKALA',
  328: 'JENNY',
  329: 'JIVOX',
  330: 'JOFOGAS',
  331: 'JORNAL_DENTISTRY',
  332: 'JORNAL_NOTICIAS',
  333: 'JUSTWATCH',
  334: 'JUST_PREMIUM',
  335: 'KANAL_D',
  336: 'KARIYER_NET',
  337: 'KARNAVAL',
  338: 'KATHIMERINI',
  339: 'KETCHUP',
  340: 'KICKBASE',
  341: 'KIJIJI',
  342: 'KISS',
  343: 'KIZLAR_SORUYOR',
  344: 'KOMPARATORE',
  345: 'KUANTO_KUSTA',
  346: 'KWANKO',
  347: 'LADBIBLE',
  348: 'LAMESCOLANZA',
  349: 'LAOSNEWS',
  350: 'LAPSI',
  351: 'LATRIBUNADEALBACETE',
  352: 'LATTIMOFUGGENTEEDITORE',
  353: 'LA_INFORMACION',
  354: 'LA_VANGUARDIA',
  355: 'LA_VOZ_GALICIA',
  356: 'LETGO',
  357: 'LIBERAL',
  358: 'LIBERO',
  359: 'LIFO',
  360: 'LIGATUS',
  361: 'LIGHT_REACTION',
  362: 'LINKAPPEAL',
  363: 'LINKEDIN',
  364: 'LOCALA',
  365: 'LOGAN',
  366: 'LOGISTICA_Y_TRANSPORTE',
  367: 'LUCKYADV',
  368: 'MACKOLIK',
  369: 'MADINAD',
  370: 'MAFRA',
  371: 'MAGGIOLI',
  372: 'MAJORDEM',
  373: 'MAKROO',
  374: 'MANZONI',
  375: 'MARCA',
  376: 'MARKETEER',
  377: 'MARKETING_TURKIYE',
  378: 'MAZONI',
  379: 'MEDIACAT',
  380: 'MEDIAMOB',
  381: 'MEDIAMOND',
  382: 'MEDIAREY',
  383: 'MEDIASERVICES',
  384: 'MEDIASET',
  385: 'MEDIAWORKS',
  386: 'MEDIA_CAPITAL_DIGITAL',
  387: 'MEDIA_IMPACT',
  388: 'MEDIA_IQ',
  389: 'MEDYANET',
  390: 'MEGATV',
  391: 'MEIOS_E_PUBLICIDADE',
  392: 'META',
  393: 'METADIG',
  394: 'METEO',
  395: 'METEONOK',
  396: 'MIAMOBILE',
  397: 'MICROSOFT_ADCENTER',
  398: 'MICROSOFT_BING',
  399: 'MITELE',
  400: 'MLADA_FRONTA',
  401: 'MOBSTA',
  402: 'MONDOMOBILE',
  403: 'MONONEWS',
  404: 'MOOVIT',
  405: 'MOTORPRESS_IBERICA',
  406: 'MOTOS_NET_Y_MIL_ANUNCIOS_MOTOS',
  407: 'MOVINGUP',
  408: 'MSAN',
  409: 'MSN',
  410: 'MUYPYM',
  411: 'MYBEST',
  412: 'MYBROADBAND_CO',
  413: 'MYNET',
  414: 'NAFTEMPORIKI',
  415: 'NANO_INTERACTIVE',
  416: 'NATIVHIRDETES',
  417: 'NBC_UNIVERSAL',
  418: 'NEJBUSINESS',
  419: 'NEOLAIA',
  420: 'NEOPOLIS',
  421: 'NETADDICTION',
  422: 'NETCOMMEDYA',
  423: 'NETCONSULTINGCUBE',
  424: 'NETFLIX',
  425: 'NETMEDIA',
  426: 'NETMEDIACLICK',
  427: 'NEWBUSINESSMEDIA',
  428: 'NEWPOST',
  429: 'NEWS24',
  430: 'NEWS247',
  431: 'NEWSBEAST',
  432: 'NEWSBOMB',
  433: 'NEWSIT',
  434: 'NEWSLETTER',
  435: 'NEWSONLINE',
  436: 'NEWSROOM',
  437: 'NEWSUK',
  438: 'NEXTDOOR',
  439: 'NIT',
  440: 'NOA',
  441: 'NOKTA',
  442: 'NORBERTOGALLEGO',
  443: 'NOTICIAS_AO_MINUTO',
  444: 'NWMGROUP',
  445: 'OATH',
  446: 'OBSERVADOR',
  447: 'OCTAIVE',
  448: 'OGURY',
  449: 'OLX',
  450: 'ONEDIO',
  451: 'ONEFOOTBALL',
  452: 'ONE_BRAND_STUDIO',
  453: 'ONE_FIVE_COUNTRIES',
  454: 'ONLARISSA',
  455: 'ONPAGE',
  456: 'OPENIT',
  457: 'OPINION',
  458: 'OT',
  459: 'OTHER',
  460: 'OUTBRAIN',
  461: 'OUTLOOK',
  462: 'O_JOGO',
  463: 'PACKED_HOUSE',
  464: 'PANORAMA',
  465: 'PANORAMA_SPORT',
  466: 'PARTNERS_MEDIA',
  467: 'PATRISNEWS',
  468: 'PATRONLAR_DUNYASI',
  469: 'PAYCLICK',
  470: 'PBU',
  471: 'PERFORM',
  472: 'PERSADO',
  473: 'PESADOS_MERCADORIAS',
  474: 'PI',
  475: 'PIEMME',
  476: 'PINTEREST',
  477: 'PLATFORM',
  478: 'PMAX',
  479: 'POLIHUB',
  480: 'POLIMI',
  481: 'POLITIKO',
  482: 'POPADVERTISING',
  483: 'POPAGANDA',
  484: 'PREMIUM_PUBLISHERS_NETWORK',
  485: 'PRENSA_IBERICA',
  486: 'PRIME',
  487: 'PRIMEDIA',
  488: 'PRISA',
  489: 'PROGRAMATIK',
  490: 'PROHARDVER',
  491: 'PROJECT_AGORA',
  492: 'PROMOQUI',
  493: 'PROPERTY24',
  494: 'PROTOTHEMA',
  495: 'PROTV',
  496: 'PROVOCATEUR',
  497: 'PUBLICO',
  498: 'PUBLIMEDIA',
  499: 'PUBLITURIS',
  500: 'PUSHNOTIFICATION',
  501: 'PUSHPUSHGO',
  502: 'PYMES_Y_AUTONOMOS',
  503: 'QRCODE',
  504: 'QRIOUSLY',
  505: 'QUALIFY',
  506: 'QUANTCAST',
  507: 'QUANTUM',
  508: 'RAI',
  509: 'RAIPLAY',
  510: 'RAIPUBBLICITA',
  511: 'RCS',
  512: 'RDS',
  513: 'REACH',
  514: 'READER',
  515: 'REAL',
  516: 'REAL_TIME_BIDDING',
  517: 'REBOLD',
  518: 'RECOGNIFIED',
  519: 'RECORD',
  520: 'REDDIT',
  521: 'REDE_COFINA',
  522: 'REDE_MCR',
  523: 'REDE_R_MULTIMEDIA',
  524: 'REFINE_DIRECT',
  525: 'REFRESHER',
  526: 'RELEVANT',
  527: 'RETRO_MUSIC_TV',
  528: 'REVISTABORDO',
  529: 'REVISTACLASS',
  530: 'REVISTA_METALICA',
  531: 'REVISTA_WHO',
  532: 'REZONENCE',
  533: 'RICHAUDIENCE',
  534: 'RICONTATTOCHECKOUT',
  535: 'RIGHTMOVE',
  536: 'RINGIER',
  537: 'RISPARMIATU',
  538: 'ROAMS',
  539: 'ROCKETFUEL',
  540: 'RODIAKI',
  541: 'RON_PARENTING_ADS',
  542: 'ROOK_DIGITAL',
  543: 'RTB_HOUSE',
  544: 'RTE',
  545: 'RTIME',
  546: 'RTL',
  547: 'RTP',
  548: 'RTP_ARENA',
  549: 'RUSTICAE',
  550: 'S4M',
  551: 'SABAH',
  552: 'SAHIBINDEN',
  553: 'SALESCONTACT',
  554: 'SALESWORKS',
  555: 'SALLO',
  556: 'SAPO',
  557: 'SAPOS_Y_PRINCESAS',
  558: 'SARUGBYMAG',
  559: 'SCHIBSTED',
  560: 'SCIBIDS',
  561: 'SCREEN_ON_DEMAND',
  562: 'SDNA',
  563: 'SEAT',
  564: 'SEEDTAG',
  565: 'SEGUGIO',
  566: 'SENSACINE',
  567: 'SERRESPARATIRITIS',
  568: 'SEVENONE',
  569: 'SEZNAM',
  570: 'SFERAEDITORE',
  571: 'SHAHID_NET',
  572: 'SHAZAM',
  573: 'SHEKULLI',
  574: 'SHOWHEROES',
  575: 'SHQIPTARJA',
  576: 'SIC',
  577: 'SIC_NOTICIAS',
  578: 'SIKAYET_VAR',
  579: 'SILVERBULLET',
  580: 'SKAI',
  581: 'SKLIK',
  582: 'SKROUTZ',
  583: 'SKY',
  584: 'SMARTADNETWORK',
  585: 'SMARTCLIP',
  586: 'SMARTCONTRACT',
  587: 'SMAU',
  588: 'SMOOT',
  589: 'SMS',
  590: 'SNAPCHAT',
  591: 'SOLVETIC',
  592: 'SOSFE',
  593: 'SOS_TARIFFE',
  594: 'SOUNDCLOUD',
  595: 'SOZCU',
  596: 'SPEWEB',
  597: 'SPINUP',
  598: 'SPORT24',
  599: 'SPORTNETWORK',
  600: 'SPOTIFY',
  601: 'STAR',
  602: 'STARTMAGAZINE',
  603: 'STORYLAB',
  604: 'STROER_DIGITAL_GROUP',
  605: 'STROER_NATIVE_STORY',
  606: 'STROSSLE',
  607: 'STUDENT_ROOM',
  608: 'SUBITO',
  609: 'SUBLIME',
  610: 'SUBLIMESKIN',
  611: 'SUNMEDIA',
  612: 'SUPERBRU',
  613: 'SUPERSPORT',
  614: 'SUPPLY_CHAIN_MAGAZINE',
  615: 'SYRI',
  616: 'SYSTEM24',
  617: 'TABMO',
  618: 'TABOOLA',
  619: 'TABRIS',
  620: 'TAB_MEDIA',
  621: 'TALKSMEDIA',
  622: 'TAPTAP',
  623: 'TEADS',
  624: 'TELEFONIA_Y_COMUNICACIONES',
  625: 'TELEGRAF',
  626: 'TELEGRAPH',
  627: 'TELTARIF',
  628: 'TEST',
  629: 'TGADV',
  630: 'THEJOURNAL_IE',
  631: 'THETOC',
  632: 'THETRADEDESK',
  633: 'THE_CITIZEN',
  634: 'THE_GUARDIAN',
  635: 'THE_INDEPENDENT',
  636: 'THE_MAGGER',
  637: 'THE_OZONE_PROJECT',
  638: 'THE_SUNDAY_TIMES_IRELAND',
  639: 'THE_TIMES',
  640: 'THREE_SIX_FIVE_DIGITAL',
  641: 'THREE_SIX_ZERO_GRADE',
  642: 'TICTOC',
  643: 'TIENDEO',
  644: 'TIKTOK',
  645: 'TIMEOUT',
  646: 'TIRANAPOST',
  647: 'TISCALI',
  648: 'TISOOMI',
  649: 'TOPINFO',
  650: 'TOP_CHANNEL',
  651: 'TOVIMA',
  652: 'TPZ',
  653: 'TRADEDOUBLER',
  654: 'TRADETRACKER',
  655: 'TRENDONLINE',
  656: 'TRIBOO',
  657: 'TRIPLELIFT',
  658: 'TRT',
  659: 'TUEXPERTO',
  660: 'TURKUVAZ',
  661: 'TV8',
  662: 'TVEKSTRA',
  663: 'TV_KLAN',
  664: 'TWITCH',
  665: 'TWITTER',
  666: 'TWO_ZERO_MINUTOS',
  667: 'ULUDAG_SOZLUK',
  668: 'UNEDISA',
  669: 'UNGDC',
  670: 'UNIDAD_EDITORIAL',
  671: 'UNIVERSALSKIN',
  672: 'UPTIMIZE',
  673: 'VALASZONLINE',
  674: 'VALICA',
  675: 'VALUEDEM',
  676: 'VANMASIK',
  677: 'VDX',
  678: 'VEESIBLE',
  679: 'VELOXITY',
  680: 'VERIZON',
  681: 'VERTISMEDIA',
  682: 'VIDEOBEET',
  683: 'VIDOOMY',
  684: 'VINTED',
  685: 'VIRALIZE',
  686: 'VIRGIN_MEDIA',
  687: 'VISABLE',
  688: 'VISIBILIA',
  689: 'VISITLIFT',
  690: 'VITASOCIETAEDITORIALESPA',
  691: 'VIU',
  692: 'VLTAVA_LABE_MEDIA',
  693: 'VOCENTO',
  694: 'VODAFONE',
  695: 'VOICEUP',
  696: 'VOXIFY',
  697: 'VOZ_POPULI',
  698: 'WALLAPOP',
  699: 'WALLSTREET',
  700: 'WAVEMAKER',
  701: 'WAZE',
  702: 'WEBADS',
  703: 'WEBBDONE',
  704: 'WEBEDIA',
  705: 'WEBLOGS',
  706: 'WEBORAMA',
  707: 'WEBPERFORMANCE',
  708: 'WEBSTARCHANNEL',
  709: 'WEBSYSTEM',
  710: 'WEBTEKNO',
  711: 'WECHAT',
  712: 'WELT',
  713: 'WEMASS',
  714: 'WETRANSFER',
  715: 'WIDESPACE',
  716: 'WINK',
  717: 'WIRED',
  718: 'WMN',
  719: 'WUOLAH',
  720: 'XATAKA',
  721: 'XAXIS',
  722: 'XIMOBI',
  723: 'YAHOO',
  724: 'YDIGITAL',
  725: 'YENISAFAK',
  726: 'YOC',
  727: 'YOUTHALL',
  728: 'YOUTUBE',
  729: 'ZANOX',
  730: 'ZEMANTA',
  731: 'ZERO_ONE_FOUR',
  732: 'ZERO_ONE_NET',
  733: 'ZERO_ZERO',
  734: 'ZETA',
  735: 'ZIARE',
  736: 'ZIFF_DAVIS',
  737: 'ZOOCOM',
  738: 'ZOOPLA',
  739: 'ZOUGLA',
  740: 'AMZ_ISP',
  741: 'BUSINESS_MEDIA',
  742: 'EXTE',
  743: 'G4MEDIA',
  744: 'SECURITY',
  745: 'SOCIAL_PUBLI',
  746: 'TURN_DIGITAL',
  747: 'SOVENDUS',
  748: 'DESTINIA',
  749: 'ONEMAN',
  750: 'GRUPO_INFORMATICO',
  751: 'UBER',
  752: 'ASKANEWS',
  753: 'JORNAL_NEGOCIOS',
  754: 'COLLEGE_TIMES',
  755: 'PLAYTECH',
  756: 'MAIS_FUTEBOL',
  757: 'ADOT',
  758: 'EVZ',
  759: 'HEPSIBURADA',
  760: 'ZF',
  761: 'CAPTURE_MEDIA',
  762: 'VERSURI',
  763: 'ANTENNA_HYBRID_TV',
  764: 'CZECHCRUNCH',
  765: 'KUDIKA',
  766: 'YO_SOY_NOTICIA',
  767: 'ACAST',
  768: 'ESPN',
  769: 'KICKER',
  770: 'LUBEN_TV',
  771: 'ADDIG_DIGITAL',
  772: 'ATTIKES_EKDOSEIS',
  773: 'REBEL_REBEL',
  774: 'RONIN',
  775: 'INTERNET_CORP',
  776: 'NSO',
  777: 'IGRAAL',
  778: 'MEGA_HYBRID_TV',
  779: 'REETMO',
  780: 'KEFALONIANMANTATA',
  781: 'PPH',
  782: 'SFATULPARINTILOR',
  783: 'PROFIT',
  784: 'MARIECLAIRE',
  785: 'QUEEN',
  786: 'VOGUE',
  787: 'MIMEDA',
  788: 'VIBER',
  789: 'MAINADV',
  790: 'ALPHA_HYBRID_TV',
  791: 'ONE_ZERO_AND5',
  792: 'APLAUSOS',
  793: 'CULTORO',
  794: 'MUNDOTORO',
  795: 'HPEIROS',
  796: 'NEWGEN',
  797: 'MVLSZ',
  798: 'SMARTSTREAM',
  799: 'GTS_ALIVE',
  800: 'HWSW',
  801: 'MASFELFLEKK',
  802: 'SERTHARROS',
  803: 'NOOZ',
  804: 'EPTOLEMEOS',
  805: 'INFOKIDS',
  806: 'PATRIS',
  807: 'THESSALIANEWS',
  808: 'XTYPOS',
  809: 'TRIKALAOLA',
  810: 'TWO_FOUR_MEDIA',
  811: 'ISHOW',
  812: 'GNOMIP',
  813: 'PROININEWS',
  814: 'CRILUGE',
  815: 'FLUIDDO_REDE_SURF',
  816: 'EMPROS',
  817: 'LAKONIKOS',
  818: 'MAGNESIANEWS',
  819: 'OLYMPIOBIMA',
  820: 'POLITISCHIOS',
  821: 'TAXYDROMOS',
  822: 'ETHESSALIA',
  823: 'EMPROSNET',
  824: 'XRONOS',
  825: 'CHARGE_ADS',
  826: 'REPUBLICA',
  827: 'VICINITY_MEDIA',
  828: 'DAZN',
  829: 'JOYN',
  830: 'LIBERTATEA',
  831: 'MONITOR',
  832: 'DETONATOR',
  833: 'OGGUSTO',
  834: 'DEMANDBASE',
  835: 'MEDIA_ALLIANCE',
  836: 'MOON',
  837: 'YODA',
  838: 'A1',
  839: 'DIKAIOLOGITIKA',
  840: 'PLISTA',
  841: 'ADLOOK',
  842: 'KAYAK',
  843: 'MIGLIORTARIFFA',
  844: 'EMPRENDEDORES',
  845: 'SAMSUNGADS',
  846: 'UNKNOWN',
}

export const adformMediaTypeDisplayLabelMapping: Record<AdformMediaType, string> = {
  A_BOLA: 'A Bola',
  A1: 'A1',
  ACAST: 'Acast',
  ACORIANO_ORIENTAL: 'Acoriano Oriental',
  ACP: 'ACP',
  ACROSS_MEDIA: 'Across Media',
  ACUITY: 'Acuity',
  ACUNN: 'Acunn',
  ADACTIVE: 'Adactive',
  ADALLIANCE: 'AdAlliance',
  ADAPTIVE_MEDIA: 'Adaptive Media',
  ADBREAK_NETWORK: 'Adbreak Network',
  ADCLICK: 'Adclick',
  ADCOLONY: 'AdColony',
  ADDCOMUNICATION: 'AddComunication',
  ADDEFEND: 'AdDefend',
  ADDIG_DIGITAL: 'Addig Digital',
  ADDOOR: 'AdDoor',
  ADEVINTA: 'Adevinta',
  ADF_NETWORK: 'ADF Network',
  ADFORM: 'Adform',
  ADGAGE: 'Adgage',
  ADGRAVITY: 'AdGravity',
  ADIMPULSION: 'AdImpulsion',
  ADKAORA: 'Adkaora',
  ADLOOK: 'Adlook',
  ADLUDIO: 'Adludio',
  ADMAN: 'Adman',
  ADMAX: 'Admax',
  ADMOB: 'Admob',
  ADNAMI: 'Adnami',
  ADNBOOST: 'AdNBoost',
  ADOMIUM: 'Adomium',
  ADOT: 'Adot',
  ADPLAY: 'Adplay',
  ADPULSE: 'Adpulse',
  ADS_INTERACTIVE: 'Ads Interactive',
  ADSLZONE: 'ADSLZone',
  ADSMOVIL: 'Adsmovil',
  ADSOCY: 'Adsocy',
  ADSQUARE: 'Adsquare',
  ADTOPMEDIA: 'AdtopMedia',
  ADVANCE_MEDIA: 'Advance Media',
  ADVICEME: 'Adviceme',
  AFILIACION: 'Afiliacion',
  AHRESP: 'Ahresp',
  AIRPUSH: 'Airpush',
  AKCE: 'AKCE',
  AKLAMIO: 'Aklamio',
  ALPHA_HYBRID_TV: 'Alpha Hybrid TV',
  ALPHATV: 'AlphaTV',
  ALSA: 'Alsa',
  AMADEUS: 'Amadeus',
  AMAZON: 'Amazon',
  AMNET: 'Amnet',
  AMPLIFY: 'Amplify',
  AMZ_ISP: 'AMZ ISP',
  ANABEL: 'Anabel',
  ANIMAL_MAKER: 'Animal Maker',
  ANTENNA_HYBRID_TV: 'Antenna Hybrid TV',
  ANTENNA: 'Antenna',
  ANTEVENIO: 'Antevenio',
  AOL: 'AOL',
  APLAUSOS: 'Aplausos',
  APPCELERATE: 'Appcelerate',
  APPLE_ESFERA: 'Apple Esfera',
  ARGJIROLAJM: 'Argjiro',
  ARKEERO: 'Arkeero',
  ARUKERESO: 'Arukereso',
  AS: 'AS',
  ASKANEWS: 'AskaNews',
  ATHENSVOICE: 'Athensvoice',
  ATHINORAMA: 'Athinorama',
  ATMEDIA: 'Atmedia',
  ATRESMEDIA: 'Atres Media',
  ATTICA_MEDIA: 'Attica Media',
  ATTIKES_EKDOSEIS: 'Attikes Ekdoseis',
  AUDEX: 'Audex',
  AUDIO_ONE: 'Audio One',
  AUDIOEMOTION: 'AudioEmotion',
  AUDIOXI: 'Audioxi',
  AUTOCASION: 'Autocasion',
  AUTOVIT: 'Autovit',
  AVD: 'AVD',
  AVRAGEMEDIA: 'Avrage Media',
  AWIN: 'Awin',
  AXEL_SPRINGER: 'Axel Springer',
  AXSP: 'AXSP',
  AZERION: 'Azerion',
  BAUER: 'Bauer',
  BAUVERLAG: 'Bauverlag',
  BEALION: 'Bealion',
  BEINSPORTS: 'BeInSports',
  BEINTOO: 'Beintoo',
  BEYUP: 'Beyup',
  BIDMEDIA: 'Bidmedia',
  BILD: 'Bild',
  BING: 'Bing',
  BITPORT: 'Bitport',
  BLACKC: 'Blackc',
  BLAZEMEDIA: 'Blaze Media',
  BLIS_MEDIA: 'Blis Media',
  BLISS: 'Bliss',
  BLITZ: 'Blitz',
  BLUEMEDIA: 'Bluemedia',
  BOND_DIJITAL: 'Bond Dijital',
  BRAND_AGE: 'Brand Age',
  BREAKEVEN: 'Breakeven',
  BRIEFING: 'Briefing',
  BTC_ECHO_ONLINE: 'BTC Echo Online',
  BUCATARAS: 'Bucataras',
  BUNDLE: 'Bundle',
  BURDA: 'Burda',
  BUSINESS_AD: 'Business Ad',
  BUSINESS_INSIDER: 'Business Insider',
  BUSINESS_MEDIA: 'Business Media',
  BUSINESS_TECH: 'Business Tech',
  BUSINESSMAG: 'Business Mag',
  BUSINESSTARGET: 'Business Target',
  CAPETOWNETC: 'CapeTownETC',
  CAPITAL: 'Capital',
  CAPTIFY: 'Captify',
  CAPTURE_MEDIA: 'Capture Media',
  CENTRAL_MEDIA_NETWORK: 'Central Media Network',
  CHARGE_ADS: 'Charge Ads',
  CIAOPEOPLE: 'CiaoPeople',
  CIMRI: 'Cimri',
  CINCO_DIAS: 'Cinco Dias',
  CINEMANIA: 'Cinemania',
  CINER: 'Ciner',
  CITYNEWS: 'Citynews',
  CLASS: 'Class',
  CLICKPOINT: 'Clickpoint',
  CLICKPROFIT: 'Clickprofit',
  CLUB_INFLUENCERS: 'Club Influencers',
  CMSALES: 'CM Sales',
  CNA: 'CNA',
  CNN: 'CNN',
  COGNITIVE: 'Cognitive',
  COLLEGE_TIMES: 'College Times',
  COMCAST: 'Comcast',
  COMMS_DEALER_10: 'Comms Dealer 10',
  COMPARASEMPLICE: 'Com Para Semplice',
  COMPUTERHOY: 'ComputerHoy',
  CONDENAST: 'Conde Nast',
  CONNECTEDSTORIES: 'Connected Stories',
  CONNECTEDTV: 'ConnectedTV',
  CONTENTS: 'Contents',
  CONVERGE: 'Converge',
  CPM_MEDIOS: 'CPM Medios',
  CREAMOS_PUBLICIDAD: 'Creamos Publicidad',
  CRILUGE: 'Criluge',
  CRITEO: 'Criteo',
  CTAWEB: 'CTAWeb',
  CUDODIGITAL: 'Cudo Digital',
  CULTORO: 'Cultoro',
  CUMHURIYET: 'Cumhuriyet',
  CZECH_NEWS_CENTER: 'Czech News Center',
  CZECHCRUNCH: 'CzechCrunch',
  DAILY_MAIL: 'Daily Mail',
  DAILYHUNT: 'Dailyhunt',
  DAILYMOTION: 'Dailymotion',
  DAILYPOST: 'Dailypost',
  DATAWAD: 'Datawad',
  DATMEAN: 'Datmean',
  DAX: 'Dax',
  DAZN: 'DAZN',
  DBSHAPER: 'DBShaper',
  DEEZER: 'Deezer',
  DELIVERY_MEDIA: 'Delivery Media',
  DEMANDBASE: 'DemandBase',
  DEMIROREN: 'Demiroren',
  DEMOPTIMAZER: 'Dem Optimazer',
  DENTSU_TECHSERVICES_PROGRAMMATICO: 'Dentsu Techservices Programmatico',
  DESTINIA: 'Destinia',
  DETONATOR: 'Detonator',
  DFV: 'DFV',
  DIARIO_NOTICIAS: 'Diario Noticias',
  DIGITAL_FIRE: 'Digital Fire',
  DIGITAL360: 'Digital360',
  DIGITALLIFE: 'DigitalLife',
  DIGITALMAXAUDIO: 'DigitalMaxAudio',
  DIGITALVENTURE: 'DigitalVenture',
  DIKAIOLOGITIKA: 'Dikaiologitika',
  DINHEIRO_VIVO: 'Dinheiro Vivo',
  DIRECTINTELLIGENCE: 'DirectIntelligence',
  DISCOVERY: 'Discovery',
  DISNEY: 'Disney',
  DISPLAYNOW: 'DisplayNow',
  DISTRIBUICAO_HOJE: 'Distribuicao Hoje',
  DIVADLA: 'Divadla',
  DMBIT: 'DMBit',
  DMG: 'DMG',
  DNU_MAFRA: 'DNU Mafra',
  DOGUS: 'Dogus',
  DOUBLECLICK_DBM: 'DoubleClick DBM',
  DOVECONVIENE: 'Dov e Conviene',
  DRITARE: 'Dritare',
  DUNYA: 'Dunya',
  DV360: 'DV360',
  DWORKS: 'Dworks',
  EBAY: 'eBay',
  EBUSINESSCONSULTING: 'E-Business Consulting',
  ECO: 'Eco',
  ECONOMIA_DIGITAL: 'Economia Digital',
  ECONOMIA: 'Economia',
  EDISCOM: 'Ediscom',
  EDS: 'EDS',
  EIKON: 'Eikon',
  EL_BURLADERO: 'El Burladero',
  EL_CONFIDENCIAL: 'El Confidencial',
  EL_DEBATE: 'El Debate',
  EL_DESMARQUE: 'El Desmarque',
  EL_ECONOMISTA: 'El Economista',
  EL_ESPANOL: 'El Espanol',
  EL_INDEPENDIENTE: 'El Independiente',
  EL_MUNDO: 'El Mundo',
  EL_PAIS: 'El Pais',
  ELEADS: 'Eleads',
  ELEFTHERIA: 'Eleftheria',
  ELTIEMPO: 'El Tiempo',
  EMOTIKA: 'Emotika',
  EMPRENDEDORES: 'Emprendedores',
  EMPROS: 'Empros',
  EMPROSNET: 'Emprosnet',
  ENGAGEYA: 'Engageya',
  ENICON: 'Enicon',
  EPEY: 'Epey',
  EPI: 'EPI',
  EPIXEIRO: 'Epixeiro',
  EPROFESSIONAL: 'Eprofessional',
  EPTOLEMEOS: 'Eptolemeos',
  ERADIO: 'Eradio',
  ERETAIL: 'E-retail',
  ESCUDO_DIGITAL: 'Escudo Digital',
  ESPINOFF: 'Espinoff',
  ESPN: 'ESPN',
  ESTADIO_DEPORTIVO: 'Estadio Deportivo',
  ESTATE_LIVING: 'Estate Living',
  ETAILMENT: 'Etailment',
  ETHESSALIA: 'E-thessalia',
  EURO2DAY: 'Euro2Day',
  EURONEWS: 'EuroNews',
  EVOLUTIONADV: 'Evolution ADV',
  EVOMEDIA: 'Evo Media',
  EVZ: 'EVZ',
  EXAME_INFORMATICA: 'Exame Informatica',
  EXECUTIVE_DIGEST: 'Executive Digest',
  EXITBANNER: 'ExitBanner',
  EXITBEE: 'Exitbee',
  EXPANSION: 'Expansion',
  EXPONENTIAL: 'Exponential',
  EXPRESSO_ECONOMIA: 'Expresso Economia',
  EXPRESSO: 'Expresso',
  EXTE: 'Exte',
  EXXEN: 'Exxen',
  FACEBOOK: 'Facebook',
  FACILE: 'Facile',
  FACTOR_ELEVEN: 'Factor 11',
  FANPLAYR: 'Fanplayr',
  FILATHLOS: 'Filathlos',
  FILMTORO: 'Filmtoro',
  FINTONIC: 'Fintonic',
  FIRSTONLINE: 'FirstOnline',
  FISIOZONE: 'Fisiozone',
  FLIX: 'Flix',
  FLUIDDO_REDE_SURF: 'Fluiddo Rede Surf',
  FLUIDDO_REDE_TRENDY: 'Fluiddo Rede Trendy',
  FORBES: 'Forbes',
  FOTOCASA: 'Fotocasa',
  FOUR_OD: '4OD',
  FOUR_W: '4W',
  FOXTV: 'FoxTV',
  FREEDA: 'Freeda',
  FREEVEE: 'Freevee',
  FREEWHEEL: 'Freewheel',
  FRIENDZ: 'Friendz',
  FURGRUNDER: 'Furgrunder',
  FUTURE: 'Future',
  G4MEDIA: 'G4Media',
  GAMELOFT: 'Gameloft',
  GATE2000: 'Gate2000',
  GAZETATEMA: 'Gazeta Tema',
  GAZZETTA: 'Gazzetta',
  GEARBURN: 'Gearburn',
  GEKO: 'Geko',
  GEZIMANYA: 'Gezimanya',
  GIGA: 'Giga',
  GLOBAL_MEDIA_GROUP: 'Global Media Group',
  GMAIL: 'Gmail',
  GNOMIP: 'Gnomip',
  GOODLOOP: 'Goodloop',
  GOOGLE_ADS: 'Google Ads',
  GOSSIP_TV: 'Gossip TV',
  GRANDE_CONSUMO: 'Grande Consumo',
  GROUPM: 'GroupM',
  GRUPO_GODO: 'Grupo Godo',
  GRUPO_INFORMATICO: 'Grupo Informatico',
  GRUPO_ZETA: 'Grupo Zeta',
  GSH: 'GSH',
  GSP: 'GSP',
  GTS_ALIVE: 'GTS Alive',
  GUJ_EMS: 'Guj EMS',
  GUMGUM: 'GumGum',
  GZT: 'GZT',
  HDBLOG: 'HDBlog',
  HDOPEN: 'HDOpen',
  HEARST: 'Hearst',
  HEATWORLD: 'Heatworld',
  HEJ: 'Hej',
  HENNEO: 'Henneo',
  HEPSIBURADA: 'Hepsiburada',
  HIBURY_MEDIA: 'Hibury Media',
  HICMOBILE: 'HIC Mobile',
  HIMEDIA: 'Hi-Media',
  HIPERSUPER: 'Hipersuper',
  HISPANIDAD: 'Hispanidad',
  HITS_SITE_1: 'Hits Site 1',
  HOBBY_CONSOLAS: 'Hobby Consolas',
  HOLA: 'Hola',
  HOOPLA: 'Hoopla',
  HOPI: 'Hopi',
  HORIZON: 'Horizon',
  HPEIROS: 'Hpeiros',
  HTTPOOL: 'Httpool',
  HTXT_CO: 'HTXT Co',
  HURRIYET: 'Hurriyet',
  HVG: 'HVG',
  HWSW: 'HWSW',
  ICTNSTRATEGY: 'ICTN Strategy',
  IEFIMERIDA: 'Iefimerida',
  IGPDECAUX: 'IGP Decaux',
  IGRAAL: 'Igraal',
  IKUB: 'Ikub',
  ILAB: 'Ilab',
  IMDB: 'IMDB',
  IMPRESA: 'Impresa',
  IMPRESACITY: 'Impresacity',
  IMPRESSION_MEDIA: 'Impression Media',
  IN: 'In',
  INBOX_INSIGHTS: 'Inbox Insights',
  INDAMEDIA: 'Indamedia',
  INDUSTRIA_E_AMBIENTE: 'Industria e Ambiente',
  INFINETY: 'Infinety',
  INFINIA: 'Infinia',
  INFLUENT: 'Influent',
  INFOKIDS: 'Infokids',
  INGATLAN: 'Ingatlan',
  INLAB: 'Inlab',
  INNO3: 'Inno3',
  INSIDER: 'Insider',
  INSKIN: 'Inskin',
  INSTAGRAM: 'Instagram',
  INTERNET_CORP: 'Internet Corp',
  INTERNET_INFO: 'Internet Info',
  INTO: 'Into',
  INTV: 'InTV',
  INVIBES: 'Invibes',
  IOL: 'IOL',
  IQ_DIGITAL: 'IQ Digital',
  IRISH_SUN_ONLINE: 'Irish Sun Online',
  IRISH_TIMES: 'Irish Times',
  ISHOW: 'Ishow',
  ISIC: 'Isic',
  ITALIAATAVOLA: 'Italia a Tavola',
  ITV: 'ITV',
  IVOOX: 'Ivoox',
  JAGUAR: 'Jaguar',
  JAKALA: 'Jakala',
  JENNY: 'Jenny',
  JIVOX: 'Jivox',
  JOFOGAS: 'Jofogas',
  JORNAL_DENTISTRY: 'Jornal Dentistry',
  JORNAL_NEGOCIOS: 'Jornal Negocios',
  JORNAL_NOTICIAS: 'Jornal Noticias',
  JOYN: 'JOYN',
  JUST_PREMIUM: 'Just Premium',
  JUSTWATCH: 'Justwatch',
  KANAL_D: 'Kanal D',
  KARIYER_NET: 'Kariyer Net',
  KARNAVAL: 'Karnaval',
  KATHIMERINI: 'Kathimerini',
  KAYAK: 'Kayak',
  KEFALONIANMANTATA: 'Kefalonian Mantata',
  KETCHUP: 'Ketchup',
  KICKBASE: 'Kickbase',
  KICKER: 'Kicker',
  KIJIJI: 'Kijiji',
  KISS: 'Kiss',
  KIZLAR_SORUYOR: 'Kizlar Soruyor',
  KOMPARATORE: 'Komparatore',
  KUANTO_KUSTA: 'Kuanto Kusta',
  KUDIKA: 'Kudika',
  KWANKO: 'Kwanko',
  LA_INFORMACION: 'La Informacion',
  LA_VANGUARDIA: 'La Vanguardia',
  LA_VOZ_GALICIA: 'La Voz Galicia',
  LADBIBLE: 'Ladbible',
  LAKONIKOS: 'Lakonikos',
  LAMESCOLANZA: 'La Mescolanza',
  LAOSNEWS: 'Laos News',
  LAPSI: 'Lapsi',
  LATRIBUNADEALBACETE: 'La Tribuna de Albacete',
  LATTIMOFUGGENTEEDITORE: 'Lattimo Fuggente Editore',
  LETGO: 'Letgo',
  LIBERAL: 'Liberal',
  LIBERO: 'Libero',
  LIBERTATEA: 'Libertatea',
  LIFO: 'Lifo',
  LIGATUS: 'Ligatus',
  LIGHT_REACTION: 'Light Reaction',
  LINKAPPEAL: 'Link Appeal',
  LINKEDIN: 'LinkedIn',
  LOCALA: 'Locala',
  LOGAN: 'Logan',
  LOGISTICA_Y_TRANSPORTE: 'Logistica y Transporte',
  LUBEN_TV: 'Luben TV',
  LUCKYADV: 'LuckyADV',
  MACKOLIK: 'Mackolik',
  MADINAD: 'Madinad',
  MAFRA: 'Mafra',
  MAGGIOLI: 'Maggioli',
  MAGNESIANEWS: 'Magnesia News',
  MAINADV: 'mainADV',
  MAIS_FUTEBOL: 'Mais Futebol',
  MAJORDEM: 'MajorDEM',
  MAKROO: 'Makroo',
  MANZONI: 'Manzoni',
  MARCA: 'Marca',
  MARIECLAIRE: 'Marie Claire',
  MARKETEER: 'Marketeer',
  MARKETING_TURKIYE: 'Marketing Turkiye',
  MASFELFLEKK: 'Masfelflekk',
  MAZONI: 'Mazoni',
  MEDIA_ALLIANCE: 'Media Alliance',
  MEDIA_CAPITAL_DIGITAL: 'Media Capital Digital',
  MEDIA_IMPACT: 'Media Impact',
  MEDIA_IQ: 'Media IQ',
  MEDIACAT: 'MediaCat',
  MEDIAMOB: 'MediaMob',
  MEDIAMOND: 'Mediamond',
  MEDIAREY: 'MediaRey',
  MEDIASERVICES: 'MediaServices',
  MEDIASET: 'MediaSet',
  MEDIAWORKS: 'MediaWorks',
  MEDYANET: 'Medyanet',
  MEGA_HYBRID_TV: 'Mega Hybrid TV',
  MEGATV: 'MegaTV',
  MEIOS_E_PUBLICIDADE: 'Meios e Publicidade',
  META: 'Meta',
  METADIG: 'Metadig',
  METEO: 'Meteo',
  METEONOK: 'Meteonok',
  MIAMOBILE: 'Miamobile',
  MICROSOFT_ADCENTER: 'Microsoft AdCenter',
  MICROSOFT_BING: 'Microsoft Bing',
  MIGLIORTARIFFA: 'Miglior Tariffa',
  MIMEDA: 'Mimeda',
  MITELE: 'Mitele',
  MLADA_FRONTA: 'Mlada Fronta',
  MOBSTA: 'Mobsta',
  MONDOMOBILE: 'MondoMobile',
  MONITOR: 'Monitor',
  MONONEWS: 'MonoNews',
  MOON: 'Moon',
  MOOVIT: 'Moovit',
  MOTORPRESS_IBERICA: 'Motorpress Iberica',
  MOTOS_NET_Y_MIL_ANUNCIOS_MOTOS: 'Motos net y mil anuncios motos',
  MOVINGUP: 'MovingUp',
  MSAN: 'Microsoft Audience Network',
  MSN: 'MSN',
  MUNDOTORO: 'Mundotoro',
  MUYPYM: 'MuyPYM',
  MVLSZ: 'MVSLZ',
  MYBEST: 'MyBest',
  MYBROADBAND_CO: 'MyBroadband',
  MYNET: 'Mynet',
  NA: 'N/A',
  NAFTEMPORIKI: 'Naftemporiki',
  NANO_INTERACTIVE: 'Nano Interactive',
  NATIVHIRDETES: 'Nativ Hirdetes',
  NBC_UNIVERSAL: 'NBC Universal',
  NEJBUSINESS: 'NejBusiness',
  NEOLAIA: 'Neolaia',
  NEOPOLIS: 'Neopolis',
  NETADDICTION: 'Netaddiction',
  NETCOMMEDYA: 'NetCommedya',
  NETCONSULTINGCUBE: 'Net Consulting Cube',
  NETFLIX: 'Netflix',
  NETMEDIA: 'Netmedia',
  NETMEDIACLICK: 'NetmediaClick',
  NEWBUSINESSMEDIA: 'NewBusinessMedia',
  NEWGEN: 'Newgen',
  NEWPOST: 'Newpost',
  NEWS24: 'News24',
  NEWS247: 'News247',
  NEWSBEAST: 'Newsbeast',
  NEWSBOMB: 'Newsbomb',
  NEWSIT: 'Newsit',
  NEWSLETTER: 'Newsletter',
  NEWSONLINE: 'NewsOnline',
  NEWSROOM: 'Newsroom',
  NEWSUK: 'NewsUK',
  NEXTDOOR: 'Nextdoor',
  NIT: 'NIT',
  NOA: 'NOA',
  NOKTA: 'Nokta',
  NOOZ: 'Nooz',
  NORBERTOGALLEGO: 'Norberto Gallego',
  NOTICIAS_AO_MINUTO: 'Noticias ao Minuto',
  NSO: 'NSO',
  NWMGROUP: 'NWMGroup',
  O_JOGO: 'O Jogo',
  OATH: 'Oath',
  OBSERVADOR: 'Observador',
  OCTAIVE: 'Octaive',
  OGGUSTO: 'Oggusto',
  OGURY: 'Ogury',
  OLX: 'OLX',
  OLYMPIOBIMA: 'Olympiobima',
  ONE_BRAND_STUDIO: 'One Brand Studio',
  ONE_FIVE_COUNTRIES: '15Countries',
  ONE_ZERO_AND5: '10and5',
  ONEDIO: 'Onedio',
  ONEFOOTBALL: 'OneFootball',
  ONEMAN: 'Oneman',
  ONLARISSA: 'Onlarissa',
  ONPAGE: 'OnPage',
  OPENIT: 'OpenIt',
  OPINION: 'Opinion',
  OT: 'OT',
  OTHER: 'Other',
  OUTBRAIN: 'Outbrain',
  OUTLOOK: 'Outlook',
  PACKED_HOUSE: 'Packed House',
  PANORAMA_SPORT: 'Panorama Sport',
  PANORAMA: 'Panorama',
  PARTNERS_MEDIA: 'Partners Media',
  PATRIS: 'Patris',
  PATRISNEWS: 'PatrisNews',
  PATRONLAR_DUNYASI: 'Patronlar Dunyasi',
  PAYCLICK: 'Payclick',
  PBU: 'PBU',
  PERFORM: 'Perform',
  PERSADO: 'Persado',
  PESADOS_MERCADORIAS: 'Pesados Mercadorias',
  PI: 'PI',
  PIEMME: 'Piemme',
  PINTEREST: 'Pinterest',
  PLATFORM: 'Platform',
  PLAYTECH: 'Playtech',
  PLISTA: 'Plista',
  PMAX: 'Pmax',
  POLIHUB: 'Polihub',
  POLIMI: 'Polimi',
  POLITIKO: 'Politiko',
  POLITISCHIOS: 'Politischios',
  POPADVERTISING: 'PopAdvertising',
  POPAGANDA: 'Popaganda',
  PPH: 'PPH',
  PREMIUM_PUBLISHERS_NETWORK: 'Premium Publishers Network',
  PRENSA_IBERICA: 'Prensa Iberica',
  PRIME: 'Prime',
  PRIMEDIA: 'Primedia',
  PRISA: 'Prisa',
  PROFIT: 'Profit',
  PROGRAMATIK: 'Programatik',
  PROHARDVER: 'Prohardver',
  PROININEWS: 'Proini News',
  PROJECT_AGORA: 'Project Agora',
  PROMOQUI: 'Promoqui',
  PROPERTY24: 'Property24',
  PROTOTHEMA: 'Protothema',
  PROTV: 'ProTV',
  PROVOCATEUR: 'Provocateur',
  PUBLICO: 'Publico',
  PUBLIMEDIA: 'Publimedia',
  PUBLITURIS: 'Publituris',
  PUSHNOTIFICATION: 'PushNotification',
  PUSHPUSHGO: 'PushPushGo',
  PYMES_Y_AUTONOMOS: 'Pymes y Autonomos',
  QRCODE: 'QRCode',
  QRIOUSLY: 'Qriously',
  QUALIFY: 'Qualify',
  QUANTCAST: 'Quantcast',
  QUANTUM: 'Quantum',
  QUEEN: 'Queen',
  RAI: 'RAI',
  RAIPLAY: 'RAIPlay',
  RAIPUBBLICITA: 'RAIPubblicita',
  RCS: 'RCS',
  RDS: 'RDS',
  REACH: 'Reach',
  READER: 'Reader',
  REAL_TIME_BIDDING: 'Real Time Bidding',
  REAL: 'Real',
  REBEL_REBEL: 'Rebel Rebel',
  REBOLD: 'Rebold',
  RECOGNIFIED: 'Recognified',
  RECORD: 'Record',
  REDDIT: 'Reddit',
  REDE_COFINA: 'Rede Cofina',
  REDE_MCR: 'Rede MCR',
  REDE_R_MULTIMEDIA: 'Rede R Multimedia',
  REETMO: 'Reetmo',
  REFINE_DIRECT: 'Refine Direct',
  REFRESHER: 'Refresher',
  RELEVANT: 'Relevant',
  REPUBLICA: 'Republica',
  RETRO_MUSIC_TV: 'Retro Music TV',
  REVISTA_METALICA: 'Revista Metalica',
  REVISTA_WHO: 'Revista WHO',
  REVISTABORDO: 'Revista Bordo',
  REVISTACLASS :'Revista Class',
  REZONENCE: 'Rezonence',
  RICHAUDIENCE: 'Rich Audience',
  RICONTATTOCHECKOUT: 'Ricontatto Checkout',
  RIGHTMOVE: 'Rightmove',
  RINGIER: 'Ringier',
  RISPARMIATU: 'Risparmiatu',
  ROAMS: 'Roams',
  ROCKETFUEL: 'Rocketfuel',
  RODIAKI: 'Rodiaki',
  RON_PARENTING_ADS: 'RON Parenting Ads',
  RONIN: 'Ronin',
  ROOK_DIGITAL: 'Rook Digital',
  RTB_HOUSE: 'RTB House',
  RTE: 'RTE',
  RTIME: 'Rtime',
  RTL: 'RTL',
  RTP_ARENA: 'RTP Arena',
  RTP: 'RTP',
  RUSTICAE: 'Rusticae',
  S4M: 'S4M',
  SABAH: 'Sabah',
  SAHIBINDEN: 'Sahibinden',
  SALESCONTACT: 'SalesContact',
  SALESWORKS: 'Salesworks',
  SALLO: 'Sallo',
  SAMSUNGADS: 'Samsung Ads',
  SAPO: 'Sapo',
  SAPOS_Y_PRINCESAS: 'Sapos y Princesas',
  SARUGBYMAG: 'SA Rugby Mag',
  SCHIBSTED: 'Schibsted',
  SCIBIDS: 'Scibids',
  SCREEN_ON_DEMAND: 'Screen on Demand',
  SDNA: 'SDNA',
  SEAT: 'Seat',
  SECURITY: 'IT Security',
  SEEDTAG: 'Seedtag',
  SEGUGIO: 'Segugio',
  SENSACINE: 'Sensacine',
  SERRESPARATIRITIS: 'Serres Para Tiritis',
  SERTHARROS: 'Sertharros',
  SEVENONE: '71',
  SEZNAM: 'Seznam',
  SFATULPARINTILOR: 'Sfatul Parintilor',
  SFERAEDITORE: 'Sfera Editore',
  SHAHID_NET: 'Shahid Net',
  SHAZAM: 'Shazam',
  SHEKULLI: 'Shekulli',
  SHOWHEROES: 'ShowHeroes',
  SHQIPTARJA: 'Shqiptarja',
  SIC_NOTICIAS: 'SIC Noticias',
  SIC: 'SIC',
  SIKAYET_VAR: 'Sikayet Var',
  SILVERBULLET: 'SilverBullet',
  SKAI: 'Skai',
  SKLIK: 'Sklik',
  SKROUTZ: 'Skroutz',
  SKY: 'Sky',
  SMARTADNETWORK: 'SmartAdNetwork',
  SMARTCLIP: 'SmartClip',
  SMARTCONTRACT: 'SmartContract',
  SMARTSTREAM: 'Smartstream',
  SMAU: 'Smau',
  SMOOT: 'Smoot',
  SMS: 'SMS',
  SNAPCHAT: 'Snapchat',
  SOCIAL_PUBLI: 'Social Publi',
  SOLVETIC: 'Solvetic',
  SOS_TARIFFE: 'SOS Tariffe',
  SOSFE: 'Sosfe',
  SOUNDCLOUD: 'Soundcloud',
  SOVENDUS: 'Sovendus',
  SOZCU: 'Sozcu',
  SPEWEB: 'Speweb',
  SPINUP: 'Spinup',
  SPORT24: 'Sport24',
  SPORTNETWORK: 'SportNetwork',
  SPOTIFY: 'Spotify',
  STAR: 'Star',
  STARTMAGAZINE: 'StartMagazine',
  STORYLAB: 'Storylab',
  STROER_DIGITAL_GROUP: 'Ströer Digital Group',
  STROER_NATIVE_STORY: 'Ströer Native Story',
  STROSSLE: 'Strossle',
  STUDENT_ROOM: 'Student Room',
  SUBITO: 'Subito',
  SUBLIME: 'Sublime',
  SUBLIMESKIN: 'SublimeSkin',
  SUNMEDIA: 'Sunmedia',
  SUPERBRU: 'Superbru',
  SUPERSPORT: 'Supersport',
  SUPPLY_CHAIN_MAGAZINE: 'Supply Chain Magazine',
  SYRI: 'Syri',
  SYSTEM24: 'System24',
  TAB_MEDIA: 'Tab Media',
  TABMO: 'Tabmo',
  TABOOLA: 'Taboola',
  TABRIS: 'Tabris',
  TALKSMEDIA: 'Talksmedia',
  TAPTAP: 'TapTap',
  TAXYDROMOS: 'Taxydromos',
  TEADS: 'Teads',
  TELEFONIA_Y_COMUNICACIONES: 'Telefonia y Comunicaciones',
  TELEGRAF: 'Telegraf',
  TELEGRAPH: 'Telegraph',
  TELTARIF: 'Teltarif',
  TEST: 'Test',
  TGADV: 'TGADV',
  THE_CITIZEN: 'The Citizen',
  THE_GUARDIAN: 'The Guardian',
  THE_INDEPENDENT: 'The Independent',
  THE_MAGGER: 'The Magger',
  THE_OZONE_PROJECT: 'The Ozone Project',
  THE_SUNDAY_TIMES_IRELAND: 'The Sunday Times Ireland',
  THE_TIMES: 'The Times',
  THEJOURNAL_IE: 'TheJournal IE',
  THESSALIANEWS: 'Thessalia News',
  THETOC: 'TheToc',
  THETRADEDESK: 'TheTradeDesk',
  THREE_SIX_FIVE_DIGITAL: '365 Digital',
  THREE_SIX_ZERO_GRADE: '360 Grade',
  TICTOC: 'Tictoc',
  TIENDEO: 'Tiendeo',
  TIKTOK: 'TikTok',
  TIMEOUT: 'TimeOut',
  TIRANAPOST: 'TiranaPost',
  TISCALI: 'Tiscali',
  TISOOMI: 'Tisoomi',
  TOP_CHANNEL: 'Top Channel',
  TOPINFO: 'TopInfo',
  TOVIMA: 'Tovima',
  TPZ: 'TPZ',
  TRADEDOUBLER: 'TradeDoubler',
  TRADETRACKER: 'TradeTracker',
  TRENDONLINE: 'TrendOnline',
  TRIBOO: 'Triboo',
  TRIKALAOLA: 'Trikalaola',
  TRIPLELIFT: 'TripleLift',
  TRT: 'TRT',
  TUEXPERTO: 'TuExperto',
  TURKUVAZ: 'Turkuvaz',
  TURN_DIGITAL: 'Turn Digital',
  TV_KLAN: 'TV Klan',
  TV8: 'TV8',
  TVEKSTRA: 'TVEkstra',
  TWITCH: 'Twitch',
  TWITTER: 'Twitter',
  TWO_FOUR_MEDIA: '24 Media',
  TWO_ZERO_MINUTOS: '20Minutos',
  UBER: 'Uber',
  ULUDAG_SOZLUK: 'Uludag Sozluk',
  UNEDISA: 'Unedisa',
  UNGDC: 'UNGDC',
  UNIDAD_EDITORIAL: 'Unidad Editorial',
  UNIVERSALSKIN: 'UniversalSkin',
  UNKNOWN: 'Unknown',
  UPTIMIZE: 'Uptimize',
  VALASZONLINE: 'ValaszOnline',
  VALICA: 'Valica',
  VALUEDEM: 'ValueDEM',
  VANMASIK: 'Vanmasik',
  VDX: 'VDX',
  VEESIBLE: 'Veesible',
  VELOXITY: 'Veloxity',
  VERIZON: 'Verizon',
  VERSURI: 'Versuri',
  VERTISMEDIA: 'VertisMedia',
  VIBER: 'Viber',
  VICINITY_MEDIA: 'Vicinity Media',
  VIDEOBEET: 'Videobeet',
  VIDOOMY: 'Vidoomy',
  VINTED: 'Vinted',
  VIRALIZE: 'Viralize',
  VIRGIN_MEDIA: 'Virgin Media',
  VISABLE: 'Visable',
  VISIBILIA: 'Visibilia',
  VISITLIFT: 'Visitlift',
  VITASOCIETAEDITORIALESPA: 'Vta Societa Editorial ESPA',
  VIU: 'Viu',
  VLTAVA_LABE_MEDIA: 'Vltava Labe Media',
  VOCENTO: 'Vocento',
  VODAFONE: 'Vodafone',
  VOGUE: 'Vogue',
  VOICEUP: 'VoiceUp',
  VOXIFY: 'Voxify',
  VOZ_POPULI: 'Voz Populi',
  WALLAPOP: 'Wallapop',
  WALLSTREET: 'WallStreet',
  WAVEMAKER: 'Wavemaker',
  WAZE: 'Waze',
  WEBADS: 'WebAds',
  WEBBDONE: 'WebbDone',
  WEBEDIA: 'Webedia',
  WEBLOGS: 'Weblogs',
  WEBORAMA: 'Weborama',
  WEBPERFORMANCE: 'WebPerformance',
  WEBSTARCHANNEL: 'WebstarChannel',
  WEBSYSTEM: 'Websystem',
  WEBTEKNO: 'Webtekno',
  WECHAT: 'Wechat',
  WELT: 'Welt',
  WEMASS: 'Wemass',
  WETRANSFER: 'WeTransfer',
  WIDESPACE: 'Widespace',
  WINK: 'Wink',
  WIRED: 'Wired',
  WMN: 'WMN',
  WUOLAH: 'Wuolah',
  XATAKA: 'Xataka',
  XAXIS: 'XAxis',
  XIMOBI: 'Ximobi',
  XRONOS: 'Xronos',
  XTYPOS: 'Xtypos',
  YAHOO: 'Yahoo',
  YDIGITAL: 'YDigital',
  YENISAFAK: 'Yenisafak',
  YO_SOY_NOTICIA: 'Yo Soy Noticia',
  YOC: 'YOC',
  YODA: 'Yoda',
  YOUTHALL: 'Youthall',
  YOUTUBE: 'YouTube',
  ZANOX: 'Zanox',
  ZEMANTA: 'Zemanta',
  ZERO_ONE_FOUR: '014',
  ZERO_ONE_NET: '01Net',
  ZERO_ZERO: 'ZeroZero',
  ZETA: 'Zeta',
  ZF: 'ZF',
  ZIARE: 'Ziare',
  ZIFF_DAVIS: 'Ziff Davis',
  ZOOCOM: 'Zoocom',
  ZOOPLA: 'Zoopla',
  ZOUGLA: 'Zougla',
}