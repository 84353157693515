import {
  endOfMonth,
  endOfQuarter,
  format,
  isBefore,
  startOfMonth,
  startOfQuarter,
  subMonths,
  subQuarters,
  subYears,
} from 'date-fns'

import { mapValues } from 'lodash-es'

import {
  DateRange,
  OptionalComparisonDateRanges,
  OptionalDateRange,
  OverviewGranularity,
} from './typings'

import { DateRangeParams, Nullable } from '@percept/types'


export const getFinancialYearStart = (referenceDate?: Date | null): Date => {
  referenceDate = referenceDate || new Date()
  const YYYY = referenceDate.getUTCFullYear()
  const financialYYYY = (
    referenceDate.getUTCMonth() < 3 ?
      YYYY - 1 :
      YYYY
  )
  const financialYearStart = new Date(financialYYYY, 3, 1)
  return financialYearStart
}

export const normaliseDate = (date: Date): Date => new Date(format(date, 'yyyy-MM-dd'))

export const isoDate = (date: Date): string => (
  format(date, 'yyyy-MM-dd')
)

export const areDatesEqual = (a: Date, b: Date): boolean => isoDate(a) === isoDate(b)


const completedRangeDateMethods: Record<OverviewGranularity, Record<keyof DateRange, (date: Date) => Date>> = {
  month: {
    start: startOfMonth,
    end: endOfMonth,
  },
  quarter: {
    start: startOfQuarter,
    end: (date): Date => (
      areDatesEqual(endOfQuarter(date), date) ? date : endOfQuarter(subQuarters(date, 1))
    ),
  },
  year: {
    start: getFinancialYearStart,
    end: endOfMonth,
  },
  year_on_year_month: {
    start: startOfMonth,
    end: endOfMonth,
  },
  year_on_year_quarter: {
    start: startOfQuarter,
    end: (date): Date => (
      areDatesEqual(endOfQuarter(date), date) ? date : endOfQuarter(subQuarters(date, 1))
    ),
  }
}

const dateRangeGenerators: Record<OverviewGranularity, (referenceDate: Date) => DateRange> = mapValues(
  completedRangeDateMethods,
  (rangeMethods) => (
    (date: Date): DateRange => {
      const end = normaliseDate(rangeMethods.end(date))
      const start = normaliseDate(rangeMethods.start(end))
      return { start, end }
    }
  )
)


const comparisonDateMethods: Record<OverviewGranularity, (date: Date, amount: number) => Date> = {
  month: subMonths,
  quarter: subQuarters,
  year: subYears,
  year_on_year_month: subYears,
  year_on_year_quarter: subYears,
}
const comparisonGenerators: Record<OverviewGranularity, (primaryDateRange: DateRange) => DateRange> = mapValues(
  comparisonDateMethods,
  (dateMethod) => (
    (dateRange: DateRange): DateRange => ({
      start: normaliseDate(startOfMonth(dateMethod(dateRange.start, 1))),
      end: normaliseDate(endOfMonth(dateMethod(dateRange.end, 1))),
    })
  )
)


export const getComparisonDateRanges = ({
  primaryGranularity,
  // secondaryGranularity,
  referenceDate
}: {
  primaryGranularity: OverviewGranularity
  secondaryGranularity: OverviewGranularity
  referenceDate: Date | null
}): OptionalComparisonDateRanges => {
  let primary: OptionalDateRange = null,
      comparison: OptionalDateRange = null
      // secondary: OptionalDateRange = null,
      // secondaryComparison: OptionalDateRange = null

  if( referenceDate ){
    primary = dateRangeGenerators[primaryGranularity](referenceDate)
    comparison = comparisonGenerators[primaryGranularity](primary)
  }

  return {
    primary,
    comparison,
    secondary: null,
    secondaryComparison: null,
  }
}


export const resolveDateParams = (dateRange: OptionalDateRange): Nullable<DateRangeParams> => {
  let start_date: string | null = null, end_date: string | null = null
  if( dateRange ){
    start_date = isoDate(dateRange.start)
    end_date = isoDate(dateRange.end)
  }
  return { start_date, end_date }
}


const historicalCutoff = new Date('2023-04-30')

export const getDateOptions = (referenceDate: Date | null, granularity: OverviewGranularity): Date[] => {
  if( !referenceDate ){
    return []
  }

  const rangeGenerator = dateRangeGenerators[granularity]

  let curr = normaliseDate(referenceDate)
  const comparison = normaliseDate(historicalCutoff)
  const options: Date[] = []

  while( !isBefore(curr, comparison) ){
    const range = rangeGenerator(curr)
    options.push(range.end)
    curr = normaliseDate(endOfMonth(subMonths(range.start, 1)))
  }

  return options.reverse()
}

export const getPreviousPotentialEfficiencyReferenceDate = (): Date => (
  subYears(new Date(), 1)
)
