

import React from 'react'

import { animated, useSpring, OpaqueInterpolation } from 'react-spring'


export type AnimatedBlobProps = (
  Pick<
    React.CSSProperties,
    'color' | 'fontSize'
  > & {
    size: number
    width?: number
    height?: number
  }
)


export function AnimatedBlob({
  size,
  color = '',
  width,
  height,
}: AnimatedBlobProps): JSX.Element {

  const radius = size / 2

  width = Math.max(width || 0, size)
  height = Math.max(height || 0, size)

  const animatedProps = useSpring({
    radius,
    cx: width / 2,
    cy: height / 2,
    color,
    to: {
      radius,
      color,
      cx: width / 2,
      cy: height / 2,
    },
  })

  return (
    <svg
      width={width}
      height={height}>
      <animated.circle
        fill={animatedProps.color}
        r={animatedProps.radius as OpaqueInterpolation<number>}
        cx={animatedProps.cx as OpaqueInterpolation<number>}
        cy={animatedProps.cy as OpaqueInterpolation<number>} />
    </svg>
  )
}
