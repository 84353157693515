import { Card, CardContent, Collapse } from '@material-ui/core'
import React, { useState } from 'react'
import { TabSectionHeader } from '../PartnershipTabs'
import { CombinedReport, StandardReportsTable } from '../ReportTables'
import { AllMarketsYoutubeReportModel } from '../types'
import { ProviderLogo } from '@percept/mui'
import { ParsedCreativeXQualityScoreData } from '../dataUtils'
import { get } from 'lodash-es'
import { format, parse } from 'date-fns'

export type YoutubeReportsProps = {
  reports: AllMarketsYoutubeReportModel
  monthlyReports: AllMarketsYoutubeReportModel
  creativeXData: ParsedCreativeXQualityScoreData
  referenceDate: string
}

export const YoutubeReports = ({ reports, monthlyReports, creativeXData, referenceDate }: YoutubeReportsProps): JSX.Element => {

  const referenceDateObject = new Date(referenceDate)

  const combinedReport: CombinedReport = {
    ...reports,
    creative_quality_score: {
      label: 'Creative Quality Score',
      market_values: reports.header_values.map( header => {
        const match = get(creativeXData.grouped, [referenceDate, header.label], null)
        return {
          market_iso_code: header.label,
          ordinal_no: header.ordinal_no,
          value: match
        }
      })
    }
  }

  const combinedMonthlyReport: CombinedReport = {
    ...monthlyReports,
    creative_quality_score: {
      label: 'Creative Quality Score',
      market_values: monthlyReports.header_values.map( header => {
        const parsedDate = parse(header.label, 'MMM-yy', referenceDateObject)
        const parsedIso = format(parsedDate, 'yyyy-MM-dd')
        const match = get(creativeXData.averaged, parsedIso, null)
        return {
          market_iso_code: header.label,
          ordinal_no: header.ordinal_no,
          value: match
        }
      })
    }
  }

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  return(
    <Card elevation={2}>
      <TabSectionHeader
        title={
          <ProviderLogo provider='youtube' size={1.5} />
        }
        my={1.5}
        px={2}
        isCollapsed={isCollapsed}
        handleCollapse={setIsCollapsed}/>
      <Collapse in={!isCollapsed}>
        <CardContent>
          <StandardReportsTable mb={5} report={combinedReport} />
          <StandardReportsTable mb={5} report={combinedMonthlyReport} />
        </CardContent>
      </Collapse>
    </Card>
  )
}
