
import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'

import { ReduxBundle } from '../lib'

import { PerformanceReportingState } from './typings'


type PerformanceReportingActions = typeof actions
type PerformanceReportingSelectors = typeof selectors


export type PerformanceReportingBundle = ReduxBundle<
  PerformanceReportingState, PerformanceReportingActions, PerformanceReportingSelectors
>

export * from './typings'

export { actions, reducer, selectors }


export const makeBundle = (): PerformanceReportingBundle => ({
  actions,
  reducer,
  selectors
})

export default { actions, reducer, selectors } as PerformanceReportingBundle
