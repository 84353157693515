import { Middleware } from 'redux'

import { user } from '../bundles'

import { getFetchTypes } from '../utils'

import { get, noop } from 'lodash-es'

import { makeStorageGetter, makeStorageSetter } from './storage'

import { Action, MFADeliveryMedium } from '@percept/types'


const signInFetchTypes = getFetchTypes(user.actions.SIGN_IN)
const codeChallengeFetchTypes = getFetchTypes(user.actions.RESPOND_TO_CODE_CHALLENGE)
const toggleMfaStateFetchTypes = getFetchTypes(user.actions.TOGGLE_SOFTWARE_MFA_STATE)


const MFA = 'MFA'

const fetchMFADeliveryMedium = makeStorageGetter<MFADeliveryMedium | null>(MFA)
const saveMFADeliveryMedium = makeStorageSetter<MFADeliveryMedium | null>(MFA)


export const mfaMiddleware: Middleware = (store) => (next) => (action: Action): Action => {

  if( action.type === user.actions.CHECK_REFRESH_TOKEN ){
    fetchMFADeliveryMedium().then( deliveryMedium => {
      store.dispatch(user.actions.storeMfaDeliveryMedium(deliveryMedium))
    }).catch(noop)
  }

  const result = next(action)

  if( action.type == toggleMfaStateFetchTypes.success ){
    saveMFADeliveryMedium(
      get(action.meta, 'enabled') ? 'SOFTWARE_TOKEN_MFA' : null
    ).then(noop).catch(console.warn)
  }

  if( action.type == codeChallengeFetchTypes.success ){
    saveMFADeliveryMedium('SOFTWARE_TOKEN_MFA').then(noop).catch(console.warn)
  }

  if( action.type == signInFetchTypes.success ){
    saveMFADeliveryMedium(null).then(noop).catch(console.warn)
  }

  return result
}
