import React from 'react'
import { BarStack as BarStackComponent } from '@visx/shape'
import { Group } from '@visx/group'
import { Grid } from '@visx/grid'
import { scaleBand, scaleLinear } from '@visx/scale'
import { useTooltip, useTooltipInPortal } from '@visx/tooltip'
import { Box, useAppTheme } from '@percept/mui'
import { SeriesPoint } from '@visx/shape/lib/types'
import { LegendOrdinal } from '@visx/legend'
import { transformGroupData, transformPlainData } from './transformData'
import { Tooltip } from './components/Tooltip'
import { Axis } from './components/Axis'
import { BarChartProps } from './type'
import { Summary } from '../DashboardLayout'
import { reorderColumns } from '../reorderColumns'
import { getMoneyFormatter, percentageFormatter } from '@percept/mui/charts'
import { AXIS_MARGIN_BOTTOM, CHART_HEIGHT } from './constants'
import { useChartClasses, visxLegendStyles } from './styles'

type TooltipData = {
  bar: SeriesPoint<Record<string, string>>
  key: string
  index: number
  height: number
  width: number
  x: number
  y: number
  color: string
}

const defaultMargin = { top: 40, right: 20, bottom: 0, left: 70 }

let tooltipTimeout: number

export const StackedBarChart = ({
  dataFormat,
  width,
  dataObject,
  yDomain,
  valueFormatter,
  colorScale,
  margin = defaultMargin,
}: BarChartProps): JSX.Element => {
  const chartClasses = useChartClasses()
  const appTheme = useAppTheme()
  const axisColor = appTheme.palette.text.primary

  const height = CHART_HEIGHT

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>()

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  })
  const currency = dataObject[0].total_currency
  const formatter = valueFormatter || (
    dataFormat === 'currency'
      ? getMoneyFormatter(currency)
      : percentageFormatter
  )
  const isGroupData = !!dataObject[0].data
  const totalObj = dataObject.find((e) => e.row_group === Summary.AllTotal || e.row_group === 'Total' ) ||
    (dataObject.length === 1 && dataObject[0]) || {
    row_group: '',
    data: [],
    costs: [],
    total: '',
    total_percentage: '',
    total_currency: '',
  }

  const {
    columnNames,
    stacksNames,
    totalArray,
    modifiedData,
    totalArrayPercentage,
  } = isGroupData
    ? {
      columnNames: reorderColumns(
        totalObj.costs.map((el: { type_value: string }) => el.type_value)
      ),
      stacksNames:
          totalObj.data &&
          totalObj.data.map((el: { data_type: string }) => el.data_type) ||
          [],
      totalArray: totalObj.costs.map((el) => el.spend),
      modifiedData: transformGroupData(totalObj, dataFormat),
      totalArrayPercentage: totalObj.costs.map((el) => el.percent),
    }
    : {
      columnNames: reorderColumns(dataObject.map((e) => e.row_group)),
      modifiedData: transformPlainData(dataObject, dataFormat),
      stacksNames: dataObject.reduce((arr: string[], el) => {
        const newArr = el.costs
          .filter(
            (item: { type_value: string }) =>
              !arr.find((el) => el === item.type_value)
          )
          .map((el) => el.type_value)
        return arr.concat(newArr)
      }, []),
      totalArray: dataObject.map((el) => el.total),
      totalArrayPercentage: dataObject.map((el) => el.total_percentage),
    }

  const fullData = modifiedData.map((el) => {
    return stacksNames
      ? stacksNames.reduce((acc: Record<string, string>, curr: string) => {
        return acc[curr] ? acc : { ...acc, [curr]: '0' }
      }, el)
      : el
  })

  yDomain = yDomain || [
    0,
    (
      dataFormat === 'currency'
        ? Math.max(...totalArray.map(Number))
        : Math.max(...totalArrayPercentage.map(Number))
    )
  ]

  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - AXIS_MARGIN_BOTTOM

  const xScale = scaleBand<string>({
    domain: columnNames,
    padding: 0.2,
    range: [0, xMax],
  })

  const yScale = scaleLinear<number>({
    domain: yDomain,
    nice: true,
    range: [yMax, 0],
  })

  colorScale = colorScale || appTheme.chart.getOrdinalColourScale(stacksNames)

  return (
    <Box className={chartClasses.root}>
      <svg ref={containerRef} width={width} height={height}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill='transparent'
        />
        <Grid
          top={margin.top}
          left={margin.left}
          xScale={xScale}
          yScale={yScale}
          width={xMax}
          height={yMax}
          stroke={appTheme.chart.grid.stroke}
          strokeOpacity={0.75}
          strokeWidth={2}
        />
        <Group top={margin.top} left={margin.left}>
          <BarStackComponent<Record<string, string>, string>
            data={fullData}
            keys={stacksNames}
            x={(d): string => d.column}
            xScale={xScale}
            yScale={yScale}
            color={colorScale}>
            {(barStacks): JSX.Element[][] =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) => (
                  <rect
                    key={`bar-stack-${barStack.index}-${bar.index}`}
                    x={bar.x}
                    y={bar.y}
                    height={bar.height}
                    width={bar.width}
                    fill={bar.color}
                    opacity={0.9}
                    onMouseLeave={(): void => {
                      tooltipTimeout = window.setTimeout(() => {
                        hideTooltip()
                      }, 300)
                    }}
                    onMouseMove={(): void => {
                      if (tooltipTimeout) clearTimeout(tooltipTimeout)
                      const left = bar.x + bar.width
                      const top = bar.y + bar.height / 4
                      showTooltip({
                        tooltipData: bar,
                        tooltipTop: top,
                        tooltipLeft: left,
                      })
                    }}
                  />
                ))
              )
            }
          </BarStackComponent>
        </Group>
        <Axis
          margin={margin}
          yMax={yMax}
          xScale={xScale}
          yScale={yScale}
          color={axisColor}
          tickFormatter={formatter}
          maxWidth={(width - margin.left - margin.right) / modifiedData.length}
        />
      </svg>
      <Box className={chartClasses.legend}>
        <LegendOrdinal
          scale={colorScale}
          style={visxLegendStyles}
          direction='row'
          labelMargin='0 30px 0 0'
        />
      </Box>
      {tooltipOpen && tooltipData && (
        <Tooltip
          TooltipInPortal={TooltipInPortal}
          colorScale={colorScale}
          top={tooltipTop}
          left={tooltipLeft}
          value={Number(tooltipData.bar.data[tooltipData.key])}
          label={tooltipData.key}
          valueFormatter={formatter}
        />
      )}
    </Box>
  )
}
