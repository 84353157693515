
import {
  apiInitialState,
  getFetchTypes,
} from '@percept/redux'

import { getEntityKeyFromAction } from '@percept/redux/utils'

import adapters, { attributesToMetrics } from '@percept/api/adapters'

import {
  mapValues,
  getPath,
  getHealthFrom,
  getHealthAggregationsFrom,
} from '@percept/utils'

import {
  getAuditEntities,
  hasInlineEntities } from './lib'

import { applyAllSegments } from '../lib'

import {
  Reducer,
  ApiResponse,
  MetricMetadataType,
  Dictionary,
  ReportEntity,
  ReduxAction,
  MetricsPayload,
} from '@percept/types'


// Reduce an action payload into a set of audit entity data
const storeInlineEntities = (payload: any, metricMetadata: MetricMetadataType | null) => {
  
  const auditEntities = getAuditEntities(payload)
  
  return Object.keys(auditEntities).reduce( (output: Dictionary<ApiResponse<ReportEntity>>, entityType) => {
    
    const byId = auditEntities[entityType]
    
    Object.keys(byId).forEach( entityID => {
      
      const entity = byId[entityID]
      
      const storageKey = `${entityType}|${entityID}`

      const metrics = mapValues(
        getPath(entity, 'metrics', {}),
        adapters.metric
      )
      
      output[storageKey] = {
        ...apiInitialState,
        data: {
          ...entity,
          metrics: metricMetadata ? applyAllSegments({
            metrics,
            metadata: metricMetadata
          }) : metrics
        }
      }
    
    })
    
    return output
  
  }, {})
}


const createAuditEntityReducer = (
  { loadAuditType, loadEntityType, loadMetricMetadataType }:
  { loadAuditType: string, loadEntityType: string, loadMetricMetadataType: string }
): Reducer<Dictionary<ApiResponse<ReportEntity>>> => {

  if( !loadAuditType || !loadEntityType ){
    throw new Error(
      'You must provide an options object with the keys `loadAuditType` & `loadEntityType` to createAuditEntityReducer()'
    )
  }

  /* Default split payload reducer generation */

  const auditEntityFetchTypes = getFetchTypes(loadEntityType)

  const metricMetadataFetchTypes = getFetchTypes(loadMetricMetadataType)


  const defaultKeyedReducer: Reducer<Dictionary<ApiResponse<ReportEntity>>> = (
    state: Dictionary<ApiResponse<ReportEntity>> = {},
    action: ReduxAction,
    metricMetadata: MetricMetadataType | null,
  ) => {
    
    const storageKey = getEntityKeyFromAction(action)

    if( action.type === metricMetadataFetchTypes.success && Object.keys(state).length ){

      return Object.keys(state).reduce( (acc: Dictionary<ApiResponse<ReportEntity>>, k) => {
        acc[k] = {
          ...state[k],
          data: getPath(state[k], 'data') ? {
            ...(getPath(state[k], 'data')) as ReportEntity,
            metrics: applyAllSegments({
              metrics: getPath(state[k], ['data', 'metrics']) || {},
              metadata: action.payload
            })
          } : null
        }
        return acc
      }, {})
    }
    
    if( storageKey ){

      const data = getPath(action, 'payload')

      const metrics = mapValues(
        getPath(data, 'metrics', {}),
        adapters.metric
      )

      switch(action.type){

        case auditEntityFetchTypes.success:
          return {
            ...state,
            [storageKey]: {
              ...apiInitialState,
              data: data ? {
                ...(data || {}),
                health: getHealthFrom(data),
                health_aggregations: getHealthAggregationsFrom(data),
                metrics: metricMetadata ? applyAllSegments({
                  metrics: metrics,
                  metadata: metricMetadata
                }) : metrics
              } : null
            } as ApiResponse<ReportEntity>
          }

        case auditEntityFetchTypes.start:
          return {
            ...state, 
            [storageKey]: { ...apiInitialState, loading: true }
          }

        case auditEntityFetchTypes.error:
          return {
            ...state,
            [storageKey]: { ...apiInitialState, error: action.payload }
          }
      
      }
    
    }

    return state

  }


  /* Single payload dummy API response reducer generation */

  const auditFetchTypes = getFetchTypes(loadAuditType)


  /* Main reducer */

  return (
    state: Dictionary<ApiResponse<ReportEntity>> = {},
    action: ReduxAction,
    metricMetadata: MetricMetadataType | null = null
  ): Dictionary<ApiResponse<ReportEntity>> => {

    const { type, payload = {} } = action

    if( type === auditFetchTypes.success && hasInlineEntities(payload) ){
      return storeInlineEntities(payload, metricMetadata)
    }

    return defaultKeyedReducer(state, action, metricMetadata)

  }


}


export default createAuditEntityReducer
